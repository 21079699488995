import { Box, Modal, ScrollView, Spinner, Text, View, useToast } from "native-base";
import React, { useEffect, useState } from "react";
import { StyleSheet } from "react-native";
import { Colors } from "../../../../styles";
import { AppHeader } from "../../../common/AppHeader";
import { getTranscriptionDataApi, getTranscriptionDataFromAwsApi } from "../Conversations/ConversationChannelNew/ConversationAPIService";
import { parseTwilioRecordingUrl } from "./CallLogsUtils";
import { ITranscriptionParams } from "./interface";
import { useIntl } from "react-intl";
import { TwilioRecordingUrlPrefix } from "../../../../constants/Configs";
import { ToastType, showToast } from "../../../../utils/commonViewUtils";


export const CallTranscriptionModal=(props:any)=>{
  const {callLogEntry} = props;
  const toast = useToast();
  const intl = useIntl();
  const [state, setState] = useState({
    callLogEntry: callLogEntry || {} as any,
    isLoading: false,
  });

  useEffect(() => {
    if (callLogEntry?.transcription?.length) {
      setState((prev) => {
        return {
          ...prev,
          callLogEntry: callLogEntry,
        };
      });
    } else if (
      callLogEntry?.recording?.length
        ? callLogEntry?.recording?.includes(TwilioRecordingUrlPrefix)
        : callLogEntry?.callrecord?.includes(TwilioRecordingUrlPrefix)
    ) {
      getTranscriptionDataFromTwilio();
    } else {
      getCallTranscriptionData();
    }
  }, [callLogEntry]);

  const getTranscriptionDataFromTwilio = async () => {
    setState((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });
    const callId = callLogEntry?.id
    const transcriptionRes = await getTranscriptionDataApi(callId)

    if (transcriptionRes?.data) {
      setState((prev) => {
        return {
          ...prev,
          isLoading: false,
          callLogEntry: {
            ...state.callLogEntry,
            transcription: transcriptionRes?.data?.data.transcription,
        }
        };
      });
    }
  }

  const getCallTranscriptionData = async () => {
    setState((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });
    const transcriptionParams = {
      callSid: callLogEntry.callSid,
      ...parseTwilioRecordingUrl(
        callLogEntry?.recording
          ? callLogEntry.recording
          : callLogEntry.callrecord || ''
      ),
    } as ITranscriptionParams;

    if (!transcriptionParams.accountSid && !transcriptionParams.recordingSid) {
      showToast(
        toast,
        intl.formatMessage({id: 'apiErrorMsg'}),
        ToastType.error
      );
      setState((prev) => {
        return {
          ...prev,
          isLoading: false,
          callLogEntry: {
            ...callLogEntry,
            transcription: '',
          },
        };
      });
      return;
    }
    const transcriptionRes = await getTranscriptionDataFromAwsApi(
      transcriptionParams
    ).catch((err) => {
      setState((prev) => {
        return {
          ...prev,
          isLoading: false,
          callLogEntry: {
            ...callLogEntry,
            transcription: '',
          },
        };
      });
    });
    if (transcriptionRes?.data) {
      setState((prev) => {
        return {
          ...prev,
          isLoading: false,
          callLogEntry: {
            ...callLogEntry,
            transcription: transcriptionRes.data?.transcriptText,
          },
        };
      });
    }
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={() => {
        props.onClose();
      }}
      flex={1}
    >
      <View style={styles.modalContainer} backgroundColor={'white'}>
        <AppHeader title={'Call Transcription'} />

        <ScrollView style={styles.scrollView}>
          {state.isLoading ? (
            <Spinner size="sm" />
          ) : (
            <>
              {state?.callLogEntry?.transcription ? (
                <Text
                  size={'xsNormal'}
                  style={styles.transcriptionText}
                >
                  {getTranscriptionString(
                    state.callLogEntry?.transcription || ''
                  )}
                </Text>
              ) : (
                <View mt={20} justifyContent={'center'} alignItems={'center'}>
                  <Text flex={1} fontSize={18} color={Colors.Custom.Gray500}>
                    {intl.formatMessage({id: 'noTranscriptionAvailable'})}
                  </Text>
                </View>
              )}
            </>
          )}
        </ScrollView>
      </View>
    </Modal>
  );
}
export const getTranscriptionString = (transcriptionString: string) => {
  let str =
    transcriptionString.replace(
      new RegExp('Speaker', 'g'),
      '\n\nSpeaker'
    ) || ' ';
  if (str.startsWith('\n\n')) {
    str = str.substring(2);
  }

  return str;
};

const styles = StyleSheet.create({
  modalContainer: {
    // backgroundColor: 'white',
    width: '90%',
    maxHeight: '70%',
    flex: 1,
    borderColor: Colors.Custom.BorderColor,
    borderWidth: 2,
    borderRadius: 10,
  },
  scrollView: {
    flex: 1,
  },
  transcriptionText: {
    margin: 12,
    flex: 1,
    color: Colors.Custom.Gray700,
    fontSize: 14,
  },
});