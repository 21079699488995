import React, { CSSProperties, useCallback, useMemo } from 'react';
import {Popover} from 'antd';
import {HStack, Pressable, Skeleton, Text, VStack, View} from 'native-base';
import {useEffect, useRef, useState} from 'react';
import {Colors} from '../../../../styles';
import {useContainerDimensions} from '../../../CustomHooks/ContainerDimensionHook';
import {TooltipPlacement} from 'antd/lib/tooltip';
import {ILabelTask} from '../CareDashboardInterfaces';
import {StyleSheet} from 'react-native';

interface TagDataViewProps {
  style?: any;
  hideAddMoreButton?: boolean;
  tagList?: any;
  popOverConfig?: {
    placement?: TooltipPlacement;
    trigger?: 'click' | 'hover';
    offset?: [number, number];
  };
}
export const LabelDataListView = (props: TagDataViewProps) => {
  const {tagList, hideAddMoreButton} = props;
  const [stateData, setStateData] = useState({
    moreActionPopup: false,
    loading: true,
  });
  const componentRef = useRef();
  const {width} = useContainerDimensions(componentRef);
  const timeOutRefs = useRef<NodeJS.Timeout[]>([])

  const handleMoreActionVisibleChange = (visible: any) => {
    setStateData((prev) => {
      return {
        ...prev,
        moreActionPopup: visible,
      };
    });
  };
  const [maxTagsToShow, setLabelToShow] = useState<ILabelTask[]>([]);

  useEffect(() => {
    getMaxWidth(tagList);
  }, [tagList]);

  useEffect(() => {
    return () => {
      timeOutRefs.current.forEach((timeoutId) => {
        clearTimeout(timeoutId);
      });
    }
  }, [])

  const getMaxWidth = useCallback((labels: ILabelTask[]) => {
    setStateData((prev) => {
        return {
          ...prev,
          loading: true,
        };
      });
    const maxTagsToShow: ILabelTask[] = [];
    let currentWidth = 0;
    const maxWidth = (props?.style?.width || width) / 12;

    if (labels?.length === 1) {
      setLabelToShow(labels);
    } else {
      for (const label of labels) {
        if (currentWidth + (label?.title || '').length <= maxWidth) {
          currentWidth = currentWidth + (label?.title || '').length;
          maxTagsToShow.push(label);
        } else {
          if (maxTagsToShow?.length === 0) {
            maxTagsToShow.push(label);
          }
          break;
        }
      }
      setLabelToShow(maxTagsToShow);
    }
    const timeoutId = setTimeout(() => {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    }, 1000);
    timeOutRefs.current.push(timeoutId);
  }, [width]);

  const renderTags = useCallback((index: number, labelsItem: ILabelTask) => {
    return (
      <HStack
        key={index}
        justifyContent={'space-between'}
        alignItems="center"
        backgroundColor={labelsItem?.color + '40'}
        style={styles.tagItem}
      >
        <Text
          wordBreak={'break-word'}
          color={Colors.Custom.Gray500}
          size={'smLight'}
        >
          {labelsItem?.title}
        </Text>
      </HStack>
    );
  }, []);

  const extraTagsCount = useMemo(() => (tagList || [])?.length - maxTagsToShow?.length, [tagList, maxTagsToShow]);

  const moreElement = useMemo(() => (
    <div
      className="pressable"
      onClick={(event) => {
        event.stopPropagation();
        setStateData((prev) => {
          return {
            ...prev,
            moreActionPopup: true,
          };
        });
      }}
    >
      <Text
        fontWeight={500}
        marginTop={1}
        marginLeft={1}
        color={'#825AC7'}
      >
        {`+${extraTagsCount} more`}
      </Text>
    </div>
  ), [extraTagsCount]);

  return (
    <>
      <View
        ref={componentRef}
        style={[
          styles.container,
          {
            backgroundColor: props.hideAddMoreButton ? 'transparent' : '#fff',
            padding: props.hideAddMoreButton ? 0 : 16,
            paddingVertical: props.hideAddMoreButton ? 0 : 10,
          },
        ]}
      >
        {stateData.loading ? (
          <VStack key={tagList?.id} space={2}>
           <Skeleton
             height={4}
             borderRadius="lg"
             color={'gray'}
             bgColor={'gray'}
           />
         </VStack>
        ) : (
          <>
            <View flexDirection="row" width={'full'}>
              <View style={styles.tagsContainer} maxWidth={'100%'}>
                <HStack
                  alignItems={'center'}
                  maxWidth={'100%'}
                  flexWrap={'wrap'}
                  overflow={'auto'}
                >
                  <View maxWidth={'100%'} style={styles.tagsContainer}>
                    {maxTagsToShow && maxTagsToShow.length ? (
                      maxTagsToShow.map(
                        (lablesItem: ILabelTask, index: number) => {
                          return lablesItem?.uuid ? (
                            <>{renderTags(index, lablesItem)}</>
                          ) : (
                            <></>
                          );
                        }
                      )
                    ) : (
                      <></>
                    )}
                  </View>
                  {extraTagsCount > 0 && (
                    <Popover
                      key={tagList?.id}
                      overlayInnerStyle={{padding: 0, borderRadius: 12}}
                      overlayClassName="patient-search-select-styles"
                      className="patient-search-select-styles"
                      overlayStyle={{padding: 0}}
                      overlay={{padding: 0}}
                      style={{padding: 0, borderRadius: 12}}
                      placement={'bottomLeft'}
                      trigger={'click'}
                      open={stateData.moreActionPopup}
                      onOpenChange={handleMoreActionVisibleChange}
                      content={
                        <View key={tagList?.id} style={styles.popoverContent}>
                          <View
                            style={styles.popoverTagsContainer}
                            flexWrap={'wrap'}
                            overflow={'auto'}
                            maxWidth={'90%'}
                          >
                            {tagList &&
                              tagList.length &&
                              tagList?.map(
                                (tagItem: ILabelTask, index: number) => {
                                  return tagItem?.id ? (
                                    <>{renderTags(index, tagItem)}</>
                                  ) : (
                                    <></>
                                  );
                                }
                              )}
                          </View>
                        </View>
                      }
                    >
                      {moreElement}
                    </Popover>
                  )}
                </HStack>
              </View>
            </View>
          </>
        )}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    borderColor: Colors.Custom.Gray200,
    width: '100%',
    flex: 1,
    paddingTop: 4,
    paddingBottom: 4,
  },
  tagItem: {
    paddingHorizontal: 8,
    marginRight: 4,
    borderRadius: 16,
    marginTop: 5,
  },
  tagsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  popoverContent: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 330,
  },
  popoverTagsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});
