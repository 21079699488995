export const DEFAULT_COLORS = [
  '#825AC7',
  '#5FA2F8',
  '#F9564E',
  '#FFDD33',
  '#FFA727',
  '#5DDB4F',
  '#C445E3',
  '#848484',
  '#00BA97',
  '#0095B6',
];

export const DEFAULT_COLOR = '#825AC7'
