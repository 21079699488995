import { useLazyQuery } from '@apollo/client';
import { Divider, HStack, Text, View, VStack } from 'native-base';
import {useEffect, useRef, useState} from 'react';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../constants/Configs';
import { ORDER_TYPE, PHONE_NUMBER_MASK } from '../../../../constants/StringConst';
import { GET_BOOKED_APPOINTMENT_BY_EXTERNAL_ID } from '../../../../services/Appointment/AppointmentQueries';
import { getDocumentRefByReferenceId, getMedicationForNote, getOrderByRefAndPatientId } from '../../../../services/CommonService/OrderService';
import { GET_FORM_BY_ID } from '../../../../services/Forms/FormsQueries';
import { getCurrentTimeZone, getCurrentTimeZoneAbbr, getDateStrFromFormat, getDateStrFromFormatWithTimezone } from '../../../../utils/DateUtils';
import {FHForm} from '../../../RightSideContainer/Forms/FHFormio';
import {ICategoryWiseOrder, IPrintPatientNoteProps, IPrintPatientNoteState} from '../interfaces';
import { OrderType } from '../Orders/OrdersAndReports/OrderConstants';
import { getExtensionValue } from '../Orders/OrdersAndReports/OrderUtils';
import { EXTENSION_URLS } from '../PatientNotes/components/AddOrUpdateTemplate/constant';
import { getFormComponents, getSubmittedResponse } from '../PatientNotes/components/DocumentationViewHelper';
import { DocStatus, IFormComponent } from '../PatientNotes/interfaces';
import { filterOrderContentFromDocumentRef, getFormContentByEhrAndFormData, hasChiefComplaintComponent, isOrderTypeDataExist } from '../PatientNotes/PatientNotesHelper';
import {ErxPrintView} from './ErxPrintView';
import {OrderPrintView} from './OrderPrintView';
import {DATE_FORMATS} from '../../../../constants/StringConst';
import { useIntl } from 'react-intl';
import { numericStringMask } from '../../../../utils/commonUtils';
import DetailPreview from '../PersonDetailsView/DetailPreview/DetailPreview';
import { FHIR_RESOURCE } from '../../../../constants/FhirConstant';
import { getResourceAbilities } from '../../../../utils/capabilityUtils';
import { getComponentsForShareablePrint } from './NoteUtils';
import { cloneDeep } from 'lodash';

export const PatientNotePrintPreview = (props: IPrintPatientNoteProps) => {
  const {formOptions, templateData, categoryWiseOrderData, personData, unFormattedContactData, notesData} = props;
  const intl = useIntl();
  const timeOutRefs = useRef<NodeJS.Timeout[]>([])
  const resourceAbilities = getResourceAbilities(
    FHIR_RESOURCE.DOCUMENT_REFERENCE,
    '',
    personData?.accountLocationUuid
  );
  const foldVisitNoteWithEncountersEnabled = resourceAbilities?.foldVisitNoteEnabled || false;
  const isSignedNote =
    props.notesData.status === DocStatus.AMENDED ||
    props.notesData.status === DocStatus.FINAL;
  const [printNoteState, setPatientNoteState] =
    useState<IPrintPatientNoteState>({
      templateData: templateData,
      categoryWiseOrderData: categoryWiseOrderData,
      patientDemographics: personData,
      unFormattedContactData: unFormattedContactData,
      isLoading: true,
      signedByDate: notesData?.signedDate || '',
      signedByUserName: notesData?.signedByUserName || '',
    });

  const callParentPrint = () => {
    const timeOutId = setTimeout(() => {
      if (props?.onRenderComponent && typeof props?.onRenderComponent == 'function') {
        props?.onRenderComponent();
      }
    }, 3000);
    timeOutRefs.current.push((timeOutId))
  };

  const handlePrintError = (error: any) => {
    if (props?.onRenderComponent && typeof props?.onRenderComponent == 'function') {
      props?.onRenderComponent(error);
    }
  }

  const getCategoryWiseOrderData = (
    responseList: any,
    orderBySequence: any[],
  ): ICategoryWiseOrder => {
    const categoryWiseOrders: ICategoryWiseOrder = {
      labTest: [],
      radiology: [],
      medications: [],
    };
    if (responseList?.length) {
      (orderBySequence || []).forEach((sequence, index) => {
        const response = responseList[index];
        const resourceList = response?.data?.entry || [];
        switch (sequence) {
          case ORDER_TYPE.LAB:
            categoryWiseOrders.labTest = resourceList;
            break;
          case ORDER_TYPE.RAD:
            categoryWiseOrders.radiology = resourceList;
            break;
          case ORDER_TYPE.MED:
            categoryWiseOrders.medications = resourceList;
            break;
        }
      });
    }
    return categoryWiseOrders;
  };

  const processOnFormAndNoteData = (
    formData: any,
    documentRefData: any,
    appointmentData?: any,
  ) => {
    try {
    const orderBySequence: any[] = [];
    const orderPromiseList: any[] = [];
    const initData = {
      formContent: [] as any[],
    };
    let formComponents: any[] = [];
    let formAnswer: any = {};
    let sequence = -1;

    // process on reference data
    const patientContactId = personData?.id || '';
    const patientId = personData?.patientId || personData?.patientUuid || '';
    const documentRefContent =
      filterOrderContentFromDocumentRef(documentRefData);
    const additionalSections = getExtensionValue(
      documentRefData,
      EXTENSION_URLS.docRefAdditionalSection,
    );
    const formContent: IFormComponent[] = getFormContentByEhrAndFormData(
      documentRefData?.content || [],
      true,
    );
    if (formData?.form) {
      initData.formContent = formContent;
      formComponents = getFormComponents(
        formData?.form?.components,
        notesData?.linkedAppointmentId,
        initData,
        !!appointmentData?.externalAppointmentId,
      );
      formAnswer = getSubmittedResponse(
        initData,
        appointmentData,
        !!appointmentData?.externalAppointmentId,
        hasChiefComplaintComponent(formData),
      );
    }
    if (documentRefContent?.length || additionalSections?.length) {
      if (
        isOrderTypeDataExist(ORDER_TYPE.LAB, documentRefContent) ||
        additionalSections?.includes(ORDER_TYPE.LAB)
      ) {
        sequence += 1;
        orderBySequence[sequence] = ORDER_TYPE.LAB;
        orderPromiseList.push(
          getOrderByRefAndPatientId(
            OrderType.LAB,
            patientId,
            `${notesData.resourceId || ''}`,
            personData?.accountLocationUuid,
          ),
        );
      }
      if (
        isOrderTypeDataExist(ORDER_TYPE.RAD, documentRefContent) ||
        additionalSections?.includes(ORDER_TYPE.RAD)
      ) {
        sequence += 1;
        orderBySequence[sequence] = ORDER_TYPE.RAD;
        orderPromiseList.push(
          getOrderByRefAndPatientId(
            OrderType.RAD,
            patientId,
            `${notesData.resourceId || ''}`,
            personData?.accountLocationUuid,
          ),
        );
      }
      if (
        isOrderTypeDataExist(ORDER_TYPE.MED, documentRefContent) ||
        additionalSections?.includes(ORDER_TYPE.MED)
      ) {
        sequence += 1;
        orderBySequence[sequence] = ORDER_TYPE.MED;
        orderPromiseList.push(
          getMedicationForNote(patientContactId, `${notesData.resourceId || ''}`),
        );
      }
    }

    if (!orderPromiseList?.length) {
      setPatientNoteState((prev: any) => {
        return {
          ...prev,
          templateData: {components: formComponents, answers: formAnswer},
          categoryWiseOrderData: {
            labTest: [],
            radiology: [],
            medications: [],
          },
          isLoading: false,
        };
      });
      callParentPrint();
      return;
    }

    Promise.all(orderPromiseList)
      .then((responseList: any) => {
        const categoryWiseOrderData: ICategoryWiseOrder =
          getCategoryWiseOrderData(responseList, orderBySequence);
        const templateData = {components: formComponents, answers: formAnswer};
        setPatientNoteState((prev: any) => {
          return {
            ...prev,
            templateData,
            categoryWiseOrderData,
            isLoading: false,
          };
        });
        callParentPrint();
      })
      .catch(error => {
        setPatientNoteState((prev: any) => {
          return {
            ...prev,
            isLoading: false,
          };
        });
        handlePrintError(error);
      });
    } catch(error) {
      setPatientNoteState(prev => ({...prev, isLoading: false}));
      handlePrintError(error);
    }
  };

  const [getFormById] = useLazyQuery(GET_FORM_BY_ID, {
    fetchPolicy: 'no-cache',
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
  });

  const [getAppointmentByExtId] = useLazyQuery(
    GET_BOOKED_APPOINTMENT_BY_EXTERNAL_ID,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      variables: {
        externalAppointmentId: notesData.linkedAppointmentId,
      },
    },
  );


  const fetchFormAndNoteOrders = async () => {
    try {
      const promiseList: any[] = [
        getFormById({variables: {id: notesData.formId}}),
        getDocumentRefByReferenceId(
          `${notesData.resourceId || ''}`,
          foldVisitNoteWithEncountersEnabled,
          personData?.accountLocationUuid,
        ),
      ];
      if (notesData.linkedAppointmentId) {
        promiseList.push(
          getAppointmentByExtId({
            variables: {externalAppointmentId: notesData.linkedAppointmentId},
          }),
        );
      }
      const noteResponse = await Promise.all(promiseList);
      const formData = noteResponse?.[0]?.data || undefined;
      const documentRefData = noteResponse?.[1]?.data || undefined;
      const appointmentData = noteResponse?.[2]?.data || undefined;
      await processOnFormAndNoteData(
        formData,
        documentRefData,
        appointmentData,
      );
    } catch (error) {
      setPatientNoteState(prev => ({...prev, isLoading: false}));
      handlePrintError(error);
    }
  };

  useEffect(() => {
    if (props.isDefaultDataAvailable) {
      setPatientNoteState(prev => {
        return {
          ...prev,
          isLoading: false,
        }
      });
      callParentPrint();
    } else {
      fetchFormAndNoteOrders();
    }
    return () => {
      timeOutRefs.current.forEach((timeoutId) => {
        clearTimeout(timeoutId);
      });
    }
  }, []);


 const renderSignedByInfo = () => {
  const signedByUserName = props.notesData.signedByUserName;
  const signedByDate = getDateStrFromFormat(props?.notesData?.signedDate || '', DATE_FORMATS.SIGNED_NOTE_DATE);
    const timezoneAbbr = getCurrentTimeZoneAbbr();
  if(signedByUserName && signedByDate) {
    return (
      <div className="page-break">
        <Divider my={2} backgroundColor="black" />
        <HStack alignItems="center" my={2}>
          <Text fontSize={16}>{'Signed By '}</Text>
          <Text fontSize={16} fontWeight={600}>{signedByUserName}</Text>
          <Text fontSize={16}>{' on '}</Text>
          <Text fontSize={16} fontWeight={600}>{signedByDate} ({timezoneAbbr}).</Text>
        </HStack>
      </div>
    );
  }
 }

 const renderPatientDemographics = () => {
   return (
     <VStack>
       <Text fontWeight={600} size={'lgMedium'}>
         {personData.name}
       </Text>
       <HStack flex={1}>
         <VStack flex={5} alignItems="flex-start">
           {personData?.phone && (
             <Text fontSize={16}>{`${intl.formatMessage({id: 'phone'})} : ${
               personData?.phone &&
               numericStringMask(personData?.phone || '', PHONE_NUMBER_MASK)
             }`}</Text>
           )}
           {personData?.email && (
             <Text fontSize={16}>{`${intl.formatMessage({id: 'email'})} : ${
               personData?.email
             }`}</Text>
           )}
         </VStack>
         <VStack flex={5} alignItems="flex-end">
           <Text fontSize={16}>{`${personData?.line1}${
             personData?.line2 && ','
           }`}</Text>
           <Text fontSize={16}>{`${personData?.line2}`}</Text>
         </VStack>
       </HStack>
     </VStack>
   );
 };

 const renderVisitInfo = () => {
  if (!props?.appointmentData?.id) {
    return <></>;
  }
  return (
    <VStack>
      <DetailPreview titleLocalId={`Visit details`}>
        <View>
        <Text>
          {`Date Of Visit: ${getDateStrFromFormat(notesData.createdDate,
            DATE_FORMATS.MESSAGE_DATE_FORMAT
          )}`}
        </Text>
        {
          !!props.notesData.linkedAppointmentId &&
          <Text fontSize={16}>
            {`Reason For Visit : ${props.appointmentData?.reasonForVisit?.displayName || '-'}`}
          </Text>
        }
        </View>
      </DetailPreview>
    </VStack>
  );
 }


  return (
    <>
      {!printNoteState?.isLoading && (
        <>
          {/* {renderPatientDemographics()} */}
          {renderVisitInfo()}
          {/* <Divider my={2} backgroundColor="black" /> */}
          <div className="note-preview-styles custom-form-styles">
            <FHForm
              components={
                props?.printShareable
                  ? getComponentsForShareablePrint(
                      cloneDeep(printNoteState?.templateData?.components || [])
                    )
                  : printNoteState?.templateData?.components
              }
              submittedResponse={printNoteState?.templateData?.answers}
              optionData={formOptions}
              isPreviewMode={true}
              readOnly={true}
            />
          </div>
          {printNoteState.categoryWiseOrderData?.labTest &&
            printNoteState.categoryWiseOrderData?.labTest?.length > 0 && (
              <OrderPrintView
                title="labOrders"
                orders={printNoteState.categoryWiseOrderData.labTest}
              />
            )}
          {printNoteState.categoryWiseOrderData?.radiology &&
            printNoteState.categoryWiseOrderData?.radiology?.length > 0 && (
              <OrderPrintView
                title="radOrders"
                orders={printNoteState.categoryWiseOrderData.radiology}
              />
            )}
          {printNoteState.categoryWiseOrderData?.medications &&
            printNoteState.categoryWiseOrderData?.medications?.length > 0 && (
              <ErxPrintView
                title="eRx"
                orders={printNoteState.categoryWiseOrderData.medications}
              />
            )}
          {isSignedNote && renderSignedByInfo()}
        </>
      )}
    </>
  );
};

