import React from 'react';
import { Colors } from '../../../styles/Colors';
import {isWeb} from '../../../utils/platformCheckUtils';
import { ICommonSvgProps } from './interfaces';
import {Image} from 'native-base';

const AtSignSvg = (props: ICommonSvgProps) => {
    return (
      <>
        {isWeb() ? (
           <svg
           xmlns="http://www.w3.org/2000/svg"
           width={props?.size ? props?.size : "22"}
           height={props?.size ? props?.size : "22"}
           fill="none"
           viewBox="0 0 22 22"
         >
           <path
             stroke="#F37232"
             strokeLinecap="round"
             strokeLinejoin="round"
             strokeWidth="1.5"
             d="M15 7v5a3 3 0 006 0v-1a10 10 0 10-3.92 7.94M15 11a4 4 0 11-8 0 4 4 0 018 0z"
           ></path>
         </svg>
        ) : (
          <Image
            size={'5'}
            source={require('../../../assets/images/Conversations/at_sign.png')}
            alt="aa"
          />
        )}
      </>
    );
};

export default AtSignSvg;
