import { Divider, HStack, Skeleton, Text, View } from "native-base";
import { Colors } from "../../../../styles";
import { DisplayText } from "../../DisplayText/DisplayText";
import { getDateStrFromFormat } from "../../../../utils/DateUtils";
import { DATE_FORMATS } from "../../../../constants";
import { ITask } from "../../CareDashboard/CareDashboardInterfaces";
import useTaskAdditionalDetail from "./useTaskAdditionalDetail";
import { MLOV_CATEGORY, TASK_SUBJECT_TYPE } from "../../../../constants/MlovConst";
import { getMlovCodeFromId, getMlovListFromCategory } from "../../../../utils/mlovUtils";
import { useContext } from "react";
import { CommonDataContext } from "../../../../context/CommonDataContext";

interface ITaskExtraDetailsViewProps {
  task: ITask | undefined;
  accountUsers: any[];
}


export const TaskExtraDetailsView = (props: ITaskExtraDetailsViewProps) => {
  const { task, accountUsers } =  props;

  const mlovData = useContext(CommonDataContext);
  const taskSubjectTypes = getMlovListFromCategory(mlovData.CARE_STUDIO_MLOV, MLOV_CATEGORY.TASK_SUBJECT) || [];
  const subjectTypeCode = task?.subjectTypeId ? getMlovCodeFromId(taskSubjectTypes, task?.subjectTypeId) : TASK_SUBJECT_TYPE.ORDER;

  if(![TASK_SUBJECT_TYPE.ORDER, TASK_SUBJECT_TYPE.PATIENT_CASE, TASK_SUBJECT_TYPE.PATIENT_REFERRAL].includes(subjectTypeCode) || !task) {
    return <></>
  }

  if ([TASK_SUBJECT_TYPE.PATIENT_CASE, TASK_SUBJECT_TYPE.PATIENT_REFERRAL].includes(subjectTypeCode) && !accountUsers?.length) {
    return <></>
  }

  const [additionalDetailData, loading] = useTaskAdditionalDetail(task, accountUsers, subjectTypeCode);

  const getDetailHeading = () => {
    switch (subjectTypeCode) {
      case TASK_SUBJECT_TYPE.ORDER:
        return 'prescriptionOrderDetail'
      case TASK_SUBJECT_TYPE.PATIENT_CASE:
        return 'patientCaseDetail'
      case TASK_SUBJECT_TYPE.PATIENT_REFERRAL:
        return 'referralDetail'
      default:
        return 'prescriptionOrderDetail'
    }
  }

  const getDetailView = () => {
    if (!additionalDetailData) {
      return <></>
    }

    let additionalDetailRecord: any[] = [];
    switch (subjectTypeCode) {
      case TASK_SUBJECT_TYPE.ORDER:
        additionalDetailRecord = [
          {
            label: 'Order Id',
            value: additionalDetailData?.externalOrderId
          },
          {
            label: 'Prescribe Date',
            value: getDateStrFromFormat(additionalDetailData?.orderDate, DATE_FORMATS.DISPLAY_DATE_FORMAT)
          },
          {
            label: 'Note',
            value: additionalDetailData?.orderNote
          },
          {
            label: 'Status',
            value: additionalDetailData?.orderStatus
          },
          {
            label: 'Quantity',
            value: additionalDetailData?.referenceDetails?.prescribedQuantity?.value ? `${additionalDetailData?.referenceDetails?.prescribedQuantity?.value} - ${additionalDetailData?.referenceDetails?.prescribedQuantity?.unit}` : undefined
          },
          {
            label: 'Sig',
            value: additionalDetailData?.referenceDetails?.sig
          },
          {
            label: 'Order Type',
            value: additionalDetailData?.orderType
          },
        ];
        break;
      case TASK_SUBJECT_TYPE.PATIENT_CASE:
        additionalDetailRecord = [
          {
            label: 'Document Id',
            value: additionalDetailData?.documentId
          },
          {
            label: 'Document Date',
            value: getDateStrFromFormat(additionalDetailData?.documentDate, DATE_FORMATS.DISPLAY_DATE_FORMAT)
          },
          {
            label: 'Description',
            value: additionalDetailData?.description
          },
          {
            label: 'Subject',
            value: additionalDetailData?.documentSubject
          },
          {
            label: 'Case Description',
            value: additionalDetailData?.patientCaseDescription
          },
          {
            label: 'Document Status',
            value: additionalDetailData?.documentStatus
          },
          {
            label: 'Callback Name',
            value: additionalDetailData?.callbackName
          },
          {
            label: 'Callback Number',
            value: additionalDetailData?.callbackNumber
          },
          {
            label: 'Source/Recipient',
            value: additionalDetailData?.reference?.documentSource
          },
          {
            label: 'Document Route',
            value: additionalDetailData?.reference?.documentRoute
          },
          {
            label: 'Provider',
            value: additionalDetailData?.provider
          },
          {
            label: 'Document Type',
            value: additionalDetailData?.documentType
          },
        ];
        break;
      case TASK_SUBJECT_TYPE.PATIENT_REFERRAL:
        additionalDetailRecord = [
          {
            label: 'Document Id',
            value: additionalDetailData?.documentId
          },
          {
            label: 'Document Date',
            value: getDateStrFromFormat(additionalDetailData?.documentDate, DATE_FORMATS.DISPLAY_DATE_FORMAT)
          },
          {
            label: 'Description',
            value: additionalDetailData?.description
          },
          {
            label: 'Document Status',
            value: additionalDetailData?.documentStatus
          },
          {
            label: 'Internal Note',
            value: additionalDetailData?.internalNote
          },
          {
            label: 'Action Note',
            value: additionalDetailData?.actionNote
          },
          {
            label: 'Alarm Days',
            value: additionalDetailData?.alarmDays
          },
          {
            label: 'Performed Date',
            value: getDateStrFromFormat(additionalDetailData?.performedDate, DATE_FORMATS.DISPLAY_DATE_FORMAT)
          },
          {
            label: 'Provider Note',
            value: additionalDetailData?.providerNote
          },
          {
            label: 'ICD10',
            value: additionalDetailData?.icd10?.display
          },
          {
            label: 'Diagnosis',
            value: additionalDetailData?.diagnosis?.display
          },
          {
            label: 'Order Type',
            value: additionalDetailData?.orderType
          },
          {
            label: 'Ordering Provider',
            value: additionalDetailData?.orderingProvider
          },
        ];
        break;
    }
    return (
      <>
        {additionalDetailRecord?.map((singleRow, index) => {
          return (
            singleRow.value &&
            <HStack key={index} space={1} flex={1} alignItems={'center'}>
              <DisplayText
                size={"mdSemibold"}
                extraStyles={{
                  fontWeight: 600,
                  fontSize: 16,
                  flex: 0.3,
                }}
                textLocalId={singleRow.label}
              />
              <Text
                flex={0.7}
                fontWeight={400}
                fontSize={14}
                color={Colors.Custom.Gray700}
              >
                {singleRow.value}
              </Text>
            </HStack>
          )
        })}
      </>
    );
  }

  return (
    subjectTypeCode === TASK_SUBJECT_TYPE.ORDER && (!task?.orders || !task?.orders?.length) ?
    null :
    <View background={Colors.Custom.Gray50} py={4} borderRadius={8} borderWidth={1} borderColor={Colors.Custom.Gray200}>
      <HStack px={4} flex={1}>
        <DisplayText
          size={'mdMedium'}
          extraStyles={{
            fontWeight: 600,
            fontSize: 16,
          }}
          textLocalId={getDetailHeading()}
        />
      </HStack>
      <Divider my={2} backgroundColor={Colors.Custom.Gray200} />
      <View px={4}>
        {loading ?
          <Skeleton.Text lines={4} /> :
          getDetailView()
        }
      </View>
    </View>
  );
}
