import React from 'react';

const VideoFileIcon = (props: {color?: string}) => {
  const strokeColor = props.color ? props.color : '#825AC7';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M4.166 2.5L15.833 10 4.166 17.5v-15z"
      ></path>
    </svg>
  );
};

export default VideoFileIcon;
