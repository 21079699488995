import React from "react";
import { Svg, Rect, Path } from "react-native-svg";

const  AutomationIcon = () => {
    return (
        <Svg
            width="26"
            height="26"
            fill="none"
            viewBox="0 0 26 26"
        >
            <Rect
                width="24.5"
                height="24.5"
                x="0.75"
                y="0.75"
                fill="#F9F5FF"
                rx="12.25"
            ></Rect>
            <Rect
                width="24.5"
                height="24.5"
                x="0.75"
                y="0.75"
                stroke="#825AC7"
                strokeWidth="0.5"
                rx="12.25"
            ></Rect>
            <Path
                stroke="#825AC7"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14.187 6.194c-.076 1.505 1.777 2.271 2.786 1.153a1.602 1.602 0 011.68 1.68c-1.118 1.009-.35 2.863 1.153 2.786a1.6 1.6 0 010 2.374c-1.504-.076-2.271 1.777-1.153 2.786a1.602 1.602 0 01-1.68 1.68c-1.009-1.118-2.862-.35-2.786 1.153a1.6 1.6 0 01-2.374 0c.077-1.504-1.777-2.271-2.785-1.153a1.602 1.602 0 01-1.68-1.68c1.117-1.009.348-2.862-1.154-2.786a1.6 1.6 0 010-2.374c1.505.077 2.271-1.777 1.153-2.785a1.602 1.602 0 011.68-1.68c1.009 1.117 2.863.348 2.786-1.154a1.6 1.6 0 012.374 0z"
            ></Path>
            <Path
                stroke="#825AC7"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M11.667 11L15 13l-3.333 2v-4z"
            ></Path>
        </Svg>
    );
}

export default AutomationIcon;
