import React ,{useContext, useEffect, useState} from 'react';
import {View} from 'native-base';
import {getAllLocations, getPractitioners} from '../../../services/CommonService/AidBoxService';
import { getEHRName } from '../../../utils/capabilityUtils';
import { EXTENSION_URLS } from '../../PersonOmniView/MiddleContainer/PatientNotes/components/AddOrUpdateTemplate/constant';
import { isAthenaCaliberEHR } from '../../PersonOmniView/MiddleContainer/PatientNotes/PatientNotesHelper';
import { ModalActionAntSelect } from '../ModalActionCommonComponent/ModalActionAntSelect';
import {
  IPractitionerSearchProps,
  PractitionerObject,
  ResponseObject,
} from './interfaces';
import { isWeb } from '../../../utils/platformCheckUtils';
import CustomSelect from '../CustomComponents/CustomSelect/CustomSelect';
import { Colors } from '../../../styles';
import { InputStyle } from '../../RightSideContainer/Contacts/Leads/LeadView/LeadViewStyle';
import {IFormCommonData} from '../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomWrapper';
import {CommonDataContext} from '../../../context/CommonDataContext';

interface ILocationProviderMap {
  [index: string]: {providerList: string[], providerGroupId: string};
};

const PractitionerSearch = (props: IPractitionerSearchProps) => {
  const {
    selectedPractitioner,
    onSelectPractitioner,
    isRequired,
    label,
    isInvalid,
    errors,
    errorText,
    accountLocationUuid,
    disable
  } = props;

  const ehrName: any = getEHRName(accountLocationUuid, '');
  const commonContextData = useContext(CommonDataContext) as IFormCommonData;
  const isAthena = isAthenaCaliberEHR(ehrName);
  const [stateData, setStateData] = useState({
    practitionerList: [] as PractitionerObject[],
    filteredPractitionerList: [] as PractitionerObject[],
    searchedPractitioners: [] as PractitionerObject[],
    loading: true as boolean,
    locationProviderMap: {} as ILocationProviderMap,
  });

  const updateSelectedPractitioner = (list: PractitionerObject[]) => {
    if (accountLocationUuid && !stateData.loading && isAthena) {
      const providerList = stateData.locationProviderMap[accountLocationUuid]?.providerList || [];
      const providerGroupId = stateData.locationProviderMap[accountLocationUuid]?.providerGroupId || '';
      const filteredList = list.filter((item) => providerList.includes(item.id) && item.providerGroupList.includes(providerGroupId));
      setStateData((prev) => {
        return {
          ...prev,
          filteredPractitionerList: filteredList,
        };
      });
      if (selectedPractitioner) {
        const practitionerAvailable = filteredList.find((practitioner) => {
          return practitioner.id === (selectedPractitioner?.toString() || '');
        });
        if (!practitionerAvailable) {
          onSelectPractitioner('');
        }
      }
    }
  }

  const fetchPractitioners = async () => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    if (isAthena) {
      try {
        const response = await getAllLocations();
        const locationProviderMap: ILocationProviderMap = {};
        if (response.data.entry?.length) {
          response.data.entry.forEach((item: any) => {
            const locationId = item.resource?.extension?.find((item: any) => item.url === EXTENSION_URLS.foldAccountLocationId)?.valueString || '';
            const providerExtensionValue = item.resource?.extension?.find((item: any) => item.url === EXTENSION_URLS.providerList)?.valueString;
            const providerGroupExtensionValue = item.resource?.extension?.find((item: any) => item.url === EXTENSION_URLS.providerGroupId)?.valueString || '';
            const providerList: string[] = Array.isArray(providerExtensionValue) ? providerExtensionValue : [];
            if (locationId && providerGroupExtensionValue) {
              locationProviderMap[locationId] = { providerList, providerGroupId: providerGroupExtensionValue };
            }
          })
        }
        setStateData((prev) => {
          return {
            ...prev,
            locationProviderMap: locationProviderMap,
          };
        });
      } catch (error) {

      }
    }
    accountLocationUuid && getPractitioners({
      successHandler(response) {
        const list = getPractitionerObjects(response.data);
        setStateData((prev) => {
          return {
            ...prev,
            practitionerList: list,
            filteredPractitionerList: list,
            loading: false,
          };
        });
      },
      errorHandler(error) {

        setStateData((prev) => {
          return {
            ...prev,
            practitionerList: [],
            loading: false,
          };
        });
      },
      locationId: accountLocationUuid
    });
  }

  useEffect(() => {
    updateSelectedPractitioner(stateData.practitionerList);
  }, [selectedPractitioner, accountLocationUuid, stateData.practitionerList]);

  useEffect(() => {
    fetchPractitioners();
  }, [accountLocationUuid]);

  return (
    <View flex={1}>
      {isWeb() ? (
        <ModalActionAntSelect
          allowClear={false}
          isRequired={isRequired}
          isInvalid={isInvalid}
          disabled={stateData.loading || disable}
          isDisabled={disable}
          loading={stateData.loading}
          leftMargin={'0'}
          showSearch={true}
          labelInValue={true}
          filterOption={(input: string, option: any) => {
            return (option?.children || '')
              .toLowerCase()
              .includes(input.toLowerCase());
          }}
          value={
            stateData.practitionerList.find(
              (item) => item.id === selectedPractitioner?.toString() || ''
            )?.displayName || undefined
          }
          label={label || 'Practitioner'}
          placeholder="Search Practitioner"
          onChange={(selectedOption: any) => {
            const value = selectedOption?.value || '';
            onSelectPractitioner(value.toString());
          }}
          data={stateData.filteredPractitionerList}
          optionProps={{
            key: 'id',
            label: 'displayName',
            value: 'id',
          }}
          errors={errors}
          errorText={errorText}
          extraStyle={{flex: 1}}
        />
      ) : (
        <CustomSelect
          styles={{
            borderRadius: 14,
            borderColor: Colors.Custom.CardBorderColor,
            placeHolderColorText: InputStyle.placeholderTextColor
          }}
          label=''
          options={stateData.filteredPractitionerList}
          optionProps={{
            key: 'id',
            label: 'displayName',
            value: 'displayName',
          }}
          placeholder="Search Practitioner"
          onSelect={(data) => props.onSelectPractitioner(data?.id)}
          isRequired={isRequired}
          isInvalid={isInvalid}
          value={
            stateData.practitionerList.find(
              (item) => item.id === selectedPractitioner?.toString() || ''
            )?.displayName || ''
          }
        />
      )}
    </View>
  );
};

export default PractitionerSearch;

export const getPractitionerObjects = (
  response: ResponseObject
): PractitionerObject[] => {
  const entry = response.entry;
  return entry.map((item) => {
    const resource = item.resource;
    return {
      id: resource.id.toString(),
      firstName: resource.name?.[0]?.given?.[0],
      lastName: resource.name?.[0]?.family,
      active: resource?.active,
      displayName: resource.name?.[0]?.given?.[0] + ' ' + resource.name?.[0]?.family,
      providerGroupList: getProviderGroupList(resource)
    };
  }).filter(item => item?.active ?? true);
};

const getProviderGroupList = (resource: any) => {
  const groupList = resource.extension?.find((item: any) => item.url === EXTENSION_URLS.providerGroupList)?.valueString || [];
  if (Array.isArray(groupList)) {
    return groupList;
  }
  return [];
}
