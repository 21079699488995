import {Modal, Text, View} from 'native-base';
import React from 'react';
import {useIntl} from 'react-intl';
import {BUTTON_TYPE} from '../../../../constants';
import {DisplayText} from '../../../common/DisplayText/DisplayText';
import ModalActionBtn from '../../../common/ModalActionBtn/ModalActionBtn';
import {FoldButton} from '../../../CommonComponents/FoldButton/FoldButton';
import {styles} from '../DeleteTemplateModal/DeleteTemplateModalStyle';

export interface IModalTextContent {
  message?: string;
  messageId?: string;
  headerText?: string;
  headerTextId?: string;
}

export interface IOverrideModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  textContent: IModalTextContent;
  closeButtonText?: string;
}

const OverrideModal = (props: IOverrideModalProps) => {
  const {isOpen, onClose, onConfirm, textContent} = props;
  const {message, messageId, headerText, headerTextId} = textContent;
  const intl = useIntl();
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Content style={styles.modalContainer}>
        <Modal.CloseButton />
        <Modal.Header>
          {headerText && <Text>{headerText}</Text>}
          {!headerText && headerTextId && (
            <Text>
              <DisplayText textLocalId={headerTextId} textType="Heading" />
            </Text>
          )}
        </Modal.Header>
        <Modal.Body>
          {message && <Text>{message}</Text>}

          {!message && messageId && (
            <Text>
              <DisplayText textLocalId={messageId} textType="Text" />
            </Text>
          )}
        </Modal.Body>

        <Modal.Footer style={styles.modalFooter}>
          <View style={{margin: 2}}>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.SECONDARY,
                onPress: () => {
                  onClose();
                },
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: props?.closeButtonText || 'close',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
          </View>

          <View style={{margin: 2}}>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                onPress: () => {
                  onConfirm();
                },
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'confirm',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
          </View>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

export default OverrideModal;
