import {HStack, Text, View, VStack} from 'native-base';
import {DATE_FORMATS, MEMBERSHIP_STATUS} from '../../../constants';
import {Colors} from '../../../styles';
import {IMembershipData} from './interface';
import React from 'react';
import {ConvertUnixTimestamp} from '../../../utils/DateUtils';
import {capitalizeText} from '../ContactRelationView/ContactRelationUtils';
import MembershipIcon from '../Svg/MembershipIcon';
import moment from 'moment';
import { isWeb } from '../../../utils/platformCheckUtils';

export interface MembershipCardProps {
  activeMembership: IMembershipData;
  onMemberShipCanceled?: (updateMembership: IMembershipData) => void;
  closeDrawer?: () => void;
  isLast?: boolean;
}
const MembershipCard = (props: MembershipCardProps) => {
  const {activeMembership} = props;
  const isMembershipCanceled =
    activeMembership.status === MEMBERSHIP_STATUS.CANCELED;
  const endDatePrefix = isMembershipCanceled
    ? 'Expires on'
    : 'Next Billing Date on';
  const isMembershipActive =
    activeMembership.status === MEMBERSHIP_STATUS.ACTIVE;
    const bgColor =
    activeMembership.status === MEMBERSHIP_STATUS.ACTIVE
      ? Colors.success['100']
      : activeMembership.status == 'IN_ACTIVE'
      ? Colors.info['100']
      : Colors.danger['100'];
  const borderColor =
  activeMembership.status == MEMBERSHIP_STATUS.ACTIVE
      ? Colors.success['200']
      : activeMembership.status == 'IN_ACTIVE'
      ? Colors.info['200']
      : Colors.danger['200'];
  const textColor =
  activeMembership.status == MEMBERSHIP_STATUS.ACTIVE
      ? Colors.success['400']
      : activeMembership.status == 'IN_ACTIVE'
      ? Colors.info['400']
      : Colors.danger['400'];
  return (
    <VStack>
      <VStack
        style={{
          marginVertical: 16,
        }}
      >
        <HStack width={'100%'} justifyContent={'space-between'}>
          {isWeb() && <View>
            <View position={'relative'} top={1.5}><MembershipIcon /></View>
            <View position={'absolute'} bottom={10} left={0.6} style={{
              width: 'max-content',
              paddingHorizontal: 6,
              paddingVertical: 2,
              borderRadius: 6,
              backgroundColor: bgColor,
              borderWidth: 1,
              borderColor: borderColor,
            }}> <Text fontSize={9} color={textColor}>{capitalizeText(activeMembership?.status === MEMBERSHIP_STATUS?.SUCCEEDED ? 'One Time' : activeMembership?.status)}</Text>
            </View>
          </View> }
          <VStack
            style={{
              marginLeft: 16,
            }}
            flex={1}
          >
            <HStack
              flex={1}
              style={{
                marginBottom: 4,
              }}
              justifyContent="space-between"
            >
              <Text
                numberOfLines={1}
                textOverflow={'ellipsis'}
                fontSize={16}
                size="mdMedium"
                fontWeight={300}
                color={Colors.Custom.Gray900}
              >
                {activeMembership.product?.name || activeMembership?.metadata?.name || '-'}
              </Text>
              <View
                style={{
                  borderRadius: 8,
                  backgroundColor: '#EFF6FF',
                  paddingHorizontal: 8,
                  paddingVertical: 4,
                }}
              >
                {(activeMembership.plan?.amount &&
                activeMembership.plan?.interval) ? (
                  <Text
                    fontSize={14}
                    fontWeight={500}
                    color={Colors.Custom.Blue700}
                    size="smBold"
                  >
                    {`$${activeMembership.plan?.amount / 100} / ${
                      activeMembership.plan?.interval
                    }`}
                  </Text>
                ) : activeMembership?.amount ? <Text
                    fontSize={14}
                    fontWeight={500}
                    color={Colors.Custom.Blue700}
                    size="smBold"
                  >
                  {`$${activeMembership?.metadata?.priceInCents/ 100} One time`}
                </Text> : null}
              </View>
            </HStack>
            {activeMembership?.metadata?.oneTime !== 'true' && <HStack flex={1} justifyContent="space-between" alignItems={'center'}>
              <Text
                fontSize={12}
                size="mdNormal"
                color={Colors.Custom.Gray500}
              >{`${ConvertUnixTimestamp(
                activeMembership?.current_period_start,
                'MMM DD, yyyy'
              )} - ${ConvertUnixTimestamp(
                activeMembership?.current_period_end,
                'MMM DD, yyyy'
              )}`}</Text>
              <Text
                fontSize={14}
                fontWeight={400}
                size="smMedium"
                color={Colors.danger[500]}
              >
                {`${moment.unix(activeMembership.current_period_end).isAfter(moment()) ? 'Expiring': 'Expired'} ${moment
                  .unix(activeMembership.current_period_end)
                  .fromNow()}`}
              </Text>
            </HStack>}
          </VStack>
        </HStack>
      </VStack>
      {!props.isLast && (
        <View
          style={{
            width: '100%',
            height: 1,
            backgroundColor: Colors.Custom.Gray200,
          }}
        />
      )}
    </VStack>
  );
};

export default MembershipCard;
