export const NPS = (props: {color?: string, size?: number}) => {
  const color = props?.color ? props.color : '#667085';
  const size = props?.size ? props?.size : 24;
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.6736 14.1582L9.56644 11.2653L12.4593 17.3725L13.1022 14.801L15.6736 14.1582Z" fill={color} stroke={color} stroke-width="0.642857" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M21.9333 18.5702C22.4912 17.1442 22.7297 15.6133 22.6317 14.0852C22.5337 12.5571 22.1017 11.0691 21.3662 9.72615L19.4496 10.7758C20.0316 11.8385 20.3734 13.0159 20.4509 14.225C20.5284 15.4341 20.3398 16.6456 19.8983 17.7739L21.9333 18.5702Z" fill={color}/>
      <path d="M19.9051 7.68484C18.713 6.38298 17.2112 5.40367 15.5391 4.83795C13.867 4.27222 12.079 4.13846 10.3414 4.44908C8.60376 4.75971 6.97287 5.50465 5.60035 6.61464C4.22783 7.72463 3.15825 9.16363 2.49105 10.7979L4.51418 11.6238C5.04212 10.3307 5.88845 9.19208 6.97449 8.31378C8.06052 7.43547 9.351 6.84602 10.7259 6.60023C12.1009 6.35444 13.5157 6.46029 14.8387 6.90793C16.1618 7.35557 17.3502 8.13047 18.2935 9.1606L19.9051 7.68484Z" fill={color}/>
      <path d="M1.72577 14.2663C1.63134 16.2866 2.12415 18.2911 3.14464 20.0374L5.03135 18.9348C4.22387 17.5531 3.83392 15.967 3.90864 14.3683L1.72577 14.2663Z" fill={color}/>
    </svg>
  );
};
