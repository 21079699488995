import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {Drawer, notification} from 'antd';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {Box, Divider, FormControl, HStack, Icon, IconButton, InfoOutlineIcon, Spacer, Spinner, Text, useToast, View, VStack} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {BUTTON_TYPE, DASHBOARD_PERMISSION_ACTION, DATE_FORMATS } from '../../../../../../constants';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../../constants/Configs';
import {
  ICalendarWidgetMetaState,
  ILocation,
  IUser
} from '../../../../../../Interfaces/CommonInterfaces';
import { AppointmentQueries, UserQueries } from '../../../../../../services';
import { GetScheduleAccessByUserId } from '../../../../../../services/UserScheduleAccess/UserScheduleAccessQueries';
import { Colors } from '../../../../../../styles';
import { getAccountUUID, getBooleanFeatureFlag, getLocationGroupIdFromLocationId, getUserUUID, isVirtualLocationDisabled, isVirtualLocationEnabledInAvailability } from '../../../../../../utils/commonUtils';
import {
  getCurrentTimeZone,
  getDateObject,
  getMomentObj,
  getStartOfDayOfTimezone,
  isBetweenDate,
  replaceTimezone
} from '../../../../../../utils/DateUtils';
import {IScheduleUserResponse} from '../../../../../RightSideContainer/UserScheduleSetting/interfaces';
import {DisplayText} from '../../../../DisplayText/DisplayText';
import FHDrawerAlert from '../../../../FHDrawerAlert/FHDrawerAlert';
import {ModalActionAntSelect} from '../../../../ModalActionCommonComponent/ModalActionAntSelect';
import {ModalActionDatePicker} from '../../../../ModalActionCommonComponent/ModalActionDatePicker';
import {ModalActionInput} from '../../../../ModalActionCommonComponent/ModalActionInput';
import {ModalActionSelect} from '../../../../ModalActionCommonComponent/ModalActionSelect';
import {ModalActionTitle} from '../../../../ModalActionTitle/ModalActionTitle';
import { CalendarSlot, IAppointmentDetail, ICalendarWidgetMetaData } from '../../../CalendarWidgetInterfaces';
import {processCalendarWidgetUsersData} from '../../../CalendarWidgetUtils';
import {IUserPracticeLocation} from '../AppointmentBooking/AppointmentBookingIntefaces';
import {IBlockSlotData, IBlockTimeData, IBlockTimeInput} from './BlockTimeInterfaces';
import { getInvalidBlockDatesError, isInvalidData, isTimeLessThanOrMoreThanLimit } from './BlockTimeHelper';
import { TimezoneSelect } from '../../../../TimezoneSelect/TimezoneSelect';
import { ITimezone } from '../../../../../../services/Location/interfaces';
import { v4 as uuidV4 } from 'uuid';
import { GET_BLOCK_SLOTS_FOR_DATE_RANGE } from '../../../../../../services/Schedule/ScheduleQueries';
import { CommonDataContext } from '../../../../../../context/CommonDataContext';
import { APPOINTMENT_STATUS_CODES, LOCATION_TYPE_CODES, MLOV_CATEGORY, USER_ROLE_CODES } from '../../../../../../constants/MlovConst';
import {FoldButton} from '../../../../../CommonComponents/FoldButton/FoldButton';
import PlusIcon from '../../../../Svg/PlusSvg';
import Feather from 'react-native-vector-icons/Feather';
import { filterNonDeletedLocationUser } from '../../BookAppointment/BookAppointmentHelper';
import { getEhrConfig, getResourceAbilities } from '../../../../../../utils/capabilityUtils';
import { CapabilityResource } from '../../../../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper';
import { getMlovIdFromCode, getMlovListFromCategory } from '../../../../../../utils/mlovUtils';
import useReccuringEvent from '../AppointmentBooking/hooks/useReccuringEvent/useReccuringEvent';
import ReccuringEventView from '../AppointmentBooking/ReccuringEventView/ReccuringEventView';
import { ReccuringEventType } from '../AppointmentBooking/ReccuringEventView/Interfaces';
import { AppointmentAction } from '../AppointmentBooking/AppointmentBookingEnums';
import { ToastType, showToast } from '../../../../../../utils/commonViewUtils';
import useEHRCapabilities from '../../../../../../screens/BusinessStudio/useEHRCapabilities';
import FeatureFlags from '../../../../../../constants/FeatureFlags.enums';
import { usePermissions } from '../../../../../CustomHooks/usePermissions';
import { USER_ACCESS_PERMISSION } from '../../../../../RightSideContainer/UserAccess/UserAccessPermission';

export interface IBlockSlotError {
  index: string,
  message: string,
}

const BlockTimeBooking = (props: {
  slotSelected: CalendarSlot;
  onCancel: () => void;
  onComplete: (data?: any) => void;
  userUUID: string | undefined;
  locationId?: string | undefined;
  isVisible: boolean;
  details?: IAppointmentDetail;
  editType?: AppointmentAction
}) => {
  const toast = useToast();
  const intl = useIntl();
  const [errors, setErrors] = useState<{
    showErrors: boolean;
    blockTimeWiseError: IBlockSlotError[];
  }>({
    showErrors: false,
    blockTimeWiseError: [],
  });
  const ehrConfig = getEhrConfig('', '');

  const [localErrors, setLocalErrors] = useState<{
    showErrors: boolean;
    blockTimeWiseError: IBlockSlotError[];
  }>({
    showErrors: false,
    blockTimeWiseError: [],
  });

  const defaultBlockTime = {
    selectedLocationIds: props?.details?.isBlockedInterval ? props?.details?.isVirtualLocation ? ['VIRTUAL_LOCATION'] : [props?.details?.accountLocationId || ''] : [],
    startDateTime: undefined,
    endDateTime: undefined,
  };
  const ALLOW_MULTIPLE_BLOCK_TIMES = false
  const commonData = useContext(CommonDataContext);
  const userSettings = commonData.userSettings;
  const isMultiTenancyEnabled = getBooleanFeatureFlag(commonData.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED);

  const scheduleLocationTypeList = getMlovListFromCategory(commonData.CARE_STUDIO_MLOV, MLOV_CATEGORY.SCHEDULE_LOCATION_TYPE) || [];
  const LOCATION_TYPE_STATUS_IDS = {
    atClinic: getMlovIdFromCode(scheduleLocationTypeList, LOCATION_TYPE_CODES.AT_CLINIC),
    virtual: getMlovIdFromCode(scheduleLocationTypeList, LOCATION_TYPE_CODES.VIRTUAL),
  };
  const appointmentStatusList =
    getMlovListFromCategory(
      commonData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.APPOINTMENT_STATUS
    ) || [];
  const APPOINTMENT_STATUS_IDS = {
    scheduled: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.SCHEDULED
    )
  };

  const MINUTE_STEP_BLOCK_TIME = 5;
  const [formDataState, setFormDataState] = useState<IBlockTimeData>({
    name: '',
    participants: [],
    selectedUserId: '',
    userPracticeLocations: [],
    blockTimes: [],
    isLoading: false,
    selectedLocationIds:[]
  });

   const {check} = usePermissions();
   const permissionConfig = check(
     USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
     DASHBOARD_PERMISSION_ACTION.CALENDAR
   );
 
   const allowedLocationIdsForLoggedInUser = permissionConfig?.allowedLocationIds;

  const locationIds = formDataState.selectedLocationIds.filter(locationId => locationId !== 'VIRTUAL_LOCATION');
  const ehrCapabilities = useEHRCapabilities({locationId: locationIds.length ? locationIds[0] : ''});
  let disAllowVirtualLocation = isVirtualLocationEnabledInAvailability(userSettings) ? false : isVirtualLocationDisabled(userSettings, ehrCapabilities);
  if (isMultiTenancyEnabled) {
    disAllowVirtualLocation = true;
  }

  const selectedLocation = ()=> {
    const timezone = getSelectedTimezone();
    const endDateTime = replaceTimezone(getStartOrEndDateTime(props?.details?.endDateTime) as Date, timezone);
    endDateTime.setSeconds(0);
    endDateTime.setMilliseconds(0);
    const startDateTime = replaceTimezone(getStartOrEndDateTime(props?.details?.startDateTime) as Date, timezone);
    startDateTime.setSeconds(0);
    startDateTime.setMilliseconds(0);

    return {
      selectedLocationIds: props?.details?.isVirtualLocation ? ['VIRTUAL_LOCATION'] : [props?.details?.accountLocationId || ''],
      startDateTime: startDateTime,
      endDateTime: endDateTime,
    }
  }

  const getBlockTimes = (isEdit?: boolean)=> {
    if (isEdit) {
      return [{ id: props?.details?.participants[0]?.id || uuidV4(), isEdit: true, ...selectedLocation()}]
    } else {
      return [{ id: uuidV4(), ...defaultBlockTime }]
    }
  }

  useEffect(()=>{
    if (props?.details?.isBlockedInterval) {
      setFormDataState((prev)=>{
        return {
          ...prev,
          name: props?.details?.name || '',
          participants: [],
          selectedUserId: props.userUUID,
          userPracticeLocations: [],
          blockTimes: getBlockTimes(true),
          isLoading: false,
          selectedLocationIds: props?.details?.isVirtualLocation ? ['VIRTUAL_LOCATION'] : [props?.details?.accountLocationId || '']
        }
      })
    } else {
      setFormDataState((prev)=>{
        return {
          ...prev,
          name: intl.formatMessage({id: 'blocked'}),
          participants: [],
          selectedUserId: props.userUUID,
          userPracticeLocations: [],
          blockTimes: getBlockTimes(),
          isLoading: false,
        }
      })
    }
  },[])

  const maxTimeForDuration = getResourceAbilities(
    CapabilityResource?.appointment,
    '',
    ''
  )?.keyAllowedOperations?.['minutesDuration']?.max;
  const minTimeForDuration = getResourceAbilities(
    CapabilityResource?.appointment,
    '',
    ''
  )?.keyAllowedOperations?.['minutesDuration']?.min;
  const [bookAppointment] = useMutation(AppointmentQueries.BOOK_APPOINTMENT);
  const loggedInUserId = getUserUUID();
  const accountUUID = getAccountUUID();
  const [userLocationQueryLoading, setUserLocationLoading] = useState(true);
  const [accountUserList, setAccountUserList] = useState<any[]>([]);
  const [calendarWidgetMetaData, setCalendarWidgetMetaData] =
    useState<ICalendarWidgetMetaState>({
      loading: true,
      accountUsers: [],
      accountLocations: [],
      locationUserMap: new Map<string, IUser[]>(),
      userLocationMap: new Map<string, ILocation[]>(),
      selectedLocation: {} as ILocation,
    });
  const allowToEditReccurenceConfig = props?.editType !== AppointmentAction.editBlockOccurence;
  const allowToToggleReccurence = !props?.details?.id;
  const [isReccuring,setIsReccuring] = useState(props?.details?.isRecurrentAppointment || false);
  const recurringBlockTimeConfig = useReccuringEvent(
    ReccuringEventType.BLOCKTIME,
    props?.details?.startDateTime
      ? getMomentObj(props?.details?.startDateTime)
      : getMomentObj(new Date()),
    !!props?.details?.id
      ? {
          ...props?.details?.reference?.recurrenceData,
          startDateTime: props?.details?.startDateTime,
          startAndEndTime: [
            getMomentObj(props?.details?.startDateTime, getSelectedTimezone()),
            getMomentObj(props?.details?.endDateTime, getSelectedTimezone()),
          ],
        }
      : undefined,
      props?.editType
  );
  const ALL_LOCATION_CODE = 'ALL_LOCATIONS';

  const resetContent = () => {
    setFormDataState((prev) => ({
      ...prev,
      name: intl.formatMessage({id: 'blocked'}),
      participants: [],
      selectedUserId: props.userUUID,
      userPracticeLocations: [],
      blockTimes: [ { id: uuidV4(), ...defaultBlockTime }],
      isLoading: false,
    }));
  };

  const submitData = () => {
    setErrors((prev) => ({ ...prev, showErrors: true }));
    let reccurenceData:any = {
      errors: false,
    }
    if(isReccuring){
      reccurenceData = recurringBlockTimeConfig.formatDataForSave(getSelectedTimezone());
    }
    if (!isInvalidData(isReccuring, formDataState, errors.blockTimeWiseError, reccurenceData,ALLOW_MULTIPLE_BLOCK_TIMES)) {
      if (formDataState.blockTimes?.length) {
        const blockTimeParamList = [];
        for (const blockTime of formDataState.blockTimes) {
          const selectedLocationIds = formDataState.selectedLocationIds;

          for (const locationId of selectedLocationIds) {
            const locationGroupId = getLocationGroupIdFromLocationId(commonData.accountLocationListWithEHR, locationId)
            const accountLocationId = getAccountLocationIdFromPracticeLocationId(
              locationId,
              formDataState.userPracticeLocations
            );
            const isVirtualLocation = locationId === 'VIRTUAL_LOCATION';
            const timezone = getSelectedTimezone();
            const endDateTime = replaceTimezone(blockTime.endDateTime as Date, timezone);
            endDateTime.setSeconds(0);
            endDateTime.setMilliseconds(0);

            const startDateTime = replaceTimezone(blockTime.startDateTime as Date, timezone);
            startDateTime.setSeconds(0);
            startDateTime.setMilliseconds(0);

            const bookAppointmentObj: any = {
              name: formDataState.name,
              startDateTime: isReccuring
                ? reccurenceData?.data?.startDateTime
                : startDateTime,
              endDateTime: isReccuring
                ? reccurenceData?.data?.endDateTime
                : endDateTime,
              participants: [{userId: formDataState.selectedUserId}],
              isBlockedInterval: true,
              userPracticeLocationId: isVirtualLocation ? null : locationId,
              accountLocationId: isVirtualLocation ? null : accountLocationId,
              isVirtualLocation: isVirtualLocation,
              locationTypeId: isVirtualLocation
                ? LOCATION_TYPE_STATUS_IDS.virtual
                : LOCATION_TYPE_STATUS_IDS.atClinic,
              statusId: APPOINTMENT_STATUS_IDS.scheduled,
              recurrenceData: isReccuring
                ? {
                    ...reccurenceData?.data,
                    endDateTime: undefined,
                    startDateTime: undefined,
                  }
                : undefined,
              isRecurrentAppointment: isReccuring,
              locationGroupId: locationGroupId,
              isApplyForSeries: !!props.details?.id
              ? props?.editType === AppointmentAction.editBlockSeries
              : undefined,
            }
            if (props?.details?.isBlockedInterval && blockTime?.isEdit) {
              bookAppointmentObj['id'] = props?.details?.id
              bookAppointmentObj['participants'] = [{userId: formDataState.selectedUserId, id: props?.details?.participants[0]?.id}]
            } else {
              bookAppointmentObj['participants'] = [{userId: formDataState.selectedUserId}]
            }
            blockTimeParamList.push(bookAppointmentObj);
          }
        }

        setFormDataState((prev) => ({ ...prev, isLoading: true }));
        bookAppointment({
          variables: {
            data: blockTimeParamList,
          },
          context: {service: CARESTUDIO_APOLLO_CONTEXT},
          onCompleted: (resp) => {
            setFormDataState((prev) => ({ ...prev, isLoading: false }));
            props.onComplete(resp?.createBookedAppointments);
          },
          onError: (error) => {
            setFormDataState((prev) => ({ ...prev, isLoading: false }));
            showToast(toast, intl.formatMessage({id: 'apiErrorMsg'}), ToastType.error);
          },
        });
      }
    } else {
      if(reccurenceData?.error){
        notification.error({message: 'Recurrence information is not valid. Please check.'});
      }
    }
  };

  const userDataPostProcessing = (
    userScheduleAccess: IScheduleUserResponse[]
  ) => {
    const widgetMetaData: ICalendarWidgetMetaData =
      processCalendarWidgetUsersData(
        userScheduleAccess,
        accountUserList,
        false,
        loggedInUserId
      );
    const selectedUserId = formDataState.selectedUserId
      ? formDataState.selectedUserId
      : loggedInUserId;

    onUserChangeHandler(
      selectedUserId,
      widgetMetaData.accountUsers,
      true
    );

    setCalendarWidgetMetaData((prev) => ({
      ...prev,
      accountUsers: widgetMetaData.accountUsers,
    }));
  };

  const findUserInAccountUsers = (
    accountUsers: IUser[],
    userId: string
  ): IUser => {
    return (
      (accountUsers || []).find((user) => {
        return user.uuid === userId;
      }) || ({} as IUser)
    );
  };

  const [getAccountUserLocation] = useLazyQuery(
    UserQueries.GET_USERS_FOR_CALENDAR_WITH_LOCATION,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data: any) => {
        const accountUsers: any[] = filterNonDeletedLocationUser(data?.users || []);
        setAccountUserList(accountUsers);
        setUserLocationLoading(false);
        setCalendarWidgetMetaData((prev) => ({
          ...prev,
          loading: false,
        }));
      },
      onError: (error) => {

        setUserLocationLoading(false);
        setCalendarWidgetMetaData((prev) => ({
          ...prev,
          loading: false,
        }));
        showToast(toast, intl.formatMessage({id: 'apiErrorMsg'}), ToastType.error);
      },
    }
  );

  const getScheduleAccessUsers = useQuery(GetScheduleAccessByUserId, {
    fetchPolicy: 'no-cache',
    skip: userLocationQueryLoading,
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    variables: {
      userId: loggedInUserId,
    },
    onCompleted: (data: any) => {
      userDataPostProcessing(data?.userScheduleAccesses);
    },
    onError: (error) => {

      setCalendarWidgetMetaData((prev) => ({
        ...prev,
        loading: false,
      }));
    },
  });

  const onUserChangeHandler = (
    userId: string,
    accountUsers: IUser[],
    firstCall?: boolean
  ) => {
    const selectedUser = findUserInAccountUsers(accountUsers, userId);
    const userPracticeLocations = selectedUser?.userPracticeLocations?.map(item => ({
      ...item,
      uuid: item.accountLocation?.uuid
    })) || [];
    if (!disAllowVirtualLocation && userPracticeLocations?.length) {
      userPracticeLocations.push({uuid: 'VIRTUAL_LOCATION', name: 'Virtual'} as any);
    }

    if (firstCall && props?.details?.isBlockedInterval) {
      setFormDataState((prev)=>{
        return {
          ...prev,
          selectedUserId: props.userUUID,
          userPracticeLocations: [
            {uuid: ALL_LOCATION_CODE, name: 'All Locations'},
            ...userPracticeLocations,
          ],
          blockTimes: getBlockTimes(true),
        }
      })
    } else {
      setFormDataState((prev) => ({
        ...prev,
        selectedUserId: userId,
        userPracticeLocations: [
          {uuid: ALL_LOCATION_CODE, name: 'All Locations'},
          ...userPracticeLocations,
        ],
        blockTimes: getBlockTimes(),
      }));
    }
  };

  const getAccountLocationIdFromPracticeLocationId = (
    practiceLocationId?: string,
    locations?: IUserPracticeLocation[]
  ) => {
    let selectedAccountLocationId = null;
    if (practiceLocationId && practiceLocationId !== 'VIRTUAL_LOCATION') {
      const userSelectedLocation = (locations || []).find(
        (userLocation: IUserPracticeLocation) => {
          return userLocation.uuid === practiceLocationId;
        }
      );

      if (userSelectedLocation && userSelectedLocation?.accountLocation?.uuid) {
        selectedAccountLocationId = userSelectedLocation?.accountLocation?.uuid;
      }
    }
    return selectedAccountLocationId;
  };

  const filterLocationArray = (locationArray?: any[]) => {
    const newLocationArray = (locationArray || [])?.filter(location => {
      if (location.uuid == 'VIRTUAL_LOCATION' || location?.uuid == ALL_LOCATION_CODE) {
        return true;
      }
      const locationId = location?.uuid || location?.accountLocation?.uuid;
      return allowedLocationIdsForLoggedInUser.includes(locationId);
    }).map((location: any) => {
      let obj: any = {};
      obj = {
        ...obj,
        name:
          location?.accountLocation?.practiceLocation?.name ||
          (location as any)?.name ||
          '',
        uuid: location.uuid,
      };
      return obj;
    });
    return newLocationArray;
  };

  function getSelectedTimezone() {
    return formDataState.selectedTimezone?.timezone || getCurrentTimeZone();
  }

  useEffect(() => {
    getAccountUserLocation({
      variables: {
        accountId: accountUUID,
        roleCode: USER_ROLE_CODES.EMPLOYER,
      },
    });
  }, []);

  const handleErrorMessage = (prevBlockMessage: IBlockSlotError[], selectedBlock?: IBlockTimeInput, errorMessage?: string) => {
    const blockMessage: IBlockSlotError = (prevBlockMessage || []).find(blockSlotError => {
      if (blockSlotError.index == selectedBlock?.id) {
        if (errorMessage) {
          blockSlotError.message = errorMessage;
        }
        return true;
      }
    }) || {} as IBlockSlotError;
    if (blockMessage && !errorMessage) {
      prevBlockMessage = prevBlockMessage?.filter(block => block.index !== selectedBlock?.id)
    }
    if ((!blockMessage || !blockMessage.index) && errorMessage && selectedBlock?.id) {
      prevBlockMessage.push({ index: selectedBlock?.id, message: errorMessage } as IBlockSlotError);
    }
    return prevBlockMessage
  }

  const [getUserBlockSlots] = useLazyQuery(GET_BLOCK_SLOTS_FOR_DATE_RANGE, {
    fetchPolicy: 'no-cache',
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    onCompleted: (data: any) => {
      setFormDataState((prev) => {
        return {
          ...prev,
          isLoading: false,
        };
      });
      const blockSlots = data.getUserBlockSlots?.blockSlots;
      if (blockSlots?.length) {
        let errorMessage = '';
        notification.destroy();
        let isAppointmentError = false
        if (isAppointmentBookedInBlockSlots(blockSlots)) {
          errorMessage = `There is an appointment scheduled for this time period on one of the selected locations.`;
          isAppointmentError = true;
        } else {
          const locationName = getBlockedLocationData(blockSlots);
          errorMessage = `This time period is already blocked for ${locationName} location.`;
        }
        errorMessage && notification.warning({
          message: errorMessage,
          duration: 5.0,
        });
        if (isAppointmentError) {
          errorMessage = ''
        }
        setErrors(prev => {
          const prevBlockMessage: IBlockSlotError[] = prev.blockTimeWiseError || [];
          const selectedBlock = formDataState?.blockTimes?.find((block) => {
            return block?.id === formDataState?.selectedBlockSlotId
          })
          const minOrMaxError = isTimeLessThanOrMoreThanLimit(selectedBlock?.startDateTime, selectedBlock?.endDateTime, minTimeForDuration, maxTimeForDuration)
          if (selectedBlock && minOrMaxError) {
            errorMessage += errorMessage?.length === 0 ? minOrMaxError : `\n${minOrMaxError}`
          }
          const updatedBlockMessage = handleErrorMessage(prevBlockMessage, selectedBlock, errorMessage)
          return {
            ...prev,
            showErrors: true,
            blockTimeWiseError: updatedBlockMessage
          }
        });
      } else {
        setErrors(prev => {
          const prevBlockMessage: IBlockSlotError[] = prev.blockTimeWiseError || [];
          const selectedBlock = formDataState?.blockTimes?.find((block) => {
            return block?.id === formDataState?.selectedBlockSlotId
          })
          const minOrMaxError = isTimeLessThanOrMoreThanLimit(selectedBlock?.startDateTime, selectedBlock?.endDateTime, minTimeForDuration, maxTimeForDuration);
          const updatedBlockMessage = handleErrorMessage(prevBlockMessage, selectedBlock, minOrMaxError)
          return {
            ...prev,
            ...(minOrMaxError && { showErrors: true }),
            blockTimeWiseError: updatedBlockMessage
          }
        });
      }
    },
    onError: (error) => {

      setFormDataState((prev) => {
        return {
          ...prev,
          isLoading: false,
        };
      });
    },
  });

  const isBlockSlotHasErrorData = (slotId: string): boolean => {
    if (errors?.blockTimeWiseError?.length) {
      return (errors?.blockTimeWiseError || []).some(blockTimeSlotError => {
        return blockTimeSlotError.index === slotId;
      });
    }
    return false;
  };

  const getBlockSlotErrorMessage = (slotId: string): IBlockSlotError => {
    if (errors?.blockTimeWiseError?.length) {
      return (errors?.blockTimeWiseError || []).find(blockTimeSlotError => {
        return blockTimeSlotError.index === slotId;
      }) || {} as IBlockSlotError;
    }
    return {} as IBlockSlotError;
  };

  const isBlockSlotHasLocalErrorData = (slotId: string): boolean => {
    if (localErrors?.blockTimeWiseError?.length) {
      return (localErrors?.blockTimeWiseError || []).some(blockTimeSlotError => {
        return blockTimeSlotError.index === slotId;
      });
    }
    return false;
  };

  const getBlockSlotLocalErrorMessage = (slotId: string): IBlockSlotError => {
    if (localErrors?.blockTimeWiseError?.length) {
      return (localErrors?.blockTimeWiseError || []).find(blockTimeSlotError => {
        return blockTimeSlotError.index === slotId;
      }) || {} as IBlockSlotError;
    }
    return {} as IBlockSlotError;
  };

  const isAppointmentBookedInBlockSlots = (blockSlots: IBlockSlotData[]): boolean => {
    return blockSlots.some(blockSlot => {
      return blockSlot.isBlockedInterval === false;
    });
  };

  const getBlockedLocationData = (blockSlots: IBlockSlotData[]) => {
    const locationList: string[] = [];
    blockSlots.forEach(blockSlot => {
      if (blockSlot.isBlockedInterval) {
        if (blockSlot.accountLocationId) {
          const locationName = getAccountLocationNameById(blockSlot.accountLocationId);
          if (locationName && !locationList.includes(locationName)) {
            locationList.push(locationName)
          }
        } else if(blockSlot.isVirtualLocation && !locationList.includes('Virtual')) {
          locationList.push('Virtual');
        }
      }
    });
    return locationList.length ? locationList?.join(',') : '';
  };

  const getAccountLocationNameById = (accountLocationId: string) => {
    const practiceLocation = (formDataState.userPracticeLocations || []).find((practiceLocation: IUserPracticeLocation) => {
      return practiceLocation.accountLocation?.uuid === accountLocationId;
    });
    return practiceLocation?.accountLocation?.practiceLocation?.name || '';
  };

  const getDuplicateBlockTimeLocation = (locationIds: string[], index: number, blockSlotId: string) => {
    const duplicateLocationIds: string[] = [];
    ( formDataState.blockTimes || []).forEach((blockSlot: any) => {
      if (blockSlot?.id !== blockSlotId) {
        (locationIds || []).forEach(locationId => {
          if (isSameLocationExistInAnotherBlockSlot(blockSlot, locationId) && isDuplicateBlockTimeAddedForLocation(blockSlot, index)) {
            const accountLocationId = getAccountLocationIdFromPracticeLocationId(
              locationId,
              formDataState.userPracticeLocations
            );
            if (locationId === 'VIRTUAL_LOCATION' || accountLocationId)
              duplicateLocationIds.push(accountLocationId || locationId);
          }
        });
      }
    });
    return duplicateLocationIds;
  }
  const checkLocalBlockTimeDuplicate = () => {
    const prevBlockMessages: IBlockSlotError[] = [];
    (formDataState?.blockTimes || []).forEach((blockTime, blockIndex) => {
      const locationIds = formDataState.blockTimes[blockIndex]?.selectedLocationIds;
      const duplicateLocationIds = getDuplicateBlockTimeLocation(locationIds, blockIndex, blockTime.id);
      if (duplicateLocationIds?.length) {
        const locationNameList: string[] = [];
        for (const locationId of duplicateLocationIds) {
          if (locationId === 'VIRTUAL_LOCATION' && !locationNameList.includes('Virtual')) {
            locationNameList.push('Virtual');
          } else {
            const locationName = getAccountLocationNameById(locationId);
            if (locationName && !locationNameList.includes(locationName)) {
              locationNameList.push(locationName)
            }
          }
        }
        if (locationNameList?.length){
          const errorMessage = `This time period is already added for ${locationNameList.join(',')} location.`;
          prevBlockMessages.push({index: blockTime?.id, message: errorMessage} as IBlockSlotError)
        }
      }
    });
    if (!prevBlockMessages?.length) {
      submitData();
    } else {
      setLocalErrors(prev => {
        return {
          ...prev,
          showErrors: true,
          blockTimeWiseError: prevBlockMessages
        }
      });
    }
  }

  const isDuplicateBlockTimeAddedForLocation = (blockSlot: any, index: number): boolean => {
    const endDateTime = formDataState.blockTimes[index]?.endDateTime;
    const startDateTime = formDataState.blockTimes[index]?.startDateTime;
    if (endDateTime && startDateTime && blockSlot?.endDateTime && blockSlot?.startDateTime) {
      return isBetweenDate(endDateTime, blockSlot?.startDateTime, blockSlot?.endDateTime, '[]') &&
      isBetweenDate(startDateTime, blockSlot?.startDateTime, blockSlot?.endDateTime, '[]')
    }
    return false;
  };

  const isSameLocationExistInAnotherBlockSlot = (blockSlot: any, selectedLocationId: string) => {
    return (blockSlot?.selectedLocationIds || []).some((locationId: string) => {
      return selectedLocationId && selectedLocationId === locationId;
    });
  }

  const validateBlockTimeAndLocation = (index: number, blockSlotId?: string,currentLocationIds?: string[]) => {
    const selectedLocationIds = currentLocationIds || formDataState?.selectedLocationIds;
    if (!formDataState.blockTimes[index]?.endDateTime || !formDataState.blockTimes[index]?.startDateTime || !selectedLocationIds?.length) {
      setErrors(prev => {
        const prevBlockMessage: IBlockSlotError[] = prev.blockTimeWiseError || [];
        const blockMessages = (prevBlockMessage || []).filter(blockSlotError => {
            return blockSlotError.index !== blockSlotId || formDataState.selectedBlockSlotId;
        });
        return {
          ...prev,
          blockTimeWiseError: blockMessages
        }
      });
      return;
    }
    const timezone = getSelectedTimezone();
    const endDateTime = replaceTimezone((formDataState.blockTimes[index]?.endDateTime || new Date()), timezone);
    endDateTime.setSeconds(0);
    endDateTime.setMilliseconds(0);

    const startDateTime = replaceTimezone((formDataState.blockTimes[index]?.startDateTime || new Date()), timezone);
    startDateTime.setSeconds(0);
    startDateTime.setMilliseconds(0);

    const locationIds: any[] = [];
    (selectedLocationIds || []).forEach((locationId) => {
      if (locationId !== 'VIRTUAL_LOCATION') {
        const accountLocationId = getAccountLocationIdFromPracticeLocationId(
          locationId,
          formDataState.userPracticeLocations
        );
        locationIds.push(accountLocationId);
      }
    });
    const isVirtualLocation = (selectedLocationIds || []).some((locationId) => {
      return locationId === 'VIRTUAL_LOCATION';
    });

    const data: any = {
      startDateTime,
      endDateTime,
      timezone,
      isVirtualLocation: isVirtualLocation,
      locationIds,
      userId: formDataState.selectedUserId,
    }
    if (blockSlotId === props?.details?.participants[0]?.id) {
      data['appointmentId'] = props?.details?.id
    }
    getUserBlockSlots({
      variables: {
        data: data
      },
    });
  };

  const handleChangeLocation = (values: any) => {
    const selectedLocationIds: string[] = [];
    setFormDataState((prev) => {
      if (values && values?.length && values.includes(ALL_LOCATION_CODE)) {
        (formDataState.userPracticeLocations || []).forEach(location => {
          if (location.uuid !== ALL_LOCATION_CODE) {
            selectedLocationIds.push(location.uuid);
          }
        });
      } else {
        selectedLocationIds.push(...values)
      }
      return {
        ...prev,
        selectedLocationIds,
        blockTimes: prev.blockTimes.map(item => ({...item,selectedLocationIds: selectedLocationIds}))
      };
    });
    validateBlockTimeAndLocation(0,"",selectedLocationIds)
  }

  const getSubtitle = () => {
    if (!!props?.details?.id && !props.details?.isRecurrentAppointment) {
      return '';
    }
    if (props?.editType === AppointmentAction.editBlockSeries) {
      return 'Changes will be applicable for the entire series';
    } else if (props?.editType === AppointmentAction.editBlockOccurence) {
      return 'Changes will be applicable only for the current occurrence';
    }
    return '';
  }


  const isInvalidBlockTime = (blockTime: IBlockTimeInput) => {
    return (localErrors.showErrors &&
      isBlockSlotHasLocalErrorData(blockTime.id)) ||
    (errors.showErrors &&
      isBlockSlotHasErrorData(blockTime.id)) ||
      !!getInvalidBlockDatesError(blockTime)
  }

  const getStartOrEndDateTime = (value: any) => {
     if (!value) {
      return undefined;
     }
     const dateObj = getDateObject(value);
     const minutes = dateObj?.getMinutes() || 0;
     const remainMinute = minutes ?  minutes % MINUTE_STEP_BLOCK_TIME : 0;
     if (remainMinute) {
      dateObj.setMinutes(dateObj.getMinutes() - remainMinute);
     }
     return dateObj;
  }
  return (
    <Drawer
      keyboard={false}
      destroyOnClose
      placement="right"
      onClose={() => {
        props.onCancel();
        resetContent();
      }}
      visible={props.isVisible}
      closable
      width={750}
      title={
        <>
          <ModalActionTitle
            title={props?.details?.isBlockedInterval ? 'editBlockTime' : 'blockTimeDrawerTitle'}
            titleColor={''}
            buttonList={[
              {
                show: true,
                id: 1,
                btnText: 'cancel',
                size: 'sm',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
                isTransBtn: false,
                onClick: () => {
                  props.onCancel();
                  resetContent();
                },
              },
              {
                show: true,
                isLoading: formDataState.isLoading,
                id: 1,
                btnText: 'block',
                textColor: Colors.Custom.mainPrimaryPurple,
                variant: BUTTON_TYPE.PRIMARY,
                isTransBtn: false,

                onClick: () => {
                  setLocalErrors((prev) => {
                    return {
                      ...prev,
                      blockTimeWiseError: [],
                    };
                  });
                  checkLocalBlockTimeDuplicate();
                },
              },
            ]}
          />
           {getSubtitle().length > 0 &&
            <Text color={Colors.Custom.Gray500}>
              <Icon mr={2} as={AntIcon} color={Colors.Custom.Gray500} name="infocirlceo" size="smMedium" />{getSubtitle()}
            </Text>
            }
        </>
      }
    >
      <View mx={0}>
        {!getScheduleAccessUsers.loading && !calendarWidgetMetaData.loading && (
          <VStack space={4}>
            <View>
              <ModalActionInput
                fieldIs={'input'}
                label={'name'}
                isRequired={true}
                value={formDataState.name}
                errors={errors.showErrors && !formDataState.name}
                errorText={'Please enter name'}
                onChangeText={(text: string) => {
                  setFormDataState((prev) => ({...prev, name: text}));
                }}
                extraStyle={{flex: 1}}
              />
            </View>

            <VStack space={4}>
              <View>
                <ModalActionSelect
                  isDisabled={props?.details?.isBlockedInterval || !!props?.details?.id}
                  label={'selectUser'}
                  isRequired={true}
                  placeholder="Select User"
                  selectedValue={formDataState.selectedUserId || ''}
                  onValueChange={(value: string) => {
                    onUserChangeHandler(
                      value,
                      calendarWidgetMetaData.accountUsers
                    );
                  }}
                  data={calendarWidgetMetaData.accountUsers}
                  selectItemProps={{
                    key: 'uuid',
                    label: 'name',
                    value: 'uuid',
                  }}
                  customStyle={{flex: 1}}
                  errors={errors.showErrors && !formDataState.selectedUserId}
                  errorText={'Please select user'}
                />
              </View>

              <View flex={1}>
                <TimezoneSelect
                  isRequired={false}
                  isDisabled={!!props?.details?.id}
                  memorizeTimezone={props?.details?.isBlockedInterval? false : true}
                  onChange={(timezone?: ITimezone) => {
                    setFormDataState((prev: any) => ({
                      ...prev,
                      selectedTimezone: timezone,
                    }));
                  }}
                />
                <View>
                  <HStack space={1}>
                    <InfoOutlineIcon
                      style={{
                        color: Colors.info[700] as string,
                        marginTop: '3px',
                      }}
                      size="xs"
                    />
                    <DisplayText
                      extraStyles={{
                        color: Colors.info[700] as string,
                        fontSize: '13px',
                      }}
                      textLocalId="blockTimeSelectedTimezoneInfo"
                    />
                  </HStack>
                </View>
              </View>
              <View flex={3}>
                <HStack>
                <DisplayText
                  size={'smMedium'}
                  extraStyles={{
                    color: Colors.Custom.Gray700,
                    fontWeight: 500,
                    fontSize: 14,
                  }}
                  textLocalId={'blockTimeSelectLocations'}
                  />
                <Text color="error.500">*</Text>
                </HStack>
                <ModalActionAntSelect
                  disabled={!!props?.details?.id}
                  isDisabled={!!props?.details?.id}
                  allowClear={true}
                  mode={'multiple'}
                  // label={'blockTimeSelectLocations'}
                  isRequired={true}
                  value={formDataState.selectedLocationIds}
                  placeholder="Select Locations"

                  onChange={(values: any) => {
                    handleChangeLocation(values);
                  }}
                  data={filterLocationArray(
                    formDataState.userPracticeLocations
                  )}
                  optionProps={{
                    key: 'uuid',
                    label: 'name',
                    value: 'uuid',
                  }}
                  extraStyle={{flex: 1}}
                  customStyle={{height: 'auto'}}
                  errors={
                    errors.showErrors &&
                    !formDataState?.selectedLocationIds?.length
                  }
                  errorText={'Please select location'}
                />
                <HStack space={1}>
                  <InfoOutlineIcon
                    style={{
                      color: Colors.info[700] as string,
                      marginTop: '3px',
                    }}
                    size="xs"
                  />
                  <DisplayText
                    extraStyles={{
                      color: Colors.info[700] as string,
                      fontSize: '13px',
                    }}
                    textLocalId="blockTimeSelectLocationInfo"
                  />
                </HStack>
              </View>
              <Divider marginTop={2} />
              <ReccuringEventView
                allowedToEdit={allowToEditReccurenceConfig}
                allowedToToggle={allowToToggleReccurence}
                type={ReccuringEventType.BLOCKTIME}
                config={recurringBlockTimeConfig}
                isEnabled={isReccuring}
                onToggle={(value) => setIsReccuring(value)}
                editType={props?.editType}
              />
              {!isReccuring &&
              <VStack>
                {formDataState.blockTimes?.length > 0 && (
                  <View>
                    <HStack space={2} flex={10}>
                      <View flex={6}>
                        <HStack marginBottom={2} alignItems="center">
                          <DisplayText
                            size={'smMedium'}
                            extraStyles={{
                              color: Colors.Custom.Gray700,
                              fontWeight: 500,
                              fontSize: 14,
                            }}
                            textLocalId={'selectBlockStartAndEndTime'}
                          />
                          <Text color="error.500">*</Text>
                          <Text marginLeft={2} fontSize="xs" color="gray.500">
                            {`(${getSelectedTimezone()})`}
                          </Text>
                        </HStack>
                      </View>
                      <View flex={0.5}></View>
                    </HStack>
                  </View>
                )}
                {formDataState.blockTimes?.map((blockTime, index) => {
                  return (
                    <View marginBottom={4} key={blockTime.id}>
                      <HStack space={2} flex={10}>
                        <View flex={6}>
                          <FormControl
                            isRequired={true}
                            isInvalid={
                              (localErrors.showErrors &&
                                isBlockSlotHasLocalErrorData(blockTime.id)) ||
                              (errors.showErrors &&
                                isBlockSlotHasErrorData(blockTime.id)) ||
                                !!getInvalidBlockDatesError(blockTime)
                            }
                          >
                            <HStack space={2} alignItems="center" marginTop={-2}>
                              <ModalActionDatePicker
                                label=''
                                showTime
                                placeholder='Select start time'
                                value={blockTime.startDateTime ? getMomentObj(blockTime.startDateTime) : undefined}
                                format={`${DATE_FORMATS.DISPLAY_DATE_FORMAT} ${DATE_FORMATS.DISPLAY_TIME_FORMAT}`}
                                isInvalid={isInvalidBlockTime(blockTime)}
                                extraStyle={{flex: 1}}
                                isHideOkButton={true}
                                customStyle={{heigh: 42}}
                                minuteStep={MINUTE_STEP_BLOCK_TIME}
                                allowClear={false}
                                disabledDate={(date: any) => {
                                  const currentDate = getStartOfDayOfTimezone(
                                    new Date(),
                                    getSelectedTimezone()
                                  );
                                  return date && date < currentDate;
                                }}
                                onChange={(startDate) => {
                                }}
                                onSelect={(value: any) => {
                                  setFormDataState((prev) => {
                                    const blockTimes = prev.blockTimes;
                                    blockTimes[index].startDateTime = value
                                      ? getStartOrEndDateTime(value)
                                      : undefined;
                                    const data = {
                                      ...prev,
                                      blockTimes: [...blockTimes],
                                      selectedBlockSlotId: blockTime.id,
                                    };
                                    return data;
                                  });
                                  validateBlockTimeAndLocation(
                                    index,
                                    blockTime.id
                                  );
                                }}
                              />
                              <Feather name="arrow-right" size={16} color={Colors.Custom.Gray400} />
                              <ModalActionDatePicker
                                label=''
                                showTime
                                placeholder='Select end time'
                                value={blockTime.endDateTime ? getMomentObj(blockTime.endDateTime) : undefined}
                                format={`${DATE_FORMATS.DISPLAY_DATE_FORMAT} ${DATE_FORMATS.DISPLAY_TIME_FORMAT}`}
                                isInvalid={isInvalidBlockTime(blockTime)}
                                extraStyle={{flex: 1}}
                                isHideOkButton={true}
                                customStyle={{heigh: 42}}
                                minuteStep={MINUTE_STEP_BLOCK_TIME}
                                allowClear={false}
                                disabledDate={(date: any) => {
                                  const currentDate = getStartOfDayOfTimezone(
                                    new Date(),
                                    getSelectedTimezone()
                                  );
                                  return date && date < currentDate;
                                }}
                                onChange={(endDate) => {
                                }}
                                onSelect={(value: any) => {
                                  setFormDataState((prev) => {
                                    const dateObj = getDateObject(value);
                                    const blockTimes = prev.blockTimes;
                                    blockTimes[index].endDateTime = value
                                      ? getStartOrEndDateTime(value)
                                      : undefined;
                                    const data = {
                                      ...prev,
                                      blockTimes: [...blockTimes],
                                      selectedBlockSlotId: blockTime.id,
                                    };
                                    return data;
                                  });
                                  validateBlockTimeAndLocation(
                                    index,
                                    blockTime.id
                                  );
                                }}
                              />
                            </HStack>
                            {errors.showErrors &&
                              !!getInvalidBlockDatesError(blockTime) && (
                                <FormControl.ErrorMessage
                                  _text={{
                                    fontSize: 'xs',
                                    color: 'error.500',
                                    fontWeight: 500,
                                  }}
                                >
                                  {getInvalidBlockDatesError(blockTime)}
                                </FormControl.ErrorMessage>
                              )}
                            {errors.showErrors &&
                              !getInvalidBlockDatesError(blockTime) && isBlockSlotHasErrorData(blockTime.id) && (
                                <FormControl.ErrorMessage
                                  _text={{
                                    fontSize: 'xs',
                                    color: 'error.500',
                                    fontWeight: 500,
                                  }}
                                >
                                  {`${
                                    getBlockSlotErrorMessage(blockTime.id)
                                      ?.message ||
                                    'This time period is already blocked for selected locations.'
                                  }`}
                                </FormControl.ErrorMessage>
                              )}

                            {localErrors.showErrors &&
                              isBlockSlotHasLocalErrorData(blockTime.id) && (
                                <FormControl.ErrorMessage
                                  _text={{
                                    fontSize: 'xs',
                                    color: 'error.500',
                                    fontWeight: 500,
                                  }}
                                >
                                  {`${
                                    getBlockSlotLocalErrorMessage(blockTime.id)
                                      ?.message ||
                                    'This time period is already blocked for selected locations.'
                                  }`}
                                </FormControl.ErrorMessage>
                              )}
                          </FormControl>
                        </View>
                        <View flex={0.5}>
                          <HStack>
                            <Spacer />
                            {formDataState.blockTimes?.length !== 1 && (
                              <View alignSelf={'flex-end'} marginTop={1}>
                                <IconButton
                                  colorScheme="gray"
                                  tintColor="gray.500"
                                  variant="ghost"
                                  onPress={() => {
                                    setFormDataState((prev) => {
                                      prev.blockTimes.splice(index, 1);
                                      return {
                                        ...prev,
                                        blockTimes: [...prev.blockTimes],
                                      };
                                    });
                                  }}
                                  icon={
                                    <Icon
                                      as={AntIcon}
                                      name="closecircle"
                                      size="4"
                                      color="gray.400"
                                    />
                                  }
                                />
                              </View>
                            )}
                          </HStack>
                        </View>
                      </HStack>
                    </View>
                  );
                })}
              </VStack>
              }
              <Divider marginTop={2} />
              {ALLOW_MULTIPLE_BLOCK_TIMES &&
              <HStack>
                <Spacer />
                <View>
                  <FoldButton
                    nativeProps={{
                      variant: BUTTON_TYPE.PRIMARY,
                      onPress: () => {
                        setFormDataState((prev) => ({
                          ...prev,
                          blockTimes: [
                            ...prev.blockTimes,
                            {id: uuidV4(), ...defaultBlockTime},
                          ],
                        }));
                      },
                      leftIcon: <PlusIcon />
                    }}
                    customProps={{
                      btnText: (
                        <DisplayText
                          textLocalId={'addMoreBlockTime'}
                          size={'smBold'}
                          extraStyles={{
                            color: Colors.Custom.mainPrimaryPurple,
                          }}
                        />
                      ),
                      withRightBorder: false,
                    }}
                  ></FoldButton>
                </View>
              </HStack>
              }

            </VStack>
          </VStack>
        )}
        {(getScheduleAccessUsers.loading ||
          calendarWidgetMetaData.loading ||
          formDataState.isLoading) && (
          <Spinner
            size="lg"
            position="absolute"
            alignSelf="center"
            top="50%"
            left="50%"
          />
        )}
      </View>
    </Drawer>
  );
};

export default BlockTimeBooking;
