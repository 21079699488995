import React, {useState} from 'react';
import {Modal} from 'antd';
import {Colors} from '../../../styles';
import {BUTTON_TYPE} from '../../../constants';
import {HStack, Text, VStack} from 'native-base';
import {FoldButton} from '../../CommonComponents/FoldButton/FoldButton';
import {useIntl} from 'react-intl';
import './styles.scss';

type RecordListItem = any;
export interface IRecordList extends RecordListItem {
  isFreeTextRecord: boolean;
}

const PatientIntakeConfirm = (props: {
  onCancel: () => void;
  onOk: () => void;
  title: string;
  renderRecords: () => JSX.Element;
  acceptCount: number
}) => {
  const {onCancel, onOk, title} = props;
  const intl = useIntl();

  const buttonList = [
    {
      show: true,
      id: 1,
      btnText: 'cancel',
      variant: BUTTON_TYPE.SECONDARY,
      isTransBtn: false,
      onClick: onCancel,
    },
    {
      show: props.acceptCount > 0,
      id: 2,
      btnText: `Accept ${props.acceptCount} Matched`,
      isTransBtn: false,
      onClick: onOk,
      variant: BUTTON_TYPE.PRIMARY,
    },
  ];

  return (
    <Modal
      open
      closable={false}
      onCancel={props?.onCancel}
      footer={null}
      bodyStyle={{
        maxHeight: '70vh',
      }}
    >
      <VStack h="100%" flex={1} maxH={'65vh'}>
        <Text mb={1} fontSize={'xl'} fontWeight={'bold'}>
          {title}
        </Text>
        <div
          className="custom-modal-styles"
          style={{
            overflowY: 'scroll',
            flex: 1,
          }}
        >
          {props.renderRecords()}
        </div>
        <HStack mt={5} justifyContent={'flex-end'}>
          {buttonList.map((button: any, index: number) => {
            if (button.show) {
              return (
                <FoldButton
                  key={index + button.btnText}
                  nativeProps={{
                    variant: button.variant,
                    onPress: () => {
                      button.onClick();
                    },
                    leftIcon: button.leftIcon,
                    rightIcon: button.rightIcon,
                    isDisabled: button.isDisabled,
                    isLoading: button.isLoading,
                    marginBottom: 1,
                    marginTop: 1,
                    marginLeft: 2,
                  }}
                  customProps={{
                    btnText: intl.formatMessage({id: button.btnText}),
                    withRightBorder: false,
                  }}
                ></FoldButton>
              );
            }
          })}
        </HStack>
      </VStack>
    </Modal>
  );
};

export default PatientIntakeConfirm;
