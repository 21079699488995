import {ICallTrailWidgetNames} from './interfaces';

export const WIDGET_NAME_TYPES = {
  ANSWERED_CALL_USER: 'ANSWERED_CALL_USER',
  DIAL: 'DIAL',
  MENU: 'MENU',
  SAY: 'SAY',
  INCOMING_CALL: 'INCOMING_CALL',
  MEMBER_INPUT: 'MEMBER_INPUT',
  PHONE_TREE: 'PHONE_TREE',
  ABANDONED_CALL: 'ABANDONED_CALL',
  RECORD: 'RECORD',
  PLAY: 'PLAY',
  PATIENT_TYPE: 'PATIENT_TYPE'
} as const;

export const CALL_TRAIL_WIDGET_NAMES: Record<
  keyof typeof WIDGET_NAME_TYPES,
  ICallTrailWidgetNames
> = {
  ANSWERED_CALL_USER: 'answeredCallUser',
  DIAL: 'dial',
  INCOMING_CALL: 'incomingCall',
  MEMBER_INPUT: 'memberInput',
  MENU: 'menu',
  PHONE_TREE: 'phoneTree',
  SAY: 'say',
  ABANDONED_CALL: 'abandonedCall',
  RECORD: 'record',
  PLAY: 'play',
  PATIENT_TYPE: 'patientType'
};
