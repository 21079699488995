import React from 'react';
import ReactDOM from 'react-dom';
import {ReactComponent} from '@foldhealth/formio-react';
import {Formio} from '@foldhealth/formiojs';
import CustomWrapper from '../CustomWrapper/CustomWrapper';
import KeyField from '../../EditFormFields/KeyField';
import ConditionalComponents from '../../EditFormFields/ConditionalFields';
import CustomClassComponent from '../CustomWrapper/CustomClassComponent';
import QuillConfig from '../../Builder/QuillConfig';
import MultiSelectCheckBoxes from './MultiSelectCheckBoxes';
import NativePromise from 'native-promise-only';
import ShareWithPatientFields from '../../EditFormFields/ShareWithPatientFields';

export default class CheckBoxes extends CustomClassComponent {
  static get builderInfo() {
    return {
      title: 'Check Boxes',
      icon: 'file-text',
      group: 'advanced',
      documentation: '',
      schema: CheckBoxes.schema(),
    };
  }

  static schema() {
    return ReactComponent.schema({
      type: 'checkBoxes',
      label: 'Check Boxes',
      key: 'checkBoxes',
    });
  }

  labelIsHidden() {
    return false;
  }

  hasChanged(newValue: any, oldValue: any) {
    const isChanged = super.hasChanged(newValue, oldValue);
    this.isComponentValueChanged = isChanged;
    return isChanged;
  }

  checkComponentValidity(data: any, dirty: any, row: any, options: any = {}) {
    data = data || this.rootValue;
    const {async = false} = options;
    const inputData = data[this.component.key];

    if (this.shouldSkipValidation(data, dirty, row)) {
      this.setCustomValidity('');
      return async ? NativePromise.resolve(true) : true;
    }

    if (
      this.component.validate.required &&
      this.isComponentValueChanged &&
      inputData &&
      this.validateRef?.current
    ) {
      return this.validateRef.current(inputData);
    }

    return super.checkComponentValidity(data, dirty, row, options);
  }

  attachReact(element: any) {
    ReactDOM.render(
      <CustomWrapper formOptionData={this.options}>
        <MultiSelectCheckBoxes
          options={this.options}
          disabled={this.disabled}
          component={this.component}
          validateRef={this.validateRef}
          onChange={this.updateValue}
          setValueRef={this.setValueRef}
        />
      </CustomWrapper>,
      element
    );
  }

  static editForm = function () {
    return {
      key: 'display',
      components: [
        {
          type: 'oldtextfield',
          key: 'label',
          label: 'Label',
          input: true,
          validate: {
            required: true,
          },
        },
        ...ShareWithPatientFields,
        {
          type: 'textarea',
          input: true,
          key: 'description',
          label: 'Description',
          placeholder: 'Description for this field.',
          tooltip:
            'The description is text that will appear below the input field.',
          editor: 'quill',
          wysiwyg: QuillConfig,
        },
        ...ConditionalComponents,
        ...KeyField,
      ],
    };
  };
}

Formio.Components.addComponent('checkBoxes', CheckBoxes);
