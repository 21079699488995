

import {useLazyQuery} from '@apollo/client';
import React, {useContext, useEffect, useState} from 'react';
import {COMMON_ACTION_CODES} from '../../../../../../../../../../constants/ActionConst';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../../../../../../constants/Configs';
import {CommonDataContext} from '../../../../../../../../../../context/CommonDataContext';
import {IAppointmentData} from '../../../../../../../../../../services/Appointment/AppointmentInterface';
import AppointmentQueries from '../../../../../../../../../../services/Appointment/AppointmentQueries';
import {isWeb} from '../../../../../../../../../../utils/platformCheckUtils';
import AppointmentDetail from '../../../../../../../../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentDetail';
import {IFeedData} from '../../../interface';
import {IApptFeedMainResourceData, IAppointmentFeedData} from './AppointmentInterface';

const AppointmentActivity = (props: {
  singleFeedData: IFeedData,
  navigation?: any
  onActionPerformed: (actionCode: string, actionData?: any) => void;
}) => {
  const {singleFeedData, onActionPerformed} = props;

  const [componentState, setComponentState] = useState({
    isDrawerVisible: false,
    isLoading: true,
    selectedAppointmentData: {} as any
  });

  let appointmentResourceData = {} as IApptFeedMainResourceData;
  let appointmentData = {} as IAppointmentFeedData;
  try {
    const feedData = singleFeedData.data;
    const crmAppointmentData = JSON.parse(feedData);
    appointmentResourceData = crmAppointmentData?.crmData;
    appointmentData = appointmentResourceData?.resourceData?.newData;
  } catch (err) {
    appointmentResourceData = {} as IApptFeedMainResourceData;
  }

  const [getBookedAppointments] = useLazyQuery<IAppointmentData>(
    AppointmentQueries.GET_BOOKED_APPOINTMENTS_BY_ID,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  const getAppointmentDataFromAPI = async () => {
    let appointmentResourceData = {} as IApptFeedMainResourceData;
    let appointmentData = {} as IAppointmentFeedData;
    try {
      const feedData = singleFeedData.data;
      const crmAppointmentData = JSON.parse(feedData);
      appointmentResourceData = crmAppointmentData?.crmData;
      appointmentData = appointmentResourceData?.resourceData?.newData;
      if (appointmentResourceData?.resourceData?.id) {
        setComponentState((prev) => {
          return {
            ...prev,
            isDrawerVisible: true,
            isLoading: false,
            selectedAppointmentData: {
              ...appointmentResourceData.resourceData
            }
          }
        });
      } else if (appointmentData?.id) {
        const appointmentRespData = await getBookedAppointments({
          variables: {
            id: appointmentData?.id
          }
        });

        if (appointmentRespData?.data?.appointments?.length) {
          setComponentState((prev) => {
            return {
              ...prev,
              isDrawerVisible: true,
              isLoading: false,
              selectedAppointmentData: appointmentRespData?.data?.appointments[0]
            }
          });
        }
      }
    } catch (err) {
      appointmentResourceData = {} as IApptFeedMainResourceData;
    }

  }

  useEffect(() => {
    getAppointmentDataFromAPI();
  }, [])

  const onAppointmentMainViewActionPerformed = (actionCode: string, actionData?: any) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.CLOSE_MODAL:
        onActionPerformed(COMMON_ACTION_CODES.CLOSE_MODAL);
        setComponentState((prev) => {
          return {
            ...prev,
            isDrawerVisible: false
          }
        });
        break;
    }
  }

  const getAppointmentDetailsElem = (): JSX.Element => {
    try {
      return (
        <>
          {
            isWeb() && componentState.isDrawerVisible &&
            <AppointmentDetail
              event={{
                detail: componentState.selectedAppointmentData,
                id: componentState.selectedAppointmentData?.id
              }}
              isVisible={
                true
              }
              hideAction={true}
              isAppointmentSummary={true}
              onStatusChange={() => {
                // onStatusChange
              }}
              onClose={() => {
                onAppointmentMainViewActionPerformed(COMMON_ACTION_CODES.CLOSE_MODAL);
              }}
            />

          }

        </>
      );
    } catch (error) {

    }
    return <></>

  };
  if (appointmentData?.id || appointmentResourceData?.resourceData?.id) {
    return getAppointmentDetailsElem()

  } else {
    return <></>;
  }
};

export default React.memo(AppointmentActivity);
