
import {useLazyQuery} from '@apollo/client';
import {Empty, Select, Spin, Tag} from 'antd';
import {debounce} from 'lodash';
import {HStack, Text, VStack, View} from 'native-base';
import {useEffect, useState} from 'react';
import {IUser} from '../../../../Interfaces/CommonInterfaces';
import {GROUP_MEMBER_TYPE} from '../../../../constants/StringConst';
import UserQueries, {GET_USERS_WITH_IDS, SEARCH_USER_BY_TEXT} from '../../../../services/User/UserQueries';
import {Colors} from '../../../../styles/Colors';
import {getAccountId, getAccountUUID, getAgentsList, isLoginUserBusinessOwner} from '../../../../utils/commonUtils';
import {DisplayCardAvatar} from '../../DisplayCard/DisplayCardAvatar';
import {formatAndCombinePrevAndNewOptions} from './utils';
import { isAccountConfigEnabled } from '../../../../utils/configUtils';
import { CONFIG_CODES } from '../../../../constants/AccountConfigConst';
import { USER_SEARCH_TYPE } from '../../../RightSideContainer/constants';
import { GLOBAL_ROLE_CODES } from '../../../../constants/MlovConst';

export interface IMultiUserSelectProps {
  selectedValue?: any;
  showError?: boolean;
  placeholder?: string;
  selectedUserMap?: {
    key: string,
    value: string,
    label: string,
  }[],
  userProfileImageMap?: {[index: string]: string};
  isDisabled?: boolean;
  userSearchType?: string;
  locationIds?: string[];
  onChange?: (userIds: any, userMap: any) => void;
}

export const MultiUserSearch = (props: IMultiUserSelectProps) => {
  const {
    selectedValue,
    onChange,
    placeholder,
    showError,
    isDisabled,
    locationIds,
    userSearchType
  } = props;

  const accountId = getAccountId();
  const accountUUid = getAccountUUID();
  const isBusinessOwner = isLoginUserBusinessOwner();
  const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED)
  const [userLoading, setUserLoading] = useState(false);
  const [staffData, setStaffData] = useState<any[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<
    any[]
  >([]);
  const [userProfileImageMap, setUserProfileImageMap] = useState<{[index: string]: string;}>();

  const userboolExp = userSearchType === USER_SEARCH_TYPE.SELECTED_LOCATION_BASED
      ? {
        _or: [
          {
            userPracticeLocations: {
              isDeleted: {
                _eq: false
              },
              accountLocation: {
                uuid: {
                  _in: locationIds,
                }
              },
            },
          },
          ...(isMsoEnabled
            ? [{
              userRoles: {
                userRole: {
                  userRole: {
                    code: { _in: [GLOBAL_ROLE_CODES.GLOBAL_ADMIN] }
                  }
                }
              }
            }]
            : [])
        ]
      }
      : null;


  const getUsersName = (ids: string[]) => {
    return (staffData || []).filter((item) => ids.includes(item?.uuid)).map((item) => {item?.contactData?.name || item?.name});
  }

  const getRoles = (user: IUser) => {
    const roleNames = Array.from(
      new Set(user.userRoles?.map((role: any) => role?.userRole?.roleName) || [])
    );
    return roleNames.join(' • ');
  };

  const tagRender = (tagProps: any) => {
    const onPreventMouseDown = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
    };
    const label =
      typeof tagProps.label === 'string'
        ? tagProps.label
        : getUsersName(tagProps?.value)?.[0];
    if (!label) {
      return <></>
    }
    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={tagProps.closable}
        onClose={tagProps.onClose}
        className='custom-tag'
      >
        {label}
      </Tag>
    );
  };

  const setProfileUrlForUser = (agentData: any, user: any, userProfileImageMap: {[index: string]: string;}) => {
    const userId = user?.id || null;
    if (userId) {
      const matchUser = (agentData || [])?.find((agent: any) => {
        if (userId && userId === agent?.id) {
          return agent;
        }
      });
      const thumbnail = matchUser?.thumbnail;
      if (thumbnail && !thumbnail?.includes('404')) {
        userProfileImageMap[user.uuid as string] = thumbnail;
      }
    }
  };
  const agentList = getAgentsList();

  const setUserConfiguration = async (users: any[]) => {
    const userProfileImageMap: {[index: string]: string;} = {};
    const userList: any = [];
    (users || []).forEach((user: any) => {
      if (user?.id) {
        const agentData = agentList
        setProfileUrlForUser(agentData, user, userProfileImageMap);
        userList.push({
          label: user.name,
          value: user.uuid,
          key: user.uuid,
          email: user?.email,
          contactData: user,
          uuid: user.uuid,
          userRoles: user?.userRoles || [],
        });
      }
    });
    setUserProfileImageMap(userProfileImageMap);
    setStaffData(userList);
    setUserLoading(false);
  }

  const [getUsers, { loading: searchQueryLoading }] = useLazyQuery(
    props?.userSearchType === USER_SEARCH_TYPE.SELECTED_LOCATION_BASED 
      ? UserQueries.GET_USERS_FOR_CALENDAR_V2 
      : SEARCH_USER_BY_TEXT,  {
    onCompleted: (data: any) => {
      if (data && data.users && data.users.length > 0) {
        setUserConfiguration(data.users);
      } else {
        setStaffData([]);
        setUserLoading(false);
      }
    },
    onError: (error) => {

      setStaffData([]);
      setUserLoading(false);
    },
  });

  const [getUserByIds, {loading: getUserByIdsQueryLoading}] =
    useLazyQuery(GET_USERS_WITH_IDS);

  const onSearch = (searchText: string) => {
    setUserLoading(true);
    
    getUsers({
      variables: {
        searchString: `%${searchText}%`,
        limit: 50,
        accountId: accountUUid,
        ...(userboolExp && { userboolExp })
      },
    });
  };

  const fetchAndMergeExistingDataWithInitData = async () => {
    const promiseList = [];
    promiseList.push(
      getUserByIds({
        variables: {
          userIds: selectedValue,
        },
      })
    );
    promiseList.push(
      getUsers({
        variables: {
          searchString: `%%`,
          limit: 50,
          accountId: accountUUid,
          ...(userboolExp && { userboolExp })
        },
      })
    );
    const response = await Promise.all(promiseList);
    const data = formatAndCombinePrevAndNewOptions(response);
    setSelectedUsers(data?.selectedUsers);
    setStaffData(data?.dropdownList);
    setUserLoading(false);
  }

  useEffect(() => {
    setUserLoading(true);
    if (selectedValue && selectedValue?.length > 0) {
      fetchAndMergeExistingDataWithInitData();
    } else {
      onSearch('');
    }
  }, []);

  return (
      <Select
      mode={'multiple'}
      disabled={isDisabled || getUserByIdsQueryLoading}
      loading={userLoading || getUserByIdsQueryLoading || searchQueryLoading || userLoading}
      size="large"
      allowClear
      showSearch
      value={getUserByIdsQueryLoading ? [] : (selectedUsers || undefined)}
      placeholder={getUserByIdsQueryLoading ? 'Loading...' : placeholder}
      tagRender={tagRender}
      onChange={(value: any, options: any) => {
        if (onChange && typeof onChange === 'function') {
          if (value && value?.length) {
            const userNames: string[] = [];
            (options || [])?.forEach((item: any) => {
              if (item?.label) {
                userNames.push(item?.label);
              }
            }) || [];
            setSelectedUsers(options);
            onChange(value, userNames)
          } else {
            onChange(undefined, undefined);
            setSelectedUsers(options);
          }
        }
      }}
      status={showError ? 'error' : undefined}
      filterOption={(input: string, option: any) => {
        return (option?.label || '').toLowerCase().includes(input.toLowerCase());
      }}
      onSearch={debounce(onSearch, 400)}
      notFoundContent={(searchQueryLoading || userLoading) ? <Spin /> : <Empty />}
    >
      {staffData.map((user: any) => {
        const name = user?.contactData?.name || user?.name;
        const uuid = user.uuid;
        const profileUrl = userProfileImageMap?.[uuid];
        const roles = getRoles(user);
        return (
          <Select.Option key={uuid} value={uuid} label={name}>
            <HStack
              width={'100%'}
              alignItems="flex-start"
              paddingY={2}
              paddingX={4}
            >
              <View>
                <DisplayCardAvatar
                  avatarStyle={{
                    avatarSize: '12',
                    width: 48,
                    height: 48,
                  }}
                  userData={{
                    userType: GROUP_MEMBER_TYPE.USER,
                    userId: uuid,
                    name: name,
                    userName: name,
                    imgSrc: profileUrl || '',
                  }}
                  isLetterAvatarShow
                />
              </View>
              <VStack
                alignSelf="stretch"
                flex={1}
                marginLeft={2}
                justifyContent={'flex-start'}
              >
                <Text fontWeight={'bold'} fontSize={16}>
                  {name}
                </Text>
                <Text color={Colors?.Custom?.Gray500}>{roles}</Text>
              </VStack>
            </HStack>
          </Select.Option>
        );
      })}
    </Select>
  );
};
