import React, { useContext, useState } from 'react'
import { getFormURL } from '../../../../utils/commonUtils';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CareJourneyQueries, TaskQueries, VitalQueries } from '../../../../services';
import { CARESTUDIO_APOLLO_CONTEXT, PATIENT_EDUCATION_URL } from '../../../../constants/Configs';
import { ITask, IUpdatedTaskData } from '../CareDashboardInterfaces';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import { FORM_SOURCE, MLOV_CATEGORY, PATIENT_CARE_JOURNEY_STATUS_CODE } from '../../../../constants/MlovConst';
import { getCompletedTaskStatusId, getDefaultTaskStatusId, getMissedTaskStatusId } from '../CareDashboardUtils/CareDashboardUtils';
import { getMlovCodeFromId, getMlovIdFromCode, getMlovListFromCategory, getMlovObjectFromCode } from '../../../../utils/mlovUtils';
import { BottomViewAction } from '../../../TaskCard/TaskCardHelper';
import { ILoginUserData } from '../../../../Interfaces';
import { ParticipantType } from '../../CalendarWidget/ParticipantAutoComplete/ParticipantEnum';
import { ToastType } from '../../../../utils/commonViewUtils';
import { useIntl } from 'react-intl';
import { getDateToMomentISOString } from '../../../../utils/DateUtils';
import { TaskField } from '../CareDashboardConstants';
import { getCareJourneyEndDate } from '../../../RightSideContainer/Journeys/AddOrUpdateJourney/CareJourneyPreview/CareJourneyPreviewHelper';
import { GET_PATIENT_IDS_BY_CONTACT_UUIDS } from '../../../../services/Contacts/ContactsQueries';
import { Vital, getFHIRTemplate, getObservationResourceTemplate, getVitalConfigFromLoinc } from '../../../../utils/VitalUtils';
import { getEhrConfig, getResourceAbilities, getVitalListFromCapability } from '../../../../utils/capabilityUtils';
import { addOrUpdateResource } from '../../../../services/CommonService/AidBoxService';
import { FHIR_RESOURCE, RESOURCE_BLOCK_MESSAGE_ID } from '../../../../constants/FhirConstant';
import { getDocumentRefByReferenceId } from '../../../../services/CommonService/OrderService';
import { DocStatus, NoteEntry } from '../../../PersonOmniView/MiddleContainer/PatientNotes/interfaces';
import { SOURCE_MAP } from '../../AddTask/AddTaskUtils';
import { getLoggedInAccountInfoForSidecar } from '../../../../sidecar/common/SideCardCommonUtils';
import { HTTP_ERROR_CODE } from '../../../../constants/StringConst';
import {GET_CARE_PLAN_BY_ID} from '../../../../services/CarePlan/CarePlanQueries';
import useCarePlanStatus from '../../../PersonOmniView/MiddleContainer/CarePlan/useCarePlanStatus';
import { useContactLocationUuid } from '../../MemebersView/customHook/useContactLocationUuid';

interface IUseTaskActionManagerInterface {
  onTaskUpdate?: (task: ITask, updatedData: IUpdatedTaskData) => void;
  showToast?: (message: string, type: ToastType, additionalData?: any) => void;
  locationUuid?: string | undefined
}

const useTaskActionManager = (args: IUseTaskActionManagerInterface) => {
  // Global constants
  const { onTaskUpdate, showToast, locationUuid } = args;
  const intl = useIntl();
  const ehrConfig = getEhrConfig(locationUuid, '');
  const vitals = getVitalListFromCapability('', locationUuid);
  const {fetchContactLocationUuid} = useContactLocationUuid(undefined, true);
  const commonData = useContext(CommonDataContext);
  const userData = commonData.userData || ({} as ILoginUserData);
  const accountId = userData?.accounts.length > 0 ? userData?.accounts[0].uuid || '' : '';
  const resourceAbilities = getResourceAbilities(
    FHIR_RESOURCE.DOCUMENT_REFERENCE,
    '',
    locationUuid
  );
  const foldVisitNoteWithEncountersEnabled = resourceAbilities?.foldVisitNoteEnabled || false;
  const formSourceList = getMlovListFromCategory(commonData.CARE_STUDIO_MLOV, MLOV_CATEGORY.FORM_SOURCE) || [];
  const taskStatusList = getMlovListFromCategory(commonData.CARE_STUDIO_MLOV, MLOV_CATEGORY.TASK_STATUS) || [];
  const formSourceId = getMlovIdFromCode(formSourceList, FORM_SOURCE.CARE_JOURNEY);
  const taskPriorityMlov = getMlovListFromCategory(
    commonData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.TASK_PRIORITY,
    false
  );
  const journeyDuration = getMlovListFromCategory(
    commonData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.CARE_JOURNEY_DURATION
  ) || [];

  const TASK_STATUS_IDS = {
    COMPLETED: getCompletedTaskStatusId(taskStatusList),
    PENDING: getDefaultTaskStatusId(taskStatusList),
    MISSED: getMissedTaskStatusId(taskStatusList),
  };
  const carePlanStatus = useCarePlanStatus();

  // States
  const [componentState, setComponentState] = useState<{
    taskActionsInProgress: { taskId: string, action: BottomViewAction }[];
  }>({
    taskActionsInProgress: [],
  });

  // APIs
  const [updateTask] = useMutation(TaskQueries.UPDATE_TASK_STATUS, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT}
  });

  const [updateTaskAssignee] = useMutation(TaskQueries.UPDATE_TASK_ASSIGNEE, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
  });

  const [assignCareJourney] = useMutation(
    CareJourneyQueries.ASSIGN_PATIENT_CARE_JOURNEY,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );
  const [isJourneyAlreadyAssigned] = useLazyQuery(
    CareJourneyQueries.GET_PATIENT_CARE_JOURNEY_BY_CARE_JOURNEY_ID,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
    }
  );
  const [getPatientIds] = useLazyQuery(GET_PATIENT_IDS_BY_CONTACT_UUIDS, {
    fetchPolicy: 'no-cache',
  });
  const [getSubTaskByIds] = useLazyQuery(TaskQueries.GET_SUB_TASK_BY_ID, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });
  const [captureVital] = useMutation(VitalQueries.RECORD_VITAL_VALUE, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
  });
  const [getLinkNoteWithTask] = useLazyQuery(TaskQueries.GET_TASK_LINK_NOTE, {
    fetchPolicy: 'no-cache',
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
  });
  const [getCarePlanById] = useLazyQuery(GET_CARE_PLAN_BY_ID, {
    fetchPolicy: 'no-cache',
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
  });


  const getCarePlanLinkedWithTask = getLinkNoteWithTask;


  // Public methods

  const handleTaskActions = async (
    task: ITask,
    action: BottomViewAction,
    data?: any,
    context?: any,
  ) => {
  // 1. Perform actions
    switch (action) {
      case BottomViewAction.captureLabTest:
        await recordLabTestData(task, data, context);
        break;

      case BottomViewAction.captureVital:
        await recordVitalData(task, data, context);
        break;

      case BottomViewAction.openForm:
        let currentSubdomainBaseUrl = '';
        if (commonData.sidecarContext?.isSidecar) {
          const loggedInUserAccountInfo = await getLoggedInAccountInfoForSidecar();
          currentSubdomainBaseUrl = loggedInUserAccountInfo?.subDomainUrl || '';
        }

        const contactId = task.contactId;
        if (!contactId) {
          showToast?.(intl.formatMessage({id: 'apiErrorMsg'}), ToastType.error);
          return;
        }
        const url = getFormURL(
          task.referenceData?.entity?.id || '',
          contactId || '',
          accountId,
          task.id,
          task.referenceData?.entity?.sourceId || formSourceId,
          currentSubdomainBaseUrl,
          task.referenceData?.eventId,
        );
        window.open(url, '_blank');
        break;

      case BottomViewAction.openContent:
        if (task.referenceData?.entity?.slug) {
          const slug = task.referenceData.entity.slug;
          const url = PATIENT_EDUCATION_URL + slug;
          window.open(url, '_blank');
        }
        break;

      case BottomViewAction.markAsComplete:
        return await markTaskStatus(task, TASK_STATUS_IDS.COMPLETED, action, context);
        break;

      case BottomViewAction.markAsIncomplete:
        // const isOverdue = task.endDateTime ? isPastDay(getMomentObj(task.endDateTime)) : false;
        // const statusId = isOverdue ? TASK_STATUS_IDS.MISSED : TASK_STATUS_IDS.PENDING;
        return await markTaskStatus(task, TASK_STATUS_IDS.PENDING, action, context);
        break;

      case BottomViewAction.markAsMissed:
        return await markTaskStatus(task, TASK_STATUS_IDS.MISSED, action, context);
        break;

      case BottomViewAction.changeAssignee:
        await updateTaskAssigneeDetails(task, data, context);
        break;

      case BottomViewAction.assignCareJourney:
        await assignCareJourneyFromTask(task, data, context);
        break;

      case BottomViewAction.deleteTask:
        await markTaskAsDeleted(task, context);
        break;

      case BottomViewAction.updatePriority:
        await updateTaskPriority(task, data, context);
        break;

      case BottomViewAction.checkIfAllSubTasksAreCompleted:
        return checkIfAllSubTasksAreCompleted(task);

      case BottomViewAction.checkNoteStatus:
        return checkNoteStatus(task);

      case BottomViewAction.checkCarePlanStatus:
        return checkCarePlanStatusIsInReview(task);

      default:
        break;
    }
  };

  // Private methods
  const markTaskStatus = async (task: ITask, statusId: string, action: BottomViewAction, context: any) => {
    try {
      //don't do anything if it is a dummy task (used for first mile view)
      if (task && task.taskType?.code !== 'dummy') {
        addTaskAction(task, action);

        const locationId = task?.contact?.id ? await fetchContactLocationUuid(task.contact.id) : '';
        const [noteStatusNotSigned, isCarePlanInReview, isSubTasksCompleted] = await Promise.all([
          checkNoteStatus(task, locationId),
          checkCarePlanStatusIsInReview(task),
          checkIfAllSubTasksAreCompleted(task)
        ]);

        if (isCarePlanInReview && action === BottomViewAction.markAsComplete) {
          args.showToast?.(intl.formatMessage({id: 'carePlanInReviewMsg'}), ToastType.info)
          removeTaskAction(task.id, action);
          return {success: false}
        }

        if (noteStatusNotSigned && action === BottomViewAction.markAsComplete) {
          args.showToast?.(intl.formatMessage({id: 'completeTaskLinkNoteMessage'}), ToastType.info)
          removeTaskAction(task.id, action);
          return {success: false}
        }

        if (!isSubTasksCompleted && action === BottomViewAction.markAsComplete) {
          return {success: false}
        }

        await updateTask({
          variables: {
            params: {
              id: task.id,
              data: {
                statusId,
              }
            }
          },
        });
        onTaskUpdate?.(task, { field: TaskField.status, value: statusId, context });
        removeTaskAction(task.id, action);
        const msgId = action === BottomViewAction.markAsComplete ? 'taskCompletedMsg' : 'taskUpdatedMsg';
        showToast?.(intl.formatMessage({id: msgId}), ToastType.success);
        return  {success: true}
      } else if (task?.taskType?.code === 'dummy') {
        removeTaskAction(task.id, action);
        showToast?.(intl.formatMessage({id: 'dummyTaskCompleteMsg'}), ToastType.success);
        return  {success: true}
      }
    } catch {
      removeTaskAction(task.id, action);
      showToast?.(intl.formatMessage({id: 'apiErrorMsg'}), ToastType.error);
      return  {success: false}
    }
  };

  const markTaskAsDeleted = async (task: ITask, context: any) => {
    try {
      addTaskAction(task, BottomViewAction.deleteTask);
      await updateTask({
        context: {service: CARESTUDIO_APOLLO_CONTEXT},
        variables: {
          params: {
            id: task.id,
            data:{
              isDeleted: true
            }
          },
        }
      });
      onTaskUpdate?.(task, { field: TaskField.isDeleted, value: true, context });
      removeTaskAction(task.id, BottomViewAction.deleteTask);
      showToast?.(intl.formatMessage({id: 'taskDeletedMsg'}), ToastType.success);
    } catch {
      removeTaskAction(task.id, BottomViewAction.deleteTask);
      showToast?.(intl.formatMessage({id: 'apiErrorMsg'}), ToastType.error);
    }
  }

  const updateTaskAssigneeDetails = async (task: ITask, data: any, context: any) => {
    try {
      const isAssigneeTypePatientOrProspect = data?.type === ParticipantType.patient || data?.type === ParticipantType.leads;
      if (data?.value) {
        addTaskAction(task, BottomViewAction.changeAssignee);
        await updateTaskAssignee({
          variables: {
            params: {
              id: task.id,
              data: {
                assigneeId: data.value,
                userPoolId: isAssigneeTypePatientOrProspect ? null : task?.userPoolId || null,
                ...(isAssigneeTypePatientOrProspect ? {
                  contactId: null,
                  taskDisplayCategoryId: null,
                } : {})
              },
            },
          },
        });
        onTaskUpdate?.(task, { field: TaskField.assignee, value: data.value, additionalData: data, context });
        removeTaskAction(task.id, BottomViewAction.changeAssignee);
        showToast?.(
          intl.formatMessage({id: 'taskAssigneeChangeMsg'}),
          ToastType.success,
        );
      }
    } catch {
      removeTaskAction(task.id, BottomViewAction.changeAssignee);
      showToast?.(intl.formatMessage({id: 'apiErrorMsg'}), ToastType.error);
    }
  }

  const updateTaskPriority = async (task: ITask, data: any, context: any) => {
    try {
      const priorityCode = data?.code || '';
      const priorityMlov = getMlovObjectFromCode(priorityCode, taskPriorityMlov);
      if (priorityMlov?.id) {
        addTaskAction(task, BottomViewAction.updatePriority);
        await updateTask({
          variables: {
            params: {
              id: task.id,
              data: {
                priorityId: priorityMlov.id
              }
            }
          },
        });
        onTaskUpdate?.(task, { field: TaskField.priority, value: priorityCode, context });
        removeTaskAction(task.id, BottomViewAction.updatePriority);
        showToast?.(intl.formatMessage({id: `Task marked as ${priorityMlov.value} Priority`}), ToastType.success);
      }
    } catch {
      removeTaskAction(task.id, BottomViewAction.updatePriority);
      showToast?.(intl.formatMessage({id: 'apiErrorMsg'}), ToastType.error);
    }
  }

  const assignCareJourneyFromTask = async (task: ITask, data: any, context: any) => {
    try {
      if (data?.startDate) {
        addTaskAction(task, BottomViewAction.assignCareJourney);
        // 1. Check if journey already assigned
        const params = await createRequestParams(data, task);
        const response = await isJourneyAlreadyAssigned({
          variables: {
            patientId: params.patientId,
            careJourneyId: params.careJourneyId,
          },
        });
        const mlovs = getMlovListFromCategory(commonData.CARE_STUDIO_MLOV, MLOV_CATEGORY.PATIENT_CARE_JOURNEY_STATUS) || [];
        if (response.data?.patientCareJourneys?.length) {
          const isStoppedOrCompletedJourney = (response.data?.patientCareJourneys || []).every((journey: any) => {
            const patientCareJourneyStatusId = journey?.statusId;
            const code = getMlovCodeFromId(mlovs, patientCareJourneyStatusId);
            return PATIENT_CARE_JOURNEY_STATUS_CODE.STOPPED === code || PATIENT_CARE_JOURNEY_STATUS_CODE.COMPLETE === code;
          });
          if (!isStoppedOrCompletedJourney) {
            showToast?.(intl.formatMessage({id: 'journeyAlreadyAssignMsg'}), ToastType.info);
            return;
          }
        }
        // 2. Assign journey
        await assignCareJourney({
          variables: {
            data: {
              operationCode: 'CREATE',
              data: params,
            },
          },
        });
        // 3. Complete task
        await updateTask({
          variables: {
            params: {
              id: task.id,
              data: {
                statusId: TASK_STATUS_IDS.COMPLETED,
              }
            }
          },
        });
        onTaskUpdate?.(task, { field: TaskField.status, value: TASK_STATUS_IDS.COMPLETED, context });
        removeTaskAction(task.id, BottomViewAction.assignCareJourney);
      }
    } catch {
      removeTaskAction(task.id, BottomViewAction.assignCareJourney);
      showToast?.(intl.formatMessage({id: 'apiErrorMsg'}), ToastType.error);
    }
  }

  const createRequestParams = async (data: any, task: any) => {
    data.patientCareJourneyTeam =
      data.careTeamUsers?.map((user: any) => {
        return {roleId: user.roleId, userId: user.uuid};
      }) || [];

    data.startDateTime = getDateToMomentISOString(data.startDate);

    const duration = {
      value: data.duration,
      unit: data.durationUnitId,
      unitList: journeyDuration,
    };

    data.duration = duration;

    data.endDateTime = getDateToMomentISOString(
      getCareJourneyEndDate(data.startDate, data)
    );

    if (task.contactId && !task.patientId) {
      const contactData = await getPatientIds({
        variables: {
          contactIdList: [task.contactId],
        },
      });

      if (contactData.data?.contacts?.[0]?.patient?.patientUuid) {
        task.patientId = contactData.data.contacts[0].patient.patientUuid;
      }
    }

    const params: any = {
      careJourneyId: task?.referenceData?.careJourney?.id,
      memberOutcome: data.memberOutcome,
      businessOutcome: data.businessOutcome,
      patientId: task.patientId,
      startDateTime: data.startDateTime,
      endDateTime: data.endDateTime,
      patientCareJourneyTeam: {data: data.patientCareJourneyTeam},
      duration: data.duration,
      durationUnitId: data.durationUnitId,
    };

    return params;
  };

  const checkIfAllSubTasksAreCompleted = async (task: ITask) => {
    if(!task.subTasks || task.subTasks?.length === 0){
      return true;
    }
    const response = await getSubTaskByIds({
      variables: {
        ids: task.subTasks?.map(task => task?.id),
      },
    });
    if (response?.data?.getTasks?.tasks?.length) {
      if (
        response?.data?.getTasks?.tasks?.every(
          (subTask: any) => subTask.statusId === TASK_STATUS_IDS.COMPLETED
        )
      ) {
        return true;
      } else {
        args.showToast?.(
          intl.formatMessage({id: 'subTaskPendingMsg'}),
          ToastType.error
        );
        return false;
      }
    } else {
      args.showToast?.(
        intl.formatMessage({id: 'apiErrorMsg'}),
        ToastType.error
      );
      return false;
    }
  };

  const checkNoteStatus = async (editTask: ITask, locationId?: string): Promise<boolean> => {
    let noteStatusNotSigned = false;
    const isTaskLinkWithNote = editTask?.resourceMap && editTask.resourceMap?.notes || false;
    if (isTaskLinkWithNote) {
      const taskNoteResourceMapParam = { resourceId: editTask?.id, sourceTypeCode: SOURCE_MAP.NOTES };
      const resourceMapResponse = await getLinkNoteWithTask({ variables: taskNoteResourceMapParam });
      const resourceMappings = resourceMapResponse?.data?.resourceMappings || [];
      const noteId = resourceMappings?.length ? resourceMappings?.[0]?.sourceId : undefined;
      if (noteId) {
        const notesResponse  = await getPatientNoteViewByResourceData(noteId, locationId);
        noteStatusNotSigned = notesResponse && notesResponse?.docStatus === DocStatus.PRELIMINARY;
      }
    }
    return noteStatusNotSigned;
  }

  const checkCarePlanStatusIsInReview = async (editTask: ITask) => {
    const isCarePlanContextTask = editTask?.resourceMap && editTask.resourceMap?.carePlan || false;
    let isInReview = false;
      if (isCarePlanContextTask) {
        const carePlanMappingParam = { resourceId: editTask?.id, sourceTypeCode: SOURCE_MAP.CARE_PLAN };
        const carePlanResourceMapResponse = await getCarePlanLinkedWithTask({ variables: carePlanMappingParam });
        const resourceMappings = carePlanResourceMapResponse?.data?.resourceMappings || [];
        const carePlanId = resourceMappings?.length ? resourceMappings?.[0]?.sourceId : undefined;
        if (carePlanId) {
          const carePlanResponse = await getCarePlanById({ variables: { id: carePlanId } });
          const carePlan = carePlanResponse?.data?.contactCarePlans?.[0];
          isInReview = carePlan?.statusId === carePlanStatus.inReviewCarePlanStatusId;
        }
      }
    return isInReview;
  }

  const getPatientNoteViewByResourceData = async (resourceId: any, locationId?: string) => {
    const response = await getDocumentRefByReferenceId(`${resourceId}`, foldVisitNoteWithEncountersEnabled, locationId);
    const noteEntry: NoteEntry = { resource: response?.data } as NoteEntry;
    return noteEntry?.resource || {};
  }

  const recordLabTestData = async (task: ITask, data: any, context: { patientId: string }) => {
    try {
      addTaskAction(task, BottomViewAction.captureLabTest);
      const params: any = {
        value: JSON.stringify(data),
        patientId: context.patientId || '',
        observationDate: getDateToMomentISOString(new Date()),
        valueUnit: data.unit,
        observationName: task.referenceData?.entity?.name || task.title,
        subjectId: task.id,
      };
      await captureVital({
        variables: {
          data: params,
        },
      });
      await updateTask({
        variables: {
          params: {
            id: task.id,
            data: {
              statusId: TASK_STATUS_IDS.COMPLETED,
              referenceData: task.referenceData,
            }
          }
        },
      });
      onTaskUpdate?.(task, { field: TaskField.status, value: TASK_STATUS_IDS.COMPLETED, context });
      removeTaskAction(task.id, BottomViewAction.captureLabTest);
    } catch {
      removeTaskAction(task.id, BottomViewAction.captureLabTest);
      showToast?.(intl.formatMessage({id: 'apiErrorMsg'}), ToastType.error);
    }
  };

  const recordVitalData = async (task: ITask, data: any, context: { patientId: string; accountLocationUuid?: string }) => {
    try {
      addTaskAction(task, BottomViewAction.captureVital);
      const vital = getVitalConfigFromLoinc(
        vitals,
        task.referenceData?.entity?.loinc || ''
      );
      const patientId = context?.patientId || '';
      if (vital && patientId) {
        const fhirData = getFHIRTemplate({
          observations: [
            getObservationResourceTemplate(
              vital.loinc as Vital,
              vitals,
              data.vital.value,
              getDateToMomentISOString(new Date())
            ),
          ],
          patientId: patientId || '',
        });

        const response = await addOrUpdateResource(
          FHIR_RESOURCE.OBSERVATION,
          undefined,
          fhirData,
          {
            patientId: patientId,
            locationId: context?.accountLocationUuid || '',
        });
        const observationId = response.data?.entry?.[0]?.id || response.data?.entry?.[0]?.resource?.id;
        if (!task.referenceData) task.referenceData = {};
        task.referenceData.externalId = observationId;
        task.referenceData.value = data.vital.value;
        task.referenceData.unit = data.unit;

        await updateTask({
          variables: {
            params: {
              id: task.id,
              data: {
                statusId: TASK_STATUS_IDS.COMPLETED,
                referenceData: task.referenceData,
              }
            }
          },
        });
        showToast?.(intl.formatMessage({id: "taskUpdatedMsg"}), ToastType.success);

        onTaskUpdate?.(task, { field: TaskField.status, value: TASK_STATUS_IDS.COMPLETED, context });
      } else {
        showToast?.(intl.formatMessage({id: 'apiErrorMsg'}), ToastType.error);
      }
      removeTaskAction(task.id, BottomViewAction.captureVital);
    } catch(error: any) {
      let errorMessageId = 'apiErrorMsg';
      if (error?.response?.status === HTTP_ERROR_CODE.METHOD_NOT_ALLOWED) {
        errorMessageId = RESOURCE_BLOCK_MESSAGE_ID;
      }
      removeTaskAction(task.id, BottomViewAction.captureVital);
      showToast?.(intl.formatMessage({id: errorMessageId}), ToastType.error);
    }
  };

  const addTaskAction = (task: ITask, action: BottomViewAction) =>  {
    setComponentState((prev) => {
      const actions = prev.taskActionsInProgress;
      actions.push({taskId: task.id, action: action});
      return {
        ...prev,
        taskActionsInProgress: actions,
      }
    });
  }

  const removeTaskAction = (taskId: string, action: BottomViewAction) => {
    setComponentState((prev) => {
      const actions = prev.taskActionsInProgress;
      return {
        ...prev,
        taskActionsInProgress: actions.filter((item) => {
          return !(item.taskId === taskId && item.action === action);
        })
      }
    });
  }

  return {
    taskActionsInProgress: componentState.taskActionsInProgress,
    handleTaskActions
  };
}

export default useTaskActionManager
