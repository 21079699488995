import {
  Checkbox,
  FormControl,
  HStack,
  Input,
  Select,
  Stack,
  useMediaQuery,
  View,
} from 'native-base';
import {Dispatch, SetStateAction} from 'react';
import {useIntl} from 'react-intl';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {
  BUTTON_TYPE,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH,
} from '../../../../../constants';
import {Colors} from '../../../../../styles';
import ModalActionBtn from '../../../../common/ModalActionBtn/ModalActionBtn';
import {FoldButton} from '../../../../CommonComponents/FoldButton/FoldButton';
import {IEditorState} from '../EmailHtmlEditor/EmailHtmlEditorMainView';
import {styles} from '../EmailHtmlEditor/EmailHtmlEditorStyles';
import { filteredFormsCategoryList } from '../../../../../utils/commonUtils';

const {Label} = FormControl;

export interface IEmailEditorTopBarProps {
  errors: any;
  editorState: IEditorState;
  setEditorState: Dispatch<SetStateAction<IEditorState>>;
  submitBtnText: string;
  showSubmitBtn: boolean;
  onCancel: () => void;
  onSubmit: () => void;
}

const EmailEditorTopBar = (props: IEmailEditorTopBarProps) => {
  const {errors, editorState, setEditorState} = props;
  const intl = useIntl();
  const setTemplateSubject = (subject: string) => {
    setEditorState((prev) => {
      return {
        ...prev,
        template: {
          ...prev.template,
          templateData: {
            ...prev.template.templateData,
            subject: subject,
          },
        },
      };
    });
  };

  const setDefaultStatus = (isSelected: boolean) => {
    setEditorState((prev) => {
      return {
        ...prev,
        template: {
          ...prev.template,
          templateIsDefault: isSelected,
        },
      };
    });
  };

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const isSmallScreen = isIPadMiniScreen || isIPadScreen;

  const filteredCategories = filteredFormsCategoryList(editorState.categoryList);

  return (
    <HStack
      style={[
        styles.topBarContainer,
        {
          justifyContent: isSmallScreen ? 'space-between' : 'flex-end',
        },
      ]}
    >
      <Stack
        direction={isSmallScreen ? 'column' : 'row'}
        space={'2'}
        style={{
          justifyContent: 'space-between',
          marginTop: 8,
        }}
      >
        <View style={styles.userInputContainer}>
          <FormControl
            style={[
              styles.formControlStyle,
              {
                justifyContent: isSmallScreen ? 'space-between' : 'center',
              },
            ]}
            isRequired
            isInvalid={errors.templateName}
          >
            <Label>Name</Label>
            <Input
            _focus={{borderColor: '#D0D5DD'}}
              placeholder="Name"
              defaultValue={editorState.template.templateName}
              onChangeText={(name: string) => {
                setEditorState((prev) => {
                  return {
                    ...prev,
                    template: {
                      ...editorState.template,
                      templateName: name,
                    },
                  };
                });
              }}
            />
          </FormControl>
        </View>

        <View style={styles.userInputContainer}>
          <FormControl
            style={[
              styles.formControlStyle,
              {
                justifyContent: isSmallScreen ? 'space-between' : 'center',
              },
            ]}
            isRequired
          >
            <Label>Category</Label>
            <Select
              placeholder="Template Category"
              onValueChange={(value) => {
                setEditorState((prev: any) => {
                  return {
                    ...prev,
                    template: {
                      ...prev.template,
                      templateCategory: value,
                    },
                  };
                });
              }}
              selectedValue={editorState?.template.templateCategory}
            >
              {filteredCategories.map((item) => {
                return (
                  <Select.Item
                    key={item.id}
                    value={item.name}
                    label={item.name}
                  />
                );
              })}
            </Select>
          </FormControl>
        </View>

        <View style={styles.userInputContainer}>
          <FormControl
            isRequired
            isInvalid={errors.subject}
            style={[
              styles.formControlStyle,
              {
                justifyContent: isSmallScreen ? 'space-between' : 'center',
              },
            ]}
          >
            <Label>Subject</Label>
            <Input
            _focus={{borderColor: '#D0D5DD'}}
              placeholder="Subject"
              defaultValue={editorState?.template?.templateData?.subject}
              onChangeText={(subject: string) => {
                setTemplateSubject(subject);
              }}
            />
          </FormControl>
        </View>

        <View style={styles.userInputContainer}>
          <FormControl
            style={[
              styles.formControlStyle,
              {
                justifyContent: isSmallScreen ? 'space-between' : 'center',
              },
            ]}
          >
            <Label style={{marginTop: 10}}>Default</Label>
            <View
              style={{
                marginBottom: 8,
              }}
            >
              <Checkbox
                value="true"
                isChecked={editorState.template.templateIsDefault}
                onChange={(isSelected) => setDefaultStatus(isSelected)}
              />
            </View>
          </FormControl>
        </View>
      </Stack>

      <Stack
        direction={isSmallScreen ? 'column' : 'row'}
        space={'2'}
        style={{
          justifyContent: 'space-between',
        }}
      >
        <FoldButton
          nativeProps={{
            variant: BUTTON_TYPE.SECONDARY,
            onPress: () => {
              props.onCancel();
            },
            leftIcon: (
              <AntIcon
                name="back"
                size={15}
                color={Colors.Custom.mainSecondaryBrown}
              />
            ),
          }}
          customProps={{
            btnText: intl.formatMessage({
              id: 'cancel',
            }),
            withRightBorder: false,
          }}
        ></FoldButton>
        {props.showSubmitBtn && (
          <FoldButton
            nativeProps={{
              variant: BUTTON_TYPE.PRIMARY,
              onPress: () => {
                props.onSubmit();
              },
              leftIcon: (
                <AntIcon
                  name="save"
                  color={Colors.Custom.mainPrimaryPurple}
                  size={15}
                />
              ),
            }}
            customProps={{
              btnText: intl.formatMessage({
                id: props.submitBtnText,
              }),
              withRightBorder: false,
            }}
          ></FoldButton>
        )}
      </Stack>
    </HStack>
  );
};

export default EmailEditorTopBar;
