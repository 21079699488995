
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import {VStack, View, Divider, HStack, Spacer, ScrollView} from 'native-base';
import React from 'react';

interface IPatientReportedTableViewProps {
  dataSource: any[];
  showBulkAction?: boolean;
  selectedRecords?: any[];
  bulkActions?: JSX.Element;
  fullHeight?: boolean;
  onBulkCheckboxChange?: (event: CheckboxChangeEvent) => void;
  rowViewForItem: (item: any, index: number) => void;
}

const PatientReportedTableView = (
  props: IPatientReportedTableViewProps
) => {
  const {
    dataSource,
    showBulkAction,
    selectedRecords,
    bulkActions,
    fullHeight,
    onBulkCheckboxChange,
    rowViewForItem,
  } = props;
  const height = fullHeight ? '100%' : window.innerHeight - 140;
  return (
    <VStack marginY={2} marginX={4} space={4} height={height}>
      {showBulkAction && (
        <VStack paddingBottom={2} space={3}>
          <HStack>
            <Checkbox
              indeterminate={!!selectedRecords?.length && dataSource.length !== selectedRecords?.length}
              checked={dataSource.length === selectedRecords?.length}
              onChange={onBulkCheckboxChange}
            >
              Select All
            </Checkbox>
            <Spacer />
            {bulkActions}
          </HStack>
          <Divider />
        </VStack>
      )}
      <ScrollView>
        <VStack marginY={2} space={4}>
          {dataSource
            .map((item, index) => {
              return (
                <VStack key={item.id || index}>
                  <View paddingBottom={3}>{rowViewForItem(item, index)}</View>
                  <Divider />
                </VStack>
              );
            })}
        </VStack>
      </ScrollView>
    </VStack>
  );
};

export default PatientReportedTableView;
