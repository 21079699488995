import { Select, Spin, Tooltip, Typography} from 'antd';
import { debounce } from 'lodash';
import { View, Pressable } from 'react-native';
import { getGroupsList, getPopGroupLocationBasedContacts } from '../../RightSideContainer/Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import { GraphMemberList } from '../AppDashboard/GraphMemberList';
import { HStack, VStack } from 'native-base';
import { Colors } from '../../../styles';
import AutomationPopGroupContactListSvg from '../../../assets/Icons/AutomationPopGroupContactListSvg';
import InfoSvg from '../Svg/InfoSvg';
import { useIntl } from 'react-intl';
const { Text} = Typography;
import { useContext, useEffect, useState } from 'react';
// import { Text } from 'react-native';
import CommonService from '../../../services/CommonService/CommonService';
import { getAccountUUID, getBooleanFeatureFlag } from '../../../utils/commonUtils';
import { GroupSearchProps } from './interfaces';
import {CommonDataContext} from '../../../context/CommonDataContext';
import FeatureFlags from '../../../constants/FeatureFlags.enums';
import { styles } from './styles'
import { cssPropertiesAntD } from './styles'

const GroupSearch = (props: GroupSearchProps) => {
  const {selectedGroups, selectedGroupOptions, onSelectGroups, locationids} = props;

  const commonService = CommonService.getCommonServiceInstance();
  const workflowService = commonService.workflowService;
  const mlovData = useContext(CommonDataContext);
  const [state, setState] = useState({
    isOpen: false,
    loading: false,
    groupData: [] as Array<{ id: string; name: string }>,
    isDrawer: false,
    selectedContacts: [] as string[],
    page: 1,
    pageSize: 10,
  });
  const intl = useIntl()

  const [totalContactCount, setTotalContactCount] = useState<number>(0);
  const [totalUniqueContactCount, setTotalUniqueContactCount] = useState<number>(0);
  const [groupWiseContacts, setGroupWiseContacts] = useState<Array<{groupName: string; groupId: string, contactCount: number, contactList: string[] }>>([]);

  const getContactForPopGroupByWorkFlowLocation = async ({ ruleIds, accountLocationIds=[], selectedGroupOptions }: { ruleIds: string[], accountLocationIds: string[], selectedGroupOptions: any }) => {
    try {
      const resp = await getPopGroupLocationBasedContacts({
        ruleIds,
        accountLocationIds,
      });
      
      let totalContacts = 0;
      const uniqueContactIds = new Set<string>();
      const groupWiseContacts: Array<{ groupId: string, groupName: string, contactCount: number, contactList: string[] }> = [];
  
      Object.keys(resp.data).forEach((groupId) => {
        const contacts = resp.data[groupId];
        totalContacts += contacts.length;
        contacts.forEach((contactId: string) => uniqueContactIds.add(contactId));
        const group = selectedGroupOptions?.find((option:any) => option?.id === groupId);
        const groupName = group ? group.name : groupId;
  
        groupWiseContacts.push({
          groupId,
          groupName,
          contactCount: contacts?.length,
          contactList: contacts,
        });
      });
  
      return {
        totalContacts,
        groupWiseContacts,
        totalUniqueContacts: uniqueContactIds?.size || totalContacts,
      };
    } catch (err) {
      console.log('Error:', err);
      return {
        totalContacts: 0,
        groupWiseContacts: [],
        totalUniqueContacts: 0
      };
    }
  };

  

  const openDrawerWithContacts = (contactUuidList: string[]) => {
    setState((prev) => ({
      ...prev,
      isDrawer: true,
      selectedContacts: contactUuidList,
      currentPage: 1,
    }));
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    setState((prev) => ({
      ...prev,
      page: page,
      pageSize,
    }));
  };

  
  const [searchText, setSearchText] = useState<string | undefined>('');

  const tenantId = getAccountUUID();
  const isMultiTenancyEnabled = getBooleanFeatureFlag(mlovData.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED);
  const searchGroup = async (searchString: string, ruleIds?: string[]) => {
    setState((prev) => {
      return {
        ...prev,
        loading: true,
        isOpen: true,
        isDrawer: false,
        groupData: []
      };
    });
    await getGroups(searchString, ruleIds);
  };

  async function getGroups(searchString: string, ruleIds?: string[]) {

    const limit = 15;
    const name = searchString;
    const offset = 0;
    const category = undefined;
    const contactType = props.contactType;
    const sort = undefined;
    const filter = props.groupType;
    setSearchText(searchString);
    try {
      const response = await getGroupsList(
        tenantId,
        limit,
        name,
        offset,
        category,
        contactType,
        ruleIds,
        sort,
        filter,
        isMultiTenancyEnabled && locationids && locationids?.length > 0
          ? locationids
          : []
      );
      const groupData = ruleIds?.length ? response?.data : response?.data?.data;
      setState((prev) => {
        return {
          ...prev,
          groupData: groupData || [],
          loading: false,
        };
      });
    } catch (error) {

    }
  }

  useEffect(() => {
    if (selectedGroupOptions?.length) {
      setState((prev) => {
        return {
          ...prev,
          groupData: selectedGroupOptions,
        };
      });
    }
  }, [groupWiseContacts]);

  return (
    <View>
      <Select
        size="large"
        mode="multiple"
        showSearch
        allowClear
        filterOption={false}
        onSearch={debounce(searchGroup, 500)}
        value={selectedGroups || selectedGroupOptions?.map?.((option) => option?.id) || undefined}
        onChange={async (value, options) => {
          const selectedGroupOptions = options?.map((option: any) => ({id: option?.key, name: option?.children})) || [];
          onSelectGroups(value, selectedGroupOptions);
          if(value?.length>0 && props?.isShowEligibleContactCount){
            const response = await getContactForPopGroupByWorkFlowLocation({
              ruleIds: value,
              accountLocationIds: props?.workFlowLocations ?? [],
              selectedGroupOptions: selectedGroupOptions
            });
            if (response) {
              setTotalContactCount(response?.totalContacts);
              setGroupWiseContacts(response?.groupWiseContacts);
              setTotalUniqueContactCount(response?.totalUniqueContacts )
            }
          }else{
            setTotalContactCount(0);
            setGroupWiseContacts([]);
          }
        }}
        onFocus={() => {
          const debouncedSearch = debounce((searchTerm) => searchGroup(searchTerm), 500);
          debouncedSearch('');
        }}
        placeholder="Search Groups"
        loading={state.loading || (!state?.groupData?.length)}
        notFoundContent={
          state.loading ?
          <Spin size="small" /> :
          ( searchText && !state.groupData.length && <Text>No Data Found</Text>)
        }
        style={cssPropertiesAntD.groupWidth}
      >
        {state.groupData.map((item) => {
          return (
            <Select.Option key={item.id} value={item.id}>
              {item.name}
            </Select.Option>
          );
        })}
      </Select>
      {(groupWiseContacts?.length > 0 && props?.isShowEligibleContactCount) && (
        <View style={styles.groupContactView}>
          <HStack height={'49px'} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
            <VStack padding={'8px 12px'} borderLeftRadius={5} borderColor={Colors.FoldPixel.GRAY150} borderWidth={0.5} alignItems={'center'} width={'50%'}>
              <Text style={{fontWeight:500}}>{intl.formatMessage({ id: 'totalContacts' })}</Text>  
              <Text strong>{totalContactCount}</Text>
            </VStack>

            <VStack padding={'8px 12px'} borderRightRadius={5}  borderColor={Colors.FoldPixel.GRAY150} borderWidth={0.5} alignItems={'center'} width={'50%'}>
              <Text>
                {intl.formatMessage({ id: 'uniqueContacts' })}
                <Tooltip title={intl.formatMessage({ id: 'popGroupUniqueContacts' })}>
                  <span style={{ marginLeft: '5px' }}>
                    <InfoSvg customColor={Colors.FoldPixel.GRAY400} customDimension={'15'} />
                  </span>
                </Tooltip>

              </Text>
              <Text strong>{totalUniqueContactCount}</Text>
            </VStack>
          </HStack>

          {groupWiseContacts?.map((group) => (
            <Pressable
              onPress={() => openDrawerWithContacts(group?.contactList)}
              style={styles.contactGroupingOutSideContainer}
            >
              <HStack
                justifyContent={'space-between'}
                width={'100%'}
                alignItems={'center'}
                padding={'8px 12px'}
              >
                <HStack alignItems={'center'} space={3}>
                  <AutomationPopGroupContactListSvg />
                  <Text>{group?.groupName} </Text>
                </HStack>
                <Text style={cssPropertiesAntD.groupName}
                >
                  {group?.contactCount}
                </Text>
              </HStack>
            </Pressable>

          ))}
        </View>
      )}
      <GraphMemberList
        title={'Members'}
        page={state.page}
        pageSize={state.pageSize}
        onClose={() => setState((prev) => ({ ...prev, isDrawer: false, selectedContacts: [] }))}
        onPaginationChange={handlePaginationChange}
        total={state?.selectedContacts?.length}
        isOpen={state?.isDrawer}
        contactUuidList={state?.selectedContacts ?? []}
        isHideExecutionOption={true}
        onCompleteAction={() => {

        }}
      />
    </View>
  );
};

export default GroupSearch;
