export const ACTION_MESSAGE_CODE = {
  IS_ONLINE_CHECK: 'IS_ONLINE_CHECK',
  IS_EXIST_IN_CHAT: 'IS_EXIST_IN_CHAT',
  INBOX_DELETED: 'INBOX_DELETED',
  INBOX_UNSUBSCRIBE: 'INBOX_UNSUBSCRIBE',
  CONSENT_NOT_CHECK: 'CONSENT_NOT_CHECK',
  PATIENT_NUMBER_CHANGED: 'PATIENT_NUMBER_CHANGED',
}

export const ACTION_MESSAGE_ICON_TYPE = {
  WARNING: 'WARNING',
  STOP: 'STOP',
}
