import {Templates} from '@foldhealth/formiojs';
import { FormComponentType } from '../CustomComponents/CustomWrapper/CustomComponentHelper';

Templates.current = {
  builder: {
    form: (ctx: any) => `
        <div class="formio builder row formbuilder">
          <div class="formcomponents">
            ${ctx.sidebar}
          </div>
          <div class="formarea" ref="form" id="FormDropArea">
            ${ctx.form}
          </div>
          <div class="formedit" ref="formedit">
            ${ctx.editForm ? ctx.editForm : ''}
          </div>
        </div>
        `,
  },
  builderComponents: {
    form: (ctx: any) => `
    <div class="builder-components drag-container formio-builder-${ctx.type}" ref="${ctx.key}-container">
      ${ctx.html}
      <div style="height: 50px;" data-noattach="true" data-position="${ctx.components?.length || 0}"></div>
    </div>
    `,
  },
  builderEditForm: {
    form: (ctx: any) => `
      <div class="row">
        <div class="col col-sm-12">
          <p class="lead">${ctx.t(ctx.componentInfo.title, {
            _userInput: true,
          })}</p>
        </div>
      </div>
        <div class="row edit-form">
            <div class="col col-sm-12">
                <div ref="editForm">
                    ${ctx.editForm}
                </div>
            </div>
        </div>
      `,
  },
  builderComponent: {
    form: (ctx: any) => `
    <div class="builder-component" ref="dragComponent">
      ${
        !ctx.disableBuilderActions &&
        `<div class="component-btn-group" data-noattach="true">
          <button type="button" class="btn btn-danger component-settings-button" ref="removeComponent" title="Remove">
            <i class="fa fa-trash" aria-hidden="true"></i>
          </button>
          <button type="button" class="btn btn-default component-duplicate-button" ref="duplicateComponent" title="Clone">
            <i class="fa fa-files-o" aria-hidden="true"></i>
          </button>
        </div>`
      }
      ${ctx.html}
    </div>
    `,
  },
  survey: {
    form: (ctx: any) => {
      let template = '<table class="table table-striped table-bordered">';
      template += '<thead>';
      template += '<tr>';
      template += '<th></th>';
      ctx.component.values.forEach(function (value: any) {
        template += '<th style="text-align: center;">';
        template += ctx.t(value.label, {
          _userInput: true,
        });
        template += '</th>';
      });

      template += '</tr>';
      template += '</thead>';
      template += '<tbody>';

      ctx.component.questions.forEach(function (question: any) {
        template += '<tr>';
        template += `<td>${ctx.t(question.label)}</td>`;
        ctx.component.values.forEach(function (value: any) {
          template += '<td style="text-align: center;">';
          template += `<input type="${
            ctx.component.allowMultiSelection ? 'checkbox' : 'radio'
          }" name="${ctx.self.getInputName(question)}" value="${
            value.value
          }" id="${ctx.key}-${question.value}-${value.value}" ref="input">`;
          template += '</td>';
        });
      });

      template += '</tbody>';
      template += '</table>';

      return template;
    },
    html: (ctx: any) => {
      let template = '<table class="table table-striped table-bordered">';
      template += '<tbody>';

      ctx.component.questions.forEach(function (question: any) {
        template += '<tr>';
        template += `<th>${ctx.t(question.label)}</th>`;
        template += '<td>';

      const selectedValues: string[] = [];
        ctx.component.values.forEach(function (item: any) {
          if (ctx.value && ctx.value.hasOwnProperty(question.value)) {
            const answer = ctx.value[question.value];
            if (answer && Array.isArray(answer) && answer.map(item => `${item}`).includes(`${item.value}`)) {
              selectedValues.push(`${ctx.t(item.label)}`);
            } else if (`${answer}` === `${item.value}`) {
              selectedValues.push(`${ctx.t(item.label)}`);
            }
          }
        });
        template += selectedValues.join(', ');
        template += '</td>';
        template += '</tr>';
      });

      template += '</tbody>';
      template += '</table>';

      return template;
    },
  },
  radio: {
    form: (ctx: any) => {
      let template = `<div class="${
        ctx.component.numberOfColumns && ctx.component.numberOfColumns > 1
          ? 'form-column-parent'
          : ''
      } form-radio radio" ref="radioGroup" role="${
        ctx.component.type === 'selectboxes' ? 'group' : 'radiogroup'
      }" aria-required="${
        ctx.input.component.validate.required
      }" aria-labelledby="l-${ctx.instance.id}-${ctx.component.key}" ${
        ctx.component.description &&
        `aria-describedby="d-${ctx.instance.id}-${ctx.component.key}"`
      }>`;

      ctx.values.forEach((item: any) => {
        template += `<div class="${ctx.input.attr.type} ${
          ctx.component.optionsLabelPosition &&
          ctx.component.optionsLabelPosition !== 'right'
            ? 'pl-0'
            : ''
        } form-check${ctx.inline ? '-inline' : ''}  form-column${
          ctx.component.numberOfColumns
            ? '-' + ctx.component.numberOfColumns
            : ''
        }" ref="wrapper">`;

        template += `<label class="form-check-label label-position-${
          ctx.component.optionsLabelPosition
        }" for="${ctx.instance.root && ctx.instance.root.id}-${ctx.id}-${
          ctx.row
        }-${item.value}">`;

        if (
          ctx.component.optionsLabelPosition === 'left' ||
          ctx.component.optionsLabelPosition === 'top'
        ) {
          template += `<span>${ctx.t(item.label, {_userInput: true})}</span>`;
        }

        template += `<${ctx.input.type}
        ref="input"`;

        Object.keys(ctx.input.attr).forEach((attr) => {
          template += `${attr}="${ctx.input.attr[attr]}"`;
        });

        template += `value="${item.value}"
        ${
          ctx.value &&
          (`${ctx.value}` === `${item.value}` ||
            (typeof ctx.value === 'object' &&
              ctx.value.hasOwnProperty(item.value) &&
              ctx.value[item.value])) &&
          `checked=true`
        }`;
        template += `${item.disabled && `disabled=true`}
        id="${ctx.instance.root && ctx.instance.root.id}-${ctx.id}-${ctx.row}-${
          item.value
        }"
        role="${ctx.component.type === 'selectboxes' ? 'checkbox' : 'radio'}"
        >`;

        template += `${
          (!ctx.component.optionsLabelPosition ||
            ctx.component.optionsLabelPosition === 'right' ||
            ctx.component.optionsLabelPosition === 'bottom') &&
          `<span>${ctx.t(item.label, {_userInput: true})}</span>`
        }`;

        template += '</label>';
        template += '</div>';
      });

      template += '</div>';
      return template;
    },
    html: (ctx: any) => {
      const isAllowToShare = ctx?.component?.allowToShare != undefined &&  ctx?.component?.allowToShare === false ? false : true;
      const filtered = ctx.values.filter((item: any) => {
        return `${ctx.value}` === `${item.value}` || (typeof ctx.value === 'object' && ctx.value.hasOwnProperty(item.value) && ctx.value[item.value])
      }).map((item: any) => {
         return ctx.t(item.label, { _userInput: true })
      }).join(', ');
      return `<div ref="value" class=${isAllowToShare ? '' : "disallow-to-share"}>${filtered || '-'}</div>`;
    }
  },
  input: {
    html: (ctx: any) => {
      const isAllowToShare = ctx?.component?.allowToShare != undefined &&  ctx?.component?.allowToShare === false ? false : true;
      const value = ctx?.component?.type === FormComponentType.CURRENCY ? (ctx?.self?.getValueAsString(ctx.value) || ctx?.value) : ctx?.value;
      return `<div ref="value" class=${isAllowToShare ? '' : "disallow-to-share"}>${value || '-'}</div>`;
    },
  },
  select: {
    html: (ctx: any) => {
      const isAllowToShare = ctx?.component?.allowToShare != undefined &&  ctx?.component?.allowToShare === false ? false : true;
      let value: string | undefined;
      if (ctx.value) {
        const label = ctx.component.data.values.find(
          (item: any) => item.value === ctx.value
        )?.label;
        if (label) {
          value = `<span>${label}</span>`;
        } else {
          value = ctx.self.itemValueForHTMLMode(ctx.value);
        }
      }
      value = value || '-';
      return `<div ref="value"  class=${isAllowToShare ? '' : "disallow-to-share"}>${value}</div>`;
    },
  },
  checkbox: {
    html: (ctx: any) => {
      const isAllowToShare = ctx?.component?.allowToShare != undefined &&  ctx?.component?.allowToShare === false ? false : true;
      let template = `
        <div class="form-check checkbox"  class=${isAllowToShare ? "form-check checkbox" : "form-check checkbox disallow-to-share"}>
          <label class="${ctx.input.labelClass} form-check-label">
            <${ctx.input.type}
              ref="input"
        `;

      const attributes: string[] = [];
      for (const attr in ctx.input.attr) {
        attributes.push(`${attr}="${ctx.input.attr[attr]}"`)
      }
      template += attributes.join(' ');

      template += `
              ${ctx.checked ? 'checked=true' : ''}
              aria-required="${ctx.component.validate.required}"
              ${ctx.component.description ? `aria-describedby="d-${ctx.instance.id}-${ctx.component.key}"`: ''}
              >
              ${!ctx.self.labelIsHidden() ? `<span>${ctx.input.label}</span>` : ''}
              ${ctx.component.tooltip ? `<i ref="tooltip" tabindex="0" class="${ctx.iconClass('question-sign')} text-muted" data-tooltip="${ctx.component.tooltip}"></i>` : ''}
              ${ctx.input.content || ''}
            </${ctx.input.type}>
          </label>
        </div>
      `;

      return template;
    }
  },
  component: {
    form: (ctx: any) => {
      const isAllowToShare = ctx?.component?.allowToShare != undefined &&  ctx?.component?.allowToShare === false ? false : true;
      const classes = isAllowToShare ? `${ctx.classes}` : `disallow-to-share ${ctx.classes}`;
      
      return `<div id="${ctx.id}" class="${classes}" ${ctx.styles ? `styles=${ctx.styles}` : ''} ref="component">
      ${
        ctx.visible
          ? `${
              ctx.options.showDeleteButton
                ? `<div ref="messageContainer" class="formio-errors invalid-feedback"></div>
                    <div class="component-btn-group component-delete-btn-group" data-noattach="true">
                      <button type="button" class="btn component-delete-button" ref="componentDelete" title="Remove">
                          <i class="fa fa-trash-o" aria-hidden="true"></i>
                      </button>
                  </div>`
                : ''
            } 
            ${
              ctx.children
            } 
            <div ref="messageContainer" class="formio-errors invalid-feedback"></div>`
          : ''
      }
      </div>
    `;
    }
  }
};
