import {View, Text} from "react-native"
import {Colors} from "../../../styles"
import {useIntl} from "react-intl";
import {NoEmailSignatureView} from "./NoEmailSignatureView";
import {AddEditSignatureView} from "./AddEditSginatureView";
import {useContext, useEffect, useState} from "react";
import {AddSignatureInputView} from "./AddSignatureInputView";
import {COMMON_ACTION_CODES} from "../../../constants/ActionConst";
import {getUserUUID} from "../../../utils/commonUtils";
import {ToastType} from "../../../utils/commonViewUtils";
import {CUSTOM_FIELD_TOAST_ERROR_DURATION} from "../../RightSideContainer/Contacts/CustomField/CustomFieldConst";
import {useToast} from "../../Toast/ToastProvider";
import {ICustomToast} from "../../RightSideContainer/Contacts/CustomField/interface";
import {addUpdateUserPreference} from "./PreferenceService";
import { v4 as uuidv4 } from 'uuid';
import {CommonDataContext} from "../../../context/CommonDataContext";
import {CommonDataAction} from "../../CommonDataProvider/interface";
import './PreferenceSetting.css'
import {IEmailSignature, IEmailSignatureSetting, IEmailSignatureSettingState} from "./PreferencesInterface";
import {EmailSignatureDeleteConfirmationModal} from "./EmailSignatureDeleteConfirmationModal";

export const EmailSignatureSetting = (props: IEmailSignatureSetting) => {
  const [stateData, setStateData] = useState<IEmailSignatureSettingState>({
    userSignatures: [],
    showAddSignatureView: false,
    newSignatureData: {} as IEmailSignature,
    editSignatureView: [],
    selectedSignatures: [],
    editSelectedSignatures: [],
    userSignatureInfoChecked: false,
    actionLoading: false,
    deleteConfirmationModal: false,
    selectedSignature: null,
  })
  const toast = useToast();
  const userUuid = getUserUUID();
  const intl = useIntl();
  const commonData = useContext(CommonDataContext);

  const showToast = (toastData: ICustomToast) => {
    toast({
      toastType: toastData?.toastType,
      message: toastData?.message,
      duration: toastData?.duration || CUSTOM_FIELD_TOAST_ERROR_DURATION,
      closeAllPrevToast: toastData?.closeAllPrevToast || false,
    })
  }

  const onActionLoadingChanges = () => {
    setStateData((prev) => {
      return {
        ...prev,
        actionLoading: !prev.actionLoading,
        selectedSignature: null,
        deleteConfirmationModal: false,
      }
    })
  }

  const setPreference = async (userSignatures: IEmailSignature[], id?: string, isChecked?: boolean) => {
    const prevJSON = props?.preferenceData || {};
    userSignatures
    const newJSON = {
      ...prevJSON,
      userSignature: userSignatures,
      ...(isChecked && { userSignatureInfoChecked: isChecked })
    };
    const data = {
      object: {
        userId: userUuid,
        userPreferencesTypeId: props?.userPreferenceId,
        preferencesJson: JSON.stringify(newJSON),
        id: props?.preferenceDataId ? props?.preferenceDataId : undefined,
      },
    };
    onActionLoadingChanges();
    try {
      const response = await addUpdateUserPreference(data);
      if (response?.data?.userPreferences?.[0]?.id) {
        let editSelectedSignatures = stateData?.editSelectedSignatures;
        if (id) {
          editSelectedSignatures = editSelectedSignatures?.filter?.((signatureId: string) => {
            return signatureId !== id;
          })
        }
        setStateData((prev) => {
          return {
            ...prev,
            userSignatures: userSignatures,
            showAddSignatureView: false,
            editSelectedSignatures: editSelectedSignatures,
            deleteConfirmationModal: false,
            selectedSignature: null,
            ...(isChecked && { userSignatureInfoChecked: isChecked })
          }
        })
        commonData?.dispatch?.({
          type: CommonDataAction.UPDATE_EMAIL_SIGNATURES,
          payload: response?.data,
        });
        showToast({
          toastType: ToastType.success,
          message: intl.formatMessage({id: 'emailSignatureSuccess'}),
          duration: CUSTOM_FIELD_TOAST_ERROR_DURATION,
          closeAllPrevToast: true,
        })
        props?.onActionPerformed(COMMON_ACTION_CODES.REFETCH)
      }
      onActionLoadingChanges();
    } catch (error: any) {
      onActionLoadingChanges();
      showToast({
        toastType: ToastType.error,
        message: error?.response?.data?.message || intl.formatMessage({id: 'apiErrorMsg'}),
        duration: CUSTOM_FIELD_TOAST_ERROR_DURATION,
        closeAllPrevToast: true,
      })
    }
  }

  const deleteSignature = (id: string) => {
    const updatedUserSignature = stateData?.userSignatures?.filter?.((signature: IEmailSignature) => {
      return signature?.id !== id;
    })
    setPreference(updatedUserSignature)
  }

  const onActionPerformed = (actionCode: string, actionData?: any) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.ADD:
        const data = {
          id: '',
          tempId: uuidv4(),
          title: '',
          bodyText: ''
        }
        setStateData((prev) => {
          return {
            ...prev,
            showAddSignatureView: true,
            newSignatureData: data,
          }
        })
        return;
      case COMMON_ACTION_CODES.DISCARD:
        if (actionData?.id) {
          const editSelectedSignatures = stateData?.editSelectedSignatures;
          const updatedEditSelectedSignatures = editSelectedSignatures?.filter?.((id: string) => {
            return id !== actionData?.id
          });
          setStateData((prev) => {
            return {
              ...prev,
              editSelectedSignatures: updatedEditSelectedSignatures,
            }
          })
          return;
        } else if (actionData?.tempId) {
          const tempData = {
            id: '',
            tempId: '',
            title: '',
            bodyText: ''
          }
          setStateData((prev) => {
            return {
              ...prev,
              showAddSignatureView: false,
              newSignatureData: tempData,
            }
          })
          return;
        }
        return;
      case COMMON_ACTION_CODES.DELETE:
        if (actionData?.id) {
          return setStateData((prev) => {
            return {
              ...prev,
              deleteConfirmationModal: true,
              selectedSignature: actionData,
            }
          })
        }
        return;
      case COMMON_ACTION_CODES.SAVE:
        if (actionData?.id) {
          const updatedUserSignature = stateData?.userSignatures?.map?.((signature: IEmailSignature) => {
            if (signature?.id === actionData?.id) {
              return {
                ...signature,
                title: actionData?.title,
                bodyText: actionData?.bodyText
              }
            }
            return signature;
          })
          return setPreference(updatedUserSignature, actionData?.id, actionData?.isChecked);
        } else if (actionData?.tempId) {
          const tempData = {
            id: actionData?.tempId,
            title: actionData?.title,
            bodyText: actionData?.bodyText,
            isDefault: false,
            tempId: actionData?.tempId
          }
          if (!stateData?.userSignatures?.length) {
            tempData.isDefault = true;
          }
          return setPreference([tempData, ...stateData?.userSignatures])
        }
        return;
      case COMMON_ACTION_CODES.EDIT:
        if (actionData?.id) {
          let selectedSignatures = stateData?.selectedSignatures;
          const isSignaturePresent = selectedSignatures?.includes?.(actionData?.id);
          if (isSignaturePresent) {
            selectedSignatures = selectedSignatures?.filter?.((id: string) => {
              return id !== actionData?.id
            });
          }
          const editSelectedSignatures = stateData?.editSelectedSignatures;
          editSelectedSignatures.push(actionData?.id);
          setStateData((prev) => {
            return {
              ...prev,
              editSignatureView: editSelectedSignatures,
              selectedSignatures: selectedSignatures
            }
          })
          return;
        }
        return;
      case COMMON_ACTION_CODES.ITEM_SELECT:
        if (actionData?.id) {
          let selectedSignatures = stateData?.selectedSignatures;
          const isSignaturePresent = selectedSignatures?.includes(actionData?.id);
          if (isSignaturePresent) {
            selectedSignatures = selectedSignatures.filter((id: string) => {
              return id !== actionData?.id
            });
          } else {
            selectedSignatures.push(actionData?.id);
          }
          setStateData((prev) => {
            return {
              ...prev,
              selectedSignatures: selectedSignatures
            }
          })
          return;
        }
        return;
      case COMMON_ACTION_CODES.SET_DEFAULT:
        if (actionData?.id) {
          const updatedUserSignature = stateData?.userSignatures?.map?.((signature: IEmailSignature) => {
            if (signature?.id === actionData?.id) {
              return {
                ...signature,
                isDefault: true,
              }
            } else if (signature?.isDefault === true) {
              return {
                ...signature,
                isDefault: false,
              }
            }
            return signature;
          })
          return setPreference(updatedUserSignature, actionData?.id, actionData?.isChecked);
        }
        return;
      case COMMON_ACTION_CODES.CANCEL:
        return setStateData((prev) => {
          return {
            ...prev,
            deleteConfirmationModal: false,
            selectedSignature: null,
          }
        });
      case COMMON_ACTION_CODES.CONFIRM:
          if (stateData?.selectedSignature?.id) {
            return deleteSignature(stateData?.selectedSignature?.id);
          }
          return;
      default:
        return;
    }
  }

  useEffect(() => {
    setStateData((prev) => {
      const userSignatures = props?.preferenceData?.userSignature;
      const userSignatureInfoChecked = props?.preferenceData?.userSignatureInfoChecked;
      return {
        ...prev,
        userSignatures: userSignatures?.length ? userSignatures : [],
        userSignatureInfoChecked: userSignatureInfoChecked
      }
    })
  },[])

  return <>
     <View style={{
      marginTop: 14,
     }}>
      <div className= {stateData?.actionLoading ? "disable-Email-signature-settings" : ""}>
        <View
          style={{
            borderWidth: 1,
            borderColor: Colors.Custom.Gray200,
            borderRadius: 12,
          }}
        >
          <View
            style={{
              padding: 10
            }}
          >
            <Text
              style={{
                fontSize: 12,
                fontWeight: '500',
                color: Colors.FoldPixel.GRAY400
              }}
            >
              {intl.formatMessage({id: 'emailSignature'})}
            </Text>
            <Text
              style={{
                fontSize: 12,
                color: Colors.FoldPixel.GRAY300,
                fontWeight: '400',
                marginTop: 4,
              }}
            >
              {intl.formatMessage({id: 'addSignatureMessage'})}
            </Text>
          </View>
          <View
            style={{
              borderBottomWidth: 1,
              borderBottomColor: Colors.Custom.Gray200,
            }}
          />
          <View>
            {stateData?.userSignatures?.length > 0  && <AddEditSignatureView
              userSignatures={stateData?.userSignatures}
              onActionPerformed={onActionPerformed}
              selectedSignature={stateData?.newSignatureData}
              showAddSignatureView={stateData?.showAddSignatureView}
              editSelectedSignatures={stateData?.editSelectedSignatures}
              selectedSignatureView={stateData?.selectedSignatures}
              userSignatureInfoChecked={stateData?.userSignatureInfoChecked}
              actionLoading={stateData?.actionLoading}
            />}
            {(!stateData?.userSignatures?.length && !stateData?.showAddSignatureView) && <NoEmailSignatureView
              onActionPerformed={onActionPerformed}
            />}
            {!stateData?.userSignatures?.length && stateData?.showAddSignatureView && <View style={{
              padding: 10,
            }}>
              <AddSignatureInputView
                onActionPerformed={onActionPerformed}
                selectedSignature={stateData?.newSignatureData}
              />
            </View>}
          </View>
        </View>
      </div>
    </View>
    {stateData?.deleteConfirmationModal && <EmailSignatureDeleteConfirmationModal
      onActionPerformed={onActionPerformed}
    />}
  </>
}