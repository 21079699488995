
import React, { useContext, useState } from 'react';
import { HStack, Text, View, Pressable, VStack, Tooltip, Spinner, Divider } from 'native-base';
import { Dimensions, TouchableOpacity, StyleSheet } from 'react-native';
import { DATE_FORMATS, DISPLAY_DATE_FORMAT, RIGHT_SIDE_CONTAINER_CODE } from '../../../../../../../../constants';
import { Colors } from '../../../../../../../../styles/Colors';
import { getDateStrFromFormat, getDisplayMonths, getMomentObjFromFormat, getMomentObjectWithDateStringAndFormat } from '../../../../../../../../utils/DateUtils';
import { IFeedData } from '../interface';
import { isWeb } from '../../../../../../../../utils/platformCheckUtils';
import { TagComponent } from '../../../../../../../common/TagComponent';
import { getDocumentStatusLabelInCareTimeline } from '../../../../../../../PersonOmniView/MiddleContainer/PatientNotes/components/DocumentationViewHelper';
import DocumentIconSvg from '../../../../../../../common/Svg/TimelineSvg/DocumentIconSvg';
import { IEhrCapability } from '../../../../../../../../Interfaces';
import { IFormCommonData } from '../../../../../../Forms/FHFormio/CustomComponents/CustomWrapper/CustomWrapper';
import { CommonDataContext } from '../../../../../../../../context/CommonDataContext';
import { REPORT_TYPE, getDiagnosticReportResponseFormatted, getReportResultForDisplay } from '../../../../../../../PersonOmniView/LeftContainer/RecentActivity/RecentReport/ReportUtils';
import { DiagnosticReport, MedicationRequest, RequestGroup } from 'fhir/r4';
import Feather from 'react-native-vector-icons/Feather';
import { IReportAndResourceMap, IReportResult } from '../../../../../../../PersonOmniView/LeftContainer/RecentActivity/OrdersInterface';
import { ReportCategory, getOrderFromReport } from '../../../../../../../PersonOmniView/MiddleContainer/Orders/OrdersAndReports/OrderUtils';
import { IAvailableEhr, IPersonData } from '../../../../../../../PersonOmniView/MiddleContainer/interfaces';
import { isMarkAsReviewEnabled } from '../../../../../../../../utils/capabilityUtils';
import { ReportResultView } from '../../../../../../../PersonOmniView/LeftContainer/RecentActivity/RecentReport/ReportResultView';
import { FHIR_RESOURCE } from '../../../../../../../../constants/FhirConstant';
import { allowContentTypeToViewHieSourceReportButton, getAppointmentStatusFontColor, getPanelHeaderText, getStatusFontColor, getTagColor, getTagText, getTagTextColor, getTimeLineItemDescription, isAllowToViewReportButtonForHIE } from '../FeedCollapse/FeedHeaderUtils';
import { CaretRightOutlined } from '@ant-design/icons';
import { DocStatus } from '../../../../../../../PersonOmniView/MiddleContainer/PatientNotes/interfaces';
import { SOURCE_TYPES, TIME_LINE_SOURCE_TYPES } from '../FeedConst';
import ViewEhrActionBtn from '../../../../../../../common/Svg/PersonActionSvgIcons/ViewEhrActionBtn';
import { getReportForImaging } from '../../../../../../../PersonOmniView/LeftContainer/RecentActivity/helper';
import OrderDetailsDrawer from '../../../../../../../PersonOmniView/MiddleContainer/Orders/OrdersAndReports/OrderDetailsDrawer/OrderDetailsDrawer';
import Stack from '../../../../../../../common/LayoutComponents/Stack';
import InstantChat from '../../../../../../../common/ChatDrawer/InstantChat';
import MeetingView from '../../../../../../../common/MeetingView/MeetingView';
import CreateSmsConversationDrawer from '../../../../ConversationChannelTabs/CreateSmsConversationDrawer/CreateSmsConversationDrawer';
import EmailDrawerCommon from '../../../../../../../common/EmailPopupView/EmailDrawerCommon';
import { getFormDataFromLeadData } from '../../../../../../Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateUtils';
import { getLabReportButtons } from '../../../../../../../PersonOmniView/MiddleContainer/TimelineView/TimelineViewHelper';
import { capitalize } from 'lodash';
import useEHRCapabilities from '../../../../../../../../screens/BusinessStudio/useEHRCapabilities';
import { fromBase64, getAccountUUID } from '../../../../../../../../utils/commonUtils';
import { ExternalSourceReportDrawer } from '../../../../../../../PersonOmniView/MiddleContainer/Orders/OrdersAndReports/ExternalSourceReportDrawer';
import { isValidXmlString, xmlStringToHtml } from '../../../../../../../common/XmlToHtml/xmlToHtmlUtils';
import { MediaReportView } from '../../../../../../../PersonOmniView/LeftContainer/RecentActivity/MediaReportView';
import { useIntl } from 'react-intl';
import { evnName } from '../../../../../../../../constants/BaseUrlConst';

interface CareTimeLineCardProps {
    singleFeedData: any,
    firstEntryID: string,
    onClick: (actionCode: string, singleFeedData: IFeedData) => void,
    ehrConfig: IAvailableEhr,
    loading: boolean,
    personData: IPersonData | undefined,
    unFormattedContactData: any;
}

const { width } = Dimensions.get('window');
const CareTimeLineCard = (props: CareTimeLineCardProps) => {

    const { singleFeedData, firstEntryID, onClick, ehrConfig, personData, unFormattedContactData, loading} = props;
    const [showModal, setShowModal] = useState(false)
    const [showImagingResultTable, setShowImagingResultTable] = useState(false)
    const [showHieReportData, setShowHieModal] = useState(false);
    const [selectedHieReportData, setSelectedHieReport] = useState<{
      reportType: string;
      reportName?: string;
      reportDetails: {[key: string]: any}[];
      report: any;
      isSingleNoteView: boolean,
      isHtmlFormattedData: boolean,
      htmlDataString: string,
      hieDataReportUrl?: string,
    }>(
      {
        reportType: '',
        reportName: '',
        reportDetails: [],
        report: null,
        isSingleNoteView: false,
        isHtmlFormattedData: false,
        htmlDataString: '',
        hieDataReportUrl: '',
      }
    )
    const intl = useIntl();
    const [selectedOrder, setSelectedOrder] = useState<{
      order?: RequestGroup;
      report?: DiagnosticReport;
      action?: any;
      prescription?: MedicationRequest;
      noteId?: string;
      reportType?: string;
      documentTitle?: string;
    }>({});
    const [onHover, setOnHover] = useState(false)
    const [reportLoading, setReportLoading] = useState(false)
    const [selectedReport, setSelectedReport] = useState({
        reportName: '',
        observation: [] as IReportResult[],
        resources: [] as DiagnosticReport[]
    })
    const [communicationAction, setCommunicationAction] = useState('');
    const accountLocationUuid = personData?.accountLocationUuid || '';
    const dateStr = singleFeedData?.time_stamp
        ? getDateStrFromFormat(
            singleFeedData?.time_stamp,
            DISPLAY_DATE_FORMAT
        )
        : '';
    const mainRecord = singleFeedData;
    const tagColor = getTagColor(mainRecord?.source)
    const tagTextColor = getTagTextColor(mainRecord?.source)
    const data = singleFeedData.resourceData;
    const contextData = useContext(CommonDataContext) as IFormCommonData;
    const ehrCapabilities = useEHRCapabilities({ locationId: accountLocationUuid });
    const canShowMarkAsReview = isMarkAsReviewEnabled(accountLocationUuid);

    const showDetailView = isAllowToViewReportButtonForHIE(data) ||
      singleFeedData.source.includes(SOURCE_TYPES.FOLD) ||
      singleFeedData.source.includes(SOURCE_TYPES.EHR);

    const accountUuid = getAccountUUID();

    const getEHRCapability = (
        resourceName: string
    ): any | undefined => {
        if (ehrCapabilities?.length) {
            const capabilities: IEhrCapability[] = ehrCapabilities;
            const matchingCapability = capabilities.find(
                (item) =>
                    item.resourceName === resourceName
            );
            if (matchingCapability) {
                return matchingCapability?.abilities?.keyAllowedOperations;
            }
        }
    };

    let displayDate = '';
    let displayCode = ''
    const showClaimsOnUi = false
    const isStagingEnv = !!(window.location.host.includes('.staging.foldhealth.io'));
    const isSandboxEnv = !!(window.location.host.includes('.sandbox.foldhealth.io'));

    const getResourceDate = (data: any, dateField: string) => {
        const capability = getEHRCapability(data.resourceType)?.[dateField]?.format;
        if (data[dateField]) {
            const date = getMomentObjectWithDateStringAndFormat(
                data[dateField],
                capability || ''
            );
            return getDisplayMonths(
                getMomentObjFromFormat(date, DISPLAY_DATE_FORMAT),
                getMomentObjFromFormat(new Date(), DISPLAY_DATE_FORMAT)
            );
        }
        return '';
    };

    switch (data.resourceType) {
        case FHIR_RESOURCE.PROCEDURE:
        case FHIR_RESOURCE.CONDITION:
            displayCode = data?.code?.coding?.[0]?.code;
            break;
        case FHIR_RESOURCE.MEDICATION_REQUEST:
            displayCode = data?.medicationCodeableConcept?.coding?.[0]?.code;
            break;
        default:
            break;
    }


    switch (data.resourceType) {
        case FHIR_RESOURCE.IMMUNIZATION:
            displayDate = getResourceDate(data, 'occurrenceDateTime');
            break;
        case FHIR_RESOURCE.PROCEDURE:
            displayDate = getResourceDate(data, 'performedDateTime');
            break;
        case FHIR_RESOURCE.ALLERGY:
        case FHIR_RESOURCE.CONDITION:
            displayDate = getResourceDate(data, 'onsetDateTime');
            break;
        case FHIR_RESOURCE.MEDICATION_STATEMENT:
            displayDate = getResourceDate(data, 'effectivePeriod');
            break;
        case FHIR_RESOURCE.MEDICATION_REQUEST:
            displayDate = getResourceDate(data, 'authoredOn');
            break;
        default:
            break;
    }

    const configureReportResult = async (
        selectedReport: IReportAndResourceMap,
        reportType: string,
        key: string,
        isImaging?: boolean
    ) => {
        const list: RequestGroup[] = [data] || [];
        try {
            setReportLoading(true)
            await getReportResultForDisplay(
                selectedReport,
                reportType,
                list,
                { ehrConfig, patientId: personData?.patientId || personData?.patientUuid, locationId: accountLocationUuid },
            ).then((response) => {
                const { reportName, observationList } = response
                const isDataAvailable = !!reportName && observationList.length > 0;
                setSelectedReport({
                  reportName,
                  observation: observationList,
                  resources: selectedReport?.resources || [],
                });
                if (isImaging) {
                  setShowImagingResultTable(true);
                } else {
                  setShowModal(isDataAvailable);
                }
                setReportLoading(false);
            }).catch((error) => {

                setReportLoading(false)
            })
        } catch (error) {

        }

    };

    const getReportButton = (
        report: IReportAndResourceMap,
        reportType: string,
        key: string,
        isFirstAction: boolean,
    ): JSX.Element => {
        const isMediaReport = reportType === REPORT_TYPE.MEDIA_REPORT || reportType === REPORT_TYPE.FOLD_MEDIA_REPORT;
        return (
            <Pressable
                onPress={() => {
                    configureReportResult(report, reportType, key);
                }}
                onHoverIn={() => setOnHover(true)}
                onHoverOut={() => setOnHover(false)}
                style={[
                    styles.reportButton,
                    onHover && styles.reportButtonHover,
                    { width: reportLoading ? 125 : 105 }
                ]}
            >
               {renderViewDetailsView(isMediaReport ? 'View Report' : 'View Result')}
            </Pressable>
        );
    };

    const renderViewDetailsView = (viewText: string, leftIcon?: JSX.Element) => {
       return (
         <Stack direction="row" style={styles.alignCenter}>
           {leftIcon}
           <Text
             fontSize={14}
             lineHeight={21}
             fontWeight={400}
             fontFamily={'Manrope'}
             color={Colors.Custom.PurpleColor}
           >
             {viewText}
           </Text>
           <Divider
             style={styles.divider}
           />
           <CaretRightOutlined style={reactStyles.caretRightOutlined} />
           {reportLoading && <Spinner style={styles.marginLeft} />}
         </Stack>
       );
    }

    const getReportDataForImaging = async (report?: DiagnosticReport) => {
        if (report) {
          setReportLoading(true)
          const supportingParams = {
            patientId: personData?.patientId || personData?.patientUuid,
            locationId: accountLocationUuid,
          };
          const reportForImaging = await getReportForImaging(
            report,
            canShowMarkAsReview ?? false,
            supportingParams
          );
          if (reportForImaging?.length > 0 && reportForImaging?.[0]?.type) {
            configureReportResult?.(
              reportForImaging[0],
              reportForImaging[0].type,
              `${report?.code}_${report?.id}`,
              true
            );
          }
          else  {
            setShowImagingResultTable(true);
            setReportLoading(false);
          }
        }
        return [];
      };

    const renderReportButton = (key: string): JSX.Element => {
        const latestReport = getLabReportButtons(data).resources?.[0];
        const report = getLabReportButtons(data)?.reports?.[0];
        const matchedOrder = getOrderFromReport(latestReport, []);
        if (report.orderType === ReportCategory.RAD) {
          return (
            <Pressable
              onPress={() => {
                getReportDataForImaging(latestReport);
                setSelectedOrder({
                  report: latestReport,
                  order: matchedOrder,
                  reportType: 'IMAGING',
                  documentTitle: '',
                });
              }}
              onHoverIn={() => setOnHover(true)}
              onHoverOut={() => setOnHover(false)}
              style={[
                styles.reportButton2,
                onHover && styles.reportButtonHover,
                { width: reportLoading ? 135 : 105 }
              ]}
            >
              {renderViewDetailsView('View Details')}
            </Pressable>
          );
        }

        switch (report?.type) {
            case REPORT_TYPE.BOTH:
                return (
                    <HStack>
                        {getReportButton(report, REPORT_TYPE.MEDIA_REPORT, key, true)}
                        {getReportButton(report, REPORT_TYPE.RESULT, key, false)}
                    </HStack>
                );
            case REPORT_TYPE.MEDIA_REPORT:
                return (
                    <>
                        {getReportButton(report, REPORT_TYPE.MEDIA_REPORT, key, true)}
                    </>
                );
            case REPORT_TYPE.RESULT:
                return (
                    <>
                        {getReportButton(report, REPORT_TYPE.RESULT, key, true)}
                    </>
                );
            case REPORT_TYPE.FOLD_MEDIA_REPORT:
                return (
                    <>
                        {getReportButton(report, REPORT_TYPE.FOLD_MEDIA_REPORT, key, true)}
                    </>
                );
        }
        return <></>;
    };

    const getViewDataButtonVisibility = () => {
        let isVisible = false;

        if (!showDetailView) {
          return false;
        }

        if (
          data.resourceType === FHIR_RESOURCE.DOCUMENT_REFERENCE &&
          data?.docStatus !== DocStatus.PRELIMINARY &&
          (data.extension.some((item: any) => item.url?.includes('form-id')) || ehrConfig.isElation)
        ) {
          isVisible = true;
        } else if (
          data?.source != SOURCE_TYPES.HIE &&
          ehrConfig.isAthena &&
          data.resourceType === FHIR_RESOURCE.DOCUMENT_REFERENCE &&
          data?.content?.[0]?.attachment?.data
        ) {
          isVisible = true;
        } else if (data.resourceType === FHIR_RESOURCE.APPOINTMENT && data.appointmentId) {
          isVisible = true;
        }

        return isVisible;
    }

    const reportResultView = (
      <ReportResultView
        unformmatedContactDetails={unFormattedContactData}
        contactData={personData}
        showModal={showModal}
        reportName={selectedReport.reportName}
        observations={selectedReport.observation}
        resources={selectedReport.resources as DiagnosticReport[]}
        onClose={() => {
          setShowModal(false);
        }}
        locationId={accountLocationUuid}
        onMarkedAsReviewed={() => {}}
      />
    );

    const onCloseOfReportResultView = () => {
      setCommunicationAction('');
      showDetailView(false);
      setShowImagingResultTable(false);
      setSelectedOrder((prev) => {
        return {
          ...prev,
          selectedOrder: {},
          selectedReport: {
            reportName: '',
            observation: [],
            resources: [],
            isReportReviewedByUser: false,
          },
        };
      });
    };

    const getHieReportButton = (): JSX.Element => {
      let reportName = '';
      let buttonLabel = '';
      const reportDetails: any[] = [];
      let report: any = null;
      let reportType: any = null;
      let htmlString = '';
      let hieDataReportUrl = '';
      if (data.resourceType === FHIR_RESOURCE?.DIAGNOSTIC_REPORT) {
        report = getLabReportButtons(data)?.reports?.[0];
        const coding = data?.code?.coding || [];
        coding.some((itemCode: any) => {
          if (!itemCode?.code || itemCode?.code !== 'UNK' && itemCode?.display) {
            reportName = itemCode?.display;
          }
        });
        reportDetails.push({
          key: 'Report Status',
          value: data?.status,
        });
        if (data?.meta?.lastUpdated) {
          reportDetails.push({
            key: 'Last Updated',
            value: getDateStrFromFormat(data?.meta?.lastUpdated, DATE_FORMATS.CARE_DASHBOARD_DATE_FORMAT),
          });
        }
        reportType = 'RAD';
        buttonLabel = 'View Details';
      }
      if (data.resourceType === FHIR_RESOURCE?.DOCUMENT_REFERENCE) {
        reportType = FHIR_RESOURCE?.DOCUMENT_REFERENCE;
        buttonLabel = 'View Note';
        const noteStatus = data?.docStatus;
        let noteView = '';
        switch(noteStatus) {
          case DocStatus.AMENDED:
          case DocStatus.FINAL:
            noteView = 'Signed';
          break;
          case DocStatus.PRELIMINARY:
            noteView = 'In Progress';
            break;
        }
        let isValidReport = false;
        const resourceDataContent = data?.content || [];
        let isUrlBasedNoteReport = false;
        for (const content of resourceDataContent) {
          const contentType = content?.attachment?.contentType;
          if ((allowContentTypeToViewHieSourceReportButton(contentType)) && (content?.attachment?.data?.length || content?.attachment?.url?.length)) {
            isValidReport = true;
            if (contentType == 'application/pdf' ||  contentType == 'image/jpeg') {
              isUrlBasedNoteReport = true;
            }
          }
        }
        if (isValidReport) {
          reportName = 'Patient Note';
          reportDetails.push({
            key: 'Note Status',
            value: noteView ,
          });
          reportDetails.push({
            key: 'Last Updated',
            value: getDateStrFromFormat(data?.date, DATE_FORMATS.CARE_DASHBOARD_DATE_FORMAT),
          });
          report = {
            orderType: FHIR_RESOURCE?.DOCUMENT_REFERENCE,
            resources: [data],
          }

          if (resourceDataContent?.length === 1 || isUrlBasedNoteReport) {
            if (isUrlBasedNoteReport) {
              const urlBasedNoteReport = resourceDataContent?.find((dataContent: any) => {
                return dataContent?.extension?.some((extensionData: any) => {
                  return extensionData?.valueCoding?.code === 'FOLDHEALTH';
                });
              });
              if (urlBasedNoteReport) {
                const attachment: { contentType: string; title?: string; url?: string; size?: number; } = urlBasedNoteReport?.attachment || {};
                if (attachment?.url && attachment?.title) {
                  hieDataReportUrl = `document/${accountUuid}/${personData?.patientId || personData?.patientUuid}/${attachment?.title}`;
                }
              }
            } else {
                const resourceContent = resourceDataContent?.[0];
                const attachment : { contentType: string; data?: string; url?: string; } = resourceContent?.attachment || {};
                const content = fromBase64(attachment?.data);
                const isValidXmlStringContent = isValidXmlString(content);
                if (isValidXmlStringContent) {
                    htmlString = xmlStringToHtml(content)
                } else {
                  htmlString = `<div>${content}</div>`;
                }
              }
          }
        }
      }
      if (!report || !reportName) {
        return (<></>);
      }
      return (
        <Pressable
          onPress={() => {
            setShowHieModal(true);
            setSelectedHieReport((prev: any) => {
              return {
                ...prev,
                report: report,
                reportDetails: reportDetails,
                reportName: reportName,
                reportType: reportType,
                htmlDataString: htmlString || '',
                isHtmlFormattedData: htmlString?.length ? true : false,
                isSingleNoteView: htmlString?.length || hieDataReportUrl?.length ? true : false,
                hieDataReportUrl: hieDataReportUrl,
              }
            });
          }}
          onHoverIn={() => setOnHover(true)}
          onHoverOut={() => setOnHover(false)}
          style={[
            styles.reportButton2,
            onHover && styles.reportButtonHover,
            { width: reportLoading ? 135 : 105 }
          ]}
        >
          {renderViewDetailsView(buttonLabel)}
        </Pressable>
      );
    }

    return (
        <View key={singleFeedData?.id}>
            <HStack style={styles.containerWidth} flex={1} alignItems={'flex-start'}>
                <VStack
                    flex={1}
                    borderRadius={10}
                    backgroundColor={isWeb() ? 'none' : '#FFFFFF'}
                    marginRight={isWeb() ? 0 : 4}
                    paddingTop={0}
                    paddingBottom={0}
                    paddingLeft={3}
                >
                    <HStack display={'flex'}>
                        <VStack paddingLeft={'20px'} flex={2}>
                            
                            {data.resourceType === 'Claim' && (showClaimsOnUi || isSandboxEnv || isStagingEnv) || data.resourceType != 'Claim' && 
                            ( <>
                                <Text color="#6F7A90" style={styles.dateText}>{dateStr}</Text>
                                <Text style={styles.headerText}>{getPanelHeaderText(mainRecord, 'description')}</Text>
                              </>
                            )}
                            {/* {data?.description && <Text fontWeight={'400'} flexWrap={'wrap'} color={Colors.FoldPixel.GRAY300}>{data.description}</Text>} */}
                            {displayDate?.length > 0 && <Text>{displayDate}</Text>}

                            {displayCode?.length > 0 && <Text>{displayCode}</Text>}
                            {data.resourceType === 'Claim' && (showClaimsOnUi || isSandboxEnv || isStagingEnv) && (
                              <>
                                <Stack direction="row">
                                  <Text>
                                    {getDateStrFromFormat(
                                      data?.billablePeriod?.start,
                                      DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT
                                    )} -{' '}
                                    {getDateStrFromFormat(
                                      data?.billablePeriod?.end,
                                      DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT
                                    )}
                                  </Text>
                                </Stack>
                                {data?.item.map(
                                  (item: any, index: any) => ( <>
                                    <Stack direction="row">{item?.productOrService?.coding?.[0]?.display} ({item?.productOrService?.coding?.[0]?.code})</Stack>
                                    <Stack direction="row">
                                        Plan Paid:{' '}
                                        {intl.formatNumber(item?.net?.value, {
                                          style: 'currency',
                                          currency: 'USD',
                                          currencyDisplay: 'narrowSymbol',
                                          maximumFractionDigits: 0,
                                          minimumFractionDigits: 0,
                                        })}
                                    </Stack>
                                  </>
                                  )
                                )}

                                <Stack direction="row">
                                  <Text>
                                    Total Claim Paid:{' '}
                                    {intl.formatNumber(data?.total?.value, {
                                      style: 'currency',
                                      currency: 'USD',
                                      currencyDisplay: 'narrowSymbol',
                                      maximumFractionDigits: 0,
                                      minimumFractionDigits: 0,
                                    })}
                                  </Text>
                                </Stack>
                              </>
                            )}
                            {data.resourceType === 'Appointment' && (
                              <Stack direction="row">
                                {data?.username && (
                                  <Text style={styles.usernameText}>
                                    {data.username}
                                  </Text>
                                )}
                                {data?.status && (
                                  <Text
                                    flexWrap={'wrap'}
                                    fontSize={12}
                                    fontWeight={'600'}
                                    color={getAppointmentStatusFontColor(data.status)}
                                  >{`${data.username ? ' • ' : ''}${capitalize(data.status)
                                    .split('-')
                                    .join(' ')}`}</Text>
                                )}
                              </Stack>
                            )}
                            {data?.docStatus && <Text fontWeight={'600'} color={getStatusFontColor(data?.docStatus)} fontSize={12}>{getDocumentStatusLabelInCareTimeline(data?.docStatus)}</Text>}
                        </VStack>
                        <HStack alignItems={'flex-start'} marginRight={3}>
                            <Text
                              style={[
                                styles.tagText,
                                { 
                                  backgroundColor: tagColor,
                                  color: tagTextColor
                                }
                              ]}
                            >
                              {getTagText(mainRecord?.source, contextData.userSettings) || mainRecord?.source}
                            </Text>
                            {/* Following code is kept for future use */}
                            {/* <ThreeDotIconSvg style={ { padding: 0, marginTop: 10 }} />   */}
                        </HStack>
                    </HStack>

                    {getViewDataButtonVisibility() && <HStack style={styles.viewDataButton}>
                        <Pressable
                            onHoverIn={() => setOnHover(true)}
                            onHoverOut={() => setOnHover(false)}
                            style={[
                                styles.viewDataPressable,
                                onHover && styles.viewDataPressableHover
                            ]}
                            onPress={() => {
                                onClick(singleFeedData.resourceType, data);
                            }}
                        >
                          {data.resourceType === FHIR_RESOURCE.DOCUMENT_REFERENCE && <DocumentIconSvg />}
                          <Text
                            fontSize={14}
                            lineHeight={21}
                            marginLeft={data.resourceType === FHIR_RESOURCE.DOCUMENT_REFERENCE ? 1 : 0}
                            fontWeight={400}
                            fontFamily={'Manrope'}
                            color={Colors.Custom.PurpleColor}
                          >
                            {data.resourceType === 'Appointment' ? 'View Appointment' : 'View Note'}
                          </Text>
                          <Divider style={styles.divider} />
                          <CaretRightOutlined style={reactStyles.caretRightOutlined} />
                          {loading && <Spinner style={styles.marginLeft} />}
                        </Pressable>
                    </HStack>}
                    {(data?.source === SOURCE_TYPES.HIE &&  data.resourceType === FHIR_RESOURCE?.DIAGNOSTIC_REPORT ||  data?.resourceType === FHIR_RESOURCE.DOCUMENT_REFERENCE) && showDetailView &&
                        <View marginLeft={5} marginTop={1}>
                            {getHieReportButton()}
                        </View>
                    }
                    {( data?.source !== SOURCE_TYPES.HIE && data.resourceType === FHIR_RESOURCE?.DIAGNOSTIC_REPORT) && showDetailView &&
                        <View marginLeft={5} marginTop={1}>
                            {renderReportButton(data.code.coding?.[0]?.code)}
                        </View>
                    }
            {showModal && (
              <ReportResultView
                unformmatedContactDetails={unFormattedContactData}
                contactData={personData}
                showModal={showModal}
                reportName={selectedReport.reportName}
                observations={selectedReport.observation}
                resources={selectedReport.resources as DiagnosticReport[]}
                onClose={() => {
                  setShowModal(false);
                }}
                onMarkedAsReviewed={() => {}}
              />
            )}
            {showImagingResultTable && (
              <OrderDetailsDrawer
                isVisible={showImagingResultTable}
                report={selectedOrder.report}
                documentTitle={selectedOrder.documentTitle}
                order={selectedOrder.order}
                action={selectedOrder.action}
                prescription={selectedOrder.prescription}
                onClose={() => {
                  setShowImagingResultTable(false);
                }}
                reportType={selectedOrder.reportType}
                showReportResultView={showImagingResultTable}
                reportResultView={reportResultView}
                selectedReportResources={
                  selectedReport?.resources as DiagnosticReport[]
                }
                unformmatedContactDetails={unFormattedContactData}
                contactData={personData}
                onCommunicationActionPerformed={(actionCode: string) => {
                  setCommunicationAction(actionCode);
                  setShowImagingResultTable(false);
                }}
              />
            )}
            {communicationAction ===
              RIGHT_SIDE_CONTAINER_CODE.CONTACT_MAIL_POPUP && (
              <EmailDrawerCommon
                contactData={personData}
                category={{
                  code: 'GENERAL',
                  name: '',
                }}
                onMailCompleteAction={() => {
                  onCloseOfReportResultView();
                }}
              />
            )}

            {communicationAction ===
              RIGHT_SIDE_CONTAINER_CODE.CONTACT_SMS_DRAWER && (
              <CreateSmsConversationDrawer
                isDrawerVisible={true}
                selectedInbox={{} as any}
                onCreateSmsConversationActionPerformed={(
                  actionCode: string,
                  actionData: any
                ) => {
                  onCloseOfReportResultView();
                }}
                selectedPatient={unFormattedContactData}
              />
            )}

            {communicationAction ===
              RIGHT_SIDE_CONTAINER_CODE.CREATE_MEETING_VIEW && personData && (
              <MeetingView
                personData={getFormDataFromLeadData(personData || {}, contextData)}
                onPersonActionPerformed={() => {
                  onCloseOfReportResultView();
                }}
              />
            )}

            {communicationAction ===
              RIGHT_SIDE_CONTAINER_CODE.CONTACT_CHAT_DRAWER && (
              <InstantChat
                contactUuid={personData?.contactUUID}
                isDrawerVisible={true}
                contactData={personData}
                onActionPerformed={()=> {
                  onCloseOfReportResultView();
                }}
              />
            )}
            {!!(showHieReportData && !selectedHieReportData?.isSingleNoteView) && (
              <ExternalSourceReportDrawer
                isVisible={showHieReportData}
                reportType={selectedHieReportData?.reportType}
                reportName={selectedHieReportData?.reportName}
                reportDetails={selectedHieReportData?.reportDetails || []}
                report={selectedHieReportData?.report}
                showCustomButton={true}
                onClose={() => {
                  setShowHieModal(false);
                }}
                unformmatedContactDetails={unFormattedContactData}
                contactData={personData}
                onCommunicationActionPerformed={(actionCode: string) => {
                  setCommunicationAction(actionCode);
                  setShowHieModal(false);
                }}
              />
            )}
            {!!(showHieReportData && selectedHieReportData?.isSingleNoteView) && (
              <MediaReportView
                showReportPdf={true}
                reportSource={SOURCE_TYPES.HIE}
                sourceType={SOURCE_TYPES.HIE}
                drawerTitle={'Note Details'}
                htmlDataString={selectedHieReportData?.htmlDataString}
                isHtmlFormattedData={selectedHieReportData.isHtmlFormattedData}
                hieDataReportUrl={selectedHieReportData?.hieDataReportUrl}
                isImaging={true}
                onClose={() => {
                  setShowHieModal(false);
                }}
            />
            )}
            </VStack>
          </HStack>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    containerWidth: {
      minWidth: width * 0.25
    },
    dateText: {
        lineHeight: 20,
        fontWeight: '500',
        letterSpacing: 1,
        fontSize: 12,
    },
    headerText: {
        fontWeight: '500',
        color: '#3A485F',
    },
    usernameText: {
        flexWrap: 'wrap',
        fontSize: 12,
        fontWeight: '600',
        color: Colors.FoldPixel.GRAY300,
    },
    statusText: {
        flexWrap: 'wrap',
        fontSize: 12,
        fontWeight: '600',
    },
    docStatusText: {
        fontWeight: '600',
        fontSize: 12,
    },
    tagText: {
        paddingHorizontal: 6,
        paddingVertical: 2,
        borderRadius: 4,
        fontSize: 14,
        fontWeight: '400',
    },
    viewDataButton: {
        paddingTop: 4,
        alignItems: 'center',
    },
    viewDataPressable: {
        flexDirection: 'row',
        borderRadius: 8,
        padding: 2,
        alignItems: 'center',
        marginLeft: '18px',
    },
    viewDataPressableHover: {
        backgroundColor: '#F9F5FF',
    },
    divider: {
        width: 0.5,
        height: 16,
        marginLeft: 6,
        marginRight: 6,
        backgroundColor: '#D7C0FF'
    },
    reportButton: {
        flexDirection: 'row',
        borderRadius: 8,
        padding: 2,
        alignItems: 'center'
    },
    reportButton2: {
        borderRadius: 8,
        padding: 2,
    },
    reportButtonHover: {
        backgroundColor: '#F9F5FF',
    },
    hieReportButton: {
        marginLeft: 5,
        marginTop: 1,
    },
    alignCenter: {
        alignItems: 'center',
    },
    marginLeft: {
        marginLeft: 5,
    },
});

const reactStyles: Record<string, React.CSSProperties> = {
  caretRightOutlined: {
    color: '#8C5AE2',
    fontSize: 10,
  },
};

export default CareTimeLineCard
