import {Button, HStack, Spacer, Text, VStack, Modal} from 'native-base';
import React from 'react';
import {useIntl} from 'react-intl';
import {Colors} from '../../../styles/Colors';
import {FoldButton} from '../../CommonComponents/FoldButton/FoldButton';
import {DisplayText} from '../DisplayText/DisplayText';
import {FHAlertDialogProps} from './interface';
import { TestIdentifiers, testID } from '../../../testUtils';

const FHAlertDialog = (props: FHAlertDialogProps) => {
  const cancelRef = React.useRef(null);
  const intl = useIntl();
  return (
    <Modal isOpen={props.isOpen}>
      {props.spinnerView}
      <Modal.Content
        maxWidth={'35%'}
        height={props?.customHeight ? props?.customHeight : '22%'}
        style={{
          backgroundColor: Colors.Custom.BackgroundColor,
        }}
      >
        <Modal.Header>
          {/* {props.header || intl.formatMessage({id: 'defaultAlertDialogHeader'})} */}
          {props.header ? (
            props.header
          ) : (
            <DisplayText
              textLocalId="defaultAlertDialogHeader"
              textType="Heading"
            />
          )}
        </Modal.Header>
        <Modal.Body>
          <VStack space={2}>
            {props.bodyContent ? (
              props.bodyContent
            ) : (
              <Text {...testID(TestIdentifiers.alertMessage)}>
                {props.message ||
                  intl.formatMessage({id: 'defaultAlertDialogMessage'})}
              </Text>
            )}
          </VStack>
        </Modal.Body>
        <Modal.Footer
          style={{
            backgroundColor: Colors.Custom.BackgroundColor,
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button.Group>
            {props.buttonActions.map((buttonAction, index) => {
              return (
                <FoldButton
                  key={index}
                  nativeProps={{
                    variant: buttonAction.buttonProps.variant,
                    onPress: () => {
                      buttonAction.onPress();
                    },
                    ...buttonAction.buttonProps,
                    ...testID(`${buttonAction.textLocalId}${TestIdentifiers.alertBtn}`)
                  }}
                  customProps={{
                    btnText: intl.formatMessage({id: buttonAction.textLocalId}),
                    withRightBorder: false,
                  }}
                ></FoldButton>
              );
            })}
          </Button.Group>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

export default FHAlertDialog;
