import React from 'react';
import {Path, Svg} from 'react-native-svg';
import {ICommonSvgProps} from '../../components/common/Svg/interfaces';
import {Colors} from '../../styles';

export const EmailIconSvg = (props: ICommonSvgProps) => {
  const {customStrokeColor} = props;
  const strokeColor = customStrokeColor
    ? customStrokeColor
    : props.isActive
    ? Colors.Custom.Gray500
    : Colors.Custom.Gray300;

  return (
    <Svg width="20" height="16" viewBox="0 0 16 12" fill="none">
      <Path
        d="M3.99992 3.33329L5.43918 4.53268C6.66361 5.55303 7.27582 6.06321 7.99992 6.06321C8.72402 6.06321 9.33623 5.55303 10.5607 4.53268L11.9999 3.33329M6.66659 11.3333H9.33325C11.8474 11.3333 13.1045 11.3333 13.8855 10.5522C14.6666 9.7712 14.6666 8.51412 14.6666 5.99996C14.6666 3.4858 14.6666 2.22872 13.8855 1.44767C13.1045 0.666626 11.8474 0.666626 9.33325 0.666626H6.66659C4.15243 0.666626 2.89535 0.666626 2.1143 1.44767C1.33325 2.22872 1.33325 3.4858 1.33325 5.99996C1.33325 8.51412 1.33325 9.7712 2.1143 10.5522C2.89535 11.3333 4.15243 11.3333 6.66659 11.3333Z"
        stroke={strokeColor}
        strokeLinecap="round"
      />
    </Svg>
  );
};