import React from 'react';
import { Svg, Path } from 'react-native-svg';

const RequestGroupIconSvg = () => {
  return (
    <Svg
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 22 22"
    >
      <Path
        stroke="#6F7A90"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.038 2c1.638 0 2.986 1.38 2.986 3.08 0 1.7-1.338 3.07-2.986 3.07m4.595 8.59C21 16.12 21 15.08 21 13.91c0-1.72-2.181-3.16-5.031-3.42M12.634 5c0 2.21-1.736 4-3.878 4-2.143 0-3.878-1.79-3.878-4s1.735-4 3.878-4c2.142 0 3.877 1.79 3.877 4zm3.877 11.5c0 2.49 0 4.5-7.755 4.5C1 21 1 18.99 1 16.5S4.47 12 8.756 12c4.285 0 7.755 2.01 7.755 4.5z"
      ></Path>
    </Svg>
  );
};


export default RequestGroupIconSvg;
