import {StyleSheet} from 'react-native';
export const styles = StyleSheet.create({
  badgeText: {
    fontFamily: 'Manrope',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: 12,
    lineHeight: 18,
    color: 'white',
  },
  alertText: {
    fontFamily: 'Manrope',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 20,
    color: '#344054',
  },
});
