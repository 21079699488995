import React from 'react';
import Svg, { Path } from "react-native-svg";

const StartTimerSvg = (props: { strokeColor?: string }) => {
  return (
    <Svg width="16" height="17" viewBox="0 0 16 17" fill="none">
      <Path
        d="M8 9.16634V6.49967M6.66667 1.83301H9.33333M14 9.16634C14 12.4801 11.3137 15.1663 8 15.1663C4.68629 15.1663 2 12.4801 2 9.16634C2 5.85263 4.68629 3.16634 8 3.16634C11.3137 3.16634 14 5.85263 14 9.16634Z"
        stroke="#8C5AE2"
        stroke-linecap="round"
      />
    </Svg>
  );
};

export default StartTimerSvg;
