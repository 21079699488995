import {Tooltip} from 'antd';
import React from 'react';
import './MessagingWindowHeaderComponentsStyles.css';
import SelectChatActionSvg from '../../../../../common/Svg/PersonActionSvgIcons/SelectChatActionSvg';
import {Colors} from '../../../../../../styles';
import {testID} from '../../../../../../testUtils';
import {FHTooltip} from '../../../../../common/FHTooltip';

const SelectChatView = ({handleSelectChat}: {handleSelectChat: () => void}) => {
  return (
    <Tooltip title={'Select Chats'}>
      <div
        {...testID('SelectChatsClick')}
        onClick={handleSelectChat}
        className="pressable"
      >
        <div className="vstack">
          <SelectChatActionSvg
            customStrokeColor={Colors.FoldPixel.GRAY300}
            width={20}
            height={20}
          />
        </div>
      </div>
    </Tooltip>
  );
};

export default SelectChatView;
