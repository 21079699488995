import {StyleSheet} from 'react-native';
import {Colors} from '../../../../styles/Colors';

export const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  avatarStyle: {
    justifyContent: 'center',
    marginBottom: 10,
  },
  rceMboxLeft: {
    backgroundColor: 'white',
    alignSelf: 'flex-start',
    borderColor: Colors.Custom.Gray50,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 8,
  },

  rceContainerMbox: {
    width: '100%',
    flexDirection: 'column',
  },

  rceMboxClearPadding: {
    paddingBottom: 3,
  },

  rceMboxBody: {
    margin: 0,
    padding: 0,
    position: 'relative',
  },

  rceMboxTitleClear: {
    marginBottom: '5px',
  },

  rceMboxText: {
    width: 'auto',
    fontSize: 12,
    fontWeight: '300',
    textAlign: 'flex-end',
    wordBreak: 'break-word',
  },

  rceMboxTime: {
    textAlign: 'right',
    color: 'white',
    fontSize: 8,
    position: 'absolute',
    right: 15,
    bottom: 5,
  },

  rceMboxRight: {
    color: Colors.Custom.TextColor,
    shadowRadius: 0,
    alignSelf: 'flex-end',
    borderColor: Colors.Custom.Gray300,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 0,
  },

  rceMbox: {
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    marginTop: 3,
    flexDirection: 'column',
    borderRadius: 8,
    marginBottom: 3,
    paddingTop: 10,
    paddingRight: 16,
    paddingBottom: 10,
    paddingLeft: 16,
    boxShadow:
      '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
  },

  atttachmentbox: {
    width: '100%',
    borderStyle: 'solid',
    borderRadius: 8,
    boxShadow:
      '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
  },

  textStylingLeft: {
    fontWeight: '300',
    fontSize: 14,
    textAlign: 'left',
  },

  textStylingRight: {
    fontWeight: '300',
    fontSize: 14,
    textAlign: 'right',
  },
  rightDateStrStyle: {
    textAlign: 'right',
    marginTop: 2,
  },
  leftDateStrStyle: {
    textAlign: 'left',
    marginTop: 2,
  },
  nativeRceMboxRight: {
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 5,
    paddingHorizontal: 16,
    paddingVertical: 15,
  },
  nativeRceMboxLeft: {
    borderTopLeftRadius: 5,
    borderTopRightRadius: 20,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    paddingHorizontal: 16,
    paddingVertical: 12,
    // boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
    shadowColor: `rgba(16, 24, 40, 0.05`,
    shadowRadius: 1,
    shadowOffset: {
      width: 1,
      height: 1,
    },
    shadowOpacity: 0.1,
  },
});
