import { MAIN_MENU_CODES } from "../../SideMenuBar/SideBarConst";

export const onChangeView = (code: string, isNewTab?:boolean) => {
  let ref:any;
  let url;
  window.name = (window?.location?.href || '').indexOf('#/admin/') != -1 ? MAIN_MENU_CODES.DESIGNER : MAIN_MENU_CODES.HOME;
  if (code === 'ADMIN') {
    const adminUrl = window?.location?.origin + '/#/admin/patients/automation';
    url = adminUrl ;
    ref= window.open('', MAIN_MENU_CODES.DESIGNER);
  } else {
    const homeUrl = window?.location?.origin + '/#/home';
    url = homeUrl ;
    ref = window.open('', MAIN_MENU_CODES.HOME);
    // window.location.href = window.location.origin + '/#/home';
    //   window.location.reload();
    //   window.location.href = window.location.origin + '/#/home';
  }
  if(ref?.location && ref?.location == 'about:blank'){
    ref.location.href = url;
    ref.focus();
  } else {
    ref.focus();
  }
};


