import React, {useEffect, useState} from 'react';
import {Center, Text, VStack, View, useToast} from 'native-base';
import {IPatientNoteCardProps} from '../../interfaces';
import Header from '../../PatientNotes/components/Header/Header';
import {isWeb} from '../../../../../utils/platformCheckUtils';
import {getEncounterDetailById} from '../../../../../services/CommonService/AidBoxService';
import {getEncounterNoteData} from '../../PatientNotes/EncounterNotes/EncounterNotesHelper';
import {IEncounterNote} from '../../PatientNotes/EncounterNotes/interfaces';
import {Skeleton} from 'antd';
import ReactHtmlParser from 'react-html-parser';
import Feather from 'react-native-vector-icons/Feather';
import {Colors} from '../../../../../styles';
import {useIntl} from 'react-intl';
import { ToastType, showToast } from '../../../../../utils/commonViewUtils';
import { usePrintVistHistory } from '../../../../CustomHooks/usePrintVisitHistory';
import { FoldButton } from '../../../../CommonComponents/FoldButton/FoldButton';
import { BUTTON_TYPE } from '../../../../../constants';
import { DocumentViewer } from '../../../../common/DocumentViewer/DocumentViewer';
import { MIME_TYPES } from '../../../../common/DocumentViewer/DocumentViewerHelper';
import { styles } from '../../../../common/ModalActionBtn/ModalActionBtnStyle';

interface IEncounterView {
  encounterDetails: IPatientNoteCardProps;
  handleGoBack: () => void;
  patientId: string;
  locationId?: string;
}

const EncounterView = (props: IEncounterView) => {
  const intl = useIntl();
  const toast = useToast();
  const {encounterDetails, patientId, handleGoBack, locationId} = props;
  const [componentState, setComponentState] = useState({
    loading: false,
    note: {} as IEncounterNote,
    error: false,
    isProcessing: false,
    showPDFViewer: false,
    pdfBlobUrl: '',
  });

  const handleVistHistoryPrintSuccess = (url:string) => {
    setComponentState(prev => {
      return {
        ...prev,
        isProcessing: false,
        showPDFViewer: true,
        pdfBlobUrl: url,
      };
    });
  }
  const handleVistHistoryPrintError = (error: string) => {
    setComponentState(prev => {
      return {
        ...prev,
        isProcessing: false,
      };
    });
    showToast(toast, error, ToastType.error);
  };
  const {triggerPrint} = usePrintVistHistory(
    patientId,
    handleVistHistoryPrintSuccess,
    handleVistHistoryPrintError
  );

  const getNote = async () => {
    try {
      const response = await getEncounterDetailById(
        patientId,
        encounterDetails?.resourceId?.toString(),
        locationId,
      );
      const data = getEncounterNoteData(response.data?.entry?.[0]?.resource);
      if (data?.html?.length) {
        setComponentState((prev) => {
          return {
            ...prev,
            loading: false,
            note: data,
            error: false,
          };
        });
      } else {
        setComponentState((prev) => {
          return {
            ...prev,
            loading: false,
            error: true,
          };
        });
      }
    } catch (error) {
      setComponentState((prev) => {
        return {
          ...prev,
          loading: false,
          error: true,
        };
      });
    }
  };

  useEffect(() => {
    if (componentState.loading) {
      return;
    }
    setComponentState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    if (!patientId) {
      setComponentState((prev) => {
        return {
          ...prev,
          loading: false,
          error: true,
        };
      });
    }
    getNote();
  }, []);

  const renderContent = (): JSX.Element => {
    if (componentState.loading) {
      return <Skeleton active />;
    }
    if (componentState.error) {
      return (
        <VStack
          h="100%"
          pt={20}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Feather
            name="alert-circle"
            style={styles.alertCircle}
          />
          <Text fontSize={'xl'} mt={8} color={Colors.Custom.Gray500}>
            {intl.formatMessage({id: 'apiErrorMsg'})}
          </Text>
        </VStack>
      );
    }
    return <>{ReactHtmlParser(componentState.note.html || '')}</>;
  };

  const getHeaderAction = () => {
    const isDisabled = componentState.isProcessing || componentState.loading || componentState.error;
    const isLoading = componentState.isProcessing;
    return (
      <FoldButton
        customProps={{
          btnText: intl.formatMessage({
            id: 'print',
          }),
        }}
        nativeProps={{
          leftIcon: (
            <Feather
              name="printer"
              size={20}
              color={
                isLoading || isDisabled
                  ? Colors.Custom.Gray200
                  : Colors.Custom.mainPrimaryPurple
              }
            />
          ),
          isDisabled: isDisabled,
          variant: BUTTON_TYPE.PRIMARY,
          backgroundColor: Colors.Custom.BackgroundColor,
          isLoading: isLoading,
          color: Colors.Custom.mainPrimaryPurple,
          onPress: () => {
            setComponentState(prev => ({...prev,isProcessing: true}));
            triggerPrint(componentState?.note?.id as string);
          },
        }}
      />
    );
  }
  return (
    <View flex={1}>
      {isWeb() && (
        <Header
          showBackButton
          form={{name: '', id: ''}}
          showTemplateSelection={false}
          backButtonAction={() => {
            props.handleGoBack();
          }}
          title={
            <>
              <VStack>
                <Text
                  style={styles.headerText}
                >
                  {componentState?.note.visitName}
                </Text>
                <Text
                  style={styles.providerText}
                >
                  Provider: {componentState.note.providerName}
                </Text>
              </VStack>
            </>
          }
          headerAction={getHeaderAction()}
          locationId={locationId}
        />
      )}
      <View mx={4} mt={2}>
        {renderContent()}
      </View>
      {componentState.showPDFViewer && (
        <DocumentViewer
          fileName={componentState?.note.visitName || ''}
          fileType={MIME_TYPES.PDF}
          onClose={() => {
            setComponentState((prev) => ({
              ...prev,
              showPDFViewer: false,
              pdfBlobUrl: '',
            }));
          }}
          fileUrl={componentState.pdfBlobUrl}
          isOpen={true}
        />
      )}
    </View>
  );
};

export default EncounterView;
