import {View, Text} from 'react-native';
import React from 'react';
import {Divider, FlatList, HStack, VStack} from 'native-base';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import {Colors} from '../../../../../styles';

export interface ICustomComponentPreviewData {
  id: string;
  title: string;
  subtitle?: string;
}

const DetailPreview = (props: {
  children: JSX.Element;
  titleLocalId: string;
}) => {
  const {titleLocalId, children} = props;
  return (
      <VStack py={2}>
        <VStack>
          <DisplayText
            extraStyles={{
              fontWeight: 800,
              color: '#000'
            }}
            size={'lgMedium'}
            textLocalId={titleLocalId}
          />
          <Divider my={2} backgroundColor={'black'} />
        </VStack>
        <VStack>
          {children}
        </VStack>
      </VStack>
  );
};

export default DetailPreview;
