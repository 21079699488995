import React, {useContext} from 'react';
import { VStack, Text, View, FlatList, Divider } from 'native-base';
import { IUser } from '../../../../../Interfaces';
import { EHRName } from '../../../MiddleContainer/PatientNotes/interfaces';
import { getCurrentEHR } from '../../../MiddleContainer/PatientNotes/PatientNotesHelper';
import { CareTeamAction } from '../CareTeamConst';
import { formatRoleArray, getUserItemPopoverAction } from '../CareTeamUtils';
import CareTeamMemberCard from './CareTeamMemberCard';
import {
  getMlovIdFromCode,
  getMlovListByCategory,
} from '../../../../../utils/mlovUtils';
import { MLOV_CATEGORY } from '../../../../../constants';
import { CARE_TEAM_MEMBER_TYPE } from '../../../../../constants/MlovConst';
import { Popover } from 'antd';
import Feather from 'react-native-vector-icons/Feather';
import { Pressable } from 'react-native';
import { useIntl } from 'react-intl';
import {isEnableCareProgram} from '../../../../../utils/commonUtils';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import { StyleSheet } from 'react-native';

const CareTeamMemberList = (props: {
  localTeam: IUser[];
  currentTeam: IUser[];
  handleDelete: (id: string, isLocal: boolean) => void;
  agentsData: any;
  handleDeleteConfirmation?: (id: string) => void;
  selectedMemberType: string;
  onActionPressed: (action: CareTeamAction, user: IUser) => void;
  locationId: string | undefined;
}) => {
  const intl = useIntl();
  const {locationId} = props;
  const careTeamMemberTypesList = getMlovListByCategory(
    MLOV_CATEGORY.CARE_TEAM_MEMBER_TYPE
  );

  const careTeamMemberTypes = {
    [CARE_TEAM_MEMBER_TYPE.BILLING_PRACTITIONER]: getMlovIdFromCode(
      careTeamMemberTypesList,
      CARE_TEAM_MEMBER_TYPE.BILLING_PRACTITIONER
    ),
    [CARE_TEAM_MEMBER_TYPE.PCP]: getMlovIdFromCode(
      careTeamMemberTypesList,
      CARE_TEAM_MEMBER_TYPE.PCP
    ),
    [CARE_TEAM_MEMBER_TYPE.CARE_MANAGER]: getMlovIdFromCode(
      careTeamMemberTypesList,
      CARE_TEAM_MEMBER_TYPE.CARE_MANAGER
    ),
  };

  const currentEHR = getCurrentEHR(locationId, '');
  const isElation = currentEHR === EHRName.ELATION;
  const {userSettings} = useContext(CommonDataContext);
  const isCareProgramEnabled = isEnableCareProgram(userSettings);

  const getUserList = (team: IUser[]) => {
    return (
      team?.map((user) => {
        const matchUser = props.agentsData?.find((agent: any) => {
          if (user?.userId === agent?.id) {
            return agent;
          }
        });
        if (matchUser) return {...user, agent: matchUser};
        else return user;
      }) || []
    );
  };

  const getUserCareTeamRoles = (userId: string) => {
    const user = props?.currentTeam?.find(
      (user: IUser) => user?.uuid === userId
    );
    const roles: string[] = [];
    if (user) {
      if (user) {
        user?.memberType?.forEach((mem) => {
          roles.push(
            careTeamMemberTypesList.find((type) => type.id === mem)?.value || ''
          );
        });
      }
    }

    return roles.filter((role) => role !== '');
  };

  const renderUser = (data: {member: any; index: number}) => {
    const {member, index} = data;
    return (
      <CareTeamMemberCard
        key={member.id}
        index={index}
        name={member.name}
        id={member.id}
        uuid={member.uuid}
        roles={formatRoleArray(member.userRoles)}
        isLocal={member.isLocal}
        profileUrl={
          member?.agent?.thumbnail.includes('d=404')
            ? ''
            : member?.agent?.thumbnail
        }
        handleDeleteConfirmation={props.handleDeleteConfirmation}
        getUserCareTeamRoles={getUserCareTeamRoles}
        actionView={
          <Popover
            overlayInnerStyle={{
              borderRadius: 16,
            }}
            style={antdStyles.closeIcon}
            placement="bottom"
            content={
              <FlatList
                data={getUserItemPopoverAction({
                  user: member,
                  careTeamMemberTypes,
                  CARE_TEAM_MEMBER_TYPE,
                  isElation,
                  isCareProgramEnabled,
                })}
                ItemSeparatorComponent={Divider}
                renderItem={({item}) => (
                  <Pressable
                    onPress={() => {
                      props?.onActionPressed?.(item.action, member);
                    }}
                    style={styles.closeIcon}
                  >
                    <Text>{intl.formatMessage({id: item.title})}</Text>
                  </Pressable>
                )}
                keyExtractor={(item) => item.title}
              />
            }
          >
            <Feather name="more-vertical" size={20} color="#000000" />
          </Popover>
        }
      />
    );
  };

  return (
    <VStack>
      {props?.currentTeam?.length > 0 && (
        <View key="CareTeamMembers">
          <Text>Care Team Members</Text>
          <FlatList
            data={getUserList(props?.currentTeam)}
            renderItem={({item, index}) =>
              renderUser({
                member: item,
                index,
              })
            }
          />
        </View>
      )}
    </VStack>
  );
};

const styles = StyleSheet.create({
  closeIcon: {
    padding: 4,
  }
});
const antdStyles: Record<string, React.CSSProperties> = {
  closeIcon: {
    borderWidth: 1,
  }
}

export default CareTeamMemberList;
