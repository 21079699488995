import {getParentIdx, IBlockData} from '@foldhealth/easy-email-core';
import {
  useBlock,
  useEditorProps,
  useFocusIdx,
} from '@foldhealth/easy-email-editor';
import {get} from 'lodash';
import {
  Box,
  FormControl,
  HStack,
  Text,
  TextArea,
  View,
  VStack,
} from 'native-base';
import {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Dimensions} from 'react-native';
import {BUTTON_TYPE} from '../../../../constants';
import CommonService from '../../../../services/CommonService/CommonService';
import {DisplayText} from '../../../common/DisplayText/DisplayText';
import LoadingSpinner from '../../../common/Loader/LoadingSpinner';
import ModalActionBtn from '../../../common/ModalActionBtn/ModalActionBtn';
import {FoldButton} from '../../../CommonComponents/FoldButton/FoldButton';
import {CustomBlocksType} from '../EmailTemplates/CreateOrEditTemplate/CustomBlocks/constants';
import {IProductRecommendation} from '../EmailTemplates/CreateOrEditTemplate/CustomBlocks/MedlineSuggestions';
import ViewMedlineSuggestionsList from './ViewMedlineSuggestionsList';

const {
  Label,
  // ErrorMessage
} = FormControl;

const ViewMedlineSuggestions = (props: {
  selectedGroupInfo?: any;
  template?: any;
  onModalClose?: any;
}) => {
  const [group, setGroup] = useState<any>(null);
  const [medlineContent, setMedlineContent] = useState<any>({});
  const commonService = CommonService.getCommonServiceInstance();
  const billingService = commonService.billingService;
  const [showLoader, setShowLoader] = useState(false);
  const {addBlock, moveBlock, values} = useBlock();
  const {focusIdx} = useFocusIdx();
  const [suggestions, setSuggestions] = useState({
    suggestedContent: [] as any,
    selectedContent: [] as any,
  });
  const intl = useIntl();
  const {mergeTags} = useEditorProps();

  const getMedlineContent = (selectedGroupInfo: any) => {
    setShowLoader(true);
    setSuggestions({
      suggestedContent: [] as any,
      selectedContent: [] as any,
    });
    setMedlineContent({});
    const url = encodeURI(`/medline/query/${selectedGroupInfo}`);
    return billingService
      .get(url)
      .then((res: any) => {
        const medLineContentResp = res?.data?.nlmSearchResult;
        setMedlineContent(medLineContentResp);
        setShowLoader(false);
      })
      .catch((err: any) => {
        setShowLoader(false);
      });
  };

  useEffect(() => {
    setGroup(props?.selectedGroupInfo);
  }, [props]);
  const {height} = Dimensions.get('window');
  const finalHeight = height - 380;
  return (
    <VStack>
      <Text style={{marginLeft: 5, marginBottom: 10}} color="gray.500">
        {'You can add the below suggested educational content to the email.'}
      </Text>

      <View style={{flex: 1}}>
        <Box alignItems="center">
          <FormControl>
            <HStack
              style={{
                justifyContent: 'flex-start',
              }}
            >
              <Label marginTop={2}>
                <DisplayText textLocalId="contentSuggestions" />
              </Label>
              <TextArea
                defaultValue={props?.selectedGroupInfo}
                onBlur={(event: any) => {
                  setGroup(event?.target?.value);
                }}
              />
              <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.PRIMARY,
                  onPress: () => {
                    if (group) {
                      getMedlineContent(group);
                    } else {
                      getMedlineContent(props?.selectedGroupInfo);
                    }
                  },
                  style: {margin: 16},
                }}
                customProps={{
                  btnText: intl.formatMessage({
                    id: 'viewSuggestions',
                  }),
                  withRightBorder: false,
                }}
              ></FoldButton>
            </HStack>
          </FormControl>
        </Box>
        {medlineContent && Array.isArray(medlineContent?.list?.document)
          ? medlineContent?.list?.document?.map((content: any, index: any) => {
              return (
                <ViewMedlineSuggestionsList
                  html={content}
                  index={index}
                  suggestions={suggestions}
                  onModalClose={props?.onModalClose}
                />
              );
            })
          : medlineContent?.list?.document && (
              <ViewMedlineSuggestionsList
                html={medlineContent?.list?.document}
                index={0}
                suggestions={suggestions}
                onModalClose={props?.onModalClose}
              />
            )}
        <FoldButton
          nativeProps={{
            variant: BUTTON_TYPE.SECONDARY,
            onPress: () => {
              let parentIdx = getParentIdx(focusIdx);
              const self = get(values, focusIdx) as IBlockData | null;

              if (focusIdx === 'content') {
                parentIdx = 'content';
              }

              if (parentIdx) {
                const payload: IProductRecommendation = {
                  type: CustomBlocksType.MEDLINE_SUGGESTIONS,
                  data: {
                    value: {
                      title: 'Suggested Content',
                      buttonText: 'View More',
                      suggestions: suggestions.suggestedContent,
                    },
                  },
                  attributes: {
                    'background-color': '#ffffff',
                    'button-text-color': '#ffffff',
                    'button-color': '#414141',
                    'title-color': '#222222',
                    'description-color': '#222222',
                  },
                  children: [],
                };
                if (self?.type != CustomBlocksType.MEDLINE_SUGGESTIONS) {
                  addBlock({
                    type: CustomBlocksType.MEDLINE_SUGGESTIONS,
                    parentIdx: parentIdx,
                    positionIndex: undefined,
                    payload: payload,
                  });
                } else {
                  addBlock({
                    type: CustomBlocksType.MEDLINE_SUGGESTIONS,
                    parentIdx: parentIdx,
                    positionIndex: undefined,
                    payload: payload,
                    canReplace: true,
                  });
                }
              }
              setMedlineContent({});
              props?.onModalClose();
            },
            style: {margin: 15},
          }}
          customProps={{
            btnText: intl.formatMessage({
              id: 'Use selected suggestion(s)',
            }),
            withRightBorder: false,
          }}
        ></FoldButton>
        {showLoader && <LoadingSpinner />}
      </View>
    </VStack>
  );
};

export default ViewMedlineSuggestions;
