export const hexToRgb = (
  hex: string
): {
  r: number;
  g: number;
  b: number;
} | null => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

function rgbaToHexOpaque(r: number, g: number, b: number, a: number): string {
  // Blend the RGBA color with a white background color
  const red = Math.round((1 - a) * 255 + a * r);
  const green = Math.round((1 - a) * 255 + a * g);
  const blue = Math.round((1 - a) * 255 + a * b);

  // Convert the resulting RGB color to hexadecimal
  const hexR = red.toString(16).padStart(2, '0');
  const hexG = green.toString(16).padStart(2, '0');
  const hexB = blue.toString(16).padStart(2, '0');
  return `#${hexR}${hexG}${hexB}`;
}

export const getLighterShadeWithoutOpacity = (
  color: string,
  opacity?: number
): string => {
  const cardColor = color;
  const rgbData = hexToRgb(cardColor || '');
  const hexColor = rgbaToHexOpaque(
    rgbData?.r || 0,
    rgbData?.g || 0,
    rgbData?.b || 0,
    opacity || 0.2
  ); // add opacity but not transparency
  return hexColor;
};
