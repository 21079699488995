import {gql} from '@apollo/client';

export const updateEmployer = gql`
  mutation createEmployer(
    $employerId: uuid
    $employer: employers_set_input
  ) {
    updateEmployers(_set: $employer, where: {id: {_eq: $employerId}}) {
      returning {
        id
      }
    }
  }
`;

export const updateEmployerDeal = gql`
  mutation updateEmployerDeal($employerDealId: uuid!, $employerDeal: employer_deals_set_input!) {
    updateEmployerDeal(pk_columns: {id: $employerDealId}, _set: $employerDeal) {
      id
    }
  }
`;

export const updateContact = gql`
  mutation updateContact($contact: contacts_set_input, $contactId: bigint) {
    updateContacts(where: {id: {_eq: $contactId}}, _set: $contact) {
      returning {
        id
      }
    }
  }
`;

export const createEmployer = gql`
  mutation createEmployer($employer: employers_insert_input!) {
    createEmployer(object: $employer) {
      id
      employerDeal {
      contactEmployee {
        contact {
          id
        }
      }
    }
    }
  }
`;

export const deleteEmployer = gql`
  mutation deleteEmployer($employerId: uuid!) {
    updateEmployer(pk_columns: {id: $employerId}, _set: {isDeleted: true}) {
      isDeleted
      id
    }
  }
`;

export const createEmployerDeal = gql`
  mutation createEmployerDeal($employerDealObject: employer_deals_insert_input!) {
    createEmployerDeal(object: $employerDealObject) {
      id
    }
  }
`;

export const createEmployee = gql`
  mutation createEmployee($contact: contacts_obj_rel_insert_input , $accountUuid: uuid , $employerId: uuid) {
    createEmployee(object: {contact: $contact, accountUuid: $accountUuid, employerId: $employerId}) {
      id
    }
  }
`;

export const getEmployersByExp = gql`
query getEmployer($limit: Int, $offset: Int, $employersBoolExp: employers_bool_exp, $order_by: [employers_order_by!], $currentDate: date) {
  employerAggregate(where: $employersBoolExp, order_by: {createdAt: desc}) {
    aggregate {
      count
    }
  }
  employers(limit: $limit, offset: $offset, where: $employersBoolExp, order_by: $order_by) {
    id
    name
    email
    industryId
    phoneNumber
    isDeleted
    accountUuid
    address {
      id
      zipcodeId
      line1
      cityId
      stateId
      zipCode {
        code
      }
      city {
        name
      }
      state {
        name
      }
    }
    employerDeal {
      statusId
      id
      managerId
      contactEmployee {
        id
        roleId
        contact {
          id
          uuid
          name
          email
          phoneNumber
        }
      }
    }
    employee_aggregate(
      where: {isDeleted: {_eq: false}, contactMembership: {isDeleted: {_eq: false}, _or: [{endDate: {_is_null: true}}, {endDate: {_gte: $currentDate}}], startDate: {_lte: $currentDate}}},
      distinct_on: contactId
    ) {
      aggregate {
        count
      }
    }
  }
}
`;

export const getEmployersCount = gql`
  query getEmployer($employersBoolExp: employers_bool_exp) {
    employerAggregate(where: $employersBoolExp) {
      aggregate {
        count
      }
    }
  }
`;

export const employersSearch = gql`
  query searchEmployer($query: String!) {
    employers(where: {name: {_ilike: $query}, isDeleted: {_eq: false}}) {
      id
      name
      email
      phoneNumber
      isDeleted
      accountUuid
      employee_aggregate {
        aggregate {
          count
        }
      }
      employerPriceSourceData {
        id
        payerId
        carrierId
        priceSource
      }
    }
  }
`;

export const getEmployerAndTotalEmployeeById = gql`
  query employer($employerId: uuid!) {
    employer(id: $employerId) {
      name
      id
    }
    employeeAggregate(
      where: {isDeleted: {_eq: false}, employerId: {_eq: $employerId}}
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const getEmployerById = gql`
  query getEmployer($employerId: uuid!) {
    employers(where: {id: {_eq: $employerId}}) {
      id
      name
      email
      industryId
      phoneNumber
      isDeleted
      address {
        id
        zipcodeId
        line1
        cityId
        stateId
        zipCode {
          code
        }
        city {
          name
        }
        state {
          name
        }
      }
      metadata
      employerDeal {
        statusId
        id
        managerId
        contactEmployee {
          id
          roleId
          contact {
            id
            uuid
            name
            email
            phoneNumber
          }
        }
      }
    }
  }
`;

export const GetEmployeeCount = gql`
  query GetEmployeeCount($employerId: uuid, $currentDate: date) {
    employeeAggregate(
      where: {
        employerId: {_eq: $employerId}
        isDeleted: {_eq: false}
        contactMembership: {
          isDeleted: {_eq: false}
          _or: [{endDate: {_is_null: true}}, {endDate: {_gte: $currentDate}}]
          startDate: {_lte: $currentDate}
        }
      }
      distinct_on: contactId
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const getEmployedBySearch = gql`
  query getEmployedBySearch($searchString: String!, $limit: Int) {
    employers(where: {name: {_ilike: $searchString}}, limit: $limit) {
      id
      name
      email
      phoneNumber
      isDeleted
    }
  }
`;

export const GetLoggedInEmployeeByEmployerId = gql`
  query GetLoggedInEmployeeByEmployerId($where: employers_bool_exp = {}) {
    employers(where: $where) {
      total: employee_aggregate(
        distinct_on: [id]
        where: {isDeleted: {_eq: false}, contact: {isDeleted: {_eq: false}}}
      ) {
        aggregate {
          count
        }
      }
      loggedIn: employee_aggregate(
        distinct_on: [id]
        where: {
          isDeleted: {_eq: false}
          contact: {
            additionalAttributes: {_has_key: "loggedInTime"}
            isDeleted: {_eq: false}
          }
        }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`;

export const GetContactUuidByEmployerId = gql`
  query GetContactUuidByEmployerId($where: employers_bool_exp = {}) {
    employers(where: $where) {
      employee(
        distinct_on: [id, contactId]
        where: {isDeleted: {_eq: false}, contact: {isDeleted: {_eq: false}}}
      ) {
        contactId
      }
    }
  }
`;

export const GetSubmittedFormsByContactIdList = gql`
  query GetFormsByContactIdList($contactIdList: [uuid!]!, $formId: uuid!) {
    aggregateFormLogs(
      distinct_on: [contactId, formId]
      where: {
        formId: {_eq: $formId}
        contactId: {_in: $contactIdList}
        status: {code: {_eq: "SUBMITTED"}}
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GetFormsByContactIdList = gql`
query get($boolExp: formLog_bool_exp) {
  formLogs(distinct_on: [contactId, formId], order_by: [{contactId: asc}, {formId: asc}, {createdOn: desc}], where: $boolExp) {
    formId
    contactId
    status {
      code
    }
  }
}

`;

export const GetFormLogCountByStatus = gql`
query get($pendingBoolExp: formLog_bool_exp, $submittedBoolExp: formLog_bool_exp) {
  pending:aggregateFormLogs(distinct_on: [contactId, formId], order_by: [{contactId: asc}, {formId: asc}, {createdOn: desc}], where: $pendingBoolExp) {
    aggregate {
      count
    }
  }
  submitted:aggregateFormLogs(distinct_on: [contactId, formId], order_by: [{contactId: asc}, {formId: asc}, {createdOn: desc}], where: $submittedBoolExp) {
    aggregate {
      count
    }
  }
}

`;


export const GetContactUuidListBySelectedFormStatus = gql`
query get($limit: Int, $offset: Int, $boolExp: formLog_bool_exp) {
  formLogs(limit: $limit, offset: $offset, distinct_on: [contactId, formId], order_by: [{contactId: asc}, {formId: asc}, {createdOn: desc}], where: $boolExp) {
    contactId
  }
}
`;

export const GetPatientUuidByEmployerId = gql`
  query getPatientUuidByEmployerId($where: employers_bool_exp = {}) {
    employers(where: $where) {
      employee(
        where: {isDeleted: {_eq: false}, contact: {isDeleted: {_eq: false}}}
        distinct_on: contactId
      ) {
        contact {
          id
          uuid
          name
          patient {
            patientUuid
          }
          additionalAttributes
        }
      }
    }
  }
`;

export const GetOnlyContactUuidByEmployerBoolExp = gql`
  query GetOnlyContactUuidByEmployerBoolExp($where: employers_bool_exp = {}) {
    employers(where: $where) {
      employee(
        where: {isDeleted: {_eq: false}, contact: {isDeleted: {_eq: false}}}
        distinct_on: contactId
      ) {
        contactUuid: contactId
      }
    }
  }
`;

export const GetEmployeesByLoginFilter = gql`
query GetEmployeesByLoginFilter($offset:Int,$limit:Int,$where: contacts_bool_exp = {}) {
  contacts(offset: $offset, limit: $limit,distinct_on:[id],order_by:{id:asc},where: $where) {
    id
    name
    email
    uuid
    phoneNumber
    person {
      firstName
      lastName
      gender {
        code
        value
      }
    }
  }
}
`;

export const GetLoggedInContacts = gql`
  query GetLoggedInContacts(
    $where1: contacts_bool_exp = {}
    $where2: contacts_bool_exp = {}
  ) {
    login: contactAggregate(where: $where1) {
      aggregate {
        count
      }
    }
    notLoggedIn: contactAggregate(where: $where2) {
      aggregate {
        count
      }
    }
  }
`;

// export const GetLoggedInEmployeeByEmployerId = gql`
//   query GetLoggedInEmployeeByEmployerId($employerId: uuid!) {
//     employers(where: {id: {_eq: $employerId}, isDeleted: {_eq: false}}) {
//       total: employee_aggregate(
//         distinct_on: [id]
//         where: {isDeleted: {_eq: false}, contact: {isDeleted: {_eq: false}}}
//       ) {
//         aggregate {
//           count
//         }
//       }
//       loggedIn: employee_aggregate(
//         distinct_on: [id]
//         where: {
//           isDeleted: {_eq: false}
//           contact: {
//             additionalAttributes: {_has_key: "loggedInTime"}
//             isDeleted: {_eq: false}
//           }
//         }
//       ) {
//         aggregate {
//           count
//         }
//       }
//     }
//   }
// `;

// export const GetContactUuidByEmployerId = gql`
//   query GetContactUuidByEmployerId($employerId: uuid!) {
//     employers(where: {id: {_eq: $employerId}, isDeleted: {_eq: false}}) {
//       employee(
//         distinct_on: [id]
//         where: {isDeleted: {_eq: false}, contact: {isDeleted: {_eq: false}}}
//       ) {
//         contactId
//       }
//     }
//   }
// `;

// export const GetFormsByContactIdList = gql`
//   query GetFormsByContactIdList($contactIdList: [uuid!]!, $formId: uuid!) {
//     aggregateFormLogs(
//       distinct_on: [contactId, formId]
//       where: {
//         formId: {_eq: $formId}
//         contactId: {_in: $contactIdList}
//         status: {code: {_eq: "SUBMITTED"}}
//       }
//     ) {
//       aggregate {
//         count
//       }
//     }
//   }
// `;
export const createEmployerAddress = gql`
  mutation createEmployerAddress($employerAddressData: employer_addresses_insert_input! ) {
  createEmployerAddress(object: $employerAddressData){
    id
  }
}
`;

export const updateEmployerAddress = gql`
  mutation updateEmployerAddress($employerAddressData: employer_addresses_set_input, $employerId: uuid) {
  updateEmployerAddresses(where: {employerId: {_eq: $employerId}}, _set: $employerAddressData) {
    returning {
      id
    }
  }
}
`;

export const createEmployerPriceSource = gql`
  mutation createEmployerPriceSources($employerPriceSource: employer_price_sources_insert_input! ) {
    createEmployerPriceSource(object: $employerPriceSource){
      id
    }
  }
`;

export const updateEmployerPriceSource = gql`
  mutation updateEmployerPriceSource($employerPriceSource:employer_price_sources_set_input, $id: uuid, $employerId: uuid) {
    updateEmployerPriceSources(
      where: {
        id: {
          _eq: $id
        },
        employerId: {
          _eq: $employerId
        },
      },
      _set: $employerPriceSource
    ) {
      returning {
        id
      }
    }
  }
`;

export const getEmployerNameById = gql`
query getEmployerNameById($id: uuid!) {
  employer(id: $id) {
    name
  }
}
`;

export const getEmployersByIds = gql`
  query getEmployersByIds($idList: [uuid!]) {
    employers(where: {id: {_in: $idList}, isDeleted: {_eq: false}}) {
      id
      name
    }
  }
`;

export default {
  createEmployer,
  updateEmployer,
  getEmployerNameById,
  getEmployersByExp,
  getEmployerById,
  getEmployerAndTotalEmployeeById,
  deleteEmployer,
  employersSearch,
  GetEmployeeCount,
  GetLoggedInEmployeeByEmployerId,
  GetContactUuidByEmployerId,
  GetSubmittedFormsByContactIdList,
  GetFormsByContactIdList,
  GetFormLogCountByStatus,
  GetPatientUuidByEmployerId,
  GetOnlyContactUuidByEmployerBoolExp,
  GetContactUuidListBySelectedFormStatus,
  GetEmployeesByLoginFilter,
  GetLoggedInContacts,
  getEmployedBySearch,
  createEmployerAddress,
  updateEmployerAddress,
  updateEmployerDeal,
  createEmployerDeal,
  createEmployee,
  updateContact,
  getEmployersCount,
  createEmployerPriceSource,
  updateEmployerPriceSource,
  getEmployersByIds
};
