import { IAbility, IEhrCapability } from '../../../../../../Interfaces';
import {getDiffInMinutes, getMomentObj, isCurrentDateInFutureComparedToOther} from '../../../../../../utils/DateUtils';
import { CapabilityResource } from '../../../../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper';
import { IReccuringEventAPI } from '../AppointmentBooking/hooks/useReccuringEvent/interface';
import { IBlockSlotError } from './BlockTimeBooking';
import {IBlockTimeData, IBlockTimeInput} from './BlockTimeInterfaces';

export const isBlockSlotsValid = (selectedBlockSlotId: string, blockSlotsError: IBlockSlotError[]) => {
  return (blockSlotsError || []).some(blockSlotError => {
    return (blockSlotError.index == selectedBlockSlotId);
 });
}

export const isInvalidData = (
  isReccuring: boolean,
  data: IBlockTimeData,
  blockSlotsError: IBlockSlotError[],
  reccurenceData: {
    data: IReccuringEventAPI;
    error: boolean;
  },
  allowMultipleBlockTimes: boolean
): any => {
  const isInvalid =
    !data.name ||
    !data.selectedUserId ||
    !!reccurenceData?.error ||
    !data.selectedLocationIds?.length;
  if (isInvalid) return true;
  if (data?.blockTimes?.length) {
    allowMultipleBlockTimes = true;
  }
  if(isReccuring){
    return (
      isInvalid ||
      !!getInvalidBlockDatesError({
        startDateTime: getMomentObj(reccurenceData?.data?.startDateTime as string).toDate(),
        endDateTime: getMomentObj(reccurenceData?.data?.endDateTime as string).toDate(),
        id: '',
        selectedLocationIds: data.selectedLocationIds
      })
    );
  }
  if (allowMultipleBlockTimes) {
    return data.blockTimes?.some((blockTime) => {
      return (
        isInvalid ||
        !!getInvalidBlockDatesError(blockTime) ||
        isBlockSlotsValid(blockTime.id, blockSlotsError)
      );
    });
  }
  return false;
};

export const getInvalidBlockDatesError = (blockTime: IBlockTimeInput) => {
  if (!blockTime.startDateTime || !blockTime.endDateTime) {
    return 'You need to a select a slot to block time on your schedule';
  } else if (isCurrentDateInFutureComparedToOther(blockTime.startDateTime, blockTime.endDateTime)) {
    return 'Start date time cannot be less than end date time';
  }
  return '';
}

function formatTime(minutes: number) {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  if (hours === 0) {
    return `${remainingMinutes} minute${remainingMinutes === 1 ? '' : 's'}`;
  } else if (remainingMinutes === 0) {
    return `${hours} hour${hours === 1 ? '' : 's'}`;
  } else {
    return `${hours} hour${hours === 1 ? '' : 's'} and ${remainingMinutes} minute${remainingMinutes === 1 ? '' : 's'}`;
  }
}

export const isTimeLessThanOrMoreThanLimit = (startDateTime?: Date, endDateTime?: Date, minTimeInMinutes?: number, maxTimeInMinutes?: number) => {
  if (!minTimeInMinutes && !maxTimeInMinutes) {
    return ''
  }
  const diffInMinutes = getDiffInMinutes(startDateTime?.setSeconds(0, 0), endDateTime?.setSeconds(0, 0));
  if (minTimeInMinutes) {
    if (diffInMinutes <= minTimeInMinutes) {
      return `Block time duration should be more than ${formatTime(minTimeInMinutes)}`
    }
  }
  if (maxTimeInMinutes) {
    if (diffInMinutes >= maxTimeInMinutes) {
      return `Block time duration should be less than ${formatTime(maxTimeInMinutes)}`
    }
  }
}