import React from 'react';
import {Path, Svg, G} from 'react-native-svg';

export const SmsIcon = (props: {color?: string}) => {
  const {color} = props;
  return (
    <Svg width="16" height="15" viewBox="0 0 16 15" fill="none" >
      <G id="Unfold Icon">
        <Path id="Vector" d="M10.4445 11.9061V12.3889C10.4445 13.0611 9.89448 13.6111 9.22226 13.6111H4.33337C3.65503 13.6111 3.11115 13.0611 3.11115 12.3889V2.61114C3.11115 1.9328 3.65503 1.38892 4.33337 1.38892H9.22226C9.89448 1.38892 10.4445 1.9328 10.4445 2.61114V2.89225M12.6261 9.12558C12.6261 9.3028 12.5528 9.48003 12.4245 9.60225C12.3023 9.73058 12.125 9.7978 11.9478 9.7978H7.89003L6.53948 11.1484V5.74614C6.53948 5.56892 6.61281 5.39169 6.74115 5.26947C6.86337 5.14114 7.04059 5.07392 7.21781 5.07392H11.9539C12.1311 5.07392 12.3084 5.14725 12.4306 5.26947C12.5589 5.3978 12.6323 5.56892 12.6323 5.74614V9.12558H12.6261Z" stroke={color || "#667085"} stroke-linecap="round" stroke-linejoin="round"/>
    </G>
    </Svg>
  );
};


