import {Platform} from 'react-native';
import DeviceInfo from 'react-native-device-info'
export const isWeb = () => {
  return Platform.OS === 'web';
};

export const isIOS = () => {
  return Platform.OS === 'ios';
};
export const isAndroid = () => {
  return Platform.OS === 'android';
};


export const  getBasicDeviceInfoForMessageSend = async () => {
  if (isIOS()) {
    return {
      version: DeviceInfo.getVersion(),
      brand: DeviceInfo.getBrand(),
      model: DeviceInfo.getModel(),
      systemName: DeviceInfo.getSystemName(),
      systemVersion: DeviceInfo.getSystemVersion(),
      deviceName: 'IOS'
    };
  } else if (isAndroid()) {
    return {
      version: DeviceInfo.getVersion(),
      brand: DeviceInfo.getBrand(),
      model: DeviceInfo.getModel(),
      systemName: DeviceInfo.getSystemName(),
      systemVersion: DeviceInfo.getSystemVersion(),
      deviceName: 'ANDROID'
    };
  } else if (isWeb()) {
    return {
      uniqueId: 'N/A',
      brand: 'N/A',
      model: 'N/A',
      systemName: 'web',
      systemVersion: window.navigator?.userAgent,
      deviceName: 'WEB'
    }
  }
};
