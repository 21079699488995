import React from 'react';
import {ICommonSvgProps} from '../interfaces';
import {isWeb} from '../../../../utils/platformCheckUtils';
import {Image} from 'native-base';

const VolumeSvg = (props: ICommonSvgProps) => {
  const height = props.height ?? '14';
  const width = props.width ?? '14';
  const stroke = props.customStrokeColor ?? '#667085';
  return isWeb() ? (
    <svg
      width={`${width}`}
      height={`${height}`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.07 4.92999C20.9447 6.80527 21.9979 9.34835 21.9979 12C21.9979 14.6516 20.9447 17.1947 19.07 19.07M15.54 8.45999C16.4774 9.39763 17.0039 10.6692 17.0039 11.995C17.0039 13.3208 16.4774 14.5924 15.54 15.53M11 4.99999L6 8.99999H2V15H6L11 19V4.99999Z"
        stroke={`${stroke}`}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ) : (
    <Image
      size={props.size}
      resizeMode="contain"
      source={require('../../../../assets/images/CallTrailPng/volume-2.png')}
      alt='image'
    />
  );
};

export default VolumeSvg;
