import { gql } from '@apollo/client';

export const GetUserPreferencesByTypeId = gql`
  query getUserPreferencesByTypeId($userId: uuid!, $userPreferencesTypeId: uuid!) {
    userPreferences(where: {isDeleted: {_eq: false}, userId: {_eq: $userId}, userPreferencesTypeId: {_eq: $userPreferencesTypeId}}, order_by: {updatedOn: desc_nulls_last}, limit: 1) {
      id
      preferencesJson
      preferencesModelVersion
    }
  }
`;


export const CreateOrUpdateUserPreference = gql`
  mutation createOrUpdateUserPreference($object: user_preferences_insert_input!) {
    createUserPreference(object: $object, on_conflict: {constraint: user_preferences_pkey, update_columns: [ preferencesJson, preferencesModelVersion ]}) {
      id
      userId
      preferencesJson
      preferencesModelVersion
    }
  }
`;
