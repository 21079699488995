import React from 'react';
import {Text, View} from 'native-base';
import Stack from '../../../../../../common/LayoutComponents/Stack';
import { Colors } from '../../../../../../../styles';
import { styles } from '../../../../Analytics/style';


interface GraphInfoItemProps {
  infoColor: string;
  textColor?: string;
  text: string;
}

export const GraphInfoItem = (props: GraphInfoItemProps) => {
  return (
    <Stack direction="row" style={styles.alignItemsCenterMarginRight6}>
      <View
        style={{
          borderRadius: 4,
          width: 8,
          height: 8,
          backgroundColor: props.infoColor,
        }}
      />
      <Text
        color={props.textColor || Colors.Custom.Gray400}
        fontSize={12}
        ml={1}
        mr={1}>
        {props.text}
      </Text>
    </Stack>
  );
};
