import React from 'react';
import { isWeb } from '../../../../utils/platformCheckUtils';
import { ICommonSvgProps } from '../interfaces';
import { Icon } from 'native-base';
import FeatherIcon from 'react-native-vector-icons/Feather';

const TransitionIconSvg = (props?: ICommonSvgProps) => {

  return (
    <>
      {isWeb() ? (
        <svg
          width={props?.width || 24}
          height={props?.height || 24}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8M14 2L20 8M14 2V8H20M16 13H8M16 17H8M10 9H8"
            stroke={props?.customStrokeColor || '#667085'}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ) : (
        <Icon as={FeatherIcon} name="file-text" size={5} color={props?.customStrokeColor || '#667085'}/>
      )}
    </>
  );
};

export default TransitionIconSvg;
