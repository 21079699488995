import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  modalContainer: {
    minWidth: '50vw',
    maxWidth: '60vw',
  },
  formElement: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  genderElement: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  employerElement: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  birthDate: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  birthDatePicker: {
    height: '36px',
    borderRadius: 5,
    borderColor: '#e5e5e5',
    backgroundColor: '#f9fafb',
  },
  formLabel: {
    marginLeft: 4,
  },
  genderLabel: {
    marginBottom: 2,
  },
  formLastElement: {
    paddingTop: 10,
    // paddingBottom: 50,
  },
  submitElement: {
    width: 100,
    paddingTop: 10,
    marginBottom: 30,
  },
  cancelElement: {
    width: 100,
    paddingLeft: 10,
    paddingTop: 10,
    marginBottom: 30,
  },
  headerText: {
    fontWeight: '400',
    fontSize: 20,
    color: '#344054',
    paddingLeft: 16,
  },
  scrollViewStyle: {
    paddingLeft: 0,
  },
  zipCode: {
    paddingTop: 10,
  },
  city: {
    paddingTop: 10,
  },
  state: {
    paddingTop: 10,
  },
  spinnerStyle: {
    margin: '0 auto',
    color: 'black',
    position: 'absolute',
    zIndex: 1000,
    backgroundColor: '#0000000f',
    height: '100%',
    width: '100%',
    padding: 0,
  },
  rowStack: {
    justifyContent: 'space-between',
  },
});
