import {View, HStack, Skeleton, VStack} from 'native-base';
import React from 'react';

export const AppointmentPatientBannerLoading = () => {
  return (
    <View w="100%">
      <HStack w="90%" space={6} rounded="md" py="4">
        <Skeleton endColor="coolGray.100" size="16" rounded="full" />
        <VStack flex="3" space="2">
          <Skeleton endColor="coolGray.100" height={6} rounded="full" />
          <Skeleton.Text endColor="coolGray.100" lines={2} />
        </VStack>
        <VStack flex="1" space="1">
          <Skeleton
            endColor="coolGray.100"
            size="4"
            rounded="full"
          />
        </VStack>
      </HStack>
    </View>
  );
};
