import { Icon } from 'native-base';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { Colors } from '../../../../../../styles';
import { isAdminUser } from '../../../../../../utils/commonUtils';
import PlusIcon from '../../../../../common/Svg/PlusSvg';
import { ITableTopBarProps } from '../../../../../common/TableTopBar/Interfaces';
export const getWorkflowListTopBarButtons = (
  buttonClickFnList: ((workflowUrl: string) => void)[],
  workflowUrl: string,
  buttonText?: string,
  handleInputFileClick?:any
): ITableTopBarProps => {
  const isLoginUserAdminRole = isAdminUser();
  return {
    title: '',
    buttonList: [
      ...(handleInputFileClick && isLoginUserAdminRole  && false? [{
        btnText: buttonText || 'Import Automation',
        size: 'sm',
        backgroundColor: Colors.secondary['100'],
        textColor: '',
        variant: '',
        borderColor: Colors.secondary['200'],
        leftIcon: (
         <PlusIcon />
        ),
        btnClick: () => {
          handleInputFileClick();
        },
      }]: []),
      {
        btnText: buttonText || 'Create Automation',
        size: 'sm',
        backgroundColor: Colors.secondary['100'],
        textColor:'',
        variant: '',
        borderColor: Colors.secondary['200'],
        leftIcon: (
          <PlusIcon />
        ),
        btnClick: () => {
          buttonClickFnList[0](workflowUrl);
        },
      },
    ],
  };
};
