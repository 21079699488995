import {Path, Svg} from 'react-native-svg';

const VirtualAppointmentIcon = (props: {color?: string}) => {
  return (
    <Svg width="13" height="9" viewBox="0 0 13 9" fill="none">
      <Path
        d="M12.1251 1.46888C11.9352 1.38338 11.7244 1.35557 11.5188 1.3889C11.3133 1.42224 11.122 1.51525 10.9689 1.65638L9.62512 2.90638V2.00013C9.62512 1.50285 9.42758 1.02593 9.07595 0.674303C8.72432 0.322672 8.2474 0.125128 7.75012 0.125128H2.12512C1.62784 0.125128 1.15093 0.322672 0.799297 0.674303C0.447666 1.02593 0.250122 1.50285 0.250122 2.00013V7.00013C0.250122 7.49741 0.447666 7.97432 0.799297 8.32595C1.15093 8.67758 1.62784 8.87513 2.12512 8.87513H7.75012C8.2474 8.87513 8.72432 8.67758 9.07595 8.32595C9.42758 7.97432 9.62512 7.49741 9.62512 7.00013V6.09388L10.9751 7.34388C11.1739 7.52378 11.4321 7.62394 11.7001 7.62513C11.8489 7.62478 11.9959 7.59283 12.1314 7.53138C12.3157 7.45681 12.4736 7.32898 12.585 7.16421C12.6963 6.99944 12.756 6.80523 12.7564 6.60638V2.39388C12.7555 2.19431 12.6949 1.99957 12.5824 1.83473C12.4699 1.66988 12.3106 1.54247 12.1251 1.46888ZM8.37512 7.00013C8.37512 7.16589 8.30927 7.32486 8.19206 7.44207C8.07485 7.55928 7.91588 7.62513 7.75012 7.62513H2.12512C1.95936 7.62513 1.80039 7.55928 1.68318 7.44207C1.56597 7.32486 1.50012 7.16589 1.50012 7.00013V2.00013C1.50012 1.83437 1.56597 1.6754 1.68318 1.55819C1.80039 1.44098 1.95936 1.37513 2.12512 1.37513H7.75012C7.91588 1.37513 8.07485 1.44098 8.19206 1.55819C8.30927 1.6754 8.37512 1.83437 8.37512 2.00013V7.00013ZM11.5001 6.12513L9.74387 4.50013L11.5001 2.87513V6.12513Z"
        fill={props?.color || '#9E77ED'}
      />
    </Svg>
  );
};

export default VirtualAppointmentIcon;
