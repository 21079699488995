import {Drawer} from 'antd';
import {HStack, Pressable, Text} from 'native-base';
import React from 'react';
import {MEDIA_TYPES} from '../../../MediaLibrary/MediaLibraryUtils';
import {MediaPreview} from '../../../MediaLibrary/MediaPreview';
import {MediaSelectionModal} from '../../../PatientEducation/MediaSelectionModal';

export interface IImageFinderProps {
  isOpen: boolean;
  imgArray: string[];
  onClose: () => void;
  onImgClick: (imgString: string) => void;
  onNewImgClick: (newImgString: string) => void;
}

const ImageFinder = (props: IImageFinderProps) => {
  const {imgArray, isOpen, onClose, onImgClick, onNewImgClick} = props;
  const [imageFinderState, setImageFinderState] = React.useState({
    isMediaDrawerOpen: false,
  });
  return (
    <Drawer
      visible={isOpen}
      onClose={onClose}
      width="50vw"
      title={
        <>
          <Text>Found these images in the template</Text>
        </>
      }
    >
      <HStack
        style={{
          padding: 16,
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        {imgArray.map((imgUrl, index) => {
          return (
            <Pressable
              key={index}
              style={{
                padding: 16,
                borderWidth: 1,
                borderColor: 'black',
                margin: 8,
              }}
              onPress={() => {
                onImgClick(imgUrl);
                setImageFinderState((prev) => {
                  return {
                    ...prev,
                    isMediaDrawerOpen: true,
                  };
                });
              }}
            >
              <MediaPreview mediaType={MEDIA_TYPES.IMAGE} mediaUrl={imgUrl} />
            </Pressable>
          );
        })}

        <MediaSelectionModal
          isOpen={imageFinderState.isMediaDrawerOpen}
          onClose={() => {
            setImageFinderState((prev) => {
              return {
                ...prev,
                isMediaDrawerOpen: false,
              };
            });
          }}
          onSelect={(mediaData) => {
            onNewImgClick(mediaData.url || '');
            setImageFinderState((prev) => {
              return {
                ...prev,
                isMediaDrawerOpen: false,
              };
            });
          }}
        />
      </HStack>
    </Drawer>
  );
};

export default ImageFinder;
