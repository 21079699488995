import {UploadFile} from 'antd';
import BaseService from '../../../../../../services/CommonService/BaseService';
import {ISendEFaxBody, IUploadEmailAttachmentResponse} from './interface';
import {IEmailAttachmentResponse} from '../../../../../common/EmailDrawerCommonV2/interfaces';
import { EFAX_SOURCE, EFAX_SUBJECT_CHAR_LIMIT } from '../ConversationChannelTabConst';

export const getUpdatedErrorMessageForEFaxDrawer = (
  stateData: any,
  fileList: UploadFile<any>[]
): any => {
  let attachmentErrorMsgStr = '';
  let attachmentLimitErrorMsgStr = '';
  let receviersErrorMsgStr = '';
  let fileTypeErrorMsgStr = '';
  let senderInboxMsgStr = '';
  let subjectMsgStr = '';
  let isErrorPresent = false;
  const sizeLimit = 1000 * 1000 * 20;

  if (!stateData?.selectedReceviers) {
    receviersErrorMsgStr = 'Please select receviers fax number';
    isErrorPresent = true;
  }

  if (!stateData?.selectedInbox) {
    senderInboxMsgStr = 'Please select EFax inbox number';
    isErrorPresent = true;
  }

  if (!(fileList?.length > 0) && !(stateData?.selectedAttachment && stateData?.selectedAttachment?.length > 0 )) {
    attachmentErrorMsgStr = 'Please attach atleast one attachment';
    isErrorPresent = true;
  }

  if (fileList?.length) {
    let fileSize = 0;
    fileList?.forEach((item) => {
      if (item?.size) {
        fileSize += item?.size;
        if (fileSize >= sizeLimit)
        isErrorPresent = true;
        fileTypeErrorMsgStr = 'You can add documents up to size 20MB';
        return;
      }
    });
  }
  if (fileList?.length > 10 || (stateData?.selectedAttachment?.length > 10 )) {
    attachmentLimitErrorMsgStr = 'Only 10 attachment allowed';
    isErrorPresent = true;
  }

  if (stateData?.subject?.length > EFAX_SUBJECT_CHAR_LIMIT) {
    subjectMsgStr = 'Max character limit of 250 is allowed for subject';
    isErrorPresent = true;
  }

  return {
    errors: {
      selectedReceviers: receviersErrorMsgStr,
      selectedInbox: senderInboxMsgStr,
      attachment: attachmentErrorMsgStr,
      attachmentLimit: attachmentLimitErrorMsgStr,
      attachmentSizeLimit: fileTypeErrorMsgStr,
      subject: subjectMsgStr
    },
    isErrorPresent,
  };
};

export const uploadAttachment = async (data: {formData: FormData}) => {
  const baseService = BaseService.getSharedInstance().axios;
  const url = `crm-nest/api/attachment/upload`;
  const response = await baseService.post<IUploadEmailAttachmentResponse>(
    url,
    data.formData
  );
  return response.data;
};

export const uploadAttachments = async (fileList: UploadFile<any>[]) => {
  const attachmentIds: string[] = [];
  if (fileList.length === 0) {
    return [];
  }
  const promiseList = fileList.map((item) => uploadSingleAttachment(item));
  const response = await Promise.all(promiseList);
  response.forEach((item) => {
    if (item && item.id) {
      attachmentIds.push(item.id);
    }
  });
  return attachmentIds;
};

export const uploadSingleAttachment = async (file: any) => {
  const formData = new FormData();
  formData.append('file', file.originFileObj as any);
  try {
    const response = await uploadAttachment({formData: formData});
    return response;
  } catch (error) {}
};

export const sendEfaxapi = async (body: ISendEFaxBody) => {
  const baseService = BaseService.getSharedInstance().axios;
  const url = `crm-nest/api/v1/efax/send`;
  const response = await baseService.post(url, body);
  return response.data;
};

export const getAttachments = async (data: {messageUuid: string}) => {
  const baseService = BaseService.getSharedInstance().axios;
  const url = `crm-nest/api/v1/email-inbox/message/${data.messageUuid}/attachments`;
  const response = await baseService.get<IEmailAttachmentResponse[]>(url);
  return response.data;
};

export const uploadEFaxAttachments = async (fileList: UploadFile<any>[]) => {
  const response = await uploadAttachments(fileList);
  return response;
};

export const downloadAttachments = async (data: {attachmentId: string}) => {
  const baseService = BaseService.getSharedInstance().axios;
  const url = `crm-nest/api/v1/email-inbox/attachment/${data.attachmentId}`;
  const response = await baseService.get(url, {responseType: 'blob'});
  return response;
};

export const getBodyData = (
  stateData: any,
  attachmentIds: string[],
  conversationId?: number,
  isEFaxFromPatientProfile?: boolean
) => {
  const subject = stateData?.subject?.trim();
  const message = stateData?.messageText?.trim();
  const body = {
    ...(subject && {subject: subject}),
    ...(message && {message: message}),
    attachmentIds: attachmentIds,
    ...(stateData?.selectedPatient?.uuid && {
      contactContextUuid: stateData?.selectedPatient?.uuid,
    }),
    ...(conversationId && {conversationId: conversationId}),
    contactProviderUuid: stateData?.selectedReceviers?.uuid,
    inboxId: stateData?.selectedInbox?.id,
    ...(isEFaxFromPatientProfile && {attachmentSource: EFAX_SOURCE.PATIENT_PROFILE})
  };
  return body;
};

export const getUpdatedErrorMessageForAttachPatientDrawer = (
  stateData: any
) => {
  let patientErrorMsgStr = '';
  let isErrorPresent = false;

  if (
    !(stateData?.selectedPatient?.value ||
    stateData?.selectedPatient?.uuid)
  ) {
    patientErrorMsgStr = 'Please select patient';
    isErrorPresent = true;
  }
  return {
    errors: {
      patient: patientErrorMsgStr,
    },
    isErrorPresent,
  };
};

export const uploadToPatientProfile = async (attachmentIds: string[]) => {
  const baseService = BaseService.getSharedInstance().axios;
  const url = `crm-nest/api/v1/efax/upload-patient-profile`;
  const body = {
    attachmentIds: attachmentIds,
  };
  const response = await baseService.post(url, body);
  return response?.data;
};
