import React, { useContext, useEffect, useState } from 'react';
import { getLicensedStateUserRoles, isLicensedStatePatientMatchingEnabled } from '../../../../../../../../utils/commonUtils';
import { CommonDataContext } from '../../../../../../../../context/CommonDataContext';
import { getPractitionerIdentifierDetails } from '../../../../../../../../services/CommonService/AidBoxService';
import { IPractitionerIdentifier } from './interface';
import { IUserSettingsByCode } from '../../../../../../../../services/UserSettings/interfaces';

export const usePractitionerIdentifierFilter = (args?: {
  useProxy?: boolean;
  headers?: {[index: string]: any};
  userSettings?: IUserSettingsByCode;
}) => {
  // States and constants
  const contextData = useContext(CommonDataContext);
  const userSettings = contextData.userSettings;
  const isLicensedStateMatchingEnabled = isLicensedStatePatientMatchingEnabled(args?.userSettings ?? userSettings);
  const enabledPractitionerRoles = getLicensedStateUserRoles(args?.userSettings ?? userSettings);

  const [practitionerStateData, setPractitionerStateData] = useState<{
    loading: boolean;
    practitionerList: IPractitionerIdentifier[];
  }>({
    loading: false,
    practitionerList: [],
  });

  // Lifecycle methods
  useEffect(() => {
    fetchProviderIdentifierDetails();
  }, []);


  // Public methods
  const getFilteredUsersBasedOnLicensedState = (userList: any[], contactDetails?: any) => {
    if (!isLicensedStateMatchingEnabled || !contactDetails) {
      return userList;
    }
    const contactStateCode = contactDetails?.personAddress?.[0]?.states?.abbr;
    if (contactStateCode) {
      const stateUsers = practitionerStateData.practitionerList.filter((item) => item.stateCode === contactStateCode).map((item) => item.userId);
      const filteredUserList = filterUsersByRoles(userList || []);
      return filteredUserList.filter((item) => stateUsers.includes(item.uuid || item.id));
    }
    return userList;
  }


  // Private methods
  const filterUsersByRoles = (userList: any[]) => {
    return userList.filter((user) => {
      if (!user?.userRoles?.length) {
        return false;
      }

      for (const userRole of user?.userRoles) {
        if (
          userRole?.userRole?.userRole?.code &&
          enabledPractitionerRoles.includes(userRole?.userRole?.userRole?.code)
        ) {
          return true;
        }
      }
      return false;
    });
  }

  const fetchProviderIdentifierDetails = async () => {
    try {
      setPractitionerStateData((prev) => ({...prev, loading: true}));
      const response = await getPractitionerIdentifierDetails(args?.useProxy, args?.headers);
      if (response.data?.identifiers?.length) {
        setPractitionerStateData((prev) => ({...prev, practitionerList: response.data?.identifiers || []}));
      }
      setPractitionerStateData((prev) => ({...prev, loading: false}));
    } catch (error) {

      setPractitionerStateData((prev) => ({...prev, loading: false}));
    }
  }

  return {
    practitionerAPILoading: practitionerStateData.loading,
    practitionerList: practitionerStateData.practitionerList,
    getFilteredUsersBasedOnLicensedState,
  }
}
