import { Drawer } from 'antd';
import {
  Box,
  // Select,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Pressable,
  Spacer,
  Text,
  View,
  VStack
} from 'native-base';
import { FC, useContext, useState } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {
  FlowType,
  WorkflowContext
} from '../../../../../context/WorkflowContext';
import { Colors } from '../../../../../styles';
import CircleIconView, {
  NodeCategory
} from '../../../../common/CircleIconView/CircleIconView';
import { styles } from '../ComponentCss';
import {
  ICondition,
  IConditionDisplayInfo,
  IInputParams,
  IUserActionState
} from './FlowNodeInterface';
import OutputModel from './OutputModel';
import SideMenu from './SideMenu';
import NewOutputModel from './NewOutputModel';
import { reactStyles } from '../../workflowStyles';
// import { InputNumber } from 'antd';

function ButtonMenu(props: {
  getOutputNodeList: (
    nodeId: string,
    outputNodeList: Array<string>,
    isNeedParentList?: boolean,
    mergeParentNodeOutputList?: boolean
  ) => Array<string>;
  currentNodeId: string;
  handleList: Array<IConditionDisplayInfo>;
  nodeMasterDataMap: {[key: string]: IUserActionState};
  onPress: (node: any, clientX: number, clientY: number) => void;
}) {
  const workflowContext = useContext(WorkflowContext);
  const mergeParentNodeOutputList =
    workflowContext.flowType !== FlowType.careJourney;

  const [showModalData, setShowModalData] = useState({
    showModal: false,
    source: '',
    clientX: 0,
    clientY: 0,
  });

  return (
    <Flex justify="space-evenly" alignItems="center" justifyContent="center">
      <HStack justifyContent="space-evenly">
        {props.handleList.map((handle, index) => {
          const key = handle.key;
          const displayName = handle.displayName || key;
          const sourceHandle = key;
          let outputNodeList = handle.outputNodeList || [];
          outputNodeList = props.getOutputNodeList
            ? props.getOutputNodeList(
                props.currentNodeId,
                outputNodeList,
                false,
                mergeParentNodeOutputList
              )
            : outputNodeList;
          return (
            <VStack key={sourceHandle + index}>
              <HStack alignItems="center">
                <Text
                  style={styles.nodeOutputLableText}
                  numberOfLines={1}
                  color={handle.color}
                >
                  {displayName}:
                </Text>
                <IconButton
                  onPress={(event: any) => {
                    setShowModalData({
                      showModal: true,
                      source: sourceHandle,
                      clientX: event?.clientX,
                      clientY: event?.clientY,
                    });
                  }}
                  width={6}
                  height={6}
                  variant="solid"
                  borderRadius={50}
                  size="sm"
                  marginRight="30px"
                  icon={
                    <>
                      <Icon as={AntIcon} size="xs" name="plus" color="white" />{' '}
                      <Handle
                        key={sourceHandle}
                        type="source"
                        position={Position.Bottom}
                        style={reactStyles.handleStyle4}
                        id={sourceHandle}
                      />
                    </>
                  }
                />
              </HStack>
              <NewOutputModel
                allOutputNodeList={outputNodeList}
                showModalData={showModalData}
                sourceHandle={sourceHandle}
                setShowModalData={setShowModalData}
                onPress={props.onPress}
              ></NewOutputModel>
            </VStack>
          );
        })}
      </HStack>
    </Flex>
  );
}

const UserActionState: FC<NodeProps> = ({data, id}) => {
  const workflowContext = useContext(WorkflowContext);
  const [isEdit, setIsEdit] = useState(false);
  const nodeMasterDataMap = workflowContext.nodeMasterDataMap;
  const [nodeType] = useState(data?.nodeType);
  const UserActionStateMetaData: IUserActionState = nodeMasterDataMap[nodeType];
  const displayName = UserActionStateMetaData?.displayName || nodeType;
  const defaultConditionList =
    UserActionStateMetaData?.conditionInfo?.conditionList || [];
  const metaData = data?.metaData || {};

  const [conditionList, setConditionList] = useState<
    Array<{[key: string]: Array<ICondition>}>
  >(metaData?.conditionList || defaultConditionList);
  const conditionResultInfo =
    UserActionStateMetaData.conditionInfo.conditionResultInfo;
  const inputParamList = UserActionStateMetaData.inputParamList || [];
  if (data.metaData && !data.metaData.conditionList) {
    data.setData('conditionList', conditionList);
  }
  // const setComponentStateWrapper = (key: string, value: any) => {
  //   const newState = { ...metaData, [key]: value };
  //   data.setData('metaData', newState);
  // }

  const handleList: Array<IConditionDisplayInfo> = Object.keys(
    conditionResultInfo
  ).map((key) => {
    return conditionResultInfo[key];
  });

  const inputParamMap: {[key: string]: IInputParams} = {};
  inputParamList.forEach((inputParam) => {
    inputParamMap[inputParam.key] = inputParam;
  });
  const getConditionDisplayValue = (key: string, value: any) => {
    if (
      inputParamMap[key].possibleValueList &&
      inputParamMap[key]?.possibleValueList?.length
    ) {
      let newValue;
      inputParamMap[key]?.possibleValueList?.some((data) => {
        if (data.value == value) {
          newValue = data.displayName;
          return true;
        }
      });
      if (newValue) {
        return newValue;
      }
      return value;
    } else {
      return value;
    }
  };

  const getConditionMapDisplayValue = (subConditionList: Array<ICondition>) => {
    return (
      <HStack flexDirection="row" flexWrap="wrap">
        {subConditionList.map((condition: ICondition, index) => {
          const displayName =
            inputParamMap[condition.key]?.displayName || condition.key;
          return (
            <Text key={condition.key + index} fontWeight={300}>
              {(condition?.bitwiseOperator
                ? condition.bitwiseOperator + ' '
                : '') +
                '' +
                displayName +
                ' ' +
                condition.operator +
                ' ' +
                getConditionDisplayValue(condition.key, condition.value)}{' '}
            </Text>
          );
        })}
      </HStack>
    );
  };

  const renderConditions = () => {
    if (!conditionList.length) {
      return <></>;
    }
    return (
      <>
        {conditionList.map(
          (conditionMap: {[key: string]: Array<ICondition>}, index: number) => {
            const key = Object.keys(conditionMap)[0];
            const displayInfo: IConditionDisplayInfo =
              conditionResultInfo[key] || {};
            const displayName = displayInfo.displayName || key;
            return (
              <>
                {' '}
                {index < 2 ? (
                  <HStack key={key + index} alignItems="start" space={2}>
                    <Text color={displayInfo.color} fontWeight={400} flex={1}>
                      {displayName}
                    </Text>
                    <View flex={5}>
                      {getConditionMapDisplayValue(conditionMap[key])}
                    </View>
                  </HStack>
                ) : (
                  <></>
                )}
              </>
            );
          }
        )}
      </>
    );
  };

  const onCloseEditMode = () => {
    setIsEdit(false);
  };

  const onSaveEditMode = (saveInfo: any) => {
    const newConditionList: Array<{[key: string]: Array<ICondition>}> =
      saveInfo.conditionList || [];
    data.setData('conditionList', newConditionList);
    setConditionList(newConditionList);
    setIsEdit(false);
  };
  return (
    <Box alignItems="center">
      <Box
        padding={2}
        rounded="md"
        borderColor="gray.100"
        borderWidth="1"
        shadow="1"
        backgroundColor="white"
        maxWidth="650"
      >
        <HStack justifyContent="center">
          <View top="-20px">
            <CircleIconView
              iconInfo={UserActionStateMetaData?.iconInfo}
              nodeCategory={
                UserActionStateMetaData.category?.code || NodeCategory.moment
              }
              backgroundColor={Colors.primary[100]}
              borderColor={Colors.primary[100]}
              iconColor={Colors.primary[900]}
            />
          </View>
        </HStack>
        <Handle
          type="target"
          position={Position.Top}
          id="in"
          style={reactStyles.handleStyle5}
        />
        {conditionList.length > 0 && (
          <View position="absolute" right="5px" marginTop={-1}>
            <IconButton
              icon={<Icon as={AntIcon} size="xs" name="edit" />}
              onPress={() => {
                setIsEdit(true);
              }}
            />
          </View>
        )}
        <VStack alignItems="center">
          <HStack>
            <Text color="black" fontWeight="bold" marginRight="20px">
              {displayName}
            </Text>
            {/* {!!conditionList.length ? (
              <Pressable
                onPress={() => {
                  setIsEdit(true);
                }}
              >
                <Text style={styles.editButtonText}>Edit</Text>
              </Pressable>
            ) : (
              <>{isEdit}</>
            )} */}
          </HStack>
          <Divider marginY="2" />
          <View>
            {renderConditions()}
            {conditionList.length > 2 && (
              <HStack marginTop={1}>
                <Spacer />
                <Pressable
                  key="more"
                  width="50px"
                  onPress={() => {
                    setIsEdit(true);
                  }}
                >
                  <Text color="primary.500">More...</Text>
                </Pressable>
              </HStack>
            )}
          </View>
          <Divider marginY="2" />
        </VStack>
        <Drawer
          title={
            <Text fontSize={24} fontWeight={400}>
              {displayName}
            </Text>
          }
          destroyOnClose
          placement="right"
          onClose={() => {
            onCloseEditMode();
          }}
          visible={isEdit}
          closable={false}
          width={'40%'}
        >
          <SideMenu
            getPreviousImmediateNode={data?.getPreviousImmediateNode}
            conditionList={conditionList}
            inputParamList={inputParamList}
            conditionResultInfo={conditionResultInfo}
            nodeType={nodeType}
            userInputFieldMap={{out: {userInputFieldList: []}}}
            isOpen={isEdit}
            title={displayName}
            onClose={onCloseEditMode}
            onSave={onSaveEditMode}
          />
        </Drawer>
        <ButtonMenu
          getOutputNodeList={data.getOutputNodeList}
          currentNodeId={id}
          nodeMasterDataMap={nodeMasterDataMap}
          handleList={handleList}
          onPress={(formData, clientX, clientY) =>
            data.onSelect(
              {
                ...formData,
                sourceId: id,
              },
              clientX,
              clientY
            )
          }
        />
      </Box>
    </Box>
  );
};

export default UserActionState;
