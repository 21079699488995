import React, { useContext } from 'react';
import { Text, VStack, View, HStack, Spinner, Center, Spacer, Box } from 'native-base';
import { useIntl } from 'react-intl';
import { Colors } from '../../../styles/Colors';
import { DisplayCardAvatar } from '../DisplayCard/DisplayCardAvatar';
import { IAuditRender, IMappedAccountUsers, IAuditState } from './AuditInterfaces';
import { ACTION_CODE, TASK_FIELDS, fieldsOfTask, TASK_RESOURCE_TYPE_CODES, TASK_RESOURCE_TYPE_VALUES, AUDIT_ACTION_CODES, getDisplayName, DATA_KEYS, fieldsOfAppointment, APPOINTMENT_RESOURCE_TYPE_CODES, PARTICIPANT_RESOURCE_TYPE_VALUES, APPOINTMENT_FIELDS, PARTICIPANT_ACTION_CODE, APPOINTMENT_RESOURCE_TYPE_VALUES, CHANGES_APPLIED_ON_SERIES, isParticipantStatusChangeLog } from './AuditHelper';
import { DATE_FORMATS, GROUP_MEMBER_TYPE, MLOV_CATEGORY } from '../../../constants';
import RightArrowIconSvg from '../Svg/RightArrowIconSvg';
import { StyleProp, ViewStyle } from 'react-native';
import { getMomentObj, getDateObjectFromAPIFormatToMyFormat, getDateStrFromFormat } from '../../../utils/DateUtils';
import { getMlovIdFromCode, getMlovListFromCategory } from '../../../utils/mlovUtils';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { IMlov } from '../../../Interfaces';
import TaskPriorityIcon from '../../../assets/Icons/TaskPriorityIcon';
import { APPOINTMENT_STATUS_CODES, TASK_STATUS } from '../../../constants/MlovConst';
import ClockIconForAudit from '../Svg/ClockIconForAudit';
import { isWeb } from '../../../utils/platformCheckUtils';
import CareJourneyIcon from '../Svg/CareJourneyIcon';
import AutomationIcon from '../Svg/AutomationIcon';
import { testID, TestIdentifiers } from '../../../testUtils';
import { styles } from './AuditRenderStyles';
import { AUDIT_RESOURCE } from '../CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/BottomTabs';
import { appointmentLogView, basicContentView, getEhrDisplayName, getMinimumWidthForView, processDataRecurrence, renderAppointmentNoteView, renderAppointmentTaskView, renderParticipantView } from './AuditViewHelper';
import Feather from 'react-native-vector-icons/Feather';
import { getSummaryString } from '../CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/hooks/useReccuringEvent/helpers';

const AuditRender = (props: IAuditRender) => {
    const intl = useIntl();
    const mlovData = useContext(CommonDataContext);
    const taskPriorityMlov = getMlovListFromCategory(
        mlovData.CARE_STUDIO_MLOV,
        MLOV_CATEGORY.TASK_PRIORITY,
        false
    );
    const taskStatusMlov =
        getMlovListFromCategory(
            mlovData.CARE_STUDIO_MLOV,
            MLOV_CATEGORY.TASK_STATUS
        ) || [];

    const appointmentStatusList =
        getMlovListFromCategory(
            mlovData.CARE_STUDIO_MLOV,
            MLOV_CATEGORY.APPOINTMENT_STATUS
        ) || [];

    const appointmentPendingStatus = getMlovIdFromCode(
        appointmentStatusList,
        APPOINTMENT_STATUS_CODES.PENDING
    );

    const appointmentScheduledStatus = getMlovIdFromCode(
        appointmentStatusList,
        APPOINTMENT_STATUS_CODES.SCHEDULED
    );
    const FOLD_EHR_CODE = 'FHIR_STORE';

    const getFieldsToConsider = () => {
        switch(props?.auditResource) {
            case AUDIT_RESOURCE.APPOINTMENT:
                return !props?.isGroupSessionAppointment
                    ? Object.fromEntries(Object.entries(fieldsOfAppointment).filter(([key]) => key !== 'DESCRIPTION' && key !== 'NAME'))
                    : fieldsOfAppointment;
            case AUDIT_RESOURCE.TASK:
                return fieldsOfTask
            default:
                return {key: 'value'}
        }
    }

    const getFieldsNameMap = () => {
        switch(props?.auditResource) {
            case AUDIT_RESOURCE.APPOINTMENT:
                return APPOINTMENT_FIELDS
            case AUDIT_RESOURCE.TASK:
                return TASK_FIELDS
            default:
                return {key: 'value'}
        }
    }
    const getCombinedKeys = (log: IAuditState) => {
        const filterNonNullKeys = (data: Record<string, any> | null) =>
            data ? Object.keys(data).filter(key => data[key] !== null) : [];

        const oldDataKeys = filterNonNullKeys(log?.data?.oldData);
        const newDataKeys = filterNonNullKeys(log?.data?.newData);

        return new Set([...oldDataKeys, ...newDataKeys]);
    };


    const getActionTextForTitle = (log: IAuditState) => {
        const textTitle = log?.actionCode === ACTION_CODE.CREATE ? intl.formatMessage({ id: 'added' }) : intl.formatMessage({ id: 'changed' });

        const isTaskLog = log?.parentResourceTypeCode === TASK_RESOURCE_TYPE_CODES.TASKS || log?.resourceTypeCode === TASK_RESOURCE_TYPE_CODES.TASKS;
        const isAppointmentLog = log?.parentResourceTypeCode === APPOINTMENT_RESOURCE_TYPE_CODES.APPOINTMENT || log?.resourceTypeCode === APPOINTMENT_RESOURCE_TYPE_CODES.APPOINTMENT;

        if (isTaskLog) {
            if (log?.resourceTypeCode === TASK_RESOURCE_TYPE_CODES.TASKS) {
                return log?.actionCode === ACTION_CODE.CREATE ? 'created the Task' : `${textTitle} ${getAdditionalTextInTitle(log)}`;
            } else if (log?.resourceTypeCode === TASK_RESOURCE_TYPE_CODES.TASK_COMMENTS) {
                return `${textTitle} a ${TASK_RESOURCE_TYPE_VALUES.TASK_COMMENTS}`;
            } else if (log?.resourceTypeCode in TASK_RESOURCE_TYPE_VALUES) {
                return `${textTitle} the ${TASK_RESOURCE_TYPE_VALUES[log?.resourceTypeCode as keyof typeof TASK_RESOURCE_TYPE_VALUES]}`;
            }
        }
        if (isAppointmentLog) {
            const seriesText = getAppointmentSeriesText(log?.data?.newData?.seriesReference)
            if (log?.resourceTypeCode === APPOINTMENT_RESOURCE_TYPE_CODES.APPOINTMENT) {
                if (log?.actionCode === ACTION_CODE.CREATE) {
                    const seriesCreationText = log?.showSeriesCreationAuditLog ? 'Series' : '';
                    return `created the Appointment ${seriesCreationText}`;
                  } else {
                    return `${textTitle} ${getAdditionalTextInTitle(log)} ${seriesText}`;
                  }
            }
            else if (
                log?.resourceTypeCode === APPOINTMENT_RESOURCE_TYPE_CODES.APPOINTMENT_PARTICIPANT &&
                !!log?.participantTypeCode
            ) {
                let newTextTitle = '';
                if(log?.actionCode === ACTION_CODE.ACCEPT_APPOINTMENT) {
                    return 'accepted the appointment RSVP'
                }
                else if (log?.actionCode === ACTION_CODE.DECLINE_APPOINTMENT) {
                    return 'declined the appointment RSVP'
                }
                else if (log.actionCode === ACTION_CODE.PURPOSED_TIME_APPOINTMENT) {
                    return 'proposed a new time for the appointment RSVP'
                }
                else {
                    if (APPOINTMENT_RESOURCE_TYPE_CODES.APPOINTMENT_PARTICIPANT && log?.participantActionCode) {
                        newTextTitle = log?.participantActionCode === PARTICIPANT_ACTION_CODE.ADD ? 'added' : 'removed';
                    }

                    const actionDescriptor = newTextTitle ? 'a' : 'the';
                    const index = log?.participantTypeCode as keyof typeof PARTICIPANT_RESOURCE_TYPE_VALUES
                    const participantType = PARTICIPANT_RESOURCE_TYPE_VALUES[index];

                    return `${newTextTitle || textTitle} ${actionDescriptor} ${participantType} ${seriesText}`;
                }
            }
            else if (log?.resourceTypeCode in APPOINTMENT_RESOURCE_TYPE_VALUES) {
                const index = log?.resourceTypeCode as keyof typeof APPOINTMENT_RESOURCE_TYPE_VALUES
                return `${textTitle} the ${APPOINTMENT_RESOURCE_TYPE_VALUES[index]} ${seriesText}`;
            }
        }
    };

    const getAdditionalTextInTitle = (log: IAuditState) => {
        const combinedKeys = getCombinedKeys(log);
        const intersectionWithFields = Object.values(getFieldsToConsider())?.filter(key => combinedKeys.has(key));
        const fieldsNameMap =  getFieldsNameMap()
        return `the ${fieldsNameMap?.[intersectionWithFields?.[0]]}`;
    };

    const getAppointmentSeriesText = (seriesReference?: any) => {
        const seriesReferenceReason = seriesReference?.reason
        let seriesText = ''
        if (seriesReferenceReason) {
            if (seriesReferenceReason === CHANGES_APPLIED_ON_SERIES) {
                seriesText = 'for the series'
            }
        }
        return seriesText
    }

    const displayUserOrPatient = (user: any, isContact?: boolean) => (
        <HStack marginY={2} alignItems='center'>
            <DisplayCardAvatar
                avatarStyle={isWeb() ? {
                    avatarSize: '10',
                    width: 28,
                    height: 28,
                    textStyle: { fontSize: 12 },
                } :
                    {
                        avatarSize: '8',
                    }
                }
                isLetterAvatarShow={true}
                userData={{
                    userId: '',
                    userType: isContact ? GROUP_MEMBER_TYPE.CONTACT : GROUP_MEMBER_TYPE.USER,
                    userName: user?.name,
                }}
            />
            <Text
                ml={2}
                fontWeight={'500'}
                fontSize={14}
                style={styles.textColor}
                fontFamily={'Manrope'}
                maxWidth={isWeb() ? '90%' : '75%'}
                {...testID('UserName')}
            >
                {user?.name}
            </Text>
        </HStack>
    );

    const getActionPerformerTitle = (actionPerformedByUser: IMappedAccountUsers, log: IAuditState, isJourneyNameFirstPreference: boolean) => {
        const journeyTitle = props?.journeyDetails?.title ||
        (log?.performedByPatientCareJourneyId ? props?.uuidMappingToData[log?.performedByPatientCareJourneyId]?.displayName : undefined);
        let ehrUser = '';
        if(props?.auditResource === AUDIT_RESOURCE.APPOINTMENT && log?.source !== FOLD_EHR_CODE) {
            ehrUser = 'EHR User'
        }

        if (isJourneyNameFirstPreference) {
            return (
                journeyTitle && `Journey- ${journeyTitle}`) ||
                ehrUser || actionPerformedByUser?.name ||
                'Automation';
        } else {
            return (
                ehrUser || actionPerformedByUser?.name ||
                (journeyTitle && `Journey- ${journeyTitle}`) ||
                'Automation'
            );
        }
    }

    const isRecurrenceDataSameInOldAndNew = (log: IAuditState) => {
        const oldDataRecurrence = processDataRecurrence(log?.data?.oldData?.reference?.recurrenceData);
        const newDataRecurrence = processDataRecurrence(log?.data?.newData?.reference?.recurrenceData);

        const oldDataTextForDisplay = getSummaryString(oldDataRecurrence, {
            startTime: getDateStrFromFormat(log?.data?.oldData?.startDateTime, DATE_FORMATS.CALENDAR_LIB_FORMAT)
        });

        const newDataTextForDisplay = getSummaryString(processDataRecurrence(newDataRecurrence), {
            startTime: getDateStrFromFormat(log?.data?.newData?.startDateTime, DATE_FORMATS.CALENDAR_LIB_FORMAT)
        });
        return oldDataTextForDisplay === newDataTextForDisplay;
    }


    const getAuditActionTitle = (actionPerformedByUser: IMappedAccountUsers, log: IAuditState, key?: string) => {
        const combinedKeys = getCombinedKeys(log);
        const intersectionWithFields = Object.values(getFieldsToConsider()).filter(key => combinedKeys.has(key));
        const isTaskCreation = log?.resourceTypeCode === TASK_RESOURCE_TYPE_CODES.TASKS && log?.actionCode === ACTION_CODE.CREATE
        const isLogCreatedByJourney = (isTaskCreation && props?.journeyDetails?.title) || (log?.performedByPatientCareJourneyId);
        const isTaskOrAppointmentResource = log?.resourceTypeCode === TASK_RESOURCE_TYPE_CODES.TASKS || log?.resourceTypeCode === APPOINTMENT_RESOURCE_TYPE_CODES.APPOINTMENT;
        const isNotCreateAction = log?.actionCode !== ACTION_CODE.CREATE;
        let toShowLogTitle = (isTaskOrAppointmentResource && isNotCreateAction) ? intersectionWithFields.length === 1 : true;
        if (log?.resourceTypeCode === APPOINTMENT_RESOURCE_TYPE_CODES.APPOINTMENT && toShowLogTitle &&
            intersectionWithFields?.[0] === fieldsOfAppointment.REFERENCE &&
            (!log?.data?.newData?.reference?.recurrenceData ||
                !getAppointmentSeriesText(log?.data?.newData?.seriesReference)?.length ||
                isRecurrenceDataSameInOldAndNew(log))
        ) {
            toShowLogTitle = false;
        }

        return toShowLogTitle ? (
          isWeb() ? (
            <View width="100%" key={key} maxWidth={'100%'}>
              <HStack alignItems={'flex-start'} width={'100%'} pt={1}>
                <Box>
                  {!isLogCreatedByJourney &&
                    (actionPerformedByUser?.name ? (
                      <DisplayCardAvatar
                        avatarStyle={{
                          avatarSize: '10',
                          width: 28,
                          height: 28,
                          textStyle: {fontSize: 12},
                        }}
                        isLetterAvatarShow={true}
                        userData={{
                          userId: '',
                          userType:
                            log?.performedByTypeCode ===
                            GROUP_MEMBER_TYPE.CONTACT
                              ? GROUP_MEMBER_TYPE.CONTACT
                              : GROUP_MEMBER_TYPE.USER,
                          userName: actionPerformedByUser?.name,
                        }}
                      />
                    ) : props?.auditResource === AUDIT_RESOURCE.APPOINTMENT &&
                      log?.source !== FOLD_EHR_CODE ? (
                      <Feather
                        name="user"
                        size={17}
                        style={styles.iconStyle}
                      />
                    ) : (
                      <AutomationIcon />
                    ))}
                  {!!isLogCreatedByJourney && <CareJourneyIcon />}
                </Box>
                <HStack maxW={'70%'} pl={3}>
                  <VStack flex={1}>
                    <Text>
                      <Text
                        fontWeight={'600'}
                        fontSize={14}
                        style={styles.colorGray500}
                        {...testID('PerformedOn')}
                      >
                        {getActionPerformerTitle(
                          actionPerformedByUser,
                          log,
                          isTaskCreation ||
                            !!log?.performedByPatientCareJourneyId
                        )}
                      </Text>
                      <Text
                        fontWeight={'500'}
                        fontSize={14}
                        style={styles.colorGray500}
                        {...testID('ActionTitle')}
                      >
                        {` ${getActionTextForTitle(log)}`}
                      </Text>
                    </Text>
                    {props?.auditResource === AUDIT_RESOURCE.APPOINTMENT &&
                      !!log?.source && (
                          <Text
                            fontSize={11}
                            color={Colors?.Custom?.Gray700}
                            {...testID('Source')}
                          >
                            <Text fontWeight={'400'} fontFamily="Manrope">
                              Source:{' '}
                            </Text>
                            <Text fontWeight={'550'} fontFamily="Manrope">
                              {getEhrDisplayName(log?.source, props?.ehrList)}
                            </Text>
                          </Text>
                      )}
                  </VStack>
                </HStack>
                <Spacer />
                <View alignSelf={'flex-start'}>
                  <Text
                    fontWeight={'500'}
                    fontSize={12}
                    fontStyle="normal"
                    style={styles.textStyle}
                    {...testID('SyncDate')}
                  >
                    {getDateObjectFromAPIFormatToMyFormat(
                      log?.timestamp,
                      DATE_FORMATS.TASK_LOG_DATE_FORMAT
                    )}
                  </Text>
                  {log?.source !== FOLD_EHR_CODE &&
                    props?.auditResource === AUDIT_RESOURCE.APPOINTMENT &&
                    log?.actionCode === ACTION_CODE.CREATE &&
                    log?.data?.newData?.statusId !== appointmentPendingStatus &&
                    log?.data?.newData?.statusId !==
                      appointmentScheduledStatus && (
                      <Text
                        fontWeight={'500'}
                        fontSize={12}
                        fontStyle="normal"
                        style={styles.textStyle2}
                      >
                        Last Synced
                      </Text>
                    )}
                </View>
              </HStack>
            </View>
          ) : (
            <View
              alignItems="center"
              width="100%"
              style={styles.flexDirectionRow}
            >
              <HStack alignItems="center" width="60%">
                <VStack alignItems={'center'} marginTop={-1}>
                  {!isLogCreatedByJourney &&
                    (actionPerformedByUser?.name ? (
                      <DisplayCardAvatar
                        avatarStyle={{
                          avatarSize: 8,
                        }}
                        isLetterAvatarShow={true}
                        userData={{
                          userId: '',
                          userType:
                            log?.performedByTypeCode ===
                            GROUP_MEMBER_TYPE.CONTACT
                              ? GROUP_MEMBER_TYPE.CONTACT
                              : GROUP_MEMBER_TYPE.USER,
                          userName: actionPerformedByUser?.name,
                        }}
                      />
                    ) : (
                      <AutomationIcon />
                    ))}
                  {!!isLogCreatedByJourney && <CareJourneyIcon />}
                </VStack>
                <HStack flexWrap={'wrap'}>
                  <Text
                    ml={4}
                    fontSize={14}
                    style={styles.colorGray500}
                    {...testID('PerformedBy')}
                  >
                    <Text fontWeight={'600'}>
                      {getActionPerformerTitle(
                        actionPerformedByUser,
                        log,
                        isTaskCreation || !!log?.performedByPatientCareJourneyId
                      )}{' '}
                    </Text>
                    <Text fontWeight={'500'}>{getActionTextForTitle(log)}</Text>
                  </Text>
                </HStack>
              </HStack>
              <Spacer />
              <View
                justifyContent="flex-end"
                alignItems="center"
                {...testID('PerformedTime')}
              >
                <Text
                  fontWeight={'500'}
                  fontSize={12}
                  fontStyle="normal"
                  marginTop={-5}
                  style={styles.textStyle}
                >
                  {getMomentObj(log?.timestamp).format(
                    DATE_FORMATS.TASK_LOG_DATE_FORMAT
                  )}
                </Text>
              </View>
            </View>
          )
        ) : (
          <></>
        );
    }

    const getStatusColors = (statusCode: string | undefined) => {
      switch (statusCode) {
          case TASK_STATUS.COMPLETED:
              return {
                  backgroundColor: Colors.FoldPixel.STATUS_LIGHT_SUCCESS,
                  textColor: Colors.FoldPixel.STATUS_DARK_SUCCESS
              };
          case TASK_STATUS.ACCEPTED:
              return {
                  backgroundColor: Colors.Custom.PendingTaskBackgroundColor,
                  textColor: Colors.FoldPixel.STATUS_IN_PROGRESS
              };
          case TASK_STATUS.MISSED:
              return {
                  backgroundColor: Colors?.Custom?.MissedTaskBackgroundColor,
                  textColor: Colors?.Custom?.MissedTaskFontColor
              };
          default:
              return {
                  backgroundColor: Colors?.Custom?.Gray200,
                  textColor: Colors?.Custom?.textBlackColor
              };
      }
  };


    const taskLogView = (log: IAuditState, key: string) => {
        const getMappedUserOrPatient = (dataKey: 'oldData' | 'newData', isContact?: boolean) => {
            const data = log?.data as { newData: { [key: string]: any }; oldData: { [key: string]: any } };
            const userData = props?.uuidMappingToData?.[data[dataKey][key]] || props?.accountUsersMappedById?.[data[dataKey][key]];
            return userData ? displayUserOrPatient(userData, isContact) : basicContentView('None');
        };
        const viewStyles = isWeb() ? styles.logDetail : styles?.markDownWrapper
        switch (key) {
            case fieldsOfTask?.ASSIGNEE_ID:
                return (
                    <HStack alignItems="center" space={2} maxWidth="100%">
                      <View style={styles.logDetail}>
                        {getMappedUserOrPatient(
                          'oldData',
                          !props?.accountUsersMappedById[
                            log?.data?.oldData?.assigneeId
                          ]
                        )}
                      </View>
                      <View width={4}>
                        <RightArrowIconSvg />
                      </View>
                      <View style={styles.logDetail}>
                        {getMappedUserOrPatient(
                          'newData',
                          !props?.accountUsersMappedById[
                            log?.data?.newData?.assigneeId
                          ]
                        )}
                      </View>
                    </HStack>
                  );

            case fieldsOfTask?.CONTACT_ID:
                return (
                    <HStack alignItems="center" space={2} maxWidth="100%">
                        <View style={styles.logDetail}>
                            {getMappedUserOrPatient('oldData', true)}
                        </View>
                        <View width={4}><RightArrowIconSvg /></View>
                        <View style={styles.logDetail}>
                            {getMappedUserOrPatient('newData', true)}
                        </View>
                    </HStack>
                );

            case fieldsOfTask?.DESCRTIPTION:
                return (
                    <HStack alignItems="center" space={2} maxWidth="100%">
                        <View style={{ ...viewStyles, maxWidth: '50%' }} minWidth={getMinimumWidthForView(log?.data?.oldData?.[key])}>
                            {basicContentView(log?.data?.oldData?.[key], fieldsOfTask?.DESCRTIPTION)}
                        </View>
                        <View width={4}><RightArrowIconSvg /></View>
                        <View style={viewStyles} minWidth={getMinimumWidthForView(log?.data?.newData?.[key])}>
                            {basicContentView(log?.data?.newData?.[key], fieldsOfTask?.DESCRTIPTION)}
                        </View>
                    </HStack>
                )
            case fieldsOfTask?.TITLE:
                return (
                    <HStack alignItems="center" space={2} maxWidth="100%">
                        <View style={viewStyles} minWidth={getMinimumWidthForView(log?.data?.oldData?.[key])}>
                            {basicContentView(log?.data?.oldData?.[key])}
                        </View>
                        <View width={4}><RightArrowIconSvg /></View>
                        <View style={viewStyles} minWidth={getMinimumWidthForView(log?.data?.newData?.[key])}>
                            {basicContentView(log?.data?.newData?.[key])}</View>
                    </HStack>
                );

            case fieldsOfTask?.USER_POOL_ID:
                return (
                    <HStack alignItems="center" space={2} maxWidth="100%">
                        <View style={viewStyles} minWidth={getMinimumWidthForView(log?.data?.oldData?.[key])}>
                            {basicContentView(props?.uuidMappingToData?.[log?.data?.oldData?.[key]]?.displayName)}</View>
                        <View width={4}><RightArrowIconSvg /></View>
                        <View style={viewStyles} minWidth={getMinimumWidthForView(log?.data?.newData?.[key])}>
                            {basicContentView(props?.uuidMappingToData?.[log?.data?.newData?.[key]]?.displayName)}</View>
                    </HStack>
                );

            case fieldsOfTask?.END_DATE_TIME:
                return (
                    <HStack alignItems="center" space={2}>
                        {basicContentView(log?.data?.oldData?.[key] ?
                            getMomentObj(
                                log?.data?.oldData?.[key],
                            ).format(DATE_FORMATS.TASK_DUE_DATE_DISPLAY_FORMAT)
                            : 'None'
                        )}
                        <RightArrowIconSvg />
                        {basicContentView(log?.data?.newData?.[key] ?
                            getMomentObj(
                                log?.data?.newData?.[key],
                            ).format(DATE_FORMATS.TASK_DUE_DATE_DISPLAY_FORMAT)
                            : 'None'
                        )}
                    </HStack>
                )

            case fieldsOfTask?.PRIORITY_ID:
                const oldPriority = taskPriorityMlov?.find((mlov: IMlov) => mlov?.id === log?.data?.oldData?.[key]);
                const newPriority = taskPriorityMlov?.find((mlov: IMlov) => mlov?.id === log?.data?.newData?.[key]);
                return (
                    <HStack alignItems="center" space={2}>
                        {oldPriority?.code && <TaskPriorityIcon priorityCode={oldPriority?.code} />}
                        {basicContentView(taskPriorityMlov?.find((mlov) => mlov?.id === log?.data?.oldData?.[key])?.value)}
                        <RightArrowIconSvg />
                        {newPriority?.code && <TaskPriorityIcon priorityCode={newPriority?.code} />}
                        {basicContentView(taskPriorityMlov?.find((mlov) => mlov?.id === log?.data?.newData?.[key])?.value)}
                    </HStack>
                );

            case fieldsOfTask?.STATUS_ID:
                const oldStatus = taskStatusMlov?.find((mlov: IMlov) => mlov?.id === log?.data?.oldData?.[key]);
                const newStatus = taskStatusMlov?.find((mlov: IMlov) => mlov?.id === log?.data?.newData?.[key]);
                return (
                    <HStack alignItems="center" space={2} marginY={2}>
                        {([oldStatus, newStatus]).map((status, index) => {
                            const isOldStatus = (status === oldStatus);
                            const { backgroundColor, textColor } = getStatusColors(status?.code);
                            return (
                                <>
                                    <View
                                        key={index}
                                        borderRadius={4}
                                        backgroundColor={backgroundColor}
                                        paddingX={3}
                                    >
                                        <Text
                                            fontSize={14}
                                            color={textColor}
                                        >
                                            {status?.value}
                                        </Text>
                                    </View>
                                    {isOldStatus && <RightArrowIconSvg />}
                                </>
                            );
                        })}
                    </HStack>
                );
        }
    };

    const getAuditActionDetails = (log: IAuditState) => {
        const stylesForView = isWeb() ? styles.logDetail : styles?.markDownWrapper
        const renderPossibleMultipleLogView = (oldDataKey: string, newDataKey: string, logView: any, multipleChangesInSingleLog?: boolean) => {
            const actionPerformedByUser = props?.uuidMappingToData[log?.performedById] || props?.accountUsersMappedById[log?.performedById];
            const isAppointmentAuditAndStartDateTime = oldDataKey === fieldsOfAppointment?.START_DATE_TIME && props?.auditResource === AUDIT_RESOURCE.APPOINTMENT;
            const auditTitleData: IAuditState = {
                ...log,
                data: {
                    oldData: {
                        [oldDataKey]: log?.data?.oldData[oldDataKey],
                        ...(log?.data?.oldData?.seriesReference && { seriesReference: log?.data?.oldData?.seriesReference }),
                        ...(isAppointmentAuditAndStartDateTime && { endDateTime: log?.data?.oldData?.endDateTime }),
                        ...(oldDataKey === fieldsOfAppointment.REFERENCE && { startDateTime: log?.data?.oldData?.startDateTime})
                    },
                    newData: {
                        [newDataKey]: log?.data?.newData[newDataKey],
                        ...(log?.data?.newData?.seriesReference && { seriesReference: log?.data?.newData?.seriesReference }),
                        ...(isAppointmentAuditAndStartDateTime && { endDateTime: log?.data?.newData?.endDateTime }),
                        ...(newDataKey === fieldsOfAppointment.REFERENCE && { startDateTime: log?.data?.newData?.startDateTime})
                    },
                },
              };

            return (
                <View key={log?.id + oldDataKey}>
                    {multipleChangesInSingleLog && getAuditActionTitle(actionPerformedByUser, auditTitleData, log?.id + oldDataKey)}
                    <View key={log?.id + oldDataKey} style={styles.logStyle as StyleProp<ViewStyle>}>
                        {logView}
                    </View>
                </View>
            );
        }

        const renderAttachmentView = () => {
            const oldDataText = getDisplayName(log, props?.uuidMappingToData, fieldsOfTask.ATTACHMENT_ID, DATA_KEYS.OLD_DATA)
            const newDataText = getDisplayName(log, props?.uuidMappingToData, fieldsOfTask.ATTACHMENT_ID, DATA_KEYS.NEW_DATA)
            return (
                <View style={styles.logStyle as StyleProp<ViewStyle>}>
                    <HStack alignItems="center" space={2} maxWidth="100%">
                        {log?.actionCode !== ACTION_CODE.CREATE &&
                            <>
                                <View style={stylesForView} minWidth={getMinimumWidthForView(oldDataText)}>
                                    {basicContentView(oldDataText)}
                                </View>
                                <View width={4}><RightArrowIconSvg /></View>
                            </>
                        }
                        <View style={stylesForView} minWidth={getMinimumWidthForView(newDataText)}>
                            {basicContentView(newDataText)}
                        </View>
                    </HStack>
                </View>
        );
        }

        const renderLabelView = () => {
            const oldDataText = getDisplayName(log, props?.uuidMappingToData, fieldsOfTask.LABEL_ID, DATA_KEYS.OLD_DATA)
            const newDataText = getDisplayName(log, props?.uuidMappingToData, fieldsOfTask.LABEL_ID, DATA_KEYS.NEW_DATA)

            return (
                <View style={styles.logStyle as StyleProp<ViewStyle>}>
                    <HStack alignItems="center" space={2} maxWidth="100%" >
                        {log?.actionCode !== ACTION_CODE.CREATE &&
                            <>
                                <View style={stylesForView} minWidth={getMinimumWidthForView(oldDataText)}>
                                    {basicContentView(oldDataText)}
                                </View>
                                <View width={4}><RightArrowIconSvg /></View>
                            </>
                        }
                        <View style={stylesForView} minWidth={getMinimumWidthForView(newDataText)}>
                            {basicContentView(newDataText)}
                        </View>
                    </HStack>
                </View>
            )
        };

        const renderCommentsView = () => {
            const oldDataText = log?.data?.oldData?.comment || props?.uuidMappingToData?.[log?.resourceId]?.displayName
            const newDataText = log?.data?.newData?.comment || 'None'

            return (
                <View style={styles.logStyle as StyleProp<ViewStyle>}>
                    < HStack alignItems="center" space={2} maxWidth="100%">
                        {log?.actionCode === ACTION_CODE.UPDATE &&
                            <>
                                <View style={stylesForView} minWidth={getMinimumWidthForView(oldDataText)}>
                                    {basicContentView(oldDataText)}
                                </View>
                                <View width={4}><RightArrowIconSvg /></View>
                            </>
                        }
                        <View style={stylesForView} minWidth={getMinimumWidthForView(newDataText)}>
                            {basicContentView(newDataText)}
                        </View>
                    </ HStack>
                </View>
            );
        }

        if (log?.resourceTypeCode === TASK_RESOURCE_TYPE_CODES.TASKS && log?.actionCode !== ACTION_CODE.CREATE) {
            const oldDatakeys = Object.keys(log?.data?.oldData);
            // check assigneeId and member id are same in log
            const newLogData = log?.data?.newData || {};
            const oldLogData = log?.data?.oldData || {};
            if (newLogData?.assigneeId && newLogData?.contactId && newLogData?.assigneeId === newLogData?.contactId) {
              delete newLogData?.contactId;
              delete oldLogData?.contactId;
            }
            const combinedKeys = getCombinedKeys(log);
            const isMultipleChangesInSingleLog = Object.values(fieldsOfTask).filter(key => combinedKeys.has(key))?.length > 1;
            return oldDatakeys.map(key =>
                Object.values(fieldsOfTask)?.includes(key) && renderPossibleMultipleLogView(key, key, taskLogView(log, key), isMultipleChangesInSingleLog))
        } else if (log?.resourceTypeCode === TASK_RESOURCE_TYPE_CODES.TASK_ATTACHMENT) {
            return renderAttachmentView();
        } else if (log?.resourceTypeCode === TASK_RESOURCE_TYPE_CODES.TASK_LABEL) {
            return renderLabelView();
        } else if (log?.resourceTypeCode === TASK_RESOURCE_TYPE_CODES.TASK_COMMENTS) {
            return renderCommentsView();
        }

        else if(log?.resourceTypeCode === APPOINTMENT_RESOURCE_TYPE_CODES.APPOINTMENT && log?.actionCode !== ACTION_CODE.CREATE) {
            const oldDatakeys = Object.keys(log?.data?.oldData);
            const combinedKeys = getCombinedKeys(log);
            const updatedFieldsOfAppointment = !props?.isGroupSessionAppointment
                ? Object.fromEntries(Object.entries(fieldsOfAppointment).filter(([key]) => key !== 'DESCRIPTION' && key !== 'NAME'))
                : fieldsOfAppointment;
            const isMultipleChangesInSingleLog = Object.values(updatedFieldsOfAppointment).filter(key => combinedKeys?.has(key))?.length > 1;

            return oldDatakeys.map(key => {
                const isReferenceKey = key === updatedFieldsOfAppointment.REFERENCE;
                const shouldRender = (isReferenceKey && log?.data?.newData?.reference?.recurrenceData
                    && getAppointmentSeriesText(log?.data?.newData?.seriesReference)?.length > 0 &&
                    !isRecurrenceDataSameInOldAndNew(log)) ||
                    !isReferenceKey && Object.values(updatedFieldsOfAppointment)?.includes(key);

                if (shouldRender) {
                  return renderPossibleMultipleLogView(
                    key,
                    key,
                    appointmentLogView(log, key, props?.uuidMappingToData, props?.accountUsersMappedById),
                    isMultipleChangesInSingleLog
                  );
                }
              });
        }
        else if(log?.resourceTypeCode === APPOINTMENT_RESOURCE_TYPE_CODES.APPOINTMENT_PARTICIPANT && !isParticipantStatusChangeLog(log?.actionCode)) {
            return renderParticipantView(log, props?.uuidMappingToData, props?.accountUsersMappedById)
        }
        else if(log?.resourceTypeCode === APPOINTMENT_RESOURCE_TYPE_CODES.APPOINTMENT_NOTE) {
            return renderAppointmentNoteView(log)
        }
        else if(log?.resourceTypeCode === APPOINTMENT_RESOURCE_TYPE_CODES.APPOINTMENT_TASK) {
            return renderAppointmentTaskView(log)
        }
    };

    const showNoData = (
        <Center>
            < ClockIconForAudit />
            <Text color={Colors.Custom.Gray400} fontSize={16} fontWeight='500'>
                No history created
            </Text>
        </Center>
    )

    const renderLogData = (
        <>
            {(props?.auditData?.loadingAuditData?.initialAuditDataLoading || props?.addCommentLoading) && <Spinner paddingY={5}/>}

            {!props?.auditData?.loadingAuditData?.initialAuditDataLoading && props?.auditData?.logData?.map((log, index: any) => {
                const actionPerformedByUser =
                    log?.performedByTypeCode === GROUP_MEMBER_TYPE.CONTACT
                        ? props?.uuidMappingToData[log?.performedById]
                        : props?.accountUsersMappedById[log?.performedById];
                return (
                    <VStack key={log?.resourceId + `${index}`} paddingY={1} space={!isWeb() ? 3 : 1}>
                        {getAuditActionTitle(actionPerformedByUser, log, log?.id)}
                        {getAuditActionDetails(log)}
                    </VStack>
                );
            })}

            {!props?.hideShowMore && !props?.auditData?.loadingAuditData?.paginationLoading && !props?.auditData?.loadingAuditData?.initialAuditDataLoading &&
                <HStack>
                    {props?.auditData?.aggregate && (props?.auditData?.aggregate > props?.totalTransactionsFetchedCount) &&
                        <Text
                            color={Colors?.Custom?.mainPrimaryPurple}
                            fontSize={16}
                            fontWeight={'700'}
                            paddingTop={3}
                            onPress={() => props?.handleActions(AUDIT_ACTION_CODES.SHOW_MORE)}
                            {...testID(TestIdentifiers?.loadMore)}
                        >
                            Load more ...
                        </Text>
                    }

                    {(props?.auditData?.logData?.length > props?.initialBatchLogsCountData?.logCount) &&
                        <Text
                            color={Colors?.Custom?.mainPrimaryPurple}
                            fontSize={16}
                            fontWeight={'700'}
                            paddingTop={3}
                            paddingLeft={2}
                            onPress={() => props?.handleActions(AUDIT_ACTION_CODES.SHOW_LESS)}
                            {...testID(TestIdentifiers?.showLess)}
                        >
                            {(!!props?.auditData?.aggregate &&
                                (props?.auditData?.aggregate > props?.totalTransactionsFetchedCount)) ?
                                '• Show less'
                                : 'Show less'
                            }
                        </Text>
                    }
                </HStack>
            }

            {!props?.auditData?.loadingAuditData?.initialAuditDataLoading && props?.auditData?.loadingAuditData?.paginationLoading && <Spinner />}
        </>

    )

    return (
        !props?.auditData?.loadingAuditData?.paginationLoading &&
            !props?.auditData?.loadingAuditData?.initialAuditDataLoading &&
            props?.auditData?.logData?.length === 0 ?
            showNoData
            :
            renderLogData
    );
};

export default AuditRender;
