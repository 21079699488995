import {StyleSheet} from 'react-native';
import {Colors} from '../../../../../styles';

export const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.Custom.BackgroundColor,
    minWidth: 600,
    // maxHeight: 600,
  },
  footerStyle: {
    backgroundColor: Colors.Custom.BackgroundColor,
  },
  uploadBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    // borderColor: 'black',
    // borderWidth: 1,
    // borderStyle: 'dashed',
    // padding: 70,
    flexDirection: 'row',
  },
  previewContainerView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  btnGroupStyle: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },

  submitBtnStyle: {
    height: 40,
    paddingHorizontal: 16,
    paddingVertical: 10,
    borderRadius: 20,
    borderWidth: 0,
    minWidth: 64,
  },
});
