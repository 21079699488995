import React from 'react';
import { Colors } from '../../../../styles';
import { ICommonSvgProps } from '../interfaces';

const MessageReplySvg = (props: ICommonSvgProps) => {
    const { isSelected } = props;
    const strokeColor = isSelected
        ? Colors.primary['500']
        : props.isPrimary
            ? Colors.primary['300']
            : props.defaultColor || '#fff';
    return (
        <svg width="inherit" height="inherit" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.66683 12.6693H5.3335C4.22893 12.6693 3.3335 11.7738 3.3335 10.6693V9.33594M9.3335 3.33594H10.6668C11.7714 3.33594 12.6668 4.23137 12.6668 5.33594V6.66927" stroke={strokeColor} stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    );
};

export default React.memo(MessageReplySvg);
