import React from "react";
import { ICommonSvgProps } from "../interfaces";

const PatientGroup =(props: ICommonSvgProps)=> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props?.width || "16"} 
      height={props?.height || "16"} 
      viewBox="0 0 16 16" 
      fill="none"
    >
      <g clip-path="url(#clip0_3955_99706)">
          <path
              d="M10.6925 2.0026C11.7848 2.0026 12.6832 2.9226 12.6832 4.05594C12.6832 5.18927 11.7912 6.1026 10.6925 6.1026M13.756 11.8293C14.6673 11.4159 14.6673 10.7226 14.6673 9.9426C14.6673 8.79594 13.2131 7.83594 11.313 7.6626M9.08968 4.0026C9.08968 5.47594 7.93279 6.66927 6.50445 6.66927C5.07611 6.66927 3.91922 5.47594 3.91922 4.0026C3.91922 2.52927 5.07611 1.33594 6.50445 1.33594C7.93279 1.33594 9.08968 2.52927 9.08968 4.0026ZM11.6749 11.6693C11.6749 13.3293 11.6749 14.6693 6.50445 14.6693C1.33398 14.6693 1.33398 13.3293 1.33398 11.6693C1.33398 10.0093 3.64777 8.66927 6.50445 8.66927C9.36113 8.66927 11.6749 10.0093 11.6749 11.6693Z"
              stroke="#8A94A8" stroke-linecap="round" stroke-linejoin="round"
          />
      </g>
      <defs>
          <clipPath id="clip0_3955_99706">
              <rect width="16" height="16" fill="white" />
          </clipPath>
      </defs>
    </svg>
  );
}

export default PatientGroup;
