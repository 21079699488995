import React from 'react';
import {isWeb} from '../../../utils/platformCheckUtils';
import {Image} from 'native-base';

const MailCircleSvg = () => {
  return (
    <>
      {isWeb() ? (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.99476 9.93703L17.9132 5.04348C17.702 4.44078 17.1069 4 16.3967 4H3.60244C2.89218 4 2.2971 4.44078 2.08594 5.04348L10.0044 9.93703H9.99476Z"
            fill="#D0D5DD"
          />
          <path
            d="M10.3887 11.3133C10.2639 11.3853 10.1296 11.4213 9.9952 11.4213C9.86083 11.4213 9.71686 11.3853 9.60168 11.3133L2 6.61768V14.4977C2 15.3253 2.71986 16 3.60288 16H16.3971C17.2801 16 18 15.3253 18 14.4977V6.61768L10.3983 11.3133H10.3887Z"
            fill="#D0D5DD"
          />
        </svg>
      ) : (
        <Image
          size={'4'}
          source={require('../../../assets/images/MessageActionImages/mailBgIcon.png')}
          alt="aa"
        />
      )}
    </>
  );
};

export default MailCircleSvg;
