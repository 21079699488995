import {IKeyOperation} from '../../../../../../../Interfaces';
import { IBarrier } from '../interfaces';
import { BarrierFields } from '../../CustomComponentUtils';
import { FHIR_RESOURCE } from '../../../../../../../constants/FhirConstant';

export const isInvalid = (
  field: BarrierFields,
  item: IBarrier,
  submitTriggered: boolean,
  fields?: IKeyOperation
) => {
  if (field !== BarrierFields.title) {
    return false;
  }
  switch (field) {
    case BarrierFields.title:
      if (
        (fields?.title?.isRequired || false) &&
        submitTriggered
      ) {
        return !item?.title;
      }
      return false;
    default:
      return false;
  }
};
