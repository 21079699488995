import {Image} from 'native-base';
import React from 'react';
import {isWeb} from '../../../utils/platformCheckUtils';
import {ICommonSvgProps} from './interfaces';
import Svg, { Path } from 'react-native-svg';

const ReplySvg = (props: ICommonSvgProps) => {
  const strokeColor = props?.customStrokeColor || '#667085';
  return (
    <>
      <Svg
        width={props?.width || "16"}
        height={props?.height || "16"}
        viewBox="0 0 16 16"
        fill="none"
      >
        <Path
          d="M3 7.99996L6.33333 11.3333M3 7.99996L6.33333 4.66663M3 7.99996L9.66667 7.99996C10.7778 7.99996 13 7.33329 13 4.66663"
          stroke={strokeColor}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </Svg>
    </>
  );
};

export default React.memo(ReplySvg);