import React from 'react';
import {Path, Svg} from 'react-native-svg';

function JourneyIcon() {
  return (
    <Svg width="16" height="17" fill="none" viewBox="0 0 16 17">
      <Path
        stroke="#667085"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M11.092 10.49l-.024 1.842a.77.77 0 01-.23.551l-2.17 2.169a.393.393 0 01-.66-.194l-.49-2.096m-1.515-7.36l-1.841.024a.77.77 0 00-.551.23L1.448 7.82a.393.393 0 00.194.66l2.096.491m-1.2 3.483l-.92.915m2.423.588l-.915.92m-.042-4.688s2.629-5.265 5.149-7.185c2.514-1.914 6.16-.903 6.16-.903s1.018 3.641-.902 6.161c-1.92 2.52-7.185 5.15-7.185 5.15l-3.222-3.223z"
      ></Path>
    </Svg>
  );
}

export default JourneyIcon;
