import {
  Path,
  Svg,
  Circle,
  Defs,
  RadialGradient,
  LinearGradient,
  Stop,
} from 'react-native-svg';
import React from 'react';
import {ICommonSvgProps} from './interfaces';

export const EmptyStateIconSvg = (props: ICommonSvgProps) => {
  const {size} = props;
  return (
    <Svg
      width="102"
      height="101"
      viewBox="0 0 102 101"
      fill="none"
    >
      <Circle
        cx="51.0001"
        cy="50.4996"
        r="50"
        stroke="url(#paint0_radial_46_8710)"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1.46 1.46"
      />
      <Circle
        cx="51.0004"
        cy="50.4999"
        r="42.6667"
        stroke="url(#paint1_radial_46_8710)"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1.46 1.46"
      />
      <Path
        d="M15.8545 50.6014C15.8545 31.2715 31.5245 15.6014 50.8545 15.6014C70.1845 15.6014 85.8545 31.2715 85.8545 50.6014C85.8545 69.9314 70.1845 85.6014 50.8545 85.6014C31.5245 85.6014 15.8545 69.9314 15.8545 50.6014Z"
        fill="url(#paint2_linear_46_8710)"
      />      
      <Defs>
        <RadialGradient
          id="paint0_radial_46_8710"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(51.0002 73.1078) rotate(-90.639) scale(78.4672)"
        >
          <Stop stop-color="#A1AABA" />
          <Stop offset="1" stopColor="#E4E7EC" />
        </RadialGradient>
        <RadialGradient
          id="paint1_radial_46_8710"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(51.0005 69.7922) rotate(-90.639) scale(66.9587)"
        >
          <Stop stop-color="#A1AABA" />
          <Stop offset="1" stopColor="#E4E7EC" />
        </RadialGradient>
        <LinearGradient
          id="paint2_linear_46_8710"
          x1="26.0002"
          y1="23.4999"
          x2="74.0002"
          y2="84.2498"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#F9FAFC" />
          <Stop offset="1" stopColor="#F1F2F3" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
};

export default EmptyStateIconSvg;
