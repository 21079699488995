import {StyleSheet} from 'react-native';
import {Colors} from '../../../styles';
export const styles = StyleSheet.create({
  backButtonStyle: {
    height: 40,
    width: 40,
    borderRadius: 30,
    marginLeft: -11,
  },
  titleStyle: {
    // fontSize: 18,
    // fontWeight: '500',
    lineHeight: 28,
    // marginBottom: 4,
    color: Colors.primary['400'],
  },
  secondTitle: {
    lineHeight: 28,
    // marginBottom: 4,
  },
  subTitleStyle: {
    //   fontWeight: '500',
    //   fontSize: 14,
    lineHeight: 20,
    color: Colors.Custom.Gray500,
    marginTop: 10,
  },
  singleStyle: {
    marginBottom: 4,
    marginHorizontal: 4,
  },
  titleSubtiteWrapperStyle: {
    marginHorizontal: 16,
    marginTop: 16,
  },
});
