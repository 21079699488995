import { useQuery } from '@apollo/client';
import { Select as AntSelect, Spin } from 'antd';
import { FormControl, Text, View } from 'native-base';
import React, { useContext, useEffect, useState } from 'react';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../constants/Configs';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import TaskPoolQueries from '../../../../services/TaskPool/TaskPoolQueries';
import { getMlovId } from '../../../../utils/mlovUtils';
import { IInputElement } from '../FlowComponent/StateNodes/FlowNodeInterface';
import { CaretDownOutlined, DownOutlined } from '@ant-design/icons';
import { Colors } from '../../../../styles';
import { reactStyles } from '../workflowStyles';


export const TaskPoolSearchField = (props: IInputElement) => {
  const [ componentState, setComponentState ] = useState<{
    selectedUserId?: string,
    selectedTaskPoolId?: string,
    accountTaskPools?: any[],
    currentTaskPools?: any[],
  }>({
    selectedTaskPoolId: props.value?.taskPoolId,
  });

  const mlovData = useContext(CommonDataContext);
  const taskPoolTypeId = getMlovId(mlovData.CARE_STUDIO_MLOV, 'UserPoolType', 'task_user_pool');

  const { loading: isGetTaskPoolAPILoading } = useQuery(TaskPoolQueries.GetTaskPool, {
    variables: {
      params: {
        userPoolTypeId: taskPoolTypeId,
      },
    },
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setComponentState((prev) => {
        return {
          ...prev,
          accountTaskPools: data?.getUserPools?.userPools,
        };
      });
    },
    onError: (error) => {
    },
  });

  function isFormValid(selectedTaskPoolId?: string) {
    if (!selectedTaskPoolId) return false;

    return true;
  }

  function onChange(selectedTaskPoolId?: string) {
    if (!isFormValid(selectedTaskPoolId)) {
      return props.onChange(null, '');
    }

    const value = {
      taskPoolId: selectedTaskPoolId,
    };

    return props.onChange(value, '');
  }

  useEffect(() => {
    if (isGetTaskPoolAPILoading || !componentState.accountTaskPools?.length || !props.userInputFieldList?.length ) {
      return;
    }

    let assigneeUserField = props.userInputFieldList.find((userInputField) => {
      return (userInputField?.key === 'assigneeUser')
    });

    if (!assigneeUserField) {
      let assigneeUserFieldNew: any = props.userInputFieldList.find(
        (userInputField) => {
          return (
            userInputField?.key === 'sendEmailTo' &&
            (userInputField?.value?.condition == 'SELECT_MEMBER_OR_USER' || userInputField?.value?.condition == 'SELECT_USER') &&
            userInputField?.value?.member?.type === 'Staff'
          );
        }
      );
      if (assigneeUserFieldNew) {
        assigneeUserFieldNew = {
          value: {
            key: assigneeUserFieldNew?.value?.member?.value,
          },
        };

        assigneeUserField = assigneeUserFieldNew;
      }
    }

    if (!assigneeUserField?.value?.key) {
      if (!componentState.selectedUserId) {
        return;
      }
      props.onChange(undefined, null);
      return setComponentState((prev) => ({
        ...prev,
        selectedUserId: undefined,
        selectedTaskPoolId: undefined,
        currentTaskPools: [],
      }));
    } else {
      const selectedUserId = assigneeUserField.value.key;
      if (selectedUserId === componentState.selectedUserId) {
        return;
      }
      const currentTaskPools = componentState.accountTaskPools?.filter((taskPool) => {
        const isSelectedUserPresentInUserPool = taskPool?.userPoolUsers?.find((userPoolUser: any) => {
          return (userPoolUser?.userId === selectedUserId)
        });

        if (isSelectedUserPresentInUserPool) {
          return true;
        }
      });

      const isSelectedTaskPoolPresent = currentTaskPools?.find((taskPool) => {
        return (taskPool.id === componentState.selectedTaskPoolId)
      });
      if (!isSelectedTaskPoolPresent) {
        props.onChange(undefined, null);
      }
      return setComponentState((prev) => ({
        ...prev,
        selectedUserId: selectedUserId,
        selectedTaskPoolId: isSelectedTaskPoolPresent ? componentState.selectedTaskPoolId : undefined,
        currentTaskPools: currentTaskPools,
      }));
    }
  }, [ props.userInputFieldList, isGetTaskPoolAPILoading, componentState.accountTaskPools ]);

  return (
    <View>
      <AntSelect
        suffixIcon={ props.isAutomationView?
          <CaretDownOutlined
            style={reactStyles.colorGray300}
          /> :
          <DownOutlined/>
        }
        size="large"
        showSearch={false}
        allowClear
        filterOption={false}
        value={componentState.selectedTaskPoolId ? [ componentState.selectedTaskPoolId ] : []}
        onChange={(value: any[], data: any) => {
          setComponentState((prev) => ({
            ...prev,
            selectedTaskPoolId: data?.value || undefined,
          }));
          onChange(data?.value || undefined);
        }}
        placeholder="Select task pool"
        loading={isGetTaskPoolAPILoading}
        notFoundContent={isGetTaskPoolAPILoading && <Spin size="small" />}
        style={reactStyles.height40}
      >
        {componentState.currentTaskPools?.map((item, index) => {
          return (
            <AntSelect.Option key={item.id} value={item.id}>
              <Text>{item.name}</Text>
            </AntSelect.Option>
          );
        })}
      </AntSelect>
    </View>
  );
};
