import { DATE_FORMATS } from '../../../../../../constants';
import { getDateStrFromFormat } from '../../../../../../utils/DateUtils';
import {IMedicationStatement} from '../Medications/interfaces';
import {MedicationStatus, MedicationViewType} from './interface';

export const getMedicationUniqueId = (medication: IMedicationStatement) => {
  return  medication.id || medication.uniqueId;
};

export const updateViewTypeByUniqueId = (
  medications: IMedicationStatement[],
  medication: IMedicationStatement,
  viewType: MedicationViewType
) => {
  return medications.map((medicationObj) => {
    if (
      getMedicationUniqueId(medicationObj) &&
      getMedicationUniqueId(medicationObj) === getMedicationUniqueId(medication)
    ) {
      medication.viewType = viewType;
      return medication;
    }
    return medicationObj;
  });
};

export const removeFromEditView = (
  medications: IMedicationStatement[],
  medication: IMedicationStatement
) => {
  return medications
    .filter((filterMedication) => {
      if (
        medication.isFirstEdit &&
        getMedicationUniqueId(medication) &&
        getMedicationUniqueId(medication) ===
          getMedicationUniqueId(filterMedication)
      ) {
        return false;
      }
      return true;
    })
    .map((medicationObj) => {
      if (
        getMedicationUniqueId(medicationObj) &&
        getMedicationUniqueId(medicationObj) ===
          getMedicationUniqueId(medication)
      ) {
        medication.viewType = MedicationViewType.list;
        return medication;
      }
      return medicationObj;
    });
};

export const getIndexByUniqueId = (
  medications: IMedicationStatement[],
  medication: IMedicationStatement
) => {
  return medications?.findIndex(
    (medicationObj) =>
      getMedicationUniqueId(medicationObj) &&
      getMedicationUniqueId(medicationObj) === getMedicationUniqueId(medication)
  );
};

export const getMedicationStatusText = (medicationStatus: string) => {
  switch (medicationStatus) {
    case MedicationStatus.willContinue:
      return 'Will Continue';
    case MedicationStatus.willStop:
      return 'Will Stop';
  }
};

export const isMedicationEditing = (medications: IMedicationStatement[]) => {
  return medications?.some(
    (medication) => medication.viewType === MedicationViewType.edit
  );
};

export const getStartAndEndDateText = (medication: IMedicationStatement) => {
  const startDate = medication.effectivePeriod
    ? getDateStrFromFormat(
        medication.effectivePeriod,
        DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT
      )
    : '';

  const endDate = medication.stopDate
    ? getDateStrFromFormat(
        medication.stopDate,
        DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT
      )
    : '';

  let startTimeEndTimeText = '';

  if (startDate) {
    startTimeEndTimeText = `Starts ${startDate} `;
  }

  if (endDate) {
    startTimeEndTimeText += ` • `;
  }

  if (endDate) {
    startTimeEndTimeText += `Till ${endDate}`;
  }

  return startTimeEndTimeText;
};

export const getMedicationStatusTextForAudit = (medicationStatus: string) => {
  switch (medicationStatus) {
    case MedicationStatus.willContinue:
      return 'Continue';
    case MedicationStatus.willStop:
      return 'Stopped';
  }
};
