import {View, Text} from 'react-native';
import React from 'react';
import {ICommonSvgProps} from './interfaces';
import {isWeb} from '../../../utils/platformCheckUtils';
import {Image} from 'native-base';
import {ClipPath, Defs, G, Path, Rect, Svg} from 'react-native-svg';

const EmailInternalSvg = (props: ICommonSvgProps) => {
  const height = props.height || '16';
  const width = props.width || '16';
  const strokeColor = props.customStrokeColor || '#6F7A90';

  return isWeb() ? (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1412_10748)">
        <path
          d="M3.99992 6.66659V5.33325C3.99992 3.12411 5.79078 1.33325 7.99992 1.33325C10.2091 1.33325 11.9999 3.12411 11.9999 5.33325V6.66659M5.33325 14.6666H10.6666C12.5522 14.6666 13.495 14.6666 14.0808 14.0808C14.6666 13.495 14.6666 12.5522 14.6666 10.6666C14.6666 8.78097 14.6666 7.83816 14.0808 7.25237C13.495 6.66659 12.5522 6.66659 10.6666 6.66659H5.33325C3.44763 6.66659 2.50482 6.66659 1.91904 7.25237C1.33325 7.83816 1.33325 8.78097 1.33325 10.6666C1.33325 12.5522 1.33325 13.495 1.91904 14.0808C2.50482 14.6666 3.44763 14.6666 5.33325 14.6666ZM9.33325 10.6666C9.33325 11.403 8.7363 11.9999 7.99992 11.9999C7.26354 11.9999 6.66659 11.403 6.66659 10.6666C6.66659 9.93021 7.26354 9.33325 7.99992 9.33325C8.7363 9.33325 9.33325 9.93021 9.33325 10.6666Z"
          stroke={strokeColor}
          stroke-linecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1412_10748">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <Svg width={width} height={height} viewBox="0 0 16 16" fill="none">
      <G clip-path="url(#clip0_1412_10748)">
        <Path
          d="M3.99992 6.66659V5.33325C3.99992 3.12411 5.79078 1.33325 7.99992 1.33325C10.2091 1.33325 11.9999 3.12411 11.9999 5.33325V6.66659M5.33325 14.6666H10.6666C12.5522 14.6666 13.495 14.6666 14.0808 14.0808C14.6666 13.495 14.6666 12.5522 14.6666 10.6666C14.6666 8.78097 14.6666 7.83816 14.0808 7.25237C13.495 6.66659 12.5522 6.66659 10.6666 6.66659H5.33325C3.44763 6.66659 2.50482 6.66659 1.91904 7.25237C1.33325 7.83816 1.33325 8.78097 1.33325 10.6666C1.33325 12.5522 1.33325 13.495 1.91904 14.0808C2.50482 14.6666 3.44763 14.6666 5.33325 14.6666ZM9.33325 10.6666C9.33325 11.403 8.7363 11.9999 7.99992 11.9999C7.26354 11.9999 6.66659 11.403 6.66659 10.6666C6.66659 9.93021 7.26354 9.33325 7.99992 9.33325C8.7363 9.33325 9.33325 9.93021 9.33325 10.6666Z"
          stroke={strokeColor}
          strokeLinecap="round"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_1412_10748">
          <Rect width="16" height="16" fill="white" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export default React.memo(EmailInternalSvg);
