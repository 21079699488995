import {StyleSheet} from 'react-native';
export const styles = StyleSheet.create({
  searchBarContainer: {
    borderRadius: 0,
    border: 'none',
  },
  searchIconStyle: {
    fontWeight: 'bold',
    color: '#000000D9',
    fontSize: 14.29,
  },
});
export const stylesObj = {
  searchInputStyle: {
    backgroundColor: '#fafafa',
    borderRadius: 8,
  },
};
