import React from 'react';
import {StyleSheet} from 'react-native';
import {testID, TestIdentifiers} from '../../../../../../testUtils';
import Feather from 'react-native-vector-icons/Feather';
import {Colors} from '../../../../../../styles';

const CancelView = ({onPressCancelBtn}: {onPressCancelBtn: () => void}) => {
  return (
    <div onClick={onPressCancelBtn} {...testID(TestIdentifiers.cancelBtn)}>
      <div className="hstack pressable">
        <Feather name="x" style={styles.cancelBtnIcon} {...testID('image')} />
        <p className="cancel-btn-text" {...testID('Cancel')}>
          Cancel
        </p>
      </div>
    </div>
  );
};

export default CancelView;

const styles = StyleSheet.create({
  cancelBtnIcon: {
    fontWeight: '500',
    fontSize: 18,
    color: Colors.Custom.mainPrimaryPurple,
    marginTop: 2,
  },
});
