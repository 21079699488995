import { StyleSheet } from "react-native";
import { Colors } from "../../../styles/Colors";

export const styles = StyleSheet.create({
  formElement: {
    paddingTop: 10,
    paddingBottom: 12,
  },
  tagIcon: {
    marginRight: 5,
  },
  tagIconText: {
    fontSize: 14,
    fontWeight: '400',
    color: Colors.FoldPixel.GRAY400,
    paddingHorizontal: 8,
  },
});
