const SideCarReferralOrderNoDataSvg = () => {
  return (
    <svg width="201" height="201" viewBox="0 0 201 201" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="100.5" cy="100.499" r="100" stroke="url(#paint0_radial_334_73278)" stroke-width="0.999999" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="2.92 2.92" />
      <circle cx="100.501" cy="100.5" r="85.3333" stroke="url(#paint1_radial_334_73278)" stroke-width="0.999999" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="2.92 2.92" />
      <path d="M30.209 100.703C30.209 62.0429 61.5491 30.7028 100.209 30.7028C138.869 30.7028 170.209 62.0429 170.209 100.703C170.209 139.363 138.869 170.703 100.209 170.703C61.5491 170.703 30.209 139.363 30.209 100.703Z" fill="url(#paint2_linear_334_73278)" />
      <path d="M131.698 103.612C133.929 101.171 133.929 97.431 131.698 94.9902L110.304 71.591C109.321 70.5151 107.528 71.211 107.528 72.6687V83.9279C107.528 85.6922 106.104 87.1127 104.34 87.1689C90.3124 87.6162 67.6029 91.9819 65.5162 128.38C65.4245 129.98 67.5067 130.627 68.4249 129.313C79.3207 113.72 87.8631 111.683 104.328 111.496C106.092 111.476 107.528 112.91 107.528 114.674V125.934C107.528 127.391 109.321 128.087 110.304 127.011L131.698 103.612Z" stroke="#8A94A8" stroke-width="3.19444" stroke-linecap="round" stroke-linejoin="round" />
      <defs>
        <radialGradient id="paint0_radial_334_73278" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(100.501 145.716) rotate(-90.639) scale(156.934)">
          <stop stop-color="#A1AABA" />
          <stop offset="1" stop-color="#E4E7EC" />
        </radialGradient>
        <radialGradient id="paint1_radial_334_73278" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(100.501 139.084) rotate(-90.639) scale(133.917)">
          <stop stop-color="#A1AABA" />
          <stop offset="1" stop-color="#E4E7EC" />
        </radialGradient>
        <linearGradient id="paint2_linear_334_73278" x1="50.5005" y1="46.4997" x2="146.5" y2="168" gradientUnits="userSpaceOnUse">
          <stop stop-color="#F9FAFC" />
          <stop offset="1" stop-color="#F1F2F3" />
        </linearGradient>
      </defs>
    </svg>

  );
};

export default SideCarReferralOrderNoDataSvg;


