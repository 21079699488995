import React from 'react';
import {Path, Svg} from 'react-native-svg';
import { Colors } from '../../../styles/Colors';

const PerformerSvg = () => {
  return (
    <Svg width="16" height="17" viewBox="0 0 16 17" fill="none">
      <Path
        d="M10.6665 4.50001C10.6665 5.97277 9.4726 7.16668 7.99984 7.16668C6.52708 7.16668 5.33317 5.97277 5.33317 4.50001C5.33317 3.02725 6.52708 1.83334 7.99984 1.83334C9.4726 1.83334 10.6665 3.02725 10.6665 4.50001Z"
        stroke={Colors.FoldPixel.GRAY300}
      />
      <Path
        d="M13.3332 12.1667C13.3332 13.8235 13.3332 15.1667 7.99984 15.1667C2.6665 15.1667 2.6665 13.8235 2.6665 12.1667C2.6665 10.5098 5.05432 9.16668 7.99984 9.16668C10.9454 9.16668 13.3332 10.5098 13.3332 12.1667Z"
        stroke={Colors.FoldPixel.GRAY300}
      />
    </Svg>
  );
};

export default PerformerSvg;
