import { FormControl, HStack, Spacer, Text, View, VStack } from 'native-base';
import React, { useEffect, useState } from 'react';
import { Colors } from '../../../../styles';
import { WeekDaySelector } from '../../../common/WeekSelector';
import { IAddOrUpdateAvailability } from './PracticeAvailabilityInterfaces';
import Feather from 'react-native-vector-icons/Feather';
import AddOrUpdateAvailabilityFormLabels from './AddOrUpdateAvailabilityFormLabels';
import { AddOrUpdateAvailability } from './AddOrUpdateAvailability';
import { IAccountLocation } from '../../Contacts/TeamMembers/interfaces';
import { ITimezone } from '../../../../services/Location/interfaces';
import { v4 as uuidV4 } from 'uuid';
import { DATE_FORMATS } from '../../../../constants';
import { convertDate, getCurrentTimeZone, getDateObject, getFormattedDate, getMomentObj, getStartOfDayOfTimezone } from '../../../../utils/DateUtils';
import { DatePicker } from 'antd';
import { VIRTUAL_LOCATION_CODE } from './PracticeAvailability';
import { TimezoneSelect } from '../../../common/TimezoneSelect/TimezoneSelect';
import {processAvailabilities} from './PracticeAvailabilityHelper';

interface IProps {
  cardId: string;
  defaultAvailability: IAddOrUpdateAvailability;
  availabilities: IAddOrUpdateAvailability[];
  disabled?: boolean;
  selectedDaysOfWeek?: number[];
  beginDate?: Date;
  endDate?: Date;
  selectedTimezoneId?: string;
  isOverrideAvailability: boolean;
  timezones: ITimezone[];
  accountLocations: IAccountLocation[];
  defaultTimezone?: ITimezone;
  isFromEditUser?: boolean;
  onUpdateAvailabilities: (availabilities: IAddOrUpdateAvailability[]) => void;

  showErrors?: boolean;
}

interface IComponentState {
  availabilities: IAddOrUpdateAvailability[];
  selectedDaysOfWeek?: number[];
  selectedTimezoneId?: string;
  beginDate?: Date;
  endDate?: Date;
}

export function AddOrUpdateAvailabilitiesCard(props: IProps) {

  const [componentState, setComponentState] = useState<IComponentState>({
    availabilities: props?.isFromEditUser
      ? processAvailabilities(props.availabilities, props.accountLocations)
      : props.availabilities,
    selectedDaysOfWeek: props.selectedDaysOfWeek || [],
    beginDate: props.beginDate,
    endDate: props.endDate,
    selectedTimezoneId: props.selectedTimezoneId,
  });

  function onWeekDaysChange(selectedDays: number[]) {
    const daysOfWeek = JSON.stringify(selectedDays);

    setComponentState((prev) => {
      prev.availabilities.forEach((availability) => {
        availability.daysOfWeek = daysOfWeek;
      });
      props.onUpdateAvailabilities(prev.availabilities);
      return {
        ...prev,
        availabilities: prev.availabilities,
        selectedDaysOfWeek: selectedDays,
      };
    });
  }

  function onBeginAndEndDateChange(beginDate: Date, endDate: Date) {
    setComponentState((prev) => {
      prev.availabilities.forEach((availability) => {
        availability.beginDate = getFormattedDate(beginDate, DATE_FORMATS.AVAILABILITY_DATE_FORMAT);
        availability.endDate = getFormattedDate(endDate, DATE_FORMATS.AVAILABILITY_DATE_FORMAT);
      });
      props.onUpdateAvailabilities(prev.availabilities);
      return {
        ...prev,
        availabilities: prev.availabilities,
        beginDate: beginDate,
        endDate: endDate,
      };
    });
  }

  function onTimezoneChange(timezone?: ITimezone) {
    setComponentState((prev) => {
      prev.availabilities.forEach((availability) => {
        availability.timezoneId = timezone?.uuid;
      });
      props.onUpdateAvailabilities(prev.availabilities);
      return {
        ...prev,
        availabilities: prev.availabilities,
        selectedTimezoneId: timezone?.uuid,
      };
    });
  }

  function onTimeChange(availability: IAddOrUpdateAvailability, startTime: string, endTime: string) {
    setComponentState((prev) => {
      prev.availabilities.find((prevAvailability) => {
        if (prevAvailability.localId === availability.localId) {
          if(startTime){
            prevAvailability.startTime = convertDate(startTime, DATE_FORMATS.DISPLAY_TIME_FORMAT, DATE_FORMATS.API_TIME_FORMAT);
          }
          if(endTime){
            prevAvailability.endTime = convertDate(endTime, DATE_FORMATS.DISPLAY_TIME_FORMAT, DATE_FORMATS.API_TIME_FORMAT);
          }
          props.onUpdateAvailabilities([ prevAvailability ]);
          return true;
        }
      });
      return {
        ...prev,
        availabilities: prev.availabilities,
      };
    });
  }

  function onLocationChange(availability: IAddOrUpdateAvailability, locationId: string) {
    setComponentState((prev) => {
      prev.availabilities.find((prevAvailability) => {
        if (prevAvailability.localId === availability.localId) {
          prevAvailability.locationId = locationId;
          prevAvailability.isVirtualLocation = (locationId === VIRTUAL_LOCATION_CODE);

          props.onUpdateAvailabilities([ prevAvailability ]);
          return true;
        }
      });
      return {
        ...prev,
        availabilities: prev.availabilities,
      };
    });
  }

  // function onTimezoneChange(availability: IAddOrUpdateAvailability, timezoneId?: string) {
  //   setComponentState((prev) => {
  //     prev.availabilities.find((prevAvailability) => {
  //       if (prevAvailability.localId === availability.localId) {
  //         prevAvailability.timezoneId = timezoneId;

  //         props.onUpdateAvailabilities([ prevAvailability ]);
  //         return true;
  //       }
  //     });
  //     return {
  //       ...prev,
  //       availabilities: prev.availabilities,
  //     };
  //   });
  // }

  function onDeleteAvailability(availability: IAddOrUpdateAvailability) {
    setComponentState((prev) => {
      prev.availabilities.find((prevAvailability) => {
        if (prevAvailability.localId === availability.localId) {
          prevAvailability.isDeleted = true;

          props.onUpdateAvailabilities([ prevAvailability ]);
          return true;
        }
      });

      const availabilities = prev.availabilities.filter((availability) => {
        return !availability.isDeleted;
      });

      return {
        ...prev,
        availabilities: availabilities,
      };
    });
  }

  function onDeleteCard() {
    setComponentState((prev) => {
      prev.availabilities.forEach((prevAvailability) => {
        prevAvailability.isDeleted = true;
      });

      props.onUpdateAvailabilities(prev.availabilities);

      return {
        ...prev,
        availabilities: [],
      };
    });
  }

  function onAddNewAvailability() {
    setComponentState((prev) => {
      const defaultAvailability: IAddOrUpdateAvailability = {
        ...props.defaultAvailability,
        localId: uuidV4(),
        daysOfWeek: JSON.stringify(prev.selectedDaysOfWeek),
        timezoneId: prev.selectedTimezoneId,
      };
      if (props.isOverrideAvailability) {
        defaultAvailability.isOverrideAvailability = true;
        if (!prev.beginDate || !prev.endDate) {
          return { ...prev };
        }
        defaultAvailability.beginDate = getFormattedDate(prev.beginDate, DATE_FORMATS.AVAILABILITY_DATE_FORMAT);
        defaultAvailability.endDate = getFormattedDate(prev.endDate, DATE_FORMATS.AVAILABILITY_DATE_FORMAT);
      }
      prev.availabilities.push(defaultAvailability);
      props.onUpdateAvailabilities([ defaultAvailability ]);
      return { ...prev };
    });
  }

  if (!componentState.availabilities?.length) {
    return <></>;
  }

  return (
    <VStack
      key={props.cardId}
      borderWidth={1}
      borderColor={props.isOverrideAvailability ? Colors.primary[200] : Colors.Custom.Gray300}
      my={2}
      borderRadius={8}
    >
      <HStack
        padding={4}
        borderBottomWidth={1}
        borderColor={props.isOverrideAvailability ? Colors.primary[200] : Colors.Custom.Gray300}
        alignItems={'center'}
        height={'65px'}
        borderTopRadius={8}
        backgroundColor={props.isOverrideAvailability ? Colors.primary[100] : 'white'}
        space={2}
      >
        {
          props.isOverrideAvailability &&
          <Text fontWeight={'bold'} fontSize={'sm'}>
            {'Schedule Override'}
          </Text>
        }

        {
          !props.isOverrideAvailability &&
          <VStack>
            <WeekDaySelector
              values={componentState.selectedDaysOfWeek}
              onChange={onWeekDaysChange}
            />
            {
              props.showErrors &&
              !componentState.selectedDaysOfWeek?.length &&
              <View>
                <Text fontSize="xs" color="error.500">
                  Please select available days
                </Text>
              </View>
            }
          </VStack>
        }

        <Spacer />

        <VStack space={1}>
          <HStack alignItems={'center'}>
            <View>
              <Text>{'Timezone: '}</Text>
            </View>
            <View width={'200px'}>
              <TimezoneSelect
                showLabel={false}
                showErrors={props.showErrors}
                memorizeTimezone={true}
                selectedTimezoneId={componentState.selectedTimezoneId}
                onChange={(timezone?: ITimezone) => {
                  return onTimezoneChange(timezone);
                }}
              />
            </View>
          </HStack>

          {
            props.showErrors &&
            !componentState.selectedTimezoneId &&
            <View>
              <Text fontSize="xs" color="error.500">
                Please select timezone
              </Text>
            </View>
          }
      </VStack>

        <Feather
          onPress={onAddNewAvailability}
          name="plus"
          color={Colors.primary[400]}
          size={22}
          style={{marginHorizontal: 4}}
        />
        <Feather
          onPress={onDeleteCard}
          name="trash-2"
          size={20}
          color={Colors.primary[400]}
        />
      </HStack>

      <VStack p={2} space={1}>
        {
          props.isOverrideAvailability &&
          <VStack space={2} marginBottom={2}>
            <HStack style={{ width: '60%' }} space={2} >
              <Text flex={1}>From Date</Text>
              <Text flex={1}>To Date</Text>
            </HStack>
            <FormControl
              isRequired={true}
              isInvalid={props.showErrors && (!componentState.beginDate || !componentState.endDate)}
            >
              <DatePicker.RangePicker
                showTime={false}
                placeholder={['Select start date', 'Select end date']}
                minuteStep={5}
                allowClear={false}
                value={[ getMomentObj(componentState.beginDate as Date), getMomentObj(componentState.endDate as Date) ]}
                disabledDate={(() => {
                  const currentDate = getStartOfDayOfTimezone(new Date(), getCurrentTimeZone());
                  return (date) => {
                    return date && date < currentDate;
                  }
                })()}
                style={{
                  height: 44,
                  width: '60%',
                  borderRadius: 8,
                }}
                format={`${DATE_FORMATS.DISPLAY_DATE_FORMAT}`}
                onChange={(value, dateString) => {
                  if (!value?.length) {
                    return
                  }
                  onBeginAndEndDateChange(getDateObject(value[0]), getDateObject(value[1]));
                }}
              />
              {props.showErrors && (!componentState.beginDate || !componentState.endDate) && (
                <FormControl.ErrorMessage
                  _text={{
                    fontSize: 'xs',
                    color: 'error.500',
                    fontWeight: 500,
                  }}
                >
                  {'Please select start and end date'}
                </FormControl.ErrorMessage>
              )}
            </FormControl>
          </VStack>
        }
        <AddOrUpdateAvailabilityFormLabels />
        {
          componentState.availabilities?.map((availability) => {
            if (availability.isDeleted) {
              return <></>
            }
            return (
              <View mb={3}>
                <AddOrUpdateAvailability
                  key={availability.localId}
                  disabled={props?.disabled}
                  availability={availability}
                  accountLocations={props.accountLocations}
                  defaultTimezone={props.defaultTimezone}
                  timezones={props.timezones}
                  hideWeekDaySelector={true}
                  hideTimezoneSelector={true}
                  showErrors={props.showErrors}

                  onTimeChange={onTimeChange}
                  onLocationChange={onLocationChange}
                  onDelete={onDeleteAvailability}
                />
              </View>
            );
          })
        }
      </VStack>
    </VStack>
  );
}
