import {HStack, View, Text, Pressable} from 'native-base';
import React from 'react';
import {Colors} from '../../../../styles/Colors';
import { isWeb } from '../../../../utils/platformCheckUtils';

interface IDialpad{
  onPress: (button?: string)=> void;
  showPhoneTreePad?: boolean
}

const Dialpad = (props:IDialpad) => {
  const DialpadRows = [
    ['1', '2', '3'],
    ['4', '5', '6'],
    ['7', '8', '9'],
    (props.showPhoneTreePad === true ? ['*', '0', '#'] : ['0']),
  ];
  const digitToLettersMap:any = {
    '1': ' ',
    '2': 'ABC',
    '3': 'DEF',
    '4': 'GHI',
    '5': 'JKL',
    '6': 'MNO',
    '7': 'PQRS',
    '8': 'TUV',
    '9': 'WXYZ',
    '0': '+',
    '*': '',
    '#': ' ',
  };

  const onPressButton = (button: string) => {
    props?.onPress(button);
  };
  return (
    <View alignItems={'center'} pt={5} width={'100%'}>
      {DialpadRows.map((row) => {
        return (
          <HStack>
            {row.map((button, index) => {
              return (
                <Pressable
                  key={'dialpad_key_'+index}
                  mx={2}
                  my={1}
                  alignItems={'center'}
                  justifyContent={'center'}
                  backgroundColor={isWeb() ? Colors.Custom.Gray100 : '#E5E5E5'}
                  fontFamily={'Manrope'}
                  fontWeight={'400'}
                  lineHeight={36}
                  style={{
                    width: isWeb() ? 56 : 78,
                    height: isWeb() ? 56 : 78,
                  }}
                  _hover={{
                    backgroundColor: Colors.Custom.mainPrimaryPurple,
                    color: 'white',
                  }}
                  borderRadius={'full'}
                  onPress={() => onPressButton(button)}
                >
                  <Text fontSize={isWeb() ? 26: 34} height={isWeb() ? 30 : 10}>
                    {button}
                  </Text>
                  <Text fontSize={isWeb() ? 8 : 10}>
                    {digitToLettersMap[button]}
                  </Text>
                  {/* {isWeb() ? (button) :( <Text fontSize={37}>{button}</Text>)} */}
                </Pressable>
              );
            })}
          </HStack>
        );
      })}
    </View>
  );
};

export default Dialpad;
