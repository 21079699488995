import { useLazyQuery } from '@apollo/client';
import { Box, useToast } from 'native-base';
import { useContext, useEffect, useState } from 'react';
import { ToastType, showToast } from '../../../../../../../../utils/commonViewUtils';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../../../../constants/Configs';
import { CommonDataContext } from '../../../../../../../../context/CommonDataContext';
import { MLOV_CATEGORY } from '../../../../../../../../constants';
import { getMlovListFromCategory, getMlovObjectFromCode } from '../../../../../../../../utils/mlovUtils';
import { IAuditState, IInitialBatchLogsCountData, IMappedAccountUsers } from '../../../../../../Audit/AuditInterfaces';
import { APPOINTMENT_AUDIT_LOG_PAGE_LIMIT } from './AppointmentAuditConstants';
import { ACTION_CODE, APPOINTMENT_CORE_FIELDS, APPOINTMENT_RESOURCE_TYPE_CODES, AUDIT_ACTION_CODES, FIELDS_OF_APPOINTMENT } from '../../../../../../Audit/AuditHelper';
import { GET_ALL_USERS_BY_IDS } from '../../../../../../../../services/User/UserQueries';
import useGetBatchedAccountUsers from '../../../../../../../CustomHooks/useGetBatchedAccountUsers';
import { GET_APPOINTMENT_LOGS } from '../../../../../../../../services/Appointment/AppointmentQueries';
import { getContactListByUuids } from '../../../../../../../../services/Contacts/ContactsQueries';
import { processActivityLogs } from '../../../../../../AddTask/Hooks/AuditHookHelper';
import { IAppointmentType } from '../../../../BookAppointment/Interfaces';
import { IAppointmentParticipantTypeIds } from '../../BottomTabs';
import { GET_PATIENT_CARE_JOURNEY_DETAIL_BY_ID, GET_PATIENT_CARE_JOURNEY_DETAIL_BY_JOURNEY_IDS } from '../../../../../../../../services/CareJourney/CareJourneyQueries';
import { GET_ACCOUNT_LOCATION_INCLUDING_DELETED_BY_IDS } from '../../../../../../../../services/Location/UserPracticeLocationQueries';
import { GET_APPOINTMENT_TYPES_INCLUDING_DELETED } from '../../../../../../../../services/ScheduleEvent/ScheduleEventQueries';
import { getEhrList } from '../../../../../../../../services/CommonService/IntegrationService';
import { ehrCodeDisplay } from '../../../../../../../RightSideContainer/Contacts/TeamMembers/AddEditUser/interfaces';

type IParamsAudit = {
    appointmentParticipantTypeIds: IAppointmentParticipantTypeIds;
    appointmentId?: string;
    appointmentTypeList?: IAppointmentType[];
    isGroupSessionAppointment?: boolean;
    refetchData?: boolean;
    onAuditRefetchData?: () => void;
}
const useAppointmentAudit = (params: IParamsAudit) => {
    const [appointmentLogData, setAppointmentLogData] = useState<IAuditState[]>([]);
    const [mappingUuidWithData, setMappingUuidWithData] = useState<{ [key: string]: any }>({})
    const toast = useToast();
    const [paginationState, setPaginationState] = useState(
        {
            paginationLoading: false,
            currentPage: 1,
            pageSize: APPOINTMENT_AUDIT_LOG_PAGE_LIMIT,
            offset: 0,
            total: undefined,
        }
    );
    const [initialBatchLogsCountData, setInitialBatchLogsCountData] = useState<IInitialBatchLogsCountData>({
        logCount: 0,
        transactionIdsCount: 0,
    });
    const [totalTransactionsFetchedCount, setTotalTransactionsFetchedCount] = useState(0);
    const [transactionIdOfCreatedAppointment, setTransactionIdOfCreatedAppointment] = useState('');
    const [sortState, setSortState] = useState({
        dueDate: {
            desc: true
        }
    });
    const [ehrList, setEhrList] = useState<ehrCodeDisplay[]>([]);
    const [ehrLoading, setEhrLoading] = useState(false);

    const commonContextData = useContext(CommonDataContext);
    const appointmentStatusList =
        getMlovListFromCategory(
            commonContextData.CARE_STUDIO_MLOV,
            MLOV_CATEGORY.APPOINTMENT_STATUS
        ) || [];
    const scheduleLocationTypeList =
        getMlovListFromCategory(
            commonContextData.CARE_STUDIO_MLOV,
            MLOV_CATEGORY.SCHEDULE_LOCATION_TYPE
        ) || [];

    const appointmentParticipantStatusList =
        getMlovListFromCategory(
            commonContextData.CARE_STUDIO_MLOV,
            MLOV_CATEGORY.APPOINTMENT_PARTICIPANT_STATUS
        ) || [];

    const mapRequiredData = () => {
        const listsToMap: any = [appointmentStatusList, scheduleLocationTypeList, appointmentParticipantStatusList, params?.appointmentTypeList];

        const mapping: { [key: string]: any } = {}

        listsToMap?.forEach((list: any) => {
            list?.forEach((item: any) => {
                if (item?.id) {
                    mapping[item?.id] = { ...item, displayName: item?.eventName || item?.value }
                }
            });
        });
        setMappingUuidWithData(mapping);
    };

    const accountUsersMappedById: { [key: string]: IMappedAccountUsers } = {};
    const onAuditRefetchData = params?.onAuditRefetchData;
    const intitiateFetchProcess = () => {
        setPaginationState({
            paginationLoading: false,
            currentPage: 1,
            pageSize: APPOINTMENT_AUDIT_LOG_PAGE_LIMIT,
            offset: 0,
            total: undefined,
        })
        setAppointmentLogData([])
        getAppointmentLogData(APPOINTMENT_AUDIT_LOG_PAGE_LIMIT, 0, sortState);
        onAuditRefetchData?.();
    }

    const setEhr = async () => {
        try {
            setEhrLoading(true);
            const ehrResponse = await getEhrList();
            if (ehrResponse?.data?.expansion?.contains?.length > 0) {
                setEhrList(ehrResponse?.data?.expansion?.contains);
            }
            setEhrLoading(false);
        } catch (error) {
            setEhrLoading(false);
        }
    };

    useEffect(() => {
        mapRequiredData();
        intitiateFetchProcess();
        setEhr();
    }, []);

    useEffect(() => {
        if(params?.refetchData) {
            intitiateFetchProcess();
        }
    }, [params?.refetchData])

    const [getAppointmentLog, { loading: appointmentLogQueryLoading }] = useLazyQuery(GET_APPOINTMENT_LOGS, {
        context: { service: CARESTUDIO_APOLLO_CONTEXT },
        fetchPolicy: 'no-cache',
    })

    const [getContactsByUuids,{loading: contactDetailsLoading}] = useLazyQuery(getContactListByUuids, {
        fetchPolicy: 'no-cache',
        onError: error => {
            showToast(toast, 'Error in fetching attachment patient/prospect for logs', ToastType.error, 4000);
        },
    })

    const [searchLocationByIds, {loading: locationDetailsLoading}] = useLazyQuery(GET_ACCOUNT_LOCATION_INCLUDING_DELETED_BY_IDS, {
        fetchPolicy: 'no-cache',
        onError: error => {
            showToast(toast, 'Error in fetching attachment patient/prospect for logs', ToastType.error, 4000);
        },
      });

    const [getCareJourneyDetails, {loading: careJourneyDetailsLoading}] = useLazyQuery(GET_PATIENT_CARE_JOURNEY_DETAIL_BY_JOURNEY_IDS, {
        context: { service: CARESTUDIO_APOLLO_CONTEXT },
        fetchPolicy: 'no-cache',
    })

    const [getAppointmentTypeDetails, { loading: appointmentTypeDetailsLoading }] = useLazyQuery(GET_APPOINTMENT_TYPES_INCLUDING_DELETED, {
        context: { service: CARESTUDIO_APOLLO_CONTEXT },
        fetchPolicy: 'no-cache',
    })

    const getDataKey = (field: FIELDS_OF_APPOINTMENT) => {
        switch (field) {
            case FIELDS_OF_APPOINTMENT.CONTACT_ID:
                return "contacts";
            case FIELDS_OF_APPOINTMENT.ACCOUNT_LOCATION_ID:
                return "accountLocations";
            case FIELDS_OF_APPOINTMENT.PATIENT_CARE_JOURNEY_ID:
                return "patientCareJourneys";
            case FIELDS_OF_APPOINTMENT.APPOINTMENT_TYPE_ID:
                return "appointmentTypes"
            default:
                return "";
        }
    };

    const handleApiResponse = (item: FIELDS_OF_APPOINTMENT, index: any, apiResponses: any) => {
        const dataKey = getDataKey(item);
        apiResponses?.[index]?.data?.[dataKey]?.forEach((dataItem: any) => {
            setMappingUuidWithData(prev => ({
                ...prev,
                [dataItem?.uuid || dataItem?.id]: {
                    ...dataItem,
                    displayName: dataItem?.title || dataItem?.name || dataItem?.practiceLocation?.name || dataItem?.eventName
                }
            }));
        });
    };

    const getDeletedDataOfAppointment = async (
        locationIdsToFetch: string[],
        contactIdsToFetch: string[],
        patientCareJourneyIdstoFetch: string[],
        appointmentTypeIdsToFetch: string[]
    ) => {
        const promiseList: Promise<any>[] = [];
        const promisesSequence: FIELDS_OF_APPOINTMENT[] = [];

        const pushPromise = (apiFunction: any, variablesForQuery: any, field: FIELDS_OF_APPOINTMENT) => {
            const { contactUuidList, accountLocationIds, journeyIds, appointmentTypeIds  } = variablesForQuery;

            if (contactUuidList?.length || accountLocationIds?.length || journeyIds?.length || appointmentTypeIds?.length) {
                promiseList.push(apiFunction({ variables: variablesForQuery }));
                promisesSequence.push(field);
            }
        };

        pushPromise(getContactsByUuids, {
            contactUuidList: contactIdsToFetch,
        }, FIELDS_OF_APPOINTMENT.CONTACT_ID);

        pushPromise(searchLocationByIds, {
            accountLocationIds: locationIdsToFetch,
        }, FIELDS_OF_APPOINTMENT.ACCOUNT_LOCATION_ID);

        pushPromise(getCareJourneyDetails, {
            journeyIds: patientCareJourneyIdstoFetch,
        }, FIELDS_OF_APPOINTMENT.PATIENT_CARE_JOURNEY_ID);

        pushPromise(getAppointmentTypeDetails, {
            appointmentTypeIds: appointmentTypeIdsToFetch,
        }, FIELDS_OF_APPOINTMENT.APPOINTMENT_TYPE_ID)

        const apiResponses = await Promise.all(promiseList);

        promisesSequence.forEach((item, index) => {
            handleApiResponse(item, index, apiResponses);
        });

    }

    const getAppointmentLogData = async (limit: number, offset: number, orderBy: {dueDate: {desc: boolean}}, emptyAppointmentLog?: boolean) => {
        let createdAppointmentTransactionId = ''
        if (params?.appointmentId) {
            const appointmentLogResponse = await getAppointmentLog({
                variables: {
                    resourceId: params?.appointmentId,
                    limit: limit,
                    offset: offset,
                    orderBy: {timeStamp: orderBy?.dueDate?.desc ? 'desc' : 'asc'},
                    excludedActionCode: params?.isGroupSessionAppointment ? ['ACCEPT_APPOINTMENT', 'DECLINE_APPOINTMENT', 'PURPOSED_TIME_APPOINTMENT', 'PENDING_APPOINTMENT'] : []
                }
            });
            const locationIdsToFetch: string[] = [];
            const contactIdsToFetch: string[] = [];
            const patientCareJourneyIdstoFetch: string[] = [];
            const appointmentTypeIdsToFetch: string[] = [];

            const labelIdsToFetch: string[] = [];
            const attachmentIdsToFetch: string[] = [];
            const taskPoolIdsToFetch: string[] = [];
            const commentIdsToFetch: string[] = [];

            const activityLogs = appointmentLogResponse?.data?.getActivityLogs?.activityLogs;
            const transactionIdsFetched: any =[]

            activityLogs?.forEach((log: IAuditState) => {
                transactionIdsFetched.push(log?.transactionId)
                const newData = log?.data?.newData;
                const oldData = log?.data?.oldData;
                const parentResourceOldData = log?.parentResourceData?.oldData;

                if ((log?.resourceTypeCode === APPOINTMENT_RESOURCE_TYPE_CODES.APPOINTMENT) && log?.actionCode === ACTION_CODE.CREATE) {
                    createdAppointmentTransactionId = log?.transactionId;
                    setTransactionIdOfCreatedAppointment(log?.transactionId)
                }

                const pushToFetchArray = (fieldName: string, fetchArray: string[]) => {
                    const pushIfNotMapped = (value: string | undefined | string[]) => {
                        if (!!value && Array.isArray(value)) {
                            value?.forEach((item) => {
                                if (!!item && !mappingUuidWithData?.[item]) {
                                    fetchArray.push(item)
                                }
                            })
                        }
                        else if (!!value && !mappingUuidWithData?.[value]) {
                            fetchArray.push(value);
                        }
                    };
                    let oldDataArray = []
                    if(log.resourceTypeCode === APPOINTMENT_RESOURCE_TYPE_CODES.APPOINTMENT_PARTICIPANT) {
                        oldDataArray = log?.parentResourceData?.oldData?.oldAppointmentParticipants
                    }
                    else oldDataArray = parentResourceOldData;
                    pushIfNotMapped(oldDataArray?.map((data: any) => data?.[fieldName]) || oldData?.[fieldName]);
                    pushIfNotMapped(newData?.[fieldName]);
                };
                if (log?.resourceTypeCode === APPOINTMENT_RESOURCE_TYPE_CODES.APPOINTMENT || log?.resourceTypeCode === APPOINTMENT_RESOURCE_TYPE_CODES.APPOINTMENT_PARTICIPANT) {
                    pushToFetchArray(APPOINTMENT_CORE_FIELDS.ACCOUNT_LOCATION_ID, locationIdsToFetch);
                    pushToFetchArray(APPOINTMENT_CORE_FIELDS.CONTACT_ID, contactIdsToFetch);
                    pushToFetchArray(APPOINTMENT_CORE_FIELDS.APPOINTMENT_TYPE_ID, appointmentTypeIdsToFetch);
                }

            });
            const {restructuredData, patientCareJourneyIds} = processActivityLogs(
                activityLogs,
                [APPOINTMENT_RESOURCE_TYPE_CODES.APPOINTMENT_PARTICIPANT, APPOINTMENT_RESOURCE_TYPE_CODES.APPOINTMENT_TASK],
                {creationCode: APPOINTMENT_RESOURCE_TYPE_CODES.APPOINTMENT, transactionId: createdAppointmentTransactionId || transactionIdOfCreatedAppointment},
                params?.appointmentParticipantTypeIds
            )
            if (patientCareJourneyIds && patientCareJourneyIds?.length > 0) {
                patientCareJourneyIds?.forEach((item) => {
                    if (!mappingUuidWithData?.[item]) {
                        patientCareJourneyIdstoFetch.push(item)
                    }
                })
            }

            const fetchedResponse = await getDeletedDataOfAppointment(
                [...new Set(locationIdsToFetch)],
                [...new Set(contactIdsToFetch)],
                [...new Set(patientCareJourneyIdstoFetch)],
                [...new Set(appointmentTypeIdsToFetch)]
            )

            const uniqueTransactionIdsFetchedCount = Array.from(new Set(transactionIdsFetched))?.length
            if (emptyAppointmentLog) {
                setAppointmentLogData(restructuredData);
                setTotalTransactionsFetchedCount(uniqueTransactionIdsFetchedCount)
            }
            else {
                setAppointmentLogData((prev) => prev.concat(restructuredData))
                setTotalTransactionsFetchedCount((prev) => prev + (uniqueTransactionIdsFetchedCount))
            }

            if (offset === 0) {
                setInitialBatchLogsCountData({
                    logCount: restructuredData?.length,
                    transactionIdsCount: uniqueTransactionIdsFetchedCount
                })
            }
            setPaginationState((prev) => {
                return {
                    ...prev,
                    paginationLoading: false,
                    offset: prev.offset + Array.from(new Set(transactionIdsFetched))?.length,
                    total: appointmentLogResponse?.data?.getActivityLogs?.aggregate?.total // this total is the total number of transactions
                }
            }
            )
        }
    }

    const handleActions = (actionCode: string) => {
        switch (actionCode) {
            case AUDIT_ACTION_CODES.SHOW_MORE:
                getAppointmentLogData(APPOINTMENT_AUDIT_LOG_PAGE_LIMIT, paginationState?.offset, sortState);
                break;
            case AUDIT_ACTION_CODES.SHOW_LESS:
                // When "Show Less" is clicked, it displays the list of elements fetched in the initial log batch.
                // The offset for the next log batch is set to the number of transactions fetched in the initial log
                setPaginationState((prev) => {
                    return {
                        ...prev,
                        offset: initialBatchLogsCountData?.transactionIdsCount,
                    };
                });
                setTotalTransactionsFetchedCount(initialBatchLogsCountData?.transactionIdsCount)
                setAppointmentLogData((prev) => {
                    return prev.slice(0, initialBatchLogsCountData?.logCount);
                });
                break
            case AUDIT_ACTION_CODES.CHANGE_SORT:
                setSortState((prev) => {
                    const newSort = { dueDate: { desc: !prev.dueDate?.desc } };
                    getAppointmentLogData(APPOINTMENT_AUDIT_LOG_PAGE_LIMIT, 0, newSort, true)
                    return newSort;
                });
                setPaginationState((prev) => {
                    return {
                        ...prev,
                        offset: 0,
                    };
                });
                break;
            default:
                break;
        }
    };

    const {
        loading: accountUserLoading,
        error: accountUsersError,
        userList: accountUserList,
    } = useGetBatchedAccountUsers({
        onError: () => { showToast(toast, 'Error in fetching account users', ToastType.error, 4000) },
        usersQueryName: GET_ALL_USERS_BY_IDS
    });

    accountUserList.forEach(user => {
        const { uuid, id, name, email } = user;
        accountUsersMappedById[uuid] = { id, name, email };
    });
    const loadingAuditData = {
        initialAuditDataLoading: paginationState?.offset === 0 && (appointmentLogQueryLoading || accountUserLoading || contactDetailsLoading || locationDetailsLoading || careJourneyDetailsLoading || appointmentTypeDetailsLoading || ehrLoading),
        paginationLoading: paginationState?.offset > 0 && (appointmentLogQueryLoading || accountUserLoading || contactDetailsLoading || locationDetailsLoading || careJourneyDetailsLoading) || appointmentTypeDetailsLoading || ehrLoading,
    }
    return {
        logAuditData: {
            logData: appointmentLogData,
            loadingAuditData: loadingAuditData,
            aggregate: paginationState?.total
        },
        accountUsersMappedById: accountUsersMappedById,
        mappingUuidWithData: mappingUuidWithData,
        handleActions: handleActions,
        offset: paginationState?.offset,
        sortState: sortState,
        totalTransactionsFetchedCount,
        initialBatchLogsCountData,
        isGroupSessionAppointment: params?.isGroupSessionAppointment,
        ehrList: ehrList
    }
}

export default useAppointmentAudit

