import { StyleSheet } from 'react-native';
import { Colors } from "../../../../../styles/Colors";

export const styles = StyleSheet.create({
  container: {
    padding: 8,
    borderRadius: 8,
    borderColor: Colors.Custom.Gray300,
    borderWidth: 0.5,
  },
  zipCodeContainer: {
    padding: 4,
  },
  labelText: {
    color: Colors.Custom.Gray500,
    fontWeight: '400',
    fontSize: 14,
  },
  avatarContainer: {
    alignItems: 'center',
  },
  avatar: {
    alignItems: 'center',
    borderRadius: 6,
    borderWidth: 1,
    marginRight: 8,
  },
  avatarText: {
    fontSize: 14,
    textAlign: 'center',
    padding: 1,
  },
  addressText: {
    fontSize: 12,
    color: Colors.FoldPixel.GRAY250,
  },
  tagIcon: {
    marginRight: 5,
  },
});
