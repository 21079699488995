import React from 'react';
import {isWeb} from '../../../utils/platformCheckUtils';
import {Image} from 'native-base';

const ProblemIconSvg = () => {
  return (
    <>
      {isWeb() ? (
        <svg
        width="24"
        height="22"
        viewBox="0 0 24 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.53855 8.82546C8.53855 5.31705 10.6802 2.30178 13.719 1.0149C12.6669 0.535836 11.5115 0.277519 10.3374 0.268125C8.10646 0.249339 6.00704 1.10413 4.42426 2.67752C2.84148 4.2509 1.9679 6.34562 1.9679 8.58124C1.9679 9.13544 1.8317 9.68495 1.57338 10.1734L0.164381 12.8223C-0.065756 13.2497 -0.051666 13.757 0.197258 14.1797C0.446181 14.5977 0.882972 14.856 1.36673 14.8654H1.96321V16.9883C1.96321 18.2611 2.99648 19.2943 4.26458 19.2943H5.76282V19.9143C5.76282 20.9711 6.62231 21.8306 7.67436 21.8306H14.2497C15.3065 21.8306 16.1613 20.9711 16.1613 19.9143V18.5382C16.1613 18.1108 16.18 17.6881 16.2223 17.2654C11.9202 16.8709 8.53855 13.2356 8.53855 8.82546Z"
          fill="#98A2B3"
        />
        <path
          d="M16.9973 1.81333C13.1366 1.81333 9.99921 4.95541 9.99921 8.82077C9.99921 12.6861 13.1413 15.8282 16.9973 15.8282C20.8532 15.8282 24 12.6908 24 8.82546C24 4.9601 20.8579 1.81333 16.9973 1.81333ZM17.7018 12.193C17.7018 12.5828 17.3871 12.8975 16.9973 12.8975C16.6074 12.8975 16.2928 12.5828 16.2928 12.193V11.62C16.2928 11.2302 16.6074 10.9155 16.9973 10.9155C17.3871 10.9155 17.7018 11.2302 17.7018 11.62V12.193ZM17.7018 9.03681V9.74131C17.7018 10.1311 17.3871 10.4458 16.9973 10.4458C16.6074 10.4458 16.2928 10.1311 16.2928 9.74131V8.81137C16.2928 8.16793 16.8141 7.6466 17.4575 7.6466C17.8427 7.6466 18.1526 7.33192 18.1526 6.95149C18.1526 6.56636 17.8427 6.25638 17.4575 6.25638H16.0626C15.6728 6.25638 15.3581 5.94171 15.3581 5.55188C15.3581 5.16206 15.6728 4.84738 16.0626 4.84738H17.4622C18.6223 4.84738 19.5663 5.79141 19.5663 6.95149C19.5616 8.02703 18.7491 8.9194 17.7018 9.03681Z"
          fill="#98A2B3"
        />
      </svg>
      ) : (
        <Image
          resizeMode="contain"
          size={'24px'}
          source={require('../../../assets/images/Contact/activeProblems.png')}
          alt="image"
        />
      )}
    </>
  );
};

export default ProblemIconSvg;
