import { View } from 'native-base';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  TableTopBar,
  TABLE_TOP_BAR_ACTION_CODES
} from '../../../../common/TableTopBar';
import { styles } from './../Styles';
import './../Styles.css';
import { getWorkflowListTopBarButtons } from './Helper/TopBarButtons';
import WorkflowListView from './WorkflowListView/WorkflowListView';

 const WorkflowMainTableView = (props: any) => {
  const navigate = useNavigate();
  const workflowEditUrl = `create?currentTag=TRIGGER&flowType=${props.flowType}`
  // const [tagList, setTagList] = useState<Array<any>>([]);
  const [searchString, setSearchString] = useState('');
  // const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  // const [popularTags, setPopularTag] = useState("")
  const flowType = props.flowType || '';

  // const getPopularTags = () => {
  //   return getWorkflowTagList("")
  //     .then((res) => res.data)
  //     .then((res) => {
  //       setPopularTag(res)
  //     });
  // };

  const handleSearchChange = (actionData: any) => {
    setSearchString(actionData.searchString);
  };
  const onTableTopBarActionPerformed = (
    actionCode: string,
    actionData: any
  ) => {
    switch (actionCode) {
      case TABLE_TOP_BAR_ACTION_CODES.SEARCH:
        handleSearchChange(actionData);
        break;
    }
  };

  // const handleVisibleChange = () => {
  //   setIsPopoverOpen(!isPopoverOpen)
  // };

  // const getWorkflowTagElement = useMemo(() => {
  //   return (
  //     <VStack width={155} justifyContent="flex-end" marginX={5}>
  //       <WorkFlowTagSearchPopover popularTags={popularTags} isPopoverOpen={isPopoverOpen} handleVisibleChange={handleVisibleChange}
  //         tagList={tagList} setTagList={setTagList} />
  //     </VStack>
  //   )
  // }, [isPopoverOpen]);

  useEffect(() => {
    // getPopularTags()
    setSearchString(props.searchText);
  }, [props.searchText]);

  return (
    <>
      <View style={[styles.shadowBoxWorkflowMainTable,{overflow:'hidden'}]}>
        {props.showTableTopBar && (
          <TableTopBar
            onActionPerformed={(actionCode: string, actionData: any) => {
              onTableTopBarActionPerformed(actionCode, actionData);
            }}
            {...getWorkflowListTopBarButtons([
              (workflowEditUrl) => {
                navigate(workflowEditUrl, {});
              },
            ], workflowEditUrl)}
          />
        )}
        <View>
         <WorkflowListView
          isNewWorkflowView={props.isNewWorkflowView}
            flowType={flowType}
            searchText={searchString}
            onActionPerformed={props.onActionPerformed}
          />
        </View>
      </View>
    </>
  );
};
export default WorkflowMainTableView;
