import {useLazyQuery} from '@apollo/client';
import {Select as AntSelect, Tooltip} from 'antd';
import {
  Divider,
  FormControl,
  HStack,
  Icon,
  Pressable,
  Text,
  View,
} from 'native-base';
import {useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {
  ACCOUNTS_AND_ROLES_ACTION_CONST,
  EXTERNAL_USER_FILTER_CODE
} from '../../../../../constants';
import {FHIR_RESOURCE} from '../../../../../constants/FhirConstant';
import {IMlov} from '../../../../../Interfaces';
import {
  getEhrCapabilitiesWithResource,
  getPractitionersDataByAccount,
} from '../../../../../services/CommonService/AidBoxService';
import {getEhrList} from '../../../../../services/CommonService/IntegrationService';
import EmployerQueries from '../../../../../services/Employer/EmployerQueries';
import UserPracticeLocationQueries from '../../../../../services/Location/UserPracticeLocationQueries';
import {Colors} from '../../../../../styles';
import {
  getEHRName,
  getResourceAbilities,
} from '../../../../../utils/capabilityUtils';
import {getAccountId, getAccountUUID} from '../../../../../utils/commonUtils';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import {ModalActionAntSelect} from '../../../../common/ModalActionCommonComponent/ModalActionAntSelect';
import CardForLocationAndUser from '../AddEditUser/components/CardForLocationAndUser';
import {
  ehrCodeDisplay,
  PractitionerData,
  SingleEmployer,
} from '../InviteUserDrawer/interfaces';
import {styles, antdStyles} from '../InviteUserDrawer/InviteUserDrawerStyles';
import {
  getFinalPractitionerData,
  getFormattedPractitionerData,
} from '../InviteUserDrawer/InviteUserDrawerUtils';
import '../InviteUserDrawer/inviteUserDrawer.css';
import {IAddAccountRoleView} from './interface';
import {getEhrDisplayName} from './utils';
import {USER_ROLE_CODES} from '../../../../../constants/MlovConst';
import {isAccountConfigEnabled} from '../../../../../utils/configUtils';
import {CONFIG_CODES} from '../../../../../constants/AccountConfigConst';

const {Label} = FormControl;

const AddAccountRoleView = (props: IAddAccountRoleView) => {
  const {
    singleAccountRoleObj,
    addUpdateFieldValue,
    accountLocations,
    isDisabled,
    userRoles,
    isEditMode
  } = props;
  const accountUuid = getAccountUUID()
  const intl = useIntl();
  const accountId = getAccountId();
  const [cleared, setCleared] = useState(singleAccountRoleObj.externalUserId ? false : true)
  const fetchUserByType = EXTERNAL_USER_FILTER_CODE.LOCATION
  
  const accountIdHeaders = {
    'account-uuid': accountUuid,
    'x-hasura-account-id': accountId
  }
  const [errors, setErrors] = useState<any>({
    practice: '',
  });
  const [stateData, setStateData] = useState({
    userRoles: userRoles as IMlov[],
    employerList: [] as SingleEmployer[],
    ehrList: [] as ehrCodeDisplay[],
    practitionerData: [] as PractitionerData[],
    loading: false,
    showPractitionerDropdown: false,
    currentCodeEHR: ''
  });

  const [getExternalUserAccountByLocationGroup] = useLazyQuery(
    UserPracticeLocationQueries.GetExternalUserAccountByLocationGroup
  );

  const ehrDisplayName = getEhrDisplayName(stateData.ehrList, stateData.currentCodeEHR);
  const getEhrCapabilitiesWithResourceAndLocation = async (locationId: string): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      getEhrCapabilitiesWithResource(
        FHIR_RESOURCE.PRACTITIONER,
        false,
        (response) => {
          const showPractitionerDropdown = response?.data?.[0]?.abilities?.isEnabled ?? false;
          setStateData(prev=> {
            return {
              ...prev,
              currentCodeEHR: response?.data?.[0]?.ehrName
            }
          })
          resolve(showPractitionerDropdown);
        },
        (error) => {
          setErrors('Something went wrong!!!');
          reject(error);
        },
        { ...accountIdHeaders },
        locationId
      );
    });
  };
  const getEhrUserData =  async () => {
    const showPractitionerDropdown = await getEhrCapabilitiesWithResourceAndLocation(singleAccountRoleObj.locationUuid || '') 
  
    if (
      showPractitionerDropdown &&
      (singleAccountRoleObj.locationUuid)
    ) {
      try {
        setStateData((prev) => {
          return {
            ...prev,
            loading: true,
          };
        });
        const promiseList = [
          getEhrList(singleAccountRoleObj?.locationUuid),
          getPractitionersDataByAccount(
            singleAccountRoleObj?.locationUuid,
              fetchUserByType
          ),
          getExternalUserAccountByLocationGroup({
            variables: {
              accountUuid: accountUuid,
              locationGroupId: singleAccountRoleObj.locationGroupId || ''
            },
          }),
        ];
        const response = await Promise.all(promiseList)
        const ehrList = response?.[0]?.data?.expansion?.contains
        const data = getFormattedPractitionerData(response[1].data);
        const accountUsers = response[2];
        const list = getFinalPractitionerData(data, accountUsers);
        setStateData((prev) => {
          return {
            ...prev,
            ehrList: ehrList,
            practitionerData: list,
            showPractitionerDropdown: true,
          };
        });
        if (isEditMode && singleAccountRoleObj.externalUserId) {
          const externalUser = data.find(
            (item) => item.id == singleAccountRoleObj.externalUserId
          );
          setStateData((prev) => {
            return {
              ...prev,
              practitionerData: externalUser
                ? [externalUser, ...list]
                : [],
              selectedExternalUser:
                externalUser || ({} as PractitionerData),
              isSelectedDisabled: true,
              showPractitionerDropdown: true,
              loading: false,
            };
          });
        }
      } catch (error) {
        setStateData((prev) => {
          return {
            ...prev,
            loading: false,
          };
        });
      }
    }
  };
  const getName = (userData: any) => {
    if (!userData.externalUserId) {
      return ''
    }
    const selectedUser = stateData.practitionerData.find((item) => {
      return item.id === userData.externalUserId;
    });
    return  selectedUser?.displayName ?  `${selectedUser?.displayName} (${ehrDisplayName})` : '';
  };
  useEffect(() => {
    if (singleAccountRoleObj.locationUuid && singleAccountRoleObj.locationGroupId) {
      getEhrUserData();
    }
  }, [
    singleAccountRoleObj.accountUuid,
    singleAccountRoleObj.locationUuid
  ]);
  return (
    <View style={styles.container2}>
      <ModalActionAntSelect
        allowClear={true}
        showSearch={false}
        isRequired={(props?.globalRoles && props?.globalRoles.length > 0) ? false : true}
        label={ 'practiceLocation'}
        placeholder={'Select location'}
        isInvalid={!singleAccountRoleObj?.locationUuid ? singleAccountRoleObj?.locationError : ''}
        errors={!singleAccountRoleObj?.locationUuid ? singleAccountRoleObj?.locationError : ''}
        errorText={!singleAccountRoleObj?.locationUuid ? singleAccountRoleObj?.locationError : ''}
        disabled={singleAccountRoleObj?.editMode}
        onClear={() => {
          addUpdateFieldValue(ACCOUNTS_AND_ROLES_ACTION_CONST.PRACTICE_NAME, {
            locationUuid: '', locationId: 0
          });
        }}
        onChange={(value: any, data: any) => {
    
          const selectedLocation = accountLocations?.find((location)=> {
            return location.uuid === data.value
          })
          addUpdateFieldValue(ACCOUNTS_AND_ROLES_ACTION_CONST.PRACTICE_NAME, {
            locationUuid: data.value,
            locationId: parseInt(data.key),
            locationGroupId: selectedLocation?.locationGroupId
          });
          setErrors((prev: any) => {
            return {
              ...prev,
              practice: '',
            };
          });
        }}
        value={singleAccountRoleObj.locationUuid}
        mode="single"
        data={accountLocations}
        optionProps={{key: 'id', value: 'uuid', label: 'name'}}
        extraStyle={{
          flex: 1,
          fontColor: '#000',
          fontWeight: 400,
          fontSize: 14}}
      />
      {/* /TEMPORARY HIDE WILL ON IN SEPTEMBER RELEASE/ */}
      { false && <ModalActionAntSelect
        marginTop={2}
        value={singleAccountRoleObj.rolesToAdd}
        mode="multiple"
        isRequired={true}
        disabled={isDisabled}
        allowClear={true}
        label={'roles'}
        placeholder="Please select"
        isInvalid={!singleAccountRoleObj?.rolesToAdd?.length ? singleAccountRoleObj?.roleError : ''}
        errors={!singleAccountRoleObj?.rolesToAdd?.length ? singleAccountRoleObj?.roleError : ''}
        errorText={!singleAccountRoleObj?.rolesToAdd?.length ? singleAccountRoleObj?.roleError : ''}
        onClear={() => {
          addUpdateFieldValue(ACCOUNTS_AND_ROLES_ACTION_CONST.ROLES, {
            rolesToAdd: [],
          });
        }}
        onChange={(value: any) => {
          singleAccountRoleObj.rolesToAdd = value;
          addUpdateFieldValue(ACCOUNTS_AND_ROLES_ACTION_CONST.ROLES, {
            rolesToAdd: value,
          });
        }}
        filterOption={(input: string, option: any) => {
          return (option?.children || '')
            ?.toLowerCase()
            ?.includes(input?.toLowerCase());
        }}
        data={stateData?.userRoles}
        optionProps={{key: 'id', value: 'id', label: 'value'}}
        extraStyle={{
          flex: 1,
          fontColor: '#000',
          fontWeight: 400,
          fontSize: 14}}
      />}
      {(stateData.showPractitionerDropdown) ? (
        <FormControl style={[styles.formElement,{marginTop: 4}]}>
          <Label>
            <HStack alignItems={'center'} marginTop={1}>
              <DisplayText
                extraStyles={styles.labelText}
                textLocalId="mapUserToEhr"
              />
              <Tooltip
                title={intl.formatMessage({id: 'mapUserToEhrText'})}
                placement={'top'}
                destroyTooltipOnHide={true}
              >
                <Icon
                  mx={2}
                  as={AntIcon}
                  color={Colors.Custom.Gray500}
                  name="infocirlceo"
                  size="smMedium"
                />
              </Tooltip>
            </HStack>
          </Label>
          <AntSelect
            className="inviteUserDrawerSelect"
            allowClear={singleAccountRoleObj.externalUserId ? true : false}
            disabled={isDisabled}
            placeholder={'Select User'}
            dropdownStyle={antdStyles.dropdownStyle}
            onClear={() => {
              addUpdateFieldValue(ACCOUNTS_AND_ROLES_ACTION_CONST.EHR_USER, {
                externalUserId: null,
                locationGroupId: singleAccountRoleObj.locationGroupId
              });
            }}
            value={
              cleared ? <></> : singleAccountRoleObj?.id === -1
                ? getName(singleAccountRoleObj)
                : getName(singleAccountRoleObj)
            }
            onChange={(value) => {
              if (!value) {
                setCleared(true);
                return;
              }
              value && setCleared(false);
              addUpdateFieldValue(ACCOUNTS_AND_ROLES_ACTION_CONST.EHR_USER, {
                externalUserId: value,
                locationGroupId: singleAccountRoleObj.locationGroupId
              });
            }}
          >
            {stateData.practitionerData.map((item, index) => {
              return (
                <AntSelect.Option
                  key={item.id.toString()}
                  value={item.id.toString()}
                  label={`${item.firstName} ${item.lastName}`}
                  className={'inviteUserDrawer'}
                  style={antdStyles.optionStyle}
                >
                  <CardForLocationAndUser
                    name={item.displayName}
                    uuid={item.id}
                    isSearchComponent={true}
                    ehrName={ehrDisplayName}
                    showAvatar={true}
                  />
                  {index < stateData.practitionerData.length - 1 ? (
                    <Divider marginX={'2'} color={Colors.Custom.Gray200} />
                  ) : null}
                </AntSelect.Option>
              );
            })}
          </AntSelect>
        </FormControl>
      ) : <></>}

      {errors.practitioner && stateData.showPractitionerDropdown && (
        <Text fontSize={'xs'} color={Colors.FoldPixel.STATUS_ERROR} fontWeight={500}>
          {errors.practitioner}
        </Text>
      )}
    </View>
  );
};

export default AddAccountRoleView;
