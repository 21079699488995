import {Divider, Pressable, Stack, Text, View, VStack} from 'native-base';
import React, {useEffect, useState} from 'react';
import {Colors} from '../styles';
import CollapseSvg from './common/Svg/CollapseSvg';
import ExpandSvg from './common/Svg/ExpandSvg';
import {
  getDateStrFromFormat,
} from '../utils/DateUtils';
import { DATE_FORMATS } from '../constants';
import { IContact } from './RightSideContainer/TeamInbox/Conversations/interfaces';
import { IcdConditionsList, IContactScoreReference, IHccDetailsCondition } from './RafScore/interface';

interface IHCCGroupDetails {
  [key: string]: {
    description: string;
    desc_short: string;
    children: string[];
    parents: string[];
  };
}

interface IConditionsAndHCCsProps {
  contactScore: {
    hccV28: number;
    reference: IContactScoreReference;
  }| undefined;
  conditionsAndHccsList: any;
  icdConditionsList: IcdConditionsList[] | undefined;
}

export const ConditionsAndHCCs = (props: IConditionsAndHCCsProps) => {
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
  const [componentState, setComponentState] = useState({
    hccDetailsConditionsArray: [] as IHccDetailsCondition[],
  });
  const hccList = props?.conditionsAndHccsList || [];
  const hccGroupDetails: IHCCGroupDetails | undefined =
    props?.contactScore?.reference?.hccRiskScoreData
      ?.hccV28RiskScoreData?.output?.hcc_group_details;
  const toggleExpand = (index: number) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const getFilteredConditionsdata = (): void => {
    const icdConditionsList = props?.icdConditionsList;
    const hccMap =
      props?.contactScore?.reference?.hccRiskScoreData
        ?.hccV28RiskScoreData?.output?.hcc_map;
    const dxlist =
      props?.contactScore?.reference?.hccRiskScoreData
        ?.hccV28RiskScoreData?.input?.dxlist;
    const removeDecimal = (code: any) => code.replace('.', '');
    const formatHCCCode = (hccCode: any) =>
      hccCode.replace(/HCC(\d+)/, 'HCC $1');
    const mappedData = dxlist?.map((icdCode: any) => {
      const condition = icdConditionsList?.find(
        
        (cond: any) => cond?.code === icdCode
      );
      const formattedReportedDate = getDateStrFromFormat(
        condition?.reportedDate,
        DATE_FORMATS.MONTH_DATE_YEAR
      );
      const icdCodeWithoutDecimal = removeDecimal(icdCode);
      const hccCode = hccMap?.[icdCodeWithoutDecimal]?.[0];
      if (condition && hccCode) {
        return {
          HccCode: formatHCCCode(hccCode),
          IcdCode: condition.code,
          recordedDate: formattedReportedDate,
          display: condition.display,
        };
      }
      return null;
    });

    const finalMappedData = mappedData?.filter((item: any) => item !== null);
    setComponentState((prev) => ({
      ...prev,
      hccDetailsConditionsArray: (finalMappedData || []).filter((item): item is IHccDetailsCondition => item !== null),
    }));
  };

  const removeDuplicates = (data: any[]) => {
    const uniqueICD = new Map();
    data.forEach((item) => {
      const existing = uniqueICD.get(item.IcdCode);
      if (
        !existing ||
        new Date(existing.recordedDate) < new Date(item.recordedDate)
      ) {
        uniqueICD.set(item.IcdCode, item);
      }
    });
    return Array.from(uniqueICD.values());
  };

  const getHccExpandElement = (code: string) => {
    const duplicatesRemovedList = removeDuplicates(
      componentState?.hccDetailsConditionsArray
    );
    const filteredData: any = duplicatesRemovedList?.filter(
      (item: any) => item.HccCode === code
    );
    return (
      <Stack
        flexDirection={'column'}
        backgroundColor={Colors.Custom.Gray50}
        padding={2}
        borderRadius={8}
        borderWidth={1}
        borderColor={Colors.Custom.Gray200}
        marginBottom={2}
      >
        {filteredData?.map((item: any, index: any) => (
          <React.Fragment key={index}>
            <Stack flexDirection={'row'} alignItems={'flex-start'}>
            <Text fontSize={14}>{`${item?.IcdCode}: `}</Text>
            <Text color={Colors.Custom.Gray400} fontSize={14}>{` ${item?.display}`}</Text>
            </Stack>
            <Stack flexDirection={'row'} alignItems={'center'}  marginTop={2}>
            <Text
              fontSize={14}
            >{`Last Recorded on: `}</Text>
            <Text color={Colors.Custom.Gray400} fontSize={14}>{`${item.recordedDate}`}</Text>
            </Stack>
            {index < filteredData.length - 1 && <Divider marginTop={2} />}{' '}
          </React.Fragment>
        ))}
      </Stack>
    );
  };

  useEffect(() => {
    getFilteredConditionsdata();
  }, []);

  const getHCCDetailsArray = () => {
    return hccList?.map((item: any) => {
      const key = Object.keys(item)[0];
      const score = item[key];
      const groupDetails = hccGroupDetails ? hccGroupDetails[key] : {description: ''};
      const description = groupDetails?.description ? groupDetails.description : '';
      const code = key.replace(/^HCC(\d+)/, 'HCC $1');
      return {
        code: code,
        display: description,
        score: score,
      };
    });
  };

  const hccDetailsArray = getHCCDetailsArray();

  return (
    <VStack  paddingY={2} paddingX={4} borderBottomWidth={1} borderColor={Colors.Custom.Gray200}>
      <Stack
        flexDirection={'row'}
        justifyContent={'space-between'}
        width="100%"
        paddingBottom={1}
      >
        <Text
          color={Colors.Custom.Gray400}
          fontSize={14}
          alignSelf={'flex-start'}
        >
          {'Conditions & HCCs'}
        </Text>
        {hccDetailsArray.length === 0 && (
          <Text
            color={Colors.Custom.Gray400}
            fontSize={14}
            alignSelf={'flex-end'}
          >
            {'-'}
          </Text>
        )}
      </Stack>
      {hccDetailsArray.map((item: any, index: number) => {
        const {code, display, score} = item;
        const isExpanded = expandedIndex === index;
        return (
          <VStack key={index} space={2}>
            <Pressable onPress={() => toggleExpand(index)}>
              <Stack
                flexDirection={'row'}
                justifyContent={'space-between'}
                width="100%"
                alignItems={'flex-start'}
              >
                <View marginTop={2}>
                  {isExpanded ? <ExpandSvg /> : <CollapseSvg />}
                </View>
                <Text fontSize={14} marginLeft={2} width={'200px'}>
                  {`${display} (${code}) `}
                </Text>
                <Text color={Colors.Custom.Gray400} fontSize={14}>
                  {score}
                </Text>
              </Stack>
            </Pressable>
            {isExpanded && getHccExpandElement(code)}
          </VStack>
        );
      })}
    </VStack>
  );
};
