import { View, FormControl } from 'native-base'
import React from 'react'
import { getMomentObj, getMomentObjFromDateStrAndFormat } from '../../../../utils/DateUtils';
import CustomLabel from '../CustomLabel/CustomLabel';
import { DatePicker } from 'antd';
import { ICustomDatePickerProps } from './interfaces';

const CustomDatePicker = (props: ICustomDatePickerProps) => {
  const {value, onChange, placeholder, label, isRequired, infoText, isIntlLabel, format, isInvalid, minDate, maxDate} = props;

  return (
    <FormControl>
      {label && (
        <View marginLeft={1}>
          <CustomLabel label={label} isRequired={isRequired} infoText={infoText} isIntlLabel={isIntlLabel} />
        </View>
      )}
      <DatePicker
        style={{height: '40px'}}
        allowClear={true}
        format={format}
        defaultValue={value ? getMomentObjFromDateStrAndFormat(value, format) : undefined}
        disabledDate={(current: any) => {
          let isDisabled = false;
          if (current) {
            if (minDate) isDisabled = getMomentObj(current).endOf('day') < getMomentObj(minDate).startOf('day');
            if (maxDate) isDisabled = getMomentObj(current).startOf('day') > getMomentObj(maxDate).endOf('day');
          }
          return isDisabled;
        }}
        onChange={(date: any) => {
          onChange?.(date ? getMomentObj(date).format(format) : '');
        }}
        placeholder={placeholder || format}
        status={isInvalid ? 'error' : undefined}
       />
    </FormControl>
  )
}

export default CustomDatePicker;
