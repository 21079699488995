import React from 'react';
import {Skeleton, VStack} from 'native-base';

const FormComponentLoader = () => {
  return (
    <VStack space={4}>
      <Skeleton.Text />
      <Skeleton my="4" rounded="md" />
    </VStack>
  );
};

export default FormComponentLoader;
