import {useApolloClient} from '@apollo/client';
import {MessageDBService} from '../../services/MessageDBService';
import {ISelectedConversationResponse} from '../RightSideContainer/TeamInbox/Conversations/interfaces';
import React from 'react';
import ConversationsQueriesV2 from '../../services/Conversations/V2/ConversationsQueriesV2';
import {DB_MESSAGE_PAGE_SIZE} from '../../services/IndexedDBService/constants';
import {isIndexedDbEnabledForAccount} from '../../utils/configUtils';
import {debounce} from 'lodash';
import {
  CaptureTransaction,
  TRANSACTION_NAMES,
} from '../../utils/CaptureTransaction';

const messageDbService = MessageDBService.getInstance();
const captureTransactionInst = CaptureTransaction.getInstance();

export const useMessageDBService = () => {
  const abortControllerRef = React.useRef<AbortController>(
    new AbortController()
  );

  const debouncedFunctions = React.useRef<
    Record<string, (uuid: string, id: number) => void>
  >({});

  const isIndexedDbEnabled = isIndexedDbEnabledForAccount();

  const client = useApolloClient();

  const updateDBWithMessage = async (
    conversationUuid: string,
    conversationId: number
  ) => {
    if (!isIndexedDbEnabled) {
      return;
    }
    if (!debouncedFunctions.current[conversationUuid]) {
      debouncedFunctions.current[conversationUuid] = debounce(
        (uuid: string, id: number) => {
          getMessagesFromLastSyncedAt(uuid, id);
        },
        500
      );
    }
    debouncedFunctions.current[conversationUuid](
      conversationUuid,
      conversationId
    );
  };

  const updateMessageInDB = async (
    conversationUuid: string,
    messageId: number
  ) => {
    if (!isIndexedDbEnabled) {
      return;
    }
    const messageDataResponse = await client.query({
      query: ConversationsQueriesV2.GetMessageDataByMessageId,
      variables: {
        id: messageId,
      },
      fetchPolicy: 'no-cache',
      context: {
        fetchOptions: {
          signal: abortControllerRef?.current.signal,
        },
      },
    });
    if (!messageDataResponse?.data?.message?.id) {
      return;
    }
    const messageData = messageDataResponse.data.message;
    messageDbService.updateMessageByConversationUuid(
      conversationUuid,
      messageData
    );
  };

  const removeMessageFromDB = async (
    conversationUuid: string,
    messageId: number
  ) => {
    if (!isIndexedDbEnabled) {
      return;
    }
    messageDbService.deleteMessagesByMessageIds(conversationUuid, [messageId]);
  };

  const fetchGroupChatMessageAndAddToDB = async ({
    conversationUuid,
    conversationId,
    pageSize = 30,
  }: {
    conversationUuid: string;
    conversationId: number;
    pageSize?: number;
  }) => {
    const groupChatConversationResponseWithOtherData =
      await client.query<ISelectedConversationResponse>({
        query: ConversationsQueriesV2.GetMessageDataForSelectedChatV2,
        variables: {
          limit: pageSize,
          createdAtCursor: new Date(),
          conversationId: conversationId,
        },
        fetchPolicy: 'no-cache',
        context: {
          fetchOptions: {
            signal: abortControllerRef?.current.signal,
          },
        },
      });
    const conversationMessages = [
      ...(groupChatConversationResponseWithOtherData?.data
        ?.conversationMessagesData?.conversationMessages || []),
    ];
    if (isIndexedDbEnabled) {
      await messageDbService.addMessagesByConversationUuid(conversationUuid, [
        ...conversationMessages,
      ]);
    }
    return groupChatConversationResponseWithOtherData;
  };

  const syncLatestConversations = async () => {
    if (!isIndexedDbEnabled) {
      return;
    }
    const unreadConversations =
      await messageDbService.getUnreadConversationData();
    const conversationIds = unreadConversations.map(
      (conversation) => conversation.id
    );
    await syncConversationsInBulk(conversationIds);
  };

  const syncConversationsInBulk = async (conversationIds: number[]) => {
    if (!isIndexedDbEnabled) {
      return;
    }
    const response = await client.query({
      query: ConversationsQueriesV2.GetMessageDataForMultipleConversations,
      variables: {
        conversationIds,
        limit: DB_MESSAGE_PAGE_SIZE,
        createdAtCursor: new Date(),
      },
      fetchPolicy: 'no-cache',
      context: {
        fetchOptions: {
          signal: abortControllerRef?.current.signal,
        },
      },
    });
    const conversationMessagesData: any[] =
      response.data?.conversationMessagesData || [];
    for (const conversationData of conversationMessagesData) {
      await messageDbService.addMessagesByConversationUuid(
        conversationData.uuid,
        conversationData.conversationMessages
      );
    }
  };

  const removeStaleConversations = async () => {
    if (!isIndexedDbEnabled) {
      return;
    }
    await messageDbService.removeStaleConversations();
  };

  const getGroupChatMessages = async ({
    conversationUuid,
    conversationId,
  }: {
    conversationUuid: string;
    conversationId: number;
  }) => {
    if (!isIndexedDbEnabled) {
      return fetchGroupChatMessageAndAddToDB({
        conversationUuid,
        conversationId,
      });
    }
    const conversationMessagesFromDb =
      await messageDbService.getMessagesByConversationUuid(conversationUuid);
    if (
      conversationMessagesFromDb?.length &&
      conversationMessagesFromDb?.length > 0
    ) {
      const groupChatConversationResponseWithoutMessages = {data: {}} as any;
      if (groupChatConversationResponseWithoutMessages?.data) {
        const conversationMessagesData = {
          conversationMessages: conversationMessagesFromDb,
        };
        groupChatConversationResponseWithoutMessages.data.conversationMessagesData =
          conversationMessagesData;
      }
      captureTransactionInst.captureTransaction(
        TRANSACTION_NAMES.INDEXED_DB_HIT,
        conversationUuid
      );
      return groupChatConversationResponseWithoutMessages;
    }
    captureTransactionInst.captureTransaction(
      TRANSACTION_NAMES.INDEXED_DB_MISS,
      conversationUuid
    );
    return fetchGroupChatMessageAndAddToDB({
      conversationUuid,
      conversationId,
    });
  };

  const getSelectedConversationResponse = ({
    conversationId,
    conversationUuid,
  }: {
    conversationId: number;
    conversationUuid: string;
  }) => {
    return getGroupChatMessages({
      conversationUuid,
      conversationId,
    });
  };

  const getMessagesFromLastSyncedAt = async (
    conversationUuid: string,
    conversationId: number
  ) => {
    const lastSyncedAt = await messageDbService.getConversationLastSyncedAt(
      conversationUuid
    );
    if (!lastSyncedAt) {
      // the conversation does not exist in the db
      return fetchGroupChatMessageAndAddToDB({
        conversationUuid,
        conversationId,
      });
    }
    const now = new Date();
    const response = await client.query<ISelectedConversationResponse>({
      query:
        ConversationsQueriesV2.GetMessageDataBetweenTimestampsByConversationId,
      variables: {
        conversationId,
        from: lastSyncedAt,
        to: now,
      },
      fetchPolicy: 'no-cache',
      context: {
        fetchOptions: {
          signal: abortControllerRef?.current.signal,
        },
      },
    });
    const conversationUuidFromResponse =
      response?.data?.conversationMessagesData?.uuid;
    const conversationMessages = [
      ...(response?.data?.conversationMessagesData?.conversationMessages || []),
    ];
    if (conversationUuidFromResponse) {
      messageDbService.addMessagesByConversationUuid(
        conversationUuidFromResponse || conversationUuid,
        [...conversationMessages]
      );
    }
    return response;
  };
  React.useEffect(() => {
    return () => {
      debouncedFunctions.current = {};
      abortControllerRef?.current?.abort();
    };
  }, []);

  return {
    updateDBWithMessage,
    updateMessageInDB,
    removeMessageFromDB,
    fetchGroupChatMessageAndAddToDB,
    getSelectedConversationResponse,
    syncLatestConversations,
    syncConversationsInBulk,
    removeStaleConversations,
  };
};
