import { Radio, Space, Checkbox } from 'antd';
import { Text, Divider, View } from 'native-base';
import { StyleSheet } from 'react-native';
import { ITimeLineRadioButton } from './interfaces';
import { Colors } from '../../../../styles';
import { DATE_RANGE_FILTER_VALUES, TIME_LINE_SOURCE_TYPES } from '../../../RightSideContainer/TeamInbox/Conversations/MessagingContactDetails/ContactDetailsTabs/ActivityTimeLine/FeedConst';
import Stack from '../../../common/LayoutComponents/Stack';
import {useCustomToast} from '../../../Toast/ToastProvider';
import {ToastType} from '../../../../utils/commonViewUtils';

const antdStyles: Record<string, React.CSSProperties> = {
  checkbox: {
    alignItems: 'center',
  },
  radio: {
    alignItems: 'center',
  },
};


const TimeLineRadioButtonView = (props: ITimeLineRadioButton) => {
  const { radioGroupName, radioGroupValue, dateRadioGroupValue, radioButtonTypes, dateRangeFilters, onActionPerformed, onDateFilterChange } =
    props;
  const toast = useCustomToast();
  const radioButtonAvailableTypes = (
    Object.keys(radioButtonTypes) as Array<keyof typeof radioButtonTypes>
  ).map((key) => ({
    label: TIME_LINE_SOURCE_TYPES[key],
    value: key,
    key: key
  }))

  let dateRangeFiltersTypes
  if (dateRangeFilters) {
    dateRangeFiltersTypes = (
      Object.keys(dateRangeFilters) as Array<keyof typeof dateRangeFilters>
    ).map((key) => ({
      label: dateRangeFilters[key],
      value: DATE_RANGE_FILTER_VALUES[key],
      key: key
    }))
  }

  return (
    <>
      {radioButtonAvailableTypes.length && (
        <Stack direction="column">
          <Text color={Colors.FoldPixel.GRAY300} fontSize={16} marginBottom={3}>
            Source
          </Text>
          <Checkbox.Group
            name={radioGroupName}
            value={radioGroupValue}
            onChange={(checkedValues) => {
              if (props?.disableFilterAction) {
                return;
              }
              onActionPerformed(checkedValues);
            }}
          >
            <Space direction="vertical">
              {radioButtonAvailableTypes.length
                ? radioButtonAvailableTypes?.map((item: any) => {
                    return (
                      <Checkbox
                        key={item.key}
                        value={item.value}
                        style={antdStyles.checkbox}
                        disabled={props?.disableFilterAction}
                      >
                        <Text style={styles.checkboxText} fontSize={16}>
                          {item?.label}
                        </Text>
                      </Checkbox>
                    );
                  })
                : null}
            </Space>
          </Checkbox.Group>
        </Stack>
      )}

      {dateRangeFiltersTypes && dateRangeFiltersTypes.length ? (
        <Stack direction='column' style={styles.dateRangeContainer}>
          <Divider marginTop={4} marginBottom={3} width={'100%'} />
          <Text color={Colors.FoldPixel.GRAY300} fontSize={16} marginBottom={3}>
            Date Range
          </Text>
          <Radio.Group
            name={'dateRangeSelector'}
            value={dateRadioGroupValue}
            onChange={(value) => {
              if (props?.disableFilterAction) {
                return;
              }
              onDateFilterChange && onDateFilterChange(value?.target?.value);
            }}
          >
            <Space direction="vertical">
              {dateRangeFiltersTypes.length
                ? dateRangeFiltersTypes?.map((item: any) => {
                    return (
                      <Radio
                        key={item.key}
                        value={item.value}
                        style={antdStyles.radio}
                        disabled={props?.disableFilterAction}
                      >
                        <Text style={styles.radioText} fontSize={16}>
                          {item?.label}
                        </Text>
                      </Radio>
                    );
                  })
                : null}
            </Space>
          </Radio.Group>
        </Stack>
      ) : null}
    </>
  );
};

const styles = StyleSheet.create({
  checkboxText: {
    marginLeft: 8,
  },
  radioText: {
    marginLeft: 8,
  },
  dateRangeContainer: {
    paddingBottom: 4,
  },
});

export default TimeLineRadioButtonView;
