import {AxiosResponse} from "axios";
import BaseService from "../../../services/CommonService/BaseService";
import {PREFERENCE_SETTING_PATH} from "./PreferencePath";

export const addUpdateUserPreference = async (addUpdateUserPreference: any): Promise<
  AxiosResponse<any>
> => {
  const baseService = BaseService.getSharedInstance().axios;
  const response = await baseService.post(PREFERENCE_SETTING_PATH.ADD_UPDATE_USER_PREFERENCE, addUpdateUserPreference)
  return response;
};