import {VStack} from 'native-base';
import {useState} from 'react';
import {messagingListDummyData} from '../../../../../dummyData/Conversations/messagingListDummyData';
import {timelineDummyData} from '../../../../PersonOmniView/MiddleContainer/TimelineHelper';
import {TimelineView} from '../../../../PersonOmniView/MiddleContainer/TimelineView';
import {ICustomerDetailsProps,ICustomerPanelProps} from '../interfaces';
import {DetailsPanel} from './DetailsPanel';
import {RelatedPanel} from './RelatedPanel';

export const CustomerPanel = (props: ICustomerPanelProps) => {
  const [currentTab] = useState('Details');
  // const [isOpen, setIsOpen] = useState(false);
  const currentCustomer: ICustomerDetailsProps = messagingListDummyData[0];
  const {contactData, conversationData, contactType, onActionPerformed, parentCode,btnTextVisibility} = props;
  const getCurrentTab = () => {
    switch (currentTab) {
      case 'Details':
        return (
          <DetailsPanel
            btnTextVisibility={btnTextVisibility}
            contactData={contactData}
            contactType={contactType}
            conversationData={conversationData}
            currentCustomer={currentCustomer}
            onActionPerformed={onActionPerformed}
            borderLessView={props?.borderLessView}
            height={props?.height}
            extra={props?.extra}
            showEditProfileOnPatientInfoBanner={props.showEditProfileOnPatientInfoBanner}
            parentCode={parentCode}
            hintExtraDetail={props.hintExtraDetail}
            showDetailProfile={props.showDetailProfile}
            showCloseDrawerIcon={props?.showCloseDrawerIcon}
            patientHieSyncCall={props.patientHieSyncCall}
            isEmployerDrillDown={props?.isEmployerDrillDown}
          />
        );

      case 'Related':
        return <RelatedPanel />;

      case 'Activity':
        return (
          <>
            {/* <Spacer marginTop={5} /> <ComingSoon /> */}
            <TimelineView
              mainTimelineTitle="February 2022"
              timelineData={timelineDummyData}
            />
          </>
        );

      default:
        break;
    }
  };

  return <VStack>{getCurrentTab()}</VStack>;
};
