import {Button, HStack, Icon, Input, Text, TextArea, View, VStack} from 'native-base';
import React, {useState, useEffect} from 'react';
import {StyleSheet} from 'react-native';
import {useIntl} from 'react-intl';
import {
  APPOINTMENT_PARTICIPANT_STATUS_CODES,
  APPOINTMENT_STATUS_CODES,
  LOCATION_TYPE_CODES,
  MLOV_CATEGORY,
} from '../../../../../../constants/MlovConst';
import {
  getMlovIdFromCode,
  getMlovListFromCategory,
} from '../../../../../../utils/mlovUtils';
import {Colors} from '../../../../../../styles';
import MaterialIcon from 'react-native-vector-icons/MaterialIcons';
import FHAlertDialog from '../../../../FHAlertDialog/FHAlertDialog.native';
import {IParticipantData} from './AppointmentBookingIntefaces';
import {
  addTimeToDate,
  getDiffInMinutes,
} from '../../../../../../utils/DateUtils';
import { BookAppointmentScreenAction } from '../../BookAppointment/Interfaces';
import { BUTTON_TYPE } from '../../../../../../constants';
import {useMutation} from '@apollo/client';
import {AppointmentQueries} from '../../../../../../services';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../../constants/Configs';
import {getUserUUID} from '../../../../../../utils/commonUtils';

const AppointmentRsvpNative = (props: any) => {
  const {
    appointmentStatusList,
    declinedReason,
    setAppointmentRSVPState,
    // rsvpOptionList,
    // handleOnSendRSVPResponse,
    appointmentRSVPState,
    participantStatusOptions,
    appointment,
    showErrorPopup,
    setDetailState,
    navigation,
  } = props;
  const loggedInParticipantId = getUserUUID();
  const [updateParticipantStatus] = useMutation(
    AppointmentQueries.UPDATE_PARTICIPANT_STATUS,
    {
      fetchPolicy: 'no-cache',
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
      },
    }
  );

  const intl = useIntl();
  const [isDeclineDialogOpen, setIsDeclineDialogOpen] =
    useState<boolean>(false);

  const APPOINTMENT_STATUS_IDS = {
    pending: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.PENDING
    ),
    proposed: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.PROPOSED
    ),
    booked: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.BOOKED
    ),
    decline: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.DECLINED
    ),
    checkedIn: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.CHECKED_IN
    ),
    checkedOut: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.CHECKED_OUT
    ),
    cancelled: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.CANCELLED
    ),
    rescheduled: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.RESCHEDULED
    ),
    scheduled: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.SCHEDULED
    ),
    noShow: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.NO_SHOW
    ),
  };

  const providerUser = (props.appointment?.participants || []).find(
    (participant: any) => {
      if (participant?.user?.uuid) return true;
    }
  );

  const providerUserUuid = providerUser?.user?.uuid || undefined;
  const appointmentStatusId = props.appointmentStatusId;

  const participantName =
    props.participant?.contact?.name || props.participant.user?.name;
  const participantId = getParticipantUniqueId(props.participant);

  const participantStatusList =
    getMlovListFromCategory(
      props.careStudioMlovData,
      MLOV_CATEGORY.APPOINTMENT_PARTICIPANT_STATUS
    ) || [];

  const isAppointmentStatusPending =
    appointmentStatusId === APPOINTMENT_STATUS_IDS.pending;

  const isAppointmentStatusDecline =
    appointmentStatusId === APPOINTMENT_STATUS_IDS.decline;

  const isAppointmentStatusProposed =
    appointmentStatusId === APPOINTMENT_STATUS_IDS.proposed;

  const PARTICIPANT_STATUS_IDS = {
    accepted: getMlovIdFromCode(
      participantStatusList,
      APPOINTMENT_PARTICIPANT_STATUS_CODES.ACCEPTED
    ),
    declined: getMlovIdFromCode(
      participantStatusList,
      APPOINTMENT_PARTICIPANT_STATUS_CODES.DECLINED
    ),
    needsAction: getMlovIdFromCode(
      participantStatusList,
      APPOINTMENT_PARTICIPANT_STATUS_CODES.NEEDS_ACTION
    ),
  };

  const selectedParticipantStatusId = props.appointmentRSVPState
    .selectedParticipantStatus?.length
    ? props.appointmentRSVPState.selectedParticipantStatus[0].key
    : undefined;

  const isSelectedParticipantStatusProposed =
    selectedParticipantStatusId === PARTICIPANT_STATUS_IDS.needsAction;

  const isSelectedParticipantStatusDeclined =
    selectedParticipantStatusId === PARTICIPANT_STATUS_IDS.declined;

  const participantStatusId = props.participant.statusId;

  const isParticipantStatusPending = !participantStatusId;

  const isParticipantStatusDeclined =
    participantStatusId === PARTICIPANT_STATUS_IDS.declined;

  let isInitiator = false;

  let isParticipantLoggedInUser = false;

  const isParticipantInitiator = props.participant.isInitiator || false;

  if (
    props.participant.id &&
    props.participant.id === props.detailState.loggedInParticipantId
  ) {
    isParticipantLoggedInUser = true;
    isInitiator = isParticipantInitiator;
  }

  const canParticipantTakeAction =
    isParticipantLoggedInUser &&
    ((isAppointmentStatusPending && isParticipantStatusPending) ||
      (isAppointmentStatusProposed && isInitiator));

  const canShowRSVPComponent =
    isAppointmentStatusPending ||
    isAppointmentStatusProposed ||
    isAppointmentStatusDecline;

  const canShowRSVPSelect = canParticipantTakeAction;

  const canShowProposedDateTimeSelect =
    canParticipantTakeAction &&
    isSelectedParticipantStatusProposed &&
    providerUserUuid;

  const canShowDeclinedReasonText =
    isAppointmentStatusDecline && isParticipantStatusDeclined;

  const canShowDeclinedReasonInput =
    canParticipantTakeAction && isSelectedParticipantStatusDeclined;

  const canShowAwaitingApprovalFrom =
    !isParticipantLoggedInUser &&
    participantName &&
    ((isAppointmentStatusPending && isParticipantStatusPending) ||
      (isAppointmentStatusProposed && isParticipantInitiator));

  const rsvpOptionList = canParticipantTakeAction
    ? participantStatusOptions
    : [];

  const appointmentDuration = getDiffInMinutes(
    props.appointment.startDateTime,
    props.appointment.endDateTime
  );

  function getParticipantUniqueId(participant: IParticipantData) {
    if (!participant) return undefined;
    if (participant.id) return participant.id;
    if (participant.user) return participant.user.uuid || undefined;
    if (participant.contact) return participant.contact.uuid || undefined;
    return undefined;
  }

  function handleOnSendRSVPResponse() {
    setAppointmentRSVPState((prevState: any) => ({
      ...prevState,
      showFormErrors: true,
    }));
    const appointmentDetails = props.appointment;

    if (!appointmentDetails?.participants?.length) {
      showErrorPopup();
      return;
    }

    const apiInputData: any = {
      id: appointmentDetails.id,
    };

    let isValid = true;

    apiInputData.participants = appointmentDetails.participants.map(
      (participant: IParticipantData) => {
        if (!canParticipantTakeAction) {
          return participant;
        }

        const newParticipant = JSON.parse(
          JSON.stringify(participant)
        ) as IParticipantData;

        if (!appointmentRSVPState?.selectedParticipantStatus?.length) {
          isValid = false;
          return newParticipant;
        }

        newParticipant.statusId =
          appointmentRSVPState.selectedParticipantStatus[0].key;
        newParticipant.isStatusUpdated = true;

        switch (appointmentRSVPState.selectedParticipantStatus[0].value) {
          case APPOINTMENT_PARTICIPANT_STATUS_CODES.ACCEPTED:
            if (
              isParticipantLoggedInUser &&
              isInitiator &&
              isAppointmentStatusProposed &&
              appointmentDetails?.proposedTime
            ) {
              apiInputData.startDateTime = appointmentDetails.proposedTime;
              apiInputData.endDateTime = addTimeToDate(
                appointmentDetails.proposedTime,
                appointmentDuration,
                'MINUTE'
              ).toISOString();
            } else if (
              isParticipantLoggedInUser &&
              !isInitiator &&
              (isAppointmentStatusProposed || isAppointmentStatusPending)
            ) {
              apiInputData.statusId = APPOINTMENT_STATUS_IDS.booked;
              // apiInputData.startDateTime = props.appointmentDetails.startDateTime;
            }
            break;
          case APPOINTMENT_PARTICIPANT_STATUS_CODES.DECLINED:
            if (appointmentRSVPState.declinedReason)
              apiInputData.declinedReason = appointmentRSVPState.declinedReason;
            break;
          case APPOINTMENT_PARTICIPANT_STATUS_CODES.NEEDS_ACTION:
            if (
              !appointmentRSVPState.selectedProposedTimeSlot ||
              !appointmentRSVPState.selectedProposedDate
            ) {
              isValid = false;
              return newParticipant;
            }
            apiInputData.proposedTime =
              appointmentRSVPState.selectedProposedTimeSlot.startDateTime;
            break;
        }

        return {
          id: newParticipant.id,
          statusId: newParticipant.statusId,
          contactId: newParticipant.contactId,
          userId: newParticipant.userId || newParticipant.user?.uuid,
          participantTypeId: newParticipant.participantTypeId,
        } as IParticipantData;
      }
    );

    if (!isValid) {
      return;
    }

    apiInputData.participants.forEach((item: any) => {
      delete item.reference;
      delete item.user;
      delete item.contact;
    });

    apiInputData.participants = apiInputData.participants.filter(
      (participant: IParticipantData) =>
        participant?.userId === loggedInParticipantId
    );

    setAppointmentRSVPState((prevState: any) => ({
      ...prevState,
      isLoading: true,
    }));

    updateParticipantStatus({
      variables: {
        data: apiInputData,
      },
    })
      .then(() => {
        setAppointmentRSVPState((prevState: any) => ({
          ...prevState,
          isLoading: false,
        }));
        props.onSendResponseSuccess(setDetailState);
      })
      .catch((error: any) => {
        setAppointmentRSVPState((prevState: any) => ({
          ...prevState,
          isLoading: false,
        }));


        showErrorPopup();
      });
  }

  const isVirtualAppointment = () => {
    return (
      props.appointment?.appointmentType?.locationType?.code ===
      LOCATION_TYPE_CODES.VIRTUAL
    );
  };

  const getAppointmentLocation = () => {
    const locationIds = [];
    if (props.appointment?.accountLocationId) {
      locationIds.push(props.appointment?.accountLocationId);
    }
    return props?.appointment?.accountLocationId;
  };


  const isAlreadyProposed = isParticipantLoggedInUser && isAppointmentStatusProposed && isInitiator;

  useEffect(() => {
    if (appointmentRSVPState.selectedParticipantStatus?.length) {
      if (
        appointmentRSVPState.selectedParticipantStatus[0]?.value ===
        rsvpOptionList?.[0]?.value
      ) {
        handleOnSendRSVPResponse();
      }
    }
  }, [appointmentRSVPState.selectedParticipantStatus]);
  return (
    <VStack padding={1}>
      {canShowRSVPComponent && (
        <VStack>
          {canParticipantTakeAction && (
            <VStack space={2}>
              {canShowRSVPSelect && (
                <HStack justifyContent={'space-between'}>
                  <View width={'48%'}>
                    <Button
                      style={styles.acceptBtn}
                      onPress={() => {
                        setAppointmentRSVPState((prevState: any) => ({
                          ...prevState,
                          selectedParticipantStatus: [rsvpOptionList?.[0]],
                        }));
                      }}
                    >
                      {intl.formatMessage({id: 'accept'})}
                    </Button>
                  </View>
                  <View width={'48%'}>
                    <Button
                      variant="outline"
                      style={styles.declineBtn}
                      onPress={() => {
                        setIsDeclineDialogOpen(true);
                        setAppointmentRSVPState((prevState: any) => ({
                          ...prevState,
                          selectedParticipantStatus: [rsvpOptionList?.[1]],
                        }));
                      }}
                    >
                      {intl.formatMessage({id: 'decline'})}
                    </Button>
                  </View>
                </HStack>
              )}
              {!isAlreadyProposed && (
              <Button
                variant="outline"
                style={styles.proposeNewTimeSlotBtn}
                onPress={() => {
                  props.onBookAppointment(
                    BookAppointmentScreenAction.proposingNewTime
                  );
                  // navigation.navigate('ProposeNewTimeSlotScreen', {
                  //   isVirtualAppointment,
                  //   getAppointmentLocation,
                  //   appointmentRSVPState,
                  //   appointmentDuration,
                  //   providerUserUuid,
                  //   setAppointmentRSVPState,
                  // });
                  // setAppointmentRSVPState((prevState: any) => ({
                  //   ...prevState,
                  //   selectedParticipantStatus: [rsvpOptionList?.[2]],
                  // }));
                }}
              >
                Propose New Time Slot
              </Button>
              )}
            </VStack>
          )}
          {canShowDeclinedReasonText && (
            <Text>
              {'Decline Reason: ' + (appointment?.declinedReason || '-')}
            </Text>
          )}
          {canShowAwaitingApprovalFrom && (
            <VStack>
              <Text color={Colors.Custom.Gray500} size={'xsNormal'}>
                Response{' '}
              </Text>
              <HStack>
                <View alignItems="center" justifyContent="center">
                  <Icon as={MaterialIcon} name="reply" size="3" mr={1} />
                </View>
                <Text>{`Awaiting Response From ${participantName}`}</Text>
              </HStack>
            </VStack>
          )}
          {/* {canShowDeclinedReasonInput && ''}
          {canShowProposedDateTimeSelect && ''} */}
        </VStack>
      )}
      <FHAlertDialog
        isOpen={canShowDeclinedReasonInput && isDeclineDialogOpen}
        header={intl.formatMessage({id: 'declineReasonHeader'})}
        bodyContent={
          <VStack marginBottom={3}>
            <View>
              <Text>{intl.formatMessage({id: 'declineReasonMessage'})}</Text>
            </View>
            <View>
              <TextArea
                numberOfLines={3}
                placeholder={intl.formatMessage({id: 'optionalDeclinedReason'})}
                value={props.appointmentRSVPState.declinedReason}
                onChangeText={(value: string) => {
                  setAppointmentRSVPState((prevState: any) => ({
                    ...prevState,
                    declinedReason: value || value.trim(),
                  }));
                }}
                marginTop={2}
              />
            </View>
          </VStack>
        }
        buttonActions={[
          {
            textLocalId: 'Cancel',
            buttonProps: {
              variant: BUTTON_TYPE.SECONDARY
            },
            onPress: () => {
              setIsDeclineDialogOpen(false);
              // setCancelAppointmentConfirmation({
              //   visible: false,
              // });
            },
          },
          {
            textLocalId: 'Decline',
            buttonProps: {
              variant: BUTTON_TYPE.PRIMARY
            },
            onPress: () => {
              handleOnSendRSVPResponse();
              setIsDeclineDialogOpen(false);
            },
          },
        ]}
      />
    </VStack>
  );
};

const styles = StyleSheet.create({
  acceptBtn: {
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#6941c6',
  },
  declineBtn: {
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
  },
  proposeNewTimeSlotBtn: {
    borderRadius: 16,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default AppointmentRsvpNative;
