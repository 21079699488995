import {StyleSheet} from 'react-native';
import {Colors} from '../../../styles';
export const styles = StyleSheet.create({
  topContainer: {
    backgroundColor: 'transparent',
    // paddingRight: 15,
    // paddingLeft: 15,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  left: {
    paddingRight: 0,
    color: Colors.Custom.TitleColor,
  },
  leftText: {
    marginRight: 10,
    color: Colors.Custom.Gray900,
  },
  topIcons: {
    marginLeft: 5,
    marginRight: 5,
  },
});
