import { View } from 'react-native';
import { interventionSvgStyles } from './InterventionSvgStyles';

const EditIconSvg = () => {

    return (
        <View style={{
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                fill="none"
                viewBox="0 0 16 16"
            >
                <path
                    stroke="#3A485F"
                    d="M9.573 2.72l.618-.619A2.622 2.622 0 1113.9 5.81l-.618.618M9.573 2.719s.078 1.313 1.236 2.472c1.159 1.158 2.472 1.236 2.472 1.236M9.573 2.719L3.893 8.4c-.385.386-.578.578-.743.79a4.37 4.37 0 00-.5.808c-.115.242-.201.5-.373 1.017l-.73 2.187m11.734-6.774L7.6 12.107c-.385.385-.577.578-.79.743-.25.195-.52.363-.807.5-.243.115-.5.2-1.017.373l-2.187.73m0 0l-.535.177a.707.707 0 01-.894-.894l.178-.535m1.25 1.251l-1.25-1.25"
                ></path>
            </svg>
        </View>
    );
};

export default EditIconSvg;
