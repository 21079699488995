import {View} from 'react-native';
import React from 'react';
import {ISingleCallTrailIconProps} from './interfaces';
import {styles} from './styles';

const SingleCallTrailIcon = (props: ISingleCallTrailIconProps) => {
  return (
    <View style={styles.iconContainer}>
      <View style={styles.line} />
      <View style={styles.iconSubContainer}>{props.Icon}</View>
      <View
        style={[
          styles.line,
          ...(props.hideBottomLine
            ? [
                {
                  backgroundColor: 'white',
                },
              ]
            : []),
        ]}
      />
    </View>
  );
};

export default SingleCallTrailIcon;
