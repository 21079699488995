import {HStack, Skeleton, View, VStack, Text, Spinner} from 'native-base';
import {GROUP_MEMBER_TYPE} from '../../../../../constants';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import {IGroupUser, IUserRoles} from '../../../../../services/Conversations/interfaces';
import {DisplayCardAvatar} from '../../../../common/DisplayCard/DisplayCardAvatar';
import RoleDataListView from './RoleDataListView';
import { Tooltip } from 'antd';
import {Colors} from '../../../../../styles';
import UnassignUserSvg from '../../../../common/Svg/UnassignUserSvg';
import { TestIdentifiers, testID } from '../../../../../testUtils';
import './ConversationActionView.module.css';
import { StyleSheet } from 'react-native';

const UserListView = (props: any) => {
  const {loadingStates, users, selectedUsers, selectionLoading} = props;
  return (
    <>
      <View style={styles.container}>
        {selectionLoading && (
          <Spinner
            style={styles.spinner}
            position={'absolute'}
            {...testID(TestIdentifiers.lazyLoading)}
          />
        )}
        {loadingStates ? (
          <View padding={3} {...testID(TestIdentifiers.pageLoading)}>
            <Skeleton.Text lines={3} />
          </View>
        ) : (
          <View style={styles.userList}>
            {
              users &&
              users.length > 0 &&
              users?.map((userItem: IGroupUser, index: number) => {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      props.onActionPerformed(
                        COMMON_ACTION_CODES.ASSIGN_USER,
                        userItem
                      );
                    }}
                    className="pressable user-list-item hstack justify-content-space-between"
                    {...testID('UsderItemClick')}
                    
                  >
                    <HStack marginY={1} marginX={1} alignItems={'center'} style={styles.userInfo}>
                      <VStack>
                        <DisplayCardAvatar
                          avatarStyle={{
                            avatarSize: '12',
                          }}
                          isLetterAvatarShow={true}
                          userData={{
                            userId: userItem?.id || '',
                            userType: GROUP_MEMBER_TYPE.USER,
                            userName: userItem.name,
                          }}
                          hideFullNameTooltip
                        />
                      </VStack>
                      <VStack>
                      <Tooltip title={userItem?.name} placement="topLeft">
                          <VStack marginX={2}>
                            <Text
                              color={'black'}
                              fontSize={14}
                              fontWeight={500}
                              numberOfLines={1}
                              maxW={140}
                              isTruncated
                              {...testID('UserName')}
                            >
                              {userItem?.name}
                            </Text>
                          </VStack>
                          <View marginX={2}>
                            <HStack
                              flex={1}
                              style={styles.roleListView}
                              {...testID('RoleListView')}
                            >
                              <RoleDataListView
                                userRoles={
                                  userItem?.userRoles || ([] as IUserRoles[])
                                }
                                isPopupView={true}
                              />
                            </HStack>
                          </View>
                        </Tooltip>
                      </VStack>
                    </HStack>
                    {selectedUsers.id == userItem?.id  ? (
                      <div onClick={(e) => {
                        e?.stopPropagation();
                        props.onActionPerformed(
                          COMMON_ACTION_CODES.UNASSIGN,
                          userItem
                        );
                      }}
                        className="unassign-user"
                      >
                        <Tooltip title='Unassign'>
                        <View style={styles.unassignIcon}
                        {...testID('Unassign')}>
                          <UnassignUserSvg customStrokeColor={Colors.error[600]} />
                        </View>
                        </Tooltip>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                );
              })
            }
          </View>
        )}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'relative',
  },
  spinner: {
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    zIndex: 12,
  },
  userList: {
    overflow: 'scroll',
    maxHeight: 400,
  },
  userItem: {
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  userInfo: {
    width: '70%',
  },
  roleListView: {
    width: 230,
  },
  unassignButton: {
    flexDirection: 'row',
    alignItems: 'center',
    display: 'flex',
  },
  unassignIcon: {
    width: 20,
    height: 20,
    alignItems: 'center',
    alignSelf: 'center',
    marginRight: 8,
  },
});

export default UserListView;
