import {Drawer} from 'antd';
import React, {useEffect} from 'react';
import {IAddCptCodesProps} from './interfaces';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import {Colors} from '../../../../../styles';
import {BUTTON_TYPE} from '../../../../../constants';
import {FormControl, Input, Spinner, TextArea, useToast} from 'native-base';
import {getAccountUUID} from '../../../../../utils/commonUtils';
import CptCodesQueries from '../../../../../services/CptCodes/CptCodesQueries';
import {useLazyQuery} from '@apollo/client';
import {createCptCode, validateCptCode} from './CommunicationTypesUtils';
import {ToastType, showToast} from '../../../../../utils/commonViewUtils';
import { StyleSheet } from 'react-native';

const AddCptCodes = (props: IAddCptCodesProps) => {
  const {isOpen, onClose, onSubmit} = props;
  const [state, setState] = React.useState({
    submitting: false,
    searching: false,
    code: '',
    description: '',
    titleError: '',
  });

  const accountUuid = getAccountUUID();
  const toast = useToast();

  const [getCptCodes] = useLazyQuery(CptCodesQueries.GET_CPT_CODES, {
    fetchPolicy: 'no-cache',
    variables: {
      searchString: '%%',
      accountUuid: accountUuid,
    },
  });

  const isCptCodeAlreadyExists = async () => {
    const code = state.code;
    if (!code) {
      return false;
    }
    const isValidCode = validateCptCode(code);
    if (!isValidCode) {
      setState((prev) => {
        return {
          ...prev,
          titleError: 'Please enter a valid CPT code',
        };
      });
      return false;
    }
    setState((prev) => {
      return {
        ...prev,
        searching: true,
      };
    });
    const response = await getCptCodes({
      variables: {
        searchString: `${code}`,
        accountUuid: accountUuid,
      },
    });
    const codes = response?.data?.cpt_codes || [];
    if (codes.length > 0) {
      setState((prev) => {
        return {
          ...prev,
          titleError: 'This CPT code already exists',
          searching: false,
          submitting: false,
        };
      });
      return false;
    } else {
      setState((prev) => {
        return {
          ...prev,
          titleError: '',
          searching: false,
        };
      });
      return true;
    }
  };

  const handleSubmit = async () => {
    try {
      const validCode = await isCptCodeAlreadyExists();
      if (!validCode) {
        return;
      }
      setState((prev) => {
        return {
          ...prev,
          submitting: true,
        };
      });
      const response = await createCptCode({
        code: state.code,
        description: state.description,
      });
      onSubmit();
    } catch (error) {

      showToast(
        toast,
        'Something went wrong in creating cpt code',
        ToastType.error
      );
      onClose();
    }
  };

  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      isCptCodeAlreadyExists();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [state.code]);

  return (
    <Drawer
      onClose={onClose}
      width={'33vw'}
      open={isOpen}
      title={
        <ModalActionTitle
          title={'Add CPT Code'}
          titleColor={''}
          buttonList={[
            {
              show: true,
              id: 1,
              btnText: 'cancel',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.SECONDARY,
              isTransBtn: false,
              onClick: () => {
                onClose();
              },
            },
            {
              show: true,
              id: 2,
              btnText: 'save',
              textColor: Colors.Custom.mainPrimaryPurple,
              isLoading: state.submitting,
              variant: BUTTON_TYPE.PRIMARY,
              isTransBtn: false,
              onClick: () => {
                handleSubmit();
              },
            },
          ]}
        />
      }
    >
      <FormControl
        style={styles.formControl}
        isInvalid={!!state.titleError}
        isRequired
      >
        <FormControl.Label>Code</FormControl.Label>
        <Input
          value={state.code}
          onChangeText={(text) => {
            setState((prev) => {
              return {
                ...prev,
                code: text.toUpperCase(),
              };
            });
          }}
          maxLength={8}
          style={styles.input}
          InputRightElement={
            state.searching ? (
              <Spinner
                size={'sm'}
                style={styles.spinner}
              />
            ) : (
              <></>
            )
          }
        />
        {state.titleError && (
          <FormControl.ErrorMessage>
            {state.titleError}
          </FormControl.ErrorMessage>
        )}
      </FormControl>
      <FormControl style={styles.formControl}>
        <FormControl.Label>Description</FormControl.Label>
        <TextArea
          value={state.description}
          onChangeText={(text) => {
            setState((prev) => {
              return {
                ...prev,
                description: text,
              };
            });
          }}
        />
      </FormControl>
    </Drawer>
  );
};

const styles = StyleSheet.create({
  formControl: {
    marginVertical: 20,
  },
  input: {
    height: 40,
  },
  spinner: {
    marginRight: 10,
  },
});

export default AddCptCodes;
