import {StyleSheet} from 'react-native';
import {Colors} from '../../../../styles';
import {isWeb} from '../../../../utils/platformCheckUtils';

export const styles = StyleSheet.create({
  emailListTextStyle: {
    fontWeight: '400',
    fontSize: 12,
    lineHeight: 14.4,
    color: Colors.Custom.Gray400,
  },
  emailMessageContainerStyle: {
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: isWeb() ? 16 : 20,
    flexDirection: 'row',
    flex: 1,
    height: '100%',
    backgroundColor: isWeb() ? 'white' : '',
  },
  privateMessageContainerStyle: {
    borderRadius: 8,
    borderWidth: 1.5,
    padding: 12,
    borderColor: '#F47A3E',
    borderStyle: 'dotted',
    flexDirection: 'column',
  },
  avatarStyle: {
    flex: 0.1,
    height: '100%',
    margin: 2,
  },
  emailContentStyle: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    flex: isWeb() ? 0.8 : 0.7,
  },
  subtitleStyle: {
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 16.8,
    color: Colors.Custom.Gray400,
  },
  actionPopoverStyle: {
    flex: 0.1,
    alignItems: 'center',
    height: '100%',
  },
  actionItemContainer: {
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    flex: 1,
    padding: 4,
  },
  actionLabel: {
    color: Colors.Custom.Gray700,
    marginLeft: 14,
    display: 'flex',
    alignItems: 'center',
    lineHeight: 20,
  },
  internalTitleText: {
    marginLeft: 4,
    color: '#F47A3E',
    fontWeight: '500',
    fontSize: 12,
    lineHeight: 14.4,
  },
  internalSenderNameText: {
    marginRight: 4,
    fontWeight: '500',
    fontSize: 12,
    lineHeight: 14.4,
    color: Colors.Custom.Gray400,
  },
  internalMessageHeaderView: {
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
  },
  internalMsgDateView: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  internalMsgDateText: {
    fontWeight: '400',
    fontSize: 12,
    lineHeight: 14.4,
    color: Colors.Custom.Gray500,
  },
});
