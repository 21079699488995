import React, { useContext } from 'react';
import {HStack, Icon, Text} from 'native-base';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {Colors} from '../../../../../../styles';
import { CommonDataContext } from '../../../../../../context/CommonDataContext';

const ContactPracticeToUpdateInfoMessage = () => {
  const color = Colors.Custom.Gray500;

  const commonData:any = useContext(CommonDataContext);

  return (
    <HStack mb={2}>
      <Icon
        as={AntIcon}
        my={1}
        name="infocirlceo"
        size={3}
        mr={1}
        color={color}
      />
      <Text fontSize="xs" color={color}>
        Please contact {commonData?.tenantName || `practice`} to update this information.
      </Text>
    </HStack>
  );
};

export default ContactPracticeToUpdateInfoMessage;
