import { useMutation } from '@apollo/client';
import { Divider, Input, Text, TextArea, View, VStack } from 'native-base';
import React, { useEffect, useRef, useState } from 'react';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../constants/Configs';
import { IAppointmentTask } from '../../../services/Appointment/AppointmentInterface';
import { UPDATE_APPOINTMENT_TASK_STATUS } from '../../../services/Appointment/AppointmentQueries';
import { Colors } from '../../../styles';
import { Task } from './Task';
import { testID } from '../../../testUtils';

interface IProps {
  tasks?: IAppointmentTask[];
  appointmentTypeTasks?: IAppointmentTask[];
  appointmentId?: string;

  canEditTitle?: boolean;
  canEditStatus?: boolean;
  canAddNewTasks?: boolean;
  canDeleteTasks?: boolean;
  showStatus?: boolean;

  showErrors?: boolean;

  onAddNewTask?: (task: IAppointmentTask) => void;
  onEditTaskDetails?: (task: IAppointmentTask) => void;
  onDelete?: (task: IAppointmentTask) => void;
  onChange?: (tasks: IAppointmentTask[]) => void;
}

interface IComponentState {
  tasks?: (IAppointmentTask & { isLoading?: boolean, errorMsg?: string; })[];
  newTaskTitle?: string;
  areTasksModified: boolean;
}

export function TaskCheckList(props: IProps) {
  const [componentState, setComponentState] = useState<IComponentState>({
    areTasksModified: false,
  });
  const timeOutRefs = useRef<NodeJS.Timeout[]>([])

  useEffect(() => {
    if (props.appointmentId) {
      const tasks = (props.tasks || []);
      setComponentState((prev) => ({
        ...prev,
        tasks: (props.tasks || []),
        areTasksModified: true,
      }));
      props.onChange?.(tasks?.map((task) => formatTask(task)));
    } else {
      const tasks = (props.tasks || props.appointmentTypeTasks || []);
      setComponentState((prev) => ({
        ...prev,
        tasks: tasks,
        areTasksModified: props.tasks?.length ? true : false,
      }));
      props.onChange?.(tasks?.map((task) => formatTask(task)));
    }
    return () => {
      timeOutRefs.current.forEach((timeoutId) => {
        clearTimeout(timeoutId);
      });
    }
  }, []);

  useEffect(() => {
    if (componentState.areTasksModified) {
      return;
    }

    const tasks = (props.appointmentTypeTasks || []).map((task) => {
      return {
        ...task,
        id: undefined,
        isCompleted: false,
      };
    });

    if (!tasks?.length && !componentState?.tasks?.length) {
      return;
    }

    setComponentState((prev) => ({
      ...prev,
      tasks: [ ...tasks ],
    }));

    props.onChange?.(tasks?.map((task) => formatTask(task)));

  }, [ props.appointmentTypeTasks ]);

  const newTaskTitleElementRef = useRef<any>(null);

  const [ updateTaskStatusApi ] = useMutation(UPDATE_APPOINTMENT_TASK_STATUS, {
    fetchPolicy: 'no-cache',
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    }
  });

  function onAddNewTask() {
    if (!componentState.newTaskTitle?.trim?.()) {
      return;
    }
    const newTask: IAppointmentTask = {
      title: componentState.newTaskTitle.trim(),
    };

    const tasks = [ ...(componentState.tasks || []), newTask ];

    props.onAddNewTask?.(formatTask(newTask));
    triggerOnChangeEvent(tasks);


    const timeOutId = setTimeout(() => {
      newTaskTitleElementRef?.current?.focus?.();
      setComponentState((prev) => {
        return {
          ...prev,
          newTaskTitle: '',
          tasks: tasks,
        };
      });
    }, 100)
    timeOutRefs.current.push((timeOutId))
  }

  function updateTaskStatus(task: IAppointmentTask & { isLoading?: boolean, errorMsg?: string; }, isCompleted: boolean) {
    if (!task.id) {
      return;
    }
    if (!props.appointmentId) {
      task.isCompleted = isCompleted;
      updateTaskList(task);
      return;
    }

    task.isLoading = true;
    updateTaskList(task, false);

    updateTaskStatusApi({
      variables: {
        id: task.id,
        isCompleted: isCompleted,
      },
      onCompleted: (result) => {
        if (result?.updateAppointmentTask?.id) {
          task.isCompleted = isCompleted;
          task.errorMsg = '';
        } else {
          task.errorMsg = 'Unable to update task, please try again later!!';
        }
        task.isLoading = false;
        updateTaskList(task, false);
      },
      onError: () => {
        task.errorMsg = 'Unable to update task, please try again later!!';
        task.isLoading = false;
        updateTaskList(task, false);
      }
    });
  }

  function updateTaskList(currentTask: IAppointmentTask, shouldTriggerOnChangeEvent = true) {
    setComponentState((prev) => {
      const tasks = prev.tasks || [];
      tasks.some((task, index) => {
        if (task.id && task.id === currentTask?.id) {
          tasks[index] = {
            ...task,
            ...currentTask,
          };
          if (!shouldTriggerOnChangeEvent) {
            props.onEditTaskDetails?.(formatTask(tasks[index]));
            triggerOnChangeEvent(prev.tasks || []);
          }
          return true;
        }
      });

      return {
        ...prev
      };
    });
  }

  function triggerOnChangeEvent(tasks: IAppointmentTask[]) {
    setComponentState((prev) => ({
      ...prev,
      areTasksModified: true,
    }));
    props.onChange?.(tasks?.map((task) => formatTask(task)));
  }

  function formatTask(task: IAppointmentTask) {
    return {
      id: task.id,
      title: task.title,
      isCompleted: task.isCompleted,
      isDeleted: task.isDeleted,
    };
  }

  function onChangeTaskProperty(taskIndex: number, propertyKey: string, propertyValue: any) {
    setComponentState((prev) => {
      const tasks = prev.tasks || [];
      const currentTask = tasks[taskIndex];
      if (currentTask) {
        (currentTask as any)[propertyKey] = propertyValue;
        triggerOnChangeEvent(prev.tasks || []);
      }

      return { ...prev };
    });
  }

  return (
    <VStack>
      {!!componentState.tasks?.length && (
        <View
          borderWidth={1}
          borderRadius={8}
          borderColor={Colors.Custom.Gray200}
          backgroundColor={'white'}
        >
          {componentState.tasks.map((task, index) => {
            if (task.isDeleted) {
              return <></>;
            }
            return (
              <>
                <VStack
                  padding={1}
                  {...(task.errorMsg && {
                    borderColor: Colors.error[300],
                    borderRadius: 4,
                    borderWidth: 1,
                  })}
                >
                  <View margin={2}>
                    <Task
                      key={index}
                      id={index.toString()}
                      isChecked={task.isCompleted}
                      title={task.title}
                      canEditStatus={props.canEditStatus}
                      canEditTitle={props.canEditTitle}
                      canDeleteTasks={props.canDeleteTasks}
                      showStatus={props.showStatus}
                      showErrors={props.showErrors}
                      isStatusLoading={task.isLoading}
                      onChangeIsChecked={(isChecked: boolean) => {
                        updateTaskStatus(task, isChecked);
                      }}
                      onChangeTitle={(title?: string) => {
                        props.onEditTaskDetails?.(formatTask({
                            ...task,
                            title: title,
                          }))
                          onChangeTaskProperty(index, 'title', title);
                        }}
                        onDelete={(id?: string) => {
                          props.onEditTaskDetails?.(formatTask({
                            ...task,
                            isDeleted: true,
                          }));
                          onChangeTaskProperty(index, 'isDeleted', true);
                        }}
                      />
                    </View>

                    {
                      task.errorMsg &&
                      <View>
                        <Text fontSize="xs" color="error.500" {...testID('TaskCheckListError')}>
                          {task.errorMsg}
                        </Text>
                      </View>
                    }
                  </VStack>

                  {
                    ((index+1) !== componentState.tasks?.length) &&
                    <Divider/>
                  }
                </>
              );
            })
          }
        </View>
      )}
       {props.canAddNewTasks && (
        <VStack space={2}>
          <View
            borderWidth={1}
            borderColor={Colors.Custom.brandAccentColor}
            backgroundColor={Colors.Custom.brandAccentBackgroundColor}
            borderRadius={6}
            marginTop={2}
          >
            <TextArea
              backgroundColor={Colors.Custom.brandAccentBackgroundColor}
              borderWidth={0}
              ref={newTaskTitleElementRef}
              numberOfLines={3}
              autoComplete={'off'}
              placeholder="Enter instructions for staff here "
              value={componentState.newTaskTitle || ''}
              {...testID('enterInstructionsForStaffHere')}
              onBlur={(event) => {
                if (componentState.newTaskTitle?.trim?.()) {
                  onAddNewTask();
                } else {
                  setComponentState((prev) => ({
                    ...prev,
                    newTaskTitle: undefined,
                  }));
                }
              }}
              onChangeText={(value) => {
                setComponentState((prev) => ({...prev, newTaskTitle: value}));
              }}
              onKeyPress={(event) => {
                if (event?.nativeEvent?.key === 'Enter') {
                  if (componentState.newTaskTitle?.trim?.()) {
                    onAddNewTask();
                  } else {
                    setComponentState((prev) => ({
                      ...prev,
                      newTaskTitle: undefined,
                    }));
                  }
                  event?.preventDefault?.();
                }
              }}
            />
          </View>
          <View>
            <Text color={Colors.Custom.Gray400} fontSize={'xs'}>
              {"Press 'Enter' or 'Return' to add new instruction"}
            </Text>
          </View>
        </VStack>
      )}
    </VStack>
  );
}
