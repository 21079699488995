import {Formio} from '@foldhealth/formiojs';
import BasicFields from '../EditFormFields/BasicFields';
import ConditionalComponents from '../EditFormFields/ConditionalFields';
import KeyField from '../EditFormFields/KeyField';

Formio.Components.components.phoneNumber.editForm = function () {
  return {
    key: 'display',
    components: [
      ...BasicFields,
      {
        type: 'oldtextfield',
        input: true,
        key: 'inputMask',
        label: 'Input Mask',
        tooltip:
          "An input mask helps the user with input by ensuring a predefined format.<br>9 indicates a numeric input expected from user<br>Total no of 9's in the mask denote expected length<br><br>Example telephone mask: (999) 999-9999",
        customConditional(context: any) {
          return !context.data.allowMultipleMasks;
        },
      },
      // KEPT FOR FUTURE
      // {
      //   type: 'checkbox',
      //   label: 'Hide Label',
      //   tooltip:
      //     'Hide the label or title of this component. This allows you to show the label in the form builder, but not when the form is shown to the member.',
      //   key: 'hideLabel',
      //   input: true,
      // },
      ...ConditionalComponents,
      ...KeyField,
    ],
  };
};
