import React from 'react';
import {ICommonSvgProps} from '../interfaces';
import Svg, {Path} from 'react-native-svg';
import { Colors } from '../../../../styles/Colors';

const ChatRoundActionSvg = (props: ICommonSvgProps) => {
    const strokeColor = props?.isActive
    ? Colors.FoldPixel.GRAY300
    : Colors.Custom.Gray300;
  return (
    <Svg
      width={props?.width ? props?.width : '24'}
      height={props?.height ? props?.height : '24'}
      fill="none"
      viewBox="0 0 24 24"
    >
      <Path
        stroke={strokeColor}
        d="M12 21a9 9 0 10-9-9c0 1.44.338 2.8.94 4.007.159.32.212.688.12 1.034l-.537 2.003a1.17 1.17 0 001.433 1.433l2.003-.536a1.47 1.47 0 011.034.12A8.964 8.964 0 0012 21z"
      ></Path>
    </Svg>
  );
};

export default ChatRoundActionSvg;
