import {DurationInputArg2} from 'moment';
import {
  FilterTypes,
  GraphDataOperation,
  IDateRange,
  IVitals,
} from './interface';
import {HOME_MONITORING_VITALS} from './utils';
import { convertSecondsToMins, getMomentObj } from '../../../../../../utils/DateUtils';
import { Vital } from '../../../../../../utils/VitalUtils';
import { IActivity } from '../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/interfaces';
import { DISPLAY_DATE_FORMAT } from '../../../../../../constants';

export const getDataByVital = (vital: string, groupObject: any) => {
  if (vital === Vital.sleep) {
    const rem = groupObject[Vital.rem_sleep];
    const light = groupObject[Vital.light_sleep];
    const deep = groupObject[Vital.deep_sleep];
    const awake = groupObject[Vital.awake];
    const sleep = groupObject[Vital.sleep];

    const stagesArr = [];
    if (rem) {
      stagesArr.push(...rem);
    }
    if (light) {
      stagesArr.push(...light);
    }
    if (deep) {
      stagesArr.push(...deep);
    }
    if (awake) {
      stagesArr.push(...awake);
    }
    if(sleep) {
      stagesArr.push(...sleep);
    }
    return stagesArr;
  }
  return groupObject[vital];
};

export const getFormatttedDateByFilterType = (
  date: string | Date,
  filterType: keyof typeof FilterTypes,
) => {
  let format = '';

  switch (filterType) {
    case FilterTypes.Day:
      format = 'hh:mm a';
      break;
    case FilterTypes.Week:
      format = 'ddd';
      break;
    case FilterTypes.Month:
      format = 'MMM DD';
      break;
    case FilterTypes.ThreeMonths:
    case FilterTypes.SixMonths:
      format = 'MMM';
      break;
  }

  return getMomentObj(date).format(format);
};

export const groupVitalDataByVital = (vitalData: IVitals[]) => {
  return vitalData?.reduce((group: any, currentObject) => {
    const {text} = currentObject;
    group[text] = group[text] ?? [];
    group[text].push(currentObject);
    return group;
  }, {});
};

export const getVitalDataArray = (
  vitalString: string,
  vitalData: IVitals[],
  dataOperation?: GraphDataOperation | undefined,
) => {
  const data = sortByDate(
    getDataByVital(vitalString, groupVitalDataByVital(vitalData)),
  );

  const config = HOME_MONITORING_VITALS.find(
    (item) => item.loinc === vitalString,
  );
  if (dataOperation === GraphDataOperation.LOW_EVENT) {
    return data?.filter?.((item) => {
      const intValue = parseInt(item.value);
      const minValue = config?.range?.[0]?.min || 0;
      return intValue < minValue;
    });
  }

  if (vitalString === Vital.sleep) {
    return data.map((item) => ({
      ...item,
      value: `${convertSecondsToMins(parseInt(item.value)).toFixed(1)}`,
    }));
  }

  return data;
};

export const sortByDate = (array: IVitals[]) => {
  return (
    array &&
    array.sort((a, b) => {
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    })
  );
};

export const getDataSetByDateRange = (
  dateRange: IDateRange,
  addUnit?: DurationInputArg2,
) => {
  const dataSet = [];
  const startDate = getMomentObj(dateRange.start);
  const endDate = getMomentObj(dateRange.end);
  while (startDate.isSameOrBefore(endDate)) {
    dataSet.push(startDate.toISOString());
    startDate.add(1, addUnit || 'day');
  }
  return dataSet;
};

export const getDataAvailableCountString = (
  data: (IVitals | IActivity)[],
  dateRange: IDateRange,
): string => {
  const totalDays = getMomentObj(dateRange.end).diff(
    getMomentObj(dateRange.start),
    'days',
  ) + 1;

  const availableDays = getAvailableDayCount(data);
  return `Data available for ${availableDays}/${totalDays} days`;
};


const getAvailableDayCount = (data: (IVitals | IActivity)[]): number => {
  const daySet = new Set();

  data?.forEach((item) => {
    const date = getMomentObj(item.date);
    if (
      (typeof item.value === 'string' && item.value !== '') ||
      (typeof item.value === 'number' && item.value !== 0)
    ) {
      daySet.add(date.format(DISPLAY_DATE_FORMAT));
    }
  });

  return daySet.size;
};