

import React from 'react';
import { Path, Svg } from 'react-native-svg';

const OneIconSvg = () => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <Path d="M0.25 12C0.25 9.16451 0.250531 7.06303 0.467441 5.44968C0.683383 3.84352 1.10965 2.75862 1.93414 1.93414C2.75862 1.10965 3.84352 0.683383 5.44968 0.467441C7.06303 0.250531 9.16451 0.25 12 0.25C14.8355 0.25 16.937 0.250531 18.5503 0.467441C20.1565 0.683383 21.2414 1.10965 22.0659 1.93414C22.8904 2.75862 23.3166 3.84352 23.5326 5.44968C23.7495 7.06303 23.75 9.16451 23.75 12C23.75 14.8355 23.7495 16.937 23.5326 18.5503C23.3166 20.1565 22.8904 21.2414 22.0659 22.0659C21.2414 22.8904 20.1565 23.3166 18.5503 23.5326C16.937 23.7495 14.8355 23.75 12 23.75C9.16451 23.75 7.06303 23.7495 5.44968 23.5326C3.84352 23.3166 2.75862 22.8904 1.93414 22.0659C1.10965 21.2414 0.683383 20.1565 0.467441 18.5503C0.250531 16.937 0.25 14.8355 0.25 12Z" fill="#F5F0FF"/>
    <Path d="M0.25 12C0.25 9.16451 0.250531 7.06303 0.467441 5.44968C0.683383 3.84352 1.10965 2.75862 1.93414 1.93414C2.75862 1.10965 3.84352 0.683383 5.44968 0.467441C7.06303 0.250531 9.16451 0.25 12 0.25C14.8355 0.25 16.937 0.250531 18.5503 0.467441C20.1565 0.683383 21.2414 1.10965 22.0659 1.93414C22.8904 2.75862 23.3166 3.84352 23.5326 5.44968C23.7495 7.06303 23.75 9.16451 23.75 12C23.75 14.8355 23.7495 16.937 23.5326 18.5503C23.3166 20.1565 22.8904 21.2414 22.0659 22.0659C21.2414 22.8904 20.1565 23.3166 18.5503 23.5326C16.937 23.7495 14.8355 23.75 12 23.75C9.16451 23.75 7.06303 23.7495 5.44968 23.5326C3.84352 23.3166 2.75862 22.8904 1.93414 22.0659C1.10965 21.2414 0.683383 20.1565 0.467441 18.5503C0.250531 16.937 0.25 14.8355 0.25 12Z" stroke="#D7C0FF" stroke-width="0.5"/>
    <Path d="M13.0955 8.72727V16H11.9947V9.82812H11.9521L10.212 10.9645V9.91335L12.0266 8.72727H13.0955Z" fill="#8C5AE2"/>
    </Svg>
  );
};

export default OneIconSvg;