import {
  HStack
} from 'native-base';
import Fontisto from 'react-native-vector-icons/Fontisto';
import { DisplayText } from '../../../../common/DisplayText/DisplayText';

const InactiveWorkflowWarning = (props: {}) => {
  return <HStack justifyContent={'center'} alignItems={'center'} width={'100%'}>
  <Fontisto name="info" size={20} />
  <DisplayText
    size={'smMedium'}
    extraStyles={{
      color: '#F37232',
      marginLeft: 20,
      width:'100%'
    }}
    textLocalId={`When running an inactive automation, all branches after the delay & reminder nodes will not work as expected.`}
  />
</HStack>
};

export default InactiveWorkflowWarning;
