import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
    listContainer: {
        paddingTop: 2,
        paddingBottom: 2,
      },
      displayListItem: {
        paddingTop: 6,
        marginRight: 18,
        marginLeft: 18,
        paddingBottom: 6,
        maxHeight: 200,
        cursor: 'pointer',
        justifyContent: 'space-between',
      },
})