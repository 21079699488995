import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {
  Button,
  HStack,
  Pressable,
  Skeleton,
  Text,
  View,
  VStack,
} from 'native-base';
import React, {useState} from 'react';
import Feather from 'react-native-vector-icons/Feather';
import {COMMON_ACTION_CODES} from '../../../constants/ActionConst';
import {NoteQueries} from '../../../services';
import {Colors} from '../../../styles';
import {getFormattedDateString} from '../../PersonOmniView/LeftContainer/OtherDetails/OtherDeatilsUtils';
import {styles} from './ContactNotesViewStyles';
import {isWeb} from '../../../utils/platformCheckUtils';
import {DeleteConfirmationDrawer} from '../DeleteConfirmationDrawer/DeleteConfirmationDrawer';
import FHAlertDialog from '../FHAlertDialog/FHAlertDialog.native';
import {DisplayText} from '../DisplayText/DisplayText';

const ContactNotesListView = (props: any) => {
  const [stateData, setStateData] = useState({
    showConfirmModal: false as boolean,
    selectedNote: {},
    loading: false as boolean,
  })
  const [deleteContactNote]= useMutation(NoteQueries.DeleteContactNote)
  const {contactNotesList, loading, contactId} = props;
  const handleNoteDelete = async (note:any) =>{
    setStateData((prev)=> {
      return {
        ...prev,
        loading: true,
      }
    })
    if (note?.id) {
      const deleteNote = await deleteContactNote({
        variables : {
          id:note?.id,
          contactId: contactId,
        }
      })
      onShowConfirmModalChange();
      if(deleteNote?.data?.updateNotes?.returning?.length){
        props.onNotesListActionPerform(COMMON_ACTION_CODES.COMPLETED, {});
      }
    } else {
      onShowConfirmModalChange();
    }
  }
  const onShowConfirmModalChange = ()=> {
    setStateData((prev)=> {
      return {
        ...prev,
        showConfirmModal: false,
        loading: false,
        selectedNote: {},
      }
    })
  }
  return (
    <VStack>
      {loading ? (
        <View padding={3}>
          <Skeleton.Text lines={3} />
        </View>
      ) : (
        <>
          {contactNotesList?.map((item: any, index: any) => {
            return (
              <VStack key={`ContactNotesList_${index}`}>
                {index!==0 && (
                  <View
                    style={{
                      width: '100%',
                      height: 1,
                      backgroundColor: Colors.Custom.Gray200,
                    }}
                  />
                )}
                <Pressable
                  key={index}
                  onPress={() => {
                    props.onNotesListActionPerform(
                      COMMON_ACTION_CODES.ROW_CLICKED,
                      item
                    );
                  }}
                  style={{
                    marginVertical: 8,
                  }}
                >
                  <HStack
                    style={[styles.displayListItem]}
                    flex={1}
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    <VStack flex={1} justifyContent={'center'}>
                      <Text
                        isTruncated
                        maxW={300}
                        size={'smLight'}
                        fontWeight={500}
                      >
                        {item.content.replace(/\n/g, ' ')}
                        {/* <MDEditor.Markdown source={item.content} /> */}
                      </Text>
                      <View my={1} flexWrap={'wrap'} flexDirection={isWeb() ? 'row' : 'column'}>
                        {item?.user?.name &&
                        <Text size={'xsMedium'} color={Colors.Custom.Gray400}>
                          {`${item?.user?.name},  `}
                        </Text>
                        }
                        <HStack>
                          <Text
                            size={'xsMedium'}
                            color={Colors.Custom.Gray400}
                          >
                            {'last updated date:  '}
                          </Text>
                          <Text
                            size={'xsMedium'}
                            color={Colors.Custom.Gray400}
                            style={{fontStyle: 'italic'}}
                          >
                            {getFormattedDateString(item.updatedAt + 'Z', 'dob')}
                          </Text>
                        </HStack>
                      </View>
                    </VStack>
                    <HStack justifyContent={'flex-end'}>
                      <Button
                        onPress={() => {
                          props.onNotesListActionPerform(
                            COMMON_ACTION_CODES.EDIT,
                            item
                          );
                        }}
                        backgroundColor={'transparent'}
                        leftIcon={
                          <Feather
                            name="edit-2"
                            size={15}
                            color={Colors.Custom.Gray600}
                          />
                        }
                        // style={styles.btn}
                      />
                      <Button
                        onPress={() => {
                          setStateData((prev)=> {
                            return {
                              ...prev,
                              showConfirmModal: true,
                              selectedNote: item
                            }
                          })
                        }}
                        backgroundColor={'transparent'}
                        leftIcon={
                          <Feather
                            name="trash-2"
                            size={15}
                            color={Colors.Custom.Gray600}
                          />
                        }
                        // style={styles.btn}
                      />
                    </HStack>
                  </HStack>
                </Pressable>
              </VStack>
            );
          })}
        </>
      )}
      {stateData?.showConfirmModal && <>
        {isWeb() ? <DeleteConfirmationDrawer
          isOpen={true}
          titleId={'deleteContactNote'}
          message={'deleteContactNoteMessage'}
          onCancel={()=> {
            onShowConfirmModalChange();
          }}
          onConfirm={()=> {
            handleNoteDelete(stateData?.selectedNote);
          }}
          isLoading={stateData?.loading}
        /> :
          <FHAlertDialog
            isOpen={true}
            header={
              <DisplayText
                textType={'Heading'}
                textLocalId={'deleteContactNote'}
              />
            }
            message={<DisplayText size={'sm'} textLocalId={'deleteContactNoteMessage'} />}
            showCloseBtn={true}
            onClose={() => {
              onShowConfirmModalChange();
            }}
            buttonActions={[
              {
                textLocalId: 'Cancel',
                buttonProps: {
                  colorScheme: 'muted',
                  variant: 'outline',
                },
                onPress: () => {
                  onShowConfirmModalChange();
                },
              },
              {
                isDeleting: stateData?.loading,
                textLocalId: 'Delete',
                textColor: '#fff',
                buttonProps: {
                  backgroundColor: Colors.primary['700'],
                },
                onPress: () => {
                  handleNoteDelete(stateData?.selectedNote);
                },
              },
            ]}
          />
      }
      </>}
    </VStack>
  );
};

export default ContactNotesListView;
