import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  tagContainer: {
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 7,
    paddingRight: 7,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 30,
  },
});
