import {
  FHIRQuestionnaireObject,
  Question,
  QuestionnaireObject,
} from '../../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/Questionnaire/interfaces';
import {IAddOrUpdateSocialHistoryParams} from '../interfaces';

export const getRecordDataFormatted = (listData: any[]): QuestionnaireObject[] => {
  const ehrFormattedRecords: QuestionnaireObject[] = []
  listData.map((item: any) => {
    if (item?.resource) {
      const entry = item.resource;
      ehrFormattedRecords.push(entry);
    }
  });
  return ehrFormattedRecords;
};

export const getQuestionListFromQuestionnaire = (
  questionnaire: FHIRQuestionnaireObject
): Question[] => {
  return questionnaire.item.map((question) => {
    return {
      id: question.linkId,
      text: question.text,
      repeats: question.repeats,
      type: question.type,
      answerOptions: (question?.answerOption || []).map((answer) => {
        return {
          display: answer.valueCoding.display,
          code: answer.valueCoding.code,
          system: answer.valueCoding.system,
        };
      }) || [],
    };
  });
};

export const getQuestionnaireIdFromObject = (
  item: QuestionnaireObject
): string | undefined => {
  let {questionnaireId} = item;
  if (!questionnaireId && item.questionnaire) {
    const list = item.questionnaire.split('/');
    if (list.length > 1) {
      questionnaireId = list[1];
    }
  }
  return questionnaireId;
};

export const getFHIRTemplate = (params: IAddOrUpdateSocialHistoryParams) => {
  return {
    resourceType: 'Bundle',
    type: 'searchset',
    total: params.questionnaire.length,
    entry: params.questionnaire.map((value) => {
      return {
        resource: value,
      };
    }),
  };
};

export const getFHIRQuestionnaireAnswerTemplate = (currentQuestionnaire: QuestionnaireObject, patientId: string) => {
  return {
    resourceType: 'QuestionnaireResponse',
    id: currentQuestionnaire.id,
    questionnaireId: currentQuestionnaire.questionnaireId,
    questionnaire: `Questionnaire/${currentQuestionnaire.questionnaireId}`,
    subject: {
      reference: `Patient/${patientId}`,
    },
    status: currentQuestionnaire.status,
    authored: currentQuestionnaire.authored,
    item: currentQuestionnaire.item,
  };
};
