import {SUPPORTED_EVENT_CODE} from '../constants/WebSocketConst';

const DEFAULT_MODULE_CODE = 'global';

type TEventOptions = {
  moduleCode?: string;
  disallowToAddMultipleEventsForModule?: boolean;
};
type IEventListener = (data: any, eventCode?: string) => void;
type TEventListenersByModuleCodes = Map<string, Set<IEventListener>>;
type TEventListenersByEventCode  = Map<string, TEventListenersByModuleCodes>;

export class EventBus {
  private static eventBusInst: EventBus;
  private eventListeners: TEventListenersByEventCode = new Map();

  private constructor() {
    if (EventBus.eventBusInst) {
      throw new Error('Use EventBus.eventBusInst instead of new.');
    }
    EventBus.eventBusInst = this;
  }

  static getEventBusInstance(): EventBus {
    return this.eventBusInst ?? (this.eventBusInst = new EventBus());
  }

  public broadcastEvent(eventName: string, data: any) {
    const moduleListeners = this.eventListeners.get(eventName);
    if (!moduleListeners?.size) {
      return;
    }

    for (const listeners of Array.from(moduleListeners.values())) {
      if (!listeners?.size) {
        continue;
      }
      for (const listener of Array.from(listeners)) {
        if (typeof listener === 'function') {
          if (
            eventName === SUPPORTED_EVENT_CODE.NEW_CHAT_CREATED ||
            eventName === SUPPORTED_EVENT_CODE.GROUP_INFO_UPDATED
          ) {
            setTimeout(() => listener(data, eventName), 100);
          } else {
            listener(data, eventName);
          }
        }
      }
    }
  }

  public addEventListener(eventName: string, listener: IEventListener, options?: TEventOptions) {
    const moduleCode = options?.moduleCode || DEFAULT_MODULE_CODE;
    const disallowToAddMultipleEventsForModule = options?.disallowToAddMultipleEventsForModule || false;

    if (!this.eventListeners.get(eventName)) {
      this.eventListeners.set(eventName, new Map());
    }
    const moduleListeners = this.eventListeners.get(eventName) as TEventListenersByModuleCodes;

    if (!moduleListeners.get(moduleCode)) {
      moduleListeners.set(moduleCode, new Set<IEventListener>());
    }
    const currentEventListeners = moduleListeners.get(moduleCode) as Set<IEventListener>;

    if (disallowToAddMultipleEventsForModule && moduleCode !== DEFAULT_MODULE_CODE) {
      currentEventListeners.clear();
    }

    currentEventListeners.add(listener);
  }

  public removeEventListener(eventListener: IEventListener, options?: TEventOptions) {
    const moduleCode = options?.moduleCode || DEFAULT_MODULE_CODE;

    for (const [ eventName ] of this.eventListeners.entries()) {
      this.removeEventListenerByEventName(eventName, eventListener, { moduleCode });
    }
  }

  public removeEventListenerByEventName(eventName: string, eventListener: IEventListener, options?: TEventOptions) {
    if (!eventName) {
      return;
    }

    const moduleCode = options?.moduleCode || DEFAULT_MODULE_CODE;
    const moduleListeners = this.eventListeners.get(eventName);
    if (!moduleListeners?.size) {
      return;
    }

    const listeners = moduleListeners.get(moduleCode);
    if (!listeners?.size) {
      return;
    }

    listeners.delete(eventListener);

    if (listeners.size === 0) {
      moduleListeners.delete(moduleCode);
      if (moduleListeners.size === 0) {
        this.eventListeners.delete(eventName);
      }
    }
  }

  public removeAllEventListenerByModuleCode(moduleCode: string) {
    if (!moduleCode || moduleCode === DEFAULT_MODULE_CODE) {
      return;
    }

    const eventNames = Array.from(this.eventListeners.keys());

    for (const eventName of eventNames) {
      const moduleListeners = this.eventListeners.get(eventName);

      if (!moduleListeners?.size) {
        continue;
      }

      const listeners = moduleListeners.get(moduleCode);
      if (listeners?.size) {
        listeners.clear();
      }

      moduleListeners.delete(moduleCode);

      if (moduleListeners.size === 0) {
        this.eventListeners.delete(eventName);
      }
    }
  }
}
