import { Coding } from 'fhir/r4';
import {
  Answer,
  AnswerList,
  Question,
  QuestionnaireObject,
  Resource,
  SingleQuestionnaire,
  SubjectObject,
} from './interfaces';

export const QUESTIONNAIRE_RESPONSE = 'QuestionnaireResponse';

export const getQuestionnaireList = (data: any): SingleQuestionnaire[] => {
  const entry = data.entry;

  return entry.map((item: any) => {
    const resource = item.resource;
    return {
      id: resource.id,
      name: resource.name,
      description: resource.description,
      status: resource.status,
    };
  });
};

export const getQuestionListFromSelectedQuestionnaire = (
  id: string,
  bundle: any
): Question[] => {
  const selectedQuestionnaire = bundle.entry.find(
    (item: any) => item.resource.id === id
  ) as Resource;

  return selectedQuestionnaire.resource.item.map((question) => {
    return {
      id: question.linkId,
      text: question.text,
      repeats: question.repeats,
      type: question.type,
      answerOptions: question.answerOption?.map((answer) => {
        return {
          display: answer.valueCoding.display,
          code: answer.valueCoding.code,
          system: answer.valueCoding.system,
        };
      }) || [],
    };
  });
};

export const getTypeByQuestion = (question: Question) => {
  if (question.answerOptions.length === 2) {
    return 'radio';
  }
  if (question.repeats) {
    return 'multiSelect';
  }
  return question.type;
};

export const getAnswerOfSingleSelectQuestion = (
  question: Question,
  answerList: AnswerList[]
): Coding | undefined => {
  const data = answerList.find((answer) => answer.linkId === question.id);
  if (data?.answer.length) {
    if (data.answer?.[0].valueString) {
      const answerCodeString = data?.answer?.[0]?.valueString;
      const answerDisplayString = question.answerOptions.find(
        (answer) => answer.code === answerCodeString
      )?.display;
      return {
        code: answerCodeString,
        display: answerDisplayString || '',
        system: '',
      };
    }
    if (data.answer?.[0].valueCoding)
      return (
        data.answer[0].valueCoding
      );
  }
};

export const getAnswerOfTextQuestion = (
  question: Question,
  answerList: AnswerList[]
): string | undefined => {
  const data = answerList.find((answer) => answer.linkId === question.id);
  if (data?.answer.length && data.answer[0].valueString) {
    return data.answer[0].valueString;
  }
};

export const getAnswerOfDateQuestion = (
  question: Question,
  answerList: AnswerList[]
): string | undefined => {
  const data = answerList.find((answer) => answer.linkId === question.id);
  if (data?.answer?.length && data.answer[0].valueString) {
    return data.answer[0].valueString;
  }
};

export const getAnswerOfMultiSelectQuestion = (
  question: Question,
  answerList: AnswerList[]
): Coding[] => {
  const result: Coding[] = [];
  const data = answerList.find((answer) => answer.linkId === question.id);
  if (data?.answer.length) {
    data.answer.forEach((item) => {
      if (item.valueCoding) {
        result.push(item.valueCoding);
      }
    });
  }
  return result;
};

export const getSelectAnswerObject = (args: {
  question: Question;
  answer: Answer;
}): AnswerList => {
  const {question, answer} = args;

  return {
    linkId: question.id,
    text: question.text,
    answer: [
      {
        valueCoding: {
          code: answer.code,
          display: answer.display,
          system: answer.system,
        },
      },
    ],
  };
};

export const getMultiSelectAnswerObject = (args: {
  question: Question;
  answers: Answer[];
}): AnswerList => {
  const {question, answers} = args;
  return {
    linkId: question.id,
    text: question.text,
    answer: getMultiSelectAnswerListObject(answers),
  };
};

export const getTextAnswerObject = (args: {
  question: Question;
  text: string;
}): AnswerList => {
  const {question, text} = args;
  return {
    linkId: question.id,
    text: question.text,
    answer: [
      {
        valueString: text,
      },
    ],
  };
};

export const getDateAnswerObject = (args: {
  question: Question;
  date: string;
}): AnswerList => {
  const {question, date} = args;
  return {
    linkId: question.id,
    text: question.text,
    answer: [
      {
        valueString: date,
      },
    ],
  };
};

export const getQuestionnaireObject = (args: {
  answerList: AnswerList[];
  questionnaireId: string;
  authorId: string;
  subjectId: string;
}): QuestionnaireObject => {
  const {answerList, questionnaireId, authorId, subjectId} = args;

  return {
    resourceType: QUESTIONNAIRE_RESPONSE,
    item: getAnswerListObject(answerList),
    questionnaire: getQuestionnaireIdString(questionnaireId),
    questionnaireId: questionnaireId,
    author: getAuthor(authorId),
    subject: getSubject(subjectId),
    authored: getAuthoredDate(),
  };
};

const getQuestionnaireIdString = (questionnaireId: string) => {
  return `Questionnaire/${questionnaireId}`;
};

const getSubject = (subjectId: string): SubjectObject => {
  return {
    reference: `Patient/${subjectId}`,
  };
};

const getAuthor = (practitionerId: string): SubjectObject => {
  return {
    reference: `Practitioner/${practitionerId}`,
  };
};

const getAuthoredDate = () => {
  return new Date(Date.now()).toJSON();
};

const getAnswerListObject = (answerList: AnswerList[]) => {
  const reversedList = [...answerList].reverse();
  return reversedList.filter((value, index, self) => {
    return self.findIndex((v) => v.linkId === value.linkId) === index;
  });
};

const getMultiSelectAnswerListObject = (answers: Answer[]) => {
  return answers.map((item) => ({
    valueCoding: {
      code: item.code,
      display: item.display,
      system: item.system,
    },
  }));
};
