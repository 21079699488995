import { compare as compareVersion, CompareOperator } from 'compare-versions';

export const BROWSER_TYPE = {
  OPERA: 'Opera',
  OPERA2: 'OPR',
  EDGE: 'Edg',
  CHROME: 'Chrome',
  SAFARI: 'Safari',
  FIREFOX: 'Firefox',
  UNKNOWN: 'unknown',
};

class BrowserInfo {
  public browserName?: string;
  public browserVersion?: string;

  constructor() {
    this.browserName = this.getBrowserName();
    this.browserVersion = this.getBrowserVersion();
  }


  private getBrowserName = function () {
    if (!navigator?.userAgent) {
      return;
    }
    return Object.values(BROWSER_TYPE).find((browser) => navigator?.userAgent.indexOf(browser) != -1);
  }

  private getBrowserVersion = function () {
    const userAgent = navigator?.userAgent;
    if (!userAgent) {
      return;
    }
    let matches = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+).(\d+)/i) || [];
    matches = matches[2] ? [matches[1], matches[2], matches[3]]: [navigator.appName, navigator.appVersion, '-?'];

    // Trident.
    if (/trident/i.test(matches[1])){
      const temp = /\brv[ :]+(\d+).(\d+)/g.exec(userAgent) || [];
      const version = (temp[1] || '');
      return version;
    }

    // Chrome.
    if (matches[1] === 'Chrome'){
      const temp = userAgent.match(/\bOPR|Edge\/(\d+).(\d+)/) || [];
      const version = temp[1];
      return version;
    }

    const temp = userAgent.match(/version\/(\d+).(\d+)/i)

    if (temp !== null) {
      const version = temp[0]?.split('/')?.[1];
      return version;
    }

    const version = matches[1] + (matches[2] ? `.${matches[2]}` : '');

    return version;
  }

  compareBrowserVersion(operator: CompareOperator, version: string) {
    if (!this.browserVersion) {
      return;
    }
    return compareVersion(this.browserVersion, version, operator);
  }
}

export const browserInfo = new BrowserInfo();

