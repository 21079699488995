import CommonService from "../../../../../services/CommonService/CommonService";

export const uploadImageService = (formData: any) => {
  const commonService = CommonService.getCommonServiceInstance();
  const axiosService = commonService.axiosService;
  return axiosService.put(`/profile`, formData, {
      headers: {
          'content-type': 'multipart/form-data',
      },
  })
};
