/* eslint-disable @typescript-eslint/no-var-requires */
export const alliedPhysicianFemale = require('../../../assets/images/Avatar/AlliedPhysician_Female.png');
export const alliedPhysicianMale = require('../../../assets/images/Avatar/AlliedPhysician_Male.png');
export const assistantPhysicianFemale = require('../../../assets/images/Avatar/AssistantPhysician_Female.png');
export const assistantPhysicianMale = require('../../../assets/images/Avatar/AssistantPhysician_Male.png');
export const branchAdminFemale = require('../../../assets/images/Avatar/BranchAdmin_Female.png');
export const branchAdminMale = require('../../../assets/images/Avatar/BranchAdmin_Male.png');
export const businessOwnerFemale = require('../../../assets/images/Avatar/BusinessOwner_Female.png');
export const businessOwnerMale = require('../../../assets/images/Avatar/BusinessOwner_Male.png');
export const careProgramManagerFemale = require('../../../assets/images/Avatar/CareProgramManager_Female.png');
export const careProgramManagerMale = require('../../../assets/images/Avatar/CareProgramManager_Male.png');
export const chatbot = require('../../../assets/images/Avatar/Chatbot.png');
export const female = require('../../../assets/images/Avatar/Female.png');
export const financeFemale = require('../../../assets/images/Avatar/Finance_Female.png');
export const financeMale = require('../../../assets/images/Avatar/Finance_Male.png');
export const healthAdvocateFemale = require('../../../assets/images/Avatar/HealthAdvocate_Female.png');
export const healthAdvocateMale = require('../../../assets/images/Avatar/HealthAdvocate_Male.png');
export const internalGroup = require('../../../assets/images/Avatar/InternalGroup.png');
export const male = require('../../../assets/images/Avatar/Male.png');
export const nurse = require('../../../assets/images/Avatar/Nurse.png');
export const patientUnknown = require('../../../assets/images/Avatar/Patient_Unknown.png');
export const patientBroadCastGroup = require('../../../assets/images/Avatar/PatientBroadCastGroup.png');
export const patientGroupFemale = require('../../../assets/images/Avatar/PatientGroup_Female.png');
export const patientGroupMale = require('../../../assets/images/Avatar/PatientGroup_Male.png');
export const physicianFemale = require('../../../assets/images/Avatar/Physician_Female.png');
export const physicianMale = require('../../../assets/images/Avatar/Physician_Male.png');
export const practiceOwnerFemale = require('../../../assets/images/Avatar/PracticeOwner_Female.png');
export const practiceOwnerMale = require('../../../assets/images/Avatar/PracticeOwner_Male.png');
export const prospect = require('../../../assets/images/Avatar/Prospect.png');
export const socialWorker = require('../../../assets/images/Avatar/SocialWorker.png');
export const supportFemale = require('../../../assets/images/Avatar/Support_Female.png');
export const supportMale = require('../../../assets/images/Avatar/Support_Male.png');
