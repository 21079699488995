import React, { useContext, useState } from 'react';
import {Popover} from 'antd';
import { ITask } from '../CareDashboardInterfaces';
import { BottomViewAction, isTaskWithType } from '../../../TaskCard/TaskCardHelper';
import { TASK_STATUS_CODES, TASK_PRIORITY_CODES } from '../../../../constants/MlovConst';
import { LazyQueryExecFunction, OperationVariables } from '@apollo/client';
import { HStack, IToastProps, Pressable, Text, Divider, Spinner, View } from 'native-base';
import { IntlShape } from 'react-intl';
import { BoardType } from '../CareDashboardTopBar/interfaces';
import { getColumnName, PropertyType } from '../CareDashboardUtils/CareDashboardUtils';
import Feather from 'react-native-vector-icons/Feather';
import { Colors } from '../../../../styles/Colors';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import { EntityType } from '../../../TaskCard/TaskEnum';
import { isAllowToDeleteTask} from '../../../TaskCard/TaskHelper';
import {getIconByAction} from './CareDashboardTableHelper';
import ThreeDotsSvg from '../../Svg/SideCarSvg/ThreeDotsSvg';
import './CareDashboard.css';
import { StyleSheet } from 'react-native';

interface ICareDashboardTableActions {
    completedStatusId: string;
    intl: IntlShape;
    task: ITask;
    boardType?: BoardType;
    checkIfAllSubTasksAreCompleted: (task: ITask) => void;
    onTaskAction?: (code: string, data: ITask, action: BottomViewAction) => void;
}

const CareDashboardTableActions = (props: ICareDashboardTableActions) => {
    const [checkifAllSubTasksCompletedLoading, setCheckifAllSubTasksCompletedLoading] = useState(false);
    const commonData = useContext(CommonDataContext);
    const isSideCarContext = commonData?.sidecarContext?.isSidecar;
    const markAsCompleteDisabled = isTaskWithType(props.task, EntityType.VITAL) || isTaskWithType(props.task, EntityType.FORM);

    const handleAction = (code: string, task: ITask) => {
      const action = getActionType(code);
      props.onTaskAction?.(code, task, action);
    }

    const getActionType = (code: string): BottomViewAction => {
      if (props.boardType === BoardType.Priority) {
        return BottomViewAction.updatePriority;
      } else {
        switch (code) {
          case TASK_STATUS_CODES.DELETE:
            return BottomViewAction.deleteTask;
          case TASK_PRIORITY_CODES.HIGH:
          case TASK_PRIORITY_CODES.LOW:
          case TASK_PRIORITY_CODES.MEDIUM:
            return BottomViewAction.updatePriority;
          case TASK_STATUS_CODES.ACCEPTED: return BottomViewAction.markAsIncomplete;
          case TASK_STATUS_CODES.MISSED: return BottomViewAction.markAsMissed;
          default: return BottomViewAction.markAsComplete;
        }
      }
    }
    return (
        <Popover
            overlayInnerStyle={{ borderRadius: 8}}
            overlayStyle={{ padding: 0 }}
            
            overlayClassName='task-actions-popover'
            placement='bottomRight'
            trigger="click"
            showArrow={false}
            content={() => {
                const obj = BoardType.Status === props.boardType
                    ? TASK_STATUS_CODES
                    : TASK_PRIORITY_CODES;
                let list = Object.keys(obj);
                const propertyKey = BoardType.Status === props.boardType ? 'status' : 'priority';
                const taskCode = props.task[propertyKey]?.code;
                if (markAsCompleteDisabled) {
                  list = list.filter(item => item.toLowerCase() !== TASK_STATUS_CODES.COMPLETED.toLowerCase());
                }
                if (!isAllowToDeleteTask(props.task)) {
                  list = list.filter(item => item.toLowerCase() !== TASK_STATUS_CODES.DELETE.toLowerCase());
                }
                return (
                    <>
                        {list.map((item, index: number) => {
                            const code = obj[item as keyof typeof obj];
                            const name = getColumnName(
                                BoardType.Status === props.boardType
                                    ? PropertyType.status
                                    : PropertyType.priority
                            )[code];

                            if (code === taskCode) {
                                return null;
                            }
                            return (
                                <>
                                    <Pressable
                                        key={item}
                                        style={index !== 0 ? styles.pressableWithPadding : undefined}
                                        onPress={async () => {
                                            if (code === TASK_STATUS_CODES.COMPLETED) {
                                                setCheckifAllSubTasksCompletedLoading(true)
                                                await props?.checkIfAllSubTasksAreCompleted(props.task)
                                                setCheckifAllSubTasksCompletedLoading(false)
                                            } else {
                                              handleAction(code, props.task);
                                            }
                                        }}
                                    >
                                        <HStack
                                            space={2}
                                            style={styles.hStack}
                                            alignItems='center'>
                                              <View>
                                                {getIconByAction(code)}
                                              </View>
                                              <Text size='smMedium' color={Colors.FoldPixel.GRAY400} style={styles.text}>
                                                {name}
                                              </Text>
                                            {(code === TASK_STATUS_CODES.COMPLETED && checkifAllSubTasksCompletedLoading) && <Spinner />}
                                        </HStack>
                                    </Pressable>
                                </>
                            );
                        })}
                    </>
                );
        }}
      >
        <View>
          < ThreeDotsSvg />
        </View>
        </Popover>
    )
}

const styles = StyleSheet.create({
  overlayInner: {
    borderRadius: 8,
  },
  overlay: {
    padding: 0,
  },
  pressableWithPadding: {
    paddingTop: 4,
  },
  hStack: {
    paddingHorizontal: 8,
    paddingVertical: 7.5,
  },
  text: {
    fontWeight: '400',
  },
});

export default CareDashboardTableActions