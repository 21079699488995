import {useContext} from 'react';
import {CommonDataContext} from '../../context/CommonDataContext';

enum UserSettingValue {
  True = 'True',
  False = 'False',
}

export const useUserSetting = (settingCode: string) => {
  const context = useContext(CommonDataContext);
  const userSettings = context.userSettings;
  return userSettings[settingCode]?.value === UserSettingValue.True;
};
