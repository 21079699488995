import {StyleSheet} from 'react-native';
import {Colors} from '../../../../../styles';

export const CarePlanDiffStyles = StyleSheet.create({
  titleStyle: {
    fontSize: 14,
    fontWeight: '500',
    color: Colors.FoldPixel.GRAY200,
  },
  mainSectionTitleStyle: {
    color: Colors.FoldPixel.GRAY200,
    textTransform: 'uppercase',
    fontSize: 12,
  },
  container: {
    backgroundColor: Colors.FoldPixel.GRAY50,
    borderWidth: 0.5,
    borderRadius: 12,
    borderColor: Colors.FoldPixel.GRAY150,
  },
  containerTitle: {
    color: Colors.FoldPixel.GRAY400,
    fontSize: 12,
    textTransform: 'uppercase',
    paddingHorizontal: 12,
    paddingVertical: 12,
  },
  divider: {
    height: 0.5,
    backgroundColor: Colors.FoldPixel.GRAY150,
    width: '100%',
  }
});
