import {useLazyQuery} from '@apollo/client';
import {Modal, Select, Spin, Tag} from 'antd';
import {debounce} from 'lodash';
import React, {useContext, useEffect} from 'react';
import {useIntl} from 'react-intl';
import {Text, View} from 'react-native';
import {UserQueries} from '../../../services';
import ContactsQueries from '../../../services/Contacts/ContactsQueries';
import {Colors} from '../../../styles';
import {IUserType} from '../../../utils/interfaces';
import {getContactTypeId} from '../../../utils/mlovUtils';
import {DisplayCardAvatar} from '../DisplayCard/DisplayCardAvatar';
import {removeDuplicatesByUuid} from './EmailDrawerCommonV2Utils';
import {
  IEmailRecipient,
  ISearchRecipientProps,
  ISearchRecipientRef,
} from './interfaces';
import {getAllowedUserPracticeLocationUuids, getBooleanFeatureFlag, getUserUUID, isContactConsentRequired} from '../../../utils/commonUtils';
import Feather from 'react-native-vector-icons/Feather';
import './EmailDrawerCommonV2Styles.scss';
import { CONFIG_CODES } from '../../../constants/AccountConfigConst';
import { isAccountConfigEnabled } from '../../../utils/configUtils';
import { USER_ACCESS_PERMISSION } from '../../RightSideContainer/UserAccess/UserAccessPermission';
import { MAIN_MENU_CODES } from '../../SideMenuBar/SideBarConst';
import {CommonDataContext} from '../../../context/CommonDataContext';
import FeatureFlags from '../../../constants/FeatureFlags.enums';
import {findArrayIntersection} from '../../../utils/arrUtils';
import {StyleSheet} from 'react-native';
const { confirm } = Modal;
const SearchRecipient = React.forwardRef<
  ISearchRecipientRef,
  ISearchRecipientProps
>((props, ref) => {
  const contextData = useContext(CommonDataContext);
  const moduleAccessLocationIds = props.moduleAccessLocationIds || [];
  const isCommunicationLocationHandlingEnabled = isAccountConfigEnabled(
    CONFIG_CODES.ENABLE_COMMUNICATION_LOCATION_HANDLING
  );
  const isMultiTenancyEnabled = getBooleanFeatureFlag(contextData.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED);
  const allowedLocationsForInbox = getAllowedUserPracticeLocationUuids(
    USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
    MAIN_MENU_CODES.INBOX
  );
  const allowedUserPracticeLocationUuids = isMultiTenancyEnabled && moduleAccessLocationIds?.length > 0
    ? findArrayIntersection(allowedLocationsForInbox, moduleAccessLocationIds)
    : allowedLocationsForInbox;
  const userUuid = getUserUUID();
  const [state, setState] = React.useState({
    searchResult: [] as IEmailRecipient[],
    selected: [] as IEmailRecipient[],
    isSearchLoading: false,
    searchString: '',
    users: [] as IEmailRecipient[],
    contacts: [] as IEmailRecipient[],
  });
  const [confirmingDeselect, setConfirmingDeselect] = React.useState(false);
  const intl = useIntl();
  const patientContactTypeId = getContactTypeId('CUSTOMER');
  const isToRecipientType = props.recipientType === 'to';
  const isConsentRequired = isContactConsentRequired();

  const [getContactByCondition] = useLazyQuery<{
    contacts: IEmailRecipient[];
  }>(ContactsQueries.GET_CONTACT_BY_CONDITION, {fetchPolicy: 'no-cache'});

  const [getUsersAndContactsByCondition] = useLazyQuery<{
    users: IEmailRecipient[];
    contacts: IEmailRecipient[];
  }>(UserQueries.GET_USERS_AND_CONTACTS_BY_CONDITION, {
    fetchPolicy: 'no-cache',
  });
  const handleToSearch = async (searchString: string) => {
    setState((prev) => {
      return {
        ...prev,
        isSearchLoading: true,
      };
    });
    const response = await getContactByCondition({
      variables: {
        where: {
          _or: [
            {person: {chosenName: {_ilike: `%${searchString}%`}}},
            {name: {_ilike: `%${searchString}%`}},
            {email: {_ilike: `%${searchString}%`}},
            {personContact: {value: {_ilike: `%${searchString}%`}}}
          ],
          _and: [
            {
              email: {
                _is_null: false,
              },
            },
            {
              email: {
                _neq: '',
              },
            },
            {
              personContact: {
                value: {
                  _is_null: false
                }
              }
            },
            {
              personContact: {
                value: {
                  _neq: ""
                }
              }
            }
          ],
          isDeleted: {_eq: false},
          ...(isConsentRequired
            ? {
                contactConsents: {
                  isDeleted: {
                    _eq: false,
                  },
                },
              }
            : {}),
          ...(isCommunicationLocationHandlingEnabled
            ? {
                contactPracticeLocations: {
                  isDeleted: {
                    _eq: false,
                  },
                  practiceLocationUuid: {
                    _in: allowedUserPracticeLocationUuids,
                  },
                },
              }
            : {}),
        },
      },
    });
    const contacts = response.data?.contacts || [];
    setState((prev) => {
      return {
        ...prev,
        isSearchLoading: false,
        contacts: contacts,
        searchResult: removeDuplicatesByUuid([
          ...contacts,
          ...prev.searchResult,
        ]),
      };
    });
  };

  const handleCCAndBCCSearch = async (searchString: string) => {
    setState((prev) => {
      return {
        ...prev,
        isSearchLoading: true,
      };
    });
    const response = await getUsersAndContactsByCondition({
      variables: {
        contactWhere: {
          _or: [
            {person: {chosenName: {_ilike: `%${searchString}%`}}},
            {name: {_ilike: `%${searchString}%`}},
            {email: {_ilike: `%${searchString}%`}},
            {personContact: {value: {_ilike: `%${searchString}%`}}}
          ],
          _and: [
            {
              email: {
                _is_null: false,
              },
            },
            {
              email: {
                _neq: '',
              },
            },
            {
              personContact: {
                value: {
                  _is_null: false
                }
              }
            },
            {
              personContact: {
                value: {
                  _neq: ""
                }
              }
            }
          ],
          isDeleted: {_eq: false},
          ...(isConsentRequired
            ? {
                contactConsents: {
                  isDeleted: {
                    _eq: false,
                  },
                },
              }
            : {}),
          ...(isCommunicationLocationHandlingEnabled
            ? {
                contactPracticeLocations: {
                  isDeleted: {
                    _eq: false,
                  },
                  practiceLocationUuid: {
                    _in: allowedUserPracticeLocationUuids,
                  },
                },
              }
            : {}),
          contactType: {
            typeId: {
              _neq: patientContactTypeId,
            },
          },
        },
        userWhere: {
          accountUsers: {isActive: {_eq: true}},
          _or: [
            {name: {_ilike: `%${searchString}%`}},
            {email: {_ilike: `%${searchString}%`}},
          ],
          isDeleted: {_eq: false},
          ...(isCommunicationLocationHandlingEnabled
            ? {
                _and: [
                  {
                    _or: [
                      {name: {_ilike: `%${searchString}%`}},
                      {email: {_ilike: `%${searchString}%`}},
                    ]
                  },
                  {
                    _or: [
                      {
                        userRoles: {
                          userRole: {
                            userRole: {
                              code: {_eq: 'GLOBAL_ADMIN'}
                            },
                          },
                        },
                      },
                      {
                        userPracticeLocations: {
                          isDeleted: {
                            _eq: false,
                          },
                          accountLocations: {
                            practiceLocation: {
                              uuid: {
                                _in: allowedUserPracticeLocationUuids,
                              },
                            },
                          },
                        },
                      },
                    ]
                  }
                ],
              }
            : {}
          ),
          _not: {
            userRoles: {
              userRole: {
                userRole: {code: {_in: ['WORKFLOW', 'CUSTOMER_SUCCESS']}},
              },
            },
          },
        },
      },
    });
    const users = response.data?.users || [];
    const contacts = response.data?.contacts || [];
    setState((prev) => {
      const searchResult = [...users, ...contacts, ...prev.searchResult];
      return {
        ...prev,
        users: users,
        contacts: contacts,
        searchResult: removeDuplicatesByUuid(searchResult),
        isSearchLoading: false,
      };
    });
  };

  const searchRecipientBasedOnType = (searchString: string) => {
    if (props.recipientType === 'to') {
      handleToSearch(searchString);
    } else {
      handleCCAndBCCSearch(searchString);
    }
  };

  const onMount = async () => {
    if (isToRecipientType) {
      await getContactRecipientData([...(props.selectedRecipients || [])]);
    } else {
      await getUserAndContactRecipients([...(props.selectedRecipients || [])]);
    }
  };

  const getUserAndContactRecipients = async (selectedRecipients: string[]) => {
    const response = await getUsersAndContactsByCondition({
      variables: {
        contactWhere: {
          uuid: {
            _in: selectedRecipients,
          },
          _and: [
            {
              email: {
                _is_null: false,
              },
            },
            {
              email: {
                _neq: '',
              },
            },
          ],
          isDeleted: {_eq: false},
          ...(isConsentRequired
            ? {
                contactConsents: {
                  isDeleted: {
                    _eq: false,
                  },
                },
              }
            : {}),
          ...(isCommunicationLocationHandlingEnabled
            ? {
                contactPracticeLocations: {
                  isDeleted: {
                    _eq: false,
                  },
                  practiceLocationUuid: {
                    _in: allowedUserPracticeLocationUuids,
                  },
                },
              }
            : {}),
          contactType: {
            typeId: {
              _neq: patientContactTypeId,
            },
          },
        },
        userWhere: {
          accountUsers: {isActive: {_eq: true}},
          uuid: {
            _in: [...(props.selectedRecipients || [])],
          },
          isDeleted: {_eq: false},
          _not: {
            userRoles: {
              userRole: {
                userRole: {code: {_in: ['WORKFLOW', 'CUSTOMER_SUCCESS']}},
              },
            },
          },
        },
      },
    });
    const contacts = response.data?.contacts || [];
    const users = response.data?.users || [];
    const selectedContacts = contacts.filter((item) =>
      props.selectedRecipients.includes(item.uuid)
    );
    const selectedUsers = users.filter((item) =>
      props.selectedRecipients.includes(item.uuid)
    );
    setState((prev) => {
      return {
        ...prev,
        selected: [...selectedUsers, ...selectedContacts],
        users: users,
        contacts: contacts,
        searchResult: [...users, ...contacts],
      };
    });
  };

  const getContactRecipientData = async (selectedRecipients: string[]) => {
    const response = await getContactByCondition({
      variables: {
        where: {
          uuid: {
            _in: selectedRecipients,
          },
          _and: [
            {
              email: {
                _is_null: false,
              },
            },
            {
              email: {
                _neq: '',
              },
            },
          ],
          isDeleted: {_eq: false},
          ...(isConsentRequired
            ? {
                contactConsents: {
                  isDeleted: {
                    _eq: false,
                  },
                },
              }
            : {}),
          ...(isCommunicationLocationHandlingEnabled
            ? {
                contactPracticeLocations: {
                  isDeleted: {
                    _eq: false,
                  },
                  practiceLocationUuid: {
                    _in: allowedUserPracticeLocationUuids,
                  },
                },
              }
            : {}),
        },
      },
    });
    const contacts = response.data?.contacts || [];
    const selected = contacts.filter((item) =>
      props.selectedRecipients.includes(item.uuid)
    );
    setState((prev) => {
      return {
        ...prev,
        selected: selected,
        searchResult: response.data?.contacts || [],
      };
    });
  };

  React.useEffect(() => {
    onMount();
  }, []);

  React.useImperativeHandle(ref, () => ({
    refetchToRecipients: getContactRecipientData,
  }));

  useEffect(()=> {
    if(isMultiTenancyEnabled && props.updateInternalState) {
      setState(prev=> {
        return {
          ...prev,
          selected: props.selectedToRecipient || []
        }
      })
    }
  },[props.selectedToRecipient?.[0]?.id,props.updateInternalState])

  const getSelectOption = (data: {
    item: IEmailRecipient;
    userType: IUserType;
  }) => {
    const {item} = data;
    return (
      <Select.Option
        key={item.uuid}
        value={item.uuid}
        label={item.name}
        email={item.email}
      >
        <View
          style={styles.container2}
        >
          <DisplayCardAvatar
            isLetterAvatarShow
            userData={{
              userId: item.id,
              userType: data.userType,
              userName: item.name,
              name: item.name,
            }}
          />

          <View
            style={styles.container}
          >
            <Text
              numberOfLines={1}
              ellipsizeMode="tail"
              style={styles.nameText}
            >
              {item.name}
            </Text>
            <Text
              numberOfLines={1}
              ellipsizeMode="tail"
              style={styles.emailText}
            >
              {item.email}
            </Text>
          </View>
        </View>
      </Select.Option>
    );
  };

  return (
    <Select
      size="large"
      {...(props?.singleMode ? {} : { mode: 'multiple' } )} 
      {...(props?.singleMode ? {optionLabelProp:"label"} : {})} 
      showSearch
      allowClear
      status={props.status}
      disabled={props.disabled}
      filterOption={false}
      loading={state.isSearchLoading}
      value={state.selected.map((item) => item.uuid)}
      onSearch={debounce(searchRecipientBasedOnType, 500)}
      onChange={(value: string | string[], option) => {
        if (!confirmingDeselect) {
          const selected = state.searchResult.filter((item) => {
            if (
              props.recipientType === 'to' &&
              isMultiTenancyEnabled &&
              !Array.isArray(value)
            ) {
              return value === item.uuid;
            } else {
              return value.includes(item.uuid);
            }
          });
        const duplicatesRemoved = removeDuplicatesByUuid(selected);
        if (!isMultiTenancyEnabled) {
          setState((prev) => {
            return {
              ...prev,
              selected: duplicatesRemoved,
            };
          });
        }
        props.onChange(duplicatesRemoved);
      }
      }}
      onDeselect={(value) => {
        if (props.recipientType === 'to') {
          if (state.selected?.length === 1) {
            setConfirmingDeselect(true); // Set the value to be deselected
            confirm({
              title: `You have removed ${state.selected?.[0]?.name} from the 'To' field. Do you want to proceed?`,
              onOk() {
                setState((prev) => {
                  return {
                    ...prev,
                    selected: [],
                  };
                });
                setConfirmingDeselect(false);
              },
              onCancel() {
                // setState((prev) => {
                //   return {
                //     ...prev,
                //     selected: state.selected,
                //   };
                // });
                const selected = state.searchResult.filter((item) =>
                  value.includes(item.uuid)
                );
                const duplicatesRemoved = removeDuplicatesByUuid(selected);
                setState((prev) => {
                  return {
                    ...prev,
                    selected: duplicatesRemoved,
                  };
                });
                props.onChange(duplicatesRemoved);
                setConfirmingDeselect(false); // Reset toDeselect state on cancel
              },
            });
          } else {
            setState((prev) => {
              return {
                ...prev,
                selected: prev?.selected?.filter(item => item.uuid !== value),
              };
            });
          }
        }
      }}
      placeholder={intl.formatMessage({
        id: 'recipientSelectPlaceholder',
      })}
      style={reactStyles.select}
      notFoundContent={state.isSearchLoading ? <Spin size="large" /> : <></>}
      tagRender={(tagProps) => {
        const selectedValue = tagProps.value;
        const selected = state.searchResult.find(
          (item) => item.uuid === selectedValue
        );
        const onPreventMouseDown = (event: any) => {
          event.preventDefault();
          event.stopPropagation();
        };
        return (
          <Tag
            onMouseDown={onPreventMouseDown}
            closable={!props.disabled}
            onClose={tagProps.onClose}
            style={reactStyles.margin3}
            className="email-recipient-custom-tag"
            icon={
              <Feather
                name="user"
                size={12}
                style={styles.featherIcon}
                color={Colors.Custom.Gray400}
              />
            }
          >
            <Text>{`${selected?.name} (${selected?.email})`}</Text>
          </Tag>
        );
      }}
    >
      {props.recipientType === 'to' ? (
        state.searchResult.map((item) => {
          return getSelectOption({item, userType: 'CONTACT'});
        })
      ) : (
        <>
          {state.users.length > 0 ? (
            <Select.OptGroup
              label={
                <View
                  style={styles.padding10}
                >
                  <Text
                    style={styles.text}
                  >
                    {intl.formatMessage({
                      id: 'users',
                    })}
                  </Text>
                </View>
              }
            >
              {state.users.map((item) => {
                return getSelectOption({item, userType: 'USER'});
              })}
            </Select.OptGroup>
          ) : (
            <></>
          )}

          {state.contacts.length > 0 ? (
            <Select.OptGroup
              label={
                <View
                  style={styles.padding10}
                >
                  <Text
                    style={styles.text}
                  >
                    {intl.formatMessage({
                      id: 'contacts',
                    })}
                  </Text>
                </View>
              }
            >
              {state.contacts.map((item) => {
                return getSelectOption({item, userType: 'CONTACT'});
              })}
            </Select.OptGroup>
          ) : (
            <></>
          )}
        </>
      )}
    </Select>
  );
});

export default SearchRecipient;

const styles = StyleSheet.create({
  container: {
    marginLeft: 10,
    display: 'flex',
    flexDirection: 'column',
  },
  nameText: {
    fontSize: 16,
    color: 'black',
  },
  padding10: {
    padding: 10,
  },
  emailText: {
    fontSize: 14,
    color: Colors.FoldPixel.GRAY300,
  },
  container2: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    padding: 10,
  },
  text: {
    color: Colors.FoldPixel.GRAY400,
    fontSize: 16,
  },
  featherIcon: {marginRight: 5},
});

const reactStyles: Record<string, React.CSSProperties> = {
  select: {width: '100%'},
  margin3: {margin: 3},

}
