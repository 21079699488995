import React from "react";

const NoDataFileIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="51"
      height="51"
      fill="none"
      viewBox="0 0 51 51"
    >
      <g clipPath="url(#clip0_1843_55131)">
        <path
          fill="#E4E7EC"
          d="M28.39 3.43c-.8 0-1.425.654-1.425 1.445v.059c0 .79.644 1.425 1.426 1.425h20.644c.81 0 1.465-.654 1.465-1.464 0-.811-.654-1.465-1.465-1.465H28.391z"
        ></path>
        <path
          fill="#E4E7EC"
          d="M24.035 4.895A4.389 4.389 0 0019.641.5H4.406A3.905 3.905 0 00.5 4.406V33.41a3.905 3.905 0 003.906 3.906h6.514c.742-7.392 6.992-13.183 14.58-13.183s13.838 5.79 14.58 13.183h6.514A3.905 3.905 0 0050.5 33.41V13.195a3.905 3.905 0 00-3.906-3.906H25.89a1.855 1.855 0 01-1.856-1.855v-2.54z"
        ></path>
        <path
          fill="#DCC8F5"
          d="M37.218 38.4c-.205-6.152-5.185-11.133-11.328-11.328-6.767-.225-12.324 5.332-12.1 12.1.196 6.142 5.186 11.123 11.329 11.328 6.768.224 12.324-5.332 12.1-12.1zm-6.68 3.35a1.456 1.456 0 010 2.07 1.456 1.456 0 01-2.07 0L25.5 40.861 22.53 43.83a1.456 1.456 0 01-2.07 0 1.456 1.456 0 010-2.07l2.968-2.97-2.968-2.968a1.456 1.456 0 010-2.07 1.456 1.456 0 012.07 0L25.5 36.72l2.968-2.968a1.456 1.456 0 012.07 0 1.456 1.456 0 010 2.07l-2.958 2.959 2.959 2.969z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1843_55131">
          <path
            fill="#fff"
            d="M0 0H50V50H0z"
            transform="translate(.5 .5)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default React.memo(NoDataFileIcon);
