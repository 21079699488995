import React from 'react';
import { View, StyleSheet } from 'react-native';
import { debounce } from 'lodash';
import { Colors } from '../../../../styles';
import {
  Pressable, VStack,
  Text,
  Tooltip,
  FlatList,
  Divider
} from 'native-base';
import { Drawer, Popover, Select, Spin } from 'antd';
import { NoTagsSvg } from '../../../common/Svg';
import { ModalActionTitle } from '../../../common/ModalActionTitle/ModalActionTitle';
import { BUTTON_TYPE } from '../../../../constants';
import {
  GROUP_TYPE_CODES, CARE_TEAM_MEMBER_TYPE
} from '../../../../constants/MlovConst';
import { ModalActionAntSelect } from '../../../common/ModalActionCommonComponent/ModalActionAntSelect';
import { IUser } from '../../../../Interfaces';
import ConfirmationModal from './components/ConfirmationModal';
import { IProps, IUserSearch } from './interfaces';
import {
  formatRoleArray, getUserItemPopoverAction
} from './CareTeamUtils';
import CareTeamLoader from './components/CareTeamLoader';
import Feather from 'react-native-vector-icons/Feather';
import CareTeamIcon from '../../../common/Svg/CareTeamIcon';
import { DisplayText } from '../../../common/DisplayText/DisplayText';
import { AddActionView } from '../../../common/Buttons/AddActionView';
import InstantChat from '../../../common/ChatDrawer/InstantChat';
import { COMMON_ACTION_CODES } from '../../../../constants/ActionConst';
import { CARE_TEAM_USER_ACTION } from './constants';
import CareTeamMemberCard from './components/CareTeamMemberCard';

const {Option} = Select;


import AddOrUpdateTask from '../../../common/AddTask/AddOrUpdateTask';
import { ParticipantType } from '../../../common/CalendarWidget/ParticipantAutoComplete/ParticipantEnum';
import CareTeamTypeUserSelect from './components/CareTeamTypeUserSelect';
import CareTeamMemberList from './components/CareTeamMemberDrawer';
import useCareTeam from './useCareTeam';
import { useIntl } from 'react-intl';


const CareTeamView = (props: IProps) => {
  const {
    careTeamMemberTypes,
    isElation,
    selectedUserForEdit,
    mainContentLoading,
    currentTeam,
    careTeamMemberTypesList,
    selectedUserAndAction,
    initialUserAction,
    deleteConfirmation,
    isOpen,
    saveCareTeamLoading,
    selectedMemberType,
    drawerListLoading,
    searchUserLoading,
    selectedValue,
    userData,
    userLoading,
    agentsData,
    isCareProgramEnabled,
    handleDeleteConfirmation,
    handleSelectUserForEdit,
    onActionPressed,
    getUserList,
    onDeleteConfirmationCancel,
    handleDelete,
    onClose,
    handleDrawer,
    handleCareTeamSave,
    getCareTeamMemberTypeOptions,
    onSelectMemberType,
    setUserData,
    onSearch,
    handleUserSelect,
  } = useCareTeam({
    contactUUID: props?.contactData?.uuid,
    contactAccountLocationId:
      props?.contactData?.contactPracticeLocations?.[0]?.accountLocation?.uuid,
  });


  const intl = useIntl();

  const getActionsView = (member: any): JSX.Element | undefined => {
    const actions = getUserItemPopoverAction({
      user: member,
      careTeamMemberTypes,
      CARE_TEAM_MEMBER_TYPE,
      isElation,
      isCareProgramEnabled
    });
    if (!actions.length) return;

    return (
      <>
        <Popover
          overlayInnerStyle={antdStyles.drawerContainerOverlayInnerStyle}
          overlayStyle={antdStyles.drawerContainerOverlayStyle}
          overlay={antdStyles.drawerContainerOverlay}
          style={antdStyles.drawerContainer}
          placement={'bottom'}
          trigger="click"
          visible={member.id === selectedUserForEdit}
          onVisibleChange={(visible) =>
            handleSelectUserForEdit(visible ? member.id : undefined)
          }
          content={
            <FlatList
              data={getUserItemPopoverAction({
                user: member,
                careTeamMemberTypes,
                CARE_TEAM_MEMBER_TYPE,
                isElation,
                isCareProgramEnabled,
              })}
              ItemSeparatorComponent={Divider}
              renderItem={({item}) => (
                <Pressable
                  onPress={() => {
                    onActionPressed(item.action, member);
                  }}
                  py={0.5}
                >
                  <Text>{intl.formatMessage({id: item.title})}</Text>
                </Pressable>
              )}
              keyExtractor={(item) => item.title}
            />
          }
        >
          <Feather
            name="more-vertical"
            size={18}
            onPress={() =>
              handleSelectUserForEdit(
                selectedUserForEdit ? undefined : member.id
              )
            }
          />
        </Popover>
      </>
    );
  };

 

  const renderContent = () => {
    return (
      <View style={styles.contentContainer}>
        {mainContentLoading ? (
          <CareTeamLoader key={'CareTeamList'} />
        ) : currentTeam && currentTeam?.length > 0 ? (
          getUserList(currentTeam)
            .filter((team: IUser) => !team.isLocal)
            ?.map((member: any) => {
              return (
                <View key={`CareTeamUser_${member?.id}`}>
                <CareTeamMemberCard
                  getUserCareTeamRoles={(userId: string) => {
                    const user = currentTeam.find(
                      (user: IUser) => user.uuid === userId
                    );
                     const roles =  user?.memberType?.map?.(
                        (mem) =>
                          `${
                            careTeamMemberTypesList.find(
                              (type) => type.id === mem
                            )?.value
                          }`
                      );
                     

                    return roles || []
                  }}
                  name={member.name}
                  key={member.id}
                  id={member.id}
                  uuid={member.uuid}
                  profileUrl={
                    member?.agent?.thumbnail.includes('d=404')
                      ? ''
                      : member?.agent?.thumbnail
                  }
                  actionView={getActionsView(member)}
                  roles={formatRoleArray(member.userRoles)}
                />
                </View>
              );
            })
        ) : (
          <NoTagsSvg titleId="noCareTeam" />
        )}
      </View>
    );
  };

  return (
    <>
      {selectedUserAndAction.code ===
        CARE_TEAM_USER_ACTION.START_CONVERSATION && (
        <InstantChat
          isDrawerVisible={
            selectedUserAndAction.code ===
            CARE_TEAM_USER_ACTION.START_CONVERSATION
          }
          groupType={GROUP_TYPE_CODES.INTERNAL}
          secondaryUserUUID={selectedUserAndAction.uuid}
          onActionPerformed={(actionCode) => {
            if (actionCode === COMMON_ACTION_CODES.CLOSE_MODAL) {
              handleSelectUserForEdit(initialUserAction.code);
            }
          }}
        />
      )}
      {selectedUserAndAction.code === CARE_TEAM_USER_ACTION.ADD_TASK && (
        <AddOrUpdateTask
          isVisible={
            selectedUserAndAction.code === CARE_TEAM_USER_ACTION.ADD_TASK
          }
          assignee={{
            value: selectedUserAndAction.uuid,
            label: selectedUserAndAction.userName,
            key: selectedUserAndAction.uuid,
            type: ParticipantType.staff,
            details: selectedUserAndAction,
          }}
          onCancel={() => {
            handleSelectUserForEdit(initialUserAction.code);
          }}
        />
      )}
      <ConfirmationModal
        isDelete={true}
        isOpen={deleteConfirmation.modal}
        onCancel={onDeleteConfirmationCancel}
        onConfirm={() => handleDelete(deleteConfirmation.id, false)}
      />
      <View style={styles.mainContainer}>
        <View style={styles.headerContainer}>
          <View style={styles.headerContent}>
            <View style={styles.iconTextContainer}>
              <View style={styles.iconContainer}>
                <CareTeamIcon />
              </View>
              <DisplayText
                size={'xlBold'}
                extraStyles={{color: Colors.primary['700'] || ''}}
                textLocalId={'careTeam'}
              />
            </View>
            <Tooltip
              label={intl.formatMessage({
                id: 'addCareTeam',
              })}
              placement={'top'}
            >
              <Pressable
                onPress={() => {
                  handleDrawer(true);
                }}
              >
                <AddActionView />
              </Pressable>
            </Tooltip>
          </View>
        </View>
        {renderContent()}
      </View>
      {isOpen && 
        <Drawer
        open={isOpen}
        width={600}
        title={
          <ModalActionTitle
            title={'Add Care Team'}
            titleSize={24}
            buttonList={[
              {
                show: true,
                id: 1,
                btnText: intl.formatMessage({
                  id: 'cancel',
                }),
                size: 'sm',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
                isTransBtn: false,
                onClick: () => {
                  onClose();
                },
              },
              {
                show: true,
                isLoading: saveCareTeamLoading,
                id: 2,
                isDisabled: saveCareTeamLoading,
                btnText: intl.formatMessage({
                  id: 'save',
                }),
                textColor: Colors.Custom.mainPrimaryPurple,
                variant: BUTTON_TYPE.PRIMARY,
                isTransBtn: false,
                onClick: () => {
                  handleCareTeamSave();
                },
              },
            ]}
          />
        }
      >
        <ConfirmationModal
          isDelete={true}
          isOpen={deleteConfirmation.modal}
          onCancel={onDeleteConfirmationCancel}
          onConfirm={() => handleDelete(deleteConfirmation.id, false)}
        />
        <VStack>
          {isCareProgramEnabled && (
            <CareTeamTypeUserSelect
              options={getCareTeamMemberTypeOptions()}
              value={selectedMemberType.length ? selectedMemberType : undefined}
              onChange={(value: string) => {
                onSelectMemberType(value);
                setUserData([]);
              }}
              disabled={!isCareProgramEnabled}
            />
          )}
          {selectedMemberType.length > 0 && (
            <ModalActionAntSelect
              filterIds={currentTeam?.map((item: IUser) => item.uuid)}
              label={intl.formatMessage({
                id: 'searchAndSelectTeamMembers',
              })}
              style={{width: '100%', marginTop: 8}}
              allowClear={true}
              showSearch={true}
              leftMargin={'0'}
              onSearch={debounce(onSearch, 500)}
              labelInValue={true}
              filterOption={false}
              value={selectedValue?.label}
              disabled={userLoading}
              loading={userLoading || searchUserLoading}
              notFoundContent={
                userLoading || searchUserLoading ? (
                  <Spin size="small" />
                ) : (
                  intl.formatMessage({
                    id: 'NoData',
                  })
                )
              }
              isRequired={false}
              placeholder={'Type to search'}
              onChange={handleUserSelect}
              optionsArray={getUserList(userData).map((member: any, index) => {
                return (
                  <Option
                    label={member.name}
                    value={member.uuid}
                    key={member.uuid}
                  >
                    <CareTeamMemberCard
                      key={member.id}
                      index={index}
                      name={member.name}
                      id={member.id}
                      uuid={member.uuid}
                      roles={formatRoleArray(member.userRoles)}
                      isLocal={false}
                      isSearchComponent={true}
                      profileUrl={
                        member?.agent?.thumbnail.includes('d=404')
                          ? ''
                          : member?.agent?.thumbnail
                      }
                    />
                  </Option>
                );
              })}
              extraStyle={{flex: 1}}
            />
          )}

          <View style={styles.drawerListContainer}>
            {drawerListLoading ? (
              <CareTeamLoader key={'CareTeamDrawer'} />
            ) : (
              <VStack>
                <CareTeamMemberList
                  onActionPressed={onActionPressed}
                  selectedMemberType={selectedMemberType}
                  currentTeam={currentTeam as IUser[]}
                  localTeam={
                    currentTeam
                      ? currentTeam?.filter((team: IUser) => team.isLocal)
                      : []
                  }
                  handleDelete={handleDelete}
                  handleDeleteConfirmation={handleDeleteConfirmation}
                  agentsData={agentsData}
                  locationId={
                    props?.contactData?.contactPracticeLocations?.[0]
                      ?.accountLocation?.uuid
                  }
                />
              </VStack>
            )}
          </View>
        </VStack>
      </Drawer>}
    </>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor: Colors.Custom.Gray50,
    shadowColor: `rgba(16, 24, 40, 0.06)`,
    shadowRadius: 1,
    shadowOffset: {
      width: 1,
      height: 1,
    },
    shadowOpacity: 0.1,
  },
  headerContainer: {
    padding: 24,
  },
  headerContent: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  iconTextContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 4,
  },
  iconContainer: {
    marginRight: 10,
  },
  contentContainer: {
    backgroundColor: '#ffffff',
    marginHorizontal: 24,
    borderRadius: 8,
    shadowColor: 'rgba(16, 24, 40, 0.06)',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 1,
    shadowRadius: 2,
    paddingHorizontal: 16,
    paddingVertical: 12,
  },
  drawerListContainer: {
    width: '100%',
    marginTop: 20,
  },
});

const antdStyles: Record<string, React.CSSProperties> = {
  drawerContainer: {
    padding: 0,
    borderRadius: 16,
  },
  drawerContainerOverlay: {
    padding: 0,
  },
  drawerContainerOverlayStyle: {
    padding: 0,
  },
  drawerContainerOverlayInnerStyle: {
    padding: 0,
    borderRadius: 16,
  },
};

export default CareTeamView;
