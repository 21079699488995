import {Formio} from '@foldhealth/formiojs';
import BasicFields from '../../EditFormFields/BasicFields';
import ConditionalComponents from '../../EditFormFields/ConditionalFields';
import KeyField from '../../EditFormFields/KeyField';
import QuillConfig from '../../Builder/QuillConfig';
import ShareWithPatientFields from '../../EditFormFields/ShareWithPatientFields';

const SelectComponent = Formio.Components.components.select;

export default class NoteStatusComponent extends SelectComponent {
  static schema(...extend: any) {
    return SelectComponent.schema(
      {
        type: 'noteStatus',
        label: 'Status',
        key: 'noteStatus',
        dataSrc: 'values',
        clearOnHide: false,
        data: {
          values: [
            { label: 'Open', value: 'Open'},
            { label: 'In Progress', value: 'In Progress'},
            { label: 'Closed', value: 'Closed'}
            ]
          },
        ...extend
      }
    );
  }

  static get builderInfo() {
    return {
      title: 'Status',
      group: 'advanced',
      icon: 'calendar',
      weight: 2,
      documentation: '/userguide/#select',
      schema: NoteStatusComponent.schema(),
    };
  }

  static editForm = function () {
    return {
      key: 'display',
      components: [
        {
          type: 'oldtextfield',
          key: 'label',
          label: 'Label',
          input: true,
          validate: {
            required: true,
          },
        },
        ...ShareWithPatientFields,
        {
          type: 'textarea',
          input: true,
          key: 'description',
          label: 'Description',
          placeholder: 'Description for this field.',
          tooltip:
            'The description is text that will appear below the input field.',
          editor: 'quill',
          wysiwyg: QuillConfig,
        },,
        ...ConditionalComponents,
        ...KeyField,
      ],
    };
  }
}

Formio.Components.addComponent('noteStatus', NoteStatusComponent);
