import {Popover, Tooltip} from 'antd';
import {IContact} from '../../../RightSideContainer/TeamInbox/Conversations/interfaces';
import {Divider, FlatList, Pressable, Text, View} from 'native-base';
import {Colors} from '../../../../styles';
import Stack from '../../LayoutComponents/Stack';
import {DisplayText} from '../../DisplayText/DisplayText';
import {PERSON_ACTION_CODES} from '../../../PersonOmniView/PersonHeaderBar/PersonAction/PersonActionPopover/ActionConst';
import OutboundCallSmsView from '../../OutboundCallSmsView/OutboundCallSmsView';
import ThreeDotIconSvg from '../../../../assets/Icons/ThreeDotIconSvg';

interface ContactRelationCommunicationViewProps {
  actions: any[];
  onActionPerformed: (action: string) => void;
  isOutboundViewVisible?: boolean;
  contact?: IContact;
  handleActions: (actionCode: string, rowData?: any) => void;
  extraActions?: any[];
}

export const ContactRelationCommunicationView = (
  props: ContactRelationCommunicationViewProps
) => {
  const {actions, isOutboundViewVisible, contact, handleActions, extraActions} =
    props;

  const horizontalActions = actions.filter((action) => action.showHorizontal);
  const verticalActions = actions.filter((action) => !action.showHorizontal);

  const renderActionItemView = (item: any, index: number) => {
    const showHorizontal = item.showHorizontal;
    return (
      <Tooltip title={item.tooltip}>
        <Pressable
          disabled={item.isDisabled}
          flexDirection="row"
          alignItems="center"
          my={showHorizontal ? 0 : 2}
          // mx={showHorizontal ? 1 : 0}
          onPress={() => props.onActionPerformed(item.action)}
        >
          {item.icon}
          {!showHorizontal && (
            <Text
              color={
                Colors.Custom.Gray500}
              fontSize={14}
              fontWeight="400"
              marginLeft={3}
            >
              {item.btnText}
            </Text>
          )}

          {showHorizontal && (
            <Divider orientation="vertical" height={3} mx={3} width={'0.5px'} backgroundColor={Colors.Custom.Gray400}/>
          )}
        </Pressable>
      </Tooltip>
    );
  };

  const renderPopoverView = (actions: any[]) => {
    return (
      <Stack direction="column">
        {actions.length > 0 && (
          <>
            {renderActionMenu(actions, false)}
          </>
        )}
        {extraActions && extraActions?.length > 0 ? (
          <>
            {actions.length > 0 && <Divider marginTop={2} marginBottom={3} />}
            <DisplayText
              textLocalId="ACTIONS"
              extraStyles={{
                color: Colors.FoldPixel.GRAY200,
                marginBottom: 4,
              }}
            />
            {renderActionMenu(extraActions, false)}
          </>
        ) : (
          <></>
        )}
      </Stack>
    );
  };

  const renderActionMenu = (actions: any[], horizontal: boolean) => {
    return (
      <FlatList
        data={actions}
        horizontal={horizontal}
        keyExtractor={(item) => item.btnText}
        renderItem={({item, index}) => (
          <>
            {item.action === PERSON_ACTION_CODES.CALL ? (
              <OutboundCallSmsView
                isHideSms={true}
                showOnlyCallNumbers={true}
                visible={!!isOutboundViewVisible}
                selectedContactData={null}
                content={<>{renderActionItemView(item, index)}</>}
                handleAction={(actionCode: string, rawData?: any) => {
                  handleActions(actionCode, rawData);
                }}
              />
            ) : (
              renderActionItemView(item, index)
            )}
          </>
        )}
      />
    );
  };

  return (
    <Stack direction="row">
      {renderActionMenu(horizontalActions, true)}
      <Popover
        overlayClassName="custom-popover-styles"
        showArrow={true}
        destroyTooltipOnHide
        placement="bottomRight"
        content={renderPopoverView(verticalActions)}
        trigger="click"
        overlayInnerStyle={{
          borderRadius: 16,
          width: 140,
          padding: 0,
        }}
        style={{cursor: 'pointer'}}
      >
        <View cursor={'pointer'}>
          <ThreeDotIconSvg
            style={{padding: 8, margin: 0}}
            color={Colors.FoldPixel.GRAY200}
          />
        </View>
      </Popover>
    </Stack>
  );
};
