import {useLazyQuery} from '@apollo/client';
import {Popover, Tooltip, notification} from 'antd';
import {
  HStack,
  Spinner,
  Text,
  VStack,
  View,
  useMediaQuery,
  useToast,
} from 'native-base';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {useIntl} from 'react-intl';
import {NavigateFunction, useNavigate} from 'react-router-dom';
import {IInfoOnMessageWindowHeader, IUser} from '../../../../../Interfaces';
import {
  CONVERSATION_CONST,
  CONVERSATION_STATUS,
  GROUP_MEMBER_TYPE,
  HEADER_ACTION_ITEM,
  MLOV_CATEGORY,
  PERSON_TYPES,
  THIRTEEN_INCH_WIDTH,
  eleven_inch_width,
  getInActiveContactError,
} from '../../../../../constants';
import {CONFIG_CODES} from '../../../../../constants/AccountConfigConst';
import {
  COMMON_ACTION_CODES,
  CONVERSATION_ACTION_CODES,
  GROUP_ACTION_CODES,
} from '../../../../../constants/ActionConst';
import {
  CONVERSATION_LOCAL_EVENT_CODES,
  SUPPORTED_EVENT_CODE,
  WEB_SOCKET_EVENT_CODE,
} from '../../../../../constants/WebSocketConst';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import {LeadQueries} from '../../../../../services';
import {getCurrentPatientDeceasedData} from '../../../../../services/CommonService/AidBoxService';
import ConversationsQueries from '../../../../../services/Conversations/ConversationsQueries';
import {Colors} from '../../../../../styles';
import {TestIdentifiers, testID} from '../../../../../testUtils';
import {localBroadcastEvent} from '../../../../../utils/CustomEventHandler';
import {EventBus} from '../../../../../utils/EventBus';
import {getEHRUrl, getEhrConfig} from '../../../../../utils/capabilityUtils';
import {
  getAllowedUserPracticeLocationUuids,
  getPrimaryGenderCode,
  getUserId,
  getUserUUID,
  isActiveContact,
  isChannelEmail,
  isChannelTwillioSms,
  notListedPronounId,
  showInfoOnMessageHeader,
} from '../../../../../utils/commonUtils';
import {ToastType, showToast} from '../../../../../utils/commonViewUtils';
import {isAccountConfigEnabled} from '../../../../../utils/configUtils';
import {IUserType} from '../../../../../utils/interfaces';
import {getMlovListFromCategory} from '../../../../../utils/mlovUtils';
import {AssignMessageTypeButton} from '../../../../AssignMessageTypeButton';
import {ILocalAssigneeChangedEventData} from '../../../../BodyContainer/interface';
import {useContainerDimensions} from '../../../../CustomHooks/ContainerDimensionHook';
import {withMiniContactViewHOC} from '../../../../MiniContactViewHOC';
import {PatientInfoBanner} from '../../../../PatientInfoBanner/PatientInfoBanner';
import {getCurrentEHR} from '../../../../PersonOmniView/MiddleContainer/PatientNotes/PatientNotesHelper';
import {EHRName} from '../../../../PersonOmniView/MiddleContainer/PatientNotes/interfaces';
import {IAvailableEhr} from '../../../../PersonOmniView/MiddleContainer/interfaces';
import {
  getViewInEHRLink,
  redirectToNewTab,
} from '../../../../SideCar/SidecarUtils';
import {MAIN_MENU_CODES} from '../../../../SideMenuBar/SideBarConst';
import {ConversationAttachments} from '../../../../common/ChatUI/MessageBox/ConversationAttachments';
import AssignActionSvg from '../../../../common/Svg/AssignActionSvg';
import MenuHorizontalIcon from '../../../../common/Svg/MenuHorizontalIcon';
import ArchiveActionSvg from '../../../../common/Svg/PersonActionSvgIcons/ArchiveActionSvg';
import SearchActionSvg from '../../../../common/Svg/PersonActionSvgIcons/SearchActionSvg';
import UnArchiveActionSvg from '../../../../common/Svg/PersonActionSvgIcons/UnArchiveActionSvg';
import SideCarBackSvg from '../../../../common/Svg/SideCarBackSvg';
import {USER_ACCESS_PERMISSION} from '../../../UserAccess/UserAccessPermission';
import ConversationActionView from '../ConversationActionView/ConversationActionView';
import {getGroupName} from '../ConversationChannelNew/ConversationSidebarUtils';
import CreateAllConversationPopup from '../ConversationChannelNew/CreateAllConversationPopup';
import {IContentAttributesAssigneeData} from '../ConversationChannelNew/Interfaces';
import {CHANNEL_TYPE_CODE, CONVERSATION_TAB_CODES} from '../ConversationConst';
import {assignStatusValue} from '../ConversationContainer/ConversationConst';
import {getAvatarFullName} from '../ConversationContainer/ConversationList/ConversationListItemUtils';
import {
  CreateNoteType,
  IContact,
  IConversationData,
  IConversationInbox,
} from '../interfaces';
import {CopyMsgView} from './CopyMsgView';
import {CreateNoteView} from './CreateNoteView';
import MemberListByContactValue from './MemberListByContactValue';
import {
  canShowArchive,
  canShowAssignment,
  getAvatarNameAndType,
  getButtonActionObject,
  getEventCodeFromParentCode,
  getGroupMemberList,
  getMessagingHeaderInfoInJSON,
  getPatientAgeAndGenderJson,
  getPatientAgeAndGenderObject,
  getPatientAgeAndGenderStr,
  isBroadCastAndPrivateGroup,
  isBroadCastGroup,
  isGroupConversation,
  isGroupMembersAvailable,
  isInternalChat,
  isPrivateGroup,
  isUserGroupsGroup,
  messageDataKeysConverter,
  setHeaderTitle,
} from './MessagingUtils';
import CancelView from './MessagingWindowHeaderComponents/CancelView';
import CreateTaskView from './MessagingWindowHeaderComponents/CreateTaskView';
import SelectChatView from './MessagingWindowHeaderComponents/SelectChatView';
import {cssProperties, styles} from './MessagingWindowStyles';
import {IMessageTopHeaderInfo} from './interface';
import {FHTooltip} from '../../../../common/FHTooltip';
import { usePermissions } from '../../../../CustomHooks/usePermissions';

interface IProps {
  contactData: IContact;
  conversation?: IConversationData;
  pcpUserData?: IUser;

  moduleCode: string;
  parentCode?: string;
  selectedInboxTypeCode?: string;
  copyMessageStateCode: string;

  isInstantChatView: boolean;
  isDisplayActions: boolean;
  defaultActiveStatus?: boolean;
  showInfoIconInHeader?: boolean;
  isDetailsContainerVisible?: boolean;
  isSelectChatOn?: boolean;
  onSelectChatAction?: boolean;
  isSeachMsgContainerVisible?: boolean;
  showNavigationBack?: boolean;
  isSideDetailVisible: boolean;
  isMessageCopy: boolean;
  messagingWindowHeaderLoading: boolean;

  onActionPerformed?: (actionCode: string, selectedData: unknown) => void;
  navigateOrOpenContactIdDrawer?: (
    contactId?: string,
    additionalData?: any
  ) => void;
  handleSelectChat?: () => void;
  navigationBackPressed?: () => void;
  handleCopyToClipBoard: () => void;
  handleCreateNoteClick: (code?: CreateNoteType) => void;
  handleAssignMessageTypeButton: () => void;
  onCreateTaskBtnPress: () => void;
  onPressCancelBtn: () => void;

  headerContainerRef?: any;
}

// #region utilities
const handleNavigateToOmniView = ({
  contactId,
  contactTypeCode,
  conversationInbox,
  navigate,
  navigateOrOpenContactIdDrawer,
}: {
  contactId: number;
  contactTypeCode: string;
  conversationInbox: IConversationInbox;
  navigate: NavigateFunction;
  navigateOrOpenContactIdDrawer?: (
    contactId?: string | undefined,
    additionalData?: any
  ) => void;
}) => {
  if (contactTypeCode == PERSON_TYPES.CUSTOMER) {
    navigateOrOpenContactIdDrawer?.(contactId?.toString(), {
      state: conversationInbox,
    });
  } else if (contactTypeCode == PERSON_TYPES.LEAD) {
    navigate(`/members/prospect/${contactId}`);
  } else {
    navigate(`/members/supporting-memeber/${contactId}`);
  }
};

const getMessageHeaderInfo = ({
  showInfoOnHeaderData,
  contactData,
  userUuid,
  ehrConfig,
  selectedInboxTypeCode,
  conversation,
  isInstantChatView,
  contactDeceasedDate,
  pcpUserData,
  channelType,
  avatarNameAndType,
}: {
  showInfoOnHeaderData: IInfoOnMessageWindowHeader;
  contactData: IContact;
  userUuid: string;
  ehrConfig: IAvailableEhr;
  selectedInboxTypeCode?: string;
  conversation?: IConversationData;
  isInstantChatView: boolean;
  contactDeceasedDate: string;
  pcpUserData?: IUser;
  channelType: string;
  avatarNameAndType:
    | {
        userType: IUserType;
        name: string;
      }
    | {
        userType: IUserType;
        name?: undefined;
      };
}) => {
  if (showInfoOnHeaderData?.SHOW_PCP) {
    const headerData: IMessageTopHeaderInfo = getMessagingHeaderInfoInJSON(
      pcpUserData || '',
      showInfoOnHeaderData,
      contactData,
      userUuid,
      ehrConfig,
      selectedInboxTypeCode,
      conversation,
      isInstantChatView,
      {
        deceasedDate: contactDeceasedDate,
      }
    );
    return {
      ...headerData,
      subtitleData: JSON.stringify(headerData.subtitleData),
    };
  } else {
    const groupMembers = conversation?.groupConversation?.groupMembers || [];
    const groupMemberData = getGroupMemberList(groupMembers);
    const memberList = groupMemberData.groupMembersInfoList;
    let contactTypeStr, contactTypeData;
    if (conversation) {
      contactTypeStr = getPatientAgeAndGenderStr(
        conversation,
        ehrConfig,
        pcpUserData?.name || '',
        undefined,
        {
          deceasedDate: contactDeceasedDate,
        }
      );
      contactTypeData = getPatientAgeAndGenderObject(
        conversation,
        isInstantChatView,
        pcpUserData?.name || ''
      );
    }
    const choosenName =
      !isBroadCastGroup(conversation as IConversationData) &&
      showInfoOnHeaderData?.SHOW_CHOSEN_NAME &&
      contactData?.person?.chosenName?.length
        ? ' (' + contactData?.person?.chosenName + ')'
        : '';

    if (isPrivateGroup(conversation as any)) {
      if (conversation?.groupConversation?.name) {
        return {
          headerString: setHeaderTitle(conversation) + choosenName,
          subtitleData: contactTypeStr,
          memberSubtitleData: contactTypeData,
        };
      } else {
        return {
          headerString: getAvatarFullName(
            conversation as any,
            true,
            '',
            contactData
          ),
          subtitleData: contactTypeStr,
          memberSubtitleData: contactTypeData,
        };
      }
    } else if (isInternalChat(conversation as any)) {
      let headerStringName = '';
      if (memberList.length === 2 && !conversation?.groupConversation?.name) {
        memberList.forEach((userData: any) => {
          if (userData?.uuid !== userUuid) {
            headerStringName = userData?.name;
          }
        });
      }
      return {
        headerString:
          headerStringName ||
          conversation?.groupConversation?.name ||
          avatarNameAndType?.name,
        subtitleData: `${groupMemberData?.groupMembersInfoList?.length} ${
          groupMemberData.groupMembersInfoList.length > 1 ? 'Members' : 'Member'
        }`,
        memberSubtitleData: contactTypeData,
      };
    } else if (isChannelTwillioSms(channelType)) {
      return {
        headerString:
          conversation?.groupConversation?.name &&
          conversation?.groupConversation?.name + choosenName,
        subtitleData: contactTypeStr,
      };
    } else if (isChannelEmail(selectedInboxTypeCode || '')) {
      return {
        headerString:
          (conversation?.groupConversation?.name ||
            CONVERSATION_CONST.GROUP_CHAT) + choosenName,
        subtitleData: contactTypeStr,
      };
    } else {
      return {
        headerString:
          (conversation?.groupConversation?.name ||
            CONVERSATION_CONST.GROUP_CHAT) + choosenName,
        subtitleData: `${groupMemberData?.groupMembersInfoList?.length} ${
          groupMemberData.groupMembersInfoList.length > 1 ? 'Members' : 'Member'
        }`,
      };
    }
  }
};

const findPatient = (members: any) => {
  const patient = members.filter((member: any) => {
    return member.groupUserType.code === GROUP_MEMBER_TYPE.PATIENT;
  });
  return patient[0];
};

const isPatientContactType = (contactTypeCode: string) => {
  return contactTypeCode === PERSON_TYPES.CUSTOMER;
};

// #endregion utilities

const MessagingWindowHeaderV2Component: React.FC<IProps> = (props) => {
  const {conversation, pcpUserData, messagingWindowHeaderLoading} = props;

  // #region states
  const [contactData, setContactData] = useState<IContact>(
    props.contactData || ({} as IContact)
  );
  const [loadingArchive, setLoadingArchive] = useState(false);
  const [conversationAttachmentDrawer, setConversationAttachmentDrawer] =
    useState(false);
  const [assignUserListPopup, setAssignUserListPopup] = useState(false);
  const [moreActionPopup, setMoreActionPopup] = useState(false);
  const [isNoContactFound, setIsNoContactFound] = useState(false);
  const [isSeachMsgContainerVisible, setIsSeachMsgContainerVisible] = useState(
    props.isSeachMsgContainerVisible
  );
  const [contactDeceasedDate, setContactDeceasedDate] = useState('');
  const [assignedUserData, setAssignedUserData] = useState<any>({});
  const [isDetailsContainerVisible, setIsDetailsContainerVisible] = useState(
    props.isDetailsContainerVisible
  );
  const [accessToViewPatientProfile, setAccessToViewPatientProfile] =
    useState(true);

  const [button, setButton] = useState({} as any);
  // #endregion

  // #region other hooks
  const headerContainerRef: any = useRef();
  const navigate = useNavigate();
  const mlovData = useContext(CommonDataContext);
  const toast = useToast();
  const intl = useIntl();
  const [is13InchScreen, is11InchScreen] = useMediaQuery([
    {maxWidth: THIRTEEN_INCH_WIDTH},
    {maxWidth: eleven_inch_width},
  ]);
  // #endregion

  // #region custom hooks
  const userUuid = getUserUUID();
  const userId = getUserId();
  const showInfoOnHeaderData = showInfoOnMessageHeader();
  const {width, resetDimension} = useContainerDimensions(
    props?.headerContainerRef
  );
  const {check} = usePermissions();
  // #endregion

  // #region derived variables and states
  const assignUserName = assignedUserData?.name || '';
  const ehrConfig = getEhrConfig('', '');
  const isEmailConversation = isChannelEmail(
    conversation?.conversationInbox?.channelType || ''
  );
  const emailSubject = conversation?.additionalAttributes?.mail_subject || '';
  const genderCode = getPrimaryGenderCode(contactData);
  const groupName = getGroupName(conversation as IConversationData);
  const isPatientSidecarContext = mlovData.sidecarContext?.isPatient;
  const isSideCarContext = mlovData.sidecarContext?.isSidecar;
  const pronounList = getMlovListFromCategory(
    mlovData.MLOV,
    MLOV_CATEGORY.PRONOUN
  );
  const notListedPronounData = notListedPronounId(pronounList);
  const avatarNameAndType = getAvatarNameAndType(userUuid, conversation);
  const isCommunicationLocationHandlingEnabled = isAccountConfigEnabled(
    CONFIG_CODES.ENABLE_COMMUNICATION_LOCATION_HANDLING
  );
  const allowedUserPracticeLocationUuids = getAllowedUserPracticeLocationUuids(
    USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
    MAIN_MENU_CODES.INBOX
  );
  const isNavigateToOmniView = is11InchScreen || is13InchScreen;
  const channelType = conversation?.conversationInbox?.channelType || '';
  const isShowForSmsOrEmail =
    isChannelEmail(channelType) || isChannelTwillioSms(channelType)
      ? true
      : false;
  const messagingHeaderInfo = getMessageHeaderInfo({
    avatarNameAndType,
    channelType,
    contactData,
    contactDeceasedDate,
    conversation,
    ehrConfig,
    isInstantChatView: props.isInstantChatView,
    pcpUserData,
    selectedInboxTypeCode: props.selectedInboxTypeCode,
    showInfoOnHeaderData,
    userUuid,
  });

  const ehrURL = getEHRUrl(
    contactData?.contactPracticeLocations?.[0]?.accountLocation?.uuid,
    ''
  );

  const currentEHR = getCurrentEHR(conversation?.contactLocationUuid, '');
  const isAthena = currentEHR === EHRName.ATHENA;
  const contactTypeCode =
    contactData?.contactType?.contactType?.code ||
    props.contactData?.contactType?.contactType?.code;
  const contactTypeValue =
    contactData?.contactType?.contactType?.value ||
    props.contactData?.contactType?.contactType?.value;

    let accessCodeValue = '';
    if (contactTypeCode=== PERSON_TYPES.LEAD) {
      accessCodeValue = MAIN_MENU_CODES.LEAD;
    } else if (contactTypeCode === PERSON_TYPES.CUSTOMER) {
      accessCodeValue = MAIN_MENU_CODES.CONSUMER;
    }
  const permissionWiseLocationData = check(USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code, accessCodeValue,'');
  // #endregion

  // #region functions
  const handleResolve = async (status: any) => {
    localBroadcastEvent(
      CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_STATUS_CHANGED_ACTION_BUS,
      {
        conversation: conversation,
        status,
      }
    );
    if (status === assignStatusValue.ARCHIVED) {
      localBroadcastEvent(
        CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_BUS_ARCHIVED,
        {
          conversation: conversation,
        }
      );
    } else if (status === assignStatusValue.OPEN) {
      localBroadcastEvent(
        CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_BUS_UN_ARCHIVED,
        {
          conversation: conversation,
        }
      );
    }
    if (
      props.isInstantChatView &&
      status === assignStatusValue.ARCHIVED &&
      props?.onActionPerformed
    ) {
      props?.onActionPerformed(
        CONVERSATION_ACTION_CODES.DRAWER_CLOSE,
        conversation
      );
    }
    setLoadingArchive(false);
    setAssignUserListPopup(false);
  };

  const onContactClicked = (contactId: any) => {
    const type = contactTypeValue || '';
    let currentStatus = true;
    if (
      isGroupConversation(conversation || ({} as any)) &&
      isUserGroupsGroup(conversation || ({} as any))
    ) {
      currentStatus = true;
    } else {
      currentStatus = isActiveContact(contactData);
      if (!currentStatus && !props.defaultActiveStatus) {
        const message = getInActiveContactError(contactData);
        notification.error({
          message,
        });
        return;
      }
    }
    if (type == 'Customer') {
      props?.navigateOrOpenContactIdDrawer?.(contactId, {
        state: conversation?.conversationInbox,
      });
    } else if (type == 'Lead') {
      navigate(`/members/prospect/${contactId}`);
    } else {
      navigate(`/members/supporting-memeber/${contactId}`);
    }
  };

  const onCallBackAction = useCallback((code: any) => {
    if (COMMON_ACTION_CODES.NO_CONTACT_FOUND == code) {
      setIsNoContactFound(true);
    }
  }, []);

  const canViewPatientProfile = () => {
    let isPatientOfUserLocation = false;
    if (permissionWiseLocationData?.hasAccess !== true) {
      setAccessToViewPatientProfile(false);
      return;
    }
    if (contactData?.contactPracticeLocations?.[0]?.practiceLocationUuid) {
      isPatientOfUserLocation = allowedUserPracticeLocationUuids?.includes(
        contactData?.contactPracticeLocations?.[0]?.practiceLocationUuid
      );
    } else if (contactTypeCode !== PERSON_TYPES.CUSTOMER) {
      isPatientOfUserLocation = true;
    }
    setAccessToViewPatientProfile(isPatientOfUserLocation);
  };

  const handleResolveWrapper = useCallback(() => {
    handleResolve(button.nextStatusCode);
  }, [handleResolve, button.nextStatusCode]);

  const onSelectUser = useCallback(() => {
    setAssignUserListPopup(false);
  }, [setAssignUserListPopup]);

  const setAssignUserListPopupWrapper = useCallback(() => {
    setAssignUserListPopup(true);
  }, [setAssignUserListPopup]);

  const onSearchMessage = useCallback(() => {
    if (props.onActionPerformed) {
      props.onActionPerformed(CONVERSATION_ACTION_CODES.SEARCH_MESSAGE, {
        isSeachMsgContainerVisible: isSeachMsgContainerVisible,
      });
      setIsSeachMsgContainerVisible(!isSeachMsgContainerVisible);
    }
  }, [isSeachMsgContainerVisible, setIsSeachMsgContainerVisible]);

  const onCreateGroupPopupActionPerformed = useCallback(
    (actionCode: string, actionData?: any) => {
      if (actionCode === CONVERSATION_ACTION_CODES.COPY) {
        props?.onActionPerformed?.(CONVERSATION_ACTION_CODES.COPY, actionData);
      }
      if (actionCode === GROUP_ACTION_CODES.GROUP_HEADER_CLICKED) {
        if (
          props.onActionPerformed &&
          typeof props.onActionPerformed === 'function'
        ) {
          props.onActionPerformed(GROUP_ACTION_CODES.GROUP_HEADER_CLICKED, {});
        }
      } else if (actionCode === CONVERSATION_ACTION_CODES.FILES) {
        setConversationAttachmentDrawer(true);
      } else if (HEADER_ACTION_ITEM.includes(actionCode)) {
        props.onActionPerformed?.(actionCode, actionData);
      } else if (actionCode === CONVERSATION_ACTION_CODES.SELECT_CHAT) {
        if (props?.handleSelectChat) {
          props?.handleSelectChat();
        }
      } else if (actionCode === CONVERSATION_ACTION_CODES.SEARCH_MESSAGE) {
        if (props.onActionPerformed) {
          props.onActionPerformed(CONVERSATION_ACTION_CODES.SEARCH_MESSAGE, {
            isSeachMsgContainerVisible: !isSeachMsgContainerVisible,
          });
          setIsSeachMsgContainerVisible(!isSeachMsgContainerVisible);
        }
      } else if (
        actionCode === CONVERSATION_ACTION_CODES.ARCHIVE ||
        actionCode === CONVERSATION_ACTION_CODES.UNARCHIVE
      ) {
        handleResolve(button.nextStatusCode);
      } else {
        const eventBus = EventBus.getEventBusInstance();
        const eventCode = getEventCodeFromParentCode(props.parentCode);
        eventBus.broadcastEvent(eventCode, {
          actionCode,
          actionData,
        });
      }
      setMoreActionPopup(false);
    },
    [
      props,
      props.onActionPerformed,
      setConversationAttachmentDrawer,
      isSeachMsgContainerVisible,
      setIsSeachMsgContainerVisible,
      handleResolve,
      button.nextStatusCode,
      props.parentCode,
      setMoreActionPopup,
    ]
  );

  const onMoreBtnClick = useCallback(() => {
    setMoreActionPopup(true);
  }, [setMoreActionPopup]);

  const onHeaderInfoClick = useCallback(() => {
    if (!conversation) return;
    if (accessToViewPatientProfile) {
      if (props.showInfoIconInHeader) {
        if (isNavigateToOmniView) {
          handleNavigateToOmniView({
            contactId: contactData?.id,
            contactTypeCode: contactTypeCode,
            conversationInbox: conversation?.conversationInbox,
            navigate: navigate,
            navigateOrOpenContactIdDrawer: props.navigateOrOpenContactIdDrawer,
          });
        } else {
          setIsDetailsContainerVisible(!isDetailsContainerVisible);
          if (props.onActionPerformed) {
            props.onActionPerformed(
              CONVERSATION_ACTION_CODES.IS_DETAILS_CONTAINER_VISIBLE,
              {
                isDetailsContainerVisible: isDetailsContainerVisible,
              }
            );
          }
        }
        return;
      }
      let currentStatus = true;
      if (
        isGroupConversation(conversation || ({} as any)) &&
        isUserGroupsGroup(conversation || ({} as any))
      ) {
        currentStatus = true;
      } else {
        currentStatus = isActiveContact(contactData);
        if (!currentStatus && !props.defaultActiveStatus) {
          const message = getInActiveContactError(contactData);
          notification.error({
            message,
          });
          return;
        }
      }

      if (conversation.groupConversation === null) {
        props.onActionPerformed &&
          props.onActionPerformed(
            GROUP_ACTION_CODES.GROUP_HEADER_CLICKED_REDIRECT,
            conversation
          );
      } else if (conversation.groupConversation) {
        const patient = findPatient(
          conversation.groupConversation?.groupMembers
        );
        const contact = patient.contact;
        props.onActionPerformed &&
          !props.isInstantChatView &&
          props.onActionPerformed(
            GROUP_ACTION_CODES.GROUP_HEADER_CLICKED_REDIRECT,
            contact
          );
      }
    } else {
      showToast(
        toast,
        intl.formatMessage({
          id: 'patientProfileAccessDenyMsg',
        }),
        ToastType.info
      );
    }
  }, [
    conversation?.id,
    accessToViewPatientProfile,
    props.showInfoIconInHeader,
    isNavigateToOmniView,
    isDetailsContainerVisible,
    contactData?.id,
    props.defaultActiveStatus,
    props.isInstantChatView,
    handleNavigateToOmniView,
    props.onActionPerformed,
    findPatient,
    isGroupConversation,
    isUserGroupsGroup,
    isActiveContact,
    getInActiveContactError,
  ]);

  const onContactInfoClick = useCallback(() => {
    if (accessToViewPatientProfile) {
      if (props.showInfoIconInHeader) {
        setIsDetailsContainerVisible(!isDetailsContainerVisible);
        if (props.onActionPerformed) {
          if (isSideCarContext) {
            const ehrPatientId = contactData?.patient?.patientId || '';
            const openInEhrUrl = getViewInEHRLink(ehrPatientId, ehrURL);
            if (openInEhrUrl) {
              window.open(openInEhrUrl, '_blank', 'noopener,noreferrer');
            } else {
              redirectToNewTab(`/members/patient/${contactData?.id}`);
            }
          } else {
            props.onActionPerformed(
              CONVERSATION_ACTION_CODES.IS_DETAILS_CONTAINER_VISIBLE,
              {
                isDetailsContainerVisible: isDetailsContainerVisible,
              }
            );
          }
        }
        return;
      }
      !props.isInstantChatView && onContactClicked(contactData.id);
    } else {
      showToast(
        toast,
        intl.formatMessage({
          id: 'patientProfileAccessDenyMsg',
        }),
        ToastType.info
      );
    }
  }, [
    props.showInfoIconInHeader,
    accessToViewPatientProfile,
    isDetailsContainerVisible,
    props.onActionPerformed,
    isSideCarContext,
    contactData?.patient?.patientId,
    contactData?.id,
    ehrURL,
    props.isInstantChatView,
    onContactClicked,
  ]);

  const onConversationAttachmentActionPerformed = (actionCode: string) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.CANCEL:
        setConversationAttachmentDrawer(false);
        break;
      default:
        break;
    }
  };

  const isShowFinallyAssignee = () => {
    let isShowAssigneeButton = false;
    if (conversation) {
      const canShowAssigneeOnType = canShowAssignment(conversation || {});
      const isGroupMemberPresent = isGroupMembersAvailable(
        conversation,
        userUuid
      );
      if (canShowAssigneeOnType && isGroupMemberPresent) {
        isShowAssigneeButton = true;
      }
    }
    return isShowAssigneeButton;
  };

  const getHeaderInfoElem = () => {
    const showRightArrow = !(
      props.isInstantChatView ||
      (isSideCarContext &&
        isInternalChat(conversation || ({} as IConversationData)))
    );
    const selectedPronoun = pronounList?.find((pronoun) => {
      return pronoun?.id === contactData?.person?.pronounId;
    });
    const isShowPronounName =
      showInfoOnHeaderData?.SHOW_PRONOUN_NAME &&
      notListedPronounData?.id !== selectedPronoun?.id;
    const pronounName =
      isShowPronounName && contactData?.name ? selectedPronoun?.value : '';
    if (conversation?.groupConversation?.id) {
      return (
        <div
          className="pressable"
          onClick={onHeaderInfoClick}
          {...testID('HeaderInfoClick')}
        >
          <HStack
            ml={props.showNavigationBack ? 2 : 5}
            justifyContent={'center'}
            alignItems={'center'}
            space="2"
          >
            <PatientInfoBanner
              name={
                conversation?.groupConversation?.name
                  ? conversation?.groupConversation?.name
                  : avatarNameAndType?.name
                  ? avatarNameAndType?.name
                  : groupName
                  ? groupName
                  : contactData?.name || CONVERSATION_CONST.GROUP_CHAT
              }
              showRightArrow={showRightArrow}
              showPronounName={pronounName}
              bannerType="headerBanner"
              isSelected={false}
              headerString={messagingHeaderInfo.headerString}
              groupType={
                conversation?.groupConversation?.groupType?.code as any
              }
              genderCode={genderCode as any}
              userId={contactData?.id}
              userType={
                avatarNameAndType?.userType
                  ? avatarNameAndType?.userType
                  : GROUP_MEMBER_TYPE.CONTACT
              }
              subtitleString={messagingHeaderInfo.subtitleData}
              memberSubtitleData={messagingHeaderInfo.memberSubtitleData}
              shouldShowSubtitleHighlighter={false}
              contactData={contactData}
              singleConversationData={conversation}
              getConversationTypeImgSvg={false}
              headerContainerWidth={width}
              onActionPerformed={props.onActionPerformed}
              deceasedDate={contactDeceasedDate}
              messagingWindowHeaderLoading={
                messagingWindowHeaderLoading || false
              }
            />
          </HStack>
        </div>
      );
    } else if (contactData?.name) {
      const contactNameWithChosenName =
        contactData.name +
        (contactData?.person?.chosenName?.length
          ? ' (' + contactData?.person?.chosenName + ')'
          : '');
      let contactTypeStr;
      if (conversation) {
        contactTypeStr = showInfoOnHeaderData?.SHOW_PCP
          ? JSON.stringify(
              getPatientAgeAndGenderJson(
                conversation,
                ehrConfig,
                pcpUserData?.name || '',
                props.isInstantChatView,
                {
                  deceasedDate: contactDeceasedDate,
                }
              )
            )
          : getPatientAgeAndGenderStr(
              conversation,
              ehrConfig,
              pcpUserData?.name || '',
              undefined,
              {
                deceasedDate: contactDeceasedDate,
              }
            );
      }
      return (
        <div
          className={`pressable ${
            isCommunicationLocationHandlingEnabled
              ? `${messagingWindowHeaderLoading ? 'disabled' : ''}`
              : ''
          } `}
          onClick={onContactInfoClick}
          {...testID('HeaderInfoClick')}
        >
          <HStack
            ml={props.showNavigationBack ? 2 : 5}
            justifyContent={'flex-start'}
            alignItems={'center'}
            space="2"
          >
            <PatientInfoBanner
              isLetterAvatarShow={true}
              name={groupName ? groupName : contactData.name}
              showRightArrow={!props.isInstantChatView}
              showPronounName={pronounName}
              bannerType="headerBanner"
              isSelected={false}
              headerString={contactNameWithChosenName}
              memberSubtitleData={messagingHeaderInfo.memberSubtitleData}
              onActionPerformed={props.onActionPerformed}
              groupType={
                conversation?.groupConversation?.groupType?.code as any
              }
              genderCode={genderCode as any}
              userId={contactData?.id}
              userType={GROUP_MEMBER_TYPE.CONTACT}
              subtitleString={
                !isNoContactFound && isShowForSmsOrEmail
                  ? ((
                      <MemberListByContactValue
                        contactData={contactData}
                        channelType={channelType}
                        conversationUuid={conversation?.uuid || ''}
                        navigate={navigate}
                        onCallBackAction={onCallBackAction}
                      />
                    ) as any)
                  : contactTypeStr
              }
              shouldShowSubtitleHighlighter={false}
              contactData={contactData}
              singleConversationData={conversation}
              headerContainerWidth={width}
              deceasedDate={contactDeceasedDate}
              messagingWindowHeaderLoading={
                messagingWindowHeaderLoading || false
              }
            />
          </HStack>
        </div>
      );
    }
    return <></>;
  };

  const isShowCreateNoteView = () => {
    if (isAthena) {
      return false;
    }
    if (!isPatientContactType(contactTypeCode)) {
      return false;
    }
    if (!props.isSelectChatOn) {
      return false;
    }
    return true;
  };

  const isShowAssignMessageTypeButton = () => {
    if (props.selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_INTERNAL) {
      return false;
    }
    if (conversation && isBroadCastAndPrivateGroup(conversation)) {
      return false;
    }
    if (!props.isSelectChatOn) {
      return false;
    }
    return true;
  };
  // #endregion

  // #region queries
  const [getAssignUserData] = useLazyQuery(
    ConversationsQueries.GetConversationUserById,
    {
      fetchPolicy: 'no-cache',
      variables: {
        conversationId: props.conversation?.id,
        loginUserId: userId,
      },
    }
  );

  const [getContactForMessageHeader] = useLazyQuery(
    LeadQueries.GetContactForMessageHeader,
    {
      fetchPolicy: 'no-cache',
      variables: {
        id: contactData.id,
      },
    }
  );

  // #endregion

  // #region api calls
  const fetchPatientDeceasedDate = useCallback(async () => {
    if (ehrConfig?.isAthena) {
      const deceasedDate = await getCurrentPatientDeceasedData(contactData);
      setContactDeceasedDate(deceasedDate);
    } else {
      setContactDeceasedDate('');
    }
  }, [contactData.patient?.id, ehrConfig?.isAthena]);

  const getAssigneeName = useCallback(async () => {
    const assigneeData = await getAssignUserData();
    if (assigneeData) {
      const assignUserName =
        assigneeData?.data?.conversations?.[0]?.assignedUser?.name || '';
      const assignedUser = assigneeData?.data?.conversations?.[0]?.assignedUser;
      if (conversation) {
        conversation.assignedUser = {...assignedUser};
        conversation.assigneeId = assignedUser?.id;
      }
      setAssignedUserData(assigneeData);
    }
  }, [conversation?.assignedUser?.id, conversation?.assigneeId]);

  const getContactDataObject = useCallback(async () => {
    const contactData = await getContactForMessageHeader();
    if (contactData?.data?.contact) {
      setContactData(contactData?.data?.contact);
    }
  }, []);
  // #endregion

  // #region listeners
  const onMsgAssigneeChangedListenerFn = useCallback(
    (data: any) => {
      data =
        Object.keys(data?.data || {}).length > 0
          ? messageDataKeysConverter(data.data)
          : data;
      if (
        data?.conversationUuid === conversation?.uuid ||
        data?.conversation_uuid === conversation?.uuid
      ) {
        const assignUserName =
          data?.assigneeName ||
          data?.meta?.assignee?.name ||
          data?.additionalData?.assigneeName ||
          '';
        setAssignedUserData(data?.contentAttributes?.assigneeToUserData);
      }
    },
    [conversation]
  );

  const onLocalConversationAssignedOrUnassignedEvent = useCallback(
    (eventData: ILocalAssigneeChangedEventData) => {
      const contentAttributesAssigneeData: IContentAttributesAssigneeData =
        eventData?.messageData?.contentAttributes;
      if (conversation?.uuid === eventData?.messageData?.conversation?.uuid) {
        if (
          contentAttributesAssigneeData &&
          Object.keys(contentAttributesAssigneeData)?.length
        ) {
          const assignUserName =
            contentAttributesAssigneeData?.assigneeToUserData?.name || '';
          const assignedUser =
            contentAttributesAssigneeData?.assigneeToUserData;
          if (conversation) {
            conversation.assignedUser = {...assignedUser};
            conversation.assigneeId = assignedUser?.id;
          }
          setAssignedUserData(
            contentAttributesAssigneeData?.assigneeToUserData
          );
        }
        if (
          props.isInstantChatView &&
          eventData?.messageData?.conversation?.status !== conversation?.status
        ) {
          const buttonStatus = getButtonActionObject(
            eventData?.messageData?.conversation?.status
          );
          setButton(buttonStatus);
        }
      }
    },
    [conversation, props.isInstantChatView]
  );

  const onConversationStatusChangedEvent = useCallback(
    (eventData: any) => {
      if (
        eventData?.data?.conversation_uuid === conversation?.uuid ||
        eventData?.data?.conversationUuid === conversation?.uuid ||
        eventData?.conversation_uuid === conversation?.uuid
      ) {
        const buttonStatus = getButtonActionObject(
          eventData?.data?.conversation?.status
        );
        setButton((prev: any) => {
          if (prev.code === buttonStatus.code) {
            return prev;
          }
          return {
            ...prev,
            ...buttonStatus,
          };
        });
        if (
          eventData?.data?.conversation?.status === CONVERSATION_STATUS.RESOLVED
        ) {
          const unAssignedUserData = {
            id: -1,
            name: '',
            uuid: '',
            email: '',
          };
          setAssignedUserData(unAssignedUserData);
        }
      }
    },
    [conversation]
  );

  const onLocalConversationStatusChangedEvent = useCallback(
    (eventData: ILocalAssigneeChangedEventData) => {
      if (conversation?.uuid === eventData?.messageData?.conversation?.uuid) {
        if (conversation) {
          conversation.status = eventData?.messageData?.conversation
            ?.status as any;
        }
        if (props.isInstantChatView) {
          const buttonStatus = getButtonActionObject(
            eventData?.messageData?.conversation?.status
          );
          setButton(buttonStatus);
        }
      }
    },
    [conversation, props.isInstantChatView]
  );

  // #endregion

  // #region side-effects
  useEffect(() => {
    setIsSeachMsgContainerVisible(props.isSeachMsgContainerVisible);
  }, [props.isSeachMsgContainerVisible]);

  useEffect(() => {
    setIsDetailsContainerVisible(props?.isDetailsContainerVisible)
  }, [props?.isDetailsContainerVisible])

  useEffect(() => {
    const buttonStatus = getButtonActionObject(conversation?.status);
    setButton(buttonStatus);
  }, [conversation?.status]);

  useEffect(() => {
    fetchPatientDeceasedDate();
    setContactData(props?.contactData);
  }, [props?.contactData?.uuid, props?.contactData?.updatedAt]);

  useEffect(() => {
    if (isCommunicationLocationHandlingEnabled) {
      canViewPatientProfile();
    }
  }, [
    conversation?.uuid,
    contactTypeCode,
    contactData?.contactPracticeLocations?.[0]?.practiceLocationUuid,
  ]);

  useEffect(() => {
    resetDimension();
  }, [props.isSideDetailVisible]);

  useEffect(() => {
    if (conversation?.assignedUser?.id) {
      setAssignedUserData(conversation?.assignedUser);
    }
    const eventBus = EventBus.getEventBusInstance();
    eventBus.removeEventListener(onMsgAssigneeChangedListenerFn);
    eventBus.removeEventListener(onLocalConversationAssignedOrUnassignedEvent, {
      moduleCode: props.moduleCode,
    });
    eventBus.removeEventListener(onLocalConversationStatusChangedEvent, {
      moduleCode: props.moduleCode,
    });
    eventBus.removeEventListener(onConversationStatusChangedEvent);
    eventBus.addEventListener(
      SUPPORTED_EVENT_CODE.ASSIGNEE_CHANGED,
      onMsgAssigneeChangedListenerFn
    );

    eventBus.addEventListener(
      CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_ASSIGNEE_CHANGED,
      onLocalConversationAssignedOrUnassignedEvent,
      {
        moduleCode: props.moduleCode,
      }
    );

    eventBus.addEventListener(
      CONVERSATION_LOCAL_EVENT_CODES.CONVERSATION_STATUS_CHANGED_EVENT,
      onLocalConversationStatusChangedEvent,
      {
        moduleCode: props.moduleCode,
      }
    );

    eventBus.addEventListener(
      WEB_SOCKET_EVENT_CODE.CONVERSATION_RESOLVED,
      onConversationStatusChangedEvent
    );

    eventBus.addEventListener(
      WEB_SOCKET_EVENT_CODE.CONVERSATION_OPENED,
      onConversationStatusChangedEvent
    );

    eventBus.addEventListener(
      WEB_SOCKET_EVENT_CODE.ASSIGNEE_CHANGED,
      onConversationStatusChangedEvent
    );

    eventBus.addEventListener(
      WEB_SOCKET_EVENT_CODE.MESSAGE_CREATED,
      onConversationStatusChangedEvent
    );

    if (
      (!conversation?.groupConversation?.id && !props.showInfoIconInHeader) ||
      !contactData?.patient?.patientId
    ) {
      getContactDataObject();
    }
    return () => {
      eventBus.removeEventListener(onMsgAssigneeChangedListenerFn);
      eventBus.removeEventListener(
        onLocalConversationAssignedOrUnassignedEvent,
        {
          moduleCode: props.moduleCode,
        }
      );
      eventBus.removeEventListener(onLocalConversationStatusChangedEvent, {
        moduleCode: props.moduleCode,
      });
      eventBus.removeEventListener(onConversationStatusChangedEvent);
    };
  }, [conversation?.uuid]);
  // #endregion

  return (
    <>
      {emailSubject && (
        <View style={styles.emailSubjectContainer}>
          <Text style={styles.emailSubjectText} {...testID('Subject')}>
            {`Subject: ${emailSubject}`}
          </Text>
        </View>
      )}
      <HStack
        ref={headerContainerRef}
        style={[
          styles.topBar,
          {
            position: 'relative',
            zIndex: 100000,
            justifyContent: props.showNavigationBack
              ? undefined
              : 'space-between',
          },
        ]}
      >
        {props.showNavigationBack && (
          <div onClick={props.navigationBackPressed} className="pressable">
            <SideCarBackSvg />
          </div>
        )}
        {getHeaderInfoElem()}
        <HStack style={styles.headerActionContainer}>
          {props.isDisplayActions && (
            <HStack
              flex={1}
              justifyContent={'flex-end'}
              alignItems={'center'}
              space="4"
              pr="4"
            >
              {props.isSelectChatOn ? (
                <CopyMsgView
                  code={props.copyMessageStateCode}
                  handleCopyToClipBoard={props.handleCopyToClipBoard}
                  isMessageCopy={props.isMessageCopy}
                />
              ) : (
                <></>
              )}

              {!isPatientSidecarContext &&
                !props.isSelectChatOn &&
                !(isDetailsContainerVisible || isSeachMsgContainerVisible) &&
                props.handleSelectChat &&
                !isChannelEmail(
                  props.conversation?.conversationInbox?.channelType || ''
                ) && (
                  <SelectChatView handleSelectChat={props.handleSelectChat} />
                )}

              {isShowCreateNoteView() ? (
                <CreateNoteView
                  handleCreateNoteClick={props.handleCreateNoteClick}
                  isInstantChatView={props.isInstantChatView}
                  isMessageCopy={props.isMessageCopy}
                  patientLocationUuid={conversation?.contactLocationUuid}
                />
              ) : (
                <></>
              )}

              {isShowAssignMessageTypeButton() ? (
                <AssignMessageTypeButton
                  onClick={props.handleAssignMessageTypeButton}
                  isMessageCopy={props.isMessageCopy}
                />
              ) : (
                <></>
              )}

              {props.isSelectChatOn ? (
                <CreateTaskView
                  isMessageCopy={props.isMessageCopy}
                  onCreateTaskBtnPress={props.onCreateTaskBtnPress}
                />
              ) : (
                <></>
              )}

              {props.isSelectChatOn ? (
                <CancelView onPressCancelBtn={props.onPressCancelBtn} />
              ) : (
                <></>
              )}

              {!isPatientSidecarContext &&
                !props.isSelectChatOn &&
                !(isDetailsContainerVisible || isSeachMsgContainerVisible) &&
                canShowArchive(
                  props.selectedInboxTypeCode ?? '',
                  conversation
                ) &&
                (loadingArchive ? (
                  <VStack
                    alignItems={'center'}
                    {...testID(TestIdentifiers.lazyLoading)}
                  >
                    <Spinner size="sm" />
                  </VStack>
                ) : (
                  <Tooltip title={button.title}>
                    <div
                      className="pressable"
                      onClick={handleResolveWrapper}
                      {...testID('ArchivedBtnClick')}
                    >
                      <VStack alignItems={'center'}>
                        {button.currentStatusCode === 1 ? (
                          <UnArchiveActionSvg
                            customStrokeColor={Colors.FoldPixel.GRAY300}
                            width={20}
                            height={20}
                          />
                        ) : (
                          <ArchiveActionSvg
                            customStrokeColor={Colors.FoldPixel.GRAY300}
                            width={20}
                            height={20}
                          />
                        )}
                      </VStack>
                    </div>
                  </Tooltip>
                ))}

              {!isPatientSidecarContext &&
                !props.isSelectChatOn &&
                !(isDetailsContainerVisible || isSeachMsgContainerVisible) &&
                isShowFinallyAssignee() && (
                  <Popover
                    overlayInnerStyle={cssProperties.overlayInnerStyle}
                    overlayStyle={cssProperties.overlayStyle}
                    overlay={cssProperties.overlay}
                    style={styles.assigneePopover}
                    placement={'bottomLeft'}
                    trigger="click"
                    visible={assignUserListPopup}
                    onVisibleChange={setAssignUserListPopup}
                    content={
                      <View width={300} {...testID('AssignPopover')}>
                        <ConversationActionView
                          conversation={conversation}
                          selectedInboxTypeCode={props.selectedInboxTypeCode}
                          style={styles.conversationActionViewStyle}
                          assignedUserData={assignedUserData}
                          onSelectUser={onSelectUser}
                          onActionPerformed={props.onActionPerformed}
                        />
                      </View>
                    }
                  >
                   <Tooltip
                      title={
                        assignUserName?.length ? assignUserName : 'Unassigned'
                      }
                    >
                      <div
                        className="pressable"
                        onClick={setAssignUserListPopupWrapper}
                        {...testID('AssignBtnClick')}
                      >
                        <VStack alignItems={'center'}>
                          <AssignActionSvg
                            customStrokeColor={
                              assignUserName && assignUserName.length
                                ? '#12B76A'
                                : Colors.FoldPixel.GRAY300
                            }
                            width={20}
                            height={20}
                          />
                        </VStack>
                      </div>
                    </Tooltip>
                  </Popover>
                )}
              {!isPatientSidecarContext &&
                !props.isSelectChatOn &&
                !(isDetailsContainerVisible || isSeachMsgContainerVisible) &&
                !props.isInstantChatView &&
                !isEmailConversation && (
                  <Tooltip title={'Search'}>
                    <div className="pressable" onClick={onSearchMessage}>
                      <VStack alignItems={'center'}>
                        <SearchActionSvg
                          customStrokeColor={Colors.FoldPixel.GRAY300}
                          width={20}
                          height={20}
                        />
                      </VStack>
                    </div>
                  </Tooltip>
                )}
              {!props.isSelectChatOn && (
                <Popover
                  overlayInnerStyle={cssProperties.overlayInnerStyle}
                  overlayStyle={cssProperties.overlayStyle}
                  overlay={cssProperties.overlay}
                  style={styles.assigneePopover}
                  placement={'bottomLeft'}
                  trigger="click"
                  visible={moreActionPopup}
                  onVisibleChange={setMoreActionPopup}
                  content={
                    <View width={210}>
                      <CreateAllConversationPopup
                        contactData={contactData}
                        isDetailsContainerVisible={isDetailsContainerVisible}
                        selectedTabCode={CONVERSATION_TAB_CODES.PATIENTS}
                        openCount={0}
                        conversation={conversation}
                        isCompactView={
                          isDetailsContainerVisible ||
                          isSeachMsgContainerVisible
                        }
                        selectedInboxTypeCode={props.selectedInboxTypeCode}
                        getAssigneeName={getAssigneeName}
                        assignUserName={assignUserName}
                        isShowFinallyAssignee={isShowFinallyAssignee()}
                        isSingleContactPresentInSMSOrEmailInbox={
                          isShowForSmsOrEmail && isNoContactFound
                        }
                        isInstantChatView={props.isInstantChatView}
                        assignedUserData={assignedUserData}
                        onCreateGroupPopupActionPerformed={
                          onCreateGroupPopupActionPerformed
                        }
                        accessToViewPatientProfile={accessToViewPatientProfile}
                        onActionPerformed={props.onActionPerformed}
                      />
                    </View>
                  }
                >
                  <div
                    className="pressable"
                    onClick={onMoreBtnClick}
                    {...testID('MoreBtnClick')}
                  >
                    <Tooltip title={'More'}>
                      <div className="vstack">
                        <MenuHorizontalIcon width={24} height={24} />
                      </div>
                    </Tooltip>
                  </div>
                </Popover>
              )}
            </HStack>
          )}
        </HStack>
      </HStack>

      <View width={'100%'} style={styles.dividerView} />

      {conversationAttachmentDrawer && (
        <ConversationAttachments
          conversationId={props.conversation?.id || -1}
          conversationDisplayId={conversation?.displayId || -1}
          isDrawerVisible={conversationAttachmentDrawer}
          onConversationAttachmentActionPerformed={
            onConversationAttachmentActionPerformed
          }
          openInDrawer={true}
        />
      )}
    </>
  );
};

export const MessagingWindowHeaderV2 = React.memo(
  withMiniContactViewHOC(MessagingWindowHeaderV2Component)
);

// export const MessagingWindowHeaderV2 = MessagingWindowHeaderV2Component;
