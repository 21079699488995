import {IContact} from "../components/RightSideContainer/TeamInbox/Conversations/interfaces"
import {PREFERRED_CHANNEL_CODE} from "../constants"

export const getToolTipMessage = (code: string, phoneNumber: string,email:string,contactInfo:IContact) => {
    switch (code) {
      case 'CALL':
        if (getPreferredChannel(contactInfo) === PREFERRED_CHANNEL_CODE.VOICE) {
          return 'Preferred Communication Channel'
        } else if (phoneNumber) {
          return 'Call'
        } else {
          return 'Phone Number not set, please add phone number to take any action'
        }

      case 'EMAIL':
        if (getPreferredChannel(contactInfo) === PREFERRED_CHANNEL_CODE.EMAIL) {
          return 'Preferred Communication Channel'
        } else if (email) {
          return 'Send Email'
        } else {
          return 'Email not set, please add email to take any action'
        }

      case 'VCALL':
        if (email || phoneNumber) {
          return 'Start Meeting'
        } else {
          return 'Email or Phone Number not set, please add email or phone Number to take any action'
        }

      case 'SMS':
        if (getPreferredChannel(contactInfo) === PREFERRED_CHANNEL_CODE.SMS) {
          return 'Preferred Communication Channel'
        } else if (phoneNumber) {
          return 'Send SMS'
        } else {
          return 'Phone Number not set, please add phone number to take any action'
        }

      default:
        return ''
    }
  }

  export   const getPreferredChannel = (contactInfo: IContact ) => {
    return contactInfo?.contactPreferredCommunicationChannel?.length
      ? contactInfo?.contactPreferredCommunicationChannel[0]
        ?.preferredCommunicationModeMlov?.code
      : '';
  };
