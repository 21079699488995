import {useEffect, useState} from 'react';
import {
  IReccuringEventAPI,
  IReccuringEventConfig,
  IUseReccuringEvent,
  Month,
  PickSpecificFields,
  ReccurenceType,
  ReccuringEventFieldType,
} from './interface';
import {
  getDateStrFromFormat,
  getMomentObj,
  getMomentObjFromDateStrAndFormat,
} from '../../../../../../../../utils/DateUtils';
import {API_VERSION} from './constants';
import {
  calculateMaxFutureDate,
  getFrequencyCode,
  getMinMaxDates,
  getSummaryString,
  isMonthAndDateValid,
} from './helpers';
import {isArray} from 'lodash';
import {ReccuringEventType} from '../../ReccuringEventView/Interfaces';
import moment, {Moment} from 'moment';
import {DISPLAY_DATE_FORMAT} from '../../../../../../../../constants';
import { AppointmentAction } from '../../AppointmentBookingEnums';
import { IAppointmentDetail } from '../../../../../CalendarWidgetInterfaces';

const useReccuringEvent = (
  EventType: ReccuringEventType,
  eventStartTime: Moment,
  updatedData?: IReccuringEventAPI,
  editType?: AppointmentAction,
  eventUpdateDate?: IAppointmentDetail
): IReccuringEventConfig => {
  const  formatStartDate = eventStartTime.format(DISPLAY_DATE_FORMAT);
  const maxRepeatsEveryLimit = 100
  const isBlockTime = EventType === ReccuringEventType.BLOCKTIME;
  const isEditBlockOccurence = editType === AppointmentAction.editBlockOccurence;
  const isEditBlockSeries = editType === AppointmentAction.editBlockSeries;
  const isEditOccurence = editType === AppointmentAction.editOccurence;
  const isEditSeries = editType === AppointmentAction.editSeries;

  const getInitStartDate = () => {
    if (!isBlockTime) {
      return eventStartTime.toISOString() || getMomentObj(new Date()).startOf('day').toISOString();
    } else {
      return isUpdate
        ? updatedData?.startDateTime ||
            getMomentObj(new Date()).startOf('day').toISOString()
        : getMomentObj(new Date()).startOf('day').toISOString();
    }
  };

  const calculateMaxFutureDateFromEventSelectedDate = () => {
    return calculateMaxFutureDate(
      isUpdate ? updatedData.frequency : ReccurenceType.DAILY,
      isUpdate ? updatedData?.repeatOnValue || 1 : 1,
      isBlockTime ? getMomentObj(getInitStartDate()) : eventStartTime
    );
  }

  const calculateMaxFutureDateFromPrevEventDate = () => {
    return calculateMaxFutureDate(
      isUpdate ? updatedData.frequency : ReccurenceType.DAILY,
      isUpdate ? updatedData?.repeatOnValue || 1 : 1,
      isBlockTime
        ? getMomentObj(getInitStartDate())
        : isUpdate
        ? getMomentObj(eventUpdateDate?.startDateTime as string)
        : eventStartTime
    );
  }

  const isUpdate = !!updatedData;
  
  const maxDateTime = isEditOccurence
    ? calculateMaxFutureDateFromPrevEventDate()
    : calculateMaxFutureDateFromEventSelectedDate();


  const initialData: IUseReccuringEvent = {
    frequency: isUpdate ? updatedData.frequency : ReccurenceType.DAILY,
    repeatEvery: isUpdate ? updatedData?.repeatOnValue : 1,
   endDate: isUpdate
      ? getMomentObj(updatedData?.endDate).toISOString()
      : maxDateTime.toISOString(),
    viewType: '',
    repeatsOnWeekDays: isUpdate ? updatedData?.daysOfWeek : [1, 2, 3, 4, 5],
    repeatsOnMonthDay: isUpdate ? updatedData?.repeatOnMonthDays?.[0] || 1 : 1,
    repeatsOnMonth: isUpdate
      ? (updatedData?.repeatOnMonth as Month)
      : Month.JANUARY,
    startDate: getInitStartDate(),
    startAndEndTime: updatedData?.startAndEndTime,
    maxDate: maxDateTime,
    seriesStartDate: updatedData?.seriesStartDate
  };

  const [data, setData] = useState<IUseReccuringEvent>(initialData);


  const onChangeData = <K extends keyof PickSpecificFields<IUseReccuringEvent>>(
    fieldType: K,
    value: IUseReccuringEvent[K]
  ) => {
    if(!isBlockTime && isUpdate && (isEditOccurence || isEditBlockOccurence)){
      return;
    }
    setData((prev) => {
      const obj = {...prev, [fieldType]: value};
      // reset repeats on if frequency is changed
      if(fieldType === 'seriesStartDate'){
        obj.startDate = value as string;
      }
      if(fieldType === 'frequency'){
        const maxDate = calculateMaxFutureDate(
          value as ReccurenceType,
          data.repeatEvery || 1,
          isBlockTime ? getMomentObj(data.startDate) : eventStartTime
        )
        obj.maxDate = maxDate;
        obj.endDate = maxDate.toISOString()
      }
      if(fieldType === 'startDate' || fieldType === 'seriesStartDate'){
        const maxDate = calculateMaxFutureDate(
          data.frequency as ReccurenceType,
          data.repeatEvery || 1,
           getMomentObj(value as string)
        )
        obj.maxDate = maxDate;
        obj.endDate = maxDate.toISOString()
      }
      if(fieldType === 'repeatEvery'){
        const maxDate = calculateMaxFutureDate(
          data.frequency as ReccurenceType,
          (value || 1) as number,
          isBlockTime ? getMomentObj(data.startDate) : eventStartTime
        )
        obj.maxDate = maxDate;
        obj.endDate = maxDate.toISOString()
        if(!!value && (value as number) > 100){
          obj.repeatEvery = 100
        }
      }
      if (fieldType === 'frequency') {
        obj.repeatsOnMonthDay = 1;
        obj.repeatsOnMonth = Month.JANUARY;
        obj.repeatsOnWeekDays = [1, 2, 3, 4, 5];
      }

      return obj;
    });
  };

  const onResetData = () => {
    setData(initialData);
  };

  const formatDataForSave = (
    timezone: string
  ): {
    data: IReccuringEventAPI;
    error: boolean;
  } => {
    const {
      repeatEvery,
      repeatsOnMonthDay,
      repeatsOnMonth,
      repeatsOnWeekDays,
      frequency,
      endDate,
      startAndEndTime,
      startDate,
    } = data;

    const dateLimits =
      frequency === ReccurenceType.MONTHLY
        ? {minDate: 0, maxDate: 31}
        : getMinMaxDates(repeatsOnMonth);
    const invalidRepeatsEvery = repeatEvery > maxRepeatsEveryLimit ||
      repeatEvery < 1 || repeatEvery === null || repeatEvery === undefined;
    const invalidDaysOfWeek =
      !isArray(repeatsOnWeekDays) || repeatsOnWeekDays?.length === 0;

    const invalidRepeatsOnMonthDay =
      repeatsOnMonthDay < 1 ||
      repeatsOnMonthDay === null ||
      repeatsOnMonthDay === undefined ||
      !(repeatsOnMonthDay >= dateLimits.minDate && repeatsOnMonthDay <= dateLimits.maxDate)
      ;

    const isInvalidDateAndMonth = !isMonthAndDateValid(
      repeatsOnMonthDay,
      repeatsOnMonth
    );

    let isInvalidEventStartTime = false;
    let isInvalidEventEndTime = false;
    let isStartTimeAfterEndTime = false;
    let blockEventStartDateTime = '';
    let blockEventEndDateTime = '';
    if (isBlockTime) {
      // event start time
      const startTimeHours = startAndEndTime?.[0]?.get('hours').toString();
      const startTimeMins = startAndEndTime?.[0]?.get('minutes').toString();
      const startDateStr = getDateStrFromFormat(startDate, 'YYYY-MM-DD');
      const eventStartDateTimeObj = getMomentObj(`${startDateStr} ${startTimeHours}:${startTimeMins}`, timezone);
      isInvalidEventStartTime = !eventStartDateTimeObj.isValid();
      blockEventStartDateTime = eventStartDateTimeObj.toISOString();
      // event end time
      const endTimeHours = startAndEndTime?.[1]?.get('hours').toString();
      const endTimeMins = startAndEndTime?.[1]?.get('minutes').toString();
      const eventEndDateTimeObj = getMomentObj(`${startDateStr} ${endTimeHours}:${endTimeMins}`, timezone);

      isInvalidEventEndTime = !eventEndDateTimeObj.isValid();
      blockEventEndDateTime = eventEndDateTimeObj.toISOString();
      isStartTimeAfterEndTime =
        eventStartDateTimeObj.isAfter(eventEndDateTimeObj);
    }

    const isInValidEventStartAndEndTime =
      isBlockTime &&
      (isInvalidEventEndTime ||
        isInvalidEventStartTime ||
        isStartTimeAfterEndTime);

    const frequencyCode = getFrequencyCode(frequency);
    const apiData: IReccuringEventAPI = {
      frequency: frequencyCode,
      repeatOnField: frequencyCode,
      repeatOnValue: repeatEvery,
      timezone: timezone,
      endDate: endDate,
      version: '1.0',
      seriesStartDate: isBlockTime ? blockEventStartDateTime : eventStartTime.toISOString()
    };

    if (frequency === ReccurenceType.DAILY) {
      return {
        error: invalidRepeatsEvery || isInValidEventStartAndEndTime,
        data: {
          ...apiData,
          ...(isBlockTime && {
            startDateTime: blockEventStartDateTime,
            endDateTime: blockEventEndDateTime,
          }),
        },
      };
    }
    if (frequency === ReccurenceType.WEEKLY) {
      return {
        error: invalidDaysOfWeek || isInValidEventStartAndEndTime,
        data: {
          ...apiData,
          daysOfWeek: repeatsOnWeekDays,
          ...(isBlockTime && {
            startDateTime: blockEventStartDateTime,
            endDateTime: blockEventEndDateTime,
          }),
        },
      };
    }
    if (frequency === ReccurenceType.MONTHLY) {
      return {
        error: invalidRepeatsOnMonthDay || isInValidEventStartAndEndTime,
        data: {
          ...apiData,
          repeatOnMonthDays: [repeatsOnMonthDay],
          ...(isBlockTime && {
            startDateTime: blockEventStartDateTime,
            endDateTime: blockEventEndDateTime,
          }),
        },
      };
    }

    if (frequency === ReccurenceType.YEARLY) {
      return {
        error:
          invalidRepeatsOnMonthDay ||
          isInvalidDateAndMonth ||
          isInValidEventStartAndEndTime,
        data: {
          ...apiData,
          repeatOnMonthDays: [repeatsOnMonthDay],
          repeatOnMonth: repeatsOnMonth,
          ...(isBlockTime && {
            startDateTime: blockEventStartDateTime,
            endDateTime: blockEventEndDateTime,
          }),
        },
      };
    }

    return {
      data: {} as any,
      error: false,
    };
  };

  const getReccStartDate = () => {
    let str = '';
    if (isBlockTime) {
      str = getMomentObj(data.startDate).format(DISPLAY_DATE_FORMAT) as string;
    } else if (!isBlockTime) {
      str = formatStartDate as string;
    }
    return str;
  };

  const disabledEndDates = (current: Moment) => {
    if(isBlockTime){
     return !current.isBetween(data.startDate,data.maxDate)
    }
    return !current.isBetween(
      getMomentObjFromDateStrAndFormat(formatStartDate, DISPLAY_DATE_FORMAT),
      data.maxDate
    );
  }

  return {
    isUpdate,
    maxRepeatsEveryLimit,
    data,
    onChangeData,
    onResetData,
    formatDataForSave,
    maxDate: data.maxDate as Moment,
    displaySummaryText: getSummaryString(data, {
      startTime: getReccStartDate() as string,
    }),
    disabledEndDates,
    // for native
    eventStartDate: getMomentObjFromDateStrAndFormat(formatStartDate, DISPLAY_DATE_FORMAT)?.toDate(),
  };
};

export default useReccuringEvent;
