import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { DatePicker, Drawer, Skeleton } from 'antd';
import { isEqual } from 'lodash';
import {
  Box,
  Center,
  FormControl,
  Pressable,
  Select,
  Stack,
  Text,
  useToast,
  View,
  VStack
} from 'native-base';
import { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import ReactQuill from 'react-quill';
import { useNavigate } from 'react-router-dom';
import { BUTTON_TYPE, DATE_FORMATS } from '../../../../../../constants';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../../constants/Configs';
import { MLOV_CODES } from '../../../../../../constants/MlovConst';
import {
  AppointmentQueries,
  ScheduleEventQueries,
  ScheduleQueries,
  UserQueries
} from '../../../../../../services';
import { Colors } from '../../../../../../styles';
import { testID, TestIdentifiers } from '../../../../../../testUtils';
import { getAccountUUID, getBooleanFeatureFlag, navigateToNewSettingTab } from '../../../../../../utils/commonUtils';
import {
  addDaysInDate,
  getDateObject,
  getDateStrFromFormat,
  getMomentObj,
  isCurrentDateInFutureComparedToOther,
  isPastDay
} from '../../../../../../utils/DateUtils';
import { IAppointmentType } from '../../../../../RightSideContainer/AccountSettings/AppointmentTypes/Interfaces';
import { DisplayText } from '../../../../DisplayText/DisplayText';
import { ModalActionTitle } from '../../../../ModalActionTitle/ModalActionTitle';
import { CalendarSlot } from '../../../CalendarWidgetInterfaces';
import ParticipantAutoComplete from '../../../ParticipantAutoComplete/ParticipantAutoComplete';
import {
  getGroupAppointmentBookingData,
  getUpdatedErrorMessagesForGroup,
  getUserIdsToBeFetched,
  isInvalidGroupBooking,
  removePastSlotTimes,
  RTE_MODULES,
  setUserDetailsToUserSlots
} from './AppointmentBookingHelper';
import {
  IAppointmentUserDetail,
  IGroupBookingData,
  IUserSlots
} from './AppointmentBookingIntefaces';
import ParticipantGroupSlots from './ParticipantGroupSlots';
import { ToastType, showToast } from '../../../../../../utils/commonViewUtils';
import { CommonDataContext } from '../../../../../../context/CommonDataContext';
import FeatureFlags from '../../../../../../constants/FeatureFlags.enums';

const GroupAppointmentBooking = (props: {
  slotSelected: CalendarSlot;
  isVisible: boolean;
  onCancel: () => void;
  onComplete: (data?: any) => void;
}) => {
  const accountId = getAccountUUID();
  const toast = useToast();
  const intl = useIntl();
  const navigateToScreen = useNavigate();
  const [userList, setUserList] = useState<IAppointmentUserDetail[]>([]);
  const [slots, setSlots] = useState<IUserSlots[]>([]);
  const [slotsLoading, setSlotsLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [selectedAppointmentType, setSelectedAppointmentType] = useState<
    IAppointmentType | undefined
  >(undefined);
  const [appointmentTypeList, setAppointmentTypeList] = useState<
    IAppointmentType[]
  >([]);
  const scrollableHeight = window.innerHeight - 160;
  const commonData = useContext(CommonDataContext);
  const isNewSettingTabEnabled = getBooleanFeatureFlag(commonData.userSettings, FeatureFlags.IS_NEW_SETTING_TAB_ENABLED);

  const [bookingData, setBookingData] = useState<IGroupBookingData>({
    selectedDate: props.slotSelected.start as Date,
    name: '',
    note: '',
    participants: [],
  });

  const [bookAppointment] = useMutation(AppointmentQueries.BOOK_APPOINTMENT);

  const appointmentTypeAPIData = useQuery(
    ScheduleEventQueries.GET_APPOINTMENT_TYPES,
    {
      variables: {
        searchString: `%%`,
        categoryCodes: [MLOV_CODES.GROUP_APPOINTMENT],
      },
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      onCompleted: (data: any) => {
        if (data && data.appointmentTypes && data.appointmentTypes.length > 0) {
          setAppointmentTypeList(data.appointmentTypes);
          handleAppointmentTypeSelection(data.appointmentTypes[0]);
        } else {
          setAppointmentTypeList([]);
        }
      },
    }
  );

  const [getUserDetails] = useLazyQuery(UserQueries.GET_USERS_WITH_IDS, {
    fetchPolicy: 'no-cache',
    onCompleted: (data: any) => {
      if (data && data.users && data.users.length > 0) {
        const list = data.users;
        if (!isEqual(list, userList)) {
          setUserList([...userList, ...data.users]);
          setUserDetailsToUserSlots(list, slots, selectedAppointmentType);
          setSlots([...slots]);
        }
      }
      setSlotsLoading(false);
    },
    onError: (error) => {

      setSlotsLoading(false);
    },
  });

  const [getAvailableSlots] = useLazyQuery(
    ScheduleQueries.GET_AVAILABLE_SLOTS,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      onCompleted: (data: any) => {
        if (data.getAvailableSlots?.availabilities && data.getAvailableSlots.availabilities.length > 0) {
          let slotsList = data.getAvailableSlots.availabilities;

          // 1. Fetch data for participants in group appointment
          const pendingListToFetch = getUserIdsToBeFetched(userList, slotsList);
          if (pendingListToFetch.length > 0) {
            getUserDetails({
              variables: {
                userIds: pendingListToFetch,
                accountId: accountId,
              },
            });
          } else {
            setSlotsLoading(false);
          }

          // 2. Remove past time slots
          slotsList = removePastSlotTimes(slotsList);

          // 3. Set data in state
          if (!isEqual(slotsList, slots)) {
            setUserDetailsToUserSlots(
              userList,
              slotsList,
              selectedAppointmentType
            );
            setSlots(slotsList);
          }
        } else if (slots.length !== 0) {
          setSlots([]);
          setSlotsLoading(false);
        } else {
          setSlotsLoading(false);
        }
      },
      onError: (error) => {

        setSlotsLoading(false);
      },
    }
  );

  const resetContent = () => {
    setUserList([]);
    setSlots([]);
    setSlotsLoading(false);
    setErrors({});
    setSelectedAppointmentType(undefined);
    setAppointmentTypeList([]);
    setBookingData({
      selectedDate: props.slotSelected.start as Date,
      name: '',
      note: '',
      participants: [],
    });
  };

  const fetchAppointmentSlots = (
    date: Date,
    appointmentType: IAppointmentType | undefined
  ) => {
    if (!appointmentType) {
      return;
    }
    setSlots([]);
    setSlotsLoading(true);
    getAvailableSlots({
      variables: {
        data: {
          slotDate: getDateStrFromFormat(
            date,
            DATE_FORMATS.DISPLAY_DATE_FORMAT
          ),
          appointmentTypeId: appointmentType?.id,
        },
      },
    });
  };

  const submitData = () => {
    const errorMessages = getUpdatedErrorMessagesForGroup(bookingData, slots);
    setErrors(errorMessages);
    if (!isInvalidGroupBooking(bookingData, slots)) {
      bookAppointment({
        variables: {
          data: getGroupAppointmentBookingData(
            bookingData,
            slots,
            selectedAppointmentType
          ),
        },
        context: {service: CARESTUDIO_APOLLO_CONTEXT},
        onCompleted: (resp) => {
          props.onComplete(resp?.createBookedAppointments[0].id);
        },
      });
    } else {
      const message =
        errorMessages.name || errorMessages.patient || errorMessages.slots;
      if (message.length > 0 && false) {
        showToast(toast, message, ToastType.error);
      }
    }
  };

  const handleDateSelection = (
    date: Date,
    appointmentType: IAppointmentType | undefined
  ) => {
    setBookingData((prev) => ({...prev, selectedDate: date}));
    setErrors({...errors, slots: ''});
    fetchAppointmentSlots(date, appointmentType);
  };

  const handleAppointmentTypeSelection = (type: IAppointmentType) => {
    setBookingData((prev) => ({
      ...prev,
      name: type.eventName,
      appointmentTypeId: type.id || '',
    }));
    setSelectedAppointmentType(type);
    if (
      type.bookWithinDays &&
      isCurrentDateInFutureComparedToOther(
        bookingData.selectedDate,
        addDaysInDate(new Date(), type.bookWithinDays)
      )
    ) {
      setSlots([]);
      setErrors({
        ...errors,
        slots: `This appointment type cannot be booked beyond ${type.bookWithinDays} days in the future.`,
      });
    } else {
      setErrors({...errors, slots: ''});
      fetchAppointmentSlots(bookingData.selectedDate, type);
    }
  };

  const onSettingPress = () => {
    const settingsPath = '/admin/schedule/types';
    if (isNewSettingTabEnabled) {
      navigateToNewSettingTab(settingsPath);
    } else {
      props.onCancel();
      navigateToScreen(settingsPath);
    }
  }

  return (
    <Drawer
      // title={intl.formatMessage({id: 'bookAppointment'})}
      title={
        <>
          <ModalActionTitle
            title={'bookAppointment'}
            titleColor={''}
            buttonList={[
              {
                show: true,
                id: 1,
                btnText: 'book',
                size: 'sm',
                textColor: Colors.Custom.mainPrimaryPurple,
                variant: BUTTON_TYPE.PRIMARY,
                isTransBtn: false,
                onClick: () => {
                  submitData();
                },
              },
            ]}
          />
        </>
      }
      destroyOnClose
      placement="right"
      onClose={() => {
        props.onCancel();
        resetContent();
      }}
      visible={props.isVisible}
      closable
      width="35%"
    >
      {!appointmentTypeAPIData.loading && appointmentTypeList.length > 0 && (
        <VStack>
          <VStack space={4} height={scrollableHeight} overflow="scroll">
            <Stack space={4} direction={['column', 'column', 'row']}>
              <View flex={1}>
                <FormControl>
                  <FormControl.Label isRequired>
                    <DisplayText textLocalId="appointmentType" />
                  </FormControl.Label>
                  <Select
                    placeholder="Select Appointment Type"
                    selectedValue={selectedAppointmentType?.id || ''}
                    onValueChange={(value: string) => {
                      const selected = appointmentTypeList.filter((type) => {
                        return type.id === value;
                      });
                      if (selected.length > 0) {
                        handleAppointmentTypeSelection(selected[0]);
                      }
                    }}
                  >
                    {appointmentTypeList.map((type) => {
                      return (
                        <Select.Item
                          label={type.eventName}
                          value={type.id || ''}
                          key={type.id || ''}
                        />
                      );
                    })}
                  </Select>
                </FormControl>
              </View>

              {/* <View flex={1}>
                <FormControl isInvalid={errors.name}>
                  <FormControl.Label isRequired>
                    <DisplayText
                      textLocalId="name"
                    />
                  </FormControl.Label>
                  <Input
                    placeholder="Appointment Name"
                    value={bookingData.name}
                    onChangeText={(value: string) => {
                      const data = {...bookingData, name: value};
                      setBookingData(data);
                      if (errors.patient) {
                        setErrors(getUpdatedErrorMessagesForGroup(data, slots));
                      }
                    }}
                  />
                  {errors.name && (
                    <FormControl.ErrorMessage
                      _text={{
                        fontSize: 'xs',
                        color: 'error.500',
                        fontWeight: 500,
                      }}
                    >
                      {errors.name}
                    </FormControl.ErrorMessage>
                  )}
                </FormControl>
              </View> */}
            </Stack>
            <Stack space={4}>
              <View flex={1}>
                <FormControl isInvalid={errors.patient}>
                  <FormControl.Label isRequired>
                    <DisplayText textLocalId="selectAppointmentPatients" />
                  </FormControl.Label>
                  <ParticipantAutoComplete
                    disableUserSearch
                    disableLeadsSearch
                    allowSingleSelectInCategory
                    placeholder="Search members"
                    showError={errors.patient}
                    defaultValue={bookingData.participants}
                    onChange={(participants: any[]) => {
                      const data = {
                        ...bookingData,
                        participants: participants,
                      };
                      setBookingData(data);
                      if (errors.patient) {
                        setErrors(getUpdatedErrorMessagesForGroup(data, slots));
                      }
                    }}
                  />
                  {errors.patient && (
                    <FormControl.ErrorMessage
                      _text={{
                        fontSize: 'xs',
                        color: 'error.500',
                        fontWeight: 500,
                      }}
                    >
                      {errors.patient}
                    </FormControl.ErrorMessage>
                  )}
                </FormControl>
              </View>
            </Stack>
            <Stack space={4} direction={['column', 'column', 'row']}>
              <View flex={1}>
                <FormControl>
                  <FormControl.Label isRequired>
                    <DisplayText textLocalId="appointmentDate" />
                  </FormControl.Label>
                  <DatePicker
                    style={{height: '40px'}}
                    allowClear={false}
                    defaultValue={getMomentObj(bookingData.selectedDate)}
                    format={DATE_FORMATS.DISPLAY_DATE_FORMAT}
                    disabledDate={(current: any) => {
                      return current && isPastDay(current);
                    }}
                    onChange={(date: any) => {
                      if (date) {
                        handleDateSelection(
                          getDateObject(date),
                          selectedAppointmentType
                        );
                      }
                    }}
                  />
                </FormControl>
              </View>
              <View flex={1}>
                <FormControl>
                  <FormControl.Label>
                    <DisplayText textLocalId="locationType" />
                  </FormControl.Label>
                  <Select
                    isDisabled
                    minWidth="200"
                    placeholder="Select"
                    selectedValue={
                      selectedAppointmentType?.locationTypeId || ''
                    }
                  >
                    {selectedAppointmentType?.locationType && (
                      <Select.Item
                        label={selectedAppointmentType?.locationType.value}
                        value={selectedAppointmentType?.locationTypeId}
                      />
                    )}
                  </Select>
                </FormControl>
              </View>
            </Stack>
            <Stack space={4} direction={['column', 'column', 'row']}>
              <FormControl isInvalid={errors.slots}>
                <FormControl.Label isRequired>
                  <DisplayText textLocalId="slots" />
                </FormControl.Label>
                <View flex={1} flexDirection="row" flexWrap="wrap">
                  {!slotsLoading && slots && slots.length > 0 && (
                    <ParticipantGroupSlots
                      userSlots={slots}
                      onChange={(updatedSlots: IUserSlots[]) => {
                        setSlots([...updatedSlots]);
                        if (errors.slots) {
                          setErrors(
                            getUpdatedErrorMessagesForGroup(
                              bookingData,
                              updatedSlots
                            )
                          );
                        }
                      }}
                    />
                  )}
                  {!slotsLoading && slots && slots.length === 0 && (
                    <DisplayText textLocalId="noSlotsAvailable" />
                  )}
                  {slotsLoading && (
                    <View {...testID(TestIdentifiers.lazyLoading)} width="full">
                      <Skeleton active />
                    </View>
                  )}
                </View>
                {!slotsLoading && errors.slots && (
                  <FormControl.ErrorMessage
                    _text={{
                      fontSize: 'xs',
                      color: 'error.500',
                      fontWeight: 500,
                    }}
                  >
                    {errors.slots}
                  </FormControl.ErrorMessage>
                )}
              </FormControl>
            </Stack>
            <Stack space={4} direction={['column', 'column', 'row']}>
              <FormControl>
                <FormControl.Label>
                  <DisplayText textLocalId="notes" />
                </FormControl.Label>
                <ReactQuill
                  className="appointment-notes"
                  theme="snow"
                  value={bookingData.note}
                  onChange={(value: string) => {
                    setBookingData((prev) => ({...prev, note: value}));
                  }}
                  modules={RTE_MODULES}
                  placeholder={intl.formatMessage({id: 'enterTextHere'})}
                />
              </FormControl>
            </Stack>
          </VStack>
          {/* <VStack>
            <Divider marginY={2} />
            <HStack>
              <Spacer />
              <Button
                rounded="3xl"
                onPress={() => {
                  submitData();
                }}
                minWidth={8}
                variant="solid"
              >
                {intl.formatMessage({id: 'book'})}
              </Button>
            </HStack>
          </VStack> */}
        </VStack>
      )}
      {appointmentTypeAPIData.loading && (
        <View margin={4} height={654} {...testID(TestIdentifiers.lazyLoading)}>
          <Skeleton active />
        </View>
      )}
      {!appointmentTypeAPIData.loading && appointmentTypeList.length === 0 && (
        <Center
          height={654}
          width="full"
          justifyContent="center"
          alignItems="center"
        >
          <Text fontWeight={200} fontSize="lg" textAlign="center">
            {/* Looks like you have't created any appointment types. */}
            <Text>
              {'\n'}Please click{' '}
              <Pressable
                key={`group-appointment-setting`}
                onPress={onSettingPress}
              >
                <Text color="primary.500">here</Text>
              </Pressable>{' '}
              to create your first appointment type and then come back again.
            </Text>
          </Text>
        </Center>
      )}
    </Drawer>
  );
};

export default GroupAppointmentBooking;
