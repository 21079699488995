import {Select, Spin} from 'antd';
import React from 'react';
import {ITemplateQueryParams} from '../../RightSideContainer/ContentManagement/ContentManagementUtils';
import {IEmailTemplateData} from '../../RightSideContainer/ContentManagement/EmailTemplates';
import {
  getEmailTemplates,
  getFormattedEmailTemplateData,
} from '../../RightSideContainer/ContentManagement/EmailTemplates/EmailTemplatesUtils';
import {
  NotificationApiResponse,
  SingleNotification,
} from '../../RightSideContainer/ContentManagement/PushNotifications/interfaces';
import {
  getFormattedPushNotifications,
  getPushNotifications,
} from '../../RightSideContainer/ContentManagement/PushNotifications/PushNotificationsUtils';
import {
  SingleSMSTemplate,
  SMSTemplateApiResponse,
} from '../../RightSideContainer/ContentManagement/SMSTemplates/interfaces';
import {
  getFormattedSMSTemplates,
  getSMSTemplates,
} from '../../RightSideContainer/ContentManagement/SMSTemplates/SMSTemplatesUtils';
import {
  AllTemplateSearchProps,
  ContentType,
  SelectedTemplate,
} from './interfaces';
import {MENTION_READ_STATUS} from '../../../constants';

const functionMap: {
  [index in ContentType]: (
    categoryQueryString?: string,
    queryParams?: ITemplateQueryParams
  ) => Promise<any>;
} = {
  email: getEmailTemplates,
  notifications: getPushNotifications,
  sms: getSMSTemplates,
};

const formatFunctionMap: {
  [index in ContentType]: (
    response: SMSTemplateApiResponse | NotificationApiResponse | any
  ) => SingleSMSTemplate[] | SingleNotification[] | IEmailTemplateData[];
} = {
  email: getFormattedEmailTemplateData,
  sms: getFormattedSMSTemplates,
  notifications: getFormattedPushNotifications,
};

const AllTemplateSearch = (props: AllTemplateSearchProps) => {
  const {contentType, categoryCode, customStyle, onSelect} = props;
  const [state, setState] = React.useState({
    loading: false,
    selected: {} as SelectedTemplate,
    searchString: '',
    apiResponse: {} as any,
    data: [] as any[],
  });
  const searchFunction = functionMap[contentType];
  const formatFunction = formatFunctionMap[contentType];

  const getTemplateData = async () => {
    if (
      state.searchString &&
      state.searchString.length < 3
      // !categoryCode
    ) {
      return;
    }
    setState((prev) => {
      return {
        ...prev,
        loading: true,
        data: [],
        selected: {} as SelectedTemplate,
      };
    });
    let categoryQueryString = '';
    if (categoryCode) {
      categoryQueryString =
        categoryCode && categoryCode.toLowerCase() !== MENTION_READ_STATUS.ALL
          ? `category=${categoryCode}`
          : '';
    } else {
      categoryQueryString = props.multipleCategoryCodes
        .map((item) => {
          return `&category=${item}`;
        })
        .join('');
    }
    const response = await searchFunction(categoryQueryString, {
      name: state.searchString,
      page: 1,
      pageSize: 10,
    });
    const formattedData = formatFunction(response);
    setState((prev) => {
      return {
        ...prev,
        loading: false,
        data: formattedData,
      };
    });
  };

  const handleOnChange = (value: string) => {
    const selected = state.data.find((item) => item.id == value);
    setState((prev) => {
      return {
        ...prev,
        selected: selected as SelectedTemplate,
      };
    });
    onSelect(selected);
  };

  React.useEffect(() => {
    getTemplateData();
  }, [state.searchString, categoryCode]);

  return (
    <Select
      size="large"
      showSearch
      allowClear
      status={props.status}
      filterOption={false}
      style={{...customStyle}}
      loading={state.loading}
      notFoundContent={state.loading && <Spin size="small" />}
      defaultValue={state.selected?.id?.toString()}
      onSearch={(value) => {
        setState((prev) => {
          return {
            ...prev,
            searchString: value,
          };
        });
      }}
      onChange={(value) => {
        handleOnChange(value);
      }}
      placeholder={'Select template'}
    >
      {state.data.map((item) => {
        return (
          <Select.Option key={item.id} value={item.id}>
            {item.name}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default AllTemplateSearch;
