import { Checkbox, HStack, Icon, IconButton, Spinner, Text, TextArea, View, VStack } from 'native-base';
import React, { useRef, useState } from 'react';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { testID } from '../../../testUtils';

interface IProps {
  id: string;

  isChecked?: boolean;
  title?: string;

  canEditTitle?: boolean;
  canEditStatus?: boolean;
  canDeleteTasks?: boolean;
  showStatus?: boolean;

  isStatusLoading?: boolean;
  showErrors?: boolean;

  onChangeTitle?: (title?: string) => void;
  onChangeIsChecked?: (isChecked: boolean) => void;
  onDelete?: (id: string) => void;
}

interface IComponentState {
  title?: string;
  isInEditMode?: boolean;
}

export function Task(props: IProps) {
  const [ componentState, setComponentState ] = useState<IComponentState>({
    title: props.title,
  });

  const newTaskTitleElementRef = useRef<any>(null);

  return (
    <HStack
      alignItems={'center'}
      width={'full'}
      space={3}
    >
      {
        props.canEditStatus &&
        <View>
          {
            !props.isStatusLoading &&
            <Checkbox
              isChecked={props.isChecked}
              value={props.id}
              width={'35px'}
              onChange={(value) => {
                props.onChangeIsChecked?.(value);
              }}
            >
            </Checkbox>
          }
          {
            props.isStatusLoading &&
            <Spinner size="sm" />
          }
        </View>
      }


      <View flex={4}>
        {
          componentState.isInEditMode &&
          <VStack>
            <TextArea
              ref={newTaskTitleElementRef}
              numberOfLines={3}
              autoComplete={'off'}
              placeholder="Please enter description"
              value={componentState.title || ''}
              onChangeText={(value) => {
                setComponentState((prev) => ({ ...prev, title: value }));
                props.onChangeTitle?.(value?.trim?.());
              }}
              onBlur={(event) => {
                if (componentState.title?.trim?.()) {
                  setTimeout(() => {
                    setComponentState((prev) => ({ ...prev, isInEditMode: false }));
                  }, 200);
                } else {
                  event?.preventDefault?.();
                }
              }}
              onKeyPress={(event) => {
                if (event?.nativeEvent?.key === 'Enter') {
                  if (componentState.title?.trim?.()) {
                    setComponentState((prev) => ({ ...prev, isInEditMode: false }));
                  }
                  event?.preventDefault?.();
                }
              }}
              {...testID('TaskField')}
            />

            {
              props.showErrors && !componentState.title?.trim?.() &&
              <Text fontSize="xs" color="error.500">
                {`Please add description`}
              </Text>
            }
          </VStack>
        }
        {
          !componentState.isInEditMode &&
          <Text {...testID('TaskTitle')}>{props.title}</Text>
        }
      </View>

      {
        props.canEditTitle &&
        !componentState.isInEditMode &&
        <View width={'32px'}>
          <IconButton
            colorScheme="gray"
            tintColor="gray.500"
            variant="ghost"
            {...testID('EditBtn')}
            onPress={() => {
              setComponentState((prev) => {
                setTimeout(() => {
                  newTaskTitleElementRef?.current?.focus?.();
                }, 200);

                return {
                  ...prev,
                  isInEditMode: true,
                };
              });
            }}
            icon={
              <Icon
                as={AntIcon}
                name="edit"
                size="4"
                color="gray.400"
              />
            }
          />
        </View>
      }

      {
        props.canDeleteTasks &&
        <View width={'32px'}>
          <IconButton
            colorScheme="gray"
            tintColor="gray.500"
            variant="ghost"
            {...testID('DeleteBtn')}
            onPress={() => {
              if (props.onDelete) {
                props.onDelete(props.id);
              }
            }}
            icon={
              <Icon
                as={AntIcon}
                name="closecircle"
                size="4"
                color="gray.400"
              />
            }
          />
        </View>
      }
    </HStack>
  );
}
