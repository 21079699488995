import { CodeableConcept, RequestGroup } from 'fhir/r4';
import React from 'react';
import AddOrUpdateExternalOrders from '../AddOrUpdateExternalOrders/AddOrUpdateExternalOrders';
import AddOrUpdateIframeOrders from '../AddOrUpdateIframeOrders/AddOrUpdateIframeOrders';
import { OrderType } from '../OrderConstants';

interface IAddOrUpdateOrdersProps {
  isVisible: boolean;
  isExternalOrder: boolean;
  printOrder?: boolean;
  patientContactId: string | number;
  orderType: OrderType;
  documentReferenceId?: string;
  unFormattedContactData: any;
  personData: any;
  selectedOrder?: RequestGroup;
  existingDiagnosis?: CodeableConcept[];
  onClose?: () => void;
}

const AddOrUpdateOrders = (props: IAddOrUpdateOrdersProps) => {
  const { isVisible, isExternalOrder, patientContactId, orderType, documentReferenceId, unFormattedContactData, personData, selectedOrder, printOrder, existingDiagnosis, onClose } = props;
  return (
    <>
      {isExternalOrder && (
        <AddOrUpdateExternalOrders
          isVisible={isVisible}
          printOrder={printOrder || false}
          patientContactId={patientContactId}
          orderType={orderType}
          documentReferenceId={documentReferenceId}
          unFormattedContactData={unFormattedContactData}
          personData={personData}
          currentOrder={selectedOrder}
          existingDiagnosis={existingDiagnosis || []}
          onClose={onClose}
        />
      )}
      {!isExternalOrder && (
        <AddOrUpdateIframeOrders
          isVisible={isVisible}
          patientContactId={patientContactId}
          orderType={orderType}
          documentReferenceId={documentReferenceId}
          unFormattedContactData={unFormattedContactData}
          personData={personData}
          selectedOrder={selectedOrder}
          onClose={onClose}
        />
      )}
    </>
  )
}

export default AddOrUpdateOrders
