export const relatedPanelDummyDataOne = {
  headerString: '1-to-1 meeting request',
  displayList: [{displayStr: 'Rohit S S', bulletPointColor: 'red'}],
  footerData: {dateStr: 'Jan 28, 2022 3:08 PM', leftStr: 'Last Updated'},
};

export const relatedPanelDummyDataTwo = {
  headerString: 'Not able to view the details',
  displayList: [
    {displayStr: 'Kaustubh M K', bulletPointColor: 'green'},
    {displayStr: 'Bhavesh P', bulletPointColor: 'black'},
  ],
  footerData: {dateStr: 'Jan 28, 2022 3:08 PM', leftStr: 'Last Updated'},
};
