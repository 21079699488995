import {Drawer} from 'antd';
import {Colors} from '../../../styles/Colors';
import {View, Dimensions} from 'react-native';
import {EMAIL_INBOX_USER_VIEW, PREFERENCES_TAB_LIST} from './PreserenceConst';
import {IPreferenceSettingDrawer, IPreferenceSettingState, IUserPreferenceData} from './PreferencesInterface';
import {useContext, useEffect, useRef, useState} from 'react';
import {useLazyQuery} from '@apollo/client';
import {UserQueries} from '../../../services';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../constants/Configs';
import {getAccountId, getUserUUID} from '../../../utils/commonUtils';
import {PreferenceSideTabList} from './PreferenceSideTabList';
import {COMMON_ACTION_CODES} from '../../../constants/ActionConst';
import {PreferenceView} from './PreferenceView';
import {getMlovIdFromCode, getMlovListFromCategory} from '../../../utils/mlovUtils';
import {MLOV_CATEGORY} from '../../../constants';
import {USER_PREFERENCE_CODE} from '../../../constants/MlovConst';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {isAccountConfigEnabled} from '../../../utils/configUtils';
import {CONFIG_CODES} from '../../../constants/AccountConfigConst';
import {ILoginUserData} from '../../../Interfaces';
import {IUsersResponse} from '../../RightSideContainer/Contacts/TeamMembers/interfaces';
import './PreferenceSetting.css'
import {PreferenceSettingHeader} from './PreferenceSettingHeader';


export const PreferencesSettingDrawer = (props: IPreferenceSettingDrawer) => {
  const addEditUserRef = useRef<any>(null);
  const [stateData, setStateData] = useState<IPreferenceSettingState>({
    selectedEmailInboxUserView: EMAIL_INBOX_USER_VIEW[1],
    preferenceData: {} as IUserPreferenceData,
    preferenceDataId: undefined,
    loading: false,
    userPreferenceLoading: false,
    selectedPreferenceTab: PREFERENCES_TAB_LIST[0],
    userProfileLoading: false,
  })
  const userUuid = getUserUUID();
  const commonData = useContext(CommonDataContext);
  const userData = commonData.userData || ({} as ILoginUserData);
  const {height} = Dimensions.get('window');
  const userPreferenceList = getMlovListFromCategory(
    commonData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.USER_PREFERENCE_TYPE
  );
  const userPreferenceId = getMlovIdFromCode(
    userPreferenceList,
    USER_PREFERENCE_CODE.USER_CONVERSATION_PREFERENCE
  );

  const onUserProfileLoadingChanges = () => {
    setStateData((prev) => {
      return {
        ...prev,
        userProfileLoading: !prev.userProfileLoading
      }
    })
  }

  const showPreferencesSetting = isAccountConfigEnabled(CONFIG_CODES.EMAIL_VIEW_USER_PREFERENCE);

  const [getUserPreference] = useLazyQuery(UserQueries.GET_USER_PREFERENCE, {
    fetchPolicy: 'no-cache',
    variables: {
      userId: userUuid,
      userPreferencesTypeId: userPreferenceId,
    },
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
    onCompleted:(data) => {
      setStateData((prev) => {
        return {
          ...prev,
          userPreferenceLoading: false,
        }
      })
      if (data?.userPreferences?.length > 0 && data?.userPreferences?.[0]?.preferencesJson) {
       const preferenceData = JSON.parse(data?.userPreferences?.[0]?.preferencesJson);
       const selectedEmailInboxUserView = {
        code: preferenceData?.userPreference?.emailInboxUserView,
        title: '',
       }
       setStateData(prev => {
        return {
          ...prev,
          preferenceData: preferenceData,
          preferenceDataId: data?.userPreferences?.[0]?.id,
          selectedEmailInboxUserView: selectedEmailInboxUserView?.code ? selectedEmailInboxUserView : prev?.selectedEmailInboxUserView,
        }
      })
      }
    }
  });

  const onActionPerformed = (actionCode: string, actionData: any) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.SELECTED_PREFERENCE_TAB_CHANGE:
        setStateData((prev) => {
          return {
            ...prev,
            selectedPreferenceTab: actionData
          }
        })
        return;
      case COMMON_ACTION_CODES.COMPLETE:
        onUserProfileLoadingChanges();
        props?.onActionPerformed(COMMON_ACTION_CODES.COMPLETE, actionData);
        return;
      case COMMON_ACTION_CODES.SAVE:
        handleSubmitFromChild();
        onUserProfileLoadingChanges();
        return;
      case COMMON_ACTION_CODES.EMAIL_PREFERENCE_CHANGE:
        setStateData((prev) => {
          return {
            ...prev,
            selectedEmailInboxUserView: actionData
          }
        })
        return;
      case COMMON_ACTION_CODES.REFETCH:
        getUserPreference();
        return;
      case COMMON_ACTION_CODES.LOADING:
        onUserProfileLoadingChanges();
        return;
      case COMMON_ACTION_CODES.LOADING_COMPLETE:
        onUserProfileLoadingChanges();
        return;
      case COMMON_ACTION_CODES.CLOSE_AUTO_REPLY:
        handleCloseFromChild();
        return;
      default:
        return;
    }
  }


  const handleSubmitFromChild = () => {
    if (addEditUserRef.current && addEditUserRef.current.handleSubmit) {
      addEditUserRef.current.handleSubmit();
    }
  };

  const handleCloseFromChild = () => {
    if (addEditUserRef.current && addEditUserRef.current.handleClose) {
      addEditUserRef.current.handleClose();
    }
  };

  useEffect(() => {
    setStateData((prev) => {
      return {
        ...prev,
        preferenceData: props?.preferenceData,
        preferenceDataId: props?.preferenceDataId || '',
        selectedEmailInboxUserView: props?.selectedEmailInboxUserView,
      }
    })
  },[])

  useEffect(() => {
    getUserPreference();
  },[])

  return (
    <Drawer
      visible={true}
      width={800}
      style={{padding: '0px'}}
      onClose={() => {
        props?.onClose();
      }}
      className='custom-drawer'
    >
      <View>
        <View>
          <PreferenceSettingHeader
            selectedPreferenceTab={stateData?.selectedPreferenceTab}
            onClose={props?.onClose}
            onActionPerformed={onActionPerformed}
            userProfileLoading={stateData?.userProfileLoading}
          />
        </View>
        <View style={{
          flexDirection: 'row',
        }}>
          <View style={{
            width: '27%',
            paddingRight: 2,
            borderRightWidth: 1,
            borderRightColor: Colors.Custom.Gray200,
          }}>
            <PreferenceSideTabList
              onActionPerformed={onActionPerformed}
              selectedPreferenceTab={stateData?.selectedPreferenceTab}
            />
          </View>
          <View style={{
            width: '73%',
            paddingHorizontal: 10,
            paddingVertical: 10,
            height: height - 56,
            overflow: 'scroll'
          }}>
            <PreferenceView
              ref={addEditUserRef}
              selectedPreferenceTab={stateData?.selectedPreferenceTab}
              selectedEmailInboxUserView={stateData?.selectedEmailInboxUserView}
              preferenceData={stateData?.preferenceData}
              preferenceDataId={stateData?.preferenceDataId}
              userPreferenceId={userPreferenceId}
              onClose={() => {
                props?.onClose();
              }}
              onActionPerformed={onActionPerformed}
              showPreferencesSetting={showPreferencesSetting}
              userData={userData}
              userEditData={props?.userEditData}
            />
          </View>
        </View>
      </View>
    </Drawer>
  );
};
