import React from 'react';
import {Path, Svg, Circle, Rect} from 'react-native-svg';

function SysIconSvg(params?: {
  strokeColor?: string;
  width?: string;
  height?: string;
}) {
  return (
    <Svg
      width={params?.width || '9'}
      height={params?.height || '10'}
      fill="none"
      viewBox="0 0 12 13"
    >
      <Rect
        x="0.686523"
        y="6.5"
        width="8"
        height="8"
        transform="rotate(-45 0.686523 6.5)"
        fill="#8DE3D4"
      />
    </Svg>
  );
}

export default React.memo(SysIconSvg);
