import React from 'react';
import {Path, Svg} from 'react-native-svg';
import {Colors} from '../../styles';

function SleepIcon(params?: {strokeColor?: string, width?: string, height?: string,strokeWidth?: number}) {
  return (
    <Svg
      width={params?.width || '16'}
      height={params?.height || '16'}
      fill="none"
      viewBox="0 0 24 24"
    >
      <Path
        fill={params?.strokeColor || Colors.Custom.mainPrimaryPurple}
        d="M22.5 12a.5.5 0 00-1 0h1zM12 2.5a.5.5 0 000-1v1zm-5.128 8.166a.5.5 0 00-.744.668l.744-.668zm4 .668a.5.5 0 00-.744-.668l.744.668zm3-.668a.5.5 0 00-.744.668l.744-.668zm4 .668a.5.5 0 00-.744-.668l.744.668zM16.75 3.567a.5.5 0 00.5.866l-.5-.866zM20.464 2l.483.13a.5.5 0 00-.733-.563l.25.433zM19 7.464l-.483-.13a.5.5 0 00.733.563L19 7.464zm3.714-1.567a.5.5 0 00-.5-.866l.5.866zm-8.416-.83a.5.5 0 00-.5.866l.5-.866zM15.78 6.5l.13.483a.5.5 0 00.12-.916l-.25.433zm-2.732.732l-.13-.483a.5.5 0 00-.12.916l.25-.433zm1.482 1.433a.5.5 0 00.5-.866l-.5.866zM21.5 12a9.5 9.5 0 01-9.5 9.5v1c5.799 0 10.5-4.701 10.5-10.5h-1zM12 21.5A9.5 9.5 0 012.5 12h-1c0 5.799 4.701 10.5 10.5 10.5v-1zM2.5 12A9.5 9.5 0 0112 2.5v-1C6.201 1.5 1.5 6.201 1.5 12h1zm6-.5c-.566 0-1.13-.28-1.628-.834l-.744.668c.635.706 1.45 1.166 2.372 1.166v-1zm1.628-.834c-.499.554-1.062.834-1.628.834v1c.921 0 1.737-.46 2.372-1.166l-.744-.668zm5.372.834c-.566 0-1.13-.28-1.628-.834l-.744.668c.635.706 1.45 1.166 2.372 1.166v-1zm1.628-.834c-.499.554-1.062.834-1.628.834v1c.921 0 1.737-.46 2.372-1.166l-.744-.668zM12.5 16a.5.5 0 01-.5.5v1a1.5 1.5 0 001.5-1.5h-1zm-.5.5a.5.5 0 01-.5-.5h-1a1.5 1.5 0 001.5 1.5v-1zm-.5-.5a.5.5 0 01.5-.5v-1a1.5 1.5 0 00-1.5 1.5h1zm.5-.5a.5.5 0 01.5.5h1a1.5 1.5 0 00-1.5-1.5v1zm5.25-11.067l3.464-2-.5-.866-3.464 2 .5.866zm2.731-2.562l-1.464 5.464.966.259 1.464-5.465-.966-.258zm-.731 6.026l3.464-2-.5-.866-3.464 2 .5.866zm-5.452-1.964l1.732 1 .5-.866-1.732-1-.5.866zm1.852.084l-2.732.732.26.966 2.731-.732-.259-.966zm-2.852 1.648l1.732 1 .5-.866-1.732-1-.5.866z"
      ></Path>
    </Svg>
  );
}

export default React.memo(SleepIcon);
