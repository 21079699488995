import {Collapse} from 'antd';
import {Content} from 'antd/lib/layout/layout';
import {
  Divider,
  HStack,
  IconButton,
  Text,
  Tooltip,
  View,
  VStack,
} from 'native-base';
import {useState} from 'react';
import {
  default as AntIcon,
  default as Icon,
} from 'react-native-vector-icons/AntDesign';
import {DATE_FORMATS} from '../../../../../../constants';
import {Colors} from '../../../../../../styles';
import {getDateStrFromFormat} from '../../../../../../utils/DateUtils';
import {DiagnosticReportView} from '../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailTables/DiagnosticReport/DiagnosticReportView';
import {styles as SingleTimelineStyles} from './../../../../../../components/PersonOmniView/MiddleContainer/SingleTimeline/SingleTimelineStyles';
import './Collapse.css';
import { TIME_LINE_SOURCE_TYPES } from '../../../../TeamInbox/Conversations/MessagingContactDetails/ContactDetailsTabs/ActivityTimeLine/FeedConst';
import { EHR, FHIR_RESOURCE } from '../../../../../../constants/FhirConstant';
import { isAccountConfigEnabled } from '../../../../../../utils/configUtils';
import { CONFIG_CODES } from '../../../../../../constants/AccountConfigConst';
import { allowContentTypeToViewHieSourceReportButton } from '../../../../TeamInbox/Conversations/MessagingContactDetails/ContactDetailsTabs/ActivityTimeLine/FeedCollapse/FeedHeaderUtils';

const {Panel} = Collapse;

const SingleVital = (props: any) => {
  let title: string = props.search_tags;
  let value: string = props.value;
  let unit: string = props.unit;

  if (props.search_tags === 'Blood Pressure') {
    const jsonData = JSON.parse(props.data);
    const [diastolic, systolic] = jsonData.component;
    const sys: number = systolic.valueQuantity.value;
    const dys: number = diastolic.valueQuantity.value;
    value = `${Math.round(sys)}/${Math.round(dys)}`;
    unit = 'mmHg';
  }

  if (props.search_tags.includes('Pain scale')) {
    title = 'Pain scale - 1-10';
  }

  return (
    <VStack
      style={{
        padding: 10,
        borderColor: Colors.Custom.BorderColor,
        borderWidth: 1,
        borderRadius: 10,
        marginBottom: 10,
        marginTop: 10,
        marginRight: 20,
        maxHeight: 100,
        maxWidth: 300,
      }}
    >
      <View style={{}}>
        <Text style={{textAlign: 'left'}} textOverflow={'ellipsis'}>
          {title}
        </Text>
      </View>
      <View
        style={{
          marginTop: 10,
        }}
      >
        <Text style={{textAlign: 'left'}} textOverflow={'ellipsis'}>
          {value} {unit}
        </Text>
      </View>
    </VStack>
  );
};

const VitalsGrid = (props: any) => {
  const {subRecordList} = props;
  return (
    <>
      <HStack
        style={{
          flexWrap: 'wrap',
          // justifyContent: 'center',
        }}
      >
        {subRecordList.map((item: any) => {
          return <>{(item.unit || item.value) && <SingleVital {...item} />}</>;
        })}
      </HStack>
    </>
  );
};

const getSingleDiagnosticReportId = (data: string) => {
  const jsonData = JSON.parse(data);
  const date = jsonData?.issued;
  const text = jsonData?.code.text;
  const result = jsonData?.result.map(
    (item: {display: string; reference: string}) => {
      return {
        display: item.display,
        reference:
          item.reference.split('/')[item.reference.split('/').length - 1],
      };
    }
  );
  return {date, text, result};
};

const getOrgDisplayText = (orgData: any) => {
  if (orgData?.display) {
    return `at ${orgData?.display}`;
  }
  if (orgData?.name) {
    if (orgData?.city && orgData?.state) {
      return `at ${orgData?.name} (${orgData?.city}, ${orgData?.state})`;
    }
    return `at ${orgData?.name}`;
  }
  return ``;
};

const getEntityDisplayText = (subRecord: any) => {
  if (subRecord.display_type == 'Condition') {
    const data = JSON.parse(subRecord.data);
    if (data?.code?.text) {
      return data.code.text;
    }
  }
  return subRecord?.search_tags || subRecord?.display_type;
};

const getPractitionerDisplayText = (practitionerData: {
  id: string;
  name: string;
}) => {
  return practitionerData?.name ? `with ${practitionerData?.name}` : '';
};

export const getAppointmentTypeText = (mainRecord: any) => {
  let displayText = '';
  const data = JSON.parse(mainRecord?.data);

  const appointmentType = data.appointmentType;
  if (
    appointmentType &&
    appointmentType?.coding &&
    appointmentType?.coding.length > 0
  ) {
    displayText = appointmentType.coding[0].display;
  }
  return displayText ? `(${displayText})` : '';
};

export const getTitleTextForAppointment = (mainRecord: any) => {
  const practitionerDisplayText = getPractitionerDisplayText(
    mainRecord?.practitionerData
  );
  const appointmentTypeText = getAppointmentTypeText(mainRecord);
  return practitionerDisplayText && appointmentTypeText
    ? `${practitionerDisplayText} ${appointmentTypeText}`
    : '';
};

export const getPanelHeaderText = (mainRecord: any, orgData: any) => {
  let data;
  const panelHeaderTextArr: string[] = [];
  let panelHeaderText = '';
  let reason = '';
  const orgDisplayText = getOrgDisplayText(orgData);
  const titleTextForAppointment = getTitleTextForAppointment(mainRecord);
  const customTitle = mainRecord.customTitle;

  if(mainRecord && mainRecord?.data) {
    data = mainRecord.data;
  }

  if (data?.reasonCode) {
    reason = data?.reasonCode?.[0]?.coding?.[0]?.display;
    if(reason) {
      panelHeaderTextArr.push(reason);
    }
  }

  if(!reason) {
    if (mainRecord?.search_tags) {
      panelHeaderTextArr.push(mainRecord?.search_tags);
    } else if (mainRecord?.display_type) {
      panelHeaderTextArr.push(mainRecord.display_type);
    }
  }

  if(orgDisplayText) {
    panelHeaderTextArr.push(orgDisplayText);
  }

  if(titleTextForAppointment) {
    panelHeaderTextArr.push(titleTextForAppointment);
  }

  panelHeaderText = panelHeaderTextArr.join(' ');

  if(Object.values(FHIR_RESOURCE).includes(panelHeaderText.trim())) {
    if (mainRecord?.resourceType && mainRecord?.resourceType === FHIR_RESOURCE.DOCUMENT_REFERENCE) {
      const resourceDataContent = mainRecord.resourceData?.content || [];
      for (const content of resourceDataContent) {
        const contentType = content?.attachment?.contentType;
        if (allowContentTypeToViewHieSourceReportButton(contentType) && (content?.attachment?.data?.length || content?.attachment?.url?.length)) {
         return customTitle || panelHeaderText || 'Note';
        }

      }
    } else if(mainRecord.resourceType === FHIR_RESOURCE.CLAIM) {
        return 'Claim'
    }
    return customTitle || '';
  }
  return panelHeaderText;
};

const FhirCollapse = (props: any) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();

  const fhirRecord = props.fhirRecord;
  if (!fhirRecord) {
    return fhirRecord;
  }
  const isHIEEnabled = isAccountConfigEnabled(CONFIG_CODES.HIE_ENABLE);

  const headerData = JSON.parse(fhirRecord.mainRecord.data);
  const codeList: Array<string> = [];
  headerData?.type?.forEach?.((data: any) => {
    data?.coding?.forEach?.((subData: any) => {
      codeList.push(subData.code);
    });
  });
  const dateStr = fhirRecord?.mainRecord?.time_stamp
    ? getDateStrFromFormat(
        fhirRecord?.mainRecord?.time_stamp,
        DATE_FORMATS.CONVERSATION_DATE_PICKER
      )
    : '';

  const mainRecord = fhirRecord?.mainRecord;
  const orgData = mainRecord?.orgData;



  const getIconName = () => {
    let iconName = 'filetext1';
    const data = JSON.parse(fhirRecord?.mainRecord?.data);
    const resourceType = data?.resourceType;
    if (resourceType === 'Appointment') {
      iconName = 'calendar';
    }
    return iconName;
  };

  const panelHeaderText = getPanelHeaderText(mainRecord, orgData);

  const getDataSource = () => {
    if (mainRecord?.source?.trim() === EHR.FOLD) {
      mainRecord.source = TIME_LINE_SOURCE_TYPES.EHR;
    }
    return mainRecord?.source ? mainRecord.source : '';
  };

  const collapsible = fhirRecord?.subList.length === 0 ? 'disabled' : undefined;

  return (
    <Content style={{paddingBottom: 0}} className="common-collapse">
      <Collapse
        defaultActiveKey={fhirRecord?.mainRecord?.id}
        expandIconPosition={'right'}
        collapsible={collapsible}
      >
        <Panel
          showArrow={fhirRecord?.subList && fhirRecord?.subList.length > 0}
          header={
            <HStack
              flex={1}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <HStack
                flex={0.9}
                style={{
                  alignItems: 'center',
                }}
              >
                <View marginX={5}>
                  <Icon
                    size={20}
                    name={getIconName()}
                    style={[
                      SingleTimelineStyles.timelineIconStyle,
                      {backgroundColor: Colors.Custom.PrimaryColor},
                    ]}
                    color={'white'}
                  />
                </View>
                <VStack flex={1}>
                  <HStack alignItems={'center'} flex={1}>
                    <Text
                      style={{
                        color: Colors.Custom.Gray900,
                        fontSize: 16,
                        lineHeight: 24,
                      }}
                      flex={1}
                    >
                      {panelHeaderText}
                    </Text>
                  </HStack>
                  <Text marginTop={2} color="gray.500">
                    {dateStr}
                  </Text>
                </VStack>
              </HStack>
              <HStack
                flex={0.1}
                style={{
                  justifyContent: 'flex-end',
                }}
              >
                {isHIEEnabled ? (
                  <Text
                    style={{
                      color: Colors.Custom.Gray500,
                      fontSize: 14,
                    }}
                  >
                    {getDataSource()?.trim()}
                  </Text>
                ) : (
                  <></>
                )}
              </HStack>
            </HStack>
          }
          key={fhirRecord?.mainRecord?.id + '_Encounter'}
        >
          <VStack padding={5}>
            <VStack padding={2}>
              {Object.keys(fhirRecord?.subListMap)?.map((key: any) => {
                let list = fhirRecord.subListMap[key];

                if (key == 'DiagnosticReport') {
                  list = list.filter((element: any) => {
                    const data = JSON.parse(element.data);
                    return data.hasOwnProperty('result');
                  });
                }

                const subValues = list.map((subRecord: any) => {
                  return (
                    <VStack>
                      <HStack
                        style={{
                          justifyContent: 'space-between',
                        }}
                      >
                        <View style={{paddingTop: 5}}>
                          <Text
                            color="gray.500"
                            style={{fontSize: 14, paddingTop: 4}}
                          >
                            {getEntityDisplayText(subRecord)}
                          </Text>
                          {subRecord.display_type == 'Vital' && (
                            <Text
                              color="gray.500"
                              style={{paddingTop: 4, fontSize: 14}}
                            >
                              {' '}
                              {(subRecord.value || '') +
                                ' ' +
                                (subRecord.unit || '')}
                            </Text>
                          )}
                        </View>
                        <View style={{height: 14, maxHeight: 14}}>
                          {subRecord.display_type === 'DiagnosticReport' && (
                            <Tooltip label="View Report" placement="top">
                              <IconButton
                                style={{width: 'fit-content', padding: 0}}
                                icon={<AntIcon name="filetext1" size={14} />}
                                onPress={() => {
                                  setSelectedRow(
                                    getSingleDiagnosticReportId(subRecord.data)
                                  );
                                  setDrawerOpen(true);
                                }}
                              />
                            </Tooltip>
                          )}
                        </View>
                      </HStack>
                    </VStack>
                  );
                });
                if (subValues.length)
                  return (
                    <VStack>
                      <Text>
                        {key == 'DiagnosticReport'
                          ? 'Diagnostic Report'
                          : key == 'MedicationRequest'
                          ? 'Medication'
                          : key}
                      </Text>
                      {key === 'Vital' ? (
                        <VitalsGrid subRecordList={list} />
                      ) : (
                        subValues
                      )}
                      {/* {subValues} */}
                      <Divider color={'gray.100'} marginY={2} />
                    </VStack>
                  );
              })}
            </VStack>
          </VStack>
        </Panel>
      </Collapse>
      {drawerOpen && (
        <DiagnosticReportView
          isOpen={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          data={selectedRow}
        />
      )}
    </Content>
  );
};

export default FhirCollapse;
