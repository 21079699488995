import {gql} from '@apollo/client';

export const RECORD_VITAL_VALUE = gql`
  mutation RecordVitalValue($data: observation_insert_input!) {
    createObservation(object: $data) {
      id
    }
  }
`;

export const GET_VITAL_DATA_WITH_TASK_IDS = gql`
  query GetVitalDataWithTaskIds($taskIds: [uuid!]) {
    observations(
      where: {is_deleted: {_eq: false}, subjectId: {_in: $taskIds}}
    ) {
      subjectId
      observationDate
      value
      valueUnit
      observationName
    }
  }
`;

export default {RECORD_VITAL_VALUE, GET_VITAL_DATA_WITH_TASK_IDS};
