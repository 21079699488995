import React, { useContext, useEffect, useState, useMemo } from 'react';
import { Badge, Divider, HStack, Icon, InfoOutlineIcon, Pressable, Skeleton, Text, View, VStack } from 'native-base';
import { GROUP_MEMBER_TYPE } from '../../../../../constants';
import { Colors } from '../../../../../styles';
import { getMlovIdFromCode, getMlovListFromCategory, getMlovValueFromId } from '../../../../../utils/mlovUtils';
import { AppointmentTasksDrawer } from '../../../../common/AppointmentTaskCheckList/AppointmentTasksDrawer';
import { getAppointmentDateTime, getCompletePracticeLocation } from '../../../../common/CalendarWidget/BookingWorkflows/BookAppointment/BookAppointmentHelper';
import { DisplayCardAvatar } from '../../../../common/DisplayCard/DisplayCardAvatar';
import { IAppointmentDetail } from '../../../LeftContainer/OtherDetails/PatientAppointmentDataView';
import { useLazyQuery, useQuery } from '@apollo/client';
import { UserQueries } from '../../../../../services';
import { getAccountUUID } from '../../../../../utils/commonUtils';
import { APPOINTMENT_STATUS_CODES, LOCATION_TYPE_CODES, MLOV_CATEGORY, USER_ROLE_CODES } from '../../../../../constants/MlovConst';
import { CommonDataContext } from '../../../../../context/CommonDataContext';
import AntIcon from 'react-native-vector-icons/AntDesign';
import MaterialIcon from 'react-native-vector-icons/MaterialIcons';
import { IAppointmentTask } from '../../../../../services/Appointment/AppointmentInterface';
import { isWeb } from '../../../../../utils/platformCheckUtils';
import { Popover, Tooltip } from 'antd';
import { IMlov } from '../../../../../Interfaces';
import UserPracticeLocationQueries from '../../../../../services/Location/UserPracticeLocationQueries';
import { IAccountPracticeLocation } from '../../../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentBookingIntefaces';
import { Dimensions, StyleSheet } from 'react-native';

interface IProps {
  appointment: IAppointmentDetail;
  canOpenAppointmentDetailsModal: boolean;
  canShowViewChecklistLink: boolean;
  canOpenAppointmentTaskModal: boolean;

  onClickAppointment?: (appointment: IAppointmentDetail) => void;
  onClickAppointmentChecklist?: (appointment: IAppointmentDetail) => void;
  onChangeAppointmentTasks?: (appointment: IAppointmentDetail, tasks: IAppointmentTask[]) => void;
}

interface IComponentState {
  appointmentUsers?: any[];
  accountPracticeLocation?: IAccountPracticeLocation;
  isAppointmentTasksModalOpen?: boolean;
  isAppointmentDetailsModalOpen?: boolean;
  locationLoading: boolean
}

export function AppointmentDetailsBanner(props: IProps) {
  const appointment = props.appointment;
  const [ componentState, setComponentState ] = useState<IComponentState>({locationLoading:false});

  const accountUUID = getAccountUUID();

  const {loading} = useQuery(UserQueries.GET_USERS_FOR_CALENDAR_WITH_LOCATION, {
    fetchPolicy: 'no-cache',
    variables: {
      accountId: accountUUID,
      roleCode: USER_ROLE_CODES.EMPLOYER,
    },
    onCompleted: (data: any) => {
      setComponentState((prev) => ({ ...prev, appointmentUsers: (data.users || []) }));
    },
    onError: (error) => {

    },
  });

  const [getAppointmentLocationDetails, locationAPIQuery] = useLazyQuery(
    UserPracticeLocationQueries.GET_ACCOUNT_LOCATIONS_DETAIL,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const commonContextData = useContext(CommonDataContext);
  const appointmentStatusList =
    getMlovListFromCategory(
      commonContextData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.APPOINTMENT_STATUS
    ) || [];

    const scheduleLocationTypeList =
    getMlovListFromCategory(
      commonContextData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.SCHEDULE_LOCATION_TYPE
    ) || [];

  const APPOINTMENT_STATUS_IDS = {
    pending: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.PENDING
    ),
    proposed: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.PROPOSED
    ),
    decline: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.DECLINED
    ),
    checkedIn: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.CHECKED_IN
    ),
    checkedOut: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.CHECKED_OUT
    ),
    cancelled: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.CANCELLED
    ),
    rescheduled: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.RESCHEDULED
    ),
    scheduled: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.SCHEDULED
    ),
  };

  const getUserNameDetail = (appointment: IAppointmentDetail) => {
    return appointment.participants.find((participant: any) => {
      return participant?.user && participant?.user?.uuid;
    })?.user?.name;
  };

  const getUserDetailData = (appointment: IAppointmentDetail) => {
    return appointment.participants.find((participant: any) => {
      return participant?.user && participant?.user?.uuid;
    })?.user as any;
  };

  const getAppointmentLocation = (appointment: IAppointmentDetail) => {
    const {locationTypeId,accountLocationId} = appointment;
    const accountPracticeLocationName =
      componentState?.accountPracticeLocation?.practiceLocation?.name;
    let locationDetail
    if (locationTypeId) {
      const locationType: IMlov =
        scheduleLocationTypeList.find((locationType) => {
          return locationType.id === locationTypeId;
        }) || ({} as IMlov);

      if (locationType?.code === LOCATION_TYPE_CODES.VIRTUAL) {
        return `${locationType.value}`;
      }
      if (
        accountLocationId &&
        componentState?.accountPracticeLocation?.practiceLocation &&
        !locationAPIQuery.loading
      ) {
        locationDetail = getCompletePracticeLocation(
          componentState?.accountPracticeLocation?.practiceLocation
        );
        if (locationDetail.trim().length || accountPracticeLocationName) {
          return `${locationDetail.trim()} ${
            accountPracticeLocationName
              ? `(${accountPracticeLocationName})`
              : ''
          }`;
        }
      }
      if (locationType.value) {
        return `${locationType.value}`;
      }
    }
    return appointment?.appointmentType?.locationType?.value || '';
  };

  const getUserRolesList = (appointment: IAppointmentDetail):string [] => {
    const userId = appointment.participants.find((participant: any) => {
      return participant?.user && participant?.user?.uuid;
    })?.user?.uuid;
    if (userId) {
      const user = componentState?.appointmentUsers?.find((user) => {
        return user.uuid === userId;
      });
      const roles = user?.userRoles?.map((role:any) => role?.userRole?.roleName)
      return roles ? Array.from(new Set(roles)) : [];
    }
    return [];
  }

  useEffect(() => {
    if (props.appointment?.accountLocationId) {
      setComponentState((prev) => ({...prev, locationLoading: true}));
      getAppointmentLocationDetails({
        variables: {
          accountLocationIds: [props.appointment.accountLocationId],
        },
      })
        .then((response) => {
          if (response?.data?.accountLocations?.length) {
            const accountPracticeLocation = response?.data?.accountLocations[0];
            setComponentState((prev) => ({
              ...prev,
              accountPracticeLocation,
              locationLoading: false,
            }));
          }
        })
        .catch((error) => {
          setComponentState((prev) => ({...prev, locationLoading: false}));
        });
    }
  },[props.appointment?.accountLocationId])

  const memoizedPopoverContent = useMemo(() => {
    const roles = getUserRolesList(appointment);
    return (
      <VStack>
        {roles.slice(2).map((role, index) => (
          <React.Fragment key={role}>
            <Text color={Colors.Custom.Gray500} px={2} py={1}>{role}</Text>
            {index !== roles.length - 3 && <Divider />}
          </React.Fragment>
        ))}
      </VStack>
    );
  }, [appointment]);

  const renderRoles = () => {
    const roles = getUserRolesList(appointment);
    if (!roles || roles.length === 0) return null;
    if (roles.length < 3) {
      return <Text color={Colors.Custom.Gray500}>{roles.join(', ')}</Text>;
    } else {
      return (
        <HStack alignItems={'center'}>
          <Text color={Colors.Custom.Gray500} isTruncated maxW={'70%'}>
            {roles.slice(0, 2).join(' • ')}
          </Text>
          <Popover
            placement='bottomLeft'
            showArrow={false}
            overlayInnerStyle={antdStyles.popoverOverlay}
            content={memoizedPopoverContent}
          >
            <Text color={Colors.Custom.Gray500}> +{roles.length - 2} more</Text>
          </Popover>
        </HStack>
      );
    }
  };

  const { locationLoading } = componentState;

  return (
    <Pressable
      isDisabled={
        !props.canOpenAppointmentDetailsModal && !props?.onClickAppointment
      }
      key={appointment.id}
      onPress={() => {
        props?.onClickAppointment?.(appointment);
        if (props.canOpenAppointmentDetailsModal) {
          return;
        }
      }}
      style={styles.pressable}
    >
      <VStack>
        <HStack my={2} alignItems="center">
          <DisplayCardAvatar
            avatarStyle={{
              avatarSize: '12',
            }}
            isLetterAvatarShow
            userData={{
              userId: getUserDetailData(appointment)?.name || '',
              userType: GROUP_MEMBER_TYPE.USER,
              name: getUserNameDetail(appointment) || '',
              userName: getUserNameDetail(appointment) || '',
            }}
          />
          <VStack ml={2} flex={1}>
            <Text fontWeight={500} fontSize={16} color={Colors.Custom.Gray900}>
              {getUserNameDetail(appointment)}
            </Text>
            {renderRoles()}
          </VStack>
        </HStack>
        <Divider />
        {appointment.reasonForVisit && (
          <HStack space={4} alignItems="center" marginLeft={4} marginTop={2}>
            <View>
              <Icon as={MaterialIcon} name="event-note" size="5" />
            </View>
            <View flex={1}>
              <Text size="smMedium">Reason For Visit:</Text>
              <Text size="xsMedium" color={Colors.Custom.Gray500}>
                {appointment.reasonForVisit?.displayName}
              </Text>
            </View>
          </HStack>
        )}
        <HStack space={4} alignItems="center" marginLeft={4} marginTop={2}>
          <View marginTop={1}>
            <Icon as={AntIcon} name="clockcircleo" size="4" />
          </View>
          <VStack space={1}>
            <HStack space={2} alignItems="flex-start" flexWrap="wrap" width={Dimensions.get('window').width * 0.7}>
              <Text
                flexShrink={1}
                numberOfLines={2}
                ellipsizeMode="tail"
                size="smMedium"
              >
                {appointment.name}
              </Text>
              {appointment.statusId && (
                <Badge
                  rounded="3xl"
                  _text={{fontSize: '2xs'}}
                  colorScheme={
                    appointment.statusId === APPOINTMENT_STATUS_IDS.checkedIn
                      ? 'success'
                      : appointment.statusId === APPOINTMENT_STATUS_IDS.checkedOut ||
                        appointment.statusId === APPOINTMENT_STATUS_IDS.decline
                      ? 'warning'
                      : 'primary'
                  }
                >
                  {getMlovValueFromId(appointmentStatusList, appointment.statusId)}
                </Badge>
              )}
            </HStack>
            <Text size="xsMedium" color={Colors.Custom.Gray500}>
              {getAppointmentDateTime(appointment)}
            </Text>
          </VStack>
        </HStack>
        {loading || componentState.locationLoading ? (
          <Skeleton.Text
            size="small"
            style={styles.skeletonText}
            lines={1}
          />
        ) : (
          <HStack space={4} alignItems="center" marginLeft={4} marginTop={2}>
            <View marginTop={1}>
              <Icon as={MaterialIcon} name="location-on" size="5" />
            </View>
            <View flex={1}>
              <Text size="smMedium">{getAppointmentLocation(appointment)}</Text>
            </View>
          </HStack>
        )}

        {isWeb() &&
          props.canShowViewChecklistLink &&
          !!appointment?.tasks?.length && (
            <View height={'20px'} marginTop={4}>
              <Pressable
                onPress={() => {
                  props.onClickAppointmentChecklist?.(appointment);
                  if (props.canOpenAppointmentTaskModal) {
                    setComponentState((prev) => ({
                      ...prev,
                      isAppointmentTasksModalOpen: true,
                    }));
                  }
                }}
              >
                <HStack color={Colors.warning[700]} space={2}>
                  <InfoOutlineIcon
                    style={styles.infoOutlineIcon}
                    size={'xs'}
                  />
                  <Text size="xs" color={Colors.warning[700]}>
                    {'View Checklist'}
                  </Text>
                </HStack>

                {componentState.isAppointmentTasksModalOpen && (
                  <AppointmentTasksDrawer
                    // appointment={appointment}
                    // tasks={appointment.tasks}
                    appointmentId={appointment.id}
                    isVisible={
                      componentState.isAppointmentTasksModalOpen || false
                    }
                    canAddNewTasks={false}
                    canDeleteTasks={false}
                    canEditStatus={true}
                    canEditTitle={false}
                    onChange={(tasks) => {
                      appointment.tasks = tasks;
                      props.onChangeAppointmentTasks?.(appointment, tasks);
                    }}
                    onClose={() => {
                      setComponentState((prev) => ({
                        ...prev,
                        isAppointmentTasksModalOpen: false,
                      }));
                    }}
                  />
                )}
              </Pressable>
            </View>
          )}
      </VStack>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  pressable: {
    // marginVertical: 16,
  },
  skeletonText: {
    width: '100%',
    marginLeft: 10,
    marginRight: 10,
  },
  infoOutlineIcon: {
    color: Colors.warning[700] as string,
    marginTop: '3px',
  },
  viewChecklistContainer: {
    height: 20,
    marginTop: 16,
  },
});

const antdStyles: Record<string, React.CSSProperties> = {
  popoverOverlay: {
    backgroundColor: Colors.Custom.BackgroundColor,
    borderRadius: 16,
  },
};
