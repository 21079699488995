import React from 'react';
import { isWeb } from '../../../utils/platformCheckUtils';
import {Image} from 'native-base';

const CareTeamIcon = () => {
  return (
    <>
      {isWeb() ? (
        <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 0.984375C9.02812 0.984375 6.60938 3.40312 6.60938 6.375C6.60938 9.34688 9.02812 11.7656 12 11.7656C14.9719 11.7656 17.3906 9.34688 17.3906 6.375C17.3906 3.40312 14.9719 0.984375 12 0.984375ZM13.875 7.07812H12.7031V8.25C12.7031 8.63906 12.3891 8.95312 12 8.95312C11.6109 8.95312 11.2969 8.63906 11.2969 8.25V7.07812H10.125C9.73594 7.07812 9.42188 6.76406 9.42188 6.375C9.42188 5.98594 9.73594 5.67188 10.125 5.67188H11.2969V4.5C11.2969 4.11094 11.6109 3.79688 12 3.79688C12.3891 3.79688 12.7031 4.11094 12.7031 4.5V5.67188H13.875C14.2641 5.67188 14.5781 5.98594 14.5781 6.375C14.5781 6.76406 14.2641 7.07812 13.875 7.07812Z"
          fill="#98A2B3"
        />
        <path
          d="M14.9016 18.7125C14.175 19.4859 13.1438 19.9688 12 19.9688C10.8562 19.9688 9.825 19.4859 9.09844 18.7125C7.88906 19.4531 7.07812 20.7891 7.07812 22.3125C7.07812 22.7016 7.39219 23.0156 7.78125 23.0156H16.2188C16.6078 23.0156 16.9219 22.7016 16.9219 22.3125C16.9219 20.7891 16.1109 19.4531 14.9016 18.7125Z"
          fill="#98A2B3"
        />
        <path
          d="M12 18.5625C13.4239 18.5625 14.5781 17.4082 14.5781 15.9844C14.5781 14.5605 13.4239 13.4062 12 13.4062C10.5761 13.4062 9.42188 14.5605 9.42188 15.9844C9.42188 17.4082 10.5761 18.5625 12 18.5625Z"
          fill="#98A2B3"
        />
        <path
          d="M21.9797 17.0719C21.2531 17.8453 20.2219 18.3281 19.0781 18.3281C17.9344 18.3281 16.9031 17.8453 16.1766 17.0719C15.975 17.1984 15.7828 17.3391 15.6047 17.4938L15.6375 17.5125C16.4438 18.0047 17.1187 18.6984 17.5875 19.5187C17.9156 20.0953 18.1406 20.7234 18.2531 21.375H23.2969C23.6859 21.375 24 21.0609 24 20.6719C24 19.1484 23.1891 17.8125 21.9797 17.0719Z"
          fill="#98A2B3"
        />
        <path
          d="M8.36719 17.5125L8.4 17.4938C8.22187 17.3391 8.02969 17.1938 7.82812 17.0719C7.09688 17.8453 6.06563 18.3281 4.92188 18.3281C3.77813 18.3281 2.74687 17.8453 2.02031 17.0719C0.810937 17.8125 0 19.1484 0 20.6719C0 21.0609 0.314062 21.375 0.703125 21.375H5.75156C5.86406 20.7234 6.08437 20.0953 6.41719 19.5187C6.88594 18.6984 7.56094 18.0047 8.36719 17.5125Z"
          fill="#98A2B3"
        />
        <path
          d="M4.92188 16.9219C6.34573 16.9219 7.5 15.7676 7.5 14.3438C7.5 12.9199 6.34573 11.7656 4.92188 11.7656C3.49802 11.7656 2.34375 12.9199 2.34375 14.3438C2.34375 15.7676 3.49802 16.9219 4.92188 16.9219Z"
          fill="#98A2B3"
        />
        <path
          d="M19.0781 16.9219C20.502 16.9219 21.6562 15.7676 21.6562 14.3438C21.6562 12.9199 20.502 11.7656 19.0781 11.7656C17.6543 11.7656 16.5 12.9199 16.5 14.3438C16.5 15.7676 17.6543 16.9219 19.0781 16.9219Z"
          fill="#98A2B3"
        />
      </svg>
      ) : (
        <Image
          resizeMode="contain"
          size={'24px'}
          source={require('../../../assets/images/Contact/care_team.png')}
          alt="image"
        />
      )}
    </>
  );
};

export default CareTeamIcon;
