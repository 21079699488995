import React, {useEffect, useRef} from 'react';
import {
  View,
  Animated,
  TouchableOpacity,
  Easing,
} from 'react-native';
import { styles } from './CustomToggleButtonStyles';
import { Colors } from '../../../styles';

interface ICustomToggleButtonProps {
  value: boolean;
  isDisabled?: boolean;
  onChange: (value: boolean) => void;
  size?: 'small' | 'large';
}

export const CustomToggleButton = (props: ICustomToggleButtonProps) => {
  const {value, isDisabled, onChange, size} = props;
  const positionButton = useRef(new Animated.Value(0)).current;

  const getStyles = () => {
    if(size === 'small') {
      return {
        containerHeight: 20,
        containerWidth: 34,
        height: 16,
        width: 16,
        outputRange: 14
      }
    }
    else {
      return {
        containerHeight: 28,
        containerWidth: 50,
        height: 24,
        width: 24,
        outputRange: 22
      }
    }
  }

  const isOnRef = useRef(false);

  useEffect(() => {
    if (isOnRef.current !== value) {
      isOnRef.current = value;
      value ? startAnimToOn() : startAnimToOff();
    }
  }, [value])

  const startAnimToOff = () => {
    Animated.timing(positionButton, {
      toValue: 0,
      duration: 10,
      easing: Easing.ease,
      useNativeDriver: false,
    }).start();
  };

  const startAnimToOn = () => {
    Animated.timing(positionButton, {
      toValue: 1,
      duration: 10,
      easing: Easing.ease,
      useNativeDriver: false,
    }).start();
  };

  const positionInterPol = positionButton.interpolate({
    inputRange: [0, 1],
    outputRange: [0, getStyles().outputRange],
  });

  const backgroundColorAnim = positionButton.interpolate({
    inputRange: [0, 1],
    outputRange: [Colors.Custom.Gray300, Colors.Custom.Primary300],
  });

  const onPress = () => {
    if (isDisabled) return;
    if (isOnRef.current) {
      startAnimToOff();
      isOnRef.current = false;
      onChange(false);
    } else {
      startAnimToOn();
      isOnRef.current = true;
      onChange(true);
    }
  };

  return (
    <View style={styles.container}>
      <TouchableOpacity
        style={{height: getStyles().containerHeight, width: getStyles().containerWidth}}
        activeOpacity={0.9}
        disabled={isDisabled}
        onPress={onPress}>
        <Animated.View
          style={[
            styles.mainStyes,
            {
              backgroundColor: value ? Colors.Custom.Primary300: Colors.Custom.Gray300,
              height: getStyles().containerHeight,
              width:  getStyles().containerWidth
            },
          ]}>
          <Animated.View
            style={[
              styles.squareStyle,
              {
                height: getStyles().height,
                width: getStyles().width,
                transform: [
                  {
                    translateX: positionInterPol,
                  },
                ],
              },
            ]}
          />
        </Animated.View>
      </TouchableOpacity>
    </View>
  );
};
