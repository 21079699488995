import React from 'react';
import {ICommonSvgProps} from './interfaces';

const CreateNewTaskSvg = (props: ICommonSvgProps) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props?.width ? props?.width : '21'}
        height={props?.height ? props?.height : '21'}
        fill="none"
        viewBox="0 0 21 21"
      >
        <path
          stroke={
            props?.customStrokeColor ? props?.customStrokeColor : '#667085'
          }
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M14.814 2.545h1.455a2.747 2.747 0 012.742 2.743v11.36a2.747 2.747 0 01-2.742 2.742H4.91a2.747 2.747 0 01-2.742-2.742V5.288a2.747 2.747 0 012.742-2.743h1.455m8.45 0c0-.822-.666-1.488-1.488-1.488H7.852c-.822 0-1.488.666-1.488 1.488m8.45 0c0 .823-.666 1.49-1.488 1.49H7.852a1.489 1.489 0 01-1.488-1.49m7.253 6.817l-4.645 4.656-1.4-1.4"
        ></path>
      </svg>
    </>
  );
};

export default CreateNewTaskSvg;
