import {Modal} from 'antd';
import {Content} from 'antd/lib/layout/layout';
import {Button, View} from 'native-base';
import React, {useState} from 'react';
import {BUTTON_TYPE, FILE_TYPE} from '../../../constants';
import {Colors} from '../../../styles';
import {FoldButton} from '../../CommonComponents/FoldButton/FoldButton';
import {DisplayText} from '../DisplayText/DisplayText';
import ModalActionBtn from '../ModalActionBtn/ModalActionBtn';

const VideoPlayerModal = (props: any) => {
  const {srcUrl, modalTitle, filetype} = props;
  const [showModal, setShowModal] = useState(true);
  const closeModal = () => {
    props.onActionPerformed();
    setShowModal(false);
  };
  return (
    <>
      <Modal
        visible={showModal}
        onCancel={() => {
          //   props.onFormCompleteAction();
          closeModal();
        }}
        closeIcon={true}
        closable={true}
        title={<DisplayText textType={'Heading'} textLocalId={modalTitle} />}
        footer={
          !props?.hideCloseButton && (
            <Button.Group justifyContent='flex-end' space={2} marginTop={3}>
              <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.SECONDARY,
                  onPress: () => {
                    closeModal();
                  },
                }}
                customProps={{
                  btnText: (
                    <DisplayText
                      textLocalId={'close'}
                      size={'smBold'}
                      extraStyles={{
                        color: Colors.Custom.mainSecondaryBrown,
                      }}
                    />
                  ),
                  withRightBorder: false,
                }}
              ></FoldButton>
            </Button.Group>
          )
        }
        style={{minWidth:'70%'}}
      >
        <View >
          {filetype === FILE_TYPE.VIDEO && (
            <video width="100%" height='500px' controls>
              <source src={srcUrl} type="video/mp4"></source>
            </video>
          )}
          {filetype === FILE_TYPE.PDF && (
            <iframe height={'600px'} src={srcUrl}></iframe>
          )}
        </View>
      </Modal>
    </>
  );
};
export default VideoPlayerModal;
