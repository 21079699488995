// Get the DateTime from the components listing.
import {Formio} from '@foldhealth/formiojs';
import NativePromise from 'native-promise-only';
import QuillConfig from '../../Builder/QuillConfig';
import ConditionalFields from '../../EditFormFields/ConditionalFields';
import KeyField from '../../EditFormFields/KeyField';
import ShareWithPatientFields from '../../EditFormFields/ShareWithPatientFields';

const FieldComponent = Formio.Components.components.field;

export default class BloodPressureComponent extends FieldComponent {
  static schema(...extend: any) {
    return FieldComponent.schema(
      {
        type: 'bloodpressure',
        label: 'Blood Pressure',
        key: 'bloodpressure',
      },
      ...extend
    );
  }

  static get builderInfo() {
    return {
      title: 'Blood Pressure',
      group: 'advanced',
      icon: 'calculator',
      weight: 2,
      documentation: '/userguide/#datetime',
      schema: BloodPressureComponent.schema(),
    };
  }

  get emptyValue() {
    return {
      systolic: '',
      diastolic: '',
    };
  }

  checkIfValidNumber(value: string): boolean {
    if (value) {
      const isNumber = /^\d+$/.test(value);

      return isNumber;
    }
    return true;
  }

  isEmpty() {
    const bloodpressureData = this.data[this.component.key];
    return !bloodpressureData.systolic || !bloodpressureData.diastolic;
  }

  hasChanged(newValue: any, oldValue: any) {
    const isChanged = super.hasChanged(newValue, oldValue);
    this.isComponentValueChanged = isChanged;
    return isChanged;
  }

  checkComponentValidity(data: any, dirty: any, row: any, options: any = {}) {
    data = data || this.rootValue;
    const {async = false} = options;
    const bloodpressureData = data[this.component.key];

    if (this.shouldSkipValidation(data, dirty, row)) {
      this.setCustomValidity('');
      return async ? NativePromise.resolve(true) : true;
    }

    if (
      this.component.validate.required &&
      this.isComponentValueChanged &&
      (!bloodpressureData.systolic || !bloodpressureData.diastolic)
    ) {
      const message = this.t('Blood pressure is required');
      this.setCustomValidity(message, dirty);
      return false;
    }

    if (!this.checkIfValidNumber(bloodpressureData.systolic)) {
      const message = this.t('Please add valid Systolic value');
      this.setCustomValidity(message, dirty);
      return false;
    }

    if (!this.checkIfValidNumber(bloodpressureData.diastolic)) {
      const message = this.t('Please add valid Diastolic value');
      this.setCustomValidity(message, dirty);
      return false;
    }

    return super.checkComponentValidity(data, dirty, row, options);
  }

  getValue() {
    return {
      systolic: this.refs[this.getKeys().systolic].value,
      diastolic: this.refs[this.getKeys().diastolic].value,
    };
  }

  setValue(value: any) {
    if (!value) {
      return;
    }
    this.refs[this.getKeys().systolic].value = value.systolic;
    this.refs[this.getKeys().diastolic].value = value.diastolic;
  }

  getKeys() {
    return {
      systolic: `${this.component.key}-systolic`,
      diastolic: `${this.component.key}-diastolic`,
    };
  }

  render() {
    let table = '<div class="bp-data">';
    table += this.renderCell('systolic', 'Systolic');
    table += '<div class="bp-seperator"><p>/</p></div>';
    table += this.renderCell('diastolic', 'Diastolic');
    table += '<div class="bp-unit"><p>mmHg</p></div>';
    table += '</div>';
    return super.render(table);
  }

  attach(element: any) {
    const systolicKey = this.getKeys().systolic;
    const diastolicKey = this.getKeys().diastolic;
    const refs: any = {};
    refs[systolicKey] = 'single';
    refs[diastolicKey] = 'single';
    this.loadRefs(element, refs);

    this.addEventListener(this.refs[systolicKey], 'input', () => {
      this.updateValue();
    });
    this.addEventListener(this.refs[diastolicKey], 'input', () => {
      this.updateValue();
    });

    // Allow basic component functionality to attach like field logic and tooltips.
    return super.attach(element);
  }

  renderCell(valueType: string, plceholder: string) {
    return this.renderTemplate('input', {
      input: {
        type: 'input',
        ref: `${this.component.key}-${valueType}`,
        attr: {
          id: `${this.component.key}-${valueType}`,
          class: `form-control bp-${valueType}`,
          type: 'number',
          placeholder: plceholder,
          pattern: '[0-9.]*',
          min: 0,
        },
      },
    });
  }
}

BloodPressureComponent.editForm = function () {
  return {
    key: 'display',
    components: [
      {
        type: 'oldtextfield',
        key: 'label',
        label: 'Label',
        input: true,
        validate: {
          required: true,
        },
      },
      {
        type: 'checkbox',
        key: 'validate.required',
        label: 'Required',
        input: true,
      },
      {
        type: 'textarea',
        input: true,
        key: 'description',
        label: 'Description',
        placeholder: 'Description for this field.',
        tooltip:
          'The description is text that will appear below the input field.',
        editor: 'quill',
        wysiwyg: QuillConfig,
      },
      {
        type: 'checkbox',
        label: 'Hide Label',
        tooltip:
          'Hide the label or title of this component. This allows you to show the label in the form builder, but not when the form is shown to the member.',
        key: 'hideLabel',
        input: true,
      },
      ...ShareWithPatientFields,
      ...ConditionalFields,
      ...KeyField,
    ],
  };
};

Formio.Components.addComponent('bloodpressure', BloodPressureComponent);
