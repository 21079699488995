import {GROUP_MEMBER_TYPE, GROUP_TYPES} from "../../../constants";
import BaseService from "../../../services/CommonService/BaseService";
import { IGroupMember } from "../../../services/Conversations/interfaces";
import { getAccountUserByUserId } from "../../RightSideContainer/TeamInbox/Conversations/ConversationChannelNew/ConversationSidebarUtils";
import {patientNotLoggedInError} from "../../RightSideContainer/TeamInbox/Conversations/ConversationContainer/ConversationConst";

export const getUpdatedErrorMessages = (createChannelStateData: any): any => {
  let patientErrorString = '';
  let chatNameErrorString = '';
  let internalUserErrorString = '';
  let isErrorPresent = false;
  if (
    !createChannelStateData?.formData?.patient &&
    createChannelStateData.type === GROUP_TYPES.PRIVATE
  ) {
    patientErrorString = 'Please select patient';
    isErrorPresent = true;
  } else if (
    createChannelStateData?.errors?.patient &&
    createChannelStateData.type === GROUP_TYPES.PRIVATE
  ) {
    patientErrorString = patientNotLoggedInError;
    isErrorPresent = true;
  }
  if (
    !createChannelStateData?.formData?.channelName && 
    createChannelStateData.type !== GROUP_TYPES.INTERNAL
  ) {
    chatNameErrorString = 'Please enter group name';
    isErrorPresent = true;
  } else if (
    createChannelStateData?.errors?.patient &&
    createChannelStateData.type === GROUP_TYPES.PATIENT_BROADCAST
  ) {
    patientErrorString = patientNotLoggedInError;
    isErrorPresent = true;
  }
  if (
    (!createChannelStateData?.formData?.internalUsers ||
      createChannelStateData?.formData?.internalUsers?.length < 1) &&
    createChannelStateData.type === GROUP_TYPES.INTERNAL
  ) {
    internalUserErrorString = 'Please select internal users';
    isErrorPresent = true;
  }

  return {
    errors: {
      patient: patientErrorString,
      chatNameErrorString: chatNameErrorString,
      internalUserErrorString: internalUserErrorString,
    },
    isErrorPresent,
  };
};

export const getGroupInternalUserUuids = (groupMembers: IGroupMember[]) => {
  if (groupMembers) {
    const groupMemberUuidList = [] as string[];
    groupMembers?.forEach((item) => {
      if (
        item?.user?.uuid &&
        item?.groupUserType?.code === GROUP_MEMBER_TYPE.USER
      ) {
        if (
          item?.user &&
          getAccountUserByUserId(item?.user?.accountUsers, item?.user?.id)?.[0]
            ?.isActive === true
        ) {
          groupMemberUuidList.push(item?.user?.uuid);
        }
      }
    });
    return groupMemberUuidList;
  }
  return [];
};

export const leaveGroupApi = async (bodyData: any, accountId?: number) => {
  const baseService = BaseService.getSharedInstance().axios;
  const url = `crm-nest/account/${accountId}/leave-group`;
  const response = await baseService.put(url, bodyData);
  return response?.data;
};
