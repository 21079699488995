import React from 'react';
import {Colors} from '../../../../styles';
import {
  Divider,
  HStack,
  Spacer,
  VStack,
  View,
  Text,
  FlatList,
} from 'native-base';
import MemberInfoListItem from '../../MemberInfoListItem/MemberInfoListItem';
import {IContact} from '../../../RightSideContainer/TeamInbox/Conversations/interfaces';
import {ActionsView} from '../../../RightSideContainer/Contacts/Leads/LeadView/LeadTableView/ActionsView';
import SmsActionSvg from '../../Svg/PersonActionSvgIcons/SmsActionSvg';
import ChatActionSvg from '../../Svg/PersonActionSvgIcons/ChatActionSvg';
import EmailActionSvg from '../../Svg/PersonActionSvgIcons/EmailActionSvg';
import {PersonActions} from '../../PersonActions/PersonActions';
import {PATIENT_QUICK_PROFILE_PARENT_CODES} from '../../../../constants/ActionConst';
import {getSecondaryValue} from '../../../../utils/commonUtils';
import {StyleSheet} from 'react-native';
import MemberContactInfo from './MemberContactInfo';

export interface IInfoItem {
  label: string;
  value: string;
}

interface IInfoSectionProps {
  infoItems: IInfoItem[];
  contactData: IContact | undefined;
  actionView: JSX.Element;
}

const InfoSection = (props: IInfoSectionProps) => {
  const columnOne = props.infoItems.filter((_, index) => index % 2 === 0);
  const columnTwo = props.infoItems.filter((_, index) => index % 2 !== 0);
  const renderInfo = (label: string, value: string, showBorder: boolean) => {
    return (
      <VStack
        style={[
          styles.infoContainer,
          {
            borderRightWidth: showBorder ? 1 : 0,
            borderRightColor: Colors.FoldPixel.GRAY150,
          },
        ]}
        gap={10}
      >
        <Text style={styles.infoContainerTitle} numberOfLines={1}>
          {label}
        </Text>
        <Text style={styles.infoContainerValue} numberOfLines={1}>
          {value}
        </Text>
      </VStack>
    );
  };

  return (
    <View style={styles.container} maxW={'100%'}>
      <HStack alignItems={'center'} flex={1} w='100%'>
        <MemberContactInfo
          contactData={props?.contactData}
          showDateOfBirth={true}
        />
        <HStack alignItems={'center'}>
          {props.actionView}
        </HStack>
      </HStack>
      {props.infoItems.length > 0 && (
        <>
          <View
            my={3}
            borderTopWidth={1}
            borderColor={Colors.FoldPixel.GRAY150}
            flex={1}
          />
          <HStack>
            <FlatList
              data={columnOne}
              keyExtractor={(item, index) => index.toString()}
              renderItem={({item, index}) =>
                renderInfo(
                  item.label,
                  item.value,
                  index !== columnOne.length - 1
                )
              }
              style={{
                flex: 1,
              }}
            />
            <FlatList
              data={columnTwo}
              keyExtractor={(item, index) => index.toString()}
              renderItem={({item, index}) =>
                renderInfo(item.label, item.value, false)
              }
              style={{
                flex: 1,
              }}
            />
          </HStack>
        </>
      )}
    </View>
  );
};

export default InfoSection;

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.FoldPixel.GRAY50,
    padding: 10,
    borderRadius: 12,
    width: '100%',
    borderWidth: 1,
    borderColor: Colors.FoldPixel.GRAY150,
  },
  infoContainer: {
    width: '100%',
    marginVertical: 5,
    paddingLeft: 8,
  },
  infoContainerTitle: {
    color: Colors.FoldPixel.GRAY200,
    fontSize: 12,
  },
  infoContainerValue: {
    color: Colors.FoldPixel.GRAY400,
    fontSize: 14,
  },
});
