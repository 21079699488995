import {Button as AntButton, Drawer, Progress, Upload, notification} from 'antd';
import {RcFile, UploadFile} from 'antd/lib/upload/interface';
import {Button, Center, HStack, useToast, View, VStack} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import {BUTTON_TYPE, MLOV_CATEGORY, SENDER_TYPE} from '../../../../../../constants';
import {CONVERSATION_ACTION_CODES} from '../../../../../../constants/ActionConst';
import {CommonDataContext} from '../../../../../../context/CommonDataContext';
import CommonService from '../../../../../../services/CommonService/CommonService';
import {Colors} from '../../../../../../styles';
import {formatToLink, getAccountUUID, getMsgEchoId, getUserUUID} from '../../../../../../utils/commonUtils';
import {getDateToMomentISOString} from '../../../../../../utils/DateUtils';
import {getMlovCodeIdObj} from '../../../../../../utils/mlovUtils';
import {DisplayText} from '../../../../../common/DisplayText/DisplayText';
import {sendEmailMessageAPI} from '../../ConversationChannelTabs/CreateSmsConversationDrawer/SmsConversationServices';
import {getConversationMentionsData, getUserIdListFromMsgContent, getUserIdsFromMsgContent, sendNotificationOnMention} from '../ConversationMentions/ConversationMentions';
import {messageDataKeysConverter} from '../MessagingUtils';
// import './AttachmentCss.css';
import { AttachmentDrawerProps } from './interface';
import { ToastType, showPhiWarningToast, showToast } from '../../../../../../utils/commonViewUtils';
import {ModalActionTitle} from '../../../../../common/ModalActionTitle/ModalActionTitle';
import { IAttachmentComponentState } from '../../../../../common/AddTask/interfaces';
import { IMediaLibraryData } from '../../../../ContentManagement/MediaLibrary/interfaces';
import { MEDIA_TYPES, MSG_ATTACHMENT_MIME_TYPE, WORD_DOC_MIME_TYPES, getMediaTypeFromMimeType } from '../../../../../common/DocumentViewer/DocumentViewerHelper';
import CareStudioService from '../../../../../../services/CommonService/CareStudioService';
import { getBase64 } from '../../../../../PersonOmniView/LeftContainer/RecentActivity/RecentReport/ReportUtils';
import { DocumentViewer } from '../../../../../common/DocumentViewer/DocumentViewer';
import { useIntl } from 'react-intl';
import {IMessageResp} from '../interface';
import {useMutation} from '@apollo/client';
import ConversationsQueries from '../../../../../../services/Conversations/ConversationsQueries';
const AttachmentDrawer = (props: AttachmentDrawerProps) => {
  const {
    isDrawerVisible,
    fileList,
    onDrawerActionPerformed,
    conversationData,
    onMsgSend,
    privateNote,
    message
  } = props;
  const [attachmentDrawerState, setAttachmentDrawerState] = useState({
    fileList: [] as UploadFile<any>[],
    isLoading: false,
    isSendingAttachments: false,
    progressPercent: 0,
  });
  const accountUUID = getAccountUUID();
  const userUUID = getUserUUID();
  const [createConversationMentions] = useMutation(
    ConversationsQueries.createConversationMentions
  );
  const toast = useToast();
  const intl = useIntl();
  const careStudioInstance = CareStudioService.getCareStudioServiceInstance();
  const [componentState, setComponentState] =
    useState<IAttachmentComponentState>({
      progress: 0,
      isLoading: false,
      showErrors: false,
      isPreviewVisible: false,
      previewTitle: undefined,
      isFileLoading: {},
    });

  const commonData = useContext(CommonDataContext);
  const mlovDataObj = commonData.MLOV;
  const groupMemberTypeList = mlovDataObj[MLOV_CATEGORY.GroupMemberType];
  const groupMemberCodeIdObj = getMlovCodeIdObj(groupMemberTypeList);
  const isSideCarContext = commonData.sidecarContext?.isSidecar;

  const onClose = () => {
    onDrawerActionPerformed(CONVERSATION_ACTION_CODES.DRAWER_CLOSE);
    setAttachmentDrawerState((prev) => {
      return {
        ...prev,
        fileList: [],
        isLoading: false,
      };
    });
  };

  const updateConversationMentionsData = async (messageData: IMessageResp) => {
    if (messageData?.content) {
      const userListData = getUserIdsFromMsgContent(
        messageData.content,
        groupMemberCodeIdObj
      );
      if (userListData?.length) {
        if (messageData.id) {
          const conversationMentionData = getConversationMentionsData({
            accountUUID,
            userUUID,
            msgData: {
              id: messageData?.id,
              uuid: messageData?.uuid || '',
              createdAt: messageData?.currentTime
            },
            selectedConversationData: conversationData,
            userList: userListData,
          });
          const mentionResponse = await createConversationMentions({
            variables: {
              mentionDataList: conversationMentionData,
            },
          });
          await sendNotificationOnMention({
            mentionResponse:
              mentionResponse?.data?.createConversationMentions?.returning || [],
            conversationUuid: conversationData.uuid,
            displayId: conversationData.displayId
          });
        }
      }
    }
  };

  const sendAttachments = () => {
    if (attachmentDrawerState?.fileList?.length) {
      setAttachmentDrawerState((prev) => {
        return {
          ...prev,
          isSendingAttachments: true,
        };
      });
      const commonService = CommonService.getCommonServiceInstance();
      const axiosService = commonService.axiosService;
      const accountId = conversationData.accountId;
      const conversationId = conversationData.displayId;
      let ccEmails = '';
      if (conversationData?.conversationInbox?.channelEmail?.forwardRouteId && conversationData?.conversationInbox?.channelEmail?.forwardToEmail) {
        ccEmails = conversationData?.conversationInbox?.channelEmail?.forwardToEmail;
      }
      // const htmlString = richTextValue || '';
      //let plainString = parseHtmlToPlainText(htmlString) || '';
      const formData = new FormData();
      formData.append('echo_id', getMsgEchoId());
      formData.append('private', privateNote);
      formData.append('cc_emails', ccEmails);
      formData.append('bcc_emails', '');
      const formattedMessage = message
      if (formattedMessage) {
        formData.append('content', formattedMessage);
      }
      if (
        attachmentDrawerState.fileList &&
        attachmentDrawerState.fileList.length
      ) {
        attachmentDrawerState.fileList.forEach((fileObj) => {
          if (fileObj.originFileObj) {
            fileObj.originFileObj;
            formData.append('attachments[]', fileObj.originFileObj as any);
          }
        });
      }

      let userMentionIdStr = '';
      if (groupMemberCodeIdObj && Object.keys(groupMemberCodeIdObj)?.length && message) {
        const userMentionIds = getUserIdListFromMsgContent(message, groupMemberCodeIdObj);
        if (userMentionIds?.length) {
          userMentionIdStr = userMentionIds.toString();
          formData.append('user_mention_ids', userMentionIdStr);
        }
      }

      if (conversationData?.conversationInbox?.channelEmail?.forwardRouteId && props.privateNote == 'false') {
        sendEmailMessageAPI({
          private: false,
          content: '',
          conversationDisplayId: conversationData?.displayId,
          inboxId: conversationData?.inboxId,
          subject: conversationData?.additionalAttributes?.mail_subject || '',
          user_mention_ids: userMentionIdStr
        }).then(async (responseMsg: any) => {
          if (responseMsg && responseMsg.data) {
            responseMsg.data['sender_type'] = SENDER_TYPE?.USER;
            const currentTime = getDateToMomentISOString();
            const messageRepData = messageDataKeysConverter(responseMsg.data);
            const responseData = {...messageRepData, currentTime};
            onMsgSend(formattedMessage || '', responseData);
            await updateConversationMentionsData(responseData);
            setAttachmentDrawerState((prev) => {
              return {
                ...prev,
                fileList: [],
                isSendingAttachments: false,
                progressPercent: 0,
              };
            });
            onDrawerActionPerformed(CONVERSATION_ACTION_CODES.SEND_ATTACHMENT, {
              fileList: attachmentDrawerState.fileList,
            });
          }
        })
          .catch(() => {
            setAttachmentDrawerState((prev) => {
              return {
                ...prev,
                uploadingMsg: false,
                isSendingAttachments: false,
                progressPercent: 0,
              };
            });
          });
      } else {
        axiosService
          .post(
            `/accounts/${accountId}/conversations/${conversationId}/messages`,
            formData,
            {
              headers: {
                'content-type': 'multipart/form-data',
              },
              onUploadProgress: (event: any) => {
                const percent = Math.floor((event.loaded / event.total) * 100);

                setAttachmentDrawerState((prev) => {
                  return {
                    ...prev,
                    progressPercent: percent,
                  };
                });
              },
            }
          )
          .then(async (responseMsg: any) => {
            if (responseMsg && responseMsg.data) {
              responseMsg.data['sender_type'] = SENDER_TYPE?.USER;
              const currentTime = getDateToMomentISOString();
              const responseData = {...responseMsg.data, currentTime};
              onMsgSend(formattedMessage || '', responseData);
              await updateConversationMentionsData(responseData);
              setAttachmentDrawerState((prev) => {
                return {
                  ...prev,
                  fileList: [],
                  isSendingAttachments: false,
                  progressPercent: 0,
                };
              });
              onDrawerActionPerformed(CONVERSATION_ACTION_CODES.SEND_ATTACHMENT, {
                fileList: attachmentDrawerState.fileList,
              });
            }
          })
          .catch(() => {
            setAttachmentDrawerState((prev) => {
              return {
                ...prev,
                uploadingMsg: false,
                isSendingAttachments: false,
                progressPercent: 0,
              };
            });
          });
      }


    }
  };

  useEffect(() => {
    setAttachmentDrawerState((prev) => {
      return {
        ...prev,
        fileList: fileList,
      };
    });
  }, [props.fileList]);

  const handlePreview = async (file: UploadFile) => {
    if (componentState.isFileLoading[(file as any).uid]) {
      return;
    }
    const mediaType = getMediaTypeFromMimeType(file.type || '');
    if (
      !(
        MSG_ATTACHMENT_MIME_TYPE.includes(file.type as string) ||
        mediaType === MEDIA_TYPES.IMAGE
      )
    ) {
      notification.info({
        message: intl.formatMessage({id: 'noPreviewAvailable'}),
        duration: 1.0,
        placement: 'topRight',
      });
      return;
    }

    let fileUrl = file?.url || '';
    if (
      !(file as any).id &&
      file?.type &&
      WORD_DOC_MIME_TYPES.includes(file?.type)
    ) {
      await convertToPdf(file);
    }

    const fileMediaData: IMediaLibraryData = {
      ...file,
      lastUpdated: file.lastModified?.toString() || '',
      mime: file.type as string,
      name: file.name,
      size: file?.size as number,
    };

    if (!(file as any).blobUrl && !file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    if (file.type && !fileUrl) {
      fileUrl =
        (file as any).blobUrl ||
        file.preview ||
        URL.createObjectURL(file.originFileObj as any);
    }
    setComponentState((prev) => ({
      ...prev,
      isPreviewVisible: true,
      previewTitle:
        file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1),
      ...(file.type &&
        fileUrl && {
          previewObj: {
            url: fileUrl || '',
            type: file.type as string,
            fileMediaData: fileMediaData,
          },
        }),
    }));
  };

  const convertToPdf = async (file: any) => {
    const service = careStudioInstance.careStudioAxiosService;
    setComponentState((prev) => {
      prev.isFileLoading[file.uid] = true;
      return {...prev, isFileLoading: prev.isFileLoading};
    });

    try {
      const formData = new FormData();
      formData.append('file', file.originFileObj);
      const response = await service.post(
        `/attachment/convert-to-pdf`,
        formData,
        {
          responseType: 'blob',
        }
      );

      file.blob = response.data;
      file.blobUrl = URL.createObjectURL(file.blob);
      file.preview = await getBase64(response.data);

      setComponentState((prev) => {
        prev.isFileLoading[file.uid] = false;
        return {...prev, isFileLoading: prev.isFileLoading};
      });
    } catch (error) {
      showToast(
        toast,
        intl.formatMessage({id: 'errorOpeningFile'}),
        ToastType.error
      );
      setComponentState((prev) => {
        prev.isFileLoading[file.uid] = false;
        return {...prev, isFileLoading: prev.isFileLoading};
      });
    }
  };

  const handlePreviewCancel = (value = false) => {
    setComponentState((prev) => ({
      ...prev,
      previewObj: undefined,
      isPreviewVisible: value,
      previewTitle: undefined,
    }));
  };


  return (
    <View flex={1} height="full">
      <>
        <Drawer
          title={
            <ModalActionTitle
              title={'Attachments'}
              titleSize={'24'}
              buttonList={[
                {
                  ...(isSideCarContext && {
                    show: true,
                    id: 1,
                    btnText: 'back',
                    textColor: Colors.Custom.mainSecondaryBrown,
                    variant: BUTTON_TYPE.SECONDARY,
                    isTransBtn: false,
                    onClick: () => {
                      onClose();
                    },
                  }),
                },
                {
                  show: true,
                  id: 1,
                  btnText: 'sendAttachments',
                  textColor: Colors.Custom.mainPrimaryPurple,
                  variant: BUTTON_TYPE.PRIMARY,
                  isTransBtn: false,
                  onClick: () => {
                    sendAttachments();
                  },
                  isLoading: attachmentDrawerState.isSendingAttachments
                },
              ]}
            />
          }
          placement={'right'}
          width={isSideCarContext ? '100%' : '30%'}
          onClose={onClose}
          closable
          visible={isDrawerVisible}
        >
          <View mb={4}>
            {showPhiWarningToast('Max file size: 100MB', '', '', 16)}
          </View>

          {attachmentDrawerState.isSendingAttachments &&
          attachmentDrawerState.progressPercent > 0 ? (
            <View
              height={'full'}
              width={'full'}
              position="absolute"
              zIndex={10}
              bg={'coolGray.50:alpha.70'}
            >
              <Center
                top={'30%'}
                justifyContent="center"
                alignItems="center"
                alignContent="center"
              >
                <Progress
                  type="circle"
                  percent={attachmentDrawerState.progressPercent}
                />
              </Center>
            </View>
          ) : (
            <></>
          )}
          <VStack height="full">
            <VStack>
              <DisplayText textType="Heading" textLocalId="attachments" />
              <Upload
                className="attachment-drawer"
                listType="picture"
                style={{background: ''}}
                multiple={true}
                beforeUpload={() => false}
                onPreview={handlePreview}
                onChange={(info) => {
                  const tempFileList = [...info.fileList];
                  setAttachmentDrawerState((prev) => {
                    return {
                      ...prev,
                      fileList: tempFileList,
                    };
                  });
                }}
                onRemove={(file) => {
                  setAttachmentDrawerState((prev) => {
                    const index = prev.fileList.indexOf(file);
                    const newFileList = prev.fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                      ...prev,
                      fileList: newFileList,
                    };
                  });
                }}
                fileList={attachmentDrawerState.fileList}
              ></Upload>
              <HStack flex={1} pt={10} justifyContent={'space-between'}>
                <VStack flex={0.4}>
                  <Upload
                    listType="picture"
                    multiple={true}
                    beforeUpload={() => false}
                    onChange={(info) => {
                      const tempFileList = [
                        ...attachmentDrawerState.fileList,
                        ...info.fileList,
                      ];
                      if (tempFileList.length > 10) {
                        tempFileList.splice(10);
                      }
                      setAttachmentDrawerState((prev) => {
                        return {
                          ...prev,
                          fileList: tempFileList,
                        };
                      });
                    }}
                    onRemove={(file) => {
                      setAttachmentDrawerState((prev) => {
                        const index = prev.fileList.indexOf(file);
                        const newFileList = prev.fileList.slice();
                        newFileList.splice(index, 1);
                        return {
                          ...prev,
                          fileList: newFileList,
                        };
                      });
                    }}
                    fileList={[]}
                  >
                    <AntButton
                      disabled={attachmentDrawerState.fileList.length >= 10}
                      type="primary"
                      shape="round"
                      color={Colors.primary[500]}
                    >
                      Add More (Max: 10)
                    </AntButton>
                  </Upload>
                </VStack>
              </HStack>
            </VStack>
          </VStack>
          {componentState.previewTitle &&
          componentState.previewObj?.type &&
          componentState.isPreviewVisible && (
            <DocumentViewer
              fileName={componentState.previewTitle}
              fileType={componentState.previewObj.type}
              onClose={handlePreviewCancel}
              fileUrl={componentState.previewObj.url}
              isOpen={true}
              mediaPlayerHeight={'75%'}
              mediaPlayerWidth={'75%'}
            />
          )}
        </Drawer>
      </>
    </View>
  );
};

export default AttachmentDrawer;
