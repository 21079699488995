import {Coding, Extension, Observation, Patient} from 'fhir/r4';
import {
  CONTACT_SOURCE_OF_CREATION,
  PERSON_TYPES,
  SEXUAL_ORIENTATION_OTHER_CODE,
} from '../../../../../../constants';
import {CONFIG_CODES} from '../../../../../../constants/AccountConfigConst';
import {IAllowedOperation, IEhrCapability} from '../../../../../../Interfaces';
import {getVitalsWithFilters} from '../../../../../../services/CommonService/AidBoxService';
import {getResourceAbilities} from '../../../../../../utils/capabilityUtils';
import {isEmptyArray, isEmptyObject, unmaskPhoneNumber} from '../../../../../../utils/commonUtils';
import {isAccountConfigEnabled} from '../../../../../../utils/configUtils';
import {getMomentObj} from '../../../../../../utils/DateUtils';
import {Vital} from '../../../../../../utils/VitalUtils';
import {getWorkflowSelectedList} from '../../../../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentBookingHelper';
import { getExtensionValue } from '../../../../../PersonOmniView/MiddleContainer/Orders/OrdersAndReports/OrderUtils';
import {IPersonData} from '../../../../../PersonOmniView/PersonHeaderBar/interfaces';
import {CapabilityResource} from '../../../../Forms/FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper';
import {getMissingFields} from '../../../ContactsUtils';
import {getGenderNameAndId} from '../../../Customer/CustomerView/AddOrUpdateCustomer/AddOrUpdateCustomerUtils';
import {checkIfAgeIsLessThan18, getFieldConfig} from './AddOrUpdateUtils';

export const createContactQueryObj = (
  contactObj: any,
  accountId: any,
  userId: any,
  patientId?: any,
  accountUuid?: any,
  defaultPracticeLocation?: any,
  addTagObjectList?: any
  // customerTypeId?: string
) => {
  const mutableObj: any = contactObj;
  const newQueryObj: any = {
    accountId: accountId,
    personAddress: {
      data: {
        accountId: accountId,
      },
    },
    personContact: {
      data: [],
    },
    contactPracticeLocations: {
      data: [],
    },
    contactTagging: {
      data: [],
    },
    // person: {
    //   data: {
    //   }
    // }
  };
  if (!!mutableObj.note) {
    newQueryObj.notes = {
      data: {userId: userId, content: mutableObj.note, accountId: accountId},
    };
  }
  if (mutableObj.leadId) {
    newQueryObj.leadId = mutableObj.leadId;
  }
  if (mutableObj.firstName) {
    newQueryObj.name =
      mutableObj.firstName +
      ' ' +
      mutableObj.middleName +
      ' ' +
      mutableObj.lastName;
  }
  if (mutableObj.email) {
    newQueryObj.email = mutableObj.email;
    newQueryObj.personContact.data.push({
      typeId: mutableObj.emailContactTypeId,
      value: mutableObj.email,
      accountId: accountId,
    });
  }
  if (mutableObj.phone) {
    newQueryObj.phoneNumber = mutableObj.phone;
    newQueryObj.personContact.data.push({
      typeId: mutableObj.phoneContactTypeId,
      value: mutableObj.phone,
      accountId: accountId,
    });
  }
  if (mutableObj.cityId) {
    newQueryObj.personAddress.data.cityId = mutableObj.cityId;
  }
  if (mutableObj.zipCodeId) {
    newQueryObj.personAddress.data.zipcodeId = mutableObj.zipCodeId;
  }
  if (mutableObj.stateId) {
    newQueryObj.personAddress.data.stateId = mutableObj.stateId;
  }
  if (!mutableObj.cityId && !mutableObj.zipCodeId && !mutableObj.stateId) {
    delete newQueryObj.personAddress;
  }
  if (!mutableObj.phone && !mutableObj.email) {
    delete newQueryObj.personContact;
  }
  if (mutableObj.locationUuid) {
    newQueryObj.contactPracticeLocations.data = [
      {
        practiceLocationUuid: mutableObj.locationUuid,
        accountUuid: accountUuid,
      },
    ];
  } else {
    if (defaultPracticeLocation) {
      newQueryObj.contactPracticeLocations.data = [
        {
          practiceLocationUuid: defaultPracticeLocation,
          accountUuid: accountUuid,
        },
      ];
    } else {
      delete newQueryObj.contactPracticeLocations;
    }
  }
  if (patientId) {
    newQueryObj.patient = {
      data: {patientUuid: patientId, accountId: accountId},
    };
  }
  if (addTagObjectList && addTagObjectList.length > 0) {
    newQueryObj.contactTagging.data = addTagObjectList;
  } else {
    delete newQueryObj.contactTagging;
  }

  // newQueryObj.person.data = {
  //   accountId: accountId,
  //   firstName: mutableObj.firstName || null,
  //   lastName: mutableObj.lastName || null,
  //   middleName: mutableObj.middleName || null,
  //   birthDate: mutableObj.birthDate || null,
  //   genderId: mutableObj.genderId || null,
  //   contactType: {
  //     data: {
  //       typeId: customerTypeId
  //     }
  //   }
  // }
  return newQueryObj;
};

export const createContactQueryObject = (
  contactObj: any,
  accountId: any,
  userId: any,
  patientId?: any,
  accountUuid?: any,
  defaultPracticeLocation?: any,
  addTagObjectList?: any,
  customerTypeId?: string,
  userUuid?: string
) => {
  const mutableObj: any = contactObj;
  const newQueryObj: any = {
    accountId: accountId,
    contactType: {} as any,
  };
  if (
    mutableObj.firstName ||
    mutableObj.lastName ||
    mutableObj.middleName ||
    mutableObj.birthDate ||
    mutableObj.genderId ||
    mutableObj?.chosenName ||
    mutableObj?.pronounId ||
    mutableObj.bloodGroupId ||
    mutableObj?.pronounId
  ) {
    const {genderId} = getGenderNameAndId(mutableObj);
    if (mutableObj.firstName?.trim()) {
      newQueryObj.firstName = mutableObj.firstName.trim();
    }
    if (mutableObj.lastName?.trim()) {
      newQueryObj.lastName = mutableObj.lastName.trim();
    }
    if (mutableObj.middleName?.trim()) {
      newQueryObj.middleName = mutableObj.middleName.trim();
    } else {
      newQueryObj.middleName = '';
    }
    if (mutableObj.birthDate) {
      newQueryObj.birthDate = mutableObj.birthDate;
    }
    if (mutableObj.genderId) {
      newQueryObj.genderId = genderId;
    }
    if (mutableObj.bloodGroupId) {
      newQueryObj.bloodGroupId = mutableObj.bloodGroupId;
    }
    if (mutableObj?.chosenName) {
      newQueryObj.chosenName = mutableObj?.chosenName;
    }
    if (mutableObj?.pronounId) {
      newQueryObj.pronounId = mutableObj?.pronounId;
    }
  }
  const personContactObj = getPersonContactList(mutableObj, accountId);
  newQueryObj.contactType.data = {
    typeId: customerTypeId,
    contact: {
      data: {
        additionalAttributes: {
          createdUserUuid: userUuid,
        },
        contactLanguages: {
          data: {
            languageCode: contactObj?.contactLanguages?.languageCode || '',
            accountUuid: accountUuid,
          },
        },
        accountId: accountId,
        name: `${mutableObj.firstName}${mutableObj.middleName ? ' ' + mutableObj.middleName + ' ' : ' '
          }${mutableObj.lastName}`,
        phoneNumber: (mutableObj.phone || '').replace(/[()-\s]/g, '') || '',
        source: mutableObj.source ?? CONTACT_SOURCE_OF_CREATION,
        personAddress: {
          data: [
            {
              accountId: accountId,
              cityId: mutableObj.cityId,
              zipcodeId: mutableObj.zipCodeId,
              stateId: mutableObj.stateId,
              line1: mutableObj.line1,
              line2: mutableObj.line2,
            },
          ],
        },
        personContact: {
          data: personContactObj,
        },
        ...((defaultPracticeLocation && defaultPracticeLocation?.length > 0) ||
        (mutableObj.locationUuid && mutableObj.locationUuid?.length > 0)
          ? {
              contactPracticeLocations: {
                data: [
                  {
                    practiceLocationUuid: mutableObj.locationUuid
                      ? mutableObj.locationUuid
                      : defaultPracticeLocation,
                    accountUuid: accountUuid,
                  },
                ],
              },
            }
          : {}),
        contactTagging: {
          data: addTagObjectList,
        },
        notes: {
          data: {
            userId: userId,
            content: mutableObj.note,
            accountId: accountId,
          },
        },
        workflowMetaData: mutableObj.workflow_meta_data,
        contactPreferredCommunicationChannel: {
          data: {
            preferredCommunicationModeId: mutableObj.preferredModeId,
            accountUuid: accountUuid,
          },
        },
      },
    },
  };

  if (!defaultPracticeLocation) {
    delete newQueryObj.contactType.data.contact.data.contactPracticeLocations;
  }
  if (!mutableObj.preferredModeId) {
    delete newQueryObj.contactType.data.contact.data
      .contactPreferredCommunicationChannel;
  }
  if (mutableObj.email) {
    newQueryObj.contactType.data.contact.data.email = mutableObj.email;
  }

  if (addTagObjectList && addTagObjectList.length > 0) {
  } else {
    delete newQueryObj.contactType.data.contact.data.contactTagging;
  }
  if (!mutableObj.note) {
    delete newQueryObj.contactType.data.contact.data.notes;
  }
  return newQueryObj;
};

export const updateContactWorkflow = (workflowEventList: any) => {
  const newQueryObj = {
    workflowList: getWorkflowSelectedList(workflowEventList),
  };
  return newQueryObj;
};

export const updateContactQueryObj = (contactObj: any) => {
  const mutableObj = contactObj;
  const newQueryObj: any = {};
  if (mutableObj.firstName) {
    newQueryObj.name =
      mutableObj.firstName?.trim() +
      ' ' +
      mutableObj.middleName?.trim() +
      ' ' +
      mutableObj.lastName?.trim();
  }

  if (mutableObj.email) {
    newQueryObj.email = mutableObj.email;
  } else if (mutableObj.phone && !mutableObj.email) {
    newQueryObj.email = null;
  }
  if (mutableObj.phone) {
    newQueryObj.phoneNumber = (mutableObj.phone || '').replace(/[()-\s]/g, '');
  } else if (mutableObj.email && !mutableObj.phone) {
    newQueryObj.phoneNumber = '';
  }
  return newQueryObj;
};

export const updatePersonAddressObj = (contactObj: any) => {
  const mutableObj = contactObj;
  const newQueryObj: any = {};
  newQueryObj.cityId = mutableObj.zipCodeId && mutableObj.cityId ? mutableObj.cityId : null;
  newQueryObj.zipcodeId = mutableObj.zipCodeId ? mutableObj.zipCodeId : null;
  newQueryObj.stateId = mutableObj.zipCodeId && mutableObj.stateId ? mutableObj.stateId : null;
  newQueryObj.line1 = mutableObj.zipCodeId  &&  mutableObj.line1 ? mutableObj.line1  : null;
  newQueryObj.line2 = mutableObj.zipCodeId && mutableObj.line2 ? mutableObj.line2 : null ;
  return newQueryObj;
};

export const crateContactPersonObj = (
  contactObj: any,
  contactId: any,
  accountId: any
) => {
  const mutableObj: any = contactObj;
  if (
    mutableObj.firstName ||
    mutableObj.lastName ||
    mutableObj.middleName ||
    mutableObj.birthDate ||
    mutableObj.genderId ||
    mutableObj.bloodGroupId
  ) {
    const {genderId} = getGenderNameAndId(mutableObj);
    const tempQueryObj: any = {
      contactId: contactId,
    };
    if (mutableObj.firstName?.trim()) {
      tempQueryObj.firstName = mutableObj.firstName?.trim();
    }
    if (mutableObj.lastName) {
      tempQueryObj.lastName = mutableObj.lastName?.trim();
    }
    if (mutableObj.middleName) {
      tempQueryObj.middleName = mutableObj.middleName?.trim();
    } else {
      tempQueryObj.middleName = '';
    }
    if (mutableObj.birthDate) {
      tempQueryObj.birthDate = mutableObj.birthDate;
    }
    if (mutableObj.genderId) {
      tempQueryObj.genderId = genderId;
    }
    if (mutableObj.bloodGroupId) {
      tempQueryObj.bloodGroupId = mutableObj.bloodGroupId;
    }
    if (mutableObj?.chosenName) {
      tempQueryObj.chosenName = mutableObj?.chosenName;
    }
    if (mutableObj?.pronounId) {
      tempQueryObj.pronounId = mutableObj?.pronounId;
    }
    if (mutableObj?.sexAtBirthId) {
      tempQueryObj.birthSexId = mutableObj?.sexAtBirthId;
    }
    return tempQueryObj;
  }
  return {};
};

export const getKeysForPersonType = (
  personType: string
): {[key: string]: string} => {
  let contactKeyMapping = {
    firstName: 'firstName',
    lastName: 'lastName',
    middleName: 'middleName',
    state: 'state',
    city: 'city',
    phoneNumber: 'phoneNumber',
    addressLine1: 'addressLine1',
    addressLine2: 'addressLine2',
    contactType: 'contactType',
  };
  if (personType === PERSON_TYPES.CUSTOMER) {
    contactKeyMapping = {
      firstName: 'firstName',
      lastName: 'lastName',
      middleName: 'middleName',
      state: 'state',
      city: 'city',
      phoneNumber: 'phoneNumber',
      addressLine1: 'addressLine1',
      addressLine2: 'addressLine2',
      contactType: 'contactType',
    };
  } else if (
    personType === PERSON_TYPES.LEAD ||
    personType === PERSON_TYPES.OTHER ||
    personType === PERSON_TYPES.SUPPORTING_MEMBER
  ) {
    contactKeyMapping = {
      firstName: 'firstName',
      lastName: 'lastName',
      middleName: 'middleName',
      state: 'state',
      city: 'city',
      phoneNumber: 'phoneNumber',
      addressLine1: 'addressLine1',
      addressLine2: 'addressLine2',
      contactType: 'contactType',
    };
  }
  return contactKeyMapping
};

// This method is triggered from medication ordering workflow, so checks also have been added as per that in this method
export const validatePersonData = async (
  contactData: any,
  ehrCapabilities: Array<IEhrCapability>,
  locationId: string | undefined,
  resourceType: CapabilityResource = CapabilityResource.patient,
) => {
  const leadFormData = contactData;
  const personType: string =
    contactData?.contactType?.code !== undefined
      ? contactData?.contactType?.code
      : '';
  const currentBirthdate = getMomentObj(contactData.birthDate);
  if (personType === PERSON_TYPES.CUSTOMER && checkIfAgeIsLessThan18(currentBirthdate)) {
    const list: Observation[] = [];
    const patientId = contactData?.patientId || contactData?.patientUuid;
    const heightObservation = getVitalsWithFilters(patientId, Vital.height, 1, 0, contactData?.locationUuid);
    const weightObservation = getVitalsWithFilters(patientId, Vital.weight, 1, 0, contactData?.locationUuid);
    try {
      const response = await Promise.all([heightObservation, weightObservation]);
      if (response?.[0]?.data?.entry?.length) {
        list.push(response[0].data?.entry?.[0]?.resource);
      }
      if (response?.[1]?.data?.entry?.length) {
        list.push(response[1].data?.entry?.[0]?.resource);
      }
    } catch (error) {

    }
    leadFormData.vitalObservations = list;
  }
  const fieldKeys = getKeysForPersonType(personType);

  const phoneNumber = getFieldConfig(
    fieldKeys?.phoneNumber,
    locationId,
    resourceType,
    ehrCapabilities
  );
  const addressLine1 = getFieldConfig(
    fieldKeys?.addressLine1,
    locationId,
    resourceType,
    ehrCapabilities
  );

  const resourceCapability = ehrCapabilities?.find((capability) => {
    return capability.resourceName === resourceType;
  });

  const isGenderAtBirthRequired =
    resourceCapability?.abilities?.keyAllowedOperations?.birthSex?.isRequired ||
    false;

  const isPractitionerRequired =
    resourceCapability?.abilities?.keyAllowedOperations?.generalPractitioner
      ?.isRequired || false;

  const genderAtBirthLabel =
    resourceCapability?.abilities?.keyAllowedOperations?.birthSex?.label;
  const genderIdentityLabel =
    resourceCapability?.abilities?.keyAllowedOperations?.gender?.label;

  const isAddressLine1Required = !!addressLine1?.isRequired && personType === PERSON_TYPES.CUSTOMER;
  const isMobileNumberRequired = !!phoneNumber?.isRequired && personType === PERSON_TYPES.CUSTOMER;
  const isLegalSexRequired = (resourceCapability?.abilities?.keyAllowedOperations?.legalSex?.isRequired || false)  && personType === PERSON_TYPES.CUSTOMER;;
  const isLocationRequired = (resourceCapability?.abilities?.keyAllowedOperations?.location?.isRequired || false) && personType === PERSON_TYPES.CUSTOMER;
  const isSexualOrientationRequired = (resourceCapability?.abilities?.keyAllowedOperations?.sexualOrientation?.isRequired || false) && personType === PERSON_TYPES.CUSTOMER;
  const isMartialStatusRequired = (resourceCapability?.abilities?.keyAllowedOperations?.maritalStatus?.isRequired || false) && personType === PERSON_TYPES.CUSTOMER;
  const isRaceRequired = (resourceCapability?.abilities?.keyAllowedOperations?.race?.isRequired || false) && personType === PERSON_TYPES.CUSTOMER;
  const isRaceMultiple = (resourceCapability?.abilities?.keyAllowedOperations?.race?.isMultiple || false);
  const isEthnicityRequired = (resourceCapability?.abilities?.keyAllowedOperations?.ethnicity?.isRequired || false) && personType === PERSON_TYPES.CUSTOMER;
  const isEthnicityMultiple = (resourceCapability?.abilities?.keyAllowedOperations?.ethnicity?.isMultiple || false);
  const isPhoneOrEmailRequired = isAccountConfigEnabled(CONFIG_CODES.IS_PHONE_OR_EMAIL_REQUIRED);
  const checks = {
    isAddressLine1Required,
    isMobileNumberRequired,
    isGenderAtBirthRequired,
    isPractitionerRequired,
    checkVitalsForLessThan18Age: personType === PERSON_TYPES.CUSTOMER,
    isPatientCreationFlow: true,
    isFaxNumberRequired: false,
    fieldKeys,
    shouldPerformPhoneNumberValidation: true,
    isPhoneOrEmailRequired: true,
    isLegalSexRequired,
    isLocationRequired,
    isSexualOrientationRequired: isSexualOrientationRequired,
    isMartialStatusRequired: isMartialStatusRequired,
    isRaceRequired: isRaceRequired,
    isRaceMultiple: isRaceMultiple,
    isEthnicityRequired: isEthnicityRequired,
    isEthnicityMultiple: isEthnicityMultiple,
  };

  const missingFieldChecks = {
    isAddressLine1Required,
    isMobileNumberRequired,
    isGenderAtBirthRequired,
    isPractitionerRequired,
    genderAtBirthLabel: genderAtBirthLabel ? genderAtBirthLabel : '',
    genderIdentityLabel: genderIdentityLabel ? genderIdentityLabel : '',
    checkVitalsForLessThan18Age: personType === PERSON_TYPES.CUSTOMER,
    isPhoneOrEmailRequired: isPhoneOrEmailRequired
  };

  const validationDetails = validateContactOrPatientDetails(leadFormData, personType, checks);
  const missingFieldDetails = getMissingFields(leadFormData, missingFieldChecks);
  return {validationDetails, missingFieldDetails, validatedPatientData: leadFormData}
};

const mostRepeatedNumber = (list: string[]) => {
  if (!list.length) {
    return;
  }
  const repeatedMap: {[index: string]: number} = {};
  let maxRepeatedValue = '';
  let maxRepeatedCount = 0;
  list.forEach((item) => {
    if (repeatedMap[item]) {
      repeatedMap[item] += 1;
    } else {
      repeatedMap[item] = 1;
    }
    maxRepeatedCount = Math.max(maxRepeatedCount, repeatedMap[item]);
    if (maxRepeatedCount === repeatedMap[item]) {
      maxRepeatedValue = item;
    }
  });
  return {value: maxRepeatedValue, repeatedCount: maxRepeatedCount};
}

/*
Area code validation requirements
    • cannot be 555
    • cannot begin with 0
    • cannot begin with 1
    • cannot have 7 or more repeating digits
*/
export const isValidPhoneNumber = (phoneNumber: string, label?: string): {isValid: boolean, error?: string} => {
  const name = label || 'Phone number';
  const number = unmaskPhoneNumber(phoneNumber);
  if (number.length !== 10) {
    return {isValid: false, error: `${name} should be 10 digits`};
  }
  if (number.startsWith('555', 0) || number.startsWith('1', 0) || number.startsWith('0', 0)) {
    return {isValid: false, error: `${name} cannot start with 555, 0 or 1`};
  }
  const repeatedData = mostRepeatedNumber(number.split(''));
  if (repeatedData && repeatedData?.repeatedCount > 6) {
    return {isValid: false, error: `${name} cannot have 7 or more repeating digits,\n '${repeatedData.value}' is repeated ${repeatedData.repeatedCount} times`};
  }
  return {isValid: true};
}

export const validateContactOrPatientDetails = (
  leadFormData: any,
  contactType: string,
  otherChecks: {
    isGenderAtBirthRequired: boolean;
    isPractitionerRequired: boolean;
    isAddressLine1Required: boolean;
    isMobileNumberRequired: boolean;
    isFaxNumberRequired: boolean;
    checkVitalsForLessThan18Age: boolean;
    isPatientCreationFlow: boolean;
    shouldPerformPhoneNumberValidation: boolean;
    isPhoneOrEmailRequired: boolean;
    isLegalSexRequired: boolean;
    fieldKeys: {[key: string]: string}
    isLocationRequired?: boolean;
    isSexualOrientationRequired?: boolean,
    isMartialStatusRequired?: boolean,
    isRaceRequired?: boolean;
    isRaceMultiple?: boolean;
    isEthnicityRequired?: boolean;
    isEthnicityMultiple?: boolean;
  },
  defaultPracticeLocation?: string
) => {

  const {isGenderAtBirthRequired, isPractitionerRequired, isAddressLine1Required, fieldKeys, isMobileNumberRequired, checkVitalsForLessThan18Age, isPatientCreationFlow, isFaxNumberRequired, shouldPerformPhoneNumberValidation, isLegalSexRequired, isLocationRequired, isSexualOrientationRequired, isMartialStatusRequired, isRaceRequired, isEthnicityRequired, isRaceMultiple, isEthnicityMultiple} = otherChecks;
  const today = new Date();
  const currentData = getMomentObj(today);
  const currentBirthdate = getMomentObj(leadFormData.birthDate);
  const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  const errors: any = {};
  let validate = true;
  let sendInvite = true;
  if (leadFormData['sendInvite'] == undefined || leadFormData.sendInvite) {
    sendInvite = true;
  } else {
    sendInvite = false;
  }

  if(isLegalSexRequired){
    errors.legalSex = !leadFormData?.legalSex ? 'Legal Sex is required' : '';
  }
  if (contactType && (contactType !== PERSON_TYPES.CUSTOMER)) {
    errors.contactType =
    !leadFormData?.contactType?.id ||
    leadFormData.contactType.id.trim()?.length === 0
      ? 'Contact type is required'
      : '';
  }
  errors.firstName = !leadFormData.firstName || leadFormData.firstName?.trim()?.length === 0
    ? 'First Name is required'
    : leadFormData.firstName.trim()?.length < 2
      ? 'First Name is too short'
      : '';

  errors.email = (otherChecks.isPhoneOrEmailRequired && (!leadFormData.email && !leadFormData.phone && sendInvite))
    ? 'Email or Phone Number is required'
    : !leadFormData.phone &&
      leadFormData.email &&
      !leadFormData.email.match(emailRegex) &&
      sendInvite
      ? 'Invalid Email'
      : '';
  errors.secondaryEmail =
    leadFormData.secondaryEmail &&
      !leadFormData.secondaryEmail.match(emailRegex) &&
      sendInvite
      ? 'Invalid Email'
      : '';

  const unmaskedPhone = unmaskPhoneNumber(leadFormData.phone || '');
  errors.mobileNumber = (otherChecks.isPhoneOrEmailRequired && (!leadFormData.email && !unmaskedPhone && sendInvite))
    ? 'Email or Phone Number is required'
    : unmaskedPhone && unmaskedPhone.length !== 10 && sendInvite
      ? 'Phone number should be 10 digits'
      : '';

  if (shouldPerformPhoneNumberValidation && unmaskedPhone.length && !errors.mobileNumber) {
    const phoneValidation = isValidPhoneNumber(unmaskedPhone);
    if (!phoneValidation.isValid && phoneValidation.error) {
      errors.mobileNumber = phoneValidation.error;
    }
  }

  if (isFaxNumberRequired && contactType === PERSON_TYPES.EMPLOYEE) {
    errors.fax = !leadFormData?.fax ? 'Fax number is required' : '';
  }
  const unmaskedSecondaryPhone = unmaskPhoneNumber(leadFormData.secondaryPhone || '');
  errors.secondaryMobileNumber =
    unmaskedSecondaryPhone && unmaskedSecondaryPhone.length !== 10 && sendInvite
      ? 'Phone number should be 10 digits'
      : '';

  if (shouldPerformPhoneNumberValidation && unmaskedSecondaryPhone.length && !errors.secondaryMobileNumber) {
    const phoneValidation = isValidPhoneNumber(unmaskedSecondaryPhone);
    if (!phoneValidation.isValid && phoneValidation.error) {
      errors.secondaryMobileNumber = phoneValidation.error;
    }
  }

  errors.lastName = !leadFormData.lastName || leadFormData.lastName?.trim().length === 0
    ? 'Last Name is required'
    : leadFormData.lastName?.trim()?.length < 2
      ? 'Last Name is too short'
      : '';

  errors.zipcode =  isPatientCreationFlow ?   !leadFormData.zipCodeId ? 'Zip code is required' : leadFormData.zipCode.length < 5 ? 'Zip code should be 5 digit' : '' : '';

  if (isPatientCreationFlow || contactType === PERSON_TYPES.EMPLOYEE) {
    errors.birthdate = !leadFormData.birthDate ? 'Birth date is required' : '';
  }
  if (leadFormData.birthDate && currentBirthdate > currentData) {
    errors.birthdate = 'DOB should not be future date';
  }

  if (isAddressLine1Required) {
    errors[fieldKeys?.addressLine1] = !leadFormData.line1?.trim() ? 'Address Line 1 is required' : '';
  }

  if (isMobileNumberRequired) {
    errors.mobileNumber =
      !leadFormData.phone && sendInvite
        ? 'Phone number is required'
        : (errors.mobileNumber || '');

    errors.email = ''
    // !leadFormData.email && !leadFormData.phone && sendInvite
    //   ? 'Email or Phone number is required'
    //   : !leadFormData.phone &&
    //     leadFormData.email &&
    //     !leadFormData.email.match(emailRegex) &&
    //     sendInvite
    //   ? 'Invalid Email'
    //   : '';

  }

  if (isGenderAtBirthRequired && (isPatientCreationFlow || contactType === PERSON_TYPES.EMPLOYEE)) {
    errors.sexAtBirth = !leadFormData.sexAtBirth
      ? 'Gender at Birth is required'
      : '';
  }

  if (isPractitionerRequired && (isPatientCreationFlow || contactType === PERSON_TYPES.EMPLOYEE)) {
    errors.practitioner = !leadFormData.practitionerId
      ? 'Please select a practitioner'
      : '';
  }

  if (checkVitalsForLessThan18Age && checkIfAgeIsLessThan18(currentBirthdate)) {
    if (!leadFormData?.vitalObservations || leadFormData?.vitalObservations?.length === 0) {
      errors.vitalObservations = {heightError: 'Height is required', weightError: 'Weight is required'};
    } else if (leadFormData?.vitalObservations?.length === 2) {
      errors.vitalObservations = {};
    } else if (leadFormData?.vitalObservations && leadFormData?.vitalObservations?.length) {
      const error = leadFormData?.vitalObservations.map((observation: any) => {
        if (observation?.loinc == Vital.weight || observation?.code?.coding?.[0]?.code == Vital.weight) {
          return {heightError: 'Height is required'};
        } else if (observation?.loinc === Vital.height || observation?.code?.coding?.[0]?.code == Vital.height) {
          return {weightError: 'Weight is required'};
        }
      });
      errors.vitalObservations = error?.[0];
    } else {
      errors.vitalObservations = {};
    }
  } else {
    errors.vitalObservations = {};
  }

  if (isLocationRequired) {
    //location required for lead also
    const patientLocationUuid =
      leadFormData.locationUuid || defaultPracticeLocation;
    if (!patientLocationUuid) {
      errors.location = 'Please select a location';
    }
  }


  if (isMartialStatusRequired) {
    errors.maritalStatus = !leadFormData.maritalStatus ? 'Please select Marital Status': '';
  }

  if (isSexualOrientationRequired) {
    errors.sexualOrientation = !leadFormData.sexualOrientation ? 'Please select sexual orientation' : '';
    if (leadFormData.sexualOrientation && leadFormData.sexualOrientation === SEXUAL_ORIENTATION_OTHER_CODE) {
      errors.sexualOrientation = !leadFormData.sexualOrientationText || !leadFormData.sexualOrientationText?.trim() ? 'Please describe sexual orientation' : '';
    }
  }

  if (isRaceRequired) {
    if (isRaceMultiple) {
      errors.race = !leadFormData.race?.length ? 'Race is required': '';
    } else {
      errors.race = !leadFormData.race ? 'Race is required': '';
    }
  }
  if (isEthnicityRequired) {
    if (isEthnicityMultiple) {
      errors.ethnicity = !leadFormData.ethnicity?.length ? 'Ethnicity is required': '';
    } else {
      errors.ethnicity = !leadFormData.ethnicity ? 'Ethnicity is required': '';
    }
  }

  if (
    (isLegalSexRequired && errors.legalSex) ||
    errors.contactType ||
    errors.firstName ||
    (otherChecks.isPhoneOrEmailRequired ? errors.email : false) ||
    errors.lastName ||
    (errors.birthdate && (isPatientCreationFlow || contactType === PERSON_TYPES.EMPLOYEE)) ||
    errors.zipcode ||
    (errors.sexAtBirth &&
      isGenderAtBirthRequired &&
      isPatientCreationFlow) ||
    (errors.practitioner &&
      isPractitionerRequired &&
      isPatientCreationFlow) ||
    errors.zipcode ||
    errors.mobileNumber ||
    errors.secondaryMobileNumber ||
    errors.secondaryEmail ||
    (errors.line1 &&
      isAddressLine1Required &&
      contactType === PERSON_TYPES.EMPLOYEE) ||
    (errors.fax &&
      isFaxNumberRequired &&
      contactType === PERSON_TYPES.EMPLOYEE) ||
    (errors.mobileNumber &&
      isMobileNumberRequired &&
      contactType === PERSON_TYPES.EMPLOYEE) ||
    (errors[fieldKeys?.addressLine1] && isAddressLine1Required) ||
    Object.keys(errors.vitalObservations)?.length !== 0 ||
    (errors.location && isLocationRequired) || //location required for lead also
    (isMartialStatusRequired && errors.maritalStatus) ||
    (isSexualOrientationRequired && errors.sexualOrientation) ||
    errors.race ||
    errors.ethnicity
  ) {
    validate = false;
  }

  return {validate: validate, errors: errors};
};

export const getRowDataFromLeadData = (
  leadFormData: any,
  singleLeadData: any,
  accountId: any
) => {
  const object = {
    singleLeadData: {
      contactType: {
        id: singleLeadData.contactType.id,
        contactType: leadFormData.contactType,
      },
      personAddress: [
        {
          zipcodes: {
            code: leadFormData.zipCode,
            id: leadFormData.zipCodeId,
          },
          cities: {
            name: leadFormData.cityName,
            id: leadFormData.cityId,
          },
          states: {
            name: leadFormData.stateName,
            id: leadFormData.stateId,
          },
          cityId: leadFormData.cityId,
          zipcodeId: leadFormData.zipCodeId,
          stateId: leadFormData.stateId,
          id: '',
          line1: leadFormData.line1,
          line2: leadFormData.line2,
        },
      ],
      secondaryPhone:leadFormData.secondaryPhoneObj.value || '',
      phoneObj:leadFormData.secondaryPhoneObj,
      contactLanguages: [
        {
          id: leadFormData.contactLanguages.id,
          languageCode: leadFormData.contactLanguages.languageCode,
        },
      ],
      accountId: accountId,
      contactPracticeLocations: [
        {
          practiceLocationUuid: leadFormData.locationUuid,
          contactUuid: leadFormData.contactUUID,
        },
      ],
      contactTagging: [],
      phoneNumber: leadFormData.phone,
      name: leadFormData.firstName + ' ' + leadFormData.lastName,
      email: leadFormData.email,
      patient: {
        patientId: leadFormData.patientId,
        patientUuid: leadFormData.patientUuid,
        isWeeklyCheckin: leadFormData.isWeeklyCheckin,
      },
      person: {
        firstName: leadFormData.firstName,
        lastName: leadFormData.lastName,
        birthDate: leadFormData.birthDate,
        id: leadFormData.personId,
        bloodGroupId: leadFormData.bloodGroupId,
        genderId: leadFormData.genderId,
        gender: {
          value: leadFormData.genderName,
        },
        sexAtBirth: leadFormData.sexAtBirth,
        sexAtBirthCode: leadFormData.sexAtBirthCode,
        practitionerId: leadFormData.practitionerId,
        contactId: leadFormData.leadId,
        middleName: leadFormData.middleName,
        chosenName: leadFormData.chosenName,
        pronounId: leadFormData.pronounId,
      },
      personContact: [],
      id: leadFormData.leadId,
      uuid: leadFormData.contactUUID,
    },
  };
  return object;
};

export const getSexAtBirthFromResponse = (data: any) => {
  const extensions: Extension[] = data?.extension || [];
  const result = extensions.filter((item: any) =>
    item.url.includes('birthsex')
  )[0];
  return result;
};

export const genderAtBirthArray = () => {
  return [
    {
      code: 'M',
      display: 'Male',
    },
    {
      code: 'F',
      display: 'Female',
    },
    {
      code: 'O',
      display: 'Other',
    },
    {
      code: 'UNK',
      display: 'Unknown',
    },
  ];
};

export const getLanguageObjectFromResponse = (data: any) => {
  const communication = data.communication;
  if (communication) {
    const language = communication[0].language;
    const mutableObject: any = {};
    const coding = language.coding;
    const languageObject = coding[0];
    const description = languageObject.display;
    delete languageObject.display;
    mutableObject.coding = [{...languageObject, description: description}];
    mutableObject.text = language?.text || '';
    return mutableObject;
  }
};

export const getPractitionerIdFromResponse = (data: any) => {
  const generalPractitioner = data?.generalPractitioner;
  if (generalPractitioner && generalPractitioner.length > 0) {
    const reference = generalPractitioner[0].reference;
    return reference.split('/').pop();
  }
  return '';
};

export const addOrUpdateContactLanguage = (args: {
  updateLanguage: any;
  createLanguage: any;
  leadFormData: any;
  accountUuid: string;
}) => {
  const {updateLanguage, createLanguage, leadFormData, accountUuid} = args;
  if (leadFormData?.contactLanguages?.id) {
    return updateLanguage({
      variables: {
        code: leadFormData?.contactLanguages?.languageCode || '',
        id: leadFormData?.contactLanguages?.id,
        contactId: leadFormData?.leadId,
      },
    });
  } else {
    return createLanguage({
      variables: {
        accountUuid: accountUuid,
        contactId: leadFormData.leadId,
        code: leadFormData?.contactLanguages?.languageCode || '',
      },
    });
  }
};

export const getLegalSexFromResponse = (data: any) => {
  const extension = data.extension;
  if (!extension?.length) {
    return '';
  }

  const result = extension.filter(
    (item: any) =>
      item?.url?.includes('legal-sex') || item?.url?.includes('legalsex')
  )[0];

  return result?.valueCode || '';
};

export const validatesIndividually = (leadFormData: any,
  otherChecks: {
  isGenderAtBirthRequired: boolean;
  isMobileNumberRequired: boolean;
  shouldPerformPhoneNumberValidation: boolean;
  isPhoneOrEmailRequired: boolean;
  isPatientCreationFlow: boolean;
  shouldCreateUsingIntegration: boolean;
  isLocationRequired: boolean;
  isPractitionerRequired:boolean;
  defaultPracticeLocation?: string;
}) => {
  const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  let error = '';

  if (!otherChecks.isPatientCreationFlow) {
    error =
      !leadFormData?.contactType?.id ||
      leadFormData.contactType.id.trim()?.length === 0
        ? 'Contact type is required'
        : '';
    if (error !== '') {
      return {validate: false, errors: error};
    }
  }

  error = !leadFormData.firstName || leadFormData.firstName?.trim().length === 0
    ? 'First name is required'
    : leadFormData.firstName?.trim()?.length < 2
      ? 'First name is too short'
      : '';
  if (error !== '') {
    return {validate: false, errors: error};
  }
  error = !leadFormData.lastName || leadFormData.firstName?.trim().length === 0
    ? 'Last name is required'
    : leadFormData.lastName?.trim().length < 2
      ? 'Last name is too short'
      : '';
  if (error !== '') {
    return {validate: false, errors: error};
  }
  if (otherChecks.isLocationRequired) {
    const patientLocationUuid =
      leadFormData.locationUuid || otherChecks?.defaultPracticeLocation;
    if (!patientLocationUuid) {
      error = 'Please select a location';
    }
    if (error.length > 0) {
      return {validate: false, errors: error};
    }
  }
  if(otherChecks?.isPractitionerRequired){
    error = !leadFormData.practitionerId ? 'Practioner required' : ''
    if (error.length > 0) {
      return {validate: false, errors: error};
    }
  }

  if (otherChecks.isPatientCreationFlow) {
    if (otherChecks.isGenderAtBirthRequired) {
      error = !leadFormData.sexAtBirth ? 'Gender at birth is required': '';
      if (error.length > 0) {
        return {validate: false, errors: error};
      }
    }
    if (otherChecks.isMobileNumberRequired) {
      error = !leadFormData.phone ? 'Phone number is required' : '';
      if (error.length > 0) {
        return {validate: false, errors: error};
      }
    }
    if (otherChecks.isPhoneOrEmailRequired) {
      error =
        !leadFormData.email && !leadFormData.phone
          ? 'Email is required'
          : leadFormData.email &&
            !leadFormData.email.match(emailRegex)
          ? 'Invalid Email'
          : '';
      if (error.length > 0) {
        return {validate: false, errors: error};
      }
    }
    error = !leadFormData.birthDate ? 'Birthdate is required' : '';
    if (error.length > 0) {
      return {validate: false, errors: error};
    }
    error = !leadFormData.zipCodeId ? 'Zip code is required' : '';
    if (error.length > 0) {
      return {validate: false, errors: error};
    }
  }
  if (otherChecks.shouldPerformPhoneNumberValidation && leadFormData.phone) {
    const phoneValidation = isValidPhoneNumber(leadFormData.phone);
    if (!phoneValidation.isValid && phoneValidation.error) {
      error = phoneValidation.error;
    }
    if (error.length > 0) {
      return {validate: false, errors: error};
    }
  }

  return {validate: true, errors: error};
};

export const getStateAndCityNameFromResponse = (
  data: any
): {
  abbr: string;
  cityName: string;
} => {
  const address = data.address;
  if (isEmptyArray(address)) {
    return {
      abbr: '',
      cityName: '',
    };
  }
  const addressObject = address[0];
  return {
    abbr: addressObject?.state || '',
    cityName: addressObject?.city || '',
  };
};

export const getPersonContactList = (leadData: any, accountId?: number) => {
  const personContactArray = [];
  if (leadData.email) {
    const temp = {
      typeId: leadData.emailContactTypeId,
      value: leadData.email,
      accountId: accountId,
      rank: 1,
    };
    personContactArray.push(temp);
  }
  if (leadData.phone) {
    const temp = {
      typeId: leadData.phoneContactTypeId,
      value: leadData.phone.replace(/[()-\s]/g, ''),
      accountId: accountId,
      rank: 1,
    };
    personContactArray.push(temp);
  }
  if (leadData.secondaryPhone) {
    const temp = {
      typeId: leadData.phoneContactTypeId,
      value: leadData.secondaryPhone.replace(/[()-\s]/g, ''),
      accountId: accountId,
      rank: 2,
    };
    personContactArray.push(temp);
  }
  if (leadData.secondaryEmail) {
    const temp = {
      typeId: leadData.emailContactTypeId,
      value: leadData.secondaryEmail,
      accountId: accountId,
      rank: 2,
    };
    personContactArray.push(temp);
  }
  return personContactArray;
};

export const getPatientMartialStatus = (data: any) => {
  if (data?.maritalStatus?.coding?.length) {
    return data.maritalStatus.coding[0]?.code;
  }
  return undefined;
};

export const getSexualOrientationFromResponse = (data: any, patientCapability: any) => {
  const sexualOrientationCapability = patientCapability?.keyAllowedOperations?.sexualOrientation;
  const extension = data.extension;
  const sexualOrientationObject = {
    sexualOrientation: '',
    sexualOrientationText: '',
  }
  if (!extension?.length || !sexualOrientationCapability) {
    return sexualOrientationObject;
  }
  sexualOrientationObject.sexualOrientation = getExtensionValue(data, sexualOrientationCapability.extensionUrl) || '';
  sexualOrientationObject.sexualOrientationText = getExtensionValue(data, sexualOrientationCapability.otherInfoExtensionUrl) || '';
  return sexualOrientationObject;
};

export const getCodeListFromResponse = (data: any, fieldCapability?: IAllowedOperation) => {
  const extensions: Extension[] = data.extension || [];
  const extension = extensions.find((item) => item.url === fieldCapability?.extensionUrl);
  if (!extension) {
    return [];
  }
  const result: Coding[] = [];
  let displayList: string[] = [];
  const codeList: string[] = [];
  extension.extension?.forEach((item) => {
    if (item.url === 'text' && item.valueString) {
      displayList = item.valueString.split(', ');
    } else if (item.valueCode) {
      codeList.push(item.valueCode);
    }
  });
  codeList.forEach((item, index) => {
    result.push({
      code: item,
      display: displayList?.[index],
    })
  });
  return result;
};

export const getContactValueFromIdentifiers = (args: {
  data: Patient;
  key: 'contact-uuid' | 'contact-id';
}) => {
  const {data, key} = args;
  const contactUuidIdentifier =
    data.identifier && data.identifier.find((item) => item?.type?.text === key);
  return contactUuidIdentifier?.value;
};
