import {StyleSheet} from 'react-native';
import {Colors} from '../../../styles';

export const getStickyNoteMessageViewStyles = ({
  height,
  backgroundColor,
}: {
  height: number;
  backgroundColor: string;
}) => {
  return StyleSheet.create({
    container: {
      left: 0,
      width: '100%',
      height: height ? height : 40,
      borderColor: Colors.Custom.GRAY150,
      borderBottomWidth: 0.5,
      backgroundColor: backgroundColor || '#FDF7E5',
      paddingVertical: 4,
      paddingHorizontal: 4,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    stickNoteSvgContainer: {
      alignItems: 'center',
      marginLeft: 5,
    },
    readMoreText: {
      fontSize: 14,
      fontWeight: '400',
      color: Colors.Custom.PrimaryColor,
    },
  });
};
