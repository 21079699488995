import React from 'react';
import {ICommonSvgProps} from '../interfaces';
import Svg, {Path} from 'react-native-svg';

const ReplaceActionSvg = (props: ICommonSvgProps) => {
  return (
    <Svg width="16" height="14" fill="none" viewBox="0 0 16 14">
      <Path
        fill={props?.customStrokeColor ? props?.customStrokeColor : '#6F7A90'}
        d="M2.453 6.556h-.5.5zm0 1.11l-.352.356a.5.5 0 00.705 0l-.353-.355zm1.472-.756a.5.5 0 00-.704-.71l.704.71zm-2.24-.71a.5.5 0 10-.704.71l.705-.71zM12.412 3.93a.5.5 0 00.851-.524l-.851.524zm1.132 2.404l.351-.355a.5.5 0 00-.703 0l.352.355zm-1.476.756a.5.5 0 00.703.711l-.703-.711zm2.248.711a.5.5 0 00.703-.711l-.703.711zm-10.77 2.27a.5.5 0 00-.85.526l.85-.525zM8.053.5c-3.365 0-6.1 2.707-6.1 6.056h1c0-2.789 2.28-5.056 5.1-5.056v-1zm-6.1 6.056v1.11h1v-1.11h-1zm.853 1.466l1.12-1.112-.705-.71-1.12 1.112.705.71zm0-.71L1.686 6.2l-.705.71 1.12 1.11.705-.71zm10.456-3.907A6.105 6.105 0 008.052.5v1c1.847 0 3.463.972 4.359 2.429l.851-.524zM7.922 13.5c3.375 0 6.12-2.706 6.12-6.056h-1c0 2.787-2.286 5.056-5.12 5.056v1zm6.12-6.056v-1.11h-1v1.11h1zm-.85-1.466l-1.125 1.11.703.712 1.124-1.111-.703-.711zm0 .71L14.314 7.8l.703-.711-1.124-1.111-.703.71zM2.693 10.597A6.131 6.131 0 007.922 13.5v-1a5.131 5.131 0 01-4.377-2.43l-.85.526z"
      ></Path>
    </Svg>
  );
};

export default ReplaceActionSvg;
