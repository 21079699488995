import {Image} from 'native-base';
import React from 'react';
import {isWeb} from '../../../../utils/platformCheckUtils';

const USFlagSVG = () => {
  return (
    <>
      {isWeb() ? (
        <svg
          width="32"
          height="20"
          viewBox="0 0 32 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_1630_110601)">
            <path
              d="M30.7111 20.2378H0.538817C0.241272 20.2378 0 19.9965 0 19.699V0.302489C0 0.0049431 0.241272 -0.236328 0.538817 -0.236328H30.7112C31.0087 -0.236328 31.25 0.0049431 31.25 0.302489V19.699C31.2499 19.9966 31.0087 20.2378 30.7111 20.2378Z"
              fill="#F5F5F5"
            />
            <path
              d="M31.2499 1.33868H0V0.302489C0 0.00494302 0.241272 -0.236328 0.538817 -0.236328H30.7112C31.0087 -0.236328 31.25 0.00494302 31.25 0.302489L31.2499 1.33868Z"
              fill="#FF4B55"
            />
            <path
              d="M31.2499 6.0625H0V7.63745H31.2499V6.0625Z"
              fill="#FF4B55"
            />
            <path
              d="M31.2499 2.91406H0V4.48901H31.2499V2.91406Z"
              fill="#FF4B55"
            />
            <path
              d="M31.2499 10.7879H0.538817C0.241272 10.7879 0 10.5466 0 10.2491V9.21289H31.2499V10.7879Z"
              fill="#FF4B55"
            />
            <path
              d="M31.2499 15.5137H0V17.0886H31.2499V15.5137Z"
              fill="#FF4B55"
            />
            <path
              d="M30.7111 20.2371H0.538817C0.241272 20.2371 0 19.9958 0 19.6982V18.6621H31.2499V19.6983C31.2499 19.9958 31.0087 20.2371 30.7111 20.2371Z"
              fill="#FF4B55"
            />
            <path
              d="M31.2499 12.3633H0V13.9382H31.2499V12.3633Z"
              fill="#FF4B55"
            />
            <path
              d="M14.0086 -0.236328H0.538817C0.241272 -0.236328 0 0.00488207 0 0.302428V10.2493C0 10.5469 0.241272 10.7881 0.538817 10.7881H14.0086C14.3062 10.7881 14.5475 10.5469 14.5475 10.2493V0.302428C14.5474 0.00488207 14.3061 -0.236328 14.0086 -0.236328Z"
              fill="#41479B"
            />
            <path
              d="M1.51369 0.99958L1.63295 1.35725L2.00997 1.36011C2.0588 1.36054 2.079 1.4228 2.03975 1.45179L1.73647 1.67579L1.85024 2.03522C1.86501 2.08173 1.81197 2.1203 1.77224 2.09186L1.46554 1.87269L1.15883 2.09192C1.1191 2.1203 1.06618 2.08179 1.08083 2.03528L1.1946 1.67585L0.891318 1.45185C0.852073 1.4228 0.872275 1.3606 0.921103 1.36017L1.29812 1.35731L1.41738 0.999641C1.43276 0.953255 1.49819 0.953255 1.51369 0.99958Z"
              fill="#F5F5F5"
            />
            <path
              d="M1.5132 2.87063L1.63247 3.22829L2.00948 3.23116C2.05831 3.23159 2.07851 3.29385 2.03927 3.32284L1.73598 3.54684L1.84975 3.90627C1.86452 3.95278 1.81148 3.99135 1.77175 3.96291L1.46505 3.74373L1.15835 3.96297C1.11861 3.99135 1.0657 3.95284 1.08034 3.90633L1.19411 3.5469L0.89083 3.3229C0.851584 3.29385 0.871787 3.23165 0.920615 3.23122L1.29763 3.22835L1.41689 2.87069C1.43227 2.82436 1.4977 2.82436 1.5132 2.87063Z"
              fill="#F5F5F5"
            />
            <path
              d="M1.5132 4.74172L1.63247 5.09939L2.00948 5.10226C2.05831 5.10268 2.07851 5.16494 2.03927 5.19393L1.73598 5.41793L1.84975 5.77736C1.86452 5.82387 1.81148 5.86245 1.77175 5.834L1.46505 5.61483L1.15835 5.83407C1.11861 5.86245 1.0657 5.82393 1.08034 5.77743L1.19411 5.41799L0.89083 5.19399C0.851584 5.16494 0.871787 5.10274 0.920615 5.10232L1.29763 5.09945L1.41689 4.74178C1.43227 4.69546 1.4977 4.69546 1.5132 4.74172Z"
              fill="#F5F5F5"
            />
            <path
              d="M1.5132 6.61286L1.63247 6.97053L2.00948 6.9734C2.05831 6.97382 2.07851 7.03608 2.03927 7.06507L1.73598 7.28907L1.84975 7.6485C1.86452 7.69501 1.81148 7.73359 1.77175 7.70515L1.46505 7.48597L1.15835 7.70521C1.11861 7.73359 1.0657 7.69507 1.08034 7.64857L1.19411 7.28913L0.89083 7.06513C0.851584 7.03608 0.871787 6.97388 0.920615 6.97346L1.29763 6.97059L1.41689 6.61292C1.43227 6.56654 1.4977 6.56654 1.5132 6.61286Z"
              fill="#F5F5F5"
            />
            <path
              d="M1.5132 8.484L1.63247 8.84167L2.00948 8.84453C2.05831 8.84496 2.07851 8.90722 2.03927 8.93621L1.73598 9.16021L1.84975 9.51964C1.86452 9.56615 1.81148 9.60473 1.77175 9.57628L1.46505 9.35711L1.15835 9.57634C1.11861 9.60473 1.0657 9.56621 1.08034 9.5197L1.19411 9.16027L0.89083 8.93627C0.851584 8.90722 0.871787 8.84502 0.920615 8.8446L1.29763 8.84173L1.41689 8.48406C1.43227 8.43761 1.4977 8.43761 1.5132 8.484Z"
              fill="#F5F5F5"
            />
            <path
              d="M2.96535 1.91751L3.08461 2.27518L3.46163 2.27804C3.51046 2.27847 3.53066 2.34073 3.49141 2.36972L3.18813 2.59372L3.3019 2.95315C3.31667 2.99966 3.26363 3.03824 3.2239 3.00979L2.9172 2.79056L2.6105 3.00979C2.57076 3.03818 2.51784 2.99966 2.53249 2.95315L2.64626 2.59372L2.34298 2.36972C2.30373 2.34067 2.32394 2.27847 2.37276 2.27804L2.74978 2.27518L2.86904 1.91751C2.88436 1.87125 2.94991 1.87125 2.96535 1.91751Z"
              fill="#F5F5F5"
            />
            <path
              d="M2.96535 3.78865L3.08461 4.14632L3.46163 4.14918C3.51046 4.14961 3.53066 4.21187 3.49141 4.24086L3.18813 4.46486L3.3019 4.82429C3.31667 4.8708 3.26363 4.90937 3.2239 4.88093L2.9172 4.66169L2.6105 4.88093C2.57076 4.90931 2.51784 4.87086 2.53249 4.82429L2.64626 4.46486L2.34298 4.24086C2.30373 4.21181 2.32394 4.14961 2.37276 4.14918L2.74978 4.14632L2.86904 3.78865C2.88436 3.74232 2.94991 3.74232 2.96535 3.78865Z"
              fill="#F5F5F5"
            />
            <path
              d="M2.96535 5.65974L3.08461 6.01741L3.46163 6.02028C3.51046 6.02071 3.53066 6.08296 3.49141 6.11195L3.18813 6.33595L3.3019 6.69539C3.31667 6.7419 3.26363 6.78047 3.2239 6.75203L2.9172 6.53279L2.6105 6.75203C2.57076 6.78041 2.51784 6.7419 2.53249 6.69539L2.64626 6.33595L2.34298 6.11195C2.30373 6.0829 2.32394 6.02071 2.37276 6.02028L2.74978 6.01741L2.86904 5.65974C2.88436 5.61342 2.94991 5.61342 2.96535 5.65974Z"
              fill="#F5F5F5"
            />
            <path
              d="M2.96535 7.53079L3.08461 7.88846L3.46163 7.89133C3.51046 7.89175 3.53066 7.95401 3.49141 7.983L3.18813 8.207L3.3019 8.56644C3.31667 8.61294 3.26363 8.65152 3.2239 8.62308L2.9172 8.40384L2.6105 8.62308C2.57076 8.65146 2.51784 8.61294 2.53249 8.56644L2.64626 8.207L2.34298 7.983C2.30373 7.95395 2.32394 7.89175 2.37276 7.89133L2.74978 7.88846L2.86904 7.53079C2.88436 7.48453 2.94991 7.48453 2.96535 7.53079Z"
              fill="#F5F5F5"
            />
            <path
              d="M4.4175 0.999588L4.53676 1.35725L4.91378 1.36012C4.9626 1.36055 4.98281 1.4228 4.94356 1.4518L4.64028 1.67579L4.75405 2.03523C4.76882 2.08174 4.71578 2.12031 4.67605 2.09187L4.36934 1.87263L4.06264 2.09187C4.02291 2.12025 3.96999 2.08174 3.98464 2.03523L4.09841 1.67579L3.79513 1.4518C3.75588 1.42274 3.77608 1.36055 3.82491 1.36012L4.20193 1.35725L4.32119 0.999588C4.33657 0.953262 4.40206 0.953262 4.4175 0.999588Z"
              fill="#F5F5F5"
            />
            <path
              d="M4.4175 2.87064L4.53676 3.2283L4.91378 3.23117C4.9626 3.2316 4.98281 3.29385 4.94356 3.32284L4.64028 3.54684L4.75405 3.90628C4.76882 3.95279 4.71578 3.99136 4.67605 3.96292L4.36934 3.74368L4.06264 3.96292C4.02291 3.9913 3.96999 3.95279 3.98464 3.90628L4.09841 3.54684L3.79513 3.32284C3.75588 3.29379 3.77608 3.2316 3.82491 3.23117L4.20193 3.2283L4.32119 2.87064C4.33657 2.82437 4.40206 2.82437 4.4175 2.87064Z"
              fill="#F5F5F5"
            />
            <path
              d="M4.4175 4.74173L4.53676 5.0994L4.91378 5.10226C4.9626 5.10269 4.98281 5.16495 4.94356 5.19394L4.64028 5.41794L4.75405 5.77737C4.76882 5.82388 4.71578 5.86246 4.67605 5.83401L4.36934 5.61478L4.06264 5.83401C4.02291 5.8624 3.96999 5.82388 3.98464 5.77737L4.09841 5.41794L3.79513 5.19394C3.75588 5.16489 3.77608 5.10269 3.82491 5.10226L4.20193 5.0994L4.32119 4.74173C4.33657 4.69547 4.40206 4.69547 4.4175 4.74173Z"
              fill="#F5F5F5"
            />
            <path
              d="M4.4175 6.61287L4.53676 6.97053L4.91378 6.9734C4.9626 6.97383 4.98281 7.03609 4.94356 7.06508L4.64028 7.28908L4.75405 7.64851C4.76882 7.69502 4.71578 7.73359 4.67605 7.70515L4.36934 7.48591L4.06264 7.70515C4.02291 7.73353 3.96999 7.69502 3.98464 7.64851L4.09841 7.28908L3.79513 7.06508C3.75588 7.03602 3.77608 6.97383 3.82491 6.9734L4.20193 6.97053L4.32119 6.61287C4.33657 6.56654 4.40206 6.56654 4.4175 6.61287Z"
              fill="#F5F5F5"
            />
            <path
              d="M4.4175 8.48401L4.53676 8.84167L4.91378 8.84454C4.9626 8.84497 4.98281 8.90723 4.94356 8.93622L4.64028 9.16022L4.75405 9.51965C4.76882 9.56616 4.71578 9.60473 4.67605 9.57629L4.36934 9.35705L4.06264 9.57629C4.02291 9.60467 3.96999 9.56616 3.98464 9.51965L4.09841 9.16022L3.79513 8.93622C3.75588 8.90716 3.77608 8.84497 3.82491 8.84454L4.20193 8.84167L4.32119 8.48401C4.33657 8.43762 4.40206 8.43762 4.4175 8.48401Z"
              fill="#F5F5F5"
            />
            <path
              d="M5.87014 1.91751L5.9894 2.27518L6.36641 2.27804C6.41524 2.27847 6.43544 2.34073 6.3962 2.36972L6.09292 2.59372L6.20668 2.95315C6.22146 2.99966 6.16842 3.03824 6.12868 3.00979L5.82198 2.79056L5.51528 3.00979C5.47555 3.03818 5.42263 2.99966 5.43728 2.95315L5.55105 2.59372L5.24776 2.36972C5.20852 2.34067 5.22872 2.27847 5.27755 2.27804L5.65456 2.27518L5.77382 1.91751C5.78914 1.87125 5.8547 1.87125 5.87014 1.91751Z"
              fill="#F5F5F5"
            />
            <path
              d="M5.87014 3.78865L5.9894 4.14632L6.36641 4.14918C6.41524 4.14961 6.43544 4.21187 6.3962 4.24086L6.09292 4.46486L6.20668 4.82429C6.22146 4.8708 6.16842 4.90937 6.12868 4.88093L5.82198 4.66169L5.51528 4.88093C5.47555 4.90931 5.42263 4.87086 5.43728 4.82429L5.55105 4.46486L5.24776 4.24086C5.20852 4.21181 5.22872 4.14961 5.27755 4.14918L5.65456 4.14632L5.77382 3.78865C5.78914 3.74232 5.8547 3.74232 5.87014 3.78865Z"
              fill="#F5F5F5"
            />
            <path
              d="M5.87014 5.65974L5.9894 6.01741L6.36641 6.02028C6.41524 6.02071 6.43544 6.08296 6.3962 6.11195L6.09292 6.33595L6.20668 6.69539C6.22146 6.7419 6.16842 6.78047 6.12868 6.75203L5.82198 6.53279L5.51528 6.75203C5.47555 6.78041 5.42263 6.7419 5.43728 6.69539L5.55105 6.33595L5.24776 6.11195C5.20852 6.0829 5.22872 6.02071 5.27755 6.02028L5.65456 6.01741L5.77382 5.65974C5.78914 5.61342 5.8547 5.61342 5.87014 5.65974Z"
              fill="#F5F5F5"
            />
            <path
              d="M5.87014 7.53079L5.9894 7.88846L6.36641 7.89133C6.41524 7.89175 6.43544 7.95401 6.3962 7.983L6.09292 8.207L6.20668 8.56644C6.22146 8.61294 6.16842 8.65152 6.12868 8.62308L5.82198 8.40384L5.51528 8.62308C5.47555 8.65146 5.42263 8.61294 5.43728 8.56644L5.55105 8.207L5.24776 7.983C5.20852 7.95395 5.22872 7.89175 5.27755 7.89133L5.65456 7.88846L5.77382 7.53079C5.78914 7.48453 5.8547 7.48453 5.87014 7.53079Z"
              fill="#F5F5F5"
            />
            <path
              d="M7.32186 0.999588L7.44112 1.35725L7.81813 1.36012C7.86696 1.36055 7.88716 1.4228 7.84792 1.4518L7.54464 1.67579L7.65841 2.03523C7.67318 2.08174 7.62014 2.12031 7.5804 2.09187L7.27364 1.87263L6.96694 2.09187C6.92721 2.12025 6.87429 2.08174 6.88894 2.03523L7.00271 1.67579L6.69942 1.4518C6.66018 1.42274 6.68038 1.36055 6.72921 1.36012L7.10622 1.35725L7.22548 0.999588C7.24093 0.953262 7.30642 0.953262 7.32186 0.999588Z"
              fill="#F5F5F5"
            />
            <path
              d="M7.32186 2.87064L7.44112 3.2283L7.81813 3.23117C7.86696 3.2316 7.88716 3.29385 7.84792 3.32284L7.54464 3.54684L7.65841 3.90628C7.67318 3.95279 7.62014 3.99136 7.5804 3.96292L7.27364 3.74368L6.96694 3.96292C6.92721 3.9913 6.87429 3.95279 6.88894 3.90628L7.00271 3.54684L6.69942 3.32284C6.66018 3.29379 6.68038 3.2316 6.72921 3.23117L7.10622 3.2283L7.22548 2.87064C7.24093 2.82437 7.30642 2.82437 7.32186 2.87064Z"
              fill="#F5F5F5"
            />
            <path
              d="M7.32186 4.74173L7.44112 5.0994L7.81813 5.10226C7.86696 5.10269 7.88716 5.16495 7.84792 5.19394L7.54464 5.41794L7.65841 5.77737C7.67318 5.82388 7.62014 5.86246 7.5804 5.83401L7.27364 5.61478L6.96694 5.83401C6.92721 5.8624 6.87429 5.82388 6.88894 5.77737L7.00271 5.41794L6.69942 5.19394C6.66018 5.16489 6.68038 5.10269 6.72921 5.10226L7.10622 5.0994L7.22548 4.74173C7.24093 4.69547 7.30642 4.69547 7.32186 4.74173Z"
              fill="#F5F5F5"
            />
            <path
              d="M7.32186 6.61287L7.44112 6.97053L7.81813 6.9734C7.86696 6.97383 7.88716 7.03609 7.84792 7.06508L7.54464 7.28908L7.65841 7.64851C7.67318 7.69502 7.62014 7.73359 7.5804 7.70515L7.27364 7.48591L6.96694 7.70515C6.92721 7.73353 6.87429 7.69502 6.88894 7.64851L7.00271 7.28908L6.69942 7.06508C6.66018 7.03602 6.68038 6.97383 6.72921 6.9734L7.10622 6.97053L7.22548 6.61287C7.24093 6.56654 7.30642 6.56654 7.32186 6.61287Z"
              fill="#F5F5F5"
            />
            <path
              d="M7.32186 8.48401L7.44112 8.84167L7.81813 8.84454C7.86696 8.84497 7.88716 8.90723 7.84792 8.93622L7.54464 9.16022L7.65841 9.51965C7.67318 9.56616 7.62014 9.60473 7.5804 9.57629L7.27364 9.35705L6.96694 9.57629C6.92721 9.60467 6.87429 9.56616 6.88894 9.51965L7.00271 9.16022L6.69942 8.93622C6.66018 8.90716 6.68038 8.84497 6.72921 8.84454L7.10622 8.84167L7.22548 8.48401C7.24093 8.43762 7.30642 8.43762 7.32186 8.48401Z"
              fill="#F5F5F5"
            />
            <path
              d="M8.77444 1.91751L8.8937 2.27518L9.27071 2.27804C9.31954 2.27847 9.33974 2.34073 9.3005 2.36972L8.99721 2.59372L9.11098 2.95315C9.12575 2.99966 9.07271 3.03824 9.03298 3.00979L8.72628 2.79056L8.41958 3.00979C8.37984 3.03818 8.32693 2.99966 8.34157 2.95315L8.45534 2.59372L8.15206 2.36972C8.11281 2.34067 8.13302 2.27847 8.18185 2.27804L8.55886 2.27518L8.67812 1.91751C8.69356 1.87125 8.75899 1.87125 8.77444 1.91751Z"
              fill="#F5F5F5"
            />
            <path
              d="M8.77444 3.78865L8.8937 4.14632L9.27071 4.14918C9.31954 4.14961 9.33974 4.21187 9.3005 4.24086L8.99721 4.46486L9.11098 4.82429C9.12575 4.8708 9.07271 4.90937 9.03298 4.88093L8.72628 4.66169L8.41958 4.88093C8.37984 4.90931 8.32693 4.87086 8.34157 4.82429L8.45534 4.46486L8.15206 4.24086C8.11281 4.21181 8.13302 4.14961 8.18185 4.14918L8.55886 4.14632L8.67812 3.78865C8.69356 3.74232 8.75899 3.74232 8.77444 3.78865Z"
              fill="#F5F5F5"
            />
            <path
              d="M8.77444 5.65974L8.8937 6.01741L9.27071 6.02028C9.31954 6.02071 9.33974 6.08296 9.3005 6.11195L8.99721 6.33595L9.11098 6.69539C9.12575 6.7419 9.07271 6.78047 9.03298 6.75203L8.72628 6.53279L8.41958 6.75203C8.37984 6.78041 8.32693 6.7419 8.34157 6.69539L8.45534 6.33595L8.15206 6.11195C8.11281 6.0829 8.13302 6.02071 8.18185 6.02028L8.55886 6.01741L8.67812 5.65974C8.69356 5.61342 8.75899 5.61342 8.77444 5.65974Z"
              fill="#F5F5F5"
            />
            <path
              d="M8.77444 7.53079L8.8937 7.88846L9.27071 7.89133C9.31954 7.89175 9.33974 7.95401 9.3005 7.983L8.99721 8.207L9.11098 8.56644C9.12575 8.61294 9.07271 8.65152 9.03298 8.62308L8.72628 8.40384L8.41958 8.62308C8.37984 8.65146 8.32693 8.61294 8.34157 8.56644L8.45534 8.207L8.15206 7.983C8.11281 7.95395 8.13302 7.89175 8.18185 7.89133L8.55886 7.88846L8.67812 7.53079C8.69356 7.48453 8.75899 7.48453 8.77444 7.53079Z"
              fill="#F5F5F5"
            />
            <path
              d="M10.2266 0.999588L10.3458 1.35725L10.7229 1.36012C10.7717 1.36055 10.7919 1.4228 10.7526 1.4518L10.4494 1.67579L10.5631 2.03523C10.5779 2.08174 10.5249 2.12031 10.4851 2.09187L10.1784 1.87263L9.87173 2.09187C9.83199 2.12025 9.77907 2.08174 9.79372 2.03523L9.90749 1.67579L9.60421 1.4518C9.56496 1.42274 9.58517 1.36055 9.63399 1.36012L10.011 1.35725L10.1303 0.999588C10.1456 0.953262 10.2111 0.953262 10.2266 0.999588Z"
              fill="#F5F5F5"
            />
            <path
              d="M10.2266 2.87064L10.3458 3.2283L10.7229 3.23117C10.7717 3.2316 10.7919 3.29385 10.7526 3.32284L10.4494 3.54684L10.5631 3.90628C10.5779 3.95279 10.5249 3.99136 10.4851 3.96292L10.1784 3.74368L9.87173 3.96292C9.83199 3.9913 9.77907 3.95279 9.79372 3.90628L9.90749 3.54684L9.60421 3.32284C9.56496 3.29379 9.58517 3.2316 9.63399 3.23117L10.011 3.2283L10.1303 2.87064C10.1456 2.82437 10.2111 2.82437 10.2266 2.87064Z"
              fill="#F5F5F5"
            />
            <path
              d="M10.2266 4.74173L10.3458 5.0994L10.7229 5.10226C10.7717 5.10269 10.7919 5.16495 10.7526 5.19394L10.4494 5.41794L10.5631 5.77737C10.5779 5.82388 10.5249 5.86246 10.4851 5.83401L10.1784 5.61478L9.87173 5.83401C9.83199 5.8624 9.77907 5.82388 9.79372 5.77737L9.90749 5.41794L9.60421 5.19394C9.56496 5.16489 9.58517 5.10269 9.63399 5.10226L10.011 5.0994L10.1303 4.74173C10.1456 4.69547 10.2111 4.69547 10.2266 4.74173Z"
              fill="#F5F5F5"
            />
            <path
              d="M10.2266 6.61287L10.3458 6.97053L10.7229 6.9734C10.7717 6.97383 10.7919 7.03609 10.7526 7.06508L10.4494 7.28908L10.5631 7.64851C10.5779 7.69502 10.5249 7.73359 10.4851 7.70515L10.1784 7.48591L9.87173 7.70515C9.83199 7.73353 9.77907 7.69502 9.79372 7.64851L9.90749 7.28908L9.60421 7.06508C9.56496 7.03602 9.58517 6.97383 9.63399 6.9734L10.011 6.97053L10.1303 6.61287C10.1456 6.56654 10.2111 6.56654 10.2266 6.61287Z"
              fill="#F5F5F5"
            />
            <path
              d="M10.2266 8.48401L10.3458 8.84167L10.7229 8.84454C10.7717 8.84497 10.7919 8.90723 10.7526 8.93622L10.4494 9.16022L10.5631 9.51965C10.5779 9.56616 10.5249 9.60473 10.4851 9.57629L10.1784 9.35705L9.87173 9.57629C9.83199 9.60467 9.77907 9.56616 9.79372 9.51965L9.90749 9.16022L9.60421 8.93622C9.56496 8.90716 9.58517 8.84497 9.63399 8.84454L10.011 8.84167L10.1303 8.48401C10.1456 8.43762 10.2111 8.43762 10.2266 8.48401Z"
              fill="#F5F5F5"
            />
            <path
              d="M11.6787 1.91751L11.798 2.27518L12.175 2.27804C12.2238 2.27847 12.244 2.34073 12.2048 2.36972L11.9015 2.59372L12.0153 2.95315C12.03 2.99966 11.977 3.03824 11.9373 3.00979L11.6306 2.79056L11.3239 3.00979C11.2841 3.03818 11.2312 2.99966 11.2459 2.95315L11.3596 2.59372L11.0564 2.36972C11.0171 2.34067 11.0373 2.27847 11.0861 2.27804L11.4632 2.27518L11.5824 1.91751C11.5978 1.87125 11.6634 1.87125 11.6787 1.91751Z"
              fill="#F5F5F5"
            />
            <path
              d="M11.6787 3.78865L11.798 4.14632L12.175 4.14918C12.2238 4.14961 12.244 4.21187 12.2048 4.24086L11.9015 4.46486L12.0153 4.82429C12.03 4.8708 11.977 4.90937 11.9373 4.88093L11.6306 4.66169L11.3239 4.88093C11.2841 4.90931 11.2312 4.87086 11.2459 4.82429L11.3596 4.46486L11.0564 4.24086C11.0171 4.21181 11.0373 4.14961 11.0861 4.14918L11.4632 4.14632L11.5824 3.78865C11.5978 3.74232 11.6634 3.74232 11.6787 3.78865Z"
              fill="#F5F5F5"
            />
            <path
              d="M11.6787 5.65974L11.798 6.01741L12.175 6.02028C12.2238 6.02071 12.244 6.08296 12.2048 6.11195L11.9015 6.33595L12.0153 6.69539C12.03 6.7419 11.977 6.78047 11.9373 6.75203L11.6306 6.53279L11.3239 6.75203C11.2841 6.78041 11.2312 6.7419 11.2459 6.69539L11.3596 6.33595L11.0564 6.11195C11.0171 6.0829 11.0373 6.02071 11.0861 6.02028L11.4632 6.01741L11.5824 5.65974C11.5978 5.61342 11.6634 5.61342 11.6787 5.65974Z"
              fill="#F5F5F5"
            />
            <path
              d="M11.6787 7.53079L11.798 7.88846L12.175 7.89133C12.2238 7.89175 12.244 7.95401 12.2048 7.983L11.9015 8.207L12.0153 8.56644C12.03 8.61294 11.977 8.65152 11.9373 8.62308L11.6306 8.40384L11.3239 8.62308C11.2841 8.65146 11.2312 8.61294 11.2459 8.56644L11.3596 8.207L11.0564 7.983C11.0171 7.95395 11.0373 7.89175 11.0861 7.89133L11.4632 7.88846L11.5824 7.53079C11.5978 7.48453 11.6634 7.48453 11.6787 7.53079Z"
              fill="#F5F5F5"
            />
            <path
              d="M13.1309 0.999588L13.2501 1.35725L13.6272 1.36012C13.676 1.36055 13.6962 1.4228 13.6569 1.4518L13.3537 1.67579L13.4674 2.03523C13.4822 2.08174 13.4292 2.12031 13.3894 2.09187L13.0827 1.87263L12.776 2.09187C12.7363 2.12025 12.6834 2.08174 12.698 2.03523L12.8118 1.67579L12.5085 1.4518C12.4693 1.42274 12.4895 1.36055 12.5383 1.36012L12.9153 1.35725L13.0346 0.999588C13.0499 0.953262 13.1154 0.953262 13.1309 0.999588Z"
              fill="#F5F5F5"
            />
            <path
              d="M13.1309 2.87064L13.2501 3.2283L13.6272 3.23117C13.676 3.2316 13.6962 3.29385 13.6569 3.32284L13.3537 3.54684L13.4674 3.90628C13.4822 3.95279 13.4292 3.99136 13.3894 3.96292L13.0827 3.74368L12.776 3.96292C12.7363 3.9913 12.6834 3.95279 12.698 3.90628L12.8118 3.54684L12.5085 3.32284C12.4693 3.29379 12.4895 3.2316 12.5383 3.23117L12.9153 3.2283L13.0346 2.87064C13.0499 2.82437 13.1154 2.82437 13.1309 2.87064Z"
              fill="#F5F5F5"
            />
            <path
              d="M13.1309 4.74173L13.2501 5.0994L13.6272 5.10226C13.676 5.10269 13.6962 5.16495 13.6569 5.19394L13.3537 5.41794L13.4674 5.77737C13.4822 5.82388 13.4292 5.86246 13.3894 5.83401L13.0827 5.61478L12.776 5.83401C12.7363 5.8624 12.6834 5.82388 12.698 5.77737L12.8118 5.41794L12.5085 5.19394C12.4693 5.16489 12.4895 5.10269 12.5383 5.10226L12.9153 5.0994L13.0346 4.74173C13.0499 4.69547 13.1154 4.69547 13.1309 4.74173Z"
              fill="#F5F5F5"
            />
            <path
              d="M13.1309 6.61287L13.2501 6.97053L13.6272 6.9734C13.676 6.97383 13.6962 7.03609 13.6569 7.06508L13.3537 7.28908L13.4674 7.64851C13.4822 7.69502 13.4292 7.73359 13.3894 7.70515L13.0827 7.48591L12.776 7.70515C12.7363 7.73353 12.6834 7.69502 12.698 7.64851L12.8118 7.28908L12.5085 7.06508C12.4693 7.03602 12.4895 6.97383 12.5383 6.9734L12.9153 6.97053L13.0346 6.61287C13.0499 6.56654 13.1154 6.56654 13.1309 6.61287Z"
              fill="#F5F5F5"
            />
            <path
              d="M13.1309 8.48401L13.2501 8.84167L13.6272 8.84454C13.676 8.84497 13.6962 8.90723 13.6569 8.93622L13.3537 9.16022L13.4674 9.51965C13.4822 9.56616 13.4292 9.60473 13.3894 9.57629L13.0827 9.35705L12.776 9.57629C12.7363 9.60467 12.6834 9.56616 12.698 9.51965L12.8118 9.16022L12.5085 8.93622C12.4693 8.90716 12.4895 8.84497 12.5383 8.84454L12.9153 8.84167L13.0346 8.48401C13.0499 8.43762 13.1154 8.43762 13.1309 8.48401Z"
              fill="#F5F5F5"
            />
          </g>
          <defs>
            <clipPath id="clip0_1630_110601">
              <rect width="31.25" height="20" rx="4" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ) : (
        <Image
          width={'6'}
          height={'6'}
          source={require('../../../../assets/images/PNGImages/FlagIcon/UsFlag.png')}
          alt="aa"
        />
      )}
    </>
  );
};

export default USFlagSVG;
