import {Button} from 'native-base';
import React from 'react';
import {BUTTON_TYPE} from '../../../constants';
import {Colors} from '../../../styles';
import {DisplayText} from '../DisplayText/DisplayText';
import {IModalActionBtn} from './interface';
import {styles} from './ModalActionBtnStyle';

const ModalActionBtn = (props: IModalActionBtn) => {
  const {
    size,
    btnText,
    onClickAction,
    btnStype,
    customStyle,
    rightIcon,
    leftIcon,
    textColor,
    loading,
    isShow = true,
  } = props;

  return (
    <>
      {BUTTON_TYPE.SECONDARY === btnStype && isShow && (
        <Button
          style={[
            styles.btnStyle,
            styles.secondarybtnStyle,
            customStyle,
          ]}
          onPress={() => {
            onClickAction();
          }}
          _focus={{
            backgroundColor: Colors.danger['100'],
            borderColor: 'black',
          }}
          borderColor={'gray.300'}
          rightIcon={rightIcon ? rightIcon : <></>}
          leftIcon={leftIcon ? leftIcon : <></>}
          isDisabled={props.isDisabled}
        >
          <DisplayText
            textLocalId={btnText || ''}
            size={size ? size : 'smBold'}
            extraStyles={{
              color: textColor || Colors.Custom.mainSecondaryBrown
            }}
          />
        </Button>
      )}
      {BUTTON_TYPE.TERTIARY === btnStype && isShow && (
        <Button
          style={[styles.btnStyle, styles.tertiarybtnStyle, customStyle]}
          onPress={() => {
            onClickAction();
          }}
          _focus={{
            backgroundColor: Colors.primary['100'],
            borderColor: 'black',
          }}
          borderColor={'gray.300'}
          rightIcon={rightIcon ? rightIcon : <></>}
          leftIcon={leftIcon ? leftIcon : <></>}
          isDisabled={props.isDisabled}
          isLoading={loading}
        >
          <DisplayText
            textLocalId={btnText}
            size={size ? size : 'smBold'}
            extraStyles={{color: textColor || Colors.primary['500'] || ''}}
          />
        </Button>
      )}
      {BUTTON_TYPE.PRIMARY === btnStype && isShow && (
        <Button
          marginRight={customStyle?.marginRight ? customStyle?.marginRight : 0}
          style={[
            styles.btnStyle,
            styles.primarybtnStyle,
            customStyle ? customStyle : {},
          ]}
          onPress={() => {
            onClickAction();
          }}
          rightIcon={rightIcon ? rightIcon : <></>}
          leftIcon={leftIcon ? leftIcon : <></>}
          isDisabled={props.isDisabled}
          isLoading={loading}
        >
          <DisplayText
            textLocalId={btnText || ''}
            size={size ? size : 'smBold'}
            extraStyles={{color: textColor || Colors.Custom.mainPrimaryPurple || ''}}
          />
        </Button>
      )}
    </>
  );
};

export default ModalActionBtn;
