import React from "react";

function PDFIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="18"
      fill="none"
      viewBox="0 0 16 18"
      id="pdf-icon"
    >
      <path
        id={'pdf'}
        fill="#475467"
        d="M3.692 9a.602.602 0 00-.435.188.658.658 0 00-.18.455v3.214c0 .17.065.334.18.455.116.12.272.188.435.188.164 0 .32-.068.435-.188a.658.658 0 00.18-.455v-.643h.308c.408 0 .8-.169 1.088-.47.289-.302.45-.71.45-1.137 0-.426-.161-.835-.45-1.136A1.506 1.506 0 004.615 9h-.923zm.923 1.929h-.307v-.643h.307c.082 0 .16.034.218.094a.329.329 0 010 .454.301.301 0 01-.218.095zM6.77 9.643c0-.17.065-.334.18-.455A.602.602 0 017.385 9h.307c.572 0 1.12.237 1.523.659.404.422.631.994.631 1.591s-.227 1.169-.63 1.591a2.108 2.108 0 01-1.524.659h-.307a.602.602 0 01-.436-.188.658.658 0 01-.18-.455V9.643zM8 12.16a.928.928 0 00.447-.353.994.994 0 000-1.114A.928.928 0 008 10.34v1.82zm2.461.697V9.643c0-.17.065-.334.18-.455A.602.602 0 0111.078 9h1.846c.163 0 .32.068.435.188.116.12.18.284.18.455 0 .17-.064.334-.18.454a.602.602 0 01-.435.189h-1.23v.643h1.23c.163 0 .32.067.435.188.116.12.18.284.18.454 0 .17-.064.334-.18.455a.602.602 0 01-.435.188h-1.23v.643c0 .17-.066.334-.18.455a.602.602 0 01-.436.188.602.602 0 01-.435-.188.658.658 0 01-.18-.455zM1.846 1.93V7.07h-.615c-.327 0-.64.136-.87.377-.231.241-.361.568-.361.91v5.785c0 .34.13.668.36.909.231.241.544.377.87.377h.616v.642c0 .512.195 1.002.54 1.364.347.362.817.565 1.306.565h8.616c.49 0 .959-.203 1.305-.565.346-.361.54-.852.54-1.364v-.642h.616c.327 0 .64-.136.87-.377.231-.241.361-.568.361-.91V8.358c0-.34-.13-.668-.36-.909a1.205 1.205 0 00-.87-.377h-.616V5.032c0-.51-.195-1.001-.541-1.363L10.642.564A1.807 1.807 0 009.337 0H3.692c-.49 0-.959.203-1.305.565-.346.362-.54.852-.54 1.364zm1.846-.643H8V4.5c0 .511.194 1.002.54 1.364.347.361.817.565 1.306.565h3.077v.642H3.077V1.93c0-.17.065-.334.18-.455a.602.602 0 01.435-.188zM3.077 16.07v-.642h9.846v.642c0 .17-.065.334-.18.455a.602.602 0 01-.435.188H3.692a.602.602 0 01-.435-.188.658.658 0 01-.18-.455zm9.846-11.039v.11H9.846a.602.602 0 01-.435-.187.658.658 0 01-.18-.455V1.286h.106c.163 0 .32.067.435.188l2.97 3.104c.116.12.181.284.181.454zM1.231 8.357h13.538v5.786H1.231V8.357z"
      ></path>
    </svg>
  );
}

export default PDFIcon;
