import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {Alert, Drawer, InputNumber, Select} from 'antd';
import {Content} from 'antd/lib/layout/layout';
import {
  CheckIcon,
  Divider,
  HStack,
  Image,
  Pressable,
  Spinner,
  Text,
  useMediaQuery,
  useToast,
  View,
  VStack,
  Select as NBSelect,
  FormControl,
} from 'native-base';
import React, {useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react';
import {
  BUTTON_TYPE,
  DISPLAY_DATE_FORMAT,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH,
  MLOV_CATEGORY,
  PARENT_CODE,
  PERSON_TYPES,
} from '../../../../../constants';
import {CRM_BASE_URL} from '../../../../../constants/Configs';
import {FHIR_RESOURCE, KEY_ALLOWED_OPERATIONS} from '../../../../../constants/FhirConstant';
import {WEB_SOCKET_EVENT_CODE} from '../../../../../constants/WebSocketConst';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import {IMlov} from '../../../../../Interfaces';
import {getPractitionersData} from '../../../../../services/CommonService/AidBoxService';
import BaseService from '../../../../../services/CommonService/BaseService';
// import {UserQueries} from '../../../../../services';
import CommonService from '../../../../../services/CommonService/CommonService';
import FormsQueries from '../../../../../services/Forms/FormsQueries';
import UserPracticeLocationQueries from '../../../../../services/Location/UserPracticeLocationQueries';
import UserQueries, {
  ADD_CONTACT_DETAILS,
  ADD_PERSON_DATA,
  CREATE_ADDRESS,
  CREATE_PERSON_ADDRESS_DETAILS,
  CREATE_PERSON_CONTACT_DETAILS,
  CREATE_PROVIDER_IDENTIFIERS,
  CREATE_UPDATE_SINGLE_USER,
  GET_SINGLE_USER,
} from '../../../../../services/User/UserQueries';
import {Colors} from '../../../../../styles';
import {
  getEHRName,
  getResourceAbilities,
  getSexAtBirthTransformValues,
  isFoldEhr,
  isOrderTypeEnabled,
} from '../../../../../utils/capabilityUtils';
import {
  getAccountId,
  getAccountUUID,
  getUserData,
  numericStringMask,
  unmaskPhoneNumber,
  isEmployerRole,
  getUserUUID,
  getFoldAccessToken,
  isChildAccount,
} from '../../../../../utils/commonUtils';
import {showToast, ToastType} from '../../../../../utils/commonViewUtils';
import {isFutureDate} from '../../../../../utils/DateUtils';
import {EventBus} from '../../../../../utils/EventBus';
import LocalStorage from '../../../../../utils/LocalStorage';
import {getMlovListFromCategory} from '../../../../../utils/mlovUtils';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import {ModalActionAntSelect} from '../../../../common/ModalActionCommonComponent/ModalActionAntSelect';
import {ModalActionDatePicker} from '../../../../common/ModalActionCommonComponent/ModalActionDatePicker';
import {ModalActionInput} from '../../../../common/ModalActionCommonComponent/ModalActionInput';
import {ModalActionSelect} from '../../../../common/ModalActionCommonComponent/ModalActionSelect';
import {ModalActionSubTitle} from '../../../../common/ModalActionTitle/ModalActionSubTitle';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import {ProfileUpload} from '../../../../common/ProfileUpload/ProfileUpload';
import StateAutoComplete from '../../../../common/State/State';
import {ZipCodeAutoComplete} from '../../../../common/ZipCodeComponent';
import {FHForm} from '../../../Forms/FHFormio';
import {IFormCommonData} from '../../../Forms/FHFormio/CustomComponents/CustomWrapper/CustomWrapper';
import {IUser, IUserRole, IUserRoleMap} from '../interfaces';
import {PractitionerData} from '../InviteUserDrawer/interfaces';
import {
  getFinalPractitionerData,
  getFormattedPractitionerData,
} from '../InviteUserDrawer/InviteUserDrawerUtils';
import {styles} from './AddEditUserStyles';
import {
  getContactDetailsDataForPost,
  getFirstAndLastNameFromUserName,
  getFormattedUserData,
  getPostData,
  getProviderIdentifierDataForPost,
  getUserCategoriesQueryObj,
  processContactDetails,
  processProviderIdentifierDetails,
  validates,
} from './addEditUsersUtils';
import {
  createPracticeLocationArray,
  getAccountLocationsBySelectedLocationUUids,
  getLocationsToAddOrUpdate,
  getLocationsToDelete,
  getUserRoleIdsToAddOrUpdate,
  getUserRoleIdsToDelete,
} from './AddEditUserUtils';
import {
  ContactDetailState,
  IAddEditUserProps,
  IdentifierTypeState,
  User,
  Person,
  AddressInfo,
  IUserPracticeLocationResp,
} from './interfaces';
import {IZipCodeData} from '../../../../common/ZipCodeComponent/interfaces';
import {CityAutoComplete} from '../../../../common/City';
import moment from 'moment';
import {getGenderIdentityLabel} from '../../../../../utils/capabilityUtils';
import {SIGNATURE_COMPONENT} from './constants';
import {addOrUpdateSignature, getSignature} from './Queries';
import {useIntl} from 'react-intl';
import {dataURItoBlob} from '../../../Forms/FHFormio/Utils';
import SkeletonLoader from './components/SkeletonLoader';
import { USER_ROLE_CODES } from '../../../../../constants/MlovConst';
import { debounce } from 'lodash';
import { OrderType } from '../../../../PersonOmniView/MiddleContainer/Orders/OrdersAndReports/OrderConstants';
import {mapGenderDisplayCode} from '../../Leads/LeadView/AddOrUpdateLead/AddOrUpdateUtils';
import {deleteUserPhoneNumberFromCache} from '../../../../../utils/CacheUtils';
import permissionQueries from '../../../UserAccess/permissionQueries';

const {Option} = Select;
interface IAccountRoles {
  userRole: IUserRole;
  customRoleCode?:string;
  id?: string;
  roleName?: string;
}
const reactStyles: Record<string, React.CSSProperties> = {
  inputNumber: {
    width: '100%',
    borderRadius: 6,
  },
  drawer: {
    minWidth: 700,
  },  
};

const AddEditUser = React.forwardRef((props: IAddEditUserProps, ref) => {
  const {user, onClose, parentCode, selectedLocation} = props;
  const commonData = useContext(CommonDataContext);
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const formattedUserData: IUser = getFormattedUserData(user || ({} as IUser));
  const eventBus = EventBus.getEventBusInstance();
  const intl = useIntl();
  const isMedOrderEnabled = isOrderTypeEnabled(OrderType.MED, '', '');
  const isLoggedInUserEdit = parentCode === PARENT_CODE.SIDE_MENU_PANEL;
  const practitionerAbilities = getResourceAbilities(
    FHIR_RESOURCE.PRACTITIONER,
    selectedLocation?.locationGroup?.id,
    ''
  );
  const ehrName: any = getEHRName('', selectedLocation?.locationGroup?.id);
  const mask = `(###) ###-####`;
  let personGender = getMlovListFromCategory(
    commonData.MLOV,
    MLOV_CATEGORY.PERSON_GENDER
  );
  const genderTransformValues = getSexAtBirthTransformValues(
    KEY_ALLOWED_OPERATIONS.GENDER,
    selectedLocation?.locationGroup?.id,
    ''
  );
  personGender = mapGenderDisplayCode(personGender,genderTransformValues)
  const userGender: IMlov[] = [];
  practitionerAbilities?.keyAllowedOperations?.gender?.possibleValues?.forEach((item) => {
    const matchedData = personGender.find((gender) => gender.code == item.code);
    if (matchedData) {
      userGender.push({
        ...matchedData,
        value: item.display
      });
    }
  });
  const personContactTypeList = getMlovListFromCategory(
    commonData.MLOV,
    MLOV_CATEGORY.PERSON_CONTACT_TYPE
  );
  const providerTypesList = getMlovListFromCategory(
    commonData.MLOV,
    MLOV_CATEGORY.PROVIDER_IDENTIFIER_TYPE
  );
  const [userFormData, setUserFormData]: any = useState(formattedUserData);
  const [errors, setErrors]: any = useState({});
  const [signatureComponent, setSignatureComponent] = useState([
    SIGNATURE_COMPONENT,
  ]);
  const [formResponse, setFormResponse] = useState<any>();
  const showPractitionerDropdown = practitionerAbilities?.isEnabled || false;
  const isChildAccountFlag = isChildAccount();
  const [stateData, setStateData] = useState({
    loading: true,
    isSubmitting: false,
    npiAPILoading: false,
    userRoles: [] as IMlov[],
    selectedRoles: [] as IAccountRoles[],
    practiceLocations: [] as any[],
    location: [] as {accountLocationId: string, name: string}[],
    selectedUserRoles: user?.userRoles || ([] as any[]),
    selectedLocations: user?.userPracticeLocations || ([] as any[]),
    selectedUser: user as any,
    showDrawer: false,
    profileImgSrc: user?.avatar_url || ('' as any),
    profileImageBlob: {} as any,
    userName: '',
    externalUserList: [] as PractitionerData[],
    selectedExternalUser: {} as PractitionerData,
    isSelectedDisabled: false,
    errors: '' as string,
    ehrName,
    isFoldEhr: isFoldEhr(ehrName),
    npiNumber: {} as IdentifierTypeState,
    deaNumber: {} as IdentifierTypeState,
    licenseState: '',
    signatureResponse: '' as any,
    stateId: '',
    stateName: '',
    npiError: '',
  });
  const toast = useToast();

  const handleProfileImageChange = (imageData: any, code: string) => {
    switch (code) {
      case 'delete':
        setStateData({
          ...stateData,
          profileImgSrc: user?.avatar_url,
          profileImageBlob: {},
        });
        break;
      case 'setImage':
        setStateData({
          ...stateData,
          profileImgSrc: URL.createObjectURL(imageData?.file),
          profileImageBlob: imageData?.file,
        });
        break;
    }
  };

  const accountUuid = getAccountUUID();
  // const userUuid = getUserUUID();
  const accountId = getAccountId();
  // const userId = getUserId();
  const userId = getUserUUID();
  const foldToken = getFoldAccessToken();
  const [signatureFormOptions] = useState({
    foldAccessToken: foldToken,
    accountUUID: accountUuid,
    userId: userId,
  });

  const [createPracticeLocations] = useMutation(
    UserPracticeLocationQueries.CreateUserPracticeLocations
  );

  const [updateUserPracticeLocations] = useMutation(
    UserPracticeLocationQueries.UpdateUserPracticeLocations
  );

  const [createUserRoles] = useMutation(
    UserPracticeLocationQueries.CreateUserRoles
  );
  const [updateUserRoles] = useMutation(
    UserPracticeLocationQueries.UpdateUserRoles
  );

  const [updateAccountUserWithExternalUserId] = useMutation(
    UserQueries.UpdateAccountUserWithExternalUserId
  );

  const [getAccountUserExternalUserId] = useLazyQuery(
    UserPracticeLocationQueries.GetAccountUserExternalUserId
  );

  const [createPersonEntry] = useMutation(ADD_PERSON_DATA);

  const [createContactDetail] = useMutation(ADD_CONTACT_DETAILS);

  const [createPersonContactDetail] = useMutation(
    CREATE_PERSON_CONTACT_DETAILS
  );

  const [createProviderIdentifiers] = useMutation(CREATE_PROVIDER_IDENTIFIERS);

  const [createOrUpdateSingleUser] = useMutation(CREATE_UPDATE_SINGLE_USER);

  const [createAddress] = useMutation(CREATE_ADDRESS);

  const [createPersonAddessDetails] = useMutation(
    CREATE_PERSON_ADDRESS_DETAILS
  );


  const setLoadingState = (isLoading: boolean) => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: isLoading,
      };
    });
  };

  const [accountPracticeLocationsQuery] = useLazyQuery<any>(
    UserPracticeLocationQueries.GetAccountPracticeLocations,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );


  const getAccountLocations = async () => {
    setLoadingState(true);
    accountPracticeLocationsQuery()
      .then((res) => {
        if (res?.data?.accountLocations?.length) {
          setStateData((prev) => {
            return {
              ...prev,
              practiceLocations: res.data?.accountLocations || [],
              loading: false,
            };
          });
        }
      })
      .catch((err) => {

      });
  };

  const onCancel = () => {
    setStateData((prev) => {
      return {
        ...prev,
        showDrawer: false,
      };
    });
    setTimeout(() => {
      onClose();
    }, 200);
  };

  const uploadImage = (name: string) => {
    if (stateData.profileImageBlob.uid) {
      const commonService = CommonService.getCommonServiceInstance();
      const axiosService = commonService.axiosService;
      const formData = new FormData();
      formData.append('profile[name]', userFormData.name);
      formData.append('profile[email]', userFormData?.email?.value || '');
      formData.append('profile[display_name]', '');
      formData.append('profile[avatar]', stateData.profileImageBlob);

      axiosService
        .put(`/profile`, formData, {
          headers: {
            'content-type': 'multipart/form-data',
          },
        })
        .then((responseMsg: any) => {

          LocalStorage.getItem('user')
            .then((result) => {
              const userData = JSON.parse(result);
              const updateUserData: any = userData;
              updateUserData['data']['avatar_url'] =
                responseMsg?.data?.avatar_url;
                LocalStorage.setItem(
                  'user',
                  JSON.stringify(updateUserData)
                );
            })
            .catch((err) => {
              onProfileUpdate();
            });
          eventBus.broadcastEvent(WEB_SOCKET_EVENT_CODE.PROFILE_URL_UPDATE, {
            name: name,
            profileImage: stateData.profileImgSrc,
          });
          onProfileUpdate();
        })
        .catch((err) => {
          // catch
        });
    } else {
      onProfileUpdate();
      eventBus.broadcastEvent(WEB_SOCKET_EVENT_CODE.PROFILE_URL_UPDATE, {
        name: name,
        profileImage: stateData.profileImgSrc,
      });
    }
  };

  const showErrorToast = () => {
    showToast(toast, intl.formatMessage({id: 'apiErrorMsg'}), ToastType.error);
  };

  const handleSignatureUpload = async () => {
    const dataBlob = dataURItoBlob(formResponse?.data?.signature);
    const formData = new FormData();
    formData.append('file', dataBlob);
    return addOrUpdateSignature(formData);
  };

  const createProviderIdentifier = async () => {
    const data = getProviderIdentifierDataForPost(
      userFormData,
      providerTypesList,
      accountUuid,
      userFormData?.deaStateId,
      true
    );
    return createProviderIdentifiers({
      variables: {
        data: data,
      },
      onError: (err) => {
        showErrorToast();
        onCancel();
      },
    });
  };
  const handleContactDetails = async (personId: string) => {
    const data = getContactDetailsDataForPost(
      userFormData,
      personContactTypeList,
      accountUuid,
      true
    );
    return createContactDetail({
      variables: {
        params: data,
      },
      onCompleted: async (data) => {
        const contactDetailIdsList = data?.createContactDetails?.returning;
        if (contactDetailIdsList?.length > 0) {
          const personContactDetails = await contactDetailIdsList.map(
            (item: {id: string}) => ({
              personId: personId,
              contactDetailId: item.id,
              accountId: accountUuid,
            })
          );
          await createPersonContactDetail({
            variables: {
              params: personContactDetails,
            },
            onError: (err) => {
              showErrorToast();
              onCancel();
            },
          });
        }
      },
      onError: (err) => {
        showErrorToast();
        onCancel();
      },
    });
  };

  const createAddressDetails = async (personId: string) => {
    const data = {
      typeId: userFormData?.addressTypeId,
      cityId: userFormData?.cityId,
      stateId: userFormData?.stateId,
      line1: userFormData?.line1,
      line2: userFormData?.line2,
      countryId: userFormData?.countryId,
      accountId: accountUuid,
      zipcodeId: userFormData?.zipcodeId,
    };
    return createAddress({
      variables: {
        params: data,
      },
      onCompleted: async (data) => {
        await createPersonAddessDetails({
          variables: {
            params: {
              personId: personId,
              addressDetailId: data?.createAddress?.id,
              accountId: accountUuid,
            },
          },
        });
      },
      onError: (err) => {
        showErrorToast();
        onCancel();
      },
    });
  };

  const handleSubmit = async () => {
    const {validate, errors} = validates(
      {
        ...userFormData,
        phone: userFormData?.phone?.value,
        zipCodeId: userFormData?.zipcodeId,
        zipCode: userFormData?.zipcode,
        email: userFormData?.email?.value,
        fax: userFormData?.fax?.value,
      },
      validationsFlags
    );
    const isNPIRequired = validationsFlags.isNPIRequired;
    let isNPIError = userFormData?.npiError?.length > 0;
    if (isNPIRequired && !userFormData.NPI_NUMBER?.value) {
      isNPIError = true;
      setUserFormData((prev: any) => ({...prev, npiError: 'NPI is required'}));
    }
    setErrors(errors);
    if (user?.uuid) {
      deleteUserPhoneNumberFromCache(user.uuid);
    }
    if (
      validate &&
      !isNPIError
    ) {
      setStateData((prev) => {
        return {
          ...prev,
          isSubmitting: true,
        };
      });
      if (formResponse?.data?.signature?.length > 0) {
        await handleSignatureUpload()
          .then((response) => {
            if (response?.data?.id) {
              userFormData.SIGNATURE.value = response?.data?.id;
              setUserFormData((prev: any) => {
                return {
                  ...prev,
                  SIGNATURE: {
                    ...prev.SIGNATURE,
                    value: response?.data?.id,
                  },
                };
              });
            }
          })
          .catch((err) => {
            showErrorToast();
            return;
          });
      }


      setStateData((prev) => {
        return {
          ...prev,
          userName: userFormData.name,
        };
      });
      if (!userFormData?.personId) {
        // create person entry
        createPersonEntry({
          variables: {
            params: {
              name: userFormData?.name,
              genderId: userFormData?.genderId,
              dateOfBirth: userFormData?.birthDate,
              accountId: accountUuid,
              firstName: userFormData?.firstName,
              lastName: userFormData?.lastName,
              middleName: userFormData?.middleName,
              birthSexId: userFormData?.birthSexId,
            },
          },
          onCompleted: async (data) => {
            setUserFormData((prev: any) => {
              return {
                ...prev,
                personId: data?.createPerson?.id,
              };
            });
            Promise.all([
              // create ProviderIdentifierEntry if available
              createProviderIdentifier(),
              // create contact details if available
              handleContactDetails(data?.createPerson?.id),
              // create address
              createAddressDetails(data?.createPerson?.id),
              // update users table
              createOrUpdateSingleUser({
                variables: {
                  params: {
                    id: userFormData.id,
                    uuid: userFormData.uuid,
                    email: userFormData?.email?.value,
                    name: userFormData.name,
                    subtitle: userFormData.subtitle,
                    description: userFormData.description,
                    personId: data?.createPerson?.id,
                  },
                }
              })
            ]).then((response) => {
              if (isLoggedInUserEdit) {
                uploadImage(response?.[3]?.data?.createUser?.name);
              } else {
                onProfileUpdate();
              }
            }).catch((error) => {

              showErrorToast();
              onCancel();
            });
            // create ProviderIdentifierEntry if available
            // await createProviderIdentifier();
            // // create contact details if available
            // await handleContactDetails(data?.createPerson?.id);
            // // create address
            // await createAddressDetails(data?.createPerson?.id);
            // await createOrUpdateSingleUser({
            //   variables: {
            //     params: {
            //       id: userFormData.id,
            //       uuid: userFormData.uuid,
            //       email: userFormData?.email?.value,
            //       name: userFormData.name,
            //       subtitle: userFormData.subtitle,
            //       description: userFormData.description,
            //       personId: data?.createPerson?.id,
            //     },
            //   },
            //   onCompleted: (data) => {
            //     if (isLoggedInUserEdit) {
            //       uploadImage(data?.createUser?.name);
            //     } else {
            //       onProfileUpdate();
            //     }
            //   },
            // });
          },
          onError: (error) => {

            showErrorToast();
            onCancel();
          },
        });
      } else {
        const userData = getPostData(
          userFormData,
          providerTypesList,
          accountUuid,
          personContactTypeList
        );
        createOrUpdateSingleUser({
          variables: {
            params: userData,
          },
          onError: (error) => {
            showErrorToast();

            onCancel();
          },
          onCompleted: (data) => {
            if (isLoggedInUserEdit) {
              uploadImage(data?.createUser?.name);
            } else {
              onProfileUpdate();
            }
            setStateData((prev) => {
              return {
                ...prev,
                isSubmitting: false,
              };
            });
          },
        });
      }
    } else {
      setStateData({...stateData, errors: 'Please enter valid name'});
    }
  };


  useImperativeHandle(ref, () => ({
    handleSubmit() {
      handleSubmit();
    },
  }));

  const onProfileUpdate = () => {
    setStateData((prev) => {
      return {
        ...prev,
        showDrawer: false,
      };
    });
    const data = {
      name: userFormData.name,
      profileImage: stateData.profileImgSrc,
    };
    setTimeout(() => {
      setStateData((prev) => ({
        ...prev,
        isSubmitting: false,
      }));
      onClose('COMPLETE', data);
    }, 200);
  };

  // GET SINGLE USER
  const [getSingleUserById] = useLazyQuery(GET_SINGLE_USER, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    const npiNumber = userFormData.NPI_NUMBER?.value;
    if (npiNumber) {
      setStateData((prev) => ({...prev, npiAPILoading: true}));
      validateNPI(npiNumber);
    }
  }, [userFormData.NPI_NUMBER?.value, userFormData.zipcode, userFormData.firstName, userFormData.lastName]);

  useEffect(() => {
    setStateData((prev: any) => {
      return {
        ...prev,
        selectedUser: user,
        selectedUserRoles: user?.userRoles || ([] as IMlov[]),
        selectedLocations: user?.userPracticeLocations || ([] as any[]),
      };
    });
    setTimeout(() => {
      setStateData((prev) => {
        return {
          ...prev,
          showDrawer: true,
        };
      });
    }, 200);
  }, [props.user?.id]);

  useEffect(() => {
    if (showPractitionerDropdown && userFormData.externalUserId) {
      getPractitionerList();
    }
  }, [userFormData.externalUserId])

  useEffect(() => {
    getSingleUserById({
      variables: {
        userId: userFormData.uuid,
      },
      onCompleted: async (data) => {
        const userData: any = data?.users[0] as User;
        const personData: Person = userData?.persons;
        const contactDetails: {[key: string]: ContactDetailState} =
          processContactDetails(personData?.personContactDetails);
        const address = personData?.personAddressDetails?.[0];
        const providerTypeDetails = processProviderIdentifierDetails(
          userData?.providerIdentifiers,
          providerTypesList
        );
        const userNameData = getFirstAndLastNameFromUserName(userData?.name || '');
        const userRoleCodes: string[] = []
        const userRoleIds: string[] = []
        const selectedRoles: IAccountRoles [] = []
        const location: {
          accountLocationId: string,
          name: string;
        }[] = []
        userData?.userRoles?.forEach(
          (role: {
            userRole: IAccountRoles
          }) => {
            if (role) {
              const useRoleCode =role?.userRole?.customRoleCode || role?.userRole?.userRole?.code
              const userRoleId = role?.userRole?.id
              if (role?.userRole?.userRole?.code && userRoleId && useRoleCode && !userRoleCodes.includes(useRoleCode)) {
                userRoleCodes.push(useRoleCode)
                userRoleIds.push(userRoleId)
                selectedRoles.push(role?.userRole)
              }
            }
          }
        )
        userData?.userPracticeLocations.forEach((userLocation: IUserPracticeLocationResp)=> {
          if (userLocation.accountLocation?.id) {
            location.push({
              accountLocationId: userLocation.accountLocation?.id,
              name:  userLocation.accountLocation.practiceLocation?.name
            })
          }
        })
        location
        const result = {
          ...userData,
          id: userData?.id,
          name: userData?.name,
          uuid: userData?.uuid,
          personId: userData?.personId,
          description: userData?.description,
          email: {
            value: userData?.email,
          },
          firstName: personData?.firstName || userNameData.firstName,
          lastName: personData?.lastName || userNameData.lastName,
          middleName: personData?.middleName,
          genderId: personData?.genderId,
          birthDate: personData?.dateOfBirth,
          birthSexId: personData?.birthSexId,
          addressRelationId: address?.id,
          contactRelationId: personData?.personContactDetails?.[0]?.id,
          contactDetailsId:
            personData?.personContactDetails?.[0]?.contactDetailId,
          line1: address?.addressDetails?.[0]?.line1,
          line2: address?.addressDetails?.[0]?.line2,
          stateName: address?.addressDetails?.[0]?.state?.name,
          stateId: address?.addressDetails?.[0]?.state?.id,
          cityName: address?.addressDetails?.[0]?.city?.name,
          cityId: address?.addressDetails?.[0]?.city?.id,
          addressTypeId: address?.addressDetails?.[0]?.typeId,
          addressId: address?.addressDetails?.[0]?.id,
          zipcode: address?.addressDetails?.[0]?.zipcode?.code,
          zipcodeId: address?.addressDetails?.[0]?.zipcodeId,
          deaStateId: providerTypeDetails?.DEA_NUMBER?.accountLocationId,
          SIGNATURE: {},
          externalUserId: user?.externalUserId || userData.externalUserId || userData.accountUsers?.[0]?.externalUserId,
          userRoleCodes: userRoleCodes,
          userRolesId: userRoleIds,
          ...(!!contactDetails && contactDetails),
          ...(!!providerTypeDetails && providerTypeDetails),
        };

        if (providerTypeDetails?.SIGNATURE?.value) {
          const id = providerTypeDetails?.SIGNATURE?.value;
          const url = CRM_BASE_URL + '/api/attachment/' + id;
          setStateData((prev) => ({
            ...prev,
            signatureResponse: {
              data: {
                signature: {
                  url: url,
                  data: {
                    id: id,
                  },
                  fileName: 'Signature',
                  storage: 'url',
                  type: 'image/png',
                  size: 10265,
                },
              },
            },
          }));
          signatureComponent[0].selectedValue = {
            url: url,
            data: {
              id: id,
            },
            fileName: 'Signature',
            storage: 'url',
            type: 'image/png',
            size: 10265,
          };
          setSignatureComponent([
            {
              ...SIGNATURE_COMPONENT,
              selectedValue: {
                url: url,
                data: {
                  id: id,
                },
                fileName: 'Signature',
                storage: 'url',
                type: 'image/png',
                size: 10265,
              },
            },
          ]);
        }
        setUserFormData((prev: any) => ({...prev, ...result}));
        setStateData(prev=> {
          return {
            ...prev,
            selectedRoles: selectedRoles,
            location: location
          }
        });
        setLoadingState(false);
      },
      onError: () => {
        setLoadingState(false);
        showErrorToast();
      },
    });
  }, []);

  const isRoleAlreadyAdded = (roleId: string) => {
    let isAdded = false;
    const userRoleData = user?.userRoles?.find((singleUserRole) => {
      return roleId === singleUserRole?.userRole?.userRole?.id;
    });
    if (userRoleData && userRoleData.id) {
      isAdded = true;
    }
    return isAdded;
  };



  const filterLocationObject = (locationData: any) => {
    const newData = (locationData || []).map((location: any) => {
      return location?.practiceLocation;
    });
    return newData;
  };
  const handleRoleRemove = (id: any) => {
    const newRoles = stateData.selectedRoles.filter((role) => {
      return role.id !== id;
    });
    setStateData({...stateData, selectedRoles: newRoles});
    const userRole = userFormData.userRolesId.filter((role: any) => {
      return role !== id;
    });
    setUserFormData({...userFormData, userRolesId: userRole});
    updateErrorsWhenRoleChanged(newRoles);
  };

  const getPractitionerList = async () => {
    const promiseList = [
      getPractitionersData(),
      getAccountUserExternalUserId({
        variables: {
          accountId: accountId,
        },
      }),
    ];

    try {
      const responses = await Promise.all(promiseList);
      const data = getFormattedPractitionerData(responses[0].data);
      const accountUsers = responses[1];
      const list = getFinalPractitionerData(data, accountUsers);
      if (userFormData.externalUserId) {
        const externalUser = data.find(
          (item) => item.id == userFormData.externalUserId
        );
        setStateData((prev) => {
          return {
            ...prev,
            externalUserList: data,
            selectedExternalUser: externalUser || ({} as PractitionerData),
            isSelectedDisabled: true,
          };
        });
      } else {
        setStateData((prev) => {
          return {
            ...prev,
            externalUserList: list,
          };
        });
      }
    } catch (error) {
      showToast(
        toast,
        'Something went wrong while fetching the practitioners',
        ToastType.error
      );
    }
  };



  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const drawerWidth = isIPadMiniScreen || isIPadScreen ? '50vw' : '40%';

  const isPhysicianRoleUser = (roles?: IAccountRoles[]): boolean => {
    const isPhysicianRoleSelected = (roles || stateData?.selectedRoles)?.some((item) => (item?.customRoleCode || item.userRole.code) === USER_ROLE_CODES.PHYSICIAN);
    return isPhysicianRoleSelected;
  };

  const updateErrorsWhenRoleChanged = (roles: IAccountRoles[]) => {
    const validationsFlags = {
      isGenderAtBirthRequired: false,
      isPractitionerRequired: false,
      isMobileNumberRequired: isMedOrderEnabled && stateData?.isFoldEhr && isPhysicianRoleUser(roles),
      isAddressLine1Required: isMedOrderEnabled && stateData?.isFoldEhr && isPhysicianRoleUser(roles),
      isFaxNumberRequired: isMedOrderEnabled && stateData?.isFoldEhr && isPhysicianRoleUser(roles),
      isBirthDateRequired: isMedOrderEnabled && stateData?.isFoldEhr && isPhysicianRoleUser(roles),
      isZipCodeRequired: isMedOrderEnabled && stateData?.isFoldEhr && isPhysicianRoleUser(roles),
      isNPIRequired: false,//stateData?.isFoldEhr && isPhysicianRoleUser(),
      shouldPerformPhoneNumberValidation: isMedOrderEnabled
    };
    const {errors} = validates(
      {
        ...userFormData,
        phone: userFormData?.phone?.value,
        zipCodeId: userFormData?.zipcodeId,
        zipCode: userFormData?.zipcode,
        email: userFormData?.email?.value,
        fax: userFormData?.fax?.value,
      },
      validationsFlags
    );
    const isNPIRequired = validationsFlags.isNPIRequired;
    if (isNPIRequired && !userFormData.NPI_NUMBER?.value) {
      setUserFormData((prev: any) => ({...prev, npiError: 'NPI is required'}));
    }
    setErrors(errors);
  }

  const validateNPI = debounce((npiNumber: string) => {
    const {axios} = BaseService.getSharedInstance();
    const path = `/integration/api/order/practitioner/validate-identifier`;
    axios
      .put(
        path,
        {
          type: 'NPI',
          value: npiNumber.toString(),
          ...(userFormData?.zipcode && { zipcode: userFormData.zipcode }),
          ...(userFormData?.firstName && { firstName: userFormData.firstName }),
          ...(userFormData?.lastName && { lastName: userFormData.lastName }),
        },
        {
          headers: contextData.headers,
        }
      )
      .then((result) => {
        setUserFormData((prev: any) => {
          return {
            ...prev,
            npiError: result?.data?.errorMessage || '',
          };
        });
        setStateData((prev) => ({...prev, npiAPILoading: false}));
      })
      .catch((error) => {

        setStateData((prev) => ({...prev, npiAPILoading: false}));
      });
  }, 500);
  const handleFormChange = useCallback((data: any) => {
    setFormResponse(data);
  }, []);
  const genderIdentityLabel = getGenderIdentityLabel(
    selectedLocation?.locationGroup?.id,
    ''
  );
  const validationsFlags = {
    isGenderAtBirthRequired: false,
    isPractitionerRequired: false,
    isMobileNumberRequired: isMedOrderEnabled && stateData?.isFoldEhr && isPhysicianRoleUser(),
    isAddressLine1Required: isMedOrderEnabled && stateData?.isFoldEhr && isPhysicianRoleUser(),
    isFaxNumberRequired: isMedOrderEnabled && stateData?.isFoldEhr && isPhysicianRoleUser(),
    isBirthDateRequired: isMedOrderEnabled && stateData?.isFoldEhr && isPhysicianRoleUser(),
    isZipCodeRequired: isMedOrderEnabled && stateData?.isFoldEhr && isPhysicianRoleUser(),
    isNPIRequired: false,//stateData?.isFoldEhr && isPhysicianRoleUser(),
    shouldPerformPhoneNumberValidation: isMedOrderEnabled,
    isFromProfile: true
  };
  const allErrors = {...errors, npiError: userFormData.npiError};
  const alertMessage = Object.keys(allErrors).map((key) => allErrors[key]).filter((item) => !!item).join(', ');

  const memorizedContentStyle = useMemo<React.CSSProperties>(() => {
    return {
      opacity: stateData.isSubmitting ? 0.3 : 1,
    };
  }, [stateData.isSubmitting]);

  const addEditUserView = () => {
    return (
      <View  mx={0}>
        <Content
          className={'addEDitUserMultiSelect'}
          style={memorizedContentStyle}
        >
          {stateData.loading ? (
            <SkeletonLoader />
          ) : (
            <View marginBottom={4}>
              <VStack space={4}>
                {/* <ModalActionInput
                  fieldIs={'input'}
                  label={'name'}
                  isRequired={true}
                  errors={userFormData.name.length ? false : true}
                  errorText={stateData.errors}
                  value={userFormData.name}
                  onChangeText={(value) => {
                    setUserFormData({...userFormData, name: value});
                  }}
                  extraStyle={{flex: 1}}
                /> */}
                {alertMessage.length > 0 && <Alert message={alertMessage} closable type="error" />}
                <ModalActionSubTitle subTitle={'Basic Info'} />
                {isLoggedInUserEdit && (
                  <ProfileUpload
                    handleProfileImageChange={handleProfileImageChange}
                    profileImgSrc={stateData.profileImgSrc}
                    fullName={user?.name}
                    profileImageBlob={stateData.profileImageBlob}
                  />
                )}
                <HStack space={10} justifyContent="space-between">
                  <ModalActionInput
                    fieldIs={'input'}
                    label={'firstName'}
                    placeholder={'John'}
                    isRequired={true}
                    isInvalid={errors?.firstName}
                    errors={errors?.firstName}
                    errorText={errors?.firstName}
                    value={userFormData?.firstName}
                    onChangeText={(value: string) => {
                      setUserFormData((prev: any) => ({
                        ...prev,
                        firstName: value,
                        name: `${value} ${prev.lastName}`,
                      }));
                    }}
                    extraStyle={{
                      flex: 1.0,
                    }}
                  />

                  <ModalActionInput
                    fieldIs={'input'}
                    label={'middleName'}
                    placeholder={'John'}
                    isRequired={false}
                    value={userFormData.middleName}
                    onChangeText={(value: any) => {
                      setUserFormData((prev: any) => ({
                        ...prev,
                        middleName: value,
                      }));
                    }}
                    extraStyle={{
                      flex: 1.0,
                    }}
                  />
                </HStack>
                <HStack space={10}>
                  <ModalActionInput
                    fieldIs={'input'}
                    label={'lastName'}
                    placeholder="Smith"
                    isRequired={true}
                    value={userFormData.lastName}
                    isInvalid={errors.lastName}
                    errors={errors?.lastName}
                    errorText={errors?.lastName}
                    onChangeText={(value: string) => {
                      setUserFormData((prev: any) => ({
                        ...prev,
                        lastName: value,
                        name: `${prev.firstName} ${value}`,
                      }));
                    }}
                    extraStyle={{
                      flex: 1.0,
                    }}
                  />

                  <ModalActionDatePicker
                    disabledDate={(current: any) => {
                      return current && isFutureDate(current);
                    }}
                    isRequired={validationsFlags.isBirthDateRequired}
                    label={'dateOfBirth'}
                    defaultValue={
                      userFormData?.birthDate
                        ? moment(userFormData?.birthDate)
                        : undefined
                    }
                    format={DISPLAY_DATE_FORMAT}
                    placeholder={DISPLAY_DATE_FORMAT}
                    isInvalid={errors?.birthdate}
                    value={
                      userFormData?.birthDate
                        ? moment(userFormData?.birthDate)
                        : undefined
                    }
                    errors={errors?.birthdate}
                    errorsText={errors?.birthdate}
                    onChange={(value: any) => {
                      setUserFormData((prev: any) => ({
                        ...prev,
                        birthDate: value,
                      }));
                    }}
                    customStyle={{
                      height: 36,
                      borderColor: Colors.Custom.BorderColor,
                    }}
                    extraStyle={{
                      flex: 1.0,
                    }}
                  />
                </HStack>
                <ModalActionInput
                  fieldIs={'input'}
                  label={'credentials'}
                  isRequired={false}
                  value={userFormData.subtitle}
                  onChangeText={(value: any) => {
                    setUserFormData({...userFormData, subtitle: value});
                  }}
                  extraStyle={{flex: 1}}
                />

                <ModalActionInput
                  fieldIs={'textArea'}
                  maxLength={500}
                  label={'profile'}
                  isRequired={false}
                  value={userFormData.description}
                  onChangeText={(value: any) => {
                    setUserFormData({...userFormData, description: value});
                  }}
                  extraStyle={{flex: 1, Height: '70px'}}
                />
              <ModalActionSubTitle  subTitle={'Roles'} />
                <HStack flexWrap={'wrap'} overflow={'auto'}>
                  {stateData.selectedRoles &&
                    stateData.selectedRoles.map((tag: any) => {
                      return (
                        <View
                          mt={1}
                          px={2}
                          py={1}
                          borderRadius={16}
                          bgColor={'gray.100'}
                        >
                          <HStack space={1} alignItems={'center'}>
                            <Text>{tag?.value || tag?.roleName}</Text>
                            {!props?.roleIsDisable && (
                              <Pressable
                                disabled={props?.roleIsDisable}
                                onPress={() => {
                                  if (!props?.roleIsDisable) {
                                    handleRoleRemove(tag.id);
                                  }
                                }}
                              >
                                <Image
                                  style={styles.image}
                                  source={require('../../../../../assets/images/cancel.png')}
                                  alt='image'
                                />
                              </Pressable>
                            )}
                          </HStack>
                        </View>
                      );
                    })}
                </HStack>
                {showPractitionerDropdown && false && (
                  <FormControl>
                    <FormControl.Label>
                      <DisplayText
                        size={'smMedium'}
                        extraStyles={{
                          color: Colors.Custom.Gray700,
                          fontWeight: 500,
                          fontSize: 14,
                        }}
                        textLocalId="Map User to EHRs"
                      />
                    </FormControl.Label>
                    <NBSelect
                      height={44}
                      width={'100%'}
                      backgroundColor={'transparent'}
                      borderRadius={8}
                      borderColor={Colors.Custom.Gray200}
                      selectedValue={stateData.selectedExternalUser.id || ''}
                      isDisabled={stateData.isSelectedDisabled}
                      onValueChange={(value) => {
                        const selectedUser = stateData.externalUserList.find(
                          (item) => {
                            return item.id == value;
                          }
                        );
                        setStateData((prev) => {
                          return {
                            ...prev,
                            selectedExternalUser:
                              selectedUser || ({} as PractitionerData),
                          };
                        });
                      }}
                    >
                      {stateData.externalUserList.map((item) => {
                        return (
                          <NBSelect.Item
                            label={item.displayName}
                            value={item.id}
                          />
                        );
                      })}
                    </NBSelect>
                  </FormControl>
                )}
              <ModalActionSubTitle subTitle={'Locations'} />
                {stateData?.location?.length > 0 && (
                  <HStack flexWrap={'wrap'} overflow={'auto'}>
                    {stateData?.location?.map((location)=> {
                      return    (
                      <View
                      key={location.accountLocationId}
                      mt={1}
                      px={2}
                      py={1}
                      borderRadius={16}
                      bgColor={'gray.100'}
                    >
                      <HStack space={1} alignItems={'center'}>
                        <Text>{location?.name || ''}</Text>
                      </HStack>
                    </View>)
                    })}
                  </HStack>
                )}
                <ModalActionSubTitle marginTop={8} subTitle={'Contact Info'} />
                <HStack space={10}>
                  <ModalActionInput
                    fieldIs={'input'}
                    label={'phoneNumber'}
                    placeholder={'(123) 123-1234'}
                    isRequired={validationsFlags.isMobileNumberRequired}
                    isInvalid={errors?.mobileNumber}
                    value={numericStringMask(userFormData?.phone?.value || '', mask)}
                    errors={errors?.mobileNumber}
                    errorText={errors?.mobileNumber}
                    onChangeText={(value: any) => {
                      const updatedValue = value?.replace(/[()-\s]/g, '');
                      if (updatedValue?.length <= 10) {
                        setUserFormData((prev: any) => ({
                          ...prev,
                          phone: {
                            ...prev?.phone,
                            value: unmaskPhoneNumber(value),
                          },
                        }));
                      }
                    }}
                    extraStyle={{flex: 1}}
                  />
                  <ModalActionInput
                    // ref={ref_inputEmail}
                    fieldIs={'input'}
                    label={'Email'}
                    placeholder={'abc@xyz.com'}
                    isRequired={false}
                    isDisabled={true}
                    isInvalid={errors?.email}
                    value={userFormData?.email?.value}
                    errors={errors?.email}
                    errorText={errors?.email}
                    onChangeText={(value: any) => {
                      setUserFormData({
                        ...userFormData,
                        email: {
                          ...userFormData?.email,
                          value: value,
                        },
                      });
                    }}
                    extraStyle={{
                      flex: 1.0,
                    }}
                  />
                </HStack>
                <HStack space={10}>
                  <ModalActionInput
                    fieldIs={'input'}
                    label={'faxNumber'}
                    placeholder={'(123) 123-1234'}
                    isRequired={validationsFlags.isFaxNumberRequired}
                    extraStyle={{flex: 1}}
                    isInvalid={errors?.fax}
                    value={numericStringMask(userFormData?.fax?.value || '', mask)}
                    errors={errors?.fax}
                    errorText={errors?.fax}
                    onChangeText={(value: any) => {
                      const updatedValue = value?.replace(/[()-\s]/g, '');
                      if (updatedValue?.length <= 10) {
                        setUserFormData((prev: any) => ({
                          ...prev,
                          fax: {...prev?.fax, value: unmaskPhoneNumber(value)},
                        }));
                      }
                    }}
                  />
                </HStack>
                <HStack space={10}>
                  <ZipCodeAutoComplete
                    flex={1}
                    name={userFormData?.zipcode}
                    isRequired={validationsFlags.isZipCodeRequired}
                    isInvalid={errors.zipcode}
                    errors={errors}
                    errorText={errors.zipcode}
                    onAutoCompleteChange={(selectedZipCode: IZipCodeData) => {
                      setUserFormData((prev: any) => {
                        return {
                          ...prev,
                          zipcodeId: selectedZipCode?.id,
                          stateId: selectedZipCode?.stateId,
                          stateName:
                            selectedZipCode?.zipcodeState?.name ||
                            selectedZipCode?.stateName,
                          cityId: selectedZipCode?.cityId,
                          cityName:
                            selectedZipCode?.zipcodeCity?.name ||
                            selectedZipCode?.cityName,
                          abbr: selectedZipCode?.zipcodeState?.abbr || '',
                          zipcode: selectedZipCode?.code,
                        };
                      });
                    }}
                    onValueChange={(value: string) => {
                      setErrors((prev: any) => ({
                        ...prev,
                        zipcode: '',
                      }));
                      setUserFormData((prev: any) => ({
                        ...prev,
                        zipcode: value,
                      }));
                    }}
                    showAdd={true}
                  />
                  <ModalActionSelect
                    label={genderIdentityLabel}
                    isRequired={validationsFlags.isGenderAtBirthRequired}
                    selectedValue={userFormData?.genderId}
                    accessibilityLabel={genderIdentityLabel}
                    placeholder={`Select ${genderIdentityLabel}`}
                    endIcon={<CheckIcon size="5" />}
                    onValueChange={(itemValue: any) => {
                      const selectedGender = userGender.find(
                        (gender: any) => {
                          return gender.id == itemValue;
                        }
                      );
                      setUserFormData((prev: any) => ({
                        ...prev,
                        genderId: itemValue,
                        genderName: selectedGender?.code,
                      }));
                    }}
                    data={userGender}
                    selectItemProps={{
                      key: 'id',
                      label: 'value',
                      value: 'id',
                    }}
                    customStyle={{flex: 1}}
                  />
                </HStack>
                <ModalActionSubTitle
                  marginTop={8}
                  subTitle={'Additional Info'}
                />
                <HStack space={10}>
                  <ModalActionInput
                    fieldIs={'input'}
                    label={'addressLine1'}
                    placeholder={'11, Main Street'}
                    isRequired={validationsFlags.isAddressLine1Required}
                    isInvalid={errors?.line1}
                    errors={errors?.line1}
                    errorText={errors?.line1}
                    value={userFormData?.line1 || ''}
                    onChangeText={(value: any) => {
                      setUserFormData((prev: any) => ({
                        ...prev,
                        line1: value,
                      }));
                    }}
                    extraStyle={{flex: 1}}
                  />

                  <ModalActionInput
                    fieldIs={'input'}
                    label={'addressLine2'}
                    placeholder={'11, Main Street'}
                    isRequired={false}
                    value={userFormData?.line2 || ''}
                    onChangeText={(value: any) => {
                      setUserFormData((prev: any) => ({
                        ...prev,
                        line2: value,
                      }));
                    }}
                    extraStyle={{flex: 1}}
                  />
                </HStack>
                <HStack space={10}>
                  <StateAutoComplete
                    isTabDisabled
                    name={userFormData?.stateName}
                    onAutoCompleteChange={(selectedId, abbr, name) => {
                      setUserFormData((prev: any) => {
                        return {
                          ...prev,
                          stateId: selectedId,
                          stateName: name,
                        };
                      });
                    }}
                    flex={1}
                  />

                  <CityAutoComplete
                    isTabDisabled
                    flex={1}
                    name={userFormData?.cityName}
                    // name={'City name'}
                    onAutoCompleteChange={(selectedId, cityName) => {
                      setUserFormData((prev: any) => ({
                        ...prev,
                        cityId: selectedId,
                        cityName: cityName || '',
                      }));
                    }}
                  />
                </HStack>
              </VStack>
              <VStack>
                <HStack flex={1} space={1}></HStack>
                {parentCode !== PARENT_CODE.SIDE_MENU_PANEL && (
                  <ModalActionAntSelect
                    value={userFormData.locationUUid}
                    mode={'multiple'}
                    allowClear={true}
                    isRequired={false}
                    label={'practiceLocations'}
                    placeholder="Please select"
                    defaultValue={user?.userPracticeLocations?.map(
                      (item) => item?.practiceLocation?.id
                    )}
                    onChange={(value: any) => {
                      setUserFormData({
                        ...userFormData,
                        locationUUid: value,
                      });
                    }}
                    data={filterLocationObject(stateData?.practiceLocations)}
                    optionProps={{key: 'uuid', value: 'uuid', label: 'name'}}
                    extraStyle={{flex: 1}}
                    marginTop={2}
                  />
                )}

                {stateData?.isFoldEhr && isPhysicianRoleUser() && (
                  <VStack marginTop={2}>
                    <ModalActionSubTitle
                      marginTop={8}
                      subTitle={'Physician Details'}
                    />
                    <FormControl
                      style={styles.formElement}
                      isInvalid={userFormData?.npiError?.length > 0}
                    >
                      <FormControl.Label style={styles.formLabel} isRequired={validationsFlags.isNPIRequired}>
                        <DisplayText
                          textLocalId={'npiNumber'}
                          size={'smMedium'}
                          extraStyles={{color: Colors.Custom.Gray700}}
                        />
                      </FormControl.Label>
                      <InputNumber
                        placeholder="Enter NPI Number"
                        style={reactStyles.inputNumber}
                        maxLength={10}
                        value={userFormData?.NPI_NUMBER?.value}
                        type={'number'}
                        required={validationsFlags.isNPIRequired}
                        status={userFormData.npiError?.length > 0 ? 'error' : ''}
                        onChange={(npiNumber) => {
                          if (npiNumber) {
                            setUserFormData((prev: any) => ({
                              ...prev,
                              npiError: '',
                              NPI_NUMBER: {
                                ...prev?.NPI_NUMBER,
                                value: `${npiNumber}`,
                              },
                            }));
                          } else {
                            setUserFormData((prev: any) => ({
                              ...prev,
                              npiError: '',
                              NPI_NUMBER: {
                                ...prev?.NPI_NUMBER,
                                value: '',
                              },
                            }));
                          }
                        }}
                      />
                      {userFormData?.npiError?.length > 0 && (
                        <FormControl.ErrorMessage
                          _text={{
                            fontSize: 'xs',
                            color: 'error.500',
                            fontWeight: 500,
                          }}
                        >
                          {userFormData?.npiError}
                        </FormControl.ErrorMessage>
                      )}
                    </FormControl>

                    <HStack flex={1} space={1}>
                      <FormControl style={[styles.formElement]} flex={0.49}>
                        <StateAutoComplete
                          name={userFormData?.DEA_NUMBER?.stateName}
                          onAutoCompleteChange={(selectedId, name) => {
                            setUserFormData((prev: any) => {
                              return {
                                ...prev,
                                deaStateId: selectedId,
                                deaStateName: name,
                              };
                            });
                          }}
                        />
                      </FormControl>

                      <ModalActionInput
                        flex={0.49}
                        fieldIs={'input'}
                        marginTop={2}
                        label={'deaNumber'}
                        isRequired={false}
                        value={userFormData?.DEA_NUMBER?.value}
                        onChangeText={(value: any) => {
                          setUserFormData((prev: any) => {
                            return {
                              ...prev,
                              DEA_NUMBER: {...prev.DEA_NUMBER, value: value},
                            };
                          });
                        }}
                        extraStyle={{flex: 1}}
                      />
                    </HStack>
                    {isLoggedInUserEdit && (
                      <FHForm
                        readOnly={false}
                        components={signatureComponent}
                        onChange={handleFormChange}
                        optionData={signatureFormOptions}
                        // canUpdateOnChangeRef
                        submittedResponse={stateData?.signatureResponse}
                      />
                    )}
                  </VStack>
                )}
              </VStack>
            </View>
          )}
        </Content>
      </View>
    )
  }

  return (
    <>
      {!props?.preferenceView && <Drawer
        visible={stateData.showDrawer}
        style={reactStyles.drawer}
        width={drawerWidth}
        onClose={() => {
          setStateData((prev) => {
            return {
              ...prev,
              showDrawer: false,
            };
          });
          setTimeout(() => {
            onClose();
          }, 200);
        }}
        title={
          <>
            <ModalActionTitle
              title={'editUser'}
              titleColor={''}
              buttonList={[
                {
                  isDisabled: stateData.loading || stateData.isSubmitting || stateData.npiAPILoading,
                  show: true,
                  id: 1,
                  btnText: 'cancel',
                  textColor: Colors.Custom.mainSecondaryBrown,
                  variant: BUTTON_TYPE.SECONDARY,
                  isTransBtn: false,
                  onClick: () => {
                    onCancel();
                  },
                },
                {
                  isLoading: stateData?.isSubmitting,
                  isDisabled: stateData.loading || stateData.isSubmitting || stateData.npiAPILoading,
                  show: true,
                  id: 2,
                  btnText: 'submit',
                  textColor: Colors.Custom.mainPrimaryPurple,
                  variant: BUTTON_TYPE.PRIMARY,
                  isTransBtn: false,
                  onClick: () => {
                    handleSubmit();
                  },
                },
              ]}
            />
          </>
        }
      >
        {addEditUserView()}
      </Drawer>}

      {props?.preferenceView && <>
        <View>
          {addEditUserView()}
        </View>
      </>}
    </>
  );
});

export default AddEditUser;
