export const TABLE_TOP_BAR_ACTION_CODES = {
  SEARCH: 'SEARCH',
  IMPORT_DATA: 'IMPORT_DATA',
  NEW_JOURNEY: 'NEW_JOURNEY',
  ASSIGN_JOURNEY: 'ASSIGN_JOURNEY',
  NEW: 'NEW',
  EDIT_ACCOUNT: 'EDIT_ACCOUNT',
  EDIT: 'EDIT',
  DELETE: 'DELETE',
  FILTER_VIEW: 'FILTER_VIEW',
  VIEW_CARE_TEAM: 'VIEW_CARE_TEAM',
  EDIT_LOCATION: 'EDIT_LOCATION',
  BUTTON_LIST:'BUTTON_LIST',
  CLOSE:'CLOSE',
  ADD_NEW_PRACTICE: 'ADD_NEW_PRACTICE',
  ADD_NEW_USER_SCHEDULE: 'ADD_NEW_USER_SCHEDULE',
  ADD_NEW_APPOINTMENT_TYPE: 'ADD_NEW_APPOINTMENT_TYPE',
  NEW_PACKAGE: 'NEW_PACKAGE',
  PACKAGE_FILTER: 'PACKAGE_FILTER',
  CLEAR_SEARCH: 'CLEAR_SEARCH',
  NEW_LABEL: 'NEW_LABEL',
};
