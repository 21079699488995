import {Button, Text, View} from 'native-base';
import React, {useState} from 'react';
import {Colors} from '../../../../styles';
import {IDalleSuggestion} from '../EmailTemplates/CreateOrEditTemplate/CustomBlocks/OpenAiSuggestions/OpenaiSuggestionsUtils';

const ViewDalleSuggestionsList = (props: any) => {
  const {html, title, onModalClose, suggestions, index} = props;
  const [disabled, setDisabled] = useState(false);
  const [disabledR, setDisabledR] = useState(true);

  return (
    <View>
      <View
        style={{
          marginVertical: 20,
          marginBottom: 20,
        }}
      >
        <Text
          size={'mdBold'}
          color={Colors.Custom.Gray700}
          style={{marginTop: 20}}
        >
          <img src={`data:image/jpeg;base64,${html?.b64_json}`} />
        </Text>
      </View>

      {disabledR && (
        <Button
          style={{
            backgroundColor: Colors.primary['300'],
            borderRadius: 20,
          }}
          isDisabled={disabled}
          onPress={() => {
            const suggestion: IDalleSuggestion = {
              title: title,
              b64_json: html?.b64_json,
            };
            suggestions.selectedContent?.push(suggestion);
            setDisabled(true);
            setDisabledR(false);
          }}
        >
          Add to suggestions
        </Button>
      )}

      {disabled && (
        <Button
          style={{
            backgroundColor: Colors.primary['300'],
            borderRadius: 20,
          }}
          isDisabled={disabledR}
          onPress={() => {
            suggestions.selectedContent = suggestions.selectedContent?.filter(
              function (e: any) {
                return e?.b64_json !== html?.b64_json;
              }
            );

            setDisabled(false);
            setDisabledR(true);
          }}
        >
          Remove from suggestions
        </Button>
      )}
    </View>
  );
};

export default ViewDalleSuggestionsList;
