import Svg, {Path} from 'react-native-svg';
import {ICommonSvgProps} from './interfaces';
import { Colors } from '../../../styles';

const CrossIconSvg = (props: ICommonSvgProps) => {
  const size = props.size || 24;
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        d="M18 6L6 18M6 6L18 18"
        stroke={props.customStrokeColor || Colors.Custom.Gray500}
        stroke-width="1.8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </Svg>
  );
};

export default CrossIconSvg;
