import React from 'react';
import {ICommonSvgProps} from './interfaces';
import Svg, {Path} from 'react-native-svg';
import {Colors} from '../../../styles';

const MenuHorizontalIcon = (props?: ICommonSvgProps) => {
  const strokeColor = props?.customStrokeColor || Colors.Custom.GRAY350;
  return (
    <Svg
      width={props?.width || '16'}
      height={props?.height || '16'}
      viewBox="0 0 16 16"
      fill="none"
    >
      <Path
        d="M4.66667 7.9974C4.66667 8.73378 4.06971 9.33073 3.33333 9.33073C2.59695 9.33073 2 8.73378 2 7.9974C2 7.26102 2.59695 6.66406 3.33333 6.66406C4.06971 6.66406 4.66667 7.26102 4.66667 7.9974Z"
        stroke={strokeColor}
        strokeLinecap="round"
      />
      <Path
        d="M9.33333 7.9974C9.33333 8.73378 8.73638 9.33073 8 9.33073C7.26362 9.33073 6.66667 8.73378 6.66667 7.9974C6.66667 7.26102 7.26362 6.66406 8 6.66406C8.73638 6.66406 9.33333 7.26102 9.33333 7.9974Z"
        stroke={strokeColor}
        strokeLinecap="round"
      />
      <Path
        d="M14 7.9974C14 8.73378 13.403 9.33073 12.6667 9.33073C11.9303 9.33073 11.3333 8.73378 11.3333 7.9974C11.3333 7.26102 11.9303 6.66406 12.6667 6.66406C13.403 6.66406 14 7.26102 14 7.9974Z"
        stroke={strokeColor}
        strokeLinecap="round"
      />
    </Svg>
  );
};

export default React.memo(MenuHorizontalIcon);