import {Modal, ScrollView, View} from 'native-base';
import {FoldButton} from '../../CommonComponents/FoldButton/FoldButton';
import {BUTTON_TYPE} from '../../../constants';
import {COMMON_ACTION_CODES} from '../../../constants/ActionConst';
import {useIntl} from 'react-intl';
import {Button, Text, VStack} from 'native-base';
import {DisplayText} from '../DisplayText/DisplayText';
import {IPatientHieResponse} from './Interface';
import {Colors} from '../../../styles';
const {Content, Footer, Header, CloseButton, Body} = Modal;

const PatientHIEStatusDetailModal = (props: {
  isOpen: boolean;
  onActionPerfomed: (code: string, data?: any) => void;
  patientHieResponseData: IPatientHieResponse;
}) => {
  const {isOpen, patientHieResponseData} = props;
  const intl = useIntl();
  const onClose = () => {
    props.onActionPerfomed(COMMON_ACTION_CODES.CLOSE);
  };
  return (
    <Modal isOpen={isOpen} onClose={() => onClose()} size={'xl'}>
      <Content  maxHeight={"70%"} minHeight={"30%"}>
        <Header paddingLeft={6}>
          <DisplayText
            textLocalId = 'syncErrorDetails'
            extraStyles={{fontSize: 18, fontWeight: 600}}
          ></DisplayText>
          <DisplayText
            textLocalId="patientHieVerificationMessage"
            extraStyles={{color: Colors.Custom.Gray500}}
          ></DisplayText>
        </Header>
        {patientHieResponseData.errors?.length ? (
          <ScrollView>
            <VStack space={4} padding={5}>
              {patientHieResponseData.errors.map((errorItem) => {
                return (
                  <View key={errorItem.key}>
                    <View
                      background={Colors.Custom.patientHieDetailsBgColor}
                      borderRadius={10}
                      padding={3}
                    >
                      <Text color={Colors.Custom.Gray600}>
                        {errorItem.details}
                      </Text>
                    </View>
                    <Text
                      color={Colors.Custom.Gray500}
                      marginLeft={2.5}
                      marginTop={2}
                    >
                      Suggestion
                    </Text>
                    <Text color={Colors.Custom.Gray400} marginLeft={2.5}>
                      {errorItem.suggestion}
                    </Text>
                  </View>
                );
              })}
            </VStack>
          </ScrollView>
        ) : (
          <></>
        )}
        <Button.Group style={{marginTop: 12}} justifyContent={'flex-end'}>
          <FoldButton
            nativeProps={{
              variant: BUTTON_TYPE.PRIMARY,
              style: {margin: 12, width: 100},
              onPress: () => {
                onClose();
              },
            }}
            customProps={{
              btnText: intl.formatMessage({id: 'done'}),
              withRightBorder: false,
            }}
          ></FoldButton>
        </Button.Group>
      </Content>
    </Modal>
  );
};

export default PatientHIEStatusDetailModal;
