import {HStack, Spinner, View, VStack} from 'native-base';
import React, {useCallback} from 'react';
import {Colors} from '../../../../styles';
import {IBookingWidgetData} from '../AppointmentBookingWidget';
import BookingDataMsg from './BookingDataMsg';
import {Calendar, Radio, Skeleton} from 'antd';
import {
  getDateStrFromFormat,
  getDateStrFromMomentObj,
  getFirstDateByMonthAndYear,
  getMomentObj,
  isDateBetweenRange,
  isDisabledMonth,
  isPastDateTime,
  isSameMonth,
} from '../../../../utils/DateUtils';
import {DATE_FORMATS} from '../../../../constants';
import {showToast, ToastType} from '../../../../utils/commonViewUtils';
import {isFutureDateAllowed} from '../../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentBookingHelper';
import {getBookingErrorTextMessage} from '../../../common/CalendarWidget/BookingWorkflows/BookAppointment/BookAppointmentHelper';
import {Moment} from '@react-awesome-query-builder/antd';
import {Content} from 'antd/lib/layout/layout';
import {ModalActionAntSelect} from '../../../common/ModalActionCommonComponent/ModalActionAntSelect';
import {StyleSheet} from 'react-native';

const WidgetCalendarView = (props: {
  bookingData: IBookingWidgetData;
  loading: boolean;
  onChange: (date: Moment) => void;
  slotRange: any;
  getSlotDate: () => {
    slotStartDate: string;
    slotEndDate: string;
  };
}) => {
  const {bookingData, loading, slotRange, onChange, getSlotDate} = props;
  const isDisabledDate = (date: any) => {
    if (getSlotDate()?.slotStartDate && getSlotDate()?.slotEndDate) {
      const isDisabled = !isDateBetweenRange(date, getSlotDate().slotStartDate, getSlotDate().slotEndDate);
      return isDisabled;
    } else {
      return getMomentObj(date).isBefore(new Date(), 'day');
    }
  };

  const isSelectedDate = (date: any) => {
    if (
      date?.format(DATE_FORMATS.DISPLAY_DATE_FORMAT) ===
      getDateStrFromFormat(
        bookingData?.selectedDate,
        DATE_FORMATS.DISPLAY_DATE_FORMAT
      )
    ) {
      return true;
    }
    return false;
  };

  const getAdditionalStyle = useCallback(
    (isDisabled: boolean, isSelected: boolean) => {
      if (isDisabled) {
        return {
          borderColor: Colors.Custom.Gray100,
          fontSize: 12,
          color: Colors.FoldPixel.GRAY200,
        };
      } else {
        return {
          borderColor: Colors.primary[100],
          fontSize: 14,
          backgroundColor: isSelected ? Colors.primary[300] : '',
          color: isSelected ? Colors.Custom.FontColorWhite : '',
        };
      }
    },
    []
  );

  const dateFullCellRender = (date: any) => {
    const renderDate = date?.format(DATE_FORMATS.DISPLAY_DATE_FORMAT);
    const isDisabled = isDisabledDate(date);
    const isSelected = isSelectedDate(date);
    const isSameMonthDate = isSameMonth(
      getMomentObj(bookingData?.selectedDate)?.toISOString(),
      date.toISOString()
    );

    if (!isSameMonthDate) {
      return null;
    }

    return (
      <Content
        className="slot-day"
        key={date?.date()}
        style={getAdditionalStyle(isDisabled || !isSameMonthDate, isSelected)}
      >
        <VStack flex={1} space={2}>
          <VStack>{isSameMonthDate && date?.date()}</VStack>
        </VStack>
      </Content>
    );
  };

  const headerRender = (props: any) => {
    const start = 0;
    const end = 12;
    const monthOptions = [];

    const current = props.value.clone();
    const localeData = props.value.localeData();
    const months = [];
    for (let i = 0; i < 12; i++) {
      current.month(i);
      months.push(localeData.monthsShort(current));
    }

    for (let index = start; index < end; index++) {
      monthOptions.push({
        key: index,
        value: index,
        label: months[index],
      });
    }
    const month = props.value.month();

    const year = props.value.year();
    const options = [];
    for (let i = year - 10; i < year + 10; i += 1) {
      options.push({
        key: i,
        value: i,
        label: i,
      });
    }
    return (
      <div style={reactStyles.padding8}>
        <HStack space={4} alignItems="center">
          <View>
            <ModalActionAntSelect
              className="custom-select-box"
              customStyle={{
                height: 32,
                width: '75px',
              }}
              size="small"
              data={options}
              value={year}
              onChange={(newYear: string) => {
                const now = props.value.clone().year(parseInt(newYear));
                props.onChange(now);
              }}
              optionProps={{
                key: 'value',
                label: 'label',
                value: 'value',
              }}
            />
          </View>
          <View>
            <ModalActionAntSelect
              className="custom-select-box"
              customStyle={{
                height: 32,
                width: '75px',
              }}
              size="small"
              data={monthOptions}
              value={month}
              optionProps={{
                key: 'value',
                label: 'label',
                value: 'value',
              }}
              onChange={(selectedMonth: string) => {
                const newValue = props.value.clone();
                newValue.month(parseInt(selectedMonth, 10));
                const date = newValue.format(
                  DATE_FORMATS.DISPLAY_DATE_FORMAT
                );
                let startDate = getMomentObj(
                  date
                ).startOf('month');
                const sameMonth = isSameMonth(date)
                if (sameMonth) {
                  startDate = getMomentObj(
                    new Date()
                  );
                }
                props.onChange(startDate);
              }}
            />
          </View>
          <Radio.Group
            onChange={(e) => props.onTypeChange(e.target.value)}
            value={props.type}
            style={reactStyles.radioGroupStyle}
          >
            <Radio.Button value="month">Month</Radio.Button>
            <Radio.Button value="year">Year</Radio.Button>
          </Radio.Group>
        </HStack>
      </div>
    );
  };

  return (
    <View
      flex={['none', 'none', 1]}
      alignItems={'center'}
      borderRightWidth={[0, 0, 1]}
      borderRightColor={Colors.Custom.BorderColor}
    >
      {loading && (
        <View minHeight={400} w="100%" padding={2}>
          <Skeleton active />
        </View>
      )}
      {!loading && (
        <VStack paddingY={2} alignItems={'center'} justifyContent={'center'}>
          {bookingData?.appointmentType?.bookWithinDays && (
            <BookingDataMsg bookingData={bookingData} />
          )}
          <Calendar
            style={reactStyles.calendarStyle}
            fullscreen={false}
            value={getMomentObj(bookingData.selectedDate)}
            disabledDate={(current: any) => {
              return current && isDisabledMonth(current);
            }}
            onChange={onChange}
            dateFullCellRender={dateFullCellRender}
            headerRender={headerRender}
          />
        </VStack>
      )}
    </View>
  );
};

export default WidgetCalendarView;

const styles = StyleSheet.create({
  
});

const reactStyles: Record<string, React.CSSProperties> = {
  padding8: {
    padding: 8
  },
  radioGroupStyle: {
    borderRadius: 4,
    height: 32,
  },
  calendarStyle: {
    width: '350px',
    backgroundColor: 'transparent',
  }
};
