import {COMMUNICATION_APP_BASE_URL} from '../../../constants/BaseUrlConst';
import {CARE_STUDIO_NEST_URL, HASURA_SECRET} from '../../../constants/Configs';
import {ILoginUserData} from '../../../Interfaces';
import BaseService from '../../../services/CommonService/BaseService';
import {getDateToMomentISOString} from '../../../utils/DateUtils';
import LocalStorage from '../../../utils/LocalStorage';
import {IHostInfo, IMeeting, IUserList} from '../MeetingView/interface';
import {IMeetingInvitees, IMemberData, IZoomAuthToken} from './interfaces';

//once the code getting from redirect url. Save the following api response into common data.
export const generateZoomAuthToken = (code: string) => {
  const axios = BaseService.getSharedInstance().axios;
  return axios.post(
    'crm-communication/meet/oauth/codeToToken',
    JSON.stringify({redirectUrl: 'https://demo.dev.foldhealth.io'}),
    {
      headers: {
        code: code,
      },
    }
  );
};

export const refreshZoomAuthToken = (refresh_token: string) => {
  const axios = BaseService.getSharedInstance().axios;
  return axios.post(
    'crm-communication/meet/oauth/refreshAccessToken',
    JSON.stringify({redirectUrl: 'https://demo.dev.foldhealth.io'}),
    {
      headers: {
        refreshToken: refresh_token,
      },
    }
  );
};

export const setZoomAuthToken = (zoomAuthToken: IZoomAuthToken) => {
  return LocalStorage.setItem('zoom', JSON.stringify(zoomAuthToken));
};

export const instantZoomCall = async (
  accountId: string | number,
  accountUUID: string,
  accountName: string,
  meeting: IMeeting,
  duration: number,
  invitees: IMemberData[],
  hostInfo: IHostInfo,
  startTime?: string
) => {
  // const zoom = await LocalStorage.getItem('zoom');
  // const zoomObject = JSON.parse(zoom || '{}');

  // if (zoomObject.refresh_token) {
  //   const response = await refreshZoomAuthToken(zoomObject.refresh_token);
  //   setZoomAuthToken(response.data);
  // } else if (code) {
  //   const response = await generateZoomAuthToken(code);
  //   if (response.data) {
  //     setZoomAuthToken(response.data);
  //   } else {
  //   }
  // }
  //create meeting
  const scheduleMeetRes = await scheduleMeet(
    accountId,
    accountUUID,
    accountName,
    meeting,
    duration,
    invitees,
    hostInfo,
    startTime
  );
  return scheduleMeetRes;
};

//
export const scheduleMeet = (
  accountId: string | number,
  accountUUID: string,
  accountName: string,
  meeting: IMeeting,
  duration: number,
  invitees: IMemberData[],
  hostInfo: IHostInfo,
  startTime?: string
) => {
  const randomPassword = Math.floor(100000 + Math.random() * 900000);
  const meetingInvitees: IMeetingInvitees[] = [];
  invitees?.forEach((invitee: IMemberData) => {
    const temp: IMeetingInvitees = {
      email: invitee.email,
      uuid: invitee.contactUUID,
      type: 'CONTACT',
      firstName: invitee?.firstName,
      lastName: invitee?.lastName
    };
    meetingInvitees.push(temp);
  });
  meeting?.inviteeList?.forEach((invitee: IUserList) => {
    const temp: IMeetingInvitees = {
      email: invitee.user.email,
      uuid: invitee.user.uuid,
      type: 'USER',
      firstName: invitee?.name || '',
      lastName: ''
    };
    meetingInvitees.push(temp);
  });
  const [firstName, lastName]: any = hostInfo?.name?.split(' ');
  const body = {
    accountUuid: accountUUID,
    accountId: accountId,
    templateCategoryCode: 'GENERAL',
    sendMail: meeting.sendEmail || false,
    sendEmailToPatient : meeting.sendEmail || false,
    sendSmsToPatient: meeting.sendEmail || false,
    sendNotificationToPatient: meeting.sendEmail || false,
    accountName: accountName,
    meetingData: {
      sessionKey: 'session123',
      scheduleFor: 'bhaveshp@fold.health',
      userUuid: hostInfo?.uuid,
      tenantName: accountName,
      isRecurring: false,
      agenda: meeting.meetingName || 'Instant Meet',
      duration: duration ? duration : 30,
      password: randomPassword,
      meetingInvitees: meetingInvitees,
      isPrivate: true,
      startTime: startTime ? startTime : getDateToMomentISOString(), // '2022-05-09T201:32:55Z'
      templateId: 'Dv4YdINdTk+Z5RToadh5ug==',
      timezone: 'America/Los_Angeles',
      topic: meeting.meetingName || 'Instant Meet',
      meetingType: 2,
      hostInfo: {
        email: hostInfo?.email,
        first_name: firstName,
        last_name: lastName,
        password: randomPassword,
        userUUID: hostInfo?.uuid,
        hostType: 'USER',
      },
      recurrence: {
        end_date_time: '2022-05-18T15:59:00Z',
        end_times: 7,
        monthly_day: 1,
        monthly_week: 1,
        monthly_week_day: 1,
        repeat_interval: 1,
        type: 1,
        weekly_days: '1',
      },
    },
  };
  const axios = BaseService.getSharedInstance().axios;
  return axios.post('/crm-communication/meet/schedule', JSON.stringify(body));
};
export const scheduleHMSMeet = (
  accountId: string | number,
  accountUUID: string,
  accountName: string,
  meeting: IMeeting,
  invitees: IMemberData[],
  hostInfo: IHostInfo,
  isZoomEnabled?: boolean
) => {
  const randomPassword = Math.floor(100000 + Math.random() * 900000);
  const meetingInvitees: IMeetingInvitees[] = [];
  invitees?.forEach((invitee: IMemberData) => {
    const temp: IMeetingInvitees = {
      email: invitee.email,
      phoneNumber: invitee.phoneObj?.value || invitee?.phone,
      uuid: invitee.contactUUID,
      type: invitee?.type || 'CONTACT',
      firstName: invitee?.firstName,
      lastName: invitee?.lastName
    };
    meetingInvitees.push(temp);
  });
  meeting?.inviteeList?.forEach((invitee: IUserList) => {
    const temp: IMeetingInvitees = {
      email: invitee.user.email,
      uuid: invitee.user.uuid,
      type: 'USER',
      firstName: invitee?.name || '',
      lastName: ''
    };
    meetingInvitees.push(temp);
  });
  const [firstName, lastName]: any = hostInfo?.name?.split(' ');
  const body = {
    accountUuid: accountUUID,
    accountId: accountId,
    templateCategoryCode: 'GENERAL',
    sendMail: meeting.sendEmail || false,
    sendEmailToPatient: meeting.sendEmailToPatient || false,
    sendSmsToPatient: meeting?.sendSmsToPatient || false,
    sendNotificationToPatient: meeting?.sendNotificationToPatient || false,
    accountName: accountName,
    meetingData: {
      meetingInvitees: meetingInvitees,
      hostInfo: {
        email: hostInfo?.email,
        first_name: firstName,
        last_name: lastName,
        password: randomPassword,
        userUUID: hostInfo?.uuid,
        hostType: 'USER',
        subtitle: hostInfo?.subtitle || ''
      },
    },
  };
  const axios = BaseService.getSharedInstance().axios;
  const url = isZoomEnabled
    ? '/crm-communication/meeting/instant'
    : '/crm-communication/meetms/meeting';
  return axios.post(url, JSON.stringify(body));
};

export const startInstantMeet = async (
  meetingObject: IMeeting,
  accountId: string | number,
  accountUUID: string,
  accountName: string,
  duration: number,
  invitees: IMemberData[],
  hostInfo: IHostInfo,
  setLoading: (value: boolean) => void,
  startTime?: string
) => {
  // const code = 'xAq5zR7yA4_L7-sPamhRjmoTrsd8f_80Q';
  const response = await instantZoomCall(
    accountId,
    accountUUID,
    accountName,
    meetingObject,
    duration,
    invitees,
    hostInfo,
    startTime
  );
  const meeting = response.data;
  const meetingId: string | number = meeting.data.id;
  const hostEmail: string = meeting.data.host_email;
  const signature: string = meeting.signature;
  const startUrl: string = meeting.data.start_url;
  const password: string = meeting.data.password;
  if (meetingId && hostEmail && signature && startUrl) {
    document.getElementById('zmmtg-root')!.style.display = 'block';

    // const zak = meeting.zakToken.token;

    setLoading(false);
    // ZoomMtg.join({
    //   sdkKey: ZOOM_SDK_KEY,
    //   meetingNumber: meetingId,
    //   userName: hostEmail,
    //   passWord: password,
    //   signature: signature,
    //   // zak: zak || '',
    //   success: (success: boolean) => {
    //   },
    //   error: (error: any) => {
    //
    //     document.getElementById('zmmtg-root')!.style.display = 'none';
    //   },
    // });
  } else {
    setLoading(false);
  }
};

// export const joinMeeting = (joinMeeting: IJoinMeetingObj) => {
//   ZoomMtg.join({
//     sdkKey: joinMeeting.sdkKey,
//     meetingNumber: joinMeeting.meetingId,
//     userName: joinMeeting.hostEmail,
//     passWord: joinMeeting.password,
//     signature: joinMeeting.signature,
//     // zak: zak || '',
//     success: (success: boolean) => {
//     },
//     error: (error: any) => {
//
//       document.getElementById('zmmtg-root')!.style.display = 'none';
//     },
//   });
// }

// export const handleOnCallAction = (
//   meetingObject: IMeeting,
//   currentUrl: string,
//   accountId: string | number,
//   accountUUID: string,
//   accountName: string,
//   duration: number,
//   invitees: IMemberData[],
//   hostInfo: IHostInfo,
//   setLoading: (value: boolean) => void,
//   setupZoomListeners: () => void,
//   startTime?: string
// ) => {
//   setLoading(true);
//   ZoomMtg.init({
//     leaveUrl: currentUrl,
//     success: (success: boolean) => {
//       if (success) {
//         setupZoomListeners();
//         startInstantMeet(
//           meetingObject,
//           accountId,
//           accountUUID,
//           accountName,
//           duration,
//           invitees,
//           hostInfo,
//           (value: boolean) => {
//             setLoading(value);
//           },
//           startTime
//         );
//       }
//     },
//     error: (error: any) => {
//
//       setLoading(false);
//     },
//   });
// };
export const handleOnCallAction = (
  meetingObject: IMeeting,
  currentUrl: string,
  accountId: string | number,
  accountUUID: string,
  accountName: string,
  duration: number,
  invitees: IMemberData[],
  hostInfo: ILoginUserData | undefined,
  setLoading: (value: boolean) => void,
  setupZoomListeners: () => void,
  startTime?: string
) => {
  setLoading(true);
  // ZoomMtg.init({
  //   leaveUrl: currentUrl,
  //   success: (success: boolean) => {
  //     if (success) {
  //       setupZoomListeners();
  //       startInstantMeet(
  //         meetingObject,
  //         accountId,
  //         accountUUID,
  //         accountName,
  //         duration,
  //         invitees,
  //         hostInfo,
  //         (value: boolean) => {
  //           setLoading(value);
  //         },
  //         startTime
  //       );
  //     }
  //   },
  //   error: (error: any) => {
  //
  //     setLoading(false);
  //   },
  // });
};

export const getMeetingDetails = (
  meetingId: string,
  successCallback: (response: any) => void,
  errorCallback?: () => void
) => {
  const axios = BaseService.getSharedInstance().axios;
  axios
    .get(`/crm-communication/meet/meeting/${meetingId}`)
    .then((response) => {
      if (response && response.data) {
        successCallback(response.data);
      } else if (errorCallback) {
        errorCallback();
      }
    })
    .catch((error: any) => {
      if (errorCallback) {
        errorCallback();
      }
    });
};

export const joinZoomMeeting = (
  meetingId: number,
  joineeEmail: string,
  appointmentId: string,
  accountUUID: string,
  userUuid: string,
  name: string,
  isZoomEnabled?: boolean
) => {
  const joinMeetingObj = {
    meetingId,
    userUuid: userUuid,
    joineeDetails: {
      email: joineeEmail,
      userUuid: userUuid,
    },
    name: name,
    appointmentDetails: {
      userId: userUuid,
      appointmentId: appointmentId,
      startMeetingHook: `${CARE_STUDIO_NEST_URL}/event/appointment/${appointmentId}/communication`,
      endMeetingHook: `${CARE_STUDIO_NEST_URL}/event/appointment/${appointmentId}/communication`,
      accountUUID,
      secret: HASURA_SECRET,
    },
  };
  const meetingDetails = JSON.stringify(joinMeetingObj);
  const token = window.btoa(meetingDetails);
  if (isZoomEnabled) {
    window.open(`${COMMUNICATION_APP_BASE_URL()}/#/zoom?token=${token}&type=MEETING`);
    return;
  }
  // window.open(`http://localhost:3000?token=${encoded}`, '_blank');
  window.open(`${COMMUNICATION_APP_BASE_URL()}?token=${token}`, '_blank');
};
