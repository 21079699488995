import { TimePicker, Select as AntSelect } from 'antd';
import { HStack, Icon, IconButton, Stack, Text, View, VStack } from 'native-base';
import { useContext, useEffect, useState } from 'react';
import { DATE_FORMATS } from '../../../../constants';
import { ITimezone } from '../../../../services/Location/interfaces';
import { getMomentObjectWithDateStringAndFormat } from '../../../../utils/DateUtils';
import { TimezoneSelect } from '../../../common/TimezoneSelect/TimezoneSelect';
import { WeekDaySelector } from '../../../common/WeekSelector';
import { IAccountLocation } from '../../Contacts/TeamMembers/interfaces';
import { VIRTUAL_LOCATION_CODE } from './PracticeAvailability';
import { IAddOrUpdateAvailability } from './PracticeAvailabilityInterfaces';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { Colors } from '../../../../styles';
import moment, { Moment } from 'moment';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import { isVirtualLocationDisabled, isVirtualLocationEnabledInAvailability } from '../../../../utils/commonUtils';
import { ModalActionTimePicker } from '../../../common/ModalActionCommonComponent/ModalActionTimePicker';


interface IProps {
  availability: IAddOrUpdateAvailability;
  timezones: ITimezone[];
  accountLocations: IAccountLocation[];
  defaultTimezone?: ITimezone;
  disabled?: boolean;
  onWeekDaysChange?: (availability: IAddOrUpdateAvailability, days: number[]) => void;
  onTimeChange?: (availability: IAddOrUpdateAvailability, startTime: string, endTime: string) => void;
  onLocationChange: (availability: IAddOrUpdateAvailability, locationId: string) => void;
  onTimezoneChange?: (availability: IAddOrUpdateAvailability, timezoneId?: string) => void;
  onDelete?: (availability: IAddOrUpdateAvailability) => void;

  showErrors?: boolean;
  isInboxHours?: boolean;
  hideWeekDaySelector?: boolean;
  hideTimezoneSelector?: boolean;
}

interface IComponentState {
  selectedDaysOfWeek: number[];
  selectedStartAndEndTime: Moment[];
  selectedLocationId: string;
  selectedTimezoneId?: string;
}

export function AddOrUpdateAvailability(props: IProps) {
  const selectedDaysOfWeek: number[] =
    props.availability && props.availability.daysOfWeek
    ? JSON.parse(props.availability.daysOfWeek)
    : [];

  const selectedLocationId = props.availability.isVirtualLocation
    ? VIRTUAL_LOCATION_CODE
    : props.availability?.locationId || '';


  const selectedTimezoneId = props.availability.timezoneId;

  const [ componentState, setComponentState ] = useState<IComponentState>({
    selectedDaysOfWeek: selectedDaysOfWeek,
    selectedLocationId: selectedLocationId,
    selectedStartAndEndTime: getTimePickerValue(),
    selectedTimezoneId: selectedTimezoneId,
  });

  const commonData = useContext(CommonDataContext);
  const ehrCapabilities = commonData.ehrCapabilities || [];
  const userSettings = commonData.userSettings;
  const disAllowVirtualLocation = isVirtualLocationEnabledInAvailability(userSettings) ? false : isVirtualLocationDisabled(userSettings, ehrCapabilities);
  const practiceLocationsOptions: any = [];

  props.accountLocations.forEach((location) => {
    if (location) {
      practiceLocationsOptions.push({
        label: location?.practiceLocation?.name,
        value: location?.practiceLocation?.name,
        key: location?.uuid,
      });
    }
  });

  if (!disAllowVirtualLocation) {
    practiceLocationsOptions.unshift({
      label: 'Virtual appointment',
      value: VIRTUAL_LOCATION_CODE,
      key: VIRTUAL_LOCATION_CODE,
    });
  }

  function getTimePickerValue() {
    if (
      props.availability &&
      props.availability.startTime &&
      props.availability.endTime
    ) {
      return [
        formatTime(props.availability.startTime),
        formatTime(props.availability.endTime),
      ];
    }
    return [];
  }

  function formatTime(time: string) {
    return getMomentObjectWithDateStringAndFormat(time, DATE_FORMATS.DISPLAY_TIME_FORMAT);
  }

  useEffect(() => {
    let selectedTimezoneIdProp = props.availability.timezoneId;
    if (!selectedTimezoneIdProp && props.defaultTimezone?.uuid) {
      selectedTimezoneIdProp = props.defaultTimezone.uuid;
    }
    if (selectedTimezoneIdProp && componentState.selectedTimezoneId !== selectedTimezoneIdProp) {
      setComponentState((prev) => ({ ...prev,  selectedTimezoneId: selectedTimezoneIdProp }));
      props?.onTimezoneChange?.(props.availability, selectedTimezoneIdProp);
    }
  }, []);

  return (
    <Stack
      direction={['column', 'column', 'row']}
      space={4}
      alignItems={'flex-start'}
      flex={10}
    >
      {
        !props.hideWeekDaySelector &&
        <View flex={3}>
          <VStack>
            <WeekDaySelector
              values={componentState.selectedDaysOfWeek}
              onChange={(selectedDays) => {
                if (props.onWeekDaysChange) {
                  props.onWeekDaysChange(props.availability, selectedDays);
                  setComponentState((prev) => ({ ...prev, selectedDaysOfWeek: selectedDays }));
                }
              }}
            />
            {props.showErrors && !selectedDaysOfWeek?.length && (
              <View>
                <Text fontSize="xs" color="error.500">
                  Please select available days
                </Text>
              </View>
            )}
          </VStack>
        </View>
      }
      <View flex={3}>
        <VStack>
         <HStack>
            <ModalActionTimePicker
              value={componentState.selectedStartAndEndTime[0]}
              format={DATE_FORMATS.DISPLAY_TIME_FORMAT}
              customStyle={{height: 40, width: 114, marginRight: 8}}
              minuteStep={5}
              isHideOkButton={true}
              onSelect={(value: Moment) => {
                const startTime = value && moment(value).format(DATE_FORMATS.DISPLAY_TIME_FORMAT);
                setComponentState((prev) => ({
                  ...prev,
                  selectedStartAndEndTime: [
                    value,
                    prev.selectedStartAndEndTime[1],
                  ],
                }));
                if (props.onTimeChange) {
                  props.onTimeChange(props.availability, startTime, '');
                }
              }}
            />
            <ModalActionTimePicker
              customStyle={{height: 40, width: 114}}
              value={componentState.selectedStartAndEndTime[1]}
              format={DATE_FORMATS.DISPLAY_TIME_FORMAT}
              isHideOkButton={true}
              minuteStep={5}
              onSelect={(value: Moment) => {
                const endTime = value && moment(value).format(DATE_FORMATS.DISPLAY_TIME_FORMAT);
                setComponentState((prev) => ({
                  ...prev,
                  selectedStartAndEndTime: [
                    prev.selectedStartAndEndTime[0],
                    value,
                  ],
                }));
                if (props.onTimeChange && endTime) {
                  props.onTimeChange(props.availability, '', endTime);
                }
              }}
            />
          </HStack>
          {props.showErrors && !componentState.selectedStartAndEndTime[0] && componentState.selectedStartAndEndTime[1] && (
            <View>
              <Text fontSize="xs" color="error.500">
                Please select start time
              </Text>
            </View>
          )}
          {props.showErrors && componentState.selectedStartAndEndTime[0] && !componentState.selectedStartAndEndTime[1] && (
            <View>
              <Text fontSize="xs" color="error.500">
                Please select end time
              </Text>
            </View>
          )}
          {props.showErrors && !componentState.selectedStartAndEndTime[0] && !componentState.selectedStartAndEndTime[1] && (
            <View>
              <Text fontSize="xs" color="error.500">
                Please select start and end time
              </Text>
            </View>
          )}
        </VStack>
      </View>

      {!props.isInboxHours && (
        <View flex={3}>
          <VStack>
            <AntSelect
              size="large"
              showSearch={true}
              disabled ={props?.disabled ? true : false}
              style={{
                width: '100%',
                height: '40px',
              }}
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              value={componentState.selectedLocationId || undefined}
              onChange={(locationId, data: any) => {
                let timezoneId = props.availability?.timezoneId;
                if (locationId !== VIRTUAL_LOCATION_CODE) {
                  const location = props.accountLocations?.find((location) => {
                    return location?.uuid && location?.uuid === locationId;
                  });

                  if (location?.practiceLocation?.timezone?.uuid) {
                    timezoneId = location.practiceLocation.timezone.uuid;
                  }
                }
                if (selectedTimezoneId !== timezoneId) {
                  props?.onTimezoneChange?.(props.availability, timezoneId);
                }
                props.onLocationChange(props.availability, locationId);

                setComponentState((prev) => ({ ...prev, selectedTimezoneId: timezoneId, selectedLocationId: locationId }));
              }}
              placeholder="Select Location"
            >
              {practiceLocationsOptions.map((location: any, index: number) => {
                return (
                  <AntSelect.Option
                    label={location.label}
                    value={location.key}
                    key={location.key || index}
                  >
                    {location.label}
                  </AntSelect.Option>
                );
              })}
            </AntSelect>
            {props.showErrors && !selectedLocationId && (
              <View>
                <Text fontSize="xs" color="error.500">
                  Please select location
                </Text>
              </View>
            )}
          </VStack>
        </View>
      )}

      {!props.hideTimezoneSelector && (
        <View flex={2}>
          <TimezoneSelect
            showLabel={false}
            showErrors={false}
            isDisabled={true}
            selectedTimezoneId={selectedTimezoneId}
            memorizeTimezone={true}
            onChange={(value?: ITimezone) => {
              // return props.onTimezoneChange(props.availability, value?.uuid);
            }}
          />
        </View>
      )}

      <View flex={1}>
        <IconButton
          colorScheme="gray"
          color={Colors.Custom.Gray500}
          variant="ghost"
          onPress={() => {
            if (props.onDelete) {
              props.onDelete(props.availability);
            }
          }}
          icon={
            <Icon
              as={AntIcon}
              name="closecircle"
              size="4"
              color={Colors.Custom.Gray400}
            />
          }
        />
      </View>
    </Stack>
  );
}
