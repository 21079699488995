import React, { useState } from 'react';
import {Checkbox, Divider, Drawer, Skeleton} from 'antd';
import {
  FlatList,
  HStack,
  Text,
  VStack,
  View,
  Image,
  useToast,
  Center,
} from 'native-base';
import {ActivityIndicator, Dimensions, SafeAreaView} from 'react-native';
import {
  DATE_FORMATS,
  SMALL_WINDOW_1700,
} from '../../../../../constants';
import {IBillableActivityEntities} from '../../BillingHooks/BillingHookInterfaces';
import {getDateStrFromFormat} from '../../../../../utils/DateUtils';
import {Colors} from '../../../../../styles/Colors';
import {COMPONENT_TEST_IDENTIFIER} from '../../../../../testUtils/ComponentTestIdentifiers';
import {testID} from '../../../../../testUtils/Utils';
import useGetBatchedAccountUsers, {
  IBatchedAccountUsersInterface,
} from '../../../../CustomHooks/useGetBatchedAccountUsers';
import UseActivityOutreach from '../../BillingHooks/UseActivityOutreach';
import {useIntl} from 'react-intl';
import ThunderSvg from '../../../../common/Svg/ThunderSvg';
import Stack from '../../../../common/LayoutComponents/Stack';
import {CarePlanTaskType, RESOURCE_ENTITIES} from '../../CareManagementConstants';
import PerformerSvg from '../../../../common/Svg/PerformerSvg';
import {ToastType, showToast} from '../../../../../utils/commonViewUtils';
import {
  GET_ALL_USERS_BY_IDS,
  GET_ALL_USER_COUNT,
} from '../../../../../services/User/UserQueries';
import {IMlov} from '../../../../../Interfaces/CommonInterfaces';
import {FilterWrapperComponent} from '../../../../common/CareDashboard/CareDashboardTopBar/FilterWrapperComponent';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import {AUDIT_ACTION_CODES} from '../../../../common/Audit/AuditHelper';
import ClockIconForAudit from '../../../../common/Svg/ClockIconForAudit';
import SchedulingSpecialistSvg from '../../../../common/Svg/CCMTaskTypes/SchedulingSpecialistSvg';
import AssessmentEstablishedSvg from '../../../../common/Svg/CCMTaskTypes/AssessmentEstablishedSvg';
import CarePlanEstablishedSvg from '../../../../common/Svg/CCMTaskTypes/CarePlanEstablishedSvg';
import CallingPatientSvg from '../../../../common/Svg/CCMTaskTypes/CallingPatientSvg';
import AssistanceAccessingTestsSvg from '../../../../common/Svg/CCMTaskTypes/AssistanceAccessingTestsSvg';
import CareManagementActivitySvg from '../../../../common/Svg/CCMTaskTypes/CareManagementActivitySvg';
import RecordingPatientHealthSvg from '../../../../common/Svg/CCMTaskTypes/RecordingPatientHealthSvg';

interface IProviderActivityAndOutreach {
  selectedBillableActivityId?: string;
  accountUserList?: IBatchedAccountUsersInterface[];
  contactCareProgramId?: string;
  showActivityFilter?: boolean;
  isTimeTrackingView?: boolean;
  isBilledView?: boolean;
}

interface IProviderActivityAndOutreachState {
  selectedResourceTypes?: string[];
}

export const ProviderActivityAndOutreachComponent = (
  props: IProviderActivityAndOutreach
) => {
  const {
    selectedBillableActivityId,
    accountUserList,
    contactCareProgramId,
    showActivityFilter,
    isTimeTrackingView,
    isBilledView,
  } = props;
  const toast = useToast();
  const screenDimensions = Dimensions.get('window');
  const smallWindow = screenDimensions?.width < SMALL_WINDOW_1700;
  const intl = useIntl();
  const [
    providerActivityAndOutreachState,
    setProviderActivityAndOutreachState,
  ] = useState<IProviderActivityAndOutreachState>();

  const {
    useActivityOutreachState,
    mappingUuidWithData,
    careProgramTaskTypeList,
    handleOutreachActions,
    showMoreData,
    loaders,
  } = UseActivityOutreach({
    contactCareProgramId: contactCareProgramId || selectedBillableActivityId,
    isTimeTrackingView,
    isBilledView
  });

  const {
    loading: accountUserLoading,
    error: accountUsersError,
    userList: fetchedAccountUserList,
  } = useGetBatchedAccountUsers({
    onError: () => {
      showToast(
        toast,
        'Error in fetching account users',
        ToastType.error,
        4000
      );
    },
    doNotFetchOnMount: accountUserList && accountUserList?.length > 0,
    usersCountQueryName: GET_ALL_USER_COUNT,
    usersQueryName: GET_ALL_USERS_BY_IDS,
  });

  const getIconName = (resourceType: string) => {
    switch (resourceType) {
      case RESOURCE_ENTITIES.appointment_scheduled:
        return <SchedulingSpecialistSvg />;
      case RESOURCE_ENTITIES.assessment_activity:
        return <AssessmentEstablishedSvg />;
      case RESOURCE_ENTITIES.access_assistance:
        return <AssistanceAccessingTestsSvg />;
      case RESOURCE_ENTITIES.care_management_activity:
        return <CareManagementActivitySvg />;
      case RESOURCE_ENTITIES.care_plan_activity:
        return <CarePlanEstablishedSvg />;
      case RESOURCE_ENTITIES.health_records:
        return <RecordingPatientHealthSvg />;
      case RESOURCE_ENTITIES.patient_interaction:
        return <CallingPatientSvg />;
      default:
        return <SchedulingSpecialistSvg />;
    }
  };

  const getTextBySourceTypeCode = (
    sourceTypeCode: string,
    item: IBillableActivityEntities
  ) => {
    switch (sourceTypeCode) {
      case RESOURCE_ENTITIES.MESSAGE:
        return 'Chat';
      case RESOURCE_ENTITIES.TASK:
        return 'Task created';
      case RESOURCE_ENTITIES.APPOINTMENT:
        return 'Appointment';
      case RESOURCE_ENTITIES.FORM:
        return mappingUuidWithData[item.resourceId].name;
      case RESOURCE_ENTITIES.VOICE_CALL || RESOURCE_ENTITIES.CALL:
        return 'Voice Call';
      case RESOURCE_ENTITIES.care_plan_activity:
      case RESOURCE_ENTITIES.appointment_scheduled:
      case RESOURCE_ENTITIES.assessment_activity:
      case RESOURCE_ENTITIES.access_assistance:
      case RESOURCE_ENTITIES.care_management_activity:
      case RESOURCE_ENTITIES.care_plan_activity:
      case RESOURCE_ENTITIES.health_records:
      case RESOURCE_ENTITIES.patient_interaction:
        return careProgramTaskTypeList.find(
          (type) => type.code === sourceTypeCode
        )?.value;
      default:
        return '';
    }
  };

  const renderSingleOutreachCard = ({item, index}: any) => {
    const listData = useActivityOutreachState.groupedDataByTimeStamp;
    const outreachLogs = listData[item];
    const accountUsersList =
      accountUserList && accountUserList?.length > 0
        ? accountUserList
        : fetchedAccountUserList;
    const timeStampIndex = Date.now();
    return (
      <>
        <HStack
          key={index}
          style={{
            justifyContent: 'space-between',
            marginTop: 0,
          }}
        >
          <Text
            style={{
              fontSize: 14,
              lineHeight: 21,
              fontWeight: '600',
              color: 'rgb(102, 112, 133)',
              padding: 4,
              marginLeft: 17,
              marginTop: 10,
              textTransform: 'uppercase',
              letterSpacing: 2,
              fontFamily: 'Manrope',
            }}
          >
            {item}
          </Text>
        </HStack>
        <VStack key={timeStampIndex}>
          {outreachLogs.map((entity, index: number) => {
            const createdByUserName = accountUsersList.find(
              (user) => user.uuid === entity.performedByUserId
            )?.name;
            const dateStr = getDateStrFromFormat(
              entity?.timestamp,
              DATE_FORMATS.BILLING_ACTIVITY_DATE
            );
            const excludeDurationForResourceTypeCodes = [
              RESOURCE_ENTITIES.FORM,
              RESOURCE_ENTITIES.TASK,
            ];
            const timeToDisplay = !excludeDurationForResourceTypeCodes.includes(
              entity.resourceTypeCode
            )
              ? `${entity.durationInMinutes} mins • ${dateStr}`
              : dateStr;
            return (
              <HStack flex={1} alignItems={'center'} minH={102}>
                <VStack marginX={2} justifyContent={'center'}>
                  <View
                    style={{
                      width: 1,
                      height: 64,
                      backgroundColor: Colors.Custom.Gray200,
                      alignSelf: 'center',
                      transform: [{rotate: '180deg'}],
                    }}
                  />
                  <View
                    {...testID(COMPONENT_TEST_IDENTIFIER.feedDataCollapseIcon)}
                    style={{
                      borderRadius: 88,
                      padding: 10,
                      borderWidth: 1,
                      borderColor: Colors.Custom.Gray200,
                    }}
                  >
                    {getIconName(entity.resourceTypeCode)}
                  </View>
                </VStack>
                <VStack
                  flex={1}
                  style={{marginTop: 20, paddingLeft: 10}}
                  borderRadius={10}
                  space={1}
                >
                  <Text
                    style={{
                      color: Colors.FoldPixel.GRAY400,
                      fontWeight: '500',
                    }}
                    fontSize={14}
                    paddingRight={2}
                    fontWeight={400}
                    textOverflow={'ellipsis'}
                  >
                    {getTextBySourceTypeCode(entity.resourceTypeCode, entity)}
                  </Text>
                  <Text
                    color={Colors.FoldPixel.GRAY200}
                    style={{
                      fontSize: 12,
                      fontWeight: '400',
                    }}
                  >
                    {timeToDisplay}
                  </Text>
                  {(entity.resourceTypeCode === RESOURCE_ENTITIES.TASK ||
                    entity.resourceTypeCode === RESOURCE_ENTITIES.FORM) && (
                    <Stack
                      direction="row"
                      space={2}
                      style={{alignItems: 'center'}}
                    >
                      <ThunderSvg />
                      <Text
                        style={{
                          color: Colors.FoldPixel.GRAY400,
                          fontWeight: '400',
                        }}
                        fontSize={14}
                        textOverflow={'ellipsis'}
                      >
                        {intl.formatMessage({
                          id:
                            entity.resourceTypeCode === RESOURCE_ENTITIES.TASK
                              ? 'ccmEnrollment'
                              : 'ccmOutreach',
                        })}
                      </Text>
                    </Stack>
                  )}
                  <Stack
                    direction="row"
                    space={2}
                    style={{alignItems: 'center'}}
                  >
                    <PerformerSvg />
                    <Text
                      style={{
                        color: Colors.FoldPixel.GRAY400,
                        fontWeight: '400',
                      }}
                      fontSize={14}
                      textOverflow={'ellipsis'}
                    >
                      {createdByUserName}
                    </Text>
                  </Stack>
                </VStack>
              </HStack>
            );
          })}
        </VStack>
      </>
    );
  };

  const renderActivityTypes = () => {
    return careProgramTaskTypeList?.map((item: IMlov, index: number) => {
      if (!item.id) {
        return null;
      }

      return (
        <>
          <Checkbox
            key={item.id}
            value={item.id || ''}
            style={{marginLeft: 0}}
            checked={providerActivityAndOutreachState?.selectedResourceTypes?.includes(
              item.id
            )}
            onChange={(event) => {
              const selected =
                providerActivityAndOutreachState?.selectedResourceTypes || [];
              if (event.target.checked) {
                selected?.push(item.id);
              } else {
                const index = selected?.indexOf(item.id);
                if (index != undefined && index > -1) {
                  selected?.splice(index, 1);
                }
              }
              setProviderActivityAndOutreachState((prev) => ({
                ...prev,
                selectedResourceTypes: selected || [],
              }));
            }}
          >
            <HStack
              style={{
                padding: 3,
              }}
              alignItems="center"
              flex={1}
            >
              <Text fontSize={16}>{item.value}</Text>
            </HStack>
          </Checkbox>
          {index !== (careProgramTaskTypeList?.length || 0) - 1 && (
            <Divider style={{marginLeft: -2, marginRight: -2}} />
          )}
        </>
      );
    });
  };

  return (
    <SafeAreaView>
      <Stack
        direction={'row'}
        style={{
          flex: 5,
          marginBottom: 10,
        }}
      >
        {showActivityFilter && (
          <FilterWrapperComponent
            label={
              !providerActivityAndOutreachState?.selectedResourceTypes?.length
                ? 'All Activities'
                : undefined
            }
            textWeight={400}
            value={careProgramTaskTypeList
              .filter((item) =>
                providerActivityAndOutreachState?.selectedResourceTypes?.includes(
                  item.id
                )
              )
              .map((item) => item.value)}
            onClose={() => {
              handleOutreachActions(
                COMMON_ACTION_CODES.FILTER_CHANGED,
                careProgramTaskTypeList.filter((item) =>
                  providerActivityAndOutreachState?.selectedResourceTypes?.includes(
                    item.id
                  )
                )
              );
            }}
          >
            {renderActivityTypes()}
          </FilterWrapperComponent>
        )}
      </Stack>
      {loaders.intialDataLoading || accountUserLoading ? (
        <Skeleton active />
      ) : (
        <VStack maxHeight="85%" overflow={'scroll'}>
          <FlatList
            marginBottom={10}
            {...testID(COMPONENT_TEST_IDENTIFIER.flatList)}
            data={Object.keys(useActivityOutreachState.groupedDataByTimeStamp)}
            keyExtractor={(item, index) => {
              return `outreach_${index}`;
            }}
            renderItem={renderSingleOutreachCard}
          />
          <HStack space={1}>
            {showMoreData &&
              !(
                loaders?.paginationLoading ||
                loaders?.intialDataLoading ||
                accountUserLoading
              ) && (
                <Text
                  color={Colors?.Custom?.mainPrimaryPurple}
                  fontSize={16}
                  fontWeight={'700'}
                  paddingTop={3}
                  onPress={() =>
                    handleOutreachActions(
                      AUDIT_ACTION_CODES.SHOW_MORE,
                      undefined
                    )
                  }
                >
                  {intl.formatMessage({id: 'loadMore'})}
                </Text>
              )}
          </HStack>
          {(!loaders?.intialDataLoading || accountUserLoading) &&
            loaders?.paginationLoading && (
              <ActivityIndicator
                size={'large'}
                color={Colors.Custom.PrimaryColor}
              />
            )}
        </VStack>
      )}
      {!loaders.intialDataLoading &&
        !accountUserLoading &&
        !loaders?.paginationLoading &&
        Object.keys(useActivityOutreachState.groupedDataByTimeStamp)?.length ===
          0 && (
          <Center>
            <ClockIconForAudit />
            <Text color={Colors.Custom.Gray400} fontSize={16} fontWeight="500">
              {intl.formatMessage({id: 'noData'})}
            </Text>
          </Center>
        )}
    </SafeAreaView>
  );
};

export default ProviderActivityAndOutreachComponent;  