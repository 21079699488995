const SideCarListSvg = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.3334 4.66663H2.66675M10.0001 7.99996H2.66675M6.00008 11.3333H2.66675" stroke="#6F7A90" stroke-linecap="round"/>
    </svg>
  );
};

export default SideCarListSvg;


