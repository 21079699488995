import { IDocumentCategory } from "./Documents";

export const getSortedCategories = (documentCategories: IDocumentCategory[]) => {
    // default categories placed before non default ones, and only the non default ones are sorted in ascending order of their value
    const sortedDocumentCategories = documentCategories ? [...documentCategories].sort((a, b) => {
        if (a.isDefault && !b.isDefault) {
            return -1;
        } else if (!a.isDefault && b.isDefault) {
            return 1;
        } else if (!a.isDefault && !b.isDefault) {
            return a.value.localeCompare(b.value);
        } else {
            return 0;
        }
    })
        : [];
    return sortedDocumentCategories
}

export type IJourneyDetails = {
    id: string;
    title: string;
    startDateTime: string;
    statusValue: string
  }