import React from "react";
import { HStack, Skeleton, VStack } from "native-base";

const CareTeamLoader = () => {
  const dummyArray = new Array(5).fill(0);
  return (
    <VStack>
      {dummyArray.map((index) => (
        <HStack padding={2} key={`CareTeamLoader_${index}_${Math.random()}`}>
          <Skeleton size="10" rounded="full" />
          <Skeleton.Text rounded="full" lines={2} marginX={4} />
        </HStack>
      ))}
    </VStack>
  );
};

export default CareTeamLoader;
