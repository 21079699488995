import {v4 as uuidv4} from 'uuid';
import {allowToShowGqlQueryNames} from '../constants/BaseUrlConst';
import BaseService from '../services/CommonService/BaseService';

export const TRANSACTION_NAMES = {
  CONVERSATION_CHANGE: 'CONVERSATION_CHANGE',
  TAB_CHANGE: 'TAB_CHANGE',
  SIDEBAR_CHANGE: 'SIDEBAR_CHANGE',
  SCROLL_TO_PREV_MESSAGES: 'SCROLL_TO_PREV_MESSAGES',
  TASK_BOARD_TYPE_CHANGE: 'TASK_BOARD_TYPE_CHANGE',
  TASK_VIEW_TYPE_CHANGE: 'TASK_VIEW_TYPE_CHANGE',
  TASK_TAB_CHANGE: 'TASK_TAB_CHANGE',
  TASK_DRAG_FROM_SOURCE: 'TASK_DRAG_FROM_SOURCE',
  TASK_FETCH_NEXT_PAGE: 'TASK_FETCH_NEXT_PAGE',
  SETTINGS_SUB_TAB_CHANGE: 'SETTINGS_SUB_TAB_CHANGE',
  CONVERSATION_STATUS_CHANGE: 'CONVERSATION_STATUS_CHANGE',
  CONVERSATION_ASSIGNED: 'CONVERSATION_ASSIGNED',
  CONVERSATION_UNASSIGNED: 'CONVERSATION_UNASSIGNED',
  CONVERSATION_SELECTED: 'CONVERSATION_SELECTED',
  CONVERSATION_LOAD_MORE_CLICKED: 'CONVERSATION_LOAD_MORE_CLICKED',
  CONVERSATION_MESSAGE_SEND: 'CONVERSATION_MESSAGE_SEND',
  CONVERSATION_CONTACT_DETAIL_CLICK: 'CONVERSATION_CONTACT_DETAIL_CLICK',
  TASK_PANEL_VIEW_ALL_CLICK: 'TASK_PANEL_VIEW_ALL_CLICK',
  TASK_PANEL_REFRESH_CLICK: 'TASK_PANEL_REFRESH_CLICK',
  TASK_PANEL_ADD_TASK_CLICK: 'TASK_PANEL_ADD_TASK_CLICK',
  TASK_PANEL_TASK_CARD_CLICK: 'TASK_PANEL_TASK_CARD_CLICK',
  TASK_FROM_MULTIPLE_MESSAGES: 'TASK_FROM_MULTIPLE_MESSAGES',
  TASK_FROM_SINGLE_MESSAGE: 'TASK_FROM_SINGLE_MESSAGE',
  TASK_ADD_OR_UPDATE: 'TASK_ADD_OR_UPDATE',
  INDEXED_DB_HIT: 'INDEXED_DB_HIT',
  INDEXED_DB_MISS: 'INDEXED_DB_MISS',
  INDEXED_DB_SYNC_CONVERSATION: 'INDEXED_DB_SYNC_CONVERSATION',
  INDEXED_DB_INIT_FAIL: 'INDEXED_DB_INIT_FAIL',
  INDEXED_DB_INIT_SUCCESS: 'INDEXED_DB_INIT_SUCCESS',
} as const;

export type ITransactionName = keyof typeof TRANSACTION_NAMES;

type IOtherData = Record<string, string | number | boolean | undefined | null>;

interface ITransaction {
  type: ITransactionName;
  identifier: string | number;
  startTime: Date;
  endTime?: Date;
  duration?: number;
}

interface MemoryInfo {
  totalJSHeapSize: number;
  usedJSHeapSize: number;
  jsHeapSizeLimit: number;
}

const performanceWithMemory = performance as Performance & {
  memory?: MemoryInfo;
};

export class CaptureTransaction {
  private static instance: CaptureTransaction;
  private transactionMap: Map<string, ITransaction> = new Map();
  private sessionId: string = uuidv4();
  private version = '3.0.0';

  private constructor() {}

  public static getInstance(): CaptureTransaction {
    if (!CaptureTransaction.instance) {
      CaptureTransaction.instance = new CaptureTransaction();
    }
    return CaptureTransaction.instance;
  }

  public clearAllTransactions(): void {
    this.transactionMap.clear();
  }

  public clearTransaction(
    name: ITransactionName,
    identifier: string | number
  ): void {
    const key = this.getKey(name, identifier);
    this.transactionMap.delete(key);
  }

  public initiateTransaction({
    name,
    identifier,
  }: {
    name: ITransactionName;
    identifier: string | number;
  }): ITransaction {
    const key = this.getKey(name, identifier);
    if (this.transactionMap.has(key)) {
      return this.transactionMap.get(key)!;
    }
    const transaction: ITransaction = {
      identifier,
      type: name,
      startTime: new Date(),
    };
    this.transactionMap.set(key, transaction);
    return transaction;
  }

  public finishTransaction(
    name: ITransactionName,
    identifier: string | number,
    otherData: IOtherData = {}
  ): ITransaction | undefined {
    const key = this.getKey(name, identifier);
    const transaction = this.transactionMap.get(key);
    if (!transaction) {
      return undefined;
    }
    transaction.endTime = new Date();
    const duration = this.getDuration(
      transaction.startTime,
      transaction.endTime
    );
    transaction.duration = duration;
    this.sendTransactionToServer(transaction, otherData);
    this.clearTransaction(name, identifier);
  }

  private getKey(name: ITransactionName, identifier: string | number): string {
    return `${name}_${identifier}`;
  }

  private getDuration(startTime: Date, endTime: Date): number {
    return endTime.getTime() - startTime.getTime();
  }

  private sendTransactionToServer(
    transaction: ITransaction,
    otherData: IOtherData = {}
  ) {
    const url =
      `crm-communication/transactions/log` +
      (allowToShowGqlQueryNames()
        ? `?transaction=${transaction.type}&identifier=${transaction.identifier}&sessionId=${this.sessionId}`
        : '');
    const baseService = BaseService.getSharedInstance().axios;
    const usedJSHeapSize = performanceWithMemory.memory?.usedJSHeapSize;
    const jsHeapSizeLimit = performanceWithMemory.memory?.jsHeapSizeLimit;
    const totalJSHeapSize = performanceWithMemory.memory?.totalJSHeapSize;
    const hash = window.location.hash;
    baseService.post(url, {
      sessionId: this.sessionId,
      version: this.version,
      usedJSHeapSize,
      jsHeapSizeLimit,
      totalJSHeapSize,
      hash,
      ...transaction,
      ...otherData,
    });
  }

  public captureTransaction(
    name: ITransactionName,
    identifier: string | number,
    otherData: IOtherData = {}
  ) {
    this.initiateTransaction({name, identifier});
    this.finishTransaction(name, identifier, otherData);
  }
}
