import {LazyQueryExecFunction, OperationVariables, useLazyQuery, useMutation} from '@apollo/client';
import {GROUP_MEMBER_TYPE} from '../../../../../../constants';
import { SUPPORTED_EVENT_CODE } from '../../../../../../constants/WebSocketConst';
import { sendConversationNotificationNoMessageEvent} from '../../../../../../services/SendNotification';
import {IMentionResponse, IPubsubTokenResponse} from '../interface';
import {
  IConversationMentions,
  IConversationMentionsReqData,
  IUserMentionData,
} from './interface';

export const getUserIdsFromMsgContent = (
  msgStr: string,
  groupMemberCodeIdObj: {[index: string]: string}
): IUserMentionData[] => {
  const userMentionsList = msgStr?.match(
    /\(mention:\/\/(user|contact)\/[\w-]*\/\w*\//gi
  );
  const userMentionDataList: IUserMentionData[] = [];
  userMentionsList?.forEach((userMention) => {
    let userUuidStr = '';
    let userTypeId = groupMemberCodeIdObj[GROUP_MEMBER_TYPE.USER];
    if (userMention.includes('user_uuid')) {
      userTypeId = groupMemberCodeIdObj[GROUP_MEMBER_TYPE.USER];
      userUuidStr =
        userMention?.split('user/')?.[1]?.split('/user_uuid/')?.[0] || '';
    } else if (userMention.includes('contact_uuid')) {
      userTypeId = groupMemberCodeIdObj[GROUP_MEMBER_TYPE.CONTACT];
      userUuidStr =
        userMention?.split('contact/')?.[1]?.split('/contact_uuid/')?.[0] || '';
    }

    if (userUuidStr && userTypeId) {
      userMentionDataList.push({
        userTypeId: userTypeId,
        uuid: userUuidStr,
      });
    }
  });
  return userMentionDataList;
};

export const getUserIdListFromMsgContent = (
  msgStr: string,
  groupMemberCodeIdObj: {[index: string]: string}
): string[] => {
  const userMentionsList = msgStr?.match(
    /\(mention:\/\/(user|contact)\/[\w-]*\/\w*\//gi
  );
  const userIdList: string[] = [];
  userMentionsList?.forEach((userMention) => {
    let userUuidStr = '';
    let userTypeId = groupMemberCodeIdObj[GROUP_MEMBER_TYPE.USER];
    if (userMention.includes('user_uuid')) {
      userTypeId = groupMemberCodeIdObj[GROUP_MEMBER_TYPE.USER];
      userUuidStr =
        userMention?.split('user/')?.[1]?.split('/user_uuid/')?.[0] || '';
    } else if (userMention.includes('contact_uuid')) {
      userTypeId = groupMemberCodeIdObj[GROUP_MEMBER_TYPE.CONTACT];
      userUuidStr =
        userMention?.split('contact/')?.[1]?.split('/contact_uuid/')?.[0] || '';
    }

    if (userUuidStr && userTypeId) {
      userIdList.push(userUuidStr);
    }
  });
  return userIdList;
};

export const getConversationMentionsData = (
  paramsData: IConversationMentionsReqData
): IConversationMentions[] => {
  const {userList} = paramsData;
  const conversationMentionReqList: IConversationMentions[] = [];
  userList.forEach((singleUserData) => {
    conversationMentionReqList.push({
      accountUuid: paramsData?.accountUUID,
      conversationUuid: paramsData?.selectedConversationData?.uuid,
      messageUuid: paramsData?.msgData?.uuid,
      userId: singleUserData.uuid,
      userTypeId: singleUserData.userTypeId,
    });
  });
  return conversationMentionReqList;
};


export const sendNotificationOnMention = async (args: {
  mentionResponse: IMentionResponse[];
  conversationUuid: string;
  displayId: string | number
}) => {
  const {
    mentionResponse,
    conversationUuid,
    displayId,
  } = args;
  if (!mentionResponse || mentionResponse?.length == 0) {
    return;
  }
  const response = await sendConversationNotificationNoMessageEvent({
    conversationUuid: conversationUuid,
    eventCode: SUPPORTED_EVENT_CODE.CONVERSATION_MENTIONED,
    eventData: {
      conversationUuid,
      displayId,
      mentionData: mentionResponse,
    }
  })
  return response;
};


export const getUserAndContactUuidFromMentionResponse = (args: {
  mentionResponse: IMentionResponse[];
}) => {
  const {mentionResponse} = args;
  const contactUuidList: string[] = [];
  const userUuidList: string[] = [];
  mentionResponse.forEach((item) => {
    const code = item.userType.code;
    if (code == 'CONTACT') {
      contactUuidList.push(item.userId);
    }
    if (code == 'USER') {
      userUuidList.push(item.userId);
    }
  });
  return {
    contactUuidList,
    userUuidList,
  };
};

export const getTokenListFromResponse = (args: {
  response: IPubsubTokenResponse;
}): string[] => {
  const {response} = args;
  if (!response) {
    return [];
  }
  const contacts = response.contacts || [];
  const users = response.users || [];
  const userTokenList = users.map((item) => item.pubsubToken) || [];
  const contactTokenList =
    contacts.map((item) => item?.person?.pubsubToken) || [];
  return [...userTokenList, ...contactTokenList];
};

export const getInboxMembersPupsubToken = async (
  accountId: string | number,
  inboxId: string | number,
  GetInboxMemberPubSubTokenById:  LazyQueryExecFunction<any, OperationVariables>,
  ) => {
  const response = await GetInboxMemberPubSubTokenById({
    fetchPolicy: 'no-cache',
    variables: {
      accountId: accountId,
      id: inboxId,
    },
  }).catch((error: any) => {

  });
  if (response) {
    if (response?.data?.inboxes?.length) {
      const userPupsubToken = response?.data?.inboxes[0]?.inboxMembers.map((inboxMember: any) => {
        return inboxMember?.user?.pubsubToken;
      });
      return [...userPupsubToken];
    }
  }
  return [];
};

