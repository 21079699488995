import {Select} from 'antd';
import {Text, View, VStack} from 'native-base';
import { useState } from 'react';
import { Colors } from '../../../../../../styles';
import {Answer, ISingleSelectProps} from '../interfaces';
import {styles} from './QuestionnaireComponentStyles';

const SingleSelect = (props: ISingleSelectProps) => {
  const {title, onItemSelect, answerOptions, required} = props;
  const [answer, setAnswer] = useState<string | undefined>(props.answer?.display);
  return (
    <VStack style={styles.container}>
      <View style={styles.titleView}>
        <Text style={styles.titleText}>{title} {required && <Text color={Colors.error[500]}>*</Text>}</Text>
      </View>

      <View>
        <Select
          defaultValue={props.answer?.display}
          disabled={props.readonly}
          status={required && !answer ? 'error' : ''}
          onChange={(value) => {
            const selectedAnswer = answerOptions.find(
              (item) => item.display === value
            );
            setAnswer(selectedAnswer?.display);
            onItemSelect(selectedAnswer as Answer);
          }}
        >
          {answerOptions.map((item) => {
            return (
              <Select.Option
                key={`${item.code}_${item.display}`}
                value={item.display}
              >
                {item.display}
              </Select.Option>
            );
          })}
        </Select>
      </View>
    </VStack>
  );
};

export default SingleSelect;
