import {useEffect, useRef, useState} from 'react';
import {
  FHIR_RESOURCE,
  PatientRecordActions,
  RESOURCE_BLOCK_MESSAGE_ID,
} from '../../constants/FhirConstant';
import {acceptIgnorePatientReportedRecords} from '../../services/CommonService/AidBoxService';
import {Modal, notification} from 'antd';
import {useIntl} from 'react-intl';
import { FoldButton } from '../CommonComponents/FoldButton/FoldButton';
import { BUTTON_TYPE, HTTP_ERROR_CODE } from '../../constants';
import { Colors } from '../../styles';
import { onError } from '@apollo/client/link/error';

const useBulkActionPatientReportedRecords = () => {
  const intl = useIntl();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [confirm, setConfirm] = useState<boolean>(false)
  const timeOutRefs = useRef<NodeJS.Timeout[]>([])
  const handleBulkAction = async (params: {
    resource: string;
    action: PatientRecordActions;
    getFilteredRecords: () => any[];
    patientId: string;
    accountLocationUuid: string;
    onComplete: (response: any) => void;
    onError: (error?: any) => void;
    isMedicalHistory?: boolean,
  }) => {
    if (confirm) {
      setConfirm(false);
    }
    const {
      resource,
      action,
      accountLocationUuid,
      patientId,
      isMedicalHistory,
      getFilteredRecords,
      onComplete,
    } = params;
    setLoading(true);
    const records = getFilteredRecords();
    await acceptIgnorePatientReportedRecords(
      resource,
      action,
      '-1',
      {
        resourceList: records,
      },
      {
        patientId: patientId,
        locationId: accountLocationUuid,
        isMedicalHistory: !!isMedicalHistory
      },
      (response) => {
        const timeOutId = setTimeout(() => {
          setLoading(false);
          setError(false);
          onComplete(response);
          notification.success({
            message: intl.formatMessage({id: 'bulkActionSucessMessage'}),
          });
        }, 1000);
        timeOutRefs.current.push((timeOutId))
      },
      (error) => {
        if (error?.response?.status === HTTP_ERROR_CODE.METHOD_NOT_ALLOWED) {
          onError(error);
          setLoading(false);
          setError(true);
          return;
        }
        notification.error({
          message: intl.formatMessage({id: 'apiErrorMsg'}),
        });
        setLoading(false);
        setError(true);
      }
    );
  };
  useEffect(() => {
    return () => {
      timeOutRefs.current.forEach((timeoutId) => {
        clearTimeout(timeoutId);
      });
    }
  }, []);
  return {
    loading,
    error,
    handleBulkAction,
    confirm,
    handleConfirmation: (value: boolean) => setConfirm(value)
  };
};

export default useBulkActionPatientReportedRecords;
