import { useLazyQuery } from '@apollo/client';
import { Select } from 'antd';
import debounce from 'lodash/debounce';
import { FormControl, View, VStack } from 'native-base';
import { useContext, useEffect, useState } from 'react';
import { MLOV_CATEGORY } from '../../../constants';
import { CommonDataContext } from '../../../context/CommonDataContext';
import LabelsQueries from '../../../services/Labels/LabelsQueries';
import { getAccountId } from '../../../utils/commonUtils';
import { getMlovIdFromCode } from '../../../utils/mlovUtils';
import { ILabel } from '../FilterView/interfaces';
import './TagDropdownCss.css';
import { styles } from './TagdropdownSelectStyle';
const TagdropdownSelect = (props: any) => {
  const accountId = getAccountId();
  const [searchString, setSearchString] = useState('');
  const commonData = useContext(CommonDataContext);
  const labelTypeList = commonData.MLOV[MLOV_CATEGORY.LABEL_TYPE];
  const labelTypeId = getMlovIdFromCode(labelTypeList, 'CONTACT');
  const [labelData, setLabelData] = useState<ILabel[]>(props.value || []);

  const [getLabelDataList] = useLazyQuery<{labels: ILabel[]}>(
    LabelsQueries.GetLabelsByFilter
  );
  const [loadingLabelData, setLoadingLabelData] = useState(true);

  useEffect(() => {
    setLabelData([]);
    setLoadingLabelData(true);
    getLabelDataList({
      variables: {
        limit:10,
        offset:0,
        labelTypeId: labelTypeId,
        accountId: accountId,
        search: `%${searchString}%`,
      },
    })
      .then((labelsRespData) => {
        setLabelData(labelsRespData?.data?.labels || []);
        setLoadingLabelData(false);
      })
      .catch(() => {
        setLabelData([]);
        setLoadingLabelData(false);
      });
  }, [searchString]);

  return (
    <FormControl paddingY={props.paddingY || 0}>
      <VStack alignItems="flex-start">
        <View>
          <VStack>{props.labelComponent ? props.labelComponent : null}</VStack>
        </View>
        <View style={styles.zipSelectContainer}>
          <Select
            allowClear
            showSearch
            status={props.isShowError && !(props?.value && props.value?.length) ? 'error' : '' }
            mode="multiple"
            filterOption={false}
            value={(props.value|| []).map((data:any)=>{
              return data.id || data;
            })}
            style={props?.isSkipHeight ? {} :{height: '36px'}}
            placeholder="Search Tag"
            onChange={(valueList: any[], dataList: any[]) => {
              const newList = dataList.map((data=>{return {id:data?.id,value:data?.id,title:data?.title}}))
              props.onChange(newList);
            }}
            disabled={props.isDisabled}
            className={'participant-auto-complete'}
            onSearch={debounce((searchString: string) => {
              setSearchString(searchString);
            }, 300)}
            open={searchString.length ? true : false}
            onBlur={() => {
              setLabelData([]);
              setSearchString('');
            }}
            dropdownMatchSelectWidth={false}
            loading={loadingLabelData}
          >
            {labelData.map((option) => {
              return (
                <Select.Option title={option.title} id={option.id} value={option.id}>
                  {option.title}
                </Select.Option>
              );
            })}
          </Select>
        </View>
      </VStack>
    </FormControl>
  );
};

export default TagdropdownSelect;
