import {Extension, QuestionnaireItem} from 'fhir/r4';
import {
  ICustomEhrAttributePayloadFormat,
  IEhrAttributeResponse,
  IEhrFieldList,
  IEhrFieldOption,
  IEhrFieldType,
} from './interface';
import {InputNumber, Select} from 'antd';
import { CUSTOM_FIELDS, FHIR_RESOURCE } from '../../../../../../../../constants/FhirConstant';
import { Input, View } from 'native-base';
import { Colors } from '../../../../../../../../styles';
import {getMomentObjectWithDateStringAndFormat } from '../../../../../../../../utils/DateUtils';
import {DISPLAY_SLASH_DATE_FORMAT } from '../../../../../../../../constants';
import { ModalActionDatePicker } from '../../../../../../../common/ModalActionCommonComponent/ModalActionDatePicker';

const EHR_FIELDS_EXTENTION_URL = {
  FIELD_TYPE: 'https://foldhealth.io/structure/field-type',
  POSSIBLE_VALUES: 'https://foldhealth.io/structure/possible-values',
};

const getValueByExtensionURls = (extensionList: Extension[], url: string) => {
  const requiredExtension = extensionList.find((item) => item.url === url);
  if (requiredExtension) {
    if (requiredExtension.url === EHR_FIELDS_EXTENTION_URL.POSSIBLE_VALUES) {
      return JSON.parse(requiredExtension?.valueString || '').map(
        (item: IEhrFieldOption) =>
          item.code === ' ' ? {...item, display: 'None'} : item
      );
    }
    return requiredExtension.valueString;
  }
};

const getValueAndDisplayByType = (type : IEhrFieldType,prevResponse: any[],field:IEhrAttributeResponse) => {
  switch(type){
    case IEhrFieldType.CHOICE:
     return{
         value:
      prevResponse?.find?.((res) => res?.linkId === field.code)?.answer[0]
        ?.valueCoding?.code || '',
         display:
      prevResponse?.find?.((res) => res?.linkId === field.code)?.answer[0]
        ?.valueCoding?.display || 'None',
     }
     case IEhrFieldType.TEXT:
      return{
          value:
       prevResponse?.find?.((res) => res?.linkId === field.code)?.answer[0]
         ?.valueString || '',
          display:
       prevResponse?.find?.((res) => res?.linkId === field.code)?.answer[0]
         ?.valueString || 'None',
      }
      case IEhrFieldType.DATE:
      return{
          value:
       prevResponse?.find?.((res) => res?.linkId === field.code)?.answer[0]
         ?.valueString || '',
          display:
       prevResponse?.find?.((res) => res?.linkId === field.code)?.answer[0]
         ?.valueString || 'None',
      }
      case IEhrFieldType.NUMBER:
        return{
            value:
         prevResponse?.find?.((res) => res?.linkId === field.code)?.answer[0]
           ?.valueString || '',
            display:
         prevResponse?.find?.((res) => res?.linkId === field.code)?.answer[0]
           ?.valueString || 'None',
        }
    default: 
    return{
      value: '',
      display: ''
    }
  }
}

export const formatCustomEhrFieldsList = (
  fieldList: IEhrAttributeResponse[],
  prevResponse: any[],
) => {
  const formmatedList: IEhrFieldList[] = [];
  fieldList.map((field) => {
    const type = getValueByExtensionURls(
      field.extension,
      EHR_FIELDS_EXTENTION_URL.FIELD_TYPE
    );
    formmatedList.push({
      key: field.code,
      label: field.display,
      type: type,
      possibleValues: getValueByExtensionURls(
        field.extension,
        EHR_FIELDS_EXTENTION_URL.POSSIBLE_VALUES
      ),
      // empty spaces indicates none option
      value: getValueAndDisplayByType(type, prevResponse, field)?.value,
      display: getValueAndDisplayByType(type, prevResponse, field)?.display,
    });
  });

  return formmatedList;
};

export const getFieldElementByType = (
  field: IEhrFieldList,
  onChange: (id: string, value: string) => void
) => {
  const {value, possibleValues, label} = field;
  switch (field.type) {
    case IEhrFieldType.CHOICE:
      return (
        <Select
          style={{
            borderWidth: 1,
          }}
          defaultValue={''}
          allowClear={true}
          value={value}
          onChange={(value: any) => onChange(field.key,value)}
        >
          {possibleValues.map((item) => (
            <Select.Option value={item.code} key={item.code}>
              {item.display}
            </Select.Option>
          ))}
        </Select>
      );

    case IEhrFieldType.TEXT:
      return (
        <Input
          style={{
            borderWidth: 1,
          }}
          _focus={{
            borderColor: Colors.Custom.Gray200,
          }}
          defaultValue={''}
          borderRadius={8}
          value={value}
          onChangeText={(text: string) => onChange(field.key, text)}
        />
      );

    case IEhrFieldType.DATE:
      return (
        <ModalActionDatePicker

          format={DISPLAY_SLASH_DATE_FORMAT}
          onChange={(value: any, dateString: string) => {
            onChange(field.key, dateString);
          }}
          value={
            getMomentObjectWithDateStringAndFormat(
              value,
              DISPLAY_SLASH_DATE_FORMAT
            ).isValid()
              ? getMomentObjectWithDateStringAndFormat(
                  value,
                  DISPLAY_SLASH_DATE_FORMAT
                )
              : undefined
          }
          customStyle={{flex: 1}}
        />
      );

    case IEhrFieldType.NUMBER:
      return (
        <InputNumber
         
          style={{
            borderWidth: 1,
            borderColor: Colors.Custom.Gray200,
            width: 680,
            borderRadius: 5,
          }}
          value={parseInt(value)}
          onChange={(value) => {
            onChange(field.key, value ? value.toString() : ' ');
          }}
        />
      );
  }
};

export const getCustomEHRAttributesFromPatientData = (data:any[]) => {
  const questionnaireResponseItem: QuestionnaireItem = data?.find(
    (item) => item?.resourceType === FHIR_RESOURCE.QUESTIONNAIRE_RESPONSE
  );
  return questionnaireResponseItem?.item?.filter(item => item.text === CUSTOM_FIELDS) || [];
}
export const getAnswerObject = (type: string, value: string) => {
  switch (type) {
    case IEhrFieldType.CHOICE:
      return {
        valueCoding: {
          code: value,
        },
      };
    case IEhrFieldType.TEXT:
      return {
        valueString: value,
      };
    case IEhrFieldType.DATE:
      return {
        valueString: value,
      };
    case IEhrFieldType.NUMBER:
      return {
        valueString: value,
      };
    default:
      return {
        valueString: value,
      };
  }
};

export const formatCustomEhrFieldsForSave = (
  updatedFieldList: IEhrFieldList[],
  prevResponse: any[]
) => {
  const answerList: ICustomEhrAttributePayloadFormat = {
    resourceType: FHIR_RESOURCE.QUESTIONNAIRE_RESPONSE,
    item: updatedFieldList.map(({key, value, type}) => ({
      text: CUSTOM_FIELDS,
      linkId: key,
      answer: [getAnswerObject(type, value)],
    })),
  };
  return answerList;
};

