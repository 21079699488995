import {StyleSheet} from 'react-native';
import { Colors } from '../../../styles/Colors';
export const styles = StyleSheet.create({
  mainMenuTxt: {
    marginStart: 8,
    marginEnd: 10,
    color: Colors.Custom.Gray500,
    alignSelf: 'flex-start',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    alignItems: 'flex-start',
  },
  shortCodeTxt: {
    marginStart: 10,
    marginEnd: 0,
    color: Colors.Custom.Gray300,
    alignSelf: 'flex-end',
    justifyContent: 'flex-end',
    alignContent: 'flex-end',
    alignItems: 'flex-end',
  },
  dividerStyle: {
    width: '100%',
  },
  mainMenuContent: {
    width: '100%',
    paddingTop: 10,
    paddingBottom: 8,
    cursor: 'pointer',
    borderBottomColor: Colors.Custom.Gray200,
    borderBottomWidth: 0.3,
    alignItems: 'center'
  },
  firstMenu: {
    paddingTop: 10,
  },
});
