
const CampaignOutlineIcon = () => {

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.26 21H6.98L5.98 15.18M20.34 3L10.38 7.87H7.95V15.18H10.38L20.34 20.05V3ZM4 7.87H7.94V15.18H4V7.87Z" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
};

export default CampaignOutlineIcon;