import React from 'react';
import {Colors} from '../../../styles';
import {ICommonSvgProps} from './interfaces';

const EditLogSvg = (props: ICommonSvgProps) => {
  const {
    isEnabled,
    defaultColor,
    customStrokeColor,
    customBgColor,
    borderColor,
  } = props;
  const bgColor = isEnabled
    ? customBgColor
      ? customBgColor
      : Colors.primary['500']
    : customBgColor
    ? customBgColor
    : Colors.primary['200'];
  return (
    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 2.00044V8.00044M1 8.00044H7M1 8.00044L5.64 3.64044C7.02091 2.26186 8.81245 1.36941 10.7447 1.09755C12.6769 0.8257 14.6451 1.18917 16.3528 2.1332C18.0605 3.07723 19.4152 4.55068 20.2126 6.33154C21.0101 8.11239 21.2072 10.1042 20.7742 12.0068C20.3413 13.9094 19.3017 15.6198 17.8121 16.8802C16.3226 18.1406 14.4637 18.8828 12.5157 18.9949C10.5677 19.107 8.63598 18.583 7.01166 17.5018C5.38734 16.4206 4.15839 14.8408 3.51 13.0004M12 6.00044V10.5004L14.5 13.0004" 
      stroke= {props?.customStrokeColor ? props?.customStrokeColor : "#825AC7"}
      stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
};

export default EditLogSvg;
