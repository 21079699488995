import React from 'react';
import {Colors} from '../../../styles/Colors';
import {isWeb} from '../../../utils/platformCheckUtils';
import {ICommonSvgProps} from './interfaces';
import {Image} from 'native-base';

const UserSvgV2 = (props: ICommonSvgProps) => {
  const {customStrokeColor,width,height} = props;
  const bgColor = customStrokeColor || Colors.Custom.Gray500;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={ width ? width : "16"}
      height={ height ? height : "17"}
      fill="none"
      viewBox="0 0 16 17"
    >
      <path
        stroke={bgColor}
        d="M10.667 4.503a2.667 2.667 0 11-5.333 0 2.667 2.667 0 015.333 0zM13.334 12.17c0 1.656 0 3-5.334 3-5.333 0-5.333-1.344-5.333-3 0-1.658 2.388-3 5.333-3 2.946 0 5.334 1.342 5.334 3z"
      ></path>
    </svg>
  );
};

export default UserSvgV2;
