import moment from 'moment';
import {
  convertToTimezone,
  getCurrentTimeZone,
  getDateStrFromMomentObj,
} from '../../../../../utils/DateUtils';
import {DATE_FORMATS} from '../../../../../constants';
import { formatEFaxNumber } from '../../EFax Settings/EFaxInboxSettingUtils';

export const getSmsInboxDetails = (conversationInboxData: any) => {
  const smsInboxDetails = [];
  const smsInboxData =
    conversationInboxData?.contactInbox?.inbox?.channelTwilioSms;
  const createdAt = conversationInboxData?.createdAt
    ? convertToTimezone(
        conversationInboxData?.createdAt,
        getCurrentTimeZone()
      ).format(DATE_FORMATS.MESSAGE_DATE_FORMAT)
    : '';

  smsInboxDetails.push({
    key: 'inboxName',
    value: conversationInboxData?.contactInbox?.inbox?.name || '',
  });
  smsInboxDetails.push({key: 'createdAt', value: createdAt});
  smsInboxDetails.push({
    key: 'inboxPhoneNumber',
    value: smsInboxData?.phoneNumber,
  });
  return smsInboxDetails;
};

export const getEmailInboxDetails = (conversationInboxData: any) => {
  const emailInboxDetails = [];
  const emailInboxData =
    conversationInboxData?.contactInbox?.inbox?.channelEmail;
  const createdAt = conversationInboxData?.createdAt
    ? convertToTimezone(
        conversationInboxData?.createdAt,
        getCurrentTimeZone()
      ).format(DATE_FORMATS.MESSAGE_DATE_FORMAT)
    : '';

  emailInboxDetails.push({
    key: 'inboxName',
    value: conversationInboxData?.contactInbox?.inbox?.name || '',
  });
  emailInboxDetails.push({key: 'createdAt', value: createdAt});
  emailInboxDetails.push({key: 'email', value: emailInboxData?.email});
  return emailInboxDetails;
};

export const getEFaxInboxDetails = (conversationInboxData: any) => {
  const efaxInboxDetails = [];
  const eFaxInboxData = conversationInboxData?.contactInbox?.inbox?.channelEfax;
  const createdAt = conversationInboxData?.createdAt
    ? convertToTimezone(
        conversationInboxData?.createdAt,
        getCurrentTimeZone()
      ).format(DATE_FORMATS.MESSAGE_DATE_FORMAT)
    : '';

  efaxInboxDetails.push({
    key: 'inboxName',
    value: conversationInboxData?.contactInbox?.inbox?.name || '',
  });
  efaxInboxDetails.push({key: 'createdAt', value: createdAt});
  efaxInboxDetails.push({
    key: 'efax',
    value: formatEFaxNumber(eFaxInboxData?.efaxNumber),
  });
  return efaxInboxDetails;
};
