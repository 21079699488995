import { gql } from '@apollo/client';

export const GetAllMlovsWithCategory = gql`
  query getAllMlovsWithCategory {
    mlovs {
      categoryId
      code
      id
      value
    }
    mlovCategories {
      category
      id
    }
  }
`;

export const GetAllCareMlovsWithCategory = gql`
  query getAllCareMlovsWithCategory {
    mlovs {
      categoryId
      code
      id
      value
      isDeleted
      description
    }
    mlovCategories {
      category
      id
    }
    accountMlovsDeprecated(where: { isDeleted: {_eq: false}}) {
      categoryId
      code
      id
      value
      isDeleted
      tenantId
      description
    }
  }
`;
export const GetAllCareMlovsWithCategoryWithoutAccountMlovs = gql`
  query getAllCareMlovsWithCategory {
    mlovs {
      categoryId
      code
      id
      value
      isDeleted
    }
    mlovCategories {
      category
      id
    }
  }
`;

export const GetAllDefaultContactTypes = gql`
  query GetAllDefaultContactTypes($accountUuid: uuid) {
    customContactTypes(where:{
      _or: [
        {isDefault: {_eq: true}},
        {accountUuid: {_eq: $accountUuid}}
      ],
      _and: {
        isDeleted: {_eq: false }
      }
    }) {
      code
      id
      value
      isDeleted
      isActive
      isDefault
      can_convert_to_patient
    }
  }
`;

export const GetAllCloudTelePhonyMlovsWithCategory = gql`
  query getAllCloudTelePhonyMlovsWithCategory {
    mlovCategories {
      category
      mlovs {
        id
        code
        value
      }
    }
  }
`;

export const GET_MLOVS_BY_CATEGORY = gql`
  query GetMlovByCategory($categories: [String!]) {
    mlovs(where: {category: {category: {_in: $categories}}}) {
      id
      code
      value
      category {
        category
      }
    }
  }
`;

export const getCloudTelephonyNumbers = gql`
  query getVirtualNumber {
    virtualPhoneNumberAssignees {
      id
    }
  }
`;

export const GET_MLOV_ALL_GENDER_LIST = gql`
  query Allgenderlist($categoryCode: String){
    mlovs(where:{category:{category:{_eq: $categoryCode}}})
    {
      code
      value
    }
  }
`;

export const GET_ACCOUNT_MLOVS_BY_CATEGORY = gql`
  query GetAccountMlovByCategory($categories: [String!]) {
    accountMlovsDeprecated(where: {category: {category: {_in: $categories}}}) {
      id
      code
      value
      category {
        category
      }
    }
  }
`;

export default {
  GetAllMlovsWithCategory,
  GetAllCareMlovsWithCategory,
  GetAllCloudTelePhonyMlovsWithCategory,
  GET_MLOVS_BY_CATEGORY,
  getCloudTelephonyNumbers,
  GET_MLOV_ALL_GENDER_LIST,
  GetAllDefaultContactTypes,
  GET_ACCOUNT_MLOVS_BY_CATEGORY,
  GetAllCareMlovsWithCategoryWithoutAccountMlovs,
};
