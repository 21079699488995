import React from "react";
import { ICommonSvgProps } from "../interfaces";

const CreateTaskActionBtn = (props: ICommonSvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props?.width ? props?.width : '24'}
      height={props?.height ? props?.height : '24'}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#667085"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M9 11l3 3L22 4m-1 8v7a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2h11"
      ></path>
    </svg>
  );
}

export default CreateTaskActionBtn;
