import {Pressable} from 'native-base';
import {
  LinkComponent,
  MediaComponent,
  TextComponent,
} from './PatientEducationComponents';
import {COMPONENT_TYPES} from './PatientEducationConsts';
import {IPEArticleProps} from './interfaces';
import {Colors} from '../../../../styles';

const PEArtical = (props: IPEArticleProps) => {
  const getComponentByType = (componentType: string): JSX.Element => {
    let resultComponent = <></>;
    switch (componentType) {
      case COMPONENT_TYPES.MEDIA:
        resultComponent = (
          <MediaComponent
            onChangeMediaData={(mediaData) => {
              const {desc, mediaId, mediaType, mediaUrl} = mediaData;
              const data = props.data;
              data.mediaId = mediaId;
              data.mediaType = mediaType;
              data.mediaUrl = mediaUrl;
              data.description = desc;
              props.onChangeDynamicZone(data);
            }}
            singleDynamicData={props.data}
          />
        );
        break;
      case COMPONENT_TYPES.TEXT:
        resultComponent = (
          <TextComponent
            text={props.data.text}
            onChangeText={(text) => {
              const data = props.data;
              data.text = text;
              props.onChangeDynamicZone(data);
            }}
            singleDynamicData={props.data}
          />
        );
        break;
      case COMPONENT_TYPES.EMBED:
        resultComponent = (
          <LinkComponent
            link={props.data.link}
            onChangeLink={(text) => {
              const data = props.data;
              data.link = text;
              props.onChangeDynamicZone(data);
            }}
            singleDynamicData={props.data}
          />
        );
        break;
    }
    return resultComponent;
  };
  return (
    <Pressable
      style={{
        borderWidth: props.data.isSelected ? 1 : undefined,
        borderColor: props.data.isSelected
          ? Colors.Custom.mainPrimaryPurple
          : '',
      }}
      onPress={() => {
        props.onSelectComponent(props.data);
      }}
    >
      {getComponentByType(props.data.__component)}
    </Pressable>
  );
};

export default PEArtical;
