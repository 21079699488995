import React from 'react';

const PhoneRingSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="#98A2B3"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M12.542 4.166a4.167 4.167 0 013.291 3.292M12.542.833a7.5 7.5 0 016.625 6.617m-.834 6.65v2.5a1.666 1.666 0 01-1.816 1.666 16.49 16.49 0 01-7.192-2.558 16.25 16.25 0 01-5-5 16.492 16.492 0 01-2.558-7.225 1.667 1.667 0 011.658-1.817h2.5A1.667 1.667 0 017.592 3.1c.105.8.301 1.585.583 2.341A1.667 1.667 0 017.8 7.2L6.742 8.258a13.333 13.333 0 005 5L12.8 12.2a1.666 1.666 0 011.758-.375c.757.282 1.542.478 2.342.583a1.667 1.667 0 011.433 1.692z"
      ></path>
    </svg>
  );
};

export default PhoneRingSvg;
