import {View, Text} from 'react-native';
import React from 'react';
import {HStack} from 'native-base';
import {Colors} from '../../../../../../../../styles';
import {Checkbox} from 'antd';

const BulkActionView = (props: {
  intermideate: boolean;
  checked: boolean;
  onChange: (e: any) => void;
  children: JSX.Element;
}) => {
  return (
    <HStack
      my={1}
      background={Colors.Custom.MonochromeWhite}
      px={4}
      py={1}
      borderTopWidth={1}
      borderBottomWidth={1}
      borderColor={Colors.Custom.Gray300}
    >
      <Checkbox
        checked={props.checked}
        onChange={props.onChange}
      />
      {props.children}
    </HStack>
  );
};

export default BulkActionView;
