import { Dimensions, View, StyleSheet } from 'react-native'
import React, { useState } from 'react'
import { ITaskCategory, ITaskMetaData } from '../../TaskInterfaces';
import { useIntl } from 'react-intl';
import { ToastType } from '../../../../utils/commonViewUtils';
import useTaskManager from '../../CustomHooks/useTaskManager';
import { TASK_DEFAULT_PAGE_SIZE } from '../../TaskModuleHelper';
import { ITask } from '../../../common/CareDashboard/CareDashboardInterfaces';
import { useLazyQuery } from '@apollo/client';
import { TaskQueries } from '../../../../services';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../constants/Configs';
import { BottomViewAction } from '../../../TaskCard/TaskCardHelper';
import { ConfigProvider, Empty, Table, TableColumnsType, notification } from 'antd';
import { COMMON_ACTION_CODES } from '../../../../constants/ActionConst';
import { FetchTaskState } from '../../../common/CareDashboard/CareDashboardConstants';
import { BUTTON_TYPE, MLOV_CATEGORY } from '../../../../constants';
import { getBooleanFeatureFlag, isAllowedAllLocationToUser, isEmployerRole, isRenderTasksCompactView } from '../../../../utils/commonUtils';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import { getMlovListFromCategory } from '../../../../utils/mlovUtils';
import { getTaskTableViewColumns } from '../../TaskModuleUIHelper';
import './TaskTableView.css';
import SidePagination from '../../../SideCar/SidePagination';
import DeleteTaskModal from '../DeleteTaskModal/DeleteTaskModal';
import { TablePaginationConfig } from 'antd/es/table';
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import { TaskContext } from '../../../../context/TaskContext';
import FeatureFlags from '../../../../constants/FeatureFlags.enums';

interface ITaskTableViewProps {
  category: ITaskCategory;
  metaData: ITaskMetaData;
  onTaskDetail: (data: ITask) => void;
  onMemberClick?: (task: ITask) => void;
  onActionPerformed?: (tabCode: string, rawData: any) => void;
}

const TaskTableView = (props: ITaskTableViewProps) => {
  const {category, metaData, onActionPerformed, onTaskDetail, onMemberClick} = props;
  const intl = useIntl();
  const isEmployer = isEmployerRole();
  const screenHeight = Dimensions.get('window').height;
  const commonData = React.useContext(CommonDataContext);
  const { taskMasterUsers, taskPools } = React.useContext(TaskContext);
  const isRenderCompactView = isRenderTasksCompactView();
  const taskStatusMlov = getMlovListFromCategory(commonData.CARE_STUDIO_MLOV, MLOV_CATEGORY.TASK_STATUS) || [];
  const taskPriorityMlov = getMlovListFromCategory(commonData.CARE_STUDIO_MLOV, MLOV_CATEGORY.TASK_PRIORITY) || [];
  const selectedTaskIds = metaData.selectedTasks?.map(item => item.id) || [];
  const accountLocationUuid = metaData.personData?.accountLocationUuid || '';
  const isMultiTenancyEnabled = getBooleanFeatureFlag(commonData.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED);
  const isAllowedAllLocation = isAllowedAllLocationToUser(commonData.currentUserRoles);
  const userPracticeLocations = commonData?.userPracticeLocations || [];

  const onShowToast = (message: string, type: ToastType, additionalData?: any) => {
    notification.destroy();
    const notificationMethod = type === ToastType.info ?
      notification.info :
      (type === ToastType.error ? notification.error : notification.success);
    notificationMethod({
      message,
      duration: additionalData?.duration || 3.0,
      placement: 'top',
      style: additionalData?.style,
      onClick: () => {
        notification.destroy();
        additionalData?.onClick?.();
      }
    });
  }
  // Task data manager
  const {
    loading,
    tasks,
    pagination,
    taskActionsInProgress,
    fetchTasks,
    handleTaskActions,
    handleMultiSelection,
    getMultiselectState,
  } = useTaskManager({
    filters: category.filters,
    categoryCode: category.code,
    boardType: metaData.boardType,
    pagination: {
      pageNumber: 1,
      pageSize: TASK_DEFAULT_PAGE_SIZE,
    },
    multiSelectData: {
      bulkActionEnabled: metaData.bulkTaskEnabled,
      selectedTasks: metaData.selectedTasks,
    },
    accountLocationUuid: accountLocationUuid,
    showToast: onShowToast,
    onSelectedTaskChange: metaData.onSelectedTaskChange,
    taskMasterUsers: taskMasterUsers?.length ? taskMasterUsers : metaData?.masterUsersList || [] ,
    allTaskPools: taskPools?.length ? taskPools : metaData?.masterPoolList || [],
  });

  // States
  const [deleteTaskData, setDeleteTaskData] = useState<{ showConfirmation: boolean, task?: ITask }>({
    showConfirmation: false,
    task: undefined,
  });

  // APIs
  const [getSubTaskByIds] = useLazyQuery(TaskQueries.GET_SUB_TASK_BY_ID, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
  });

  const onBottomViewAction = async (task: ITask, action: BottomViewAction, data?: any) => {
    switch (action) {
      case BottomViewAction.deleteTask:
        setDeleteTaskData((prev) => ({...prev, showConfirmation: true, task}));
        break;

      default:
        await handleTaskActions(task, action, data);
        break;
    }
  }

  const expandedColumns: TableColumnsType<any> = getTaskTableViewColumns(
    category.filters,
    {
      intl,
      userList: metaData.masterUsersList,
      isMultiTenancyEnabled,
      isAllowedAllLocation,
      userPracticeLocations,
      boardType: metaData.boardType,
      userPool: metaData.masterPoolList,
      loginUserLocations: metaData.loginUserLocations,
      isEmployer,
      taskStatusMlov,
      taskPriorityMlov,
      taskActionsInProgress,
      selectedTasks: selectedTaskIds,
      multiSelectState: getMultiselectState(),
      bulkTaskEnabled: metaData.bulkTaskEnabled,
      sidecarContext: commonData.sidecarContext,
      panelType: metaData.panelType
    },
    {
      getSubTaskByIds,
      onBottomViewAction,
      onTaskDetail,
      onMemberClick,
      onShowToast,
      handleMultiSelection,
  });

  const onDeleteConfirm = async () => {
    const task = deleteTaskData.task;
    setDeleteTaskData((prev) => ({...prev, showConfirmation: false, task: undefined}));
    if (task) {
      await handleTaskActions(task, BottomViewAction.deleteTask, undefined);
    }
  }

  const onDeleteCancel = () => {
    setDeleteTaskData((prev) => ({...prev, showConfirmation: false, task: undefined}));
  }

  const onTableChange = React.useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<any> | SorterResult<any>[],
      extra: TableCurrentDataSource<any>
    ) => {
      if (extra?.action !== 'paginate') {
        onActionPerformed?.(COMMON_ACTION_CODES.CHANGE_TABLE_FILTER_SORTER, {
          filters,
          sorter,
          extra,
        });
      }
    },
    [onActionPerformed]
  );

  if (category.filters.searchString && !tasks?.length && !loading) {
    return <Empty description={'No tasks found in search'} />;
  }

  return (
  <>
    {(!tasks?.length && !loading && !metaData.intialDataLoading) ? (
      <ConfigProvider
        renderEmpty={() => (
          <Empty
            description={'No tasks to show'}
          />
        )}
      >
        <Table
          columns={expandedColumns}
          size={'small'}
          dataSource={tasks}
          showHeader={!isRenderCompactView}
          onChange={onTableChange}
          pagination={false}
          rowKey={(row) => row.id}
          loading={loading || metaData.intialDataLoading}
        />
      </ConfigProvider>
    ) : (
      <Table
        className="task-table-view"
        scroll={{ y: (screenHeight - 320) }}
        columns={expandedColumns}
        loading={loading || metaData.intialDataLoading}
        size={'small'}
        dataSource={tasks}
        rowKey={(row) => row.id as string}
        showHeader={!isRenderCompactView}
        onChange={onTableChange}
        pagination={false}
        footer={() => (
          <View style={styles.paginationContainer}>
            <SidePagination
              page={pagination.pageNumber}
              pageSize={TASK_DEFAULT_PAGE_SIZE}
              totalCount={pagination.total}
              handleChange={(pageNumber: number, pageSize: number) =>
                fetchTasks({ pageNumber, pageSize }, category.filters, { state: FetchTaskState.resetAndFetchFromPagination })
              }
            />
          </View>
        )}
      />
    )}
    {deleteTaskData.showConfirmation && (
      <DeleteTaskModal
        deleteTaskData={deleteTaskData}
        onDeleteCancel={onDeleteCancel}
        onDeleteConfirm={onDeleteConfirm}
      />
    )}
    </>
  )
}

const styles = StyleSheet.create({
  paginationContainer: {
    padding: 5,
  },
});

export default TaskTableView
