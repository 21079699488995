import {useLazyQuery} from '@apollo/client';
import {Select, Spin} from 'antd';
import {debounce, isEqual} from 'lodash';
import {useContext, useEffect, useState} from 'react';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {getMlovListFromCategory, getMlovObjectFromCode} from '../../../../utils/mlovUtils';
import {Skeleton, Text, View} from 'native-base'
import { LABEL_TYPE_CODES, MLOV_CATEGORY } from '../../../../constants/MlovConst';
import { GetLabelsByLabelTitleAndTypeId, GET_LABEL_BY_IDS } from '../../../../services/Labels/LabelsQueries';

const TaskLabelSearch = (props: {
  defaultValue?: any;
  isShowError?: boolean;
  onChange: (actionData?: any, extraData?: any) => void;
}) => {
  const {defaultValue} = props;
  const [labelList, setLabelList] = useState<any[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [selectedValues, setSelectedValues] = useState<any[]>([])
  const mlovData = useContext(CommonDataContext);

  const mlovs = getMlovListFromCategory(
    mlovData.MLOV,
    MLOV_CATEGORY.LABEL_TYPE
  ) || [];
  const takLabelMlov = getMlovObjectFromCode(LABEL_TYPE_CODES.TASK, mlovs);



  const [getTaskLabels] = useLazyQuery(GetLabelsByLabelTitleAndTypeId, {
    fetchPolicy: 'no-cache'
  });

  const [searchTaskLabelById] = useLazyQuery(GET_LABEL_BY_IDS, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      setLoading(false);
      setLabelList(data?.labels || []);
    },
    onError: error => {
      setLoading(false);
    },
  });

  const onGetTaskLabelByIds = (labelIds: string[]) => {
    setLoading(true);
    searchTaskLabelById({
      variables: {
        labelTypeId: takLabelMlov?.id,
        labelIds: labelIds,
      },
    });
  };

  const onSearchTaskLabel = async (searchString: string) => {
    try {
      const taskLabelParams = {
        limit: 10,
        offset: 0,
        labelTypeId: takLabelMlov?.id,
        search: `%${searchString?.trim()}%`,
      };
      const apiResponse = await getTaskLabels({ variables: taskLabelParams});
      const taskLabels = apiResponse?.data?.labels || [];
      setLabelList(taskLabels || []);
      setLoading(false);
    } catch(error) {
      setLabelList([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (defaultValue && defaultValue?.length && !isEqual(defaultValue, selectedValues)) {
      setSelectedValues(defaultValue);
      onGetTaskLabelByIds(defaultValue);
    } else {
      onSearchTaskLabel('');
    }
  }, [defaultValue]);


  return (
    <Select
      size="large"
      mode="multiple"
      showSearch
      status={
        props.isShowError &&
        !(selectedValues && selectedValues?.length)
          ? 'error'
          : ''
      }
      allowClear
      filterOption={false}
      onSearch={debounce(onSearchTaskLabel, 500)}
      options={ labelList.map(label => ({
        value: label.uuid,
        label: label.title
      }))}
      loading={isLoading}
      value={isLoading ? undefined : selectedValues}
      onChange={(value: any) => {
        if (value && value.length > 0) {
          const taskLabelName = value?.map((labelId: any) => {
            const findLabel = labelList?.find(label => {
              return label?.uuid == labelId;
            });
            return findLabel?.title;
          });
          setSelectedValues(value);
          props.onChange(value, taskLabelName);
        } else {
          setSelectedValues([]);
          props.onChange(undefined);
        }
      }}
      placeholder="Select Task Label"
      notFoundContent={isLoading ? <Spin size="large" /> : <Text>No Data Found</Text>}
      style={{width: '100%'}}
      dropdownRender={menu => (
        <>
          {isLoading && <Spin size="small" style={{ display: 'block', textAlign: 'center', margin: '8px 0' }} />}
          {menu}
        </>
      )}
    >
    </Select>
  );
};

export default TaskLabelSearch;
