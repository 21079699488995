import React, { useContext, useEffect, useState } from "react";
import { IBillableActivityEntities } from "./BillingHookInterfaces";
import { useLazyQuery } from "@apollo/client";
import { CARESTUDIO_APOLLO_CONTEXT } from "../../../../constants/Configs";
import { ToastType, showToast } from "../../../../utils/commonViewUtils";
import { useToast } from "native-base";
import { useIntl } from "react-intl";
import { RESOURCE_MAP } from "../../../common/AddTask/AddTaskUtils";
import { GET_FORM_NAME_BY_IDS } from "../../../../services/Forms/FormsQueries";
import { GET_CONTACT_CARE_PROGRAM_BILLABLE_ACTIVITY_ENTITIES } from "../BillingQueries";
import { COMMON_ACTION_CODES } from "../../../../constants/ActionConst";
import { IMlov } from "../../../../Interfaces/CommonInterfaces";
import { getMlovIdFromCode, getMlovListFromCategory } from "../../../../utils/mlovUtils";
import { CommonDataContext } from "../../../../context/CommonDataContext";
import { MLOV_CATEGORY } from "../../../../constants";
import { AUDIT_ACTION_CODES } from "../../../common/Audit/AuditHelper";
import { getCurrentMonthStartDateTime } from "../../../../utils/DateUtils";
import { IFormCommonData } from "../../Forms/FHFormio/CustomComponents/CustomWrapper/CustomWrapper";
import { BILLING_ACTIVITY_STATUS } from "../../../../constants/MlovConst";

export interface IUseActivityOutreach {
  contactCareProgramId?: string;
  isTimeTrackingView?: boolean;
  isBilledView?: boolean;
}

export interface IUseActivityOutreachState {
  selectedBillDetailedViewData: IBillableActivityEntities[];
  groupedDataByTimeStamp: ISingleData;
  selectedFilter?: {
    selectedResourceTypeCodes: IMlov[]
  };
}
interface ISingleData {
  [key: string]: IBillableActivityEntities[];
}

export interface IReturnUseActivityOutreach {
  entitiesLoading: boolean;
  useActivityOutreachState: IUseActivityOutreachState;
  mappingUuidWithData: { [key: string]: any };
  careProgramTaskTypeList: IMlov[];
  handleOutreachActions: (actionCode: string, actionData: any) => void;
  showMoreData: boolean;
}

const ALL_ACTIVITIES = 'ALL_ACTIVITIES'
const PROVIDER_ACTIVITY_PAGE_LIMIT = 10;

const UseActivityOutreach = (params: IUseActivityOutreach) => {
  const toast = useToast();
  const intl = useIntl();
  const [useActivityOutreachState, setUseActivityOutreachState] = useState<IUseActivityOutreachState>({
    selectedBillDetailedViewData: [],
    groupedDataByTimeStamp: {},
  })
  const [mappingUuidWithData, setMappingUuidWithData] = useState<{ [key: string]: any }>({})
  const mlovData = useContext(CommonDataContext);
  const careProgramTaskTypeList = getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.CCM_BILLING_TASK_TYPE
  ) || [];
  const [paginationState, setPaginationState] = useState({
    paginationLoading: false,
    pageSize: PROVIDER_ACTIVITY_PAGE_LIMIT,
    offset: 0,
    total: 0,
  });
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const billableActivityStatusMlov = getMlovListFromCategory(contextData.CARE_STUDIO_MLOV, MLOV_CATEGORY.CONTACT_CARE_PROGRAM_BILLABLE_ACTIVITY_STATUS) || [];
  const careProgramBillableActivityStatusId = getMlovIdFromCode(
    billableActivityStatusMlov,
    params?.isTimeTrackingView ? BILLING_ACTIVITY_STATUS.DRAFT : params?.isBilledView ? BILLING_ACTIVITY_STATUS.COMPLETED : BILLING_ACTIVITY_STATUS.PENDING
  )

  const [getBillableDetailedEntities, { loading: billableDetailEnititiesLoading }] = useLazyQuery<{ contactCareProgramBillableActivityEntities: IBillableActivityEntities[], aggregateContactCareProgramBillableActivityEntities: { aggregate: { count: number } } }>(GET_CONTACT_CARE_PROGRAM_BILLABLE_ACTIVITY_ENTITIES, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
    onError: () => {
      showToast(
        toast,
        intl.formatMessage({ id: 'apiErrorMsg' }),
        ToastType.error,
        4000
      )
    }
  });

  const [getFormNames, { loading: formNamesLoading }] = useLazyQuery<{ forms: { id: string; name: string }[] }>(GET_FORM_NAME_BY_IDS, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache'
  });

  const groupDataByTimeStamp = (dataList: IBillableActivityEntities[]) => {
    return dataList?.reduce((singleData: ISingleData, singleFeedDataObj) => {
      const date = singleFeedDataObj.createdOn || '';
      const key = new Date(date).toLocaleDateString('en-US', {
        month: 'long',
        year: 'numeric',
      });
      if (!singleData[key]) {
        singleData[key] = [];
      }
      singleData[key].push(singleFeedDataObj);
      return singleData;
    }, {});
  };

  const getEntityData = async (args?: { resourceTypeCodeFilter?: IMlov[], offset?: number }) => {
    const monthStartDateTime = getCurrentMonthStartDateTime();
    const resourceCodeTypesForVariables = args?.resourceTypeCodeFilter ?? useActivityOutreachState.selectedFilter?.selectedResourceTypeCodes;
    const response = await getBillableDetailedEntities({
      variables: {
        where: {
          billableActivity: {
            ...(params?.isTimeTrackingView && {
              createdOn: {
                _gte: monthStartDateTime,
              }
            }),
            statusId: {
              _eq: careProgramBillableActivityStatusId
            },
            contactCareProgram: {
              id: {
                _eq: params.contactCareProgramId
              },
            }
          },
          ...(!!resourceCodeTypesForVariables && resourceCodeTypesForVariables?.length > 0) && {
            resourceTypeCode: {
              _in: resourceCodeTypesForVariables?.map((item) => item?.code) || useActivityOutreachState.selectedFilter?.selectedResourceTypeCodes?.map((item) => item?.code)
            }
          },
        },
        offset: args?.offset ?? paginationState.offset,
        limit: PROVIDER_ACTIVITY_PAGE_LIMIT
      }
    })
    if (response.data) {
      const formIdsToFetch: string[] = [];
      response.data.contactCareProgramBillableActivityEntities?.forEach((entity) => {
        if (entity.resourceTypeCode === RESOURCE_MAP.FORM) {
          formIdsToFetch.push(entity.resourceId)
        }
      })
      if (formIdsToFetch.length > 0) {
        const formReponse = await getFormNames({
          variables: { formIds: formIdsToFetch }
        })
        formReponse.data?.forms.forEach((form) => {
          setMappingUuidWithData(prev => ({ ...prev, [form.id]: form }));
        })
      }

      setPaginationState((prev) => {
        return {
          ...prev,
          total: response?.data?.aggregateContactCareProgramBillableActivityEntities.aggregate.count || 0,
        }
      })
      setUseActivityOutreachState((prev) => {
        const offsetToConsider = args?.offset ?? paginationState.offset
        const entities = offsetToConsider === 0
          ? response?.data?.contactCareProgramBillableActivityEntities || []
          : [...prev.selectedBillDetailedViewData, ...(response?.data?.contactCareProgramBillableActivityEntities || [])]
        return {
          ...prev,
          selectedBillDetailedViewData: entities,
          groupedDataByTimeStamp: groupDataByTimeStamp(entities)
        }
      })
    }
  }

  const handleOutreachActions = (actionCode: string, actionData: any) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.FILTER_CHANGED:
        setUseActivityOutreachState((prev) => {
          return {
            ...prev,
            selectedFilter: {
              ...prev.selectedFilter,
              selectedResourceTypeCodes: actionData,
            }
          }
        })
        setPaginationState((prev) => ({
          ...prev,
          offset: 0,
        }));
        getEntityData({ resourceTypeCodeFilter: actionData, offset: 0 })
        break;
      case AUDIT_ACTION_CODES.SHOW_MORE:
        setPaginationState((prev) => ({
          ...prev,
          offset: paginationState?.offset + PROVIDER_ACTIVITY_PAGE_LIMIT,
        }));
        getEntityData({ offset: paginationState?.offset + PROVIDER_ACTIVITY_PAGE_LIMIT });
        break;
    }
  }

  useEffect(() => {
    if (params?.contactCareProgramId) {
      getEntityData()
    }
  },
    [params?.contactCareProgramId]
  )

  const showMoreData = useActivityOutreachState.selectedBillDetailedViewData.length < (paginationState?.total || 0);
  const loaders = {
    intialDataLoading:
      paginationState?.offset === 0 &&
      (billableDetailEnititiesLoading || formNamesLoading),
    paginationLoading:
      paginationState?.offset !== 0 && billableDetailEnititiesLoading || formNamesLoading,
  };

  return {
    useActivityOutreachState,
    mappingUuidWithData,
    careProgramTaskTypeList,
    handleOutreachActions,
    showMoreData,
    loaders,
  }

}

export default UseActivityOutreach;