import React from 'react';
import { Colors } from '../../../styles/Colors';
import { ICommonSvgProps } from './interfaces';

const GraphViewIcon = (props: ICommonSvgProps) => {
    const { isEnabled } = props;

    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Unfold Icon">
                <path id="Vector" d="M15 16.6666V8.33331M10 16.6666V3.33331M5 16.6666V11.6666" stroke={isEnabled ? Colors.primary['300'] : Colors?.Custom?.Gray400} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </g>
        </svg>

    );
};

export default GraphViewIcon;
