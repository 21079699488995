

import React, {useEffect, useState} from 'react';
import {COMMON_ACTION_CODES} from '../../../../../../../../../../constants/ActionConst';
import {isWeb} from '../../../../../../../../../../utils/platformCheckUtils';
import {ITask} from '../../../../../../../../../common/CareDashboard/CareDashboardInterfaces';
import {IFeedData} from '../../../interface';
import TaskActivityView from './TaskActivityView';

const TaskActivity = (props: {
  singleFeedData: IFeedData,
  navigation?: any
  onActionPerformed: (actionCode: string, actionData?: any) => void;
}) => {
  const {singleFeedData, onActionPerformed} = props;

  const [componentState, setComponentState] = useState({
    isDrawerVisible: false,
    isLoading: true,
  });

  let taskData = {} as ITask;
  try {
    const feedData = singleFeedData.data;
    const crmAppointmentData = JSON.parse(feedData);
    taskData = crmAppointmentData?.crmData?.resourceData?.newData;

  } catch (err) {
    taskData = {} as ITask;
  }


  const onTaskMainViewActionPerformed = (actionCode: string, actionData?: any) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.CLOSE_MODAL:
        onActionPerformed(COMMON_ACTION_CODES.CLOSE_MODAL);
        setComponentState((prev) => {
          return {
            ...prev,
            isDrawerVisible: false
          }
        });
        break;
    }
  }

  useEffect(() => {
    setComponentState((prev) => {
      return {
        ...prev,
        isDrawerVisible: true
      }
    });
  }, [])

  const getTaskDetailsElem = (): JSX.Element => {
    try {
      let taskData = {} as ITask;
      try {
        const feedData = singleFeedData.data;
        const crmAppointmentData = JSON.parse(feedData);
        taskData = crmAppointmentData?.crmData?.resourceData?.newData;

      } catch (err) {
        taskData = {} as ITask;
      }

      return (
        <>
          {
            isWeb() && componentState.isDrawerVisible &&
            <TaskActivityView
              taskData={taskData}
              isDrawerVisible={componentState.isDrawerVisible}
              onActionPerformed={onTaskMainViewActionPerformed}
            />
          }

        </>
      );
    } catch (error) {

    }
    return <></>

  };
  if (taskData?.id) {
    return getTaskDetailsElem()
  } else {
    return <></>;
  }
};

export default React.memo(TaskActivity);
