import React from 'react';
import { Svg, Path } from 'react-native-svg';

const MedicationStatementIconSvg = () => {
  return (
    <Svg
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 22 22"
    >
      <Path
        fill="#6F7A90"
        d="M9.398 19.01l.354-.354-.354.353zm9.611-9.612l-.353.354.353-.354zm-6.407-6.407l.353-.354-.353.354zM2.99 12.6l-.354.354.354-.353zM16.297 6.29a.5.5 0 10-.982-.19l.982.19zm-4.165 5.844l.354.353-.354-.353zM6.1 15.315a.5.5 0 10.189.982l-.19-.982zm6.148-11.97l6.408 6.407.707-.707-6.408-6.408-.707.707zm-2.496 15.31l-6.408-6.407-.707.707 6.408 6.408.707-.707zm8.904 0a6.296 6.296 0 01-8.904 0l-.707.708a7.296 7.296 0 0010.318 0l-.707-.707zm0-8.903a6.296 6.296 0 010 8.904l.707.707a7.296 7.296 0 000-10.318l-.707.707zM3.344 3.344a6.296 6.296 0 018.904 0l.707-.707a7.296 7.296 0 00-10.318 0l.707.707zm-.707-.707a7.296 7.296 0 000 10.318l.707-.707a6.296 6.296 0 010-8.904l-.707-.707zm13.169 3.557a62 62 0 01-.491-.095V6.1l-.004.017-.02.08a5.59 5.59 0 01-.095.332c-.093.291-.25.718-.503 1.242-.505 1.047-1.391 2.484-2.914 4.007l.707.707c1.61-1.61 2.56-3.141 3.108-4.28.274-.57.448-1.04.554-1.372a6.589 6.589 0 00.146-.53l.002-.01v-.003-.001l-.49-.096zm-4.027 5.585c-1.523 1.523-2.96 2.41-4.008 2.915-.523.252-.95.409-1.242.502a5.647 5.647 0 01-.41.115l-.018.003h-.003c0 .001 0 0 0 0H6.1l.095.492.095.49h.005l.01-.002a6.57 6.57 0 00.53-.145c.332-.107.803-.28 1.372-.555 1.138-.548 2.67-1.498 4.28-3.108l-.707-.707z"
      ></Path>
    </Svg>
  );
};


export default MedicationStatementIconSvg;
