import {IFrameProps} from './interface';

const Iframe = (props: IFrameProps) => {
  const {source, height, width, srcDoc} = props;
  return (
    <div>
      {srcDoc && (
        <iframe srcDoc={srcDoc} height={height} width={width} style={props.customStyle} />
      )}
      {!srcDoc && (
        <iframe src={source} height={height} width={width} style={props.customStyle} />
      )}
    </div>
  );
};

export default Iframe;
