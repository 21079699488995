import {View} from 'native-base';
import React from 'react';
import ReactQuill from 'react-quill';
import {ITextComponentProps} from '../interfaces';
import './ComponentsCss.css';

const TextComponent = (props: ITextComponentProps) => {
  return (
    <View>
      <ReactQuill
        className="text-editor"
        theme="snow"
        value={props.text}
        placeholder="Please enter text"
        onChange={(text: string) => {
          props.onChangeText(text);
        }}
      />
    </View>
  );
};

export default TextComponent;
