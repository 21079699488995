import React from 'react';
import Svg, { Path } from 'react-native-svg';

const DownloadSvg = (props: {
  isActive?: boolean;
  customActiveColor?: string;
  customColor?: string;
  customStrokeWidth?: string;
}) => {
  const strokeColor = props.isActive
    ? props.customActiveColor
      ? props.customActiveColor
      : ''
    : props.customColor
    ? props.customColor
    : '#4F2D90';
  return (
    <Svg
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <Path
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={props?.customStrokeWidth ? props?.customStrokeWidth : "2"}
        d="M17.5 12.5v3.333a1.666 1.666 0 01-1.667 1.667H4.167A1.667 1.667 0 012.5 15.833V12.5m3.333-4.167L10 12.5m0 0l4.167-4.167M10 12.5v-10"
      ></Path>
    </Svg>
  );
};

export default React.memo(DownloadSvg);