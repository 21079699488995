import {debounce} from 'lodash';
import {Text, TextArea, View, VStack} from 'native-base';
import { useState } from 'react';
import { Colors } from '../../../../../../styles';
import {ITextInputProps} from '../interfaces';
import {styles} from './QuestionnaireComponentStyles';

const TextInput = (props: ITextInputProps) => {
  const {title, onChangeText, required} = props;
  const [answer, setAnswer] = useState<string | undefined>(props.answer);

  return (
    <VStack style={styles.container}>
      <View style={styles.titleView}>
        <Text style={styles.titleText}>{title} {required && <Text color={Colors.error[500]}>*</Text>}</Text>
      </View>

      <View>
        <TextArea
          isInvalid={required && !answer}
          defaultValue={props.answer || ''}
          isReadOnly={props.readonly}
          onChangeText={debounce((text) => {
            setAnswer(text);
            onChangeText(text);
          }, 500)}
        />
      </View>
    </VStack>
  );
};

export default TextInput;
