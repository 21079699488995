import React from "react";
import {Svg, Path} from "react-native-svg";

function DropIcon(params?: {strokeColor?: string, width?: string, height?: string}) {
  return (
    <Svg width={params?.width || "16"} height={params?.height || "17"} fill="none" viewBox="0 0 16 17" key={'drop-icon'}>
      <Path
        stroke={params?.strokeColor || "#8C5AE2"}
        strokeLinecap="round"
        strokeWidth="0.667"
        d="M5.303 8.5c-1.06 1.292-.773 2.938.697 4m.103-9.17L3.412 7.598c-1.242 1.97-.906 4.48.817 6.1 2.083 1.958 5.46 1.958 7.542 0 1.723-1.62 2.06-4.13.817-6.1L9.898 3.33c-.4-.633-.599-.95-.81-1.119a1.757 1.757 0 00-2.176 0c-.211.17-.41.486-.81 1.119z"
      ></Path>
    </Svg>
  );
}

export default React.memo(DropIcon);
