import React from "react";
import { Colors } from "../../../../styles/Colors";
import { ICommonSvgProps } from "../interfaces";

const VideoMeeting =(props: ICommonSvgProps)=> {
  const strokeColor = props.customStrokeColor ? props.customStrokeColor : "#8A94A8";
  return (
    <svg
    width={props?.width || '16'}
    height={props?.height || '16'}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    >
     <path d="M1.33398 7.66406C1.33398 5.47241 1.33398 4.37658 1.93929 3.63901C2.0501 3.50399 2.17391 3.38018 2.30894 3.26937C3.0465 2.66406 4.14233 2.66406 6.33398 2.66406C8.52564 2.66406 9.62147 2.66406 10.359 3.26937C10.4941 3.38018 10.6179 3.50399 10.7287 3.63901C11.334 4.37658 11.334 5.47241 11.334 7.66406V8.33073C11.334 10.5224 11.334 11.6182 10.7287 12.3558C10.6179 12.4908 10.4941 12.6146 10.359 12.7254C9.62147 13.3307 8.52564 13.3307 6.33398 13.3307C4.14233 13.3307 3.0465 13.3307 2.30894 12.7254C2.17391 12.6146 2.0501 12.4908 1.93929 12.3558C1.33398 11.6182 1.33398 10.5224 1.33398 8.33073V7.66406Z" stroke={strokeColor}/>
     <path d="M11.334 6.33086L11.7729 6.11141C13.0702 5.46278 13.7188 5.13846 14.193 5.43157C14.6673 5.72469 14.6673 6.44988 14.6673 7.90026V8.09479C14.6673 9.54517 14.6673 10.2704 14.193 10.5635C13.7188 10.8566 13.0702 10.5323 11.7729 9.88364L11.334 9.66419V6.33086Z" stroke={strokeColor}/>
    </svg>
  );
}

export default VideoMeeting;
