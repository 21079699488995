import { HStack, Icon, View, VStack, Text } from "native-base";
import AntDesign from "react-native-vector-icons/AntDesign";


const WorkflowPreview = (props: any) => {

    const showSubFormList = (data: any) => {
        return data.formNodeList && data.formNodeList.length > 0 && data.formNodeList.map((formList: any) => {
            if (formList.isEnabled) {
                return (
                    <HStack
                        padding={2}
                        flex={1}
                        justifyContent="center"
                        alignItems="center"
                        marginTop={1}
                        borderRadius={0}
                        ml={0}
                        background="white"
                    >

                        <Icon
                            as={AntDesign}
                            name="form"
                            size="5"
                            color="black.500"
                            alignSelf={'center'}
                            justifySelf={'center'}
                        />
                        <HStack flex={1} paddingLeft={1}>
                            <Text>{formList.name}</Text>
                        </HStack>

                    </HStack>
                )
            }
        });
    };

    return (
        <View>
            <Text
                fontWeight={600}
                fontSize={14}
                color="gray.700"
                marginTop={2}
            >Active Workflow</Text>
            {props.workFlowData.map((data: any) => {
                if (data.isEnabled) {
                    return (
                        <VStack width={'100%'} flex={1} padding={2} borderRadius={4} space={1} alignItems="flex-start" alignSelf={'flex-start'} justifySelf={'flex-start'} marginTop={2} backgroundColor={'gray.100'}>
                            <HStack flex={1} marginLeft={2}>
                                <Icon as={AntDesign} name='fork' size='4' color='black.500' alignSelf={'flex-start'} justifySelf={'flex-start'} ml={0} />
                                <HStack paddingLeft={1} flex={1}>
                                    <Text>{data.name}</Text>
                                </HStack>
                            </HStack>
                            {showSubFormList(data)}
                        </VStack>
                    )
                }
            })
            }
        </View>
    )
}

export default WorkflowPreview;