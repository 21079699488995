import React from 'react';
import {Colors} from '../../styles';
import {Svg, Path} from 'react-native-svg';

function FlameIcon(params?: {strokeColor?: string, width?: string, height?: string}) {
  return (
    <Svg width={params?.width || "16"} height={params?.height || "17"} fill="none" viewBox="0 0 16 17">
      <Path
        stroke={params?.strokeColor || Colors.Custom.mainPrimaryPurple}
        strokeLinecap="round"
        strokeWidth="0.667"
        d="M8 14.5c2.946 0 5.333-2.237 5.333-4.997 0-2.495-1.356-4.444-2.292-5.373-.173-.173-.462-.097-.56.125-.498 1.127-1.536 2.75-2.862 2.75-.82.109-2.074-.593-1.062-4.073.09-.313-.244-.565-.5-.355-1.454 1.193-3.39 3.597-3.39 6.926 0 2.76 2.388 4.997 5.333 4.997z"
      ></Path>
    </Svg>
  );
}

export default React.memo(FlameIcon);
