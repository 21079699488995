import {Button, HStack, Spacer, VStack, Text, View, Divider, Badge, Pressable} from 'native-base';
import React, {useState} from 'react';
import {Colors} from '../../../../../../../styles';
import {DisplayText} from '../../../../../../common/DisplayText/DisplayText';
import {EmptyStateSvg} from '../../../../../../common/Svg';
import CustomButton from '../CustomButton/CustomButton';

interface ICompactListViewMoreDetailsProps {
  dataSource: any[];
  showMoreMaxCount: number;
  rowViewForItem: (item: any, index: number) => void;
  noDataTextLabel: string;
  currentStatusValue: string;
  showStatusValue: string;
  viewDataText: string;
  onViewMoreClick?: () => void;
  noDataComponent?: JSX.Element;
  hasPatientReportedRecords?: boolean;
  onViewPatientReportedClick?: () => void;
  filterActiveDataFunction?: (dataItem: any) => boolean;
  filterInactiveDataFunction?: (dataItem: any) => boolean;
}

const CompactListViewMoreDetails = (
  props: ICompactListViewMoreDetailsProps
) => {
  const {
    dataSource,
    showMoreMaxCount,
    rowViewForItem,
    noDataTextLabel,
    noDataComponent,
    currentStatusValue,
    showStatusValue,
    viewDataText,
    hasPatientReportedRecords,
    onViewPatientReportedClick,
    filterActiveDataFunction,
    filterInactiveDataFunction
  } = props;

  const [showMore, setShowMore] = useState(false);
  
  const activeData = dataSource.filter(
    filterActiveDataFunction ||
    ((dataItem: any) =>
      (dataItem?.clinicalStatus?.code || dataItem?.status?.code || '').toLowerCase() ===
      currentStatusValue.toLowerCase())
  );
  
  const nonActiveData = dataSource.filter(
    filterInactiveDataFunction ||
    ((dataItem: any) =>
      (dataItem?.clinicalStatus?.code || dataItem?.status?.code || '').toLowerCase() !==
      currentStatusValue.toLowerCase())
  );

  const shouldDataHaveActiveData = activeData.length > 0;
  const shouldDisplayDataHaveNoActiveData = nonActiveData.length > 0;
  const countDisplayDataHaveNoActiveData = nonActiveData.length;

  return (
    <VStack marginY={2} marginX={4}>
      {hasPatientReportedRecords && (
        <HStack marginBottom={4} marginTop={2}>
          <CustomButton
              onPress={() => {
                if (props.onViewPatientReportedClick) {
                  props.onViewPatientReportedClick();
                }
              }}
              title= {'View Patient Reported Records'}
              />
          <Spacer />
        </HStack>
      )}
      <VStack space={2}>
        <VStack space={4}>
          {/* Empty State  */}
          {(!shouldDataHaveActiveData || !dataSource.length) && !hasPatientReportedRecords && (
            <View marginLeft={2}>
              {/* {noDataComponent ? (
                  noDataComponent
                ) : (
                  <EmptyStateSvg titleId={noDataTextLabel} />
                )} */}
              <DisplayText
                extraStyles={{
                  color: '#667085',
                }}
                textLocalId={noDataTextLabel}
                textType="Text"
              />
            </View>
          )}

          {activeData.map((item, index) => {
              return (
                <VStack>
                  <View paddingBottom={3}>{rowViewForItem(item, index)}</View>
                  {shouldDisplayDataHaveNoActiveData ||
                  index !== activeData.length - 1 ? (
                    <Divider />
                  ) : null}
                </VStack>
              );
            })}
        </VStack>

        {/* Display non active data  */}
        {shouldDisplayDataHaveNoActiveData && (
          <HStack marginTop={2}>
            <CustomButton
              onPress={() => {
                props.onViewMoreClick
                  ? props.onViewMoreClick()
                  : setShowMore(!showMore);
              }}
              title= {showMore
                ? `Hide ${viewDataText}`
                : `View ${viewDataText} (${countDisplayDataHaveNoActiveData})`}
              />
            <Spacer />
          </HStack>
        )}
      </VStack>
    </VStack>
  );
};

export default CompactListViewMoreDetails;
