import React from 'react';
import { G, Svg, Path } from 'react-native-svg';

function AutomationIcon() {
  return (
    <Svg
      width="18"
      height="17"
      fill="none"
      viewBox="0 0 18 17"
    >
      <G>
        <G
          stroke="#667085"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        >
          <Path d="M10.187 1.694c-.077 1.505 1.777 2.271 2.786 1.153a1.602 1.602 0 011.68 1.68c-1.118 1.009-.35 2.863 1.153 2.786a1.6 1.6 0 010 2.374c-1.504-.077-2.271 1.777-1.153 2.786a1.601 1.601 0 01-1.68 1.68c-1.009-1.118-2.863-.35-2.786 1.153a1.6 1.6 0 01-2.374 0c.077-1.504-1.777-2.271-2.786-1.153a1.602 1.602 0 01-1.68-1.68c1.118-1.009.35-2.863-1.153-2.786a1.6 1.6 0 010-2.374c1.505.077 2.271-1.777 1.153-2.785a1.602 1.602 0 011.68-1.68c1.009 1.117 2.863.348 2.786-1.154a1.6 1.6 0 012.374 0z"></Path>
          <Path d="M7.667 6.5L11 8.5l-3.333 2v-4z"></Path>
        </G>
      </G>
    </Svg>
  );
}

export default AutomationIcon;
