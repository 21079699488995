import {Icon, Tooltip, View} from 'native-base';
import Octicons from 'react-native-vector-icons/MaterialIcons';
import React from 'react';
import { testID } from '../../../testUtils';

export const ContactVerified = (props: {label: string}) => {
  return (
    <>
      <Tooltip label={props?.label} placement="top">
        <View {...testID('ContactVerified')}>
          <Icon as={Octicons} name={'verified'} size="3" color={'green.400'} />
        </View>
      </Tooltip>
    </>
  );
};
