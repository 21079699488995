import { useLazyQuery, useMutation } from '@apollo/client';
import { Pressable, Stack, View, Text, Icon, Skeleton, VStack } from 'native-base';
import React, { useContext, useEffect, useState } from 'react';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../constants/Configs';
import { MLOV_CATEGORY, TASK_STATUS_CODES } from '../../../constants/MlovConst';
import TaskQueries from '../../../services/Task/TaskQueries';
import { Colors } from '../../../styles/Colors';
import { getPriorityIcon } from '../../TaskCard/TaskCardHelper';
import FeatherIcon from 'react-native-vector-icons/Feather';
import AddOrUpdateTask from '../AddTask/AddOrUpdateTask';
import { getMlovListFromCategory } from '../../../utils/mlovUtils';
import { getCompletedTaskStatusId } from '../CareDashboard/CareDashboardUtils/CareDashboardUtils';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { getTaskStatusWiseBackgroundColor, getTaskStatusWiseFontColor } from '../../RightSideContainer/Forms/FHFormio/CustomComponents/Intervention/AddOrUpdateIntervention/AddOrUpdateInterventionHelper';
import { TaskStatus } from '../../RightSideContainer/Forms/FHFormio/CustomComponents/Intervention/AddOrUpdateIntervention/InterventionConstants';
import { EventBus } from '../../../utils/EventBus';
import { TASK_EVENTS } from '../CareDashboard/CareDashboardConstants';


export const InterventionLinkTask = (props: { interventionId: string, patientId: string, accountLocationUuid: string }) => {
  const { interventionId } = props;

  const [customBorderColor, setCustomBorderColor] = useState('');
  const [linkTaskState, setLinkTasks] = useState<{
    tasks: any[];
    loading: boolean;
    showTaskDrawer: boolean;
    selectedTask: any;
  }>(
    {
      tasks: [],
      loading: false,
      showTaskDrawer: false,
      selectedTask: undefined
    }
  );
  const contextData = useContext(CommonDataContext);
  const eventBus = EventBus.getEventBusInstance();
  const taskStatusMlov = getMlovListFromCategory(contextData.CARE_STUDIO_MLOV, MLOV_CATEGORY.TASK_STATUS) || [];
  const completedStatusId = getCompletedTaskStatusId(taskStatusMlov);

  const [updateTaskStatus] = useMutation(TaskQueries.UPDATE_TASK_STATUS);

  useEffect(() => {
    eventBus.addEventListener(
      TASK_EVENTS.TASK_UPDATED,
      onEventReceived
    );
    return () => {
      eventBus.removeEventListener(onEventReceived);
    };
  }, []);


  const onEventReceived =() => {getLinkTasksWithIntervention()}

  const renderNoteLinkTask = (linkTask: any) => {
    if (linkTask?.id) {
      const backgroundColor = getTaskStatusWiseBackgroundColor(linkTask?.status?.code || '');
      const fontColor = getTaskStatusWiseFontColor(linkTask?.status?.code || '')
      return (
        <Stack
          justifyContent={'center'}
          direction={'column'}
          key={`task_detail_${interventionId}`}
          style={{
            backgroundColor: Colors.Custom.White,
            borderRadius: 6,
            borderColor: Colors.FoldPixel.GRAY200,
            borderWidth: 0.5,
            marginTop: 10,
            padding: 2
          }}>
          <Stack space={2} padding={1} direction={'row'} flex={1}>
            <View flex={0.03} flexDirection="row" justifyContent={'flex-start'} >
              {linkTask?.priority && (
                <View paddingLeft={2}>{getPriorityIcon(linkTask?.priority, true)}</View>
              )}
            </View>
            <View flex={0.95} flexDirection="row" alignItems={'flex-start'}>
              {linkTask?.title && (
                <Text marginLeft={2} numberOfLines={1} ellipsizeMode={'clip'}>{`${linkTask?.title}`}</Text>
              )}
            </View>
            {linkTask?.status && linkTask?.status?.value && (
              <View flex={0.15} flexDirection="row" justifyContent={'center'} backgroundColor={backgroundColor} borderRadius={4}>
                <Text size={'smMedium'} color={fontColor}>
                  {linkTask.status.value}
                </Text>
              </View>
            )}
            <Pressable
              onHoverIn={() => {
                setCustomBorderColor(Colors.FoldPixel.GRAY50);
              }}
              onHoverOut={() => {
                setCustomBorderColor('');
              }}
              onPress={() => {
                setLinkTasks(prev => {
                  return {
                    ...prev,
                    selectedTask: linkTask,
                    showTaskDrawer: true
                  }
                });
              }}
              alignItems={'flex-end'}>
              <Icon
                size='5'
                as={FeatherIcon}
                name="arrow-up-right"
                backgroundColor={customBorderColor}
                color={Colors.Custom.Gray300}
              />
            </Pressable>
          </Stack>
        </Stack>
      );
    }
    return <></>;
  };

  const [getTaskLinkWithDocumentReference] = useLazyQuery(
    TaskQueries.GET_TASKS_LINK_WITH_NOTES,
    {
      fetchPolicy: 'no-cache',
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
      },
    },
  );

  const [getTasksByIds] = useLazyQuery(TaskQueries.GET_TASKS_BY_IDS, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
  });

  const getLinkTasksWithIntervention = async () => {
    try {
      let linkTasks: any[] = [];
      const taskNoteResourceMapParam = { sourceIds: [interventionId] };
      const resourceMapResponse = await getTaskLinkWithDocumentReference({
        variables: taskNoteResourceMapParam,
      });
      const resourceMappings: { sourceId: string; resourceId: string }[] = resourceMapResponse?.data?.resourceMappings || [];
      if (resourceMappings?.length) {
        const filterResourceIds = resourceMappings.map(resourceMap => {
          return resourceMap?.resourceId;
        });
        if (filterResourceIds?.length) {
          const taskResponse = await getTasksByIds({
            variables: { ids: filterResourceIds },
          });
          linkTasks = taskResponse?.data?.getTasks?.tasks || [];
        }
      }
      setLinkTasks((prev: any) => {
        return {
          ...prev,
          tasks: linkTasks,
          loading: false,
        }
      });
    } catch (error) {
      setLinkTasks((prev) => {
        return {
          ...prev,
          loading: false,
        }
      });
    }
  };

  useEffect(() => {
    if (!linkTaskState?.loading) {
      setLinkTasks((prev) => {
        return {
          ...prev,
          loading: true,
        }
      });
      getLinkTasksWithIntervention();
    }
  }, [])

  return (
    <>
      {linkTaskState.loading && (
        <View>
          <Skeleton.Text lines={2} />
        </View>
      )}
      {(!linkTaskState.loading && linkTaskState.tasks.length > 0) && (
        <VStack
          key={`list_view_${interventionId}`}
          style={{
            backgroundColor: Colors.Custom.Gray50,
            borderRadius: 8,
            borderColor: Colors.Custom.Gray100,
            borderWidth: 0.5,
            padding: 12,
            flex: 1
          }}
          my={1}
        >
          {(linkTaskState.tasks || []).map((task, index) => (
            renderNoteLinkTask(task)
          ))}
        </VStack>
      )}
      {linkTaskState.showTaskDrawer && !!linkTaskState?.selectedTask?.id && (
        <AddOrUpdateTask
          task={linkTaskState?.selectedTask}
          isVisible={linkTaskState.showTaskDrawer}
          personData={{
            patientId: props.patientId,
            accountLocationUuid: props.accountLocationUuid
          }}
          onCancel={() => {
            setLinkTasks(prev => {
              return {
                ...prev,
                showTaskDrawer: false,
                selectedTask: undefined
              }
            });
          }}
          onComplete={(data) => {
            setLinkTasks(prev => {
              return {
                ...prev,
                loading: true,
                showTaskDrawer: false,
                selectedTask: undefined
              }
            });
            getLinkTasksWithIntervention();
          }}
          onMarkAsComplete={async (task) => {
            setLinkTasks(prev => {
              return {
                ...prev,
                loading: true,
                showTaskDrawer: false,
                selectedTask: undefined
              }
            });
            await updateTaskStatus({
              context: { service: CARESTUDIO_APOLLO_CONTEXT },
              variables: {
                params: {
                  id: task.id,
                  data: {
                    statusId: completedStatusId,
                  },
                }
              },
            });
            getLinkTasksWithIntervention();
          }}
        />
      )}
    </>
  )
};
