import {Popover, Tooltip} from 'antd';
import {Box, Divider, Pressable, Text, VStack} from 'native-base';
import React from 'react';
import {NOTE_TYPE} from '../../../../../../constants';
import {Colors} from '../../../../../../styles';
import {testID} from '../../../../../../testUtils';
import {getEhrConfig} from '../../../../../../utils/capabilityUtils';
import CreateNoteSvg from '../../../../../common/Svg/CreateNoteSvg';
import {CreateNoteType} from '../../interfaces';
import {cssProperties} from './CreateNoteViewStyles';
import {FHTooltip} from '../../../../../common/FHTooltip';

const CreateNoteView = (props: {
  isInstantChatView: boolean | undefined;
  patientLocationUuid: string | undefined;
  isMessageCopy: boolean;
  handleCreateNoteClick: (code?: CreateNoteType) => void;
}) => {
  const {handleCreateNoteClick, isInstantChatView, patientLocationUuid} = props;
  const [showCreateNoteOptionsPopover, setShowCreateNoteOptionsPopover] =
    React.useState(false);
  const handleShowCreateNoteOptionsPopover = () => {
    setShowCreateNoteOptionsPopover((prev) => !prev);
  };
  const ehrConfig = getEhrConfig(patientLocationUuid, '');
  const isFold = ehrConfig.isFold;
  const isElation = ehrConfig.isElation;

  const isMessagesSelected = props.isMessageCopy;

  const allowClinicalNote = isFold || isElation;

  const createNoteOptionActionList = [
    {
      label: 'Clinical Note',
      code: NOTE_TYPE.CLINICAL_NOTE,
      action: () => handleCreateNoteClick(CreateNoteType.CLINICAL),
    },
    {
      label: 'Contact Note',
      code: NOTE_TYPE.CONTACT_NOTE,
      action: () => handleCreateNoteClick(CreateNoteType.CONTACT),
    },
  ];

  return (
    <Box alignItems="center">
      {isMessagesSelected ? (
        <Popover
          onOpenChange={handleShowCreateNoteOptionsPopover}
          open={showCreateNoteOptionsPopover}
          overlayInnerStyle={cssProperties.overlayInnerStyle}
          trigger="click"
          placement={'bottomRight'}
          content={
            <VStack>
              {createNoteOptionActionList.map((option, index: number) => {
                if (
                  !allowClinicalNote &&
                  option.code === NOTE_TYPE.CLINICAL_NOTE
                ) {
                  return <></>;
                }
                return (
                  <>
                    <Pressable
                      key={option.label}
                      onPress={() => {
                        option?.action();
                        setShowCreateNoteOptionsPopover(false);
                      }}
                    >
                      <Text
                        paddingY={4}
                        paddingX={2}
                        size={'smSemibold'}
                        color={Colors.Custom.Gray700}
                        noOfLines={1}
                        {...testID(option.label)}
                      >
                        {option.label}
                      </Text>
                    </Pressable>
                    {index !== createNoteOptionActionList.length - 1 && (
                      <Divider bg={Colors.Custom.Gray200} />
                    )}
                  </>
                );
              })}
            </VStack>
          }
        >
          <Pressable
            disabled={!isMessagesSelected && !isInstantChatView}
            onPress={handleShowCreateNoteOptionsPopover}
          >
            <FHTooltip text={'Create Note'} position="bottom">
              <VStack alignItems="center">
                <CreateNoteSvg
                  customStrokeColor={
                    isMessagesSelected
                      ? Colors.FoldPixel.GRAY300
                      : Colors.Custom.Gray300
                  }
                  height={20}
                  width={20}
                />
              </VStack>
            </FHTooltip>
          </Pressable>
        </Popover>
      ) : (
        <Pressable
          disabled={!isMessagesSelected && !isInstantChatView}
          onPress={() => handleCreateNoteClick()}
        >
          <FHTooltip text={'Create Note'} position="bottom">
            <VStack alignItems="center">
              <CreateNoteSvg
                customStrokeColor={
                  isMessagesSelected
                    ? Colors.FoldPixel.GRAY300
                    : Colors.Custom.Gray300
                }
                height={20}
                width={20}
              />
            </VStack>
          </FHTooltip>
        </Pressable>
      )}
    </Box>
  );
};

export default CreateNoteView;
