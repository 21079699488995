import React from 'react';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {styles} from '../LeadViewStyle';
import {Box, View, Text} from 'native-base';
import { ICustomUserTagProps } from '../../interfaces';

const CustomUserTag = (props: ICustomUserTagProps) => {
    const {value, handleDeselect, color, label, backgroundColor} = props;
    return (
      <View flex={1} flexDirection={'row'}>
        <Box
          width={'fit-content'}
          style={[styles.tagContainer, {backgroundColor: backgroundColor}]}
        >
          <Text
            style={{
              fontSize: 12,
              fontWeight: '700',
              color: color,
            }}
          >
            {label}
          </Text>
          <AntIcon
            name="close"
            onPress={() => handleDeselect && handleDeselect(value)}
            style={{marginLeft: 4}}
            color={color}
          />
        </Box>
      </View>
    );
  };

  export default CustomUserTag;
