import {View, Text} from 'react-native';
import React from 'react';
import {Colors} from '../../../../styles';
import EmailForwardSvg from '../../Svg/EmailForwardSvg';
import {useIntl} from 'react-intl';

const ForwardedEmailTag = () => {
  const intl = useIntl();
  return (
    <View
      style={{
        backgroundColor: Colors.FoldPixel.GRAY50,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: 4,
        paddingVertical: 2,
        paddingHorizontal: 6,
      }}
    >
      <EmailForwardSvg customStrokeColor={Colors.FoldPixel.GRAY200} size={4} />
      <Text
        style={{
          fontWeight: '400',
          fontSize: 14,
          lineHeight: 16.8,
          color: Colors.FoldPixel.GRAY300,
        }}
      >
        {intl.formatMessage({id: 'forwarded'})}
      </Text>
    </View>
  );
};

export default ForwardedEmailTag;
