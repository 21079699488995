import {Checkbox, View} from 'native-base';
import {StyleSheet} from 'react-native';

interface IModalActionCheckbox {
  defaultIsChecked?: any;
  isChecked?: boolean;
  value: string;
  customStyle?: any;
  isDisabled?: boolean
  onChange: (value: any) => void;
}

export const ModalActionCheckbox = (props: IModalActionCheckbox) => {
  const {isChecked, onChange, value, customStyle, defaultIsChecked, isDisabled} = props;
  return (
    <>
      <View>
        <Checkbox
          defaultIsChecked={defaultIsChecked}
          style={[customStyle]}
          isChecked={isChecked}
          onChange={(value) => {
            onChange(value);
          }}
          value={value}
          isDisabled={isDisabled}
        />
      </View>
    </>
  );
};

const styles = StyleSheet.create({});
