import { Alert, Drawer, Skeleton } from 'antd';
import { Divider, View } from 'native-base';
import React, { useEffect, useRef, useState } from 'react';
import { BUTTON_TYPE } from '../../../constants';
import { IAppointmentTask } from '../../../services/Appointment/AppointmentInterface';
import { Colors } from '../../../styles';
import { AppointmentDetailsBanner } from '../../PersonOmniView/MiddleContainer/PatientNotes/components/AppointmentDetailsBanner';
import { ModalActionTitle } from '../ModalActionTitle/ModalActionTitle';
import { Task } from './Task';
import { TaskCheckList } from './TaskCheckList';
import { IAppointmentDetail } from '../../PersonOmniView/LeftContainer/OtherDetails/PatientAppointmentDataView';
import { useQuery } from '@apollo/client';
import { AppointmentQueries } from '../../../services';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../constants/Configs';

interface IProps {
  isVisible: boolean;
  // tasks?: IAppointmentTask[];
  // appointment?: IAppointmentDetail;
  appointmentId: string;

  canEditTitle?: boolean;
  canEditStatus?: boolean;
  canAddNewTasks?: boolean;
  canDeleteTasks?: boolean;
  showStatus?: boolean;

  showErrors?: boolean;

  onChange?: (tasks: IAppointmentTask[]) => void;
  onClose?: () => void;
}

interface IComponentState {
  appointment?: IAppointmentDetail;
  tasks?: IAppointmentTask[];
}

export function AppointmentTasksDrawer(props: IProps) {
  const [ componentState, setComponentState ] = useState<IComponentState>({});

  const getAppointmentApi = useQuery(AppointmentQueries.GET_APPOINTMENT_BY_ID, {
    // skip: !!props.appointment || !props.appointmentId,
    fetchPolicy: 'no-cache',
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    variables: {
      appointmentId: props.appointmentId
    },
    onCompleted: (data) => {
      if (data?.appointments?.[0]?.id === props.appointmentId) {
        const appointment = data.appointments[0];
        const tasks = appointment?.tasks
        setComponentState((prev) => ({ ...prev, appointment: appointment, tasks: tasks }));
      }
    },
    onError: (error) => {

    }
  });

  return (
    <Drawer
      destroyOnClose
      placement="right"
      visible={props.isVisible}
      onClose={() => {
        props.onClose?.()
      }}
      closable
      width={750}
      title={
        <>
          <ModalActionTitle
            title={'staffInstructionsAppointmentBookDrawerTitle'}
            titleColor={''}
            buttonList={[
              {
                show: true,
                id: 1,
                btnText: 'done',
                size: 'sm',
                variant: BUTTON_TYPE.PRIMARY,
                isTransBtn: false,
                onClick: props.onClose,
              },
            ]}
          />
        </>
      }
    >
      <View mx={0}>
        {
          getAppointmentApi.loading &&
          <View width={'full'}>
            <Skeleton active />
          </View>
        }
        {
          getAppointmentApi.error &&
          <Alert
            message="Something went wrong while fetching appointment details"
            type='error'
            showIcon={true}
          />
        }

        {
          !getAppointmentApi.loading && componentState.appointment &&
          <View>
            <AppointmentDetailsBanner
              appointment={componentState.appointment}

              canOpenAppointmentDetailsModal={false}
              canOpenAppointmentTaskModal={false}
              canShowViewChecklistLink={false}
            />

            <Divider marginTop={3} marginBottom={5}/>

            <TaskCheckList
              tasks={componentState?.tasks || []}
              appointmentId={props.appointmentId}

              canAddNewTasks={false}
              canDeleteTasks={false}
              canEditTitle={false}
              canEditStatus={true}
              showErrors={false}

              onChange={(tasks: IAppointmentTask[]) => {
                props?.onChange?.(tasks);
              }}
            />
          </View>
        }
      </View>
    </Drawer>
  );
}


// return (
  //   <Modal
  //     size={'full'}
  //     isOpen={props.isVisible}
  //     onClose={props.onClose}
  //   >
  //     <Modal.Content>
  //       <View
  //         position={'fixed'}
  //         right={0}
  //         top={0}
  //         style={{
  //           backgroundColor: '#ffffff',
  //           minWidth: 750,
  //           height: '100%',
  //           paddingHorizontal: 20,
  //         }}
  //       >
  //         <ScrollView>
  //           <VStack>
  //             <ModalActionTitle
  //               title={'appointmentTaskDrawerTitle'}
  //               titleColor={''}
  //               buttonList={[
  //                 {
  //                   show: true,
  //                   id: 1,
  //                   btnText: 'cancel',
  //                   size: 'sm',
  //                   textColor: Colors.Custom.mainSecondaryBrown,
  //                   variant: BUTTON_TYPE.SECONDARY,
  //                   isTransBtn: false,
  //                   onClick: props.onClose,
  //                 },
  //               ]}
  //             />

  //             <View mx={0} style={{marginTop: 120}}>
  //               <TaskCheckList
  //                 tasks={props?.tasks || []}

  //                 canAddNewTasks={false}
  //                 canDeleteTasks={false}
  //                 canEditTitle={false}
  //                 canEditStatus={true}
  //                 showErrors={false}

  //                 onChange={(tasks: IAppointmentTask[]) => {
  //                   props?.onChange?.(tasks);
  //                 }}
  //               />
  //             </View>
  //           </VStack>
  //         </ScrollView>
  //       </View>
  //     </Modal.Content>
  //   </Modal>
  // );
