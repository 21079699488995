import {HStack,Image,Text} from "native-base"
import React, {useContext, useEffect, useRef, useState} from "react"
import {CONFIG_CODES} from "../../../constants/AccountConfigConst"
import {WEB_SOCKET_EVENT_CODE} from "../../../constants/WebSocketConst"
import {CommonDataContext, ICommonData} from "../../../context/CommonDataContext"
import {IdleTimer} from "../../../screens/MainScreen/IdleTimer"
import {LOCAL_STORAGE_CONST} from "../../../screens/MainScreen/MainScreenConst"
import {Colors} from "../../../styles"
import {getFeatureFlag} from "../../../utils/commonUtils"
import {EventBus} from "../../../utils/EventBus"
import LocalStorage from "../../../utils/LocalStorage"

export const LockScreenWarning = () => {
  const [showUserInactive,setShowUserInactive]=useState(false)
  const [remaining, setRemaining] = useState(30)
  const timerId:any = useRef()
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const gif = require('../../../assets/images/lockScreen.gif');
  const onIdle = () => {
    setShowUserInactive(true)
  }
  const onActive = ()=>{
    setShowUserInactive(false)
  }
  const handleLockScreen = () => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.broadcastEvent(WEB_SOCKET_EVENT_CODE.LOCK_SCREEN, {data:''});
  }

  const setCurrentScreen = async () => {
    const currentScreenString = await LocalStorage.getItem(LOCAL_STORAGE_CONST?.lockScreen);
    if(currentScreenString?.lockScreen){
      handleLockScreen()
    }
  }

  useEffect(()=>{
    setCurrentScreen()
  },[])

  useEffect(() => {
    setRemaining(30)
    timerId.current = setInterval(() => {
      if(showUserInactive) {
        setRemaining(prev => prev -1)
      }
    }, 1000)
    return () => {
      clearInterval(timerId.current)
    }
  }, [showUserInactive])

  useEffect(()=>{
    if(remaining <= 0) {
      clearInterval(timerId.current)
      handleLockScreen()
    }
  },[remaining])

  const commonData: any = useContext(CommonDataContext);
  const enableLockScreen = getFeatureFlag(CONFIG_CODES.ENABLE_LOCK_SCREEN, commonData);

  return <>
  {showUserInactive && <HStack style={{width:302,height:40,borderRadius:8,backgroundColor:Colors.danger[100],alignItems:'center',justifyContent:'center'}}>
    <Image
      width={'26px'}
      height={'26px'}
      source={gif}
      alt={'lock'}
    />
      <Text width={251} color={Colors.error[600]}>
        {`Screen will lock in ${remaining}s due to inactivity`}
      </Text>
    </HStack>}
    {enableLockScreen && <IdleTimer onIdle={onIdle} onActive={onActive} ></IdleTimer>}
  </>
}
