import {StyleSheet} from 'react-native';
export const groupListPanelStyle = StyleSheet.create({
  groupListWrapper: {
    marginHorizontal: 25, 
    marginBottom: 0
  },
  groupListWrapperFromComms: {
    marginLeft: 10, 
    marginRight: 16, 
    marginBottom: 0
  },
  groupListItem: {
    marginVertical: 4
  }
});
