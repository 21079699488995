import * as React from 'react';
import { IUserPracticeLocation } from '../services/Location/interfaces';

export interface ITaskContext {
  taskPools: any[];
  taskAccessUsers: any[];
  taskContextDataLoading: boolean;
  manageAccessUsersTaskPools: any[];
  taskMasterUsers: any[];
  taskPoolsLoading?: boolean;
  loginUserLocations: IUserPracticeLocation[];
}

export const TaskContext = React.createContext<ITaskContext>({
  taskPools: [],
  taskAccessUsers: [],
  manageAccessUsersTaskPools: [],
  taskContextDataLoading: false,
  taskMasterUsers: [],
  loginUserLocations: [],
  taskPoolsLoading: false,
});
