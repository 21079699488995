import React from 'react';
import {useMutation} from '@apollo/client';
import {View, StyleSheet} from 'react-native';
import {
  CUSTOM_AUTOMATION_URL,
  ENTITY_EVENTS_TITLE_SUBTITLE,
  ENTITY_EVENTS_TYPE,
} from '../../../../constants';
import {LeadQueries} from '../../../../services';
import WorkFlowListByEntityEvents from '../../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/WorkFlowListByEntityEvents';
import {updateContactWorkflow} from '../../../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateQueryUtils';
import {DisplayText} from '../../../common/DisplayText/DisplayText';
import {Colors} from '../../../../styles';
import TaskIconOther from '../../../common/Svg/TaskIconOther';
import {getUserPermissionByEntityAndActionCode, USER_ACCESS_PERMISSION} from '../../../RightSideContainer/UserAccess/UserAccessPermission';
import { MAIN_MENU_CODES } from '../../../SideMenuBar/SideBarConst';

import {getCaslAbility} from '../../../../utils/commonUtils';
import FoldPermitCan from '../../../CommonComponents/FoldPermitCan/FoldPermitCan';

export const EntityEventsListView = (props: any) => {
  const [SingleCreateContactQuery] = useMutation(
    LeadQueries.UpdateWorkflowMetaData
  );

  const UpdateWorkFlow = (workflowEventList: any) => {
    const data = updateContactWorkflow(workflowEventList);
    SingleCreateContactQuery({
      variables: {
        id: props.patient.id,
        workflowListObj: data,
      },
    });
  };

  

  const showSettingTab = getUserPermissionByEntityAndActionCode(
    USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
    MAIN_MENU_CODES.SETTING
  );

  return (
    <View style={styles.container}>
      { showSettingTab ? (
      <FoldPermitCan 
        resource={MAIN_MENU_CODES.AUTOMATION}
        action={USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code}
        component={
          <>
          <View style={styles.headerContainer}>
            <View style={styles.headerContent}>
              <View
                style={styles.container2}
              >
                <View style={styles.iconContainer}>
                  <TaskIconOther />
                </View>
                <DisplayText
                  size={'xlBold'}
                  extraStyles={{color: Colors.primary['700'] || ''}}
                  textLocalId={'activeAutomations'}
                />
              </View>
            </View>
          </View>
          <View style={styles.workflowContainer}>
            <WorkFlowListByEntityEvents
              isDetailsPage={true}
              workflowlist={
                props.patient.workflowMetaData &&
                props.patient.workflowMetaData.workflowList &&
                props.patient.workflowMetaData.workflowList.length > 0 &&
                props.patient.workflowMetaData.workflowList
              }
              addUrl={CUSTOM_AUTOMATION_URL.ADD_PATIENT}
              title={
                ENTITY_EVENTS_TITLE_SUBTITLE.ADD_OR_UPDATE_LEAD_PATIENT_TITLE
              }
              subtitle={
                ENTITY_EVENTS_TITLE_SUBTITLE.ADD_OR_UPDATE_LEAD_PATIENT_SUB_TITLE
              }
              entityEventList={ENTITY_EVENTS_TYPE.ADD_OR_UPDATE_LEAD}
              onValueChanage={(eventEntityList: any) => {
                UpdateWorkFlow(eventEntityList);
              }}
              backgroundColor={'#ffffff'}
            ></WorkFlowListByEntityEvents>
          </View>
          </>
        }
        />
        ) : <></>}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.Custom.Gray50,
  },
  container2: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 9,
  },
  headerContainer: {
    padding: 24,
  },
  headerContent: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  iconContainer: {
    marginRight: 10,
  },
  workflowContainer: {
    backgroundColor: '#ffffff',
    marginHorizontal: 24,
    borderRadius: 8,
    boxShadow: `0px 1px 2px rgba(16, 24, 40, 0.06)`,
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
});

export default EntityEventsListView;
