import { FlowType } from "../../../../../../context/WorkflowContext";

export const navigationPathMap: any = {
    [FlowType.patients]: '/admin/patients/automation',
    [FlowType.communication]: '/admin/communication/automation',
    [FlowType.scheduling]: '/admin/schedule/automation',
    [FlowType.form]: '/admin/forms/automation',
    [FlowType.journeys]: '/admin/journeys/automation',
    [FlowType.task]: '/admin/taskManagement/automation',
    [FlowType.lab]: '/admin/labs/automation',
    [FlowType.order]: '/admin/order/automation',
    [FlowType.DB_TRIGGER]: '/admin/database/automation',
    [FlowType.note]: '/admin/note/automation',
}