import {Drawer} from 'antd';
import {Image, Skeleton, Text, View} from 'native-base';
import React, { useContext } from 'react';
import {BUTTON_TYPE} from '../../../../constants';
import {COMMON_ACTION_CODES} from '../../../../constants/ActionConst';
import {Colors} from '../../../../styles';
import {ModalActionTitle} from '../../ModalActionTitle/ModalActionTitle';
import AttachmentView from '../MsgAttachment/AttachmentView';
import {IConversationAttachmentsView} from './interfaces';
import { CommonDataContext } from '../../../../context/CommonDataContext';

export const ConversationAttachmentView = (
  props: IConversationAttachmentsView
) => {
  const {
    isDrawerVisible,
    onConversationAttachmentActionPerformed,
    loading,
    messageAttachments,
    openInDrawer,
  } = props;
  const commonData = useContext(CommonDataContext);
  const isSideCarContext = commonData.sidecarContext?.isSidecar;

  const getDrawerContent = () => {
    return (
      <>
        {loading ? (
          <Skeleton.Text mt={20} lines={6}></Skeleton.Text>
        ) : (
          <View mt={0}>
            {messageAttachments?.length ? (
              <>
                {messageAttachments.map((attachment: any, index: number) => {
                  return (
                    <AttachmentView
                      key={`ConversationAttachment_${attachment.id}_${index}`}
                      attachments={attachment}
                      openInDrawer={openInDrawer}
                    />
                  );
                })}
              </>
            ) : (
              <View
                height={'full'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <Image
                  size={'md'}
                  resizeMode="contain"
                  source={require('../../../../assets/images/EmptyStatePng/noAttachment.png')}
                  alt="image"
                />
                <Text fontWeight={500} color={'gray.500'}>
                  {'No Files'}
                </Text>
              </View>
            )}
          </View>
        )}
      </>
    );
  };

  return (
    <>
      {openInDrawer ? (
        <Drawer
          visible={isDrawerVisible}
          width={isSideCarContext ? '100%' : 550}
          mask={isSideCarContext ? false : true}
          onClose={() => {
            onConversationAttachmentActionPerformed?.(
              COMMON_ACTION_CODES.CANCEL
            );
          }}
          closable={false}
          title={ <ModalActionTitle
            title={'files'}
            titleColor={''}
            buttonList={[
              {
                show: true,
                id: 1,
                btnText: isSideCarContext ? 'back' : 'close',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
                isTransBtn: false,
                onClick: () => {
                  onConversationAttachmentActionPerformed?.(
                    COMMON_ACTION_CODES.CANCEL
                  );
                },
              },
            ]}
          />}
        >

          <View>{getDrawerContent()}</View>
        </Drawer>
      ) : (
        <View>{getDrawerContent()}</View>
      )}
    </>
  );
};
