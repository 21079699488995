import { View, Image } from 'native-base';
import { isWeb } from '../../../utils/platformCheckUtils';
import React from 'react';
import Svg, { Path } from 'react-native-svg';

const SortIcon = (props: { sortMode: string, strokeColor?: string, strokeWidth?: string }) => {
    if (props.sortMode === "asc") {
        if (isWeb()) {
            return (
                <View>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.6667 5.33317L8.00004 2.6665L5.33337 5.33317M5.33337 11.3332L8.00004 13.9998L10.6667 11.3332" stroke="#825AC7" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M5.33337 11.3335L8.00004 14.0002L10.6667 11.3335" stroke="#E4E7EC" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>

                </View>
            );
        }
        else {
            return (
                <Svg
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 16 16"
                >
                    <Path
                        stroke="#825AC7"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M10.667 5.333L8 2.666 5.333 5.333m0 6L8 14l2.667-2.667"
                    ></Path>
                    <Path
                        stroke="#E4E7EC"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5.333 11.334L8 14l2.667-2.666"
                    ></Path>
                </Svg>
            )
        }
    }
    if (props.sortMode === "desc") {
        if (isWeb()) {
            return (
                <View>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.6667 5.33317L8.00004 2.6665L5.33337 5.33317M5.33337 11.3332L8.00004 13.9998L10.6667 11.3332" stroke="#E4E7EC" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M5.33337 11.3335L8.00004 14.0002L10.6667 11.3335" stroke="#825AC7" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>

                </View>
            )
        }
        else {
            return (
                <Svg
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 16 16"
                >
                    <Path
                        stroke="#E4E7EC"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M10.667 5.333L8 2.666 5.333 5.333m0 6L8 14l2.667-2.667"
                    ></Path>
                    <Path
                        stroke="#825AC7"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5.333 11.334L8 14l2.667-2.666"
                    ></Path>
                </Svg>
            )
        }
    }
    else {
        if (isWeb()) {
            return (
                <View>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.6667 5.33317L8.00004 2.6665L5.33337 5.33317M5.33337 11.3332L8.00004 13.9998L10.6667 11.3332"  stroke-width={props?.strokeWidth || "1"} stroke={props?.strokeColor || "#667085"} stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </View>
            );
        }
        else {
            return (
                <Svg
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 16 16"
                >
                    <Path
                        stroke="#667085"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M10.667 5.333L8 2.666 5.333 5.333m0 6L8 14l2.667-2.667"
                    ></Path>
                </Svg>
            )
        }
    }
};

export default SortIcon;