export enum EntityType {
  MED = 'MED',
  VITAL = 'VITAL',
  LAB_TEST = 'LAB_TEST',
  FORM = 'FORM',
  EXERCISE = 'EXERCISE',
  CONTENT = 'CONTENT',
  INSTRUCTION = 'INSTRUCTION',
  NUTRITION = 'NUTRITION',
  IMMUNIZATION = 'IMMUNIZATION',
  GOAL = 'GOAL',
  TASK = 'TASK',
  SUBTASK = 'SUBTASK',
  ESCALATION = 'ESCALATION',
  ACTIVITY = 'ACTIVITY',
}

export enum TaskPanelType {
  PATIENT,
  INTERNAL,
}

export enum TaskPanelTypeCode {
  PATIENT = 'Patient',
  INTERNAL = 'Internal',
}