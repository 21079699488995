import { DocumentReference } from "fhir/r4";
import { IALlowedCategory } from "../../../../Interfaces";
import { FHIR_RESOURCE } from "../../../../constants/FhirConstant";
import { IAvailableEhr } from "../interfaces"
import { getDiagnosticReportResponseFormatted } from "../../LeftContainer/RecentActivity/RecentReport/ReportUtils";
import { IReportAndResourceMap } from "../../LeftContainer/RecentActivity/OrdersInterface";
import { ReportCategory } from "../Orders/OrdersAndReports/OrderUtils";

export const getTimelineItemCustomTitle = (mainRecord: any, additionalInfo: {
  ehrConfig: IAvailableEhr;
  categories?: IALlowedCategory[];
}) => {
  const resourceType = mainRecord?.resourceType;
  if (
    resourceType === FHIR_RESOURCE.DOCUMENT_REFERENCE &&
    additionalInfo.ehrConfig.isElation &&
    additionalInfo.categories?.length
  ) {
    const documentReference: DocumentReference = mainRecord;
    const noteCode = documentReference.type?.coding?.[0]?.code;
    const category = additionalInfo.categories?.find((item) => item.code === noteCode);
    return category?.foldDisplay || '';
  }
  return '';
}


export const formatFeedResponse = (feedRecords: any) => {
  return feedRecords.map((feedRecord: any) => {
    let resourceData;

    try {
      resourceData = typeof feedRecord.data === 'string' ? JSON.parse(feedRecord.data) : feedRecord.data;
    } catch (error) {

    }

    feedRecord.resourceType = feedRecord.display_type;
    feedRecord.resourceData = resourceData;
    return feedRecord;
  });
};


export const getLabReportButtons = (resourceData: any) => {
  const formattedResponse = getDiagnosticReportResponseFormatted({ data: { entry: [{ resource: resourceData }] } }, false, true);
  const reports: IReportAndResourceMap[] = formattedResponse.reports as IReportAndResourceMap[];
  const resources = reports.map(report => report.resources)[0];
  return { resources, reports };
}

export const isImagingReport = (resourceData: any) => {
  if(resourceData){
    const report = getLabReportButtons(resourceData)?.reports?.[0];
    return report?.orderType === ReportCategory.RAD;
  }
  return false;
}
