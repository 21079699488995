import { useEffect, useState } from "react";
import { isWeb } from "../../utils/platformCheckUtils";

export const useContainerDimensions = (componentRef: any) => {
  const getDimensions = () => ({
    width: componentRef?.current?.offsetWidth,
    height: componentRef?.current?.offsetHeight
  });

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const resetDimension = () => {
    setDimensions(getDimensions());
  };

  useEffect(() => {
    const handleResize = () => {
      setDimensions(getDimensions())
    }
    if (componentRef?.current) {
      setDimensions(getDimensions())
    }
    if(isWeb()){
      window.addEventListener("resize", handleResize)
    }
    return () => {
      if(isWeb()){
        window.removeEventListener("resize", handleResize)
      }
    }
  }, [componentRef]);

  return {...dimensions, resetDimension};
};
