import React from 'react';
import {isWeb} from '../../../utils/platformCheckUtils';
import {Image} from 'native-base';

const TaskIconOther = () => {
  return (
    <>
      {isWeb() ? (
        <svg
          width="22"
          height="24"
          viewBox="0 0 22 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11 3.44696e-07C10.9261 -9.34053e-05 10.8534 0.0189378 10.7891 0.055266L0.932141 5.62655C0.797563 5.70263 0.714407 5.84527 0.714501 5.99986V13.714C0.714501 16.7022 3.31409 19.2251 5.82041 21.0372C8.32672 22.8493 10.8251 23.9626 10.8251 23.9626C10.9364 24.0124 11.0636 24.0124 11.1749 23.9626C11.1749 23.9626 13.6732 22.8493 16.1796 21.0372C18.686 19.2252 21.2855 16.7022 21.2855 13.714V5.99986C21.2856 5.84527 21.2024 5.70263 21.0679 5.62655L11.2109 0.055266C11.1466 0.0189378 11.0739 -9.34053e-05 11 3.44696e-07ZM11 4.71417C15.0186 4.71417 18.2855 7.98112 18.2855 11.9997C18.2855 16.0183 15.0186 19.2853 11 19.2853C6.98136 19.2853 3.71445 16.0184 3.71445 11.9998C3.71445 7.98117 6.98136 4.71417 11 4.71417Z"
            fill="#98A2B3"
          />
          <path
            d="M9.71431 7.71412C9.47764 7.71417 9.28578 7.90603 9.28574 8.1427V10.2855H7.14294C6.90627 10.2855 6.71441 10.4774 6.71436 10.7141V13.2855C6.71441 13.5221 6.90627 13.714 7.14294 13.714H9.28574V15.8568C9.28578 16.0935 9.47764 16.2854 9.71431 16.2854H12.2857C12.5224 16.2854 12.7142 16.0935 12.7143 15.8568V13.714H14.8571C15.0937 13.714 15.2856 13.5221 15.2856 13.2855V10.7141C15.2856 10.4774 15.0937 10.2855 14.8571 10.2855H12.7143V8.1427C12.7143 7.90603 12.5224 7.71417 12.2857 7.71412H9.71431Z"
            fill="#98A2B3"
          />
        </svg>
      ) : (
        <Image
          resizeMode="contain"
          size={'24px'}
          source={require('../../../assets/images/Contact/contact_notes.png')}
          alt="image"
        />
      )}
    </>
  );
};

export default TaskIconOther;
