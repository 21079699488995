import { View, Text, Pressable, Dimensions } from 'react-native'
import React, { useCallback, useState } from 'react'
import { Drawer, Input } from 'antd';
import Stack from '../../../../../../common/LayoutComponents/Stack';
import { IAutomation, IRule, IRuleType } from '../interfaces';
import { RuleEngineInputField } from '../../../../../ContentManagement/CampaignManagement/RuleEngineInputField';
import { Colors } from '../../../../../../../styles';
import FoldButtonV2, { ButtonType } from '../../../../../../PersonOmniView/MiddleContainer/CarePlan/components/FoldButtonV2';
import { useIntl } from 'react-intl';
import CrossIconSvg from '../../../../../../common/Svg/CrossIconSvg';
import AutomationSearch from './AutomationSearch';
import { getDefaultInitTree, getUpdatedRuleJson } from './ContactRulesHelper';
import { addOrUpdateRuleTemplate } from '../../../../../ContentManagement/CampaignManagement/GroupDetails/PopulationGroupApi';
import {v4 as uuidv4} from 'uuid';
import { useToast } from '../../../../../../Toast/ToastProvider';
import { ToastType } from '../../../../../../../utils/commonViewUtils';
import { testID } from '../../../../../../../testUtils';
import './AddOrUpdateContactRuleDrawer.css';
import { reactStyles, styles } from '../../../../formStyle';

interface IAddOrUpdateContactRuleDrawer {
  isUpdate: boolean;
  createNewTemplate: boolean;
  rule?: IRule;
  ruleType?: IRuleType;
  additionalHeaders?: {[index: string]: any};
  onSave: (rule: IRule) => void;
  onCancel?: () => void;
}

interface IAddOrUpdateContactRuleState {
  name: string;
  description?: string;
  jsonTree?: any;
  loading: boolean;
  automation?: IAutomation;
  showErrors: boolean;
}

const AddOrUpdateContactRuleDrawer = (props: IAddOrUpdateContactRuleDrawer) => {
  // Constants
  const { rule, isUpdate, ruleType, createNewTemplate, onSave, onCancel, additionalHeaders } = props;
  const intl = useIntl();
  const { height } = Dimensions.get('window');
  const toast = useToast();

  // States
  const [componentState, setComponentState] = useState<IAddOrUpdateContactRuleState>({
    name: rule?.name || '',
    description: rule?.description,
    jsonTree: rule?.displayJson && Object.keys(rule?.displayJson).length > 0 ? rule?.displayJson : getDefaultInitTree(ruleType),
    automation: rule?.reference?.workflows?.[0],
    loading: false,
    showErrors: false,
  });

  // Other methods
  const handleJSONTreeStateChange = useCallback((newJsonTree: any) => {
    setComponentState((prev) => ({ ...prev, jsonTree: newJsonTree }));
  }, []);

  const isValid = () => {
    return componentState.name &&
    componentState.description &&
    componentState.jsonTree &&
    componentState.automation?.id
  }

  const saveRule = async () => {
    setComponentState((prev) => ({ ...prev, showErrors: true }))
    if (!isValid() || !componentState.automation) {
      return;
    }
    try {
      setComponentState((prev) => ({ ...prev, loading: true }));
      const ruleData = getUpdatedRuleJson({
        name: componentState.name,
        description: componentState.description,
        rule: rule?.rule,
        id: rule?.ruleId,
        displayJson: componentState.jsonTree,
        isTemplate: true,
      }, componentState.automation);

      let ruleId = rule?.ruleId;
      if (createNewTemplate) {
        const response = await addOrUpdateRuleTemplate(ruleData);
        ruleId = response.data?.id;
      }

      onSave({
        id: rule?.id || uuidv4(),
        name: ruleData.name,
        rule: ruleData.rule,
        displayJson: ruleData.displayJson,
        ruleId: ruleId,
        description: ruleData.description,
        reference: ruleData.reference,
      });
    } catch (error) {
      setComponentState((prev) => ({ ...prev, loading: false }));
      toast({
        message: 'Something went wrong while saving rule. Please try again later',
        toastType: ToastType.error,
        closeAllPrevToast: true,
      });
    }
  }

  const renderTitle = () => {
    return (
      <Stack direction='row' space={8} style={styles.alignItemsCenterJustifyContentSpaceBetween}>
        <Text style={styles.text}>
          {isUpdate ? 'Update Rule Alert' : 'Create Rule Alert'}
        </Text>
        <Stack direction='row' space={12} style={styles.alignItemsCenter}>
          <FoldButtonV2
            key={'SaveBtn'}
            label={intl.formatMessage({id: 'save'})}
            buttonType={ButtonType.primary}
            isDisabled={componentState.loading}
            isLoading={componentState.loading}
            onPress={saveRule}
          />
          <View style={styles.view} />
          <Pressable key={'CrossButton'} onPress={onCancel} disabled={componentState.loading}>
            <CrossIconSvg
              size={18}
            />
          </Pressable>
        </Stack>
      </Stack>
    );
  }

  return (
    <Drawer
      width={'40%'}
      open={true}
      closeIcon={null}
      closable={false}
      maskClosable={true}
      destroyOnClose
      onClose={onCancel}
      title={renderTitle()}
    >
      <Stack direction="column" space={16}>
        <Stack direction='column' space={24} style={styles.flex2}>
          <Stack direction='column' space={8}>
            <Text style={styles.text2}>
              {'Rule Alert Name'}
              <Text style={styles.colorError}>{'*'}</Text>
            </Text>
            <Input
              className='rule-drawer-custom-input'
              value={componentState.name}
              placeholder="Enter rule alert name"
              style={reactStyles.input}
              status={componentState.showErrors && !componentState.name ? 'error' : undefined}
              onChange={(e) => {
                setComponentState((prev) => ({ ...prev, name: e.target.value }));
              }}
              {...testID('RuleAlertNameInput')}
            />
          </Stack>
          <Stack direction='column' space={8}>
            <Text style={styles.text2}>
              {'Description'}
              <Text style={styles.colorError}>{'*'}</Text>
            </Text>
            <Input.TextArea
              className='rule-drawer-custom-input'
              value={componentState.description}
              placeholder="Enter description"
              rows={4}
              style={reactStyles.input}
              onChange={(e) => {
                setComponentState((prev) => ({ ...prev, description: e.target.value }));
              }}
              {...testID('RuleAlertDescriptionInput')}
              showCount
              maxLength={150}
              status={componentState.showErrors && !componentState.description ? 'error' : undefined}
            />
          </Stack>
        </Stack>
        <Stack direction='column' space={16} style={styles.flex5}>
          <Text style={styles.text3}>{'If below condition matches'}</Text>
          <View style={styles.marginMinus8MarginRightMinus16MaxHeightHeight50PercentOverflowScroll}>
            <RuleEngineInputField
              isPatientGroup={true}
              jsonTree={componentState.jsonTree}
              handleJSONTreeStateChange={handleJSONTreeStateChange}
            />
          </View>
          <Text style={styles.text3}>{'then execute below automation'}</Text>
          <AutomationSearch
            value={componentState.automation}
            isShowError={componentState.showErrors && !componentState.automation?.workflowId}
            additionalHeaders={additionalHeaders}
            onChange={(automation) => {
              // generate new id if new automation is selected or use old id
              setComponentState((prev) => ({ ...prev, automation: { ...automation, id: prev.automation?.id || uuidv4() }}));
            }}
          />
        </Stack>
      </Stack>
    </Drawer>
  )
}

export default AddOrUpdateContactRuleDrawer
