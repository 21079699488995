import {ITabDataProps} from '../../components/common/TabList/interface';

export const Member_Screen_Tab_Code = {
  PATIENTS: 'CUSTOMER',
  PROSPECTS: 'LEAD',
  MY_PATIENT: 'MY_PATIENT'
};

export const Member_Screen_TAB_LIST: ITabDataProps[] = [
  {
    tabCode: Member_Screen_Tab_Code.PATIENTS,
    title: 'Patients',
    code: 'Customer',
  },
  {
    tabCode: Member_Screen_Tab_Code.MY_PATIENT,
    title: 'My Patients',
    code: 'MyPatient',
  },
  {
    tabCode: Member_Screen_Tab_Code.PROSPECTS,
    title: 'Leads',
    code: 'Lead',
  },
];
