import axios from 'axios';
import {IConversationMessageEventBody, INotificationParams} from '../components/RightSideContainer/TeamInbox/Conversations/MessagingWindow/interface';
import {evnName} from '../constants/BaseUrlConst';
import LocalStorage from '../utils/LocalStorage';
import CommonService from './CommonService/CommonService';
export const BASE_URL = `https://${evnName()}foldhealth.io`;

export const sendConversationNotificationNoMessageEvent = async (notificationBody: IConversationMessageEventBody) => {
  const commonService = CommonService.getCommonServiceInstance();
  const axiosService = commonService.crmService;
  return axiosService.post('/conversation/notification', JSON.stringify(notificationBody));
};
