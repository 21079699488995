import {CaretLeftFilled, CaretRightFilled} from '@ant-design/icons';
import React from 'react';
import Stack from '../common/LayoutComponents/Stack';
import {Text} from 'react-native';
import {Colors} from '../../styles/Colors';

interface IPaginationProps {
  page: number;
  pageSize: number;
  totalCount: number;
  handleChange: (newPage: number, newPageSize: number) => void;
}

const SidePagination = (props: IPaginationProps) => {
  const {page, pageSize, totalCount, handleChange} = props;
  const totalPages = Math.ceil(totalCount / pageSize);

  const handlePrevious = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (page > 1) {
      handleChange(page - 1, pageSize);
    }
  };

  const handleNext = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (page < totalPages) {
      handleChange(page + 1, pageSize);
    }
  };

  return (
    <Stack direction={'row'} style={{alignItems: 'center'}} space={10}>
      <Text
        style={{
          color: Colors?.FoldPixel?.GRAY300,
          fontWeight: '400',
          fontSize: 14,
        }}
      >
        {(page - 1) * pageSize + 1}-{Math.min(page * pageSize, totalCount)} of{' '}
        {totalCount}
      </Text>
      <CaretLeftFilled
        disabled={page === 1}
        onClick={handlePrevious}
        style={{color: Colors?.FoldPixel?.GRAY300, marginRight: 4}}
      />
      <CaretRightFilled
        disabled={page === totalPages}
        onClick={handleNext}
        style={{color: Colors?.FoldPixel?.GRAY300}}
      />
    </Stack>
  );
};

export default SidePagination;
