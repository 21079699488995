import {Pressable, Text, View} from 'native-base';
import {useIntl} from 'react-intl';
import {getFoldAccessTokenByAccountId} from '../../../services/CommonService/AidBoxService';
import {Colors} from '../../../styles';
import {ToastType} from '../../../utils/commonViewUtils';
import {EventBus} from '../../../utils/EventBus';
import LocalStorage from '../../../utils/LocalStorage';
import {useToast} from '../../Toast/ToastProvider';
import TickSvg from '../Svg/TickSvg';
import {IUserAccountLocationList} from './interface';

const UserAccountsList = (props: {
  accountUuid: string,
  userAccounts: IUserAccountLocationList[]
}) => {
  const {accountUuid, userAccounts} = props;

  const toast = useToast();
  const intl = useIntl()
  const switchAccount = async (account:IUserAccountLocationList) => {
    const subDomain = account?.accounts?.account_subdomain_maps?.[0]?.sub_domain;
    const accountId = account?.accounts?.id;
    const accessTokenResp = await getFoldAccessTokenByAccountId(accountId);
    const token = accessTokenResp?.data?.token;
    if (subDomain && token) {
      let  redirectString = '';
      if (window.location.host.includes('localhost')) {
         redirectString = `${window.location.origin}/#/switch_account?token=${token}`;
      } else {
        const protocol = window.location.protocol
        redirectString = `${protocol}//${subDomain}/#/switch_account?token=${token}`;
      }
      await LocalStorage.removeItem('user');
      window.location.href = redirectString;;
    } else {
      let message = ''
      if (!token) {
        message  = message + ', token';
      }
      if (!subDomain) {
        message  = message + ', subDomain';
      }
      message = message + 'does not exits';
      toast({
        message: message,
        toastType: ToastType.error,
      });

    }
  };
  return (
    <View style={{
      borderWidth: .5,
      borderColor: Colors.FoldPixel.GRAY150,
      borderStyle: 'solid',
      borderRadius: 12,
      overflow: 'scroll',
      marginBottom: 15,
      maxHeight: 250,
    }}>
      {userAccounts.map((account, index) => {
        return (
            <Pressable
              onPress={() => {
                switchAccount(account)
              }}
              style={{
                paddingHorizontal: 16,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingVertical: 11.5,
                borderBottomWidth: (userAccounts.length -1) == index ? 0 : .5,
                borderColor: Colors.FoldPixel.GRAY150,
                borderStyle: 'solid',
                backgroundColor: account?.accounts?.uuid == accountUuid
                ? Colors.Custom.insuranceBgColor
                : '#fff'
              }}
              key={`${account.accounts?.name}_${index}`}
            >
              <Text
                color={
                  account?.accounts?.uuid == accountUuid
                    ? Colors.FoldPixel.PRIMARY300
                    : Colors.FoldPixel.GRAY400
                }
                size={'smNormal'}
              >
                {account?.accounts.name}
              </Text>
              {account?.accounts?.uuid == accountUuid && <TickSvg />}
            </Pressable>
        );
      })}
    </View>
  );
};

export default UserAccountsList;
