import {View, Text} from 'react-native';
import React from 'react';
import {IAssignCommunicationTypeButtonProps} from './interfaces';
import {Pressable} from 'native-base';
import AssignCommunicationTypeDrawer from './AssignCommunicationTypeDrawer';
import {styles} from './CommunicationTypesStyles';
import { TestIdentifiers, testID } from '../../../../../testUtils';

const AssignCommunicationTypeButton = (
  props: IAssignCommunicationTypeButtonProps
) => {
  const [state, setState] = React.useState({
    isOpen: false,
  });
  const {messageIdUuid, onSave} = props;

  const handleDrawerOpen = (isOpen: boolean) => {
    setState((prev) => {
      return {
        ...prev,
        isOpen,
      };
    });
  };

  return (
    <View>
      <Pressable
        onPress={() => {
          handleDrawerOpen(true);
        }}
      >
        <View style={styles.messageCommunicationTypeWrapper}>
          <Text {...testID(TestIdentifiers.assignType)}>Assign Type</Text>
        </View>
      </Pressable>

      {state.isOpen && (
        <AssignCommunicationTypeDrawer
          isOpen={state.isOpen}
          contactUuid={props.contactUuid}
          messageUuids={[messageIdUuid]}
          onClose={() => {
            handleDrawerOpen(false);
          }}
          onSave={() => {
            handleDrawerOpen(false);
            onSave();
          }}
        />
      )}
    </View>
  );
};

export default AssignCommunicationTypeButton;
