export const ENTITY_EVENTS_TYPE: {[index: string]: {entity: string, eventType: string, isDisableInDetailsPage: boolean, isPersonLevelWorkflow?: boolean}[]} = {
    ADD_TASK: [{
        entity: 'memberTask',
        eventType: 'addOrUpdateTask',
        isDisableInDetailsPage: true
    }, {
        entity: 'memberTask',
        eventType: 'addOrUpdate',
        isDisableInDetailsPage: true
    }],
    ADD_TASK_PROVIDER: [{
        eventType: 'addOrUpdateTask',
        entity: 'userTask',
        isDisableInDetailsPage: true
    }],
    APPOINTMENT_BOOKING: [{
        entity: 'appointment',
        eventType: 'addOrUpdate',
        isDisableInDetailsPage: false
    },{
        eventType: 'add',
        entity: 'appointment',
        isDisableInDetailsPage: false
    }],
    ADD_OR_UPDATE_LEAD: [{
        entity: 'contactPatients',
        eventType: 'add',
        isDisableInDetailsPage: true
    }],
    FORM: [{
        entity: 'Form',
        eventType: 'Submit',
        isDisableInDetailsPage: true
    },
    {
        entity: 'Form',
        eventType: 'Send',
        isDisableInDetailsPage: true
    }],
    EMAIL: [{
        entity: 'Email',
        eventType: 'Send',
        isDisableInDetailsPage: true
    }],
    ARTICAL: [{
        entity: 'Education',
        eventType: 'Send',
        isDisableInDetailsPage: true
    }],
    WORKFLOW_STATIC: [
      {
        entity: 'contact',
        eventType: 'matchingContact',
        isDisableInDetailsPage: false
    }
],
};

export const CUSTOM_AUTOMATION_URL = {
    SCHEDULE_APPOINTMENT: '/admin/schedule/automation/create?currentTab=TRIGGER&flowType=SCHEDULING',
    ADD_TASK: '/admin/communication/automation/create?currentTab=TRIGGER&flowType=COMMUNICATION',
    ADD_PATIENT: '/admin/patients/automation/create?currentTab=TRIGGER&flowType=PATIENTS',
    FORM: '/admin/forms/automation/create?currentTab=TRIGGER&flowType=FORM',
    EMAIL: '/',
    ARTICAL: '/',
};

export const ENTITY_EVENTS_TITLE_SUBTITLE = {
    SCHEDULE_APPOINTMENT_TITLE: 'Do following smart automations after schedule is added',
    ADD_TASK_TITLE: 'Do following smart automations after task is added',
    ADD_OR_UPDATE_LEAD_PATIENT_TITLE: 'Do following smart automations after patient is added',
    ADD_OR_UPDATE_LEAD_EMPLOYEE_TITLE: 'Run following smart automations',
    ARTICAL_WORKFLOW_TITLE: 'Do following smart automations after article is sent',
    FORM_WORKFLOW_TITLE: 'Do following smart automations after form is sent',
    EMAIL_WORKFLOW_TITLE: 'Do following smart automations after email is sent',
   //sub title
    ADD_OR_UPDATE_LEAD_EMPLOYEE_SUB_TITLE: 'Use automations to get automated consents, first visits, scheduled engagements and more',
    SCHEDULE_APPOINTMENT_SUB_TITLE: 'Use automations to send appointment reminders, forms and more',
    ADD_TASK_SUB_TITLE: 'Use automations to create task reminders, escalations and more',
    ADD_OR_UPDATE_LEAD_PATIENT_SUB_TITLE: 'Use automations to send consent forms, request appointment booking and more',
    ARTICAL_WORKFLOW_SUB_TITLE: 'Use automations to send reminders, additional content and more',
    FORM_WORKFLOW_SUB_TITLE: 'Use automations to send reminders, additional forms and more',
    EMAIL_WORKFLOW_SUB_TITLE: 'Use automations to send reminders, additional mails and more',
};

export const EVENT_NAMES = {
    NEW_TASK_ADDED: 'NEW_TASK_ADDED',
    REFRESH_TASK: 'REFRESH_TASK',
    REFRESH_TASK_COLUMN_FOR_PATIENT: 'REFRESH_TASK_COLUMN_FOR_PATIENT',
    UPDATE_PATIENT_LIST_APPOINTMENT_DATA: 'UPDATE_PATIENT_LIST_APPOINTMENT_DATA'
}
