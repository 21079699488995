import {StyleSheet} from 'react-native';
import {Colors} from '../../../../styles';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: 5,
    marginBottom: 5,
  },
  itemContainer: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleText: {
    fontWeight: 'bold',
    fontSize: 15,
    marginTop: 5,
    marginBottom: 5,
    color: Colors.Custom.Gray900,
  },
  dateText: {
    color: Colors.Custom.Gray400,
    fontWeight: 'bold',
    fontSize: 12,
    marginTop: 5,
    marginBottom: 5,
  },
  description: {
    color: Colors.Custom.Gray700,
  },
});
