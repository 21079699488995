import { DurationInputArg2 } from "moment";
import { IAllowedWearableData, IVitalData } from "../../../../../../Interfaces";
import { Vital } from "../../../../../../utils/VitalUtils";
import { GRAPH_TYPES } from "../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailTables/Activities/Activities";

export interface IWearableSettingListItem extends IAllowedWearableData {
  isSelected?: boolean;
  sequence?: number;
}

export interface IWearableSetting {
  [key: string]: IWearableSettingListItem[];
}


export interface ILastRecordedData {
  value: string | number;
  date: string;
}

export const FilterTypes = {
  Day: 'Day',
  Week: 'Week',
  Month: 'Month',
  ThreeMonths: 'ThreeMonths',
  SixMonths: 'SixMonths',
};

export enum SubFilterTypes {
  STAGE,
}

export interface IVitals {
  date: string;
  text: string;
  value: string;
  valueNumber?: number;
  unit: string;
  effectivePeriod?: {
    end: string;
    start: string;
  };
  subtext?: string;
}

export enum GraphsTypes {
  BAR,
  LINE,
  BAR_WITH_SCATTER,
  VERTICAL_SCATTER,
  SPLIT_BAR,
  HORIZONTAL_BAR,
  PIE_CHART,
}

export enum GraphsNodeTypes {
  SYSTOLIC,
  DYSTOLIC,
  SPLIT_BAR,
}

export type Activity = GRAPH_TYPES;

export enum GraphDataOperation {
  AVERAGE = 'AVERAGE',
  LOW_EVENT = 'LOW_EVENT',
  FLAT_PLOT = 'FLAT_PLOT',
}

export type VitalDataOperationByFilterMap = {
  [index in string]: GraphDataOperation;
};

export type VitalDataSubFilterMap = {
  [index in string]: SubFilterTypes[];
};

export enum DataType {
  VITAL = 'VITAL',
  ACTIVITY = 'ACTIVITY',
}

export enum ObservationSourceType {
  wearable = 'wearable',
  form = 'form',
  task = 'task',
}

export enum ObservationSortBy {
  ASC = 'date',
  DESC = '-date',
}

export interface IEffectivePeriod {
  start: string;
  end: string;
}

export interface IGraph {
  x: string;
  y: string | number;
  dateStr: string;
  label?: string;
  dataObj?: IVitals;
  tooltipText?: string;
  y0?: number | string;
  effectivePeriod?: {
    start: string;
    end: string;
  };
  metaData?: {
    titleText?: string;
    subTitleText?: string;
    pieColor?: string;
    colorScale?: string[];
    lineColor?: string;
  };
  subGraphData?: IGraph[];
  subGraphLinesData?: IGraph[];
  graphNodeType?: GraphsNodeTypes;
}
export interface VitalGraphData {
  graphType: GraphsTypes;
  graphData: IGraph[] | any[];
  barData?: IGraph[] | any[];
  title: string;
  loinc: string;
  code: Vital | Activity | any;
  highLightValues: IHighLightValues[];
  dataAvailability: string;
  annontationIndex?: number;
  filterType?: keyof typeof FilterTypes;
  dataType: DataType;
  vitalYAxisLabel: string;
  dataOperation?: GraphDataOperation;
  displayUnit?: string;
  ccmDate?: string;
  source?: string;
  tickLabelFunc?: (data: any) => string;
  tickValues?: string[] | number[];
  config?: IGraphConfig;
  subGraphData?: IGraph[];
  graphInfoView?: JSX.Element;
  subGraphLinesData?: IGraph[];
  xTickValues?: string[] | number[]
}

export interface IGraphConfig {
  barWidth?: number;
  barColorScale?: string[];
  barRadius?: number;
  showStageFilterSwitch?: boolean;
  hideBarTooltip?: boolean;
  barColor?: string;
  barOpacity?: number;
  domainYPadding?: number;
  tickLabelXPadding?: number;
  yAxisLabel?: string;
}

export interface IDateRange {
  start: Date;
  end: Date;
}

export interface IHighLightValues {
  value: string;
  subValueView?: JSX.Element;
  subValue: string;
  hideUnit?: boolean;
  unit?: string;
}

export interface IFilterParams {
  vital: Vital;
  vitalData: IVitals[];
  vitalConfigs: IVitalData[];
  ccmDate?: string | undefined;
  dateRange: IDateRange;
  filterType?: keyof typeof FilterTypes;
  dataOperation?: GraphDataOperation;
  groupBy?: DurationInputArg2;
  subFilterType?: VitalDataSubFilterMap;
  isTrendView?: boolean;
}

export interface IWearableDataFilterParams {
  selectedFilterTab: string;
  graphList: VitalGraphData[];
  filterDataType: string;
  wearableSetting: IWearableSetting;
  filterEmptyGraphData?: boolean;
}


export interface ISleepStage {
  [key: string]: {
    sleepStages: {
      [key: string]: {value: string; code: string; subtext: string};
    };
    effectivePeriod?: IEffectivePeriod;
    totalValue: number;
  };
}
