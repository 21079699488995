import React from "react";
import { Path, Svg } from "react-native-svg";
import { ICommonSvgProps } from "./interfaces";

const Chevron = (props: ICommonSvgProps)=> {
  return (
    <Svg
      width={props.size ||  "10"}
      height={props.size || "11"}
      fill="none"
      viewBox="0 0 10 11"
    >
      <Path
        fill="#6F7A90"
        stroke="#6F7A90"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7 5.5l-4-4v8l4-4z"
      ></Path>
    </Svg>
  );
}

export default Chevron;
