import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  // common styles
  container: {
    paddingTop: 12,
    paddingBottom: 12,
  },
  titleText: {},
  titleView: {
    marginBottom: 8,
  },
});
