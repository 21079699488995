import parse from 'html-react-parser';
import {View} from 'native-base';
import {ViewStyle} from 'react-native';
import {styles} from './EmailPreviewStyles';

export interface IEmailPreviewProps {
  htmlString: string;
  containerStyle?: ViewStyle;
}

const EmailPreview = (props: IEmailPreviewProps) => {
  const {htmlString} = props;

  return (
    <View style={[styles.container, props.containerStyle]}>
      <View
        style={{
          width: '100%',
        }}
      >
        {parse(htmlString)}
      </View>
    </View>
  );
};

export default EmailPreview;
