
import React from 'react';
import { Path, Rect, Svg } from 'react-native-svg';

const HorizantalLineSvg = () => {
    return (
        <Svg width="20" height="2" viewBox="0 0 20 2" fill="none">
            <Rect y="0.75" width="20" height="0.5" fill="#D0D6E1" />
        </Svg>

    );
};

export default HorizantalLineSvg;