import {useLazyQuery} from '@apollo/client';
import {FlatList, Skeleton, Text, View, VStack} from 'native-base';
import React, {useEffect, useState} from 'react';
import {DATE_FORMATS} from '../../../constants';
import StickyNotesApis from '../../../services/StickyNotes/StickyNotesApis';
import {Colors} from '../../../styles';
import {getAccountUUID} from '../../../utils/commonUtils';
import {getDateAndTimeOnLocalFormate} from '../../../utils/DateUtils';
import {IStickyNotesVersion} from './interface';

const StickyNotesVersion = (props: IStickyNotesVersion) => {
  const {noteUuid} = props;
  const accountUuid = getAccountUUID();
  const [stateData, setStateData] = useState({
    notesList: [] as any[],
    loading: false,
    page: 1,
    offSet: 0,
    pageLimit: 10,
    isData: true
  });

  const [GetNotesVersionList] = useLazyQuery(
    StickyNotesApis.GetStickNoteVersionByNoteUuid,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );

  const getStickyNotesList = async () => {

    const tempStateData = {...stateData};
    const pageNumber = tempStateData.page;
    const pageLimit = tempStateData.pageLimit;
    const offset = pageNumber * pageLimit - pageLimit;
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const response = await GetNotesVersionList({
      variables: {
        noteUuid: noteUuid,
        accountUuid: accountUuid,
        limit: tempStateData.pageLimit,
        offset: offset || 1
      },
    }).catch(() => {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    });
    if (response?.data?.contactNoteVersions?.length) {
      const notesList = response?.data?.contactNoteVersions;
      const updatedList = [...stateData.notesList, ...notesList]
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
          notesList: updatedList,
          isData: true
        };
      });
      if(response?.data?.contactNoteVersions?.length < 10) {
        setStateData((prev) => {
          return {
            ...prev,
            isData: false
          };
        });
      }
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
          isData: false
        };
      });
    }
    setStateData(prev=> {
      return {
        ...prev,
        page: pageNumber + 1,
      }
    })
  };
  useEffect(() => {
    if (noteUuid)
    getStickyNotesList();
  }, []);
  return (
    <View height={props?.height || undefined}>
      <FlatList
        style={{
          maxHeight: 500,
          flex: 1,
          overflow: 'scroll',
        }}
        onEndReachedThreshold={0.01}
        onEndReached={({distanceFromEnd}) => {
          if(stateData.isData){
            getStickyNotesList()
          }
        }}

        ListFooterComponent={stateData.loading  ? <Skeleton.Text lines={4}></Skeleton.Text> : <></>}
        data={stateData.notesList}
        renderItem={({item}) => (
          <VStack flex={1} justifyContent={'center'}>
            <Text size={'mdSemibold'} color={Colors.Custom.Gray900}>
              {item?.note}
            </Text>
            <View
              style={{
                flexDirection: 'row',
                marginTop: 10,
                alignItems: 'center',
              }}
            >
              <Text style={{color: Colors.Custom.Gray500}} size={'smMedium'}>
                {item?.updatedByUser?.name} •{' '}
              </Text>
              <Text style={{color: Colors.Custom.Gray500}} size={'smMedium'}>
                {getDateAndTimeOnLocalFormate(
                  item?.updatedAt,
                  DATE_FORMATS.STICKY_NOTE_DATE
                )}
              </Text>
            </View>
          </VStack>
        )}
        ItemSeparatorComponent={({highlighted}) => (
          <View
            style={[
              {
                height: 1,
                backgroundColor: Colors.Custom.BorderColor,
                // marginLeft: 60,
                marginVertical: 20,
              },
              highlighted && {marginLeft: 0},
            ]}
          />
        )}
        keyExtractor={(item, index) =>
          `StickyNoteList_${item.noteUuid}_${index}`
        }
      />
    </View>
  );
};

export default React.memo(StickyNotesVersion);
