import React from 'react';
import {usePermissions} from '../../CustomHooks/usePermissions';
import {USER_ACCESS_PERMISSION} from '../../RightSideContainer/UserAccess/UserAccessPermission';

const FoldPermitCan = (props: {
  resource: string;
  action: string;
  component: JSX.Element;
}) => {
  const {check} = usePermissions()
  const {action, resource, component} = props;
  const resourceCode = action ? action : USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code
  const actionCode = resource
  const isAllowed = check(resourceCode,actionCode,'').hasAccess
  return (
    <>
      {isAllowed ? (
        component
      ) : (
        <></>
      )}
    </>
  );
};

export default FoldPermitCan;
