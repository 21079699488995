import React from "react";
import { Path, Svg } from "react-native-svg";

function HighPriorityIcon() {
  return (
    <Svg
      width="14"
      height="10"
      fill="none"
      viewBox="0 0 14 10"
      id="HighPriorityIcon"
    >
      <Path
        id="HighPriorityIcon_Path_0"
        stroke="#D92D20"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M1.4 4L7 1.2 12.6 4M1.4 8.8L7 6l5.6 2.8"
      ></Path>
    </Svg>
  );
}

export default React.memo(HighPriorityIcon);
