import {HStack, Text} from 'native-base';
import {IPatientHIEStatus, IPatientHieResponse} from './Interface';
import {useEffect, useState} from 'react';
import {getPatientHieStatus} from '../../../services/Patient/PatientHieStatus';
import {Button} from 'antd';
import PatientHIEStatusDetailModal from './PatientHIEStatusDetailModal';
import {COMMON_ACTION_CODES} from '../../../constants/ActionConst';
import Feather from 'react-native-vector-icons/Feather';
import {Colors} from '../../../styles';

const PatientHIEStatusView = (props: IPatientHIEStatus) => {
  const {patientData} = props;
  const [patientHIESyncStatus, setPatientHIESyncStatus] = useState<IPatientHieResponse>({} as IPatientHieResponse);
  const [patientHIESyncModal, setPatientHIESyncModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const patientHieStatus = async (patientId: string) => {
    const patientHieStatusResp = await getPatientHieStatus(patientId);
    if (
      patientHieStatusResp &&
      patientHieStatusResp?.data &&
      !patientHieStatusResp?.data?.patientRegistered
    ) {
      setLoading(false);
      setPatientHIESyncStatus(patientHieStatusResp.data);
    } else {
      setLoading(true);
      setPatientHIESyncStatus(patientHieStatusResp.data);
    }
  };

  useEffect(() => {
    if (patientData?.patientId) {
      patientHieStatus(patientData?.patientId);
    }
  }, [patientData?.patientId]);
  const onActionPerfomed = (code: string, data?: any) => {
    if (code === COMMON_ACTION_CODES.CLOSE) {
      setPatientHIESyncModal(false);
    }
  };

  return loading ? (
    <></>
  ) : (
    <HStack>
      <HStack>
        <Button
          type="text"
          danger
          onClick={() => {
            setPatientHIESyncModal(true);
          }}
          style={{color: Colors.Custom.Danger700}}
        >
          <Feather
            name="alert-triangle"
            size={15}
            color={Colors.Custom.Danger700}
          />
          <Text style={{marginLeft: 5, color: Colors.Custom.Danger700}}>
            Sync Error
          </Text>
        </Button>
      </HStack>
      <PatientHIEStatusDetailModal
        onActionPerfomed={onActionPerfomed}
        isOpen={patientHIESyncModal}
        patientHieResponseData={patientHIESyncStatus}
      ></PatientHIEStatusDetailModal>
    </HStack>
  );
};

export default PatientHIEStatusView;
