import {useMutation} from '@apollo/client';
import {Spin} from 'antd';
import {Divider, HStack, Pressable, useToast, VStack} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {CONFIG_CODES} from '../../../../../../constants/AccountConfigConst';
import {CONVERSATION_ACTION_CODES} from '../../../../../../constants/ActionConst';
import {
  GROUP_TYPE_CODES,
  MLOV_CATEGORY,
} from '../../../../../../constants/MlovConst';
import {GROUP_MEMBER_TYPE} from '../../../../../../constants/StringConst';
import {CommonDataContext} from '../../../../../../context/CommonDataContext';
import BaseService from '../../../../../../services/CommonService/BaseService';
import ConversationsQueries from '../../../../../../services/Conversations/ConversationsQueries';
import {
  getAccountId,
  getUserData,
  getUserUUID,
  isEmployerRole,
} from '../../../../../../utils/commonUtils';
import {showToast, ToastType} from '../../../../../../utils/commonViewUtils';
import {isAccountConfigEnabled} from '../../../../../../utils/configUtils';
import {getDateToMomentISOString} from '../../../../../../utils/DateUtils';
import {
  getMlovIdFromCode,
  getMlovListFromCategory,
} from '../../../../../../utils/mlovUtils';
import {
  ICreateGroupBodyParams,
  ISearchExistingGroupBodyParams,
} from '../../../../../common/CreateChannelView/interface';
import {DisplayText} from '../../../../../common/DisplayText/DisplayText';
import PatientSearchPopup from './PatientSearchPopup';

const ALL_OPT_LIST = [
  CONVERSATION_ACTION_CODES.CHAT_WITH_PATIENT,
  CONVERSATION_ACTION_CODES.START_GROUP_CHAT,
  CONVERSATION_ACTION_CODES.START_SMS,
  CONVERSATION_ACTION_CODES.START_EMAIL,
  CONVERSATION_ACTION_CODES.CHAT_WITH_INTERNAL_USERS,
];

const CreateAllGroupPopup = (props: {
  openCount: number;
  selectedTabCode: string;
  selectedGroupCode?: string;
  onCreateGroupPopupActionPerformed: (
    actionCode: string,
    actionData?: any
  ) => void;
  displayTabCodes?: string[];
  contactData?: any;
}) => {
  const createGroupToast = useToast();
  const intl = useIntl();
  const accountId = getAccountId();
  const userUuid = getUserUUID();
  const commonData = useContext(CommonDataContext);
  const currentUserData = getUserData();
  const displayTabCode = props?.displayTabCodes || ALL_OPT_LIST;
  const [createGroupState, setCreateGroupState] = useState({
    selectedGroupCode: '',
    isLoading: false,
  });
  const isPatientMessagingEnabled = isAccountConfigEnabled(CONFIG_CODES.PATIENT_MESSAGING_ENABLED);
  const {openCount, onCreateGroupPopupActionPerformed, contactData} = props;
  const isPatientLoggedIn = contactData?.additionalAttributes?.loggedInTime ?  true : false
  let displayTabCodeList = displayTabCode.filter((tab)=>{
    if (
      tab === CONVERSATION_ACTION_CODES.CHAT_WITH_PATIENT ||
      tab === CONVERSATION_ACTION_CODES.START_GROUP_CHAT
    ) {
      if (isPatientLoggedIn) {
        return tab;
      }
    } else {
      return tab;
    }
  })
  if (!isPatientMessagingEnabled) {
    displayTabCodeList = displayTabCodeList.filter((tab)=> {
      return (tab !== CONVERSATION_ACTION_CODES.CHAT_WITH_PATIENT &&
        tab !== CONVERSATION_ACTION_CODES.START_GROUP_CHAT)
    })
  }
  useEffect(() => {
    setCreateGroupState((prev) => {
      return {
        ...prev,
        selectedGroupCode: props?.selectedGroupCode || '',
      };
    });
  }, [openCount]);

  const getChatWithPatientElem = () => {
    return (
      <Pressable
        onPress={() => {
          if (contactData?.uuid) {
            findExistingGroupWithSamePatient(contactData);
          } else {
            setCreateGroupState((prev) => {
              return {
                ...prev,
                selectedGroupCode: CONVERSATION_ACTION_CODES.CHAT_WITH_PATIENT,
              };
            });
          }
        }}
      >
        <HStack p={4}>
          <DisplayText textLocalId={'chatWithPatient'} />
        </HStack>
      </Pressable>
    );
  };

  const getStartGroupChatElem = () => {
    return (
      <Pressable
        onPress={() => {
          onCreateGroupPopupActionPerformed(
            CONVERSATION_ACTION_CODES.START_GROUP_CHAT
          );
        }}
      >
        <HStack p={4}>
          <DisplayText textLocalId="startGroupChat" />
        </HStack>
      </Pressable>
    );
  };

  const getStartSmsElem = () => {
    return (
      <Pressable
        onPress={() => {
          onCreateGroupPopupActionPerformed(
            CONVERSATION_ACTION_CODES.START_SMS
          );
        }}
      >
        <HStack p={4}>
          <DisplayText textLocalId="sendSms" />
        </HStack>
      </Pressable>
    );
  };

  const getStartEmailElem = () => {
    return (
      <Pressable
        onPress={() => {
          onCreateGroupPopupActionPerformed(
            CONVERSATION_ACTION_CODES.START_EMAIL
          );
        }}
      >
        <HStack p={4}>
          <DisplayText textLocalId="sendEmail" />
        </HStack>
      </Pressable>
    );
  };

  const getChatWithInternalUsersElem = () => {
    return (
      <Pressable
        onPress={() => {
          onCreateGroupPopupActionPerformed(
            CONVERSATION_ACTION_CODES.CHAT_WITH_INTERNAL_USERS
          );
        }}
      >
        <HStack p={4}>
          <DisplayText textLocalId={isEmployerRole() ? 'newChat' : "newInternalChat"} />
        </HStack>
      </Pressable>
    );
  };

  const findExistingGroupWithSamePatient = async (patientData: any) => {
    setCreateGroupState((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });
    const baseService = BaseService.getSharedInstance().axios;
    const searchGroupWithExistUserUrl = `/crm-nest/account/${accountId}/search-group-with-exist-user`;
    const bodyData: ISearchExistingGroupBodyParams = {
      groupTypeCode: GROUP_TYPE_CODES.PRIVATE,
      userIds: [userUuid],
      contactIds: [patientData?.uuid || ''],
    };
    const groupListInfo = await baseService
      .post(searchGroupWithExistUserUrl, bodyData, {
        headers: {
          api_access_token: currentUserData.access_token,
        },
      })
      .catch(() => {
        setCreateGroupState((prev) => {
          return {
            ...prev,
            isLoading: false,
          };
        });
      });
    if (groupListInfo?.data?.length) {
      onCreateGroupPopupActionPerformed(
        CONVERSATION_ACTION_CODES.ON_PATIENT_SELECTION,
        groupListInfo?.data?.[0]
      );
      setCreateGroupState((prev) => {
        return {
          ...prev,
          isLoading: false,
        };
      });
    } else {
      createGroupConversation(patientData);
    }
  };

  const createGroupConversation = async (patientData: any) => {
    const groupMemberTypeList =
      getMlovListFromCategory(commonData.MLOV, MLOV_CATEGORY.GroupMemberType) ||
      [];
    const userTypeId = getMlovIdFromCode(
      groupMemberTypeList,
      GROUP_MEMBER_TYPE.USER
    );
    const baseService = BaseService.getSharedInstance().axios;
    const createGroupUrl = `/crm-nest/account/${accountId}/group`;

    const bodyData: ICreateGroupBodyParams = {
      groupType: GROUP_TYPE_CODES.PRIVATE,
      groupName: '',
      groupData: {
        userIds: [userUuid],
        contactId: patientData.uuid,
        familyMemberIds: [],
      },
    };
    const groupInfo = await baseService
      .post(createGroupUrl, bodyData, {
        headers: {
          ['user-type-id']: userTypeId,
          api_access_token: currentUserData.access_token,
        },
      })
      .catch(() => {
        showToast(
          createGroupToast,
          intl.formatMessage({id: 'apiErrorMsg'}),
          ToastType.error
        );
        setCreateGroupState((prev) => {
          return {
            ...prev,
            isLoading: false,
          };
        });
      });

    if (groupInfo?.data?.uuid) {
      showToast(
        createGroupToast,
        intl.formatMessage({id: 'groupCreatedMsg'}),
        ToastType.success
      );
      groupInfo.data.conversationUuid = groupInfo?.data?.uuid;
      onCreateGroupPopupActionPerformed(
        CONVERSATION_ACTION_CODES.ON_PATIENT_SELECTION,
        groupInfo.data
      );
      setCreateGroupState((prev) => {
        return {
          ...prev,
          isLoading: false,
        };
      });
    } else {
      setCreateGroupState((prev) => {
        return {
          ...prev,
          isLoading: false,
        };
      });
      showToast(
        createGroupToast,
        intl.formatMessage({id: 'apiErrorMsg'}),
        ToastType.error
      );
    }
  };

  const getDefaultCreateGroupPopup = () => {
    const allList = displayTabCodeList.map((code: string, index: number) => {
      let optElem = <></>;
      switch (code) {
        case CONVERSATION_ACTION_CODES.CHAT_WITH_PATIENT:
          optElem = getChatWithPatientElem();
          break;
        case CONVERSATION_ACTION_CODES.START_GROUP_CHAT:
          optElem = getStartGroupChatElem();
          break;
        case CONVERSATION_ACTION_CODES.START_SMS:
          optElem = getStartSmsElem();
          break;
        case CONVERSATION_ACTION_CODES.START_EMAIL:
          optElem = getStartEmailElem();
          break;
        case CONVERSATION_ACTION_CODES.CHAT_WITH_INTERNAL_USERS:
          optElem = getChatWithInternalUsersElem();
          break;
        default:
          optElem = <></>;
          break;
      }

      if (index === displayTabCodeList.length - 1) {
        return optElem;
      } else {
        return (
          <>
            {optElem}
            <Divider />
          </>
        );
      }
    });
    return <VStack width={300}>{allList}</VStack>;
  };

  const renderCreateGroupPopup = () => {
    if (
      createGroupState.selectedGroupCode ===
      CONVERSATION_ACTION_CODES.CHAT_WITH_PATIENT
    ) {
      return (
        <PatientSearchPopup
          onPatientSearchPopupActionPerformed={(actionCode, patientData) => {
            onCreateGroupPopupActionPerformed(actionCode, patientData);
            setCreateGroupState((prev) => {
              return {
                ...prev,
                selectedGroupCode: '',
              };
            });
          }}
          width={300}
        />
      );
    } else {
      return getDefaultCreateGroupPopup();
    }
  };
  return (
    <>
      <Spin size="large" spinning={createGroupState.isLoading}>
        {renderCreateGroupPopup()}
      </Spin>
    </>
  );
};

export default CreateAllGroupPopup;
