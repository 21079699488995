import React from 'react';
import {isWeb} from '../../../utils/platformCheckUtils';
import {Image} from 'native-base';

const LocationPin = () => {
  return (
    <>
      {isWeb() ? (
        <svg
          width="16"
          height="22"
          viewBox="0 0 16 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.96758 0C3.57423 0 0 3.57423 0 7.96753C0 13.4198 7.1302 21.4239 7.43377 21.762C7.71891 22.0796 8.21675 22.079 8.50138 21.762C8.80495 21.4239 15.9352 13.4198 15.9352 7.96753C15.9351 3.57423 12.3609 0 7.96758 0ZM7.96758 11.9762C5.75717 11.9762 3.95893 10.1779 3.95893 7.96753C3.95893 5.75713 5.75722 3.95889 7.96758 3.95889C10.1779 3.95889 11.9762 5.75717 11.9762 7.96758C11.9762 10.178 10.1779 11.9762 7.96758 11.9762Z"
            fill="#98A2B3"
          />
        </svg>
      ) : (
        <Image
          resizeMode="contain"
          size={'24px'}
          source={require('../../../assets/images/Contact/addressInfo.png')}
          alt="image"
        />
      )}
    </>
  );
};

export default LocationPin;
