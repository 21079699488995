import {useMutation} from '@apollo/client';
import {Editor} from '@toast-ui/react-editor';
import {Text, useToast, View} from 'native-base';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {BUTTON_TYPE} from '../../../../../../constants';
import {COMMON_ACTION_CODES} from '../../../../../../constants/ActionConst';
import ConversationsQueries from '../../../../../../services/Conversations/ConversationsQueries';
import {Colors} from '../../../../../../styles';
import {showToast, ToastType} from '../../../../../../utils/commonViewUtils';
import {FoldButton} from '../../../../../CommonComponents/FoldButton/FoldButton';
import {IScheduleEditView} from './interface';
import './scheduled-list-style.css';
import { StyleSheet } from 'react-native';

const ScheduleEditView = (props: IScheduleEditView) => {
  const {message} = props
  const editorRef: any = React.createRef();
  const [stateData, setStateData] = useState({
    emptyError: false
  })
  const msgToast = useToast();
  const intl = useIntl()
  const [UPDATE_SCHEDULED_MESSAGE] = useMutation(ConversationsQueries.UPDATE_SCHEDULED_MESSAGE);
  const onSaveAction = async () => {
    const scheduleMessageId =  message.id;
    const inst = editorRef.current.getInstance();
    const plainString = inst.getMarkdown();
    if (plainString.length < 1) {
      setStateData(prev=> {
        return {
          ...prev,
          emptyError: true
        }
      })
      return
    }
    const updateScheduledMessageResponse = await UPDATE_SCHEDULED_MESSAGE({
      variables: {
        content: plainString,
        id: scheduleMessageId
      }
    }).catch(()=> {
      props.onFormCompleteAction?.(COMMON_ACTION_CODES.CANCEL, message);
      showToast(
        msgToast,
        intl.formatMessage({id: 'errorOnUpdateSchedule'}),
        ToastType.error
      );
    })
    if (updateScheduledMessageResponse?.data?.updateMessageSchedules?.returning?.length) {
      message.content = plainString
      props.onFormCompleteAction?.(COMMON_ACTION_CODES.SAVE, message);
      showToast(
        msgToast,
        intl.formatMessage({id: 'successScheduleMessage'}),
        ToastType.success
      );
    } else {
      props.onFormCompleteAction?.(COMMON_ACTION_CODES.CANCEL, message);
      showToast(
        msgToast,
        intl.formatMessage({id: 'errorOnUpdateSchedule'}),
        ToastType.error
      );
    }
  }
  return (
    <View style={styles.marginTop}>
      {stateData.emptyError && (
        <Text color={Colors.danger['500']} size={'smMedium'}>{intl.formatMessage({id: 'required'})}</Text>
      )}
      <View nativeID="toast-ui-scheduled-list-edit">
        <Editor
          placeholder=""
          initialValue={message.content}
          height="90px"
          initialEditType="wysiwyg"
          useCommandShortcut={true}
          previewHighlight={false}
          toolbarItems={[]}
          ref={editorRef}
          viewer={true}
          onChange={() => {
            if (editorRef && editorRef?.current) {
              const inst = editorRef.current.getInstance();
              const plainString = inst.getMarkdown();
              if (plainString.length) {
                setStateData(prev=> {
                  return {
                    ...prev,
                    emptyError: false
                  }
                })
              }
            }
          }}
        />
      </View>
      <View style={styles.buttonContainer}>
        <FoldButton
          nativeProps={{
            variant: BUTTON_TYPE.SECONDARY,
            style: {margin: 3},
            onPress: () => {
              props.onFormCompleteAction?.(COMMON_ACTION_CODES.EDIT, message);
            },
          }}
          customProps={{
            btnText: intl.formatMessage({
              id: 'cancel',
            }),
            withRightBorder: false,
          }}
        />
        <FoldButton
          nativeProps={{
            variant: BUTTON_TYPE.PRIMARY,
            style: {margin: 3, marginLeft: 6},
            onPress: () => {
              onSaveAction();
            },
          }}
          customProps={{
            btnText: intl.formatMessage({
              id: 'save',
            }),
            withRightBorder: false,
          }}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 8,
  },
  marginTop: {marginTop: 4}
});

export default ScheduleEditView;
