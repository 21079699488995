import React from 'react';
import ReactDOM from 'react-dom';
import {ReactComponent} from '@foldhealth/formio-react';
import {Formio} from '@foldhealth/formiojs';
import CustomWrapper from '../CustomWrapper/CustomWrapper';
import KeyField from '../../EditFormFields/KeyField';
import ConditionalFields from '../../EditFormFields/ConditionalFields';
import CustomClassComponent from '../CustomWrapper/CustomClassComponent';
import { FormComponentLabelEnum } from '../../FormEnum';
import MacroTemplateField from '../../EditFormFields/MacroTemplateField';
import ShareWithPatientFields from '../../EditFormFields/ShareWithPatientFields';
import QuillConfig from '../../Builder/QuillConfig';
import SearchableComponentFields from '../../EditFormFields/SearchableComponentFields';
import TextFieldComponent from './TextFieldComponent';
import PrefixSuffixFields from '../../EditFormFields/PrefixSuffixFields';

export default class TextFieldFormComponent extends CustomClassComponent {
  static get builderInfo() {
    return {
      title: FormComponentLabelEnum.textfield as string,
      icon: 'calendar',
      group: 'advanced',
      documentation: '',
      schema: TextFieldFormComponent.schema(),
    };
  }

  labelIsHidden() {
    return false;
  }

  static schema(...extend: any) {
    return ReactComponent.schema({
      label: FormComponentLabelEnum.textfield,
      key: 'textField',
      type: 'textfield',
      mask: false,
      inputType: 'text',
      inputFormat: 'plain',
      inputMask: '',
      displayMask: '',
      tableView: true,
      spellcheck: true,
      truncateMultipleSpaces: false,
      validate: {
        minLength: '',
        maxLength: '',
        pattern: '',
        customMessage: 'Field is required',
      },
    });
  }

  attachReact(element: any) {
    return ReactDOM.render(
      <CustomWrapper formOptionData={this.options}>
        <TextFieldComponent
          options={this.options}
          disabled={this.disabled}
          component={this.component}
          validateRef={this.validateRef}
          onChange={this.updateValue}
          setValueRef={this.setValueRef}
        />
      </CustomWrapper>,
      element
    );
  }

  static editForm = function () {
    return {
      key: 'display',
      components: [
        {
          type: 'oldtextfield',
          key: 'label',
          label: 'Label',
          input: true,
          validate: {
            required: true,
          },
        },
        {
          type: 'checkbox',
          key: 'validate.required',
          label: 'Is this field required?',
          input: true,
        },
        ...SearchableComponentFields,
        ...MacroTemplateField,
        {
          type: 'oldtextfield',
          input: true,
          key: 'placeholder',
          label: 'Placeholder',
          placeholder: 'Placeholder',
          tooltip: 'The placeholder text that will appear when this field is empty.',
        },
        {
          type: 'textarea',
          input: true,
          key: 'description',
          label: 'Description',
          placeholder: 'Description for this field.',
          tooltip: 'The description is text that will appear below the input field.',
          editor: 'quill',
          wysiwyg: QuillConfig,
        },
        ...ShareWithPatientFields,
        ...PrefixSuffixFields,
        // ...ValidationFields,
        // KEPT FOR FUTURE
        // {
        //   type: 'checkbox',
        //   label: 'Hide Label',
        //   tooltip:
        //     'Hide the label or title of this component. This allows you to show the label in the form builder, but not when the form is shown to the member.',
        //   key: 'hideLabel',
        //   input: true,
        //   customConditional: function customConditional(context: any) {
        //     return canShowHideLabelOption(context);
        //   },
        // },
        ...ConditionalFields,
        ...KeyField,
      ],
    };
  };
}

Formio.Components.addComponent('textfield', TextFieldFormComponent);
