import React from 'react';
import {MedicationRequest, RequestGroup} from 'fhir/r4';
import {Divider, HStack, Text, View, VStack} from 'native-base';
import {ORDER_TYPE} from '../../../../../constants';
import DetailPreview from '../../PersonDetailsView/DetailPreview/DetailPreview';
import {
  getFacilityName,
  getMedicationOrderName,
  getMedicationSubTitle,
  getOrderName,
  getOrderTitleSubtitleForPrint,
} from './OrderUtils';
import {Colors} from '../../../../../styles';
import {
  IReportAndResourceMap,
  IRequestGroup,
} from '../../../LeftContainer/RecentActivity/OrdersInterface';
import IndividualOrderView from './IndividualOrderView/IndividualOrderView';

const OrderListPreview = (props: {
  orderLabel: string;
  orderList: any[];
  type: string;
  renderActions?: (
    order: RequestGroup | MedicationRequest,
    report?: IReportAndResourceMap
  ) => JSX.Element;
  personData?: any;
}) => {
  const {personData} = props;
  const getOrderCard = (order: IRequestGroup) => {
    const report = order?.action?.[0]?.resource?.report;
    return (
      <div className="page-break">
        <HStack py={1}>
          <VStack py={1} flex={8}>
            <HStack>
              <Text fontWeight={500} fontSize={14}>
                {getFacilityName(order as RequestGroup) ||
                  'No facility selected'}
              </Text>
              <HStack
                alignItems="center"
                flex={1}
                justifyContent="flex-end"
                space={1}
              >
                {props.renderActions &&
                  props.renderActions(order as RequestGroup, report)}
              </HStack>
            </HStack>
            <Divider marginY={2} background="black" />
            <VStack space={2}>
              {order.action?.map((action, index) => {
                return (
                  <IndividualOrderView
                    isPreviewMode={true}
                    key={index}
                    order={order as RequestGroup}
                    action={action}
                    report={action.resource?.report}
                    personData={personData}
                  />
                );
              })}
            </VStack>
            {(order.action || [])?.length > 0 && <Divider background="black" />}
            <Text fontSize={14} fontWeight={500}>
              {getOrderTitleSubtitleForPrint(order as RequestGroup)}
            </Text>
          </VStack>
        </HStack>
      </div>
    );
  };

  const getErxOrderCard = (order: MedicationRequest) => {
    return (
      <div className="page-break">
        <HStack py={1} flex={8}>
          <VStack>
            <Text fontSize={16} fontWeight={700} key={`${order.id}`}>
              {getMedicationOrderName(order)}
            </Text>
            <Text fontSize={16} fontWeight={500}>
              {getMedicationSubTitle(order as MedicationRequest)}
            </Text>
          </VStack>
          <HStack
            alignItems="center"
            flex={1}
            justifyContent="flex-end"
            space={1}
          >
            {props.renderActions &&
              props.renderActions(order as MedicationRequest)}
          </HStack>
        </HStack>
      </div>
    );
  };
  switch (props.type) {
    case ORDER_TYPE.LAB:
      return (
        <DetailPreview titleLocalId={props.orderLabel}>
          <>
            {(props.orderList || [])?.map((order: any) => {
              return getOrderCard(order);
            })}
          </>
        </DetailPreview>
      );
    case ORDER_TYPE.RAD:
      return (
        <DetailPreview titleLocalId={props.orderLabel}>
          <>
            {(props.orderList || [])?.map((order: any) => {
              return getOrderCard(order);
            })}
          </>
        </DetailPreview>
      );
    case ORDER_TYPE.MED:
      return (
        <div className="page-break page-margin-left page-margin-right">
          <DetailPreview titleLocalId={props.orderLabel}>
            <>
              {props.orderList.map((lab: any) => {
                return getErxOrderCard(lab);
              })}
            </>
          </DetailPreview>
        </div>
      );
    default:
      return <></>;
  }
};

export default OrderListPreview;
