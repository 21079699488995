import {HStack, Skeleton, Spacer} from 'native-base';
import React from 'react';

const LoadingIndicator = () => {
  return (
    <HStack justifyContent="space-between" px={4} my={1}>
      <Skeleton.Text lines={2} flex={6} />
    </HStack>
  );
};

export default LoadingIndicator;
