import {useLazyQuery} from '@apollo/client';
import {Menu, Pressable, ScrollView, Skeleton, Text, View} from 'native-base';
import React, {useEffect, useState} from 'react';
import {GROUP_MEMBER_TYPE} from '../../../../../constants';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import {HEADER_TITLE_TYPES} from '../../../../../constants/ConstantValues';
import {LeadQueries} from '../../../../../services';
import ConversationsQueries from '../../../../../services/Conversations/ConversationsQueries';
import {Colors} from '../../../../../styles';
import {getAccountId, getAllowedUserPracticeLocationUuids, getPrimaryGenderCode, getUserUUID, isChannelEmail, isChannelTwillioSms} from '../../../../../utils/commonUtils';
import {isWeb} from '../../../../../utils/platformCheckUtils';
import {DisplayCardAvatar} from '../../../../common/DisplayCard/DisplayCardAvatar';
import {IContact, IConversationData} from '../interfaces';
import { withMiniContactViewHOC } from '../../../../MiniContactViewHOC';
import { CustomHeaderPatientSubtitle } from '../../../../NativeComponents/CustomHeaderPatientSubtitle';
import { getCurrentPatientDeceasedData } from '../../../../../services/CommonService/AidBoxService';
import { getEhrConfig } from '../../../../../utils/capabilityUtils';
import { Keyboard } from 'react-native';
import { TestIdentifiers, testID } from '../../../../../testUtils';
import { Popover } from 'antd';
import { useIntl } from 'react-intl';
import { CONFIG_CODES } from '../../../../../constants/AccountConfigConst';
import { isAccountConfigEnabled } from '../../../../../utils/configUtils';
import { MAIN_MENU_CODES } from '../../../../SideMenuBar/SideBarConst';
import { USER_ACCESS_PERMISSION } from '../../../UserAccess/UserAccessPermission';
import { StyleSheet } from 'react-native';

interface IMemberListByContactValue {
  contactData: IContact;
  channelType: string;
  conversationUuid:string;
  onNavigateToContact?: (contactData:any) => void;
  navigate?:any;
  onCallBackAction?:(code:string,data?:any) => void
  selectedConversation?: IConversationData;
  navigateOrOpenContactIdDrawer?:(contactId?:string, additionalData?:any) => void;
  deceasedDate?:string
}

const MemberListByContactValue = (props: IMemberListByContactValue) => {
  const {contactData,channelType,conversationUuid,onNavigateToContact,navigate,onCallBackAction} = props;
  const accountId = getAccountId();
  const userUuid = getUserUUID();
  const intl = useIntl();
  const isCommunicationLocationHandlingEnabled = isAccountConfigEnabled(
    CONFIG_CODES.ENABLE_COMMUNICATION_LOCATION_HANDLING
  );
  const allowedUserPracticeLocationUuids = getAllowedUserPracticeLocationUuids(
    USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
    MAIN_MENU_CODES.INBOX
  );

  const [stateData, setStateData] = useState({
    contacts: [] as any,
    loading: false,
    showMoreContactsPopup: false,
  });
  const[deceasedDate,setDeceasedDate] = useState('')
  const showMemberCount = (isWeb() ? 2 : 1);

  const [getContactById] = useLazyQuery(LeadQueries.GetContact, {
    fetchPolicy: 'no-cache',
  })

  const [getMemberByContact] = useLazyQuery(
    ConversationsQueries.getMemberByContact,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const routeToContactById = async (contactData: any) => {
    if(isWeb()) {
      const type = contactData.contactType
      ? contactData?.contactType?.contactType?.value
      : '';
    const contactId = contactData?.id;
    if (type == 'Customer') {
      props?.navigateOrOpenContactIdDrawer?.(contactId);
    } else if (type == 'Lead') {
      navigate(`/members/prospect/${contactId}`);
    } else {
      navigate(`/members/supporting-memeber/${contactId}`);
    }
    } else {
      const contactResp = await getContactById({
        variables: {
          id: contactData?.id,
        }
      })
      const contact = contactResp?.data?.contact
      onNavigateToContact?.(contact)
  }
  };
  const isSmsInbox = isChannelTwillioSms(channelType);
  const isEmailInbox = isChannelEmail(channelType)
  const ehrConfig = getEhrConfig(
    contactData?.contactPracticeLocations?.[0]?.uuid,
    ''
  );

  const getPatientDeceasedData = async (contactData: IContact) => {
  const deceasedDatePatient = await getCurrentPatientDeceasedData(contactData);
    setDeceasedDate(deceasedDatePatient);
  };
  useEffect(() => {
    ehrConfig.isAthena && getPatientDeceasedData(contactData);
  }, [contactData?.patient?.patientId]);

  const getMembersDataForEmailAndSms = async  () => {
    setStateData(prev=> {
      return {
        ...prev,
        loading: true
      }
    })
    let whereObject: any = {
      isDeleted: {_eq: false},
      id: {_neq: contactData.id},
    };
    if (isSmsInbox) {
       whereObject = {
        ...whereObject,
        phoneNumber: {_like: contactData.phoneNumber},
       };
    } else if (isEmailInbox) {
      whereObject = {
        ...whereObject,
        email: {_like: contactData.email},
       };
    }
    if (isCommunicationLocationHandlingEnabled) {
      whereObject = {
        ...whereObject,
        contactPracticeLocations: {
          practiceLocationUuid: {
            _in: allowedUserPracticeLocationUuids,
          }
        }
      }
    }
    if ((isEmailInbox && contactData.email) || (isSmsInbox && contactData.phoneNumber)) {
      const response = await getMemberByContact({
        variables: {
          where: whereObject
        }
      }).catch(()=> {
        setStateData((prev=> {
          return {
            ...prev,
            loading: false
          }
        }))
      })
      if(response?.data?.contacts?.length) {
        const contacts = response?.data?.contacts;
        setStateData((prev=> {
          return {
            ...prev,
            contacts: contacts,
            loading:false
          }
        }))
      } else {
        onCallBackAction?.(COMMON_ACTION_CODES.NO_CONTACT_FOUND)
        setStateData((prev=> {
          return {
            ...prev,
            loading: false
          }
        }))
      }
    } else {
      onCallBackAction?.(COMMON_ACTION_CODES.NO_CONTACT_FOUND);
      setStateData((prev=> {
        return {
          ...prev,
          loading: false
        }
      }));
    }
  }
  useEffect(()=> {
    getMembersDataForEmailAndSms()
  },[conversationUuid, contactData?.id])

  const handleMorePress = () => {
    Keyboard.dismiss();
    setStateData((prev) => {
      return {
        ...prev,
        showMoreContactsPopup: !prev.showMoreContactsPopup,
      };
    });
  };

  const handleMoreUserOpenChange = (visible: any) => {
    setStateData((prev) => {
      return {
        ...prev,
        showMoreContactsPopup: visible,
      };
    });
  };
  const getUserListItemElem = (contact: any) => {
    const userName = contact?.person?.firstName ?? contact?.person?.lastName;
    return (
      <>
        <DisplayCardAvatar
          avatarStyle={{
            avatarSize: '10',
            width: 28,
            height: 28,
            textStyle: {
              fontSize: 14,
            },
          }}
          isLetterAvatarShow={true}
          userData={{
            userId: contactData?.id || '',
            userType: GROUP_MEMBER_TYPE.CONTACT,
            genderCode: getPrimaryGenderCode(contact) as any,
            contactType: contact?.contactType?.contactType?.code || '',
            name: contact?.person,
            userName: userName,
          }}
        />
        <Text
          size={'smMedium'}
          style={styles.contactFirstName}
          {...testID('ContactFirstName')}
        >
          {userName}
        </Text>
      </>
    );
  };

  const getUserListPopoverElem = () => {
    return (
      <Popover
        trigger={'click'}
        placement={'bottomLeft'}
        open={stateData.showMoreContactsPopup}
        onOpenChange={handleMoreUserOpenChange}
        content={
          <ScrollView maxHeight={300} width={145}>
            {stateData.contacts?.map((contact: any, index: number) => {
              if (index < showMemberCount) {
                return;
              }
              return (
                <Pressable
                  key={contact?.uuid}
                  onPress={() => {
                    routeToContactById(contact);
                  }}
                  style={styles.popoverPressable}
                >
                  <View style={styles.popoverView}>
                    {getUserListItemElem(contact)}
                  </View>
                </Pressable>
              );
            })}
          </ScrollView>
        }
      >
        <Pressable
          accessibilityLabel="More options menu"
          onPress={handleMorePress}
        >
          <Text
            {...testID('More')}
            style={styles.moreText}
          >
            {'...more'}
          </Text>
        </Pressable>
      </Popover>
    );
  };
  const getUserListMenuElem = () => {
    return (
      <Menu
        style={styles.menuStyle}
        shadow={2}
        w="190"
        trigger={(triggerProps) => {
          return (
            <Pressable
              accessibilityLabel="More options menu"
              onPress={handleMorePress}
              {...triggerProps}
            >
              <Text
                {...testID('More')}
                style={styles.moreText}
              >
                {`, +${stateData?.contacts?.length - showMemberCount} more`}
              </Text>
            </Pressable>
          );
        }}
        defaultIsOpen={false}
      >
        {stateData.contacts?.map((contact: any, index: number) => {
          return (
            <Menu.Item
              key={contact?.uuid}
              onPress={() => {
                routeToContactById(contact);
              }}
              style={styles.menuItem}
            >
              <View style={styles.menuItemView}>
                {getUserListItemElem(contact)}
              </View>
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };
  const getUserListElem = () => {
    if (stateData?.contacts?.length > showMemberCount) {
      if (isWeb()) {
        return getUserListPopoverElem();
      } else {
        return getUserListMenuElem();
      }
    }
    return <></>;
  };

  return stateData.loading ? (
    <Skeleton.Text lines={1} {...testID(TestIdentifiers.lazyLoading)}/>
  ) : stateData?.contacts?.length ? (
    <View style={styles.contactListContainer}>
      {stateData.contacts?.map((contact: any, index: number) => {
        if (index < showMemberCount) {
          return (
            <View
              key={`memberList_${contact?.uuid}_${index}`}
              style={styles.contactListItem}
              {...testID('ContactList')}
            >
              <Pressable
                onPress={() => {
                  routeToContactById(contact);
                }}
                style={styles.contactListPressable}
              >
                <Text
                  size={'smMedium'}
                  fontWeight={400}
                  noOfLines={1}
                  style={styles.contactName}
                  {...testID('ContactFirstName')}
                >
                  {contact?.person?.firstName
                    ? contact?.person?.firstName
                    : contact?.person?.lastName}
                </Text>
              </Pressable>
              {isWeb() && stateData.contacts.length - 1 !== index ? (
                <Text
                  size={'smMedium'}
                  fontWeight={400}
                  noOfLines={1}
                  style={styles.orText}
                  {...testID('Or')}
                >
                  or
                </Text>
              ) : (
                <></>
              )}
            </View>
          );
        }
      })}
      {getUserListElem()}
    </View>
  ) : (
    <CustomHeaderPatientSubtitle
      deceasedDate={deceasedDate}
      contactData={contactData}
      type={HEADER_TITLE_TYPES.SUBTITLE}
      />
  );
};

const styles = StyleSheet.create({
  contactFirstName: {
    marginLeft: 12,
    color: Colors.Custom.Gray700,
  },
  popoverPressable: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.Custom.Gray200,
  },
  popoverView: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 2,
    marginTop: 5,
    marginBottom: 5,
  },
  moreText: {
    color: Colors.Custom.mainPrimaryPurple,
  },
  menuStyle: {
    position: 'relative',
    right: 10,
    maxHeight: 300,
  },
  menuItem: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.Custom.Gray200,
  },
  menuItemView: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 2,
  },
  contactListContainer: {
    width: !isWeb() ? '72%' : undefined,
    flexDirection: 'row',
    alignItems: 'center',
  },
  contactListItem: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  contactListPressable: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  contactName: {
    color: Colors.Custom.mainPrimaryPurple,
    marginTop: 0,
    textDecorationLine: 'underline',
  },
  orText: {
    color: Colors.Custom.Gray500,
    marginHorizontal: 4,
    fontSize: 12,
  },
});

export default isWeb()
  ? React.memo(withMiniContactViewHOC(MemberListByContactValue))
  : React.memo(MemberListByContactValue);
