import React from 'react';
import Stack from '../../../../../../common/LayoutComponents/Stack';
import NoPinnedWidgetsIconSvg from '../../../../../../common/Svg/NoPinnedWidgetsIconSvg';
import NoDataFoundWithCustomIcon from '../../../../../../common/NoDataFound/NoDataFoundWithCustomIcon';
import GraphIconSvg from '../../../../../../common/Svg/GraphIconSvg';
import { styles } from '../../../../Analytics/style';

export const NoDataFoundView = () => {
  return (
    <Stack
      direction="column"
      style={styles.stackStyle1}
    >
      <NoDataFoundWithCustomIcon
        icon={<GraphIconSvg />}
        displayString="noDataAvailable"
      />
    </Stack>
  );
};
