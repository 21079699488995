import {Icon, View} from 'native-base';
import React from 'react';
import {StyleSheet} from 'react-native';
import AppointmentActionSvg from '../../../../common/Svg/PersonActionSvgIcons/AppointmentActionSvg';
import AutomationActionBtn from '../../../../common/Svg/PersonActionSvgIcons/AutomationActionBtn';
import CreateTaskActionBtn from '../../../../common/Svg/PersonActionSvgIcons/CreateTaskActionBtn';
import EditActionBtnSvg from '../../../../common/Svg/PersonActionSvgIcons/EditActionBtnSvg';
import ResetPasswordActionSvg from '../../../../common/Svg/PersonActionSvgIcons/ResetPasswordActionSvg';
import SendFormActionBtn from '../../../../common/Svg/PersonActionSvgIcons/SendFormActionBtn';
import {PERSON_ACTION_CODES} from './ActionConst';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { Colors } from '../../../../../styles';
import PrintActionBtn from '../../../../common/Svg/PersonActionSvgIcons/PrintActionBtn';
import ConvertSvgIcon from '../../../../common/Svg/ConvertSvgIcon';
import ClipBoardSvg from '../../../../common/Svg/ClipBoardSvg';

const PersonActionPopoverHelper = (props:{actionCode:string})=> {
  const  {actionCode} = props
  const getSvgIconByAction = ()=> {
    let ele = <></>
    switch (actionCode) {
        case PERSON_ACTION_CODES.EDIT:
        case PERSON_ACTION_CODES.EDIT_LEAD:
        ele = <EditActionBtnSvg />
        break;
        case PERSON_ACTION_CODES.RESET_PASSWORD:
        ele = <ResetPasswordActionSvg />
        break;
        case PERSON_ACTION_CODES.ADD_NOTE:
        ele = <ResetPasswordActionSvg />
        break;
        case PERSON_ACTION_CODES.SEND_FORMS:
        ele = <SendFormActionBtn />
        break;
        case PERSON_ACTION_CODES.SEND_PROVIDER_REQUEST_FORM:
        ele = (
          <View style={styles.sendProviderRequestForm}>
            <SendFormActionBtn />
          </View>
        );
          break;
        case PERSON_ACTION_CODES.PRINT_CLINICAL_PROFILE:
        ele = <PrintActionBtn />;
        break;
        case PERSON_ACTION_CODES.CREATE_TASK:
        ele = <CreateTaskActionBtn />
        break;
        case PERSON_ACTION_CODES.CREATE_AUTOMATION:
        ele = <AutomationActionBtn />
        break;
        case PERSON_ACTION_CODES.SCHEDULE_APPOINTMENT:
        ele = <View style={styles.iconContainer}> <AppointmentActionSvg isActive={true} /> </View>
        break;
        case PERSON_ACTION_CODES.CONVERT_TO_CUSTOMER:
        ele = <View style={styles.iconContainer}> <ConvertSvgIcon isActive={true} /> </View>
        break;
        case PERSON_ACTION_CODES.CARE_PROGRAM_CONSENT_AND_ENROLL:
          ele = <View style={styles.iconContainer}> <ClipBoardSvg /> </View>
          break;
        default:
        ele = <></>
        break;
    }
    return ele
  }
  return (
    getSvgIconByAction()
  )
}

const styles = StyleSheet.create({
  sendProviderRequestForm: {
    width: 24,
    height: 32,
  },
  iconContainer: {
    width: 20,
    height: 20,
  },
});

export default React.memo(PersonActionPopoverHelper)
