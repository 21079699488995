import {useLazyQuery, useMutation} from '@apollo/client';
import {
  Center,
  FlatList,
  HStack,
  Pressable,
  Spinner,
  useToast,
  View,
  VStack,
} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {GROUP_MEMBER_TYPE, MLOV_CATEGORY} from '../../../../../../constants';
import {COMMON_ACTION_CODES} from '../../../../../../constants/ActionConst';
import {GROUP_TYPE_CODES} from '../../../../../../constants/MlovConst';
import {CommonDataContext} from '../../../../../../context/CommonDataContext';
import BaseService from '../../../../../../services/CommonService/BaseService';
import ConversationsQueries from '../../../../../../services/Conversations/ConversationsQueries';
import LeadQueries from '../../../../../../services/Lead/LeadQueries';
import {Colors} from '../../../../../../styles/Colors';
import {
  getAccountId,
  getUserData,
  getUserUUID,
} from '../../../../../../utils/commonUtils';
import {showToast, ToastType} from '../../../../../../utils/commonViewUtils';
import {getDateToMomentISOString} from '../../../../../../utils/DateUtils';
import {
  getContactTypeId,
  getMlovId,
  getMlovIdFromCode,
  getMlovListFromCategory,
} from '../../../../../../utils/mlovUtils';
import {
  ICreateGroupBodyParams,
  ISearchExistingGroupBodyParams,
} from '../../../../../common/CreateChannelView/interface';
import MemberInfoListItem from '../../../../../common/MemberInfoListItem/MemberInfoListItem';
import {NoDataFound} from '../../../../../common/NoDataFound';
import PatientSearchAndSelect from '../../../../../common/PatientSearch/PatientSearchAndSelect';
import SearchBar from '../../../../../common/SearchBar/SearchBar';
import {getContactFiltersObject} from '../../../../Contacts/ContactsUtils';
import {IContact} from '../../interfaces';
import ExistingGroupModal from './ExistingGroupModal';
import {IPatientSearchPopupState} from './interfaces';
import { StyleSheet } from 'react-native';

const PatientSearchPopup = (props: {
  onPatientSearchPopupActionPerformed: (
    actionCode: string,
    actionData?: any
  ) => void,width?: number
}) => {
  const createGroupToast = useToast();
  const intl = useIntl();
  const {onPatientSearchPopupActionPerformed,width} = props;
  const [updateConversationLastActivityAt] = useMutation(
    ConversationsQueries.updateConversationLastActivityAt
  );
  const filter: any = {
    _or: [],
    contactTagging: {},
    contactInboxes: {},
  };
  const accountId = getAccountId();
  const userUuid = getUserUUID();
  const commonData = useContext(CommonDataContext);
  const currentUserData = getUserData();
  const contactTypeId = getContactTypeId('CUSTOMER');
  const [patientSearchState, setPatientSearchState] =
    useState<IPatientSearchPopupState>({
      patientList: [],
      groupList: [],
      isLoading: false,
      currentPage: 1,
      offSet: 0,
      pageSize: 10,
      showExistingGroupModal: false,
      selectedPatientName: '',
    });
  const [getPatientList] = useLazyQuery(LeadQueries.GetContacts);

  const getPatientDataList = async (searchText: string) => {
    setPatientSearchState((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });
    const patientListRespData = await getPatientList({
      variables: {
        limit: patientSearchState.pageSize,
        offset: patientSearchState.offSet,
        searchString: '%' + searchText + '%',
        order_by: {
          updatedAt: 'desc',
        },
        contact_filters: getContactFiltersObject(
          filter,
          searchText,
          accountId,
          [],
          [],
          contactTypeId,
          [],
        ),
      },
    }).catch(() => {
      setPatientSearchState((prev) => {
        return {
          ...prev,
          isLoading: false,
        };
      });
    });

    if (patientListRespData?.data?.contacts?.length) {
      setPatientSearchState((prev) => {
        return {
          ...prev,
          isLoading: false,
          patientList: [...patientListRespData.data.contacts],
        };
      });
    } else {
      setPatientSearchState((prev) => {
        return {
          ...prev,
          isLoading: false,
          patientList: [],
        };
      });
    }
  };

  const searchPatient =  (searchString: string) => {
     getPatientDataList(searchString);
  };

  useEffect(() => {
    searchPatient('');
  }, []);

  const findExistingGroupWithSamePatient = async (patientData: IContact) => {
    setPatientSearchState((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });
    const baseService = BaseService.getSharedInstance().axios;
    const searchGroupWithExistUserUrl = `/crm-nest/account/${accountId}/search-group-with-exist-user`;
    const bodyData: ISearchExistingGroupBodyParams = {
      groupTypeCode: GROUP_TYPE_CODES.PRIVATE,
      userIds: [userUuid],
      contactIds: [patientData?.uuid || ''],
    };
    const groupListInfo = await baseService
      .post(searchGroupWithExistUserUrl, bodyData, {
        headers: {
          api_access_token: currentUserData.access_token,
        },
      })
      .catch(() => {
        // catch
      });
    if (groupListInfo?.data?.length) {
      onPatientSearchPopupActionPerformed(COMMON_ACTION_CODES.CLOSE_POPUP);
    } else {
      createGroupConversation(patientData);
    }
    setPatientSearchState(prev => {
      return {
        ...prev,
        selectedPatientName: '',
      }
    });
  };

  const createGroupConversation = async (patientData: IContact) => {
    setPatientSearchState((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });
    const groupMemberTypeList =
      getMlovListFromCategory(commonData.MLOV, MLOV_CATEGORY.GroupMemberType) ||
      [];
    const userTypeId = getMlovIdFromCode(
      groupMemberTypeList,
      GROUP_MEMBER_TYPE.USER
    );
    const baseService = BaseService.getSharedInstance().axios;
    const createGroupUrl = `/crm-nest/account/${accountId}/group`;

    const bodyData: ICreateGroupBodyParams = {
      groupType: GROUP_TYPE_CODES.PRIVATE,
      groupName: '',
      groupData: {
        userIds: [userUuid],
        contactId: patientData.uuid,
        familyMemberIds: [],
      },
    };
    const groupInfo = await baseService
      .post(createGroupUrl, bodyData, {
        headers: {
          ['user-type-id']: userTypeId,
          api_access_token: currentUserData.access_token,
        },
      })
      .catch(() => {
        showToast(
          createGroupToast,
          intl.formatMessage({id: 'apiErrorMsg'}),
          ToastType.error
        );
      });

    if (groupInfo) {
      showToast(
        createGroupToast,
        intl.formatMessage({id: 'groupCreatedMsg'}),
        ToastType.success
      );
      onPatientSearchPopupActionPerformed(COMMON_ACTION_CODES.CLOSE_POPUP);
    }
  };

  const renderPatientList = () => {
    if (patientSearchState?.patientList?.length) {
      return (
        <VStack>
          <FlatList
            style={styles.flatList}
            data={patientSearchState.patientList}
            ItemSeparatorComponent={({highlighted}) => (
              <View
                style={[
                  {
                    height: 1,
                    backgroundColor: Colors.Custom.BorderColor,
                    marginLeft: 60,
                  },
                  highlighted && {marginLeft: 0},
                ]}
              />
            )}
            renderItem={({item}) => {
              return (
                <Pressable
                  _hover={{
                    background: Colors.primary[100] + '7a',
                  }}
                  style={styles.pressable}
                  onPress={() => {
                    findExistingGroupWithSamePatient(item);
                  }}
                >
                  <MemberInfoListItem contactData={item} showEmail={true} showDateOfBirth={true} />
                </Pressable>
              );
            }}
            keyExtractor={(item: any, index: number) => {
              return 'PatientSearchPopup_' + index + item.id;
            }}
          />
        </VStack>
      );
    }
    return (
      <View height={300}>
        <Center top={'40%'}>
          <NoDataFound />
        </Center>
      </View>
    );
  };
  const onExistingGroupActionPerformed = (
    actionCode: string,
    actionData?: any
  ) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.CLOSE_MODAL:
        setPatientSearchState((prev) => {
          return {
            ...prev,
            showExistingGroupModal: false,
          };
        });
        break;

      default:
        break;
    }
  };
  return (
    <VStack flex={1} width={width ? 300 : 230} marginRight={5}>
      {/* <HStack flex={1}>
        <SearchBar
          placeholderText={'Search Patients'}
          onChange={(value: string) => {
            searchPatient(value);
          }}
        />
      </HStack>
      <View>
        {patientSearchState.isLoading && (
          <View
            height={'100%'}
            width={'100%'}
            position="absolute"
            zIndex={10}
            bg={'coolGray.50:alpha.70'}
          >
            <Center
              top={'50%'}
              justifyContent="center"
              alignItems="center"
              alignContent="center"
            >
              <Spinner size="lg" />
            </Center>
          </View>
        )}
        <VStack>{renderPatientList()}</VStack>
      </View> */}

      <View width={width ? 300 : 230}>
          <PatientSearchAndSelect
                value={patientSearchState.selectedPatientName}
                label='Search patients'
                isProspect={false}
                placeholder="Search patients"
                onChange={(participants: any) => {
                  const contactData = participants?.label?.props?.contactData;
                  setPatientSearchState(prev => {
                    return {
                      ...prev,
                      selectedPatientName: contactData?.name,
                    }
                  });
                  findExistingGroupWithSamePatient(contactData);
                }}
              />
      </View>

      <ExistingGroupModal
        showExistingGroupModal={patientSearchState.showExistingGroupModal}
        groupList={patientSearchState?.groupList || []}
        onExistingGroupActionPerformed={(
          actionCode: string,
          actionData: any
        ) => {
          onExistingGroupActionPerformed(actionCode, actionData);
        }}
      />
    </VStack>
  );
};

const styles = StyleSheet.create({
  flatList: {
    maxHeight: 400,
    flex: 1,
    overflow: 'scroll',
  },
  pressable: {
    paddingBottom: 8,
    paddingTop: 8,
  }
});

export default PatientSearchPopup;
