import {StyleSheet} from 'react-native';
import {Colors} from '../../../styles';

export const styles = StyleSheet.create({
  cannedResponsesMain: {
    maxHeight: 200,
    width: '97%',
    overflow: 'scroll',
    position: 'absolute',
    left: 0,
    zIndex: 100,
    backgroundColor: Colors.Custom.BackgroundColor,
    borderColor: Colors.Custom.BorderColor,
    borderWidth: 1,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    marginLeft: 10,
    marginRight: 15,
    padding: 5,
  },
  contentOverflow: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  contentListItem: {
    padding: 5,
  },
  contentText: {
    fontSize: 14,
    fontWeight: '300',
  },
  shortCode: {
    fontSize: 14,
    fontWeight: '600',
  },
});
