import React from "react";
import {Colors} from "../../../styles";
import {isWeb} from "../../../utils/platformCheckUtils";
import {ICommonSvgProps} from "./interfaces";
import Svg, { Path } from 'react-native-svg';
import {StyleSheet} from 'react-native';

const ConvertSvgIcon = (props: ICommonSvgProps) => {
  const strokeColor = props.isActive
    ? Colors.Custom.Gray500
    : props.customStrokeColor
      ? props.customStrokeColor
      : Colors.Custom.Gray300;
  return (
    <>
      {isWeb() ? (
        <Svg
          style={styles.svg}
          fill="none"
          viewBox="0 0 24 25"
        >
          <Path
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M16 21.5v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2M21.666 4.833L23 6.167m0 0L21.666 7.5M23 6.167h-4.667A1.333 1.333 0 0017 7.5v.667m1.333 5L17 11.833m0 0l1.333-1.333M17 11.833h4.667A1.333 1.333 0 0023 10.5v-.667M12.5 7.5a4 4 0 11-8 0 4 4 0 018 0z"
          />
        </Svg>
      ) : null
      }
    </>
  );
}

const styles = StyleSheet.create({
  svg: {
    width: 'inherit',
    height: 'inherit',
  },
});

export default ConvertSvgIcon;
