import React, {useState, useEffect, useRef} from 'react';
import {View, Text, Pressable} from 'react-native';
import LogTimeSvg from '../Svg/LogTimeSvg';
import {Colors} from '../../../styles/Colors';
import Stack from '../LayoutComponents/Stack';
import {useIntl} from 'react-intl';
import StartTimerSvg from '../Svg/StartTimerSvg';

export interface IStopwatch {
  onStop: (minutes: number, seconds: number) => void;
  onStart: () => void;
  isCcmCareManagement?: boolean;
  isLoggedTimeCleared?: boolean;
}

const Stopwatch = (props: IStopwatch) => {
  const [time, setTime] = useState(0);

  const [isRunning, setIsRunning] = useState(false);
  const timeRef = useRef(time);
  const intl = useIntl();

  useEffect(() => {
    timeRef.current = time;
  }, [time]);


  useEffect(() => {
    let intervalId: any;
    if (isRunning) {
      intervalId = setInterval(() => setTime((prevTime) => prevTime + 1), 1000);
    }
    return () => clearInterval(intervalId);
  }, [isRunning, time]);


  useEffect(() => {
    if (props.isCcmCareManagement && time === 0 && !props.isLoggedTimeCleared) {
      setIsRunning(true);
      props.onStart();
    }
    return () => {
      const minutes = Math.floor(timeRef.current / 60);
      const seconds = timeRef.current % 60;
      props.onStop(minutes, seconds);
    };
  }, []); 
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;

  const startAndStop = () => {
    if (isRunning) {
      props.onStop(minutes, seconds);
    } else {
        props.onStart();
    }
    setIsRunning(!isRunning);
  };


  const startTimeElement = (
    <Stack
      style={{
        borderColor: !props.isCcmCareManagement ? Colors.Custom.PrimaryColor200 : undefined,
        backgroundColor: !props.isCcmCareManagement ? Colors.FoldPixel.PRIMARY100 : undefined,
        borderWidth: !props.isCcmCareManagement ? 0.5 : undefined,
        padding: 8,
        alignItems: 'center',
        borderRadius: !props.isCcmCareManagement ? 4 : undefined,
        width: 'fit-content',
      }}
      space={4}
      direction={'row'}
    >
      <StartTimerSvg />
      <Text
        style={{
          fontWeight: '500',
          fontSize: 14,
          color: Colors.FoldPixel.PRIMARY300,
        }}
      >
        {intl.formatMessage({id: 'startTime'})}
      </Text>
    </Stack>
  );

  const stopTimeElement = (
    <Stack
      style={{
        backgroundColor: Colors.FoldPixel.STATUS_LIGHT_SUCCESS,
        padding: 8,
        alignItems: 'center',
        borderRadius: !props.isCcmCareManagement ? 4 : undefined,
        width: 'fit-content',
      }}
      space={4}
      direction={'row'}
    >
      <LogTimeSvg strokeColor={Colors.FoldPixel.STATUS_DARK_SUCCESS} />
      <Text
        style={{color: Colors.FoldPixel.STATUS_DARK_SUCCESS, fontWeight: '500'}}
      >
        {minutes.toString().padStart(2, '0')}:
        {seconds.toString().padStart(2, '0')}
      </Text>
      <Text
        style={{
          fontWeight: '500',
          fontSize: 14,
          color: Colors.FoldPixel.STATUS_DARK_SUCCESS,
        }}
      >
        {' '}
        • {intl.formatMessage({id: 'stop'})}
      </Text>
    </Stack>
  );

  return (
    <View>
      <Pressable onPress={startAndStop}>
        {isRunning ? stopTimeElement : startTimeElement}
      </Pressable>
    </View>
  );
};

export default Stopwatch;
