import React from 'react';

const MembershipIcon = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="50" height="50" rx="25" fill="#E9C770" fill-opacity="0.2" />
      <g filter="url(#filter0_d_843_152533)">
        <path
          d="M37.3913 16.1523V22.6139L24.9997 17.9896L12.6086 22.6145V16.1523C12.6086 15.765 12.8567 15.4147 13.2156 15.2776L24.6779 11.003C24.8887 10.9157 25.1118 10.9157 25.322 11.003L36.7837 15.2776C37.1432 15.4152 37.3913 15.765 37.3913 16.1523Z"
          fill="#E9C770"
        />
        <path
          d="M37.3907 22.6147V26.1516C37.3907 37.5382 25.3586 40.8754 25.2349 40.9129L24.9997 40.9381L24.7644 40.9129C24.6407 40.8754 12.6086 37.5382 12.6086 26.1516V22.6147L24.9991 17.9897L37.3901 22.6141L37.3907 22.6147Z"
          fill="#E9C770"
        />
        <path
          d="M31.7662 22.9904C31.6558 22.6565 31.3706 22.4145 31.025 22.3618L27.4477 21.8105L25.8393 18.3543C25.5344 17.697 24.4627 17.697 24.1578 18.3543L22.5495 21.8105L18.9721 22.3618C18.6276 22.4145 18.3413 22.6582 18.2309 22.9904C18.1194 23.3226 18.2036 23.6917 18.4464 23.9431L21.0619 26.6463L20.4422 30.4744C20.3852 30.8283 20.5328 31.1845 20.8249 31.3919C21.1159 31.5993 21.4993 31.6181 21.81 31.4458L24.9983 29.6683L28.1853 31.4458C28.3253 31.5243 28.4804 31.5636 28.6349 31.5636C28.8219 31.5636 29.0101 31.5062 29.1704 31.3925C29.4614 31.1851 29.609 30.8289 29.5532 30.475L28.9334 26.6469L31.549 23.9436C31.7917 23.6923 31.876 23.3238 31.7644 22.991L31.7662 22.9904Z"
          fill="white"
        />
        <path
          opacity="0.1"
          d="M24.9997 10.9395H24.9276C24.8434 10.9471 24.7592 10.9682 24.6773 11.0021L13.2156 15.2767C12.8567 15.4144 12.6086 15.7642 12.6086 16.1514V22.6131L24.9997 17.9888V10.9395Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M24.9997 17.9897L12.6086 22.6147V26.1516C12.6086 37.5382 24.6407 40.8754 24.7644 40.9129L24.9997 40.9381V33.4189L21.8126 35.1965C21.6715 35.275 21.5164 35.3142 21.3613 35.3142C21.1743 35.3142 20.9861 35.2568 20.8258 35.1443C20.5348 34.9369 20.3872 34.5807 20.443 34.2268L21.0627 30.3987L18.4472 27.6954C18.2044 27.4441 18.1202 27.0756 18.2317 26.7428C18.3421 26.4088 18.6273 26.1669 18.9729 26.1141L22.5503 25.5628L24.1586 22.1067C24.3108 21.7757 24.6552 21.6105 25.0002 21.6105V17.9909L24.9997 17.9897Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M24.9994 21.6094C24.655 21.6094 24.3105 21.7746 24.1578 22.1033L22.5494 25.5594L18.9721 26.1107C18.6276 26.1634 18.3413 26.4071 18.2309 26.7393C18.1194 27.0715 18.2036 27.4406 18.4464 27.692L21.0619 30.3952L20.4422 34.2234C20.3852 34.5773 20.5328 34.9335 20.8249 35.1409C20.9847 35.2545 21.1717 35.3108 21.3605 35.3108C21.5156 35.3108 21.6701 35.2721 21.81 35.1948L24.9983 33.419V21.6094H24.9994Z"
          fill="black"
        />
      </g>
      <rect
        x="0.5"
        y="0.5"
        width="49"
        height="49"
        rx="24.5"
        stroke="#E9C770"
        stroke-opacity="0.25"
      />
      <defs>
        <filter
          id="filter0_d_843_152533"
          x="12.6086"
          y="10"
          width="24.7826"
          height="30.938"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_843_152533"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_843_152533"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_843_152533"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default MembershipIcon;
