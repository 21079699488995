import {Popover, Tooltip} from 'antd';
import {
  Button,
  IconButton,
  Pressable,
  Text,
  useMediaQuery,
  View,
} from 'native-base';
import React, {useRef, useState} from 'react';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {v4} from 'uuid';
import {IPAD_MINI_WIDTH, IPAD_WIDTH} from '../../../../../constants';
import {Colors} from '../../../../../styles';
import {ICreatePEViewProps, IPEDynamicZone} from '../interfaces';
import {COMPONENT_TITLES, COMPONENT_TYPES} from '../PatientEducationConsts';
import PEArtical from '../PEArtical';
import {styles} from './CreatePEStyles';
import {MediaSelectionModal} from '../MediaSelectionModal';
import {isEmptyHtml} from '../PatientEducationUtils';

const CreatePEView = (props: ICreatePEViewProps) => {
  const {dynamicData, getDynamicData} = props;
  const [count, setCount] = useState(dynamicData.length + 1);
  const [state, setState] = React.useState({
    selectionModalOpen: false,
  });

  const handleAddElementByType = (type: string, data?: any) => {
    let newDataSource: IPEDynamicZone[] = [];
    switch (type) {
      case COMPONENT_TYPES.MEDIA:
        newDataSource = [
          ...dynamicData,
          {
            key: count,
            __component: COMPONENT_TYPES.MEDIA,
            description: '',
            componentTitle: COMPONENT_TITLES.MEDIA,
            mediaUrl: data.mediaUrl || '',
            mediaType: data.mediaType || '',
            text: '',
            draggableId: v4(),
            mediaId: data.mediaId || '',
            link: '',
          },
        ];
        setCount((prev) => prev + 1);
        getDynamicData(newDataSource);
        break;
      case COMPONENT_TYPES.TEXT:
        newDataSource = [
          ...dynamicData,
          {
            key: count,
            __component: COMPONENT_TYPES.TEXT,
            text: '',
            componentTitle: COMPONENT_TITLES.TEXT,
            description: '',
            draggableId: v4(),
            mediaId: '',
            mediaType: '',
            mediaUrl: '',
            link: '',
          },
        ];
        setCount((prev) => prev + 1);
        getDynamicData(newDataSource);
        break;
      case COMPONENT_TYPES.EMBED:
        newDataSource = [
          ...dynamicData,
          {
            key: count,
            __component: COMPONENT_TYPES.EMBED,
            text: '',
            componentTitle: 'Vimeo',
            description: '',
            draggableId: v4(),
            mediaId: '',
            mediaType: '',
            mediaUrl: '',
            link: '',
          },
        ];
        setCount((prev) => prev + 1);
        getDynamicData(newDataSource);
        break;
    }
  };

  const handleDelete = (data: IPEDynamicZone) => {
    const newDataSource = dynamicData.filter(
      (d: IPEDynamicZone) => d.draggableId !== data.draggableId
    );
    getDynamicData(newDataSource);
  };

  const handleSelect = (data: IPEDynamicZone) => {
    const newDynamicData = [...dynamicData];
    newDynamicData.forEach((item) => {
      if (item.draggableId === data.draggableId) {
        item.isSelected = true;
      } else {
        item.isSelected = false;
      }
    });
    getDynamicData(newDynamicData);
  };

  const popoverContent = () => {
    return (
      <Button.Group>
        <Pressable
          onPress={() => {
            setState((prev) => {
              return {
                ...prev,
                selectionModalOpen: true,
              };
            });
          }}
          style={{
            borderRadius: 6,
            borderColor: Colors.Custom.Gray200,
            padding: 8,
            borderWidth: 1,
            marginHorizontal: 6,
          }}
        >
          <Tooltip
            placement="top"
            title={
              <Text
                style={{
                  color: 'black',
                }}
              >
                Media
              </Text>
            }
            overlayStyle={{
              backgroundColor: 'white',
            }}
            overlayInnerStyle={{
              backgroundColor: 'white',
            }}
          >
            <AntIcon name="picture" style={{color: 'black'}} size={20} />
          </Tooltip>
        </Pressable>
        <Pressable
          onPress={() => {
            handleAddElementByType(COMPONENT_TYPES.TEXT);
          }}
          style={{
            borderRadius: 6,
            borderColor: Colors.Custom.Gray200,
            padding: 8,
            borderWidth: 1,
            marginHorizontal: 6,
          }}
        >
          <Tooltip
            placement="top"
            title={
              <Text
                style={{
                  color: 'black',
                }}
              >
                Text
              </Text>
            }
            overlayStyle={{
              backgroundColor: 'white',
            }}
            overlayInnerStyle={{
              backgroundColor: 'white',
            }}
          >
            <AntIcon name="edit" style={{color: 'black'}} size={20} />
          </Tooltip>
        </Pressable>
        <Pressable
          onPress={() => {
            handleAddElementByType(COMPONENT_TYPES.EMBED);
          }}
          style={{
            borderRadius: 6,
            borderColor: Colors.Custom.Gray200,
            padding: 8,
            borderWidth: 1,
            marginHorizontal: 6,
          }}
        >
          <Tooltip
            placement="top"
            title={
              <Text
                style={{
                  color: 'black',
                }}
              >
                Link
              </Text>
            }
            overlayStyle={{
              backgroundColor: 'white',
            }}
            overlayInnerStyle={{
              backgroundColor: 'white',
            }}
          >
            <AntIcon name="link" style={{color: 'black'}} size={20} />
          </Tooltip>
        </Pressable>
      </Button.Group>
    );
  };

  const handleOnDragEnd = (result: any) => {
    if (result.destination) {
      const items = Array.from(dynamicData);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      getDynamicData(items);
    }
  };

  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    ...draggableStyle,
    position: isDragging ? 'absolute' : '',
    top: 'auto !important',
    left: 'auto !important',
  });

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const commonWidth = () => {
    if (isIPadMiniScreen) {
      return 600;
    }
    if (isIPadScreen) {
      return 600;
    }
    return '100%';
  };

  const handleOnChangeDynamicZone = (data: IPEDynamicZone) => {
    if (data.__component === COMPONENT_TYPES.EMBED && !data.link) {
      handleDelete(data);
      return;
    }
    const currentDataIdx = dynamicData.findIndex(
      (item) => item.draggableId === data.draggableId
    );
    const newDynamicData = [...dynamicData];
    newDynamicData[currentDataIdx] = data;
    getDynamicData(newDynamicData);
  };

  return (
    <View style={styles.mainContainer}>
      <DragDropContext onDragEnd={(result) => handleOnDragEnd(result)}>
        <Droppable droppableId="createPreviewContainer">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {dynamicData.map((data: any, index: number) => {
                return (
                  <Draggable
                    key={data.draggableId}
                    draggableId={data.draggableId}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <View
                          style={[
                            styles.commonContainer,
                            {width: commonWidth()},
                          ]}
                        >
                          <PEArtical
                            data={data}
                            onChangeDynamicZone={(data) => {
                              handleOnChangeDynamicZone(data);
                            }}
                            onSelectComponent={(data) => {
                              handleSelect(data);
                            }}
                          />
                        </View>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <View style={[styles.btnContainer, {flex: 1, alignItems: 'flex-start'}]}>
        <Popover trigger={'hover'} content={popoverContent} placement="right">
          <View
            style={{
              borderRadius: 6,
              borderColor: Colors.Custom.Gray200,
              padding: 8,
              borderWidth: 1,
            }}
          >
            <AntIcon name="plus" size={20} />
          </View>
        </Popover>
      </View>

      {state.selectionModalOpen && (
        <MediaSelectionModal
          isOpen={state.selectionModalOpen}
          onClose={() => {
            setState((prev) => {
              return {
                ...prev,
                selectionModalOpen: false,
              };
            });
          }}
          onSelect={(mediaData) => {
            setState((prev) => {
              return {
                ...prev,
                selectionModalOpen: false,
              };
            });
            handleAddElementByType(COMPONENT_TYPES.MEDIA, {
              mediaUrl: mediaData.url,
              mediaType: mediaData.mime,
              mediaId: mediaData.fileId,
            });
          }}
        />
      )}
    </View>
  );
};

export default CreatePEView;
