import {StyleSheet} from 'react-native';
import {Colors} from '../../../../../styles';

export const styles = StyleSheet.create({
  messageCommunicationTypeWrapper: {
    padding: 3,
    borderWidth: 1,
    borderRadius: 4,
    borderColor: '#0000001A',
  },
  messageCommunicationTypeText: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 21,
    color: Colors.Custom.MonochromeBlack
  },
  assignTypeSubtitle: {
    color: Colors.Custom.Gray700,
    fontSize: 16,
    fontWeight: '500',
    marginBottom: 12,
  },
  messageWrapper: {
    flexDirection: 'row',
    borderColor: Colors.Custom.mainPrimaryPurple,
  },
  iconWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    color: Colors.Custom.mainPrimaryPurple,
  },
  text: {
    color: Colors.Custom.mainPrimaryPurple,
  },
});
