import React from 'react';
import {ICommonSvgProps} from '../interfaces';
import Svg, { ClipPath, Defs, G, Path} from 'react-native-svg';
import {Colors} from '../../../../styles/Colors';

const MailPopoverActionSvg = (props: ICommonSvgProps) => {
  const strokeColor = props?.isActive
    ? Colors.FoldPixel.GRAY300
    : Colors.Custom.Gray300;
  return (
    <Svg
      width={props?.width ? props?.width : 'inherit'}
      height={props?.height ? props?.height : 'inherit'}
      fill="none"
      viewBox="0 0 16 16"
    >
      <Path
        stroke={props?.customStrokeColor || strokeColor}
        strokeLinecap="round"
        d="M4 5.33l1.44 1.2C6.663 7.55 7.275 8.06 8 8.06s1.336-.51 2.56-1.53L12 5.33m-5.333 8h2.667c2.514 0 3.77 0 4.552-.78.78-.781.78-2.038.78-4.553 0-2.514 0-3.77-.78-4.552-.781-.78-2.038-.78-4.552-.78H6.667c-2.514 0-3.771 0-4.552.78-.782.781-.782 2.038-.782 4.552 0 2.515 0 3.772.782 4.553.78.78 2.038.78 4.552.78z"
      ></Path>
    </Svg>
  );
};

export default React.memo(MailPopoverActionSvg);
