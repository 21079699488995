import { Vital } from "../../../../../../utils/VitalUtils";

export const SLEEP_STAGES = [
  {
    code: Vital.deep_sleep,
    display: 'Deep',
  },
  {
    code: Vital.rem_sleep,
    display: 'REM',
  },
  {
    code: Vital.light_sleep,
    display: 'Light',
  },
  {
    code: Vital.awake,
    display: 'Awake',
  },
  {
    code: Vital.sleep,
    display: 'Sleep',
  },
];
