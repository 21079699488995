export default [
  {
    type: 'checkbox',
    key: 'macroTemplatesEnabled',
    label: 'Enable macro templates',
    input: true,
    defaultValue: false,
    clearOnHide: true,
    tooltip: 'This option will allow you to enable the use of macro templates by separating them with the \'/\' character. These templates will be available for contexts like notes and care plans, but not for forms.',
  },
];
