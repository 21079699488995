import React from 'react';
import {ICopiedMessageItem} from '../interface';
import {COPY_MESSAGE_CODE} from '../../../../../../constants';
import {HStack, Pressable, Text} from 'native-base';
import {Colors} from '../../../../../../styles';
import {TestIdentifiers, testID} from '../../../../../../testUtils';
import Feather from 'react-native-vector-icons/Feather';

export interface ISelectedMessageViewProps {
  code: string;
  copyMessageList: ICopiedMessageItem[];
  onPressCancelBtn: () => void;
}

const SelectedMessageView = (props: ISelectedMessageViewProps) => {
  const {code, copyMessageList, onPressCancelBtn} = props;
  return (
    <>
      {[
        COPY_MESSAGE_CODE.COPY_MODE_ON,
        COPY_MESSAGE_CODE.COPY_MODE_COUNT,
      ].includes(code) &&
        copyMessageList.length > 0 && (
          <HStack
            px={4}
            py={1}
            borderTopWidth={1}
            borderBottomWidth={1}
            justifyContent={'flex-end'}
            borderColor={Colors.Custom.BorderColor}
            bg={Colors.Custom.Gray50}
            {...testID('SelectedMessageView')}
          >
            <Text color={Colors.Custom.Gray500} {...testID('MessageSelected')}>
              {copyMessageList.length} message selected
            </Text>
            <Pressable
              ml={2}
              onPress={onPressCancelBtn}
              {...testID(TestIdentifiers.closeBtn)}
            >
              <Feather
                name="x"
                size={20}
                color={Colors.Custom.Gray500}
                {...testID('image')}
              />
            </Pressable>
          </HStack>
        )}
    </>
  );
};

export default SelectedMessageView;
