import { IntlShape } from 'react-intl';
import { v4 as uuidv4} from 'uuid';
import { BasicComponents } from '../../FHFormio/Builder/SupportedComponents';
import { getFlatListedFormComponents} from '../AddOrUpdateForm/AddOrUpdateFormHelper';
import { IRiskScoreField} from './RiskScoreFields';
import { IFormMetaData, IGroupField, ISectionRiskScoreState, ISingleField } from './RiskScoreInterface';
import { GENERAL_RISK_SCORE_GROUP_ID, GENERAL_RISK_SCORE_GROUP_NAME } from './SectionWiseRiskScoreView';
import { FormComponents } from '../../../../PublicPages/PublicForm/PublicFormHelper';
import { FHIRQuestionnaireObject, Item, Question, Resource } from '../../../../PersonOmniView/MiddleContainer/PersonDetailsView/Questionnaire/interfaces';
import { QUESTION_TYPES } from '../../../../PersonOmniView/MiddleContainer/PersonDetailsView/Questionnaire/QuestionnaireConsts';
import { getTypeByQuestion } from '../../../../PersonOmniView/MiddleContainer/PersonDetailsView/Questionnaire/QuestionnaireUtils';
import { getQuestionListFromQuestionnaire } from '../../FHFormio/CustomComponents/SocialHistory/AddOrUpdateSocialHistory/AddOrUpdateSocialHistoryHelper';
import { generateUUID } from '../../../../../utils/commonUtils';

export const FIELD_TYPES_WITH_RISK_SCORE = [
  FormComponents.CUSTOM_SURVEY,
  FormComponents.CHECKBOX,
  FormComponents.RADIO,
  FormComponents.SELECT,
  // FormComponents has SELECT_BOXES mapped to selectBoxes, (B is capital). thus using string only
  'selectboxes',
  FormComponents.RATING,
];

export const DISPLAY_HAS_ENTERED_VALUE_FOR_FIELDS = [
  FormComponents.EMAIL,
  FormComponents.PHONE_NUMBER,
  FormComponents.NUMBER,
  // FormComponents has TEXTFIELD and TEXTAREA mapped to textField(F is capital) and textArea(A is capital). thus using string only
  'textfield',
  'textarea',
];

export const DISPLAY_OPERATORS_FOR_FIELDS = [
  FormComponents.RATING,
];

export const CHECK_FIELDS_FOR_HAS_ENTERED_VALUE = [
  FormComponents.EMAIL.toLowerCase(),
  FormComponents.PHONE_NUMBER.toLowerCase(),
  FormComponents.NUMBER.toLowerCase(),
  FormComponents.TEXTAREA.toLowerCase(),
  FormComponents.TEXTFIELD.toLowerCase(),
];

export const CHECK_FIELDS_FOR_EXACTLY_EQUAL_VALUE = [
  FormComponents.EMAIL.toLowerCase(),
  FormComponents.PHONE_NUMBER.toLowerCase(),
  FormComponents.NUMBER.toLowerCase(),
];

export const FIELD_OPERATION = {
  SINGLE_FIELD_ADD: 'SINGLE_FIELD_ADD',
  SINGLE_FIELD_REMOVE: 'SINGLE_FIELD_REMOVE',
  GROUP_ADDED: 'GROUP_ADDED',
  GROUP_REMOVED: 'GROUP_REMOVED',
  GROUP_FIELD_ADD: 'GROUP_FIELD_ADD',
  GROUP_FIELD_REMOVE: 'GROUP_FIELD_REMOVE'
};

export const generateUniqueKey = (): string => {
  return uuidv4();
};

export const getOptionsOfComponent = (component: any): any[] => {
  if (component?.type === FormComponents.SELECT) {
    return component?.data?.values || [];
  } else if (component?.values) {
    return component.values || [];
  }
  return [];
};

export const getFieldsWithRiskScore = (
  components: any[],
  intl: IntlShape
): any[] => {
  return getComponentsWithType(components, FIELD_TYPES_WITH_RISK_SCORE, intl);
};

const getComponentsWithType = (
  components: any[],
  types: string[],
  intl: IntlShape
): any[] => {
  const fieldsWithRiskScore: any[] = [];
  components.forEach((field) => {
    if (
      field.type === 'checkbox' &&
      (!field.values || field.values.length === 0)
    ) {
      field.values = [
        {label: intl.formatMessage({id: 'checked'}), value: true, riskScore: 0},
        {
          label: intl.formatMessage({id: 'unchecked'}),
          value: false,
          riskScore: 0,
          groupRiskScore: 0,
        },
      ];
    }
    if (types.includes(field.type)) {
      (field.values || field.data?.values)?.forEach((option: any) => {
        if (!option.riskScore) {
          option.riskScore = 0;
        }
      });
      fieldsWithRiskScore.push(field);
    }
    if (field.columns && field.columns.length > 0) {
      field.columns.forEach((column: any) => {
        const fields = getComponentsWithType(column.components, types, intl);
        fieldsWithRiskScore.push(...fields);
      });
    }
    if (field.components && field.components.length > 0) {
      const fields = getComponentsWithType(field.components, types, intl);
      fieldsWithRiskScore.push(...fields);
    }
  });
  return fieldsWithRiskScore;
};

export const getDefaultData = (
  fields: any[],
  components: any[]
): IRiskScoreField[] => {
  const flatListedComponents = getFlatListedFormComponents(components);
  if (fields.length > 0) {
    return fields.map((field: any) => {
      const matchedData = flatListedComponents.filter((component) => {
        const componentId = getComponentId(component);
        return (componentId === field.componentId);
      });
      return {
        field: matchedData.length > 0 ? matchedData[0] : undefined,
        uniqueKey:
          matchedData.length > 0
            ? getComponentId(matchedData[0])
            : generateUniqueKey(),
      };
    });
  } else {
    return [];
  }
};

export const getRowsWithNoFieldSelected = (
  fields: IRiskScoreField[]
): string[] => {
  return fields
    .filter((field: IRiskScoreField) => {
      return !field.field;
    })
    .map((field) => {
      return field.uniqueKey;
    });
};

const getGroupDataFromMetadata = (formMetadata: IFormMetaData | undefined, groupId: string | undefined, questionnaireId: string | undefined) => {
  const group = (formMetadata?.metadata || []).find((riskScoreData) => {
    if (riskScoreData?.type !== 'RISK_SCORE') {
      return false;
    }
    if ((groupId === riskScoreData.groupId)
      || (groupId === GENERAL_RISK_SCORE_GROUP_ID && !riskScoreData.groupId)
    ) {
      return true;
    }
    if (questionnaireId && questionnaireId === riskScoreData?.questionnaireId){
      return true;
    }
    return false;
  });

  return group;
}

export function getComponentId(component: any) {
  if (!component) {
    return undefined;
  }

  if (component.componentId) {
    return component.componentId;
  } else if (component.referenceId) {
    return component.referenceId;
  } else {
    component.referenceId = uuidv4();
    return component.referenceId;
  }
}

const addSequenceNumberToComponents = (components: any[], sequenceNumber = 0) => {
  if (!components?.length) {
    return { components, sequenceNumber };
  }

  components.forEach((field) => {
    sequenceNumber = sequenceNumber + 1;
    field.sequenceNumber = sequenceNumber;

    if (field.columns && field.columns.length > 0) {
      field.columns.forEach((column: any) => {
        const result = addSequenceNumberToComponents(column.components, sequenceNumber);
        sequenceNumber = result?.sequenceNumber;
      });
    }
    if (field.components && field.components.length > 0) {
      const result = addSequenceNumberToComponents(field.components, sequenceNumber);
      sequenceNumber = result?.sequenceNumber;
    }
  });

  return { components, sequenceNumber };
}

const findComponentByGroupId = (components: any[], groupId: string) => {
  if (!components?.length) {
    return undefined;
  }

  const component = components.find((field) => {
    const componentId = getComponentId(field);
    if (componentId === groupId) {
      return field;
    }
    if (field.columns && field.columns.length > 0) {
      field.columns.forEach((column: any) => {
        const component = addSequenceNumberToComponents(column.components);
        if (component) {
          return component;
        }
      });
    }
    if (field.components && field.components.length > 0) {
      const component = addSequenceNumberToComponents(field.components);
      if (component) {
        return component;
      }
    }
  });

  return component;
}

export const getSectionWiseRiskField = (
  formMetadata: IFormMetaData | undefined,
  components: any[],
  groupFields: IGroupField[],
  singleFields: ISingleField[],
  questionnaireList?: Resource[],
  handleSetInvalidRecordMap?: (newMap: Map<string, boolean>) => void
) => {
  const result = addSequenceNumberToComponents(components, 0);
  components = result.components;

  for (const component of components) {
    if (!component) {
      continue;
    }

    if (!isGroupComponent(component) && !component.isHealthComponentField && isRiskScoreField(component)) {
      singleFields.push(getFieldWithDetail(component, false));
    } else if (isGroupComponent(component)) {
      if (isRiskScoreEnabledHealthComponent(component)) {
        configureHealthComponentRiskScoreGroupAndField(component, groupFields, components, questionnaireList);
      } else {
        const groupField: IGroupField = {
          groupFieldName: component?.title || component?.label,
          groupId: getComponentId(component),
          isAdded: false,
          fields: [],
          sequenceNumber: component.sequenceNumber,
        };
        setGroupRiskField(component, groupField);
        if (groupField?.fields?.length) {
          groupFields.push(groupField);
        }
      }
    }
  }

  // const selectedGroupIds = getSelectedGroupIdsFromFormMetadata(formMetadata);

  const selectedGroupFields: IGroupField[] = [];
  const selectedSingleFields: ISingleField[] = [];
  const resultFields: ISectionRiskScoreState[] = [];

  const allGroupFields: IGroupField[] = [ ...(groupFields || []) ];

  const allSingleFields = allGroupFields.reduce((initialValue, groupField) => {
    if (groupField?.fields?.length) {
      return initialValue?.concat?.(groupField?.fields);
    }
    return initialValue;
  }, [ ...singleFields ]);

  if (allSingleFields?.length) {
    allGroupFields.push({
      fields: [ ...allSingleFields  ],
      groupId: GENERAL_RISK_SCORE_GROUP_ID,
      isAdded: false,
      groupFieldName: GENERAL_RISK_SCORE_GROUP_NAME,
      sequenceNumber: -1,
    });
  }

  allGroupFields.forEach((groupField) => {
    if (!groupField) {
      return;
    }
    const groupId = groupField?.groupId;
    const questionnaireId = groupField?.questionnaireId;
    const groupData = getGroupDataFromMetadata(formMetadata, groupId, questionnaireId);

    if (!groupData) {
      return;
    }

    const isGeneralGroup = (!groupId || groupId === GENERAL_RISK_SCORE_GROUP_ID);

    const selectedGroupField: IGroupField = {
      ...(JSON.parse(JSON.stringify(groupField))),
      fields: []
    };

    if (!isGeneralGroup) {
      groupField.isAdded = true;
      selectedGroupField.isAdded = true;
      selectedGroupFields.push(selectedGroupField);
    }

    const selectedGroupComponentIds = groupData.fields?.map?.((field) => {
      return field?.componentId || '';
    });

    (groupField.fields || []).forEach((field) => {
      const componentId = getComponentId(field?.component);
      if (selectedGroupComponentIds?.includes(componentId)) {
        if (isGeneralGroup) {
          field.isAddedAsSingleField = true;
          selectedSingleFields.push(field);
        } else {
          field.isAddedAsGroupField = true;
          selectedGroupField.fields.push(field);
        }
      }
    });

    const states = groupData.states;
    const component = findComponentByGroupId(components, groupId);

    const resultField: ISectionRiskScoreState = {
      groupId: groupId,
      questionnaireId: questionnaireId,
      name: groupData.name || GENERAL_RISK_SCORE_GROUP_NAME,
      operation: groupData.operation,
      riskScoreState: [],
      sequenceNumber: (component ? component?.sequenceNumber : -1),
      // fields: groupData.fields,
    };

    resultFields.push(resultField);
    const tempInvalidStateMap = new Map();
    (states || []).forEach((state) => {
      const isInvalid = !state?.state;
      const id = getRiskStateId();
      tempInvalidStateMap.set(id, true);
      resultField.riskScoreState.push({
        ...state,
        isInvalid: isInvalid,
        index: id,
      });
    });
    handleSetInvalidRecordMap?.(tempInvalidStateMap);
  });

  return {
    groupFields,
    singleFields,
    selectedGroupFields,
    selectedSingleFields,
    resultFields,
  };
};

const setGroupRiskField = (field: any, groupField: IGroupField) => {
  if (field?.columns?.length) {
    for(const column of field?.columns) {
      for (const columnComponent of column?.components) {
        setGroupRiskField(columnComponent, groupField);
      }
    }
  } else if(field?.components?.length) {
    for(const fieldComponent of field?.components) {
      setGroupRiskField(fieldComponent, groupField);
    }
  } else if(isRiskScoreField(field)) {
    groupField.fields.push(getFieldWithDetail(field, true, groupField));
  }
}

export const getFieldWithDetail = (component: any, isGroupField: boolean, groupField?: IGroupField) => {
  const response: ISingleField = {
    fieldId: component?.id,
    fieldName: component?.title || component?.label,
    isGroupField,
    component,
    type: component?.type,
    groupId: groupField?.groupId,
    optionList: getOptionsOfComponent(component),
    isAddedAsGroupField: false,
    isAddedAsSingleField: false,
    sequenceNumber: component.sequenceNumber,
    isAdded: false,
  };
  return response;
}


export const isRiskScoreField = (field: any) => {
  return field && field?.type && FIELD_TYPES_WITH_RISK_SCORE.includes(field?.type);
}

export const isGroupComponent = (component: any) => {
  return component?.columns?.length > 0 || component?.components?.length > 0 || isRiskScoreEnabledHealthComponent(component);
}

export const getFieldIconInfo = (field: ISingleField) => {
  switch(field.type) {
    case FormComponents.CUSTOM_SURVEY:
      return {lib: BasicComponents.customsurvey.iconLib || '', icon: BasicComponents.customsurvey.icon || ''};
    case FormComponents.CHECKBOX:
      return {lib: BasicComponents.checkbox.iconLib|| '', icon: BasicComponents.checkbox.icon || ''};
    case FormComponents.RADIO:
      return {lib: BasicComponents.radio.iconLib || '', icon: BasicComponents.radio.icon || ''};
    case FormComponents.SELECT:
      return {lib: BasicComponents.select.iconLib || '', icon: BasicComponents.select.icon || ''};
    case 'selectboxes':
      return {lib: BasicComponents.selectboxes.iconLib || '', icon: BasicComponents.selectboxes.icon || ''};
    case FormComponents.RATING:
      return {lib: BasicComponents.rating.iconLib || '', icon: BasicComponents.rating.icon || ''};
  }
};

export const SINGLE_GROUP_FIELD_ID = uuidv4();

export const getRiskStateId = () => {
  return uuidv4();
}

export function sortRiskScoreFields<T = ISingleField | IGroupField>(fields: T[]): T[] {

  if (!fields?.length) {
    return fields;
  }

  const result = fields.sort((field1: any, field2: any) => {
    if (field1.sequenceNumber === -1) {
      return 1;
    }

    const sequenceNumber1 = (field1?.sequenceNumber === undefined) ? -1 : field1?.sequenceNumber;
    const sequenceNumber2 = (field2?.sequenceNumber === undefined) ? -1 : field2?.sequenceNumber;

    if (sequenceNumber1 === -1) {
      return 1;
    }

    if (sequenceNumber2 === -1) {
      return -1;
    }

    return (sequenceNumber1 - sequenceNumber2);
  });

  if (fields?.length) {
    fields.forEach((field) => {
      if ((field as IGroupField).fields?.length) {
        (field as IGroupField).fields = sortRiskScoreFields((field as IGroupField).fields);
      }
    });
  }

  return result;
}

export const ALLOWED_QUESTION_TYPE_IN_RISK_SCORE = [QUESTION_TYPES.CHOICE, QUESTION_TYPES.OPEN_CHOICE, QUESTION_TYPES.MULTI_SELECT, QUESTION_TYPES.RADIO];

export const RISK_SCORE_ENABLED_HEALTH_COMPONENT = [FormComponents.SOCIAL_HISTORY];

export const isRiskScoreEnabledHealthComponent = (component: any) => {
  return RISK_SCORE_ENABLED_HEALTH_COMPONENT.includes(component?.type);
};

export const isRiskScoreTypeQuestion = (question: any) => {
  return ALLOWED_QUESTION_TYPE_IN_RISK_SCORE.includes(question?.type);
};

export const isRiskScoreTypeQuestionsExist = (questionList: Question[]) => {
  return (questionList || []).some(question => {
    return isRiskScoreTypeQuestion(question);
  });
};

export const getValuesForHealthComponent = (question: Question) => {
  const questionOptions: any[] = [];
  (question.answerOptions || []).forEach(option => {
    questionOptions.push({
      label: option?.display,
      riskScore: 0,
      groupRiskScore: 0,
      value: option?.code
    });
  });
  return questionOptions;
};

export const getSelectTypeHealthComponentField = (props: any) => {
  const componentId = generateUUID();
  const {questionnaire, question, groupField, sequenceNumber, healthComponent, type} = props;
  return {
    referenceId: componentId,
    questionnaireId: questionnaire?.id,
    questionId: question?.id,
    answerOption: question?.answerOptions,
    data: {
      values: getValuesForHealthComponent(question),
    },
    key: `select${componentId}`,
    type: 'select',
    inputType: 'select',
    id: componentId,
    isHealthComponentField: true,
    label: question?.text,
    healthComponentGroupId: groupField?.groupId || '',
    hidden: true,
    parentComponentId: getComponentId(healthComponent),
    sequenceNumber,
  };
}

export const getRadioTypeHealthComponentField = (props: any) => {
  const componentId = generateUUID();
  const { questionnaire, question, groupField, sequenceNumber, healthComponent } = props;
  return {
    referenceId: componentId,
    questionnaireId: questionnaire?.id,
    questionId: question?.id,
    answerOption: question?.answerOptions,
    values: getValuesForHealthComponent(question),
    key: `radio${componentId}`,
    type: 'radio',
    inputType: 'radio',
    id: componentId,
    isHealthComponentField: true,
    label: question.text,
    healthComponentGroupId: groupField?.groupId || '',
    hidden: true,
    parentComponentId: getComponentId(healthComponent),
    sequenceNumber,
  };
}

export const getMultiSelectTypeHealthComponentField = (props: any) => {
  const componentId = generateUUID();
  const { questionnaire, question, groupField,  sequenceNumber, healthComponent } = props;
  return {
    referenceId: componentId,
    questionnaireId: questionnaire?.id,
    questionId: question?.id,
    answerOption: question?.answerOptions,
    values: getValuesForHealthComponent(question),
    key: `checkbox_${componentId}`,
    type: 'selectboxes',
    inputType: 'selectboxes',
    id: componentId,
    isHealthComponentField: true,
    label: question.text,
    healthComponentGroupId: groupField?.groupId || '',
    hidden: true,
    parentComponentId: getComponentId(healthComponent),
    sequenceNumber,
  };
}

export const getComponentByQuestionType = (props: any) => {
  const { type, answerOptions,  repeats} = props;
  let questionType = type;
  if (answerOptions.length === 2) {
    questionType = 'radio';
  }
  if (repeats) {
    questionType = 'multiSelect';
  }
  switch (questionType) {
    case QUESTION_TYPES.CHOICE:
    case QUESTION_TYPES.OPEN_CHOICE:
      return getSelectTypeHealthComponentField(props);
    case QUESTION_TYPES.RADIO:
      return getRadioTypeHealthComponentField(props);
    case QUESTION_TYPES.MULTI_SELECT:
      return getMultiSelectTypeHealthComponentField(props);
  }
};

const findQuestionComponent = (components: any[], healthComponent: any, question: any, questionnaire: FHIRQuestionnaireObject) => {
  return (components || []).find(component => {
    return component?.isHealthComponentField &&
        component?.questionId === question?.id && component?.questionnaireId === questionnaire.id && component?.parentComponentId === getComponentId(healthComponent);
  });
};

const getSingleFieldsByQuestions = (questionList: Question[], healthComponent: any, components: any[], questionnaire: FHIRQuestionnaireObject, groupField: IGroupField) => {
  const singleFields: ISingleField[] = [];
  (questionList || []).forEach((question: any, index: number) => {
    if (isRiskScoreTypeQuestion(question)) {
      const singleFieldComponent = findQuestionComponent(components, healthComponent, question, questionnaire);
      if (singleFieldComponent?.id) {
        singleFields.push({
          component: singleFieldComponent,
          fieldId: singleFieldComponent?.id,
          questionId: question?.id,
          fieldName: question?.text || '',
          groupId: groupField?.groupId,
          isAdded: false,
          isAddedAsGroupField: false,
          isAddedAsSingleField: false,
          isGroupField: true,
          optionList: getOptionsOfComponent(singleFieldComponent),
          type: singleFieldComponent?.type,
          sequenceNumber: index,
        });
      }
    }
  });
  return singleFields;
}

export const configureHealthComponentRiskScoreGroupAndField = (healthComponent: any, groupFields: IGroupField[], components: any[], questionnaireList?: Resource[]) => {
  const newlyAddedComponent: any = [];
  try {
    (questionnaireList || []).forEach((questionnaire, index) => {
      const questionList = getQuestionListFromQuestionnaire(questionnaire?.resource);
      const groupField: IGroupField = {
        questionnaireId: questionnaire.resource?.id,
        groupFieldName: questionnaire?.resource?.name || '',
        groupId: generateUUID(),
        isAdded: false,
        fields: [],
        sequenceNumber: (groupFields?.length || 0) + 1,
      };
      const singleFields: ISingleField[] =  getSingleFieldsByQuestions(questionList, healthComponent, components, questionnaire?.resource, groupField);
      if (singleFields?.length) {
        groupField.fields = singleFields || [];
        groupFields.push(groupField);
      }
    });
  } catch(error) {
  }
  return newlyAddedComponent;
};
