import React, {useState, useEffect, useContext} from "react";
import {CommonWebSocket} from "../../utils/WebSocketUtils";
import {EventBus} from "../../utils/EventBus";
import {CUSTOM_MESSAGE_EVENT_CODES} from "../../constants/WebSocketConst";
import {ONLINE_STATUS} from "../../constants";

const OnlineStatusContext = React.createContext(true);

export const OnlineStatusProvider: React.FC = ({children}) => {
  const [onlineStatus, setOnlineStatus] = useState<boolean>(true);
  const onUserOnline = () => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.broadcastEvent(CUSTOM_MESSAGE_EVENT_CODES.USER_ONLINE_STATUS, {
      onlineStatus: ONLINE_STATUS.ONLINE
    })
    setOnlineStatus(true);


  };
  const onUserOffline =  () => {
    const eventBus = EventBus.getEventBusInstance();
    setOnlineStatus(false);
    eventBus.broadcastEvent(CUSTOM_MESSAGE_EVENT_CODES.USER_ONLINE_STATUS, {
      onlineStatus: ONLINE_STATUS.OFFLINE
    })
  };
  useEffect(() => {

    window.addEventListener("offline", onUserOffline);
    window.addEventListener("online", onUserOnline);

    return () => {
      window.removeEventListener("offline", onUserOnline);
      window.removeEventListener("online", onUserOffline);
    };
  }, []);

  return (
    <OnlineStatusContext.Provider value={onlineStatus}>
      {children}
    </OnlineStatusContext.Provider>
  );
};

export const useOnlineStatus = () => {
  const store = useContext(OnlineStatusContext);
  return store;
};
