// import {TableTopBar} from '../../../../common/TableTopBar';
import { useLazyQuery } from '@apollo/client';
import { Drawer, Popover, Select, Spin, notification } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import parse from 'html-react-parser';
import { Liquid } from 'liquidjs';
import moment from 'moment';
import {
  Checkbox,
  FormControl,
  HStack,
  Input,
  Radio,
  Text,
  VStack,
  useMediaQuery,
  useToast
} from 'native-base';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Dimensions, Pressable, View } from 'react-native';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {
  BUTTON_TYPE,
  DATE_FORMATS,
  GROUP_MEMBER_TYPE,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH
} from '../../../../../constants';
import { CommonDataContext } from '../../../../../context/CommonDataContext';
import ContactsQueries from '../../../../../services/Contacts/ContactsQueries';
import { Colors } from '../../../../../styles';
import {
  accountLocationUuidListByUserPracticeLocationList,
  getAccountId,
  getAccountUUID,
  getPrimaryGenderCode,
  getUserActionObjectFromActionCode,
  getUserPracticeLocations,
  getUserUUID,
  isContactConsentRequired,
  isLoginUserBusinessOwner,
  showEnvironmentInCampaign
} from '../../../../../utils/commonUtils';
import { ToastType, showToast } from '../../../../../utils/commonViewUtils';
import { getContactTypeId } from '../../../../../utils/mlovUtils';
import { isWeb } from '../../../../../utils/platformCheckUtils';
import { IStep } from '../../../../common/CustomStepView/interfaces';
import { DisplayCardAvatar } from '../../../../common/DisplayCard/DisplayCardAvatar';
import { ModalActionTitle } from '../../../../common/ModalActionTitle/ModalActionTitle';
import TitleSubtitleView from '../../../../common/TitleSubtitleView/TitleSubtitleView';
import { handleCreateCampaignAction } from '../../../../common/UsageLevel/UsageLevelHandlers';
import { getPreFilterMemberStatus, patientFilterForCreateSegment } from '../../../MembersManagement/Helper';
import {
  createCampaignSegment,
  createOrUpdateCampaign,
  exportCampaign,
  getAllTenants,
  getCampaignSegmentRules,
  getContactsWithinGroup,
  getGroupDetailById,
  getPopGroupLocationBasedContacts,
  updateCampaign
} from '../../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import { ContentTypes } from '../../ContentManagementConsts';
import {
  getAccountMergeTagData,
  getTemplateById,
  getTemplateCategories,
  getTemplateCategoryList
} from '../../ContentManagementUtils';
import { IMedlineSuggestion } from '../../EmailTemplates/CreateOrEditTemplate/CustomBlocks/MedlineSuggestions/MedlineSuggestionsUtils';
import { getFormattedSingleEmailTemplate } from '../../EmailTemplates/EmailTemplatesUtils';
import { ITemplateCategory } from '../../EmailTemplates/interfaces';
import { PushNotificationPreview } from '../../PushNotifications/PushNotificationPreview';
import { getFormattedPushNotification } from '../../PushNotifications/PushNotificationsUtils';
import { SMSPreview } from '../../SMSTemplates/SMSPreview';
import {
  getFormattedSingleSMSTemplate,
  getParseBody
} from '../../SMSTemplates/SMSTemplatesUtils';
import { SingleSMSTemplate } from '../../SMSTemplates/interfaces';
import { CampaignDetailsForm } from '../CampaignDetailsForm';
import { CampaignSegmentForm } from '../CampaignSegmentForm';
import { EmailOrSmsCreatorForm } from '../EmailOrSmsCreatorForm';
import NotificationTemplateCampaignPreview from '../NotificationTemplateCampaignPreview';
import { PreviousSegmentGroup } from '../PreviousSegmentGroup';
import { getAggregateWhereClause, initialAggregateState } from '../QueryBuilder/QueryBuilderHelper';
import SMSTemplateCampaignPreview from '../SMSTemplateCampaignPreview';
import {
  ICampaignDetails,
  ICampaignDetailsFormState,
  ICampaignErrors,
  ICampaignSegmentFormState
} from '../interfaces';
import { styles } from './AddOrUpdateCampaignStyles';
import { getFrequencyValue } from '../utils';
import { isAccountConfigEnabled } from '../../../../../utils/configUtils';
import { CONFIG_CODES } from '../../../../../constants/AccountConfigConst';
import { ILocationObject } from '../../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowTriggerView/interfaces';
import WorkflowLocationSelect from '../../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowTriggerView/WorkflowLocationSelect';
import { testID, TestIdentifiers } from '../../../../../testUtils';
import { POPGROUP_RELATED_SEGMENTS, SEGMENT_TYPES } from '../constants';
import { IAccountLocations, IUserPracticeLocations } from '../../../../../context/interfaces';

const { width } = Dimensions.get('window');
const getContactName = (contact: any): string => {
  return (
    (
      (contact?.person?.firstName || '') +
      ' ' +
      (contact?.person?.lastName || '')
    ).trim() ||
    contact?.name ||
    ''
  )
    .trim()
    .replace(/\w+/g, function (w: any) {
      return w
    });
};
const AddOrUpdateCampaignTabs = (props: {
  shouldShowEdit: boolean;
  initData?: any;
  close?: (status: string) => void;
  segmentId?: any;
  isDuplicate?: boolean;
  channel?: any;
  templateId?: any;
  showLeadAndPatient?: boolean;
  selectedContactLocationIds?:string[];
}) => {
  const intl = useIntl();
  const [segmentId, setSegmentId] = useState(
    props?.segmentId || props?.initData?.segmentId || ''
  );
  const [eligibleContactsCount, setEligibleContactsCount] = useState<number>(0);
  const isMsoEnabled = isAccountConfigEnabled( CONFIG_CODES.IS_MSO_ENABLED )
  const isBusinessOwner = isLoginUserBusinessOwner()
  const toast = useToast();
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    { maxWidth: IPAD_WIDTH },
    { maxWidth: IPAD_MINI_WIDTH },
  ]);
  const mlovData = useContext(CommonDataContext);
  const userPracticeLocationsObj = mlovData?.userPracticeLocations;
  const accountLocationUuids = useMemo(() => 
    userPracticeLocationsObj?.flatMap(
      (loc: IUserPracticeLocations) => loc?.accountLocations?.map((accLoc: IAccountLocations) => accLoc?.uuid)
    ), [userPracticeLocationsObj]);

  const [campaignPublishLoading, setCampaignPublishLoading] = useState<boolean>(false);
  const showPublishButton = () => {
    const check = props.initData?.status != 'ENDED' || props.isDuplicate
    return check;
  };
  const isConsentRequired = isContactConsentRequired();

  //let segmentId = searchParams?.get('segmentId') || undefined;
  const { isDuplicate, channel, templateId } = props;
  const [selectedGroupInfo, setSelectedGroupInfo] = useState<any>('');
  const [openToPopover, setOpenToPopover] = useState(false);
  const [openSchedulePopover, setOpenSchedulePopover] = useState(false);
  const [isTimeset, setIsTimeset] = useState<boolean>(false);

  const [template, setTemplate] = useState<any>();

  const campaignDetailsSteps = (): IStep[] => {
    return [
      {
        key: 'createOrEditSegmentDetails',
        title: intl.formatMessage({ id: 'audience' }),
        isDisabled: false,
      },
      {
        key: 'selectChannel',
        title: intl.formatMessage({ id: 'channel' }),
        isDisabled: false,
      },
      {
        key: 'createOrEditTemplates',
        title: intl.formatMessage({ id: 'content' }),
        isDisabled: false,
      },
      {
        key: 'preview',
        title: intl.formatMessage({ id: 'summary' }),
        isDisabled: false,
      },
    ];
  };

  const campaignChannelList = [
    {
      key: 'EMAIL',
      title: intl.formatMessage({ id: 'email' }),
      isChecked: true,
    },
    {
      key: 'SMS',
      title: intl.formatMessage({ id: 'sms' }),
      isChecked: false,
    },
    {
      key: 'NOTIFICATION',
      title: intl.formatMessage({ id: 'notification' }),
      isChecked: false,
    },
  ];

  const tenantId = getAccountUUID();
  const accountId = getAccountId();
  const userUuid = getUserUUID();
  const [emailTemplateId, setEmailTemplateId] = useState('');
  const [membersList, setMembersList] = useState<any[] | null>(null);
  const [openModal, setOpenModal] = useState<{
    type: string;
    data: any;
    customDrawerWidth?: number;
  }>({
    type: '',
    data: null as any,
    customDrawerWidth: undefined,
  });
  const [campaignId, setCampaignId] = useState(null);

  const [campaignDetails, setCampaignDetails] = useState<ICampaignDetails>({
    name: '',
    description: '',
    retryDelayInDays: '',
    enableReminder: false,
    numberOfRetry: '',
    patientFilter: {},
    type: ['email'],
    segmentType: '',
    channelType: campaignChannelList[0].key,
    metadata: undefined,
    locations: props?.initData?.campaignLocations ?? []
  });
  const [campaignDetailsError, setCampaignDetailsErrors] =
    useState<ICampaignErrors>({});
  const [categoryList, setCategoryList] = useState<ITemplateCategory[]>([]);
  const [getContactsByIds] = useLazyQuery(
    ContactsQueries.GET_CONTACTS_BY_CONTACTIDS,
    {
      fetchPolicy: 'no-cache',
      onError: (error: any) => {

      },
    }
  );
  const [getContactsByUuids] = useLazyQuery(
    ContactsQueries.GET_CONTACTS_BY_CONTACTUUIDS,
    {
      fetchPolicy: 'no-cache',
      onError: (error: any) => {
      },
    }
  );
  const patientTypeContactId = getContactTypeId(
    'CUSTOMER'
  );
  const [getContactAggregate] = useLazyQuery(
    ContactsQueries.GET_CONTACT_AGGREGATE,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
      onError: (error: any) => {

      },
    }
  );

  const [aggregates, setAggregates] = useState(initialAggregateState);

  const userActionObject =
    getUserActionObjectFromActionCode('CREATE_A_CAMPAIGN');
  const level = userActionObject.level || '';

  const getContactsByRule = async (groupDetails: any) => {
    const id = groupDetails.id;
    if (isMsoEnabled && !isBusinessOwner) {
      const ruleIds = [id]
      const groupLocations = groupDetails?.ruleLocations?.map((loc: any) => loc?.locationId) || []
      const resp = await getPopGroupLocationBasedContacts({
        ruleIds,
        accountLocationIds:groupLocations
      });

      if (resp?.data) {
        const firstKey = Object.keys(resp?.data)[0];
        const contactUuids = resp?.data[firstKey];
      
        const contactResponse: { [key: string]: any } = await getContactsByUuids({
          variables: {
            contactUuidList: contactUuids,
          },
        });

        const contacts = contactResponse?.data?.contacts || [];
        const contactIds = contacts.map((contact: any) => contact?.id);
        
        contactResponse['contactIds'] = contactIds;

        const ruleLoc = groupLocations?.filter((loc: string) =>
          accountLocationUuids?.includes(loc)
        );
    
        if (ruleLoc?.length) {
          contactResponse['filteredCampaignLoc'] = ruleLoc;
        }
        return contactResponse;
    }
    }
    if (
      groupDetails?.rule?.patientFilter?.contactIds &&
      groupDetails?.rule?.patientFilter?.contactIds?.length
    ) {
      const variables : any = {
        contactIdList: (groupDetails?.rule?.patientFilter?.contactIds||[]).slice(0,150)
      }
      const responseData:any = await getContactsByIds({
        variables: variables
      });
      responseData.contactIds = groupDetails?.rule?.patientFilter?.contactIds;
      return responseData
    }
    const response = await getContactsWithinGroup(id, undefined);
    const contactIds = response?.data?.data || [];
    const responseData:any = await getContactsByIds({
      variables: {
        contactIdList: (contactIds||[]).slice(0,150),
      },
    });
    responseData.contactIds = contactIds;
    return responseData;
  }

  useEffect(() => {
    const channelType = channel
      ? campaignChannelList.find((item) => item.key.toLowerCase() == channel)
        ?.key
      : props?.initData?.channelType;

    if (props?.initData?.emailTemplateId || (channel && templateId)) {
      const templateToFetch = props?.initData?.emailTemplateId || templateId;
      const path =
        channelType == 'EMAIL'
          ? ContentTypes.emails.path
          : channelType == 'NOTIFICATION'
            ? ContentTypes.pushNotifications.path
            : channelType == 'SMS'
              ? ContentTypes.textMessages.path
              : '';
      setCampaignDetails((prev) => {
        return {
          ...prev,
          channelType,
        };
      });
      getTemplateById(path, templateToFetch)
        .then((data) => {
          const formattedData =
            channelType == 'EMAIL'
              ? getFormattedSingleEmailTemplate(data)
              : channelType == 'NOTIFICATION'
                ? getFormattedPushNotification(data)
                : channelType == 'SMS'
                  ? getFormattedSingleSMSTemplate(data)
                  : null;
          if (formattedData) {
            setTemplate({
              type: channelType,
              data: formattedData,
            });

            setEmailTemplateId(templateToFetch);
          }
        })
        .catch((error) => {});
    }
    if (segmentId && campaignDetails?.segmentType != SEGMENT_TYPES.CREATE_AUDIENCE) {
      getGroupDetailById(segmentId).then((resp) => {
        const groupDetails = resp?.data;
        if (groupDetails) {
          if (groupDetails?.rule && groupDetails.id) {
            let locationIds = groupDetails?.ruleLocations?.map((item:any) => item?.locationId);
            getContactsByRule(groupDetails).then((respInner:any) => {
              if (respInner?.filteredCampaignLoc) locationIds = respInner?.filteredCampaignLoc
              if (!locationIds?.length && respInner?.data?.contacts?.length > 0 && isMsoEnabled) {
                locationIds = respInner?.data?.contacts?.flatMap((contact: any) =>
                  contact?.contactPracticeLocations?.map((loc: any) => loc?.accountLocation?.uuid)
                );
              }
              setMembersList(respInner?.data?.contacts);
              setSelectedGroupInfo(groupDetails?.name);
              const contactIds = respInner?.contactIds;
              getContactAggregate({
                variables: getAggregateWhereClause(Number(accountId), {
                  contactIds: contactIds,
                  segmentType: campaignDetails.segmentType,
                  segmentTypeValue: campaignDetails.segmentTypeValue,
                  patientTypeContactId: patientTypeContactId,
                  mlovData,
                  locationUuids: locationIds || []
                }),
              })
                .then((response) => {
                  setAggregates((prev) => {
                    return {
                      ...prev,
                      total: contactIds?.length || 0,
                      emailTotal:
                        response?.data?.emailTotal?.aggregate?.count || 0,
                      emailConsent:
                        response?.data?.emailConsent?.aggregate?.count || 0,
                      smsTotal: response?.data?.smsTotal?.aggregate?.count || 0,
                      smsConsent:
                        response?.data?.smsConsent?.aggregate?.count || 0,
                      notificationTotal:
                      contactIds?.length || 0,
                      notificationConsent:
                        response?.data?.notificationConsent?.aggregate?.count ||
                        0,
                    };
                  });
                })
                .catch((error) => {

                });

              if (props?.initData?.id) {
                setCampaignId(props.initData?.id);
                setCampaignDetails({
                  ...campaignDetails,
                  retryDelayInDays: props?.initData?.retryDelayInDays,
                  enableReminder: false,
                  type: ['email'],
                  name: props?.initData?.name,
                  description: props?.initData?.description,
                  isImmediate: props?.initData?.isImmediate,
                  numberOfRetry: props?.initData?.numberOfRetry,
                  smsText: props?.initData?.smsText,
                  startDate: props?.initData?.startDate,

                  audienceName: groupDetails?.name,
                  channelType:
                    props?.initData?.channelType ||
                    channelType ||
                    campaignChannelList[0].key,
                  segmentTypeValue: props?.initData?.segmentTypeValue,
                  segmentType:props?.initData?.segmentType ||
                    (groupDetails?.name || respInner?.data?.contacts
                      ? 'previousGroup'
                      : 'customSelected')
                });
                setIsTimeset(true);
                setEmailTemplateId(props?.initData?.emailTemplateId);
              } else {
                setCampaignDetails({
                  ...campaignDetails,
                  name: groupDetails?.name || '',
                  channelType:
                    campaignDetails.channelType ||
                    props?.initData?.channelType ||
                    channelType ||
                    campaignChannelList[0].key,
                  audienceName: groupDetails?.name || '',
                  segmentType:
                    groupDetails.name || respInner?.data?.contacts
                      ? 'previousGroup'
                      : 'customSelected',
                  locations: locationIds || []
                });
              }
            });
          } else {
            if (props?.initData?.id) {
              setSelectedGroupInfo(groupDetails.name);
              setCampaignId(props.initData?.id);
              setIsTimeset(true);
              setCampaignDetails({
                ...campaignDetails,
                retryDelayInDays: props?.initData?.retryDelayInDays,
                enableReminder: false,
                type: ['email'],
                name: props?.initData?.name,
                description: props?.initData?.description,
                isImmediate: props?.initData?.isImmediate,
                numberOfRetry: props?.initData?.numberOfRetry,
                smsText: props?.initData?.smsText,
                startDate: props?.initData?.startDate,
                patientFilter: {
                  jsonTree: groupDetails?.displayJson,
                },
                segmentTypeValue: props?.initData?.segmentTypeValue,
                segmentType:props?.initData?.segmentType ||(groupDetails.name
                  ? 'previousGroup'
                  : 'customSelected'),
                audienceName: groupDetails?.name,
                channelType:
                  props?.initData?.channelType ||
                  channelType ||
                  campaignChannelList[0].key,
              });
              setEmailTemplateId(props?.initData?.emailTemplateId);
            }
          }
        }
      });
    } else {
      if (props?.initData?.id) {
        getContactAggregate({
          variables: getAggregateWhereClause(Number(accountId), {
            segmentType: props?.initData?.segmentType || campaignDetails.segmentType,
            segmentTypeValue: props?.initData?.segmentTypeValue || campaignDetails.segmentTypeValue,
            patientTypeContactId: patientTypeContactId,
            mlovData,
            locationUuids: campaignDetails?.locations
          }),
        })
          .then((response) => {
            setAggregates((prev) => {
              return {
                ...prev,
                total: response?.data?.total?.aggregate?.count || 0,
                emailTotal: response?.data?.emailTotal?.aggregate?.count || 0,
                emailConsent:
                  response?.data?.emailConsent?.aggregate?.count || 0,
                smsTotal: response?.data?.smsTotal?.aggregate?.count || 0,
                smsConsent: response?.data?.smsConsent?.aggregate?.count || 0,
                notificationTotal: response?.data?.total?.aggregate?.count || 0,
                notificationConsent:
                  response?.data?.notificationConsent?.aggregate?.count || 0,
              };
            });
          })
          .catch((error) => {

          });

        setCampaignId(props.initData?.id);
        setIsTimeset(true);
        setCampaignDetails({
          ...campaignDetails,
          retryDelayInDays: props?.initData?.retryDelayInDays,
          enableReminder: false,
          type: ['email'],
          name: props?.initData?.name,
          description: props?.initData?.description,
          isImmediate: props?.initData?.isImmediate,
          numberOfRetry: props?.initData?.numberOfRetry,
          smsText: props?.initData?.smsText,
          startDate: props?.initData?.startDate,
          segmentTypeValue: props?.initData?.segmentTypeValue,
          segmentType:props?.initData?.segmentType || 'customSelected',
          audienceName: undefined,
          channelType:
            props?.initData?.channelType ||
            channelType ||
            campaignChannelList[0].key,
        });
        setEmailTemplateId(props?.initData?.emailTemplateId);
      }
    }
  }, [segmentId]);

  useEffect(() => {
    getTemplateCategories()
      .then((data) => {
        return getTemplateCategoryList(data);
      })
      .then((list) => {
        setCategoryList(list);
      })
      .catch((error) => {});
  }, []);



  useEffect(() => {
    const onSubmitDataEvent = (event: any) => {
      const keyName = event.key;
      if (event.shiftKey && keyName === 'Enter') {
        onSubmit();
      }
    }
    if (isWeb() && window) {
      window.addEventListener('keydown',onSubmitDataEvent,false);
    }
    return () => {
      if (isWeb() && window) {
        window.removeEventListener('keydown', onSubmitDataEvent);
      }
    }
  }, []);

  const validateCampaignDetailsForm = (): boolean => {
    const errorFields: ICampaignErrors = {};

    if (!campaignDetails.name) {
      errorFields.campaignName = intl.formatMessage({ id: 'required' });
    }
    if (
      (!campaignDetails.isImmediate && !campaignDetails.startDate) ||
      campaignDetails.isImmediate == undefined
    ) {
      errorFields.campaignTime = intl.formatMessage({ id: 'required' });
    }
    if (
      campaignDetails.enableReminder &&
      (!campaignDetails.numberOfRetry || !campaignDetails.retryDelayInDays)
    ) {
      errorFields.reminderError = intl.formatMessage({ id: 'required' });
    }

    if (!campaignDetails?.segmentType) {
      errorFields.segmentError = 'Atleast one of segment is required';
    }

    if ((!campaignDetails?.locations?.length && !props?.selectedContactLocationIds) && (isMsoEnabled)) {
      errorFields.locationError = intl.formatMessage({ id: 'Atleast one of location is required' });
    }

    if (!campaignDetails.type?.length) {
      errorFields.campaignType = intl.formatMessage({ id: 'required' });
    }

    if (!membersList?.length) {
      if (
        campaignDetails?.segmentType != 'customSelected' &&
        !campaignDetails?.audienceName
      ) {
        errorFields.audienceName = 'Name is required for custom segment';
      }
      if (
        !campaignDetails?.patientFilter?.jsonTree &&
        !campaignDetails?.patientFilter?.contactIds?.length &&
        ['customSelected', 'previousGroup'].indexOf(campaignDetails?.segmentType || '') == -1
      ) {
        errorFields.segmentError = 'At least one filter is required';
      }
    }

    if (!emailTemplateId) {
      errorFields.emailTemplateError = intl.formatMessage({
        id: 'templateRequired',
      });
    }

    if (campaignDetailsError != errorFields) {
      setCampaignDetailsErrors(errorFields);
    }
    if (JSON.stringify(errorFields) === '{}') {
      return true;
    } else {
      return false;
    }
  };

  const patientFilterFormatter = (): object => {
    return {
      name: selectedGroupInfo
        ? selectedGroupInfo
        : campaignDetails?.audienceName || '',
      metadata: { patientFilter: getPatientFilterForCreateSegment() },
      type:
        campaignDetails?.segmentType == 'createAudience' ? 'GENERAL' : 'STATIC',
    };
  };

  const createOrUpdateCampaignLocal = (segmentId: any): void => {
    if (campaignId && !isDuplicate) {
      updateCampaign({
        campeignId: campaignId,
        campeignData: {
          name: campaignDetails.name,
          segmentType: campaignDetails.segmentType,
          segmentTypeValue: campaignDetails.segmentTypeValue,
          description: campaignDetails.description,
          isImmediate: campaignDetails.isImmediate || false,
          startDate: campaignDetails.isImmediate
            ? null
            : campaignDetails.startDate || null,
          numberOfRetry: parseInt(campaignDetails.numberOfRetry) || 0,
          retryDelayInDays: parseInt(campaignDetails.retryDelayInDays) || 0,
          emailTemplateId: parseInt(emailTemplateId),
          channelType: campaignDetails.channelType,
          locations: campaignDetails?.locations,
          isMsoEnabled: isMsoEnabled,
          mappedLocations:props?.initData?.campaignLocations ?? [],
          segmentId: segmentId ? segmentId : undefined,
        },
        campaignSegmentId: segmentId ? segmentId : undefined,
        campaignSegmentData: segmentId ? patientFilterFormatter() : undefined,
      })
        .then((response) => {
          if (response?.updateCampaign || response?.insertCampaign) {
            setCampaignId(
              response?.updateCampaign?.id || response?.insertCampaign?.id
            );
            props?.close?.('updated');
          }
        })
        .catch((error) => {
          setCampaignPublishLoading(false);
        });
    } else {
      if (isExportSupported && isDuplicate && (copyState.isSelectAll || copyState?.tenants?.length)) {
        const selectTenants = (copyState.isSelectAll ? tenants : copyState.tenants);
        const campaignData = {
          name: campaignDetails.name,
          description: campaignDetails.description,
          segmentType: campaignDetails.segmentType,
          segmentTypeValue: campaignDetails.segmentTypeValue,
          isImmediate: campaignDetails.isImmediate || false,
          startDate: campaignDetails.isImmediate
            ? null
            : campaignDetails.startDate || null,
          numberOfRetry: parseInt(campaignDetails.numberOfRetry) || 0,
          tenantId,
          // campaignSegment: {data: patientFilterFormatter()},
          retryDelayInDays: parseInt(campaignDetails.retryDelayInDays) || 0,
          emailTemplateId: parseInt(emailTemplateId),
          segmentId: segmentId ? segmentId : undefined,
          channelType: campaignDetails.channelType,
          metadata: campaignDetails?.metadata,
        }
        exportCampaign({ campaignData: campaignData, tenants: selectTenants }).finally(() => {
          props?.close?.('created');
        });
      } else {
        createOrUpdateCampaign({
          name: campaignDetails.name,
          description: campaignDetails.description,
          segmentType: campaignDetails.segmentType,
          segmentTypeValue: campaignDetails.segmentTypeValue,
          isImmediate: campaignDetails.isImmediate || false,
          startDate: campaignDetails.isImmediate
            ? null
            : campaignDetails.startDate || null,
          numberOfRetry: parseInt(campaignDetails.numberOfRetry) || 0,
          tenantId,
          // campaignSegment: {data: patientFilterFormatter()},
          retryDelayInDays: parseInt(campaignDetails.retryDelayInDays) || 0,
          emailTemplateId: parseInt(emailTemplateId),
          segmentId: segmentId ? segmentId : undefined,
          channelType: campaignDetails.channelType,
          metadata: campaignDetails?.metadata,
          locations: (campaignDetails?.locations && campaignDetails.locations.length > 0) ? campaignDetails.locations : props?.selectedContactLocationIds,
          isMsoEnabled: isMsoEnabled
        })
        .then((response) => {
          handleCreateCampaignAction({
            accountUuid: tenantId,
            userUuid: userUuid,
            level,
          }).then((r) => {
            if (response?.updateCampaign || response?.insertCampaign) {
              setCampaignId(
                response?.updateCampaign?.id || response?.insertCampaign?.id
              );
              props?.close?.('created');
            }
          });
        })
        .catch((error) => {
          setCampaignPublishLoading(false);
        });
      }
    }
  };

  const onCampaignDetailsFormChange = (
    value: ICampaignDetailsFormState
  ): any => {
    if (
      value.campaignTime.type == 'immediate' ||
      (value.campaignTime.type == 'scheduleOnDate' && value.campaignTime.value)
    ) {
      setIsTimeset(true);
    }
    setCampaignDetails({
      ...campaignDetails,
      name: value.name,
      description: value.description,
      isImmediate: value.campaignTime.type == 'immediate',
      startDate: value.campaignTime.value
        ? moment(value.campaignTime.value).format()
        : '',
      numberOfRetry: value.numberOfRetry,
      retryDelayInDays: value.interval,
      type: value.type,
      enableReminder: value.enableReminder,
    });
  };

  const onCampaignSegmentFormChange = (
    value: ICampaignSegmentFormState
  ): any => {
    setCampaignDetails({
      ...campaignDetails,
      audienceName: value.audienceName,
    });
  };

  const onDrawerHeader = (type?: string): JSX.Element => {
    let element: JSX.Element = <></>;
    switch (type) {
      case 'createAudience':
        element = (
          <>
            <TitleSubtitleView
              isTitleSubtitleOldView={true}
              isHideCommonButton={true}
              containerStyle={{ marginLeft: 0, marginBottom: 0 }}
              titleLabelId={'createAudienceGroup'}
              subtitleLabelId=""
            />
          </>
        );
        break;
      case 'previousGroup':
        element = (
          <>
            <TitleSubtitleView
              isHideCommonButton={true}
              containerStyle={{ marginLeft: 0, marginBottom: 0 }}
              titleLabelId={'selectPreviousGroup'}
              subtitleLabelId=""
            />
          </>
        );
        break;
      default:
        break;
    }
    return element;
  };

  const finalWidth =
    isIPadScreen || isIPadMiniScreen ? width * 0.6 : width * 0.5;

  const segmentTabs = [
    {
      displayText: 'All Patients & Contacts',
      type: 'customSelected',
      value: 'customSelected'
    },
    {
      displayText: 'Patients',
      type: 'customSelected',
      value: 'allPatient'
    },
    {
      displayText: 'Active Patients',
      type: 'customSelected',
      value: 'activePatient'
    },
    {
      displayText: 'Inactive Patients',
      type: 'customSelected',
      value: 'inactivePatient'
    },
    {
      displayText: 'All Leads & Contacts',
      type: 'customSelected',
      value: 'allLead'
    },
    {
      displayText: 'Select Existing Group',
      type: 'previousGroup',
      value: 'previousGroup'
    },
    {
      displayText: 'Create New Group',
      type: 'createAudience',
      value: 'createAudience'
    },
  ];

  const getPatientFilterForCreateSegment = (): object => {
    return campaignDetails.patientFilter;
  };

  const createSegmentId = (callCreateOrUpdateApi: boolean) => {
    createCampaignSegment({
      name: selectedGroupInfo
        ? selectedGroupInfo
        : campaignDetails?.audienceName || '',
      tenantId: tenantId,
      metadata: {
        patientFilter: getPatientFilterForCreateSegment(),
      },
    })
      .then((resp: any) => {
        if (resp?.id) {
          setSegmentId(resp?.id);
          if (callCreateOrUpdateApi) {
            createOrUpdateCampaignLocal(resp?.id);
          }
        }
      })
      .catch((error) => {
        showToast(
          toast,
          error?.response?.data?.message || 'Something went wrong',
          ToastType.error,
        );
        setCampaignPublishLoading(false);
      });
  };

  const setLatestData = async (campaignSegmentFormData: any) => {
    setSelectedGroupInfo(campaignSegmentFormData.audienceName);
    const locationIds = campaignSegmentFormData?.selectedAccountLocations?.map((location: { uuid: string }) =>location?.uuid)
    if (
      campaignSegmentFormData.patientList &&
      campaignSegmentFormData.patientList.length > 0
    ) {
      const contactIdss = campaignSegmentFormData.patientList;
      const patientFilters = {
        contactIds: contactIdss,
      };
      await getContactsByIds({
        variables: {
          contactIdList: campaignSegmentFormData.patientList,
        },
      }).then((resp) => {
        const contactIds = resp?.data?.contacts?.map((item: any) => item?.id);
        getContactAggregate({
          variables: getAggregateWhereClause(Number(accountId), {
            contactIds: contactIds,
            segmentType: campaignDetails.segmentType,
            segmentTypeValue: campaignDetails.segmentTypeValue,
            patientTypeContactId: patientTypeContactId,
            mlovData,
            locationUuids: locationIds
          }),
        })
          .then((response) => {
            setAggregates((prev) => {
              return {
                ...prev,
                total: response?.data?.total?.aggregate?.count || 0,
                emailTotal: response?.data?.emailTotal?.aggregate?.count || 0,
                emailConsent:
                  response?.data?.emailConsent?.aggregate?.count || 0,
                smsTotal: response?.data?.smsTotal?.aggregate?.count || 0,
                smsConsent: response?.data?.smsConsent?.aggregate?.count || 0,
                notificationTotal: response?.data?.total?.aggregate?.count || 0,
                notificationConsent:
                  response?.data?.notificationConsent?.aggregate?.count || 0,
              };
            });
          })
          .catch((error) => {

          });
        setMembersList(resp?.data?.contacts);
      });
      setCampaignDetails({
        ...campaignDetails,
        audienceName: campaignSegmentFormData.audienceName,
        segmentType: 'previousGroup',
        patientFilter: patientFilters,
        locations: locationIds
      });
    } else {
      setMembersList([]);
      setCampaignDetails({
        ...campaignDetails,
        audienceName: campaignSegmentFormData.audienceName,
        segmentType: 'previousGroup',
        patientFilter: {
          jsonTree: campaignSegmentFormData.jsonTree,
        },
        locations: locationIds
      });
    }
    if (!props?.shouldShowEdit && props?.segmentId) {
      createSegmentId(false);
    }
  };

  const getAllMergeTags = (
    category: string,
    categories: ITemplateCategory[]
  ) => {
    const mergeTagsByCategory = categories.find(
      (item) => item.name === category
    )?.mergeTags;
    return { ...mergeTagsByCategory, global: accountMergeTags };
  };

  const getPreviewHtml = (
    html: string,
    templateData?: any,
    suggestion?: IMedlineSuggestion[]
  ): string => {
    if (!html) {
      return '';
    }
    const engine = new Liquid();
    const mergeTags = {
      suggestions: suggestion,
    };
    const finalMergeTag = {
      ...getAllMergeTags(templateData?.templateCategory, categoryList),
      ...mergeTags,
    };
    try {
      const tpl = engine.parse(html);
      return engine.renderSync(tpl, finalMergeTag);
    } catch (error) {
      return '';
    }
  };

  const accountMergeTags = getAccountMergeTagData({hideLogo: true});

  const getMergeTags = (smsTemplateData?: SingleSMSTemplate) => {
    if (smsTemplateData?.category) {
      return {
        ...smsTemplateData?.category?.mergeTags,
        global: accountMergeTags,
      };
    }
    return {
      global: accountMergeTags,
    };
  };

  const onSubmit = () => {
    setCampaignPublishLoading(true);
    const isValidated = validateCampaignDetailsForm();
    if (isValidated) {
      if (
        !props?.shouldShowEdit &&
        !segmentId &&
        campaignDetails?.segmentType !== 'customSelected'
      ) {
        createSegmentId(true);
      } else {
        createOrUpdateCampaignLocal(segmentId);
      }
    }else{
      setCampaignPublishLoading(false);
    }
  };

  const renderPreview = (): JSX.Element => {
    return (
      <>
        <HStack
          flex={1}
          style={{
            marginTop: 30,
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: 400,
          }}
        >
          {template?.type == 'SMS' ? (
            <HStack
              style={[styles.templateCard]}
              flex={1}
            >
              <VStack flex={1} style={[{ padding: 40 }]}>
                <HStack style={styles.readonlyLineContainer}>
                  <Text paddingTop={'3px'} width={100} fontWeight={300}>
                    Name
                  </Text>
                  <Text
                    style={styles.readPillContainer}
                    fontWeight={500}
                    numberOfLines={1}
                  >
                    {template?.data?.name || ''}
                  </Text>
                </HStack>

                <HStack style={styles.readonlyLineContainer}>
                  <Text paddingTop={'3px'} width={100} fontWeight={300}>
                    Category
                  </Text>
                  <Text
                    style={styles.readPillContainer}
                    fontWeight={500}
                    numberOfLines={1}
                  >
                    {template?.data?.category?.name || ''}
                  </Text>
                </HStack>
                <HStack style={styles.readonlyLineContainer}>
                  <Text paddingTop={'3px'} width={100} fontWeight={300}>
                    Body
                  </Text>
                  <Text
                    style={styles.readPillContainer}
                    fontWeight={500}
                  //numberOfLines={1}
                  >
                    {template?.data?.body || ''}
                  </Text>
                </HStack>
              </VStack>
              <SMSPreview
                bodyText={
                  getParseBody({
                    bodyString: template?.data?.body || '',
                    mergeTags: getMergeTags(template?.data),
                  }) || 'Body'
                }
              />
            </HStack>
          ) : template?.type == 'EMAIL' ? (
            <View style={{ flex: 1 }}>
              {parse(
                getPreviewHtml(
                  template ? (template?.data?.templateHtml as string) : '',
                  template?.data,
                  campaignDetails.metadata?.mergeTags?.suggestions
                )
              )}
            </View>
          ) : template?.type == 'NOTIFICATION' ? (
            <VStack
              justifyItems={'center'}
              alignSelf={'center'}
              style={[styles.templateCard, { padding: 40 }]}
            >
              <HStack style={styles.readonlyLineContainer}>
                <Text paddingTop={'3px'} width={100} fontWeight={300}>
                  Name
                </Text>
                <Text
                  style={styles.readPillContainer}
                  fontWeight={500}
                  numberOfLines={1}
                >
                  {template?.data?.name || ''}
                </Text>
              </HStack>

              <HStack style={styles.readonlyLineContainer}>
                <Text paddingTop={'3px'} width={100} fontWeight={300}>
                  Category
                </Text>
                <Text
                  style={styles.readPillContainer}
                  fontWeight={500}
                  numberOfLines={1}
                >
                  {template?.data?.category?.name || ''}
                </Text>
              </HStack>
              <HStack style={styles.readonlyLineContainer}>
                <Text paddingTop={'3px'} width={100} fontWeight={300}>
                  Body
                </Text>
                <Text
                  style={styles.readPillContainer}
                  fontWeight={500}
                //numberOfLines={1}
                >
                  {template?.data?.body || ''}
                </Text>
              </HStack>
              <PushNotificationPreview
                notificationBody={
                  getParseBody({
                    bodyString: template?.data?.body || '',
                    mergeTags: getMergeTags(template?.data),
                  }) || 'Body'
                }
                notificationSubject={template?.data?.subject || 'Title'}
              />
            </VStack>
          ) : (
            <></>
          )}
        </HStack>
      </>
    );
  };

  const createSegmentGroup = (
    campaignSegmentFormData: ICampaignSegmentFormState,
    initialLocationIdsList?: string[],
  ) => {
    // popgroup location handling
    const selectedAccountLocations = campaignSegmentFormData?.selectedAccountLocations || [];
    let locationIdList = selectedAccountLocations?.map((location) => {
      return { locationId: location?.uuid };
    });
    const selectedLocationIds = new Set(selectedAccountLocations.map(location => location?.uuid));
    const deletedLocations = initialLocationIdsList
      ?.filter(locationId => !selectedLocationIds.has(locationId))
      .map(locationId => ({ locationId, isDeleted: true }));

    locationIdList = locationIdList?.concat(deletedLocations || []);
    // popgroup location handling 
    createCampaignSegment({
      id: campaignSegmentFormData?.id || '',
      name: campaignSegmentFormData?.audienceName || '',
      tenantId: tenantId,
      description: campaignSegmentFormData?.description || '',
      metadata: {
        patientFilter: patientFilterForCreateSegment(campaignSegmentFormData),
      },
      ...!campaignSegmentFormData.patientList?.length && (getPreFilterMemberStatus(campaignSegmentFormData)),
      executionFrequency: campaignSegmentFormData?.executionFrequency,
      automationToUpdate: campaignSegmentFormData?.automationToUpdate || [],
      locationIdList: locationIdList,
    })
      .then(async (resp: any) => {
        if (resp?.id) {
          let popGroupResponse = resp;
          if (resp?.inputRuleId) {
            popGroupResponse = await getCampaignSegmentRules(resp?.inputRuleId);
          }
          notification.success({
            message:
              resp.type == 'DYNAMIC'
                ? `${intl.formatMessage({id: 'popGroupFrequencyMessage'})} ${getFrequencyValue(popGroupResponse)}.`
                : intl.formatMessage({id: 'createGroupSuccessMessage'}),
          });
          setSegmentId(resp?.id);
          setLatestData(campaignSegmentFormData);
          setOpenModal({
            type: '',
            data: null,
          });
        }
      })
      .catch((error) => {
        notification.error({
          message: error?.response?.data?.message || 'Something went wrong',
        });
      });
  };

  const onDrawerContent = (type?: string): JSX.Element => {
    let element: JSX.Element = <></>;
    switch (type) {
      case 'templateGallery':
        element = (
          <>
            {campaignDetails.channelType === 'EMAIL' && (
              <EmailOrSmsCreatorForm
                emailTemplateId={undefined}
                campaignDetailsError={campaignDetailsError}
                onCancel={() => {
                  setOpenModal({
                    type: '',
                    data: null,
                  });
                }}
                onTemplateIdSelected={(value: any) => {
                  setEmailTemplateId(value.id);
                  setTemplate({
                    type: 'EMAIL',
                    data: value,
                  });
                  setCampaignDetailsErrors({});
                }}
              />
            )}
            {campaignDetails.channelType === 'NOTIFICATION' && (
              <NotificationTemplateCampaignPreview
                emailTemplateId={undefined}
                campaignDetailsError={campaignDetailsError}
                onTemplateIdSelected={(value: any) => {
                  setEmailTemplateId(value.id);
                  setTemplate({
                    type: 'NOTIFICATION',
                    data: value,
                  });
                  setCampaignDetailsErrors({});
                }}
                onCancel={() => {
                  setOpenModal({
                    type: '',
                    data: null,
                  });
                }}
              />
            )}

            {campaignDetails.channelType === 'SMS' && (
              <SMSTemplateCampaignPreview
                emailTemplateId={undefined}
                onCancel={() => {
                  setOpenModal({
                    type: '',
                    data: null,
                  });
                }}
                campaignDetailsError={campaignDetailsError}
                onTemplateIdSelected={(value: any) => {
                  setEmailTemplateId(value?.id);
                  setCampaignDetailsErrors({});
                  setTemplate({
                    type: 'SMS',
                    data: value,
                  });
                }}
              />
            )}
          </>
        );
        break;
      case 'createAudience':
        element = (
          <>
            <View style={{ marginHorizontal: 0 }}>
              <Content className="common-scroll">
                <CampaignSegmentForm
                  campaignDetailsError={campaignDetailsError}
                  showLeadAndPatient={props?.showLeadAndPatient}
                  onModalClose={() => {
                    setOpenModal({
                      type: '',
                      data: null,
                      customDrawerWidth: undefined,
                    });
                  }}
                  onColumnViewChange={(columns: number) => {
                    setOpenModal((prev) => ({...prev, customDrawerWidth: columns > 1 ? width * 0.75 : undefined}));
                  }}
                  onSubmitForm={(
                    campaignSegmentFormData: ICampaignSegmentFormState,
                    callback?: any,
                    initialLocationIdsList?: string[],
                  ) => {
                    createSegmentGroup(campaignSegmentFormData, initialLocationIdsList);
                  }}
                  locationOptionList={campaignDetails?.locations ?? []}
                />
              </Content>
            </View>
          </>
        );
        break;
      case 'previousGroup':
        element = (
          <>
            <PreviousSegmentGroup
              segmentId={segmentId}
              onModalClose={() => {
                setOpenModal({
                  type: '',
                  data: null,
                });
              }}
              onSubmitForm={(group: any, eligibleCount?: number) => {
                setSelectedGroupInfo(group?.name);

                //get segment Id
                setSegmentId(group?.id);
                if(eligibleCount) setEligibleContactsCount(eligibleCount)
                // if (!props?.shouldShowEdit && searchParams?.get('segmentId')) {
                //   createSegmentId(false);
                // }
                setOpenModal({
                  type: '',
                  data: null,
                });
              }}
              accountLocationUuids={accountLocationUuids || []}
            />
          </>
        );
        break;

      default:
        break;
    }
    return element;
  };
  const [allTenants, setAllTenants] = useState<any>([]);
  const [tenants, setTenants] = useState<any>([]);
  const isExportSupported = showEnvironmentInCampaign();
  const [copyState, setCopyState] = useState({
    environments: [],
    isSelectAll: false,
    tenants: []
  })

  const setCurrentTenants = () => {
    const list: any = [];
    copyState?.environments?.forEach((env: any) => {
      allTenants.forEach((item: any) => {
        if (env == item.env) {
          list.push({ ...item });
        }
      });
    });
    setTenants(list);
    setCopyState((prev: any) => {
      const list: any = [];
      prev?.environments?.forEach((env: any) => {
        prev?.tenants?.forEach((item: any) => {
          if (env == item.env) {
            list.push({ ...item });
          }
        });
      });
      return { ...prev, tenants: list }
    });

  }

  // Location changes
  const { Option } = Select;
  useEffect(() => {
    if (campaignDetails?.locations && campaignDetails?.segmentType && !(POPGROUP_RELATED_SEGMENTS?.includes(campaignDetails?.segmentType))) {
      const locationIds = isMsoEnabled ? (campaignDetails?.locations?.length > 0
        ? campaignDetails.locations : (isBusinessOwner ? [] : accountLocationUuids)) : [];
      getContactAggregate({
        variables: getAggregateWhereClause(
          Number(accountId), {
          segmentType: campaignDetails.segmentType,
          segmentTypeValue: campaignDetails.segmentTypeValue,
          patientTypeContactId: patientTypeContactId,
          mlovData,
          locationUuids: locationIds
        }
        ),
      })
        .then((response) => {
          setAggregates((prev) => ({
            ...prev,
            total: response?.data?.total?.aggregate?.count || 0,
            emailTotal: response?.data?.emailTotal?.aggregate?.count || 0,
            emailConsent: response?.data?.emailConsent?.aggregate?.count || 0,
            smsTotal: response?.data?.smsTotal?.aggregate?.count || 0,
            smsConsent: response?.data?.smsConsent?.aggregate?.count || 0,
            notificationTotal: response?.data?.total?.aggregate?.count || 0,
            notificationConsent: response?.data?.notificationConsent?.aggregate?.count || 0,
          }));
        });
    }
  }, [campaignDetails?.locations, campaignDetails?.segmentType]);
  // Location changes

  useEffect(() => {
    if (!allTenants.length && isExportSupported) {
      getAllTenants().then((response) => {
        const data = response.data;
        const list: any = [];
        Object.keys(data).forEach((key) => {
          data[key].forEach((item: any) => {
            list.push({ ...item, env: key });
          });
        });
        setAllTenants(list);
        setCurrentTenants();
      });
    } else if (isExportSupported) {
      setCurrentTenants();
    }

  }, [copyState?.environments])
  const environments = [{ code: 'production', name: 'Production' }, { code: 'sandbox', name: 'Sandbox' }, { code: 'qa', name: 'QA' }, { code: 'develop', name: 'development' }];
  return (
    <>
      <ModalActionTitle
        title={
          props.shouldShowEdit && !isDuplicate && props.initData?.id ? 'updateCampaign' : (props.shouldShowEdit ? 'newCampaign' : 'Campaign')
        }
        //titleColor={Colors.Custom.Gray700}
        //titleFontSize={24}
        buttonList={[
          {
            show: true,
            id: 1,
            btnText: 'cancel',
            textColor: Colors.Custom.mainSecondaryBrown,
            variant: BUTTON_TYPE.SECONDARY,
            isTransBtn: false,
            onClick: () => {
              props?.close?.('cancel');
            },
          },
          ...(props.shouldShowEdit ?  [{
            show: showPublishButton(),
            id: 1,
            btnText: 'publish',
            isLoading: campaignPublishLoading, 
            disabled: campaignPublishLoading,
            textColor: Colors.Custom.mainPrimaryPurple,
            variant: BUTTON_TYPE.PRIMARY,
            isTransBtn: false,

            onClick: () => {
              onSubmit();
            },
          }] : [])
        ]}
      />

      <View>
        <HStack alignItems={'center'}>
          <Text
            fontWeight={'600'}
            size={'smSemiBold'}
            width={150}
            color={Colors.Custom.Gray900}
            style={{ flex: 1 }}
          >
            Campaign Name
            <span className="required-dot"
              style={{
                marginBottom: 2
              }}
            >
            </span>
          </Text>
          <View style={{ marginLeft: 15, flex: 7 }}>
            <FormControl width={'50%'} isRequired={true}>
              <Input
              isDisabled={!props.shouldShowEdit}
              _focus={{borderColor: '#D0D5DD'}}
                defaultValue={''}
                isInvalid={false}
                value={campaignDetails?.name || ''}
                onChangeText={(value: string) => {
                  setCampaignDetails({
                    ...campaignDetails,
                    name: value,
                  });
                }}
              />
            </FormControl>
            {campaignDetailsError?.campaignName ? (
              <Text
                color={Colors.error[500]}
                size="smNormal"
                style={{ marginTop: 5 }}
              >
                {campaignDetailsError?.campaignName}
              </Text>
            ) : null}
          </View>
        </HStack>
        <View
          style={{
            width: '100%',
            height: 1,
            backgroundColor: Colors.Custom.Gray200,
            marginVertical: 15,
          }}
        />
        <HStack alignItems={'center'}>
          <Text
            fontWeight={'600'}
            size={'smSemiBold'}
            width={150}
            color={Colors.Custom.Gray900}
            style={{ flex: 1 }}
          >
            Description
          </Text>
          <View style={{ marginLeft: 15, flex: 7 }}>
            <FormControl width={'50%'}>
              <Input
              isDisabled={!props.shouldShowEdit}
              _focus={{borderColor: '#D0D5DD'}}
                defaultValue={''}
                isInvalid={false}
                value={campaignDetails?.description || ''}
                onChangeText={(value: string) => {
                  setCampaignDetails({
                    ...campaignDetails,
                    description: value,
                  });
                }}
              />
            </FormControl>
          </View>
        </HStack>
        <View
          style={{
            width: '100%',
            height: 1,
            backgroundColor: Colors.Custom.Gray200,
            marginVertical: 15,
          }}
        />
            <HStack alignItems={'center'}>
              <Text
                fontWeight={'600'}
                size={'smSemiBold'}
                width={150}
                style={{ flex: 1 }}
                color={Colors.Custom.Gray900}
              >
                To
                <span className="required-dot"
                  style={{
                    marginBottom: 2
                  }}
                ></span>
              </Text>
              <View style={{ flex: 6.5 }}>
                {(openToPopover || !campaignDetails?.audienceName) &&
                  !membersList?.length &&
                  campaignDetails?.segmentType != 'customSelected' ? (
                  <Popover
                    content={
                      <View>
                        {segmentTabs.map((content: any, index) => {
                          return (
                            <Pressable
                              style={{
                                paddingVertical: 16,
                                borderTopColor: Colors.Custom.Gray100,
                                borderTopWidth: index ? 1 : 0,
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}
                              onPress={() => {
                                setOpenToPopover(false);
                                // if (props?.shouldShowEdit) {
                                //   return;
                                // }
                                if (
                                  campaignDetails?.segmentType ==
                                  'customSelected' ||
                                  content?.type == 'customSelected'
                                ) {
                                  const locationIds = isMsoEnabled ? (campaignDetails?.locations && campaignDetails?.locations?.length > 0
                                    ? campaignDetails.locations : (isBusinessOwner ? [] : accountLocationUuids)) : [];
                                  getContactAggregate({
                                    variables: getAggregateWhereClause(
                                      Number(accountId), {
                                      segmentType: content.type,
                                      segmentTypeValue: content.value,
                                      patientTypeContactId: patientTypeContactId,
                                      mlovData,
                                      locationUuids: locationIds
                                    }
                                    ),
                                  })
                                    .then((response) => {
                                      setAggregates((prev) => {
                                        return {
                                          ...prev,
                                          total:
                                            response?.data?.total?.aggregate
                                              ?.count || 0,
                                          emailTotal:
                                            response?.data?.emailTotal?.aggregate
                                              ?.count || 0,
                                          emailConsent:
                                            response?.data?.emailConsent?.aggregate
                                              ?.count || 0,
                                          smsTotal:
                                            response?.data?.smsTotal?.aggregate
                                              ?.count || 0,
                                          smsConsent:
                                            response?.data?.smsConsent?.aggregate
                                              ?.count || 0,
                                          notificationTotal:
                                            response?.data?.total?.aggregate
                                              ?.count || 0,
                                          notificationConsent:
                                            response?.data?.notificationConsent
                                              ?.aggregate?.count || 0,
                                        };
                                      });
                                    });
                                  setSegmentId('');
                                  setMembersList([]);
                                  setSelectedGroupInfo('');
                                  setCampaignDetails({
                                    ...campaignDetails,
                                    zipCodes: [],
                                    tagList: [],
                                    gender: [],
                                    fromYear: undefined,
                                    toYear: undefined,
                                    operator: undefined,
                                    audienceName: undefined,
                                    patientFilter: {},
                                    segmentType: content?.type,
                                    segmentTypeValue: content?.value
                                  });
                                } else {
                                  setCampaignDetails({
                                    ...campaignDetails,
                                    segmentType: content?.type,
                                    segmentTypeValue: content?.value,
                                  });
                                }

                                {
                                  content?.type !== 'customSelected'
                                    ? setOpenModal({
                                      type: content?.type,
                                      data: null,
                                    })
                                    : null;
                                }
                              }}
                            >
                              <Text size={'mdMedium'} color={Colors.Custom.Gray700}>
                                {content?.displayText}
                              </Text>
                              <AntIcon
                                name="right"
                                color={Colors.Custom.Gray500}
                                size={15}
                                style={{
                                  marginLeft: 10,
                                }}
                              />
                            </Pressable>
                          );
                        })}
                      </View>
                    }
                    trigger="click"
                    overlayInnerStyle={{ padding: 0, borderRadius: 16 }}
                    overlayStyle={{ padding: 0 }}
                    overlay={{ padding: 0 }}
                    style={{ padding: 0, borderRadius: 16 }}
                    placement={'bottom'}
                    zIndex={9999}
                    visible={openToPopover}
                    onVisibleChange={() => {
                      setOpenToPopover(!openToPopover);
                    }}
                  >
                    <View
                      style={{
                        paddingVertical: 4,
                        paddingHorizontal: 8,
                        flexDirection: 'row',
                        alignItems: 'center',
                        borderWidth: 1,
                        borderColor: '#EFE0FC',
                        borderRadius: 20,
                        marginLeft: 20,
                        width: 'fit-content',
                      }}
                    >
                      <AntIcon
                        size={13}
                        style={{ marginRight: 5 }}
                        name="plus"
                        color={'#825AC7'}
                      />
                      <Text
                        cursor={'pointer'}
                        size="smMedium"
                        fontWeight={'400'}
                        color={'#825AC7'}
                      >
                        Add Send to
                      </Text>
                    </View>
                  </Popover>
                ) : campaignDetails?.segmentType == 'customSelected' ? (
                  <View
                    style={{
                      paddingVertical: 4,
                      paddingHorizontal: 8,
                      flexDirection: 'row',
                      alignItems: 'center',
                      backgroundColor: Colors.Custom.Blue50,
                      borderRadius: 20,
                      marginLeft: 15,
                      width: 'fit-content',
                    }}
                  >
                    <Text
                      cursor={'pointer'}
                      size="smMedium"
                      fontWeight={'400'}
                      color={Colors.Custom.Blue700}
                      style={{ marginRight: 5 }}
                    >
                      {
                        (segmentTabs.filter((segment) => { return segment.value == campaignDetails?.segmentTypeValue })[0]?.displayText) || 'All selected'}
                    </Text>
                    {props?.shouldShowEdit ? (
                      <AntIcon
                        onPress={() => {
                          setCampaignDetails({
                            ...campaignDetails,
                            zipCodes: undefined,
                            tagList: undefined,
                            gender: undefined,
                            fromYear: undefined,
                            toYear: undefined,
                            operator: undefined,
                            audienceName: undefined,
                            segmentType: '',
                            segmentTypeValue: '',
                            patientFilter: {},
                            locations:[]
                          });
                          setMembersList([]);
                          setSegmentId('');
                          setAggregates(initialAggregateState);
                        }}
                        size={15}
                        name="close"
                        color={Colors.Custom.Blue700}
                      />
                    ) : null}
                  </View>
                ) : membersList && membersList?.length ? (
                  <HStack
                    flexWrap={'wrap'}
                    style={{
                      maxHeight: 150,
                      overflow: 'scroll',
                    }}
                  >
                    {membersList?.map((contact: any, index: number) => {
                      return (
                        <View
                          style={{
                            paddingVertical: 4,
                            paddingHorizontal: 8,
                            marginVertical: 5,
                            flexDirection: 'row',
                            alignItems: 'center',
                            backgroundColor: Colors.Custom.Blue50,
                            borderRadius: 20,
                            marginLeft: 15,
                          }}
                        >
                          <DisplayCardAvatar
                            avatarStyle={{
                              avatarSize: '6',
                              textStyle: {
                                fontSize: 9,
                              }
                            }}
                            isLetterAvatarShow
                            userData={{
                              userId: contact.id,
                              userType: GROUP_MEMBER_TYPE.CONTACT,
                              genderCode: getPrimaryGenderCode(contact),
                              userName: contact?.name
                            }}
                          />

                          <Text
                            cursor={'pointer'}
                            size="smMedium"
                            fontWeight={'400'}
                            style={{ marginHorizontal: 5 }}
                            color={Colors.Custom.Blue700}
                          >
                            {`${getContactName(contact)}` +
                              (contact.email || contact.phoneNumber
                                ? ` (${contact.email || contact.phoneNumber})`
                                : '')}
                          </Text>
                          {/* {!props?.shouldShowEdit ? (
                              <AntIcon
                                onPress={() => {
                                  const membersTemp = [...membersList];
                                  membersTemp.splice(index, 1);
                                  const contactIds = membersTemp?.map(
                                    (member) => member?.id
                                  );
                                  const patientFilters = contactIds?.length
                                    ? {
                                        contactIds,
                                      }
                                    : {};
                                  if (!membersTemp?.length) {
                                    setCampaignDetails({
                                      ...campaignDetails,
                                      zipCodes: undefined,
                                      tagList: undefined,
                                      gender: undefined,
                                      fromYear: undefined,
                                      toYear: undefined,
                                      operator: undefined,
                                      audienceName: undefined,
                                      segmentType: '',
                                      patientFilter: patientFilters,
                                    });
                                    setSegmentId('');
                                  } else {
                                    setCampaignDetails({
                                      ...campaignDetails,
                                      patientFilter: patientFilters,
                                    });
                                  }

                                  setMembersList(membersTemp);
                                }}
                                size={15}
                                name="close"
                                color={Colors.Custom.Blue700}
                              />
                            ) : null} */}
                        </View>
                      );
                    })}
                  </HStack>
                ) : (
                  <View
                    style={{
                      paddingVertical: 4,
                      paddingHorizontal: 8,
                      flexDirection: 'row',
                      alignItems: 'center',
                      backgroundColor: Colors.Custom.Blue50,
                      borderRadius: 20,
                      marginLeft: 15,
                      width: 'fit-content',
                    }}
                  >
                    <Text
                      cursor={'pointer'}
                      size="smMedium"
                      fontWeight={'400'}
                      color={Colors.Custom.Blue700}
                      style={{ marginRight: 5 }}
                    >
                      {campaignDetails?.audienceName}
                    </Text>
                    {props?.shouldShowEdit ? (
                      <AntIcon
                        onPress={() => {
                          setCampaignDetails({
                            ...campaignDetails,
                            zipCodes: undefined,
                            tagList: undefined,
                            gender: undefined,
                            fromYear: undefined,
                            toYear: undefined,
                            operator: undefined,
                            audienceName: undefined,
                            segmentType: '',
                            segmentTypeValue: '',
                            patientFilter: {},
                            locations:[]
                          });
                          setMembersList([]);
                          setSegmentId('');
                          setAggregates(initialAggregateState);
                        }}
                        size={15}
                        name="close"
                        color={Colors.Custom.Blue700}
                      />
                    ) : (
                      <AntIcon
                        onPress={() => {
                          setCampaignDetails({
                            ...campaignDetails,
                            segmentType: 'createAudience',
                          });
                          setOpenModal({
                            type: 'createAudience',
                            data: null,
                          });
                        }}
                        size={15}
                        name="edit"
                        color={Colors.Custom.Blue700}
                      />
                    )}
                  </View>
                )}
                {campaignDetailsError?.segmentError ? (
                  <Text
                    color={Colors.error[500]}
                    size="smNormal"
                    style={{ marginTop: 5, marginLeft: 15 }}
                  >
                    {campaignDetailsError?.segmentError}
                  </Text>
                ) : null}
              </View>

              <VStack space={'2'}>
                {membersList && membersList?.length && props.shouldShowEdit ? (
                  <Pressable>
                    <text
                      onClick={() => {
                        setEligibleContactsCount(0)
                        setCampaignDetails({
                          ...campaignDetails,
                          zipCodes: undefined,
                          tagList: undefined,
                          gender: undefined,
                          fromYear: undefined,
                          toYear: undefined,
                          operator: undefined,
                          audienceName: undefined,
                          segmentType: '',
                          segmentTypeValue: '',
                          patientFilter: {},
                          locations:[]
                        });
                        setMembersList([]);
                        setSegmentId('');
                        setAggregates(initialAggregateState);
                      }}
                      // size={15}
                      name="close"
                      color={Colors.secondary[800]}
                    >
                      Clear all
                    </text>
                  </Pressable>
                ) : null}
                <View>
                  <Text>{`Total Recipients: ${eligibleContactsCount || aggregates?.total}`}</Text>
                </View>
              </VStack>
            </HStack>
            <View
              style={{
                width: '100%',
                height: 1,
                backgroundColor: Colors.Custom.Gray200,
                marginVertical: 15,
              }}
            />
        {
          (isMsoEnabled && campaignDetails?.segmentType) && (
            <>
              <HStack alignItems={'center'}>
                <Text
                  fontWeight={'600'}
                  size={'smSemiBold'}
                  width={150}
                  color={Colors.Custom.Gray900}
                  style={{ flex: 1 }}
                >
                  Select locations
                  <span className="required-dot"
                    style={{
                      marginBottom: 2
                    }}
                  >
                  </span>
                </Text>
                <View style={{ marginLeft: 15, flex: 7 }}>
                  <FormControl width={'50%'} {...testID(TestIdentifiers.campaignSelectLocation)} >
                    <WorkflowLocationSelect
                      onChangeLocationIds={(values: string[]) => {
                        if (values?.length) {
                          setCampaignDetails({
                            ...campaignDetails,
                            locations: values,
                          })
                        } else {
                          setCampaignDetails({
                            ...campaignDetails,
                            locations: [],
                          })
                        }
                      }}
                      isPopGrp={POPGROUP_RELATED_SEGMENTS?.includes(campaignDetails?.segmentType)}
                      crossEhrEnabled={true}
                      locationIds={(campaignDetails?.locations && campaignDetails.locations.length > 0) ? campaignDetails.locations : props?.selectedContactLocationIds}
                      flowType={''}
                      notFoundContent={intl.formatMessage({ id: 'NoData' })}
                    />
                  </FormControl>
                  {campaignDetailsError?.locationError ? (
                    <Text
                      color={Colors.error[500]}
                      size="smNormal"
                      style={{ marginTop: 5 }}
                    >
                      {campaignDetailsError?.locationError}
                    </Text>
                  ) : null}
                </View>
              </HStack>
              <View
                style={{
                  width: '100%',
                  height: 1,
                  backgroundColor: Colors.Custom.Gray200,
                  marginVertical: 15,
                }}
              />
            </>
          )
        }

        <HStack alignItems={'center'}>
          <Text
            fontWeight={'600'}
            size={'smSemiBold'}
            width={150}
            flex={1}
            color={Colors.Custom.Gray900}
          >
            Schedule Send
            <span className="required-dot"
              style={{
                marginBottom: 2
              }}
            ></span>
          </Text>
          <View style={{ flex: 7 }}>
            {!isTimeset || openSchedulePopover ? (
              <Popover
                content={
                  <View>
                    <View
                      style={{
                        maxHeight: 500,
                        minWidth: 300,
                        zIndex: 9999,
                        overflow: 'scroll',
                      }}
                    >
                      <CampaignDetailsForm
                        campaignDetailsError={campaignDetailsError}
                        onChange={(value: ICampaignDetailsFormState) => {
                          onCampaignDetailsFormChange(value);
                        }}
                        initData={{
                          name: campaignDetails?.name,
                          campaignTime: {
                            type: campaignDetails?.isImmediate
                              ? 'immediate'
                              : 'scheduleOnDate',
                            value: campaignDetails?.startDate
                              ? moment(props?.initData?.startDate).toDate()
                              : undefined,
                          },
                          description: campaignDetails?.description,
                          enableReminder:
                            campaignDetails?.retryDelayInDays ||
                            campaignDetails?.numberOfRetry
                              ? true
                              : false,
                          interval: campaignDetails?.retryDelayInDays,
                          numberOfRetry: campaignDetails?.numberOfRetry,
                          type: campaignDetails?.type,
                        }}
                      />
                    </View>
                  </View>
                }
                trigger="click"
                overlayInnerStyle={{ padding: 0, borderRadius: 16 }}
                overlayStyle={{ padding: 0 }}
                overlay={{ padding: 0 }}
                style={{ padding: 0, borderRadius: 16 }}
                placement={'bottom'}
                zIndex={9999}
                visible={openSchedulePopover}
                onVisibleChange={() => {
                  setOpenSchedulePopover(!openSchedulePopover);
                }}
              >
                <View
                  style={{
                    paddingVertical: 4,
                    paddingHorizontal: 8,
                    flexDirection: 'row',
                    alignItems: 'center',
                    borderWidth: 1,
                    borderColor: '#EFE0FC',
                    borderRadius: 20,
                    marginLeft: 15,
                    width: 'fit-content',
                  }}
                >
                  <AntIcon
                    size={13}
                    style={{ marginRight: 5 }}
                    name="plus"
                    color={'#825AC7'}
                  />
                  <Text
                    cursor={'pointer'}
                    size="smMedium"
                    fontWeight={'400'}
                    color={'#825AC7'}
                  >
                    Add Schedule
                  </Text>
                </View>
              </Popover>
            ) : campaignDetails.startDate ? (
              <View
                style={{
                  paddingVertical: 4,
                  paddingHorizontal: 8,
                  flexDirection: 'row',
                  alignItems: 'center',
                  backgroundColor: Colors.Custom.Blue50,
                  borderRadius: 20,
                  marginLeft: 15,
                  width: 'fit-content',
                }}
              >
                <Text
                  cursor={'pointer'}
                  size="smMedium"
                  fontWeight={'400'}
                  color={Colors.Custom.Blue700}
                >
                  {moment(campaignDetails.startDate).format(
                    DATE_FORMATS.MESSAGE_DATE_FORMAT
                  )}
                </Text>
                {props.shouldShowEdit ? <AntIcon
                  onPress={() => {
                    setIsTimeset(false);
                    setCampaignDetails({
                      ...campaignDetails,
                      startDate: undefined,
                      isImmediate: undefined,
                    });
                  }}
                  size={13}
                  style={{ marginLeft: 5 }}
                  name="close"
                  color={Colors.Custom.Blue700}
                />  : <></>}
              </View>
            ) : (
              <View
                style={{
                  paddingVertical: 4,
                  paddingHorizontal: 8,
                  flexDirection: 'row',
                  alignItems: 'center',
                  backgroundColor: Colors.Custom.Blue50,
                  borderRadius: 20,
                  marginLeft: 15,
                  width: 'fit-content',
                }}
              >
                <Text
                  cursor={'pointer'}
                  size="smMedium"
                  fontWeight={'400'}
                  color={Colors.Custom.Blue700}
                >
                  Immediate
                </Text>
                <AntIcon
                  onPress={() => {
                    setIsTimeset(false);
                    setCampaignDetails({
                      ...campaignDetails,
                      startDate: undefined,
                      isImmediate: undefined,
                    });
                  }}
                  size={15}
                  style={{ marginLeft: 5 }}
                  name="close"
                  color={Colors.Custom.Blue700}
                />
              </View>
            )}
            {campaignDetailsError?.campaignTime ? (
              <Text
                color={Colors.error[500]}
                size="smNormal"
                style={{ marginTop: 5, marginLeft: 15 }}
              >
                {campaignDetailsError?.campaignTime}
              </Text>
            ) : null}
          </View>
        </HStack>
        <View
          style={{
            width: '100%',
            height: 1,
            backgroundColor: Colors.Custom.Gray200,
            marginVertical: 15,
          }}
        />

        <HStack alignItems={'center'}>
          <Text
            fontWeight={'600'}
            size={'smSemiBold'}
            width={150}
            color={Colors.Custom.Gray900}
          >
            Send via
          </Text>
          <HStack
          style={{
            flex: 1,
            alignItems:'center',
            justifyContent: 'space-between'
          }}
          >
            <VStack alignItems={'flex-start'} style={{}}>
              <Radio.Group
                style={{flexDirection: 'row'}}
                name="contactsType"
                value={campaignDetails.channelType}
                onChange={(nextValue: any) => {
                  setTemplate(undefined as any);
                  setCampaignDetails((prev) => {
                    return {
                      ...prev,
                      channelType: nextValue,
                    };
                  });
                }}
              >
                {campaignChannelList.map((channelData, index: number) => {
                  return (
                    <HStack
                      style={{
                        width: 200,
                      }}
                    >
                      <Radio
                        colorScheme=""
                        isHovered={false}
                        isFocusVisible={false}
                        isDisabled={!props.shouldShowEdit}
                        isFocused={false}
                        key={index}
                        value={channelData.key}
                        style={{marginVertical: 10}}
                      >
                        <Text
                          size={'smMedium'}
                          color={Colors.Custom.Gray700}
                          style={{marginLeft: 8, marginRight: 10}}
                        >
                          {channelData.title}
                        </Text>
                      </Radio>
                    </HStack>
                  );
                })}
              </Radio.Group>
              {isConsentRequired && <HStack>
                <View
                  style={{
                    width: 200,
                    paddingLeft: 25,
                  }}
                >
                  <Text>
                    {`${aggregates.emailConsent}/${aggregates.total} have consented`}
                  </Text>
                </View>
                <View
                  style={{
                    width: 200,
                    paddingLeft: 25,
                  }}
                >
                  <Text>
                    {`${aggregates.smsConsent}/${aggregates.total} have consented`}
                  </Text>
                </View>
                <View
                  style={{
                    width: 200,
                    paddingLeft: 25,
                  }}
                >
                  <Text>
                    {`${aggregates.notificationConsent}/${aggregates.total} have consented`}
                  </Text>
                </View>
              </HStack>}
            </VStack>
            {isConsentRequired && <View
            style={{
              justifyContent: 'center',
              alignItems: 'center'
            }}
            >
              {campaignDetails.channelType === 'EMAIL' && (
                <Text>
                  {`Members without consent: ${
                    aggregates.total - aggregates.emailConsent
                  }`}
                </Text>
              )}
              {campaignDetails.channelType === 'SMS' && (
                <Text>
                  {`Members without consent: ${
                    aggregates.total - aggregates.smsConsent
                  }`}
                </Text>
              )}
              {campaignDetails.channelType === 'NOTIFICATION' && (
                <Text>
                  {`Members without consent: ${
                    aggregates.total -
                    aggregates.notificationConsent
                  }`}
                </Text>
              )}
            </View>}
          </HStack>
        </HStack>
        {!!allTenants?.length && isExportSupported && (
          <VStack>
            <View
              style={{
                width: '100%',
                height: 1,
                backgroundColor: Colors.Custom.Gray200,
                marginVertical: 15,
              }}
            />
            <HStack alignItems={'center'}>
              <Text
                fontWeight={'600'}
                size={'smSemiBold'}
                width={150}
                color={Colors.Custom.Gray900}
              >
                Environments
              </Text>
              <HStack
                alignItems={'center'}
                style={{marginLeft: 15}}
                width={'full'}
              >
                <VStack width={'80%'}>
                  <Select
                    mode="multiple"
                    allowClear
                    filterOption={false}
                    value={copyState.environments}
                    onChange={(value: any[]) => {
                      if (value) {
                        setCopyState((prev: any) => {
                          return {...prev, environments: value};
                        });
                      }
                    }}
                    placeholder="Select Environments"
                    style={{height: 'auto'}}
                  >
                    {environments.map((environment) => {
                      return (
                        <Select.Option
                          key={environment.code}
                          value={environment.code}
                        >
                          {environment.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </VStack>
              </HStack>
            </HStack>
            {!!copyState?.environments?.length && (
              <View
                style={{
                  width: '100%',
                  height: 1,
                  backgroundColor: Colors.Custom.Gray200,
                  marginVertical: 15,
                }}
              />
            )}
            {!!copyState?.environments?.length && (
              <HStack alignItems={'center'} marginTop={5}>
                <Text
                  fontWeight={'600'}
                  size={'smSemiBold'}
                  width={150}
                  marginRight={5}
                  color={Colors.Custom.Gray900}
                >
                  Tenants
                </Text>
                <Checkbox
                  value="complete"
                  isChecked={copyState.isSelectAll}
                  onChange={(value) => {
                    if (value) {
                      setCopyState((prev: any) => {
                        return {...prev, isSelectAll: value};
                      });
                    } else {
                      setCopyState((prev: any) => {
                        return {...prev, isSelectAll: value};
                      });
                    }
                  }}
                >
                  All Tenants
                </Checkbox>

                {!copyState.isSelectAll && (
                  <HStack
                    alignItems={'center'}
                    style={{marginLeft: 15}}
                    width={'full'}
                  >
                    <VStack width={'70%'}>
                      <Select
                        mode="multiple"
                        allowClear
                        filterOption={(input, option) =>
                          (option!.children as unknown as string)
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        value={copyState.tenants?.map((tenant: any) => {
                          return tenant?.uuid;
                        })}
                        onChange={(value: any[]) => {
                          if (value) {
                            const result = value.map((uuid) => {
                              let item;
                              tenants.some((tenant: any) => {
                                if (tenant.uuid === uuid) {
                                  item = tenant;
                                }
                              });
                              return item;
                            });
                            setCopyState((prev: any) => {
                              return {...prev, tenants: result};
                            });
                          }
                        }}
                        placeholder="Select Tenants"
                        style={{height: 'auto'}}
                      >
                        {tenants.map((tenant: any) => {
                          return (
                            <Select.Option
                              key={tenant.uuid}
                              value={tenant.uuid}
                            >
                              {tenant.name +
                                ' (' +
                                environments?.filter((item: any) => {
                                  return tenant.env == item.code;
                                })[0]?.name +
                                ')'}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </VStack>
                  </HStack>
                )}
              </HStack>
            )}
          </VStack>
        )}

        <View
          style={{
            width: '100%',
            height: 1,
            backgroundColor: Colors.Custom.Gray200,
            marginVertical: 15,
          }}
        />
        {/* <TitleSubtitleView
        showBackButton
        onBackClick={onBackBtnClick}
        titleLabelId={
          props.shouldShowEdit && !isDuplicate
            ? 'updateCampaign'
            : 'newCampaign'
        }
      /> */}

        {/* <HStack
        style={{
          backgroundColor: Colors.Custom.Gray100,
          paddingHorizontal: 32,
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <CustomStepView
          style={{ paddingTop: 0, height: 70, paddingHorizontal: 0 }}
          stepList={campaignDetailsSteps()}
          stepListWrapperStyle={
            {
              // marginHorizontal: 24,
            }
          }
          onStepClick={(step: IStep) => {
            const isValidated = validateCampaignDetailsForm();
            if (isValidated) {
              setSelectedStep(step);
            }
            return;
          }}
          selectedStep={selectedStep}
        />
        <HStack justifySelf={'center'} alignSelf={'center'}>
          <ModalActionBtn
            btnStype={BUTTON_TYPE.PRIMARY}
            btnText={selectedStep?.key == 'preview' ? 'save' : 'next'}
            onClickAction={async () => {
              const isValidated = validateCampaignDetailsForm();
              if (isValidated) {
                if (selectedStep?.key == 'preview') {
                  if (
                    !props?.shouldShowEdit &&
                    !segmentId &&
                    segmentId !== 'null' &&
                    campaignDetails?.segmentType !== 'customSelected'
                  ) {
                    createSegmentId(true);
                  } else {
                    createOrUpdateCampaignLocal(segmentId);
                  }
                } else {
                  const nextStep =
                    selectedStep?.key == 'createOrEditSegmentDetails'
                      ? 1
                      : selectedStep?.key == 'selectChannel'
                        ? 2
                        : selectedStep?.key == 'createOrEditTemplates'
                          ? 3
                          : 0;
                  setSelectedStep(campaignDetailsSteps()[nextStep]);
                }
              }
            }}
          />
        </HStack>
      </HStack> */}
        <Drawer
          width={
            openModal.customDrawerWidth || (openModal?.type == 'templateGallery' ? width * 0.8 : finalWidth)
          }
          visible={openModal?.type ? true : false}
          closeIcon={null}
          closable
          title={null}
          headerStyle={{ display: 'none' }}
          maskClosable={true}
          onClose={() => {
            setOpenModal({
              type: '',
              data: null,
            });
          }}
        >
          {onDrawerContent(openModal?.type)}
        </Drawer>
        {campaignDetailsError?.emailTemplateError ? (
          <Text
            color={Colors.error[500]}
            size="smNormal"
            style={{ marginVertical: 10 }}
          >
            {campaignDetailsError?.emailTemplateError}
          </Text>
        ) : null}

        <View
          style={{
            backgroundColor: '#fff',
            borderColor: Colors.Custom.BorderColor,
            borderWidth: 1,
            borderRadius: 8,
            padding: 8,
          }}
        >
          <HStack alignItems={'center'} justifyContent="flex-end">
            <Pressable
              style={{
                paddingVertical: 4,
                paddingHorizontal: 8,
                flexDirection: 'row',
                alignItems: 'center',
                borderWidth: 1,
                borderColor: '#EFE0FC',
                borderRadius: 20,
                marginLeft: 15,
              }}
              onPress={() => {
                setOpenModal({
                  type: 'templateGallery',
                  data: null,
                });
              }}
            >
              <Text
                cursor={'pointer'}
                size="smMedium"
                fontWeight={'400'}
                color={'#825AC7'}
              >
                {'Use Templates'}
              </Text>
            </Pressable>
            <span className="required-dot"
              style={{
                marginBottom: 2
              }}
            ></span>
          </HStack>
          <View>{renderPreview()}</View>
        </View>
        {/* {selectedStep?.key === 'createOrEditCampaignDetails' && (
        <View style={styles.mainContainer}>
          <Content className="common-scroll">
            <CampaignDetailsForm
              campaignDetailsError={campaignDetailsError}
              onChange={(value: ICampaignDetailsFormState) => {
                onCampaignDetailsFormChange(value);
              }}
              initData={{
                name: campaignDetails?.name,
                campaignTime: {
                  type: campaignDetails?.isImmediate
                    ? 'immediate'
                    : 'scheduleOnDate',
                  value: campaignDetails?.startDate
                    ? moment(props?.initData?.startDate).toDate()
                    : undefined,
                },
                description: campaignDetails?.description,
                enableReminder:
                  campaignDetails?.retryDelayInDays ||
                  campaignDetails?.numberOfRetry
                    ? true
                    : false,
                interval: campaignDetails?.retryDelayInDays,
                numberOfRetry: campaignDetails?.numberOfRetry,
                type: campaignDetails?.type,
              }}
            />
          </Content>
        </View>
      )} */}
      </View>
    </>
  );
};

export default AddOrUpdateCampaignTabs;
