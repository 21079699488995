import {Center, Image, Text, View} from 'native-base';
import React from 'react';
import {Platform} from 'react-native';
import {Colors} from '../../../styles';
import {DisplayText} from '../DisplayText/DisplayText';
import {svgStyles} from './SvgStyles';

const NoContactNotesSvg = (props: {titleId?: string; fillColor?: string}) => {
  const fillColor = props.fillColor || Colors.primary['300'];

  return (
    <View style={svgStyles.container}>
      {Platform.OS === 'web' ? (
        <svg
          width="105"
          height="128"
          viewBox="0 0 105 128"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M29.7345 93.2726H74.0207C75.2379 93.2756 76.4112 92.8157 77.3055 91.985C81.0362 88.5101 84.0208 84.3041 86.0757 79.6257C88.1306 74.9473 89.2124 69.8954 89.2546 64.7808C89.4456 43.9839 72.6413 26.8719 51.9683 26.8229C31.2835 26.7738 14.5 43.6281 14.5 64.4261C14.4941 69.6026 15.5536 74.7242 17.6118 79.4685C19.6699 84.2128 22.6822 88.477 26.4589 91.9927C27.3513 92.8195 28.5213 93.2767 29.7345 93.2726V93.2726Z"
            fill="#F9FAFB"
          />
          <g filter="url(#filter0_d_20_72021)">
            <path
              d="M71.3956 93.1462H33.6967C32.7717 93.1462 31.8846 92.7788 31.2305 92.1247C30.5764 91.4706 30.209 90.5835 30.209 89.6585V29.4877C30.209 28.5627 30.5764 27.6756 31.2305 27.0215C31.8846 26.3675 32.7717 26 33.6967 26H59.5642L74.8833 41.3191V89.6585C74.8833 90.1165 74.7931 90.57 74.6179 90.9932C74.4426 91.4163 74.1857 91.8008 73.8618 92.1247C73.5379 92.4486 73.1535 92.7055 72.7303 92.8807C72.3072 93.056 71.8536 93.1462 71.3956 93.1462V93.1462Z"
              fill="white"
            />
          </g>
          <path
            d="M74.8833 41.3191H63.0519C62.5939 41.3191 62.1404 41.2289 61.7172 41.0536C61.2941 40.8784 60.9096 40.6214 60.5857 40.2976C60.2619 39.9737 60.005 39.5892 59.8297 39.1661C59.6544 38.7429 59.5642 38.2894 59.5642 37.8314V26L74.8833 41.3191Z"
            fill={fillColor}
          />
          <path
            d="M52.2385 37.6768H37.6425C36.903 37.6768 36.3035 38.2763 36.3035 39.0158V39.0158C36.3035 39.7553 36.903 40.3548 37.6425 40.3548H52.2385C52.978 40.3548 53.5775 39.7553 53.5775 39.0158C53.5775 38.2763 52.978 37.6768 52.2385 37.6768Z"
            fill="#E4E7EC"
          />
          <path
            d="M67.1163 46.7046H37.6425C36.903 46.7046 36.3035 47.3041 36.3035 48.0436V48.0436C36.3035 48.7831 36.903 49.3826 37.6425 49.3826H67.1163C67.8559 49.3826 68.4554 48.7831 68.4554 48.0436C68.4554 47.3041 67.8559 46.7046 67.1163 46.7046Z"
            fill="#E4E7EC"
          />
          <path
            d="M67.1163 55.7323H37.6425C36.903 55.7323 36.3035 56.3318 36.3035 57.0714V57.0714C36.3035 57.8109 36.903 58.4104 37.6425 58.4104H67.1163C67.8559 58.4104 68.4554 57.8109 68.4554 57.0714C68.4554 56.3318 67.8559 55.7323 67.1163 55.7323Z"
            fill="#E4E7EC"
          />
          <path
            d="M67.1163 64.7602H37.6425C36.903 64.7602 36.3035 65.3597 36.3035 66.0992V66.0992C36.3035 66.8388 36.903 67.4383 37.6425 67.4383H67.1163C67.8559 67.4383 68.4554 66.8388 68.4554 66.0992C68.4554 65.3597 67.8559 64.7602 67.1163 64.7602Z"
            fill="#E4E7EC"
          />
          <path
            d="M67.224 73.7881H37.6425C36.903 73.7881 36.3035 74.3876 36.3035 75.1271C36.3035 75.8666 36.903 76.4661 37.6425 76.4661H67.224C67.9636 76.4661 68.5631 75.8666 68.5631 75.1271C68.5631 74.3876 67.9636 73.7881 67.224 73.7881Z"
            fill="#E4E7EC"
          />
          <path
            d="M53.5775 82.7632H37.6425C36.903 82.7632 36.3035 83.3627 36.3035 84.1022C36.3035 84.8417 36.903 85.4412 37.6425 85.4412H53.5775C54.3171 85.4412 54.9166 84.8417 54.9166 84.1022C54.9166 83.3627 54.3171 82.7632 53.5775 82.7632Z"
            fill="#E4E7EC"
          />
          <path
            d="M87.6301 44.608C88.126 44.8097 88.5216 45.2001 88.7296 45.6934C88.9377 46.1866 88.9413 46.7423 88.7397 47.2383L87.1337 51.1878L83.3939 49.6672L84.9999 45.7176C85.2016 45.2217 85.592 44.8262 86.0852 44.6181C86.5785 44.41 87.1342 44.4064 87.6301 44.608Z"
            fill={fillColor}
          />
          <path
            opacity="0.15"
            d="M87.6301 44.608C88.126 44.8097 88.5216 45.2001 88.7296 45.6934C88.9377 46.1866 88.9413 46.7423 88.7397 47.2383L87.1337 51.1878L83.3939 49.6672L84.9999 45.7176C85.2016 45.2217 85.592 44.8262 86.0852 44.6181C86.5785 44.41 87.1342 44.4064 87.6301 44.608Z"
            fill="black"
          />
          <path
            d="M84.7913 47.862L87.3927 48.9198C87.6858 49.039 87.9196 49.2698 88.0426 49.5613C88.1656 49.8529 88.1677 50.1813 88.0485 50.4745L71.3798 91.4674L66.5679 89.5107L83.2366 48.5178C83.3558 48.2247 83.5866 47.9909 83.8781 47.8679C84.1697 47.745 84.4981 47.7428 84.7913 47.862Z"
            fill={fillColor}
          />
          <path
            d="M82.6354 66.9564C82.5311 66.9139 82.4435 66.8386 82.3857 66.7421C82.3279 66.6455 82.303 66.5327 82.3148 66.4208L82.5684 64.0229C82.5752 63.9592 82.5937 63.8973 82.6229 63.8403L87.6799 53.9959C87.7082 53.9409 87.7247 53.8806 87.7284 53.8189C87.7321 53.7572 87.7229 53.6954 87.7014 53.6374C87.6799 53.5794 87.6465 53.5265 87.6035 53.4821C87.5604 53.4377 87.5086 53.4028 87.4513 53.3795L82.2511 51.265C82.1237 51.213 82.0221 51.1126 81.9687 50.9858C81.9153 50.859 81.9144 50.7161 81.9662 50.5887C82.018 50.4612 82.1184 50.3595 82.2451 50.306C82.3719 50.2525 82.5147 50.2514 82.6422 50.3031L87.8424 52.4177C88.0332 52.4953 88.2056 52.6117 88.3489 52.7595C88.4922 52.9074 88.6032 53.0834 88.6748 53.2765C88.7463 53.4695 88.777 53.6753 88.7647 53.8809C88.7524 54.0864 88.6975 54.2871 88.6035 54.4703L83.5908 64.2285L83.3475 66.53C83.3389 66.61 83.3119 66.6869 83.2685 66.7547C83.2252 66.8225 83.1667 66.8793 83.0977 66.9206C83.0286 66.962 82.951 66.9867 82.8707 66.9929C82.7905 66.9991 82.7099 66.9866 82.6354 66.9564V66.9564Z"
            fill={fillColor}
          />
          <path
            opacity="0.15"
            d="M82.6354 66.9564C82.5311 66.9139 82.4435 66.8386 82.3857 66.7421C82.3279 66.6455 82.303 66.5327 82.3148 66.4208L82.5684 64.0229C82.5752 63.9592 82.5937 63.8973 82.6229 63.8403L87.6799 53.9959C87.7082 53.9409 87.7247 53.8806 87.7284 53.8189C87.7321 53.7572 87.7229 53.6954 87.7014 53.6374C87.6799 53.5794 87.6465 53.5265 87.6035 53.4821C87.5604 53.4377 87.5086 53.4028 87.4513 53.3795L82.2511 51.265C82.1237 51.213 82.0221 51.1126 81.9687 50.9858C81.9153 50.859 81.9144 50.7161 81.9662 50.5887C82.018 50.4612 82.1184 50.3595 82.2451 50.306C82.3719 50.2525 82.5147 50.2514 82.6422 50.3031L87.8424 52.4177C88.0332 52.4953 88.2056 52.6117 88.3489 52.7595C88.4922 52.9074 88.6032 53.0834 88.6748 53.2765C88.7463 53.4695 88.777 53.6753 88.7647 53.8809C88.7524 54.0864 88.6975 54.2871 88.6035 54.4703L83.5908 64.2285L83.3475 66.53C83.3389 66.61 83.3119 66.6869 83.2685 66.7547C83.2252 66.8225 83.1667 66.8793 83.0977 66.9206C83.0286 66.962 82.951 66.9867 82.8707 66.9929C82.7905 66.9991 82.7099 66.9866 82.6354 66.9564V66.9564Z"
            fill="black"
          />
          <path
            d="M66.568 89.5103L66.0413 97.7007L71.3799 91.4669L66.568 89.5103Z"
            fill={fillColor}
          />
          <path
            opacity="0.15"
            d="M66.568 89.5103L66.0413 97.7007L71.3799 91.4669L66.568 89.5103Z"
            fill="black"
          />
          <defs>
            <filter
              id="filter0_d_20_72021"
              x="0.208984"
              y="0"
              width="104.674"
              height="127.146"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="15" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_20_72021"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_20_72021"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      ) : (
        <Center>
          <Image
            w="100%"
            // style={{aspectRatio: 16 / 9}}
            // resizeMode="contain"
            source={require('../../../assets/images/EmptyStatePng/noContactNotes.png')}
            alt="image"
          ></Image>
        </Center>
      )}

      {props.titleId && (
        <Text style={svgStyles.svgText}>
          <DisplayText textLocalId={props.titleId} textType="Text" />
        </Text>
      )}
    </View>
  );
};

export default NoContactNotesSvg;
