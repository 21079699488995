import {Text, View} from 'native-base';
import React from 'react';
import {Colors} from '../../../styles';
import {DisplayText} from '../DisplayText/DisplayText';
import {svgStyles} from './SvgStyles';
import { Path, Svg } from 'react-native-svg';

const NoActivitySvg = (props: {titleId?: string; fillColor?: string}) => {
  const fillColor = props.fillColor || Colors.primary['300'];

  return (
    <View style={svgStyles.container}>
      <Svg
        width="151"
        height="141"
        viewBox="0 0 151 141"
        fill="none"
      >
        <Path
          d="M31.2312 140.984H119.767C122.2 140.99 124.546 140.07 126.334 138.409C133.792 131.463 139.759 123.054 143.867 113.701C147.975 104.348 150.138 94.2487 150.222 84.0237C150.604 42.4473 117.009 8.23749 75.6803 8.13937C34.328 8.0412 0.774951 41.736 0.774951 83.3147C0.763143 93.6633 2.88123 103.902 6.99585 113.387C11.1105 122.872 17.1325 131.396 24.6827 138.425C26.4668 140.078 28.8058 140.992 31.2312 140.984V140.984Z"
          fill="#F9FAFB"
        />
        <Path
          d="M96.0386 8.79249H90.0442C88.8096 8.7816 87.6035 8.42035 86.5661 7.75077C85.5288 7.0812 84.703 6.13082 84.1847 5.01022C83.4698 3.51028 82.3446 2.24357 80.9394 1.35685C79.5342 0.470123 77.9066 -0.000327011 76.245 1.70541e-07H74.7555V0.128181C73.3581 0.369043 72.0395 0.943848 70.9119 1.80371C69.7843 2.66357 68.881 3.78304 68.2789 5.06692C67.7617 6.17808 66.9384 7.11874 65.9056 7.7786C64.8727 8.43846 63.6731 8.79017 62.4475 8.79249H56.4513C56.0293 8.79249 55.6115 8.8756 55.2217 9.03707C54.8318 9.19855 54.4776 9.43523 54.1793 9.7336C53.8809 10.032 53.6442 10.3862 53.4827 10.776C53.3213 11.1659 53.2382 11.5837 53.2382 12.0056V17.1986C53.2382 18.0508 53.5767 18.8681 54.1793 19.4706C54.7819 20.0732 55.5991 20.4118 56.4513 20.4118H96.0386C96.8908 20.4118 97.7081 20.0732 98.3107 19.4706C98.9132 18.8681 99.2518 18.0508 99.2518 17.1986V12.0057C99.2518 11.1535 98.9132 10.3362 98.3107 9.73361C97.7081 9.13102 96.8908 8.7925 96.0386 8.79249Z"
          fill={fillColor}
        />
        <Path
          opacity="0.15"
          d="M96.0386 8.79249H90.0442C88.8096 8.7816 87.6035 8.42035 86.5661 7.75077C85.5288 7.0812 84.703 6.13082 84.1847 5.01022C83.4698 3.51028 82.3446 2.24357 80.9394 1.35685C79.5342 0.470123 77.9066 -0.000327011 76.245 1.70541e-07H74.7555V0.128181C73.3581 0.369043 72.0395 0.943848 70.9119 1.80371C69.7843 2.66357 68.881 3.78304 68.2789 5.06692C67.7617 6.17808 66.9384 7.11874 65.9056 7.7786C64.8727 8.43846 63.6731 8.79017 62.4475 8.79249H56.4513C56.0293 8.79249 55.6115 8.8756 55.2217 9.03707C54.8318 9.19855 54.4776 9.43523 54.1793 9.7336C53.8809 10.032 53.6442 10.3862 53.4827 10.776C53.3213 11.1659 53.2382 11.5837 53.2382 12.0056V17.1986C53.2382 18.0508 53.5767 18.8681 54.1793 19.4706C54.7819 20.0732 55.5991 20.4118 56.4513 20.4118H96.0386C96.8908 20.4118 97.7081 20.0732 98.3107 19.4706C98.9132 18.8681 99.2518 18.0508 99.2518 17.1986V12.0057C99.2518 11.1535 98.9132 10.3362 98.3107 9.73361C97.7081 9.13102 96.8908 8.7925 96.0386 8.79249Z"
          fill="black"
        />
        <Path
          d="M117.962 14.9093H35.4147C32.9516 14.9093 30.9548 16.906 30.9548 19.3691V136.54C30.9548 139.003 32.9516 141 35.4147 141H117.962C120.425 141 122.422 139.003 122.422 136.54V19.3691C122.422 16.906 120.425 14.9093 117.962 14.9093Z"
          fill="#D0D5DD"
        />
        <Path
          d="M115.585 14.9093H33.0377C30.5746 14.9093 28.5779 16.906 28.5779 19.3691V136.54C28.5779 139.003 30.5746 141 33.0377 141H115.585C118.049 141 120.045 139.003 120.045 136.54V19.3691C120.045 16.906 118.049 14.9093 115.585 14.9093Z"
          fill="#E4E7EC"
        />
        <Path
          d="M111.673 19.9194H36.9506C35.3464 19.9194 34.046 21.2199 34.046 22.824V133.035C34.046 134.639 35.3464 135.939 36.9506 135.939H111.673C113.277 135.939 114.578 134.639 114.578 133.035V22.824C114.578 21.2199 113.277 19.9194 111.673 19.9194Z"
          fill="white"
        />
        <Path
          d="M48.8028 55.5341C53.0993 55.5341 56.5823 52.0511 56.5823 47.7546C56.5823 43.4581 53.0993 39.9751 48.8028 39.9751C44.5063 39.9751 41.0233 43.4581 41.0233 47.7546C41.0233 52.0511 44.5063 55.5341 48.8028 55.5341Z"
          fill={fillColor}
        />
        <Path
          d="M48.8028 77.79C53.0993 77.79 56.5823 74.307 56.5823 70.0105C56.5823 65.714 53.0993 62.231 48.8028 62.231C44.5063 62.231 41.0233 65.714 41.0233 70.0105C41.0233 74.307 44.5063 77.79 48.8028 77.79Z"
          fill={fillColor}
        />
        <Path
          d="M48.8028 100.045C53.0993 100.045 56.5823 96.5623 56.5823 92.2658C56.5823 87.9693 53.0993 84.4863 48.8028 84.4863C44.5063 84.4863 41.0233 87.9693 41.0233 92.2658C41.0233 96.5623 44.5063 100.045 48.8028 100.045Z"
          fill={fillColor}
        />
        <Path
          d="M47.248 51.5802C47.1089 51.5804 46.9711 51.553 46.8426 51.4998C46.714 51.4466 46.5973 51.3685 46.499 51.27L44.3418 49.1128C44.1439 48.914 44.0329 48.6448 44.0332 48.3643C44.0335 48.0838 44.1451 47.8148 44.3434 47.6165C44.5418 47.4181 44.8107 47.3066 45.0912 47.3063C45.3718 47.3059 45.6409 47.4169 45.8397 47.6148L47.248 49.023L51.2486 45.0224C51.3469 44.9237 51.4637 44.8453 51.5923 44.7918C51.7209 44.7383 51.8588 44.7107 51.9981 44.7105C52.1374 44.7104 52.2753 44.7377 52.4041 44.7909C52.5328 44.8441 52.6497 44.9223 52.7482 45.0207C52.8467 45.1192 52.9248 45.2362 52.9781 45.3649C53.0313 45.4937 53.0586 45.6316 53.0585 45.7709C53.0583 45.9102 53.0307 46.0481 52.9772 46.1767C52.9236 46.3053 52.8453 46.4221 52.7466 46.5204L47.997 51.27C47.8987 51.3685 47.782 51.4466 47.6535 51.4998C47.5249 51.5531 47.3871 51.5804 47.248 51.5802V51.5802Z"
          fill="white"
        />
        <Path
          d="M105.742 45.3721H64.3625C63.0466 45.3721 61.9799 46.4389 61.9799 47.7547V47.7547C61.9799 49.0706 63.0466 50.1373 64.3625 50.1373H105.742C107.058 50.1373 108.124 49.0706 108.124 47.7547C108.124 46.4389 107.058 45.3721 105.742 45.3721Z"
          fill="#E4E7EC"
        />
        <Path
          d="M47.248 73.8356C47.1089 73.8357 46.9711 73.8084 46.8426 73.7552C46.714 73.7019 46.5973 73.6238 46.499 73.5253L44.3418 71.3682C44.1439 71.1694 44.0329 70.9002 44.0332 70.6197C44.0335 70.3392 44.1451 70.0702 44.3434 69.8719C44.5418 69.6735 44.8107 69.562 45.0912 69.5616C45.3718 69.5613 45.6409 69.6723 45.8397 69.8702L47.248 71.2784L51.2486 67.2778C51.4474 67.0799 51.7166 66.9689 51.9971 66.9692C52.2776 66.9696 52.5465 67.0811 52.7449 67.2795C52.9432 67.4778 53.0548 67.7468 53.0551 68.0273C53.0554 68.3078 52.9445 68.577 52.7466 68.7758L47.997 73.5253C47.8987 73.6238 47.782 73.702 47.6535 73.7552C47.5249 73.8084 47.3871 73.8358 47.248 73.8356V73.8356Z"
          fill="white"
        />
        <Path
          d="M105.742 67.6274H64.3625C63.0466 67.6274 61.9799 68.6942 61.9799 70.01V70.01C61.9799 71.3259 63.0466 72.3926 64.3625 72.3926H105.742C107.058 72.3926 108.124 71.3259 108.124 70.01C108.124 68.6942 107.058 67.6274 105.742 67.6274Z"
          fill="#E4E7EC"
        />
        <Path
          d="M47.248 96.0914C47.1089 96.0916 46.9711 96.0643 46.8426 96.011C46.714 95.9578 46.5973 95.8797 46.499 95.7812L44.3418 93.624C44.1439 93.4252 44.0329 93.1561 44.0332 92.8755C44.0335 92.595 44.1451 92.3261 44.3434 92.1277C44.5418 91.9294 44.8107 91.8178 45.0912 91.8175C45.3718 91.8172 45.6409 91.9282 45.8397 92.1261L47.248 93.5343L51.2486 89.5337C51.4474 89.3358 51.7166 89.2248 51.9971 89.2251C52.2776 89.2254 52.5465 89.337 52.7449 89.5353C52.9432 89.7337 53.0548 90.0026 53.0551 90.2831C53.0554 90.5636 52.9445 90.8328 52.7466 91.0316L47.997 95.7812C47.8987 95.8797 47.782 95.9578 47.6535 96.0111C47.5249 96.0643 47.3871 96.0916 47.248 96.0914V96.0914Z"
          fill="white"
        />
        <Path
          d="M105.742 89.8833H64.3625C63.0466 89.8833 61.9799 90.95 61.9799 92.2659C61.9799 93.5818 63.0466 94.6485 64.3625 94.6485H105.742C107.058 94.6485 108.124 93.5818 108.124 92.2659C108.124 90.95 107.058 89.8833 105.742 89.8833Z"
          fill="#E4E7EC"
        />
        <Path
          d="M48.8028 122.301C53.0993 122.301 56.5823 118.818 56.5823 114.522C56.5823 110.225 53.0993 106.742 48.8028 106.742C44.5063 106.742 41.0233 110.225 41.0233 114.522C41.0233 118.818 44.5063 122.301 48.8028 122.301Z"
          fill="#E4E7EC"
        />
        <Path
          d="M105.742 112.139H64.3625C63.0466 112.139 61.9799 113.206 61.9799 114.522V114.522C61.9799 115.838 63.0466 116.904 64.3625 116.904H105.742C107.058 116.904 108.124 115.838 108.124 114.522C108.124 113.206 107.058 112.139 105.742 112.139Z"
          fill="#E4E7EC"
        />
        <Path
          d="M94.549 8.7925H88.5546C87.32 8.78161 86.1138 8.42036 85.0765 7.75079C84.0392 7.08121 83.2133 6.13083 82.695 5.01023C81.9799 3.51049 80.8547 2.24396 79.4495 1.35727C78.0444 0.470585 76.4169 0 74.7554 0C73.0939 0 71.4663 0.470585 70.0612 1.35727C68.6561 2.24396 67.5308 3.51049 66.8157 5.01023C66.2974 6.13083 65.4715 7.0812 64.4342 7.75078C63.3969 8.42036 62.1908 8.78161 60.9562 8.7925H54.9617C54.5397 8.7925 54.1219 8.87561 53.7321 9.03708C53.3422 9.19856 52.988 9.43524 52.6896 9.7336C52.3913 10.032 52.1546 10.3862 51.9931 10.776C51.8316 11.1659 51.7485 11.5837 51.7485 12.0056V24.2507C51.7485 25.1028 52.0871 25.9201 52.6897 26.5227C53.2922 27.1253 54.1095 27.4638 54.9617 27.4638H94.549C94.9709 27.4638 95.3888 27.3807 95.7786 27.2192C96.1684 27.0578 96.5227 26.8211 96.821 26.5227C97.1194 26.2243 97.3561 25.8701 97.5176 25.4803C97.679 25.0904 97.7622 24.6726 97.7622 24.2506V12.0057C97.7621 11.1535 97.4236 10.3362 96.821 9.73362C96.2184 9.13103 95.4012 8.79251 94.549 8.7925Z"
          fill={fillColor}
        />
        <Path
          opacity="0.2"
          d="M93.0462 22.7163H57.5129C56.8135 22.7163 56.2466 23.2833 56.2466 23.9826V23.9826C56.2466 24.682 56.8135 25.2489 57.5129 25.2489H93.0462C93.7455 25.2489 94.3125 24.682 94.3125 23.9826C94.3125 23.2833 93.7455 22.7163 93.0462 22.7163Z"
          fill="white"
        />
      </Svg>

      {props.titleId && (
        <Text
          style={[
            svgStyles.svgText,
            {
              textAlign: 'center',
              fontSize: 20,
              marginTop: 20,
            },
          ]}
        >
          <DisplayText textLocalId={props.titleId} textType="Text" />
        </Text>
      )}
    </View>
  );
};

export default NoActivitySvg;
