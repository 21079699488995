import {useLazyQuery} from '@apollo/client';
import React from 'react';
import ConversationsQueries from '../../../services/Conversations/ConversationsQueries';
import {getAccountId} from '../../../utils/commonUtils';
import {IContactCardState} from './interfaces';
import MemberInfoListItem from '../MemberInfoListItem/MemberInfoListItem';
import {View} from 'react-native';
import { Colors } from '../../../styles/Colors';

const ContactCard = (props: {contactUuid: string}) => {
  const accountId = getAccountId();

  const [stateData, setStateData] = React.useState<IContactCardState>({});

  const [getBasicContactDetailsByUuid] = useLazyQuery(
    ConversationsQueries.GetBasicContactDetailsByUuid,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const fetchContactData = async () => {
    const response = await getBasicContactDetailsByUuid({
      variables: {
        accountId: accountId,
        contactUuid: props?.contactUuid || "",
      },
    });

    if (response?.data && response?.data?.contacts?.length) {
      setStateData((prev) => {
        return {
          ...prev,
          contactData: response?.data?.contacts?.[0],
        };
      });
    }
  };

  React.useEffect(() => {
    fetchContactData();
  }, []);

  return stateData?.contactData ? (
    <View
      style={{
        borderRadius: 8,
        borderColor: Colors.FoldPixel.GRAY150,
        borderWidth: 0.5,
        paddingHorizontal: 8,
        paddingVertical: 9.5
      }}
    >
      <MemberInfoListItem
        contactData={stateData?.contactData as any}
        alignAvatar={"center"}
        hideFoldStatus
        showDateOfBirth
      />
    </View>
  ) : <></>;
};

export default ContactCard;
