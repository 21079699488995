import React, {useMemo} from 'react';
import {
  VictoryArea,
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryLabel,
  VictoryLine,
  VictoryScatter,
  VictoryStack,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from '../../../../../../PersonOmniView/LeftContainer/RecentActivity/RecentReport/VictoryComponent';
import { getDomain, getGraphNodeColor, getGraphNodeShape } from '../../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailTables/VitalsGraphView/VitalsGraphUtils';
import {Colors} from '../../../../../../../styles';
import {GraphDataOperation, GraphsTypes, VitalGraphData} from '../interface';
import {isWeb} from '../../../../../../../utils/platformCheckUtils';

const TrendView = (props: {vitalGraph: VitalGraphData, toolTipFontSize?: number}) => {
  const {vitalGraph, toolTipFontSize} = props;
  const isEmptyGraph = vitalGraph.graphData.length === 0;
  const dataOperation = vitalGraph.dataOperation;
  const isLowEvent = dataOperation === GraphDataOperation.LOW_EVENT;
  const filterFunc = (data: any) => {
    return (typeof data.y === 'number' && data.y !== 0) || (typeof data.y === 'string' && data.y !== '0');
  }    

  const tooltipFontSize = toolTipFontSize || 12;

  const renderGraphByType = () => {
    switch (vitalGraph.graphType) {
      case GraphsTypes.BAR:
        if(vitalGraph?.tickValues) {
          return <VictoryBar
               barWidth={3}
               data={props.vitalGraph.graphData}
               style={{
                 data: {
                   fill: isLowEvent
                     ? Colors.Custom.ErrorColor
                     : Colors.Custom.BarColorV2,
                 },
                 labels: {
                   fill: isLowEvent
                     ? Colors.Custom.ErrorColor
                     : Colors.Custom.BarColorV2,
                 },
               }}
               cornerRadius={{
                bottom: 2,
                top: 2,
              }}
               labels={
                 (d: any) => {
                   return d.datum.tooltipText ? d.datum.tooltipText : [];
                 }
               }
               labelComponent={
                 <VictoryTooltip
                   flyoutStyle={{
                     fill: Colors.Custom.White,
                     strokeWidth: 1,
                     stroke: Colors.Custom.Gray200,
                   }}
                   renderInPortal={isWeb()}
                   style={{
                     fill: (d: any) => {
                       return Colors.Custom?.TitleColor;
                     },
                     fontSize: 12,
                   }}
                   constrainToVisibleArea
                 />
               }
             />
         }
 
        const maxValue = Math.max(
          ...vitalGraph.graphData.map((data) =>
            typeof data.y === 'number' ? data.y : parseInt(data.y)
          )
        );

        const finalPlot = props.vitalGraph.graphData.map((data) => ({
          ...data,
          y:
            maxValue - (typeof data.y === 'number' ? data.y : parseInt(data.y)),
        }));

        return (
          <VictoryStack>
            <VictoryBar
              data={props.vitalGraph.graphData}
              barWidth={5}
              style={{
                data: {
                  fill:
                    isLowEvent
                      ? Colors.FoldPixel.STATUS_ERROR
                      : Colors.Custom.activeJourneyColor,
                },
                labels: {
                  fill:
                  isLowEvent
                      ? Colors.FoldPixel.STATUS_ERROR
                      : Colors.Custom.activeJourneyColor,
                },
              }}
              cornerRadius={2}
              labelComponent={
                <VictoryLabel style={[{fill: Colors.Custom.White}]} dy={100} />
              }
            />
            <VictoryBar
              barWidth={5}
              style={{
                data: {
                  fill: Colors.Custom.Gray100,
                },
              }}
              cornerRadius={2}
              labelComponent={
                <VictoryLabel style={[{fill: Colors.Custom.White}]} dy={100} />
              }
              data={finalPlot}
            />
          </VictoryStack>
        );
      case GraphsTypes.LINE:
        return (
          <VictoryLine
            data={props.vitalGraph.graphData.filter(filterFunc)}
            style={{
              data: {
                stroke: Colors.Custom.graphLineColor,
                strokeWidth: 1,
              },
            }}
          />
        );
        case GraphsTypes.VERTICAL_SCATTER: 
          return (
            <VictoryGroup>
              {props.vitalGraph.graphData?.map((graph, index) => {
                return (
                  <VictoryScatter
                    style={{
                      data: {
                        fill: getGraphNodeColor(graph),
                      },
                    }}
                    symbol={getGraphNodeShape(graph)}
                    labelComponent={
                      <VictoryTooltip
                        renderInPortal={isWeb()}
                        flyoutStyle={{
                          fill: Colors.Custom.White,
                          strokeWidth: 1,
                          stroke: Colors.Custom.Gray200,
                        }}      
                        style={{
                          fill: (d: any) => {
                            return Colors.FoldPixel?.GRAY300;
                          },
                          fontSize: tooltipFontSize,
                          backgroundColor: Colors.Custom.White,
                        }}
                        constrainToVisibleArea
                      />
                    }
                    labels={(d: any) => {              
                      return d.datum.tooltipText ? d.datum.tooltipText : `${d.datum.y}`;
                    }}
                    size={2}
                    data={graph as []}
                  />
                );
              })}

              {props.vitalGraph.barData?.map((graph, index) => {
                return (
                  <VictoryBar
                    barWidth={1}
                    style={{
                      data: {
                        fill: getGraphNodeColor(graph),
                      },
                    }}
                    labelComponent={
                      <VictoryLabel
                        style={[{fill: Colors.Custom.White}]}
                        dy={100}
                      />
                    }
                    data={graph as []}
                  />
                );
              })}
            </VictoryGroup>
          );   
  
    }
  };

  const containerComponent = (
    <VictoryVoronoiContainer
      labelComponent={
        <VictoryTooltip
          renderInPortal={isWeb()}
          flyoutStyle={{
            fill: Colors.Custom.White,
            strokeWidth: 1,
            stroke: Colors.Custom.Gray200,
          }}
          style={{
            fill: (d: any) => {
              return Colors.Custom?.HeadingColor;
            },
            fontSize: 8,
          }}
          constrainToVisibleArea
        />
      }
      labels={(point, index, points) => {
        return '';
      }}
    />
  );


  return (
    <VictoryChart
      domain={
        getDomain(vitalGraph.graphData, vitalGraph.annontationIndex) as any
      }
      minDomain={{y: 0}}
      height={50}
      width={isWeb() ? 180 : 160}
      key={vitalGraph?.title}
      style={{
        parent: {
          flex: 0.5,
        },
      }}
      containerComponent={containerComponent}
      padding={{
        top: isWeb() ? 5 : 2,
        bottom: isWeb() ? 2 : 0,
        left: isWeb() ? 20 : 10,
        right: 20,
      }}
    >
      <VictoryAxis
        style={{
          axis: {stroke: Colors.FoldPixel.GRAY100, strokeDasharray: 5},
          ticks: {stroke: 'transparent'},
          tickLabels: {fill: 'transparent'},
          grid: {
            stroke: Colors.FoldPixel.GRAY100,
            strokeWidth: 1,
            strokeDasharray: 5,
          },
        }}
      />
      <VictoryAxis
        dependentAxis
        tickValues={props.vitalGraph?.tickValues}
        tickFormat={props.vitalGraph.tickLabelFunc}
        style={{
          axis: {stroke: 'transparent'},
          ticks: {stroke: 'transparent'},
          tickLabels: {fill: 'transparent'},
        }}
      />

        {renderGraphByType()}
        {vitalGraph.graphType === GraphsTypes.LINE && (
          <VictoryScatter
            data={props.vitalGraph.graphData.filter(filterFunc)}
            style={{
              data: {
                fill: Colors.Custom.graphLineColor,
              },
            }}
            labelComponent={
              <VictoryTooltip
                flyoutStyle={{
                  fill: Colors.Custom.White,
                  strokeWidth: 1,
                  stroke: Colors.Custom.Gray200,
                }}      
                style={{
                  fill: (d: any) => {
                    return Colors.FoldPixel?.GRAY300;
                  },
                  fontSize: tooltipFontSize,
                  backgroundColor: Colors.Custom.White,
                }}
                constrainToVisibleArea
              />
            }
            labels={(d: any) => {              
              return d.datum.tooltipText ? d.datum.tooltipText : `${d.datum.y}`;
            }}
            size={2}
          />
        )}
    </VictoryChart>
  );
};

export default TrendView;
