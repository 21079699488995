import {gql} from '@apollo/client';

const GetAllConversationWithCallMessages = gql`
  query GetAllConversationWithCallMessages(
    $limit: Int!
    $offset: Int!
    $loginUserUuid: uuid!
    $whereCondition: conversations_bool_exp!
  ) {
    conversationMessageGroups(
      limit: $limit
      offset: $offset
      order_by: {messageCreatedAt: desc_nulls_last}
      where: {conversation: $whereCondition}
    ) {
      messageConversation {
        contactId
        accountId
        id
        uuid
        practiceLocationUuid
        conversationMainUuid: uuid
        displayId
        inboxId
        status
        additionalAttributes
        assigneeId
        createdAt
        updatedAt
        lastActivityAt
        workflowCommunicationLogResponseId
        blockReasonCode
        subject
        conversationInbox {
          id
          channelType
          name
          isDeleted
          channelTwilioSms {
            phoneNumber
          }
          inboxMembers(where: {user: {uuid: {_eq: $loginUserUuid}}}) {
            user {
              uuid
              id
              name
            }
          }
        }
        conversationContact {
          id
          uuid
          isDeleted
          name
          phoneNumber
          email
        }
        contactInbox {
          sourceId
        }
        mentions {
          userId
          conversationId
        }
        assignedUser {
          id
          name
        }
        groupConversation {
          id
          name
          groupType {
            code
          }
          groupMembers(where: {groupUserId: {_eq: $loginUserUuid}}) {
            groupUserId
            groupUserType {
              code
              value
            }
            user {
              id
              uuid
              name
              email
              accountUsers {
                isActive
                userId
              }
            }
          }
        }
      }
      message {
        ...conversationMessages
      }
      messageContent: messageConversation {
        conversationMessages(
          limit: 1
          order_by: {createdAt: desc}
          where: {
            messageType: {_nin: [-1, 4, 5, 6, 7]}
            _or: [
              {
                inboxId: {_neq: -1}
                inbox: {channelType: {_neq: "Channel::Email"}}
              }
              {inboxId: {_eq: -1}}
            ]
          }
        ) {
          content
        }
      }
    }
  }

  fragment conversationMessages on messages {
    senderType
    senderId
    createdAt
    updatedAt
    messageType
    id
    uuid
    messageAttachments {
      id
    }
    senderUser {
      id
      name
    }
  }

  fragment conversationMessageContent on messages {
    id
    content
  }
`;

const GetMessageContentAttribiteByUuids = gql`
  query getMessageContentAttribiteByUuids($messageUuids: [uuid!]) {
    messages(where: {uuid: {_in: $messageUuids}}) {
      id
      uuid
      content
      contentAttributes
    }
  }
`;

export default {
  GetAllConversationWithCallMessages,
  GetMessageContentAttribiteByUuids,
};
