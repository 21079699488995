import React from 'react';
import { View } from 'react-native';
import { interventionSvgStyles } from '../InterventionSvgStyles';
import { Path, Svg } from "react-native-svg";

const InternalTaskIconSvg = () => {

  return (
    <View style={interventionSvgStyles.leftSvgStyle}>
      <Svg
        width="20"
        height="21"
        fill="none"
        viewBox="0 0 20 21"
        stroke="#6F7A90"
        strokeWidth="0.5"
      >
        <Path
          fill="#6F7A90"
          d="M18.121 3.377l-.353.353.353-.353zm-16.242 0l-.354-.354.354.354zM13 12a.5.5 0 100-1v1zm-6-1a.5.5 0 000 1v-1zm3.5-2.5a.5.5 0 00-1 0h1zm-1 6a.5.5 0 001 0h-1zm9-6.002v6h1v-6h-1zm-5.5 11.5H7v1h6v-1zm-11.5-5.5v-6h-1v6h1zm5.5 5.5c-1.428 0-2.45 0-3.225-.105-.762-.102-1.212-.296-1.543-.627l-.707.707c.548.548 1.244.794 2.117.911.858.115 1.958.114 3.358.114v-1zm-6.5-5.5c0 1.4-.001 2.5.114 3.359.118.872.363 1.568.911 2.116l.707-.707c-.33-.33-.524-.781-.627-1.542-.104-.776-.105-1.797-.105-3.226h-1zm18 0c0 1.429-.001 2.45-.105 3.226-.103.761-.296 1.211-.627 1.542l.707.707c.548-.548.793-1.244.91-2.116.116-.858.115-1.959.115-3.359h-1zm-5.5 6.5c1.4 0 2.5.001 3.358-.114.873-.117 1.57-.363 2.117-.91l-.707-.708c-.331.331-.781.525-1.543.627-.776.104-1.797.105-3.225.105v1zm6.5-12.5c0-1.4.001-2.5-.114-3.358-.118-.873-.363-1.569-.911-2.117l-.707.707c.33.331.524.782.627 1.543.104.776.105 1.797.105 3.225h1zm-18 0c0-1.428.001-2.449.105-3.225.103-.761.296-1.212.627-1.543l-.707-.707C.977 3.571.732 4.267.615 5.14.498 5.998.5 7.098.5 8.498h1zM13.997 3c1.092.006 1.888.034 2.5.147.6.11.98.293 1.27.583l.708-.707c-.478-.478-1.07-.727-1.797-.86-.711-.13-1.592-.157-2.675-.163l-.006 1zm-8-1c-1.083.006-1.964.032-2.675.163-.727.133-1.319.382-1.797.86l.707.707c.29-.29.672-.473 1.27-.583.613-.113 1.41-.14 2.5-.147l-.005-1zM7.5 1h5V0h-5v1zm6 1v1h1V2h-1zm-1 2h-5v1h5V4zm-6-1V2h-1v1h1zm1 1a1 1 0 01-1-1h-1a2 2 0 002 2V4zm6-1a1 1 0 01-1 1v1a2 2 0 002-2h-1zm-1-2a1 1 0 011 1h1a2 2 0 00-2-2v1zm-5-1a2 2 0 00-2 2h1a1 1 0 011-1V0zM13 11h-3v1h3v-1zm-3 0H7v1h3v-1zm-.5-2.5v3h1v-3h-1zm0 3v3h1v-3h-1z"
        ></Path>
      </Svg>
    </View>
  );
};

export default InternalTaskIconSvg;
