import { Text, VStack, View, useMediaQuery } from 'native-base';
import { SMSPreviewProps } from '../interfaces';
import { styles } from './SMSPreviewStyles';
import ReactDevicePreview from 'react-device-preview';
import './SMSPreview.css'
import { Colors } from '../../../../../styles';

const SMSPreview = (props: SMSPreviewProps) => {
  const { bodyText, fontSize, showMobilePreview = true } = props;
  const [isSmallScreen] = useMediaQuery({maxWidth: 1512});
  
  const smsPreview = () => {
    return (
      <View
        style={styles.noPreview}
        flex={1}
      >
        <Text
          {...(fontSize? { fontSize: fontSize } : {})}
        >{bodyText ? bodyText : 'Body'}</Text>
      </View>
    )
  }

  const smsMobilePreview = () => {
    return (
      <VStack p={10} pb={0} style={styles.container}>
        <Text fontWeight={300}>
          Preview
        </Text>
        <View style={{
          marginTop: 16,
          alignSelf: 'center',
          marginLeft: isSmallScreen ? 225 : 156,
          maxHeight: 350,
          overflow: 'hidden',
        }}>
          <div className='fixNotch'>
            <ReactDevicePreview
              device={'iphonex'}
              scale={isSmallScreen ? '0.5' : '0.65'}
            >
              <View my={16} mx={4} style={{
                backgroundColor: '#e9e9eb',
                padding: 10,
                paddingHorizontal: 5,
                borderRadius: 10,
                marginLeft: 15,
                marginRight: 40,
                marginBottom: 320
              }}>
                <Text
                  fontSize={18}
                >
                  {bodyText ? `${bodyText}` : 'Body'}
                </Text>
              </View>
            </ReactDevicePreview>
          </div>
        </View>
      </VStack>
    )
  }
  return (
    showMobilePreview ? (
      smsMobilePreview()
    ) : (
      smsPreview()
    )
  );
};

export default SMSPreview;
