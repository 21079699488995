import React from 'react';
import {Modal, Text} from 'native-base';
import {IComingSoonModalProps} from '..';

const {CloseButton, Content, Header, Body} = Modal;

const ComingSoonModal = (props: IComingSoonModalProps) => {
  const {isOpen, onModalClose, headerText, bodyText} = props;
  return (
    <Modal isOpen={isOpen} onClose={() => onModalClose()}>
      <Content>
        <CloseButton />
        <Header>
          <Text>{headerText}</Text>
        </Header>
        <Body>
          <Text>{bodyText}</Text>
        </Body>
      </Content>
    </Modal>
  );
};

export default ComingSoonModal;
