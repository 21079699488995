import React, {useContext} from 'react';
import { CONFIG_CODES } from '../../../constants/AccountConfigConst';
import {CommonDataContext} from '../../../context/CommonDataContext';
import { getConfigDataFromCode } from '../../../utils/commonUtils';
import {IThemeAttachments} from '../../BodyContainer/interface';
import { IDefaultFormTheme } from '../../RightSideContainer/Forms/interfaces';
import BrandingLogo from '../BrandingLogo/BrandingLogo';

const BrandingLogoWrapper = (props: {
  accountNameTextColor?: string;
  accountLogoPosition?: string;
  accountNamePosition?: string;
  isFromPrint?: boolean;
}) => {
  const commonData = useContext(CommonDataContext);
  // Account name
  const accountData: any = commonData.accountSubdomain || {};
  const accountName = accountData.accountToSubdomain?.name;

  // Account logo
  const accountThemeConfig: any = commonData.accountThemeConfig || {};
  const tempThemeAdditionalData: string =
    accountThemeConfig.additionalAttributes || '{}';
  const finalThemeAdditionalAttributes: IThemeAttachments = JSON.parse(
    tempThemeAdditionalData
  );
  const url = finalThemeAdditionalAttributes?.attachment?.logo?.url;
  const isLogoWithName = finalThemeAdditionalAttributes?.attachment?.logo?.isLogoWithName || false;

  const defaultThemeConfig = getConfigDataFromCode(CONFIG_CODES.DEFAULT_THEME);
  let configJson = undefined;
  let formThemeConfig: IDefaultFormTheme | undefined  = {} as IDefaultFormTheme;

  if (defaultThemeConfig?.accountConfigurations && defaultThemeConfig?.accountConfigurations?.length) {
    configJson = JSON.parse(defaultThemeConfig?.accountConfigurations[0]?.value);
    formThemeConfig = configJson?.form || undefined;
  } else if (defaultThemeConfig?.defaultValue){
    configJson = JSON.parse(defaultThemeConfig?.defaultValue);
    formThemeConfig = configJson?.form || undefined;
  }

  return (
    <BrandingLogo
      accountNameTextColor={props.accountNameTextColor || formThemeConfig?.accountName?.color}
      name={isLogoWithName ? accountName : undefined}
      isLogoWithName={isLogoWithName}
      url={url}
      isFromPrint={props.isFromPrint}
      accountNamePosition={props.accountNamePosition || formThemeConfig?.accountName?.position}
      accountLogoPosition={props.accountLogoPosition || formThemeConfig?.accountLogo?.position}
      onClick={() => {
        /**/
      }}
    />
  );
};

export default BrandingLogoWrapper;
