import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
    formElement: {
        paddingTop: 10,
        paddingBottom: 10,
      },
      formLabel: {
        marginLeft: 4,
      },
      checkboxContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 15,
        marginLeft: 4,
      },
})

export const reactStyles: Record<string, React.CSSProperties> = {
  autoComplete: {
    fontSize: 12,
  },
}