import React from 'react';
import { Path, Svg } from 'react-native-svg';

const UploadSvg = () => {
  return (
    <Svg width="16" height="17" viewBox="0 0 16 17" fill="none">
    <Path d="M7.5 11.1667C7.5 11.4428 7.72386 11.6667 8 11.6667C8.27614 11.6667 8.5 11.4428 8.5 11.1667H7.5ZM8 2.5L8.36901 2.16262C8.27429 2.05901 8.14038 2 8 2C7.85962 2 7.72571 2.05901 7.63099 2.16262L8 2.5ZM10.2977 5.75405C10.484 5.95785 10.8003 5.97201 11.0041 5.78568C11.2079 5.59935 11.222 5.28308 11.0357 5.07928L10.2977 5.75405ZM4.96432 5.07928C4.77799 5.28308 4.79215 5.59935 4.99595 5.78568C5.19975 5.97201 5.51602 5.95785 5.70235 5.75405L4.96432 5.07928ZM10 14H6V15H10V14ZM6 14C5.04306 14 4.37565 13.9989 3.87208 13.9312C3.3829 13.8655 3.12385 13.7452 2.93934 13.5607L2.23223 14.2678C2.63351 14.669 3.13876 14.8416 3.73883 14.9223C4.32452 15.0011 5.07133 15 6 15V14ZM1.5 10.5C1.5 11.4287 1.49894 12.1755 1.57768 12.7612C1.65836 13.3612 1.83096 13.8665 2.23223 14.2678L2.93934 13.5607C2.75483 13.3762 2.63453 13.1171 2.56876 12.6279C2.50106 12.1244 2.5 11.4569 2.5 10.5H1.5ZM13.5 10.5C13.5 11.4569 13.4989 12.1244 13.4312 12.6279C13.3655 13.1171 13.2452 13.3762 13.0607 13.5607L13.7678 14.2678C14.169 13.8665 14.3416 13.3612 14.4223 12.7612C14.5011 12.1755 14.5 11.4287 14.5 10.5H13.5ZM10 15C10.9287 15 11.6755 15.0011 12.2612 14.9223C12.8612 14.8416 13.3665 14.669 13.7678 14.2678L13.0607 13.5607C12.8762 13.7452 12.6171 13.8655 12.1279 13.9312C11.6244 13.9989 10.9569 14 10 14V15ZM8.5 11.1667V2.5H7.5V11.1667H8.5ZM7.63099 2.83738L10.2977 5.75405L11.0357 5.07928L8.36901 2.16262L7.63099 2.83738ZM7.63099 2.16262L4.96432 5.07928L5.70235 5.75405L8.36901 2.83738L7.63099 2.16262Z" fill="#A7AFBE"/>
    </Svg>
  );
};

export default UploadSvg;
