export const APPOINTMENT_SEARCH_TYPE_CODES = {
    EVENT_ID: 'EVENT_ID',
    EVENT_NAME: 'EVENT_NAME',
    LOCATION: 'LOCATION',
    PRACTITIONER: 'PRACTITIONER',
    SERIES_ID: 'SERIES_ID'
  };

  export const APPOINTMENT_SEARCH_TYPE_VALUES = {
    EVENT_ID: 'Event Id',
    EVENT_NAME: 'Event Name',
    LOCATION: 'Location',
    PRACTITIONER: 'Practitioner',
    SERIES_ID: 'Series Id'
  };

  export const INITIAL_LIMIT = 10;