import React from 'react'
import { Pressable, Text } from 'native-base';
import { Colors } from '../../../../../../../styles';
import { Tooltip } from 'antd';

const CustomButton = (props: {
  title: string;
  leftIcon?: JSX.Element;
  isDisabled?: boolean;
  onPress: () => void;
  styles?: any;
  tooltipLabel?: string;
}) => {
  return (
    <Tooltip title={props?.tooltipLabel}>
      <Pressable
        flexDirection="row"
        alignItems="center"
        isDisabled={props.isDisabled}
        onPress={props.onPress}
        borderWidth={0.3}
        backgroundColor={Colors.Custom.Base50}
        borderColor={Colors.Custom.ActionBtnBorder}
        px={2}
        py={0.5}
        rounded="2xl"
        shadow={2}
        style={props?.styles}
      >
        {props.leftIcon}
        <Text
          fontWeight="bold"
          ml={1.5}
          fontSize={14}
          color={Colors.Custom.PurpleColor}
        >
          {props.title}
        </Text>
      </Pressable>
    </Tooltip>
  );
}

export default CustomButton
