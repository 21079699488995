import {IPersonContact} from "../../../Interfaces";
import {MASKED_PHONE_NUMBER} from "../../../constants";
import {numericStringMask} from "../../../utils/commonUtils";
import {INVITE_TYPE_VALUE} from "./MeetingViewConst";

export const showInvite = (args: {
  inviteTypeCode: string,
  emailObj: IPersonContact,
  phoneObj: IPersonContact,
  patientLoggedInTime: boolean
})=> {
  switch(args?.inviteTypeCode) {
    case INVITE_TYPE_VALUE.EMAIL:
      return {
        show: !!args?.emailObj?.value,
        value: args?.emailObj?.value,
      }
    case INVITE_TYPE_VALUE.SMS:
      const maskedPhoneNumber = numericStringMask(
        args?.phoneObj?.value,
        MASKED_PHONE_NUMBER
      );
      return {
        show: !!args?.phoneObj?.value,
        value: maskedPhoneNumber,
      }
    case INVITE_TYPE_VALUE.NOTIFICATION:
      return {
        show: !!args?.patientLoggedInTime,
        value: '',
      }
    default:
      return;
  }
}
