import React from 'react';
import { HStack, Icon, Text, VStack } from 'native-base';
import AntDesign from 'react-native-vector-icons/AntDesign';
import { Colors } from '../../../../styles/Colors';


const OutreachAutomationPanel = (props: {
  name: string;
}) => {

  return (
    <VStack
      style={{
        marginTop: 5,
        marginLeft: 5,
        marginRight: 5,
        backgroundColor: Colors.FoldPixel.GRAY50,
        padding: 10,
        flex: 1,
        borderRadius: 4,
        borderWidth: 1,
        borderColor: Colors.Custom.Gray200
      }}
    >
      <HStack space={1} flex={1}>
        <Icon
          as={AntDesign}
          name="fork"
          size="4"
          color={Colors.Custom.black25}
          alignSelf={'center'}
          justifySelf={'center'}
        />
        <Text fontWeight={600}>{props.name}</Text>
      </HStack>
    </VStack>
  );
};

export default OutreachAutomationPanel;