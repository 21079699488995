import { useMutation } from '@apollo/client';
import { Alert, Drawer } from 'antd';
import { Spinner, Text, useMediaQuery, View, VStack } from 'native-base';
import React, { useContext, useState } from 'react';
import { Dimensions } from 'react-native';
import { BUTTON_TYPE, IPAD_MINI_WIDTH, IPAD_WIDTH } from '../../../../../constants';
import { COMMON_ACTION_CODES } from '../../../../../constants/ActionConst';
import { Colors } from '../../../../../styles/Colors';
import { ContactAutoComplete } from '../../../../common/ContactRelationView';
import { ModalActionTitle } from '../../../../common/ModalActionTitle/ModalActionTitle';
import { styles } from './ContactSearchDrawerStyles';
import { CommonDataContext } from '../../../../../context/CommonDataContext';

const ContactSearchDrawer = (props: any) => {
  const [showModal, setShowModal] = useState(true);
  const [loading, setLoading] = useState(false);
  const closeModal = () => {
    setShowModal(false);
    props.onFormCompleteAction(COMMON_ACTION_CODES.CANCEL);
  };

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    { maxWidth: IPAD_WIDTH },
    { maxWidth: IPAD_MINI_WIDTH },
  ]);

  const { width } = Dimensions.get('window');
  const commonData = useContext(CommonDataContext);
  const isSideCarContext = commonData.sidecarContext?.isSidecar;

  const finalWidth = isSideCarContext
    ? '100%'
    : isIPadMiniScreen || isIPadScreen
    ? width / 2
    : width / 3;

  return (
    <>
      <Drawer
        visible={showModal}
        width={finalWidth}
        mask={isSideCarContext ? false : true}
        onClose={() => closeModal()}
        closable={false}
        title={<ModalActionTitle
          title={'Browse Contact'}
          titleColor={''}
          titleSize={'24'}
          buttonList={[
            {
              show: true,
              id: 1,
              btnText: isSideCarContext ? 'back' : 'cancel',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.SECONDARY,
              isTransBtn: false,
              onClick: () => {
                closeModal();
              },
            },
            {
              show: true,
              id: 2,
              btnText: 'send',
              textColor: Colors.Custom.mainPrimaryPurple,
              variant: BUTTON_TYPE.PRIMARY,
              isTransBtn: false,
              onClick: () => {
                props.onMsgSend();
              },
            },
          ]}
        />}
      >
        {loading ? <Spinner size="lg" style={styles.spinnerStyle} /> : <></>}
        <View ml={0}>
          <VStack flex={1} marginTop={1}>
            <ContactAutoComplete
              onFormCompleteAction={props.onFormCompleteAction}
              onAutoCompleteChange={(action: any, data: any) => {
                props.onAutoCompleteChange(
                  action,
                  data?.children?.props?.contactData?.uuid || ''
                );
              }}
            />
          </VStack>
        </View>
      </Drawer>
    </>
  );
};

export default ContactSearchDrawer;
