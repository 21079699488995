import {StyleSheet} from 'react-native';
import {Colors} from '../../../../styles';

export const styles = StyleSheet.create({
  mainContainer: {
    // margin: 10,
    overflow: 'hidden',
    flex: 1,
  },
  componentContainer: {
    overflow: 'scroll',
    flex: 1,
  },
});
