import {Spinner, View} from "native-base"
import {useEffect} from "react";
import {useIntl} from "react-intl";
import {useLocation} from "react-router-dom";
import {useToast} from "../../../components/Toast/ToastProvider";
import {getAccountDataByAccountId} from "../../../services/CommonService/AidBoxService";
import {isString, isValidObject} from "../../../utils/commonUtils";
import {showToast, ToastType} from "../../../utils/commonViewUtils";
import {EventBus} from "../../../utils/EventBus";
import LocalStorage from "../../../utils/LocalStorage";

const SwitchAccount = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token: any = queryParams.get('token');
  const accountId: any =  parseInt(queryParams.get('accountId') || '-1');
  const toast = useToast();
  const intl = useIntl()
  const getUserDetailsMap = (response: any) => {
    const userResponseMap = {
      accessToken: response.accessToken,
      client: response.client,
      data: response.data,
      userRoles: response.userRole || [],
      fold_access_token: response.fold_access_token,
      isMasterAccount: response.accountData?.masterAccountId ? false : true
    };
    return userResponseMap;
  };
  const handleLoginData = async (response: any) => {
    if (response.status == 200 && isValidObject(response.data)) {
      LocalStorage.setItem('isWorkflowLogin', 'true');
      const spreeResponseMap = {
        access_token: response.data.spreeAccessToken,
        token_type: 'Bearer',
        x_spree_token: response.data.spreeApiKey,
      };
      const associatedAccountsString = response?.data?.data?.associatedAccounts
      const userAccountsString = response?.data?.data?.userAccounts
      delete response.data.data.associatedAccounts
      delete response.data.data.userAccounts
      const userResponseMap = getUserDetailsMap(response.data);
      const userRoles = userResponseMap.userRoles || [];
      delete userResponseMap.userRoles;
      const userRoleString = JSON.stringify(userRoles);
      const resString = JSON.stringify(userResponseMap);
      const spreeResString = JSON.stringify(spreeResponseMap);
      LocalStorage.setItem('user', resString).then(() => {
        // window.location.hostname = newHostName;
      });
      await LocalStorage.setItem('userRoles', userRoleString);
      await LocalStorage.setItem('associatedAccounts', associatedAccountsString || "[]");
      await LocalStorage.setItem('userAccounts', userAccountsString || "[]");
      LocalStorage.setItem('commerceUserToken', spreeResString).then();
    } else {
      toast({
        message: intl.formatMessage({id: 'apiErrorMsg'}),
        toastType: ToastType.error,
      });
    }
  }
  const switchAccount =  async () => {
    const redirectString = '/#/home';
    if (!token) {
      toast({
        message: 'Invalid Token',
        toastType: ToastType.error,
      });
      window.location.href = window.location.origin + redirectString;
      return
    }
    await LocalStorage.removeItem('user');
    getAccountDataByAccountId(token).then(async (response) => {
      const data = await handleLoginData(response)
        window.location.href = window.location.origin
    }).catch((err) => {
      window.location.href = window.location.origin + redirectString;
      let errMessage = '';
      if (isString(err?.response?.data)) {
        errMessage = err.response.data
      } else if (err?.response?.data?.message) {
        errMessage = err?.response?.data?.message
      } else {
        errMessage = 'Something went wrong'
      }
      const toastTitle = errMessage ;
      toast({
        message: toastTitle,
        toastType: ToastType.error,
      });
    });
  };
  useEffect(() => {
    switchAccount()
  }, [])
  return (
    <View style={{height: '100vh', width: '100vw'}}>
      <Spinner size="lg" style={{
        margin: '0 auto',
        position: 'absolute',
        zIndex: 100011111,
        backgroundColor: '#0000000f',
        height: '100%',
        width: '100%',
        padding: 0
      }} />
    </View>
  )
}

export default SwitchAccount;
