import React from 'react';
import { Svg, Path } from 'react-native-svg';

const ServiceRequestIconSvg = () => {
  return (
    <Svg
      width="16"
      height="18"
      fill="none"
      viewBox="0 0 20 22"
    >
      <Path
        fill="#6F7A90"
        d="M18.503 20.558a.5.5 0 00.994-.116l-.994.116zm-.152-5.579l.496-.058-.497.058zM1.485 4.88a.5.5 0 10-.97.242l.97-.242zm-.103 1.649l.485-.121-.485.12zM7 12.5h8v-1H7v1zm7.5-.5v5h1v-5h-1zm-7 5v-5h-1v5h1zm10.354-1.962l.65 5.52.993-.116-.65-5.521-.993.117zM15 12.5a2.874 2.874 0 012.854 2.538l.993-.117A3.874 3.874 0 0015 11.5v1zm-4 8c-.957 0-1.624-.001-2.128-.069-.49-.066-.748-.186-.933-.37l-.707.707c.402.401.907.574 1.507.654.586.08 1.332.078 2.261.078v-1zM6.5 17c0 .929-.001 1.675.078 2.261.08.6.253 1.105.654 1.507l.707-.707c-.184-.185-.304-.444-.37-.933C7.5 18.624 7.5 17.957 7.5 17h-1zm8 0c0 .957-.001 1.624-.069 2.128-.065.49-.186.748-.37.933l.707.707c.401-.402.574-.907.654-1.507.08-.585.078-1.332.078-2.261h-1zM11 21.5c.929 0 1.675.001 2.261-.078.6-.08 1.106-.253 1.507-.654l-.707-.707c-.185.184-.444.305-.933.37-.504.068-1.171.069-2.128.069v1zM1.867 6.407l-.382-1.528-.97.242.382 1.528.97-.242zm5.241 5.105a6.959 6.959 0 01-5.241-5.105l-.97.242a7.959 7.959 0 005.995 5.84l.216-.977zM14.5 5A3.5 3.5 0 0111 8.5v1A4.5 4.5 0 0015.5 5h-1zM11 8.5A3.5 3.5 0 017.5 5h-1A4.5 4.5 0 0011 9.5v-1zM7.5 5A3.5 3.5 0 0111 1.5v-1A4.5 4.5 0 006.5 5h1zM11 1.5A3.5 3.5 0 0114.5 5h1A4.5 4.5 0 0011 .5v1z"
      ></Path>
    </Svg>
  );
};

export default ServiceRequestIconSvg;
