import React from 'react';
import { Svg, Path } from 'react-native-svg';

const AllergyIconSvg = () => {
  return (
    <Svg
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 22 22"
    >
      <Path
        fill="#6F7A90"
        d="M6.7 10.6a.5.5 0 00.6.8l-.6-.8zM9 9.5l.3.4a.5.5 0 000-.8l-.3.4zM7.3 7.6a.5.5 0 00-.6.8l.6-.8zm7.4 3.8a.5.5 0 00.6-.8l-.6.8zM13 9.5l-.3-.4a.5.5 0 000 .8l.3-.4zm2.3-1.1a.5.5 0 00-.6-.8l.6.8zm-.6 7a.5.5 0 00.6-.8l-.6.8zM13.667 14l.3-.4a.5.5 0 00-.6 0l.3.4zm-1.334 1l-.3.4a.5.5 0 00.6 0l-.3-.4zM11 14l.3-.4a.5.5 0 00-.6 0l.3.4zm-1.333 1l-.3.4a.5.5 0 00.6 0l-.3-.4zm-1.334-1l.3-.4a.5.5 0 00-.6 0l.3.4zm-1.633.6a.5.5 0 00.6.8l-.6-.8zM20.5 11a9.5 9.5 0 01-9.5 9.5v1c5.799 0 10.5-4.701 10.5-10.5h-1zM11 20.5A9.5 9.5 0 011.5 11h-1c0 5.799 4.701 10.5 10.5 10.5v-1zM1.5 11A9.5 9.5 0 0111 1.5v-1C5.201.5.5 5.201.5 11h1zM11 1.5a9.5 9.5 0 019.5 9.5h1C21.5 5.201 16.799.5 11 .5v1zm-3.7 9.9l2-1.5-.6-.8-2 1.5.6.8zm2-2.3l-2-1.5-.6.8 2 1.5.6-.8zm6 1.5l-2-1.5-.6.8 2 1.5.6-.8zm-2-.7l2-1.5-.6-.8-2 1.5.6.8zm2 4.7l-1.333-1-.6.8 1.333 1 .6-.8zm-1.933-1l-1.334 1 .6.8 1.334-1-.6-.8zm-.734 1l-1.333-1-.6.8 1.333 1 .6-.8zm-1.933-1l-1.333 1 .6.8 1.333-1-.6-.8zm-.733 1l-1.334-1-.6.8 1.334 1 .6-.8zm-1.934-1l-1.333 1 .6.8 1.333-1-.6-.8z"
      ></Path>
    </Svg>
  );
};

export default AllergyIconSvg;
