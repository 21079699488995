import {
  VStack,
  Text,
  HStack,
  Button,
  useToast,
  FormControl,
  Input,
  Divider,
  ScrollView,
  Icon,
  Box,
} from 'native-base';
import {useCallback, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Colors} from '../../../../../styles/Colors';
import {FIELD_OPERATION, getComponentId, getRiskStateId, getSectionWiseRiskField, SINGLE_GROUP_FIELD_ID, sortRiskScoreFields} from './RiskScoreHelper';

import {
  IGroupField,
  IRiskScoreData,
  IRiskScoreState,
  ISectionRiskScoreProps,
  ISectionRiskScoreState,
  ISingleField,
} from './RiskScoreInterface';
import RiskScoreFieldView from './RiskScoreFieldView';
import FieldScoreView from './FieldScoreView';
import {showToast, ToastType} from '../../../../../utils/commonViewUtils';
import { cloneDeep, isNaN } from 'lodash';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { FoldButton } from '../../../../CommonComponents/FoldButton/FoldButton';
import { BUTTON_TYPE } from '../../../../../constants';
import Feather from 'react-native-vector-icons/Feather';
import  EmptyScoreIcon from './EmptyScore';
import { styles } from '../../Analytics/style';
import { FlatList } from 'react-native';

export const GENERAL_RISK_SCORE_GROUP_NAME = 'Form score';
export const GENERAL_RISK_SCORE_GROUP_ID = 'General';

export const SectionWiseRiskScoreView = (props: ISectionRiskScoreProps) => {
  const intl = useIntl();
  const toast = useToast();
  const { formMetadata, components, questionnaireList } = props;

  const RESULT_FIELD_OPERATION = {
    UPDATE_FROM_FIELD: 'from',
    UPDATE_TO_FIELD: 'to',
    UPDATE_NAME: 'name',
    DELETE_FIELD: 'delete',
    ADD_FIELD: 'add'
  };

  const [sectionWiseField, setSectionWiseField] = useState<{
    groupFields: IGroupField[];
    singleFields: ISingleField[];
    selectedGroupFields: IGroupField[];
    selectedSingleFields: ISingleField[];
    resultFields: ISectionRiskScoreState[];
  }>({
    groupFields: [],
    singleFields: [],
    selectedGroupFields: [],
    selectedSingleFields: [],
    resultFields: [],
  });

  const [invalidRecordMap, setInvalidRecordMap] = useState(new Map());

  const addResultGroupField = (selectedGroups: IGroupField[], selectedGroup: IGroupField) => {
    setSectionWiseField((sectionWiseField) => {
      const resultFields: ISectionRiskScoreState[] = sectionWiseField.resultFields || [];
      if (selectedGroup?.isAdded && selectedGroup?.fields?.length) {
          const resultField: ISectionRiskScoreState = {} as ISectionRiskScoreState;
          resultField.name = selectedGroup?.groupFieldName || '';
          resultField.riskScoreState = [
            { to: 0, from: 0, state: 'Low', index: getRiskStateId()},
            { to: 0, from: 0, state: 'High', index: getRiskStateId()}
          ];
          resultField.groupId = selectedGroup?.groupId;
          resultField.questionnaireId = selectedGroup?.questionnaireId;
          resultField.sequenceNumber = selectedGroup.sequenceNumber;
          resultFields.push(resultField);
      }
      return {
        ...sectionWiseField,
        resultFields: sortRiskScoreFields([ ...resultFields ]),
      };
    });
  };


  const onAddGroupHandler = (groupField?: IGroupField | undefined) => {
    if (!groupField) {
      return;
    }

    if (groupField.isAdded) {
      showToast(
        toast,
        `There is already a score group created for section ${groupField.groupFieldName || 'group'}.`,
        ToastType.info,
      );
      return;
    }

    setSectionWiseField((sectionWiseField) => {
      const selectedGroups: IGroupField[] = sectionWiseField.selectedGroupFields || [];
      (sectionWiseField.groupFields || []).forEach(field => {
        if (field?.groupId === groupField?.groupId) {
          field.isAdded = true;
          (field.fields || []).forEach(singleField => {
            singleField.isAddedAsGroupField = true;
          });

          const selectedGroupField = cloneDeep(field);
          selectedGroups.push(selectedGroupField);
          addResultGroupField(selectedGroups, selectedGroupField);
        }
      });

      return {
        ...sectionWiseField,
        selectedGroupFields: sortRiskScoreFields([ ...selectedGroups ]),
      };
    });
  };

  const findFromSelectedField = (
    singleField: ISingleField,
    groupField?: IGroupField | undefined,
    inputGroupFields?: Array<any>,
  ) => {
    if (groupField?.groupId) {
      const groupFields: IGroupField[] =
        inputGroupFields || [];
      const selectedFieldGroup: IGroupField =
        groupFields.find(field => {
          return groupField?.groupId === field?.groupId;
        }) || ({} as IGroupField);

      let findGroupSingleField = {} as ISingleField;
      if (selectedFieldGroup?.groupId && selectedFieldGroup?.fields?.length) {
        findGroupSingleField =
          (selectedFieldGroup?.fields || []).find(field => {
            return field?.fieldId === singleField?.fieldId;
          }) || ({} as ISingleField);
      }
      return {
        groupField: selectedFieldGroup,
        singleField: findGroupSingleField,
      };
    }

    const findSingleField =
      (sectionWiseField.selectedSingleFields || []).find(field => {
        return field?.fieldId === singleField?.fieldId;
      }) || ({} as ISingleField);
    return {groupField: undefined, singleField: findSingleField};
  };

  const findFromRiskScoreField = (
    singleField: ISingleField,
    groupField?: IGroupField | undefined,
  ) => {
    if (groupField?.groupId) {
      const groupFields: IGroupField[] = sectionWiseField.groupFields || [];
      const selectedFieldGroup: IGroupField =
        groupFields.find(field => {
          return groupField?.groupId === field?.groupId;
        }) || ({} as IGroupField);

      let findGroupSingleField = {} as ISingleField;
      if (selectedFieldGroup?.groupId && selectedFieldGroup?.fields?.length) {
        findGroupSingleField =
          (selectedFieldGroup?.fields || []).find(field => {
            return field?.fieldId === singleField?.fieldId;
          }) || ({} as ISingleField);
      }
      return {
        groupField: selectedFieldGroup,
        singleField: findGroupSingleField,
      };
    }

    const findSingleField =
      (sectionWiseField.singleFields || []).find(field => {
        return field?.fieldId === singleField?.fieldId;
      }) || ({} as ISingleField);
    return {groupField: undefined, singleField: findSingleField};
  };

  const getGroupFieldById = (groupFields: IGroupField[], groupId?: string): IGroupField => {
    return (groupFields || []).find(field => {
      return groupId === field?.groupId;
    }) || {} as IGroupField;
  }

  const getSingleFieldFromGroup = (groupField?: IGroupField, fieldId?: string) => {
    if (!fieldId || !groupField?.fields?.length) {
      return;
    }
    return (groupField.fields || []).find(field => {
      return field.fieldId === fieldId;
    });
  }

  const isFieldExistInScoreGroup = (selectedSingleField: ISingleField, selectedGroupField: IGroupField) => {
    return (selectedGroupField.fields || []).some(field => {
      return field.fieldId === selectedSingleField?.fieldId
    });
  }

  const onAddGroupSectionFieldHandler = (
    selectedSingleField: ISingleField,
    inputGroupField?: IGroupField | undefined,
  ) => {
    if (!selectedSingleField) {
      return;
    }

    const selectedGroupField = getGroupFieldById(sectionWiseField.selectedGroupFields, inputGroupField?.groupId);
    const groupField = getGroupFieldById(sectionWiseField.groupFields, inputGroupField?.groupId);

    if (selectedGroupField?.groupId && selectedGroupField.isAdded && !isFieldExistInScoreGroup(selectedSingleField, selectedGroupField)) {
      setSectionWiseField((prev) => {
        const singleField = getSingleFieldFromGroup(groupField, selectedSingleField.fieldId);
        if (singleField) {
          singleField.isAddedAsGroupField = true;
          selectedGroupField.fields.push(singleField);
        }
        return {
          ...prev,
          selectedGroupFields: sortRiskScoreFields([ ...sectionWiseField.selectedGroupFields ]),
        };
      });
    } else {
      onAddSingleFieldHandler(selectedSingleField);
    }
  };

  const updateRiskScoreValue = (
    selectedFields: any,
    optionValue: string,
    option: any,
    isGroupScore: boolean,
  ) => {
    (selectedFields?.singleField?.optionList || []).some(
      (selectedOption: any) => {
        if (selectedOption?.label === option?.label) {
          const riskScore = optionValue ? parseInt(optionValue) : 0;
          if (isGroupScore) {
            selectedOption.groupRiskScore = riskScore;
          } else {
            selectedOption.riskScore = riskScore;
          }
          return true;
        }
      },
    );
  };

  const onRiskScoreOptionValueChange = (
    singleField: ISingleField,
    optionValue: string,
    option: any,
    selectedGroupField?: IGroupField | undefined,
  ) => {
    const selectedFields = findFromSelectedField(singleField, selectedGroupField, sectionWiseField.selectedGroupFields);
    const groupFields = findFromSelectedField(singleField, selectedGroupField, sectionWiseField.groupFields);

    if (
      selectedFields?.groupField?.groupId &&
      selectedFields?.singleField?.fieldId
    ) {
      updateRiskScoreValue(selectedFields, optionValue, option, true);
      updateRiskScoreValue(groupFields, optionValue, option, true);
      setSectionWiseField(prev => {
        return {
          ...prev,
          selectedGroupFields: [ ...sectionWiseField.selectedGroupFields ],
        };
      });
    } else if (selectedFields?.singleField?.fieldId) {
      updateRiskScoreValue(selectedFields, optionValue, option, false);
      updateRiskScoreValue(groupFields, optionValue, option, false);
      setSectionWiseField(prev => {
        return {
          ...prev,
          selectedSingleFields: sortRiskScoreFields([ ...sectionWiseField.selectedSingleFields ]),
        };
      });
    }
  };

  const onResultFieldValueChange = (
    riskScoreSection: ISectionRiskScoreState,
    changeValue: string,
    operationType: string,
    selectedRiskScoreState?: IRiskScoreState,
  ) => {
    const resultFields = sectionWiseField.resultFields || [];
    const findField = (resultFields || []).find(field => {
      return field?.groupId === riskScoreSection?.groupId;
    });
    let findRiskScoreState: IRiskScoreState = {} as IRiskScoreState;
    if (findField?.groupId) {
      findRiskScoreState = (findField.riskScoreState || []).find(resultField => {
        return resultField.index === selectedRiskScoreState?.index;
      }) || {} as IRiskScoreState;
    }

    switch(operationType) {
      case RESULT_FIELD_OPERATION.UPDATE_FROM_FIELD:
        findRiskScoreState.from =  (changeValue && !isNaN(changeValue)) ? parseInt(changeValue) : 0;
        break;
      case RESULT_FIELD_OPERATION.UPDATE_TO_FIELD:
        findRiskScoreState.to =  (changeValue && !isNaN(changeValue)) ? parseInt(changeValue) : 0;
        break;
      case RESULT_FIELD_OPERATION.UPDATE_NAME:
        findRiskScoreState.state =  changeValue;
        break;
      case RESULT_FIELD_OPERATION.DELETE_FIELD:
        if (findField?.name) {
          const filteredResultScore = (findField?.riskScoreState || []).filter(resultField => {
            return resultField.index !== selectedRiskScoreState?.index;
          });
          findField['riskScoreState'] = filteredResultScore;
        }
        break;
      case RESULT_FIELD_OPERATION.ADD_FIELD:
        const scoreState = { to: 0, from: 0, state: '', index: getRiskStateId()};
        findField?.riskScoreState.push(scoreState);
        break;
    }

    setSectionWiseField(prev => {
      return {
        ...prev,
        resultFields: sortRiskScoreFields([ ...resultFields ]),
      };
    });
  };

  const createNewGeneralResultGroup = () => {
      const isGeneralGroupExist = (sectionWiseField.resultFields || []).some(resultField => {
        return resultField?.groupId === GENERAL_RISK_SCORE_GROUP_ID;
      });
      if (!isGeneralGroupExist) {
        const resultField: ISectionRiskScoreState = {} as ISectionRiskScoreState;
        resultField.name = GENERAL_RISK_SCORE_GROUP_NAME;
        resultField.riskScoreState = [
          { to: 0, from: 0, state: 'Low', index: getRiskStateId()},
          { to: 0, from: 0, state: 'High', index: getRiskStateId()}
        ];
        resultField.groupId = GENERAL_RISK_SCORE_GROUP_ID;
        resultField.sequenceNumber = -1;
        setSectionWiseField(prev => {
          const resultFields = prev.resultFields || [];
          resultFields.push(resultField);


          return {
            ...prev,
            resultFields: sortRiskScoreFields([ ...resultFields ]),
          };
        });
      }
  };

  const onAddSingleFieldHandler = (singleField: ISingleField) => {
    if (singleField.isAddedAsSingleField) {
      showToast(
        toast,
        `${singleField.fieldName} field already added in general section.`,
        ToastType.info,
      );
      return;
    }
    singleField.isAddedAsSingleField = true;
    const singleFields: ISingleField[] = sectionWiseField.selectedSingleFields || [];
    const isAlreadyAdded = (singleFields || []).some(field => {
      return field.fieldId === singleField.fieldId;
    });
    if (!isAlreadyAdded) {
      singleFields.push(singleField || ({} as ISingleField));
    }
    createNewGeneralResultGroup();
    setSectionWiseField((prev) => {
      return {
        ...prev,
        selectedSingleFields: sortRiskScoreFields([ ...singleFields ]),
      };
    });
  };

  const updateSingleFieldStatus = (singleField: ISingleField, singleFields: ISingleField[], isAddedAsSingleField: boolean) => {
    (singleFields || []).forEach(selectedSingleField => {
      if (selectedSingleField?.fieldId === singleField?.fieldId) {
        selectedSingleField.isAddedAsSingleField = isAddedAsSingleField;
      }
    });
  }

  const onRemoveSingleFieldHandler = (singleField: ISingleField) => {
    const filteredSingleFields: ISingleField[] = sectionWiseField.singleFields || [];
    const filteredGroupFields: IGroupField[] = sectionWiseField.groupFields || [];
    let filteredSelectedSingleFields: ISingleField[] = sectionWiseField.selectedSingleFields || [];
    let filteredResultScoreGroups: ISectionRiskScoreState[] = sectionWiseField.resultFields || [];

    // Update single field and section wise field status
    if (singleField?.isGroupField && singleField?.groupId) {
      const groupField = filteredGroupFields.find(field => {
        return singleField?.groupId === field?.groupId;
      }) || {} as IGroupField;
      updateSingleFieldStatus(singleField, groupField.fields, false);
    } else {
      updateSingleFieldStatus(singleField, filteredSingleFields, false);
    }

    // update score field group status
    filteredSelectedSingleFields = (sectionWiseField.selectedSingleFields || []).filter(field => {
      return field?.fieldId !== singleField?.fieldId;
    });

    // update general result group status
    if (!filteredSelectedSingleFields?.length) {
      filteredResultScoreGroups = (sectionWiseField.resultFields).filter(resultField => {
        return (!resultField?.groupId || resultField?.groupId !== GENERAL_RISK_SCORE_GROUP_ID);
      });
    }

    setSectionWiseField(prev => {
      return {
        ...prev,
        groupFields: sortRiskScoreFields([ ...filteredGroupFields ]),
        singleFields: sortRiskScoreFields([ ...filteredSingleFields ]),
        selectedSingleFields: sortRiskScoreFields([ ...filteredSelectedSingleFields ]),
        resultFields: sortRiskScoreFields([ ...filteredResultScoreGroups ]),
      };
    });
  };

  const onRemoveGroupSingleFieldRemoveHandler = (
    selectedSingleField: ISingleField,
    inputGroupField: IGroupField,
  ) => {
    const filteredGroupFields: IGroupField[] = sectionWiseField.groupFields || [];
    const filteredScoreGroupFields: IGroupField[] = [];
    const nonFieldSectionGroupIds: string[] = [];
    let filteredResultScoreGroups: ISectionRiskScoreState[] = sectionWiseField.resultFields || [];

    // update risk score field status
    const selectedGroupField = filteredGroupFields.find(field => {
      return selectedSingleField?.groupId === field?.groupId;
    }) || {} as IGroupField;
    (selectedGroupField.fields || []).forEach(singleField => {
      if (singleField?.fieldId === selectedSingleField?.fieldId) {
        singleField.isAddedAsGroupField = false;
      }
    });
    const groupFieldList = (selectedGroupField.fields || []).filter(field => {
      return field.isAddedAsGroupField;
    });
    if (!groupFieldList?.length) {
      selectedGroupField.isAdded = false;
    }

    // update score group field
    (sectionWiseField.selectedGroupFields || []).forEach(field => {
      let filterFields: ISingleField[] = field?.fields || [];
      if (field?.groupId === inputGroupField?.groupId) {
        filterFields = (field?.fields || []).filter(singleField => {
          return singleField?.fieldId !== selectedSingleField?.fieldId;
        });
        field.fields = filterFields;
      }
      if (filterFields?.length) {
        filteredScoreGroupFields.push(field);
      } else {
        nonFieldSectionGroupIds.push(field.groupId);
      }
    });

    // handle result field
    if (nonFieldSectionGroupIds?.length) {
      filteredResultScoreGroups = (sectionWiseField.resultFields || []).filter(resultField => {
        return !nonFieldSectionGroupIds.includes(resultField.groupId || '');
      })
    }

    setSectionWiseField(prev => {
      return {
        ...prev,
        groupFields: sortRiskScoreFields([ ...filteredGroupFields ]),
        selectedGroupFields: sortRiskScoreFields([ ...filteredScoreGroupFields ]),
        resultFields: sortRiskScoreFields([ ...filteredResultScoreGroups ]),
      };
    });
  };

  const onGroupRemoveHandler = (groupField: IGroupField) => {
    setSectionWiseField((sectionWiseField) => {
      const selectedGroupFields: IGroupField[] = (sectionWiseField.selectedGroupFields || []).filter(field => {
        return field?.groupId !== groupField?.groupId;
      });
      const groupResultFields = (sectionWiseField.resultFields || []).filter(group => {
        return groupField?.groupId !== group?.groupId;
      });

      (sectionWiseField.groupFields || []).forEach(field => {
        if (field?.groupId === groupField?.groupId) {
          field.isAdded = false;

          (field.fields || []).forEach(singleField => {
            singleField.isAddedAsGroupField = true;
          });
        }
      });


      return {
        ...sectionWiseField,
        groupFields: sortRiskScoreFields([ ...sectionWiseField.groupFields ]),
        selectedGroupFields: sortRiskScoreFields([ ...selectedGroupFields ]),
        resultFields: sortRiskScoreFields([ ...groupResultFields ]),
      };
    });
  };

  const onRiskScoreFieldChangeHandler = (
    singleField: ISingleField | undefined,
    operationKey: string,
    groupField?: IGroupField | undefined,
  ) => {
    switch (operationKey) {
      case FIELD_OPERATION.GROUP_ADDED:
        onAddGroupHandler(groupField);
        break;
      case FIELD_OPERATION.GROUP_FIELD_ADD:
        if (singleField) {
          onAddGroupSectionFieldHandler(singleField, groupField);
        }
        break;
      case FIELD_OPERATION.SINGLE_FIELD_ADD:
        if (singleField?.fieldId) {
          onAddSingleFieldHandler(singleField);
        }
        break;
      case FIELD_OPERATION.SINGLE_FIELD_REMOVE:
        if (singleField?.fieldId) {
          onRemoveSingleFieldHandler(singleField);
        }
        break;
      case FIELD_OPERATION.GROUP_REMOVED:
        if (groupField && groupField?.groupId) {
          onGroupRemoveHandler(groupField);
        }
        break;
      case FIELD_OPERATION.GROUP_FIELD_REMOVE:
        if (singleField?.fieldId && groupField?.groupId) {
          onRemoveGroupSingleFieldRemoveHandler(singleField, groupField);
        }
        break;
    }
  };

  function createMetadata(
    selectedGroupFields: IGroupField[],
    selectedSingleFields: ISingleField[],
    resultFields: ISectionRiskScoreState[],
  ) {
    const metadata: IRiskScoreData[] = [];

    if (!resultFields?.length) {
      return metadata;
    }

    for (const resultField of resultFields) {
      if (!resultField) {
        continue;
      }
      const riskScoreData: IRiskScoreData = {
        groupId: resultField.groupId,
        questionnaireId: resultField?.questionnaireId,
        fields: [],
        name: resultField.name,
        operation: resultField.operation || 'SUM',
        states: (resultField.riskScoreState || [])?.map?.((riskScoreState) => {
          return {
            state: riskScoreState?.state,
            from: riskScoreState?.from,
            to: riskScoreState?.to,
          }
        }),
        sequenceNumber: resultField.sequenceNumber,
        type: 'RISK_SCORE',
      };

      const groupId = resultField.groupId;
      const isGeneralGroup = (!groupId || groupId === GENERAL_RISK_SCORE_GROUP_ID);

      const singleFields = isGeneralGroup ? selectedSingleFields : getGroupFieldById(selectedGroupFields, resultField.groupId)?.fields;

      if (singleFields?.length) {
        riskScoreData.fields = singleFields?.map?.((singleField) => {
          return {
            componentId: getComponentId(singleField?.component),
          };
        });

        metadata.push(riskScoreData);
      }
    }

    return metadata;
  }

  function triggerOnChange(
    selectedGroupFields: IGroupField[],
    selectedSingleFields: ISingleField[],
    resultFields: ISectionRiskScoreState[],
  ) {
    const metadata = createMetadata(
      selectedGroupFields,
      selectedSingleFields,
      resultFields,
    );

    props.onChange?.({
      id: props?.formMetadata?.id,
      metadata: metadata,
    });
  }

  const removeFromInvalidRecordMap = (id: string) => {
    const newInvalidRecordMap = new Map(invalidRecordMap);
    newInvalidRecordMap.delete(id);
    setInvalidRecordMap(newInvalidRecordMap);
  };

  const handleSetInvalidRecordMap = (newMap: Map<string, boolean>) => {
    setInvalidRecordMap(newMap);
  };

  useEffect(() => {
    triggerOnChange(
      sectionWiseField.selectedGroupFields,
      sectionWiseField.selectedSingleFields,
      sectionWiseField.resultFields,
    );
  }, [
    sectionWiseField.selectedGroupFields,
    sectionWiseField.selectedSingleFields,
    sectionWiseField.resultFields,
  ]);

  useEffect(() => {
    const {
      groupFields,
      singleFields,
      selectedGroupFields,
      selectedSingleFields,
      resultFields,
    } = getSectionWiseRiskField(formMetadata, components || [], [], [], questionnaireList, handleSetInvalidRecordMap);

    setSectionWiseField(prev => {
      return {
        ...prev,
        groupFields: sortRiskScoreFields([ ...groupFields ]),
        singleFields: sortRiskScoreFields([ ...singleFields ]),
        selectedGroupFields: sortRiskScoreFields([ ...selectedGroupFields ]),
        selectedSingleFields: sortRiskScoreFields([ ...selectedSingleFields ]),
        resultFields: sortRiskScoreFields([ ...resultFields ]),
      };
    });
  }, []);

  const onFieldScoreChangeHandler = (
    singleField: ISingleField | undefined,
    operationKey: string,
    groupField?: IGroupField | undefined,
  ) => {
    onRiskScoreFieldChangeHandler(
      singleField,
      operationKey,
      groupField,
    );
  };

  const onActionPerformHandler = (
    singleField: ISingleField,
    optionValue: string,
    option: any,
    groupField?: IGroupField | undefined,
  ) => {
    onRiskScoreOptionValueChange(
      singleField,
      optionValue,
      option,
      groupField,
    );
  };

  const renderRiskScoreItem = (riskScore: IRiskScoreState, resultField: ISectionRiskScoreState) => {
    return (
      <HStack key={`${resultField.groupId}_${resultField.name}_${riskScore.index}`} space={2}  marginY={1} marginLeft={1} paddingLeft={1} flex={1}>
        <HStack flex={0.49} space={1}>
            <FormControl width={'49%'}>
              <Input
                placeholder={intl.formatMessage({id: 'from'})}
                value={riskScore.from?.toString()}
                onChangeText={(text: string) => {
                  onResultFieldValueChange(resultField, text, RESULT_FIELD_OPERATION.UPDATE_FROM_FIELD, riskScore);
                }}
              />
            </FormControl>
            <FormControl width={'49%'}>
              <Input
                placeholder={intl.formatMessage({id: 'to'})}
                value={riskScore.to?.toString()}
                onChangeText={(text: string) => {
                  onResultFieldValueChange(resultField, text, RESULT_FIELD_OPERATION.UPDATE_TO_FIELD, riskScore);
                }}
              />
            </FormControl>
        </HStack>
        <HStack flex={0.49}>
          <FormControl width={'85%'} isInvalid={invalidRecordMap.get(riskScore.index) && riskScore.isInvalid}>
              <Input
                width={'95%'}
                placeholder={intl.formatMessage({id: 'name'})}
                value={riskScore.state}
                onChangeText={(text: string) => {
                  onResultFieldValueChange(resultField, text, RESULT_FIELD_OPERATION.UPDATE_NAME, riskScore);
                  removeFromInvalidRecordMap(riskScore.index || '');
                }}
              />
          </FormControl>
          {resultField?.riskScoreState?.length > 1 && (
            <Button
              marginTop={1}
              style={styles.box24}
              backgroundColor={'##FDFAFF'}
              onPress={() => {
                onResultFieldValueChange(resultField, '', RESULT_FIELD_OPERATION.DELETE_FIELD, riskScore);
              }}>
            <Feather
              name="trash-2"
              size={20}
              color={Colors.Custom.Gray500}
              />
            </Button>
          )}
        </HStack>
      </HStack>
    );
  }

  return (
    <HStack flex={1} space={1}>
      <HStack
        flex={1}
        >
        <VStack
          flex={0.3}
          borderRightWidth={'1'}
          borderColor={Colors.Custom.Gray200}>
          <HStack padding={4}>
            <Text fontSize={16} fontWeight={700} fontStyle={'normal'} paddingLeft={1}>
              {intl.formatMessage({id: 'riskScoreFields'})}
            </Text>
          </HStack>
          <Divider/>

          <RiskScoreFieldView
            groupFields={sectionWiseField.groupFields}
            singleFields={sectionWiseField.singleFields}
            onChange={onRiskScoreFieldChangeHandler}
          />
        </VStack>
        <VStack
          flex={0.4}
          borderRightWidth={'1'}
          borderColor={Colors.Custom.Gray200}>
          <HStack padding={4}>
            <Text fontSize={16} fontWeight={700} fontStyle={'normal'} paddingLeft={1}>
              {intl.formatMessage({id: 'addScore'})}
            </Text>
          </HStack>
          <Divider/>

          <FieldScoreView
            groupFields={sectionWiseField.selectedGroupFields}
            singleFields={sectionWiseField.selectedSingleFields}
            onChange={onFieldScoreChangeHandler}
            onActonPerform={onActionPerformHandler}
          />
        </VStack>
        <VStack
          flex={0.3}
          borderColor={Colors.Custom.Gray200}>
          <HStack padding={4}>
            <Text fontSize={16} fontWeight={700} fontStyle={'normal'} paddingLeft={1}>
              {intl.formatMessage({id: 'result'})}
            </Text>
          </HStack>
          <Divider/>
          <VStack space={1} padding={2}>
            <HStack flex={1} space={1}>
              <Text fontWeight="bold" justifyContent={'flex-start'} flex={0.49} paddingLeft={3}>
                {intl.formatMessage({id: 'scoreInterpretations'})}
              </Text>
              <HStack flex={0.49} justifyContent={'flex-end'}>
                <Button
                  style={styles.width78height24}
                  backgroundColor={'#F9F5FF'}
                  borderRadius={16}
                  borderWidth={1}
                  borderColor={'#C2A6F3'}>
                  <Text color={'#9E77ED'}> {`fx = 'SUM'`} </Text>
                </Button>
              </HStack>
            </HStack>
          </VStack>
            {sectionWiseField?.resultFields?.length === 0 ? <Divider marginBottom={6}></Divider> : <Divider></Divider>}
            {sectionWiseField?.resultFields?.length === 0 && (
              <VStack>
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                  <EmptyScoreIcon/>
                  <Text marginTop={4} fontWeight={800} fontSize={'18'} width="192" height="30">Score Interpretations</Text>
                  <Text textAlign="center" fontWeight={400} fontSize={'14'} width= "210px" height= "40px" color={Colors.Custom.Gray500}>You can define the scores here after adding the score fields from the left.</Text>
                </Box>
              </VStack>
            )}
            <ScrollView>
              {sectionWiseField?.resultFields?.length > 0 && (
                (sectionWiseField.resultFields || []).map(resultField => {
                  return (
                    <VStack
                    key={resultField.groupId + resultField.name + resultField.sequenceNumber}
                    margin={2}
                    paddingBottom={2}
                    flexDirection={"column"}
                    background={Colors.Custom.ContainerBGColor}
                    alignSelf={"stretch"}
                    borderWidth={1}
                    borderColor={Colors.Custom.Gray200}
                    borderRadius={8}>
                      <Text fontSize={14} fontWeight={600} paddingTop={2} paddingBottom={2} marginLeft={2} paddingLeft={1}>
                        {resultField?.name}
                      </Text>
                      <Divider marginBottom={2} />
                      {resultField?.riskScoreState?.length > 0 && (
                        <FlatList
                          data={resultField.riskScoreState || []}
                          renderItem={({ item }) => renderRiskScoreItem(item, resultField)}
                          keyExtractor={(item: any): string => `${resultField.groupId}_${resultField.name}_${item.index}`}
                        />
                      )}
                      <HStack marginTop={2} marginLeft={2} paddingLeft={1} space={2}>
                        <FoldButton nativeProps={{
                          variant: BUTTON_TYPE.PRIMARY,
                          onPress: () => {
                            onResultFieldValueChange(resultField, '', RESULT_FIELD_OPERATION.ADD_FIELD);
                          },
                          leftIcon: (
                            <Icon as={AntIcon} name="plus" size="4" />
                            ),
                          }} customProps={{
                            btnText: intl.formatMessage({id: 'addScoreState'})
                            }}></FoldButton>
                       </HStack>
                    </VStack>
                  )
                })
              )}
            </ScrollView>
        </VStack>
      </HStack>
    </HStack>
  );
}
