import React, {useContext, useMemo} from 'react';
import {
  getMlovIdFromCode,
  getMlovListFromCategory,
} from '../../../utils/mlovUtils';
import {MLOV_CATEGORY} from '../../../constants';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {
  OUT_REACH_OUTCOME_CATEGORIES,
  OUT_REACH_OUTCOME_CATEGORY_CODES,
} from '../../../constants/MlovConst';

function useOutreachStepOutComes() {
  const context = useContext(CommonDataContext);

  const outreachStepOutComes = useMemo(
    () =>
      getMlovListFromCategory(
        context.CARE_STUDIO_MLOV,
        MLOV_CATEGORY.OUT_REACH_STEP_OUTCOMES
      ),
    []
  );

  const getCategoryList = (code: string) => {
    const codeList = OUT_REACH_OUTCOME_CATEGORIES[code];
    return codeList.map((code) => {
      const mlovId = getMlovIdFromCode(outreachStepOutComes, code);
      const mlovObj = outreachStepOutComes.find((mlov) => mlov.id === mlovId);

      return {
        key: mlovObj?.id || '',
        value: mlovObj?.id || '',
        label: mlovObj?.value || '',
      };
    });
  };

  const categoryWiseList = useMemo(
    () => ({
      [OUT_REACH_OUTCOME_CATEGORY_CODES.OUTREACH_COMPLETED]: getCategoryList(
        OUT_REACH_OUTCOME_CATEGORY_CODES.OUTREACH_COMPLETED
      ),
      [OUT_REACH_OUTCOME_CATEGORY_CODES.FOLLOW_UP_NEEDED]: getCategoryList(
        OUT_REACH_OUTCOME_CATEGORY_CODES.FOLLOW_UP_NEEDED
      ),
      [OUT_REACH_OUTCOME_CATEGORY_CODES.REMOVE_PATIENT]: getCategoryList(
        OUT_REACH_OUTCOME_CATEGORY_CODES.REMOVE_PATIENT
      ),
    }),
    [outreachStepOutComes.length]
  );

  return {categoryWiseList, outreachStepOutComes};
}

export default useOutreachStepOutComes;
