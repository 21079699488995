import {useLazyQuery} from '@apollo/client';
import React, {useEffect, useState} from 'react';
import ConversationsQueries from '../../../../services/Conversations/ConversationsQueries';
import {getAccountId} from '../../../../utils/commonUtils';
import {conversationAttachments} from './AttachmentService';
import {ConversationAttachmentView} from './ConversationAttachmentsView';
import {
  IConversationAttachmentsData,
  IConversationAttachmentsDrawer,
} from './interfaces';

export const ConversationAttachments = (
  props: IConversationAttachmentsDrawer
) => {
  const {
    conversationId,
    conversationDisplayId,
    onConversationAttachmentActionPerformed,
    isDrawerVisible,
    navigation,
    openInDrawer,
  } = props;
  const accountId = getAccountId();
  const [GetAttachmentsIds] = useLazyQuery(
    ConversationsQueries.GetAttachmentsIds,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
      variables: {
        id: conversationId,
      },
    }
  );
  const [stateData, setStateData] = useState({
    messageAttachments: [] as IConversationAttachmentsData[],
    loading: false,
  });

  const getConversationAttachments = async () => {
    const attachmentsIds = await GetAttachmentsIds();
    const attachments = attachmentsIds?.data?.attachments || [];
    const attachmentMessageId: number[] = attachments.map((attachment: any) => {
      return attachment?.messageId;
    });
    const params = {
      pathParams: {
        accountId: accountId,
        conversationDisplayId: conversationDisplayId,
      },
      bodyParams: {
        attachmentMessageId: attachmentMessageId,
      },
    };

    const response = await conversationAttachments(params).catch((err) => {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    });
    if (response?.data?.length) {
      setStateData((prev) => {
        return {
          ...prev,
          messageAttachments: response?.data.reverse(),
          loading: false,
        };
      });
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    }
  };

  useEffect(() => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    getConversationAttachments();
  }, []);

  return (
    <>
      <ConversationAttachmentView
        isDrawerVisible={isDrawerVisible}
        onConversationAttachmentActionPerformed={
          onConversationAttachmentActionPerformed
        }
        loading={stateData?.loading}
        messageAttachments={stateData?.messageAttachments}
        navigation={navigation}
        openInDrawer={openInDrawer}
      />
    </>
  );
};
