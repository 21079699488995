import { Divider, HStack, Pressable, Text, VStack } from 'native-base';
import React from 'react';
import { DisplayText } from '../../DisplayText/DisplayText';
import { styles } from './NotificationListHeaderStyles';

export interface INotificationListHeaderProps {
  onMarkAllAsRead: () => void;
}

const NotificationListHeader = (props: INotificationListHeaderProps) => {
  const { onMarkAllAsRead } = props;
  return (
    <VStack>
      <HStack style={styles.container}>
        <Text fontWeight={'bold'}>
          <DisplayText size={'lgMedium'} textLocalId="notifications" />
        </Text>
        <Pressable onPress={onMarkAllAsRead}>
          <DisplayText textLocalId="markAllAsRead" />
        </Pressable>

      </HStack>
      <Divider marginY={1} />
    </VStack>
  );
};

export default NotificationListHeader;
