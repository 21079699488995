import {Button, HStack, Icon, Pressable, Text} from 'native-base';
import React from 'react';
import {Colors} from '../../../../styles';
import {BottomAction} from '../interface';
import AntIcon from 'react-native-vector-icons/AntDesign';
const ActionChip = (props: {action: BottomAction}) => {
  const {action} = props;
  return (
    <Button
      rounded="full"
      py={1}
      px={3}
      borderWidth={1}
      borderColor={Colors.Custom.PurpleColor}
      backgroundColor={Colors.Custom.Base50}
      mr={2}
      _hover={{
        backgroundColor: Colors.Custom.PurpleColor,
        _text: {
          color: 'white',
        },
      }}
      _text={{
        color: Colors.Custom.PurpleColor,
        fontWeight: 'bold',
      }}
      onPress={() => action.onSelect(action.code)}
      rightIcon={
        <AntIcon
          name="plus"
          size={12}
          style={{marginRight: 5}}
        />
      }
    >
      {action.label}
    </Button>
  );
};

export default ActionChip;
