import { useLazyQuery } from '@apollo/client';
import { Select, Spin } from 'antd';
import { useToast } from 'native-base';
import React from 'react';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../constants/Configs';
import { CareJourneyQueries } from '../../../services';
import { showToast, ToastType } from '../../../utils/commonViewUtils';
import {
  ICarePlanSearchProps,
  ICarePlanSearchState,
  SingleCareJourney
} from './interfaces';

export const CarePlanSearch = (props: ICarePlanSearchProps) => {
  const [state, setState] = React.useState<ICarePlanSearchState>({
    carePlans: [] as SingleCareJourney[],
    searchString: '',
    loading: false,
  });

  const toast = useToast();

  const [getCareJourneysByTitle] = useLazyQuery(
    CareJourneyQueries.GET_CARE_JOURNEYS_BY_TITLE,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  const searchCarePlans = async (isInitial?: boolean) => {
    if (!isInitial && state.searchString.length < 3) {
      return;
    }
    setState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    try {
      const response = await getCareJourneysByTitle({
        variables: {
          searchString: `%${state.searchString}%`,
          ...(isInitial ? {limit: 10} : {}),
        },
      });
      const carePlans: SingleCareJourney[] = response?.data?.careJourneys || [];
      setState((prev) => {
        return {
          ...prev,
          carePlans: carePlans,
          searchedCarePlans: carePlans,
          loading: false,
        };
      });
    } catch (error) {
      setState((prev) => {
        return {
          ...prev,
          carePlans: [],
          searchedCarePlans: [],
          loading: false,
        };
      });
      showToast(toast, 'Error in getting care journeys', ToastType.error);
    }
  };

  React.useEffect(() => {
    searchCarePlans();
  }, [state.searchString]);

  React.useEffect(() => {
    searchCarePlans(true);
  }, []);

  const handleOnChange = (value: string[]) => {
    const result: SingleCareJourney[] = [];
    value.forEach((v) => {
      state.carePlans.forEach((item) => {
        if (item.id == v) {
          result.push(item);
        }
      });
    });
    props.onSelect(result);
  };

  return (
    <Select
      size={'large'}
      allowClear
      showSearch
      filterOption={false}
      onSearch={(value: string) => {
        setState((prev) => {
          return {
            ...prev,
            searchString: value,
          };
        });
      }}
      onChange={(value: string[]) => {
        handleOnChange(value);
      }}
      placeholder="Search Care Journeys"
      mode="multiple"
      notFoundContent={state.loading && <Spin size="large" />}
      loading={state.loading}
      style={{
        width: '100%',
      }}
    >
      {state.carePlans.map((item) => {
        return (
          <Select.Option key={item.id} value={item.id}>
            {item.title}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default CarePlanSearch;
