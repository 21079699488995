import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { DatePicker, Drawer, Skeleton } from 'antd';
import { cloneDeep } from 'lodash';
import {
  Center,
  FormControl,
  HStack,
  Select,
  Text,
  View,
  VStack
} from 'native-base';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  BUTTON_TYPE,
  DATE_FORMATS,
  MLOV_CATEGORY
} from '../../../../../../constants';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../../constants/Configs';
import { CommonDataContext } from '../../../../../../context/CommonDataContext';
import {
  AppointmentQueries,
  CareJourneyQueries,
  ScheduleEventQueries,
  UserQueries
} from '../../../../../../services';
import { Colors } from '../../../../../../styles';
import { getAccountUUID } from '../../../../../../utils/commonUtils';
import {
  getDateObjFromDateStrAndFormat,
  getDateStrFromFormat,
  getDateToMomentISOString,
  getMomentObjectWithDateStringAndFormat,
  isPastDay
} from '../../../../../../utils/DateUtils';
import {
  getMlovCodeFromId,
  getMlovListFromCategory,
  getMlovValueFromId
} from '../../../../../../utils/mlovUtils';
import { getWorkFlowDataById } from '../../../../../RightSideContainer/Journeys/AddOrUpdateJourney/JourneyMetadataService';
import { DisplayText } from '../../../../DisplayText/DisplayText';
import { ModalActionTitle } from '../../../../ModalActionTitle/ModalActionTitle';
import ParticipantAutoComplete from '../../../ParticipantAutoComplete/ParticipantAutoComplete';
import { ParticipantType } from '../../../ParticipantAutoComplete/ParticipantEnum';
import { IParticipantSearch } from '../../../ParticipantAutoComplete/ParticipantInterfaces';
import {
  getAppointmentEndDate,
  getAppointmentTypeIdsFromWorkflowElement,
  getFilteredUsersByRoleId,
  isInvalidData
} from './ServiceBookingHelper';

export interface IServiceBooking {
  isVisible: boolean;
  onComplete: (data?: any) => void;
  onCancel: () => void;
}

export interface IServiceBookingState {
  serviceList: IAppointmentService[];
  selectedService?: IAppointmentService;
  workflowError: string;
  roleDataLoading: boolean;
  userRoleMapping: IUserRoleMapping[];
  startDate: string;
  showErrors: boolean;
  selectedUsers: {roleId: string; userId: string}[];
  participants: IParticipantSearch[];
}

export interface IUserRoleMapping {
  roleId: string;
  roleName: string;
  userList: {id: string; name: string; roleId: string}[];
}

export interface IAppointmentService {
  id: string;
  duration: number;
  durationUnitId: string;
  title: string;
}

const ServiceBooking = (props: IServiceBooking) => {
  const intl = useIntl();
  const scrollableHeight = window.innerHeight - 160;
  const accountId = getAccountUUID();
  const defaultData: IServiceBookingState = {
    serviceList: [],
    selectedService: undefined,
    workflowError: '',
    roleDataLoading: false,
    userRoleMapping: [],
    showErrors: false,
    selectedUsers: [],
    participants: [],
    startDate: getDateStrFromFormat(
      new Date(),
      DATE_FORMATS.DISPLAY_DATE_FORMAT
    ),
  };
  const mlovData = useContext(CommonDataContext);
  const userRoles =
    getMlovListFromCategory(mlovData.MLOV, MLOV_CATEGORY.USER_ROLES) || [];
  const journeyDuration =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.CARE_JOURNEY_DURATION
    ) || [];

  const [serviceBookingState, setServiceBookingState] =
    useState<IServiceBookingState>(defaultData);

  const serviceAPIData = useQuery(CareJourneyQueries.GET_ALL_CARE_JOURNEYS, {
    fetchPolicy: 'no-cache',
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    onCompleted: (data: any) => {
      if (data && data.careJourneys && data.careJourneys.length > 0) {
        setServiceBookingState((prev) => ({
          ...prev,
          selectedService: data.careJourneys[0],
          serviceList: data.careJourneys,
        }));
      } else {
        setServiceBookingState((prev) => ({
          ...prev,
          serviceList: [],
        }));
      }
    },
  });

  const [getAppointmentTypeData] = useLazyQuery(
    ScheduleEventQueries.GET_APPOINTMENT_TYPES_BY_IDS,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  const [getUsersByRoleIds] = useLazyQuery(UserQueries.GET_USERS_BY_ROLE_IDS, {
    fetchPolicy: 'no-cache',
  });

  const [bookWorkflowAppointment] = useMutation(
    AppointmentQueries.BOOK_WORKFLOW_APPOINTMENT
  );

  useEffect(() => {
    if (
      serviceBookingState.selectedService &&
      serviceBookingState.selectedService.id
    ) {
      setServiceBookingState((prev) => ({
        ...prev,
        workflowError: '',
        userRoleMapping: [],
        selectedUsers: [],
        roleDataLoading: true,
      }));
      getWorkFlowDataById(
        serviceBookingState.selectedService.id,
        accountId,
        (response) => {
          if (response && response.nodes && response.nodes.length > 0) {
            const appointmentTypeIds = getAppointmentTypeIdsFromWorkflowElement(
              response.nodes
            );
            fetchRoleData(appointmentTypeIds);
          } else {
            setAPIError();
          }
        },
        () => {
          setAPIError();
        }
      );
    }
  }, [serviceBookingState.selectedService]);

  const fetchRoleData = (appointmentTypeIds: string[]) => {
    if (appointmentTypeIds.length > 0) {
      getAppointmentTypeData({
        variables: {
          ids: appointmentTypeIds,
        },
      })
        .then((response: any) => {
          if (
            response &&
            response.data &&
            response.data.appointmentTypes &&
            response.data.appointmentTypes.length > 0
          ) {
            const roleIds: string[] = [];
            response.data.appointmentTypes.forEach((type: any) => {
              if (
                type?.appointmentTypeGroup &&
                type?.appointmentTypeGroup?.length > 0
              ) {
                type.appointmentTypeGroup.forEach((group: any) => {
                  if (group.roleId) {
                    roleIds.push(group.roleId);
                  }
                });
              }
            });
            if (roleIds.length > 0) {
              fetchUsersByRoleIds(roleIds);
            } else {
              setAPIError();
            }
          } else {
            setAPIError();
          }
        })
        .catch(() => {
          setAPIError();
        });
    } else {
      setServiceBookingState((prev) => ({
        ...prev,
        workflowError: 'Selected service does not contain any schedule node',
        roleDataLoading: false,
      }));
    }
  };

  const fetchUsersByRoleIds = (roleIds: string[]) => {
    getUsersByRoleIds({
      variables: {
        roleIds: roleIds,
        accountId: accountId,
      },
    })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.users &&
          response.data.users.length > 0
        ) {
          const users = cloneDeep(response.data.users);
          const userRoleMapping: IUserRoleMapping[] = [];
          roleIds.forEach((roleId) => {
            userRoleMapping.push({
              roleId: roleId,
              roleName: getMlovValueFromId(userRoles, roleId),
              userList: getFilteredUsersByRoleId(users, roleId),
            });
          });
          const defaultSelectedUsers = userRoleMapping
            .filter((userRole) => userRole.userList.length > 0)
            .map((userRole) => {
              return {
                roleId: userRole.roleId,
                userId: userRole.userList[0].id,
              };
            });
          setServiceBookingState((prev) => ({
            ...prev,
            userRoleMapping: userRoleMapping,
            roleDataLoading: false,
            selectedUsers: defaultSelectedUsers,
          }));
        } else {
          setAPIError();
        }
      })
      .catch(() => {
        setAPIError();
      });
  };

  const setAPIError = () => {
    setServiceBookingState((prev) => ({
      ...prev,
      workflowError: intl.formatMessage({id: 'apiErrorMsg'}),
      roleDataLoading: false,
    }));
  };

  const resetContent = () => {
    setServiceBookingState(defaultData);
  };

  const submitData = () => {
    setServiceBookingState((prev) => ({...prev, showErrors: true}));
    if (!isInvalidData(serviceBookingState)) {
      const startDate = getDateObjFromDateStrAndFormat(
        serviceBookingState.startDate,
        DATE_FORMATS.DISPLAY_DATE_FORMAT
      );
      const duration = serviceBookingState.selectedService?.duration || 0;
      const durationUnitId =
        serviceBookingState.selectedService?.durationUnitId;
      const durationUnitCode = getMlovCodeFromId(
        journeyDuration,
        durationUnitId || ''
      );
      bookWorkflowAppointment({
        variables: {
          data: {
            workflowMasterId: serviceBookingState.selectedService?.id,
            userRoleMap: serviceBookingState.selectedUsers,
            startDateTime: getDateToMomentISOString(startDate),
            endDateTime: getDateToMomentISOString(
              getAppointmentEndDate(startDate, duration, durationUnitCode)
            ),
            contactId: getContactId(serviceBookingState),
          },
        },
        context: {service: CARESTUDIO_APOLLO_CONTEXT},
        onCompleted: (resp) => {
          props.onComplete(resp?.createBookedAppointments[0].id);
        },
      });
    }
  };

  const getContactId = (bookingData: IServiceBookingState) => {
    const participants = bookingData.participants;
    const patientList = participants.filter(
      (participant) => participant.type === ParticipantType.patient
    );
    if (patientList.length > 0) {
      return patientList[0].value;
    } else {
      const leadList = participants.filter(
        (participant) => participant.type === ParticipantType.leads
      );
      if (leadList.length > 0) {
        return leadList[0].value;
      }
    }
    return '';
  };

  const componentLoading = serviceAPIData.loading;
  return (
    <Drawer
      // title={intl.formatMessage({ id: 'bookAppointment' })}
      title={
        <>
          {/* <HStack
            style={{
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Text>{intl.formatMessage({id: 'bookAppointment'})}</Text>
            <HStack>
              <Spacer />
              <Button
                rounded="3xl"
                onPress={() => {
                  submitData();
                }}
                minWidth={8}
                variant="solid"
              >
                {intl.formatMessage({id: 'book'})}
              </Button>
            </HStack>
          </HStack> */}

          <ModalActionTitle
            title={'bookAppointment'}
            titleColor={''}
            buttonList={[
              {
                show: true,
                id: 1,
                btnText: 'book',
                textColor: Colors.Custom.mainPrimaryPurple,
                variant: BUTTON_TYPE.PRIMARY,
                isTransBtn: false,
                // leftIcon: '',
                onClick: () => {
                  submitData();
                },
              },
            ]}
          />
        </>
      }
      destroyOnClose
      placement="right"
      onClose={() => {
        props.onCancel();
        resetContent();
      }}
      visible={props.isVisible}
      closable
      width="35%"
    >
      {!componentLoading && serviceBookingState.serviceList.length > 0 && (
        <VStack >
          <VStack space={4} height={scrollableHeight} overflow="scroll">
            <FormControl>
              <FormControl.Label isRequired>
                <DisplayText textLocalId="appointmentService" />
              </FormControl.Label>
              <Select
                placeholder="Select Appointment Service"
                selectedValue={serviceBookingState.selectedService?.id}
                onValueChange={(value: string) => {
                  const selected = serviceBookingState.serviceList.filter(
                    (type) => {
                      return type.id === value;
                    }
                  );
                  if (selected.length > 0) {
                    setServiceBookingState((prev) => ({
                      ...prev,
                      selectedService: selected[0],
                    }));
                  }
                }}
              >
                {serviceBookingState.serviceList.map((type) => {
                  return (
                    <Select.Item
                      label={type.title}
                      value={type.id || ''}
                      key={type.id || ''}
                    />
                  );
                })}
              </Select>
            </FormControl>

            <FormControl>
              <FormControl.Label isRequired>
                <DisplayText textLocalId="selectAppointmentPatients" />
              </FormControl.Label>
              <ParticipantAutoComplete
                disableUserSearch
                allowSingleSelectInCategory
                placeholder="Search members and prospects"
                showError={
                  serviceBookingState.showErrors &&
                  serviceBookingState.participants.length === 0
                }
                defaultValue={serviceBookingState.participants}
                onChange={(participants: any[]) => {
                  setServiceBookingState((prev) => ({
                    ...prev,
                    participants: participants,
                  }));
                }}
              />
              {serviceBookingState.showErrors &&
                serviceBookingState.participants.length === 0 && (
                  <Text color="error.500" fontSize="xs">
                    Please select patient.
                  </Text>
                )}
            </FormControl>

            <FormControl maxWidth={300}>
              <FormControl.Label isRequired>
                <DisplayText textLocalId="startDate" />
              </FormControl.Label>
              <DatePicker
                value={getMomentObjectWithDateStringAndFormat(
                  serviceBookingState.startDate,
                  DATE_FORMATS.DISPLAY_DATE_FORMAT
                )}
                format={DATE_FORMATS.DISPLAY_DATE_FORMAT}
                disabledDate={(current: any) => {
                  return current && isPastDay(current);
                }}
                onChange={(value, dateString) => {
                  setServiceBookingState((prev) => ({
                    ...prev,
                    startDate: dateString,
                  }));
                }}
                className={
                  serviceBookingState.showErrors &&
                  !serviceBookingState.startDate
                    ? 'field-error'
                    : ''
                }
              />
            </FormControl>

            <FormControl marginTop={2}>
              <FormControl.Label isRequired>
                <DisplayText textLocalId="selectUserForRole" />
              </FormControl.Label>
              {serviceBookingState.roleDataLoading && (
                <View>
                  <Skeleton active />
                </View>
              )}
              {!serviceBookingState.roleDataLoading &&
                serviceBookingState.userRoleMapping.length == 0 && (
                  <Text color="error.500">
                    There are no appointment entities available in the selected
                    service. Please select another appointment service.
                  </Text>
                )}
              {!serviceBookingState.roleDataLoading &&
                serviceBookingState.userRoleMapping.length > 0 && (
                  <VStack space={4} marginTop={2}>
                    {serviceBookingState.userRoleMapping.map(
                      (userRole, index) => {
                        const selectedUser =
                          serviceBookingState.selectedUsers.filter(
                            (user) => user.roleId === userRole.roleId
                          );
                        const selectedUserId =
                          selectedUser.length > 0 ? selectedUser[0].userId : '';

                        return (
                          <HStack space={4} key={index} alignItems="center">
                            <Text flex={1}>{userRole.roleName}</Text>
                            <FormControl
                              flex={2}
                              isInvalid={
                                serviceBookingState.showErrors &&
                                !selectedUserId
                              }
                            >
                              <Select
                                placeholder="Select User"
                                selectedValue={selectedUserId}
                                onValueChange={(value: string) => {
                                  setServiceBookingState((prev) => {
                                    let newState = prev.selectedUsers;
                                    newState = newState.filter(
                                      (user) => user.roleId !== userRole.roleId
                                    );
                                    newState.push({
                                      userId: value,
                                      roleId: userRole.roleId,
                                    });
                                    return {...prev, selectedUsers: newState};
                                  });
                                }}
                              >
                                {userRole.userList.map((user) => {
                                  return (
                                    <Select.Item
                                      label={user.name}
                                      value={user.id || ''}
                                      key={user.id || ''}
                                    />
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </HStack>
                        );
                      }
                    )}
                  </VStack>
                )}
            </FormControl>
          </VStack>
          {/* <VStack>
            <Divider marginY={2} />
            <HStack>
              <Spacer />
              <Button
                rounded="3xl"
                onPress={() => {
                  submitData();
                }}
                minWidth={8}
                variant="solid"
              >
                {intl.formatMessage({ id: 'book' })}
              </Button>
            </HStack>
          </VStack> */}
        </VStack>
      )}
      {componentLoading && (
        <View height={scrollableHeight}>
          <Skeleton active />
        </View>
      )}
      {!componentLoading && serviceBookingState.serviceList.length === 0 && (
        <Center
          style={{marginTop: 50}}
          height={654}
          width="full"
          justifyContent="center"
          alignItems="center"
        >
          <Text fontWeight={200} fontSize="lg" textAlign="center">
            Looks like you have't created any appointment services. Please
            create appointment services and then come back again.
          </Text>
        </Center>
      )}
    </Drawer>
  );
};

export default ServiceBooking;
