import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  emailView: {
    flex: 0.2,
    alignItems: 'flex-start',
    // backgroundColor: 'yellow',
  },
  emailText: {
    fontSize: 14,
  },
});
