import React, { useCallback, useContext, useEffect, useState } from 'react'
import { ITaskFilter, MultiSelectAction, MultiSelectState } from '../TaskInterfaces';
import { ToastType } from '../../../utils/commonViewUtils';
import { FetchTaskState, TASK_EVENTS, TASK_MODULE_CODE, TaskField } from '../../common/CareDashboard/CareDashboardConstants';
import { EventBus } from '../../../utils/EventBus';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { getMlovCodeFromId, getMlovListFromCategory, getMlovObjectFromCode } from '../../../utils/mlovUtils';
import { MLOV_CATEGORY, WINDOW_EVENT_CODES } from '../../../constants';
import { LABEL_TYPE_CODES, TASK_PRIORITY_CODES, TASK_STATUS, TASK_STATUS_CODES } from '../../../constants/MlovConst';
import useDebounceEvent from '../../common/CareDashboard/CustomHook/useDebounceEvent';
import { IContactDetails, ILabelTask, ITask, ITaskDragContext, IUpdatedTaskData } from '../../common/CareDashboard/CareDashboardInterfaces';
import { setTaskDetailsBasedOnUpdatedData } from '../../common/CareDashboard/CustomHook/TaskCustomHookHelper';
import useTaskActionManager from '../../common/CareDashboard/CustomHook/useTaskActionManager';
import { useLazyQuery } from '@apollo/client';
import { TaskQueries } from '../../../services';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../constants/Configs';
import { BottomViewAction, isTaskWithType } from '../../TaskCard/TaskCardHelper';
import { EntityType } from '../../TaskCard/TaskEnum';
import { GET_TASK_LABEL_BATCH_COUNT, getAllAddedlabelsIds, getAllContactIds } from '../../common/CareDashboard/CareDashboardUtils/CareDashboardUtils';
import { BoardType } from '../../common/CareDashboard/CareDashboardTopBar/interfaces';
import { doesTaskSatisfiesFilterConditions, isAssigneeMatchingFilters, isPriorityMatchingFilters, isStatusMatchingFilters } from '../TaskModuleHelper';
import { useIntl } from 'react-intl';
import { usePermissions } from '../../CustomHooks/usePermissions';
import { USER_ACCESS_PERMISSION } from '../../RightSideContainer/UserAccess/UserAccessPermission';
import { MAIN_MENU_CODES } from '../../SideMenuBar/SideBarConst';
import { getBooleanFeatureFlag } from '../../../utils/commonUtils';
import FeatureFlags from '../../../constants/FeatureFlags.enums';
import { SEARCH_CONTACT_AND_GET_BASIC_DETAILS } from '../../../services/Patient/PatientQueries';
import { IUser } from '../../../Interfaces/CommonInterfaces';
import { IUseTaskCountManagerReturn } from '../../common/CareDashboard/CustomHook/useTaskCountManager';


export interface IPagination {
  pageSize: number;
  pageNumber: number;
}

export interface IPaginationData extends IPagination {
  total: number;
}

export interface IUserItem {
  id: string;
  name: string;
  uuid: string;
  email: string;
}

interface IUseTaskManagerInterface {
  filters: ITaskFilter;
  pagination: IPagination;
  categoryCode?: string;
  boardType?: BoardType;
  accountLocationUuid?: string;
  multiSelectData?: {
    selectedTasks?: ITask[];
    bulkActionEnabled?: boolean;
  }
  showToast?: (message: string, type: ToastType, additionalData?: any) => void;
  onSelectedTaskChange?: (action: MultiSelectAction, tasks: ITask[]) => void;
  taskMasterUsers: IUser[];
  allTaskPools: any[];
  taskCountData?: IUseTaskCountManagerReturn;
}

const useTaskManager = (args: IUseTaskManagerInterface) => {
  // Global constants
  const eventBus = EventBus.getEventBusInstance();
  const intl = useIntl();
  const commonData = useContext(CommonDataContext);
  const taskStatusList = getMlovListFromCategory(commonData.CARE_STUDIO_MLOV, MLOV_CATEGORY.TASK_STATUS) || [];
  const labels = getMlovListFromCategory(
    commonData.MLOV,
    MLOV_CATEGORY.LABEL_TYPE
  );
  const isMultiTenancyEnabled = getBooleanFeatureFlag(commonData.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED);
  const { accountLocationListWithEHR } = commonData;
  const { check } = usePermissions();
  const permissionConfig = check(USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code, MAIN_MENU_CODES.TASKS);
  const allowedAccountLocationIds = accountLocationListWithEHR?.filter((location) => {
    return permissionConfig?.allowedLocationIds?.includes(location?.uuid)
  })?.map((location) => location?.uuid) || [];
  const taskPriorityList = getMlovListFromCategory(
    commonData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.TASK_PRIORITY,
    false
  );
  const taskDueDateCategoryMlovs =
    getMlovListFromCategory(
      commonData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.TASK_DUE_DATE_CATEGORY
    ) || [];
  const filteredResult = labels?.filter((item) => {
    return item.code === LABEL_TYPE_CODES.TASK;
  });
  const taskLabelMlov = filteredResult?.[0];
  const categoryTaskCount = args.taskCountData?.taskCount.find((item) => item.code === args.categoryCode);

  // States
  const [tasks, setTasks] = useState<ITask[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [pagination, setPagination] = useState<IPaginationData>({
    ...args.pagination,
    total: 0,
  });
  const {pushEvent} = useDebounceEvent({
    onDebounce: () => {
      fetchTasks(pagination, args.filters, {state: FetchTaskState.refetchSamePageInBg});
    }
  });
  const onTaskUpdate = (task: ITask, updatedData: IUpdatedTaskData) => {
    // For Kanban drag and drop actions, task is already moved to new list, thus skipping task visibility check
    if (updatedData.context?.shouldSkipTaskVisibilityCheck) {
      setTaskDetailsBasedOnUpdatedData(task, updatedData, { taskStatusList, taskPriorityList });
      return;
    }
    updateLocalTask(task, updatedData);
  }
  const { handleTaskActions, taskActionsInProgress } = useTaskActionManager({
    onTaskUpdate,
    showToast: args.showToast,
    locationUuid: args.accountLocationUuid,
  });

  // APIs
  const [getTasks] = useLazyQuery(TaskQueries.GET_TASKS_FOR_DASHBOARD, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
  });

  const [getLabelsData] = useLazyQuery(TaskQueries.GET_LABELS_BY_IDS, {
    fetchPolicy: 'no-cache',
  });

  const [getTaskCount] = useLazyQuery(TaskQueries.GET_AGGREGATE_TASK_COUNT, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });

  // search contact and get basic details
  const [getContacts] = useLazyQuery(SEARCH_CONTACT_AND_GET_BASIC_DETAILS, {
    fetchPolicy: 'no-cache',
  });

  // lifecycle methods
  useEffect(() => {
    window.addEventListener('message', onEventReceived);
    return () => {
      window.removeEventListener('message', onEventReceived);
    };
  }, []);

  useEffect(() => {
    eventBus.addEventListener(TASK_EVENTS.TASK_CATEGORY_CHANGED, onTaskCategoryChangeEvent, { moduleCode: TASK_MODULE_CODE });
    eventBus.addEventListener(TASK_EVENTS.TASK_ADDED, onTaskAddedEvent, { moduleCode: TASK_MODULE_CODE });
    eventBus.addEventListener(TASK_EVENTS.TASK_UPDATED, onTaskUpdatedEvent, { moduleCode: TASK_MODULE_CODE });
    eventBus.addEventListener(TASK_EVENTS.SUB_TASK_ADDED, onTaskAddedEvent, { moduleCode: TASK_MODULE_CODE });
    eventBus.addEventListener(TASK_EVENTS.SUB_TASK_UPDATED, onTaskUpdatedEvent, { moduleCode: TASK_MODULE_CODE });
    eventBus.addEventListener(TASK_EVENTS.ON_TASK_DRAG_FROM_SOURCE, onTaskDraggedFromSourceEvent, { moduleCode: TASK_MODULE_CODE });
    eventBus.addEventListener(TASK_EVENTS.ON_TASK_DROP_TO_DESTINATION, onTaskDroppedToDestinationEvent, { moduleCode: TASK_MODULE_CODE });
    eventBus.addEventListener(TASK_EVENTS.ON_TASK_ACTION, onTaskActionEvent, { moduleCode: TASK_MODULE_CODE });
    eventBus.addEventListener(TASK_EVENTS.REFRESH_TOTAL_COUNT, refreshCountEvent, { moduleCode: TASK_MODULE_CODE });
    eventBus.addEventListener(TASK_EVENTS.REFRESH_TASKS, refreshTask, { moduleCode: TASK_MODULE_CODE });
    eventBus.addEventListener(TASK_EVENTS.ON_MULTI_TASK_STATE_CHANGE, onMultiTaskStateChange, { moduleCode: TASK_MODULE_CODE });

    return () => {
      eventBus.removeEventListenerByEventName(TASK_EVENTS.TASK_CATEGORY_CHANGED, onTaskCategoryChangeEvent, { moduleCode: TASK_MODULE_CODE })
      eventBus.removeEventListenerByEventName(TASK_EVENTS.TASK_ADDED, onTaskAddedEvent, { moduleCode: TASK_MODULE_CODE });
      eventBus.removeEventListenerByEventName(TASK_EVENTS.TASK_UPDATED, onTaskUpdatedEvent, { moduleCode: TASK_MODULE_CODE });
      eventBus.removeEventListenerByEventName(TASK_EVENTS.SUB_TASK_ADDED, onTaskAddedEvent, { moduleCode: TASK_MODULE_CODE });
      eventBus.removeEventListenerByEventName(TASK_EVENTS.SUB_TASK_UPDATED, onTaskUpdatedEvent, { moduleCode: TASK_MODULE_CODE });
      eventBus.removeEventListenerByEventName(TASK_EVENTS.ON_TASK_DRAG_FROM_SOURCE, onTaskDraggedFromSourceEvent, { moduleCode: TASK_MODULE_CODE });
      eventBus.removeEventListenerByEventName(TASK_EVENTS.ON_TASK_DROP_TO_DESTINATION, onTaskDroppedToDestinationEvent, { moduleCode: TASK_MODULE_CODE });
      eventBus.removeEventListenerByEventName(TASK_EVENTS.ON_TASK_ACTION, onTaskActionEvent, { moduleCode: TASK_MODULE_CODE });
      eventBus.removeEventListenerByEventName(TASK_EVENTS.REFRESH_TOTAL_COUNT, refreshCountEvent, { moduleCode: TASK_MODULE_CODE });
      eventBus.removeEventListenerByEventName(TASK_EVENTS.REFRESH_TASKS, refreshTask, { moduleCode: TASK_MODULE_CODE });
      eventBus.removeEventListenerByEventName(TASK_EVENTS.ON_MULTI_TASK_STATE_CHANGE, onMultiTaskStateChange, { moduleCode: TASK_MODULE_CODE });
    }
  }, [tasks, pagination, args.filters]);

  useEffect(() => {
    fetchTasks({ pageNumber: 1, pageSize: pagination.pageSize }, args.filters, {state: FetchTaskState.resetAndFetchFromPagination});
  }, [
    args.filters.assigneeIds,
    args.filters.assigneeIdsNotIn,
    args.filters.assigneeTypeCodes,
    args.filters.assignedByIds,
    args.filters.createdByIds,
    args.filters.mentionedUserOrContactIds,
    args.filters.contactOrAssigneeIds,
    args.filters.contactIds,
    args.filters.statusIds,
    args.filters.statusIdsNotIn,
    args.filters.statusCodesNotIn,
    args.filters.priorityIds,
    args.filters.startDateTime,
    args.filters.endDateTime,
    args.filters.userPoolIds,
    args.filters.labels,
    args.filters.searchString,
    args.filters.taskDueDateCategoryIds,
    args.filters.orderBy,
  ]);

  useEffect(() => {
    eventBus.broadcastEvent(TASK_EVENTS.ON_MULTI_TASK_STATE_CHANGE, { categoryCode: args.categoryCode, state: getMultiselectState() })
  }, [args.multiSelectData?.selectedTasks])

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      total: categoryTaskCount?.count || 0,
    }));
  }, [categoryTaskCount])

  // Public methods
  const fetchTasks = async (paginationData: IPagination, filterData: ITaskFilter, config: { state: FetchTaskState }) => {
    if ([FetchTaskState.fetchAndAppendToList, FetchTaskState.resetAndFetchFromPagination, FetchTaskState.refetchSamePageInBgAndApplyNewChanges].includes(config.state)) {
      setPagination((prev) => ({
        ...prev,
        ...paginationData,
      }));
    }
    if (config.state === FetchTaskState.resetAndFetchFromPagination) {
      setTasks([]);
      setLoading(false);
      setError(false);
    }

    const isBGFetch = [FetchTaskState.refetchSamePageInBg, FetchTaskState.refetchSamePageInBgAndApplyNewChanges].includes(config.state);
    const offset = (paginationData.pageNumber - 1) * paginationData.pageSize;

    try {
      const params = {
        ...filterData,
        offset,
        limit: paginationData.pageSize,
        ...(isMultiTenancyEnabled && { accountLocationIds: allowedAccountLocationIds}),
      };

      setLoading(!isBGFetch);

      let tasksList: ITask[] = [];
      let totalTasks = 0;

      const getTasksData = await getTasks({
        variables: {
          params,
        },
      });

      if (!getTasksData?.data?.getTasks?.tasks) {
        if (!isBGFetch) {
          setError(true);
          setLoading(false);
        }
        return;
      }

      tasksList = getTasksData?.data?.getTasks?.tasks || [];
      tasksList = (tasksList || []).map((task) => {
        const assignedByUser = args.taskMasterUsers.find(user => user.uuid === task?.assignedById);
        const assigneeUser = args.taskMasterUsers.find(user => user.uuid === task?.assigneeId);
        let taskUserPool = null;
        if (task?.userPoolId) {
          const taskPool = args.allTaskPools.find(pool => pool.id === task?.userPoolId);
          if (taskPool) {
            taskUserPool = {
              name: taskPool?.name,
              id: taskPool?.id,
              userPoolUsers: taskPool?.userPoolUsers || [],
            }
          }
        }
        return {
          ...task,
          isContactDataLoad: task?.contactId ? false : true,
          ...(assignedByUser?.uuid && {assignedByUser: assignedByUser}),
          ...(taskUserPool && {userPool: taskUserPool}),
          ...(assigneeUser?.uuid && {assigneeUser: assigneeUser}),
        }
      });
      totalTasks = getTasksData?.data?.getTasks?.aggregate?.total || 0;

      setTaskAdditionalDetails(tasksList);
      if (isBGFetch) {
        if (config.state === FetchTaskState.refetchSamePageInBgAndApplyNewChanges) {
          setTasksInBackground(tasksList, true);
        } else if (pagination.pageNumber === paginationData.pageNumber) {
          setTasksInBackground(tasksList, false);
        }
      } else {
        if (config.state === FetchTaskState.fetchAndAppendToList) {
          setTasks((oldTasks) => {
            const oldTaskIds = oldTasks.map(item => item.id);
            const newTasks = tasksList.filter(item => !oldTaskIds.includes(item.id));
            return [...oldTasks, ...newTasks];
          });
        } else {
          setTasks([...tasksList]);
        }
        setLoading(false);
      }
      setPagination((prev) => ({
        ...prev,
        total: totalTasks,
      }));
      fetchTaskContactDetails(tasksList);
      fetchTaskLabels(tasksList);
    } catch (error) {
      if (!isBGFetch) {
        setError(true);
        setLoading(false);
      }
      return;
    }
  }

  const refreshCountEvent = useCallback((data: { columnId: string }) => {
    if (data?.columnId && data?.columnId === args.categoryCode) {
      refreshTaskTotalCount();
    }
  }, []);

  const handleMultiSelection = (action: MultiSelectAction, updatedTasks?: ITask[]) => {
    switch(action) {
      case MultiSelectAction.selectTask:
        args?.onSelectedTaskChange?.(action, updatedTasks || []);
        break;

      case MultiSelectAction.selectAllTasks:
        args?.onSelectedTaskChange?.(action, tasks || []);
        break;

      case MultiSelectAction.unSelectTask:
        args?.onSelectedTaskChange?.(action, updatedTasks || []);
        break;

      case MultiSelectAction.unSelectAllTask:
        args?.onSelectedTaskChange?.(action, tasks || []);
        break;

      default:
        break;
    }
  }

  const getMultiselectState = () => {
    if (!args.multiSelectData?.bulkActionEnabled) {
      return MultiSelectState.disabled;
    }
    const selectedTasks = args.multiSelectData?.selectedTasks || [];
    const selectedTaskIds = selectedTasks.map(item => item.id);
    const allTaskIds = tasks.map(item => item.id);
    const someTasksSelected = tasks.some(item => selectedTaskIds.includes(item.id));
    const allTasksSelected = allTaskIds.length && allTaskIds.every(item => selectedTaskIds.includes(item));
    if (allTasksSelected) {
      return MultiSelectState.all;
    } else if (someTasksSelected) {
      return MultiSelectState.some;
    }
    return MultiSelectState.none;
  }


  // Event listeners
  const onMultiTaskStateChange = (data: { bulkAction: MultiSelectAction, categoryCode: string }) => {
    if (data?.bulkAction && data?.categoryCode === args.categoryCode) {
      handleMultiSelection(data.bulkAction);
    }
  }

  const refreshTask = () => {
    fetchTasks(pagination, args.filters, {state: FetchTaskState.resetAndFetchFromPagination});
  }

  const onEventReceived = useCallback((event: MessageEvent) => {
    try {
      if (typeof event.data === 'string') {
        const data = JSON.parse(event.data);
        if (
          data.messageCode === WINDOW_EVENT_CODES.FORM_SUBMIT &&
          data.contactId &&
          args.filters.contactIds?.includes(data.contactId) || args.filters.assigneeIds?.includes(data.contactId)
        ) {
          fetchTasks(pagination, args.filters, {state: FetchTaskState.refetchSamePageInBg});
        }
      }
    } catch (error) {
    }
  }, []);

  const onTaskCategoryChangeEvent = (data: {task: ITask, code: string}) => {
    if (args.categoryCode === data?.code) {
      fetchTasks(pagination, args.filters, {state: FetchTaskState.refetchSamePageInBg});
    }
  };

  const onTaskActionEvent = (data: {task: ITask, action: BottomViewAction, additionalData?: any}) => {
    if (data?.task && data?.action) {
      const isTaskPresentInList = tasks.some(item => item.id === data?.task?.id);
      if (isTaskPresentInList) {
        handleTaskActions(data.task, data.action, data?.additionalData);
      }
    }
  }

  const onTaskDraggedFromSourceEvent = async (data: {
    source: ITaskDragContext,
    destination: ITaskDragContext
  }) => {
    // If required data is not present then return
    if (!data?.source || !data?.destination) {
      return;
    }
    const {source, destination} = data;
    // If current column is not same as column from event then return
    if (args.categoryCode !== source.column?.code) {
      return;
    }
    const task: ITask = tasks[source.index];
    // If task is of type vital or form then do not allow to drag or drop
    const isVitalTask = isTaskWithType(task, EntityType.VITAL);
    const isFormTask = isTaskWithType(task, EntityType.FORM);
    if (isVitalTask || isFormTask) {
      if (isFormTask && source.column.code === TASK_STATUS.COMPLETED) {
        args.showToast?.('Form already filled can not change status of completed task', ToastType.info);
        return;
      }
      if (destination.column.code === TASK_STATUS.COMPLETED) {
        const message = isFormTask ? 'Please fill the form to complete the task' : 'Please enter requested values to complete the task';
        args.showToast?.(message, ToastType.info);
        return;
      }
    }

    const updatedSourceList = Array.from(tasks);
    const [reorderItem] = updatedSourceList.splice(source.index, 1);
    // If no task is found then return
    if (!reorderItem) {
      return;
    }

    if (destination.column.code === TASK_STATUS.COMPLETED) {
      // If subtasks are incomplete then return
      const allSubmitted = await handleTaskActions(reorderItem, BottomViewAction.checkIfAllSubTasksAreCompleted);
      if (!allSubmitted) {
        return;
      }

      const noteNotSigned = await handleTaskActions(reorderItem, BottomViewAction.checkNoteStatus);

      const carePlanIsInReview = await handleTaskActions(reorderItem, BottomViewAction.checkCarePlanStatus);

      if (carePlanIsInReview) {
        args.showToast?.(intl.formatMessage({id: 'carePlanInReviewMsg'}), ToastType.info);
        return;
      }

      if (noteNotSigned) {
        args.showToast?.(intl.formatMessage({id: 'completeTaskLinkNoteMessage'}), ToastType.info);
        return;
      }
    }
    setTasks([...updatedSourceList]);
    // Send drop event to destination with task
    eventBus.broadcastEvent(TASK_EVENTS.ON_TASK_DROP_TO_DESTINATION, { ...data, task: reorderItem });
  }

  const onTaskDroppedToDestinationEvent = async (data: {
    source: ITaskDragContext,
    destination: ITaskDragContext,
    task: ITask
  }) => {
    // If required data is not present then return
    if (!data?.source || !data?.destination || !data?.task) {
      return;
    }
    const {source, destination} = data;
    // If current column is not same as column from event then return
    if (args.categoryCode !== destination.column?.code) {
      return;
    }
    const selectedTask: ITask = data.task;

    const updatedDestinationList: ITask[] = Array.from(tasks);
    updatedDestinationList.splice(destination.index, 0, selectedTask);
    setTasks([...updatedDestinationList]);

    // Perform action as per destination column
    const columnId = destination.column?.code;
    const context = { shouldSkipTaskVisibilityCheck: true };
    if (columnId) {
      switch (columnId) {
        case TASK_STATUS.COMPLETED:
          await handleTaskActions(selectedTask, BottomViewAction.markAsComplete, {}, context);
          break;

        case TASK_STATUS.ACCEPTED:
          await handleTaskActions(selectedTask, BottomViewAction.markAsIncomplete, {}, context);
          break;

        case TASK_STATUS.MISSED:
          await handleTaskActions(selectedTask, BottomViewAction.markAsMissed, {}, context);
          break;

        case TASK_PRIORITY_CODES.HIGH:
        case TASK_PRIORITY_CODES.MEDIUM:
        case TASK_PRIORITY_CODES.LOW:
          await handleTaskActions(selectedTask, BottomViewAction.updatePriority, { code: columnId }, context);
          break;
      }
      args.taskCountData?.refreshCount();
    }
  }

  const onTaskAddedEvent = (data: {task: ITask}) => {
    const task = data?.task;
    if (!task) {
      fetchTasks(pagination, args.filters, {state: FetchTaskState.refetchSamePageInBg});
      return;
    }
    const isTaskVisible = doesTaskSatisfiesFilterConditions(task, args.filters, {
      taskDueDateCategoryMlovs,
      categoryCode: args.categoryCode,
      boardType: args.boardType,
     });
    if (!isTaskVisible) {
      args.showToast?.(
        'The task is created but is not visible. View details.',
        ToastType.info,
        {
          style: {
            width: 500,
            cursor: 'pointer',
          },
          duration: 5.0,
          onClick: () => {
            eventBus.broadcastEvent(TASK_EVENTS.OPEN_TASK_DETAIL, { task });
          }
      });
    }
    fetchTasks(pagination, args.filters, {state: FetchTaskState.refetchSamePageInBg});
  };

  const onTaskUpdatedEvent = (data: {task: ITask}) => {
    const task = data.task;
    if (!task) {
      return;
    }

    const isTaskPresentInList = tasks.some(item => item.id === task.id);
    if (!isTaskPresentInList) {
      fetchTasks(pagination, args.filters, {state: FetchTaskState.refetchSamePageInBg});
      return;
    }

    // handle labels in this filter
    const isTaskVisible = doesTaskSatisfiesFilterConditions(task, args.filters, {
      taskDueDateCategoryMlovs,
      categoryCode: args.categoryCode,
      boardType: args.boardType,
     });
    if (!isTaskVisible) {
      removeTaskFromList(task);
      fetchTasks(pagination, args.filters, {state: FetchTaskState.refetchSamePageInBg});
      return;
    }

    let shouldRefetchAndApplyNewChanges = false;
    if (args.boardType) {
      switch (args.boardType) {
        case BoardType.Status:
          const status = getMlovCodeFromId(taskStatusList, task.statusId || '');
          shouldRefetchAndApplyNewChanges = status === args.categoryCode;
          break;

        case BoardType.Priority:
          const priority = getMlovCodeFromId(taskPriorityList, task.priorityId || '');
          shouldRefetchAndApplyNewChanges = priority === args.categoryCode;
          break;

        case BoardType.dueDate:
          shouldRefetchAndApplyNewChanges = true;
          break;

        case BoardType.taskPool:
          const taskPoolId = task.userPoolId;
          const currentPoolId = args.filters.userPoolIds?.length ? args.filters.userPoolIds[0] : '';
          shouldRefetchAndApplyNewChanges = taskPoolId === currentPoolId;
          break;
      }
    } else {
      shouldRefetchAndApplyNewChanges = true;
    }

    if (shouldRefetchAndApplyNewChanges) {
      fetchTasks(pagination, args.filters, {state: FetchTaskState.refetchSamePageInBgAndApplyNewChanges});
    } else {
      removeTaskFromList(task);
      fetchTasks(pagination, args.filters, {state: FetchTaskState.refetchSamePageInBg});
    }
  }

  // Private methods
  const refreshTaskTotalCount = async () => {
    try {
      const filterData = args.filters;
      const response = await getTaskCount({
        variables: {
          params: {
            ...filterData,
            ...(isMultiTenancyEnabled && { accountLocationIds: allowedAccountLocationIds}),
          }
        }
      });
      const total = response.data?.getTasks?.aggregate?.total || 0
      setPagination((prev) => ({ ...prev, total }));
    } catch {}
  }

  const setTasksInBackground = (tasksList: ITask[], isOverride: boolean) => {
    setTasks((oldTasks) => {
      const newTaskIds = tasksList.map(item => item.id);
      const updatedTasks = oldTasks.filter(oldTask => newTaskIds.includes(oldTask.id));
      tasksList.forEach((newTask, index) => {
        const itemExists = updatedTasks.some(updatedTask => updatedTask.id === newTask.id);
        if (!itemExists) {
          updatedTasks.splice(index, 0, newTask);
        } else if (isOverride) {
          updatedTasks.splice(index, 1, newTask);
        }
      });
      return [...updatedTasks];
    });
  }

  const setTaskAdditionalDetails = (tasksList: ITask[]) => {
    tasksList.forEach((task: ITask) => {
      if (task.status?.code === TASK_STATUS_CODES.COMPLETED) {
        task.isCompleted = true;
      }
    });

    if (tasksList?.length > 0) {
      tasksList.forEach((task) => {
        if (task.assignedByUser) {
          task.assignedBy = task.assignedByUser;
        }
      });
    }
  }

  const fetchTaskContactDetails = async (tasksList: ITask[]) => {
    const allContactIds = getAllContactIds(tasksList);
    let allContactDetails: IContactDetails[] = []; 
    if (allContactIds?.length) {
      const contactDetailsRes = await getContacts({ variables: { 
        params: {
          uuids: allContactIds,
        }
       }});
      allContactDetails = contactDetailsRes?.data?.searchContacts?.contacts || [];
      const contactDetailsMap: {[index: string]: IContactDetails} = {};
      allContactDetails.forEach((contact) => {
        contactDetailsMap[contact?.uuid] = contact;
      });
      setTasks((oldTasks) => {
        return oldTasks.map((task) => {
          const isContactAssignee = task?.assigneeTypeCode == 'CONTACT' && task?.assigneeId && contactDetailsMap[task?.assigneeId]?.uuid;
          if (task?.contactId && !task?.contact) {
            task.contact = contactDetailsMap[task?.contactId] || null;
            task.isContactDataLoad = true;
          } 
          if (isContactAssignee && task?.assigneeId && !task.assigneeUser) { 
            task.assigneeUser = contactDetailsMap[task?.assigneeId] || null;
            task.isContactDataLoad = true;
          }
          return task;
        })
      });
    }
  }

  const fetchTaskLabels = async (tasksList: ITask[]) => {
    if (tasksList?.length && taskLabelMlov?.id) {
      const allLabelsIdLists = getAllAddedlabelsIds(tasksList);
      if (allLabelsIdLists?.length) {
        const batchSize = GET_TASK_LABEL_BATCH_COUNT;
        let batches = [] as ILabelTask[];
        for (let i = 0; i < allLabelsIdLists.length; i += batchSize) {
          const batchIds = allLabelsIdLists.slice(i, i + batchSize);
          const getLabelsDataRes = await fetchLabelsForBatch(batchIds);
          batches = [...batches, ...getLabelsDataRes];
        }

        Promise.all(batches)
          .then((results) => {
            const labelIdMap: {[index: string]: ILabelTask} = {};
            results?.filter((allLabelsItem) => {
              if (allLabelsItem?.uuid) {
                labelIdMap[allLabelsItem.uuid] = allLabelsItem;
              }
            });
            setTasks((oldTasks) => {
              return oldTasks.map((task) => {
                task.labels = task.labels?.map((labelsItem) => {
                  if (labelsItem.labelId) {
                    const labelObj = labelIdMap[labelsItem.labelId];
                    if (labelObj) {
                      return {
                        ...labelObj,
                        id: labelsItem.id,
                        labelId: labelsItem.labelId
                      };
                    }
                  }
                  return labelsItem;
                });
                return task;
              })
            });
          })
          .catch((error) => {

          });
      }
    }
  }

  const fetchLabelsForBatch = async (batchIds: string[]) => {
    const getLabelsRes = await getLabelsData({
      variables: {
        labelIds: batchIds,
        labelTypeId: taskLabelMlov?.id,
      },
    });
    return getLabelsRes?.data?.labels || [] as ILabelTask[];
  }

  const updateLocalTask = (task: ITask, updatedData: IUpdatedTaskData) => {
    let shouldRefetchTasksPage = false;
    setTaskDetailsBasedOnUpdatedData(task, updatedData, { taskStatusList, taskPriorityList });

    switch (updatedData.field) {
      case TaskField.isDeleted:
        shouldRefetchTasksPage = true;
        removeTaskFromList(task);
        eventBus.broadcastEvent(TASK_EVENTS.TASK_DELETED, {task});
        break;

      case TaskField.status:
        const updatedStatusId = updatedData.value as string;
        const updatedStatusMlov = taskStatusList.filter((item) => item.id === updatedStatusId)?.[0];
        if (!isStatusMatchingFilters(updatedStatusId, args.filters, args.boardType)) {
          shouldRefetchTasksPage = true;
          removeTaskFromList(task);
          eventBus.broadcastEvent(TASK_EVENTS.TASK_CATEGORY_CHANGED, {task, code: updatedStatusMlov?.code});
        }
        break;

      case TaskField.assignee:
        const updatedAssigneeId = updatedData.value as string;
        if (!isAssigneeMatchingFilters(updatedAssigneeId, args.filters)) {
          shouldRefetchTasksPage = true;
          removeTaskFromList(task);
          eventBus.broadcastEvent(TASK_EVENTS.TASK_CATEGORY_CHANGED, { task });
        }
        break;

      case TaskField.priority:
        const updatedPriorityCode = updatedData.value as string;
        const priorityMlov = getMlovObjectFromCode(updatedPriorityCode, taskPriorityList);
        if (!isPriorityMatchingFilters(priorityMlov?.id || '', args.filters, args.boardType)) {
          shouldRefetchTasksPage = true;
          removeTaskFromList(task);
          eventBus.broadcastEvent(TASK_EVENTS.TASK_CATEGORY_CHANGED, { task, code: updatedPriorityCode });
        }
        break;
    }
    if (shouldRefetchTasksPage) {
      pushEvent();
    }
  }

  const removeTaskFromList = (task: ITask) => {
    setTasks((oldTasks) => {
      const newTasks = oldTasks.filter((item) => item.id !== task.id);
      return [...newTasks];
    });
  }

  return {
    loading,
    error,
    tasks,
    pagination,
    taskActionsInProgress,
    fetchTasks,
    handleTaskActions,
    refreshCountEvent,
    handleMultiSelection,
    getMultiselectState,
  };
}

export default useTaskManager
