import { IDetailsPanelProps } from '../../components/RightSideContainer/TeamInbox/Conversations/interfaces';

export const detailsDummyAafreen: IDetailsPanelProps[] = [
  { property: 'Primary Email', value: '--' },
  { property: 'Primary Phone', value: '--' },
  { property: 'Owner', value: '--' },
  { property: 'Department', value: '--' },
  { property: 'Pronoun', value: '--' },
  { property: 'Birthday', value: '--' },
  { property: 'Description', value: '--' },
];

export const detailsDummySujitha: IDetailsPanelProps[] = [
  { property: 'Primary Email', value: 'sujitha@yopmail.com' },
  { property: 'Primary Phone', value: '9285227864' },
  { property: 'Owner', value: 'Bhavesh Patel' },
  { property: 'Department', value: 'Research and Development' },
  { property: 'Pronoun', value: 'She/her' },
  { property: 'Birthday', value: '31/08/1999' },
  { property: 'Description', value: '-' },
];
