import {Formio} from '@foldhealth/formiojs';
import NativePromise from 'native-promise-only';
import {
  FORM_FILE_S3_URL,
} from '../../../../../constants/Configs';
import BaseService from '../../../../../services/CommonService/BaseService';
import LocalStorage from '../../../../../utils/LocalStorage';

const isNil = (val: any) => val === null || val === undefined;

export default class FileService {
  public formOptions: any;

  async uploadFile(
    storage: any,
    file: any,
    fileName: any,
    dir?: any,
    progressCallback?: any,
    url?: any,
    options?: any,
    fileKey?: any,
    groupPermissions?: any,
    groupId?: any,
    uploadStartCallback?: any,
    abortCallback?: any
  ) {
    const userDataString = await LocalStorage.getItem('user');
    const key = fileKey || 'file';
    const requestArgs = {
      provider: storage,
      method: 'upload',
      file,
      fileName,
      dir,
    };
    const request = Formio.pluginWait('preRequest', requestArgs).then(() => {
      return Formio.pluginGet('fileRequest', requestArgs).then(
        (result: any) => {
          if (storage && isNil(result)) {
            if (uploadStartCallback) {
              uploadStartCallback();
            }
            // Below 3 variables are not used
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const _this = this;
            const uploadRequest = function () {
              return _this
                .xhrRequest(
                  FORM_FILE_S3_URL.UPLOAD,
                  'POST',
                  _this.getHeaders(userDataString),
                  fileName,
                  {},
                  {
                    [key]: file,
                    fileName,
                    dir,
                  },
                  options,
                  progressCallback,
                  abortCallback
                )
                .then((response: any) => {
                  // Store the project and form url along with the metadata.
                  response.data = response.data || {};
                  return {
                    storage: 'url',
                    fileName,
                    url: response.url,
                    size: file.size,
                    type: file.type,
                    data: response.data,
                  };
                });
            };
            return uploadRequest();
          }
        }
      );
    });
    return Formio.pluginAlter('wrapFileRequestPromise', request, requestArgs);
  }

  deleteFile(file: any) {
    const requestArgs = {
      method: 'delete',
      file: file,
    };
    const request = Formio.pluginWait('preRequest', requestArgs).then(() => {
      return Formio.pluginGet('fileRequest', requestArgs).then(
        (result: any) => {
          // we are not performing delete
          return result || {url: ''};
        }
      );
    });

    return Formio.pluginAlter('wrapFileRequestPromise', request, requestArgs);
  }

  async getFileSignedURL(
    url: string,
    successCallback: (data: any) => void,
    errorCallback?: () => void
  ) {
    const userDataString = await LocalStorage.getItem('user');
    const headers: any = this.getHeaders(userDataString);
    return fetch(url, {
      headers,
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        successCallback(data);
      })
      .catch(() => {
        if (errorCallback) {
          errorCallback();
        }
      });
  }
  async downloadFile(file: any) {
    const requestArgs = {
      method: 'download',
      file,
    };
    const userDataString = await LocalStorage.getItem('user');
    const headers: any = this.getHeaders(userDataString);

    const request = Formio.pluginWait('preRequest', requestArgs).then(() => {
      return Formio.pluginGet('fileRequest', requestArgs).then(
        (result: any) => {
          if (file.storage && isNil(result)) {
            return fetch(file.url, {
              headers,
            })
              .then((res) => {
                return res.json();
              })
              .then((data) => {
                return {url: data.url};
              });
          } else {
            return result || {url: ''};
          }
        }
      );
    });
    return Formio.pluginAlter('wrapFileRequestPromise', request, requestArgs);
  }

  getHeaders = (userDataString: string) => {
    const userData = JSON.parse(userDataString);
    const accountUUID = this.formOptions?.accountUUID || (userData?.data.accounts.length > 0 ? userData?.data.accounts[0].uuid || '' : '');
    const userUUID = this.formOptions?.userId || userData?.data.uuid || '';
    const contactUUID = this.formOptions?.contactId || '';
    const foldToken = this.formOptions?.foldAccessToken || userData?.fold_access_token || '';
    return {
      ...(userUUID && { 'user-uuid': userUUID }),
      ...(contactUUID && { 'contact-uuid': contactUUID }),
      ...(accountUUID && {'account-uuid': accountUUID}),
      ...(foldToken && { 'fold-access-token': foldToken })
    };
  };

  xhrRequest = (
    url: any,
    method: string,
    headers: any,
    name?: any,
    query?: any,
    data?: any,
    options?: any,
    progressCallback?: any,
    abortCallback?: any
  ) => {
    return new NativePromise((resolve: any, reject: any) => {
      const xhr: any = new XMLHttpRequest();
      const json = typeof data === 'string';
      const fd = new FormData();

      if (typeof progressCallback === 'function') {
        xhr.upload.onprogress = progressCallback;
      }

      if (typeof abortCallback === 'function') {
        abortCallback(() => xhr.abort());
      }

      if (!json) {
        for (const key in data) {
          fd.append(key, data[key]);
        }
      }

      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          // Need to test if xhr.response is decoded or not.
          let respData: any = {};
          try {
            respData =
              typeof xhr.response === 'string' ? JSON.parse(xhr.response) : {};
            respData = respData && respData.data ? respData.data : respData;
          } catch (err) {
            respData = {};
          }

          // Get the url of the file.
          const respUrl = respData.hasOwnProperty('id')
            ? FORM_FILE_S3_URL.FILE_DETAIL_GET.replace(
                '#ATTACHMENT_ID',
                respData.id
              )
            : `${xhr.responseURL}/${name}`;

          resolve({url: respUrl, data: respData});
        } else {
          reject(xhr.response || 'Unable to upload file');
        }
      };

      xhr.onerror = () => reject(xhr);
      xhr.onabort = () => reject(xhr);

      let requestUrl = url + (url.indexOf('?') > -1 ? '&' : '?');
      for (const key in query) {
        requestUrl += `${key}=${query[key]}&`;
      }
      if (requestUrl[requestUrl.length - 1] === '&') {
        requestUrl = requestUrl.substr(0, requestUrl.length - 1);
      }

      xhr.open(method, requestUrl);
      if (json) {
        xhr.setRequestHeader('Content-Type', 'application/json');
      }
      if (headers) {
        Object.keys(headers).forEach((key) => {
          xhr.setRequestHeader(key, headers[key]);
        });
      }
      //Overrides previous request props
      if (options) {
        const parsedOptions =
          typeof options === 'string' ? JSON.parse(options) : options;
        for (const prop in parsedOptions) {
          xhr[prop] = parsedOptions[prop];
        }
      }
      xhr.send(json ? data : fd);
    });
  };
}
