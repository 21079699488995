import {StyleSheet} from 'react-native';
import {Colors} from '../../../../styles';

export const styles = StyleSheet.create({
  container: {
    paddingTop: 10,
    paddingBottom: 10,
    borderTopColor: Colors.Custom.BorderColor,
    borderBottomColor: Colors.Custom.BorderColor,
  },
  bottomContainer: {
    // backgroundColor: 'yellow',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 10,
  },
  linkText: {
    color: '#6569DF',
    fontWeight: 'bold',
  },
});
