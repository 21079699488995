import {Box, HStack, Image, Pressable, Text, Tooltip, VStack, View} from 'native-base';
import React from 'react';
import {useIntl} from 'react-intl';
import {styles} from './NewMessageReceivedStyle';
import {IConversationData} from '../../interfaces';
import ArrowDownSvg from '../../../../../common/Svg/ArrowDownSvg';
import {Colors} from '../../../../../../styles';
import { isWeb } from '../../../../../../utils/platformCheckUtils';
import AntIcon from 'react-native-vector-icons/AntDesign'
import { testID } from '../../../../../../testUtils';

const NewMessageReceived = (props: {
  newMessageUuids: string[];
  goToNewMessages: () => void;
}) => {
  const intl = useIntl()
  const getNewMessageReceivedElem = (): JSX.Element => {
    return (
      <HStack style={styles.newMessage}>
        <Pressable onPress={() => {
          props.goToNewMessages();
        }}
        {...testID('newMessageBtnClick')}>
          <HStack>
            <VStack justifyContent={'center'}>
              <Text {...testID('newMessage')} color={Colors.Custom.FontColorWhite}>{intl.formatMessage({
                id: 'newMessage'
              })}</Text>
            </VStack>
            <VStack justifyContent={'center'} marginTop={isWeb() ? 0.5: -1}
              style={styles.vStackStyle}
              >
              <View size={18} {...testID('image')}>
                {isWeb() ? (
                  <ArrowDownSvg customStrokeColor="white"></ArrowDownSvg>
                ) : (
                  <AntIcon
                    name="down"
                    color={'white'}
                    size={14}
                    style={{
                      marginTop: 5,
                      marginLeft: 2,
                    }}
                  />
                )}
              </View>
            </VStack>
          </HStack>
        </Pressable>
      </HStack>
    );
  };
  return (
    <>
      {props.newMessageUuids?.length > 0 && (
        <Box
          style={styles.boxStyle}
        >
          {getNewMessageReceivedElem()}
        </Box>
      )}
    </>
  );
};

export default React.memo(NewMessageReceived);
