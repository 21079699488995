import React from 'react';
import {Pressable} from 'native-base';
import Feather from 'react-native-vector-icons/Feather';
import {StyleProp, ViewStyle} from 'react-native';
import {Colors} from '../../../styles';

const CheckBox = (props: {
  isChecked: boolean;
  onChange: (value: boolean) => void;
  isDisabled?: boolean;
  style?: StyleProp<ViewStyle>;
}) => {
  const {isChecked, isDisabled, onChange} = props;
  return (
    <Pressable
      disabled={isDisabled}
      justifyContent={'center'}
      cursor={isDisabled ? 'not-allowed' : 'pointer'}
      alignItems={'center'}
      onPress={() => onChange(!isChecked)}
      h={6}
      w={6}
      rounded={'full'}
      background={
        isChecked
          ? Colors.FoldPixel.STATUS_DARK_SUCCESS
          : isDisabled
          ? Colors.FoldPixel.GRAY50
          : Colors.Custom.White
      }
      borderWidth={1}
      borderColor={
        isChecked
          ? Colors.FoldPixel.STATUS_DARK_SUCCESS
          : Colors.FoldPixel.GRAY200
      }
      style={props.style}
    >
      {isChecked && (
        <Feather name="check" size={14} color={Colors.Custom.White} />
      )}
    </Pressable>
  );
};

export default CheckBox;
