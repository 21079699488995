import React from 'react';
import { Path, Svg, RadialGradient, Circle, Defs, Stop } from 'react-native-svg';

const ClockIconForAudit = () => {
  return (
    <Svg
      width="102"
      height="102"
      fill="none"
      viewBox="0 0 102 102"
    >
      <Circle
        cx="51"
        cy="51"
        r="50"
        stroke="url(#paint0_radial_1034_70302)"
        strokeDasharray="1.46 1.46"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
      ></Circle>
      <Circle
        cx="50.667"
        cy="50.667"
        r="42.667"
        stroke="url(#paint1_radial_1034_70302)"
        strokeDasharray="1.46 1.46"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
      ></Circle>
      <Circle
        cx="51"
        cy="51"
        r="35"
        stroke="url(#paint2_radial_1034_70302)"
        strokeDasharray="1.2 1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.41"
      ></Circle>
      <Path
        stroke="#D0D5DD"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M51.75 40v11l7.333 3.667m11-3.667c0 10.125-8.208 18.333-18.333 18.333-10.125 0-18.334-8.208-18.334-18.333 0-10.125 8.209-18.333 18.334-18.333S70.083 40.875 70.083 51z"
      ></Path>
      <Defs>
        <RadialGradient
          id="paint0_radial_1034_70302"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 -72.5485 72.5485 0 51 73.608)"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#E4E7EC"></Stop>
          <Stop offset="1" stopColor="#E4E7EC" stopOpacity="0.13"></Stop>
        </RadialGradient>
        <RadialGradient
          id="paint1_radial_1034_70302"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(-90.639 59.925 9.927) scale(66.9587)"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#E4E7EC"></Stop>
          <Stop offset="1" stopColor="#E4E7EC" stopOpacity="0.35"></Stop>
        </RadialGradient>
        <RadialGradient
          id="paint2_radial_1034_70302"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(-90.639 58.542 8.196) scale(54.9271)"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#E4E7EC"></Stop>
          <Stop offset="1" stopColor="#E4E7EC" stopOpacity="0.35"></Stop>
        </RadialGradient>
      </Defs>
    </Svg>
  )
}


export default ClockIconForAudit;