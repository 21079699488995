import React from 'react';
import {isWeb} from '../../../utils/platformCheckUtils';
import {Image} from 'native-base';

const CalenderIcon = ({height = 19, width=19}) => {
  return (
    <>
      {isWeb() ? (
        <svg
          width= {width}
          height= {height}
          viewBox="0 0 19 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19 5.42857V3.39286C19 2.85295 18.7855 2.33516 18.4038 1.95339C18.022 1.57162 17.5042 1.35714 16.9643 1.35714H14.25V0.678571C14.25 0.498603 14.1785 0.326006 14.0513 0.198749C13.924 0.0714921 13.7514 0 13.5714 0C13.3915 0 13.2189 0.0714921 13.0916 0.198749C12.9643 0.326006 12.8929 0.498603 12.8929 0.678571V1.35714H6.10714V0.678571C6.10714 0.498603 6.03565 0.326006 5.90839 0.198749C5.78114 0.0714921 5.60854 0 5.42857 0C5.2486 0 5.07601 0.0714921 4.94875 0.198749C4.82149 0.326006 4.75 0.498603 4.75 0.678571V1.35714H2.03571C1.49581 1.35714 0.978017 1.57162 0.596247 1.95339C0.214477 2.33516 0 2.85295 0 3.39286V5.42857H19Z"
            fill="#98A2B3"
          />
          <path
            d="M0 6.78571V16.9643C0 17.5042 0.214477 18.022 0.596247 18.4038C0.978017 18.7855 1.49581 19 2.03571 19H16.9643C17.5042 19 18.022 18.7855 18.4038 18.4038C18.7855 18.022 19 17.5042 19 16.9643V6.78571H0ZM13.3339 10.6936L8.58393 14.765C8.45409 14.876 8.28717 14.934 8.11648 14.9273C7.94579 14.9207 7.78389 14.8499 7.66311 14.7291L5.62739 12.6934C5.50379 12.5654 5.43539 12.394 5.43694 12.216C5.43848 12.0381 5.50985 11.8679 5.63566 11.7421C5.76147 11.6163 5.93167 11.5449 6.10959 11.5434C6.2875 11.5419 6.45891 11.6102 6.58689 11.7339L8.17882 13.3258L12.4538 9.6615C12.5211 9.60086 12.5998 9.5543 12.6854 9.52457C12.771 9.49484 12.8616 9.48253 12.952 9.48839C13.0424 9.49424 13.1307 9.51814 13.2117 9.55866C13.2927 9.59918 13.3648 9.65551 13.4237 9.72433C13.4826 9.79314 13.5271 9.87305 13.5546 9.95935C13.5821 10.0456 13.5921 10.1366 13.5839 10.2268C13.5758 10.317 13.5496 10.4047 13.507 10.4846C13.4644 10.5645 13.4063 10.6351 13.336 10.6923L13.3339 10.6936Z"
            fill="#98A2B3"
          />
        </svg>
      ) : (
        <Image
          resizeMode="contain"
          size={'24px'}
          source={require('../../../assets/images/Contact/upcomingAppointments.png')}
          alt="image"
        />
      )}
    </>
  );
};

export default CalenderIcon;
