import {View} from 'native-base';
import {Colors} from '../../../styles';
import {styles} from './styles';

export const PageBodyContainer = (props: any) => {
  return (
    <View style={[styles.mainContainer,props.style]}>
      {props.children}
    </View>
  );
};

export default PageBodyContainer;
