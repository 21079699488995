import {useQuery} from '@apollo/client';
import MDEditor from '@uiw/react-md-editor';
import {HStack, Pressable, Skeleton, Text, View, VStack} from 'native-base';
import React from 'react';
import {COMMON_ACTION_CODES} from '../../../constants/ActionConst';
import ConversationsQueries from '../../../services/Conversations/ConversationsQueries';
import {Colors} from '../../../styles';
import {getAccountId} from '../../../utils/commonUtils';
import {getFormattedDateString} from '../../PersonOmniView/LeftContainer/OtherDetails/OtherDeatilsUtils';
import {DisplayCardAvatar} from '../DisplayCard/DisplayCardAvatar';
import {EmptyStateSvg} from '../Svg';
import {styles} from './PrivateMessageViewStyles';

const PrivateMessageListView = (props: any) => {
  const accountId = getAccountId();
  const conversationId = props.conversationData;
  const {data, loading, refetch} = useQuery(
    ConversationsQueries.GetPrivateMessage,
    {
      variables: {
        accountId: accountId,
        conversationId: conversationId,
      },
    }
  );
  refetch();
  const message = data ? data.messages : [];

  return (
    <>
      {loading ? (
        <View padding={3}>
          <Skeleton.Text lines={3} />
        </View>
      ) : (
        <>
          {message && message.length > 0 ? (
            message?.map((item: any, index: any) => {
              return (
                <Pressable
                  _hover={{
                    bg: Colors.Custom.HoverBgColor,
                  }}
                  key={index}
                  onPress={() => {
                    props.onMessageListActionPerform(
                      COMMON_ACTION_CODES.ROW_CLICKED,
                      item
                    );
                  }}
                  style={{
                    borderTopWidth: index != 0 ? 1 : 0,
                    borderTopColor: Colors.Custom.Gray200,
                  }}
                >
                  <HStack
                    style={[styles.displayListItem]}
                    flex={1}
                    justifyContent={'center'}
                  >
                    <DisplayCardAvatar
                      avatarStyle={{
                        avatarSize: '12',
                      }}
                      isLetterAvatarShow
                      userData={{
                        userId: item?.senderId,
                        userType: item?.userType,
                        genderCode: item?.genderCode as any,
                        userName: item?.senderUser?.name
                      }}
                      groupType={item?.groupType as any}
                    />
                    <VStack
                      flex={1}
                      justifyContent={'center'}
                      style={{
                        marginLeft: 10,
                      }}
                    >
                      <Text isTruncated maxW={300}>
                        {/* {item.content.replace(/\n/g, '')} */}
                        <div data-color-mode="light">
                          <MDEditor.Markdown source={item.content} />
                        </div>
                      </Text>
                      <View my={1} flexDirection={'row'}>
                        <Text size={'xsMedium'} color={Colors.Custom.Gray400}>
                          {item.senderUser?.name},
                        </Text>
                        <Text
                          size={'xsMedium'}
                          color={Colors.Custom.Gray400}
                          style={{marginLeft: 5, fontStyle: 'italic'}}
                        >
                          {getFormattedDateString(item.createdAt, 'dob')}
                        </Text>
                      </View>
                    </VStack>
                  </HStack>
                </Pressable>
              );
            })
          ) : (
            <EmptyStateSvg titleId="noprivateMessage" />
          )}
        </>
      )}
    </>
  );
};

export default PrivateMessageListView;
