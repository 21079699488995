import React from 'react';
import {IContinueWithExistingConversationPopupProps} from './interface';
import {Button, Text} from 'native-base';
import {SMS_INBOX_ACTION_CODES} from '../../../Integrations/IntegrationCreate/SmsInboxCreate/SmsInboxConst';
import ModalActionBtn from '../../../../../common/ModalActionBtn/ModalActionBtn';
import {BUTTON_TYPE} from '../../../../../../constants/StringConst';
import {useIntl} from 'react-intl';
import {Modal} from 'antd';
import {FoldButton} from '../../../../../CommonComponents/FoldButton/FoldButton';

const ContinueWithExistingConversationPopup = (
  props: IContinueWithExistingConversationPopupProps
) => {
  const intl = useIntl();
  const {isOpen, onActionPerformed} = props;
  const onCloseModal = () => {
    onActionPerformed(SMS_INBOX_ACTION_CODES.MODAL_CLOSE);
  };
  const continueWithTheConversation = () => {
    onActionPerformed(SMS_INBOX_ACTION_CODES.CONTINUE_WITH_THE_CONVERSATION);
  };
  return (
    <>
      <Modal
        visible={isOpen}
        title={
          <Text fontSize={'lg'}>
            {intl.formatMessage({id: 'existingConversation'})}
          </Text>
        }
        onCancel={onCloseModal}
        footer={[
          <Button.Group
            mx={{
              base: 'auto',
              md: 0,
            }}
          >
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                onPress: () => {
                  continueWithTheConversation();
                },
                style: {marginLeft: 10},
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'continue',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.SECONDARY,
                onPress: () => {
                  onCloseModal();
                },
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'cancel',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
          </Button.Group>,
        ]}
      >
        <Text>{intl.formatMessage({id: 'continueWithTheConversation'})}</Text>
      </Modal>
    </>
  );
};

export default ContinueWithExistingConversationPopup;
