import moment from 'moment';
import {
  CLOUD_TELEPHONY_CODE,
  CONVERSATION_CONST,
  CONVERSATION_STATUS,
  GROUP_MEMBER_TYPE,
  GROUP_TYPES,
} from '../../../../../../constants/StringConst';
import {IGroupContact} from '../../../../../../services/Conversations/interfaces';
import { isBirthSexPrimary } from '../../../../../../utils/capabilityUtils';
import {getGenderTextByGenderCode, getPrimaryGenderValue, getUserOnlineStatus, showInfoOnMessageHeader} from '../../../../../../utils/commonUtils';
import {capitalizeText} from '../../../../../common/ContactRelationView/ContactRelationUtils';
import {CHANNEL_TYPE, CHANNEL_TYPE_CODE, CONVERSATION_TAB_CODES} from '../../ConversationConst';
import {IContact, IConversationData, MESSAGE_TYPE} from '../../interfaces';
import {
  getContactDataAndType,
  getPatientGroupMemberData,
  isBroadCastGroup,
} from '../../MessagingWindow/MessagingUtils';
import { Colors } from '../../../../../../styles/Colors';

export const getAvatarFullName = (
  singleConversationData: IConversationData,
  isNameOnly = false,
  selectedTabCode?: string,
  contactData?: IContact
): string => {
  const showInfoOnHeaderData = showInfoOnMessageHeader();
  let name = '';
  const patientData = getPatientGroupMemberData(
    singleConversationData?.groupConversation?.groupMembers || []
  ) || singleConversationData?.conversationContact || [];
  const choosenName =
  !isBroadCastGroup(singleConversationData as IConversationData) &&
    showInfoOnHeaderData?.SHOW_CHOSEN_NAME && contactData?.person?.chosenName?.length
      ? ' (' + contactData?.person?.chosenName + ')'
      : '';
  if (
    singleConversationData?.groupConversation?.groupType?.code ===
    GROUP_TYPES?.PRIVATE
  ) {
    const patientContact = patientData?.contact?.person || ({} as any);
    const age = moment().diff(patientContact?.birthDate, 'years');
    const gender = getGenderTextByGenderCode(patientData?.contact);
    if (isNameOnly) {
      name =
        (patientData?.contact?.name || '') + choosenName;
      name = name;
    } else {
      if (age && gender) {
        name = `${age}Y  |  ${gender}`;
      } else if (age) {
        name = age + 'Y';
      } else {
        name = gender;
      }
    }
  } else if (singleConversationData?.groupConversation?.id) {
    const contactInfo: any = getContactDataAndType(singleConversationData);
    const contactData: IGroupContact = contactInfo.contactData;
    name =
      singleConversationData?.groupConversation?.name ||
      contactData?.name + choosenName ||
      CONVERSATION_CONST.GROUP_CHAT;
    name = name;
  } else {
    name = singleConversationData?.conversationContact?.name;
    name = name;
  }
  if (selectedTabCode == 'ALL' || selectedTabCode == 'RECENT') {
    name = `${patientData?.contact?.name + choosenName} | ${name}`;
  }
  return name;
};
export const isPrivateGroup = (singleConversationData: IConversationData) => {
  return (
    singleConversationData?.groupConversation?.groupType?.code ===
    GROUP_TYPES.PRIVATE
  );
};



export const isContactOnline = (
  singleConversationData: IConversationData
): boolean => {
  let isOnline = false;
  let contactId = 0;

  if (singleConversationData?.groupConversation?.id) {
    if (isPrivateGroup(singleConversationData)) {
      const patientData = getPatientGroupMemberData(
        singleConversationData?.groupConversation?.groupMembers || []
      )|| singleConversationData?.conversationContact || [];
      if (patientData.contact?.id) {
        contactId = patientData.contact?.id;
      }
    }
  } else if (
    singleConversationData &&
    singleConversationData.conversationContact
  ) {
    contactId = singleConversationData.conversationContact.id;
  }
  if (contactId) {
    isOnline = getUserOnlineStatus(contactId, GROUP_MEMBER_TYPE.CONTACT as any);
  }
  return isOnline;
};

export const getAssignedUserName = (
  singleConversationData: IConversationData,
  selectedTabCode: string
): string => {
  if (singleConversationData?.groupConversation?.id) {
    return '';
  }
  const name = singleConversationData?.assignedUser?.name || '';
  return '';
  if (selectedTabCode !== CONVERSATION_TAB_CODES.MINE) {
    return name ? '@' + name : '';
  }
  return '';
};

export const getConversationTypeImgSrc = (
  singleConversationData: IConversationData,
): string => {
  let channelType;
  if (singleConversationData?.conversationInbox?.channelType) {
    const type = singleConversationData?.conversationInbox?.channelType || '';
    if (type === CHANNEL_TYPE.CHANNEL_EMAIL) {
      channelType = require('../../../../../../assets/images/email.png');
    } else if (type === CHANNEL_TYPE.CHANNEL_TWILIO_SMS) {
      channelType = require('../../../../../../assets/images/sms.png');
    } else {
      channelType = require('../../../../../../assets/images/message.png');
    }
  } else {
    channelType = require('../../../../../../assets/images/message.png');
  }
  return channelType;
};

export const getSmsConversationLastCallTypeCode = (
  singleConversationData: IConversationData
) => {
  const isIncomingTypeMessage =
    singleConversationData?.conversationMessages[0]?.messageType ==
      MESSAGE_TYPE.incoming_completed_call;
  const isOutgoingTypeMessage =
    singleConversationData?.conversationMessages[0]?.messageType ==
      MESSAGE_TYPE.outgoing_completed_call ||
      singleConversationData?.conversationMessages[0]?.messageType ==
      MESSAGE_TYPE.outgoing_missed_call;
  const isMissedCallTypeMessage =
    singleConversationData?.conversationMessages[0]?.messageType ==
      MESSAGE_TYPE.incoming_missed_call
  const lastMessageActivityCode = isIncomingTypeMessage
    ? CLOUD_TELEPHONY_CODE.INCOMING
    : isOutgoingTypeMessage
    ? CLOUD_TELEPHONY_CODE.OUTGOING
    : isMissedCallTypeMessage
    ? CLOUD_TELEPHONY_CODE.MISSED_CALL
    : '';

  return lastMessageActivityCode;
};

export const getRemovedConversationIndicatorStrStyle = (
  singleConversationData: IConversationData,
  channelType: string,
  currentUserId: string | number,
) => {
  let conversationStyle = {} as any;
  switch (channelType) {
    case CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_ME:
      if (singleConversationData?.assignedUser?.id !== currentUserId) {
        conversationStyle = {
          color: Colors.Custom.brandAccentColor,
          fontWeight: 'bold',
        };
      }
      break;

    case CHANNEL_TYPE_CODE.CHANNEL_UNASSIGNED: {
      if (singleConversationData?.assignedUser?.uuid) {
        conversationStyle = {
          color: Colors.Custom.brandAccentColor,
          fontWeight: 'bold',
        };
      } else if (singleConversationData?.status === CONVERSATION_STATUS.RESOLVED && singleConversationData?.conversationMessages[0]?.messageType === 2) {
        conversationStyle = {
          color: Colors.Custom.brandAccentColor,
          fontWeight: 'bold',
        };
      }
      break;
    }
    case CHANNEL_TYPE_CODE.CHANNEL_ARCHIVED: {
      if (singleConversationData?.status === CONVERSATION_STATUS.OPEN && singleConversationData?.conversationMessages[0]?.messageType === 2) {
        conversationStyle = {
          color: Colors.Custom.brandAccentColor,
          fontWeight: 'bold',
        };
      }
      break;
    }
    case CHANNEL_TYPE_CODE.CHANNEL_MISSED_CALLS: {
      if (singleConversationData?.status === CONVERSATION_STATUS.RESOLVED) {
        conversationStyle = {
          color: Colors.Custom.brandAccentColor,
          fontWeight: 'bold',
        };
      }
      break;
    }
    case CHANNEL_TYPE_CODE.CHANNEL_ALL:
    case CHANNEL_TYPE_CODE.CHANNEL_WEB_WIDGET:
    case CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS:
    case CHANNEL_TYPE_CODE.CHANNEL_EMAIL: 
    case CHANNEL_TYPE_CODE.CHANNEL_E_FAX: {
      if (singleConversationData?.status === CONVERSATION_STATUS.RESOLVED && singleConversationData?.conversationMessages[0]?.messageType === 2) {
        conversationStyle = {
          color: Colors.Custom.brandAccentColor,
          fontWeight: 'bold',
        };
      }
      break;
    }
  }
  return conversationStyle;
};

export const getRemovedConversationIndicatorStr = (
  singleConversationData: IConversationData,
  selectedInboxTypeCode?: string,
  userId?: string | number
) => {
  if (singleConversationData?.additionalAttributes?.mail_subject) {
    switch (selectedInboxTypeCode) {
      case CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_ME:
        if (
          singleConversationData?.conversationMessages?.[0]?.contentAttributes
            ?.assigneeToUserData?.id !== userId ||
          singleConversationData?.status === CONVERSATION_STATUS.RESOLVED
        ) {
          return (
            singleConversationData?.conversationMessages[0]?.content?.trim() ||
            ''
          );
        }
        break;

      case CHANNEL_TYPE_CODE.CHANNEL_UNASSIGNED:
        if (
          singleConversationData?.conversationMessages?.[0]?.contentAttributes
            ?.assigneeToUserData?.id ||
          singleConversationData?.status === CONVERSATION_STATUS.RESOLVED
        ) {
          return (
            singleConversationData?.conversationMessages[0]?.content?.trim() ||
            ''
          );
        }
        break;

      case CHANNEL_TYPE_CODE.CHANNEL_ARCHIVED:
        if (
          singleConversationData?.status === CONVERSATION_STATUS.OPEN ||
          singleConversationData?.conversationMessages?.[0]?.status ===
            CONVERSATION_STATUS.OPEN
        ) {
          if (
            singleConversationData?.conversationMessages?.[0]?.messageType === 2
          ) {
            return (
              singleConversationData?.conversationMessages[0]?.content?.trim() ||
              ''
            );
          }
        }
        break;

      case CHANNEL_TYPE_CODE.CHANNEL_ALL:
      case CHANNEL_TYPE_CODE.CHANNEL_EMAIL:
        if (
          singleConversationData?.status === CONVERSATION_STATUS.RESOLVED &&
          singleConversationData?.conversationMessages?.[0]?.messageType === 2
        ) {
          return (
            singleConversationData?.conversationMessages[0]?.content?.trim() ||
            ''
          );
        }
        break;
    }
    return singleConversationData.additionalAttributes.mail_subject;
  }
  return singleConversationData?.conversationMessages[0]?.content?.trim() || '';
};