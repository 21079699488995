import React from 'react';
import ReactDOM from 'react-dom';
import {ReactComponent} from '@foldhealth/formio-react';
import {Formio} from '@foldhealth/formiojs';
import CustomWrapper from '../CustomWrapper/CustomWrapper';
import KeyField from '../../EditFormFields/KeyField';
import ConditionalFields from '../../EditFormFields/ConditionalFields';
import CustomClassComponent from '../CustomWrapper/CustomClassComponent';
import QuillConfig from '../../Builder/QuillConfig';
import Rating from './Rating';
import SearchableComponentFields from '../../EditFormFields/SearchableComponentFields';
import { DEFAULT_RATING_SCALE, RATING_ELEMENTS, RatingType } from './Helper';
import { DEFAULT_COLOR } from '../ColorPicker/Helper';
import ShareWithPatientFields from '../../EditFormFields/ShareWithPatientFields';

export default class RatingComponent extends CustomClassComponent {
  static get builderInfo() {
    return {
      title: 'Rating',
      group: 'advanced',
      documentation: '',
      ratingType: RatingType.star,
      ratingScale: DEFAULT_RATING_SCALE,
      showRatingValue: true,
      leftKey: 'Not likely at all',
      rightKey: 'Extremely likely',
      usePlainColor: false,
      fillColor: DEFAULT_COLOR,
      schema: RatingComponent.schema(),
    };
  }

  labelIsHidden() {
    return false;
  }

  static schema() {
    return ReactComponent.schema({
      type: 'rating',
      label: 'Rating',
      key: 'rating'
    });
  }

  attachReact(element: any) {
    return ReactDOM.render(
      <CustomWrapper formOptionData={this.options}>
        <Rating
          options={this.options}
          disabled={this.disabled}
          component={this.component}
          validateRef={this.validateRef}
          onChange={this.updateValue}
          setValueRef={this.setValueRef}
        />
      </CustomWrapper>,
      element
    );
  }

  static editForm = function () {
    return {
      key: 'display',
      components: [
        {
          type: 'oldtextfield',
          key: 'label',
          label: 'Label',
          input: true,
          validate: {
            required: true,
          },
        },
        {
          type: 'checkbox',
          key: 'validate.required',
          label: 'Is this field required?',
          input: true,
        },
        ...SearchableComponentFields,
        ...ShareWithPatientFields,
        {
          type: 'textarea',
          input: true,
          key: 'description',
          label: 'Description',
          placeholder: 'Description for this field.',
          tooltip: 'The description is text that will appear below the input field.',
          editor: 'quill',
          wysiwyg: QuillConfig,
        },
        {
          type: 'customSelect',
          input: true,
          label: 'Rating Elements',
          key: 'ratingType',
          dataSrc: 'values',
          defaultValue: RatingType.star,
          data: {
            values: RATING_ELEMENTS,
          },
        },
        {
          type: 'checkbox',
          key: 'showRatingValue',
          label: 'Show rating scale',
          input: true,
          defaultValue: true,
          customConditional: function customConditional(context: any) {
            return context?.data?.ratingType !== 'nps';
          },
        },
        {
          type: 'select',
          input: true,
          label: 'Rating Scale',
          key: 'ratingScale',
          dataSrc: 'values',
          defaultValue: '10',
          clearOnHide: false,
          data: {
            values: [
              {label: '1', value: 1},
              {label: '2', value: 2},
              {label: '3', value: 3},
              {label: '4', value: 4},
              {label: '5', value: 5},
              {label: '6', value: 6},
              {label: '7', value: 7},
              {label: '8', value: 8},
              {label: '9', value: 9},
              {label: '10', value: 10},
            ],
          },
          customConditional: function customConditional(context: any) {
            return context?.data?.ratingType !== 'nps';
          },
        },
        {
          type: 'select',
          input: true,
          label: 'Values',
          key: 'values',
          hidden: true,
          clearOnHide: false,
          calculateValue: function calculateValue(context: any) {
            const isNPSScale = context?.data?.ratingType === RatingType.nps;
            const scale = isNPSScale ? 11 : (context?.data?.ratingScale || 10);
            const list = [...Array(scale).keys()];
            const existingOptions = context?.data?.values || [];
            const suffix = RATING_ELEMENTS.find(item => item.value === context?.data?.ratingType)?.label;
            const finalList = list.map((item) => {
              const value = isNPSScale ? item : (item + 1);
              const option = existingOptions.find((item: any) => `${item.value}` === `${value}`);
              return {
                ...option,
                ...((!option?.riskScore && option?.riskScore !== 0) && { riskScore: value }),
                ...((!option?.groupRiskScore && option?.groupRiskScore !== 0) && { groupRiskScore: value }),
                label: `${value}${suffix ? ` ${suffix}` : ''}`,
                value: `${value}`,
              }
            });
            return finalList;
          },
        },
        {
          type: 'checkbox',
          key: 'usePlainColor',
          label: 'Remove Colors',
          input: true,
          defaultValue: false,
          clearOnHide: false,
          customConditional: function customConditional(context: any) {
            return context?.data?.ratingType === 'nps';
          },
        },
        {
          type: 'colorPicker',
          key: 'fillColor',
          label: 'Fill Color',
          input: true,
          clearOnHide: false,
          customConditional: function customConditional(context: any) {
            if (context?.data?.ratingType === 'nps') {
              return context?.data?.usePlainColor;
            }
            return true;
          },
        },
        {
          type: 'oldtextfield',
          key: 'leftKey',
          label: 'Left Key',
          input: true,
          defaultValue: 'Not likely at all',
          clearOnHide: false,
          customConditional: function customConditional(context: any) {
            return context?.data?.ratingType === 'nps';
          },
        },
        {
          type: 'oldtextfield',
          key: 'rightKey',
          label: 'Right Key',
          input: true,
          clearOnHide: false,
          defaultValue: 'Extremely likely',
          customConditional: function customConditional(context: any) {
            return context?.data?.ratingType === 'nps';
          },
        },
        ...ConditionalFields,
        ...KeyField,
      ],
    };
  };
}

Formio.Components.addComponent('rating', RatingComponent);
