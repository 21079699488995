import {StyleSheet} from 'react-native';
import {Colors} from '../../../styles/Colors';
import { isWeb } from '../../../utils/platformCheckUtils';
export const styles = StyleSheet.create({
  actionMsgBox: {
    color: Colors.Custom.Gray200,
    // marginTop: 10,
    marginBottom: 4,
    paddingVertical: isWeb() ? 4 : 6,
    paddingHorizontal:  isWeb() ? 8 : 12,
    position: 'relative',
  },
  txtColor: {
    color: Colors.Custom.TextColor,
  },
});
