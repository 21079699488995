import React from 'react';
import {HStack, Icon, Text} from 'native-base';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { Colors } from '../../../../../../styles';

const AllowOverrideMessage = () => {
  const color = Colors.Custom.Gray500;
  return (
    <HStack my={2}>
      <Icon
        as={AntIcon}
        my={1}
        name="infocirlceo"
        size="smMedium"
        mr={2}
        color={color}
      />
      <Text fontSize="sm" color={color}>
      Select ‘Keep fields editable for member' to enable editing of empty fields
      </Text>
    </HStack>
  );
};

export default AllowOverrideMessage;
