import {HStack, View, Text, Select, Input, VStack} from 'native-base';
import {useContext, useState} from 'react';
import {Colors} from '../../../../styles/Colors';
import {
  RichTextEditor,
  TEXT_ONLY_MODULES,
} from '../../RichTextEditor/RichTextEditor';
import {Select as AntSelect} from 'antd';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {getMlovListFromCategory} from '../../../../utils/mlovUtils';
import {MLOV_CATEGORY} from '../../../../constants/MlovConst';
const {Option} = AntSelect;

export interface IPrescriptionOrderTaskFieldProps {
  orderActionCondition?: any;
  isShowError?: boolean;
  taskInfo?: any;
  onChange: (actionData?: any) => void;
}

export interface IPrescriptionOrderTaskDetails {
  title: string;
  description: string;
  priorityId: string;
}

export const PrescriptionOrderTaskField = (
  props: IPrescriptionOrderTaskFieldProps,
) => {
  const {orderActionCondition, taskInfo, isShowError} = props;
  const {title, description, userId, priorityId} = taskInfo || {};

  const [orderAction, setOrderAction] = useState<string>(
    orderActionCondition || 'PRESCRIPTION_NEW',
  );
  const [taskInfoField, setTaskInfoField] =
    useState<IPrescriptionOrderTaskDetails>({
      description: description || '',
      title: title || '',
      priorityId: priorityId || '',
    });

  const mlovData = useContext(CommonDataContext);
  const taskPriority =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.TASK_PRIORITY,
    ) || [];

  const prescriptionOrderAction = [
    {
      value: 'PRESCRIPTION_NEW',
      displayName: 'New Prescription',
      id: 'PRESCRIPTION_NEW',
    },
    {
      value: 'PRESCRIPTION_RENEWAL',
      displayName: 'Prescription Renewal',
      id: 'PRESCRIPTION_RENEWAL',
    },
  ];

  function onChangeHandler(orderAction?: string, title?: string, description?: string, priorityId?: string) {
    if (!orderAction || !title || !priorityId) {
      return props.onChange('');
    }
    const value = {
      orderActionCondition: orderAction,
      taskInfo: {
        title,
        description,
        priorityId
      }
    };
    return props.onChange(value);
  }

  return (
    <>
      <View style={{marginTop: 18}}>
        <HStack>
          <HStack flex={2.4}>
            <Text fontWeight={300} fontSize={16} flex={2} marginTop={1}>
              {'Action'}
              {<Text color="error.500">*</Text>}
            </Text>
          </HStack>
          <HStack flex={7}>
            <VStack marginY={1} flex={1}>
              <AntSelect
                status={isShowError && !orderAction ? 'error' : ''}
                onClear={() => {
                  setOrderAction('');
                }}
                value={orderAction}
                showSearch={true}
                filterOption={(input, option: any) => {
                  return (
                    option?.title.toLowerCase().indexOf(input.toLowerCase()) >=
                      0 ||
                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  );
                }}
                onChange={itemValue => {
                  setOrderAction(itemValue);
                  onChangeHandler(itemValue, taskInfoField.title, taskInfoField.description, taskInfoField.priorityId);
                }}>
                {prescriptionOrderAction.map(data => {
                  return (
                    <Option
                      value={data.value}
                      title={data.displayName}
                      key={data.value}>
                      {data.displayName}
                    </Option>
                  );
                })}
              </AntSelect>
              <Text marginTop={2} fontStyle="italic">{`Order closure in underlying EHR will automatically mark the task as done.`}</Text>
            </VStack>
          </HStack>
        </HStack>
      </View>
      {orderAction && (
        <>
          <View style={{marginTop: 18}}>
            <HStack>
              <HStack flex={2.4}>
                <Text fontWeight={300} fontSize={16} flex={2} marginTop={1}>
                  {'Title'}
                  {<Text color="error.500">*</Text>}
                </Text>
              </HStack>
              <HStack flex={7}>
                <View marginY={1} flex={1}>
                  <Input
                    isInvalid={isShowError && !taskInfoField.title}
                    _focus={{
                      borderColor: Colors.Custom.Gray200,
                    }}
                    style={{height: 36}}
                    value={taskInfoField.title}
                    onChangeText={(value: any) => {
                      setTaskInfoField(prev => {
                        return {
                          ...prev,
                          title: value,
                        };
                      });
                      onChangeHandler(orderAction, value, taskInfoField.description, taskInfoField.priorityId);
                    }}
                    onBlur={(event: any) => {
                      setTaskInfoField(prev => {
                        return {
                          ...prev,
                          title: event?.target?.value,
                        };
                      });
                      onChangeHandler(orderAction, event?.target?.value, taskInfoField.description, taskInfoField.priorityId);
                    }}
                  />
                </View>
              </HStack>
            </HStack>
          </View>
          <View style={{marginTop: 18}}>
            <HStack>
              <HStack flex={2.4}>
                <Text fontWeight={300} fontSize={16} flex={2} marginTop={1}>
                  {'Description'}
                </Text>
              </HStack>
              <HStack flex={7}>
                <View marginY={1} flex={1}>
                  <RichTextEditor
                    modules={TEXT_ONLY_MODULES}
                    valueStr={taskInfoField.description}
                    onChangesValue={(value: any) => {
                      setTaskInfoField(prev => {
                        return {
                          ...prev,
                          description: value,
                        };
                      });
                      onChangeHandler(orderAction, taskInfoField.title, value, taskInfoField.priorityId);
                    }}
                  />
                </View>
              </HStack>
            </HStack>
          </View>
          <View style={{marginTop: 18}}>
            <HStack>
              <HStack flex={2.4}>
                <Text fontWeight={300} fontSize={16} flex={2} marginTop={1}>
                  {'Task Priority'}
                  {<Text color="error.500">*</Text>}
                </Text>
              </HStack>
              <HStack flex={7}>
                <VStack marginY={1} flex={1}>
                  <Select
                    height={'36px'}
                    fontSize={'13px'}
                    selectedValue={taskInfoField.priorityId}
                    onValueChange={itemValue => {
                      setTaskInfoField(prev => {
                        return {
                          ...prev,
                          priorityId: itemValue,
                        };
                      });
                      onChangeHandler(orderAction, taskInfoField.title, taskInfoField.description, itemValue);
                    }}>
                    {taskPriority?.length &&
                      taskPriority.map((data: any) => {
                        return (
                          <Select.Item
                            key={data.id}
                            label={data.value}
                            value={data.id}
                          />
                        );
                      })}
                  </Select>
                  <Text marginTop={2} fontStyle="italic">{`Order marked as urgent in underlying EHR will override the task priority to High.`}</Text>
                </VStack>
              </HStack>
            </HStack>
          </View>
        </>
      )}
    </>
  );
};
