import React from 'react';
import {Path, Svg} from 'react-native-svg';
import { Colors } from '../../../styles/Colors';

const ThunderSvg = () => {
  return (
    <Svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <Path
        d="M9.51243 4.76359C10.38 3.07274 10.8138 2.22732 10.7476 1.92884C10.6635 1.55038 10.3261 1.30277 9.94004 1.33632C9.6356 1.36277 8.96013 2.03086 7.60917 3.36704L4.51435 6.42801C3.68793 7.24538 3.27473 7.65407 3.34024 8.0907C3.40576 8.52733 3.91077 8.73045 4.92079 9.1367L5.92354 9.54003C6.29626 9.68995 6.48262 9.7649 6.5963 9.88934C6.74085 10.0476 6.81261 10.2632 6.79529 10.4874C6.78168 10.6638 6.68375 10.8546 6.4879 11.2363C5.62029 12.9272 5.18648 13.7726 5.25277 14.0711C5.33682 14.4495 5.67427 14.6971 6.06029 14.6636C6.36472 14.6371 7.0402 13.969 8.39116 12.6329L11.486 9.57189C12.3124 8.75451 12.7256 8.34583 12.6601 7.9092C12.5946 7.47257 12.0896 7.26945 11.0795 6.8632L10.0768 6.45987C9.70407 6.30995 9.51771 6.235 9.40402 6.11056C9.25948 5.95234 9.18772 5.73665 9.20503 5.51245C9.21865 5.33612 9.31658 5.14527 9.51243 4.76359Z"
        stroke={Colors.FoldPixel.GRAY300}
        stroke-linecap="round"
      />
    </Svg>
  );
};

export default ThunderSvg;
