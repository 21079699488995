import React from "react";
import { Colors } from "../../../../styles/Colors";
import { ICommonSvgProps } from "../interfaces";

const FavouriteSvg = (props: ICommonSvgProps) => {
    const strokeColor = props.customStrokeColor ? props.customStrokeColor : Colors.Custom.Gray500;
    return (
        <svg width="inherit" height="inherit" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 2.5L15.09 8.76L22 9.77L17 14.64L18.18 21.52L12 18.27L5.82 21.52L7 14.64L2 9.77L8.91 8.76L12 2.5Z" stroke={strokeColor} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
}

export default FavouriteSvg;
