import React, {useContext} from 'react';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {
  getMlovIdFromCode,
  getMlovListFromCategory,
} from '../../../utils/mlovUtils';
import {MLOV_CATEGORY} from '../../../constants';
import {OUTREACH_STATUS_CODES} from '../../../constants/MlovConst';

const useOutreachStatus = () => {
  const context = useContext(CommonDataContext);

  const OutreachStatusList = getMlovListFromCategory(
    context.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.CARE_PROGRAM_OUTREACH_STATUS
  );

  const status = {
    pending: getMlovIdFromCode(
      OutreachStatusList,
      OUTREACH_STATUS_CODES.PENDING
    ),
    failed: getMlovIdFromCode(OutreachStatusList, OUTREACH_STATUS_CODES.FAILED),
    success: getMlovIdFromCode(
      OutreachStatusList,
      OUTREACH_STATUS_CODES.SUCCESS
    ),
  };

  return status;
};

export default useOutreachStatus;
