import React from "react";

const StartPreferredicon = () => {
    return (
        <svg width="inherit" height="inherit" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.00002 0.666668L5.03002 2.75333L7.33335 3.09L5.66669 4.71334L6.06002 7.00667L4.00002 5.92334L1.94002 7.00667L2.33335 4.71334L0.666687 3.09L2.97002 2.75333L4.00002 0.666668Z" fill="#FFCA0D" />
        </svg>
    );
}

export default StartPreferredicon;
