import React from 'react'
import { View } from 'react-native'
import SearchContactEmptyState from '../../../../../../../../assets/svg/SearchContactEmptyState'
import { Text, VStack } from 'native-base'
import { Colors } from '../../../../../../../../styles';

function InviteMemberEmptyState(props: {title: string}) {
  const {title} = props;
  return (
    <VStack w='full' justifyContent={'center'} alignItems={'center'} flex={1}>
      <SearchContactEmptyState />
      <Text color={Colors.Custom.Gray400} mt={2}>{title}</Text>
    </VStack>
  );
}

export default InviteMemberEmptyState