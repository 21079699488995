import {VStack, Alert, HStack, Text, Skeleton, Divider, Pressable} from 'native-base';
import React, {useEffect, useState} from 'react';
import {getCustomEHRAttributes} from '../../../../../../../../services/CommonService/AidBoxService';
import {
  ICustomEHRAttributesComponentState,
  ICustomEHRAttributesProps,
  IEhrFieldList,
} from './interface';
import {ModalActionSubTitle} from '../../../../../../../common/ModalActionTitle/ModalActionSubTitle';
import {
  formatCustomEhrFieldsList,
  getCustomEHRAttributesFromPatientData,
  getFieldElementByType,
} from './helper';
import {DisplayText} from '../../../../../../../common/DisplayText/DisplayText';
import {Colors} from '../../../../../../../../styles';
import { useIntl} from 'react-intl';
import { default as AntIcon } from 'react-native-vector-icons/AntDesign';

const CustomEHRAttributes = (props: ICustomEHRAttributesProps) => {
  const intl = useIntl();
  const {prevResponse, updateEhrAttributes,leadId} = props;
  const ehrAttributeFieldResponseList =
    getCustomEHRAttributesFromPatientData(prevResponse);
  const [componentState, setComponentState] =
    useState<ICustomEHRAttributesComponentState>({
      isLoading: true,
      error: false,
      fieldList: [],
      emptyFieldList: [],
      filledFieldList: []
    });
  const [customFieldOpen,setCustomFieldOpen] = useState(false)
  const [showFilledCustomField,setShowFilledCustomField] = useState(false)
  const [showEhrTitle, setShowEhrTitle] = useState(false)
  const handleCustomEHRAttributesResponse = (response: any) => {
    const formmattedList = formatCustomEhrFieldsList(
      response.data?.expansion?.contains,
      ehrAttributeFieldResponseList
    );
    const tempEmptyFieldList: any = [];
    const tempFilledFieldList: any = []; 
    formmattedList.forEach(
      (field) => {   
        if (field?.value) {
          tempFilledFieldList.push(field);
        } else {
          tempEmptyFieldList.push(field);
        }
      }
    );
    setComponentState((prev) => ({
      ...prev,
      isLoading: false,
      fieldList: formmattedList,
      emptyFieldList: tempEmptyFieldList as IEhrFieldList[],
      filledFieldList: tempFilledFieldList as  IEhrFieldList[],
      error: false,
    }));
    if (formmattedList.some((item: IEhrFieldList) => item?.value !== ' ')) {
      setShowEhrTitle(true);
    }
  };

  const handleCustomEHRAttributesError = (err: any) => {
    setComponentState((prev) => ({
      isLoading: false,
      error: true,
      fieldList: [],
      emptyFieldList: [],
      filledFieldList: []
    }));
  };

  const onAnswerChange = (fieldKey: string, answer: string) => {
    setComponentState((prev) => {
      const newList = prev.fieldList.map((field) =>
        field.key === fieldKey ? {...field, value: answer} : field
      );
      const tempEmptyFieldList = prev.emptyFieldList.map((field) =>
        field.key === fieldKey ? {...field, value: answer} : field
      );
      const tempFilledFieldList = prev.filledFieldList.map((field) =>
        field.key === fieldKey ? {...field, value: answer} : field
      ); 
      updateEhrAttributes?.(newList);
      return {
        ...prev,
        fieldList: newList,
        emptyFieldList: tempEmptyFieldList,
        filledFieldList: tempFilledFieldList,
      };      
    });    
  };
  useEffect(() => {
    setComponentState((prev) => ({...prev, isLoading: true}));
    getCustomEHRAttributes(
      handleCustomEHRAttributesResponse,
      handleCustomEHRAttributesError
    );
  }, [prevResponse]);

  const {isLoading, error, fieldList} = componentState;
  const numberOfRows = Math.ceil(fieldList.length / 2);

  if(!isLoading && fieldList.length === 0){
    return <></>
  };

  return (
    <>
      <VStack mt={2} key={`${customFieldOpen}`}>
        {(customFieldOpen || showEhrTitle) &&
          !isLoading &&
          !props.useInline && (
            <Text fontWeight={700} color={Colors.Custom.Gray400} fontSize={12}>
              {intl.formatMessage({id: 'custom EHR Fields'}).toUpperCase()}
            </Text>
          )}
        {/* {(customFieldOpen || showEhrTitle) && */}
          {!props.useInline && (
            <>
              <Divider my={6} />
              {isLoading && <Skeleton.Text lines={2} />}
              {!isLoading && error && (
                <Alert width="100%" status={'error'} variant="left-accent">
                  <VStack space={2} flexShrink={1} width="100%">
                    <HStack
                      flexShrink={1}
                      space={2}
                      justifyContent="space-between"
                    >
                      <HStack space={2} flexShrink={1}>
                        <Alert.Icon marginTop="1" />
                        <Text fontSize="md" color="coolGray.800">
                          Something went wrong while fetching EHR attributes
                        </Text>
                      </HStack>
                    </HStack>
                  </VStack>
                </Alert>
              )}
            </>
          )}

        {!isLoading &&
          !error &&
          componentState?.filledFieldList?.length > 0 && ( 
            <>
              {componentState?.filledFieldList.map((field) => {
 
                return (
                  <VStack flex={1} key={field.key}>
                    <DisplayText
                      extraStyles={{
                        color: Colors.Custom.Gray500,
                        fontWeigth: 400,
                        marginBottom: 5,
                      }}
                      textLocalId={field.label}
                    />
                    {getFieldElementByType(field, onAnswerChange)}
                  </VStack>
                  // )
                );
              })}
            </>
          )}
       

        {customFieldOpen &&
          !isLoading &&
          !error &&
          componentState?.emptyFieldList?.length > 0 && (
            <>
              {componentState?.emptyFieldList.map((field) => {
             
                return (
                  <VStack flex={1} key={field.key}>
                    <DisplayText
                      extraStyles={{
                        color: Colors.Custom.Gray500,
                        fontWeigth: 400,
                        marginBottom: 5,
                      }}
                      textLocalId={field.label}
                    />
                    {getFieldElementByType(field, onAnswerChange)}
                  </VStack>
                );
              })}
            </>
          )}

        <Pressable onPress={() => setCustomFieldOpen(!customFieldOpen)}>
          <HStack alignSelf={'flex-start'} alignItems={'center'}>
            {leadId ?
            <Text fontWeight={500} color={Colors.Custom.mainPrimaryPurple}>
             {customFieldOpen ? 'Hide Empty Custom Fields ' : 'Show Empty Custom Fields '}
            </Text>
            :
            <Text fontWeight={500} color={Colors.Custom.mainPrimaryPurple}>
             {customFieldOpen ? 'Hide Custom Fields ' : 'Show Custom Fields '}
            </Text>
            }
            {customFieldOpen ? (
              <AntIcon
                style={{
                  marginTop: 4,
                }}
                name="up"
                color={'#825AC7'}
                size={14}
              />
            ) : (
              <AntIcon
                style={{
                  marginTop: 4,
                }}
                name="down"
                color={'#825AC7'}
                size={14}
              />
            )}
          </HStack>
        </Pressable>
      </VStack>
    </>
  );
};

export default CustomEHRAttributes;
