import { Box, Button, Center, HStack, Input, ScrollView, Text, View, VStack } from "native-base";
import { IGroupField, IRiskScoreFieldProps, ISingleField } from "./RiskScoreInterface";
import AntIcon from 'react-native-vector-icons/AntDesign';
import {FlatList, StyleSheet} from 'react-native';
import { Colors } from "../../../../../styles/Colors";
import CircleIconView, { NodeCategory } from "../../../../common/CircleIconView/CircleIconView";
import { FIELD_OPERATION, getFieldIconInfo } from "./RiskScoreHelper";
import { InputNumber } from "antd";
import React from "react";
import Feather from "react-native-vector-icons/Feather";
import EmptyScoreIcon from "./EmptyScore";
import { Divider } from 'native-base';
import { GENERAL_RISK_SCORE_GROUP_NAME } from "./SectionWiseRiskScoreView";


const FieldScoreView = (props: IRiskScoreFieldProps) => {

  const getField = (singleField: ISingleField, groupField?: IGroupField | undefined): JSX.Element => {
    const key = `${groupField?.groupId}/${singleField?.component?.componentId || singleField?.fieldId}`;
    if (
      (!groupField && !singleField.isAddedAsSingleField)
      || (groupField && !singleField.isAddedAsGroupField)
    ) {
      return <React.Fragment key={key}></React.Fragment>
    }
    return (
      <Box
        key={key}
        style={styles.singleItemBox}
        marginTop={groupField?.groupId ? 4 : 2}
        margin={2}
      >
        <HStack flex={1} space={1} alignContent={'center'}>
            <HStack flex={0.9} justifyContent={'flex-start'} space={1} justifyItems={'center'}>
              <Text fontWeight={groupField?.groupId ? 500 : 600} fontSize={'14'} marginTop={1}>
                {singleField?.fieldName}
              </Text>
            </HStack>
            <HStack flex={0.1} justifyContent={'flex-end'} paddingTop={2}>
              <Button
                style={styles.box24}
                backgroundColor={'##FDFAFF'}
                color={Colors.Custom.PrimaryBtnColor}
                marginRight={3}
                onPress={() => {
                  props.onChange(singleField, groupField?.groupId ? FIELD_OPERATION.GROUP_FIELD_REMOVE : FIELD_OPERATION.SINGLE_FIELD_REMOVE, groupField);
                }}>
                  <Feather
                      name="trash-2"
                      size={20}
                      color={Colors.Custom.Gray500}
                  />
              </Button>
            </HStack>
        </HStack>
        <VStack>
          {singleField?.optionList?.length > 0 &&
            (singleField?.optionList || []).map((option: any, optionIndex: number) => {
              return (
                <HStack
                  marginY={1}
                  marginLeft={1}
                  space={2}
                  justifyContent="flex-start"
                  alignItems="center"
                  key={optionIndex}
                >
                  <Text flex={2}>{option.label}</Text>
                  <View flex={8}>
                    <InputNumber
                      type={'number'}
                      width={'20%'}
                      min={0}
                      value={(groupField ? option.groupRiskScore : option.riskScore) || 0}
                      onChange={(optionValue) => {
                        if (props?.onActonPerform && typeof props.onActonPerform === 'function') {
                          props.onActonPerform(singleField, optionValue, option, groupField);
                        }
                      }}
                    />
                  </View>
                </HStack>
              );
            }
          )}
          </VStack>
      </Box>
    );
  };

  return (
  <>
    {props?.groupFields?.length == 0 && props?.singleFields?.length == 0 && (
      <VStack height={"100%"} justifyContent={"center"} alignItems={"center"}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <EmptyScoreIcon />
          <Text
            marginTop={4}
            fontWeight={800}
            fontSize={"18"}
            width={121}
            height={30}
          >
            Patient Score
          </Text>
          <Text
            textAlign="center"
            fontWeight={400}
            fontSize={"14"}
            width={300}
            height={40}
            color={Colors.Custom.Gray500}
          >
            Choose score field or sections from left to create a score sheet
          </Text>
        </Box>
      </VStack>
    )}
    <ScrollView>
      {props?.groupFields?.length > 0 &&
        (props?.groupFields || []).map((groupField) => {
          if (!groupField.isAdded) {
            return <React.Fragment key={groupField.groupId}></React.Fragment>;
          }
          return (
            <Box
              key={groupField.groupId}
              margin={2}
              paddingY={2}
              flexDirection={"column"}
              background={Colors.Custom.ContainerBGColor}
              alignSelf={"stretch"}
              borderWidth={1}
              borderColor={Colors.Custom.Gray200}
              borderRadius={8}
            >
              <HStack flex={1} space={1} paddingBottom="8px">
                <HStack flex={0.49} justifyContent={"flex-start"}>
                  <Text fontWeight={600} fontSize={"14"} paddingLeft='4px' marginLeft='8px'>
                    {groupField.groupFieldName}
                  </Text>
                </HStack>
                <HStack flex={0.49} justifyContent={"flex-end"} marginRight='8'>
                  <Button
                    style={styles.box24}
                    backgroundColor={Colors.Custom.Base50}
                    onPress={() => {
                      props.onChange(
                        undefined,
                        FIELD_OPERATION.GROUP_REMOVED,
                        groupField
                      );
                    }}
                  >
                    <HStack alignSelf={"flex-start"} alignItems={"center"} paddingRight ='32px' marginRight='2px'>
                      <Feather name="trash-2" size={20} color={Colors.Custom.mainPrimaryPurple} />
                      <Text ml={2} fontWeight={500} color={Colors.Custom.mainPrimaryPurple}>
                        {"Section score"}
                      </Text>
                    </HStack>
                  </Button>
                </HStack>
              </HStack>
              <Divider marginY={2} />
              <FlatList
                data={(groupField?.fields || [])}
                renderItem={({item}) => getField(item, groupField)}
                keyExtractor={(item) => `${groupField?.groupId}/${item?.component?.componentId || item?.fieldId}`}
              />
            </Box>
          );
        })}

      {props?.singleFields?.length > 0 && (
        <Box
          margin={2}
          paddingY={2}
          flexDirection={"column"}
          background={Colors.Custom.ContainerBGColor}
          alignSelf={"stretch"}
          borderWidth={1}
          borderColor={Colors.Custom.Gray200}
          borderRadius={8}
        >
          <HStack flex={1} space={1} paddingBottom={2}>
            <HStack flex={0.49} justifyContent={"flex-start"}>
              <Text fontWeight={600} fontSize={"14"} paddingLeft={1} marginLeft={2}>
                {GENERAL_RISK_SCORE_GROUP_NAME}
              </Text>
            </HStack>
          </HStack>
          <Divider marginY={2} />
          <FlatList
            data={(props?.singleFields || [])}
            renderItem={({item}) => {
              if (item?.isAddedAsSingleField) {
                return getField(item);
              }
              return null;
            }}
            keyExtractor={(item) => `${item?.component?.componentId || item?.fieldId}`}
          />
        </Box>
      )}
    </ScrollView>
  </>
  );
};

const styles = StyleSheet.create({
  singleItemBox: {
    boxSizing: 'border-box',
    display: 'flex',
    padding: 4,
    backgroundColor: '#FFF',
    border: '1px solid #E4E7EC',
    borderRadius: 8,
    flexGrow: 0,
    minHeight: '10px',
  },
  box24: {
    width: 24,
    height: 24
  }
});

export default React.memo(FieldScoreView);
