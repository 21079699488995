import { Circle, HStack, View } from 'native-base';
import { ColorType } from 'native-base/lib/typescript/components/types';
import AntIcon from 'react-native-vector-icons/AntDesign';
import Feather from 'react-native-vector-icons/Feather';
import FontAwesomeIcon from 'react-native-vector-icons/FontAwesome';
import Fontisto from 'react-native-vector-icons/Fontisto';
import Ionicons from 'react-native-vector-icons/Ionicons';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import Octicons from 'react-native-vector-icons/Octicons';
import { CustomAutomationIcon } from './CustomAutomationIcon';
import { Colors } from '../../../styles';
import { JOURNEY_PRESCRIBE_NODE } from '../../RightSideContainer/Journeys/AddOrUpdateJourney/JourneyTabs';
export interface ICircleIconViewProps {
  iconInfo?: {
    lib: string,
    icon: string,
  };
  nodeCategory?: string;
  icon?: JSX.Element;
  size?: string;
  backgroundColor?: ColorType;
  borderColor?: ColorType;
  iconColor?: any;
  borderWidth?: string;
  borderRadius? : number;
  isSquareView? : boolean;
  isStartNode? : boolean;
  nodeType?: string;
}



export enum NodeCategory {
  entity = 'ENTITY',
  action = 'ACTION',
  moment = 'MOMENT',
  event = 'EVENT',
  operation = 'OPERATION',
  task = 'TASK',
  add = 'ADD',
  condition = 'CONDITION',
  delay = 'DELAY',
  careJourney = 'CARE_JOURNEY',
  order = 'ORDER',
  reminder = 'REMINDER',
  send = 'SEND',
  schedule = 'SCHEDULE',
  measure = 'MEASURE',
}

export enum ICON_LIB {
  AntIcon = 'AntIcon',
  Octicons = 'Octicons',
  Ionicons = 'Ionicons',
  MaterialCommunityIcons = 'MaterialCommunityIcons',
  FontAwesomeIcon = 'FontAwesomeIcon',
  Feather = 'Feather',
  Fontisto = 'Fontisto',
  MaterialIcons = 'MaterialIcons',
  CustomSvgIcon = 'CustomSvgIcon'
}

const CircleIconView = (props: ICircleIconViewProps) => {
  const getIcon = () => {
    if (props.icon) {
      return props.icon;
    } else if (props.iconInfo?.lib && props.iconInfo?.icon) {
      switch (props.iconInfo.lib) {
        case ICON_LIB.AntIcon:
          return <AntIcon name={props.iconInfo?.icon} size={18} color={props.iconColor ? props.iconColor : "white"} />;
        case ICON_LIB.Feather:
          return <Feather name={props.iconInfo?.icon} size={18} color={props.iconColor ? props.iconColor : "white"} />;
        case ICON_LIB.Octicons:
          return <Octicons name={props.iconInfo?.icon} size={18} color={props.iconColor ? props.iconColor : "white"} />;
        case ICON_LIB.MaterialCommunityIcons:
          return <MaterialCommunityIcons name={props.iconInfo?.icon} size={18} color={props.iconColor ? props.iconColor : "white"} />;
        case ICON_LIB.FontAwesomeIcon:
          return <FontAwesomeIcon name={props.iconInfo?.icon} size={18} color={props.iconColor ? props.iconColor : "white"} />;
        case ICON_LIB.Fontisto:
          return <Fontisto name={props.iconInfo?.icon} size={18} color={props.iconColor ? props.iconColor : "white"} />;
        case ICON_LIB.MaterialIcons:
          return <MaterialIcons name={props.iconInfo?.icon} size={18} color={props.iconColor ? props.iconColor : "white"} />;
        case ICON_LIB.Ionicons:
          return <Ionicons name={props.iconInfo?.icon} size={18} color={props.iconColor ? props.iconColor : "white"} />;
        case ICON_LIB.CustomSvgIcon:
          return <CustomAutomationIcon name={props.iconInfo?.icon} size={props.size ? props.size : "18"} iconColor={props.iconColor ? props.iconColor : "white"} />
        default:
          return <><FontAwesomeIcon name={props.iconInfo?.icon} size={18} color={props.iconColor ? props.iconColor : "white"} /></>;
      }

    } else if (props.nodeCategory) {
      switch (props.nodeCategory) {
        case NodeCategory.send:
          return <FontAwesomeIcon name="send-o" size={14} color={props.iconColor ? props.iconColor : "white"} />;
        case NodeCategory.order:
          return <AntIcon name="medicinebox" size={14} color={props.iconColor ? props.iconColor : "white"} />;
        case NodeCategory.schedule:
          return <AntIcon name="calendar" size={14} color={props.iconColor ? props.iconColor : "white"} />;
        case NodeCategory.add:
          return <AntIcon name="addfile" size={14} color={props.iconColor ? props.iconColor : "white"} />;
        case NodeCategory.measure:
          return <MaterialCommunityIcons name="tape-measure" size={14} color={props.iconColor ? props.iconColor : "white"} />;
        case NodeCategory.entity:
          return <AntIcon name="profile" size={14} color={props.iconColor ? props.iconColor : "white"} />;
        case NodeCategory.action:
          return <Feather name="check-circle" size={14} color={props.iconColor ? props.iconColor : "white"} />;
        case NodeCategory.moment:
          return <Feather name="git-pull-request" size={14} color={props.iconColor ? props.iconColor : "white"} />;
        case NodeCategory.event:
          return <Feather name="trending-up" size={14} color={props.iconColor ? props.iconColor : "white"} />;
        case NodeCategory.operation:
          return <FontAwesomeIcon name="gears" size={14} color={props.iconColor ? props.iconColor : "white"} />;
        default:
          return <FontAwesomeIcon name="gears" size={14} color={props.iconColor ? props.iconColor : "white"} />;
      }
    }
  };

  if(props.isStartNode && false){
    return(
      <View borderRadius={8}>
        <Circle
          borderWidth={props.borderWidth?props.borderWidth : '5'}
          size={props.size || '28px'}
          backgroundColor={Colors.FoldPixel.TRANSPARENT} 
          borderColor={Colors.FoldPixel.TRANSPARENT}
        >
          {getIcon()}
        </Circle>
      </View>
    )
  }
  if(props.isSquareView){
    return (
      <View 
      style={{
        // @ts-ignore: Unreachable code error
        backgroundImage: (props?.isStartNode || props?.nodeType == JOURNEY_PRESCRIBE_NODE) ?
        'linear-gradient(237.73deg, #D2EBEF 0.27%, #F5E4FE 102.44%)' : undefined,
      }}
        backgroundColor={props?.isStartNode ? undefined : props.backgroundColor} 
        borderRadius={8} 
        alignItems={'center'} 
        justifyContent={'center'} 
        height={'max-content'}
        
        > 
        <Circle
          borderWidth={props.borderWidth?props.borderWidth : '5'}
          size={props.size || '28px'}
          backgroundColor={Colors.FoldPixel.TRANSPARENT} 
          borderColor={Colors.FoldPixel.TRANSPARENT}
        >
          {getIcon()}
        </Circle>
      </View>
    )
  }
  else{
    return (
      <Circle
        borderWidth= {props.borderWidth?props.borderWidth : '5'}
        size={props.size || '8'}
        backgroundColor={props.backgroundColor || 'primary.500'}
        borderColor={props.borderColor || 'primary.600'}
      >
        {getIcon()}
      </Circle>
    );
  }
};

export default CircleIconView;
