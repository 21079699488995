import {View, HStack, VStack, Text} from 'native-base';
import {styles} from './DisplayCardStyles';
import {IDisplayListItem, IDisplayListProps} from './interface';
export const DisplayCardList = (props: IDisplayListProps) => {
  const displayList = props.displayList || [];
  return (
    <View flex={1}>
      {displayList.length && (
        <VStack flex={1}>
          {displayList.map(
            (displayListItem: IDisplayListItem, index: number) => {
              return (
                <HStack flex={1} justifyContent={'space-between'} key={index}>
                  <VStack flex={0.05} justifyContent={'flex-start'}>
                    <View
                      style={[
                        styles.bulletPoint,
                        {
                          backgroundColor: displayListItem.bulletPointColor,
                        },
                      ]}
                    ></View>
                  </VStack>
                  <VStack flex={0.95} justifyContent={'flex-end'}>
                    <Text style={styles.displayListText}>
                      {displayListItem.displayStr}
                    </Text>
                  </VStack>
                </HStack>
              );
            }
          )}
        </VStack>
      )}
    </View>
  );
};
