import {StyleSheet} from 'react-native';
import {Colors} from '../../../../../styles';

export const styles = StyleSheet.create({
  lineStyles: {
    // justifyContent: 'space-between',
    justifyContent: 'flex-start',
    padding: 5,
  },
  arrayViewStyle: {},

  // visit history styles
  singleVisitHistoryStyle: {
    padding: 10,
    justifyContent: 'space-between',
    borderColor: Colors.Custom.BorderColor,
    marginTop: 5,
    marginBottom: 5,
    borderWidth: 1,
    borderRadius: 10,
  },
  graphViewStyles: {
    marginTop: 10,
    maxHeight: 300,
    marginLeft: 0,
    marginRight: 0,
    flex: 1/2,
  },
  graphInfoView: {
    alignItems: 'center',
  },
});
