import React from 'react';
import Highlighter from 'react-highlight-words';

const MsgHighlighter = (props: {
  searchString: string;
  contentString?: string;
}) => {
  return (
    <Highlighter
      highlightStyle={{
        backgroundColor: '#ffc069',
        padding: 0,
      }}
      searchWords={[props.searchString || '']}
      autoEscape
      textToHighlight={props.contentString || ''}
    />
  );
};

export default MsgHighlighter;
