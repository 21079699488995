import {StyleSheet} from 'react-native';
import {Colors} from '../../../../../../styles';
import {FONT_CONFIG} from '../../../../../../styles/FontConst';

export const styles = StyleSheet.create({
  cardView: {
    display: 'flex',
  },
  btnView: {
    display: 'flex',
    justifyContent: 'center',
    padding: 10,
    alignItems: 'flex-end',
  },
  singleDataView: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    marginTop: 2,
    marginBottom: 2,
    justifyContent: 'flex-start',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 4,
    marginBottom: 4,
    borderRadius: 20,
    borderWidth: 1,
    borderColor: Colors.Custom.BorderColor,
    padding: 15,
  },
  dataText: {
    color: FONT_CONFIG.TEXT.textColor,
    fontFamily: FONT_CONFIG.TEXT.fontFamily,
  },
  multiDataView: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  btnStyle: {
    borderRadius: 50,
    width: 'fit-content',
    paddingLeft: 20,
    paddingRight: 20,
  },
});
