import {IReplyMessageObject} from '../components/common/ChatUI/MessageBox/interfaces';
import {IMessageResp} from '../components/RightSideContainer/TeamInbox/Conversations/MessagingWindow/interface';
import BaseService from '../services/CommonService/BaseService';
import CommonService from '../services/CommonService/CommonService';
import ConversationsQueries from '../services/Conversations/ConversationsQueries';
import {isEmptyArray} from './commonUtils';
import {CallBackArgs, IMentionMessageCallBack, IMentionObject, IMutationPostObject} from './interfaces';

export class MessageBus {
  private static instance: MessageBus;
  private mentionUuids: Set<string> = new Set(['']);
  private callback?: (args: CallBackArgs) => void;
  private errorCallback?: (error: any) => void;
  private onMentionStatusChangedCallback?: (
    mentionObject: IMentionObject[]
  ) => void;
  private onMentionStatusChangedCallbackForList?: (
    mentionObject: IMentionObject[]
  ) => void;
  private onMentionMessageCallBack?: (
    args: IMentionMessageCallBack
  ) => void;

  constructor() {
    if (MessageBus.instance) {
      throw new Error('Use EventBus.eventBusInst instead of new.');
    }
    MessageBus.instance = this;
  }
  static getMessageBusInstance(): MessageBus {
    return MessageBus.instance ?? (MessageBus.instance = new MessageBus());
  }

  public registerCallbacks(args: {
    callback?: (args: CallBackArgs) => void;
    errorCallback?: (error: any) => void;
    onMentionStatusChanged?: (mentionObject: IMentionObject[]) => void;
    onMentionStatusChangedCallbackForList?: (
      mentionObject: IMentionObject[]
    ) => void;
    onMentionMessageCallBack?: (
      args: IMentionMessageCallBack
    ) => void;
  }) {
    const {
      callback,
      errorCallback,
      onMentionStatusChanged,
      onMentionStatusChangedCallbackForList,
      onMentionMessageCallBack,
    } = args;
    if (callback) {
      this.callback = callback;
    }
    if (onMentionMessageCallBack) {
      this.onMentionMessageCallBack = onMentionMessageCallBack;
    }
    if (errorCallback) {
      this.errorCallback = errorCallback;
    }
    if (onMentionStatusChanged) {
      this.onMentionStatusChangedCallback = onMentionStatusChanged;
    }
    if (onMentionStatusChangedCallbackForList) {
      this.onMentionStatusChangedCallbackForList =
        onMentionStatusChangedCallbackForList;
    }
  }

  public async sendMessage(args: {
    accountId: number;
    conversationId: number;
    formData: any;
    message: string;
    parentMessage?: IReplyMessageObject;
    echoId?: string
    selectedConversation: {
      uuid: string,
      displayId: number
    }
  }) {
    const {accountId, conversationId, formData, message, parentMessage, echoId, selectedConversation} = args;
    const commonService = CommonService.getCommonServiceInstance();
    const axiosService = commonService.axiosService;
    const url = `/accounts/${accountId}/conversations/${conversationId}/messages`;
    try {
      const response = await axiosService.post<IMessageResp>(url, formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      });
      if (this.callback) {
        this.callback({
          msgTempData: response.data,
          msgText: message,
          parentMessage: parentMessage,
          selectedConversation: {uuid: selectedConversation?.uuid},
        });
      }
      if (this.onMentionMessageCallBack) {
        this.onMentionMessageCallBack({
          msgTempData: response.data,
          msgText: message,
          parentMessage: parentMessage,
          selectedConversation: selectedConversation
        });
      }
    } catch (error) {
      if (this.errorCallback) {
        this.errorCallback({error, echoId});
      }
    }
  }

  public removeCallback(args: {
    callback?: (args: CallBackArgs) => void;
    errorCallback?: (error: any) => void;
    onMentionStatusChanged?: (mentionObject: IMentionObject[]) => void;
    onMentionStatusChangedCallbackForList?: (
      mentionObject: IMentionObject[]
    ) => void;
    onMentionMessageCallBack?: (
      args: IMentionMessageCallBack
    ) => void;
  }) {
    const {
      callback,
      errorCallback,
      onMentionStatusChanged,
      onMentionStatusChangedCallbackForList,
      onMentionMessageCallBack,
    } = args;
    if (callback) {
      this.callback = undefined;
    }
    if (onMentionMessageCallBack) {
      this.onMentionMessageCallBack = undefined;
    }
    if (errorCallback) {
      this.errorCallback = undefined;
    }
    if (onMentionStatusChanged) {
      this.onMentionStatusChangedCallback = undefined;
      this.mentionUuids.clear();
    }
    if (onMentionStatusChangedCallbackForList) {
      this.onMentionStatusChangedCallbackForList = undefined;
      this.mentionUuids.clear();
    }
  }

  public async updateMentionStatus(args: {mentionObjects: IMentionObject[]}) {
    const {mentionObjects} = args;
    if (!mentionObjects || mentionObjects?.length == 0) {
      return;
    }
    const mentionUuidsToUpdate = this.getMentionIdsFromMentionObjects({
      mentionObjects: mentionObjects,
    });
    if (isEmptyArray(mentionUuidsToUpdate)) {
      return;
    }
    const url = '/crm-nest/platform';
    const mutationPostObject = this.getMutationPostObject({
      ids: mentionUuidsToUpdate,
    });
    const baseService = BaseService.getSharedInstance().axios;
    try {
      mentionUuidsToUpdate.forEach((item) => {
        this.mentionUuids.add(item);
      });
      const response = await baseService.post(url, mutationPostObject);
      if (!response.data) {
        return;
      }
      if (this.onMentionStatusChangedCallback) {
        this.onMentionStatusChangedCallback(mentionObjects);
      }
      if (this.onMentionStatusChangedCallbackForList) {
        this.onMentionStatusChangedCallbackForList(mentionObjects);
      }
    } catch (error) {

    }
  }

  private getMentionIdsFromMentionObjects(args: {
    mentionObjects: IMentionObject[];
  }) {
    const {mentionObjects} = args;
    const result: string[] = [];
    mentionObjects.forEach((item) => {
      if (!this.mentionUuids.has(item.mentionUuid)) {
        result.push(item.mentionUuid);
      }
    });
    return result;
  }

  private getMutationPostObject(args: {ids: string[]}): IMutationPostObject {
    const {ids} = args;
    return {
      operationName: 'ReadMentionMessage',
      query: ConversationsQueries.ReadMentionMessage,
      variables: {
        ids: ids,
      },
    };
  }
}
