import React from "react";
import { isWeb } from "../../../../utils/platformCheckUtils";
import { ICommonSvgProps } from "../interfaces";
import {Image} from 'native-base';

const ScheduleMessageSvg = (props: ICommonSvgProps) => {
    const strokeColor = props?.customStrokeColor ? props?.customStrokeColor : "#667085"
    return (
      <>
        {isWeb() ? (
          <svg width="inherit"
          height="inherit" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.7445 8.59291V12.0849L17.0742 13.2472M9.45486 14.5231H5.34301L1.43262 18.4283V2.80222C1.43262 2.28566 1.63924 1.78976 2.01117 1.423C2.37276 1.05623 2.86867 0.849609 3.3904 0.849609H17.0587C17.5752 0.849609 18.0763 1.05623 18.4379 1.423C18.8098 1.78976 19.0113 2.28566 19.0113 2.80222V8.13316M14.7445 17.9117C17.9575 17.9117 20.5662 15.3031 20.5662 12.09C20.5662 8.87702 17.9575 6.26837 14.7445 6.26837C11.5314 6.26837 8.9228 8.87702 8.9228 12.09C8.9228 15.3031 11.5314 17.9117 14.7445 17.9117Z" 
          stroke= {props?.customStrokeColor ? props?.customStrokeColor : "#667085"}
           stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        ) : (
          <Image
            tintColor={strokeColor}
            size={'4'}
            source={require('../../../../assets/images/PNGImages/TabsPngIcons/scheduleIcon.png')}
            alt="image"
          />
        )}
      </>
    );
}

export default ScheduleMessageSvg;
