import {Dropdown, Menu} from 'antd';
import {Box, HStack, Pressable, Text, View} from 'native-base';
import {useIntl} from 'react-intl';
import SuggestionsSvg from '../../../../common/Svg/SideMenu/SuggestionsSvg';

export enum SuggestionsOptions {
  suggestContent = 'suggestContent',
  suggestEduContent = 'suggestEduContent',
  suggestImageContent = 'suggestImageContent',
}

export interface IDropdownButtonProps {
  title: string;
  onClick?: (action: SuggestionsOptions) => void;
  leftIcon: JSX.Element;
}

const CustomSuggestionsButton = (props: IDropdownButtonProps) => {
  const intl = useIntl();
  const addNewMenu = () => {
    return (
      <Menu
        items={[
          {
            key: SuggestionsOptions.suggestContent,
            label: intl.formatMessage({id: 'suggestContent'}),
          },
          {
            key: SuggestionsOptions.suggestEduContent,
            label: intl.formatMessage({id: 'suggestEduContent'}),
          },

          {
            key: SuggestionsOptions.suggestImageContent,
            label: intl.formatMessage({id: 'suggestImageContent'}),
          },
        ]}
        onClick={(data: any) => {
          if (props.onClick && typeof props.onClick === 'function') {
            props.onClick(data.key)
          }
        }
        }
      />
    );
  };

  return (
    <View
      margin={1}
      style={{
        height: 36,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        p="2"
        rounded="lg"
        _text={{fontSize: 'md', fontWeight: 'bold', color: 'white'}}
        bg={{
          linearGradient: {
            colors: ['#1E9DAE', '#D478FF'],
            start: [0, 0],
            end: [1, 0],
          },
        }}
      >
        <Pressable
          rounded="8"
          overflow="hidden"
          maxW="24"
        >
          <HStack>
            <SuggestionsSvg />
            <Dropdown
              overlay={addNewMenu}
              placement="bottomLeft"
              overlayStyle={{width: 120}}
            >
              <Text
                color="white"
                size={'xsBold'}
                width={'110px'}
                style={{fontSize: 14}}
                textAlign="center"
              >
                {props.title}
              </Text>
            </Dropdown>
          </HStack>
        </Pressable>
      </Box>
    </View>
  );
};

export default CustomSuggestionsButton;
