import React, {useContext} from 'react';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {
  getMlovIdFromCode,
  getMlovListFromCategory,
} from '../../../../utils/mlovUtils';
import {
  CARE_PLAN_STATUS_CODES,
  MLOV_CATEGORY,
} from '../../../../constants/MlovConst';

const useCarePlanStatus = () => {
  const contextData = useContext(CommonDataContext);
  const carePlanStatusMlovs =
    getMlovListFromCategory(
      contextData.CARE_STUDIO_MLOV || {},
      MLOV_CATEGORY.CARE_PLAN_STATUS
    ) || [];

  const activeCarePlanStatusId = getMlovIdFromCode(
    carePlanStatusMlovs,
    CARE_PLAN_STATUS_CODES.ACTIVE
  );

  const draftCarePlanStatusId = getMlovIdFromCode(
    carePlanStatusMlovs,
    CARE_PLAN_STATUS_CODES.DRAFT
  );

  const inReviewCarePlanStatusId = getMlovIdFromCode(
    carePlanStatusMlovs,
    CARE_PLAN_STATUS_CODES.IN_REVIEW
  );

  return {
    activeCarePlanStatusId,
    draftCarePlanStatusId,
    inReviewCarePlanStatusId,
  };
};

export default useCarePlanStatus;
