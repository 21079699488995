import { Popover } from 'antd';
import {
  Button,
  FormControl,
  HStack,
  Input,
  Pressable,
  Text,
  View,
  VStack
} from 'native-base';
import React, { useState } from 'react';
import { IColorSelectorProps } from '.';
import { Colors } from '../../../../../../styles';
import { DisplayText } from '../../../../../common/DisplayText/DisplayText';
import { style } from './ColorStyles';

const { Label } = FormControl;

export const COLOR_SELECTOR_VIEW_CODES = {
  BUTTON: 'BUTTON',
  COLOR_AND_NAME: 'COLOR_AND_NAME',
  CIRCLE_BUTTON: 'CIRCLE_BUTTON',
};

const ColorSelector = (props: IColorSelectorProps) => {
  const { color, colorList, onBtnPress, onChangeHex, title } = props;
  const [visible, setVisible] = useState(false);

  const handleColorSelect = (color: string) => {
    setVisible(false);
    onBtnPress(color);
  };

  const content = (
    <VStack backgroundColor={'white'} padding={10}>
      <HStack width={250} flexWrap={'wrap'}>
        {colorList.map((color: string) => {
          return (
            <Pressable
              key={color}
              margin={1}
              height={30}
              width={30}
              onPress={() => handleColorSelect(color)}
            >
              <View flex={1} backgroundColor={color} borderRadius={3}></View>
            </Pressable>
          );
        })}
      </HStack>
      <Input
      _focus={{
        borderColor: Colors.Custom.Gray200
      }}
        marginTop={5}
        maxLength={6}
        fontSize={'md'}
        onChangeText={(text: string) => onChangeHex(text)}
        InputLeftElement={
          <>
            <View margin={2}>
              <Text fontWeight={'bold'}>#</Text>
            </View>
          </>
        }
        placeholder="Enter Hex Value"
        value={color?.slice(1)}
      />
    </VStack>
  );

  return (
    <FormControl style={props.customStyle || style.formElement}>
      {title && (
        <Label>
          <DisplayText textLocalId={title} size={'mdNormal'} />
        </Label>
      )}
      <VStack>
        <Popover
          content={content}
          trigger="click"
          visible={visible}
          onVisibleChange={() => {
            setVisible(false);
          }}
        >
          {(!props.viewCode ||
            props.viewCode === COLOR_SELECTOR_VIEW_CODES.BUTTON) && (
            <Button
              onPress={() => {
                setVisible(true);
              }}
              height={10}
              backgroundColor={color}
              borderColor={'white'}
            ></Button>
          )}
          {props.viewCode === COLOR_SELECTOR_VIEW_CODES.COLOR_AND_NAME && (
            <Pressable
              onPress={() => {
                setVisible(true);
              }}
            >
              <View
                width={'full'}
                borderColor={Colors.Custom.Gray200}
                borderWidth={'1px'}
                borderRadius={'5px'}
                height={'40px'}
              >
                <HStack height={'full'} space={2} alignItems={'center'}>
                  <View
                    width={'25px'}
                    height={'25px'}
                    marginLeft={'10px'}
                    backgroundColor={color}
                    borderColor={Colors.Custom.Gray300}
                    borderRadius={'3px'}
                    borderWidth={'1px'}
                  ></View>

                  <View>
                    <Text>{color}</Text>
                  </View>
                </HStack>
              </View>
            </Pressable>
          )}
          {
            props.viewCode === COLOR_SELECTOR_VIEW_CODES.CIRCLE_BUTTON && (
              <Button
              onPress={() => {
                setVisible(true);
              }}
              height={props?.roundBtnSize || 10}
              width={props?.roundBtnSize || 10}
              rounded='full'
              backgroundColor={color}
              borderColor={'white'}
            ></Button>
            )
          }
        </Popover>
      </VStack>
    </FormControl>
  );
};

export default ColorSelector;
