export default {};

export const QUESTIONNAIRE_CONSTS = {
  QUESTIONNAIRE: 'QUESTIONNAIRE',
  QUESTIONNAIRE_RESPONSE: 'QUESTIONNAIRE_RESPONSE',
};

export const QUESTIONNAIRE_PATHS = {
  QUESTIONNAIRE: 'Questionnaire',
  QUESTIONNAIRE_RESPONSE: 'QuestionnaireResponse',
};

export const QUESTION_TYPES = {
  TEXT: 'text',
  CHOICE: 'choice',
  RADIO: 'radio',
  MULTI_SELECT: 'multiSelect',
  DATE: 'DATE',
  FREE_TEXT: 'FREETEXT',
  OPEN_CHOICE: 'open-choice',
  DATE_NEW: 'date',
  STRING: 'string',
  NUMERIC: 'NUMERIC',
};
