import { CodeableConcept, Coding, RequestGroup, ServiceRequest } from "fhir/r4";
import { IMlov } from "../../../../../../Interfaces";
import { generateUUID } from "../../../../../../utils/commonUtils";
import { getMomentObj } from "../../../../../../utils/DateUtils";
import { IStep } from "../../../../../common/CustomStepView/interfaces";
import { getExternalOrderDisplay } from "../../../../../common/OrderSearch/Helper";
import { processContactDetails, processProviderIdentifierDetails } from "../../../../../RightSideContainer/Contacts/TeamMembers/AddEditUser/addEditUsersUtils";
import { EXTENSION_URLS } from "../../../PatientNotes/components/AddOrUpdateTemplate/constant";
import { OrderType } from "../OrderConstants";
import { getOrderType, getOrderTypeFromCode, ORDER_SOURCE } from "../OrderUtils";
import { IOrderViewModel } from "./interfaces";

export enum OrderStatus {
  draft = 'draft',
  active = 'active',
  onHold = 'on-hold',
  revoked = 'revoked',
  completed = 'completed',
  enteredInError = 'entered-in-error',
  unknown = 'unknown',
}

export const NOTE_REFERENCE = {
  PATIENT: 'PATIENT',
  FACILITY: 'FACILITY',
}

export const ORDER_PRIORITY = [
  { code: 'routine', display: 'Routine' },
  { code: 'stat', display: 'STAT' },
]

export const getUserSignatureId = (user: any, providerTypesList: IMlov[]) => {
  const providerTypeDetails = processProviderIdentifierDetails(
    user?.providerIdentifiers,
    providerTypesList
  );
  return providerTypeDetails?.SIGNATURE?.value;
}

export const getUserNPI = (user: any, providerTypesList: IMlov[]) => {
  const providerTypeDetails = processProviderIdentifierDetails(
    user?.providerIdentifiers,
    providerTypesList
  );
  return providerTypeDetails?.NPI_NUMBER?.value;
}

export const getUserContactDetails = (user: any) => {
  const providerTypeDetails = processContactDetails(
    user?.persons?.personContactDetails
  );
  return {
    phoneNumber: providerTypeDetails?.phone?.value || '',
    email: providerTypeDetails?.email?.value || '',
    faxNumber: providerTypeDetails?.fax?.value || '',
  }
}

export const getFHIRTemplateFromOrder = (order: IOrderViewModel, status: OrderStatus): RequestGroup => {
  const currentDate = getMomentObj(new Date()).toISOString();
  const authorDate = order.author.date || currentDate;
  const requestGroup: RequestGroup = {
    resourceType: 'RequestGroup',
    id: order.id,
    intent: 'order',
    status: status,
    subject: {
      reference: `Patient/${order.patientId}`
    },
    meta: {
      lastUpdated: currentDate,
    },
    ...(order.priority && {
      priority: order.priority,
    }),
    ...(order.documentReferenceId && {
      basedOn: [
        {
          reference: `DocumentReference/${order.documentReferenceId}`
        }
      ]
    }),
    action: order.orders.map((order) => {
      const action = {
        resource: {
          display: getExternalOrderDisplay(order.order),
          reference: `ServiceRequest/${order.serviceRequestId}`,
          extension: [
            {
              valueString: order.order.display,
              url: EXTENSION_URLS.externalDisplayName
            }
          ]
        }
      };
      if (order.order.system) {
        action.resource.extension.push({
          valueString: order.order.system,
          url: EXTENSION_URLS.system
        });
      }
      return action;
    }),
    contained: [],
    ...((order.author.name || order.author.userUuid) && {
      author: {
      ...(order.author.name && { display: order.author.name }),
      ...(order.author.userUuid && { reference: `Practitioner/${order.author.userUuid}` }),
      }
    }),
    authoredOn: authorDate,
    note: [],
    ...(order.diagnosis.length > 0 && {
      reasonCode: order.diagnosis,
    }),
    extension: [
      {
        url: EXTENSION_URLS.orderCategory,
        valueString: getOrderType(order.orderType),
      },
      {
        url: EXTENSION_URLS.source,
        valueString: ORDER_SOURCE.FOLD,
      },
      {
        url: EXTENSION_URLS.orderingPhysician,
        valueReference: {
          display: order.orderingPhysician?.name,
          reference: `Practitioner/${order.orderingPhysician?.userUuid}`
        }
      },
    ],
  }
  const serviceRequestItems = getServiceRequestTemplates(order, status, currentDate);
  serviceRequestItems.forEach((item) => requestGroup.contained?.push(item));

  if (order.facilityNotes) {
    requestGroup.note?.push({
      text: order.facilityNotes,
      authorString: NOTE_REFERENCE.FACILITY
    })
  }
  if (order.patientNotes) {
    requestGroup.note?.push({
      text: order.patientNotes,
      authorString: NOTE_REFERENCE.PATIENT
    })
  }

  if (order.facility) {
    requestGroup.extension?.push({
      url: EXTENSION_URLS.requestGroupPerformer,
      valueReference: {
        display: order.facility,
      }
    });
  }
  if (order.orderDate) {
    requestGroup.extension?.push({
      url: EXTENSION_URLS.foldRequestGroupTiming,
      valueTiming: {
        event: [order.orderDate]
      }
    });
  }
  return requestGroup;
}

export const getServiceRequestTemplates = (order: IOrderViewModel, status: OrderStatus, currentDate: string): ServiceRequest[] => {
  const authorDate = order.author.date || currentDate;
  const serviceRequestItems: ServiceRequest[] = order.orders.map((item) => {
    const serviceRequest: ServiceRequest = {
      resourceType: 'ServiceRequest',
      id: item.serviceRequestId,
      code: {
        text: getExternalOrderDisplay(item.order),
        coding: [
          item.order
        ],
      },
      authoredOn: authorDate,
      intent: 'order',
      status: status,
      ...(order.priority && {
        priority: order.priority,
      }),
      subject: {
        reference: `Patient/${order.patientId}`
      },
      ...(order.diagnosis.length > 0 && {
        reasonCode: order.diagnosis,
      }),
      requester: {
        display: order.orderingPhysician?.name,
        reference: `Practitioner/${order.orderingPhysician?.userUuid}`
      },
      ...(order.facility && {
        performer: [
          {
            display: order.facility,
          },
        ],
      }),
      extension: [
        {
          url: EXTENSION_URLS.author,
          valueReference: {
            display: order.author.name,
            reference: `Practitioner/${order.author.userUuid}`,
          }
        },
        {
          url: EXTENSION_URLS.orderCategory,
          valueString: getOrderType(order.orderType),
        },
        {
          url: EXTENSION_URLS.source,
          valueString: ORDER_SOURCE.FOLD,
        },
      ],
      meta: {
        lastUpdated: currentDate,
      }
    }
    if (order.orderDate) {
      serviceRequest.extension?.push({
        url: EXTENSION_URLS.foldRequestGroupTiming,
        valueTiming: {
          event: [order.orderDate]
        }
      });
    }
    return serviceRequest;
  });
  return serviceRequestItems;
}

export const getOrderViewModelFromRequestGroup = (
  order: RequestGroup | undefined,
  defaultData: {
    patientId: string;
    authorName: string;
    authorUserUuid: string;
    orderType: OrderType;
    documentReferenceId?: string;
    existingDiagnosis: CodeableConcept[],
  },
  ): IOrderViewModel => {
  if (order) {
    // Convert RequestGroup to IOrderViewModel
    const orderingPhysician = order.extension?.find((item) => item.url === EXTENSION_URLS.orderingPhysician);
    const orderDate = order.extension?.find((item) => item.url === EXTENSION_URLS.foldRequestGroupTiming);
    const facilityDetails = order.extension?.find((item) => item.url === EXTENSION_URLS.requestGroupPerformer);
    const orderTypeDetails = order.extension?.find((item) => item.url === EXTENSION_URLS.orderCategory);
    const documentReferenceId = order.basedOn?.[0]?.reference?.split('/')?.[1] || defaultData.documentReferenceId;

    const patientId = order.subject?.reference?.split('/')?.[1] || defaultData.patientId || '';
    const authorName = order.author?.display || defaultData.authorName;
    const authorUserUuid = order.author?.reference?.split('/')?.[1] || defaultData.authorUserUuid;
    const authorDate = order.authoredOn || '';
    const orderType = getOrderTypeFromCode(orderTypeDetails?.valueString || '') || defaultData.orderType;

    const facilityNotes = order?.note?.find((item) => item.authorString === NOTE_REFERENCE.FACILITY)?.text;
    const patientNotes = order?.note?.find((item) => item.authorString === NOTE_REFERENCE.PATIENT)?.text;

    return {
      id: order.id || '',
      orders: order.action?.map((action) => {
        const serviceRequestId = action.resource?.reference?.split('/')?.[1] || '';
        const serviceRequestObject = order.contained?.find((item) => item.id === serviceRequestId) as ServiceRequest;
        return {
          serviceRequestId: serviceRequestId,
          order: serviceRequestObject.code?.coding?.[0] as Coding,
          questionAnswers: [],
        };
      }) || [],
      facilityNotes: facilityNotes || '',
      patientNotes: patientNotes || '',
      diagnosis: order.reasonCode || [],
      facility: facilityDetails?.valueReference?.display || '',
      orderDate: orderDate?.valueTiming?.event?.[0] || '',
      priority: order.priority,
      eSign: false,
      orderingPhysician: {
        name: orderingPhysician?.valueReference?.display || '',
        userUuid: orderingPhysician?.valueReference?.reference?.split('/')?.[1] || '',
        signatureId: '',
        npi: '',
        signatureUrl: undefined,
        phone: '',
        email: '',
        fax: '',
      },
      patientId: patientId,
      author: {
        name: authorName,
        userUuid: authorUserUuid,
        date: authorDate
      },
      orderType: orderType,
      documentReferenceId: documentReferenceId,
    };
  } else {
    return {
      id: generateUUID(),
      orders: [],
      facilityNotes: '',
      patientNotes: '',
      diagnosis: defaultData.existingDiagnosis,
      facility: '',
      orderDate: '',
      priority: undefined,
      eSign: false,
      orderingPhysician: undefined,
      patientId: defaultData.patientId,
      author: {
        name: defaultData.authorName,
        userUuid: defaultData.authorUserUuid,
        date: ''
      },
      orderType: defaultData.orderType,
      documentReferenceId: defaultData.documentReferenceId,
    };
  }
}

export const getOrderingPhysicianMessage = (orderingPhysician: {
  name: string;
  userUuid: string;
  signatureId: string;
  npi: string;
}) => {
  const signature = orderingPhysician?.signatureId;
  const npi = orderingPhysician?.npi;
  if (!signature && !npi) {
    return'Selected physician does not have e-signature and NPI configured';
  } else if (!signature) {
    return 'Selected physician does not have e-signature configured';
  } else if (!npi) {
    return 'Selected physician does not have NPI configured';
  }
  return '';
}
