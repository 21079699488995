import axios from 'axios';
import {BILLING_BASE_URL} from '../../constants/Configs';
import LocalStorage from '../../utils/LocalStorage';
import { createAxiosInstance } from '../../utils/APIUtils';

export default class BillingService {
  private static billingServiceInstance: BillingService;
  public axios;
  constructor() {
    if (BillingService.billingServiceInstance) {
      throw new Error('Use BaseService.BaseServiceInstance instead of new.');
    }
    this.axios = createAxiosInstance({
      baseURL: BILLING_BASE_URL,
      headers: {},
    });
    this.axios.interceptors.request.use(
      async (config) => {
        const zoom = await LocalStorage.getItem('zoom');
        const zoomObj = zoom ? JSON.parse(zoom) : '{}';
        const zoom_auth_token = zoomObj.access_token || '';

        const userDataString = await LocalStorage.getItem('user');
        const userData = JSON.parse(userDataString);
        const uid = userData?.data?.email || '';
        const client = userData?.client || '';
        const fold_access_token = userData?.fold_access_token;
        const responseAccessToken = userData?.accessToken || '';
        const accountUUID =
          userData?.data.accounts.length > 0
            ? userData?.data.accounts[0].uuid || ''
            : '';
        const accountId =
          userData?.data.accounts.length > 0
            ? userData?.data.accounts[0].id || ''
            : '';
        const apiAccessToken =
          userData?.data?.access_token || '';
        config.headers = {
          client,
          fold_access_token: fold_access_token,
          'access-token': responseAccessToken,
          'account-uuid': accountUUID,
          account_id: accountId,
          account: accountId,
          'user-uuid': userData?.data.uuid || '',
          userId: userData?.data.id || '',
          api_access_token: apiAccessToken,
          uid,
          zoom_auth_token,
          ...config.headers,
        };
        return config;
      },
      (error) => {
      }
    );

    BillingService.billingServiceInstance = this;
  }
  static getBillingServiceInstanceInstance(): BillingService {
    return (
      BillingService.billingServiceInstance ??
      (BillingService.billingServiceInstance = new BillingService())
    );
  }
}
