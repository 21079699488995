import React, { RefObject, useEffect, useRef } from 'react';
import Editor, { EventMap } from '@toast-ui/editor';
import {EventNames, EditorProps} from '@toast-ui/react-editor';

interface CustomEditorProps extends EditorProps {
  customRef: any
}
const CustomEditor: React.FC<CustomEditorProps> = (props) => {
  const rootEl = useRef<HTMLDivElement | null>(null);
  const editorInst = useRef<Editor | null>(null);

  const getBindingEventNames = () => {
    return Object.keys(props)
      .filter((key) => /^on[A-Z]\w+/.test(key))
      .filter((key) => props[key as EventNames]);
  };

  const bindEventHandlers = (props: any) => {
    getBindingEventNames().forEach((key) => {
      const eventName = key[2].toLowerCase() + key.slice(3);

      editorInst.current?.off(eventName);
      editorInst.current?.on(eventName, props[key as EventNames]!);
    });
  };

  const getInitEvents = () => {
    return getBindingEventNames().reduce(
      (acc: Record<string, EventMap[keyof EventMap]>, key) => {
        const eventName = (key[2].toLowerCase() + key.slice(3)) as keyof EventMap;

        acc[eventName] = props[key as EventNames];

        return acc;
      },
      {}
    );
  };

  useEffect(() => {
    if (rootEl.current) {
      if (editorInst?.current) {
        editorInst.current?.destroy();
      }
      editorInst.current = new Editor({
        el: rootEl.current,
        ...props,
        events: getInitEvents(),
      });
      props.customRef.current = editorInst.current;
      // if (editorInst.current) {
      //   editorInst.current.setMarkdown(props?.initialValue || '');
      // }
    }

    // Cleanup function to unbind events and destroy the editor instance
    return () => {
      if (editorInst.current) {
        getBindingEventNames().forEach((key) => {
          const eventName = key[2].toLowerCase() + key.slice(3);
          editorInst.current?.off(eventName);
        });

        // Destroy the editor instance to prevent memory leaks
        if(editorInst?.current?.destroy){
          editorInst?.current?.destroy?.();
        }
        editorInst.current = null;
        if (props.customRef) {
          props.customRef.current = null;
        }
      }
    };
  }, []); // Empty dependency array ensures this runs once on mount and unmount

  useEffect(() => {
    const instance = editorInst.current;

    if (instance) {
      const { height, previewStyle } = props;

      // Update the height if it has changed
      if (height && instance.getHeight() !== height) {
        instance.setHeight(height);
      }

      // Update the preview style if it has changed
      if (previewStyle) {
        instance.changePreviewStyle(previewStyle);
      }

      // Rebind event handlers with updated props
      bindEventHandlers(props);
    }
  }, [props]); // This effect will run whenever props change

  return <div ref={rootEl} />;
};

export default CustomEditor;
