import { StyleSheet } from 'react-native';
import { Colors } from '../../../styles';

export const styles = StyleSheet.create({
  spinnerStyle: {
    margin: '0 auto',
    color: 'black',
    position: 'absolute',
    zIndex: 100011111,
    backgroundColor: '#0000000f',
    height: '100%',
    width: '100%',
    padding: 0,
  },
  inputStyle: {
    borderColor: 'transparent',
    backgroundColor: 'none',
  },
  badge: {
    flex: 2.8,
    flexDirection: 'row',
    borderRadius: 12,
    alignItems: 'center',
  },
  selectionTextStyle: {
    color: Colors.Custom.Gray500,
    flex: 0.3,
  },
  selectionContainer: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  selectStyle: {
    flex: 0.7,
  },
  dividerStyle: {
    paddingTop: 10,
    paddingBottom: 10,
    // backgroundColor: 'red',
  },
  missingFieldErrorMsgStyle: {
    color: Colors.Custom.ErrorColor,
    flex: 1,
    fontSize: 10,
  },
  textStyle: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
});
