import React, {useContext} from 'react';
import {IParticipantSearch} from '../CalendarWidget/ParticipantAutoComplete/ParticipantInterfaces';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {IThemeAttachments} from '../../BodyContainer/interface';
import {DisplayCardAvatar} from '../DisplayCard/DisplayCardAvatar';
import {GROUP_MEMBER_TYPE} from '../../../constants';
import { HStack, Text, VStack} from 'native-base';
import {Colors} from '../../../styles/Colors';
import { ParticipantType } from '../CalendarWidget/ParticipantAutoComplete/ParticipantEnum';

const GroupInfoItem = (props: {option: IParticipantSearch}) => {
  const {option} = props;
  const mlovData = useContext(CommonDataContext);
  const accountThemeConfig: any = mlovData.accountThemeConfig || {};

  const renderUserAvatar = (option: IParticipantSearch) => {
    let logoElem = <></>;
    const tempThemeAdditionalData: string =
      accountThemeConfig.additionalAttributes || '{}';

    const finalThemeAdditionalAttributes: IThemeAttachments = JSON.parse(
      tempThemeAdditionalData
    );
    if (finalThemeAdditionalAttributes?.attachment?.logo?.isLogoWithName) {
      logoElem = (
        <DisplayCardAvatar
          avatarStyle={{
            avatarSize: '12',
            height: 48,
            width: 48,
          }}
          isLetterAvatarShow
          userData={{
            userName: option.label,
            userId: option.key,
            userType: GROUP_MEMBER_TYPE.PATIENT,
          }}
        />
      );
    } else {
      logoElem = (
        <DisplayCardAvatar
          isLetterAvatarShow
          avatarStyle={{
            avatarSize: '12',
          }}
          userData={{
            userName: option.label,
            userId: option.key,
            userType: GROUP_MEMBER_TYPE.PATIENT,
          }}
        />
      );
    }
    return logoElem;
  };

  const isLeadGroup = (option: IParticipantSearch) => {
    return (option?.type === ParticipantType?.leadGroup)
  }

  return (
    <HStack px={0}>
      {renderUserAvatar(option as IParticipantSearch)}
      <VStack ml={2} alignItems={'flex-start'}>
        <Text size={'smBold'} color={'coolGray.700'} justifyContent={'center'}>
          {option.label}
        </Text>
        <Text size={'xsMedium'} color={Colors.Custom.Gray400}>
          {`${isLeadGroup(option) ? 'Count' : 'Active members'}- ${option?.count ? `${option?.count}` : '0'}`}
        </Text>
      </VStack>
    </HStack>
  );
};

export default GroupInfoItem;
