import { useLazyQuery, useQuery } from '@apollo/client';
import { notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../../constants/Configs';
import { AppointmentQueries } from '../../../../../../services';
import { IAppointmentDetail } from '../../../CalendarWidgetInterfaces'
import AppointmentDetail from './AppointmentDetail';

interface IProps {
  appointmentId: string;
  appointmentDetail?: any;
  onClose?: (refreshCalendar?: boolean) => void;
  onComplete?: () => void;
  onStatusChange?: () => void;
  onError?: () => void;
}

interface IComponentState {
  isError?: boolean;
  isVisible?: boolean;

  appointment?: IAppointmentDetail;
}

export function LoadAppointmentDetailsDrawer(props: IProps) {
  const [ componentState, setComponentState ] = useState<IComponentState>({
    isVisible: true,
  });

  const [ getAppointmentApi, { loading: isGetAppointmentApiLoading } ] = useLazyQuery<{ appointments?: IAppointmentDetail[] }>(AppointmentQueries.GET_APPOINTMENT_BY_ID_WITH_GROUP, {
    fetchPolicy: 'no-cache',
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    onCompleted: (response) => {
      if (response?.appointments?.length && response.appointments[0]) {
        setComponentState(prev => ({ ...prev, appointment: (response.appointments as any)[0], isVisible: true }));
      } else {
        showError();
      }
    },
    onError: (error) => {
      showError();
    }
  });

  useEffect(() => {
    if (props?.appointmentId && props?.appointmentDetail && props?.appointmentDetail?.isBlockedInterval) {
      setComponentState(prev => ({ ...prev, appointment: props?.appointmentDetail, isVisible: true }));
    } else if (props.appointmentId) {
      getAppointmentApi({
        variables: {
          appointmentId: props.appointmentId,
        },
      });
    }
  }, [ props.appointmentId ]);

  function showError() {
    setComponentState(prev => ({ ...prev, isError: true, }));
    notification.destroy();
    notification.error({
      message: 'Something went wrong while fetching appointment details',
    });
  }

  return (
    <>
      {
        !componentState.isError &&
        componentState?.isVisible &&
        <AppointmentDetail
          key={props.appointmentId}
          event={{ detail: componentState?.appointment }}
          isLoading={isGetAppointmentApiLoading}
          isVisible={componentState.isVisible || false}
          onStatusChange={() => {
            setComponentState(prev => ({ ...prev, isVisible: false, }));
            props.onStatusChange?.();
            props.onClose?.();
          }}
          onClose={(refreshCalendar: any) => {
            setComponentState(prev => ({ ...prev, isVisible: false, }));
            props.onClose?.(refreshCalendar);
          }}
          onComplete={() => {
            setComponentState(prev => ({ ...prev, isVisible: false, }));
            props.onComplete?.();
            props.onClose?.();
          }}
        />
      }
    </>
  );

}
