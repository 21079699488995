import {StyleSheet} from 'react-native';
// import {Colors} from '../../../styles';

export const svgStyles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
    margin: 15,
  },
  svgText: {
    fontSize: 14,
    marginTop: 10,
    color: '#D0D5DD',
  },
});
