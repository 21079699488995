import {Linking, NativeModules} from 'react-native';
import BaseService from '../CommonService/BaseService';
import {IZoomHelper, MeetingData, WebinarData} from './interfaces';
import {isAndroid} from '../../utils/platformCheckUtils';
import {getExistingZoomLinkConfig} from '../../components/RightSideContainer/TeamInbox/VideoCallSettings/VideoCallSettingsAPI';
import {isFlagEnabled} from '../../utils/configUtils';

const ZoomHelper: IZoomHelper = NativeModules.ZoomHelper;

const getWebinarData = async (data: {webinarId: string; userUuid: string}) => {
  const baseService = BaseService.getSharedInstance().axios;
  const url = `crm-communication/webinar/link`;
  const response = await baseService.post<WebinarData>(url, data);
  return response.data;
};

const getMeetingData = async (data: {meetingId: string; userUuid: string}) => {
  const baseService = BaseService.getSharedInstance().axios;
  const url = `crm-communication/meeting/link`;
  const response = await baseService.post<MeetingData>(url, data);
  return response.data;
};

const openZoomLink = async (link: string) => {
  const isSupported = await Linking.canOpenURL(link);
  if (!isSupported) {
    return;
  }
  Linking.openURL(link);
};

export const startWebinar = async (data: {
  webinarId: string;
  userUuid: string;
}) => {
  const webinarData = await getWebinarData(data);
  // const accountUuid = webinarData.accountUuid;
  // const config = await getExistingZoomLinkConfig({accountUuid});
  // const isOpenZoomLink = isFlagEnabled(config);
  const jwtToken = webinarData.signature;
  const zoomAccessToken = webinarData.zakToken;
  const meetingNo = webinarData.webinarId;
  const password = webinarData.passWord;
  const displayName = webinarData.userName;
  const registrantToken = webinarData.registrantToken;
  if (isAndroid()) {
    openZoomLink(webinarData.link);
    return;
  }
  ZoomHelper.startMeeting(
    jwtToken,
    zoomAccessToken,
    meetingNo,
    password,
    displayName,
    registrantToken
  );
};

export const startMeeting = async (data: {
  meetingId: string;
  userUuid: string;
}) => {
  const meetingData = await getMeetingData(data);
  // const accountUuid = meetingData.accountUuid;
  // const config = await getExistingZoomLinkConfig({accountUuid});
  // const isOpenZoomLink = isFlagEnabled(config);
  const jwtToken = meetingData.signature;
  const zoomAccessToken = meetingData.zakToken;
  const meetingNo = meetingData.meetingId;
  const password = meetingData.passWord;
  const displayName = meetingData.userName;
  if (isAndroid()) {
    openZoomLink(meetingData.link);
    return;
  }
  ZoomHelper.startMeeting(
    jwtToken,
    zoomAccessToken,
    meetingNo,
    password,
    displayName,
    ''
  );
};

export default {
  startWebinar,
  startMeeting,
};
