import { Drawer } from 'antd';
import { Divider, HStack, Text, View, VStack, Image, Spacer } from 'native-base';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import ReactToPrint from 'react-to-print';
import { BUTTON_TYPE, DATE_FORMATS, PHONE_NUMBER_MASK } from '../../../constants';
import { CONFIG_CODES } from '../../../constants/AccountConfigConst';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { Colors } from '../../../styles';
import { getConfigDataFromCode, getContactAddressDetails, getPrimaryGenderValue, getUserName, numericStringMask } from '../../../utils/commonUtils';
import { getCurrentTimeZoneAbbr, getDateStrFromFormat } from '../../../utils/DateUtils';
import { IThemeAttachments } from '../../BodyContainer/interface';
import { IOrderViewModel } from '../../PersonOmniView/MiddleContainer/Orders/OrdersAndReports/AddOrUpdateExternalOrders/interfaces';
import { OrderType } from '../../PersonOmniView/MiddleContainer/Orders/OrdersAndReports/OrderConstants';
import { getStatusDisplay } from '../../PersonOmniView/MiddleContainer/Orders/OrdersAndReports/OrderUtils';
import { getAdminAppUrl, getPatientAppUrl } from '../../RightSideContainer/ContentManagement/ContentManagementUtils';
import { getDefaultComponent } from '../../RightSideContainer/ContentManagement/EmailTemplates/EmailTemplatesUtils';
import { FHForm } from '../../RightSideContainer/Forms/FHFormio';
import { IDefaultFormTheme } from '../../RightSideContainer/Forms/interfaces';
import BrandingLogoWrapper from '../BrandingLogoWrapper/BrandingLogoWrapper';
import { ModalActionTitle } from '../ModalActionTitle/ModalActionTitle';
import { getExternalOrderCode, getExternalOrderDisplay } from '../OrderSearch/Helper';
import { getCodeSystem } from '../PAMISearch/PAMISearchHelper';
import { ComponentToPrint } from '../PrintComponent/ComponentToPrint';

interface IProps {
  title?: string;
  unFormattedContactData: any;
  isOpen: boolean;
  isLoading?: boolean;
  showPreviewInDrawer?: boolean;
  triggerPrint?: boolean;
  selectedOrder: IOrderViewModel;
  onClose: () => void;
}

export function PrintRequisitionForm(props: IProps) {
  const intl = useIntl();
  const { selectedOrder } = props;
  const personData = props.unFormattedContactData;
  const userName = getUserName();
  const genderValue = getPrimaryGenderValue(personData);
  const contextData = useContext(CommonDataContext);
  const componentRef = useRef(null);
  const printRef: any = useRef(null);

  const [printElement, setHeaderFooter] = useState<{
    header: any,
    footer: any,
  }>({
    header: '',
    footer: '',
  });

  const defaultThemeConfig = getConfigDataFromCode(CONFIG_CODES.DEFAULT_THEME);
  let configJson = undefined;
  let formThemeConfig: IDefaultFormTheme | undefined  = {} as IDefaultFormTheme;

  const accountData: any = contextData.accountSubdomain || {};
  const accountThemeConfig: any = contextData.accountThemeConfig || {};
  const tempThemeAdditionalData: string =
    accountThemeConfig.additionalAttributes || '{}';
  const finalThemeAdditionalAttributes: IThemeAttachments = JSON.parse(
    tempThemeAdditionalData
  );

  const subdomainMap: any = contextData?.accountSubdomain || {};
  const subdomainName: string = subdomainMap?.subDomain;
  const logo = finalThemeAdditionalAttributes?.attachment?.logo?.url;
  const accountName = accountData?.name;


  if (defaultThemeConfig?.accountConfigurations && defaultThemeConfig?.accountConfigurations?.length) {
    configJson = JSON.parse(defaultThemeConfig?.accountConfigurations[0]?.value);
    formThemeConfig = configJson?.form || undefined;
  } else if (defaultThemeConfig?.defaultValue){
    configJson = JSON.parse(defaultThemeConfig?.defaultValue);
    formThemeConfig = configJson?.form || undefined;
  }

  const renderDefaultThemeHeader = (): JSX.Element => {
    return (
      <View backgroundColor={'white'}>
        <HStack marginY={2} alignItems={'center'}>
          <BrandingLogoWrapper
            accountNameTextColor={'black'}
            accountLogoPosition={'center'}
            accountNamePosition={'center'}
            isFromPrint={true}
          />
          <Spacer />
          {/* <View>
            <VStack>
              <Text fontSize={14} fontWeight={500}>Office address ?</Text>
              <Text fontSize={14} fontWeight={500}>Office address ?</Text>
            </VStack>
          </View> */}
        </HStack>
      </View>
    );
  }

  const setHeaderAndFooter = async () => {
    const headerElem = renderDefaultThemeHeader();
    const footerElem = (
      <div className="page-break">
        <HStack space={2} width={'full'}>
          <Text fontSize={10}>{'The contents of this document and/or fax transmission are intended only for the use of the individual or entity to which they are addressed and may contain information that is legally privileged, confidential and exempt from disclosure, including protected health information. If you are not the intended recipient, you are hereby notified that any dissemination, distribution or copying of this document is strictly prohibited. If you have received or obtained this document or fax transmission in error, please notify the original sender immediately by telephone or by email and delete this document immediately.'}</Text>
        </HStack>
      </div>
    );
    setHeaderFooter((prev) => {
      return {
        ...prev,
        header: headerElem,
        footer: footerElem,
      }
    });
  };

  function getDocumentTitle() {
    if (props.title) {
      return props.title;
    }

    const personName = personData.name;
    // const personEmail = personData?.email;
    // const personPhone = personData?.phone ? numericStringMask(personData.phone, PHONE_NUMBER_MASK) : '';
    const birthDate = personData?.birthDate ? getDateStrFromFormat(personData?.birthDate, DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT) : '';

    let title: string = personName;

    if (birthDate) {
      title += ` • DOB: ${birthDate}`;
    }
    // if (personData.age) {
    //   title += ` • Age: ${personData.age} yrs`;
    // }
    // if (personEmail) {
    //   title += ` • ${personEmail}`;
    // }
    // if (personPhone) {
    //   title += ` • ${personPhone}`;
    // }
    return title;
  }

  const getContactBirthDate = (personData: any) => {
    return personData?.person?.birthDate;
  }

  const getContactGenderAndAge = (personData: any) => {
    const details: string[] = [];
    if (genderValue) {
      details.push(`Gender: ${genderValue}`);
    }
    const birthDate = getContactBirthDate(personData);
    if (birthDate) {
      const dob = getDateStrFromFormat(birthDate, DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT);
      details.push(`DOB: ${dob}`);
    }
    return details.join('  •  ');
  }

  const getContactPhoneAndEmail = (personData: any) => {
    const details: string[] = [];
    if (personData.phoneNumber) {
      details.push(`${intl.formatMessage({id: 'phone'})}: ${numericStringMask(personData.phoneNumber || ' ', PHONE_NUMBER_MASK)}`);
    }
    if (personData.email) {
      details.push(`${intl.formatMessage({id: 'email'})} : ${personData?.email}`);
    }
    return details.join('  •  ');
  }

  const renderPatientDemographics = () => {
    const addressDetails = getContactAddressDetails(personData);
    const genderAndAge = getContactGenderAndAge(personData);
    const phoneAndEmail = getContactPhoneAndEmail(personData);
    return (
      <VStack>
        <Divider my={2} backgroundColor={'black'} />
        <Text fontWeight={600} size={'lgMedium'}>
          {personData.name}
        </Text>
        {!!genderAndAge && (
          <Text fontWeight={500} fontSize={14}>{genderAndAge}</Text>
        )}
        {!!phoneAndEmail && (
          <Text fontWeight={500} fontSize={14}>{phoneAndEmail}</Text>
        )}
        {addressDetails && (
          <Text fontWeight={500} fontSize={14}>{addressDetails}</Text>
        )}
        <Divider my={2} backgroundColor={'black'} />
      </VStack>
    );
  };

  const getProviderDetails = () => {
    let details: string[] = [];
    if (selectedOrder.orderingPhysician) {
      details.push(`Ref Practitioner ${selectedOrder.orderingPhysician.name}`);
      if (selectedOrder.orderingPhysician.npi) {
        details.push(`NPI: ${selectedOrder.orderingPhysician.npi}`);
      }
      if (details.length) {
        details = [details.join('  •  ')];
      }
      const contactDetails: string[] = [];
      if (selectedOrder.orderingPhysician.phone) {
        contactDetails.push(`Phone: ${numericStringMask(selectedOrder.orderingPhysician.phone, PHONE_NUMBER_MASK)}`);
      }
      if (selectedOrder.orderingPhysician.fax) {
        contactDetails.push(`Fax: ${numericStringMask(selectedOrder.orderingPhysician.fax, PHONE_NUMBER_MASK)}`);
      }
      if (contactDetails.length) {
        details.push(contactDetails.join('  •  '));
      }
    }
    return details.join('\n');
  }

  const renderFacilityAndPractitionerDetails = () => {
    const facility = selectedOrder.facility;
    const providerDetails = getProviderDetails();
    const facilityLabel = selectedOrder.orderType == OrderType.RAD ? 'Imaging Center' : 'Lab Center';
    return (
      <VStack>
        <Divider my={2} backgroundColor={'black'} />
        <HStack alignItems={'center'}>
          {facility && (
            <Text flex={1} textAlign="start" fontWeight={500} fontSize={14}>{`${facilityLabel}: ${facility}`}</Text>
          )}
          {providerDetails && (
            <Text flex={1} textAlign="end" fontWeight={500} fontSize={14}>{providerDetails}</Text>
          )}
        </HStack>
      </VStack>
    );
  };

  const printDocument = () => {
    if (printRef?.current && printRef?.current?.handleClick) {
      printRef.current.handleClick();
    }
  }

  const renderPrintedByInfo = () => {
    const downloadedTime = getDateStrFromFormat(new Date(), DATE_FORMATS.MESSAGE_DATE_FORMAT);
    const timezoneAbbr = getCurrentTimeZoneAbbr();
    const signatureUrl = selectedOrder.orderingPhysician?.signatureUrl;
    if(userName && downloadedTime) {
      return (
        <div className="page-break">
          <HStack alignItems="center" my={2}>
            {signatureUrl && <Text fontSize={14} fontWeight={500}>{`Electronically signed document`}</Text>}
            <Spacer />
            <Text fontSize={14} fontWeight={500}>{`Printed By ${userName} on ${downloadedTime} (${timezoneAbbr})`}</Text>
          </HStack>
        </div>
      );
    }
  }

  const renderDiagnosis = () => {
    return (
      <div className="page-break">
        <VStack my={2} space={4}>
          <Text fontSize={14}>{'Diagnosis'}</Text>
          <Divider />
          <VStack marginX={2}>
            {selectedOrder.diagnosis.map((item, index) => {
              return <HStack key={index} flex={1}>
                <Text fontSize={16} fontWeight={600} flex={2}>{item.coding?.[0]?.code}</Text>
                <Text fontSize={16} fontWeight={600} flex={8}>{item.text}</Text>
              </HStack>
            })}
          </VStack>
        </VStack>
      </div>
    );
  }

  const getOrderDetails = () => {
    const details: string[] = [];
    if (selectedOrder.priority) {
      details.push(`Priority: ${getStatusDisplay(selectedOrder.priority)}`);
    }
    if (selectedOrder.orderDate) {
      const date = getDateStrFromFormat(selectedOrder.orderDate, DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT)
      details.push(`Test to be done on: ${date}`);
    }
    return details.join('  •  ');
  }

  const renderTests = () => {
    const orderDetails = getOrderDetails();
    return (
      <div className="page-break">
        <VStack my={2} space={4}>
          <VStack>
            <Text fontSize={14}>{'Test(s)'}</Text>
          </VStack>
          <Divider />
          <VStack marginX={2}>
            {selectedOrder.orders.map((order, index) => {
              return <HStack key={index} flex={1} alignItems={'flex-start'}>
              <Text fontSize={16} fontWeight={600} flex={2}>{getExternalOrderCode(order.order)}</Text>
              <VStack flex={8}>
                <Text fontSize={16} fontWeight={600}>{getExternalOrderDisplay(order.order)}</Text>
                {orderDetails && <Text fontSize={14} fontWeight={500} mb={2}>{orderDetails}</Text>}
              </VStack>
            </HStack>
            })}
          </VStack>
        </VStack>
      </div>
    );
  }

  const renderSignature = () => {
    if (!selectedOrder.orderingPhysician) return <></>;
    const signatureUrl = selectedOrder.orderingPhysician?.signatureUrl;
    return (
      <div className="page-break">
        <HStack>
          <Spacer />
          <VStack my={2} mt={4} width={200}>
            {signatureUrl && (
              <Image
                key={Math.random()}
                resizeMode="contain"
                source={{ uri: signatureUrl }}
                width={'full'}
                height={'16'}
              />
            )}
            {!signatureUrl && (
              <View
                width={'full'}
                height={'16'}
              />
            )}
            <Divider />
            <Text textAlign={'center'} fontSize={14} fontWeight={500} marginLeft={2}>{`Practitioner ${selectedOrder.orderingPhysician.name}`}</Text>
          </VStack>
        </HStack>
      </div>
    );
  }

  const renderNotes = () => {
    if (!selectedOrder.facilityNotes && !selectedOrder.patientNotes) return <></>;
    return (
      <div className="page-break">
        <VStack marginX={2}>
          {selectedOrder.facilityNotes && (
            <HStack alignItems="flex-start">
              <Text fontSize={14} flex={2}>{'Instruction to facility'}</Text>
              <Text fontSize={14} flex={8}>{selectedOrder.facilityNotes}</Text>
            </HStack>
          )}
          {selectedOrder.patientNotes && (
            <HStack alignItems="flex-start">
              <Text fontSize={14} flex={2}>{'Instruction to patient'}</Text>
              <Text fontSize={14} flex={8}>{selectedOrder.patientNotes}</Text>
            </HStack>
          )}
        </VStack>
      </div>
    );
  }

  function previewContent() {
    return (
      <VStack>
        <ReactToPrint
          documentTitle={getDocumentTitle()}
          trigger={() => <></>}
          content={() => (componentRef.current as any)}
          ref={printRef}
          print={(target) => {
            return new Promise<any>((resolve, reject) => {
              if (target.contentDocument) {
                target.contentDocument.title = getDocumentTitle();
              }
              target?.contentWindow?.print?.();
              resolve(true);
            });
          }}

          onAfterPrint={() => {
            props.onClose?.();
          }}
          removeAfterPrint={true}
        />
        <div>
          <ComponentToPrint
            ref={componentRef}
            // header={printElement.header}
            headerComponents={[
              printElement.header,
              renderFacilityAndPractitionerDetails(),
              renderPatientDemographics()
            ]}
            footer={printElement.footer}
          >
            <VStack>
              <VStack paddingX={4}>
                {renderDiagnosis()}
                <Divider my={2} backgroundColor="black" />
                {renderTests()}
                <Divider my={2} backgroundColor="black" />
                {renderNotes()}
                {renderSignature()}
              </VStack>
              <Divider my={2} backgroundColor="black" />
              {renderPrintedByInfo()}
            </VStack>
          </ComponentToPrint>
        </div>

      </VStack>
    );
  }

  useEffect(() => {
    setHeaderAndFooter();
  }, []);

  useEffect(() => {
    if (props.triggerPrint) {
      printDocument();
    }
  }, [props.triggerPrint]);

  return (
    <>
      {
        props.showPreviewInDrawer &&
        <Drawer
          open={props.isOpen || false}
          width={'50%'}
          onClose={props.onClose}
          closable={false}
          title={
            <>
              <ModalActionTitle
                title={'Print Preview'}
                titleColor={''}
                isHeadNotSticky
                buttonList={[
                  {
                    show: true,
                    id: 1,
                    btnText: 'close',
                    textColor: Colors.Custom.mainSecondaryBrown,
                    variant: BUTTON_TYPE.SECONDARY,
                    isTransBtn: false,
                    onClick: () => {
                      props.onClose?.();
                    },
                  },
                  {
                    show: true,
                    id: 1,
                    btnText: 'print',
                    isLoading: props.isLoading || false,
                    textColor: Colors.Custom.mainPrimaryPurple,
                    variant: BUTTON_TYPE.PRIMARY,
                    isTransBtn: false,
                    onClick: () => {
                      printDocument();
                    },
                  }
                ]}
              />
            </>
          }
        >
          {previewContent()}
        </Drawer>
      }
      {
        !props.showPreviewInDrawer &&
        previewContent()
      }
    </>

  );
}
