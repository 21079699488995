import {useLazyQuery} from '@apollo/client';
import {Select, Spin} from 'antd';
import debounce from 'lodash/debounce';
import {HStack, Spacer, Switch, View, VStack, Radio} from 'native-base';
import {useState} from 'react';
import {Text, TouchableOpacity} from 'react-native';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../../constants/Configs';
import {FormsQueries} from '../../../../../../services';
import {Colors} from '../../../../../../styles';
import {DisplayText} from '../../../../../common/DisplayText/DisplayText';
import {IWorkflowGoalView} from './IWorkflowGoalView';
import {styles} from './WorkflowGoalViewCss';
import {Text as TextNB, Image} from 'native-base';
import ModalActionBtn from '../../../../../common/ModalActionBtn/ModalActionBtn';
import {BUTTON_TYPE} from '../../../../../../constants';
import {FoldButton} from '../../../../../CommonComponents/FoldButton/FoldButton';
import {useIntl} from 'react-intl';
const {Option} = Select;

// import { Heading } from 'native-base';

// import { Header } from 'react-native/Libraries/NewAppScreen';

// import { Header } from 'react-native/Libraries/NewAppScreen';
const WorkflowGoalView = (props: IWorkflowGoalView) => {
  const intl = useIntl()
  const [getFormListResponse, formListResponse] = useLazyQuery(
    FormsQueries.GET_ALL_FORMS
  );
  const surveyForm = props.surveyForm;
  const formList = formListResponse?.data?.forms || [];

  const handleSearch = (searchString: string) => {
    getFormListResponse({
      variables: {
        searchString: `%${searchString}%`,
        offset: 0,
        limit: 5,
      },
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
      },
    });
  };

  const GOAL_TYPE = {
    DEFINE_GOAL: 'DEFINE_GOAL',
    SKIP_GOAL: 'SKIP_GOAL',
  };
  const [currentGoal, setCurrentGoal] = useState(GOAL_TYPE.DEFINE_GOAL);
  const optionList = (
    (formList && formList.length ? formList : undefined) ||
    (surveyForm ? [surveyForm] : [])
  ).map((form: any) => {
    return <Option key={form.id}>{form.name}</Option>;
  });

  return (
    <>
      <View>
        <HStack
          style={styles.justifyContentSpaceBetweenAlignItemsCenterMv10}
        >
          <TextNB style={styles.colorBlackFontSize30} size="xxlBold">
            Publish
          </TextNB>
          <HStack space={3}>
            {/* <ModalActionBtn
              textColor={Colors.danger[800]}
              btnText={'cancel'}
              btnStype={BUTTON_TYPE.SECONDARY}
              onClickAction={() => {
              }}
            /> */}
            {props?.tabList?.[0].actions.map((action: any) => {
              return (
                  <FoldButton
                    nativeProps={{
                      variant: BUTTON_TYPE.PRIMARY,
                      onPress: () => {
                        action.onPress();
                      },
                    }}
                    customProps={{
                      btnText: intl.formatMessage({
                        id: action.actionDisplayName,
                      }),
                      withRightBorder: false,
                    }}
                  ></FoldButton>
              );
            })}
          </HStack>
        </HStack>
        <View style={styles.goalSubHeader}>
          <Text style={styles.goalHeaderTextFont}>Conversion goal</Text>
          <Text style={styles.goalSubHeaderTextFont}>
            Measure the performance of your workflow by setting a conversion
            goal.
          </Text>
        </View>
        <View>
          <TouchableOpacity
            style={[
              GOAL_TYPE.DEFINE_GOAL === currentGoal
                ? {
                    borderColor: Colors.primary[600],
                    backgroundColor: Colors.primary[100] + '50',
                  }
                : {borderColor: Colors.Custom.Gray300},
              styles.goalBtnStyle,
            ]}
            onPress={() => {
              setCurrentGoal(GOAL_TYPE.DEFINE_GOAL);
            }}
          >
            <View
              style={styles.rowAlignItemsCenter}
            >
              <Image
                style={styles.imageStyle}
                resizeMode={'contain'}
                // style={styles.logoImg}
                source={{
                  uri: 'https://i.imgur.com/bKaN8Hw.png',
                  cache: 'force-cache',
                }}
              />
              <Text style={styles.defineGoal}>Define Goal</Text>
            </View>

            <View
              style={{
                width: 16,
                height: 16,
                borderWidth: 1,
                borderColor:
                  GOAL_TYPE.DEFINE_GOAL === currentGoal
                    ? Colors.primary[500]
                    : Colors.Custom.Gray300,
                borderRadius: 16,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <View
                style={{
                  backgroundColor:
                    GOAL_TYPE.DEFINE_GOAL === currentGoal
                      ? Colors.primary[500]
                      : '#ffffff',
                  width: 10,
                  height: 10,
                  borderRadius: 12,
                }}
              ></View>
            </View>
            {/* <Text style={styles.recommendedTextFont}>
              Set up conditions to measure workflow success
            </Text> */}
          </TouchableOpacity>
          <TouchableOpacity
            style={[
              GOAL_TYPE.SKIP_GOAL === currentGoal
                ? {borderColor: Colors.primary[600]}
                : {borderColor: Colors.Custom.Gray300},
              styles.goalBtnStyle,
            ]}
            onPress={() => {
              setCurrentGoal(GOAL_TYPE.SKIP_GOAL);
            }}
          >
            <View
              style={styles.rowAlignItemsCenter}
            >
              <Image
                style={styles.imageStyle}
                resizeMode={'contain'}
                // style={styles.logoImg}
                source={{
                  uri: 'https://i.imgur.com/vElsWQM.png',
                  cache: 'force-cache',
                }}
              />
              <Text style={styles.defineGoal}>Skip Goal</Text>
            </View>

            <View
              style={{
                width: 16,
                height: 16,
                borderWidth: 1,
                borderColor:
                  GOAL_TYPE.SKIP_GOAL === currentGoal
                    ? Colors.primary[500]
                    : Colors.Custom.Gray300,
                borderRadius: 16,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <View
                style={{
                  backgroundColor:
                    GOAL_TYPE.SKIP_GOAL === currentGoal
                      ? Colors.primary[500]
                      : '#ffffff',
                  width: 10,
                  height: 10,
                  borderRadius: 12,
                }}
              ></View>
            </View>
            {/* <Text style={styles.recommendedTextFont}>
              Don't track conversions for this workflow
            </Text> */}
          </TouchableOpacity>
        </View>
      </View>
      {GOAL_TYPE.DEFINE_GOAL === currentGoal ? (
        <View>
          <VStack height="auto" marginBottom={5}>
            <HStack>
              <Text>Send Survey Form On Completion Of Workflow</Text>
              <Switch
                marginLeft={2}
                isChecked={props.isSendSurveyForm}
                onToggle={() => {
                  props.setIsSendSurveyForm(!props.isSendSurveyForm);
                }}
              ></Switch>
            </HStack>
          </VStack>
          <VStack maxWidth={630}>
            {props.isSendSurveyForm ? (
              <>
                <VStack marginBottom={2}>
                  <Text style={styles.recommendedTextFont}>
                    <DisplayText textLocalId="selectedSurveyForm" />
                  </Text>
                </VStack>
                <Spacer></Spacer>
                <VStack mb={6}>
                  <Select
                    showSearch
                    value={surveyForm?.id}
                    placeholder={'Search Survey Form'}
                    onSearch={debounce(handleSearch, 500)}
                    defaultActiveFirstOption={false}
                    filterOption={false}
                    notFoundContent={
                      formListResponse.loading ? <Spin size="small" /> : null
                    }
                    onChange={(formId: any) => {
                      if (formId) {
                        const selectedForm = formList.filter(
                          (formData: any) => {
                            return formData.id == formId;
                          }
                        );
                        props.setSurveyForm(selectedForm[0]);
                      } else {
                        props.setSurveyForm({});
                      }
                    }}
                  >
                    {' '}
                    {optionList}
                  </Select>
                </VStack>
              </>
            ) : (
              <></>
            )}
          </VStack>
        </View>
      ) : (
        <></>
      )}
    </>
  );
};

export default WorkflowGoalView;
