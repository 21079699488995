import React, {useState} from 'react';
import type HmsManager from '@100mslive/react-native-hms';
import {HMSSDK} from '@100mslive/react-native-hms';
import {PeerTrackNode} from '../screens/VideoMeetPreview/Utils/types';

export const AppStateContext = React.createContext({});

const AppStateProvider = (props: any) => {
  const [hmsUserName, setHmsUserName] = useState<string>();
  const [hmsRoomID, setHmsRoomID] = useState<string>();
  const [hmsRoomCode, setHmsRoomCode] = useState<string>();
  const [hmsStateInstance, setHmsStateInstance] = useState<HmsManager>();
  const [hmsMirrorLocalVideo, setHmsMirrorLocalVideo] =
    useState<boolean>(false);
  const [hmsMessages, setHmsMessages] = useState<any>([]);
  const [hmsAudioState, setHmsAudioState] = useState<boolean>(true);
  const [hmsVideoState, setHmsVideoState] = useState<boolean>(true);
  const [hmsPeerState, setHmsPeerState] = useState<PeerTrackNode[]>([]);

  const [hmsReference, setHmsReference] = useState<HMSSDK | undefined>();

  return (
    <AppStateContext.Provider
      value={{
        hmsUserName,
        setHmsUserName,
        hmsRoomID,
        setHmsRoomID,
        hmsRoomCode,
        setHmsRoomCode,
        hmsStateInstance,
        setHmsStateInstance,
        hmsMirrorLocalVideo,
        setHmsMirrorLocalVideo,
        hmsMessages,
        setHmsMessages,
        hmsAudioState,
        setHmsAudioState,
        hmsVideoState,
        setHmsVideoState,
        hmsPeerState,
        setHmsPeerState,
        hmsReference,
        setHmsReference,
      }}
    >
      {props.children}
    </AppStateContext.Provider>
  );
};

export default AppStateProvider;
