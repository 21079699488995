import {ReactComponent} from '@foldhealth/formio-react';
import {Formio} from '@foldhealth/formiojs';
import ReactDOM from 'react-dom';
import BasicFields from '../../EditFormFields/BasicFields';
import ConditionalFields from '../../EditFormFields/ConditionalFields';
import KeyField from '../../EditFormFields/KeyField';
import CustomClassComponent from '../CustomWrapper/CustomClassComponent';
import CustomWrapper from '../CustomWrapper/CustomWrapper';
import ConsentAndPreferredCommunication from './ConsentAndPreferredCommunication';
import { CapabilityResource } from '../CustomWrapper/CustomComponentHelper';
import { CONSENT_TYPE_CODES, FORM_CONSENT_TYPE_LIST } from '../../../../../../constants/MlovConst';
import { isEnableCareProgram } from '../../../../../../utils/commonUtils';

export default class ConsentAndPreferredCommunicationComponent extends CustomClassComponent {
  static get builderInfo() {
    return {
      title: 'Member Consent',
      icon: 'filetext1',
      group: 'advanced',
      documentation: '',
      schema: ConsentAndPreferredCommunicationComponent.schema(),
    };
  }

  labelIsHidden() {
    return this.options.renderMode === 'html';
  }

  static schema() {
    return ReactComponent.schema({
      type: 'consentAndPreferredCommunication',
      label: 'Member Consent',
      key: 'consentAndPreferredCommunication'
    });
  }

  attachReact(element: any) {
    return ReactDOM.render(
      <CustomWrapper formOptionData={this.options} capabilityList={[CapabilityResource.patient]}>
        <ConsentAndPreferredCommunication
          options={this.options}
          component={this.component}
          disabled={this.disabled}
          validateRef={this.validateRef}
          onChange={this.updateValue}
        />
      </CustomWrapper>,
      element
    );
  }

  static editForm = function () {
    const filteredBasicFiled = BasicFields.filter(field=>field.key !== 'placeholder') || []
    return {
      key: 'display',
      components: [
        ...filteredBasicFiled,
        {
          type: 'select',
          key: 'consentType',
          label: 'Select Consent Type',
          input: true,
          validate: {
            required: true,
          },
          dataSrc: 'custom',
          defaultValue: CONSENT_TYPE_CODES.COMMUNICATION,
          valueProperty: 'value',
          data: {
            custom(context: any) {
              const isCareProgramEnabled = isEnableCareProgram(
                context?.options?.userSettings
              );

              const filteredFormConsentTypes = isCareProgramEnabled
                ? FORM_CONSENT_TYPE_LIST
                : FORM_CONSENT_TYPE_LIST.filter(
                    (consentType) =>
                      consentType.value === CONSENT_TYPE_CODES.COMMUNICATION
                  );

              return filteredFormConsentTypes;
            },
          }          
        },
        ...ConditionalFields,
        ...KeyField,
      ],
    };
  };
}

Formio.Components.addComponent('consentAndPreferredCommunication', ConsentAndPreferredCommunicationComponent);
