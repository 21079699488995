import React from 'react';
import {Colors} from '../../../styles';
import {ICommonSvgProps} from './interfaces';

const DollorIcon = (props: ICommonSvgProps) => {
  const {
    isEnabled,
    defaultColor,
    customStrokeColor,
    customBgColor,
    borderColor,
  } = props;
  const bgColor = isEnabled
    ? customBgColor
      ? customBgColor
      : Colors.primary['500']
    : customBgColor
    ? customBgColor
    : Colors.primary['200'];
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 0.916748V21.0834M15.5833 4.58342H8.70833C7.85743 4.58342 7.04138 4.92144 6.4397 5.52311C5.83802 6.12479 5.5 6.94085 5.5 7.79175C5.5 8.64265 5.83802 9.4587 6.4397 10.0604C7.04138 10.6621 7.85743 11.0001 8.70833 11.0001H13.2917C14.1426 11.0001 14.9586 11.3381 15.5603 11.9398C16.162 12.5415 16.5 13.3575 16.5 14.2084C16.5 15.0593 16.162 15.8754 15.5603 16.4771C14.9586 17.0787 14.1426 17.4167 13.2917 17.4167H5.5"
        stroke="#667085"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default DollorIcon;
