import {StyleSheet} from 'react-native';
import {Colors} from '../../../styles';

export const styles = StyleSheet.create({
  submitElement: {
    width: 100,
    paddingTop: 10,
    marginBottom: 5,
  },
  cancelElement: {
    width: 100,
    paddingLeft: 10,
    paddingTop: 10,
    marginBottom: 5,
  },
  spinnerStyle: {
    marginVertical: 0,
    marginHorizontal: 'auto',
    color: 'black',
    position: 'absolute',
    zIndex: 1000,
    // backgroundColor: '#0000000f',
    height: '100%',
    width: '100%',
    padding: 0,
  },
  textArea: {
    color: Colors.Custom.TextColor,
    fontSize: 18,
  },
  errorText: {
    marginTop: 8,
  },
  versionText: {
    marginTop: 20,
  },
  stickyVersionBottomList: {
    marginTop: 20,
    marginHorizontal: 12,
  },
});
