import { Drawer,Popover } from 'antd';
import { cloneDeep } from 'lodash';
import {
  Box,
  Divider,
  HStack,
  Icon,
  IconButton,
  Pressable,
  Spacer,
  Text,
  VStack,
  View,
  useToast
} from 'native-base';
import React,{ FC,useContext,useEffect,useState } from 'react';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { useSearchParams } from 'react-router-dom';
import { Handle,NodeProps,Position } from 'reactflow';
import { getId } from '..';
import {
  FlowType,
  WorkflowContext
} from '../../../../../context/WorkflowContext';
import { Colors } from '../../../../../styles';
import { ToastType,showToast } from '../../../../../utils/commonViewUtils';
import CircleIconView,{
  NodeCategory
} from '../../../../common/CircleIconView/CircleIconView';
import { CustomAutomationIcon } from '../../../../common/CircleIconView/CustomAutomationIcon';
import { getNextNodeDependOnNaturalLanguage } from '../../workflowUtils';
import DynamicAutomationIcon from './DynamicAutomationIcon';
import {
  canShowField,
  getDisplayLabel,
  getDisplayValueComponent
} from './FlowNodeHelper';
import {
  IAddNewOptionInfo,
  IConditionState,
  IUserInputField,
  IUserInputFieldMap
} from './FlowNodeInterface';
import NewOutputModel from './NewOutputModel';
import { RequiredSourceNodeError } from './NodeInputField';
import NodeLevelExecutionLogFirst from './NodeLevelExecutionLogFirst';
import NodeLevelExecutionLogSecond from './NodeLevelExecutionLogSecond';
import ShowFilterTotalCountInputField from './ShowFilterTotalCountInputField';
import ShowFilterTotalCountOnNode from './ShowFilterTotalCountOnNode';
import SideMenu from './SideMenu';
import SideMenuHeader from './SideMenuHeader';
import WorkflowNodeDisplayText from './WorkflowNodeDisplayText';
import { reactStyles, styles } from '../../workflowStyles';

const CATEGORY = {
  OPERATION: 'OPERATION',
  FILTER: 'FILTER',
  CONDITION: 'CONDITION'
}

const HandleList = (props1: {
  index: number,
  handle: any,
  handleList: any,
  nodeMasterDataMap: any,
  widthHeightRatio: number,
  isNewlyAdded: boolean,
  getColorByLambdaState: any,
  isHideFalseOption: boolean,
  isExecutionLog: boolean,
  outputNodeList: any,
  optionMap: any,
  getPopoverBorder: any,
  isHoveredOverNode: boolean,
  showModalData: any,
  setShowModalData: any,
  workflowContext: any,
  isViewOnly: boolean,
  nodeId: any,
  lambdaState: any,
  rootNodeType: any,
  onPress: any,
}) => {
  const index = props1.index;
  const handle = props1.handle;
  const [isHoveredOverOption, setIsHoveredOverOption] = useState(false)
  const [isHoverOverButton, setIsHoverOverButton] = useState(false)
  const isLastIndex = index == (props1.handleList.length - 1);
  const key = handle.key;
  const displayName = handle.displayName || key;
  const sourceHandle = key;
  const allOutputNodeList: Array<string> = handle?.isOnlyAddOptionOutputNodeList ? [] : [...props1?.outputNodeList];
  const iconInfo = handle.iconInfo || {};
  (props1.nodeMasterDataMap[handle?.nodeType]?.outputNodeList || []).forEach(
    (nextNodeType: string) => {
      if (
        allOutputNodeList.indexOf(
          nextNodeType
        ) === -1
      ) {
        allOutputNodeList.push(nextNodeType);
      }
    }
  );

  // if (index == 0) {
  //   // alert(JSON.stringify(props?.data))
  //   if (props?.data?.metaData?.naturalLanguageParseNodeList?.length) {
  //     const naturalLanguageParseNodeList = props?.data?.metaData?.naturalLanguageParseNodeList || [];
  //     // alert(JSON.stringify(naturalLanguageParseNode))
  //     props?.data?.setData ? props.data?.setData('naturalLanguageParseNodeList', []) : '';
  //     const nextNode = getNextNodeDependOnNaturalLanguage(naturalLanguageParseNodeList, sourceHandle, outputNodeList, nodeMasterDataMap);
  //     if (nextNode) {
  //       props.onPress(nextNode, 0, 0, false);
  //     }
  //   }
  // }

  return (
    <Pressable
      onHoverIn={() => { setIsHoveredOverOption(true) }}
      onHoverOut={() => { setIsHoveredOverOption(false) }}
      paddingBottom={10}
      style={styles.pressableStyle}
      >
      <Box
        style={styles.padding6}
        borderRadius={8}
        borderWidth={0.5}
        marginRight={isLastIndex ? 0 : 2}
        borderColor={
          props1.isNewlyAdded ? props1.getColorByLambdaState({ params: { isBorderColor: true } }) :
            isHoveredOverOption || props1.isHoveredOverNode ?
              props1.getColorByLambdaState({ params: { isBorderColor: true } }) : Colors.FoldPixel.GRAY200
        }
        backgroundColor={
          props1.isNewlyAdded ? props1.getColorByLambdaState({ params: { isBackgroundColor: true } }) :
            props1.isHoveredOverNode || isHoveredOverOption ? props1.getColorByLambdaState({ params: { isBackgroundColor: true } }) : 'white'
        }
      >
        <HStack justifyContent={'center'} space={1}>
          <CircleIconView
            borderWidth='5px'
            size={'28px'}
            iconInfo={iconInfo}
            nodeCategory={props1.lambdaState?.category?.code || NodeCategory.moment}
            backgroundColor={Colors.FoldPixel.TRANSPARENT}
            borderColor={Colors.FoldPixel.TRANSPARENT}
            iconColor={props1.getColorByLambdaState({ params: { isBorderColor: true } })}
          />
          <Text
            alignSelf={'center'}
            fontSize={12}
            fontWeight={400}
            lineHeight={'120%'}
            color={
              (props1.isNewlyAdded ||
                isHoverOverButton ||
                isHoveredOverOption ||
                props1.isHoveredOverNode) ? Colors.FoldPixel.GRAY400 :
                Colors.FoldPixel.GRAY200
            }
          >
            {!props1.isHideFalseOption ? displayName : 'Then'}
          </Text>
        </HStack>
      </Box>
      <VStack key={sourceHandle + index}>

        <NewOutputModel
          parentDisplayName={displayName + ', then'}
          allOutputNodeList={allOutputNodeList}
          showModalData={props1.showModalData}
          sourceHandle={sourceHandle}
          setShowModalData={props1.setShowModalData}
          onPress={props1.onPress}
        ></NewOutputModel>
      </VStack>
      <HStack justifyContent={'center'}>
        <Pressable
          top={-10}
          onPress={(event: any) => {
            if (props1.isViewOnly) {
              return
            }
            props1.onPress({
              sourceHandle: sourceHandle,
              isFocusOnSelectNextNode: true,
              metaData: {},
            }, event?.clientX, event?.clientY, false);
          }}
          onHoverIn={() => {
            setIsHoverOverButton(true)
          }}
          onHoverOut={() => {
            setIsHoverOverButton(false)
          }}
        >
          <Box>
            {((isHoveredOverOption || isHoverOverButton) || (props1.workflowContext?.focusOnSelectNextNode?.sourceHandle == sourceHandle && props1.workflowContext?.focusOnSelectNextNode?.sourceId === props1.nodeId)) && !props1.isExecutionLog && !props1?.isViewOnly && props1.workflowContext.isAllowInterAction ? (
              <DynamicAutomationIcon
                isHoveredOverButtonMenu={isHoverOverButton || (props1.workflowContext?.focusOnSelectNextNode?.sourceHandle == sourceHandle && props1.workflowContext?.focusOnSelectNextNode?.sourceId === props1.nodeId)}
              />
            ) : null}
            <Handle
              key={sourceHandle}
              type="source"
              position={Position.Bottom}
              style={{
                marginTop: props1.isExecutionLog ? -10 : undefined,
                top: -3,
                position: 'absolute',
                width: '100%',
                height: '20px',
                borderWidth: 0,
                backgroundColor: 'transparent',
                borderRadius: 0,
              }}
              id={sourceHandle}
            />
          </Box>
        </Pressable>
      </HStack>
    </Pressable>
  );
}

function ButtonMenu(props: {
  isExecutionLog? : boolean;
  isNewlyAdded?: boolean;
  isHoveredOverNode?: boolean;
  getColorByLambdaState? : any;
  nodeId: string;
  previousImmediateNode?: string | undefined;
  optionMap?: IUserInputFieldMap;
  isViewOnly?: boolean;
  data?:any,
  parentDisplayName:string;
  prevConditionNodeType: string | undefined;
  getOutputNodeList: (
    nodeId: string,
    outputNodeList: Array<string>,
    isNeedParentList?: boolean,
    mergeParentNodeOutputList?: boolean
  ) => Array<string>;
  lambdaState: IConditionState;
  nodeMasterDataMap: { [key: string]: IConditionState };
  onPress: (node: any, clientX: number, clientY: number, isEdit?:boolean) => void;
}) {
  const isNewlyAdded = props?.isNewlyAdded;
  const isExecutionLog = props?.isExecutionLog;
  const isHoveredOverNode = props.isHoveredOverNode;
  const getColorByLambdaState = props.getColorByLambdaState;
  const nodeMasterDataMap = props.nodeMasterDataMap;
  const workflowContext = useContext(WorkflowContext);
  const [showModalData, setShowModalData] = useState({
    showModal: false,
    source: '',
    clientX: 0,
    clientY: 0,
  });

  const handleList: Array<{
    nodeType: string;
    key: string;
    displayName: string;
    isOnlyAddOptionOutputNodeList?:boolean;
    color: string;
    iconInfo: {lib: string, icon: string};
  }> = [];
  const prevConditionNodeType = props.prevConditionNodeType;

  const optionInfo = prevConditionNodeType
    ? nodeMasterDataMap[prevConditionNodeType]?.optionInfo
    : undefined;

  const isHideFalseOption = props.prevConditionNodeType
    ? props.nodeMasterDataMap[props.prevConditionNodeType]?.isHideFalseOption
    : false;
  const optionMap = props.optionMap || {};
  const possibleValueList:any = props.optionMap
    ? Object.keys(props.optionMap || {}).map((key: string) => {
      const data: any = optionMap[key];
      return {
        nodeType: data.nodeType,
        value: data.key || data.value,
        displayName: data.displayName,
        color: data.color,
        isOnlyAddOptionOutputNodeList: data?.isOnlyAddOptionOutputNodeList,
        iconInfo: data.iconInfo,
      };
    })
    : optionInfo?.possibleValueList || [];

  if (
    !isHideFalseOption &&
    possibleValueList &&
    possibleValueList.length == 1
  ) {
    handleList.push({
      nodeType: possibleValueList[0]?.nodeType,
      key: possibleValueList[0]?.nodeType,
      isOnlyAddOptionOutputNodeList: possibleValueList[0]?.isOnlyAddOptionOutputNodeList,
      displayName: 'True',
      color: 'green.500',
      iconInfo: {
        lib: possibleValueList[0]?.iconInfo?.lib,
        icon: possibleValueList[0]?.iconInfo?.icon,
      }
    });
    handleList.push({
      nodeType: '',
      key: 'default',
      displayName: 'False',
      isOnlyAddOptionOutputNodeList:false,
      color: 'red.500',
      iconInfo: {
        lib: possibleValueList[0]?.iconInfo?.lib,
        icon: possibleValueList[0]?.iconInfo?.icon,
      }
    });
  } else if (possibleValueList.length == 1) {
    handleList.push({
      nodeType: possibleValueList[0]?.nodeType,
      key: possibleValueList[0]?.value,
      displayName: possibleValueList[0]?.displayName || 'True',
      color: possibleValueList[0]?.color || 'green.500',
      isOnlyAddOptionOutputNodeList: possibleValueList[0]?.isOnlyAddOptionOutputNodeList,
      iconInfo: {
        lib: possibleValueList[0]?.iconInfo?.lib,
        icon: possibleValueList[0]?.iconInfo?.icon,
      }
    });
  } else {
    (possibleValueList || []).forEach((data: any, index:number) => {
      handleList.push({
        nodeType: data.nodeType,
        key: data.value,
        displayName: data.displayName,
        isOnlyAddOptionOutputNodeList: data?.isOnlyAddOptionOutputNodeList,
        color: data.color || '',
        iconInfo: {
          lib: possibleValueList[index]?.iconInfo?.lib,
          icon: possibleValueList[index]?.iconInfo?.icon,
        }
      });
    });
  }

  let outputNodeList = props.lambdaState?.outputNodeList || [];
  const mergeParentNodeOutputList =
    workflowContext.flowType !== FlowType.careJourney;
  outputNodeList = props.getOutputNodeList
    ? props.getOutputNodeList(
      props.nodeId,
      outputNodeList,
      false,
      mergeParentNodeOutputList
    )
    : outputNodeList;
  outputNodeList = outputNodeList.filter((id) => {
    return id != props.lambdaState?.type || id == 'AutomationTrigger';
  });

  
  const userInputFieldList: any = [];
  const [timeoutId, setTimeoutId] = useState<any>(undefined);
  const widthHeightRatio = props?.data?.reactFlowInstance ? props?.data?.reactFlowInstance?.getZoom() : 1
  const getPopoverBorder = () => {
    const borderColor = getColorByLambdaState({params:{isBorderColor : true}})
    const borderString = '0.5px solid '+ borderColor;
    return borderString;
  }

  // const HandleList = (props1:{index:number, handle:any})=>{
  //   const index = props1.index;
  //   const handle = props1.handle;
  //   const [isHoveredOverOption, setIsHoveredOverOption] = useState(false)
  //   const [isHoverOverButton, setIsHoverOverButton] = useState(false)
  //   const isLastIndex = index ==( handleList.length-1);
  //   const key = handle.key;
  //   const displayName = handle.displayName || key;
  //   const sourceHandle = key;
  //   const allOutputNodeList: Array<string> = handle?.isOnlyAddOptionOutputNodeList ?  [] : [...outputNodeList];
  //   const iconInfo = handle.iconInfo || {};
  //   (nodeMasterDataMap[handle?.nodeType]?.outputNodeList || []).forEach(
  //     (nextNodeType) => {
  //       if (
  //         allOutputNodeList.indexOf(
  //           nextNodeType
  //         ) === -1
  //       ) {
  //         allOutputNodeList.push(nextNodeType);
  //       }
  //     }
  //   );


  //   if (index == 0) {
  //     // alert(JSON.stringify(props?.data))
  //     if (props?.data?.metaData?.naturalLanguageParseNodeList?.length) {
  //       const naturalLanguageParseNodeList = props?.data?.metaData?.naturalLanguageParseNodeList || [];
  //       // alert(JSON.stringify(naturalLanguageParseNode))
  //       props?.data?.setData ? props.data?.setData('naturalLanguageParseNodeList', []) : '';
  //       const nextNode = getNextNodeDependOnNaturalLanguage(naturalLanguageParseNodeList, sourceHandle, outputNodeList, nodeMasterDataMap);
  //       if (nextNode) {
  //         props.onPress(nextNode, 0, 0, false);
  //       }
  //     }
  //   }
    
  //   return (
  //     <Pressable
  //     onHoverIn={()=>{setIsHoveredOverOption(true)}}
  //     onHoverOut={()=> {setIsHoveredOverOption(false)}}
  //     paddingBottom={10}
  //     style={ {
  //       minWidth:123,
  //       maxWidth:'fit-content'
  //     }}

  //     >
  //     <Popover
  //       content = {() => renderConditionPopoverContent(sourceHandle)}
  //       showArrow={false}
  //       open = {false&&isHoveredOverOption && nodeMasterDataMap?.[props?.optionMap?.[sourceHandle]?.nodeType || '']?.isMemberFilterNode && props?.data?.rootNodeType == 'ForAllPatientWithFilter'}
  //       overlayInnerStyle={{
  //         borderRadius: 8*widthHeightRatio,
  //         border: getPopoverBorder(),
  //         // padding:12*widthHeightRatio
  //       }}
  //     >
  //       <Box
  //         style={ {
  //           padding:6
  //         }}
  //         borderRadius={8}
  //         borderWidth={0.5}
  //         marginRight={ isLastIndex ? 0 : 2 }
  //         borderColor={
  //           // !isHideFalseOption ? handle.color : Colors.secondary[300]
  //           isNewlyAdded? getColorByLambdaState({params: {isBorderColor : true}}) :
  //           isHoveredOverOption || isHoveredOverNode?
  //           getColorByLambdaState({params:{isBorderColor: true}}) : Colors.FoldPixel.GRAY200
  //         }
  //         backgroundColor={
  //           // !isHideFalseOption ? handle.color : Colors.secondary[100]
  //             isNewlyAdded? getColorByLambdaState({params: {isBackgroundColor : true}}) :
  //             isHoveredOverNode || isHoveredOverOption ? getColorByLambdaState({params: {isBackgroundColor : true}}) : 'white'
  //         }
  //       >
  //       <HStack justifyContent={'center'} space={1}>
  //         <CircleIconView
  //           borderWidth='5px'
  //           size={'28px'}
  //           iconInfo={iconInfo}
  //           nodeCategory={props.lambdaState?.category?.code || NodeCategory.moment}
  //           backgroundColor={Colors.FoldPixel.TRANSPARENT}
  //           borderColor={Colors.FoldPixel.TRANSPARENT}
  //           iconColor={getColorByLambdaState({ params: { isBorderColor: true }})}
  //         />
  //         <Text
  //           alignSelf={'center'}
  //           fontSize={12}
  //           fontWeight={400}
  //           lineHeight={'120%'}
  //           color={
  //             // !isHideFalseOption
  //             //   ? '#ffffff'
  //             //   : Colors.secondary[600]
  //             ( isNewlyAdded || 
  //               isHoverOverButton || 
  //               isHoveredOverOption || 
  //               isHoveredOverNode) ? Colors.FoldPixel.GRAY400 : 
  //               Colors.FoldPixel.GRAY200
  //           }
  //         >
  //           {!isHideFalseOption ? displayName : 'Then'}
  //         </Text>
  //         </HStack>
  //       </Box>
  //       <VStack key={sourceHandle + index}>

  //       <NewOutputModel
  //         parentDisplayName={ displayName + ', then'}
  //         allOutputNodeList={allOutputNodeList}
  //         showModalData={showModalData}
  //         sourceHandle={sourceHandle}
  //         setShowModalData={setShowModalData}
  //         onPress={props.onPress}
  //       ></NewOutputModel>
  //       </VStack>
  //       <HStack justifyContent={'center'}>
  //         <Pressable
  //           // marginTop={}
  //           top={-10}
  //             onPress={(event: any) => {
  //               if (props.isViewOnly) {
  //                 return
  //               }
  //               props.onPress({
  //                 sourceHandle: sourceHandle,
  //                 isFocusOnSelectNextNode: true ,
  //                 metaData: {},
  //               }, event?.clientX, event?.clientY, false);
  //               // setShowModalData({
  //               //   showModal: true,
  //               //   source: sourceHandle,
  //               //   clientX: event?.clientX,
  //               //   clientY: event?.clientY,
  //               // });
  //           }}
  //           onHoverIn={()=> {
  //             setIsHoverOverButton(true)
              

  //           }}
  //           onHoverOut={()=> {
  //             setIsHoverOverButton(false)
              
  //           }}
  //         >
  //           <Box>
  //             {'isHoveredOverOption' + isHoveredOverOption}
  //             {'isHoverOverButton' + isHoverOverButton}
  //             {'props?.isViewOnly' + props?.isViewOnly}
  //             {'workflowContext.isAllowInterAction' + workflowContext.isAllowInterAction}
  //           {((isHoveredOverOption || isHoverOverButton) || (workflowContext?.focusOnSelectNextNode?.sourceHandle == sourceHandle && workflowContext?.focusOnSelectNextNode?.sourceId === props.nodeId)) && !isExecutionLog && !props?.isViewOnly && workflowContext.isAllowInterAction  ? (
  //             <DynamicAutomationIcon
  //               isHoveredOverButtonMenu={isHoverOverButton || (workflowContext?.focusOnSelectNextNode?.sourceHandle == sourceHandle && workflowContext?.focusOnSelectNextNode?.sourceId === props.nodeId)}
  //             />
  //           ) : null}
  //           <Handle

  //           key={sourceHandle}
  //           type="source"
  //           position={Position.Bottom}
  //           style={ {
  //             marginTop: isExecutionLog? -10 : undefined,
  //             top:-3,
  //             position: 'absolute',
  //             width: '100%',
  //             height: '20px',
  //             borderWidth: 0,
  //             backgroundColor: 'transparent',
  //             borderRadius: 0,
  //           }}
  //           id={sourceHandle}
  //         />
         
  //         </Box>

  //         </Pressable>
  //       </HStack>
  //     </Popover>
  //     </Pressable>
  //   );
  // }

  if (
    workflowContext.flowType !== FlowType.careJourney &&
    !outputNodeList.length
  ) {
    return <></>;
  }

  return (
    <HStack justifyContent="space-evenly" flex={1}>
      {handleList.map((handle, index) => {
        return (
          <HandleList
            optionMap={optionMap}
            handle={handle}
            index={index}
            handleList={handleList}
            nodeMasterDataMap={nodeMasterDataMap}
            widthHeightRatio={widthHeightRatio}
            isNewlyAdded={isNewlyAdded || false}
            getColorByLambdaState={getColorByLambdaState}
            isHideFalseOption={isHideFalseOption || false}
            isExecutionLog={isExecutionLog || false}
            outputNodeList={outputNodeList}
            getPopoverBorder={getPopoverBorder}
            isHoveredOverNode={isHoveredOverNode || false}
            showModalData={showModalData}
            setShowModalData={setShowModalData}
            workflowContext={workflowContext}
            isViewOnly={props.isViewOnly || false}
            nodeId={props?.nodeId}
            lambdaState={props?.lambdaState}
            rootNodeType={props?.data?.rootNodeType}
            onPress={props?.onPress}         
          />
        )
      })}
    </HStack>
  );
}

const getDefaultUserInputList = (
  nodeMasterDataMap: { [key: string]: any },
  nodeType: string,
  prevConditionNodeType: string | undefined
): {
  addNewOptionInfo: IAddNewOptionInfo;
  isAllowEdit: boolean;
  defaultOptionMap: IUserInputFieldMap;
  defaultOptionKey: string;
  defaultOptionValue: string;
  optionList: Array<{ displayName: string; value: string; nodeType: string, iconInfo: {lib: string, icon: string} }>;
} => {
  let isAllowAddNewOption = false;
  let defaultOptionKey = '';
  let defaultOptionValue = '';
  let isAllowEdit = false;
  let defaultNodeTypeOnNewOption = undefined;
  let optionDisplayName = undefined;
  let optionList: Array<{
    isOnlyAddOptionOutputNodeList?: boolean;
    color: string;
    displayName: string;
    value: string;
    isAllowDelete?: boolean;
    nodeType: string;
    iconInfo: {
      lib: string;
      icon: string;
    }
  }> = [];
  const defaultOptionMap: IUserInputFieldMap = {};
  prevConditionNodeType = nodeMasterDataMap[nodeType]?.awsState == 'Condition' && nodeMasterDataMap[nodeType]?.optionInfo ? nodeType : prevConditionNodeType;
  if (!prevConditionNodeType) {
    return {
      defaultOptionKey,
      defaultOptionValue,
      optionList,
      defaultOptionMap,
      isAllowEdit: isAllowEdit,
      addNewOptionInfo: { isAllowAddNewOption },
    };
  }
  const prevNodeType = prevConditionNodeType;
  if (!prevNodeType || !nodeMasterDataMap[prevNodeType]) {
    return {
      defaultOptionKey,
      defaultOptionValue,
      optionList,
      defaultOptionMap,
      isAllowEdit: isAllowEdit,
      addNewOptionInfo: { isAllowAddNewOption },
    };
  }
  const optionInfo = JSON.parse(
    JSON.stringify(nodeMasterDataMap[prevNodeType]?.optionInfo || {})
  );
  defaultOptionKey = optionInfo?.key;
  defaultOptionValue = optionInfo?.defaultValue;
  optionList = optionInfo?.possibleValueList || [];
  isAllowAddNewOption = !!optionInfo.isAllowAddNewOption;
  defaultNodeTypeOnNewOption = optionInfo.defaultNodeTypeOnNewOption;
  optionDisplayName = optionInfo.optionDisplayName;
  const editableConditionList = optionList.filter((defaultListInfo) => {
    const list =
      JSON.parse(
        JSON.stringify(
          nodeMasterDataMap[defaultListInfo?.nodeType]?.userInputFieldList || []
        )
      ) || [];
    if (list.length) {
      isAllowEdit = true;
    }
    return true;
  });

  editableConditionList.forEach((defaultListInfo) => {
    const defaultUserInputFieldList: Array<IUserInputField> = [];
    const list =
      JSON.parse(
        JSON.stringify(
          nodeMasterDataMap[defaultListInfo?.nodeType]?.userInputFieldList || []
        )
      ) || [];
    list.forEach((userInputField: IUserInputField) => {
      userInputField = {
        ...userInputField,
        value:
          userInputField.value == undefined
            ? userInputField.defaultValue
            : userInputField.value,
      };
      defaultUserInputFieldList.push(userInputField);
    });
    defaultOptionMap[defaultListInfo?.value] = {
      id: getId(defaultListInfo.nodeType),
      nodeType: defaultListInfo.nodeType,
      value: defaultListInfo.value,
      displayName: defaultListInfo.displayName,
      color: defaultListInfo.color,
      isOnlyAddOptionOutputNodeList:defaultListInfo?.isOnlyAddOptionOutputNodeList,
      isAllowDelete: !!defaultListInfo.isAllowDelete,
      userInputFieldList: defaultUserInputFieldList,
      iconInfo: defaultListInfo.iconInfo
    };
  });
  return {
    defaultOptionKey,
    defaultOptionValue,
    optionList,
    defaultOptionMap,
    isAllowEdit,
    addNewOptionInfo: {
      optionDisplayName,
      defaultNodeTypeOnNewOption,
      isAllowAddNewOption,
    },
  };
};

const ConditionState: FC<NodeProps> = ({ data, id }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const toast = useToast();
  const [isExecutionLog] = useState<any>(searchParams.get('isExecutionLog') || false);
  const workflowContext = useContext(WorkflowContext);
  const nodeMasterDataMap = workflowContext.nodeMasterDataMap;
  const nodeMetaData = workflowContext.nodeMetaData;
  const [nodeType] = useState(data?.nodeType);
  const [rootNodeId] = useState(data?.rootNodeId);
  const getPreviousImmediateNode = data?.getPreviousImmediateNode;
  const [rootNodeType] = useState(data?.rootNodeType);
  const lambdaState: IConditionState = cloneDeep(nodeMasterDataMap[nodeType]);
  const [isViewOnly] = useState(!!data?.isViewOnly)
  const [previousImmediateNode, setPreviousImmediateNode] = useState(
    data?.getPreviousImmediateNode(id)
  );
  const preprocessNode = nodeMasterDataMap[nodeType]?.preprocessNode || [];

  const defaultPrevConditionNodeType = !previousImmediateNode
    ? preprocessNode.length
      ? preprocessNode[preprocessNode.length - 1]
      : []
    : previousImmediateNode?.data?.nodeType || undefined;

  const [prevConditionNodeType, setPrevConditionNodeType] = useState(
    defaultPrevConditionNodeType
  );

  const { optionList, defaultOptionMap, isAllowEdit, addNewOptionInfo } =
    getDefaultUserInputList(nodeMasterDataMap, nodeType, prevConditionNodeType);
  if (!data?.metaData?.optionMap && prevConditionNodeType) {
    data.setData('optionMap', defaultOptionMap);
  }

  const [optionMap, setOptionMap] = useState<IUserInputFieldMap | undefined>(
    data.metaData.optionMap || defaultOptionMap
  );

  const [isEdit, setIsEdit] = useState(data.isEdit || false);

  const [isNewlyAdded, setIsNewlyAdded] = useState(data.isNewlyAdded || false);
  useEffect(()=>{
    if(isNewlyAdded != data.isNewlyAdded){
    setIsNewlyAdded(data.isNewlyAdded)
    }
  }, [data.isNewlyAdded])

  const displayName =
    lambdaState?.nodeCardTitle ||
    nodeMasterDataMap[prevConditionNodeType]?.optionInfo?.displayName ||
    lambdaState?.displayName ||
    nodeType;

  const getOutputNodeList = data?.getOutputNodeList;
  const replaceNodeById = data?.replaceNodeById;

  useEffect(() => {
    const currentPreviousImmediateNode = data?.getPreviousImmediateNode(id);
    if (currentPreviousImmediateNode != previousImmediateNode) {
      setPreviousImmediateNode(currentPreviousImmediateNode);
    }
    const preprocessNode = nodeMasterDataMap[nodeType]?.preprocessNode || [];
    const prevConditionNodeType = preprocessNode.length
      ? preprocessNode[preprocessNode.length - 1]
      : currentPreviousImmediateNode?.data?.nodeType || undefined;
    setPrevConditionNodeType(prevConditionNodeType);
    const { defaultOptionMap } = getDefaultUserInputList(
      nodeMasterDataMap,
      nodeType,
      prevConditionNodeType
    );
    if (data?.metaData?.optionMap && prevConditionNodeType) {
      if(JSON.stringify(data?.metaData?.optionMap) != JSON.stringify(optionMap))
      setOptionMap(JSON.parse(JSON.stringify(data?.metaData?.optionMap)));
    } else if (prevConditionNodeType) {
      setOptionMap(defaultOptionMap);
    } else if (data?.metaData?.optionMap != optionMap) {
      saveOptionMapValue(undefined);
    }
  }, [
    data?.getPreviousImmediateNode(id),
    data?.metaData,
    data?.metaData?.optionMap,
  ]);

  const onCloseEditMode = () => {
    setIsEdit(false);
  };

  const isRequiredSourceNodeError = (): boolean => {
    const requiredNode = getRequiredSourceNodeIfConnected();
    const isInValid = requiredSourceNodes.length > 0 && !requiredNode;
    return isInValid && !prevConditionNodeType;
  };

  const getRequiredSourceNodeIfConnected = () => {
    const requiredConnectedNode = requiredSourceNodes.filter(
      (node) => node.type && node.type === previousImmediateNode?.data?.nodeType
    );
    if (requiredConnectedNode.length > 0) {
      return previousImmediateNode;
    }
  };

  const getRequiredSourceNodes = () => {
    const requiredNodeSourceNames: any[] = [];
    Object.keys(nodeMasterDataMap).forEach((key: string) => {
      if (
        (nodeMasterDataMap[key]?.outputNodeList || []).indexOf(nodeType) != -1
      ) {
        requiredNodeSourceNames.push(nodeMasterDataMap[key]);
      }
    });
    return requiredNodeSourceNames;
  };

  const saveOptionMapValue = (optionMap: IUserInputFieldMap | undefined) => {
    data.setData('optionMap', optionMap);
    setOptionMap(optionMap);
  };

  const requiredSourceNodes = getRequiredSourceNodes();
  if (
    isRequiredSourceNodeError() &&
    data?.metaData?.userInputFieldList?.length
  ) {
    saveOptionMapValue(undefined);
  }

  const isInvalid = isRequiredSourceNodeError();

  const onSaveEditMode = (info: any) => {
    saveOptionMapValue({...info.userInputFieldMap});
    setIsEdit(false);
  };

  let firstUserInputFieldList: Array<IUserInputField> = [];

  if (optionMap && optionList.length) {
    firstUserInputFieldList =
      optionMap[Object.keys(optionMap)[0]]?.userInputFieldList || [];
  }

  const previousImmediateNodeId = previousImmediateNode?.id;

  const mergeParentNodeOutputList =
    workflowContext.flowType !== FlowType.careJourney;

  let parentNodeOutputList: Array<string> = [];
  if (previousImmediateNodeId) {
    const outputNodeData = getOutputNodeList
      ? getOutputNodeList(id, [], true, mergeParentNodeOutputList)
      : { parentNodeOutputList: [] };
    parentNodeOutputList = outputNodeData.parentNodeOutputList || [];
  }

  const nodeDisplayTemplate = nodeMasterDataMap?.[nodeType]?.nodeDisplayTemplate || nodeMasterDataMap?.[prevConditionNodeType]?.optionInfo?.nodeDisplayTemplate;
  const isShowNodeDisplayTemplate = nodeDisplayTemplate;
  const showCardTitle = !!(lambdaState?.nodeCardTitle || lambdaState?.displayName || !isShowNodeDisplayTemplate);

  const isDuplicateKey = (data: any) => {
    let isDuplicateValue = false;
    const idSet = new Set(); // a Set stores unique values of any type
    isDuplicateValue = Object.keys(data?.userInputFieldMap).some((key: string) => {
      const info = data?.userInputFieldMap?.[key];
      const hasDuplicate = info?.userInputFieldList?.some((element: any) => {
        if (element?.key === 'whenPressKey') {
          if (idSet.has(element?.value)) {
            showToast(
              toast,
             'Number Already Present',
              ToastType.error
            );
          }
          // returns true for the first duplicate and terminates .some()
          return idSet.size === idSet.add(element?.value).size;
        }
      });
      return hasDuplicate;
    });
    return isDuplicateValue;
  };
  const [isHoveredOverNode, setIsHoveredOverNode] = useState(false)

  const getColorByLambdaState = ({ params: { isBorderColor = false, isIconBackground = false, isBackgroundColor = false } }) => {
    const categoryCode = lambdaState?.category?.code || '';
    const colorMap = {
        isBorderColor: {
            [CATEGORY.FILTER]: Colors.FoldPixel.ACCENT_DARK_PINK,
            [CATEGORY.OPERATION]: Colors.FoldPixel.GRAY200,
            default: Colors.FoldPixel.PRIMARY300
        },
        isIconBackground: {
            [CATEGORY.FILTER]: '#FBD4E1',
            [CATEGORY.OPERATION]: Colors.FoldPixel.GRAY100,
            default: '#8F5AE21A'
        },
        isBackgroundColor: {
            [CATEGORY.FILTER]: Colors.FoldPixel.ACCENT_LIGHT_PINK,
            [CATEGORY.OPERATION]: Colors.FoldPixel.GRAY50,
            default: Colors.FoldPixel.PRIMARY100
        }
    };

    const getColor = (map:any, code:string) => {
      return map[code] || map.default || 'white'
    };

    if (isBorderColor) {return getColor(colorMap.isBorderColor, categoryCode)};
    if (isIconBackground) {return getColor(colorMap.isIconBackground, categoryCode)};
    if (isBackgroundColor) {return getColor(colorMap.isBackgroundColor, categoryCode)};
    return 'white';
};


  const [timeoutId, setTimeoutId] = useState<any>(undefined);

  return (
    <div className={  workflowContext?.focusOnSelectNextNode?.isFocusOnSelectNextNode && workflowContext?.focusOnSelectNextNode?.sourceId !== id ? 'blurDiv': '' } style={reactStyles.full} >
    <View alignItems={'center'}>
    <HStack justifyContent={'center'} width={250}>
      {
        isExecutionLog ?
          <HStack
          //  margin={0} borderStyle={'solid'} roundedLeft="md" shadow={1} borderColor={'gray.100'} borderTopWidth={1} borderBottomWidth={1} borderLeftWidth={1} height={70}
           // margin={0}
           borderStyle={'solid'}
           roundedLeft="md"
           // shadow={1}
           // borderColor={'gray.100'}
           borderWidth={0.5}
           borderRightWidth={0}
           // height={70}
           borderLeftRadius={12}
           // width={}
           borderColor={(
             isNewlyAdded ? getColorByLambdaState({ params: { isBorderColor: true } }) :
             isHoveredOverNode ? getColorByLambdaState({ params: { isBorderColor: true } }) : Colors.FoldPixel.GRAY200
             )}
           >
            <NodeLevelExecutionLogFirst rootNodeType={rootNodeType} rootNodeId={rootNodeId} parentNode={previousImmediateNodeId} nodeMetaData={lambdaState} uiNodeId={id} />
            <NodeLevelExecutionLogSecond rootNodeId={rootNodeId} parentNode={previousImmediateNodeId} nodeMetaData={lambdaState} uiNodeId={id} />
          </HStack> : <></>
      }

      <Pressable
        width={'100%'}
        cursor={'pointer'}
        onPress={()=>{
          if(data.isWorkFlowEditable && isAllowEdit && !lambdaState?.disableEdit && !lambdaState.disableEditAndDeleteActions){
            setIsEdit(true)
          }
        }}
        onHoverIn={() => {
          // if(timeoutId) {
          //   clearTimeout(timeoutId)
          //   setTimeoutId(undefined)
          // }
          setIsHoveredOverNode(true)
        }}

        onHoverOut={() => {
          // if (timeoutId) {
          //   clearTimeout(timeoutId)
          //   setTimeoutId(undefined)
          // }
          // const timeoutIdNew = setTimeout(() => {
            setIsHoveredOverNode(false)
          //   setTimeoutId(undefined)
          // },0)
          // setTimeoutId(timeoutIdNew)

        }}

      >
      <Box
        minHeight={isExecutionLog? '100%' : 70}
        style={styles.padding12}
        borderRightRadius={12}
        borderLeftRadius={isExecutionLog ? 'none' : 12}
        borderLeftWidth={isExecutionLog? 0 : 0.5}
        borderColor={(
          isNewlyAdded? getColorByLambdaState({params: {isBorderColor : true}}) :
          isHoveredOverNode? getColorByLambdaState({params:{isBorderColor : true}}) : Colors.FoldPixel.GRAY200 
        )}
        borderWidth='0.5'
        backgroundColor={
          isNewlyAdded? getColorByLambdaState({params: {isBackgroundColor : true}}) :
          isHoveredOverNode? getColorByLambdaState({params: {isBackgroundColor : true}}) : 'white'
        }
        width={'100%'}
        borderStyle={data?.isAddedByFold ? 'dashed' : 'solid'}
        justifyContent={"center"}
      >
        <Handle
          type="target"
          position={Position.Top}
          id="in"
          style={reactStyles.handleStyle}
        />


        <HStack space={2}>
          <HStack alignItems={'center'}>
            <CircleIconView
              isSquareView={true}
              borderWidth='5px'
              size={'28px'}
              iconInfo={lambdaState?.iconInfo}
              nodeCategory={lambdaState?.category?.code || NodeCategory.moment}
              backgroundColor={
                data?.isAddedByFold
                  ? Colors.secondary[100]
                  :
                  isNewlyAdded? getColorByLambdaState({params: {isIconBackground: true}}) :
                  isHoveredOverNode? getColorByLambdaState({params: {isIconBackground: true}}) : getColorByLambdaState({params:{isBackgroundColor : true}})
              }
              borderColor={
                data?.isAddedByFold
                  ? Colors.secondary[100]
                  :
                  Colors.FoldPixel.TRANSPARENT
              }
              iconColor={
                data?.isAddedByFold
                  ? Colors.secondary[500]
                  :
                  getColorByLambdaState({params:{isBorderColor : true}})
              }
            />
          </HStack>
          <HStack flex={1} alignItems={'center'} space={1}>

          {isShowNodeDisplayTemplate &&
          <Text
            fontSize={12}
            fontWeight={isHoveredOverNode? '500' : '400'}
            textAlign='left'
            lineHeight={'120%'}
            color={Colors.FoldPixel.GRAY400}
            numberOfLines={1}
            textOverflow={'ellipsis'}
          >
            <WorkflowNodeDisplayText
              isSimpleView={true}
              // isHoveredOverNode={isHoveredOverNode}
              nodeDisplayTemplate={nodeDisplayTemplate}
              previousImmediateNode={previousImmediateNode}
              userInputFieldList={firstUserInputFieldList}
              displayName={displayName}
              nodeType={nodeType}
              isTitle={true}
            />
            </Text>
            }

           {!isShowNodeDisplayTemplate &&
            <Text
              fontSize={12}
              fontWeight={400}
              textAlign='left'
              lineHeight={'120%'}
              color={Colors.FoldPixel.GRAY400}
            >
              {displayName}
            </Text>
             }
          </HStack>

          <HStack>
          {data?.isAddedByFold ? (
          <HStack
            style={styles.addedByFoldViewStyle}
          >
            <View
              style={styles.justifyContentFlexStartAlignItemsCenterDirectionRow}
            >
              <AntIcon
                size={15}
                color={Colors.secondary[500]}
                name={'infocirlceo'}
              />
              <Text
                size={'smRegular'}
                color={Colors.secondary[500]}
                style={styles.alignItemsCenter}
              >
                {`   Added by Fold`}
              </Text>
            </View>
            <View style={styles.rowAlignItemsCenter}>
              {data.isWorkFlowEditable && (
                <IconButton
                  icon={<Icon as={AntIcon} color={Colors.secondary[700]} size="xs" name={isViewOnly ? 'eyeo' : "edit"} />}
                  onPress={() => {
                    setIsEdit(true);
                  }}
                />
              )}
              {!data?.isStartNode && !isViewOnly && (
                <IconButton
                  icon={
                    <Icon
                      as={AntIcon}
                      color={Colors.secondary[700]}
                      size="xs"
                      name="delete"
                    />
                  }
                  onPress={() => {
                    data?.onDelete(id);
                  }}
                />
              )}
            </View>
          </HStack>
          ) :
          (
            <View justifyContent={'center'}>
              {!data?.isStartNode && !isViewOnly && workflowContext.isAllowInterAction  &&
              (<Pressable
                borderRadius={4}
                onHoverIn={()=>{setIsHoveredOverNode(true)}}
                onHoverOut={()=>{setIsHoveredOverNode(true)}}
                backgroundColor={Colors.FoldPixel.TRANSPARENT}
                _hover={{ backgroundColor: getColorByLambdaState({ params: { isIconBackground: true } }) }}
                onPress={()=>{
                  data?.onDelete(id)
                }}
              >
                <CustomAutomationIcon
                  name={'trashBin'}
                  size='24px'
                  iconColor={isHoveredOverNode? Colors.FoldPixel.GRAY300 : 'transparent'}
                />
              </Pressable>)
              }
            </View>
            )
            }

            </HStack>
        </HStack>

        <VStack>

          { showCardTitle && <>
          {nodeType === 'WORKFLOWS' &&
            data &&
            data.metaData &&
            data.metaData.title && (
              <Text fontWeight="400" textAlign="center">
                {data.metaData.title}
              </Text>
            )}
          {optionList.length > 0 && optionList.length == 1 && (
            <Divider marginTop="5px"></Divider>
          )}
          {isInvalid && (
            <RequiredSourceNodeError
              requiredNodes={requiredSourceNodes.map((node) => node.displayName)}
            />
          )}
          </>}
          {/* <HStack marginTop={2} flex={1} width='350'>
            {isShowNodeDisplayTemplate && <WorkflowNodeDisplayText nodeDisplayTemplate={nodeDisplayTemplate} previousImmediateNode={previousImmediateNode} userInputFieldList={firstUserInputFieldList} displayName={displayName} nodeType={nodeType}></WorkflowNodeDisplayText>}
          </HStack> */}
          {!isShowNodeDisplayTemplate && <VStack marginTop={2}>
            {!isInvalid &&
              optionList.length == 1 &&
              firstUserInputFieldList.map(
                (userInputField: IUserInputField, index: number) => {
                  const canShow = canShowField(userInputField);
                  const displayLabel = getDisplayLabel(userInputField);
                  const displayValue = getDisplayValueComponent(
                    userInputField,
                    nodeMetaData
                  );
                  if (!canShow) {
                    return <View key={index}></View>;
                  }
                  return index < 2 ||
                    (!data.isWorkFlowEditable && displayValue !== '-') ? (
                    <HStack maxWidth={350} key={index} alignItems="start">
                      {displayLabel && <Text fontWeight={400} flex={2} color={Colors.FoldPixel.GRAY300}>
                        {displayLabel}
                      </Text>}
                      <Text fontWeight={300} width={'full'} flex={3} numberOfLines={2} color={Colors.FoldPixel.GRAY300}>
                        {displayValue}
                      </Text>
                    </HStack>
                  ) : (
                    <View key={index}></View>
                  );
                }
              )}
            {!isInvalid &&
              data.isWorkFlowEditable &&
              optionList.length == 1 &&
              firstUserInputFieldList.length > 2 && (
                <HStack marginTop={1}>
                  <Spacer />
                  <Pressable
                    key="more"
                    width="50px"
                    onPress={() => {
                      setIsEdit(true);
                    }}
                  >
                    <Text color="primary.500">More...</Text>
                  </Pressable>
                </HStack>
              )}
          </VStack>
          }
        </VStack>



        <Drawer
          destroyOnClose
          placement="right"
          onClose={() => {
            onCloseEditMode();
          }}
          visible={isEdit && optionMap}
          closable={false}
          width={'40%'}
        >
          {optionMap && (
            <SideMenu
              iconInfo={lambdaState?.iconInfo}
              nodeCategory={lambdaState?.category?.code || NodeCategory.moment}
              rootNodeType={rootNodeType}
              getPreviousImmediateNode={getPreviousImmediateNode}
              sourceHandle={data?.getSourceHandle(id)}
              isViewOnly={isViewOnly}
              sideMenuHeader={
                <SideMenuHeader
                  isViewOnly= {isViewOnly}
                  replaceNodeById={replaceNodeById}
                  nodeId={id}
                  previousImmediateNodeId={previousImmediateNodeId}
                  stateData={lambdaState}
                  data={data}
                  parentOutputNodeTypeList={parentNodeOutputList}
                ></SideMenuHeader>
              }
              addNewOptionInfo={addNewOptionInfo}
              key={'ConditionState'}
              prevImmediateNode={previousImmediateNode}
              requiredSourceNode={{
                isRequiredSourceNodeError: isInvalid,
                requiredSourceNodes: requiredSourceNodes,
              }}
              nodeType={nodeType}
              userInputFieldMap={optionMap}
              isOpen={isEdit}
              title={displayName}
              onClose={onCloseEditMode}
              onSave={(data: any) => {
                if (!isDuplicateKey(data)) {
                  onSaveEditMode(data);
                }
              }}
            />
          )}
        </Drawer>
      </Box>
      </Pressable>
      {/* {
        isExecutionLog ?
          <VStack margin={0} borderStyle={'solid'} roundedRight="md" shadow={1} borderColor={'gray.100'} borderTopWidth={1} borderBottomWidth={1} borderRightWidth={1}>

          </VStack> : <></>
      } */}
    </HStack>
      <View alignItems="center" marginY={2} width={'100%'}>
        <ButtonMenu
          isExecutionLog={isExecutionLog}
          isNewlyAdded={isNewlyAdded}
          getColorByLambdaState={getColorByLambdaState}
          isHoveredOverNode={isHoveredOverNode}
          data={data}
          parentDisplayName={displayName}
          isViewOnly={isViewOnly}
          key={Object.keys(optionMap || {}).join('_')}
          optionMap={optionMap}
          prevConditionNodeType={prevConditionNodeType}
          previousImmediateNode={previousImmediateNode}
          nodeId={id}
          getOutputNodeList={getOutputNodeList}
          lambdaState={lambdaState}
          nodeMasterDataMap={nodeMasterDataMap}
          onPress={(formData, clientX, clientY, isEdit) =>
            data.onSelect(
              {
                ...formData,
                sourceId: id,
                isEdit: isEdit,
                nodeType: nodeType
              },
              clientX,
              clientY
            )
          }
        />
      </View>
    </View>
    </div>
  );
};


export default React.memo(ConditionState);
