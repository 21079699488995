import { View } from 'native-base';
import React from 'react';
import { Rect, Path, Svg } from "react-native-svg";

const CareJourneyIcon = (props:{
    strokeColor?: string
    size?: number
    withoutContainer?: boolean
}) => {
    if (props.withoutContainer) {
        return (
          <Svg
            width={props.size || 28}
            height={props.size || 28}
            fill="none"
            viewBox={`0 0 ${props.size || 28} ${props.size || 28}`}
          >
            <Path
              stroke={props.strokeColor || "#825AC7"}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16.092 14.99l-.024 1.842a.77.77 0 01-.23.551l-2.17 2.169a.393.393 0 01-.66-.194l-.49-2.096m-1.515-7.36l-1.841.024a.77.77 0 00-.552.23L6.448 12.32a.393.393 0 00.194.66l2.096.491m-1.2 3.483l-.92.915m2.423.588l-.915.92m-.043-4.688s2.63-5.265 5.15-7.185c2.514-1.914 6.16-.903 6.16-.903s1.018 3.641-.902 6.161c-1.92 2.52-7.185 5.15-7.185 5.15l-3.223-3.223z"
            ></Path>
          </Svg>
        );
    }
    return (
        <View>
            <Svg
                width="28"
                height="28"
                fill="none"
                viewBox="0 0 28 28"
            >
                <Rect
                    width="24.5"
                    height="24.5"
                    x="0.75"
                    y="0.75"
                    fill="#F9F5FF"
                    rx="12.25"
                ></Rect>
                <Rect
                    width="24.5"
                    height="24.5"
                    x="0.75"
                    y="0.75"
                    stroke="#825AC7"
                    strokeWidth="0.5"
                    rx="12.25"
                ></Rect>
                <Path
                    stroke="#825AC7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.092 14.99l-.024 1.842a.77.77 0 01-.23.551l-2.17 2.169a.393.393 0 01-.66-.194l-.49-2.096m-1.515-7.36l-1.841.024a.77.77 0 00-.552.23L6.448 12.32a.393.393 0 00.194.66l2.096.491m-1.2 3.483l-.92.915m2.423.588l-.915.92m-.043-4.688s2.63-5.265 5.15-7.185c2.514-1.914 6.16-.903 6.16-.903s1.018 3.641-.902 6.161c-1.92 2.52-7.185 5.15-7.185 5.15l-3.223-3.223z"
                ></Path>
            </Svg>
        </View>
    )
}

export default CareJourneyIcon