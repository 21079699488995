import React, { useEffect, useState } from 'react'
import { Text, Box, HStack, Spacer, VStack, Button, Tooltip, Pressable, Divider } from 'native-base'
import CustomButton from '../../../../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomButton/CustomButton'
import { IMedOrderListProps, IMedOrderListState } from './interfaces'
import AddOrUpdateOrders from '../AddOrUpdateIframeOrders/AddOrUpdateIframeOrders'
import { populatePrescriptionForNote } from '../../../../../../services/CommonService/OrderService'
import { Skeleton } from 'antd'
import { Colors } from '../../../../../../styles'
import Feather from 'react-native-vector-icons/Feather'
import { OrderType } from '../OrderConstants'
import { CodeableConcept, MedicationRequest } from 'fhir/r4'
import { isPendingMedicationOrder, getExtensionValue, getMedicationOrderName, getMedicationSubTitle, getRequesterId } from '../OrderUtils'
import { useLazyQuery } from '@apollo/client'
import { GET_USERS_BY_USER_IDS_OR_EXT_USER_IDS } from '../../../../../../services/User/UserQueries'
import AntIcon from 'react-native-vector-icons/AntDesign';
import OrderDetailsDrawer from '../OrderDetailsDrawer/OrderDetailsDrawer'
import OrderListPreview from '../OrderListPreview'
import { ORDER_TYPE } from '../../../../../../constants'
import {StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  infoBtn: {
    fontSize: 16,
    color: Colors.Custom.Gray500,
    padding: 0,
  },
});

const MedOrderList = (props: IMedOrderListProps) => {
  const {personData} = props;
  const [orderListState, setOrderListState] = useState<IMedOrderListState>({
    showAddNewOrderModal: false,
    loading: false,
    orderList: [],
    showModal: false,
    showDetail: false,
    selectedOrder: {},
  });

  const [getUserList] = useLazyQuery(GET_USERS_BY_USER_IDS_OR_EXT_USER_IDS, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data?.accountUsers.length) {
        const userIdNameMap: any = {};
        data.accountUsers.forEach((item: any) => {
          if (item.user?.uuid) {
            userIdNameMap[item.user.uuid] = item.user.name;
          }
        });
        setOrderListState((prev) => {
          const list = prev.orderList;
          list.forEach((med) => {
            const userId = getRequesterId(med);
            if (userId && med.requester && userIdNameMap[userId]) {
              med.requester.display = userIdNameMap[userId];
            }
          });
          return {
            ...prev,
            orderList: [...list],
            loading: false,
          };
        });
      }
    },
    onError: () => {
      setOrderListState((prev) => ({
        ...prev,
        loading: false,
      }));
    },
  });

  const getInfoButton = (params: {
    prescription?: MedicationRequest;
  }): JSX.Element => {
    return (
      <Tooltip label="View Details" placement="top">
        <Pressable
          ml={2}
          onPress={() => {
            setOrderListState((prev) => {
              return {
                ...prev,
                showDetail: true,
                selectedOrder: {
                  prescription: params.prescription,
                },
              };
            });
          }}
        >
          <Feather
            name="info"
            style={styles.infoBtn}
          />
        </Pressable>
      </Tooltip>
    );
  };

  const getEditButton = (order?: MedicationRequest): JSX.Element | undefined => {
    const canEditOrder = !!order && isPendingMedicationOrder(order);
    if (!canEditOrder) return;
    return (
      <Tooltip label="Edit Order" placement="top">
        <Pressable
          ml={2}
          onPress={() => {
            setOrderListState((prev) => ({...prev, showAddNewOrderModal: true}));
          }}
        >
          <Feather
            name="edit"
            style={styles.infoBtn}
          />
        </Pressable>
      </Tooltip>
    );
  };

  const fetchOrders = (isOrderPlaced: boolean) => {
    if (!props.documentReferenceId || !props.patientId) return;
    setOrderListState((prev) => ({...prev, loading: true}));
    populatePrescriptionForNote(
      props.patientContactId,
      props.documentReferenceId,
      (response) => {
        const medicationRequests: MedicationRequest[] = [];
        const userUUIDs: string[] = [];
        const reasonCodes: CodeableConcept[] = [];
        response?.data?.entry?.forEach(
          (item: {resource: MedicationRequest}) => {
            const medOrder = item.resource;
            if (medOrder) {
              medicationRequests.push(medOrder);
              const userUUID = getRequesterId(medOrder);
              if (userUUID && !userUUIDs.includes(userUUID)) {
                userUUIDs.push(userUUID);
              }
            }
          }
        );
        medicationRequests.forEach((item) => {
          if (item?.reasonCode && item?.reasonCode?.length) {
            reasonCodes.push(...item.reasonCode);
          }
        });
        props.addDiagnosisFormOrderList(reasonCodes);
        if (userUUIDs.length) {
          getUserList({
            variables: {
              userIds: userUUIDs.filter((uuid) => uuid !== '-1'),
              externalUserIds: [],
            },
          });
        }
        setOrderListState((prev) => ({
          ...prev,
          loading: !!userUUIDs.length,
          ...(medicationRequests.length && {orderList: medicationRequests}),
        }));
        if (props.onOrderListUpdate && isOrderPlaced) {
          props.onOrderListUpdate(medicationRequests);
        }
        setOrderListState((prev) => ({...prev, loading: false}));
      },
      (error) => {

        setOrderListState((prev) => ({...prev, loading: false}));
      }
    );
  };

  useEffect(() => {
    fetchOrders(true);
  }, [props.documentReferenceId, props.patientId]);

  return (
    <>
      {props.isPreviewMode && !orderListState.loading ? (
        <OrderListPreview
          orderLabel={'eRx'}
          personData={personData}
          orderList={orderListState.orderList}
          type={ORDER_TYPE.MED}
          renderActions={(order) => (
            <HStack
              flex={2}
              justifyContent={'flex-end'}
              space={1}
              marginTop={2}
            >
              {getInfoButton({prescription: order as MedicationRequest})}
            </HStack>
          )}
        />
      ) : (
        <>
          <div className="custom-style-extra-note-component">
            <Box
              px={4}
              py={3}
              borderColor="#D0D5DD"
              borderWidth={0.5}
              mt={1}
              borderRadius={8}
              backgroundColor="white"
            >
              <HStack mb={1}>
                <Text fontWeight="bold" fontSize="md">
                  {'eRx'}
                </Text>
                <Spacer />
                {!props.disabled && (
                  <CustomButton
                    title={'Rx'}
                    onPress={() => {
                      setOrderListState((prev) => ({
                        ...prev,
                        showAddNewOrderModal: true,
                      }));
                    }}
                    leftIcon={
                      <Feather
                        name="plus"
                        size={14}
                        color={Colors.Custom.PurpleColor}
                      />
                    }
                  />
                )}
              </HStack>
              {orderListState.loading && <Skeleton active />}
              {!orderListState.loading &&
                orderListState.orderList.length > 0 &&
                orderListState.orderList.map((order, index) => {
                  return (
                    <VStack key={order.id}>
                      <HStack alignItems="flex-start">
                        <VStack flex={8} my={1}>
                          <Text color={Colors.Custom.Gray900} fontWeight={400} fontSize={14}>{getMedicationOrderName(order)}</Text>
                          <Text color={Colors.Custom.Gray500} fontSize="14">
                            {getMedicationSubTitle(order, true)}
                          </Text>
                        </VStack>
                        <HStack
                          flex={2}
                          justifyContent={'flex-end'}
                          space={1}
                          marginTop={2}
                        >
                          {getEditButton(order)}
                          {getInfoButton({prescription: order})}
                        </HStack>
                      </HStack>
                      {index !== orderListState.orderList.length - 1 && (
                        <Divider />
                      )}
                    </VStack>
                  );
                })}
              {!orderListState.loading && !orderListState.orderList.length && (
                <Text>{'No Prescriptions'}</Text>
              )}
            </Box>
          </div>
        </>
      )}
      {!orderListState.loading && orderListState.showAddNewOrderModal && (
        <AddOrUpdateOrders
          isVisible={orderListState.showAddNewOrderModal}
          orderType={OrderType.MED}
          patientContactId={props.patientContactId}
          documentReferenceId={props.documentReferenceId}
          unFormattedContactData={props.unFormattedContactData}
          personData={props.personData}
          onClose={() => {
            fetchOrders(true);
            setOrderListState((prev) => ({
              ...prev,
              showAddNewOrderModal: false,
            }));
          }}
        />
      )}
      {orderListState.showDetail && (
        <OrderDetailsDrawer
          isVisible={orderListState.showDetail}
          prescription={orderListState.selectedOrder.prescription}
          onClose={() => {
            setOrderListState((prev) => {
              return {
                ...prev,
                showDetail: false,
                selectedOrder: {},
              };
            });
          }}
        />
      )}
    </>
  );
};

export default MedOrderList;
