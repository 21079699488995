import React from 'react';
import {isWeb} from '../../../../utils/platformCheckUtils';
import {ICommonSvgProps} from '../interfaces';
import  { Image } from 'native-base';

const VoiceRecordPlaySvg = (props?: ICommonSvgProps) => {
  return (
    <>
      {isWeb() ? (
        <svg
          width={props?.width || 24}
          height={props?.height || 24}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 3l14 9-14 9V3z"
            stroke={props?.customStrokeColor || '#4F2D90'}
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ) : (
        <Image
          resizeMode="contain"
          size={'20px'}
          source={require('../../../../assets/images/PNGImages/voice_record_play.png')}
          alt="image"
          tintColor={props?.customStrokeColor || '#4F2D90'}
        />
      )}
    </>
  );
};

export default VoiceRecordPlaySvg;
