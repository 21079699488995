import {useLazyQuery} from '@apollo/client';
import {Select, Skeleton, Spin} from 'antd';
import {debounce} from 'lodash';
import {Text} from 'native-base';
import {useEffect, useState} from 'react';
import {
  GetAccountAndPracticeLocations,
  GET_ACCOUNT_LOCATION_BY_ID,
} from '../../../../services/Location/UserPracticeLocationQueries';

export const AccountLocationSearchAndSelect = (props: {
  defaultValue?: any;
  isShowError?: boolean;
  onChange: (actionData?: any, extraData?: any) => void;
  isMsoEnabled?: boolean
  locationIdList?: string[]
}) => {
  const {defaultValue} = props;
  const [accountLocations, setAccountLocations] = useState<any[]>([]);
  const [isLoading, setLoading] = useState(true);

  const formatAccountLocationData = (locations: any[]) => {
    const formattedLocations: any[] = [];
    (locations || []).forEach((location: any) => {
      if (
        location?.uuid &&
        location?.practiceLocation &&
        location?.practiceLocation?.name
      ) {
        formattedLocations.push({
          uuid: location?.uuid,
          name: location?.practiceLocation?.name,
        });
      }
    });
    return formattedLocations || [];
  };

  const [getAccountLocations] = useLazyQuery(GetAccountAndPracticeLocations, {
    fetchPolicy: 'no-cache',
  });

  const [searchLocationById] = useLazyQuery(GET_ACCOUNT_LOCATION_BY_ID, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      setAccountLocations(
        formatAccountLocationData(data?.accountLocations || []),
      );
      setLoading(false);
    },
    onError: error => {
      setLoading(false);
    },
  });

  const getAccountLocationById = (locationIds: string[]) => {
    setLoading(true);
    searchLocationById({
      variables: {
        accountLocationIds: locationIds,
      },
    });
  };

  const onSearchAccountLocation = async (searchString: string) => {
    try {
      const params = {
        limit: 10,
        offset: 0,
        search: `%${searchString?.trim()}%`,
        boolExp: (
          props?.isMsoEnabled ?
            {
              uuid: {
                _in: props?.locationIdList
              }
            } : {}
        )
      };
      const apiResponse = await getAccountLocations({variables: params});
      const locations = apiResponse?.data?.accountLocations || [];
      setAccountLocations(formatAccountLocationData(locations));
      setLoading(false);
    } catch (error) {
      setAccountLocations([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (defaultValue && defaultValue?.length) {
      getAccountLocationById(defaultValue);
    } else {
      onSearchAccountLocation('');
    }
  }, [defaultValue]);

  return (
    <>
      <Select
        size="large"
        mode="multiple"
        showSearch
        status={
          props.isShowError && !(defaultValue && defaultValue?.length)
            ? 'error'
            : ''
        }
        allowClear
        filterOption={false}
        value={accountLocations?.length ? defaultValue || undefined : undefined}
        onSearch={debounce(onSearchAccountLocation, 500)}
        onChange={(value: any) => {
          if (value && value.length > 0) {
            const locationName = value?.map((uuid: any) => {
              const accountLocation = accountLocations?.find(location => {
                return location?.uuid == uuid;
              });
              return accountLocation?.name;
            });
            props.onChange(value, locationName);
          } else {
            props.onChange(undefined);
          }
        }}
        placeholder="Select Location"
        loading={isLoading}
        notFoundContent={
          isLoading ? <Spin size="large" /> : <Text>No Data Found</Text>
        }
        style={{width: '100%'}}>
        {accountLocations?.map((item: any) => {
          return (
            <Select.Option key={item.uuid} value={item.uuid}>
              {item.name}
            </Select.Option>
          );
        })}
      </Select>
    </>
  );
};

export default AccountLocationSearchAndSelect;
