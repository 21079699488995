import { View, Text, Box, VStack, Icon, Heading, Spinner } from 'native-base'
import React, { useEffect, useState } from 'react'
import { Colors } from '../../../styles'
import AntIcon from 'react-native-vector-icons/AntDesign';
import { getIframeResponseCode, getMessage, IOrderState, OrderIframeResponseCode, OrderSubmissionState } from './OrderSuccessUtils';
import { reportOrderSubmission } from '../../../services/CommonService/OrderService';
import { WINDOW_EVENT_CODES } from '../../../constants';



const OrderSuccess = (props: {
  query: string
}) => {
  const [orderState, setOrderState] = useState<IOrderState>({
    state: OrderSubmissionState.loading,
  });
  const currentMessage = getMessage(orderState.state);

  const broadcastMessage = (code?: OrderIframeResponseCode) => {
    const windowObject = window.opener || window.parent || window;
    if (windowObject) {
      const messageData = JSON.stringify({
        messageCode: WINDOW_EVENT_CODES.ORDER_UPDATES,
        query: props.query,
        responseCode: code,
      });
      windowObject.postMessage(messageData, '*');
    }
  };

  useEffect(() => {
    const queryParams = props.query;
    const params = new URLSearchParams(queryParams);
    const responseCode = getIframeResponseCode(params.get('responseCode'));
    if (responseCode == OrderIframeResponseCode.cancelled) {
      setOrderState((prev) => ({...prev, state: OrderSubmissionState.cancelled}));
    } else if (responseCode == OrderIframeResponseCode.error) {
      setOrderState((prev) => ({...prev, state: OrderSubmissionState.error}));
    }
    reportOrderSubmission(queryParams, () => {
      setOrderState((prev) => {
        return {
          ...prev,
          ...(prev.state === OrderSubmissionState.loading && { state: OrderSubmissionState.success })
        }
      });
      broadcastMessage(responseCode);
    }, () => {
      setOrderState((prev) => ({
        ...prev,
        ...(prev.state === OrderSubmissionState.loading && { state: OrderSubmissionState.error })
      }));
      broadcastMessage(responseCode);
    })
  }, []);

  return (
    <Box
      borderColor={Colors.Custom.BorderColor}
      rounded="lg"
      borderWidth={1}
      marginX={{
        base: 0,
        lg: 32,
      }}
      marginY={{
        base: 0,
        lg: 8,
      }}
    >
      <View
        padding={{
          base: 4,
          sm: 2,
          md: 4,
          lg: 8,
          xl: 8,
        }}
      >
        <VStack space={2}>
          <View alignItems="center" justifyContent="center">
            {orderState.state === OrderSubmissionState.loading && (
              <Spinner color={Colors.primary[200]} size="lg" />
            )}
            {orderState.state !== OrderSubmissionState.loading && (
              <Icon
                as={AntIcon}
                name={currentMessage.iconName}
                size={currentMessage.size || 20}
                color={currentMessage.color}
              />
            )}
          </View>
          <View alignItems="center" justifyContent="center">
            <Heading>{currentMessage.title}</Heading>
            <Text>{currentMessage.message}</Text>
          </View>
        </VStack>
      </View>
    </Box>
  )
}

export default OrderSuccess
