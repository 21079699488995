import React from "react";
import { Svg, Path } from 'react-native-svg';
import { } from 'victory';

function AutomationPopGroupContactListSvg() {
  return (
    <Svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <Path d="M10.6919 2C11.7841 2 12.6825 2.92 12.6825 4.05333C12.6825 5.18667 11.7906 6.1 10.6919 6.1M13.7554 11.8267C14.6667 11.4133 14.6667 10.72 14.6667 9.94C14.6667 8.79333 13.2125 7.83333 11.3123 7.66M9.08903 4C9.08903 5.47333 7.93214 6.66667 6.5038 6.66667C5.07546 6.66667 3.91857 5.47333 3.91857 4C3.91857 2.52667 5.07546 1.33333 6.5038 1.33333C7.93214 1.33333 9.08903 2.52667 9.08903 4ZM11.6743 11.6667C11.6743 13.3267 11.6743 14.6667 6.5038 14.6667C1.33334 14.6667 1.33334 13.3267 1.33334 11.6667C1.33334 10.0067 3.64712 8.66667 6.5038 8.66667C9.36048 8.66667 11.6743 10.0067 11.6743 11.6667Z" stroke="#8C5AE2" stroke-linecap="round" stroke-linejoin="round" />
    </Svg>
  );
}

export default AutomationPopGroupContactListSvg;