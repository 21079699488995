import { memo } from "react";
import { prospect } from "../../../../common/DisplayCard/DisplayAvatarPngFiles";

const DynamicAutomationIcon = (props:{isHoveredOverButtonMenu?: any, size?:string}) => {
  return (
    <svg width={props?.size? props.size : '22' } height={props?.size? props.size : '22' } viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="20" height="20" rx="5" fill={props?.isHoveredOverButtonMenu ? '#0E66E9' : 'white'} />
      <rect x="1" y="1" width="20" height="20" rx="5" stroke="#0E66E9" stroke-width="1.5" stroke-linecap="round" />
      <path d="M15 11.75C15.4142 11.75 15.75 11.4142 15.75 11C15.75 10.5858 15.4142 10.25 15 10.25V11.75ZM7 10.25C6.58579 10.25 6.25 10.5858 6.25 11C6.25 11.4142 6.58579 11.75 7 11.75V10.25ZM11.75 7C11.75 6.58579 11.4142 6.25 11 6.25C10.5858 6.25 10.25 6.58579 10.25 7L11.75 7ZM10.25 15C10.25 15.4142 10.5858 15.75 11 15.75C11.4142 15.75 11.75 15.4142 11.75 15H10.25ZM15 10.25H11V11.75H15V10.25ZM11 10.25H7V11.75H11V10.25ZM10.25 7L10.25 11H11.75L11.75 7L10.25 7ZM10.25 11V15H11.75V11H10.25Z" fill={props?.isHoveredOverButtonMenu ? "white" : "#0E66E9"} />
    </svg>
  )
}

export default DynamicAutomationIcon;