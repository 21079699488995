import React from 'react';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import {Colors} from '../../../../../styles';
import Stack from '../../../../common/LayoutComponents/Stack';
import { EmptyCarePlan } from '../../../../../assets/svg/EmptyCarePlan';
import { StyleSheet } from 'react-native';
export const NoCarePlan = (props: {title: string; extraView?: JSX.Element}) => {
  return (
    <Stack
      direction="column"
      style={styles.stackStyle}
    >
      <EmptyCarePlan />
      <DisplayText
        size="lgNormal"
        textLocalId={props.title}
        extraStyles={{
          color: Colors.Custom.Gray500,
          textAlign: 'center',
          maxWidth: 300,
          fontSize: 16,
          marginTop: 30
        }}
      />
    </Stack>
  );
};

const styles = StyleSheet.create({
  stackStyle: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 24
  }
});
