import {View} from 'react-native';
import React from 'react';
import {FormControl, Spinner, Stack} from 'native-base';
import {ModalActionAntSelect} from '../../../../../../common/ModalActionCommonComponent/ModalActionAntSelect';
import {IGoal} from './interface';
import {IMlov} from '../../../../../../../Interfaces';
import {
  GOAL_STATUS_CODES,
  GOAL_TRACK_VALUES,
} from '../../../../../../../constants';
import {Select, Tag} from 'antd';
import {Text} from 'react-native';
import {Colors} from '../../../../../../../styles';
import { reactStyles, styles } from '../../../../Analytics/style';

interface IGoalStatusViewProps {
  goal: IGoal;
  goalStatusList: IMlov[];
  disabled: boolean;
  onChange: (statusId: string, statusCode: string) => void;
  isGoalLoading: boolean;
}

const GoalStatusView = (props: IGoalStatusViewProps) => {
  const {disabled, goal, onChange, goalStatusList, isGoalLoading} = props;

  if (goalStatusList?.length === 0) {
    return <></>;
  }

  const noChangeStatusId = goalStatusList.find((status) => status.code === GOAL_STATUS_CODES.NO_CHANGE)?.id;

  const isNoChangeStatusId = goal.statusId === noChangeStatusId;

  const getUpdatedStatusList = () => {
    return goalStatusList.map((goalStatus) => {
      switch (goalStatus.code) {
        case GOAL_STATUS_CODES.ACHIEVED:
          goalStatus.value = GOAL_TRACK_VALUES.ON_TRACK;
          break;
        case GOAL_STATUS_CODES.NOT_ACHIEVED:
          goalStatus.value = GOAL_TRACK_VALUES.OFF_TRACK;
          break;
      }
      return goalStatus;
    }).filter((goalStatus)=> {
      return goalStatus.code !== GOAL_STATUS_CODES.NO_CHANGE;
    }).reverse();
  };

  const getColorByCode = (goalStatus: IMlov) => {
    let color = Colors.Custom.Gray500;
    switch (goalStatus.code) {
      case GOAL_STATUS_CODES.ACHIEVED:
        color = Colors.Custom.SuccessColor;
        break;
      case GOAL_STATUS_CODES.NOT_ACHIEVED:
        color = Colors.Custom.crossIconColor;
        break;
    }
    return color;
  };

  return (
    <View>
      <Stack
        space={4}
        direction={'row'}
        style={styles.flex1AlignItemsCenter}
      >
        <FormControl isDisabled={false} flex={1}>
          <Select
            showSearch={false}
            value={isNoChangeStatusId ? undefined : goal.statusId}
            loading={isGoalLoading}
            placeholder="Update Status"
            onChange={(itemValue: any, data: any) => {
              onChange(itemValue, data.code);
            }}
            style={reactStyles.select}
            size="small"
            bordered={true}
          >
            {getUpdatedStatusList().map((item) => {
              return (
                <Select.Option key={`${item.code}`} value={item.id}>
                  <Text
                    style={{
                      color: getColorByCode(item),
                      marginTop: 2,
                      fontSize: 14,
                      fontWeight: '400',
                    }}
                  >
                    {item.value}
                  </Text>
                </Select.Option>
              );
            })}
          </Select>
        </FormControl>
      </Stack>
    </View>
  );
};

export default GoalStatusView;
