import {useQuery} from '@apollo/client';
import {HStack, Pressable, Skeleton, Text, View} from 'native-base';
import React from 'react';
import {NavigateFunction, useNavigate} from 'react-router-dom';
import ContactRelation from '../../../services/ContactRelation/ContactRelation';
import {Colors} from '../../../styles';
import {isWeb} from '../../../utils/platformCheckUtils';
import {capitalizeText} from '../ContactRelationView/ContactRelationUtils';
import {DisplayText} from '../DisplayText/DisplayText';
import { withMiniContactViewHOC } from '../../MiniContactViewHOC';

const RelationWithContactsList = (props: any) => {
  const {contactId, contactData, onNavigateRelationalContact} = props;
  let navigate: NavigateFunction;
  if (isWeb()) {
    navigate = useNavigate();
  }

  const {loading, data} = useQuery(
    ContactRelation.GetRelationShipWithContact,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
      variables: {
        contactId:  contactId ||contactData?.id,
      },
    }
  );
  const onContactClicked = (contactId: any, contact: any) => {
    const type = contact.contactType
      ? contact.contactType.contactType.value
      : '';
    if (isWeb()) {
      if (type == 'Customer') {
        props?.navigateOrOpenContactIdDrawer?.(contactId);
      } else if (type == 'Lead') {
        navigate(`/members/prospect/${contactId}`);
      } else {
        navigate(`/members/supporting-memeber/${contactId}`);
      }
    } else {
      if(contact?.id && onNavigateRelationalContact){
      onNavigateRelationalContact(contact);
    }
    }
  };

  return (
    <>
      <View>
        {loading ? (
          <View padding={3}>
            <Skeleton.Text lines={3} />
          </View>
        ) : (
          <>

            {data?.contactRelations && data?.contactRelations.length ? (
              <>
              {/* <DisplayText extraStyles={{paddingHorizontal: 16}} size={'mdBold'} textLocalId={'relationShipWith'} /> */}
              {data?.contactRelations?.map((contact: any, index: number) => {
                return (
                  <Pressable
                    key={index}
                    onPress={() => {
                      onContactClicked(contact.contactId, contact.contactInfo);
                    }}
                  >
                    <HStack
                      style={{
                        paddingTop: 6,
                        paddingRight: 4,
                        paddingBottom: 4,
                        marginLeft: 6,
                        maxHeight: 200,
                        minHeight: 40,
                      }}
                      flex={1}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                    >
                      <HStack flex={1} flexWrap={'wrap'}>
                        {/* <Text> */}
                        <Text size={'smNormal'} color={Colors.Custom.Gray600}>
                          Relation identified with{' '}
                        </Text>
                        <Text size={'smBold'} color={Colors.Custom.Gray700}>
                          {contact?.contactInfo?.name || ''}
                        </Text>

                        <Text size={'smNormal'} color={Colors.Custom.Gray700}>
                          {' '}
                          as a{' '}
                        </Text>
                        <Text size={'smBold'} color={Colors.Custom.Gray700}>
                          {contact?.relationType?.value}
                        </Text>
                        {/* </Text> */}
                      </HStack>
                    </HStack>
                  </Pressable>
                );
              })}
              </>
            ) : (
              <></>
              // <EmptyStateSvg titleId="noRelativeContact" />
            )}
          </>
        )}
      </View>
    </>
  );
};

export default withMiniContactViewHOC(RelationWithContactsList);
