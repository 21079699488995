import React, { useState, useCallback, useEffect, SetStateAction, useContext } from 'react';
import { Text } from 'native-base';
import { Select as AntSelect, Spin } from 'antd';
import debounce from 'lodash/debounce';
import { useLazyQuery } from '@apollo/client';
import TeamQueries from '../../../../../../services/Team/TeamQueries';
import { accountLocationUuidListByUserPracticeLocationList, getAccountUUID, getEhrDisplayNameByCodes, getUserUUID, isLoginUserBusinessOwner } from '../../../../../../utils/commonUtils';
import { ILocationObject, IWorkflowLocationSelect } from './interfaces';
import { VStack } from 'native-base';
import { EHRName } from '../../../../../PersonOmniView/MiddleContainer/PatientNotes/interfaces';
import { getEhrConfig } from '../../../../../../utils/capabilityUtils';
import { FlowType, WorkflowContext } from '../../../../../../context/WorkflowContext';
import { CONFIG_CODES } from '../../../../../../constants/AccountConfigConst';
import { isAccountConfigEnabled } from '../../../../../../utils/configUtils';
import CommonService from '../../../../../../services/CommonService/CommonService';
import { CaretDownOutlined } from '@ant-design/icons';
import { Colors } from '../../../../../../styles/Colors';
import { workflowTriggerStyle } from '../../../../Workflow/Workflow/Styles'
import UserPracticeLocationQueries from '../../../../../../services/Location/UserPracticeLocationQueries';
import { isSubsetArray } from '../../../workflowUtils';
import { useIntl } from 'react-intl';
import { getAccountLocationsWithEhrCode } from '../../../../../../services/CommonService/IntegrationService';
import './../../Styles.css';
import { CommonDataContext } from '../../../../../../context/CommonDataContext';
import { useParams, useSearchParams } from 'react-router-dom';

const { Option } = AntSelect;

const WorkflowLocationSelect = (
  props: IWorkflowLocationSelect
) => {
  const commonService = CommonService.getCommonServiceInstance();
  const integrationService = commonService.integrationService;
  const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED);
  const isBusinessOwner = isLoginUserBusinessOwner();
  const accountUUID = getAccountUUID();
  const [selectedLocations, setSelectedLocations] = useState(props?.locationIds || []);
  const [locationOptionList, setLocationOptionList] = useState<ILocationObject[]>([]);
  const [ehrList, setEhrList] = useState<any>([])
  const [selectedEhr, setSelectedEhr] = useState(props?.ehrCode || undefined);
  const [fetching, setFetching] = useState(false);
  const ehrConfig = getEhrConfig('', '');
  const userUuid = getUserUUID();
  const workflowContext = useContext(WorkflowContext)
  const mlovData = useContext(CommonDataContext);
  const userPracticeLocationsObj = mlovData.userPracticeLocations;
  const [searchParams, setSearchParams] = useSearchParams()
  const [flowType, setFlowType] = useState(searchParams.get('flowType'))
  const [userPracticeLocations, setUserPracticeLocations] = useState<string[]>(workflowContext?.userPracticeLocations || [])
  const intl = useIntl();

  useEffect(() => {
    const response = getEhrList(props?.flowType)
    const fetchData = async () => {
      const response1 = await getUserPracticeLocation()
    }
    // fetchData()
  }, [])

  const [getLocations] = useLazyQuery(TeamQueries.GetLocations, {
    onCompleted: (data) => {
      const locations = data.accountLocations.map((location: ILocationObject) => ({
        uuid: location?.uuid,
        name: location?.practiceLocation?.name + location?.ehrInfo?.ehrCode,
        ehrCode: location?.ehrInfo?.ehrCode
      }));
      setLocationOptionList(locations);
      setFetching(false);
    },
    onError: (error) => {
      console.error('Error fetching locations:', error);
      setFetching(false);
    },
  });



  // useEffect(() => {
  //   if (data) {
  //     const locations = data.accountLocations.map((location) => ({
  //       uuid: location.uuid,
  //       name: location.practiceLocation.name,
  //     }));
  //     setOptions(locations);
  //     setFetching(false);
  //   }
  // }, [data]);

  // const handleSearch = useCallback(
  //   debounce(async (value) => {
  //     if (!value) {
  //       setOptions([]);
  //       return;
  //     }
  //     setFetching(true);
  //     await getLocations({
  //       variables: {
  //         limit: 10,
  //         offset: 20,
  //         accountUuid: String(accountUUID),
  //         searchString: `%${value}%`,
  //       },
  //     });
  //   }, 800),
  //   [getLocations, accountUUID]
  // );

  const renderSelectedItems = () => {
    return selectedLocations.map((locationId: string) => {
      const location: any = locationOptionList.find((option: any) => {
        return option?.uuid === locationId
      });
      return location ? location?.name : locationId;
    });
  };

  const fetchLocations = useCallback(() => {
    setFetching(true);
    getLocations({
      variables: {
        limit: 10,
        offset: 0,
        accountUuid: String(accountUUID),
        searchString: '',
      },
    });
  }, [getLocations, accountUUID]);

  useEffect(() => {
    //Commented GraphQL
    // fetchLocations()
  }, [])

  const handleChange = (value: any) => {
    setSelectedLocations(value);
    props?.setSelectedLocations?.(value)
    props?.onChangeLocationIds?.(value)
  };

  const getUserPracticeLocation = async () => {
    const response = await getUserPracticeLocations();
    const data = response?.data;
    const uuidList: string[] = []

    if (data?.userPracticeLocations?.length > 0) {
      data.userPracticeLocations.forEach((object: any) => {
        uuidList.push(object?.accountLocations[0]?.uuid)
      })
    }
    setUserPracticeLocations(uuidList)
    return uuidList
  };

  const [getUserPracticeLocations] = useLazyQuery(
    UserPracticeLocationQueries.GetMyPracticeLocations,
    {
      fetchPolicy: 'no-cache',
      variables: {
        userUuid: userUuid,
        accountUuid: accountUUID,
      },
    }
  );

  useEffect(() => {
    const userPracticeLocations = accountLocationUuidListByUserPracticeLocationList(userPracticeLocationsObj);
    setUserPracticeLocations(userPracticeLocations)
    const fetchData = async () => {
      try {
        setFetching(true)
        const allLocations = await getAccountLocationsWithEhrCode();
        // const userPracticeLocations = await getUserPracticeLocation();
        let locationOptions = [];
        if (!isBusinessOwner && isMsoEnabled) {
          const filteredLocations = allLocations
            .filter((location: { uuid: string }) => userPracticeLocations?.includes(location?.uuid))
            .map((location: ILocationObject) => ({
              uuid: location.uuid,
              name: `${location.practiceLocation.name} (${getEhrDisplayNameByCodes(location?.ehrInfo?.ehrCode)})`,
              ehrCode: location?.ehrInfo?.ehrCode
            }));

          locationOptions = filteredLocations;
        } else {
          locationOptions = allLocations.map((location: ILocationObject) => ({
            uuid: location.uuid,
            name: `${location.practiceLocation.name} (${getEhrDisplayNameByCodes(location?.ehrInfo?.ehrCode)})`,
            ehrCode: location?.ehrInfo?.ehrCode
          }));
        }
        const filteredSelectedLocation = locationOptions
          .filter((location: ILocationObject) =>
            selectedLocations.includes(location?.uuid)
          )
          .map((location: ILocationObject) => location.uuid);
        setSelectedLocations(filteredSelectedLocation);
        setLocationOptionList(locationOptions);
        setFetching(false);
      } catch (e) {
        console.error('Error fetching account locations:', e);
        setFetching(false);
      }
    };
    fetchData();
  }, []);

  const getEhrList = (flowType: string) => {
    const url = 'api/ehr';
    integrationService
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then((response: { data: any }) => {
        if (response?.data && response?.data?.ehr) {
          const ehrArray = response?.data?.ehr;
          const tempEhrList: { ehrCode: string, name: string }[] = []
          ehrArray.map((object: any) => {
            tempEhrList.push({
              ehrCode: object?.ehrName,
              name: object?.ehrName,
            })
          })
          let filteredEhrList = tempEhrList;

          if (flowType === FlowType.note) {
            filteredEhrList = tempEhrList.filter((ehr: { ehrCode: string }) =>
              ehr.ehrCode === EHRName.ATHENA || ehr.ehrCode === EHRName.FOLD
            );
          }
          setEhrList(filteredEhrList);
        }
      });
  };


  let isEditAutomationDisabled = false
  useEffect(() => {
    isEditAutomationDisabled = !isSubsetArray(selectedLocations, userPracticeLocations);
  }, [selectedLocations, userPracticeLocations])


  const getDisabledLocations = () => {
    let disabledLocations: string[] = [];
    if (flowType === FlowType.note) {
      disabledLocations = locationOptionList
        .filter((location: any) => location?.ehrCode !== EHRName.FOLD && location?.ehrCode !== EHRName.ATHENA)
        .map((location: any) => location?.uuid);
    }
    const selectedEhrCode = locationOptionList.find((location: any) => location?.uuid === selectedLocations[0])?.ehrCode;
    if (!selectedEhrCode) {
      return disabledLocations
    }
    locationOptionList
      .filter((location: any) => location?.ehrCode !== selectedEhrCode)
      .map((location: any) => location?.uuid).forEach((uuid) => {
        if (!disabledLocations.includes(uuid)) {
          disabledLocations.push(uuid)
        }
      });
    return disabledLocations;
  };

  useEffect(() => {
    setSelectedLocations(props?.locationIds || []);
  }, [props?.locationIds, props?.isPopGrp]);

  const [disabledLocations, setDisabledLocations] = useState<string[]>(getDisabledLocations())

  useEffect(() => {
    if (selectedLocations?.length < 2) {
      setDisabledLocations(getDisabledLocations())
    }
  }, [selectedLocations?.length, locationOptionList, flowType])

  return (
    <VStack space={4}>
      {/* <VStack space={1}>
        <Text style={workflowTriggerStyle.workflowSubtitle} paddingX={1.5}>
          {'Select EHR'}
        </Text>
        <AntSelect
          suffixIcon={
            <CaretDownOutlined
              style={ {
                color: Colors.FoldPixel.GRAY300
              }}
            />
          }
          value={selectedEhr ? selectedEhr : undefined}
          placeholder='Select EHR'
          onChange={handleEhrChange}
          style={ { width: '100%' }}
        >
          {ehrList.map((ehrObject: any) => (
            <Option key={ehrObject.ehrCode} value={ehrObject.ehrCode}>
              {ehrObject.name}
            </Option>
          ))}
        </AntSelect>
      </VStack> */}

      {
        <VStack space={1} gap={1}>
          <AntSelect
            allowClear
            disabled={isEditAutomationDisabled || props?.isPopGrp}
            mode='multiple'
            className={(props?.isShowErrorColor && !selectedLocations.length) ? 'workflow-location-select-error' : ''}
            // value={fetching ? [] : renderSelectedItems()}
            value={fetching ? [] : selectedLocations}
            placeholder={intl.formatMessage({id: 'selectLocations'})}
            notFoundContent={fetching ? <Spin size='small' /> : props?.notFoundContent || null }
            // onSearch={handleSearch}
            onChange={handleChange}
            style={{
              width: '100%',
              borderColor: props?.isShowErrorColor ? 'red.400' : undefined,
              borderWidth: 1
            }}

            filterOption={(input, option: any) => {
              return (
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              );
            }}
            {...(props?.customSelectProps ? props?.customSelectProps : {})}
          >
            {locationOptionList.map((location: ILocationObject) => (
              <Option key={location.uuid} value={location.uuid} disabled={ disabledLocations.includes(location.uuid) &&  !props?.crossEhrEnabled }>
                {location.name}
              </Option>
            ))}
          </AntSelect></VStack>}
    </VStack>
  );
};

export default WorkflowLocationSelect;

