import CommonService from "../CommonService/CommonService";
import { IMessageParams, ISendMessageResp } from "./interface";

export const sendMessageWithRawBody = (bodyData: IMessageParams) => {
    const commonService = CommonService.getCommonServiceInstance();
    const axiosService = commonService.axiosService;

    return axiosService.post<ISendMessageResp>(
        `/accounts/${bodyData.accountId}/conversations/${bodyData.conversationId}/messages`,
        bodyData.messageBody,
        {
            headers: {
                'content-type': 'application/json',
            },
        }
    )
}
