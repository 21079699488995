export const MIME_TYPES = {
  DOC: 'application/msword',
  DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ODT: 'application/vnd.oasis.opendocument.text',
  PDF: 'application/pdf',
};

export const WORD_DOC_MIME_TYPES = [
  MIME_TYPES.DOC,
  MIME_TYPES.DOCX,
  MIME_TYPES.ODT,
];

export const MEDIA_TYPES = {
  WORD_DOC: 'WORD_DOC',
  IMAGE: 'IMAGE',
  PDF: 'PDF',
  VIDEO: 'VIDEO',
  AUDIO: 'AUDIO',
  JPEG: 'JPEG',
  JPG: 'JPG',
  PNG: 'PNG'
};

export const ALLOWED_MIME_TYPES_FOR_IMAGING_REPORT = [
  'image/jpg',
  'image/png',
  'image/jpeg',
  'application/pdf',
];

export const ALLOWED_MIME_TYPES_FOR_ALL_DOCUMENTS = [
  'image/jpg',
  'image/png',
  'image/jpeg',
  'application/pdf',
  MIME_TYPES.DOC,
  MIME_TYPES.DOCX,
];

export const getMediaTypeFromMimeType = (mimeType: string) => {
  if (!mimeType) {
    return undefined;
  }
  if (mimeType === MIME_TYPES.PDF) {
    return MEDIA_TYPES.PDF;
  } else if (WORD_DOC_MIME_TYPES.includes(mimeType)) {
    return MEDIA_TYPES.WORD_DOC;
  } else if (mimeType.startsWith('image/')) {
    return MEDIA_TYPES.IMAGE;
  } else if (mimeType.startsWith('video/')) {
    return MEDIA_TYPES.VIDEO;
  } else if (mimeType.startsWith('audio/')) {
    return MEDIA_TYPES.AUDIO;
  }

  return undefined;
};

export const getMediaTypeFromMimeTypeForImagingReport = (mimeType: string) => {
  if (!mimeType) {
    return undefined;
  }
  if (mimeType === MIME_TYPES.PDF) {
    return MEDIA_TYPES.PDF;
  } else if (WORD_DOC_MIME_TYPES.includes(mimeType)) {
    return MEDIA_TYPES.WORD_DOC;
  } else if (mimeType.startsWith('image/jpeg')) {
    return 'JPEG';
  } else if (mimeType.startsWith('image/jpg')) {
    return 'JPG';
  } else if (mimeType.startsWith('image/png')) {
    return 'PNG';
  }
  return undefined;
};

export const MSG_ATTACHMENT_MIME_TYPE = [
  MIME_TYPES.DOC,
  MIME_TYPES.DOCX,
  MIME_TYPES.ODT,
  MIME_TYPES.PDF,
]


