import { useLazyQuery } from '@apollo/client';
import { Empty, Select, Spin, Tag, Tooltip } from 'antd';
import { debounce, isArray } from 'lodash';
import { useEffect, useState } from 'react';
import { ISearchFieldProps } from '../../../Interfaces';
import EmployerQueries, { getEmployersByIds } from '../../../services/Employer/EmployerQueries';
import { getAccountUUID } from '../../../utils/commonUtils';

const EmployerSearch = (props: ISearchFieldProps) => {
  const {value, isShowError, allowUserMultiSelect, fetchInitailEmployers, onChange} = props;
  const [loading, setLoading] = useState(false);
  const fetchDataForUpdate = allowUserMultiSelect && isArray(value) && value.length > 0
  const [prefillDataLoading, setPrefillDataLoading] = useState(fetchDataForUpdate);
  const [employerList, setEmployerList] = useState<any[]>([]);
  const [selectedEmployers,setSelectedEmployers] = useState<{id:string,label:string,value:string}[]>([]);
  const accountUuid = getAccountUUID();
  const [getDataforPrefilledData] = useLazyQuery(getEmployersByIds,{
    variables:{
      idList: value
    },
    onCompleted: (data) => {
      setSelectedEmployers(
        isArray(data?.employers)
          ? data?.employers?.map((item: {id: any; label: any}) => ({
              key: item.id,
              value: item.id,
              label: item.label,
            }))
          : []
      );
      setTimeout(() => setPrefillDataLoading(false), 200);
    },
    onError:(err) => {
      setTimeout(() => setPrefillDataLoading(false), 200);
    }
  })

  useEffect(() => {
    if (!allowUserMultiSelect && value && value.id && employerList.length === 0) {
      const {id, name} = value;
      setEmployerList([{name, id}]);
    } else if(fetchDataForUpdate) {
      setPrefillDataLoading(true)
      getDataforPrefilledData();
    }
  }, []);

  useEffect(() => {
    if(fetchInitailEmployers){
      searchEmployer({
        variables: {
          accountUuid: accountUuid,
          query: `%%`,
        },
      });
    }
  },[])

  const [searchEmployer,{loading: searchEmployerLoading}] = useLazyQuery(EmployerQueries.employersSearch, {
    fetchPolicy: 'no-cache',
    onCompleted: (response: any) => {
      if (response?.employers?.length > 0) {
        const data = response.employers;
        setEmployerList([...data]);
      } else {
        setEmployerList([]);
      }
      setLoading(false);
    },
    onError: (error) => {

      setLoading(false);
    },
  });

  const onSearch = (searchString: string) => {
      setLoading(true);
      searchEmployer({
        variables: {
          accountUuid: accountUuid,
          query: `%${searchString}%`,
        },
      });
  };

  const getDataFromId = (id: any) => {
    const matchedData = employerList.filter((item) => {
      return item.id === id;
    });
    if (matchedData.length > 0) {
      const {name, id} = matchedData[0];

      return {name, id};
    }
  };

  const tagRender = (tagProps: any) => {
    const onPreventMouseDown = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
    };
    const label:string = tagProps.label;
    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={tagProps.closable}
        onClose={tagProps.onClose}
        className='custom-tag'
      >
        <Tooltip title={label.length > 100 ? label : undefined}>
          {label.length > 100 ? `${label.slice(0,40)}...` : label}
        </Tooltip>
      </Tag>
    );
  };
  const getSelectValue = () => {
    if(prefillDataLoading){
      return [];
    }
    if (allowUserMultiSelect) {
      return selectedEmployers;
    } else {
      return value ? value.id : undefined;
    }
  }
  return (
    <Select
      //size='large'
      mode={props?.allowUserMultiSelect ? 'multiple' : undefined}
      showSearch
      allowClear
      filterOption={false}
      value={getSelectValue()}
      tagRender={allowUserMultiSelect ? tagRender : undefined}
      onSearch={debounce(onSearch, 500)}
      onChange={(value: any[], data: any) => {
        if(allowUserMultiSelect){
          setSelectedEmployers(data);
          onChange(value);
          searchEmployer({
            variables: {
              accountUuid: accountUuid,
              query: `%%`,
            },
          });
          return;
        }
        if (value) {
          const newValue = getDataFromId(value)
          onChange(newValue);
        } else {
          onChange(undefined);
        }
      }}
      placeholder={prefillDataLoading ? "Loading..." : "Search Employer"}
      loading={loading || searchEmployerLoading}
      notFoundContent={loading || searchEmployerLoading ? <Spin size="small" /> : <Empty />}
      style={{minHeight: '36px'}}
      dropdownMatchSelectWidth={false}
      className={isShowError && !value ? 'field-error' : 'participant-auto-complete'}
      status={isShowError && !value ? 'error' : ''}
      disabled={prefillDataLoading}
    >
      {employerList.map((employer, index) => {
        return (
          <Select.Option key={`${employer.id}_${index}`} value={employer.id} label={employer.name}>
            {employer.name}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default EmployerSearch;
