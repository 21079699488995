import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  formElement: {
    paddingTop: 10,
    paddingBottom: 10,
  },

  formLabel: {},
  formLastElement: {
    paddingTop: 10,
    paddingBottom: 50,
  },
  submitElement: {
    width: 100,
    paddingTop: 10,
    marginBottom: 30,
  },
  cancelElement: {
    width: 100,
    paddingLeft: 10,
    paddingTop: 10,
    marginBottom: 30,
  },
  headerText: {
    fontWeight: '400',
    fontSize: 20,
    color: '#344054',
    paddingLeft: 16,
  },
});
