import {CMSTypes, IContentType} from './interfaces';

// email editor font list
export const fontList = [
  'Arial',
  'Tahoma',
  'Verdana',
  'Times New Roman',
  'Courier New',
  'Georgia',
  'Lato',
  'Montserrat',
].map((item) => ({value: item, label: item}));

// merge tags for email editor
export const mergeTags = {
  // patient: {
  //   name: 'Steven Stewart',
  //   age: 100,
  //   firstName: 'John',
  //   lastName: 'Doe',
  // },
  // provider: {
  //   name: 'Dr Richard',
  // },
  // appointment: {
  //   time: '10:30 AM',
  //   date: 'Thursday May 31st 2022',
  //   location: 'Vision Eye Hospital, Albany, NY',
  // },
};

/*
for APP_CONFIRMATION category
patient
	firstname
	last name
provider
	name
appointment
	time
	date
	location


*/

export const categoryList: string[] = ['Appointment', 'Patient', 'Diagnoses'];
export const ContentTypes: {[index in CMSTypes]: IContentType} = {
  emails: {
    path: 'emails',
    name: 'Email',
    code: 'EMAIL',
  },
  pushNotifications: {
    path: 'push-notifications',
    name: 'Push Notifications',
    code: 'PUSH_NOTIFICATIONS',
  },
  textMessages: {
    path: 'text-messages',
    name: 'Text Messages',
    code: 'TEXT_MESSAGES',
  },
  attachments: {
    path: 'attachments',
    name: 'Attachments',
    code: 'ATTACHMENTS',
  },
  patientEducationArticles: {
    path: 'patient-education-articles',
    name: 'Patient Education',
    code: 'PATIENT_EDUCATION',
  },
  emailComponents: {
    path: 'email-components',
    name: 'Email Components',
    code: 'EMAIL_COMPONENTS',
  },
};

export const IMPORT_ZIP_WARNING = `Please make sure the zip file follows the below mentioned format\n- It should contain a single html file.\n- The images used in the template should be in a separate folder in the zip titled "images"`;

export const SMS_SEGMENT_LENGTH = 160;

export const SMS_BODY_MAX_LENGTH = SMS_SEGMENT_LENGTH * 3;
