

export const xmlToHtml = (node: Node): HTMLElement => {
  let element: HTMLElement;
  switch (node.nodeName) {
    case 'styleCode':
      element = document.createElement('span');
      element.className = (node.textContent || '');
      break;
    case 'paragraph':
      element = document.createElement('p');
      element.innerHTML = (node.textContent || '');
      break;
    case 'content':
      element = document.createElement('div');
      Array.from(node.childNodes).forEach(child => {
        element.appendChild(xmlToHtml(child));
      });
      break;
    case 'ID':
      element = document.createElement('h1');
      element.innerHTML = (node.textContent || '');
      break;
    default:
      element = document.createElement('div');
      element.innerHTML = (node.textContent || '');
  }
  return element;
}

export const getStyle = (styleCode: string): string => {
  switch (styleCode) {
    case 'xnoIndent':
      return 'style="margin-left: 0;"';
    case 'Bold':
      return 'style="font-weight: bold;"';
    case 'xLabel':
      return 'style="font-style: italic; color: gray;"';
    default:
      return '';
  }
}

const convertToHtml = (node: Node, depth = 0): string => {
  let html = '';
  const indent = ' '.repeat(depth);
  let style = '';
  if ((node.nodeName === 'content' && node.childNodes.length === 0)) {
    return html;
  }
  switch (node.nodeName) {
    // case 'ID':
    //   html += `${indent}<h1 ${style}>${node.textContent}</h1>`;
    //   break;
    case 'styleCode':
      const styleClass = (node.textContent || '').trim();
      html += `${indent}<span class="${styleClass}"></span>`;
      break;
    case 'root':
      Array.from(node.childNodes).forEach(childNode => {
        if (childNode.nodeName == 'styleCode') {
          style = getStyle(childNode.textContent || '')
        }
      });
      html += `${indent}<div ${style}>`;
      break;
    case 'paragraph':
      Array.from(node.childNodes).forEach(childNode => {
        if (childNode.nodeName == 'styleCode') {
          style = getStyle(childNode.textContent || '')
        }
      });
      html += `${indent}<p ${style}>${node.textContent}</p>`;
      break;
    case 'content':
      Array.from(node.childNodes).forEach(childNode => {
        if (childNode.nodeName == 'styleCode') {
          style = getStyle(childNode.textContent || '')
        }
      });
      html += `${indent}<div ${style}>`;

      break;
    default:
      if (node.nodeType === node.TEXT_NODE && node.textContent) {
        html += `${indent}${node.textContent}</br>`;
      }
  }
  if (node.nodeType !== node.TEXT_NODE) {
    Array.from(node.childNodes).forEach(childNode => {
      html += convertToHtml(childNode, depth + 1);
    });
    if (node.nodeName === 'root' || node.nodeName === 'content') {
      html += `${indent}</div>`;
    }
  }
  return html;
}

export const xmlStringToHtml = (xmlString: string): string => {
  try {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlString, 'text/xml');
    // Convert the root element to HTML
    const rootElement = xmlDoc.getElementsByTagName('root')[0];
    let htmlContent = convertToHtml(rootElement);

    htmlContent = htmlContent.replaceAll('xLabel', '');
    htmlContent = htmlContent.replaceAll('xnoIndent', '');
    htmlContent = htmlContent.replaceAll('Bold', '');
    return htmlContent.trim();
  } catch (error) {
    return '';
  }
}

export const isValidXmlString = (xmlString: string): boolean => {
  try {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlString, 'text/xml');
    return true;
  } catch (error) {
    return false;
  }
}
