import {Modal as ModalAntd} from 'antd';
import {Text, View, Pressable, HStack, Button} from 'native-base';
import {DisplayText} from '../../../../../common/DisplayText/DisplayText';
import {Colors} from '../../../../../../styles';
import {useState} from 'react';
import {BackgroundColor, TextAlign} from '@foldhealth/easy-email-extensions';
import {background} from 'native-base/lib/typescript/theme/styled-system';
import {Modal} from 'native-base';
import { styles } from '../../../workflowStyles';

export const WorkflowOnSavePopup = (props: {
  isShowPopup: boolean;
  onAction: (action: string) => void;
}) => {
  const title = 'Automation saved successfully!';
  const message =
    'Please note that this action does not activate automation. To enable automation, navigate to the automation screen and toggle it on.';

  return (
    <ModalAntd
      className="wokflowOnSave"
      title={
        <Text
          style={styles.textStyle1}
        >
          {title}
        </Text>
      }
      width={320}
      footer={[
        <HStack alignItems={'center'} justifyContent={'center'} space={2}>
          <Button
            style={styles.buttonStyle1}
            onPress={() => {
              props.onAction('LIST_VIEW');
            }}
          >
            <DisplayText
              textLocalId={'Go to List'}
              size={'smBold'}
              extraStyles={{
                color: Colors.FoldPixel.GRAY300,
              }}
            />
          </Button>
          <Button
            style={styles.buttonStyle2}
            backgroundColor={Colors.primary[300]}
            onPress={() => {
              props.onAction('EDIT_VIEW');
            }}
          >

            <DisplayText
              textLocalId={'Continue Editing'}
              size={'smBold'}
              extraStyles={{
                color: '#FFFFFF',
              }}
            />
          </Button>
        </HStack>,
      ]}
      closable={false}
      open={props.isShowPopup}
    >
      <View>
        <Text
          style={styles.textStyle2}
        >
          {message}
        </Text>
      </View>
    </ModalAntd>
  );
};
