import {Button, HStack, Spacer, VStack, Text, View, Divider} from 'native-base';
import React, {useState} from 'react';
import { DisplayText } from '../../../../../../common/DisplayText/DisplayText';
import CustomButton from '../CustomButton/CustomButton';

interface ICompactListViewProps {
  dataSource: any[];
  showMoreMaxCount: number;
  hasPatientReportedRecords?: boolean;
  noDataText?: string;
  onViewPatientReportedClick?: () => void;
  rowViewForItem: (item: any, index: number) => void;
}

const CompactListView = (props: ICompactListViewProps) => {
  const {dataSource, showMoreMaxCount, hasPatientReportedRecords, onViewPatientReportedClick, rowViewForItem, noDataText} = props;
  const [showMore, setShowMore] = useState(false);

  return (
    <VStack marginY={2} marginX={4}>
      {hasPatientReportedRecords && (
        <HStack marginBottom={4} marginTop={2}>
          <CustomButton
              onPress={() => {
                if (onViewPatientReportedClick) {
                  onViewPatientReportedClick();
                }
              }}
              title= {'View Patient Reported Records'}
              />
          <Spacer />
        </HStack>
      )}
      {!dataSource.length && !hasPatientReportedRecords && (
        <View marginLeft={2}>
          <DisplayText
            extraStyles={{
              color: '#667085',
            }}
            textLocalId={noDataText || 'noSurgicalHistory'}
            textType="Text"
          />
        </View>
      )}
      {dataSource.length > 0 && (
        <VStack space={2}>
          <VStack space={4}>
            {dataSource.map((item, index) => {
              if (index > showMoreMaxCount - 1 && !showMore) {
                return <></>;
              }
              return (
                <VStack>
                  <View paddingBottom={3}>{rowViewForItem(item, index)}</View>
                  {dataSource.length > showMoreMaxCount ||
                  index !== dataSource.length - 1 ? (
                    <Divider />
                  ) : null}
                </VStack>
              );
            })}
          </VStack>
          {dataSource.length > showMoreMaxCount && (
            <HStack>
              <Spacer />
              <Button
                variant="unstyled"
                onPress={() => {
                  setShowMore(!showMore);
                }}
              >
                <Text color="primary.500" size="xsNormal" italic>
                  {showMore
                    ? '...Show Less'
                    : `...Show More (${dataSource.length - showMoreMaxCount})`}
                </Text>
              </Button>
            </HStack>
          )}
        </VStack>
      )}
    </VStack>
  );
};

export default CompactListView;
