export const TestIdentifiers = {
  lazyLoading: 'LazyLoading',
  pageLoading: 'PageLoading',
  imageLoading: 'ImageLoading',
  videoLoading: 'VideoLoading',
  searchBar: 'SearchBar',
  saveBtn: 'SaveBtn',
  discardBtn: 'DiscardBtn',
  deleteBtn: 'DeleteBtn',
  editBtn: 'EditBtn',
  printBtn: 'PrintBtn',
  leftOutlinedBtn: 'LeftOutlinedBtn',
  loginUserName: 'LoginUserName',
  loginPassword: 'LoginPassword',
  loginBtn: 'LoginButton',
  sidebar: 'Sidebar',
  createForm: 'CreateForm',
  cancelForm: 'CancelForm',
  formName: 'FormName',
  previewForm: 'PreviewForm',
  saveForm: 'SaveForm',
  closePreview: 'ClosePreview',
  clone: 'Clone',
  basicCard: 'BasicCard',
  backButton: 'BackButton',
  filterButton: 'FilterButton',
  addButton: 'AddButton',
  doneButton: 'DoneButton',
  reasonForVisit: 'ReasonForVisit',
  scheduleButton: 'ScheduleAppointmentButton',
  eventName: 'EventName',
  all: 'All',
  history: 'History',
  comments: 'Comments',
  activity: 'Activity',
  automation: 'Automation',
  sortTaskAudit: 'SortTaskAudit',
  loadMore: 'LoadMore',
  showLess: 'ShowLess',
  copyRightText: 'CopyRightText',
  tnCCheckbox: 'TnCCheckbox',
  tnCLink: 'TnCLink',
  privacyPolicyLink: 'PrivacyPolicyLink',
  showPasswordBtn: 'ShowPasswordBtn',
  forgotPasswordBtn: 'ForgotPasswordBtn',
  forgotPasswordEmail: 'ForgotPasswordEmail',
  submitBtn: 'SubmitButton',
  backToLogin: 'BackToLogin',
  backToLockScreen: 'BackToLockScreen',
  memberShipStatus: 'MemberShipStatus',
  name: 'Name',
  email: 'Email',
  phoneNumber: 'PhoneNumber',
  memberCardSubTitle: 'MemberCardSubTitle',
  location: 'Location',
  checkbox: 'Checkbox',
  memberCardAction: 'MemberCardAction',
  memberShipStatusValue: 'MemberShipStatusValue',
  allAppointment: 'AllAppointmentBtn',
  avatarInitials: 'AvatarInitials',
  groupAppointmentIcon: 'GroupAppointmentIcon',
  appointmentCardContactName: 'AppointmentContactName',
  appointmentCardSubString: 'AppointmentSubString',
  appointmentCardStartTime: 'AppointmentStartTime',
  appointmentCardDuration: 'AppointmentDuration',
  appointmentCardStatus: 'AppointmentStatus',
  appointmentCardUserName: 'AppointmentCardUserName',
  joinMeetingBtn: 'JoinMeetingBtn',
  alertMessage: 'AlertMessage',
  alertBtn: 'AlertBtn',
  headerTitle: 'HeaderTitle',
  calendarDefaultMessage: 'CalendarDefaultMessage',
  noDataViewMessage: 'NoDataViewMessage',
  userName: 'UserName',
  userEmail: 'UserEmail',
  userSelect: 'UserSelect',
  appointmentInfoLabel: 'AppointmentInfoLabel',
  secondaryUserMessage: 'SecondaryUserMessage',
  locationMessage: 'LocationMessage',
  locationErrorMessage: 'LocationErrorMessage',
  appointmentName: 'AppointmentName',
  selectDateLabel: 'SelectDateLabel',
  timeSlotsLabel: 'TimeSlotsLabel',
  dateInfoMessage: 'DateInfoMessage',
  alertTitle: 'AlertTitle',
  cancelAppointmentBtn: 'CancelAppointmentBtn',
  rescheduleAppointmentBtn: 'RescheduleAppointmentBtn',
  checkInAppointmentBtn: 'CheckInAppointmentBtn',
  checkoutAppointmentBtn: 'CheckoutAppointmentBtn',
  unblockAppointmentBtn: 'UnblockAppointmentBtn',
  appointmentDate: 'AppointmentDate',
  searchBtn: 'SearchBtn',
  taskTitle: 'TaskTitle',
  taskDueDate: 'TaskDueDate',
  taskAssignee: 'TaskAssignee',
  taskAssignedBy: 'TaskAssignedBy',
  taskSubTaskCount: 'TaskSubTaskCount',
  taskAttachmentCount: 'TaskAttachmentCount',
  taskDescription: 'TaskDescription',
  viewInstruction: 'ViewInstruction',
  cancelBtn: 'CancelBtn',
  updateBtn: 'UpdateBtn',
  addIconBtn: 'AddIconBtn',
  formBtn: 'FormBtn',
  seeTheContentBtn : 'SeeTheContentBtn',
  switchAccountBtn: 'SwitchAccountBtn',
  loginBg: 'LoginBackgroundImage',
  foldhealthLogoIcon: 'FoldhealthLogoIcon',
  foldhealthLogoWithName: 'FoldhealthLogoWithName',
  isRequiredInput: 'IsRequiredInput',
  errorMessage: 'ErrorMessage',
  forgotPasswordText: 'ForgotPasswordText',
  welcomeBack: 'WelcomeBackText',
  passwordText: 'PasswordText',
  settingsBtn: 'SettingsBtn',
  conversationTabClick: 'ConversationTabClick',
  selectDropdown: 'SelectDropdown',
  infoBtn: 'InfoBtn',
  closeBtn: 'CloseBtn',
  phiWarning : 'PhiWarning',
  actionMsgHeader: 'ActionMsgHeader',
  actionMsgHeaderString: 'ActionMsgHeaderString',
  actionMsgHeaderDate: 'ActionMsgHeaderDate',
  transitionBtn: 'TransitionBtn',
  voiceRecordPlayBtn: 'VoiceRecordPlayBtn',
  callTrailBtn: 'CallTrailBtn',
  assignType: 'AssignType',
  senderName: 'MessageSenderName',
  messageDateString: 'MessageDateString',
  attachmentView: 'AttachmentView',
  downloadBtn: 'DownloadBtn',
  messageContent: 'MessageContent',
  inputField: 'InputField',
  textAreaField: 'TextAreaField',
  noDataFound: 'NoDataFound',
  viewProfile: 'ViewProfile',
  radioGroup: 'RadioGroup',
  radioButton: 'RadioButton',
  messageFooterInput: 'MessageFooterInput',
  attachmentBtn: 'AttachmentBtn',
  fileName: 'FileName',
  cancelText: 'CancelText',
  memberType: 'MemberType',
  scrollListView: 'ScrollListView',
  cannedResponseText: 'CannedResponseText',
  changeText: 'Change',
  contentViewer: 'ContentViewer',
  webView: 'WebView',
  callBtn: 'CallBtn',
  smsBtn: 'SmsBtn',
  badgeCount: 'BadgeCount',
  badge: 'Badge',
  carePlanIconBtn: 'CarePlanIconBtn',
  userImage: 'UserImage',
  subTitle: 'SubTitle',
  role: 'Role',
  practiceLocations: 'PracticeLocations',
  practiceLocationName: 'PracticeLocationName',
  logout: 'Logout',
  messageCommunicationTypesTagRemainingClick: 'MessageCommunicationTypesTagRemainingClick',
  headerInfoClick: 'HeaderInfoClick',
  fileNameClick: 'FileNameClick',
  playBtn: 'PlayBtn',
  viewProfileClick: 'ViewProfileClick',
  failed: 'Failed',
  failedBtnClick: 'FailedBtnClick',
  messageSendBtn: 'MessageSendBtn',
  messageSendIcon: 'MessageSendIcon',
  scheduleIcon: 'ScheduleIcon',
  scheduleBtnClick: 'ScheduleBtnClick',
  scheduleDatePickerView: 'ScheduleDatePickerView',
  datePicker: 'DatePicker',
  messageTime: 'MessageTime',
  threeDots: 'ThreeDots',
  articleTitle: 'ArticleTitle',
  articleLinkBtn: 'ArticleLinkBtn',
  articleDropDown: 'ArticleDropDown',
  sendArticleLinkText: 'SendArticleLinkText',
  enableBtn: 'EnableBtn',
  popGroupEnabledConfirmationMsg: 'popGroupEnabledConfirmationMsg',
  popGroupEnabledConfirmationLabel: 'popGroupEnabledConfirmationLabel',
  leftArrow: 'LeftArrow',
  rightArrow: 'RightArrow',
  expandCollapse: 'ExpandCollapse',
  visitPopOver: 'Popover',
  viewNotes: 'ViewNotes',
  backBtn: 'BackBtn',
  closeButton: 'CloseButton',
  visitCode: 'VisitCode',
  popOver: 'PopOver',
  emailSignature: 'emailSignature',
  callExtension: 'callExtension,',
  muteBtn: 'MuteBtn',
  holdBtn: 'HoldBtn',
  transferBtn: 'TransferBtn',
  addCallBtn: 'AddCallBtn',
  keyPad: 'KeyPad',
  endCallBtn: 'EndCallBtn',
  priority: 'Priority',
  members: 'Members',
  labels: 'Labels',
  commentBtn: 'CommentBtn',
  attachmentIcon: 'AttachmentIcon',
  taskPool: 'TaskPool',
  selectMembers: 'SelectMembers',
  selectTaskPool: 'SelectTaskPool',
  searchLabels: 'SearchLabels',
  searchAssignee: 'SearchAssignee',
  searchMember: 'SearchMember',

  llm: {
    diagnosysSurfacing:{
      diagnosysIcon: 'DiagnosysIcon',
      diagnosysTitle: 'DiagnosysTitle',
      diagnosysAddBtn: 'DiagnosysAddBtn',
      diagnosysRemoveBtn: 'DiagnosysRemoveBtn',
      diagnosysPoweredByUnity: 'diagnosysPoweredByUnity',
      diagnosysThumbsUp: 'DiagnosysThumbsUp',
      diagnosysThumpsDown: 'DiagnosysThumpsDown',
      diagnosysHardRefresh: 'DiagnosysHardRefresh'
    },
    patientSynopsis:{
      patientSynopsisIcon: 'PatientSynopsisIcon',
      patientSynopsisTitle: 'PatientSynopsisTitle',
      patientSynopsisTemplateDropDown: 'PatientSynopsisTemplateDropDown',
      patientSynopsisPoweredByUnity: 'PatientSynopsisPoweredByUnity',
      patientSynopsisThumbsUp: 'PatientSynopsisThumbsUp',
      patientSynopsisThumpsDown: 'PatientSynopsisThumpsDown',
      patientSynopsisHardRefresh: 'PatientSynopsisHardRefresh'
    },
    healthMap:{
      healthMapIcon: 'HealthMapIcon',
      healthMapTitle: 'HealthMapTitle',
      healthMapDropDown: 'HealthMapDropDown',
      healthMapTemplateDropDown: 'HealthMapTemplateDropDown',
      healthMapPoweredByUnity: 'HealthMapPoweredByUnity',
      healthMapThumbsUp: 'HealthMapThumbsUp',
      healthMapThumpsDown: 'HealthMapThumpsDown',
      healthMapHardRefresh: 'HealthMapHardRefresh'
    },
    alerts:{
      alertsIcon: 'AlertsIcon',
      alertsTitle: 'AlertsTitle',
      alertsDropDown: 'AlertsDropDown',
      alertsPoweredByUnity: 'AlertsPoweredByUnity',
      alertsThumbsUp: 'AlertsThumbsUp',
      alertsThumpsDown: 'AlertsThumpsDown',
      alertsHardRefresh: 'AlertsHardRefresh'
    },
  },
  recommendedPrograms: 'RecommendedPrograms',
  recommendedProgramsInfo: 'RecommendedProgramsInfo',
  campaignSelectLocation: 'campaignSelectLocation',
  careCapDetailButton: 'CareCapDetailButton',
  addTaskPool: {
    locationSelect: 'locationSelect'
  },
  providerDirectorySearch: 'ProviderDirectorySearch',
  providerDirectorySearchTag: 'ProviderDirectorySearchTag',
  personAction: {
    edit: 'Edit',
    meet: 'Meet',
    viewInEhr: 'ViewInEhr',
    viewProfile: 'ViewProfile',
    charts: 'Charts',
    sms: 'Sms',
    email: 'Email',
    chat: 'Chat',
    appointment: 'Appointment',
    notes: 'Notes',
    call: 'Call'
  },
  headerInfoView: 'HeaderInfoView'
};

export default TestIdentifiers;
