import {useLazyQuery} from '@apollo/client';
import {Center, Skeleton, View} from 'native-base';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Keyboard, FlatList as RNFlatList, SafeAreaView} from 'react-native';
import {FlatList} from 'react-native-bidirectional-infinite-scroll';
import {GestureHandlerRootView} from 'react-native-gesture-handler';
import {COPY_MESSAGE_CODE, MESSAGE_WINDOW_TABS} from '../../../../../constants';
import {CONFIG_CODES} from '../../../../../constants/AccountConfigConst';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import {
  CONVERSATION_LOCAL_EVENT_CODES,
  WEB_SOCKET_EVENT_CODE,
} from '../../../../../constants/WebSocketConst';
import ConversationsQueries from '../../../../../services/Conversations/ConversationsQueries';
import {TestIdentifiers, testID} from '../../../../../testUtils';
import {EventBus} from '../../../../../utils/EventBus';
import {getUserUUID, isChannelEmail} from '../../../../../utils/commonUtils';
import {isAccountConfigEnabled} from '../../../../../utils/configUtils';
import {isWeb} from '../../../../../utils/platformCheckUtils';
import {EmptyStateSvg} from '../../../../common/Svg';
import {CHANNEL_TYPE_CODE} from '../ConversationConst';
import {
  IConversationData,
  IConversationMessage,
  IMessageBoxData,
  IMessagingWindowCommonV2,
  IMessagingWindowCommonV2Ref,
  ISearchMessageData,
} from '../interfaces';
import {
  getContactDataAndType,
  getLastNonInternalMessageInList,
  getMembersId,
  getUniqueDisplayMessageList,
} from './MessagingUtils';
import {
  ICopiedMessageItem,
  IGetMessages,
  IMessageRespData,
  IMsgListData,
} from './interface';
import {MessagingEventQueue} from '../MessagingEventQueue/MessagingEventQueue';
import {RenderMsgList} from './RenderMsgList';
import {getMessagingWindowCommonStyles} from './MessagingWindowCommonStyles';
import {MessagingWindowCommonHook} from './MessagingWindowCommonHook';
import {MessageActionID} from '../../../../common/MessageAction/MessageActionView';
import {IReplyMessageObject} from '../../../../common/ChatUI/MessageBox/interfaces';
import {ICommunicationType} from '../CommunicationTypes/interfaces';

const GetMessagingListElemV2 = React.forwardRef<
  IMessagingWindowCommonV2Ref,
  IMessagingWindowCommonV2
>((props, ref): JSX.Element => {
  const {
    selectedConversation,
    selectedInboxTypeCode,
    searchMessage,
    onRedirectToMentionChange,
    onUpdateUserLastSeen,
    conversationUpdateOnMsgReceived,
  } = props;
  const eventQueueRef = useRef(
    new MessagingEventQueue({moduleCode: 'MessagingWindowCommonV2'})
  );
  const isComponentMounted = useRef<boolean>(false);
  const isScrollingToPrevMessages = useRef(false);
  const eventQueue = eventQueueRef?.current;

  const userUuid = getUserUUID();
  const conversationUuid = selectedConversation?.uuid;
  const contactInfo = getContactDataAndType(
    selectedConversation || ({} as IConversationData)
  );
  const isEmailInbox = isChannelEmail(
    props.selectedConversation?.conversationInbox?.channelType || ''
  );

  const isMentionConversation =
    selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION;

  const contactData: any = contactInfo.contactData;
  const [stateData, setStateData] = useState({
    lastSeenData: [] as any[],
    tab: MESSAGE_WINDOW_TABS.CHAT,
    copiedMsgList: [] as ICopiedMessageItem[],
  });

  const [msgListData, setMsgListData] = useState<IMsgListData>({
    apiData: [] as IConversationMessage[],
    displayData: [] as IMessageBoxData[],
    msgDataLoading: true,
    loadingLoadMore: false,
    areMessagesRemaining: true,
    lastConversationMsgUuid: '',
    clickMessageUuid: '',
    clickEventId: '',
    newMessageUuids: [] as string[],
    selectedMessageItem: {} as any,
    footerMsgLoading: false,
    isAnyMsgSenderDeleted: false,
    lastEmailMessageUuid: '',
  });

  const isCopyOrSelectMessageInDescending = isAccountConfigEnabled(
    CONFIG_CODES.COPY_SELECT_MESSAGE_IN_DESCENDING
  );

  const styles = React.useMemo(
    () =>
      getMessagingWindowCommonStyles({msgWindowHeight: props.msgWindowHeight}),
    [props.msgWindowHeight]
  );

  const [GetLastSeenByConversationId] = useLazyQuery(
    ConversationsQueries.GET_LAST_SEEN_BY_CONVERSATION_ID,
    {
      fetchPolicy: 'no-cache', //FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );

  const [groupMemberId, setGroupMemberId] = React.useState<any[]>([]);
  useEffect(() => {
    const groupMemberId = getMembersId(
      selectedConversation,
      contactData,
      userUuid
    );
    setGroupMemberId(groupMemberId);
    return () => {
      setGroupMemberId([]);
    };
  }, [
    selectedConversation?.groupConversation?.groupMembers,
    contactData?.uuid,
    userUuid,
  ]);

  const getLastSeenByUsers = useCallback(() => {
    GetLastSeenByConversationId({
      variables: {
        conversationUuid: conversationUuid,
      },
    }).then((data) => {
      if (data?.data?.conversationLastseens) {
        setStateData((prev) => {
          return {
            ...prev,
            lastSeenData: data.data.conversationLastseens,
          };
        });
      } else {
        setStateData((prev) => {
          return {
            ...prev,
            lastSeenData: [],
          };
        });
      }
    });
  }, [conversationUuid]);

  const usersLastSeenUpdated = (data: any) => {
    if (conversationUuid === data?.data?.conversationUuid) {
      getLastSeenByUsers();
    }
  };

  const {
    getSelectedConversationMessageDataWithLastMessage,
    onFetchMoreIfAvailableCallback,
    onFetchMoreRecentIfAvailableCallback,
    resetAndFetchFirstBatch,
    handleOnScrollToMessageFail,
    handleCreateConversationTask,
    onAddCommunicationType,
    onMessageSendAction,
    onAttachmentDelete,
    onMessageDelete,
    handleCommunicationTypeSaveOnMessageList,
    checkCommunicationTypeAssignedToSelectedMessages,
    getMessages,
    onMessageUpdateAfterEditMessageByCurrentUser,
    checkLastNonInternalMessageData,
  } = MessagingWindowCommonHook({
    selectedConversation,
    msgListData,
    setMsgListData,
    selectedInboxTypeCode: selectedInboxTypeCode || '',
    isComponentMounted: isComponentMounted,
    isSeachMsgContainerVisible: props?.isSeachMsgContainerVisible,
    searchMessageUuid: searchMessage?.uuid,
    isInstantChatView: false,
    onUpdateUserLastSeen,
    conversationUpdateOnMsgReceived,
    onNewMessageReceived: props?.onNewMessageReceived,
    onLoadingLatestMessage: props?.onLoadingLatestMessage,
    onLoadingMsgFirstBatch: props?.onLoadingMsgFirstBatch,
    onGetStickyNotes: props?.onGetStickyNotes,
  });

  useEffect(() => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener(
      WEB_SOCKET_EVENT_CODE.MESSAGE_READ,
      usersLastSeenUpdated
    );
    return () => {
      eventBus.removeEventListener(usersLastSeenUpdated);
    };
  }, [conversationUuid, isComponentMounted?.current]);

  React.useImperativeHandle(ref, () => ({
    handleCreateConversationTask: (taskId: string, messageList?: string[]) =>
      handleCreateConversationTask(
        taskId,
        isComponentMounted?.current,
        messageList
      ),
    onMessageSendAction: (
      msgText: string,
      msgTempData: IMessageRespData,
      parentMessage?: IReplyMessageObject
    ) => {
      onMessageSendAction(
        isComponentMounted?.current,
        msgText,
        msgTempData,
        parentMessage
      );
    },
    onAttachmentDelete: onAttachmentDelete,
    onMessageDelete: onMessageDelete,
    handleCommunicationTypeSaveOnMessageList:
      handleCommunicationTypeSaveOnMessageList,
    checkCommunicationTypeAssignedToSelectedMessages:
      checkCommunicationTypeAssignedToSelectedMessages,
    getMessages: (data: IGetMessages) => {
      getMessages(data, isComponentMounted?.current);
    },
    onMessageUpdateAfterEditMessageByCurrentUser:
      onMessageUpdateAfterEditMessageByCurrentUser,
    checkLastNonInternalMessageData: checkLastNonInternalMessageData,
  }));

  const messageList = getUniqueDisplayMessageList(msgListData?.displayData);
  const lastNonInternalMessageInDisplayList = getLastNonInternalMessageInList(
    msgListData?.displayData || []
  );
  const row: Array<any> = React.useMemo(() => {
    return [];
  }, []);
  let prevOpenedRow: any;
  const flatListRef = useRef<RNFlatList<IMessageBoxData>>(null);

  const scrollToMessage = React.useCallback((msg: IMessageBoxData) => {
    if (isWeb()) {
      onScrollToMessageFail(msg);
      return;
    }
    const index = messageList.findIndex((element) => element.uuid === msg.uuid);
    if (index === -1 || index > 15) {
      onScrollToMessageFail(msg);
    }
    if (flatListRef?.current && index > 1) {
      try {
        flatListRef.current?.scrollToIndex({
          animated: true,
          index: index,
          viewPosition: 0,
        });
        onScrollToMessageFail(msg, true);
      } catch (error) {}
    }
  }, []);

  const onScrollToMessageFail = (
    msg: IMessageBoxData,
    isSetClickedMessageUuid?: boolean
  ) => {
    if (msg?.messageData) {
      getMsgDataOfNextAndPrevMessages(msg?.messageData);
    }
  };

  const getMsgDataOfNextAndPrevMessages = React.useCallback(
    (msg: ISearchMessageData) => {
      const index = msgListData?.displayData?.findIndex(
        (item) => item?.uuid === msg?.uuid
      );
      if (index === -1) {
        handleOnScrollToMessageFail(msg, isComponentMounted?.current);
      }
    },
    [msgListData?.displayData]
  );

  const closeRow = React.useCallback((index: number) => {
    if (prevOpenedRow) prevOpenedRow.close();
    prevOpenedRow = row[index];
  }, []);

  const handleCopyClick = React.useCallback(
    (selectedItem: ICopiedMessageItem) => {
      const mutableArray = stateData.copiedMsgList;
      let updatedArray = [] as ICopiedMessageItem[];
      if (selectedItem?.isChecked) {
        let selectedMessage = {} as ICopiedMessageItem;
        messageList?.forEach((msg) => {
          if (msg.id === selectedItem.id) {
            selectedMessage = selectedItem;
          } else {
            return;
          }
        });
        if (selectedMessage.id) {
          mutableArray.push(selectedMessage);
          updatedArray = mutableArray;
          setStateData((prev) => {
            return {
              ...prev,
              copiedMsgList: mutableArray,
            };
          });
        }
      } else {
        const index = mutableArray.findIndex(
          (msg) => msg.id === selectedItem.id
        );
        if (index !== -1) {
          mutableArray.splice(index, 1);
        }
        updatedArray = mutableArray;
        setStateData((prev) => {
          return {
            ...prev,
            copiedMsgList: mutableArray,
          };
        });
      }
      const updatedOrderArray = updatedArray.sort(function (a, b) {
        const firstDate: any = new Date(b.date);
        const secondDate: any = new Date(a.date);
        if (isCopyOrSelectMessageInDescending) {
          const diff: any = firstDate - secondDate;
          return diff;
        } else {
          const diff: any = secondDate - firstDate;
          return diff;
        }
      });
      props.onActionPerformed?.(
        COMMON_ACTION_CODES.COPY_MESSAGE_LIST,
        updatedOrderArray
      );
    },
    [stateData.copiedMsgList?.length, props.isCopyModeStateOnCode]
  );

  useEffect(() => {
    if (
      props.isCopyModeStateOnCode == COPY_MESSAGE_CODE.COPY_MODE_COPIED ||
      props.isCopyModeStateOnCode == COPY_MESSAGE_CODE.COPY_MODE_OFF
    ) {
      setStateData((prev) => {
        return {
          ...prev,
          copiedMsgList: [],
        };
      });
    }
  }, [props.isCopyModeStateOnCode]);

  useEffect(() => {
    const isLoading =
      msgListData.msgDataLoading || msgListData.loadingLoadMore || false;
    eventQueue.updateLoadingStatus({isLoading: isLoading});
  }, [msgListData.msgDataLoading, msgListData.loadingLoadMore]);

  const onMsgReceivedListenerFn = useCallback(() => {
    if (!flatListRef.current || isMentionConversation || isWeb()) {
      return;
    }
    flatListRef.current.scrollToIndex({animated: true, index: 0});
  }, [conversationUuid]);

  useEffect(() => {
    eventQueue.addEventListener(
      CONVERSATION_LOCAL_EVENT_CODES.NEW_MESSAGE_CREATED,
      onMsgReceivedListenerFn
    );
    isComponentMounted.current = true;
    if (isComponentMounted?.current) {
      if (
        selectedConversation?.conversationMentionId &&
        selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION
      ) {
        resetAndFetchFirstBatch(isComponentMounted?.current, true);
      } else {
        getSelectedConversationMessageDataWithLastMessage(
          isComponentMounted?.current
        );
      }
    }
    return () => {
      eventQueue.removeEventQueue();
      isComponentMounted.current = false;
      isScrollingToPrevMessages.current = false;
    };
  }, []);

  useEffect(() => {
    // isComponentMounted.current = true;
    if (searchMessage?.uuid) {
      if (isComponentMounted?.current) {
        setMsgListData((prev) => {
          return {
            ...prev,
            clickEventId: searchMessage.clickEventId || '',
            newMessageUuids: [],
          };
        });
      }
      getMsgDataOfNextAndPrevMessages(searchMessage);
    }
  }, [searchMessage?.clickEventId]);

  const loadMoreOlderMessages = async () => {
    if (msgListData?.areMessagesRemaining) {
      setMsgListData((prev) => {
        return {
          ...prev,
          loadingLoadMore: true,
        };
      });
      onFetchMoreIfAvailableCallback(isComponentMounted?.current);
    } else {
      setMsgListData((prev) => {
        return {
          ...prev,
          loadingLoadMore: false,
        };
      });
    }
  };

  const loadMoreRecentMessages = async () => {
    if (
      msgListData?.lastConversationMsgUuid !== messageList?.[0]?.uuid &&
      msgListData?.lastConversationMsgUuid !== messageList?.[1]?.uuid
    ) {
      setMsgListData((prev) => {
        return {
          ...prev,
          loadingLoadMore: false,
          footerMsgLoading: true,
        };
      });
      onFetchMoreRecentIfAvailableCallback(isComponentMounted?.current);
    }
  };

  const onMessageActionCallback = useCallback(
    (
      actionId: MessageActionID,
      data?: any,
      replyObject?: IReplyMessageObject
    ) => {
      setMsgListData((prev) => ({
        ...prev,
        selectedMessageItem: data,
      }));

      props?.onMessageAction?.(actionId, data, replyObject);
    },
    [props?.onMessageAction]
  );

  const resetClickedMessageUuid = React.useCallback(() => {
    if (isComponentMounted?.current) {
      setMsgListData((prev) => {
        return {
          ...prev,
          clickMessageUuid: '',
          clickEventId: '',
        };
      });
    }
  }, [isComponentMounted?.current]);

  const onAddCommunicationTypeCallback = React.useCallback(
    (data: {messageUuid: string; types: ICommunicationType[]}) =>
      onAddCommunicationType(data, isComponentMounted?.current),
    [isComponentMounted?.current]
  );

  return (
    <View flex={1}>
      {messageList?.length > 0 ? (
        <>
          <SafeAreaView style={styles.safeAreaViewStyle}>
            <GestureHandlerRootView style={styles.gestureHandlerRootViewStyle}>
              {msgListData?.loadingLoadMore && (
                <View {...testID(TestIdentifiers.pageLoading)}>
                  <Skeleton.Text lines={3}></Skeleton.Text>
                </View>
              )}
              <FlatList
                inverted
                keyboardShouldPersistTaps="handled"
                initialNumToRender={
                  msgListData?.clickMessageUuid || msgListData?.clickEventId
                    ? 30
                    : 1
                } 
                windowSize={5}
                maxToRenderPerBatch={
                  msgListData?.clickMessageUuid || msgListData?.clickEventId
                    ? 30
                    : 1
                }
                disableVirtualization={true}
                onEndReached={loadMoreOlderMessages}
                onStartReached={loadMoreRecentMessages}
                onEndReachedThreshold={1}
                onStartReachedThreshold={0.1}
                style={styles.flatListStyle}
                data={messageList}
                keyExtractor={(item) => 'msgBox_' + item.uuid}
                renderItem={({index, item}) => {
                  let key = item?.taskCount
                    ? item.uuid + item.displayContent + item?.taskCount
                    : item.uuid + item.displayContent;
                  if (item?.communicationTypes?.length) {
                    key = key + item?.communicationTypes?.length;
                  }
                  return (
                    <RenderMsgList
                      channelData={
                        selectedConversation?.conversationInbox?.channelEmail
                      }
                      groupMemberId={groupMemberId}
                      handleCopyClick={handleCopyClick}
                      index={isWeb() ? 0 : index}
                      item={item}
                      lastNonInternalMessageInDisplayList={
                        isEmailInbox
                          ? lastNonInternalMessageInDisplayList
                          : undefined
                      }
                      lastSeenData={stateData.lastSeenData}
                      onRedirectToMentionChange={onRedirectToMentionChange}
                      onReplyCallback={props.onReplyCallback}
                      prevMessage={
                        index != undefined ? messageList[index + 1] : undefined
                      }
                      redirectToMention={props.redirectToMention}
                      scrollToMessage={scrollToMessage}
                      searchMessage={searchMessage}
                      selectedConversation={selectedConversation}
                      clickEventId={msgListData?.clickEventId}
                      clickMessageUuid={msgListData?.clickMessageUuid}
                      groupMemberDataLoading={props.groupMemberDataLoading}
                      isCopyModeStateOnCode={props.isCopyModeStateOnCode}
                      isReadOnlyView={props.isReadOnlyView}
                      messageAction={props.messageAction}
                      navigation={props.navigation}
                      onActionPerformed={props.onActionPerformed}
                      onAddCommunicationType={onAddCommunicationTypeCallback}
                      onMessageAction={onMessageActionCallback}
                      onViewTaskAction={props.onViewTaskAction}
                      resetClickedMessageUuid={resetClickedMessageUuid}
                      selectedInboxTypeCode={props.selectedInboxTypeCode}
                      lastEmailMessageUuid={msgListData?.lastEmailMessageUuid}
                      showModal={props.showModal}
                      closeRow={closeRow}
                      row={row}
                      key={key}
                    />
                  );
                }}
                onScrollBeginDrag={() => {
                  Keyboard.dismiss();
                }}
                {...testID('MessageList')}
              />
              {msgListData?.footerMsgLoading && (
                <View {...testID(TestIdentifiers.pageLoading)}>
                  <Skeleton.Text lines={3}></Skeleton.Text>
                </View>
              )}
            </GestureHandlerRootView>
          </SafeAreaView>
        </>
      ) : msgListData?.msgDataLoading ? (
        <View flex={1} padding={4} {...testID(TestIdentifiers.pageLoading)}>
          <Skeleton.Text lines={5} />
        </View>
      ) : (
        <View flex={1} width={'full'} zIndex={10}>
          <Center
            alignItems={'center'}
            justifyContent={'center'}
            alignContent={'center'}
            flex={1}
            height={'100%'}
            {...testID('NoMessages')}
          >
            <EmptyStateSvg titleId="noMessages" />
          </Center>
        </View>
      )}
    </View>
  );
});

export default GetMessagingListElemV2;
