export const DEFAULT_FONT_FAMILY = 'Manrope-Regular';
export const FONT_CONFIG = {
  TEXT: {
    textColor: '#101828',
    fontFamily: DEFAULT_FONT_FAMILY,
  },
  HEADING: {
    headingColor: '#000000',
    fontWeight: '500',
    fontFamily: DEFAULT_FONT_FAMILY,
    lineHeight: '22',
  },
};
