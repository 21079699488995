import axios from 'axios';
import {CRM_BASE_URL} from '../../../../../constants/Configs';
import LocalStorage from '../../../../../utils/LocalStorage';

const getHeaders = async () => {
    const userDataString = await LocalStorage.getItem('user');
    const userData = JSON.parse(userDataString);
    const accountUUID =
      userData?.data.accounts.length > 0
        ? userData?.data.accounts[0].uuid || ''
        : '';
    const responseAccessToken = userData?.accessToken || '';
    const fold_access_token = userData?.fold_access_token;
    const headersObj: any = {
      'access-token': responseAccessToken,
      fold_access_token,
      accountUUID,
    };
    return headersObj;
  };


export const getSignature = async (id: string) => {
  const headers = await getHeaders();
  const url = CRM_BASE_URL + '/api/attachment/' + id;
  return axios.get(url, {headers: headers});
};

  
export const addOrUpdateSignature = async (file: FormData,id?:string) => {
  const headers = await getHeaders();
  const url = CRM_BASE_URL + '/api/attachment/upload';
  if(id){
    return axios.put(url, file, {headers: headers});
  }
  return axios.post(url, file, {headers: headers});
};
