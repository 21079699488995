import {Collapse} from 'antd';
import {VStack, View, Text, Divider, HStack, Spacer} from 'native-base';
import React, { useState } from 'react';
import { PatientRecordActions } from '../../../../../../../constants/FhirConstant';
import {Colors} from '../../../../../../../styles';
import {DisplayText} from '../../../../../../common/DisplayText/DisplayText';
import {EmptyStateSvg} from '../../../../../../common/Svg';
import {
  AnswerList,
  FHIRQuestionnaireObject,
  QuestionnaireObject,
} from '../../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/Questionnaire/interfaces';
import QuestionnaireRenderer from '../../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/Questionnaire/QuestionnaireRenderer/QuestionnaireRenderer';
import CustomButton from '../../CustomWrapper/CustomButton/CustomButton';
import {
  getQuestionListFromQuestionnaire,
  getQuestionnaireIdFromObject,
} from '../AddOrUpdateSocialHistory/AddOrUpdateSocialHistoryHelper';
import { reactStyles } from '../../../../Analytics/style';

interface IStructuredSocialHXViewProps {
  questionnaireList: FHIRQuestionnaireObject[];
  questionnaireAnswer: QuestionnaireObject[];
  disabled?: boolean;
  isReadOnly?: boolean;
  onAnswerChange: (
    answer: AnswerList,
    questionnaire: FHIRQuestionnaireObject
  ) => void;
  isCompact?: boolean;
  isPatientReportedRecordView?: boolean;
  onlyShowAnsweredQuestions?: boolean;
  showSavingIndicator?: boolean;
  config?: {
    answerDateFormat?: string;
  }
  onPatientRecordActionClick?: (action: PatientRecordActions, questionnaire?: QuestionnaireObject) => void;
}

const StructuredSocialHXView = (props: IStructuredSocialHXViewProps) => {
  const {
    questionnaireList,
    questionnaireAnswer,
    disabled,
    isReadOnly,
    onAnswerChange,
    isCompact,
    isPatientReportedRecordView,
    showSavingIndicator,
    onlyShowAnsweredQuestions,
    onPatientRecordActionClick,
    config,
  } = props;
  const {Panel} = Collapse;
  const expandedQuestionnaire = questionnaireList.map((item) => item.id) || [];
  const [componentState, setComponentState] = useState<{activePanels: string[]}>({
    activePanels: expandedQuestionnaire,
  });

  return (
    <VStack flex={1} space={4} marginBottom={1}>
      {!questionnaireAnswer.length &&
        isReadOnly &&
        (isCompact ? (
          <View marginLeft={6} mt={2}>
            <DisplayText
              extraStyles={{
                color: '#667085',
              }}
              textLocalId={'noSocialHistory'}
              textType="Text"
            />
          </View>
        ) : (
          <EmptyStateSvg titleId="noSocialHistory" />
        ))}

      <Collapse
        activeKey={componentState.activePanels}
        onChange={(activePanels) => {
          setComponentState((prev) => ({...prev, activePanels: activePanels as string[]}));
        }}
        expandIconPosition={'right'}
        style={reactStyles.collapseStyle6}
      >
        {questionnaireList.length > 0 &&
          questionnaireList.map((questionnaire, index) => {
            let questionList = getQuestionListFromQuestionnaire(questionnaire);
            const currentQuestionnaireAnswers = questionnaireAnswer.find((item) => {
              const questionnaireId = getQuestionnaireIdFromObject(item);
              return questionnaireId === questionnaire.id;
            });
            const answers = currentQuestionnaireAnswers?.item || [];
            if (!answers.length && isReadOnly) return <></>;
            if (onlyShowAnsweredQuestions) {
              questionList = questionList.filter((question) => {
                return answers.some((answer) => answer.linkId === question.id)
              })
            }
            return (
              <>
                <Panel
                  header={
                    <div className='page-break'>
                    <HStack space={2}>
                      <Text size="smSemibold">{questionnaire.name}</Text>
                      <Spacer />
                      {isPatientReportedRecordView && (
                        <HStack space={2}>
                          <CustomButton
                            title="Accept"
                            isDisabled={showSavingIndicator}
                            onPress={() => {
                              onPatientRecordActionClick && onPatientRecordActionClick(PatientRecordActions.accept, currentQuestionnaireAnswers);
                            }}
                          />
                          <CustomButton
                            title="Discard"
                            isDisabled={showSavingIndicator}
                            onPress={() => {
                              onPatientRecordActionClick && onPatientRecordActionClick(PatientRecordActions.deny, currentQuestionnaireAnswers);
                            }}
                          />
                        </HStack>
                      )}
                    </HStack>
                    </div>
                  }
                  key={questionnaire.id}
                >
                  <View paddingX={4}>
                    <QuestionnaireRenderer
                      isReadOnly={disabled || isReadOnly || false}
                      questions={questionList}
                      answers={answers}
                      config={config}
                      onAnswerChange={(answer) => {
                        onAnswerChange(answer, questionnaire);
                      }}
                    />
                  </View>
                </Panel>
                {index < questionnaireList.length - 1 && <Divider />}
              </>
            );
          })}
      </Collapse>
    </VStack>
  );
};

export default StructuredSocialHXView;
