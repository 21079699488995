import parse from 'html-react-parser';
import {Liquid} from 'liquidjs';
import {debounce} from 'lodash';
import {HStack, Icon, ScrollView, Spinner, Text, VStack} from 'native-base';
import {useEffect, useState} from 'react';
import {Dimensions, Pressable, View} from 'react-native';
import AntDesign from 'react-native-vector-icons/AntDesign';
import {BUTTON_TYPE} from '../../../../../constants';
import {Colors} from '../../../../../styles';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import PageBodyContainer from '../../../../common/PageBodyContainer/PageBodyContainer';
import {SearchBar} from '../../../../common/SearchBar';
import {ContentTypes} from '../../ContentManagementConsts';
import {
  getAccountMergeTagData,
  getTemplateCategories,
  getTemplateCategoryList,
  getTemplates,
} from '../../ContentManagementUtils';
import {
  getCategoryQueryString,
  getFormattedEmailTemplateData,
} from '../../EmailTemplates/EmailTemplatesUtils';
import {ITemplateCategory} from '../../EmailTemplates/interfaces';
import {ICampaignErrors} from '../interfaces';
import {styles} from './EmailOrSmsCreatorFormStyles';
import EmailTemplateCreatorEditorRightPanel from './EmailTemplateCreatorEditorRightPanel';
import TemplateCategoryAutoComplete from './TemplateCategoryAutoComplete';

const EmailOrSmsCreatorForm = (props: {
  emailTemplateId?: string;
  campaignDetailsError?: ICampaignErrors;
  onTemplateIdSelected: (value: string) => void;
  onCancel: () => void;
}) => {
  const {height, width} = Dimensions.get('window');
  const [searchedEmailTemplates, setSearchedEmailTemplates] = useState<any[]>(
    []
  );
  const [emailTemplates, setEmailTemplates] = useState<any[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<any>(null);
  const [categoryList, setCategoryList] = useState<ITemplateCategory[]>([]);
  const [showMore, setShowMore] = useState<boolean>(false);
  const [category, setCategory] = useState<ITemplateCategory>();
  const [categoryName, setCategoryName] = useState<string>();
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [searchedTemplateText, setSearchedTemplateText] = useState<string>('');

  const [templateCategory, setTemplateCategory] =
    useState<ITemplateCategory[]>();
  const [shouldCreateEmailTemplate, setShouldCreateEmailTemplate] =
    useState<boolean>(false);
  const [shouldUpdateEmailTemplate, setShouldUpdateEmailTemplate] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getOrSetEmailTemplates(props.emailTemplateId);
  }, [category]);

  useEffect(() => {
    if (selectedTemplate?.id) {
      props.onTemplateIdSelected(selectedTemplate);
    }
  }, [selectedTemplate]);

  useEffect(() => {
    getTemplateCategories()
      .then((data) => {
        return getTemplateCategoryList(data, true);
      })
      .then((list) => {
        setCategoryList(list);
        setCategory(list?.[0]);
        setCategoryName(list?.[0]?.name || '');
      })
      .catch((error) => {});
  }, []);

  const getOrSetEmailTemplates = async (id?: string) => {
    setIsLoading(true);
    const categoryResponse = await getTemplateCategories();
    const categoryList: ITemplateCategory[] = getTemplateCategoryList(
      categoryResponse,
      true
    );
    setTemplateCategory(categoryList || []);

    const path = `${ContentTypes.emails.path}?${getCategoryQueryString(
      categoryList,
      category && category?.code != 'All' ? [category?.name] : []
    )}`;

    const emailResponse = await getTemplates(path, {
      name: '',
      page: 1,
      pageSize: 200,
      sort: 'desc',
    });

    const data = getFormattedEmailTemplateData(emailResponse);
    if (id) {
      const template = data?.find((value) => {
        return value.id == id;
      });
      if (template) {
        template;
        setSelectedTemplate(template);
      }
    } else {
      if (data && data.length > 0) {
        //setSelectedTemplate(data[0]);
      }
    }
    setEmailTemplates(data);
    setSearchedEmailTemplates(data);
    setIsLoading(false);
  };

  // account specific merge tags
  const accountMergeTags = getAccountMergeTagData();

  const getMergeTags = (category: string, categories: ITemplateCategory[]) => {
    const mergeTagsByCategory = categories.find(
      (item) => item.name === category
    )?.mergeTags;
    return {...mergeTagsByCategory, global: accountMergeTags};
  };
  const getPreviewHtml = (templateData: any): string => {
    if (!templateData?.templateHtml) {
      return '';
    }
    try {
      const engine = new Liquid();
      const finalMergeTag = getMergeTags(
        templateData?.templateCategory,
        categoryList
      );
      const tpl = engine.parse(templateData?.templateHtml);
      return engine.renderSync(tpl, finalMergeTag);
    } catch (error) {
      return '';
    }
  };

  const renderPreview = () => {
    return (
      <HStack flex={1}>
        <ScrollView>
          <HStack>
            <View style={{flex: 9}}>
              {parse(getPreviewHtml(selectedTemplate ? selectedTemplate : {}))}
            </View>
            <VStack style={{backgroundColor: '#eaecf0', width: 20}}></VStack>
          </HStack>
        </ScrollView>
      </HStack>
    );
  };

  const searchTemplates = (value: string) => {
    const searchedEmailTemplates = emailTemplates?.filter((template) => {
      if (
        template?.templateName?.toLowerCase()?.includes(value.toLowerCase())
      ) {
        return template;
      }
    });
    setSearchedEmailTemplates(searchedEmailTemplates);
    setSearchedTemplateText(value);
  };

  return (
    <>
      <PageBodyContainer style={{marginHorizontal: 0}}>
        <View style={{padding: 4}}>
          <View style={{}}>
            <VStack>
              <ModalActionTitle
                title={'emailTemplates'}
                //titleColor={Colors.Custom.Gray700}
                //titleFontSize={24}
                buttonList={[
                  {
                    show: true,
                    id: 1,
                    btnText: 'cancel',
                    textColor: Colors.Custom.mainPrimaryPurple,
                    variant: BUTTON_TYPE.SECONDARY,
                    isTransBtn: false,
                    onClick: () => {
                      if (showPreview) {
                        setShowPreview(false);
                      } else {
                        props?.onCancel();
                      }
                    },
                  },
                ]}
              />
              {showPreview ? (
                renderPreview()
              ) : (
                <View>
                  <HStack
                    flexWrap={'wrap'}
                    alignItems="center"
                    justifyContent={'space-between'}
                    style={{
                      marginBottom: 20,
                    }}
                  >
                    <TemplateCategoryAutoComplete
                      flex={0.3}
                      name={categoryName}
                      isRequired={false}
                      onAutoCompleteChange={(
                        selectedTemplateCategoryCode: ITemplateCategory
                      ) => {
                        setCategory(selectedTemplateCategoryCode);
                        setSearchedTemplateText('');
                      }}
                      onValueChange={(value: string) => {
                        const selectedTemplate =
                          templateCategory?.find((categoryObj) => {
                            return categoryObj.name === value;
                          }) || ({} as ITemplateCategory);
                        setCategoryName(selectedTemplate?.name || value);
                        setSearchedTemplateText('');
                      }}
                      showAdd={true}
                      dataList={templateCategory}
                    />

                    <SearchBar
                      searchText={searchedTemplateText || ''}
                      flex={0.3}
                      searchBarInputStyles={{
                        marginTop: 7,
                        paddingVertical: 0.1,
                      }}
                      placeholderText={'search template'}
                      borderRadius={10}
                      onChange={debounce(searchTemplates, 2000)}
                    />
                  </HStack>
                  {!isLoading ? (
                    <ScrollView backgroundColor="white">
                      <VStack>
                        {props?.campaignDetailsError?.emailTemplateError ? (
                          <DisplayText
                            textLocalId={'required'}
                            size={'smMedium'}
                            extraStyles={styles.requiredText}
                          />
                        ) : null}
                        <HStack
                          flexWrap={'wrap'}
                          justifyContent={
                            searchedEmailTemplates?.length < 2
                              ? 'flex-start'
                              : 'space-between'
                          }
                          alignItems="center"
                          backgroundColor="white"
                        >
                          <Pressable
                            style={{
                              justifyContent: 'center',
                              alignSelf: 'center',
                              backgroundColor: 'white',
                              marginBottom: 10,
                              marginLeft: width * 0.0075,
                            }}
                            onPress={() => {
                              setShouldCreateEmailTemplate(
                                !shouldCreateEmailTemplate
                              );
                              //getThumbnail();
                            }}
                          >
                            <View
                              style={[
                                styles.templateCard,
                                {
                                  marginTop: 5,
                                  alignSelf: 'center',
                                  height: width * 0.15,
                                  width: width * 0.24,
                                  justifyContent: 'center',
                                },
                              ]}
                            >
                              <Icon
                                as={AntDesign}
                                name="plus"
                                size="10"
                                color="blue.900"
                                alignSelf="center"
                              />
                            </View>
                            <Text
                              style={{
                                color: Colors.Custom.Gray700,
                                fontSize: 14,
                                marginTop: 3,
                                justifyContent: 'center',
                                alignSelf: 'center',
                                alignItems: 'center',
                              }}
                            >
                              {' '}
                            </Text>
                          </Pressable>
                          {searchedEmailTemplates.map((emailTemplate: any) => {
                            return (
                              <Pressable
                                style={{
                                  justifyContent: 'center',
                                  alignSelf: 'center',
                                  marginLeft: width * 0.0075,
                                  marginBottom: 10,
                                  position: 'relative',
                                }}
                                onPress={() => {
                                  setSelectedTemplate(emailTemplate);
                                }}
                              >
                                <View
                                  style={{
                                    height: width * 0.15 + 5,
                                    width: width * 0.24,
                                    position: 'absolute',
                                    paddingTop: 5,
                                    paddingHorizontal: 10,
                                    borderRadius: 12,
                                    top: 0,
                                    // @ts-ignore: Unreachable code error
                                    backgroundImage: `linear-gradient(#ffffff00,#ffffff00, #000000cc)`,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    zIndex: 2,
                                  }}
                                >
                                  <View
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      justifyContent: 'flex-end',
                                      alignItems: 'center',
                                      width: '100%',
                                      padding: 10,
                                    }}
                                  >
                                    <Pressable
                                      style={{
                                        backgroundColor: '#FFECD5',
                                        borderRadius: 50,
                                        padding: 8,
                                        marginRight: 10,
                                      }}
                                      onPress={() => {
                                        setShouldUpdateEmailTemplate(true);
                                        setSelectedTemplate(emailTemplate);
                                      }}
                                    >
                                      <AntDesign
                                        name="edit"
                                        color={'#8C210F'}
                                        size={20}
                                      />
                                    </Pressable>
                                    <Pressable
                                      style={{
                                        backgroundColor: '#FFECD5',
                                        borderRadius: 50,
                                        padding: 8,
                                      }}
                                      onPress={() => {
                                        setSelectedTemplate(emailTemplate);
                                        setShowPreview(true);
                                      }}
                                    >
                                      <AntDesign
                                        name="eye"
                                        color={'#8C210F'}
                                        size={20}
                                      />
                                    </Pressable>
                                  </View>
                                  <Pressable
                                    style={{
                                      backgroundColor: '#FFECD5',
                                      borderRadius: 50,
                                      padding: 8,
                                      width: '45%',
                                      paddingHorizontal: 44,
                                      marginBottom: 20,
                                    }}
                                    onPress={() => {
                                      props.onTemplateIdSelected(emailTemplate);
                                      setSelectedTemplate(emailTemplate);
                                      props?.onCancel();
                                    }}
                                  >
                                    <Text
                                      size="smNormal"
                                      fontSize={14}
                                      color="#8C210F"
                                      textAlign={'center'}
                                    >
                                      Use
                                    </Text>
                                  </Pressable>
                                </View>
                                <View
                                  style={[
                                    styles.templateCard,
                                    {
                                      justifyContent: 'center',
                                      alignSelf: 'center',
                                      alignItems: 'center',
                                      marginTop: 5,
                                      height: width * 0.15,
                                      width: width * 0.24,
                                      overflow: 'hidden',
                                      display: 'flex',
                                      borderWidth:
                                        selectedTemplate?.id ==
                                        emailTemplate?.id
                                          ? 2
                                          : 0,
                                      borderColor: '#825AC7',
                                    },
                                  ]}
                                >
                                  {parse(getPreviewHtml(emailTemplate))}
                                </View>
                                <Text
                                  style={{
                                    color: Colors.Custom.Gray700,
                                    fontSize: 14,
                                    marginTop: 3,
                                    justifyContent: 'center',
                                    alignSelf: 'center',
                                    alignItems: 'center',
                                  }}
                                >
                                  {emailTemplate && emailTemplate.templateName}
                                </Text>
                              </Pressable>
                            );
                          })}
                        </HStack>
                      </VStack>
                    </ScrollView>
                  ) : (
                    <View style={{height: height - 300}}>
                      <Spinner
                        flex={8.5}
                        size="lg"
                        position="absolute"
                        alignSelf="center"
                        top="50%"
                        left="50%"
                      />
                    </View>
                  )}
                </View>
              )}
              {/* {!shouldCreateEmailTemplate ? (
                <HStack flex={8.5}>
                  <ScrollView>
                    <HStack>

                      <VStack style={{ backgroundColor: '#eaecf0', width: 20 }}></VStack>
                    </HStack>
                  </ScrollView>
                </HStack>
              ) : null} */}
            </VStack>
          </View>
        </View>

        {shouldCreateEmailTemplate && (
          <EmailTemplateCreatorEditorRightPanel
            id={''}
            isVisible={true}
            isTemplateCreate={true}
            onCancel={() => {
              setShouldCreateEmailTemplate(false);
              // getOrSetEmailTemplates();
            }}
            shouldHideDefault={true}
            shouldHideTitle={true}
            preSelectedCategory={'Campaign'}
            shouldDisableChangeCategory={true}
            customOnSubmitFn={(resp: any) => {
              if (resp?.data?.id) {
                setShouldCreateEmailTemplate(false);
                getOrSetEmailTemplates(resp?.data?.id);
                props?.onCancel();
              }
            }}
          />
        )}

        {shouldUpdateEmailTemplate && (
          <EmailTemplateCreatorEditorRightPanel
            id={selectedTemplate.id}
            isHtml={selectedTemplate?.templateData?.content?.isHTML}
            isTemplateCreate={false}
            isVisible={true}
            onCancel={() => {
              setShouldUpdateEmailTemplate(false);
              //getOrSetEmailTemplates();
            }}
            shouldHideDefault={true}
            shouldHideTitle={true}
            preSelectedCategory={'Campaign'}
            shouldDisableChangeCategory={true}
            customOnSubmitFn={(resp: any) => {
              if (resp?.data?.id) {
                setShouldUpdateEmailTemplate(false);
                getOrSetEmailTemplates(resp?.data?.id);
                props?.onCancel();
              }
            }}
          />
        )}
      </PageBodyContainer>
    </>
  );
};

export default EmailOrSmsCreatorForm;
