import {HStack, Image, Text, View, VStack} from 'native-base';
import React from 'react';
import {getAccountMergeTagData} from '../../ContentManagementUtils';
import {IPushNotificationPreviewProps} from '../interfaces';
import {NOTIFICATION_BODY_LENGTH} from '../PushNotificationConsts';
import {styles} from './PushNotificationPreviewStyles';

const PushNotificationPreview = (props: IPushNotificationPreviewProps) => {
  const {notificationSubject, notificationBody} = props;
  const widthHeightRatio = props?.widthHeightRatio;

  const getTrimmedSubject = (subject: string) => {
    if (subject.length < 50) {
      return subject;
    }
    return subject.slice(0, 50) + '...';
  };

  const getTrimmedBody = (body: string) => {
    if (body.length < 168) return body;
    return body.slice(0, NOTIFICATION_BODY_LENGTH) + '...';
  };

  const accountMergeTags = getAccountMergeTagData();
  const logo = accountMergeTags?.accountLogo || '';

  const relativeFontSize = widthHeightRatio? widthHeightRatio*14 : 14;
  const relativeDimention = widthHeightRatio? widthHeightRatio*32 : 32;

  return (
    <View style={styles.container}>
      <HStack>
        <View
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: 16,
          }}
        >
          <Image
            src={logo}
            style={{
              height: relativeDimention,
              width: relativeDimention,
            }}
          />
        </View>
        <VStack flex={1}>
          <HStack
            style={{
              marginBottom: 2,
            }}
            justifyContent={'space-between'}
          >
            <View>
              <Text style={[styles.titleText, {fontSize: relativeFontSize}]}>
                {getTrimmedSubject(notificationSubject)}
              </Text>
            </View>
          </HStack>
          <View
            style={{
              marginTop: 2,
            }}
          >
            <Text style={[styles.bodyText, {fontSize: relativeFontSize}]}>
              {getTrimmedBody(notificationBody)}
            </Text>
          </View>
        </VStack>
      </HStack>
    </View>
  );
};

export default PushNotificationPreview;
