export const DOCUMENT_CATEGORY_CODES = {
    OTHERS: "Others",
    EXTERNAL_VISIT_SUMMARY: "external_visit_summary",
    CONSENT_FORMS: "consent_forms",
    HANDOUTS: "handouts",
    HEALTH_ASSESSMENT: "health_assessment",
    CARE_PLANS: "care_plans",
    REFERRALS: "referrals",
    REPORTS: "reports",
    FORMS: "forms",
    PERSONAL_IDS: "personal_ids",
    MEDICAL_RECORDS: "medical_records",
    IMAGING_REPORTS: "imaging_reports",
    LAB_REPORTS: "lab_reports",
    PHYSICAL_THERAPY_NOTES: "physical_therapy_notes",
    ALL_DOCUMENTS: "all_documents",
};

export const CARE_PLAN_SUB_CATEGORY = {
    CARE_JOURNEY: "CARE_JOURNEY",
    SUPPORTING_JOURNEY_DOCUMENTS: "SUPPORTING_JOURNEY_DOCUMENTS",
    PATIENT_CARE_JOURNEY: "PATIENT_CARE_JOURNEY"
}

export const MAX_DOC_TITLE_CHAR_LIMIT = 55;