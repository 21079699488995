import LocalStorage from '../../utils/LocalStorage';
import BaseService from '../CommonService/BaseService';

const baseService = BaseService.getSharedInstance().axios;

export class EncryptionService {
  private static instance: EncryptionService;
  private encryptionKeyConfig: JsonWebKey | null = null;
  constructor() {}

  public static getInstance() {
    if (!EncryptionService.instance) {
      EncryptionService.instance = new EncryptionService();
    }
    return EncryptionService.instance;
  }

  public async encrypt(data: string) {
    const keyConfig = await this.getEncryptionKeyConfig();
    if (!keyConfig?.k) {
      console.error('Encryption key is not set');
      return null;
    }
    const iv = crypto.getRandomValues(new Uint8Array(12));
    const encoder = new TextEncoder();
    const encodedMessage = encoder.encode(data || '');
    const importedKey = await crypto.subtle.importKey(
      'jwk',
      keyConfig,
      {name: 'AES-GCM'},
      true,
      ['encrypt']
    );
    const encryptedMessage = await crypto.subtle.encrypt(
      {
        name: 'AES-GCM',
        iv: iv,
      },
      importedKey,
      encodedMessage
    );
    return {
      iv: iv,
      encryptedMessage: encryptedMessage,
    };
  }

  public async decrypt(iv: Uint8Array, encryptedMessage: ArrayBuffer) {
    const keyConfig = await this.getEncryptionKeyConfig();
    if (!keyConfig?.k) {
      console.error('Encryption key is not set');
      return null;
    }
    const importedKey = await crypto.subtle.importKey(
      'jwk',
      keyConfig,
      {name: 'AES-GCM'},
      true,
      ['decrypt']
    );
    const decryptedMessage = await crypto.subtle.decrypt(
      {
        name: 'AES-GCM',
        iv: iv,
      },
      importedKey,
      encryptedMessage
    );
    const decoder = new TextDecoder();
    const content = decoder.decode(decryptedMessage);
    return content;
  }

  public async getEncryptionKeyConfig() {
    if (this.encryptionKeyConfig?.k) {
      return this.encryptionKeyConfig;
    }
    const key = await LocalStorage.getItem('encryptionKey');
    this.encryptionKeyConfig = {
      key_ops: ['encrypt', 'decrypt'],
      ext: true,
      kty: 'oct',
      k: key,
      alg: 'A256GCM',
    };
    return this.encryptionKeyConfig;
  }

  public async setEncryptionKeyConfig() {
    let key: string | null = await LocalStorage.getItem('encryptionKey');
    if (key) {
      // encryption key is already set
      return;
    }
    key = await this.getEncryptionKey();
    if (!key) {
      return;
    }
    await LocalStorage.setItem('encryptionKey', key);
    this.encryptionKeyConfig = {
      key_ops: ['encrypt', 'decrypt'],
      ext: true,
      kty: 'oct',
      k: key,
      alg: 'A256GCM',
    };
  }

  private async getEncryptionKey() {
    const url = `crm-nest/encryption/indexed-db-encryption-key`;
    try {
      const response = await baseService.get<{key: string}>(url);
      return response.data?.key;
    } catch (error) {
      console.error('Error fetching encryption key:', error);
      return null;
    }
  }
}
