import React from "react";
import { ICommonSvgProps } from "../interfaces";

const EmailActionSvg  = (props: ICommonSvgProps)=> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{width: props?.width ? props?.width : 'inherit', height:  props?.height ? props?.height : 'inherit'}}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={props?.customStrokeColor ? props.customStrokeColor : !props.isActive ? "#D0D5DD"  : '#667085' }
        d="M3.99992 5.83464L5.43918 7.03402C6.66361 8.05437 7.27582 8.56455 7.99992 8.56455C8.72402 8.56455 9.33623 8.05437 10.5607 7.03402L11.9999 5.83464M6.66659 13.8346H9.33325C11.8474 13.8346 13.1045 13.8346 13.8855 13.0536C14.6666 12.2725 14.6666 11.0155 14.6666 8.5013C14.6666 5.98714 14.6666 4.73007 13.8855 3.94902C13.1045 3.16797 11.8474 3.16797 9.33325 3.16797H6.66659C4.15243 3.16797 2.89535 3.16797 2.1143 3.94902C1.33325 4.73007 1.33325 5.98714 1.33325 8.5013C1.33325 11.0155 1.33325 12.2725 2.1143 13.0536C2.89535 13.8346 4.15243 13.8346 6.66659 13.8346Z"
        stroke-linecap="round"
      ></path>
    </svg>
  );
}

export default React.memo(EmailActionSvg);
