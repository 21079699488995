import {Image} from 'native-base';
import React from 'react';
import {isWeb} from '../../../utils/platformCheckUtils';
import { ICommonSvgProps } from './interfaces';

const WarningSvg = (props: ICommonSvgProps) => {
  const fillColor = props.customStrokeColor || '#F79009';
  const size = props.strokeWidth || 24;
  return (
    <>
      {isWeb() ? (
        <svg
          width={size}
          height={size}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.86 2H16.14L22 7.86V16.14L16.14 22H7.86L2 16.14V7.86L7.86 2Z"
            stroke={fillColor}
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12 8V12"
            stroke={fillColor}
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12 16H12.01"
            stroke={fillColor}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ) : (
        <Image
          width={'6'}
          height={'6'}
          source={require('../../../assets/images/warning.png')}
          alt="aa"
        />
      )}
    </>
  );
};

export default WarningSvg;
