import React, {useContext, useMemo, useState} from 'react';
import {IContactCareProgramStepOutreach, StepOutreachLogModeOfContact, UpdateCareProgramStepOutreachInput} from '../../../../services/ContactCareProgram/interface';
import {HStack, Spacer, Text, VStack, Pressable, View} from 'native-base';
import {Checkbox, Tooltip} from 'antd';
import useOutreachStatus from '../useOutreachStatus';
import {Colors} from '../../../../styles';
import Feather from 'react-native-vector-icons/Feather';
import {useIntl} from 'react-intl';
import OutreachStepNoteView, {IData} from './OutreachStepNoteView';
import CareProgramStepOutreachUtils from '../CareProgramStepOutreachUtils';
import {useMutation} from '@apollo/client';
import {UPDATE_OUTREACH_MANUALLY} from '../../../../services/ContactCareProgram/ContactCareProgram';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../constants/Configs';
import {useToast} from '../../../Toast/ToastProvider';
import {ToastType} from '../../../../utils/commonViewUtils';
import {IUser} from '../../../../Interfaces';
import useOutreachStepOutComes from '../useOutreachStepOutComes';
import CheckBox from '../../V2/Checkbox';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import { getMlovIdFromCode, getMlovListFromCategory } from '../../../../utils/mlovUtils';
import { MLOV_CATEGORY } from '../../../../constants';
import { CARE_PROGRAM_OUTREACH_MODE_CODES } from '../../../../constants/MlovConst';

interface IOutreachStepProps {
  step: IContactCareProgramStepOutreach;
  checkIfAllPreviousRequiredStepsAreCompleted: (currentSequence: number) => boolean;
  userMap: Record<string, IUser>;
  syncParent: (newData: IContactCareProgramStepOutreach) => void;
  anyNoteInEditMode: string | undefined;
  onNoteEditModeChange: (value: string | undefined) => void;
  isCloseButtonClicked: boolean;
  onCloseButtonClicked: (value: boolean) => void;
  enabledContactModes:  StepOutreachLogModeOfContact[];
  disableCompleteButton?: boolean;
}

interface IOutreachStepState {
  showNote: boolean;
  isLocallyCompleted: boolean;
}

interface IUpdateOutReachManuallyResponse {
  updateCareProgramStepOutreach: {
    id: string;
    name: string;
    communicationMode: StepOutreachLogModeOfContact;
    note: string;
    statusId: string;
    outcomeId: string;
    outreachDateTime: string;
    outreachCompletedBy: string;
  };

}



const OutreachStep = (props: IOutreachStepProps) => {
  const mlovData = useContext(CommonDataContext);
  const {outreachStepOutComes} = useOutreachStepOutComes();
  const { step, userMap, onNoteEditModeChange, anyNoteInEditMode, isCloseButtonClicked, onCloseButtonClicked } = props;
  const intl = useIntl();
  const outreachStatus = useOutreachStatus();
  const toast = useToast();
  const {
    stepOutreachLog: {statusId},
    name,
  } = step;

  const outComeMlov = useMemo(() => outreachStepOutComes.find(mlov => mlov.id === step.stepOutreachLog.outcomeId), [step.stepOutreachLog.outcomeId]);

  const [componentState, setComponentState] = useState<IOutreachStepState>({
    showNote: false,
    isLocallyCompleted: outreachStatus.success === statusId,
  });
  const [stepSaveLoading, setStepSaveLoading] = useState(false);
  const [updateOutReachManually] = useMutation<IUpdateOutReachManuallyResponse>(UPDATE_OUTREACH_MANUALLY, {
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
    fetchPolicy: 'no-cache',
  });

  const stepOutreachModeList =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.CARE_PROGRAM_OUTREACH_MODE
    ) || [];

  const stepOutreachModes = {
    automation: getMlovIdFromCode(
      stepOutreachModeList,
      CARE_PROGRAM_OUTREACH_MODE_CODES.AUTOMATION
    ),
  };

  const isOutreachDoneByAutomation: boolean =
    step.stepOutreachLog.typeId === stepOutreachModes.automation;

  const subtitleElement = useMemo(
    () =>
      !componentState.showNote
        ? CareProgramStepOutreachUtils.getSubtitleText({
            step,
            userMap,
            outcomeMLOV: outComeMlov,
            isOutreachDoneByAutomation: isOutreachDoneByAutomation,
          })
        : null,
    [
      step.stepOutreachLog,
      userMap,
      componentState.isLocallyCompleted,
      componentState.showNote,
    ]
  );
  

  const renderAddNoteButton = (): JSX.Element | null => {
    if (componentState.showNote) {
      return null;
    }
    return (
      <Pressable
        flexDir="row"
        alignItems="center"
        onPress={() => {
          if (!anyNoteInEditMode) {
            onNoteEditModeChange(step.id);
            setComponentState((prev) => ({...prev, showNote: true}));
          }
          else {
            onCloseButtonClicked(true);
          }
        }}
      >
        <Feather
          name={componentState.isLocallyCompleted ? 'edit-2' : 'plus'}
          size={20}
          color={
            componentState.isLocallyCompleted
              ? Colors.FoldPixel.GRAY300
              : Colors.FoldPixel.PRIMARY300
          }
        />
        {!componentState.isLocallyCompleted && (
          <Text fontSize={16} color={Colors.FoldPixel.PRIMARY300}>
            {intl.formatMessage({
              id: componentState.isLocallyCompleted ? 'editNote' : 'addNote',
            })}
          </Text>
        )}
      </Pressable>
    );
  };

  const updateParentWithNewData = (newDataParams: IUpdateOutReachManuallyResponse) => {
    const {updateCareProgramStepOutreach} = newDataParams;
    const newStepData = {...step};
    newStepData.stepOutreachLog = {
      ...newStepData.stepOutreachLog,
      note: updateCareProgramStepOutreach.note,
      communicationMode: updateCareProgramStepOutreach.communicationMode,
      statusId: updateCareProgramStepOutreach.statusId,
      outcomeId: updateCareProgramStepOutreach.outcomeId,
      outreachCompletedBy: updateCareProgramStepOutreach.outreachCompletedBy,
      outreachDateTime: updateCareProgramStepOutreach.outreachDateTime,
    };
    props.syncParent(newStepData);
    setComponentState((prev) => ({...prev, showNote: false}));
  }


  const onCheckboxChange = async (value: boolean) => {
    const prevValue = componentState.isLocallyCompleted;
    try {
      setComponentState((prev) => ({...prev, isLocallyCompleted: value}));
      // api call only when the note view is not open when note view is open the api call will be made from the note view's save button
      if (!componentState.showNote) {
        const statusId = value
          ? outreachStatus.success
          : outreachStatus.pending;
        const params = CareProgramStepOutreachUtils.getFormattedDataForUpdate(
          props.step,
          {
            // passing the previous value of the note, communication mode & outcome id
            modeOfContact: step.stepOutreachLog.communicationMode,
            outcomeId: step.stepOutreachLog.outcomeId,
            note: step.stepOutreachLog.note,
          } as IData,
          statusId
        );
        const response = await updateOutReachManually({variables: {params}});
        if (response?.data?.updateCareProgramStepOutreach.id) {
           updateParentWithNewData(response.data);
          toast({
            message: 'Outreach status updated successfully',
            toastType: ToastType.success,
            closeAllPrevToast: true,

          });
        }
      }
    } catch (e) {
      toast({
        message: 'Failed to update outreach status',
        toastType: ToastType.error,
        closeAllPrevToast: true,
      });
      setComponentState((prev) => ({...prev, isLocallyCompleted: prevValue}));
    }
  };

  const onSaveStepLog = async (data: IData) => {
    setStepSaveLoading(true);
    const statusId = componentState.isLocallyCompleted
      ? outreachStatus.success
      : outreachStatus.pending;
    const params = CareProgramStepOutreachUtils.getFormattedDataForUpdate(
      props.step,
      data,
      statusId
    );
    try {
      const response = await updateOutReachManually({variables: {params}});
      if (response?.data?.updateCareProgramStepOutreach.id) {
        updateParentWithNewData(response.data);
      }
      setComponentState((prev) => ({...prev, showNote: false}));
      onNoteEditModeChange(undefined);
      onCloseButtonClicked(false);
      setStepSaveLoading(false);
    } catch (e) {
      toast({
        message: 'Failed to update outreach status',
        toastType: ToastType.error,
        closeAllPrevToast: true,
      });
      setStepSaveLoading(false);
    }
  }
  const isAllowToComplete = props.checkIfAllPreviousRequiredStepsAreCompleted(step.sequence) && !isOutreachDoneByAutomation && !props?.disableCompleteButton;

  return (
    <VStack flex={1} mb={4}>
      <HStack alignItems={'center'}>
        <Tooltip
          title={
            isOutreachDoneByAutomation
              ? intl.formatMessage({ id: 'outreachCompletedByAutomationMsg'})
              : ''
          }
          placement="top"
        >
          <View>
            <CheckBox
              isDisabled={!isAllowToComplete}
              isChecked={componentState.isLocallyCompleted}
              onChange={onCheckboxChange}
            />
          </View>
        </Tooltip>
        <VStack flex={1} ml={2} mt={-1}>
          <HStack alignItems={'center'}>
            <Text color={Colors.FoldPixel.GRAY400} fontSize={14} fontWeight={600}>
              {name}
            </Text>
            <Spacer />
            {renderAddNoteButton()}
          </HStack>
          {subtitleElement}
        </VStack>
      </HStack>
      {componentState.showNote && (
        <OutreachStepNoteView
          stepSaveLoading={stepSaveLoading}
          onCancel={() => {
            setComponentState((prev) => ({ ...prev, showNote: false }))
            onNoteEditModeChange(undefined);
            onCloseButtonClicked(false)
          }}
          onSave={onSaveStepLog}
          log={step.stepOutreachLog}
          showNotSaved={isCloseButtonClicked}
          enabledContactModes={props.enabledContactModes}
        />
      )}
    </VStack>
  );
};

export default OutreachStep;
