import {useMutation} from '@apollo/client';
import {Drawer} from 'antd';
import {Pressable, Text, View, VStack} from 'native-base';
import {useContext, useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import {
  BUTTON_TYPE,
  CUSTOM_AUTOMATION_URL,
  ENTITY_EVENTS_TITLE_SUBTITLE,
  ENTITY_EVENTS_TYPE,
  MLOV_CATEGORY,
  SENDER_TYPE,
} from '../../../../../../constants';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../../constants/Configs';
import {
  FORM_SOURCE,
  FORM_STATUS_CODE,
  TASK_ENTITY_TYPE_CODES,
} from '../../../../../../constants/MlovConst';
import {CommonDataContext} from '../../../../../../context/CommonDataContext';
import {FormsQueries, TaskQueries} from '../../../../../../services';
import CommonService from '../../../../../../services/CommonService/CommonService';
import {Colors} from '../../../../../../styles';
import {
  getAccountUUID,
  getCaslAbility,
  getFormURL,
  getMsgEchoId,
  getUserUUID,
} from '../../../../../../utils/commonUtils';
import {getDateToMomentISOString, getFormTaskDates} from '../../../../../../utils/DateUtils';
import {
  getMlovCodeIdObj,
  getMlovIdFromCode,
  getMlovListFromCategory,
} from '../../../../../../utils/mlovUtils';
import WorkFlowListByEntityEvents from '../../../../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/WorkFlowListByEntityEvents';
import {getDefaultTaskStatusId} from '../../../../../common/CareDashboard/CareDashboardUtils/CareDashboardUtils';
import FormSearch from '../../../../../common/FormSearch/FormSearch';
import {ModalActionTitle} from '../../../../../common/ModalActionTitle/ModalActionTitle';
import {MAIN_MENU_CODES} from '../../../../../SideMenuBar/SideBarConst';
import {IForm} from '../../../../Forms/interfaces';
import { USER_ACCESS_PERMISSION} from '../../../../UserAccess/UserAccessPermission';
import {sendEmailMessageAPI} from '../../ConversationChannelTabs/CreateSmsConversationDrawer/SmsConversationServices';
import {IConversationData} from '../../interfaces';
import {getUserIdListFromMsgContent} from '../ConversationMentions/ConversationMentions';
import {
  getPatientGroupMemberData,
  getShortLink,
  isGroupConversation,
  isPrivateGroupConversation,
  messageDataKeysConverter,
} from '../MessagingUtils';
import { useIntl } from 'react-intl';
import { getLoggedInAccountInfoForSidecar } from '../../../../../../sidecar/common/SideCardCommonUtils';

import FoldPermitCan from '../../../../../CommonComponents/FoldPermitCan/FoldPermitCan';
import { useContactsLocation } from '../../../../../CustomHooks/useContactsLocation';

export interface IAttachFormDrawerProps {
  updateMessage?: (message:string) => void;
  isUpdateMode?: boolean;
  isOpen: boolean;
  onClose: () => void;
  onFormSelect: (form: any) => void;
  conversationData: IConversationData;
  onMsgSend: (msgText: string, msgData?: any) => void;
  privateNote: string;
  isGetOnlyFormData?: boolean
  externalFormLocationIds?: string[];
  useExactMatchForFormLocations?: boolean;
}

const AttachFormDrawer = (props: IAttachFormDrawerProps) => {
  const {
    externalFormLocationIds,
    isOpen,
    onClose,
    onFormSelect,
    conversationData,
    onMsgSend,
    privateNote,
    useExactMatchForFormLocations
  } = props;
  const {accountId} = conversationData;
  const [formLinkData, setFormLinkData] = useState<any>();
  const [sendFormLoading, setSendFormLoading] = useState(false);
  const mlovData = useContext(CommonDataContext);
  const intl = useIntl();
  const formInfoMessage = intl.formatMessage({ id: 'genericHealthComponentFormInfoMessageForLead' });
  const formSourceList =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.FORM_SOURCE
    ) || [];
  const formSourceId = getMlovIdFromCode(
    formSourceList,
    FORM_SOURCE.PATIENT_PROFILE
  );
  const formStatusList =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.FORM_STATUS
    ) || [];
  const formStatusId = getMlovIdFromCode(
    formStatusList,
    FORM_STATUS_CODE.PENDING
  );
  const taskEntityTypes =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.TASK_ENTITY_TYPE
    ) || [];
  const userUUID = getUserUUID();
  const commonData = useContext(CommonDataContext);
  const mlovDataObj = commonData.MLOV;
  const groupMemberTypeList = mlovDataObj[MLOV_CATEGORY.GroupMemberType];
  const groupMemberCodeIdObj = getMlovCodeIdObj(groupMemberTypeList);
  const isSideCarContext = commonData.sidecarContext?.isSidecar;

  const {loading: contactLocationLoading, data: contactLocations  } = useContactsLocation({contactUuid: conversationData?.conversationContact?.uuid || '' });
  const getFormLocations = () => {
    if (externalFormLocationIds && externalFormLocationIds.length > 0) {
      return externalFormLocationIds;
    }
    const formLocationIds: string[] = [];
    (contactLocations || [])?.forEach((location) => {
      if (location?.accountLocation?.uuid) {
        formLocationIds.push(location?.accountLocation?.uuid);
      }
    });
    return formLocationIds;
  }

  const handleSendFormLink = () => {
    if (props.isGetOnlyFormData) {
      onFormSelect(formLinkData);
      onClose();
    } else {
      sendFormLink();
    }
  };


  const sendFormLink = async () => {
    if (formLinkData && formLinkData.id) {
      setSendFormLoading(true);

      const contactUUID = getContactIdFromMsgConversion();
      const params = {
        contactId: contactUUID,
        formId: formLinkData.id,
        sourceId: formSourceId,
        requestedByUserId: userUUID,
        subjectId: uuidv4(),
        generateShortLink: true,
      };
      const response = await createFormLogLink({
        variables: {
          params,
        },
      });
      const link = response.data?.createFormLogLink?.formLink;
      const textContent = `${formLinkData.name} \n ${link}`;

      // onFormSelect(textContent);
      // return;

      const commonService = CommonService.getCommonServiceInstance();
      const axiosService = commonService.axiosService;
      const conversationId = conversationData.displayId;
      const formData = new FormData();
      let ccEmails = '';
      if (conversationData?.conversationInbox?.channelEmail?.forwardRouteId && conversationData?.conversationInbox?.channelEmail?.forwardToEmail) {
        ccEmails = conversationData?.conversationInbox?.channelEmail?.forwardToEmail;
      }
      formData.append('echo_id', getMsgEchoId());
      formData.append('private', privateNote);
      formData.append('cc_emails', ccEmails);
      formData.append('bcc_emails', '');
      formData.append('content', textContent);

      let userMentionIdStr = '';
      if (groupMemberCodeIdObj && Object.keys(groupMemberCodeIdObj)?.length && textContent) {
        const userMentionIds = getUserIdListFromMsgContent(textContent, groupMemberCodeIdObj);
        if (userMentionIds?.length) {
          userMentionIdStr = userMentionIds.toString();
          formData.append('user_mention_ids', userMentionIdStr);
        }
      }
      if (conversationData?.conversationInbox?.channelEmail?.forwardRouteId && props.privateNote == 'false') {
        sendEmailMessageAPI({
          private: false,
          content: textContent,
          conversationDisplayId: conversationData?.displayId,
          inboxId: conversationData?.inboxId,
          subject: conversationData?.additionalAttributes?.mail_subject || '',
          user_mention_ids: userMentionIdStr
        }).then((response: any) => {
          setFormLinkData({});
          setSendFormLoading(false);
          if (response) {
            response.data['sender_type'] = SENDER_TYPE?.USER;
            const messageRepData = messageDataKeysConverter(response.data);
            const currentTime = getDateToMomentISOString();
            const responseData = {...messageRepData, currentTime};
            onMsgSend(messageRepData.content, responseData);
            setSendFormLoading(false);
            onClose();
          }
        })
          .catch((error: any) => {
            setSendFormLoading(false);
            onClose();
          });
      } else {
        if (props.isUpdateMode && props.updateMessage) {
          props.updateMessage(textContent);
          setFormLinkData({});
          setSendFormLoading(false);
          onClose();
        } else {
          axiosService
            .post(
              `/accounts/${accountId}/conversations/${conversationId}/messages`,
              formData,
              {
                headers: {
                  'content-type': 'multipart/form-data',
                },
              }
            )
            .then((response: any) => {
              setFormLinkData({});
              setSendFormLoading(false);
              if (response) {
                response.data['sender_type'] = SENDER_TYPE?.USER;
                const currentTime = getDateToMomentISOString();
                const responseData = {...response.data, currentTime};
                onMsgSend(response.data.content, responseData);
                setSendFormLoading(false);
                onClose();
              }
            })
            .catch((error: any) => {
              setSendFormLoading(false);
              onClose();
            });
        }
      }
    }
  };

  const [createFormLogLink] = useMutation(FormsQueries.CREATE_FORM_LOG_LINK, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
  });

  const getContactIdFromMsgConversion = () => {
    const contactUUID = conversationData?.conversationContact?.uuid || '';
    //@umesh code impact https://foldhealth.atlassian.net/browse/CRM-6974 here not getting groupMembers
    // if (
    //   isGroupConversation(conversationData) &&
    //   isPrivateGroupConversation(conversationData)
    // ) {
    //   const groupMemberData = getPatientGroupMemberData(
    //     conversationData?.groupConversation?.groupMembers || []
    //   );
    //   const contactData = groupMemberData.contact;
    //   contactUUID = contactData?.uuid || '';
    // }
    return contactUUID;
  };



  return (
    <Drawer
      visible={isOpen}
      onClose={() => {
        setFormLinkData({});
        onClose();
      }}
      destroyOnClose
      width={isSideCarContext ? '100%' : 550}
      mask={isSideCarContext ? false : true}
      closable={false}
      placement="right"
      title={
        <>
          <ModalActionTitle
            title={'Select a form'}
            titleColor={''}
            titleSize={24}
            buttonList={[
              {
                ...(isSideCarContext && {
                  show: true,
                  id: 1,
                  btnText: 'back',
                  textColor: Colors.Custom.mainSecondaryBrown,
                  variant: BUTTON_TYPE.SECONDARY,
                  isTransBtn: false,
                  onClick: () => {
                    setFormLinkData({});
                    onClose();
                  },
                }),
              },
              {
                show: true,
                id: 1,
                btnText: props.isGetOnlyFormData
                  ? 'insert'
                  : props.isUpdateMode
                  ? 'addFormLink'
                  : 'sendFormLink',
                textColor: Colors.Custom.mainPrimaryPurple,
                variant: BUTTON_TYPE.PRIMARY,
                isTransBtn: false,
                isDisabled: !formLinkData?.id,
                isLoading: sendFormLoading,
                onClick: () => {
                  handleSendFormLink();
                },
              },
            ]}
          />
        </>
      }
    >
      <VStack mx={0}>
        <VStack mb={2}>
          {!contactLocationLoading && (
            <FormSearch
              isShowError={false}
              value={{}}
              infoMessage={
                formLinkData?.isHealthComponentIncluded
                  ? formInfoMessage
                  : undefined
              }
              onChange={(value: any) => {
                setFormLinkData(value);
              }}
              formLocations={getFormLocations()}
              useAbsoluteLocations={useExactMatchForFormLocations}
            />
          )}
        </VStack>
        {/* {formLinkData && formLinkData.id && (
          <VStack style={ {marginTop: 10}}>
            <Text style={ {marginTop: 10, marginBottom: 10}}>
              Message Preview
            </Text>
            <View
              style={ {
                padding: 10,
                backgroundColor: '#FCF9FF',
                width: 'fit-content',
                borderRadius: 10,
                borderColor: Colors.Custom.Gray50,
                borderWidth: 2,
                flexDirection: 'column',
              }}
            >
              <Text>{formLinkData.name}</Text>
              <Pressable
                onPress={() => {
                  window.open(
                    getFormURL(formLinkData.id, contactUuid, accountUuid),
                    '_blank'
                  );
                }}
              >
                <Text
                  style={ {
                    textDecorationColor: 'black',
                    textDecorationStyle: 'solid',
                    textDecorationLine: 'underline',
                  }}
                >
                  Click here to open form
                </Text>{' '}
              </Pressable>
            </View>
          </VStack>
        )} */}

      <FoldPermitCan
        action={USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code}
        resource={MAIN_MENU_CODES.AUTOMATION}
        component={<WorkFlowListByEntityEvents
          addUrl={CUSTOM_AUTOMATION_URL.FORM}
          title={ENTITY_EVENTS_TITLE_SUBTITLE.FORM_WORKFLOW_TITLE}
          subtitle={ENTITY_EVENTS_TITLE_SUBTITLE.FORM_WORKFLOW_SUB_TITLE}
          entityEventList={ENTITY_EVENTS_TYPE.FORM}
          // onValueChanage={(eventEntityList: any) => {
          //   setAppointmentBookingState((prev) => ({
          //     ...prev,
          //     workflowList: eventEntityList,
          //   }));
          // }}
        ></WorkFlowListByEntityEvents>} />
      </VStack>
    </Drawer>
  );
};

export default AttachFormDrawer;
