import React from 'react';
import {isWeb} from '../../../utils/platformCheckUtils';
import {Image} from 'native-base';

const ImmunizationIconSvg = () => {
  return (
    <>
      {isWeb() ? (
        <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.687503 17.2391C0.439066 17.4875 0.439066 17.886 0.687503 18.1297L3.86563 21.3125C4.11407 21.561 4.5125 21.561 4.75625 21.3125C5.00469 21.0641 5.00469 20.6657 4.75625 20.4219L3.6125 19.2782L7.72813 15.1625L4.79844 12.2329L3.3125 13.7188L2.98907 13.3954C2.74063 13.1469 2.34219 13.1469 2.09844 13.3954C1.85 13.6438 1.85 14.0422 2.09844 14.286L4.46094 16.6485L2.73125 18.3782L1.5875 17.2344C1.33438 16.9954 0.935941 16.9954 0.687503 17.2391Z"
          fill="#98A2B3"
        />
        <path
          d="M5.68907 11.3469L10.6531 16.3063L17.6984 9.26567L12.7344 4.30161L10.5594 6.47661L12.0078 7.93442L11.1172 8.82505L9.66407 7.37192L8.375 8.66099L9.82813 10.1141L8.9375 11.0047L7.48438 9.55161L5.68907 11.3469Z"
          fill="#98A2B3"
        />
        <path
          d="M18.0594 6.58911C18.6172 6.04536 18.7719 5.15005 18.4297 4.4563L21.3125 1.57817C21.5609 1.32974 21.5609 0.931299 21.3125 0.687549C21.0641 0.439111 20.6656 0.439111 20.4219 0.687549L17.5391 3.57036C16.8266 3.23755 15.9781 3.3688 15.4063 3.94067L14.7781 4.5688L17.4313 7.22192L18.0594 6.58911Z"
          fill="#98A2B3"
        />
        <path
          d="M5.34688 17.5438L7.70938 19.9063C7.95782 20.1547 8.35625 20.1547 8.6 19.9063C8.84844 19.6579 8.84844 19.2594 8.6 19.0157L8.27657 18.6922L9.7625 17.2063L7.72344 15.1672L5.34688 17.5438Z"
          fill="#98A2B3"
        />
      </svg>
      ) : (
        <Image
          resizeMode="contain"
          size={'24px'}
          source={require('../../../assets/images/Contact/immunizations.png')}
          alt="image"
        />
      )}
    </>
  );
};

export default ImmunizationIconSvg;
