export const temp = {};

export const TIMELINE_TYPE_CODES = {
  TASK: 'TASK',
  NOTE: 'NOTES',
  ASSIGNEE: 'ASSIGNEE',
  TICKET: 'TICKET',
  TAGGED: 'TAGGED',
  MESSENGER: 'MESSENGER',
  CUSTOMER: 'CUSTOMER',
  DEAL: 'DEAL',
};

export const REMINDERS = {
  AT_DUE_DATE: 'AT_DUE_DATE',
  FIVE_MINUTES: 'FIVE_MINUTES',
  TEN_MINUTES: 'TEN_MINUTES',
  FIFTEEN_MINUTES: 'FIFTEEN_MINUTES',
  ONE_HOUR: 'ONE_HOUR',
  TWO_HOURS: 'TWO_HOURS',
  ONE_DAY: 'ONE_DAY',
  TWO_DAYS: 'TWO_DAY',
};

export interface IReminderConsts {
  reminderValue: string;
  reminderLabel: string;
}

export const ReminderConst: IReminderConsts[] = [
  {
    reminderLabel: 'At time of due date',
    reminderValue: REMINDERS.AT_DUE_DATE,
  },
  {
    reminderLabel: '5 Minutes before',
    reminderValue: REMINDERS.FIVE_MINUTES,
  },
  {
    reminderLabel: '10 Minutes before',
    reminderValue: REMINDERS.TEN_MINUTES,
  },
  {
    reminderLabel: '15 Minutes before',
    reminderValue: REMINDERS.FIFTEEN_MINUTES,
  },
  {
    reminderLabel: '1 Hour before',
    reminderValue: REMINDERS.ONE_HOUR,
  },
  {
    reminderLabel: '2 Hours before',
    reminderValue: REMINDERS.TWO_HOURS,
  },
  {
    reminderLabel: '1 Day before',
    reminderValue: REMINDERS.ONE_DAY,
  },
  {
    reminderLabel: '2 Days before',
    reminderValue: REMINDERS.TWO_DAYS,
  },
];
