import { Text, View } from "native-base"
import Stack from "../../../../common/LayoutComponents/Stack"
import { IInputElement } from "./FlowNodeInterface"
import ZipCodeAutoComplete from "../../../../common/ZipCodeComponent/ZipCode"
import { IZipCodeData } from "../../../../common/ZipCodeComponent/interfaces"
import React,{ useState, useEffect } from "react"
import { IFormattedPractitioner, IPractitioner, IPractitionerAPIResponse, IPractitionerEntry, IProviderDirectorySearchState } from "./interface"
import { getClinicalProviderByFilter } from "../../../../../services/CommonService/AidBoxService"
import { Spin, Select, Tag, Tooltip } from "antd"
const { Option } = Select;
import { useIntl } from "react-intl"
import { Colors } from "../../../../../styles/Colors"
import AlphabetAvatar from "../../../../common/Avatar/AlphabetAvatar"
import Feather from 'react-native-vector-icons/Feather';
import { testID } from "../../../../../testUtils/Utils"
import { TestIdentifiers } from "../../../../../testUtils/TestIdentifiers"
import { clipText } from "../../../../PersonOmniView/MiddleContainer/PatientNotes/components/AddOrUpdateTemplate/helper"
import { styles } from "./ProviderDirectorySearchStyle"



export const ProviderDirectorySearch = (props: IInputElement) => {
  const intl = useIntl();
  const { value, onChange } = props;
  const [componentState, setComponentState] = useState<IProviderDirectorySearchState>({
    zipCode: '',
    searchValue: '',
    practitionerList: [],
    masterPractitionerList: value?.masterPractitionerList || [],
    practionerIds: value?.referralList || [],
    loading: false,
  });


  const getPractitioners = async (params: { searchString: string, practitionerIds?: string[] }) => {
    try {
      const { searchString, practitionerIds } = params;
      if (practitionerIds && practitionerIds.length > 0) {
        const promiseList: any[] = [];
        (practitionerIds || []).forEach(practitionerId => {
          promiseList.push(getClinicalProviderByFilter({ providerReferralId: practitionerId }));
        });
        const promiseResponse = await Promise.all(promiseList);
        promiseResponse.forEach((response) => {
          const formattedPractitioners = filterPractitionerResponse(response?.data);
          setComponentState(prevState => ({
            ...prevState,
            practitionerList: formattedPractitioners,
            loading: false
          }));
        });
        return;
      }
      if (searchString && componentState?.zipCode) {
        const response = await getClinicalProviderByFilter({ zipCode: componentState?.zipCode, name: searchString });
        const formattedPractitioners = filterPractitionerResponse(response?.data);
        setComponentState(prevState => ({
          ...prevState,
          practitionerList: formattedPractitioners,
          masterPractitionerList: [...prevState.masterPractitionerList, ...formattedPractitioners],
          loading: false
        }));
      }
    } catch (error) {
      setComponentState(prevState => ({
        ...prevState,
        practitionerList: [],
        loading: false
      }));
    }
  }

  const filterPractitionerResponse = <T extends IPractitionerAPIResponse | IPractitioner>(response: T) => {
    const formattedPractitioners: IFormattedPractitioner[] = [];
    if ('entry' in response) {
      (response.entry || []).forEach((entry: IPractitionerEntry) => {
        const formattedPractitioner = formatResponse(entry.resource);
        formattedPractitioners.push(formattedPractitioner);
      });
      return formattedPractitioners;
    }
    const formattedPractitioner = formatResponse(response);
    formattedPractitioners.push(formattedPractitioner);
    return formattedPractitioners;
  }

  const formatResponse = (resource: IPractitioner) => {
    const practionerName = (resource?.name || []).map((resourceName) => resourceName.text).join(' ');
    const address: string[] = resource.address.map((address) => {
      return address.line.join(',') + ',' + address.city + ',' + address.state + ',' + address.postalCode;
    });
    const formattedPractitioner: IFormattedPractitioner = {
      id: resource.id,
      name: practionerName,
      referralAddress: address.join(','),
      isDeleted: false,
    }
    return formattedPractitioner;
  }

  const getOptions = () => {
    if (componentState.loading) {
      return (
        <Option key={`search_practitioner_loading`} disabled>
          <Spin size="small" />
        </Option>
      )
    }
    if (componentState?.practitionerList?.length) {
      return (componentState?.practitionerList || []).map((practitioner: IFormattedPractitioner) => {
        return <Option key={practitioner.id} value={practitioner.id}>
          <View key={practitioner.id}>
            <Stack direction={'row'} style={styles.avatarContainer}>
              <Tooltip title={practitioner?.name} placement={'top'}>
                <AlphabetAvatar
                  size={10}
                  style={styles.avatar}
                  disableTop
                  name={clipText(`${practitioner?.name}`, 40)}
                  textStyle={styles.avatarText}
                  userType={'USER'}
                />
              </Tooltip>
              <Stack direction={'column'}>
                <Text>{practitioner.name}</Text>
                <Text style={styles.addressText}>
                  {practitioner.referralAddress}
                </Text>
              </Stack>
            </Stack>
          </View>
        </Option>
      });
    } else {
      return <Option key={'noData'} value={'noData'} disabled>
        {intl.formatMessage({ id: 'providerNoDataSelect' })}
      </Option>
    }
  }

  useEffect(() => {
    if (value && value?.referralList && value?.referralList?.length > 0 && !componentState?.loading) {
      setComponentState(prevState => ({
        ...prevState,
        loading: true
      }));
      getPractitioners({ practitionerIds: value?.referralList, searchString: '' });
    }
  }, []);

  const onSerachProviderDirectory = (searchString: string) => {
    const isApiCallNeeded = searchString && searchString?.length > 2 && componentState?.zipCode;
    setComponentState(prevState => ({
      ...prevState,
      searchValue: searchString,
      ...((isApiCallNeeded) && { loading: true })
    }));
    if (isApiCallNeeded) {
      getPractitioners({ practitionerIds: [], searchString: searchString });
    }
  }

  const renderZipCodeSearch = (): JSX.Element => {
    return (
      <Stack space={2} direction={'row'} style={styles.zipCodeContainer}>
        <ZipCodeAutoComplete
          isRequired={false}
          flex={1}
          name={''}
          onAutoCompleteChange={(selectedZipCode: IZipCodeData) => {
            setComponentState(prevState => ({
              ...prevState,
              zipCode: selectedZipCode?.code || '',
            }));
          }}
          onValueChange={(value: string) => {
            setComponentState(prevState => ({
              ...prevState,
              zipCode: value || '',
            }));
          }}
          showAdd={false}
        />
      </Stack>
    );
  }

  const onProviderDirectoryChange = (selectedPractionerIds: string[]) => {
    setComponentState(prevState => ({
      ...prevState,
      searchValue: '',
      practionerIds: selectedPractionerIds,
      ...(!selectedPractionerIds?.length && { practitionerList: [] }),
    }));
    onChange({ referralList: selectedPractionerIds || [], masterPractitionerList: componentState?.masterPractitionerList || [] });
  }

  const getUsersName = (ids: string[]) => {
    return (componentState.masterPractitionerList || []).filter((item) => ids.includes(item?.id)).map((item) => item?.name);
  }

  const tagRender = (tagProps: any) => {
    const onPreventMouseDown = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
    };
    const label = tagProps.value && getUsersName([tagProps.value])?.[0];
    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={tagProps.closable}
        onClose={tagProps.onClose}
        className='custom-tag'
        {...testID(TestIdentifiers.providerDirectorySearchTag)}
        icon={
          <Feather
            name="user"
            size={12}
            style={styles.tagIcon}
            color={Colors.Custom.Gray400}
          />
        }
      >
        {label}
      </Tag>
    );
  };

  const renderProviderDirectorySearch = () => {
    return (
      <>
        <Text style={styles.labelText}>Select Provider</Text>
        <Select
          disabled={componentState.practionerIds ? false: !componentState.zipCode}
          mode="multiple"
          allowClear
          placeholder={componentState.loading ? "Loading..." : "Search Labels"}
          showSearch={true}
          value={componentState.practionerIds}
          searchValue={componentState.searchValue}
          filterOption={false}
          bordered={true}
          dropdownStyle={{
            borderRadius: 8,
            width: '100%'
          }}
          tagRender={tagRender}
          onChange={onProviderDirectoryChange}
          onSearch={onSerachProviderDirectory}
          onInputKeyDown={(e) => {
            if (e.key === "Enter") {
              e.stopPropagation()
            }
          }}
          loading={componentState.loading}
          notFoundContent={intl.formatMessage({
            id: 'NoDataSelect',
          })}
          {...testID(TestIdentifiers.providerDirectorySearch)}
        >
          {getOptions()}
        </Select>
      </>
    );
  }


  return (
    <>
      <Text style={styles.labelText}>Select Provider</Text>
      <View style={styles.container}>
        <Stack space={2} direction={'column'} >
          {renderZipCodeSearch()}
          {renderProviderDirectorySearch()}
        </Stack>
      </View>
    </>
  )
}