import {AutoComplete, Spin} from 'antd';
import {debounce} from 'lodash';
import {useEffect, useState} from 'react';
import {IAutoComplete} from '../../../../../Interfaces';
import {ModalActionAutoComplete} from '../../../../common/ModalActionCommonComponent/ModalActionAutoComplete';
import {ITemplateCategory} from '../../EmailTemplates/interfaces';

const {Option} = AutoComplete;

const TemplateCategoryAutoComplete = (props: IAutoComplete) => {
  const [templateCategory, setTemplateCategory] = useState<
    ITemplateCategory[] | undefined
  >(props?.dataList);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setTemplateCategory(props?.dataList);
  }, [props?.dataList]);
  const searchTemplateCategoryCode = (searchString: string) => {
    if (props?.dataList) {
      const searchedCatagories: any[] = props?.dataList?.filter((item) =>
        item?.name?.toLowerCase()?.startsWith(searchString.toLowerCase())
      );

      searchedCatagories.push(
        ...props?.dataList?.filter(
          (item) =>
            !item?.name
              ?.toLowerCase()
              ?.startsWith(searchString.toLowerCase()) &&
            item?.name?.toLowerCase()?.includes(searchString.toLowerCase())
        )
      );
      setTemplateCategory(searchedCatagories);
    }
  };

  return (
    <>
      <ModalActionAutoComplete
        disabled={props.isTabDisabled}
        value={props.name}
        allowClear={true}
        isRequired={props.isRequired !== undefined ? props.isRequired : true}
        label={'Category'}
        defaultValue={'All'}
        placeholder="Search Template Category"
        onSelect={(value: any, options: any) => {
          let categoryName = '';
          if (!value) {
            props.onAutoCompleteChange(props?.dataList?.[0]);
          } else {
            const selectedTemplate =
              templateCategory?.find((categoryObj) => {
                return categoryObj.code == value;
              }) || ({} as ITemplateCategory);
            if (value) {
              props.onAutoCompleteChange(selectedTemplate);
              categoryName = selectedTemplate?.name;
            } else {
              props.onAutoCompleteChange(props?.dataList?.[0]);
            }
          }

          props?.onValueChange?.(categoryName);
        }}
        onClear={() => {
          props.onAutoCompleteChange(props?.dataList?.[0]);
          props?.onValueChange?.(props?.dataList?.[0]?.name || '');
        }}
        onSearch={debounce(searchTemplateCategoryCode, 500)}
        onChange={(value: any, options: any) => {
          if (!value) {
            props.onAutoCompleteChange(props?.dataList?.[0]);
          } else {
            const selectedTemplate =
              templateCategory?.find((categoryObj) => {
                return categoryObj.id === options?.key;
              }) || ({} as ITemplateCategory);
            if (options?.key) {
              props.onAutoCompleteChange(selectedTemplate);
            } else {
              props.onAutoCompleteChange(props?.dataList?.[0]);
            }
          }
          searchTemplateCategoryCode(value);
          props?.onValueChange?.(value);
        }}
        onBlur={() => {
          // if (zipCodeData.length == 1) {
          //   props.onAutoCompleteChange(zipCodeData[0]);
          // }
        }}
        customStyle={{
          flex: props.flex ? props.flex : '',
          ...props.customStyles,
        }}
        notFoundContent={isLoading && <Spin size="small" />}
        data={templateCategory}
        classStyle={props.isInvalid ? 'field-error' : ''}
        optionProps={{id: 'id', code: 'code', value: 'name'}}
        valueType={'templateCategory'}
      />
    </>
  );
};

export default TemplateCategoryAutoComplete;
