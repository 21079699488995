import {
  Center,
  HStack,
  Modal,
  Pressable,
  Text,
  View,
  VStack,
} from 'native-base';
import React, {useState} from 'react';
import {Colors} from '../../../styles';
import {isWeb} from '../../../utils/platformCheckUtils';
import {
  IConversationData,
  IConversationMessage,
  IMsgAttachment,
} from '../../RightSideContainer/TeamInbox/Conversations/interfaces';
import { MESSAGE_CUSTOM_CARD_KEY, MESSAGE_CUSTOM_CARD_TYPE } from '../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MessagingCustomCard/CustomCardConst';
import {showMessageOption} from '../ChatUI/MessageBox/MessageBoxUtils';
import CreateNoteSvg from '../Svg/CreateNoteSvg';
import CreateTaskSvg from '../Svg/CreateTaskSvg';
import InfoSvg from '../Svg/InfoSvg';
import MessageDeleteSvg from '../Svg/MessageDeleteSvg';
import MessageEditSvg from '../Svg/MessageEditSvg';
import ReplySvg from '../Svg/ReplySvg';
import ShareSvg from '../Svg/ShareSvg';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { testID, TestIdentifiers } from '../../../testUtils';
import AssignMessageTypeSvg from '../Svg/PersonActionSvgIcons/AssignMessageTypeSvg';
import MarkAsUnreadSvg from '../Svg/PersonActionSvgIcons/MarkAsUnreadSvg';

export enum MessageActionID {
  reply,
  share,
  edit,
  delete,
  createNote,
  createTask,
  info,
  markAsReadUnread,
  assignType,
  confirmedDelete,
  forward,
  replyAll,
  emailReply
}

export interface MessageActionViewModal {
  actionList: MessageActionID[];
  selectedAction: (action: MessageActionID) => void;
  onCloseModel: () => void;
}

interface IMessageActionView {
  actionModel: MessageActionViewModal;
  conversation: IConversationData;
  position: string;
  contactInfo: any;
  attachments: IMsgAttachment[];
  conversationMessage?: IConversationMessage;
  text?: string;
  isSenderLoggedInUser: boolean;
}

const MessageActionView = (props: IMessageActionView) => {
  const [visible, setVisible] = useState(true);
  const messageActionList = props.actionModel.actionList;
  const {conversation, position, contactInfo, attachments, conversationMessage, text, isSenderLoggedInUser} = props;

  const getActionItemView = (actionId: MessageActionID) => {
    return (
      <Pressable
        key={`messageAction_'${actionId}`}
        onPress={() => {
          props.actionModel.selectedAction(actionId);
          setVisible(false);
        }}
        {...testID('MessageActionClick')}
      >
        {showMessageOption(
          actionId,
          conversation,
          position,
          contactInfo,
          attachments,
          isSenderLoggedInUser,
          text,
        ) ? (
          <></>
        ) : (
          <HStack paddingY={4} paddingX={3}>
            <View {...testID('image')}>{getSVG(actionId)}</View>
            <Text
              size={'smSemibold'}
              color={Colors.Custom.Gray700}
              style={{marginLeft: 16}}
              lineHeight={20}
              {...testID('MessageActionLable')}
            >
              {getDisplayText(actionId)}
            </Text>
          </HStack>
        )}
      </Pressable>
    );
  };

  const getSVG = (actionId: MessageActionID) => {
    switch (actionId) {
      case MessageActionID.reply:
        return <ReplySvg height={20} width={20} customStrokeColor={Colors.FoldPixel.GRAY300} />;
      case MessageActionID.edit:
        return <MessageEditSvg height={20} width={20} customStrokeColor={Colors.FoldPixel.GRAY300} />;
      case MessageActionID.delete:
        return <MessageDeleteSvg height={20} width={20} customStrokeColor={Colors.FoldPixel.GRAY300} />;
      case MessageActionID.share:
        return <ShareSvg height={20} width={20} customStrokeColor={Colors.FoldPixel.GRAY300} />;
      case MessageActionID.createNote:
        return <CreateNoteSvg height={20} width={20} customStrokeColor={Colors.FoldPixel.GRAY300} />;
      case MessageActionID.createTask:
        return <CreateTaskSvg height={20} width={20} customStrokeColor={Colors.FoldPixel.GRAY300} />;
      case MessageActionID.info:
        return <InfoSvg customDimension={'20'} customColor={Colors.FoldPixel.GRAY300} />;
      case MessageActionID.markAsReadUnread:
        return (
          <View
            style={{
              alignItems: 'center',
            }}
            {...testID(TestIdentifiers.showPasswordBtn)}
          >
            <MarkAsUnreadSvg height={20} width={20} customStrokeColor={Colors.FoldPixel.GRAY300} />
          </View>
        )
      case MessageActionID.assignType:
        return (
          <View
            style={{
              alignItems: 'center',
              height: 20,
              width: 20,
            }}
            {...testID('image')}
          >
            <AssignMessageTypeSvg
              customStrokeColor={Colors.FoldPixel.GRAY300} height={20} width={20}
            />
          </View>
        )
      default:
        return <View />;
    }
  };

  const getDisplayText = (actionId: MessageActionID) => {
    switch (actionId) {
      case MessageActionID.reply:
        return 'Reply';
        break;
      case MessageActionID.edit:
        return 'Edit';
        break;
      case MessageActionID.delete:
        return 'Delete';
        break;
      case MessageActionID.share:
        return 'Share';
        break;
      case MessageActionID.createNote:
        return 'Create Note';
        break;
      case MessageActionID.createTask:
        return 'Create Task';
        break;
      case MessageActionID.info:
        return 'Info';
        break;
      case MessageActionID.markAsReadUnread:
        return 'Mark as Unread';
        break;  
      case MessageActionID.assignType:
        return 'Assign Type' 
        break;
      default:
        return '';
        break;
    }
  };

  return (
    <Center>
      <Modal
        onClose={() => {
          props.actionModel.onCloseModel();
          setVisible(false);
        }}
        animationPreset="slide"
        isOpen={visible}
        overlayVisible={isWeb() ? true : true}
        style={{
          justifyContent: isWeb() ? 'center' : 'flex-end',
          alignItems: 'center',
        }}
      >
        <Modal.Content
          width={isWeb() ? '200' : 'full'}
          paddingX={3}
          paddingY={3}
          style={{
            borderRadius: 16,
            backgroundColor: '#fff',
            borderWidth: 1,
            borderColor: Colors.Custom.CardBorderColor,
          }}
        >
          <VStack>
            {messageActionList.map((action: MessageActionID) => {
              if (conversationMessage?.contentType === MESSAGE_CUSTOM_CARD_TYPE.CONTACT_CARD || conversationMessage?.contentType === MESSAGE_CUSTOM_CARD_KEY.CUSTOM_CARD) {
                if (action !== MessageActionID.edit && action !== MessageActionID.createNote) {
                  return getActionItemView(action)
                }
              } else {
                return getActionItemView(action)
              }
            })}
          </VStack>
        </Modal.Content>
      </Modal>
    </Center>
  );
};

export default MessageActionView;
