import React from 'react';
import Svg, { Path } from "react-native-svg";

const CreateNewGlobalActionSvg = (props: {
  customColor?: string;
  width?: string;
  height?: string;
}) => {
  const {customColor} = props;
  const strokeColor = customColor ? customColor : '#8C210F';
  return (
    <Svg
      width={props?.width || 16}
      height={props?.height || 16}
      fill="none"
      viewBox="0 0 16 16"
    >
      <Path
        fill={strokeColor}
        d="M12.5 8.50003C12.7761 8.50003 13 8.27618 13 8.00003C13 7.72389 12.7761 7.50003 12.5 7.50003V8.50003ZM4.5 7.50003C4.22386 7.50003 4 7.72389 4 8.00003C4 8.27618 4.22386 8.50003 4.5 8.50003V7.50003ZM9 4C9 3.72386 8.77614 3.5 8.5 3.5C8.22386 3.5 8 3.72386 8 4L9 4ZM8 12C8 12.2761 8.22386 12.5 8.5 12.5C8.77614 12.5 9 12.2761 9 12H8ZM12.5 7.50003H8.5V8.50003H12.5V7.50003ZM8.5 7.50003H4.5V8.50003H8.5V7.50003ZM8 4L8 8.00003L9 8.00003L9 4L8 4ZM8 8.00003V12H9V8.00003H8Z"
      ></Path>
    </Svg>
  );
};

export default CreateNewGlobalActionSvg;
