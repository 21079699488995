import {StyleSheet} from 'react-native';
import {Colors} from '../../../../../styles';

export const styles = StyleSheet.create({
  footerStyle: {
    paddingLeft: 9,
    backgroundColor: 'white',
    marginTop: 4,
    display: 'flex',
    alignItems: 'center',
  },
  modalContent: {
    margin: 'auto',
    marginLeft:20,
    marginRight:20,
    minWidth: 900,
    backgroundColor: Colors.Custom.BackgroundColor,
  },
  modalStyle: {
    margin: 'auto',
  },

  displayDataText: {},
  labelString: {
    fontWeight: 'bold',
  },
  displayViewStyle: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    flex: 1,
    padding: 5,
  },
  modalBodyStyle: {
    width: '100%',
    backgroundColor: Colors.Custom.BackgroundColor,
    marginTop: 5,
  },
  titleView: {
    marginTop: 12,
    marginLeft: 12,
  },
});
