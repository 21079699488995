import React from "react";
import { Svg, Path } from "react-native-svg";
import { ICommonSvgProps } from "./interfaces";

function CrossIcon(props: ICommonSvgProps) {
    return (
        <Svg
            width={props.size || "28"}
            height={props.size || "28"}
            fill="none"
            viewBox="0 0 28 28"
        >
            <Path
                fill={props.customStrokeColor || "#6F7A90"}
                d="M19.354 9.354a.5.5 0 00-.708-.708l.708.708zM8.646 18.646a.5.5 0 00.708.708l-.708-.708zm.708-10a.5.5 0 10-.708.708l.708-.708zm9.292 10.707a.5.5 0 10.707-.707l-.707.707zm0-10.707l-5 5 .708.708 5-5-.708-.708zm-5 5l-5 5 .708.708 5-5-.707-.707zm-5-4.292l5 5 .708-.707-5-5-.708.707zm5 5l5 5 .707-.708-5-5-.707.708z"
            ></Path>
        </Svg>
    );
}

export default React.memo(CrossIcon);
