import { useEffect, useState } from 'react';
import { IInputElement } from '../../RightSideContainer/Workflow/FlowComponent/StateNodes/FlowNodeInterface';
import { FormControl, View } from 'native-base';
import { ModalActionAntSelect } from '../ModalActionCommonComponent/ModalActionAntSelect';
import { getCodes } from '../../../services/CommonService/AidBoxService';
import { SEARCH_TYPES } from '../../../constants/FhirConstant';
import { Spin } from 'antd';

interface IComponentState {
  statusList: {
    value: Array<string>;
    isValid: boolean;
  };
  analyteStatusMasterList: Array<{
    code: string;
    display: string;
  }>;
  isLoading: boolean;
}

export function AnalyteStatusSelect(props: IInputElement) {
  const [ componentState, setComponentState ] = useState<IComponentState>({
    statusList: {
      value: props.value?.statusList || [],
      isValid: isStatusListValid(props.value?.statusList || []),
    },
    analyteStatusMasterList: [],
    isLoading: false,
  });

  function isStatusListValid(statusList: Array<string>) {
    if (!statusList?.length) {
      return false;
    }
    return true;
  }

  function fetchAnalyteStatusList() {
    setComponentState(prev => ({ ...prev, isLoading: true}));
    getCodes({ searchType: SEARCH_TYPES.LAB_RESULT_STATUS})
      .then((response) => {
        const codes = response?.data?.expansion?.contains || [];
        setComponentState(prev => ({ ...prev, analyteStatusMasterList: codes, isLoading: false }));
      })
      .catch(error => {

        setComponentState(prev => ({ ...prev, isLoading: false}));
      });
  }

  useEffect(() => {
    fetchAnalyteStatusList();
  }, []);

  return (
    <View>
        <FormControl
          isInvalid={props.isShowError && !componentState.statusList.isValid}
          flex={1}
        >
          <ModalActionAntSelect
            mode={props.elementProperty?.isMultiple ? 'multiple' : undefined}
            filterOption={(input: string, option: any) => {
              return (option?.children || '').toLowerCase().includes(input.toLowerCase());
            }}
            disabled={false}
            style={{width:'100%'}}
            allowClear={true}
            showSearch={true}
            leftMargin={'0'}
            value={componentState.statusList?.value}
            notFoundContent={componentState.isLoading && <Spin size="small" />}
            isRequired={true}
            isInvalid={props.isShowError && !componentState.statusList.isValid}
            errors={props.isShowError && !componentState.statusList.isValid}
            errorText={'Please select analyte result status'}
            placeholder={props.elementProperty?.placeholder}
            onChange={(value: any[], data: any) => {
              const statusList = { value: value, isValid: isStatusListValid(value)};
              let displayValue = '';
              if (statusList.isValid) {
                displayValue = value?.map((code) => {
                  return componentState.analyteStatusMasterList.find(obj => obj.code === code)?.display || '';
                }).join(', ');
              }
              setComponentState(prev => ({ ...prev, statusList: statusList}));
              props.onChange(statusList.isValid ? { statusList: statusList?.value } : undefined, displayValue);
            }}
            data={componentState.analyteStatusMasterList}
            optionProps={{key: 'code', value: 'code', label: 'display'}}
            extraStyle={{ flex: 1 }}
            customStyle={{ flex: 1 }}
          />
        </FormControl>
      </View>
  );
}
