import {Drawer, notification} from 'antd';
import {Text, useToast, View, VStack} from 'native-base';
import {useEffect, useState} from 'react';
import {BUTTON_TYPE} from '../../../constants';
import {COMMON_ACTION_CODES} from '../../../constants/ActionConst';
import {Colors} from '../../../styles';
import {showToast, ToastType} from '../../../utils/commonViewUtils';
import {CityAutoComplete} from '../City';
import {ModalActionInput} from '../ModalActionCommonComponent/ModalActionInput';
import {ModalActionTitle} from '../ModalActionTitle/ModalActionTitle';
import {StateAutoComplete} from '../State';
import {addZipCode} from './AddZipCodeService';

export const AddZipCode = (props: {
  onAddZipCodeActionPerformed: (actionCode: string, data?: any) => void;
  zipCode: string;
}) => {
  const [stateData, setStateData] = useState({
    cityName: '',
    cityId: '',
    stateName: '',
    zipCode: '',
    stateId: '',
    cityValue: '',
    showErrorMsg: false,
  });
  const toast = useToast();
  useEffect(() => {
    setStateData((prev) => {
      return {
        ...prev,
        zipCode: props?.zipCode,
      };
    });
  }, []);
  const handleAddZipCode = async () => {
    if(stateData?.cityId && stateData?.stateId){
      const params = {
        bodyParams: {
          code: stateData?.zipCode,
          cityId: stateData?.cityId,
          stateId: stateData?.stateId,
        },
      };
      const response = await addZipCode(params).catch(() => {
        setStateData((prev) => {
          return {
            ...prev,
            loading: false,
            showErrorMsg: false,
          };
        });
      });
      if (response?.data?.id) {
        const responseObj = {
          zipCodeId: response?.data?.id,
          stateId: response?.data?.stateId,
          stateName: stateData?.stateName,
          cityId: response?.data?.cityId,
          cityName: stateData?.cityName,
          zipCode: stateData?.zipCode,
        };
        props?.onAddZipCodeActionPerformed(
          COMMON_ACTION_CODES.SUCCESS,
          responseObj
        );
      } else {
        showToast(
          toast,
          'Something went wrong while inviting patient',
          ToastType.error
        );
        setStateData((prev) => {
          return {
            ...prev,
            showErrorMsg: false
          }
        })
      }
    }
    else {
      const message = 'Select city to add zipcode';
      notification.error({
        message,
      });
      setStateData((prev) => {
        return {
          ...prev,
          showErrorMsg: true
        }
      })
    }

  };
  return (
    <Drawer
      visible={true}
      width={550}
      onClose={() => {
        props?.onAddZipCodeActionPerformed(COMMON_ACTION_CODES.CANCEL);
      }}
      closable={false}
      title={
        <ModalActionTitle
        title={'addZipCode'}
        titleColor={''}
        buttonList={[
          {
            show: true,
            id: 1,
            btnText: 'close',
            textColor: Colors.Custom.mainSecondaryBrown,
            variant: BUTTON_TYPE.SECONDARY,
            isTransBtn: false,
            onClick: () => {
              props?.onAddZipCodeActionPerformed(COMMON_ACTION_CODES.CANCEL);
            },
          },
          {
            show: true,
            id: 2,
            btnText: 'Add',
            textColor: Colors.Custom.mainPrimaryPurple,
            variant: BUTTON_TYPE.PRIMARY,
            isTransBtn: false,
            onClick: () => {
              handleAddZipCode();
            },
          },
        ]}
      />
      }
    >

      <VStack space={2}>
        <ModalActionInput
          fieldIs={'input'}
          label={'Zipcode'}
          // leftMargin={'0'}
          isRequired={false}
          // errors={addTaskState.showErrors}
          // errorText={addTaskState.title}
          value={stateData?.zipCode}
          onChangeText={(text: any) => {
            setStateData((prev) => {
              return {
                ...prev,
                zipCode: text,
              };
            });
          }}
          extraStyle={{flex: 1}}
        />
        <CityAutoComplete
          flex={1}
          name={stateData?.cityName}
          noDataLabel={stateData?.cityValue ? 'notFound' : 'NoData'}
          onAutoCompleteChange={(selectedId, cityName, selectedCity) => {
            setStateData((prev) => {
              return {
                ...prev,
                cityId: selectedId,
                cityName: cityName || '',
                stateName: selectedCity?.cities_state?.name,
                stateId:
                  selectedCity?.cities_state?.id || selectedCity?.stateId,
              };
            });
          }}
          onValueChange={(value) => {
            setStateData((prev) => {
              return {
                ...prev,
                cityValue: value
              }
            })
          }}
        />
        {stateData?.cityValue && !stateData?.stateId && stateData?.showErrorMsg && <View>
          <Text ml={2} fontSize={10} color={'error.500'}>
            {'Select city'}
          </Text>
        </View>}
        <StateAutoComplete
          isTabDisabled = {true}
          name={stateData?.stateName}
          onAutoCompleteChange={(selectedId, abbr) => {
            setStateData((prev) => {
              return {
                ...prev,
                stateId: selectedId,
                abbr: abbr || '',
              };
            });
          }}
          flex={1}
        />
      </VStack>
    </Drawer>
  );
};
