import React from 'react';
import Stack from '../LayoutComponents/Stack';
import {Divider, Text} from 'native-base';
import {Colors} from '../../../styles';
import {useIntl} from 'react-intl';

const UnreadMsgV2 = () => {
  const intl = useIntl();
  return (
    <Stack
      direction="row"
      style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}
    >
      <Divider
        flex={0.45}
        backgroundColor={Colors.Custom.GRAY150}
      ></Divider>
      <Text color={Colors.Custom.messageTimeColor} paddingX={4} 
        style={{
          fontWeight: '400',
          fontSize: 12
        }}>
        {intl.formatMessage({id: 'lastRead'})}
      </Text>
      <Divider
        flex={0.45}
        backgroundColor={Colors.Custom.GRAY150}
      ></Divider>
    </Stack>
  );
};

export default React.memo(UnreadMsgV2);
