import {View, Text, Pressable} from 'react-native';
import React, { useContext } from 'react';
import {ISingleCallTrailProps} from './interfaces';
import {styles} from './styles';
import {
  getIconByWidgetName,
  getTextByWidgetType,
  getTimeStringForCallTrail,
  openPhoneTree,
} from './CallTrailUtils';
import {isWeb} from '../../../utils/platformCheckUtils';
import {Colors} from '../../../styles';
import Feather from 'react-native-vector-icons/Feather';
import {useIntl} from 'react-intl';
import SingleCallTrailIcon from './SingleCallTrailIcon';
import { CommonDataContext } from '../../../context/CommonDataContext';

const SingleCallTrail = (props: ISingleCallTrailProps) => {
  const intl = useIntl();
  const textId = getTextByWidgetType(props.singleCallTrail);
  const text = intl.formatMessage({id: textId});
  const commonData = useContext(CommonDataContext);
  const isSideCarContext = commonData.sidecarContext?.isSidecar;
  const timeString = getTimeStringForCallTrail({
    timestamp1: props.firstNodeTimestamp,
    timestamp2: props.singleCallTrail.timestamp,
  });
  const Icon = getIconByWidgetName(props.singleCallTrail.widgetName);
  return (
    <View style={styles.mainContainer}>
      <SingleCallTrailIcon Icon={Icon} hideBottomLine={props.isLastTrail} />

      <View style={styles.textContainer}>
        <View style={styles.textSubContainer}>
          <Text style={styles.text}>{text}</Text>
          {/* <Text style={styles.time}>{timeString}</Text> */}
        </View>
        <View>
          {props.singleCallTrail.widgetName === 'phoneTree' &&
          props.workflowMasterId ? (
            isWeb() ? (
              <Pressable
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onPress={() => {
                  openPhoneTree(props.workflowMasterId, isSideCarContext);
                }}
              >
                <Text
                  style={{
                    color: Colors.Custom.mainPrimaryPurple,
                    fontSize: 14,
                    fontWeight: '600',
                    marginRight: 2,
                  }}
                >
                  {intl.formatMessage({id: 'openPhoneTree'})}
                </Text>
                <Feather name="external-link" size={15} color={'#825AC7'} />
              </Pressable>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </View>
      </View>
    </View>
  );
};

export default SingleCallTrail;
