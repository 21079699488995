import { Drawer } from "antd";
import { HStack, Spacer, VStack } from "native-base";
import {useIntl} from "react-intl";
import { DisplayText } from "../../../../common/DisplayText/DisplayText";
import ModalActionBtn from "../../../../common/ModalActionBtn/ModalActionBtn";
import {FoldButton} from "../../../../CommonComponents/FoldButton/FoldButton";

// eslint-disable-next-line @typescript-eslint/ban-types
const AlertPopUp = (props: {
  buttonList: Array<{
    type: string,
    text: string,
    // eslint-disable-next-line @typescript-eslint/ban-types
    onClick: Function,
    // eslint-disable-next-line @typescript-eslint/ban-types
  }>, OnCloseButtonPress: Function, placement?: 'left' | 'top' | 'right' | 'bottom', closable?: boolean, isVisible: boolean, messageTextLocalId: string, headerTextLocalId: string
}) => {
  const intl = useIntl()
  return <Drawer
    title={<DisplayText textType={'Heading'} textLocalId={props.headerTextLocalId} />}
    destroyOnClose
    placement={props.placement || "right"}
    onClose={() => {
      props.OnCloseButtonPress();
    }}
    visible={props.isVisible}
    closable={props.closable || false}
    width={450}
  >
    <VStack space={6}>
      <HStack>
        <DisplayText textLocalId={props.messageTextLocalId} />
      </HStack>
      <HStack space={2}>
        <Spacer />
        {
          props.buttonList.map((buttonInfo) => {
            return <FoldButton
              nativeProps={{
                variant: buttonInfo.type,
                onPress: () => {
                  buttonInfo.onClick();
                },
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: buttonInfo.text,
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
          })}
      </HStack>
    </VStack>
  </Drawer>
}

export default AlertPopUp
