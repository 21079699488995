import React from 'react';
import {Colors} from '../../../../styles';
import Svg, {Path} from 'react-native-svg';

const ChatSquareAvatar = (props: any) => {
  const isInternalChat = props?.isInternalChat;
  const bgColor = isInternalChat ? '#FEEEE7' : Colors.Custom.PrimaryColor100;
  const strokeColor = isInternalChat
    ? Colors.Custom.badgeColorOrange
    : Colors.Custom.Primary300;
  const borderColor = isInternalChat
    ? '#FBCEB7'
    : Colors.Custom.PrimaryColor200;
  return (
    <Svg
      width={props?.width ? props?.width : '32'}
      height={props?.height ? props?.height : '33'}
      fill="none"
      viewBox="0 0 32 33"
    >
      <Path
        fill={bgColor}
        d="M.25 16.5c0-3.778 0-6.587.29-8.745.29-2.15.863-3.618 1.98-4.735 1.117-1.117 2.584-1.69 4.735-1.98C9.413.75 12.222.75 16 .75c3.778 0 6.587 0 8.745.29 2.15.29 3.618.863 4.735 1.98 1.117 1.117 1.69 2.584 1.98 4.735.29 2.158.29 4.967.29 8.745 0 3.778 0 6.587-.29 8.745-.29 2.15-.863 3.618-1.98 4.735-1.117 1.117-2.584 1.69-4.735 1.98-2.158.29-4.967.29-8.745.29-3.778 0-6.587 0-8.745-.29-2.15-.29-3.618-.863-4.735-1.98C1.403 28.863.83 27.396.54 25.245.25 23.087.25 20.278.25 16.5z"
      ></Path>
      <Path
        stroke={borderColor}
        strokeWidth="0.5"
        d="M.25 16.5c0-3.778 0-6.587.29-8.745.29-2.15.863-3.618 1.98-4.735 1.117-1.117 2.584-1.69 4.735-1.98C9.413.75 12.222.75 16 .75c3.778 0 6.587 0 8.745.29 2.15.29 3.618.863 4.735 1.98 1.117 1.117 1.69 2.584 1.98 4.735.29 2.158.29 4.967.29 8.745 0 3.778 0 6.587-.29 8.745-.29 2.15-.863 3.618-1.98 4.735-1.117 1.117-2.584 1.69-4.735 1.98-2.158.29-4.967.29-8.745.29-3.778 0-6.587 0-8.745-.29-2.15-.29-3.618-.863-4.735-1.98C1.403 28.863.83 27.396.54 25.245.25 23.087.25 20.278.25 16.5z"
      ></Path>
      <Path
        stroke={strokeColor}
        d="M16 24.83a8.333 8.333 0 10-7.464-4.622c.148.297.197.636.112.957L8.15 23.02a1.083 1.083 0 001.327 1.326l1.855-.496c.32-.086.66-.037.957.111a8.3 8.3 0 003.71.87z"
      ></Path>
    </Svg>
  );
};

export default React.memo(ChatSquareAvatar);