export const setWorkFlowConfigToElements = (
  nodes: any[],
  edges: any[],
  workflowConfig: any[]
) => {
  workflowConfig.forEach((config: any) => {
    if (config.nodeId && config.userInputFieldList) {
      const matchedData = nodes.filter(
        (element) => element.id === config.nodeId
      );
      if (matchedData && matchedData.length > 0) {
        const currentElement = matchedData[0];
        if (
          currentElement.data &&
          currentElement.data.metaData &&
          currentElement.data.metaData.userInputFieldList &&
          currentElement.data.metaData.userInputFieldList.length > 0
        ) {
          currentElement.data.metaData.userInputFieldList.forEach(
            (inputField: any) => {
              if (inputField.key && config.userInputFieldList[inputField.key]) {
                inputField.value = config.userInputFieldList[inputField.key];
              }
            }
          );
        }
      }
    }
  });
};
