import React, { useContext } from 'react'
import { CommonDataContext, ICommonData } from '../../context/CommonDataContext';
import { IEhrCapability } from '../../Interfaces';
import {getBooleanFeatureFlag} from '../../utils/commonUtils';
import FeatureFlags from '../../constants/FeatureFlags.enums';

const useEHRCapabilities = (args: {
  locationId?: string;
  locationGroupId?: string;
  locationGroupIds?: string[];
  commonDataObject?: ICommonData
}) => {
  // Constants
  const {locationId, locationGroupId, locationGroupIds, commonDataObject} = args;
  const commonData = commonDataObject ?? useContext(CommonDataContext);
  const locationGroupList =
    commonData.loggedInUserLocationDetails?.locationGroupList;
  const isMultiTenancyEnabled = getBooleanFeatureFlag(
    commonData.userSettings,
    FeatureFlags.IS_MULTI_TENANCY_ENABLED
  );
  let capabilities: IEhrCapability[] = [];
  if (!isMultiTenancyEnabled && commonData?.ehrCapabilityLocationMap) {
    const defaultCapability = Array.from(commonData.ehrCapabilityLocationMap.keys())[0];
    capabilities = commonData.ehrCapabilityLocationMap.get(defaultCapability) || [];
    return capabilities;
  }

  if(locationGroupId) {
    capabilities = commonData.ehrCapabilityLocationMap?.get(locationGroupId || '') || [];
  }
  else if(locationGroupIds) {
    locationGroupIds?.forEach(locationGroupId => {
      const locationGroupCapabilities = commonData.ehrCapabilityLocationMap?.get(locationGroupId || '') || [];
      capabilities.push(...locationGroupCapabilities);
    });
  }

  if (locationId) {
    const locationGroup = locationGroupList?.find((locationGroup) => locationGroup.accountLocationUuid === locationId);
    if (locationGroup?.locationGroupId) {
      return commonData.ehrCapabilityLocationMap?.get(locationGroup?.locationGroupId || '') || capabilities;
    }
  }

  return capabilities;
}

export default useEHRCapabilities;
