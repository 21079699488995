import {Avatar, View} from 'native-base';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {getColorFromOnlineStatus, getUserData, isUserOrContactOnline} from '../../../utils/commonUtils';
import {isWeb} from '../../../utils/platformCheckUtils';
import AlphabetAvatar from '../Avatar/AlphabetAvatar';
import {getUserAvatarImageSrc} from './DisplayAvatarUtils';
import {styles} from './DisplayCardStyles';
import {ICardAvatarProps} from './interface';
import {IPresenceUpdateMsgData, IWsMsgData} from '../../../Interfaces/WebSocketEventInterfaces';
import {SUPPORTED_EVENT_CODE} from '../../../constants/WebSocketConst';
import {EventBus} from '../../../utils/EventBus';
import {getUserOnlineDataFromWebsocketUtils} from '../../../utils/WebSocketUtils';
import { TestIdentifiers, testID } from '../../../testUtils';
import { CommonDataContext } from '../../../context/CommonDataContext';

export const DisplayCardAvatar = React.memo((props: ICardAvatarProps) => {
  const {userData, avatarStyle, groupType, isLetterAvatarShow, hideOnlineIndicator, hideFullNameTooltip} = props;
  const batchSize = avatarStyle?.onlineBatchSize || 12;
  // const currentLoginUserData = getUserData();
  // const userOnlineData = getUserOnlineDataFromWebsocketUtils();
  // let isOnline = false;
  const commonData = useContext(CommonDataContext);
  const isSideCarContext = commonData.sidecarContext?.isSidecar;
  const cardAvatarStyle = isSideCarContext ? styles.sideCarAvatarStyle : styles.avatarStyle;
  if (props?.userData?.userId && !hideOnlineIndicator) {
    // isOnline = isUserOrContactOnline(props?.userData?.userId, props?.userData?.userType, userOnlineData, currentLoginUserData);
  }
  const alphabetAvatarStyles = React.useMemo(
    () => [
      cardAvatarStyle,
      props?.avatarStyle,
      {borderRadius: Number(avatarStyle?.avatarSize || '12')},
    ],
    [cardAvatarStyle, props?.avatarStyle, avatarStyle?.avatarSize]
  );

  const [isUserOnline, setIsUserOnline] = useState(false);
  const getDisplayCardAvatarElem = (): JSX.Element => {
    if (props?.customAvatarElem) {
      return (
        <View style={{width: 40, height: 40, marginRight: 5}}>
          {props.customAvatarElem}
          {isUserOnline && (
            <Avatar.Badge
              style={{
                width: 12,
                height: 12,
              }}
              {...testID(TestIdentifiers.badge)}
              bg={getColorFromOnlineStatus('ONLINE')}
            />
          )}
        </View>
      )
    } else {
      const avatarImgSrc = getUserAvatarImageSrc(
        userData as any,
        groupType as any
      );
      if (userData?.imgSrc) {
        return (
          <View>
            <Avatar
              style={[
                cardAvatarStyle,
                {
                  height: props?.avatarStyle?.height || undefined,
                  width: props?.avatarStyle?.width || undefined,
                  borderWidth: props?.avatarStyle?.borderWidth || undefined,
                  borderRadius: Number(avatarStyle?.avatarSize || '12')
                },
              ]}
              size={avatarStyle?.avatarSize || 12}
              source={{
                // uri: require('../../../assets/images/Avatar/PatientGroup_Female.png'),
                uri: userData?.imgSrc,
              }}
              {...testID(TestIdentifiers.userImage)}
            >
              {isUserOnline && (
                <Avatar.Badge
                  style={{
                    width: batchSize,
                    height: batchSize,
                  }}
                  bg={getColorFromOnlineStatus('ONLINE')}
                  {...testID(TestIdentifiers.badge)}
                />
              )}
            </Avatar>
          </View>
        );
      } else {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        // const imgData = require(avatarImgSrc);
        return (
          <>
            {isLetterAvatarShow ? (
              <View>
                <AlphabetAvatar
                  style={alphabetAvatarStyles}
                  size={avatarStyle?.avatarSize || '12'}
                  name={userData?.userName}
                  textStyle={props?.avatarStyle?.textStyle}
                  userType={userData?.userType}
                  isUserDeleted={userData?.isUserDeleted}
                  disableTop={props?.avatarStyle?.disableTop}
                  hideFullNameTooltip={hideFullNameTooltip}
                />
                {isUserOnline && (
                  <Avatar.Badge
                    style={{
                      width: batchSize,
                      height: batchSize,
                    }}
                    bg={getColorFromOnlineStatus('ONLINE')}
                    {...testID(TestIdentifiers.badge)}
                  />
                )}
              </View>
            ) : (
              <Avatar
                style={[
                  cardAvatarStyle,
                  {
                    height: props?.avatarStyle?.height || undefined,
                    width: props?.avatarStyle?.width || undefined,
                    borderWidth: props?.avatarStyle?.borderWidth || undefined,
                    borderRadius: Number(avatarStyle?.avatarSize || '12')
                  },
                ]}
                size={avatarStyle?.avatarSize || '12'}
                key={avatarImgSrc}
                source={
                  isWeb()
                    ? {
                        // uri: require('../../../assets/images/Avatar/PatientGroup_Female.png'),
                        uri: avatarImgSrc,
                      }
                    : avatarImgSrc
                }
                {...testID(TestIdentifiers.userImage)}
              >
                {isUserOnline && (
                  <Avatar.Badge
                    style={{
                      width: batchSize,
                      height: batchSize,
                    }}
                    bg={getColorFromOnlineStatus('ONLINE')}
                    {...testID(TestIdentifiers.badge)}
                  />
                )}
              </Avatar>
            )}
          </>
        );
      }
    }
  };

  // const onPresenceUpdate = (data: IPresenceUpdateMsgData) => {
  //   if (props?.userData?.userId && !hideOnlineIndicator) {
  //     const isOnline = isUserOrContactOnline(props?.userData?.userId, props?.userData?.userType, data, currentLoginUserData);
  //     setIsUserOnline(isOnline);
  //   }
  // };

  // const onPresenceUpdateListenerFn = useCallback(
  //   (data: IWsMsgData) => {
  //     if (props?.userData?.userId) {
  //       onPresenceUpdate(data as IPresenceUpdateMsgData);
  //     }
  //   },
  //   [props?.userData?.userId, isUserOnline]
  // );

  // useEffect(() => {
  //   const eventBus = EventBus.getEventBusInstance();
  //   eventBus.addEventListener(
  //     SUPPORTED_EVENT_CODE.PRESENCE_UPDATE,
  //     onPresenceUpdateListenerFn
  //   );
  //   return () => {
  //     eventBus.removeEventListener(onPresenceUpdateListenerFn);
  //   };
  // }, []);

  return (
    <View>{getDisplayCardAvatarElem()}</View>
  );
});


