export const CINICAL_SECTIONS_FILTER_TAB_CODES = {
  RELEVANT: 'RELEVANT',
  ALL: 'ALL',
} as const;

export const CLINICAL_SECTION_VIEW_TYPES = {
  LIST: 'LIST',
  GRAPH: 'GRAPH',
};

export const CINICAL_SECTIONS_FILTER_TABS = [
  {
    code: CINICAL_SECTIONS_FILTER_TAB_CODES.RELEVANT,
    label: 'relevant',
  },
  {
    code: CINICAL_SECTIONS_FILTER_TAB_CODES.ALL,
    label: 'all',
  },
];

export const HOME_MONITORING_FILTER_TABS = [ 
  {
    code: CINICAL_SECTIONS_FILTER_TAB_CODES.ALL,
    label: 'all',
  },
  {
    code: CINICAL_SECTIONS_FILTER_TAB_CODES.RELEVANT,
    label: 'pinned',
  },
];

export const CINICAL_SECTIONS_VIEW_TABS = [
  {
    code: CLINICAL_SECTION_VIEW_TYPES.GRAPH,
    icon: 'bar-chart-2'
  },
  {
    code: CLINICAL_SECTION_VIEW_TYPES.LIST,
    icon: 'list'
  },
];