import React from 'react';
import BottomSheetModal from '../BottomSheetModal/BottomSheetModal';
import { View, Text } from 'react-native';
import { Colors } from '../../../styles/Colors';
import { FoldButton } from '../../CommonComponents/FoldButton/FoldButton';
import { useIntl } from 'react-intl';
import { BUTTON_TYPE } from '../../../constants/StringConst';

const CustomBottomSheetAlertModel = (props: {
  refRBSheet: any;
  alertIcon: JSX.Element;
  alertMessage: string;
  buttonText?: string;
  onCloseModal?: (refRBSheetAlreadyClosed?: boolean) => void;
}) => {
  const intl = useIntl();

  const onCloseModel = () => {
    props?.onCloseModal?.();
  };

  const ModelContent = (
    <View style={{ paddingHorizontal: 32 }}>
      <View style={{ alignItems: 'center', marginTop: 32, marginBottom: 24 }}>
        {props.alertIcon}
      </View>
      <Text
        style={{
          marginBottom: 24,
          color: Colors.FoldPixel.GRAY300,
        }}
      >
        {props.alertMessage}
      </Text>
      <FoldButton
        nativeProps={{
          borderRadius: 8,
          background: Colors.primary['700'],
          onPress: () => {
            onCloseModel();
          },
          colorScheme: BUTTON_TYPE.PRIMARY,
        }}
        customProps={{
          btnText:
            props.buttonText ||
            intl.formatMessage({
              id: 'okay',
            }),
          withRightBorder: false,
        }}
      />
    </View>
  );

  const bottomSheetExtraProps = {
    closeOnDragDown: false,
    closeOnPressMask: false,
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
  };

  return (
    <BottomSheetModal
      bottomSheetExtraProps={bottomSheetExtraProps}
      onCloseModal={onCloseModel}
      refRBSheet={props.refRBSheet}
      content={ModelContent}
    />
  );
};

export default CustomBottomSheetAlertModel;
