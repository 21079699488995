import React from 'react';
import {IReplyMessageObject} from '../../../../../common/ChatUI/MessageBox/interfaces';
import {
  convertMsgObjectToReplyMsgObject,
  isIncomingOrOutgoingNonActivityMessage,
  isSeen,
} from '../MessagingUtils';
import {getDisplayDateFormatted} from '../../../../../../utils/DateUtils';
import {EmailMessageBox} from '../../../../../common/ChatUI/EmailMessageBox';
import {MessageActionID} from '../../../../../common/MessageAction/MessageActionView';
import {isWeb} from '../../../../../../utils/platformCheckUtils';
import MessageBox from '../../../../../common/ChatUI/MessageBox/MessageBox';
import {
  IConversationData,
  IInboxChannelEmail,
  IMessageBoxData,
  ISearchMessageData,
  onAddCommunicationType,
} from '../../interfaces';
import {ICopiedMessageItem} from '../interface';
import {isAccountConfigEnabled} from '../../../../../../utils/configUtils';
import {CONFIG_CODES} from '../../../../../../constants/AccountConfigConst';
import {isChannelEmail} from '../../../../../../utils/commonUtils';

export interface IRenderMsgItemProps {
  item: any;
  index: number;
  onMessageAction?: (
    actionId: MessageActionID,
    data?: any,
    replyObject?: IReplyMessageObject
  ) => void;
  channelData: IInboxChannelEmail;
  prevMessage: IMessageBoxData | undefined;
  lastNonInternalMessageInDisplayList?: IMessageBoxData;
  navigation?: any;
  onViewTaskAction?: ((messageUuid: string) => void) | undefined;
  clickMessageUuid?: string | undefined;
  clickEventId?: string | undefined;
  resetClickedMessageUuid?: (() => void) | undefined;
  onAddCommunicationType?: onAddCommunicationType | undefined;
  isCopyModeStateOnCode?: string | undefined;
  selectedConversation: IConversationData;
  isReadOnlyView?: boolean | undefined;
  selectedInboxTypeCode?: string | undefined;
  scrollToMessage: (msg: IMessageBoxData) => void;
  groupMemberDataLoading?: boolean | undefined;
  redirectToMention: boolean | undefined;
  onRedirectToMentionChange: (() => void) | undefined;
  searchMessage: ISearchMessageData | undefined;
  handleCopyClick: (selectedItem: ICopiedMessageItem) => void;
  onActionPerformed?:
    | ((actionCode?: string | undefined, data?: any) => void)
    | undefined;
  messageAction?: any;
  showModal?: any;
  onReplyCallback: ((data: IReplyMessageObject) => void) | undefined;
  lastSeenData: any[];
  groupMemberId: any[];
  lastEmailMessageUuid?: string;
}

const RenderMsgItem = (props: IRenderMsgItemProps) => {
  const {item, channelData, prevMessage, lastNonInternalMessageInDisplayList} =
    props;
  const tempObject: IReplyMessageObject = React.useMemo(() => {
    return convertMsgObjectToReplyMsgObject(item);
  }, []);

  const prevDate = prevMessage
    ? getDisplayDateFormatted(prevMessage?.dateStr)
    : undefined;
  const currentDate = getDisplayDateFormatted(item.dateStr);

  const isDisplaySectionMsgHeader = currentDate != prevDate;
  const isSinglePatientReadReceiptEnable = isAccountConfigEnabled(
    CONFIG_CODES.SINGLE_PATIENT_READ_RECEIPTS
  );
  const isEmailInbox = isChannelEmail(
    props.selectedConversation?.conversationInbox?.channelType || ''
  );

  const isSeenByUsers = React.useMemo(() => {
    return isSeen(
      props.lastSeenData,
      props.groupMemberId,
      item.date,
      item.displayContent,
      true,
      props.selectedConversation,
      isSinglePatientReadReceiptEnable,
      item?.private
    );
  }, [
    props.lastSeenData,
    props.groupMemberId,
    props.selectedConversation,
    item.date,
    item.displayContent,
    item?.private,
  ]);

  if (
    isEmailInbox &&
    isIncomingOrOutgoingNonActivityMessage(item.messageType)
  ) {
    return (
      <EmailMessageBox
        messageData={item}
        channelData={channelData}
        onActionPerformed={props.onMessageAction!}
        isDisplayFullContent={
          isWeb() && lastNonInternalMessageInDisplayList?.id === item.id
        }
        navigation={props.navigation ? props.navigation : null}
        onViewTaskAction={props.onViewTaskAction}
        lastEmailMessageUuid={props?.lastEmailMessageUuid}
      />
    );
  }
  return (
    <MessageBox
      clickMessageUuid={props.clickMessageUuid}
      clickEventId={props.clickEventId}
      resetClickedMessageUuid={props.resetClickedMessageUuid}
      onAddCommunicationType={props.onAddCommunicationType}
      isCopyModeStateOnCode={props.isCopyModeStateOnCode || ''}
      navigation={props.navigation ? props.navigation : null}
      text={item.displayContent}
      dateStr={item.dateStr}
      position={item.position}
      senderType={item.senderType}
      senderContact={item.senderContact}
      senderUser={item.senderUser}
      senderFullName={item.senderFullName}
      messageType={item.messageType}
      attachments={item.msgAttachment}
      private={item.private}
      conversation={props.selectedConversation}
      isReadOnlyView={props.isReadOnlyView}
      selectedInboxTypeCode={props.selectedInboxTypeCode}
      conversationMessage={item.messageData}
      parentMessageUuid={item?.parentMessageUuid}
      parentMessage={item?.parentMessage}
      onParentMsgClick={props.scrollToMessage}
      uuid={item.uuid}
      id={item.id}
      onReplyCallback={props.onReplyCallback}
      showModal={props?.showModal}
      onActionPerformed={props?.onActionPerformed}
      replyObject={tempObject}
      item={item}
      messageAction={props?.messageAction}
      onMessageAction={props?.onMessageAction}
      isSeen={
        item.position === 'right'
          ? isSeen(
              props.lastSeenData,
              props.groupMemberId,
              item.date,
              item.displayContent,
              false,
              props.selectedConversation,
              isSinglePatientReadReceiptEnable,
              item?.private
            )
          : false
      }
      isSeenByUsers={isWeb() ? undefined : isSeenByUsers}
      handleCopyClick={props.handleCopyClick}
      taskCount={item.taskCount}
      onViewTaskAction={props.onViewTaskAction}
      searchMessage={props.searchMessage}
      onRedirectToMentionChange={props.onRedirectToMentionChange}
      redirectToMention={props.redirectToMention}
      isDisplaySectionMsgHeader={isDisplaySectionMsgHeader}
      groupMemberDataLoading={props?.groupMemberDataLoading || false}
      messageContentAttributes={item?.messageData?.contentAttributes}
    />
  );
};

export default React.memo(RenderMsgItem);
