import { Text, View } from "native-base";
import { Colors } from "../../../styles";

const InstructionField = (props: {value: any}) => {
    return (
        <View
            borderRadius={6}
            backgroundColor={Colors?.Custom?.Gray50}
            padding={2}
            borderWidth={1}
            borderColor={Colors?.Custom?.Gray100}>
            <Text
                color={Colors?.Custom?.GrayTextForReadOnly}
                fontFamily={'Manrope'}
                fontSize={13}>
                {props?.value}
            </Text>
        </View>
    )
}

export default InstructionField;