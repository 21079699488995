export const WIDGET_TYPES = {
  TPA_CARE_ADVOCATE: 'TPA_CARE_ADVOCATE',
};

export const FORM_CATEGORY_TYPES = {
  PROVIDER_REQUEST_FORM: 'PROVIDER_REQUEST_FORM',
  NOTES: 'SOAP',
  ASSESSMENT_FORM_CATEGORY: 'CLINICAL_ASSESSMENT',
  CARE_PLAN: 'CARE_PLAN',
};
