// Get the DateTime from the components listing.
import {Formio} from '@foldhealth/formiojs';
import NativePromise from 'native-promise-only';
import QuillConfig from '../../Builder/QuillConfig';
import ConditionalFields from '../../EditFormFields/ConditionalFields';
import KeyField from '../../EditFormFields/KeyField';
import ShareWithPatientFields from '../../EditFormFields/ShareWithPatientFields';

const FieldComponent = Formio.Components.components.field;

export default class BMIComponent extends FieldComponent {
  static schema(...extend: any) {
    return FieldComponent.schema(
      {
        type: 'bmi',
        label: 'BMI',
        key: 'bmi',
      },
      ...extend
    );
  }

  static get builderInfo() {
    return {
      title: 'BMI',
      group: 'advanced',
      icon: 'calculator',
      weight: 2,
      documentation: '/userguide/#datetime',
      schema: BMIComponent.schema(),
    };
  }

  get emptyValue() {
    return {
      height: '',
      weight: '',
      bmi: '',
      heightUnit: 'cm',
      weightUnit: 'kg',
    };
  }

  checkIfValidNumber(value: string): boolean {
    if (value) {
      const isNumber = /^\d+$/.test(value);

      return isNumber;
    }
    return true;
  }

  isEmpty() {
    const bmiData = this.data[this.component.key];
    return !bmiData.height || !bmiData.weight;
  }

  hasChanged(newValue: any, oldValue: any) {
    const isChanged = super.hasChanged(newValue, oldValue);
    this.isComponentValueChanged = isChanged;
    return isChanged;
  }

  checkComponentValidity(data: any, dirty: any, row: any, options: any = {}) {
    data = data || this.rootValue;
    const {async = false} = options;
    const bmiData = data[this.component.key];

    if (this.shouldSkipValidation(data, dirty, row)) {
      this.setCustomValidity('');
      return async ? NativePromise.resolve(true) : true;
    }

    if (
      this.component.validate.required &&
      this.isComponentValueChanged &&
      (!bmiData.height || !bmiData.weight)
    ) {
      const message = this.t('BMI is required');
      this.setCustomValidity(message, dirty);
      return false;
    }

    if (!this.checkIfValidNumber(bmiData.height)) {
      const message = this.t('Please add valid Height value');
      this.setCustomValidity(message, dirty);
      return false;
    }

    if (!this.checkIfValidNumber(bmiData.weight)) {
      const message = this.t('Please add valid Weight value');
      this.setCustomValidity(message, dirty);
      return false;
    }

    return super.checkComponentValidity(data, dirty, row, options);
  }

  getValue() {
    return {
      height: this.refs[this.getKeys().height].value,
      weight: this.refs[this.getKeys().weight].value,
      bmi: this.getBMIValue(),
      heightUnit: 'cm',
      weightUnit: 'kg',
    };
  }

  setValue(value: any) {
    if (!value) {
      return;
    }
    this.refs[this.getKeys().height].value = value.height;
    this.refs[this.getKeys().weight].value = value.weight;
  }

  getKeys() {
    return {
      height: `${this.component.key}-height`,
      weight: `${this.component.key}-weight`,
      bmiValue: `${this.component.key}-value`,
    };
  }

  attach(element: any) {
    const heightKey = this.getKeys().height;
    const weightKey = this.getKeys().weight;
    const refs: any = {};
    refs[heightKey] = 'single';
    refs[weightKey] = 'single';
    refs[this.getKeys().bmiValue] = 'single';
    this.loadRefs(element, refs);

    this.addEventListener(this.refs[heightKey], 'input', () => {
      this.updateValue();
      this.calculateAndUpdateBMI();
    });
    this.addEventListener(this.refs[weightKey], 'input', () => {
      this.updateValue();
      this.calculateAndUpdateBMI();
    });

    // Allow basic component functionality to attach like field logic and tooltips.
    return super.attach(element);
  }

  calculateAndUpdateBMI() {
    const bmiRef = this.refs[this.getKeys().bmiValue];
    const bmiValue = this.getBMIValue();
    this.dataValue.bmi = bmiValue;
    if (bmiValue) {
      this.setContent(bmiRef, `<p>(BMI value is ${bmiValue})</p>`);
    } else {
      this.setContent(bmiRef, '');
    }
  }

  getBMIValue() {
    if (this.dataValue.height && this.dataValue.weight) {
      const bmiValue = (
        this.dataValue.weight /
        ((this.dataValue.height * this.dataValue.height) / 10000)
      ).toFixed(2);
      return `${bmiValue}`;
    }
    return '';
  }

  render() {
    let table = '<div class="bmi-data">';
    table +=
      `<div class="bmi-value" ref="${this.component.key}-value">` +
      this.getBMIValue() +
      '</div>';

    table += '<div class="bmi-height-label"><p>Height</p></div>';
    table += this.renderCell('height');
    table += '<div class="bmi-height-unit"><p>cm</p></div>';

    table += '<div class="bmi-seperator"></div>';

    table += '<div class="bmi-weight-label"><p>Weight</p></div>';
    table += this.renderCell('weight');
    table += '<div class="bmi-weight-unit"><p>kg</p></div>';

    table += '</div>';
    return super.render(table);
  }

  renderCell(valueType: string) {
    return this.renderTemplate('input', {
      input: {
        type: 'input',
        ref: `${this.component.key}-${valueType}`,
        attr: {
          id: `${this.component.key}-${valueType}`,
          class: `form-control bmi-${valueType}`,
          type: 'number',
        },
      },
    });
  }
}

BMIComponent.editForm = function () {
  return {
    key: 'display',
    components: [
      {
        type: 'oldtextfield',
        key: 'label',
        label: 'Label',
        input: true,
        validate: {
          required: true,
        },
      },
      {
        type: 'checkbox',
        key: 'validate.required',
        label: 'Required',
        input: true,
      },
      {
        type: 'textarea',
        input: true,
        key: 'description',
        label: 'Description',
        placeholder: 'Description for this field.',
        tooltip:
          'The description is text that will appear below the input field.',
        editor: 'quill',
        wysiwyg: QuillConfig,
      },
      {
        type: 'checkbox',
        label: 'Hide Label',
        tooltip:
          'Hide the label or title of this component. This allows you to show the label in the form builder, but not when the form is shown to the member.',
        key: 'hideLabel',
        input: true,
      },
      ...ShareWithPatientFields,
      ...ConditionalFields,
      ...KeyField,
    ],
  };
};

Formio.Components.addComponent('bmi', BMIComponent);
