import React from 'react';
import {Path, Svg} from 'react-native-svg';

const ZapSvg = (props: {strokeColor?: string; size?: number}) => {
  const strokeColor = props.strokeColor || "#6F7A90";
  const size = props.size || 24;
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <Path
        d="M14.2684 7.14545C15.5698 4.60919 16.2205 3.34106 16.1211 2.89334C15.995 2.32564 15.4888 1.95423 14.9098 2.00455C14.4532 2.04424 13.4399 3.04637 11.4135 5.05064L6.77128 9.64209C5.53166 10.8682 4.91184 11.4812 5.01012 12.1361C5.10839 12.7911 5.86591 13.0958 7.38094 13.7051L8.88507 14.3101C9.44414 14.535 9.72368 14.6474 9.89421 14.8341C10.111 15.0714 10.2187 15.3949 10.1927 15.7312C10.1723 15.9957 10.0254 16.282 9.7316 16.8545C8.43019 19.3908 7.77948 20.6589 7.87891 21.1067C8.00498 21.6744 8.51116 22.0458 9.09019 21.9954C9.54684 21.9558 10.5601 20.9536 12.5865 18.9494L17.2287 14.3579C18.4683 13.1318 19.0882 12.5188 18.9899 11.8639C18.8916 11.2089 18.1341 10.9042 16.6191 10.2949L15.1149 9.68988C14.5559 9.46501 14.2763 9.35257 14.1058 9.16591C13.889 8.92859 13.7813 8.60505 13.8073 8.26876C13.8277 8.00425 13.9746 7.71799 14.2684 7.14545Z"
        stroke={strokeColor}
        stroke-linecap="round"
      />
    </Svg>
  );
};

export default ZapSvg;
