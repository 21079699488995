import {Drawer} from 'antd';
import {Select, Skeleton, View, VStack} from 'native-base';
import {useEffect, useState} from 'react';
import {Text} from 'react-native';
import {BUTTON_TYPE} from '../../../../../constants';
import {Colors} from '../../../../../styles';
import {getAccountUUID} from '../../../../../utils/commonUtils';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import {
  Campaign,
  GroupMemberFilterState,
  GroupMemberListFilterProps,
  Workflow,
} from '../../interfaces';
import {GroupMemberListFilterController} from './GroupMemberListFilterUtils';

const GroupMemberListFilter = (props: GroupMemberListFilterProps) => {
  const {
    isOpen,
    selectedCampaign,
    selectedWorkflow,
    onSubmit,
    onCompleteAction,
  } = props;

  const [state, setState] = useState<GroupMemberFilterState>({
    campaigns: [] as Campaign[],
    workflows: [] as Workflow[],
    error: '',
    selectedCampaign: {} as Campaign,
    selectedWorkflow: {} as Workflow,
    workflowLoading: false,
    campaignLoading: false,
  });

  const accountUuid = getAccountUUID();

  const {
    skeletonLines,
    handleOnClose,
    handleOnSubmit,
    onMount,
    onSelectCampaign,
    onSelectWorkflow,
  } = new GroupMemberListFilterController({
    isOpen,
    accountUuid,
    state,
    selectedCampaign,
    selectedWorkflow,
    setState,
    onCompleteAction,
    onSubmit,
  });

  useEffect(() => {
    onMount();
  }, []);

  return (
    <Drawer
      open={isOpen}
      onClose={handleOnClose}
      width={'33vw'}
      title={
        <ModalActionTitle
          title={'Filters'}
          titleColor={''}
          buttonList={[
            {
              show: true,
              id: 1,
              btnText: 'cancel',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.SECONDARY,
              isTransBtn: false,
              onClick: () => {
                handleOnClose();
              },
            },
            {
              show: true,
              id: 2,
              btnText: 'apply',
              textColor: Colors.Custom.mainPrimaryPurple,
              variant: BUTTON_TYPE.PRIMARY,
              isTransBtn: false,
              onClick: () => {
                handleOnSubmit();
              },
            },
          ]}
        />
      }
    >
      <VStack  space="5">
        <VStack style={{}} space="2">
          <Text>Members not in following Campaign</Text>
          <View>
            {state.campaignLoading ? (
              <Skeleton.Text lines={skeletonLines} />
            ) : (
              <Select
                onValueChange={(value) => {
                  onSelectCampaign(value);
                }}
                selectedValue={state.selectedCampaign.id || ''}
              >
                {state.campaigns.map((item) => {
                  return <Select.Item label={item.name} value={item.id} />;
                })}
              </Select>
            )}
          </View>
        </VStack>
        <VStack space="2">
          <Text>Members not in following Workflow</Text>
          <View>
            {state.workflowLoading ? (
              <Skeleton.Text lines={skeletonLines} />
            ) : (
              <Select
                onValueChange={(value) => {
                  onSelectWorkflow(value);
                }}
                selectedValue={state.selectedWorkflow.workflowMasterId || ''}
              >
                {state.workflows.map((item) => {
                  return (
                    <Select.Item
                      label={item.name}
                      value={item.workflowMasterId}
                    />
                  );
                })}
              </Select>
            )}
          </View>
        </VStack>
      </VStack>
    </Drawer>
  );
};

export default GroupMemberListFilter;
