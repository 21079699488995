import {View} from 'native-base';
import React from 'react';
import {StyleSheet} from 'react-native';

const SwipeActions = () => {
  return (
    <View style={styles.container}></View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 100,
  },
});

export default React.memo(SwipeActions);
