import { useLazyQuery } from '@apollo/client';
import { Drawer, Radio, Skeleton } from 'antd';
import { uniqBy } from 'lodash';
import {
  Divider,
  HStack, Stack, useMediaQuery,
  useToast, View, VStack
} from 'native-base';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { FlatList, Pressable, StyleSheet } from 'react-native';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {
  BUTTON_TYPE,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH
} from '../../../../../../constants';
import { CONFIG_CODES } from '../../../../../../constants/AccountConfigConst';
import { CONVERSATION_LOCAL_EVENT_CODES } from '../../../../../../constants/WebSocketConst';
import { CommonDataContext } from '../../../../../../context/CommonDataContext';
import ConversationsQueries from '../../../../../../services/Conversations/ConversationsQueries';
import { Colors } from '../../../../../../styles/Colors';
import {
  getAllowedUserPracticeLocationUuids,
  getUserId,
  getUserUUID,
  isChannelEmail,
  isChannelTwillioSms
} from '../../../../../../utils/commonUtils';
import { showToast, ToastType } from '../../../../../../utils/commonViewUtils';
import { isAccountConfigEnabled } from '../../../../../../utils/configUtils';
import { localBroadcastEvent } from '../../../../../../utils/CustomEventHandler';
import MemberInfoListItem from '../../../../../common/MemberInfoListItem/MemberInfoListItem';
import { ModalActionTitle } from '../../../../../common/ModalActionTitle/ModalActionTitle';
import { FoldButton } from '../../../../../CommonComponents/FoldButton/FoldButton';
import { MAIN_MENU_CODES } from '../../../../../SideMenuBar/SideBarConst';
import OverrideAntdModal from '../../../../ContentManagement/OverrideModal/OverrideAntdModal';
import { USER_ACCESS_PERMISSION } from '../../../../UserAccess/UserAccessPermission';
import { changePrimaryContactAPI, changePrimaryContactForAllConversationForSelectedContact } from '../../ConversationChannelNew/ConversationAPIService';
import { CHANNEL_TYPE } from '../../ConversationConst';
import { IContact, INewConversationResponse } from '../../interfaces';
import {
  IContactResponse,
  IUpdateToContactProp,
  IUpdateToContactState
} from './interface';

const UpdateToContact = (props: IUpdateToContactProp) => {
  const intl = useIntl();
  const createToast = useToast();
  const commonData = React.useContext(CommonDataContext);
  const isSideCarContext = commonData.sidecarContext?.isSidecar;
  const userUuid = getUserUUID();
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);
  const isCommunicationLocationHandlingEnabled = isAccountConfigEnabled(
    CONFIG_CODES.ENABLE_COMMUNICATION_LOCATION_HANDLING
  );
  const allowedUserPracticeLocationUuids = getAllowedUserPracticeLocationUuids(
    USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
    MAIN_MENU_CODES.INBOX
  );
  const [getConversationByConversationId] =
    useLazyQuery<INewConversationResponse>(
      ConversationsQueries.GetConversationByConversationId,
      {
        fetchPolicy: 'no-cache',
      }
    );
 
  const userId = getUserId();
  const useruuid = getUserUUID();

  const enableUpdateContactDisplayForSelectedConversation = isAccountConfigEnabled(
    CONFIG_CODES.ENABLE_SELECTED_UPDATE_CONTACT_DISPLAY
  );

  const defaultData: IUpdateToContactState = {
    isVisible: props?.isVisible,
    loading: false,
    contacts: [],
    selectedContact: props?.contactData?.uuid,
    updateLoading: false,
    openConfirmationModal: false,
  };

  const [updateToContactState, setUpdateToContactState] = useState(defaultData);

  const isSmsInbox = isChannelTwillioSms(props?.channelType);
  const isEmailInbox = isChannelEmail(props?.channelType);

  const finalWidth = isIPadScreen || isIPadMiniScreen ? '75%' : 560;

  const onSave = async () => {
    const fromContactUuid = props?.contactData?.uuid;
    const toContactUuid = updateToContactState?.selectedContact;
    const conversationUuid = props?.selectedConversation?.uuid;

    try {
      if (props?.selectedConversation?.conversationInbox?.channelType === CHANNEL_TYPE.CHANNEL_EMAIL && !enableUpdateContactDisplayForSelectedConversation) {
        showToast(
          createToast,
          intl.formatMessage({id: 'primaryContactUpdatedForAllConversation'}),
          ToastType.success
        );
        await changePrimaryContactForAllConversationForSelectedContact(
          fromContactUuid,
          toContactUuid,
          props?.selectedConversation?.conversationInbox?.channelType,
          props?.selectedConversation?.uuid
        );

        const conversationData = await getConversationByConversationId({
          variables: {
            conversationId: props?.selectedConversation?.id,
            loginUserIntId: userId,
            loginUserUuid: useruuid,
          },
        });
        localBroadcastEvent(
          CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_CONTACT_UPDATE,
          {}
        );
        props.onComplete(conversationData?.data?.conversations?.[0]);
      } else {
        await changePrimaryContactAPI(
          fromContactUuid,
          toContactUuid,
          conversationUuid
        )

        const conversationData = await getConversationByConversationId({
          variables: {
            conversationId: props?.selectedConversation?.id,
            loginUserIntId: userId,
            loginUserUuid: useruuid,
          },
        });
        showToast(
          createToast,
          intl.formatMessage({id: 'primaryContactUpdated'}),
          ToastType.success
        );
        localBroadcastEvent(
          CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_CONTACT_UPDATE,
          {}
        );
        props.onComplete(conversationData?.data?.conversations?.[0]);
      }

    } catch(e) {
      showToast(
        createToast,
        intl.formatMessage({id: 'apiErrorMsg'}),
        ToastType.error
      );
      props.onCancel();
    }
  };

  const [getMemberByContact] = useLazyQuery(
    ConversationsQueries.getMemberByContact,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const getMembersDataForEmailAndSms = async () => {
    setUpdateToContactState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    let whereObject: any = {
      isDeleted: {_eq: false},
      id: {_neq: props?.contactData?.id},
    };
    if (isSmsInbox) {
      whereObject = {
        ...whereObject,
        phoneNumber: {_like: props?.contactData?.phoneNumber},
      };
    } else if (isEmailInbox) {
      whereObject = {
        ...whereObject,
        email: {_like: props?.contactData?.email},
      };
    }
    if (isCommunicationLocationHandlingEnabled) {
      whereObject = {
        ...whereObject,
        contactPracticeLocations: {
          practiceLocationUuid: {
            _in: allowedUserPracticeLocationUuids,
          },
        },
      };
    }
    if (isEmailInbox || isSmsInbox) {
      const response = await getMemberByContact({
        variables: {
          where: whereObject,
        },
      }).catch(() => {
        setUpdateToContactState((prev) => {
          return {
            ...prev,
            loading: false,
          };
        });
      });
      if (response?.data?.contacts?.length) {
        const contacts: IContactResponse[] = response?.data?.contacts;

        const sortedContactsList = contacts?.sort((prevContact, currContact) => {
          const prevContactName = prevContact?.name?.toLowerCase();
          const currContactName = currContact?.name?.toLowerCase();
          if (prevContactName < currContactName) {
            return -1;
          }
          if (prevContactName > currContactName) {
            return 1;
          }
          return 0;
        }) || [];

        const uniqueSortedContactsList = uniqBy(sortedContactsList, 'uuid') || []

        setUpdateToContactState((prev) => {
          return {
            ...prev,
            contacts: [props?.contactData as IContactResponse, ...uniqueSortedContactsList],
            loading: false,
          };
        });
      } else {
        setUpdateToContactState((prev) => {
          return {
            ...prev,
            loading: false,
            contacts: [props?.contactData as IContactResponse],
          };
        });
      }
    }
  };

  useEffect(() => {
    getMembersDataForEmailAndSms();
  }, []);

  const getConfirmationTextContent = (): string => {
    const fromPatientName = props?.contactData?.name;
    const toPatient = updateToContactState?.contacts?.filter((contact) => {
        return contact?.uuid === updateToContactState.selectedContact;
      }) || {};
    const toPatientName = toPatient?.[0]?.name || "";
    return `Updating the display contact from ${fromPatientName} will ensure that conversations are listed under ${toPatientName}`;
  };

  const getTitleView = (): JSX.Element => {
    return (
      <Stack direction="row" style={styles.container}>
        <ModalActionTitle title={intl.formatMessage({id: 'updateContactDisplay'})} />
        <Stack
          direction="row"
          style={styles.titleContainer}
        >
          <View style={styles.buttonContainer}>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                onPress: () => {
                  setUpdateToContactState((prev) => {
                    return {
                      ...prev,
                      openConfirmationModal: true,
                    };
                  });
                },
                isLoading: updateToContactState?.updateLoading,
                marginBottom: 1,
                marginTop: 1,
                isDisabled:
                  props?.contactData?.uuid ===
                  updateToContactState.selectedContact,
              }}
              customProps={{
                btnText: intl.formatMessage({id: 'update'}),
                withRightBorder: false,
              }}
            ></FoldButton>
          </View>
          <View style={styles.closeIconContainer}>
            <Pressable onPress={props?.onCancel}>
              <AntIcon name="close" size={20} color={Colors.Custom.Gray400} />
            </Pressable>
          </View>
        </Stack>
      </Stack>
    );
  };

  return (
    <>
      <Drawer
        headerStyle={{borderWidth: 0, marginBottom: 0}}
        destroyOnClose
        placement="right"
        onClose={() => {
          props?.onComplete();
        }}
        visible={updateToContactState?.isVisible}
        closable
        width={isSideCarContext ? "100%" : finalWidth}
        title={getTitleView()}
      >
        {updateToContactState.loading ? (
          <Skeleton active style={{width: '100%'}} />
        ) : (
          <View>
            <Radio.Group
              name="mentionReadStatus"
              value={updateToContactState?.selectedContact}
              style={{width: '100%'}}
            >
              <VStack width={'100%'} flex={1} justifyContent={'space-between'}>
                {updateToContactState?.contacts?.length ? (
                  <FlatList
                    data={updateToContactState?.contacts}
                    renderItem={({item, index}: any) => {
                      const contact = item;
                      return (
                        <>
                          <Pressable
                            onPress={() => {
                              setUpdateToContactState((prev) => {
                                return {
                                  ...prev,
                                  selectedContact: contact?.uuid,
                                };
                              });
                            }}
                            style={styles.flatListContainer}
                          >
                            <HStack
                              style={styles.contactItemContainer}
                              alignItems={'center'}
                              justifyContent={'space-between'}
                            >
                              <View>
                                <MemberInfoListItem
                                  contactData={contact as IContact}
                                  showDateOfBirth={true}
                                />
                              </View>
                              <Radio value={contact?.uuid} />
                            </HStack>
                          </Pressable>

                          {index === 0 ? (
                            <Divider style={styles.divider} />
                          ) : undefined}
                        </>
                      );
                    }}
                  ></FlatList>
                ) : undefined}
              </VStack>
            </Radio.Group>
          </View>
        )}
      </Drawer>

      {updateToContactState.openConfirmationModal && (
        <OverrideAntdModal
          isOpen={updateToContactState.openConfirmationModal}
          onClose={() => {
            setUpdateToContactState((prev) => {
              return {
                ...prev,
                openConfirmationModal: false,
              };
            });
          }}
          onConfirm={() => {
            setUpdateToContactState((prev) => {
              return {
                ...prev,
                openConfirmationModal: false,
              };
            });

            setUpdateToContactState((prev) => {
              return {...prev, updateLoading: true};
            });
            if (
              props?.selectedConversation?.conversationInbox?.channelType ===
              CHANNEL_TYPE.CHANNEL_EMAIL
            ) {
              setUpdateToContactState((prev) => {
                return {
                  ...prev,
                  isVisible: false,
                };
              });
            }
            onSave();
          }}
          closeButtonText="cancel"
          textContent={{
            headerText: 'Confirmation',
            message: getConfirmationTextContent(),
          }}
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  titleContainer: {
    alignItems: 'center',
  },
  buttonContainer: {
    paddingRight: 20,
  },
  closeIconContainer: {
    paddingLeft: 10,
    borderLeftWidth: 1,
    borderLeftColor: Colors.Custom.Gray300,
    marginVertical: 5,
  },
  flatListContainer: {
    width: '100%',
  },
  contactItemContainer: {
    marginBottom: 10,
  },
  divider: {
    marginBottom: 12,
  },
});


export default UpdateToContact;
