import { Divider, HStack, Text, VStack } from 'native-base'
import React from 'react'
import ActionChip from './components/ActionChip';
import { IBottomActionsBarProps } from './interface';


const BottomActionsBar = (props:IBottomActionsBarProps) => {
  const { actionList,height } = props;
  if (actionList?.length === 0) return <></>;
  return (
    <VStack w="100%" h={height}>
      <HStack overflowX="scroll" alignItems="center" pb={1}>
        {actionList?.map((action, index) => {
          return <ActionChip action={action} key={index} />;
        })}
      </HStack>
    </VStack>
  );
}

export default BottomActionsBar;
