import {Skeleton, VStack, View} from 'native-base';
import React, {useContext} from 'react';
import {useIntl} from 'react-intl';
import {CONSENT_ERROR} from '../../../../../../constants';
import {CommonDataContext} from '../../../../../../context/CommonDataContext';
import {Colors} from '../../../../../../styles';
import {
  getUserUUID,
  isChannelEmail,
  isEFaxChannelInboxType,
} from '../../../../../../utils/commonUtils';
import ActionMessageView from '../../../../../common/ActionMessageView/ActionMessageView';
import {ACTION_MESSAGE_CODE} from '../../../../../common/ActionMessageView/ActionMessageViewConst';
import {IReplyMessageObject} from '../../../../../common/ChatUI/MessageBox/interfaces';
import {IEmailReplyAction} from '../../../../../common/EmailDrawerCommonV2/interfaces';
import TypingOn from '../../../../../common/TypingOn/TypingOn';
import {CHANNEL_TYPE_CODE} from '../../ConversationConst';
import {IContact, IConversationData, MsgQueue} from '../../interfaces';
import {EmailInboxFooter} from '../EmailInboxFooter';
import {MentionBar} from '../MentionBar';
import {
  getBlockNumberActionMessage,
  isSendMessageAllowed,
} from '../MessagingUtils';
import MessagingWindowFooter from '../MessagingWindowFooter';
import NewMessageReceived from '../NewMessageReceived/NewMessageReceived';
import {IMessageRespData, IUserPreferences} from '../interface';
import {getStyles} from './styles';

export interface IFooterViewProps {
  selectedConversation: IConversationData;
  selectedInboxTypeCode?: string;
  contactData: IContact;
  isSeachMsgContainerVisible?: boolean;
  parentCode?: string;
  isInstantChatView?: boolean;
  isDetailsContainerVisible?: boolean;
  conversationInbox: any;
  msgQueue?: MsgQueue[];
  selectedTabCode?: string;
  isInDrawerView?: boolean;
  isBlockNumber: boolean;
  isShowFooter: boolean;
  isConsentGiven: boolean;
  actionMessageCode: string;
  newMessageUuids: string[];
  loadingMsgs: boolean;
  mentionMessage: string;
  selectedReplyMsg: IReplyMessageObject;
  archiveOnSendData?: IUserPreferences;
  isNewLineOnEnter?: IUserPreferences;
  conversationDraftMessageLoading: boolean;
  footerLoading: boolean;
  selectedConversationData: IConversationData;
  isLoggedInUserGroupMember: boolean;
  conversationDraftMessage: string;
  totalScheduleMessage: number;
  totalScheduleFailedMessage: number;

  goToNewMessages: () => void;
  onMsgChangeText?: (richText?: any) => void;
  onViewChangeActionPerformed: (actionCode: string, rowData?: any) => any;
  onMessageSend: (
    msgText: string,
    msgTempData: IMessageRespData,
    parentMessage?: IReplyMessageObject
  ) => Promise<void>;
  onEmailInboxFooterActionPerformed: (actionCode: IEmailReplyAction) => void;
  onActionMsgFooterPerformed: (actionCode: string, actionData?: any) => void;
  onAddMsgToQueue?: (queue: MsgQueue[]) => void;
  onMessageSendFromFooter: (
    msgText: string,
    msgData?: any,
    parentMessage?: IReplyMessageObject
  ) => void;
  handleMentionBarClose: () => void;
  onEFaxFooterMsgSend: (
    msgText: string,
    msgData?: any,
    parentMessage?: IReplyMessageObject | undefined
  ) => void;
  onActionMsgViewClickAction: () => void;
}
const FooterView = React.forwardRef(
  (props: IFooterViewProps, mentionContainerRef: any) => {
    const {
      goToNewMessages,
      selectedConversation,
      selectedInboxTypeCode,
      contactData,
      onViewChangeActionPerformed,
      isSeachMsgContainerVisible,
      onMsgChangeText,
      onMessageSend,
      onEmailInboxFooterActionPerformed,
      parentCode,
      isInstantChatView,
      isDetailsContainerVisible,
      conversationInbox,
      onActionMsgFooterPerformed,
      selectedTabCode,
      onMessageSendFromFooter,
      actionMessageCode,
      isBlockNumber,
      isConsentGiven,
      isShowFooter,
      loadingMsgs,
      mentionMessage,
      newMessageUuids,
      selectedReplyMsg,
      archiveOnSendData,
      isNewLineOnEnter,
    } = props;
    const intl = useIntl();
    const userUuid = getUserUUID();
    const commonData = useContext(CommonDataContext);
    const isEFaxChannel = isEFaxChannelInboxType(
      selectedConversation?.conversationInbox?.channelType
    );
    const enableShowAccountNameOnlyToPatient =
      commonData?.userSettings[
        'mask_sender_user_with_account_in_patient_app_incoming_messages'
      ]?.value === 'True' || false;

    const isNewMessageReceivedDisplay = React.useCallback(() => {
      return newMessageUuids?.length > 0;
    }, [newMessageUuids]);

    const styles = React.useMemo(() => getStyles(), []);
    const actionMessageViewCustomStyle = React.useMemo(() => {
      return {
        backgroundColor: Colors.Custom.Gray100,
        textColor: Colors.Custom.Gray500,
        height:
          actionMessageCode === ACTION_MESSAGE_CODE.PATIENT_NUMBER_CHANGED
            ? 60
            : 53,
      };
    }, [actionMessageCode]);

    return (
      <VStack style={styles.container}>
        {!loadingMsgs && (
          <TypingOn
            selectedConversation={selectedConversation}
            selectedInboxTypeCode={props?.selectedInboxTypeCode || ''}
            isNewMessageShown={isNewMessageReceivedDisplay()}
          />
        )}

        {!loadingMsgs &&
          (props?.selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION ||
            (newMessageUuids && newMessageUuids?.length > 0)) && (
            <NewMessageReceived
              newMessageUuids={newMessageUuids}
              goToNewMessages={goToNewMessages}
            />
          )}
        {!loadingMsgs &&
          selectedInboxTypeCode == CHANNEL_TYPE_CODE.CHANNEL_MENTION &&
          mentionMessage && (
            <View ref={mentionContainerRef} style={styles.mentionBarContainer}>
              <MentionBar
                text={mentionMessage}
                handleClose={props.handleMentionBarClose}
                selectedConversationMentionId={
                  selectedConversation.conversationMentionId || ''
                }
              />
            </View>
          )}
        {props.conversationDraftMessageLoading || props.footerLoading ? (
          <Skeleton.Text
            bgColor={'green.700'}
            mx={1}
            mt={7}
            lines={4}
            width={'100%'}
          ></Skeleton.Text>
        ) : !isBlockNumber &&
          isSendMessageAllowed(
            props?.selectedConversationData || selectedConversation,
            userUuid,
            props?.isLoggedInUserGroupMember,
            props?.selectedConversationData?.conversationInbox ||
              selectedConversation?.conversationInbox
          ) &&
          isShowFooter ? (
          <>
            {props.footerLoading ? (
              <Skeleton.Text
                mx={1}
                mt={7}
                bgColor={'blue.900'}
                lines={4}
                width={'100%'}
              ></Skeleton.Text>
            ) : isChannelEmail(
                selectedConversation?.conversationInbox?.channelType
              ) ? (
              <View paddingBottom={3}>
                <EmailInboxFooter
                  selectedConversation={props?.selectedConversationData}
                  onMsgSend={onMessageSend}
                  onActionPerformed={onEmailInboxFooterActionPerformed}
                />
              </View>
            ) : isEFaxChannel ? (
              <></>
            ) : (
              <View paddingBottom={3}>
                <MessagingWindowFooter
                  enableShowAccountNameOnlyToPatient={
                    enableShowAccountNameOnlyToPatient
                  }
                  onCallBackEditorInputHeight={onActionMsgFooterPerformed}
                  msgQueue={props.msgQueue}
                  onAddMsgToQueue={props.onAddMsgToQueue}
                  isInDrawerView={props.isInDrawerView}
                  selectedReplyMsg={selectedReplyMsg}
                  conversationData={props?.selectedConversationData}
                  onMsgSend={onMessageSendFromFooter}
                  selectedTabCode={selectedTabCode}
                  selectedInboxTypeCode={selectedInboxTypeCode}
                  onActionMsgFooterPerformed={onActionMsgFooterPerformed}
                  conversationInbox={conversationInbox}
                  contactData={contactData}
                  isDetailsContainerVisible={isDetailsContainerVisible}
                  isInstantChatView={isInstantChatView}
                  parentCode={parentCode}
                  onMsgChangeText={onMsgChangeText}
                  archiveOnSendData={archiveOnSendData}
                  isNewLineOnEnter={isNewLineOnEnter}
                  isSeachMsgContainerVisible={isSeachMsgContainerVisible}
                  onMentionActionPerformed={onViewChangeActionPerformed}
                  conversationDraftMessage={props.conversationDraftMessage}
                  totalScheduleMessage={props.totalScheduleMessage}
                  totalScheduleFailedMessage={props.totalScheduleFailedMessage}
                />
              </View>
            )}
          </>
        ) : isBlockNumber &&
          props?.selectedConversationData?.conversationInbox?.channelTwilioSms
            ?.phoneNumber ? (
          <View>
            <ActionMessageView
              actionCode={ACTION_MESSAGE_CODE.INBOX_UNSUBSCRIBE}
              conversationId={selectedConversation?.uuid}
              message={getBlockNumberActionMessage(
                selectedConversation?.conversationContact?.name,
                props?.selectedConversationData?.conversationInbox?.channelTwilioSms?.phoneNumber?.toString(),
                intl
              )}
              customStyle={actionMessageViewCustomStyle}
            />
          </View>
        ) : isSendMessageAllowed(
            props?.selectedConversationData,
            userUuid,
            props?.isLoggedInUserGroupMember,
            props?.selectedConversationData?.conversationInbox
          ) && !isConsentGiven ? (
          <View>
            <ActionMessageView
              actionCode={ACTION_MESSAGE_CODE.CONSENT_NOT_CHECK}
              conversationId={selectedConversation?.uuid}
              message={`${CONSENT_ERROR}`}
              customStyle={actionMessageViewCustomStyle}
              onClickAction={props.onActionMsgViewClickAction}
            />
          </View>
        ) : (
          actionMessageCode &&
          !loadingMsgs && (
            <View>
              <ActionMessageView
                actionCode={actionMessageCode}
                contactData={contactData}
                conversationId={selectedConversation?.uuid || ''}
                customStyle={actionMessageViewCustomStyle}
              />
            </View>
          )
        )}
      </VStack>
    );
  }
);

export default FooterView;
