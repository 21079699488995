import {Avatar, HStack, Image, Pressable, Text, View} from 'native-base';
import React from 'react';
import './BrandingLogoStyle.scss'

export interface IBrandingLogoProps {
  url?: string;
  name?: string;
  smallVersion?: boolean;
  isLogoWithName?: boolean;
  accountNameTextColor?: string;
  accountNamePosition?: any;
  accountLogoPosition?: any;
  isFromPrint?: boolean;
  onClick: () => void;
}

const BrandingLogo = (props: IBrandingLogoProps) => {
  const getElementPosition = (position: string | undefined) => {
    switch (position) {
      case 'left':
        return 'flex-start';
      case 'right':
        return 'flex-end';
      default:
        return 'center';
    }
  };

  const isBothElementHasSamePosition = (): boolean => {
    const logoPosition = getElementPosition(props?.accountLogoPosition);
    const namePosition = getElementPosition(props?.accountNamePosition);
    return logoPosition === namePosition;
  };

  const isLeftPositionElementExist = (): boolean => {
    const logoPosition = getElementPosition(props?.accountLogoPosition);
    const namePosition = getElementPosition(props?.accountNamePosition);
    return logoPosition === 'flex-start' || namePosition === 'flex-start';
  };

  const isRightPositionElementExist = (): boolean => {
    const logoPosition = getElementPosition(props?.accountLogoPosition);
    const namePosition = getElementPosition(props?.accountNamePosition);
    return logoPosition === 'flex-end' || namePosition === 'flex-end';
  };

  const isCenterPositionElementExist = (): boolean => {
    const logoPosition = getElementPosition(props?.accountLogoPosition);
    const namePosition = getElementPosition(props?.accountNamePosition);
    return logoPosition === 'center' || namePosition === 'center';
  };

  const getLeftElement = (): JSX.Element => {
    const logoPosition = getElementPosition(props?.accountLogoPosition);
    const accountNamePosition = getElementPosition(props?.accountNamePosition);
    if (logoPosition === 'flex-start') {
      return (<div className={getClassNameByAccountLogoPosition()}>{getLogoElement()}</div>);
    } else if (accountNamePosition === 'flex-start') {
      return (
        <div className={getClassNameByAccountNamePosition()}>{getAccountNameElement()}</div>
      );
    } else {
      return (<></>);
    }
  };

  const getRightElement = (): JSX.Element => {
    const logoPosition = getElementPosition(props?.accountLogoPosition);
    const accountNamePosition = getElementPosition(props?.accountNamePosition);
    if (logoPosition === 'flex-end') {
      return (
        <div className={getClassNameByAccountLogoPosition()}>{getLogoElement()}</div>
      );
    } else if (accountNamePosition === 'flex-end' && props.name) {
      return (
        <div className={getClassNameByAccountNamePosition()}>{getAccountNameElement()}</div>
      );
    } else {
      return (<></>);
    }
  };

  const getCenterElement = (): JSX.Element => {
    const logoPosition = getElementPosition(props?.accountLogoPosition);
    const accountNamePosition = getElementPosition(props?.accountNamePosition);
    if (logoPosition === 'center') {
      if (isLeftPositionElementExist() || !props.name) {
        return (
          <div className={getClassNameByAccountLogoPosition()}>{getLogoElement()}</div>
        );
      } else {
        return (
          <div className={getClassNameByAccountNamePosition()}>{getAccountNameElement()}</div>
        );
      }
    } else if (accountNamePosition === 'center') {
      if (isLeftPositionElementExist()) {
        return (
          <div className={getClassNameByAccountLogoPosition()}>{getLogoElement()}</div>
        );
      } else {
        return (
          <div className={getClassNameByAccountNamePosition()}>{getAccountNameElement()}</div>
        );
      }
    } else {
      return (<></>);
    }
  };

  function getImageElement(src: string, height: number, width: number, borderRadius?: number) {
    if (props.isFromPrint) {
      return (
        <img
          height={height}
          width={width}
          src={src}
        />
      );
    } else {
      return (
        <Image
          borderRadius={borderRadius}
          style={{
            width,
            height,
          }}
          resizeMode={'contain'}
          source={{
            uri: src,
            cache: 'force-cache',
          }}
          alt='image'
        />
      )
    }
  }

  const getLogoElement = (): JSX.Element => {
    const logoDivClassName = props?.smallVersion ? 'logo-div-padding-view' :  'logo-div-view';
    return (
      <>
        {props.url && props.isLogoWithName && (
          getImageElement(props.url, 40, 40, 50)
        )}
        {props.url && !props.isLogoWithName && (
          <span className={logoDivClassName}>
            {props.smallVersion && (
              <Avatar
                size="sm"
                source={require('../../../assets/images/Abstract.png')}
              />
            )}
            {!props.smallVersion && (
              getImageElement(props.url, 40, 120)
            )}
          </span>
        )}
        {!props.url && (
          <Image
            borderRadius={50}
            width={10}
            height={10}
            source={require('../../../assets/images/Abstract.png')}
            alt='image'
          />
        )}
      </>
    );
  };

  const getAccountNameElement = () => {
    return (
      <>
        {!props.smallVersion && props.name && (
          <span className='account-name-text-style'>
            {props.name}
          </span>
        )}
        {!props.url && props.name && (
          <span className='account-name-text-style'>
            {props.name}
          </span>
        )}
      </>
    );
  };

  const getClassNameByAccountLogoPosition = (): string => {
    const position = props?.accountLogoPosition;
    switch (position) {
        case 'left':
          return 'flex-start';
        case 'right':
          return 'flex-end';
        default:
          return 'flex-center';
    }
  };

  const getClassNameByAccountNamePosition = (): string => {
    const position = props?.accountNamePosition;
    switch (position) {
        case 'left':
          return 'flex-start';
        case 'right':
          return 'flex-end';
        default:
          return 'flex-center';
    }
  };

  return (
    <Pressable
      onPress={() => {
        props.onClick();
      }}>
      {isBothElementHasSamePosition() && (
        <div className={getClassNameByAccountLogoPosition()}>
          <div>
            {getLogoElement()}
          </div>
          { props.name && <div className='ml-1'>{getAccountNameElement()}</div> }
        </div>
      )}
      {!isBothElementHasSamePosition() && (
        <div className='flex-div'>
          {isLeftPositionElementExist() && (
            getLeftElement()
          )}
          {isCenterPositionElementExist() && (
            getCenterElement()
          )}
          {isRightPositionElementExist() && (
            getRightElement()
          )}
        </div>
      )}
    </Pressable>
  );
};

export default BrandingLogo;
