import React from 'react';
import {ICommonSvgProps} from '../interfaces';

const CampaignSvg = (props: ICommonSvgProps) => {
  return (
    <svg
      width={props?.width || '16'}
      height={props?.height || '16'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33398 14.6693V9.33594M3.33398 9.33594V2.66927M3.33398 9.33594L4.9811 9.00652C6.08145 8.78644 7.22206 8.89117 8.26394 9.30792C9.39297 9.75953 10.6356 9.84387 11.8153 9.54894L11.9583 9.5132C12.375 9.40902 12.6673 9.03461 12.6673 8.60508V3.69373C12.6673 3.17335 12.1783 2.79153 11.6734 2.91774C10.586 3.18961 9.44047 3.11187 8.3997 2.69556L8.26394 2.64125C7.22205 2.2245 6.08145 2.11978 4.9811 2.33985L3.33398 2.66927M3.33398 2.66927V1.33594"
        stroke="#8A94A8"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default CampaignSvg;
