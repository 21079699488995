import { useLazyQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { CARESTUDIO_APOLLO_CONTEXT } from "../../constants/Configs";
import { USER_ROLE_CODES } from "../../constants/MlovConst";
import { GET_RESOURCE_MAPS } from "../../services/ContactCareProgram/ContactCareProgram";
import { GetTimezoneOfAccountAndLocations } from "../../services/Location/UserPracticeLocationQueries";
import { getAccountUUID } from "../../utils/commonUtils";

export interface IResourceMapArgs {
  resourceId: string;
}

export interface IResourceMapDetail {
  resourceId: string,
  sourceId: string,
  sourceTypeCode: string,
  createdOn: Date,
  createdBy: string,
  sourceDetail?: {
    id: string,
    createdOn?: Date,
    createdBy?: string,
    title?: string,
    statusId?: string
  }
}

export interface IResourceMapAPIResponse {
  resourceId: string,
  sourceId: string,
  sourceTypeCode: string,
  createdOn: Date,
  createdBy: string,
}

export const useResourceMapping = (props: IResourceMapArgs) => {

  const {resourceId} = props;
  const accountUuid = getAccountUUID();

  const [loading, setLoading] = useState<boolean>(false);
  const [resourceMapList, setResourceMapDetail] = useState<IResourceMapAPIResponse[]>([]);

  const [getResourceMaps] = useLazyQuery(GET_RESOURCE_MAPS, {
    fetchPolicy: 'no-cache',
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
  });

  const [getAccountUsers] = useLazyQuery(GetTimezoneOfAccountAndLocations, {
    fetchPolicy: 'no-cache',
  });

  const getResourceMappingData = async () => {
    setLoading(true);
    try {
      const [resourceMapResponse, userResponse] = await Promise.all([
        getResourceMaps({
          variables: {
            resourceId: resourceId,
          },
        }),
        getAccountUsers({
          variables: {
            tenantId: accountUuid, roleCode: USER_ROLE_CODES.EMPLOYER
          }
        })
      ]);

      const resourceMappings: IResourceMapAPIResponse[] = resourceMapResponse.data?.resourceMappings || [];
      if (!resourceMappings?.length) {
        setLoading(false);
        setResourceMapDetail([]);
        return;
      }
      if (resourceMappings?.length) {
        setLoading(false);
        setResourceMapDetail(resourceMappings);
      }
    } catch (error) {

      setLoading(false);
      setResourceMapDetail([]);
    }
  }

  useEffect(() => {
    getResourceMappingData();
  }, []);

  return { loading, resourceMapList }

}
