import { Drawer } from 'antd';
import { Spinner, useMediaQuery, View, VStack } from 'native-base';
import React, { useContext, useState } from 'react';
import { Dimensions } from 'react-native';
import { BUTTON_TYPE, IPAD_MINI_WIDTH, IPAD_WIDTH } from '../../../../../constants';
import { COMMON_ACTION_CODES } from '../../../../../constants/ActionConst';
import { Colors } from '../../../../../styles/Colors';
import { ModalActionTitle } from '../../../../common/ModalActionTitle/ModalActionTitle';
import { styles } from './ContactSearchDrawerStyles';
import {ScheduleMessageList} from './ScheduleMessageList';
import { CommonDataContext } from '../../../../../context/CommonDataContext';

const ScheduleMessageDrawer = (props: any) => {
  const {scheduleMessageData, onFormCompleteAction} = props;
  const [showModal, setShowModal] = useState(true);
  const closeModal = () => {
    setShowModal(false);
    onFormCompleteAction?.(COMMON_ACTION_CODES.CANCEL);
  };

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    { maxWidth: IPAD_WIDTH },
    { maxWidth: IPAD_MINI_WIDTH },
  ]);

  const { width } = Dimensions.get('window');

  const commonData = useContext(CommonDataContext);
  const isSideCarContext = commonData.sidecarContext?.isSidecar;
  const finalWidth = isSideCarContext
    ? '100%'
    : isIPadMiniScreen || isIPadScreen
    ? width / 2
    : width / 3;

  return (
    <>
      <Drawer
        visible={showModal}
        width={finalWidth}
        mask={isSideCarContext ? false : true}
        onClose={() => closeModal()}
        closable={false}
        title={
          <ModalActionTitle
          title={'Scheduled'}
          titleColor={''}
          titleSize={'24'}
          buttonList={[
            {
              show: true,
              id: 2,
              btnText: 'Done',
              textColor: Colors.Custom.mainPrimaryPurple,
              variant: BUTTON_TYPE.PRIMARY,
              isTransBtn: false,
              onClick: () => {
                closeModal();
              },
            },
          ]}
          />
        }>
        {props?.loading ? <Spinner size="lg" style={styles.spinnerStyle} /> :
        <View ml={0}>
        <VStack flex={1} marginTop={1}>
          <ScheduleMessageList
            onFormCompleteAction={(actionCode: string, data?: any) => {
            onFormCompleteAction(actionCode, data);
           }}
            scheduleMessageData={scheduleMessageData}
            handleSendMessage= {props?.handleSendMessage}
            refetchScheduleData= {props?.refetchScheduleData}
          />
        </VStack>
      </View>
        }
      </Drawer>
    </>
  );
};

export default ScheduleMessageDrawer;
