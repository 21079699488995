import {DISPLAY_SLASH_DATE_FORMAT, INSURANCE_NUMBER} from '../constants';
import React from "react";
import {
  postInsuranceFieldData,
  putInsuranceFielddata,
} from '../services/CommonService/AidBoxService';
import {getDateStrFromFormat, isValidDate} from '../utils/DateUtils';
import {
  displayToCodeMapAthena,
  displayToCodeMapElation,
  renderCopayFields,
} from '../utils/commonUtils';
import { ICopayDetail, IInsuranceState, planNameData } from './InsuranceInterfaces';
import {EXTENSION_URLS} from './PersonOmniView/MiddleContainer/PatientNotes/components/AddOrUpdateTemplate/constant';

export enum ImageSide {
  frontSide = 'Front Side',
  backSide = 'Back Side'
}

export const getPostRequestBodyCoverageElation = (
  insuranceData: IInsuranceState | undefined,
  patientId: string,
  isNewPatientFlow: boolean,
  planNameDataArray: planNameData[],
  order: number
) => {
  const planNameData = insuranceData?.planNameDataArray || insuranceData?.secondaryPlanNameDataArray || insuranceData?.tertiaryPlanNameDataArray || []
  const planNameId = (
    planNameData?.find(
      (item: any) => item.name === insuranceData?.planName
    ) || {}
  ).id;
  const requestBody = {
    resourceType: 'Coverage',
    identifier: [
      {
        system: 'member-id-number',
        value: insuranceData?.memberID,
      },
    ],
    order: order,
    beneficiary: {
      reference: `Patient/${patientId}`,
    },    
    ...(!!insuranceData?.patientRealtionWithPolicyHolder
      ? {
          relationship: {
            text: insuranceData?.patientRealtionWithPolicyHolder,
          },
        }
      : {}),
    policyHolder: {
      ...(!!insuranceData?.policyGroupNumber
        ? {reference: `Patient/${insuranceData?.policyGroupNumber}`}
        : {}),
      extension: [
        {
          valueAddress: {
            ...(!!insuranceData?.address
              ? {line: [insuranceData?.address, '']}
              : {}),
            ...(!!insuranceData?.city ? {city: insuranceData?.city} : {}),
            ...((!!insuranceData?.state || !!insuranceData?.abbr) ? {state: insuranceData?.abbr || insuranceData.state} : {}),
            ...(!!insuranceData?.country
              ? {country: insuranceData?.country}
              : {}),
            ...(!!insuranceData?.zipcode
              ? {postalCode: insuranceData?.zipcode}
              : {}),
          },
          url: EXTENSION_URLS.insurancePolicyHolderAddress,
        },
        insuranceData?.dob ? (isValidDate(insuranceData?.dob || '')
          ? {
              url: EXTENSION_URLS.insuranceDob,
              valueString: getDateStrFromFormat(insuranceData?.dob, DISPLAY_SLASH_DATE_FORMAT)
            }
          : {}) : {},
        !!insuranceData?.sexAtBirth
          ? {
              url: EXTENSION_URLS.insuranceSexAtBirth,
              valueCode:
                displayToCodeMapElation[insuranceData?.sexAtBirth || ''],
            }
          : {},
        {
          url: EXTENSION_URLS.insurancePolicyHolderName,
          valueHumanName: {
            family: insuranceData?.lastName,
            given: [insuranceData?.firstName],
          },
        },
        {
          ...(!!insuranceData?.policyGroupNumber ? {
          url: EXTENSION_URLS.insurancePolicyHolderIdElation,
          valueString: insuranceData?.policyGroupNumber
        } : {})
        }
      ],
    },
    ...(!!insuranceData?.issueDate || !!insuranceData?.endDate
      ? {
          period: {
            ...(isValidDate(insuranceData?.issueDate || '')
              ? {start: insuranceData?.issueDate}
              : {}),
            ...(isValidDate(insuranceData?.endDate || '')
              ? {end: insuranceData?.endDate}
              : {}),
          },
        }
      : {}),
    ...(!!insuranceData?.insuranceCarrierName
      ? {
          payor: [
            {
              reference: `Organization/${insuranceData?.organizationId}`,
              display: insuranceData?.insuranceCarrierName,
            },
          ],
        }
      : {}),
    costToBeneficiary: [
      {
        valueMoney: {
          ...(!!insuranceData?.copayAmount
            ? {value: insuranceData?.copayAmount}
            : {}),
        },
        type: {
          text: 'copay',
          coding: [
            {
              code: 'copay',
              system: EXTENSION_URLS.insuranceCopay,
            },
          ],
        },
      },
      {
        valueMoney: {
          ...(!!insuranceData?.deductibleAmount
            ? {value: insuranceData?.deductibleAmount}
            : {}),
        },
        type: {
          coding: [
            {
              code: 'deductible',
              system: EXTENSION_URLS.insuranceCopay,
            },
          ],
          text: 'deductible',
        },
      },
    ],
    class: [
      {
        type: {
          coding: [
            {
              system: EXTENSION_URLS.insurancePlan,
              code: 'plan',
            },
          ],
        },
        ...(!!insuranceData?.planName ? {name: insuranceData?.planName} : {}),
        ...(planNameId ? {value: insuranceData?.insurancePackageId || insuranceData?.planNameId}: {})
      },
      {
        type: {
          coding: [
            {
              system: EXTENSION_URLS.insurancePlan,
              code: 'group',
            },
          ],
        },
        ...(!!insuranceData?.groupID ? {value: insuranceData?.groupID} : {}),
      },
    ],
    contained: [
      !!insuranceData?.base64Image?.front && {
        content: {
          data: insuranceData?.base64Image?.front,
        },
        extension: [
          {
            url: EXTENSION_URLS.insuranceMediaRank,
            valueInteger: order,
          },
          {
            url: EXTENSION_URLS.insuranceMediaSide,
            valueInteger: 1,
          },
        ],
        subject: {
          reference: `Patient/${
            insuranceData?.patientWithPatientId || patientId
          }`,
        },
        resourceType: 'Media',
      },
      !!insuranceData?.base64Image?.back && {
        content: {
          data: insuranceData?.base64Image?.back,
        },
        extension: [
          {
            url: EXTENSION_URLS.insuranceMediaRank,
            valueInteger: order,
          },
          {
            url: EXTENSION_URLS.insuranceMediaSide,
            valueInteger: 2,
          },
        ],
        subject: {
          reference: `Patient/${
            insuranceData?.patientWithPatientId || patientId
          }`,
        },
        resourceType: 'Media',
      },
    ].filter(Boolean),
  };
  return requestBody;
};

export const handleAddDataElation = async (
  insuranceData: IInsuranceState | undefined,
  patientId: string,
  planNameDataArray: planNameData[],
  order: number
) => {
  const requestBody = getPostRequestBodyCoverageElation(
    insuranceData,
    patientId,
    false,
    planNameDataArray,
    order
  );
  return requestBody;
};

export const getPostRequestBodyCoverageAthena = (
  insuranceData: IInsuranceState | undefined,
  copayDetails: ICopayDetail[] | undefined,
  isNewPatientFlow: boolean,
  patientId: string | undefined,
  planNameDataArray: planNameData[],
  sequenceNumber: number
) => {
  const dateStringInFormat = insuranceData?.dob && getDateStrFromFormat(
    insuranceData?.dob,
    DISPLAY_SLASH_DATE_FORMAT
  );
  const planNameData = getPlanName(insuranceData, sequenceNumber);
  const planNameId = (
    planNameData?.find(
      (item: any) => item.name === insuranceData?.planName
    ) || {}
  ).id;
  const requestBody = {
    resourceType: 'Coverage',
    beneficiary: {
      reference: `Patient/${insuranceData?.patientWithPatientId || patientId}`,
      id: insuranceData?.patientWithPatientId || patientId,
    },
    identifier: [
      {
        system: 'insurance-id-number',
        value: insuranceData?.memberID,
      },
      {
        system: 'insurance-package-id',
        value: insuranceData?.insurancePackageId || insuranceData?.organizationId,
      },
    ],
    policyHolder: {
      display: `${insuranceData?.firstName} ${insuranceData?.lastName}`,
      identifier: {},
      ...(!!insuranceData?.policyGroupNumber
        ? {reference: `Patient/${insuranceData?.policyGroupNumber}`}
        : {}),
      extension: [
        {
          url: EXTENSION_URLS.insurancePolicyHolderAddress,
          valueAddress: {
            ...(!!insuranceData?.address
              ? {line: [insuranceData?.address, '']}
              : {}),
            ...(!!insuranceData?.city ? {city: insuranceData?.city} : {}),
            ...(!!insuranceData?.state ? {state: insuranceData?.state} : {}),
            ...(!!insuranceData?.country
              ? {country: insuranceData?.country}
              : {}),
            ...(!!insuranceData?.zipcode
              ? {postalCode: insuranceData?.zipcode}
              : {}),
          },
        },
        !!insuranceData?.sexAtBirth
          ? {
              url: EXTENSION_URLS.insuranceSexAtBirth,
              valueCode: displayToCodeMapAthena[insuranceData?.sexAtBirth || ''],
            }
          : {},
        !!insuranceData?.country
          ? {
              url: EXTENSION_URLS.insuranceHolderCountry,
              valueString: insuranceData?.country,
            }
          : {},
          dateStringInFormat ? (isValidDate(dateStringInFormat)
          ? {
              url: EXTENSION_URLS.insuranceDob,
              valueString: dateStringInFormat,
            }
          : {}) : {},
        {
          url: EXTENSION_URLS.insurancePolicyHolderName,
          valueHumanName: {
            family: `${insuranceData?.lastName}`,
            given: [`${insuranceData?.firstName}`, 'MIDDLE'],
          },
        },
      ],
    },
    ...(!!insuranceData?.issueDate || !!insuranceData?.endDate
      ? {
          period: {
            ...(isValidDate(insuranceData?.issueDate || '')
              ? {start: insuranceData?.issueDate}
              : {}),
            ...(isValidDate(insuranceData?.endDate || '')
              ? {end: insuranceData?.endDate}
              : {}),
          },
        }
      : {}),
    ...(!!insuranceData?.patientRealtionWithPolicyHolder
      ? {
          relationship: {
            text: insuranceData?.patientRealtionWithPolicyHolder,
            coding: [
              {
                  code: insuranceData?.patientRelationshipCode,
                  display: insuranceData?.patientRealtionWithPolicyHolder
              }
          ]
          },
        }
      : {}),
    class: [
      {
        type: {
          coding: [
            {
              system: EXTENSION_URLS.insurancePlan,
              code: 'group',
            },
          ],
        },
        value: insuranceData?.groupID ? insuranceData?.groupID : '',
      },
      {
        type: {
          coding: [
            {
              system: EXTENSION_URLS.insurancePlan,
              code: 'plan',
            },
          ],
        },
        ...((!!insuranceData?.insurancePackageId || !!insuranceData?.organizationId) ? {value: insuranceData?.insurancePackageId || insuranceData?.organizationId} : {}),
        ...(!!insuranceData?.planName ? {name: insuranceData?.planName} : {}),
      },
    ],
    extension: [
      {
        ...(!!insuranceData?.coinsurance
          ? {
              url: EXTENSION_URLS.insuranceCoInsurancePercent,
              valueInteger: insuranceData?.coinsurance,
            }
          : {}),
      },
      {
        ...(!!insuranceData?.lastChecked
          ? {
              url: EXTENSION_URLS.insuranceLastChecked,
              valueString: insuranceData?.lastChecked,
            }
          : {}),
      },
      {
        url: EXTENSION_URLS.insuranceSequenceNumber,
        valueInteger: sequenceNumber,
      },
      {
        ...(!!insuranceData?.policyGroupNumber ? {
        url: EXTENSION_URLS.insurancePolicyHolderIdAthena,
        valueString: insuranceData?.policyGroupNumber
      } : {})
      }
    ],
    ...(insuranceData?.copayDetailsArray &&
    insuranceData?.copayDetailsArray.length > 0
      ? {costToBeneficiary: renderCopayFields(copayDetails || [])}
      : {}),
    payor: [
      {
        reference: `Organization/${insuranceData?.organizationId}`,
      },
    ],
    contained: !!insuranceData?.base64ImageLink 
      ? [
          {
            content: {
              data: insuranceData?.base64ImageLink ,
            },
            resourceType: 'Media',
            extension: [
              {
                url: EXTENSION_URLS.insuranceCategory,
                valueString: 'insurance-card',
              },
              {
                url: EXTENSION_URLS.insuranceId,
                valueString: insuranceData?.insuranceId,
              },
              {
                url: EXTENSION_URLS.insurancePackageId,
                valueString: insuranceData?.insurancePackageId || insuranceData?.organizationId,
              },
            ],
            subject: {
              reference: `Patient/${insuranceData?.patientWithPatientId}`,
            },
          },
        ]
      : [],
  };
  return requestBody;
};

export const getPutRequestBodyCoverageAthena = (
  insuranceData: IInsuranceState | undefined,
  copayDetails: ICopayDetail[] | undefined,
  patientId: string | undefined,
  planNameDataArray: planNameData[],
  sequenceNumber: number) => {
  const planNameData = getPlanName(insuranceData, sequenceNumber);
  const planNameId = (
    planNameData?.find(
      (item: any) => item.name === insuranceData?.planName
    ) || {}
  ).id || insuranceData?.organizationId;
  const dateStringInFormat = insuranceData?.dob && getDateStrFromFormat(
    insuranceData?.dob,
    DISPLAY_SLASH_DATE_FORMAT
  );
  const requestBody = {
    resourceType: 'Coverage',
    beneficiary: {
      reference: `Patient/${insuranceData?.patientWithPatientId || patientId || ''
        }`,
      id: insuranceData?.patientWithPatientId || patientId || '',
    },
    id: insuranceData?.insuranceId,
    identifier: [
      {
        system: 'insurance-id-number',
        value: insuranceData?.memberID,
      },
      {
        system: 'insurance-package-id',
        value: insuranceData?.insurancePackageId || insuranceData?.organizationId,
      },
    ],
    policyHolder: {
      display: `${insuranceData?.firstName} ${insuranceData?.lastName}`,
      ...(!!insuranceData?.policyGroupNumber
        ? { reference: `Patient/${insuranceData?.policyGroupNumber || ''}` }
        : {}),
      extension: [
        {
          url: EXTENSION_URLS.insurancePolicyHolderAddress,
          valueAddress: {
            ...(!!insuranceData?.address
              ? { line: [insuranceData?.address, ''] }
              : {}),
            ...(!!insuranceData?.city ? { city: insuranceData?.city } : {}),
            ...(!!insuranceData?.state ? { state: insuranceData?.state } : {}),
            ...(!!insuranceData?.country
              ? { country: insuranceData?.country }
              : {}),
            ...(!!insuranceData?.zipcode
              ? { postalCode: insuranceData?.zipcode }
              : {}),
          },
        },
        !!insuranceData?.sexAtBirth
          ? {
            url: EXTENSION_URLS.insuranceSexAtBirth,
            valueCode:
              displayToCodeMapAthena[insuranceData?.sexAtBirth || ''],
          }
          : {},
        !!insuranceData?.country
          ? {
            url: EXTENSION_URLS.insuranceHolderCountry,
            valueString: insuranceData?.country,
          }
          : {},
          dateStringInFormat ?  (isValidDate(dateStringInFormat)
          ? {
            url: EXTENSION_URLS.insuranceDob,
            valueString: dateStringInFormat,
          }
          : {}) : {},
        {
          url: EXTENSION_URLS.insurancePolicyHolderName,
          valueHumanName: {
            family: `${insuranceData?.lastName}`,
            given: [`${insuranceData?.firstName}`, 'MIDDLE'],
          },
        },
      ],
    },
    ...(!!insuranceData?.issueDate || !!insuranceData?.endDate
      ? {
        period: {
          ...(isValidDate(insuranceData?.issueDate || '')
            ? { start: insuranceData?.issueDate }
            : {}),
          ...(isValidDate(insuranceData?.endDate || '')
            ? { end: insuranceData?.endDate }
            : {}),
        },
      }
      : {}),
    ...(!!insuranceData?.patientRealtionWithPolicyHolder
      ? {
        relationship: {
          text: insuranceData?.patientRealtionWithPolicyHolder,
          coding: [
            {
              code: insuranceData?.patientRelationshipCode,
              display: insuranceData?.patientRealtionWithPolicyHolder
            }
          ]
        },
      }
      : {}),

    class: [
      {
        type: {
          coding: [
            {
              system: EXTENSION_URLS.insurancePlan,
              code: 'group',
            },
          ],
        },
        value: insuranceData?.groupID ? insuranceData?.groupID : '',
      },
      {
        type: {
          coding: [
            {
              system: EXTENSION_URLS.insurancePlan,
              code: 'plan',
            },
          ],
        },
        ...((!!insuranceData?.insurancePackageId || !!insuranceData?.organizationId)  ? { value: insuranceData?.insurancePackageId || insuranceData?.organizationId } : {}),
        ...(!!insuranceData?.planName ? { name: insuranceData?.planName } : {}),
      },
    ],
    extension: [
      //here 1,1 stands for 1 as rank (primary insurance), 1 as side(front side image)
      insuranceData?.isAthenaImageDeleted ? {
        url: EXTENSION_URLS.insuranceDeletedMedia,
        valueString: `1,1`,
      } : {},
      !!insuranceData?.coinsurance
        ? {
          url: EXTENSION_URLS.insuranceCoInsurancePercent,
          valueInteger: insuranceData?.coinsurance,
        }
        : {},
      !!insuranceData?.lastChecked
        ? {
          url: EXTENSION_URLS.insuranceLastChecked,
          valueString: insuranceData?.lastChecked,
        }
        : {},
      {
        ...(!!insuranceData?.policyGroupNumber ? {
          url: EXTENSION_URLS.insurancePolicyHolderIdAthena,
          valueString: insuranceData?.policyGroupNumber
        } : {})
      },
      {
        url: EXTENSION_URLS.insuranceSequenceNumber,
        valueInteger: sequenceNumber,
      },
    ],
    ...(insuranceData?.copayDetailsArray &&
      insuranceData?.copayDetailsArray.length > 0
      ? { costToBeneficiary: renderCopayFields(copayDetails || []) }
      : {}),
    payor: [
      {
        reference: `Organization/${insuranceData?.organizationId}`,
      },
    ],
    contained: !!insuranceData?.base64ImageLink
      ? [
        {
          content: {
            data: insuranceData?.base64ImageLink,
          },
          resourceType: 'Media',
          extension: [
            {
              url: EXTENSION_URLS.insuranceCategory,
              valueString: 'insurance-card',
            },
            {
              url: EXTENSION_URLS.insuranceId,
              valueString: insuranceData?.insuranceId,
            },
            {
              url: EXTENSION_URLS.insurancePackageId,
              valueString: insuranceData?.organizationId,
            },
          ],
          subject: {
            reference: `Patient/${insuranceData?.patientWithPatientId}`,
          },
        },
      ]
      : [],
  };
  return requestBody;

}

const getPlanName = (insuranceData: IInsuranceState | undefined, order: number) => {
  let planNameData;
  switch (order) {
    case 1:
      planNameData = insuranceData?.planNameDataArray
      break;
    case 2:
      planNameData = insuranceData?.secondaryPlanNameDataArray
      break;
    case 3:
      planNameData = insuranceData?.tertiaryPlanNameDataArray
  }
  return planNameData;
}

export const getPutRequestBodyCoverageElation = (
  insuranceData: IInsuranceState | undefined,
  patientId: string,
  planNameDataArray: planNameData[],
  order: number
) => {
  const planNameData = getPlanName(insuranceData,order )
  const planNameId = (
    planNameData?.find(
      (item: any) => item.name === insuranceData?.planName
    ) || {}
  ).id || insuranceData?.planNameId;
  //imageDeletedString can be '1', '2' or '1,2' ,it indicates 'front side image', 'back side image' or 'front side image, back side image' respectively.
  let imageDeletedString;
  if (insuranceData?.isFrontSideImageDeleted && insuranceData?.isBackSideImageDeleted) {
    imageDeletedString = '1,2';
  } else if (insuranceData?.isBackSideImageDeleted) {
    imageDeletedString = '2';
  } else if (insuranceData?.isFrontSideImageDeleted) {
    imageDeletedString = '1';
  } else {
    imageDeletedString = ''
  }
  const requestBody = {
    resourceType: 'Coverage',
    id: insuranceData?.insuranceId,
    identifier: [
      {
        system: 'member-id-number',
        value: insuranceData?.memberID,
      },
      {
        system: 'insurance-package-id',
        value: insuranceData?.planNameId,
      },
    ],
    order: order,
    ...((imageDeletedString !== '') && {extension: [
      {
        url: EXTENSION_URLS.insuranceDeletedMedia,
        valueString: imageDeletedString
      },
    ]}),
    beneficiary: {
      reference: `Patient/${patientId}`,
    },
    ...(!!insuranceData?.patientRealtionWithPolicyHolder
      ? {
          relationship: {
            text: insuranceData?.patientRealtionWithPolicyHolder,
          },
        }
      : {}),
    policyHolder: {
      ...(!!insuranceData?.policyGroupNumber
        ? {reference: `Patient/${insuranceData?.policyGroupNumber}`}
        : {}),
      extension: [
        {
          valueAddress: {
            ...(!!insuranceData?.address
              ? {line: [insuranceData?.address, '']}
              : {}),
            ...(!!insuranceData?.city ? {city: insuranceData?.city} : {}),
            ...((!!insuranceData?.state  || !!insuranceData?.abbr) ? {state: insuranceData?.abbr || insuranceData.state} : {}),
            ...(!!insuranceData?.country
              ? {country: insuranceData?.country}
              : {}),
            ...(!!insuranceData?.zipcode
              ? {postalCode: insuranceData?.zipcode}
              : {}),
          },
          url: EXTENSION_URLS.insurancePolicyHolderAddress,
        },
        insuranceData?.dob ? (isValidDate(insuranceData?.dob || '')
          ? {
              url: EXTENSION_URLS.insuranceDob,
              valueString: getDateStrFromFormat(insuranceData?.dob, DISPLAY_SLASH_DATE_FORMAT),
            }
          : {}) : {},
        !!insuranceData?.sexAtBirth
          ? {
              url: EXTENSION_URLS.insuranceSexAtBirth,
              valueCode:
                displayToCodeMapElation[insuranceData?.sexAtBirth || ''],
            }
          : {},
        {
          url: EXTENSION_URLS.insurancePolicyHolderName,
          valueHumanName: {
            family: insuranceData?.lastName,
            given: [insuranceData?.firstName],
          },
        },
        {
          ...(!!insuranceData?.policyGroupNumber ? {
          url: EXTENSION_URLS.insurancePolicyHolderIdElation,
          valueString: insuranceData?.policyGroupNumber
        } : {})
        }
      ],
    },
    ...(!!insuranceData?.issueDate || !!insuranceData?.endDate
      ? {
          period: {
            ...(isValidDate(insuranceData?.issueDate || '')
              ? {start: insuranceData?.issueDate}
              : {}),
            ...(isValidDate(insuranceData?.endDate || '')
              ? {end: insuranceData?.endDate}
              : {}),
          },
        }
      : {}),
    payor: insuranceData?.insuranceCarrierName
      ? [
          {
            reference: `Organization/${insuranceData?.organizationId}`,
            display: insuranceData?.insuranceCarrierName,
          },
        ]
      : [],
    costToBeneficiary: [
      insuranceData?.copayAmount
        ? {
            valueMoney: {
              value: insuranceData?.copayAmount,
            },
            type: {
              text: 'copay',
              coding: [
                {
                  code: 'copay',
                  system: EXTENSION_URLS.insuranceCopay,
                },
              ],
            },
          }
        : {},
      insuranceData?.deductibleAmount
        ? {
            valueMoney: {
              value: insuranceData?.deductibleAmount,
            },
            type: {
              coding: [
                {
                  code: 'deductible',
                  system: EXTENSION_URLS.insuranceCopay,
                },
              ],
              text: 'deductible',
            },
          }
        : {},
    ],
    class: [
      insuranceData?.planName
        ? {
            type: {
              coding: [
                {
                  system: EXTENSION_URLS.insurancePlan,
                  code: 'plan',
                },
              ],
            },
            value: insuranceData?.insurancePackageId || insuranceData?.planNameId,
            name: insuranceData?.planName,
          }
        : {},
      insuranceData?.groupID
        ? {
            type: {
              coding: [
                {
                  system: EXTENSION_URLS.insurancePlan,
                  code: 'group',
                },
              ],
            },
            value: insuranceData?.groupID,
          }
        : {},
    ],
    contained: [
      !!insuranceData?.base64Image?.front && {
        content: {
          data: insuranceData?.base64Image?.front,
        },
        extension: [
          {
            url: EXTENSION_URLS.insuranceMediaRank,
            valueInteger: order,
          },
          {
            url: EXTENSION_URLS.insuranceMediaSide,
            valueInteger: 1,
          },
        ],
        subject: {
          reference: `Patient/${
            insuranceData?.patientWithPatientId || patientId
          }`,
        },
        resourceType: 'Media',
      },
      !!insuranceData?.base64Image?.back && {
        content: {
          data: insuranceData?.base64Image?.back,
        },
        extension: [
          {
            url: EXTENSION_URLS.insuranceMediaRank,
            valueInteger: order,
          },
          {
            url: EXTENSION_URLS.insuranceMediaSide,
            valueInteger: 2,
          },
        ],
        subject: {
          reference: `Patient/${
            insuranceData?.patientWithPatientId || patientId
          }`,
        },
        resourceType: 'Media',
      },
    ].filter(Boolean),
  };
  return requestBody;
}

export const handleAddData = async (
  insuranceData: IInsuranceState | undefined,
  copayDetails: ICopayDetail[],
  patientId: string | undefined,
  planNameDataArray: planNameData[],
  sequenceNumber: number
) => {
  const requestBody = getPostRequestBodyCoverageAthena(
    insuranceData,
    copayDetails,
    false,
    patientId,
    planNameDataArray,
    sequenceNumber
  ); 
  return requestBody; 
};

export const handleUpdateElation = async (
  insuranceData: IInsuranceState | undefined,
  patientId: string,
  planNameDataArray: planNameData[],
  order: number
) => {
  const requestBody = getPutRequestBodyCoverageElation(insuranceData, patientId, planNameDataArray, order )
  return requestBody;
};

export const handleUpdate = async (
  insuranceData: IInsuranceState | undefined,
  copayDetails: ICopayDetail[],
  patientId: string | undefined,
  planNameDataArray: planNameData[],
  sequenceNumber: number
) => {
  const requestBody = getPutRequestBodyCoverageAthena(insuranceData, copayDetails, patientId, planNameDataArray, sequenceNumber )
  return requestBody;
};

export const isInsuranceStateEmpty = (state: IInsuranceState | undefined): boolean => {
  for (const key in state) {
    if(key === 'addSecondaryInsuranceOpen' || key === 'addTertiaryInsuranceOpen'){
      continue;
    }
      if (Object.prototype.hasOwnProperty.call(state, key)) {
          const value = state[key as keyof IInsuranceState];
          if (value && (typeof value === 'string' ? value.trim() !== '' : true)) {
              return false;
          }
      }
  }
  return true;
}

export const getInsuranceStateClear = (dataToClear: IInsuranceState | undefined, insuranceNumber: string) => {
  const clearedData: IInsuranceState | undefined = {};
  if (dataToClear) {
    Object.keys(dataToClear).forEach(key => {
      if (
        insuranceNumber === INSURANCE_NUMBER.PRIMARY &&
        (key === 'addSecondaryInsuranceOpen' || key === 'addTertiaryInsuranceOpen')
      ) {
        clearedData[key] = dataToClear[key];
      } else {
        clearedData[key as keyof IInsuranceState] = undefined;
      }
    });
  }
  return clearedData;
}

