import {Spinner} from 'native-base';
import React from 'react';
import {ScrollView, Text, View} from 'react-native';
import SingleCallTrail from './SingleCallTrail';
import {getCallTrailByCallId} from './CallTailAPI';
import {ICallTrail, ICallTrailProps} from './interfaces';
import {CALL_STATUS_LABELS} from '../../../constants';

const CallTrail = (props: ICallTrailProps) => {
  const [state, setState] = React.useState({
    loading: false,
    callTrail: [] as ICallTrail[],
    statusLabel: '',
    workflowMasterId: '',
  });

  const abortControllerRef = React.useRef<AbortController>();

  const getCallTrail = async () => {
    setState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    try {
      const response = await getCallTrailByCallId({
        callId: props.callId,
        config: {
          signal: abortControllerRef.current?.signal,
        },
      });
      const callTrail = response.callTrail || [];
      if (
        response.statusLabel === CALL_STATUS_LABELS.ABANDONED_CALL &&
        callTrail.length > 0
      ) {
        callTrail.push({
          sequenceNumber: callTrail.length,
          timestamp: callTrail[callTrail.length - 1].timestamp,
          widgetName: 'abandonedCall',
        });
      }
      setState((prev) => {
        return {
          ...prev,
          loading: false,
          callTrail: callTrail || [],
          workflowMasterId: response.workflowMasterId || '',
          statusLabel: response.statusLabel || '',
        };
      });
    } catch (error) {
      setState((prev) => {
        return {
          ...prev,
          loading: false,
          callTrail: [],
          workflowMasterId: '',
          statusLabel: '',
        };
      });
    }
  };

  React.useEffect(() => {
    getCallTrail();
    return () => {
      abortControllerRef.current?.abort();
    };
  }, [props.callId]);

  return (
    <View>
      {state.loading ? (
        <View
          style={{
            flex: 1,
            padding: 10,
          }}
        >
          <Spinner />
        </View>
      ) : (
        <ScrollView>
          {state.callTrail.map((item, index) => (
            <SingleCallTrail
              key={index}
              singleCallTrail={item}
              firstNodeTimestamp={state.callTrail[0].timestamp}
              workflowMasterId={state.workflowMasterId}
              statusLabel={state.statusLabel}
              isLastTrail={index === state.callTrail.length - 1}
            />
          ))}
        </ScrollView>
      )}
    </View>
  );
};

export default CallTrail;
