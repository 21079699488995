import {gql} from '@apollo/client';

const GetConversations = gql`
  query GetConversations(
    $limit: Int!
    $offset: Int!
    $orderByObject: [conversations_order_by!]
    $whereCondition: conversations_bool_exp
    $conversationMessagesWhere: messages_bool_exp
    $unreadMessagesWhere: messages_bool_exp
    $unreadMessagesWhereCount: unreadMsgNew_conversations_args!
  ) {
    conversations(
      limit: $limit
      offset: $offset
      order_by: $orderByObject
      where: $whereCondition
    ) {
      contactId
      id
      uuid
      accountId
      displayId
      inboxId
      practiceLocationUuid
      status
      assigneeId
      createdAt
      lastActivityAt
      lastSmsAt
      lastMissCallAt
      subject
      additionalAttributes
      blockReasonCode
      conversationInbox {
        id
        name
        channelType
        isDeleted
      }
      contactInbox {
        sourceId
      }
      conversationContact {
        id
        uuid
        isDeleted
        name
        phoneNumber
        email
      }
      unreadMsgCount: unreadMsgNew(args: $unreadMessagesWhereCount)
      conversationMessages(
        limit: 1
        order_by: {createdAt: desc}
        where: $conversationMessagesWhere
      ) {
        contentType
        content
        createdAt
        updatedAt
        private
        messageType
        messageAttachments(limit: 1, order_by: {createdAt: desc}) {
          id
        }
        senderType
        senderUser {
          id
          name
        }
      }
      assignedUser {
        id
        uuid
        name
      }
      groupConversation {
        name
        id
        groupTypeId
        groupType {
          code
        }
      }
    }
  }
`;

const GetInboxConversations = gql`
  query GetConversations(
    $limit: Int!
    $offset: Int!
    $orderByObject: [conversations_order_by!]
    $whereCondition: conversations_bool_exp
    $conversationMessagesWhere: messages_bool_exp
    $unreadMessagesWhere: messages_bool_exp
    $unreadMessagesWhereCount: unreadMsgNew_conversations_args!
  ) {
    conversations(
      limit: $limit
      offset: $offset
      order_by: $orderByObject
      where: $whereCondition
    ) {
      contactId
      id
      uuid
      accountId
      displayId
      inboxId
      practiceLocationUuid
      status
      assigneeId
      createdAt
      lastActivityAt
      lastSmsAt
      lastMissCallAt
      blockReasonCode
      conversationInbox {
        id
        channelType
        isDeleted
      }
      contactInbox {
        sourceId
      }
      conversationContact {
        id
        uuid
        isDeleted
        name
        phoneNumber
        email
      }
      unreadMsgCount: unreadMsgNew(args: $unreadMessagesWhereCount)
      conversationMessages(
        limit: 1
        order_by: {createdAt: desc}
        where: $conversationMessagesWhere
      ) {
        contentType
        content
        createdAt
        updatedAt
        messageType
        private
        messageAttachments(limit: 1, order_by: {createdAt: desc}) {
          id
        }
        senderType
        senderUser {
          id
          name
        }
      }
      assignedUser {
        id
        uuid
        name
      }
    }
  }
`;

const GetChatConversations = gql`
  query GetConversations(
    $limit: Int!
    $offset: Int!
    $orderByObject: [conversations_order_by!]
    $whereCondition: conversations_bool_exp
    $conversationMessagesWhere: messages_bool_exp
    $unreadMessagesWhere: messages_bool_exp
    $unreadMessagesWhereCount: unreadMsgNew_conversations_args!
  ) {
    conversations(
      limit: $limit
      offset: $offset
      order_by: $orderByObject
      where: $whereCondition
    ) {
      contactId
      id
      uuid
      accountId
      displayId
      inboxId
      practiceLocationUuid
      status
      assigneeId
      createdAt
      lastActivityAt
      conversationContact {
        id
        uuid
        isDeleted
        name
        phoneNumber
        email
      }
      unreadMsgCount: unreadMsgNew(args: $unreadMessagesWhereCount)
      conversationMessages(
        limit: 1
        order_by: {createdAt: desc}
        where: $conversationMessagesWhere
      ) {
        contentType
        content
        private
        createdAt
        updatedAt
        messageAttachments(limit: 1, order_by: {createdAt: desc}) {
          id
        }
        senderType
        senderUser {
          id
          name
        }
      }
      assignedUser {
        id
        uuid
        name
      }
      groupConversation {
        name
        id
        groupTypeId
        groupType {
          code
        }
      }
    }
  }
`;

const GetMentionsConversations = gql`
  query GetConversations(
    $limit: Int!
    $offset: Int!
    $orderByObject: [conversation_mentions_order_by!]
    $whereCondition: conversation_mentions_bool_exp
  ) {
    conversationMentions(
      limit: $limit
      offset: $offset
      order_by: $orderByObject
      where: $whereCondition
    ) {
      id
      isRead
      conversationMessages: message {
        id
        contentType
        content
        createdAt
        updatedAt
        messageType
        private
        senderType
        senderUser {
          id
          name
        }
      }
      conversation {
        contactId
        id
        uuid
        inboxId
        practiceLocationUuid
        accountId
        displayId
        status
        additionalAttributes
        subject
        assigneeId
        createdAt
        lastActivityAt
        blockReasonCode
        conversationUser {
          email
          name
          id
          uuid
        }
        conversationInbox {
          id
          channelType
          isDeleted
        }
        contactInbox {
          sourceId
        }
        conversationContact {
          id
          uuid
          isDeleted
          name
          phoneNumber
          email
        }
        assignedUser {
          id
          name
        }
        groupConversation {
          name
          id
          groupTypeId
          groupType {
            code
          }
          groupMembers(limit: 3, order_by: {user: {name: asc}}) {
            groupUserId
            groupUserType {
              code
              value
            }
            user {
              id
              uuid
              name
              email
              accountUsers {
                isActive
                userId
              }
            }
          }
        }
      }
    }
  }
`;

export const GetEmailConversations = gql`
  query GetConversations(
    $limit: Int!
    $offset: Int!
    $orderByObject: [email_conversation_activities_order_by!]
    $whereCondition: email_conversation_activities_bool_exp
    $conversationMessagesWhere: messages_bool_exp
    $unreadMessagesWhere: messages_bool_exp
    $unreadMessagesWhereCount: unreadMsgNew_conversations_args!
  ) {
    emailConversationActivities(
      limit: $limit
      offset: $offset
      order_by: $orderByObject
      where: $whereCondition
    ) {
      conversations {
        contactId
        accountId
        id
        displayId
        uuid
        inboxId
        practiceLocationUuid
        status
        additionalAttributes
        subject
        assigneeId
        createdAt
        lastActivityAt
        conversationInbox {
          channelType
          isDeleted
          id
        }
        unreadMsgCount: unreadMsgNew(args: $unreadMessagesWhereCount)
        conversationContact {
          id
          uuid
          isDeleted
          name
          phoneNumber
          email
        }
        conversationMessages(
          limit: 1
          order_by: {createdAt: desc}
          where: $conversationMessagesWhere
        ) {
          private
          createdAt
          updatedAt
          senderUser {
            id
            name
          }
        }
        assignedUser {
          id
          uuid
          email
          name
        }
      }
      lastOutgoingEmailAt
    }
  }
`;

const GetGroupConversationByUuid = gql`
  query GetGroupConversationByUuid(
    $conversationUuid: uuid!
    $loginUserUuid: uuid!
    $loginUserId: bigint!
  ) {
    conversations(where: {uuid: {_eq: $conversationUuid}}) {
      unreadMessages: unreadMsg(
        args: {useruuid: $loginUserUuid}
        where: {
          _or: {senderType: {_is_null: false}, senderId: {_neq: $loginUserId}}
        }
      ) {
        id
      }
      conversationContact {
        id
        uuid
        name
        isDeleted
        phoneNumber
        email
        contactConsents {
          id
          consentId
        }
        person {
          chosenName
          pronounId
          birthDate
          gender {
            code
            value
          }
          sexAtBirth {
            value
          }
        }
        additionalAttributes
        personContact {
          value
          id
          typeId
          rank
        }
        patient {
          id
          patientId
          patientUuid
        }
        contactType {
          contactType {
            code
            value
          }
        }
        contactPracticeLocations {
          practiceLocationUuid
          accountLocation {
            uuid
          }
        }
      }
      groupConversation {
        id
        name
        ownerId
        ownerTypeId
        groupTypeId
        groupType {
          code
        }
        groupMembers_aggregate {
          aggregate {
            count
          }
        }
        groupMembers(where: {groupUserId: {_eq: $loginUserUuid}}) {
          groupUserId
          groupUserType {
            code
            value
          }
          user {
            id
            uuid
            name
            email
            accountUsers {
              isActive
              userId
            }
          }
        }
      }
    }
  }
`;

const GetInboxConversationByUuid = gql`
  query GetInboxConversationByUuid(
    $conversationUuid: uuid!
    $loginUserUuid: uuid!
    $loginUserId: bigint!
  ) {
    conversations(where: {uuid: {_eq: $conversationUuid}}) {
      unreadMessages: unreadMsg(
        args: {useruuid: $loginUserUuid}
        where: {
          _or: {senderType: {_is_null: false}, senderId: {_neq: $loginUserId}}
        }
      ) {
        id
      }
      blockReasonCode
      conversationContact {
        id
        uuid
        name
        isDeleted
        phoneNumber
        email
        contactConsents {
          id
          consentId
        }
        person {
          chosenName
          pronounId
          birthDate
          gender {
            code
            value
          }
          sexAtBirth {
            value
          }
        }
        additionalAttributes
        personContact {
          value
          id
          typeId
          rank
        }
        patient {
          id
          patientId
          patientUuid
        }
        contactType {
          contactType {
            code
            value
          }
        }
        contactPracticeLocations {
          practiceLocationUuid
          accountLocation {
            uuid
          }
        }
      }
      conversationInbox {
        id
        name
        channelType
        isDeleted
        channelEmail {
          forwardToEmail
          forwardRouteId
          smtpAddress
        }
        channelTwilioSms {
          phoneNumber
        }
        channelEfax {
          id
          efaxNumber
        }
        inboxMembers_aggregate {
          aggregate {
            count
          }
        }
        inboxMembers(where: {user: {uuid: {_eq: $loginUserUuid}}}) {
          user {
            uuid
            id
            name
          }
        }
      }
      contactInbox {
        sourceId
      }
    }
  }
`;

const GetGroupMembersByConversationUuid = gql`
  query GetGroupMembersByConversationUuid($conversationUuid: uuid!) {
    groupConversations(where: {conversationUuid: {_eq: $conversationUuid}}) {
      groupMembers {
        groupUserId
        groupUserType {
          code
          value
        }
        user {
          id
          uuid
          name
          email
          accountUsers {
            isActive
            userId
          }
          userRoles {
            userRole {
              userRole {
                code
                value
              }
            }
          }
        }
        contact {
          id
          uuid
          name
          contactType {
            contactType {
              code
              value
            }
          }
        }
      }
    }
  }
`;

const GetInboxMemberByInboxId = gql`
  query getAllInboxesMembers($inboxId: Int!) {
    inboxMembers(where: {inboxId: {_eq: $inboxId}, isDeleted: {_eq: false}}) {
      id
      userId
      user {
        id
        name
        uuid
        accountUsers {
          userId
          isActive
        }
        userRoles {
          userRole {
            userRole {
              code
              value
            }
          }
        }
      }
    }
  }
`;

export const GetIntenalChatConversations = gql`
  query GetIntenalChatConversations(
    $limit: Int!
    $offset: Int!
    $orderByObject: [conversations_order_by!]
    $whereCondition: conversations_bool_exp
    $conversationMessagesWhere: messages_bool_exp
    $unreadMessagesWhere: messages_bool_exp
    $unreadMessagesWhereCount: unreadMsgNew_conversations_args!
  ) {
    conversations(
      limit: $limit
      offset: $offset
      order_by: $orderByObject
      where: $whereCondition
    ) {
      contactId
      accountId
      id
      inboxId
      uuid
      displayId
      status
      additionalAttributes
      assigneeId
      createdAt
      lastActivityAt

      unreadMsgCount: unreadMsgNew(args: $unreadMessagesWhereCount)
      conversationMessages(
        limit: 1
        order_by: {createdAt: desc}
        where: $conversationMessagesWhere
      ) {
        contentType
        content
        private
        createdAt
        updatedAt
        messageAttachments(limit: 1, order_by: {createdAt: desc}) {
          id
        }
        senderType
        senderUser {
          id
          name
        }
      }
      groupConversation {
        id
        name
        groupType {
          code
        }
        groupMembers_aggregate {
          aggregate {
            count
          }
        }
        groupMembers(limit: 3, order_by: {user: {name: asc}}) {
          groupUserId
          groupUserType {
            code
            value
          }
          user {
            id
            uuid
            name
            email
            accountUsers {
              isActive
              userId
            }
          }
        }
      }
    }
  }
`;

const GetConversationMessages = gql`
  query GetConversationMessages(
    $id: bigint!
    $limit: Int!
    $createdAtCursor: timestamp!
  ) {
    conversation(id: $id) {
      conversationMessages(
        limit: $limit
        where: {createdAt: {_lt: $createdAtCursor}}
        order_by: {createdAt: desc}
      ) {
        ...messagesFragment
      }
    }
  }

  fragment messagesFragment on messages {
    id
    uuid
    content
    contentAttributes
    senderId
    senderType
    contentType
    createdAt
    updatedAt
    messageType
    status
    sourceId
    private
    isScheduleMessage
    isAutoResponse
    uuid
    parentMessageUuid
    parentMessage {
      id
    }
    senderContact {
      email
      name
      id
    }
    senderUser {
      email
      id
      name
      uuid
    }
    messageAttachments {
      id
    }
  }
`;

const GetMentionConversationMessages = gql`
  query GetConversationMessages(
    $id: bigint!
    $loginUserId: bigint!
    $loginUserUuid: uuid!
    $limit: Int!
    $createdAtCursor: timestamp!
  ) {
    conversation(id: $id) {
      unreadMessages: unreadMsg(
        args: {useruuid: $loginUserUuid}
        where: {
          _or: {senderType: {_is_null: false}, senderId: {_neq: $loginUserId}}
        }
        limit: 1
      ) {
        id
        messageType
        messageMentions(where: {userId: {_eq: $loginUserUuid}}) {
          id
        }
      }
      conversationMessages(
        limit: $limit
        where: {createdAt: {_lt: $createdAtCursor}}
        order_by: {createdAt: desc}
      ) {
        ...messagesFragment
      }
    }
  }

  fragment messagesFragment on messages {
    id
    uuid
    content
    messageMentions(
      where: {isRead: {_eq: false}, userId: {_eq: $loginUserUuid}}
    ) {
      id
      isRead
    }
    contentAttributes
    senderId
    senderType
    contentType
    createdAt
    updatedAt
    messageType
    status
    sourceId
    private
    isScheduleMessage
    isAutoResponse
    uuid
    parentMessageUuid
    parentMessage {
      id
    }
    senderContact {
      email
      name
      id
    }
    senderUser {
      email
      id
      name
      uuid
    }
    messageAttachments {
      id
    }
  }
`;

const GetConversationContactByUuid = gql`
  query GetConversationContactByUuid($conversationUuid: uuid!) {
    conversations(where: {uuid: {_eq: $conversationUuid}}) {
      conversationContact {
      id
      uuid
      name
      email
      isDeleted
      source
      accountId
      phoneNumber
      createdAt
      updatedAt
      lastActivityAt
      identifier
      additionalAttributes
      contactConsents {
        id
        consentId
      }
      contactPracticeLocations(where: {practiceLocationUuid: {_is_null: false}}) {
        id
        uuid
        additionalAttributes
        practiceLocationUuid
        accountLocation {
          uuid
        }
        contactUuid
        accountLocation {
          uuid
        }
        practiceLocation {
          name
          practiceCity {
            name
            id
          }
        }
      }
      contactDeactivationInfo {
        isActive
      }
      isActive
      contactTagging {
        labels {
          title
          color
          id
          description
        }
      }
      contactPreferredCommunicationChannel {
        preferredCommunicationModeId
        id
        preferredCommunicationModeMlov {
          code
          value
        }
      }
      contactProfilePhotos {
        attachmentId
      }
      contactType {
        contactType {
          code
          value
          id
        }
        id
      }
      personAddress {
        cityId
        stateId
        zipcodeId
        cities {
          name
          id
        }
        zipcodes {
          code
          id
        }
        states {
          name
          id
        }
        id
      }
      notes {
        contactId
        content
        userId
        id
      }
      patient {
        patientId
        patientUuid
        annualVisitDate
        isWeeklyCheckin
        id
      }
      person {
        chosenName
        pronounId
        firstName
        birthDate
        id
        bloodGroup {
          value
          code
          id
        }
        gender {
          code
          value
        }
        genderId
        birthSexId
        sexAtBirth {
          value
          code
          id
        }
        contactId
        middleName
        lastName
      }
      personContact {
        personContactId
        value
        id
        personContactType {
          accountId
          value
          id
          categoryId
        }
        typeId
        rank
      }
    }
  }
}
`;

const GetConversationContactByUUIDandContactUuid = gql`
  query GetConversationContactByUUIDandContactUuid(
    $conversationUuid: uuid!
    $contactUuid: uuid!
  ) {
    conversations(
      where: {
        uuid: {_eq: $conversationUuid}
        conversationContact: {uuid: {_eq: $contactUuid}}
      }
    ) {
      contactId
      id
      uuid
      accountId
      conversationContact {
        id
        uuid
      }
    }
  }
`;

const GetConversationByUUIDandContactUuid = gql`
  query GetConversationByUuidAndContactUuid(
    $conversationUuid: uuid!
    $contactUuid: uuid!
    $loginUserIntId: Int!
    $loginUserUuid: uuid!
  ) {
    conversations(
      where: {
        uuid: {_eq: $conversationUuid}
        conversationContact: {uuid: {_eq: $contactUuid}}
      }
    ) {
      contactId
      accountId
      id
      uuid
      displayId
      inboxId
      status
      additionalAttributes
      assigneeId
      inboxId
      createdAt
      blockReasonCode
      assignedUser {
        id
        uuid
        email
        name
      }
      lastActivityAt
      lastSmsAt
      lastMissCallAt
      unreadMsgCount: unreadMsgNew(
        args: {
          useruuid: $loginUserUuid
          userid: $loginUserIntId
          messagetype: "{-1}"
          messageconditionin: false
        }
      )
      conversationContact {
        id
        uuid
        isDeleted
        name
        phoneNumber
        email
      }
      conversationContact {
        id
        uuid
        isDeleted
        name
        phoneNumber
        email
      }
      conversationInbox {
        id
        name
        channelType
        isDeleted
      }
      conversationMessages(limit: 1, order_by: {createdAt: desc}) {
        contentType
        content
        createdAt
        updatedAt
        private
        messageType
        messageAttachments(limit: 1, order_by: {createdAt: desc}) {
          id
        }
      }
      groupConversation {
        name
        id
        groupTypeId
        groupType {
          code
        }
      }
    }
  }
`;

const GetAssignToOtherConversations = gql`
  query GetAssignToOtherConversations(
    $limit: Int!
    $offset: Int!
    $orderByObject: [conversations_order_by!]
    $whereCondition: conversations_bool_exp
    $conversationMessagesWhere: messages_bool_exp
    $unreadMessagesWhere: messages_bool_exp
    $unreadMessagesWhereCount: unreadMsgNew_conversations_args!
  ) {
    conversations(
      limit: $limit
      offset: $offset
      order_by: $orderByObject
      where: $whereCondition
    ) {
      contactId
      id
      uuid
      accountId
      displayId
      inboxId
      status
      assigneeId
      createdAt
      lastActivityAt
      lastSmsAt
      lastMissCallAt
      subject
      additionalAttributes
      blockReasonCode
      conversationInbox {
        id
        name
        channelType
        isDeleted
        channelEmail {
          forwardToEmail
          forwardRouteId
          smtpAddress
        }
        channelTwilioSms {
          phoneNumber
        }
        channelEfax {
          id
          efaxNumber
        }
        inboxMembers_aggregate {
          aggregate {
            count
          }
        }
      }
      contactInbox {
        sourceId
      }
      contactInbox {
        sourceId
      }
      conversationContact {
        id
        uuid
        isDeleted
        name
        phoneNumber
        email
      }
      unreadMsgCount: unreadMsgNew(args: $unreadMessagesWhereCount)
      conversationMessages(
        limit: 1
        order_by: {createdAt: desc}
        where: $conversationMessagesWhere
      ) {
        contentType
        content
        createdAt
        updatedAt
        private
        messageType
        messageAttachments(limit: 1, order_by: {createdAt: desc}) {
          id
        }
        senderType
        senderUser {
          id
          name
        }
      }
      assignedUser {
        id
        uuid
        name
      }
      groupConversation {
        id
        name
        ownerId
        ownerTypeId
        groupTypeId
        groupType {
          code
        }
        groupMembers_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
`;

const GetMessageDataForSelectedChat = gql`
  query GetMessageDataForSelectedChat(
    $limit: Int!
    $createdAtCursor: timestamp
    $conversationId: bigint!
  ) {
    conversationMessagesData: conversation(id: $conversationId) {
      conversationMessages(
        limit: $limit
        where: {createdAt: {_lt: $createdAtCursor}}
        order_by: {createdAt: desc}
      ) {
        ...messagesFragment
      }
    }
  }

  fragment messagesFragment on messages {
    id
    uuid
    content
    contentAttributes
    senderId
    senderType
    contentType
    createdAt
    updatedAt
    messageType
    status
    sourceId
    private
    isScheduleMessage
    isAutoResponse
    uuid
    parentMessageUuid
    parentMessage {
      id
    }
    senderContact {
      email
      name
      id
    }
    senderUser {
      email
      id
      name
      uuid
    }
    messageAttachments {
      id
    }
  }

  fragment strickNotes on sticky_notes {
    noteUuid
    contactNote {
    content
    }
    updatedByUser {
      name
    }
    updatedAt
  }
`;


const GetMessageDataForSelectedChatV2 = gql`
  query GetMessageDataForSelectedChat(
    $limit: Int!
    $createdAtCursor: timestamp
    $conversationId: bigint!
  ) {
    conversationMessagesData: conversation(id: $conversationId) {
      conversationMessages(
        limit: $limit
        where: {createdAt: {_lt: $createdAtCursor}}
        order_by: {createdAt: desc}
      ) {
        ...messagesFragment
      }
    }
  }

  fragment messagesFragment on messages {
    id
    uuid
    content
    contentAttributes
    senderId
    senderType
    contentType
    createdAt
    updatedAt
    messageType
    status
    sourceId
    private
    isScheduleMessage
    isAutoResponse
    uuid
    parentMessageUuid
    parentMessage {
      id
    }
    senderContact {
      email
      name
      id
    }
    senderUser {
      email
      id
      name
      uuid
    }
    messageAttachments {
      id
    }
  }
`;

const GetMessageDataForSelectedConversationInbox = gql`
  query GetMessageDataForSelectedConversationInbox(
    $limit: Int!
    $createdAtCursor: timestamp
    $conversationId: bigint!
  ) {
    conversationMessagesData: conversation(id: $conversationId) {
      conversationMessages(
        limit: $limit
        where: {createdAt: {_lt: $createdAtCursor}}
        order_by: {createdAt: desc}
      ) {
        ...messagesFragment
      }
    }
  }

  fragment messagesFragment on messages {
    id
    uuid
    content
    contentAttributes
    senderId
    senderType
    contentType
    createdAt
    updatedAt
    messageType
    status
    sourceId
    private
    isScheduleMessage
    isAutoResponse
    uuid
    parentMessageUuid
    parentMessage {
      id
    }
    senderContact {
      email
      name
      id
    }
    senderUser {
      email
      id
      name
      uuid
    }
    messageAttachments {
      id
    }
  }

  fragment strickNotes on sticky_notes {
    noteUuid
    contactNote {
      content
    }
    updatedByUser {
      name
    }
    updatedAt
  }
`;

const GetMessageDataForSelectedConversationInboxV2 = gql`
  query GetMessageDataForSelectedConversationInbox(
    $limit: Int!
    $createdAtCursor: timestamp
    $conversationId: bigint!
  ) {
    conversationMessagesData: conversation(id: $conversationId) {
      conversationMessages(
        limit: $limit
        where: {createdAt: {_lt: $createdAtCursor}}
        order_by: {createdAt: desc}
      ) {
        ...messagesFragment
      }
    }
  }

  fragment messagesFragment on messages {
    id
    uuid
    content
    contentAttributes
    senderId
    senderType
    contentType
    createdAt
    updatedAt
    messageType
    status
    sourceId
    private
    isScheduleMessage
    isAutoResponse
    uuid
    parentMessageUuid
    parentMessage {
      id
    }
    senderContact {
      email
      name
      id
    }
    senderUser {
      email
      id
      name
      uuid
    }
    messageAttachments {
      id
    }
  }
`;

const GetMessageDataForSelectedInternalChat = gql`
  query GetMessageDataForSelectedInternalChat(
    $limit: Int!
    $createdAtCursor: timestamp!
    $conversationId: bigint!
  ) {
    conversationMessagesData: conversation(id: $conversationId) {
      conversationMessages(
        limit: $limit
        where: {createdAt: {_lt: $createdAtCursor}}
        order_by: {createdAt: desc}
      ) {
        ...messagesFragment
      }
    }
  }

  fragment messagesFragment on messages {
    id
    uuid
    content
    contentAttributes
    senderId
    senderType
    contentType
    createdAt
    updatedAt
    messageType
    status
    sourceId
    private
    isScheduleMessage
    isAutoResponse
    uuid
    parentMessageUuid
    parentMessage {
      id
    }
    senderContact {
      email
      name
      id
    }
    senderUser {
      email
      id
      name
      uuid
    }
    messageAttachments {
      id
    }
  }
`;

const GetMessageDataForSelectedInternalChatWithoutMessages = gql`
  query GetMessageDataForSelectedInternalChatWithoutMessages(
    $conversationId: bigint!
    $lastLimit: Int!
  ) {
    lastMessage: conversation(id: $conversationId) {
      lastMsg: conversationMessages(
        limit: $lastLimit
        order_by: {createdAt: desc}
      ) {
        id
        uuid
      }
    }
  }
`;

const GetPrevConversationMentionMessages = gql`
  query GetPrevConversationMentionMessages(
    $id: bigint!
    $limit: Int!
    $dateTime: timestamp!
  ) {
    conversation(id: $id) {
      prevMsg: conversationMessages(
        limit: $limit
        where: {createdAt: {_lt: $dateTime}}
        order_by: {createdAt: desc}
      ) {
        ...prevConversationMentionMessage
      }
    }
  }

  fragment prevConversationMentionMessage on messages {
    id
    uuid
    content
    contentAttributes
    senderId
    senderType
    contentType
    createdAt
    updatedAt
    messageType
    status
    sourceId
    private
    uuid
    parentMessage {
      id
      content
      contentAttributes
      senderId
      senderType
      contentType
      createdAt
      messageType
      status
      sourceId
      private
      uuid
      senderContact {
        email
        name
        id
      }
      senderUser {
        email
        id
        name
      }
      messageAttachments {
        id
      }
    }
    senderContact {
      email
      name
      id
    }
    senderUser {
      email
      id
      name
    }
    messageAttachments {
      id
    }
  }
`;

const GetNextConversationMentionMessages = gql`
  query GetNextConversationMentionMessages(
    $id: bigint!
    $limit: Int!
    $dateTime: timestamp!
  ) {
    conversation(id: $id) {
      nextMsg: conversationMessages(
        limit: $limit
        where: {createdAt: {_gt: $dateTime}}
        order_by: {createdAt: asc}
      ) {
        ...nextConversationMentionMessage
      }
    }
  }

  fragment nextConversationMentionMessage on messages {
    id
    uuid
    content
    contentAttributes
    senderId
    senderType
    contentType
    createdAt
    updatedAt
    messageType
    status
    sourceId
    private
    uuid
    parentMessage {
      id
      content
      contentAttributes
      senderId
      senderType
      contentType
      createdAt
      messageType
      status
      sourceId
      private
      uuid
      senderContact {
        email
        name
        id
      }
      senderUser {
        email
        id
        name
      }
      messageAttachments {
        id
      }
    }
    senderContact {
      email
      name
      id
    }
    senderUser {
      email
      id
      name
    }
    messageAttachments {
      id
    }
  }
`;

export const GetEFaxConversations = gql`
  query GetEFaxConversationsActivity(
    $limit: Int!
    $offset: Int!
    $orderByObject: [efax_conversation_activities_order_by!]
    $whereCondition: efax_conversation_activities_bool_exp
    $conversationMessagesWhere: messages_bool_exp
    $unreadMessagesWhere: messages_bool_exp
    $unreadMessagesWhereCount: unreadMsgNew_conversations_args!
  ) {
    efaxConversationActivities(
      limit: $limit
      offset: $offset
      order_by: $orderByObject
      where: $whereCondition
    ) {
      conversations {
        contactId
        accountId
        id
        displayId
        uuid
        inboxId
        status
        additionalAttributes
        assigneeId
        createdAt
        lastActivityAt
        conversationInbox {
          channelType
          isDeleted
          id
        }
        contactInbox {
          sourceId
        }
        unreadMsgCount: unreadMsgNew(args: $unreadMessagesWhereCount)
        conversationContact {
          id
          uuid
          isDeleted
          name
          phoneNumber
          email
        }
        conversationMessages(
          limit: 1
          order_by: {createdAt: desc}
          where: $conversationMessagesWhere
        ) {
          private
          createdAt
          updatedAt
          messageType
          content
          senderUser {
            id
            name
          }
        }
        assignedUser {
          id
          uuid
          email
          name
        }
      }
      lastOutgoingEFaxAt
    }
  }
`;

const GetMessageDataBetweenTimestampsByConversationId = gql`
  query GetMessageDataBetweenTimestampsByConversationId(
    $from: timestamp!
    $to: timestamp!
    $conversationId: bigint!
  ) {
    conversationMessagesData: conversation(id: $conversationId) {
      id
      uuid
      conversationMessages(
        where: {createdAt: {_gte: $from, _lt: $to}}
        order_by: {createdAt: desc}
      ) {
        ...messagesFragment
      }
    }
  }

  fragment messagesFragment on messages {
    id
    uuid
    content
    contentAttributes
    senderId
    senderType
    contentType
    createdAt
    updatedAt
    messageType
    status
    sourceId
    private
    isScheduleMessage
    isAutoResponse
    uuid
    parentMessageUuid
    parentMessage {
      id
    }
    senderContact {
      email
      name
      id
    }
    senderUser {
      email
      id
      name
      uuid
    }
    messageAttachments {
      id
    }
  }
`;

export const GetStickNotesAndLastMessageByConversationId = gql`
  query GetStickNotesAndLastMessage(
    $lastLimit: Int!
    $conversationId: bigint!
    $contactUuid: uuid!
  ) {
    lastMessage: conversation(id: $conversationId) {
      lastMsg: conversationMessages(
        limit: $lastLimit
        order_by: {createdAt: desc}
      ) {
        id
        uuid
      }
    }
    stickyNotes(where: {contactUuid: {_eq: $contactUuid}}) {
      ...strickNotes
    }
  }

  fragment strickNotes on sticky_notes {
    noteUuid
    contactNote {
      content
    }
    updatedByUser {
      name
    }
    updatedAt
  }
`;

const GetMessageDataByMessageId = gql`
  query GetMessageDataByMessageId($id: bigint!) {
    message(id: $id) {
      id
      uuid
      content
      contentAttributes
      senderId
      senderType
      contentType
      createdAt
      updatedAt
      messageType
      status
      sourceId
      private
      isScheduleMessage
      isAutoResponse
      uuid
      parentMessageUuid
      parentMessage {
        id
      }
      senderContact {
        email
        name
        id
      }
      senderUser {
        email
        id
        name
        uuid
      }
      messageAttachments {
        id
      }
    }
  }
`;

const GetMessageDataForMultipleConversations = gql`
  query GetMessageDataForMultipleConversations(
    $limit: Int!
    $createdAtCursor: timestamp
    $conversationIds: [bigint!]!
  ) {
    conversationMessagesData: conversations(
      where: {id: {_in: $conversationIds}}
    ) {
      id
      uuid
      conversationMessages(
        limit: $limit
        where: {createdAt: {_lt: $createdAtCursor}}
        order_by: {createdAt: desc}
      ) {
        ...messagesFragment
      }
    }
  }

  fragment messagesFragment on messages {
    id
    uuid
    content
    contentAttributes
    senderId
    senderType
    contentType
    createdAt
    updatedAt
    messageType
    status
    sourceId
    private
    isScheduleMessage
    isAutoResponse
    uuid
    parentMessageUuid
    parentMessage {
      id
    }
    senderContact {
      email
      name
      id
    }
    senderUser {
      email
      id
      name
      uuid
    }
    messageAttachments {
      id
    }
  }
`;

const GetLastEmailMessageByconversationId = gql`
  query getLastEmailMessageByconversationUuid($conversationId: Int!) {
    messages(
      where: {
        conversationId: {_eq: $conversationId}
        messageType: {_in: [0, 1, 8]}
        private: {_eq: false}
      }
      limit: 1
      order_by: {createdAt: desc_nulls_last}
    ) {
      uuid
    }
  }
`;

export default {
  GetConversations,
  GetChatConversations,
  GetInboxConversations,
  GetMentionsConversations,
  GetEmailConversations,
  GetGroupConversationByUuid,
  GetInboxConversationByUuid,
  GetGroupMembersByConversationUuid,
  GetInboxMemberByInboxId,
  GetIntenalChatConversations,
  GetConversationMessages,
  GetMentionConversationMessages,
  GetConversationContactByUuid,
  GetAssignToOtherConversations,
  GetMessageDataForSelectedChat,
  GetMessageDataForSelectedConversationInbox,
  GetConversationContactByUUIDandContactUuid,
  GetConversationByUUIDandContactUuid,
  GetMessageDataForSelectedInternalChat,
  GetPrevConversationMentionMessages,
  GetNextConversationMentionMessages,
  GetEFaxConversations,
  GetMessageDataForSelectedInternalChatWithoutMessages,
  GetMessageDataBetweenTimestampsByConversationId,
  GetStickNotesAndLastMessageByConversationId,
  GetMessageDataForSelectedChatV2,
  GetMessageDataForSelectedConversationInboxV2,
  GetMessageDataByMessageId,
  GetMessageDataForMultipleConversations,
  GetLastEmailMessageByconversationId
};
