import {ICustomerDetailsProps} from '../../components/RightSideContainer/TeamInbox/Conversations/interfaces';
import {messagesDummyAafreen, messagesDummySujitha} from './messagesDummy';
import {
  detailsDummyAafreen,
  detailsDummySujitha,
} from './detailsPanelDummyData';

export const messagingListDummyData: ICustomerDetailsProps[] = [
  {
    id: 'bd7acbea-c1b1-46c2-aed5-3ad53abb28ba',
    fullName: 'Aafreen Khan',
    email: 'aafreenkhan@gmail.com',
    timeStamp: '12:47 PM',
    recentText: 'Good Day!',
    origin: 'to Brand Name via Messenger',
    conversation: messagesDummyAafreen,
    details: detailsDummyAafreen,
    phone: '9212844718',
    unread: '2',
    avatarUrl:
      'https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
  },
  {
    id: '3ac68afc-c605-48d3-a4f8-fbd91aa97f63',
    fullName: 'Sujitha Mathur',
    email: 'sujithamathur@gmail.com',
    timeStamp: '11:11 PM',
    recentText: 'Cheer up, there!',
    origin: 'to Brand Name via Messenger',
    conversation: messagesDummySujitha,
    details: detailsDummySujitha,
    phone: '9212844718',
    unread: '2',
    avatarUrl:
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyEaZqT3fHeNrPGcnjLLX1v_W4mvBlgpwxnA&usqp=CAU',
  },
  {
    id: '58694a0f-3da1-471f-bd96-145571e29d72',
    fullName: 'Sujitha Barroco',
    email: 'sujithabarroco@gmail.com',
    timeStamp: '6:22 PM',
    recentText: 'Good Day!',
    origin: 'to Brand Name via Messenger',
    conversation: messagesDummyAafreen,
    details: detailsDummyAafreen,
    phone: '9212844718',
    unread: '2',
    avatarUrl: 'https://miro.medium.com/max/1400/0*0fClPmIScV5pTLoE.jpg',
  },
  {
    id: '68694a0f-3da1-431f-bd56-142371e29d72',
    fullName: 'Aniket Kumar',
    email: 'aniketkumar@gmail.com',
    timeStamp: '8:56 PM',
    recentText: 'All the best',
    origin: 'to Brand Name via Messenger',
    conversation: messagesDummySujitha,
    details: detailsDummySujitha,
    phone: '9212844718',
    unread: '2',
    avatarUrl:
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSr01zI37DYuR8bMV5exWQBSw28C1v_71CAh8d7GP1mplcmTgQA6Q66Oo--QedAN1B4E1k&usqp=CAU',
  },
  {
    id: '28694a0f-3da1-471f-bd96-142456e29d72',
    fullName: 'Kiara',
    email: 'kiara@gmail.com',
    timeStamp: '12:47 PM',
    recentText: 'I will call today.',
    origin: 'to Brand Name via Messenger',
    conversation: messagesDummyAafreen,
    details: detailsDummySujitha,
    phone: '9212844718',
    unread: '2',
    avatarUrl:
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRBwgu1A5zgPSvfE83nurkuzNEoXs9DMNr8Ww&usqp=CAU',
  },
];
