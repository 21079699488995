import {HStack, Spacer, Switch, Text, View} from 'native-base';
import React, {useEffect, useState} from 'react';
import {IPAMIProps} from '../../interfaces';
import {Skeleton, VStack} from 'native-base';
import ActivitiesGraphView, { GRAPH_RENDER_MODE } from './ActivitiesGraphView/ActivitiesGraphView';
import { getActivityFormattedData, getDateRange, getFormattedActivityData, getTodaysDateRange } from './ActivityUtils';
import { CARE_PROGRAM_TYPES } from '../../../../../../constants/MlovConst';
import useLatestCareProgramEnrollmentDate from '../../../../LeftContainer/PamiDetail/hooks/useCareProgramEnrollmentDate';
import { FlatList } from 'react-native';
import { Empty } from 'antd';

export enum GRAPH_TYPES {
  Calories = 'CALORIES',
  Step = 'STEPS',
  Distance = 'DISTANCE',
  Time = 'DURATION',
  Form = 'form'
}

enum ACTIVITY_GRAPH_TITLE {
  CaloriesBurned = 'Calories Burned',
  StepsCount = 'Steps',
  Distance = 'Distance',
  Time = 'Activity Duration'
}


export enum ActivityLoincCodes {
  DAILY_ACTIVITY= '79534-4',
  EXERCISE_ACTIVITY= '73985-4',
  CALORIES ='93822-5',
  DAILY_STEPS = '41950-7',
  DURATION = '55411-3',
}
export const getGraphTitle = (type: string) => {
  switch (type) {
    case GRAPH_TYPES.Calories:
      return ACTIVITY_GRAPH_TITLE.CaloriesBurned;
    case GRAPH_TYPES.Step:
      return ACTIVITY_GRAPH_TITLE.StepsCount;
    case GRAPH_TYPES.Time:
      return ACTIVITY_GRAPH_TITLE.Time;
    default:
      return '';
  }
};

export const getActivitiesGraphTitle = getGraphTitle;

interface IActivitiesProps {
  patientId?: string;
  personData?: any;
  accountLocationUuid?: string;
  contactId?: string;
  isSmallView?: boolean;
  activityData?: any[];
  selectedParentFilterId?: string;
}

const Activities = (props: IActivitiesProps) => {
  const patientUuid = props.patientId;
  const accountLocationUuid = props.accountLocationUuid;

  const [loading, setLoading] = useState(false);
  const [showAutomatic, setShowAutomatic] = useState(false);
  const [visibleGraphTypes, setVisibleGraphTypes] = useState( [
    GRAPH_TYPES.Calories,
    GRAPH_TYPES.Step,
    GRAPH_TYPES.Time
  ]);

  const defaultRange = getTodaysDateRange()

  const {date: ccmDate, loading: ccmDateLoading} = useLatestCareProgramEnrollmentDate({
    type: CARE_PROGRAM_TYPES.CCM,
    contactId: props?.contactId || '',
  });

  const getActivityDataByType = (type: string) => {
    if (!props.activityData) {
      return [];
    }
    return getFormattedActivityData(props.activityData || [], type);
  };

  useEffect(()=> {
    if (props.activityData && props.selectedParentFilterId) {
      const availableGraphTypes: GRAPH_TYPES[] = [];
      visibleGraphTypes.forEach((graphType) => {
        const activityDataByType = getActivityDataByType(graphType);
        const range = getDateRange(props.selectedParentFilterId || '');
        const formattedActivityData = getActivityFormattedData(range?.startDate, range?.endDate, activityDataByType, showAutomatic) || []
        if (formattedActivityData.length > 0) {
          availableGraphTypes.push(graphType);
        }
      });
      setVisibleGraphTypes(availableGraphTypes);
    }
  }, [props.activityData])

  return (
    <View flex={1}>
      {loading || ccmDateLoading  ? (
        <Skeleton.Text lines={4} />
      ) : (
        <>
          {/* <HStack space={3}>
            <Spacer></Spacer>
            <Text>{'All'}</Text>
            <Switch
              onValueChange={(isChecked) => {
                setShowAutomatic(isChecked)
              }}
            ></Switch>
            <Text>{'Automatic'}</Text>
          </HStack> */}
          <VStack flex={1} space={2}>
            {visibleGraphTypes.length > 0 ? (
              <FlatList
                data={visibleGraphTypes}
                key={'activities_flat_list'}
                numColumns={props.isSmallView ? 2 : 1}
                renderItem={({item, index}) => {
                  return (
                    <ActivitiesGraphView
                      key={index}
                      title={getGraphTitle(item)}
                      type={item}
                      isSmallView={props.isSmallView}
                      range={defaultRange}
                      patientId={patientUuid}
                      accountLocationUuid={accountLocationUuid}
                      showAutomatic={showAutomatic}
                      renderMode={GRAPH_RENDER_MODE.VICTORY}
                      ccmDate={ccmDate}
                      selectedParentFilterId={props.selectedParentFilterId}
                      activityData={getActivityDataByType(item)}
                    />
                  );
                }}
              ></FlatList>
            ) : (
              <Empty />
            )}
          </VStack>
        </>
      )}
    </View>
  );
};

export default Activities;
