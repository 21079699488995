import {StyleSheet} from 'react-native';
import {Colors} from '../../../../../../styles';

export const styles = StyleSheet.create({
  commonContainer: {
    marginBottom: 5,
    marginTop: 5,
    padding: 15,
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: 10,
    // backgroundColor: 'yellow',
    justifyContent: 'space-between',
  },
  rightContainer: {
    flex: 1,
    padding: 10,
    // backgroundColor: 'blue',
  },
  formContainer: {
    alignItems: 'center',
    paddingHorizontal: 15,
  },
});
