import React, {useState} from 'react';
import {COMMON_ACTION_CODES, CONVERSATION_ACTION_CODES} from '../../../../../../../../../constants/ActionConst';
import ConversationMainView from '../../../../../../../../common/ConversationView/ConversationMainView';
import {IFeedData} from '../../interface';

const ActivityConversationMessages = (props: {
  singleFeedData: IFeedData,
  onActionPerformed?: (actionCode: string, actionData?: any) => void;
  navigation?: any,
}) => {
  const {singleFeedData, onActionPerformed} = props;
  const [componentState, setComponentState] = useState({
    isDrawerVisible: true,
  });
  let singleConversationData = {} as any;
  try {
    const feedData = singleFeedData.data;
    const crmConversationMessagingData = JSON.parse(feedData);
    singleConversationData = crmConversationMessagingData?.crmData?.conversationResp?.data?.conversations[0] || {};
  } catch (err) {
    singleConversationData = {};
  }

  const onConversationMainViewActionPerformed = (actionCode: string, actionData: any) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.CLOSE_MODAL:
      case CONVERSATION_ACTION_CODES.UPDATE_TO_CONTACT:
        onActionPerformed?.(COMMON_ACTION_CODES.CLOSE_MODAL);
        setComponentState((prev) => {
          return {
            ...prev,
            isDrawerVisible: false
          }
        });
        break;
    }
  }

  const getMessagingListElem = (): JSX.Element => {
    const feedData = singleFeedData.data;
    let crmConversationMessagingData = {};
    try {
      const crmConversationMessagingData = JSON.parse(feedData);
      const singleConversationData = crmConversationMessagingData?.crmData?.conversationResp?.data?.conversations[0] || {};
      return (
        <>
          {
            // isWeb() &&
            <>
              {
                componentState.isDrawerVisible &&
                <ConversationMainView
                  conversationUuid={singleConversationData.uuid}
                  isDrawerVisible={componentState.isDrawerVisible}
                  onActionPerformed={onConversationMainViewActionPerformed}
                />
              }
            </>
          }

        </>
      );
    } catch (error) {
      crmConversationMessagingData = {};
    }
    return <></>

  };
  if (singleConversationData?.uuid) {
    return getMessagingListElem()

  } else {
    return <></>;
  }
};

export default React.memo(ActivityConversationMessages);
