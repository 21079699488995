import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  checkBtn: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkBtnIcon: {
    padding: 4,
    borderRadius: 30,
  },
  taskTitleStack: {
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 10,
  },
  headerText: {
    margin: 2,
    fontSize: 12,
  },
  selectContainer: {
    height: 30,
  },
  taskTitleText: {
    fontWeight: '600',
    fontSize: 20,
  },
  ml10: {
    marginLeft: 10,
  },
  alignCenter: {
    alignItems: 'center',
  },
});
