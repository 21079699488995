import {Formio} from '@foldhealth/formiojs';
import BasicFields from '../EditFormFields/BasicFields';
import ConditionalComponents from '../EditFormFields/ConditionalFields';
import KeyField from '../EditFormFields/KeyField';

const inputField = Formio.Components.components.oldtextfield;

Formio.Components.components.email.schema = (...extend: any) => {
  return inputField.schema(
    {
      type: 'email',
      label: 'Email',
      key: 'email',
      inputType: 'email',
      kickbox: {
        enabled: false,
      },
      validate: {
        minLength: '',
        maxLength: '',
        pattern: '',
        customMessage: '',
      },
    },
    ...extend
  );
};

Formio.Components.components.email.editForm = function () {
  return {
    key: 'display',
    components: [
      ...BasicFields,
      // KEPT FOR FUTURE
      // {
      //   type: 'checkbox',
      //   label: 'Hide Label',
      //   tooltip:
      //     'Hide the label or title of this component. This allows you to show the label in the form builder, but not when the form is shown to the member.',
      //   key: 'hideLabel',
      //   input: true,
      // },
      ...ConditionalComponents,
      ...KeyField,
    ],
  };
};
