import {View, Text, Pressable} from "react-native"
import {Colors} from "../../../../../styles"
import InfoSvg from "../../../../common/Svg/InfoSvg"
import AntDesign from "react-native-vector-icons/AntDesign"
import {useIntl} from "react-intl"
import {useMediaQuery} from "native-base"
import {SMALL_WINDOW} from "../../../../../constants"
import { TestIdentifiers, testID } from "../../../../../testUtils"
import { StyleSheet } from "react-native"

export const PhiInfo = (props: {onClose: ()=> void, isMentionTab: boolean})=> {
  const intl = useIntl();
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    { maxWidth: SMALL_WINDOW }
  ]);

  const isSmallScreen = isIPadMiniScreen || isIPadScreen;
  return (
    <View style={[
      styles.container,
      props?.isMentionTab ? null : styles.borderTop
    ]}>
      <View style={styles.iconContainer} {...testID('image')}>
        <InfoSvg customColor={Colors.Custom.Gray500} customDimension={'20'} />
      </View>
      <Text 
        {...testID(TestIdentifiers.phiWarning)} 
        style={[
          styles.warningText,
          isSmallScreen ? styles.smallText : styles.normalText
        ]}
      >
        {intl.formatMessage({id:'phiWarning'})}
      </Text>
      <View style={styles.closeButtonContainer}>
        <Pressable {...testID(TestIdentifiers.closeBtn)} onPress={props?.onClose}>
          <AntDesign color={Colors.Custom.Gray400} size={18} name="close" {...testID('image')}/>
        </Pressable>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    backgroundColor: Colors.Custom.Gray50,
    padding: 6,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopWidth: 0,
    borderColor: Colors.Custom.Gray200,
  },
  borderTop: {
    borderTopWidth: 1,
  },
  iconContainer: {
    marginHorizontal: 10,
  },
  warningText: {
    color: Colors.Custom.Gray500,
    fontWeight: '500',
    lineHeight: 21,
  },
  smallText: {
    fontSize: 12,
  },
  normalText: {
    fontSize: 14,
  },
  closeButtonContainer: {
    flex: 1,
    flexDirection: 'row-reverse',
  },
});