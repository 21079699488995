import React from 'react';
import ReactDOM from 'react-dom';
import {ReactComponent} from '@foldhealth/formio-react';
import {Formio} from '@foldhealth/formiojs';
import CustomWrapper from '../CustomWrapper/CustomWrapper';
import KeyField from '../../EditFormFields/KeyField';
import ConditionalFields from '../../EditFormFields/ConditionalFields';
import BasicFields from '../../EditFormFields/BasicFields';
import CustomClassComponent from '../CustomWrapper/CustomClassComponent';
import QuillConfig from '../../Builder/QuillConfig';
import DatePicker from './DatePicker';
import { DATE_FORMATS } from '../../../../../../constants/StringConst';

export default class DatePickerComponent extends CustomClassComponent {
  static get builderInfo() {
    return {
      title: 'Date',
      icon: 'calendar',
      group: 'advanced',
      documentation: '',
      schema: DatePickerComponent.schema(),
    };
  }

  labelIsHidden() {
    return false;
  }

  static schema() {
    return ReactComponent.schema({
      type: 'date',
      label: 'Date',
      key: 'date'
    });
  }

  attachReact(element: any) {
    return ReactDOM.render(
      <CustomWrapper formOptionData={this.options}>
        <DatePicker
          options={this.options}
          disabled={this.disabled}
          component={this.component}
          validateRef={this.validateRef}
          onChange={this.updateValue}
          setValueRef={this.setValueRef}
        />
      </CustomWrapper>,
      element
    );
  }

  static editForm = function () {
    return {
      key: 'display',
      components: [
        ...BasicFields,
        // {
        //   type: 'oldtextfield',
        //   input: true,
        //   key: 'format',
        //   label: 'Format',
        //   placeholder: 'Format',
        //   defaultValue: DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT,
        //   disabled: true,
        //   tooltip: 'The format for displaying the date.',
        // },
        {
          type: 'formDatePicker',
          input: true,
          key: 'minDate',
          label: 'Minimum Date',
          format: DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT,
          tooltip: 'The minimum date that can be picked.',
        },
        {
          type: 'formDatePicker',
          input: true,
          key: 'maxDate',
          label: 'Maximum Date',
          format: DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT,
          tooltip: 'The maximum date that can be picked.',
        },
        ...ConditionalFields,
        ...KeyField,
      ],
    };
  };
}

Formio.Components.addComponent('date', DatePickerComponent);
