import {StyleSheet} from 'react-native';

export const interventionSvgStyles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
    margin: 15,
  },
  leftSvgStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
    margin: 15,
    marginBottom: 5,
    marginTop: 5,
    paddingLeft: 0, 
    paddingRight: 5, 
    marginLeft: 0
  }
});
