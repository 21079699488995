import {PatientContact} from 'fhir/r4';
import {
  canCommunicateWithInactivePatient,
  getSecondaryValue,
  isActiveContact,
  isConsentRequiredAndGiven,
  isEmployerRole,
} from '../../../../utils/commonUtils';
import {isAccountConfigEnabled} from '../../../../utils/configUtils';
import {CONFIG_CODES} from '../../../../constants/AccountConfigConst';
import {useContext, useState} from 'react';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {
  IContact,
  IConversationData,
} from '../../../RightSideContainer/TeamInbox/Conversations/interfaces';
import {TELECOM_SYSTEM} from '../../../../constants/FhirConstant';
import {
  MAIN_MENU_CODES,
  USER_ROLE_SIDE_BAR_CODES_PERMISSION_WISE,
} from '../../../SideMenuBar/SideBarConst';
import {PERSON_ACTION_CODES} from '../../../PersonOmniView/PersonHeaderBar/PersonAction/PersonActionPopover/ActionConst';
import {
  COMMON_ACTION_CODES,
  CONVERSATION_ACTION_CODES,
} from '../../../../constants/ActionConst';
import {EventBus} from '../../../../utils/EventBus';
import {
  RIGHT_SIDE_CONTAINER_CODE,
  getInActiveContactError,
} from '../../../../constants';
import {getToolTipMessage} from '../../../../utils/PersonActionsUtils';
import {patientNotLoggedInError} from '../../../RightSideContainer/TeamInbox/Conversations/ConversationContainer/ConversationConst';
import {View} from 'react-native';
import {Colors} from '../../../../styles';
import Stack from '../../LayoutComponents/Stack';
import {EmailDrawerCommonV2} from '../../EmailDrawerCommonV2';
import CreateSmsConversationDrawer from '../../../RightSideContainer/TeamInbox/Conversations/ConversationChannelTabs/CreateSmsConversationDrawer/CreateSmsConversationDrawer';
import {SMS_INBOX_ACTION_CODES} from '../../../RightSideContainer/TeamInbox/Integrations/IntegrationCreate/SmsInboxCreate/SmsInboxConst';
import InstantChat from '../../ChatDrawer/InstantChat';
import MeetingView from '../../MeetingView/MeetingView';
import {ContactRelationCommunicationView} from './ContactRelationCommunicationView';
import DeleteIconSvg from '../../../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/DeleteIconSvg';
import CallActionSvg from '../../Svg/PersonActionSvgIcons/CallActionSvg';
import EmailActionSvg from '../../Svg/PersonActionSvgIcons/EmailActionSvg';
import SmsActionSvg from '../../Svg/PersonActionSvgIcons/SmsActionSvg';
import VideoActionSvg from '../../Svg/PersonActionSvgIcons/VideoActionSvg';
import {getFormDataFromLeadData} from '../../../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateUtils';
import ChatActionSvg from '../../Svg/PersonActionSvgIcons/ChatActionSvg';
 import Feather from 'react-native-vector-icons/Feather';
import {CallIconSvg} from '../../../../assets/Icons/CallIconSvg';
import {ChatIconSvg} from '../../../../assets/Icons/ChatIconSvg';
import {SmsIconSvg} from '../../../../assets/Icons/SMSIconSvg';
import {VideoCallIconSvg} from '../../../../assets/Icons/VideoCallIconSvg';
import {EmailIconSvg} from '../../../../assets/Icons/EmailIconSvg';
import { isCallsAllowed } from '../../../RightSideContainer/UserAccess/UserAccessPermission';

interface IContactRelationCommunicationState {
  selectedDrawer?: string;
  isOutboundViewVisible?: boolean;
  selectedConversation: IConversationData;
}

interface IContactRelationCommunicationProps {
  contact?: IContact;
  contactType?: any;
  handleActions: (action: string) => void;
  isEmergencyContact?: boolean;
  emergencyContact?: PatientContact;
}

export const ContactRelationCommunication = (
  props: IContactRelationCommunicationProps
) => {
  const isEmployer = isEmployerRole();

  const isPatientMessagingEnabled = isAccountConfigEnabled(
    CONFIG_CODES.PATIENT_MESSAGING_ENABLED
  );

  const {contact, handleActions, isEmergencyContact, emergencyContact} = props;
  const commonData = useContext(CommonDataContext);
  const contactActiveStatus = isActiveContact(contact);
  const isCallAllowed = isCallsAllowed();
  const isPatientLoggedIn = contact?.additionalAttributes?.loggedInTime
    ? true
    : false;

  const formattedContactData = getFormDataFromLeadData(
    contact || {},
    commonData
  );

  const email =
    contact?.email || getSecondaryValue(contact?.personContact, 'email') || '';
  const phoneNumber =
    contact?.phoneNumber ||
    getSecondaryValue(contact?.personContact, 'phone') ||
    '';

  let emergencyPhoneNumber: string | undefined;

  if (emergencyContact && isEmergencyContact) {
    emergencyPhoneNumber = emergencyContact.telecom?.find(
      (telecom) => telecom?.system == TELECOM_SYSTEM.PHONE
    )?.value;
  }


  const [relationListState, setRelationListState] =
    useState<IContactRelationCommunicationState>({
      selectedDrawer: '',
      selectedConversation: {} as IConversationData,
    });

  const shouldShowChat = () => {
    if (
      USER_ROLE_SIDE_BAR_CODES_PERMISSION_WISE.USER.includes(
        MAIN_MENU_CODES.INBOX
      ) &&
      contact?.patient?.id &&
      contact?.uuid &&
      isPatientLoggedIn &&
      isPatientMessagingEnabled
    ) {
      return true;
    } else {
      return false;
    }
  };

  const onActionPerformed = (action: string, rowData?: any) => {
    switch (action) {
      case PERSON_ACTION_CODES.DELETE:
        handleActions?.(action);
        break;
      case COMMON_ACTION_CODES.CLOSE_MODAL:
        setRelationListState((prev) => ({
          ...prev,
          isOutboundViewVisible: false,
        }));
        break;
      case COMMON_ACTION_CODES.CALL:
        if (!isCallAllowed) {
          return
        }
        if (rowData) {
          const eventBus = EventBus.getEventBusInstance();
          eventBus.broadcastEvent('CLOUD_CALL', {
            contactData: contact,
            fromNumber: rowData.fromNumber,
            toNumber: emergencyPhoneNumber || contact?.phoneNumber,
          });
          setRelationListState((prev) => ({
            ...prev,
            isOutboundViewVisible: false,
          }));
        } else {
          setRelationListState((prev) => ({
            ...prev,
            isOutboundViewVisible: true,
          }));
        }
        break;
      case PERSON_ACTION_CODES.EMAIL:
        setRelationListState((prev) => ({
          ...prev,
          selectedDrawer: RIGHT_SIDE_CONTAINER_CODE.CONTACT_MAIL_POPUP,
        }));

        break;
      case PERSON_ACTION_CODES.SMS:
        setRelationListState((prev) => ({
          ...prev,
          selectedDrawer: RIGHT_SIDE_CONTAINER_CODE.CONTACT_SMS_DRAWER,
        }));
        break;

      case PERSON_ACTION_CODES.CREATE_MEETING:
        setRelationListState((prev) => ({
          ...prev,
          selectedDrawer: RIGHT_SIDE_CONTAINER_CODE.CREATE_MEETING_VIEW,
        }));
        break;

      case PERSON_ACTION_CODES.CHAT:
        setRelationListState((prev) => ({
          ...prev,
          selectedDrawer: CONVERSATION_ACTION_CODES.CHAT_WITH_PATIENT,
        }));
        break;
    }
  };

  const clearContactDrawerSelection = () => {
    setRelationListState((prev) => ({
      ...prev,
      selectedDrawer: '',
      selectedRelatedContact: null,
      selectedConversation: {} as IConversationData,
    }));
  };

  const getTooltipMessageText = (action: string) => {
    switch (action) {
      case PERSON_ACTION_CODES.SMS:
        return !canCommunicateWithInactivePatient(contact)
          ? getInActiveContactError(contact)
          : getToolTipMessage('SMS', phoneNumber, email, contact as IContact);
      case PERSON_ACTION_CODES.EMAIL:
        return !canCommunicateWithInactivePatient(contact)
          ? getInActiveContactError(contact)
          : getToolTipMessage('EMAIL', phoneNumber, email, contact as IContact);
      case PERSON_ACTION_CODES.CREATE_MEETING:
        return !contactActiveStatus
          ? getInActiveContactError(contact)
          : getToolTipMessage('VCALL', phoneNumber, email, contact as IContact);
      case PERSON_ACTION_CODES.CALL:
        if (isEmergencyContact) {
          if (!emergencyPhoneNumber) {
            return 'Phone Number not set, please add phone number to take any action';
          }
          return 'Call';
        }
        return !contactActiveStatus
          ? getInActiveContactError(contact)
          : getToolTipMessage('CALL', phoneNumber, email, contact as IContact);
      case PERSON_ACTION_CODES.CHAT:
        return isPatientLoggedIn
          ? !contactActiveStatus
            ? getInActiveContactError(contact)
            : 'Chat with patient'
          : patientNotLoggedInError;
    }
  };

  const getActions = () => {
    const actions: any[] = [];

    if (isEmergencyContact) {
      actions.push({
        btnText: 'Call',
        action: PERSON_ACTION_CODES.CALL,
        isDisabled: !emergencyPhoneNumber,
        showHorizontal: true,
        tooltip: getTooltipMessageText(PERSON_ACTION_CODES.CALL),
        icon: (
          <CallIconSvg isActive={emergencyPhoneNumber ? true : false} />
        ),
      });

      return actions;
    }

    const isCommuniCateWithInActivePatient =
      canCommunicateWithInactivePatient(contact);
    const consentCheck = isConsentRequiredAndGiven(contact);
    const enableChat = contactActiveStatus && isPatientLoggedIn;
    const enableSendEmail =
      isCommuniCateWithInActivePatient && email && consentCheck;
    const enableSendSMS =
      isCommuniCateWithInActivePatient && phoneNumber && consentCheck;
    const enableVideoCall =
      contactActiveStatus && consentCheck && (email || phoneNumber);
    const enablePhoneCall = contactActiveStatus && consentCheck && phoneNumber;

    if (shouldShowChat()) {
      actions.push({
        btnText: 'Chat',
        isDisabled: !enableChat,
        tooltip: getTooltipMessageText(PERSON_ACTION_CODES.CHAT),
        action: PERSON_ACTION_CODES.CHAT,
        showHorizontal: true,
        icon: (
          <View style={{width: 20, height: 20}}>
          <ChatIconSvg isActive={enableChat} />
        </View>
        ),
      });
    }

    actions.push({
      btnText: 'Call',
      action: PERSON_ACTION_CODES.CALL,
      isDisabled: !enablePhoneCall,
      showHorizontal: true,
      tooltip: getTooltipMessageText(PERSON_ACTION_CODES.CALL),
      icon: (
        <View style={{width: 20, height: 20 , marginTop: 1}}>
          <CallIconSvg isActive={enablePhoneCall} />
        </View>
      ),
    });

    actions.push({
      btnText: 'Email',
      isDisabled: !enableSendEmail,
      tooltip: getTooltipMessageText(PERSON_ACTION_CODES.EMAIL),
      action: PERSON_ACTION_CODES.EMAIL,
      icon: <EmailIconSvg isActive={enableSendEmail} />,
    });

    actions.push({
      btnText: 'SMS',
      action: PERSON_ACTION_CODES.SMS,
      tooltip: getTooltipMessageText(PERSON_ACTION_CODES.SMS),
      isDisabled: !enableSendSMS,
      icon: <SmsIconSvg isActive={enableSendSMS} />,
    });

    actions.push({
      btnText: 'Video Call',
      action: PERSON_ACTION_CODES.CREATE_MEETING,
      isDisabled: !enableVideoCall,
      tooltip: getTooltipMessageText(PERSON_ACTION_CODES.CREATE_MEETING),
      icon: <VideoCallIconSvg isActive={enableVideoCall} />,
    });

    return actions;
  };

  const getExtraActions = () => {
    const extraActions: any[] = [];

    if (!isEmployer) {
      extraActions.push({
        btnText: 'Delete',
        action: PERSON_ACTION_CODES.DELETE,
        btnTextColor: Colors.Custom.ErrorColor,
        tooltip: 'Delete Relation',
        icon: <DeleteIconSvg />,
      });
    }

    return extraActions;
  };

  return (
    <Stack direction="column">
      <ContactRelationCommunicationView
        onActionPerformed={onActionPerformed}
        actions={getActions()}
        contact={contact}
        handleActions={onActionPerformed}
        isOutboundViewVisible={relationListState.isOutboundViewVisible}
      />

      {relationListState.selectedDrawer ===
        RIGHT_SIDE_CONTAINER_CODE.CONTACT_MAIL_POPUP && (
        <EmailDrawerCommonV2
          isOpen={true}
          onClose={() => {
            clearContactDrawerSelection();
          }}
          onEmailSent={() => {
            clearContactDrawerSelection();
          }}
          contactIds={contact?.id ? [contact?.id] : []}
        />
      )}

      {relationListState.selectedDrawer ===
        RIGHT_SIDE_CONTAINER_CODE.CONTACT_SMS_DRAWER && (
        <CreateSmsConversationDrawer
          isDrawerVisible={true}
          selectedInbox={{} as any}
          onCreateSmsConversationActionPerformed={(
            actionCode: string,
            actionData: any
          ) => {
            // setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW);
            if (
              actionCode == SMS_INBOX_ACTION_CODES.DRAWER_CLOSE &&
              !actionData
            ) {
              clearContactDrawerSelection();
            } else {
              setRelationListState((prev) => {
                return {
                  ...prev,
                  selectedDrawer:
                    RIGHT_SIDE_CONTAINER_CODE.SHOW_CONVERSATION_DRAWER,
                  selectedConversation:
                    actionData?.selectedInboxConversationData
                      ?.conversations?.[0],
                };
              });
            }
          }}
          selectedPatient={contact}
        />
      )}

      {relationListState.selectedDrawer ===
        RIGHT_SIDE_CONTAINER_CODE.CREATE_MEETING_VIEW && (
        <MeetingView
          personData={formattedContactData}
          onPersonActionPerformed={(actionCode: any) => {
            if (actionCode == COMMON_ACTION_CODES.CANCEL) {
              clearContactDrawerSelection();
            }
          }}
        />
      )}
      {relationListState.selectedDrawer ===
        CONVERSATION_ACTION_CODES.CHAT_WITH_PATIENT &&
        contact?.uuid &&
        contact?.patient?.id && (
          <InstantChat
            contactData={contact}
            contactUuid={contact?.uuid}
            isDrawerVisible={
              relationListState.selectedDrawer ===
              CONVERSATION_ACTION_CODES.CHAT_WITH_PATIENT
            }
            onActionPerformed={(actionCode: string) => {
              if (actionCode === COMMON_ACTION_CODES.CLOSE_MODAL) {
                clearContactDrawerSelection();
              }
            }}
          />
        )}
    </Stack>
  );
};
