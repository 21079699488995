import {Drawer} from 'antd';
import {Spinner, Text, useToast, View, VStack} from 'native-base';
import React, { useContext } from 'react';
import {useIntl} from 'react-intl';
import {BUTTON_TYPE} from '../../../../../constants';
import {Colors} from '../../../../../styles';
import {showToast, ToastType} from '../../../../../utils/commonViewUtils';
import {ModalActionTitle} from '../../../ModalActionTitle/ModalActionTitle';

import {getReadMessageEntriesInMessageLastSeen} from './MessageReadInfoService';
import {IMessageReadInfo} from '../interfaces';
import {IMessageReadInfoResp, IMessageReadInfoState} from './interface';
import {MessageReadInfoItem} from './MessageReadInfoItem/MessageReadInfoItem';
import {sortMemberByTypeForMessageLastSeen} from './MessageReadUtils';
import { CommonDataContext } from '../../../../../context/CommonDataContext';
import { getUserUUID } from '../../../../../utils/commonUtils';

export const MessageReadInfo = (props: IMessageReadInfo) => {
  const abortControllerRef = React.useRef<AbortController>(
    new AbortController()
  );
  const [state, setState] = React.useState<IMessageReadInfoState>({
    isLoading: true,
    showDrawer: true,
    seenByUsers: {} as IMessageReadInfoResp,
    deliveredToUsers: {} as IMessageReadInfoResp,
  });
  const toast = useToast();
  const intl = useIntl();
  const commonData = useContext(CommonDataContext);
  const isSideCarContext = commonData.sidecarContext?.isSidecar;
  const userUuid = getUserUUID();

  const getSeenByUsersData = async () => {
    setState((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });
    try {
      const messageResResp = await getReadMessageEntriesInMessageLastSeen(props?.conversationUuid, props?.messageUuid, abortControllerRef?.current?.signal)
      const filterUserList = messageResResp?.deliveredToUsers?.userList?.filter(
        (item) => item?.groupUserId !== userUuid
      );
      const deliveredToUsersList = {
        ...messageResResp?.deliveredToUsers,
        userList: filterUserList,
      };
      setState((prev) => {
        return {
          ...prev,
          seenByUsers: messageResResp?.seenByUsers || {},
          deliveredToUsers: deliveredToUsersList || {},
          isLoading: false,
        };
      });
    } catch (error) {
      handleError(error);
    }
  };

  // const updateLastSeenDataOnMessageRead = async (data: any) => {
  //   if (!(props.conversationUuid === data?.data?.conversationUuid)) {
  //     return;
  //   }
  //   try {
  //     const lastSeenData = await getLastSeenByUsers();
  //     setState((prev) => {
  //       const groupMembersUserIds = (prev.groupMembers || []).map(
  //         (item) => item.groupUserId
  //       );
  //       const seenByUsersData = isSeen(
  //         lastSeenData,
  //         groupMembersUserIds,
  //         props.messageDate,
  //         '',
  //         true,
  //         {
  //           groupConversation: {groupMembers: prev.groupMembers},
  //         } as IConversationData,
  //         isSinglePatientReadReceiptEnabled
  //       );
  //       const {deliveredToUsers, seenByUsers} =
  //         getSeenByAndDeliveredToMemberList(
  //           prev.filterSenderMemberList,
  //           seenByUsersData
  //         );
  //       const seenByUsersList = (seenByUsersData || []).filter((item: any) => {
  //         return !(
  //           userUuid === item.memberId || props.senderId === item.memberId
  //         );
  //       });
  //       return {
  //         ...prev,
  //         seenByUsers,
  //         deliveredToUsers,
  //         seenByUsersList,
  //       };
  //     });
  //   } catch (error) {
  //     handleError(error);
  //   }
  // };

  const handleError = (error: any) => {
    showToast(toast, intl.formatMessage({id: 'apiErrorMsg'}), ToastType.error);
    setState((prev) => {
      return {
        ...prev,
        seenByUsers: {} as any,
        deliveredToUsers: {} as any,
        isLoading: false,
      };
    });
  };


  React.useEffect(() => {
    // const eventBus = EventBus.getEventBusInstance();
    // eventBus.addEventListener(
    //   WEB_SOCKET_EVENT_CODE.MESSAGE_READ,
    //   updateLastSeenDataOnMessageRead
    // );
    getSeenByUsersData();

    return () => {
      // eventBus.removeEventListener(updateLastSeenDataOnMessageRead);
    };
  }, [props.conversationUuid]);

  return (
    <>
      <Drawer
        destroyOnClose
        placement="right"
        onClose={() => {
          setState((prev) => {
            return {
              ...prev,
              showDrawer: false,
            };
          });
          props.onCancel();
        }}
        visible={state.showDrawer}
        width={isSideCarContext ? '100%' : 550}
        mask={isSideCarContext ? false : true}
        title={
          <ModalActionTitle
            title={'seenInfo'}
            titleColor={''}
            buttonList={[
              {
                show: true,
                id: 1,
                btnText: isSideCarContext ? 'back' : 'cancel',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
                isTransBtn: false,
                onClick: () => {
                  {
                    setState((prev) => {
                      return {
                        ...prev,
                        showDrawer: false,
                      };
                    });
                    props.onCancel();
                  }
                },
              },
            ]}
          />
        }
      >
        <>
          {state.isLoading ? (
            <View
              flex={1}
              style={{
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Spinner size={'sm'} />
            </View>
          ) : (
            <VStack>
              <Text fontSize={20}>{'Seen By'}</Text>
              <MessageReadInfoItem
                parentCode={'MESSAGE_READ'}
                agentsData={[]}
                messageReadUserData={sortMemberByTypeForMessageLastSeen(state?.seenByUsers || [])}
                height={720}
                remainingCount={state?.deliveredToUsers?.userList?.length || 0}
              />
              <View style={{marginTop: 20}}>
                <Text fontSize={20}>{'Sent To'}</Text>
                <MessageReadInfoItem
                  parentCode={'MESSAGE_READ'}
                  messageReadUserData={sortMemberByTypeForMessageLastSeen(state?.deliveredToUsers || [])}
                  agentsData={[]}
                  height={720}
                />
              </View>
            </VStack>
          )}
        </>
      </Drawer>
    </>
  );
};
