import React, {useMemo} from 'react';
import {ModalActionAntSelect} from '../../../../common/ModalActionCommonComponent/ModalActionAntSelect';
import {useIntl} from 'react-intl';
import {IMlov} from '../../../../../Interfaces';
import {StyleSheet} from 'react-native';

interface ICareTeamTypeUserSelectProps {
  value?: string;
  options: IMlov[];
  onChange: (value: string) => void;
  disabled?: boolean;
}

const CareTeamTypeUserSelect = ({
  value,
  onChange,
  options,
  disabled
}: ICareTeamTypeUserSelectProps) => {
  const intl = useIntl();

  return (
    <ModalActionAntSelect
      label={intl.formatMessage({
        id: 'selectRole',
      })}
      value={value}
      optionProps={{key: 'id', value: 'id', label: 'value'}}
      style={{width: '100%', flex: 1}}
      extraStyle={{
        flex: 1,
      }}
      labelInValue
      disabled={disabled}
      placeholder={'Select Care Team Role'}
      onChange={(item: IMlov) => {
        onChange(item.value);
      }}
      data={options}
    />
  );
};

export default CareTeamTypeUserSelect;
