import { View, Text, StyleSheet } from 'react-native'
import React, { useContext, useState } from 'react'
import { IContactCareProgram } from './interface'
import { CommonDataContext } from '../../../context/CommonDataContext';
import { getMlovListFromCategory } from '../../../utils/mlovUtils';
import { MLOV_CATEGORY, RIGHT_SIDE_CONTAINER_CODE } from '../../../constants';
import { CARE_PROGRAM_COMPLEXITY_STATUS_CODES, CARE_PROGRAM_TYPE_CODES, CareProgramComplexityCode } from '../../../constants/MlovConst';
import Stack from '../LayoutComponents/Stack';
import { Colors } from '../../../styles';
import { CareProgramStepWiseView } from '../MemebersView/CareProgramStepWiseView';
import { IContactCareProgramResponse } from '../../../services/ContactCareProgram/interface';
import { CARE_PROGRAM_STEP_TYPE, PROGRAM_STEPS_FILTER_CODE } from '../MemebersView/constant';
import { ICondition } from '../../RightSideContainer/Forms/FHFormio/CustomComponents/Conditions/interfaces';
import { chronicCondtionsView } from '../../RightSideContainer/Contacts/Leads/LeadView/LeadTableView/Helper/LeadListCommonViews';
import {useCareProgramDeclinedInfo} from '../MemebersView/customHook/useCareProgramDeclinedInfo';
import {HStack, Pressable, useToast} from 'native-base';
import Feather from 'react-native-vector-icons/Feather';
import {COMMON_ACTION_CODES} from '../../../constants/ActionConst';
import {useIntl} from 'react-intl';
import { useMutation } from '@apollo/client';
import ContactCareProgram from '../../../services/ContactCareProgram/ContactCareProgram';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../constants/Configs';
import { IContact } from '../../RightSideContainer/TeamInbox/Conversations/interfaces';
import { showToast, ToastType } from '../../../utils/commonViewUtils';


interface ICareProgramCardProps {
  careProgramData: IContactCareProgramResponse;
  careProgram: IContactCareProgram;
  chronicConditions: ICondition[];
  onActionPerform?: (
    actionCode: string,
    selectedCareProgram: IContactCareProgram,
    stepId?: string
  ) => void;
  contact?: IContact;
}

interface ICareProgramComplexityStatus {
  code: CareProgramComplexityCode;
  value: string;
}

const CareProgramCard = (props: ICareProgramCardProps) => {
  const { careProgram, onActionPerform, chronicConditions, contact } = props;
  const intl = useIntl();
  const toast = useToast();
  const contextData = useContext(CommonDataContext);
  const careProgramTypesList = getMlovListFromCategory(contextData.CARE_STUDIO_MLOV, MLOV_CATEGORY.CARE_PROGRAM_TYPES) || [];
  const {isDeclined, declinedAtStep} = useCareProgramDeclinedInfo(props?.careProgramData);  
  const SIMPLE_COMPLEXITY_STATUS = CARE_PROGRAM_COMPLEXITY_STATUS_CODES.find(
    (status) => status.code === CareProgramComplexityCode.SIMPLE
  );
  const findCareProgramComplexity = (
    complexityCode: CareProgramComplexityCode | undefined
  ): ICareProgramComplexityStatus => {
    return (
      CARE_PROGRAM_COMPLEXITY_STATUS_CODES.find(
        (status) => status.code === complexityCode
      ) || SIMPLE_COMPLEXITY_STATUS!
    );
  };
  const [careProgramComplexity, setCareProgramComplexity] = useState<ICareProgramComplexityStatus>(
    findCareProgramComplexity(careProgram?.complexity as CareProgramComplexityCode)
  );

  const [updateContactCareProgramComplexityStatus] = useMutation(ContactCareProgram.UPDATE_CONTACT_CARE_PROGRAM_STATUS, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
  });
  
  // Other methods
  const getCardTopTitle = () => {
    return 'Care Program Eligibility';
  }

  const getCardMainTitle = () => {
    const careProgramTypeCode = careProgramTypesList?.find((item) => item?.id === careProgram?.payerCareProgram?.careProgramTypeId)?.code;
    switch (careProgramTypeCode) {
      case CARE_PROGRAM_TYPE_CODES.CCM: return 'Chronic Care Management (CCM)';
      case CARE_PROGRAM_TYPE_CODES.TCM: return 'Transitional Care Management (TCM)';
      case CARE_PROGRAM_TYPE_CODES.ECM: return 'Enhanced Care Management (ECM)';
      default: return 'Care Program';
    }
  }

  const renderComplexity = (): JSX.Element => {
    const isCareProgramSimple = careProgramComplexity.code === CareProgramComplexityCode.SIMPLE;
    const backgroundColor = isCareProgramSimple ? Colors.Custom.PendingTaskBackgroundColor : Colors.Custom.MissedTaskBackgroundColor;
    const textColor = isCareProgramSimple ? Colors.Custom.PendingTaskFontColor : Colors.Custom.MissedTaskFontColor;
    return (
      <HStack alignItems={'center'}>
        <Text style={styles.complexityLabel}>Complexity: </Text>
        <Pressable onPress={toggleComplexity}>
          <HStack style={[styles.complexityContainer, { backgroundColor }]} space={1}>
            <Text style={[styles.complexityText, { color: textColor }]}>
              {careProgramComplexity?.value}
            </Text>
            <Feather
              name="repeat"
              size={10}
              color={textColor}
            />
          </HStack>
        </Pressable>
      </HStack>
    )
  }

  const getCardSubTitle = () => {
    return chronicCondtionsView({
      conditionNametextStyles: {color: Colors.Custom.Gray500},
      monthTextStyles: {color: Colors.Custom.Gray900},
      chronicConditions: chronicConditions,
      careProgramEnrollmentDate: props?.careProgramData?.enrollmentLog?.enrollmentDateTime,
    });
  };

  const getCardSteps = () => {
    if (!props?.careProgramData?.id) {
      return (<></>);
    }
    return (
      <CareProgramStepWiseView
        contactId={contact?.id}
        contactCareProgram={props?.careProgramData}
        onActionPerformed={(code: string, extraData?: any) => {
          if (onActionPerform && typeof onActionPerform === 'function') {
            onActionPerform(code, careProgram, extraData?.stepId);
          }
        }}
        config={{hideCareProgramName: true}}
        filterCode={PROGRAM_STEPS_FILTER_CODE.ALL}
        isCareProgramCard={true}
      />)
  }

  const handleDeclinedStepClick = () => { 
    const stepCode = declinedAtStep?.careProgramStepType.code;
    let actionCode = '';
    switch (stepCode) {
      case CARE_PROGRAM_STEP_TYPE.CONSENT:
        actionCode = COMMON_ACTION_CODES.CARE_PROGRAM_CONSENT_AND_ENROLL;
        break;
      case CARE_PROGRAM_STEP_TYPE.ECM_OUTREACH:
      case CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_2DAY:
      case CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_14DAY:
        actionCode = COMMON_ACTION_CODES.ECM_OUTREACH_VIEW;
        break;
      default:
        break;
    }

    if (
      onActionPerform &&
      typeof onActionPerform === 'function' &&
      actionCode.length > 0
    ) {
      onActionPerform(actionCode, careProgram, declinedAtStep?.id);
    }
  }

  const renderDeclinedView = () => {
    return (
      <Pressable
        _hover={{
          backgroundColor: Colors.Custom.Red100,
        }}
        padding={1}
        alignSelf={'flex-start'}
        borderRadius={4}
        ml={-1}
        onPress={handleDeclinedStepClick}
      >
        <Text
          style={{
            color: Colors.FoldPixel.STATUS_ERROR,
          }}
        >
          <Feather name="slash" size={14} style={{marginRight: 2}} />
          {intl.formatMessage({id: 'patientDeclined'})}
        </Text>
      </Pressable>
    );
  }

  const onUpdateStatus = async (status: string) => {
    try {
      await updateContactCareProgramComplexityStatus({
        variables: {
          params: {
            id: props?.careProgramData?.id,
            contactId: props?.contact?.uuid,
            complexity: status,
          }
        },
      });
    } catch (error) {
      showToast(
        toast,
        intl.formatMessage({id: 'careProgramComplexityUpdateErrorMessage'}),
        ToastType.error
      );
    }
  };

  const toggleComplexity = () => {
    const newComplexity = CARE_PROGRAM_COMPLEXITY_STATUS_CODES.find(
      status => status.code !== careProgramComplexity.code
    ) || SIMPLE_COMPLEXITY_STATUS;
    setCareProgramComplexity(newComplexity as ICareProgramComplexityStatus);
    onUpdateStatus(newComplexity?.code as CareProgramComplexityCode);
  };

  return (
    <Stack
      direction="row"
      style={{
        alignItems: 'center',
        borderRadius: 8,
        borderWidth: 0.5,
        borderColor: isDeclined ? Colors.FoldPixel.GRAY150 : Colors.Custom.Primary300,
        backgroundColor: isDeclined ? Colors.Custom.White : Colors.Custom.insuranceBgColor,
        overflow: 'hidden',
        marginBottom: 4,
      }}
    >
      <View style={isDeclined ? styles.cardStripDeclined : styles.cardStripActive}/>
      <Stack direction="column" space={4} style={styles.contentContainer}>
        {!isDeclined && (
          <Text style={styles.topTitle}>
            {getCardTopTitle()}
          </Text>
        )}
        <Text style={styles.mainTitle}>
          {getCardMainTitle()}
        </Text>
        {renderComplexity()}
        {getCardSubTitle()}
        {isDeclined ? renderDeclinedView() : getCardSteps()}
      </Stack>
    </Stack>
  );
}

const styles = StyleSheet.create({
  cardStripDeclined: {
    height: '80%',
    width: 4,
    overflow: 'hidden',
    borderTopEndRadius: 8,
    borderBottomEndRadius: 8,
    backgroundColor: Colors.FoldPixel.GRAY200,
  },
  cardStripActive: {
    height: '80%',
    width: 4,
    overflow: 'hidden',
    borderTopEndRadius: 8,
    borderBottomEndRadius: 8,
    backgroundColor: Colors.Custom.Primary300,
  },
  contentContainer: {
    padding: 12,
    flex: 1,
  },
  topTitle: {
    fontSize: 12,
    fontWeight: '400',
    color: Colors.Custom.Gray400,
  },
  mainTitle: {
    fontSize: 14,
    fontWeight: '400',
    color: Colors.Custom.Gray700,
  },
  complexityLabel: {
    color: Colors.FoldPixel.GRAY300,
    fontSize: 12,
  },
  complexityContainer: {
    alignItems: 'center',
    borderRadius: 4,
    paddingHorizontal: 3,
    paddingVertical: 1,
  },
  complexityText: {
    fontSize: 12,
  },
});

export default CareProgramCard
