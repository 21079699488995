import { Drawer } from 'antd';
import { View } from 'native-base';
import { useNavigate } from 'react-router-dom';
import { BUTTON_TYPE } from '../../../constants';
import { Colors } from '../../../styles';
import WorkflowView from '../../RightSideContainer/Workflow/Workflow/WorkflowView';
import { getCreateWorkflowUrlFromFlowType } from '../../RightSideContainer/Workflow/workflowUtils';
import { ModalActionTitle } from '../ModalActionTitle/ModalActionTitle';
import { WorkflowDrawerProps } from './interfaces';
import { styles } from './WorkflowDrawerStyles';
import { CommonDataContext } from '../../../context/CommonDataContext';
import React from 'react';

const WorkflowDrawer = (props: WorkflowDrawerProps) => {
  const {isOpen, onClose} = props;
  const flowType = props.flowType || '';
  const url = getCreateWorkflowUrlFromFlowType(flowType);
  const navigate = useNavigate();
  const commonData = React.useContext(CommonDataContext);
  const isSideCarContext = commonData.sidecarContext?.isSidecar;

  return (
    <Drawer
      zIndex={1000}
      open={isOpen}
      onClose={onClose}
      width={isSideCarContext ? '100%' : '75vw'}
      title={
        <ModalActionTitle
          title={'Automation'}
          titleColor={''}
          buttonList={[
            {
              show: true,
              id: 1,
              btnText: 'cancel',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.SECONDARY,
              isTransBtn: false,
              onClick: () => {
                onClose();
              },
            },
            {
              show: true,
              id: 1,
              btnText: 'createAutomation',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.PRIMARY,
              isTransBtn: false,
              onClick: () => {
                navigate(url);
              },
            },
          ]}
        />
      }
    >
      <View>
        <WorkflowView
          isNewWorkflowView={true}
          showTableTopBar={false}
          flowType={flowType}
          searchText={''}
        />
      </View>
    </Drawer>
  );
};

export default WorkflowDrawer;


