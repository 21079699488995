export const INVITE_TYPE_VALUE = {
  EMAIL: 'Email',
  SMS: 'Sms',
  NOTIFICATION: 'Mobile App Notification'
}

export const MEETING_VIEW_INVITE_STATE_DATA_KEY = {
  SEND_EMAIL: 'sendEmail',
  SEND_SMS: 'sendSms',
  SEND_APP_NOTIFICATION: 'sendAppNotification'
}

export const MEETING_INVITE_TYPE = [
  {
    code: INVITE_TYPE_VALUE.EMAIL,
    value: INVITE_TYPE_VALUE.EMAIL,
    stateDataKey: MEETING_VIEW_INVITE_STATE_DATA_KEY.SEND_EMAIL
  },
  {
    code: INVITE_TYPE_VALUE.SMS,
    value: INVITE_TYPE_VALUE.SMS,
    stateDataKey: MEETING_VIEW_INVITE_STATE_DATA_KEY.SEND_SMS
  },
  {
    code: INVITE_TYPE_VALUE.NOTIFICATION,
    value: INVITE_TYPE_VALUE.NOTIFICATION,
    stateDataKey: MEETING_VIEW_INVITE_STATE_DATA_KEY.SEND_APP_NOTIFICATION
  }
]