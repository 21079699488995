import React from "react";

const EmployerSvgIcon = ()=> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="#98A2B3"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M13.333 17.5V4.167A1.667 1.667 0 0011.667 2.5H8.333a1.667 1.667 0 00-1.666 1.667V17.5M3.333 5.833h13.334c.92 0 1.666.747 1.666 1.667v8.333c0 .92-.746 1.667-1.666 1.667H3.333c-.92 0-1.666-.746-1.666-1.667V7.5c0-.92.746-1.667 1.666-1.667z"
      ></path>
    </svg>
  );
}

export default EmployerSvgIcon;
