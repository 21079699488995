import React, {useCallback, useContext, useMemo, useState} from 'react';
import {CommonDataContext} from '../../../../../../context/CommonDataContext';
import {findArrayIntersection} from '../../../../../../utils/arrUtils';
import {IFormLocation} from '../AddOrUpdateForm';

const useEhrAndLocation = (props: {
  isEHRDisabled?: boolean;
  allowedLocationList?: string[];
  nonRemovableLocations?: Set<string>;
  selectedEhrAndLocations?: any;
}) => {
  const {
    isEHRDisabled,
    allowedLocationList: propsAllowedLocationList,
    nonRemovableLocations,
    selectedEhrAndLocations,
  } = props;
  const commonData = useContext(CommonDataContext);
  const {accountLocationListWithEHR} = commonData;
  const ehrList = commonData.accountEhrList || [];

  const findEhrCodeByLocationId = useCallback(
    (locationSet: Set<string>) => {
      // first locationId from the set
      const [locationId] = locationSet;
      const location = commonData.accountLocationListWithEHR?.find(
        (location) => location.uuid === locationId
      );
      return location?.ehrInfo?.ehrCode;
    },
    [commonData.accountLocationListWithEHR]
  );

  const nonRemovableLocationIds = useMemo(
    () =>
      props.nonRemovableLocations
        ? Array.from(props.nonRemovableLocations)
        : [],
    [props.nonRemovableLocations]
  );

  const filterLocationsByEhrcode = (ehrCode: string) => {
    const res = (accountLocationListWithEHR || [])?.filter(
      (location) => !!location && location.ehrInfo.ehrCode === ehrCode
    );

    return res;
  };

  const getInitEhrValue = () => {
    if (nonRemovableLocations?.size) {
      return findEhrCodeByLocationId(nonRemovableLocations);
    }

    if (selectedEhrAndLocations?.ehrCode) {
      return selectedEhrAndLocations?.ehrCode;
    }
    if (ehrList?.length > 0) {
      return ehrList[0];
    }
    return '';
  }

  const getInitLocationValue = () => {
    if (nonRemovableLocationIds?.length > 0) {
      return nonRemovableLocationIds?.map(locationId => ({locationId}));
    }
    if (selectedEhrAndLocations?.locationIdList) {
      return selectedEhrAndLocations?.locationIdList;
    }
  }

  const [ehrAndLocationSelect, setEhrAndLocationSelect] = useState<{
    locationIdList: IFormLocation[];
    ehrCode: string;
    isNonAccessLocationExist: boolean;
    prevLocation: IFormLocation[];
  }>({
    locationIdList: getInitLocationValue() || [],
    ehrCode: getInitEhrValue(),
    isNonAccessLocationExist: false,
    prevLocation: [],
  });

  const baseLocationList = useMemo(
    () => filterLocationsByEhrcode(ehrAndLocationSelect.ehrCode),
    [ehrAndLocationSelect.ehrCode]
  );
  const baseLocationUUIDList =
    baseLocationList?.map((location) => location.uuid) || [];
  const allowedLocationList = findArrayIntersection(
    baseLocationUUIDList,
    props?.allowedLocationList || []
  );
  const locationList = (accountLocationListWithEHR || [])?.filter((location) =>
    allowedLocationList?.includes(location.uuid)
  );

  const checkIfTheLoggedInUserHasAccessToAllFormLocations = (formLocationIds: string[], ehrCode: string) => {
    const currentUserLocationMap = new Map<string, string>();
    props?.allowedLocationList?.forEach((location) => {
      currentUserLocationMap.set(location, location);
    });

    // here all formlocationsIds should be existing in the currentUserLocationMap map 
    const isUserHasAccessToAllFormLocations = formLocationIds.every((locationId) => {
      return currentUserLocationMap.has(locationId);
    });
    return isUserHasAccessToAllFormLocations;
  }

  return {
    locationList,
    ehrAndLocationSelect,
    setEhrAndLocationSelect,
    checkIfTheLoggedInUserHasAccessToAllFormLocations
  };
};

export default useEhrAndLocation;
