import {Button, Text, View} from 'native-base';
import React from 'react';
import {styles} from './ContactDisplayCardStyles';
export interface IContactDataObj {
  contactId: string;
  contactName: string;
  contactGender: string;
  contactCity: string;
  contactState: string;
  contactPostalCode: string;
  contactGenderLabel?: string;
}

export interface IContactDisplayCardProps {
  contactData: IContactDataObj;
  btnText?: string;
  onBtnPress?: (contact: IContactDataObj) => void;
}

const ContactDisplayCard = (props: IContactDisplayCardProps) => {
  const {contactData, btnText, onBtnPress} = props;

  const handleBtnPress = () => {
    if (onBtnPress) {
      onBtnPress(contactData);
    }
  };

  return (
    <View style={styles.cardContainer}>
      <View flex={0.8} style={styles.cardView}>
        <View style={styles.multiDataView}>
          <View style={styles.singleDataView}>
            <Text style={styles.dataText}>Name: </Text>
            <Text style={styles.dataText}>
              {contactData.contactName || 'NA'}
            </Text>
          </View>
          <View style={styles.singleDataView}>
            <Text style={styles.dataText}>{`${contactData.contactGenderLabel || 'Gender'}: `}</Text>
            <Text style={styles.dataText}>
              {contactData.contactGender || 'NA'}
            </Text>
          </View>
          <View style={styles.singleDataView}></View>
        </View>

        <View style={styles.multiDataView}>
          <View style={styles.singleDataView}>
            <Text style={styles.dataText}>City: </Text>
            <Text style={styles.dataText}>
              {contactData.contactCity || 'NA'}
            </Text>
          </View>
          <View style={styles.singleDataView}>
            <Text style={styles.dataText}>State: </Text>
            <Text style={styles.dataText}>
              {contactData.contactState || 'NA'}
            </Text>
          </View>
          <View style={styles.singleDataView}>
            <Text style={styles.dataText}>Postal Code: </Text>
            <Text style={styles.dataText}>
              {contactData.contactPostalCode || 'NA'}
            </Text>
          </View>
        </View>
      </View>
      <View flex={0.2} style={styles.btnView}>
        {btnText && (
          <Button onPress={() => handleBtnPress()} style={styles.btnStyle}>
            {btnText}
          </Button>
        )}
      </View>
    </View>
  );
};

export default ContactDisplayCard;
