import {StyleSheet} from 'react-native';

export const getStyles = () => {
  return StyleSheet.create({
    container: {
      backgroundColor: '#fff',
    },
    mentionBarContainer: {
      width: '100%',
    },
  });
};
