import { Skeleton, Text, View } from 'native-base';
import { StyleSheet } from 'react-native';

import { useLazyQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../../constants/Configs';
import { TaskQueries } from '../../../../../../services';
import ContactsQueries from '../../../../../../services/Contacts/ContactsQueries';
import { getWorkflowExecutionLogsByWorkflowMasterId } from '../../AddOrUpdateWorkflow/WorkflowApi';
import { WorflowStatusTable } from './WorkflowStatusTable';
import BaseService from '../../../../../../services/CommonService/BaseService';
import { UserLocationContext } from '../../../../../../context/UserLocationContext';
import { getFoldLocations, getLocationNames, isLoginUserBusinessOwner } from '../../../../../../utils/commonUtils';
import { ILocation } from '../../../../ContentManagement/CampaignManagement/interfaces';
import { isAccountConfigEnabled } from '../../../../../../utils/configUtils';
import { CONFIG_CODES } from '../../../../../../constants/AccountConfigConst';



const StatusHeader = ({ title, iconColor}: any)=> {

    return (
        <View style={styles.headerBar}>
            <View style={styles.headerBarView} background={iconColor} />
            <Text style={styles.headerBarText}>{title}</Text>
        </View>
    )
}




export const WorkflowStatusDetail = ({ 
    workflowMasterId,
    activeTabCount,
    workflowState,
    activeTab, 
    workflowExecutionData, 
    iconColor, 
    getColumn, 
    onPageChange, 
    limit, 
    activePage,
    foldLocations
  }: any)=>{
    const [ contacts , setContact] = useState({})
    const [ allTask , setAllTask] = useState({})
    const [ userWorkFlows , setUserWorkFlows] = useState({})
    const [ offset, setOffset] = useState(0);
    const [ currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize ] = useState(limit || 20)
    const userLocationContextData = useContext(UserLocationContext)
    const isBusinessOwner = isLoginUserBusinessOwner();
    const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED)

    const getContactsByIds = async (
      params: {
        params: {
        contactIds: number[]
        }
      }
      ) : Promise<any> => {
        try{
          const baseService = BaseService.getSharedInstance().axios;
          const getContactDetails = `crm-nest/account/contact-details`;
  
          const response = await baseService.get(getContactDetails, params);
          return response;
  
        } catch (error) {
          return {error: error}
        }
      }
      const [getTaskByMultiIds,{loading}] = useLazyQuery(TaskQueries.GET_TASK_BY_MULTI_IDS, {
        context: {service: CARESTUDIO_APOLLO_CONTEXT},
        fetchPolicy: 'no-cache',
      });
    
      // const fetchTaskDetails = async () => {
      //   const response = await getTaskByIdApi({
      //     variables: {
      //       id: taskId,
      //     },
      //   });
      //   const task = response?.data?.getTasks?.tasks?.[0];
       
       
      // };

    enum LOADING_STATUS {
        loadingValue,
        loadedValue,
        needToLoad,
    }
      const [workflowExecutionDataLoadingStatus, setWorkflowExecutionDataLoadingStatus] = useState(LOADING_STATUS.needToLoad)
    //   if (!props?.workflowData?.workflowMasterId) {
    //     return <></>
    //   }
    
    //   const createWorkflowExecutionGraphData = (workflowExecutionData: IWorkflowExecutionData) => {
    //     workflowExecutionData = workflowExecutionData || {}
    //     const completed = workflowExecutionData?.completed || 0;
    //     const failed = workflowExecutionData?.failed || 0;
    //     const incomplete = workflowExecutionData?.incomplete || 0;
    //     props.setDisabledStatus ? props.setDisabledStatus(props.workflowData, workflowExecutionData.totalCount === 0) : undefined;
    //     setWorkflowExecutionData(workflowExecutionData)
       
    //     setWorkflowExecutionGraphData([
    //       {
    //         type: 'Completed Automation',
    //         value: completed
    //       },ss
    //       {
    //         type: 'In Progress Automation',
    //         value: incomplete
    //       },
    //       {
    //         type: 'Failed Automation',
    //         value: failed
    //       }
    //     ])
    //   }

    const getContacts = async (contactIds: any, taskIds?: any)=> {
      
        if (contactIds && contactIds?.length) {
            const response = await getContactsByIds({
              params: {
                contactIds:
                  contactIds,
              },
            });
            const allContacts = response?.data;
            if(allContacts?.length > 0){
              const idObj: any = {};  
                allContacts.forEach((item: any)=>{
                    idObj[item.id] = item;
                })
                setContact(idObj);
            }
            
          }
          if (taskIds?.length) {
            const response = await getTaskByMultiIds({
              variables: {
                ids:
                taskIds,
              },
            });
            const allTasks = response?.data?.getTasks.tasks;
            if(allTasks?.length > 0){
              const idObj: any = {};
                allTasks.forEach((item: any)=>{
                    idObj[item.id] = item;
                })
               
                setAllTask(idObj);
            }
            
          }
    }
    
     

      // useEffect(()=>{
      //       if(workflowMasterId){
      //       setWorkflowExecutionDataLoadingStatus(LOADING_STATUS.loadingValue)
      //       getWorkflowExecutionLogsByWorkflowMasterId(workflowMasterId, workflowState, offset, pageSize, userLocationContextData).then((response) => {
      //         const { data, contactIds, taskIds } = response;
      //         getContacts(contactIds, taskIds)
      //         setUserWorkFlows(data);
      //         setWorkflowExecutionDataLoadingStatus(LOADING_STATUS.loadedValue)
      //       }, () => {
      //         setWorkflowExecutionDataLoadingStatus(LOADING_STATUS.loadedValue)
      //       })
      //   } else if(workflowExecutionData?.serviceLogs){
      //     setWorkflowExecutionDataLoadingStatus(LOADING_STATUS.loadingValue)
      //     const resourceIds:any = [];
      //     workflowExecutionData?.serviceLogs.forEach((item: any)=> {
      //       item?.resourceLevelLog.forEach((itemVal: any)=>{
      //         if(itemVal.resourceType === 'CONTACT'){
      //           resourceIds.push(itemVal?.resourceId);
      //         }
      //       })
      //     })
      //     getContacts(resourceIds)
      //     setUserWorkFlows(workflowExecutionData?.serviceLogs);
      //     setWorkflowExecutionDataLoadingStatus(LOADING_STATUS.loadedValue)
      //   }
        
      // }, [activeTab, offset, pageSize])

      useEffect(() => {
        const fetchData = async () => {
          if(workflowMasterId){
            setWorkflowExecutionDataLoadingStatus(LOADING_STATUS.loadingValue)
            try {
              const response = await getWorkflowExecutionLogsByWorkflowMasterId(workflowMasterId, workflowState, isBusinessOwner, offset, pageSize, userLocationContextData)
              const { data, contactIds, taskIds } = response;
              if (isMsoEnabled) {
                foldLocations = await getFoldLocations();
              }
              const dataListWithLocationNames = await Promise.all((data || []).map(async (dataItem: any) => {
                const locationIds = dataItem?.locations?.map((loc: {locationId: string;}) => loc?.locationId) || [];
                const result = getLocationNames(foldLocations, locationIds);
                const names = result?.map((loc: ILocation) => loc?.name) || [];
                return {
                  ...dataItem,
                  locationNames: names
                };
              }));
              await getContacts(contactIds, taskIds)
              setUserWorkFlows(dataListWithLocationNames);
              setWorkflowExecutionDataLoadingStatus(LOADING_STATUS.loadedValue)
            } catch (error) {
              console.error('Error fetching data:', error);
              setWorkflowExecutionDataLoadingStatus(LOADING_STATUS.loadedValue)
            }
        } else if(workflowExecutionData?.serviceLogs){
          setWorkflowExecutionDataLoadingStatus(LOADING_STATUS.loadingValue)
          const resourceIds:any = [];
          workflowExecutionData?.serviceLogs.forEach((item: any)=> {
            item?.resourceLevelLog.forEach((itemVal: any)=>{
              if(itemVal.resourceType === 'CONTACT'){
                resourceIds.push(itemVal?.resourceId);
              }
            })
          })
          await getContacts(resourceIds)
          setUserWorkFlows(workflowExecutionData?.serviceLogs);
          setWorkflowExecutionDataLoadingStatus(LOADING_STATUS.loadedValue)
        }
        };
        fetchData();
      }, [activeTab, offset, pageSize])

      if(LOADING_STATUS.loadingValue === workflowExecutionDataLoadingStatus){
        return(
          <View padding={3}>
            <Skeleton.Text lines={6} />
          </View>
        )
      }

      
    return (
        <View style={styles.container}>
           <StatusHeader title={`${activeTab} • ${activeTabCount || 0}`} iconColor={iconColor}/>
           <WorflowStatusTable 
                  column={typeof getColumn === 'function' ?getColumn(contacts): null}
                  contacts={contacts}
                  allTask={allTask}
                  currentPage={activePage || currentPage}
                  pageSize={pageSize}
                  activeTab={activeTab} 
                  userWorksFlows={userWorkFlows}
                  activeTabCount={activeTabCount}
                  onPageChange={(page: number, pageSize: number)=> {
                    setPageSize(pageSize)
                    setCurrentPage(page)
                    setOffset((page-1)*pageSize)
                    if( typeof onPageChange === 'function'){
                      onPageChange(page, pageSize);
                    }
                    setWorkflowExecutionDataLoadingStatus(LOADING_STATUS.needToLoad);
                  }}

            />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1, 
        padding: 16,
        borderLeftWidth: 1, 
        borderLeftColor: '#E4E7EC', 
        flexDirection: 'column', 
        justifyContent: 'flex-start', 
        alignItems: 'flex-start',  
    },
    headerBar: {
        flexDirection: 'row', 
        alignItems: 'center', 
        marginBottom: 16
    },
    headerBarView: {
        width: 20, 
        height: 20, 
        borderRadius: 9999 
    },
    headerBarText: {
        flex: 1, 
        color: 'black', 
        fontSize: 18, fontFamily: 'Manrope', 
        fontWeight: '600', 
        lineHeight: 27, 
        marginLeft: 8
    }
})
