import {gql} from '@apollo/client';

export const GET_CPT_CODES = gql`
  query GetCptCodes($searchString: String, $accountUuid: uuid) {
    cpt_codes(
      order_by: {created_at: desc}
      where: {
        code: {_ilike: $searchString}
        _or: [
          {account_uuid: {_is_null: true}}
          {account_uuid: {_eq: $accountUuid}}
        ]
      }
    ) {
      id
      code
      description
    }
  }
`;

export default {
  GET_CPT_CODES,
};
