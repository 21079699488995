import React from 'react';
import {Colors} from '../../../styles';
import {ICommonSvgProps} from './interfaces';

const FilterNewIconSvg = (props: ICommonSvgProps) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.116 2.5H9.29996C5.38283 2.5 3.42426 2.5 3.05368 3.68648C2.68309 4.87296 4.24334 6.17992 7.36384 8.79384C8.29158 9.57097 8.75545 9.95954 9.00341 10.5079C9.25136 11.0563 9.25136 11.6909 9.25136 12.96V16.0639C9.25136 16.5476 9.25136 16.7895 9.30626 16.9428C9.44945 17.3427 9.83786 17.5704 10.2233 17.4805C10.371 17.446 10.5564 17.3128 10.9273 17.0466C11.2914 16.7852 11.4734 16.6544 11.6237 16.5009C12.0061 16.1103 12.2611 15.5946 12.3501 15.0319C12.3851 14.8108 12.3851 14.5734 12.3851 14.0985V12.7746C12.3851 11.5937 12.3851 11.0033 12.6032 10.4835C12.8214 9.96378 13.2314 9.57742 14.0513 8.8047L14.2192 8.64655C16.956 6.06746 18.3244 4.77791 17.9347 3.63895C17.5449 2.5 15.7353 2.5 12.116 2.5Z"
        stroke={"#6F7A90" }
        stroke-linecap="round"
      />
    </svg>
  );
};

export default React.memo(FilterNewIconSvg);