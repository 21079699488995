import React from 'react'

const PageBreakWrapper = (props: {children: JSX.Element}) => {
  return (
    <div className='page-break'>
      {props.children}
    </div>
  )
}

export default PageBreakWrapper
