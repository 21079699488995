import { Patient, PatientContact } from "fhir/r4";
import { IContactRelations, ICustomPatient } from "../../../services/ContactRelation/interface";

export const getRelationValue = (relationid: any, personRelationObj: any) => {
  const relationId = relationid;
  const personRelation = personRelationObj;
  let result = personRelation?.find((item: any) => item.id === relationId);
  if (result === undefined) {
    result = {value: ''};
  }
  return result;
};

export const capitalizeText = (string: string) => {
  const str = string;
  const str2 = str?.charAt(0)?.toUpperCase() + str?.slice(1);
  return str2 || '';
};

export const filterExistingContacts = (list: any, contactId: any) => {
  const tempArry: any = [];
  list?.forEach((item: any) => {
    const isExist = item.relationshipWith.some(
      (e: any) => e.isDeleted === false && e.contactId === contactId
    );
    if (!isExist) {
      tempArry.push(item);
    }
  });
  return tempArry;
};

export const getEmergencyContactFromPatientFHIR = (patient?: Patient) => {
  if (!patient || !patient?.contact?.length) {
    return [];
  }
  return patient.contact.filter((contact) => {
    return contact.name?.text?.trim() || contact.name?.family?.trim() || contact.name?.given?.[0]?.trim();
  });
}

export const getPatientContactName = (patientContact: PatientContact): string => {
  if (patientContact.name?.text?.trim()) {
    return patientContact.name.text;
  }
  const lastName = patientContact.name?.family || '';
  const firstName = patientContact.name?.given && patientContact.name?.given.length > 0
    ? patientContact.name?.given[0]
    : '';
  return firstName + ' ' + lastName;
};

export const getEmptyEmergencyContactFHIRTemplate = (patientId: string, contactUuid: string): ICustomPatient => {
  return {
    resourceType: 'Patient',
    id: patientId,
    reference: {
      contactUuid: contactUuid,
    },
    contact: [
      {
        name: {
          family: '',
          given: [''],
        },
        relationship: [
          {
            coding: [
              {
                code: 'C',
                display: '',
              },
            ],
          },
        ],
        address: {
          line: ['', ''],
          city: '',
          state: '',
          postalCode: '',
        },
      },
    ],
  };
}

export const sortContactRelations = (contactRelations: IContactRelations[]) => {
  return contactRelations?.sort(
    (relationA, relationB) => {
      if (relationA.isCaregiver && relationB.isCaregiver) {
        return 0;
      }
      if (relationA.isCaregiver) {
        return -1;
      }
      return 1;
    }
  );     
}