import { ITimezone } from "../../../../../services/Location/interfaces";
import { IAppointmentTypeUserPool } from "../../../../RightSideContainer/AccountSettings/AppointmentTypes/Interfaces";

export interface IAppointmentType {
  id?: string;
  code?: string;
  duration?: number;
  description?: string;
  eventName: string;
  endDate?: string;
  beginDate?: string;
  locationTypeId: string;
  intervalType?: string;
  isShownToPatient: boolean;
  locationType?: {value: string; code: string};
  bookWithinDays?: number;
  appointmentTypeGroup: IAppointmentTypeGroup[];
  isRsvpEnabled?: boolean;
  categoryId?: string;
  availabilityTypeCode?: string;
  userPool?: IAppointmentTypeUserPool;
  customFields?: ICustomAppointmentType[],
  slotStartTime?: string;
  slotEndTime?: string;
  bookFromDays?: number;
  bookWithinSpanText?: string;
  bookFromSpanText?: string;
  locationGroupId?: string;
}

export interface ICustomAppointmentType {
  fieldTypeId?: string;
  subjectId?: string;
  subjectTypeCode?: string;
  value: ICustomAppointmentTypeValue;
  valueDataTypeCode: string;
}

export interface ICustomAppointmentTypeValue {
  value?: string;
  unitId?: string;
  locationCustomMessage?: string;
}
export interface IAppointmentTypeGroup {
  id?: string;
  duration?: number;
  userId?: string;
  roleId?: string;
  user?: IUserSearch;
  uniqueKey?: string;
  accountRoleId?: string;
}
export interface IUserSearch {
  id: number;
  name: string;
  uuid: string;
  userRoles: IUserRole[];
  userPracticeLocations: IUserPracticeLocation[];
}
export interface IUserRole {
  userRole: {roleId: string};
}

export interface IBookingData {
  appointmentType?: IAppointmentType;
  selectedSlot?: ISlot;
  selectedDate?: string;
  selectedLocation?: IUserPracticeLocation;
  selectedTimezone?:ITimezone;
  isSpecificTimeView: boolean;
  dayWiseSlots: Map<string, ISlot[]>;
  dayWiseAvailability: Map<string, ISlot[]>;
}

export interface IUserPracticeLocation {
  practiceLocationUuid: string;
  uuid: string;
  practiceLocation: IPracticeLocation;
  accountLocation?: {
    uuid: string;
    practiceLocation: IPracticeLocation;
  };
}

export interface IPracticeLocation {
  name: string;
  addressLine1?: string;
  addressLine2?: string;
  practiceZipcode?: {code: string};
  practiceState?: {name: string};
  practiceCountry?: {name: string};
  practiceCity?: {name: string};
}

export interface ISlot {
  startDateTime: string;
  endDateTime: string;
}

export interface IAppointmentUser {
  name: string;
  duration: string;
  appointmentName: string;
  appointmentLocation: string;
  appointmentDetails?: string;
}

export interface IAppointmentDetail {
  title: string;
  subTitle: string;
  description: string;
  icon: JSX.Element;
}

export interface IBookedAppointment {
  id: string;
  name?: any;
  startDateTime: string;
  endDateTime: string;
  participants: IParticipant[];
  notes: {id: string; content: string}[];
  isBlockedInterval: boolean;
  statusId: string;
  isRecurrentAppointment: boolean,
  seriesId?: string
  reference?: any;
  proposedTime?: string;
  declinedReason?: string;
  userPracticeLocationId?: string;
  accountLocationId?: string;
  locationTypeId?: string;
  isRsvpEnabled?: boolean;
  locationGroupId?: string;
  appointmentType: {
    id: string;
    eventName: string;
    locationType: {
      value: string;
      code?:string;
    };
    category: {
      code: string;
    };
  };
  memberLimit?: number;
  description?: string;
  expiryDateTime?: string;
}

export interface IParticipant {
  id: string;
  patient?: {
    name: {
      family: string;
      given: string[];
    }[];
    id: string;
    birthDate: string;
  };
  user?: {
    uuid: string;
    name: string;
    id: number;
  };
  contact?: {
    id: string;
    name: string;
    uuid: string;
    age: string;
    gender: string;
    sexAtBirth?: string;
    contactTypeValue: string;
  };
  statusId?: string;
  isInitiator?: boolean;
  participantTypeId?: string;
}

export type AssistanceScreenStackParamList = {
  AssistanceScreen: undefined; // undefined because you aren't passing any params to the home screen
  BookAppointment: {
    action: BookAppointmentScreenAction;
    appointmentDetail: IBookedAppointment;
    onComplete: () => void;
  };
};

export enum BookAppointmentScreenAction {
  newBooking,
  reschedule,
  proposingNewTime,
  bookedRecurring
}

export interface ILocationGroup {
  id: string;
  groupName: string;
}

export interface ILocationGroupData {
  locationGroups: ILocationGroup[];
}
