import React from 'react';
import AppointmentBooking from '../../../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentBooking';
import {ParticipantType} from '../../../../common/CalendarWidget/ParticipantAutoComplete/ParticipantEnum';
import {
  CONVERSATION_ACTION_CODES,
  GROUP_ACTION_CODES,
} from '../../../../../constants/ActionConst';
import {RIGHT_SIDE_CONTAINER_CODE} from '../../../../../constants';
import MeetingView from '../../../../common/MeetingView/MeetingView';
import {
  IContactType,
  IConversationData,
  IMessagingWindowHeaderActionProps,
} from '../interfaces';
import {AddOrUpdateLead} from '../../../Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateLead';
import CreateSmsConversationDrawer from '../ConversationChannelTabs/CreateSmsConversationDrawer/CreateSmsConversationDrawer';
import {CHANNEL_TYPE_CODE} from '../ConversationConst';
import CreateChannelView from '../../../../common/CreateChannelView/CreateChannelView';
import { localBroadcastEvent } from '../../../../../utils/CustomEventHandler';
import { CONVERSATION_LOCAL_EVENT_CODES } from '../../../../../constants/WebSocketConst';
import UpdateToContact from './UpdateToContactView/UpdateToContactDrawer';
import { EmailDrawerCommonV2 } from '../../../../common/EmailDrawerCommonV2';

const MessagingWindowHeaderAction = (
  props: IMessagingWindowHeaderActionProps
) => {
  const getAppointmentDrawer = (selectedRowData: any) => {
    return (
      <AppointmentBooking
        defaultParticipants={[
          {
            label: selectedRowData?.name || '',
            key: selectedRowData?.uuid || '',
            value: selectedRowData?.uuid || '',
            type: ParticipantType.patient,
          },
        ]}
        isVisible={true}
        onComplete={() => {
          props?.onComplete();
          localBroadcastEvent(CONVERSATION_LOCAL_EVENT_CODES.LOCAL_SCHEDULE_APPOINTMENT, {});
        }}
        onCancel={() => {
          props?.onComplete();
        }}
      />
    );
  };

  const getUpdateToContactDrawer = (
    selectedRowData: any,
    selectedConversation: IConversationData,
  ) => {
    const channelType =
      selectedConversation?.conversationInbox?.channelType || '';
    return (
      <UpdateToContact
        selectedConversation={selectedConversation}
        contactData={selectedRowData}
        channelType={channelType}
        isVisible={true}
        onComplete={(actionData: any) => {
          props?.onComplete(
            CONVERSATION_ACTION_CODES.UPDATE_TO_CONTACT,
            actionData
          );
          localBroadcastEvent(
            CONVERSATION_LOCAL_EVENT_CODES.CONTACT_DETAIL_UPDATE,
            {
              conversationData: selectedConversation,
            }
          );
        }}
        onCancel={() => {
          props?.onComplete();
        }}
      />
    );
  };

  const getEmailCommonDrawer = (selectedRowData: any) => {
    return (
      <EmailDrawerCommonV2
        isOpen
        onClose={() => {
          props?.onComplete();
        }}
        onEmailSent={() => {
          props?.onComplete();
        }}
        contactIds={[selectedRowData.id]}
      />
    );
  };

  const getMeetingView = (
    formattedContactData: any,
    selectedConversation: IConversationData
  ) => {
    return (
      <MeetingView
        personData={formattedContactData}
        selectedConversation={selectedConversation}
        onPersonActionPerformed={() => {
          props?.onComplete();
        }}
      />
    );
  };

  const getAddOrUpdateContact = (
    contactType: IContactType,
    selectedRowData: any
  ) => {
    return (
      <AddOrUpdateLead
        singleLeadData={selectedRowData}
        onFormCompleteAction={(actionCode: string) => {
          props?.onComplete();
        }}
        personType={contactType?.code}
        personTypeUuid={contactType?.id}
      />
    );
  };

  const getSmsConversationDrawer = (selectedRowData: any) => {
    return (
      <CreateSmsConversationDrawer
        isDrawerVisible={true}
        selectedInbox={{} as any}
        selectedPatient={selectedRowData}
        onCreateSmsConversationActionPerformed={props?.onComplete}
      />
    );
  };

  const getCreateChannelView = (
    selectedActionView: string,
    selectedConversation: IConversationData,
    selectedTabCode?: string
  ) => {
    return (
      <CreateChannelView
        isDrawerVisible={true}
        onCreateChannelActionPerformed={(
          actionCode: string,
          actionData: any
        ) => {
          props?.onComplete(actionCode, actionData, selectedActionView);
        }}
        selectedConversation={selectedConversation}
        selectedTabCode={selectedTabCode}
      />
    );
  };

  const getHeaderActions = (
    headerActionData: IMessagingWindowHeaderActionProps
  ) => {
    switch (headerActionData?.selectedActionView) {
      case RIGHT_SIDE_CONTAINER_CODE.CREATE_MEETING_VIEW:
        return getMeetingView(
          headerActionData?.formattedContactData,
          headerActionData?.selectedConversation
        );

      case CONVERSATION_ACTION_CODES.CREATE_APPOINTMENT:
        return getAppointmentDrawer(headerActionData?.selectedRowData);

      case CONVERSATION_ACTION_CODES.UPDATE_TO_CONTACT:
        return getUpdateToContactDrawer(
          headerActionData?.selectedRowData,
          headerActionData?.selectedConversation
        );

      case CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS:
        return getSmsConversationDrawer(headerActionData?.selectedRowData);

      case CONVERSATION_ACTION_CODES.START_EMAIL:
        return getEmailCommonDrawer(headerActionData?.selectedRowData);

      case RIGHT_SIDE_CONTAINER_CODE.CONTACT_EDIT_VIEW:
        return getAddOrUpdateContact(
          headerActionData?.contactType,
          headerActionData?.selectedRowData
        );

      case GROUP_ACTION_CODES.GROUP_HEADER_CLICKED:
        return getCreateChannelView(
          headerActionData?.selectedActionView,
          headerActionData?.selectedConversation,
          headerActionData?.selectedTabCode
        );
    }
  };
  return (
    <>
      {getHeaderActions(
        props
      )}
    </>
  );
};

export default React.memo(MessagingWindowHeaderAction);
