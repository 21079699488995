import {gql} from '@apollo/client';

export const GetConversationTags = gql`
  query GetConversationTags(
    $taggableId: Int!
    $taggableType: String!
  ) {
    labels(
      where: {
        tagCount: {
          tags_taggings: {
            taggableType: {_eq: $taggableType}
            taggableId: {_eq: $taggableId}
          }
        }
      }
    ) {
      id
      color
      description
      title
      tagCount {
        name
        id
        taggingsCount
        tags_taggings(where: {taggableType: {_eq: $taggableType}}) {
          taggableId
          taggableType
          taggerId
          taggerType
          id
        }
      }
    }
  }
`;

export const GetTagsForConversation = gql`
  query GetTagsForConversation($contactId: bigint!) {
    conversations(where: {contactId: {_eq: $contactId}}) {
      id
      uuid
      accountId
      conversationsTaggings {
        labels {
          color
          description
          id
          title
        }
      }
    }
  }
`;

export const GetTagsForContact = gql`
  query GetTagsForContact($id: Int!) {
    contacts(where: {id: {_eq: $id}}) {
      contactTagging {
        id
        labels {
          description
          id
          color
          title
          labelTypes {
            labelType {
              description
              value
            }
          }
        }
      }
      accountId
      id
      name
    }
  }
`;

export const GetConversationDetail = gql`
  query GetConversationDetail($id: bigint!) {
    conversation(id: $id) {
      conversationContact {
        additionalAttributes
        customAttributes
        name
        email
        phoneNumber
      }
      additionalAttributes
    }
  }
`;

export const GetConversationTrackData = gql`
  query GetConversationTrackData($contactId: bigint) {
    conversations(where: {contactId: {_eq: $contactId}}) {
      id
      uuid
      contactId
      contactLastSeenAt
      lastActivityAt
      assigneeLastSeenAt
    }
  }
`;

export const GetConversationDetails = gql`
  query GetConversationDetails($contactId: bigint, $uuid: uuid!) {
    conversations(where: {contactId: {_eq: $contactId}, uuid: {_eq: $uuid}}) {
      id
      uuid
      contactId
      createdAt
      contactInbox {
        inbox {
          name
          channelTwilioSms {
            phoneNumber
          }
          channelEmail {
            email
          }
          channelEfax {
            efaxNumber
          }
        }
      }
    }
  }
`;

export default {
  GetConversationTags,
  GetConversationDetail,
  GetConversationTrackData,
  GetConversationDetails,
  GetTagsForConversation,
  GetTagsForContact,
};
