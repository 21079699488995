import {HStack, Spacer, Text} from 'native-base';
import React, { useContext } from 'react';
import {useIntl} from 'react-intl';
import Feather from 'react-native-vector-icons/Feather';
import { Colors } from '../../../../../styles';
import InfoIconSvg from '../../../../common/Svg/InfoIconSvg';
import { View } from 'react-native';

function WriteInfoBackBanner(props: {
  message?: string;
  marginBottom?: number;
  children?: React.ReactNode;
  borderRadius?: number;
}) {
  const intl = useIntl();
  return (
    <HStack
      backgroundColor={Colors.Custom.infoBannerBgColor}
      rounded={'lg'}
      px={3}
      py={2}
      mb={props.marginBottom || 2}
      borderRadius={props.borderRadius || undefined}
      alignItems={'center'}
    >
      <View>
        <InfoIconSvg size={20} />
      </View>
      <Text ml={2} color={Colors.FoldPixel.GRAY400} fontWeight={200}>
        {intl.formatMessage({id: props.message || 'writeBackInfoMessage'})}
      </Text>
      <Spacer />
      {props.children}
    </HStack>
  );
}

export default React.memo(WriteInfoBackBanner);
