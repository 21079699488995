export const STRIP_API_PATH = {
  PRODUCT_LIST: 'crm-nest/stripe/product',
  BILLING_PRODUCT_LIST: 'crm-nest/stripe/billing-products',
  SEARCH_PRODUCT_LIST: 'crm-nest/stripe/search-active-product',
  SUBSCRIPTION_DETAILS: 'crm-nest/stripe/subscription',
  SEARCH_ORDER_LIST: 'crm-nest/stripe/search-charges',
  SEARCH_ORDER_LIST_ONE_TIME: 'crm-nest/stripe/search-charges-oneTime',
  SEARCH_BILLING_ORDER_LIST: 'crm-nest/stripe/search-billing-charges',
  STRIP_PAYMENT_CRED: 'crm-nest/stripe/account-cred',
  SUBSCRIPTION_LIST: 'crm-nest/payment/membership-subscription',
  SUBSCRIPTION_ORDERS: 'crm-nest/stripe/subscription',
  SUBSCRIPTION_ORDER_DETAIL: 'crm-nest/stripe/subscription-details',
  SUBSCRIPTION_INVOICES: 'crm-nest/stripe/subscription-invoices',
  ORDER_LIST: 'crm-nest/stripe/charges',
  SUBSCRIPTION:'crm-nest/stripe/contact/subscription',
  ONE_TIME_LIST: 'crm-nest/stripe/search-charges-oneTime',
  PACKAGE_PRODUCTS: 'crm-nest/stripe/package-products',
  GET_PACKAGE: 'crm-nest/stripe/package'
};
