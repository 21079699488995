import React from 'react';
import {Svg, Path} from 'react-native-svg';
import { ICommonSvgProps } from '../interfaces';

function ThreeDotsSvg(props: ICommonSvgProps) {
  return (
    <Svg
      width="28"
      height="28"
      fill="none"
      viewBox="0 0 28 28"
    >
      <Path
        stroke={props?.customStrokeColor || "#6F7A90"}
        strokeLinecap="round"
        d="M9.833 14.003a1.667 1.667 0 11-3.333 0 1.667 1.667 0 013.333 0zM15.667 14.003a1.667 1.667 0 11-3.334 0 1.667 1.667 0 013.334 0zM21.5 14.003a1.667 1.667 0 11-3.333 0 1.667 1.667 0 013.333 0z"
      ></Path>
    </Svg>
  );
}

export default ThreeDotsSvg;
