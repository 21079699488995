export const tagColorList: string[] = [
  '#05AECC',
  '#DAE3F0',
  '#F57373',
  '#687689',
  '#4AC16A',
  '#0478C0',
  '#89609E',
  '#858C92',
  '#CC5992',
  '#D29034',
  '#63D2D7',
  '#F6CF52',
  '#FE6A00',
  '#EB144B',
];
