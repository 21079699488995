import {Badge, Button, HStack, Icon, Text, useMediaQuery, View, VStack} from 'native-base';
import React, { useContext, useMemo } from 'react';
import AntIcon from 'react-native-vector-icons/AntDesign';
import MaterialIcon from 'react-native-vector-icons/MaterialIcons';
import {
  BUTTON_TYPE,
  DATE_FORMATS,
  DISPLAY_DATE_FORMAT,
  GROUP_MEMBER_TYPE,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH,
  MLOV_CATEGORY,
  PERSON_TYPES,
} from '../../../constants';
import {APPOINTMENT_PARTICIPANT_STATUS_CODES, APPOINTMENT_STATUS_CODES, APPOINTMENT_TYPE_CATEGORY_CODES, MLOV_CODES} from '../../../constants/MlovConst';
import {IMlovByCategory} from '../../../Interfaces';
import {Colors} from '../../../styles';
import {getDateStrFromFormat, getMomentObjFromFormat} from '../../../utils/DateUtils';
import {
  getMlovId,
  getMlovIdFromCode,
  getMlovListFromCategory,
  getMlovValueFromId,
} from '../../../utils/mlovUtils';
import {IParticipantData} from '../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentBookingIntefaces';
import {AppointmentRSVP} from '../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentRSVP';
import {ParticipantType} from '../../common/CalendarWidget/ParticipantAutoComplete/ParticipantEnum';
import {DisplayCardAvatar} from '../../common/DisplayCard/DisplayCardAvatar';
import {getAgeFromDate} from '../../PersonOmniView/LeftContainer/OtherDetails/OtherDeatilsUtils';
import parse from 'html-react-parser';
import {getAppointmentDateTime} from '../../common/CalendarWidget/BookingWorkflows/BookAppointment/BookAppointmentHelper';
import {IAppointmentDetail} from '../../common/CalendarWidget/CalendarWidgetInterfaces';
import {
  getConfigDataFromCode,
  getGenderTextByGenderCode,
  getPrimaryGenderValue,
} from '../../../utils/commonUtils';
import Feather from 'react-native-vector-icons/Feather';
import {CONFIG_CODES} from '../../../constants/AccountConfigConst';
import {IDefaultFormTheme} from '../../RightSideContainer/Forms/interfaces';
import { useIntl } from 'react-intl';
import { isFutureMeeting, isPastMeeting } from '../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentBookingHelper';
import { CommonDataContext } from '../../../context/CommonDataContext';
import ReccuringAppointmentIcon from '../../../assets/Icons/ReccuringAppointmentIcon';
import { getSummaryString } from '../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/hooks/useReccuringEvent/helpers';
import { processDataRecurrence } from '../../common/Audit/AuditViewHelper';
import {IUserSettingsByCode} from '../../../services/UserSettings/interfaces';

interface IProps {
  appointment: IAppointmentDetail;
  currentParticipantId: string;
  appointmentLocation?: string;
  careStudioMlovData: IMlovByCategory;
  headers?: {[index: string]: string};
  onSendResponseSuccess: (code: string) => void;
  accounSettings: IUserSettingsByCode
}

export function AppointmentDetailsWidget(props: IProps) {
  // const toast = useToast();
   const intl = useIntl();
  const defaultThemeConfig = getConfigDataFromCode(CONFIG_CODES.DEFAULT_THEME);
  let configJson = undefined;
  let formThemeConfig: IDefaultFormTheme | undefined = {} as IDefaultFormTheme;

  if (
    defaultThemeConfig?.accountConfigurations &&
    defaultThemeConfig?.accountConfigurations?.length
  ) {
    configJson = JSON.parse(
      defaultThemeConfig?.accountConfigurations[0]?.value
    );
    formThemeConfig = configJson?.form || undefined;
  } else if (defaultThemeConfig?.defaultValue) {
    configJson = JSON.parse(defaultThemeConfig?.defaultValue);
    formThemeConfig = configJson?.form || undefined;
  }

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);
  const isSmallScreen = isIPadMiniScreen || isIPadScreen ;

  const participants = props.appointment?.participants || [];
  const appointmentTypeName =
    props?.appointment?.name || props.appointment?.appointmentType?.eventName || '';
  const isReccurentAppointment = props?.appointment?.isRecurrentAppointment && !!props?.appointment?.seriesId;
  const currentParticipant: IParticipantData | undefined = participants.find(
    (participant) =>
      participant.id && participant.id === props.currentParticipantId
  );
  const otherParticipant: IParticipantData | undefined = participants.find(
    (participant) =>
      participant.id && participant.id !== props.currentParticipantId
  );

  if (!currentParticipant || !otherParticipant) {
    showError();
    return <></>;
  }

  const appointmentStatusList =
    getMlovListFromCategory(
      props.careStudioMlovData,
      MLOV_CATEGORY.APPOINTMENT_STATUS
    ) || [];
    const mlovData = useContext(CommonDataContext);

    const APPOINTMENT_PARTICIPANT_STATUS_LIST: any = {
      accepted: getMlovId(
        mlovData.CARE_STUDIO_MLOV,
        MLOV_CATEGORY.APPOINTMENT_PARTICIPANT_STATUS,
        APPOINTMENT_PARTICIPANT_STATUS_CODES.ACCEPTED
      ),
      declined: getMlovId(
        mlovData.CARE_STUDIO_MLOV,
        MLOV_CATEGORY.APPOINTMENT_PARTICIPANT_STATUS,
        APPOINTMENT_PARTICIPANT_STATUS_CODES.DECLINED
      ),
      need_action: getMlovId(
        mlovData.CARE_STUDIO_MLOV,
        MLOV_CATEGORY.APPOINTMENT_PARTICIPANT_STATUS,
        APPOINTMENT_PARTICIPANT_STATUS_CODES.NEEDS_ACTION
      )
    }

  const APPOINTMENT_STATUS_IDS = {
    pending: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.PENDING
    ),
    proposed: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.PROPOSED
    ),
    booked: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.BOOKED
    ),
    decline: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.DECLINED
    ),
    checkedIn: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.CHECKED_IN
    ),
    checkedOut: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.CHECKED_OUT
    ),
    cancelled: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.CANCELLED
    ),
    rescheduled: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.RESCHEDULED
    ),
  };

  const appointmentStatusId = props.appointment.statusId;
  const isAppointmentStatusPending =
    appointmentStatusId === APPOINTMENT_STATUS_IDS.pending;
  const isAppointmentStatusDecline =
    appointmentStatusId === APPOINTMENT_STATUS_IDS.decline;
  const isAppointmentStatusProposed =
    appointmentStatusId === APPOINTMENT_STATUS_IDS.proposed;

  const isGroupAppointment = props.appointment?.appointmentType?.category?.code === APPOINTMENT_TYPE_CATEGORY_CODES.GROUP_SESSION;

  const needRSVPAction =
    isGroupAppointment ||
    isAppointmentStatusPending ||
    isAppointmentStatusDecline ||
    isAppointmentStatusProposed;

  function getParticipantName(participant: IParticipantData) {
    return participant?.user?.name || participant?.contact?.name;
  }

  function getAllHostName(participant: IParticipantData[]) {
    return participant
      ?.filter((item) => !!item.user?.name)
      ?.map((item) => {
        return item?.user?.name;
      });
  }

  function getParticipantTypeValue(participant: IParticipantData) {
    if (participant.contact) {
      if (participant.contact?.contactType?.contactType?.code === 'CUSTOMER') {
        return ParticipantType.patient;
      } else {
        return ParticipantType.leads;
      }
    }
    return '';
  }

  function getParticipantSubHeader(participant: IParticipantData) {
    const participantTypeValue = getParticipantTypeValue(participant);
    if (!participantTypeValue) return undefined;

    const participantAge = getParticipantAge(participant);
    const participantGender = getGenderTextByGenderCode(participant.contact);

    let participantSubHeader = participantTypeValue.toString();

    if (participantAge || participantGender) {
      participantSubHeader += `,`;
      if (participantAge) participantSubHeader += ` ${participantAge} yrs`;
      if (participantGender) participantSubHeader += ` ${participantGender}`;
    }

    return participantSubHeader;
  }

  function getParticipantAge(participant: IParticipantData) {
    if (participant.contact?.person?.birthDate) {
      return getAgeFromDate(
        getMomentObjFromFormat(
          participant.contact.person.birthDate,
          DATE_FORMATS.DISPLAY_DATE_FORMAT
        ).toDate()
      );
    }
    return '';
  }

  function onSendResponseSuccess(code: string) {
    props.onSendResponseSuccess(code);
  }

  function showError() {
    // showToast(toast, intl.formatMessage({id: 'apiErrorMsg'}), ToastType.error);
  }

  const getRecurringSummary = () => {
    if (!isReccurentAppointment) {
      return '';
    }
    const recurrenceData = props?.appointment?.reference?.recurrenceData;
    const string = getSummaryString(processDataRecurrence(recurrenceData), {
      startTime: getDateStrFromFormat(recurrenceData?.seriesStartDate, DISPLAY_DATE_FORMAT),
  });

  return string.length > 0 ? string : '';
  }

  const recurrenceSummary = useMemo(
    () => getRecurringSummary(),
    [props.appointment?.id, props.appointment?.seriesId]
  );

  return (
    <>
      <View flex={1} flexDirection={isSmallScreen ? 'column' : 'row'}>
        <VStack space={4} flex={isSmallScreen ? undefined : 0.5}>
          <View
            py={5}
            width={'100%'}
            borderColor={Colors.Custom.Gray100}
          >
            <Text fontSize={24} fontWeight={700} color={'black'}>
              {appointmentTypeName}
            </Text>
          </View>
          {props.appointment.reasonForVisit && (
            <VStack width={'100%'}>
              <Text fontSize={18} fontWeight={700} color={'black'}>
                {'Reason for visit'}
              </Text>
              <Text
                size="xsMedium"
                fontWeight={400}
                color={Colors.Custom.Gray500}
              >
                {props.appointment.reasonForVisit?.displayName}
              </Text>
            </VStack>
          )}
          {props.appointment.description && (
            <VStack width={'100%'}>
              <Text fontSize={18} fontWeight={700} color={'black'}>
                {'Description'}
              </Text>
              <Text
                size="xsMedium"
                fontWeight={400}
                color={Colors.Custom.Gray500}
              >
                {props.appointment.description}
              </Text>
            </VStack>
          )}
          <VStack width={'100%'}>
            <Text fontSize={18} fontWeight={700} color={'black'}>
              {'Date & Time'}
              {props.appointment?.isRecurrentAppointment &&
                !!props.appointment?.seriesId && (
                  <Badge
                    backgroundColor={Colors.Custom.mainPrimaryPurple}
                    alignSelf="center"
                    flexDir={'row'}
                    alignItems={'center'}
                    variant="solid"
                    ml={2}
                    rounded={'full'}
                  >
                    <ReccuringAppointmentIcon />
                    <Text
                      ml={2}
                      color={Colors.Custom.FontColorWhite}
                      fontSize={12}
                    >
                      Reccuring
                    </Text>
                  </Badge>
                )}
            </Text>
            <Text
              size="xsMedium"
              fontWeight={400}
              color={Colors.Custom.Gray500}
            >
              {getAppointmentDateTime(props.appointment)}
            </Text>
            {recurrenceSummary.length > 0 && (
              <Text
                size="xsMedium"
                fontWeight={400}
                color={Colors.Custom.Gray500}
              >
                {recurrenceSummary}
              </Text>
            )}
          </VStack>

          <VStack width={'100%'}>
            <Text fontSize={18} fontWeight={700} color={'black'}>
              {'Host'}
            </Text>
            <Text
              size="xsMedium"
              fontWeight={400}
              color={Colors.Custom.Gray500}
            >
              {getAllHostName(participants)?.toString() || ''}
            </Text>
          </VStack>
          {!!props.appointmentLocation && (
            <VStack width={'100%'}>
              <Text fontSize={18} fontWeight={700} color={'black'}>
                {'Location'}
              </Text>
              <HStack alignItems="center" marginTop={1}>
                {!props?.appointment?.isVirtualLocation ? (
                  <View>
                    <Icon as={MaterialIcon} name="location-on" size="5" />
                  </View>
                ) : (
                  <View>
                    <Feather
                      name="video"
                      size={5}
                      color={Colors.Custom.Gray500}
                    />
                  </View>
                )}
                <Text
                  size="xsMedium"
                  fontWeight={400}
                  color={Colors.Custom.Gray500}
                >
                  {props.appointmentLocation}
                </Text>
              </HStack>
            </VStack>
          )}

          {props.appointment.notes && props.appointment.notes.length > 0 && (
            <VStack width={'100%'}>
              <Text fontSize={18} fontWeight={700} color={'black'}>
                {'Notes'}
              </Text>
              {props.appointment.notes.map((note: any, index: number) => {
                return (
                  <View size="xsMedium" key={index} >
                    <Text
                      size="xsMedium"
                      fontWeight={400}
                      color={Colors.Custom.Gray500}
                    >
                      {parse(note.content)}
                    </Text>
                  </View>
                );
              })}
            </VStack>
          )}
        </VStack>

        <VStack
          flex={isSmallScreen ? undefined : 0.5}
          ml={isSmallScreen ? undefined : 5}
          mt={isSmallScreen ? 5 : undefined}
        >
          {needRSVPAction && props.appointment.participants?.length && (
            <VStack padding={5} space={3}>
              {
                props.appointment.participants?.filter((participant) => {
                  const isParticipantLoggedInUser = participant.id && participant.id === props.currentParticipantId;
                  return isParticipantLoggedInUser;
                }).map((participant) => {
                  return (
                    <AppointmentRSVP
                      key={participant.id}
                      appointmentDetails={props.appointment as IAppointmentDetail}
                      participant={participant}
                      loggedInParticipantId={props.currentParticipantId}
                      careStudioMlovData={props.careStudioMlovData}
                      onSendResponseSuccess={onSendResponseSuccess}
                      headers={props.headers}
                      isGroupAppointmentWidget={isGroupAppointment}
                      isFromRSVPWidget={true}
                      currentParticipantType={getParticipantTypeValue(currentParticipant)}
                      currentParticipantContactId={currentParticipant?.contact?.uuid}
                      isReccurentAppointment={isReccurentAppointment}
                      accountSettings={props.accounSettings}
                    />
                  );
                })
              }
            </VStack>
          )}
          {/* <View height={20} /> */}
        </VStack>
      </View>
    </>
  );
}
