import { StyleSheet } from "react-native";
import { Colors } from "../../../styles";

export const styles = StyleSheet.create({
  relationSelect: {
    marginLeft: 10
  },
  heading: {
    fontSize: 14,
    fontWeight: '700',
    marginLeft: 4,
    marginBottom: 6 
  },
  singleItem: {
    marginBottom: 8
  },
  addMoreStyle: {
    marginTop: 4,
    fontSize: 12,
    color: Colors.Custom.PrimaryColor,
    marginLeft: 4 
  },
  spinnerStyle: {
    margin: '0 auto',
    color: 'black',
    position: 'absolute',
    zIndex: 100011111,
    backgroundColor: '#0000000f',
    height: '100%',
    width: '100%',
    padding: 0
  }
})