import {Button, HStack, Pressable, Text, View, VStack} from 'native-base';
import React, {useState} from 'react';
import Icon from 'react-native-vector-icons/AntDesign';
import {Colors} from '../../../../styles';
// import {getEditorContainer} from '../QuickActions/EditorContainer.web';
import {styles} from './TimelineNoteStyles';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {ITimelineNoteProps} from '..';
// import './TimelineNoteCss.css';

const modules = {
  toolbar: [
    [{header: [1, 2, false]}],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{list: 'ordered'}, {list: 'bullet'}, {indent: '-1'}, {indent: '+1'}],
    ['link', 'image'],
    ['clean'],
  ],
  clipboard: {
    matchVisual: true,
  },
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
];

const TimelineNote = (props: ITimelineNoteProps) => {
  const {noteColor, noteText} = props;

  const [noteVisible, setNoteVisible] = useState(true);
  const [note, setNote] = useState(noteText);

  const handleNoteChange = (note: string) => {
    setNote(note);
  };

  return (
    <View style={styles.noteContainer}>
      <VStack>
        {noteVisible && (
          <Pressable onPress={() => setNoteVisible((prev) => !prev)}>
            <HStack style={[styles.noteStack, {backgroundColor: noteColor}]}>
              <Text>{note}</Text>
            </HStack>
          </Pressable>
        )}
        {!noteVisible && (
          <VStack>
            <ReactQuill
              theme="snow"
              value={note}
              onChange={(value) => handleNoteChange(value)}
              modules={modules}
              formats={formats}
              placeholder="Enter text here"
            />
            <HStack>
              <Button.Group style={styles.btnGroup}>
                <Button
                  style={[styles.saveBtn]}
                  _text={{color: Colors.Custom.FontColorWhite}}
                  variant={'unstyled'}
                  leftIcon={<Icon name="save" size={15} />}
                  onPress={() => {
                    setNoteVisible((prev) => !prev);
                  }}
                >
                  Save
                </Button>
                <Button
                  style={[styles.discardBtn, styles.ml5]}
                  variant={'unstyled'}
                  _text={{color: Colors.Custom.FontColorWhite}}
                  onPress={() => setNoteVisible((prev) => !prev)}
                  leftIcon={<Icon name="delete" size={15} />}
                >
                  Discard
                </Button>
                <Button
                  style={[styles.cancelBtn, styles.ml5]}
                  variant={'unstyled'}
                  onPress={() => setNoteVisible((prev) => !prev)}
                  leftIcon={<Icon name="close" size={15} />}
                >
                  Cancel
                </Button>
              </Button.Group>
            </HStack>
          </VStack>
        )}
      </VStack>
    </View>
  );
};

export default TimelineNote;
