import {Pressable, View} from 'native-base';
import React from 'react';
import {DATE_FORMATS} from '../../../../../../../../constants';
import {getDateStrFromFormat} from '../../../../../../../../utils/DateUtils';
import {FEED_CLICKABLE_RESOURCE_TYPE_LIST, FEED_RESOURCE_TYPE} from '../FeedConst';
import {IFeedData} from '../interface';
import {getConversationMessageElem, getFeedCommonHeaderElem} from './FeedHeaderUtils';
import { testID } from '../../../../../../../../testUtils';
import { COMPONENT_TEST_IDENTIFIER } from '../../../../../../../../testUtils/ComponentTestIdentifiers';


const FeedDataCollapse = (props: {
  singleFeedData: IFeedData
  firstEntryID: string,
  onHeaderClick: (actionCode: string, singleFeedData: IFeedData) => void;
}) => {
  const singleFeedData = props.singleFeedData;
  const {onHeaderClick} = props;
  if (!singleFeedData) {
    return singleFeedData;
  }

  const dateStr = singleFeedData?.time_stamp ? getDateStrFromFormat(
    singleFeedData?.time_stamp,
    DATE_FORMATS.CONVERSATION_DATE_PICKER
  )
    : '';


  const getPanelHeaderText = () => {
    let panelHeaderText = '';
    if (singleFeedData?.schema_type?.trim() === 'CRM') {
      panelHeaderText = `${singleFeedData.search_tags
        }`;
    }
    return panelHeaderText?.trim();
  };

  const getIconName = () => {
    let iconName = 'filetext1';
    const resourceType = singleFeedData?.resourceType;
    if (resourceType === 'Appointment') {
      iconName = 'calendar';
    }
    return iconName;
  };


  const getFeedDataHeaderElem = () => {
    let headerElem = <></>;
    if (singleFeedData?.schema_type?.trim() === 'CRM' && getPanelHeaderText()) {
      if (singleFeedData.resourceType === FEED_RESOURCE_TYPE.MESSAGE) {
        headerElem = getConversationMessageElem(
          singleFeedData,
          props.firstEntryID,
          onHeaderClick
        );
      } else {
        headerElem = getFeedCommonHeaderElem(
          singleFeedData,
          props.firstEntryID,
          FEED_CLICKABLE_RESOURCE_TYPE_LIST.includes(
            singleFeedData.resourceType || ''
          ),
          onHeaderClick
        );
      }
    } else {
      headerElem = <></>;
    }
    return headerElem;
  };

  return (
    <View {...testID(COMPONENT_TEST_IDENTIFIER.feedDataCollapse)}>
      {getFeedDataHeaderElem()}
    </View>
  )


};

export default React.memo(FeedDataCollapse);
