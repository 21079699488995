import React from "react";
import { Svg, Path } from 'react-native-svg';

function MissedTaskIcon2024() {
    return (
        <Svg
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 16 16"
        >
            <Path
                stroke="#6F7A90"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2 6.336l3.927 3.89c.665.66.997.991 1.41.991.412 0 .745-.33 1.41-.991l4.92-4.89m0 0v3.697m0-3.697H9.945"
            ></Path>
        </Svg>
    )
}

export default React.memo(MissedTaskIcon2024);