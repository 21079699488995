import {Formio} from '@foldhealth/formiojs';
import ConditionalComponents from '../EditFormFields/ConditionalFields';
import KeyField from '../EditFormFields/KeyField';
import SearchableComponentFields from '../EditFormFields/SearchableComponentFields';
import { FormComponentLabelEnum } from "../FormEnum";
import ShareWithPatientFields from '../EditFormFields/ShareWithPatientFields';

const nestedComponent = Formio.Components.components.nested;
Formio.Components.components.columns.schema = (...extend: any) => {
  return nestedComponent.schema({
    label: FormComponentLabelEnum.columns,
    key: 'columns',
    type: 'columns',
    columns: [
      { components: [], width: 6, offset: 0, push: 0, pull: 0, size: 'md' },
      { components: [], width: 6, offset: 0, push: 0, pull: 0, size: 'md' }
    ],
    clearOnHide: false,
    input: false,
    tableView: false,
    persistent: false,
    autoAdjust: false
  }, ...extend);
};

Formio.Components.components.columns.editForm = function () {
  return {
    key: 'display',
    components: [
      {
        type: 'oldtextfield',
        key: 'label',
        label: 'Label',
        input: true,
        validate: {
          required: true,
        },
      },
      ...SearchableComponentFields,
      {
        weight: 150,
        type: 'datagrid',
        input: true,
        key: 'columns',
        label: 'Column Properties',
        addAnother: 'Add Column',
        tooltip: 'The width, offset, push, and pull settings for each column.',
        reorder: true,
        components: [
          {
            type: 'hidden',
            key: 'components',
            defaultValue: [],
          },
          {
            type: 'select',
            key: 'size',
            defaultValue: 'md',
            label: 'Size',
            data: {
              values: [
                {label: 'xs', value: 'xs'},
                {label: 'sm', value: 'sm'},
                {label: 'md', value: 'md'},
                {label: 'lg', value: 'lg'},
                {label: 'xl', value: 'xl'},
              ],
            },
          },
          {
            type: 'number',
            key: 'width',
            defaultValue: 6,
            label: 'Width',
          },
        ],
      },
      ...ShareWithPatientFields,
      ...ConditionalComponents,
      ...KeyField,
    ],
  };
};
