import moment from 'moment';
import {PossibleValue} from '../../../../Interfaces';
import {getGenderTextByGenderCode, isEmptyArray, isEmptyObject} from '../../../../utils/commonUtils';
import {IContact, IContactType} from '../../../RightSideContainer/TeamInbox/Conversations/interfaces';
import { getContactTypeValue } from '../../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MessagingUtils';
import { getAgeValue } from '../../../../utils/DateUtils';
import { getCurrentPatientDeceasedData} from '../../../../services/CommonService/AidBoxService';
import { IAvailableEhr } from '../../MiddleContainer/interfaces';

export const getFormattedDateString = (date: any, type?: string) => {
  const today = new Date(date);
  const dates = today?.toDateString();
  let time = '';
  if (type !== 'dob') {
    time = today?.toLocaleTimeString();
  }
  return `${dates} ${time}`;
};

export const getAgeFromDate = (dateString: any) => {
  const currentDate: any = new Date();
  const dob: any = new Date(dateString);
  const ageInMilliseconds = currentDate - dob;
  return Math.floor(ageInMilliseconds / 1000 / 60 / 60 / 24 / 365); // convert to years
};

export function getFormattedDate(date: Date | string, format: string): string {
  return moment(date).format(format);
}

export const getDiffBetweenTwoTime = (
  dateString1: string,
  dateString2: string
): number => {
  let sec = 0;
  sec = moment(dateString2).diff(dateString1);
  return sec / 1000 / 60;
};

export const getPracticeLocationNameAndCity = (
  contact: IContact
): {name: string; practiceCity: string} => {
  const contactPracticeLocations = contact?.contactPracticeLocations || [];
  if (isEmptyArray(contactPracticeLocations)) {
    return {
      name: '',
      practiceCity: '',
    };
  }
  const practiceLocation = contactPracticeLocations[0].practiceLocation || {};
  if (isEmptyObject(practiceLocation || {})) {
    return {
      name: '',
      practiceCity: '',
    };
  }
  return {
    name: practiceLocation.name,
    practiceCity: practiceLocation.practiceCity?.name || '',
  };
};


export const getGenderTransformValue = (gender: {
  value: string;
  code: string;
},genderTransformValues: PossibleValue[]) => {
  const transformedValue = genderTransformValues.filter((genderValue)=>{
    return genderValue?.code === gender?.code
  })
  if (transformedValue?.length) {
    return transformedValue[0]?.display
  }
  return gender?.value
}

export const getPatientOrProspectElem = (
  contactData: IContact ,
  contactType?: IContactType,
  additionalData?: {
    deceasedDate?: string,
  }
) => {
  const contactTypeStr = getContactTypeValue(
    contactType || ({} as IContactType)
  );
  const genderValue = getGenderTextByGenderCode(contactData);
  const deceasedDate = additionalData?.deceasedDate || '';

  const getAgeAndGenderText = () => {
    return `${contactTypeStr}${
      getAgeValue(contactData?.person?.birthDate || '', deceasedDate) || genderValue
        ? '   |   '
        : ''
    }${
      getAgeValue(contactData?.person?.birthDate || '', deceasedDate)
        ? ' ' + getAgeValue(contactData?.person?.birthDate || '', deceasedDate)
        : ''
    }${genderValue ? ` | ` + genderValue : ''}`;
  };
  return getAgeValue(contactData?.person?.birthDate || '', deceasedDate) ||
    genderValue ||
    contactTypeStr
    ? getAgeAndGenderText()
    : '';
};

export const getUserNameByContact = (contact: any) => {
  return contact?.person?.firstName
    ? contact?.person?.firstName
    : contact?.person?.lastName;
};
