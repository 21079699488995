import React from 'react';
import {Path, Svg} from 'react-native-svg';
import {ICommonSvgProps} from '../../components/common/Svg/interfaces';
import {Colors} from '../../styles';

export const VideoCallIconSvg = (props: ICommonSvgProps) => {
  const {customStrokeColor} = props;
  const strokeColor = customStrokeColor
    ? customStrokeColor
    : props.isActive
    ? Colors.Custom.Gray500
    : Colors.Custom.Gray300;

  return (
    <Svg
      width="20"
      height="16"
      viewBox="0 0 16 12"
      fill="none"
    >
      <Path
        d="M1.33325 5.66663C1.33325 3.47497 1.33325 2.37914 1.93856 1.64158C2.04937 1.50655 2.17318 1.38274 2.3082 1.27193C3.04577 0.666626 4.1416 0.666626 6.33325 0.666626C8.52491 0.666626 9.62073 0.666626 10.3583 1.27193C10.4933 1.38274 10.6171 1.50655 10.7279 1.64158C11.3333 2.37914 11.3333 3.47497 11.3333 5.66663V6.33329C11.3333 8.52495 11.3333 9.62077 10.7279 10.3583C10.6171 10.4934 10.4933 10.6172 10.3583 10.728C9.62073 11.3333 8.52491 11.3333 6.33325 11.3333C4.1416 11.3333 3.04577 11.3333 2.3082 10.728C2.17318 10.6172 2.04937 10.4934 1.93856 10.3583C1.33325 9.62077 1.33325 8.52495 1.33325 6.33329V5.66663Z"
        stroke={strokeColor}
      />
      <Path
        d="M11.3333 4.33342L11.7722 4.11397C13.0694 3.46534 13.718 3.14102 14.1923 3.43414C14.6666 3.72725 14.6666 4.45244 14.6666 5.90282V6.09735C14.6666 7.54773 14.6666 8.27292 14.1923 8.56604C13.718 8.85915 13.0694 8.53484 11.7722 7.88621L11.3333 7.66675V4.33342Z"
        stroke={strokeColor}
      />
    </Svg>
  );
};
