import moment, { Moment } from "moment";
import { CALENDAR_WIDGET_DATE_FORMATS } from "../../../../../../constants";
import { getDiffInDays } from "../../../../../../utils/DateUtils";
import { IActivity } from "../../interfaces";
import { activityFrequencyUnitCodes } from "./ActivitiesGraphView/ActivitiesGraphView";

export const getPatientData = (data: any[], patientId?: string, isDaily = false) => {
    if (patientId) {
      const response = data.find((obj) => {
        return obj.patientId === patientId
      });
      return (response?.activities || response?.daily || [])
    }
    return []
}

export const getActivityDataArray = (
    type: string,
    activityData: IActivity[]
  ) => {
    return activityData.filter((activity) => {
        return activity.type === type;
    })
};

export const getFormattedActivityData = (data: any[], type: string, isDaily=false) => {
    const array: IActivity[] = [];
    if (data) {
        data?.find((activity) => {
            return activity.type === type;
        })?.entry.forEach((entity: any) => {
            array.push({
              date: entity.period.start || '',
              value: entity.value || '',
              unit: entity.unit || '',
              type: entity.type || '',
              isAutomatic: isDaily ? true: entity && entity.uploadType === 1 ? true : false,
              isDaily: isDaily,
              source: entity.source,
            });
        });
    }
    return array;
};

export const getTodaysDateRange = () => {
    return {
        startDate: moment().startOf('day'),
        endDate: moment().endOf('day'),
    };
}

export const getDateRange = (code: string) => {
    switch (code) {
        case activityFrequencyUnitCodes.TODAY:
            return getTodaysDateRange();
        case activityFrequencyUnitCodes.YESTERDAY:
            return {
                startDate: moment().subtract(1, 'day').startOf('day'),
                endDate: moment().subtract(1, 'day').endOf('day'),
            }
        case activityFrequencyUnitCodes.LAST_WEEK:
            return getStartAndEndDateForInterval(1, 'week');
        case activityFrequencyUnitCodes.LAST_THREE_WEEKS:
            return getStartAndEndDateForInterval(3, 'week');
        case activityFrequencyUnitCodes.LAST_MONTH:
            return getStartAndEndDateForInterval(1, 'month');
        case activityFrequencyUnitCodes.LAST_THREE_MONTHS:
            return getStartAndEndDateForInterval(3, 'month');
        case activityFrequencyUnitCodes.LAST_SIX_MONTHS:
            return getStartAndEndDateForInterval(6, 'month');
    }
    return getTodaysDateRange();
}

const getStartAndEndDateForInterval = (count: number, unit: string) => {
    let startDate = moment()
    const endDate = moment().endOf('day');
    switch (unit) {
        case 'day':
            startDate = moment().subtract(count, 'day');
            break;
        case 'week':
            startDate = moment().subtract(count, 'week').add(1, 'day');
            break;
        case 'month':
            startDate = moment().subtract(count, 'month').add(1, 'day');
            break;
    }
    return {
        startDate: startDate.startOf('day'),
        endDate: endDate,
    }
}

export const getActivityFormattedData = (start: Moment, end: Moment, data: any[], showAutomatic: boolean) =>  {
    const dayDiff = getDiffInDays(start, end);
    sortActivityData(data)
    if (dayDiff > 1) {
        return getMappedDataBasedOnDayDiff(dayDiff, start, end, data, showAutomatic)
    }
    return getSingleDayGraphData(start, data, showAutomatic);
}

export const formatGraphValue = (value: string | number) => {
    if(typeof value === 'number'){
      return value.toFixed(0)
    }

    return value;
}

const getSingleDayGraphData = (start: Moment, data: any[], showAutomatic: boolean) => {
    const filteredData = data.filter((ele) => {
        return start.isSame(moment(ele.date).startOf('day')) && (showAutomatic ? ele.isAutomatic : true)
    });
    return getSingleDayFormattedData(filteredData, 'time');
}

const getSingleDayFormattedData = (data: any[], type: string) => {
    const dailyEntry = data.find((obj) => { return obj.isDaily });
    const activityData = data.filter((obj) => { return !obj.isDaily });
    const formattedData = getFormattedData(activityData, type);
    let activityValue = 0;
    formattedData.forEach((obj) => {
        activityValue += obj.value;
    });
    if (dailyEntry && dailyEntry.value && dailyEntry.value > activityValue) {
        return [{
            date: getDisplayDate(dailyEntry.date, type),
            value: dailyEntry.value - activityValue,
        }, ...formattedData]
    }
    return formattedData
}

const getFormattedData = (data: any[], type: string) => {
    const formattedData: any[] = [];
    const grouped: any = {};
    data.map((element, index) => {
        if (element.date && element.value) {
            const displayDate = getDisplayDate(element.date, type);
            let activityValue = 0;
            let dailyValue = 0;
            if (displayDate && !(displayDate in grouped)) {
                if (element.isDaily) {
                    dailyValue = element.value;
                } else {
                    activityValue = element.value;
                }
                for (let i = index + 1; i < data.length; i++) {
                    const obj = data[i];
                    if(obj.date && obj.value) {
                        const objDisplayDate = getDisplayDate(obj.date, type);
                        if (displayDate === objDisplayDate) {
                            if (obj.isDaily) {
                                dailyValue += obj.value;
                            } else {
                                activityValue += obj.value;
                            }
                        }
                    }
                }
                grouped[displayDate] = {
                    date: displayDate,
                    value: dailyValue > activityValue ? ((dailyValue - activityValue) + activityValue) : activityValue,
                }
            }
        }
    })
    Object.keys(grouped).map(key => {
        formattedData.push(grouped[key])
    })
    return formattedData;
}

const getMappedDataBasedOnDayDiff = (dayDiff: number, start: Moment, end: Moment, data: any[], showAutomatic: boolean) => {
    const type = getGroupingType(dayDiff)
    const filteredData = data.filter((element) => {
        if (element.date) {
            const momentDate = moment(element.date);
            return (start.isBefore(momentDate) && end.isAfter(momentDate) && (showAutomatic ? element.isAutomatic : true));
        }
        return false;
    });
    return getFormattedData(filteredData, type)
}

export const getDisplayDate = (date: string, type: string) => {
    switch (type) {
        case 'day':
            return moment(date).format('DD/MM/YYYY')
        case 'week':
            return getWeekDisplayDate(date)
        case 'month':
            return moment(date).format(CALENDAR_WIDGET_DATE_FORMATS.SHORT_MONTH)
        case 'time':
            return moment(date).format('DD/MM/YYYY, hh:mm A');
    }
}

const getWeekDisplayDate = (date: string) => {
    const startDate = moment(date)
    .add(1, 'days')
    .startOf('isoWeek')
    .subtract(1, 'day');
  const endDate = moment(date)
    .add(1, 'days')
    .endOf('isoWeek')
    .subtract(1, 'day');
  const startMonth = moment(startDate).format(
    CALENDAR_WIDGET_DATE_FORMATS.SHORT_MONTH
  );
  const endMonth = moment(endDate).format(
    CALENDAR_WIDGET_DATE_FORMATS.SHORT_MONTH
  );
  const startDay = startDate.format(CALENDAR_WIDGET_DATE_FORMATS.DAY_FORMAT);
  const endDay = endDate.format(CALENDAR_WIDGET_DATE_FORMATS.DAY_FORMAT);
  if (startMonth === endMonth) {
    return startMonth + ' ' + startDay + ' - ' + endDay;
  } else {
    return startMonth + ' ' + startDay + ' - ' + endMonth + ' ' + endDay;
  }
}

const getGroupingType = (dayDiff: number) => {
    if (dayDiff <= 7) {
        return 'day';
    } else if (dayDiff <= 49) {
        return 'week'
    }
    return 'month'
}

const sortActivityData = (data: any[]) => {
    return data.sort((activity1, activity2) => {
        return moment(activity1.date).isAfter(moment(activity2.date)) ? 1 : -1;
    })
}
