import { Colors } from "../../../../styles/Colors";
import { ICommonSvgProps } from "../interfaces";

const StarredSvg = (props: ICommonSvgProps) => {
    const {
        customStrokeColor,
    } = props;
    const bgColor = customStrokeColor || Colors.Custom.Gray500;

    return (
        <svg width="inherit" height="inherit" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z" stroke={bgColor} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

export default StarredSvg;
