import React, { useCallback, useContext, useEffect, useState } from 'react';
import { getDateStrFromFormat, getMomentObj, isAfter, isBefore, isMomentObject } from '../../../../../../utils/DateUtils';
import { ModalActionDatePicker } from '../../../../../common/ModalActionCommonComponent/ModalActionDatePicker';
import { IFormCommonData, IFormComponentProps } from '../CustomWrapper/CustomWrapper';
import { DATE_FORMATS } from '../../../../../../constants/StringConst'
import { IFormValidationOutput } from '../CustomWrapper/interfaces';
import { CommonDataContext } from '../../../../../../context/CommonDataContext';
import { Text } from 'native-base';
import { componentKeys } from '../CustomWrapper/CustomComponentHelper';

export const DatePicker = (props: IFormComponentProps) => {
  const { component, disabled } = props;
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const isPreviewMode = contextData.isPreviewMode || false;
  const isAllowToShare = props?.component?.allowToShare != undefined &&  props?.component?.allowToShare === false ? false : true;
  const [componentState, setComponentState] = useState({ selectedDate: props.component?.selectedValue, showErrors: false, isDataLoaded: true});

  const setValue = useCallback((value: any) => {
    if (value) {
      setComponentState((prev) => {
        return {
          ...prev,
          selectedDate: value,
          isDataLoaded: false,
        };
      });
    } else {
      setComponentState((prev) => {
        return {
          ...prev,
          isDataLoaded: false,
        }
      });
    }
  }, []);

  const validateData = (currentData: string): IFormValidationOutput => {
    setComponentState((prev) => ({...prev, showErrors: true}));
    if (props.component?.validate?.required) {
      return { isValid: !!currentData, message: !currentData ? `${props.component.label} is required` : '' };
    }
    return {isValid: true, message: ''};
  };

  props.setValueRef.current = setValue;
  props.validateRef.current = validateData;

  const hasErrors = (props.component?.validate?.required && !componentState.selectedDate) && componentState.showErrors;

  useEffect(() => {
    let selectedDate: any = componentState?.selectedDate || '';
    selectedDate = isMomentObject(selectedDate) ? selectedDate.toISOString() : selectedDate;
    props.onChange(selectedDate);
  }, [componentState.selectedDate]);

  useEffect(() => {
    const isDataAvailable = !!componentState?.selectedDate;
    contextData?.updateLoadingStatus?.(componentKeys.DATE, componentState.isDataLoaded, isDataAvailable);
  }, [componentState.isDataLoaded]);

  if (isPreviewMode) {
    const displayDate = componentState.selectedDate ? getDateStrFromFormat(componentState.selectedDate, DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT) : '-';
    return (
      <div className={` ${isAllowToShare ? '' : 'disallow-to-share'}`}>
      <Text>{displayDate}</Text>
      </div>
    );
  }

  return (
    <ModalActionDatePicker
      key={`${component?.id}`}
      format={DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT}
      placeholder={component?.placeholder || ''}
      disablePicker={disabled}
      isInvalid={hasErrors}
      onChange={(date: any) => {
        setComponentState((prev) => ({
          ...prev,
          selectedDate: date,
        }));
      }}
      disabledDate={(current: any) => {
        let isDateDisabled = false;
        if (component?.minDate) {
          isDateDisabled = isDateDisabled || isBefore(current, component?.minDate, 'day');
          if (isDateDisabled) return true;
        }
        if (component?.maxDate) {
          return isDateDisabled || isAfter(current, component?.maxDate, 'day');
        }
        return isDateDisabled;
      }}
      value={componentState?.selectedDate ? getMomentObj(componentState.selectedDate) : undefined}
      customStyle={{flex: 1}}
    />
  );
}

export default DatePicker;
