import React from "react";
import { Path, Svg } from "react-native-svg";

function ReadIcon() {
  return (
    <Svg
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 9 6"
    >
      <Path
        stroke="#12B76A"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M.5 3.45l1.571 1.8L6 .75m2.5.031l-4.286 4.5L4 5"
      ></Path>
    </Svg>
  );
}

export default ReadIcon;
