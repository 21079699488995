import React from 'react';
import {ICommonSvgProps} from '../interfaces';
import Svg, {Path} from 'react-native-svg';

const SearchActionSvg = (props: ICommonSvgProps) => {
  const strokeColor = props.customStrokeColor
    ? props.customStrokeColor
    : '#6F7A90';
  return (
    <Svg
      width={props?.width ? props?.width : 'inherit'}
      height={props?.height ? props?.height : 'inherit'}
      viewBox="0 0 20 20"
      fill="none"
    >
      <Path
        d="M14.875 14.875L17.5 17.5M16.75 9.625C16.75 13.56 13.56 16.75 9.625 16.75C5.68997 16.75 2.5 13.56 2.5 9.625C2.5 5.68997 5.68997 2.5 9.625 2.5C13.56 2.5 16.75 5.68997 16.75 9.625Z"
        stroke={strokeColor}
        strokeLinecap="round"
      />
    </Svg>
  );
};

export default React.memo(SearchActionSvg);