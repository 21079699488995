import {Popover} from 'antd';
import {debounce} from 'lodash';
import {Box, Icon, Input, Spinner, Text, VStack} from 'native-base';
import {Pressable} from 'react-native';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {useNavigate} from 'react-router-dom';
import React from 'react';
import {Colors} from '../../styles';
import { withMiniContactViewHOC } from '../MiniContactViewHOC';

const SearchBarComponent = (props: any) => {
  const navigate = useNavigate();
  const {
    onChange,
    searchList,
    setSearchList,
    isLoading,
    foundData,
    showPopover,
    setShowPopover,
    searchBoxStyle,
    inputWidth,
  } = props;
  const onContactClicked = (contactId: any) => {
    props?.navigateOrOpenContactIdDrawer?.(contactId)
  };

  const handlePopover = () => {
    setShowPopover(!showPopover);
  };

  const PopoverComp = () => {
    handlePopover;
    return (
      <Popover
        placement="bottom"
        content={ContactList}
        trigger="click"
        visible={showPopover}
        onVisibleChange={handlePopover}
      ></Popover>
    );
  };
  const ContactList = () => {
    return (
      <>
        {foundData ? (
          <>
            {searchList.map((el: any) => {
              return (
                <VStack key={el.id} p={1} width={180}>
                  <Pressable
                    onPress={() => {
                      onContactClicked(el.id);
                      setSearchList({
                        ...searchList,
                        searchListData: [],
                        foundData: true,
                      });
                    }}
                  >
                    <Text>{el.name}</Text>
                  </Pressable>
                </VStack>
              );
            })}
          </>
        ) : (
          <VStack p={2} width={180}>
            <Text alignSelf={'center'} textAlign={'center'} width={180}>
              Contact not found
            </Text>
          </VStack>
        )}
      </>
    );
  };
  return (
    <>
      <Box
        style={[
          {
            marginHorizontal: 8,
            alignItems: 'center',
            width: '90%',
          },
          searchBoxStyle,
        ]}
      >
        <Input
          // marginX={3}
          _focus={{
            borderColor: Colors.Custom.Gray200
          }}
          borderWidth={1}
          borderColor={Colors.Custom.Gray200}
          borderRadius={8}
          onChange={debounce(onChange, 500)}
          placeholder={props.placeholderText}
          width={inputWidth ? inputWidth : 'auto'}
          InputLeftElement={
            <Icon
              m="2"
              ml="3"
              size="4"
              color="gray.400"
              as={<AntIcon name="search1" />}
            />
          }
        />
        <Box>
          {isLoading ? <Spinner position={'absolute'} bottom={2} /> : null}
          {searchList.length || !foundData ? <PopoverComp /> : null}
        </Box>
      </Box>
    </>
  );
};

export const SearchBar =  withMiniContactViewHOC(SearchBarComponent);
