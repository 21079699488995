import {useMutation} from '@apollo/client';
import {Drawer} from 'antd';
import {Button, HStack, Text, VStack} from 'native-base';
import React, {useState} from 'react';
import {Dimensions} from 'react-native';
import {BUTTON_TYPE} from '../../../constants';
import { COMMON_ACTION_CODES } from '../../../constants/ActionConst';
import TagQueries from '../../../services/Tags/TagQueries';
import {Colors} from '../../../styles';
import {FoldButton} from '../../CommonComponents/FoldButton/FoldButton';
import {DisplayText} from '../DisplayText/DisplayText';
import { ILabelDataView } from '../FilterView/interfaces';
import ModalActionBtn from '../ModalActionBtn/ModalActionBtn';
import {ModalActionTitle} from '../ModalActionTitle/ModalActionTitle';

interface DeleteTagViewInterface {
  deleteTagData?: any;
  onFormCompleteAction: (actionCode?: string) => void;
}

export const DeleteTagView = (props: DeleteTagViewInterface) => {
  const [removeAttachedTag] = useMutation(TagQueries.RemoveAttachedTag);
  const [showModal, setShowModal] = useState(true);
  const onDeleteAction = () => {
    removeAttachedTag({
      variables: {id: props?.deleteTagData?.labelTaggings[0]?.id},
    }).then((res) => {
      props.onFormCompleteAction(COMMON_ACTION_CODES.COMPLETED);
      setShowModal(false);
    });
  };
  const {width} = Dimensions.get('window');
  const finalWidth = width / 3;
  return (
    <>
      <Drawer
        visible={showModal}
        width={finalWidth}
        onClose={() => {
          props.onFormCompleteAction();
          setShowModal(false);
        }}
        title={
          <ModalActionTitle
            title={'removeTagTitle'}
            titleColor={''}
            buttonList={[
              {
                show: true,
                id: 1,
                btnText: 'cancel',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
                isTransBtn: false,
                onClick: () => {
                  props.onFormCompleteAction();
                  setShowModal(false);
                },
              },
              {
                show: true,
                id: 2,
                btnText: 'delete',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
                isTransBtn: false,
                onClick: () => {
                  onDeleteAction();
                },
              },
            ]}
          />
        }
      >
        <VStack space={3}>
          <DisplayText size={'mdNormal'} textLocalId={'removeTag'} />
        </VStack>
      </Drawer>
    </>
  );
};
