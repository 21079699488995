import React from "react";
import {Path, Svg} from "react-native-svg";

function BloodOxygenIcon(params?: {strokeColor?: string, width?: string, height?: string}) {
  return (
    <Svg
      width={params?.width || "16"}
      height={params?.height || "17"}
      fill="none"
      viewBox="0 0 16 17"
    >
      <Path
        stroke={params?.strokeColor || "#8C5AE2"}
        strokeLinecap="round"
        strokeWidth="0.667"
        d="M13.975 11.428a6.667 6.667 0 10-2.907 2.96m-.348-1.2a2 2 0 114 0 2 2 0 01-4 0z"
      ></Path>
    </Svg>
  );
}

export default React.memo(BloodOxygenIcon);
