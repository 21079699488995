import React from 'react';
import {Collapse} from 'antd';
import {Box, Link, VStack, Text} from 'native-base';
import {Colors} from '../../../../../styles';
import {CommonRelatedBadge} from './CommonRelatedBadge';

const {Panel} = Collapse;

export const CompanyPanel = () => {
  return (
    <VStack mt={2} space={2}>
      <Collapse defaultActiveKey={['1']} expandIconPosition={'right'}>
        <Panel header="COMPANIES" key="1">
          <VStack>
            <Box
              borderBottomWidth={1}
              borderBottomColor={Colors.Custom.BorderColor}
              color={'gray'}
              pt={2}
              pb={2}
              pl={15}
            >
              <Link>
                <Text underline color={'coolGray.500'} fontWeight={'300'}>
                  ABC Pvt Ltd.
                </Text>
              </Link>
            </Box>
            <Box
              borderBottomWidth={1}
              borderBottomColor={Colors.Custom.BorderColor}
              color={'gray'}
              pt={2}
              pb={2}
              pl={15}
            >
              <Link>
                <Text underline color={'coolGray.500'} fontWeight={'300'}>
                  XYZ Company
                </Text>
              </Link>
            </Box>
          </VStack>
          <CommonRelatedBadge label="companies" />
        </Panel>
      </Collapse>
      <Collapse defaultActiveKey={['1']} expandIconPosition={'right'}>
        <Panel header="CONTACTS" key="2"></Panel>
      </Collapse>
    </VStack>
  );
};
