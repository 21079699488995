import React from 'react';
import {Text, VStack} from 'native-base';
import {Colors} from '../../../../../../styles';
import {DocStatus} from '../../interfaces';
import { clipText } from './helper';
import {StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  title: {
    fontSize: 20,
    fontWeight: '700',
    color: '#101828',
    marginLeft: 10,
  },
  subText: {
    marginLeft: 10,
    color: Colors.Custom.Gray500,
    fontSize: 14,
  },
});

export const getTitle = (
  isSignedNote: boolean,
  authorName: string,
  signedByUserName?: string,
  formName?: string,
  docStatus?: string,
  signedByUserId?: string,
  authorUUID?: string,

): JSX.Element => {
  const name = formName ? clipText(formName, 32) : '';
  if (isSignedNote) {
    return (
      <VStack>
        <Text style={styles.title}>
          Signed Note: {name}
        </Text>
        {authorUUID === signedByUserId ? (
          <Text style={styles.subText}>
            {`Created and signed by ${signedByUserName}`}
          </Text>
        ) : (
          <>
            {authorUUID && authorName && (
              <Text style={styles.subText}>
                {`Created by ${authorName}`}
              </Text>
            )}
            {signedByUserName && (
              <Text style={styles.subText}>
                {`Signed by ${signedByUserName || '- -'}`}
              </Text>
            )}
          </>
        )}
      </VStack>
    );
  } else if (docStatus === DocStatus.PRELIMINARY) {
    return (
      <VStack>
        <Text style={styles.title}>
         {name}
        </Text>
        <Text
          fontSize="md"
          style={styles.subText}
        >
          In Progress Note
        </Text>
      </VStack>
    );
  }
  return (
    <Text style={styles.title}>
      New Note
    </Text>
  );
};
