import {Modal} from 'antd';
import React, {useState} from 'react';
import {Text, View} from 'react-native';
import {FoldButton} from '../../CommonComponents/FoldButton/FoldButton';
import {BUTTON_TYPE} from '../../../constants';

const PatientChangeAlertModal = (props: {
  showModal: boolean;
  onHandleDiscard: () => void;
  onHandleConfirm: () => void;
}) => {
  const {showModal, onHandleDiscard, onHandleConfirm} = props;
  const [openModal, setOpenModal] = useState(showModal);
  const handleConfirm = () => {
    onHandleConfirm();
    setOpenModal(false);
  };
  const handleDiscard = () => {
    onHandleDiscard();
    setOpenModal(false);
  };
  return (
    <Modal
      title="Change Recipient?"
      className="change-recipient"
      open={openModal}
      cancelText="No"
      okText="Discard"
      okType="danger"
      okButtonProps={{
        style: {
          borderRadius: '0px',
        },
      }}
      footer={
        <View
          style={{
            alignItems: 'center',
            justifyContent: 'flex-end',
            flexDirection: 'row',
          }}
        >
          <FoldButton
            nativeProps={{
              variant: BUTTON_TYPE.SECONDARY,
              marginRight: 3,
              onPress() {
                handleDiscard();
              },
            }}
            customProps={{
              btnText: 'Close',
            }}
          />
          <FoldButton
            nativeProps={{
              variant: BUTTON_TYPE.PRIMARY,
              onPress() {
                handleConfirm();
              },
            }}
            customProps={{
              btnText: 'Change & Discard Content',
            }}
          />
        </View>
      }
      onCancel={() => {
        handleDiscard();
      }}
    >
      <Text>
        Selecting a new recipient will clear the email content you have entered,
requiring you to rewrite the message . Are you sure you want to
        continue?
      </Text>
    </Modal>
  );
};

export default PatientChangeAlertModal;
