import React, { useMemo } from 'react';
import { View, ViewStyle } from 'react-native';
import { svgStyles } from '../SvgStyles';

const ThreeDotIconSvg = (props: {style?: ViewStyle, width?:any}) => {
    const width = props.width || 20;

    const memoizedSvg = useMemo(() => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height="5"
            fill="none"
            viewBox="0 0 20 5"
        >
            <path
                stroke="#3A485F"
                strokeLinecap="round"
                d="M5 2.5a2 2 0 11-4 0 2 2 0 014 0zM12 2.5a2 2 0 11-4 0 2 2 0 014 0zM19 2.5a2 2 0 11-4 0 2 2 0 014 0z"
            ></path>
        </svg>
    ), [width]);

    return (
        <View style={[svgStyles.container, props.style]}>
            {memoizedSvg}
        </View>
    );
};

export default ThreeDotIconSvg;
