import ReactDOM from 'react-dom';
import {ReactComponent} from '@foldhealth/formio-react';
import {Formio} from '@foldhealth/formiojs';
import CustomWrapper from '../CustomWrapper/CustomWrapper';
import CustomClassComponent from '../CustomWrapper/CustomClassComponent';
import {CapabilityResource} from '../CustomWrapper/CustomComponentHelper';
import HomeMonitoringView from './HomeMonitoringView';

export default class Vitals extends CustomClassComponent {

  static schema() {
    return ReactComponent.schema({
      type: 'homeMonitoringView',
      label: 'Home Monitoring',
      key: 'HomeMonitoringView',
    });
  }

  attachReact(element: any) {
    return ReactDOM.render(
      <CustomWrapper
        formOptionData={this.options}
        capabilityList={[CapabilityResource.observation]}
      >
        <HomeMonitoringView
          options={this.options}
          disabled={this.disabled}
          component={this.component}
          validateRef={this.validateRef}
          onChange={this.updateValue}
        />
      </CustomWrapper>,
      element
    );
  }

  static editForm = function () {
    return {
      key: 'display',
      components: [],
    };
  };
}

Formio.Components.addComponent('homeMonitoringView', Vitals);
