import { Dimensions, StyleSheet } from 'react-native';
import { Colors } from '../../../styles/Colors';
const { height } = Dimensions.get('window');

export const styles = StyleSheet.create({
  alignItemsCenter: {
    alignItems: 'center',
  },
  alignItemsCenterFlex1: { 
    alignItems: 'center', 
    flex: 1 
  },
  alignItemsCenterJustifyContentSpaceBetween: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  alignItemsCenterJustifySpaceBetween: {
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  button: {
    backgroundColor: 'transparent',
    marginBottom: '5px',
    paddingLeft: '0px',
    paddingRight: '0px',
  },
  colorError: {
    color: Colors.Custom.ErrorColor,
  },
  colorGray300FontSize14: {
    color: Colors.FoldPixel.GRAY300,
    fontSize: 14
  },
  colorGray500MarginLeft8: {
    color: Colors.Custom.Gray500,
    marginLeft: 8
  },
  colorGray500MarginLeft8Flex1: {
    color: Colors.Custom.Gray500,
    marginLeft: 8,
    flex: 1,
  },
  colorPrimary300: {
    color: Colors.Custom.Primary300
  },
  columnContainer: {
    flexDirection: 'column',
    width: 'fit-content',
  },
  container: {
    width: '100%',
    position: 'relative',
  },
  contactCell: {
    padding: 12,
    borderColor: Colors.Custom.Gray200,
    borderRightWidth: 1,
    // backgroundColor: '#fff',
  },
  contactInfoContainer: {
    height: 50,
  },
  defaultCell: {
    padding: 12,
    borderColor: Colors.Custom.Gray200,
    borderBottomWidth: 1,
    // backgroundColor: '#fff',
  },
  flex1: {
    flex: 1
  },
  flex2: {
    flex: 2,
  },
  flex5: {
    flex: 5,
  },
  fontSize14ColorGray400: {
    fontSize: 14,
    color: Colors.Custom.Gray400
  },
  fontSize12ColorGray400: {
    fontSize: 14,
    color: Colors.Custom.Gray400
  },
  fontSize14FontWeight500ColorGray600: {
    fontSize: 14,
    fontWeight: '500',
    color: Colors.Custom.Gray600
  },
  fontSize16ColorCustomGray400Padding0MarginLeft4: {
    fontSize: 16,
    color: Colors.Custom.Gray400,
    padding: 0,
    marginLeft: 4
  },
  fontSize24FontWeight500ColorGray800: {
    fontSize: 24,
    fontWeight: '500',
    color: Colors.Custom.Gray800
  },
  headerCell: {
    // backgroundColor: Colors.Custom.Gray200,
    paddingHorizontal: 12,
    paddingVertical: 8,
    borderColor: Colors.Custom.Gray200,
    borderBottomWidth: 1,
    marginBottom: -1,
    borderTopWidth: 1,
  },
  headerText: {
    color: Colors.Custom.Gray500,
    fontSize: 14,
  },
  height50: {
    height: 50
  },
  height77P: {
    height: height * 0.77
  },
  justifyContentFlexEnd: {
    justifyContent: 'flex-end'
  },
  justifyContentSpaceBetween: {
    justifyContent: 'space-between',
  },
  marginBottom8: {
    marginBottom: 8
  },
  marginHorizontal6: {
    marginHorizontal: 6
  },
  marginMinus8MarginRightMinus16MaxHeightHeight50PercentOverflowScroll: {
    margin: -8,
    marginRight: -16,
    maxHeight: height * 0.5,
    overflow: 'scroll',
  },
  marginRight6: {
    marginRight: 6
  },
  marginTop1: {
    marginTop: 1
  },
  marginTop2: {
    marginTop: 2
  },
  marginTop8: {
    marginTop: 8
  },
  marginTop2MarginLeft4: {
    marginTop: 2,
    marginLeft: 4
  },
  marginTop44JustifyCenter: {
    marginTop: 44,
    justifyContent: 'center'
  },
  nodataView: {
    paddingTop: 30,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  nodeView2: {
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 24
  },
  overflowHidden: {
    overflow: 'hidden'
  },
  padding0: {
    padding: 0
  },
  padding0MarginRight2: {
    padding: 0,
    marginRight: 2
  },
  padding12: {
    padding: 12
  },
  padding12AlignItemsCenter: {
    padding: 12,
    alignItems: 'center'
  },
  padding16: {
    padding: 16
  },
  paddingTop8: {
    paddingTop: 8
  },
  paddingVertical4WidthFull: {
    paddingVertical: 4,
    width: '100%'
  },
  paddingVertical8Width70: {
    paddingVertical: 8,
    width: 70
  },
  pointText: {
    fontSize: 14,
  },
  pointsCell: {
    padding: 12,
    borderColor: Colors.Custom.Gray200,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    marginBottom: -1,
    // backgroundColor: '#fff',
    flexDirection: 'column',
  },
  pressableStyles: {
    paddingVertical: 8,
    borderRightColor: Colors.Custom.Gray200,
    borderRightWidth: 1,
    width: 70
  },
  rowContainer: {
    flexDirection: 'row',
  },
  scrollContainer: {
    maxWidth: '100%',
    width: 'fit-content',
    borderColor: Colors.Custom.Gray200,
    borderWidth: 1,
    borderRadius: 8,
  },
  stackStyle: {
    paddingVertical: 6,
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  stackStyles1: {
    alignItems: 'center', 
    marginTop: 8
  },
  stringCell: {
    padding: 12,
    borderColor: Colors.Custom.Gray200,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    marginBottom: -1,
    // backgroundColor: '#fff',
  },
  text: {
    fontSize: 24,
    fontWeight: '500',
    color: Colors.Custom.Gray800,
  },
  text2: {
    color: Colors.Custom.AlertsDescriptionColor,
    fontSize: 14,
  },
  text3: {
    color: Colors.Custom.Gray500,
    fontSize: 16
  },
  text4: {
    color: Colors.Custom.Gray400,
    fontSize: 12,
    marginTop: 5
  },
  text5: {
    fontSize: 12,
    width: 'fit-content',
    paddingHorizontal: 6,
    paddingTop: 2,
    paddingBottom: 4,
    color: Colors.FoldPixel.GRAY300,
    backgroundColor: Colors.FoldPixel.GRAY50,
    borderRadius: 4
  },
  text6: {
    color: Colors.FoldPixel.GRAY300, 
    marginTop: 8, 
    fontSize: 14
  },
  text7: {
    color: Colors.FoldPixel.GRAY300,  
    fontSize: 15,
    marginTop: 2
  },
  view: {
    backgroundColor: Colors.Custom.Gray500,
    width: 0.2,
    height: 16,
  },
  view1: { 
    width: '100%', 
    alignItems: 'center', 
    paddingTop: 6 
  },
  viewStyle: {
    // backgroundColor: Colors.Custom.Gray100,
    borderRadius: 20,
    paddingLeft: 12,
    paddingRight: 0,
  },
  viewStyle2: {
    width: 1,
    height: 20,
    backgroundColor: Colors.Custom.Gray300,
    marginHorizontal: 2
  },

  justifyContentFlexEndFlex1: {
    justifyContent:'flex-end', 
    flex: 1
  },
  positionRelative: {
    position:'relative'
  },
  marginLeft2: {
    marginLeft: 2
  },
  stackStyles: {
    justifyContent: 'space-between', 
    alignItems:'center', 
    margin: 12
  },
  width40AlignItemsCenter: {
    width: 40, 
    alignItems:'center'
  },
  paddingHorizontal8: {
    paddingHorizontal: 8
  },
  paddingHorizontal8PaddingTop2: {
    paddingHorizontal: 8,
    paddingTop: 2
  },
  stack: {
    alignItems: 'center',
    marginVertical: 16,
    marginHorizontal: 18,
    marginBottom: 10,
  },
  marginVertical10: {
    marginVertical: 10,
  },
  pressable: {
    width: 36,
    height: 36,
    justifyContent: 'center',
    alignItems: 'center',
  },
  collapsedView: {
    borderRadius: 8,
    // backgroundColor: 'white',
    overflow: 'hidden',
  },
  minWidth40MarginBotton2: {
    minWidth: 40, 
    marginBottom: 2
  },
  justifyContentSpaceBetweenAlignItemsCenter: {
    justifyContent: 'space-between',
    alignItems: 'center',
  }
})

export const reactStyles: Record<string, React.CSSProperties> = {
  arrayContainer: {
    display: "grid",
    gridTemplateColumns: "auto",
    gridAutoRows: "1fr",
  },
  input: {
    borderRadius: 4,
  },
  padding0: {
    padding: 0
  },
  collapse: {
    borderRadius: 12,
    borderWidth: 0,
    padding: 0,
    background: Colors.Custom.Gray100,
    marginTop: 0,
  }
}