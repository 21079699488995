import {Badge, HStack, Pressable, Text} from 'native-base';
import {Colors} from '../../../../../styles';
interface commonRelatedBadge {
  label: string;
}
export const CommonRelatedBadge = (props: commonRelatedBadge) => {
  return (
    <HStack justifyContent={'center'} alignItems={'center'} pt={3} pb={3}>
      <Pressable>
        <Badge justifyContent={'flex-end'} borderRadius={30}>
          <Text fontSize={'xs'} color={Colors.Custom.DataKeyColor}>
            See related {props.label}
          </Text>
        </Badge>
      </Pressable>
    </HStack>
  );
};
