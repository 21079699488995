import BaseService from '../../../../../../services/CommonService/BaseService';
import CommonService from '../../../../../../services/CommonService/CommonService';
import {getMsgEchoId} from '../../../../../../utils/commonUtils';
import {getUserIdListFromMsgContent} from '../../MessagingWindow/ConversationMentions/ConversationMentions';
import {
  ICreateSmsInboxConversationParams,
  ISendMessageParams,
  ISendEmailMessageParams,
  IScheduleMessageParams,
  IGetWebhookEventIdParams,
  IReScheduleMessageParams,
  IDeleteScheduleMessageParams,
  ISendScheduleParams
} from './interface';

export const createSmsInboxConversation = (
  params: ICreateSmsInboxConversationParams,
  accountId: any
) => {
  const bodyParams = params.bodyParams;
  const baseService = BaseService.getSharedInstance().axios;

  return baseService.post(
    `/chatwoot/api/v1/accounts/${accountId}/conversations`,
    bodyParams
  );
};

export const sendMessageAPI = (params: ISendMessageParams) => {
  const commonService = CommonService.getCommonServiceInstance();
  const axiosService = commonService.axiosService;
  const messageParams:any = {
    echo_id: getMsgEchoId(),
    cc_emails: '',
    bcc_emails: '',
    private: params?.privateNote + '' || 'false',
    user_mention_ids: '',
  };
  if (params?.groupMemberCodeIdObj && Object.keys(params?.groupMemberCodeIdObj)?.length) {
    const userMentionIds = getUserIdListFromMsgContent(params?.textContent, params?.groupMemberCodeIdObj);
    if (userMentionIds?.length) {
      messageParams.user_mention_ids = userMentionIds.toString();
    }else {
      messageParams.content = params?.textContent
    }
  }
  return axiosService.post(
    `/accounts/${params.accountId}/conversations/${params.conversationId}/messages`,
    messageParams,
    {
      headers: {
        'content-type': 'application/json',
      },
    }
  );
};


export const sendEmailMessageAPI = (params: ISendEmailMessageParams) => {
  const baseService = BaseService.getSharedInstance();
  const axiosService = baseService.axios;
  const messageParams = {
    echo_id: getMsgEchoId(),
    private: params?.private,
    content: params.content,
    conversationDisplayId: params?.conversationDisplayId,
    inboxId: params?.inboxId,
    subject: params?.subject,
    user_mention_ids: params?.user_mention_ids,
    attachmentIds: params?.attachmentIds,
  };
  return axiosService.post(`crm-nest/conversation/email-message`,
    messageParams,
    {
      headers: {
        'content-type': 'application/json',
      },
    }
  );
};


export const scheduleMessageApi = (params: IScheduleMessageParams) => {
  const baseService = BaseService.getSharedInstance();
  const axiosService = baseService.axios;
  return axiosService.post(`crm-nest/conversation/schedule-message`,
    params,
    {
      headers: {
        'content-type': 'application/json',
      },
    }
  );
};

export const getWebhookEventId = (params: IGetWebhookEventIdParams) => {
  const baseService = BaseService.getSharedInstance();
  const axiosService = baseService.axios;
  return axiosService.post(`crm-nest/conversation/scheduleMessage-getWebhookEventId`,
    params,
    {
      headers: {
        'content-type': 'application/json',
      },
    }
  );
};

export const reScheduleMessageApi = (params: IReScheduleMessageParams) => {
  const baseService = BaseService.getSharedInstance();
  const axiosService = baseService.axios;
  return axiosService.put(`crm-nest/account/conversation/${params?.conversationId}/message/schedule`,
    params,
    {
      headers: {
        'content-type': 'application/json',
      },
    }
  );
};

export const deleteScheduleMessageApi = (params: IDeleteScheduleMessageParams) => {
  const baseService = BaseService.getSharedInstance();
  const axiosService = baseService.axios;
  return axiosService.put(`crm-nest/account/conversation/${params?.conversationId}/message/update-schedule`,
    params
  );
};

export const sendScheduleMessageApi = (params: ISendScheduleParams) => {
  const baseService = BaseService.getSharedInstance();
  const axiosService = baseService.axios;
  return axiosService.post(`crm-nest/account/conversation/${params?.conversationId}/message/send-schedule`,
    params,
    {
      headers: {
        'content-type': 'application/json',
      },
    }
  );
};