import {Text, View, VStack} from 'native-base';
import {IDateInputProps} from '../interfaces';
import {styles} from './QuestionnaireComponentStyles';
import { DatePicker } from 'antd';
import { getMomentObj, getMomentObjectWithDateStringAndFormat } from '../../../../../../utils/DateUtils';
import { DISPLAY_DATE_FORMAT } from '../../../../../../constants';
const antdStyles: Record<string, React.CSSProperties> = {
  datePicker: {
    height: '40px'
  }
}

const DateInput = (props: IDateInputProps) => {
  const {title, format, onChange} = props;

  return (
    <VStack style={styles.container}>
      <View style={styles.titleView}>
        <Text style={styles.titleText}>{title}</Text>
      </View>
      <View>
      <DatePicker
        style={antdStyles.datePicker}
        allowClear={false}
        disabled={props.readonly}
        defaultValue={props.answer ? getMomentObjectWithDateStringAndFormat(
          props.answer,
          format || ''
        ) : undefined}
        format={DISPLAY_DATE_FORMAT}
        onChange={(date: any) => {
          const selectedDate = format ? date?.format(format) : getMomentObj(date).toISOString();
          onChange(selectedDate);
        }}
      />
      </View>
    </VStack>
  );
};

export default DateInput;
