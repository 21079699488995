import React from 'react';
import {Popover, Tooltip} from 'antd';
import ThreeDotIconSvg from '../Svg/InterventionSvg/ThreeDotIconSvg';
import {Divider, FlatList, Pressable, Text, View} from 'native-base';
import OutboundCallSmsView from '../OutboundCallSmsView/OutboundCallSmsView';
import {PERSON_ACTION_CODES} from '../../PersonOmniView/PersonHeaderBar/PersonAction/PersonActionPopover/ActionConst';
import {IContact} from '../../RightSideContainer/TeamInbox/Conversations/interfaces';
import Stack from '../LayoutComponents/Stack';
import {DisplayText} from '../DisplayText/DisplayText';
import {Colors} from '../../../styles';
import { IActionView } from '../../../screens/MembersScreens/interface';

interface ContactRelationCommunicationViewProps {
  actions: IActionView[];
  onActionPerformed: (action: string) => void;
  isOutboundViewVisible?: boolean;
  contact?: IContact;
  handleActions: (actionCode: string, rowData?: any) => void;
  extraActions?: IActionView[];
}

export const ContactRelationCommunicationView = (
  props: ContactRelationCommunicationViewProps
) => {
  const {actions, isOutboundViewVisible, contact, handleActions, extraActions} =
    props;

  const horizontalActions: IActionView[] = [];
  const verticalActions : IActionView[]= [];

  actions.forEach((action)=> {
    if(action.showHorizontal){
      horizontalActions.push(action);
    }
    else {
      verticalActions.push(action)
    }
  })

  const renderActionItemView = (item: IActionView, index: number) => {
    const showHorizontal = item.showHorizontal;
    return (
      <Tooltip title={item.tooltip}>
        <Pressable
          disabled={item.isDisabled}
          flexDirection="row"
          alignItems="center"
          my={showHorizontal ? 0 : 2}
          onPress={() => props.onActionPerformed(item.action || '')}
        >
          {item.icon}
          {!showHorizontal && (
            <Text
              color={item.isDisabled ? Colors.Custom.Gray300 : item.btnTextColor}
              fontSize={14}
              fontWeight="400"
              marginLeft={3}
            >
              {item.btnText}
            </Text>
          )}

          {showHorizontal && (
            <Divider orientation="vertical" height={3} mx={2} width={'0.5px'} />
          )}
        </Pressable>
      </Tooltip>
    );
  };

  const renderPopoverView = (actions: IActionView[]) => {
    return (
      <Stack direction="column">
        {actions.length > 0 && (
          <>
            <DisplayText
              textLocalId="OTHER MEDIUM"
              extraStyles={{
                color: Colors.FoldPixel.GRAY200,
                marginTop: 6,
                marginBottom: 8,
              }}
            />
            {renderActionMenu(actions, false)}
          </>
        )}
        {extraActions && extraActions?.length > 0 ? (
          <>
            {actions.length > 0 && <Divider marginTop={2} marginBottom={3} />}
            <DisplayText
              textLocalId="ACTIONS"
              extraStyles={{
                color: Colors.FoldPixel.GRAY200,
                marginBottom: 4,
              }}
            />
            {renderActionMenu(extraActions, false)}
          </>
        ) : (
          <></>
        )}
      </Stack>
    );
  };

  const renderActionMenu = (actions: IActionView[], horizontal: boolean) => {
    return (
      <FlatList
        data={actions}
        horizontal={horizontal}
        keyExtractor={(item) => item.btnText}
        renderItem={({item, index}) => (
          <>
            {item.action === PERSON_ACTION_CODES.CALL ? (
              <OutboundCallSmsView
                isHideSms={true}
                showOnlyCallNumbers={true}
                visible={!!isOutboundViewVisible}
                selectedContactData={null}
                content={<>{renderActionItemView(item, index)}</>}
                handleAction={(actionCode: string, rawData?: any) => {
                  handleActions(actionCode, rawData);
                }}
              />
            ) : (
              renderActionItemView(item, index)
            )}
          </>
        )}
      />
    );
  };

  return (
    <Stack direction="row">
      {renderActionMenu(horizontalActions, true)}
      <Popover
        overlayClassName="custom-popover-styles"
        showArrow={true}
        destroyTooltipOnHide
        placement="bottomRight"
        content={renderPopoverView(verticalActions)}
        trigger="click"
        overlayInnerStyle={{
          borderRadius: 16,
          width: 140,
          padding: 0,
        }}
        style={{cursor: 'pointer'}}
      >
        <View cursor={'pointer'}>
          <ThreeDotIconSvg style={{padding: 8, margin: 0}} />
        </View>
      </Popover>
    </Stack>
  );
};
