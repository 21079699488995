import React from 'react';
import moment, {DurationInputArg2} from 'moment';

import {
  DataType,
  FilterTypes,
  GraphsTypes,
  IEffectivePeriod,
  IFilterParams,
  IGraphConfig,
  IGraph,
  IHighLightValues,
  ISleepStage,
  IVitals,
  SubFilterTypes,
  VitalDataSubFilterMap,
  VitalGraphData,
} from './interface';
import {
  getDataAvailableCountString,
  getDataSetByDateRange,
  getFormatttedDateByFilterType,
  getVitalDataArray,
} from './CommonUtils';
import { Vital } from '../../../../../../utils/VitalUtils';
import { addTimeToDate, convertMinsToHours, convertMinsToHoursAndMinutes, getDatesBetweenTwoDates, getDiffBetweenTwoTime, getFormattedDate, getMomentObj, getStartOfDay, getTimeDifferenceInMinutes, isDateBetweenRange } from '../../../../../../utils/DateUtils';
import { DATE_FORMATS, DISPLAY_DATE_FORMAT } from '../../../../../../constants';
import { SLEEP_STAGES } from './HomeMonitoringConstants';
import { cloneDeep } from 'lodash';
import Stack from '../../../../../common/LayoutComponents/Stack';
import {getAnonationIndex} from '../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailTables/VitalsGraphView/VitalsGraphUtils';
import {GraphInfoItem} from './components/GraphInfoItem';
import {Colors} from '../../../../../../styles';
import {Text} from 'native-base';
import {View} from 'react-native';
import { isWeb } from '../../../../../../utils/platformCheckUtils';
import { styles } from '../../../Analytics/style';

const getSleepStageColors = (vital: Vital) => {
  switch (vital) {
    case Vital.sleep:
      return Colors.Custom.DEEP_SLEEP;
    case Vital.rem_sleep:
      return Colors.Custom.REM_SLEEP;
    case Vital.awake:
      return Colors.Custom.AWAKE_SLEEP;
    case Vital.light_sleep:
      return Colors.Custom.LIGHT_SLEEP;
    case Vital.deep_sleep:
      return Colors.Custom.DEEP_SLEEP;
  }
};

const getSleepStageText = (code: string) => {
  return SLEEP_STAGES.find((sleepStage) => code === sleepStage.code)?.display;
};

export const getSleepGraphTypeBasedOnFilterType = (
  filterType: keyof typeof FilterTypes,
  subFilterType: VitalDataSubFilterMap | undefined,
  sleepStagesAvailable: boolean
) => {
  switch (filterType) {
    case FilterTypes.Day:
      if (sleepStagesAvailable) {
        return GraphsTypes.BAR;
      } else {
        return GraphsTypes.PIE_CHART;
      }
    case FilterTypes.Week:
    case FilterTypes.Month:
    case FilterTypes.ThreeMonths:
    case FilterTypes.SixMonths:
      if (subFilterType?.[Vital.sleep]?.includes(SubFilterTypes.STAGE)) {
        return GraphsTypes.SPLIT_BAR;
      } else {
        return GraphsTypes.BAR;
      }
    default:
      return GraphsTypes.BAR;
  }
};

export const getHoursAndMinsText = (totalMins: number) => {
  const {hours, minutes} = convertMinsToHoursAndMinutes(totalMins);
  let hoursMinsText = '';
  if (hours) {
    hoursMinsText += hours + ' hr ';
  }
  if (minutes) {
    hoursMinsText += minutes + ' min';
  }
  return hoursMinsText;
};

export const getDiffInHours = (effectivePeriod: IEffectivePeriod) => {
  const mins = getTimeDifferenceInMinutes(
    effectivePeriod?.start || '',
    effectivePeriod?.end || '',
    true,
  ) as number;
  return (mins/60).toFixed(1)
}

export const getFormattedStagesData = (sortedData: IVitals[]) => {
  const reducedStagesObj = sortedData?.reduce((acc: any, item) => {
    if (item.subtext) {
      const convertedDate = getFormattedDate(
        item.date,
        DISPLAY_DATE_FORMAT,
      );
      const dateObj = acc?.[convertedDate];
      if (dateObj) {
        let sleepStagesObj = dateObj?.sleepStages?.[item.text];

        if (sleepStagesObj) {
          sleepStagesObj = {
            [item.text]: {
              ...sleepStagesObj,
              value: parseFloat(sleepStagesObj.value) + parseFloat(item.value),
            },
          };
          dateObj.effectivePeriod = {
            ...dateObj.effectivePeriod,
            end: item.effectivePeriod?.end
          }
        } else {
          sleepStagesObj = {
            [item.text]: {
              value: cloneDeep(item.value),
              code: item.text,
              subtext: item.subtext,
            },
          };
        }

        dateObj.sleepStages = {
          ...dateObj.sleepStages,
          ...sleepStagesObj,
        };

        if(item.text === Vital.sleep) {
          dateObj.totalValue = parseFloat(sleepStagesObj?.[item.text].value);
        }
      } else {
        if (!acc) {
          acc = {};
        }
        acc[convertedDate] = {
          sleepStages: {
            [item.text]: {
              value: cloneDeep(item.value),
              code: item.text,
              subtext: item.subtext,
            },
          },
          effectivePeriod: cloneDeep(item.effectivePeriod),
          totalValue: cloneDeep(parseFloat(item.value)),
        };
      }
      return acc;
    }
  }, []);
  return reducedStagesObj as ISleepStage;
};

const groupVitalDataByTimeUnit = (
  vitalData: IVitals[],
  timeUnit: DurationInputArg2 | undefined,
) => {
  const dataMap = new Map<string, IVitals>();
  if (timeUnit) {
    vitalData.forEach((item) => {
      const date = getMomentObj(item.effectivePeriod?.end || item.date);
      const startDate = date.startOf(timeUnit).toISOString();
      const endDate = date.endOf(timeUnit).toISOString();

      const value = cloneDeep(item);
      value.effectivePeriod = {
        start: startDate,
        end: endDate,
      };
      dataMap.set(startDate, value);
    });
  }

  return dataMap;
};

const getFormattedDisplayTime = (dateTime: string) => {
  return getFormattedDate(dateTime, DATE_FORMATS.DISPLAY_TIME_FORMAT);
};

export const getSleepDayStagesGraphData = (
  filterParams: IFilterParams,
  stagesData: ISleepStage,
) => {
  const graphData: IGraph[] = [];
  const highLightValues: IHighLightValues[] = [];
  let totalValue = 0;
  let totalStagesValue = 0;
  let totalStagesPercent = 0;
  let effectivePeriod;
  const isOnlySleepStageAvailable = checkOnlySleepStagesExist(stagesData);

  const uniqueStages = getFilteredStages(stagesData);
  const uniqueStagesCodes = uniqueStages?.map(
    (uniqueStage) => uniqueStage.code
  );

  let awakeValue = 0;

  for (const dateKey in stagesData) {
    const dateObj = stagesData[dateKey];
    const currentDate = new Date(dateObj?.effectivePeriod?.end || '');
    const isDateInRange = isDateBetweenRange(currentDate, filterParams.dateRange.start, filterParams.dateRange.end, true)
    if(isDateInRange) {
      for (const stagesKey in dateObj?.sleepStages) {
        if (uniqueStagesCodes.includes(stagesKey as Vital)) {
          const sleepStagesObj = dateObj?.sleepStages;
          if (sleepStagesObj?.[Vital.awake]) {
            awakeValue = parseFloat(sleepStagesObj?.[Vital.awake].value);
          }
          effectivePeriod = dateObj.effectivePeriod;
          const stagesObj = sleepStagesObj[stagesKey];
          totalValue = dateObj?.totalValue;
          const percent = (
            (parseFloat(stagesObj.value) / dateObj?.totalValue) *
            100
          ).toFixed(0);
          totalStagesValue += parseFloat(stagesObj.value);
          totalStagesPercent += parseFloat(percent);
          graphData.push({
            x: stagesKey,
            y: parseFloat(percent),
            dateStr: stagesKey,
            metaData: {
              titleText: stagesObj?.subtext?.replace(' Sleep', ''),
              subTitleText: `${getHoursAndMinsText(
                parseFloat(stagesObj?.value)
              )} (${percent}%)`,
              pieColor: getSleepStageColors(stagesObj?.code as Vital),
            },
          });
        }
      }
    }
  }

  // if (awakeValue) {
  //   highLightValues.push({
  //     value: getMinsToHoursWithUnit(totalValue - awakeValue).value,
  //     subValue: 'Time Asleep',
  //   });
  // }

  const unknownValue = totalValue - totalStagesValue;
  const unknownValuePercent = 100 - totalStagesPercent;
  if (unknownValuePercent > 0 && totalStagesPercent > 0 && unknownValue > 0) {
    const percent = 100 - totalStagesPercent;
    graphData.push({
      x: 'unknown',
      y: percent,
      dateStr: 'unknown',
      metaData: {
        titleText: 'Unknown',
        subTitleText: `${getHoursAndMinsText(
          unknownValue,
        )} (${percent}%)`,
        pieColor: Colors.Custom.Gray100,
      },
    });
  }

  highLightValues.push({
    value: getMinsToHoursWithUnit(totalValue).value,
    subValue: 'Time in Bed',
  });

  if (effectivePeriod) {
    const startTime = getFormattedDisplayTime(effectivePeriod?.start);
    const endTime = getFormattedDisplayTime(effectivePeriod?.end);
    highLightValues.push({
      value: `${startTime} - ${endTime}`,
      subValue: 'In Bed Start & In Bed End',
      hideUnit: true,
    });
  }

  const graphType = getSleepGraphTypeBasedOnFilterType(
    filterParams.filterType as keyof typeof FilterTypes,
    filterParams.subFilterType,
    isOnlySleepStageAvailable
  );

  const params = {graphData, highLightValues, graphType};

  return getGraphObj(filterParams, params);
};

const getFilteredStages = (stagesData: ISleepStage) => {
  return checkOnlySleepStagesExist(stagesData)
  ? SLEEP_STAGES?.filter((sleepStage) => sleepStage.code === Vital.sleep)
  : SLEEP_STAGES?.filter((sleepStage) => sleepStage.code !== Vital.sleep);
}

const getStageAverageValue = (
  stagesData: ISleepStage,
  startDateTime: string,
  endDateTime: string,
) => {
  let totalSleepHours = 0;
  let totalCount = 0;
  let minValue = 0;
  let maxValue = 0;

  let sleepStages = getFilteredStages(stagesData).map((sleepStage) => {
    return {...sleepStage, totalValue: 0, totalCount: 0};
  });

  for (const dateKey in stagesData) {
    const dateObj = stagesData[dateKey];
    const currentDate = new Date(dateObj?.effectivePeriod?.end || '');
    if (isDateBetweenRange(currentDate, startDateTime, endDateTime, true)) {
      totalSleepHours += dateObj.totalValue;
      totalCount += 1;

      if (!minValue) {
        minValue = dateObj.totalValue;
      } else if (dateObj.totalValue < minValue) {
        minValue = dateObj.totalValue;
      }

      if (!maxValue) {
        maxValue = dateObj.totalValue;
      } else if (dateObj.totalValue > maxValue) {
        maxValue = dateObj.totalValue;
      }
      sleepStages = sleepStages.map((sleepStage) => {
        if (dateObj.sleepStages?.[sleepStage.code]?.value) {
          sleepStage.totalValue =
            sleepStage.totalValue +
            parseFloat(dateObj.sleepStages[sleepStage.code].value);
          sleepStage.totalCount = sleepStage.totalCount + 1;
        }
        return sleepStage;
      });
    }
  }
  if (totalSleepHours === 0) {
    return {avg: 0, min: 0, max: 0};
  }

  let avgStagesText = '';
  sleepStages.forEach((sleepStage) => {
    if (sleepStage.totalValue && sleepStage.totalCount) {
      avgStagesText += `Avg ${sleepStage.display} : ${getHoursAndMinsText(
        sleepStage.totalValue / sleepStage.totalCount,
      )}\n`;
    }
  });
  return {
    avg: totalSleepHours / totalCount,
    min: minValue,
    max: maxValue,
    avgStagesText,
  };
};

const getSleepAverageValue = (
  stagesData: ISleepStage,
  startDateTime: string,
  endDateTime: string
) => {
  let totalSleepHours = 0;
  let totalCount = 0;
  let minValue = 0;
  let maxValue = 0;

  for (const dateKey in stagesData) {
    const dateobj = stagesData[dateKey];
    const currentDate = new Date(dateobj?.effectivePeriod?.end || '');
    if (isDateBetweenRange(currentDate, startDateTime, endDateTime, true)) {
      const value = dateobj.totalValue;
      totalSleepHours += value;
      totalCount += 1;

      if (!minValue) {
        minValue = value;
      } else if (value < minValue) {
        minValue = value;
      }

      if (!maxValue) {
        maxValue = value;
      } else if (value > maxValue) {
        maxValue = value;
      }
    }
  }

  if (totalSleepHours === 0) {
    return {avg: 0, min: 0, max: 0};
  }

  const avg = totalSleepHours ? totalSleepHours / totalCount : 0;

  return {avg: avg, min: minValue, max: maxValue};
};

const getSleepingTimeAverageValue = (
  sortedData: IVitals[],
  startDateTime: string,
  endDateTime: string,
  isStartTimeAvg: boolean,
) => {
  const betweenRangesData = new Map<string, IVitals>();

  sortedData.forEach((vitalObj) => {
    if (
      isDateBetweenRange(new Date(vitalObj.date), startDateTime, endDateTime, true)
    ) {
      betweenRangesData.set(vitalObj.date, vitalObj);
    }
  });

  let totalDiffMins = 0;
  let totalMins = 0;

  let totalDiffCount = 0;

  Array.from(betweenRangesData.entries()).forEach(([key, value]) => {
    const endDateMoment = getStartOfDay(value?.effectivePeriod?.end || '');
    const diffInMinutes = getTimeDifferenceInMinutes(
      endDateMoment,
      isStartTimeAvg
        ? value?.effectivePeriod?.start || ''
        : value?.effectivePeriod?.end || '',
      true,
    ) as number;


    const startTimeDiff = getTimeDifferenceInMinutes(
      endDateMoment,
      value?.effectivePeriod?.start || '',
      true,
    ) as number;

    //ignore if start time is greater than 12 pm
    if(startTimeDiff < 60 * 12) {
      totalMins = totalMins + parseInt(value?.value);
      totalDiffMins = totalDiffMins + diffInMinutes;
      totalDiffCount += 1;
    }
  });

  const avgDiffMins = totalDiffMins / totalDiffCount;

  const averageSleep = totalMins / totalDiffCount;

  const avgDateTime = addTimeToDate(
    getStartOfDay(new Date()),
    avgDiffMins,
    'MINUTE',
  );

  return {avgDateTime: avgDateTime?.toISOString(), averageSleep: averageSleep};
};

const checkOnlySleepStagesExist = (sleepStages: ISleepStage) => {
  const uniqueSleepStages: string[] = [];
  for (const key in sleepStages) {
    for (const stageKey in sleepStages[key]?.sleepStages) {
      if (!uniqueSleepStages.includes(stageKey)) {
        uniqueSleepStages.push(stageKey);
      }
    }
  }
  if (
    uniqueSleepStages.length === 1 &&
    uniqueSleepStages?.[0] === Vital.sleep
  ) {
    return true;
  }
  return false;
}

export const getSleepWeekMonthStagesGraphData = (
  filterParams: IFilterParams,
  stagesData: ISleepStage,
  sortedData: IVitals[]
) => {
  const xTickValues: string[] = [];
  const graphData: IGraph[] = [];
  const highLightValues: IHighLightValues[] = [];
  const isOnlySleepStageAvailable = checkOnlySleepStagesExist(stagesData);
  const uniqueStages = getFilteredStages(stagesData);
  const uniqueStagesCodes = uniqueStages?.map(
    (uniqueStage) => uniqueStage.code
  );

  const colorScale = uniqueStages.map(
    (stagesObj) => getSleepStageColors(stagesObj.code || ('' as Vital)) || '',
  );

  if (sortedData?.length > 0) {
    uniqueStages?.forEach((uniqueStage) => {
      let totalValue = 0;
      let totalCount = 0;
      let stageCode = '';
      let sleepStages;
      let sleepStagesToolTipText = '';
      const uniqueStageArr: IGraph[] = [];
      for (const dateKey in stagesData) {
        let stageValue = '0';
        sleepStagesToolTipText = '';
        const vitalObj = stagesData[dateKey];
        for (const stagesKey in stagesData[dateKey]?.sleepStages) {
          if (uniqueStagesCodes.includes(stagesKey as Vital)) {
            sleepStages = stagesData[dateKey]?.sleepStages;
            const stagesObj = sleepStages[stagesKey];
            const hoursObj = getMinsToHoursWithUnit(parseFloat(stagesObj.value));
            sleepStagesToolTipText += `\n${getSleepStageText(stagesKey)}: ${
              hoursObj?.value
            } ${hoursObj?.unit}`;
            if (uniqueStage.code === stagesObj.code) {
              stageValue = stagesObj.value;
              stageCode = stagesKey;
            }
          }
        }
        const formattedDate = getFormatttedDateByFilterType(
          vitalObj?.effectivePeriod?.end || '',
          FilterTypes.Month as keyof typeof FilterTypes,
        );

        const isFound = uniqueStageArr?.find(
          (uniqueObj) => uniqueObj.x === formattedDate,
        );

        const tooltipText = getSleepTooltipText(
          vitalObj.effectivePeriod,
          vitalObj.totalValue,
          sleepStagesToolTipText,
        );

        if (!isFound) {
          totalValue += parseFloat(stageValue);
          totalCount += 1;
          uniqueStageArr.push({
            x: formattedDate,
            y: convertMinsToHours(parseFloat(stageValue)),
            tooltipText: tooltipText,
            dateStr: formattedDate,
          });
        }
      }

      const dataBetweenRangeFilter = getDatesBetweenTwoDates(
        filterParams?.dateRange?.start,
        filterParams?.dateRange?.end,
        'days'
      );

      const allDatesStagesArr: IGraph[] = [];

      dataBetweenRangeFilter?.forEach((date) => {
        const formattedDate = getFormatttedDateByFilterType(
          date,
          FilterTypes.Month as keyof typeof FilterTypes
        );
        xTickValues.push(formattedDate);
        const uniqueStageDateObj = uniqueStageArr?.find((uniqueStageData) => {
          const formattedUniqueStageDate = getFormatttedDateByFilterType(
            uniqueStageData.x,
            FilterTypes.Month as keyof typeof FilterTypes
          );
          return formattedUniqueStageDate === formattedDate;
        });

        if (uniqueStageDateObj) {
          allDatesStagesArr.push(uniqueStageDateObj);
        } else {
          allDatesStagesArr.push({
            x: formattedDate,
            y: 0,
            tooltipText: '',
            dateStr: '',
          });
        }
      });

      graphData.push({
        x: 'x',
        y: 'y',
        dateStr: '',
        subGraphData: allDatesStagesArr,
        metaData: {
          colorScale: colorScale,
        },
      });

      if (totalValue) {
        const avgValue = totalValue ? totalValue / totalCount : 0;
        const valueText =
          avgValue < 60
            ? `${avgValue?.toFixed(0)}`
            : `${(avgValue / 60).toFixed(2)}`;

        highLightValues.push({
          value: valueText,
          subValue: `Avg ${getSleepStageText(stageCode)}`,
          subValueView: getAverageHighLightCustomView(stageCode as Vital),
          unit: avgValue < 60 ? 'mins' : 'hrs',
        });
      }
    });
  }

  const subGraphLinesData: IGraph[] = [];
  const showWeekAvgRange = isShowWeekAvgRange(
    filterParams.filterType as keyof typeof FilterTypes
  );

  //weekly avg graph lines
  if (showWeekAvgRange) {
    const groupedDataByDays = groupVitalDataByTimeUnit(sortedData, 'week');
    Array.from(groupedDataByDays.entries()).forEach(([key, value]) => {
      const groupEffectivePeriod = groupedDataByDays?.get(key)?.effectivePeriod;
      if (groupEffectivePeriod?.start) {
        const startTime = groupEffectivePeriod?.start || '';
        const endTime = groupEffectivePeriod?.end || '';
        const avgValueObj = getStageAverageValue(
          stagesData,
          startTime,
          endTime,
        );
        const y = convertMinsToHours(avgValueObj.avg);
        if (y) {
          const x = getFormatttedDateByFilterType(
            groupedDataByDays?.get(key)?.effectivePeriod?.end || '',
            FilterTypes.Month as keyof typeof FilterTypes,
          );

          const x0 = getFormatttedDateByFilterType(
            groupedDataByDays?.get(key)?.effectivePeriod?.start || '',
            FilterTypes.Month as keyof typeof FilterTypes,
          );

          const rangeAvgTooltipText = getRangeAndAvgTimeTooltipText(
            groupEffectivePeriod,
            avgValueObj.avg,
          );
          const tooltipText =
            rangeAvgTooltipText + '\n' + avgValueObj.avgStagesText;
          const subGraph = getSubGraphObj(
            x0,
            x,
            y,
            tooltipText,
            Colors.Custom.BarColorV2,
          );
          subGraphLinesData.push(subGraph);
        }
      }
    });
  }

  const dataAvailabilty = getDataAvailableCountString(sortedData, filterParams.dateRange);

  const barWidth = getBarWidthByFilter(
    filterParams.filterType as keyof typeof FilterTypes
  );

  const graphType = getSleepGraphTypeBasedOnFilterType(
    filterParams.filterType as keyof typeof FilterTypes,
    filterParams.subFilterType,
    isOnlySleepStageAvailable
  );

  const params = {
    graphType,
    graphData,
    highLightValues,
    subGraphLinesData,
    xTickValues: xTickValues,
    config: {
      barWidth: barWidth,
      showStageFilterSwitch: !isOnlySleepStageAvailable,
      hideBarTooltip: false,
      barColor: showWeekAvgRange
        ? Colors.Custom.AppBackgroundColor
        : Colors.Custom.BarColorV2,
      barOpacity: showWeekAvgRange ? 0.3 : undefined,
      yAxisLabel: 'Hours',
    },
    dataAvailabilty: dataAvailabilty,
    graphInfoView: getGraphInfoViewByFilterTypes(
      filterParams,
      isOnlySleepStageAvailable
    ),
  };

  return getGraphObj(filterParams, params);
};

export const getMinsToHoursWithUnit = (avgValue: number, convertInHours?: boolean) => {
  const value =
    avgValue < 60 && !convertInHours ? `${avgValue?.toFixed(0)}` : `${(avgValue / 60).toFixed(1)}`;

  const unit = `${avgValue < 60 ? 'mins' : 'hrs'}`;
  return {value, unit};
};

export const getAvgTextByFilter = (filterType: keyof typeof FilterTypes) => {
  switch (filterType) {
    case FilterTypes.Day:
      return 'Time in Bed';
    case FilterTypes.Week:
      return 'Weekly Avg';
    case FilterTypes.Month:
      return 'Monthly Avg';
    case FilterTypes.ThreeMonths:
      return '3 months Avg';
    case FilterTypes.SixMonths:
      return '3 months Avg';
    default:
      return 'Avg';
  }
};

export const getBarWidthByFilter = (filterType: keyof typeof FilterTypes) => {
  switch (filterType) {
    case FilterTypes.Week:
      return 4;
    case FilterTypes.Month:
      return 3;
    case FilterTypes.ThreeMonths:
      return 2;
    case FilterTypes.SixMonths:
      return 1;
    default:
      return 2;
  }
};

export const isShowWeekAvgRange = (filterType: keyof typeof FilterTypes) => {
  return [FilterTypes.ThreeMonths, FilterTypes.SixMonths].includes(
    filterType as keyof typeof FilterTypes,
  );
};

export const getFormattedStartEndTime = (effectivePeriod: IEffectivePeriod, dateFormat?: string) => {
  return `${getFormattedDate(
    effectivePeriod?.start,
    dateFormat || DATE_FORMATS.DISPLAY_TIME_FORMAT
  )} - ${getFormattedDate(
    effectivePeriod?.end,
    dateFormat || DATE_FORMATS.DISPLAY_TIME_FORMAT
  )}`;
};

export const getSleepTooltipText = (
  effectivePeriod:
    | {
        start: string;
        end: string;
      }
    | undefined,
  totalValue: number,
  extraTooltipText?: string
) => {
  let tooltipText = '';
  if (effectivePeriod?.end) {
    tooltipText =
      tooltipText +
      getFormattedDate(effectivePeriod?.end, DATE_FORMATS.DMY_DATE_FORMAT);
  }

  if (totalValue) {
    const valueObj = getMinsToHoursWithUnit(totalValue);
    tooltipText = `${tooltipText} (${valueObj.value} ${valueObj.unit})`;
  }

  if (effectivePeriod?.start && effectivePeriod?.end) {
    tooltipText =
      tooltipText + '\n' + `${getFormattedStartEndTime(effectivePeriod)}`;
  }

  return `${tooltipText} ${extraTooltipText ? extraTooltipText : ''}`;
};

const getRangeAndAvgTimeTooltipText = (
  effectivePeriod: {start: string; end: string},
  totalAvgMins: number
) => {
  let tooltipText = '';
  if (effectivePeriod?.start) {
    tooltipText =
      tooltipText +
      `${getFormattedStartEndTime(
        effectivePeriod,
        DATE_FORMATS.SHORT_DAY_MONTH_FORMAT
      )}`;
  }

  if (totalAvgMins) {
    const valueObj = getMinsToHoursWithUnit(totalAvgMins);
    tooltipText = `${tooltipText} \n Avg. Time In Bed: (${valueObj.value} ${valueObj.unit})`;
  }
  return tooltipText;
};

const getAverageSleepTooltipText = (
  effectivePeriod: {start: string; end: string},
  totalAvgMins: number,
  avgSleepTime: string,
  avgAwakeTime: string
) => {
  let tooltipText = getRangeAndAvgTimeTooltipText(
    effectivePeriod,
    totalAvgMins
  );

  if (avgSleepTime && avgAwakeTime) {
    tooltipText =
      tooltipText +
      '\n' +
      `Avg. Sleep Time: ${getFormattedDate(
        avgSleepTime,
        DATE_FORMATS.DISPLAY_TIME_FORMAT
      )} \n
      Avg. Awake Time:
      ${getFormattedDate(avgAwakeTime, DATE_FORMATS.DISPLAY_TIME_FORMAT)}`;
  }
  return tooltipText;
};

const getSubGraphObj = (
  x0: string,
  x: string,
  y: string | number,
  tooltipText: string,
  lineColor: string
) => {
  const subGraph: IGraph = {
    x: '',
    y: 0,
    dateStr: '',
    metaData: {
      lineColor: lineColor,
    },
    subGraphData: [
      {
        x: x0,
        y: y,
        tooltipText: tooltipText,
        dateStr: '',
      },
      {
        x: x,
        y: y,
        tooltipText: tooltipText,
        dateStr: 'key',
      },
    ],
  };
  return subGraph;
};

const getHourMinFormattedTime = (time: string | undefined) => {
  if (time) {
    return getFormattedDate(time, DATE_FORMATS.HOUR_MIN_AM_PM_FORMAT);
  }
  return '';
};

export const getSleepWeekMonthGraphData = (
  params: IFilterParams,
  stagesData: ISleepStage,
  sortedData: IVitals[]
) => {
  const isOnlySleepStageAvailable = checkOnlySleepStagesExist(stagesData);
  const dataSetTime = getDataSetByDateRange(params.dateRange, params.groupBy);
  const timeStampList: string[] = [];

  const graphData: IGraph[] = [];
  const subGraphLinesData: IGraph[] = [];

  const highLightValues: IHighLightValues[] = [];

  const groupedDataByDays = groupVitalDataByTimeUnit(sortedData, 'week');
  const showWeekAvgRange = isShowWeekAvgRange(
    params.filterType as keyof typeof FilterTypes
  );

  //for weekly average group lines
  if (showWeekAvgRange) {
    Array.from(groupedDataByDays.entries()).forEach(([key, value]) => {
      const groupEffectivePeriod = groupedDataByDays?.get(key)?.effectivePeriod;
      if (groupEffectivePeriod) {
        const avgStartObj = getSleepingTimeAverageValue(
          sortedData,
          groupEffectivePeriod?.start || '',
          groupEffectivePeriod?.end || '',
          true
        );

        const avgEndObj = getSleepingTimeAverageValue(
          sortedData,
          groupEffectivePeriod?.start || '',
          groupEffectivePeriod?.end || '',
          false
        );

        const tooltipText = getAverageSleepTooltipText(
          groupEffectivePeriod,
          avgStartObj.averageSleep,
          avgStartObj.avgDateTime,
          avgEndObj.avgDateTime
        );

        const y0 = getHourMinFormattedTime(avgStartObj.avgDateTime);
        const x0 = getFormatttedDateByFilterType(
          groupedDataByDays?.get(key)?.effectivePeriod?.start || '',
          FilterTypes.Month as keyof typeof FilterTypes
        );

        const x = getFormatttedDateByFilterType(
          groupedDataByDays?.get(key)?.effectivePeriod?.end || '',
          FilterTypes.Month as keyof typeof FilterTypes
        );

        timeStampList.push(avgStartObj.avgDateTime);
        const subGraphStart = getSubGraphObj(
          x0,
          x,
          y0,
          tooltipText,
          Colors.Custom.BarColorV2
        );
        subGraphLinesData.push(subGraphStart);

        const y = getHourMinFormattedTime(avgEndObj.avgDateTime);
        timeStampList.push(avgEndObj.avgDateTime);
        const subGraphEnd = getSubGraphObj(
          x0,
          x,
          y,
          tooltipText,
          Colors.Custom.REM_SLEEP
        );
        subGraphLinesData.push(subGraphEnd);
      }
    });
  }

  dataSetTime.forEach((date) => {
    const dataInCurrentRange = sortedData.filter((item) =>
      getMomentObj(item.effectivePeriod?.end || item.date).isSame(
        date,
        params.groupBy
      )
    );

    if (dataInCurrentRange.length === 0) {
      const x = getFormatttedDateByFilterType(
        date,
        FilterTypes.Month as keyof typeof FilterTypes
      );
      graphData.push({
        x: x,
        y: 0,
        y0: 0,
        tooltipText: '',
        dateStr: date,
      });
      return;
    }

    dataInCurrentRange.forEach((item) => {
      const date = getMomentObj(item.date);
      const x = getFormatttedDateByFilterType(
        date.toISOString(),
        FilterTypes.Month as keyof typeof FilterTypes
      );
      const y0 = getHourMinFormattedTime(item.effectivePeriod?.end);
      const y = getHourMinFormattedTime(item.effectivePeriod?.start);
      const endDateMoment = getStartOfDay(item.effectivePeriod?.end);

      const diffInMinutesStart = getTimeDifferenceInMinutes(
        endDateMoment,
        item.effectivePeriod?.start || '',
        true
      ) as number;
      const startDateTimeWithCurrentDay = addTimeToDate(
        getStartOfDay(new Date()),
        diffInMinutesStart,
        'MINUTE'
      )?.toISOString();

      const diffInMinutesEnd = getTimeDifferenceInMinutes(
        endDateMoment,
        item.effectivePeriod?.end || '',
        true
      ) as number;

      const endDateTimeWithCurrentDay = addTimeToDate(
        getStartOfDay(new Date()),
        diffInMinutesEnd,
        'MINUTE'
      )?.toISOString();

      timeStampList.push(endDateTimeWithCurrentDay);
      timeStampList.push(startDateTimeWithCurrentDay);

      if (y === '' && y0 === '') {
        return;
      }

      if (!y || !y0) {
        return;
      }

      const tooltipText = getSleepTooltipText(
        item.effectivePeriod,
        parseFloat(item.value || '0')
      );

      graphData.push({
        x,
        y: y || '',
        y0,
        dateStr: date.toISOString(),
        dataObj: item,
        tooltipText: tooltipText,
        effectivePeriod: item.effectivePeriod,
      });
    });
  });

  const {avg, min, max} = getSleepAverageValue(
    stagesData,
    params.dateRange?.start?.toISOString(),
    params.dateRange?.end?.toISOString()
  );

  highLightValues.push({
    value: getMinsToHoursWithUnit(avg, true).value,
    subValue: getAvgTextByFilter(params.filterType as keyof typeof FilterTypes),
  });

  highLightValues.push({
    value: `${getMinsToHoursWithUnit(min, true).value} - ${
      getMinsToHoursWithUnit(max, true).value
    }`,
    subValue: 'Range',
  });

  const tickValues = extractAndSortTimes(timeStampList, params.isTrendView);

  const barWidth = getBarWidthByFilter(
    params.filterType as keyof typeof FilterTypes
  );

  const dataAvailabilty = getDataAvailableCountString(
    sortedData,
    params.dateRange
  );

  const graphType = getSleepGraphTypeBasedOnFilterType(
    params.filterType as keyof typeof FilterTypes,
    params.subFilterType,
    isOnlySleepStageAvailable
  );

  return getGraphObj(params, {
    graphData,
    graphType,
    highLightValues,
    subGraphLinesData,
    tickValues,
    dataAvailabilty: dataAvailabilty,
    config: {
      barWidth: barWidth,
      showStageFilterSwitch: !isOnlySleepStageAvailable,
      hideBarTooltip: false,
      barColor: showWeekAvgRange
        ? Colors.Custom.Gray200
        : Colors.Custom.BarColorV2,
      domainYPadding: 1,
      yAxisLabel: isWeb() ? 'Time' : '',
      tickLabelXPadding: 7,
    },
    graphInfoView: getGraphInfoViewByFilterTypes(params),
  });
};

export function extractAndSortTimes(dateTimeStrings: string[], isTrendView?: boolean): string[] {
  const array = dateTimeStrings;
  const timeStamped = array.map((item) => new Date(item).getTime());

  const maxDateTime = new Date(Math.max.apply(null, timeStamped)).toUTCString();
  const minDateTime = new Date(Math.min.apply(null, timeStamped)).toUTCString();

  const bufferDateTime = getMomentObj(minDateTime)
    .startOf('hour')
    .subtract('3', 'hour');
  const nextDayBufferDateTime = getMomentObj(bufferDateTime.toISOString()).add(
    '1',
    'day'
  );

  let endDate = getMomentObj(maxDateTime).endOf('hour');
  endDate = endDate.add('1', 'minutes');

  const startDate =
    nextDayBufferDateTime < endDate || isTrendView
      ? getMomentObj(minDateTime)
      : bufferDateTime;

  const originalTimes = getDatesBetweenTwoDates(
    startDate?.toDate(),
    endDate.toDate(),
    'minute'
  )?.map((date) => {
    return getMomentObj(date).format(DATE_FORMATS.HOUR_MIN_AM_PM_FORMAT);
  });

  return originalTimes.slice().reverse();
}

const getAverageHighLightCustomView = (vital: Vital) => {
  return (
    <Stack direction="column">
      <Stack direction="row" style={styles.alignItemsCenter}>
        <View
          style={{
            borderRadius: 4,
            width: 8,
            height: 8,
            marginRight: 4,
            backgroundColor: getSleepStageColors(vital),
          }}
        />
        <Text
          style={styles.textStyle6}
        >
          {`Avg ${getSleepStageText(vital)}`}
        </Text>
      </Stack>
    </Stack>
  );
};

const filterStagesVitals = (vitalData: IVitals[]) => {
  const vitalDataList: IVitals[] = [];

  vitalData?.forEach((vitalObj) => {
    const vitalObjExist = vitalDataList.find((existingVitalObj) => {
      return (
        existingVitalObj.effectivePeriod?.start ===
          vitalObj.effectivePeriod?.start &&
        existingVitalObj.effectivePeriod?.end ===
          vitalObj.effectivePeriod?.end &&
        existingVitalObj?.text === vitalObj?.text
      );
    });
    if (!vitalObjExist) {
      vitalDataList.push(vitalObj);
    }
  });
  return vitalDataList;
};

const filterSleepSortedVitals = (vitalData: IVitals[]) => {
  const vitalDataList: IVitals[] = [];

  vitalData?.forEach((vitalObj) => {
    const updatedVitalObj = cloneDeep(vitalObj);
    const vitalObjExist = vitalDataList.find((existingVitalObj) => {
      return (
        existingVitalObj.effectivePeriod?.start ===
          vitalObj.effectivePeriod?.start &&
        existingVitalObj.effectivePeriod?.end === vitalObj.effectivePeriod?.end
      );
    });
    if (!vitalObjExist && vitalObj?.effectivePeriod) {
      updatedVitalObj.value = `${getDiffBetweenTwoTime(
        vitalObj?.effectivePeriod?.start,
        vitalObj?.effectivePeriod?.end,
      )}`;
      vitalDataList.push(updatedVitalObj);
    }
  });
  return vitalDataList;
};

export const getSleepGraphData = (params: IFilterParams): VitalGraphData => {
  const filteredStagesVitals = filterStagesVitals(params.vitalData);

  const sortedDataClubbed = filteredStagesVitals?.length
    ? getVitalDataArray(
        params.vital,
        filteredStagesVitals,
        params.dataOperation
      )
    : [];

  const sortedData = sortedDataClubbed.map((item) => ({...item}));

  const filteredSortedData = filterSleepSortedVitals(sortedData);

  const stagesData = getFormattedStagesData(sortedData);

  const isSleep = params.vital === Vital.sleep;

  const isOnlySleepStageAvailable = checkOnlySleepStagesExist(stagesData);

  //Day view pie chart
  if (
    isSleep &&
    params.filterType === FilterTypes.Day &&
    !isOnlySleepStageAvailable
  ) {
    return getSleepDayStagesGraphData(params, stagesData);
  } else if (
    params.subFilterType?.[Vital.sleep]?.includes(SubFilterTypes.STAGE)
  ) {
    //Stages view split bar chart
    return getSleepWeekMonthStagesGraphData(
      params,
      stagesData,
      filteredSortedData
    );
  } else {
    //Sleep view bar chart
    return getSleepWeekMonthGraphData(params, stagesData, filteredSortedData);
  }
};

const getGraphInfoViewByFilterTypes = (
  filterParams: IFilterParams,
  isOnlySleepStageAvailable?: boolean
) => {
  const isStage = filterParams.subFilterType?.[Vital.sleep]?.includes(
    SubFilterTypes.STAGE
  );

  switch (filterParams.filterType) {
    case FilterTypes.Week:
    case FilterTypes.Month:
      if (isStage && !isOnlySleepStageAvailable) {
        return (
          <Stack direction="row">
            <GraphInfoItem
              infoColor={Colors.Custom.DEEP_SLEEP}
              text={'Deep'}
            />
            <GraphInfoItem
              infoColor={Colors.Custom.REM_SLEEP}
              text={'Rem'}
            />
            <GraphInfoItem
              infoColor={Colors.Custom.LIGHT_SLEEP}
              text={'Light'}
            />
            <GraphInfoItem
              infoColor={Colors.Custom.AWAKE_SLEEP}
              text={'Awake'}
            />
          </Stack>
        );
      } else {
        return (
          <GraphInfoItem
            infoColor={Colors.Custom.BarColorV2}
            text={'Time In Bed'}
          />
        );
      }
    case FilterTypes.ThreeMonths:
    case FilterTypes.SixMonths:
      if (isStage) {
        return (
          <GraphInfoItem
            infoColor={Colors.Custom.BarColorV2}
            text={'Weekly Avg Sleep Stages'}
          />
        );
      } else {
        return (
          <Stack direction="row">
            <GraphInfoItem
              infoColor={Colors.Custom.DEEP_SLEEP}
              text={'Weekly Avg Sleep Time'}
            />
            <GraphInfoItem
              infoColor={Colors.Custom.REM_SLEEP}
              text={'Weekly Avg Waking Time'}
            />
          </Stack>
        );
      }

    default:
      return <></>;
  }
};

const getGraphObj = (
  filterParams: IFilterParams,
  params: {
    graphType: GraphsTypes;
    graphData: IGraph[];
    subGraphLinesData?: IGraph[];
    highLightValues: IHighLightValues[];
    subGraphData?: IGraph[];
    tickValues?: string[];
    config?: IGraphConfig;
    graphInfoView?: JSX.Element;
    dataAvailabilty?: string;
    xTickValues?: string[]
  }
) => {
  const {
    graphType,
    graphData,
    highLightValues,
    subGraphData,
    tickValues,
    xTickValues,
    config,
    graphInfoView,
    subGraphLinesData,
  } = params;

  const vitalConfig = filterParams.vitalConfigs.find(
    (item) => item.loinc === filterParams.vital
  );

  const isAllEmpty = graphData.every((item) => item.y === 0);

  const addIndexCorrection = filterParams.ccmDate
    ? !getMomentObj(filterParams.ccmDate || '').isBetween(
        getMomentObj(filterParams.dateRange.start),
        getMomentObj(filterParams.dateRange.end),
        filterParams.groupBy,
      )
    : false;

  const annontationIndex = filterParams?.ccmDate
    ? getAnonationIndex(
        filterParams?.ccmDate as string,
        graphData,
        filterParams.groupBy,
        true,
      ) + (addIndexCorrection ? 0.25 : 0)
    : undefined;

  return {
    graphType,
    graphData: isAllEmpty ? [] : graphData,
    title: vitalConfig?.display || '',
    loinc: vitalConfig?.loinc || '',
    code: filterParams.vital,
    highLightValues,
    dataAvailability: params.dataAvailabilty || '',
    dataType: DataType.VITAL,
    vitalYAxisLabel: `${vitalConfig?.display} (${vitalConfig?.unit})`,
    annontationIndex,
    dataOperation: filterParams?.dataOperation,
    tickValues: tickValues || [],
    xTickValues: xTickValues,
    tickLabelFunc: (x: string) => {
      const time = getMomentObj(
        x,
        undefined,
        DATE_FORMATS.HOUR_MIN_AM_PM_FORMAT
      ).format(DATE_FORMATS.HOUR_AM_PM_FORMAT);
      return time;
    },
    subGraphLinesData: subGraphLinesData,
    subGraphData: subGraphData || [],
    config: config,
    graphInfoView: graphInfoView,
  };
};
