export const GET_WORKFLOW_BY_EVENT_ENTITY = `
query workflowEntityEventList($tenantId: uuid, $entityEventConditionList: [workflow_bool_exp!]) {
  workflows(where: {tenantId: {_eq: $tenantId}, isDeleted: {_eq: false}, _and: $entityEventConditionList}) {
    name
    id
    description
    entity
    eventType
    flowType
    isEnabled
    workflowMasterId
    tenantId
    isDeleted
    workflowLocations{
      locationId
    }
  }
}
`;

export const GET_WORKFLOW_FORMLIST_BY_EVENT_ENTITY = `
query workflowFormList($entity: String, $eventType: String, $entityEventConditionList: [uuid!]) {
    workflows(where:{isDeleted:{_eq:false}, workflowMasterId:{_in:$entityEventConditionList}, entity:{_eq:$entity}, eventType: {_eq:$eventType}}) {
      name
      workflowMasterId
      eventType
      graph {
        nodes {
          id
          type
          data
        }
      }
    }
  }
`;

export const GEY_WORKFLOW_FOR_PACKAGES = `
query GetUserInputFields($key: String!) {
  userInputFields(
    where: {
      node: { graph: { workflow: { isDeleted: { _eq: false } } } }
      key: { _eq: $key }
    }
  ) {
    id
    value
    tenantId
    node {
      graph {
        workflow {
          id
          name
          flowType
        }
      }
    }
  }
}
`;

export const GET_EMAIL_STATUS_BY_TAGS = `
query getEmailStatusByTags($tagId: uuid, $tagType: String!) {
  emailAnalyticTags(where: {tag: {_eq: $tagId}, tagType: {_eq: $tagType}}) {
    id
    tag
    tagType
    logs(where: {eventCode: {_in: ["opened", "clicked"]}}) {
      eventCode
      createdOn
    }
  }
}
`

export const GET_EMAIL_ALL_STATUS_BY_TAGS_GROUP_BY_MESSAGE_ID = `
query getEmailStatusByTags($emailAnalyticLogBoolExp: emailAnalyticLog_bool_exp!) {
  emailAnalyticLogs:emailAnalyticLogs( where: {_and:[{eventCode:{_in:["opened", "clicked"]}},$emailAnalyticLogBoolExp]}) {
    eventCode
    createdOn
  }
  emailAnalyticLogsUnique:emailAnalyticLogs(distinct_on: [messageId, eventCode], where: $emailAnalyticLogBoolExp) {
    eventCode
    createdOn
  }
}
`

export const GET_EMAIL_ALL_STATUS_BY_TAGS_GROUP_BY_MESSAGE_ID_WITH_CONTACT = `
query getEmailStatusByTags($emailAnalyticLogBoolExp: emailAnalyticLog_bool_exp!) {
  emailAnalyticLogs:emailAnalyticLogs( where: {_and:[{eventCode:{_in:["opened", "clicked"]}},$emailAnalyticLogBoolExp]}) {
    messageId
    eventCode
    createdOn
    tags(limit:1, where:{tagType:{_eq:"communicationLogResponse"}}) {
      messageId
      tag
      tagType
      serviceLog {
        workflowExecutionId
        resourceLevelLog(limit:1,where:{resourceType:{_eq:"CONTACT"}}) {
          resourceType
          resourceId
        }
      }
      
    }
  }
  emailAnalyticLogsUnique:emailAnalyticLogs(distinct_on: [messageId, eventCode], where: $emailAnalyticLogBoolExp) {
    messageId
    eventCode
    createdOn
    
    tags(limit:1,where:{tagType:{_eq:"communicationLogResponse"}}) {
      tag
      tagType
      serviceLog {
        workflowExecutionId
        resourceLevelLog(limit:1,where:{resourceType:{_eq:"CONTACT"}}) {
          resourceType
          resourceId
        }
      }
    }
  }
}
`

export const GET_PATIENT_ENROLLMENT_WORKFLOW_LIST = `
  query searchWorkflow($boolExp: workflow_bool_exp) {
    workflows(
      order_by: [{ isEnabled: desc }, { createdOn: desc }],
      where: $boolExp
    ) {
      id
      name
    }
  }
`;

export default {
  GET_WORKFLOW_BY_EVENT_ENTITY,
  GET_EMAIL_ALL_STATUS_BY_TAGS_GROUP_BY_MESSAGE_ID,
  GET_EMAIL_ALL_STATUS_BY_TAGS_GROUP_BY_MESSAGE_ID_WITH_CONTACT,
  GET_WORKFLOW_FORMLIST_BY_EVENT_ENTITY,
  GET_EMAIL_STATUS_BY_TAGS,
  GET_PATIENT_ENROLLMENT_WORKFLOW_LIST,
  GEY_WORKFLOW_FOR_PACKAGES
};
