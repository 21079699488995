import {Path, Rect, Svg} from 'react-native-svg';

export const EmptyCarePlan = () => {
  return (
    <Svg width="151" height="151" viewBox="0 0 151 151" fill="none">
      <Path
        d="M75.1994 150.403C116.731 150.403 150.399 116.734 150.399 75.2027C150.399 33.6709 116.731 0.00268555 75.1994 0.00268555C33.6676 0.00268555 -0.000610352 33.6709 -0.000610352 75.2027C-0.000610352 116.734 33.6676 150.403 75.1994 150.403Z"
        fill="#F2F4F7"
      />
      <Rect
        x="30.7638"
        y="37.2582"
        width="89.5564"
        height="113.484"
        rx="2.73455"
        fill="white"
      />
      <Rect
        x="41.7021"
        y="48.1964"
        width="26.6618"
        height="5.46909"
        rx="2.73455"
        fill="#E4E7EC"
      />
      <Rect
        x="41.702"
        y="61.1855"
        width="51.2727"
        height="5.46909"
        rx="2.73455"
        fill="#E4E7EC"
      />
      <Rect
        x="41.702"
        y="74.1746"
        width="65.6291"
        height="5.46909"
        rx="2.73455"
        fill="#E4E7EC"
      />
      <Rect
        x="41.702"
        y="87.1637"
        width="41.7018"
        height="5.46909"
        rx="2.73455"
        fill="#E4E7EC"
      />
    </Svg>
  );
};
