import { StyleSheet } from 'react-native';
import { isWeb } from '../../../utils/platformCheckUtils';
import { Colors } from '../../../styles/Colors';

export const styles = StyleSheet.create({
    logDetail: {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: 'auto'
    },
    markDownWrapper: {
        flexShrink: 1,
        flexBasis: 'auto'
    },
    logStyle: {
        flexDirection: 'row',
        alignItems: 'center',
        maxWidth: '100%',
        marginLeft: 45,
        marginTop: -4,
        flexWrap: 'wrap',
        flex: 1,
        paddingLeft: isWeb() ? 0 : 5
    },
    colorGray500: {
        color: Colors.Custom.Gray500
    },
    iconStyle: {
        marginLeft: 8,
        marginRight: 6,
        fontSize: 15,
        color: Colors.Custom.Gray600,
    },
    textColor: {
        color: Colors.Custom.textBlackColor
    },
    textStyle: {
        color: Colors.Custom.Gray500,
        marginLeft: 5
    },
    textStyle2: {
        color: Colors.Custom.Gray500,
        marginLeft: 5,
        alignSelf: 'flex-end',
    },
    flexDirectionRow: {
      flexDirection: 'row'
    }
});
