import React from 'react';
import { isWeb } from '../../../utils/platformCheckUtils';
import { Image } from 'native-base';

const BasicInfo = () => {
  return (
    <>
    {isWeb() ? (<svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4854 11.2527C13.5928 11.2527 16.1118 8.73368 16.1118 5.62634C16.1118 2.519 13.5928 0 10.4854 0C7.37811 0 4.85911 2.519 4.85911 5.62634C4.85911 8.73368 7.37811 11.2527 10.4854 11.2527Z"
        fill="#98A2B3"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.9432 20.9832C10.8803 19.8415 10.2297 18.3112 10.2297 16.6294C10.2297 14.9077 10.912 13.3436 12.0209 12.1938C11.5197 12.1549 11.0072 12.1345 10.4854 12.1345C7.08714 12.1345 4.07858 12.9846 2.21064 14.2582C0.785641 15.23 0 16.4647 0 17.7608V19.2441C0 19.7055 0.183112 20.1484 0.509439 20.4737C0.835767 20.8001 1.27769 20.9832 1.73905 20.9832H11.9432Z"
        fill="#98A2B3"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.6233 11.2588C13.6587 11.2588 11.2527 13.6648 11.2527 16.6294C11.2527 19.594 13.6587 22 16.6233 22C19.5878 22 21.9939 19.594 21.9939 16.6294C21.9939 13.6648 19.5878 11.2588 16.6233 11.2588ZM15.856 16.6294V19.1868C15.856 19.6103 16.1998 19.9541 16.6233 19.9541C17.0468 19.9541 17.3905 19.6103 17.3905 19.1868V16.6294C17.3905 16.2059 17.0468 15.8622 16.6233 15.8622C16.1998 15.8622 15.856 16.2059 15.856 16.6294ZM16.6233 13.3048C17.188 13.3048 17.6462 13.763 17.6462 14.3277C17.6462 14.8924 17.188 15.3507 16.6233 15.3507C16.0586 15.3507 15.6003 14.8924 15.6003 14.3277C15.6003 13.763 16.0586 13.3048 16.6233 13.3048Z"
        fill="#98A2B3"
      />
    </svg>) : (
      <Image
      resizeMode="contain"
      size={'24px'}
      source={require('../../../assets/images/Contact/basicInfo.png')}
      alt="image"
    />
    )}
    </>
  );
};

export default BasicInfo;
