import React from 'react';
import { Path, Svg } from 'react-native-svg';

const TwoIconSvg = () => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <Path d="M0.25 12C0.25 9.16451 0.250531 7.06303 0.467441 5.44968C0.683383 3.84352 1.10965 2.75862 1.93414 1.93414C2.75862 1.10965 3.84352 0.683383 5.44968 0.467441C7.06303 0.250531 9.16451 0.25 12 0.25C14.8355 0.25 16.937 0.250531 18.5503 0.467441C20.1565 0.683383 21.2414 1.10965 22.0659 1.93414C22.8904 2.75862 23.3166 3.84352 23.5326 5.44968C23.7495 7.06303 23.75 9.16451 23.75 12C23.75 14.8355 23.7495 16.937 23.5326 18.5503C23.3166 20.1565 22.8904 21.2414 22.0659 22.0659C21.2414 22.8904 20.1565 23.3166 18.5503 23.5326C16.937 23.7495 14.8355 23.75 12 23.75C9.16451 23.75 7.06303 23.7495 5.44968 23.5326C3.84352 23.3166 2.75862 22.8904 1.93414 22.0659C1.10965 21.2414 0.683383 20.1565 0.467441 18.5503C0.250531 16.937 0.25 14.8355 0.25 12Z" fill="#F5F0FF"/>
    <Path d="M0.25 12C0.25 9.16451 0.250531 7.06303 0.467441 5.44968C0.683383 3.84352 1.10965 2.75862 1.93414 1.93414C2.75862 1.10965 3.84352 0.683383 5.44968 0.467441C7.06303 0.250531 9.16451 0.25 12 0.25C14.8355 0.25 16.937 0.250531 18.5503 0.467441C20.1565 0.683383 21.2414 1.10965 22.0659 1.93414C22.8904 2.75862 23.3166 3.84352 23.5326 5.44968C23.7495 7.06303 23.75 9.16451 23.75 12C23.75 14.8355 23.7495 16.937 23.5326 18.5503C23.3166 20.1565 22.8904 21.2414 22.0659 22.0659C21.2414 22.8904 20.1565 23.3166 18.5503 23.5326C16.937 23.7495 14.8355 23.75 12 23.75C9.16451 23.75 7.06303 23.7495 5.44968 23.5326C3.84352 23.3166 2.75862 22.8904 1.93414 22.0659C1.10965 21.2414 0.683383 20.1565 0.467441 18.5503C0.250531 16.937 0.25 14.8355 0.25 12Z" stroke="#D7C0FF" stroke-width="0.5"/>
    <Path d="M9.63317 16V15.2045L12.0941 12.6548C12.3569 12.3778 12.5735 12.1352 12.744 11.9268C12.9168 11.7161 13.0458 11.5161 13.131 11.3267C13.2163 11.1373 13.2589 10.9361 13.2589 10.723C13.2589 10.4815 13.2021 10.2732 13.0884 10.098C12.9748 9.92045 12.8197 9.78433 12.6232 9.68963C12.4267 9.59257 12.2054 9.54403 11.9592 9.54403C11.6987 9.54403 11.4715 9.5973 11.2773 9.70384C11.0832 9.81037 10.9341 9.9607 10.8299 10.1548C10.7257 10.349 10.6737 10.5762 10.6737 10.8366H9.62607C9.62607 10.3939 9.72786 10.0069 9.93146 9.67543C10.1351 9.34399 10.4144 9.08712 10.7695 8.90483C11.1246 8.72017 11.5283 8.62784 11.9805 8.62784C12.4374 8.62784 12.8398 8.71899 13.1879 8.90128C13.5382 9.0812 13.8117 9.32741 14.0082 9.63991C14.2047 9.95005 14.3029 10.3004 14.3029 10.6911C14.3029 10.9609 14.252 11.2249 14.1502 11.483C14.0508 11.741 13.8768 12.0286 13.6282 12.3459C13.3796 12.6607 13.034 13.0431 12.5913 13.4929L11.146 15.0057V15.0589H14.4201V16H9.63317Z" fill="#8C5AE2"/>
    </Svg>
    
  );
};

export default TwoIconSvg;