import {useLazyQuery} from '@apollo/client';
// import {Skeleton} from 'antd';
import {
  Box,
  FormControl,
  Stack,
  Text,
  useToast,
  View,
  Skeleton,
} from 'native-base';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {DATE_FORMATS} from '../../../../../../constants';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../../constants/Configs';
import {ScheduleQueries} from '../../../../../../services';
import {
  getCurrentTimeZone,
  getCustomDateRangeForAppointments,
  getDateObject,
  getDateRangeForCalendar,
  getDateStrFromFormat,
  isCurrentDateInFutureComparedToOther,
} from '../../../../../../utils/DateUtils';
import {DisplayText} from '../../../../DisplayText/DisplayText';
import { CalendarView } from '../../../CalendarWidgetEnums';
import {
  IAppointmentAvailableSlotsProps,
  IAppointmentAvailableSlotsState,
  ISlot,
} from './AppointmentBookingIntefaces';
import { MAX_FUTURE_DATE_RANGE } from './AppointmentConstant';
import SlotsGroup from './SlotsGroup';
import { ToastType, showToast } from '../../../../../../utils/commonViewUtils';

export function AppointmentAvailableSlots(
  props: IAppointmentAvailableSlotsProps
) {
  const [slotsState, setSlotsState] = useState<IAppointmentAvailableSlotsState>(
    {}
  );
  const intl = useIntl();
  const toast = useToast();
  const [getAvailableSlots] = useLazyQuery(
    ScheduleQueries.GET_AVAILABLE_SLOTS_FOR_DATE_RANGE,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT,
        // NOTE: check is added so that headers are not passed it will take them form LS of loggedin user
        ...(props?.headers && { headers: props.headers })
      },
      fetchPolicy: 'no-cache',
      onError: (error) => {

      },
    }
  );

  function showErrorPopup() {
    showToast(toast, intl.formatMessage({id: 'apiErrorMsg'}), ToastType.error);
  }

  function updateSlotLoading(isLoading: boolean) {
    setSlotsState((prevState) => ({...prevState, isSlotLoading: isLoading}));
    if (props.updateSlotLoading) props.updateSlotLoading(isLoading);
  }

  function fetchAppointmentSlots(
    date: string | undefined,
    duration: number,
    userId: string
  ) {
    if (slotsState.selectedSlot) props.onSlotSelection(undefined);
    setSlotsState((prevState) => ({
      ...prevState,
      selectedSlot: undefined,
      availableSlots: [],
    }));


    // TODO: fix this contactids
    const contactIds: string[] = [];
    if (!date || !duration || !userId) return;
    updateSlotLoading(true);

    const slotDate = getDateRangeForCalendar(CalendarView.day, getDateObject(date));

    getAvailableSlots({
      variables: {
        data: {
          slotStartDate: slotDate.startDate,
          slotEndDate: slotDate.endDate,
          userId: userId,
          ...(contactIds?.length > 0 && {contactIds: contactIds}),
          duration: duration,
          isVirtualLocation: props?.isVirtualLocation,
          locationId: props?.isVirtualLocation ? undefined : (props?.locationIds?.length ? props?.locationIds[0] : undefined) ,
          timezone: getCurrentTimeZone(),
          ...(props.rescheduleAppointmentId && { rescheduleAppointmentId: props.rescheduleAppointmentId }),
          ...(props?.appointmentTypeId && { appointmentTypeId: props?.appointmentTypeId }),
        },
      },
    })
      .then((response) => {
        if (response?.data?.getAvailableSlots?.slots && Object.keys(response.data.getAvailableSlots.slots).length > 0) {
          const selectedDate = getDateStrFromFormat(date, DATE_FORMATS.DISPLAY_DATE_FORMAT);
          if (response.data.getAvailableSlots.slots.hasOwnProperty(selectedDate)) {
            const selectedDateSlots = response.data.getAvailableSlots.slots[selectedDate];
            const futureSlots = (selectedDateSlots || []).filter(
              (slot: ISlot) => {
                return isCurrentDateInFutureComparedToOther(
                  slot.startDateTime,
                  new Date()
                );
              }
            );
            setSlotsState((prevState) => ({
              ...prevState,
              availableSlots: futureSlots,
            }));
          } else {
            setSlotsState((prevState) => ({...prevState}));
          }
        }
        updateSlotLoading(false);
      })
      .catch((error) => {
        showErrorPopup();
        setSlotsState((prevState) => ({...prevState, availableSlots: []}));
        updateSlotLoading(false);
      });
  }

  const canShowNoSlotsAvailable =
    !slotsState.isSlotLoading &&
    (!slotsState.availableSlots || !slotsState.availableSlots.length);
  const canShowAvailableSlots = !!(
    !slotsState.isSlotLoading &&
    slotsState.availableSlots &&
    slotsState.availableSlots.length
  );

  useEffect(() => {
    fetchAppointmentSlots(props.date, props.duration, props.userId);
  }, [props.date, props.duration, props.userId]);

  return (
    <Stack space={2} direction={['column', 'column', 'row']}>
      <FormControl isInvalid={props.showFormErrors && !slotsState.selectedSlot}>
        <FormControl.Label isRequired>
          <DisplayText textLocalId="slots" />
        </FormControl.Label>

        <View flex={1}>
          {props.showFormErrors && !slotsState.selectedSlot && (
            <View>
              {canShowNoSlotsAvailable && (
                <Text color="error.500">
                  Choose different date and select time slot
                </Text>
              )}
              {canShowAvailableSlots && (
                <Text color="error.500">Please select time slot</Text>
              )}
            </View>
          )}

          {canShowAvailableSlots && ( 
            <SlotsGroup
              selectedSlotDate={getDateObject(props.date)}
              selectedTimezone={getCurrentTimeZone()}
              userSlots={slotsState.availableSlots || []}
              selectedSlot={slotsState.selectedSlot}
              handleSlotSelection={(slot: ISlot) => {
                setSlotsState((prevState) => ({
                  ...prevState,
                  selectedSlot: slot,
                }));
                props.onSlotSelection(slot);
              }}
            />
          )}

          {canShowNoSlotsAvailable && (
            <View width="full">
              <DisplayText textLocalId="noSlotsAvailable" />
            </View>
          )}

          {slotsState.isSlotLoading && (
            <View width="full">
              <Skeleton />
            </View>
          )}
        </View>
      </FormControl>
    </Stack>
  );
}
