import {
  GROUP_MEMBER_TYPE,
  GROUP_TYPES,
  PERSON_TYPES,
} from './../../../constants/StringConst';
import {getUserOnlineStatus} from '../../../utils/commonUtils';
import {IAvatarUserData, IGroupType} from './interface';
import {
  CONTACT_GENDER_IMG,
  GENDER_CODE,
  USER_AVATAR_BY_ROLES,
  USER_ROLE_PRIORITY_LIST,
} from './DisplayAvatarConst';
import {
  internalGroup,
  patientUnknown,
  patientBroadCastGroup,
  patientGroupMale,
  patientGroupFemale,
  prospect,
  careProgramManagerMale,
} from './DisplayAvatarPngFiles';

export const isUserOnline = (userData: IAvatarUserData) => {
  let isOnline = false;
  if (
    userData &&
    Object.keys(userData).length &&
    userData.userId &&
    userData.userType
  ) {
    isOnline = getUserOnlineStatus(userData.userId, userData.userType);
  }
  return isOnline;
};

export const getUserAvatarImageSrc = (
  userData: IAvatarUserData,
  groupType: IGroupType
): any => {
  let imageSrc = patientUnknown;
  if (userData?.imgSrc) {
    imageSrc = userData.imgSrc;
  } else {
    imageSrc = getUserImageSrcFromData(userData, groupType);
  }
  return imageSrc;
};

export const getUserImageSrcFromData = (
  userData: IAvatarUserData,
  groupTypeCode: IGroupType
): string => {
  let imageSrc = patientUnknown;
  if (groupTypeCode) {
    imageSrc = getGroupTypeAvatar(userData, groupTypeCode);
  } else if (userData?.userId && userData?.userType) {
    if (userData?.userType === GROUP_MEMBER_TYPE.USER) {
      imageSrc = getUserImageSrc(userData);
    } else if (
      userData?.userType === GROUP_MEMBER_TYPE.CONTACT ||
      userData?.userType === GROUP_MEMBER_TYPE.PATIENT
    ) {
      imageSrc = getContactImageSrc(userData);
    }
  }
  return imageSrc;
};

export const getGroupTypeAvatar = (
  userData: IAvatarUserData,
  groupTypeCode: IGroupType
) => {
  let imageSrc = patientUnknown;
  switch (groupTypeCode) {
    case GROUP_TYPES.PATIENT_BROADCAST:
      imageSrc = patientBroadCastGroup;
      break;
    case GROUP_TYPES.USER_BROADCAST:
      imageSrc = patientBroadCastGroup;
      break;
    case GROUP_TYPES.PRIVATE: {
      if (userData?.genderCode === GENDER_CODE.male || userData?.genderCode === GENDER_CODE.M) {
        imageSrc = patientGroupMale;
      } else if (userData?.genderCode === GENDER_CODE.female || userData?.genderCode === GENDER_CODE.F) {
        imageSrc = patientGroupFemale;
      }
      break;
    }
    case GROUP_TYPES.INTERNAL:
      imageSrc = internalGroup;
      break;
  }
  return imageSrc;
};

export const getContactImageSrc = (userData: IAvatarUserData): string => {
  let imageSrc = patientUnknown;
  const genderCode = userData?.genderCode;
  if (genderCode) {
    imageSrc = getGenderAvatar(genderCode || '');
  } else if (userData?.contactType !== PERSON_TYPES.CUSTOMER) {
    imageSrc = prospect;
  }
  return imageSrc;
};

export const getGenderAvatar = (genderCode: string) => {
  let imageSrc = patientUnknown;
  switch (genderCode) {
    case GENDER_CODE.male:
    case GENDER_CODE.M:
      imageSrc = CONTACT_GENDER_IMG[GENDER_CODE.male];
      break;
    case GENDER_CODE.female:
    case GENDER_CODE.F:
      imageSrc = CONTACT_GENDER_IMG[GENDER_CODE.female];
      break;
  }
  return imageSrc;
};

export const getUserImageSrc = (userData: IAvatarUserData): string => {
  let imageSrc = careProgramManagerMale;
  const genderCode = userData?.genderCode;
  if (userData?.roles?.length) {
    for (
      let userRoleIndex = 0;
      userRoleIndex < USER_ROLE_PRIORITY_LIST.length;
      userRoleIndex++
    ) {
      const userRole = USER_ROLE_PRIORITY_LIST[userRoleIndex];
      if (userData?.roles?.includes(userRole)) {
        if (USER_AVATAR_BY_ROLES[userRole]) {
          const userAvatarImgData = USER_AVATAR_BY_ROLES[userRole];
          switch (genderCode) {
            case GENDER_CODE.M:
            case GENDER_CODE.male:
              imageSrc = userAvatarImgData[GENDER_CODE.male];
              break;
            case GENDER_CODE.female:
            case GENDER_CODE.F:
              imageSrc = userAvatarImgData[GENDER_CODE.female];
              break;
            default:
              imageSrc = userAvatarImgData[GENDER_CODE.other];
              break;
          }
        }
        break;
      }
    }
  }
  return imageSrc;
};
