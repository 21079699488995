import {Text} from 'native-base';
import {ISingleTimelineProps} from '.';
import {Colors} from '../../../styles';
import {TagComponent} from '../../common/TagComponent';
import {TIMELINE_TYPE_CODES} from './TimelineConsts';
import {TimelineConversation} from './TimelineConversation';
import {TimelineNote} from './TimelineNote';
import {TimelineTask} from './TimelineTask';

export const getStringFromTimelineType = (timelineType: string) => {
  let iconString = 'filetext1';
  const iconBgColor = Colors.Custom.PrimaryColor;
  switch (timelineType) {
    case TIMELINE_TYPE_CODES.NOTE:
      iconString = 'filetext1';
      break;
    case TIMELINE_TYPE_CODES.ASSIGNEE:
      iconString = 'user';
      break;
    case TIMELINE_TYPE_CODES.CUSTOMER:
      iconString = 'adduser';
      break;
    case TIMELINE_TYPE_CODES.DEAL:
      iconString = 'staro';
      break;
    case TIMELINE_TYPE_CODES.MESSENGER:
      iconString = 'mail';
      break;
    case TIMELINE_TYPE_CODES.TAGGED:
      iconString = 'tago';
      break;
    case TIMELINE_TYPE_CODES.TICKET:
      iconString = 'solution1';
      break;
    case TIMELINE_TYPE_CODES.TASK:
      iconString = 'check';
      break;
  }
  return {iconString, iconBgColor};
};

export const timelineDummyData: ISingleTimelineProps[] = [
  {
    timelineDate: new Date(Date.now()).toDateString(),
    timelineTitleElem: <Text>ABC created a task</Text>,
    timelineType: TIMELINE_TYPE_CODES.TASK,
    timelineComponent: (
      <TimelineTask
        isTaskComplete
        taskTitle="Star 1391"
        taskDueDate="11/11/11"
        assignToList={['Sanket Chavan', 'Abc Def']}
      />
    ),
  },
  {
    timelineDate: new Date(Date.now()).toDateString(),
    timelineTitleElem: <Text>ABC created a task</Text>,
    timelineType: TIMELINE_TYPE_CODES.TASK,
    timelineComponent: (
      <TimelineTask
        isTaskComplete={false}
        taskTitle="1 to 1 Meeting Request"
        taskDueDate="11/11/11"
        assignToList={['Lorem Ipsum', 'Abc Def']}
      />
    ),
  },
  {
    timelineDate: new Date(Date.now()).toDateString(),
    timelineTitleElem: <Text>Tagged</Text>,
    timelineType: TIMELINE_TYPE_CODES.TAGGED,
    timelineComponent: <TagComponent tagText={'LOST'} tagColor="#102010" />,
  },
  {
    timelineDate: new Date(Date.now()).toDateString(),
    timelineTitleElem: <Text>Conversation with unknown</Text>,
    timelineType: TIMELINE_TYPE_CODES.MESSENGER,
    timelineComponent: <TimelineConversation />,
  },
  {
    timelineDate: new Date(Date.now()).toDateString(),
    timelineTitleElem: <Text>Assignee</Text>,
    timelineType: TIMELINE_TYPE_CODES.ASSIGNEE,
  },
  {
    timelineDate: new Date(Date.now()).toDateString(),
    timelineTitleElem: <Text>Customer</Text>,
    timelineType: TIMELINE_TYPE_CODES.CUSTOMER,
  },
  {
    timelineDate: new Date(Date.now()).toDateString(),
    timelineTitleElem: <Text>Notes</Text>,
    timelineType: TIMELINE_TYPE_CODES.NOTE,
    timelineComponent: (
      <TimelineNote noteColor="yellow" noteText="This is Note" />
    ),
  },
];
