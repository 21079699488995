import AntIcon from 'react-native-vector-icons/AntDesign';
import {QUICK_ACTION_CODES} from '../MiddleContainerConst';
import {styles} from './QuickActionsStyles';

export const getActionIconFromCode = (quickActionCode: string): JSX.Element => {
  let quickActionIconElem = <></>;
  switch (quickActionCode) {
    case QUICK_ACTION_CODES.NEW_NOTE:
      quickActionIconElem = (
        <AntIcon name="pluscircleo" style={[styles.icons]} />
      );
      break;
    case QUICK_ACTION_CODES.NEW_MESSAGE:
      quickActionIconElem = (
        <AntIcon name="pluscircleo" style={[styles.icons]} />
      );
      break;
    case QUICK_ACTION_CODES.NEW_EMAIL:
      quickActionIconElem = (
        <AntIcon name="pluscircleo" style={[styles.icons]} />
      );
      break;
  }
  return quickActionIconElem;
};
