const CareJourneyDetailsIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.6734 1.63595C21.2859 1.42501 22.6172 2.75626 22.4062 4.36876C22.0968 6.72658 21.2484 10.7344 18.8531 13.3172C18.089 14.1375 17.1703 14.8969 16.2234 15.5719L16.5 17.5078C16.5797 18.0516 16.3922 18.6047 16.0031 18.9938L12.8203 22.1766C11.7984 23.1985 10.0547 22.6125 9.84841 21.1875L9.42185 18.2156C9.14998 18.0844 8.90154 17.9063 8.68123 17.686L6.34216 15.3469C6.12185 15.1266 5.94373 14.8781 5.81248 14.6063L2.8406 14.1797C1.41091 13.9781 0.829664 12.2297 1.85154 11.2078L5.03435 8.02501C5.42341 7.63595 5.97185 7.45314 6.52029 7.52814L8.45623 7.8047C9.13123 6.85783 9.8906 5.93908 10.7109 5.17501C13.3078 2.79376 17.3109 1.94533 19.6734 1.63595ZM4.3406 15.4594C4.63123 15.75 4.63123 16.2281 4.3406 16.5188L2.92498 17.9344C2.63435 18.225 2.15623 18.225 1.8656 17.9344C1.57498 17.6438 1.57498 17.1656 1.8656 16.875L3.28123 15.4594C3.57185 15.1688 4.04529 15.1688 4.3406 15.4594ZM6.45935 17.5828C6.74998 17.8735 6.74998 18.3516 6.45935 18.6422L3.63279 21.4688C3.34216 21.7594 2.86404 21.7594 2.57341 21.4688C2.28279 21.1781 2.28279 20.7 2.57341 20.4094L5.39998 17.5828C5.6906 17.2875 6.16873 17.2875 6.45935 17.5828ZM8.58279 19.7016C8.87341 19.9922 8.87341 20.4703 8.58279 20.761L7.16716 22.1766C6.87654 22.4672 6.39841 22.4672 6.10779 22.1766C5.81716 21.886 5.81716 21.4078 6.10779 21.1172L7.52341 19.7016C7.81404 19.411 8.28748 19.411 8.58279 19.7016ZM17.2406 8.0672H15.9797V6.80626C15.9797 6.33283 15.5953 5.95314 15.1265 5.95314C14.6531 5.95314 14.2734 6.33751 14.2734 6.80626V8.0672H13.0125C12.539 8.0672 12.1594 8.45158 12.1594 8.92033C12.1594 9.39376 12.5437 9.77345 13.0125 9.77345H14.2734V11.0344C14.2734 11.5078 14.6578 11.8875 15.1265 11.8875C15.6 11.8875 15.9797 11.5031 15.9797 11.0344V9.77345H17.2406C17.714 9.77345 18.0937 9.38908 18.0937 8.92033C18.0937 8.44689 17.7093 8.0672 17.2406 8.0672Z"
        fill="#98A2B3"
      />
    </svg>
  );
};

export default CareJourneyDetailsIcon;
