// Get the DateTime from the components listing.
import {Formio} from '@foldhealth/formiojs';
import { DATE_FORMATS } from '../../../../../../constants/StringConst';
import BasicFields from '../../EditFormFields/BasicFields';
import ConditionalFields from '../../EditFormFields/ConditionalFields';
import KeyField from '../../EditFormFields/KeyField';

const DateTimeComponent = Formio.Components.components.date;

export default class FormDatePicker extends DateTimeComponent {
  static schema(...extend: any) {
    return DateTimeComponent.schema(
      {
        type: 'formDatePicker',
        label: 'Date',
        key: 'formDatePicker',
        format: DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT,
        enableTime: false,
      },
      ...extend
    );
  }

  static get builderInfo() {
    return {
      title: 'Date',
      group: 'advanced',
      icon: 'calendar',
      weight: 2,
      documentation: '/userguide/#datetime',
      schema: FormDatePicker.schema(),
    };
  }
}

FormDatePicker.editForm = function () {
  return {
    key: 'display',
    components: [
      ...BasicFields,
      {
        type: 'oldtextfield',
        input: true,
        key: 'format',
        label: 'Format',
        placeholder: 'Format',
        defaultValue: DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT,
        tooltip: 'The format for displaying the date.',
      },
      ...ConditionalFields,
      ...KeyField,
    ],
  };
};

Formio.Components.addComponent('formDatePicker', FormDatePicker);
