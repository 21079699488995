import React from 'react';
import ReactDOM from 'react-dom';
import {ReactComponent} from '@foldhealth/formio-react';
import { IFormValidationOutput } from './interfaces';

export default class CustomClassComponent extends ReactComponent {
  constructor(component: any, options: any, data: any) {
    super(component, options, data);
    this.reactInstance = null;
    this.setValueRef = React.createRef();
    this.validateRef = React.createRef();
  }

  labelIsHidden() {
    return true;
  }

  checkValidity(data: any) {
    if (
      this.validateRef?.current &&
      typeof this.validateRef.current === 'function' && 
      this._visible
    ) {
      const {key} = this.component;
      const currentData = data[key];
      const { isValid, message }: IFormValidationOutput = this.validateRef.current(currentData, data);
      if (!isValid) {
        this.setComponentValidity([{
          level: 'error',
          message: message || 'This field is required',
          componentInstance: this.component,
          context: {
            hasLabel: true,
          },
        }], true);
      }
      return isValid;
    }
    return true;
  }

  focus() {
    if ('beforeFocus' in this.parent) {
      this.parent.beforeFocus(this);
    }

    if (this.element) {
      this.element.scrollIntoViewIfNeeded();
    }
  }

  setValue(value: any) {
    if (
      this.setValueRef?.current &&
      typeof this.setValueRef.current === 'function'
    ) {
      this.setValueRef.current(value);
    }
    this.dataValue = value;
  }

  getValue() {
    return this.dataValue || this.defaultValue;
  }

  detachReact(element: any) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
}
