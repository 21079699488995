import { Drawer, Table, Tooltip } from "antd"
import { View, useToast,Skeleton } from "native-base"
import { BUTTON_TYPE, MLOV_CATEGORY } from "../../../constants";
import { Colors } from "../../../styles";
import MemberInfoListItem from "../MemberInfoListItem/MemberInfoListItem";
import { ModalActionTitle } from "../ModalActionTitle/ModalActionTitle";
import AlertTriangleSvg from "../Svg/AlertTriangleSvg";
import { TableWrapper } from "../TableWrapper";
import ContactsQueries from "../../../services/Contacts/ContactsQueries";
import { useContext, useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { getGroupDetailById } from "../../RightSideContainer/Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi";
import { getMlovListFromCategory } from "../../../utils/mlovUtils";
import { CommonDataContext } from "../../../context/CommonDataContext";
import { FORM_BATCH_STATUS_CODES } from "../../../constants/MlovConst";
import ActionCompletedSvg from "../Svg/ActionCompletedSvg";
import FeatherIcon from 'react-native-vector-icons/Feather';
import Helper from "./Helper";
import { CARESTUDIO_APOLLO_CONTEXT } from "../../../constants/Configs";
import { Dimensions } from "react-native";

interface IPopulationGroupFormDrawer {
    open: boolean;
    title: string;
    width: string;
    onClose: () => void;
    notification?: any
    dataSource?: any;
}

interface IPopulationGroupFormDrawerColumns {
    tooltip: string;
    actionFn?: (actionCode: string, actionData: any) => void
}

interface IComponentState {
    loading: boolean;
    groupDetails?: any,
    dataSourceForTable?: any[];
}

const PopulationGroupFormDrawer = (props: IPopulationGroupFormDrawer) => {
    const {height} = Dimensions.get('window');
    const [paginationState, setPaginationState] = useState(
        {
            loading: true,
            currentPage: 1,
            pageSize: 10,
            total: undefined,
        }
    )
    const [componentState, setComponentState] = useState<IComponentState>(
        {
            loading: false,
            groupDetails: {},
            dataSourceForTable: []
        }
    )

    const getGroupDetails = async () => {
        if (Object.keys(componentState?.groupDetails)?.length === 0) {
          const groupDetails = await getGroupDetailById(
            props?.notification?.metaData?.groups?.[0]?.groupId
          );
          setComponentState((prev) => {
            return {
              ...prev,
              groupDetails: groupDetails?.data,
              loading: false,
            };
          });
          setPaginationState((prev) => {
            return {
              ...prev,
              loading: false,
            };
          });
        }
    };

    useEffect(() => {
        setComponentState((prev) => {
          return {
            ...prev,
            loading: true,
          };
        });
      getGroupDetails();
    }, [props?.notification?.metaData?.groups?.[0]?.groupId]);

    useEffect(() => {
        setPaginationState((prev) => {
            return {
                ...prev,
                loading: true
            }
        })
        getPopulationGroupActivity({ limit: paginationState?.pageSize, offset: paginationState.currentPage })
    }, [props?.notification,paginationState.currentPage,paginationState.pageSize])

    const toast = useToast();

    const mlovData = useContext(CommonDataContext);
    const formBatchStatus = getMlovListFromCategory(
        mlovData.CARE_STUDIO_MLOV,
        MLOV_CATEGORY.FORM_BATCH_STATUS
    )

    const getStatusIcon = (record: any) => {
        const statusCode = formBatchStatus?.find((status) => status?.id === record?.statusId)?.code
        switch (statusCode) {
            case FORM_BATCH_STATUS_CODES.PASS: return <Tooltip title={"Sent"}> <ActionCompletedSvg /> </Tooltip>
            case FORM_BATCH_STATUS_CODES.FAIL: return <Tooltip title={record?.metadata}><AlertTriangleSvg /> </Tooltip>
            case FORM_BATCH_STATUS_CODES.PENDING: return <Tooltip title={"Pending"}> <FeatherIcon
                name="clock"
                size={18}
                style={{
                    marginRight: 5,
                    color: '#FFF'
                }}
                color={Colors.Custom.overdueTaskIconColor}
            /></Tooltip>
        }
    }

    const PopulationGroupFormDrawerColumns = (args?: IPopulationGroupFormDrawerColumns) => {
        return [
            {
                title: 'Name',
                dataIndex: 'contactdata',
                key: 'name',
                width: '65%',
                render: (contactData: any) => {
                    return (
                        <View>
                            {/* <Text color={Colors.Custom['Gray900']}>{text}</Text> */}
                            <MemberInfoListItem contactData={contactData} showDateOfBirth={true}/>
                        </View>
                    );
                },
            },
            {
                title: 'Status',
                dataIndex: 'statusId',
                key: 'status',
                width: '25%',
                render: (statusId: string, record: any) => {
                  return getStatusIcon(record);
                },
            },
        ];
    };

    const [getAnalytics] = useLazyQuery(Helper.getFormAnalytics, {
        context: { service: CARESTUDIO_APOLLO_CONTEXT },
        fetchPolicy: 'no-cache',
    })

    const [getContactsData] = useLazyQuery(ContactsQueries.GET_CONTACTS_BY_CONTACT_IDS, {
        fetchPolicy: 'no-cache',
    });

    const handlePaginationChange = async (currentPage: any, currentPageSize: any) => {
        setPaginationState((prev) => {
            return {
                ...prev,
                currentPage: currentPage,
                pageSize: currentPageSize,
            }
        });
    }

    const getPopulationGroupActivity = async (args: any) => {
        const groupIds: any[] = []
        const groupActivityLogIds = [props?.notification?.metaData?.groups?.[0]?.groupActivityLogId]
        props?.notification?.metaData?.groups?.forEach((group: any) => { groupIds.push(group?.groupId) })
        const objectForCall = {
          groupIds: groupIds,
          groupWiseActivityIds: groupActivityLogIds,
          limit: args?.limit || paginationState.pageSize,
          offset: args?.offset || 0,
        };
        setPaginationState((prev) => {
            return {
                ...prev,
                loading: true
            }
        })
        getAnalytics({ variables: { params: objectForCall } }).then(async (response: any) => {
            if (!paginationState?.total) {
                setPaginationState((prev) => {
                    return {
                        ...prev,
                        total: response?.data?.getPopulationGroupFormAnalytics?.aggregate?.total
                    }
                })
            }
            const analyticsData: any[] = response?.data?.getPopulationGroupFormAnalytics?.forms
            const contactIds = analyticsData.map((item) => item?.contactId)
            getContactsData({
                variables: {
                    contactIds: contactIds,
                },
            }).then((response) => {
                if (response?.data && response?.data?.contacts?.length > 0) {
                    response?.data?.contacts?.forEach((contact: any) => {
                        analyticsData?.some((item, index) => {
                            if (item.contactId === contact?.uuid) {
                                analyticsData[index].contactdata = contact
                            }
                        })
                    });
                    setPaginationState((prev) => {
                        return {
                            ...prev,
                            loading: false
                        }
                    })
                    setComponentState((prev) => {
                        return {
                            ...prev,
                            dataSourceForTable: analyticsData,
                            loading: false
                        }
                    })
                }
            }).catch((error) => {
                setComponentState((prev) => {
                    return {
                        ...prev,
                        loading: false
                    }
                })
                setPaginationState((prev) => {
                    return {
                        ...prev,
                        loading: false
                    }
                })
            })
        }).catch(() => {
            setComponentState((prev) => {
                return {
                    ...prev,
                    loading: false
                }
            })
            setPaginationState((prev) => {
                return {
                    ...prev,
                    loading: false
                }
            })
        })
    }

    return (
        <View>
            <Drawer
                open={props?.open}
                destroyOnClose
                width={props?.width}
                onClose={props?.onClose}
                title={
                    componentState?.loading ? <Skeleton.Text lines={2} />
                        :
                        <ModalActionTitle
                            title={componentState?.groupDetails?.name || props?.title}
                            titleColor={''}
                            buttonList={[
                                {
                                    show: true,
                                    id: 1,
                                    btnText: 'cancel',
                                    textColor: Colors.Custom.mainSecondaryBrown,
                                    variant: BUTTON_TYPE.SECONDARY,
                                    isTransBtn: false,
                                    onClick: () => {
                                        props?.onClose();
                                    },
                                },
                            ]}
                        />}
            >
                <TableWrapper
                    showDivierAbovePagination
                    hideOnSinglePage={false}
                    pagination={{
                        current: paginationState?.currentPage,
                        pageSize: paginationState?.pageSize,
                        total: paginationState?.total,
                        onChange: handlePaginationChange
                    }}
                >
                    <Table
                        dataSource={componentState?.dataSourceForTable || props?.dataSource}
                        columns={PopulationGroupFormDrawerColumns()}
                        pagination={false}
                        loading={paginationState?.loading}
                        scroll={{y: height - 254}}
                    />
                </TableWrapper>
            </Drawer>
        </View>

    )

}

export default PopulationGroupFormDrawer