export const formatCategories = (mlovList: any) => {
  const categoryList: any = [];
  mlovList?.mlovs.forEach((mlov: any) => {
    categoryList.push({id: mlov?.id, value: mlov?.value});
  });
  return categoryList;
};

export const formatGetTags = (tagList: any, setTagList?: any) => {
  const newTagList: any = [];
  const filterTagList = filterTagsFromSetTags(tagList, setTagList);
  filterTagList.forEach((tagDetail: any) => {
    newTagList.push({
      color: tagDetail?.color,
      id: tagDetail?.id,
      title: tagDetail?.title,
      description: tagDetail.description,
      labelTypeId: tagDetail?.labelTypes?.[0]?.labelTypeId || '',
    });
  });
  return newTagList;
};

export const formatGetCategoryId = (tagList: any, category: string) => {
  const categoryElement = tagList?.find((element: any) => {
    return element.value == category;
  });
  return categoryElement?.id;
};

export const formatConversationTags = (tagList: any) => {
  const newTagList: any = [];
  tagList.forEach((tagItem: any) => {
    newTagList.push({
      color: tagItem?.labels?.color,
      title: tagItem?.labels?.title,
    });
  });
  return newTagList;
};

export const formatContactTags = (tagList: any) => {
  const newTagList: any = [];
  tagList.forEach((tagItem: any) => {
    newTagList.push({
      color: tagItem?.labels?.color,
      title: tagItem?.labels?.title,
    });
  });
  return newTagList;
};

export const formatGetLabelDetails = (labelData: any, selectedLabels: any) => {
  const newList: any = [];
  selectedLabels.forEach((selectedItem: any) => {
    labelData.find((labelItem: any) => {
      labelItem.id == selectedItem ? newList.push(labelItem) : null;
    });
  });

  return newList;
};

export const formatGetMLovfromCategory = (category: any, mlovData: any) => {
  if (mlovData) {
    const categoryElement = mlovData?.MLOV?.LabelType?.find((element: any) => {
      return element.value == category;
    });
    return categoryElement?.id || '';
  } else {
    return '';
  }
};

export const filterTagsFromSetTags = (
  mainLabelList: any,
  setLabelList: any
) => {
  let res = [];
  res = mainLabelList?.filter((el: any) => {
    return !setLabelList?.find((element: any) => {
      return element?.labels?.id === el?.id || element?.id === el?.id;
    });
  });
  return res;
};
