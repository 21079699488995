import React from 'react';
import {isWeb} from '../../../utils/platformCheckUtils';
import {Image} from 'native-base';
const GroupIconOutline = () => {
  return isWeb() ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      fill="none"
      viewBox="0 0 50 50"
    >
      <rect
        width="49"
        height="49"
        x="0.5"
        y="0.5"
        fill="#F9F5FF"
        rx="24.5"
      ></rect>
      <path
        stroke="#825AC7"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M30 34v-2a4 4 0 00-4-4h-8a4 4 0 00-4 4v2m22 0v-2a4 4 0 00-3-3.87m-4-12a4 4 0 010 7.75M26 20a4 4 0 11-8 0 4 4 0 018 0z"
      ></path>
      <rect
        width="49"
        height="49"
        x="0.5"
        y="0.5"
        stroke="#825AC7"
        strokeLinecap="round"
        rx="24.5"
      ></rect>
    </svg>
  ) : (
    <Image
      size={'10'}
      source={require('../../../assets/images/MessageActionImages/GroupIconOutline.png')}
      alt="aa"
    />
  );
};

export default GroupIconOutline;
