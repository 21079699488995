import React, {useState} from 'react';
import {Checkbox, Popover, Radio} from 'antd';
import {Button, Divider, Pressable, Text, View} from 'native-base';
import {Colors} from '../../../../../../../../styles';
import AntIcon from 'react-native-vector-icons/AntDesign';
import Feather from 'react-native-vector-icons/Feather';

const SideTrackingListSort = (props: {
  appointmentStatusList: any;
  onFilterChange: (status?: string | null) => void;
  currentActiveFilter?: string | null;
}) => {
  const [popover, setPopover] = useState(false);
  const filterList = Object.keys(props?.appointmentStatusList);
  const getIsChecked = (type: string) => {
    if (type === 'need_action') {
      return props.currentActiveFilter === null;
    }
    return props?.appointmentStatusList[type] === props?.currentActiveFilter;
  };
  return (
    <Popover
      overlayInnerStyle={{padding: 0, borderRadius: 16}}
      overlayStyle={{padding: 0}}
      placement={'bottomLeft'}
      trigger="click"
      open={popover}
      onOpenChange={(popover) => setPopover(popover)}
      content={
        <>
          {filterList.map((type: any,index) => {
            const value = type === 'need_action' ? 'No response' : type;
            const isChecked = getIsChecked(type);
            return (
              <>
                <Button
                  justifyContent={'flex-start'}
                  leftIcon={
                   <Radio checked={isChecked} />
                  }
                  size={'smMedium'}
                  variant={'unstyled'}
                  _text={{
                    fontWeight: 700,
                    fontSize: 14,
                    textTransform: 'capitalize',
                  }}
                  style={{
                    paddingVertical: 10,
                    borderBottomColor: Colors.Custom.Gray200,
                  }}
                  borderRadius={0}
                  _hover={{
                    _text: {
                      color: Colors.Custom.PurpleColor,
                    },
                  }}
                  onPress={() => {
                    props?.onFilterChange(props?.appointmentStatusList[type]);
                  }}
                >
                  {value}
                </Button>
                {(index < filterList.length - 1) && <Divider />}
              </>
            );
          })}
          {props?.currentActiveFilter !== undefined && (
            <Button
              justifyContent={'flex-start'}
              size={'smMedium'}
              variant={'unstyled'}
              leftIcon={
                <Feather
                  name="trash-2"
                  size={16}
                  color={Colors.Custom.Gray400}
                />
              }
              _text={{
                fontWeight: 700,
                fontSize: 14,
                textTransform: 'capitalize',
              }}
              style={{
                paddingVertical: 10,

                borderBottomColor: Colors.Custom.Gray200,
              }}
              _hover={{
                _text: {
                  color: Colors.Custom.PurpleColor,
                },
              }}
              onPress={() => {
                props?.onFilterChange(undefined);
              }}
            >
              Clear
            </Button>
          )}
        </>
      }
    >
      <Pressable
        onPress={() => setPopover(true)}
        style={{
          padding: 12,
          backgroundColor: Colors.Custom.ContainerBGColor,
          borderColor: Colors.Custom.Gray300,
          borderWidth: 1,
          borderRadius: 6,
          marginLeft: 6,
        }}
      >
        {props?.currentActiveFilter !== undefined ? (
          <View
            style={{
              backgroundColor: Colors.Custom.PurpleColor,
              width: 20,
              height: 20,
              borderRadius: 20,
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              zIndex: 99,
              top: -10,
              right: -10,
            }}
          >
            <Text
              color={Colors.Custom.ContainerBGColor}
              style={{}}
              fontWeight={700}
              fontSize={14}
            >
              1
            </Text>
          </View>
        ) : null}

        <AntIcon name="filter" color={Colors.Custom.PurpleColor} size={18} />
      </Pressable>
    </Popover>
  );
};

export default SideTrackingListSort;
