import {Collapse, Grid, Space} from '@arco-design/web-react';
// FIXME: for mobile needs to be commented
import '@arco-themes/react-easy-email-theme-purple/css/arco.css';
import {Drawer} from 'antd';
import {
  AdvancedType,
  BasicType,
  BlockManager,
  JsonToMjml,
} from '@foldhealth/easy-email-core';
import {
  EmailEditor,
  EmailEditorProvider,
  IEmailTemplate,
  Stack,
  useFocusIdx,
} from '@foldhealth/easy-email-editor';
import {
  AttributesPanelWrapper,
  BlockAttributeConfigurationManager,
  ColorPickerField,
  ExtensionProps,
  FontFamily,
  InputWithUnitField,
  StandardLayout,
  TextAreaField,
  TextField,
} from '@foldhealth/easy-email-extensions';
//import { AddFont } from '@foldhealth/easy-email-extensions';
import {Liquid} from 'liquidjs';
import {
  Button,
  Checkbox,
  FormControl,
  HStack,
  Input,
  Select,
  Skeleton,
  Spinner,
  Stack as NBStack,
  Tooltip,
  useMediaQuery,
  useToast,
  View,
} from 'native-base';
import {useCallback, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Dimensions} from 'react-native';
import AntIcon from 'react-native-vector-icons/AntDesign';
import Feather from 'react-native-vector-icons/Feather';
import {useNavigate} from 'react-router-dom';
import {
  BUTTON_TYPE,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH,
} from '../../../../../constants';
import {getSuggestions} from '../../../../../services/Billing/BillingServices';
import {Colors} from '../../../../../styles';
import {commonBodyContentContainer} from '../../../../../styles/CommonBodyContentStyle';
import {
  getAccountUUID,
  getOnFocusButtonStyles,
  getUserActionObjectFromActionCode,
  getUserUUID,
} from '../../../../../utils/commonUtils';
import {showToast, ToastType} from '../../../../../utils/commonViewUtils';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import EmailDrawerCommon from '../../../../common/EmailPopupView/EmailDrawerCommon';
import ModalActionBtn from '../../../../common/ModalActionBtn/ModalActionBtn';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import SuggestionsSvg from '../../../../common/Svg/SideMenu/SuggestionsSvg';
import TitleSubtitleView from '../../../../common/TitleSubtitleView/TitleSubtitleView';
import {handleCreateEmailTemplateAction} from '../../../../common/UsageLevel/UsageLevelHandlers';
import {FoldButton} from '../../../../CommonComponents/FoldButton/FoldButton';
import {ContentTypes, fontList, mergeTags} from '../../ContentManagementConsts';
import {
  createTemplate,
  getAccountMergeTagData,
  getTemplateCategories,
  getTemplateCategoryList,
} from '../../ContentManagementUtils';
import {EmailComponentsListDrawer} from '../../EmailComponents/EmailComponentsListDrawer';
import {OverrideModal} from '../../OverrideModal';
import ViewDalleSuggestions from '../../ViewDalleSuggestions/ViewDalleSuggestions';
import ViewMedlineSuggestions from '../../ViewMedlineSuggestions/ViewMedlineSuggestions';
import ViewOpenaiSuggestions from '../../ViewOpenaiSuggestions/ViewOpenaiSuggestions';
import {ComingSoonModal} from '../ComingSoonModal';
import {
  emailTemplateValidation,
  getDefaultComponent,
  getEmailTemplateFormattedObj,
  uploadImage,
} from '../EmailTemplatesUtils';
import {
  IEmailTemplateCreatorProps,
  IEmailTemplateData,
  ISuggestions,
  ITemplateCategory,
} from '../interfaces';
//FIXME: for mobile needs to be commented
import './CreateOrEditTemplateCss.css';
import {
  getEmailTemplateButtonStyles,
  styles,
} from './CreateOrEditTemplateStyles';
import CustomSuggestionsButton, {
  SuggestionsOptions,
} from './CustomSuggestionsButton';
import {EmailDrawerCommonV2} from '../../../../common/EmailDrawerCommonV2';
import {FormsBlockWrapper} from './CustomBlocks/FormsBlock';
import {SubjectInput} from '../SubjectInput';
import { filteredFormsCategoryList } from '../../../../../utils/commonUtils';

const {Item} = Select;
const {Label} = FormControl;
const pageBlock = BlockManager.getBlockByType(BasicType.PAGE)!;

const EmailTemplateCreator = (props: IEmailTemplateCreatorProps) => {
  const {focusIdx} = useFocusIdx();

  const accountUuid = getAccountUUID();
  const userUuid = getUserUUID();
  const userActionObject = getUserActionObjectFromActionCode(
    'CONFIGURE_AN_EMAIL_TEMPLATE'
  );
  const level = userActionObject.level || '';

  BlockAttributeConfigurationManager.add({
    [BasicType.PAGE]: () => (
      <AttributesPanelWrapper style={{padding: 0}}>
        <Stack.Item fill>
          <Collapse defaultActiveKey={['0', '1']}>
            <Collapse.Item name="0" header="Email Setting">
              <Space direction="vertical">
                <TextField label="SubTitle" name={'subTitle'} inline />
                <InputWithUnitField
                  label="Width"
                  name={`${focusIdx}.attributes.width`}
                  inline
                />
                <InputWithUnitField
                  label="Breakpoint"
                  helpText="Allows you to control on which breakpoint the layout should go desktop/mobile."
                  name={`${focusIdx}.data.value.breakpoint`}
                  inline
                />
              </Space>
            </Collapse.Item>
            <Collapse.Item name="1" header="Theme Setting">
              <Stack vertical spacing="tight">
                <Grid.Row>
                  <Grid.Col span={11}>
                    <FontFamily name={`${focusIdx}.data.value.font-family`} />
                  </Grid.Col>
                  <Grid.Col offset={1} span={11}>
                    <InputWithUnitField
                      label="Font size"
                      name={`${focusIdx}.data.value.font-size`}
                    />
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Col span={11}>
                    <InputWithUnitField
                      label="Line height"
                      unitOptions="percent"
                      name={`${focusIdx}.data.value.line-height`}
                    />
                  </Grid.Col>
                  <Grid.Col offset={1} span={11}>
                    <InputWithUnitField
                      label="Font weight"
                      unitOptions="percent"
                      name={`${focusIdx}.data.value.font-weight`}
                    />
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Col span={11}>
                    <ColorPickerField
                      label="Text color"
                      name={`${focusIdx}.data.value.text-color`}
                    />
                  </Grid.Col>
                  <Grid.Col offset={1} span={11}>
                    <ColorPickerField
                      label="Background"
                      name={`${focusIdx}.attributes.background-color`}
                    />
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                  <ColorPickerField
                    label="Content background"
                    name={`${focusIdx}.data.value.content-background-color`}
                  />
                </Grid.Row>

                <TextAreaField
                  autoSize
                  label="User style"
                  name={`${focusIdx}.data.value.user-style.content`}
                />
                <Stack.Item />
                <Stack.Item />
                {/* <AddFont /> */}
                <Stack.Item />
                <Stack.Item />
              </Stack>
            </Collapse.Item>
          </Collapse>
        </Stack.Item>
      </AttributesPanelWrapper>
    ),
  });

  const [templateJson, setTemplateJson] = useState<IEmailTemplate['content']>(
    pageBlock.create({
      data: {
        value: {},
      },
    })
  );

  const [state, setState] = useState({
    initialLoading: false,
    submitting: false,
  });

  const [editedTemplate, setEditedTemplate] = useState<IEmailTemplateData>(
    {} as IEmailTemplateData
  );
  const [tName, setTName] = useState<string>('');
  const [tSuggestion, setTSuggestion] = useState<string>('');
  const [suggestion, setSuggestion] = useState<ISuggestions>({suggestions: []});

  const [tCategory, setTCategory] = useState<string>('');
  const [templateHtml, setTemplateHtml] = useState('');
  const [templateDefaultStatus, setTemplateDefaultStatus] =
    useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState<ITemplateCategory[]>([]);
  const [overrideModal, setOverrideModal] = useState(false);
  const [tValues, setTValues] = useState<any>();
  const [subject, setSubject] = useState('');
  const [openEmailDrawerModal, setOpenEmailDrawerModal] = useState(false);
  const [openSuggestionsModal, setOpenSuggestionsModal] = useState(false);
  const [openEduSuggestionsModal, setOpenEduSuggestionsModal] = useState(false);
  const [openImageSuggestionsModal, setOpenImageSuggestionsModal] =
    useState(false);

  const [customDrawerOpen, setCustomDrawerOpen] = useState(false);

  const [sendEmail, setSendEmail] = useState(false);
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);
  const {width} = Dimensions.get('window');

  const drawerWidth =
    isIPadMiniScreen || isIPadScreen ? width * 0.7 : width * 0.4;

  const isSmallScreen = isIPadMiniScreen || isIPadScreen;

  const toast = useToast();
  const intl = useIntl();

  const onChangeMergeTag = useCallback((path: string, val: any) => {
  }, []);

  const setSubmitting = (value: boolean) => {
    setState((prev) => {
      return {
        ...prev,
        submitting: value,
      };
    });
  };

  const defaultCategories: ExtensionProps['categories'] = [
    {
      label: 'Content',
      active: true,
      blocks: [
        {
          type: AdvancedType.TEXT,
        },
        {
          type: AdvancedType.IMAGE,
          payload: {attributes: {padding: '0px 0px 0px 0px'}},
        },
        {
          type: AdvancedType.BUTTON,
        },
        {
          type: AdvancedType.SOCIAL,
        },
        {
          type: AdvancedType.DIVIDER,
        },
        {
          type: AdvancedType.SPACER,
        },
        {
          type: AdvancedType.HERO,
        },
        {
          type: AdvancedType.WRAPPER,
        },
        {
          type: AdvancedType.ACCORDION,
        },
        // {
        //   type: AdvancedType.CAROUSEL,
        // },
        {
          type: AdvancedType.NAVBAR,
        },
        {
          type: AdvancedType.GROUP,
        },
        {
          type: AdvancedType.COLUMN,
        },
        {
          type: AdvancedType.SECTION,
        },
      ],
    },

    {
      label: 'Layout',
      active: true,
      displayType: 'column',
      blocks: [
        {
          title: '2 columns',
          payload: [
            ['50%', '50%'],
            ['33%', '67%'],
            ['67%', '33%'],
            ['25%', '75%'],
            ['75%', '25%'],
          ],
        },
        {
          title: '3 columns',
          payload: [
            ['33.33%', '33.33%', '33.33%'],
            ['25%', '25%', '50%'],
            ['50%', '25%', '25%'],
          ],
        },
        {
          title: '4 columns',
          payload: [[['25%', '25%', '25%', '25%']]],
        },
      ],
    },
    {
      label: 'Custom',
      active: true,
      displayType: 'custom',
      blocks: [<FormsBlockWrapper />],
    },
  ];

  const handleOverride = () => {
    const mjml = JsonToMjml({
      data: tValues.content,
      mode: 'production',
      context: tValues.content,
      dataSource: mergeTags,
    });
    const newTemplate: IEmailTemplateData = {
      templateName: tName,
      name: tName,
      templateCategory: tCategory,
      templateIsDefault: templateDefaultStatus,
      templateMjml: mjml,
      templateHtml: templateHtml,
      templateData: {
        ...tValues,
        subject: subject,
      },
    };
    const emailTemplateData = getEmailTemplateFormattedObj(
      newTemplate,
      categories,
      true
    );
    showToast(toast, 'Saving...', ToastType.success);
    setSubmitting(true);
    createTemplate(ContentTypes.emails.path, emailTemplateData)
      .then((response) => {
        setSubmitting(false);
        setLoading(true);
        return response;
      })
      .then((responseObj) => {
        handleCreateEmailTemplateAction({accountUuid, userUuid, level}).then(
          (r) => {
            setLoading(false);
            navigate('/admin/contentManagement/emailTemplates');
          }
        );
      })
      .catch((error) => {
        setSubmitting(false);
        if (error.response) {
        }
      });

    setOverrideModal(false);
  };

  const setInitialValues = async () => {
    setState((prev) => {
      return {
        ...prev,
        initialLoading: true,
      };
    });
    try {
      const headerResponse = await getDefaultComponent({type: 'HEADER'});
      const footerResponse = await getDefaultComponent({type: 'FOOTER'});
      const headerJson =
        headerResponse.data.length > 0
          ? headerResponse.data[0]?.attributes?.bodyJson
          : undefined;
      const footerJson =
        footerResponse.data.length > 0
          ? footerResponse.data[0]?.attributes?.bodyJson
          : undefined;
      const initialTemplateJson = pageBlock.create({
        data: {
          value: {},
        },
      });
      const children = initialTemplateJson.children;
      if (headerJson) {
        headerJson.type = 'wrapper';
        children.unshift(headerJson);
      }
      if (footerJson) {
        footerJson.type = 'wrapper';
        children.push(footerJson);
      }
      initialTemplateJson.children = children;
      setTemplateJson(initialTemplateJson);
      setState((prev) => {
        return {
          ...prev,
          initialLoading: false,
        };
      });
    } catch (error) {
      setTemplateJson(
        pageBlock.create({
          data: {
            value: {},
          },
        })
      );
      setState((prev) => {
        return {
          ...prev,
          initialLoading: false,
        };
      });
    }
  };


  useEffect(() => {
    setInitialValues();
    getTemplateCategories()
      .then((data) => {
        return getTemplateCategoryList(data);
      })
      .then((list) => {

        const filteredCategories = filteredFormsCategoryList(list)
        setCategories(filteredCategories);
        if (filteredCategories && filteredCategories.length && props.preSelectedCategory) {
          const selectedCategory = filteredCategories.find((item) => {
            return item.name == props?.preSelectedCategory;
          });
          if (selectedCategory) {
            setTCategory(selectedCategory?.name);
          }
        }
      })
      .catch((error) => {});
  }, []);

  const navigate = useNavigate();

  const onSubmit = (values: IEmailTemplate) => {
    setTValues(values);

    const mjml = JsonToMjml({
      data: values.content,
      mode: 'production',
      context: values.content,
      dataSource: mergeTags,
    });
    setTemplateJson(values.content);
    const templateCategory = categories.find((item) => item.name === tCategory);
    const newTemplate: IEmailTemplateData = {
      templateName: tName,
      name: tName,
      templateCategory: tCategory,
      templateSuggestions: tSuggestion,
      templateIsDefault: templateDefaultStatus,
      templateMjml: mjml,
      templateHtml: templateHtml,
      templateCategoryCode: templateCategory?.code || '',
      templateData: {
        ...values,
        subject: subject,
      },
    };
    setErrors(emailTemplateValidation(newTemplate).errors);
    if (emailTemplateValidation(newTemplate).validate) {
      const emailTemplateData = getEmailTemplateFormattedObj(
        newTemplate,
        categories
      );

      if (!sendEmail) {
        showToast(toast, 'Saving...', ToastType.success);
        setSubmitting(true);
        createTemplate(ContentTypes.emails.path, emailTemplateData)
          .then((response) => {
            setSubmitting(false);
            setLoading(true);
            return response;
          })
          .then((responseObj) => {
            handleCreateEmailTemplateAction({
              accountUuid,
              userUuid,
              level,
            }).then((r) => {
              setLoading(false);
              if (props?.customOnSubmitFn) {
                props?.customOnSubmitFn(responseObj.data);
              } else {
                navigate('/admin/contentManagement/emailTemplates');
              }
            });
          })
          .catch((error) => {
            errorHandler(error);
          });
      } else {
        setEditedTemplate(newTemplate);
        setOpenEmailDrawerModal(true);
      }
    } else {
    }
  };

  const errorHandler = (error: any) => {
    if (
      error.response?.status === 400 &&
      error.response.data?.error === 'Default Template Already Present'
    ) {
      setSubmitting(false);
      setOverrideModal(true);
    } else {
      showToast(
        toast,
        'Something went wrong. Please try again later',
        ToastType.error
      );
    }
  };

  const accountMergeTags = getAccountMergeTagData();

  const getSuggestionsResp = async (suggestion: any) => {
    const suggestionsResp = await getSuggestions(suggestion);
    if (suggestionsResp) {
      const result = suggestionsResp.data?.result;
      const suggs = [];
      if (result && result.length > 0) {
        if (result && result.length > 0) {
          for (const choice of result) {
            const sugg = {
              title: suggestion,
              description: choice?.text,
            };

            suggs.push(sugg);
          }
        }
      }
      const suggestionsT = {
        suggestions: suggs,
      };
      setSuggestion(suggestionsT);
    }
  };

  const getMergeTags = (category: string) => {
    const mergeTagsByCategory = categories.find(
      (item) => item.name === category
    )?.mergeTags;

    return {
      ...mergeTags,
      ...mergeTagsByCategory,
      global: accountMergeTags,
      ...(suggestion?.suggestions && suggestion?.suggestions?.length > 0
        ? suggestion
        : {}),
    };
  };

  const onBeforePreview = (html: string, mergeTags: any) => {
    setTemplateHtml(html);
    const engine = new Liquid();
    const tpl = engine.parse(html);
    return engine.renderSync(tpl, mergeTags);
  };

  const onHeaderBackClick = () => {
    navigate(-1);
  };

  const beforeunloadEvent = (ev: BeforeUnloadEvent) => {
    ev.preventDefault();
    ev.returnValue = '';
  };

  useEffect(() => {
    window.addEventListener('beforeunload', beforeunloadEvent);

    return () => {
      window.removeEventListener('beforeunload', beforeunloadEvent);
    };
  }, []);

  return (
    <View>
      {loading ? (
        <Spinner />
      ) : (
        <View>
          {!props?.shouldHideTitle ? (
            <View style={{backgroundColor: '#fff'}}>
              <TitleSubtitleView
                titleLabelId="createTemplate"
                subtitle="Here you can create new email templates"
                showBackButton={true}
                onBackClick={onHeaderBackClick}
              />
            </View>
          ) : null}
          <View
            style={[
              commonBodyContentContainer.pageContentContainer,
              {padding: 14,},
            ]}
          >
            {state.initialLoading ? (
              <Skeleton.Text lines={4} />
            ) : (
              <EmailEditorProvider
                dashed={false}
                fontList={fontList}
                data={{
                  subject: '',
                  subTitle: '',
                  content: templateJson,
                }}
                height={'80vh'}
                autoComplete
                onSubmit={onSubmit}
                mergeTags={getMergeTags(tCategory)}
                mergeTagGenerate={(m: string) => `{{${m}}}`}
                onChangeMergeTag={onChangeMergeTag}
                onBeforePreview={onBeforePreview}
                onUploadImage={(blob: Blob) => {
                  return uploadImage(blob);
                }}
              >
                {({}, {submit}) => {
                  return (
                    <View style={{padding: 2}}>
                      <View
                        style={{
                          backgroundColor: '#fff',
                          borderColor: Colors.Custom.BorderColor,
                          borderWidth: 1,
                          borderRadius: 8,
                        }}
                      >
                        <HStack style={styles.topbarContainer}>
                          <HStack
                            style={{
                              justifyContent: isSmallScreen
                                ? 'space-between'
                                : 'space-between',
                              flex: 1,
                            }}
                          >
                            <HStack>
                              <View style={styles.inputStyle}>
                                <FormControl
                                  isRequired
                                  isInvalid={errors.templateName}
                                >
                                  <HStack
                                    style={{
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    <Input
                                      _focus={{borderColor: '#D0D5DD'}}
                                      placeholder="Enter Name"
                                      onChangeText={(text: string) =>
                                        setTName(text)
                                      }
                                    />
                                  </HStack>
                                </FormControl>
                              </View>
                              <View style={styles.dropdownStyle}>
                                <FormControl
                                  isRequired
                                  isInvalid={errors.templateCategory}
                                >
                                  <HStack
                                    style={{
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    <Select
                                      onValueChange={(value: string) =>
                                        setTCategory(value)
                                      }
                                      selectedValue={
                                        tCategory ? tCategory : undefined
                                      }
                                      isDisabled={
                                        props?.shouldDisableChangeCategory
                                      }
                                      placeholder={'Select Category'}
                                    >
                                      {categories.map((item) => {
                                        return (
                                          <Item
                                            key={item.id}
                                            value={item.name}
                                            label={item.name}
                                          />
                                        );
                                      })}
                                    </Select>
                                  </HStack>
                                </FormControl>
                              </View>
                              <View style={styles.inputStyle}>
                                <FormControl
                                  isRequired
                                  isInvalid={errors.subject}
                                >
                                  <HStack
                                    style={{
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    <SubjectInput
                                      isError={!!errors.subject}
                                      mergeTags={getMergeTags(tCategory)}
                                      value={subject}
                                      _focus={{borderColor: '#D0D5DD'}}
                                      placeholder={'Enter Subject'}
                                      onChangeText={(text: string) => {
                                        setSubject(text);
                                      }}
                                    />
                                  </HStack>
                                </FormControl>
                              </View>
                            </HStack>

                            <HStack>
                              {' '}
                              {!props.shouldHideDefault ? (
                                <View style={styles.switchView}>
                                  <FormControl>
                                    <HStack
                                      style={{
                                        justifyContent: 'space-between',
                                      }}
                                    >
                                      <Label marginTop={2}>Default</Label>
                                      <Checkbox
                                        marginTop={2}
                                        value="true"
                                        onChange={(isChecked: boolean) => {
                                          setTemplateDefaultStatus(isChecked);
                                        }}
                                      />
                                    </HStack>
                                  </FormControl>
                                </View>
                              ) : null}
                              <View style={styles.inputStyle}>
                                <FormControl
                                  isRequired
                                  isInvalid={errors.templateName}
                                >
                                  <HStack
                                    style={{
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    <CustomSuggestionsButton
                                      title={intl.formatMessage({
                                        id: 'contentSuggestions',
                                      })}
                                      onClick={(action: SuggestionsOptions) => {
                                        if (action === 'suggestContent') {
                                          setOpenSuggestionsModal(true);
                                        }
                                        if (action === 'suggestEduContent') {
                                          setOpenEduSuggestionsModal(true);
                                        }
                                        if (action === 'suggestImageContent') {
                                          setOpenImageSuggestionsModal(true);
                                        }
                                      }}
                                      leftIcon={<SuggestionsSvg />}
                                    />
                                  </HStack>
                                </FormControl>
                              </View>
                              <Button
                                style={getEmailTemplateButtonStyles({
                                  btnType: 'primary',
                                })}
                                _focus={{
                                  ...getOnFocusButtonStyles(),
                                }}
                                onPress={() => {
                                  setCustomDrawerOpen(true);
                                }}
                                _text={{
                                  color: '#825AC7',
                                }}
                              >
                                Custom
                              </Button>
                              <Button
                                style={getEmailTemplateButtonStyles({
                                  btnType: 'primary',
                                })}
                                _focus={{
                                  ...getOnFocusButtonStyles(),
                                }}
                                leftIcon={
                                  <AntIcon
                                    name="mail"
                                    color={'#825AC7'}
                                    size={16}
                                  />
                                }
                                onPress={() => {
                                  setOpenEmailDrawerModal(true);
                                }}
                                _text={{
                                  color: '#825AC7',
                                }}
                              >
                                Test Mail
                              </Button>
                              <Button
                                style={getEmailTemplateButtonStyles({
                                  btnType: 'primary',
                                })}
                                _focus={{
                                  ...getOnFocusButtonStyles({
                                    backgroundColor: Colors.danger['100'],
                                    borderColor: Colors.danger['200'],
                                  }),
                                }}
                                leftIcon={
                                  <AntIcon
                                    name="back"
                                    size={15}
                                    color={'#825AC7'}
                                  />
                                }
                                onPress={() => {
                                  props?.onCancel
                                    ? props.onCancel()
                                    : navigate(
                                        '/admin/contentManagement/emailTemplates'
                                      );
                                }}
                                _text={{
                                  color: '#825AC7',
                                }}
                              >
                                Discard
                              </Button>
                              <Button
                                style={getEmailTemplateButtonStyles({
                                  btnType: 'primary',
                                })}
                                isLoading={state.submitting}
                                _focus={{
                                  ...getOnFocusButtonStyles({
                                    backgroundColor: Colors.secondary['100'],
                                    borderColor: Colors.secondary['200'],
                                  }),
                                }}
                                leftIcon={
                                  <Feather
                                    name="save"
                                    color={'#825AC7'}
                                    size={16}
                                  />
                                }
                                onPress={() => {
                                  setSendEmail(false);
                                  submit();
                                }}
                                _text={{
                                  color: '#825AC7',
                                }}
                              >
                                Save & Exit
                              </Button>
                            </HStack>
                          </HStack>
                        </HStack>
                        <ComingSoonModal
                          isOpen={isModalOpen}
                          onModalClose={() => setIsModalOpen(false)}
                          headerText="Coming Soon"
                          bodyText="Coming Soon"
                        />
                        <View>
                          <StandardLayout
                            compact={!isSmallScreen}
                            categories={defaultCategories}
                          >
                            <EmailEditor />
                          </StandardLayout>
                        </View>
                      </View>

                      <Drawer
                        open={openEduSuggestionsModal}
                        onClose={() => {
                          setOpenEduSuggestionsModal(false);
                        }}
                        width={drawerWidth}
                        title={
                          <ModalActionTitle
                            title={'viewMedlineSuggestions'}
                            titleColor={''}
                            buttonList={[
                              {
                                show: true,
                                id: 1,
                                btnText: 'cancel',
                                textColor: Colors.Custom.mainSecondaryBrown,
                                variant: BUTTON_TYPE.SECONDARY,
                                isTransBtn: false,
                                onClick: () => {
                                  setOpenEduSuggestionsModal(false);
                                },
                              },
                            ]}
                          />
                        }
                      >
                        <ViewMedlineSuggestions
                          selectedGroupInfo={subject}
                          onModalClose={() => {
                            setOpenEduSuggestionsModal(false);
                          }}
                        />
                      </Drawer>
                      <Drawer
                        open={openSuggestionsModal}
                        onClose={() => {
                          setOpenSuggestionsModal(false);
                        }}
                        width={drawerWidth}
                        title={
                          <ModalActionTitle
                            title={'contentSuggestions'}
                            titleColor={''}
                            buttonList={[
                              {
                                show: true,
                                id: 1,
                                btnText: 'cancel',
                                textColor: Colors.Custom.mainSecondaryBrown,
                                variant: BUTTON_TYPE.SECONDARY,
                                isTransBtn: false,
                                onClick: () => {
                                  setOpenSuggestionsModal(false);
                                },
                              },
                            ]}
                          />
                        }
                      >
                        <ViewOpenaiSuggestions
                          onModalClose={() => {
                            setOpenSuggestionsModal(false);
                          }}
                          template={editedTemplate}
                        />
                      </Drawer>
                      <Drawer
                        open={openImageSuggestionsModal}
                        onClose={() => {
                          setOpenImageSuggestionsModal(false);
                        }}
                        width={drawerWidth}
                        title={
                          <ModalActionTitle
                            title={'contentSuggestions'}
                            titleColor={''}
                            buttonList={[
                              {
                                show: true,
                                id: 1,
                                btnText: 'cancel',
                                textColor: Colors.Custom.mainSecondaryBrown,
                                variant: BUTTON_TYPE.SECONDARY,
                                isTransBtn: false,
                                onClick: () => {
                                  setOpenImageSuggestionsModal(false);
                                },
                              },
                            ]}
                          />
                        }
                      >
                        <ViewDalleSuggestions
                          onModalClose={() => {
                            setOpenImageSuggestionsModal(false);
                          }}
                          template={editedTemplate}
                        />
                      </Drawer>
                      {customDrawerOpen && (
                        <EmailComponentsListDrawer
                          isOpen={customDrawerOpen}
                          onClose={() => {
                            setCustomDrawerOpen(false);
                          }}
                        />
                      )}
                    </View>
                  );
                }}
              </EmailEditorProvider>
            )}
          </View>
        </View>
      )}

      <OverrideModal
        isOpen={overrideModal}
        onClose={() => setOverrideModal(false)}
        textContent={{
          headerText: 'Override Default Template',
          message:
            'Default template for the same category is already present. Do you want to override and make this template as default?',
        }}
        onConfirm={() => handleOverride()}
      />

      {openEmailDrawerModal && (
        <EmailDrawerCommonV2
          isOpen={openEmailDrawerModal}
          onClose={() => {
            setOpenEmailDrawerModal(false);
          }}
          onEmailSent={() => {
            setOpenEmailDrawerModal(false);
          }}
          html={templateHtml}
          subject={subject || ''}
        />
      )}
    </View>
  );
};

export default EmailTemplateCreator;
