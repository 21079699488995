import {DatePicker, InputNumber} from 'antd';
import {Text, HStack, View, VStack} from 'native-base';
import React, { useEffect, useState } from 'react';
import {
  convertVitalValues,
  feetAndInchesToCm,
  feetAndInchesToInches,
  isInchesUnit,
} from '../../../../../../../utils/vitalUnitConversions';
import {VitalType} from '../../../../../../TaskCard/VitalCaptureInput/VitalCaptureInput';
import { Vital, VitalInputType } from '../../../../../../../utils/VitalUtils';
import { ModalActionAntSelect } from '../../../../../../common/ModalActionCommonComponent/ModalActionAntSelect';
import { PossibleValue } from '../../../../../../../Interfaces';
import { getMomentObj, isFutureDay } from '../../../../../../../utils/DateUtils';
import { DISPLAY_DATE_FORMAT } from '../../../../../../../constants';
import { reactStyles } from '../../../../Analytics/style';

const VitalInput = (props: {
  label: string;
  decimalPrecision: number;
  vital?: {
    value?: string;
    min?: number;
    max?: number;
  };
  unit?: string;
  displayUnit?: string;
  disabled?: boolean;
  lastRecordedText?: string;
  type: VitalType;
  inputType: VitalInputType;
  possibleValues?: PossibleValue[];
  format?: string;
  loinc?: string;
  defaultValue?: {
    vital1: string | number | undefined;
    vital2: string | number | undefined;
  },
  showErrors?: boolean;
  onChange: (value?: string) => void;
}) => {
  const {
    label,
    decimalPrecision,
    vital,
    unit,
    type,
    disabled,
    lastRecordedText,
    inputType,
    possibleValues,
    format,
    displayUnit,
    loinc,
    defaultValue,
    showErrors,
    onChange,
  } = props;

  const [componentState, setComponentState] = useState<{
    vital1: string | number | undefined,
    vital2: string | number | undefined,
  }>({vital1: defaultValue?.vital1, vital2: defaultValue?.vital2})

  useEffect(() => {
    handleChange(componentState);
  }, [componentState.vital1, componentState.vital2]);

  const handleChange = (params: {
    vital1?: string | number;
    vital2?: string | number;
  }) => {
    let result = undefined;
    if (type === VitalType.normal) {
      if (displayUnit && unit && displayUnit !== unit) {
        result = convertVitalValues(params.vital1, displayUnit, unit, decimalPrecision);
      } else {
        result = params.vital1;
      }
    } else if (
      type === VitalType.bloodPressure &&
      (params.vital1 || params.vital2)
    ) {
      result = `${params.vital1 || ''}/${params.vital2 || ''}`;
    } else if (
      type === VitalType.heightWithFeetAndInches &&
      (params.vital1 != undefined ||
        params.vital1 != '' ||
        params.vital2 != undefined ||
        params.vital2 != '')
    ) {
      const vital1Value = parseFloat(`${params.vital1 || 0}`).toFixed(0);
      const vital2Value = parseFloat(`${params.vital2 || 0}`).toFixed(0);
      if (isInchesUnit(unit)) {
        result = feetAndInchesToInches(
          parseFloat(vital1Value),
          parseFloat(vital2Value)
        );
      } else {
        result = feetAndInchesToCm(
          parseFloat(vital1Value),
          parseFloat(vital2Value)
        );
      }
    }
    result = result ? `${result}` : undefined;
    onChange(result);
  };

  const valueFormatter = (
    value: string | number | undefined,
    info: {userTyping: boolean; input: string}
  ): string => {
    const data = `${value}`;
    if (data.includes('.') && !info.userTyping) {
      return `${value}`.replace('\\.0*$', '');
    }
    return `${value}`;
  };

  return (
    <HStack flex={1} alignItems={lastRecordedText ? 'start' : 'center'}>
      {!!label && (
        <VStack width="150">
          <Text>{label}</Text>
          {lastRecordedText && (
            <Text size="xsLight" italic color="gray.500">
              {lastRecordedText}
            </Text>
          )}
        </VStack>
      )}

      {type === VitalType.normal && (
        <VStack flex={1}>
          <HStack flex={1} space={2} alignItems="center" justifyContent="start">
            {inputType !== VitalInputType.choice && inputType !== VitalInputType.date && (
              <InputNumber
              // Keep controlled component value (two way binding) only in case of BMI as it's auto calculated based on height and weight
                {...(vital?.value && loinc == Vital.bmi && { value: vital?.value })}
                min={vital?.min || vital?.min == 0 ? `${vital?.min}` : undefined}
                max={vital?.max || vital?.max == 0 ? `${vital?.max}` : undefined}
                readOnly={disabled}
                precision={decimalPrecision}
                formatter={valueFormatter}
                style={reactStyles.borderRadius6height36}
                defaultValue={componentState.vital1}
                status={showErrors ? 'error' : undefined}
                onChange={(text) => {
                  setComponentState((prev) => ({...prev, vital1: text || ''}));
                }}
              />
            )}
            {inputType === VitalInputType.choice && (
              <ModalActionAntSelect
                allowClear={true}
                showSearch={true}
                labelInValue={true}
                filterOption={(input: string, option: any) => {
                  return (option?.children || '').toLowerCase().includes(input.toLowerCase());
                }}
                key={label}
                label={''}
                isInvalid={showErrors}
                isRequired={false}
                leftMargin={'0'}
                placeholder={'Select'}
                disabled={disabled}
                isDisabled={disabled}
                onChange={(selectedOption: any) => {
                  const value = selectedOption?.key;
                  setComponentState((prev) => ({...prev, vital1: value || ''}));
                }}
                data={possibleValues || []}
                optionProps={{
                  key: 'code',
                  label: 'display',
                  value: 'code',
                }}
                customStyle={{width: 90}}
                extraStyle={{flex: 1}}
              />
            )}
            {inputType === VitalInputType.date && (
              <DatePicker
                style={reactStyles.height40px}
                allowClear={true}
                disabled={disabled}
                status={showErrors ? 'error' : undefined}
                format={format || DISPLAY_DATE_FORMAT}
                placeholder={format || DISPLAY_DATE_FORMAT}
                disabledDate={(current: any) =>
                  current && isFutureDay(current)
                }
                onChange={(date: any) => {
                  const selectedDate = getMomentObj(date).toISOString();
                  setComponentState((prev) => ({...prev, vital1: selectedDate || ''}));
                }}
              />
            )}
            {(displayUnit || unit) && <Text>{displayUnit || unit}</Text>}
          </HStack>
        </VStack>
      )}
      {type === VitalType.bloodPressure && (
        <VStack flex={1}>
          <HStack flex={1} space={2} alignItems="center" justifyContent="start">
            <View>
              <InputNumber
                placeholder="Sys"
                status={showErrors ? 'error' : undefined}
                min={vital?.min || vital?.min == 0 ? `${vital?.min}` : undefined}
                max={vital?.max || vital?.max == 0 ? `${vital?.max}` : undefined}
                readOnly={disabled}
                style={reactStyles.inputNumberStyles}
                precision={decimalPrecision}
                formatter={valueFormatter}
                defaultValue={componentState.vital1}
                onChange={(text) => {
                  setComponentState((prev) => ({...prev, vital1: text || ''}));
                }}
              />
            </View>
            <Text>/</Text>
            <View>
              <InputNumber
                placeholder="Dia"
                status={showErrors ? 'error' : undefined}
                min={vital?.min || vital?.min == 0 ? `${vital?.min}` : undefined}
                max={vital?.max || vital?.max == 0 ? `${vital?.max}` : undefined}
                readOnly={disabled}
                style={reactStyles.inputNumberStyles}
                precision={decimalPrecision}
                formatter={valueFormatter}
                defaultValue={componentState.vital2}
                onChange={(text) => {
                  setComponentState((prev) => ({...prev, vital2: text || ''}));
                }}
              />
            </View>
            {(displayUnit || unit) && <Text>{displayUnit || unit}</Text>}
          </HStack>
        </VStack>
      )}
      {type === VitalType.heightWithFeetAndInches && (
        <VStack flex={1}>
          <HStack flex={1} space={2} alignItems="center" justifyContent="start">
            <View>
              <InputNumber
                status={showErrors ? 'error' : undefined}
                min={vital?.min || vital?.min == 0 ? `${vital?.min}` : undefined}
                max={vital?.max || vital?.max == 0 ? `${vital?.max}` : undefined}
                readOnly={disabled}
                style={reactStyles.inputNumberStyles}
                precision={0}
                formatter={valueFormatter}
                defaultValue={componentState.vital1}
                onChange={(text) => {
                  setComponentState((prev) => ({...prev, vital1: text || ''}));
                }}
              />
            </View>
            <Text>ft</Text>
            <View>
              <InputNumber
                status={showErrors ? 'error' : undefined}
                min={vital?.min || vital?.min == 0 ? `${vital?.min}` : undefined}
                max={vital?.max || vital?.max == 0 ? `${vital?.max}` : undefined}
                readOnly={disabled}
                style={reactStyles.inputNumberStyles}
                precision={0}
                formatter={valueFormatter}
                defaultValue={componentState.vital2}
                onChange={(text) => {
                  setComponentState((prev) => ({...prev, vital2: text || ''}));
                }}
              />
            </View>
            <Text>in</Text>
          </HStack>
        </VStack>
      )}
    </HStack>
  );
};

export default VitalInput;
