import {HStack, Text, VStack} from 'native-base';
import React from 'react';
import {Colors} from '../../../styles';
import {DisplayText} from '../DisplayText/DisplayText';
import {IAppHeaderProps} from './interface';

const AppHeader = (props: IAppHeaderProps) => {
  const titleFlex = () => {
    if (props.leftView && props.rightView) {
      return 0.8;
    } else if (props.leftView || props.rightView) {
      return 0.9;
    } else {
      return 1;
    }
  };

  return (
    <HStack
      alignItems={'center'}
      justifyContent={'space-between'}
      space={2}
      padding={2}
      marginBottom={2}
      borderBottomColor={Colors.Custom.BorderColor}
      borderBottomWidth={1}
      style={{paddingBottom: props.infoViewEle ? 20 : 2}}
    >
      {props.leftView && (
        <VStack flex={0.1} justifyContent={'center'} alignItems={'center'}>
          {props.leftView}
        </VStack>
      )}
      {props.title && (
        <VStack
          flex={titleFlex()}
          alignItems={'flex-start'}
          justifyContent={'center'}
          style={{marginLeft: 2}}
        >
          {props.isTitleI18n ? (
            <DisplayText
              textLocalId={props.title}
              textType={'Heading'}
              extraStyles={{
                color: Colors.primary[500] || '',
                fontSize: 20,
              }}
            />
          ) : (
            <Text fontSize="xl" color={Colors.primary[500] || ''}>
              {props.title}
            </Text>
          )}
        </VStack>
      )}
      {props.infoViewEle && (
        <VStack
          flex={titleFlex()}
          alignItems={'flex-start'}
          justifyContent={'center'}
        >
          {props.infoViewEle}
        </VStack>
      )}

      {props.rightView ? (
        <VStack flex={0.1} mr={2}>
          {props.rightView}
        </VStack>
      ) : (
        <VStack flex={0.1} mr={2}></VStack>
      )}
    </HStack>
  );
};

export default AppHeader;
