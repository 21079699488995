import { Colors } from "../../../styles/Colors";
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
    actionIcons: {
        color: Colors.Custom.SuccessColor,
        marginTop: 0,
        marginRight: 0,
        position: 'absolute',
        alignSelf: 'flex-end',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        bottom: 10,
        left: 14
    },
    preferredIcon: {
        height: 12,
        width: 12,
        marginTop: 0,
        marginRight: 0,
        position: 'absolute',
        alignSelf: 'flex-end',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        bottom: 12,
        left: 16
      },
});
