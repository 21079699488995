import React from "react";

const CreateNoteAction = (props: {isActive?:boolean})=> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{width: 'inherit', height: 'inherit'}}
      fill="none"
      viewBox="0 0 21 20"
    >
      <path
        stroke={ !props.isActive ? "#D0D5DD"  : '#667085' }
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M13.833 18.333H4.25c-.55 0-1.083-.175-1.475-.491-.392-.317-.608-.734-.608-1.175V3.333c0-.441.216-.866.608-1.175a2.397 2.397 0 011.475-.491h12.5c.55 0 1.083.175 1.475.491.392.317.608.734.608 1.175v10m-5 5l5-5m-5 5v-5h5M10.5 5.317v7.175m3.592-3.584H6.917"
      ></path>
    </svg>
  );
}

export default CreateNoteAction;
