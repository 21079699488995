import { useRef, useState } from 'react';
import { Typography, Popover } from 'antd';
import { Pressable } from 'react-native';
import { HStack, View, VStack, Text as NativeText } from 'native-base';
import { Colors } from '../../../styles';
import MenuHorizontalIcon from '../../common/Svg/MenuHorizontalIcon';
import EFaxActionSvg from '../../common/Svg/PersonActionSvgIcons/EFaxActionSvg';
import { useReactToPrint } from 'react-to-print';
import { ComponentToPrint } from '../../common/PrintComponent/ComponentToPrint';
import InsightsReferralPreviewSection from './InsightsReferralPreviewSection';
import { useIntl } from 'react-intl';
import ArrowRightDiagonalSvg2024 from '../../common/Svg/ArrowRightDiagonalSvg2024';
import TrashBinIcon2024 from '../../../assets/Icons/TrashBinIcon2024';
import '../SideCar.css';
import EyeOpenSvg from '../../common/Svg/WidgetsSvg/EyeOpenSvg';
import MessageEditSvg from '../../common/Svg/MessageEditSvg';
const { Text } = Typography;


export interface IContact {
  uuid?: string;
  phoneNumber?: string;
  name?: string;
  id?: number;
  email?: string;
  accountId?: number;
  contactPracticeLocations?: any[];
  contactType?: any;
  contactUUID?: string;
}

interface IInsightsReferralOrderCard {
  details?: any;
  onClick: () => void;
  onEditClick: (val: any) => void;
  contactData?: IContact;
  onDelete: (val: any) => void;
  isReadOnlyView?: boolean;
}

const ReferralOrderCard = (props: IInsightsReferralOrderCard) => {
  const { details, onClick, onEditClick, contactData, onDelete, isReadOnlyView } = props;
  const [visible, setVisible] = useState<boolean>(false);
  const printRef = useRef(null);
  const intl = useIntl();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: intl.formatMessage({ id: 'referralOrder' })
  });

  const ACTION_LIST = [
    ...((details.status !== 'SENT' && !isReadOnlyView) ? [{ key: 'edit', title: 'Edit', icon: <MessageEditSvg height={16} width={16} customStrokeColor={Colors.FoldPixel.GRAY300}/> }] : []),
    ...(details.status === 'SENT' ? [{ key: 'view', title: 'View Details', icon: <EyeOpenSvg width={16} height={16} /> }] : []),
    { key: 'print', title: 'Print', icon: <EFaxActionSvg width={16} height={16} /> },
    ...(!isReadOnlyView ? [{ key: 'delete', title: 'Delete', icon: <TrashBinIcon2024 color={Colors.FoldPixel.STATUS_ERROR} /> }] : []),
  ];

  const performAction = (key: string, item: any) => {
    switch (key) {
      case 'view':
        onClick();
        break;
      case 'edit':
        onEditClick(item);
        break;
      case 'delete':
        onDelete(item?.id)
        break;
      case 'print':
        handlePrint();
        break;
      default:
        break;
    }
    setVisible(false);
  };

  const menuContent = (
    <View width={'200px'}>
      {ACTION_LIST?.map((item, index) => (
        <Pressable
          key={`ActionList_${index}`}
          onPress={() => performAction(item.key, details)}
          style={{
            padding: 0,
            backgroundColor: 'white',
            borderBottomWidth: index < ACTION_LIST.length - 1 ? 1 : 0,
            borderBottomColor: Colors.Custom.Gray200,
          }}
        >
          <HStack alignItems='center'>
            <View justifyContent='center' alignItems='center' height={12} width={12}>
              {item?.icon}
            </View>
            <NativeText color={item.key === 'delete' ? Colors.FoldPixel.STATUS_ERROR : Colors.Custom.Gray500}>
              {item?.title}
            </NativeText>
          </HStack>
        </Pressable>
      ))}
    </View>
  );

  return (
    <>

      <HStack backgroundColor={Colors.Custom.MonochromeWhite} borderRadius={12} borderWidth={0.5} borderColor={Colors.Custom.gray150} justifyContent={'space-between'} alignItems={'center'} padding={2} marginBottom={2}>
        <Pressable
          style={{ width: '80%' }}
          onPress={() => {
            onClick();
          }}
        >
          <VStack gap={2}>
            <HStack justifyContent={'space-between'} alignItems={'flex-start'} space={5}>
              <View width={'40%'}>
                <NativeText fontSize={14} fontWeight={400} color={Colors.FoldPixel.GRAY400} numberOfLines={1} ellipsizeMode="tail">{details?.sender?.name}</NativeText>
                <NativeText fontSize={12} fontWeight={400} color={Colors.FoldPixel.GRAY250}>
                  {details?.sender?.persons?.personAddressDetails?.[0]?.addressDetails?.[0]?.city?.name
                    ? `${details?.sender?.persons?.personAddressDetails?.[0]?.addressDetails?.[0]?.city?.name}`
                    : ''}
                  {details?.sender?.persons?.personAddressDetails?.[0]?.addressDetails?.[0]?.city?.name && details?.sender?.persons?.personAddressDetails?.[0]?.addressDetails?.[0]?.zipcode?.code
                    ? ` • `
                    : ''}
                  {details?.sender?.persons?.personAddressDetails?.[0]?.addressDetails?.[0]?.zipcode?.code
                    ? `${details?.sender?.persons?.personAddressDetails?.[0]?.addressDetails?.[0]?.zipcode?.code}`
                    : ''}
                  {details?.senderEfaxNo && (
                    <>
                      {' • '}
                      <EFaxActionSvg width={12} height={12} />
                      {' '}
                      {details?.senderEfaxNo}
                    </>
                  )}
                </NativeText>
              </View>
              <ArrowRightDiagonalSvg2024 />
              <View width={'40%'}>
                <NativeText fontSize={14} fontWeight={400} color={Colors.FoldPixel.GRAY400} numberOfLines={1} ellipsizeMode="tail">{details?.receiver?.name}</NativeText>
                <HStack alignItems='center'>
                  <NativeText fontSize={12} fontWeight={400} color={Colors.FoldPixel.GRAY250}>
                    {details?.receiver?.personAddress?.[0]?.cities?.name
                      ? `${details?.receiver?.personAddress?.[0]?.cities?.name}`
                      : ''}
                    {details?.receiver?.personAddress?.[0]?.cities?.name && details?.receiver?.personAddress[0]?.zipcodes?.code
                      ? ` • `
                      : ''}
                    {details?.receiver?.personAddress?.[0]?.zipcodes?.code
                      ? `${details?.receiver?.personAddress?.[0]?.zipcodes?.code}`
                      : ''}
                    {details?.receiver?.eFaxNo?.[0]?.value && (
                      <>
                        {' • '}
                        <EFaxActionSvg width={12} height={12} />
                        {' '}
                        {details?.receiver?.eFaxNo?.[0]?.value}
                      </>
                    )}
                  </NativeText>
                </HStack>
              </View>
            </HStack>
            <HStack>
              <NativeText fontWeight={400} fontSize={'12px'} color={Colors.FoldPixel.GRAY250}>
                Created on : {new Date(details?.createdAt).toLocaleString()}
              </NativeText>
            </HStack>
          </VStack>
        </Pressable>
        <Popover
          overlayInnerStyle={{ padding: 0, borderRadius: 12 }}
          overlayStyle={{ padding: 0 }}
          placement='bottomLeft'
          trigger='click'
          visible={visible}
          onVisibleChange={() => setVisible(!visible)}
          content={menuContent}
          style={{ padding: 0 }}
          overlayClassName='referral-order-card'
        >
          <Pressable
            onPress={() => setVisible(!visible)}
            style={{ marginRight: 16, alignItems: 'center', height: 20 }}
          >
            <MenuHorizontalIcon width={24} height={24} />
          </Pressable>
        </Popover>
      </HStack>
      <div style={{ display: 'none' }}>
        <ComponentToPrint footer={'Fold Health Test'} ref={printRef}>
          <InsightsReferralPreviewSection
            contactData={contactData}
            note={details?.summary}
            provider={{ name: details?.name }}
          />
        </ComponentToPrint>
      </div>
    </>
  );
};

export default ReferralOrderCard;
