import React from 'react';
import ReactDOM from 'react-dom';
import {ReactComponent} from '@foldhealth/formio-react';
import {Formio} from '@foldhealth/formiojs';
import CustomWrapper from '../CustomWrapper/CustomWrapper';
import KeyField from '../../EditFormFields/KeyField';
import ConditionalComponents from '../../EditFormFields/ConditionalFields';
import CustomClassComponent from '../CustomWrapper/CustomClassComponent';
import QuillConfig from '../../Builder/QuillConfig';
import {CapabilityResource} from '../CustomWrapper/CustomComponentHelper';
import { IEhrCapability } from '../../../../../../Interfaces/CommonInterfaces';
import { CustomComponentKey, getComponentField, getFieldDisplayName, isRequiredField } from '../CustomComponentUtils';
import AddOrUpdatePastMedicalHistory from './AddOrUpdatePastMedicalHistory/AddOrUpdatePastMedicalHistory';
import ShareWithPatientFields from '../../EditFormFields/ShareWithPatientFields';

export default class PastMedicalHistory extends CustomClassComponent {
  static get builderInfo() {
    return {
      title: 'Medical History',
      icon: 'filetext1',
      group: 'advanced',
      documentation: '',
      schema: PastMedicalHistory.schema(),
    };
  }

  static schema() {
    return ReactComponent.schema({
      type: 'pastMedicalHistory',
      label: 'Medical History',
      key: 'pastMedicalHistory',
    });
  }

  attachReact(element: any) {
    return ReactDOM.render(
      <CustomWrapper
        formOptionData={this.options}
        capabilityList={[CapabilityResource.pastMedicalHistory]}
      >
        <AddOrUpdatePastMedicalHistory
          options={this.options}
          disabled={this.disabled}
          component={this.component}
          validateRef={this.validateRef}
          onChange={this.updateValue}
        />
      </CustomWrapper>,
      element
    );
  }

  static editForm = function () {
    return {
      key: 'display',
      components: [
        {
          type: 'oldtextfield',
          key: 'label',
          label: 'Label',
          input: true,
          validate: {
            required: true,
          },
        },
        ...ShareWithPatientFields,
        {
          type: 'checkBoxes',
          key: 'enabledFields',
          label: 'Select Problem Field',
          input: true,
          validate: {
            required: true,
          },
          dataSrc: 'asyncOptions',
          data: {
            async asyncOptions(component: any, options: any) {
              const capabilities: IEhrCapability[] = options.ehrCapabilities || [];
              const capability = capabilities.find((capability) => capability.resourceName === CapabilityResource.pastMedicalHistory);
              const keyAllowedOperations = capability?.abilities?.keyAllowedOperations || {};
              const commField = getComponentField(CustomComponentKey.PAST_MEDICAL_HISTORY, keyAllowedOperations);
              const finalFieldList = commField.map((field) => {
                return {
                  label: getFieldDisplayName(CustomComponentKey.PAST_MEDICAL_HISTORY, field, keyAllowedOperations),
                  value: field,
                  disabled: isRequiredField(CustomComponentKey.PAST_MEDICAL_HISTORY, field, keyAllowedOperations),
                };
              });
              return Promise.resolve().then(() => finalFieldList);
            },
          },
        },
        ...ConditionalComponents,
        ...KeyField,
      ],
    };
  };
}

Formio.Components.addComponent('pastMedicalHistory', PastMedicalHistory);
