import {Center, Image, Text, View} from 'native-base';
import React from 'react';
import {Platform} from 'react-native';
import {Colors} from '../../../styles';
import {DisplayText} from '../DisplayText/DisplayText';
import {svgStyles} from './SvgStyles';

const EmptyStateSvg = (props: {titleId?: string; fillColor?: string}) => {
  const fillColor = props.fillColor || Colors.primary['300'];

  return (
    <View style={svgStyles.container}>
      {Platform.OS === 'web' ? (
        <svg
          width="76"
          height="74"
          viewBox="0 0 152 120"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="76" cy="52" r="52" fill="#EAECF0" />
          <g filter="url(#filter0_dd_1182_1994)">
            <path
              d="M77.6 16C66.8273 16 57.2978 21.3233 51.4987 29.4829C49.605 29.0363 47.6301 28.8 45.6 28.8C31.4615 28.8 20 40.2615 20 54.4C20 68.5385 31.4615 80 45.6 80L109.6 80C121.971 80 132 69.9712 132 57.6C132 45.2288 121.971 35.2 109.6 35.2C108.721 35.2 107.854 35.2506 107.002 35.349C102.098 23.9677 90.7797 16 77.6 16Z"
              fill="#F9FAFB"
            />
            <ellipse
              cx="45.6"
              cy="54.4"
              rx="25.6"
              ry="25.6"
              fill="url(#paint0_linear_1182_1994)"
            />
            <circle
              cx="77.6"
              cy="48"
              r="32"
              fill="url(#paint1_linear_1182_1994)"
            />
            <ellipse
              cx="109.6"
              cy="57.5999"
              rx="22.4"
              ry="22.4"
              fill="url(#paint2_linear_1182_1994)"
            />
          </g>
          <circle cx="21" cy="19" r="5" fill={fillColor} />
          <circle cx="18" cy="109" r="7" fill={fillColor} />
          <circle cx="145" cy="35" r="7" fill={fillColor} />
          <circle cx="134" cy="8" r="4" fill={fillColor} />
          <g filter="url(#filter1_b_1182_1994)">
            <path
              d="M52 86C52 72.7452 62.7452 62 76 62V62C89.2548 62 100 72.7452 100 86V86C100 99.2548 89.2548 110 76 110V110C62.7452 110 52 99.2548 52 86V86Z"
              fill="#9dabc3"
              fillOpacity="0.4"
            />
            <path
              d="M85 95L80.65 90.65M83 85C83 89.4183 79.4183 93 75 93C70.5817 93 67 89.4183 67 85C67 80.5817 70.5817 77 75 77C79.4183 77 83 80.5817 83 85Z"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <filter
              id="filter0_dd_1182_1994"
              x="0"
              y="16"
              width="152"
              height="104"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feMorphology
                radius="4"
                operator="erode"
                in="SourceAlpha"
                result="effect1_dropShadow_1182_1994"
              />
              <feOffset dy="8" />
              <feGaussianBlur stdDeviation="4" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_1182_1994"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feMorphology
                radius="4"
                operator="erode"
                in="SourceAlpha"
                result="effect2_dropShadow_1182_1994"
              />
              <feOffset dy="20" />
              <feGaussianBlur stdDeviation="12" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0"
              />
              <feBlend
                mode="normal"
                in2="effect1_dropShadow_1182_1994"
                result="effect2_dropShadow_1182_1994"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect2_dropShadow_1182_1994"
                result="shape"
              />
            </filter>
            <filter
              id="filter1_b_1182_1994"
              x="44"
              y="54"
              width="64"
              height="64"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImage" stdDeviation="4" />
              <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_1182_1994"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_1182_1994"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_1182_1994"
              x1="25.9429"
              y1="37.4858"
              x2="71.2"
              y2="80"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor={fillColor} />
              <stop offset="0.350715" stopColor="white" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_1182_1994"
              x1="53.0286"
              y1="26.8571"
              x2="109.6"
              y2="80"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor={fillColor} />
              <stop offset="0.350715" stopColor="white" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_1182_1994"
              x1="92.4"
              y1="42.8"
              x2="132"
              y2="79.9999"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor={fillColor} />
              <stop offset="0.350715" stopColor="white" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
      ) : (
        <Center>
          <Image
            w="100%"
            // style={{aspectRatio: 16 / 9}}
            resizeMode="contain"
            source={require('../../../assets/images/PNGImages/empty-state.png')}
            alt="image"
          />
        </Center>
      )}

      {props.titleId && (
        <Text style={svgStyles.svgText}>
          <DisplayText textLocalId={props.titleId} textType="Text" />
        </Text>
      )}
    </View>
  );
};

export default EmptyStateSvg;
