import React from 'react';
import {Center, HStack, View, VStack} from 'native-base';
import {INoDataFoundProps} from '.';
import {styles} from './NoDataFoundStyles';
import {DisplayText} from '../DisplayText/DisplayText';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {Colors} from '../../../styles/Colors';
import { testID, TestIdentifiers } from '../../../testUtils';
const NoDataFound = (props: INoDataFoundProps) => {
  const {isShowIcon, iconSize, size, iconColor} = props;
  const textStyle = props.textStyle || {};
  const displayTextStyle = {
    ...styles.container,
    ...textStyle,
    ...(!props.isOnMessage ? {color: Colors.Custom.Gray300} : {} ),
  };
  return (
    <View>
      <Center
        style={
          props.isOnMessage
            ? {}
            : {
                alignItems: 'center',
                justifyContent: 'center',
                alignContent: 'center',
              }
        }
      >
        {isShowIcon ? (
          <VStack padding={5}>
            <HStack justifyContent={'center'}>
              <AntIcon
                size={iconSize || 16}
                name="folder1"
                color={iconColor ? iconColor : Colors.Custom.IconColor}
                {...testID('image')}
              />
            </HStack>
            <HStack>
              <DisplayText
                testID={TestIdentifiers.noDataViewMessage}
                size={size ? size : 'mdNormal'}
                textLocalId={props.displayString || 'noDataFound'}
                extraStyles={displayTextStyle}
              />
            </HStack>
          </VStack>
        ) : (
          <DisplayText
            size={size ? size : 'mdNormal'}
            testID={TestIdentifiers.noDataViewMessage}
            textLocalId={props.displayString || 'noDataFound'}
            extraStyles={displayTextStyle}
          />
        )}
      </Center>
    </View>
  );
};

export default NoDataFound;
