import { IVitalData } from "../../../../../../../Interfaces";
import { BLOOD_PRESSURE_LOINC, HEIGHT_LOINC } from "../../../../../../../constants";
import { Colors } from "../../../../../../../styles";
import { cmToFeetAndInches, convertVitalValues, inchesToFeetAndInches, isInchesUnit } from "../../../../../../../utils/vitalUnitConversions";
import { GOAL_OPERATOR_LIST } from "./GoalConstants";
import { GoalViewType, IGoal, IGoalTarget } from "./interface";

export const getDefaultGoalValue = (
  value?: string | number,
  entityCode?: string,
  config?: IVitalData
) => {
  if (entityCode === HEIGHT_LOINC && value) {
    const floatValue = parseFloat(`${value}`);
    if (isInchesUnit(config?.unit)) {
      const {feet, inches} = inchesToFeetAndInches(floatValue);
      return {
        vital1: `${feet}`,
        vital2: `${inches}`,
      }
    } else {
      const {feet, inches} = cmToFeetAndInches(floatValue);
      return {
        vital1: `${feet}`,
        vital2: `${inches}`,
      }
    }
  } else if (entityCode === BLOOD_PRESSURE_LOINC && value) {
    const bpData = `${value}`.split('/');
    return {
      vital1: bpData?.[0],
      vital2: bpData?.[1],
    }
  } else if (value && config?.displayUnit && config?.unit && config?.displayUnit !== config?.unit) {
    const floatValue = parseFloat(`${value}`);
    const convertedValue = convertVitalValues(floatValue, config.unit, config.displayUnit, config.allowedDecimalPlaces || 0);
    return {
      vital1: convertedValue || convertedValue === 0 ? `${convertedValue}` : undefined,
    }
  } else {
    return {
      vital1: value,
    }
  }
}

export const getGoalDisplayValue = (
  value?: string | number,
  entityCode?: string,
  config?: IVitalData
) => {
  const unit = config?.displayUnit || config?.unit;
  if (entityCode === HEIGHT_LOINC && value) {
    const floatValue = parseFloat(`${value}`);
    if (isInchesUnit(config?.unit)) {
      const {feet, inches} = inchesToFeetAndInches(floatValue);
      return `${feet} ft ${inches} in`;
    } else {
      const {feet, inches} = cmToFeetAndInches(floatValue);
      return `${feet} ft ${inches} in`;
    }
  } else if (entityCode === BLOOD_PRESSURE_LOINC && value) {
    if (value && unit) {
      return `${value} ${unit}`;
    }
    return value || value === 0 ? `${value}` : undefined;
  } else if (value && config?.displayUnit && config?.unit && config?.displayUnit !== config?.unit) {
    const floatValue = parseFloat(`${value}`);
    const convertedValue = convertVitalValues(floatValue, config.unit, config.displayUnit, config.allowedDecimalPlaces || 0);
    const valueText = convertedValue || convertedValue === 0 ? `${convertedValue}` : undefined;
    if (valueText && config?.displayUnit) {
      return `${valueText} ${config?.displayUnit}`;
    }
    return valueText;
  } else {
    if (value && unit) {
      return `${value} ${unit}`;
    }
    return value || value === 0 ? `${value}` : undefined;
  }
}

export const removeFromEditView = (goals: IGoal[], goal: IGoal) => {
  return goals
    .filter((filterGoal) => {
      if (
        goal.isFirstEdit &&
        getGoalUniqueId(goal) === getGoalUniqueId(filterGoal)
      ) {
        return false;
      }
      return true;
    })
    .map((goalObj) => {
      if (
        (goalObj.id && goalObj.id === goal.id) ||
        getGoalUniqueId(goalObj) === getGoalUniqueId(goal)
      ) {
        goal.viewType = GoalViewType.list;
        return goal;
      }
      return goalObj;
    });
};

export const isGoalsEditing = (goals: IGoal[]) => {
  return goals.some((goal) => goal.viewType === GoalViewType.edit);
};

export const getGoalUniqueId = (goal: IGoal) =>
  goal.temporaryTemplateId || goal.templateId;

export const updateViewTypeByUniqueId = (
  goals: IGoal[],
  goal: IGoal,
  viewType: GoalViewType
) => {
  return goals.map((goalObj) => {
    if (getGoalUniqueId(goalObj) === getGoalUniqueId(goal)) {
      goal.viewType = viewType;
      return goal;
    }
    return goalObj;
  });
};

export const getGoalByUniqueId = (
  goals: IGoal[],
  goal: IGoal,
) => {
  return goals.find(
    (item) => getGoalUniqueId(item) === getGoalUniqueId(goal)
  );
};

export const getIndexByUniqueId = (goals: IGoal[], goal: IGoal) => {
  return goals.findIndex(
    (item) => getGoalUniqueId(item) === getGoalUniqueId(goal)
  );
};


export const getGoalTargetText = (goalTarget: IGoalTarget, params: {
  GOAL_ENTITY_TYPE_IDS: {[index: string]: string},
  vitals: IVitalData[],
}) => {
  const measure = goalTarget.measure;
  const operatorValue = GOAL_OPERATOR_LIST.find(operator => operator.code === measure?.operator)?.value || '';
  const prefix = '';
  const isForm = goalTarget.entityTypeId === params.GOAL_ENTITY_TYPE_IDS.FORM;

  let suffix = '';
  if (measure?.targetValue?.value) {
    suffix = getDisplayValue(goalTarget, {
      selectedValue: measure?.targetValue?.value,
      ...params
    });
  } else if (measure?.targetValue?.low && measure?.targetValue?.high) {
    suffix = getDisplayValue(goalTarget, {selectedValue: measure?.targetValue?.low, ...params}) + ' to ' + getDisplayValue(goalTarget, {selectedValue: measure?.targetValue?.high, ...params})
  }
  return `${prefix}${goalTarget.title || ''}${isForm ? ' score' : ''} ${operatorValue} ${suffix}`.trim();
}

const getDisplayValue = (goalTarget: IGoalTarget, params: {
  GOAL_ENTITY_TYPE_IDS: {[index: string]: string},
  vitals: IVitalData[],
  selectedValue?: string | number
}) => {
  if (goalTarget.entityTypeId === params.GOAL_ENTITY_TYPE_IDS.VITAL) {
    const vitals = params.vitals;
    const vitalConfig = vitals.find((item) => {
      return item.loinc === goalTarget.entityCode;
    });
    return getGoalDisplayValue(params.selectedValue, goalTarget.entityCode, vitalConfig) || '';
  } else {
    return getGoalDisplayValue(params.selectedValue, goalTarget.entityCode, { allowedDecimalPlaces: 4, unit: goalTarget.measure?.unit, loinc: '' }) || '';
  }
}

export const updateGoalsWithContactIds = (goals: IGoal[], contactId: string) => {
  goals.forEach((goal) => {
    goal.contactRules?.forEach((contactRule) => {
      contactRule.contactId = contactId;
      if (contactRule.rule) {
        contactRule.rule.contactUuid = contactId;
      }
    });
  })
}

export const getGoalProgressBarColor = (goalProgress: number) => {
  if (goalProgress < 50) {
    return Colors.FoldPixel.STATUS_ERROR;
  } else if (goalProgress < 70) {
    return Colors.FoldPixel.STATUS_IN_PROGRESS;
  } else {
    return Colors.Custom.SuccessColor;
  }
}
