import React from 'react';
import { Badge, Skeleton, Spacer, Icon, Text, Tooltip } from 'native-base';
import { Checkbox } from 'antd';
import { StyleSheet, View, Pressable } from 'react-native';
import { DisplayUserList } from '../../../common/DisplayUserList/DisplayUserList';
import AddTaskIcon from '../../../../assets/Icons/AddTaskIcon';
import OpenedCollapseSvg from '../../../common/Svg/SideCarSvg/OpenedCollapseSvg';
import ClosedCollapseSvg from '../../../common/Svg/SideCarSvg/ClosedCollpaseSvg';
import { Colors } from '../../../../styles';
import { MultiSelectState, MultiSelectAction } from '../../TaskInterfaces';
import { TASK_EVENTS } from '../../../common/CareDashboard/CareDashboardConstants';
import { EventBus } from '../../../../utils/EventBus';
import { getTodayDueTaskTextLabel } from '../../../TaskCard/TaskHelper';
import Stack from '../../../common/LayoutComponents/Stack';

interface TaskCategoryRowProps {
  record: any;
  primaryId: string;
  countData: any;
  todaysCountData: any;
  multiState: MultiSelectState;
  userIds: string[];
  defaultUserId?: string;
  userPool: any;
  isExpanded: boolean;
  isSidecarContext: boolean;
  isTaskPoolBoard: boolean;
  metaData: any;
  accountUsers: any[];
  taskCountLoading: boolean;
  loadingFilterCodes: string[];
  onUserListClick: (userIds: string[], userPool?: any, defaultUserId?: string) => void;
  onAddTaskClick: (primaryId: string, record: any) => void;
  isRenderCompactView: boolean;
}

const TaskCategoryRow: React.FC<TaskCategoryRowProps> = ({
  record,
  primaryId,
  countData,
  todaysCountData,
  multiState,
  userIds,
  defaultUserId,
  userPool,
  isExpanded,
  isTaskPoolBoard,
  metaData,
  accountUsers,
  taskCountLoading,
  loadingFilterCodes,
  onUserListClick,
  onAddTaskClick,
  isRenderCompactView
}) => {
  return (
    <Stack direction='row' space={8} key={primaryId + '-name'} style={styles.categoryContainer}>
      {isRenderCompactView && metaData.bulkTaskEnabled && (
        <div
          onClick={(event) => {
            event.stopPropagation();
          }}>
          <View style={styles.marginLeft}>
            <Checkbox
              style={reactStyles.checkbox}
              indeterminate={multiState === MultiSelectState.some}
              checked={multiState === MultiSelectState.all}
              onChange={async (event) => {
                const eventBus = EventBus.getEventBusInstance();
                eventBus.broadcastEvent(TASK_EVENTS.ON_MULTI_TASK_STATE_CHANGE, {
                  categoryCode: primaryId,
                  bulkAction: event.target.checked ? MultiSelectAction.selectAllTasks : MultiSelectAction.unSelectAllTask
                });
                event.stopPropagation();
              }}
            />
          </View>
        </div>
      )}
      <View key={record.name + '-name'}>
        <Text style={styles.categoryName}>{record.name} </Text>
      </View>
      {isExpanded ? <OpenedCollapseSvg /> : <ClosedCollapseSvg />}
      {taskCountLoading && loadingFilterCodes.includes(record.key) ? (
        <Stack
          direction={'row'}
          style={{
            marginBottom: 2,
            justifyContent: 'center',
          }}
        >
          <Skeleton.Text
            alignContent={'center'}
            width={10}
            justifyContent={'center'}
            alignItems={'center'}
            lines={0.5}
            marginTop={1}
          ></Skeleton.Text>
        </Stack>
      ) : (
        <Badge
          key={record.name + '-badge'}
          fontWeight={500}
          backgroundColor={
            isRenderCompactView ? Colors.FoldPixel.GRAY50 : '#F2F4F7'
          }
          _text={{
            color: Colors.FoldPixel.GRAY300,
            fontSize: 14,
          }}
          alignSelf="center"
          rounded={isRenderCompactView ? '' : "full"}
          borderRadius={isRenderCompactView ? 4 : undefined}
          justifyContent={'center'}
          style={styles.badge}
        >
          <Text color={Colors.FoldPixel.GRAY300} fontSize={'xs'}>
            {getTodayDueTaskTextLabel(todaysCountData?.count, countData?.count, record.code, isTaskPoolBoard)}
          </Text>
        </Badge>
      )}
      <Spacer />
      {isTaskPoolBoard && (
        <>
          <Tooltip label={'Show all users'} placement={'top'} key={record.name + '-users'}>
            <View key={record.name + '-users'}>
              <DisplayUserList
                userIds={userIds}
                accountUsers={accountUsers}
                showAsAvatarGroup={true}
                defaultUserId={defaultUserId}
                maxAvatarGroupUsers={5}
                onClick={() => onUserListClick(userIds, userPool, defaultUserId)}
              />
            </View>
            </Tooltip>
          <View style={styles.divider} />
          <Tooltip label={'Add Task'} placement={'top'} key={record.name + '-add-task'}>
            <Pressable
              key={record.value}
              onPress={(event) => {
                event.stopPropagation();
                onAddTaskClick(primaryId, record);
              }}
              style={styles.addTaskButton}
            >
              <Icon color={Colors.FoldPixel.GRAY200} as={<AddTaskIcon />} />
            </Pressable>
          </Tooltip>
        </>
      )}
    </Stack>
  );
};

const reactStyles: Record<string, React.CSSProperties> = {
  checkbox: {
    marginTop: 1,
    color: Colors.Custom.SuccessColor
  },
}

const styles = StyleSheet.create({
  categoryContainer: {
    alignItems: "center",
  },
  categoryName: {
    color: Colors?.FoldPixel?.GRAY300,
    fontWeight: '600',
    fontSize: 14,
    lineHeight: 16.8,
  },
  badge: {
    paddingHorizontal: 6,
    paddingVertical: 2.5,
  },
  divider: {
    width: 1,
    height: 20,
    backgroundColor: Colors.Custom.Gray200,
    marginHorizontal: 8,
  },
  addTaskButton: {
    height: 36,
    marginLeft: 5,
    marginRight: 6,
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkbox: {
    marginTop: 1,
    color: Colors.Custom.SuccessColor,
  },
  marginLeft: {
    marginLeft: -4
  }
});

export default React.memo(TaskCategoryRow);
