import React, { useMemo } from 'react';
import { Path, Svg } from 'react-native-svg';

const ArrowRightDiagonalSvg2024: React.FC<{ color?: string }> = ({ color }) => {
    const stroke = useMemo(() => color || '#6F7A90', [color]);

    return (
        <Svg width="16" height="17" viewBox="0 0 16 17" fill="none">
            <Path 
                d="M2.66675 8.5H13.3334M13.3334 8.5L9.33341 4.5M13.3334 8.5L9.33341 12.5" 
                stroke={stroke}
                strokeLinecap="round"
                strokeLinejoin="round" 
            />
        </Svg>
    );
};

export default ArrowRightDiagonalSvg2024;