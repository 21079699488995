import {DATE_FORMATS} from "../../../../constants";
import {ModalActionDatePicker} from "../../../common/ModalActionCommonComponent/ModalActionDatePicker"
import {IDatePickerInput} from "./interface";

export const DatePickerInput = (props: IDatePickerInput) => {
  return (
    <ModalActionDatePicker
      className="date-picker-background-color"
      changeBackground={true}
      value={props?.value}
      format={DATE_FORMATS.CONVERSATION_DATE_PICKER}
      placeholder={DATE_FORMATS.CONVERSATION_DATE_PICKER}
      onChange={props?.onChange}
      customStyle={{
        borderRadius: 6,
        marginBottom: 10,
        heigh: 38,
      }}
      extraStyle={{
        flex: 1.0,
      }}
    />
  )
}