import React from 'react';
import {
  Circle,
  Svg,
  Path,
  Defs,
  LinearGradient,
  Stop,
  RadialGradient,
} from 'react-native-svg';

function NoGraphDataIcon() {
  return (
    <Svg width="114" height="114" fill="none" viewBox="0 0 114 114">
      <Circle
        cx="57"
        cy="56.999"
        r="56"
        stroke="url(#paint0_radial_1854_23053)"
        strokeDasharray="1.63 1.63"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.56"
      ></Circle>
      <Circle
        cx="57"
        cy="57"
        r="47.787"
        stroke="url(#paint1_radial_1854_23053)"
        strokeDasharray="1.63 1.63"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.56"
      ></Circle>
      <Path
        fill="url(#paint2_linear_1854_23053)"
        d="M17.637 57.114c0-21.65 17.55-39.2 39.2-39.2 21.65 0 39.2 17.55 39.2 39.2 0 21.65-17.55 39.2-39.2 39.2-21.65 0-39.2-17.55-39.2-39.2z"
      ></Path>
      <Path
        fill="#A7AFBE"
        d="M39.111 73.994a.894.894 0 100 1.789v-1.79zm35.778 0H39.111v1.789H74.89v-1.79zM65.05 59.683h5.367v-1.79H65.05v1.79zm7.156 1.788v13.417h1.789V61.471h-1.79zM63.26 74.888V61.471h-1.789v13.417h1.79zm7.156-15.205c.988 0 1.789.8 1.789 1.788h1.789a3.578 3.578 0 00-3.578-3.577v1.789zm-5.367-1.79a3.578 3.578 0 00-3.578 3.578h1.79c0-.987.8-1.788 1.788-1.788v-1.79zm-3.578-13.416v30.411h1.79V44.477h-1.79zm-8.944 30.411V44.477h-1.789v30.411h1.789zM57 40.005c1.29 0 2.168.002 2.825.09.632.085.924.235 1.123.434l1.266-1.265c-.587-.587-1.32-.83-2.15-.942-.804-.108-1.824-.106-3.064-.106v1.789zm6.261 4.472c0-1.24.002-2.26-.106-3.063-.111-.83-.355-1.563-.941-2.15l-1.265 1.265c.199.199.348.491.433 1.123.089.657.09 1.535.09 2.825h1.79zm-6.26-6.261c-1.24 0-2.26-.002-3.064.106-.83.112-1.563.355-2.15.942l1.265 1.265c.2-.2.492-.349 1.123-.434.657-.088 1.535-.09 2.825-.09v-1.79zm-4.473 6.261c0-1.29.002-2.168.09-2.825.085-.632.235-.924.434-1.123l-1.265-1.265c-.587.587-.83 1.32-.942 2.15-.108.804-.106 1.823-.106 3.063h1.789zm-8.944 6.261h5.366V48.95h-5.366v1.79zm7.155 1.789v22.361h1.789V52.527h-1.789zm-8.944 22.361V52.527h-1.79v22.361h1.79zm7.155-24.15c.988 0 1.79.801 1.79 1.789h1.788a3.578 3.578 0 00-3.578-3.578v1.79zm-5.366-1.789a3.578 3.578 0 00-3.578 3.578h1.789c0-.988.8-1.789 1.789-1.789V48.95z"
      ></Path>
      <Defs>
        <RadialGradient
          id="paint0_radial_1854_23053"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(-90.639 69.204 12.976) scale(87.8833)"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#A1AABA"></Stop>
          <Stop offset="1" stopColor="#E4E7EC"></Stop>
        </RadialGradient>
        <RadialGradient
          id="paint1_radial_1854_23053"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(-90.639 67.368 11.12) scale(74.9937)"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#A1AABA"></Stop>
          <Stop offset="1" stopColor="#E4E7EC"></Stop>
        </RadialGradient>
        <LinearGradient
          id="paint2_linear_1854_23053"
          x1="29"
          x2="82.76"
          y1="26.76"
          y2="94.8"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#F9FAFC"></Stop>
          <Stop offset="1" stopColor="#F1F2F3"></Stop>
        </LinearGradient>
      </Defs>
    </Svg>
  );
}

export default NoGraphDataIcon;
