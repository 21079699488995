import React from "react";
import { ICommonSvgProps } from "../interfaces";
import Svg, { ClipPath, Defs, G, Path } from "react-native-svg";

const ChatPopoverActionSvg = (props: ICommonSvgProps)=> {
  const strokeColor = props.customStrokeColor ? props.customStrokeColor : '#6F7A90';
  return (
    <Svg
      width={props?.width ? props?.width : 'inherit'}
      height={props?.height ? props?.height : 'inherit'}
      fill="none"
      viewBox="0 0 16 16"
    >
      <G clipPath="url(#clip0_3512_144482)">
        <Path
          stroke={strokeColor}
          d="M8 14.67a6.667 6.667 0 10-5.971-3.7c.118.239.158.51.089.766L1.72 13.22a.867.867 0 001.061 1.062l1.484-.398a1.09 1.09 0 01.766.09A6.638 6.638 0 008 14.669z"
        ></Path>
      </G>
      <Defs>
        <ClipPath id="clip0_3512_144482">
          <Path fill="#fff" d="M0 0H16V16H0z"></Path>
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default ChatPopoverActionSvg;
