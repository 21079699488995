import React from 'react';
import {Image, Text, View} from 'native-base';
import ReactPlayer from 'react-player';
import {IMediaPreviewProps} from '../interfaces';
import {getMediaTypeFromMime, MEDIA_TYPES} from '../MediaLibraryUtils';
import {styles} from './MediaPreviewStyle';
import {isWeb} from '../../../../../utils/platformCheckUtils';
import {getShortenedString} from '../MediaLibraryTable/Helper';
import {Colors} from '../../../../../styles';

export const getElementFromMedia = (media: IMediaPreviewProps): JSX.Element => {
  let mediaElement = <></>;
  const {audioProps, videoProps, imageProps} = media;
  switch (media.mediaType) {
    case MEDIA_TYPES.VIDEO:
      {
        if (media.mediaUrl) {
          if (isWeb()) {
            mediaElement = (
              <ReactPlayer
                width={'100%'}
                height={'100%'}
                controls={true}
                url={media.mediaUrl}
                {...videoProps}
              />
            );
          }
        }
      }
      break;
    case MEDIA_TYPES.AUDIO:
      {
        if (media.mediaUrl) {
          if (isWeb()) {
            mediaElement = (
              <ReactPlayer
                width={300}
                height={50}
                controls={true}
                url={media.mediaUrl}
                {...audioProps}
              />
            );
          }
        }
      }
      break;
    case MEDIA_TYPES.IMAGE:
      {
        if (media.mediaUrl) {
          mediaElement = (
            <View
              style={[
                styles.imageContainer,
                {
                  height: (media.mediaData?.imageHeight as number) || 150,
                  width: (media.mediaData?.imageWidth as number) || 250,
                },
              ]}
            >
              <Image
                style={styles.imageStyle}
                alt={media.mediaData?.name}
                src={media.mediaUrl}
                {...imageProps}
              />
            </View>
          );
        }
      }
      break;

    case MEDIA_TYPES.PDF:
      {
        if (media.mediaUrl) {
          if (isWeb()) {
            mediaElement = (
              <iframe height={'800px'} src={media.mediaUrl}></iframe>
            );
          }
        }
      }
      break;
    case MEDIA_TYPES.DOCX:
      {
        if (media.mediaUrl) {
          if (isWeb()) {
            mediaElement = (
              <iframe height={'100vh'} srcDoc={media.mediaHtmlContent}></iframe>
            );
          }
        }
      }
      break;
  }

  return mediaElement;
};

const MediaPreview = (props: IMediaPreviewProps) => {
  const {mediaType, mediaUrl} = props;
  const type = getMediaTypeFromMime(mediaType || '');

  return (
    <View style={styles.previewContainer}>
      {getElementFromMedia({
        ...props,
        mediaType: type,
        mediaUrl: mediaUrl,
        mediaData: props.mediaData,
        mediaHtmlContent: props.mediaHtmlContent,
      })}
      <View>
        {props.mediaData && (
          <>
            <Text
              style={{
                color: '#000',
                fontSize: 16,
                fontWeight: '500',
                lineHeight: 24,
                marginTop: 20,
              }}
            >
              {getShortenedString({
                str: props.mediaData?.name || '',
                length: 10,
              })}
            </Text>
            <Text
              style={{
                color: Colors.Custom.Gray500,
                fontSize: 14,
                fontWeight: '500',
                lineHeight: 21,
              }}
            >
              {(props.mediaData.size / 1000).toFixed(2)} MB
            </Text>
          </>
        )}
      </View>
    </View>
  );
};

export default MediaPreview;
