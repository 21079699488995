export const COMMON_VIEW_CONST = {
  INTERNAL_USERS: 'INTERNAL_USERS',
  PATIENTS: 'PATIENTS',
  CHANNEL_NAME: 'CHANNEL_NAME',
  SEND_MAIL: 'SEND_MAIL',
  PATIENTS_SEGMENT: 'PATIENTS_SEGMENT',
  FAMILY_MEMBER: 'FAMILY_MEMBER',
};

export const form_Input_Field: any = {
  PRIVATE: [
    COMMON_VIEW_CONST.PATIENTS,
    COMMON_VIEW_CONST.CHANNEL_NAME,
    COMMON_VIEW_CONST.INTERNAL_USERS,
    COMMON_VIEW_CONST.FAMILY_MEMBER,
    COMMON_VIEW_CONST.SEND_MAIL,
  ],
  PATIENT_BROADCAST: [
    COMMON_VIEW_CONST.CHANNEL_NAME,
    COMMON_VIEW_CONST.PATIENTS_SEGMENT,
    COMMON_VIEW_CONST.SEND_MAIL,
  ],
  USER_BROADCAST: [
    COMMON_VIEW_CONST.CHANNEL_NAME,
    COMMON_VIEW_CONST.INTERNAL_USERS,
    COMMON_VIEW_CONST.SEND_MAIL,
  ],
  INTERNAL: [
    COMMON_VIEW_CONST.CHANNEL_NAME,
    COMMON_VIEW_CONST.INTERNAL_USERS,
    COMMON_VIEW_CONST.SEND_MAIL,
  ],
};
