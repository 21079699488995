import {resetApolloContext} from '@apollo/client';
import {resolveReadonlyArrayThunk} from 'graphql';
import { updateValueToPrecision } from '../../RightSideContainer/Forms/FHFormio/CustomComponents/Vitals/AddOrUpdateVitals/AddOrUpdateVitalsHelper';
import {
  OrganizationApiResponse,
  OrganizationData,
} from './TimelineView/interfaces';

const sortByTime = (list: any) => {
  const sortedList = list.sort((data1: any, data2: any) => {
    const date1: any = new Date(data1?.time_stamp);
    const date2: any = new Date(data2?.time_stamp);
    return date2 - date1;
  });
  return sortedList;
};

export const getSortedFhrData = (fhirData: any) => {
  const keyMap: any = {};
  let sortedList: any = [];
  fhirData.forEach((data: any) => {
    data.display_type = data.display_type.trim();
    data.group_id = data?.group_id.trim();
    data.group_code = data?.group_code;
  });

  //Vital processing
  fhirData.forEach((data: any) => {
    const dataObj = typeof data.data === 'string' ? JSON.parse(data.data) : data.data;
    delete dataObj.status;
    if (dataObj.valueQuantity && dataObj.valueQuantity.value) {
      dataObj.valueQuantity.value = updateValueToPrecision(dataObj.valueQuantity.value, 2);
    }
    data.data = JSON.stringify(dataObj);
  });

  fhirData.forEach((data: any) => {
    const displayType = data?.display_type;
    const groupId = data?.group_id;
    if (!displayType || !groupId) {
      return;
    }
    if (!keyMap[displayType]) {
      keyMap[displayType] = {};
    }
    if (!keyMap[displayType][groupId]) {
      keyMap[displayType][groupId] = [];
    }
    keyMap[displayType][groupId].push(data);
  });

  const encounterKey = 'Encounter';
  const appointmentKey = 'Appointment';
  const encounterMap = keyMap[encounterKey];
  //const appointmentMap = keyMap['Appointment'];
  const list = sortByTime(fhirData);
  sortedList = list.filter((data: any) => {
    return (
      !encounterMap?.[data.group_id] ||
      data.display_type == 'Encounter' ||
      data.display_type == appointmentKey
    );
  });

  const keyList = Object.keys(keyMap).filter((key) => {
    return key != encounterKey;
  });
  if (!encounterMap) {
    return list;
  }

  Object.keys(encounterMap).forEach((groupId) => {
    let encounterSubList: any = [];
    keyList.forEach((otherKey) => {
      if (keyMap[otherKey][groupId]) {
        encounterSubList = encounterSubList.concat(keyMap[otherKey][groupId]);
        delete keyMap[otherKey][groupId];
      }
    });
    encounterSubList = sortByTime(encounterSubList);
    sortedList.some((data: any, index: number) => {
      const displayType = data.display_type;
      if (displayType == 'Encounter' && data.group_id === groupId) {
        let count = 0;
        encounterSubList.forEach((subData: any) => {
          sortedList.splice(index + 1 + count, 0, subData);
          count = count + 1;
        });
        return true;
      }
    });
  });
  return sortedList;
};

const keySequence = [
  'Vital',
  'DiagnosticReport',
  'Lab Result',
  'Problem',
  'Observation',
  'Procedure',
  'MedicationRequest',
];
const keySequenceNotToDisplay = ['Lab Result', 'Survey'];

export const getGroupByEncounter = (sortedList: Array<any>) => {
  const groupByEncounterList: Array<any> = [];
  let currentList: any = undefined;
  let currentGroupId: any = undefined;
  sortedList.forEach((record) => {
    if (record.group_id != currentGroupId) {
      currentList = {mainRecord: undefined, subListMap: {}};
      groupByEncounterList.push(currentList);
      if (record.display_type == 'Encounter') {
        currentList.mainRecord = record;
        currentGroupId = record.group_id;
      } else {
        currentList.mainRecord = record;
        currentGroupId = record.id;
      }
    } else {
      const subListMap = currentList.subListMap;
      if (keySequenceNotToDisplay.indexOf(record.display_type) == -1) {
        if (!subListMap[record.display_type]) {
          subListMap[record.display_type] = [];
        }
        subListMap[record.display_type].push(record);
      }
    }
  });
  return groupByEncounterList;
};

export const sortSubListMap = (groupByEncounterList: Array<any>) => {
  if (!groupByEncounterList || !groupByEncounterList.length) {
    return [];
  }
  groupByEncounterList.forEach((data: any) => {
    const keys = Object.keys(data.subListMap || {});
    const subList: Array<any> = [];
    keys.forEach((key) => {
      if (keySequence.indexOf(key) == -1) {
        keySequence.push(key);
      }
    });
    keySequence.forEach((key: string) => {
      if (!data.subListMap[key]) {
        return;
      }

      data.subListMap[key].forEach((subData: any) => {
        if (
          key == 'Procedure' ||
          key == 'DiagnosticReport' ||
          key == 'Problem'
        ) {
          const valueData = subData.data ? JSON.parse(subData.data) : {};
          subData.search_tags = valueData?.code?.text || subData.search_tags;
          subData.search_tags = valueData?.clinicalStatus?.text
            ? subData.search_tags + ' - ' + valueData.clinicalStatus.text
            : subData.search_tags;

          if (key === 'DiagnosticReport') {
            if (!valueData['result']) {
              subData.noResult = true;
            }
          }
        }
        if (key == 'MedicationRequest') {
          const valueData = subData.data ? JSON.parse(subData.data) : {};
          subData.search_tags =
            valueData?.medicationCodeableConcept?.text || subData.search_tags;
        }
        if (key == 'Vital') {
          const valueData = subData.data ? JSON.parse(subData.data) : {};
          if (valueData?.valueQuantity?.value) {
            subData.value =
              typeof valueData.valueQuantity.value == 'number'
                ? (valueData?.valueQuantity?.value).toFixed(2)
                : valueData.valueQuantity.value;
            subData.unit = valueData?.valueQuantity?.unit;
          } else if (valueData?.component) {
            let value = '';
            valueData?.component.forEach((subComponent: any) => {
              const subText = subComponent?.code?.text || '';
              const subValue =
                (typeof subComponent?.valueQuantity?.value == 'number'
                  ? (subComponent?.valueQuantity?.value).toFixed(2)
                  : subComponent?.valueQuantity?.value) || '';
              const subUnit = subComponent?.valueQuantity?.unit || '';
              value = `${value} ${subText} ${subValue} ${subUnit}`;
            });
            subData.value = value;
            subData.unit = '';
          }
        }
        subList.push(subData);
      });
    });
    data.subList = subList;
  });
  return groupByEncounterList;
};

export const getFormattedOrganizationData = (data: any[]) => {
  const resultArray: {id: string; name: string; city: string; state: string}[] =
    [];

  data.map((item: any) => {
    const name = item.name;
    const city = item.address[0].city;
    const state = item.address[0].state;
    const id = item.id;

    resultArray.push({id, name, city, state});
  });

  return resultArray;
};

export const getEncounterOrgMap = (
  encounterData: any[],
  orgData: {id: string; name: string; city: string; state: string}[]
) => {
  const encounterOrgMap: {[index: string]: any} = {};
  encounterData.forEach((item) => {
    let orgId = '';
    if (item?.serviceProvider?.reference) {
      orgId = item?.serviceProvider?.reference?.split('|').pop();
    }
    if (item?.serviceProvider?.identifier) {
      orgId = item?.serviceProvider?.identifier?.value;
    }
    const encounterId = item.id;
    if (item?.serviceProvider?.display) {
      encounterOrgMap[encounterId] = item?.serviceProvider;
      return;
    }
    orgData.forEach((item) => {
      if (item.id === orgId) {
        encounterOrgMap[encounterId] = item;
      }
    });
  });

  return encounterOrgMap;
};

export const renderInEffective = (entity: any) => {
  if (false) {
  }
};

export const getPractitionerAppointmentMap = (args: {
  appointmentData: any[];
  practitionerData: {id: string; name: string}[];
}) => {
  const {appointmentData, practitionerData} = args;
  const practitionerAppointmentMap: {[index: string]: any} = {};
  appointmentData.map((item) => {
    const practitionerId = getPractitionerId(item);
    const appointmentId = item.id;
    practitionerData.forEach((object) => {
      if (object?.id === practitionerId) {
        practitionerAppointmentMap[appointmentId] = object;
      }
    });
  });
  return practitionerAppointmentMap;
};

export const getPractitionerId = (item: any) => {
  const participants = item.participant;
  const resultObject = participants.find((object: any) => {
    return (
      object?.actor?.reference &&
      object?.actor?.reference?.includes('Practitioner')
    );
  });

  return resultObject?.actor?.reference?.split('/')?.pop();
};

export const getOrganizationsData = (
  data: OrganizationApiResponse
): OrganizationData[] => {
  const entry = data.entry;
  const result: OrganizationData[] = [];

  entry.forEach((item) => {
    const resource = item.resource;
    result.push({
      name: resource?.name,
      city: resource?.address?.length > 0 ? resource?.address[0]?.city : '',
      state: resource?.address?.length > 0 ? resource?.address[0]?.state : '',
      id: resource?.id,
    });
  });

  return result;
};
