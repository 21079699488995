import { debounce } from 'lodash';
import {View, Text, HStack, VStack} from 'native-base';
import React from 'react';
import {AnswerList, Question} from '../interfaces';
import {
  SingleSelect,
  RadioComponent,
  MultiSelect,
  TextInput,
} from '../QuestionnaireComponents';
import {QUESTION_TYPES} from '../QuestionnaireConsts';
import {
  getTypeByQuestion,
  getSelectAnswerObject,
  getTextAnswerObject,
  getMultiSelectAnswerObject,
  getAnswerOfSingleSelectQuestion,
  getAnswerOfTextQuestion,
  getAnswerOfMultiSelectQuestion,
  getAnswerOfDateQuestion,
  getDateAnswerObject,
} from '../QuestionnaireUtils';
import { getMomentObj } from '../../../../../../utils/DateUtils';
import DateInput from '../QuestionnaireComponents/DateInput';
import { DISPLAY_DATE_FORMAT } from '../../../../../../constants';
import NumericInput from '../QuestionnaireComponents/NumericInput';

const QuestionnaireRenderer = (props: {
  isReadOnly?: boolean;
  questions: Question[];
  answers: AnswerList[];
  config?: {[index: string]: any};
  onAnswerChange: (answer: AnswerList) => void;
}) => {
  const {questions, answers, onAnswerChange, config} = props;
  const getComponentByQuestionType = (
    question: Question,
    index: number
  ): JSX.Element | undefined => {
    const type = getTypeByQuestion(question);
    switch (type) {
      case QUESTION_TYPES.CHOICE:
      case QUESTION_TYPES.OPEN_CHOICE:
        const answer = getAnswerOfSingleSelectQuestion(question, answers);
        if (props.isReadOnly) {
          return getReadOnlyView(question.text, answer?.display || '-', index);
        }
        return (
          <SingleSelect
            readonly={props.isReadOnly}
            title={question.text}
            answer={answer}
            answerOptions={question.answerOptions}
            required={question.required}
            onItemSelect={(answer) => {
              const selectAnswerObject = getSelectAnswerObject({
                question,
                answer,
              });
              onAnswerChange(selectAnswerObject);
            }}
          />
        );
      case QUESTION_TYPES.FREE_TEXT:
      case QUESTION_TYPES.TEXT:
      case QUESTION_TYPES.STRING:
        const textAnswer = getAnswerOfTextQuestion(question, answers);
        if (props.isReadOnly) {
          return getReadOnlyView(question.text, textAnswer || '-', index);
        }
        return (
          <TextInput
            readonly={props.isReadOnly}
            title={question.text}
            answer={textAnswer}
            required={question.required}
            onChangeText={debounce((text) => {
              const textAnswerObject = getTextAnswerObject({question, text});
              onAnswerChange(textAnswerObject);
            }, 500)}
          />
        );

        case QUESTION_TYPES.NUMERIC:
          const numericAnswer = getAnswerOfTextQuestion(question, answers);
          if (props.isReadOnly) {
            return getReadOnlyView(question.text, numericAnswer || '-', index);
          }
          return (
            <NumericInput
              readonly={props.isReadOnly}
              title={question.text}
              answer={numericAnswer}
              required={question.required}
              onChangeText={debounce((text) => {
                const textAnswerObject = getTextAnswerObject({question, text});
                onAnswerChange(textAnswerObject);
              }, 500)}
            />
          );

      case QUESTION_TYPES.RADIO:
        const radioAnswer = getAnswerOfSingleSelectQuestion(question, answers);
        if (props.isReadOnly) {
          return getReadOnlyView(
            question.text,
            radioAnswer?.display || '-',
            index
          );
        }
        return (
          <RadioComponent
            title={question.text}
            readonly={props.isReadOnly}
            answer={radioAnswer}
            required={question.required}
            onSelectOption={(answer) => {
              const radioAnswerObject = getSelectAnswerObject({
                question,
                answer,
              });
              onAnswerChange(radioAnswerObject);
            }}
            answerOptions={question.answerOptions}
          />
        );

      case QUESTION_TYPES.MULTI_SELECT:
        const multiAnswer = getAnswerOfMultiSelectQuestion(question, answers);
        if (props.isReadOnly) {
          const answer =
            multiAnswer.map((answer) => answer.display).join(', ') || '-';
          return getReadOnlyView(question.text, answer, index);
        }
        return (
          <MultiSelect
            readonly={props.isReadOnly}
            title={question.text}
            answerOptions={question.answerOptions}
            answer={multiAnswer}
            required={question.required}
            onItemSelect={(answers) => {
              const selectedAnswersObjects = getMultiSelectAnswerObject({
                question,
                answers,
              });
              onAnswerChange(selectedAnswersObjects);
            }}
          />
        );

      case QUESTION_TYPES.DATE:
      case QUESTION_TYPES.DATE_NEW:
        const dateAnswer = getAnswerOfDateQuestion(question, answers);
        if (props.isReadOnly) {
          const formattedDate = dateAnswer ? getMomentObj(new Date(dateAnswer)).format(DISPLAY_DATE_FORMAT) : undefined;
          return getReadOnlyView(question.text, formattedDate || '-', index);
        }
        return (
          <DateInput
            readonly={props.isReadOnly}
            title={question.text}
            answer={dateAnswer}
            format={config?.answerDateFormat}
            onChange={(date) => {
              const dateAnswerObject = getDateAnswerObject({question, date});
              onAnswerChange(dateAnswerObject);
            }}
          />
        );
      default:
        break;
    }
  };

  const getReadOnlyView = (question: string, answer: string, index: number) => {
    return (
      <VStack paddingBottom={4} paddingTop={index === 0 ? 2 : 0}>
        <Text size={'smLight'} color="gray.500" italic>
          {question}
        </Text>
        <Text>{answer}</Text>
      </VStack>
    );
  };

  return (
    <View>
      {questions.map((question, index) => {
        return (
          <div className='page-break'>
            <View key={`${question.id}_${index}`}>
              {getComponentByQuestionType(question, index)}
            </View>
          </div>
        );
      })}
    </View>
  );
};

export default QuestionnaireRenderer;
