import {useLazyQuery, useMutation} from '@apollo/client';
import {Button, Center, HStack, Skeleton, Text, VStack, View, useToast} from 'native-base';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Platform} from 'react-native';
import { HTTP_ERROR_CODE, MLOV_CATEGORY, PERSON_TYPES, RIGHT_SIDE_CONTAINER_CODE, WINDOW_EVENT_CODES} from '../../../../../constants';
import {COMMON_ACTION_CODES, CONVERSATION_ACTION_CODES} from '../../../../../constants/ActionConst';
import {SUPPORTED_EVENT_CODE} from '../../../../../constants/WebSocketConst';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import {ConvertContacts, LeadQueries, PatientQueries} from '../../../../../services';
import {Colors} from '../../../../../styles';
import {EventBus} from '../../../../../utils/EventBus';
import MeetingView from '../../../../common/MeetingView/MeetingView';
import {PERSON_ACTION_CODES} from '../../../../PersonOmniView/PersonHeaderBar/PersonAction/PersonActionPopover/ActionConst';
import {AddOrUpdateLead} from '../../../Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateLead';
import {getFieldConfig, getFormDataFromLeadData} from '../../../Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateUtils';
import CreateSmsConversationDrawer from '../ConversationChannelTabs/CreateSmsConversationDrawer/CreateSmsConversationDrawer';
import {IContact, IConversationData, INewConversationResponse} from '../interfaces';
import {CompanyPanel} from './CompanyPanel';
import {CustomerPanel} from './CustomerPanel';
import {IMessagingContactDetailState} from './interface';
import {styles} from './MessagingContactDetailsStyles';
import { checkMandatoryFields, getMissingFields } from '../../../Contacts/ContactsUtils';
import { CONTACT_ACTION_CODES } from '../../../Contacts/ContactsConst';
import { ConfirmationModal } from '../../../Contacts/Visitor/ConvertContactsList/ConfirmationModal';
import { ConvertLeadList } from '../../../Contacts/Leads/ConvertLeadList';
import { getAccountId, getUserId, getUserUUID } from '../../../../../utils/commonUtils';
import { getMlovId } from '../../../../../utils/mlovUtils';
import { CreatePatient, createPatientUsingIntegration } from '../../../../../services/CommonService/AidBoxService';
import { getBirthSexLabel, getGenderIdentityLabel, getResourceAbilities } from '../../../../../utils/capabilityUtils';
import { CapabilityResource } from '../../../Forms/FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper';
import { getKeysForPersonType } from '../../../Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateQueryUtils';
import { FHIR_RESOURCE, RESOURCE_BLOCK_MESSAGE_ID } from '../../../../../constants/FhirConstant';
import { Observation } from 'fhir/r4';
import { useIntl } from 'react-intl';
import {isAccountConfigEnabled} from '../../../../../utils/configUtils';
import {CONFIG_CODES} from '../../../../../constants/AccountConfigConst';
import { ToastType, showToast } from '../../../../../utils/commonViewUtils';
import MemberNotFoundSvg from '../../../../common/Svg/MemberNotFoundSvg';
import { EmailDrawerCommonV2 } from '../../../../common/EmailDrawerCommonV2';
import InstantChatView from '../../../../common/ChatDrawer/InstantChatView';
import ConversationsQueries from '../../../../../services/Conversations/ConversationsQueries';

export const MessagingContactDetails = (props: {
  selectedConversation?: IConversationData;
  selectedInboxTypeCode?: string;
  onConversationActionPerformed: (actionCode: string, actionData?: any) => void;
  borderLessView?: boolean;
  contactId?: any;
  height?: number;
  extra?: any;
  showEditProfileOnPatientInfoBanner?: boolean;
  parentCode?: string;
  hintExtraDetail?: any;
  btnTextVisibility?:any;
  showDetailProfile?: boolean;
  showCloseDrawerIcon?: boolean;
  patientHieSyncCall?: boolean;
  isEmployerDrillDown?: boolean;
  onContactDataFetched?: (contactDetails: {locationId: string}) => void;
}) => {
  const accountId = getAccountId();
  const userId = getUserId();
  const userUUID = getUserUUID();
  const toast = useToast();
  const intl = useIntl();
  const [error, setError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [messagingContactDetailState, setMessagingContactDetailState] =
    useState<IMessagingContactDetailState>({
      selectedActionView: '',
      selectedRowData: {} as any,
      currentTab: 'Customer',
      contactData: {} as IContact,
      contactType: null as any,
      isRank2: false as boolean,
      alert: '' as string,
      actionType: '' as string,
      sendInvite: true as boolean,
      convertLeadModal: false as boolean,
      confirmationModalOpen: false as boolean,
      selectLeadViewCode: {
        code: RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW as string,
        invocationCode: '' as string,
      },
      observations: [] as Observation[],
      patientUuid: '' as string | undefined,
    });

  const [state, setState] = React.useState({
    selectedConversationData: {} as any,
  });

  const [GetConversationByConversationId] =
  useLazyQuery<INewConversationResponse>(
    ConversationsQueries.GetConversationByConversationId,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const handleOnCreateNewEmailConversation = async (conversationId: string) => {
    if (!conversationId) {
      return;
    }
    try {
      const response = await GetConversationByConversationId({
        variables: {
          conversationId: Number(conversationId),
          loginUserIntId: userId,
          loginUserUuid: userUUID,
        },
      });
      const conversations = response.data?.conversations || [];
      if (conversations.length > 0) {
        const selectedConversationData = conversations[0];
        setState((prev) => {
          return {
            ...prev,
            selectedConversationData: selectedConversationData,
          };
        });
      }
    } catch (error) {
      setState((prev) => {
        return {
          ...prev,
          selectedConversationData: {},
        };
      });
    }
  };

  const {selectedConversation, contactId, parentCode} = props;
  const [currentTab, setCurrentTab] = useState('Customer');
  // const contactInfo = selectedConversation? getContactDataAndType(selectedConversation):null;
  const isLocationRequired =
    getResourceAbilities(
      FHIR_RESOURCE.PATIENT,
      '',
      messagingContactDetailState?.contactData?.contactPracticeLocations?.[0]
        ?.accountLocation?.uuid
    )?.keyAllowedOperations?.location?.isRequired || false;
  const currentContactType = messagingContactDetailState?.contactType
  ? messagingContactDetailState.contactType?.code
  : PERSON_TYPES.CUSTOMER;
  const patientResource = FHIR_RESOURCE.PATIENT;
  const fieldKeys = getKeysForPersonType(currentContactType)
  const isAddressLine1Required = !!getFieldConfig(
    fieldKeys?.addressLine1,
    messagingContactDetailState?.contactData?.contactPracticeLocations?.[0]
      ?.accountLocation?.uuid,
    patientResource
  )?.isRequired;
  const isMobileNumberRequired = false;
  const isPhoneOrEmailRequired = isAccountConfigEnabled(CONFIG_CODES.IS_PHONE_OR_EMAIL_REQUIRED);
  const genderAtBirthLabel = getBirthSexLabel(
    '',
    messagingContactDetailState?.contactData?.contactPracticeLocations?.[0]
      ?.accountLocation?.uuid
  );
  const genderIdentityLabel = getGenderIdentityLabel(
    '',
    messagingContactDetailState?.contactData?.contactPracticeLocations?.[0]
      ?.accountLocation?.uuid
  );

  const broadcastPatientUpdateEvent = () => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.broadcastEvent(WINDOW_EVENT_CODES.PATIENT_UPDATES, {fromSection: 'LEFT_PROFILE_VIEW'});
  }


  const canShowTabs = false;
  const commonData = useContext(CommonDataContext);
  const formattedContactData = getFormDataFromLeadData(
    messagingContactDetailState?.contactData || {},
    commonData
  );

  const [GetContact] = useLazyQuery(LeadQueries.GetContact, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      setIsLoading(false)
    },
    onError: () => {
      setError(true)

    },
  });

  const [CreatePatientWithContactId] = useMutation(
    PatientQueries.CreatePatientWithContactId
  );
  const [UpdateContactTypeByPatientUuid] = useMutation(
    ConvertContacts.UpdateContactTypeByPatientUuid
  );
  const handleConvertToCustomer = (id: any, convertToType: string) => {
    const typeId = getMlovId(commonData.MLOV, MLOV_CATEGORY.CUSTOMER_TYPE, convertToType);
    UpdateContactTypeByPatientUuid({
      variables: {
        id: id,
        typeId: typeId,
      },
    });
  };
  const getContactData = async () => {
    if (!contactId) {
      return;
    }
    const responseData = await GetContact({
      variables: {
        id: contactId,
      },
    });
    if (responseData?.data?.contact?.id) {
      props?.onContactDataFetched?.({
        locationId:
          responseData?.data?.contact?.contactPracticeLocations?.[0]
            ?.accountLocation?.uuid,
      });
      setMessagingContactDetailState((prev) => {
        return {
          ...prev,
          contactData: responseData?.data?.contact,
          contactType: responseData?.data?.contact?.contactType?.contactType,
        };
      });
    } else {
      setMessagingContactDetailState((prev) => {
        return {
          ...prev,
          contactData: {} as IContact,
        };
      });
    }
  };

  const patientCapability = getResourceAbilities(
    CapabilityResource.patient,
    '',
    messagingContactDetailState?.contactData?.contactPracticeLocations?.[0]
      ?.accountLocation?.uuid
  );
  const isGenderAtBirthRequired =
    patientCapability?.keyAllowedOperations?.birthSex?.isRequired || false;
  const isPractitionerRequired =
    patientCapability?.keyAllowedOperations?.generalPractitioner?.isRequired ||
    false;

  const shouldCreateInCanvas =
    getResourceAbilities(
      FHIR_RESOURCE.PATIENT,
      '',
      messagingContactDetailState?.contactData?.contactPracticeLocations?.[0]
        ?.accountLocation?.uuid
    )?.createPatientUsingIntegration || false;

  const setViewInvocationCode = (type: string) => {
    setMessagingContactDetailState((prev: any) => {
      return {
        ...prev,
        selectLeadViewCode: {
          invocationCode: type,
        }
      };
    });
  };

  const onViewChangeActionPerformed = (
    actionCode: string,
    rowData?: any,
    isRank2?: boolean
  ): any => {
    let finalActionCode =
      actionCode || RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW;
      setViewInvocationCode('');
    switch (actionCode) {
      case COMMON_ACTION_CODES.EDIT:
      case PERSON_ACTION_CODES.EDIT_LEAD:
        setMessagingContactDetailState((prev) => {
          return {
            ...prev,
            alert: '',
          };
        });
        finalActionCode = RIGHT_SIDE_CONTAINER_CODE.CONTACT_EDIT_VIEW;
        break;
      case COMMON_ACTION_CODES.CONVERT_TO_CUSTOMER:
        const formattedData = getFormDataFromLeadData(
          rowData?.singleLeadData || rowData,
          commonData
        );
        setMessagingContactDetailState((prev) => {
          return {
            ...prev,
            selectedRowData: rowData?.singleLeadData || rowData,
          };
        });
        if (
          checkMandatoryFields(formattedData, PERSON_TYPES.CUSTOMER, {
            isGenderAtBirthRequired:  isGenderAtBirthRequired || false, //
            isPractitionerRequired: isPractitionerRequired || false,
            isAddressLine1Required: isAddressLine1Required || false,
            isMobileNumberRequired: isMobileNumberRequired || false,
            checkVitalsForLessThan18Age: false,
            isPhoneOrEmailRequired,
            isLocationRequired: isLocationRequired || false,
          })
        ) {
          setMessagingContactDetailState((prev: any) => {
            return {
              ...prev,
              convertLeadModal: true,
              actionType: CONTACT_ACTION_CODES.CONVERT_TO_CUSTOMER,
              selectLeadViewCode: {
                code: RIGHT_SIDE_CONTAINER_CODE.CONTACT_CONVERT_CUSTOMER,
              }
            };
          });
          finalActionCode = RIGHT_SIDE_CONTAINER_CODE.CONTACT_CONVERT_CUSTOMER;
        } else {
          const alert = getMissingFields(formattedData, {
            isGenderAtBirthRequired: isGenderAtBirthRequired || false,
            isPractitionerRequired: isPractitionerRequired || false,
            genderAtBirthLabel,
            genderIdentityLabel,
            isAddressLine1Required: isAddressLine1Required || false,
            isMobileNumberRequired:isMobileNumberRequired || false,
            checkVitalsForLessThan18Age: false,
            isPhoneOrEmailRequired,
            isLocationRequired: isLocationRequired || false,
          }).message;
          setMessagingContactDetailState((prev) => {
            return {
              ...prev,
              alert: alert,
            };
          });
          setViewInvocationCode(COMMON_ACTION_CODES.CONVERT_TO_CUSTOMER);
          finalActionCode = RIGHT_SIDE_CONTAINER_CODE.CONTACT_EDIT_VIEW;
        }
        break;
      case COMMON_ACTION_CODES.MAIL:
        finalActionCode = RIGHT_SIDE_CONTAINER_CODE.CONTACT_MAIL_POPUP;
        break;
      case COMMON_ACTION_CODES.CALL:
        finalActionCode = RIGHT_SIDE_CONTAINER_CODE.CREATE_MEETING_VIEW;
        break;
      case COMMON_ACTION_CODES.SMS:
        finalActionCode = RIGHT_SIDE_CONTAINER_CODE.CONTACT_SMS_DRAWER;
        break;
      case COMMON_ACTION_CODES.ADDED_OR_UPDATED:
          if (
            rowData?.invocationCode === COMMON_ACTION_CODES.CONVERT_TO_CUSTOMER &&
            rowData?.leadFormData
          ) {
            onViewChangeActionPerformed(COMMON_ACTION_CODES.CONVERT_TO_CUSTOMER,
              rowData.leadFormData.singleLeadData,
            );
          } else {
            broadcastPatientUpdateEvent();
            getContactData();
            props.onConversationActionPerformed?.(COMMON_ACTION_CODES.ADDED_OR_UPDATED, rowData);
          }
        break;
      case PERSON_ACTION_CODES.OPEN_IN_EHR:
        props.onConversationActionPerformed?.(actionCode, rowData);
      break;
      case PERSON_ACTION_CODES.RESET_PASSWORD:
        props.onConversationActionPerformed?.(actionCode, rowData);
      break;
      case CONVERSATION_ACTION_CODES.IS_DETAILS_CONTAINER_VISIBLE:
        props.onConversationActionPerformed(
          CONVERSATION_ACTION_CODES.IS_DETAILS_CONTAINER_VISIBLE,
          rowData
        );
        break;
      default:
        props?.onConversationActionPerformed(actionCode, rowData);
        finalActionCode = RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW;
        break;
    }

    setMessagingContactDetailState((prev) => {
      return {
        ...prev,
        selectedRowData: rowData?.leadFormData?.singleLeadData || rowData?.singleLeadData || rowData,
        selectedActionView: finalActionCode,
        isRank2: isRank2,
      };
    });
  };
  const getCurrentTab = () => {
    switch (currentTab) {
      case 'Customer':
        return (
          <CustomerPanel
            btnTextVisibility={props.btnTextVisibility}
            onActionPerformed={onViewChangeActionPerformed}
            conversationData={selectedConversation}
            contactData={messagingContactDetailState.contactData}
            contactType={messagingContactDetailState.contactType}
            borderLessView={props?.borderLessView}
            height={props?.height}
            extra={props?.extra}
            hintExtraDetail={props.hintExtraDetail}
            showEditProfileOnPatientInfoBanner={
              props.showEditProfileOnPatientInfoBanner
            }
            parentCode={parentCode || ''}
            showDetailProfile={props?.showDetailProfile}
            showCloseDrawerIcon={props?.showCloseDrawerIcon}
            patientHieSyncCall={props.patientHieSyncCall}
            isEmployerDrillDown={props?.isEmployerDrillDown}
          />
        );

      case 'Company':
        return <CompanyPanel />;

      default:
        break;
    }
  };

  const getCurrentStyle = useCallback((tab: string) => {
    if (currentTab == tab) {
      return styles.bottomBorder;
    } else return {};
  }, [currentTab]);

  const getBorder = () => {
    if (currentTab == 'Customer') {
      return 1;
    } else return 0;
  };
  useEffect(() => {
    getContactData();
    setMessagingContactDetailState((prev) => {
      return {
        ...prev,
        selectedRowData: {},
        selectedActionView: '',
      };
    });
  }, [selectedConversation?.id]);

  useEffect(() => {
    getContactData();
    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener(WINDOW_EVENT_CODES.PATIENT_UPDATES, onEventReceived);
    return () => {
      eventBus.removeEventListener(onEventReceived);
    };
  }, []);
  const onEventReceived = useCallback(
    (data) => {
      if (data?.fromSection !== 'LEFT_PROFILE_VIEW') {
        getContactData();
      }
    },
    []
  );

  const handlePatientContactMap = (contactData: any, patientUuid: any) => {
    CreatePatientWithContactId({
      variables: {
        accountId: accountId,
        patientUuid: patientUuid,
        contactId: contactData.leadId,
      },
    }).catch(()=> {
      showToast(
        toast,
        intl.formatMessage({id: 'apiErrorMsg'}),
        ToastType.error,
      );
    })
  };

  const handleCreatePatientInEHR = (formattedData: any) => {
    const typeId = getMlovId(
      commonData.MLOV,
      MLOV_CATEGORY.CUSTOMER_TYPE,
      PERSON_TYPES.CUSTOMER
    );
    if (shouldCreateInCanvas) {
      createPatientUsingIntegration({
        patientData: {...formattedData, typeId: typeId},
        sendInvite: messagingContactDetailState?.sendInvite || false,
        addTagObjectList: [],
        userId: userId,
        userUuid: userUUID,
        successHandler() {
          handleConvertToCustomer(
            formattedData.personTypeId,
            PERSON_TYPES.CUSTOMER
          );
          props?.onConversationActionPerformed(COMMON_ACTION_CODES.ADDED_OR_UPDATED);
          showToast(
            toast,
            intl.formatMessage({id: 'convertPatientSuccessMsg'}),
            ToastType.success,
          )
          getContactData();
        },
        errorHandler(error) {
          if (error.response?.status === HTTP_ERROR_CODE.METHOD_NOT_ALLOWED){
            showToast(
              toast,
              intl.formatMessage({id: RESOURCE_BLOCK_MESSAGE_ID}),
              ToastType.error,
            );
          } else {
            showToast(
              toast,
              intl.formatMessage({id: 'apiErrorMsg'}),
              ToastType.error,
            );
          }
        },
      });
    } else {
      CreatePatient(
        formattedData,
        (res: any) => {
          const resId = res?.data?.id;
          handleConvertToCustomer(
            formattedData.personTypeId,
            PERSON_TYPES.CUSTOMER
          );
          props?.onConversationActionPerformed(COMMON_ACTION_CODES.ADDED_OR_UPDATED);
          showToast(
            toast,
            intl.formatMessage({id: 'convertPatientSuccessMsg'}),
            ToastType.success,
          )
          getContactData();
          handlePatientContactMap(formattedData, resId);
        },
        (error: any) => {
          if(error){
            showToast(
              toast,
              intl.formatMessage({id: 'apiErrorMsg'}),
              ToastType.error,
            );
          }
        }
      );
    }
  };

  return (
    <>
      <VStack>
        {canShowTabs && (
          <HStack
            style={styles.msgContactDetails}
            space={10}
            bgColor={Colors.Custom.ContainerBGColor}
            alignItems={'center'}
            justifyContent={'space-evenly'}
            borderBottomWidth={getBorder()}
            borderBottomColor={Colors.Custom.BorderColor}
          >
            <Button
              variant={'unstyled'}
              style={getCurrentStyle('Customer')}
              borderBottomRadius={'0'}
              onPress={() => {
                setMessagingContactDetailState((prev) => {
                  return {
                    ...prev,
                    currentTab: 'Customer',
                  };
                });
              }}
            >
              Customer
            </Button>
            <Button
              variant={'unstyled'}
              style={getCurrentStyle('Company')}
              borderBottomRadius={'0'}
              onPress={() => {
                setMessagingContactDetailState((prev) => {
                  return {
                    ...prev,
                    currentTab: 'Company',
                  };
                });
              }}
            >
              Company
            </Button>
          </HStack>
        )}
        {!error && messagingContactDetailState?.contactData &&
          Object.keys(messagingContactDetailState?.contactData).length ? (
          getCurrentTab()
        ) : (
          isLoading ? (
            <Skeleton.Text mt={10} lines={3} startColor="light.300" />
          ) : (
            <>
            <Text marginTop ={4} fontSize="22" marginLeft={6} font-weight='400'>Patient Details</Text>
            <View marginTop={280}>
              <Center>
              <MemberNotFoundSvg/>
                <Text
                  fontWeight="400"
                  fontSize="18"
                  color={Colors.Custom.Gray400}
                  marginTop={2}
                >
                  {'Member Not Found'}
                </Text>
              </Center>
            </View>
            </>
          )
        )}
      </VStack>
      {Platform.OS === 'web' &&
        messagingContactDetailState.selectedActionView ===
        RIGHT_SIDE_CONTAINER_CODE.CONTACT_EDIT_VIEW && (
          <AddOrUpdateLead
            singleLeadData={messagingContactDetailState.selectedRowData}
            invocationCode={messagingContactDetailState?.selectLeadViewCode.invocationCode}
            onFormCompleteAction={(actionCode: string, actionData: any) => {
              onViewChangeActionPerformed(actionCode, actionData);
              const eventBus = EventBus.getEventBusInstance()
              eventBus.broadcastEvent(
                SUPPORTED_EVENT_CODE.MESSAGE_UPDATED,
                COMMON_ACTION_CODES.COMPLETED
              );
            }}
            personType={messagingContactDetailState.contactType?.code}
            personTypeUuid={messagingContactDetailState.contactType?.id}
            alert={messagingContactDetailState.alert}
            observationsUpdated={(observations: Observation[]) => {
              setMessagingContactDetailState((prev: any) => {
                return {
                  ...prev,
                  observations: [...observations]
                };
              });
            }}
          />
        )}
      {Platform.OS === 'web' && messagingContactDetailState?.selectLeadViewCode.code ===
        RIGHT_SIDE_CONTAINER_CODE.CONTACT_CONVERT_CUSTOMER && (
          <>
            <ConvertLeadList
              isOpen={messagingContactDetailState.convertLeadModal}
              onClose={() => {
                setMessagingContactDetailState((prev: any) => {
                  return {
                    ...prev,
                    convertLeadModal: false,
                  };
                });
              }}
              onSave={(patientUuid) => {
                setMessagingContactDetailState((prev) => {
                  return {
                    ...prev,
                    confirmationModalOpen: true,
                    patientUuid: patientUuid,
                    actionType: CONTACT_ACTION_CODES.CONVERT_TO_CUSTOMER,
                  };
                });
              }}
              personType={PERSON_TYPES.CUSTOMER}
              contactData={{ singleLeadData: messagingContactDetailState.selectedRowData }}
              onCreatePatient={() => {
                setMessagingContactDetailState((prev) => {
                  return {
                    ...prev,
                    confirmationModalOpen: true,
                    actionType: CONTACT_ACTION_CODES.CREATE_PATIENT,
                  };
                });
              }}
              isChecked={(checked) => {
                setMessagingContactDetailState((prev) => {
                  return {
                    ...prev,
                    sendInvite: checked
                  };
                });
              }}
            />
            <ConfirmationModal
              isModalOpen={messagingContactDetailState.confirmationModalOpen}
              onModalClose={() => {
                setMessagingContactDetailState((prev) => {
                  return {
                    ...prev,
                    confirmationModalOpen: false,
                  };
                });
              }}
              btnList={[
                {
                  btnText: 'Confirm',
                  onBtnPress: () => {
                    let contactData =
                      messagingContactDetailState.selectedRowData;
                    if (!contactData?.personContact?.length) {
                      contactData = {
                        ...contactData,
                        personContact:
                          messagingContactDetailState?.contactData
                            ?.personContact || [],
                      };
                    }
                    let formattedData = getFormDataFromLeadData(
                      contactData,
                      commonData
                    );
                    formattedData = { ...formattedData, vitalObservations: messagingContactDetailState?.observations || [] };
                    if (
                      messagingContactDetailState.actionType ===
                      CONTACT_ACTION_CODES.CONVERT_TO_CUSTOMER
                    ) {
                      handlePatientContactMap(formattedData, messagingContactDetailState?.patientUuid);
                      handleConvertToCustomer(
                        formattedData.personTypeId,
                        PERSON_TYPES.CUSTOMER
                      );
                    } else if (
                      messagingContactDetailState?.actionType === CONTACT_ACTION_CODES.CREATE_PATIENT
                    ) {
                      handleCreatePatientInEHR(formattedData);
                    }
                    setMessagingContactDetailState((prev) => {
                      return {
                        ...prev,
                        confirmationModalOpen: false,
                        convertLeadModal: false,
                      };
                    });
                  },
                },
                {
                  btnText: 'Cancel',
                  onBtnPress: () => {
                    setMessagingContactDetailState((prev) => {
                      return {
                        ...prev,
                        confirmationModalOpen: false,
                      };
                    });
                  },
                  btnColorScheme: 'dark',
                  btnTextStyle: { color: 'white' },
                },
              ]}
              actionType={messagingContactDetailState.actionType}
            />
          </>
        )}
      {messagingContactDetailState.selectedActionView ===
        RIGHT_SIDE_CONTAINER_CODE.CONTACT_MAIL_POPUP && (
        <EmailDrawerCommonV2
          isOpen={
            messagingContactDetailState.selectedActionView ===
            RIGHT_SIDE_CONTAINER_CODE.CONTACT_MAIL_POPUP
          }
          onClose={() => {
            onViewChangeActionPerformed(COMMON_ACTION_CODES.RESET);
          }}
          contactIds={[messagingContactDetailState.contactData.id]}
          onEmailSent={({msgData}) => {
            handleOnCreateNewEmailConversation(msgData?.conversationId);
          }}
        />
      )}

      {state?.selectedConversationData?.id && (
        <InstantChatView
          selectedConversation={state?.selectedConversationData}
          contactData={{} as any}
          isLoading={false}
          isDrawerVisible={true}
          isInstantChatView={true}
          instantChatFromNotifications={true}
          onActionPerformed={(actionCode: any, actionData?: any) => {
            if (actionCode === COMMON_ACTION_CODES.CLOSE_MODAL) {
              setState((prev) => {
                return {
                  ...prev,
                  selectedConversationData: {} as any,
                };
              });
            } else if (actionCode === COMMON_ACTION_CODES.ITEM_CLICKED) {
              setState((prev) => {
                return {
                  ...prev,
                  selectedConversationData: actionData,
                };
              });
            } else if (actionCode === CONVERSATION_ACTION_CODES.UPDATE_TO_CONTACT) {
              setState((prev) => {
                return {
                  ...prev,
                  selectedConversationData: actionData,
                };
              });
            }
          }}
        />
      )}
      {messagingContactDetailState.selectedActionView ===
        RIGHT_SIDE_CONTAINER_CODE.CONTACT_SMS_DRAWER && (
          <CreateSmsConversationDrawer
            isDrawerVisible={true}
            selectedInbox={{} as any}
            onCreateSmsConversationActionPerformed={(
              actionCode: string,
              actionData: any
            ) => {
              setMessagingContactDetailState((prev) => {
                return {
                  ...prev,
                  selectedActionView: '',
                };
              });
            }}
            selectedInboxId={
              messagingContactDetailState?.selectedRowData?.smsInboxId
            }
            selectedPatient={messagingContactDetailState?.contactData}
          />
        )}
      {Object.keys(formattedContactData).length > 0 &&
        messagingContactDetailState.selectedActionView ===
        RIGHT_SIDE_CONTAINER_CODE.CREATE_MEETING_VIEW && (
          <MeetingView
            personData={formattedContactData}
            selectedConversation={selectedConversation}
            onPersonActionPerformed={onViewChangeActionPerformed}
          />
        )}
    </>
  );
};
