import {View, Text, Pressable} from 'native-base';
import React from 'react';
import CreateNewTaskSvg from '../../Svg/CreateNewTaskSvg';
import {Colors} from '../../../../styles/Colors';
import {styles} from './MessageBoxStyles';
import {IMessageTaskCountView} from './interfaces';
import { testID } from '../../../../testUtils';

const MessageTaskCountView = (props: IMessageTaskCountView) => {
  return (
    <View
      borderBottomRadius={8}
      verticalAlign="center"
      style={styles.taskPreview}
    >
      <View {...testID('image')}>
        <CreateNewTaskSvg
          customStrokeColor={Colors.Custom.Gray400}
          height={16}
          width={16}
        />
      </View>
      <View>
        <Text {...testID('DotSeparator')} paddingX={1} color={Colors.Custom.Gray500} fontSize={14}>
          {'•'}
        </Text>
      </View>
      <Pressable
        onPress={() => {
          props?.onActionPerformed();
        }}
        {...testID('TaskCount')}
      >
        <Text color={Colors.Custom.mainPrimaryPurple}>{props?.taskCount}</Text>
      </Pressable>
    </View>
  );
};

export default MessageTaskCountView;
