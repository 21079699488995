import {gql} from '@apollo/client';

export const CreateNoteByContact = gql`
  mutation CreateNoteByContact(
    $accountId: bigint!
    $contactId: Int
    $userId: bigint!
    $content: String!
  ) {
    createNote(
      object: {
        accountId: $accountId
        contactId: $contactId
        content: $content
        userId: $userId
      }
    ) {
      createdAt
      updatedAt
      userId
      content
      accountId
      id
      contactId
      user {
        name
        id
      }
    }
  }
`;

export const GetNotesByContactId = gql`
  query GetNotesByContactId($contactId: Int) {
    notes(
      where: {contactId: {_eq: $contactId}}
      order_by: {createdAt: desc}
    ) {
      content
      id
      userId
      user {
        name
        id
      }
      createdAt
      updatedAt
      contactStickyNotes {
      id
    }
    }
  }
`;
export const UPDATE_NOTE = gql`
  mutation UPDATE_NOTE($id: bigint!, $content: String!) {
    updateNote(
      pk_columns: {id: $id}
      _set: {content: $content}
    ) {
      id
      content
    }
  }
`;
export const updateLastNote = gql`
  mutation updateLastNote($noteId: bigint!, $content: String!) {
    updateNote(pk_columns: {id: $noteId}, _set: {content: $content}) {
      id
    }
  }
`;

export const DeleteContactNote = gql`
  mutation deleteContactNote($id: bigint!, $contactId: Int!) {
  updateNotes(where: {id: {_eq: $id}, contactId: {_eq: $contactId}}, _set: {isDeleted: true}) {
    returning {
      id
    }
  }
}
`

export default {
  CreateNoteByContact,
  GetNotesByContactId,
  updateLastNote,
  UPDATE_NOTE,
  DeleteContactNote
};
