import {Pressable, View, Text, Image} from "react-native"
import {SelectCustomField} from "./SelectCustomField"
import {BOOLEAN_OPTION, CUSTOM_ATTRIBUTE_DATA_TYPES, CUSTOM_ATTRIBUTE_FILTERS, CUSTOM_ATTRIBUTE_FILTERS_VALUE, CUSTOM_ATTRIBUTE_SELECT_TYPE, CUSTOM_FIELD_TOAST_ERROR_DURATION, CUSTOM_FIELD_TOAST_ERROR_DURATION_LONG, DATA_TYPES_OPERATION, ENTITY_TYPE} from "./CustomFieldConst"
import {getCustomAttributeFieldNameByDateType, getFormattedCustomAttributesSuggestedValues, getSelectedCustomAttributeIds} from "./CustomFieldUtils"
import {AutoComplete, Select, Spin} from "antd"
import AntDesign from "react-native-vector-icons/AntDesign"
import {Colors} from "../../../../styles"
import {COMMON_ACTION_CODES} from "../../../../constants/ActionConst"
import {useEffect, useRef, useState} from "react"
import {v4 as uuidV4} from 'uuid';
import moment, {Moment} from "moment";
import {useIntl} from "react-intl"
import {NumberInput} from "./NumberInput"
import {DatePickerInput} from "./DatePickerInput"
import {ICustomAttributeDataType, ICustomAttributesFilters, ICustomFieldFilter, ICustomFieldFilterState, ICustomFieldViewRef, ICustomToast, IOnActionPerformedData} from "./interface"
import {getDateObjectFromAPIFormatToMyFormat} from "../../../../utils/DateUtils"
import {PATIENT_QUERRY_DATE_FROMATED} from "../../../../constants"
import {getCustomFieldValueById} from "./CustomFieldService"
import {LoadingOutlined} from "@ant-design/icons"
import {ToastType} from "../../../../utils/commonViewUtils"
import {useToast} from "../../../Toast/ToastProvider"
const { Option } = Select;

export const CustomFieldFilter = (props: ICustomFieldFilter) => {
  const intl = useIntl();
  const [stateData, setStateData] = useState<ICustomFieldFilterState>({
    customAttributes: [],
    loading: false,
    visible: false,
    searchString: '',
    customAttributesSuggestedValues: [],
    selectedCustomAttributeId: '',
    customAttributeSuggestedValueLoading: false,
  })
  const toast = useToast();
  const customFieldViewRef = useRef<ICustomFieldViewRef>({} as any);

  const showToast = (toastData: ICustomToast) => {
    toast({
      toastType: toastData?.toastType,
      message: toastData?.message,
      duration: toastData?.duration || CUSTOM_FIELD_TOAST_ERROR_DURATION,
      closeAllPrevToast: toastData?.closeAllPrevToast || false,
    })
  }

  const getCustomAttributeSuggestedValues = async (searchString: string) => {
    setStateData((prev) => {
      return {
        ...prev,
        customAttributeSuggestedValueLoading: true,
      }
    })
    try {
      const selectedCustomAttributeId = stateData?.selectedCustomAttributeId;
      const params = {
        searchQuery: searchString
      }
      if (searchString && selectedCustomAttributeId) {
        const response = await getCustomFieldValueById(params, selectedCustomAttributeId);
        if (response?.data?.length) {
          const formattedData = getFormattedCustomAttributesSuggestedValues(response?.data);
          setStateData((prev) => {
            return {
              ...prev,
              customAttributesSuggestedValues: formattedData,
              customAttributeSuggestedValueLoading: false,
            }
          })
        } else {
          setStateData((prev) => {
            return {
              ...prev,
              customAttributesSuggestedValues: [],
              customAttributeSuggestedValueLoading: false,
            }
          })
        }
      } else {
        setStateData((prev) => {
          return {
            ...prev,
            customAttributesSuggestedValues: [],
            customAttributeSuggestedValueLoading: false,
          }
        })
      }
    } catch(error: any) {
      setStateData((prev) => {
        return {
          ...prev,
          customAttributeSuggestedValueLoading: false,
        }
      })
      showToast({
        toastType: ToastType.error,
        message: error.response.data.message || intl.formatMessage({id: 'apiErrorMsg'}),
        duration: CUSTOM_FIELD_TOAST_ERROR_DURATION_LONG,
        closeAllPrevToast: true,
      });
    }
  }

  const onAttributeChange = (actionData: IOnActionPerformedData, id: string) => {
    const filterType = getOptions(actionData?.type)?.[0];
    const updatedList = stateData?.customAttributes?.map((customAttribute: ICustomAttributesFilters) =>
      customAttribute?.id === id
      ? {
        ...customAttribute,
        customAttributeId: actionData?.key,
        customAttributeName: actionData?.name,
        dataType: actionData?.type,
        filterType: filterType?.value,
        value: '',
        range: {
          from: '',
          to: ''
        },
        condition: filterType?.condition,
        fieldName: getCustomAttributeFieldNameByDateType(actionData?.type),
      }
      : customAttribute
    );
    setStateData((prev)=> {
      return {
        ...prev,
        customAttributes: updatedList
      }
    })
  }

  const onFilterConditionChange = (actionData: IOnActionPerformedData, id: string) => {
    const updatedList = stateData?.customAttributes?.map((customAttribute: ICustomAttributesFilters) =>
      customAttribute?.id === id
      ? {
        ...customAttribute,
        filterType: actionData?.filterType,
        value: '',
        range: {
          from: '',
          to: ''
        },
        condition: actionData?.condition,
      }
      : customAttribute
    );
    setStateData((prev)=> {
      return {
        ...prev,
        customAttributes: updatedList,
      }
    })
  }

  const onCustomFieldValueChange = (actionData: IOnActionPerformedData, id: string) => {
    let updatedList: ICustomAttributesFilters[] = []
    if (actionData?.filterType === CUSTOM_ATTRIBUTE_FILTERS_VALUE.BETWEEN) {
      updatedList = stateData?.customAttributes?.map?.(
        (customAttribute: ICustomAttributesFilters) => {
          if (customAttribute?.id === id) {
            const newCustomAttribute = {...customAttribute};
            if (actionData?.range?.from !== undefined) {
              newCustomAttribute.range.from = actionData?.range?.from;
            } else if (actionData?.range?.to !== undefined) {
              newCustomAttribute.range.to = actionData?.range?.to;
            }
            return newCustomAttribute;
          } else {
            return customAttribute;
          }
        }
      );
    } else {
      updatedList = stateData?.customAttributes?.map?.((customAttribute: ICustomAttributesFilters) =>
        customAttribute?.id === id
        ? {
          ...customAttribute,
          value: actionData?.value
        }
        : customAttribute
      );
    }
    setStateData((prev)=> {
      return {
        ...prev,
        customAttributes: updatedList
      }
    })
  }

  const onCustomFieldDelete = (id: string) => {
    const updatedList = stateData?.customAttributes?.filter((customAttribute: ICustomAttributesFilters) => customAttribute.id !== id);
    setStateData((prev)=> {
      return {
        ...prev,
        customAttributes: updatedList
      }
    })
  }

  const addNewCustomFiled=() => {
    const customAttributeObject = {
      id: uuidV4(),
      customAttributeId: '',
      dataType: '',
      filterType: '',
      customAttributeName: '',
      value: '',
      range: {
        from: '',
        to: ''
      },
      fieldName: '',
      condition: ''
    }
    setStateData((prev)=> {
      return {
        ...prev,
        customAttributes: [...prev?.customAttributes || [], customAttributeObject]
      }
    })
  }

  const getOptions = (dataType: string) => {
    switch (dataType) {
      case CUSTOM_ATTRIBUTE_DATA_TYPES.BOOLEAN:
        return DATA_TYPES_OPERATION.BOOLEAN;
      case CUSTOM_ATTRIBUTE_DATA_TYPES.DATE:
        return DATA_TYPES_OPERATION.DATE;
      case CUSTOM_ATTRIBUTE_DATA_TYPES.NUMBER:
        return DATA_TYPES_OPERATION.NUMBER;
      case CUSTOM_ATTRIBUTE_DATA_TYPES.TEXT:
        return DATA_TYPES_OPERATION.TEXT;
      case CUSTOM_ATTRIBUTE_DATA_TYPES.EMAIL:
        return DATA_TYPES_OPERATION.EMAIL;
      case CUSTOM_ATTRIBUTE_DATA_TYPES.PHONE:
        return DATA_TYPES_OPERATION.PHONE;
      case CUSTOM_ATTRIBUTE_DATA_TYPES.NPI:
        return DATA_TYPES_OPERATION.NPI;
      default:
        return [];
    }
  }

  const onActionPerformed = (
    actionCode: string,
    actionData?: any,
    id?: string
  ) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.ADD:
        addNewCustomFiled();
        break;
      case COMMON_ACTION_CODES.ITEM_SELECT:
        id && onAttributeChange(actionData, id);
        break;
      case COMMON_ACTION_CODES.ATTRIBUTE_FILTER_CONDITION_CHANGE:
        id && onFilterConditionChange(actionData, id);
        break;
      case COMMON_ACTION_CODES.ATTRIBUTE_FILTER_VALUE_CHANGE:
        id && onCustomFieldValueChange(actionData, id);
        break;
      default:
        break;
    }
  };

  const onSearch = (value: string, id: string) => {
    onActionPerformed(
      COMMON_ACTION_CODES.ATTRIBUTE_FILTER_VALUE_CHANGE,
      {value: value},
      id
    )
    setStateData((prev) => ({
      ...prev,
      searchString: value,
    }));
  }

  const setSelectedValueForAttribute = (value: string, id: string) => {
    onActionPerformed(
      COMMON_ACTION_CODES.ATTRIBUTE_FILTER_VALUE_CHANGE,
      {value: value},
      id
    )
    setStateData((prev) => {
      return {
        ...prev,
        searchString: '',
      }
    })
  }

  const setCustomAttributeIdForSearch = (customAttributeId: string) => {
    setStateData((prev) => {
      return {
        ...prev,
        selectedCustomAttributeId: customAttributeId,
        customAttributesSuggestedValues: [],
      }
    })
  }

  const getFieldByDataType = (customFieldData: ICustomAttributesFilters) => {
    const { dataType, value, range, id, filterType, customAttributeId} = customFieldData
    let ele = <></>;
    if (dataType === CUSTOM_ATTRIBUTE_DATA_TYPES.TEXT || dataType === CUSTOM_ATTRIBUTE_DATA_TYPES.EMAIL) {
      ele = <>
        <AutoComplete
          ref={(ref) =>
            (customFieldViewRef.current[id] = ref as HTMLInputElement | any)
          }
          value={value}
          placeholder="Enter text"
          onSelect={(value: any, options: any) => {
            setSelectedValueForAttribute(value, id);
          }}
          onFocus={()=>{
            setCustomAttributeIdForSearch(customAttributeId);
          }}
          onBlur={()=> {
            setCustomAttributeIdForSearch(customAttributeId);
          }}
          onSearch={(text: string)=> {
            onSearch(text, id);
          }}
        >
          {stateData?.customAttributesSuggestedValues?.map((field: any,index: number) => {
            return (
              <>
                <Option key={field?.code} value={field?.value}>
                  {field?.label}
                </Option>
              </>
            )
          })}
        </AutoComplete>
        {(stateData?.customAttributeSuggestedValueLoading && stateData?.selectedCustomAttributeId === customAttributeId) && <View style={{
          position: 'absolute',
          right: 5,
          top: 5,
        }}>
          <Spin size='small' indicator={<LoadingOutlined style={{ fontSize: 16, color: Colors.Custom.Gray300 }} spin />} />
        </View>}
      </>
    } else if (dataType === CUSTOM_ATTRIBUTE_DATA_TYPES.NUMBER ||
      dataType === CUSTOM_ATTRIBUTE_DATA_TYPES.PHONE ||
      dataType === CUSTOM_ATTRIBUTE_DATA_TYPES.NPI) {
      if (filterType === CUSTOM_ATTRIBUTE_FILTERS.BETWEEN) {
        ele = <View style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
          <NumberInput
            value={range?.from}
            onChangeText={(text: string) => {
              onActionPerformed(
                COMMON_ACTION_CODES.ATTRIBUTE_FILTER_VALUE_CHANGE,
                {range: {from: text}, filterType},
                id
              )
            }}
            refId={`${id}_${customAttributeId}`}
          />
          <Text
            style={{
              marginBottom: 5,
              marginHorizontal: 6,
            }}
          >
            {"-"}
          </Text>
          <NumberInput
            value={range?.to}
            onChangeText={(text: string) => {
              onActionPerformed(
                COMMON_ACTION_CODES.ATTRIBUTE_FILTER_VALUE_CHANGE,
                {range: {to: text}, filterType},
                id
              )
            }}
            refId={`${id}_${customAttributeId}`}
          />
        </View>
      } else if (dataType === CUSTOM_ATTRIBUTE_DATA_TYPES.NPI) {
        ele = <>
          <AutoComplete
            ref={(ref) =>
              (customFieldViewRef.current[id] = ref as HTMLInputElement | any)
            }
            value={value}
            placeholder="Enter number"
            onSelect={(value: any, options: any) => {
              setSelectedValueForAttribute(value, id);
            }}
            onFocus={()=>{
              setCustomAttributeIdForSearch(customAttributeId);
            }}
            onBlur={()=> {
              setCustomAttributeIdForSearch(customAttributeId);
            }}
            onSearch={(text: string)=> {
              const numericValue = text?.replace(/[^0-9.]/g, '');
              customFieldViewRef.current[id].value = numericValue;
              onSearch(numericValue, id);
            }}
          >
            {stateData?.customAttributesSuggestedValues?.map((field: any,index: number) => {
              return (
                <>
                  <Option key={field?.code} value={field?.value}>
                    {field?.label}
                  </Option>
                </>
              )
            })}
          </AutoComplete>
          {(stateData?.customAttributeSuggestedValueLoading && stateData?.selectedCustomAttributeId === customAttributeId) && <View style={{
            position: 'absolute',
            right: 5,
            top: 5,
          }}>
            <Spin size='small' indicator={<LoadingOutlined style={{ fontSize: 16, color: Colors.Custom.Gray300 }} spin />} />
          </View>}
        </>
      } else {
        ele = <NumberInput
          value={value}
          onChangeText={(text: string) => {
            onActionPerformed(
              COMMON_ACTION_CODES.ATTRIBUTE_FILTER_VALUE_CHANGE,
              {value: text},
              id
            )
          }}
          refId={`${id}_${customAttributeId}`}
        />
      }
    } else if (dataType === CUSTOM_ATTRIBUTE_DATA_TYPES.DATE) {
      if (filterType === CUSTOM_ATTRIBUTE_FILTERS.BETWEEN) {
        ele = <View style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
          <DatePickerInput
            value={value || range?.from ? moment(value || range?.from) : null}
            onChange={(date: Moment | null, dateString: string) => {
              const formattedDateStr = getDateObjectFromAPIFormatToMyFormat(dateString, PATIENT_QUERRY_DATE_FROMATED);
              onActionPerformed(
                COMMON_ACTION_CODES.ATTRIBUTE_FILTER_VALUE_CHANGE,
                {range: {from: formattedDateStr}, filterType},
                id
              )
            }}
          />
          <Text
            style={{
              marginBottom: 5,
              marginHorizontal: 6,
            }}
          >
            {"-"}
          </Text>
          <DatePickerInput
            value={value || range?.to ? moment(value || range?.to) : null}
            onChange={(date: Moment | null, dateString: string) => {
              const formattedDateStr = getDateObjectFromAPIFormatToMyFormat(dateString, PATIENT_QUERRY_DATE_FROMATED);
              onActionPerformed(
                COMMON_ACTION_CODES.ATTRIBUTE_FILTER_VALUE_CHANGE,
                {range: {to: formattedDateStr}, filterType},
                id
              )
            }}
          />
        </View>
      } else {
        ele = <DatePickerInput
        value={value ? moment(value) : null}
          onChange={(date: Moment | null, dateString: string) => {
            const formattedDateStr = getDateObjectFromAPIFormatToMyFormat(dateString, PATIENT_QUERRY_DATE_FROMATED);
            onActionPerformed(
              COMMON_ACTION_CODES.ATTRIBUTE_FILTER_VALUE_CHANGE,
              {value: formattedDateStr},
              id
            )
          }}
        />
      }
    } else if (dataType === CUSTOM_ATTRIBUTE_DATA_TYPES.BOOLEAN) {
      ele = <Select
          style={{
            borderRadius: 6,
            width: '100%'
          }}
          defaultValue={value ? value : undefined}
          placeholder="Select value"
          options={BOOLEAN_OPTION}
          onSelect={(value) => {
            onActionPerformed(
              COMMON_ACTION_CODES.ATTRIBUTE_FILTER_VALUE_CHANGE,
              {value: value},
              id
            )
          }}
        />
    }
    return ele;
  }

  useEffect(()=> {
    setStateData((prev)=> {
      return {
        ...prev,
        customAttributes: props?.customAttributes || []
      }
    })
  },[])


  useEffect(()=> {
    props?.onDetailChange?.(stateData?.customAttributes);
  },[stateData?.customAttributes])

  useEffect(()=> {
    if (!props?.customAttributes) {
      setStateData((prev)=> {
        return {
          ...prev,
          customAttributes: [],
        }
      })
      addNewCustomFiled();
    }
  },[props?.onReset])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getCustomAttributeSuggestedValues(stateData?.searchString);
    }, 300);
    return () => clearTimeout(delayDebounceFn);
  }, [stateData?.searchString]);

  return <>
    <View style={{
      flex: 1,
    }}>
      <View >
        {stateData?.customAttributes?.map((customAttribute: ICustomAttributesFilters)=> {
          return (
            <View style={{
              flex: 1,
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 12,
            }}>
              <View style={{
                flex: 0.37
              }}>
                <SelectCustomField
                  onActionPerformed={(actionCode: string, actionData: any) => {
                    onActionPerformed(actionCode, actionData, customAttribute?.id)
                  }}
                  entityType={ENTITY_TYPE.CONTACT}
                  selectedValue={customAttribute?.customAttributeName}
                  selectedCustomAttributesId={getSelectedCustomAttributeIds(stateData?.customAttributes)}
                  customAttributeSelectType={CUSTOM_ATTRIBUTE_SELECT_TYPE.SINGLE_SELECT}
                  placeholder={intl.formatMessage({id: 'selectCustomField'})}
                />
              </View>
              <View style={{
                flex: 0.16
              }}>
                <Select
                  showSearch
                  style={{
                    borderRadius: 6,
                    width: '100%'
                  }}
                  value={customAttribute?.filterType}
                  onSelect={(value, item) => {
                    onActionPerformed(COMMON_ACTION_CODES.ATTRIBUTE_FILTER_CONDITION_CHANGE, {
                      filterType: value,
                      condition: item?.condition
                    }, customAttribute?.id)
                  }}
                  placeholder="Select filter"
                >
                  {getOptions(customAttribute?.dataType)?.map((customAttributeDataType: ICustomAttributeDataType, index: number) => {
                    return (
                      <>
                        <Option key={customAttributeDataType?.key} value={customAttributeDataType?.value} condition={customAttributeDataType?.condition}>
                          {customAttributeDataType?.label}
                        </Option>
                      </>
                    )
                  })
                  }
                </Select>
              </View>
              <View style={{
                flex: 0.37,
                alignSelf: 'center',
              }}>
                {getFieldByDataType(customAttribute)}
              </View>
              <View style={{
                flex: 0.07
              }}>
                <Pressable
                  style={{
                    alignItems: 'center',
                    alignSelf: 'center'
                  }}
                  onPress={() => {
                    onCustomFieldDelete(customAttribute?.id);
                  }}
                >
                  <Image
                    resizeMode="contain"
                    style={{
                      width: 24,
                      height: 24,
                    }}
                    source={require('../../../../assets/images/trash.png')}
                  />
                </Pressable>
              </View>
            </View>
          )
        })}
      </View>
      <View style={{
        flex: 1,
        marginBottom: 2
      }}>
        <Pressable
          style={{
            alignItems: 'center',
            flexDirection: 'row',
            marginLeft: 5,
            width: 70,
          }}
          onPress={() => {
            onActionPerformed?.(COMMON_ACTION_CODES.ADD)
          }}
        >
          <AntDesign style={{
            color: Colors.Custom.mainPrimaryPurple,
            fontWeight: '400'
          }}
            size={16}
            name="plus"
          />
          <Text style={{
            color: Colors.Custom.mainPrimaryPurple,
            fontWeight: '400'
          }}>
            {" "}{intl.formatMessage({id: 'add'})}
          </Text>
        </Pressable>
      </View>
    </View>
  </>
}
