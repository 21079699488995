import {Formio} from '@foldhealth/formiojs';
import KeyField from '../EditFormFields/KeyField';
import SearchableComponentFields from '../EditFormFields/SearchableComponentFields';

Formio.Components.components.hidden.editForm = function () {
  return {
    key: 'display',
    components: [
      {
        type: 'oldtextfield',
        key: 'label',
        label: 'Label',
        input: true,
        validate: {
          required: true,
        },
      },
      ...SearchableComponentFields,
      {
        type: 'oldtextfield',
        label: 'Default Value',
        key: 'defaultValue',
        placeholder: 'Default Value',
        tooltip:
          'The will be the value for this field, before user interaction. Having a default value will override the placeholder text.',
        input: true,
      },
      ...KeyField,
    ],
  };
};
