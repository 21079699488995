export const DEFAULT_SUGGESTION_COUNT = 3;

export const DEFAULT_TITLE = 'composeEmailWithUnityAI';

export const VIEW_CODES = {
  INITIAL: 'INITIAL',
  PROMPT_INPUT: 'PROMPT_INPUT',
  GENERATED: 'GENERATED',
  SELECTED: 'SELECTED',
} as const;
