import {Center, HStack, Skeleton, VStack} from 'native-base';
import React from 'react';
import { TestIdentifiers, testID } from '../../../testUtils';

export const ConversationItemLoading = () => {
  return (
    <Center w="100%" {...testID(TestIdentifiers.loadMore)}>
      <HStack w="90%" space={6} rounded="md" p="4">
        <Skeleton endColor="coolGray.100" size="16" rounded="full" />
        <VStack flex="3" space="1">
          <Skeleton.Text endColor="coolGray.100" lines={3} />
        </VStack>
        <VStack flex="1" space="1">
          <Skeleton.Text endColor="coolGray.100" flex="1" lines={1} />
          <Skeleton
            justifyContent={'flex-end'}
            endColor="coolGray.100"
            size="4"
            rounded="full"
          />
        </VStack>
      </HStack>
    </Center>
  );
};
