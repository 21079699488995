import MDEditor, {commands} from '@uiw/react-md-editor';
import {useState} from 'react';
import {IRichTextProps} from '../RichText/interface';

export const MdEditor = (props: IRichTextProps) => {
  const {onChangesValue} = props;
  const commandsToShow = [
    {...commands.bold},
    {...commands.italic},
    {...commands.strikethrough},
    {...commands.code},
    {...commands.image},
    {...commands.divider},
    {...commands.unorderedListCommand},
    {...commands.orderedListCommand},
    {...commands.link},
  ];
  const [enterHit,setEnterHit] = useState(true);
  const extraCommandsToShow = [{}];
  commandsToShow;
  return (
    <div data-color-mode="light">
      <MDEditor
        height={640}
        commands={commandsToShow}
        value={props.valueStr}
        onChange={onChangesValue}
        extraCommands={extraCommandsToShow}
        defaultTabEnable={true}
        preview={'edit'}
        onKeyDown={(e) => {
          const keyCode = e.which || e.keyCode;
          if (props.onKeyPress) {
            if (keyCode === 50) {
              props.onKeyPress(true);
            }
            if (keyCode === 8) {
              props.onKeyPress(false);
            }
          }
          if (keyCode === 13 && !e.shiftKey) {
            e.preventDefault();
            if (props.isEnterToSubmit) {
              if(enterHit) {
                props.onRichTextAction?.();
                setEnterHit(false)
              }
            }
          }
        }}
        visiableDragbar={false}
      />
    </div>
  );
};
