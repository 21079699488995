import React from 'react';
import ReactDOM from 'react-dom';
import {ReactComponent} from '@foldhealth/formio-react';
import {Formio} from '@foldhealth/formiojs';
import CustomWrapper from '../CustomWrapper/CustomWrapper';
import KeyField from '../../EditFormFields/KeyField';
import ConditionalFields from '../../EditFormFields/ConditionalFields';
import CustomClassComponent from '../CustomWrapper/CustomClassComponent';
import Image from './Image';
import SearchableComponentFields from '../../EditFormFields/SearchableComponentFields';
import ShareWithPatientFields from '../../EditFormFields/ShareWithPatientFields';

export default class ImageComponent extends CustomClassComponent {
  static get builderInfo() {
    return {
      type: 'image',
      label: 'Image',
      key: 'image',
      content: 'Image',
      scale: '33%',
      align: 'center',
      schema: ImageComponent.schema(),
    };
  }

  static schema() {
    return ReactComponent.schema({
      type: 'image',
      label: 'Image',
      key: 'image'
    });
  }

  attachReact(element: any) {
    return ReactDOM.render(
      <CustomWrapper formOptionData={this.options}>
        <Image
          options={this.options}
          disabled={this.disabled}
          component={this.component}
          validateRef={this.validateRef}
          onChange={this.updateValue}
          setValueRef={this.setValueRef}
          fileService={this.fileService}
        />
      </CustomWrapper>,
      element
    );
  }

  static editForm = function () {
    return {
      key: 'display',
      components: [
        {
          label: 'Upload Image',
          tableView: false,
          storage: 'base64',
          webcam: false,
          fileTypes: [],
          key: 'file',
          type: 'file',
          input: true,
          multiple: false,
          hidden: true,
          clearOnHide: false,
          filePattern: 'image/*',
        },
        {
          type: 'select',
          input: true,
          key: 'align',
          label: 'Align Image',
          tooltip:
            'Image will be aligned as per selected option. Default value is center',
          defaultValue: 'center',
          dataSrc: 'values',
          data: {
            values: [
              {label: 'Center', value: 'center'},
              {label: 'Left', value: 'left'},
              {label: 'Right', value: 'right'},
            ],
          },
        },
        {
          type: 'select',
          input: true,
          key: 'scale',
          label: 'Scale Image',
          tooltip:
            'Image will be scaled as per the selected value. Default value is 33%.',
          defaultValue: '33%',
          dataSrc: 'values',
          data: {
            values: [
              {label: '10%', value: '10%'},
              {label: '25%', value: '25%'},
              {label: '33%', value: '33%'},
              {label: '50%', value: '50%'},
              {label: '75%', value: '75%'},
              {label: '100%', value: '100%'},
            ],
          },
        },
        ...SearchableComponentFields,
        ...ConditionalFields,
        ...KeyField,
        {
          type: 'oldtextfield',
          key: 'label',
          label: 'Label',
          input: true,
          hidden: true,
          clearOnHide: false,
        },
        {
          label: 'ImageURL',
          key: 'imageURL',
          type: 'hidden',
          hidden: true,
          clearOnHide: false,
          input: true,
        },
        ...ShareWithPatientFields,
      ],
    };
  };
}

Formio.Components.addComponent('image', ImageComponent);
