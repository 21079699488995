import React from 'react';

const ImageSvg = (props: {color?: string}) => {
  const strokeColor = props.color ? props.color : '#825AC7';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M15.833 2.5H4.167c-.92 0-1.667.746-1.667 1.667v11.666c0 .92.746 1.667 1.667 1.667h11.666c.92 0 1.667-.746 1.667-1.667V4.167c0-.92-.746-1.667-1.667-1.667z"
      ></path>
      <path
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M7.084 8.333a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zM17.5 12.5l-4.167-4.167L4.166 17.5"
      ></path>
    </svg>
  );
};

export default ImageSvg;
