import {FormControl, Input, useMediaQuery, View, VStack} from 'native-base';
import {IPAD_MINI_WIDTH, IPAD_WIDTH} from '../../../../../../constants';
import {ITopCardProps} from '../../interfaces';
import {styles} from './TopCardStyle';

const {Label, ErrorMessage} = FormControl;

const TopCard = (props: ITopCardProps) => {
  const {onChangeTitle} = props;

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const commonWidth = () => {
    if (isIPadMiniScreen) {
      return 600;
    }
    if (isIPadScreen) {
      return 600;
    }
    return '100%';
  };

  return (
    <View style={styles.formContainer}>
      <View style={[styles.commonContainer, {width: commonWidth()}]}>
        <VStack>
          <View style={styles.leftContainer}>
            <FormControl isRequired isInvalid={props.errors.title} flex={1}>
              <Input
                defaultValue={props.data?.title}
                isRequired
                onChangeText={(text: string) => onChangeTitle(text)}
                fontSize={'36px'}
                height={'54px'}
                lineHeight={'54px'}
                fontWeight={'500'}
                borderWidth={'0px'}
                placeholder="Title"
                backgroundColor={'white'}
              />
              {props.errors.title && (
                <ErrorMessage>{props.errors.title}</ErrorMessage>
              )}
            </FormControl>
          </View>
        </VStack>
      </View>
    </View>
  );
};

export default TopCard;
