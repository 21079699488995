import {Formio, Utils} from '@foldhealth/formiojs';
import _ from 'lodash';
import BasicFields from '../../EditFormFields/BasicFields';
import ConditionalFields from '../../EditFormFields/ConditionalFields';
import KeyField from '../../EditFormFields/KeyField';

const Field = Formio.Components.components.field;

export default class SurveyComponent extends Field {
  static schema(...extend: any) {
    return Field.schema(
      {
        type: 'customsurvey',
        label: 'Table',
        key: 'customsurvey',
        questions: [],
        values: [],
      },
      ...extend
    );
  }

  static get builderInfo() {
    return {
      title: 'Survey',
      group: 'advanced',
      icon: 'list',
      documentation: '/userguide/#survey',
      schema: SurveyComponent.schema(),
    };
  }

  get defaultSchema() {
    return SurveyComponent.schema();
  }

  render() {
    return super.render(this.renderTemplate('survey'));
  }

  attach(element: any) {
    this.loadRefs(element, {input: 'multiple'});
    const superAttach = super.attach(element);
    this.refs.input.forEach((input: any) => {
      if (this.disabled) {
        input.setAttribute('disabled', 'disabled');
      } else {
        this.addEventListener(input, 'change', () =>
          this.updateValue(null, {
            modified: true,
          })
        );
      }
    });
    this.setValue(this.dataValue);
    return superAttach;
  }

  setValue(value: any, flags = {}) {
    if (!value) {
      return false;
    }

    _.each(this.component.questions, (question) => {
      _.each(this.refs.input, (input) => {
        if (input.name === this.getInputName(question)) {
          if (this.component.allowMultiSelection) {
            input.checked =
              value[question.value] &&
              value[question.value].includes(input.value);
          } else {
            input.checked = input.value === value[question.value];
          }
        }
      });
    });

    const changed = this.updateValue(value, flags);

    if (changed && this.isHtmlRenderMode()) {
      this.redraw();
    }
    return changed;
  }

  get emptyValue() {
    return {};
  }

  get defaultValue() {
    const defaultValue = super.defaultValue;
    //support for default values created in old formio.js versions
    if (
      defaultValue &&
      !_.isObject(defaultValue) &&
      this.component.values.some((value: any) => value.value === defaultValue)
    ) {
      const adoptedDefaultValue: any = {};

      this.component.questions.forEach((question: any) => {
        adoptedDefaultValue[question.value] = defaultValue;
      });

      return adoptedDefaultValue;
    }

    return defaultValue;
  }

  getValue() {
    if (this.viewOnly || !this.refs.input || !this.refs.input.length) {
      return this.dataValue;
    }
    const value: any = {};
    _.each(this.component.questions, (question) => {
      _.each(this.refs.input, (input) => {
        if (input.checked && input.name === this.getInputName(question)) {
          if (this.component.allowMultiSelection) {
            if (!value[question.value]) {
              value[question.value] = [];
            }
            value[question.value].push(input.value);
          } else {
            value[question.value] = input.value;
            return false;
          }
        }
      });
    });
    return value;
  }

  set disabled(disabled) {
    super.disabled = disabled;
    _.each(this.refs.input, (input) => {
      input.disabled = true;
    });
  }

  get disabled() {
    return super.disabled;
  }

  validateRequired(setting: any, value: any) {
    if (!Utils.boolValue(setting)) {
      return true;
    }
    return this.component.questions.reduce(
      (result: any, question: any) => result && Boolean(value[question.value]),
      true
    );
  }

  getInputName(question: any) {
    return `${this.options.name}[${question.value}]`;
  }

  getValueAsString(value: any, options: any) {
    if (options?.email) {
      let result = `
        <table border="1" style="width:100%">
          <thead>
            <tr>
              <th>Question</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
      `;

      _.forIn(value, (value, key) => {
        const question = _.find(this.component.questions, ['value', key]);
        const answer = _.find(this.component.values, ['value', value]);

        if (!question || !answer) {
          return;
        }

        result += `
            <tr>
              <td style="text-align:center;padding: 5px 10px;">${question.label}</td>
              <td style="text-align:center;padding: 5px 10px;">${answer.label}</td>
            </tr>
          `;
      });

      result += '</tbody></table>';

      return result;
    }

    return super.getValueAsString(value, options);
  }
}

SurveyComponent.editForm = function () {
  return {
    key: 'display',
    components: [
      ...BasicFields.filter((field) => {
        return field.key !== 'placeholder';
      }),
      {
        type: 'datagrid',
        input: true,
        label: 'Questions',
        key: 'questions',
        tooltip: 'The questions you would like to ask in this survey.',
        weight: 0,
        reorder: true,
        defaultValue: [{label: '', value: ''}],
        components: [
          {
            label: 'Label',
            key: 'label',
            input: true,
            type: 'oldtextfield',
          },
          {
            label: 'Value',
            key: 'value',
            input: true,
            type: 'oldtextfield',
            allowCalculateOverride: true,
            hidden: true,
            clearOnHide: false,
            calculateValue: {_camelCase: [{var: 'row.label'}]},
          },
        ],
      },
      {
        type: 'datagrid',
        input: true,
        label: 'Possible Answers',
        key: 'values',
        tooltip:
          "The values that can be selected per question. Example: 'Satisfied', 'Very Satisfied', etc.",
        weight: 1,
        reorder: true,
        defaultValue: [{label: '', value: ''}],
        components: [
          {
            label: 'Label',
            key: 'label',
            input: true,
            type: 'oldtextfield',
          },
          {
            label: 'Value',
            key: 'value',
            input: true,
            type: 'oldtextfield',
            allowCalculateOverride: true,
            hidden: true,
            clearOnHide: false,
            calculateValue: {_camelCase: [{var: 'row.label'}]},
          },
        ],
      },
      {
        type: 'checkbox',
        label: 'Allow multiple answers for a question',
        tooltip:
          'This allows user to select multiple values against each question.',
        key: 'allowMultiSelection',
        input: true,
      },
      // KEPT FOR FUTURE
      // {
      //   type: 'checkbox',
      //   label: 'Hide Label',
      //   tooltip:
      //     'Hide the label or title of this component. This allows you to show the label in the form builder, but not when the form is shown to the member.',
      //   key: 'hideLabel',
      //   input: true,
      // },
      ...ConditionalFields,
      ...KeyField,
    ],
  };
};

Formio.Components.addComponent('customsurvey', SurveyComponent);
