import {useLazyQuery, useMutation} from '@apollo/client';
import {
  Box,
  HStack,
  Image,
  Pressable,
  ScrollView,
  Skeleton,
  Text,
  useToast,
  View,
  VStack,
} from 'native-base';
import {useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {default as Icon} from 'react-native-vector-icons/AntDesign';
import {useNavigate} from 'react-router-dom';
import {MLOV_CATEGORY} from '../../../../../constants';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../constants/Configs';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import {ILoginUserData} from '../../../../../Interfaces';
import {TaskQueries} from '../../../../../services';
import {Colors} from '../../../../../styles';
import { getUserId, getUserUUID } from '../../../../../utils/commonUtils';
import { getCurrentTimeZone } from '../../../../../utils/DateUtils';
import {EventBus} from '../../../../../utils/EventBus';
import {getMlovListFromCategory} from '../../../../../utils/mlovUtils';
import AddOrUpdateTask from '../../../../common/AddTask/AddOrUpdateTask';
import {
  IConversationTaskData,
  ITask,
} from '../../../../common/CareDashboard/CareDashboardInterfaces';
import { TASK_ASSIGNEE_TYPE_CODES } from '../../../../common/CareDashboard/CareDashboardTable/CareDashboardTableHelper';
import {
  getCompletedTaskStatusId,
  TASK_COMPLETED_STATUS_CODE,
} from '../../../../common/CareDashboard/CareDashboardUtils/CareDashboardUtils';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import TaskCard from '../../../../TaskCard/TaskCard';
import {
  BottomViewAction,
  descriptionDisplayModes,
  getCardTag,
  getDescription,
} from '../../../../TaskCard/TaskCardHelper';
import {IContact, IConversationData} from '../interfaces';
import { getVitalListFromCapability } from '../../../../../utils/capabilityUtils';
import { ToastType, showToast } from '../../../../../utils/commonViewUtils';
import { StyleSheet } from 'react-native';

export const TaskDetailPanel = (props: {
  conversationData: IConversationData;
  contactData: IContact;

}) => {
  const intl = useIntl();
  const toast = useToast();
  const mlovData = useContext(CommonDataContext);
  const conversationUuid = props?.conversationData?.uuid || '';

  const commonData = useContext(CommonDataContext);
  const userData = commonData.userData || ({} as ILoginUserData);
  const navigate = useNavigate();
  const accountId =
    userData?.accounts.length > 0 ? userData?.accounts[0].uuid || '' : '';
  const [stateData, setStateData] = useState<{
    loading: boolean;
    taskIdWithAction?: unknown;
    taskLoading?: boolean;
    tasklist: ITask[];
  }>({
    loading: false,
    taskLoading: true,
    tasklist: [],
  });

  const taskStatusMlov =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.TASK_STATUS
    ) || [];
  const completedStatusId = getCompletedTaskStatusId(taskStatusMlov);

  const showErrorPopup = () => {
    showToast(
      toast,
      intl.formatMessage({id: 'apiErrorMsg'}),
      ToastType.error
    );
  };

  const [taskDetail, setTaskDetail] = useState<{
    show: boolean;
    task?: ITask;
  }>({
    show: false,
  });

  const vitals = getVitalListFromCapability('', '');

  const [loadingData, setLoadingData] = useState<{
    loading: boolean;
    taskIdWithAction?: any;
  }>({
    loading: false,
  });

  const [updateTaskStatus] = useMutation(TaskQueries.UPDATE_TASK_STATUS, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    onCompleted: () => {
      if (taskDetail.show) { // this condition implies that the task is being updated from the Drawer
        setTaskDetail({
          show: false,
          task: undefined,
        });
      }
      showToast(
        toast,
        intl.formatMessage({id: 'taskCompletedMsg'}),
        ToastType.success
      );
      setStateData((prev) => ({...prev, loading: false}));
      fetchTasks();
    },
    onError: (error: any) => {

      setStateData((prev) => ({...prev, loading: false}));
      showErrorPopup();
    },
  });


  const [getTasks, taskQuery] = useLazyQuery(TaskQueries.GET_TASKS, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
    onCompleted: (data: IConversationTaskData) => {
      setStateData((prev) => ({...prev, taskLoading: false}));

      if (data && data?.getTasks?.tasks && data?.getTasks?.tasks.length > 0) {
        const sortTaskList= data?.getTasks?.tasks.sort((a) => (a.status?.code === 'completed') ? 1 : -1)
        setStateData((prev) => ({...prev, tasklist: sortTaskList}));
      } else {
        setStateData((prev) => ({...prev, tasklist: []}));
      }
    },
    onError: (error: any) => {
      setStateData((prev) => ({...prev, taskLoading: false, tasklist: []}));

      showErrorPopup();
    },
  });

  const fetchTasks = () => {
    let itemIds: string[] = [];
    GetTaskItems({
      variables: {
        conversationUuid: conversationUuid,
      },
    }).then((res) => {
      if (res?.data?.conversationTaggedItems?.length) {
        itemIds = res.data?.conversationTaggedItems.map((item: any) => {
          return item?.itemId;
        });

        const paramsObject = {
          ids: itemIds || [],
          timezone: getCurrentTimeZone(),
        };
        setStateData((prev) => ({...prev, taskLoading: true}));
        getTasks({
          variables: {
            params: paramsObject,
          },
        });
      } else {
        setStateData((prev) => ({...prev, taskLoading: false, tasklist: []}));
      }
    });
  };

  const [GetTaskItems] = useLazyQuery(TaskQueries.GET_TASK_ITEM_IDS, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    setStateData((prev) => ({...prev, taskLoading: true}));

    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener('NEW_TASK_ADDED', fetchTasks);
    fetchTasks();
  }, [conversationUuid]);

  const limitTask = (tasklist: ITask[]) => {
    const topFiveTask = tasklist.slice(0, 5);
    return topFiveTask;
  };

  const checkIsCompleted = (task: ITask) => {
    if (task.status?.code === TASK_COMPLETED_STATUS_CODE) {
      return true;
    } else {
      return false;
    }
  };

  const openTaskDetailView = (task: ITask) => {
    setTaskDetail({
      show: true,
      task: task,
    });
  };
  const loggedInUser = getUserUUID();

  const handleBottomViewActions = (
    task: ITask,
    action: BottomViewAction,
  ) => {
    setLoadingData((prev) => ({
      ...prev,
      taskIdWithAction: task.id,
    }));
    switch (action) {
      case BottomViewAction.markAsComplete:
        setLoadingData({
          taskIdWithAction: task.id,
          loading: true,
        });
        updateTaskStatus({
          variables: {
            params: {
              id: task.id,
              data: {
                statusId: completedStatusId,
              },
            },
          },
        });
        break;

      default:
        break;
    }
    setTaskDetail((prev)=>{
      return {
        ...prev,
        show: false
      }
    })
  };
  return (
    <View>
      {stateData.taskLoading ? (
        <Skeleton.Text m={2} lines={5} />
      ) : (
        <View>
        {taskDetail.show && (
          <AddOrUpdateTask
            task={taskDetail.task}
            isVisible={taskDetail.show}
            onMarkAsComplete={handleBottomViewActions}
            onComplete={() => {
              setTaskDetail({
                show: false,
                task: undefined,
              });
              fetchTasks() // refetch task list
            }}
            onCancel={() => {
              setTaskDetail({
                show: false,
                task: undefined,
              });
            }}
          />
        )}
          {stateData?.tasklist && stateData?.tasklist.length > 0 ? (
            <View maxH={540} m={2}>
              <ScrollView>
                {limitTask(stateData?.tasklist).map((task: ITask) => {
                  return (
                    <View mr={5} ml={5}>
                    <TaskCard
                      task={task}
                      hideDetails={true}
                      key={task.id}
                      title={task.title}
                      disable={checkIsCompleted(task)}
                      isChecked={checkIsCompleted(task)}
                      description={getDescription(
                        task,
                        task.patientName,
                        descriptionDisplayModes.SHORT,
                        vitals,
                      )}
                      data={task}
                      tag={getCardTag(task)}
                      loading={
                        stateData.taskIdWithAction === task.id
                          ? stateData.loading
                          : false
                      }
                      attachments={
                        task.patientEducations?.map((education) => {
                          return education.patientEducation;
                        }) || []
                      }
                      onPress={() => {
                        openTaskDetailView(task);
                      }}
                      onCheckBoxChange={(isSelected) => {
                        if (isSelected) {
                          setStateData({
                            ...stateData,
                            taskIdWithAction: task.id,
                            loading: true,
                          });
                          updateTaskStatus({
                            variables: {
                              params: {
                                id: task.id,
                                data: {
                                  statusId: completedStatusId,
                                },
                              },
                            },
                          });
                        }
                      }}
                      onAttachmentClick={(patientEducation) => {
                        window.open(patientEducation.url, '_blank', 'noopener,noreferrer');
                      }}
                      onDeleteTaskHandler={handleBottomViewActions}
                    />
                    </View>
                  );
                })}
              </ScrollView>
              {stateData?.tasklist.length > 5 && (
                <HStack
                  mr={2}
                  my={2}
                  justifyContent={'flex-end'}
                  alignItems={'center'}
                  space={3}
                >
                  {stateData?.tasklist.length > 5 && (
                    <Text
                      color={'gray.500'}
                    >{`Showing 5 out of ${stateData?.tasklist.length}`}</Text>
                  )}
                  <Pressable
                    onPress={() => {
                      navigate(`/tasks/my_tasks`, {replace: true});
                    }}
                  >
                    <HStack space={2}>
                      <Text color={'#F37232'} textAlign={'right'}>
                        {'View All'}
                      </Text>
                      <Icon
                        style={styles.icon}
                        name="down"
                        color={'#F37232'}
                        size={14}
                      />
                    </HStack>
                  </Pressable>
                </HStack>
              )}
            </View>
          ) : (
            <VStack mb={2} alignItems={'center'}>
              <Image
                width={'100px'}
                height={'110px'}
                resizeMode={'contain'}
                source={require('../../../../../assets/images/PNGImages/TabsPngIcons/EmptyState.png')}
              />
              <DisplayText
                extraStyles={{color: Colors.Custom.Gray500, fontWeight: 500}}
                textLocalId={'noTask'}
              />
            </VStack>
          )}
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  icon: {
    marginTop: 4,
  },
});