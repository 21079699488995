import {Drawer} from 'antd';
import {getParentIdx, IBlockData} from '@foldhealth/easy-email-core';
import {useBlock, useFocusIdx} from '@foldhealth/easy-email-editor';
import {get} from 'lodash';
import {HStack, Select, Text, View, VStack} from 'native-base';
import React from 'react';
import {BUTTON_TYPE} from '../../../../../constants';
import {Colors} from '../../../../../styles';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import {getTemplateCategoryList} from '../../ContentManagementUtils';
import {CustomBlocksType} from '../../EmailTemplates/CreateOrEditTemplate/CustomBlocks/constants';
import {EmailPreview} from '../../EmailTemplates/EmailPreview';
import {ITemplateCategory} from '../../EmailTemplates/interfaces';
import {
  getComponentTypes,
  getEmailComponents,
  getFormattedEmailComponents,
} from '../EmailComponentsUtils';
import {
  EmailComponentsListDrawerProps,
  SingleEmailComponent,
} from '../interfaces';

const EmailComponentsListDrawer = (props: EmailComponentsListDrawerProps) => {
  const {isOpen, onClose} = props;
  const [state, setState] = React.useState<{
    components: SingleEmailComponent[];
    selected: SingleEmailComponent;
    loading: boolean;
    componentTypes: ITemplateCategory[];
    selectedComponentType: ITemplateCategory;
    componentLoading: boolean;
  }>({
    components: [],
    loading: false,
    selected: {} as SingleEmailComponent,
    componentTypes: [] as ITemplateCategory[],
    selectedComponentType: {} as ITemplateCategory,
    componentLoading: false,
  });

  const {addBlock, values} = useBlock();
  const {focusIdx} = useFocusIdx();

  const handleSubmit = () => {
    let parentIdx = getParentIdx(focusIdx);
    const self = get(values, focusIdx) as IBlockData | null;
    if (focusIdx === 'content') {
      parentIdx = 'content';
    }
    if (parentIdx) {
      const jsonData = {
        ...state.selected.bodyJson,
        type: CustomBlocksType.USER_CREATED,
      };
      const payload = {
        type: CustomBlocksType.USER_CREATED,
        data: {
          value: state?.selected?.bodyJson || {},
        },
        attributes: {
          'background-color': '#ffffff',
          'button-text-color': '#ffffff',
          'button-color': '#414141',
          'title-color': '#222222',
          'description-color': '#222222',
        },
        children: [],
      };
      if (self?.type != CustomBlocksType.USER_CREATED) {
        addBlock({
          type: CustomBlocksType.USER_CREATED,
          parentIdx: parentIdx,
          positionIndex: undefined,
          payload: jsonData,
        });
      } else {
        addBlock({
          type: CustomBlocksType.USER_CREATED,
          parentIdx: parentIdx,
          positionIndex: undefined,
          payload: jsonData,
          canReplace: true,
        });
      }
    }
  };

  const getInitialData = async () => {
    setState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    try {
      const componentTypesResponse = await getComponentTypes();
      const componentTypes = getTemplateCategoryList(componentTypesResponse);
      const firstComponentCode =
        componentTypes.length > 0 ? componentTypes[0].code : '';
      const componentsResponse = await getEmailComponents({
        categoryQueryString: firstComponentCode,
      });
      const components = getFormattedEmailComponents({
        response: componentsResponse,
      });
      const selected =
        components.length > 0 ? components[0] : ({} as SingleEmailComponent);
      const selectedComponentType =
        componentTypes.length > 0
          ? componentTypes[0]
          : ({} as ITemplateCategory);
      setState((prev) => {
        return {
          ...prev,
          loading: false,
          components,
          componentTypes,
          selected,
          selectedComponentType,
        };
      });
    } catch (error) {
      setState((prev) => {
        return {
          ...prev,
          loading: false,
          components: [],
          componentTypes: [],
        };
      });
    }
  };

  const getComponentsBySelectedCategory = async () => {
    setState((prev) => {
      return {
        ...prev,
        componentLoading: true,
      };
    });
    try {
      const response = await getEmailComponents({
        categoryQueryString: `component_types=${
          state.selectedComponentType?.code || ''
        }`,
      });
      const components = getFormattedEmailComponents({response});
      const selected =
        components.length > 0 ? components[0] : ({} as SingleEmailComponent);
      setState((prev) => {
        return {
          ...prev,
          componentLoading: false,
          components,
          selected,
        };
      });
    } catch (error) {
      setState((prev) => {
        return {
          ...prev,
          componentLoading: false,
          components: [],
        };
      });
    }
  };

  React.useEffect(() => {
    getInitialData();
  }, []);

  React.useEffect(() => {
    getComponentsBySelectedCategory();
  }, [state.selectedComponentType?.code]);

  return (
    <Drawer
      title={
        <ModalActionTitle
          title={'Select Component'}
          titleColor={''}
          buttonList={[
            {
              show: true,
              id: 1,
              btnText: 'cancel',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.SECONDARY,
              isTransBtn: false,
              onClick: () => {
                onClose();
              },
            },
            {
              show: true,
              id: 2,
              btnText: 'submit',
              textColor: Colors.Custom.mainPrimaryPurple,
              variant: BUTTON_TYPE.PRIMARY,
              isTransBtn: false,
              onClick: () => {
                handleSubmit();
                onClose();
              },
            },
          ]}
        />
      }
      width={'50vw'}
      open={isOpen}
      onClose={() => onClose()}
    >
      <VStack
        style={{
          flex: 1,
        }}
        space="4"
      >
        <VStack
          style={{
            width: '100%',
            justifyContent: 'space-between',
          }}
          space="3"
        >
          <VStack
            style={{
              width: '60%',
            }}
          >
            <View
              style={{
                marginVertical: 8,
              }}
            >
              <Text>Type</Text>
            </View>
            <Select
              selectedValue={state.selectedComponentType.code}
              onValueChange={(code) => {
                const selectedComponentType =
                  state.componentTypes.find((item) => item.code == code) ||
                  ({} as ITemplateCategory);
                setState((prev) => {
                  return {
                    ...prev,
                    selectedComponentType,
                  };
                });
              }}
            >
              {state.componentTypes.map((item) => {
                return <Select.Item label={item.name} value={item.code} />;
              })}
            </Select>
          </VStack>
          <VStack
            style={{
              width: '60%',
            }}
          >
            <View
              style={{
                marginVertical: 8,
              }}
            >
              <Text>Component</Text>
            </View>
            <Select
              selectedValue={state.selected?.id?.toString()}
              onValueChange={(id) => {
                const selected =
                  state.components.find((item) => item.id?.toString() == id) ||
                  ({} as SingleEmailComponent);
                setState((prev) => {
                  return {
                    ...prev,
                    selected,
                  };
                });
              }}
            >
              {state.components.map((item) => {
                return (
                  <Select.Item label={item.name} value={item.id?.toString()} />
                );
              })}
            </Select>
          </VStack>
        </VStack>

        <VStack
          style={{
            flex: 1,
          }}
        >
          <View
            style={{
              marginVertical: 8,
            }}
          >
            <Text>Preview</Text>
          </View>

          <EmailPreview
            containerStyle={{
              padding: 0,
            }}
            htmlString={state.selected?.bodyHtml || ''}
          />
        </VStack>
      </VStack>
    </Drawer>
  );
};

export default EmailComponentsListDrawer;
