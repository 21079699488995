import React, {useState} from 'react';
import {Image} from 'antd';
import {IImgAttachmentProps} from './interfaces';
import {Center, Spinner} from 'native-base';
import { TestIdentifiers, testID } from '../../../../testUtils';

const ImgAttachment: React.FC<IImgAttachmentProps> = (props) => {
  const {attachment} = props;
  const [isImgLoaded, setIsImgLoaded] = useState(false);
  const getImgElement = (): JSX.Element => {
    return (
      <Image
        src={attachment.thumb_url}
        preview={{
          src: attachment.data_url,
        }}
        onLoad={() => {
          setIsImgLoaded(true);
        }}
        alt={'image'}
        height={props?.height || 200}
        width={props?.width || 300}
      />
    );
  };

  return (
    <>
      {isImgLoaded ? (
        <></>
      ) : (
        <Center {...testID(TestIdentifiers.imageLoading)}>
          <Spinner />
        </Center>
      )}
      {getImgElement()}
    </>
  );
};

export default ImgAttachment;
