import React from 'react';
// Views
import {Button,Divider,HStack,IToastProps,Icon,Pressable,Spinner,Text,VStack,View} from 'native-base';
import {useContext} from 'react';
import {IntlShape} from 'react-intl';
// import {TagView} from '../../Table/TagView';
import {Checkbox,Popover,Tooltip, notification} from 'antd';
import moment from 'moment';
import Feather from 'react-native-vector-icons/Feather';
import {Colors} from '../../../../styles';
import {getCurrentTimeZone,getDateStrFromFormat,getEndOfDay,getISODateFromDisplayDate,getNextDateStartOfDay,getPreviousDateEndOfDay,getStartOfDay} from '../../../../utils/DateUtils';
import {
  BottomViewAction,
  getPriorityIcon,
  renderResourceMapCountAndDeleteField,
} from '../../../TaskCard/TaskCardHelper';
import {isAllowToDeleteTask} from '../../../TaskCard/TaskHelper';
import UserAutoComplete,{
  IUserSearch,
} from '../../CalendarWidget/UserAutoComplete/UserAutoComplete';
import PatientSearchAndSelect from '../../PatientSearch/PatientSearchAndSelect';
import {ITask, ITaskActionParams} from '../CareDashboardInterfaces';
import {IUserPool} from '../CareDashboardWidget/UserAutoComplete';


import {LazyQueryExecFunction,OperationVariables} from '@apollo/client';
import {ColumnsType} from 'antd/es/table';
import AntDesign from 'react-native-vector-icons/AntDesign';
import {IMlov,IUserRoleCode,IVitalData} from '../../../../Interfaces/CommonInterfaces';
import {MLOV_CATEGORY,TASK_PRIORITY_CODES,TASK_STATUS,TASK_STATUS_CODES} from '../../../../constants/MlovConst';
import {CommonDataContext,ICommonData} from '../../../../context/CommonDataContext';
import {isEmployerRole} from '../../../../utils/commonUtils';
import {ToastType,showToast} from '../../../../utils/commonViewUtils';
import {getMlovId,getMlovIdFromCode,getMlovListFromCategory} from '../../../../utils/mlovUtils';
import {TaskPanelType} from '../../../TaskCard/TaskEnum';
import {ParticipantType} from '../../CalendarWidget/ParticipantAutoComplete/ParticipantEnum';
import SubtasksSvg from '../../Svg/SubtasksSvg';
import {ITopBarData} from '../CareDashboardTopBar/CareDashboardTopBar';
import {dateRangeStatusUuid} from '../CareDashboardTopBar/FilterDrawerView';
import {BoardType} from '../CareDashboardTopBar/interfaces';
import {PropertyType,getColumnName,getCompletedTaskStatusId} from '../CareDashboardUtils/CareDashboardUtils';
import {LabelDataListView} from '../CareDashboardWidget/LabelDataListView';
import CareDashboardTableActions from './CareDashboardTableActions';
import { DATE_FORMATS } from '../../../../constants';
import UserIcon from '../../../../assets/Icons/UserIcon';
import AutomationIcon from '../../../../assets/Icons/AutomationIcon';
import JourneyIcon from '../../../../assets/Icons/JourneyIcon';
import { TASK_TABS } from '../CareDashboardConstants';
import NoteLinkIcon from '../../../../assets/Icons/NoteLinkIcon';
import { SOURCE_MAP } from '../../AddTask/AddTaskUtils';
import TrashBin2Icon from '../../../../assets/Icons/TrashBin2Icon';
import ReadMsgSvg from '../../Svg/ReadMsgSvg';
import ClockIcon from '../../../../assets/Icons/ClockIcon';
import MissedCallSvg from '../../Svg/ConversationAllSvg/MissedCallSvg';
import Missed from '../../../../assets/Icons/Missed';
import ReadMsgSvg2024 from '../../Svg/ReadMsgSvg2024';
import TrashBinIcon2024 from '../../../../assets/Icons/TrashBinIcon2024';
import ClockIcon2024 from '../../../../assets/Icons/ClockIcon2024';
import MissedTaskIcon2024 from '../../../../assets/Icons/MissedTaskIcon2024';
import { StyleSheet } from 'react-native';
import { hasMatchingLocation } from '../../AddTask/CommonUtilsForWebAndNative';

const TASK_STATUS_FILTER_CODES = {
  PENDING: 'Pending',
  COMPLETED: 'Completed',
  ALL: 'All',
};
const TASK_STATUS_FILTER_VALUES = {
  PENDING: 'Not Completed',
  COMPLETED: 'Completed',
  MISSED: 'Missed',
}


export const TASK_ASSIGNEE_TYPE_CODES = {
  User: 'USER',
  Contact: 'CONTACT',
}


export const MAX_PAGES_ALLOWED_IN_LIST = 2;
export const PAGE_SIZE = 25;

const isTaskCompleted = (task: ITask): boolean => {
  if (task.status && task.status.value) {
    return task.status.value === 'Completed'
  }
  return false;
}

const getTaskAssignee = (
  task: ITask,
  userList: {
    id: string;
    name: string;
    uuid: string;
    email: string;
  }[]
) => {
  const assigneeType = task.assigneeTypeCode;
  switch (assigneeType) {
    case TASK_ASSIGNEE_TYPE_CODES.User:
      const assigneeValue = userList
          .filter((user: any) => user.uuid === task?.assigneeId)
          .map((user: any) => ({
            value: user.uuid,
            label: user.name,
            key: user.uuid,
          }));
      return assigneeValue[0];
    case TASK_ASSIGNEE_TYPE_CODES.Contact:
        return {
          value: task?.assigneeUser?.uuid,
          label: task?.assigneeUser?.name,
          key: task?.assigneeUser?.uuid,
        }
  }
  return '';
};

export const getMatchActiveUserById = (userId: string, accountUserList?: any[]): any => {
  return (accountUserList || []).find(user => {
    return user?.uuid === userId;
  });
}

export const getUserObjectForDisplay = (selectedUser: any) => {
  return {
    key: selectedUser?.uuid,
    value: selectedUser?.uuid,
    label: selectedUser?.name,
    contactData: selectedUser,
    type: ParticipantType?.staff
  }
}

export const filterActiveUsersAndDefaultAssigneeOfPool = (
  pool: any,
  userList: { id: string; name: string; uuid: string; email: string; }[],
  selectedAccountLocationId: string,
  isMultiTenancyEnabled: boolean,
  isAllowedAllLocation: boolean,
) => {
  const selectedPoolUsers: any[] = [];
  let defaultAssignee: any = undefined;
  if (pool?.id && pool?.userPoolUsers?.length) {
    (pool?.userPoolUsers || []).forEach((user: any) => {
      const userPracticeLocations = user?.user?.userPracticeLocations || [];
      const selectedUser = getMatchActiveUserById(user?.userId, userList);
      const isUserEligible = (selectedUser?.userId || selectedUser?.uuid) && (
        !isMultiTenancyEnabled ||
        isAllowedAllLocation ||
        hasMatchingLocation(userPracticeLocations, undefined, selectedAccountLocationId)
      );
      if (isUserEligible) {
        const userDisplayObject = getUserObjectForDisplay(selectedUser);
        if (user.isDefault) {
          defaultAssignee = userDisplayObject;
        } else {
          selectedPoolUsers.push(userDisplayObject);
        }
      }
    });
  }
  return {
    defaultAssignee,
    selectedPoolUsers,
  };
}

export const isAssigneePartOfPool = (userId: string, poolId: string, userPools: IUserPool[] | undefined) => {
  return (userPools || []).some(pool => {
    if (pool?.id === poolId && pool?.userPoolUsers?.length) {
      return (pool?.userPoolUsers || []).some(user => {
        return user?.userId === userId;
      });
    }
    return false;
  })
};

export const isAllowToEditTask = (task: ITask | any) => {
  const isCareJourneyOrFormTask  = task?.referenceData?.careJourneyId || task?.referenceData?.formId || task?.referenceData?.entityType == 'FORM';
  return task?.id && !isCareJourneyOrFormTask ? true : false;
}

export const isAllowMarkAsComplete = (task: ITask | any) => {
  if (!task?.id) {
    return false;
  }
  // if task is not completed then any one can mark it as completed
  return !(task?.isCompleted || isTaskCompleted(task));
}

const styles = StyleSheet.create({
  titleText: {
    color: Colors.Custom.Gray500,
  },
  assigneeContainer: {
    marginTop: 7,
    marginLeft: 4,
    width: '100%',
  },
  assignedByTitle: {
    color: Colors.Custom.Gray500,
    marginLeft: 8,
  },
  actionsTitle: {
    color: Colors.Custom.Gray500,
    marginLeft: 8,
  },
  deleteButton: {
    width: 24,
    height: 24,
  },
});

export function getFetchTaskParams(params: {
  commonDataContext: ICommonData;
  topBarData: ITopBarData;
  isPatientTask: boolean;
  personData: any;
  currentUserId: string;
  offset: number;
  columnCode: string;
  patientTaskFilters: any;
  userPoolId?: string;
  pageSize?:number;
  isWithoutPagination?: boolean;
  searchString?: string;
}) {
  const isFromPatientTaskPanel = params.topBarData.panelType === TaskPanelType.PATIENT;
  const isFromPatientContextTaskPanel = params.topBarData.panelType === TaskPanelType.INTERNAL;
  const completedStatusUuid = getMlovId(
    params.commonDataContext.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.TASK_STATUS,
    TASK_STATUS.COMPLETED,
  );
  const missedStatusUuid = getMlovId(
    params.commonDataContext.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.TASK_STATUS,
    TASK_STATUS.MISSED,
  );

  const taskStatusMlovList = getMlovListFromCategory(params.commonDataContext.CARE_STUDIO_MLOV, MLOV_CATEGORY.TASK_STATUS) || [];
  const taskPriorityMlovList = getMlovListFromCategory(params.commonDataContext.CARE_STUDIO_MLOV, MLOV_CATEGORY.TASK_PRIORITY) || [];

  const selectedSortObject: any = {};
  const selectedSortUuid = params.topBarData.selectedSortUuid || '';
  const selectedMentionsSortUuid = params.topBarData.selectedMentionsSortUuid || '';
  const isCreatedByView = params.columnCode === TASK_TABS.CREATED;
  const isMentionsTaskView = params.columnCode === TASK_TABS.MENTIONED;
  const isAssignedTaskView = params.topBarData.selectedTab === TASK_TABS.ASSIGNED;
  const isTaskPoolView = !!params.userPoolId;

  if (!isMentionsTaskView && params.topBarData.selectedSortUuid && params.topBarData.selectedSortUuid !== 'none') {
    selectedSortObject[selectedSortUuid] = params.topBarData.selectedSortValueAscending ? 'asc' : 'desc';
  }
  if(isMentionsTaskView && params.topBarData.selectedMentionsSortUuid && params.topBarData.selectedMentionsSortUuid !== 'none'){
      selectedSortObject[selectedMentionsSortUuid] = params.topBarData.selectedMentionsSortValueAscending ? 'asc' : 'desc';
  }

  let assigneeIds:(string | null)[] = [];
  let createdByIds:(string | null)[] = [];
  let mentionedUserIds:(string | null)[] = [];

  if (params.isPatientTask) {
    if (params.personData?.contactUUID) {
      assigneeIds.push(params.personData.contactUUID)
    }
  } else {
    assigneeIds = params?.topBarData?.selectedUsers?.map((user)=> {
      if (user?.key === 'null') {
        return null
      }
      return user?.key
    }) || [];
    createdByIds = params?.topBarData?.createdByIds?.map((user)=> {
      if (user?.key === 'null') {
        return null
      }
      return user?.key
    }) || [];
    mentionedUserIds = params?.topBarData?.mentionUserIds?.map((user)=> {
      if (user?.key === 'null') {
        return null
      }
      return user?.key
    }) || [];
  }

  if (!assigneeIds?.length && !isCreatedByView) {
    throw new Error();
  } else {
    let paramsObject: any = {};

    let apiDateRange = getISODateFromDisplayDate(params.topBarData);

    if (params.isPatientTask) {
      if (params?.patientTaskFilters?.formData) {
        apiDateRange = getISODateFromDisplayDate(params?.patientTaskFilters?.formData);
      }
      paramsObject = {
        assigneeIds: isFromPatientTaskPanel ? assigneeIds : [],
        contactIds: isFromPatientContextTaskPanel ? assigneeIds : [],
        ...(isFromPatientContextTaskPanel && {
          assigneeIdsNotIn: assigneeIds,
        }),
        ...(apiDateRange?.start && { startDateTime: apiDateRange.start }),
        ...(apiDateRange?.end && { endDateTime: apiDateRange.end }),
        orderBy: selectedSortObject,
        offset: params.offset,
        limit: params?.pageSize || PAGE_SIZE,
        timezone: getCurrentTimeZone(),
        taskDueDateCategoryIds: params?.patientTaskFilters?.formData?.selectedDueDateUuid,
      };

      if (params.patientTaskFilters?.formData?.selectedPriorityTypes?.length) {
        paramsObject.priorityIds = params.patientTaskFilters.formData.selectedPriorityTypes?.map((mlov: any) => mlov.id) || [];
      }
      if (params.patientTaskFilters?.formData?.selectedTaskEntityTypes?.length) {
        paramsObject.entityTypeIds = params.patientTaskFilters.formData.selectedTaskEntityTypes?.map((mlov: any) => mlov.id) || [];
      }
      if (params.patientTaskFilters?.formData?.selectedStatusTypes?.length) {
        paramsObject.statusIds = params.patientTaskFilters.formData.selectedStatusTypes?.map((mlov: any) => mlov.id) || [];
      }
      if (!params?.patientTaskFilters?.formData?.selectedDueDateUuid?.includes(dateRangeStatusUuid)) {
        delete paramsObject?.startDateTime;
        delete paramsObject?.endDateTime;
      } else {
        paramsObject.taskDueDateCategoryIds = (paramsObject as any).taskDueDateCategoryIds.filter((id: string) => id !== dateRangeStatusUuid)
      }

      // props.filter?.formData?.selectedPriorityTypes,
      // props.filter?.formData?.selectedTaskEntityTypes,
      // props.filter?.formData?.selectedStatusTypes,
    } else {
      paramsObject = {
        startDateTime: apiDateRange.start,
        endDateTime: apiDateRange.end,
        assigneeIds: assigneeIds,
        ...( isCreatedByView && { createdByIds: createdByIds } ),
        ...(isTaskPoolView && { userPoolIds: [ params.userPoolId ] }),
        ...(isMentionsTaskView && { mentionedUserOrContactIds: mentionedUserIds}),
        statusIds: params.topBarData.selectedStatusList?.map?.((mlov) => mlov.id) || [],
        taskDueDateCategoryIds: params.topBarData.selectedDueDateUuid,
        priorityIds: params.topBarData.selectedPriorityList?.map?.((mlov) => mlov.id) || [],
        contactIds:
          params.topBarData.selectedContactUuid && params.topBarData.selectedContactUuid.length
            ? params.topBarData.selectedContactUuid.map(
                (contactItem: any) => contactItem.value
              )
            : [],
        offset: params.offset,
        limit: params?.pageSize || PAGE_SIZE,
        orderBy: selectedSortObject,
        timezone: getCurrentTimeZone(),
      };

      // if selected status type is requested then we will pass completed uuid in statusIdsNotIn key
      // that means requested = not completed task
      // if (params.topBarData.selectedStatusList?.length === 1 && params.topBarData.selectedStatusList[0]?.code === TASK_STATUS.ACCEPTED) {
      // // if (props.selectedStatusType === requestedStatusValue) {
      //   delete (paramsObject as any).statusIds;
      //   (paramsObject as any).statusIdsNotIn = [completedStatusUuid,missedStatusUuid];
      // } else if (params.topBarData.selectedStatusList?.length === 1 && params.topBarData.selectedStatusList[0]?.code === TASK_STATUS.COMPLETED) {
      //   paramsObject.statusIds = [ completedStatusUuid ];
      // } else if (params.topBarData.selectedStatusList?.length === 1 && params.topBarData.selectedStatusList[0]?.code === TASK_STATUS.MISSED) {
      //   delete (paramsObject as any).statusIdsNotIn;
      //   paramsObject.statusIds = [ params.topBarData.selectedStatusList[0]?.id ];
      // } else
      if ((params.topBarData.selectedStatusList?.length || 0) > 0) {
        delete (paramsObject as any).statusIdsNotIn;
        paramsObject.statusIds = params.topBarData.selectedStatusList?.map?.((mlov) => mlov.id) || []
      } else {
        delete (paramsObject as any).statusIds;
      }

      // Selected Due Date value is not date range then do not pass startDateTime and EndDateTime Filter
      if (!params.topBarData.selectedDueDateUuid?.includes(dateRangeStatusUuid)) {
        delete paramsObject.startDateTime;
        delete paramsObject.endDateTime;
      } else {
        paramsObject.taskDueDateCategoryIds = (paramsObject as any).taskDueDateCategoryIds.filter((id: string) => id !== dateRangeStatusUuid)
      }
    }

    if (!isTaskPoolView && params.topBarData.selectedPools?.length) {
      paramsObject.userPoolIds = params.topBarData.selectedPools?.map((item) => item.value).filter((item?: string) => !!item);
    }

    if (params.columnCode) {
      if (params.isPatientTask) {
        const statusId = getMlovIdFromCode(taskStatusMlovList, params.columnCode);
        if (statusId) {
          // if (!paramsObject.statusIds?.length) {
          //   paramsObject.statusIds = [];
          // }
          paramsObject.statusIds = [];
          paramsObject.statusIds.push(statusId);
        }
      } else if (isAssignedTaskView) {
        if (params.topBarData.selectedBoardType === BoardType.Status) {
          // if (!paramsObject.statusIds?.length) {
          //   paramsObject.statusIds = [];
          // }
          paramsObject.statusIds = [];
          const statusId = getMlovIdFromCode(taskStatusMlovList, params.columnCode);
          if (statusId) {
            paramsObject.statusIds.push(statusId);
          }
        } else if (params.topBarData.selectedBoardType === BoardType.Priority) {
          // if (!paramsObject.priorityIds?.length) {
          //   paramsObject.priorityIds = [];
          // }
          paramsObject.priorityIds = [];
          const priorityId = getMlovIdFromCode(taskPriorityMlovList, params.columnCode);
          if (priorityId) {
            paramsObject.priorityIds.push(priorityId);
          }
        } else if (params.topBarData.selectedBoardType === BoardType.dueDate) {
          delete paramsObject.taskDueDateCategoryIds;
          if(params.columnCode === 'past') {
            paramsObject.endDateTime = getPreviousDateEndOfDay();
            delete paramsObject.startDateTime;
          }
          else if(params.columnCode === 'today') {
            paramsObject.startDateTime = getStartOfDay()?.toISOString();
            paramsObject.endDateTime = getEndOfDay()?.toISOString();
          }
          else if(params.columnCode === 'future') {
            paramsObject.startDateTime = getNextDateStartOfDay();
            delete paramsObject.endDateTime;
          }
        }
      }
    }

    if (params.topBarData?.labels?.length) {
      paramsObject.labels = params.topBarData?.labels?.map(
        (item) => item?.uuid || ''
      ) || [];
    }
    if (params.searchString) {
      paramsObject.searchString = params.searchString;
    }

    if (params.isWithoutPagination) {
      delete paramsObject.offset;
      delete paramsObject.limit;
    }

    return paramsObject;
  }
}


export const getAssignedByType = (task: ITask, intl: IntlShape) => {
  let icon = <></>;
  let name = "";
  // is automation task if assignedBy is not present and careJourney is not present
  if (!task?.assignedByUser?.name && !task?.patientCareJourney?.careJourney?.id) {
    name = intl.formatMessage({id: 'automation'});
    icon = <AutomationIcon />;
  }
  // is care journey task if careJourney is present
  if (task?.patientCareJourney?.careJourney?.id) {
    name = task?.patientCareJourney?.careJourney?.title || '';
    icon = <JourneyIcon  />;
  }
  // is created by some user if assignedBy is present
  if (task?.assignedByUser?.name) {
    name = task?.assignedByUser?.name || '';
    icon = <UserIcon />;
  }

  return {
    icon,
    name,
  };
}

export const getIconByAction = (action: string)=> {
  switch (action) {
    case TASK_STATUS_CODES.DELETE:
      return <TrashBinIcon2024 />;
    case TASK_STATUS_CODES.COMPLETED:
      return <ReadMsgSvg2024 />
    case TASK_STATUS_CODES.ACCEPTED:
      return <ClockIcon2024 />
    case TASK_STATUS_CODES.MISSED:
      return <MissedTaskIcon2024 />
    default:
      return '';
  }
}
