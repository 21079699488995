import React from 'react';
import { Svg, Path } from 'react-native-svg';
import { ICommonSvgProps } from '../interfaces';
import { Colors } from '../../../../styles';

const SearchIconSvg = (props: ICommonSvgProps) => {
  return (
    <Svg
      width={props.size || "20"}
      height={props.size || "20"}
      fill="none"
      viewBox="0 0 20 20"
    >
      <Path
        stroke={props.customStrokeColor || Colors.FoldPixel.GRAY300}
        strokeLinecap="round"
        d="M15.85 15.85L19 19m-.9-9.45a8.55 8.55 0 11-17.1 0 8.55 8.55 0 0117.1 0z"
      ></Path>
    </Svg>
  );
};

export default SearchIconSvg;
