import React from 'react';
import { isWeb } from '../../../utils/platformCheckUtils';
import { Image } from 'native-base';

const MailCircleSvg = () => {

    return (
        <>
        {isWeb() ? (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.62 6.21548C17.38 5.97581 17.05 5.83601 16.71 5.83601H14.54V10.6992C14.54 11.3083 14.3 11.8775 13.87 12.3169C13.44 12.7463 12.87 12.986 12.26 12.986H6.45V13.5352C6.45 13.8747 6.59 14.2042 6.83 14.4439C7.07 14.6836 7.4 14.8234 7.74 14.8234H15.44L17.54 16.9204C17.71 17.0902 18 16.9703 18 16.7307V7.1242C18 6.78468 17.86 6.45514 17.62 6.21548Z" fill="#D0D5DD" />
            <path d="M13.17 11.6079C13.41 11.3682 13.55 11.0387 13.55 10.6992V4.28819C13.55 3.94867 13.41 3.61913 13.17 3.37947C12.93 3.1398 12.6 3 12.26 3H3.28C2.94 3.00999 2.62 3.14979 2.38 3.38945C2.14 3.62912 2 3.95865 2 4.29817V13.8847C2 14.1244 2.29 14.2442 2.46 14.0744L4.56 11.9774H12.26C12.6 11.9774 12.93 11.8376 13.17 11.5979V11.6079Z" fill="#D0D5DD" />
        </svg>
        ) : (
            <Image
                size={'6'}
                source={require('../../../assets/images/MessageActionImages/smsBgIcon.png')}
                alt="aa"
            />
        )}
    </>
    );
};

export default MailCircleSvg;
