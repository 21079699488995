import {HStack, Spacer, VStack, View, Divider} from 'native-base';
import React, {useState} from 'react';
import {
  getMomentObjectWithDateStringAndFormat,
  isFutureDate,
} from '../../../../../../../utils/DateUtils';
import {DisplayText} from '../../../../../../common/DisplayText/DisplayText';
import {IImmunization} from '../../Immunizations/interfaces';
import CustomButton from '../CustomButton/CustomButton';

interface ICompactListViewMoreDetailsForImmunizationProps {
  dataSource: any[];
  rowViewForItem: (item: any, index: number) => void;
  noDataTextLabel: string;
  viewDataText: string;
  onViewMoreClick?: () => void;
  occurrenceDateFormat: any;
  showMoreMaxCount: number;
  hasPatientReportedRecords?: boolean;
  onViewPatientReportedClick?: () => void;
}

const CompactListViewMoreDetailsForImmunization = (
  props: ICompactListViewMoreDetailsForImmunizationProps
) => {
  const {
    dataSource,
    rowViewForItem,
    noDataTextLabel,
    viewDataText,
    occurrenceDateFormat,
    hasPatientReportedRecords,
    onViewPatientReportedClick
  } = props;

  const [showMore, setShowMore] = useState(false);

  return (
    <VStack marginTop={2} marginX={4}>
      {hasPatientReportedRecords && (
        <HStack marginBottom={4} marginTop={2}>
          <CustomButton
              onPress={() => {
                if (onViewPatientReportedClick) {
                  onViewPatientReportedClick();
                }
              }}
              title= {'View Patient Reported Records'}
              />
          <Spacer />
        </HStack>
      )}
      <VStack space={2}>
        <VStack space={4}>
          {!dataSource.length && !hasPatientReportedRecords && (
            <View marginLeft={2} mb={2}>
              <DisplayText
                extraStyles={{
                  color: '#667085',
                }}
                textLocalId={noDataTextLabel}
                textType="Text"
              />
            </View>
          )}

          {dataSource.map((item, index) => {
            return (
              <VStack key={item.id || index}>
                <View paddingBottom={3}>{rowViewForItem(item, index)}</View>
                {index !== dataSource.length - 1 && (<Divider />)}
              </VStack>
            );
          })}
        </VStack>

        {/* Past Immunization Data  */}
        {false && (
          <HStack>
            <CustomButton
              onPress={() => {
                props.onViewMoreClick
                  ? props.onViewMoreClick()
                  : setShowMore(!showMore);
              }}
              title= {showMore
                ? `Hide ${viewDataText}`
                : `View ${viewDataText}`}
              />
            <Spacer />
          </HStack>
        )}
      </VStack>
    </VStack>
  );
};

export default CompactListViewMoreDetailsForImmunization;
