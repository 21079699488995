import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Alert, Drawer, InputNumber, Steps, Select as AntSelect, Tooltip } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {
    Center,
    FormControl,
    HStack,
    Icon,
    Image,
    Pressable,
    Spacer,
    Spinner,
    Text,
    useMediaQuery,
    useToast,
    View,
    VStack,
    Select as NBSelect,
    CheckIcon,
    Divider,
} from 'native-base';
import { useCallback, useContext, useEffect, useState } from 'react';
import {
  ACCOUNTS_AND_ROLES_ACTION_CONST,
    BUTTON_TYPE,
    DATE_FORMATS,
    DISPLAY_DATE_FORMAT,
    IPAD_MINI_WIDTH,
    IPAD_WIDTH,
    MLOV_CATEGORY,
    PARENT_CODE,
} from '../../../../../constants';
import { FHIR_RESOURCE, KEY_ALLOWED_OPERATIONS } from '../../../../../constants/FhirConstant';
import { WEB_SOCKET_EVENT_CODE } from '../../../../../constants/WebSocketConst';
import { CommonDataContext } from '../../../../../context/CommonDataContext';
import { IMlov } from '../../../../../Interfaces';
import { getPractitionersData } from '../../../../../services/CommonService/AidBoxService';
import CommonService from '../../../../../services/CommonService/CommonService';
import UserPracticeLocationQueries from '../../../../../services/Location/UserPracticeLocationQueries';
import UserQueries, { ADD_CONTACT_DETAILS, ADD_PERSON_DATA, CREATE_ADDRESS, CREATE_PERSON_ADDRESS_DETAILS, CREATE_PERSON_CONTACT_DETAILS, CREATE_PROVIDER_IDENTIFIERS, CREATE_UPDATE_SINGLE_USER, GET_SINGLE_USER } from '../../../../../services/User/UserQueries';
import { Colors } from '../../../../../styles';
import { getEHRName, getGenderIdentityLabel, getResourceAbilities, getSexAtBirthTransformValues, isFoldEhr, isOrderTypeEnabled } from '../../../../../utils/capabilityUtils';
import { showToast, ToastType } from '../../../../../utils/commonViewUtils';
import { EventBus } from '../../../../../utils/EventBus';
import LocalStorage from '../../../../../utils/LocalStorage';
import { getMlovIdFromCode, getMlovIdFromCodeAndCategory, getMlovListFromCategory } from '../../../../../utils/mlovUtils';
import ModalActionBtn from '../../../../common/ModalActionBtn/ModalActionBtn';
import { ModalActionAntSelect } from '../../../../common/ModalActionCommonComponent/ModalActionAntSelect';
import { ModalActionInput } from '../../../../common/ModalActionCommonComponent/ModalActionInput';
import { ModalActionSelect } from '../../../../common/ModalActionCommonComponent/ModalActionSelect';
import { ModalActionTitle } from '../../../../common/ModalActionTitle/ModalActionTitle';
import { ProfileUpload } from '../../../../common/ProfileUpload/ProfileUpload';
import { AddOrUpdateAvailabilitiesCard } from '../../../AccountSettings/PracticeAvailabilityNew/AddOrUpdateAvailabilitiesCard';
import { IAccountLocation, IUser, IUserRole, IUserRoleMap } from '../interfaces';
import { ehrCodeDisplay, PractitionerData } from '../InviteUserDrawer/interfaces';
import {
    updateUserRolesAndAccount,
} from '../InviteUserDrawer/InviteUserDrawerUtils';
import { styles } from './AddEditUserStyles';
import {
  getContactDetailsDataForPost,
    getFirstAndLastNameFromUserName,
    getFormattedUserData,
    getPostData,
    getProviderIdentifierDataForPost,
    getUserCategoriesQueryObj,
    processContactDetails,
    processProviderIdentifierDetails,
    validates,
} from './addEditUsersUtils';
import {
    checkAvailabilities,
    createPracticeLocationArray,
    getAccountLocationsBySelectedLocationUUids,
    getLocationsToAddOrUpdate,
    getLocationsToDelete,
    getRoleIdsToAddOrUpdate,
    getRoleIdsToDelete,
    getUserRoleIdsToAddOrUpdate,
    getUserRoleIdsToDelete,
    updatePracticeLocationArray,
} from './AddEditUserUtils';
import { ContactDetailState, IAccountUser, IAddEditUserProps, IComponentStateState, IdentifierTypeState, Person, User } from './interfaces';
import { IAddOrUpdateAvailability, IAvailabilitiesByDaysOfWeek, IAvailabilityCalendarData, IPracticeAvailability, IUserAvailability } from '../../../AccountSettings/PracticeAvailabilityNew/PracticeAvailabilityInterfaces';
import { getFormattedDate, getMomentObj, isFutureDate } from '../../../../../utils/DateUtils';
import { areAvailabilitiesInvalid, createAvailabilityCalendarGroupData, getDefaultTimezone, groupAvailabilitiesByDaysOfWeek, isAvailabilitiesPresent } from '../../../AccountSettings/PracticeAvailabilityNew/PracticeAvailabilityHelper';
import { ITimezone } from '../../../../../services/Location/interfaces';
import { AVAILABILITY_TYPE_CODES, USER_ROLE_CODES } from '../../../../../constants/MlovConst';
import { CARESTUDIO_APOLLO_CONTEXT, CRM_BASE_URL } from '../../../../../constants/Configs';
import ScheduleQueries from '../../../../../services/Schedule/ScheduleQueries';
import { v4 as uuidV4 } from 'uuid';
import { VIRTUAL_LOCATION_CODE } from '../../../AccountSettings/PracticeAvailabilityNew/PracticeAvailability';
import { useIntl } from 'react-intl';
import { TEAM_MEMBERS_HANDLE_CODE, TEAM_MEMBERS_ROLES_CODE } from './TeamMemberConst';
import { uploadImageService } from './UpdateProfileService';
import { extractAccountLocationsFromUserPracticeLocations, getAccountId, getAccountUUID, getFoldAccessToken, getUserRoleExistByRoleCode, isEmployerRole, isMasterAccount, numericStringMask, unmaskPhoneNumber } from '../../../../../utils/commonUtils';
import { SIGNATURE_COMPONENT } from './constants';
import { dataURItoBlob } from '../../../Forms/FHFormio/Utils';
import { addOrUpdateSignature } from './Queries';
import { debounce , isEmpty} from 'lodash';
import BaseService from '../../../../../services/CommonService/BaseService';
import { OrderType } from '../../../../PersonOmniView/MiddleContainer/Orders/OrdersAndReports/OrderConstants';
import SkeletonLoader from './components/SkeletonLoader';
import { ModalActionSubTitle } from '../../../../common/ModalActionTitle/ModalActionSubTitle';
import { ModalActionDatePicker } from '../../../../common/ModalActionCommonComponent/ModalActionDatePicker';
import { DisplayText } from '../../../../common/DisplayText/DisplayText';
import { ZipCodeAutoComplete } from '../../../../common/ZipCodeComponent';
import { IZipCodeData } from '../../../../common/ZipCodeComponent/interfaces';
import { StateAutoComplete } from '../../../../common/State';
import { CityAutoComplete } from '../../../../common/City';
import { FHForm } from '../../../Forms/FHFormio';
import {FoldButton} from '../../../../CommonComponents/FoldButton/FoldButton';
import PlusIcon from '../../../../common/Svg/PlusSvg';
import {mapGenderDisplayCode} from '../../Leads/LeadView/AddOrUpdateLead/AddOrUpdateUtils';
import { getEhrList } from '../../../../../services/CommonService/IntegrationService';
import { updateBusinessHours, updateBusinessHoursInCloudTelephony } from '../../../AutoReplyView/AutoReplyViewUtils';
import {getAssociatedAccountList, getGlobalRoles} from '../AssociatedAccountView/utils';
import AssociatedAccountView from '../AssociatedAccountView/AssociatedAccountView';
import {IAccountRole, ILocationWiseRoles} from '../AssociatedAccountView/interface';
import Stack from '../../../../common/LayoutComponents/Stack';
import { ZipCodeQueries } from '../../../../../services';
import {deleteUserPhoneNumberFromCache} from '../../../../../utils/CacheUtils';
import { ViewStyle } from 'react-native';

const { Step } = Steps;

const EditUserWithBusinessHours = (props: IAddEditUserProps) => {
    const { user, onClose, parentCode, licensedStates, selectedLocation } = props;
    const commonData = useContext(CommonDataContext);
    const formattedUserData = getFormattedUserData(user || ({} as IUser));
    const eventBus = EventBus.getEventBusInstance();
    const practitionerAbilities = getResourceAbilities(
      FHIR_RESOURCE.PRACTITIONER,
      selectedLocation?.locationGroup?.id,
      ''
    );
    const ehrName: any = getEHRName('', selectedLocation?.locationGroup?.id);
    const mask = `(###) ###-####`;
    const isProfileEdit = parentCode === PARENT_CODE.SIDE_MENU_PANEL;
    let personGender = getMlovListFromCategory(
      commonData.MLOV,
      MLOV_CATEGORY.PERSON_GENDER
    );
    const genderTransformValues = getSexAtBirthTransformValues(
      KEY_ALLOWED_OPERATIONS.GENDER,
      selectedLocation?.locationGroup?.id,
      ''
    );
    personGender = mapGenderDisplayCode(personGender,genderTransformValues)
    const userGender: IMlov[] = [];
    practitionerAbilities?.keyAllowedOperations?.gender?.possibleValues?.forEach((item) => {
      const matchedData = personGender.find((gender) => gender.code == item.code);
      if (matchedData) {
        userGender.push({
          ...matchedData,
          value: item.display
        });
      }
    });
    const personContactTypeList = getMlovListFromCategory(
      commonData.MLOV,
      MLOV_CATEGORY.PERSON_CONTACT_TYPE
    );
    const providerTypesList = getMlovListFromCategory(
      commonData.MLOV,
      MLOV_CATEGORY.PROVIDER_IDENTIFIER_TYPE
    );
    const [userFormData, setUserFormData]: any = useState(formattedUserData);
    const [errors, setErrors]: any = useState({});
    const [signatureComponent, setSignatureComponent] = useState([
      SIGNATURE_COMPONENT,
    ]);
    const [formResponse, setFormResponse] = useState<any>();
    const [licensedStateDetails, setLicensedStateDetails] = useState<{ name: string, id: string, abbr: string }[]>([])
    const showPractitionerDropdown = practitionerAbilities?.isEnabled || false;
      const intl = useIntl();
      const accountUuid = getAccountUUID();
      const accountId = getAccountId();
      const foldToken = getFoldAccessToken();
      const API_ERROR_MESSAGE = intl.formatMessage({id: 'apiErrorMsg'});
      const isMedOrderEnabled = isOrderTypeEnabled(
        OrderType.MED,
        selectedLocation?.locationGroup?.id,
        ''
      );
      const [stateData, setStateData] = useState({
        loading: true,
        npiAPILoading: false,
        userRoles: [] as IMlov[],
        selectedRoles: [] as IMlov[],
        practiceLocations: [] as any[],
        selectedUserRoles: user?.userRoles || ([] as any[]),
        selectedLocations: user?.userPracticeLocations || ([] as any[]),
        selectedUser: user as any,
        showDrawer: false,
        profileImgSrc: user?.avatar_url || ('' as any),
        profileImageBlob: {} as any,
        userName: '',
        externalUserList: [] as PractitionerData[],
        selectedExternalUser: {} as PractitionerData | null,
        isSelectedDisabled: false,
        errors: '' as string,
        current: 0,
        isSubmitting: false,
        ehrName,
        isFoldEhr: isFoldEhr(ehrName),
        npiNumber: {} as IdentifierTypeState,
        deaNumber: {} as IdentifierTypeState,
        licenseState: '',
        signatureResponse: '' as any,
        stateId: '',
        stateName: '',
        npiError: '',
        loadingPractitioner: false,
        isExternalUserDeleted: false,
        associatedAccountList: getAssociatedAccountList(props.user || {} as IUser) as IAccountRole[],
        selectedGlobalRoles: getGlobalRoles(props.user || {} as IUser),
        globalRoles: getGlobalRoles(props.user || {} as IUser),
      });
    const toast = useToast();

    const [componentState, setComponentState] = useState<IComponentStateState>({
        searchUserText: '',
        availabilitiesByDaysOfWeek: {} as IAvailabilitiesByDaysOfWeek,
        overriddenAvailabilitiesByDateRangeKey: undefined,
        updatedAvailabilitiesByLocalId: {},
        showErrors: false,
        selectedUserAccountLocations: [],
    });

    const [ehrList, setEhrList] = useState<ehrCodeDisplay[]>([]);

    const getEhrDisplayName = (ehrCode?: string) => {
      return (ehrList || []).find(ehr => ehr.code === (ehrCode || ehrName))?.display || '';
    };

    const setEhr = async () => {
      try {
        const ehrResponse = await getEhrList();
        if (ehrResponse?.data?.expansion?.contains?.length > 0) {
          setEhrList(ehrResponse?.data?.expansion?.contains);
        }
      } catch (error) {
      }
    };
    const setSelectedUserAccountLocationsFromSelectedLocations = (selectedLocationUuid?: string[]) => {
      const {accountLocations, selectedUserAccountLocations} = componentState;
      const {associatedAccountList} = stateData;
      const locationUuids: string[] = [];
      associatedAccountList.forEach((location) => {
        if (!location.isDeleted) {
          locationUuids.push(location.locationUuid || '');
        }
      });
      const selctedLocation = accountLocations?.filter((location)=> {
        return locationUuids.includes(location.uuid)
      })
      setComponentState((prev) => {
        return {
          ...prev,
          selectedUserAccountLocations: selctedLocation,
        };
      });
    }

    const handleProfileImageChange = (imageData: any, code: string) => {
        switch (code) {
            case TEAM_MEMBERS_HANDLE_CODE.DELETE:
                setStateData({
                    ...stateData,
                    profileImgSrc: user?.avatar_url,
                    profileImageBlob: {},
                });
                break;
            case TEAM_MEMBERS_HANDLE_CODE.SET_IMAGE:
                setStateData({
                    ...stateData,
                    profileImgSrc: URL.createObjectURL(imageData?.file),
                    profileImageBlob: imageData?.file,
                });
                break;
        }
    };

    const userId = props.user?.id;
    const userUuid = props.user?.uuid;
    const [signatureFormOptions] = useState({
      foldAccessToken: foldToken,
      accountUUID: accountUuid,
      userId: userUuid,
    });
    const mlovData = useContext(CommonDataContext);

    const availabilityTypes = getMlovListFromCategory(mlovData.CARE_STUDIO_MLOV, MLOV_CATEGORY.AVAILABILITY_TYPE) || [];
    const practiceScheduleAvailabilityTypeId = getMlovIdFromCode(availabilityTypes, AVAILABILITY_TYPE_CODES.PRACTICE_SCHEDULE);
    const defaultIAvailability: IAddOrUpdateAvailability = {
        isDeleted: false,
        localId: '',
        typeId: practiceScheduleAvailabilityTypeId,
        userId: props.user?.uuid,
    };

    const [getTimezoneOfAccountAndLocations, { loading: timeZoneLoading }] = useLazyQuery(
      UserPracticeLocationQueries.GetTimezoneOfAccountAndLocations,
      {
          fetchPolicy: 'no-cache',
          onCompleted: async (res) => {
              let accountLocations: IAccountLocation[] = [];
              let timezones: ITimezone[] = [];
              let accountUsers: IAccountUser[] = [];
  
              accountLocations = res?.accountLocations?.length ? res.accountLocations : [];
              timezones = res?.timezones?.length ? res.timezones : [];
              accountUsers = res?.users?.length ? res.users : [];
  
              if (!accountUsers.length) {
                  const userDataRes = await getSingleUserById({
                      variables: {
                          userId: userFormData.uuid,
                      },
                  });
  
                  if (userDataRes?.data?.users) {
                      accountUsers = userDataRes?.data?.users || [];
                      const defaultTimezone = getDefaultTimezone(
                          res?.accountTimezones?.[0]?.timezone,
                          res?.timezones
                      );
  
                      setComponentState((prev: any) => {
                          const availabilityCalendarData: IAvailabilityCalendarData[] =
                              createAvailabilityCalendarGroupData({
                                  practiceAvailabilities: prev.practiceAvailabilities,
                                  timezones,
                                  accountLocations,
                                  accountUsers,
                                  isUserSchedule: true,
                              });
                          return {
                              ...prev,
                              availabilityCalendarData,
                              accountLocations,
                              accountUsers,
                              timezones,
                              defaultTimezone,
                          };
                      });
                  }
              } else {
                  accountUsers = accountUsers?.filter((item) => {
                      let roles = '';
                      item.userRoles?.forEach((element: any) => {
                          roles += element?.userRole?.userRole?.code;
                      });
                      if (
                          !roles.includes(TEAM_MEMBERS_ROLES_CODE.WORKFLOW) &&
                          !roles.includes(TEAM_MEMBERS_ROLES_CODE.CUSTOMER_SUCCESS)
                      ) {
                          return item;
                      }
                  });
  
                  const defaultTimezone = getDefaultTimezone(
                      res?.accountTimezones?.[0]?.timezone,
                      res?.timezones
                  );
  
                  setComponentState((prev: any) => {
                      const availabilityCalendarData: IAvailabilityCalendarData[] =
                          createAvailabilityCalendarGroupData({
                              practiceAvailabilities: prev.practiceAvailabilities,
                              timezones,
                              accountLocations,
                              accountUsers,
                              isUserSchedule: true,
                          });
                      return {
                          ...prev,
                          availabilityCalendarData,
                          accountLocations,
                          accountUsers,
                          timezones,
                          defaultTimezone,
                      };
                  });
              }
          },
      }
  );
  
  const fetchTimezoneData = async () => {
      await getTimezoneOfAccountAndLocations({
          variables: { tenantId: userUuid, roleCode: USER_ROLE_CODES.EMPLOYER },
      });
      await getAvailabilitiesAPI();
  };

    const [createPracticeLocations] = useMutation(
        UserPracticeLocationQueries.CreateUserPracticeLocations
    );

    const [updateUserPracticeLocations] = useMutation(
        UserPracticeLocationQueries.UpdateUserPracticeLocations
    );

    const [createUserRoles] = useMutation(
        UserPracticeLocationQueries.CreateUserRoles
    );
    const [updateUserRoles] = useMutation(
        UserPracticeLocationQueries.UpdateUserRoles
    );

    const [updateAccountUserWithExternalUserId] = useMutation(
        UserQueries.UpdateAccountUserWithExternalUserId
    );

    const [getAccountUserExternalUserId] = useLazyQuery(
        UserPracticeLocationQueries.GetAccountUserExternalUserId,{
          fetchPolicy: 'no-cache',
        }
    );

    const [getStatesByCodes] = useLazyQuery(
      ZipCodeQueries.getStatesByAbbr,{
        fetchPolicy: 'no-cache',
      }
  );

    const [updateUser] = useMutation(UserQueries.UPDATE_USER);

    const [createPersonEntry] = useMutation(ADD_PERSON_DATA);

    const [createContactDetail] = useMutation(ADD_CONTACT_DETAILS);

    const [createPersonContactDetail] = useMutation(
      CREATE_PERSON_CONTACT_DETAILS
    );

    const [createProviderIdentifiers] = useMutation(CREATE_PROVIDER_IDENTIFIERS);

    const [createOrUpdateSingleUser] = useMutation(CREATE_UPDATE_SINGLE_USER);

    const [createAddress] = useMutation(CREATE_ADDRESS);

    const [createPersonAddessDetails] = useMutation(
      CREATE_PERSON_ADDRESS_DETAILS
    );

    const [addUpdateAvailabilitiesAPI, {loading: isAddUpdateScheduleLoading}] = useMutation(ScheduleQueries.ADD_UPDATE_SCHEDULE, {
        fetchPolicy: 'no-cache',
        context: {service: CARESTUDIO_APOLLO_CONTEXT},
      });

    const setLoadingState = (isLoading: boolean) => {
        setStateData((prev) => {
            return {
                ...prev,
                loading: isLoading,
            };
        });
    };

    const [accountPracticeLocationsQuery] = useLazyQuery<any>(
        UserPracticeLocationQueries.GetAccountPracticeLocations,
        {
            fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
        }
    );


    const getAccountLocations = async () => {
      const accountLocationsRes = await accountPracticeLocationsQuery();
      if (accountLocationsRes?.data?.accountLocations?.length) {
        setStateData((prev) => {
          return {
            ...prev,
            practiceLocations: accountLocationsRes.data?.accountLocations || [],
          };
        });
      }
    };

    const onCancel = () => {
        setStateData((prev) => {
            return {
                ...prev,
                showDrawer: false,
            };
        });
        setTimeout(() => {
            onClose();
        }, 200);
    };

    const handleAddOrEditLocation = async () => {
        const selectedAccountLocationUUids =
            getAccountLocationsBySelectedLocationUUids(userFormData.locationUUid, stateData.practiceLocations);
        const locationsToAddOrUpdate = getLocationsToAddOrUpdate(
            stateData.practiceLocations,
            selectedAccountLocationUUids
        );
        await createPracticeLocations({
            variables: {
                objects: createPracticeLocationArray(
                    locationsToAddOrUpdate,
                    user?.uuid || '',
                    accountUuid,
                ),
            },
        });
    };

    const handleDeleteLocation = async () => {
        const selectedAccountLocationUUids =
            getAccountLocationsBySelectedLocationUUids(userFormData.locationUUid, stateData.practiceLocations);
        const locationToDelete = getLocationsToDelete(
            stateData.selectedLocations,
            selectedAccountLocationUUids,
            userFormData.locationUUid
        );
        await updateUserPracticeLocations({
            variables: {
                _or: updatePracticeLocationArray(locationToDelete),
            },
        });
    };



    const uploadImage = async (name: string) => {
        if (stateData.profileImageBlob.uid) {
            const formData = new FormData();
            formData.append('profile[name]', userFormData.name);
            formData.append('profile[email]', userFormData?.email?.value || '');
            formData.append('profile[display_name]', '');
            formData.append('profile[avatar]', stateData.profileImageBlob);

            const profileRes = await uploadImageService(formData);
            if (profileRes) {
                LocalStorage.getItem('user')
                    .then((result) => {
                        const userData = JSON.parse(result);
                        const updateUserData: any = userData;
                        updateUserData['data']['avatar_url'] =
                            profileRes?.data?.avatar_url;
                            LocalStorage.setItem(
                                'user',
                                JSON.stringify(updateUserData)
                            );
                    })
                    .catch((err) => {
                        onProfileUpdate();
                    });
                eventBus.broadcastEvent(WEB_SOCKET_EVENT_CODE.PROFILE_URL_UPDATE, {
                    name: name,
                    profileImage: stateData.profileImgSrc,
                });
                onProfileUpdate();
            }
        } else {
            onProfileUpdate();
            eventBus.broadcastEvent(WEB_SOCKET_EVENT_CODE.PROFILE_URL_UPDATE, {
                name: name,
                profileImage: stateData.profileImgSrc,
            });
        }
    };

    const showErrorToast = () => {
      showToast(toast, intl.formatMessage({id: 'apiErrorMsg'}), ToastType.error);
    };

    const handleSignatureUpload = async () => {
      const dataBlob = dataURItoBlob(formResponse?.data?.signature);
      const formData = new FormData();
      formData.append('file', dataBlob);
      return addOrUpdateSignature(formData);
    };

    const createProviderIdentifier = async () => {
      const data = getProviderIdentifierDataForPost(
        userFormData,
        providerTypesList,
        accountUuid,
        userFormData?.deaStateId,
        true
      );
      return createProviderIdentifiers({
        variables: {
          data: data,
        },
        onError: (err) => {
          showErrorToast();
          onCancel();
        },
      });
    };
    const handleContactDetails = async (personId: string) => {
      const data = getContactDetailsDataForPost(
        userFormData,
        personContactTypeList,
        accountUuid,
        true
      );
      return createContactDetail({
        variables: {
          params: data,
        },
        onCompleted: async (data) => {
          const contactDetailIdsList = data?.createContactDetails?.returning;
          if (contactDetailIdsList?.length > 0) {
            const personContactDetails = await contactDetailIdsList.map(
              (item: {id: string}) => ({
                personId: personId,
                contactDetailId: item.id,
                accountId: accountUuid,
              })
            );
            await createPersonContactDetail({
              variables: {
                params: personContactDetails,
              },
              onError: (err) => {
                showErrorToast();
                onCancel();
              },
            });
          }
        },
        onError: (err) => {
          showErrorToast();
          onCancel();
        },
      });
    };

    const createAddressDetails = async (personId: string) => {
      const data = {
        typeId: userFormData?.addressTypeId,
        cityId: userFormData?.cityId,
        stateId: userFormData?.stateId,
        line1: userFormData?.line1,
        line2: userFormData?.line2,
        countryId: userFormData?.countryId,
        accountId: accountUuid,
        zipcodeId: userFormData?.zipcodeId,
      };
      return createAddress({
        variables: {
          params: data,
        },
        onCompleted: async (data) => {
          await createPersonAddessDetails({
            variables: {
              params: {
                personId: personId,
                addressDetailId: data?.createAddress?.id,
                accountId: accountUuid,
              },
            },
          });
        },
        onError: (err) => {
          showErrorToast();
          onCancel();
        },
      });
    };

    const updateErrorsWhenRoleChanged = (roles: IMlov[]) => {
      const validationsFlags = {
        isGenderAtBirthRequired: false,
        isPractitionerRequired: false,
        isMobileNumberRequired: isMedOrderEnabled && stateData?.isFoldEhr && isPhysicianRoleUser(roles),
        isAddressLine1Required: isMedOrderEnabled && stateData?.isFoldEhr && isPhysicianRoleUser(roles),
        isFaxNumberRequired: isMedOrderEnabled && stateData?.isFoldEhr && isPhysicianRoleUser(roles),
        isBirthDateRequired: isMedOrderEnabled && stateData?.isFoldEhr && isPhysicianRoleUser(roles),
        isZipCodeRequired: isMedOrderEnabled && stateData?.isFoldEhr && isPhysicianRoleUser(roles),
        isNPIRequired: false,//stateData?.isFoldEhr && isPhysicianRoleUser(),
        shouldPerformPhoneNumberValidation: isMedOrderEnabled
      };
      const {errors} = validates(
        {
          ...userFormData,
          phone: userFormData?.phone?.value,
          zipCodeId: userFormData?.zipcodeId,
          zipCode: userFormData?.zipcode,
          email: userFormData?.email?.value,
          fax: userFormData?.fax?.value,
          roles: roles || stateData.selectedRoles || [],
        },
        validationsFlags
      );
      const isNPIRequired = validationsFlags.isNPIRequired;
      let isNPIError = userFormData?.npiError?.length > 0;
      if (isNPIRequired && !userFormData.NPI_NUMBER?.value) {
        isNPIError = true;
        setUserFormData((prev: any) => ({...prev, npiError: 'NPI is required'}));
      }
      setErrors(errors);
    }
    const getFormattedRoleAndAccount = () => {
      const mutableAccountList = [...stateData.associatedAccountList]
      const updatedAssociatedAccountList: ILocationWiseRoles[] = []
      const userId = mutableAccountList[0]?.userId || userFormData?.id
      mutableAccountList.forEach((account) => {
        if (!account.locationUuid){
          return
        }
        const rolesToAdd = getRoleIdsToAddOrUpdate(account?.roles || [], account?.rolesToAdd)
        const rolesToDelete = getRoleIdsToDelete(account?.roles || [], account?.rolesToAdd)
        updatedAssociatedAccountList.push({
          accountLocationId: account.locationUuid,
          externalUserId: account.externalUserId,
          accountRoleIdsToAdd: rolesToAdd,
          accountRoleIdsToDelete: rolesToDelete,
          userId: user?.id,
          isDeleted: account.isDeleted || false,
          locationGroupId: account.locationGroupId || ''
        })
      })
      const globalRoles: Partial<{
        accountRoleIdsToAdd: string[];
        accountRoleIdsToDelete: string[];
      }> = {};
      const rolesToAdd = getRoleIdsToAddOrUpdate(stateData.globalRoles, stateData.selectedGlobalRoles)
      const rolesToDelete = getRoleIdsToDelete(stateData.globalRoles , stateData.selectedGlobalRoles)
      if (rolesToAdd.length > 0) {
        globalRoles.accountRoleIdsToAdd = rolesToAdd;
      }
      if (rolesToDelete.length > 0) {
        globalRoles.accountRoleIdsToDelete = rolesToDelete;
      }
      const associatedAccounts: IAccountRole[] = [{
        accountUuid: accountUuid,
        accountId: accountId,
        userId: userId,
        ...(updatedAssociatedAccountList.length ? {locationWiseRoles: updatedAssociatedAccountList} : {}),
        ...(Object.keys(globalRoles).length > 0 ? { globalRoles: globalRoles } : {})
      }]
      setUserFormData((prev: any) => {
        return {
          ...prev,
          associatedAccounts: associatedAccounts
        }
      })
      return associatedAccounts
    }
    const handleSubmit = async () => {
      setSelectedUserAccountLocationsFromSelectedLocations()
      const formattedRoleAndAccount = stateData.associatedAccountList;
      const globalRoles = stateData.selectedGlobalRoles;
      const {validate, errors} = validates(
        {
          ...{...userFormData,
            associatedAccounts: formattedRoleAndAccount,
            globalRoles: globalRoles
          },
          phone: userFormData?.phone?.value,
          zipCodeId: userFormData?.zipcodeId,
          zipCode: userFormData?.zipcode,
          email: userFormData?.email?.value,
          fax: userFormData?.fax?.value,
          roles: stateData.selectedRoles || [],
        },
        validationsFlags
      );
      const isNPIRequired = validationsFlags.isNPIRequired;
      let isNPIError = userFormData?.npiError?.length > 0;
      if (isNPIRequired && !userFormData.NPI_NUMBER?.value) {
        isNPIError = true;
        setUserFormData((prev: any) => ({...prev, npiError: 'NPI is required'}));
      }
      setErrors(errors);
      if (userUuid) {
        deleteUserPhoneNumberFromCache(userUuid);
      }
        switch (stateData.current) {
            case 0: {
                if (!validate || isNPIError) {
                    setStateData({ ...stateData, errors: 'enterValidName' });
                } else if (steps.length > 1) {
                    setStateData((prev) => {
                        return {
                            ...prev,
                            current: stateData.current + 1,
                        };
                    });
                } else {
                  break;
                }
                return;
            }
            default:
                break;
      }

        if (validate && !isNPIError) {
            setStateData((prev) => {
                return {
                    ...prev,
                    loading: true,
                    isSubmitting: true,
                };
            });
            if (formResponse?.data?.signature?.length > 0) {
              await handleSignatureUpload()
                .then((response) => {
                  if (response?.data?.id) {
                    userFormData.SIGNATURE.value = response?.data?.id;
                    setUserFormData((prev: any) => {
                      return {
                        ...prev,
                        SIGNATURE: {
                          ...prev.SIGNATURE,
                          value: response?.data?.id,
                        },
                      };
                    });
                  }
                })
                .catch((err) => {
                  showErrorToast();
                  return;
                });
            }

            if (props?.roleIsDisable) return; // Early return if roleIsDisable is true
            const associatedAccounts = getFormattedRoleAndAccount();
            const hasRolesOrLocations =
              associatedAccounts[0].locationWiseRoles?.length ||
              associatedAccounts[0].globalRoles;
            if (hasRolesOrLocations) {
              await updateUserRolesAndAccount({
                body: {
                  associatedAccounts
                }
              });
            }
            if (showPractitionerDropdown) {
              await handleExternalUserIdMapping();
            }

            setStateData((prev) => {
                return {
                    ...prev,
                    userName: userFormData.name,
                };
            });
            if (!userFormData?.personId) {
              // create person entry
              createPersonEntry({
                variables: {
                  params: {
                    name: userFormData?.name,
                    genderId: userFormData?.genderId,
                    dateOfBirth: userFormData?.birthDate,
                    accountId: accountUuid,
                    firstName: userFormData?.firstName,
                    lastName: userFormData?.lastName,
                    middleName: userFormData?.middleName,
                    birthSexId: userFormData?.birthSexId,
                  },
                },
                onCompleted: async (data) => {
                  setUserFormData((prev: any) => {
                    return {
                      ...prev,
                      personId: data?.createPerson?.id,
                    };
                  });
                  Promise.all([
                    // create ProviderIdentifierEntry if available
                    createProviderIdentifier(),
                    // create contact details if available
                    handleContactDetails(data?.createPerson?.id),
                    // create address
                    createAddressDetails(data?.createPerson?.id),
                    // update users table
                    createOrUpdateSingleUser({
                      variables: {
                        params: {
                          id: userFormData.id,
                          uuid: userFormData.uuid,
                          email: userFormData?.email?.value,
                          name: userFormData.name,
                          subtitle: userFormData.subtitle,
                          description: userFormData.description,
                          personId: data?.createPerson?.id,
                        },
                      }
                    })
                  ]).then((response) => {
                    if (isProfileEdit) {
                      uploadImage(response?.[3]?.data?.createUser?.name);
                    } else {
                      onProfileUpdate();
                    }
                    showToast(toast, intl.formatMessage({id: 'userUpdated'}), ToastType.success);
                    setStateData((prev) => {
                      return {
                          ...prev,
                          loading: false,
                          isSubmitting: false,
                      };
                  });
                  }).catch((error) => {
                    showErrorToast();
                    setStateData((prev) => {
                        return {
                            ...prev,
                            isSubmitting: false,
                        };
                    });
                  });
                },
                onError: (error) => {

                  showErrorToast();
                  setStateData((prev) => {
                      return {
                          ...prev,
                          isSubmitting: false,
                      };
                  });
                },
              });
            } else {
              const userData = getPostData(
                userFormData,
                providerTypesList,
                accountUuid,
                personContactTypeList
              );
              createOrUpdateSingleUser({
                variables: {
                  params: userData,
                },
                onError: (error) => {
                  showErrorToast();
                  setStateData((prev) => {
                    return {
                        ...prev,
                        isSubmitting: false,
                    };
                });
                },
                onCompleted: (data) => {
                  if (isProfileEdit) {
                    uploadImage(data?.createUser?.name);
                  } else {
                    onProfileUpdate();
                  }
                  setStateData((prev) => {
                    return {
                        ...prev,
                        loading: false,
                        isSubmitting: false,
                    };
                });
                showToast(toast, intl.formatMessage({id: 'userUpdated'}), ToastType.success);
                },
              });
            }
          onBusinessHoursSave(true);
        } else {
            setStateData({ ...stateData, errors: 'enterValidName' });
        }
    };
    const onProfileUpdate = () => {
        setStateData((prev) => {
            return {
                ...prev,
                showDrawer: false,
            };
        });
        const data = {
            name: name,
            profileImage: stateData.profileImgSrc,
        };
        setTimeout(() => {
            onClose('COMPLETE', data);
        }, 200);
    };

    const onBusinessHoursSave = (isUserSchedule: boolean) => {
      setComponentState((prev) => ({...prev, showErrors: true}));

      let availabilities = Object.values(
        componentState.updatedAvailabilitiesByLocalId || {}
      );
      if (areAvailabilitiesInvalid(availabilities)) {
        return;
      }

      if (!availabilities.length) {
        return;
      }

      if (isUserSchedule && componentState?.availabilitiesByDaysOfWeek?.isPracticeScheduleApplied) {
        const isPracticeSchedulesChanged = availabilities.some((availability) => {
          if (!availability.isOverrideAvailability) {
            return true;
          }
          return false;
        });

        if (isPracticeSchedulesChanged) {
          componentState.availabilitiesByDaysOfWeek.data.forEach((data) => {
            data.availabilities.forEach((availability) => {
              if (componentState?.updatedAvailabilitiesByLocalId && !componentState.updatedAvailabilitiesByLocalId?.[availability?.localId]) {
                componentState.updatedAvailabilitiesByLocalId[availability.localId] = availability;
              }
            });
          });
          availabilities = Object.values(componentState?.updatedAvailabilitiesByLocalId || {});
        }
      }

      if (isUserSchedule) {
        availabilities = availabilities.map((availability) => {
          const userAvailability = {
            ...availability,
            userId: userUuid,
          };
          if (!availability.isOverrideAvailability && (componentState?.availabilitiesByDaysOfWeek?.isPracticeScheduleApplied || !availability.userId)) {
            delete userAvailability.id;
          }
          return userAvailability
        })
      }

      const deletedAvailabilities: IPracticeAvailability[] = [];
      const updatedAvailabilities: IPracticeAvailability[] = [];
      const allAvailabilities: IPracticeAvailability[] = []

        availabilities.forEach((availability) => {
          if (availability.isDeleted && !availability.id) {
            return;
          }
          const isVirtualAvailability = availability.locationId === VIRTUAL_LOCATION_CODE || availability.isVirtualLocation;
          const practiceAvailability: IPracticeAvailability = {
            id: availability.id,
            beginDate: availability.beginDate,
            endDate: availability.endDate,
            startTime: availability.startTime,
            endTime: availability.endTime,
            daysOfWeek: availability.daysOfWeek,
            isVirtualLocation: isVirtualAvailability,
            locationId: isVirtualAvailability ? null : availability.locationId,
            timezoneId: availability.timezoneId,
            typeId: availability.typeId,
            userId: availability.userId,
            inboxId: availability.inboxId,
            isDeleted: availability.isDeleted,
          }
          if (availability.isDeleted) {
            deletedAvailabilities.push(practiceAvailability);
          } else {
            updatedAvailabilities.push(practiceAvailability);
            allAvailabilities.push({
              ...practiceAvailability,
              timezone:
                availability?.timezone ||
                componentState?.timezones?.find(
                  (item) => item.uuid == availability.timezoneId
                ),
            });
          }
        });

        if (!deletedAvailabilities.length && !updatedAvailabilities?.length) {
          return;
        }

        setComponentState((prev) => {
          return {
            ...prev,
            isSaveLoading: true,
          }
        });

        addUpdateAvailabilitiesAPI({
          variables: {
            data: [
              ...deletedAvailabilities,
              ...updatedAvailabilities
            ],
          },
          onCompleted: async () => {
            setComponentState((prev) => ({ ...prev, isSaveLoading: false }));
            await updateBusinessHours({data: allAvailabilities})
            await updateBusinessHoursInCloudTelephony({data: allAvailabilities})
            showToast(toast,  intl.formatMessage({id: 'scheduleUpdated'}), ToastType.success );
          },
          onError: (error) => {
            setComponentState((prev) => ({ ...prev, isSaveLoading: false }));
            showToast(toast, API_ERROR_MESSAGE, ToastType.error );
          }
        });
      }

    // GET SINGLE USER
    const [getSingleUserById] = useLazyQuery(GET_SINGLE_USER, {
        fetchPolicy: 'no-cache',
    });

    const editAvailabilitiesByLocationOrUser = () => {
      const availabilityCalendarDataObject = componentState.availabilityCalendarData?.find((data) => {
        const id = data?.userId || '';
        if (id === user?.uuid) {
          return true;
        }
      });
      if (availabilityCalendarDataObject) {
        setComponentState((prev) => ({
          ...prev,
          availabilitiesByDaysOfWeek: JSON.parse(JSON.stringify(availabilityCalendarDataObject.availabilitiesByDaysOfWeek)),
          overriddenAvailabilitiesByDateRangeKey: JSON.parse(JSON.stringify(availabilityCalendarDataObject.overriddenAvailabilitiesByDateRangeKey)) || undefined,
      }));
      }
    }

    const getLicensedStateDetails = async () => {
      try {
        const stateCodes = licensedStates?.map((item) => item.stateCode) || [];
        if (stateCodes.length) {
          const response = await getStatesByCodes({
            variables: {
              stateCodes: stateCodes,
            }
          });
          const statesDetails = response.data?.states || [];
          setLicensedStateDetails(statesDetails);
        }
      } catch (error) {

      }
    }

    useEffect(() => {
      if (showPractitionerDropdown) {
        setEhr()
      };
      if (licensedStates?.length) {
        getLicensedStateDetails()
      }
    }, []);

    useEffect(() => {
      const npiNumber = userFormData.NPI_NUMBER?.value;
      if (npiNumber) {
        setStateData((prev) => ({...prev, npiAPILoading: true}));
        validateNPI(npiNumber);
      }
    }, [userFormData.NPI_NUMBER?.value, userFormData.zipcode, userFormData.firstName, userFormData.lastName]);

    useEffect(() => {
        setStateData({
          ...stateData,
          selectedRoles: getInitialSelectedRoles(user?.userRoles || []),
      });
        getAccountLocations();
        onAddScheduleBtnClick();
        fetchUserData();
        const userRoles = (
          getMlovListFromCategory(commonData.MLOV, MLOV_CATEGORY.USER_ROLES) ||
          []
          ).filter((roles: IMlov) => {
            if (!getUserRoleExistByRoleCode(
              getInitialSelectedRoles(user?.userRoles || []),
              USER_ROLE_CODES.EMPLOYER
            )) {
              return roles.code !== USER_ROLE_CODES.EMPLOYER;
            } else {
              return roles;
            }
          })?.sort((a, b) => a?.value.localeCompare(b?.value));
            setStateData((prev: any) => {
            return {
                ...prev,
                userRoles: userRoles,
                selectedUser: user,
                selectedUserRoles: user?.userRoles || ([] as IMlov[]),
                selectedLocations: user?.userPracticeLocations || ([] as any[]),
            };
        });
        setStateData((prev) => {
            return {
                ...prev,
                showDrawer: true,
            };
        });

    }, [props.user?.id]);

    useEffect(() => {
      if(componentState.availabilityCalendarData?.length){
        editAvailabilitiesByLocationOrUser();
      }
    }, [componentState.availabilityCalendarData?.length]);

    const fetchUserData = () => {
      getSingleUserById({
        variables: {
          userId: userFormData.uuid,
        },
        onCompleted: async (data) => {
          const userData: any = data?.users[0] as User;
          setComponentState((prev) => {
            return {
              ...prev,
              accountUsers: data?.users,
              selectedUserAccountLocations: extractAccountLocationsFromUserPracticeLocations(data?.users[0]?.userPracticeLocations || []),
            }
          });
          fetchTimezoneData()
          const personData: Person = userData?.persons;
          const contactDetails: {[key: string]: ContactDetailState} =
            processContactDetails(personData?.personContactDetails);
          const address = personData?.personAddressDetails?.[0];
          const providerTypeDetails = processProviderIdentifierDetails(
            userData?.providerIdentifiers,
            providerTypesList
          );
          const userNameData = getFirstAndLastNameFromUserName(userData?.name || '');
          const result = {
            ...userData,
            id: userData?.id,
            name: userData?.name,
            uuid: userData?.uuid,
            personId: userData?.personId,
            description: userData?.description,
            email: {
              value: userData?.email,
            },
            firstName: personData?.firstName || userNameData.firstName,
            lastName: personData?.lastName || userNameData.lastName,
            middleName: personData?.middleName,
            genderId: personData?.genderId,
            birthDate: personData?.dateOfBirth,
            birthSexId: personData?.birthSexId,
            addressRelationId: address?.id,
            contactRelationId: personData?.personContactDetails?.[0]?.id,
            contactDetailsId:
              personData?.personContactDetails?.[0]?.contactDetailId,
            line1: address?.addressDetails?.[0]?.line1,
            line2: address?.addressDetails?.[0]?.line2,
            stateName: address?.addressDetails?.[0]?.state?.name,
            stateId: address?.addressDetails?.[0]?.state?.id,
            cityName: address?.addressDetails?.[0]?.city?.name,
            cityId: address?.addressDetails?.[0]?.city?.id,
            addressTypeId: address?.addressDetails?.[0]?.typeId,
            addressId: address?.addressDetails?.[0]?.id,
            zipcode: address?.addressDetails?.[0]?.zipcode?.code,
            zipcodeId: address?.addressDetails?.[0]?.zipcodeId,
            deaStateId: providerTypeDetails?.DEA_NUMBER?.accountLocationId,
            externalUserId: user?.externalUserId || userData.externalUserId || userData.accountUsers?.[0]?.externalUserId,
            SIGNATURE: {},
            userRoleCodes:userData?.userRoles?.map(
              (role: {
                userRole: {
                  userRole: IUserRole;
                  roleCategory: {
                    code: string;
                  };
                };
              }) => {
                if (role) {
                  const id = role?.userRole?.userRole?.code;
                  return id;
                }
              }
            ),
            userRolesId: userData?.userRoles?.map(
              (role: {
                userRole: {
                  userRole: IUserRole;
                  roleCategory: {
                    code: string;
                  };
                };
              }) => {
                if (role) {
                  const id = role?.userRole?.userRole?.id;
                  return id;
                }
              }
            ),
            ...(!!contactDetails && contactDetails),
            ...(!!providerTypeDetails && providerTypeDetails),
          };

          if (providerTypeDetails?.SIGNATURE?.value) {
            const id = providerTypeDetails?.SIGNATURE?.value;
            const url = CRM_BASE_URL + '/api/attachment/' + id;
            setStateData((prev) => ({
              ...prev,
              signatureResponse: {
                data: {
                  signature: {
                    url: url,
                    data: {
                      id: id,
                    },
                    fileName: 'Signature',
                    storage: 'url',
                    type: 'image/png',
                    size: 10265,
                  },
                },
              },
            }));
            signatureComponent[0].selectedValue = {
              url: url,
              data: {
                id: id,
              },
              fileName: 'Signature',
              storage: 'url',
              type: 'image/png',
              size: 10265,
            };
            setSignatureComponent([
              {
                ...SIGNATURE_COMPONENT,
                selectedValue: {
                  url: url,
                  data: {
                    id: id,
                  },
                  fileName: 'Signature',
                  storage: 'url',
                  type: 'image/png',
                  size: 10265,
                },
              },
            ]);
          }
          setUserFormData((prev: any) => ({...prev, ...result}));
          setStateData(prev=> {
            return {
              ...prev,
              loading: false
            }
          })
        },
        onError: () => {
          setLoadingState(false);
          showErrorToast();
        },
      });
    }

    const onAddScheduleBtnClick = () => {
        const availabilities= checkAvailabilities(componentState.availabilityCalendarData || []);
        const availabilitiesByDaysOfWeek = groupAvailabilitiesByDaysOfWeek(availabilities || [], true);

        setComponentState((prev) => ({
          ...prev,
          availabilitiesByDaysOfWeek: JSON.parse(JSON.stringify(availabilitiesByDaysOfWeek)),
        }));
      }

      const getPracticeAvailabilityWhereCondition =() =>{
        let whereCondition: any = {};
        whereCondition = {
            isDeleted: { _eq: false },
            typeId: { _eq: practiceScheduleAvailabilityTypeId },
            //userId: { _eq: props.user?.uuid },
            //locationId: { _eq: user?.userPracticeLocations?.length ? user.userPracticeLocations[0].uuid : undefined},
        };

        return whereCondition;
    }

    const [getAvailabilitiesAPI, { loading: isGetAvailabilitiesAPILoading }] = useLazyQuery(ScheduleQueries.GET_PRACTICE_SCHEDULE, {
        fetchPolicy: 'no-cache',
        context: { service: CARESTUDIO_APOLLO_CONTEXT },
        variables: {
            whereCondition: getPracticeAvailabilityWhereCondition(),
        },
        onCompleted: (response) => {
            setComponentState((prev) => {
                const availabilityCalendarData: IAvailabilityCalendarData[] = createAvailabilityCalendarGroupData({
                    practiceAvailabilities: response?.schedules || [],
                    timezones: (prev.timezones || []),
                    accountLocations: prev.accountLocations,
                    accountUsers: prev.accountUsers,
                    isUserSchedule: true,
                });

                return {
                    ...prev,
                    practiceAvailabilities: response?.schedules?.length ? response.schedules : [],
                    availabilityCalendarData,
                };
            });
        },
        onError: (error) => {

            setComponentState((prev) => ({
                ...prev,
                practiceAvailabilities: [],
            }));
            showToast(toast, API_ERROR_MESSAGE, ToastType.error);
        },
    }
    );

    const isRoleAlreadyAdded = (roleId: string) => {
        let isAdded = false;
        const userRoleData = user?.userRoles?.find((singleUserRole) => {
            return roleId === singleUserRole?.userRole?.userRole?.id;
        });
        if (userRoleData && userRoleData.id) {
            isAdded = true;
        }
        return isAdded;
    };

    const getInitialSelectedRoles = (userRoles: any) => {
        const rolesArray = userFormData.userRolesId;
        const selectedRoles = userRoles
            .filter((role: any) => {
                return (
                    rolesArray?.includes(role.id) ||
                    rolesArray?.includes(role.userRole?.userRole?.id)
                );
            })
            .map((role: any) => {
                return role.userRole?.userRole;
            });
        setStateData({
            ...stateData,
            selectedRoles: selectedRoles || [],
        });
        return selectedRoles;
    };

    const filterLocationObject = (locationData: any) => {
        const newData = (locationData || []).map((location: any) => {
            return location?.practiceLocation;
        });
        return newData;
    };
    const handleRoleRemove = (id: any) => {
        const newRoles = (stateData.selectedRoles || []).filter((role) => {
            return role.id !== id;
        });
        setStateData({ ...stateData, selectedRoles: newRoles || [] });
        const userRole = userFormData.userRolesId.filter((role: any) => {
            return role !== id;
        });
        setUserFormData({ ...userFormData, userRolesId: userRole });
        updateErrorsWhenRoleChanged(newRoles);
    };


    const handleExternalUserIdMapping = async () => {
        const id = user?.id;
        const externalUserId = stateData.selectedExternalUser?.id || null;
        if(!externalUserId && !stateData.isExternalUserDeleted){
          return;
        }

        try {
            const response = await updateAccountUserWithExternalUserId({
                variables: {
                    accountId: accountId,
                    userId: id,
                    externalUserId: externalUserId,
                },
            });
        } catch (error) {
            showToast(
                toast,
                API_ERROR_MESSAGE,
                ToastType.error
            );
        }
    };

    const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
        { maxWidth: IPAD_WIDTH },
        { maxWidth: IPAD_MINI_WIDTH },
    ]);

    const drawerWidth = isIPadMiniScreen || isIPadScreen ? '60vw' : '42vw';

    const isPhysicianRoleUser = (roles?: IMlov[]): boolean => {
      const isPhysicianRoleSelected = (roles || stateData.selectedRoles || [])?.some((item) => item?.code === USER_ROLE_CODES.PHYSICIAN);
      return isPhysicianRoleSelected;
    };

    const validateNPI = debounce((npiNumber: string) => {
      const {axios} = BaseService.getSharedInstance();
      const path = `/integration/api/order/practitioner/validate-identifier`;
      axios
        .put(
          path,
          {
            type: 'NPI',
            value: npiNumber.toString(),
            ...(userFormData?.zipcode && { zipcode: userFormData.zipcode }),
            ...(userFormData?.firstName && { firstName: userFormData.firstName }),
            ...(userFormData?.lastName && { lastName: userFormData.lastName }),
          }
        )
        .then((result) => {
          setUserFormData((prev: any) => {
            return {
              ...prev,
              npiError: result?.data?.errorMessage || '',
            };
          });
          setStateData((prev) => ({...prev, npiAPILoading: false}));
        })
        .catch((error) => {

          setStateData((prev) => ({...prev, npiAPILoading: false}));
        });
    }, 500);

    const handleFormChange = useCallback((data: any) => {
      setFormResponse(data);
    }, []);

    const genderIdentityLabel = getGenderIdentityLabel(
      selectedLocation?.locationGroup?.id,
      ''
    );
    const validationsFlags = {
      isGenderAtBirthRequired: false,
      isPractitionerRequired: false,
      isMobileNumberRequired: isMedOrderEnabled && stateData?.isFoldEhr && isPhysicianRoleUser(),
      isAddressLine1Required: isMedOrderEnabled && stateData?.isFoldEhr && isPhysicianRoleUser(),
      isFaxNumberRequired: isMedOrderEnabled && stateData?.isFoldEhr && isPhysicianRoleUser(),
      isBirthDateRequired: isMedOrderEnabled && stateData?.isFoldEhr && isPhysicianRoleUser(),
      isZipCodeRequired: isMedOrderEnabled && stateData?.isFoldEhr && isPhysicianRoleUser(),
      isNPIRequired: false,//stateData?.isFoldEhr && isPhysicianRoleUser(),
      shouldPerformPhoneNumberValidation: isMedOrderEnabled,
    };
    const allErrors = {...errors, npiError: userFormData.npiError};
    const alertMessage = Object.keys(allErrors).map((key) => allErrors[key]).filter((item) => !!item).join(', ');

    const onAddNewCard = (isOverrideAvailability: boolean) => {
        setComponentState((prev: any) => {
            const cardId = uuidV4();

            const defaultAvailability: IAddOrUpdateAvailability = {
                ...defaultIAvailability,
                localId: uuidV4(),
                timezoneId: componentState.defaultTimezone?.uuid,
            };

            if (isOverrideAvailability) {
                if (!prev.overriddenAvailabilitiesByDateRangeKey) {
                    return prev;
                }
                const beginDate = new Date();
                const endDate = new Date();

                defaultAvailability.daysOfWeek = '[]';
                defaultAvailability.isOverrideAvailability = true;
                defaultAvailability.beginDate = getFormattedDate(beginDate, DATE_FORMATS.AVAILABILITY_DATE_FORMAT);
                defaultAvailability.endDate = getFormattedDate(endDate, DATE_FORMATS.AVAILABILITY_DATE_FORMAT);

                prev.overriddenAvailabilitiesByDateRangeKey?.data.unshift({
                    availabilities: [defaultAvailability],
                    localId: cardId,
                    beginDate,
                    endDate,
                    timezoneId: componentState.defaultTimezone?.uuid || '',
                });

                prev.overriddenAvailabilitiesByDateRangeKey.isAvailabilitiesPresent = true;

            } else {
                const selectedDaysOfWeek = [1, 2, 3, 4, 5];
                defaultAvailability.daysOfWeek = JSON.stringify(selectedDaysOfWeek);

                prev.availabilitiesByDaysOfWeek.data.unshift({
                    availabilities: [defaultAvailability],
                    localId: cardId,
                    selectedDaysOfWeek,
                    timezoneId: componentState.defaultTimezone?.uuid || '',
                });
                prev.availabilitiesByDaysOfWeek.isAvailabilitiesPresent = true;
            }

            prev.updatedAvailabilitiesByLocalId[defaultAvailability.localId] = defaultAvailability;

            return { ...prev };
        });
    }

    const onUpdateAvailabilities = (availabilities: IAddOrUpdateAvailability[])=> {
        setComponentState((prev: any) => {
            availabilities.forEach((availability) => {
                prev.updatedAvailabilitiesByLocalId[availability.localId] = availability;
            });

            prev.availabilitiesByDaysOfWeek.isAvailabilitiesPresent = isAvailabilitiesPresent(prev.availabilitiesByDaysOfWeek);
            return { ...prev };
        });
    }

    const getName = (userData: any) => {
      if (!userData?.id) {
        return ''
      }
      return `${userData?.displayName} (${getEhrDisplayName()})`;
    };

    const isRoleDisabled = props.roleIsDisable || getUserRoleExistByRoleCode(
      stateData.selectedRoles || [],
      USER_ROLE_CODES.EMPLOYER
    );

    const renderAccountDetails = (): JSX.Element => {
      return (
        <Content
          className={'addEDitUserMultiSelect'}
          style={{
            opacity: stateData.isSubmitting ? 0.3 : 1,
            marginTop: 5,
          }}
        >
          { (stateData.loading || stateData.loadingPractitioner) ? (
            <View marginTop={8}>
              <SkeletonLoader />
            </View>
          ) : (
            <View marginBottom={4}>
              <VStack space={4} marginTop={8}>
                {/* <ModalActionInput
                  fieldIs={'input'}
                  label={'name'}
                  isRequired={true}
                  errors={userFormData.name.length ? false : true}
                  errorText={stateData.errors}
                  value={userFormData.name}
                  onChangeText={(value) => {
                    setUserFormData({...userFormData, name: value});
                  }}
                  extraStyle={{flex: 1}}
                /> */}
                {alertMessage.length > 0 && <Alert message={alertMessage} closable type="error" />}
                <ModalActionSubTitle subTitle={'Basic Info'} />



                {isProfileEdit && (
                  <ProfileUpload
                    handleProfileImageChange={handleProfileImageChange}
                    profileImgSrc={stateData.profileImgSrc}
                    fullName={user?.name}
                    profileImageBlob={stateData.profileImageBlob}
                  />
                )}
                <HStack space={10} justifyContent="space-between">
                  <ModalActionInput
                    fieldIs={'input'}
                    label={'firstName'}
                    placeholder={'John'}
                    isRequired={true}
                    isInvalid={errors?.firstName}
                    errors={errors?.firstName}
                    errorText={errors?.firstName}
                    value={userFormData?.firstName}
                    onChangeText={(value: string) => {
                      setUserFormData((prev: any) => ({
                        ...prev,
                        firstName: value,
                        name: `${value} ${prev.lastName}`,
                      }));
                    }}
                    extraStyle={{
                      flex: 1.0,
                    }}
                  />

                  <ModalActionInput
                    fieldIs={'input'}
                    label={'middleName'}
                    placeholder={'John'}
                    isRequired={false}
                    value={userFormData.middleName}
                    onChangeText={(value: any) => {
                      setUserFormData((prev: any) => ({
                        ...prev,
                        middleName: value,
                      }));
                    }}
                    extraStyle={{
                      flex: 1.0,
                    }}
                  />
                </HStack>
                <HStack space={10}>
                  <ModalActionInput
                    fieldIs={'input'}
                    label={'lastName'}
                    placeholder="Smith"
                    isRequired={true}
                    value={userFormData.lastName}
                    isInvalid={errors.lastName}
                    errors={errors?.lastName}
                    errorText={errors?.lastName}
                    onChangeText={(value: string) => {
                      setUserFormData((prev: any) => ({
                        ...prev,
                        lastName: value,
                        name: `${prev.firstName} ${value}`,
                      }));
                    }}
                    extraStyle={{
                      flex: 1.0,
                    }}
                  />

                  <ModalActionDatePicker
                    disabledDate={(current: any) => {
                      return current && isFutureDate(current);
                    }}
                    isRequired={validationsFlags.isBirthDateRequired}
                    label={'dateOfBirth'}
                    defaultValue={
                      userFormData?.birthDate
                        ? getMomentObj(userFormData?.birthDate)
                        : undefined
                    }
                    format={DISPLAY_DATE_FORMAT}
                    placeholder={DISPLAY_DATE_FORMAT}
                    isInvalid={errors?.birthdate}
                    value={
                      userFormData?.birthDate
                        ? getMomentObj(userFormData?.birthDate)
                        : undefined
                    }
                    errors={errors?.birthdate}
                    errorsText={errors?.birthdate}
                    onChange={(value: any) => {
                      setUserFormData((prev: any) => ({
                        ...prev,
                        birthDate: value,
                      }));
                    }}
                    customStyle={{
                      height: 36,
                      borderColor: Colors.Custom.BorderColor,
                    }}
                    extraStyle={{
                      flex: 1.0,
                    }}
                  />
                </HStack>
                <ModalActionInput
                  fieldIs={'input'}
                  label={'credentials'}
                  isRequired={false}
                  value={userFormData.subtitle}
                  onChangeText={(value: any) => {
                    setUserFormData({...userFormData, subtitle: value});
                  }}
                  extraStyle={{flex: 1}}
                />

                <ModalActionInput
                  fieldIs={'textArea'}
                  maxLength={500}
                  label={'profile'}
                  isRequired={false}
                  value={userFormData.description}
                  onChangeText={(value: any) => {
                    setUserFormData({...userFormData, description: value});
                  }}
                  extraStyle={{flex: 1, Height: '70px'}}
                />

                {!isRoleDisabled && (
                  <>
                   <AssociatedAccountView
                    isEditMode={true}
                    selectedGlobalRoles={stateData.selectedGlobalRoles}
                    userData={props.user as any}
                    isDisabled={false}
                    onActionPerformed={(code: string,data: any)=> {
                      setErrors((prev: any)=> {
                        return {
                          ...prev,
                          accountRole: ''
                        }
                      })
                      if (code === ACCOUNTS_AND_ROLES_ACTION_CONST.LOCATION_AND_ROLE) {
                        setStateData((prev)=> {
                          return {
                            ...prev,
                            associatedAccountList: data
                          }
                        })
                      } else if (code === ACCOUNTS_AND_ROLES_ACTION_CONST.GLOBAL_USER_ROLES) {
                        setStateData(prev=> {
                          return {
                            ...prev,
                            selectedGlobalRoles: data
                          }
                        })
                      }
                    }}
                    associatedAccounts={stateData.associatedAccountList}
                  />
                </>
                )}
                {isRoleDisabled && (
                  <FormControl.Label
                    marginBottom={-2}
                  >
                    <DisplayText
                      size={'smMedium'}
                      extraStyles={{
                        color: Colors.Custom.Gray700,
                      }}
                      textLocalId={'roles'}
                    />
                  </FormControl.Label>
                )}
                {isRoleDisabled && <HStack flexWrap={'wrap'} overflow={'auto'} space={2}>
                  {stateData.selectedRoles &&
                    stateData.selectedRoles.map((tag: any) => {
                      return (
                        <View
                          mt={2}
                          px={2}
                          py={1}
                          borderRadius={16}
                          bgColor={'gray.100'}
                        >
                          <HStack space={1} alignItems={'center'}>
                            <Text>{tag?.value}</Text>
                            {!isRoleDisabled && (
                              <Pressable
                                disabled={props?.roleIsDisable}
                                onPress={() => {
                                  if (
                                    getUserRoleExistByRoleCode(
                                      stateData.selectedRoles || [],
                                      USER_ROLE_CODES.EMPLOYER
                                    )
                                  ) {
                                    return;
                                  }
                                  if (!props?.roleIsDisable) {
                                    handleRoleRemove(tag?.id);
                                  }
                                }}
                              >
                                <Image
                                  style={styles.box8}
                                  source={require('../../../../../assets/images/cancel.png')}
                                />
                              </Pressable>
                            )}
                          </HStack>
                        </View>
                      );
                    })}
                </HStack>}


                <ModalActionSubTitle marginTop={8} subTitle={'Contact Info'} />
                <HStack space={10}>
                  <ModalActionInput
                    fieldIs={'input'}
                    label={'phoneNumber'}
                    placeholder={'(123) 123-1234'}
                    isRequired={validationsFlags.isMobileNumberRequired}
                    isInvalid={errors?.mobileNumber}
                    value={numericStringMask(userFormData?.phone?.value || '', mask)}
                    errors={errors?.mobileNumber}
                    errorText={errors?.mobileNumber}
                    onChangeText={(value: any) => {
                      const updatedValue = value?.replace(/[()-\s]/g, '');
                      if (updatedValue?.length <= 10) {
                        setUserFormData((prev: any) => ({
                          ...prev,
                          phone: {
                            ...prev?.phone,
                            value: unmaskPhoneNumber(value),
                          },
                        }));
                      }
                    }}
                    extraStyle={{flex: 1}}
                  />
                  <ModalActionInput
                    // ref={ref_inputEmail}
                    fieldIs={'input'}
                    label={'Email'}
                    placeholder={'abc@xyz.com'}
                    isRequired={false}
                    isDisabled={true}
                    isInvalid={errors?.email}
                    value={userFormData?.email?.value}
                    errors={errors?.email}
                    errorText={errors?.email}
                    onChangeText={(value: any) => {
                      setUserFormData({
                        ...userFormData,
                        email: {
                          ...userFormData?.email,
                          value: value,
                        },
                      });
                    }}
                    extraStyle={{
                      flex: 1.0,
                    }}
                  />
                </HStack>
                <HStack space={10}>
                  <ModalActionInput
                    fieldIs={'input'}
                    label={'faxNumber'}
                    placeholder={'(123) 123-1234'}
                    isRequired={validationsFlags.isFaxNumberRequired}
                    extraStyle={{flex: 1}}
                    isInvalid={errors?.fax}
                    value={numericStringMask(userFormData?.fax?.value || '', mask)}
                    errors={errors?.fax}
                    errorText={errors?.fax}
                    onChangeText={(value: any) => {
                      const updatedValue = value?.replace(/[()-\s]/g, '');
                      if (updatedValue?.length <= 10) {
                        setUserFormData((prev: any) => ({
                          ...prev,
                          fax: {...prev?.fax, value: unmaskPhoneNumber(value)},
                        }));
                      }
                    }}
                  />
                </HStack>
                <HStack space={10}>
                  <ZipCodeAutoComplete
                    flex={1}
                    name={userFormData?.zipcode}
                    isRequired={validationsFlags.isZipCodeRequired}
                    isInvalid={errors.zipcode}
                    errors={errors}
                    errorText={errors.zipcode}
                    onAutoCompleteChange={(selectedZipCode: IZipCodeData) => {
                      setUserFormData((prev: any) => {
                        return {
                          ...prev,
                          zipcodeId: selectedZipCode?.id,
                          stateId: selectedZipCode?.stateId,
                          stateName:
                            selectedZipCode?.zipcodeState?.name ||
                            selectedZipCode?.stateName,
                          cityId: selectedZipCode?.cityId,
                          cityName:
                            selectedZipCode?.zipcodeCity?.name ||
                            selectedZipCode?.cityName,
                          abbr: selectedZipCode?.zipcodeState?.abbr || '',
                          zipcode: selectedZipCode?.code,
                        };
                      });
                    }}
                    onValueChange={(value: string) => {
                      setErrors((prev: any) => ({
                        ...prev,
                        zipcode: '',
                      }));
                      setUserFormData((prev: any) => ({
                        ...prev,
                        zipcode: value,
                      }));
                    }}
                    showAdd={true}
                  />
                  <ModalActionSelect
                    label={genderIdentityLabel}
                    isRequired={validationsFlags.isGenderAtBirthRequired}
                    selectedValue={userFormData?.genderId}
                    accessibilityLabel={genderIdentityLabel}
                    placeholder={`Select ${genderIdentityLabel}`}
                    endIcon={<CheckIcon size="5" />}
                    onValueChange={(itemValue: any) => {
                      const selectedGender = userGender.find(
                        (gender: any) => {
                          return gender.id == itemValue;
                        }
                      );
                      setUserFormData((prev: any) => ({
                        ...prev,
                        genderId: itemValue,
                        genderName: selectedGender?.code,
                      }));
                    }}
                    data={userGender}
                    selectItemProps={{
                      key: 'id',
                      label: 'value',
                      value: 'id',
                    }}
                    customStyle={{flex: 1}}
                  />
                </HStack>
                <ModalActionSubTitle
                  marginTop={8}
                  subTitle={'Additional Info'}
                />
                <HStack space={10}>
                  <ModalActionInput
                    fieldIs={'input'}
                    label={'addressLine1'}
                    placeholder={'11, Main Street'}
                    isRequired={validationsFlags.isAddressLine1Required}
                    isInvalid={errors?.line1}
                    errors={errors?.line1}
                    errorText={errors?.line1}
                    value={userFormData?.line1 || ''}
                    onChangeText={(value: any) => {
                      setUserFormData((prev: any) => ({
                        ...prev,
                        line1: value,
                      }));
                    }}
                    extraStyle={{flex: 1}}
                  />

                  <ModalActionInput
                    fieldIs={'input'}
                    label={'addressLine2'}
                    placeholder={'11, Main Street'}
                    isRequired={false}
                    value={userFormData?.line2 || ''}
                    onChangeText={(value: any) => {
                      setUserFormData((prev: any) => ({
                        ...prev,
                        line2: value,
                      }));
                    }}
                    extraStyle={{flex: 1}}
                  />
                </HStack>
                <HStack space={10}>
                  <StateAutoComplete
                    isTabDisabled
                    name={userFormData?.stateName}
                    onAutoCompleteChange={(selectedId, abbr, name) => {
                      setUserFormData((prev: any) => {
                        return {
                          ...prev,
                          stateId: selectedId,
                          stateName: name,
                        };
                      });
                    }}
                    flex={1}
                  />

                  <CityAutoComplete
                    isTabDisabled
                    flex={1}
                    name={userFormData?.cityName}
                    // name={'City name'}
                    onAutoCompleteChange={(selectedId, cityName) => {
                      setUserFormData((prev: any) => ({
                        ...prev,
                        cityId: selectedId,
                        cityName: cityName || '',
                      }));
                    }}
                  />
                </HStack>
              </VStack>
              <VStack>
                <HStack flex={1} space={1}></HStack>

                {stateData?.isFoldEhr && isPhysicianRoleUser() && (
                  <VStack marginTop={2}>
                    <ModalActionSubTitle
                      marginTop={8}
                      subTitle={'Physician Details'}
                    />
                    <FormControl
                      style={styles.formElement}
                      isInvalid={userFormData?.npiError?.length > 0}
                    >
                      <FormControl.Label style={styles.formLabel} isRequired={validationsFlags.isNPIRequired}>
                        <DisplayText
                          textLocalId={'npiNumber'}
                          size={'smMedium'}
                          extraStyles={{color: Colors.Custom.Gray700}}
                        />
                      </FormControl.Label>
                      <InputNumber
                        placeholder="Enter NPI Number"
                        style={reactStyles.inputNumber}
                        maxLength={10}
                        value={userFormData?.NPI_NUMBER?.value}
                        type={'number'}
                        required={validationsFlags.isNPIRequired}
                        status={userFormData.npiError?.length > 0 ? 'error' : ''}
                        onChange={(npiNumber) => {
                          if (npiNumber) {
                            setUserFormData((prev: any) => ({
                              ...prev,
                              npiError: '',
                              NPI_NUMBER: {
                                ...prev?.NPI_NUMBER,
                                value: `${npiNumber}`,
                              },
                            }));
                          } else {
                            setUserFormData((prev: any) => ({
                              ...prev,
                              npiError: '',
                              NPI_NUMBER: {
                                ...prev?.NPI_NUMBER,
                                value: '',
                              },
                            }));
                          }
                        }}
                      />
                      {userFormData?.npiError?.length > 0 && (
                        <FormControl.ErrorMessage
                          _text={{
                            fontSize: 'xs',
                            color: 'error.500',
                            fontWeight: 500,
                          }}
                        >
                          {userFormData?.npiError}
                        </FormControl.ErrorMessage>
                      )}
                    </FormControl>

                    <HStack flex={1} space={1}>
                      <FormControl style={[styles.formElement]} flex={0.49}>
                        <StateAutoComplete
                          name={userFormData?.DEA_NUMBER?.stateName}
                          onAutoCompleteChange={(selectedId, name) => {
                            setUserFormData((prev: any) => {
                              return {
                                ...prev,
                                deaStateId: selectedId,
                                deaStateName: name,
                              };
                            });
                          }}
                        />
                      </FormControl>

                      <ModalActionInput
                        flex={0.49}
                        fieldIs={'input'}
                        marginTop={2}
                        label={'deaNumber'}
                        isRequired={false}
                        value={userFormData?.DEA_NUMBER?.value}
                        onChangeText={(value: any) => {
                          setUserFormData((prev: any) => {
                            return {
                              ...prev,
                              DEA_NUMBER: {...prev.DEA_NUMBER, value: value},
                            };
                          });
                        }}
                        extraStyle={{flex: 1}}
                      />
                    </HStack>
                    {isProfileEdit && (
                      <FHForm
                        readOnly={false}
                        components={signatureComponent}
                        onChange={handleFormChange}
                        optionData={signatureFormOptions}
                        // canUpdateOnChangeRef
                        submittedResponse={stateData?.signatureResponse}
                      />
                    )}
                  </VStack>
                )}
              </VStack>
              {(licensedStates || []).length > 0 && (
                <Stack direction="column" space={4} style={viewStyle}>
                  <DisplayText
                    size={'smMedium'}
                    extraStyles={{
                      color: Colors.Custom.Gray500,
                      fontWeight: 500,
                      fontSize: 14,
                    }}
                    textLocalId="licensedStates"
                  />
                  <Stack direction="row" style={stackStyle} space={8}>
                    {licensedStates && licensedStates.map((stateData, index) => {
                      const name = licensedStateDetails.find((item) => item.abbr === stateData.stateCode)?.name || stateData.stateCode;
                        return (
                          <View
                            key={`StateTag_${index}`}
                            mt={2}
                            px={2}
                            py={1}
                            borderRadius={16}
                            bgColor={'gray.100'}
                          >
                            <Text>{name}</Text>
                          </View>
                        );
                      })}
                  </Stack>
                </Stack>
              )}
            </View>
          )}
        </Content>
      );
    };

    const renderBusinessHours = (): JSX.Element => {
        return (
          <VStack>
            <HStack space={2}>
              <Spacer />
              <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.PRIMARY,
                  onPress: () => {
                    onAddNewCard(false);
                  },
                  style: {marginLeft: 20},
                  leftIcon: (
                    <PlusIcon />
                  ),
                }}
                customProps={{
                  btnText: intl.formatMessage({
                    id: 'practiceAvailabilityAddSlots',
                  }),
                  withRightBorder: false,
                }}
              ></FoldButton>
            </HStack>
            <VStack>
              {componentState.overriddenAvailabilitiesByDateRangeKey
                ?.isAvailabilitiesPresent &&
                componentState.overriddenAvailabilitiesByDateRangeKey?.data?.map(
                  (data) => {
                    const availabilities = data.availabilities;
                    const localId = data.localId;
                    const beginDate = data.beginDate;
                    const endDate = data.endDate;
                    return (
                      <AddOrUpdateAvailabilitiesCard
                        key={localId}
                        cardId={localId}
                        disabled={props?.isEdit ? true : false}
                        isOverrideAvailability={true}
                        beginDate={beginDate}
                        endDate={endDate}
                        selectedTimezoneId={data.timezoneId}
                        accountLocations={componentState?.selectedUserAccountLocations || []}
                        availabilities={availabilities}
                        defaultTimezone={
                          componentState.defaultTimezone || ({} as ITimezone)
                        }
                        timezones={componentState.timezones || []}
                        defaultAvailability={defaultIAvailability}
                        onUpdateAvailabilities={onUpdateAvailabilities}
                        showErrors={componentState.showErrors}
                        isFromEditUser
                      />
                    );
                  }
                )}

              {componentState.availabilitiesByDaysOfWeek
                ?.isAvailabilitiesPresent &&
                componentState.availabilitiesByDaysOfWeek.data?.map((data) => {
                  const availabilities = data.availabilities;
                  const localId = data.localId;
                  const selectedDaysOfWeek = data.selectedDaysOfWeek;
                  return (
                    <AddOrUpdateAvailabilitiesCard
                      key={localId}
                      cardId={localId}
                      disabled={props?.isEdit ? true : false}
                      isOverrideAvailability={false}
                      selectedDaysOfWeek={selectedDaysOfWeek}
                      selectedTimezoneId={data.timezoneId}
                      accountLocations={componentState?.selectedUserAccountLocations || []}
                      availabilities={availabilities}
                      defaultTimezone={
                        componentState.defaultTimezone || ({} as ITimezone)
                      }
                      timezones={componentState.timezones || []}
                      defaultAvailability={defaultIAvailability}
                      onUpdateAvailabilities={onUpdateAvailabilities}
                      showErrors={componentState.showErrors}
                      isFromEditUser
                    />
                  );
                })}
              {!componentState.availabilitiesByDaysOfWeek
                ?.isAvailabilitiesPresent &&
                !componentState.overriddenAvailabilitiesByDateRangeKey
                  ?.isAvailabilitiesPresent && (
                  <Center
                    height={654}
                    width="full"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Text fontWeight={200} fontSize="lg" textAlign="center">
                      No schedule present.
                      <Text>
                        {'\n'}Please click{' '}
                        <Pressable
                          onPress={() => {
                            onAddNewCard(false);
                          }}
                        >
                          <Text color="primary.500">here</Text>
                        </Pressable>{' '}
                        to add new schedule.
                      </Text>
                    </Text>
                  </Center>
                )}
            </VStack>
          </VStack>
        );
    };

    const steps = [
        {
            title: (
                <Text
                    size={'xsMedium'}
                    style={{
                        backgroundColor: stateData.current == 0 ? Colors.primary[400] : Colors.Custom.Gray200,
                        borderRadius: 8,
                        color: stateData.current == 0 ? 'white' : 'black',
                        padding: 10,
                    }}
                >
                    {'Account'}
                </Text>
            ),
            content: renderAccountDetails(),
        },
    ];

    if (
      !getUserRoleExistByRoleCode(
        stateData.selectedRoles,
        USER_ROLE_CODES.EMPLOYER
      )) {
        steps.push(
          {
            title: (
                <Text
                    size={'xsMedium'}
                    style={{
                        backgroundColor: stateData.current == 1 ? Colors.primary[400] : Colors.Custom.Gray200,
                        borderRadius: 8,
                        color: stateData.current == 1 ? 'white' : 'black',
                        padding: 10,
                    }}
                >
                    {'Business hours'}
                </Text>
            ),
            content: renderBusinessHours(),
        },
        )
      }

    const handleStepClick = (index: number) => {
      if (index == 1) {
        const {validate, errors, updateUserData} = validates(
          {
            ...userFormData,
            phone: userFormData?.phone?.value,
            zipCodeId: userFormData?.zipcodeId,
            zipCode: userFormData?.zipcode,
            email: userFormData?.email?.value,
            fax: userFormData?.fax?.value,
            roles: stateData.selectedRoles || [],
          },
          validationsFlags
        );
        const isNPIRequired = validationsFlags.isNPIRequired;
        let isNPIError = userFormData?.npiError?.length > 0;
        if (isNPIRequired && !userFormData.NPI_NUMBER?.value) {
          isNPIError = true;
          setUserFormData((prev: any) => ({
            ...prev,
            npiError: 'NPI is required',
          }));
        }
        setErrors(errors);
        if (!validate || isNPIError) {
          setStateData({...stateData, errors: 'enterValidName'});
          setUserFormData((prev: any)=> {
            return {
              ...prev,
              associatedAccounts: updateUserData.associatedAccounts
            }
          })
        } else {
          setStateData((prev) => {
            return {
              ...prev,
              current: index,
            };
          });
        }
      } else {
        setStateData((prev) => {
          return {
            ...prev,
            current: index,
          };
        });
      }
    }

    return (
        <Drawer
            visible={stateData.showDrawer}
            style={reactStyles.drawer}
            width={drawerWidth}
            onClose={() => {
                setStateData((prev) => {
                    return {
                        ...prev,
                        showDrawer: false,
                    };
                });
                setTimeout(() => {
                    onClose();
                }, 200);
            }}
            title={
                <>
                    <ModalActionTitle
                        title={'editUser'}
                        titleColor={''}
                        buttonList={[
                            {
                                show: stateData.current === 1 ? true : false,
                                id: 3,
                                btnText: 'previous',
                                textColor: Colors.Custom.mainPrimaryPurple,
                                variant: BUTTON_TYPE.PRIMARY,
                                isTransBtn: false,
                                onClick: () => {
                                    setStateData((prev) => {
                                        return {
                                            ...prev,
                                            current: prev.current - 1,
                                        };
                                    });
                                },
                            },
                            {
                                show: true,
                                id: 1,
                                btnText: 'cancel',
                                textColor: Colors.Custom.mainSecondaryBrown,
                                variant: BUTTON_TYPE.SECONDARY,
                                isTransBtn: false,
                                onClick: () => {
                                    onCancel();
                                },
                            },
                            {
                                show: true,
                                id: 2,
                                btnText:
                                steps.length > 1
                                  ? stateData.current === 1
                                    ? 'submit'
                                    : 'next'
                                  : 'submit',
                                textColor: Colors.Custom.mainPrimaryPurple,
                                variant: BUTTON_TYPE.PRIMARY,
                                isTransBtn: false,
                                onClick: () => {
                                    handleSubmit();
                                },
                                isLoading: stateData.isSubmitting || timeZoneLoading || isGetAvailabilitiesAPILoading ,
                            },
                        ]}
                    />
                </>
            }
        >
            <Steps
                style={reactStyles.steps}
                labelPlacement="vertical"
                current={stateData.current}
            >
              {steps.map((item, index) => (
                <Step
                  key={index + 'step'}
                  style={reactStyles.step}
                  title={item.title}
                  onClick={() => {
                    handleStepClick(index);
                  }}
                />
              ))}
            </Steps>
            <View>{steps[stateData.current].content}</View>
        </Drawer>
    );
};

const reactStyles: Record<string, React.CSSProperties> = {
  step: {
    cursor: 'pointer',
  },
  steps: {
    width: 200,
    marginLeft: 0,
  },
  inputNumber: {
    width: '100%',
    borderRadius: 6,
  },
  drawer: {
    minWidth: 700
  }
};

const viewStyle: ViewStyle = {
  marginTop: 16
};

const stackStyle: ViewStyle = {
  flexWrap: 'wrap'
};

export default EditUserWithBusinessHours;
