import {StyleSheet} from 'react-native';
import {Colors} from '../../../../styles/Colors';

export const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    flexDirection: 'row',
    position: 'relative'
  },
  copyCheckBoxStyle: {
    position: 'absolute',
    right: 0,
    // transform: [{translateY: 50}]
  },
  avatarStyle: {
    justifyContent: 'center',
    marginBottom: 10,
  },
  rceMboxLeft: {
    backgroundColor: 'white',
    // alignSelf: 'flex-start',
    borderColor: Colors.Custom.Gray200,
    marginLeft: 0,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 8,
  },
  nativeRceMboxRight: {
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 5,
    paddingHorizontal: 16,
    paddingVertical: 15,
  },
  nativeRceMboxLeft: {
    borderTopLeftRadius: 5,
    borderTopRightRadius: 20,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    paddingHorizontal: 16,
    paddingVertical: 12,
    // boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
    shadowColor: `rgba(16, 24, 40, 0.05`,
    shadowRadius: 0.3,
    shadowOffset: {
      width: 0.5,
      height: 0.5,
    },
    shadowOpacity: 0.05,
  },

  rceContainerMbox: {
    flexDirection: 'column',
    overflow: 'hidden',
    minWidth: 240,
    maxWidth: '75%',
  },

  rceMboxClearPadding: {
    width: '100%',
    paddingBottom: 3,
  },

  rceMboxBody: {
    width: 'auto',
    margin: 0,
    padding: 0,
    position: 'relative',
  },

  rceMboxTitleClear: {
    width: 'auto',
    marginBottom: '5px',
  },

  rceMboxText: {
    width: 'auto',
    fontSize: 12,
    fontWeight: '300',
    textAlign: 'flex-end',
    wordBreak: 'break-word',
  },

  rceMboxTime: {
    textAlign: 'right',
    color: 'white',
    fontSize: 8,
    position: 'absolute',
    right: 15,
    bottom: 5,
  },

  rceMboxRight: {
    color: Colors.Custom.TextColor,
    shadowRadius: 0,
    borderWidth: 0,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 0,
  },

  rceMbox: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 8,
    flexDirection: 'column',
    marginBottom: 3,
    paddingRight: 16,
    paddingLeft: 16,
    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
  },

  rceEfaxMbox: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 8,
    flexDirection: 'column',
    paddingBottom: 6,
    paddingRight: 8,
    paddingLeft: 8,
    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
  },

  rceEfaxMboxWithTask: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
    flexDirection: 'column',
    paddingBottom: 6,
    paddingRight: 8,
    paddingLeft: 8,
    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
  },

  rceMboxWithTask: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
    flexDirection: 'column',
    paddingRight: 16,
    paddingLeft: 16,
    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
  },
  taskPreview: {
    height: 32,
    backgroundColor: Colors.Custom.Gray50,
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    marginRight: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    borderColor: Colors.Custom.Gray200,
    paddingRight: 14,
    paddingLeft: 14,
  },

  textStylingLeft: {
    fontWeight: '300',
    fontSize: 14,
    textAlign: 'left',
  },

  textStylingRight: {
    fontWeight: '300',
    fontSize: 14,
    textAlign: 'right',
  },
  rightDateStrStyle: {
    textAlign: 'right',
    marginTop: 2,
  },
  leftDateStrStyle: {
    textAlign: 'left',
    marginTop: 2,
  },
  markdownTextRight: {
    color: '#fff',
  },
  markdownTextLeft: {
    color: Colors.Custom.Gray900,
  },
  resendBtnContainerStyle: {
    alignItems: 'center',
    marginLeft: 4,
  },

  displayCardAvatarContainer: {
    marginBottom: 24,
    marginRight: -8,
  },

  displayCardAvatarContainer2: {
    marginBottom: 26,
  },

  displayCardAvatarContainer3: {
    marginBottom: 22,
    marginRight: 6,
  },

  messageReadStatusContainer: {
    marginLeft: 2,
  },

  w100: {
    width: '100%',
  },

  messageActionLabel: {
    marginLeft: 12,
    display: 'flex',
    alignItems: 'center',
  },

  communicationTypeContainer: {
    marginTop: 1,
    marginLeft: 10,
    alignItems: 'center',
  },

  messagePopoverContainer: {
    marginLeft: 5,
  },

  messageAttachmentContainer: {
    flexDirection: 'row',
    flex: 1,
    alignItems: 'flex-start',
    marginHorizontal: 7,
    marginRight: 30,
  },

  editMessageLabelContainer: {
    justifyContent: 'flex-end',
  },

  editMesssageLabelSubContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },

  failedMessageText: {
    fontSize: 14,
    fontWeight: '600',
    color: '#D92D20',
    marginLeft: 4,
  },

  emailSubjectContainer: {
    overflow: 'hidden',
    width: '60%',
  },
});
export const markdownStyleRight = StyleSheet.create({
  // The main container
  body: {},

  // Headings
  heading1: {
    color: '#fff',
    fontSize: 16,
  },
  heading2: {
    color: '#fff',
    fontSize: 16,
  },
  heading3: {
    color: '#fff',
    fontSize: 16,
  },
  heading4: {
    color: '#fff',
    fontSize: 16,
  },
  heading5: {
    color: '#fff',
    fontSize: 16,
  },
  heading6: {
    color: '#fff',
    fontSize: 16,
  },

  // Horizontal Rule
  hr: {
    color: '#fff',
    fontSize: 16,
  },

  // Emphasis
  strong: {
    color: '#fff',
    fontSize: 16,
  },
  em: {
    color: '#fff',
    fontSize: 16,
  },
  s: {
    color: '#fff',
    fontSize: 16,
  },

  // Blockquotes
  blockquote: {
    color: '#fff',
    fontSize: 16,
  },

  // Lists
  bullet_list: {color: '#fff'},
  ordered_list: {color: '#fff'},
  list_item: {
    color: '#fff',
    fontSize: 16,
  },
  // @pseudo class, does not have a unique render rule
  bullet_list_icon: {
    color: '#fff',
    fontSize: 16,
  },
  // @pseudo class, does not have a unique render rule
  bullet_list_content: {
    color: '#fff',
    fontSize: 16,
  },
  // @pseudo class, does not have a unique render rule
  ordered_list_icon: {
    color: '#fff',
    fontSize: 16,
  },
  // @pseudo class, does not have a unique render rule
  ordered_list_content: {
    color: '#fff',
    fontSize: 16,
  },

  // Code
  code_inline: {
    color: '#fff',
    fontSize: 16,
  },
  code_block: {
    color: '#fff',
    fontSize: 16,
  },
  fence: {
    color: '#fff',
    fontSize: 16,
  },

  // Tables
  table: {
    color: '#fff',
    fontSize: 16,
  },
  thead: {},
  tbody: {},
  th: {
    color: '#fff',
    fontSize: 16,
  },
  tr: {
    color: '#fff',
    fontSize: 16,
  },
  td: {
    color: '#fff',
    fontSize: 16,
  },

  // Links
  link: {
    backgroundColor:'rgba(255, 255, 255, 0.1)',
    fontSize: 16,
  },
  blocklink: {
    color: '#fff',
    fontSize: 16,
  },

  // Images
  image: {
    color: '#fff',
    fontSize: 16,
  },

  // Text Output
  text: {color: '#fff'},
  textgroup: {color: '#fff'},
  paragraph: {
    color: '#fff',
    fontSize: 16,
  },
  hardbreak: {
    color: '#fff',
    fontSize: 16,
  },
  softbreak: {color: '#fff'},

  // Believe these are never used but retained for completeness
  pre: {color: '#fff'},
  inline: {color: '#fff'},
  span: {color: '#fff'},
});
export const markdownStyleLeft = StyleSheet.create({
  // The main container
  body: {},

  // Headings
  heading1: {
    color: Colors.Custom.Gray900,
    fontSize: 15,
  },
  heading2: {
    color: Colors.Custom.Gray900,
    fontSize: 15,
  },
  heading3: {
    color: Colors.Custom.Gray900,
    fontSize: 15,
  },
  heading4: {
    color: Colors.Custom.Gray900,
    fontSize: 15,
  },
  heading5: {
    color: Colors.Custom.Gray900,
    fontSize: 15,
  },
  heading6: {
    color: Colors.Custom.Gray900,
    fontSize: 15,
  },

  // Horizontal Rule
  hr: {
    color: Colors.Custom.Gray900,
    fontSize: 15,
  },

  // Emphasis
  strong: {
    color: Colors.Custom.Gray900,
    fontSize: 15,
  },
  em: {
    color: Colors.Custom.Gray900,
    fontSize: 15,
  },
  s: {
    color: Colors.Custom.Gray900,
    fontSize: 15,
  },

  // Blockquotes
  blockquote: {
    color: Colors.Custom.Gray900,
    fontSize: 15,
  },

  // Lists
  bullet_list: {color: Colors.Custom.Gray900},
  ordered_list: {color: Colors.Custom.Gray900},
  list_item: {
    color: Colors.Custom.Gray900,
    fontSize: 15,
  },
  // @pseudo class, does not have a unique render rule
  bullet_list_icon: {
    color: Colors.Custom.Gray900,
    fontSize: 15,
  },
  // @pseudo class, does not have a unique render rule
  bullet_list_content: {
    color: Colors.Custom.Gray900,
    fontSize: 15,
  },
  // @pseudo class, does not have a unique render rule
  ordered_list_icon: {
    color: Colors.Custom.Gray900,
    fontSize: 15,
  },
  // @pseudo class, does not have a unique render rule
  ordered_list_content: {
    color: Colors.Custom.Gray900,
    fontSize: 15,
  },

  // Code
  code_inline: {
    color: Colors.Custom.Gray900,
    fontSize: 15,
  },
  code_block: {
    color: Colors.Custom.Gray900,
    fontSize: 15,
  },
  fence: {
    color: Colors.Custom.Gray900,
    fontSize: 15,
  },

  // Tables
  table: {
    color: Colors.Custom.Gray900,
    fontSize: 15,
  },
  thead: {color: Colors.Custom.Gray900},
  tbody: {color: Colors.Custom.Gray900},
  th: {
    color: Colors.Custom.Gray900,
    fontSize: 15,
  },
  tr: {
    color: Colors.Custom.Gray900,
    fontSize: 15,
  },
  td: {
    color: Colors.Custom.Gray900,
    fontSize: 15,
  },

  // Links
  link: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontSize: 15,
  },
  blocklink: {
    color: 'blue',
    fontSize: 15,
  },

  // Images
  image: {
    color: Colors.Custom.Gray900,
    fontSize: 15,
  },

  // Text Output
  text: {color: Colors.Custom.Gray900},
  textgroup: {color: Colors.Custom.Gray900},
  paragraph: {
    color: Colors.Custom.Gray900,
    fontSize: 15,
  },
  hardbreak: {
    color: Colors.Custom.Gray900,
    fontSize: 15,
  },
  softbreak: {color: Colors.Custom.Gray900},

  // Believe these are never used but retained for completeness
  pre: {color: Colors.Custom.Gray900},
  inline: {color: Colors.Custom.Gray900},
  span: {color: Colors.Custom.Gray900},
});

export const markdownReplyStyle = StyleSheet.create({
  // The main container
  body: {maxHeight:50, paddingBottom:5,},

  // Headings
  heading1: {
    color: Colors.Custom.Gray400,
    fontSize: 16,
   fontStyle:'italic'
  },
  heading2: {
    color: Colors.Custom.Gray400,
    fontSize: 16,
    fontStyle:'italic'
  },
  heading3: {
    color: Colors.Custom.Gray400,
    fontSize: 16,
    fontStyle:'italic'
  },
  heading4: {
    color: Colors.Custom.Gray400,
    fontSize: 16,
    fontStyle:'italic'
  },
  heading5: {
    color: Colors.Custom.Gray400,
    fontSize: 16,
    fontStyle:'italic'
  },
  heading6: {
    color: Colors.Custom.Gray400,
    fontSize: 16,
    fontStyle:'italic'
  },

  // Horizontal Rule
  hr: {
    color: Colors.Custom.Gray400,
    fontSize: 16,
    fontStyle:'italic'
  },

  // Emphasis
  strong: {
    color: Colors.Custom.Gray400,
    fontSize: 16,
    fontStyle:'italic'
  },
  em: {
    color: Colors.Custom.Gray400,
    fontSize: 16,
    fontStyle:'italic'
  },
  s: {
    color: Colors.Custom.Gray400,
    fontSize: 16,
    fontStyle:'italic'
  },

  // Blockquotes
  blockquote: {
    color: Colors.Custom.Gray400,
    fontSize: 16,
    fontStyle:'italic'
  },

  // Lists
  bullet_list: {color: Colors.Custom.Gray400,fontStyle:'italic'},
  ordered_list: {color: Colors.Custom.Gray400,fontStyle:'italic'},
  list_item: {
    color: Colors.Custom.Gray400,
    fontSize: 16,
    fontStyle:'italic'  },
  // @pseudo class, does not have a unique render rule
  bullet_list_icon: {
    color: Colors.Custom.Gray400,
    fontSize: 16,
    fontStyle:'italic'  },
  // @pseudo class, does not have a unique render rule
  bullet_list_content: {
    color: Colors.Custom.Gray400,
    fontSize: 16,
    fontStyle:'italic'  },
  // @pseudo class, does not have a unique render rule
  ordered_list_icon: {
    color: Colors.Custom.Gray400,
    fontSize: 16,
    fontStyle:'italic'  },
  // @pseudo class, does not have a unique render rule
  ordered_list_content: {
    color: Colors.Custom.Gray400,
    fontSize: 16,
    fontStyle:'italic'  },

  // Code
  code_inline: {
    color: Colors.Custom.Gray400,
    fontSize: 16,
    fontStyle:'italic'  },
  code_block: {
    color: Colors.Custom.Gray400,
    fontSize: 16,
    fontStyle:'italic'  },
  fence: {
    color: Colors.Custom.Gray400,
    fontSize: 16,
    fontStyle:'italic'  },

  // Tables
  table: {
    color: Colors.Custom.Gray400,
    fontSize: 16,
    fontStyle:'italic'  },
  thead: {color: Colors.Custom.Gray400,fontStyle:'italic'},
  tbody: {color: Colors.Custom.Gray400,fontStyle:'italic'},
  th: {
    color: Colors.Custom.Gray400,
    fontSize: 16,
    fontStyle:'italic'  },
  tr: {
    color: Colors.Custom.Gray400,
    fontSize: 16,
    fontStyle:'italic'  },
  td: {
    color: Colors.Custom.Gray400,
    fontSize: 16,
    fontStyle:'italic'  },

  // Links
  link: {
    color: Colors.Custom.Gray400,
    fontSize: 16,
    fontStyle:'italic'  },
  blocklink: {
    color: Colors.Custom.Gray400,
    fontSize: 16,
    fontStyle:'italic'  },

  // Images
  image: {
    color: Colors.Custom.Gray400,
    fontSize: 16,
    fontStyle:'italic'  },

  // Text Output
  text: {color: Colors.Custom.Gray400, fontStyle:'italic'},
  textgroup: {color: Colors.Custom.Gray400, fontStyle:'italic'},
  paragraph: {
    color: Colors.Custom.Gray400,
    fontSize: 16,
     fontStyle:'italic'
  },
  hardbreak: {
    color: Colors.Custom.Gray400,
    fontSize: 16,
    fontStyle:'italic'
  },
  softbreak: {color: Colors.Custom.Gray400, fontStyle:'italic'},

  // Believe these are never used but retained for completeness
  pre: {color: Colors.Custom.Gray400, fontStyle:'italic'},
  inline: {color: Colors.Custom.Gray400, fontStyle:'italic'},
  span: {color: Colors.Custom.Gray400, fontStyle:'italic'},
});
export const markdownReplyInListStyle = StyleSheet.create({
  // The main container
  body: {maxHeight:40, paddingBottom:5,},

  // Headings
  heading1: {
    color: Colors.Custom.Gray400,
    fontSize: 12,
   fontStyle:'italic'
  },
  heading2: {
    color: Colors.Custom.Gray400,
    fontSize: 12,
    fontStyle:'italic'
  },
  heading3: {
    color: Colors.Custom.Gray400,
    fontSize: 12,
    fontStyle:'italic'
  },
  heading4: {
    color: Colors.Custom.Gray400,
    fontSize: 12,
    fontStyle:'italic'
  },
  heading5: {
    color: Colors.Custom.Gray400,
    fontSize: 12,
    fontStyle:'italic'
  },
  heading6: {
    color: Colors.Custom.Gray400,
    fontSize: 12,
    fontStyle:'italic'
  },

  // Horizontal Rule
  hr: {
    color: Colors.Custom.Gray400,
    fontSize: 12,
    fontStyle:'italic'
  },

  // Emphasis
  strong: {
    color: Colors.Custom.Gray400,
    fontSize: 12,
    fontStyle:'italic'
  },
  em: {
    color: Colors.Custom.Gray400,
    fontSize: 12,
    fontStyle:'italic'
  },
  s: {
    color: Colors.Custom.Gray400,
    fontSize: 12,
    fontStyle:'italic'
  },

  // Blockquotes
  blockquote: {
    color: Colors.Custom.Gray400,
    fontSize: 12,
    fontStyle:'italic'
  },

  // Lists
  bullet_list: {color: Colors.Custom.Gray400,fontStyle:'italic'},
  ordered_list: {color: Colors.Custom.Gray400,fontStyle:'italic'},
  list_item: {
    color: Colors.Custom.Gray400,
    fontSize: 12,
    fontStyle:'italic'  },
  // @pseudo class, does not have a unique render rule
  bullet_list_icon: {
    color: Colors.Custom.Gray400,
    fontSize: 12,
    fontStyle:'italic'  },
  // @pseudo class, does not have a unique render rule
  bullet_list_content: {
    color: Colors.Custom.Gray400,
    fontSize: 12,
    fontStyle:'italic'  },
  // @pseudo class, does not have a unique render rule
  ordered_list_icon: {
    color: Colors.Custom.Gray400,
    fontSize: 12,
    fontStyle:'italic'  },
  // @pseudo class, does not have a unique render rule
  ordered_list_content: {
    color: Colors.Custom.Gray400,
    fontSize: 12,
    fontStyle:'italic'  },

  // Code
  code_inline: {
    color: Colors.Custom.Gray400,
    fontSize: 12,
    fontStyle:'italic'  },
  code_block: {
    color: Colors.Custom.Gray400,
    fontSize: 12,
    fontStyle:'italic'  },
  fence: {
    color: Colors.Custom.Gray400,
    fontSize: 12,
    fontStyle:'italic'  },

  // Tables
  table: {
    color: Colors.Custom.Gray400,
    fontSize: 12,
    fontStyle:'italic'  },
  thead: {color: Colors.Custom.Gray400,fontStyle:'italic'},
  tbody: {color: Colors.Custom.Gray400,fontStyle:'italic'},
  th: {
    color: Colors.Custom.Gray400,
    fontSize: 12,
    fontStyle:'italic'  },
  tr: {
    color: Colors.Custom.Gray400,
    fontSize: 12,
    fontStyle:'italic'  },
  td: {
    color: Colors.Custom.Gray400,
    fontSize: 12,
    fontStyle:'italic'  },

  // Links
  link: {
    color: Colors.Custom.Gray400,
    fontSize: 12,
    fontStyle:'italic'  },
  blocklink: {
    color: Colors.Custom.Gray400,
    fontSize: 12,
    fontStyle:'italic'  },

  // Images
  image: {
    color: Colors.Custom.Gray400,
    fontSize: 12,
    fontStyle:'italic'  },

  // Text Output
  text: {color: Colors.Custom.Gray400, fontStyle:'italic'},
  textgroup: {color: Colors.Custom.Gray400, fontStyle:'italic'},
  paragraph: {
    color: Colors.Custom.Gray400,
    fontSize: 12,
     fontStyle:'italic'
  },
  hardbreak: {
    color: Colors.Custom.Gray400,
    fontSize: 12,
    fontStyle:'italic'
  },
  softbreak: {color: Colors.Custom.Gray400, fontStyle:'italic'},

  // Believe these are never used but retained for completeness
  pre: {color: Colors.Custom.Gray400, fontStyle:'italic'},
  inline: {color: Colors.Custom.Gray400, fontStyle:'italic'},
  span: {color: Colors.Custom.Gray400, fontStyle:'italic'},
});

export const cssProperties: Record<string, React.CSSProperties> = {
  overlayInnerStyle: {
    bottom: 3,
    borderRadius: 16,
    padding: 0,
  },
};

export const getConditionalStyles = (
  position: string,
  isCheckBoxVisible: boolean
) => {
  return StyleSheet.create({
    messageAttachmentContainer: {
      flexDirection: 'row',
      flex: 1,
      alignItems: 'flex-end',
      marginRight: position == 'left' ? 30 : isCheckBoxVisible ? 30 : 0,
    },
    messageSenderNameContainer: {
      flexDirection: 'row',
      marginLeft: position == 'left' ? 4 : 0,
      marginRight: position != 'left' ? 4 : 0,
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    displayCardAvatarContainer4: {
      marginBottom: 22,
      position: 'absolute',
      right: isCheckBoxVisible ? 28 : 0,
    },
  });
};
