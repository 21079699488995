import React, {useEffect, useState} from 'react';
import {View, Text, StyleSheet} from 'react-native';
import UserListView from '../../RightSideContainer/TeamInbox/Conversations/ConversationActionView/UserListView';
import {ILeaveGroupView} from './interface';
import {getAccountUserByUserId} from '../../RightSideContainer/TeamInbox/Conversations/ConversationChannelNew/ConversationSidebarUtils';
import {GROUP_MEMBER_TYPE} from '../../../constants/StringConst';
import {
  IGroupMember,
  IGroupUser,
} from '../../../services/Conversations/interfaces';
import {getUserId} from '../../../utils/commonUtils';
import SearchBar from '../SearchBar/SearchBar';
import { Colors } from '../../../styles';
import { useIntl } from 'react-intl';
import { testID } from '../../../testUtils';

const LeaveGroupView = (props: ILeaveGroupView) => {
  const userId = getUserId();
  const intl = useIntl()
  const [state, setState] = useState({
    users: [] as IGroupUser[],
  });

  useEffect(() => {
    getUsersList(props?.selectedConversation?.groupConversation?.groupMembers);
  }, []);

  const getUsersList = (groupMembers?: IGroupMember[]) => {
    const groupMemberList: IGroupUser[] = [];
    if (groupMembers) {
      groupMembers?.map((item: IGroupMember) => {
        if (item.groupUserType.code === GROUP_MEMBER_TYPE.USER) {
          if (
            item?.user &&
            getAccountUserByUserId(
              item?.user?.accountUsers,
              item?.user?.id
            )?.[0]?.isActive === true &&
            item?.user?.id !== userId
          ) {
            groupMemberList.push(item?.user);
          }
        }
      });
    }
    setState((prev) => {
      return {
        ...prev,
        users: groupMemberList,
      };
    });
  };
  return (
    <View>
      <View style={styles.marginVertical}>
        <View style={styles.titleContainer}>
          <Text
            style={styles.titleText}
            {...testID('LeaveGroupPopoverTitle')}
          >
            {intl.formatMessage({id: 'leaveGroupPopoverTitle'})}
          </Text>
        </View>
        <SearchBar
          onChange={(value: string) => {
            const searchString = value;
            if (state?.users?.length) {
              if (searchString.length) {
                const filteredGroupUserList: IGroupUser[] = [];
                state?.users?.map((item) => {
                  const name = item?.name.toLowerCase() || '';
                  const textData = searchString.toLowerCase();
                  if (name.includes(textData)) {
                    filteredGroupUserList.push(item);
                  }
                });
                setState((prev) => {
                  return {
                    ...prev,
                    users: filteredGroupUserList,
                  };
                });
              } else {
                getUsersList(
                  props?.selectedConversation?.groupConversation?.groupMembers
                );
              }
            }
          }}
        />
      </View>
      <UserListView
        conversationId={props?.selectedConversation?.id}
        onActionPerformed={(actionCode: string, actionData: any) => {
          props?.onActionPerformed(actionCode, actionData);
        }}
        loadingStates={false}
        users={state.users}
        selectionLoading={false}
        selectedUsers={{}}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  marginVertical: {
    marginVertical: 8,
  },
  titleContainer: {
    paddingBottom: 12,
  },
  titleText: {
    fontSize: 14,
    fontWeight: '600',
    fontFamily: 'Manrope-Medium',
    color: Colors.Custom.Gray500,
  },
});

export default LeaveGroupView;
