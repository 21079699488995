import React from "react";
import { HStack, Skeleton, VStack } from "native-base";

const TimeLineSkeletonLoader = () => {
    const dummyArray = [1, 2, 3];
    return (
      <VStack m={2}>
        <Skeleton h={10} rounded={'md'} />
        {dummyArray.map((item, index) => (
          <HStack m={4} key={item + index}>
            <Skeleton.Text flex={2} lines={1} />
            <VStack flex={8} mx={3}>
              <Skeleton.Text flex={2} lines={3} />
              <Skeleton.Text flex={2} lines={3} mt={2} />
            </VStack>
          </HStack>
        ))}
      </VStack>
    );
  };



  export default TimeLineSkeletonLoader;
