import React from 'react';
import { Svg, Path } from 'react-native-svg';

const RightArrowIconSvg = () => {
  return (
    <Svg
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <Path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        d="M3.333 8h9.334m0 0L8 3.333M12.667 8L8 12.667"
      ></Path>
    </Svg>
  );
}

export default RightArrowIconSvg