export default [
  {
    type: 'oldtextfield',
    input: true,
    key: 'prefix',
    label: 'Prefix',
  },
  {
    type: 'oldtextfield',
    input: true,
    key: 'suffix',
    label: 'Suffix',
  },
];
