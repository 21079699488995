import {Formio} from '@foldhealth/formiojs';
import BasicFields from '../EditFormFields/BasicFields';
import ConditionalComponents from '../EditFormFields/ConditionalFields';
import KeyField from '../EditFormFields/KeyField';
import {FormComponentLabelEnum} from '../FormEnum';

const field = Formio.Components.components.field;
Formio.Components.components.select.schema = (...extend: any) => {
  return field.schema(
    {
      type: 'select',
      label: FormComponentLabelEnum.select,
      key: 'select',
      idPath: 'id',
      data: {
        values: [{label: '', value: ''}],
        json: '',
        url: '',
        resource: '',
        custom: '',
      },
      clearOnRefresh: false,
      limit: 100,
      dataSrc: 'values',
      valueProperty: '',
      lazyLoad: true,
      filter: '',
      searchEnabled: true,
      searchDebounce: 0.3,
      searchField: '',
      minSearch: 0,
      readOnlyValue: false,
      authenticate: false,
      ignoreCache: false,
      template: '<span>{{ item.label }}</span>',
      selectFields: '',
      selectThreshold: 0.3,
      uniqueOptions: false,
      tableView: true,
      fuseOptions: {
        include: 'score',
        threshold: 0.3,
      },
      validate: {
        onlyAvailableItems: false,
        customMessage: 'Field is required',
      },
      indexeddb: {
        filter: {},
      },
      customOptions: {},
      useExactSearch: false,
    },
    ...extend
  );
};

Formio.Components.components.select.editForm = function () {
  return {
    key: 'display',
    components: [
      ...BasicFields,
      {
        type: 'datagrid',
        input: true,
        // label: 'Values',
        label: 'Options',
        key: 'data.values',
        tooltip:
          'Values to use as the data source. Labels are shown in the select field. Values are the corresponding values saved with the submission.',
        weight: 10,
        reorder: true,
        defaultValue: [{label: '', value: ''}],
        components: [
          {
            label: 'Label',
            key: 'label',
            input: true,
            type: 'oldtextfield',
          },
          {
            label: 'Value',
            key: 'value',
            input: true,
            type: 'oldtextfield',
            allowCalculateOverride: true,
            hidden: true,
            clearOnHide: false,
            calculateValue: 'value = _.camelCase(row.label);',
          },
        ],
        conditional: {
          json: {'===': [{var: 'data.dataSrc'}, 'values']},
        },
      },
      // KEPT FOR FUTURE
      // {
      //   type: 'checkbox',
      //   label: 'Hide Label',
      //   tooltip:
      //     'Hide the label or title of this component. This allows you to show the label in the form builder, but not when the form is shown to the member.',
      //   key: 'hideLabel',
      //   input: true,
      // },
      ...ConditionalComponents,
      ...KeyField,
    ],
  };
};
