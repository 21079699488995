import React from "react";

const SendFormActionBtn = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#667085"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M13 2H6c-.53 0-1.04.21-1.41.59C4.22 2.97 4 3.47 4 4v16c0 .53.21 1.04.59 1.41.38.37.88.59 1.41.59h12c.53 0 1.04-.21 1.41-.59.37-.38.59-.88.59-1.41V9m-7-7l7 7m-7-7v7h7m-8 9l3-3m0 0l-3-3m3 3H9"
      ></path>
    </svg>
  );
}

export default SendFormActionBtn;
