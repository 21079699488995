import {useContext} from 'react';
import React from 'react';
import {Center, Spinner, useToast, View} from 'native-base';
import {useEffect, useState} from 'react';
import {IContact, IConversationData, IConversationResponse} from '../../RightSideContainer/TeamInbox/Conversations/interfaces';
import {getContactDataAndType} from '../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MessagingUtils';

import {IConversationMainViewDrawerProps, IConversationMainViewDrawerState} from './interface';
import {useIntl} from 'react-intl';
import {getAccountId, getUserData, getUserId, getUserUUID, showInfoOnMessageHeader} from '../../../utils/commonUtils';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {useLazyQuery} from '@apollo/client';
import {showToast, ToastType} from '../../../utils/commonViewUtils';
import GroupQueries from '../../../services/Conversations/GroupQueries';
import ConversationView from './ConversationView';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../constants/Configs';
import { GET_CARE_TEAM } from '../../../services/CareTeam/CareTeamQueries';
import { GET_USERS_WITH_IDS } from '../../../services/User/UserQueries';
import { IUser } from '../../../Interfaces';
import InstantChatView from '../ChatDrawer/InstantChatView';
import { isWeb } from '../../../utils/platformCheckUtils';

const ConversationMainView = (props: IConversationMainViewDrawerProps) => {
  const {conversationUuid, selectedConversation, isDrawerVisible, onActionPerformed} = props;
  const createGroupToast = useToast();
  const intl = useIntl();
  const accountId = getAccountId();
  const userUuid = getUserUUID();
  const userId = getUserId();
  const showInfoOnHeaderData = showInfoOnMessageHeader();

  const [componentState, setComponentState] = useState<IConversationMainViewDrawerState>({
    selectedConversation: {} as IConversationData,
    contactData: {} as IContact,
    isLoading: true,
  });

  const [getGroupMemberConversations] = useLazyQuery<IConversationResponse>(GroupQueries.GetSingleGroupConversations, {
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
  });

  const [getCareTeam] = useLazyQuery(GET_CARE_TEAM, {
    fetchPolicy: 'no-cache',
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
  });
  const [getUserDetailData] = useLazyQuery(GET_USERS_WITH_IDS);

  const emptyRespHandler = () => {
    setComponentState((prev) => {
      return {
        ...prev,
        isLoading: false
      };
    });
    showToast(
      createGroupToast,
      intl.formatMessage({id: 'apiErrorMsg'}),
      ToastType.error
    );
  };
  const getPCPDetails = async (contactData: IContact) => {
    if (contactData?.uuid) {
      const careTeamResponse = await getCareTeam({
        variables: {
          contactId: contactData?.uuid,
        },
      });
      if (careTeamResponse?.data?.careTeams[0]?.primaryCarePhysicianUserId) {
        const careTeamPCPUsers =
          careTeamResponse?.data?.careTeams?.[0]?.userPool?.userPoolUsers?.filter(
            (user: any) =>
              user.userId ===
              careTeamResponse?.data?.careTeams[0]?.primaryCarePhysicianUserId
          ) || [];

        if (careTeamPCPUsers?.length) {
          const userData = await getUserDetailData({
            variables: {
              userIds: [careTeamPCPUsers?.[0]?.userId],
              accountId: accountId,
            },
          });
          return userData?.data?.users?.[0]
        }
      }
    }
    return {};
  };

  const getSingleGroupConversation = async () => {
    setComponentState((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });
    const groupResp = await getGroupMemberConversations({
      variables: {
        limit: 1,
        offset: 0,
        loginUserId: userId,
        loginUserUuid: userUuid,
        whereCondition: {
          accountId: {
            _eq: accountId
          },
          uuid: {
            _eq: conversationUuid
          },
        },
      },
    }).catch(() => {
      emptyRespHandler();
    });
    if (groupResp?.data) {
      const allConversationList = groupResp?.data?.conversations || [];
      if (allConversationList[0]?.id) {
        const selectedConversation = allConversationList[0];
        const contactInfo = getContactDataAndType(selectedConversation);
        const contactData: any = contactInfo.contactData;
        setComponentState((prev) => {
          return {
            ...prev,
            isLoading: false,
            selectedConversation: selectedConversation,
            contactData: contactData || {},
          };
        });
      }
    } else {
      emptyRespHandler();
    }
  };



  useEffect(() => {
    getSingleGroupConversation();
  }, [conversationUuid]);

  return (
    <View>
      {componentState.isLoading ? (
        <View
          height={'full'}
          width={'full'}
          position="absolute"
          zIndex={10}
          bg={'coolGray.50:alpha.70'}>
          <Center
            top={'30%'}
            justifyContent="center"
            alignItems="center"
            alignContent="center">
            <Spinner size="lg" />
          </Center>
        </View>
      ) : isWeb() ? (
        <InstantChatView
          selectedConversation={componentState?.selectedConversation}
          contactData={componentState.contactData}
          isLoading={false}
          isDrawerVisible={isDrawerVisible}
          isInstantChatView={true}
          onActionPerformed={onActionPerformed}
        />
      ) : (
        <ConversationView
          selectedConversation={componentState.selectedConversation}
          contactData={componentState.contactData}
          isDrawerVisible={isDrawerVisible}
          onActionPerformed={onActionPerformed}
        />
      )}
    </View>
  );
};
export default ConversationMainView;
