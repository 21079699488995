import React from "react";
import { ICommonSvgProps } from "../interfaces";

const AppointmentActionSvg = (props: ICommonSvgProps)=> {
  const strokeColor = props.isActive ? '#667085' : props.customStrokeColor ? props.customStrokeColor : '#D0D5DD';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      key={'AppointmentActionSvg'}
      style={{width: 'inherit', height: 'inherit'}}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M21 12V6a2 2 0 00-2-2H5a2 2 0 00-2 2v14a2 2 0 002 2h6m5-20v4M8 2v4m-5 4h18m-3.5 6.8v1.7l1.5 1m3-1a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0z"
      ></path>
    </svg>
  );
}

export default AppointmentActionSvg;
