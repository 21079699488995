import {StyleSheet} from 'react-native';
import {Colors} from '../../../styles';

export const styles = StyleSheet.create({
  listContainer: {
    paddingTop: 2,
    paddingBottom: 2,
  },

  OnCreatLink: {
    marginVertical: 10,
    color: Colors.Custom.PrimaryColor,
  },
  iconBtnView: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  iconBtnStyle: {
    padding: 0,
    marginTop: 1,
    marginLeft: 1.5,
  },
  tagListItem: {
    paddingTop: 6,
    paddingRight: 10,
    paddingBottom: 4,
    marginHorizontal: 16,
    maxHeight: 200,
    cursor: 'pointer',
    minHeight: 40,
  },
  contactListItem: {
    paddingBottom: 4,
    paddingLeft: 6,
    maxHeight: 200,
    cursor: 'pointer',
    minHeight: 40,
  },
  submitElement: {
    width: 100,
    paddingTop: 10,
    marginBottom: 5,
  },
  cancelElement: {
    width: 100,
    paddingLeft: 10,
    paddingTop: 10,
    marginBottom: 5,
  },
});
