import React from "react";
import { isWeb } from "../../../utils/platformCheckUtils";

const WarningSvgIcon = (props: {
  isActive?: boolean;
  customActiveColor?: string;
  customColor?: string;
}) => {
  const strokeColor = props.isActive
  ? props.customActiveColor
    ? props.customActiveColor
    : ''
  : props.customColor
  ? props.customColor
  : '#D94D55';
  return (
    <>
      {isWeb() ? (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{width: 'inherit', height: 'inherit'}}
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          fill={strokeColor}
          d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"
        ></path>
      </svg>
      ) : null}
    </>
  );
}

export default WarningSvgIcon;
