import React from 'react';
import {Colors} from '../../../styles';
import {ICommonSvgProps} from './interfaces';

const ListIconSvg = (props: ICommonSvgProps) => {
  const {isEnabled} = props;

  return (
    <svg
      width="18"
      height="12"
      viewBox="0 0 18 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.66667 1H16.5M5.66667 6H16.5M5.66667 11H16.5M1.5 1H1.50833M1.5 6H1.50833M1.5 11H1.50833"
        stroke={props?.customStrokeColor ? props?.customStrokeColor : isEnabled ? 'white' : '#344054'}
        stroke-width="1.5"
        stroke-linecap="round"
      stroke-linejoin="round"
      />
    </svg>
  );
};

export default ListIconSvg;
