import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  userInputFieldValueText: {
    fontWeight: '300',
    maxWidth: 200,
    fontSize: 12,
    color: '#344054',
  },
  editButtonText: {
    color: 'blue',
  },
  sideMenuInputFieldLableText: {
    fontWeight: '400',
    bold: 14,
  },
  nodeOutputLableText: {
    fontWeight: '400',
    bold: 14,
    marginRight: '5px',
  },
});
