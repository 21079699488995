import {useLazyQuery} from '@apollo/client';
import {Select, Spin} from 'antd';
import {useEffect, useState} from 'react';
import {getAccountUUID} from '../../../utils/commonUtils';
import {ICustomContactType} from './interface';
import ContactsQueries from '../../../services/Contacts/ContactsQueries';
import {getActiveContactTypeFormList} from './contactTypeSearchUtils';

const ContactTypeSearch = (props: any) => {
  const [componentState, setComponentState] = useState({
    contactTypeData: [],
    loading: true,
  });

  useEffect(() => {
    getNumberList();
  }, []);

  const [getUsersData] = useLazyQuery<ICustomContactType>(ContactsQueries.GetAllCustomContactTypes, {
    variables: {
      accountUuid: getAccountUUID(),
    },
  });

  const getNumberList = async () => {
    const contactTypeList = await getUsersData();
    const ActiveContactTypeList = getActiveContactTypeFormList(contactTypeList?.data?.customContactTypes || [])
    setComponentState((prev: any) => {
      return {
        ...prev,
        contactTypeData: ActiveContactTypeList
      };
    });
  };

  return (
    <>
      <Select
        dropdownMatchSelectWidth={3}
        size="large"
        allowClear
        showSearch={true}
        mode="multiple"
        filterOption={(input, option) =>
          (option!.children as unknown as string)
            .toLowerCase()
            .includes(input.toLowerCase())
        }
        value={(props?.value||[]).map((info:any)=>{return info?.id;}) }
        onChange={(valueList: any) => {
          if (valueList && valueList.length > 0) {
            const contactTypeList = valueList?.map((id:any)=>{
              const contactType = componentState.contactTypeData?.find((contactType: any)=>{
                return contactType?.id == id
              })
              return contactType;
            })
            props.onChange(contactTypeList);
          } else {
            props.onChange(undefined);
          }
        }}
        placeholder="Search Contact Types"
        notFoundContent={componentState.loading && <Spin size="small" />}
        style={{height: '36px'}}
        maxTagCount={'responsive'}
      >
        {componentState.contactTypeData.map((contactTypeObject: any) => {
          return (
            <Select.Option
              key={`${contactTypeObject.id}`}
              value={contactTypeObject.id}
            >
              {contactTypeObject.value === 'Customer' ? 'Patient' : contactTypeObject.value}
            </Select.Option>
          );
        })}
      </Select>
    </>
  );
};

export default ContactTypeSearch;
