import React from 'react'
import NoDataBackgroundSvg from '../../../../../../../assets/svg/NodataBackgroundSVG'
import {Text, View} from 'native-base'
import {Colors} from '../../../../../../../styles';

const NoDataFoundCard = () => {

  return (
    <View w={'100%'} justifyContent={'center'} alignItems={'center'} my={4} gap={2}>
      <NoDataBackgroundSvg />
      <Text color={Colors.Custom.Gray400}>No data available for this time range</Text>
    </View>
  );
}

export default NoDataFoundCard