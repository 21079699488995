import {
  IContactFormResponseData,
  IFormSender,
} from '../../PersonOmniView/MiddleContainer/FormResponseTableView/interfaces';
import {
  Box,
  FlatList,
  HStack,
  Icon,
  Pressable,
  Skeleton,
  Spacer,
  Text,
  useToast,
  View,
  VStack,
} from 'native-base';
import {Dimensions} from 'react-native';
import AntIcon from 'react-native-vector-icons/AntDesign';
import FeatherIcon from 'react-native-vector-icons/Feather';
import {ITableTopBarProps, TableTopBar} from '../TableTopBar';
import {Colors} from '../../../styles/Colors';
import RiskScoreDisplay from '../../RightSideContainer/Forms/FormBuilderWidget/RiskScoreDisplay/RiskScoreDisplay';
import PublicFormView from '../../PublicPages/PublicForm/PublicFormView';
import {forEachExtensiveFormComponent} from '../../RightSideContainer/Forms/FormBuilderWidget/AddOrUpdateForm/AddOrUpdateFormHelper';

import {getDateStrFromFormat} from '../../../utils/DateUtils';
import React, {useContext, useEffect, useState} from 'react';
import {IPublicFormData} from '../../PublicPages/PublicForm/PublicFormInterfaces';
import {FormsQueries} from '../../../services';
import {useLazyQuery, useQuery} from '@apollo/client';
import {
  CARESTUDIO_APOLLO_CONTEXT,
  HASURA_SECRET,
} from '../../../constants/Configs';
import {IFormScore} from './interface';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {
  getMlovIdFromCode,
  getMlovListFromCategory,
  getMlovValueFromId,
} from '../../../utils/mlovUtils';
import {BUTTON_TYPE, MLOV_CATEGORY} from '../../../constants';
import {FORM_STATUS_CODE} from '../../../constants/MlovConst';
import {getAccountUUID} from '../../../utils/commonUtils';
import {Button, Drawer} from 'antd';
import {ModalActionTitle} from '../ModalActionTitle/ModalActionTitle';
import {IContact} from '../../RightSideContainer/TeamInbox/Conversations/interfaces';
import MemberInfoListItem from '../MemberInfoListItem/MemberInfoListItem';
import {getFormSenderDetail} from '../../RightSideContainer/TeamInbox/Conversations/MessagingContactDetails/ContactDetailsTabs/ActivityTimeLine/ActivityAdditionalInfo/CommonComponent/FormResponse/FormUtils';
import {ToastType, showToast} from '../../../utils/commonViewUtils';
import {useIntl} from 'react-intl';
import { GET_CONTACTS_NAME_BY_UUID } from '../../../services/Contacts/ContactsQueries';
import { REQUESTED_BY_TYPES_CODES } from '../../RightSideContainer/Sales/ProductsAndServices/Products/ProductDetailView/ProductDetailViewSidebar/RightSideContainer/ProductDetailViewForm/Forms/FormConst';
import { FoldButton } from '../../CommonComponents/FoldButton/FoldButton';
import {LeftOutlined} from '@ant-design/icons';
import { redirectToNewTab } from '../../SideCar/SidecarUtils';
import {useContactsLocation} from '../../CustomHooks/useContactsLocation';
import useEHRCapabilities from '../../../screens/BusinessStudio/useEHRCapabilities';

interface IProps {
  showPatientBanner?: boolean;
  formId: string;
  formName: string;
  formLogId?: string;
  contactId?: string;
  accountUsers?: IFormSender[];
  onClose: () => void;
  mask?: boolean;
  drawerWidth?: string;
  hideResponseList?: boolean;
  titleText?: string;
  isInlineView?: boolean;
  contactLocationUuid?: string
}

const FormResponseView = (props: IProps) => {
  const {height} = Dimensions.get('window');
  const removeHeight = parseInt((24 / 100) * height + '');
  const finalListHeight = height - removeHeight;
  const accountUUID = getAccountUUID();
  const intl = useIntl();
  const [contactLocationUuid, setContactLocationUuid] = useState(props?.contactLocationUuid);

  const toast = useToast();
  const {contactId, formId, formName, formLogId, accountUsers, isInlineView} = props;
  const [submittedFormList, setSubmittedFormList] = useState<
    IContactFormResponseData[]
  >([]);

  const [contactState, setContactState] = useState<IContact>();

  const [responsesListState, setResponsesListState] =
    useState<IContactFormResponseData>({
      formScoreMeta: [],
      formComponentMeta: {} as IPublicFormData,
      entityName: formName,
      createdOn: '',
      formResponseId: '',
      source: '',
      sentOn: '',
    });

  const contextData = useContext(CommonDataContext);
  const {loading: contactLocationLoading, data: contactLocations} =
    useContactsLocation({contactUuid: contactId || ''});
  
  const getContactLocations = () => {
      setContactLocationUuid(contactLocations?.[0]?.accountLocation?.uuid);
  };
  
  const isSidecarContext = !!contextData?.sidecarContext?.isSidecar;
  const ehrCapabilities = useEHRCapabilities({
    locationId: contactLocationUuid,
  });
  const sourceMlovList = getMlovListFromCategory(
    contextData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.FORM_SOURCE
  );
  const formStatusMlovList = getMlovListFromCategory(
    contextData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.FORM_STATUS
  );
  const statusId = getMlovIdFromCode(
    formStatusMlovList,
    FORM_STATUS_CODE.SUBMITTED
  );


  const [getContactsByUuids] = useLazyQuery(
    GET_CONTACTS_NAME_BY_UUID,
    {
      fetchPolicy: 'no-cache',
      onError: (error) => {
        showToast(
          toast,
          'Error in fetching conacts',
          ToastType.error,
          4000
        );
      },
    }
  );

  const fetchContactsByUuid = (contactIdList: string[], formattedFormResponse: IContactFormResponseData[]) => {
    getContactsByUuids({
      variables: {
        contactUuidList: contactIdList,
      },
    })
      .then((data) => {
        if (data?.data?.contacts) {
          const contactList = data?.data?.contacts;
          const updatedFormResponses = formattedFormResponse.map(
            (submittedForm) => {
              if (submittedForm.requestedById) {
                const contact = contactList.find(
                  (contact: IContact) =>
                    contact.uuid === submittedForm.requestedById
                );
                submittedForm.sentBy = contact?.name || '';
              }
              return submittedForm;
            }
          );
          setSubmittedFormList(updatedFormResponses);
        }
      })
      .catch((error) => {

      });
  };

  const formatResponse = (
    formResponseList: any
  ): {
    formattedFormResponse: IContactFormResponseData[];
    contactIdList: string[];
  } => {
    const formattedFormResponse: IContactFormResponseData[] = [];
    const contactIdList: string[] = [];
    formResponseList.map((item: any) => {
      let isFormSignedByContactText;
      if (item.formResponse?.isFormDigitallySignedByContact) {
        isFormSignedByContactText = 'Digitally signed by contact';
      } else if (item.formResponse?.isContactSignComponentPresent) {
        isFormSignedByContactText = 'Not signed by contact';
      }

      let sentBy = '';
      if (item.requestedByTypeCode === REQUESTED_BY_TYPES_CODES.CONTACT && item.requestedById) {
        contactIdList.push(item.requestedById);
      } else {
        sentBy = getFormSenderDetail(item, accountUsers || [], sourceMlovList);
      }

      formattedFormResponse.push({
        formResponseId: item.formResponseId,
        createdOn: item.formResponse?.updatedOn || item.formResponse?.createdOn,
        formScoreMeta: getFormScoreMetaData(item.formResponse),
        entityName: formName,
        formComponentMeta: getFormComponentData(item.formResponse),
        source: item.sourceId
          ? getMlovValueFromId(sourceMlovList, item.sourceId)
          : '',
        isFormSignedByContactText,
        sentOn: item?.formResponse?.formLog?.createdOn || '',
        requestedById: item.requestedById,
        sentBy: sentBy,
      });
    });

    if (formattedFormResponse?.length > 0) {
      const defaultFormRes = formattedFormResponse[0];
      setResponsesListState((prev) => {
        return {
          ...prev,
          formScoreMeta: defaultFormRes.formScoreMeta,
          formComponentMeta: defaultFormRes.formComponentMeta,
          createdOn: defaultFormRes.createdOn,
          formResponseId: defaultFormRes.formResponseId,
          source: defaultFormRes.source,
          isFormSignedByContactText: defaultFormRes.isFormSignedByContactText,
          sentOn: defaultFormRes?.sentOn,
        };
      });
    }
    return {
      formattedFormResponse: formattedFormResponse,
      contactIdList: contactIdList,
    };
  };

  const getFormScoreMetaData = (formResponse: any): Array<{ metadata: IFormScore}> => {
    const metadataScore = formResponse.metadataScore;
    if (metadataScore?.length) {
      return formResponse.metadataScore;
    }
    return [{ metadata: {operation: '', state: '', score: null!, name: ''} }] as  Array<{ metadata: IFormScore}>;
  };

  const getFormComponentData = (responseForm: any): IPublicFormData => {
    const components = responseForm.formResponse.components;
    return {name: formName, components: components, noData: false, id: formId};
  };

  const [fetchSubmittedForms, fetchSubmittedFormsAPI] = useLazyQuery(
    FormsQueries.GET_CONTACT_SUBMITED_FORM_LIST,
    {
      variables: {
        contactId: contactId,
        statusIds: [statusId],
        formId: formId,
      },
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
      onCompleted: (submitForms: any) => {
        const {formattedFormResponse, contactIdList} = formatResponse(
          submitForms.formLogs
        );
        setSubmittedFormList(formattedFormResponse);
        if (contactIdList?.length > 0) {
          fetchContactsByUuid(contactIdList, formattedFormResponse);
        }
      },
      onError: () => {
        showToast(toast, intl.formatMessage({id: 'errorMsg'}), ToastType.error);
      },
    }
  );

  const [ fetchSubmittedFormByFormId, fetchSubmittedFormByFormIdAPI ] = useLazyQuery(
    FormsQueries.GET_CONTACT_SUBMITTED_FORM_BY_FORM_LOG_ID,
    {
      variables: {
        formLogId: formLogId,
        statusIds: [statusId],
        contactId: contactId,
      },
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
      onCompleted: (submitForms: any) => {
        const {formattedFormResponse, contactIdList} = formatResponse(
          submitForms.formLogs
        );
        setSubmittedFormList(formattedFormResponse);
      },
      onError: () => {
        showToast(toast, intl.formatMessage({id: 'errorMsg'}), ToastType.error);
      },
    });

  const [getContactDetails, getContactDetailAPI] = useLazyQuery(
    FormsQueries.GET_DETAILS_FOR_CONTACTS,
    {
      variables: {
        where: {
          uuid: {_in: contactId ? [contactId] : []},
        },
      },
      fetchPolicy: 'no-cache',
      onCompleted: (data: any) => {
        if (data.contacts?.[0]) {
          setContactState(data.contacts[0]);
        }
      },
      onError: (error: any) => {

      },
    }
  );

  useEffect(() => {
    if (props.formLogId) {
      fetchSubmittedFormByFormId();
    } else {
      fetchSubmittedForms();
    }

    if (props.showPatientBanner) {
      getContactDetails();
    }

    if (contactId && !contactLocationLoading && !props?.contactLocationUuid) {
      getContactLocations();
    }
  }, [])

  const onPrint = () => {
    const data = {
      response: getSubmittedDataFromComponents(
        responsesListState.formComponentMeta?.components
      ),
      ehrCapabilities: ehrCapabilities,
    };

  let printUrl = `widget.html#/public/forms?formId=${formId}&accountId=${accountUUID}&isPrint=true&readOnly=true&isPreviewMode=true`;
    printUrl += `&formComponents=${encodeURIComponent(JSON.stringify(responsesListState.formComponentMeta?.components))}`;
    printUrl += `&formData=${encodeURIComponent(JSON.stringify(data))}`;
    if (isSidecarContext) {
      redirectToNewTab(printUrl);
    }
    else {
      window.open(printUrl, '_blank', 'noopener,noreferrer');
    }
  };

  const getFormResponseTopBarButtons = (): ITableTopBarProps => {
    return {
      title: `Responses received for ${formName} `,
      buttonList: [
        {
          btnText: 'print',
          size: 'sm',
          colorScheme: 'primary',
          textColor: Colors.primary['800'],
          backgroundColor: Colors.primary['100'],
          borderColor: Colors.primary[200],
          variant: '',
          leftIcon: (
            <Icon
              as={AntIcon}
              name={'printer'}
              size="4"
              color={Colors.primary['800']}
            />
          ),
          btnClick: () => {
            onPrint();
          },
        },
        {
          btnText: 'cancel',
          size: 'sm',
          colorScheme: 'secondary',
          textColor: Colors.Custom.mainSecondaryBrown,
          backgroundColor: Colors.danger[100],
          variant: BUTTON_TYPE.SECONDARY,
          leftIcon: (
            <Icon
              as={AntIcon}
              name={'back'}
              size="4"
              color={Colors.danger['800']}
            />
          ),
          btnClick: () => {
            props.onClose();
          },
        },
      ],
      hideSearchBar: true,
    };
  };

  const getFormBackgroundColor = (formResponseId: any) => {
    return responsesListState.formResponseId === formResponseId
      ? Colors.primary[100]
      : Colors.primary[100] + '7a';
  };
  const getSubmittedDataFromComponents = (formComponents: any): object => {
    const submittedData: any = {};
    const data: any = {};
    if (formComponents && formComponents.length) {
      forEachExtensiveFormComponent(formComponents, (component: any) => {
        data[component.key] = component.selectedValue;
      });
    }
    submittedData.data = data;
    return submittedData;
  };
  const submittedFinaldata = {
    response: getSubmittedDataFromComponents(
      responsesListState.formComponentMeta?.components
    ),
    ehrCapabilities: ehrCapabilities,
  };
  const getBgColorForResponse = (formResponseId: any) => {
    return formResponseId === responsesListState.formResponseId
      ? Colors.primary[100]
      : '';
  };

  const buildFormComponentMeta = (): IPublicFormData => {
    return {
      name: responsesListState.formComponentMeta?.name || '',
      components:
        responsesListState.formComponentMeta?.components?.filter(
          (formComponentMeta) => {
            return formComponentMeta.key !== 'submit';
          }
        ) || [],
      noData: false,
      id: responsesListState.formComponentMeta?.id || '',
    };
  };

  const isResponsesListVisible = !props.formLogId && !props.showPatientBanner && !props.hideResponseList;
  const titleText = props?.titleText || `received for ${formName}`;

  return isInlineView ? (
    <>
      <View style={{height: finalListHeight}}>
        {fetchSubmittedFormsAPI.loading || getContactDetailAPI?.loading ? (
          <View padding={4}>
            <Skeleton.Text lines={4} />
          </View>
        ) : (
          <>
            <View flex={8}>
              <VStack
                height={finalListHeight}
                overflow="scroll"
                key={responsesListState.formResponseId}>
                {props.showPatientBanner && contactState?.uuid && (
                  <HStack padding={2}>
                    <MemberInfoListItem
                      showDateOfBirth={true}
                      contactData={contactState}
                      isFormResponse={true}
                      signature={
                        (responsesListState as any)?.isFormSignedByContactText
                      }
                    />
                    <FoldButton
                      nativeProps={{
                        variant: BUTTON_TYPE.PRIMARY,
                        onPress: () => {
                          onPrint()
                        },
                        leftIcon: (
                          <Icon
                            as={AntIcon}
                            name={'printer'}
                            size="4"
                            color={Colors.primary['800']}
                          />
                        )
                      }}
                      customProps={{
                        btnText: intl.formatMessage({
                          id: 'print',
                        }),
                        withRightBorder: false,
                        textColor: Colors.Custom.mainPrimaryPurple,
                      }}
                    />
                  </HStack>
                )}
                <PublicFormView
                  readOnly
                  formData={buildFormComponentMeta()}
                  optionData={{
                    accountUUID,
                    ehrCapabilities,
                    accountLocationUuid: contactLocationUuid
                  }}
                  submittedData={submittedFinaldata}
                  isPreviewMode={true}
                  extraStyles={{
                    marginX: 4,
                    marginY: 0,
                  }}
                />
              </VStack>
            </View>
          </>
        )}
      </View>
    </>
  ) : (
    <Drawer
      open={true}
      destroyOnClose
      mask={props?.mask ?? true}
      width={props?.drawerWidth || '70%'}
      onClose={() => props.onClose()}
      closable={isSidecarContext}
      title={
        <ModalActionTitle
          title={titleText}
          titleColor={''}
          titleSize={20}
          leftBackButton={isSidecarContext ? <Button onClick={() =>  props.onClose()} type="text" icon={<LeftOutlined />}/> : undefined}
          buttonList={[
            {
              show: !isSidecarContext,
              id: 1,
              btnText: 'close',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.SECONDARY,
              isTransBtn: false,
              onClick: () => {
                props.onClose();
              },
            },
            {
              show: true,
              id: 2,
              btnText: 'print',
              textColor: Colors.Custom.mainPrimaryPurple,
              variant: BUTTON_TYPE.PRIMARY,
              isTransBtn: false,
              leftIcon: (
                <Icon
                  as={AntIcon}
                  name={'printer'}
                  size="4"
                  color={Colors.primary['800']}
                />
              ),
              onClick: () => {
                onPrint();
              },
            },
          ]}
        />
      }>
      <View style={{height: finalListHeight}}>
        {fetchSubmittedFormsAPI.loading || getContactDetailAPI?.loading ? (
          <View padding={4}>
            <Skeleton.Text lines={4} />
          </View>
        ) : (
          <Box
            borderColor={Colors.Custom.BorderColor}
            borderWidth={1}
            overflow="hidden"
            rounded="lg"
            marginBottom={2}
            height={finalListHeight + 24}>
            {/* <TableTopBar {...getFormResponseTopBarButtons()} /> */}
            <HStack flex={1}>
              {isResponsesListVisible && (
                <View
                  overflow="scroll"
                  width="250"
                  borderRightWidth={1}
                  borderColor="gray.200">
                  <FlatList
                    data={submittedFormList}
                    renderItem={({item}) => {
                      const itemElem = (
                        <Pressable
                          _hover={{
                            backgroundColor: getFormBackgroundColor(
                              item.formResponseId,
                            ),
                          }}
                          onPress={() => {
                            setResponsesListState(prev => {
                              return {
                                ...prev,
                                formScoreMeta: item.formScoreMeta,
                                formComponentMeta: item.formComponentMeta,
                                createdOn: item.createdOn,
                                formResponseId: item.formResponseId,
                                source: item.source,
                              };
                            });
                          }}
                          key={item.formResponseId}>
                          <HStack
                            padding={2}
                            background={getBgColorForResponse(
                              item.formResponseId,
                            )}
                            borderBottomWidth={1}
                            borderColor="gray.200"
                            alignItems="center"
                            flex={1}>
                            <VStack flex={1}>
                              <Text>Form sent By</Text>
                              <Text fontSize="xs" color="gray.500">
                                {item.sentBy}
                                {` (${getDateStrFromFormat(
                                  item?.sentOn as string,
                                  'Do MMM YY, hh:mm a',
                                )})`}
                              </Text>
                              <Text>Response received on</Text>
                              <Text color="gray.500" fontSize="xs">
                                {getDateStrFromFormat(
                                  item.createdOn as string,
                                  'Do MMM YY, hh:mm a',
                                )}
                              </Text>
                              {item.isFormSignedByContactText && (
                                <Text color="gray.500">
                                  {item.isFormSignedByContactText}
                                </Text>
                              )}
                            </VStack>

                            <View>
                              <Icon
                                as={FeatherIcon}
                                name="chevron-right"
                                size="4"
                                color={Colors.Custom.Gray500}
                              />
                            </View>
                          </HStack>
                        </Pressable>
                      );
                      return itemElem;
                    }}
                    keyExtractor={() => {
                      return 'FormResponsesList_' + Math.random();
                    }}
                  />
                </View>
              )}
              <View flex={8}>
                <VStack
                  space={2}
                  height={finalListHeight}
                  overflow="scroll"
                  key={responsesListState.formResponseId}>
                  {props.showPatientBanner && contactState?.uuid && (
                    <HStack marginX={4} padding={2}>
                      <MemberInfoListItem
                        showDateOfBirth={true}
                        contactData={contactState}
                        isFormResponse={true}
                        signature={
                          (responsesListState as any)?.isFormSignedByContactText
                        }
                      />
                      <Spacer />
                    </HStack>
                  )}
                  {responsesListState.formScoreMeta?.length > 0 &&
                    responsesListState.formScoreMeta?.map?.((score, index) => {
                      if (!score?.metadata?.state) {
                        return <React.Fragment key={index} />;
                      }
                      return (
                        <RiskScoreDisplay
                          key={index}
                          name={score.metadata.name}
                          interpretation={score.metadata.state}
                          score={score.metadata.score}
                        />
                      );
                    })}
                  <PublicFormView
                    readOnly
                    formData={buildFormComponentMeta()}
                    optionData={{
                      accountUUID,
                      ehrCapabilities,
                      accountLocationUuid: contactLocationUuid
                    }}
                    submittedData={submittedFinaldata}
                    isPreviewMode={true}
                    extraStyles={{
                      marginX: 4,
                      marginY: 0,
                    }}
                  />
                </VStack>
              </View>
            </HStack>
          </Box>
        )}
      </View>
    </Drawer>
  );
};

export default FormResponseView;
