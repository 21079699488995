import {IMlov} from '../../../../Interfaces';
import { ILabelTask } from '../CareDashboardInterfaces';

export interface ITabsAction {
  rightContainerElem?: () => JSX.Element;
}
export interface ITabsList {
  key: string;
  title: string;
  path?: string;
  tabActions?: ITabsAction;
  tabContainerElem?: () => JSX.Element;
  isDisabled?: boolean;
  count?: number;
  backgroundColor?:string
}

export interface ICustomTabsView {
  tabsList: ITabsList[];
  canNavigate?: boolean;
  tabListWrapperStyle?: any;
  basePath?: string;
  onTabClick?: (tabItem: any, index?: number, lastIndexInVisibleList?: number) => void;
  selectedTab?: any;
  rightView?: () => JSX.Element;
  handleWithOnTab?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  numberOfElements?: number;
  minScreenSupportWidth?:number;
  isAddMaxTab?: boolean;
  leftOffset?: number;
}

export interface IFilterByTaskStatus {
  statusType: string;
  statusTypeUuid: string;
  id: number;
  label: string;
}

export interface IFilterByTaskPriority {
  priorityType: string;
  priorityTypeUuid: string;
  id: number;
  label: string;
}

export interface ITaskFilter {
  priorityFilter: IMlov[];
  statusFilter: IMlov[];
  dueDateFilter: IMlov[];
  dateRangeFilter?: any;
  selectedSortUuid?: any;
  selectedSortType?: any;
  userPreferenceId?: string | undefined;
  selectedSortValueAscending?: any;
  labels?: any[];
  selectedBoardType?: string;
  isTableView?: boolean;
  selectedContactUuid?: any[]
}

export interface IUserPreferencesTaskFilter {
  userPreferenceId?: string,
  start?: string,
  end?: string,
  selectedDueDateType?: any,
  selectedDueDateUuid?: any[];
  selectedPatientName?: any[];
  selectedPatientLabel?: any[];
  selectedContactUuid?: [],
  selectedPriorityList?: any[],
  selectedStatusList?: any[],
  selectedSortUuid?: string,
  selectedSortType?: string,
  selectedSortValueAscending?: any,
  labels?: string[];
  selectedBoardType?: string;
  isTableView?: boolean;
}


export interface ITaskFilterState {
  priorityFilter: string[];
  statusFilter: string[];
  dueDateFilter: string[];
  selectedTaskEntityTypesIds: string[];
  selectedPriorityTypes: IMlov[];
  dateRangeFilter?: any;
  labels?: ILabelTask[];
  loading?: boolean
}

export interface ITaskFilterObjectList {
  priorityFilterList: IMlov[];
  statusFilterList: IMlov[];
  dueDateFilterList: IMlov[];
  taskEntityTypeList: IMlov[];
}

export interface ITaskFilterRequest {
  taskDisplayCategoryId?: string;
  assigneeIds: any;
  taskDueDateCategoryId?: string;
  priorityIds?: string[] | [];
  statusIds?: string[] | [];
  statusIdsNotIn?: string[] | [];
  startDateTime?: any;
  endDateTime?: any;
  contactIds?: any;
  timezone?: string;
}


export enum BoardType {
  Status = "Status",
  Priority = 'Priority',
  dueDate = "dueDate",
  taskPool = "TaskPool",
}

export enum TaskFilterType {
  Status = "Status",
  Priority = 'Priority',
  dueDate = "dueDate",
  Assignee = 'Assignee',
  Mention = 'Mention',
  CreatedBy = 'CreatedBy'
}

export interface ITaskKanbanFilter {
  selectedTaskEntityTypesIds: string[];
  selectedPriorityTypes: IMlov[];
  selectedDueDateUuid?: string[];
  dateRangeFilter?: {
    start?: string;
    end?: string;
  };
}

export enum DuedateType{
  Overdue = 'Overdue',
  Today = 'Today',
  Tomorrow = 'Tomorrow',
  Later = 'Later',
  DateRange = 'Date Range',
  All = 'All'
}

export enum DuedateColumnType{
  past = 'past',
  today = 'today',
  future = 'future',
}

export interface IFilterByDueDate {
  dueDateType: string;
  dueDateTypeUuid: string;
  id: number;
  label: string;
}
