import {IStore} from './interfaces';

export const STORE_NAMES = [
  'conversations',
  'conversationLastSyncedAt',
] as const;

export const DB_NAME = 'foldDb';
export const DB_VERSION = 1;

export const STORES: IStore[] = [
  {
    name: 'conversations',
    keyPath: 'id',
    autoIncrement: true,
  },
  {
    name: 'conversationLastSyncedAt',
    keyPath: 'id',
    autoIncrement: true,
  },
];

export const DB_MESSAGE_PAGE_SIZE = 30;
