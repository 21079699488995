import {Formio} from '@foldhealth/formiojs';
import SearchableComponentFields from '../../../EditFormFields/SearchableComponentFields';

const PanelComponent = Formio.Components.components.panel;

export default class LabMonitoring extends PanelComponent {
  static schema(...extend: any) {
    return PanelComponent.schema(
      {
        type: 'labMonitoring',
        label: 'Lab Monitoring',
        key: 'labMonitoring',
        title: 'Lab Monitoring',
        theme: 'default',
        breadcrumb: 'default',
        components: [],
        clearOnHide: false,
        input: false,
        tableView: false,
        persistent: false,
      },
      ...extend
    );
  }

  static get builderInfo() {
    return {
      title: 'Lab Monitoring',
      group: 'advanced',
      icon: 'filetext1',
      weight: 2,
      documentation: '/userguide/#datetime',
      schema: LabMonitoring.schema(),
    };
  }

  static editForm = function () {
    return {
      key: 'display',
      components: [
        {
          type: 'oldtextfield',
          label: 'Title',
          key: 'title',
          input: true,
          validate: {
            required: true,
          }
        },
        ...SearchableComponentFields
      ],
    };
  };

}

Formio.Components.addComponent('labMonitoring', LabMonitoring);
