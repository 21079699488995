import {Drawer, Progress, Upload, notification} from 'antd';
import {UploadFile} from 'antd/lib/upload/interface';
import {
  Divider,
  FormControl,
  HStack,
  Icon,
  Input,
  Pressable,
  Spinner,
  Text,
  useMediaQuery,
  useToast,
  View,
  VStack,
} from 'native-base';
import React from 'react';
import {
  BUTTON_TYPE,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH,
} from '../../../../../constants';
import CMSService from '../../../../../services/CommonService/CMSService';
import {Colors} from '../../../../../styles';
import {showToast, ToastType} from '../../../../../utils/commonViewUtils';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import {IAddMediaModalProps} from '../interfaces';
import {MediaPreview} from '../MediaPreview';
import './AddMediaModalCss.css';
import {styles} from './AddMediaModalStyle';
import FeatherIcon from 'react-native-vector-icons/Feather';
import AntIcon from 'react-native-vector-icons/AntDesign';
import CheckIcon from '../../../../common/Svg/CheckIcon';
import {Dimensions} from 'react-native';
import {useIntl} from 'react-intl';

const AddMediaModal = (props: IAddMediaModalProps) => {
  const {onClose, onUpload} = props;
  const [fileList, setFileList] = React.useState<UploadFile<any>[]>([]);
  const [progress, setProgress] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const intl = useIntl();
  const [previewObj, setPreviewObj] = React.useState<{
    url: string;
    type: string;
  }>({
    url: '',
    type: '',
  });
  const [state, setState] = React.useState({
    name: '',
    url: '',
    loading: false,
  });
  const supportedFormatString =
    props?.supportedFormatString ||
    intl.formatMessage({id: 'supportedMediaText'});
  const acceptedFormats = props?.acceptedFiles || "image/*,video/*,audio/*,application/pdf";
  const {width} = Dimensions.get('window');

  const handleOnClose = () => {
    onClose();
    if (fileList && fileList.length !== 0) {
      URL.revokeObjectURL(fileList[0].originFileObj as any);
    }
    setFileList([]);
    setPreviewObj({url: '', type: ''});
  };

  const cmsInstance = CMSService.getCMSServiceInstance();
  const fileUploadService = cmsInstance.fileUploadService;
  const toast = useToast();

  const uploadFile = async () => {
    if (fileList.length === 0 && !state.url) {
      return;
    }
    setLoading(true);
    const formData = new FormData();
    fileList.forEach((file: any) =>
      formData.append('files', file.originFileObj as any)
    );
    try {
      const res = await fileUploadService.post('', formData, {
        onUploadProgress: (progressEvent) => {
          const percent = Math.floor(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          setProgress(percent);
          if (percent === 100) {
            setTimeout(() => setProgress(0), 1000);
          }
        },
      });
      onUpload(res.data);
      notification.open({
        message: 'Media added successfully',
        description: 'New file added to media successfully',
        duration: 3,
        icon: <CheckIcon />,
      });
      setLoading(false);
    } catch (err) {
      showToast(
        toast,
        'Something went wrong. Please try again later',
        ToastType.error
      );
    }
  };

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const drawerWidth = isIPadMiniScreen || isIPadScreen ? '50vw' : '33vw';

  return (
    <Drawer
      placement="right"
      width={drawerWidth}
      closable={false}
      destroyOnClose
      visible
      onClose={() => handleOnClose()}
      title={
        <ModalActionTitle
          title={'addMedia'}
          titleColor={''}
          buttonList={[
            {
              show: true,
              id: 2,
              btnText: 'cancel',
              textColor: Colors.Custom.mainPrimaryPurple,
              variant: BUTTON_TYPE.SECONDARY,
              isTransBtn: false,
              onClick: () => {
                handleOnClose();
              },
            },
            {
              show: true,
              isLoading: loading,
              id: 2,
              btnText: loading ? 'submitting' : 'submit',
              textColor: Colors.Custom.mainPrimaryPurple,
              variant: BUTTON_TYPE.PRIMARY,
              isTransBtn: false,
              onClick: () => {
                uploadFile();
              },
            },
          ]}
        />
      }
    >
      <VStack mx={5}>
        <View style={styles.previewContainerView}>
          {previewObj.type && previewObj.url && (
            <MediaPreview
              mediaType={previewObj.type}
              mediaUrl={previewObj.url}
            />
          )}
        </View>
        <View
          style={{
            flex: 1,
          }}
        >
          <Upload
            className="upload-container"
            onPreview={(file) => {}}
            beforeUpload={() => false}
            onChange={(info) => {
              if (info.fileList.length !== 0) {
                setPreviewObj({
                  url: URL.createObjectURL(
                    info.fileList[0].originFileObj as any
                  ),
                  type: info.fileList[0].type as string,
                });
              }
              const tempFileList = [...info.fileList];
              setFileList(tempFileList);
            }}
            onRemove={(file) => {
              setPreviewObj({url: '', type: ''});
              URL.revokeObjectURL(fileList[0].originFileObj as any);
              setFileList((prevFileList) => {
                const index = prevFileList.indexOf(file);
                const newFileList = prevFileList.slice();
                newFileList.splice(index, 1);
                return newFileList;
              });
            }}
            fileList={fileList}
            maxCount={1}
            accept={acceptedFormats}
          >
            {!previewObj.url && (
              <VStack
                style={{
                  padding: 24,
                  borderRadius: 8,
                  borderColor: Colors.Custom.Gray200,
                  borderWidth: 2,
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderStyle: 'dotted',
                  flex: 1,
                  width: width * 0.284,
                }}
              >
                <FeatherIcon name="upload-cloud" size={20} />
                <HStack
                  style={{
                    marginTop: 16,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 14,
                      fontWeight: '500',
                      lineHeight: 21,
                      color: Colors.Custom.Gray400,
                    }}
                  >
                    Drag and drop file here or
                  </Text>
                  <Text style={{color: Colors.Custom.mainPrimaryPurple}}>
                    {' Choose File'}
                  </Text>
                </HStack>
              </VStack>
            )}
          </Upload>

          <HStack
            style={{
              marginTop: 8,
              justifyContent: 'space-between',
            }}
          >
            <Text
              style={{
                color: Colors.Custom.Gray400,
                fontSize: 12,
                fontWeight: '500',
                lineHeight: 18,
              }}
            >
              {supportedFormatString}
            </Text>
            <Text
              style={{
                color: Colors.Custom.Gray400,
                fontSize: 12,
                fontWeight: '500',
                lineHeight: 18,
              }}
            ></Text>
          </HStack>
          {/* {progress > 0 ? <Progress percent={progress} /> : null} */}
        </View>

        {/* <HStack
          style={{
            marginTop: 16,
            height: 24,
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
            overflow: 'hidden',
          }}
        >
          <Divider style={{}} />
          <Text
            style={{
              marginHorizontal: 16,
            }}
          >
            OR
          </Text>
          <Divider style={{}} />
        </HStack>

        <VStack>
          <FormControl
            style={{
              marginTop: 16,
            }}
          >
            <FormControl.Label>
              <Text
                style={{
                  color: Colors.Custom.Gray500,
                  fontSize: 14,
                  fontWeight: '400',
                  lineHeight: 21,
                }}
              >
                Upload from URL
              </Text>
            </FormControl.Label>
            <Input
              value={state.url}
              onChangeText={(text) => {
                setState((prev) => {
                  return {
                    ...prev,
                    url: text,
                  };
                });
              }}
              placeholder="Paste Vimeo/image URL here..."
              style={{
                paddingVertical: 8,
                paddingHorizontal: 12,
                borderRadius: 6,
                borderWidth: 1,
                borderColor: Colors.Custom.Gray200,
              }}
              InputRightElement={
                state.loading ? (
                  <Spinner
                    size={'sm'}
                    style={{
                      marginRight: 12,
                    }}
                  />
                ) : (
                  <Pressable
                    style={{
                      marginRight: 12,
                    }}
                    onPress={() => {
                      uploadFromUrl();
                    }}
                  >
                    <Text
                      style={{
                        color: Colors.Custom.Gray400,
                        fontSize: 14,
                        fontWeight: '600',
                        lineHeight: 21,
                      }}
                    >
                      Upload
                    </Text>
                  </Pressable>
                )
              }
            />
          </FormControl>

          <FormControl
            style={{
              marginTop: 16,
            }}
          >
            <FormControl.Label>
              <Text
                style={{
                  color: Colors.Custom.Gray500,
                  fontSize: 14,
                  fontWeight: '400',
                  lineHeight: 21,
                }}
              >
                Media Name
              </Text>
            </FormControl.Label>
            <Input
              value={state.name}
              onChangeText={(text) => {
                setState((prev) => {
                  return {
                    ...prev,
                    name: text,
                  };
                });
              }}
              placeholder="Enter media name"
              style={{
                paddingVertical: 8,
                paddingHorizontal: 12,
                borderRadius: 6,
                borderWidth: 1,
                borderColor: Colors.Custom.Gray200,
              }}
            />
          </FormControl>

        </VStack> */}
      </VStack>
    </Drawer>
  );
};

export default AddMediaModal;
