import React, {useEffect, useState} from 'react';
import {Button, HStack} from 'native-base';
import {Colors} from '../../../styles';
import { isWeb } from '../../../utils/platformCheckUtils';
import { testID } from '../../../testUtils';

const WeekDaySelector = (props: IWeekDaySelectorProps) => {
  const [weekDays, setWeekDays] = useState<IWeekDay[]>([
    {value: 'S', id: 0, isSelected: false},
    {value: 'M', id: 1, isSelected: true},
    {value: 'T', id: 2, isSelected: true},
    {value: 'W', id: 3, isSelected: true},
    {value: 'T', id: 4, isSelected: true},
    {value: 'F', id: 5, isSelected: true},
    {value: 'S', id: 6, isSelected: false},
  ]);

  const daySelected = (selectedDay: IWeekDay) => {
    weekDays.forEach((day) => {
      if (day.id === selectedDay.id) {
        day.isSelected = !selectedDay.isSelected;
      }
    });
    setWeekDays([...weekDays]);
    if (props.onChange) {
      const selectedIds = weekDays.filter((day) => day.isSelected);
      props.onChange(
        selectedIds.map((day) => day.id),
        weekDays
      );
    }
  };

  const isDefaultSelected = (id: number): boolean => {
    if (props.values) {
      return props.values.includes(id);
    }
    return [1, 2, 3, 4, 5].includes(id);
  };

  useEffect(() => {
    weekDays.forEach((day) => {
      day.isSelected = isDefaultSelected(day.id);
    });
    setWeekDays([...weekDays]);
  }, [props.values]);

  if(!isWeb()){
    return (
      <HStack flex={1} justifyContent={'space-around'}>
        {weekDays.map((day) => {
          return (
            <Button
              key={day.id}
              height={10}
              width={10}
              variant={day.isSelected ? 'solid' : 'outline'}
              onPress={() => daySelected(day)}
              color={day.isSelected ? 'white' : 'black'}
              fontSize={16}
              marginX={'0.5'}
              fontWeight="bold"
              backgroundColor={
                day.isSelected
                  ? Colors.Custom.mainPrimaryPurple
                  : Colors.Custom.Gray200
              }
              borderWidth={0}
              disabled={props?.disabled}
              {...testID(day.value)}
            >
              {day.value}
            </Button>
          );
        })}
      </HStack>
    );
  }

  return (
    <HStack>
      {weekDays.map((day) => {
        return (
          <Button
            minWidth={8}
            key={day.id}
            height={8}
            width={8}
            // style={{height: '40px', width: '40px'}}
            variant={day.isSelected ? 'solid' : 'outline'}
            onPress={() => daySelected(day)}
            colorScheme={day.isSelected ? 'white' : 'black'}
            fontSize={16}
            marginX={'0.5'}
            fontWeight="bold"
            backgroundColor={
              day.isSelected ? Colors.primary[300] : 'blueGray.200'
            }
            borderWidth={0}
            disabled={props?.disabled}
          >
            {day.value}
          </Button>
        );
      })}
    </HStack>
  );
};

export interface IWeekDay {
  value: string;
  id: number;
  isSelected: boolean;
}

export interface IWeekDaySelectorProps {
  values?: number[];
  disabled?: boolean;
  onChange?: (selectedIds: number[], days: IWeekDay[]) => void;
}

export default WeekDaySelector;
