import { Drawer } from 'antd';
import { View } from 'native-base';
import { BUTTON_TYPE } from '../../../constants';
import { IUser } from '../../RightSideContainer/Contacts/TeamMembers/interfaces';
import { ModalActionTitle } from '../ModalActionTitle/ModalActionTitle';
import { DisplayUserList } from './DisplayUserList';

interface IProps {
  isOpen: boolean;
  userIds?: string[];
  userList?: IUser[];
  accountUsers?: IUser[];
  defaultUserId?: string;

  children?: JSX.Element;

  drawerTitleId?: string;

  onClose?: () => void;
}

export function DisplayUserListDrawer(props: IProps) {
  return (
    <Drawer
      destroyOnClose
      placement="right"
      visible={props.isOpen}
      onClose={() => {
        props.onClose?.()
      }}
      closable
      width={500}
      title={
        <>
          <ModalActionTitle
            title={props.drawerTitleId || 'userListDrawerTitle'}
            titleColor={''}
            buttonList={[
              {
                show: true,
                id: 1,
                btnText: 'done',
                size: 'sm',
                variant: BUTTON_TYPE.PRIMARY,
                isTransBtn: false,
                onClick: props.onClose,
              },
            ]}
          />
        </>
      }
    >
      <View mx={0}>
        {
          !!props.children &&
          <View marginBottom={5}>
            {props.children}
          </View>
        }
        <DisplayUserList
          {...props}
        />
      </View>
    </Drawer>
  );
}
