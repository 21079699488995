import {Box, Divider, HStack, Text} from 'native-base';
import React from 'react';
import {Colors} from '../../../../../styles';
import { GENERAL_RISK_SCORE_GROUP_NAME } from '../RiskScoreConfiguration/SectionWiseRiskScoreView';

const RiskScoreDisplay = (props: {
  interpretation?: string;
  score?: number;
  name?: string;
  borderColor?: string;
  customMarginBottom?: number;
}) => {
  const {interpretation, score} = props;
  return (
    <Box
      marginX={4}
      padding={4}
      borderColor={
        props.borderColor ? props.borderColor : Colors.Custom.BorderColor
      }
      rounded="lg"
      borderWidth={1}
      marginBottom={props.customMarginBottom ? props.customMarginBottom : -4}
    >
       <HStack space={4} alignItems="center">
        <Text fontSize="sm" color={Colors.Custom.Gray500} width={150}>
          Score group
        </Text>
        <Text fontSize="md" color={Colors.Custom.TitleColor}>
          {props.name || GENERAL_RISK_SCORE_GROUP_NAME}
        </Text>
      </HStack>
      {interpretation && (
        <HStack space={4} alignItems="center">
          <Text fontSize="sm" color={Colors.Custom.Gray500} width={150}>
            Score interpretation
          </Text>
          <Text fontSize="md" color={Colors.Custom.TitleColor}>
            {interpretation}
          </Text>
        </HStack>
      )}
      {(score || score === 0) && (
        <HStack space={4} alignItems="center">
          <Text fontSize="sm" color={Colors.Custom.Gray500} width={150}>
            Score
          </Text>
          <Text fontSize="md" color={Colors.Custom.TitleColor}>
            {score}
          </Text>
        </HStack>
      )}
    </Box>
  );
};

export default RiskScoreDisplay;
