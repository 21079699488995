import { useLazyQuery } from "@apollo/client";
import { HStack, Skeleton, VStack, Text } from "native-base";
import { useEffect, useState } from "react";
import { stringOrDate } from "react-big-calendar";
import { CARESTUDIO_APOLLO_CONTEXT } from "../../../../../../constants/Configs";
import TaskQueries from "../../../../../../services/Task/TaskQueries";
import { Colors } from "../../../../../../styles/Colors";
import { getDateStrFromFormat } from "../../../../../../utils/DateUtils";

export const GetOrderNameAndDetailById = (props: {isHideIcon?:boolean, orderId: string, email?:stringOrDate }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [order, setOrder] = useState<any>();

  const [ getOrderById ] = useLazyQuery(TaskQueries.GET_TASK_ORDER_DETAIL_BY_ID, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    getOrderById({
      variables: {
        externalOrderId: props?.orderId,
      },
    }).then((respInner) => {
      if (respInner?.data?.taskOrders?.length) {
        setOrder(respInner?.data?.taskOrders[0])
      }
      setIsLoading(false)
    });
  }, [props.orderId]);

  return (
    <HStack alignItems={'center'} flex={1} >
        {isLoading ? <Skeleton.Text flex={1} lines={1}></Skeleton.Text> :
        <>
          {order?.description && (
            <HStack
              flex={2}
              alignContent="center"
              justifyContent={'center'}
              alignItems={'center'}>
              <VStack flex={1}>
                <Text size={'smMedium'} color={Colors.Custom.Gray900}>
                  {order.description || ''}
                </Text>
                {
                  order?.externalOrderId &&
                  <Text size={'smNormal'} color={Colors.Custom.Gray500}>
                    {`OrderId : ${order?.externalOrderId}`}
                  </Text>
                }
                {
                  order?.orderStatus &&
                  <Text size={'smNormal'} color={Colors.Custom.Gray500}>
                    {`Status: ${order?.orderStatus}`}
                  </Text>
                }
                {
                  order?.orderDate &&
                  <Text size={'smNormal'} color={Colors.Custom.Gray500}>
                    {`Due Date: ${getDateStrFromFormat(
                      order.orderDate || new Date(),
                      'Do MMM YYYY'
                    ) || ''}`}
                  </Text>
                }
                {
                  order?.isDeleted &&
                  <Text size={'smNormal'} color={Colors.Custom.Gray500}>
                    {`Order deleted from underlying EHR`}
                  </Text>
                }
              </VStack>
            </HStack>
          )}
        </>
      }
    </HStack>
  );
};
