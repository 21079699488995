import React from 'react';
import { Content } from "antd/lib/layout/layout"
import { isWeb } from "../../../utils/platformCheckUtils"
import { Viewer } from "@toast-ui/react-editor"
import RenderHTML from 'react-native-render-html';
import { ACTION_CODE, fieldsOfAppointment, fieldsOfTask } from "./AuditHelper";
import MarkDownWrapper from './MarkdownWrapper';
import { Colors } from "../../../styles/Colors";
import { IAuditState, IMappedAccountUsers } from "./AuditInterfaces";
import { HStack, View, Text, Divider } from "native-base";
import { StyleProp, ViewStyle } from "react-native";
import { styles } from "./AuditRenderStyles";
import RightArrowIconSvg from "../Svg/RightArrowIconSvg";
import { DisplayCardAvatar } from "../DisplayCard/DisplayCardAvatar";
import { DATE_FORMATS, DISPLAY_DATE_FORMAT, GROUP_MEMBER_TYPE } from "../../../constants";
import { APPOINTMENT_PARTICIPANT_TYPE_CODES, APPOINTMENT_STATUS_CODES, LOCATION_TYPE_CODES } from "../../../constants/MlovConst";
import { IMlov } from "../../../Interfaces";
import AtClinicAppointmentIcon from "../../../assets/images/Avatar/AtClinicAppointmentIcon";
import VirtualAppointmentIcon from "../../../assets/images/Avatar/VirtualAppointmentIcon";
import { IUseReccuringEvent, Month } from "../CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/hooks/useReccuringEvent/interface";
import { getDateStrFromFormat, getMomentObj } from "../../../utils/DateUtils";
import { calculateMaxFutureDate, getSummaryString } from "../CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/hooks/useReccuringEvent/helpers";
import { getAppointmentDateTime } from "../CalendarWidget/BookingWorkflows/BookAppointment/BookAppointmentHelper";
import { testID } from "../../../testUtils";
import { ehrCodeDisplay } from "../../RightSideContainer/Contacts/TeamMembers/AddEditUser/interfaces";

export const basicContentView = (value: any, field?: string, className?: string) => {
    if (isWeb()) {
        return <Content
            className={className || "message-box-viewer"}
            key={value}
            style={{
                fontSize: 14,
                fontWeight: '400',
                flexWrap: 'wrap',
                color: Colors.Custom.textBlackColor,

            }}
            {...testID('LogDetails')}
        >
            <Viewer linkAttributes={{ target: '_blank' }} extendedAutolinks={true} initialValue={value || 'None'} />
        </Content>
    }
    else {
        if (field === fieldsOfTask?.DESCRTIPTION) {
            return <RenderHTML
                source={{ html: value || 'None' as string }}
                enableExperimentalMarginCollapsing={true}
                enableExperimentalBRCollapsing={true}
                baseStyle={{
                    whiteSpace: 'normal',
                    overflow: 'visible',
                    width: '100%',
                    color: Colors.Custom.MonochromeBlack,
                    fontWeight: '300'
                }}
            />
        }
        else return <MarkDownWrapper value={value || 'None'} />
    }

}

export const getMinimumWidthForView = (text: any): number => {
    const length = text?.replace( /(<([^>]+)>)/ig, '')?.length || 0
    return (length > 0 && length < 3) ? 18 : 35;
};

export const renderBasicView = (oldDataText: string, newDataText: string, log: IAuditState) => {
    const stylesForView = isWeb() ? styles.logDetail : styles?.markDownWrapper
    return (
        <View style={styles.logStyle as StyleProp<ViewStyle>}>
            <HStack alignItems="center" space={2} maxWidth="100%" >
                {log?.actionCode !== ACTION_CODE.CREATE &&
                    <>
                        <View style={stylesForView} minWidth={getMinimumWidthForView(oldDataText)}>
                            {basicContentView(oldDataText)}
                        </View>
                        <View width={4}><RightArrowIconSvg /></View>
                    </>
                }
                <View style={stylesForView} minWidth={getMinimumWidthForView(newDataText)}>
                    {basicContentView(newDataText)}
                </View>
            </HStack>
        </View>
    )
};

export const renderStatusView = (status: IMlov, backgroundColor: string, textColor: string) => {
    return (
        <View
            borderRadius={20}
            backgroundColor={backgroundColor}
            paddingX={4}
            paddingY={'1px'}
        >
            <Text fontSize={14}
                color={textColor} {...testID('Status')}>
                {status?.value}
            </Text>
        </View>
    )
}

export const getStatusBadgeBackgroundColor = (status: IMlov) => {
    switch(status?.code) {
        case APPOINTMENT_STATUS_CODES.SCHEDULED:
        case APPOINTMENT_STATUS_CODES.PROPOSED:
            return Colors?.Custom?.Gray200
        case APPOINTMENT_STATUS_CODES.PENDING:
            return Colors?.Custom?.AppointmentStatusPending
        case APPOINTMENT_STATUS_CODES.CANCELLED:
        case APPOINTMENT_STATUS_CODES.DECLINED:
        case APPOINTMENT_STATUS_CODES.NO_SHOW:
        case APPOINTMENT_STATUS_CODES.CHECKED_OUT:
            return Colors?.Custom?.AppointmentStatusRed
        case APPOINTMENT_STATUS_CODES.CHECKED_IN:
            return Colors?.Custom?.SuccessColor
        default:
            return Colors?.Custom?.Gray200
    }
}

export const getStatusBadgeTextColor = (status: IMlov) => {
    switch(status?.code) {
        case APPOINTMENT_STATUS_CODES.SCHEDULED:
        case APPOINTMENT_STATUS_CODES.PROPOSED:
            return Colors?.Custom?.Gray700
        default:
            return Colors?.Custom?.FontColorWhite
    }
}

export const appointmentLogView = (log: IAuditState, key: string, uuidMappingToData: { [key: string]: any }, accountUsersMappedById: { [key: string]: IMappedAccountUsers }) => {
    const getMappedUserOrPatient = (id: string, isContact?: boolean) => {
        const userData = uuidMappingToData?.[id] || accountUsersMappedById?.[id];
        return userData ? displayUserOrPatient(userData, isContact) : basicContentView('None');
    };
    const viewStyles = isWeb() ? styles.logDetail : styles?.markDownWrapper;
    switch (key) {
        case fieldsOfAppointment?.CONTACT_IDS:
            return (
                <HStack alignItems="center" space={2} maxWidth="100%">
                    <View style={styles.logDetail}>
                        {log?.data?.oldData?.[key]?.map((id: any) => {
                            return getMappedUserOrPatient(id, true)
                        })}
                    </View>
                    <View width={4}><RightArrowIconSvg /></View>
                    <View style={styles.logDetail}>
                        {log?.data?.newData?.[key]?.map((id: any) => {
                            return getMappedUserOrPatient(id, true)
                        })}
                    </View>
                </HStack>
            );
        case fieldsOfAppointment?.USER_IDS:
            return (
                <HStack alignItems="center" space={2} maxWidth="100%">
                    <View style={styles.logDetail}>
                        {log?.data?.oldData?.[key]?.map((id: any) => {
                            return getMappedUserOrPatient(id, true)
                        })}
                    </View>
                    <View width={4}><RightArrowIconSvg /></View>
                    <View style={styles.logDetail}>
                        {log?.data?.newData?.[key]?.map((id: any) => {
                            return getMappedUserOrPatient(id, true)
                        })}
                    </View>
                </HStack>
            );
        case fieldsOfAppointment?.TITLE: {
            const oldDataTextForDisplay = log?.data?.oldData?.[key]
            const newDataTextForDisplay = log?.data?.newData?.[key]
            return (
                <HStack alignItems="center" space={2} maxWidth="100%">
                    <View style={viewStyles} minWidth={getMinimumWidthForView(oldDataTextForDisplay)}>
                        {basicContentView(oldDataTextForDisplay)}
                    </View>
                    <View width={4}><RightArrowIconSvg /></View>
                    <View style={viewStyles} minWidth={getMinimumWidthForView(newDataTextForDisplay)}>
                        {basicContentView(newDataTextForDisplay)}</View>
                </HStack>
            );
        }
        case fieldsOfAppointment.REASON_FOR_VISIT: {
            const oldDataTextForDisplay = log?.data?.oldData?.[key]?.displayName
            const newDataTextForDisplay = log?.data?.newData?.[key]?.displayName
            return (
                <HStack alignItems="center" space={2} maxWidth="100%">
                    <View style={{...viewStyles, maxWidth: '50%'}} minWidth={getMinimumWidthForView(oldDataTextForDisplay)}>
                        {basicContentView(oldDataTextForDisplay)}
                    </View>
                    <View width={4}><RightArrowIconSvg /></View>
                    <View style={viewStyles} minWidth={getMinimumWidthForView(newDataTextForDisplay)}>
                        {basicContentView(newDataTextForDisplay)}</View>
                </HStack>
            );
        }
        case fieldsOfAppointment.STATUS_ID: {
            const oldStatus: IMlov = uuidMappingToData[log?.data?.oldData?.[key]];
            const newStatus: IMlov = uuidMappingToData[log?.data?.newData?.[key]];
            const oldStatusBackgroundColor = getStatusBadgeBackgroundColor(oldStatus)
            const newStatusBackgroundColor = getStatusBadgeBackgroundColor(newStatus)
            const oldStatusTextColor = getStatusBadgeTextColor(oldStatus)
            const newStatusTextColor = getStatusBadgeTextColor(newStatus)
            return (
                <HStack alignItems="center" space={2} marginY={2}>
                    {renderStatusView(oldStatus, oldStatusBackgroundColor, oldStatusTextColor)}
                    <RightArrowIconSvg />
                    {renderStatusView(newStatus, newStatusBackgroundColor, newStatusTextColor)}
                </HStack>
            );
        }
        case fieldsOfAppointment.LOCATION_TYPE_ID: {
            const oldLocationTypeCode = uuidMappingToData[log?.data?.oldData?.[key]]?.code;
            const newLocationTypeCode = uuidMappingToData[log.data?.newData?.[key]]?.code;
            const oldDataTextForDisplay = oldLocationTypeCode === LOCATION_TYPE_CODES.VIRTUAL ?
                'Virtual' :
                uuidMappingToData[log?.data?.oldData?.[key]]?.displayName
            const newDataTextForDisplay = newLocationTypeCode === LOCATION_TYPE_CODES.VIRTUAL ?
                'Virtual' :
                uuidMappingToData[log?.data?.newData?.[key]]?.displayName

            const getLocationTypeIcon = (statusCode: string) => {
                switch (statusCode) {
                    case LOCATION_TYPE_CODES.VIRTUAL:
                        return <VirtualAppointmentIcon color={Colors.Custom.black25} />
                    default:
                        return <AtClinicAppointmentIcon color={Colors.Custom.black25} />

                }
            }

            const oldLocationTypeIcon = getLocationTypeIcon(oldLocationTypeCode);
            const newLocationTypeIcon = getLocationTypeIcon(newLocationTypeCode);

            return (
                <HStack alignItems="center" space={2} maxWidth="100%">
                    <View style={viewStyles} minWidth={getMinimumWidthForView(oldDataTextForDisplay)}>
                        <HStack alignItems={'center'} space={2}>
                            {oldLocationTypeIcon}
                            {basicContentView(oldDataTextForDisplay)}
                        </HStack>
                    </View>
                    <View width={4}><RightArrowIconSvg /></View>
                    <View style={viewStyles} minWidth={getMinimumWidthForView(newDataTextForDisplay)}>
                        <HStack alignItems={'center'} space={2}>
                            {newLocationTypeIcon}
                            {basicContentView(newDataTextForDisplay)}
                        </HStack>
                    </View>
                </HStack>
            );
        }
        case fieldsOfAppointment.ACCOUNT_LOCATION_ID: {
            const oldDataTextForDisplay = uuidMappingToData[log?.data?.oldData?.[key]]?.displayName
            const newDataTextForDisplay = uuidMappingToData[log?.data?.newData?.[key]]?.displayName
            return (
                <HStack alignItems="center" space={2} maxWidth="100%">
                    <View style={viewStyles} minWidth={getMinimumWidthForView(oldDataTextForDisplay)}>
                        {basicContentView(oldDataTextForDisplay)}
                    </View>
                    <View width={4}><RightArrowIconSvg /></View>
                    <View style={viewStyles} minWidth={getMinimumWidthForView(newDataTextForDisplay)}>
                        {basicContentView(newDataTextForDisplay)}</View>
                </HStack>
            );
        }
        case fieldsOfAppointment.CONTENT: {
            const oldDataTextForDisplay = log?.data?.oldData?.[key]
            const newDataTextForDisplay = log?.data?.newData?.[key]
            return (
                <HStack alignItems="center" space={2} maxWidth="100%">
                    <View style={viewStyles} minWidth={getMinimumWidthForView(oldDataTextForDisplay)}>
                        {basicContentView(oldDataTextForDisplay)}
                    </View>
                    <View width={4}><RightArrowIconSvg /></View>
                    <View style={viewStyles} minWidth={getMinimumWidthForView(newDataTextForDisplay)}>
                        {basicContentView(newDataTextForDisplay)}</View>
                </HStack>
            );
        }
        case fieldsOfAppointment.REFERENCE: {
              const oldDataRecurrence = processDataRecurrence(log?.data?.oldData?.[key]?.recurrenceData);
              const newDataRecurrence = processDataRecurrence(log?.data?.newData?.[key]?.recurrenceData);

              const oldDataTextForDisplay = getSummaryString(oldDataRecurrence, {
                  startTime: getDateStrFromFormat(log?.data?.oldData?.startDateTime, DATE_FORMATS.CALENDAR_LIB_FORMAT)
              });

              const newDataTextForDisplay = getSummaryString(newDataRecurrence, {
                  startTime: getDateStrFromFormat(log?.data?.newData?.startDateTime, DATE_FORMATS.CALENDAR_LIB_FORMAT)
              });
            return (
                <HStack alignItems="center" space={2} maxWidth="100%">
                    <View style={viewStyles} minWidth={getMinimumWidthForView(oldDataTextForDisplay)}>
                        {basicContentView(oldDataTextForDisplay)}
                    </View>
                    <View width={4}><RightArrowIconSvg /></View>
                    <View style={viewStyles} minWidth={getMinimumWidthForView(newDataTextForDisplay)}>
                        {basicContentView(newDataTextForDisplay)}</View>
                </HStack>
            );
        }

        case fieldsOfAppointment.DESCRIPTION: {
            const oldDataTextForDisplay = log?.data?.oldData?.[key]
            const newDataTextForDisplay = log?.data?.newData?.[key]
            return (
                <HStack alignItems="center" space={2} maxWidth="100%">
                    <View style={{...viewStyles, maxWidth: '50%'}} minWidth={getMinimumWidthForView(oldDataTextForDisplay)}>
                        {basicContentView(oldDataTextForDisplay)}
                    </View>
                    <View width={4}><RightArrowIconSvg /></View>
                    <View style={viewStyles} minWidth={getMinimumWidthForView(newDataTextForDisplay)}>
                        {basicContentView(newDataTextForDisplay)}</View>
                </HStack>
            );
        }

        case fieldsOfAppointment.NAME: {
            const oldDataTextForDisplay = log?.data?.oldData?.[key]
            const newDataTextForDisplay = log?.data?.newData?.[key]
            return (
                <HStack alignItems="center" space={2} maxWidth="100%">
                    <View style={viewStyles} minWidth={getMinimumWidthForView(oldDataTextForDisplay)}>
                        {basicContentView(oldDataTextForDisplay)}
                    </View>
                    <View width={4}><RightArrowIconSvg /></View>
                    <View style={viewStyles} minWidth={getMinimumWidthForView(newDataTextForDisplay)}>
                        {basicContentView(newDataTextForDisplay)}</View>
                </HStack>
            );
        }

        case fieldsOfAppointment.APPOINTMENT_TYPE_ID:
            const oldDataTextForDisplay = uuidMappingToData[log?.data?.oldData?.[key]]?.displayName
            const newDataTextForDisplay = uuidMappingToData[log?.data?.newData?.[key]]?.displayName
            return (
                <HStack alignItems="center" space={2} maxWidth="100%">
                    <View style={viewStyles} minWidth={getMinimumWidthForView(oldDataTextForDisplay)}>
                        {basicContentView(oldDataTextForDisplay)}
                    </View>
                    <View width={4}><RightArrowIconSvg /></View>
                    <View style={viewStyles} minWidth={getMinimumWidthForView(newDataTextForDisplay)}>
                        {basicContentView(newDataTextForDisplay)}</View>
                </HStack>
            );

        case fieldsOfAppointment.START_DATE_TIME: {
            const oldDataTextForDisplay = getAppointmentDateTime({
                startDateTime: log?.data?.oldData?.startDateTime,
                endDateTime: log?.data?.oldData?.endDateTime
            }, '', DATE_FORMATS.CALENDAR_LIB_FORMAT)
            const newDataTextForDisplay = getAppointmentDateTime({
                startDateTime: log?.data?.newData?.startDateTime,
                endDateTime: log?.data?.newData?.endDateTime
            }, '', DATE_FORMATS.CALENDAR_LIB_FORMAT)
            return (
                <HStack alignItems="center" space={2} maxWidth="100%">
                    <View style={viewStyles} minWidth={getMinimumWidthForView(oldDataTextForDisplay)}>
                        {basicContentView(oldDataTextForDisplay)}
                    </View>
                    <View width={4}><RightArrowIconSvg /></View>
                    <View style={viewStyles} minWidth={getMinimumWidthForView(newDataTextForDisplay)}>
                        {basicContentView(newDataTextForDisplay)}</View>
                </HStack>
            );
        }
    }
};

export const displayUserOrPatient = (user: any, isContact?: boolean) => (
    <HStack marginY={2} alignItems='center' key={user?.id || user?.uuid || user?.userId || user?.contactId}>
        <DisplayCardAvatar
            avatarStyle={isWeb() ? {
                avatarSize: '10',
                width: 28,
                height: 28,
                textStyle: { fontSize: 12 },
            } :
                {
                    avatarSize: '8',
                }
            }
            isLetterAvatarShow={true}
            userData={{
                userId: '',
                userType: isContact ? GROUP_MEMBER_TYPE.CONTACT : GROUP_MEMBER_TYPE.USER,
                userName: user?.name,
            }}
        />
        <Text
            ml={2}
            fontWeight={'500'}
            fontSize={14}
            style={{ color: Colors.Custom.textBlackColor }}
            fontFamily={'Manrope'}
            maxWidth={isWeb() ? '90%' : '75%'}
            {...testID('UserName')}
        >
            {user?.name}
        </Text>
    </HStack>
);

export const renderParticipantView = (log: IAuditState, uuidMappingToData: { [key: string]: any }, accountUsersMappedById: { [key: string]: IMappedAccountUsers }) => {
    const key = (log?.participantTypeCode === APPOINTMENT_PARTICIPANT_TYPE_CODES.PATIENT) ?
        fieldsOfAppointment.CONTACT_IDS :
        fieldsOfAppointment.USER_IDS;

    const getMappedUserOrPatient = (id: string, isContact?: boolean) => {
        const userData = uuidMappingToData?.[id] || accountUsersMappedById?.[id];
        return !!userData ? displayUserOrPatient(userData, isContact) : basicContentView('None');
    };

    return (
        <View style={styles.logStyle as StyleProp<ViewStyle>}>
            <HStack alignItems="center" space={2} maxWidth="100%">
                {log?.participantTypeCode !== APPOINTMENT_PARTICIPANT_TYPE_CODES.PATIENT && (
                    <>
                        <View style={styles.logDetail}>
                            {log?.data?.oldData?.[key]?.length
                                ? log.data.oldData[key].map((id: any) => (
                                    <View key={id}>
                                        {getMappedUserOrPatient(id, true)}
                                    </View>
                                ))
                                : basicContentView('None')}

                        </View>
                        <View width={4}><RightArrowIconSvg /></View>
                    </>
                )}
                <View style={styles.logDetail}>
                    {log?.data?.newData?.[key]?.length
                        ? log.data.newData[key].map((id: any) => (
                            <View key={id}>
                                {getMappedUserOrPatient(id, true)}
                            </View>
                        ))
                        : basicContentView('None')}
                </View>

            </HStack>
        </View>
    );
}

export const renderAppointmentNoteView = (log: IAuditState) => {
    const oldDataTextForDisplay = log?.data?.oldData?.content;
    const newDataTextForDisplay = log?.data?.newData?.content;
    const viewStyles = isWeb() ? styles.logDetail : styles?.markDownWrapper;
    return (
        <View style={styles.logStyle as StyleProp<ViewStyle>}>
            <HStack alignItems="center" space={2} maxWidth="100%">
                <View style={viewStyles} minWidth={getMinimumWidthForView(oldDataTextForDisplay)}>
                    {basicContentView(oldDataTextForDisplay)}
                </View>
                <View width={4}><RightArrowIconSvg /></View>
                <View style={viewStyles} minWidth={getMinimumWidthForView(newDataTextForDisplay)}>
                    {basicContentView(newDataTextForDisplay)}</View>
            </HStack>
        </View>
    );
}

export const renderAppointmentTaskView = (log: IAuditState) => {
    const viewStyles = isWeb() ? styles.logDetail : styles?.markDownWrapper;
    const contentClassName = "appointment-task-message-box-viewer"
    return (
        <View style={styles.logStyle as StyleProp<ViewStyle>}>
            <HStack alignItems="center" space={2} maxWidth="100%" marginTop={2}>
                <View style={viewStyles} minWidth={60}>
                    {log?.data?.oldData?.title?.length ?
                        <View borderWidth={1} borderColor={Colors?.Custom?.Gray200} borderRadius={2}>
                            {log.data.oldData?.title?.map((text: any, index: any) => {
                                return (
                                    <View marginX={1} key={index}>
                                        {basicContentView(text, '', contentClassName)}
                                        {(index !== log.data.oldData?.title?.length - 1) && <Divider />}
                                    </View>
                                )
                            })}
                        </View>
                        : basicContentView('None')}
                </View>
                <View width={4}><RightArrowIconSvg /></View>
                <View style={viewStyles} minWidth={60}>
                    {log?.data?.newData?.title?.length ?
                        <View borderWidth={1} borderColor={Colors?.Custom?.Gray200} borderRadius={2}>
                            {log.data.newData?.title?.map((text: any, index: any) => {
                                return (
                                    <View marginX={1} key={index}>
                                        {basicContentView(text, '', contentClassName)}
                                        {(index !== log.data.newData?.title?.length - 1) && <Divider />}
                                    </View>
                                )
                            })}
                        </View>
                        : basicContentView('None')}
                </View>
            </HStack>
        </View>
    );
}

export const processDataRecurrence = (data: any) => {
    return {
      frequency: data?.frequency,
      repeatEvery: data?.repeatOnValue,
      endDate: getMomentObj(data?.endDate).toISOString(),
      viewType: '',
      repeatsOnWeekDays: data?.daysOfWeek,
      repeatsOnMonthDay: data?.repeatOnMonthDays?.[0],
      repeatsOnMonth: (data?.repeatOnMonth as Month),
      startDate: data?.startDateTime,
      startAndEndTime: data?.startAndEndTime,
      maxDate: calculateMaxFutureDate(
        data?.frequency,
        data?.repeatOnValue || 1,
        getMomentObj(data?.startDateTime as string)
      ),
      seriesStartDate: data?.seriesStartDate
    };
  };

  export const getEhrDisplayName = (ehrCode: string, ehrList?: ehrCodeDisplay[]) => {
    return (ehrList || []).find(ehr => ehr.code === ehrCode)?.display || '';
  };
