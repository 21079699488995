import { Dimensions, StyleSheet } from 'react-native'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { isEqual } from 'lodash';
import { List, Skeleton, notification, Checkbox } from 'antd';
import { Draggable, DraggableProvided, Droppable, DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd';
import { Badge, HStack, View, Text, Spacer, Spinner, Divider, VStack, Image } from 'native-base';
import { Colors } from '../../../../styles';
import { ITaskCategory, ITaskMetaData, MultiSelectAction, MultiSelectState } from '../../TaskInterfaces';
import { ITask } from '../../../common/CareDashboard/CareDashboardInterfaces';
import { ToastType } from '../../../../utils/commonViewUtils';
import useTaskManager from '../../CustomHooks/useTaskManager';
import { TASK_DEFAULT_PAGE_SIZE } from '../../TaskModuleHelper';
import { FetchTaskState } from '../../../common/CareDashboard/CareDashboardConstants';
import { BottomViewAction, canHideCheckBox, descriptionDisplayModes, getBottomView, getCardTag, getDescription } from '../../../TaskCard/TaskCardHelper';
import TaskCard from '../../../TaskCard/TaskCard';
import { TASK_STATUS } from '../../../../constants/MlovConst';
import { getVitalListFromCapability } from '../../../../utils/capabilityUtils';
import InfiniteScroll from 'react-infinite-scroll-component';
import Card from '../../../TaskCard/Card.native';
import { getMomentObj, isPastDay } from '../../../../utils/DateUtils';
import { FoldButton } from '../../../CommonComponents/FoldButton/FoldButton';
import useTaskCountManager, { IUseTaskCountManagerReturn } from '../../../common/CareDashboard/CustomHook/useTaskCountManager';
import { getTodayDueTaskTextLabel } from '../../../TaskCard/TaskHelper';
import { BoardType } from '../../../common/CareDashboard/CareDashboardTopBar/interfaces';
import {
  CaptureTransaction,
  TRANSACTION_NAMES,
} from '../../../../utils/CaptureTransaction';
import { IAttachment } from '../../../TaskCard/CardInterfaces';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import DeleteTaskModal from '../DeleteTaskModal/DeleteTaskModal';
import Stack from '../../../common/LayoutComponents/Stack';
import { TaskContext } from '../../../../context/TaskContext';

interface ITaskKanbanColumnProps {
  category: ITaskCategory;
  metaData: ITaskMetaData;
  noOfColumns: number;
  isDropDisabled: boolean;
  taskCountData: IUseTaskCountManagerReturn;
  onTaskDetail: (data: ITask) => void;
  onMemberClick?: (task: ITask) => void;
  onActionPerformed?: (tabCode: string, rawData: any) => void;
}

const TaskKanbanColumn = (props: ITaskKanbanColumnProps) => {
  // Constants
  const { noOfColumns, category, metaData, isDropDisabled, taskCountData, onTaskDetail, onMemberClick } = props;
  const taskScrollContainer = useRef<HTMLDivElement>(null);
  const {height} = Dimensions.get('window');
  const width = noOfColumns > 1 ? `calc(100% / ${noOfColumns})` : '50%';
  const patientId = metaData.personData?.patientId || metaData.personData?.patientUuid;
  const accountLocationUuid = metaData.personData?.accountLocationUuid || '';
  const vitals = useMemo(() => getVitalListFromCapability('', accountLocationUuid), [accountLocationUuid]);
  const isPatientTask = false;
  const selectedTaskIdsRef = useRef<Set<string>>();
  selectedTaskIdsRef.current = useMemo(() => new Set(metaData.selectedTasks?.map(item => item.id) || []), [metaData.selectedTasks?.length]);
  const captureTransactionInst = CaptureTransaction.getInstance();
  const { taskMasterUsers, taskPools } = React.useContext(TaskContext);


  const onShowToast = useCallback((message: string, type: ToastType, additionalData?: any) => {
    notification.destroy();
    const notificationMethod = type === ToastType.info ?
      notification.info :
      (type === ToastType.error ? notification.error : notification.success);
    notificationMethod({
      message,
      duration: additionalData?.duration || 3.0,
      placement: 'top',
      style: additionalData?.style,
      onClick: () => {
        notification.destroy();
        additionalData?.onClick?.();
      }
    });
  }, []);

  // Task data manager
  const {
    loading,
    tasks,
    pagination,
    taskActionsInProgress,
    fetchTasks,
    handleTaskActions,
    handleMultiSelection,
    getMultiselectState,
  } = useTaskManager({
    filters: category.filters,
    categoryCode: category.code,
    boardType: metaData.boardType,
    multiSelectData: {
      bulkActionEnabled: metaData.bulkTaskEnabled,
      selectedTasks: metaData.selectedTasks,
    },
    pagination: {
      pageNumber: 1,
      pageSize: TASK_DEFAULT_PAGE_SIZE,
    },
    accountLocationUuid: accountLocationUuid,
    showToast: onShowToast,
    onSelectedTaskChange: metaData.onSelectedTaskChange,
    taskMasterUsers: taskMasterUsers?.length ? taskMasterUsers : metaData?.masterUsersList || [],
    allTaskPools: taskPools?.length ? taskPools : metaData?.masterPoolList || [],
    taskCountData: taskCountData,
  });

  const countData = useMemo(() => taskCountData?.taskCount?.filter(item => item.code === category.code)?.[0], [taskCountData?.taskCount, category.code]);
  const todaysCountData = useMemo(() => taskCountData?.todaysTaskCount?.filter(item => item.code === category.code)?.[0], [taskCountData?.todaysTaskCount, category.code]);
  const isTaskPoolBoard = metaData.boardType === BoardType.taskPool;
  const multiSelectState = getMultiselectState();
  const columnContainerStyles = useMemo(
    () => getColumnContainerStyles(width),
    [width]
  );

  // States
  const [ componentState, setComponentState ] = useState<{loaderInColumns: boolean}>({
    loaderInColumns: false,
  });
  const [deleteTaskData, setDeleteTaskData] = useState<{ showConfirmation: boolean, task?: ITask }>({
    showConfirmation: false,
    task: undefined,
  });

  // Other methods
  const hasMoreData = useMemo(() => {
    const offset = pagination.pageNumber * pagination.pageSize;
    return pagination.total == -1 || offset < pagination.total;
  }, [pagination.pageNumber, pagination.pageSize, pagination.total]);

  const callTaskAPI = useCallback(async (refresh: boolean) => {
    const pageSize = pagination.pageSize;
    let pageNumber = 1;
    let taskState: FetchTaskState = FetchTaskState.resetAndFetchFromPagination;
    if (!refresh) {
      if (!hasMoreData) {
        setComponentState((prev: any) => {
          return {
            ...prev,
            loaderInColumns: false
          }
        })
        return;
      }
      pageNumber = pagination.pageNumber + 1;
      taskState = FetchTaskState.fetchAndAppendToList;
    }
    await fetchTasks({ pageNumber, pageSize }, category.filters, { state: taskState });
    captureTransactionInst.finishTransaction(
      TRANSACTION_NAMES.TASK_FETCH_NEXT_PAGE,
      category.code,
      {
        pageNumber,
        pageSize,
        boardType: metaData.boardType,
        noOfColumns,
      }
    );
    if (refresh) {
      setComponentState((prev: any) => {
        return {
          ...prev,
          loaderInColumns: false
        }
      });
    }
    if (refresh && taskScrollContainer?.current) {
      (taskScrollContainer.current as any).scrollTop = 0;
    }
  }, [fetchTasks, pagination.pageNumber, pagination.pageSize, category.filters, taskScrollContainer, setComponentState, hasMoreData]);

  const fetchNextPage = useCallback(() => {
    if (loading) {
      setComponentState((prev: any) => {
        return {
          ...prev,
          loaderInColumns: false
        }
      });
      return;
    }
    captureTransactionInst.initiateTransaction({
      name: TRANSACTION_NAMES.TASK_FETCH_NEXT_PAGE,
      identifier: category.code,
    });
    callTaskAPI(false);
  }, [loading, callTaskAPI]);

  const getTaskCardLoading = useMemo(() => (task: ITask) => {
    return taskActionsInProgress.filter(item => item.taskId === task.id).length > 0;
  }, [taskActionsInProgress]);

  const onBottomViewAction = useCallback(async (task: ITask, action: BottomViewAction, data?: any) => {
    if (metaData.bulkTaskEnabled) {
      return;
    }
    switch (action) {
      case BottomViewAction.deleteTask:
        setDeleteTaskData((prev) => ({...prev, showConfirmation: true, task}));
        break;
      case BottomViewAction.captureLabTest:
      case BottomViewAction.captureVital:
        handleTaskActions(task, action, data, { patientId, accountLocationUuid });
        break;

      default:
        await handleTaskActions(task, action, data);
        break;
    }
  }, [handleTaskActions, metaData.bulkTaskEnabled, patientId, accountLocationUuid]);

  const onDeleteConfirm = async () => {
    const task = deleteTaskData.task;
    setDeleteTaskData((prev) => ({...prev, showConfirmation: false, task: undefined}));
    if (task) {
      await handleTaskActions(task, BottomViewAction.deleteTask, undefined);
    }
  };

  const onDeleteCancel = () => {
    setDeleteTaskData((prev) => ({...prev, showConfirmation: false, task: undefined}));
  }

  const getNoDataText = useMemo(() => {
    if (category.filters.searchString) {
      return 'No tasks found in search';
    }
    return 'No tasks to show';
  }, [category.filters.searchString]);

  const getColumnHeight = useMemo(() => {
    if (isPatientTask) {
      return height - 360;
    } else {
      return height - 290;
    }
  }, [isPatientTask, height]);

  const onTaskCardPress = useCallback((task: ITask) => {
    if (metaData.bulkTaskEnabled) {
      return;
    }
    onTaskDetail?.(task);
  }, [metaData.bulkTaskEnabled, onTaskDetail]);

  const onTaskCheckBoxChange = useCallback((task: ITask, isSelected: boolean) => {
    const currentTaskDuedate = task?.endDateTime
    const isPastDueDate = currentTaskDuedate ? isPastDay(getMomentObj(currentTaskDuedate)) : false
    const incompleteAction = isPastDueDate ? BottomViewAction.markAsMissed : BottomViewAction.markAsIncomplete;
    onBottomViewAction(task, isSelected ? BottomViewAction.markAsComplete : incompleteAction, {});
  }, [onBottomViewAction]);

  const onTaskAttachmentClick = useCallback((patientEducation: IAttachment) => {
    window.open(patientEducation.url, '_blank', 'noopener,noreferrer');
  }, []);

  const onTaskAssigneeClick = useCallback((task: ITask) => {
    onMemberClick?.(task);
  }, [onMemberClick]);

  const onHandleMultiSelection = useCallback(
    (event: CheckboxChangeEvent) => {
      const value = event.target.checked;
      handleMultiSelection?.(
        value
          ? MultiSelectAction.selectAllTasks
          : MultiSelectAction.unSelectAllTask
      );
    },
    [handleMultiSelection]
  );

  const bottomView = useCallback((task: ITask, isLoading: boolean) => {
    if (task.isCompleted) {
      return undefined;
    }
    return getBottomView(
      task,
      isLoading,
      vitals,
      onBottomViewAction,
      false,
      false,
      true
    );
  }, [vitals, onBottomViewAction]);

  const description = useCallback((task: ITask) => {
    return getDescription(
      task,
      task.patientName || task.contact?.name,
      descriptionDisplayModes.SHORT,
      vitals,
    );
  }, [vitals]);

  const renderTask = useCallback((task: ITask) => {
    const isLoading = getTaskCardLoading(task);
    const isMultiSelectEnabled = multiSelectState !== MultiSelectState.disabled;
    let isTaskSelected = task.isCompleted || task?.status?.code === TASK_STATUS.COMPLETED || false;
    if (isMultiSelectEnabled) {
      isTaskSelected = selectedTaskIdsRef.current?.has(task.id) || false;
    }
    return (
        <View
          width={'100%'}
          paddingY={2}
          paddingX={2}
          key={`${task.id}_${isLoading}_${isTaskSelected}_${metaData.bulkTaskEnabled}`}
        >
          <TaskCard
            task={task}
            isPatientTask={isPatientTask || false}
            key={task.id}
            title={task.title}
            disable={false}
            isChecked={isTaskSelected}
            data={task}
            handleDeleteOnCallback
            description={description(task)}
            tag={getCardTag(task)}
            assigneeName={isPatientTask ?
              (task.assigneeUser?.name || task.patientName || task.contact?.name) :
              (task.patientName || task.contact?.name)
            }
            onPress={onTaskCardPress.bind(null, task)}
            loading={isLoading || metaData?.intialDataLoading}
            attachments={
              task.patientEducations?.map((education: any) => {
                return education.patientEducation;
              }) || []
            }
            onCheckBoxSelect={
              metaData.bulkTaskEnabled ?
              (isSelected: boolean) => {
                handleMultiSelection?.(isSelected ? MultiSelectAction.selectTask : MultiSelectAction.unSelectTask, [task]);
              } : undefined
            }
            onCheckBoxChange={onTaskCheckBoxChange.bind(null, task)}
            onAttachmentClick={onTaskAttachmentClick}
            onAssigneeClick={onTaskAssigneeClick.bind(null, task)}
            bottomView={bottomView(task, isLoading)}
            hideCheckBox={canHideCheckBox(task) && !metaData.bulkTaskEnabled}
            onDeleteTaskHandler={onBottomViewAction}
            hideDeleteAction={true}
            hideAssigneeNavigation={isPatientTask}
            showDeleteField={true}
            accountLocationUuid={accountLocationUuid}
          />
        </View>
    );
  }, [getTaskCardLoading, metaData.bulkTaskEnabled, handleMultiSelection, onTaskCardPress, onTaskCheckBoxChange, onTaskAttachmentClick, onTaskAssigneeClick, onBottomViewAction, isPatientTask, accountLocationUuid, vitals]);

  const columnContent = useMemo(() => {
    return (
      <View>
        <Droppable
          droppableId={category.code}
          isDropDisabled={isDropDisabled}
        >
          {(
            provided: DroppableProvided,
            snapshot: DroppableStateSnapshot
          ) => {
            return (
              <div {...provided.droppableProps} ref={provided.innerRef} style={{height: getColumnHeight}}>
                {
                  componentState?.loaderInColumns &&
                  <View marginTop={25}>
                    <Spinner size={'lg'} />
                  </View>
                }
                {
                  !componentState?.loaderInColumns && !!tasks?.length &&
                  <InfiniteScroll
                    dataLength={tasks.length}
                    next={fetchNextPage}
                    hasMore={hasMoreData}
                    loader={<InfiniteScrollLoader />}
                    scrollableTarget={category.code}
                  >
                    <InnerList
                      taskList={tasks}
                      renderTask={renderTask}
                      loading={taskActionsInProgress.length > 0}
                      total={pagination.total}
                      selectedTaskIds={selectedTaskIdsRef.current || new Set()}
                      bulkActionEnabled={metaData.bulkTaskEnabled || false}
                    />
                    {provided.placeholder}
                  </InfiniteScroll>
                }
                {!componentState?.loaderInColumns && tasks?.length === 0 && !snapshot.isDraggingOver && (
                  <VStack
                    justifyContent={'center'}
                    height={height - (isPatientTask ? 360 : 310)}
                    alignItems={'center'}
                    padding={5}
                  >
                    <Image
                      width={'100px'}
                      height={'110px'}
                      resizeMode={'contain'}
                      source={require('../../../../assets/images/PNGImages/TabsPngIcons/EmptyState.png')}
                    />
                    <Text textAlign={'center'}>{getNoDataText}</Text>
                  </VStack>
                )}
              </div>
            );
          }}
        </Droppable>
      </View>
    )
  }, [renderTask, category, isDropDisabled, componentState.loaderInColumns, tasks, pagination, hasMoreData, fetchNextPage, taskActionsInProgress, metaData.bulkTaskEnabled, getNoDataText]);


  React.useEffect(() => {
    return () => {
      captureTransactionInst.clearTransaction(
        TRANSACTION_NAMES.TASK_FETCH_NEXT_PAGE,
        category.code
      );
    };
  }, []);

  const columnCountText = useMemo(() => {
    return getTodayDueTaskTextLabel(todaysCountData?.count, countData?.count, category.code, isTaskPoolBoard);
  }, [todaysCountData?.count, countData?.count, category.code, isTaskPoolBoard])

  const kanbanHeader = useMemo(
    () => (
      <div style={kanbanStyles.columnHeader}>
        <Stack direction="row" space={4}>
          <View paddingX={2}>
            <Text>{category.name} </Text>
          </View>
          <Badge
            fontSize={12}
            fontWeight={500}
            backgroundColor="gray.100"
            alignSelf="center"
            rounded="full"
            justifyContent={'center'}
            paddingY={1}
            paddingX={2}
          >
            {columnCountText}
          </Badge>
          <Spacer />
          {(loading || metaData?.intialDataLoading) && (
            <View
              width={'25px'}
              style={styles.spinnerContainer}
            >
              <Spinner size={'sm'} />
            </View>
          )}
          {multiSelectState !== MultiSelectState.disabled && (
            <View marginRight={2}>
              <Checkbox
                checked={multiSelectState === MultiSelectState.all}
                indeterminate={multiSelectState === MultiSelectState.some}
                value="multiselect"
                onChange={onHandleMultiSelection}
              />
            </View>
          )}
        </Stack>
      </div>
    ),
    [columnCountText, category.name, loading, multiSelectState]
  );

  return (
    <div style={columnContainerStyles}>
      {kanbanHeader}
      <Divider bgColor={'gray.200'} />
      <div
        id={category.code}
        style={{height: getColumnHeight, overflowY: 'scroll', backgroundColor: Colors.FoldPixel.GRAY100}}
        ref={taskScrollContainer}
      >
        {columnContent}
      </div>
      {deleteTaskData.showConfirmation && (
        <DeleteTaskModal
          deleteTaskData={deleteTaskData}
          onDeleteCancel={onDeleteCancel}
          onDeleteConfirm={onDeleteConfirm}
        />
      )}
    </div>
  );
}

const MemoizedTaskCard = React.memo(({
  task,
  renderTask,
}: {
  task: ITask;
  renderTask: (task: ITask) => React.ReactElement;
  loading?: boolean;
  isSelected: boolean;
  bulkActionEnabled: boolean;
}) => {
  return renderTask(task);
}, (prevProps, nextProps) => {
  return prevProps.task.id === nextProps.task.id &&
         isEqual(prevProps.task.labels, nextProps.task.labels) &&
         prevProps.loading === nextProps.loading &&
         prevProps.bulkActionEnabled === nextProps.bulkActionEnabled &&
         prevProps.isSelected === nextProps.isSelected;
});


const InnerList = React.memo(({
  taskList,
  renderTask,
  loading,
  total,
  selectedTaskIds,
  bulkActionEnabled
}: {
  taskList: any[],
  renderTask: (task: ITask) => React.ReactElement,
  loading?: boolean,
  total?: number,
  selectedTaskIds: Set<string>,
  bulkActionEnabled: boolean;
}) => {
  const renderItem = useCallback((item: ITask, index: number) => (
    <Draggable key={`Draggable_${item.id}`} draggableId={item.id} index={index}>
      {(provide: DraggableProvided) => (
        <div
          {...provide.draggableProps}
          {...provide.dragHandleProps}
          ref={provide.innerRef}
        >
          <div>
            {/* <MemoizedTaskCard
              task={item}
              renderTask={renderTask}
              loading={loading}
              isSelected={selectedTaskIds.has(item.id)}
              bulkActionEnabled={bulkActionEnabled}
            /> */}
            {renderTask(item)}
          </div>
        </div>
      )}
    </Draggable>
  ), [renderTask, loading, selectedTaskIds, bulkActionEnabled]);

  // return (
  //   <FlatList
  //     data={taskList}
  //     renderItem={renderItem}
  //     keyExtractor={(item: any): string => `${item?.id}`}
  //     style={styles.list}
  //     initialNumToRender={1}
  //     windowSize={5}
  //     maxToRenderPerBatch={1}
  // />
  // );
  return (
    <List
      dataSource={taskList}
      size="small"
      style={listStyles}
      rowKey={(item: any): string => item?.id}
      renderItem={renderItem}
    />
  );
});

const styles = StyleSheet.create({
  spinnerContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  innerList: {
    width: '100%',
    height: '100%',
  },
  listItem: {
    width: '100%',
  },
  list: {
    width: '100%',
    height: '100%',
    paddingHorizontal: 12,
  }
});

export default React.memo(TaskKanbanColumn)

const InfiniteScrollLoader = () => {
  return (
    <VStack paddingX={4} space={2}>
      <View width={'full'}>
        <Card bgColor={'#FFF'}>
          <Skeleton paragraph={{rows: 2}} active />
        </Card>
      </View>
      <Text
        height={8}
        alignItems={'center'}
        justifyContent={'center'}
        textAlign={'center'}
        color={Colors.Custom.Gray500}
      >
        {`Fetching tasks...`}
      </Text>
    </VStack>
  );
};

const listStyles: React.CSSProperties = {
  width: '100%',
  height: '100%',
};

const getColumnContainerStyles = (width: string): React.CSSProperties => ({
  backgroundColor: 'white',
  border: '1px solid',
  borderColor: Colors.Custom.Gray200,
  flex: 1,
  borderRadius: 12,
  width: width,
});

const kanbanStyles: Record<string, React.CSSProperties> = {
  columnHeader: {
    marginTop: 16,
    marginBottom: 16,
    marginLeft: 8,
    marginRight: 8,
  },
};
