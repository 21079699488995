import { useLazyQuery, useMutation } from '@apollo/client';
import '@toast-ui/editor/dist/toastui-editor.css';
import { Avatar, Box, HStack, Skeleton, Text, VStack, View, useToast } from 'native-base';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import { MLOV_CATEGORY } from '../../../constants';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../constants/Configs';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { TaskQueries } from '../../../services';
import { Colors } from '../../../styles';
import { getAccountUUID, getColorFromOnlineStatus, getUserUUID } from '../../../utils/commonUtils';
import { getMlovListFromCategory } from '../../../utils/mlovUtils';
import { getDisplayDate } from '../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailTables/Activities/ActivityUtils';
import { getOpenAiConversation, sendMessageToOpenAi } from '../../RightSideContainer/Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import TaskCard from '../../TaskCard/TaskCard';
import { BottomViewAction } from '../../TaskCard/TaskCardHelper';
import { getCardTag } from '../../TaskCard/TaskCardHelper.native';
import AddOrUpdateTask from '../AddTask/AddOrUpdateTask';
import SlotsGroup from '../CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/SlotsGroup';
import { ITask } from '../CareDashboard/CareDashboardInterfaces';
import { getCompletedTaskStatusId } from '../CareDashboard/CareDashboardUtils/CareDashboardUtils';
import ContactCardView from '../ChatUI/MessageBox/MessagingCustomCard/ContactCard/ContactCardView';
import SendButtonSvg from '../Svg/SendButtonSvg';
import UnityAiIcon from '../Svg/UnityAiIcon';
import DisplayTemplateById from '../TemplateSearch/DisplayTemplateById';
import './ChatBotStyle.css';
import { ToastType, showToast } from '../../../utils/commonViewUtils';

type Message = {
  id: string,
  actor: string;
  content: string;
  responseType?: string;
  createdOn?: string
};

const INTENT_MAP = {
  CREATE_TASK: 'create_task',
  SEARCH_PATIENT: 'search_patient',
  AVAILABLE_TIME_SLOT_FOR_APPOINTMENT: 'show_available_slots',
  SEND_EMAIL: 'send_email'
}


const UserContent = (props: { message: Message }) => {
  const createdOn =  props.message?.createdOn || (new Date()).toISOString();
  return <div key={props.message.id} style={{ marginBottom: '20px', marginTop: '20px', maxWidth: '100%' }}>
    <VStack width={'100%'} >
      <VStack>

        <div className={'user'} style={{ flexDirection: 'column' }}>
          <HStack space={2}> <Text color={'rgb(130, 90, 199)'} alignItems={'center'} justifyContent={'flex-start'}>You</Text> <Text justifyContent={'center'} alignItems={'center'} ><div className="unit-ai-date">{getDisplayDate(createdOn || '', 'time')}</div></Text></HStack>
          <HStack space={2} justifyContent={'center'}> <div className={'user-text'} style={{alignItems:'center'}}><HStack height={'full'} alignItems={'center'} whiteSpace='pre-line'>{props?.message?.content || ''}</HStack></div></HStack>
        </div>
      </VStack>
    </VStack>
  </div>
}

const ShowTaskCreateIntentMessage = (props: { message: Message }) => {
  const content = JSON.parse(props?.message?.content);
  const taskId = content?.result?.taskId;
  const toast = useToast();
  const [isShowAddOrUpdate, setIsShowAddOrUpdate] = useState(false)
  const intl = useIntl();
  const mlovData = useContext(CommonDataContext);
  const taskStatusMlov =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.TASK_STATUS
    ) || [];
  const completedStatusId = getCompletedTaskStatusId(taskStatusMlov);
  const [getTaskByIdApi] = useLazyQuery(TaskQueries.GET_TASK_BY_ID, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
  });

  const [updateTaskStatus] = useMutation(TaskQueries.UPDATE_TASK_STATUS, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    onCompleted: () => {
      setIsShowAddOrUpdate(false);
      showToast(
        toast,
        intl.formatMessage({id: 'taskCompletedMsg'}),
        ToastType.success,
        4000,
      );
      const status:any = {...(task?.status||{}), code: 'completed'}
      if(task){
        setTask({...task, status: status})
      }
    },
    onError: (error: any) => {

      setIsShowAddOrUpdate(false);
    },
  });

  const handleBottomViewActions = (
    task: ITask,
    action: BottomViewAction,
  ) => {

    switch (action) {
      case BottomViewAction.markAsComplete:
        updateTaskStatus({
          variables: {
            params: {
              id: task.id,
              data: {
                statusId: completedStatusId,
              },
            },
          },
        });
        break;

      default:
        break;
    }
  };
  const [task, setTask] = useState<ITask>()
  const [isLoading, setIsLoading] = useState(false);
  const loadTask = async () => {
    setIsLoading(true)
    const response = await getTaskByIdApi({
      variables: { id: taskId }
    })
    const task = response?.data?.getTasks?.tasks?.[0];
    setTask(task)
    setIsLoading(false)
  }
  useEffect(() => {
    if (taskId) {
      loadTask()
    }
  }, [])

  return task && !isLoading ? <VStack width={'100%'}>
  <TaskCard hideCheckBox={true} hideDeleteAction={true} onPress={() => { setIsShowAddOrUpdate(true) }}
  onAttachmentClick={() => { }}
  onCheckBoxChange={() => { }}
  data={task}
  task={task}
  tag={getCardTag(task)}
  attachments={
    task.patientEducations?.map((education) => {
      return education.patientEducation;
    }) || []
  } isChecked={'completed' == task.status?.code} key={task.id} disable={'completed' == task.status?.code} title={task?.title} ></TaskCard>
    <AddOrUpdateTask
      key={task.id + (task.status?.code||'')}
      task={task}
      isVisible={isShowAddOrUpdate}
      onMarkAsComplete={handleBottomViewActions}
      onComplete={() => {
        setIsShowAddOrUpdate(false);
      }}
      onCancel={() => {
        setIsShowAddOrUpdate(false);
      }}
    />
  </VStack> : isLoading ? <Skeleton height={'200px'} width={'100%'} isLoaded={!isLoading} /> : <div className={'chatbot'}>{content?.result?.message || 'No Task Created'}</div>
}


const ShowPatientList = (props: { message: Message }) => {
  const content = JSON.parse(props?.message?.content);
  const responseType = props?.message?.responseType || ''
  const contactUuidList = content?.result?.contactUuidList || [];
  return <>
    {contactUuidList?.length ? contactUuidList.map((uuid: string) => {
      return <div key={props.message.id} className={'chatbot'} >
        { <ContactCardView contactUUID={uuid} navigation={''}></ContactCardView> }
      </div>
    }) : <div className={'chatbot'}>No patient found</div>}
  </>
}

const ShowAvailableSlots = (props: { message: Message }) => {
  const content = JSON.parse(props?.message?.content);
  const responseType = props?.message?.responseType || ''
  let slots: any = []
  Object.keys(content?.result?.availableSlot?.slots || []).forEach((date) => {
    slots = [ ...slots,...content?.result?.availableSlot?.slots[date] ]
  });
  const slotStartDate = new Date(content?.result?.requestData?.slotStartDate)
  const timezone = content?.result?.requestData?.timezone || '';
  return <VStack>
    <div key={props.message.id} className={'chatbot'}>
    <VStack>
    { content?.result?.matchedUserName ? <HStack>{'Provider Name: ' + content?.result?.matchedUserName}</HStack> : <></>}
    { content?.result?.matchedLocation ? <HStack>{'Location Name: ' + content?.result?.matchedLocation}</HStack> : <></>}
    { content?.result?.slotStartDate ? <HStack>{'From: ' + getDisplayDate(content?.result?.slotStartDate || '', 'time')}</HStack> : <></>}
    { content?.result?.slotEndDate ? <HStack>{'To: ' + getDisplayDate(content?.result?.slotEndDate || '', 'time')}</HStack> : <></>}
    { content?.result?.timezone ? <HStack>{'Timezone: ' + content?.result?.timezone}</HStack> : <></>}
    { content?.result?.duration ? <HStack>{'Duration: ' + content?.result?.duration}</HStack> : <></>}
    { content?.result?.isVirtualLocation != undefined ? <HStack>{'Appointment Mode: ' + (content?.result?.isVirtualLocation ? 'Virtual' : 'At Clinic')}</HStack> : <></>}
    </VStack>
    </div>
    <div key={props.message.id} className={'chatbot'}>
      {slots?.length ? <SlotsGroup key={props.message.id} handleSlotSelection={() => { }} userSlots={slots} selectedSlotDate={slotStartDate} selectedTimezone={timezone} isMobileView={false}></SlotsGroup> : <>{content?.result?.message || 'No slots Available'}</>}
    </div>
  </VStack>

}

const ShowSendEmailTemplate = (props: { message: Message }) => {
  const content = JSON.parse(props?.message?.content);
  const sendToListResponse = content?.result?.sendToListResponse || [];
  return <>
  { sendToListResponse?.length ?
  <>
   { sendToListResponse.map((emailResult:any)=>{
    return <div key={props.message.id} style={{width:'80%'}}>
      {emailResult?.emailId ?
        emailResult?.templateId ?
        <VStack>
        <Text>{'To: ' +  emailResult?.sendTo}</Text>
        <Text>{'Subject: ' +  emailResult?.emailSubject}</Text>
        <DisplayTemplateById hideEdit={true} templateId={emailResult?.templateId} templateCategories={[]} mergeTags={emailResult?.mergeTag }/>
        </VStack>
        : 'Default General email template not found'
       :
       <>{emailResult?.message || 'Email Failed'}</>}
    </div> }) }</> : <>Email Not Sent</>}
  </>

}

const AssistantContent = (props: { message: Message }) => {
  const content = JSON.parse(props?.message?.content);
  const responseType = props?.message?.responseType || ''
  let childComponent = <></>
  if (responseType == 'INTENT') {
    switch (content?.intent) {
      case INTENT_MAP.CREATE_TASK:
        childComponent = <div key={props.message.id} className={''} style={{backgroundColor:'#fff', boxShadow:'none', borderWidth:0}}><View width={'400px'}><ShowTaskCreateIntentMessage message={props.message}></ShowTaskCreateIntentMessage></View></div>
        break;
      case INTENT_MAP.SEARCH_PATIENT:
        childComponent = <ShowPatientList key={props?.message?.id} message={props?.message}></ShowPatientList>
        break;
      case INTENT_MAP.AVAILABLE_TIME_SLOT_FOR_APPOINTMENT:
        childComponent = <ShowAvailableSlots key={props?.message?.id} message={props?.message}></ShowAvailableSlots>
        break;
      case INTENT_MAP.SEND_EMAIL:
          childComponent = <ShowSendEmailTemplate key={props?.message?.id} message={props?.message}></ShowSendEmailTemplate>
          break;
      default:
        childComponent = <div key={props.message.id} className={'chatbot'}>{"I'm sorry, I didn't understand that"} </div>
    }

  } else {
    childComponent = <div key={props.message.id} className={'chatbot'} >{content?.message}</div>
  }
  return <HStack width={'full'} space={2}>
    <Avatar
      bg={Colors.Custom.Primary50}
      borderColor={Colors.primary[400]}
      borderWidth={1}
      size={12}
    >

      <UnityAiIcon width="30.04" height="30.66"></UnityAiIcon>
      <Avatar.Badge
        style={{
          width: 12,
          height: 12,
        }}
        bg={getColorFromOnlineStatus('ONLINE')}
      />
    </Avatar>
    <VStack width={'full'}>
      <HStack space={2}>
        <Text color={'rgb(130, 90, 199)'} alignItems={'center'} justifyContent={'flex-start'}>Unity</Text> <Text justifyContent={'center'} alignItems={'center'} ><div className="unit-ai-date">{getDisplayDate(props.message?.createdOn || '', 'time')}</div></Text>
      </HStack>
      <div> {childComponent}</div>
    </VStack></HStack>
}




const Chatbot = (props: { width: string, heigth: string, setIsHideUnitIcon: any }) => {
  const currentUserUUID = getUserUUID();
  const accountUuid = getAccountUUID();

  const [chatBotState, setChatBotState] = useState<{
    isLoading: boolean
    messages: Array<Message>,
    userMessage: string
    offset: number,
    limit: number,
    lastAction: string
  }>({
    isLoading: false,
    userMessage: '',
    messages: [],
    offset: 0,
    limit: 20,
    lastAction: ''

  })

  const chatMessagesRef = useRef<HTMLDivElement | null>(null);
  const [rows, setRows] = useState(2);
  const handleUserMessageChange = (event:any) => {

    setChatBotState((oldState) => {

      return { ...oldState, userMessage: event.target.value }
    })




  };

  useEffect(()=>{
    const newlineCount = ((chatBotState.userMessage || '').match(/\n/g) || []).length;
    const row = Math.min(5, Math.max(1, 1 + newlineCount));
    setRows(row <2 ? 2: row);
  }, [chatBotState.userMessage])



  const sendMessage = ()=> {
    const userMsg = chatBotState.userMessage || '';
    if(!userMsg.trim()){
      return;
    }
      addMessage(userMsg, true);
      generateChatbotResponse(userMsg);
      setChatBotState((oldState) => {
        return { ...oldState, userMessage: '' }
      })
  }

  const handleUserMessageSubmit = (event: any) => {
    if(event.key === 'Enter' && event.shiftKey){
    }else if (event.key === 'Enter') {
      sendMessage();

    }
  };





  const addMessage = (text: string, isUser: boolean) => {
    const newMessage: Message = { id: uuidv4(), content: text, actor: isUser ? 'USER' : 'ASSISTANT' };
    setChatBotState((oldState) => {
      return { ...oldState, messages: [newMessage, ...oldState.messages], lastAction: "ADD_MESSAGE" }
    })


  };

  const generateChatbotResponse = async (userMsg: string) => {
    // You would integrate an NLP API (e.g., GPT-3) here to generate chatbot responses.
    // For simplicity, we'll echo the user's message as a response.
    setIsLoading(true)
    try {
      const openAiResponse = await sendMessageToOpenAi(userMsg)
      if (openAiResponse?.data?.data?.content) {
        setChatBotState((oldState) => {
          return { ...oldState, messages: [openAiResponse?.data?.data, ...oldState.messages], lastAction: "ADD_MESSAGE" }
        })
      }
    } catch {
      setIsLoading(false)
    }
    setIsLoading(false)
  };





  const renderChatMessages = () => {
    const reversedArray = [...chatBotState?.messages].reverse();
    return (reversedArray || []).map((message, index) => {
      return message?.actor == 'USER' ? <UserContent message={message}></UserContent> : <AssistantContent message={message}></AssistantContent>
    });
  };

  const handleChatScroll = (event: React.UIEvent<HTMLDivElement>) => {
    if (event.currentTarget.scrollTop === 0) {
      // When the user scrolls to the top of the chat
      setChatBotState((oldState) => {
        return { ...oldState, offset: oldState?.messages?.length }
      })
    }
  };

  const [isLoading, setIsLoading] = useState(false)

  const loadMessages = async () => {
    setIsLoading(true)
    try {
      const messages = await getOpenAiConversation({ tenantId: accountUuid, userUuid: currentUserUUID, offset: chatBotState.offset, limit: chatBotState.limit })
      setChatBotState((oldState) => {
        return { ...oldState, messages: [...oldState?.messages, ...messages], lastAction: chatBotState.offset ? 'LOAD_MESSAGE_OFFSET' : 'LOAD_MESSAGE' }
      })
      setIsLoading(false)
    } catch {
      setIsLoading(false)
    }

  }
  const inputRef: any = useRef(null);
  useEffect(() => {
    loadMessages();
  }, [chatBotState.offset])




  useEffect(() => {
    props.setIsHideUnitIcon(!chatBotState?.messages?.length)

    if (chatMessagesRef.current && chatBotState?.lastAction != 'LOAD_MESSAGE_OFFSET') {
      chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }

  }, [chatBotState?.messages?.length])




  return (
    <div className="chatbot-container spinner-input-container">
      <View width={props.width} style={{overflow:'hidden'}} justifyContent={'space-between'}>
        <div className="chat-messages" style={{ height: props.heigth }} ref={chatMessagesRef} onScroll={handleChatScroll}>
          {!chatBotState?.messages?.length ? <VStack alignItems={'center'}>
            <UnityAiIcon width="88.04" height="88.66"></UnityAiIcon>
            <Text fontFamily={'sora'} marginTop={'20px'} width={'383px'} height={'60px'}>Fold Unity AI is here to assist you deliver faster care. Unity AI can help you get things done quicker. Give your personal care assistant a try</Text>
          </VStack> : <></>}
          {renderChatMessages()}
        </div>

        <HStack justifyContent={'flex-start'} alignItems={'flex-start'}>

          <div className="open-ai-chat-input" style={{flexDirection:'row'}}>
           <textarea
           rows={rows}
           style={{backgroundColor:'#fff',borderWidth:'1px', width:'710px', padding:'5px', paddingRight:'55px' ,borderRadius:'5px 5px 5px 5px', borderColor:'rgb(102, 112, 133) rgb(102, 112, 133) rgb(102, 112, 133) rgb(102, 112, 133)', resize:'none'}}
           ref={inputRef}
           disabled={isLoading}
          value={chatBotState.userMessage}
          onChange={handleUserMessageChange}
          onKeyPress={handleUserMessageSubmit}
          placeholder="Type a message..."
        />
          {isLoading ? <div className="spinner"></div> : <></>}
          </div>
          <button  onClick={sendMessage} style={{marginLeft:'-50px',marginTop:'5px',borderWidth:'0px',backgroundColor:'#fff',borderColor:'#fff',height: '30px', width:'40px', borderRadius:'0px'}}><SendButtonSvg></SendButtonSvg></button>
        </HStack>

      </View>
    </div>
  );
};

export default Chatbot;
