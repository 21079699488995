import {useLazyQuery} from '@apollo/client';
import {
  FlatList,
  HStack,
  Pressable,
  Spacer,
  Spinner,
  Text,
  VStack,
  View,
  useToast,
} from 'native-base';
import React, {useState} from 'react';
import {Checkbox, Divider, Skeleton, notification} from 'antd';
import {LeadQueries} from '../../../../../../../services';
import {useIntl} from 'react-intl';
import {getPatientSearchORCondition} from '../../../../../../RightSideContainer/Contacts/ContactsUtils';
import {Colors} from '../../../../../../../styles';
import {SearchBar} from '../../../../../SearchBar';
import {debounce} from 'lodash';
import LoadingSpinner from '../../../../../Loader/LoadingSpinner';
import MemberInfoListItem from '../../../../../MemberInfoListItem/MemberInfoListItem';
import Feather from 'react-native-vector-icons/Feather';
import {IContact} from '../../../../../../RightSideContainer/TeamInbox/Conversations/interfaces';
import InviteMemberEmptyState from './components/InviteMemberEmptyState';
import BulkActionView from './components/BulkActionView';

interface IAddInviteMembersViewProps {
  onInviteUser: (contactIds: IContact[]) => Promise<string[] | undefined>;
  onClose: () => void;
  inviteUserLoading: boolean;
  externalContactList?: string[];
  title: string;
  subtitle: string;
  inviteButtonTitle: string;
  isRsvpAppointment?: boolean;
  memberLimit?: number;
}

function AddInviteMembersView(props: IAddInviteMembersViewProps) {
  const [contactsSearchResults, setContactsSearchResults] = useState<
    IContact[]
  >([]);
  const [isShowList, setIsShowList] = useState<boolean>(false);
  const [selectedContacts, setSelectedContacts] = useState<
    Map<string, IContact>
  >(new Map());
  const toast = useToast();
  const intl = useIntl();

  const [getContacts] = useLazyQuery<{contacts: IContact[]}, any>(
    LeadQueries.SearchContacts3,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [loadingStates, setLoadingStates] = useState(false);

  const searchContacts = (searchString: string) => {
    setContactsSearchResults([]);
    if (searchString?.length > 1) {
      setLoadingStates(true);
      let searchStringArray = searchString?.split(' ');
      searchStringArray = searchStringArray?.filter((char: any) => {
        return char.length >= 1;
      });
      const where = {
        _or: getPatientSearchORCondition(searchString, searchStringArray),
      };
      getContacts({
        variables: {
          searchString: '%' + searchString + '%',
          limit: 50,
          where: where,
        },
      })
        .then((contactRespData) => {
          setContactsSearchResults(contactRespData?.data?.contacts || []);
          setLoadingStates(false);
        })
        .catch(() => {
          setContactsSearchResults([]);
          setLoadingStates(false);
        });
    }
  };

  const renderMemberInvitedStatus = (invited: boolean) => {
    if (!invited) {
      return <></>;
    }
    return (
      <HStack alignItems={'center'} ml={2}>
        <Feather
          name={'check-circle'}
          size={18}
          color={Colors.Custom.Gray400}
          style={{marginRight: 8, paddingTop: 4}}
        />
        <Text color={Colors.Custom.Gray400}>
          {intl.formatMessage({
            id: props?.isRsvpAppointment ? 'invited' : 'added',
          })}
        </Text>
      </HStack>
    );
  };

  const inviteAction = () => {
    const isDisabled = selectedContacts.size === 0 || props?.inviteUserLoading;

    return (
      <Pressable
        disabled={isDisabled}
        onPress={async () => {
          if (selectedContacts.size > 0) {
           const ids = await props.onInviteUser(
              Array.from(selectedContacts.values())
            );
            if (ids) {
              const map = new Map(selectedContacts);
              ids.forEach((id) => {
                map.delete(id);
              });
              setSelectedContacts(map);
            }
          }
        }}
      >
        <Text
          fontSize={14}
          color={
            isDisabled ? Colors.Custom.Gray500 : Colors.Custom.mainPrimaryPurple
          }
        >
          {props?.inviteButtonTitle}
        </Text>
      </Pressable>
    );
  };
  const isOverMemberLimit = (bulkActionCount?: number) => {
    const bulkActionMembersCount = bulkActionCount || 0;
    const contactListSize = props.externalContactList?.length || 0;
    const memberLimit = props.memberLimit || 0;
    return (selectedMembers + bulkActionMembersCount + contactListSize) >= memberLimit;
  };


  const renderContact = ({item}: {item: IContact}) => {
    const contactObj = item;
    const invited = !!props?.externalContactList?.includes(
      contactObj?.uuid as string
    );
    return (
      <HStack
        alignItems="center"
        px={4}
        py={2}
        borderBottomWidth={1}
        borderBottomColor={Colors.Custom.Gray300}
      >
        <Checkbox
          style={{
            marginRight: 10,
          }}
          disabled={invited}
          checked={invited || selectedContacts.has(contactObj?.uuid as string)}
          value={contactObj?.uuid}
          onChange={(e) => {
            const set = selectedContacts;
            if (e.target.checked && contactObj?.uuid) {
              if (!props.isRsvpAppointment && isOverMemberLimit()) {
                notification.error({ message: intl.formatMessage({ id: 'groupAppointmentMemberLimitExceeded' }) });
                return;
              }
              setSelectedContacts(
                (prev) =>
                  new Map(prev.set(contactObj?.uuid as string, contactObj))
              );
            } else {
              setSelectedContacts((prev) => {
                const newSet = new Map(prev);
                newSet.delete(contactObj?.uuid as string);
                return newSet;
              });
            }
          }}
        />
        <VStack flex={1} opacity={invited ? 0.5 : 1}>
          <MemberInfoListItem
            contactData={contactObj}
            showDateOfBirth={true}
            extaSubHeader={renderMemberInvitedStatus(invited)}
          />
        </VStack>
      </HStack>
    );
  };

  const loadingView = () => (
    <HStack space={2} my={2}>
      <Skeleton.Avatar active size="large" />
      <Skeleton active />
    </HStack>
  );

  const renderMainView = () => {
    if (loadingStates) {
      return (
        <VStack flex={1} px={4}>
          {loadingView()}
          {loadingView()}
          {loadingView()}
        </VStack>
      );
    }
    if (isShowList) {
      if (contactsSearchResults?.length > 0) {
        return (
          <FlatList
            data={contactsSearchResults}
            renderItem={renderContact}
            keyExtractor={(item) => item?.uuid as string}
          />
        );
      } else {
        return (
          <>
            <InviteMemberEmptyState title="No Contacts Found" />
          </>
        );
      }
    } else {
      if (selectedContacts.size > 0) {
        return (
          <FlatList
            data={Array.from(selectedContacts.values())}
            renderItem={renderContact}
            keyExtractor={(item) => item?.uuid as string}
          />
        );
      }
      return (
        <>
          <InviteMemberEmptyState title="Search contacts" />
        </>
      );
    }
  };

  const selectedMembers = Array.from(selectedContacts).length;
  const membersForCheckBoxCount = contactsSearchResults?.filter(
    (contact) => !props.externalContactList?.includes(contact.uuid as string)
  );

  const isBulkActionChecked = () => {
    return (
      (selectedMembers === membersForCheckBoxCount.length &&
        membersForCheckBoxCount.length > 0) ||
      (!isShowList && selectedMembers > 0)
    );
  }

  return (
    <View flex={1}>
      <View
        width={'100%'}
        style={{
          paddingHorizontal: 16,
          marginBottom: 10,
        }}
      >
        <HStack mt={4} alignItems="center">
          <Text fontWeight={700} fontSize={18}>
            {props?.title}
          </Text>
          <Spacer />
          {inviteAction()}
          <Divider type="vertical" />
          <Pressable onPress={props.onClose}>
            <Feather name="x" size={20} color={Colors.Custom.Gray400} />
          </Pressable>
        </HStack>
        {props.subtitle.length > 0 && (
          <Text color={Colors.Custom.Gray500} fontSize={16} mb={4}>
            {props.subtitle}
          </Text>
        )}
        <SearchBar
          onChange={debounce((value: string) => {
            searchContacts(value);
            setIsShowList(value.length ? true : false);
          }, 500)}
          placeholderText={'Search Members'}
          borderRadius={6}
          searchBarInputStyles={{
            paddingVertical: 3.5,
            backgroundColor: Colors.Custom.MonochromeWhite,
          }}
          height={100}
        />
      </View>
      <BulkActionView
        checked={isBulkActionChecked()}
        intermideate={
          selectedMembers > 0 &&
          selectedMembers < membersForCheckBoxCount.length
        }
        onChange={(e) => {
          if (e.target.checked) {
            const bulkActionCount = membersForCheckBoxCount.length ? membersForCheckBoxCount.length - 1 : 0;
            if (!props.isRsvpAppointment && isOverMemberLimit(bulkActionCount)) {
              notification.error({ message: intl.formatMessage({ id: 'groupAppointmentMemberLimitExceeded' }) });
              return;
            }
            const prev = new Map(selectedContacts);
            membersForCheckBoxCount.forEach((contact) => {
              prev.set(contact?.uuid as string, contact);
            });
            setSelectedContacts(prev);
          } else {
            setSelectedContacts(new Map());
          }
        }}
      >
        <Text color={Colors.Custom.Gray500} ml={2}>
          {selectedMembers > 0
            ? `${selectedMembers} Members Selected`
            : `Members`}
        </Text>
      </BulkActionView>
      <View
        style={{
          maxHeight: '70vh',
          position: 'relative',
          flex: 1,
          opacity: props?.inviteUserLoading ? 0.5 : 1,
        }}
      >
        {renderMainView()}
      </View>
      {props?.inviteUserLoading && (
        <View
          style={{
            backgroundColor: 'transparent',
            opacity: 1,
            position: 'absolute',
            height: '100%',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner size={'lg'} />
        </View>
      )}
    </View>
  );
}

export default AddInviteMembersView;
