import {HStack, View, Text} from 'native-base';
import {Colors} from '../../../styles';
import {useState} from 'react';
import {AddMediaModal} from '../../RightSideContainer/ContentManagement/MediaLibrary/AddMediaModal';
import {Pressable} from 'react-native';

export interface IUploadButtonProps {
    onUpload: (uploadUrl: string) => void;
  }

const UploadButton = (props: IUploadButtonProps) => {
  const [isUploadDrawerVisible, setIsUploadDrawerVisible] = useState(false);

  return (
    <View alignSelf={'flex-start'} justifySelf={'flex-start'}>
      <Pressable
        onPress={() => {
          setIsUploadDrawerVisible(true);
        }}
      >
        <HStack
          style={[
            {
              backgroundColor: Colors.secondary['100'],
              borderColor: Colors.secondary['200'],
              height: 40,
              borderRadius: 20,
              borderWidth: 1,
              minWidth: 64,
              paddingHorizontal: 14,
              paddingVertical: 8,
            },
          ]}
        >
          <Text size={'smMedium'} color={Colors.secondary['800']}>
            Upload
          </Text>
        </HStack>
      </Pressable>
      {isUploadDrawerVisible && (
        <AddMediaModal
          onClose={() => {
            setIsUploadDrawerVisible(false);
          }}
          onUpload={(data) => {
            if (data?.data?.attributes?.file?.data?.[0]?.attributes?.url) {
                props?.onUpload(data.data.attributes.file.data[0].attributes.url);
                setIsUploadDrawerVisible(false);
            } else {
                setIsUploadDrawerVisible(false);
            }
          }}
          supportedFormatString='Only MP3 format is supported'
          acceptedFiles='.mp3'
        />
      )}
    </View>
  );
};

export default UploadButton;
