import {Divider, Icon, Stack, Text, View} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import {DATE_FORMATS, RAF_MODEL, SCORE_TYPE} from '../../constants';
import './PopOverRafScore.css';
import {Colors} from '../../styles';
import {genderCodeToDisplay, getHccScoreData, hasValue} from '../../utils/commonUtils';
import Feather from 'react-native-vector-icons/Feather';
import {ConditionsAndHCCs} from './../ConditionsAndHCCs';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { Popover } from 'antd';
import { getDateStrFromFormat } from '../../utils/DateUtils';

import RafScoreDiff from '../RafScore/RafScoreDiff';
import { IContact } from '../RightSideContainer/TeamInbox/Conversations/interfaces';
import { IConditionItem, IPopOver } from '../RafScore/interface';
import { getColorCodeForHCC } from '../../sidecar/common/SideCardCommonUtils';
import { CONDITION_CODES, DEMOGRAPHIC_CODES } from './RafHccConditionsConst';
import { CommonDataContext } from '../../context/CommonDataContext';

interface IComponentState {
  conditionsAndHccsList: IConditionItem[];
  d2List: IConditionItem[];
  interactionEffectList: IConditionItem[];
}

export const RAFScorePopoverContent = (props: IPopOver) => {
  const [componentState, setComponentState] = useState<IComponentState>({
    conditionsAndHccsList: [],
    d2List: [],
    interactionEffectList: [],
  });
  const commonData = useContext(CommonDataContext);
  const isSidecarContext = commonData?.sidecarContext?.isSidecar;
  const referenceData = props?.contactScore?.reference;

  const { hccScore, hccScoreDiff, hccScoreValue, formattedHccScoreDiff } = getHccScoreData(
    props?.contactScore,
    props?.contactScoreAudits,
    SCORE_TYPE,
    hasValue
  );

  useEffect(() => {
    getFilteredData();
  }, []);

  const getFilteredData = () => {
    const allDataWithValues = [];
    const conditionsAndHccsList: IConditionItem[] = [];
    const d2List: IConditionItem[] = [];
    const interactionEffectList: IConditionItem[] = [];
    const data = referenceData?.hccRiskScoreData?.hccV28RiskScoreData?.output;
    data?.hcc_lst?.forEach((hcc: any) => {
      const conditionCodes = CONDITION_CODES.join('|');
      const keyRegex = new RegExp(`^(${conditionCodes})_${hcc}$`);
      const key = Object.keys(data.details).find(k => keyRegex.test(k));
      const value = key ? data.details[key] : undefined;
      if (value !== undefined) {
        allDataWithValues[hcc] = value;
        if (hcc.startsWith('HCC')) {
          conditionsAndHccsList.push({[hcc]: value});
        } else if (DEMOGRAPHIC_CODES.includes(hcc)) {
          d2List.push({[hcc]: value});
        } else {
          interactionEffectList.push({[hcc]: value});
        }
      }
    });
    setComponentState((prev) => ({
      ...prev,
      conditionsAndHccsList: conditionsAndHccsList,
      d2List: d2List,
      interactionEffectList: interactionEffectList,
    }));
  };

  const getConditionsAndHCCsElement = () => {
    return (
      <>
        <ConditionsAndHCCs
          contactScore={props?.contactScore}
          conditionsAndHccsList={componentState.conditionsAndHccsList}
          icdConditionsList={props?.icdConditionsList}
        />
      </>
    );
  };


  const getDemographicsElement = () => {
    const data = referenceData?.hccRiskScoreData?.hccV28RiskScoreData;
    const genderCode = data?.input?.sex;
    const displayGender = genderCode && genderCodeToDisplay[genderCode];
    const displayAgeValue = data?.input?.age;
    const displayAgeScore = data?.output?.risk_score_age;
    const medicaidValue =
      componentState?.d2List?.length > 0 ? 
        Object.values(componentState.d2List[0])[0] : 
        '-';
    return (
      <View paddingY={2} paddingX={4} borderBottomWidth={1} borderColor={Colors.Custom.Gray200}>
        <Text
          color={Colors.Custom.Gray400}
          fontSize={14}
        >{`Demographics`}</Text>
        <Stack
          flexDirection={'row'}
          justifyContent={'space-between'}
          width="100%"
        >
          {
            displayGender && displayAgeValue ?
              <Text
                alignSelf={'flex-start'}
                fontSize={14}
              >
                {`${displayGender}, age ${displayAgeValue}`}
              </Text>    
            : <></>
          }     
          { displayAgeScore ? 
            <Text
              alignSelf={'flex-end'}
              color={Colors.Custom.Gray400}
              fontSize={14}
            >
              {`${displayAgeScore}`}
            </Text>
            : <></>
          }
        </Stack>
        <Stack
          flexDirection={'row'}
          justifyContent={'space-between'}
          width="100%"
          paddingBottom={1}
        >
          <Text
            alignSelf={'flex-start'}
            fontSize={14}
          >{`Medicaid eligibility`}</Text>
          <Text
            alignSelf={'flex-end'}
            color={Colors.Custom.Gray400}
            fontSize={14}
          >{`${medicaidValue}`}</Text>
        </Stack>
      </View>
    );
  };

  const getInteractionEffectElement = () => {
    const interactionEffectList = componentState?.interactionEffectList;
    return (
      <View paddingY={2} paddingX={4} borderBottomWidth={1} borderColor={Colors.Custom.Gray200}>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          width="100%"
          paddingBottom={1}
        >
          <Text
            color={Colors.Custom.Gray400}
            fontSize={14}
            alignSelf="flex-start"
          >
            Interaction Effect
          </Text>
          {interactionEffectList?.length === 0 && (
            <Text
              color={Colors.Custom.Gray400}
              fontSize={14}
              alignSelf="flex-end"
            >
              -
            </Text>
          )}
        </Stack>
        {interactionEffectList?.map((effect: any, index: number) => {
          const key = Object.keys(effect)[0];
          const displayName = key.replace(/_/g, ' + ');
          const value = effect[key];
          return (
            <Stack
              key={index}
              flexDirection={'row'}
              justifyContent={'space-between'}
              width="100%"
            >
              <Text alignSelf={'flex-start'} fontSize={14}>
                {displayName}
              </Text>
              <Text
                alignSelf={'flex-end'}
                color={Colors.Custom.Gray400}
                fontSize={14}
              >
                {value}
              </Text>
            </Stack>
          );
        })}
      </View>
    );
  };

 
  const hccScoreColor = getColorCodeForHCC(hccScore || 0);

  const getRafModelFromScoreType = (scoreType: string) => {
    switch (scoreType) {
      case SCORE_TYPE.HCC_V_24:
        return RAF_MODEL.HCC_V_24;
      case SCORE_TYPE.HCC_V_28:
        return RAF_MODEL.HCC_V_28;
      default:
        return '';
    }
  };

  const rafScoreEvaluationDetails = (
    <Stack flexDirection={'column'}>
      <Text color={Colors.FoldPixel.GRAY400} fontSize={10}>
        <Text fontWeight={500}>Last evaluated: </Text>
        <Text fontWeight={400}>{getDateStrFromFormat(props?.contactScoreAudits?.[0]?.scoreDate, DATE_FORMATS.MONTH_DATE_YEAR)}</Text>
      </Text>
      <Text color={Colors.FoldPixel.GRAY400} fontSize={10}>
        <Text fontWeight={500}>Model: </Text>
        <Text fontWeight={400}>{props?.contactScoreAudits && props?.contactScoreAudits?.length > 0 ? getRafModelFromScoreType(SCORE_TYPE.HCC_V_28) : '-'}</Text>
      </Text>
    </Stack>
  );

  return (
    <Stack flexDirection={'column'} width={isSidecarContext ? '250px' : '280px'}>
      <Stack flexDirection={'row'} paddingBottom={1} alignItems={'center'} paddingY={2} paddingX={4} borderBottomWidth={1} borderColor={Colors.Custom.Gray200}>
        <Text color={Colors.Custom.Gray400} fontSize={14}>
          {`RAF Score `}
          <Popover
            overlayInnerStyle={{padding: 6, paddingTop:0, borderRadius: 4}}
            overlayClassName='contact-popover'
            style={{borderRadius: 4}}
            placement={'topLeft'}
            trigger="hover"
            content={rafScoreEvaluationDetails}
            align={{ offset: [-20, 5] }}
          >
            <Icon as={AntIcon} name="infocirlceo" size="smMedium" color={Colors.Custom.Gray400} />
          </Popover>
          {`: `}
        </Text>
        <Text color={hccScoreColor} fontSize={14}>
          {`${hccScoreValue}`}
        </Text>
        <RafScoreDiff hccScore={hccScore} hccScoreDiff={hccScoreDiff} formattedHccScoreDiff={formattedHccScoreDiff} />
      </Stack>
      <Text color={Colors.Custom.Gray400} fontSize={12} paddingY={2} paddingX={4} borderBottomWidth={1} borderColor={Colors.Custom.Gray200}>
        {`Score Breakdown`}{' '}
      </Text>
      {getConditionsAndHCCsElement()}
      {getDemographicsElement()}
      {getInteractionEffectElement()}
      <Stack
        flexDirection={'row'}
        justifyContent={'space-between'}
        width="100%"
        paddingY={2} paddingX={4} borderBottomWidth={1} borderColor={Colors.Custom.Gray200}
      >
        <Text
          alignSelf={'flex-start'}
          fontWeight={800}
          fontSize={14}
        >{`Total RAF Score`}</Text>
        <Text
          alignSelf={'flex-end'}
          fontSize={14}
          fontWeight={800}
        >{`${hccScoreValue}`}</Text>
      </Stack>
    </Stack>
  );
};