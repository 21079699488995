import {StyleSheet} from 'react-native';
import {Colors} from '../../../styles';

export const styles = StyleSheet.create({
  mainContainer: {
    // backgroundColor: 'white',
    // borderRadius: 8,
    elevation: 10,
    borderColor: Colors.Custom.BorderColor,
    borderWidth: 0,
    overflow: 'hidden',
  },
});
