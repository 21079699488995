import React from 'react';
import Svg, { Path } from 'react-native-svg';

function CalendarCancelIcon() {
  return (
    <Svg
      width="19"
      height="20"
      fill="none"
      viewBox="0 0 19 20"
      id="calendar-cancel-icon"
    >
      <Path
        id="Path_1"
        stroke="#12B76A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M16.5 9.5v-5c0-.92-.746-1.667-1.667-1.667H3.167c-.92 0-1.667.747-1.667 1.667v11.667c0 .92.746 1.666 1.667 1.666h5m4.166-16.666V4.5M5.667 1.167V4.5M1.5 7.833h15M13.583 13.5v1.417l1.25.833m2.5-.833a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
      ></Path>
    </Svg>
  );
}

export default CalendarCancelIcon;
