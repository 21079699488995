import React from 'react';
import { Svg, Path } from 'react-native-svg';

const FilterIconSvg = () => {
  return (
    <Svg
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <Path
        stroke="#6F7A90"
        strokeLinecap="round"
        d="M11.94 1H8.56c-4.7 0-7.05 0-7.496 1.424C.62 3.848 2.492 5.416 6.237 8.553c1.113.932 1.67 1.398 1.967 2.056.298.659.298 1.42.298 2.943v3.725c0 .58 0 .87.066 1.054.171.48.637.753 1.1.646.177-.042.4-.202.845-.521.437-.314.655-.47.835-.655a3.243 3.243 0 00.872-1.763c.042-.265.042-.55.042-1.12V13.33c0-1.418 0-2.126.262-2.75.262-.623.754-1.087 1.738-2.014l.201-.19c3.284-3.095 4.926-4.643 4.459-6.01C18.454 1 16.282 1 11.939 1z"
      ></Path>
    </Svg>
  );
};

export default FilterIconSvg;
