import React from 'react';
import {
  Circle,
  Defs,
  LinearGradient,
  Path,
  RadialGradient,
  Svg,
} from 'react-native-svg';

function SearchContactEmptyState() {
  return (
    <Svg width="142" height="142" viewBox="0 0 142 142" fill="none">
      <Circle
        cx="71.0003"
        cy="70.9993"
        r="70"
        stroke="url(#paint0_radial_437_16743)"
        stroke-width="0.7"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dasharray="2.04 2.04"
      />
      <Circle
        cx="71.0005"
        cy="70.9999"
        r="59.7333"
        stroke="url(#paint1_radial_437_16743)"
        stroke-width="0.7"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dasharray="2.04 2.04"
      />
      <Path
        d="M21.7963 71.1418C21.7963 44.0799 43.7343 22.1418 70.7963 22.1418C97.8582 22.1418 119.796 44.0799 119.796 71.1418C119.796 98.2038 97.8582 120.142 70.7963 120.142C43.7343 120.142 21.7963 98.2038 21.7963 71.1418Z"
        fill="url(#paint2_linear_437_16743)"
      />
      <Path
        d="M84.0814 84.0803L91.1252 91.124M89.1127 69.9928C89.1127 80.5518 80.5529 89.1115 69.9939 89.1115C59.4349 89.1115 50.8752 80.5518 50.8752 69.9928C50.8752 59.4338 59.4349 50.874 69.9939 50.874C80.5529 50.874 89.1127 59.4338 89.1127 69.9928Z"
        stroke="#A7AFBE"
        stroke-width="2.23611"
        stroke-linecap="round"
      />
      <Defs>
        <RadialGradient
          id="paint0_radial_437_16743"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(71.0004 102.651) rotate(-90.639) scale(109.854)"
        >
          <stop stop-color="#A1AABA" />
          <stop offset="1" stop-color="#E4E7EC" />
        </RadialGradient>
        <RadialGradient
          id="paint1_radial_437_16743"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(71.0006 98.0091) rotate(-90.639) scale(93.7422)"
        >
          <stop stop-color="#A1AABA" />
          <stop offset="1" stop-color="#E4E7EC" />
        </RadialGradient>
        <LinearGradient
          id="paint2_linear_437_16743"
          x1="36.0003"
          y1="33.1997"
          x2="103.2"
          y2="118.25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F9FAFC" />
          <stop offset="1" stop-color="#F1F2F3" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
}

export default SearchContactEmptyState;
