import {Moment} from "moment";
import {ToastType} from "../../../../utils/commonViewUtils";

export interface ICustomFieldViewState {
  openAddCustomFiledDrawer: boolean,
  loading: boolean,
  customFieldData: ICustomFieldData[],
  updateStatusLoading: boolean,
  selectedCustomField: ICustomFieldData | undefined,
  addUpdateCustomFieldLoading: boolean,
  offSet: number,
  pageNo: number,
  limit: number,
  total: number,
}
export interface ICustomFieldView {
  searchString?: string;
  openModal?: boolean;
  showTableTopBar?: boolean;
  onViewChangeCallback?: () => void;
}
export interface IGetContactTypesParams {
  pageNo?: number,
  pageSize?: number,
  searchString: string,
  entityType: string,
  subType?: string,
}

export interface IGetCustomFieldsForEntityParams {
  entityId: string,
}

export interface ICreateCustomFieldParams {
  id: string,
  body: {
    label: string,
    entityType: string,
    description: string,
    datatype: string,
    contactTypes: string[],
  }
}

export interface IEnableDisableCustomField {
  id: string,
  body: {
    isDisabled: boolean;
  }
}
export interface ICustomFieldResponse {
  id: string
  accountUuid: string,
  label: string,
  key: string,
  description: string | null,
  entityType: string,
  datatype: string,
  isDeleted: boolean,
  isDisabled: boolean,
  createdAt: string,
  updatedAt: string,
  customContactTypes: IContactTypes[],
  isDefault: boolean,
}

export enum CUSTOM_FIELD_DATATYPE {
  TEXT = 'TEXT',
  DATE = 'DATE',
  NUMBER = 'NUMBER',
  EMAIL = 'EMAIL',
  NPI = 'NPI',
}

export interface IGetCustomFieldResponse {
  count: number,
  customAttributes: ICustomFieldResponse[]
}

export interface ICustomAttribute {
  datatype: string,
  id: string,
  label: string,
}

export interface IGetCustomFieldByEntityResponse {
  customAttribute: ICustomAttribute,
  customAttributeId: string,
  dataSource: string,
  entityId?: string,
  entityType: string,
  formattedValue?: any,
  id: string,
  tempId?: string,
  isDeleted: boolean
  value: string
}

export interface ICreateUpdateCustomFieldsForEntityResponse {
  id: string,
  accountUuid: string,
  entityId: string,
  entityType: string,
  customAttributeId: string,
  value: string,
  dataSource: string,
  isDeleted: false,
}
export interface IFormattedCustomFieldsData {
  customAttribute: ICustomAttribute,
  customAttributeId: string,
  entityId?: string,
  entityType: string,
  formattedValue?: any,
  id: string,
  tempId?: string,
  isDeleted: boolean,
  value: string,
  dataSource: string,
}

export type CustomAttributesValue = IFormattedCustomFieldsData[][];
export interface ICustomFieldData {
  id: string,
  title: string,
  description: string | null,
  visibleIn: string[],
  type: string,
  active? : boolean,
  value?: boolean,
  fieldValue?: string
  contactTypes: IContactTypes[],
  isDefault: boolean,
}

export interface IContactTypes {
  id: string
  value: string,
  isDefault?: boolean,
  isActive?: boolean,
  code?: string,
}

export interface ICustomToast {
  toastType: ToastType,
  message: string,
  duration: number,
  closeAllPrevToast: boolean,
}

export type ICustomFieldFormKeys =
  | 'title'
  | 'description'

export type ICustomFieldRef = {
  [index in ICustomFieldFormKeys]: HTMLInputElement;
};

export type ICustomFieldViewRef = {
  [index: string]: HTMLInputElement;
};
export interface ICustomFieldFormBodyData {
  id: string,
  name: string,
  description: string,
  type: string,
}

export interface ICustomFieldDataErrors {
  type: string,
  name: string,
  description: string,
  applicableTo: string,
}

export interface IAddCustomField {
  isDrawerOpen: boolean,
  onDrawerClose: () => void,
  selectedCustomField?: ICustomFieldData | undefined,
  onActionPerformed: (actionCode: string, actionData: any) => void,
  title?: string,
  contactTypeId?: string,
}

export interface IAddCustomFieldState {
  isDrawerOpen: boolean,
  loading: boolean,
  errors: ICustomFieldDataErrors,
  selectedType: string,
  selectedCustomField: ICustomFieldData | undefined,
  addUpdateCustomFieldLoading: boolean,
  customContactTypes: IContactTypes[],
  selectedCustomContactTypes: IContactTypes[],
  customContactTypesLoading: boolean,
  hoveredIndex: number | null,
  visible: boolean,
  show: boolean,
}

export interface ICustomFieldTable {
  onPagination: (page: number, pageSize: number) => void,
  pageNo: number,
  pageSize: number,
  total: number,
  data: ICustomFieldData[],
  onActionPerformed: (actionCode: string, rowRecord: ICustomFieldData) => void,
  loading: boolean,
}

export interface IAddCustomFieldContainerState {
  showSelectCustomField: boolean,
  customAttributesValue: IFormattedCustomFieldsData[],
  loading: ICustomFieldViewLoading
}

export interface IAddCustomFieldContainer {
  entityType: string,
  entityId: string,
  contactTypeId: string,
  showAddMore?: boolean,
  showTitle?: boolean,
  dataSource: string,
  onDetailChange: (customAttributesValue: IFormattedCustomFieldsData[]) => void;
  customFieldsError: ICustomFieldsError;
}

export interface ICustomFieldAddUpdateFormattedData {
  entityId: string,
  dataSource: string,
  values: customAttributeIdValue[]
}

export interface customAttributeIdValue {
  customAttributeId: string,
  value: string,
}

export interface ICustomFieldViewLoading {
  [key: string]: boolean;
}

export interface ICustomFieldsError {
  [key: string]: string;
}

export interface ICustomFieldValueView {
  customFieldsValue: IFormattedCustomFieldsData[],
  onActionPerformed: (actionCode: string, actionData: any) => void,
  customFieldsError: ICustomFieldsError,
  loading: ICustomFieldViewLoading,
}

export interface IGetFieldByDataType {
  type: string,
  value: string,
  label: string,
  id: string,
  errors: ICustomFieldsError,
  customAttributeId: string,
}

export interface ISelectCustomField {
  onActionPerformed: (actionCode: string, actionData: any) => void,
  entityType: string,
  contactTypeId?: string,
  customAttributesValue?: IFormattedCustomFieldsData[],
  selectedValue?: string
  importError?: string
  selectedCustomAttributesId?: string[];
  customAttributeSelectType: string;
  placeholder: string;
}

export interface ISelectCustomFieldState {
  searchString: string,
  loading: boolean,
  customAttributeData: ICustomFieldData[],
  selectedCustomFields: ICustomFieldData[],
  customFieldText: string,
  openAddCustomFiledDrawer: boolean,
  visible: boolean,
  hoveredIndex: number | null,
}

export interface IBulkCustomAttribute {
  id: string,
  customAttributeId?: string,
  mappedValue?: string
}

export interface ICustomAttributesFilters {
  id: string,
  customAttributeId: string,
  dataType: string
  filterType: string,
  customAttributeName: string,
  value: string,
  range: ICustomAttributesFilterRange,
  fieldName: string,
  condition: string
}

export interface ICustomAttributesFilterRange {
  from: string,
  to: string
}

export interface ICustomAttributeQueryObj {
  customAttributeId: string,
  customAttributeValue: string,
  fieldName: string,
  condition: string,
  range: ICustomAttributesFilterRange
}

export interface ICustomFieldFilter {
  customAttributes: ICustomAttributesFilters[],
  onReset: boolean,
  onDetailChange: (customAttributes: ICustomAttributesFilters[]) => void,
}

export interface ICustomAttributeDataType {
  key: number,
  value: string,
  label: string,
  condition: string,
}

export type CustomAttributeValues<T extends Record<string, any>> = {
  customAttributeId: {
    _eq: string;
  };
} & {
  [key: string]: T;
};

export interface IDatePickerInput {
  value: Moment | null
  onChange: (date: Moment | null, dateString: string) => void,
}
export interface INumberInput {
  value: string | undefined
  onChangeText: (text: string) => void,
  refId: string,
  type?: string
}

export interface IOnActionPerformedData {
  key: string,
  name: string,
  type: string,
  filterType: string,
  condition: string,
  range: ICustomAttributesFilterRange,
  value: string,
}

export interface ICustomFieldNoDataView {
  title: string,
  message?: string,
  buttonMessage: string,
  onCreateFirstCustomField: () => void,
}

export interface IAttributes {
  id: string;
  customAttribute: {
    id: string,
    label: string,
  },
  label: string
  value: string,
  booleanValue: boolean,
  dateValue: string,
}

export interface ICustomAttributesSuggestedValue {
  code: string,
  value: string,
  label: string,
}

export interface ICustomFieldFilterState {
  customAttributes: ICustomAttributesFilters[],
  loading: boolean,
  visible: boolean,
  searchString: string,
  customAttributesSuggestedValues: ICustomAttributesSuggestedValue[],
  selectedCustomAttributeId: string,
  customAttributeSuggestedValueLoading: boolean,
}

export interface ICustomFieldsValueView {
  searchString: string,
  customAttributesSuggestedValues: ICustomAttributesSuggestedValue[],
  selectedCustomAttributeId: string,
  customAttributeSuggestedValueLoading: boolean,
}