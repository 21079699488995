import { gql } from "@apollo/client";

const ADD_OR_UPDATE_INTERVENTION =gql`
mutation AddOrUpdateIntervention($params: InterventionInput!) {
  addOrUpdateIntervention(params: $params) {
    type
    trigger
    title
    metadata
    id
    isDeleted
  }
}`;

export default {
    ADD_OR_UPDATE_INTERVENTION
}