import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  iconBtnStyle: {
    padding: 20,
    marginTop: 1,
    backgroundColor: 'yellow',
  },
  iconBtnView: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
});
