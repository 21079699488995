import { ENTITY_EVENTS_TYPE } from '../../../../../constants';
import { COMMON_ACTION_CODES } from '../../../../../constants/ActionConst';
import { isEmptyObject } from '../../../../../utils/commonUtils';
import { ToastType } from '../../../../../utils/commonViewUtils';
import { IParticipantSearch } from '../../../../common/CalendarWidget/ParticipantAutoComplete/ParticipantInterfaces';
import { handleRunExistingAutomationOnMember } from '../../../../common/UsageLevel/UsageLevelHandlers';
import {
  ExecuteWorkflowControllerArgs,
  ExecuteWorkflowState,
  ExecutionViewCodes,
  WorkflowData
} from './interfaces';

export const executeWorkflowController = (
  args: ExecuteWorkflowControllerArgs
) => {
  const {
    workflowData,
    showToast,
    state,
    tenantId,
    userUuid,
    toast,
    workflowService,
    showWorkflowList,
    level,
    setState,
    onCompleteAction,
  } = args;
  return {
    handleSubmit() {
      setState((prev) => {
        return {
          ...prev,
          submitting: true,
        };
      });
      const workflowMasterList = getWorkFlowMasterList(state, workflowData);
      const data = getPostDataObject(state);
      if (!data.segmentIdList?.length && !data?.contactIdList?.length && !data?.employerIdList?.length) {
        setState((prev) => {
          return {
            ...prev,
            submitting: false,
          };
        });
        return;
      }
      workflowService
        .post(`/workflow/event/trigger`, {
          data: {
            ...data,
            isSkipEmptyListExecution:true
          },
          eventType: 'executeStaticWorkflow',
          entity: 'workflow',
          workflowMasterList: workflowMasterList,
          'account-uuid': tenantId,
        })
        .then((response) => {
          handleRunExistingAutomationOnMember({
            accountUuid: tenantId,
            userUuid,
            level,
          });
          successHandler({
            workflowData,
            toast,
            response,
            showToast,
            setState,
            onCompleteAction,
          });
        })
        .catch((e) => {
          errorHandler(e);
        });
    },

    handleOnClose() {
      onCompleteAction(COMMON_ACTION_CODES.CANCEL, workflowData);
    },

    setViewCode(value: string) {
      setState((prev: any) => {
        return {
          ...prev,
          selectedGroups: [],
          selectedMembers: [],
          viewCode: value,
          selectedOption: value,
        };
      });
    },

    setMembers(members: IParticipantSearch[]) {
      setState((prev) => {
        return {
          ...prev,
          selectedMembers: members,
        };
      });
    },

    setGroups(groupIds: string[]) {
      setState((prev) => {
        return {
          ...prev,
          selectedGroups: groupIds,
        };
      });
    },
    setSelectedEmployerIds(selectedEmployerIds: string[]) {
      setState((prev) => {
        return {
          ...prev,
          selectedEmployerIds: selectedEmployerIds,
        };
      });
    },

    setPersonLevelWorkflow() {
      const entityEventType = ENTITY_EVENTS_TYPE.WORKFLOW_STATIC.find(
        (item) =>
          item.entity == workflowData?.entity &&
          item.eventType == workflowData?.eventType
      );
      setState((prev) => {
        return {
          ...prev,
          isPersonLevelWorkflow:
            entityEventType?.isPersonLevelWorkflow || false,
        };
      });
    },

    isSubmitButtonDisabled() {
      const check = showWorkflowList && isEmptyObject(state.workflow);
      return check;
    },
  };
};

export const getWorkFlowMasterList = (
  state: ExecuteWorkflowState,
  workflowData?: WorkflowData
) => {
  return [
    {
      workflowMasterId: workflowData?.workflowMasterId,
      isPersonLevelWorkflow: state.isPersonLevelWorkflow,
    },
  ];
};

export const getPostDataObject = (state: ExecuteWorkflowState) => {
  const data:any = {};
    state.viewCode == ExecutionViewCodes.GroupSearch &&
    state.selectedGroups.length > 0
      ? {segmentIdList: state.selectedGroups}
      : {
          contactIdList: state.selectedMembers.map((item) => item.contactId),
        };
  if (state.viewCode == ExecutionViewCodes.GroupSearch || state.viewCode == ExecutionViewCodes.LeadGroupSearch) {
    data.segmentIdList = state.selectedGroups;
  } else if (state.viewCode == ExecutionViewCodes.EmployerSearch) {
    data.employerIdList = state.selectedEmployerIds;
  } else if (state.viewCode == ExecutionViewCodes.MemberSearch) {
    data.contactIdList = state.selectedMembers.map((item) => item.contactId)
  }

  return data;
};

export const successHandler = (args: {
  response: any;
  showToast: any;
  workflowData: any;
  toast: any;
  setState: React.Dispatch<React.SetStateAction<ExecuteWorkflowState>>;
  onCompleteAction: (actionCode: string, record: WorkflowData, response:any) => void;
}) => {
  const {response, onCompleteAction, setState, showToast, workflowData, toast} =
    args;
  if (response.data) {
    showToast(toast, 'Automation Execution Initiated', ToastType.success);
    onCompleteAction(COMMON_ACTION_CODES.SAVE, workflowData, response?.data);
    setState((prev) => {
      return {
        ...prev,
        submitting: false,
      };
    });
  }
};

export const errorHandler = (args: {
  error: any;
  toast: any;
  showToast: any;
  setState: React.Dispatch<React.SetStateAction<ExecuteWorkflowState>>;
}) => {
  const {error, toast, setState, showToast} = args;

  showToast(
    toast,
    'Something went wrong. Please Try again later',
    ToastType.error
  );
  setState((prev) => {
    return {
      ...prev,
      submitting: false,
    };
  });
};
