import { VitalUnit } from "./VitalUtils";

interface IVitalValue {
  value: string;
  unit?: string;
}

export const feetAndInchesToCm = (feet: number, inches: number): number => {
  const feetInInches = feet * 12;
  const totalInches = feetInInches + inches;
  const heightInCm = totalInches * 2.54;
  // 2 decimal places
  return Math.floor(heightInCm * 100) / 100;
};

export const cmToFeetAndInches = (
  heightInCm: number
): {feet: number; inches: number} => {
  const heightInInches = heightInCm / 2.54;
  const feet = Math.floor(heightInInches / 12);
  const inches = heightInInches % 12;
  return {feet, inches};
};

export const feetAndInchesToInches = (feet: number, inches: number): number => {
  const feetInInches = feet * 12;
  const totalInches = feetInInches + inches;
  return Math.floor(totalInches * 100) / 100;
};

export const inchesToFeetAndInches = (
  heightInInches: number
): {feet: number; inches: number} => {
  const feet = Math.floor(heightInInches / 12);
  const inches = heightInInches % 12;
  return {feet, inches};
};

// Height unit: Only supports inches and cm
// Weight unit: Only supports lbs and gms and kg
export const getBMIValue = (
  height: IVitalValue,
  weight: IVitalValue
): number => {
  let heightValueInInches = parseFloat(height.value);
  if (!isInchesUnit(height.unit)) {
    heightValueInInches /= 2.54;
  }
  const weightValueInPounds = convertVitalValues(weight.value, weight.unit || '', VitalUnit.lbs, 4);
  return (
    (parseFloat(`${weightValueInPounds}`) * 703) /
    (heightValueInInches * heightValueInInches)
  );
};

export const isInchesUnit = (unit?: string) => {
  return unit == VitalUnit.inches || unit == VitalUnit.in;
};

export const isGramUnit = (unit?: string) => {
  return unit == VitalUnit.g || unit == VitalUnit.gm || unit == VitalUnit.grams;
};

export const isKGUnit = (unit?: string) => {
  return unit == VitalUnit.kg;
};

export const convertVitalValues = (value: string | number | undefined, fromUnit: string, toUnit: string, decimalPrecision: number) => {
  if (!value) return value;
  let valueNumber: number = parseFloat(`${value}`);
  switch (fromUnit) {
    // Weight
    case VitalUnit.lbs:
    case VitalUnit.pounds:
      if ([VitalUnit.gm, VitalUnit.grams, VitalUnit.g].includes(toUnit as VitalUnit)) {
        valueNumber *= 453.592;
      }
      if (toUnit === VitalUnit.kg) {
        valueNumber *= 0.453592;
      }
      if ([VitalUnit.oz, VitalUnit.ounce].includes(toUnit as VitalUnit)) {
        valueNumber *= 16;
      }
      break;

    case VitalUnit.gm:
    case VitalUnit.grams:
    case VitalUnit.g:
      if ([VitalUnit.pounds, VitalUnit.lbs].includes(toUnit as VitalUnit)) {
        valueNumber *= 0.00220462;
      }
      if (toUnit === VitalUnit.kg) {
        valueNumber /= 1000;
      }
      if ([VitalUnit.oz, VitalUnit.ounce].includes(toUnit as VitalUnit)) {
        valueNumber *= 0.035274;
      }
      break;

    case VitalUnit.kg:
      if ([VitalUnit.gm, VitalUnit.grams, VitalUnit.g].includes(toUnit as VitalUnit)) {
        valueNumber *= 1000;
      }
      if ([VitalUnit.pounds, VitalUnit.lbs].includes(toUnit as VitalUnit)) {
        valueNumber *= 2.20462;
      }
      if ([VitalUnit.oz, VitalUnit.ounce].includes(toUnit as VitalUnit)) {
        valueNumber *= 35.274;
      }
      break;

    case VitalUnit.ounce:
    case VitalUnit.oz:
      if ([VitalUnit.gm, VitalUnit.grams, VitalUnit.g].includes(toUnit as VitalUnit)) {
        valueNumber *= 28.3495;
      }
      if ([VitalUnit.pounds, VitalUnit.lbs].includes(toUnit as VitalUnit)) {
        valueNumber *= 0.0625;
      }
      if (toUnit === VitalUnit.kg) {
        valueNumber *= 0.0283495;
      }
      break;

  // Temperature
  case VitalUnit.fahrenheit:
  case VitalUnit.F:
    if (toUnit === VitalUnit.C) {
      valueNumber = (valueNumber - 32) * 5 / 9;
    }
    break;
  case VitalUnit.C:
    if ([VitalUnit.fahrenheit, VitalUnit.F].includes(toUnit as VitalUnit)) {
      valueNumber = (valueNumber * 9 / 5) + 32;
    }
    break;

  // Length
  case VitalUnit.in:
  case VitalUnit.inches:
    if ([VitalUnit.cm, VitalUnit.cms].includes(toUnit as VitalUnit)) {
      valueNumber *= 2.54;
    }
    break;

  case VitalUnit.cm:
  case VitalUnit.cms:
    if ([VitalUnit.in, VitalUnit.inches].includes(toUnit as VitalUnit)) {
      valueNumber /= 2.54;
    }
    break;

    default: break;
  }
  valueNumber = Number.parseFloat(valueNumber.toFixed(decimalPrecision));
  return valueNumber;
}

export const convertCmToFeetInchesObject = (cm: number) => {
 const inches = cm / 2.54;
 return {
  ft: Number.parseFloat(Math.floor(inches / 12).toFixed(0)),
  inches: Number.parseFloat((inches % 12).toFixed(0))
 }
} 