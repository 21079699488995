import {useMutation, useLazyQuery} from '@apollo/client';
import {Popover} from 'antd';
import {Icon, Pressable, Text, Tooltip, View} from 'native-base';
import {useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {ILoginUserData} from '../../../Interfaces';
import UserQueries from '../../../services/User/UserQueries';
import {Colors} from '../../../styles';
import HomeSvg from '../Svg/SideMenu/HomeSvg';
import {onChangeView} from './CustomSwitchUtils';
import {styles} from './CustomSwtichStyles';

const CustomSwtich = (props: {isSettingIcon?: boolean}) => {
  const {isSettingIcon} = props;
  const [stateData, setStateData] = useState({
    selectedBtn: 'CLINICIAN',
    shouldShowHint: false,
  });
  const intl = useIntl();

  const btnTextArray = [
    {
      text: 'Dashboard',
      Code: 'CLINICIAN',
    },
    {
      text: 'Admin',
      Code: 'ADMIN',
    },
  ];
  const commonData = useContext(CommonDataContext);

  const userData = commonData.userData || ({} as ILoginUserData);

  // GET SINGLE USER
  const [getUserUiSetting] = useLazyQuery(UserQueries.GET_USER_CUSTOM_ATTR, {
    fetchPolicy: 'no-cache',
  });

  const [setUserUiSetting] = useMutation(UserQueries.SET_USER_CUSTOM_ATTR);

  // const onchange = (code: string) => {
  //   if (code === 'ADMIN') {
  //     // window.location.replace(`${window.location.origin} + '/#/admin/forms`);
  //     window.location.reload();
  //   } else {
  //     window.location.replace(`${window.location.origin} + '/#/home`);
  //     window.location.reload();
  //   }
  // };
  useEffect(() => {
    setStateData((prev) => {
      return {
        ...prev,
        selectedBtn: window.location.href.includes('/admin')
          ? 'ADMIN'
          : 'CLINICIAN',
      };
    });

    getUserUiSetting({
      variables: {
        userId: userData?.id,
      },
    }).then((resp) => {
      if (resp?.data?.user && window) {
        if (
          (window.location.href.includes('/admin') &&
            !resp?.data?.user?.uiSettings?.settingDisplayConfig?.isStandardViewHintShow) ||
          (!window.location.href.includes('/admin') &&
            !resp?.data?.user?.uiSettings?.settingDisplayConfig?.isAdminViewHintShown)
        ) {
          setStateData({
            ...stateData,
            shouldShowHint: true,
          });
          const variables = window.location.href.includes('/admin')
            ? {
                userId: userData?.id,
                settingDisplayConfig: {
                  isStandardViewHintShow: true,
                },
              }
            : {
                userId: userData?.id,
                settingDisplayConfig: {
                  isAdminViewHintShown: true,
                },
              };

          setTimeout(() => {
            setUserUiSetting({
              variables,
            }).then((resp3) => {
              setStateData({
                ...stateData,
                shouldShowHint: false,
              });
            });
          }, 3000);
        }
      }
    });
  }, []);
  return (
    <>
      {isSettingIcon ? (
        <>
        </>
      ) : (
        <View
          style={{
            height: 40,
            borderRadius: 52,
            borderWidth: 1,
            borderColor: Colors.Custom.Gray200,
            flexDirection: 'row',
            alignItems: 'center',
            marginHorizontal: 12,
            paddingHorizontal: 4,
            paddingVertical: 8,
            backgroundColor: '#fff',
          }}
        >
          {btnTextArray.map((btn) => {
            return (
              <Pressable
                key={btn.Code}
                onPress={() => {
                  setStateData((prev) => {
                    return {
                      ...prev,
                      selectedBtn: btn.Code,
                    };
                  });
                  onChangeView(btn.Code);
                }}
              >
                <View
                  style={[
                    styles.swtichBtnStyle,
                    stateData.selectedBtn === btn.Code
                      ? {backgroundColor: Colors.primary['300']}
                      : {},
                  ]}
                >
                  <Text
                    size={'xsBold'}
                    color={
                      stateData.selectedBtn === btn.Code
                        ? '#fff'
                        : Colors.Custom.Gray900
                    }
                  >
                    {btn.text}
                  </Text>
                </View>
              </Pressable>
            );
          })}
        </View>
      )}
    </>
  );
};
export default CustomSwtich;
