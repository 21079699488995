import {View, Text} from 'react-native';
import React from 'react';
import {ISearchPackagesProps, IProduct} from './interfaces';
import {GetPackages} from '../../../services/Stripe/StripeServices';
import {PACKAGE_TYPES} from '../../RightSideContainer/Sales/ProductsAndServices/Products/ProductDetailView/ProductDetailViewSidebar/RightSideContainer/ProductDetailViewForm/Forms/FormConst';
import {Select, Spin} from 'antd';
import {debounce} from 'lodash';

const SearchPackages = (props: ISearchPackagesProps) => {
  const [state, setState] = React.useState({
    packages: [] as IProduct[],
    selectedPackageIds: [] as string[],
    loading: false,
  });

  const onPackageSearch = async (searchString: string) => {
    if (searchString.length > 0) {
      setState((prev) => {
        return {
          ...prev,
          loading: true,
        };
      });
      try {
        const packagesResponse = await GetPackages({
          searchString: searchString,
          limit: 10,
          offset: 0,
          status: PACKAGE_TYPES.PUBLISHED,
          excludeExpired: true,
        });
        setState((prev) => {
          return {
            ...prev,
            packages: packagesResponse?.data?.products,
            loading: false,
          };
        });
      } catch (error) {}
    }
  };

  const handleChange = (value: string[]) => {
    setState((prev) => {
      return {
        ...prev,
        selectedPackageIds: value,
      };
    });
    const selected = state.packages.filter((item) => value.includes(item.id));
    props.onSelectPackages(selected);
  };

  return (
    <Select
      size="large"
      mode="multiple"
      showSearch
      allowClear
      filterOption={false}
      value={state.selectedPackageIds || undefined}
      onSearch={debounce(onPackageSearch, 500)}
      onChange={(value: any) => {
        handleChange(value);
      }}
      placeholder="Search Packages"
      loading={state.loading}
      notFoundContent={state.loading && <Spin size="large" />}
      style={{width: '100%'}}
    >
      {state.packages?.map((item, index: number) => {
        return (
          <Select.Option key={item.id} value={item.id}>
            {item.name}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default SearchPackages;
