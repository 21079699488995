import {Drawer} from 'antd';
import {HStack, Text} from 'native-base';
import {useState} from 'react';
import {useIntl} from 'react-intl';
import { BUTTON_TYPE } from '../../../constants';
import { Colors } from '../../../styles';
import { FoldButton } from '../../CommonComponents/FoldButton/FoldButton';
import { ModalActionTitle } from '../ModalActionTitle/ModalActionTitle';

export const DeleteConfirmationDrawer = (props: {
  titleId?: string;
  message?: string;

  isOpen: boolean;

  onCancel: () => void;
  onConfirm: () => void;
  cancelButtonText?: string;
  confirmButtonText?: string;
  isLoading?: boolean;
}) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const onUserConfirm = () => {
    setLoading(true);
    props.onConfirm();
    setLoading(false);
  };
  const buttonList = [
    {
      show: true,
      id: 1,
      btnText: intl.formatMessage({
        id: props?.cancelButtonText || 'cancel',
      }),
      isLoading: false,

      textColor: Colors.Custom.mainSecondaryBrown,
      variant: BUTTON_TYPE.SECONDARY,
      isTransBtn: false,
      onClick: () => {
        props.onCancel();
      },
    },
    {
      show: true,
      isLoading: props?.isLoading || loading,
      id: 2,
      btnText: intl.formatMessage({id: props?.confirmButtonText || 'delete'}),
      textColor: Colors.Custom.mainPrimaryPurple,
      variant: BUTTON_TYPE.PRIMARY,
      isTransBtn: false,

      onClick: () => {
        onUserConfirm();
      },
    },
  ];
  return (
    <Drawer
      title={
        <ModalActionTitle
          title={props.titleId || 'deleteConfirmationDrawerTitle'}
        />
      }
      open={props.isOpen}
      closable
      width={500}
      onClose={()=> props?.onCancel()}
    >
      <HStack>
        <Text style={{fontSize: 16}}>
          {!!props?.message ? intl.formatMessage({id: props?.message}) : 'Are you sure you want to delete this record?'}
        </Text>
      </HStack>
      <HStack marginTop={4} justifyContent="flex-end">
        {buttonList.map((button) => (
          <FoldButton
            key={button.id}
            nativeProps={{
              variant: button.variant,
              onPress: () => {
                button.onClick();
              },
              isLoading: button?.isLoading,
              style: {marginHorizontal: 5},
            }}
            customProps={{
              btnText: intl.formatMessage({id: button.btnText}),
              withRightBorder: false,
            }}
          ></FoldButton>
        ))}
      </HStack>
    </Drawer>
  );
};
