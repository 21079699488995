import { StyleSheet } from "react-native";
import { Colors } from "../../../../../../styles/Colors";

export const styles = StyleSheet.create({
  formElement: {
    paddingTop: 10,
    paddingBottom: 12,
  },
  formLabel: {
    marginLeft: 4,
  },
  phoneIconContainer: {
    padding: 5,
  },
  inboxInfoContainer: {
    marginLeft: 12,
  },
  inboxName: {
    marginTop: 0,
  },
  consentErrorText: {
    fontSize: 12,
    color: Colors.Custom.MissedCallIconColor,
  },
  textArea: {
    color: Colors.Custom.TextColor,
    fontSize: 18,
  },
  noInboxContainer: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    zIndex: 10,
    backgroundColor: 'rgba(247, 250, 252, 0.7)',
  },
  noInboxVStack: {
    marginTop: 50,
  },
  marginLeft16: {
    marginLeft: 16,
  },
  positionAbsoluteBottom85Left0Right0: {
    position: 'absolute',
    bottom: 85,
    left: 0,
    right: 0,
  },
  view: {
    position: 'absolute',
    bottom: 100,
    left: 0,
    right: 0,
  },
  marginTop50: {
    marginTop: 50,
  },
});
