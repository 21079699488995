import {StyleSheet} from 'react-native';
import {Colors} from '../../../styles';

export const styles = StyleSheet.create({
  emailRecipientContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 2,
    justifyContent: 'space-between',
  },
  emailRecipientTitleText: {
    color: '#A7AFBE',
    fontSize: 16,
    fontWeight: '400',
    lineHeight: 19,
  },
  recipientRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft: 10,
  },
  recipientRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 6,
    borderRadius: 6,
    backgroundColor: '#F6F7F8',
    justifyContent: 'space-evenly',
    margin: 4,
  },
  emailText: {
    color: '#6F7A90',
    fontSize: 16,
    fontWeight: '400',
    lineHeight: 19,
    width: 200,
  },
  divider: {
    width: '100%',
    backgroundColor: '#A7AFBE',
    height: 0.5,
    marginVertical: 8,
  },
  label: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 17,
    color: Colors.FoldPixel.GRAY200,
  },
  formControl: {
    marginTop: 16,
  },
  dividerStyle: { 
    width: 1.5,
    marginHorizontal: 8,
    backgroundColor: Colors.Custom.Gray400,
  },
  rightButtonContainer: { 
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  spinnerContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  editorContainer: {
    borderWidth: 0.5,
    borderColor: Colors.FoldPixel.GRAY200,
    borderRadius: 4,
  },
  editorHeader: {
    paddingVertical: 4,
    paddingHorizontal: 8,
    alignItems: 'center',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    backgroundColor: Colors.Custom.PendingTaskBackgroundColor,
  },
  editorHeaderText: {
    color: Colors.Custom.Gray500,
    paddingLeft: 6,
    fontSize: 12,
  },
  editorContent: {
    display: 'flex',
    flexDirection: 'column',
    borderTopWidth: 0.5,
    borderTopColor: Colors.FoldPixel.GRAY200,
    overflow: 'hidden',
  },
  editorFooter: { 
    padding: 10,
  },
  editorFooterContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  editorFooterLeft: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  editorFooterRight: { 
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  attachmentButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.Custom.mainPrimaryPurple,
    padding: 4,
    borderRadius: 6,
  },
  attachmentButtonText: {
    color: 'white',
    marginHorizontal: 4,
    fontWeight: '500',
    fontSize: 12,
    lineHeight: 14.4,
  },
  previewContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
    flexDirection: 'column',
  },
  previewHeader: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    width: '100%',
    marginBottom: 10,
  },
  previewHeaderText: {
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 19,
    color: Colors.FoldPixel.GRAY300,
  },

  safeArea: {
    flex: 1,
  },
  hStack: {
    width: '100%',
    paddingHorizontal: 12,
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '96%',
    alignItems: 'center',
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  pressableButton: {
    backgroundColor: 'white',
    padding: 12,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 100,
    marginRight: 8,
  },
  sendingIndicator: {
    backgroundColor: 'white',
    padding: 8,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 100,
  },
  keyboardAwareScrollView: {
    flexDirection: 'column',
    paddingHorizontal: 20,
    paddingVertical: 12,
    backgroundColor: 'white',
    flexGrow: 1,
    flex: 1,
  },
  fromContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  marginLeft: {
    marginLeft: 10,
    flex: 1,
    height: 30,
  },
  errorText: {
    fontSize: 13,
    color: '#F04438',
    fontWeight: '500',
    marginTop: 10,
  },
  addButtonText: {
    color: Colors.Custom.mainPrimaryPurple,
  },
  recipientContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 0.9,
  },
  addButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  recipientRow2: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  marginLeft4: {
    marginLeft: 4,
  },
  subjectContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  attachmentContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.Custom.mainPrimaryPurple,
    padding: 4,
    borderRadius: 6,
    margin: 5,
    alignSelf: 'flex-start',
  },
  attachmentText: {
    color: 'white',
    marginHorizontal: 4,
    fontWeight: '500',
    fontSize: 12,
    lineHeight: 14.4,
  },
  modalContainer: {
    backgroundColor: 'white',
    width: '90%',
    height: '70%',
    alignSelf: 'center',
    padding: 16,
    borderColor: Colors.Custom.BorderColor,
    borderWidth: 1,
    borderRadius: 10,
  },
  modalContent: {
    backgroundColor: 'white',
  },
  modalBody: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderColor: 'white',
    paddingHorizontal: 14,
    paddingTop: 20,
    paddingBottom: 16,
  },
  colorBlack: {
    color: 'black',
  },
  marginRight5: {
    marginRight: 5,
  },
  modalBodyView: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
  modalBodyView2: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalBodyView3: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: 16,
  },
  text: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 17,
    color: Colors.FoldPixel.GRAY300,
  },
  text2: {
    fontSize: 12,
    lineHeight: 14,
    fontWeight: '400',
    marginLeft: 8,
    color: Colors.FoldPixel.GRAY300,
  },
  input: {
    borderRadius: 8,
  },
  modalBodyView4: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginTop: 16,
  },
  modalBodyView5: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },
  pressable: {
    marginLeft: -4,
    marginBottom: 1,
  },
  featherIcon: {
    fontSize: 18,
    color: Colors.FoldPixel.GRAY300,
  },
  box: {
    height: 18,
    width: 18,
  },
  upload: {
    // background: '',
  },
  marginTop16: {
    marginTop: 16,
  },
  modalBodyView6: {
    flex: 0.5,
    marginLeft: 5,
  },
  view: {
    flex: 0.5,
  }
});

export const reactStyles: Record<string, React.CSSProperties> = {
  select: {
    width: '100%',
  },
  content: {
    overflow: 'hidden',
  }
}