import axios from 'axios';
import { BASE_URL, CRM_BASE_URL, CRM_PUBLIC_BASE_URL } from '../../constants/Configs';
import LocalStorage from '../../utils/LocalStorage';
import { userLogout } from '../../utils/commonUtils';
import { createAxiosInstance } from '../../utils/APIUtils';

export default class BaseService {
  private static shared: BaseService;
  public axios;
  constructor() {
    if (BaseService.shared) {
      throw new Error('Use BaseService.BaseServiceInstance instead of new.');
    }
    this.axios = createAxiosInstance({
      baseURL: `${BASE_URL}`,
      headers: {
        'Content-type': 'application/json',
        'token-type': 'Bearer',
      },
    });
    this.axios.interceptors.request.use(
      async (config) => {
        const isPublicURL = config.url?.includes(CRM_PUBLIC_BASE_URL);
        const isCrmNestURL = config.url?.includes(CRM_BASE_URL);
        const userDataString = await LocalStorage.getItem('user');
        const userData =
          typeof userDataString == 'string'
            ? JSON.parse(userDataString)
            : userDataString;
        const uid = userData?.data?.email || '';
        const client = userData?.client || '';
        const fold_access_token = userData?.fold_access_token;
        const responseAccessToken = userData?.accessToken || '';
        const accountUUID =
          userData?.data?.accounts.length > 0
            ? userData?.data.accounts[0].uuid || ''
            : '';
        const accountId =
          userData?.data?.accounts.length > 0
            ? userData?.data.accounts[0].id || ''
            : '';
        const apiAccessToken =
          userData?.data?.access_token || '';
        config.headers = {
          client,
          ...(!isPublicURL && {fold_access_token: fold_access_token}),
          'access-token': responseAccessToken,
          'account-uuid': accountUUID,
          account_id: accountId,
          account: accountId,
          'user-uuid': userData?.data?.uuid || '',
          userId: userData?.data?.id || '',
          api_access_token: apiAccessToken,
          uid,
          ...config.headers,
        };
        if (isCrmNestURL) {
          config.withCredentials = true;
        }
        return config;
      },
      (error) => {

      }
    );

    this.axios.interceptors.response.use(
      (res) => {
        return res;
      },
      (error) => {
        const isSessionExpired = error?.response?.status === 401;
        if (
          isSessionExpired &&
          !error?.request?.responseURL?.includes('platform/sign_in') &&
          !error?.request?.responseURL?.includes('platform/sso/sign_in')
        ) {
          userLogout();
        }

        return Promise.reject(error);
      }
    );

    BaseService.shared = this;
  }
  static getSharedInstance(): BaseService {
    return BaseService.shared ?? (BaseService.shared = new BaseService());
  }
}
