import {useLazyQuery, useMutation} from '@apollo/client';
import '@toast-ui/editor/dist/toastui-editor.css';
import {Checkbox, DatePicker, notification, Popover, Radio, RadioChangeEvent, Statistic, Switch, Tooltip, Upload} from 'antd';
import {Content} from 'antd/lib/layout/layout';
import {UploadFile} from 'antd/lib/upload/interface';
import {AxiosResponse} from 'axios';
import {
  HStack, Pressable,
  Spinner,
  Text, useToast,
  View,
  VStack,
  Divider
} from 'native-base';
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import moment from 'moment';
import Feather from 'react-native-vector-icons/Feather';
import {
  BUTTON_TYPE,
  CONVERSATION_STATUS,
  CONVERSATION_STATUS_STRING,
  getInActiveContactError,
  GROUP_TYPES,
  MLOV_CATEGORY,
  PARENT_CODE,
  PARENT_CODE_CLASS_NAME,
  RIGHT_SIDE_CONTAINER_CODE,
  SENDER_TYPE
} from '../../../../../constants';
import {
  COMMON_ACTION_CODES,
  CONVERSATION_ACTION_CODES
} from '../../../../../constants/ActionConst';
import {CONVERSATION_LOCAL_EVENT_CODES, CUSTOM_MESSAGE_EVENT_CODES, SUPPORTED_EVENT_CODE, WEB_SOCKET_EVENT_CODE} from '../../../../../constants/WebSocketConst';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import CommonService from '../../../../../services/CommonService/CommonService';
import ConversationsQueries from '../../../../../services/Conversations/ConversationsQueries';
import {
  IMessageData
} from '../../../../../services/Conversations/interfaces';
import {sendMessageWithRawBody} from '../../../../../services/Message/MessageService';
import {Colors} from '../../../../../styles/Colors';
import {
  findStringDifference,
  canCreateSMSInboxWithPhoneNumber,
  getAccountId,
  getAccountUUID,
  getChannelType,
  getConfigDataFromCode,
  getMsgEchoId,
  getUserData,
  getUserId,
  getUserUUID,
  isActiveContact,
  isChannelEmail,
  isChannelTwillioSms,
  isEmployerRole,
  isInternalChannelInboxType,
  isValidJsonString,
  isWebChannelInboxType,
  parseHtmlToPlainText
} from '../../../../../utils/commonUtils';
import { showToast, ToastType} from '../../../../../utils/commonViewUtils';
import {disabledDateTime, getDateToMomentISOString, isPastDay} from '../../../../../utils/DateUtils';
import {EventBus} from '../../../../../utils/EventBus';
import {getContactTypeId, getMlovCodeIdObj} from '../../../../../utils/mlovUtils';
import {IReplyMessageObject} from '../../../../common/ChatUI/MessageBox/interfaces';
import GroupUserMentionList from '../../../../common/GroupUserMentionList/GroupUserMentionList';
import {DatePickerProps} from '../../../../common/ModalActionCommonComponent/ModalActionDatePicker';
import {NOTIFICATION_TYPES} from '../../../../common/Notifications/NotificationsUtils';
import EducationContent from '../../../../common/Svg/EducationContent';
import SendButtonSvg from '../../../../common/Svg/SendButtonSvg';
import SendScheduleMsgSvg from '../../../../common/Svg/sendScheduleMsgSvg';
import CalendarSvg from '../../../../common/Svg/SideMenu/CalendarSvg';
import FormSvg from '../../../../common/Svg/SideMenu/FormSvg';
import {AddOrUpdateLead} from '../../../Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateLead';
import {updateSingleNotificationReadStatus} from '../../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import '../../Conversations/ConversationContainer/customStylesTabs.css';
import {getWebhookEventId, scheduleMessageApi, sendEmailMessageAPI, sendScheduleMessageApi} from '../ConversationChannelTabs/CreateSmsConversationDrawer/SmsConversationServices';
import {CHANNEL_TYPE, CHANNEL_TYPE_CODE, SCHEDULE_MESSAGE_STATUS} from '../ConversationConst';
import {IMessagingWindowFooterProps, ITypingStatus} from '../interfaces';
import {AttachArticleDrawer} from './AttachArticleDrawer';
import AttachFormDrawer from './AttachFormDrawer';
import AttachAppointmentDrawer from './AttachmentAppointmentDrawer/AttachAppointmentDrawer';
import AttachmentDrawer from './AttachmentDrawer/AttachmentDrawer';
import CannedResponses from './CannedResponses/CannedResponses';
import {ICannedResponses} from './CannedResponses/interface';
import ContactSearchDrawer from './ContactSearchDrawer';
import {
  getConversationMentionsData,
  getUserIdsFromMsgContent,
  getUserIdListFromMsgContent,
  sendNotificationOnMention
} from './ConversationMentions/ConversationMentions';
import './customFooterStylesTabs.css';
import {DeleteMessageModal} from './DeleteMessageModal';
import {IGroupMemberInfo, IMessageResp, IOnEnterActionData} from './interface';
import './customFooterStylesTabs.css';
import {
  MESSAGE_CUSTOM_CARD_KEY,
  MESSAGE_CUSTOM_CARD_KEY_NAME
} from './MessagingCustomCard/CustomCardConst';
import {
  getCategoryCode,
  getGroupMemberList,
  getMessageBodyWithAllParams,
  getSendMsgOptionList,
  GroupTypeIs,
  isGroupConversation,
  isInternalChat,
  isLoginUserDeletedFromInbox,
  isPrivateGroup,
  messageDataKeysConverter,
  getShortLink,
  isArchiveOnSend,
  getPlaceholder,
  getEventCodeFromParentCode,
  saveConversationMessage,
  removeConversationMessage,
  getFilteredMentionMessage
} from './MessagingUtils';
import './MessagingWindowFooterStyles.css';
import {hideEditorToolbar, KEY_PRESS_CODES, ON_PRESS_ENTER_ACTION_CODES, MSG_SEND_OPTIONS} from './MsgConst';
import MsgReplyDrawer from './MsgReplyDrawer/MsgReplyDrawer';
import ReplyMessageView from './ReplyMessageView';
import ScheduleMessageContainer from './ScheduleMessageContainer';
import {FoldButton} from '../../../../CommonComponents/FoldButton/FoldButton';
import locale from "antd/es/date-picker/locale/de_DE";
import {IScheduleMessageParams, ISendScheduleParams} from '../ConversationChannelTabs/CreateSmsConversationDrawer/interface';
import {isAccountConfigEnabled} from '../../../../../utils/configUtils';
import {CONFIG_CODES} from '../../../../../constants/AccountConfigConst';
import {Dimensions} from 'react-native';
import {MediaSelectionModal} from '../../../ContentManagement/PatientEducation/MediaSelectionModal';
import InfoSvg from '../../../../common/Svg/InfoSvg';
import { isArchiveOnSendEnable } from '../../../Sales/ProductsAndServices/JourneyPackages/PackagesUtils';
import {PhiInfo} from './PhiInfo';
import LocalStorage from '../../../../../utils/LocalStorage';
import {LOCAL_STORAGE_CONST} from '../../../../../screens/MainScreen/MainScreenConst';
import {getBasicDeviceInfoForMessageSend} from '../../../../../utils/platformCheckUtils';
import debounce from 'lodash/debounce';
import {MESSAGE_DRAFT_TYPES} from '../../../../common/EmailDrawerCommonV2/EmailDrawerCommonV2Const';
import { testID, TestIdentifiers } from '../../../../../testUtils';
import { getSearchStringForCannedResponseEditor } from './CannedResponses/CannedResponsesUtils.web';
import BrowseScheduleSvg from '../../../../common/Svg/SideMenu/BrowseScheduleSvg';
import BrowseMediaSvg from '../../../../common/Svg/SideMenu/BrowseMediaSvg';
import BrowseAttachmentSvg from '../../../../common/Svg/SideMenu/BrowseAttachmentSvg';
import MessageReplySvg from '../../../../common/Svg/SideMenu/MessageReplySvg';
import BrowseContactSvg from '../../../../common/Svg/SideMenu/BrowseContactSvg';
import CustomEditor from './CustomEditor/CustomEditor';
import {isFormAccessAllowed, USER_ACCESS_PERMISSION} from '../../../UserAccess/UserAccessPermission';
import {usePermissions} from '../../../../CustomHooks/usePermissions';
import {MAIN_MENU_CODES} from '../../../../SideMenuBar/SideBarConst';
import {getMessagingWindowFooterStyles} from './MessagingWindowFooterStyles';
import {
  CaptureTransaction,
  TRANSACTION_NAMES,
} from '../../../../../utils/CaptureTransaction';
import {FHTooltip} from '../../../../common/FHTooltip';

const {Countdown} = Statistic
export const MessagingWindowFooter = (props: IMessagingWindowFooterProps) => {
  const {isInDrawerView, isDetailsContainerVisible, isInstantChatView, parentCode, isSeachMsgContainerVisible} = props;
  const editorRef: any = useRef<any>(null);
  const phiContainerRef: any = useRef();
  const typingStatusRef = useRef<{
    isCurrentUserTyping: boolean,
    typingIntervalFn?: NodeJS.Timeout,
    privateNote: string,
  }>({
    isCurrentUserTyping: false,
    privateNote: 'false'
  });
  const previousRichTextValue = useRef("");
  const commonData = useContext(CommonDataContext);
  const userData = getUserData();
  const mlovData = commonData.MLOV;
  const groupMemberTypeList = mlovData[MLOV_CATEGORY.GroupMemberType];
  const accountUUID = getAccountUUID();
  const userUUID = getUserUUID();
  const contentMaxHeight = 132;
  const contentMinHeight = 44;
  const [editorInputHightState, setEditorInputHeightState] = useState(contentMinHeight)
  const userId = getUserId();
  const groupMemberCodeIdObj = getMlovCodeIdObj(groupMemberTypeList);
  const accountId = getAccountId()
  const intl = useIntl();
  const archiveOnSendConfig = getConfigDataFromCode(CONFIG_CODES.ARCHIVE_CONVERSATION_ON_SEND_MESSAGE);
  const {check} = usePermissions();
  const isAppointmenetAccess = check(
    USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
    MAIN_MENU_CODES.CALENDAR
  ).hasAccess;
  const isFormAccess = isFormAccessAllowed();
  const isArchiveEnable =
    isArchiveOnSendEnable(archiveOnSendConfig) &&
    !isInternalChat(props?.conversationData)
  const groupMembers =
    props.conversationData?.groupConversation?.groupMembers || [];
  const groupMemberData = getGroupMemberList(groupMembers);
  const isPatientBroadCastGroup =
    props.conversationData?.groupConversation?.groupType?.code ===
    GROUP_TYPES.PATIENT_BROADCAST;
  let isMemberExist: any = {} as IGroupMemberInfo;
  isMemberExist = groupMemberData?.groupMembersInfoList?.find((item) => {
    return item.uuid === userUUID;
  }) || {};
  const timeGreaterThan = -120000
  const {width} = Dimensions.get('window');
  const minWidth = 1500;
  const isSideCarContext = commonData.sidecarContext?.isSidecar;
  const {
    conversationData,
    onMsgSend,
    selectedTabCode,
    selectedInboxTypeCode,
    onActionMsgFooterPerformed,
    conversationInbox,
    contactData,
    onMsgChangeText,
  } = props;
  const inboxTypeFromConversation =
    conversationData?.conversationInbox?.channelType || '';
  const [createConversationMentions] = useMutation(
    ConversationsQueries.createConversationMentions
  );
  const [getMessageData] = useLazyQuery<{
    message: IMessageData;
  }>(ConversationsQueries.getMessageDataId, {
    fetchPolicy: 'no-cache',
  });
  const [GET_SCHEDULE_AND_FAILED_MESSAGE_COUNT_BY_STATUS] = useLazyQuery(ConversationsQueries.GET_SCHEDULE_AND_FAILED_MESSAGE_COUNT_BY_STATUS, {
    fetchPolicy: 'no-cache',
  })
  let onEnterNewLine = {} as any;
  if (isValidJsonString(props?.isNewLineOnEnter?.preferencesJson || '{}')) {
    onEnterNewLine = JSON.parse(props?.isNewLineOnEnter?.preferencesJson || '{}');
  }
  const onEnterNewLineValue: boolean = onEnterNewLine?.userMessages?.isNewLineOnEnter || false;
  const isScheduledSendEnabled = isAccountConfigEnabled(CONFIG_CODES.ENABLE_SCHEDULE_MESSAGE);
  const enableEnterToNextLine = isAccountConfigEnabled(CONFIG_CODES.ENABLE_ENTER_TO_NEXT_LINE)
  const captureTransactionInst = CaptureTransaction.getInstance();

  const isShowReplyTab = () => {
    if (
      !isChannelTwillioSms(
        props?.conversationData?.conversationInbox?.channelType
      )
    ) {
      return true;
    }
    const isSendSMSAllowed = canCreateSMSInboxWithPhoneNumber(
      props?.conversationData?.conversationInbox?.channelTwilioSms?.phoneNumber?.toString() ||
        ''
    );
    if (isSendSMSAllowed) {
      return true;
    }
    return false;
  };

  const [showAgentList, setShowAgentList] = useState(false);
  const [fileList, setFileList] = useState<UploadFile<any>[]>([]);
  const [richTextValue, setRichTextValue] = useState('');
  const [initialValue, setInitialValue] = useState('');
  const [uploadingMsg, setUploadingMsg] = useState(false);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [formLink, setFormLink] = useState('');
  const [formDrawer, setFormDrawer] = useState(false);
  const [showMediaDrawer, setShowMediaDrawer] = useState(false);
  const [isMsgReplyDrawerVisible, setIsMsgReplyDrawerVisible] = useState(false);
  const [messageType, setMessageType] = useState(isShowReplyTab() ? 'reply' : 'internalMessage');
  const [articleLink, setArticleLink] = useState('');
  const [articleDrawer, setArticleDrawer] = useState(false);
  const [contactDrawer, setContactDrawer] = useState(false);
  const [categoryCode, setCategoryCode] = useState('');
  const [selectedReplyMsg, setSelectedReplyMsg] = useState<IReplyMessageObject>({} as IReplyMessageObject);
  const [appointmentDrawer, setAppointmentDrawer] = useState(false);
  const [showSendMessageDisable, setShowSendMessageDisable] = useState(false);
  const [contactDeactivationStatus, setContactDeactivationStatus] = useState(false);
  const [browseContactUUID, setBrowseContactUUID] = useState('');
  const [code, setCode] = useState('');
  const [scheduleMessageDrawer, setScheduleMessageDrawer] = useState(false);
  const [showSchedulePopover, setShowSchedulePopover] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalScheduleMessage, setTotalScheduleMessage] = useState(0);
  const [totalScheduleFailedMessage, setTotalScheduleFailedMessage] = useState(0);
  const [showPhiWarning, setShowPhiWarning] = useState(false);
  const [isShowOnEnterActionPopup, setIsShowOnEnterActionPopup] = useState(false);
  const [onEnterActionMsgFooterText, setOnEnterActionMsgFooterText] = useState(
    onEnterNewLineValue
      ? intl.formatMessage({id: 'pressEnterToStartANewLine'})
      : intl.formatMessage({id: 'pressEnterToSendTheMessage'})
  );
  const [onSelectedEnterNewLine, setOnSelectedEnterNewLine] = useState(onEnterNewLineValue || false);
  const [isArchiveOnMessageSend, setIsArchiveOnMessageSend] = useState(false);
  const [showArchiveOnSend, setShowArchiveOnSend] = useState(
    conversationData?.status === CONVERSATION_STATUS.OPEN || false
  );

  const styles = React.useMemo(
    () =>
      getMessagingWindowFooterStyles({
        totalScheduleMessage,
        totalScheduleFailedMessage,
        messageType,
        editorInputHightState,
      }),
    [
      totalScheduleMessage,
      totalScheduleFailedMessage,
      messageType,
      editorInputHightState,
    ]
  );

  const hideCannedResponse = () => {
    setShowAgentList(false);
  };
  const msgToast = useToast();

  const getScheduleMessagesCount = async () => {
    const scheduleAndFailedMsgData =
      await GET_SCHEDULE_AND_FAILED_MESSAGE_COUNT_BY_STATUS({
        variables: {
          conversationUuid: conversationData?.uuid,
          scheduleMsgStatus: SCHEDULE_MESSAGE_STATUS.SCHEDULED,
          failedMsgStatus: SCHEDULE_MESSAGE_STATUS.FAILED,
        },
      });
    if (
      scheduleAndFailedMsgData?.data?.scheduleMsgCount?.aggregate?.count > 0
    ) {
      setTotalScheduleMessage(
        scheduleAndFailedMsgData?.data?.scheduleMsgCount?.aggregate?.count
      );
    }
    if (
      scheduleAndFailedMsgData?.data?.failedScheduleMsgCount?.aggregate?.count >
      0
    ) {
      setTotalScheduleFailedMessage(
        scheduleAndFailedMsgData?.data?.failedScheduleMsgCount?.aggregate
          ?.count
      );
    }
  };
  const onActionPerformed = (actionCode: string, actionData: any) => {
    switch (actionCode) {
      case CONVERSATION_ACTION_CODES.SCHEDULE_MESSAGE_LIST:
        setScheduleMessageDrawer(true);
        break;
    }
  };
  const resetMessageWindowHeight = () => {
    props.onCallBackEditorInputHeight?.(COMMON_ACTION_CODES.RESET);
    setEditorInputHeightState(contentMinHeight);
  }
  const onConversationPopupActionPerformed = (data: {
    actionCode: string;
    actionData?: any;
  }) => {
    onActionPerformed(data?.actionCode, data?.actionData);
  };

  useEffect(() => {
    if (isDetailsContainerVisible !== undefined) {
      calculateTextBoxHeight()
    }
  }, [isDetailsContainerVisible])

  useEffect(()=>{
    if (editorRef.current) {
      const editor = editorRef.current
      let newPlaceholder = '';
      newPlaceholder = getPlaceholder(
        intl,
        typingStatusRef.current.privateNote,
        selectedInboxTypeCode,
        onSelectedEnterNewLine,
        isSideCarContext,
        isInDrawerView || isInstantChatView
      );
      editor.setPlaceholder(newPlaceholder);
    }
    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener(
      CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CHANGE_MESSAGE_USER_PREFERENCE,
      handleOnEnterActionEvent
    );
    return () => {
      eventBus.removeEventListener(handleOnEnterActionEvent);
    };
  },[messageType, onSelectedEnterNewLine]);

  const setConversationSavedText = async () => {
    if (editorRef?.current && props?.conversationDraftMessage || '') {
      const inst = editorRef.current
      inst.setMarkdown(props?.conversationDraftMessage || '');
    }
    setInitialValue(props?.conversationDraftMessage || '');
    setRichTextValue(props?.conversationDraftMessage || '');
  }

  useEffect(() => {
    setConversationSavedText();
    const categoryCode = getCategoryCode(
      selectedInboxTypeCode,
      inboxTypeFromConversation
    );
    getScheduleMessagesCount();
    setRichTextValue('');
    setFileList([]);
    typingStatusRef.current.privateNote = isShowReplyTab() ? 'false' : 'true'
    setMessageType(isShowReplyTab() ? 'reply' : 'internalMessage');
    setShowAgentList(false);
    setCategoryCode(categoryCode);
    setSelectedReplyMsg({} as any);
    if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_OTHER || isInstantChatView) {
      updateSingleNotificationReadStatus({
        type: NOTIFICATION_TYPES.CONVERSATION_UPDATED,
        typeId: conversationData.id + '',
        userId: userUUID,
        isRead: true,
      });
    }
    let currentStatus = true;
    if (isGroupConversation(conversationData)) {
      if (isPrivateGroup(conversationData)) {
        currentStatus = true;
      } else {
        currentStatus = true;
      }
    } else if (conversationData?.inboxId > 0) {
      currentStatus = true;
    }
    setContactDeactivationStatus(!currentStatus);
  }, [conversationData.id, selectedInboxTypeCode]);

  useEffect(() => {
    setSelectedReplyMsg(props.selectedReplyMsg);
    setMessageType(props.selectedReplyMsg?.private ? 'internalMessage' : messageType);
    if (props.selectedReplyMsg?.private) {
      typingStatusRef.current.privateNote = 'true'
    }
  }, [
    props?.selectedReplyMsg ||
    props?.selectedReplyMsg?.id ||
    props?.selectedReplyMsg?.uuid ||
    props?.selectedReplyMsg?.position ||
    props?.selectedReplyMsg?.senderFullName,
    props?.selectedReplyMsg?.attachmentId ||
    props?.selectedReplyMsg?.text ||
    props?.selectedReplyMsg?.private ||
    props?.selectedReplyMsg?.messageType ||
    props?.selectedReplyMsg?.dateStr,
  ]);

  useEffect(() => {
    const eventBus = EventBus.getEventBusInstance();
    const eventCode = getEventCodeFromParentCode(parentCode);
    eventBus.removeEventListener(showArchiveOnSendCallback);
    const newNotification = (data: any) => {
      const singleNotification = data?.data || {};
      const conversationId = conversationData.id + '';
      if (
        singleNotification.type == NOTIFICATION_TYPES.CONVERSATION_UPDATED &&
        singleNotification.typeId === conversationId
      ) {
        updateSingleNotificationReadStatus({
          type: NOTIFICATION_TYPES.CONVERSATION_UPDATED,
          typeId: conversationId,
          userId: userUUID,
          isRead: true,
        });
      }
    };
    eventBus.addEventListener(
      SUPPORTED_EVENT_CODE.NOTIFICATION_CREATED,
      newNotification
    );
    eventBus.addEventListener(
      eventCode,
      onConversationPopupActionPerformed
    );

    eventBus.addEventListener(
      WEB_SOCKET_EVENT_CODE.CONVERSATION_OPENED,
      showArchiveOnSendCallback
    )
    eventBus.addEventListener(
      WEB_SOCKET_EVENT_CODE.CONVERSATION_RESOLVED,
      showArchiveOnSendCallback
    )
    eventBus.addEventListener(
      WEB_SOCKET_EVENT_CODE.ASSIGNEE_CHANGED,
      showArchiveOnSendCallback
    )
    eventBus.addEventListener(
      SUPPORTED_EVENT_CODE.MESSAGE_CREATED,
      showArchiveOnSendCallback
    )

    return () => {
      eventBus.removeEventListener(newNotification);
      eventBus.removeEventListener(onConversationPopupActionPerformed);
      eventBus.removeEventListener(showArchiveOnSendCallback);
      if (editorRef.current) {
        // Destroy the editor instance to prevent memory leaks
        editorRef?.current?.destroy?.();
        editorRef.current = null;
      }
      if (typingStatusRef.current) {
        // Destroy the editor instance to prevent memory leaks
        typingStatusRef.current = {
          isCurrentUserTyping: false,
          privateNote: 'false'
        };
      }
    };
  }, []);

  const showArchiveOnSendCallback = React.useCallback( (conversationData: any) => {
    if (
      conversationData?.data?.conversationStatus === CONVERSATION_STATUS.OPEN ||
      conversationData?.data?.conversation?.status === CONVERSATION_STATUS.OPEN ||
      conversationData?.conversation?.status === CONVERSATION_STATUS_STRING.OPEN ||
      conversationData?.data?.id !== props.conversationData?.id
    ) {
      setShowArchiveOnSend(true);
    } else {
      setShowArchiveOnSend(false);
    }
  }, [props.conversationData?.id]);

  const saveConversationMessageInMessageStorage = () => {
    let messageText = '';
    if (editorRef.current) {
      const inst = editorRef.current
      messageText = inst.getMarkdown();
      if (props?.conversationData?.uuid) {
        if (messageText !== props?.conversationDraftMessage) {
          saveConversationMessage(props?.conversationData?.uuid, {
            content: messageText
          }, MESSAGE_DRAFT_TYPES.REPLY_DRAFT);
        }
      }
    }
  }

  const showToastMsg = (isError: boolean) => {
    showToast(msgToast, intl.formatMessage({id: isError ? 'errorOnMsgSend' : 'onAttachmentSend'}), isError ? ToastType.error : ToastType.success);
  };

  const getPlainString = () => {
    let plainString = '';
    if (editorRef.current) {
      const inst = editorRef.current
      plainString = inst.getMarkdown();
    }
    return plainString;
  };

  const captureSendMessageTransaction = () => {
    captureTransactionInst.initiateTransaction({
      name: TRANSACTION_NAMES.CONVERSATION_MESSAGE_SEND,
      identifier: conversationData?.id,
    });
    captureTransactionInst.finishTransaction(
      TRANSACTION_NAMES.CONVERSATION_MESSAGE_SEND,
      conversationData?.id,
      {
        selectedInboxTypeCode,
      }
    );
  };

  const handleSendMessage = (sendScheduleMessageBody?: ISendScheduleParams) => {
    let plainString = '';
    if (isWebChannelInboxType(selectedInboxTypeCode || '')) {
      if (editorRef.current) {
        const inst = editorRef.current
        plainString = inst.getMarkdown();
      }
      const editorHtmlElemList: any = document?.querySelectorAll(
        '.messagewindow-editor .ProseMirror.toastui-editor-contents .ProseMirror-trailingBreak'
      );
      if (editorHtmlElemList?.length) {
        for (let index = 0; index < editorHtmlElemList.length; index++) {
          const element = editorHtmlElemList[index];
          if (element?.remove) {
            element.remove();
          }
        }
      }
      const parentClassName = parentCode ? `.${getClassNameForFooter()}` : '';
      const editorHtmlElem: any = document?.querySelector(
        `.messagewindow-editor${parentClassName} .ProseMirror.toastui-editor-contents`
      );
      if (editorHtmlElem && editorHtmlElem.innerHTML) {
        editorHtmlElem.innerHTML = '';
        if (editorHtmlElem.focus) {
          editorHtmlElem.focus();
        }
      }
      if (editorRef?.current) {
        const inst = editorRef.current
        inst.reset();
        inst.setMarkdown('');
      }
    } else {
      if (editorRef.current) {
        const inst = editorRef.current
        plainString = inst.getMarkdown();
        inst.reset();
        inst.setMarkdown('');
      } else {
        plainString = richTextValue;
      }
    }
    const messagePlainStr = parseHtmlToPlainText(plainString.trim());
    if (sendScheduleMessageBody?.content) {
      sendMessage(sendScheduleMessageBody?.content, sendScheduleMessageBody)
    } else if (messagePlainStr && messagePlainStr.trim().length > 0) {
      sendMessage(messagePlainStr.replace(/\\/g, ''))
    }
    captureSendMessageTransaction();
  };

  const getClassNameForFooter = () => {
    let parentClassName = '';
    if (parentCode) {
      parentClassName = PARENT_CODE_CLASS_NAME[parentCode];
    }
    return parentClassName;
  };

  const updateConversationMentionsData = async (messageData: IMessageResp) => {
    const userListData = getUserIdsFromMsgContent(
      messageData.content,
      groupMemberCodeIdObj
    );

    if (userListData?.length) {
      const messageResp = await getMessageData({
        variables: {
          messageId: messageData.id,
        },
      }).catch(() => {

      });

      if (messageResp?.data?.message?.id === messageData.id) {
        const conversationMentionData = getConversationMentionsData({
          accountUUID,
          userUUID,
          msgData: messageResp?.data?.message,
          selectedConversationData: conversationData,
          userList: userListData,
        });
        const mentionResponse = await createConversationMentions({
          variables: {
            mentionDataList: conversationMentionData,
          },
        });
        await sendNotificationOnMention({
          mentionResponse:
            mentionResponse?.data?.createConversationMentions?.returning || [],
          conversationUuid: conversationData.uuid,
          displayId: conversationData.displayId
        });
      }
    }
  };

  const getScheduleMessageObj = async (message: string, dateString: string) => {
    const channelType = getChannelType(inboxTypeFromConversation)
    const workflowEventId = await getWorkFlowEventId(dateString)
    if (workflowEventId) {
      const privateNote = typingStatusRef.current.privateNote;
      return {
        content: message,
        conversationUuid: conversationData?.uuid,
        private: privateNote === 'true' ? true : false,
        contentType: conversationData?.conversationInbox?.channelEmail?.forwardRouteId && privateNote == 'false' ? 12 : 0,
        senderType: SENDER_TYPE?.USER,
        scheduledAt: dateString,
        workflowEventId: workflowEventId,
        status: 'scheduled',
        inboxId: conversationData?.inboxId || conversationData?.contactInbox?.inbox?.id || -1,
        senderId: userId,
        createdBy: userUUID,
        updatedBy: userUUID,
        accountUuid: accountUUID,
        channelType: channelType
      }
    }
    return {} as IScheduleMessageParams
  }

  const getWorkFlowEventId = async (date: string) => {
    const body = {
      nextExecutionDate: date,
      data: {
        accountId: accountId,
        accountUUID: accountUUID
      }
    }
    const webhookEventId = await getWebhookEventId(body);
    return webhookEventId?.data?.id
  };

  const sendMessage = async (message: string, sendScheduleMessageBody?: ISendScheduleParams) => {
    let currentStatus = true;
    const deviceInfo = await getBasicDeviceInfoForMessageSend();
    const additionalAttributes = {
      deviceInfo: deviceInfo,
    };
    if (isGroupConversation(conversationData)) {
      if (isPrivateGroup(conversationData)) {
        currentStatus = true;
      } else {
        currentStatus = true;
      }
    } else if (conversationData?.inboxId > 0) {
      currentStatus = true;
    }
    if (!currentStatus) {
      const message = getInActiveContactError(contactData);
      notification.error({
        message,
      });
      return;
    }
    setUploadingMsg(true);
    const commonService = CommonService.getCommonServiceInstance();
    const axiosService = commonService.axiosService;
    const accountId = conversationData.accountId;
    const conversationId = conversationData.displayId;
    let ccEmails = '';
    if (conversationData?.conversationInbox?.channelEmail?.forwardRouteId && conversationData?.conversationInbox?.channelEmail?.forwardToEmail) {
      ccEmails = conversationData?.conversationInbox?.channelEmail?.forwardToEmail;
    }
    const formData = new FormData();
    const echoId = getMsgEchoId()
    formData.append('echo_id', echoId);
    formData.append('private', typingStatusRef.current.privateNote);
    formData.append('cc_emails', ccEmails);
    formData.append('bcc_emails', '');
    formData.append('additional_attributes', JSON.stringify(additionalAttributes));
    if (message) {
      formData.append('content', message);
      setRichTextValue('');
    }

    if (groupMemberCodeIdObj && Object.keys(groupMemberCodeIdObj)?.length && message) {
      const userMentionIds = getUserIdListFromMsgContent(message, groupMemberCodeIdObj);
      if (userMentionIds?.length) {
        formData.append('user_mention_ids', userMentionIds.toString());
      }
    }
    if (selectedReplyMsg?.uuid) {
      formData.append(
        'parent_message_uuid',
        selectedReplyMsg?.uuid
      );
    }
    groupMemberCodeIdObj
    const currentTime = getDateToMomentISOString();

    if (
      fileList &&
      fileList.length
    ) {
      fileList.forEach((fileObj) => {
        if (fileObj.originFileObj) {
          fileObj.originFileObj;
          formData.append('attachments[]', fileObj.originFileObj as any);
        }
      });
    }

    let userMentionIdStr = '';
    if (groupMemberCodeIdObj && Object.keys(groupMemberCodeIdObj)?.length && message) {
      const userMentionIds = getUserIdListFromMsgContent(message, groupMemberCodeIdObj);
      if (userMentionIds?.length) {
        userMentionIdStr = userMentionIds.toString();
        formData.append('user_mention_ids', userMentionIdStr);
      }
    }
    if (conversationData?.conversationInbox?.channelEmail?.forwardRouteId && typingStatusRef.current.privateNote == 'false') {
      sendEmailMessageAPI({
        private: false,
        content: message,
        conversationDisplayId: conversationData?.displayId,
        inboxId: conversationData?.inboxId,
        subject: conversationData?.additionalAttributes?.mail_subject || '',
        user_mention_ids: userMentionIdStr
      }).then(async (responseMsg: AxiosResponse<IMessageResp>) => {
        if (responseMsg && responseMsg.data) {
          await removeConversationMessage(conversationData?.uuid, MESSAGE_DRAFT_TYPES.REPLY_DRAFT);
          if (props?.conversationData?.status === 1) {
            const eventBus = EventBus.getEventBusInstance()
            eventBus.broadcastEvent(CUSTOM_MESSAGE_EVENT_CODES.NEW_MESSAGE_CREATED, {
              conversationData: props?.conversationData,
              eventCode: CUSTOM_MESSAGE_EVENT_CODES.NEW_MESSAGE_CREATED,
            });
          }

          responseMsg.data['sender_type'] = 'user';
          const messageRepData = messageDataKeysConverter(responseMsg.data);
          const responseData = {...messageRepData, currentTime};
          onMsgSend(message, responseData, selectedReplyMsg);
          updateConversationMentionsData(messageRepData);
          if (
            fileList &&
            fileList.length
          ) {
            showToastMsg(false);
          }
          setFileList([]);
          setRichTextValue('');
          setUploadingMsg(false);
          setSelectedReplyMsg({} as IReplyMessageObject);
          const parentClassName = parentCode ? `.${getClassNameForFooter()}` : '';
          const editorHtmlElem: any = document?.querySelector(
            `.messagewindow-editor${parentClassName} .ProseMirror.toastui-editor-contents`
          );
          if (editorHtmlElem && editorHtmlElem.innerHTML) {
            editorHtmlElem.innerHTML = '';
            if (editorHtmlElem.focus) {
              editorHtmlElem.focus();
            }
          }
          if (editorRef?.current) {
            const inst = editorRef.current
            resetMessageWindowHeight();
            inst.reset();
          }
        }
      })
        .catch(() => {
          showToastMsg(true);
          setUploadingMsg(false);
        });
    } else {
      await removeConversationMessage(conversationData?.uuid, MESSAGE_DRAFT_TYPES.REPLY_DRAFT);
      if (props.onAddMsgToQueue && props.msgQueue) {
        const obj = {
          sender_type: 'user',
          sending: true,
          echo_id: echoId,
          private:
            messageType === 'internalMessage'
              ? true
              : false,
        };
        const responseData = {...obj, currentTime};
        const eventBus = EventBus.getEventBusInstance()
        eventBus.broadcastEvent(SUPPORTED_EVENT_CODE.SEND_MESSAGE, {
          accountId,
          conversationId,
          formData,
          message,
          parentMessage: selectedReplyMsg,
          echoId,
          selectedConversation: {
            uuid: conversationData?.uuid,
            displayId: conversationData?.displayId
          }
        });
        if (props?.conversationData?.status === 1) {
          const eventBus = EventBus.getEventBusInstance()
          eventBus.broadcastEvent(CUSTOM_MESSAGE_EVENT_CODES.NEW_MESSAGE_CREATED, {
            conversationData: props?.conversationData,
            eventCode: CUSTOM_MESSAGE_EVENT_CODES.NEW_MESSAGE_CREATED,
          });
        }

        onMsgSend(
          message,
          responseData,
          selectedReplyMsg
        );
        if (
          fileList &&
          fileList.length
        ) {
          showToastMsg(false);
        }
        setFileList([]);
        setRichTextValue('');
        setUploadingMsg(false);
        setSelectedReplyMsg({} as IReplyMessageObject);
        const parentClassName = parentCode ? `.${getClassNameForFooter()}` : '';
        const editorHtmlElem: any = document?.querySelector(
          `.messagewindow-editor${parentClassName} .ProseMirror.toastui-editor-contents ${parentClassName}`
        );
        if (editorHtmlElem && editorHtmlElem.innerHTML) {
          editorHtmlElem.innerHTML = '';
          if (editorHtmlElem.focus) {
            editorHtmlElem.focus();
          }
        }
        if (editorRef?.current) {
          const inst = editorRef.current
          inst.reset();
          resetMessageWindowHeight();
        }
      } else {
        axiosService.post(
          `/accounts/${accountId}/conversations/${conversationId}/messages`,
          formData,
          {
            headers: {
              'content-type': 'multipart/form-data',
            },
          }
        )
          .then(async (responseMsg: AxiosResponse<IMessageResp>) => {
            if (responseMsg && responseMsg.data) {
              await removeConversationMessage(conversationData?.uuid, MESSAGE_DRAFT_TYPES.REPLY_DRAFT);
              if (props?.conversationData?.status === 1) {
                const eventBus = EventBus.getEventBusInstance()
                eventBus.broadcastEvent(CUSTOM_MESSAGE_EVENT_CODES.NEW_MESSAGE_CREATED, {
                  conversationData: props?.conversationData,
                  eventCode: CUSTOM_MESSAGE_EVENT_CODES.NEW_MESSAGE_CREATED,
                });
              }
              responseMsg.data['sender_type'] = 'user';
              const responseData = {...responseMsg.data, currentTime};
              onMsgSend(message, responseData, selectedReplyMsg);
              updateConversationMentionsData(responseMsg.data);
              if (
                fileList &&
                fileList.length
              ) {
                showToastMsg(false);
              }
              setFileList([]);
              setRichTextValue('');
              setUploadingMsg(false);
              setSelectedReplyMsg({} as IReplyMessageObject);
              const parentClassName = parentCode ? `.${getClassNameForFooter()}`: '';
              const editorHtmlElem: any = document?.querySelector(
                `.messagewindow-editor${parentClassName} .ProseMirror.toastui-editor-contents`
              );
              if (editorHtmlElem && editorHtmlElem.innerHTML) {
                editorHtmlElem.innerHTML = '';
                if (editorHtmlElem.focus) {
                  editorHtmlElem.focus();
                }
              }
              if (editorRef?.current) {
                const inst = editorRef.current
                inst.reset();
                resetMessageWindowHeight();
              }
            }
          })
          .catch(() => {
            showToastMsg(true);
            setUploadingMsg(false);
          });
      }
    }
    if (sendScheduleMessageBody) {
      const sendScheduleResponse = await sendScheduleMessageApi(sendScheduleMessageBody);
      if (sendScheduleResponse?.data?.id) {
        setScheduleMessageDrawer(false);
        showToast(
          msgToast,
          intl.formatMessage({id: 'sendSuccess'}),
          ToastType.success
        );
      } else {
        showToast(
          msgToast,
          intl.formatMessage({id: 'apiErrorMsg'}),
          ToastType.error
        );
      }
    }
  };

  const toggleTypingStatus = () => {
    if (
      conversationData?.inboxId === -1 ||
      (conversationData?.inboxId > 0 &&
        conversationData?.conversationInbox?.channelType ===
        CHANNEL_TYPE.CHANNEL_WEB_WIDGET)
    ) {
      if (!typingStatusRef?.current) {
        return;
      }
      if (!typingStatusRef.current?.isCurrentUserTyping) {
        toggleTypingStatusApi('on');
        if (typingStatusRef?.current) {
          typingStatusRef.current.isCurrentUserTyping = true;
        }
      }

      if (typingStatusRef.current?.typingIntervalFn) {
        clearTimeout(typingStatusRef.current.typingIntervalFn);
      }

      if (typingStatusRef?.current) {
        const typingIntervalFn = setTimeout(() => {
          toggleTypingStatusApi('off');
          if (typingStatusRef?.current) {
            typingStatusRef.current.isCurrentUserTyping = false;
          }
        }, 2000);
        typingStatusRef.current.typingIntervalFn = typingIntervalFn;
      }
    }
  };

  const toggleTypingStatusApi = (typingStatus: ITypingStatus) => {
    const commonService = CommonService.getCommonServiceInstance();
    const axiosService = commonService.axiosService;
    const accountId = conversationData.accountId;
    const conversationId = conversationData.displayId;
    const bodyData = JSON.stringify({
      is_private: typingStatusRef?.current?.privateNote == 'true',
      typing_status: typingStatus,
    });
    axiosService
      .post(
        `/accounts/${accountId}/conversations/${conversationId}/toggle_typing_status`,
        bodyData,
        {
          data: bodyData,
          headers: {
            'content-type': 'application/json',
          },
        }
      )
      .catch(() => {
        // catch
      });
  };

  const sendContactCard = async (messageCustomCardKey: string) => {
    if (browseContactUUID) {
      const accountId = conversationData.accountId;
      const conversationId = conversationData.displayId;

      const messageBodyWithAllParams = getMessageBodyWithAllParams(
        messageCustomCardKey as MESSAGE_CUSTOM_CARD_KEY_NAME,
        accountId,
        conversationId,
        browseContactUUID,
        typingStatusRef.current.privateNote
      );

      let userMentionIdStr = '';
      if (groupMemberCodeIdObj && Object.keys(groupMemberCodeIdObj)?.length && messageBodyWithAllParams?.messageBody?.content) {
        const userMentionIds = getUserIdListFromMsgContent(messageBodyWithAllParams?.messageBody?.content, groupMemberCodeIdObj);
        if (userMentionIds?.length) {
          userMentionIdStr = userMentionIds.toString();
          if (userMentionIdStr) {
            messageBodyWithAllParams.messageBody.user_mention_ids = userMentionIdStr;
          }
        }
      }

      const messageResp = await sendMessageWithRawBody(
        messageBodyWithAllParams
      );
      if (messageResp) {
        try {
          setContactDrawer(false);
          setBrowseContactUUID('');
          if (messageResp) {
            const currentTime = getDateToMomentISOString();
            const responseData = {...messageResp.data, currentTime};
            onMsgSend(messageResp.data.content, responseData);
          }
        } catch { }
      }
    }
  };

  const isDisplayCannedResponses = () => {
    return (
      richTextValue &&
      richTextValue.indexOf('/') === 0
    );
  };

  const isDisplayUserAgents = (listCheck: boolean) => {
    setShowAgentList(listCheck);
  };

  const onUserAgentSelection = (userSelected: any) => {
    const inst = editorRef?.current;
    const plainString: string = (inst && inst?.getMarkdown()) || '';
    const currentText = parseHtmlToPlainText(plainString);
    const diffLocation = findStringDifference(
      currentText,
      previousRichTextValue?.current
    );
    const diffIndex = diffLocation != -1 ? diffLocation + 1 : undefined;
    const firstText = currentText?.slice(0, diffIndex);
    const updateIndex = firstText?.lastIndexOf('@');

    const searchText = currentText?.slice(updateIndex + 1, diffIndex);
    let startIndex = firstText?.length;
    let cursorPadding = 1;
    if (firstText[firstText?.length - 1] === '\n') {
      const searchTextSplit = searchText?.split('\n');
      if (searchTextSplit?.length > 0 && searchTextSplit[0]?.length == 0) {
        startIndex -= searchText?.length - 1;
        cursorPadding = searchText?.length - 1;
      } else if (searchTextSplit?.length > 2) {
        startIndex -= searchText?.length - searchTextSplit[0]?.length - 1;
        cursorPadding = searchTextSplit?.length - 2;
      } else {
        cursorPadding = 0;
      }
    }

    let replaced = currentText?.slice(0, updateIndex)?.replace(/\n/g, '<br>');
    replaced += `${userSelected}&nbsp;`;
    replaced += currentText
      ?.slice(startIndex, currentText.length)
      ?.replace(/\n/g, '<br>');

    previousRichTextValue.current = replaced;
    setRichTextValue(replaced);
    setShowAgentList(false);
    if (editorRef?.current) {
      const inst = editorRef.current;
      const selection = inst?.getSelection();
      const searchTextLength = currentText?.slice(updateIndex, diffIndex)?.length - 2;
      const selectedUserTextLength = getFilteredMentionMessage(userSelected)?.length - 1;
      const index = selectedUserTextLength - searchTextLength;
      inst.setMarkdown(replaced);
      const selectionPosition = selection[1] as number;
      const selectionIndex = (selectionPosition) + index - 1 + cursorPadding;
      inst.setSelection(selectionIndex);
    }
  };
  const getUserListElem = () => {
    let listElem = <></>;
    const currentText =  parseHtmlToPlainText(richTextValue)?.split("\n")?.join(" ");
    const previousText = parseHtmlToPlainText(previousRichTextValue?.current)?.split("\n")?.join(" ");
    const diffLocation = findStringDifference(currentText, previousText)
    const diffIndex = diffLocation != -1 ? diffLocation + 1 : undefined;
    const firstText = currentText?.slice(0, diffIndex);
    const updateIndex = firstText?.lastIndexOf('@');
    const text = currentText?.slice(updateIndex, diffIndex)
    if (
      showAgentList &&
      ((isGroupConversation(conversationData) &&
        conversationData?.groupConversation?.groupMembers?.length &&
        !isPatientBroadCastGroup) ||
        (conversationData?.inboxId !== -1 &&
          typingStatusRef.current.privateNote == 'true'))
    ) {
      listElem = (
        <GroupUserMentionList
          isReplyMessage={
            selectedReplyMsg?.uuid ? true : false
          }
          memberList={
            conversationData?.groupConversation?.groupMembers ||
            conversationData?.conversationInbox?.inboxMembers ||
            []
          }
          searchString={text}
          onMemberSelection={onUserAgentSelection}
          hideCannedResponse={hideCannedResponse}
          selectedTab={messageType}
          inboxId={conversationData?.inboxId}
          conversationUuid={conversationData?.uuid}
          memberListAggergateCount={
            conversationData?.groupConversation?.groupMembers_aggregate?.aggregate?.count ||
            conversationData?.conversationInbox?.inboxMembers_aggregate?.aggregate?.count
          }
          onActionPerformed={props?.onMentionActionPerformed}
        />
      );
    }

    return listElem;
  };
  const onDrawerActionPerformed = (actionCode: string) => {
    switch (actionCode) {
      case CONVERSATION_ACTION_CODES.SEND_ATTACHMENT:
        setFileList([]);
        setIsDrawerVisible(false);
        if (editorRef.current) {
          const inst = editorRef.current
          inst.reset();
        }
        break;
      case CONVERSATION_ACTION_CODES.DRAWER_CLOSE:
        setFileList([]);
        setIsDrawerVisible(false);
        break;
    }
  };

  const getAttachment = () => {
    const currentStatus = isActiveContact(contactData);
    if (!currentStatus) {
      return <View></View>;
    }
    if (
      !isChannelTwillioSms(inboxTypeFromConversation || '') &&
      !isChannelEmail(inboxTypeFromConversation)
    ) {
      return (
          <FHTooltip text='Attachment' position='top'>
            <Upload
              style={{background: ''}}
              multiple={true}
              beforeUpload={() => false}
              maxCount={10}
              onChange={(info) => {
                const tempFileList = [...info.fileList];
                setFileList(tempFileList);
                setIsDrawerVisible(true);
              }}
              fileList={[]}
              className="msg-attachment"
              data-testId={TestIdentifiers.attachmentBtn}
            >
              {conversationData?.groupConversation?.groupType?.code ===
                GROUP_TYPES.USER_BROADCAST ||
                conversationData?.groupConversation?.groupType?.code ===
                GROUP_TYPES.INTERNAL ? (
                  <View size={18} {...testID('image')}>
                    <BrowseAttachmentSvg defaultColor={Colors.FoldPixel.GRAY300} />
                  </View>
              ) : (
                <>
                  <View size={18} {...testID('image')}>
                    <BrowseAttachmentSvg defaultColor={Colors.FoldPixel.GRAY300} />
                  </View>
                </>
              )}
            </Upload>
          </FHTooltip>
      );
    } else {
      return <View></View>;
    }
  };

  const getBrowseFormsElem = () => {
    return (
      <FHTooltip text="Forms" position='top'>
        <div className='pressable'
          onClick={() => {
            const currentStatus = isActiveContact(contactData);
            if (!currentStatus) {
              const message = getInActiveContactError(contactData);
              notification.error({
                message,
              });
              return;
            }
            setFormDrawer(true);
          }}
          {...testID('BrowseFormBtn')}
        >
          <View size={18} {...testID('image')}>
            <FormSvg defaultColor={Colors.FoldPixel.GRAY300} />
          </View>
        </div>
      </FHTooltip>
    );
  };

  const getMediaAttachmentElem = () => {
    return (
      <FHTooltip text="Media" position='top'>
        <div className='pressable'
          onClick={() => {
            const currentStatus = isActiveContact(contactData);
            if (!currentStatus) {
              const message = getInActiveContactError(contactData);
              notification.error({
                message,
              });
              return;
            }
            setShowMediaDrawer(true);
          }}
          {...testID('BrowseMediaBtn')}
        >
          <View size={18} {...testID('image')}>
            <BrowseMediaSvg defaultColor={Colors.FoldPixel.GRAY300}/>
          </View>
        </div>
      </FHTooltip>
    );
  };

  const getBrowseArticlesElem = () => {
    return (
      <FHTooltip text="Articles" position='top'>
        <div className='pressable'
          onClick={() => {
            const currentStatus = isActiveContact(contactData);
            if (!currentStatus) {
              const message = getInActiveContactError(contactData);
              notification.error({
                message,
              });
              return;
            }
            setArticleDrawer(true);
          }}
          {...testID('BrowseArticleBtn')}
        >
          <View size={18} {...testID('image')}>
            <EducationContent
              defaultColor={Colors.FoldPixel.GRAY300}
            />
          </View>
        </div>
      </FHTooltip>
    );
  };

  const getSendAppointmentLinkElem = () => {
    return (
      <FHTooltip text="Appointment Link" position='top'>
        <div className='pressable'
          onClick={() => {
            const currentStatus = isActiveContact(contactData);
            if (!currentStatus) {
              const message = getInActiveContactError(contactData);
              notification.error({
                message,
              });
              return;
            }
            setAppointmentDrawer(true);
          }}
          {...testID('SendAppointmentLinkBtn')}
        >
          <View size={18} {...testID('image')}>
            <CalendarSvg defaultColor={Colors.FoldPixel.GRAY300} />
          </View>
        </div>
      </FHTooltip>
    );
  };

  const getBrowseContact = () => {
    if (!isEmployerRole()) {
      return (
        <FHTooltip text="Contacts" position='top'>
          <div className='pressable'
            onClick={() => {
              const currentStatus = isActiveContact(contactData);
              if (!isInternalChannelInboxType(selectedInboxTypeCode || '')
                && !currentStatus) {
                const message = getInActiveContactError(contactData);
                notification.error({
                  message,
                });
                return;
              }
              setContactDrawer(true);
            }}
            {...testID('BrowseContactBtn')}
          >
            <View size={18} {...testID('image')}>
              <BrowseContactSvg defaultColor={Colors.FoldPixel.GRAY300} />
            </View>
          </div>
        </FHTooltip>
      );
    } else {
      return <View></View>;
    }
  };

  const getScheduleMessage = () => {
    return (
      <View style={styles.scheduleMessageContainer} paddingRight={5}>
        <FHTooltip text="Scheduled" position='top'>
          <div className='pressable'
            onClick={() => {
              setScheduleMessageDrawer(true);
            }}
            {...testID(TestIdentifiers.scheduleButton)}
          >
            <View size={18} {...testID('image')}>
              <BrowseScheduleSvg defaultColor={Colors.FoldPixel.GRAY300}/>
            </View>
          </div>
        </FHTooltip>
        <View style={styles.scheduleMessageCountView}>
          <Text {...testID('TotalScheduleMessage')} size={'smMedium'}>
            {totalScheduleMessage}
          </Text>
        </View>
        <View style={styles.scheduleMessageFailedCountView}>
          <InfoSvg customColor={Colors.error?.[600]} customDimension='12' />
          <Text {...testID('TotalScheduleFailedMessage')} style={styles.scheduleMessageFailedText} size={'smMedium'} color={Colors.error?.[600]}>
            {totalScheduleFailedMessage}
          </Text>
        </View>
      </View>
    );
  };

  const getSendMsgOptionElemFromCode = (singleMsgOptionCode: string) => {
    switch (singleMsgOptionCode) {
      case MSG_SEND_OPTIONS.BROWSE_ATTACHMENTS:
        return getAttachment();
      case MSG_SEND_OPTIONS.BROWSE_FORMS:
        return getBrowseFormsElem();
      case MSG_SEND_OPTIONS.BROWSE_ARTICLES:
        return getBrowseArticlesElem();
      case MSG_SEND_OPTIONS.SEND_APPOINTMENT_LINK:
        return getSendAppointmentLinkElem();
      case MSG_SEND_OPTIONS.BROWSE_CONTACT:
        return getBrowseContact();
      case MSG_SEND_OPTIONS.MEDIA_ATTACHMENTS:
        return getMediaAttachmentElem();
      case MSG_SEND_OPTIONS.SCHEDULE_MESSAGE:
        if (isScheduledSendEnabled && typingStatusRef.current.privateNote == 'false') {
          return getScheduleMessage();
        }
    }
  };

  const getAttachmentContentElem = useMemo(() => {
    let sendMsgOptionList = getSendMsgOptionList(
      selectedInboxTypeCode || '',
      conversationData,
      conversationInbox
    );
    if (!isFormAccess) {
      sendMsgOptionList = sendMsgOptionList.filter(
        (option: string) => option !== MSG_SEND_OPTIONS.BROWSE_FORMS
      );
    }
    if (!isAppointmenetAccess) {
      sendMsgOptionList = sendMsgOptionList.filter(
        (option: string) => option !== MSG_SEND_OPTIONS.SEND_APPOINTMENT_LINK
      );
    }
    if (sendMsgOptionList.length > 0) {
      return (
        <HStack alignItems={'center'} justifyContent={'center'}>
          {sendMsgOptionList.map((singleMsgOptionCode) => {
            return (
              <View justifyContent={'center'} alignItems={'center'} key={`sendMsgOptionList_${singleMsgOptionCode}`} ml={4}>
                {getSendMsgOptionElemFromCode(singleMsgOptionCode)}
              </View>
            )
          })}
        </HStack>
      );
    }
  }, [totalScheduleMessage]);

  const onMsgReplyActionPerformed = (actionCode: string, actionData: {
    content: string,
    cursorPosition: number,
  }) => {
    switch (actionCode) {
      case CONVERSATION_ACTION_CODES.DRAWER_CLOSE:
        setIsMsgReplyDrawerVisible(false);
        setRichTextValue(actionData?.content);
        if (editorRef?.current) {
          const inst = editorRef.current
          inst.setMarkdown(actionData?.content);
          if (actionData?.cursorPosition !== -1) {
            inst.setSelection(actionData?.cursorPosition,actionData?.cursorPosition);
          }
        }
        break;
    }
  };

  const toShowPhiWarning = () => {
    if (conversationData?.conversationInbox?.channelType) {
      const type = conversationData?.conversationInbox?.channelType || '';
      if (
        type === CHANNEL_TYPE.CHANNEL_EMAIL &&
        messageType !== 'internalMessage'
      ) {
        return true;
      } else if (
        type === CHANNEL_TYPE.CHANNEL_TWILIO_SMS &&
        messageType !== 'internalMessage'
      ) {
        return true;
      } else if (
        type === CHANNEL_TYPE.CHANNEL_WEB_WIDGET &&
        messageType !== 'internalMessage'
      ) {
        return true;
      } else if (type === CHANNEL_TYPE_CODE.CHANNEL_MENTION) {
        return true
      }
      return false;
    } else {
      if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION) {
        return true
      }
      if (contactData?.source) {
        if (contactData?.source === 'WEB_WIDGET') {
          return true;
        }
        return false;
      }
      return false;
    }
  };

  const onViewChangeActionPerformed = (
    actionCode: string  ): any => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.EDIT:
        setCode(RIGHT_SIDE_CONTAINER_CODE.ADD_FAMILY_MEMBERS);
        break;
      case COMMON_ACTION_CODES.CANCEL:
        setContactDrawer(false);
        setScheduleMessageDrawer(false);
        setBrowseContactUUID('');
        break;
      default:
        setContactDrawer(false);
        setBrowseContactUUID('');
        break;
    }
  };

  const onClose = () => {
    setShowSendMessageDisable(false);
  }


  const setLoadingFalse = () => {
    setLoading(false);
    setShowSchedulePopover(false);
  }

  const scheduleMessage = async (message: string, dateString: string) => {
    let body = {} as IScheduleMessageParams
    body = await getScheduleMessageObj(message, dateString)
    if (body?.workflowEventId) {
      const scheduleMessageResponse = await scheduleMessageApi(body);
      await removeConversationMessage(conversationData?.uuid, MESSAGE_DRAFT_TYPES.REPLY_DRAFT);
      if (scheduleMessageResponse?.data?.id) {
        setLoadingFalse();
        showToast(
          msgToast,
          intl.formatMessage({id: 'scheduleSuccess'}),
          ToastType.success
        );
        setTotalScheduleMessage(totalScheduleMessage + 1)
      } else {
        setLoadingFalse()
        showToast(
          msgToast,
          intl.formatMessage({id: 'apiErrorMsg'}),
          ToastType.error
        );
      }
    } else {
      setLoadingFalse()
      showToast(
        msgToast,
        intl.formatMessage({id: 'apiErrorMsg'}),
        ToastType.error
      );
    }
  }

  const onOk = (value: DatePickerProps['value']) => {
    const dateString = moment.utc(value).format();
    const currentTime = moment(new Date());
    const duration = moment.duration(currentTime.diff(dateString));
    if (duration.asMilliseconds() >= timeGreaterThan) {
      showToast(
        msgToast,
        intl.formatMessage({id: 'selectTimeInFuture'}),
        ToastType.error
      );
      setLoadingFalse()
      return;
    }
    const parentClassName = parentCode ? `.${getClassNameForFooter()}` : '';
    const editorHtmlElem: any = document?.querySelector(
      `.messagewindow-editor${parentClassName} .ProseMirror.toastui-editor-contents`
    );
    if (editorHtmlElem && editorHtmlElem.innerHTML) {
      resetMessageWindowHeight();
      editorHtmlElem.innerHTML = '';
      if (editorHtmlElem.focus) {
        editorHtmlElem.focus();
      }
    }
    if (
      !contactDeactivationStatus ||
      typingStatusRef.current.privateNote == 'true'
    ) {
      setLoading(true);
      setRichTextValue('');
      let plainString = '';
      if (isWebChannelInboxType(selectedInboxTypeCode || '')) {
        if (editorRef.current) {
          const inst = editorRef.current
          plainString = inst.getMarkdown();
        }
      } else {
        plainString = richTextValue;
      }
      const messagePlainStr = parseHtmlToPlainText(plainString.trim());
      if (messagePlainStr && messagePlainStr.trim().length > 0) {
        scheduleMessage(messagePlainStr.replace(/\\/g, ''), dateString)
      }
      setShowSchedulePopover(false);
    } else {
      setShowSendMessageDisable(true);
      setShowSchedulePopover(false);
      setLoading(false)
    }
  };
  const datePickerRef: any = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (datePickerRef?.current) {
        if (datePickerRef?.current && !datePickerRef?.current?.contains?.(event?.target)) {
          if (showSchedulePopover) {
            setShowSchedulePopover(true);
          }
        }
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
      toggleTypingStatusApi('off');
      if (typingStatusRef?.current?.typingIntervalFn) {
        clearTimeout(typingStatusRef?.current?.typingIntervalFn);
      }
      if (datePickerRef.current) {
        datePickerRef.current = null;
      }
    };
  }, []);

  const shouldShowPhiInfo = ()=> {
    return showPhiWarning && toShowPhiWarning();
  }

  useEffect(()=> {
    LocalStorage.getItem(LOCAL_STORAGE_CONST.phiWarning).then((res) => {
      const data = JSON.parse(res);
      const phiInfoRead = data?.phiWarningRead;
      setShowPhiWarning(phiInfoRead ? false : true);
    })
  },[conversationData.id, selectedInboxTypeCode])

  useEffect(() => {
    props?.onCallBackEditorInputHeight?.(COMMON_ACTION_CODES.PHI_WARNING, {
      showPhiWarning: shouldShowPhiInfo(),
    });
  }, [showPhiWarning]);

  const datePickerFooter = () => {
    return (
      <View position={'absolute'}>
        <FoldButton
          nativeProps={{
            variant: BUTTON_TYPE.PRIMARY,
            onPress: () => {
              setShowSchedulePopover(false)
            },
            borderWidth: 0
          }}
          customProps={{
            btnText: intl.formatMessage({id: 'cancel'}),
          }}
        ></FoldButton>
      </View>
    )
  }

  const hideText = () => {
    if (isDetailsContainerVisible || isSeachMsgContainerVisible || parentCode === PARENT_CODE.MESSAGING_DETAIL_PANEL || isInstantChatView || width < minWidth) {
      return false;
    }
    return true;
  }
  const debouncedSaveConversationMessage = useCallback(
    debounce(() => {
      saveConversationMessageInMessageStorage();
    }, 500), []);

    const debouncedTextChange = useCallback(
      debounce(() => {
        const inst = editorRef.current;
        const plainString: string = (inst && inst.getMarkdown()) || '';

        // const currentText = parseHtmlToPlainText(plainString) || '';



        setRichTextValue(plainString);

        if (plainString.length < 2) {
          onMsgChangeText?.(plainString);
        }
        if (plainString.length > 0) {
          toggleTypingStatus();
        }
      }, 500),
      []
    );

  const calculateTextBoxHeight = () => {

    const parentClassName = parentCode ? `.${getClassNameForFooter()}` : '';
    const editorHtmlElemList: any = document?.querySelectorAll(
      `.messagewindow-editor${parentClassName} .ProseMirror.toastui-editor-contents`
    );
    editorHtmlElemList[0]?.setAttribute('data-testid', TestIdentifiers.messageFooterInput);

    const editorInputHeight = editorHtmlElemList[0]?.clientHeight;
    // let messageText = '';
    // if (editorRef.current) {
    //   const inst = editorRef.current
    //   messageText = inst.getMarkdown();
    // }
    if (editorInputHeight < contentMaxHeight) {
      if (editorInputHeight > contentMinHeight) {
        const updatedValue = editorInputHeight - editorInputHightState
        setEditorInputHeightState(editorInputHeight)
        updateHeight(editorInputHeight);
        props?.onCallBackEditorInputHeight?.(COMMON_ACTION_CODES.UPDATE, updatedValue)
      } else if (editorInputHeight === contentMinHeight) {
        setEditorInputHeightState(contentMinHeight)
        props?.onCallBackEditorInputHeight?.(COMMON_ACTION_CODES.RESET)
      }
    }
    if (editorRef.current) {
      debouncedSaveConversationMessage();
    }
  }

  const updateHeight = (editorInputHeight: number) => {
    setEditorInputHeightState(editorInputHeight)
  };

  const getOtherClassName = () => {
    if (hideText()) {
      if (!isInternalChannelInboxType(selectedInboxTypeCode || '')) {
        return 'messagewindow-editor-toolbar-without-collapse'
      }
    } else {
      if (isInternalChannelInboxType(selectedInboxTypeCode || '')) {
        return 'messagewindow-editor-toolbar-internal-collapse'
      } else {
        return 'messagewindow-editor-toolbar-other-collapse'
      }
    }
  }

  const handleOnEnterActionEvent = (actionData: IOnEnterActionData) => {
    if (actionData?.parentCode && actionData?.parentCode !== parentCode) {
      const actionCode = actionData?.isNewLineOnEnter
        ? ON_PRESS_ENTER_ACTION_CODES.NEW_LINE
        : ON_PRESS_ENTER_ACTION_CODES.SEND_MESSAGE;
      let intlMessageCode = 'pressEnterToSendTheMessage';
      if (actionCode === ON_PRESS_ENTER_ACTION_CODES.NEW_LINE) {
        intlMessageCode = 'pressEnterToStartANewLine';
      }
      setOnEnterActionMsgFooterText(intl.formatMessage({
        id: intlMessageCode,
      }));
      setOnSelectedEnterNewLine(actionData?.isNewLineOnEnter);
    }
  };
  const handleOnEnterActionFooterText = (e: RadioChangeEvent) => {
    if (e.target.value === ON_PRESS_ENTER_ACTION_CODES.SEND_MESSAGE) {
      setOnEnterActionMsgFooterText(intl.formatMessage({
        id: 'pressEnterToSendTheMessage',
      }));
      setOnSelectedEnterNewLine(false);
      setIsShowOnEnterActionPopup(false);
      onActionMsgFooterPerformed(ON_PRESS_ENTER_ACTION_CODES.SEND_MESSAGE, {
        isArchiveSendChecked: isArchiveOnMessageSend,
        isNewLineOnEnter: false,
      });
    } else if (e.target.value === ON_PRESS_ENTER_ACTION_CODES.NEW_LINE) {
      setOnEnterActionMsgFooterText(intl.formatMessage({
        id: 'pressEnterToStartANewLine',
      }));
      setOnSelectedEnterNewLine(true);
      setIsShowOnEnterActionPopup(false);
      onActionMsgFooterPerformed(ON_PRESS_ENTER_ACTION_CODES.NEW_LINE, {
        isArchiveSendChecked: isArchiveOnMessageSend,
        isNewLineOnEnter: true,
      });
    }
  };
  const handleOnEnterActionChangePopup = () => {
    setIsShowOnEnterActionPopup(
      !isShowOnEnterActionPopup
    );
  };

  const concateArticleMessage = (articleLink: string) => {
    const updatedText = getUpdatedMessage(articleLink);

    setArticleLink(articleLink);
    setArticleDrawer(false);
    setRichTextValue(updatedText);

    if (editorRef?.current) {
      const inst = editorRef.current
      inst.setMarkdown(updatedText);
    }
  };

  const concateFormLinkMessage = (formLink: string) => {
    const updatedText = getUpdatedMessage(formLink);

    setFormLink(formLink);
    setRichTextValue(updatedText);
    setFormDrawer(false);
    setSelectedReplyMsg({} as IReplyMessageObject);

    if (editorRef?.current) {
      const inst = editorRef.current
      inst.setMarkdown(updatedText);
    }
  };

  const getUpdatedMessage = (messageText: string) => {
    let updatedText = richTextValue;
    if (editorRef?.current) {
      const inst = editorRef.current
      updatedText = inst.getMarkdown();
    }
    updatedText += '\n' + messageText;

    return updatedText;
  };

  const handleBeforeImageUpload = () => {
    // Prevent image from being uploaded
    return false;
  };

  const getCurrentMessageText = () => {
    let currentMsg = richTextValue;
    if (editorRef?.current) {
      const inst = editorRef.current
      currentMsg = inst?.getMarkdown?.();
    }
    const finalMsgText = parseHtmlToPlainText(currentMsg);
    return finalMsgText;
  }


  const isArrowKey = (keyCode:number) => [37, 38, 39, 40].includes(keyCode);
  const isEscapeOrSpaceKey = (keyCode:number) => [27, 32].includes(keyCode);
  const isEnterKey = (keyCode:number) => keyCode === 13;
  const isShiftEnter = (event:any) => event.shiftKey && event.key === KEY_PRESS_CODES.ENTER;
  const isArrowOrEnter = (event:any) =>
    [KEY_PRESS_CODES.ARROW_UP, KEY_PRESS_CODES.ARROW_DOWN, KEY_PRESS_CODES.ENTER].includes(event.key);
  const isAtSignKey = (keyCode:number, event: any) => keyCode === 50 && event.key !== '2';



 const [isLastActionSendMessage, setIsLastActionSendMessage] = useState(false);
 const onKeydown = (editorType: string, event: any) => {
   setIsLastActionSendMessage(false);
   const keyCode: any = event.which || event.keyCode;
   // Helper functions to improve readability and maintainability

   // Handle arrow keys when the agent list is visible
   if (isArrowKey(keyCode) && showAgentList) {
     event.preventDefault();
     event.stopPropagation();
     return;
   }

   // Handle escape and space keys
   if (isEscapeOrSpaceKey(keyCode)) {
     isDisplayUserAgents(false);
   }

   // Handle canned responses display logic
   if (isDisplayCannedResponses() && isArrowOrEnter(event)) {
     event.preventDefault();
     return;
   }

   // Handle "@" key logic for displaying user agents
   if (isAtSignKey(keyCode, event)) {
     handleAtSignLogic();
     return;
   }

   if (!editorRef?.current) return;

   // Perform operations that depend on the editor's content
   const inst = editorRef.current;
   const plainString = inst.getMarkdown().trim();
   const messagePlainStr = parseHtmlToPlainText(plainString).trim();

   if (onSelectedEnterNewLine) {
     if (isShiftEnter(event)) {
       handleSendMessage();
       setIsLastActionSendMessage(true);
       return;
     }
     if (event.key === KEY_PRESS_CODES.ENTER) {
       event.preventDefault();
     }
     return;
   }

   if (
     isEnterKey(keyCode) &&
     !event.shiftKey &&
     !showAgentList &&
     messagePlainStr
   ) {
     event.preventDefault();
     event.stopPropagation();
     handleSendMessage();
     setIsLastActionSendMessage(true);

     inst.reset();
     inst.setMarkdown('');
     return;
   }

   // Recheck canned responses (if needed again, which is unlikely here)
   if (isDisplayCannedResponses() && isArrowOrEnter(event)) {
     event.preventDefault();
   }
 };

  const handleAtSignLogic = () => {
    if (
      props.enableShowAccountNameOnlyToPatient &&
      typingStatusRef.current.privateNote !== 'internalMessage' &&
      !isInternalChat(conversationData)
    ) {
      isDisplayUserAgents(false);
      return;
    }
    isDisplayUserAgents(true);
  };

  const onSwitchChange = useCallback(
    (switchStatus: boolean) => {
      const tabCode = switchStatus ? 'internalMessage' : 'reply';

      if (selectedReplyMsg?.private) {
        notification.error({
          message: intl.formatMessage({
            id: 'switchToReplyAlert',
          }),
          duration: 3,
          closeIcon: (
            <Countdown
              valueStyle={{
                fontSize: 12,
              }}
              value={Date.now() + 4000}
              format="s"
            />
          ),
          placement: 'topRight',
          style: {
            borderRadius: 8,
          },
        });
        return;
      }
      let isInternalMessage = false;
      setMessageType(tabCode);
      if (tabCode === 'reply' && messageType !== 'reply') {
        let formattedText = richTextValue;
        if (
          props.selectedInboxTypeCode == CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS
        ) {
          formattedText = getFilteredMentionMessage(formattedText);
        }
        if (editorRef?.current) {
          const inst = editorRef.current;
          inst.setMarkdown(formattedText);
        }
        setRichTextValue(formattedText);
        typingStatusRef.current.privateNote = 'false';
        setShowAgentList(false);
      } else if (
        tabCode === 'internalMessage' &&
        messageType !== 'internalMessage'
      ) {
        isInternalMessage = true;
        typingStatusRef.current.privateNote = 'true'
        setShowAgentList(false);
      }
    },
    [
      selectedReplyMsg.private,
      messageType,
      richTextValue,
      props.selectedInboxTypeCode,
    ]
  );

  useEffect(() => {
    const enterActionMsgText = onEnterNewLineValue
      ? 'pressEnterToStartANewLine'
      : 'pressEnterToSendTheMessage';

    setOnSelectedEnterNewLine(onEnterNewLineValue);
    setOnEnterActionMsgFooterText(intl.formatMessage({id: enterActionMsgText}));
  }, [onEnterNewLineValue]);
  
  return (
    <>
      {shouldShowPhiInfo() && (
        <View ref={phiContainerRef} width={'100%'}>
          <PhiInfo
            isMentionTab={
              selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION
            }
            onClose={() => {
              setShowPhiWarning(false);
              LocalStorage.setItem(
                LOCAL_STORAGE_CONST.phiWarning,
                JSON.stringify({phiWarningRead: true})
              );
            }}
          />
        </View>
      )}
      <View>
        {true ||
          !isActiveContact(props.contactData) ||
          (!!!isMemberExist?.id && props.isInDrawerView && (
            <>
              <Content
                className={'editor-inactive'}
                onClick={(e) => {
                  e.preventDefault();
                  const currentStatus = isActiveContact(contactData);
                  if (!currentStatus) {
                    const message = getInActiveContactError(contactData);
                    notification.error({
                      message,
                    });
                    return;
                  }
                }}
                data-testid={TestIdentifiers.contentViewer}
              ></Content>
            </>
          ))}

        {isLoginUserDeletedFromInbox(props?.conversationData, userData) && (
          <>
            <Content
              className={'editor-inactive'}
              onClick={(e) => {
                e.preventDefault();
                const currentStatus = isActiveContact(contactData);
                if (!currentStatus) {
                  const message = getInActiveContactError(contactData);
                  notification.error({
                    message,
                  });
                  return;
                }
              }}
              data-testid={TestIdentifiers.contentViewer}
            ></Content>
          </>
        )}

        <VStack style={styles.msgFooterStyle}>
          {isDisplayCannedResponses() ? (
            <CannedResponses
              isReplyMessage={
                selectedReplyMsg?.uuid
                  ? true
                  : false
              }
              categoryCode={categoryCode}
              searchString={getSearchStringForCannedResponseEditor(editorRef)}
              onCannedResponsesSelection={(
                cannedResponseData: ICannedResponses
              ) => {
                const finalText = `${cannedResponseData.content}${(
                  richTextValue || ''
                ).replace(
                  getSearchStringForCannedResponseEditor(editorRef),
                  ''
                )}`;

                setRichTextValue(finalText || '');
                if (editorRef.current) {
                  const inst = editorRef.current
                  const currentText = inst.getMarkdown();
                  const finalText =
                    `${cannedResponseData.content}${currentText}`.replace(
                      getSearchStringForCannedResponseEditor(editorRef),
                      ''
                    );
                  inst.setMarkdown(finalText);
                  inst.moveCursorToStart(true);
                }
              }}
              parentCode={PARENT_CODE.MESSAGING_WINDOW_FOOTER}
            />
          ) : (
            <View></View>
          )}
          {showAgentList ? getUserListElem() : <></>}
          {selectedReplyMsg.uuid ? (
            <ReplyMessageView
              isCannedOpen={isDisplayCannedResponses() || false}
              onClearReplyMessage={() => {
                setSelectedReplyMsg({} as IReplyMessageObject);
                props?.onActionMsgFooterPerformed && props?.onActionMsgFooterPerformed(CONVERSATION_ACTION_CODES.REPLY_DESELECT)
              }}
              selectedReply={selectedReplyMsg}
            />
          ) : (
            <></>
          )}
          <View
            style={styles.divider}
          ></View>
          <VStack padding="16px" paddingBottom={'0px'} paddingTop={'16px'}>
            <HStack marginBottom={'12px'}>
              {GroupTypeIs(conversationData) ? (
                <View
                  flex={0.5}
                  style={styles.replyTypeSwitchView}
                >
                  <Switch
                    className={`${messageType === 'reply' ? 'reply-type-switch-reply' : 'reply-type-switch-internal'}`}
                    disabled={!isShowReplyTab()}
                    checked={
                      messageType !== 'reply'
                    }
                    onChange={onSwitchChange}
                  />
                  <Text
                    marginLeft={'8px'}
                    marginRight={'4px'}
                    fontWeight={400}
                    color={Colors.Custom.Gray400}
                  >
                    Internal
                  </Text>

                  {!isShowReplyTab() && (
                    <FHTooltip
                      text="Please note that SMS functionality is not available for toll-free numbers"
                      position="top"
                    >
                      <View style={styles.infoIconView}>
                        <Feather
                          name="info"
                          style={styles.infoIcon}
                          {...testID('image')}
                        />
                      </View>
                    </FHTooltip>
                  )}
                </View>
              ) : (
                <View flex={0.5}></View>
              )}

              <HStack
                flex={0.5}
                justifyContent={'flex-end'}
                alignItems={'center'}
                flexWrap={'wrap'}
                // marginLeft={getMarginForToolbarItems()}
              >
                {getAttachmentContentElem}
                {!isInDrawerView && (
                  <VStack ml={-1} mt={0.5} alignItems={'center'}>
                    <FHTooltip text="Expand Reply" position="left">
                      <div className='pressable'
                        onClick={() => {
                          if (
                            !contactDeactivationStatus ||
                            typingStatusRef.current.privateNote == 'true'
                          ) {
                            setIsMsgReplyDrawerVisible(true);
                          } else {
                            setShowSendMessageDisable(true);
                          }
                        }}
                        {...testID('ExpandBtn')}
                      >
                        <View size={18} {...testID('image')}>
                          <MessageReplySvg
                            defaultColor={Colors.FoldPixel.GRAY300}
                          />
                        </View>
                      </div>
                    </FHTooltip>
                  </VStack>
                )}
              </HStack>
            </HStack>
            <HStack
              height={editorInputHightState + 10 + 'px'}
              style={styles.editorHStack}
              space={1}
            >
              <View
                style={styles.editorView}
                nativeID="toastui-editor-toolbar-height"
              >
                <Content
                  className={`messagewindow-editor ${getOtherClassName()} ${
                    messageType !== 'reply'
                      ? 'internalMessage-editor'
                      : ''
                  } ${getClassNameForFooter()}`}
                  data-testid={'EditorContent'}
                >
                  <CustomEditor
                    initialValue={props?.conversationDraftMessage || ''}// height calculation is not working properly for drawer setting text in setConversationSavedText this method
                    previewStyle="vertical"
                    height={`${contentMaxHeight}px`}
                    initialEditType="wysiwyg"
                    useCommandShortcut={true}
                    previewHighlight={false}
                    customRef={editorRef}
                    hooks={{
                      addImageBlobHook: handleBeforeImageUpload
                    }}
                    toolbarItems={
                      hideEditorToolbar
                        ? [[]]
                        : !isChannelTwillioSms(inboxTypeFromConversation || '')
                        ? [['heading', 'bold', 'italic', 'strike', 'link']]
                        : [[]]
                    }
                    autofocus={false}
                    onKeydown={onKeydown}
                    onChange={() => {
                      setIsLastActionSendMessage((prev)=>{
                        if(!prev) {
                          calculateTextBoxHeight();
                        }
                        return false;
                      })


                      if (editorRef && editorRef?.current) {
                          const inst = editorRef.current;
                          const plainString: string = (inst && inst.getMarkdown()) || '';


                          const includeAt = plainString?.slice(plainString.lastIndexOf('@'));
                          if (!plainString.length) {
                            setShowAgentList(false);
                          } else if (!includeAt.trim().includes('@')) {
                            setShowAgentList(false);
                          }
                          previousRichTextValue.current = plainString;
                          debouncedTextChange()



                      }
                      // if (keyCode === 13 && !event.shiftKey) {
                      //   event.preventDefault();
                      //   event.stopPropagation();
                      // }
                    }}
                    data-testid={TestIdentifiers.messageFooterInput}
                  />
                </Content>
              </View>

              <VStack style={styles.sendBtnVStack} justifyContent={'center'} mb={2}>
                <View
                  height={46}
                  width={'100%'}
                  alignSelf={'flex-start'}
                  justifySelf={'center'}
                  justifyContent={'center'}
                  alignContent={'center'}
                  alignItems={'center'}
                  borderRadius={6}
                  borderWidth={1}
                  backgroundColor={
                    messageType !== 'reply'
                      ? Colors.secondary['100']
                      : Colors.Custom.PrimaryColor100
                  }
                  borderColor={
                    messageType !== 'reply'
                      ? Colors.secondary['500']
                      : Colors.Custom.PrimaryColor200
                  }
                >
                  <HStack
                    justifyContent={'center'}
                    height={42}
                    width={'100%'}
                  >
                    <div
                        className={`pressable send-button ${
                          isScheduledSendEnabled
                            ? hideText()
                              ? 'send-button-flex-07'
                              : 'send-button-flex-05'
                            : ''
                        }`}
                      onClick={() => {
                        if (
                          !contactDeactivationStatus ||
                          typingStatusRef.current.privateNote == 'true'
                        ) {
                          handleSendMessage();
                        } else {
                          setShowSendMessageDisable(true);
                        }
                      }}
                      data-testid={TestIdentifiers.messageSendBtn}
                    >
                    <HStack
                        alignItems={'center'}
                        justifyContent={'center'}
                        height={'100%'}
                        space={2}
                        borderRightWidth={isScheduledSendEnabled ? 1 : 0}
                        borderColor={
                          messageType !== 'reply'
                            ? Colors.secondary['500']
                            : Colors.Custom.PrimaryColor200
                        }
                      >
                        <View {...testID(TestIdentifiers.messageSendIcon)}>
                          <SendButtonSvg
                            isEnabled={true}
                            customStrokeColor={
                              messageType !==
                              'reply'
                                ? Colors.secondary['500']
                                : Colors.primary['300']
                            }
                          />
                        </View>
                        {hideText() && (
                          <Text
                            color={
                              messageType !==
                              'reply'
                                ? Colors.secondary['500']
                                : Colors.primary['300']
                            }
                            {...testID('Send')}
                          >
                            {'Send'}
                          </Text>
                        )}
                      </HStack>
                    </div>
                    {isScheduledSendEnabled ?
                    <View
                      flex={hideText() ? .3 : .5}
                      alignItems={'center'}
                      justifyContent={'center'}
                      height={'100%'}>
                      {isScheduledSendEnabled &&
                        showSchedulePopover && (
                          <View
                            ref={datePickerRef}
                            position={'absolute'}
                            {...testID(TestIdentifiers.scheduleDatePickerView)}
                          >
                            <DatePicker
                              className="popover-class"
                              open={
                                showSchedulePopover &&
                                !loading
                              }
                              showTime={{format: 'HH:mm'}}
                              renderExtraFooter={() => (
                                <>{datePickerFooter()}</>
                              )}
                              disabledDate={(current: any) => {
                                return current && isPastDay(current);
                              }}
                              onOk={onOk}
                              disabledTime={disabledDateTime}
                              locale={{
                                ...locale,
                                lang: {
                                  ...locale.lang,
                                  ok: 'Schedule Send',
                                },
                              }}
                              data-testid={TestIdentifiers.datePicker}
                            />
                          </View>
                        )}
                      {isScheduledSendEnabled && (
                        <HStack alignItems={'center'} justifyContent={'center'} height={'100%'}>
                            <div
                              className="schedule-button"
                              onClick={() => {
                                if (richTextValue?.length > 0) {
                                  setShowSchedulePopover(true);
                                }
                              }}
                              {...testID(TestIdentifiers.scheduleBtnClick)}
                            >
                              {!loading ? (
                                <View {...testID(TestIdentifiers.scheduleIcon)}>
                                  <SendScheduleMsgSvg
                                    isEnabled={true}
                                    customStrokeColor={
                                      richTextValue
                                        ?.length > 0
                                        ? messageType !==
                                          'reply'
                                          ? Colors.secondary['500']
                                          : Colors.primary['300']
                                        : messageType !==
                                          'reply'
                                        ? Colors.secondary['300']
                                        : Colors.primary['100']
                                    }
                                  />
                                </View>
                              ) : (
                                <View
                                  position={'absolute'}
                                  {...testID(TestIdentifiers.lazyLoading)}
                                >
                                  <Spinner accessibilityLabel="Loading posts" />
                                </View>
                              )}
                            </div>
                        </HStack>
                      )}
                    </View>
                    : <></>}
                  </HStack>
                </View>
              </VStack>
            </HStack>
            <HStack alignItems={'center'} flex={1}>
              <View style={styles.preferenceContainer}>
                <Content>
                  <View style={styles.preferenceView}>
                    <div className='pressable'
                      onClick={handleOnEnterActionChangePopup}>
                      <View style={styles.preferenceView}>
                        <Text
                          {...testID('OnEnterActionMsgFooterText')}
                          style={styles.preferenceText}
                        >
                          {
                            onEnterActionMsgFooterText
                          }
                          •
                        </Text>
                        <Popover
                          overlayInnerStyle={{
                            bottom: 3,
                            borderRadius: 16,
                            padding: 0,
                          }}
                          content={
                            <VStack>
                              <Text
                                style={styles.pressText}
                                fontFamily={'Manrope'}
                                letterSpacing={1}
                                fontSize={'12'}
                                {...testID('PressEnterTo')}
                              >
                                PRESS
                                <Text
                                  style={styles.pressEnterText}
                                  fontSize={'16'}
                                >
                                  Enter
                                </Text>
                                TO ...
                              </Text>
                              <Radio.Group
                                onChange={handleOnEnterActionFooterText}
                                className='message-footer-radio-group'
                                value={
                                  onSelectedEnterNewLine
                                    ? ON_PRESS_ENTER_ACTION_CODES.NEW_LINE
                                    : ON_PRESS_ENTER_ACTION_CODES.SEND_MESSAGE
                                }
                                data-testid={TestIdentifiers.radioGroup}
                              >
                                <Divider
                                  style={styles.dividerStyle}
                                  thickness={1.5}
                                />
                                <Radio
                                  value={
                                    ON_PRESS_ENTER_ACTION_CODES.SEND_MESSAGE
                                  }
                                  onClick={handleOnEnterActionChangePopup}
                                  className='message-footer-radio-group-item'
                                  data-testid={TestIdentifiers.radioButton}
                                >
                                  <Text
                                    {...testID('SendTheMessage')}
                                    style={styles.sendMessageText}
                                    fontSize={16}
                                  >
                                    {intl.formatMessage({id: 'sendTheMessage'})}
                                  </Text>
                                </Radio>
                                <Divider
                                  style={styles.dividerStyle2}
                                />
                                <Radio
                                  value={ON_PRESS_ENTER_ACTION_CODES.NEW_LINE}
                                  onClick={handleOnEnterActionChangePopup}
                                  className='message-footer-radio-group-item'
                                  data-testid={TestIdentifiers.radioButton}
                                >
                                  <Text
                                    {...testID('StartANewLine')}
                                    style={styles.startANewLineText}
                                    fontSize={16}
                                  >
                                    {intl.formatMessage({id: 'startANewLine'})}
                                  </Text>
                                </Radio>
                              </Radio.Group>
                            </VStack>
                          }
                          title=""
                          trigger="click"
                          placement={'bottomRight'}
                          visible={
                            isShowOnEnterActionPopup
                          }
                          onVisibleChange={() => {
                            setIsShowOnEnterActionPopup(
                              !isShowOnEnterActionPopup
                            );
                          }}
                        >
                          <Text
                            {...testID(TestIdentifiers.changeText)}
                            style={styles.changeText}
                          >
                            {intl.formatMessage({id: 'change'})}
                          </Text>
                        </Popover>
                      </View>
                    </div>
                  </View>
                </Content>
              </View>
              <View flex={1} />
              {(isArchiveEnable &&
              showArchiveOnSend) ? (
                <HStack justifyContent={'flex-end'} alignItems={'center'}>
                  <Checkbox
                    defaultChecked={isArchiveOnSend(props?.archiveOnSendData)}
                    checked={isArchiveOnSend(props?.archiveOnSendData)}
                    key={conversationData.id}
                    onChange={(item) => {
                      onActionMsgFooterPerformed(
                        COMMON_ACTION_CODES.CHANGE_ARCHIVE_ON_SEND,
                        {
                          isArchiveSendChecked: item?.target?.checked,
                        }
                      );
                      setIsArchiveOnMessageSend(item?.target?.checked);
                    }}
                    data-testid={TestIdentifiers.checkbox}
                  >
                    <FHTooltip text="Archive on send" position='top'>
                      <Text
                        fontSize={hideText() ? '12px' : 11}
                        fontWeight={600}
                        color={Colors.Custom.Gray400}
                        {...testID('ArchiveOnSend')}
                      >
                        Archive on send
                      </Text>
                    </FHTooltip>
                  </Checkbox>
                </HStack>
              ) : (
                <></>
              )}
            </HStack>
          </VStack>
        </VStack>

        {isDrawerVisible ?
        <AttachmentDrawer
          isDrawerVisible={isDrawerVisible}
          fileList={fileList}
          onDrawerActionPerformed={onDrawerActionPerformed}
          conversationData={conversationData}
          onMsgSend={onMsgSend}
          message={getCurrentMessageText()}
          privateNote={typingStatusRef.current.privateNote}
        />
        : <></>}

        {formDrawer ?
        <AttachFormDrawer
          conversationData={conversationData}
          onMsgSend={onMsgSend}
          isOpen={formDrawer}
          privateNote={typingStatusRef.current.privateNote}
          onClose={() => {
            setFormDrawer(false);
          }}
          onFormSelect={concateFormLinkMessage}
          updateMessage={concateFormLinkMessage}
          isUpdateMode
        />
        : <></>}

        {showMediaDrawer && (
          <MediaSelectionModal
            isOpen={showMediaDrawer}
            onClose={() => {
              setShowMediaDrawer(false);
            }}
            onSelect={async (mediaData) => {
              if (mediaData.url) {
                const shortLink = await getShortLink(mediaData.url);
                const updatedText = getUpdatedMessage(shortLink);

                if (editorRef?.current) {
                  const inst = editorRef.current
                  inst.setMarkdown(updatedText);
                }
                setRichTextValue(updatedText);
                setShowMediaDrawer(false);
                setSelectedReplyMsg({} as IReplyMessageObject);
              }
            }}
          />
        )}

        {articleDrawer && (
          <AttachArticleDrawer
            isDrawerVisible={articleDrawer}
            privateNote={typingStatusRef.current.privateNote}
            onClose={() => {
              setArticleDrawer(false);
            }}
            conversationData={conversationData}
            onMsgSend={onMsgSend}
            onArticleSelect={concateArticleMessage}
            updateMessage={concateArticleMessage}
            isUpdateMode
          />
        )}

        {isMsgReplyDrawerVisible && (
          <MsgReplyDrawer
            conversationData={props?.conversationData}
            conversationInbox={conversationInbox}
            onMsgSend={(
              msgText: string,
              msgData?: any,
              parentMessage?: IReplyMessageObject | undefined
            ) => {
              const currentTime = getDateToMomentISOString();
              const responseData = {...msgData, currentTime};
              props.onMsgSend(msgText, responseData, parentMessage);
              setIsMsgReplyDrawerVisible(false);
              if (editorRef.current) {
                const inst = editorRef.current
                inst.reset();
              }
            }}
            showMessageTypeTab={true}
            selectedTabCode={selectedTabCode}
            messageType={messageType}
            selectedInboxTypeCode={selectedInboxTypeCode}
            onActionMsgFooterPerformed={onActionMsgFooterPerformed}
            isDrawerVisible={isMsgReplyDrawerVisible}
            onMsgReplyActionPerformed={onMsgReplyActionPerformed}
            contactData={contactData}
            msgText={getPlainString()}
            isReplyMode
            onMentionActionPerformed={props?.onMentionActionPerformed}
          />
        )}

        {showSendMessageDisable ?
        <DeleteMessageModal
          titleMessage="deactivateMessageTxt"
          heading="Message"
          onConfirm={() => {
            setShowSendMessageDisable(false);
          }}
          onClose={onClose}
          isOpen={showSendMessageDisable}
        />
        : <></>}

        {appointmentDrawer && (
          <AttachAppointmentDrawer
            isOpen={appointmentDrawer}
            conversationData={conversationData}
            onClose={() => {
              setAppointmentDrawer(false);
            }}
            onSendMessage={(appointmentMessage: string) => {
              const updatedText = getUpdatedMessage(appointmentMessage);

              setRichTextValue(updatedText);
              setAppointmentDrawer(false);
              setSelectedReplyMsg({} as IReplyMessageObject);

              if (editorRef?.current) {
                const inst = editorRef.current
                inst.setMarkdown(updatedText);
              }
            }}
            isUpdateMode
          />
        )}

        {contactDrawer && (
          <ContactSearchDrawer
            onMsgSend={() => {
              sendContactCard(MESSAGE_CUSTOM_CARD_KEY.CONTACT_CARD);
            }}
            onFormCompleteAction={onViewChangeActionPerformed}
            onAutoCompleteChange={(action: any, data: any) => {
              setBrowseContactUUID(data);
            }}
          />
        )}

        {scheduleMessageDrawer && (
          <ScheduleMessageContainer
            onFormCompleteAction={onViewChangeActionPerformed}
            conversationUuid={conversationData?.uuid}
            handleSendMessage={handleSendMessage}
          />
        )}

        {code === RIGHT_SIDE_CONTAINER_CODE.ADD_FAMILY_MEMBERS && (
          <AddOrUpdateLead
            isShowAddContactTypeOption={true}
            personTypeUuid={getContactTypeId('CUSTOMER')}
            personType={'CUSTOMER'}
            singleLeadData={undefined}
            onFormCompleteAction={() => {
              onViewChangeActionPerformed(COMMON_ACTION_CODES.ADD);
            }}
            handleCloseModal={() => {
              setCode('');
              setContactDrawer(false);
            }}
            isShowModal={code === RIGHT_SIDE_CONTAINER_CODE.ADD_FAMILY_MEMBERS}
          />
        )}
      </View>
    </>
  );
};

export default React.memo(MessagingWindowFooter);
