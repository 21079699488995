import React from 'react';
import {Colors} from '../../../../styles';
import { Path, Svg } from 'react-native-svg';

const ArrowLeft2024 = ({color = Colors.FoldPixel.GRAY300}: any) => {
  return (
    <Svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <Path 
        d="M16.6668 10H3.3335M3.3335 10L8.3335 5M3.3335 10L8.3335 15" 
        stroke={color} 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default React.memo(ArrowLeft2024);