import {View, Text} from 'react-native';
import React from 'react';

const CheckIcon = (props: {fill?: string, stroke?: string}) => {
  const  {fill, stroke} = props
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill={fill} />
      <path
        d="M23.7573 10L13.0907 20.6667L8.24219 15.8182"
        stroke={stroke}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default CheckIcon;
