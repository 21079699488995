import format from 'string-format';
import { FREQUENCY_CODES } from '../../../../../constants/MlovConst';
import { WORKFLOW_INPUT_FIELD_KEYS } from '../../../../../constants/WorkflowNodeConst';
import { IUserInputField } from '../../FlowComponent/StateNodes/FlowNodeInterface';

enum templateVarCodes {
  ENTITY_NAME = 'entityName',
  FREQUENCY = 'frequency',
  START_TIME_AFTER = 'startTimeAfter',
  FOR_END_TIME = 'forEndTime',
  COMMUNICATION_CHANNEL = 'communicationChannel',
  GAP_BETWEEN = 'gapBetween',
  COUNT = 'count',
  WORKFLOW = 'workflow'
}

export const formatTemplate = (template: string | undefined, templateVarList: string[] | undefined, userInputFieldList: Array<IUserInputField> | undefined) => {
  if (!userInputFieldList || !userInputFieldList.length || !template || !templateVarList || !templateVarList.length) return (template || '');

  const templateVarValues: {
    [index: string]: string | undefined;
  } = {};

  for (let keyIndex = 0; keyIndex < templateVarList.length; keyIndex++) {
    const varKey = templateVarList[keyIndex];
    const value = getTemplateVarValue(varKey, userInputFieldList);

    if (value === undefined) {
      return undefined;
    }
    templateVarValues[varKey] = value;
  }

  return format(template, templateVarValues);
}

const getTemplateVarValue = (templateVar: string, userInputFieldList: Array<IUserInputField>) => {
  if (!templateVar || !userInputFieldList || !userInputFieldList.length) return undefined;

  switch (templateVar) {
    case templateVarCodes.WORKFLOW:
      const userInputFieldWorkflow = getInputFieldByKey(userInputFieldList, WORKFLOW_INPUT_FIELD_KEYS.WORKFLOW);
      return userInputFieldWorkflow?.value?.name
    case templateVarCodes.ENTITY_NAME:
      let userInputField = getInputFieldByKey(userInputFieldList, WORKFLOW_INPUT_FIELD_KEYS.ENTITY);
      if (!userInputField) userInputField = getInputFieldByKey(userInputFieldList, WORKFLOW_INPUT_FIELD_KEYS.TYPE);
      return getEntityTemplateVarValue(userInputField);
    case templateVarCodes.FREQUENCY:
      return getFrequencyTemplateVarValue(
        getInputFieldByKey(userInputFieldList, WORKFLOW_INPUT_FIELD_KEYS.FREQUENCY)
        || getInputFieldByKey(userInputFieldList, WORKFLOW_INPUT_FIELD_KEYS.FREQUENCY_OBJECT)
      );
    case templateVarCodes.START_TIME_AFTER:
      return getStartTimeAfterTemplateVarValue(getInputFieldByKey(userInputFieldList, WORKFLOW_INPUT_FIELD_KEYS.TRIGGER));
    case templateVarCodes.FOR_END_TIME:
      return getForEndTimeTemplateVarValue(getInputFieldByKey(userInputFieldList, WORKFLOW_INPUT_FIELD_KEYS.DURATION));
    case templateVarCodes.COMMUNICATION_CHANNEL:
      return getCommunicationChannelTemplateVarValue(getInputFieldByKey(userInputFieldList, WORKFLOW_INPUT_FIELD_KEYS.CHANNEL));
    case templateVarCodes.GAP_BETWEEN:
      return getGapBetweenTemplateVarValue(getInputFieldByKey(userInputFieldList, WORKFLOW_INPUT_FIELD_KEYS.GAP_BETWEEN));
    case templateVarCodes.COUNT:
      return getCounterTemplateVarValue(getInputFieldByKey(userInputFieldList, WORKFLOW_INPUT_FIELD_KEYS.COUNT));
  }

  return undefined;
}

export const getInputFieldByKey = (inputFields: IUserInputField[], key: string) => {
  return (inputFields || []).find(field => (field?.key === key));
}

const getEntityTemplateVarValue = (userInputField: IUserInputField | undefined) => {
  if (!userInputField || !userInputField.value) return undefined;
  const value = (userInputField.value.name || userInputField.value.title || userInputField.value.drugName || userInputField.value.eventName || userInputField.value.description);
  if (!value) return undefined;
  return value;
}

const getFrequencyTemplateVarValue = (userInputField: IUserInputField | undefined) => {
  if (userInputField?.isHidden) return '';
  if (!userInputField || !userInputField.displayValue) return undefined;
  if (userInputField.value && userInputField.value === FREQUENCY_CODES.NEVER || userInputField.value?.code === FREQUENCY_CODES.NEVER) return 'once';
  return userInputField.displayValue.toLowerCase();
}

const getStartTimeAfterTemplateVarValue = (userInputField: IUserInputField | undefined) => {
  if (userInputField?.isHidden) return undefined;
  if (!userInputField || !userInputField.value || (!userInputField.value.value && userInputField.value.value !== 0) || !userInputField.value.unit) return undefined;
  if (userInputField.value.value === 0) return 'on the day';
  return `${userInputField.value.value} ${userInputField.value.unit} after`.toLowerCase();
}

const getGapBetweenTemplateVarValue = (userInputField: IUserInputField | undefined) => {
  if (userInputField?.isHidden) return '';
  if (!userInputField || !userInputField.value || (!userInputField.value.value && userInputField.value.value !== 0) || !userInputField.value.unit) return undefined;
  return `${userInputField.value.value} ${userInputField.value.unit}`.toLowerCase();
}

const getForEndTimeTemplateVarValue = (userInputField: IUserInputField | undefined) => {
  if (userInputField?.isHidden) return '';
  if (!userInputField || !userInputField.value || !userInputField.value.unit) return undefined;
  if (!userInputField.value.value && userInputField.value.value !== 0) {
    if (userInputField.value.unit === 'End') {
      return `till the care journey ends`;
    } else {
    return undefined;
    }
  }
  if (userInputField.value.value === 0) return '';
  return `for ${userInputField.value.value} ${userInputField.value.unit}`.toLowerCase();
}

const getCommunicationChannelTemplateVarValue = (userInputField: IUserInputField | undefined) => {
  if (!userInputField || !userInputField.value) return undefined;
  return userInputField.value.toLowerCase();
}

const getCounterTemplateVarValue = (userInputField: IUserInputField | undefined) => {
  if (!userInputField || (!userInputField.value && userInputField.value !== 0)) return undefined;
  return userInputField.value;
}
