import {StyleSheet} from 'react-native';
import { Colors } from './Colors';

export const commonBodyContentContainer = StyleSheet.create({
  pageContentContainer: {
    // marginHorizontal: 8,
    height:'full'
  },
  topbarMarigin: {
    //  paddingHorizontal: 16,
  },
  customStepViewStyle:{
    // borderBottomWidth: 1,
    borderColor: Colors.Custom.Gray200,
    borderBottomWidth: 1
}
});
