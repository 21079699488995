import {FormControl, View, Text} from 'native-base';
import {useContext, useEffect, useState} from 'react';
import {Select as AntSelect, Spin} from 'antd';
import {IInputElement} from '../RightSideContainer/Workflow/FlowComponent/StateNodes/FlowNodeInterface';
import {getMlovListFromCategory} from '../../utils/mlovUtils';
import {MLOV_CATEGORY} from '../../constants/MlovConst';
import {CommonDataContext} from '../../context/CommonDataContext';
import {IMlov} from '../RightSideContainer/Journeys/JourneysOfCare/Table/Interfaces';
import UserAutoComplete from './CalendarWidget/UserAutoComplete/UserAutoComplete';
import {ParticipantType} from './CalendarWidget/ParticipantAutoComplete/ParticipantEnum';

interface IMember {
  key?: string;
  email?: string;
  label?: string;
  value?: string;
  type?: string;
}

interface IJourneyAssignee {
  selectedOptionCode: string | undefined;
  isAssigneeToCareJourneyMember: boolean;
  primaryCareTeamRoleId: string | undefined;
  careJourneyTeamRoleId: string | undefined;
  specificUserId: string | undefined;
  member: IMember | undefined;
}

interface IComponentState {
  value: IJourneyAssignee;
}

export enum AssigneeOptions {
  assigneeToCareJourneyMember = 'assigneeToCareJourneyMember',
  careJourneyTeamRole = 'careJourneyTeamRole',
  primaryCareTeamRole = 'primaryCareTeamRole',
  specificUser = 'specificUser',
}

const JourneyAutomationTaskAssignee = (props: {
  assigneeData?: any;
  isShowError?: boolean;
  onChange: (actionData?: any, extraData?: any) => void;
}) => {
  const {assigneeData, isShowError, onChange} = props;
  const mlovData = useContext(CommonDataContext);
  const userRoles =
    getMlovListFromCategory(mlovData.MLOV, MLOV_CATEGORY.USER_ROLES) || [];
  const [componentState, setComponentState] = useState<IComponentState>({
    value: {
      isAssigneeToCareJourneyMember: assigneeData?.isAssigneeToCareJourneyMember,
      primaryCareTeamRoleId: assigneeData?.primaryCareTeamRoleId,
      careJourneyTeamRoleId: assigneeData?.careJourneyTeamRoleId,
      specificUserId: assigneeData?.specificUserId,
      selectedOptionCode: assigneeData?.selectedOptionCode,
      member: assigneeData?.member,
    },
  });
  const taskAssigneeOption: { value: any; label: string }[] = [
    {
      value: AssigneeOptions.assigneeToCareJourneyMember,
      label: "Journey Assign Member",
    },
    {
      value: AssigneeOptions.careJourneyTeamRole,
      label: "Specific Role In Care Journey Team",
    },
    {
      value: AssigneeOptions.primaryCareTeamRole,
      label: "Specific Role In Member Care Team",
    },
    { value: AssigneeOptions.specificUser, label: "Specific User" },
  ];

  const isValidAssigneeSelected = () => {
    return (
      assigneeData?.isAssigneeToCareJourneyMember ||
      assigneeData?.primaryCareTeamRoleId ||
      assigneeData?.careJourneyTeamRoleId ||
      assigneeData?.specificUserId ||
      assigneeData?.member?.key
    );
  };

  const getRoleNameById = (roleId: string | undefined) => {
    if (!roleId) {
      return '';
    }
    return (userRoles || []).find(userRole => {
      return userRole?.id === roleId;
    })?.value || '';
  }

  const assigneeSelectionUpdate = (
    assigneeOptions: IJourneyAssignee,
  ): boolean => {
    const {
      selectedOptionCode,
      isAssigneeToCareJourneyMember,
      primaryCareTeamRoleId,
      careJourneyTeamRoleId,
      specificUserId,
      member,
    } = assigneeOptions;
    let isValid = false;
    let displayText = '';
    switch (selectedOptionCode) {
      case AssigneeOptions.assigneeToCareJourneyMember:
        isValid = isAssigneeToCareJourneyMember;
        displayText = `Assign care journey member `;
        break;
      case AssigneeOptions.careJourneyTeamRole:
        isValid = careJourneyTeamRoleId ? true : false;
        displayText = `Care journey team role - ${getRoleNameById(careJourneyTeamRoleId)} `;
        break;
      case AssigneeOptions.primaryCareTeamRole:
        isValid = primaryCareTeamRoleId ? true : false;
        displayText = `Primary care team role - ${getRoleNameById(careJourneyTeamRoleId)} `;
        break;
      case AssigneeOptions.specificUser:
        isValid = specificUserId ? true : false;
        displayText = `User - ${member?.label} `;
        break;
    }
    setComponentState(prev => {
      return {
        ...prev,
        value: { ...assigneeOptions },
      };
    });
    if (!isValid) {
      props.onChange(undefined);
    } else {
      props.onChange({ ...assigneeOptions, displayText: displayText});
    }
    return isValid;
  };

  useEffect(() => {
    if (
      !assigneeData?.isAssigneeToCareJourneyMember &&
      !assigneeData?.primaryCareTeamRoleId &&
      !assigneeData?.careJourneyTeamRoleId &&
      !assigneeData?.specificUserId &&
      !assigneeData?.member?.key
    ) {
      onChange(undefined);
    }
  }, []);
  return (
    <>
      <View>
        <AntSelect
          key={'selectAssigneeOption'}
          allowClear={true}
          status={isShowError && !isValidAssigneeSelected() ? 'error' : ''}
          onClear={() => {
            const assigneeOptions: IJourneyAssignee = {
              isAssigneeToCareJourneyMember: false,
              primaryCareTeamRoleId: undefined,
              careJourneyTeamRoleId: undefined,
              specificUserId: undefined,
              selectedOptionCode: undefined,
              member: undefined,
            };
            assigneeSelectionUpdate(assigneeOptions);
          }}
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          placeholder={'Select Assignee Type'}
          value={componentState.value?.selectedOptionCode}
          showSearch={true}
          onChange={(optionCode, data: any) => {
            const assigneeOptions: IJourneyAssignee = {
              isAssigneeToCareJourneyMember: optionCode === AssigneeOptions.assigneeToCareJourneyMember,
              primaryCareTeamRoleId: undefined,
              careJourneyTeamRoleId: undefined,
              specificUserId: undefined,
              selectedOptionCode: optionCode || undefined,
              member: undefined,
            };
            assigneeSelectionUpdate(assigneeOptions);
          }}>
          {taskAssigneeOption?.map(
            (assigneeOption: {value: string; label: string}, index: number) => {
              return (
                <AntSelect.Option
                  key={assigneeOption.value}
                  defaultValue="assigneeToCareJourneyMember"
                  value={assigneeOption.value}
                  title={assigneeOption.label || ''}>
                  {assigneeOption.label || ''}
                </AntSelect.Option>
              );
            },
          )}
        </AntSelect>
      </View>
      {componentState.value?.selectedOptionCode === AssigneeOptions.specificUser && (
        <View marginTop={2}>
          <Text marginBottom={2}>
            Select User <Text color={'red.400'}>*</Text>{' '}
          </Text>
          <UserAutoComplete
            key={'specificUserSelection'}
            selectedValue={componentState?.value?.member as any || undefined}
            isShowError={
              isShowError && componentState.value?.specificUserId ? true : false
            }
            isDisabled={false}
            onChange={user => {
              if (user) {
                const member: IMember = {
                  key: user?.key,
                  email: user?.data?.email,
                  label: user?.label,
                  value: user?.value,
                  type: ParticipantType.staff,
                };
                const assigneeOptions: IJourneyAssignee = {
                  isAssigneeToCareJourneyMember: false,
                  primaryCareTeamRoleId: undefined,
                  careJourneyTeamRoleId: undefined,
                  specificUserId: member?.key,
                  selectedOptionCode: componentState.value?.selectedOptionCode,
                  member: member,
                };
                assigneeSelectionUpdate(assigneeOptions);
              } else {
                const assigneeOptions: IJourneyAssignee = {
                  isAssigneeToCareJourneyMember: false,
                  primaryCareTeamRoleId: undefined,
                  careJourneyTeamRoleId: undefined,
                  specificUserId: undefined,
                  selectedOptionCode: componentState.value?.selectedOptionCode,
                  member: undefined,
                };
                assigneeSelectionUpdate(assigneeOptions);
              }
            }}
          />
        </View>
      )}
      {(componentState.value?.selectedOptionCode === AssigneeOptions.careJourneyTeamRole ||
        componentState.value?.selectedOptionCode === AssigneeOptions.primaryCareTeamRole) && (
        <View marginTop={2}>
          <FormControl
            isInvalid={
              isShowError &&
              !(
                componentState.value.careJourneyTeamRoleId ||
                componentState.value.primaryCareTeamRoleId
              )
            }
            flex={1}>
            <Text marginBottom={2}>
              Select Role <Text color={'red.400'}>*</Text>{' '}
            </Text>
            <AntSelect
              key={'userAssigneeRole'}
              status={
                isShowError && !(componentState.value?.careJourneyTeamRoleId || componentState.value.primaryCareTeamRoleId) ? 'error' : ''
              }
              placeholder="Select user role"
              value={
                componentState.value?.careJourneyTeamRoleId ||
                componentState.value.primaryCareTeamRoleId
              }
              showSearch={true}
              onChange={(roleId, data: any) => {
                const isCareTeamRoleSelect: boolean =
                  componentState.value?.selectedOptionCode === AssigneeOptions.careJourneyTeamRole;
                const assigneeOptions: IJourneyAssignee = {
                  isAssigneeToCareJourneyMember: false,
                  primaryCareTeamRoleId: isCareTeamRoleSelect
                    ? undefined
                    : roleId,
                  careJourneyTeamRoleId: isCareTeamRoleSelect
                    ? roleId
                    : undefined,
                  specificUserId: undefined,
                  selectedOptionCode: componentState.value?.selectedOptionCode,
                  member: undefined,
                };
                assigneeSelectionUpdate(assigneeOptions);
              }}>
              {userRoles?.map((userRole: IMlov) => {
                return (
                  <AntSelect.Option
                    key={userRole.id}
                    value={userRole.id}
                    title={userRole.id || ''}>
                    {userRole.value || ''}
                  </AntSelect.Option>
                );
              })}
            </AntSelect>
          </FormControl>
        </View>
      )}
    </>
  );
};

export default JourneyAutomationTaskAssignee;
