import {debounce} from 'lodash';
import {Input, Text, View, VStack} from 'native-base';
import { useState } from 'react';
import { Colors } from '../../../../../../styles';
import {ITextInputProps} from '../interfaces';
import {styles} from './QuestionnaireComponentStyles';

const NumericInput = (props: ITextInputProps) => {
  const {title, onChangeText, required} = props;
  const [answer, setAnswer] = useState<string | undefined>(props.answer);
  const numberRegex = /^[0-9\b]+$/;

  return (
    <VStack style={styles.container}>
      <View style={styles.titleView}>
        <Text style={styles.titleText}>{title} {required && <Text color={Colors.error[500]}>*</Text>}</Text>
      </View>

      <View>
        <Input
          isInvalid={required && !answer}
          value={answer || ''}
          isReadOnly={props.readonly}
          onChangeText={(text) => {
            if (text && numberRegex.test(text)) {
              setAnswer(text);
              onChangeText(text);
            } else if (!text) {
              setAnswer(text);
              onChangeText(text);
            }
          }}
        />
      </View>
    </VStack>
  );
};

export default NumericInput;
