import React from "react";
import { ICommonSvgProps } from "./interfaces";

const LogoutSvg =(props?: ICommonSvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props?.width ? props?.width : "24"}
      height={props?.height ? props?.height : "24"}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke={props?.customStrokeColor ? props?.customStrokeColor : "#98A2B3"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9 21H5a2 2 0 01-2-2V5a2 2 0 012-2h4m7 14l5-5m0 0l-5-5m5 5H9"
      ></path>
    </svg>
  );
}

export default LogoutSvg;
