import React, {useEffect} from 'react';
import {Button, Divider, HStack, Text, View, VStack} from 'native-base';
import {IUserSlots} from './AppointmentBookingIntefaces';
import {DATE_FORMATS} from '../../../../../../constants';
import {DisplayText} from '../../../../DisplayText/DisplayText';
import {getDateStrFromFormat} from '../../../../../../utils/DateUtils';

const ParticipantGroupSlots = (props: {
  userSlots: IUserSlots[];
  onChange: (data: IUserSlots[]) => void;
}) => {

  return (
    <VStack space={2} width="full">
      {props.userSlots.map((userData: IUserSlots) => {
        return (
          <View key={userData.userId}>
            <HStack space={2}>
              <View flex={1} marginLeft={2} marginTop={2}>
                {userData.user && (
                  <>
                    <Text fontWeight={300}>{userData.user?.name}</Text>
                    <Text fontWeight={300} color="gray.400">
                      {userData.duration} min
                    </Text>
                  </>
                )}
              </View>
              <View flex={4} overflowX="scroll">
                <View flexDirection="row">
                  {userData.slots.length > 0 &&
                    userData.slots
                      .filter((slot, index) => {
                        return (
                          userData.slots.length <= 5 ||
                          index <
                            Math.max(Math.ceil(userData.slots.length / 2), 5)
                        );
                      })
                      .map((slot, index) => {
                        return (
                          <View key={index} margin={2}>
                            <Button
                              width={90}
                              onPress={() => {
                                userData.selectedSlot = slot;
                                props.onChange(props.userSlots);
                              }}
                              minWidth={8}
                              variant={
                                userData.selectedSlot === slot
                                  ? 'solid'
                                  : 'outline'
                              }
                            >
                              {getDateStrFromFormat(
                                slot.startDateTime,
                                DATE_FORMATS.DISPLAY_TIME_FORMAT
                              )}
                            </Button>
                          </View>
                        );
                      })}
                </View>
                <View flexDirection="row">
                  {userData.slots.length > 5 &&
                    userData.slots
                      .filter((slot, index) => {
                        return (
                          index >=
                          Math.max(Math.ceil(userData.slots.length / 2), 5)
                        );
                      })
                      .map((slot, index) => {
                        return (
                          <View key={index} margin={2}>
                            <Button
                              width={90}
                              onPress={() => {
                                userData.selectedSlot = slot;
                                props.onChange(props.userSlots);
                              }}
                              minWidth={8}
                              variant={
                                userData.selectedSlot === slot
                                  ? 'solid'
                                  : 'outline'
                              }
                            >
                              {getDateStrFromFormat(
                                slot.startDateTime,
                                DATE_FORMATS.DISPLAY_TIME_FORMAT
                              )}
                            </Button>
                          </View>
                        );
                      })}
                </View>
                {userData.slots && userData.slots.length === 0 && (
                  <View marginLeft={'30%'} justifyContent="center">
                    <DisplayText textLocalId="noSlotsAvailable" />
                  </View>
                )}
              </View>
            </HStack>
            <Divider marginTop={2} />
          </View>
        );
      })}
    </VStack>
  );
};

export default ParticipantGroupSlots;
