import React from 'react';
import { Svg, Path } from 'react-native-svg';

const ConditionIconSvg = () => {
  return (
    <Svg
      width="18"
      height="16"
      fill="none"
      viewBox="0 0 22 20"
    >
      <Path
        fill="#6F7A90"
        d="M7.962 17.379l.315-.389-.315.389zM11 3.574l-.365.342a.5.5 0 00.73 0L11 3.574zm3.038 13.805l.315.388-.315-.388zM11 19v-.5.5zm6.5-11.5a.5.5 0 000-1v1zm-4-1a.5.5 0 000 1v-1zM16 5a.5.5 0 00-1 0h1zm-1 4a.5.5 0 001 0h-1zm-6.723 7.99C6.82 15.808 5.11 14.252 3.77 12.543 2.421 10.82 1.5 9.013 1.5 7.318h-1c0 2.033 1.089 4.062 2.484 5.842 1.405 1.792 3.177 3.401 4.663 4.607l.63-.777zM1.5 7.318c0-2.975 1.303-4.885 2.995-5.548 1.689-.661 3.982-.162 6.14 2.146l.73-.683C9.023.728 6.316-.017 4.13.839 1.947 1.694.5 4.062.5 7.318h1zm12.853 10.449c1.486-1.205 3.258-2.815 4.663-4.607C20.411 11.38 21.5 9.35 21.5 7.317h-1c0 1.696-.921 3.504-2.271 5.226-1.34 1.709-3.049 3.265-4.506 4.447l.63.777zM21.5 7.317c0-3.255-1.447-5.623-3.63-6.478-2.186-.856-4.893-.111-7.235 2.394l.73.683c2.158-2.308 4.451-2.807 6.14-2.146 1.692.663 2.995 2.573 2.995 5.547h1zM7.647 17.767C8.938 18.815 9.748 19.5 11 19.5v-1c-.829 0-1.35-.396-2.723-1.51l-.63.777zm6.076-.777C12.351 18.104 11.83 18.5 11 18.5v1c1.252 0 2.062-.685 3.353-1.733l-.63-.777zM17.5 6.5h-2v1h2v-1zm-2 0h-2v1h2v-1zM15 5v2h1V5h-1zm0 2v2h1V7h-1z"
      ></Path>
    </Svg>
  );
};


export default ConditionIconSvg;
