import { gql } from '@apollo/client';

export const GET_PRACTICE_SCHEDULE = gql`
  query GetPracticeSchedules($whereCondition: availability_bool_exp!) {
    schedules: availabilities(
      where: $whereCondition
      order_by: {beginDate: asc, endDate: asc, startTime: asc, endTime: asc}
    ) {
      id
      startTime
      endTime
      beginDate
      endDate
      daysOfWeek
      locationId
      typeId
      timezoneId
      isVirtualLocation
      isDeleted
      locationId
      isDeleted
      typeId
      inboxId
      userId
    }
  }
`;

export const ADD_UPDATE_SCHEDULE = gql`
  mutation AddUpdateSchedule($data: [AvailabilityInput!]) {
    createOrUpdateAvailability(params: $data) {
      id
      startTime
      endTime
      beginDate
      endDate
      daysOfWeek
      locationId
      isVirtualLocation
      typeId
      timezoneId
      inboxId
      timezoneId
    }
  }
`;

export const DELETE_SCHEDULE = gql`
  mutation DeleteSchedules($ids: [uuid!]!) {
    updateAvailabilities(where: {id: {_in: $ids}}, _set: {isDeleted: true}) {
      affected_rows
    }
  }
`;

export const GET_AVAILABLE_SLOTS = gql`
  query GetAvailableSlots($data: SlotInput!) {
    getAvailableSlots(params: $data) {
      availabilities {
        userId
        slots {
          startDateTime
          endDateTime
        }
      }
      availabilityAggregate
    }
  }
`;

export const GET_AVAILABLE_SLOTS_FOR_DATE_RANGE = gql`
  query GetAvailableTimeSlotsForDateRange($data: SlotsForDateRangeInput!) {
    getAvailableSlots: getAvailableTimeSlotsForDateRange(params: $data) {
      slots
      availabilityAggregate
      isLocationNotMatchWithAppointmentType
      restrictedUserAppointmentTypes
    }
  }
`;

export const GET_BLOCK_SLOTS_FOR_DATE_RANGE = gql`
  query getUserBlockSlots($data: UserBlockSlotsInput!) {
    getUserBlockSlots: getUserBlockSlots(params: $data) {
      blockSlots {
        endDateTime
        startDateTime
        isBlockedInterval
        accountLocationId
        isVirtualLocation
      }
    }
  }
`;

export const GET_APPOINTMENT_TYPES = gql`
  query GetAppointmentTypes($tenantId: uuid) {
    appointmentTypes(
      where: {tenantId: {_eq: $tenantId}, isDeleted: {_eq: false}}
    ) {
      id
      eventName
      category {
        code
      }
    }
  }
`;

export const AGGREGATE_APPOINTMENTS = gql`
  query aggregateAppointments(
    $appointmentTypeId: uuid!
    $patientIdList: [uuid!]!
  ) {
    aggregateAppointments(
      where: {
        participants: {contactId: {_in: $patientIdList}}
        appointmentTypeId: {_eq: $appointmentTypeId}
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_CONTACT_UUIDS_BY_APPOINTMENT_TYPES = gql`
  query GetContactUuidsByAppointmentTypes(
    $appointmentTypeId: uuid
    $tenantId: uuid
  ) {
    appointments(
      where: {
        appointmentTypeId: {_eq: $appointmentTypeId}
        tenantId: {_eq: $tenantId}
        isDeleted: {_eq: false}
      }
    ) {
      appointmentTypeId
      participants(distinct_on: contactId) {
        contactId
        status {
          id
          code
        }
      }
    }
  }
`;

export const GET_TIMEZONES_BY_UUIDS = gql`
  query GetTimezonesByUuids($uuids: [uuid!]) {
    timezones(where: {uuid: {_in: $uuids}}) {
      uuid
      abbreviations
      timezone
      displayValue
      gmtOffset
      utcOffset
      isUserFacing
    }
  }
`;

export default {
  GET_PRACTICE_SCHEDULE,
  ADD_UPDATE_SCHEDULE,
  GET_AVAILABLE_SLOTS,
  GET_AVAILABLE_SLOTS_FOR_DATE_RANGE,
  GET_BLOCK_SLOTS_FOR_DATE_RANGE,
  GET_APPOINTMENT_TYPES,
  AGGREGATE_APPOINTMENTS,
  GET_CONTACT_UUIDS_BY_APPOINTMENT_TYPES,
  DELETE_SCHEDULE,
  GET_TIMEZONES_BY_UUIDS
};
