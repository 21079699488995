import React from "react";
import { ICommonSvgProps } from "../interfaces";
import { Colors } from "../../../../styles";

const ChatActionSvg = (props: ICommonSvgProps)=> {
  const strokeColor = props.customStrokeColor
    ? props.customStrokeColor
    : props?.isActive
    ? Colors.FoldPixel.GRAY300
    : '#D0D5DD';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{width: props?.width ? props?.width : 'inherit', height: props?.height ? props?.height : 'inherit'}}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path 
        d="M8.00008 15.1654C11.682 15.1654 14.6667 12.1806 14.6667 8.4987C14.6667 4.8168 11.682 1.83203 8.00008 1.83203C4.31818 1.83203 1.33341 4.8168 1.33341 8.4987C1.33341 9.56515 1.58382 10.5731 2.02905 11.467C2.14737 11.7046 2.18675 11.9761 2.11816 12.2324L1.72109 13.7165C1.54872 14.3607 2.13809 14.9501 2.78231 14.7777L4.26634 14.3806C4.5227 14.312 4.79422 14.3514 5.03177 14.4697C5.92566 14.915 6.93363 15.1654 8.00008 15.1654Z"
        stroke={strokeColor}
      />
    </svg>
  );
}

export default React.memo(ChatActionSvg);