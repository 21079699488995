import { IAuditState } from "./AuditInterfaces";

export enum ACTION_CODE {
    CREATE = "CREATE",
    UPDATE = "UPDATE",
    PURPOSED_TIME_APPOINTMENT= "PURPOSED_TIME_APPOINTMENT",
    ACCEPT_APPOINTMENT="ACCEPT_APPOINTMENT",
    DECLINE_APPOINTMENT="DECLINE_APPOINTMENT"
}

export enum PARTICIPANT_ACTION_CODE {
    ADD = "ADD",
    REMOVE = "REMOVE",
}

export const propertyPaths = [
    (log: IAuditState) => log?.data?.oldData?.label,
    (log: IAuditState) => log?.data?.newData?.label,
    (log: IAuditState) => log?.data?.oldData?.attachment,
    (log: IAuditState) => log?.data?.newData?.attachment,
    (log: IAuditState) => log?.data?.oldData?.taskPool,
    (log: IAuditState) => log?.data?.newData?.taskPool,
];

export enum TASK_RESOURCE_TYPE_CODES {
    TASK_ATTACHMENT = "TASK_ATTACHMENT",
    TASK_LABEL = "TASK_LABEL",
    TASKS = "TASKS",
    TASK_COMMENTS="TASK_COMMENT"
}

export enum TASK_RESOURCE_TYPE_VALUES {
    TASK_ATTACHMENT = "Attachment",
    TASK_LABEL = "Label",
    TASKS = "Tasks",
    TASK_COMMENTS="Comment"
}

export enum APPOINTMENT_RESOURCE_TYPE_CODES {
    APPOINTMENT = 'APPOINTMENT',
    APPOINTMENT_PARTICIPANT = 'APPOINTMENT_PARTICIPANT',
    APPOINTMENT_NOTE = 'APPOINTMENT_NOTE',
    APPOINTMENT_TASK = 'APPOINTMENT_TASK',
}

export enum APPOINTMENT_RESOURCE_TYPE_VALUES {
    APPOINTMENT = "Appointment",
    APPOINTMENT_PARTICIPANT = "Appointment Participant",
    APPOINTMENT_NOTE = "Instructions for member",
    APPOINTMENT_TASK = "Instructions for staff",
}

export enum PARTICIPANT_RESOURCE_TYPE_VALUES {
    PAT="Member",
    PPRF="Primary Provider",
    SPRF="Secondary Provider(s)"
}

export const TASK_FIELDS: { [key: string]: string } = {
    'title': 'Title',
    'description': 'Description',
    'assigneeId': 'Assignee',
    'endDateTime': 'Due Date',
    'priorityId': 'Priority',
    'contactId': 'Member',
    'userPoolId': 'Task Pool',
    'attachmentId': 'Attachment',
    'labelId': 'Label',
    'statusId': 'Status'
}

export const fieldsOfTask = {
    TITLE: 'title',
    DESCRTIPTION: 'description',
    ASSIGNEE_ID: 'assigneeId',
    END_DATE_TIME: 'endDateTime',
    PRIORITY_ID: 'priorityId',
    CONTACT_ID: 'contactId',
    USER_POOL_ID: 'userPoolId',
    ATTACHMENT_ID: 'attachmentId',
    LABEL_ID: 'labelId',
    COMMENT_ID: 'commentId',
    STATUS_ID: 'statusId',
}
export const fieldsOfAppointment = {
    DISPLAY_NAME: 'displayName',
    LOCATION_TYPE_ID: 'locationTypeId',
    ACCOUNT_LOCATION_ID: 'accountLocationId',
    USER_IDS: 'userIds',
    CONTACT_IDS: 'contactIds',
    START_DATE_TIME: 'startDateTime',
    CONTENT: 'Content',
    TITLE: 'title',
    STATUS_ID: 'statusId',
    REFERENCE: 'reference',
    DESCRIPTION: 'description',
    NAME: 'name',
    REASON_FOR_VISIT: 'reasonForVisit',
    APPOINTMENT_TYPE_ID: 'appointmentTypeId',
};

export const APPOINTMENT_FIELDS: { [key: string]: string } = {
    'reasonForVisit': 'Reason for visit',
    'locationTypeId': 'Mode of Appointment',
    'accountLocationId': 'Location',
    'userIds': 'Provider',
    'contactIds': 'Members',
    'startDateTime': 'Date & Time',
    'Content': 'Instructions for member',
    'title': 'Instructions for staff',
    'statusId': 'Appointment Status',
    'reference': 'Recurrence',
    'description': 'Description',
    'name': 'Event Name',
    'appointmentTypeId': 'Appointment Type',
}

export enum FIELDS_OF_TASK {
    TITLE = 'TITLE',
    DESCRTIPTION = 'DESCRTIPTION',
    ASSIGNEE_ID = 'ASSIGNEE_ID',
    END_DATE_TIME = 'END_DATE_TIME',
    PRIORITY_ID = 'PRIORITY_ID',
    CONTACT_ID = 'CONTACT_ID',
    USER_POOL_ID = 'USER_POOL_ID',
    ATTACHMENT_ID ='ATTACHMENT_ID',
    LABEL_ID = 'LABEL_ID',
    COMMENT_ID ='COMMENT_ID',
    STATUS_ID = 'STATUS_ID'
}

export enum FIELDS_OF_APPOINTMENT {
    CONTACT_ID = 'CONTACT_ID',
    ACCOUNT_LOCATION_ID = 'ACCOUNT_LOCATION_ID',
    PATIENT_CARE_JOURNEY_ID = 'PATIENT_CARE_JOURNEY_ID',
    APPOINTMENT_TYPE_ID = 'APPOINTMENT_TYPE_ID',
}

export enum APPOINTMENT_CORE_FIELDS {
    CONTACT_ID = 'contactId',
    ACCOUNT_LOCATION_ID = 'accountLocationId',
    APPOINTMENT_TYPE_ID = 'appointmentTypeId',
} 

export const AUDIT_ACTION_CODES = {
    SHOW_MORE: 'SHOW_MORE',
    SHOW_LESS: 'SHOW_LESS',
    CHANGE_SORT: 'CHANGE_SORT',
    CHANGE_RESOURCE_CODE_FILTER: 'CHANGE_RESOURCE_CODE_FILTER',
    CHANGE_DATE_FILTER: 'CHANGE_DATE_FILTER',
}

export enum DATA_KEYS {
    OLD_DATA = 'oldData',
    NEW_DATA = 'newData',
}

export enum RESOURCE_CODE_TO_FIELD  {
    TASK_ATTACHMENT = "attachmentId",
    TASK_LABEL = "labelId",
    APPOINTMENT_PARTICIPANT = "appointmentParticipant",
    APPOINTMENT_TASK = "title"
}

export const CHANGES_APPLIED_ON_SERIES = 'changes applied on series'

export const getDisplayName = (log: IAuditState, uuidMappingToData: { [key: string]: any }, field: string, dataKey: DATA_KEYS) => {
    const dataField = log?.data?.[dataKey]?.[field];
    let result;
    if (Array.isArray(dataField) && dataField.length > 0) {
        const displayNames = dataField
            .map(element => uuidMappingToData?.[element]?.displayName)
            .join(', ');
        result = displayNames || 'None';
    } else {
        if (dataKey === DATA_KEYS.OLD_DATA) {
            result = uuidMappingToData?.[dataField]?.displayName || 'None'
        }
        else result = log?.data?.[dataKey].isDeleted ? 'None' :
            uuidMappingToData?.[dataField]?.displayName
    }
    return result;
}

export const isParticipantStatusChangeLog = (actionCode: string) => {
    return actionCode === ACTION_CODE.ACCEPT_APPOINTMENT || actionCode === ACTION_CODE.DECLINE_APPOINTMENT || actionCode === ACTION_CODE.PURPOSED_TIME_APPOINTMENT
}