import React from "react";

const CancelButtonSvg = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3536 1.3536C13.5488 1.15834 13.5488 0.841757 13.3536 0.646495C13.1583 0.451233 12.8417 0.451233 12.6464 0.646495L13.3536 1.3536ZM0.646497 12.6464C0.451235 12.8417 0.451235 13.1583 0.646497 13.3536C0.841759 13.5488 1.15834 13.5488 1.3536 13.3536L0.646497 12.6464ZM1.35355 0.646447C1.15829 0.451185 0.841709 0.451184 0.646447 0.646447C0.451185 0.841709 0.451184 1.15829 0.646447 1.35355L1.35355 0.646447ZM12.6464 13.3535C12.8417 13.5488 13.1582 13.5488 13.3535 13.3535C13.5488 13.1582 13.5488 12.8417 13.3535 12.6464L12.6464 13.3535ZM12.6464 0.646495L6.64647 6.64647L7.35358 7.35358L13.3536 1.3536L12.6464 0.646495ZM6.64647 6.64647L0.646497 12.6464L1.3536 13.3536L7.35358 7.35358L6.64647 6.64647ZM0.646447 1.35355L6.64647 7.35358L7.35358 6.64647L1.35355 0.646447L0.646447 1.35355ZM6.64647 7.35358L12.6464 13.3535L13.3535 12.6464L7.35358 6.64647L6.64647 7.35358Z"
        fill="#6F7A90"
      />
    </svg>
  );
};

export default CancelButtonSvg;
