import {StyleSheet} from 'react-native';
import {Colors} from '../../../../../styles';

export const getMessagingWindowFooterStyles = ({
  totalScheduleMessage,
  totalScheduleFailedMessage,
  messageType,
  editorInputHightState,
}: {
  totalScheduleMessage: number;
  totalScheduleFailedMessage: number;
  messageType: string;
  editorInputHightState: number;
}) => {
  return StyleSheet.create({
    scheduleMessageContainer: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    scheduleMessageCountView: {
      marginHorizontal: 4,
      display: totalScheduleMessage > 0 ? 'flex' : 'none',
    },
    scheduleMessageFailedCountView: {
      backgroundColor: Colors.Custom.Gray100,
      borderRadius: 20,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: Colors.Custom.Gray200,
      paddingHorizontal: 6,
      marginHorizontal: 4,
      flexDirection: 'row',
      alignItems: 'center',
      display: totalScheduleFailedMessage > 0 ? 'flex' : 'none',
    },
    msgFooterStyle: {
      borderTopColor: 'coolGray.200',
    },
    divider: {
      borderColor: Colors.Custom.BorderColor,
      borderBottomWidth: 1,
      width: '100%',
      margin: 'auto',
    },
    replyTypeSwitchView: {
      flexDirection: 'row',
      alignItems: 'flex-end',
    },
    replyTypeSwitch: {
      alignSelf: 'center',
      backgroundColor:
        messageType !== 'reply' ? '#F37232' : Colors.Custom.Gray200,
      borderRadius: 4,
    },
    infoIcon: {
      fontSize: 14,
      color: Colors.Custom.Gray400,
    },
    infoIconView: {
      alignSelf: 'center',
    },
    editorHStack: {
      height: editorInputHightState + 10 + 'px',
      width: '100%',
    },
    editorView: {
      width: '88%',
    },
    sendBtnVStack: {
      width: '12%',
    },
    preferenceContainer: {
      flexDirection: 'row',
    },
    preferenceView: {
      flex: 1,
      flexDirection: 'row',
    },
    preferenceText: {
      color: Colors.Custom.Gray400,
      minWidth: 'fit-content',
    },
    pressText: {
      color: Colors.Custom.Gray400,
      fontWeight: '700',
    },
    pressEnterText: {
      backgroundColor: Colors.Custom.Gray100,
      color: Colors.Custom.Gray500,
      paddingLeft: 6,
      paddingRight: 6,
      borderRadius: 4,
      fontWeight: '500',
    },
    dividerStyle: {
      marginTop: 10,
      marginBottom: 10,
      width: 225,
      borderColor: Colors.Custom.Gray300,
    },
    radioStyle: {
      paddingLeft: 16,
      paddingTop: 5,
      paddingBottom: 5,
    },
    radioStyle2: {
      paddingLeft: 16,
      paddingTop: 5,
    },
    radioGroupStyle: {
      marginTop: 0,
    },
    sendMessageText: {
      color: Colors.Custom.Gray500,
      fontSize: 16,
    },
    dividerStyle2: {
      marginTop: 6,
      marginBottom: 6,
      marginLeft: 16,
      width: 193,
      borderColor: Colors.Custom.Gray200,
    },
    startANewLineText: {
      color: Colors.Custom.Gray500,
      fontSize: 16,
    },
    changeText: {
      color: Colors.Custom.PurpleColor,
    },
    scheduleMessageFailedText: {
      marginLeft: 4,
    },
  });
};
