import React from 'react';
import {isWeb} from '../../../utils/platformCheckUtils';
import {Image} from 'native-base';

const StopSvgIcon = (props: {
  isActive?: boolean;
  customActiveColor?: string;
  customColor?: string;
  size?: string | number;
  bgColor?: string;
  customStrokeWidth?: string;
}) => {
  const strokeColor = props.isActive
    ? props.customActiveColor
      ? props.customActiveColor
      : ''
    : props.customColor
    ? props.customColor
    : '#D94D55';
  return (
    <>
      {isWeb() ? (
        <svg
          width={props?.size ? props?.size : "20"}
          height={props?.size ? props?.size : "21"}
          viewBox="0 0 20 21"
          fill={props?.bgColor ? props?.bgColor : "none"}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.10829 4.83183L15.8916 16.6152M18.3333 10.7235C18.3333 15.3259 14.6023 19.0568 9.99996 19.0568C5.39759 19.0568 1.66663 15.3259 1.66663 10.7235C1.66663 6.12113 5.39759 2.39017 9.99996 2.39017C14.6023 2.39017 18.3333 6.12113 18.3333 10.7235Z"
            stroke={strokeColor}
            stroke-width={props?.customStrokeWidth ? props?.customStrokeWidth : "1.5"}
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ) : (
        <Image
          resizeMode="contain"
          size={'24px'}
          source={require('../../../assets/images/MessageActionImages/StopIcon.png')}
          alt="image"
        />
      )}
    </>
  );
};

export default StopSvgIcon;
