import React from "react";

function ResultIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="13"
      fill="none"
      viewBox="0 0 17 13"
      id='result-icon'
    >
      <path
        id="result-icon-path"
        fill="#475467"
        d="M15.625.563H1.375a.594.594 0 00-.594.593V11.25a1.188 1.188 0 001.188 1.188H15.03a1.187 1.187 0 001.188-1.188V1.156a.594.594 0 00-.594-.593zM1.969 5.313h2.968v2.375H1.97V5.313zm4.156 0h8.906v2.375H6.125V5.313zm8.906-3.563v2.375H1.97V1.75H15.03zM1.97 8.875h2.968v2.375H1.97V8.875zM15.03 11.25H6.125V8.875h8.906v2.375z"
      ></path>
    </svg>
  );
}

export default ResultIcon;
