import React from 'react';
import {Path, Svg} from 'react-native-svg';

function ClinicalAssessmenticonSvg(params?: {
  strokeColor?: string;
  width?: string;
  height?: string;
}) {
  return (
    <Svg
      width={params?.width || '16'}
      height={params?.height || '16'}
      viewBox="0 0 21 22"
      fill="none"
    >
      <Path
        d="M16.18 7.03933L16.6435 6.57589C17.4113 5.80804 18.6563 5.80804 19.4241 6.57589C20.192 7.34374 20.192 8.58868 19.4241 9.35653L18.9607 9.81997M16.18 7.03933C16.18 7.03933 16.238 8.02414 17.1069 8.89309C17.9759 9.76204 18.9607 9.81997 18.9607 9.81997M16.18 7.03933L11.9194 11.2999C11.6308 11.5885 11.4865 11.7328 11.3624 11.8919C11.2161 12.0796 11.0906 12.2827 10.9882 12.4975C10.9014 12.6797 10.8368 12.8732 10.7078 13.2604L10.1609 14.901M18.9607 9.81997L14.7001 14.0806C14.4115 14.3692 14.2672 14.5135 14.1081 14.6376C13.9204 14.7839 13.7173 14.9094 13.5025 15.0118C13.3203 15.0986 13.1268 15.1632 12.7396 15.2922L11.099 15.8391M11.099 15.8391L10.6979 15.9728C10.5074 16.0363 10.2973 15.9867 10.1553 15.8447C10.0133 15.7027 9.96371 15.4926 10.0272 15.3021L10.1609 14.901M11.099 15.8391L10.1609 14.901M6 12H8.5M6 8H12.5M6 16H7.5M17.8284 2.17157C16.6569 1 14.7712 1 11 1H9C5.22876 1 3.34315 1 2.17157 2.17157C1 3.34315 1 5.22876 1 9V13C1 16.7712 1 18.6569 2.17157 19.8284C3.34315 21 5.22876 21 9 21H11C14.7712 21 16.6569 21 17.8284 19.8284C18.7715 18.8853 18.9554 17.4796 18.9913 15"
        stroke={params?.strokeColor || '#8C5AE2'}
        strokeLinecap="round"
      />
    </Svg>
  );
}

export default React.memo(ClinicalAssessmenticonSvg);
