import {useLazyQuery} from '@apollo/client';
import {Select, Spin} from 'antd';
import {debounce} from 'lodash';
import {Skeleton, View} from 'native-base';
import React, {useEffect, useState} from 'react';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../constants/Configs';
import {ISearchFieldProps} from '../../../Interfaces';
import {MDMQueries} from '../../../services';

const PhysicalActivitySearch = (props: ISearchFieldProps) => {
  const {value, isShowError, onChange} = props;
  const [loading, setLoading] = useState(true);
  const [activityData, setActivityData] = useState<any[]>([]);
  const [originalData, setOriginalData] = useState<any[]>([]);

  useEffect(() => {
    setLoading(true);
    searchMDM({
      variables: {
        type: 'ACTIVITY',
      },
    });
  }, []);

  const [searchMDM] = useLazyQuery(MDMQueries.GET_MDM_DATA, {
    fetchPolicy: 'no-cache',
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
    onCompleted: (response: any) => {
      if (
        response &&
        response.mdm &&
        response.mdm.length > 0 &&
        response.mdm[0].data &&
        response.mdm[0].data.length > 0
      ) {
        const data = response.mdm[0].data;
        setOriginalData(data);
        setActivityData(data);
      } else {
        setActivityData([]);
      }
      setLoading(false);
    },
    onError: (error) => {

      setLoading(false);
    },
  });

  const onSearch = (searchString: string) => {
    if (searchString.length > 0) {
      const filteredData = originalData.filter((item) => {
        return item.name.toLowerCase().includes(searchString.toLowerCase());
      });
      setActivityData([...filteredData]);
    } else {
      setActivityData([...originalData]);
    }
  };

  const getDataFromId = (id: any) => {
    const matchedData = activityData.filter((item) => {
      return item.name === id;
    });
    if (matchedData.length > 0) {
      return {
        value: matchedData[0].value,
        name: matchedData[0].name,
      };
    }
  };

  return (
    <>
      {loading && (
        <View>
          <Skeleton rounded="md" />
        </View>
      )}
      {!loading && (
        <Select
          size="large"
          mode='multiple'
          showSearch
          allowClear
          filterOption={false}
          value={value || undefined}
          onSearch={debounce(onSearch, 500)}
          onChange={(value: any[], data: any) => {
            onChange(value || undefined);
          }}
          placeholder="Search Physical Activity"
          loading={loading}
          notFoundContent={loading && <Spin size="small" />}
          style={{height: '40px'}}
          className={isShowError && !value ? 'field-error' : ''}
        >
          {activityData.map((item, index) => {
            return (
              <Select.Option key={`${item.name}_${index}`} value={item.name}>
                {item.name}
              </Select.Option>
            );
          })}
        </Select>
      )}
    </>
  );
};

export default PhysicalActivitySearch;
