import React, {useContext, useEffect, useState} from 'react';
import {useLazyQuery, useMutation} from '@apollo/client';
import {TaskQueries} from '../../../../services';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../constants/Configs';
import {ILabelTask, ITask} from '../../CareDashboard/CareDashboardInterfaces';
import {Box, HStack, Spinner, Text, useMediaQuery, useToast} from 'native-base';
import AddOrUpdateTask from '../../AddTask/AddOrUpdateTask';
import {MLOV_CATEGORY} from '../../../../constants';
import {BottomViewAction} from '../../../TaskCard/TaskCardHelper';
import {useIntl} from 'react-intl';
import {getMlovListFromCategory} from '../../../../utils/mlovUtils';
import {getCompletedTaskStatusId} from '../../CareDashboard/CareDashboardUtils/CareDashboardUtils';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {notification} from 'antd';
import {ToastType, showToast} from '../../../../utils/commonViewUtils';
import {LABEL_TYPE_CODES} from '../../../../constants/MlovConst';

interface ITaskNotificationDrawerProps {
  taskId: string;
  visible: boolean;
  onClose: () => void;
  commentId?: string;
}

const TaskNotificationDrawer = (props: ITaskNotificationDrawerProps) => {
  const toast = useToast();
  const intl = useIntl();
  const mlovData = useContext(CommonDataContext);
  const taskStatusMlov =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.TASK_STATUS
    ) || [];
  const completedStatusId = getCompletedTaskStatusId(taskStatusMlov);

  const {taskId, visible, commentId, onClose} = props;
  const [componentState, setComponentState] = useState<{
    isLoading: boolean;
    task: ITask;
  }>({
    isLoading: true,
    task: {} as ITask,
  });
  const labels = getMlovListFromCategory(
    mlovData.MLOV,
    MLOV_CATEGORY.LABEL_TYPE
  );
  const filteredResult = labels?.filter((item) => {
    return item?.code === LABEL_TYPE_CODES.TASK;
  });
  const taskLabelMlov = filteredResult?.[0];
  const [getTaskByIdApi] = useLazyQuery(TaskQueries.GET_NON_DELETED_TASK_BY_ID, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });

  const [getLabelsData] = useLazyQuery(TaskQueries.GET_LABELS_BY_IDS, {
    fetchPolicy: 'no-cache',
  });

  const getLabels = async (labels: ILabelTask[]) => {
    const labelIds = labels?.map((label: ILabelTask) => label?.labelId)
    const getLabelsRes = await getLabelsData({
      variables: {
        labelIds: labelIds,
        labelTypeId: taskLabelMlov?.id,
      },
    });
    return getLabelsRes?.data?.labels?.map((labelData: ILabelTask) => {
      const matchedLabel = labels?.find((labelArg: ILabelTask) => labelArg?.labelId === labelData?.uuid);
      if (matchedLabel) {
        labelData.id = matchedLabel?.id;
        labelData.labelId = matchedLabel?.labelId;
      }
      return labelData;
    }) || [];
  }

  const fetchTask = async () => {
    showToast(toast, intl.formatMessage({id: 'fetchingTaskDetails'}), ToastType.info);
    const response = await getTaskByIdApi({
      variables: {
        id: taskId,
      },
    });
    let task = response?.data?.getTasks?.tasks?.[0];
    if (!task?.id) {
      toast.closeAll();
      showToast(
        toast,
        intl.formatMessage({id: 'taskIsDeleted'}),
        ToastType.info
      );
    } else {
      if (task?.parentId) {
        const parentTaskResponse = await getTaskByIdApi({
          variables: {
            id: task?.parentId,
          },
        });
        task = parentTaskResponse?.data?.getTasks?.tasks?.[0];
      }
      let labelsData = [];
      if (task?.labels?.length > 0) {
        labelsData = await getLabels(task?.labels);
      }
      toast.closeAll();
      setComponentState({
        task: {
          ...task,
          labels: labelsData,
          isCompleted: task?.statusId === completedStatusId,
        },
        isLoading: false,
      });
    }
  };

  useEffect(() => {
    if (taskId) {
      fetchTask();
    }
  }, [taskId]);

  const [updateTaskStatus] = useMutation(TaskQueries.UPDATE_TASK_STATUS, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    onCompleted: () => {
      onClose();
      showToast(
        toast,
        intl.formatMessage({id: 'taskCompletedMsg'}),
        ToastType.success,
      );
    },
    onError: (error: any) => {

      onClose();
      showToast(
        toast,
        intl.formatMessage({id: 'apiErrorMsg'}),
        ToastType.error,
      );
    },
  });
  const handleBottomViewActions = (task: ITask, action: BottomViewAction) => {
    switch (action) {
      case BottomViewAction.markAsComplete:
        updateTaskStatus({
          variables: {
            params: {
              id: task.id,
              data: {
                statusId: completedStatusId,
              },
            },
          },
        });
        break;

      default:
        break;
    }
  };

  return (
    <>
      {componentState.isLoading ? (
        <></>
      ) : !!componentState?.task && (
        <AddOrUpdateTask
          task={componentState.task}
          isVisible
          onCancel={onClose}
          onMarkAsComplete={handleBottomViewActions}
          onComplete={onClose}
        />
      )}
    </>
  );
};

export default TaskNotificationDrawer;
