export enum FormComponentLabelEnum {
  textfield = 'Small text',
  textarea = 'Large text',
  email = 'Email',
  checkbox = 'Consent',
  selectboxes = 'Multi select',
  select = 'Dropdown',
  phoneNumber = 'Phone number',
  radio = 'Single select',
  date = 'Date',
  paragraph = 'Paragraph',
  image = 'Image',
  number = 'Number',
  currency = 'Currency',
  file = 'File upload',
  signature = 'Signature',
  customsurvey = 'Table',
  columns = 'Multi column',
  panel = 'Container',
  rating = 'Rating',
}
