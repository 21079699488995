import {StyleSheet} from 'react-native';

export const AwvSvgStyles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
    margin: 0,
  },
});
