import {Formio} from '@foldhealth/formiojs';
import BasicFields from '../../EditFormFields/BasicFields';
import ConditionalComponents from '../../EditFormFields/ConditionalFields';
import KeyField from '../../EditFormFields/KeyField';

const FileComponent = Formio.Components.components.file;

export default class CustomFileComponent extends FileComponent {
  static schema(...extend: any) {
    return FileComponent.schema(
      {
        type: 'file',
        label: 'File upload',
        key: 'file',
        image: false,
        privateDownload: false,
        imageSize: '200',
        filePattern: 'image/*,application/pdf',
        fileMinSize: '1KB',
        fileMaxSize: '10MB',
        uploadOnly: false,
      },
      ...extend
    );
  }

  static get builderInfo() {
    return {
      title: 'File',
      group: 'premium',
      icon: 'file',
      documentation: '/userguide/#file',
      weight: 100,
      schema: CustomFileComponent.schema(),
    };
  }

  attach(element: any) {
    this.loadRefs(element, {
      fileStatusRemove: 'multiple',
    });
    this.refs?.fileStatusRemove?.forEach((fileStatusRemove: any, index: number) => {
      this.addEventListener(fileStatusRemove, 'click', (event: any) => {
        event?.preventDefault();
        if (this.abortUpload) {
          this.abortUpload();
        }
        this.statuses?.splice(index, 1);
        if (!this.statuses?.length) {
          this.fileDropHidden = false;
        }
        this.redraw();
      });
    });
    return super.attach(element);
  }

  getFile(fileInfo: any) {
    const {fileService} = this;
    if (!fileService) {
      return alert('File Service not provided');
    }
    if (this.component.privateDownload) {
      fileInfo.private = true;
    }
    fileService.getFileSignedURL(
      fileInfo.url,
      (data: any) => {
        const signedURL = data.url;
        if (signedURL) {
          window.open(signedURL);
        }
      },
      () => {
        alert('There is some error while downloading this file.');
      }
    );
  }
}

CustomFileComponent.editForm = function () {
  return {
    key: 'display',
    components: [
      ...BasicFields.filter((field) => {
        return field.key !== 'placeholder';
      }),
      {
        type: 'select',
        input: true,
        key: 'storage',
        label: 'Storage',
        placeholder: 'Select your file storage provider',
        tooltip: 'Which storage to save the files in.',
        valueProperty: 'value',
        defaultValue: 'base64',
        clearOnHide: false,
        hidden: true,
      },
      {
        type: 'oldtextfield',
        input: true,
        key: 'filePattern',
        label: 'File Pattern',
        placeholder: 'image/*,video/*,application/pdf',
        tooltip: 'Example image/*,video/*,application/pdf',
      },
      {
        type: 'oldtextfield',
        input: true,
        key: 'fileMaxSize',
        label: 'File Maximum Size',
        placeholder: '10MB',
        value: '10MB',
        tooltip: 'Example 10MB',
      },
      {
        type: 'checkbox',
        label: 'Hide Label',
        tooltip:
          'Hide the label or title of this component. This allows you to show the label in the form builder, but not when the form is shown to the member.',
        key: 'hideLabel',
        input: true,
      },
      ...ConditionalComponents,
      ...KeyField,
    ],
  };
};

Formio.Components.addComponent('file', CustomFileComponent);
