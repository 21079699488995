import { useLazyQuery } from '@apollo/client';
import { Select, Spin, notification } from 'antd';
import { debounce } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import ContactsQueries from '../../../services/Contacts/ContactsQueries';
import { IContactRespData } from '../../../services/Contacts/interfaces';
import { getAccountId, getBooleanFeatureFlag, isActiveContact } from '../../../utils/commonUtils';
import { ModalActionAntSelect } from '../../common/ModalActionCommonComponent/ModalActionAntSelect';
// import './PatientDropdown.css';
import { useIntl } from 'react-intl';
import MemberInfoListItem from '../../common/MemberInfoListItem/MemberInfoListItem';
import { getContactTypeId } from '../../../utils/mlovUtils';
import { getChosenNameCondition } from '../Contacts/ContactsUtils';
import {getInActiveContactError} from '../../../constants';
import {isPatientDetailViewAllowed} from '../UserAccess/UserAccessPermission';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {withMiniContactViewHOC} from '../../MiniContactViewHOC';
import { GLOBAL_CONTACTS_SEARCH_BY_ACTION } from '../../../services/Patient/PatientQueries';
import { getContactSearchActionQuery } from '../../common/PatientSearch/PatientSearchUtils';
import {IContact} from '../TeamInbox/Conversations/interfaces';
import FeatureFlags from '../../../constants/FeatureFlags.enums';



const PatientDropdownList = (props: {
  defaultValue?: any;
  selectedAllContactData?: any;
  notShowPatient?: boolean;
  showLeadAndPatient?: boolean;
  onSelectValue: (actionData?: any, contactData?: any) => void;
  openContactInWindowOrOpenContactIdDrawer?:(contactId?:string)=>void;
  locationOptionList?: any;
  isMsoEnabled?: boolean;
  useActionForPatientQuery?: boolean;
  selectedMembers?: any;
  contactTypeUuid?: string
  selectedPatientsWithContactData?: IContact[];
  returnNonLocationAccesPatientIds?: (contactIds: number[]) => void
}) => {
  const [customerData, setCustomerData] = useState([] as any);
  const [isLoading, setLoading] = useState(false);
  const intl = useIntl();
  const mlovData = useContext(CommonDataContext);
  const customerTypeId = getContactTypeId('CUSTOMER');
  const isMultiTenancyEnabled = getBooleanFeatureFlag(mlovData.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED);
  const redirectToProfile = (contactId?: number | string, contactTypeId?: string)=> {
    if (!contactId) {
      return;
    }
    if (contactTypeId === customerTypeId) {
      props?.openContactInWindowOrOpenContactIdDrawer?.(contactId.toString());
    } else {
      window.open(
        `/#/members/prospect/${contactId}`,
        '_blank',
        'noopener,noreferrer'
      )
    }
  }
  const isPatientDetailViewAllowedConfig = isPatientDetailViewAllowed(mlovData.currentUserRoles,mlovData.userPermissions);
  const showRedirectIcon = ()=> {
    return isPatientDetailViewAllowedConfig;
  }

  useEffect(() => {
    if (props?.defaultValue?.length) {
      setLoading(true)
      getContactsByIds({
        variables: {
          contactIdList: props?.defaultValue,
        },
      });
    }
  }, []);

  useEffect(() => {
     setCustomerData([])
  }, [props?.locationOptionList]);

  const [getContactsByIds] = useLazyQuery(
    ContactsQueries.GET_CONTACTS_BY_CONTACTIDS,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (resp: any) => {
        const selectedContactsWithContactData: any = []
        setLoading(false)
        let contactsData = [];
        const actionData: any = []
        const nonLocationAccessPatient: number[] = [];
        if (resp && resp.contacts && resp.contacts.length > 0) {
          contactsData = resp.contacts
            .filter((contactItem: any) => {
              if (props.useActionForPatientQuery && isMultiTenancyEnabled) {
                const locationId =
                  contactItem?.contactPracticeLocations?.[0]?.accountLocation
                    ?.uuid;
                const isLocationExist =
                  props.locationOptionList?.includes(locationId);
                if (contactItem?.uuid && isLocationExist) {
                  return true;
                } else {
                  nonLocationAccessPatient.push(contactItem.id);
                  return false;
                }
              } else {
                return true;
              }
            })
            .map((contact: any) => {
              actionData.push(contact?.id)
              selectedContactsWithContactData.push(contact)
              return {
                label: contact.name,
                value: contact?.uuid,
                key: contact?.uuid,
                contactData: contact,
              };
            });
        }
        setCustomerData(contactsData);
        props.onSelectValue(actionData, selectedContactsWithContactData);
        props.returnNonLocationAccesPatientIds?.(nonLocationAccessPatient)
        setLoading(false)
      },
      onError: (error: any) => {

      },
    }
  );

  const [getPatients] = useLazyQuery(
    props?.useActionForPatientQuery ? GLOBAL_CONTACTS_SEARCH_BY_ACTION : ContactsQueries.GetContactWithOffset,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (resp: any) => {
        const patientList = resp?.searchContacts?.contacts || resp?.contacts || [];
  
        let contactsData = [];
        const nonLocationAccessPatient: number[] = [];
        if (patientList) {
          contactsData = patientList
          .filter((contactItem: any) => contactItem?.uuid)
          .map((contact: any) => {
            return {
              label: contact.name,
              value: contact?.id,
              key: contact?.id,
              contactData: contact,
            };
          });
          setCustomerData(contactsData);
        } else {
          setCustomerData([]);
        }
        setLoading(false);
      },
      onError: (error: any) => {
      },
    }
  );

  const onChangeCustomer = (searchText: string) => {
    if (searchText) {
      onSearchCustomer(searchText);
    }
  };

  const getWhereCondition = (searchText: string, locationUuids: string[]) => {
    const where: any = {};
    const splitWords = searchText.split(' ').filter(word => word !== '')
    const chosenNameCondition = getChosenNameCondition(splitWords);

    where._or = [
       ...chosenNameCondition,
      {
        person: {
          chosenName: {
            _ilike: `%${searchText}%`,
          },
        },
      },
      {
        name: {
          _ilike: `%${searchText}%`,
        },
      },
    ];
  if (locationUuids?.length > 0 && props?.isMsoEnabled) {
    where.contactPracticeLocations = {
      accountLocation: {
        uuid: {
          _in: locationUuids
        }
      },
    }
  }
    if (!props?.showLeadAndPatient) {
      if (props.notShowPatient) {
        where.contactType = {
          typeId: {
            _nin: [customerTypeId],
          },
        };
      } else {
        where.contactType = {
          typeId: {
            _eq: customerTypeId,
          },
        };
      }
    }
    return where;
  }

  const onSearchCustomer = async (searchText: string) => {
    try {
      setLoading(true);
      if(searchText.length > 0){
        const searchActionQueryParams = getContactSearchActionQuery({
          searchString: searchText,
          ...(props.contactTypeUuid
            ? {contactTypeId: props.contactTypeUuid}
            : !props?.showLeadAndPatient &&
              (props.notShowPatient
                ? {contactTypeNotId: customerTypeId}
                : {contactTypeId: customerTypeId})),
          accountLocationIds: props?.locationOptionList,
        })
        searchActionQueryParams.limit = 15;
        searchActionQueryParams.offset = 0;
        if (props?.useActionForPatientQuery) {
          await getPatients({
            variables: {
              params: searchActionQueryParams,
            },
          });
        }
        else {
          await getPatients({
            variables: {
              where: getWhereCondition(searchText, props.locationOptionList),
              limit: 15,
              offset: 0
            },
          });
    }
    }
    } catch (e) {}
  };

  return (
    <ModalActionAntSelect
      mode={'multiple'}
      allowClear={false}
      leftMargin={'0'}
      showSearch={true}
      onSearch={debounce(onChangeCustomer, 500)}
      labelInValue={true}
      filterOption={false}
      value={props?.selectedMembers || undefined}
      isRequired={true}
      notFoundContent={intl.formatMessage({
        id: 'noMatchToSearch',
      })}
      loading={isLoading}
      placeholder={'Search'}
      onChange={(value: any) => {
        if (value) {
          const newValue = value.map((item: any) => {
            return {
              label: item?.label?.props?.contactData?.name || item?.label,
              key: item.key,
              value: item.value
            }
          })
          if (value && value.length > 0) {
            const actionData = value.map((data: any) => { return data?.value });
            const selectedContactsWithContactData = customerData?.filter((contact: any) => {
              return actionData.includes(contact?.contactData?.id)
            })?.map((contact: any) => contact?.contactData);
            const updatedSelectedContacts = [
              ...props?.selectedPatientsWithContactData || [], 
              ...selectedContactsWithContactData
            ];
            const uniqueSelectedContacts = Array.from(new Map(updatedSelectedContacts.map(item => [item.id, item])).values());
            const filteredSelectedContacts = uniqueSelectedContacts?.filter((contact: IContact) => 
              actionData?.includes(contact?.id)
            );
            props.onSelectValue(actionData, filteredSelectedContacts);
          } else {
            props.onSelectValue(undefined, [])
          }
        }
      }}
      data={customerData}
      optionProps={{ key: 'key', value: 'value', label: 'label', contactData: 'contactData' }}
      extraStyle={{ flex: 1 }}
      optionsArray={
        customerData &&
        customerData.map((option: any) => {
          return (
            <Select.Option label={option?.contactData.id} key={option?.contactData.id} value={option?.contactData.id}>
              <MemberInfoListItem
                contactData={option?.contactData}
                isPatientSearchCard
                showRedirectIcon={showRedirectIcon()}
                onRedirectToProfile={() => {
                  const isActive=isActiveContact(option?.contactData);
                  if(!isActive) {
                    const message=getInActiveContactError(option?.contactData);
                    notification.error({
                      message,
                    });
                    return;
                  }
                  const contactId=option?.contactData?.id||option?.contactId;
                  const contactTypeId=option?.contactData?.contactType?.contactType?.id;
                  redirectToProfile(contactId,contactTypeId);
                }}
                showDateOfBirth={true}
              />
            </Select.Option>
          );
        })
      }
    />
  );
};

export default withMiniContactViewHOC(PatientDropdownList);
