import React from 'react';
import {Path, Svg} from 'react-native-svg';

export const ReccuringAppointmentIcon = ({color}: {color?: string}) => {
  return (
    <Svg width="10" height="12" fill="none" viewBox="0 0 10 12">
      <Path
        stroke={color || '#fff'}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.5.5l2 2m0 0l-2 2m2-2h-7a2 2 0 00-2 2v1m2 6l-2-2m0 0l2-2m-2 2h7a2 2 0 002-2v-1"
      ></Path>
    </Svg>
  );
};

export default ReccuringAppointmentIcon;
