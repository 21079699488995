import {Image, Tooltip} from 'native-base';
import React from 'react';
import {isWeb} from '../../../utils/platformCheckUtils';
import Svg, { Path } from 'react-native-svg';

const InfoSvg = (props: {customColor?: string; customDimension?: string,isToolTip?:boolean,title?:string}) => {
  const {customColor} = props;
  const strokeColor = customColor ? customColor : '#667085';
  const svgView = (
    <Svg
      width={props.customDimension ? props.customDimension : '25'}
      height={props.customDimension ? props.customDimension : '25'}
      viewBox="0 0 25 25"
      fill="none"
    >
      <Path
        d="M11 15.5V11.5M11 7.5H11.01M21 11.5C21 17.0228 16.5228 21.5 11 21.5C5.47715 21.5 1 17.0228 1 11.5C1 5.97715 5.47715 1.5 11 1.5C16.5228 1.5 21 5.97715 21 11.5Z"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
  return (
    <>
      {props.isToolTip ? (
        <Tooltip style={{maxWidth: 320}} label={props.title || ''} placement="top">
          {svgView}
        </Tooltip>
      ) : (
        svgView
      )}
    </>
  );
};

export default React.memo(InfoSvg);
