export enum OrderSubmissionState {
  loading, success, error, cancelled
}

export enum OrderIframeResponseCode {
  success = 'success',
  cancelled = 'cancelled',
  error = 'error',
}

export interface IOrderState {
  state: OrderSubmissionState,
}

export const getIframeResponseCode = (responseCode: string | null): OrderIframeResponseCode | undefined => {
  if (responseCode === 'cancelled' || responseCode === 'canceled') {
    return OrderIframeResponseCode.cancelled;
  } else if (responseCode === 'error') {
    return OrderIframeResponseCode.error;
  } else if (responseCode === 'success') {
    return OrderIframeResponseCode.success;
  }
}

export const getMessage = (state: OrderSubmissionState) => {
  switch(state) {
    case OrderSubmissionState.success:
      return {
        title: '',
        message: 'Order has been submitted to the selected facility.',
        iconName: 'checkcircleo',
        color: 'success.500'
      }
    case OrderSubmissionState.error:
      return {
        title: 'Something went wrong',
        message: 'We encountered an error while sending the order to the facility. In case the error persists, please reach out to Fold Health support.',
        iconName: 'warning',
        color: 'error.500'
      }
    case OrderSubmissionState.cancelled:
      return {
        title: 'Order Cancelled',
        iconName: 'infocirlce',
        size: 12,
        color: 'info.500'
      }

    case OrderSubmissionState.loading:
      return {
        title: 'Submitting Order',
        message: 'Please wait while we submit your order.',
      }
  }
}
