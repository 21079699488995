import axios from 'axios';
import { CMS_BASE_URL } from '../../constants/BaseUrlConst';
import LocalStorage from '../../utils/LocalStorage';
import { createAxiosInstance } from '../../utils/APIUtils';

// constants

// export const CMS_BASE_URL = 'http://localhost:9393/cms'; // to be used for testing only
// export const CMS_BASE_URL = `https://dev.foldhealth.io/cms`;

export default class CMSService {
  private static cmsServiceInstance: CMSService;
  public cmsAxiosService;
  public fileUploadService;

  constructor() {
    if (CMSService.cmsServiceInstance) {
      throw new Error('Error');
    }

    this.cmsAxiosService = createAxiosInstance({
      baseURL: CMS_BASE_URL,
      headers: {},
    });

    // interceptor
    this.cmsAxiosService.interceptors.request.use(
      async (config) => {
        const userDataString = await LocalStorage.getItem('user');
        const userData = JSON.parse(userDataString);
        const fold_access_token = userData?.fold_access_token;
        config.headers = {
          ...config.headers,
          fold_access_token: fold_access_token,
          'x-hasura-account-id': userData?.data?.account_id,
          'x-hasura-account-uuid':  userData?.data?.accounts?.length ? userData.data.accounts[0].uuid : '',
        };
        return config;
      },
      (error) => {}
    );

    this.fileUploadService = createAxiosInstance({
      baseURL: `${CMS_BASE_URL}/upload`,
      headers: {
        'content-type': 'multipart/form-data',
      },
    });

    this.fileUploadService.interceptors.request.use(
      async (config) => {
        const userDataString = await LocalStorage.getItem('user');
        const userData = JSON.parse(userDataString);
        const fold_access_token = userData?.fold_access_token;
        config.headers = {
          ...config.headers,
          fold_access_token: fold_access_token,
        };
        return config;
      },
      (error) => {}
    );

    CMSService.cmsServiceInstance = this;
  }
  static getCMSServiceInstance(): CMSService {
    return (
      CMSService.cmsServiceInstance ??
      (CMSService.cmsServiceInstance = new CMSService())
    );
  }
}
