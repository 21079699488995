import React from 'react';
import { Svg } from 'react-native-svg';
import { Colors } from '../../../styles';

const CollapseSvg = () => {
  return (
    <Svg width="6" height="7" viewBox="0 0 6 7" fill="none">
      <path
        d="M3 4.5L5 2.5H1L3 4.5Z"
        fill={Colors.Custom.GRAY350}
        stroke={Colors.Custom.GRAY350}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </Svg>
  );
};

export default CollapseSvg;
