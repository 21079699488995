import {Formio} from '@foldhealth/formiojs';
import ConditionalComponents from '../../EditFormFields/ConditionalFields';
import KeyField from '../../EditFormFields/KeyField';
import SearchableComponentFields from '../../EditFormFields/SearchableComponentFields';
import ShareWithPatientFields from '../../EditFormFields/ShareWithPatientFields';

const SignatureComponent = Formio.Components.components.signature;

export default class CustomSignatureComponent extends SignatureComponent {
  resetStyles() {
    this.refs?.signatureImage?.setAttribute('style', 'width: 100%; left: 0%;top: 0%;position: inherit; height: 100%; object-fit: contain;border: 1px solid #e5e5e5;border-radius: 4px;box-shadow: 0 0 5px rgb(0 0 0 / 2%) inset;');
  }

  checkSize(force: boolean, scale: number) {
    if (this.refs.padBody && (force || this.refs.padBody && this.refs.padBody.offsetWidth !== this.currentWidth)) {
      this.scale = force ? scale : this.scale;
      this.currentWidth = this.refs.padBody.offsetWidth;
      const width = this.currentWidth * this.scale;
      this.refs.canvas.width = width;
      const height = this.ratio ? width / this.ratio : this.refs.padBody.offsetHeight * this.scale;
      this.refs.canvas.height = height;
      this.refs.canvas.style.maxWidth = "".concat(`${this.currentWidth * this.scale}`, "px");
      this.refs.canvas.style.maxHeight = "".concat(`${this.refs.padBody.offsetHeight * this.scale}`, "px");
      const ctx = this.refs.canvas.getContext('2d');
      ctx.setTransform(1, 0, 0, 1, 0, 0);
      ctx.scale(1 / this.scale, 1 / this.scale);
      ctx.fillStyle = this.signaturePad.backgroundColor;
      ctx.fillRect(0, 0, this.refs.canvas.width, this.refs.canvas.height);
      this.signaturePad.clear();

      if (this.dataValue) {
        this.setDataToSigaturePad();
      }
      this.showCanvas(true);
      if (this.signedURL && !this.isResetSignature) {
        this.refs.signatureImage.setAttribute('src', this.signedURL);
        this.resetStyles();
        this.showCanvas(false);
      }
    }
  }

  setValue(value: any, flags = {}) {
    if (this.fetchAPIInProgress) {
      return;
    }
    const changed = super.setValue(value, flags);
    if (
      value &&
      !(typeof value === 'string' && value?.includes?.('base64')) &&
      this.refs.signatureImage &&
      !this.isResetSignature
    ) {
      this.showCanvas(false);
      if (this.signedURL) {
        this.refs.signatureImage.setAttribute('src', this.signedURL);
        this.resetStyles();
        this.showCanvas(false);
      } else {
        this.fetchAPIInProgress = true;
        this.refs.signatureImage.setAttribute('style', 'width: 10%;left: 45%;top: 25%;position: absolute; height: auto; object-fit: contain;border: none;');
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        this.refs.signatureImage.setAttribute('src', require('../../../../../../assets/images/loading.gif'));

        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const this1 = this;
        const signatureImage = this.refs.signatureImage;
        this.fileService.getFileSignedURL(value.url, (data: any) => {
          this1.signedURL = data.url || data.data?.url;
          this1.fetchAPIInProgress = false;
          if (this1.signedURL) {
            signatureImage.setAttribute('src', this1.signedURL);
            this.showCanvas(false);
          } else {
            this.showCanvas(true);
          }
          this1.resetStyles();

        });
      }
    } else {
      this.resetStyles();
      if (this.signaturePad) {
        if (!value) {
          this.signaturePad.clear();
        } else if (changed) {
          this.triggerChange();
        }
      }
      if (this.signaturePad && this.dataValue && this.signaturePad.isEmpty()) {
        this.setDataToSigaturePad();
      }
    }

    return changed;
  }

  attach(element: any) {
    this.component.backgroundColor = 'rgb(255, 255, 255)';
    const superAttach = super.attach(element);
    if (this.signaturePad) {
      this.signaturePad.dotSize = 3;
    }
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _this = this;
    this.loadRefs(element, {
      canvas: 'single',
      refresh: 'single',
      padBody: 'single',
      signatureImage: 'single'
    });

    this.addEventListener(this.refs.refresh, 'click', function (event: any) {
      event.preventDefault();
      _this.isResetSignature = true;
      _this.showCanvas(true);

      _this.signaturePad.clear();

      _this.setValue(_this.defaultValue);
    });
    this.setValue(this.dataValue);
    return superAttach;
  }
  /* eslint-enable max-statements */

  detach() {
    if (this.observer) {
      this.observer.disconnect();
      this.observer = null;
    }
    if (this.signaturePad) {
      this.signaturePad.off();
    }
    this.signaturePad = null;
    this.currentWidth = 0;
    super.detach();
  }
}

SignatureComponent.editForm = function () {
  return {
    key: 'display',
    components: [
      {
        type: 'oldtextfield',
        key: 'label',
        label: 'Label',
        input: true,
        validate: {
          required: true,
        },
      },
      {
        type: 'checkbox',
        key: 'validate.required',
        label: 'Required',
        input: true,
      },
      ...ShareWithPatientFields,
      ...SearchableComponentFields,
      {
        type: 'oldtextfield',
        input: true,
        key: 'footer',
        label: 'Footer Label',
        tooltip: 'The footer text that appears below the signature area.',
        placeholder: 'Footer Label',
        weight: 10,
      },
      // KEPT FOR FUTURE
      // {
      //   type: 'checkbox',
      //   label: 'Hide Label',
      //   tooltip:
      //     'Hide the label or title of this component. This allows you to show the label in the form builder, but not when the form is shown to the member.',
      //   key: 'hideLabel',
      //   input: true,
      // },
      ...ConditionalComponents,
      ...KeyField,
    ],
  };
};

Formio.Components.addComponent('signature', CustomSignatureComponent);
