import React from 'react';
import {CLOUD_TELEPHONY_CODE} from '../../../../../../constants';
import CallIncomingSquareAvatar from '../../../../../common/Svg/ConversationAllSvg/CallIncomingSquareAvatar';
import CallMissedSquareAvatar from '../../../../../common/Svg/ConversationAllSvg/CallMissedSquareAvatar';
import CallOutgoingSquareAvatar from '../../../../../common/Svg/ConversationAllSvg/CallOutgoingSquareAvatar';
import ChatSquareAvatarSvg from '../../../../../common/Svg/ConversationAllSvg/ChatSquareAvatarSvg';
import ConversationSelectionSvg from '../../../../../common/Svg/ConversationAllSvg/ConversationSelectionSvg';
import MailSquareAvatar from '../../../../../common/Svg/ConversationAllSvg/MailSquareAvatar';
import SMSSquareAvatar from '../../../../../common/Svg/ConversationAllSvg/SMSSquareAvatar';
import EFaxSquareAvatar from '../../../../../common/Svg/PersonActionSvgIcons/EFaxSquareAvatar';
import {CHANNEL_TYPE, CHANNEL_TYPE_CODE} from '../../ConversationConst';
import {IConversationData} from '../../interfaces';
import {GROUP_TYPE_CODES} from '../../../../../../constants/MlovConst';

const GetConversationTypeAvatarV2 = ({
  channelType,
  callLastActivityCode,
  selectedInboxTypeCode,
  enableConversationSelection,
  groupTypeCode,
}: {
  channelType?: string;
  groupTypeCode?: string;
  callLastActivityCode?: string;
  selectedInboxTypeCode?: string;
  enableConversationSelection?: boolean;
}): JSX.Element => {
  const isInternalChatGroup = groupTypeCode === GROUP_TYPE_CODES.INTERNAL;
  if (enableConversationSelection) {
    return <ConversationSelectionSvg width={36} height={36} />;
  }
  if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MISSED_CALLS) {
    if (callLastActivityCode) {
      if (callLastActivityCode === CLOUD_TELEPHONY_CODE.INCOMING) {
        return <CallIncomingSquareAvatar width={36} height={36} />;
      } else if (callLastActivityCode === CLOUD_TELEPHONY_CODE.OUTGOING) {
        return <CallOutgoingSquareAvatar width={36} height={36} />;
      } else if (callLastActivityCode === CLOUD_TELEPHONY_CODE.MISSED_CALL) {
        return <CallMissedSquareAvatar width={36} height={36} />;
      }
    }
    return <CallMissedSquareAvatar width={36} height={36} />;
  } else if (channelType) {
    const type = channelType || '';
    if (type === CHANNEL_TYPE.CHANNEL_EMAIL) {
      return <MailSquareAvatar width={36} height={36} />;
    } else if (type === CHANNEL_TYPE.CHANNEL_TWILIO_SMS) {
      if (callLastActivityCode) {
        if (callLastActivityCode === CLOUD_TELEPHONY_CODE.INCOMING) {
          return <CallIncomingSquareAvatar width={36} height={36} />;
        } else if (callLastActivityCode === CLOUD_TELEPHONY_CODE.OUTGOING) {
          return <CallOutgoingSquareAvatar width={36} height={36} />;
        } else if (callLastActivityCode === CLOUD_TELEPHONY_CODE.MISSED_CALL) {
          return <CallMissedSquareAvatar width={36} height={36} />;
        }
      }
      return <SMSSquareAvatar width={36} height={36} />;
    } else if (type === CHANNEL_TYPE.CHANNEL_EFAX) {
      return <EFaxSquareAvatar width={36} height={36} />;
    } else {
      return (
        <ChatSquareAvatarSvg
          isInternalChat={isInternalChatGroup ? true : false}
          width={36}
          height={36}
        />
      );
    }
  } else {
    return (
      <ChatSquareAvatarSvg
        isInternalChat={isInternalChatGroup ? true : false}
        width={36}
        height={36}
      />
    );
  }
  return <></>;
};

export default React.memo(GetConversationTypeAvatarV2);
