import React from 'react';
import {Text} from 'react-native';
import {VStack} from 'native-base';
import {Colors} from '../../../../../../../styles';
import {isIOS, isWeb} from '../../../../../../../utils/platformCheckUtils';
import { styles } from '../../../../Analytics/style';

interface IValueHeighlighProps {
  value: string;
  unit: string;
  type: string;
  hideUnit?: boolean;
  subValueView?: JSX.Element;
}

const ValueHighlight = (props: IValueHeighlighProps) => {
  return (
    <VStack flex={1} marginRight={2}>
      <Text
        style={styles.textStyle5}
      >
        {props.value}
        {!props.hideUnit && (
          <Text
            style={styles.textStyle4}
          >
            {` ${props.unit}`}
          </Text>
        )}
      </Text>
      {props?.subValueView ? (
        props?.subValueView
      ) : (
        <Text
          style={styles.textStyle3}
        >
          {props.type}
        </Text>
      )}
    </VStack>
  );
};

export default ValueHighlight;
