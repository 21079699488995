import {useLazyQuery} from '@apollo/client';
import {useContext, useEffect, useState} from 'react';
import {Drawer, Skeleton} from 'antd';
import {useMediaQuery, Text} from 'native-base';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../constants/Configs';
import {
  CARE_PROGRAM_CONSENT_TYPE,
  MLOV_CATEGORY,
} from '../../../constants/MlovConst';
import {
  BUTTON_TYPE,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH,
  SMALL_WINDOW_1700,
} from '../../../constants/StringConst';
import {CommonDataContext} from '../../../context/CommonDataContext';
import FormResponseView from '../FormResponseView/FormResponseView';
import {Colors} from '../../../styles/Colors';
import Stack from '../LayoutComponents/Stack';
import {ModalActionTitle} from '../ModalActionTitle/ModalActionTitle';
import {
  GET_CONSENT_ASSESSMENT_FORM_LOG_DATA,
  GET_CONTACT_CARE_PROGRAM_ASSESSMENT_LOG_DATA,
} from '../../../services/ContactCareProgram/ContactCareProgram';
import {Dimensions} from 'react-native';

export interface ICareProgramAssessmentViewProps {
  contactCareProgramId: string;
  stepId: string;
  onClose: (action: boolean) => void;
}

export const CareProgramAssessmentResponseView = (
  props: ICareProgramAssessmentViewProps,
) => {
  const {stepId, contactCareProgramId, onClose} = props;
  const contextData = useContext(CommonDataContext);
  const screenDimensions = Dimensions.get('window');
  const smallWindow = screenDimensions?.width < SMALL_WINDOW_1700;
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const [assessmentState, setAssessmentState] = useState<{
    loading: boolean;
    assessmentForm?: {
      contactId: string;
      formId: string;
      formLogId: string;
      formName: string;
    };
  }>({
    loading: false,
  });

  const [getCareProgramAssessment] = useLazyQuery(
    GET_CONTACT_CARE_PROGRAM_ASSESSMENT_LOG_DATA,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    },
  );

  const [getCareProgramAssessmentFormLogData] = useLazyQuery(
    GET_CONSENT_ASSESSMENT_FORM_LOG_DATA,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    },
  );

  const getAssessmentLog = async () => {
    try {
      const response = await getCareProgramAssessment({
        variables: {
          contactCareProgramId: contactCareProgramId,
        },
      });
      const contactCarePrograms = response?.data?.contactCarePrograms;
      const assessmentLogData = contactCarePrograms?.length
        ? contactCarePrograms?.[0]
        : {};

      if (
        assessmentLogData?.assessmentLog?.resourceId &&
        assessmentLogData?.assessmentLog?.resourceTypeCode == 'FORM_LOG'
      ) {
        const formLogResponse = await getCareProgramAssessmentFormLogData({
          variables: {
            formLogId: assessmentLogData?.assessmentLog?.resourceId,
          },
        });
        const consentFormLogData = formLogResponse?.data?.formLog;
        const formData = {
          contactId: consentFormLogData.contactId,
          formId: consentFormLogData?.formResponse?.form?.id,
          formLogId: consentFormLogData?.id,
          formName: consentFormLogData?.formResponse?.form?.name,
        };
        setAssessmentState(prev => {
          return {
            ...prev,
            loading: false,
            assessmentForm: formData,
          };
        });
      } else {
        setAssessmentState(prev => {
          return {
            ...prev,
            loading: false,
          };
        });
      }
    } catch (err) {
      setAssessmentState(prev => {
        return {
          ...prev,
          loading: false,
        };
      });
    }
  };

  useEffect(() => {
    if (!assessmentState.loading) {
      setAssessmentState(prev => {
        return {
          ...prev,
          loading: true,
        };
      });
      getAssessmentLog();
    }
  }, []);

  return (
    <>
      <Drawer
        destroyOnClose
        placement="right"
        open={true}
        closable
        width={
          isIPadScreen || isIPadMiniScreen ? '60%' : smallWindow ? '50%' : '40%'
        }
        onClose={() => {
          onClose(false);
        }}
        title={
          <ModalActionTitle
            title={'Assessment Response'}
            titleColor={''}
            buttonList={[
              {
                show: true,
                id: 1,
                btnText: 'cancel',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
                isTransBtn: false,
                onClick: () => {
                  onClose(false);
                },
              },
            ]}
          />
        }>
        {assessmentState?.loading && <Skeleton active />}
        {!assessmentState?.loading && (
          <Stack direction="column" space={24}>
            {assessmentState.assessmentForm?.formId && (
              <FormResponseView
                onClose={() => {
                  onClose(false);
                }}
                contactId={assessmentState.assessmentForm?.contactId}
                formId={assessmentState.assessmentForm?.formId || ''}
                formLogId={assessmentState.assessmentForm?.formLogId}
                formName={assessmentState.assessmentForm?.formName || ''}
                showPatientBanner={true}
                drawerWidth={
                  isIPadScreen || isIPadMiniScreen
                    ? '60%'
                    : smallWindow
                    ? '50%'
                    : '40%'
                }
                titleText={'Assessment Response'}
                isInlineView={true}
              />
            )}
          </Stack>
        )}
      </Drawer>
    </>
  );
};
