import React from 'react';
import Svg, { Path } from "react-native-svg";
import { Colors } from '../../../styles/Colors';

const LogTimeSvg = (props: {strokeColor?: string}) => {
  return (
    <Svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <Path
        d="M10 9.16669C10.2761 9.16669 10.5 8.94284 10.5 8.66669C10.5 8.39055 10.2761 8.16669 10 8.16669V9.16669ZM6 8.16669C5.72386 8.16669 5.5 8.39055 5.5 8.66669C5.5 8.94284 5.72386 9.16669 6 9.16669V8.16669ZM8.5 6.66668C8.5 6.39053 8.27614 6.16668 8 6.16668C7.72386 6.16668 7.5 6.39053 7.5 6.66668H8.5ZM7.5 10.6667C7.5 10.9428 7.72386 11.1667 8 11.1667C8.27614 11.1667 8.5 10.9428 8.5 10.6667H7.5ZM2.06833 2.57601C1.83417 2.72236 1.76298 3.03084 1.90933 3.26501C2.05569 3.49918 2.36416 3.57036 2.59833 3.42401L2.06833 2.57601ZM5.26501 1.75734C5.49918 1.61099 5.57037 1.30251 5.42402 1.06835C5.27766 0.834177 4.96919 0.762989 4.73502 0.909344L5.26501 1.75734ZM13.4017 3.42401C13.6358 3.57036 13.9443 3.49918 14.0907 3.26501C14.237 3.03084 14.1658 2.72237 13.9317 2.57601L13.4017 3.42401ZM11.265 0.909344C11.0308 0.762989 10.7224 0.834176 10.576 1.06834C10.4296 1.30251 10.5008 1.61099 10.735 1.75734L11.265 0.909344ZM13.5 8.66668C13.5 11.7042 11.0376 14.1667 8 14.1667V15.1667C11.5899 15.1667 14.5 12.2565 14.5 8.66668H13.5ZM8 14.1667C4.96243 14.1667 2.5 11.7042 2.5 8.66668H1.5C1.5 12.2565 4.41015 15.1667 8 15.1667V14.1667ZM2.5 8.66668C2.5 5.62911 4.96243 3.16668 8 3.16668V2.16668C4.41015 2.16668 1.5 5.07683 1.5 8.66668H2.5ZM8 3.16668C11.0376 3.16668 13.5 5.62911 13.5 8.66668H14.5C14.5 5.07683 11.5899 2.16668 8 2.16668V3.16668ZM10 8.16669H8V9.16669H10V8.16669ZM8 8.16669H6V9.16669H8V8.16669ZM7.5 6.66668L7.5 8.66669L8.5 8.66669L8.5 6.66668H7.5ZM7.5 8.66669V10.6667H8.5V8.66669H7.5ZM2.59833 3.42401L5.26501 1.75734L4.73502 0.909344L2.06833 2.57601L2.59833 3.42401ZM13.9317 2.57601L11.265 0.909344L10.735 1.75734L13.4017 3.42401L13.9317 2.57601Z"
        fill={props?.strokeColor || Colors.FoldPixel.GRAY300}
      />
    </Svg>
  );
};

export default LogTimeSvg;
