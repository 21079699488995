import {Button, Text, View} from 'native-base';
import React from 'react';
import {useIntl} from 'react-intl';
import {BUTTON_TYPE} from '../../../../constants';
import {DisplayText} from '../../../common/DisplayText/DisplayText';
import {FoldButton} from '../../../CommonComponents/FoldButton/FoldButton';
import {Modal} from 'antd';
import {Colors} from '../../../../styles/Colors';
import { TestIdentifiers, testID } from '../../../../testUtils';

export interface IModalTextContent {
  message?: string;
  messageId?: string;
  headerText?: string;
  headerTextId?: string;
}

export type IButtonTypes =
  typeof BUTTON_TYPE[keyof typeof BUTTON_TYPE];

export interface IOverrideModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  textContent: IModalTextContent;
  closeButtonText?: string;
  confirmButtonText?: IButtonTypes;
  closeButtonType?: string;
  confirmButtonType?: IButtonTypes;
  confirmButtonLoading?: boolean;
}

const OverrideAntdModal = (props: IOverrideModalProps) => {
  const {isOpen, onClose, onConfirm, textContent} = props;
  const {message, messageId, headerText, headerTextId} = textContent;
  const intl = useIntl();
  return (
    <Modal
      title={
        <>
          {headerText && (
            <Text {...testID(TestIdentifiers.headerTitle)} size={'smMedium'} fontWeight={600}>
              {headerText}
            </Text>
          )}
          {!headerText && headerTextId && (
            <Text>
              <DisplayText textLocalId={headerTextId} textType="Heading" />
            </Text>
          )}
        </>
      }
      open={isOpen}
      closable
      maskClosable
      destroyOnClose
      onCancel={() => {
        onClose();
      }}
      footer={null}
    >
      <View
        flex={1}
        mt={-6}
        mb={2}
        backgroundColor={Colors.Custom.Gray300}
        height="1px"
      ></View>
      {message && (
        <View ml={4}>
          <Text {...testID(TestIdentifiers.alertMessage)}>{message}</Text>
        </View>
      )}

      {!message && messageId && (
        <Text>
          <DisplayText textLocalId={messageId} textType="Text" />
        </Text>
      )}
      <Button.Group
        style={{
          backgroundColor: Colors.Custom.BackgroundColor,
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: 20,
          marginRight: 10,
        }}
      >
        <FoldButton
          nativeProps={{
            variant: props?.closeButtonType ? props?.closeButtonType : BUTTON_TYPE.SECONDARY,
            onPress: () => {
              onClose();
            },
          }}
          customProps={{
            btnText: intl.formatMessage({
              id: props?.closeButtonText || 'close',
            }),
            withRightBorder: false,
          }}
        ></FoldButton>
        <FoldButton
          nativeProps={{
            variant: props?.confirmButtonType ? props?.confirmButtonType : BUTTON_TYPE.PRIMARY,
            onPress: () => {
              onConfirm();
            },
            isLoading: props?.confirmButtonLoading || false,
          }}
          customProps={{
            btnText: intl.formatMessage({
              id: props?.confirmButtonText || 'confirm',
            }),
            withRightBorder: false,
          }}
        ></FoldButton>
      </Button.Group>
    </Modal>
  );
};

export default OverrideAntdModal;
