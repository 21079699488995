import {Extension, QuestionnaireItem} from 'fhir/r4';

export interface ICustomEHRAttributesComponentState {
  isLoading: boolean;
  error: boolean;
  fieldList: IEhrFieldList[];
  emptyFieldList: IEhrFieldList[];
  filledFieldList: IEhrFieldList[]
}

export interface ICustomEHRAttributesProps {
  prevResponse: any[];
  updateEhrAttributes: (list:IEhrFieldList[]) => void
  useInline?: boolean
  leadId?:any
}

export interface IEhrAttributeResponse {
  extension: Extension[];
  code: string;
  display: string;
}

export interface IEhrFieldList {
  value: string;
  key: string;
  label: string;
  type: IEhrFieldType;
  possibleValues: IEhrFieldOption[];
  display?:string
}

export enum IEhrFieldType {
  CHOICE = 'choice',
  TEXT = 'text',
  DATE = 'date',
  INTEGER = 'integer',
  NUMBER = 'number'
}
export interface IEhrFieldOption {
  code: string;
  display: string;
}


export interface ICustomEhrAttributePayloadFormat {
  resourceType: string;
  item: {
    text: string;
    linkId: string;
    answer: any[];
  }[];
}