import {Modal} from 'antd';
import {Content} from 'antd/lib/layout/layout';
import {Button, ITextProps, View} from 'native-base';
import React from 'react';
import {ViewStyle} from 'react-native';
import {DisplayText} from '../../../../../common/DisplayText/DisplayText';
import {getConfirmationMessageByAction} from '../../../ContactsUtils';
import {styles} from './ConfirmationModalStyle';

export interface IConfirmationBtn {
  btnText: string;
  onBtnPress: () => void;
  btnStyle?: ViewStyle;
  btnIcon?: JSX.Element;
  btnColorScheme?: 'dark' | 'primary';
  btnVariant?: 'solid' | 'subtle' | 'outline';
  btnTextStyle?: ITextProps;
}

export interface IConfirmationModalProps {
  isModalOpen: boolean;
  onModalClose: () => void;
  btnList: IConfirmationBtn[];
  actionType: string;
}

// const {Body, Content, Footer, Header, CloseButton} = Modal;

const ConfirmationModal = (props: IConfirmationModalProps) => {
  const {isModalOpen, actionType, btnList, onModalClose} = props;

  const confirmationMessage =
    getConfirmationMessageByAction(actionType).message;
  const modalTitle = getConfirmationMessageByAction(actionType).modalTitle;

  return (
    <Modal open={isModalOpen}
    // onClose={() => onModalClose()}
      onCancel={onModalClose}
      title={<DisplayText textLocalId={modalTitle} textType="Heading" />}
      footer={[<Button.Group justifyContent={'flex-end'}>
        {btnList.map((button, index) => {
          const btnTextStyle = button.btnTextStyle;
          return (
            <Button
              style={[styles.btnStyle, button.btnStyle]}
              key={index}
              onPress={() => button.onBtnPress()}
              colorScheme={button.btnColorScheme}
              variant={button.btnVariant}
              _text={btnTextStyle}
            >
              {button.btnText}
            </Button>
          );
        })}
      </Button.Group>]}
    >
           <View style={styles.container}>
             <View>
              <DisplayText textLocalId={confirmationMessage} textType="Text" />
            </View>

      </View>
    </Modal>
  );
};

export default ConfirmationModal;
