import {Box, HStack, Text} from 'native-base';
import {FC} from 'react';
import {NodeProps} from 'reactflow';
import {Colors} from '../../../../styles/Colors';
import {Handle, Position} from 'reactflow';
import {DisplayCardAvatar} from '../../../common/DisplayCard/DisplayCardAvatar';
import {GROUP_MEMBER_TYPE} from '../../../../constants';

const CareTeamNodeType: FC<NodeProps> = ({data}) => {
  return (
    <Box
      padding={2}
      margin={10}
      overflowWrap={'normal'}
      rounded="md"
      borderColor={Colors.Custom.Gray100}
      borderWidth={1}
      shadow={1}
      backgroundColor={Colors.primary['100'] + '7a'}
      minWidth={40}
      minHeight={20}
      maxWidth={50}
      textAlign="center"
      alignItems={'center'}
      alignContent={'flex-start'}
    >
      {!data?.rootNode && <Handle type="target" position={Position.Top} />}
      {data.nodeType !== 'Journey' && (
        <HStack flexWrap={'wrap'} alignContent={'center'}>
          <DisplayCardAvatar
                                avatarStyle={{
                                  avatarSize: '12',
                                }}
                                isLetterAvatarShow
                                userData={{
                                  userId: data?.id,
                                  userType: data.nodeType === 'Patient' ? GROUP_MEMBER_TYPE.CUSTOMER : GROUP_MEMBER_TYPE.USER,
                                  userName: data?.name
                                }}
                              />
        </HStack>
      )}
      <HStack>
        <Text>{data?.name}</Text>
      </HStack>
      {data.nodeType === 'CareTeam' && data?.role && (
        <HStack>
          <Text fontStyle={'italic'}> ({data?.role})</Text>
        </HStack>
      )}
      {!data?.footNode && <Handle type="source" position={Position.Bottom} />}
    </Box>
  );
};

export default CareTeamNodeType;
