export enum activityTabsCode {
    ALL = 'all',
    COMMENTS = 'comments',
    HISTORY = 'history',
}

export enum TaskViewTabsCode {
  ACTIVITY= 'activity',
  AUTOMATION= 'automation'
}

export const activityTabs
 = [
    { key: 0, code: activityTabsCode?.ALL, title: 'All' },
    { key: 1, code: activityTabsCode?.COMMENTS, title: 'Comments' },
    { key: 2, code: activityTabsCode?.HISTORY, title: 'History' }
  ]

  export const ACTION_PERFORMER_TYPE = {
    USER: 'USER',
  };

export const TASK_VIEW_TABS = [
  { key: 0, code: TaskViewTabsCode?.ACTIVITY, title: 'Activity' },
  { key: 1, code: TaskViewTabsCode?.AUTOMATION, title: 'Automation' },
]