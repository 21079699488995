import { GROUP_MEMBER_TYPE } from "../../../constants";
import { IGroupMember } from "../../../services/Conversations/interfaces";
import {IMessageUnreadUserAndContactFinalList} from "../ChatUI/MessageBox/MessageReadInfo/interface";

export  const getMemberContent = (member: IGroupMember, inboxId?: number) => {
  const memberName = getMemberName(member, inboxId);
  const encodedName = encodeURIComponent(memberName);
  let selectedUser = '';
  if (member?.groupUserType?.code === GROUP_MEMBER_TYPE.USER) {
    selectedUser = `[@${memberName}](mention://user/${member.groupUserId}/user_uuid/${encodedName})`;
  } else if (
    member?.groupUserType?.code === GROUP_MEMBER_TYPE.PATIENT ||
    member?.groupUserType?.code === GROUP_MEMBER_TYPE.CONTACT
  ) {
    selectedUser = `[@${memberName}](mention://contact/${member.groupUserId}/contact_uuid/${encodedName})`;
  } else if (inboxId && inboxId !== -1) {
    selectedUser = `[@${memberName}](mention://user/${member?.user?.uuid}/user_uuid/${encodedName})`;
  }
  return selectedUser;
};

export const getMemberName = (memberData: IGroupMember, inboxId?: number): string => {
  let name = '';
  if (memberData?.groupUserType?.code === GROUP_MEMBER_TYPE.USER) {
    name = memberData.user?.name || '';
  } else if (
    memberData?.groupUserType?.code === GROUP_MEMBER_TYPE.PATIENT ||
    memberData?.groupUserType?.code === GROUP_MEMBER_TYPE.CONTACT
  ) {
    name = memberData.contact?.name || '';
  } else if (inboxId && inboxId !== -1) {
    name = memberData?.user?.name || '';
  }
  return name;
};

export const getMemberNameForReamMessageInfo = (memberData: IMessageUnreadUserAndContactFinalList): string => {
  let name = '';
  if (memberData.groupUserType.code === GROUP_MEMBER_TYPE.USER) {
    name = memberData.user?.name || '';
  } else if (
    memberData.groupUserType.code === GROUP_MEMBER_TYPE.PATIENT ||
    memberData.groupUserType.code === GROUP_MEMBER_TYPE.CONTACT
  ) {
    name = memberData.contact?.name || '';
  }
  return name;
};

export  const getMemberContentForTask = (member: IGroupMember) => {
  const memberName = getMemberName(member);
  let selectedUser = '';
  if (member?.groupUserType?.code === GROUP_MEMBER_TYPE.USER) {
    selectedUser = `[@${memberName}](mention://user/${member?.groupUserId})`;
  } else if (
    member?.groupUserType?.code === GROUP_MEMBER_TYPE.PATIENT ||
    member?.groupUserType?.code === GROUP_MEMBER_TYPE.CONTACT
  ) {
    selectedUser = `[@${memberName}](mention://contact/${member?.groupUserId})`;
  }
  return selectedUser;
};
