import { View } from 'native-base';
import React from 'react';
import Feather from 'react-native-vector-icons/Feather';
import { Colors } from '../../styles/Colors';
import { isWeb } from '../../utils/platformCheckUtils';

const EditSvg = () => {
  return (
    <>{isWeb() ?
    <svg
      style={{width: 'inherit', height: 'inherit'}}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="35" height="35" rx="17.5" fill='transparent' />
      <path
        d="M18 24.6662H25.5M21.75 10.9162C22.0815 10.5847 22.5312 10.3984 23 10.3984C23.2321 10.3984 23.462 10.4442 23.6765 10.533C23.891 10.6218 24.0858 10.7521 24.25 10.9162C24.4142 11.0804 24.5444 11.2752 24.6332 11.4897C24.722 11.7042 24.7678 11.9341 24.7678 12.1662C24.7678 12.3984 24.722 12.6282 24.6332 12.8427C24.5444 13.0572 24.4142 13.2521 24.25 13.4162L13.8333 23.8329L10.5 24.6662L11.3333 21.3329L21.75 10.9162Z"
        stroke="#667085"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect x="0.5" y="0.5" width="35" height="35" rx="17.5" stroke="transparent" />
    </svg>
    :
    <View
      style={[
        {
          borderWidth: 0.5,
          borderColor: Colors.Custom.Gray200,
          width: 36,
          height: 36,
          borderRadius: 18,
          backgroundColor: 'white',
          justifyContent: 'center',
          alignItems: 'center',
        },
      ]}
    >
      <Feather
        name="edit-2"
        size={15}
        color={Colors.Custom.Gray600}
      />
      </View>
    }
    </>
  );
};

export default EditSvg;
