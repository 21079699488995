import {
  Button,
  HStack,
  Modal,
  Pressable,
  Text,
  View,
  VStack,
} from 'native-base';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {Platform} from 'react-native';
import {COMMON_ACTION_CODES} from '../../../../../../constants/ActionConst';
import {GROUP_TYPE_CODES} from '../../../../../../constants/MlovConst';
import {
  BUTTON_TYPE,
  GROUP_MEMBER_TYPE,
} from '../../../../../../constants/StringConst';
import {Colors} from '../../../../../../styles/Colors';
import {isWeb} from '../../../../../../utils/platformCheckUtils';
import {DisplayCardAvatar} from '../../../../../common/DisplayCard/DisplayCardAvatar';
import {DisplayText} from '../../../../../common/DisplayText/DisplayText';
import ModalActionBtn from '../../../../../common/ModalActionBtn/ModalActionBtn';
import {FoldButton} from '../../../../../CommonComponents/FoldButton/FoldButton';
import {getAllGroupMemberListNames} from '../ConversationUtils';
import {IExistingGroupResp} from './interfaces';
import { StyleSheet } from 'react-native';
const ExistingGroupModal = (props: {
  groupList: IExistingGroupResp[];
  showExistingGroupModal: boolean;
  onExistingGroupActionPerformed: (
    actionCode: string,
    actionData?: any
  ) => void;
}) => {
  const [existingGroupModalState, setExistingGroupModalState] = useState({
    showModal: false,
    selectedGroup: {} as IExistingGroupResp,
  });
  const intl = useIntl();
  const {showExistingGroupModal, groupList, onExistingGroupActionPerformed} =
    props;
  const onCloseModal = () => {
    setExistingGroupModalState((prev) => {
      return {
        ...prev,
        showModal: false,
      };
    });
    onExistingGroupActionPerformed(COMMON_ACTION_CODES.CLOSE_MODAL);
  };

  const getGroupInfoItem = (groupInfo: IExistingGroupResp) => {
    const groupUserInfo = getAllGroupMemberListNames(groupInfo.groupMembers);
    if (groupInfo?.name) {
      return (
        <HStack
          flex={1}
          flexDirection={'row'}
          style={styles.modalContent}
        >
          <HStack>
            <VStack>
              <DisplayCardAvatar
                avatarStyle={{
                  avatarSize: '12',
                }}
                isLetterAvatarShow={true}
                userData={{
                  userId: '',
                  userType: GROUP_MEMBER_TYPE.CONTACT,
                  userName: groupInfo?.name
                }}
                groupType={GROUP_TYPE_CODES.PRIVATE as any}
              />
            </VStack>

            <VStack style={styles.groupNameContainer}>
              <HStack>
                <View
                  flexDirection={'row'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  marginLeft={2}
                >
                  <Text
                    size={'smBold'}
                    color={'coolGray.700'}
                    justifyContent={'center'}
                  >
                    {groupInfo?.name}
                  </Text>
                </View>
              </HStack>
              <HStack>
                <View
                  flexDirection={'row'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  marginLeft={2}
                >
                  <Text
                    size={'xsLight'}
                    color={'coolGray.700'}
                    justifyContent={'center'}
                  >
                    {groupUserInfo.remainingUsers}
                  </Text>
                </View>
              </HStack>
            </VStack>
          </HStack>
        </HStack>
      );
    } else {
      return (
        <HStack
          flex={1}
          flexDirection={'row'}
          style={styles.modalContent}
        >
          <HStack>
            <VStack>
              <DisplayCardAvatar
                avatarStyle={{
                  avatarSize: '12',
                }}
                isLetterAvatarShow
                userData={{
                  userId: '',
                  userType: GROUP_MEMBER_TYPE.CONTACT,
                  userName: groupUserInfo.firstUserName
                }}
                groupType={GROUP_TYPE_CODES.PRIVATE as any}
              />
            </VStack>

            <VStack style={styles.groupNameContainer}>
              <HStack>
                <View
                  flexDirection={'row'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  marginLeft={2}
                >
                  <Text
                    size={'smBold'}
                    color={'coolGray.700'}
                    justifyContent={'center'}
                  >
                    {groupUserInfo.firstUserName}
                    {' +'}
                    {groupInfo.groupMembers?.length - 1}
                  </Text>
                </View>
              </HStack>
            </VStack>
          </HStack>
        </HStack>
      );
    }
  };

  const getBgColorForGroupItem = (
    currentItem: IExistingGroupResp,
    selectedGroup: IExistingGroupResp
  ): string => {
    let bgColor = Platform.OS === 'web' ? '' : '#FFF';
    if (selectedGroup && selectedGroup.id === currentItem.id) {
      bgColor = Colors.primary[100] + '7a' || '';
    }
    return bgColor;
  };

  const getGroupListElem = () => {
    return groupList.map((groupInfo) => {
      return (
        <Pressable
          onPress={() => {
            setExistingGroupModalState((prev) => {
              return {
                ...prev,
                selectedGroup: groupInfo,
              };
            });
          }}
          _hover={{
            background:
              existingGroupModalState.selectedGroup &&
              existingGroupModalState.selectedGroup.id === groupInfo.id
                ? Colors.primary[100]
                : Colors.primary[100] + '7a',
          }}
          background={getBgColorForGroupItem(
            groupInfo,
            existingGroupModalState.selectedGroup
          )}
          key={groupInfo.id}
        >
          {getGroupInfoItem(groupInfo)}
        </Pressable>
      );
    });
  };

  const createNewGroup = () => {
    // createNewGroup
  };
  const continueWithSelectedConversation = () => {
    // continueWithSelectedConversation
  };
  const isGroupSelected = () => {
    if (existingGroupModalState?.selectedGroup?.id) {
      return true;
    }
    return false;
  };
  return (
    <View>
      <Modal
        isOpen={showExistingGroupModal}
        onClose={() => {
          onCloseModal();
        }}
      >
        <Modal.Content maxWidth={500}>
          <Modal.CloseButton />
          <Modal.Header>
            <DisplayText textLocalId="existingGroups" />
          </Modal.Header>
          <Modal.Body>{getGroupListElem()}</Modal.Body>
          <Modal.Footer>
            <Button.Group
              mx={{
                base: 'auto',
                md: 0,
              }}
            >
              <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.SECONDARY,
                  onPress: () => {
                    onCloseModal();
                  },
                }}
                customProps={{
                  btnText: intl.formatMessage({id: 'Cancel'}),
                  withRightBorder: false,
                }}
              ></FoldButton>
               <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.PRIMARY,
                  onPress: () => {
                    continueWithSelectedConversation();
                  },
                  style: {marginRight: 20}
                }}
                customProps={{
                  btnText: intl.formatMessage({id: 'Continue with selected chat'}),
                  withRightBorder: false,
                }}
              ></FoldButton>
              <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.PRIMARY,
                  onPress: () => {
                    createNewGroup();
                  },
                  style: {marginRight: 20}
                }}
                customProps={{
                  btnText: intl.formatMessage({id: 'Start New Chat'}),
                  withRightBorder: false,
                }}
              ></FoldButton>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  modalContent: {
    marginLeft: !isWeb() ? 16 : 0,
    height: !isWeb() ? 90 : 'auto',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  groupNameContainer: {
    marginLeft: 8
  }
});

export default ExistingGroupModal;
