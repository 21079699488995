import {GROUP_MEMBER_TYPE} from '../../../../constants';
import {getEmailsFromCommaSeperatedString, isFulfilled} from '../../../../utils/commonUtils';
import {IMessageBoxData} from '../../../RightSideContainer/TeamInbox/Conversations/interfaces';
import {getInlineAttachment} from '../../EmailDrawerCommonV2/EmailInboxApi';
import {IEmailMessageContentAttributes, IEmailSenders} from './interfaces';

export const getSenderUserData = (
  messageData: IMessageBoxData
): {
  userId?: number;
  userType: string;
} => {
  if (messageData.senderType?.toLowerCase() == 'user') {
    return {
      userId: messageData?.senderUser?.id,
      userType: GROUP_MEMBER_TYPE.USER,
    };
  } else if (messageData.senderType?.toLowerCase() == 'contact') {
    return {
      userId: messageData?.senderContact?.id,
      userType: GROUP_MEMBER_TYPE.CONTACT,
    };
  }
  return {} as any;
};

export const getEmailListFromSendersArray = (senders: IEmailSenders) => {
  const result: string[] = [];
  senders.forEach((item) => {
    if (typeof item === 'string') {
      result.push(item);
    } else {
      result.push(item.emailAddress.address);
    }
  });
  return result;
};

export const getEmailDataFromContentAttributes = (
  contentAttributes: Partial<IEmailMessageContentAttributes>
) => {
  const cc =
    contentAttributes.cc && contentAttributes.cc.length > 0
      ? contentAttributes.cc
      : contentAttributes.ccRecipients &&
        contentAttributes.ccRecipients.length > 0
      ? contentAttributes.ccRecipients
      : [];
  const bcc = contentAttributes.bcc || contentAttributes.bccRecipients || [];
  contentAttributes.bcc && contentAttributes.bcc.length > 0
    ? contentAttributes.bcc
    : contentAttributes.bccRecipients &&
      contentAttributes.bccRecipients.length > 0
    ? contentAttributes.bccRecipients
    : [];
  const to = contentAttributes.to || [];
  const receiversList = getEmailListFromSendersArray(to)
  if (receiversList.length === 0) {
    const receiversEmails = getEmailsFromCommaSeperatedString(contentAttributes?.To)
    if (receiversEmails.length) {
      receiversList.push(...receiversEmails)
    }
  }
  return {
    to: receiversList,
    cc: getEmailListFromSendersArray(cc || []),
    bcc: getEmailListFromSendersArray(bcc || []),
    hasAttachments: contentAttributes.hasAttachments || false,
    importance: contentAttributes.importance,
  };
};

export const getAttachmentCidInputString = (inputString: string) => {
  const regex = new RegExp(/cid:([^"'\s]+)/gm);
  return Array.from(new Set(inputString.match(regex))).map((item) =>
    item.replace('cid:', '')
  );
};

export const getAttachmentDataFromCidList = async (data: {
  messageUuid: string;
  cidList: string[];
}) => {
  try {
    const promiseList = data.cidList.map((item) => {
      return getInlineAttachment({
        messageUuid: data.messageUuid,
        attachmentId: item,
      });
    });
    const commonResponse = await Promise.allSettled(promiseList);
    return commonResponse;
  } catch (error) {
    return [];
  }
};

export const processMessageData = async (data: {
  messageData: IMessageBoxData;
}) => {
  const messageData: IMessageBoxData = {...data.messageData};
  let finalMessageContent = data.messageData.messageData?.content || '';
  const attachmentIdList = getAttachmentCidInputString(
    data.messageData.messageData?.content || ''
  );
  const response = await getAttachmentDataFromCidList({
    messageUuid: data.messageData.uuid,
    cidList: attachmentIdList,
  });
  response.filter(isFulfilled).forEach((res) => {
    const item = res.value;
    const cid = item.contentId;
    const contentType = item.contentType;
    const imageSrc = `data:${contentType};base64, ${item.contentBytes}`;
    finalMessageContent = finalMessageContent.replace(`cid:${cid}`, imageSrc);
  });
  messageData.content = finalMessageContent || '';
  messageData.displayContent = finalMessageContent || '';
  if (messageData.messageData) {
    messageData.messageData.content = finalMessageContent;
  }
  return messageData;
};
