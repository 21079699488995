import {Pressable, View} from 'react-native';
import React from 'react';
import {ICallTrailButtonProps} from './interfaces';
import CallTrailSvg from '../../common/Svg/CallTrail/CallTrailSvg';
import CallTrailPopUp from './CallTrailPopUp';
import {IconButton, Tooltip} from 'native-base';
import {useIntl} from 'react-intl';
import {isWeb} from '../../../utils/platformCheckUtils';
import { TestIdentifiers, testID } from '../../../testUtils';

const CallTrailButton = (props: ICallTrailButtonProps) => {
  const [state, setState] = React.useState({
    isOpen: false,
  });
  const intl = useIntl();
  return (
    <View style={props.viewStyle || {}}>
      <Tooltip
        label={`${intl.formatMessage({id: 'callTrail'})}`}
        placement="top"
        hasArrow
      >
        {isWeb() ? (
          <IconButton
            _hover={{
              backgroundColor: 'transparent',
            }}
            onPress={() => {
              setState((prev) => {
                return {
                  ...prev,
                  isOpen: true,
                };
              });
            }}
            icon={
              <CallTrailSvg
                height={props.height}
                width={props.width}
                size={props.size}
              />
            }
            {...testID(TestIdentifiers.callTrailBtn)}
          />
        ) : (
          <Pressable
            onPress={() => {
              setState((prev) => {
                return {
                  ...prev,
                  isOpen: true,
                };
              });
            }}
            {...testID(TestIdentifiers.callTrailBtn)}
          >
            <CallTrailSvg
              height={props.height}
              width={props.width}
              size={props.size}
            />
          </Pressable>
        )}
      </Tooltip>

      {state.isOpen && (
        <CallTrailPopUp
          isOpen={state.isOpen}
          callId={props.callId}
          onClose={() => {
            setState((prev) => {
              return {
                ...prev,
                isOpen: false,
              };
            });
          }}
        />
      )}
    </View>
  );
};

export default CallTrailButton;
