const SummarySvg = () => {
  return (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.35559 0.938563C3.58622 0.353812 4.41378 0.353813 4.64441 0.938564L5.35909 2.75066C5.4295 2.92919 5.57081 3.0705 5.74934 3.14091L7.56144 3.85559C8.14619 4.08622 8.14619 4.91378 7.56144 5.14441L5.74934 5.85909C5.57081 5.9295 5.4295 6.07081 5.35909 6.24934L4.64441 8.06144C4.41378 8.64619 3.58622 8.64619 3.35559 8.06144L2.64091 6.24934C2.5705 6.07081 2.42919 5.9295 2.25066 5.85909L0.438563 5.14441C-0.146188 4.91378 -0.146187 4.08622 0.438564 3.85559L2.25066 3.14091C2.42919 3.0705 2.5705 2.92919 2.64091 2.75066L3.35559 0.938563Z" fill="url(#paint0_linear_148_2030)" />
      <path d="M8.99232 7.39875C9.11436 7.0893 9.55231 7.0893 9.67435 7.39875L10.2081 8.75206C10.2453 8.84653 10.3201 8.92132 10.4146 8.95858L11.7679 9.49232C12.0774 9.61436 12.0774 10.0523 11.7679 10.1744L10.4146 10.7081C10.3201 10.7453 10.2453 10.8201 10.2081 10.9146L9.67435 12.2679C9.55231 12.5774 9.11436 12.5774 8.99232 12.2679L8.45858 10.9146C8.42132 10.8201 8.34653 10.7453 8.25206 10.7081L6.89875 10.1744C6.5893 10.0523 6.5893 9.61436 6.89875 9.49232L8.25206 8.95858C8.34653 8.92132 8.42132 8.84653 8.45858 8.75206L8.99232 7.39875Z" fill="url(#paint1_linear_148_2030)" />
      <defs>
        <linearGradient id="paint0_linear_148_2030" x1="6" y1="-8.92" x2="-8.3009" y2="0.108984" gradientUnits="userSpaceOnUse">
          <stop stop-color="#1E9DAE" />
          <stop offset="1" stop-color="#D478FF" />
        </linearGradient>
        <linearGradient id="paint1_linear_148_2030" x1="6" y1="-8.92" x2="-8.3009" y2="0.108984" gradientUnits="userSpaceOnUse">
          <stop stop-color="#1E9DAE" />
          <stop offset="1" stop-color="#D478FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SummarySvg;

