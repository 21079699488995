const isSpace = /\s/;
const isSeparator = /(_|-|\.|:)/;
const isCamelcase = /([a-z][A-Z]|[A-Z][a-z])/;
const sepSplRegex = /[\W_]+(.|$)/g;
const camelSplRegex = /(.)([A-Z]+)/g;
const mOptRegex = /[|\\{}()[\]^$+*?.]/g;
const sentenceTempStrList = [
  'a',
  'an',
  'and',
  'as',
  'at',
  'but',
  'by',
  'en',
  'for',
  'from',
  'how',
  'if',
  'in',
  'neither',
  'nor',
  'of',
  'on',
  'only',
  'onto',
  'out',
  'or',
  'per',
  'so',
  'than',
  'that',
  'the',
  'to',
  'until',
  'up',
  'upon',
  'v',
  'v.',
  'versus',
  'vs',
  'vs.',
  'via',
  'when',
  'with',
  'without',
  'yet',
  'is',
];
export class StrCaseConverter {
  public static toNoCase(str: string) {
    return isSpace.test(str)
      ? str.toLowerCase()
      : isSeparator.test(str)
      ? (this.unseparate(str) || str).toLowerCase()
      : isCamelcase.test(str)
      ? this.uncamelize(str).toLowerCase()
      : str.toLowerCase();
  }
  public static unseparate(str: string) {
    return str.replace(sepSplRegex, function (e, t) {
      return t ? ' ' + t : '';
    });
  }
  public static uncamelize(str: string) {
    return str.replace(camelSplRegex, function (e, t, r) {
      return t + ' ' + r.toLowerCase().split('').join(' ');
    });
  }
  public static toSentenceCase(str: string) {
    const r = /([^\.?!]*)([\.?!\s]*)([\s\S]*)/;
    let t = '';
    let o: any = r.exec(str);
    let a = '';
    for (t = '', o = r.exec(str), a = ''; o; ) {
      (a += o[1].charAt(0).toUpperCase() + o[1].slice(1).toLowerCase() + o[2]),
        (t = o[3]),
        (o = !!o[3] && r.exec(o[3]));
    }

    return t && (a += t.charAt(0).toUpperCase() + t.slice(1).toLowerCase()), a;
  }
  public static toProperCase(str: string) {
    return str.replace(/\w\S*/g, function (e) {
      return e.charAt(0).toUpperCase() + e.substr(1).toLowerCase();
    });
  }
  public static escapeStringRegexp(str: string) {
    return str.replace(mOptRegex, '\\$&');
  }
  public static toTitleCase(str: string) {
    const t = sentenceTempStrList.map(this.escapeStringRegexp);
    const r = new RegExp('[^^]\\b(' + t.join('|') + ')\\b', 'ig');
    return this.toSentenceCase(str)
      .replace(/(^|\s)(\w)/g, function (str: string, t: string, r: string) {
        return t + r.toUpperCase();
      })
      .replace(r, function (e) {
        return e.toLowerCase();
      })
      .replace(/:\s*(\w)/g, function (e) {
        return e.toUpperCase();
      });
  }
  public static toReverseCase(str: string) {
    return str.split('').reverse().join('');
  }
  public static toAlternativeCase(str: string) {
    let t = '';
    let r = 0;
    for (t = '', r = 0; r < str.length; r++) {
      t += r % 2 == 0 ? str[r].toLowerCase() : str[r].toUpperCase();
    }
    return t;
  }
  public static checkLowerCase(str: string) {
    return str === str.toLowerCase() && str !== str.toUpperCase();
  }
  public static toInverseCase(str: string) {
    let t = '';
    let r = 0;
    for (t = '', r = 0; r < str.length; r++)
      this.checkLowerCase(str[r])
        ? (t += str[r].toUpperCase())
        : (t += str[r].toLowerCase());
    return t;
  }
  public static toSpaceCase(str: string) {
    return this.toNoCase(str)
      .replace(/[\W_]+(.|$)/g, function (e, t) {
        return t ? ' ' + t : '';
      })
      .trim();
  }
  public static toCamelCase(str: string) {
    return this.toSpaceCase(str).replace(/\s(\w)/g, function (e, t) {
      return t.toUpperCase();
    });
  }
  public static toSnakeCase(str: string) {
    return this.toSpaceCase(str).replace(/\s/g, '_');
  }
  public static toSlugCase(str: string) {
    return this.toSpaceCase(str).replace(/\s/g, '-');
  }
  public static toConstantCase(str: string) {
    return this.toSnakeCase(str).toUpperCase();
  }
  public static toPascalCase(str: string) {
    return this.toSpaceCase(str).replace(/(?:^|\s)(\w)/g, function (e, t) {
      return t.toUpperCase();
    });
  }
  public static toDotCase(str: string) {
    return this.toSpaceCase(str).replace(/\s/g, '.');
  }
  public static toRandomCase(str: string) {
    str = str.toLowerCase();
    let t = '';
    let r = 0;
    for (t = '', r = 0; r < str.length; r++) {
      const o = str.charAt(r);
      1 == Math.floor(2 * Math.random() + 1)
        ? (t += o.toUpperCase())
        : (t += o);
    }
    return t;
  }
}
