import {Change, diffWords} from 'diff';
import {Colors} from '../../../../../styles';
import {View, Text, Image} from 'react-native';
import {isWeb} from '../../../../../utils/platformCheckUtils';
import {getMinimumWidthForView} from '../../../../common/Audit/AuditViewHelper';
import {CarePlanDiffStyles} from './CarePlanDiffStyles';
import Stack from '../../../../common/LayoutComponents/Stack';
import RightArrowIconSvg from '../../../../common/Svg/RightArrowIconSvg';
import React from 'react';
import {IMedicationStatement} from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/Medications/interfaces';
import {MedicationStatus} from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/CareplanMedications/interface';
import {getMedicationStatusTextForAudit} from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/CareplanMedications/MedicationUtils';
import {
  getAllergySubtitle,
  getConditionSubtitle,
  getEmergencyContactSubTitle,
  getFHXSubtitle,
  getImmunizationSubtitle,
  getMedicationSubtitle,
  getPMHXSubtitle,
  getPatientCommunicationSubTitle,
  getPatientDemographicsSubTitle,
  getSurgicalHXSubtitle,
  getVitalSubTitle,
} from './CarePlanDiffUtils';
import {IAllergy} from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/Allergies/interfaces';
import {ICondition} from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/Conditions/interfaces';
import { IDiffCellItem } from './interfaces';
import { IImmunization } from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/Immunizations/interfaces';
import { ISurgicalHistory } from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/SurgicalHistory/interfaces';
import { getGroupedHXByRelation } from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/FamilyHistory/AddOrUpdateFamilyHistory/AddOrUpdateFamilyHistoryHelper';
import { IFamilyHistory } from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/FamilyHistory/interfaces';
import { IPastMedicalHistory } from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/PastMedicalHistory/interfaces';
import { IEhrCapability, IMlov, IVitalData } from '../../../../../Interfaces';
import { IObservation } from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/Vitals/interfaces';
import { IPatientCommunicationComponentValue } from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/PatientCommunication/AddOrUpdatePatientCommunication/AddOrUpdatePatientCommunication';
import { IPatientDemographicsComponentValue } from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/PatientDemographics/AddOrUpdatePatientDemographics/AddOrUpdatePatientDemographics';
import { IEmergencyContactComponentValue } from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/EmergencyContact/AddOrUpdateEmergencyContact/AddOrUpdateEmergencyContact';
import { CONSENT_TYPE_CODES } from '../../../../../constants/MlovConst';
import { IAvailableEhr } from '../../interfaces';
import { StyleSheet } from 'react-native';

export const EMPTY_VALUE = '';

export const renderTextDiff = (
  part: Change,
  index: number,
  additionalDetails?: {
    addedColor?: string;
    removedColor?: string;
    regularColor?: string;
    fontSize?: number;
    fontWeight?:
      | '100'
      | '200'
      | '300'
      | '400'
      | '500'
      | '600'
      | '700'
      | '800'
      | '900';
  }
) => {
  const spanStyle = {
    color: part.added
      ? additionalDetails?.addedColor || Colors.Custom.mainPrimaryPurple
      : part.removed
      ? additionalDetails?.removedColor || Colors.Custom.Gray600
      : additionalDetails?.regularColor || Colors.FoldPixel.GRAY400,
    fontSize: additionalDetails?.fontSize || 14,
    fontWeight: additionalDetails?.fontWeight || (part.removed ? '500' : '400'),
  };
  return (
    <Text key={index} style={spanStyle}>
      {part.value}
    </Text>
  );
};

const getDiffViewStyle = (text: string) => {
  return {
    flexShrink: 1,
    flexBasis: 'auto',
    minWidth: getMinimumWidthForView(text),
  };
};

export const renderTextComponent = (
  title: string,
  oldStr: string,
  newStr: string,
  isTopLevelComponent?: boolean
) => {
  const textDiff = diffWords(oldStr, newStr);
  const oldView = textDiff
    .filter((part) => part.removed || !part.added)
    .map((part, index) => renderTextDiff(part, index));

  const newView = textDiff
    .filter((part) => part.added || !part.removed)
    .map((part, index) => renderTextDiff(part, index));

  const viewStyles = getDiffViewStyle(isWeb() ? oldStr : newStr);
  if (isTopLevelComponent) {
    return (
      <Stack direction="column" style={CarePlanDiffStyles.container}>
        <Text style={CarePlanDiffStyles.containerTitle}>{title}</Text>
        <View style={CarePlanDiffStyles.divider} />
        <Stack
          direction="row"
          space={8}
          style={styles.container}
        >
          {!!oldStr && <Text style={viewStyles}>{oldView}</Text>}
          {!!oldStr && renderDiffArrow()}
          <Text style={viewStyles}>{newView}</Text>
        </Stack>
      </Stack>
    );
  }
  return (
    <Stack direction="column" space={8}>
      <Text style={CarePlanDiffStyles.titleStyle}>{title}</Text>
      {isTopLevelComponent && <View />}
      <Stack direction="row" space={8} style={styles.alignCenter}>
        {!!oldStr && <Text style={viewStyles}>{oldView}</Text>}
        {!!oldStr && renderDiffArrow()}
        <Text style={viewStyles}>{newView}</Text>
      </Stack>
    </Stack>
  );
};

export const renderDiffArrow = () => {
  return (
    <View style={styles.diffArrowContainer}>
      <RightArrowIconSvg />
    </View>
  );
};

export const renderCellComponent = (
  oldData: {title: string; subTitle: string; additionalView?: JSX.Element},
  newData: {title: string; subTitle: string; additionalView?: JSX.Element}
) => {
  const titleDiff = diffWords(oldData.title, newData.title);
  const subtitleDiff = diffWords(oldData.subTitle, newData.subTitle);
  const oldTitleView = titleDiff
    .filter((part) => part.removed || !part.added)
    .map((part, index) =>
      renderTextDiff(part, index, {regularColor: Colors.Custom.Gray600})
    );
  const oldSubtitleView = subtitleDiff
    .filter((part) => part.removed || !part.added)
    .map((part, index) =>
      renderTextDiff(part, index, {
        regularColor: Colors.Custom.Gray400,
        fontSize: 12,
      })
    );

  const newTitleView = titleDiff
    .filter((part) => part.added || !part.removed)
    .map((part, index) =>
      renderTextDiff(part, index, {regularColor: Colors.Custom.Gray600})
    );
  const newSubtitleView = subtitleDiff
    .filter((part) => part.added || !part.removed)
    .map((part, index) =>
      renderTextDiff(part, index, {
        regularColor: Colors.Custom.Gray400,
        fontSize: 12,
      })
    );

  const viewStyles = getDiffViewStyle(isWeb() ? oldData.title : newData.title);
  return (
    <Stack direction="row" style={styles.alignCenter}>
      <Stack direction="column" style={viewStyles}>
        <Text style={styles.cellComponentTitle}>
          {oldTitleView}
        </Text>
        <Text style={styles.cellComponentSubtitle}>
          {oldSubtitleView}
        </Text>
        {oldData.additionalView}
      </Stack>
      {renderDiffArrow()}
      <Stack direction="column" style={viewStyles}>
        <Text style={styles.cellComponentTitle}>
          {newTitleView}
        </Text>
        <Text style={styles.cellComponentSubtitle}>
          {newSubtitleView}
        </Text>
        {newData.additionalView}
      </Stack>
    </Stack>
  );
};

export const renderInterventionComponent = (
  oldData: {title: string; subTitleView?: JSX.Element},
  newData: {title: string; subTitleView?: JSX.Element}
) => {
  const titleDiff = diffWords(oldData.title, newData.title);
  const oldTitleView = titleDiff
    .filter((part) => part.removed || !part.added)
    .map((part, index) =>
      renderTextDiff(part, index, {regularColor: Colors.Custom.Gray600})
    );

  const newTitleView = titleDiff
    .filter((part) => part.added || !part.removed)
    .map((part, index) =>
      renderTextDiff(part, index, {regularColor: Colors.Custom.Gray600})
    );

  const viewStyles = getDiffViewStyle(isWeb() ? oldData.title : newData.title);
  return (
    <Stack direction="row" style={styles.alignCenter}>
      <Stack direction="column" space={8} style={viewStyles}>
        <Text style={styles.interventionComponentTitle}>
          {oldTitleView}
        </Text>
        {oldData.subTitleView}
      </Stack>
      {renderDiffArrow()}
      <Stack direction="column" space={8} style={viewStyles}>
        <Text style={styles.interventionComponentTitle}>
          {newTitleView}
        </Text>
        {newData.subTitleView}
      </Stack>
    </Stack>
  );
};

export const renderImageComponent = (
  title: string,
  oldStr: string,
  newStr: string,
  isTopLevelComponent?: boolean
) => {
  const viewStyles = getDiffViewStyle(isWeb() ? oldStr : newStr);
  if (isTopLevelComponent) {
    return (
      <Stack direction="column" style={CarePlanDiffStyles.container}>
        <Text style={CarePlanDiffStyles.containerTitle}>{title}</Text>
        <View style={CarePlanDiffStyles.divider} />
        <Stack
          direction="row"
          style={styles.imageComponentContainer}
        >
          {!!oldStr && (
            <Stack direction="row" style={viewStyles}>
              {oldStr !== EMPTY_VALUE && (
                <Image
                  resizeMode="contain"
                  style={styles.imageStyle}
                  key={`old_${title}`}
                  source={{
                    uri: newStr,
                    cache: 'force-cache',
                  }}
                  accessibilityLabel="oldImage"
                />
              )}
              {oldStr === EMPTY_VALUE && <Text>{EMPTY_VALUE}</Text>}
            </Stack>
          )}
          {!!oldStr && renderDiffArrow()}
          <Stack direction="row" style={viewStyles}>
            {newStr !== EMPTY_VALUE && (
              <Image
                resizeMode="contain"
                style={styles.imageStyle}
                key={`new_${title}`}
                source={{
                  uri: newStr,
                  cache: 'force-cache',
                }}
                accessibilityLabel="newImage"
              />
            )}
            {newStr === EMPTY_VALUE && <Text>{EMPTY_VALUE}</Text>}
          </Stack>
        </Stack>
      </Stack>
    );
  }
  return (
    <Stack direction="column" space={8}>
      <Text style={CarePlanDiffStyles.titleStyle}>{title}</Text>
      <Stack direction="row" style={styles.alignCenter}>
        {!!oldStr && (
          <Stack direction="row" style={viewStyles}>
            {oldStr !== EMPTY_VALUE && (
              <Image
                resizeMode="contain"
                style={styles.imageStyle}
                key={`old_${title}`}
                source={{
                  uri: newStr,
                  cache: 'force-cache',
                }}
                accessibilityLabel="oldImage"
              />
            )}
            {oldStr === EMPTY_VALUE && <Text>{EMPTY_VALUE}</Text>}
          </Stack>
        )}
        {!!oldStr && renderDiffArrow()}
        <Stack direction="row" style={viewStyles}>
          {newStr !== EMPTY_VALUE && (
            <Image
              resizeMode="contain"
              style={styles.imageStyle}
              key={`new_${title}`}
              source={{
                uri: newStr,
                cache: 'force-cache',
              }}
              accessibilityLabel="newImage"
            />
          )}
          {newStr === EMPTY_VALUE && <Text>{EMPTY_VALUE}</Text>}
        </Stack>
      </Stack>
    </Stack>
  );
};

export const renderRemovedAndNewAdded = (
  removedList: JSX.Element[],
  addedList: JSX.Element[]
) => {
  if (!removedList.length && !addedList.length) {
    return null;
  }
  return (
    <Stack direction="column" space={16}>
      {removedList.map((item) => {
        return (
          <Stack
            direction="row"
            space={8}
            style={styles.removedAddedItem}
          >
            <View style={styles.flexGrow}>{item}</View>
            <Text style={styles.removedText}>{'Removed'}</Text>
          </Stack>
        );
      })}
      {addedList.map((item) => {
        return (
          <Stack
            direction="row"
            space={8}
            style={styles.removedAddedItem}
          >
            <View style={styles.flexGrow}>{item}</View>
            <Text style={styles.addedText}>
              {'New Added'}
            </Text>
          </Stack>
        );
      })}
    </Stack>
  );
};

export const renderBarrierDiff = (
  component: any,
  oldValue?: any,
  newValue?: any,
  isTopLevelComponent?: boolean
) => {
  const oldBarrierList = filterDeletedItems(oldValue?.selectedValue?.barriers || [])
    .map((item: any) => item.title);
  const newBarrierList = filterDeletedItems(newValue?.selectedValue?.barriers || [])
    .map((item: any) => item.title);
  const removedBarrierList = oldBarrierList
    .filter((val: string) => !newBarrierList.includes(val))
    .map((item: string) => {
      return <Text style={styles.barrierText}>{item}</Text>;
    });
  const addedBarrierList = newBarrierList
    .filter((val: string) => !oldBarrierList.includes(val))
    .map((item: string) => {
      return <Text style={styles.barrierText}>{item}</Text>;
    });
  const barrierHasData =
    removedBarrierList.length > 0 || addedBarrierList.length > 0;
  if (isTopLevelComponent && barrierHasData) {
    return (
      <Stack
        direction="column"
        style={CarePlanDiffStyles.container}
      >
        <Text
          style={CarePlanDiffStyles.containerTitle}
        >
          {component.label}
        </Text>
        <View
          style={CarePlanDiffStyles.divider}
        />
        <Stack
          direction="column"
          style={styles.barrierDiffContainer}
        >
          {renderRemovedAndNewAdded(removedBarrierList, addedBarrierList)}
        </Stack>
      </Stack>
    );
  }
  return barrierHasData ? (
    <Stack direction="column" space={8}>
      <Text
        style={[
          CarePlanDiffStyles.titleStyle,
          CarePlanDiffStyles.mainSectionTitleStyle,
        ]}
      >
        {component.label}
      </Text>
      <View
        style={CarePlanDiffStyles.divider}
      />
      {renderRemovedAndNewAdded(removedBarrierList, addedBarrierList)}
    </Stack>
  ) : null;
};

export const renderMedicationDiff = (
  component: any,
  oldValue?: any,
  newValue?: any,
  isTopLevelComponent?: boolean
) => {
  const oldMedicationList: IMedicationStatement[] = filterDeletedItems(
    oldValue?.selectedValue?.medicationStatements || []
  );
  const newMedicationList: IMedicationStatement[] = filterDeletedItems(
    newValue?.selectedValue?.medicationStatements || []
  );

  const oldMedications: {title: string; subTitle: string; status?: string}[] =
    [];
  const newMedications: {title: string; subTitle: string; status?: string}[] =
    [];

  const removedMedicationList = oldMedicationList
    .filter((val) => {
      const isRemoved = !newMedicationList.some(
        (item) => item.uniqueId === val.uniqueId
      );
      if (!isRemoved) {
        oldMedications.push({
          title: val.name || '',
          subTitle: getMedicationSubtitle(val),
          status: val.medicationStatus,
        });
      }
      return isRemoved;
    })
    .map((item) => renderCell(item.name || '', getMedicationSubtitle(item)));

  const addedMedicationList = newMedicationList
    .filter((val) => {
      const isAdded = !oldMedicationList.some(
        (item) => item.uniqueId === val.uniqueId
      );
      if (!isAdded) {
        const subTitle = getMedicationSubtitle(val);
        const oldIndex = oldMedications.findIndex(
          (oldData) =>
            oldData.title === val.name &&
            oldData.subTitle === subTitle &&
            oldData.status === val.medicationStatus
        );
        if (oldIndex === -1) {
          newMedications.push({
            title: val.name || '',
            subTitle,
            status: val.medicationStatus,
          });
        } else {
          oldMedications.splice(oldIndex, 1);
        }
      }
      return isAdded;
    })
    .map((item) => renderCell(item.name || '', getMedicationSubtitle(item)));

  const medicationHasData =
    removedMedicationList.length > 0 ||
    addedMedicationList.length > 0 ||
    oldMedications.length > 0;

  if (isTopLevelComponent && medicationHasData) {
    return (
      <Stack
        direction="column"
        style={CarePlanDiffStyles.container}
      >
        <Text
          style={CarePlanDiffStyles.containerTitle}
        >
          {component.label}
        </Text>
        <View
          style={CarePlanDiffStyles.divider}
        />
        <Stack
          direction="column"
          style={styles.medicationContainer}
        >
          {renderRemovedAndNewAdded(removedMedicationList, addedMedicationList)}
          {oldMedications.map((item, index) => {
            const newItem = newMedications[index];
            const isStatusDiff = item.status !== newItem.status;
            const oldData = {
              ...item,
              additionalView: isStatusDiff
                ? renderMedicationStatus(item.status || '')
                : undefined,
            };
            const newData = {
              ...newItem,
              additionalView: isStatusDiff
                ? renderMedicationStatus(newItem.status || '')
                : undefined,
            };
            return newItem ? renderCellComponent(oldData, newData) : <></>;
          })}
        </Stack>
      </Stack>
    );
  }
  return medicationHasData ? (
    <Stack direction="column" space={16}>
      <Text
        style={[
          CarePlanDiffStyles.titleStyle,
          CarePlanDiffStyles.mainSectionTitleStyle,
        ]}
      >
        {component.label}
      </Text>
      <View
        style={CarePlanDiffStyles.divider}
      />
      {renderRemovedAndNewAdded(removedMedicationList, addedMedicationList)}
      {oldMedications.map((item, index) => {
        const newItem = newMedications[index];
        const isStatusDiff = item.status !== newItem.status;
        const oldData = {
          ...item,
          additionalView: isStatusDiff
            ? renderMedicationStatus(item.status || '')
            : undefined,
        };
        const newData = {
          ...newItem,
          additionalView: isStatusDiff
            ? renderMedicationStatus(newItem.status || '')
            : undefined,
        };
        return newItem ? renderCellComponent(oldData, newData) : <></>;
      })}
    </Stack>
  ) : null;
};

export const renderMedicationStatus = (status: string) => {
  const textStyle = {
    fontSize: 14,
    color:
      status === MedicationStatus.willStop
        ? Colors.Custom.ErrorColor
        : Colors.Custom.SuccessColor,
  };
  return status ? (
    <Text style={textStyle}>{getMedicationStatusTextForAudit(status)}</Text>
  ) : (
    <></>
  );
};

export const renderCell = (
  title: string,
  subTitle: string,
  additionalView?: JSX.Element
) => {
  return (
    <Stack direction="column" space={8} style={styles.cellContainer}>
      <Text
        style={[CarePlanDiffStyles.titleStyle, styles.cellTitle]}
      >
        {title}
      </Text>
      <Stack direction="column" space={8}>
        <Text style={styles.cellSubtitle}>
          {subTitle}
        </Text>
        {additionalView}
      </Stack>
    </Stack>
  );
};

export const renderCustomSurveyDiff = (
  component: any,
  oldValue?: any,
  newValue?: any,
  isTopLevelComponent?: boolean
) => {
  const oldSurveyValue = oldValue?.selectedValue || {};
  const newSurveyValue = newValue?.selectedValue || {};
  const surveyElements: JSX.Element[] = [];
  component.questions.forEach((question: any) => {
    const oldSelectedValues: string[] = [];
    const newSelectedValues: string[] = [];
    component.values.forEach((item: any) => {
      if (oldSurveyValue?.hasOwnProperty(question.value)) {
        const answer = oldSurveyValue[question.value];
        if (
          answer &&
          Array.isArray(answer) &&
          answer.map((item) => `${item}`).includes(`${item.value}`)
        ) {
          oldSelectedValues.push(`${item.label}`);
        } else if (`${answer}` === `${item.value}`) {
          oldSelectedValues.push(`${item.label}`);
        }
      }
      if (newSurveyValue?.hasOwnProperty(question.value)) {
        const answer = newSurveyValue[question.value];
        if (
          answer &&
          Array.isArray(answer) &&
          answer.map((item) => `${item}`).includes(`${item.value}`)
        ) {
          newSelectedValues.push(`${item.label}`);
        } else if (`${answer}` === `${item.value}`) {
          newSelectedValues.push(`${item.label}`);
        }
      }
    });
    const oldValue = oldSelectedValues.join(', ');
    const newValue = newSelectedValues.join(', ');
    if (oldValue !== newValue) {
      surveyElements.push(
        renderTextComponent(question.label, oldValue, newValue)
      );
    }
  });
  if (isTopLevelComponent && surveyElements.length > 0) {
    return (
      <Stack
        direction="column"
        style={CarePlanDiffStyles.container}
      >
        <Text
          style={CarePlanDiffStyles.containerTitle}
        >
          {component.label}
        </Text>
        <View
          style={CarePlanDiffStyles.divider}
        />
        <Stack
          direction="column"
          space={16}
          style={styles.surveyContainer}
        >
          {surveyElements.map((item) => item)}
        </Stack>
      </Stack>
    );
  }
  return surveyElements.length > 0 ? (
    <Stack direction="column" space={8}>
      <Text style={CarePlanDiffStyles.titleStyle}>{component.label}</Text>
      <Stack direction="column" space={16} style={styles.surveyElementsSubContainer}>
        {surveyElements.map((item) => item)}
      </Stack>
    </Stack>
  ) : null;
};

export const renderAllergyDiff = (
  component: any,
  oldValue?: any,
  newValue?: any,
  isTopLevelComponent?: boolean
) => {
  const oldList: IAllergy[] = filterDeletedItems(oldValue?.selectedValue?.allergies || []);
  const newList: IAllergy[] = filterDeletedItems(newValue?.selectedValue?.allergies || []);

  return renderPAMIDiff(component, oldList, newList, getAllergySubtitle, isTopLevelComponent);
};

const filterDeletedItems = (items: any[]) => {
  return items.filter((item: any) => !item.isDeleted);
};

export const renderConditionDiff = (
  component: any,
  oldValue?: any,
  newValue?: any,
  isTopLevelComponent?: boolean
) => {
  const oldList: ICondition[] = filterDeletedItems(
    oldValue?.selectedValue?.conditions || []
  );
  const newList: ICondition[] = filterDeletedItems(
    newValue?.selectedValue?.conditions || []
  );

  const oldDisplayData: IDiffCellItem[] = [];
  const newDisplayData: IDiffCellItem[] = [];

  const removedList = oldList
    .filter((val) => {
      const isRemoved = !newList.some((item) => item.uniqueId === val.uniqueId);
      if (!isRemoved) {
        oldDisplayData.push({
          title: val.name || '',
          subTitle: getConditionSubtitle(val),
          original: val,
        });
      }
      return isRemoved;
    })
    .map((item) => renderCell(item.name || '', getConditionSubtitle(item)));

  const addedList = newList
    .filter((val) => {
      const isAdded = !oldList.some((item) => item.uniqueId === val.uniqueId);
      if (!isAdded) {
        const subTitle = getConditionSubtitle(val);
        const oldIndex = oldDisplayData.findIndex(
          (oldData) =>
            oldData.title === val.name && oldData.subTitle === subTitle
        );
        if (oldIndex === -1) {
          newDisplayData.push({
            title: val.name || '',
            subTitle,
            original: val,
          });
        } else {
          oldDisplayData.splice(oldIndex, 1);
        }
      }
      return isAdded;
    })
    .map((item) => renderCell(item.name || '', getConditionSubtitle(item)));

  const hasData =
    removedList.length > 0 || addedList.length > 0 || oldDisplayData.length > 0;

  if (isTopLevelComponent && hasData) {
    return (
      <Stack direction="column" style={CarePlanDiffStyles.container}>
        <Text style={CarePlanDiffStyles.containerTitle}>{component.label}</Text>
        <View style={CarePlanDiffStyles.divider} />
        <Stack
          direction="column"
          space={16}
          style={styles.conditionContainer}
        >
          {renderRemovedAndNewAdded(removedList, addedList)}
          {renderUpdatedCellComponents(oldDisplayData, newDisplayData)}
        </Stack>
      </Stack>
    );
  }
  return hasData ? (
    <Stack direction="column" space={16}>
      <Text
        style={[
          CarePlanDiffStyles.titleStyle,
          CarePlanDiffStyles.mainSectionTitleStyle,
        ]}
      >
        {component.label}
      </Text>
      <View
        style={CarePlanDiffStyles.divider}
      />
      {renderRemovedAndNewAdded(removedList, addedList)}
      {renderUpdatedCellComponents(oldDisplayData, newDisplayData)}
    </Stack>
  ) : null;
};

export const renderUpdatedCellComponents = (oldList: IDiffCellItem[], newList: IDiffCellItem[]) => {
  return (
    <Stack direction="column" space={16}>
      {oldList.map((item, index) => {
        const newItem = newList[index];
        return newItem ? renderCellComponent(item, newItem) : <></>;
      })}
    </Stack>
  );
}

export const renderImmunizationDiff = (
  component: any,
  oldValue?: any,
  newValue?: any,
  isTopLevelComponent?: boolean
) => {
  const oldList: IImmunization[] = filterDeletedItems(
    oldValue?.selectedValue?.immunizations || []
  );
  const newList: IImmunization[] = filterDeletedItems(
    newValue?.selectedValue?.immunizations || []
  );

  return renderPAMIDiff(component, oldList, newList, getImmunizationSubtitle, isTopLevelComponent);
};

export const renderSurgicalHXDiff = (
  component: any,
  oldValue?: any,
  newValue?: any,
  isTopLevelComponent?: boolean
) => {
  const oldList: ISurgicalHistory[] = filterDeletedItems(
    oldValue?.selectedValue?.surgicalHistory || []
  );
  const newList: ISurgicalHistory[] = filterDeletedItems(
    newValue?.selectedValue?.surgicalHistory || []
  );

  return renderPAMIDiff(component, oldList, newList, getSurgicalHXSubtitle, isTopLevelComponent);
};
export const renderPMHXDiff = (
  component: any,
  oldValue?: any,
  newValue?: any,
  isTopLevelComponent?: boolean
) => {
  const oldList: IPastMedicalHistory[] = filterDeletedItems(
    oldValue?.selectedValue?.pastMedicalHistories || []
  );
  const newList: IPastMedicalHistory[] = filterDeletedItems(
    newValue?.selectedValue?.pastMedicalHistories || []
  );

  return renderPAMIDiff(component, oldList, newList, getPMHXSubtitle, isTopLevelComponent);
};
export const renderFHXDiff = (
  component: any,
  oldValue?: any,
  newValue?: any,
  isTopLevelComponent?: boolean
) => {
  const oldList: IFamilyHistory[] = filterDeletedItems(
    oldValue?.selectedValue?.history || []
  ).map((item: IFamilyHistory) => ({...item, name: item.relationship.display}));
  const newList: IFamilyHistory[] = filterDeletedItems(
    newValue?.selectedValue?.history || []
  ).map((item: IFamilyHistory) => ({...item, name: item.relationship.display}));

  return renderPAMIDiff(component, oldList, newList, getFHXSubtitle, isTopLevelComponent);
};

export const renderVitalDiff = (
  component: any,
  oldValue?: any,
  newValue?: any,
  isTopLevelComponent?: boolean,
  vitalConfigList?: IVitalData[]
) => {
  const oldList: IObservation[] = filterDeletedItems(
    oldValue?.selectedValue?.observations || []
  ).map((item: IObservation) => ({...item, uniqueId: item.id }));
  const newList: IObservation[] = filterDeletedItems(
    newValue?.selectedValue?.observations || []
  ).map((item: IObservation) => ({...item, uniqueId: item.id }));

  return renderPAMIDiff(component, oldList, newList, (item) => {
    return getVitalSubTitle(item,{ vitalDataList: vitalConfigList || []})
  }, isTopLevelComponent);
};

export const renderPatientCommunicationDiff = (
  component: any,
  oldValue?: any,
  newValue?: any,
  isTopLevelComponent?: boolean,
  isPatientAddress?: boolean,
) => {
  const oldList: IPatientCommunicationComponentValue[] = oldValue?.selectedValue ? [oldValue?.selectedValue] : [];
  const newList: IPatientCommunicationComponentValue[] = newValue?.selectedValue ? [newValue?.selectedValue] : [];

  return renderPAMISubTitleDiff(component, oldList, newList, (item) => {
    return getPatientCommunicationSubTitle(item, {isPatientAddress})
  }, isTopLevelComponent);
};

export const renderPatientDemographicsDiff = (
  component: any,
  oldValue?: any,
  newValue?: any,
  isTopLevelComponent?: boolean,
  additionalDetails?: {
    hideGenderFields?: boolean,
    capabilities?: IEhrCapability,
    genderList?: IMlov[],
  },
) => {
  const oldList: IPatientDemographicsComponentValue[] = oldValue?.selectedValue ? [oldValue?.selectedValue] : [];
  const newList: IPatientDemographicsComponentValue[] = newValue?.selectedValue ? [newValue?.selectedValue] : [];

  return renderPAMISubTitleDiff(component, oldList, newList, (item) => {
    return getPatientDemographicsSubTitle(item, {...additionalDetails, component})
  }, isTopLevelComponent);
};

export const renderEmergencyContactDiff = (
  component: any,
  oldValue?: any,
  newValue?: any,
  isTopLevelComponent?: boolean,
) => {
  const oldList: IEmergencyContactComponentValue[] = oldValue?.selectedValue ? [oldValue?.selectedValue] : [];
  const newList: IEmergencyContactComponentValue[] = newValue?.selectedValue ? [newValue?.selectedValue] : [];

  return renderPAMISubTitleDiff(component, oldList, newList, getEmergencyContactSubTitle, isTopLevelComponent);
};

const getMemberConsentDisplayText = (consentType: string) => {
  switch (consentType) {
    case CONSENT_TYPE_CODES.COMMUNICATION: return 'memberFacingConsents';
    case CONSENT_TYPE_CODES.CCM: return 'memberFacingCCMConsent';
    case CONSENT_TYPE_CODES.TCM: return 'memberFacingTCMConsent';
    case CONSENT_TYPE_CODES.ECM: return 'memberFacingECMConsent';
    default: return 'memberFacingConsents';
  }
}

export const renderPAMIDiff = (
  component: any,
  oldList: any[],
  newList: any[],
  getSubTitleFn: (item: any, additionalParams?: any) => string,
  isTopLevelComponent?: boolean
) => {
  const oldDisplayData: IDiffCellItem[] = [];
  const newDisplayData: IDiffCellItem[] = [];

  const removedList = oldList
    .filter((val) => {
      const isRemoved = !newList.some((item) => item.uniqueId === val.uniqueId);
      if (!isRemoved) {
        oldDisplayData.push({
          title: val.name || '',
          subTitle: getSubTitleFn(val),
          original: val,
        });
      }
      return isRemoved;
    })
    .map((item) => renderCell(item.name || '', getSubTitleFn(item)));

  const addedList = newList
    .filter((val) => {
      const isAdded = !oldList.some((item) => item.uniqueId === val.uniqueId);
      if (!isAdded) {
        const subTitle = getSubTitleFn(val);
        const oldIndex = oldDisplayData.findIndex(
          (oldData) =>
            oldData.title === (val.name) && oldData.subTitle === subTitle
        );
        if (oldIndex === -1) {
          newDisplayData.push({
            title: val.name || '',
            subTitle,
            original: val,
          });
        } else {
          oldDisplayData.splice(oldIndex, 1);
        }
      }
      return isAdded;
    })
    .map((item) => renderCell(item.name || '', getSubTitleFn(item)));

  const hasData =
    removedList.length > 0 || addedList.length > 0 || oldDisplayData.length > 0;

  if (isTopLevelComponent && hasData) {
    return (
      <Stack direction="column" style={CarePlanDiffStyles.container}>
        <Text style={CarePlanDiffStyles.containerTitle}>{component.label}</Text>
        <View style={CarePlanDiffStyles.divider} />
        <Stack
          direction="column"
          space={16}
          style={styles.medicationContainer}
        >
          {renderRemovedAndNewAdded(removedList, addedList)}
          {renderUpdatedCellComponents(oldDisplayData, newDisplayData)}
        </Stack>
      </Stack>
    );
  }
  return hasData ? (
    <Stack direction="column" space={16}>
      <Text
        style={[
          CarePlanDiffStyles.titleStyle,
          CarePlanDiffStyles.mainSectionTitleStyle,
        ]}
      >
        {component.label}
      </Text>
      <View
        style={CarePlanDiffStyles.divider}
      />
      {renderRemovedAndNewAdded(removedList, addedList)}
      {renderUpdatedCellComponents(oldDisplayData, newDisplayData)}
    </Stack>
  ) : null;
};

export const renderPAMISubTitleDiff = (
  component: any,
  oldList: any[],
  newList: any[],
  getSubTitleFn: (item: any, additionalParams?: any) => string,
  isTopLevelComponent?: boolean
) => {
  const oldDisplayData: IDiffCellItem[] = [];
  const newDisplayData: IDiffCellItem[] = [];

  const removedList = oldList
    .filter((val) => {
      const isRemoved = !newList.some((item) => item.uniqueId === val.uniqueId);
      if (!isRemoved) {
        oldDisplayData.push({
          title: val.name || '',
          subTitle: getSubTitleFn(val),
          original: val,
        });
      }
      return isRemoved;
    })
    .map((item) => renderCell(item.name || '', getSubTitleFn(item)));

  const addedList = newList
    .filter((val) => {
      const isAdded = !oldList.some((item) => item.uniqueId === val.uniqueId);
      if (!isAdded) {
        const subTitle = getSubTitleFn(val);
        const oldIndex = oldDisplayData.findIndex(
          (oldData) => oldData.subTitle === subTitle
        );
        if (oldIndex === -1) {
          newDisplayData.push({
            title: val.name || '',
            subTitle,
            original: val,
          });
        } else {
          oldDisplayData.splice(oldIndex, 1);
        }
      }
      return isAdded;
    })
    .map((item) => renderCell(item.name || '', getSubTitleFn(item)));

  const hasData =
    removedList.length > 0 || addedList.length > 0 || oldDisplayData.length > 0;

  if (isTopLevelComponent && hasData) {
    return (
      <Stack direction="column" style={CarePlanDiffStyles.container}>
        <Text style={CarePlanDiffStyles.containerTitle}>{component.label}</Text>
        <View style={CarePlanDiffStyles.divider} />
        <Stack
          direction="column"
          space={16}
          style={styles.medicationContainer}
        >
          {renderRemovedAndNewAdded(removedList, addedList)}
          {renderUpdatedCellComponents(oldDisplayData, newDisplayData)}
        </Stack>
      </Stack>
    );
  }
  return hasData ? (
    <Stack direction="column" space={16}>
      <Text
        style={[
          CarePlanDiffStyles.titleStyle,
          CarePlanDiffStyles.mainSectionTitleStyle,
        ]}
      >
        {component.label}
      </Text>
      <View
        style={CarePlanDiffStyles.divider}
      />
      {renderRemovedAndNewAdded(removedList, addedList)}
      {renderUpdatedCellComponents(oldDisplayData, newDisplayData)}
    </Stack>
  ) : null;
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    paddingHorizontal: 12,
    paddingVertical: 12,
  },
  alignCenter: {
    alignItems: 'center',
  },
  diffArrowContainer: {
    width: 40,
    alignItems: 'center',
  },
  cellComponentTitle: {
    fontSize: 14,
    fontWeight: '500',
    color: Colors.Custom.Gray600,
  },
  cellComponentSubtitle: {
    fontSize: 12,
    color: Colors.Custom.Gray400,
  },
  interventionComponentTitle: {
    fontSize: 14,
    fontWeight: '500',
    color: Colors.Custom.Gray600,
  },
  imageComponentContainer: {
    alignItems: 'center',
    paddingHorizontal: 12,
    paddingVertical: 12,
  },
  imageStyle: {
    width: 200,
    height: 150,
  },
  removedAddedItem: {
    alignItems: 'center',
    flex: 1,
  },
  flexGrow: {
    flexGrow: 1,
    flex: 1,
  },
  removedText: {
    color: Colors.Custom.ErrorColor,
  },
  addedText: {
    color: Colors.Custom.SuccessColor,
  },
  barrierText: {
    color: Colors.FoldPixel.GRAY400,
  },
  barrierDiffContainer: {
    paddingHorizontal: 12,
    paddingVertical: 12,
  },
  cellContainer: {
    flex: 1,
  },
  cellTitle: {
    color: Colors.Custom.Gray600,
  },
  cellSubtitle: {
    fontSize: 12,
    color: Colors.Custom.Gray400,
  },
  surveyElementsContainer: {
    paddingHorizontal: 12,
    paddingVertical: 12,
  },
  surveyElementsSubContainer: {
    paddingLeft: 16,
  },
  conditionDiffContainer: {
    paddingHorizontal: 12,
    paddingVertical: 12,
  },
  conditionContainer: {
    paddingHorizontal: 12,
    paddingVertical: 12,
  },
  surveyContainer: {
    paddingHorizontal: 12,
    paddingVertical: 12,
  },
  medicationContainer: {
    paddingHorizontal: 12,
    paddingVertical: 12,
  },
});
