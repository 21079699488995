import {gql} from '@apollo/client';
import BaseService from '../CommonService/BaseService';

export const CreateStickyNotes = async (
  contactId: number,
  contactUuid: string,
  note: string
) => {
  const baseService = BaseService.getSharedInstance().axios;
  const body = {
    contactId: contactId,
    contactUuid: contactUuid,
    note: note,
  };
  return await baseService.post('crm-nest/account/contact-sticky-notes', {
    ...body,
  });
};

export const UpdateStickyNotes = async (
  contactId: number,
  contactUuid: string,
  note: string,
  noteUuid: string
) => {
  const baseService = BaseService.getSharedInstance().axios;
  const body = {
    contactId: contactId,
    contactUuid: contactUuid,
    note: note,
    noteUuid:noteUuid
  };
  return await baseService.put('crm-nest/account/contact-sticky-notes', {
    ...body,
  });
};

const GetStickyNotes = gql`
  query GetStickyNotes($contactUuid: uuid!) {
    stickyNotes(where: {contactUuid: {_eq: $contactUuid}}) {
      noteUuid
      contactNote {
        content
      }
      updatedByUser {
        name
      }
      updatedAt
    }
  }
`;

const GetStickNoteVersionByNoteUuid = gql`
  query GetStickNoteVersionByNoteUuid(
    $noteUuid: uuid!
    $limit: Int!
    $offset: Int!
  ) {
    contactNoteVersions(
      where: {noteUuid: {_eq: $noteUuid}}
      limit: $limit
      offset: $offset
      order_by: {createdAt: desc}
    ) {
      updatedByUser {
        name
      }
      updatedAt
      note
      noteUuid
    }
  }
`;

export default {GetStickyNotes,GetStickNoteVersionByNoteUuid}
