import  React from 'react';
import { View, ViewStyle } from 'react-native';
import { AwvSvgStyles } from './AwvSvgStyles';


const LeftArrowIconSvg = (props: {style?: ViewStyle}) => {

  return (
    <View style={[AwvSvgStyles.container, props.style]}>
      <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="14"
      fill="none"
      viewBox="0 0 18 14"
    >
      <path
        stroke="#6F7A90"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M17 7H1m0 0l6-6M1 7l6 6"
      ></path>
    </svg>
    </View>
  );
};

export default LeftArrowIconSvg;
