import {Checkbox,Divider,Select, notification} from "antd"
import {useEffect,useState} from "react"
import {useIntl} from "react-intl"
import {Pressable,Text,View} from "react-native"
import {ToastType} from "../../../../utils/commonViewUtils"
import {useToast} from "../../../Toast/ToastProvider"
import {CUSTOM_ATTRIBUTE_SELECT_TYPE, CUSTOM_FIELD_SELECT_DATA_LIMIT, CUSTOM_FIELD_TOAST_ERROR_DURATION, CUSTOM_FIELD_TOAST_ERROR_DURATION_LONG, SHOW_DISABLED} from "./CustomFieldConst"
import {getCustomFieldData} from "./CustomFieldService"
import './CustomFieldStyle.css'
import {getFilteredCustomAttribute, getFormattedCustomFieldData, removeAlreadySelected} from "./CustomFieldUtils"
import {ICustomFieldData, ICustomToast, ISelectCustomField, ISelectCustomFieldState} from "./interface"
import {COMMON_ACTION_CODES} from "../../../../constants/ActionConst"
import {Colors} from "../../../../styles"
import {AddCustomFieldDrawer} from "./AddCustomFieldDrawer"

export const SelectCustomField = (props: ISelectCustomField) => {
  const toast = useToast();
  const intl = useIntl();
  const [stateData, setStateData] = useState<ISelectCustomFieldState>({
    searchString: '',
    loading: false,
    customAttributeData: [],
    selectedCustomFields: [],
    customFieldText: '',
    openAddCustomFiledDrawer: false,
    visible: false,
    hoveredIndex: null,
  });
  const { Option } = Select;

  const handleMouseEnter = (index: number) => {
    setStateData((prev)=> {
      return {
        ...prev,
        hoveredIndex: index
      }
    })
  };

  const handleMouseLeave = () => {
    setStateData((prev)=> {
      return {
        ...prev,
        hoveredIndex: null
      }
    })
  };

  const onCustomFieldAddUpdateSuccess = (newCustomField: ICustomFieldData) => {
    if (newCustomField?.id) {
      setStateData((prev)=> {
        return {
          ...prev,
          customAttributeData: [newCustomField, ...stateData?.customAttributeData],
          openAddCustomFiledDrawer: false,
        }
      })
      props?.onActionPerformed?.(COMMON_ACTION_CODES.CUSTOM_FIELD_ADDED, [...stateData?.selectedCustomFields, newCustomField]);
    } else {
      onAddCustomFiledDrawerVisibleChanges(false);
    }
    notification.success({
      message: intl.formatMessage({id: 'customFieldAddedMsg'}),
      duration: 4.0,
      placement: 'topRight'
    });
  }

  const onCustomFieldAddUpdateFailed = (error: any) => {
    onAddCustomFiledDrawerVisibleChanges(false);
    showToast({
      toastType: ToastType.error,
      message: error.response.data.message || intl.formatMessage({id: 'apiErrorMsg'}),
      duration: CUSTOM_FIELD_TOAST_ERROR_DURATION_LONG,
      closeAllPrevToast: true,
    });
  }


  const onCustomFieldDrawerActionPerformed = (
    actionCode: string,
    actionData: any
  ) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.SUCCESS:
        onCustomFieldAddUpdateSuccess(actionData);
        break;
      case COMMON_ACTION_CODES.FAILED:
        onCustomFieldAddUpdateFailed(actionData);
        break;
      default:
        break;
    }
  };

  const onShowCustomFieldOptionVisibleChanges = (value: boolean) => {
    setStateData((prev)=> {
      return {
        ...prev,
        visible: value
      }
    })
    if (!value && stateData?.selectedCustomFields?.length) {
      props?.onActionPerformed?.(COMMON_ACTION_CODES.CUSTOM_FIELD_ADDED, stateData?.selectedCustomFields);
    }
  }

  const onAddCustomFiledDrawerVisibleChanges = (value: boolean) => {
    setStateData((prev)=> {
      return {
        ...prev,
        openAddCustomFiledDrawer: value,
        visible: false
      }
    })
    if (!value && stateData?.selectedCustomFields?.length) {
      props?.onActionPerformed?.(COMMON_ACTION_CODES.CUSTOM_FIELD_ADDED, stateData?.selectedCustomFields);
    }
  }

  const showToast = (toastData: ICustomToast) => {
    toast({
      toastType: toastData?.toastType,
      message: toastData?.message,
      duration: toastData?.duration || CUSTOM_FIELD_TOAST_ERROR_DURATION,
      closeAllPrevToast: toastData?.closeAllPrevToast || false,
    })
  }

  const renderOptions = (
    <>
      {stateData?.customAttributeData?.map((field: ICustomFieldData, index: number) => {
          return (
            <>
            <Option key={field.title} value={field.title}>
              <Pressable
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  paddingHorizontal: 14
                }}
              >
                <div
                  style={{
                    width: '100%',
                    borderWidth: '1px',
                    paddingBottom: 10,
                    paddingTop: 10,
                  }}
                  key={field.id}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                >
                  <Checkbox
                    style={{
                      alignSelf: 'center',
                      width: '100%'
                    }}
                    value={field?.id}
                    checked={stateData?.selectedCustomFields?.find((selectedField: ICustomFieldData) => selectedField.id === field.id) ? true : false}
                    onChange={(event) => {
                      let tempSelectedCustomFields = stateData?.selectedCustomFields;
                      if (event?.target?.checked) {
                        tempSelectedCustomFields = [...tempSelectedCustomFields, field];
                      } else {
                        tempSelectedCustomFields = tempSelectedCustomFields.filter((tempSelectedField) => {
                          return field?.id !== tempSelectedField?.id
                        })
                      }
                      setStateData((prev)=> {
                        return {
                          ...prev,
                          selectedCustomFields: tempSelectedCustomFields
                        }
                      });
                    }}
                  >
                    <Text style={{
                        marginLeft: 4,
                        color: stateData?.hoveredIndex === index ? Colors.Custom.mainPrimaryPurple : Colors.Custom.Gray500,
                        fontSize: 16,
                        alignSelf: 'center',
                      }}
                    >
                      {field?.title}
                    </Text>
                  </Checkbox>
                </div>
              </Pressable>
              {stateData?.customAttributeData?.length -1 > index && <Divider style={{margin: 0}} />}
              </Option>
            </>
          )
        })
      }
    </>
  );

  const renderNoDataView = (
    <>
      <Pressable
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 2
        }}
        onPress={()=> {
          onAddCustomFiledDrawerVisibleChanges(true);
        }}
      >
        <Text>
          {stateData?.customFieldText}
        </Text>
        <Text
          style={{
            color: Colors.Custom.mainPrimaryPurple,
            fontSize: 14,
            fontWeight: '500',
          }}
        >
          {'+'} {'Add As New'}
        </Text>
      </Pressable>
    </>
  )

  const getCustomFiled = async (searchString: string) => {
    setStateData((prev)=> {
      return {
        ...prev,
        loading: true
      }
    })
    const params = {
      pageNo: 1,
      pageSize: CUSTOM_FIELD_SELECT_DATA_LIMIT,
      searchString: searchString,
      entityType: props?.entityType,
      showDisabled: SHOW_DISABLED.NO,
      subType: props?.contactTypeId
    }
    try {
      const response = await getCustomFieldData(params);
      if (response?.data?.customAttributes?.length) {
        const customField = response?.data?.customAttributes;
        const formattedData = getFormattedCustomFieldData(customField);
        const formattedCustomAttribute = removeAlreadySelected(formattedData, props?.customAttributesValue)
        setStateData((prev)=> {
          return {
            ...prev,
            customAttributeData: formattedCustomAttribute,
            loading: false,
          }
        })
      } else {
        setStateData((prev)=> {
          return {
            ...prev,
            loading: false,
            customAttributeData: []
          }
        })
      }
    } catch (error: any) {
      setStateData((prev)=> {
        return {
          ...prev,
          loading: false
        }
      })
      showToast({
        toastType: ToastType.error,
        message: error?.response?.data?.message || intl.formatMessage({id: 'apiErrorMsg'}),
        duration: CUSTOM_FIELD_TOAST_ERROR_DURATION_LONG,
        closeAllPrevToast: true,
      })
    }
  }

  const onSearch = (value: string) => {
    setStateData((prev)=> {
      return {
        ...prev,
        customFieldText: value,
      }
    })
  }

  const getSelectComponent = (customAttributeSelectType: string) => {
    if (customAttributeSelectType === CUSTOM_ATTRIBUTE_SELECT_TYPE.MULTI_SELECT) {
      return <>
        <Text style={{
          color: Colors.Custom.Gray500,
          fontWeight: '400',
          fontSize: 14,
          marginLeft: 4,
          marginBottom: 10,
        }}>
          {intl.formatMessage({id: 'selectCustomField'})}
          <Text style={{
            color: Colors.Custom.Gray400,
          }}>
            {intl.formatMessage({id: 'onlyActiveFieldMsg'})}
          </Text>
        </Text>
        <Select
          showSearch
          className="select-custom-field"
          popupClassName="select-custom-field"
          style={{
            borderRadius: 6,
            width: '100%'
          }}
          disabled={stateData?.loading}
          loading={stateData?.loading}
          open={stateData?.visible}
          onSearch={onSearch}
          placeholder={props?.placeholder}
          notFoundContent={renderNoDataView}
          onDropdownVisibleChange={(open) => onShowCustomFieldOptionVisibleChanges(open)}
        >
          {renderOptions}
        </Select>
      </>
    } else if (customAttributeSelectType === CUSTOM_ATTRIBUTE_SELECT_TYPE.SINGLE_SELECT) {
      return <Select
        showSearch
        style={{
          borderRadius: 6,
          width: '100%'
        }}
        disabled={stateData?.loading}
        status={props?.importError && !props?.selectedValue ? 'error' : ''}
        value={(props?.selectedValue && !stateData?.loading) ? props?.selectedValue : undefined}
        loading={stateData?.loading}
        onSearch={onSearch}
        placeholder={props?.placeholder}
        notFoundContent={renderNoDataView}
        onSelect={(value, item: any)=> {
          setStateData((prev)=> {
            return {
              ...prev,
              visible: false,
            }
          })
          props?.onActionPerformed?.(COMMON_ACTION_CODES.ITEM_SELECT, item)
        }}
        open={stateData?.visible}
        onDropdownVisibleChange={(open) => onShowCustomFieldOptionVisibleChanges(open)}
      >
        {getFilteredCustomAttribute(stateData?.customAttributeData, props?.selectedCustomAttributesId, props?.selectedValue)?.map((field: ICustomFieldData, index: number) => {
          return (
            <>
            <Option key={field?.id} value={field?.title} type={field?.type} name={field?.title}>
              {field?.title}
            </Option>
            </>
          )
        })
      }
      </Select>
    }
    return <></>
  }

  useEffect(()=> {
    getCustomFiled('');
  },[props?.customAttributesValue, props?.contactTypeId])

  return (
    <>
      <View
        style={{
          width: '100%',
        }}
      >
        {getSelectComponent(props?.customAttributeSelectType)}
      </View>
      {stateData?.openAddCustomFiledDrawer && <AddCustomFieldDrawer
          isDrawerOpen={stateData?.openAddCustomFiledDrawer}
          onDrawerClose={() => {
            onAddCustomFiledDrawerVisibleChanges(false);
          }}
          onActionPerformed={onCustomFieldDrawerActionPerformed}
          title={stateData?.customFieldText}
          contactTypeId={props?.contactTypeId}
        />}
    </>
  )
}

