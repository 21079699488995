import React from 'react';
import Svg, {Path} from 'react-native-svg';
import {ICommonSvgProps} from '../interfaces';
import {Colors} from '../../../../styles/Colors';

const MailActionSvg = (props: ICommonSvgProps) => {
  const strokeColor = props?.isActive
    ? Colors.FoldPixel.GRAY300
    : Colors.Custom.Gray300;
  return (
    <Svg
      width={props?.width ? props?.width : '24'}
      height={props?.height ? props?.height : '24'}
      fill="none"
      viewBox="0 0 24 24"
    >
      <Path
        stroke={strokeColor}
        strokeLinecap="round"
        d="M6 8l2.159 1.8c1.837 1.53 2.755 2.295 3.841 2.295 1.086 0 2.005-.765 3.841-2.296L18 8m-8 12h4c3.771 0 5.657 0 6.828-1.172C22 17.657 22 15.771 22 12c0-3.771 0-5.657-1.172-6.828C19.657 4 17.771 4 14 4h-4C6.229 4 4.343 4 3.172 5.172 2 6.343 2 8.229 2 12c0 3.771 0 5.657 1.172 6.828C4.343 20 6.229 20 10 20z"
      ></Path>
    </Svg>
  );
};

export default MailActionSvg;
