import React from 'react';
import ReactDOM from 'react-dom';
import {ReactComponent} from '@foldhealth/formio-react';
import {Formio} from '@foldhealth/formiojs';
import CustomWrapper from '../CustomWrapper/CustomWrapper';
import KeyField from '../../EditFormFields/KeyField';
import ConditionalComponents from '../../EditFormFields/ConditionalFields';
import CustomClassComponent from '../CustomWrapper/CustomClassComponent';
import QuillConfig from '../../Builder/QuillConfig';
import AddOrUpdatePatientDemographics from './AddOrUpdatePatientDemographics/AddOrUpdatePatientDemographics';
import {CapabilityResource} from '../CustomWrapper/CustomComponentHelper';
import { ALLOW_OVERRIDE_COMPONENT, CustomComponentKey, getComponentField, getFieldDisplayName, isRequiredField } from '../CustomComponentUtils';
import { IEhrCapability } from '../../../../../../Interfaces/CommonInterfaces';
import ShareWithPatientFields from '../../EditFormFields/ShareWithPatientFields';

export default class PatientDemographics extends CustomClassComponent {
  static get builderInfo() {
    return {
      title: 'Patient Demographics',
      icon: 'filetext1',
      group: 'advanced',
      documentation: '',
      schema: PatientDemographics.schema(),
    };
  }

  labelIsHidden() {
    return true;
  }

  static schema() {
    return ReactComponent.schema({
      type: 'patientDemographics',
      label: 'Patient Demographics',
      key: 'patientDemographics',
    });
  }

  attachReact(element: any) {
    return ReactDOM.render(
      <CustomWrapper
        formOptionData={this.options}
        capabilityList={[CapabilityResource.patient]}
      >
        <AddOrUpdatePatientDemographics
          options={this.options}
          disabled={this.disabled}
          component={this.component}
          validateRef={this.validateRef}
          onChange={this.updateValue}
        />
      </CustomWrapper>,
      element
    );
  }

  static editForm = function () {
    return {
      key: 'display',
      components: [
        {
          type: 'oldtextfield',
          key: 'label',
          label: 'Label',
          input: true,
          validate: {
            required: true,
          },
        },
        ALLOW_OVERRIDE_COMPONENT,
        ...ShareWithPatientFields,
        {
          type: 'checkBoxes',
          key: 'enabledFields',
          label: 'Select Demographic Field',
          input: true,
          validate: {
            required: true,
          },
          dataSrc: 'asyncOptions',
          data: {
            async asyncOptions(component: any, options: any) {
              const capabilities: IEhrCapability[] = options.ehrCapabilities || [];
              const capability = capabilities.find((capability) => capability.resourceName == CapabilityResource.patient);
              const keyAllowedOperations = capability?.abilities?.keyAllowedOperations || {};
              const commField = getComponentField(CustomComponentKey.PATIENT_DEMOGRAPHICS, keyAllowedOperations);
              const finalFieldList = commField.map((demographicField) => {
                return {
                  label: getFieldDisplayName(CustomComponentKey.PATIENT_DEMOGRAPHICS, demographicField, keyAllowedOperations),
                  value: demographicField,
                  disabled: isRequiredField(CustomComponentKey.PATIENT_DEMOGRAPHICS, demographicField, keyAllowedOperations),
                };
              });
              return Promise.resolve().then(() => finalFieldList);
            },
          },
        },
        {
          type: 'textarea',
          input: true,
          key: 'description',
          label: 'Description',
          placeholder: 'Description for this field.',
          tooltip:
            'The description is text that will appear below the input field.',
          editor: 'quill',
          wysiwyg: QuillConfig,
        },
        ...ConditionalComponents,
        ...KeyField,
      ],
    };
  };
}

Formio.Components.addComponent('patientDemographics', PatientDemographics);
