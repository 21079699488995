import React from 'react';
import { Svg, Path, G, Defs } from 'react-native-svg';
import { ICommonSvgProps } from '../../components/common/Svg/interfaces';

function ClockIcon2024(props: ICommonSvgProps) {
    return (
        <Svg
            width={props.width ||"16"}
            height={props.height ||"16"}
            fill="none"
            viewBox="0 0 16 16"
        >
            <G clipPath="url(#clip0_4573_133988)">
                <Path
                    stroke={props.customStrokeColor || "#6F7A90"}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8 5.336v2.667l1.666 1.666m5-1.666a6.667 6.667 0 11-13.333 0 6.667 6.667 0 0113.333 0z"
                ></Path>
            </G>
            <Defs>
                <clipPath id="clip0_4573_133988">
                    <Path fill="#fff" d="M0 0H16V16H0z"></Path>
                </clipPath>
            </Defs>
        </Svg>
    )
}

export default React.memo(ClockIcon2024);