import {HStack, Stack, Text, VStack} from 'native-base';
import {DATE_FORMATS} from '../../../constants';
import {Colors} from '../../../styles';
import {
  convertToTimezone,
  getCurrentTimeZone,
  getDiffBetweenTwoTime,
  getFormattedDate,
} from '../../../utils/DateUtils';
import React from 'react';
import { TestIdentifiers, testID } from '../../../testUtils';
import ReccuringAppointmentIcon from '../../../assets/Icons/ReccuringAppointmentIcon';
import Feather from 'react-native-vector-icons/Feather';

export interface DateAndTimeProps {
  startDateTime: string;
  endDateTime: string;
  paddingX?: number;
  paddingY?: number;
  selectedTimezone?: any;
  isRecurrentAppointment?: boolean;
  showVerticalLayout?: boolean;
}

const DateAndTime = (props: DateAndTimeProps) => {
  return (
    <Stack
      flexDirection={props.showVerticalLayout ? 'column' : 'row'}
      borderColor={'#F2F4F7'}
      alignItems={'flex-start'}
      justifyContent={'space-around'}
      width={'full'}
      height={10}
      px={props.paddingX}
      py={props.paddingY}
    >
      <HStack alignItems={'center'} justifyContent={'center'}>
        <Feather
          name="calendar"
          size={16}
          color={Colors.Custom.Gray500}
        />
        <Text
          mx={2}
          fontSize="14"
          fontWeight="500"
          color={Colors.Custom.Gray700}
          {...testID(TestIdentifiers.appointmentDate)}
        >
           {getFormattedDate(
            props.startDateTime,
            DATE_FORMATS.DISPLAY_DATE_FORMAT
          )}
        </Text>
        {props.isRecurrentAppointment && (
          <ReccuringAppointmentIcon color={Colors.Custom.black25} {...testID('RecurringIcon')} />
        )}
      </HStack>
      <HStack alignItems={'center'} justifyContent={'center'} marginTop={props.showVerticalLayout ? 4 : 0}>
        <Feather
          name="clock"
          size={16}
          color={Colors.Custom.Gray500}
        />
        <Text
          mx={2}
          fontSize="14"
          fontWeight="500"
          color={Colors.Custom.Gray700}
          {...testID(TestIdentifiers.appointmentCardStartTime)}
        >
          {convertToTimezone(
            props.startDateTime,
            props.selectedTimezone
              ? props.selectedTimezone
              : getCurrentTimeZone()
          ).format(DATE_FORMATS.DISPLAY_TIME_FORMAT)}
        </Text>
        <Text
          mx={1}
          fontSize="14"
          fontWeight="400"
          color={Colors.Custom.Gray500}
          {...testID(TestIdentifiers.appointmentCardDuration)}
        >
          {'(for '}
          {getDiffBetweenTwoTime(props.startDateTime, props.endDateTime)}
          {' min)'}
        </Text>
      </HStack>
    </Stack>
  );
};

export default DateAndTime;
