import { useLazyQuery } from '@apollo/client';
import { Modal, Skeleton, Table } from 'antd';
// import './../../Styles.css';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import {
  Button,
  Icon,
  IconButton,
  Switch,
  Text,
  Tooltip,
  View,
  useToast
} from 'native-base';
import { useEffect, useState } from 'react';
import { Dimensions, Pressable } from 'react-native';
import AntIcon from 'react-native-vector-icons/AntDesign';
import Feather from 'react-native-vector-icons/Feather';
// import './../../Styles.css';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { BUTTON_TYPE } from '../../../../../../constants';
import { COMMON_ACTION_CODES } from '../../../../../../constants/ActionConst';
import { CLOUD_TELEPHONY_APOLLO_CONTEXT } from '../../../../../../constants/Configs';
import { FlowType } from '../../../../../../context/WorkflowContext';
import CloudTelephonyQueries from '../../../../../../services/CloudTelephony/CloudTelephonyQueries';
import { Colors } from '../../../../../../styles';
import {
  findIsAllowToRunAutomation,
  getAccountUUID,
  getFoldLocations,
  getLocationListFromWorkflowLocations,
  getLocationNames,
  getPracticeLocationUuidList,
  getUserUUID,
  isAdminUser,
  isLoginUserBusinessOwner,
  isStringArrayElementDiff
} from '../../../../../../utils/commonUtils';
import { FoldButton } from '../../../../../CommonComponents/FoldButton/FoldButton';
import WorkflowTimeSavedView from '../../../../../common/AppInfo/WorkflowTimeSavedView';
import { DisplayText } from '../../../../../common/DisplayText/DisplayText';
import { TableWrapper } from '../../../../../common/TableWrapper';
import { changeWorkflowIdForImport, getWorkflowBasePath, isNotPermittedToEdit, isSubsetArray } from '../../../workflowUtils';
import {
  deleteWorkflowById, getWorkflowById, getWorkflowListWithPage, getWorkflowNodeMetaData,
  updateWorkflowState
} from '../../AddOrUpdateWorkflow/WorkflowApi';
import { IItemToDelete } from '../../AddOrUpdateWorkflow/WorkflowTriggerView/interfaces';
import { phoneTreeAutoSaveInDraft } from '../../AddOrUpdateWorkflow/WorkflowUtils';
import CloneWorkflow from '../../CloneWorkflow/CloneWorkflow';
import { ExecuteWorkflow } from '../../ExecuteWorkflow';
import WorkflowExecutionGraph from '../Helper/WorkflowExecutionGraph';
import WorkflowGraphPopup from '../Helper/WorkflowGraphPopup';
import { IWorkflowData, IWorkflowViewProps } from '../Interfaces';
import NewWorkflowTableView from '../NewWorkflowTableView/NewWorkflowTableView';
import {ToastType, showToast} from '../../../../../../utils/commonViewUtils';
import {useIntl} from 'react-intl';
import UserPracticeLocationQueries, { GET_ACCOUNT_LOCATION_INCLUDING_DELETED_BY_IDS } from '../../../../../../services/Location/UserPracticeLocationQueries';
import { UserLocationContext } from '../../../../../../context/UserLocationContext';
import { CONFIG_CODES } from '../../../../../../constants/AccountConfigConst';
import { isAccountConfigEnabled } from '../../../../../../utils/configUtils';
import { ILocation } from '../../../../ContentManagement/CampaignManagement/interfaces';
import TagsColumnView from '../../../../Contacts/TeamMembers/TeamMembersTable/TagsColumnView';
import { reactStyles, styles } from '../../../workflowStyles';

const WorkflowNodeName = (props: { nodeType: string; flowType: string, isNotPermitted?: boolean }) => {
  const [value, setValue] = useState('');
  getWorkflowNodeMetaData(
    props.flowType,
    'nodeInfo',
    props.nodeType,
    (response: any) => {
      setValue(response?.displayName || props.nodeType);
    },
    () => {
      setValue(props.nodeType);
    }
  );
  return (
    <View>
      {!!value ? <Text color={props?.isNotPermitted ? Colors.FoldPixel.GRAY150 : Colors.Custom.MonochromeBlack}>{value}</Text> : <Skeleton.Input active={true} />}
    </View>
  );
};


const WorkflowListView = (props: IWorkflowViewProps): JSX.Element => {
  const navigate = useNavigate();
  const [page, setPage] = useState({ page: 0, pageSize: 10, total: 10 })
  const isIVRFlow = props.flowType === FlowType.ivr;
  const [isShowWorkflowGraphPopup, setIsShowWorkflowGraphPopup] =
    useState(false);
  const [selectedWorkflowData, setSelectedWorkflowData] = useState<any>();
  const [dataSource, setDataSource] = useState<Array<IWorkflowData>>([]);
  const intl = useIntl();
  const toast = useToast();
  const userUuid = getUserUUID();
  const location = useLocation();

  const [dataSourceMap, setDataSourceMap] = useState<{ [key: string]: any }>([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText]: any = useState(props.searchText);
  const [tagList, setTagList]: any = useState<Array<string>>([]);
  const [userPracticeLocations, setUserPracticeLocations] = useState<any>([])
  const isMsoEnabled =  isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED)
  const isBusinessOwner = isLoginUserBusinessOwner()
  const [componentState, setComponentState] = useState({
    id: '',
    name: '',
    workflowMasterId: '',
    assigneeCount: 0,
    isEnabled: false,
    showDeleteModal: false,
    isOutOfOfficeIvr: false,
  });
  const [selectedWorkflowLocations, setSelectedWorkflowLocations] = useState<string[]>([]);

  const [executeState, setExecuteState] = useState({
    isOpen: false,
  });
  const [foldLocations, setFoldLocations] = useState([]);

  const [cloneDrawerState, setCloneDrawerState] = useState({
    isOpen: false,
  });

  const isHideGroupAndEmployer = !findIsAllowToRunAutomation();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.has('isdraweropen')) {
      const isDrawerOpenParam = searchParams.get('isdraweropen');
      const workflowMasterId = searchParams.get('workflowMasterId');
      const flowType = searchParams.get('flowtype');
      const key = searchParams.get('key');
      if (isDrawerOpenParam !== null) {
        const drawerStatus = isDrawerOpenParam === 'true';
        setIsShowWorkflowGraphPopup(drawerStatus);
        const record = {
          workflowMasterId,
          flowType,
          key
        };
        setSelectedWorkflowData(record)
      }
    }
  }, []);


  const setItemsForDeletions = async (record: any) => {
    const newItemDeleteState = {
      ...componentState,
      id: record.id,
      name: record.name,
      workflowMasterId: record.workflowMasterId,
      assigneeCount: 0,
      showDeleteModal: true,
      isOutOfOfficeIvr: false,
      isEnabled: record?.isEnabled
    }
    if (isIVRFlow) {
      setLoading(true);
      const ivrFlowData = await onDeleteIvr(record.workflowMasterId);
      if (ivrFlowData.assigneeCount || ivrFlowData.assigneeCount > 0) {
        newItemDeleteState.assigneeCount = ivrFlowData.assigneeCount;
      }
      newItemDeleteState.isOutOfOfficeIvr = ivrFlowData.isOutOfOfficeIvr;
    }
    setComponentState(() => {
      return newItemDeleteState;
    });
    setLoading(false);
  }
  const accountUuid = getAccountUUID();
  const searchByText = (text: string) => {
    if (searchText !== text) {
      setSearchText(text);
      setDataSource([]);
      setPage((prev) => {
        return {
          ...prev,
          page: 0
        }
      });
      fetchData();
    }
  };
  const searchByTagList = (newTagList: Array<string>) => {
    if (isStringArrayElementDiff(tagList, newTagList)) {
      fetchData();
      setTagList(newTagList);
    }
  };

  const showWorkflowPopUp = (workflow: any) => {
    setSelectedWorkflowData(workflow);
    setIsShowWorkflowGraphPopup(true);
  }

  const [getUserPracticeLocations] = useLazyQuery(
    UserPracticeLocationQueries.GetMyPracticeLocations,
    {
      fetchPolicy: 'no-cache',
      variables: {
        userUuid: userUuid,
        accountUuid: accountUuid,
      },
    }
  );

  const getUserPracticeLocation = async () => {
    const response = await getUserPracticeLocations();
    const data = response?.data;
    const uuidList: string[] = []

    if (data?.userPracticeLocations?.length > 0) {
      data?.userPracticeLocations?.forEach((object: any) => {
        if (object?.accountLocations && object?.accountLocations.length > 0 && object?.accountLocations[0]?.uuid) {
          uuidList.push(object?.accountLocations[0]?.uuid)
        }
      })
    }
    setUserPracticeLocations(uuidList)
    return uuidList
  };
  let userPracticeLocationsUuidArray: string[] = []

  const fetchData = async () => {
    setLoading(true);
    let fLocations = [...foldLocations]
    if (isMsoEnabled) {
      userPracticeLocationsUuidArray = await getUserPracticeLocation()
      if (!fLocations?.length) {
        fLocations = await getFoldLocations()
        if (fLocations?.length) setFoldLocations(fLocations)
      }
    }
    getWorkflowListWithPage(
      isMsoEnabled && !isBusinessOwner,
      userPracticeLocationsUuidArray || [],
      props.searchText,
      props.flowType,
      accountUuid,
      async (dataList: any, total: number) => {
        dataList = dataList || [];
        let ivrFlowList: any;
        if (props.flowType === FlowType.ivr) {
          ivrFlowList = await GET_IVR_LIST({
            context: { service: CLOUD_TELEPHONY_APOLLO_CONTEXT },
            variables: {
              accountId: accountUuid
            },
          });
        }
        dataList = dataList.sort(function (data1: any, data2: any) {
          const date1 = moment(data1.date).unix();
          const date2 = moment(data2.date).unix();
          return date2 - date1;
        });
        const dataSourceMap: { [key: string]: any } = {};

        dataList.forEach((workflowObject: any) => {
          ivrFlowList?.data?.flows?.forEach((ivrObject: any) => {
            if (workflowObject.workflowMasterId === ivrObject.workflowMasterId) {
              workflowObject.unavailabilityFlowTypeId = ivrObject.unavailabilityFlowTypeId
              workflowObject.phoneTreeTypeId = ivrObject.ivrUsageTypeId
            }
          });
        });
        setPage((prev) => {
          return { ...prev, total }
        })
        let dataListWithLocationNames = dataList
        if (isMsoEnabled) {
          dataListWithLocationNames = await Promise.all((dataList || []).map(async (workflowData: any) => {
            const locationIds = workflowData?.workflowLocations?.map((loc: {locationId : string}) => loc?.locationId) || [];
            const result = getLocationNames(fLocations, locationIds);
            const names = result?.map((loc: ILocation ) => loc?.name) || [];
            return {
              ...workflowData,
              locationNames: names
            };
          }));
        }

        setDataSource((prev) => {
          const list =  dataListWithLocationNames;
          const workflowMap: any = {};
          const filterList = list.filter((workflow: any) => {
            if (!workflowMap[workflow.id]) {
              workflowMap[workflow.id] = workflow.id;
              return true;
            }
          });
          filterList.forEach((data: any) => {
            dataSourceMap[data.id] = {
              isEnabled: data.isEnabled,
            };
          });

          return filterList;
        });
        setDataSourceMap(dataSourceMap);
        setLoading(false);
      },
      (error: any) => {

      },
      !!props.isNewWorkflowView,
      page
    );
  };

  useEffect(() => {
    fetchData();
  }, [page.page, page.pageSize])

  const onWorkflowStatusChange = (workflowId: string, isEnabled: boolean, callback: any) => {
    setLoading(true);
    updateWorkflowState(
      workflowId,
      isEnabled,
      (data: any) => {
        const newDataSource = [...dataSource];
        newDataSource.some((workflow: any) => {
          if (workflow.id === workflowId) {
            workflow.isEnabled = isEnabled;
          }
        })
        setDataSource(newDataSource);
        setLoading(false);
        if (callback) {
          callback(data);
        }
      },
      (error: any) => {

        if (callback) {
          callback(error);
        }
      }
    );
  };

  const workflowDeletedSuccessfullyToast = ()=> {
    showToast(
      toast,
      intl.formatMessage({id : isIVRFlow ?'phoneTreeDeletedSuccessfully' : 'automationDeletedSuccessfully'}),
      ToastType.success,
      4000
    );
  }

  const onWorkflowDelete = (itemToDelete: IItemToDelete) => {
    setLoading(true);
    deleteWorkflowById(
      itemToDelete,
      (data: any) => {
        const dataSourceCopy = dataSource.filter((data) => {
          return data.id != itemToDelete.id;
        });
        setDataSource(dataSourceCopy);
        setLoading(false);
      },
      (error: any) => {

      },
      () => {
        workflowDeletedSuccessfullyToast();
      }
    );
  };

  const [GET_IVR_PHONE_NUMBERS] = useLazyQuery(
    CloudTelephonyQueries.GET_IVR_PHONE_NUMBERS,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );

  const [GET_IVR_LIST] = useLazyQuery(
    CloudTelephonyQueries.GET_IVR_LIST,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );

  const onDeleteIvr = async (workflowMasterId: string) => {
    const virtualNumberAssignee = await GET_IVR_PHONE_NUMBERS({
      context: { service: CLOUD_TELEPHONY_APOLLO_CONTEXT },
      variables: {
        workflowMasterId: workflowMasterId,
      },
    });
    const ivrFlowData = {
      assigneeCount: 0,
      isOutOfOfficeIvr: false,
    };
    if (virtualNumberAssignee?.data?.flows?.[0]?.virtualPhoneNumberAssignees.length) {
      ivrFlowData.assigneeCount = virtualNumberAssignee?.data?.flows?.[0]?.virtualPhoneNumberAssignees.length;
    }
    ivrFlowData.isOutOfOfficeIvr = virtualNumberAssignee?.data?.flows?.[0]?.unavailabilityFlowTypeId ? true : false;

    return ivrFlowData;
  }

  function downloadObjectAsJson(exportObj: any, exportName: string) {
    exportObj = exportObj || {};
    const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj));
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", exportName + ".json");
    document.body.appendChild(downloadAnchorNode);
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }




  const downloadWorkflowContent = async (workflowId: string) => {
    const workflowData: any = await getWorkflowById(workflowId, accountUuid);
    const newWorkflowData = changeWorkflowIdForImport(workflowData);
    // (newWorkflowData?.graph?.nodes || []).forEach((node:any)=>{
    // (node?.data?.metaData?.userInputFieldList || []).forEach((node:any)=>{
    //   if(node?.value !=  undefined){
    //     delete node.value;
    //   }
    // });
    // const optionMap = node?.data?.metaData?.optionMap || {};
    // Object.keys(optionMap).forEach((key:string)=>{
    //   const data = optionMap[key];
    //   (data?.userInputFieldList || []).forEach((node:any)=>{
    //     if(node?.value !=  undefined){
    //       delete node.value;
    //     }
    //   })
    // })
    // });
    downloadObjectAsJson(newWorkflowData, newWorkflowData?.workflow?.name)

  }

  const getIvrDeleteModal = (componentState: any) => {
    let deleteModalText;
    if (componentState.isOutOfOfficeIvr) {
      deleteModalText = `This is business hours phone tree and can not be deleted`
    } else {
      deleteModalText = `This Phone Tree is set to ${componentState.assigneeCount} phone numbers. Please remove it from phone numbers first`
    }

    return (
      <Modal
        style={reactStyles.maxWidth400px}
        title={<DisplayText textLocalId="delete" textType="Heading" />}
        open={componentState.showDeleteModal}
        closable
        maskClosable
        destroyOnClose
        onCancel={() => {
          setComponentState(() => {
            return {
              ...componentState,
              showDeleteModal: false,
            };
          });
        }}
        footer={null}
      >
        <View
          flex={1}
          mt={-6}
          mb={2}
          backgroundColor={Colors.Custom.Gray300}
          height='1px'
        ></View>
        <DisplayText textLocalId={deleteModalText} textType="Text" />
        <Button.Group
          style={styles.buttonGroupStyle}
        >
          <FoldButton
            nativeProps={{
              variant: BUTTON_TYPE.SECONDARY,
              onPress: () => {
                setComponentState(() => {
                  return {
                    ...componentState,
                    showDeleteModal: false,
                  };
                });
              },
            }}
            customProps={{
              btnText: 'Cancel',
              withRightBorder: false,
            }}
          ></FoldButton>
        </Button.Group>
      </Modal>
    );
  }

  const deleteSeletedWorkFlow = () => {
    if (componentState.assigneeCount || componentState.assigneeCount > 0 || componentState.isOutOfOfficeIvr) {
      return getIvrDeleteModal(componentState);
    } else {
      return (
        <Modal
          title={<DisplayText textLocalId="delete" textType="Heading" />}
          open={componentState.showDeleteModal}
          closable
          maskClosable
          destroyOnClose
          onCancel={() => {
            setComponentState(() => {
              return {
                ...componentState,
                showDeleteModal: false,
              };
            });
          }}
          footer={null}
        >
          <View
            flex={1}
            mt={-6}
            mb={2}
            backgroundColor={Colors.Custom.Gray300}
            height='1px'
          ></View>
          <Text color={Colors.Custom.Gray500}>
            {(componentState?.isEnabled ? 'You are about to delete an active automation which could severely impact your current workflow. ' : '') + 'Do you want to delete'}
            <Text
              color={Colors.Custom.Gray900}
              mt={5}
            >{`"${componentState.name.replace(
              /(^\w{1})|(\s+\w{1})/g,
              (letter) => letter.toUpperCase()
            )}" `}</Text>
            automation?
          </Text>
          <Button.Group
            style={styles.buttonGroupStyle}
          >
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.SECONDARY,
                onPress: () => {
                  setComponentState(() => {
                    return {
                      ...componentState,
                      showDeleteModal: false,
                    };
                  });
                },
              }}
              customProps={{
                btnText: 'Cancel',
                withRightBorder: false,
              }}
            ></FoldButton>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                onPress: () => {
                  onWorkflowDelete(componentState);
                  setComponentState(() => {
                    return {
                      ...componentState,
                      showDeleteModal: false,
                    };
                  });
                },
              }}
              customProps={{
                btnText: 'Confirm',
                withRightBorder: false,
              }}
            ></FoldButton>
          </Button.Group>
        </Modal>
      );
    }
  };

  const columnTitleStyle: any = {
    fontSize: '13px',
    FontWeight: '500',
    fontStyle: 'normal',
    letterSpacing: '1px',
    lineHeight: '110%',
};

  const isLoginUserAdminRole = isAdminUser();
  // fieldType = 'nodeInfo', nodeType ='nodeType'
  const columns: ColumnsType<any> = [
    {
      title: 'Trigger',
      // dataIndex: 'triggerInfo',
      width: '15%',
      fixed: 'left',
      render: (record: any) => {
        let isNotPermitted = false;
        if (isMsoEnabled) {
          isNotPermitted = isNotPermittedToEdit(record?.workflowLocations, userPracticeLocations || [])
        }
        
        return (
        <WorkflowNodeName
          nodeType={record.triggerInfo.triggerCondition}
          flowType={props.flowType}
          key={record.triggerInfo.triggerCondition}
          isNotPermitted={isNotPermitted}
        />)
      }
    },
    {
      title: !isIVRFlow ? 'Fold automation' : 'Phone Tree Name',
      // dataIndex: 'name',
      width: '15%',
      render: (record: any, dataSource: any): JSX.Element => {
        let isNotPermitted = false;
        if (isMsoEnabled && !isBusinessOwner) {
          isNotPermitted = isNotPermittedToEdit(record?.workflowLocations, userPracticeLocations || [])
        }
        if (isIVRFlow && dataSource?.draft?.metadata?.workflowName) {
          return (
            <Text color={isNotPermitted? Colors.FoldPixel.GRAY150 : undefined}>{dataSource.draft.metadata.workflowName}</Text>
          )
        } else {
          return (
            <Text color={isNotPermitted? Colors.FoldPixel.GRAY150 : undefined}>{record.name}</Text>
          )
        }
      }
    },
    {
      title: 'Description',
      // dataIndex: 'description',
      width: '25%',
      render: (record: any, dataSource: any): JSX.Element => {
        let isNotPermitted = false;
        if (isMsoEnabled && !isBusinessOwner) {
          isNotPermitted = isNotPermittedToEdit(record?.workflowLocations, userPracticeLocations || [])
        }
        if (isIVRFlow && dataSource?.draft?.metadata?.description) {
          return (
            <Text color={isNotPermitted? Colors.FoldPixel.GRAY150 : undefined}>{dataSource.draft.metadata.description}</Text>
          )
        } else {
          return (
            <Text color={isNotPermitted? Colors.FoldPixel.GRAY150 : undefined}>{record.description}</Text>
          )
        }
      },
    },
    {
      title: 'Time Saved',
      width: '20%',
      render: (record: any) => {
        return (
          <WorkflowTimeSavedView
            isHideLabel={true}
            workflowMasterId={record?.workflowMasterId}
          ></WorkflowTimeSavedView>
        );
      },
    },
    ...(phoneTreeAutoSaveInDraft
      ? [
        {
          title: 'Draft',
          dataIndex: 'draftId',
          width: '25%',
          render: (draftId: any) => <>{draftId ? 'Yes' : 'No'}</>,
        },
      ]
      : []),
    ...(isIVRFlow && isMsoEnabled ? [
      {
        title: (
          <div style={columnTitleStyle}>Locations</div>
        ),
        key: 'locationNames',
        width: '16%',
        render: (record: any) => {
          let isNotPermitted = false;
          if (isMsoEnabled && !isBusinessOwner) {
            isNotPermitted = isNotPermittedToEdit(record?.workflowLocations, userPracticeLocations || [])
          }
          return (
            <TagsColumnView
              color={!isNotPermitted ? Colors.Custom.Gray900 : Colors.FoldPixel.GRAY150}
              labelData={record?.locationNames}
              bgColor={Colors.Custom.Primary50}
            />
          );
        }

      }] : []),
    // {
    //   title: 'Tags',
    //   width: '45%',
    //   render: (record: IWorkflowData) => {
    //     const tagList = record.tagList;
    //     return (
    //       <View flexDirection="row" alignItems="center">
    //         <WorkflowTag
    //           key={record?.id}
    //           isAddNew={true}
    //           placeholder="Add Tag"
    //           tagList={tagList}
    //           setTagList={(newTagList: Array<string>) => {
    //             const dataSourceCopy = JSON.parse(JSON.stringify(dataSource));
    //             dataSourceCopy.some((data: any) => {
    //               if (data.id == record.id) {
    //                 data.tagList = newTagList;
    //                 return true;
    //               }
    //             });
    //             setDataSource(dataSourceCopy);
    //             setLoading(true);
    //             setWorkflowTag(record.id, newTagList).then(() => {
    //               setLoading(false);
    //             });
    //           }}
    //         ></WorkflowTag>
    //       </View>
    //     );
    //   },
    // },
    {
      title: 'Actions',
      width: '10%',
      fixed: 'right',
      render: (record: IWorkflowData) => {
        let isChecked = false;
        if (record?.id && dataSourceMap[record.id]) {
          isChecked = dataSourceMap[record.id].isEnabled;
        }

        let isNotPermitted = false;
        if (isMsoEnabled && !isBusinessOwner) {
          isNotPermitted = isNotPermittedToEdit(record?.workflowLocations, userPracticeLocations || [])
        }

        return (
          <View flexDirection="row" alignItems="center">
            {!isIVRFlow && (
              <Switch
                disabled={isNotPermitted}
                cursor={isNotPermitted ? 'not-allowed' : 'pointer'}
                onTouchEnd={(e) => {
                  e.stopPropagation();
                }}
                onToggle={function () {
                  const dataSourceMapCopy = JSON.parse(
                    JSON.stringify(dataSourceMap)
                  );
                  dataSourceMapCopy[record.id].isEnabled =
                    !dataSourceMapCopy[record.id].isEnabled;
                  onWorkflowStatusChange(
                    record.id,
                    dataSourceMapCopy[record.id].isEnabled,
                    undefined
                  );
                  setDataSourceMap(dataSourceMapCopy);
                }}
                isChecked={isChecked}
                size={'sm'}
              />
            )}

            {!record.unavailabilityFlowTypeId && <IconButton
              disabled={isNotPermitted}
              cursor={isNotPermitted ? 'not-allowed' : 'pointer'}
              colorScheme="gray"
              tintColor="gray.500"
              key={record.id + 'delete'}
              variant="ghost"
              onPress={() => {
                setItemsForDeletions(record);
              }}
              icon={
                <Icon cursor={isNotPermitted ? 'not-allowed' : 'pointer'} as={AntIcon} name="delete" size="4" color= {isNotPermitted ? Colors.FoldPixel.GRAY150 : Colors.Custom.Gray600} />
              }
            />}
            <IconButton
              disabled={isNotPermitted}
              colorScheme="gray"
              key={record.id + 'edit'}
              variant="ghost"
              onPress={() => {
                const navigationPath = getWorkflowBasePath(props.flowType);
                navigate(
                  navigationPath +
                  '/update' +
                  '/' +
                  record.workflowMasterId +
                  '?flowType=' +
                  record.flowType
                );
              }}
              icon={<Icon cursor={isNotPermitted ? 'not-allowed' : 'pointer'} as={AntIcon} name="edit" size="4" color= {isNotPermitted ? Colors.FoldPixel.GRAY150 : Colors.Custom.Gray600} />}
            />
            {isLoginUserAdminRole && false && <IconButton
              colorScheme="gray"
              key={record.id + 'download'}
              variant="ghost"
              onPress={() => {
                downloadWorkflowContent(record.id)
              }}
              icon={<Icon as={AntIcon} name="download" size="4" color= "gray.400" />}
            />}
            {!isIVRFlow && (
              <IconButton
                colorScheme="gray"
                key={record.id + 'list'}
                variant="ghost"
                onPress={() => {
                  const navigationPath = getWorkflowBasePath(record.flowType);
                  navigate(
                    navigationPath +
                    '/execution/audit' +
                    '/' +
                    record.workflowMasterId +
                    '?flowType=' +
                    record.flowType
                  );
                }}
                icon={
                  <Icon
                    as={AntIcon}
                    name="barschart"
                    size="4"
                    color="green.400"
                  />
                }
              />
            )}

            {!isIVRFlow && (
              <View
                width={10}
                height={10}
                alignItems="center"
                justifyContent={'center'}
              >
                <Pressable
                  onPress={() => {
                    if (record?.isDisabled) {
                      return;
                    }
                    showWorkflowPopUp(record)
                  }}
                >
                  <View width={10} height={10}>
                    <WorkflowExecutionGraph
                      workflowData={record}
                      isIcon={true}
                      setDisabledStatus={(record: any, isDisabled: boolean) => {
                        record.isDisabled = isDisabled;
                      }}
                    ></WorkflowExecutionGraph>
                  </View>
                </Pressable>
              </View>
            )}
            {!isIVRFlow && record?.triggerInfo?.triggerCondition === 'ForAllPatientWithFilter' && (
              <Tooltip label="Execute" placement="top">
                <IconButton
                  colorScheme="gray"
                  key={record.id + 'execute'}
                  variant="ghost"
                  onPress={() => {
                    setSelectedWorkflowData(record);
                    setExecuteState((prev) => {
                      return {
                        ...prev,
                        isOpen: true,
                      };
                    });
                  }}
                  icon={
                    <MaterialCommunityIcons
                      name="lightning-bolt"
                      size={18}
                      color={Colors.Custom.Gray400}
                    />
                    // <Icon
                    //   as={MaterialCommunityIcons}
                    //   name="lightning-bolt"
                    //   size="6" //   color="gray.400"
                    // />
                  }
                />

              </Tooltip>
            )}

            <Tooltip label="Duplicate" placement="top">
              <IconButton
                colorScheme="gray"
                key={record.id + 'Clone'}
                variant="ghost"
                onPress={() => {
                  setSelectedWorkflowData(record);
                  setCloneDrawerState((prev) => {
                    return {
                      ...prev,
                      isOpen: true,
                    };
                  });
                }}
                icon={
                  <Feather
                    name="copy"
                    size={18}
                    color={Colors.Custom.Gray600}
                  />
                }
              />
            </Tooltip>
          </View>
        );
      },
    },
  ];

  if (isIVRFlow) {
    columns.shift();
    columns.splice(2, 1);
  } else {
    columns.splice(4, 1);
  }

  const onCloneWorkflowActionCompletion = (actionCode: string) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.CLONE:
        fetchData();
        break;
    }
    setCloneDrawerState((prev) => {
      return {
        ...prev,
        isOpen: false,
      };
    });
  }

  const { height } = Dimensions.get('window');
  const finalHeight = height - (props?.isNewWorkflowView ? 160 : 277);
  useEffect(() => {
    fetchData();
  }, []);

  const setExecuteStatePopUp = (record: any) => {
    setSelectedWorkflowData(record);
    setExecuteState((prev) => {
      return {
        ...prev,
        isOpen: true,
      };
    });

    const accountLocations = getLocationListFromWorkflowLocations(record?.workflowLocations)
    const fetch = async () => {
      getPracticeLocations(accountLocations)
        .then((data) => {
          const practiceLocationList = getPracticeLocationUuidList(data?.accountLocations)
          setSelectedWorkflowLocations(practiceLocationList)
        })
    }
    if (isMsoEnabled) {
      fetch()
    }
  }

  const pathSegments = location.pathname.split('/');
  const dynamicSegment = pathSegments[pathSegments.length - 1];

  const setClonePopup = (record: any) => {
    setSelectedWorkflowData(record);
    setCloneDrawerState((prev) => {
      return {
        ...prev,
        isOpen: true,
      };
    });
  }

  const [getPracticeLocationByAccountLocation, { loading: locationDetailsLoading }] = useLazyQuery(GET_ACCOUNT_LOCATION_INCLUDING_DELETED_BY_IDS, {
    fetchPolicy: 'no-cache',
    onError: error => {
      showToast(toast, 'Error in fetching practice locations', ToastType.error, 4000);
    },
  });

  const getPracticeLocations = async (accountLocationUuids: string[]) => {
    const response = await getPracticeLocationByAccountLocation({
      variables: {
        accountLocationIds: accountLocationUuids
      }
    })
    return response?.data
  }
  
  return (
    <UserLocationContext.Provider value={{userPracticeLocations: userPracticeLocations, isLocationFilterEnabled: isMsoEnabled }}>
    <View key={props.flowType}>
      {searchByText(props.searchText)}
      {props.isNewWorkflowView ? (
        // <NewWorkflowListView
        //   setClonePopup={setClonePopup}
        //   setExecuteStatePopUp={setExecuteStatePopUp}
        //   showWorkflowPopUp={showWorkflowPopUp}
        //   isIVRFlow={isIVRFlow}
        //   loading={loading}
        //   onWorkflowStatusChange={onWorkflowStatusChange}
        //   setPage={setPage}
        //   page={page}
        //   height={finalHeight}
        //   workflowList={dataSource}
        //   onWorkflowDelete={(record: any) => {
        //     setItemsForDeletions(record);
        //   }}
        // ></NewWorkflowListView>
        <NewWorkflowTableView
          userPracticeLocations={userPracticeLocations}
          setClonePopup={setClonePopup}
          setExecuteStatePopUp={setExecuteStatePopUp}
          showWorkflowPopUp={showWorkflowPopUp}
          isIVRFlow={isIVRFlow}
          loading={loading}
          onWorkflowStatusChange={onWorkflowStatusChange}
          setPage={setPage}
          page={page}
          height={finalHeight}
          workflowList={dataSource}
          onWorkflowDelete={(record: any) => {
            setItemsForDeletions(record);
          }}
          foldLocations={ foldLocations }
        ></NewWorkflowTableView>
      ) : (
        <TableWrapper
          pagination={{
            pageSize: page.pageSize,
            current: page.page + 1,
            total: page.total,
            onChange: (page, pageSize) => {
              setPage((prev) => {
                return { ...prev, page: page - 1, pageSize };
              });
            },
          }}
        >
          <Table
            rowClassName={(record, index) => {
              const isDisabled = isNotPermittedToEdit(record?.workflowLocations, userPracticeLocations || [])
              return isDisabled && !isBusinessOwner ? 'workflow-disabled-row' : '';
            }}
            className="lead-list-table-view patient-list-view"
            loading={loading}
            rowKey={'id'}
            sortDirections={['ascend']}
            showSorterTooltip={true}
            indentSize={1}
            scroll={{ x: 850, y: finalHeight }}
            dataSource={dataSource}
            pagination={false}
            columns={columns}
            onRow={(data) => {
              const isNotPermitted = isNotPermittedToEdit(data?.workflowLocations, userPracticeLocations || [])
              return {
                onClick: (event: any) => {
                  if (event?.target?.nodeName === 'INPUT') {
                    return;
                  }
                  if((isMsoEnabled && !isBusinessOwner) && isNotPermitted){
                    return
                  }
                  const navigationPath = getWorkflowBasePath(data.flowType);
                  navigate(
                    navigationPath +
                    '/update' +
                    '/' +
                    data.workflowMasterId +
                    '?flowType=' +
                    data.flowType
                  );
                },
              };
            }}
          />
        </TableWrapper>
      )}
      {isShowWorkflowGraphPopup ? (
        <WorkflowGraphPopup
          flowType={props.flowType}
          isShow={isShowWorkflowGraphPopup}
          workflowData={selectedWorkflowData}
          userPracticeLocations={userPracticeLocations}
          onClose={() => {
            navigate(`/admin/workflow/${dynamicSegment}`);
            setIsShowWorkflowGraphPopup(false);
          }}
          foldLocations={ foldLocations }
        ></WorkflowGraphPopup>
      ) : (
        <></>
      )}
      {deleteSeletedWorkFlow()}

      {executeState.isOpen && (
        <ExecuteWorkflow
          workflowLocations={selectedWorkflowLocations}
          isHideGroup={isHideGroupAndEmployer}
          isHideEmployer={isHideGroupAndEmployer}
          isOpen={executeState.isOpen}
          workflowData={selectedWorkflowData}
          onCompleteAction={() => {
            setExecuteState((prev) => {
              return {
                ...prev,
                isOpen: false,
              };
            });
          }}
        />
      )}
      {cloneDrawerState.isOpen && (
        <CloneWorkflow
          isOpen={cloneDrawerState.isOpen}
          workflowData={selectedWorkflowData}
          onCompleteAction={(actionCode: string) => {
            onCloneWorkflowActionCompletion(actionCode);
          }}
        />
      )}
    </View>
    </UserLocationContext.Provider>
  );
};
export default WorkflowListView;
