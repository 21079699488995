import { Drawer } from "antd";
import { HStack, Text, VStack, View } from "native-base";
import { useState } from "react";
import { TouchableOpacity } from 'react-native';
import { Colors } from "../../../styles";
import UnityAiICloseIcon from "../Svg/UnityAiICloseIcon";
import UnityAiIcon from "../Svg/UnityAiIcon";
import Chatbot from "./ChatBot";

const CloseButton = (props: { onPress: any }) => {
  return (
    <div style={{ width: '100%', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
      <TouchableOpacity onPress={props?.onPress}>
        <View style={{ flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
          <UnityAiICloseIcon customColor={Colors.Custom.mainPrimaryPurple} />
        </View>
      </TouchableOpacity>
    </div>
  );
};


const SeparatorLine = () => {
  return (
    <View

      style={{marginVertical:'8px',width:'full',height: 1, borderRadius: 10}}
      backgroundColor={Colors.Custom.Gray200}
    />
  );
};


const FoldUnityAi = (props: { isDrawer: boolean, isShowFoldUnityAi: boolean, setIsShowFoldUnityAi: any }) => {
  const { isShowFoldUnityAi, setIsShowFoldUnityAi, isDrawer } = props;
  const [isHideUnitIcon, setIsHideUnitIcon] = useState(true)

  return (

    isDrawer ? <Drawer

      title={<VStack>
        <View style={{
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
          <HStack style={{
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}>
            {isHideUnitIcon ? <></> : <UnityAiIcon width="40" height="40"></UnityAiIcon>}
            
          </HStack>
          <View alignItems={'center'}>
            <Text alignItems={'center'} fontSize={'18px'} color={'rgb(130, 90, 199)'}>Fold Unity AI</Text>
          </View>
          <View style={{
            alignItems: 'flex-end',
          }}>
            <CloseButton onPress={() => { setIsShowFoldUnityAi(false) }}></CloseButton>
          </View>
        </View>
        <View marginLeft={-18}>
        <SeparatorLine></SeparatorLine>
        <Text marginLeft={5} color={Colors.Custom.Gray400}>Fold Unity AI currently supports creating a task for provider, sending email to members, checking availability & searching a patient.</Text>
        <SeparatorLine></SeparatorLine>
        </View>

      </VStack>}
      placement="right"
      closable={false}

      width={'785px'}
      onClose={
        () => {
          setIsShowFoldUnityAi(false)
        }
      }
      visible={isShowFoldUnityAi}
    >
      <VStack width={'720px'} height={'100%'} alignItems={'center'} >
      
        <Chatbot  setIsHideUnitIcon={setIsHideUnitIcon} width={'720px'} heigth={(window.innerHeight - 220) + 'px'}></Chatbot>
      </VStack>
    </Drawer> : <></>
  );
};

export default FoldUnityAi;
