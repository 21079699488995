import { View, Text, FormControl, HStack, Select, Pressable, Actionsheet, ScrollView, Spacer } from 'native-base'
import React, { useState } from 'react'
import { Colors } from '../../../../styles';
import CustomLabel from '../CustomLabel/CustomLabel';
import { testID } from '../../../../testUtils';
import { camelCase } from 'lodash';
import { Keyboard } from 'react-native';


interface ICustomSelectProps {
  value: string;
  label: string;
  options: any[];
  placeholder?: string;
  isRequired?: boolean;
  isIntlLabel?: boolean;
  infoText?: string;
  optionProps?: IOption;
  isInvalid?: boolean;
  isDisabled?: boolean;
  styles?: {
    labelColor?: string;
    borderColor?: string;
    borderRadius?: number;
    height?: number;
    placeHolderColorText?: string
  }
  onSelect?: (option: any | undefined) => void;
  customSelectOptionLeftIcon?: (item:any) => JSX.Element;
  customDropDownIcon?: JSX.Element;
  customRenderOption?: (item: any) => JSX.Element;
}

interface IOption {
  key: string;
  value: string;
  label: string | string[];
}

const CustomSelect = (props: ICustomSelectProps) => {
  const {value, onSelect, placeholder, label, isRequired, infoText, isIntlLabel, options, optionProps, isInvalid, isDisabled, styles, customRenderOption} = props;
  const [actionSheetOpen, setActionSheetOpen] = useState(false);
  const fieldTestId = camelCase(label || 'CustomSelect') + 'Field';

  const getOptionLabel = (item: any) => {
    if (optionProps?.label) {
      if (Array.isArray(optionProps.label)) {
        let labelValue: any = item;
        optionProps.label.forEach((labelKey) => {
          labelValue = labelValue?.[labelKey];
        });
        return labelValue;
      } else {
        return item[optionProps.label]
      }
    } else {
      return item['label'];
    }
  }

  const getLabelFromValue = (key: string) => {
    const selectedOption = options.find(item => {
      const optionValue = item[optionProps?.value || 'value'];
      return optionValue === key;
    });
    if (selectedOption) {
      return getOptionLabel(selectedOption);
    }
  }

  return (
    <FormControl flex={1} flexDir={'column'}>
      {!!label && (
        <View marginLeft={1}>
          <CustomLabel
            label={label}
            isRequired={isRequired}
            infoText={infoText}
            isIntlLabel={isIntlLabel}
            labelColor={styles?.labelColor}
          />
        </View>
      )}
     {!customRenderOption && (
      <HStack
        flex={1}
        alignItems={'center'}
        width="100%"
        borderWidth={1}
        borderColor={isInvalid ? Colors.error[500] : (styles?.borderColor ||  Colors.Custom.Gray200)}
        backgroundColor={isDisabled ? Colors.Custom.Gray100 : 'white'}
        borderRadius={styles?.borderRadius || 8}
        paddingLeft={1}
        paddingRight={2}>
        <Select
          placeholderTextColor={styles?.placeHolderColorText}
          isDisabled={isDisabled}
          selectedValue={value}
          placeholder={placeholder}
          onValueChange={(value) => {
            const selectedValue = options.find((item) => item[optionProps?.value || 'value'] === value);
            onSelect?.(selectedValue);
          }}
          flex={1}
          borderRadius={styles?.borderRadius || 6}
          borderWidth={0}
          height={styles?.height || 12}
          fontSize={16}
          _selectedItem={{
            _text: {
              color: Colors.primary[600],
              fontWeight: 600
            }
          }}
        dropdownIcon={props?.customDropDownIcon}
        {...testID(fieldTestId)}
        >
          {options?.map((item, index) => {
            return (
              <Select.Item
                leftIcon={
                  props?.customSelectOptionLeftIcon
                    ? props.customSelectOptionLeftIcon?.(item)
                    : undefined
                }
                borderBottomWidth={index != options.length - 1 ? 1 : 0}
                borderColor={Colors.Custom.Gray200}
                _text={{
                  fontWeight: 600,
                  color: Colors.Custom.Gray500,
                }}
                key={item[optionProps?.key || 'key']}
                label={getOptionLabel(item)}
                value={item[optionProps?.value || 'value']}
                {...testID(getOptionLabel(item))}
              />
            );
            })
          }
        </Select>
      </HStack>
      )}
      {!!customRenderOption && (
        <>
        <Pressable
          onPress={() => {
            Keyboard.dismiss();
            setActionSheetOpen(true)
          }}
          isDisabled={isDisabled}
          {...testID(fieldTestId)}
        >
          <HStack
            alignItems={'center'}
            width="100%"
            borderWidth={1}
            borderColor={
              isInvalid
                ? Colors.error[500]
                : styles?.borderColor || Colors.Custom.Gray200
            }
            backgroundColor={isDisabled ? Colors.Custom.Gray100 : 'white'}
            borderRadius={styles?.borderRadius || 8}
            paddingLeft={1}
            paddingRight={2}
            minHeight={styles?.height || 12}
          >
            {!value && (
              <Text color={Colors.Custom.Gray400} paddingX={2}>
                {placeholder}
              </Text>
            )}
            {!!value && (
              <Text paddingX={2} fontSize={16}>
                {getLabelFromValue(value)}
              </Text>
            )}
            {
              props?.customDropDownIcon && 
              <>
                <Spacer />
                {props.customDropDownIcon}
              </>
            }
          </HStack>
        </Pressable>
        <Actionsheet isOpen={actionSheetOpen} onClose={() => setActionSheetOpen(false)}>
          <Actionsheet.Content backgroundColor={'white'}>
            <ScrollView width={'100%'}>
            {options.map((item, index) => {
              const isSelected = item[optionProps?.value || 'value'] === value;
              const label = getOptionLabel(item);
              return (
                <Actionsheet.Item
                  key={index}
                  width={'100%'}
                  onPress={() => {
                    setActionSheetOpen(false);
                    onSelect?.(item);
                  }}
                  borderBottomWidth={index != options.length - 1 ? 1 : 0}
                  borderColor={Colors.Custom.Gray200}
                  backgroundColor={isSelected ? Colors.Custom.Gray200 : 'white'}
                  {...testID(label)}
                >
                  {customRenderOption(item)}
                </Actionsheet.Item>
              );
            })}
            </ScrollView>
          </Actionsheet.Content>
        </Actionsheet>
        </>
      )}
    </FormControl>
  )
}

export default CustomSelect
