import {Formio} from '@foldhealth/formiojs';
import BasicFields from '../EditFormFields/BasicFields';
import ConditionalComponents from '../EditFormFields/ConditionalFields';
import KeyField from '../EditFormFields/KeyField';
import PrefixSuffixFields from '../EditFormFields/PrefixSuffixFields';

Formio.Components.components.number.editForm = function () {
  return {
    key: 'display',
    components: [
      ...BasicFields,
      {
        type: 'number',
        label: 'Minimum Value',
        key: 'validate.min',
        input: true,
        placeholder: 'Minimum Value',
        tooltip:
          'The minimum value this field must have before the form can be submitted.',
      },
      {
        type: 'number',
        label: 'Maximum Value',
        key: 'validate.max',
        input: true,
        placeholder: 'Maximum Value',
        tooltip:
          'The maximum value this field can have before the form can be submitted.',
      },
      ...PrefixSuffixFields,
      // KEPT FOR FUTURE 
      // {
      //   type: 'checkbox',
      //   label: 'Hide Label',
      //   tooltip:
      //     'Hide the label or title of this component. This allows you to show the label in the form builder, but not when the form is shown to the member.',
      //   key: 'hideLabel',
      //   input: true,
      // },
      ...ConditionalComponents,
      ...KeyField,
    ],
  };
};
