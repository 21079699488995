import React from 'react';
import {isWeb} from '../../../utils/platformCheckUtils';
import {Image} from 'native-base';

const ContactInfo = () => {
  return (
    <>
      {isWeb() ? (
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.46811 3.14989C3.91469 3.0543 3.17511 3.20523 2.53113 3.53728C2.34498 3.63287 2.16889 3.73853 2.0079 3.85424C0.579066 4.90071 -0.0196345 6.90812 0.000489934 9.1369C0.0206143 11.3657 0.654533 13.7655 1.59032 15.5365C2.5261 17.3074 4.15115 19.189 5.97744 20.4569C7.80372 21.7247 9.79604 22.3687 11.4664 21.7801C11.6575 21.7146 11.8487 21.6291 12.0298 21.5285C12.6688 21.1813 13.2071 20.6531 13.4386 20.1449C13.67 19.6368 13.67 19.2041 13.2172 18.7211L9.9671 15.7377C9.75579 15.5415 9.44889 15.5012 9.19231 15.6371L8.11062 16.2006C7.19999 16.6785 5.91203 16.2207 5.39886 15.2396L3.98512 12.558C3.46692 11.582 3.81406 10.2588 4.72469 9.77585L5.80135 9.19727C6.05793 9.06143 6.1988 8.78472 6.15856 8.49795L5.51961 4.13095C5.42402 3.48194 5.02153 3.24548 4.46811 3.14989Z"
            fill="#98A2B3"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M17.4483 0.518622C16.5528 0.161414 15.6321 -0.00964305 14.7265 0.000419151C12.0198 0.0255746 9.48914 1.63553 8.3823 4.39257C7.59242 6.35973 7.73329 8.40739 8.58354 10.1079L7.54211 11.6273C7.1849 12.1455 7.42639 12.6889 8.05528 12.6084L10.3394 12.3166C10.8979 12.7844 11.5418 13.1718 12.2613 13.4636C15.8485 14.9025 19.8482 13.2674 21.3273 9.59473C22.7964 5.90693 21.0355 1.95752 17.4483 0.518622ZM15.8937 9.83622C15.8937 10.4098 15.4309 10.8726 14.8573 10.8726C14.2838 10.8726 13.8209 10.4098 13.8209 9.83622V6.21383C13.8209 5.64028 14.2838 5.17742 14.8573 5.17742C15.4309 5.17742 15.8937 5.64028 15.8937 6.21383V9.83622ZM14.8573 4.65922C14.2838 4.65922 13.8209 4.19636 13.8209 3.62281C13.8209 3.04927 14.2838 2.5864 14.8573 2.5864C15.4309 2.5864 15.8937 3.04927 15.8937 3.62281C15.8937 4.19636 15.4258 4.65922 14.8573 4.65922Z"
            fill="#98A2B3"
          />
        </svg>
      ) : (
        <Image
          resizeMode="contain"
          size={'24px'}
          source={require('../../../assets/images/Contact/contactInfo.png')}
          alt="image"
        />
      )}
    </>
  );
};

export default ContactInfo;
