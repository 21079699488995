import React from "react";
import { Defs, G, Path, Rect, Svg } from "react-native-svg";

export const NoChatFound = () => {
  return (
    <Svg
      width="300"
      height="235"
      viewBox="0 0 340 235"
      fill="none"
    >
      <Rect
        x="63.1451"
        y="2.5"
        width="220"
        height="220"
        rx="18.8571"
        fill="#F2F4F7"
      />
      <G filter="url(#filter0_d_138_75041)">
        <Path
          d="M310.027 86.207H97.8841C93.5447 86.207 90.0269 89.7248 90.0269 94.0642V133.35C90.0269 137.689 93.5447 141.207 97.8841 141.207H310.027C314.366 141.207 317.884 137.689 317.884 133.35V94.0642C317.884 89.7248 314.366 86.207 310.027 86.207Z"
          fill="white"
        />
      </G>
      <Path
        d="M117.598 129C125.882 129 132.598 122.284 132.598 114C132.598 105.716 125.882 99 117.598 99C109.314 99 102.598 105.716 102.598 114C102.598 122.284 109.314 129 117.598 129Z"
        fill="#D0D5DD"
      />
      <Path
        d="M121 119.407V120C121 120.825 120.325 121.5 119.5 121.5H113.5C112.667 121.5 112 120.825 112 120V108C112 107.167 112.667 106.5 113.5 106.5H119.5C120.325 106.5 121 107.167 121 108V108.345M123.677 115.995C123.677 116.212 123.587 116.43 123.43 116.58C123.28 116.737 123.062 116.82 122.845 116.82H117.865L116.207 118.477V111.848C116.207 111.63 116.297 111.412 116.455 111.262C116.605 111.105 116.822 111.023 117.04 111.023H122.852C123.07 111.023 123.287 111.112 123.437 111.262C123.595 111.42 123.685 111.63 123.685 111.848V115.995H123.677Z"
        stroke="#667085"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <Path
        d="M190.17 99H149.312C146.709 99 144.598 101.111 144.598 103.714C144.598 106.318 146.709 108.429 149.312 108.429H190.17C192.773 108.429 194.884 106.318 194.884 103.714C194.884 101.111 192.773 99 190.17 99Z"
        fill="#D0D5DD"
      />
      <Path
        d="M218.455 119.43H149.312C146.709 119.43 144.598 121.54 144.598 124.144C144.598 126.748 146.709 128.858 149.312 128.858H218.455C221.059 128.858 223.17 126.748 223.17 124.144C223.17 121.54 221.059 119.43 218.455 119.43Z"
        fill="#E4E7EC"
      />
      <G filter="url(#filter1_d_138_75041)">
        <Path
          d="M242.027 153.207H29.8841C25.5447 153.207 22.0269 156.725 22.0269 161.064V200.35C22.0269 204.689 25.5447 208.207 29.8841 208.207H242.027C246.366 208.207 249.884 204.689 249.884 200.35V161.064C249.884 156.725 246.366 153.207 242.027 153.207Z"
          fill="white"
        />
      </G>
      <Path
        d="M49.5982 196C57.8825 196 64.5982 189.284 64.5982 181C64.5982 172.716 57.8825 166 49.5982 166C41.3139 166 34.5982 172.716 34.5982 181C34.5982 189.284 41.3139 196 49.5982 196Z"
        fill="#D0D5DD"
      />
      <Path
        d="M52.9996 186.407V187C52.9996 187.825 52.3246 188.5 51.4996 188.5H45.4996C44.6671 188.5 43.9996 187.825 43.9996 187V175C43.9996 174.167 44.6671 173.5 45.4996 173.5H51.4996C52.3246 173.5 52.9996 174.167 52.9996 175V175.345M55.6771 182.995C55.6771 183.212 55.5871 183.43 55.4296 183.58C55.2796 183.737 55.0621 183.82 54.8446 183.82H49.8646L48.2071 185.477V178.848C48.2071 178.63 48.2971 178.412 48.4546 178.262C48.6046 178.105 48.8221 178.023 49.0396 178.023H54.8521C55.0696 178.023 55.2871 178.112 55.4371 178.262C55.5946 178.42 55.6846 178.63 55.6846 178.848V182.995H55.6771Z"
        stroke="#667085"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <Path
        d="M122.17 166H81.3125C78.7089 166 76.5982 168.111 76.5982 170.714C76.5982 173.318 78.7089 175.429 81.3125 175.429H122.17C124.773 175.429 126.884 173.318 126.884 170.714C126.884 168.111 124.773 166 122.17 166Z"
        fill="#D0D5DD"
      />
      <Path
        d="M150.455 186.43H81.3125C78.7089 186.43 76.5982 188.54 76.5982 191.144C76.5982 193.748 78.7089 195.858 81.3125 195.858H150.455C153.059 195.858 155.17 193.748 155.17 191.144C155.17 188.54 153.059 186.43 150.455 186.43Z"
        fill="#E4E7EC"
      />
      <G filter="url(#filter2_d_138_75041)">
        <Path
          d="M242.027 18.207H29.8841C25.5447 18.207 22.0269 21.7248 22.0269 26.0642V65.3499C22.0269 69.6893 25.5447 73.207 29.8841 73.207H242.027C246.366 73.207 249.884 69.6893 249.884 65.3499V26.0642C249.884 21.7248 246.366 18.207 242.027 18.207Z"
          fill="white"
        />
      </G>
      <Path
        d="M49.5982 61C57.8825 61 64.5982 54.2843 64.5982 46C64.5982 37.7157 57.8825 31 49.5982 31C41.3139 31 34.5982 37.7157 34.5982 46C34.5982 54.2843 41.3139 61 49.5982 61Z"
        fill="#D0D5DD"
      />
      <Path
        d="M52.9996 51.4075V52C52.9996 52.825 52.3246 53.5 51.4996 53.5H45.4996C44.6671 53.5 43.9996 52.825 43.9996 52V40C43.9996 39.1675 44.6671 38.5 45.4996 38.5H51.4996C52.3246 38.5 52.9996 39.1675 52.9996 40V40.345M55.6771 47.995C55.6771 48.2125 55.5871 48.43 55.4296 48.58C55.2796 48.7375 55.0621 48.82 54.8446 48.82H49.8646L48.2071 50.4775V43.8475C48.2071 43.63 48.2971 43.4125 48.4546 43.2625C48.6046 43.105 48.8221 43.0225 49.0396 43.0225H54.8521C55.0696 43.0225 55.2871 43.1125 55.4371 43.2625C55.5946 43.42 55.6846 43.63 55.6846 43.8475V47.995H55.6771Z"
        stroke="#667085"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <Path
        d="M122.17 31H81.3125C78.7089 31 76.5982 33.1107 76.5982 35.7143C76.5982 38.3179 78.7089 40.4286 81.3125 40.4286H122.17C124.773 40.4286 126.884 38.3179 126.884 35.7143C126.884 33.1107 124.773 31 122.17 31Z"
        fill="#D0D5DD"
      />
      <Path
        d="M150.455 51.4297H81.3125C78.7089 51.4297 76.5982 53.5403 76.5982 56.144C76.5982 58.7476 78.7089 60.8583 81.3125 60.8583H150.455C153.059 60.8583 155.17 58.7476 155.17 56.144C155.17 53.5403 153.059 51.4297 150.455 51.4297Z"
        fill="#E4E7EC"
      />
      <Defs>
        <filter
          id="filter0_d_138_75041"
          x="68.0269"
          y="68.9213"
          width="271.857"
          height="99"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4.71429" />
          <feGaussianBlur stdDeviation="11" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.596078 0 0 0 0 0.635294 0 0 0 0 0.701961 0 0 0 0.6 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_138_75041"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_138_75041"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_138_75041"
          x="0.0269146"
          y="135.921"
          width="271.857"
          height="99"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4.71429" />
          <feGaussianBlur stdDeviation="11" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.596078 0 0 0 0 0.635294 0 0 0 0 0.701961 0 0 0 0.6 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_138_75041"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_138_75041"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_138_75041"
          x="0.0269146"
          y="0.921315"
          width="271.857"
          height="99"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4.71429" />
          <feGaussianBlur stdDeviation="11" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.596078 0 0 0 0 0.635294 0 0 0 0 0.701961 0 0 0 0.6 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_138_75041"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_138_75041"
            result="shape"
          />
        </filter>
      </Defs>
    </Svg>
  );
};
