import AsyncStorage from '@react-native-async-storage/async-storage';
import Cookies from 'js-cookie';
import {isValidObject} from './commonUtils';
import {isWeb} from './platformCheckUtils';

export default {
  setMultipleItems: async (object: Record<string, string>) => {
    try {
      if (!object || !Object.keys(object)?.length) {
        return;
      }
      const stringifiedValueObject: Record<string, string> = {};
      for (const objKey in object) {
        stringifiedValueObject[objKey] = JSON.stringify(object[objKey]);
      }
      if (isWeb()) {
        if (window.isExtension) {
          await chrome.storage.local.set(stringifiedValueObject);
        } else {
          for (const objKey in stringifiedValueObject) {
            Cookies.set(objKey, stringifiedValueObject[objKey]);
          }
        }
      } else {
        const objectToStore: [string, string][] = [];
        for (const objKey in stringifiedValueObject) {
          objectToStore.push([objKey, stringifiedValueObject[objKey]]);
        }
        await AsyncStorage.multiSet(objectToStore);
      }
    } catch (error) {}
  },

  setItem: async (key: string, value: string) => {
    const stringifiedValue = JSON.stringify(value);
    try {
      if (isWeb()) {
        if (window.isExtension) {
         await chrome.storage.local.set({[key]: stringifiedValue});
        } else {
          Cookies.set(key, stringifiedValue);
        }
      } else {
        await AsyncStorage.setItem(key, stringifiedValue);
      }
    } catch (error) {}
  },

  getItem: async (key: string) => {
    let json: any = null;
    let item: any;
    try {
      if (isWeb()) {
        if (window.isExtension) {
          const response = await chrome.storage.local.get(key);
          item = response?.[key];
        } else {
          item = Cookies.get(key);
        }
      } else {
        item = await AsyncStorage.getItem(key);
      }
      if (isValidObject(item)) {
        json = JSON.parse(item!);
      }
      return json;
    } catch (error) {
      return json;
    }
  },
  removeItem: async (key: string) => {
    try {
      if (isWeb()) {
        if (window.isExtension) {
         await chrome.storage.local.remove(key);
        } else {
        Cookies.remove(key);
        }
      } else {
        await AsyncStorage.removeItem(key);
      }
    } catch (error) {}
  },
};
