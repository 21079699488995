import { Drawer } from 'antd';
import { Divider, HStack, Text, View, VStack } from 'native-base';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import ReactToPrint from 'react-to-print';
import { BUTTON_TYPE, DATE_FORMATS, PHONE_NUMBER_MASK } from '../../../constants';
import { CONFIG_CODES } from '../../../constants/AccountConfigConst';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { Colors } from '../../../styles';
import { getConfigDataFromCode, numericStringMask } from '../../../utils/commonUtils';
import { getDateStrFromFormat } from '../../../utils/DateUtils';
import { IThemeAttachments } from '../../BodyContainer/interface';
import { getAdminAppUrl, getPatientAppUrl } from '../../RightSideContainer/ContentManagement/ContentManagementUtils';
import { getDefaultComponent } from '../../RightSideContainer/ContentManagement/EmailTemplates/EmailTemplatesUtils';
import { IDefaultFormTheme } from '../../RightSideContainer/Forms/interfaces';
import BrandingLogoWrapper from '../BrandingLogoWrapper/BrandingLogoWrapper';
import { ModalActionTitle } from '../ModalActionTitle/ModalActionTitle';
import { ComponentToPrint } from '../PrintComponent/ComponentToPrint';
import './PrintContactContent.scss';

interface IProps {
  title?: string;
  formattedContactData: any;
  isOpen: boolean;
  isLoading?: boolean;

  showPreviewInDrawer?: boolean;
  triggerPrint?: boolean;

  enablePatientDemographics?: boolean;

  children: any[] | any;

  onClose: () => void;
}

export function PrintContactContent(props: IProps) {
  const intl = useIntl();

  const personData = props.formattedContactData;

  const contextData = useContext(CommonDataContext);

  const componentRef = useRef(null);
  const printRef: any = useRef(null);

  const [printElement, setHeaderFooter] = useState<{
    header: any,
    footer: any,
  }>({
    header: '',
    footer: '',
  });

  const defaultThemeConfig = getConfigDataFromCode(CONFIG_CODES.DEFAULT_THEME);
  let configJson = undefined;
  let formThemeConfig: IDefaultFormTheme | undefined  = {} as IDefaultFormTheme;

  const accountData: any = contextData.accountSubdomain || {};
  const accountThemeConfig: any = contextData.accountThemeConfig || {};
  const tempThemeAdditionalData: string =
    accountThemeConfig.additionalAttributes || '{}';
  const finalThemeAdditionalAttributes: IThemeAttachments = JSON.parse(
    tempThemeAdditionalData
  );

  const subdomainMap: any = contextData?.accountSubdomain || {};
  const subdomainName: string = subdomainMap?.subDomain;
  const logo = finalThemeAdditionalAttributes?.attachment?.logo?.url;
  const accountName = accountData?.name;


  if (defaultThemeConfig?.accountConfigurations && defaultThemeConfig?.accountConfigurations?.length) {
    configJson = JSON.parse(defaultThemeConfig?.accountConfigurations[0]?.value);
    formThemeConfig = configJson?.form || undefined;
  } else if (defaultThemeConfig?.defaultValue){
    configJson = JSON.parse(defaultThemeConfig?.defaultValue);
    formThemeConfig = configJson?.form || undefined;
  }

  const renderDefaultThemeHeader = (): JSX.Element => {
    return (
      <View backgroundColor={formThemeConfig?.topBar?.backgroundColor}>
        <View marginY={2}>
          <BrandingLogoWrapper
            accountNameTextColor={formThemeConfig?.accountName?.color}
            accountLogoPosition={formThemeConfig?.accountLogo?.position}
            accountNamePosition={formThemeConfig?.accountName?.position}
            isFromPrint={true}
          />
        </View>
      </View>
    );
  }

  const setHeaderAndFooter = async () => {
    const headerResponse = await getDefaultComponent({type: 'HEADER'});
    let headerElem = headerResponse?.data?.length > 0 ? headerResponse?.data[0]?.attributes?.bodyHtml : undefined;
    if (!headerElem && formThemeConfig?.accountName) {
      headerElem = renderDefaultThemeHeader();
    } else {
      if (headerElem && headerElem.includes('{{global')) {
        headerElem = headerElem.replace('{{global.accountLogo}}', logo);
        headerElem = headerElem.replace('{{global.accountName}}', accountName);
        headerElem = headerElem.replace('{{global.clinicName}}', accountName);
        headerElem = headerElem.replace('{{global.accountAdminUrl}}', getAdminAppUrl(subdomainName));
        headerElem = headerElem.replace('{{global.accountPatientAppUrl}}', getPatientAppUrl(subdomainName));
      }
    }

    const footerElem = renderPatientDemographicsFooter();
    setHeaderFooter((prev) => {
      return {
        ...prev,
        header: headerElem,
        footer: footerElem,
      }
    });
  };

  function getDocumentTitle() {
    if (props.title) {
      return props.title;
    }

    const personName = personData.name;
    // const personEmail = personData?.email;
    // const personPhone = personData?.phone ? numericStringMask(personData.phone, PHONE_NUMBER_MASK) : '';
    const birthDate = personData?.birthDate ? getDateStrFromFormat(personData?.birthDate, DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT) : '';

    let title: string = personName;

    if (birthDate) {
      title += ` • DOB: ${birthDate}`;
    }
    if (personData.age) {
      title += ` • Age: ${personData.age} yrs`;
    }
    // if (personEmail) {
    //   title += ` • ${personEmail}`;
    // }
    // if (personPhone) {
    //   title += ` • ${personPhone}`;
    // }
    return title;
  }

  const renderPatientDemographics = () => {
    return (
      <VStack>
        <Divider my={2} backgroundColor={'black'} />
        <Text fontWeight={600} size={'lgMedium'}>
          {personData.name}
        </Text>
        <HStack flex={1}>
          <VStack flex={5} alignItems="flex-start">
            {personData?.phone && (
              <Text fontSize={16}>{`${intl.formatMessage({id: 'phone'})} : ${
                personData?.phone &&
                numericStringMask(personData?.phone || '', PHONE_NUMBER_MASK)
              }`}</Text>
            )}
            {personData?.email && (
              <Text fontSize={16}>{`${intl.formatMessage({id: 'email'})} : ${
                personData?.email
              }`}</Text>
            )}
          </VStack>
          {/* <VStack flex={5} alignItems="flex-end">
            <Text fontSize={16}>{`${personData?.line1}${
              personData?.line2 && ','
            }`}</Text>
            <Text fontSize={16}>{`${personData?.line2}`}</Text>
          </VStack> */}
        </HStack>
        <Divider my={2} backgroundColor={'black'} />
      </VStack>
    );
  };

  const renderPatientDemographicsFooter = () => {
    return (
      <div className="page-break">
        <Divider backgroundColor={"black"} my={2}></Divider>
        <HStack space={2} width={'full'}>
          <Text fontSize={18} fontWeight={700} flex={1}>
            {personData?.name}
          </Text>
          {personData?.birthDate && (
            <Text
              flex={1}
              fontSize={18}
              fontWeight={700}
            >{`DOB : ${getDateStrFromFormat(personData?.birthDate, DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT)}`}</Text>
          )}
          {personData?.age && (
            <Text
              flex={1}
              fontSize={18}
              fontWeight={700}
            >{`Age : ${personData?.age} yrs`}</Text>
          )}
        </HStack>
        <Divider backgroundColor={"black"} my={2}></Divider>
      </div>
    );
  };

  const printDocument = () => {
    if (printRef?.current && printRef?.current?.handleClick) {
      printRef.current.handleClick();
    }
  }

  function previewContent() {
    return (
      <VStack>
        <ReactToPrint
          documentTitle={getDocumentTitle()}
          trigger={() => <></>}
          content={() => (componentRef.current as any)}
          ref={printRef}
          print={(target) => {
            return new Promise<any>((resolve, reject) => {
              if (target.contentDocument) {
                target.contentDocument.title = getDocumentTitle();
              }
              target?.contentWindow?.print?.();
              resolve(true);
            });
          }}

          onAfterPrint={() => {
            props.onClose?.();
          }}
          removeAfterPrint={true}
        />
        <div>
          <ComponentToPrint
            ref={componentRef}
            // header={printElement.header}
            headerComponents={[
              printElement.header,
              props.enablePatientDemographics ? renderPatientDemographics() : <></>
            ]}
            footer={printElement.footer}
          >
            <VStack>

              {props.children}
            </VStack>
          </ComponentToPrint>
        </div>

      </VStack>
    );
  }

  useEffect(() => {
    setHeaderAndFooter();
  }, []);

  useEffect(() => {
    if (props.triggerPrint) {
      printDocument();
    }
  }, [props.triggerPrint]);

  return (
    <>
      {
        props.showPreviewInDrawer &&
        <Drawer
          open={props.isOpen || false}
          width={700}
          onClose={props.onClose}
          closable={false}
          title={
            <>
              <ModalActionTitle
                title={'Print Preview'}
                titleColor={''}
                isHeadNotSticky
                buttonList={[
                  {
                    show: true,
                    id: 1,
                    btnText: 'close',
                    textColor: Colors.Custom.mainSecondaryBrown,
                    variant: BUTTON_TYPE.SECONDARY,
                    isTransBtn: false,
                    onClick: () => {
                      props.onClose?.();
                    },
                  },
                  {
                    show: true,
                    id: 1,
                    btnText: 'print',
                    isLoading: props.isLoading || false,
                    textColor: Colors.Custom.mainPrimaryPurple,
                    variant: BUTTON_TYPE.PRIMARY,
                    isTransBtn: false,
                    onClick: () => {
                      printDocument();
                    },
                  }
                ]}
              />
            </>
          }
        >
          {previewContent()}
        </Drawer>
      }
      {
        !props.showPreviewInDrawer &&
        previewContent()
      }
    </>

  );
}
