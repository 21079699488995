import {useApolloClient, useLazyQuery} from '@apollo/client';
import {useEffect, useMemo, useState} from 'react';
import {getAccountUUID} from '../../utils/commonUtils';
import {UserQueries} from '../../services';
import {IUserRole} from '../PersonOmniView/RightContainer/CareTeamView/interfaces';
import {IUserPracticeLocation} from '../../services/Location/interfaces';
import {IUser} from '../../Interfaces';

export interface Args {
  onError?: () => void;
  doNotFetchOnMount?: boolean;
  usersQueryName?: any;
  usersCountQueryName?: any;
  useMap?: boolean;
}

export interface IBatchedAccountUsersInterface {
  id: string;
  name: string;
  uuid: string;
  email: string;
  externalUserId?: string;
  accountUsers?: IAccountUsers[];
  userRoles?: IUserRole[];
  userPracticeLocations?: IUserPracticeLocation[];
}

export interface IAccountUsers {
  employerId: string;
  externalUserId: string;
  isActive: boolean;
}

const useGetBatchedAccountUsers = (args: Args = {}) => {
  // Temporary using this as useLazyQuery is not working with Promise.all.
  // It's fixed in latest apollo client version but we have added fix version in package.json
  const client = useApolloClient();
  const {onError, doNotFetchOnMount, usersQueryName, usersCountQueryName} =
    args;
  const accountUuid = getAccountUUID();
  const [userList, setUserList] = useState<IBatchedAccountUsersInterface[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [getUsersQuery] = useLazyQuery(
    usersQueryName || UserQueries.GET_USERS_FOR_CALENDAR,
    {
      fetchPolicy: 'no-cache',
      variables: {
        accountId: accountUuid,
      },
    }
  );
  const [getAccountUsersCountQuery] = useLazyQuery(
    usersCountQueryName || UserQueries.GET_ACCOUNT_USER_COUNT,
    {
      fetchPolicy: 'no-cache',
      variables: {
        accountId: accountUuid,
      },
    }
  );

  const fetchAccountUserCount = async () => {
    const {data} = await getAccountUsersCountQuery();
    return data?.userAggregate?.aggregate?.count || 0;
  };

  const getOffsetArray = (number: number) => {
    const array = new Array(Math.floor(number / 100))
      .fill(100)
      .concat(number % 100);
    return array;
  };

  const fetchUsersByChunk = async (count: number) => {
    let offset = 0;
    const limit = 100;
    const chunkArray = getOffsetArray(count);
    const promiseList: Promise<any>[] = [];
    chunkArray.forEach((i) => {
      promiseList.push(
        client.query({
          query: usersQueryName || UserQueries.GET_USERS_FOR_CALENDAR,
          variables: {
            accountId: accountUuid,
            limit: limit,
            offset: offset,
          },
          fetchPolicy: 'no-cache',
        })
      );
      offset = offset + limit;
    });
    try {
      const responseList = await Promise.all(promiseList);
      const newUserList = responseList.flatMap(
        (response) => response?.data?.users || []
      );
      if (doNotFetchOnMount) {
        return newUserList;
      }
      setUserList(newUserList);

      setLoading(false);
      setError(false);
    } catch {
      setLoading(false);
      setError(true);
      onError?.();
      if (doNotFetchOnMount) {
        return [];
      }
    }
  };

  const getData = async () => {
    setLoading(true);
    setError(false);
    const accountUsersCount = await fetchAccountUserCount();
    if (accountUsersCount === 0) {
      setLoading(false);
      setError(true);
      onError?.();
      if (doNotFetchOnMount) {
        return [];
      }
      return;
    }
    if (accountUsersCount < 100) {
      const {data} = await getUsersQuery();
      setUserList(data?.users || []);
      setLoading(false);
      setError(false);
      if (doNotFetchOnMount) {
        return data?.users || [];
      }
    } else {
      if (doNotFetchOnMount) {
        const userList = fetchUsersByChunk(accountUsersCount);
        return userList;
      }
      await fetchUsersByChunk(accountUsersCount);
    }
  };

  useEffect(() => {
    if (!doNotFetchOnMount) {
      getData();
    }
  }, []);

  const userMap = useMemo(() => userList.reduce(
    (
      acc: {
        [key: string]: IUser;
      },
      user: IUser
    ) => {
      acc[user.uuid] = user;
      return acc;
    },
    {}
  ), [userList.length])

  return {
    loading,
    error,
    userList,
    getData,
    userMap: userMap
  };
};

export default useGetBatchedAccountUsers;
