import {Pressable, Text, View} from 'native-base';
import React, {useEffect, useState} from 'react';
import { useIntl } from 'react-intl';
import {COMMON_ACTION_CODES} from '../../../constants/ActionConst';
import {Colors} from '../../../styles';
import { TASK_TABS } from '../CareDashboard/CareDashboardConstants';

interface ISortByTaskSortType {
  sortType: string;
  sortTypeUuid: string;
  id: number;
  label: string;
}

const SortByTaskSortType = (props: any) => {
  const intl = useIntl();
  const {returnFilterItems, selectedSortType, selectedSortValueAscending, selectedTab} =
    props;
  const [sortTypeState, setSortTypeState] = useState({
    sortTypeList: [] as ISortByTaskSortType[],
    selectedSortType: selectedSortType ? selectedSortType : 'None',
  });

  const onHandleSortTypeSelected = (sortType: ISortByTaskSortType) => {
    setSortTypeState((prev) => {
      return {
        ...prev,
        selectedSortType: sortType.sortType,
      };
    });
    returnFilterItems(COMMON_ACTION_CODES.SORT_CHANGED, sortType);
  };

  useEffect(() => {
    const initialSortType = [
      {
        id: 1,
        sortType: 'None',
        sortTypeUuid: 'none',
        label: 'None',
      },
      {
        id: 2,
        sortType: 'title',
        sortTypeUuid: 'title',
        label: 'Title',
      },
      // {
      //   id: 3,
      //   sortType: 'patient',
      //   sortTypeUuid: 'patient',
      //   label: intl.formatMessage({id: 'patientName'}),
      // },
      {
        id: 3,
        sortType: 'endDateTime',
        sortTypeUuid: 'endDateTime',
        label: intl.formatMessage({id: 'DueDate'}),
      },
      // {
      //   id: 5,
      //   sortType: 'createdOn',
      //   sortTypeUuid: 'createdOn',
      //   label: 'Created Date',
      // },
      // {
      //   id: 4,
      //   sortType: 'Priority',
      //   sortTypeUuid: 'priority',
      //   label: 'Priority',
      // },
    ];
    if(selectedTab === TASK_TABS.MENTIONED){
      initialSortType.push(
        {
          id: 4,
          sortType: 'mentionedDateTime',
          sortTypeUuid: 'mentionedDateTime',
          label: intl.formatMessage({ id: 'mentionedDateTime' }),
        },
      )
    }
    setSortTypeState((prev) => {
      return {
        ...prev,
        sortTypeList: initialSortType,
        selectedSortType: selectedSortType ? selectedSortType : 'None',
      };
    });
  }, [selectedTab]);

  return (
    <>
      {sortTypeState.sortTypeList.length && (
        <View style={{minWidth: 150}}>
          {sortTypeState.sortTypeList.map(
            (sortType: ISortByTaskSortType, index: number) => {
              return (
                <Pressable
                  key={index}
                  _hover={{bg: Colors.primary['100'] + '7a'}}
                  onPress={() => {
                    onHandleSortTypeSelected(sortType);
                  }}
                  style={{
                    height: 40,
                    justifyContent: 'center',
                    paddingHorizontal: 4,
                  }}
                  backgroundColor={
                    selectedSortType && selectedSortType === sortType.sortType
                      ? Colors.primary['100'] + '7a'
                      : sortTypeState.selectedSortType === sortType.sortType
                      ? Colors.primary['100'] + '7a'
                      : ''
                  }
                >
                  <Text size={'smMedium'} color={Colors.Custom.Gray500}>
                    {sortType.label}{' '}
                    {((selectedSortType &&
                      selectedSortType === sortType.sortType) ||
                      sortTypeState.selectedSortType === sortType.sortType) &&
                    sortType.sortType !== 'None'
                      ? selectedSortValueAscending
                        ? '↑'
                        : '↓'
                      : ''}
                  </Text>
                </Pressable>
              );
            }
          )}
        </View>
      )}
    </>
  );
};
export default SortByTaskSortType;
