import {Dimensions, StyleSheet} from 'react-native';
import {Colors} from '../../../../../styles';
const {height} = Dimensions.get('window');

export const styles = StyleSheet.create({
  mainContainer: {
    height: height - 80,
  },
  titleContainer: {
    flex: 0.1,
    justifyContent: 'center',
    backgroundColor: '#fff',
  },
  container: {
    flex: 0.9,
  },
  topBarContainer: {
    alignItems: 'center',
    flex: 1,
    backgroundColor: '#fff',
  },
  editorContainer: {
    flex: 1,
    borderRightColor: Colors.Custom.BorderColor,
    borderRightWidth: 1,
  },
  previewContainer: {
    flex: 1,
  },
  uploadViewContainer: {
    flex: 0.9,
    alignItems: 'center',
    justifyContent: 'center',
  },
  userInputContainer: {
    marginLeft: 8,
    marginRight: 8,
  },
  formControlStyle: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },

  // sub-components
  editorView: {
    padding: 16,
    flex: 1,
    overflow: 'scroll',
  },
});
