import React, { useMemo} from 'react';
import {useQuery} from '@apollo/client';
import {GET_CARE_PLAN_BY_ID} from '../../../../../services/CarePlan/CarePlanQueries';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../constants/Configs';
import {Icon, Pressable, Skeleton, Stack, Text, View} from 'native-base';
import {isWeb} from '../../../../../utils/platformCheckUtils';
import {DisplayText} from '../../../DisplayText/DisplayText';
import {Colors} from '../../../../../styles';
import FeatherIcon from 'react-native-vector-icons/Feather';
import {useIntl} from 'react-intl';
import useCarePlanStatus from '../../../../PersonOmniView/MiddleContainer/CarePlan/useCarePlanStatus';
import { StyleSheet } from 'react-native';

interface IPatientLinkCarePlanCard {
  linkedCarePlanId: string | undefined;
  onCarePlanLinkClick: () => void;
  assignByUserName: string | undefined;
}

const PatientLinkCarePlanCard = (props: IPatientLinkCarePlanCard) => {
  const intl = useIntl();
  const {linkedCarePlanId, onCarePlanLinkClick} = props;
  const {activeCarePlanStatusId, draftCarePlanStatusId, inReviewCarePlanStatusId} = useCarePlanStatus();

  const {data, loading} = useQuery(GET_CARE_PLAN_BY_ID, {
    fetchPolicy: 'no-cache',
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    variables: {
      id: linkedCarePlanId,
    },
  });

  const carePlan = data?.contactCarePlans?.[0];

  const carePlanStatusDisplayTextMap = useMemo(
    () => ({
      [activeCarePlanStatusId]: {
        text: 'active',
        color: Colors.Custom.Gray500,
      },
      [draftCarePlanStatusId]: {
        text: 'draft',
        color: Colors.Custom.Gray500,
      },
      [inReviewCarePlanStatusId]: {
        text: 'inReview',
        color: Colors.Custom.orangeMessageTopBar,
      },
    }),
    [
      activeCarePlanStatusId,
      draftCarePlanStatusId,
      inReviewCarePlanStatusId,
    ]
  );

  const currentStatus = carePlanStatusDisplayTextMap[carePlan?.statusId];
  const currentStatusDisplayText = currentStatus?.text ? intl.formatMessage({id: currentStatus?.text}) : '';


  const renderVisitNoteLinkElement = (): JSX.Element => {
    return (
      <Pressable onPress={onCarePlanLinkClick}>
        <Stack direction={'row'} alignItems="center">
          <Text fontSize="16" color={Colors.Custom.Primary300}>
            View
          </Text>
          <Icon
            as={FeatherIcon}
            name="arrow-up-right"
            size="5"
            color={Colors.Custom.Primary300}
          />
        </Stack>
      </Pressable>
    );
  };

  return (
    <View>
      <Stack
        direction={'row'}
        key={`patient_link_${linkedCarePlanId}`}
        marginTop={isWeb() ? 0 : 6}
      >
        <DisplayText
          size={'smMedium'}
          extraStyles={{
            color: Colors.Custom.Gray500,
            fontWeight: '500',
            fontSize: 14,
          }}
          textLocalId={'linkedCarePlan'}
        />
      </Stack>
      <Stack
        direction={'column'}
        key={linkedCarePlanId}
        style={styles.carePlanContainer}
        my={1}
      >
        {loading ? (
          <Skeleton.Text />
        ) : (
          <>
            <Stack direction={'row'} flex={1}>
              <Stack direction={'row'} flex={9} alignItems="center">
                <Stack direction={'column'} flex={1}>
                  <Text color={Colors.Custom.Gray900}>
                    {intl.formatMessage({id: 'carePlan'})} •
                    <Text color={currentStatus?.color}>
                      {currentStatusDisplayText}
                    </Text>
                  </Text>
                  {props?.assignByUserName && (
                    <Text color={Colors.Custom.Gray500}
                    fontSize={12}>{`Assigned by: ${props.assignByUserName}`}</Text>
                  )}
                </Stack>
              </Stack>
              <Stack direction={'row'} alignItems="center" space={2}>
                {isWeb() && renderVisitNoteLinkElement()}
              </Stack>
            </Stack>
          </>
        )}
      </Stack>
    </View>
  );
};

const styles = StyleSheet.create({
  carePlanContainer: {
    backgroundColor: '#F9FAFB',
    borderRadius: 8,
    borderColor: '#F3F4F6',
    borderWidth: 0.5,
    padding: 12,
    flex: 1,
  }
});

export default PatientLinkCarePlanCard;
