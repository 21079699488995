// Get the DateTime from the components listing.
import {Formio} from '@foldhealth/formiojs';
import BasicFields from '../../EditFormFields/BasicFields';
import ConditionalFields from '../../EditFormFields/ConditionalFields';
import KeyField from '../../EditFormFields/KeyField';

const DateTimeComponent = Formio.Components.components.datetime;
const FORM_DATE_FORMAT = 'MM-dd-YY';

export default class DateComponent extends DateTimeComponent {
  static schema(...extend: any) {
    return DateTimeComponent.schema(
      {
        type: 'date',
        label: 'Date',
        key: 'date',
        format: FORM_DATE_FORMAT,
        enableTime: false,
      },
      ...extend
    );
  }

  static get builderInfo() {
    return {
      title: 'Date',
      group: 'advanced',
      icon: 'calendar',
      weight: 2,
      documentation: '/userguide/#datetime',
      schema: DateComponent.schema(),
    };
  }
}

DateComponent.editForm = function () {
  return {
    key: 'display',
    components: [
      ...BasicFields,
      {
        type: 'oldtextfield',
        input: true,
        key: 'format',
        label: 'Format',
        placeholder: 'Format',
        defaultValue: FORM_DATE_FORMAT,
        // description: `Use formats provided by ${FORM_DATE_FORMAT}`,
        tooltip: 'The format for displaying the date.',
      },
      {
        type: 'datetime',
        input: true,
        enableTime: false,
        key: 'datePicker.minDate',
        skipMerge: true,
        label: 'Minimum Date',
        format: FORM_DATE_FORMAT,
        tooltip: 'The minimum date that can be picked.',
      },
      {
        type: 'datetime',
        input: true,
        enableTime: false,
        key: 'datePicker.maxDate',
        skipMerge: true,
        label: 'Maximum Date',
        format: FORM_DATE_FORMAT,
        tooltip: 'The maximum date that can be picked.',
      },
      {
        type: 'checkbox',
        label: 'Hide Label',
        tooltip:
          'Hide the label or title of this component. This allows you to show the label in the form builder, but not when the form is shown to the member.',
        key: 'hideLabel',
        input: true,
      },
      ...ConditionalFields,
      ...KeyField,
    ],
  };
};

Formio.Components.addComponent('date__Deprecated', DateComponent);
