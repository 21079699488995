import React, { useEffect, useState } from 'react'
import { ITask } from '../../CareDashboard/CareDashboardInterfaces'
import { useLazyQuery } from '@apollo/client';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../constants/Configs';
import { TASK_SUBJECT_TYPE } from '../../../../constants/MlovConst';
import { GET_PATIENT_CASE, GET_PATIENT_REFERRAL } from '../../../../services/Task/TaskQueries';

const useTaskAdditionalDetail = (task: ITask, accountUsers: any[], subjectTypeCode: string): [any, boolean] => {
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(true);

  const [getPatientCase] = useLazyQuery(GET_PATIENT_CASE, {
    fetchPolicy: 'no-cache',
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
  });
  const [getPatientReferral] = useLazyQuery(GET_PATIENT_REFERRAL, {
    fetchPolicy: 'no-cache',
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
  });

  const fetchData = async () => {
    try {
      switch(subjectTypeCode) {
        case TASK_SUBJECT_TYPE.ORDER:
          const orderData = task?.orders?.[0];
          setData(orderData);
          setLoading(false);
          break;
        case TASK_SUBJECT_TYPE.PATIENT_CASE:
          const patientCaseResponse = await getPatientCase({
            variables: {
              subjectId: task?.subjectId
            }
          });
          const patientCaseData = patientCaseResponse?.data?.contactCases?.[0];
          if(patientCaseData) {
            patientCaseData.provider = accountUsers?.filter(user => user?.id === patientCaseData?.userId)?.[0]?.name
          }
          setData(patientCaseData)
          setLoading(false);
          break;
        case TASK_SUBJECT_TYPE.PATIENT_REFERRAL:
          const patientReferralResponse = await getPatientReferral({
            variables: {
              subjectId: task?.subjectId
            }
          });
          const patientReferralData = patientReferralResponse?.data?.contactReferrals?.[0];
          if(patientReferralData) {
            patientReferralData.orderingProvider = accountUsers?.filter(user => user?.id === +patientReferralData?.userId)?.[0]?.name
          }
          setData(patientReferralData)
          setLoading(false);
          break;
      }
    } catch (error) {
      
    }
  };

  useEffect(() => {
    fetchData();
    return () => {
      // cancel api req if comp unmounted
    };
  }, [task?.id]);

  return [data, loading];
}

export default useTaskAdditionalDetail