import {Box, HStack, ScrollView, Text, Button, Spacer, Pressable, Icon, IconButton, Divider, VStack} from 'native-base';
import {IGroupField, IRiskScoreFieldProps, ISingleField} from './RiskScoreInterface';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {StyleSheet} from 'react-native';
import { FIELD_OPERATION, getFieldIconInfo } from './RiskScoreHelper';
import CircleIconView, { NodeCategory } from '../../../../common/CircleIconView/CircleIconView';
import { Colors } from '../../../../../styles/Colors';
import EmptyScoreIcon from './EmptyScore';
import React from 'react';

const RiskScoreFieldView = (props: IRiskScoreFieldProps) => {
  const {groupFields, singleFields } = props;

  const getField = (singleField: ISingleField, groupField?: IGroupField | undefined): JSX.Element => {
    const key = `${groupField?.groupId}/${singleField?.component?.componentId || singleField?.fieldId}`;
    const isAddActionDisabled = singleField.isGroupField ? (singleField.isAddedAsGroupField && singleField.isAddedAsSingleField) : singleField.isAddedAsSingleField;

    return (
      <Box
        key={key}
        style={styles.singleItemBox}
        marginTop={singleField.isGroupField ? 2 : 1}
        padding={singleField.isGroupField ? 2 : 2}
        marginLeft={singleField.isGroupField ? 2 : 0}
        marginRight={singleField.isGroupField ? 2 : 0}
        margin={singleField.isGroupField ? 0 : 2}
      >
        <HStack flex={1} space={1} justifyContent={'flex-start'}>
          <CircleIconView
            iconInfo={getFieldIconInfo(singleField)}
            nodeCategory={NodeCategory.measure}
            backgroundColor={Colors.Custom.ContainerBGColor}
            borderColor={Colors.Custom.FontColorWhite}
            iconColor={Colors.Custom.Gray300}
          />
          { !isAddActionDisabled &&
          <Text fontWeight={500} fontSize={'14'} marginTop={1}>
            {singleField?.fieldName}
          </Text>
          }
          { isAddActionDisabled &&
          <Text fontWeight={500} fontSize={'14'} marginTop={1} color={Colors.Custom.Gray300}>
            {singleField?.fieldName}
          </Text>
          }

          <Spacer/>
          { !isAddActionDisabled &&
          <HStack width={6} justifyContent={'flex-end'}>
            <IconButton
              onPress={() => {
                props.onChange(singleField, singleField?.isGroupField ? FIELD_OPERATION.GROUP_FIELD_ADD : FIELD_OPERATION.SINGLE_FIELD_ADD, groupField);
              }}
              variant={'unstyled'}
              icon={<Icon
                as={AntIcon}
                name="plus"
                size="5"
                color={Colors.Custom.Gray500}
              />}
            />
          </HStack>
          }
        </HStack>
      </Box>
    );
  };

  return (
  <>
    {groupFields?.length === 0 && singleFields?.length == 0 && (
        <VStack height={"100%"} justifyContent={"center"} alignItems={"center"}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <EmptyScoreIcon />
          <Text
            marginTop={4}
            fontWeight={800}
            fontSize={"18"}
            width={121}
            height={30}
          >
             Score  Fields
          </Text>
          <Text
            textAlign="center"
            fontWeight={400}
            fontSize={"14"}
            width={300}
            height={40}
            color={Colors.Custom.Gray500}
          >
            Non scoring fields have been selected in the form
          </Text>
        </Box>
      </VStack>
    )}
    <ScrollView>
      {props?.groupFields?.length > 0 &&
        (props?.groupFields || []).map((groupField, index) => {
          return (
            <Box
              key={groupField.groupId}
              margin={2}
              paddingY={2}
              flexDirection={'column'}
              background={'#FFFFFF'}
              alignSelf={'stretch'}
              borderWidth={1}
              borderColor={Colors.Custom.Gray200}
              borderRadius={8}>
              <HStack width={'full'} space={1} paddingBottom="8px" flex={1}>
                <HStack justifyContent={'flex-start'} flex={0.69}>
                  <Text fontWeight={600} fontSize={'14'} marginLeft={2} paddingLeft={1}>
                    {groupField.groupFieldName}
                  </Text>
                </HStack>
                <HStack width={6} justifyContent={'flex-end'} flex={0.29}>

                <Pressable
                    onPress={() => {
                      props.onChange(undefined,  FIELD_OPERATION.GROUP_ADDED, groupField);
                    }}
                  >
                    <HStack alignSelf={'flex-end'} alignItems={'center'} paddingRight={1}>
                      {
                        <AntIcon
                          name="plus"
                          color={Colors.Custom.mainPrimaryPurple}
                          size={20}
                        />
                      }
                      <Text ml={2} fontWeight={500} color={Colors.Custom.mainPrimaryPurple}>
                        {'Section score'}
                      </Text>
                    </HStack>
                  </Pressable>
                </HStack>
              </HStack>
              <Divider></Divider>
              {(groupField?.fields || []).map(singleField => {
                return getField(singleField, groupField);
              })}
            </Box>
          );
        })}

      {props?.singleFields?.length > 0 &&
        (props?.singleFields || []).map(singleField => {
          return getField(singleField);
        }
      )}
    </ScrollView>
  </>
  );
};

const styles = StyleSheet.create({
  singleItemBox: {
    boxSizing: 'border-box',
    display: 'flex',
    padding: 4,
    backgroundColor: '#FFF',
    border: '1px solid #E4E7EC',
    borderRadius: 8,
    flexGrow: 0,
    minHeight: '10px',
  },
});

export default React.memo(RiskScoreFieldView);
