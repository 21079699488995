import React from 'react'
import { VictoryArea, VictoryLabel, VictoryLine } from '../../../../../PersonOmniView/LeftContainer/RecentActivity/RecentReport/VictoryComponent'
import { CustomPlotName, getTooltipContent } from '../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailTables/VitalsGraphView/VitalsGraphUtils'
import { Colors } from '../../../../../../styles'

export interface IGoalLinesViewProps {
  goalLines: any[];
  graphData: any[];
  fontSize: number;
  unit: string;
  isExtended?: boolean;
}

// for web
const GoalLinesView = (props: IGoalLinesViewProps) => {

  const {goalLines, graphData, fontSize, unit, isExtended} = props;
  
  // no goals
	if(!goalLines || !goalLines.length) {
    return <></>
	}

  // between goals
  if (goalLines.length === 2) {
    const lowerGoal = +goalLines.find(e => e?.name === CustomPlotName.LG)?.value || 0
    const upperGoal = +goalLines.find(e => e?.name === CustomPlotName.UG)?.value || 0
    const extendedCoords = [
      {
        x: 0,
        y: upperGoal,
        y0: lowerGoal,
      },
      {
        x: graphData?.length + 1,
        y: upperGoal,
        y0: lowerGoal,
      }
    ]
    return (
      [
        <VictoryArea
          key={'area'}
          data={[
            ...graphData?.map((singledataPoint: any) => {
              return {
                x: singledataPoint?.x,
                y: upperGoal,
                y0: lowerGoal,
              }
            }),
            ...(isExtended ? (extendedCoords) : []),
          ]}
          style={{ data: { fill: Colors.Custom.goalAreaColor, strokeWidth: 1, strokeDasharray: 1,  } }}
        />,
        <VictoryLine
          key={'line-1'}
          name={getTooltipContent(goalLines?.[0]?.operator, [upperGoal, lowerGoal], unit)}
          style={{
            data: {
              stroke: Colors.Custom.goalAreaBorderColor,
              strokeWidth: 0.5,
              strokeDasharray: 3,
              strokeLinecap: 'round'
            },
            labels: {fontSize: fontSize},
          }}
          y={() => upperGoal}
        />,
        <VictoryLine
          key={'line-2'}
          name={getTooltipContent(goalLines?.[0]?.operator, [upperGoal, lowerGoal], unit)}
          style={{
            data: {
              stroke: Colors.Custom.goalAreaBorderColor,
              strokeWidth: 0.5,
              strokeDasharray: 3,
              strokeLinecap: 'round'
            },
            labels: {fontSize: fontSize},
          }}
          y={() => lowerGoal}
        />
      ]
    )
  }

  // gradientArea for [lt, lte, gt, gte] goals
  // if(goalLines?.[0]?.operator === GoalOperator.lt) {
  //   const extendedCoords = [
  //     {
  //       x: 0,
  //       y: goalLines?.[0]?.value,
  //       y0: 0,
  //     },
  //     {
  //       x: graphData?.length + 1,
  //       y: goalLines?.[0]?.value,
  //       y0: 0,
  //     }
  //   ]
  //   return (
  //     [
  //       // <GoalAreaGradientSvg />,
  //       <VictoryLine
  //         key={goalLines?.[0]?.name}
  //         name={getTooltipContent(goalLines?.[0]?.operator, goalLines?.[0]?.value, unit)}
  //         style={ {
  //           data: {
  //             stroke: Colors.Custom.goalLineColor,
  //             strokeWidth: 1,
  //             strokeDasharray: 1,
  //           },
  //           labels: {fontSize: fontSize},
  //         }}
  //         labelComponent={<VictoryLabel x={100} />}
  //         y={() => goalLines?.[0]?.value as number}
  //       />,
  //       <VictoryArea
  //         key={'area'}
  //         data={[
  //           ...graphData?.map((singledataPoint: any) => {
  //             return {
  //               x: singledataPoint?.x,
  //               y: (+goalLines?.[0]?.value),
  //               y0: 0,
  //             }
  //           }),
  //           ...(isExtended ? (extendedCoords) : []),
  //         ]}
  //         style={ { data: { fill: Colors.Custom.goalAreaColor, strokeWidth: 1, strokeDasharray: 1,  } }}
  //       />
  //     ]
  //   )
  // }

  // single goal
  
  return (
    <VictoryLine
      key={goalLines?.[0]?.name}
      name={getTooltipContent(goalLines?.[0]?.operator, goalLines?.[0]?.value, unit)}
      style={{
        data: {
          stroke: Colors.Custom.goalLineColor,
          strokeWidth: 0.5,
          strokeDasharray: 3,
          strokeLinecap: 'round'
        },
        labels: {fontSize: fontSize},
      }}
      labelComponent={<VictoryLabel x={100} />}
      y={() => goalLines?.[0]?.value as number}
    />
  )
}

export default GoalLinesView