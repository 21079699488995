import { useLazyQuery } from '@apollo/client';
import {
  HStack,
  View
} from 'native-base';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { GROUP_MEMBER_TYPE } from '../../../../../constants';
import { COMMON_ACTION_CODES, CONVERSATION_ACTION_CODES } from '../../../../../constants/ActionConst';
import { CONVERSATION_LOCAL_EVENT_CODES } from '../../../../../constants/WebSocketConst';
import ConversationsQueriesV2 from '../../../../../services/Conversations/V2/ConversationsQueriesV2';
import { IGroupMember, IGroupUser } from '../../../../../services/Conversations/interfaces';
import { IInboxMember } from '../../../../../services/Inbox/interfaces';
import { Colors } from '../../../../../styles';
import { localBroadcastEvent } from '../../../../../utils/CustomEventHandler';
import {
  getUserName,
  getUserUUID
} from '../../../../../utils/commonUtils';
import { ToastType, showToast } from '../../../../../utils/commonViewUtils';
import { useToast as useCustomToast } from '../../../../Toast/ToastProvider';
import { DisplayText } from '../../../../common/DisplayText/DisplayText';
import { SearchBar } from '../../../../common/SearchBar';
import { unAssignConversationAPI } from '../ConversationChannelNew/ConversationAPIService';
import { getAccountUserByUserId, getGroupMembersListForAssignee } from '../ConversationChannelNew/ConversationSidebarUtils';
import { IConversationActionView } from '../MessagingWindow/interface';
import { handleErrorMsgForAssign } from '../conversationUtils';
import './ConversationActionView.module.css';
import UserListView from './UserListView';
import { StyleSheet } from 'react-native';
import {
  CaptureTransaction,
  TRANSACTION_NAMES,
} from '../../../../../utils/CaptureTransaction';

const ConversationActionView = (props: IConversationActionView) => {
  if (!props.conversation) {
    return null;
  }
  const customToast = useCustomToast();
  const intl = useIntl();
  const conversationId = props.conversation.id;
  const myUserUUID = getUserUUID();

  const groupMembers = props.conversation?.groupConversation?.groupMembers || [];
  const inboxMembers = props?.conversation?.conversationInbox?.inboxMembers || [];
  const userName = getUserName();
  const [users, setUsers] = useState([] as IGroupUser[]);
  const [loadingStates, setLoadingStates] = useState(false);
  const captureTransactionInst = CaptureTransaction.getInstance();

  const [stateData, setStateData] = useState({
    selectionLoading: false
  })

  const onUserListViewActionPerformed = async (actionCode: string, rowData?: any) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.ASSIGN_USER:
        if (prevAssigneeUUID === rowData.id) {
          return
        }
        assignUsers(rowData.uuid, rowData?.name);
        break;
      case COMMON_ACTION_CODES.UNASSIGN:
        setStateData(prev => {
          return {
            ...prev,
            selectionLoading: true
          }
        })
        unAssignConversation()
        break;
    }
  };

  const unAssignConversation = async ()=> {
    const body = {
      conversationUuid: props.conversation?.uuid || '',
    };

    captureTransactionInst.initiateTransaction({
      name: TRANSACTION_NAMES.CONVERSATION_UNASSIGNED,
      identifier: props.conversation?.id || '',
    });
    captureTransactionInst.finishTransaction(
      TRANSACTION_NAMES.CONVERSATION_UNASSIGNED,
      props.conversation?.id || ''
    );
    const unAssignResponse = await unAssignConversationAPI({
      body,
    }).catch((err: any) => {
      const errorMsg = handleErrorMsgForAssign(err?.response?.data);
      showToast(
        customToast,
        errorMsg?.length ? errorMsg : intl.formatMessage({id: 'apiErrorMsg'}),
        ToastType.error,
        2000,
        true
      );

      setStateData(prev => {
        return {
          ...prev,
          selectionLoading: false
        }
      })
    })
    if (unAssignResponse?.data?.conversationUuid) {
      try {
        localBroadcastEvent(CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_UN_ASSIGNED, {
          conversation: props.conversation,
          assignUser: {},
          messageData: unAssignResponse?.data?.messageData
        });
        setStateData(prev => {
          return {
            ...prev,
            selectionLoading: false
          }
        });
      } catch (e) {
        setStateData(prev => {
          return {
            ...prev,
            selectionLoading: false
          }
        })
      }
      showToast(
        customToast,
        intl.formatMessage({id: 'userUnAssignSuccessfully'}),
        ToastType.success,
        1000,
        true
      );
      props?.onSelectUser?.(null)
    } else {
      setStateData(prev => {
        return {
          ...prev,
          selectionLoading: false
        }
      })
    }
  }
  const [getAllInboxesMembersByInboxId] = useLazyQuery<{
    inboxMembers: IInboxMember[]
  }>(ConversationsQueriesV2.GetInboxMemberByInboxId, {
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
  });


  const [GetGroupMembersByConversationUuid] = useLazyQuery(
    ConversationsQueriesV2.GetGroupMembersByConversationUuid,
    {
      variables: {
        conversationUuid: props?.conversation?.uuid
      },
      fetchPolicy: 'no-cache',
    }
  )

  const getMemberDetails = async (searchString: string) => {
    setUsers([]);
    setLoadingStates(true);
    const inboxId =
      props.conversation?.inboxId || props.conversation?.conversationInbox?.id;
    if (inboxId === -1) {
      const groupMembersData = await GetGroupMembersByConversationUuid();
      const groupMembersList =
        groupMembersData?.data?.groupConversations?.[0]?.groupMembers;
      if (groupMembersList) {
        const finalGroupMembers = getGroupMembersListForAssignee(
          groupMembersList,
          myUserUUID
        );
        setUsers(finalGroupMembers);
        setLoadingStates(false);
        props?.onActionPerformed &&
          props?.onActionPerformed(
            CONVERSATION_ACTION_CODES.CONVERSATION_GROUP_MEMBER,
            groupMembersList
          );
      }
    } else {
      const allInboxMembers = await getAllInboxesMembersByInboxId({
        fetchPolicy: 'no-cache', //FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
        variables: {
          inboxId: inboxId,
        },
      });
      if (allInboxMembers?.data && allInboxMembers.data?.inboxMembers?.length) {
        const privateConversMembers: IGroupUser[] = [];
        allInboxMembers.data?.inboxMembers?.map((userItem: any) => {
          userItem?.user && privateConversMembers.push(userItem?.user || ({} as IGroupUser));
        });
        setUsers(privateConversMembers);
        setLoadingStates(false);
        props?.onActionPerformed &&
          props?.onActionPerformed(
            CONVERSATION_ACTION_CODES.CONVERSATION_INBOX_MEMBER,
            allInboxMembers?.data?.inboxMembers
          );
      } else {
        setUsers([]);
        setLoadingStates(false);
      }
    }
  };
  const selectedUsers =
    props?.assignedUserData?.data?.conversations?.[0]?.assignedUser ||
    props?.assignedUserData ||
    {};
  const prevAssigneeUUID = selectedUsers.uuid || '';

  const assignUsers = async (userId: any, name: string) => {
    localBroadcastEvent(CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_ASSIGNEE_ACTION_BUS, {
      userUuid: userId,
      conversationUuid: props.conversation?.uuid,
      conversation: props.conversation,
    });
    props?.onSelectUser && props?.onSelectUser(userId, 0);
  };


  const getGroupMembersList = () => {
    const finalGroupMembers = getGroupMembersListForAssignee(groupMembers, myUserUUID);
    setUsers(finalGroupMembers);
  }

  const getInboxMembersList = () => {
    const privateConversMembers: IGroupUser[] = [];
    inboxMembers?.map((userItem: any) => {
      privateConversMembers.push(
        userItem?.user || ({} as IGroupUser)
      );
    });
    setUsers(privateConversMembers);
  };

  useEffect(() => {
    if(groupMembers?.length === props?.conversation?.groupConversation?.groupMembers_aggregate?.aggregate?.count){
      getGroupMembersList();
    } else if (inboxMembers?.length === props?.conversation?.conversationInbox?.inboxMembers_aggregate?.aggregate?.count) {
      getInboxMembersList();
    } else {
      getMemberDetails('');
    }
  }, [props.conversation?.groupConversation?.groupMembers, props.conversation?.id]);

  const content = (
    <>
      <UserListView
        conversationId={conversationId}
        onActionPerformed={onUserListViewActionPerformed}
        loadingStates={loadingStates}
        users={users}
        selectedUsers={selectedUsers}
        selectionLoading={stateData.selectionLoading}
      />
    </>
  );

  return (
    <>
      <View flex={1}>
        <HStack flex={1} justifyContent={'space-between'}>
          <DisplayText
            extraStyles={{fontSize: 16, color: Colors.Custom.Gray400, fontWeight: '500'}}
            textLocalId="assignConversation"
          ></DisplayText>

          {(prevAssigneeUUID != myUserUUID) || !prevAssigneeUUID.length ? (
            <div
              className={`pressable assignToMe ${stateData.selectionLoading ? 'disable' : ''}`}
              onClick={() => {
                assignUsers(myUserUUID, userName);
              }}
            >
              <View
                backgroundColor={'white'}
                padding={1}
                px={3}
                borderRadius={8}
                style={styles.assignToMeButton}
              >
                <DisplayText
                  textLocalId="assignToMe"
                  size={'smLight'}
                  extraStyles={{
                    color: Colors.Custom.mainPrimaryPurple || '',
                    marginLeft: 2,
                  }}
                />
              </View>
            </div>
          ) : (
            ''
          )}
        </HStack>

        <View
          flex={1}
          marginBottom={2}
          marginTop={2}
          style={styles.separator}
        />
        <View marginY={2}>
          <SearchBar
            onChange={(value: string) => {
              const searchString = value;
              if (groupMembers?.length) {
                if (searchString.length) {
                  const privateConversMembers: IGroupUser[] = [];
                  groupMembers?.map((item: IGroupMember) => {
                    if (item?.groupUserType?.code === GROUP_MEMBER_TYPE.USER) {
                      if (item?.user?.uuid !== myUserUUID) {
                        const name = item?.user?.name.toLowerCase() || '';
                        const textData = searchString.toLowerCase();
                        if (name.includes(textData)) {
                          if(getAccountUserByUserId(item?.user?.accountUsers,item?.user?.id)?.[0]?.isActive === true) {
                            privateConversMembers.push(item.user as IGroupUser)
                          }
                        }
                      }
                    }
                  })
                  setUsers(privateConversMembers);
                } else {
                  getGroupMembersList();
                }
              } else if (inboxMembers?.length) {
                if (searchString.length) {
                  const privateConversMembers: IGroupUser[] = [];
                  inboxMembers?.map((item: any) => {
                    if (item?.user?.uuid !== myUserUUID) {
                      const name = item?.user?.name.toLowerCase() || '';
                      const textData = searchString.toLowerCase();
                      if (name.includes(textData)) {
                        if (
                          getAccountUserByUserId(
                            item?.user?.accountUsers,
                            item?.user?.id
                          )?.[0]?.isActive === true
                        ) {
                          privateConversMembers.push(item.user);
                        }
                      }
                    }
                  });
                  setUsers(privateConversMembers);
                } else {
                  getInboxMembersList();
                }
              } else {
                getMemberDetails(searchString);
              }
            }}
          />
        </View>
        <View
          flex={1}
          marginBottom={2}
          marginTop={2}
          style={styles.separator}
        />
        {content}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  assignToMeButton: {
    borderWidth: 1,
    borderColor: Colors.Custom.mainPrimaryPurple,
    height: 28,
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  assignToMeText: {
    color: Colors.Custom.mainPrimaryPurple || '',
    marginLeft: 2,
  },
  separator: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.Custom.Gray300,
  },
});

export default ConversationActionView;
