import {Collapse} from 'antd';
import {IconButton, Tooltip, View} from 'native-base';
import React from 'react';
import {ICommonCollapseProps} from '../..';
import {Colors} from '../../../../../../styles';
import {DisplayText} from '../../../../../common/DisplayText/DisplayText';
import './Collapse.css';
import {styles} from './CommonCollapseStyles';

const {Panel} = Collapse;

const CommonCollapse = (props: ICommonCollapseProps) => {
  const {children, textLocalId, btnList, style, isDefaultClose, header} = props;

  return (
    <>
      <Collapse
        defaultActiveKey={
          isDefaultClose ? '' : [textLocalId.split(' ').join('_')]
        }
        expandIconPosition={'end'}
        style={style ? style : {marginBottom: 6, overflow: 'hidden'}}
      >
        <Panel
          showArrow={props.showArrow === undefined ? true : props.showArrow}
          header={
            header ? (
              header
            ) : (
              <DisplayText
                size={'lgMedium'}
                extraStyles={{color: Colors.primary['700'] || ''}}
                textLocalId={textLocalId}
              />
            )
          }
          key={textLocalId.split(' ').join('_')}
          extra={
            <View style={styles.iconBtnView}>
              {btnList.map((singleBtn, index) => {
                return (
                  <Tooltip
                    label={singleBtn.tooltipLabel}
                    placement={'top'}
                    key={index}
                  >
                    <IconButton
                      icon={singleBtn.icon}
                      onPress={singleBtn.onIconBtnClick}
                      _hover={{background: 'transparent'}}
                      padding={'0'}
                      marginTop={'1'}
                      marginLeft={'1.5'}
                    />
                  </Tooltip>
                );
              })}
            </View>
          }
        >
          {children}
        </Panel>
      </Collapse>
    </>
  );
};

export default CommonCollapse;
