import React from 'react';
import { isWeb } from '../../../utils/platformCheckUtils';
import { Image } from 'native-base';

const ChatCircleSvg = () => {

    return (
        <>
            {isWeb() ? (
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 9.72215C18 10.9167 17.7227 12.1007 17.1787 13.1779C16.5388 14.4578 15.5575 15.5351 14.3309 16.2924C13.115 17.0497 11.7071 17.4443 10.2778 17.4443C9.18992 17.4443 8.11266 17.2203 7.12072 16.7724C6.92873 16.687 6.71542 16.6657 6.51276 16.7297L2.79034 17.9669C2.32104 18.1269 1.87307 17.679 2.03306 17.2097L3.27031 13.4872C3.33431 13.2846 3.32364 13.0713 3.22765 12.8793C2.77968 11.8873 2.54503 10.8101 2.55569 9.72215C2.55569 8.29292 2.961 6.88501 3.70762 5.66909C4.4649 4.45317 5.54216 3.46124 6.82207 2.82128C7.88867 2.27731 9.07259 2 10.2778 2H10.7365C12.6244 2.10666 14.4162 2.90661 15.7601 4.23985C17.1041 5.58376 17.904 7.36498 18 9.26352V9.72215Z" fill="#D0D5DD" />
                </svg>
            ) : (
                <Image
                    size={'6'}
                    source={require('../../../assets/images/MessageActionImages/chatBgIcon.png')}
                    alt="aa"
                />
            )}
        </>
    );
};

export default ChatCircleSvg;
