import React from 'react';
import {ICommonSvgProps} from '../interfaces';
import Svg, { Path } from 'react-native-svg';

const EyeOpenSvg = (props: ICommonSvgProps) => {
  return (
    <Svg
      width={props?.width ? props?.width :"16"}
      height={props?.height ? props?.height : "12"}
      fill="none"
      viewBox="0 0 16 12"
    >
      <Path
        fill={props?.customStrokeColor ? props?.customStrokeColor : "#6F7A90"}
        d="M13.817 3.803l-.396.305.396-.305zm0 4.394l-.396-.305.396.305zM14.667 6h-.5.5zM2.183 3.803l.397.305-.397-.305zm0 4.394l.397-.305-.397.305zm.397-4.09c1.086-1.41 2.854-2.94 5.42-2.94v-1c-3.01 0-5.036 1.802-6.213 3.33l.793.61zM8 1.168c2.566 0 4.334 1.53 5.42 2.94l.793-.61C13.037 1.97 11.009.168 8 .168v1zm5.42 6.725c-1.086 1.411-2.854 2.941-5.42 2.941v1c3.01 0 5.037-1.803 6.213-3.33l-.792-.61zM8 10.833c-2.566 0-4.334-1.53-5.42-2.94l-.793.61c1.177 1.527 3.204 3.33 6.213 3.33v-1zm5.42-6.725c.293.38.464.607.577.862.105.238.17.53.17 1.03h1c0-.592-.077-1.031-.255-1.434-.171-.387-.425-.712-.699-1.068l-.792.61zm.793 4.394c.274-.356.528-.681.699-1.068.178-.403.255-.842.255-1.434h-1c0 .5-.065.792-.17 1.03-.113.255-.284.482-.576.862l.792.61zM1.787 3.498c-.274.356-.528.681-.698 1.068C.91 4.969.833 5.408.833 6h1c0-.5.065-.792.17-1.03.113-.255.284-.482.577-.862l-.793-.61zm.793 4.394c-.293-.38-.464-.607-.577-.862-.105-.238-.17-.53-.17-1.03h-1c0 .592.077 1.031.256 1.434.17.387.424.712.698 1.068l.793-.61zM9.5 6A1.5 1.5 0 018 7.5v1A2.5 2.5 0 0010.5 6h-1zM8 7.5A1.5 1.5 0 016.5 6h-1A2.5 2.5 0 008 8.5v-1zM6.5 6A1.5 1.5 0 018 4.5v-1A2.5 2.5 0 005.5 6h1zM8 4.5A1.5 1.5 0 019.5 6h1A2.5 2.5 0 008 3.5v1z"
      ></Path>
    </Svg>
  );
};

export default EyeOpenSvg;
