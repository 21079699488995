import {Drawer} from 'antd';
import {Button, HStack, IButtonProps, Spacer, VStack} from 'native-base';
import React from 'react';
import {useIntl} from 'react-intl';
import {DisplayText} from '../DisplayText/DisplayText';

export interface FHDrawerAlertButton {
  textLocalId: string;
  textColor?: string;
  buttonProps: IButtonProps;
  onPress: () => void;
}

export interface FHDrawerAlertProps {
  isOpen: boolean;
  header?: string;
  message?: string;
  closeModal?: () => void;
  buttonActions: FHDrawerAlertButton[];
}

const FHDrawerAlert = (props: FHDrawerAlertProps) => {
  const intl = useIntl();
  return (
    <Drawer
      title={
        <DisplayText
          textType={'Heading'}
          textLocalId={
            props.header || intl.formatMessage({id: 'defaultAlertDialogHeader'})
          }
        />
      }
      destroyOnClose
      placement="right"
      onClose={() => {
        if (props.closeModal) {
          props.closeModal();
        }
      }}
      visible={props.isOpen}
      closable={false}
      width={450}
    >
      <VStack space={6}>
        <HStack>
          <DisplayText
            textLocalId={
              props.message ||
              intl.formatMessage({id: 'defaultAlertDialogMessage'})
            }
          />
        </HStack>
        <HStack space={2}>
          <Spacer />
          {props.buttonActions.map((buttonAction, index) => {
            return (
              <Button
                key={index}
                onPress={buttonAction.onPress}
                rounded="3xl"
                {...buttonAction.buttonProps}
              >
                <DisplayText
                  textLocalId={buttonAction.textLocalId}
                  extraStyles={{color: buttonAction.textColor || 'black'}}
                />
              </Button>
            );
          })}
        </HStack>
      </VStack>
    </Drawer>
  );
};

export default FHDrawerAlert;
