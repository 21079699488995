import { Drawer, Skeleton } from 'antd';
import { View, useToast } from 'native-base';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { WorkflowRender } from '../../../../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowRenderView';
import { ModalActionTitle } from '../../../../../../common/ModalActionTitle/ModalActionTitle';
import { BUTTON_TYPE } from '../../../../../../../constants';
import { Colors } from '../../../../../../../styles';
import { IWorkflowContextData, WorkflowContext } from '../../../../../../../context/WorkflowContext';
import { CommonDataContext } from '../../../../../../../context/CommonDataContext';
import { getNodeMetadata, getWorkFlowDataById } from '../../../../../Journeys/AddOrUpdateJourney/JourneyMetadataService';
import { showToast, ToastType } from '../../../../../../../utils/commonViewUtils';
import { setWorkFlowConfigToElements } from '../../../../../Journeys/JourneysOfCare/PatientCareJourneyDetail/PatientCareJourneyDetailHelper';
import { getAccountUUID } from '../../../../../../../utils/commonUtils';
import { reactStyles, styles } from '../../../../formStyle';

const ReadOnlyAutomationViewDrawer = (props: {
  title: string;
  open: boolean;
  workflowDetails: any;
  onClose: () => void;
  flowType: any
}) => {
  const intl = useIntl();
  const toast = useToast();
  const accountUuId = getAccountUUID();
  const [detailState, setDetailState] = useState<{
    loading: boolean;
    selectedJourneyNodes: any[];
    selectedJourneyEdges: any[];
    workflowData: any;
  }>({
    loading: false,
    selectedJourneyNodes: [],
    selectedJourneyEdges: [],
    workflowData: {},
  });

  const commonData = useContext(CommonDataContext);
  const isSidecarContext = commonData?.sidecarContext?.isSidecar;

  const flowType = props.flowType;
  const [workflowContextData, setWorkflowContextData] = useState<IWorkflowContextData>({
    nodeMasterDataMap: {},
    nodeMetaData: undefined,
    flowType: flowType,
    setNodeMasterDataMap: (data) => {
      setWorkflowContextData((prev) => ({ ...prev, nodeMasterDataMap: data }));
    },
  });

  useEffect(() => {
    getNodeMetaData();
    const journeyId = props.workflowDetails?.workflowMasterId;
    if (journeyId) {
      setDetailState((prev) => ({ ...prev, loading: true }));
      getWorkFlowDataById(
        journeyId,
        accountUuId,
        async (response) => {
          const nodes = response.nodes;
          const edges = response.edges;
          if (
            nodes &&
            nodes.length > 0 &&
            props.workflowDetails?.workflowConfig
          ) {
            setWorkFlowConfigToElements(
              nodes,
              edges,
              props.workflowDetails?.workflowConfig
            );
          }
          setDetailState((prev) => ({
            ...prev,
            selectedJourneyNodes: nodes || [],
            selectedJourneyEdges: edges || [],
            workflowData: response?.workflowData,
            loading: false,
          }));
        },
        () => {
          showErrorToast();
          setDetailState((prev) => ({
            ...prev,
            loading: false,
          }));
        }
      );
    }
  }, [props.workflowDetails?.workflowMasterId, props.workflowDetails?.workflowConfig]);

  const getNodeMetaData = () => {
    getNodeMetadata(
      flowType,
      (response) => {
        setWorkflowContextData((prev) => ({
          ...prev,
          nodeMasterDataMap: response.nodeMasterData?.nodeMap,
          nodeMetaData: {
            frequencyList: response.nodeMetaData?.frequencyList || [],
            triggerUnits: response.nodeMetaData?.triggerUnits || [],
            durationUnits: response.nodeMetaData?.durationUnits || [],
            reminderUnits: response.nodeMetaData?.reminderUnits || [],
          },
        }));
      },
      () => {
        showErrorToast();
      }
    );
  };

  const showErrorToast = () => {
    showToast(toast, intl.formatMessage({ id: 'apiErrorMsg' }), ToastType.error);
  };

  return (
    <Drawer
      destroyOnClose
      placement="right"
      onClose={() => {
        props.onClose();
      }}
      style={reactStyles.padding0}
      open={props.open}
      closable={false}
      width={isSidecarContext ? '100%' : '60%'}
      bodyStyle={reactStyles.padding0}
      mask={!isSidecarContext}
      title={
        <ModalActionTitle
          title={props.title}
          buttonList={[
            {
              show: true,
              id: 1,
              btnText: isSidecarContext ? 'back' : 'close',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.SECONDARY,
              isTransBtn: false,
              onClick: () => {
                props.onClose();
              },
            },
          ]}
        />
      }
    >
      {detailState.loading ? (
        <View width="full" padding={4}>
          <Skeleton active />
        </View>
      ) : (
        <WorkflowContext.Provider value={workflowContextData}>
          <View height="85vh">
            <WorkflowRender
              setWorkflowContext={setWorkflowContextData}
              onNodeDataChange={() => {}}
              isViewOnly={true}
              isWorkFlowEditable={false}
              flowType={flowType}
              libNodeIdList={[]}
              nodes={detailState.selectedJourneyNodes || []}
              setNodes={() => {}}
              edges={detailState.selectedJourneyEdges || []}
              setEdges={() => {}}
            />
          </View>
        </WorkflowContext.Provider>
      )}
    </Drawer>
  );
};

export default ReadOnlyAutomationViewDrawer;
