import {Drawer, Modal} from 'antd';
import {Button, Text, View} from 'native-base';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {BUTTON_TYPE} from '../../../constants';
import {FoldButton} from '../../CommonComponents/FoldButton/FoldButton';
import {DisplayText} from '../DisplayText/DisplayText';


// const {Content, Footer, Header, CloseButton, Body} = Modal;

const ResetPasswordModal = (props: any) => {
  const {onClose, onConfirm, isOpen} = props;
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  return (
    <>
      <Modal
        title="Confirmation" open={isOpen}
        onCancel={() => {
          onClose()
        }}
        footer={null}
        bodyStyle={{paddingTop: 12}}
      >
        <View>
          <Text size={'mdMedium'} >Do you want to send reset password instructions?</Text>
          <Button.Group style={{marginTop: 12}} justifyContent={'flex-end'}>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.SECONDARY,
                onPress: () => {
                  onClose();
                },
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: props?.closeButtonText || 'close',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                onPress: () => {
                  onConfirm();
                },
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'confirm',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
          </Button.Group>
        </View>
      </Modal>

    </>
  );
};

export default ResetPasswordModal;
