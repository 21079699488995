import { View } from 'react-native'
import React, { useContext, useEffect, useState } from 'react'
import TaskMainView from '../TaskModuleComponents/TaskMainView'
import { BoardType } from '../../common/CareDashboard/CareDashboardTopBar/interfaces';
import { ITaskDisplayConfig, IUserPatientSearchItem, TaskNavigateContext, TaskViewType } from '../TaskInterfaces';
import { getCategoriesFromBoardType, getPatientTasksDefaultParams } from '../TaskModuleHelper';
import { getTaskFilteredCategoryData } from '../../common/Tasks/TasksUtils/TasksUtils';
import { ITopBarData } from '../../common/CareDashboard/CareDashboardTopBar/CareDashboardTopBar';
import { getCareStudioMlovListByCategoryCode, getMlovId, getMlovObjectFromCode } from '../../../utils/mlovUtils';
import { MLOV_CATEGORY } from '../../../constants';
import { ITask } from '../../common/CareDashboard/CareDashboardInterfaces';
import { COMMON_ACTION_CODES } from '../../../constants/ActionConst';
import { IMlov } from '../../../Interfaces';
import { IBatchedAccountUsersInterface } from '../../CustomHooks/useGetBatchedAccountUsers';
import { IUserPool } from '../../common/MemberShip/interface';
import { cloneDeep, isEqual } from 'lodash';
import { ITaskCount } from '../../common/CareDashboard/CustomHook/useTaskCountManager';
import { TaskPanelType, TaskPanelTypeCode } from '../../TaskCard/TaskEnum';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { isRenderTasksCompactView } from '../../../utils/commonUtils';

interface IPatientTaskManagerProps {
  personData: any;
  userList: IBatchedAccountUsersInterface[];
  topBarData: ITopBarData;
  filters: any;
  restrictPatientOrLeadSelectionTo?: IUserPatientSearchItem;
  onNavigate?: (context: TaskNavigateContext, task?: ITask) => void;
  viewType?: TaskViewType;
  masterUsersPoolList?: IUserPool[];
  onFiltersUpdate?: (filters: any) => void;
  onTaskCountUpdate?: (taskCount: ITaskCount[]) => void;
}

const PatientTaskManager = (props: IPatientTaskManagerProps) => {
  const { personData, topBarData : topBarDataProps, filters: filtersProps, restrictPatientOrLeadSelectionTo, userList, masterUsersPoolList, onNavigate, onFiltersUpdate, onTaskCountUpdate } = props;
  const taskStatusMlovs = getCareStudioMlovListByCategoryCode(MLOV_CATEGORY.TASK_STATUS);
  const [topBarData, setTopBarData] = useState<ITopBarData>(topBarDataProps)
  const taskPriorityMlovs = getCareStudioMlovListByCategoryCode(MLOV_CATEGORY.TASK_PRIORITY);
  const [filters, setFilters] = useState(filtersProps);
  const commonData = useContext(CommonDataContext);
  const isRenderCompactView = isRenderTasksCompactView();
  
  const [displayConfig, setDisplayConfig] = useState<ITaskDisplayConfig>({
    boardType: BoardType.Status,
    viewType: props.viewType || TaskViewType.kanban,
    categories: [],
  });

  // Life cycle methods
  useEffect(() => {
    updateViewCategoriesWithFilters();
  }, [filters, topBarData]);

  useEffect(() => {
    setTopBarData((prev: any) => {
      const topBarData = {
        ...prev,
        ...topBarDataProps,
      };
      return topBarData;
    });
  }, [topBarDataProps]);

  useEffect(() => {
    setFilters(filtersProps);
  }, [filtersProps]);

  useEffect(()=> {
    setDisplayConfig((prev)=> ({
      ...prev,
      viewType: props.viewType || TaskViewType.kanban
    }));
  }, [props.viewType]);

  const updateViewCategoriesWithFilters = () => {
    const categories = getCategoriesFromBoardType(BoardType.Status);
    const topBarDataForInternalTasks = {
      ...topBarData,
      panelType: TaskPanelType.INTERNAL
    }
    const topBarDataForPatientTasks = {
      ...topBarData,
      panelType: TaskPanelType.PATIENT
    }
    const paramsForInternalTasks = getPatientTasksDefaultParams({
      topBarData: topBarDataForInternalTasks,
      taskStatusMlovList: taskStatusMlovs,
      filters: filters,
      categoryCode: filters?.selectedStatusCodes,
      personData,
    });
    const paramsForPatientTasks = getPatientTasksDefaultParams({
      topBarData: topBarDataForPatientTasks,
      taskStatusMlovList: taskStatusMlovs,
      filters: filters,
      categoryCode: filters?.selectedStatusCodes,
      personData,
    });
    const taskCategoryTabs = [{
      key: TaskPanelTypeCode.INTERNAL,
      code: TaskPanelTypeCode.INTERNAL,
      name: 'Internal',
      filters: paramsForInternalTasks,
    },
    {
      key: TaskPanelTypeCode.PATIENT as string,
      code: TaskPanelTypeCode.PATIENT as string,
      name: 'Patient',
      filters: paramsForPatientTasks,
    },
  ];
    const filterCodes = getTaskFilteredCategoryData(categories, {...topBarData, selectedStatusList: filters?.formData?.selectedStatusList }, 'code', BoardType.Status);
    const filteredCategories = filterCodes.length > 0 ? categories.filter(category => filterCodes.includes(category.code)) : categories;

    setDisplayConfig((prev) => {
      const categoriesForDisplayConfig = filteredCategories.map((item) => {
        const params = getPatientTasksDefaultParams({
          topBarData,
          taskStatusMlovList: taskStatusMlovs,
          filters: filters,
          categoryCode: item.code,
          personData,
        });
        return {
          key: item.code,
          code: item.code,
          name: item.name,
          filters: params
        }
      })
      if (isRenderCompactView) {
        categoriesForDisplayConfig.push(...taskCategoryTabs)
      }      
      return {
        ...prev,
        boardType: BoardType.Status,
        viewType: prev.viewType,
        categories: categoriesForDisplayConfig,
      }
    })
  }

  const onActionPerformed = (tabCode: string, rawData: any) => {
    const updatedTopBarData = cloneDeep(topBarData);
    const updatedFilters = cloneDeep(filters);
    switch (tabCode) {
      case COMMON_ACTION_CODES.CHANGE_TABLE_FILTER_SORTER:
        const prevPriorityCodes =
          filters?.formData.selectedPriorityTypes?.map(
            (mlov: IMlov) => mlov.code
          ) || [];
        const currentPriorityCodes = rawData?.filters?.priority || [];

        if (!isEqual(prevPriorityCodes, currentPriorityCodes)) {
          const selectedPriorityList: IMlov[] = [];
          currentPriorityCodes.forEach((mlovCode: string) => {
            const mlov = getMlovObjectFromCode(mlovCode, taskPriorityMlovs);
            if (mlov) {
              selectedPriorityList.push(mlov);
            }
          });
          updatedFilters.formData.selectedPriorityTypes = selectedPriorityList;
          updatedFilters.formData.selectedPriorityTypesIds =
            selectedPriorityList.map((priority) => priority.id);
        }

        const sorter = rawData?.sorter || {};
        if (sorter.columnKey && sorter.order) {
          (updatedTopBarData as any).selectedSortUuid = sorter.columnKey;
          (updatedTopBarData as any).selectedSortType = sorter.columnKey;
          (updatedTopBarData as any).selectedSortValueAscending =
            sorter.order === 'ascend' ? true : false;
        } else {
          (updatedTopBarData as any).selectedSortUuid = 'none';
          (updatedTopBarData as any).selectedSortType = undefined;
        }
        break;
      case COMMON_ACTION_CODES.DISMISS_INTRO_CARD:
        updatedTopBarData.bulkTaskIntroCardDismissed = rawData?.bulkTaskIntroCardDismissed;
        break;
    }

    if (!isEqual(filters, updatedFilters)) {
      setFilters((prev: any) => ({
        ...prev,
        updatedFilters,
      }));
      onFiltersUpdate?.({...updatedFilters});
    }

    if(!isEqual(topBarData, updatedTopBarData)){
      setTopBarData((prev: any) => {
        const topBarData = {
          ...prev,
          ...updatedTopBarData,
        };
        return topBarData;
      });
    }
  }

  return (
    <View>
      <TaskMainView
        config={displayConfig}
        onActionPerformed={onActionPerformed}
        onTaskCountUpdate={onTaskCountUpdate}
        metaData={{
          boardType: displayConfig.boardType,
          masterUsersList: userList,
          masterPoolList: masterUsersPoolList,
          personData,
          restrictPatientOrLeadSelectionTo,
          onNavigate: onNavigate,
          panelType: topBarData.panelType
        }}
      />
    </View>
  )
}

export default PatientTaskManager
