import { VStack } from "native-base";
import React from 'react';
import { MessagingCustomCardContent } from "../../../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/interface";
import { MESSAGE_CUSTOM_CARD_KEY } from "../../../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MessagingCustomCard/CustomCardConst";
import ContactCardView from "./ContactCard/ContactCardView";
import { IMessagingCustomCardWebProps } from "./interfase";
import { testID } from "../../../../../testUtils";

const MessagingCustomCard = (props: IMessagingCustomCardWebProps) => {
    const {
        conversationMessage,
        textColor,
        navigation,
    } = props;

    function isJsonString(str: any) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    
    const content: MessagingCustomCardContent = isJsonString(conversationMessage?.content || '') ? JSON.parse(conversationMessage?.content || '') : {}
    const resourceId = content?.resourceData?.resourceId.length > 1 ? content?.resourceData?.resourceId : content?.resourceData?.resourceId[0];
    
    return (
        <VStack {...testID('MessageContactCardContent')}>
            {
                content.resourceCode === MESSAGE_CUSTOM_CARD_KEY.CONTACT_CARD
                    ?
                    <ContactCardView
                        contactUUID={resourceId}
                        textColor={textColor}
                        navigation={navigation}
                    />
                    :
                    content.resourceCode === MESSAGE_CUSTOM_CARD_KEY.PATIENT_CARD
                        ?
                        <ContactCardView
                            contactUUID={resourceId}
                            textColor={textColor}
                            navigation={navigation}
                        />
                        :
                    <></>
            }
        </VStack>
    )
}

export default MessagingCustomCard;