import {getParentIdx, IBlockData} from '@foldhealth/easy-email-core';
import {useBlock, useFocusIdx} from '@foldhealth/easy-email-editor';
import {get} from 'lodash';
import {
  Box,
  FormControl,
  HStack,
  Text,
  TextArea,
  useToast,
  View,
  VStack,
} from 'native-base';
import {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Dimensions} from 'react-native';
import {BUTTON_TYPE} from '../../../../constants';
import {getDalleSuggestions} from '../../../../services/Billing/BillingServices';
import CMSService from '../../../../services/CommonService/CMSService';
import {showToast, ToastType} from '../../../../utils/commonViewUtils';
import {DisplayText} from '../../../common/DisplayText/DisplayText';
import LoadingSpinner from '../../../common/Loader/LoadingSpinner';
import ModalActionBtn from '../../../common/ModalActionBtn/ModalActionBtn';
import {FoldButton} from '../../../CommonComponents/FoldButton/FoldButton';
import {dataUrlToFile} from '../ContentManagementUtils';
import {CustomBlocksType} from '../EmailTemplates/CreateOrEditTemplate/CustomBlocks/constants';
import {IGpt3} from '../EmailTemplates/CreateOrEditTemplate/CustomBlocks/OpenAiSuggestions';
import ViewDalleSuggestionsList from './ViewDalleSuggestionsList';
const {
  Label,
  // ErrorMessage
} = FormControl;

const ViewDalleSuggestions = (props: {template?: any; onModalClose?: any}) => {
  const [template, setTemplate] = useState<any>(null);
  const [showLoader, setShowLoader] = useState(false);
  const [suggestedContent, setSuggestedContent] = useState<any>([]);
  const [tSuggestion, setTSuggestion] = useState<any>(null);
  const {addBlock, moveBlock, values} = useBlock();
  const {focusIdx} = useFocusIdx();
  const cmsInstance = CMSService.getCMSServiceInstance();
  const fileUploadService = cmsInstance.fileUploadService;
  const toast = useToast();
  const intl = useIntl();
  const [suggestions, setSuggestions] = useState({
    suggestedContent: [] as any,
    selectedContent: [] as any,
  });

  const getSuggestionsResp = async (suggestion: string) => {
    setShowLoader(true);
    setTSuggestion(suggestion);
    setSuggestions({
      suggestedContent: [] as any,
      selectedContent: [] as any,
    });
    setSuggestedContent([]);
    const suggs = [];
    const suggestionsResp = await getDalleSuggestions(suggestion);
    if (suggestionsResp) {
      const result = suggestionsResp.data?.result;

      if (result && result.length > 0) {
        for (const choice of result) {
          const sugg = {
            title: suggestion,
            b64_json: choice?.b64_json,
          };
          suggs.push(sugg);
        }
      }
    }
    setSuggestedContent(suggs);
    setShowLoader(false);
  };

  const uploadFile = async (files: any) => {
    setShowLoader(true);
    const formData = new FormData();
    const returningUrl = [];
    files.forEach((file: any) =>
      formData.append('files', file.originFileObj as any)
    );
    try {
      const res = await fileUploadService.post('', formData);
      const files = res.data?.data?.attributes?.file?.data;
      for (const upFile of files) {
        returningUrl.push({
          title: upFile?.attributes?.name,
          url: upFile?.attributes?.url,
        });
      }

      setShowLoader(false);
      return returningUrl;
    } catch (err) {
      showToast(
        toast,
        'Something went wrong. Please try again later',
        ToastType.error
      );
      setShowLoader(false);
    }
  };

  useEffect(() => {
    setShowLoader(false);
    setTemplate(props?.template);
  }, [props]);
  const {height} = Dimensions.get('window');
  const finalHeight = height - 380;
  return (
    <VStack>
      <Text style={{marginLeft: 5, marginBottom: 10}} color="gray.500">
        {'You can add the below suggested content to the email.'}
      </Text>

      <View style={{flex: 1}}>
        <Box alignItems="center">
          <FormControl>
            <HStack
              style={{
                justifyContent: 'flex-start',
              }}
            >
              <Label marginTop={2}>
                <DisplayText textLocalId="contentSuggestions" />
              </Label>
              <TextArea
                defaultValue={template?.templateData?.subject}
                onBlur={(event: any) => {
                  setTSuggestion(event?.target?.value);
                }}
              />
              <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.PRIMARY,
                  onPress: () => {
                    if (tSuggestion) {
                      getSuggestionsResp(tSuggestion);
                    } else {
                      getSuggestionsResp(template?.templateData?.subject);
                    }
                  },
                  style: {margin: 16},
                }}
                customProps={{
                  btnText: intl.formatMessage({
                    id: 'viewSuggestions',
                  }),
                  withRightBorder: false,
                }}
              ></FoldButton>
            </HStack>
          </FormControl>
        </Box>
        {suggestedContent?.map((content: any, index: any) => {
          return (
            <ViewDalleSuggestionsList
              html={content}
              index={index}
              title={tSuggestion}
              suggestions={suggestions}
              onModalClose={props?.onModalClose}
            />
          );
        })}
        <FoldButton
          nativeProps={{
            variant: BUTTON_TYPE.PRIMARY,
            onPress: async () => {
              let parentIdx = getParentIdx(focusIdx);
              const self = get(values, focusIdx) as IBlockData | null;

              if (focusIdx === 'content') {
                parentIdx = 'content';
              }

              const files = [];

              for (const content of suggestions.selectedContent) {
                const file = await dataUrlToFile(
                  content.b64_json,
                  `${content.title}.png`
                );
                const selFile = {
                  originFileObj: file,
                  title: content.title,
                };
                files.push(selFile);
              }

              let upFiles = [
                {
                  title: '',
                  url: '',
                },
              ];
              if (files.length > 0) {
                const newUpfile = await uploadFile(files);
                if (newUpfile) {
                  upFiles = newUpfile;
                }
              }


              if (parentIdx) {
                const payload: IGpt3 = {
                  type: CustomBlocksType.DALLE_SUGGESTIONS,
                  data: {
                    value: {
                      suggestions: upFiles,
                    },
                  },
                  attributes: {
                    'background-color': '#ffffff',
                    'button-text-color': '#ffffff',
                    'button-color': '#414141',
                    'title-color': '#222222',
                    'description-color': '#222222',
                  },
                  children: [],
                };
                if (self?.type != CustomBlocksType.DALLE_SUGGESTIONS) {
                  addBlock({
                    type: CustomBlocksType.DALLE_SUGGESTIONS,
                    parentIdx: parentIdx,
                    positionIndex: undefined,
                    payload: payload,
                  });
                } else {
                  addBlock({
                    type: CustomBlocksType.DALLE_SUGGESTIONS,
                    parentIdx: parentIdx,
                    positionIndex: undefined,
                    payload: payload,
                    canReplace: true,
                  });
                }
              }
              setSuggestedContent([]);
              props?.onModalClose();
            },
            style: {margin: 16},
          }}
          customProps={{
            btnText: intl.formatMessage({
              id: 'submit',
            }),
            withRightBorder: false,
          }}
        ></FoldButton>

        {showLoader && <LoadingSpinner />}
      </View>
    </VStack>
  );
};

export default ViewDalleSuggestions;
