import React from 'react';
import {HStack, Spinner, Text, View} from 'native-base';

const SectionLoadingIndicator = () => {
  return (
    <HStack space={2} justifyContent="center" height={30} width={20}>
      <View>
        <Spinner color="gray.500" size="sm" />
      </View>
      <Text color="gray.500" size="sm" italic>
        Saving
      </Text>
    </HStack>
  );
};

export default SectionLoadingIndicator;
