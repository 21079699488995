import { Divider, VStack, Text } from "native-base"
import { DisplayText } from "../../../common/DisplayText/DisplayText"
import { IErxPrintViewProps } from "../interfaces"
import { getMedicationOrderName, getMedicationSubTitle } from "../Orders/OrdersAndReports/OrderUtils"

export const ErxPrintView = (props: IErxPrintViewProps) => {
  // Note - don't remove page-break class, it used for print preview
  return (
    <VStack py={2}>
      <VStack>
        <DisplayText
          extraStyles={{
            fontWeight: 800,
            color: '#000'
          }}
          size={'lgMedium'}
          textLocalId={props.title}
        />
        <Divider my={2} backgroundColor={'black'} />
      </VStack>
      <VStack>
        {(props.orders || [])?.map((order: any) => {
          return (
            <div className='page-break'>
              <VStack py={1} flex={8}>
                <Text fontSize={16} fontWeight={700} key={`${order.id}`}>
                  {getMedicationOrderName(order.resource)}
                </Text>
                <Text fontSize={16} fontWeight={500}>
                  {getMedicationSubTitle(order.resource)}
                </Text>
              </VStack>
            </div>
          )
        })}
      </VStack>
    </VStack>
  )

}
