import {
  alliedPhysicianFemale,
  alliedPhysicianMale,
  assistantPhysicianFemale,
  assistantPhysicianMale,
  branchAdminFemale,
  branchAdminMale,
  businessOwnerFemale,
  businessOwnerMale,
  careProgramManagerFemale,
  careProgramManagerMale,
  chatbot,
  female,
  financeFemale,
  financeMale,
  healthAdvocateFemale,
  healthAdvocateMale,
  internalGroup,
  male,
  nurse,
  patientUnknown,
  patientBroadCastGroup,
  patientGroupFemale,
  patientGroupMale,
  physicianFemale,
  physicianMale,
  practiceOwnerFemale,
  practiceOwnerMale,
  prospect,
  socialWorker,
  supportFemale,
  supportMale,
} from './DisplayAvatarPngFiles';
import {IGenderCodeType, IPronounType} from './interface';

export const AVATAR_IMG_BASE_PATH = '../../../assets/images/Avatar/';

export const GENDER_CODE: {[index in IGenderCodeType]: IGenderCodeType} = {
  male: 'male',
  female: 'female',
  other: 'other',
  M: 'M',
  F: 'F',
  ASKU: 'ASKU',
  OTH: 'OTH',
  UKN: 'UKN',
  UNK: 'UNK',
};
export const CONTACT_GENDER_IMG: {[index in IGenderCodeType]: string} = {
  male: male,
  female: female,
  other: patientUnknown,
  M: male,
  F: female,
  ASKU: patientUnknown,
  OTH: patientUnknown,
  UKN: patientUnknown,
  UNK: patientUnknown,
};

export const USER_AVATAR_BY_ROLES: {
  [index: string]: {[index in IGenderCodeType]: string};
} = {
  ALLIED_PHYSICIAN: {
    male: alliedPhysicianMale,
    female: alliedPhysicianFemale,
    other: alliedPhysicianMale,

    M: alliedPhysicianMale,
    F: alliedPhysicianFemale,
    ASKU: alliedPhysicianMale,
    OTH: alliedPhysicianMale,
    UKN: alliedPhysicianMale,
    UNK: alliedPhysicianMale
  },
  BUSINESS_OWNER: {
    male: businessOwnerMale,
    female: businessOwnerFemale,
    other: businessOwnerMale,

    M: businessOwnerMale,
    F: businessOwnerFemale,
    ASKU: businessOwnerMale,
    OTH: businessOwnerMale,
    UKN: businessOwnerMale,
    UNK: businessOwnerMale,
  },
  CARE_PROGRAM_MANAGER: {
    male: careProgramManagerMale,
    female: careProgramManagerFemale,
    other: careProgramManagerMale,

    M: careProgramManagerMale,
    F: careProgramManagerFemale,
    ASKU: careProgramManagerMale,
    OTH: careProgramManagerMale,
    UKN: careProgramManagerMale,
    UNK: careProgramManagerMale
  },
  COMMUNITY_HEALTH_WORKER: {
    male: socialWorker,
    female: socialWorker,
    other: socialWorker,

    M: socialWorker,
    F: socialWorker,
    ASKU: socialWorker,
    OTH: socialWorker,
    UKN: socialWorker,
    UNK: socialWorker,
  },
  EXTERNAL_CASE_MANAGER: {
    male: practiceOwnerMale,
    female: practiceOwnerFemale,
    other: practiceOwnerMale,

    M: practiceOwnerMale,
    F: practiceOwnerFemale,
    ASKU: practiceOwnerMale,
    OTH: practiceOwnerMale,
    UKN: practiceOwnerMale,
    UNK: practiceOwnerMale
  },
  FINANCE_TEAM_MEMBER: {
    male: financeMale,
    female: financeFemale,
    other: financeMale,

    M: financeMale,
    F: financeFemale,
    ASKU: financeMale,
    OTH: financeMale,
    UKN: financeMale,
    UNK: financeMale
  },
  FRONT_DESK_MANAGER: {
    male: supportMale,
    female: supportFemale,
    other: supportMale,

    M: supportMale,
    F: supportFemale,
    ASKU: supportMale,
    OTH: supportMale,
    UKN: supportMale,
    UNK: supportMale
  },
  GUARDIAN: {
    male: careProgramManagerMale,
    female: careProgramManagerFemale,
    other: careProgramManagerMale,

    M: careProgramManagerMale,
    F: careProgramManagerFemale,
    ASKU: careProgramManagerMale,
    OTH: careProgramManagerMale,
    UKN: careProgramManagerMale,
    UNK: careProgramManagerMale
  },
  HEALTH_COACH: {
    male: healthAdvocateMale,
    female: healthAdvocateFemale,
    other: healthAdvocateMale,

    M: healthAdvocateMale,
    F: healthAdvocateFemale,
    ASKU: healthAdvocateMale,
    OTH: healthAdvocateMale,
    UKN: healthAdvocateMale,
    UNK: healthAdvocateMale
  },
  LOCATION_PRACTICE_MANAGER: {
    male: branchAdminMale,
    female: branchAdminFemale,
    other: branchAdminMale,

    M: branchAdminMale,
    F: branchAdminFemale,
    ASKU: branchAdminMale,
    OTH: branchAdminMale,
    UKN: branchAdminMale,
    UNK: branchAdminMale
  },
  MENTAL_HEALTH_GUARDIAN: {
    male: careProgramManagerMale,
    female: careProgramManagerFemale,
    other: careProgramManagerMale,

    M: careProgramManagerMale,
    F: careProgramManagerFemale,
    ASKU: careProgramManagerMale,
    OTH: careProgramManagerMale,
    UKN: careProgramManagerMale,
    UNK: careProgramManagerMale,
  },
  NURSE_GUARDIAN: {
    male: nurse,
    female: nurse,
    other: nurse,

    M: nurse,
    F: nurse,
    ASKU: nurse,
    OTH: nurse,
    UKN: nurse,
    UNK: nurse
  },
  NURSE_PRACTITIONER: {
    male: nurse,
    female: nurse,
    other: nurse,

    M: nurse,
    F: nurse,
    ASKU: nurse,
    OTH: nurse,
    UKN: nurse,
    UNK: nurse
  },
  ONSITE_GUARDIAN: {
    male: careProgramManagerMale,
    female: careProgramManagerFemale,
    other: careProgramManagerMale,

    M: careProgramManagerMale,
    F: careProgramManagerFemale,
    ASKU: careProgramManagerMale,
    OTH: careProgramManagerMale,
    UKN: careProgramManagerMale,
    UNK: careProgramManagerMale
  },
  PHYASST: {
    male: assistantPhysicianMale,
    female: assistantPhysicianFemale,
    other: assistantPhysicianMale,

    M: assistantPhysicianMale,
    F: assistantPhysicianFemale,
    ASKU: assistantPhysicianMale,
    OTH: assistantPhysicianMale,
    UKN: assistantPhysicianMale,
    UNK: assistantPhysicianMale
  },
  PHYSICIAN: {
    male: physicianMale,
    female: physicianFemale,
    other: physicianMale,

    M: physicianMale,
    F: physicianFemale,
    ASKU: physicianMale,
    OTH: physicianMale,
    UKN: physicianMale,
    UNK: physicianMale
  },
};

export const USER_ROLE_PRIORITY_LIST = [
  'BUSINESS_OWNER',
  'PHYSICIAN',
  'PHYASST',
  'ALLIED_PHYSICIAN',
  'CARE_PROGRAM_MANAGER',
  'EXTERNAL_CASE_MANAGER',
  'HEALTH_COACH',
  'COMMUNITY_HEALTH_WORKER',
  'LOCATION_PRACTICE_MANAGER',
  'FRONT_DESK_MANAGER',
  'FINANCE_TEAM_MEMBER',
  'MENTAL_HEALTH_GUARDIAN',
  'NURSE_GUARDIAN',
  'NURSE_PRACTITIONER',
  'GUARDIAN',
  'ONSITE_GUARDIAN',
];

export const PRONOUN_CODE: {[index in IPronounType]: IPronounType} = {
  he_him_his: 'he_him_his',
  she_her_hers:  'she_her_hers',
  they_them_theirs: 'they_them_theirs',
  not_listed: 'not_listed',
};