import {Platform} from 'react-native';

export interface IAccessibilityProps {
  // android
  accessible?: boolean;
  accessibilityLabel?: string;
  // web > converts to id attribute
  nativeID?: string;
  // ios
  testID?: string;
}

export const testID = (id: string) => {
  switch (Platform.OS) {
    case 'android':
      return {accessible: true, accessibilityLabel: id, testID: id};

    case 'web':
      return {nativeID: id};

    default:
      return {testID: id};
  }
};
