import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {
  HStack,
  Icon,
  View,
  VStack,
  Text,
  Pressable,
  Spacer,
  FormControl,
  Input,
  IconButton,
  Button,
  FlatList,
  Spinner,
} from 'native-base';
import { Collapse, Tooltip } from 'antd';
import PAMISearch, {
  PAMISearchType,
} from '../../../../../../common/PAMISearch/PAMISearch';
import AntIcon from 'react-native-vector-icons/AntDesign';
import Feather from 'react-native-vector-icons/Feather';
import {
  IAddOrUpdateImmunizationsState,
  IImmunization,
  IImmunizationComponentValue,
} from '../interfaces';
import {
  getDateStrFromFormat,
  getDisplayMonths,
  getMomentObj,
  getMomentObjectWithDateStringAndFormat,
  getMomentObjFromFormat,
  isFutureDate,
} from '../../../../../../../utils/DateUtils';

import {useIntl} from 'react-intl';
import {CommonDataContext} from '../../../../../../../context/CommonDataContext';
import {
  FormContext,
  FormViewType,
  IFormCommonData,
  IFormComponentProps,
} from '../../CustomWrapper/CustomWrapper';
import {
  fhirAddOrUpdate,
  getResourceContentWithHeaders,
  acceptIgnorePatientReportedRecords,
  deleteResource,
} from '../../../../../../../services/CommonService/AidBoxService';
import {
  getFHIRTemplate,
  getImmunizationName,
  getRecordListFormatted,
  getSampleData,
  isInvalid,
} from './AddOrUpdateImmunizationsHelper';
import FormComponentLoader from '../../CustomWrapper/FormComponentLoader/FormComponentLoader';
import {IKeyOperation} from '../../../../../../../Interfaces';
import {FHIR_RESOURCE, PatientRecordActions, RESOURCE_BLOCK_MESSAGE_ID} from '../../../../../../../constants/FhirConstant';
import {
  ADD_UPDATE_EVENTS,
  componentKeys,
  FormError,
  getHeaderButtons,
  tooltipInnerOverlayStyle,
  tooltipOverlayStyle,
  getSectionNoteDetails,
  getHeaderViewAllButton,
} from '../../CustomWrapper/CustomComponentHelper';
import FormComponentError from '../../CustomWrapper/FormComponentError/FormComponentError';
import {v4 as uuidv4} from 'uuid';
import {Colors} from '../../../../../../../styles';
import SectionLoadingIndicator from '../../CustomWrapper/SectionLoadingIndicator/SectionLoadingIndicator';
import CustomDetailTableView, {
  ICustomDetailColumn,
} from '../../CustomWrapper/CustomDetailTableView/CustomDetailTableView';
import {DatePicker, Drawer, Popover, notification, InputNumber} from 'antd';
import {BUTTON_TYPE, DISPLAY_DATE_FORMAT, HTTP_ERROR_CODE} from '../../../../../../../constants';
import FHIRPractitionerSearch from '../../../../../../common/FHIRPractitionerSearch/FHIRPractitionerSearch';
import {cloneDeep, debounce} from 'lodash';
import {DetailViewCollapse} from '../../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailViewCollapse';
import {NoImmunizationsSvg} from '../../../../../../common/Svg';
import {EventBus} from '../../../../../../../utils/EventBus';
import ImmunizationIconSvg from '../../../../../../common/Svg/ImmunizationIconSvg';
import CompactListViewMoreDetailsForImmunization from '../../CustomWrapper/CompactListView/CompactListViewMoreDetailsForImmunization';
import CustomButton from '../../CustomWrapper/CustomButton/CustomButton';
import { DisplayText } from '../../../../../../common/DisplayText/DisplayText';
import ModalActionBtn from '../../../../../../common/ModalActionBtn/ModalActionBtn';
import PatientReportedTableView from '../../CustomWrapper/PatientReportedTableView/PatientReportedTableView';
import { InputType } from '../../../../../../../utils/capabilityUtils';
import { ICodeableParam } from '../../Conditions/interfaces';
import { ImmunizationFields, filterAndSkipDuplicates } from '../../CustomComponentUtils';
import { useContainerDimensions } from '../../../../../../CustomHooks/ContainerDimensionHook';
import { ModalActionTitle } from '../../../../../../common/ModalActionTitle/ModalActionTitle';
import { CUSTOM_COMPONENT_STYLE } from '../../constants';
import {FoldButton} from '../../../../../../CommonComponents/FoldButton/FoldButton';
import DetailPreview, { ICustomComponentPreviewData } from '../../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailPreview/DetailPreview';
import { RightOutlined } from '@ant-design/icons';
import SectionLevelDetails from '../../CustomWrapper/SectionLevelDetails/SectionLevelDetails';
import { IDeleteConfirmation, IFormValidationOutput } from '../../CustomWrapper/interfaces';
import PatientIntakeDeleteModal from '../../../../../../common/PatientIntakeDeleteModal/PatientIntakeDeleteModal';
import { getOrganizationCapabilites } from '../../../../../TeamInbox/Conversations/MessagingContactDetails/ContactDetailsTabs/ActivityTimeLine/ActivityAdditionalInfo/CommonComponent/FormResponse/FormUtils';
import useBulkActionPatientReportedRecords from '../../../../../../CustomHooks/useBulkActionPatientReportedRecords';
import PatientIntakeConfirm from '../../../../../../common/PatientIntakeConfirm/PatientIntakeConfirm';
import RecentReportedViewHeader from '../../../../../../common/RecentReportedViewHeader/RecentReportedViewHeader';
import { getAcceptedAndIgnoredRecordNames } from '../../../../../../common/PatientIntakeConfirm/utils';
import { renderSectionBulletList } from '../../../../../../common/CommonUtils/CommonUtils';
import usePractitioners from '../../../../../../common/FHIRPractitionerSearch/usePractitioners';
import { reactStyles } from '../../../../Analytics/style';

const AddOrUpdateImmunizations = (props: IFormComponentProps) => {
  const { Panel } = Collapse;
  const intl = useIntl();
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const isPatientForm = contextData.formContext === FormContext.patientForm;
  const isPatientNote = contextData.formContext === FormContext.patientNotes;
  const isCompactView = contextData.componentView === FormViewType.compact;
  const isPatientProfile = contextData.formContext === FormContext.patientProfile;
  const isRecentReported = contextData.formContext === FormContext.recentReportedView;
  const isPreviewMode = contextData.isPreviewMode;
  const isAllowToShare = props?.component?.allowToShare != undefined &&  props?.component?.allowToShare === false ? false : true;
  const componentRef = useRef();
  const drawerDetailRef = useRef();
  const { width } = useContainerDimensions(componentRef);
  const { width: drawerDetailWidth, resetDimension } = useContainerDimensions(drawerDetailRef);
  const isMobileScreen = isCompactView ? drawerDetailWidth <= 480 : width <= 480;
  const [componentValue, setComponentValue] =
    useState<IImmunizationComponentValue>(
      props.component?.selectedValue || {immunizations: []}
    );
  const bulkAction = useBulkActionPatientReportedRecords();
  const [componentState, setComponentState] =
    useState<IAddOrUpdateImmunizationsState>({
      loading: false,
      formError: contextData.formError,
      collapsed: contextData.defaultCollapse,
      showSavingIndicator: false,
      editModalVisible: false,
      editModalCollapse: false,
      showPatientReportedRecords: false,
      selectedPatientReportedRecord: undefined,
      selectedItemToMap: undefined,
      patientReported: [],
      sectionNote: '',
      activePanels: [],
    });
  const [importFromChartLoading, setImportFromChartLoading] = useState<boolean>(false);
  const [invalidRecordMap, setInvalidRecordMap] = useState(new Map());
  const getFieldCapabilities = (): IKeyOperation | undefined => {
    return contextData.capabilities?.abilities?.keyAllowedOperations;
  };

  const [deleteConfirmation, setDeleteConfirmation] = useState<IDeleteConfirmation>({
    modalVisible:false,
    selectedEntry: undefined,
  });
  const cancelDeletePrompt = () =>
    setDeleteConfirmation((prev) => ({
      ...prev,
      modalVisible: false,
      selectedEntry: undefined,
    }));

  const occurrenceDateFormat =
    getFieldCapabilities()?.occurrenceDateTime?.format;

  const validateData = (currentData: IImmunizationComponentValue): IFormValidationOutput => {
    // If no patient id is not found means it's lead and for lead, the section is not applicable. So validation should always return true
    if (componentState.formError === FormError.noPatientIdFound) {
      return {isValid: true, message: ''};
    }
    const hasMissingData = currentData?.immunizations?.some((item) => {
      return isInvalidRecord(item);
    });
    return { isValid: !hasMissingData, message: hasMissingData ? `${props.component.label}: Please fill all the mandatory fields` : '' };
  };

  const isInvalidRecord = (item: IImmunization) => {
    const config = getFieldCapabilities();
    return (
      isInvalid(ImmunizationFields.occurrenceDateTime, item, true, config) ||
      isInvalid(ImmunizationFields.performer, item, true, config) ||
      isInvalid(ImmunizationFields.doseQuantity, item, true, config) ||
      isInvalid(ImmunizationFields.doseQuantityUnit, item, true, config)
    );
  };

  const getDefaultDoseQuantityUnit = () => {
    return getFieldCapabilities()?.doseQuantityUnit?.defaultValue || '';
  };

  const canAddNew = (): boolean => {
    // GLOBAL CHECK FROM EHR SETTINGS
    if(isPatientProfile && organizationCapability?.canCreate === false){
        return false
    }
    return (
      contextData.isBuilderMode ||
      ((contextData.capabilities?.abilities?.allowedOperations?.canCreate ||
        false) &&
        !props.disabled)
    );
  };
  const organizationCapability = getOrganizationCapabilites(
    contextData.organizationCapability,
    FHIR_RESOURCE.CLINICAL_DATA
  );
  const canUpdate = (): boolean => {
    // GLOBAL CHECK FROM EHR SETTINGS
    if(isPatientProfile && organizationCapability?.canUpdate === false){
      return false
    }
    return (
      (contextData.capabilities?.abilities?.allowedOperations?.canUpdate ||
        false) &&
      !props.disabled &&
      !isPatientForm
    );
  };

  const canDelete = (): boolean => {
    // GLOBAL CHECK FROM EHR SETTINGS
    if(isPatientProfile && organizationCapability?.canDelete === false){
      return false
    }
    return (
      contextData.capabilities?.abilities?.allowedOperations?.canDelete || false
    );
  };

  const onDetailChange = (data: IImmunization, index: number) => {
    onRecordChange(data);
    setComponentValue((prev) => {
      const list = prev.immunizations;
      list[index] = data;
      return {
        ...prev,
        immunizations: list,
      };
    });
  };
  const removeFromLocal = (uniqueId?: string) => {
    const index = componentValue.immunizations.findIndex(
      (item) => item.uniqueId === uniqueId
    );
    componentValue.immunizations.splice(index, 1);
    setComponentValue((prev) => ({
      ...prev,
      immunizations: [...componentValue.immunizations],
    }));
  }
  const onDelete = (data: IImmunization, index: number,isLocalDelete:boolean) => {
    if (!isLocalDelete && data.id) {
      deleteResource(
        FHIR_RESOURCE.IMMUNIZATION,
        `${data.id}`,
        !contextData.hasLoggedInContext,
        contextData.headers,
        {
          patientId: contextData.patientId,
          locationId: contextData.locationId,
          primarySource: data.primarySource,
        },
        (response) => {
          if (response?.success) {
            removeFromLocal(data?.uniqueId);
            broadcastUpdateEvent();
            cancelDeletePrompt();
          }
        },
        (error) => {

          cancelDeletePrompt();
          setComponentState((prev) => ({
            ...prev,
            loading: false,
            showSavingIndicator: false,
            formError: FormError.existingDataAPIFail,
          }));
        }
      );
    } else {
      removeFromLocal(data?.uniqueId);
      broadcastUpdateEvent();
      cancelDeletePrompt();
    }
  };

  const isFieldEnabled = (field: ImmunizationFields) => {
    if (props.component?.enabledFields) {
      return props.component?.enabledFields[field];
    }
    return IsFieldVisible(field);
  };

  const IsFieldVisible = (field: ImmunizationFields) => {
    const config = getFieldCapabilities();
    let isDisplayField = false;
    switch(field) {
      case ImmunizationFields.doseQuantity:
        if (config && config['doseQuantity']) {
          isDisplayField = config['doseQuantity']?.isHidden ? false : true;
        }
        break;
      case ImmunizationFields.doseQuantityUnit:
        if (config && config['doseQuantityUnit']) {
          isDisplayField = config['doseQuantityUnit']?.isHidden ? false : true;
        }
        break;
      case ImmunizationFields.expirationDate:
        if (config && config['expirationDate']) {
          isDisplayField = config['expirationDate']?.isHidden ? false : true;
        }
        break;
      case ImmunizationFields.immunization:
        if (config && config['immunization']) {
          isDisplayField = config['immunization']?.isHidden ? false : true;
        }
        break;
      case ImmunizationFields.lotNumber:
        if (config && config['lotNumber']) {
          isDisplayField = config['lotNumber']?.isHidden ? false : true;
        }
        break;
      case ImmunizationFields.manufacturer:
        if (config && config['manufacturer']) {
          isDisplayField = config['manufacturer']?.isHidden ? false : true;
        }
        break;
      case ImmunizationFields.occurrenceDateTime:
        if (config && config['occurrenceDateTime']) {
          isDisplayField = config['occurrenceDateTime']?.isHidden ? false : true;
        }
        break;
      case ImmunizationFields.performer:
        if (config && config['performer']) {
          isDisplayField = config['performer']?.isHidden ? false : true;
        }
        break;
      case ImmunizationFields.reasonCode:
        if (config && config['reasonCode']) {
          isDisplayField = config['reasonCode']?.isHidden ? false : true;
        }
        break;
      case ImmunizationFields.vaccineCode:
        if (config && config['vaccineCode']) {
          isDisplayField = config['vaccineCode']?.isHidden ? false : true;
        }
        break;
    }
    return isDisplayField;

  }

  const isPractitionerEnabled = !!isFieldEnabled(ImmunizationFields.performer);

  const practitionerData = usePractitioners({
    skip: !isPractitionerEnabled || !!isPreviewMode,
    hasLoggedInContext: contextData.hasLoggedInContext,
    additionalHeaders: contextData.headers,
    locationId: contextData?.locationId
  });

  const addNewItem = (item: ICodeableParam) => {
    const date = occurrenceDateFormat
      ? getDateStrFromFormat(new Date(), occurrenceDateFormat)
      : getMomentObj(new Date()).toISOString();

    const recordedDate = getMomentObj(new Date()).toISOString();
    const isFreeTextItem = item.coding?.length === 0;

    const data: IImmunization = {
      immunization: item,
      // statusCode: IMMUNIZATION_STATUS_CODE.COMPLETED,
      name: getImmunizationName(item),
      occurrenceDateTime: date,
      recordedDate,
      primarySource: !isFreeTextItem,
      doseQuantity: '',
      doseQuantityUnit: getDefaultDoseQuantityUnit(),
      uniqueId: uuidv4(),
      isFreeTextRecord: false,
    };
    componentValue.immunizations.splice(0, 0, data);
    onRecordChange(data);
    setComponentValue({
      immunizations: [...componentValue.immunizations],
    });
  };

  const onRecordChange = (record: IImmunization) => {
    const isUpdateOperation = !!record.id;
    const shouldAllowAddUpdate = isUpdateOperation ? canUpdate() : canAddNew();
    if (contextData.backgroundSaveEnabled && shouldAllowAddUpdate) {
      addOrUpdateRecord(record);
    }
  };

  const isFieldDisabled = (item: IImmunization) => {
    return props.disabled || (!canUpdate() && !!item.id);
  };

  const isInvalidFieldData = (
    field: ImmunizationFields,
    item: IImmunization
  ) => {
    return isInvalid(field, item, true, getFieldCapabilities());
  };

  const isOpenChoice = () => {
    const fields = getFieldCapabilities();
    return fields?.vaccineCode?.inputType == InputType.openChoice;
  }

  const getSearchComponent = (fullWidth?: boolean): JSX.Element | undefined => {
    return (
      <Pressable
        width={'100%'}
        paddingRight={fullWidth ? 6 : 0}
        onPress={(event) => event.stopPropagation()}
      >
        <View
          flex={1}
          style={!isMobileScreen ? {width: fullWidth ? '100%' : 350} : {}}
        >
          <PAMISearch
            addNewOptionEnabled={isPatientForm || contextData.isBuilderMode || isOpenChoice()}
            clearOnSelection
            searchType={PAMISearchType.immunization}
            additionalHeaders={contextData.headers}
            placeholder="Search and add new immunization"
            isShowError={false}
            onChange={(value) => {
              if (value) {
                addNewItem(value);
              }
            }}
            locationId={contextData.locationId}
          />
        </View>
      </Pressable>
    );
  };

  const getHeaderRightView = (isCompact: boolean): JSX.Element | undefined => {
    if (!canAddNew()) {
      if (isCompact) {
        return getHeaderViewAllButton(() => {
          setComponentState((prev) => ({...prev, editModalVisible: true}));
        }, 'View all');
      } else {
        return;
      }
    }


    if (isCompact) {
      return getHeaderButtons(() => {
        setComponentState((prev) => ({...prev, editModalVisible: true}));
      });
    } else if (!isMobileScreen) {
      return (
        <HStack space={2} alignItems="center">
          <Spacer />
          {componentState.showSavingIndicator && <SectionLoadingIndicator />}
        </HStack>
      );
    }
  };
  const canShowDeleteBtn = (itemId?: string): boolean => {
    if(isPatientNote){
      return true;
    }
    if (isPatientForm) {
      return !itemId;
    }
    return canDelete() || contextData.isBuilderMode;
  };
  const invokeDeleteConfirmation = (item:IImmunization,index:number) => {
    setDeleteConfirmation(prev => ({
      ...prev,
      modalVisible: true,
      selectedEntry: {
        item: item,
        index: index
      }
    }))
   }
const getDeleteFunction = (item: IImmunization, index: number) => {
  const isLocalDelete =
    (isPatientForm && !item.id) ||
    contextData.isBuilderMode ||
    !contextData.backgroundSaveEnabled;

  if (isLocalDelete) {
    onDelete(item, index, true);
  } else if (canDelete()) {
    invokeDeleteConfirmation(item,index)
  }
};
  const getColumns = (): ICustomDetailColumn[] => {
    const fields = getFieldCapabilities();
    const columns: ICustomDetailColumn[] = [
      {
        title: intl.formatMessage({id: 'name'}),
        flex: 3,
        render: (item: IImmunization) => {
          return <Text>{item.name}</Text>;
        },
      },
    ];
    if (isFieldEnabled(ImmunizationFields.occurrenceDateTime)) {
      const isOnlyYearFormat = occurrenceDateFormat === 'YYYY';
      const label = intl.formatMessage({id: 'dateOccurred'});
      columns.push({
        title: label,
        flex: 2,
        render: (item: IImmunization, index: number) => {
          if (!item.primarySource && fields?.vaccineCode?.hideFieldsForFreeText) {
            return <Text>{'-'}</Text>;
          }
          const isDisabled = isFieldDisabled(item);
          const isInvalidOccurrenceDateTime =
            invalidRecordMap.get(item.uniqueId) &&
            getFieldCapabilities()?.occurrenceDateTime?.isRequired &&
            isInvalidFieldData(ImmunizationFields.occurrenceDateTime, item);
          return (
            <DatePicker
              picker={isOnlyYearFormat ? 'year' : 'date'}
              disabled={isDisabled}
              placeholder={label}
              format={
                isOnlyYearFormat ? occurrenceDateFormat : DISPLAY_DATE_FORMAT
              }
              disabledDate={(current: any) => {
                return current && isFutureDate(current);
              }}
              value={
                item.occurrenceDateTime
                  ? getMomentObjectWithDateStringAndFormat(
                      item.occurrenceDateTime,
                      occurrenceDateFormat || ''
                    )
                  : undefined
              }
              style={reactStyles.height40px}
              className={isInvalidOccurrenceDateTime && !isDisabled ? 'field-error' : ''}
              onChange={(value) => {
                item.occurrenceDateTime = occurrenceDateFormat
                  ? value?.format(occurrenceDateFormat)
                  : value?.toISOString();
                onDetailChange(item, index);
              }}
            />
          );
        },
      });
    }
    if (isFieldEnabled(ImmunizationFields.performer)) {
      columns.push({
        title: intl.formatMessage({id: 'practitioner'}),
        flex: 2,
        render: (item: IImmunization, index: number) => {
          if (!item.primarySource && fields?.vaccineCode?.hideFieldsForFreeText) {
            return <Text>{'-'}</Text>;
          }
          const isDisabled = isFieldDisabled(item);
          const isInvalidPerformer =
            invalidRecordMap.get(item.uniqueId) &&
            getFieldCapabilities()?.performer?.isRequired &&
            isInvalidFieldData(ImmunizationFields.performer, item);
          return (
            <FHIRPractitionerSearch
              value={item.practitioner}
              data={practitionerData?.data}
              loading={practitionerData?.loading}
              isShowError={isInvalidPerformer && !isDisabled}
              disabled={isDisabled}
              onChange={(value) => {
                item.practitioner = value;
                onDetailChange(item, index);
              }}
            />
          );
        },
      });
    }
    if (isFieldEnabled(ImmunizationFields.doseQuantity)) {
      const label = intl.formatMessage({id: 'doseQuantity'});
      columns.push({
        title: label,
        flex: 1,
        render: (item: IImmunization, index: number) => {
          if (!item.primarySource && fields?.vaccineCode?.hideFieldsForFreeText) {
            return <Text>{'-'}</Text>;
          }
          const isDisabled = isFieldDisabled(item);
          const isInvalidDoseQuantity =
            invalidRecordMap.get(item.uniqueId) &&
            getFieldCapabilities()?.doseQuantity?.isRequired &&
            isInvalidFieldData(ImmunizationFields.doseQuantity, item);
          return (
            <InputNumber
              min='0'
              value={item.doseQuantity}
              status={isInvalidDoseQuantity && !isDisabled ? "error" : ""}
              disabled={isDisabled}
              style={reactStyles.inputNumberStyle}
              onChange={(value) => {
                item.doseQuantity = value || undefined;
                onDetailChange(item, index);
              }}
            />
          );
        },
      });
    }
    if (isFieldEnabled(ImmunizationFields.doseQuantityUnit)) {
      const label = intl.formatMessage({id: 'doseQuantityUnit'});
      columns.push({
        title: label,
        flex: 1,
        render: (item: IImmunization, index: number) => {
          if (!item.primarySource && fields?.vaccineCode?.hideFieldsForFreeText) {
            return <Text>{'-'}</Text>;
          }
          const isDisabled = isFieldDisabled(item);
          const isInvalidDoseQuantityUnit =
            invalidRecordMap.get(item.uniqueId) &&
            getFieldCapabilities()?.doseQuantityUnit?.isRequired &&
            isInvalidFieldData(ImmunizationFields.performer, item);
          return (
            <FormControl isInvalid={isInvalidDoseQuantityUnit && !isDisabled}>
              <Input
              _focus={{borderColor: '#D0D5DD'}}
                defaultValue={item.doseQuantityUnit}
                isReadOnly={isDisabled}
                onChangeText={debounce((value) => {
                  item.doseQuantityUnit = value;
                  onDetailChange(item, index);
                }, 500)}
                autoComplete={"off"}
              />
            </FormControl>
          );
        },
      });
    }
    const actionsAvailable = canShowDeleteBtn();
    if (!props.disabled && actionsAvailable) {
      columns.push({
        title:  intl.formatMessage({id: 'actions'}),
        flex: 0.5,
        render: (item: IImmunization, index: number) => {
          return (
            <HStack>
              <Spacer />
              {/* Patient Profile => API DELETE  => trash-btn*/}
              {canShowDeleteBtn(item.id) ? (
                <Pressable
                  marginTop={-1}
                  onPress={() => getDeleteFunction(item, index)}
                  borderWidth={0.3}
                  backgroundColor={Colors.Custom.Base50}
                  borderColor={Colors.Custom.ActionBtnBorder}
                  px={2}
                  py={1}
                  rounded="full"
                  shadow={2}
                >
                  <Feather
                    name={
                      canDelete() || contextData.isBuilderMode ? 'trash-2' : 'x'
                    }
                    size={20}
                    color={Colors.Custom.PurpleColor}
                  />
                </Pressable>
              ) : null}
            </HStack>
          );
        },
      });
    }
    return columns;
  };

  const getSectionAdditionalView = (isCompactView: boolean) => {
    const fieldCapabilities = getFieldCapabilities();
    if (fieldCapabilities?.sectionNote) {
      return (
        <VStack paddingX={isCompactView ? 4 : 0} paddingTop={isCompactView ? 3 : 1} paddingBottom={isCompactView ? 2 : 0}>
          <SectionLevelDetails
            organizationCapabilites={organizationCapability}
            patientId={contextData.patientId || ''}
            accountLocationId={contextData.locationId}
            fieldCapabilities={fieldCapabilities}
            resource={FHIR_RESOURCE.IMMUNIZATION}
            note={componentState.sectionNote || ''}
            onChange={(text) => setComponentState(prev => ({...prev, sectionNote: text}))}
          />
        </VStack>
      );
    }
    return;
  }

  const getDetailView = (isCompact: boolean, isPatientReported: boolean): JSX.Element => {
    if (!componentValue.immunizations.length && !isCompact && !isPatientReported) {
      return (
        <VStack paddingBottom={5} alignItems={'center'}>
          <NoImmunizationsSvg titleId="noImmunizationList" />
          <HStack space={2} alignItems="center">
            <Spacer />
            {componentState.showSavingIndicator && <SectionLoadingIndicator />}
            {canAddNew() && getSearchComponent()}
          </HStack>
        </VStack>
      );
    }

    if (isCompact) {
      return (
        <>
        <CompactListViewMoreDetailsForImmunization
          hasPatientReportedRecords={componentState.patientReported.length > 0}
          onViewPatientReportedClick={() => {
            setComponentState((prev) => ({...prev, showPatientReportedRecords: true}));
          }}
          dataSource={componentValue.immunizations}
          noDataTextLabel={'noUpcomingImmunizationList'}
          viewDataText={'Administered Immunization'}
          onViewMoreClick={() => {
            setComponentState((prev) => ({...prev, editModalVisible: true}));
          }}
          occurrenceDateFormat={occurrenceDateFormat}
          showMoreMaxCount={contextData.showMoreMaxCount}
          rowViewForItem={(item: IImmunization) => {
            let displayDate = '';

            if (item.occurrenceDateTime) {
              const date = getMomentObjectWithDateStringAndFormat(
                item.occurrenceDateTime,
                occurrenceDateFormat || ''
              );

              /**
               * Difference in Months
               */
              displayDate = getDisplayMonths(
                getMomentObjFromFormat(date, DISPLAY_DATE_FORMAT),
                getMomentObjFromFormat(new Date(), DISPLAY_DATE_FORMAT)
              );
            }
            return (
              <VStack key={item.id}>
                <Text color={Colors.Custom['Gray900']}>
                  {!displayDate ? item.name : `${item.name} (${displayDate})`}
                </Text>
                {/* <HStack space={2} alignItems="center" marginBottom={-1}>
                  {displayDate && (
                    <Text size={'smMedium'} color={Colors.Custom['Gray500']}>
                      {displayDate}
                    </Text>
                  )}
                </HStack> */}
              </VStack>
            );
          }}
        />
        {getSectionAdditionalView(true)}
        </>
      );
    } else if (isPatientReported) {
      return (
        <PatientReportedTableView
          dataSource={componentState.patientReported}
          rowViewForItem={(item: IImmunization, index: number) => {
            let displayDate = '';

            if (item.occurrenceDateTime) {
              const date = getMomentObjectWithDateStringAndFormat(
                item.occurrenceDateTime,
                occurrenceDateFormat || ''
              );

              /**
               * Difference in Months
               */
              displayDate = getDisplayMonths(
                getMomentObjFromFormat(date, DISPLAY_DATE_FORMAT),
                getMomentObjFromFormat(new Date(), DISPLAY_DATE_FORMAT)
              );
            }

            return (
              <VStack key={item.id}>
                <HStack space={1} alignItems="start">
                  <VStack space={1} flex={1}>
                    <Text color={Colors.Custom['Gray900']} width="full">
                      {!displayDate
                        ? item.name
                        : `${item.name} (${displayDate})`}
                    </Text>
                  </VStack>
                  {organizationCapability?.canCreate && (
                    <HStack space={2}>
                      {item.isFreeTextRecord && (
                          <Popover
                            key={item.id || index}
                            overlayInnerStyle={{
                              bottom: 3,
                              borderRadius: 16,
                              padding: 0,
                            }}
                            content={
                              <View minWidth={300} maxW={400} padding={2}>
                                <VStack space={8}>
                                  <Text size="xlMedium">
                                    Match to Immunization
                                  </Text>
                                  <VStack space={1}>
                                    <DisplayText
                                      textLocalId={'immunization'}
                                      size="smMedium"
                                    />
                                    <PAMISearch
                                      searchType={PAMISearchType.immunization}
                                      additionalHeaders={contextData.headers}
                                      placeholder="Search Immunization"
                                      isShowError={false}
                                      onChange={(value) => {
                                        setComponentState((prev) => ({
                                          ...prev,
                                          selectedItemToMap: value,
                                        }));
                                      }}
                                      locationId={contextData.locationId}
                                    />
                                  </VStack>
                                  <HStack>
                                    <Spacer />
                                    <Button.Group space={2}>
                                      <FoldButton
                                        nativeProps={{
                                          variant: BUTTON_TYPE.PRIMARY,
                                          onPress: () => {
                                            setComponentState((prev) => {
                                              const list = prev.patientReported;
                                              const index = list.findIndex(
                                                (value) =>
                                                  value.uniqueId ===
                                                  item.uniqueId
                                              );
                                              if (index > -1) {
                                                list[index].immunization =
                                                  componentState.selectedItemToMap;
                                                list[index].primarySource =
                                                  true;
                                                list[index].name =
                                                  getImmunizationName(
                                                    componentState.selectedItemToMap
                                                  );
                                                list[index].isFreeTextRecord = false;
                                              }
                                              return {
                                                ...prev,
                                                patientReported: list,
                                                selectedItemToMap: undefined,
                                                selectedPatientReportedRecord:
                                                  undefined,
                                              };
                                            });
                                          },
                                          isDisabled:
                                            !componentState.selectedItemToMap,
                                        }}
                                        customProps={{
                                          btnText: intl.formatMessage({
                                            id: 'continue',
                                          }),
                                          withRightBorder: false,
                                        }}
                                      ></FoldButton>
                                    </Button.Group>
                                  </HStack>
                                </VStack>
                              </View>
                            }
                            title=""
                            trigger="click"
                            visible={
                              componentState.selectedPatientReportedRecord ==
                              item.id
                            }
                            onVisibleChange={(visible) => {
                              setComponentState((prev) => ({
                                ...prev,
                                selectedPatientReportedRecord: visible
                                  ? item.id
                                  : undefined,
                              }));
                            }}
                            placement={'bottom'}
                          >
                            <CustomButton
                              title="Match to Immunization"
                              isDisabled={componentState.showSavingIndicator}
                              onPress={() => {
                                setComponentState((prev) => ({
                                  ...prev,
                                  selectedPatientReportedRecord: item.id,
                                }));
                              }}
                            />
                          </Popover>
                        )}
                      <CustomButton
                        title="Accept"
                        isDisabled={componentState.showSavingIndicator}
                        onPress={() => {
                          const isCurrentFreeText =
                            !item.immunization?.coding?.length;
                          if (isCurrentFreeText && !isOpenChoice()) {
                            notification.error({
                              message:
                                'This free text immunization must be matched to database before it can be added to EHR',
                            });
                          } else {
                            handlePatientReportedActions(
                              PatientRecordActions.accept,
                              item
                            );
                          }
                        }}
                      />
                      <CustomButton
                        title="Discard"
                        isDisabled={componentState.showSavingIndicator}
                        onPress={() => {
                          handlePatientReportedActions(
                            PatientRecordActions.deny,
                            item
                          );
                        }}
                      />
                    </HStack>
                  )}
                </HStack>
              </VStack>
            );
          }}
        />
      );
    }

    return (
      <VStack flex={1} space={4}>
        <VStack space={2}>
          {canAddNew() && !isMobileScreen && (
            <HStack width={'100%'} margin={3} space={2} alignItems="center">
              {getSearchComponent(true)}
            </HStack>
          )}
          <CustomDetailTableView
            dataSource={componentValue.immunizations}
            isPatientNote={isPatientNote}
            isBuilderMode={contextData.isBuilderMode}
            columns={getColumns()}
          />
        </VStack>
      </VStack>
    );
  };

  const onAddOrUpdateError = (error: any) => {
    const errorMessage =
      error?.response?.status === HTTP_ERROR_CODE.METHOD_NOT_ALLOWED
        ? intl.formatMessage({
            id: RESOURCE_BLOCK_MESSAGE_ID,
          })
        : error?.response?.data?.message;
    setComponentState((prev) => ({
      ...prev,
      showSavingIndicator: false,
      ...(errorMessage && {customError: errorMessage}),
      ...(!errorMessage && {nonBlockingFormError: FormError.addUpdateFail}),
    }));
  }

  const addOrUpdateRecord = (record: IImmunization) => {
    if (!isInvalidRecord(record)) {
      setComponentState((prev) => ({...prev, showSavingIndicator: true}));
      const fhirData = getFHIRTemplate({
        ...record,
        patientId: contextData.patientId || '',
        tenantId: contextData.tenantId || '',
      });
      fhirAddOrUpdate(
        FHIR_RESOURCE.IMMUNIZATION,
        record.id,
        fhirData,
        {
          patientId: contextData.patientId,
          locationId: contextData.locationId,
        },
        (response) => {
          broadcastUpdateEvent();
          let keepShowingSavingIndicator = false;
          if (!record.id) {
            keepShowingSavingIndicator = true;
            getExistingData({patientId: contextData.patientId || '', skipLoader: true});
          }
          if (response?.data?.id) {
            updateDataFromAPIToList(response.data, record);
          }
          setComponentState((prev) => ({
            ...prev,
            ...(!keepShowingSavingIndicator && {showSavingIndicator: false}),
            nonBlockingFormError: undefined,
          }));
        },
        (error) => {
          onAddOrUpdateError(error);
          // If new record is failed which is not saved before then only remove from local list
          if (!record.id) {
            removeFromLocal(record.uniqueId);
          }
        }
      );
    }
  };

  const handleSubmitAllAction = async () => {
    await bulkAction.handleBulkAction({
       resource: FHIR_RESOURCE.IMMUNIZATION,
       action: PatientRecordActions.accept,
       getFilteredRecords: () => {
         const structuredRecords = componentState.patientReported.filter(
           (record) => !record.isFreeTextRecord
         );
         const FHIRformatedRecords = structuredRecords.map((record) =>
           getFHIRTemplate({
             ...record,
             patientId: contextData.patientId || '',
             tenantId: contextData.tenantId || '',
           })
         );
         return FHIRformatedRecords;
       },
       patientId: contextData.patientId || '',
       accountLocationUuid: contextData.locationId || '',
       onComplete: (response) => {
        broadcastUpdateEvent();
        if (response?.data?.id || response?.data?.success) {
          getExistingData({patientId: contextData.patientId || '', skipLoader: true});
        }
       },
       onError: onAddOrUpdateError,
     });
   };

  const handlePatientReportedActions = (action: PatientRecordActions, record: IImmunization) => {
    setComponentState((prev) => ({...prev, showSavingIndicator: true}));
    const fhirData = getFHIRTemplate({
      ...record,
      patientId: contextData.patientId || '',
      tenantId: contextData.tenantId || '',
    });
    acceptIgnorePatientReportedRecords(
      FHIR_RESOURCE.IMMUNIZATION,
      action,
      record.id,
      fhirData,
      {
        patientId: contextData.patientId,
        locationId: contextData.locationId,
      },
      (response) => {
        // NOTE: This is done because when we send event from `Recent Clinical Event` the component view is not same as the current view. And is same when send event from PAMI component as well to handle this, we are sending the view type from the event itself in case of PAMI component so that the api call is made.
        const viewType = isRecentReported ? undefined : FormViewType.compact;
        broadcastUpdateEvent(viewType);
        let keepShowingSavingIndicator = false;
        if (response?.data?.id || response?.data?.success) {
          if (action === PatientRecordActions.accept) {
            keepShowingSavingIndicator = true;
            // Here we only remove the specific Accepted entry so that it doesnot un-match the other matched records.
            setComponentState((prev) => ({
              ...prev,
              patientReported: prev.patientReported.filter(
                (item) => item.id !== record.id
              ),
              showSavingIndicator: false
            }));
          } else {
            updatePatientReportedDataFromAPIToList(response?.data, record, action);
          }
        }
        setComponentState((prev) => ({
          ...prev,
          ...(!keepShowingSavingIndicator && {showSavingIndicator: false}),
          nonBlockingFormError: undefined,
        }));
      },
      onAddOrUpdateError
    );
  }

  const updateDataFromAPIToList = (data: any, record: IImmunization) => {
    if (record.id) return;

    setComponentValue((prev) => {
      const list = prev.immunizations;
      const index = list.findIndex(
        (value) => value.uniqueId === record.uniqueId
      );
      if (index > -1) {
        list[index].id = data.id;
      }
      return {
        ...prev,
        immunizations: list,
      };
    });
  };

  const updatePatientReportedDataFromAPIToList = (data: any, record: IImmunization, action: PatientRecordActions) => {
    setComponentState((prev) => {
      const list = prev.patientReported;
      const index = list.findIndex(
        (value) => value.uniqueId === record.uniqueId
      );
      if (index > -1) {
        list.splice(index, 1);
      }
      return {
        ...prev,
        patientReported: list,
        showPatientReportedRecords: list.length > 0
      };
    });
    if (action === PatientRecordActions.accept && data?.id) {
      setComponentValue((prev) => {
        const list = prev.immunizations;
        record.id = data.id;
        list.splice(0, 0, record);
        return {
          ...prev,
          immunizations: list,
        };
      });
    }
  };

  const getExistingData = (params: {patientId: string, skipLoader?: boolean, manualImport?: boolean}) => {
    const {patientId, skipLoader, manualImport} = params;
    if (!skipLoader) {
      setComponentState((prev) => ({...prev, loading: true}));
    }
    getResourceContentWithHeaders(
      FHIR_RESOURCE.IMMUNIZATION,
      `patient=${patientId}${isRecentReported ? `&patientReportedOnly=true` : ''}`,
      !contextData.hasLoggedInContext,
      contextData.headers,
      (response) => {
        handleExistingDataResponse(response, manualImport);
      },
      (error) => {

        setComponentState((prev) => ({
          ...prev,
          loading: false,
          showSavingIndicator: false,
          formError: FormError.existingDataAPIFail,
        }));
      },
      contextData.locationId,
      !isPatientForm
    );
  };


  const handleExistingDataResponse = (response: any,  manualImport?: boolean) => {
    if (isPatientForm) {
      if (response?.entry) {
        const formattedData = getRecordListFormatted(response.entry);
        const sectionNote = getSectionNoteDetails(response.meta?.extension || []).sectionNote;
        const newRecordsAddedInForm = props.component?.selectedValue?.immunizations || [];
        setComponentValue((prev) => ({
          ...prev,
          immunizations: [...newRecordsAddedInForm, ...formattedData],
        }));

        contextData?.updateFormPrefilledByFoldProgress?.(componentKeys.IMMUNIZATIONS, { immunizations: [ ...formattedData ]});

        setComponentState((prev) => ({
          ...prev,
          loading: false,
          sectionNote: sectionNote,
          showSavingIndicator: false,
          activePanels: formattedData?.length ? [componentKeys.IMMUNIZATIONS] : [],
          formError: !response?.entry
            ? FormError.existingDataAPIFail
            : prev.formError,
        }));
      }
    } else {
      const patientReportedRecords = response?.patientReported?.data?.entry || [];
      const ehrRecords = response?.resource?.data?.entry || [];
      const sectionNote = getSectionNoteDetails(response?.resource?.data?.meta?.extension || []).sectionNote;
      let patientReportedFormattedRecords: IImmunization[] = [];
      if (patientReportedRecords || ehrRecords) {
        let ehrFormattedRecords: IImmunization[] = [];
        if (patientReportedRecords?.length) {
          patientReportedFormattedRecords = getRecordListFormatted(patientReportedRecords);
        }
        if (ehrRecords?.length) {
          ehrFormattedRecords = getRecordListFormatted(ehrRecords);
          if (manualImport) {
            ehrFormattedRecords = filterAndSkipDuplicates<IImmunization>(
              componentValue.immunizations,
              ehrFormattedRecords
            );
          };
        }
        setComponentValue((prev) => ({
          ...prev,
          immunizations: [...ehrFormattedRecords]
        }));

        contextData?.updateFormPrefilledByFoldProgress?.(componentKeys.IMMUNIZATIONS, { immunizations: [ ...ehrFormattedRecords ]});
      }
      setImportFromChartLoading(false);
      setComponentState((prev) => ({
        ...prev,
        loading: false,
        sectionNote: sectionNote,
        showSavingIndicator: false,
        ...(!patientReportedFormattedRecords.length && {showPatientReportedRecords: false}),
        patientReported: patientReportedFormattedRecords,
        activePanels: ehrRecords?.length || patientReportedFormattedRecords?.length ? [componentKeys.IMMUNIZATIONS] : [],
        formError: !ehrRecords || !patientReportedRecords
          ? FormError.existingDataAPIFail
          : prev.formError,
      }));
    }
  }

  const broadcastUpdateEvent = (viewType?: FormViewType) => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.broadcastEvent(
      ADD_UPDATE_EVENTS.IMMUNIZATION,
      viewType || contextData.componentView
    );
  };

  const onEventReceived = useCallback(
    (data) => {
      if (data != contextData.componentView && contextData?.patientId && !isPatientNote) {
        getExistingData({patientId: contextData.patientId, skipLoader: true});
      }
    },
    [contextData]
  );

  const fetchImportData = () => {
    if (isPatientNote && !isPreviewMode && contextData.patientId) {
      setImportFromChartLoading(true);
      getExistingData({patientId: contextData.patientId, skipLoader: true, manualImport: true});
    }
  }

  useEffect(() => {
    if (isRecentReported) {
      contextData?.updateLoadingStatus?.(componentKeys.IMMUNIZATIONS, componentState.loading, !!componentState.patientReported.length);
      return;
    }
    const isDataAvailable = !!componentValue.immunizations.length || !!componentState.patientReported.length;
    contextData?.updateLoadingStatus?.(componentKeys.IMMUNIZATIONS, componentState.loading, isDataAvailable);
  }, [componentState.loading]);

  useEffect(() => {
    if (componentState.editModalVisible) {
      resetDimension();
    }
  }, [componentState.editModalVisible]);

  useEffect(() => {
    if (contextData.isBuilderMode) {
      setComponentValue(getSampleData());
      return;
    }
    const patientId = contextData?.patientId;
    if (patientId && !isPatientNote && (!props.component?.selectedValue || (isPatientForm && !props.disabled))) {
      getExistingData({patientId});
    }
    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener(ADD_UPDATE_EVENTS.IMMUNIZATION, onEventReceived);
    return () => {
      eventBus.removeEventListener(onEventReceived);
    };
  }, []);

  useEffect(() => {
    let list = componentValue.immunizations;
    if (!canUpdate()) {
      list = componentValue.immunizations.filter((item) => {
        return !item.id;
      });
    }
    props.onChange(cloneDeep({immunizations: list, total: componentValue.immunizations.length}));
  }, [componentValue]);

  props.validateRef.current = validateData;

  const {acceptRecord, ignoredRecords} =
  getAcceptedAndIgnoredRecordNames(componentState.patientReported);

  const isDataInvalid = () => {
    const { immunizations } = componentValue;
    const tempInvalidRecords = new Map();
    immunizations.forEach(record => {
      const isAnyFieldInvalid = isInvalidRecord(record);
      if (isAnyFieldInvalid) {
        tempInvalidRecords.set(record.uniqueId, true);
      }
    });
    setInvalidRecordMap(tempInvalidRecords);
    return (tempInvalidRecords.size > 0);
  }
  const onClose = () => {
    if (isDataInvalid()) {
      notification.destroy();
      notification.error({
        message: intl.formatMessage({
          id: 'mandatoryFieldError',
        }),
        duration: 2.0,
        placement: 'top'
      });
    }
    else {
      setComponentState((prev) => ({
        ...prev,
        editModalVisible: false,
      }));
    }
  }

  if (isPreviewMode) {
    return (
      <>
        {componentState.loading && (
          <View padding={4}>
            <FormComponentLoader />
          </View>
        )}
        {!componentState.loading && !componentState.formError && (
          <>
            {componentValue.immunizations.length > 0 && (
              <div className={`${isAllowToShare ? '' : 'disallow-to-share'}`}>
              <DetailPreview titleLocalId={props.component.label}>
                <FlatList
                  data={componentValue.immunizations}
                  initialNumToRender={(componentValue.immunizations || [])?.length}
                  keyExtractor={(item) => JSON.stringify(item)}
                  renderItem={({item}) => (
                    <div className="page-break">
                      <VStack my={0.5}>
                        <Text fontWeight={600}>{item.name}</Text>
                        <Text>
                          {item.occurrenceDateTime &&
                            `${intl.formatMessage({
                              id: 'dateOccurred',
                            })}: ${getDateStrFromFormat(
                              item.occurrenceDateTime,
                              DISPLAY_DATE_FORMAT
                            )}`}
                        </Text>
                      </VStack>
                    </div>
                  )}
                />
              </DetailPreview>
              </div>
            )}
          </>
        )}
      </>
    );
  }

  return (
    <View ref={componentRef}>
      {isCompactView ? (
        <VStack background={Colors.Custom.BackgroundColor}>
          <Collapse
            activeKey={componentState.activePanels}
            onChange={(activePanels) => {
              setComponentState((prev) => ({...prev, activePanels: activePanels as string[]}));
            }}
            expandIconPosition="end"
            expandIcon={({ isActive }) => {
            const tooltipText = isActive ? intl.formatMessage({id: 'collapse'}) : intl.formatMessage({id:'expand'});
            return (
              <Tooltip title={tooltipText} placement="bottom" showArrow={false} overlayStyle={tooltipOverlayStyle} overlayInnerStyle={tooltipInnerOverlayStyle}>
                <RightOutlined rotate={isActive ? 90 : 0} />
              </Tooltip>
              )}}
          style={reactStyles.collapseStyle5}>
            <Panel forceRender className='last-subheader' key={componentKeys.IMMUNIZATIONS} header={
              <HStack
                alignItems={'center'}
                marginRight={4}
                justifyContent="space-between"
              >
                <HStack alignItems={'center'} space={2}>
                  <View>
                    <ImmunizationIconSvg />
                  </View>
                  <Text
                    color={'#0e0333'}
                    fontWeight={600}
                    fontFamily={'Manrope-Bold'}
                    fontSize={18}
                  >
                    {props.component.leftSidebarLabel || props.component.label}
                  </Text>
                </HStack>
                <View>{getHeaderRightView(isCompactView)}</View>
              </HStack>}>
              {componentState.loading && (
                <View padding={4}>
                  <FormComponentLoader />
                </View>
              )}
              {!componentState.loading && componentState.customError && (
                 <FormComponentError error={FormError.custom} customError={componentState.customError} />
               )}
              {!componentState.loading && componentState.formError && (
                <FormComponentError error={componentState.formError} />
              )}
              {!componentState.loading && componentState.nonBlockingFormError && (
                <FormComponentError error={componentState.nonBlockingFormError} />
              )}
              {!componentState.loading &&
                !componentState.formError &&
                getDetailView(isCompactView, false)}
            </Panel>
          </Collapse>
        </VStack>
      ) : (
        <div className='page-break'>
        {isRecentReported ? (
          <RecentReportedViewHeader
            loading={componentState.loading}
            noData={!componentState.patientReported.length}
            title={props.component.label}
            buttonOnPress={() => {
              setComponentState((prev) => ({
                ...prev,
                showPatientReportedRecords: true,
              }));
            }}
          />
        ) :
        <DetailViewCollapse
          btnList={[
            {
              icon: importFromChartLoading ? (
                <Spinner mr={1.5} />
              ) : (
                <Feather
                  name="download"
                  size={20}
                  color={Colors.Custom.mainPrimaryPurple}
                />
              ),
              onIconBtnClick: fetchImportData,
              label: intl.formatMessage({id: 'importFromChart'}),
              tooltipLabel: intl.formatMessage({id: 'importFromChartOverrideInfo'}),
              isCustomButton: true,
              hide: !(isPatientNote && !isPreviewMode),
              disabled: importFromChartLoading,
            }
          ]}
          textLocalId={props.component.label}
          toggled={!componentState.collapsed}
          headerRightView={getHeaderRightView(isCompactView)}
          headerStyle={{marginTop: 3}}
          extraTextStyles={
            isPatientNote ? {fontWeight: 'bold', fontSize: 16} : {}
          }
          extraPanelStyles={
            isPatientNote
              ? {borderColor: '#D0D5DD', ...CUSTOM_COMPONENT_STYLE}
              : {}
          }
          onToggled={() => {
            setComponentState((prev) => ({
              ...prev,
              collapsed: !prev.collapsed,
            }));
          }}
        >
          <VStack>
            {canAddNew() &&
              isMobileScreen &&
              componentValue.immunizations.length > 0 && (
                <VStack marginY={4} space={2} flex={1} marginX={2}>
                  {getSearchComponent()}
                  {componentState.showSavingIndicator && (
                    <SectionLoadingIndicator />
                  )}
                </VStack>
              )}
            {componentState.loading && (
              <View padding={4}>
                <FormComponentLoader />
              </View>
            )}
            {!componentState.loading && componentState.customError && (
                 <FormComponentError error={FormError.custom} customError={componentState.customError} />
               )}
            {!componentState.loading && componentState.formError && (
              <FormComponentError error={componentState.formError} />
            )}
            {!componentState.loading && componentState.nonBlockingFormError && (
              <FormComponentError error={componentState.nonBlockingFormError} />
            )}
            {!componentState.loading &&
              !componentState.formError &&
              getDetailView(isCompactView, false)}
          </VStack>
        </DetailViewCollapse>
        }
        </div>
      )}
      <Drawer
        title={
          <>
          <ModalActionTitle
            isHeadNotSticky
            title={'Immunizations'}
            buttonList={[
              {
                onClick: onClose,
                show: true,
                id: 1,
                btnText: intl.formatMessage({
                  id: 'close',
                }),
                size: 'sm',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
              },
            ]}
          />
          {getSectionAdditionalView(false)}
          </>
        }
        destroyOnClose
        placement="right"
        onClose={onClose}
        open={componentState.editModalVisible}
        closable
        width="50%"
      >
        <DetailViewCollapse
          btnList={[]}
          textLocalId={props.component.label}
          toggled={!componentState.editModalCollapse}
          headerRightView={getHeaderRightView(false)}
          onToggled={() => {
            setComponentState((prev) => ({
              ...prev,
              editModalCollapse: !prev.editModalCollapse,
            }));
          }}
        >
          <VStack ref={drawerDetailRef}>
            {canAddNew() &&
              isMobileScreen &&
              componentValue.immunizations.length > 0 && (
                <VStack marginY={4} space={2} flex={1} marginX={2}>
                  {getSearchComponent()}
                  {componentState.showSavingIndicator && (
                    <SectionLoadingIndicator />
                  )}
                </VStack>
              )}
            {componentState.loading && <FormComponentLoader />}
            {!componentState.loading && componentState.customError && (
              <FormComponentError error={FormError.custom} customError={componentState.customError} />
            )}
            {!componentState.loading && componentState.formError && (
              <FormComponentError error={componentState.formError} />
            )}
            {!componentState.loading && componentState.nonBlockingFormError && (
              <FormComponentError error={componentState.nonBlockingFormError} />
            )}
            {!componentState.loading &&
              !componentState.formError &&
              getDetailView(false, false)}
          </VStack>
        </DetailViewCollapse>
      </Drawer>
      <Drawer
        title={
          <ModalActionTitle
            isHeadNotSticky
            title={'Patient Reported Immunizations'}
            buttonList={[
              {
                isDisabled: componentState.showSavingIndicator,
                isLoading: componentState.showSavingIndicator,
                onClick: () => {
                  setComponentState((prev) => ({
                    ...prev,
                    showPatientReportedRecords: false,
                    selectedVitalsForBulkActions:
                      componentState.patientReported.map(
                        (item) => item.id || ''
                      ),
                  }));
                },
                show: true,
                id: 1,
                btnText: intl.formatMessage({
                  id: componentState.showSavingIndicator ? 'Saving' : 'close',
                }),
                size: 'sm',
                textColor: componentState.showSavingIndicator
                  ? Colors.primary[800]
                  : Colors.danger[800],
                variant: componentState.showSavingIndicator
                  ? BUTTON_TYPE.TERTIARY
                  : BUTTON_TYPE.SECONDARY,
              },
              {
                isDisabled: componentState.showSavingIndicator || bulkAction.loading,
                isLoading: bulkAction.loading,
                onClick: () => bulkAction.handleConfirmation(true),
                show: organizationCapability?.canCreate && componentState.patientReported.filter(item => !item.isFreeTextRecord).length > 0,
                id: 2,
                btnText: intl.formatMessage({
                  id: 'acceptAll'
                }),
                size: 'sm',
                textColor: componentState.showSavingIndicator || bulkAction.loading
                  ? Colors.primary[800]
                  : Colors.danger[800],
                variant: BUTTON_TYPE.PRIMARY,
              },
            ]}
          />
        }
        destroyOnClose
        placement="right"
        onClose={() => {
          setComponentState((prev) => ({
            ...prev,
            showPatientReportedRecords: false,
          }));
        }}
        open={componentState.showPatientReportedRecords}
        closable
        width="50%"
      >
        <VStack>
          {componentState.loading && <FormComponentLoader />}
          {!componentState.loading && componentState.nonBlockingFormError && (
            <FormComponentError error={componentState.nonBlockingFormError} />
          )}
          {!componentState.loading && componentState.customError && (
            <FormComponentError error={FormError.custom} customError={componentState.customError} />
          )}
          {!componentState.loading && componentState.formError && (
            <FormComponentError error={componentState.formError} />
          )}
          {!componentState.loading &&
            !componentState.formError &&
            getDetailView(false, true)}
        </VStack>
      </Drawer>
      {deleteConfirmation.modalVisible && (
       <PatientIntakeDeleteModal
        type={'Immunization'}
        visible={deleteConfirmation.modalVisible}
        onCancel={cancelDeletePrompt}
        onDelete={() => {
          onDelete(
            deleteConfirmation.selectedEntry?.item,
            deleteConfirmation.selectedEntry?.index as number,
            false
          );
        }}
        />
      )}
       {bulkAction.confirm && (
        <PatientIntakeConfirm
          title='Accept Patient Reported Data'
          onOk={() => handleSubmitAllAction()}
          onCancel={() => bulkAction.handleConfirmation(false)}
          acceptCount={acceptRecord.length}
          renderRecords={() => {
            return (
              <>
                {ignoredRecords.length > 0 && (
                  <View>
                    <Text mt={2.5}>
                      Following data reported by patient is unstructured and has
                      not been matched to a structured record. These will not be
                      updated in the EHR.
                    </Text>
                    {renderSectionBulletList(
                      `Unmatched records (${ignoredRecords.length} / ${componentState.patientReported.length})`,
                      ignoredRecords,
                      undefined,
                      {
                        backgroundColor: Colors.Custom.Red100,
                        borderColor: Colors.Custom.ErrorColor,
                      }
                    )}
                  </View>
                )}
                {acceptRecord.length > 0 && (
                  <View>
                    <Text mt={2.5}>Following records will be updated in the EHR.</Text>
                    {renderSectionBulletList(
                      `Matched records (${acceptRecord.length} / ${componentState.patientReported.length}) `,
                      acceptRecord
                    )}
                  </View>
                )}
              </>
            );
          }}
        />
      )}
    </View>
  );
};

export default AddOrUpdateImmunizations;
