import React, { useEffect, useState } from 'react'
import { Text, Box, HStack, Spacer, VStack, Tooltip, Pressable, Divider, Spinner, IconButton } from 'native-base'
import CustomButton from '../../../../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomButton/CustomButton'
import { IOrderListProps, IOrderListState } from './interfaces'
import { deleteOrder, getDiagnosticReport, getOrdersFromNote } from '../../../../../../services/CommonService/OrderService'
import { Dropdown, Menu, Skeleton } from 'antd'
import { Colors } from '../../../../../../styles'
import { IReportAndResourceMap, IRequestGroup } from '../../../../LeftContainer/RecentActivity/OrdersInterface'
import {  getDiagnosticReportResponseFormatted, getDiagnosticReportsForServiceRequest, getReportReferences, getReportResultForDisplay, getServiceRequestsFromOrder, isAllReportViewedBy, REPORT_TYPE } from '../../../../LeftContainer/RecentActivity/RecentReport/ReportUtils'
import { ReportResultView } from '../../../../LeftContainer/RecentActivity/RecentReport/ReportResultView'
import { getServiceRequestReference, getReportMatchWithOrder, getOrderName, getFacilityName, isOrderAddedFromFold, updateOrderStatus, canShowEditButton, canShowDeleteButton, canShowPrintButton } from '../OrderUtils'
import Feather from 'react-native-vector-icons/Feather'
import { ICodeableParam } from '../../../../../RightSideContainer/Forms/FHFormio/CustomComponents/Conditions/interfaces'
import { DiagnosticReport, MedicationRequest, RequestGroup } from 'fhir/r4'
import OrderDetailsDrawer from '../OrderDetailsDrawer/OrderDetailsDrawer'
import ResultIcon from '../../../OrdersTimeline/assets/ResultIcon'
import PDFIcon from '../../../OrdersTimeline/assets/PdfIcon'
import OrderListPreview from '../OrderListPreview'
import { BUTTON_TYPE, ORDER_TYPE } from '../../../../../../constants'
import { OrderType } from '../OrderConstants'
import IndividualOrderView from '../IndividualOrderView/IndividualOrderView'
import AddOrUpdateOrders from '../AddOrUpdateOrders/AddOrUpdateOrders'
import { MENU_KEYS } from '../../../PatientNotes/components/CustomSendOrderButton'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import { useIntl } from 'react-intl'
import { renderOrderSubtitle } from '../OrderViewHelper'
import { FHAlertDialog } from '../../../../../common/FHAlertDialog'
import { getEhrConfig } from '../../../../../../utils/capabilityUtils'
import { StyleSheet } from 'react-native';

const OrderList = (props: IOrderListProps) => {
  const {personData, existingDiagnosis} = props;
  const intl = useIntl();
  const accountLocationUuid = personData?.accountLocationUuid || '';
  const ehrConfig = getEhrConfig(accountLocationUuid, '');
  const [orderListState, setOrderListState] = useState<IOrderListState>({
    showAddNewOrderModal: false,
    deleteConfirmation: false,
    printOrder: false,
    isExternalOrder: false,
    deleteLoading: false,
    loading: false,
    showDetail: false,
    reportLoadingKey: undefined,
    orderList: [],
    showModal: false,
    reports: [],
    selectedReport: {
      reportName: '',
      observation: [],
      isReportReviewedByUser: false,
      resources: []
    },
    selectedOrder: {},
  });
  const [statusLoadingOrderId, setStatusLoadingOrderId] = useState('');

  const handleStatusChange = async (status: string, order: RequestGroup) => {
    setStatusLoadingOrderId(order?.id || '');
    await updateOrderStatus(status, order);
    setStatusLoadingOrderId('');
    props.onOrderListUpdate?.(orderListState.orderList);
  };

  const fetchReports = (list: IRequestGroup[]) => {
    const serviceRequests = getServiceRequestReference(list);
    if (!serviceRequests.length) return;
    setOrderListState((prev) => ({
      ...prev,
      loading: true,
    }));
    getDiagnosticReport(
      props.patientId,
      serviceRequests,
      (response) => {
        const {reports} = getDiagnosticReportResponseFormatted(response);
        setOrderListState((prev) => {
          reports.forEach((report) => {
            const referenceIds = getReportReferences(report);
            if (referenceIds?.length) {
              prev.orderList.map((order) => {
                const actions = order?.action?.map((action) => {
                  const reference = action?.resource?.reference;
                  if (referenceIds.includes(reference || '')) {
                    action.resource.report = report;
                  }
                  return action;
                });
                return {
                  ...order,
                  action: actions,
                };
              });
            }
          });
          return {
            ...prev,
            orderList: prev.orderList,
            reports: reports,
            loading: false,
          };
        });
      },
      (error) => {

        setOrderListState((prev) => ({...prev, loading: false}));
      },
     props.orderType,
     accountLocationUuid,
    );
  }

  const fetchOrders = (isOrderPlaced: boolean) => {
    if (!props.documentReferenceId || !props.patientId) return;
    setOrderListState((prev) => ({...prev, loading: true}));
    getOrdersFromNote(
      props.orderType,
      props.patientId,
      props.documentReferenceId,
      (response) => {
        const list: IRequestGroup[] = response?.data?.entry?.map((item: any) => item.resource);
        const reasonCodes:ICodeableParam[] = [];
        list.forEach(item => {
          if(item?.reasonCode && item?.reasonCode?.length){
            reasonCodes.push(...item.reasonCode)
          }
        })
        props.addDiagnosisFormOrderList(reasonCodes)
        fetchReports(list);
        setOrderListState((prev) => ({
          ...prev,
          loading: false,
          ...(list.length && { orderList: list })
        }));
        if (props.onOrderListUpdate && isOrderPlaced) {
          props.onOrderListUpdate(list);
        }
      },
      (error) => {

        setOrderListState((prev) => ({...prev, loading: false}));
    }),
    accountLocationUuid
  }

  const renderReportButton = (report: IReportAndResourceMap, order: RequestGroup, key: string): JSX.Element => {
    let matchReport: any = null;
    if (order) {
      matchReport = getReportMatchWithOrder(report, order);
    } else {
      matchReport = report?.resources?.[0];
    }
    if (matchReport) {
      switch (report?.type) {
        case REPORT_TYPE.BOTH:
          return (
            <>
              {getReportButton(report, REPORT_TYPE.MEDIA_REPORT, key)}
              {getReportButton(report, REPORT_TYPE.RESULT, key)}
            </>
          );
        case REPORT_TYPE.MEDIA_REPORT:
          return (
            <>
              {getReportButton(report, REPORT_TYPE.MEDIA_REPORT, key)}
            </>
          );
        case REPORT_TYPE.RESULT:
          return (
            <>{getReportButton(report, REPORT_TYPE.RESULT, key)}</>
          );
        case REPORT_TYPE.FOLD_MEDIA_REPORT:
          return getReportButton(report, REPORT_TYPE.FOLD_MEDIA_REPORT, key, order);
      }
    }
    return <></>;
  };

  const getReportButton = (
    report: IReportAndResourceMap,
    reportType: string,
    key: string,
    order?: RequestGroup,
  ): JSX.Element => {
    const isMediaReport = reportType === REPORT_TYPE.MEDIA_REPORT || reportType === REPORT_TYPE.FOLD_MEDIA_REPORT;
    if (key === orderListState.reportLoadingKey) {
      return <Spinner size={'sm'} />
    }
    return (
      <Tooltip label={isMediaReport ? 'View Report' : 'View Result'} placement='top' key={'report'}>
        <Pressable
          key={'report'}
          onPress={() => {
            configureReportResult(report, reportType, key, order);
          }}
        >
          {isMediaReport ? (
            <PDFIcon key={'pdf'} />
          ) : (
            <ResultIcon key={'result'} />
          )}
        </Pressable>
      </Tooltip>
    );
  };

  const configureReportResult = async (
    selectedReport: IReportAndResourceMap,
    reportType: string,
    key: string,
    order?: RequestGroup,
  ) => {
    setOrderListState((prev: any) => {
      return {
        ...prev,
        reportLoadingKey: key,
      };
    });
    let orderReport = selectedReport;
    if (reportType == REPORT_TYPE.FOLD_MEDIA_REPORT && order) {
      const serviceRequests = getServiceRequestsFromOrder(order);
      const resources = getDiagnosticReportsForServiceRequest(serviceRequests, orderListState.reports);
      orderReport = {
        code: selectedReport.code,
        resourceCode: selectedReport.resourceCode,
        display: getOrderName(order),
        type: selectedReport.type,
        showGraph: false,
        resources: resources,
        orderType: selectedReport.orderType,
      }
    }
    const { reportName, observationList } = await getReportResultForDisplay(
      orderReport,
      reportType,
      orderListState.orderList as RequestGroup[],
      { ehrConfig, patientId: personData?.patientId || personData?.patientUuid, locationId: accountLocationUuid },
    );
    const isDataAvailable = reportName && observationList.length;
    setOrderListState((prev: any) => {
      return {
        ...prev,
        showModal: isDataAvailable,
        reportLoadingKey: undefined,
        selectedReport: {
          reportName,
          observation: observationList,
          isReportReviewedByUser: isAllReportViewedBy(orderReport),
          resources: orderReport.resources,
        },
      };
    });
  };

  const getInfoButton = (params: {
    report?: DiagnosticReport,
    order?: RequestGroup,
    action?: any,
  }): JSX.Element => {
    return (
      <Tooltip label="View Details" placement="top" key={'info'}>
        <Pressable
          key={'info'}
          onPress={() => {
            setOrderListState((prev) => {
              return {
                ...prev,
                showDetail: true,
                selectedOrder: {
                  report: params.report,
                  order: params.order,
                  action: params.action,
                }
              }
            });
          }}
        >
          <Feather
            name="info"
            style={styles.infoIcon}
          />
        </Pressable>
      </Tooltip>
    );
  };

  const getEditButton = (order?: RequestGroup): JSX.Element => {
    const canShowEdit = canShowEditButton(order);
    if (!canShowEdit || !order) return <></>;
    return (
      <Tooltip label="Edit Order" placement="top">
        <Pressable
          onPress={() => {
            setOrderListState((prev) => {
              return {
                ...prev,
                showAddNewOrderModal: true,
                isExternalOrder: isOrderAddedFromFold(order as RequestGroup),
                selectedOrder: {
                  order: order,
                }
              }
            });
          }}
        >
          <Feather
            name="edit-3"
            style={styles.infoIcon}
          />
        </Pressable>
      </Tooltip>
    );
  };

  const onDeleteOrder = async (order: RequestGroup | undefined) => {
    if (!order?.id) return;
    setOrderListState((prev) => ({...prev, deleteLoading: true}));
    try {
      await deleteOrder(order.id);
      setOrderListState((prev) => ({...prev, deleteLoading: false, deleteConfirmation: false, selectedOrder: {}}));
      fetchOrders(true);
    } catch (error) {
      setOrderListState((prev) => ({...prev, deleteLoading: false}));
    }
  }

  const getDeleteButton = (order: RequestGroup): JSX.Element | undefined => {
    const canShow = canShowDeleteButton(order);
    if (!canShow || !order) return;
    return (
      <Tooltip label="Delete Order" placement="top" key={'delete'}>
        <Pressable
          key={'delete'}
          onPress={() => {
            setOrderListState((prev) => ({
              ...prev,
              deleteConfirmation: true,
              selectedOrder: {
                order: order,
              }
            }))
          }}
        >
          <Feather
            name="trash-2"
            style={styles.infoIcon}
          />
        </Pressable>
      </Tooltip>
    );
  };

  const getPrintButton = (order: RequestGroup): JSX.Element | undefined => {
    const canShowPrint = canShowPrintButton(order);
    if (!canShowPrint) return;
    return (
      <Tooltip label="Print Requisition Form" placement="top" key={'print'}>
        <Pressable
          key={'print'}
          onPress={() => {
            setOrderListState((prev) => {
              return {
                ...prev,
                showAddNewOrderModal: true,
                printOrder: true,
                isExternalOrder: isOrderAddedFromFold(order),
                selectedOrder: {
                  order: order,
                  noteId: props.documentReferenceId,
                  orderType: props.orderType
                }
              }
            });
          }}
        >
          <Feather
            name="printer"
            style={styles.infoIcon}
          />
        </Pressable>
      </Tooltip>
    );
  };

  const actionMenu = () => {
    const menuItems: ItemType[] = [];
    if (props.orderType === OrderType.LAB) {
      menuItems.push({
        key: MENU_KEYS.labOrder,
        label: intl.formatMessage({id: 'labOrder'}),
      });
      menuItems.push({
        key: MENU_KEYS.externalLabOrder,
        label: intl.formatMessage({id: 'externalLabOrder'}),
      });
    } else if (props.orderType === OrderType.RAD) {
      menuItems.push({
        key: MENU_KEYS.radOrder,
        label: intl.formatMessage({id: 'radOrder'}),
      });
      menuItems.push({
        key: MENU_KEYS.externalRadOrder,
        label: intl.formatMessage({id: 'externalRadOrder'}),
      });
    }
    return (
      <Menu
        className="custom-menu"
        items={menuItems}
        onClick={(data: any) => {
          setOrderListState((prev) => ({
            ...prev,
            showAddNewOrderModal: true,
            isExternalOrder: data?.key === MENU_KEYS.externalLabOrder || data?.key === MENU_KEYS.externalRadOrder,
          }));
        }}
      />
    );
  };

  const getAddNewButton = () => {
    const isExternalOrderEnabled = true;
    if (isExternalOrderEnabled) {
      return (
        <Dropdown
          overlay={actionMenu}
          placement="bottomRight"
          overlayStyle={{width: 160}}
        >
          <IconButton
            icon={
              <Feather
                name="plus"
                size={20}
                color={Colors.Custom.Gray500}
              />
            }
            _hover={{background: 'none'}}
          />
        </Dropdown>
      );
    } else {
      return (
        <CustomButton
          title={'Order'}
          onPress={() => {
            setOrderListState((prev) => ({
              ...prev,
              showAddNewOrderModal: true,
            }));
          }}
          leftIcon={
            <Feather
              name="plus"
              size={14}
              color={Colors.Custom.PurpleColor}
            />
          }
        />
      );
    }
  }

  const renderCardActions = (params: {
    order: RequestGroup;
    report?: IReportAndResourceMap;
    key?: string;
  }): JSX.Element => {
    const { report, order, key } = params;
    return (<>
      {getEditButton(order)}
      {getDeleteButton(order)}
      {getPrintButton(order)}
      {report && renderReportButton(report, order, key || '')}
      {getInfoButton({ order: order })}
    </>);
  }

  useEffect(() => {
    fetchOrders(true);
  }, [props.documentReferenceId, props.patientId]);


  return (
    <>
      {props.isPreviewMode && !orderListState.loading ? (
        <OrderListPreview
          orderLabel={props.title}
          personData={personData}
          orderList={orderListState.orderList}
          type={
            props.orderType === OrderType.LAB ? ORDER_TYPE.LAB : ORDER_TYPE.RAD
          }
          renderActions={(
            order: RequestGroup | MedicationRequest,
            report?: IReportAndResourceMap
          ) => (
            <HStack
              alignItems={'center'}
              justifyContent="end"
              flex={2}
              space={3}
              marginTop={2}
            >
              {/* {report && renderReportButton(report, order as RequestGroup, order.id || '')}
              {NEW_HG_FLOW &&
                isPendingDraftOrder(order as RequestGroup) &&
                getDraftLinkButton(order as RequestGroup)}
              {getInfoButton({
                report: report?.resources?.[0],
                order: order as RequestGroup,
              })} */}
              {renderCardActions({
                report: report,
                order: order as RequestGroup,
                key: order.id || '',
              })}
            </HStack>
          )}
        />
      ) : (
        <div className="custom-style-extra-note-component">
          <Box
            py={3}
            borderColor={'#D0D5DD'}
            borderWidth={0.5}
            mt={1}
            borderRadius={8}
            backgroundColor="white"
          >
            <HStack mb={1} px={4}>
              <Text
                fontWeight="bold"
                fontSize={16}
              >{`${props.title} Orders & Reports`}</Text>
              <Spacer />
              {!props.disabled && getAddNewButton()}
            </HStack>
            {orderListState.loading && <Skeleton active />}
            {!orderListState.loading &&
              orderListState.orderList.length > 0 &&
              orderListState.orderList.map((order, orderIndex) => {
                const report = order?.action?.[0]?.resource?.report;
                const key = `${report?.code}_${orderIndex}`;

                return (
                  <VStack key={key}>
                    <VStack mb={0.5} flex={1} pr={5.5} pl={4} marginRight={2}>
                      <HStack>
                        <Text color={Colors.Custom.Gray900} fontWeight={500} fontSize={14}>{getFacilityName(order as RequestGroup) || 'No facility selected'}</Text>
                        <HStack
                          alignItems="center"
                          flex={1}
                          justifyContent="flex-end"
                          space={3}
                        >
                          {/* {report && renderReportButton(report, order as RequestGroup, key)}
                          {NEW_HG_FLOW && isPendingDraftOrder(order as RequestGroup) && getDraftLinkButton(order as RequestGroup)}
                          {getInfoButton({ report: report?.resources?.[0], order: order as RequestGroup })} */}
                          {renderCardActions({
                            report: report,
                            order: order as RequestGroup,
                            key: key,
                          })}
                        </HStack>
                      </HStack>
                      <Divider marginY={2} />
                      <VStack space={2}>
                        {order.action?.map((action, index) => {
                          return <IndividualOrderView
                            key={index}
                            order={order as RequestGroup}
                            action={action}
                            report={action.resource?.report}
                            personData={personData}
                          />
                        })}
                      </VStack>
                      {(order.action || [])?.length > 0 && <Divider marginY={3} />}
                      {renderOrderSubtitle(order as RequestGroup, statusLoadingOrderId === order.id, (status) => handleStatusChange(status, order as RequestGroup))}
                    </VStack>
                    {orderIndex !== orderListState.orderList.length - 1 && (
                      <Divider my={4} />
                    )}
                  </VStack>
                );
              })}
            {!orderListState.loading && !orderListState.orderList.length && (
              <Text pr={5.5} pl={4} marginRight={2}>{'No Orders'}</Text>
            )}
          </Box>
        </div>
      )}
      {!orderListState.loading && orderListState.showAddNewOrderModal && (
        <AddOrUpdateOrders
          isVisible={orderListState.showAddNewOrderModal}
          isExternalOrder={orderListState.isExternalOrder}
          existingDiagnosis={existingDiagnosis}
          printOrder={orderListState.printOrder}
          orderType={props.orderType}
          patientContactId={props.patientContactId}
          documentReferenceId={props.documentReferenceId}
          unFormattedContactData={props.unFormattedContactData}
          personData={props.personData}
          selectedOrder={orderListState.selectedOrder.order}
          onClose={() => {
            fetchOrders(true);
            setOrderListState((prev) => ({
              ...prev,
              showAddNewOrderModal: false,
              isExternalOrder: false,
              printOrder: false,
              selectedOrder: {},
            }));
          }}
        />
      )}
      {orderListState.showModal && (
        <ReportResultView
          contactData={props.personData}
          unformmatedContactDetails={props.unFormattedContactData}
          showModal={orderListState.showModal}
          reportName={orderListState.selectedReport.reportName}
          resources={
            orderListState.selectedReport.resources as DiagnosticReport[]
          }
          locationId={accountLocationUuid}
          observations={orderListState.selectedReport.observation}
          onMarkedAsReviewed={() => {
            fetchOrders(true);
          }}
          onClose={() => {
            setOrderListState((prev) => {
              return {
                ...prev,
                showModal: false,
                selectedReport: {
                  reportName: '',
                  observation: [],
                  resources: [],
                  isReportReviewedByUser: false,
                },
              };
            });
          }}
        />
      )}
      {orderListState.showDetail && (
        <OrderDetailsDrawer
          isVisible={orderListState.showDetail}
          report={orderListState.selectedOrder.report}
          order={orderListState.selectedOrder.order}
          action={orderListState.selectedOrder.action}
          onClose={() => {
            setOrderListState((prev) => {
              return {
                ...prev,
                showDetail: false,
                selectedOrder: {},
              };
            });
          }}
        />
      )}
      <FHAlertDialog
        isOpen={orderListState.deleteConfirmation}
        header={'Delete Draft Order'}
        message={'Are you sure, you want to delete this draft order?'}
        buttonActions={[
          {
            textLocalId: 'No',
            buttonProps: {
              variant: BUTTON_TYPE.SECONDARY,
              isDisabled: orderListState.deleteLoading,
            },
            onPress: () => {
              setOrderListState((prev) => ({
                ...prev,
                deleteConfirmation: false,
                selectedOrder: {},
              }));
            },
          },
          {
            textLocalId: 'Yes',
            textColor: 'white',
            buttonProps: {
              variant: BUTTON_TYPE.PRIMARY,
              isLoading: orderListState.deleteLoading,
              isDisabled: orderListState.deleteLoading,
              _loading: {

              },
            },
            onPress: () => {
              onDeleteOrder(orderListState.selectedOrder.order);
            },
          },
        ]}
      />
    </>
  );
}

export default OrderList;

const styles = StyleSheet.create({
  infoIcon: {
    fontSize: 16,
    color: Colors.Custom.Gray500,
    padding: 0,
  },
});
