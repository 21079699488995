import {HStack, Text} from 'native-base';
import React from 'react';
import {HEADER_TITLE_TYPES} from '../../constants/ConstantValues';
import {Colors} from '../../styles';
import {testID} from '../../testUtils';
import {getAgeValue, getDateStrFromFormat} from '../../utils/DateUtils';
import {getGenderTextByGenderCode} from '../../utils/commonUtils';
import {IConversationData} from '../RightSideContainer/TeamInbox/Conversations/interfaces';
import {
  getContactDataAndType,
  getContactTypeValue,
  getGenderAndSexAtBirthByGenderCodeStr,
} from '../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MessagingUtils';
import {GROUP_TYPE_CODES} from '../../constants/MlovConst';
import {IGroupContact} from '../../services/Conversations/interfaces';
import Stack from '../common/LayoutComponents/Stack';
import { DATE_FORMATS } from '../../constants';
import { isWeb } from '../../utils/platformCheckUtils';
import { getGroupNameText } from '../RightSideContainer/MembersManagement/MembersManagementHelper';
interface ISubTitleViewProps {
  title: string;
  color: string;
}

export const CustomHeaderPatientSubtitle = (props: {
  type?: string;
  contactData: any;
  deceasedDate?: string;
  selectedConversation?: IConversationData;
  pcpUserName?: string;
  showPcp?: boolean;
  isShowAgeWithDOB?: boolean;
  isShowFullSubtitle?: boolean;
  isMessgingWindowSubtitle?: boolean;
}) => {
  const {type} = props;
  let fontSize = 24;
  let fontWeight = '700';
  let color = '#000';
  if (HEADER_TITLE_TYPES.SINGLE_MAIN_TITLE === type) {
    fontSize = 24;
  } else if (HEADER_TITLE_TYPES.TITLE === type) {
    fontSize = 16;
    color = Colors.Custom.Gray900;
  } else if (HEADER_TITLE_TYPES.SUBTITLE === type) {
    fontSize = 12;
    fontWeight = '400';
    color = Colors.Custom.Gray600;
  } else {
    fontSize = 20;
    color = Colors.Custom.Gray900;
  }

  const getAgeAndGenderTextElem = (
    contactData: IGroupContact,
    showPcp?: boolean
  ): ISubTitleViewProps[] => {
    const ageAndGenderDetail: ISubTitleViewProps[] = [];
    const deceasedDate = props?.deceasedDate || ''
    const ageInYears = contactData?.person?.birthDate
      ? (props?.isShowAgeWithDOB
      ? (getDateStrFromFormat(contactData?.person?.birthDate,DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT) + ('(' + getAgeValue(contactData?.person?.birthDate,deceasedDate) + ')'))
      : (getDateStrFromFormat(contactData?.person?.birthDate,DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT)))
      : '';
    if (ageInYears?.length) {
      ageAndGenderDetail.push({
        title: ageInYears,
        color: !!props?.deceasedDate
          ? Colors.Custom.DeceasedTagColor
          : Colors.Custom.Gray500,
      });
    }
    const genderValue = showPcp
      ? getGenderAndSexAtBirthByGenderCodeStr(contactData)
      : getGenderTextByGenderCode(contactData);
    if (genderValue.length) {
      ageAndGenderDetail.push({
        title: genderValue,
        color: Colors.Custom.Gray500,
      });
    }
    const location =
      props?.contactData?.contactPracticeLocations?.[0]?.practiceLocation?.name;
    if (location?.length && !props?.isMessgingWindowSubtitle) {
      ageAndGenderDetail.push({title: location, color: Colors.Custom.Gray500});
    }
    return ageAndGenderDetail;
  };

  const getPatientAgeAndGenderFromConversation = (
    singleConversationData: IConversationData,
    pcpUserName?: string,
    showPcp?: boolean
  ): ISubTitleViewProps[] => {
    let detail: ISubTitleViewProps[] = [];
    const contactInfo: any = getContactDataAndType(singleConversationData);
    const contactData: IGroupContact = contactInfo.contactData;
    const contactType = contactInfo.contactType;
    if (
      singleConversationData?.groupConversation?.groupType?.code ===
      GROUP_TYPE_CODES.INTERNAL
    ) {
      const groupName = singleConversationData?.groupConversation?.name;
      detail.push({title: groupName, color: Colors.Custom.Gray500});
    } else {
      const contactTypeStr = getContactTypeValue(contactType);
      detail.push({title: contactTypeStr, color: Colors.Custom.Gray500});
      const ageAndGenderText = getAgeAndGenderTextElem(contactData, showPcp);
      if (ageAndGenderText.length) {
        detail = detail.concat(ageAndGenderText);
      }
    }
    const groupMemberCount =
      singleConversationData?.groupConversation?.groupMembers_aggregate
        ?.aggregate?.count ||
      singleConversationData?.groupConversation?.groupMembers?.length;
    let str = '';
    if (groupMemberCount) {
      str = `${groupMemberCount}`;
      detail.push({
        title: str + `${groupMemberCount > 1 ? ' members' : 'member'}`,
        color: Colors.Custom.Gray500,
      });
    }
    if (pcpUserName?.length) {
      detail.push({title: pcpUserName + '(PCP)', color: Colors.Custom.Gray500});
    }
    const location =
      props?.contactData?.contactPracticeLocations?.[0]?.practiceLocation?.name;
    if (location?.length && props?.isMessgingWindowSubtitle) {
      detail.push({title: location, color: Colors.Custom.Gray500});
    }
    if (singleConversationData?.conversationInbox?.name?.length) {
      detail.push({
        title: singleConversationData?.conversationInbox?.name,
        color: Colors.Custom.Gray500,
      });
    }

    return detail;
  };

  const ageAndGenderDetail = props.selectedConversation
    ? getPatientAgeAndGenderFromConversation(
        props.selectedConversation,
        props.pcpUserName,
        props.showPcp
      )
    : getAgeAndGenderTextElem(props.contactData, props.showPcp);


  const combinedTitles = ageAndGenderDetail.map((item) => item.title).join(' | ');
  const displayText = getGroupNameText(combinedTitles,40)

  return (
    <>
      {!isWeb() && !props?.isShowFullSubtitle ? (
        <Text
          fontSize={fontSize}
          fontWeight={fontWeight}
          color={Colors.Custom.Gray700}
          noOfLines={1}
          ellipsizeMode={'tail'}
          {...testID('SubTitle')}
        >
          {displayText}
        </Text>
      ) : (
        <HStack space={1}>
          {ageAndGenderDetail.map((item, index) => (
            <Stack direction="row" key={`CustomHeaderPatientSubtitle_${item.title}_${index}`}>
            {index > 0 && (
              <Text fontSize={fontSize} fontWeight={fontWeight} color={color}>
                {' | '}
              </Text>
            )}
            <Text
              fontSize={fontSize}
              fontWeight={fontWeight}
              color={item.color}
              noOfLines={1}
              ellipsizeMode={'tail'}
              {...testID(item.title)}
              key={`CustomHeaderPatientSubtitle_${item.title}_${index}`}
            >
              {item.title}
            </Text>
          </Stack>
          ))}
        </HStack>
      )}
    </>
  );
};
