import React from 'react';
import {StyleSheet} from 'react-native';
export const styles = StyleSheet.create({
  modalContainer: {
    minWidth: 'fit-content',
  },
  modalBody: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  previewContainer: {
    maxWidth: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  copyBtnView: {
    padding: 2,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
  },
  imageContainer: {
    width: 500,
    height: 500,
  },
  imageStyle: {
    width: '100%',
    height: '100%',
  },
});
