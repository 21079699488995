import {FormControl, Pressable, View} from 'native-base';
import {useState} from 'react';
import {MediaPreview} from '../../MediaLibrary/MediaPreview';
import MediaSelectionModal from '../MediaSelectionModal/MediaSelectionModal';
import {IMediaComponentProps} from '../interfaces';
import {styles} from './ComponentStyle';

const {Label} = FormControl;

const MediaComponent = (props: IMediaComponentProps) => {
  const [selectionModalOpen, setSelectionModalOpen] = useState(false);
  const {onChangeMediaData, singleDynamicData} = props;
  return (
    <View style={styles.basicContainer}>
      <View style={styles.formControlView}>
        <Pressable
          onPress={() => {
            setSelectionModalOpen(true);
          }}
        >
          <MediaPreview
            mediaType={singleDynamicData.mediaType as string}
            mediaUrl={singleDynamicData.mediaUrl as string}
          />
        </Pressable>
      </View>

      {selectionModalOpen && (
        <MediaSelectionModal
          isOpen={selectionModalOpen}
          onClose={() => {
            setSelectionModalOpen(false);
          }}
          onSelect={(mediaData) => {
            setSelectionModalOpen(false);
            onChangeMediaData({
              mediaType: mediaData.mime,
              desc: '',
              mediaId: mediaData.fileId || '',
              mediaUrl: mediaData.url || '',
            });
          }}
        />
      )}
    </View>
  );
};

export default MediaComponent;
