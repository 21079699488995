import axios from 'axios';
import {
  getEnvVariable,
  SERVICES_BASE_PATH,
  SERVICES_BASE_URL,
} from '../../constants/BaseUrlConst';
import LocalStorage from '../../utils/LocalStorage';
import { createAxiosInstance } from '../../utils/APIUtils';

export default class CommerceCommonService {
  private static commonServiceInstance: CommerceCommonService;
  public axiosService;
  constructor() {
    if (CommerceCommonService.commonServiceInstance) {
      throw new Error(
        'Use CommerceCommonService.commonServiceInstance instead of new.'
      );
    }
    this.axiosService = createAxiosInstance({
      baseURL: `${SERVICES_BASE_URL.COMMERCE_BASE_URL}${SERVICES_BASE_PATH.COMMERCE_BASE_PATH}`,
      headers: {
        'Content-type': 'application/json',
      },
    });

    this.axiosService.interceptors.request.use(
      async (config) => {
        const envStr = getEnvVariable();
        let accessToken = '';
        let tokenType = '';
        let commercesSubdomain = '';

        const accountInfo = await LocalStorage.getItem('account');
        const userDataString = await LocalStorage.getItem('user');
        const userData = JSON.parse(userDataString);
        const fold_access_token = userData?.fold_access_token;
        const accountInfoJson = JSON.parse(accountInfo || '{}');
        const subdomainName = accountInfoJson?.subDomain;
        if (subdomainName && envStr.length) {
          commercesSubdomain = subdomainName.replace(
            '.' + envStr + '.',
            '.website.' + envStr + '.'
          );
        } else if (subdomainName && !envStr.length) {
          const tenantName = subdomainName.replace('.foldhealth.io', '');
          commercesSubdomain = `${tenantName}.website.foldhealth.io`;
        }
        const commerceUserTokenStr = await LocalStorage.getItem(
          'commerceUserToken'
        );
        const commerceUserTokenData = JSON.parse(commerceUserTokenStr || '{}');
        if (commerceUserTokenData) {
          accessToken = commerceUserTokenData.access_token || '';
          tokenType = commerceUserTokenData.token_type || '';
        }
        config.headers = {
          ...config.headers,
          subdomain: commercesSubdomain,
          fold_access_token,
        };
        if (accessToken && tokenType) {
          config.headers['Authorization'] = `${tokenType} ${accessToken}`;
        }
        return config;
      },
      (error) => {
      }
    );
    CommerceCommonService.commonServiceInstance = this;
  }

  static getCommerceCommonServiceInstance(): CommerceCommonService {
    return (
      CommerceCommonService.commonServiceInstance ??
      (CommerceCommonService.commonServiceInstance =
        new CommerceCommonService())
    );
  }
}
