import React from 'react';
import {useIntl} from 'react-intl';
import {Colors} from '../../styles';
import {ICopiedMessageItem} from '../RightSideContainer/TeamInbox/Conversations/MessagingWindow/interface';
import {testID} from '../../testUtils';
import {Tooltip} from 'antd';
import AssignMessageTypeSvg from '../common/Svg/PersonActionSvgIcons/AssignMessageTypeSvg';
import {View} from 'react-native';
import {styles} from './AssignMessageTypeButtonStyles';
import {FHTooltip} from '../common/FHTooltip';

export interface IAssignMessageTypeButtonProps {
  onClick: () => void;
  copyMessageList: ICopiedMessageItem[];
  copyCode: string;
}
const AssignMessageTypeButton = ({
  isMessageCopy,
  onClick,
}: {
  isMessageCopy: boolean;
  onClick: () => void;
}) => {
  const intl = useIntl();
  return (
    <FHTooltip text={intl.formatMessage({id: 'assignType'})} position="bottom">
      <div
        className={`pressable ${!isMessageCopy ? 'disable' : ''}`}
        onClick={onClick}
        {...testID('AssignMessageTypeBtn')}
      >
        <View style={styles.container} {...testID('image')}>
          <AssignMessageTypeSvg
            customStrokeColor={
              isMessageCopy ? Colors.FoldPixel.GRAY300 : Colors.Custom.Gray300
            }
          />
        </View>
      </div>
    </FHTooltip>
  );
};

export default AssignMessageTypeButton;
