import { Drawer } from "antd";
import { HStack, Text, useMediaQuery } from "native-base";
import { IPAD_MINI_WIDTH, IPAD_WIDTH } from "../../../../../constants";
import { EmailTemplateCreator, EmailTemplateEditor } from "../../EmailTemplates/CreateOrEditTemplate";
import { EmailHtmlEditorMainView } from "../../EmailTemplates/EmailHtmlEditor";
import { ModalActionTitle } from "../../../../common/ModalActionTitle/ModalActionTitle";


const EmailTemplateCreatorEditorRightPanel = (props: {
    id?: string;
    isHtml?: boolean;
    isTemplateCreate: boolean;
    isVisible: boolean;
    onCancel: () => void;
    shouldHideDefault: boolean;
    shouldHideTitle: boolean;
    preSelectedCategory: string;
    shouldDisableChangeCategory: boolean;
    customOnSubmitFn: (data: any) => void;

}) => {

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);
  
  const isSmallScreen = isIPadMiniScreen || isIPadScreen 

  const drawerWidth = isSmallScreen ? '90%' : '85%'


    return (
        <Drawer
            title={<ModalActionTitle title={"Email Template"}/>}
            destroyOnClose
            placement="right"
            onClose={() => {
                props.onCancel();

            }}
            visible={props.isVisible}
            closable
            width={drawerWidth}
            bodyStyle={{
              padding: 0
            }}
            drawerStyle={{
              padding: 0
            }}
            // zIndex={10}
        >
            {props.isTemplateCreate ?
                <EmailTemplateCreator
                    onCancel={() => {
                        props.onCancel();
                    }}
                    shouldHideDefault={true}
                    shouldHideTitle={true}
                    preSelectedCategory={props.preSelectedCategory}
                    shouldDisableChangeCategory={true}
                    customOnSubmitFn={(resp: any) => {
                        props.customOnSubmitFn(resp)
                    }}
                />
                :
               ( props.isHtml ? <EmailHtmlEditorMainView isOpenInDrawer={true} onUpdate={(resp:any)=>{props.customOnSubmitFn(resp)}} onClose={()=>{props.onCancel();}} id={props.id}></EmailHtmlEditorMainView> :
                <EmailTemplateEditor
                    id={props.id}
                    onCancel={() => {
                        props.onCancel();
                    }}
                    shouldHideDefault={true}
                    shouldHideTitle={true}
                    preSelectedCategory={props.preSelectedCategory}
                    shouldDisableChangeCategory={true}
                    customOnSubmitFn={(resp: any) => {
                        props.customOnSubmitFn(resp)
                    }}
                />)
            }
        </Drawer>
    );
};


export default EmailTemplateCreatorEditorRightPanel;
