import React from 'react';

const CreateNew = (props: {
  customColor?: string;
  width?: string;
  height?: string;
}) => {
  const {customColor} = props;
  const strokeColor = customColor ? customColor : '#8C210F';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props?.width || 20}
      height={props?.height || 20}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M18.333 9.233V10a8.333 8.333 0 11-4.941-7.617m4.941.95L10 11.675l-2.5-2.5"
      ></path>
    </svg>
  );
};

export default React.memo(CreateNew);
