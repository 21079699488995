import { Colors } from "../../../../../styles";

const EmptyScoreIcon = (props: {width?: string, height?: string, fillColor?: string}) => {
    const fillColor = props.fillColor || "#B895EC";
    return(
        <svg width={props.width? props.width: "75"} height= {props.height? props.height: "75"} viewBox="0 0 76 75" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="0.5" width="74" height="74" rx="37" fill="#F6EBFE"/>
                <path d="M48.1122 29.3121C47.7541 29.0893 47.3146 29.1077 46.9829 29.3224L37.6748 35.0604C35.3892 35.2368 33.284 37.0551 33.284 39.7395C33.284 42.343 35.3967 44.4324 37.9914 44.4324C40.6853 44.4324 42.5059 42.339 42.6846 40.0636L48.4519 30.7658C48.7593 30.2703 48.6073 29.6197 48.1122 29.3121Z" fill={fillColor}/>
                <path d="M36.9453 22.207C32.9937 22.4351 29.2421 23.9424 26.2336 26.5183L33.812 34.0839C34.7493 33.4456 35.8216 33.0308 36.9453 32.8704V22.207Z" fill={fillColor}/>
                <path d="M24.7122 27.9816C22.0348 30.8743 20.3803 34.5519 20.0109 38.489C19.945 39.191 20.1795 39.8933 20.6547 40.4158C21.132 40.9403 21.8127 41.2413 22.5226 41.2413H28.165C29.4047 41.2413 30.477 40.3171 30.6593 39.0915C30.8556 37.7727 31.4106 36.5358 32.253 35.5097L24.7122 27.9816Z" fill={fillColor}/>
                <path d="M48.9782 48.614H27.0218C26.4393 48.614 25.9671 48.1414 25.9671 47.5582C25.9671 46.9751 26.4393 46.5024 27.0218 46.5024H48.9782C49.5605 46.5024 50.0329 46.9751 50.0329 47.5582C50.0329 48.1414 49.5605 48.614 48.9782 48.614Z" fill={fillColor}/>
                <path d="M43.4848 52.793H32.498C31.9157 52.793 31.4433 52.3201 31.4433 51.7372C31.4433 51.1541 31.9157 50.6814 32.498 50.6814H43.4848C44.0673 50.6814 44.5395 51.1541 44.5395 51.7372C44.5395 52.3201 44.0673 52.793 43.4848 52.793Z" fill={fillColor}/>
                <path d="M39.0547 22.207V32.2375L49.1164 25.987C46.2257 23.7356 42.7265 22.419 39.0547 22.207Z" fill={fillColor}/>
                <path d="M55.989 38.4892C55.6355 34.7189 54.1031 31.1864 51.6222 28.3532L45.3413 39.0943C45.5245 40.3184 46.5959 41.2413 47.835 41.2413H53.4775C54.1875 41.2413 54.868 40.94 55.3457 40.4158C55.8203 39.8935 56.0552 39.191 55.989 38.4892Z" fill={fillColor}/>
            <rect x="1" y="0.5" width="74" height="74" rx="37" stroke="#DCC8F5"/>
        </svg>
    )
};

export default EmptyScoreIcon;