import {ICON_LIB} from '../../../../common/CircleIconView/CircleIconView';
import {FormComponentLabelEnum} from '../FormEnum';
interface BasicComponentsObject {
  title: string;
  icon?: string;
  iconLib?: string;
}

interface BasicComponentsType {
  [key: string]: BasicComponentsObject;
}

export const BasicComponents: BasicComponentsType = {
  textfield: {
    title: FormComponentLabelEnum.textfield,
    icon: 'format-color-text',
    iconLib: ICON_LIB.MaterialCommunityIcons,
  },
  textarea: {
    title: FormComponentLabelEnum.textarea,
    icon: 'card-text-outline',
    iconLib: ICON_LIB.MaterialCommunityIcons,
  },
  email: {
    title: FormComponentLabelEnum.email,
    icon: 'alternate-email',
    iconLib: ICON_LIB.MaterialIcons,
  },
  checkbox: {
    title: FormComponentLabelEnum.checkbox,
    icon: 'checksquare',
    iconLib: ICON_LIB.AntIcon,
  },
  radio: {
    title: FormComponentLabelEnum.radio,
    icon: 'radio-btn-active',
    iconLib: ICON_LIB.Fontisto,
  },
  selectboxes: {
    title: FormComponentLabelEnum.selectboxes,
    icon: 'bars',
    iconLib: ICON_LIB.AntIcon,
  },
  select: {
    title: FormComponentLabelEnum.select,
    icon: 'form-dropdown',
    iconLib: ICON_LIB.MaterialCommunityIcons,
  },
  phoneNumber: {
    title: FormComponentLabelEnum.phoneNumber,
    icon: 'phone',
    iconLib: ICON_LIB.AntIcon,
  },
  date: {
    title: FormComponentLabelEnum.date,
    icon: 'calendar',
    iconLib: ICON_LIB.AntIcon,
  },
  paragraph: {
    title: FormComponentLabelEnum.paragraph,
    icon: 'paragraph',
    iconLib: ICON_LIB.FontAwesomeIcon,
  },
  image: {
    title: FormComponentLabelEnum.image,
    icon: 'image',
    iconLib: ICON_LIB.FontAwesomeIcon,
  },
  number: {
    title: FormComponentLabelEnum.number,
    icon: 'hashtag',
    iconLib: ICON_LIB.FontAwesomeIcon,
  },
  // button: {title: 'Button'},
  currency: {
    title: FormComponentLabelEnum.currency,
    icon: 'dollar',
    iconLib: ICON_LIB.FontAwesomeIcon,
  },
  file: {
    title: FormComponentLabelEnum.file,
    icon: 'cloud-upload',
    iconLib: ICON_LIB.FontAwesomeIcon,
  },
  rating: {
    title: FormComponentLabelEnum.rating,
    icon: 'star-o',
    iconLib: ICON_LIB.FontAwesomeIcon,
  },
  signature: {
    title: FormComponentLabelEnum.signature,
    icon: 'pencil',
    iconLib: ICON_LIB.FontAwesomeIcon,
  },
  customsurvey: {
    title: FormComponentLabelEnum.customsurvey,
    icon: 'form-select',
    iconLib: ICON_LIB.MaterialCommunityIcons,
  },
  columns: {
    title: FormComponentLabelEnum.columns,
    icon: 'columns',
    iconLib: ICON_LIB.FontAwesomeIcon,
  },
  panel: {
    title: FormComponentLabelEnum.panel,
    icon: 'folder-text-outline',
    iconLib: ICON_LIB.MaterialCommunityIcons,
  }
  // hidden: {title: 'Hidden input'},
  // checkBoxes: {title: 'Check Boxes'},
};

export const BasicComponentsKeys = Object.keys(BasicComponents);

export const HealthComponents = [
  // 'bloodpressure',
  // 'bmi',
  'chiefComplaint',
  'patientDemographics',
  'patientCommunication',
  'patientAddress',
  'emergencyContact',
  'vitals',
  'medications',
  'allergies',
  'conditions',
  'immunizations',
  'socialHistory',
  'familyHistory',
  'surgicalHistory',
  'subjectiveComplaint',
  'objectiveFindings',
  'assessment',
  'plan',
  'diagnosis',
  'pastMedicalHistory',
  'consentAndPreferredCommunication',
  'noteSubStatus',
  'noteStatus',
  'barriers',
  'diet',
  'exercise',
  'goals',
  'habits',
  'healthMaintenance',
  'homeMonitoring',
  'intervention',
  'labMonitoring',
  'medicationManagement',
  'symptomMonitoring',
];

export const HEALTH_COMPONENT_CAPABILITY_MAP: {[index: string]: string} = {
  conditions: 'Condition',
  immunizations: 'Immunization',
  allergies: 'AllergyIntolerance',
  familyHistory: 'FamilyHistory',
  socialHistory: 'SocialHistory',
  vitals: 'Observation',
  medications: 'MedicationStatement',
  surgicalHistory: 'Procedure',
  patientDemographics: 'Patient',
  patientCommunication: 'Patient',
  patientAddress: 'Patient',
  emergencyContact: 'Patient',
  diagnosis: 'Diagnosis',
  pastMedicalHistory: 'MedicalHistory'
};

export const CARE_PLAN_FORM_COMPONENTS: {[index: string]: string} = {
  barriers: 'barriers',
  goals: 'goals',
  symptomMonitoring: 'symptomMonitoring',
  medicationManagement: 'medicationManagement',
  diet: 'diet',
  exercise: 'exercise',
  habits: 'habits',
  homeMonitoring: 'homeMonitoring',
  labMonitoring: 'labMonitoring',
  healthMaintenance: 'healthMaintenance',
  intervention: 'intervention',
};
