import {useLazyQuery} from "@apollo/client";
import {Spinner, View} from "native-base";
import {useEffect, useState} from "react";
import {COMMON_ACTION_CODES} from "../../../../../constants/ActionConst";
import ConversationsQueries from "../../../../../services/Conversations/ConversationsQueries";
import {IScheduleMessageInterface} from "../interfaces";
import ScheduleMessageDrawer from "./ScheduleMessageDrawer";

const ScheduleMessageContainer = (props: any) => {
  const [stateData,setStateData]=useState({
    scheduleMessageData: [] as IScheduleMessageInterface[],
    loading: false,
  })
  const [getScheduleMessageData] = useLazyQuery(ConversationsQueries.GetScheduleMessageData, {
    fetchPolicy: 'no-cache',
  });

  const getScheduleData = async () =>{
    setStateData((prev)=>{
      return {
        ...prev,
        loading: true
      }
    })
    const scheduleMessageResp = await getScheduleMessageData({
      variables: {
        conversationUuid: props?.conversationUuid
      },
    }).catch((error) => {
      setStateData((prev)=>{
        return {
          ...prev,
          loading: false
        }
      })
    });
    if (scheduleMessageResp?.data?.messageSchedules.length) {
      setStateData((prev)=>{
        return {
          ...prev,
          scheduleMessageData: scheduleMessageResp?.data?.messageSchedules,
          loading: false
        }
      })
    } else {
      setStateData((prev)=>{
        return {
          ...prev,
          scheduleMessageData: [],
          loading: false
        }
      })
    }
  }

  useEffect(()=>{
    getScheduleData()
  },[])

  const refetchScheduleData = () => {
    getScheduleData()
  }

  const onFormCompleteAction = (actionCode: string, data?:any)=> {
    switch (actionCode) {
      case COMMON_ACTION_CODES.EDIT:
        onEditModeForSelectedMessage(data)
        break
      case COMMON_ACTION_CODES.SAVE:
        onSaveForSelectedMessage(data)
      break
      default:
        props.onFormCompleteAction(actionCode)
        break;
    }
  }

  const onEditModeForSelectedMessage = (data: IScheduleMessageInterface)=> {
    const scheduledMessageId = data?.id;
    const scheduledMessageList = [...stateData.scheduleMessageData]
    scheduledMessageList.forEach((messageObj)=> {
      if (messageObj.id === scheduledMessageId) {
        messageObj.isEditModeOn = !data.isEditModeOn;
        return
      }
    })
    setStateData(prev=> {
      return {
        ...prev,
        scheduleMessageData: [...scheduledMessageList]
      }
    })
  }
  const onSaveForSelectedMessage = (data: IScheduleMessageInterface)=> {
    const scheduledMessageId = data?.id;
    const scheduledMessageList = [...stateData.scheduleMessageData]
    scheduledMessageList.forEach((messageObj)=> {
      if (messageObj.id === scheduledMessageId) {
        messageObj.content = data.content;
        messageObj.isEditModeOn = !data.isEditModeOn;
        return
      }
    })
    setStateData(prev=> {
      return {
        ...prev,
        scheduleMessageData: [...scheduledMessageList]
      }
    })
  }
  return (
    <>
      <ScheduleMessageDrawer
        onFormCompleteAction={(actionCode: string, data: any) => {
         onFormCompleteAction(actionCode, data);
        }}
        scheduleMessageData={stateData?.scheduleMessageData}
        handleSendMessage={props?.handleSendMessage}
        loading= {stateData?.loading}
        refetchScheduleData= {refetchScheduleData}
      />
    </>
  );
};

export default ScheduleMessageContainer;
