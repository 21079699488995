import React from "react";
import { isWeb } from "../../../utils/platformCheckUtils";
import Svg, { Path } from 'react-native-svg';
import {StyleSheet} from 'react-native';

const CrossSvgIcon = (props: {
  isActive?: boolean;
  customActiveColor?: string;
  customColor?: string;
}) => {
  const strokeColor = props.isActive
  ? props.customActiveColor
    ? props.customActiveColor
    : ''
  : props.customColor
  ? props.customColor
  : '#D94D55';
  return (
    <>
      {isWeb() ? (
        <Svg
          style={styles.svg}
          fill="none"
          viewBox="0 0 24 24"
        >
          <Path
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M18 6L6 18M6 6l12 12"
          />
        </Svg>
      ) : null}
    </>
  );
}

const styles = StyleSheet.create({
  svg: {
    width: 'inherit',
    height: 'inherit',
  },
});

export default CrossSvgIcon;
