import React from 'react';
import { Path, Svg } from 'react-native-svg';
import { Colors } from '../../../styles';

const ExpandSvg = () => {
  return (
    <Svg width="6" height="7" viewBox="0 0 6 7" fill="none">
    <Path d="M3 2.5L1 4.5L5 4.5L3 2.5Z" fill={Colors.Custom.GRAY350} stroke={Colors.Custom.GRAY350} stroke-linecap="round" stroke-linejoin="round"/>
    </Svg>
  );
};

export default ExpandSvg;
