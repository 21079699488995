import React from 'react';
import ReactDOM from 'react-dom';
import {ReactComponent} from '@foldhealth/formio-react';
import {Formio} from '@foldhealth/formiojs';
import CustomWrapper from '../CustomWrapper/CustomWrapper';
import KeyField from '../../EditFormFields/KeyField';
import ConditionalFields from '../../EditFormFields/ConditionalFields';
import BasicFields from '../../EditFormFields/BasicFields';
import CustomClassComponent from '../CustomWrapper/CustomClassComponent';
import Select from './Select';

export default class SelectComponent extends CustomClassComponent {
  static get builderInfo() {
    return {
      title: 'Select',
      group: 'advanced',
      documentation: '',
      schema: SelectComponent.schema(),
    };
  }

  labelIsHidden() {
    return false;
  }

  static schema() {
    return ReactComponent.schema({
      type: 'customSelect',
      label: 'Select',
      key: 'customSelect'
    });
  }

  attachReact(element: any) {
    return ReactDOM.render(
      <CustomWrapper formOptionData={this.options}>
        <Select
          options={this.options}
          disabled={this.disabled}
          component={this.component}
          validateRef={this.validateRef}
          onChange={this.updateValue}
          setValueRef={this.setValueRef}
        />
      </CustomWrapper>,
      element
    );
  }

  static editForm = function () {
    return {
      key: 'display',
      components: [
        ...BasicFields,
        {
          type: 'datagrid',
          input: true,
          // label: 'Values',
          label: 'Options',
          key: 'data.values',
          tooltip:
            'Values to use as the data source. Labels are shown in the select field. Values are the corresponding values saved with the submission.',
          weight: 10,
          reorder: true,
          defaultValue: [{label: '', value: '', iconLib: '', iconName: ''}],
          components: [
            {
              label: 'Label',
              key: 'label',
              input: true,
              type: 'oldtextfield',
            },
            {
              label: 'Value',
              key: 'value',
              input: true,
              type: 'oldtextfield',
              allowCalculateOverride: true,
              hidden: true,
              clearOnHide: false,
              calculateValue: 'value = _.camelCase(row.label);',
            },
            {
              label: 'Custom Icon',
              key: 'customIcon',
              input: true,
              hidden: true,
              type: 'custom',
            },
          ],
          conditional: {
            json: {'===': [{var: 'data.dataSrc'}, 'values']},
          },
        },
        // KEPT FOR FUTURE
        // {
        //   type: 'checkbox',
        //   label: 'Hide Label',
        //   tooltip:
        //     'Hide the label or title of this component. This allows you to show the label in the form builder, but not when the form is shown to the member.',
        //   key: 'hideLabel',
        //   input: true,
        // },
        {
          type: 'checkbox',
          label: 'Allow users to clear selected value?',
          tooltip: 'This allows user to clear selected values in dropdown.',
          key: 'allowClear',
          input: true,
        },
        {
          type: 'checkbox',
          label: 'Allow users to search within values',
          tooltip: 'This allows user to search within values in dropdown.',
          key: 'showSearch',
          input: true,
        },
        ...ConditionalFields,
        ...KeyField,
      ],
    };
  };
}

Formio.Components.addComponent('customSelect', SelectComponent);
