import {StyleSheet} from 'react-native';
import {Colors} from '../../../../styles';

export const styles = StyleSheet.create({
  noteContainer: {
    marginTop: 10,
  },
  noteStack: {
    padding: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,
    elevation: 4,
  },
  ml5: {
    marginLeft: 5,
  },
  saveBtn: {
    backgroundColor: Colors.Custom.PrimaryBtnColor,
  },
  discardBtn: {
    backgroundColor: '#F4BC14',
  },
  cancelBtn: {
    backgroundColor: Colors.Custom.BorderColor,
  },
  btnGroup: {
    marginTop: 10,
  },
});
