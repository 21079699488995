import { HStack, Text , View ,Icon} from 'native-base';
import { IUserInputField } from '../../FlowComponent/StateNodes/FlowNodeInterface';
import { formatTemplate } from './FormOutputDisplayTemplateHelper';
import Feather from 'react-native-vector-icons/Feather';
import { Colors } from '../../../../../styles';
interface IProps {
  userInputField?: IUserInputField;
  userInputFieldList?: Array<IUserInputField>;
}

export const FormOutputDisplayTemplate = (props: IProps) => {
  const templateValue = formatTemplate(props.userInputField?.template, props.userInputField?.templateVarList, props.userInputFieldList);
  return templateValue ? (
    <HStack
      space={3}
      backgroundColor={Colors.Custom.infoBannerBgColor}
      padding={3}
    >
      {/* <Feather name="info" size={20} color={Colors.Custom.infoColor} /> */}
      <Text fontWeight={400} fontSize={14} lineHeight={16.8}>
        {templateValue}
      </Text>
    </HStack>
  ) : (
    <></>
  );
};

export default FormOutputDisplayTemplate;
