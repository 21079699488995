import React from 'react';
import { IAuditState, IInitialBatchLogsCountData, IMappedAccountUsers } from "../Audit/AuditInterfaces";
import AuditRender from "../Audit/AuditRender";

type IActivityTabs = {
    selectedTab: any;
    taskLogAuditData: {
        loadingAuditData: { initialAuditDataLoading: boolean, paginationLoading: boolean };
        taskLogData: IAuditState[];
        aggregate: number | undefined;
    }
    accountUsersMappedById: { [key: string]: IMappedAccountUsers }
    mappingUuidWithData: { [key: string]: any }
    handleActions: any;
    offset: number;
    addCommentLoading: boolean;
    totalTransactionsFetchedCount: number;
    initialBatchLogsCountData: IInitialBatchLogsCountData;
    auditResource: string;
    taskId?: string;
    journeyDetails?: any;
}

const ActivityTabs = (props: IActivityTabs) => {
    return (
        <AuditRender
            auditData={{
                loadingAuditData: props?.taskLogAuditData?.loadingAuditData,
                logData: props?.taskLogAuditData?.taskLogData,
                aggregate: props?.taskLogAuditData?.aggregate
            }}
            accountUsersMappedById={props?.accountUsersMappedById}
            uuidMappingToData={props?.mappingUuidWithData}
            handleActions={props?.handleActions}
            offset={props?.offset}
            addCommentLoading={props?.addCommentLoading}
            hideShowMore={!props?.taskId}
            journeyDetails={props?.journeyDetails}
            totalTransactionsFetchedCount={props?.totalTransactionsFetchedCount}
            initialBatchLogsCountData={props?.initialBatchLogsCountData}
            auditResource={props?.auditResource}
        />
    )
}

export default ActivityTabs
