import {GROUP_MEMBER_TYPE} from "../../../../../constants/StringConst";
import {IMessageReadInfoResp, ISectionDataListParams} from "./interface";

export const sortMemberByTypeForMessageLastSeen = (memberData: IMessageReadInfoResp): ISectionDataListParams[] => {
  let users: any = memberData?.userList;
  const patient: any = memberData?.patientData;
  let familyMembers: any = memberData?.familyMembers;


  if (users?.length) {
    users = users.sort((firstUser: any, secUser: any) => {
      if (firstUser?.user?.name < secUser?.user?.name ){
        return -1;
      }
      if (firstUser?.user?.name > secUser?.user?.name ){
        return 1;
      }
      return 0;
    });
  }
  if (familyMembers?.length) {
    familyMembers = familyMembers.sort((firstContact: any, secContact: any) => {
      if (firstContact?.contact?.name < secContact?.contact?.name ) {
        return -1;
      }
      if (firstContact?.contact?.name > secContact?.contact?.name ) {
        return 1;
      }
      return 0;
    });
  }

  const finalMemberData = [
    {
      id: '0',
      title: 'Patient',
      data: patient || [],
    },
    {
      id: '1',
      data: familyMembers || [],
    },
    {
      id: '2',
      title: 'User',
      data: users || [],
    },
  ];
  return finalMemberData;
};
