import React from 'react';
import {ILinkComponentProps} from '../interfaces';
import {Input, View} from 'native-base';
import {Colors} from '../../../../../styles';
import ReactPlayer from 'react-player';
import {useIntl} from 'react-intl';

const LinkComponent = (props: ILinkComponentProps) => {
  const [state, setState] = React.useState({
    isFocused: false,
  });
  const intl = useIntl();
  return (
    <View>
      <Input
        borderWidth={0}
        value={props.link || ''}
        onChangeText={(text) => {
          props.onChangeLink(text);
        }}
        onKeyPress={(e: any) => {
          if (e.key === 'Backspace' && !props.link) {
            props.onChangeLink('');
          }
        }}
        textDecorationColor={Colors.Custom.mainPrimaryPurple}
        textDecorationLine={'underline'}
        color={Colors.Custom.mainPrimaryPurple}
        fontWeight={'500'}
        isFocused={state.isFocused}
        onBlur={() => {
          setState((prev) => {
            return {
              ...prev,
              isFocused: false,
            };
          });
        }}
        placeholder={intl.formatMessage({id: 'linkComponentPlaceholder'})}
        backgroundColor={'transparent'}
      />
    </View>
  );
};

export default LinkComponent;
