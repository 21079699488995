import {useEffect, useState} from 'react';

export const useMouseMovementCheck = (inactivityDuration: number) => {
  const [isInactive, setIsInactive] = useState(false);
  let timeoutId: any;

  useEffect(() => {
    const resetTimer = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setIsInactive(true);
      }, inactivityDuration);
    };

    const handleActivity = () => {
      setIsInactive(false);
      resetTimer();
    };

    const handleMouseMove = () => {
      handleActivity();
    };

    const handleKeyDown = () => {
      handleActivity();
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      clearTimeout(timeoutId);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [inactivityDuration]);

  return isInactive;
};
