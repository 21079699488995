import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  container: {
    padding: 16,
    flex: 1,
    overflow: 'scroll',
    alignItems: 'center',
  },
});
