import dagre from '@dagrejs/dagre';
import { useEffect } from 'react';
import {
  Edge,
  Node,
  Position,
  ReactFlowState,
  useReactFlow,
  useStore,
} from 'reactflow';
import { reArrangeOptionNodeNode } from '../../../../utils/commonUtils';

// the layout direction (T = top, R = right, B = bottom, L = left, TB = top to bottom, ...)
export type Direction = 'TB' | 'LR' | 'RL' | 'BT';

export type Options = {
  direction: Direction;
  isExecutionLog: boolean;
};



const positionMap: Record<string, Position> = {
  T: Position.Top,
  L: Position.Left,
  R: Position.Right,
  B: Position.Bottom,
};

const nodeCountSelector = (state: ReactFlowState) => state.nodeInternals.size;
const nodesInitializedSelector = (state: ReactFlowState) =>
  Array.from(state.nodeInternals.values()).every(
    (node) => node.width && node.height
  );

function useAutoLayout(options: Options) {
  const { direction, isExecutionLog } = options;
  const nodeCount = useStore(nodeCountSelector);
  const nodesInitialized = useStore(nodesInitializedSelector);
  const { getNodes, getEdges, setNodes, setEdges, fitView } = useReactFlow();

  useEffect(() => {
    // only run the layout if there are nodes and they have been initialized with their dimensions
    if (!nodeCount || !nodesInitialized) {
      return;
    }

    const dagreGraph:any = new dagre.graphlib!.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));
    dagreGraph.setGraph({ rankdir: direction });
    dagreGraph['#nodes'] = {}
    dagreGraph['#edgeCount'] = 0
    dagreGraph['#nodeCount'] = 0
    dagreGraph['#preds'] = {}
    dagreGraph['#sucs'] = {}
    dagreGraph['#out'] = {}

    const data1 = reArrangeOptionNodeNode(getNodes(), getEdges())
    const nodes = data1.nodes;
    const edges = data1.edges;
    nodes.forEach((node: Node) => {
      dagreGraph.setNode(node.id, {
        width: isExecutionLog? (node?.width || 0) + 350 : (node.width || 0) + 100 ?? 0,
        height: isExecutionLog? (node?.height || 0) + 150 : (node.height || 0) ?? 0
      });
    });

    edges.forEach((edge: Edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    setNodes((nodes) =>
      nodes.map((node) => {
        if(node?.data?.isSkipFitView){
          node.data.isSkipFitView = false;
          return node;
        }
        const { x, y } = dagreGraph.node(node.id);

        return {
          ...node,
          sourcePosition: positionMap[direction[1]],
          targetPosition: positionMap[direction[0]],
          position: { x, y },
          style: { opacity: 1 },
        };
      })
    );

    setEdges((edges) =>
      edges.map((edge) => ({ ...edge, style: { opacity: 1 } }))
    );
  }, [
    nodeCount,
    nodesInitialized,
    getNodes,
    getEdges,
    setNodes,
    setEdges,
    fitView,
    direction
  ]);
}

export default useAutoLayout;
