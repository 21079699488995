import {DATE_FORMATS, GROUP_MEMBER_TYPE, GROUP_TYPES} from '../../../../constants';
import { getDateStrFromFormat } from '../../../../utils/DateUtils';
import { Colors } from '../../../../styles/Colors';
import { IConversationMessage, ISenderContact, ISenderUser } from '../../../RightSideContainer/TeamInbox/Conversations/interfaces';
import {
  IConversationData,
  IMessageBoxData,
  IMsgAttachment,
} from '../../../RightSideContainer/TeamInbox/Conversations/interfaces';
import {MessageActionID} from '../../MessageAction/MessageActionView';
import {IReplyMessageObject} from './interfaces';
import { FlexAlignType } from 'react-native';
import { MESSAGE_CUSTOM_CARD_KEY } from '../../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MessagingCustomCard/CustomCardConst';
import { isWeb } from '../../../../utils/platformCheckUtils';
import { getSenderName, isBroadCastAndPrivateGroup } from '../../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MessagingUtils';
import {IGroupMember} from '../../../../services/Conversations/interfaces';
import {IIsSeenByUsers} from '../../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/GroupMemberList/interfaces';
import { ICallLogEntry } from '../../../RightSideContainer/TeamInbox/CallLogs/interface';
import { CallDirections, TWILIO_CALL_STATUS } from '../../../RightSideContainer/TeamInbox/CallLogs/CallLogsConst';
import { CHANNEL_TYPE } from '../../../RightSideContainer/TeamInbox/Conversations/ConversationConst';
import moment from 'moment';

export const getParentMessageData = (
  parentMessage?: IMessageBoxData
): IReplyMessageObject => {
  if (parentMessage) {
    return {
      id: parentMessage?.id,
      messageType: parentMessage?.messageType || -1,
      position: parentMessage?.position || 'left',
      text: parentMessage?.displayContent || parentMessage?.content || '',
      dateStr:
        (isValidCustomISODate(parentMessage?.dateStr || '')
          ? getDateStrFromFormat(
              parentMessage?.dateStr,
              DATE_FORMATS.MESSAGE_DATE_FORMAT
            )
          : parentMessage?.dateStr) || '',
      senderFullName:
        getSenderName(parentMessage) || parentMessage?.senderFullName || 'You',
      attachments: parentMessage?.msgAttachment || [],
      private: parentMessage.private,
      uuid: parentMessage?.uuid || '',
    };
  } else {
    return {} as IReplyMessageObject;
  }
};



export const showMessageOption = (
  actionId: any,
  conversation: IConversationData,
  position: string,
  contactInfo: any,
  attachments: IMsgAttachment[],
  isSenderLoggedInUser: boolean,
  text?: string,
) => {
  if(actionId === MessageActionID.assignType){
    if (isBroadCastAndPrivateGroup(conversation)) {
      return true;
    }
    return false;
  }
  if (conversation?.conversationInbox) {
    if (
      conversation?.conversationInbox?.channelType === 'Channel::TwilioSms' ||
      conversation?.conversationInbox?.channelType === 'Channel::Email' ||
      conversation?.conversationInbox?.channelType ===  CHANNEL_TYPE.CHANNEL_EFAX
    ) {
      return showCurrentOption(actionId);
    } else if (!contactInfo?.contactData?.patient) {
      if (actionId === MessageActionID.info) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else if (
    !contactInfo?.contactData?.patient &&
    checkIfBroadcastGroup(conversation?.groupConversation?.groupType?.code) ===
    false
  ) {
    // showing info option and not create note for internal chats
    if (actionId === MessageActionID.createNote) {
      return true;
    } else {
      return false;
    }
  } else if (attachments.length) {
    if (
      (actionId === MessageActionID.edit && text) ||
      actionId === MessageActionID.reply ||
      actionId === MessageActionID.delete ||
      (actionId === MessageActionID.info && position === 'right') ||
      (actionId === MessageActionID.markAsReadUnread &&
        !isSenderLoggedInUser) ||
      (actionId === MessageActionID.assignType && (position === 'right' || position === 'left'))
    ) {
      return false;
    } else {
      return true;
    }
  } else {
    if (conversation?.groupConversation) {
      if (
        conversation?.groupConversation?.groupType?.code ===
        GROUP_TYPES.USER_BROADCAST ||
        conversation?.groupConversation?.groupType?.code ===
        GROUP_TYPES.PATIENT_BROADCAST
      ) {
        return showCurrentOption(
          actionId,
          conversation?.groupConversation?.groupType?.code
        );
      } else {
        return false;
      }
    }
    return false;
  }
};

const showCurrentOption = (actionId: any, groupType?: string) => {
  if (
    actionId === MessageActionID.reply ||
    actionId === MessageActionID.delete ||
    actionId === MessageActionID.edit ||
    (actionId === MessageActionID.info &&
      groupType !== GROUP_TYPES.USER_BROADCAST)
  ) {
    return true;
  } else {
    return false;
  }
};

export function detectURLs(message: string): string | undefined {
  const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
  const urlString = message?.match(urlRegex)?.toString();
  if (urlString && urlString?.charAt(urlString?.length - 1) === ')') {
    return urlString?.substring(0, urlString?.length - 1);
  }
  return urlString;
}

// export const getArticleFromMessageText = (message: string) => {
//   const titleString =  !message?.split('](')[0]?.split('[')[0] ? message?.split('](')[1] : message?.split('](')[0];
//   const title = !message?.split('](')[0]?.split('[')[0] ? message?.split('](')[1]?.split(')')[1] : titleString;
//   return [title,message?.split('](')[0]?.split('[')[1] || ''];
// };
export const getArticleFromMessageText = (message: string) => {
  return message.split('](')[0].split('[');
};


const checkIfBroadcastGroup = (groupType: string) => {
  if (
    groupType === GROUP_TYPES.USER_BROADCAST ||
    groupType === GROUP_TYPES.PATIENT_BROADCAST
  ) {
    return true;
  } else {
    return false;
  }
};

export const isAutoReplyMessage = (args: {
  message?: IConversationMessage
}) => {
  const {message} = args
  return message?.contentAttributes?.isAutoResponse
}

export const goToMessages = (parentUuid: string) => {
  const element: any = document.getElementById(parentUuid);
  if(!element){
    return
  }
  element.scrollIntoView({
    behavior: 'smooth',
    block: 'nearest',
    inline: 'start',
  });
  element.style.backgroundColor = Colors.Custom.Gray100;
  element.style.borderRadius = 16;
  setTimeout(() => {
    element.style.backgroundColor = 'transparent';
    element.style.borderRadius = 0;
  }, 2000);
  return element
  // element.scrollIntoView({
  //   behavior: 'smooth',
  //   // block: 'end',
  //   // inline: 'nearest',
  // });
};
export const isHTML = (text:string) => {
  return /<\/?[a-z][\s\S]*>/i.test(text)
}
export const getDurationInFormat = (callLogEntry: ICallLogEntry) => {
  let duration = 0;
  if (callLogEntry?.direction === CallDirections.Incoming && callLogEntry?.status === TWILIO_CALL_STATUS.COMPLETED && callLogEntry?.childCallDuration){
    duration = callLogEntry?.childCallDuration
  } else if (callLogEntry?.duration) {
    duration = Number(callLogEntry?.duration);
  }

  if (duration >=60) {
    const minutes = Math.floor(duration / 60);
    const seconds = duration % 60;
    return `${minutes}m ${seconds}s`;
  } else {
    return duration > 0 ? `${duration}s` : '';
  }
};

export const getFlexAlignType = (content: any) => {
  const resourceCode = content?.resourceCode;
  let result: FlexAlignType;
  switch (resourceCode) {
    case MESSAGE_CUSTOM_CARD_KEY.INCOMING_COMPLETED_CALL_CARD:
      result = 'flex-start';
      break;
    case MESSAGE_CUSTOM_CARD_KEY.OUTGOING_COMPLETED_CALL_CARD:
      result = 'flex-end';
      break;
    case MESSAGE_CUSTOM_CARD_KEY.INCOMING_MISSED_CALL_CARD:
    case MESSAGE_CUSTOM_CARD_KEY.OUTGOING_MISSED_CALL_CARD:
      result = 'center';
      break;
    default:
      result = 'center';
  }
  return result;
};

export const isMissedCallMessage = (resourceCode: string) => {
  return (
    resourceCode === MESSAGE_CUSTOM_CARD_KEY.INCOMING_MISSED_CALL_CARD ||
    resourceCode === MESSAGE_CUSTOM_CARD_KEY.OUTGOING_MISSED_CALL_CARD
  );
}

export const getCallMessageContainerPaddingConfig = (resourceCode: string) => {
  if (!isWeb() || isMissedCallMessage(resourceCode)) {
    return {};
  }
  return {
    paddingVertical: 20,
    paddingHorizontal: 16,
  };
}

export const getSeenByAndDeliveredToMemberList = (groupMembers: IGroupMember[], isSeenByUsers: IIsSeenByUsers[])=> {
  const seenByUsers: IGroupMember[] = []
  const deliveredToUsers: IGroupMember[] = []
  groupMembers.forEach((item: IGroupMember)=> {
    const checkIfSeen = (member: IIsSeenByUsers) => {
      return member.memberId === item?.groupUserId && member.isSeen === true;
    };
    if (isSeenByUsers?.some(checkIfSeen)) {
      seenByUsers.push(item)
    } else {
      deliveredToUsers.push(item)
    }
  })
  return {seenByUsers, deliveredToUsers}
}

export const isValidCustomISODate = (dateString: string) => {
  const isoDatePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{1,}Z$/;
  return isoDatePattern.test(dateString);
}

export const getCallActionMessageTime = (dateTime: string) => {
  let updatedTime = dateTime;
  if (updatedTime && (updatedTime.includes('Z') || updatedTime.includes('z'))) {
    updatedTime = updatedTime;
  } else if (updatedTime) {
    updatedTime = updatedTime + 'Z';
  }
  if (updatedTime) {
    return moment(updatedTime).format(DATE_FORMATS.TIME_SELECT_FORMAT);
  }
  return updatedTime;
};

export const isImageExtension = (extension: string) => {
  const acceptedExtensions = ['jpeg', 'jpg', 'gif', 'png', 'bmp'];
  return acceptedExtensions.includes(extension?.toLowerCase());
}


export const getSenderUserData = ({
  senderType,
  senderUser,
  senderContact,
}: {
  senderType: string;
  senderUser?: ISenderUser;
  senderContact?: ISenderContact;
}): {
  userId?: number;
  userType: string;
} => {
  if (senderType?.toLowerCase() == 'user') {
    return {
      userId: senderUser?.id,
      userType: GROUP_MEMBER_TYPE.USER,
    };
  } else if (senderType?.toLowerCase() == 'contact') {
    return {
      userId: senderContact?.id,
      userType: GROUP_MEMBER_TYPE.CONTACT,
    };
  }
  return {} as any;
};

export const goToMessagesForEmail = (parentUuid: string) => {
  const element: any = document.getElementById(parentUuid);
  if (!element) {
    return;
  }
  element.scrollIntoView({
    block: 'nearest',
    inline: 'start',
  });
  return element;
};
