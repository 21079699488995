import React from 'react';
import ReactDOM from 'react-dom';
import {ReactComponent} from '@foldhealth/formio-react';
import {Formio} from '@foldhealth/formiojs';
import CustomWrapper from '../CustomWrapper/CustomWrapper';
import KeyField from '../../EditFormFields/KeyField';
import ConditionalFields from '../../EditFormFields/ConditionalFields';
import BasicFields from '../../EditFormFields/BasicFields';
import CustomClassComponent from '../CustomWrapper/CustomClassComponent';
import ColorPicker from './ColorPicker';

export default class ColorPickerComponent extends CustomClassComponent {
  static get builderInfo() {
    return {
      title: 'Color Picker',
      group: 'advanced',
      documentation: '',
      schema: ColorPickerComponent.schema(),
    };
  }

  labelIsHidden() {
    return false;
  }

  static schema() {
    return ReactComponent.schema({
      type: 'colorPicker',
      label: 'Color Picker',
      key: 'colorPicker'
    });
  }

  attachReact(element: any) {
    return ReactDOM.render(
      <CustomWrapper formOptionData={this.options}>
        <ColorPicker
          options={this.options}
          disabled={this.disabled}
          component={this.component}
          validateRef={this.validateRef}
          onChange={this.updateValue}
          setValueRef={this.setValueRef}
        />
      </CustomWrapper>,
      element
    );
  }

  static editForm = function () {
    return {
      key: 'display',
      components: [
        ...BasicFields,
        ...ConditionalFields,
        ...KeyField,
      ],
    };
  };
}

Formio.Components.addComponent('colorPicker', ColorPickerComponent);
