import React from 'react';
import {TASK_PRIORITY_CODES} from '../../constants/MlovConst';
import HighPriorityIcon from './HighPriorityIcon';
import LowPriorityIcon from './LowPriorityIcon';
import MediumPriorityIcon from './MediumPriorityIcon';

const TaskPriorityIcon = (props: {priorityCode: string}) => {
  switch (props.priorityCode) {
    case TASK_PRIORITY_CODES.HIGH:
      return <HighPriorityIcon />;
    case TASK_PRIORITY_CODES.MEDIUM:
      return <MediumPriorityIcon />;
    case TASK_PRIORITY_CODES.LOW:
      return <LowPriorityIcon />;
    default:
      return <></>;
  }
};

export default TaskPriorityIcon;
