import React from 'react';
import {isWeb} from '../../../utils/platformCheckUtils';
import {Image} from 'native-base';

const GroupIcon = (props: {width?: number, height?: number, size?: number}) => {
  const {width, height, size} = props
  return (
    <>
      {isWeb() ? (
        <svg width={width ? width : '20'}
        height={height ? height : '19'} viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.5195 9.91221H16.8456C17.0162 10.3793 17.1094 10.8834 17.1094 11.4089V17.735C17.1094 17.9541 17.0713 18.1644 17.0019 18.3599H19.7692C20.8029 18.3599 21.6438 17.5189 21.6438 16.4853V13.0366C21.6439 11.3138 20.2423 9.91221 18.5195 9.91221Z" fill="#98A2B3"/>
        <path d="M4.53443 11.4089C4.53443 10.8834 4.62764 10.3794 4.79826 9.91226H3.12441C1.40161 9.91226 0 11.3139 0 13.0367V16.4854C0 17.519 0.84094 18.36 1.87465 18.36H4.64193C4.57256 18.1644 4.53443 17.9541 4.53443 17.7351V11.4089Z" fill="#98A2B3"/>
        <path d="M12.7353 8.28449H8.90861C7.18581 8.28449 5.7842 9.6861 5.7842 11.4089V17.7351C5.7842 18.0802 6.06396 18.36 6.40908 18.36H15.2348C15.5799 18.36 15.8597 18.0802 15.8597 17.7351V11.4089C15.8597 9.6861 14.4581 8.28449 12.7353 8.28449Z" fill="#98A2B3"/>
        <path d="M10.8219 0C8.75004 0 7.06444 1.6856 7.06444 3.75754C7.06444 5.16291 7.84011 6.39031 8.98563 7.03472C9.52896 7.34036 10.1554 7.51503 10.8219 7.51503C11.4885 7.51503 12.1149 7.34036 12.6582 7.03472C13.8038 6.39031 14.5794 5.16286 14.5794 3.75754C14.5794 1.68564 12.8938 0 10.8219 0Z" fill="#98A2B3"/>
        <path d="M4.22385 3.50229C2.67433 3.50229 1.41374 4.76288 1.41374 6.3124C1.41374 7.86192 2.67433 9.12251 4.22385 9.12251C4.61691 9.12251 4.99119 9.04109 5.3312 8.89474C5.91905 8.64165 6.40375 8.19364 6.70368 7.63246C6.9142 7.2386 7.03396 6.78928 7.03396 6.3124C7.03396 4.76292 5.77337 3.50229 4.22385 3.50229Z" fill="#98A2B3"/>
        <path d="M17.42 3.50229C15.8705 3.50229 14.6099 4.76288 14.6099 6.3124C14.6099 6.78933 14.7297 7.23865 14.9402 7.63246C15.2401 8.19368 15.7248 8.64169 16.3127 8.89474C16.6527 9.04109 17.027 9.12251 17.42 9.12251C18.9695 9.12251 20.2301 7.86192 20.2301 6.3124C20.2301 4.76288 18.9695 3.50229 17.42 3.50229Z" fill="#98A2B3"/>
        </svg>
      ) : (
        <Image
          resizeMode="contain"
          size={size ? size : '24px'}
          source={require('../../../assets/images/Contact/member_group.png')}
          alt="image"
        />
      )}
    </>
  );
};

export default GroupIcon;
