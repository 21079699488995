import React, {useState} from 'react';
import {ICreateNoteFromMessageProps} from './interfaces';
import {Drawer, notification} from 'antd';
import AddOrUpdatePatientNote from '../../../../../PersonOmniView/MiddleContainer/CareTimeline/AddOrUpdatePatientNote';
import {
  filterUsersDataUsingLocationGroupId,
  getAccountUUID,
  getDefaultFormIdForClinicalNote,
  getLocationGroupIdFromLocationId,
  getUserUUID,
  isPhysicianRole,
} from '../../../../../../utils/commonUtils';
import {
  getEhrConfig,
  getUserDetailByUserUuid,
} from '../../../../../PersonOmniView/MiddleContainer/CareTimeline/CareTimelineUtils';
import {CommonDataContext} from '../../../../../../context/CommonDataContext';
import {getFormDataFromLeadData} from '../../../../Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateUtils';
import {getEHRName, getResourceAbilities} from '../../../../../../utils/capabilityUtils';
import {FHIR_RESOURCE} from '../../../../../../constants/FhirConstant';
import {GET_USERS_FOR_CALENDAR_WITH_LOCATION} from '../../../../../../services/User/UserQueries';
import {useQuery} from '@apollo/client';
import TimeLineSkeletonLoader from '../../../../../common/TimeLineSkeletonLoader/TimeLineSkeletonLoader';
import {USER_ROLE_CODES} from '../../../../../../constants/MlovConst';
import './styles.scss';
import {
  NARRATIVE,
  PHONE_NOTE_FORM_CODE,
} from '../../../../../PersonOmniView/MiddleContainer/PatientNotes/constants';
import {GET_FORM_BY_CODE} from '../../../../../../services/Forms/FormsQueries';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../../constants/Configs';
import {
  IPatientNoteCardProps,
  ViewType,
} from '../../../../../PersonOmniView/MiddleContainer/interfaces';
import {DocStatus} from '../../../../../PersonOmniView/MiddleContainer/PatientNotes/interfaces';
import BaseService from '../../../../../../services/CommonService/BaseService';
import {useToast} from 'native-base';
import { ToastType, showToast } from '../../../../../../utils/commonViewUtils';
import { useIntl } from 'react-intl';
import { useToast as useCustomToast } from '../../../../../Toast/ToastProvider';
import useEHRCapabilities from '../../../../../../screens/BusinessStudio/useEHRCapabilities';
const CreateNoteFromMessage = (props: ICreateNoteFromMessageProps) => {
  const accountUUID = getAccountUUID();
  const toast = useToast();
  const customToast = useCustomToast();
  const intl = useIntl();
  const userUUID = getUserUUID();
  const {
    copiedMessages,
    conversationUUID,
    onClose,
    isVisible,
    contactInfo,
    messageIds,
  } = props;
  const isPhysicianUser = isPhysicianRole();
  const commonData = React.useContext(CommonDataContext);
  const isSideCarContext = commonData.sidecarContext?.isSidecar;
  const userSettings = commonData.userSettings;
  const defaultFormIdForElation = getDefaultFormIdForClinicalNote(userSettings);
  const formmatedContactData = getFormDataFromLeadData(
    contactInfo.contactData,
    commonData
  );
  const accountLocationUuid = formmatedContactData.accountLocationUuid || formmatedContactData?.accountLocation?.uuid;
  const locationGroupId = getLocationGroupIdFromLocationId(
    commonData?.accountLocationListWithEHR,
    accountLocationUuid
  );
  const ehrCapabilities = useEHRCapabilities({locationId: accountLocationUuid});
  const currentEHR = getEHRName(accountLocationUuid, '');
  const ehrConfig = getEhrConfig(currentEHR || '');
  const resourceAbilities = getResourceAbilities(
    FHIR_RESOURCE.DOCUMENT_REFERENCE,
    '',
    accountLocationUuid
  );
  const elationFormData = {
    categories: resourceAbilities?.allowedCategories,
    noteComponents: resourceAbilities?.allowedNoteComponents,
  };
  const [defaultPhoneNote, setDefaultPhoneNote] = useState({
    formId: '',
    name: '',
  });
  const [componentState, setComponentState] = useState({
    currentUserData: {} as any,
  });

  const createClinicalMessageNote = async (body: any) => {
    const baseService = BaseService.getSharedInstance().axios;
    const url = `crm-nest/api/contact/clinical-message-note`;
    return baseService.post(url, body);
  };

  const {data: accountUsersData, loading: accountUsersLoading} = useQuery(
    GET_USERS_FOR_CALENDAR_WITH_LOCATION,
    {
      fetchPolicy: 'no-cache',
      variables: {
        accountId: accountUUID,
        roleCode: USER_ROLE_CODES.EMPLOYER,
      },
      onCompleted: (data) => {
        const currentUserData = getUserDetailByUserUuid(
          data?.users || [],
          userUUID
        );
        setComponentState((prev) => ({
          ...prev,
          currentUserData: currentUserData,
        }));
      },
      onError: (error) => {
        notification.error({
          message: 'Failed to fetch account users',
        });
      },
    }
  );

  const getNoteConfigureForAddNewNote = () => {
    return {
      createdByUserName: componentState.currentUserData?.name,
      status: DocStatus.PRELIMINARY,
      authorUuid: componentState.currentUserData?.uuid,
      linkedAppointmentId: undefined,
      resourceId: '',
      isAllowToDelete: false,
      isAllowToPrint: false,
      isAllowToUnlock: false,
      isAllowToAutoSave: !ehrConfig?.isElation,
      isAllowToSign: !ehrConfig?.isElation,
      isAllowToSave: true, 
      formId: ehrConfig?.isElation ? defaultFormIdForElation : defaultPhoneNote?.formId,
      isFoldDrivenNote: !ehrConfig?.isElation
    } as IPatientNoteCardProps;
  };

  const getPrefillData = () => {
    return [
      {
        label: NARRATIVE,
        // handled this case because of elation form different structure for narrative
        key: ehrConfig?.isElation ? NARRATIVE : 'textArea',
        data: copiedMessages,
      },
    ]
  }

  const {loading: phoneNoteLoading} = useQuery(GET_FORM_BY_CODE, {
    variables: {
      code: PHONE_NOTE_FORM_CODE,
    },
    fetchPolicy: 'no-cache',
    skip: ehrConfig?.isElation,
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
    onCompleted: (data) => {
      if (data?.form?.id) {
        setDefaultPhoneNote({
          formId: data.form.id,
          name: data.form.name,
        });
      }
    },
    onError: (error) => {

      notification.error({
        message: 'Failed to fetch form',
      });
    },
  });


  const onTemplateChangeCallback = (template: string) => {
    showToast(
      customToast,
      intl.formatMessage({id: 'clinicalNoteTemplateChanged'}),
      ToastType.success,
      1000,
      true
    );
  };

  const onAddNoteSuccessCallback = (data:any) => {
    const {id,formId} = data;
    const postData:any = {
      accountUuid: accountUUID,
      contactUuid: contactInfo.contactData.uuid,
      conversationUuid: conversationUUID,
      documentReferenceId: id,
      documentReferenceJson: {},
      messageUuids: messageIds,
    };
    // use formid only if it is not elation
    if(!ehrConfig?.isElation){
      postData.formId = formId
    }
    createClinicalMessageNote(postData)
      .then((res) => {
        onClose();
        showToast(
          customToast,
          intl.formatMessage({id: 'noteCreatedSuccessfully'}),
          ToastType.success,
          1000,
          true
        );
      })
      .catch((err) => {
        onClose();
        showToast(
          toast,
          intl.formatMessage({id: 'errorMsg'}),
          ToastType.error,
        );
      });
  };

  const componentLoading = accountUsersLoading || phoneNoteLoading;
  const accountUserList =
    accountUsersData?.users?.length > 0
      ? {
          users: filterUsersDataUsingLocationGroupId(
            accountUsersData?.users,
            locationGroupId
          ),
        }
      : {users: []};
  return (
    <Drawer
      className="create-note-from-message-drawer"
      open={isVisible}
      width={isSideCarContext ? "100%" : "40%"}
      onClose={onClose}
      destroyOnClose
      closable
    >
      {componentLoading ? (
        <TimeLineSkeletonLoader />
      ) : (
        <AddOrUpdatePatientNote
          isNewNote
          hideBottomOrderActionBar
          disableDefaultTemplateChange
          viewType={ViewType.DRAWER}
          ehrCapabilities={ehrCapabilities}
          elationFormData={elationFormData}
          personData={formmatedContactData}
          isLinkAppointment={false}
          ehrConfig={ehrConfig}
          unFormattedContactData={contactInfo.contactData}
          isPhysicianUser={isPhysicianUser}
          goBackHandler={onClose}
          accountUserList={accountUserList?.users || []}
          prefillData={getPrefillData()}
          defaultTemplate={defaultPhoneNote}
          noteData={getNoteConfigureForAddNewNote()}
          onTemplateChangeCallback={onTemplateChangeCallback}
          onAddNoteSuccessCallback={onAddNoteSuccessCallback}
          isClinicalNoteFromMessage
        />
      )}
    </Drawer>
  );
};

export default CreateNoteFromMessage;
