import React from "react";

const ViewEhrActionBtn = (props: {isActive?:boolean})=> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{width: 'inherit', height: 'inherit'}}
      fill="none"
      viewBox="0 0 21 20"
    >
      <path
        stroke={ !props.isActive ? "#D0D5DD"  : '#667085' }
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M1.333 10S4.667 3.333 10.5 3.333 19.667 10 19.667 10s-3.334 6.667-9.167 6.667S1.333 10 1.333 10z"
      ></path>
      <path
        stroke={ !props.isActive ? "#D0D5DD"  : '#667085' }
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M10.5 12.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"
      ></path>
    </svg>
  );
}

export default ViewEhrActionBtn;
