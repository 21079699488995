import {Text, View} from 'native-base';
import React from 'react';
import {Colors} from '../../../styles';
import {DisplayText} from '../DisplayText/DisplayText';
import {svgStyles} from './SvgStyles';

const NoMedicationsSvg = (props: {titleId?: string; fillColor?: string}) => {
  const fillColor = props.fillColor || Colors.primary['300'];

  return (
    <View style={svgStyles.container}>
      <svg
        width="76"
        height="74"
        viewBox="0 0 76 74"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.7842 67.2777H60.2148C61.436 67.2807 62.6132 66.8192 63.5104 65.9858C67.2532 62.4996 70.2476 58.2799 72.3092 53.5862C74.3708 48.8926 75.4561 43.8242 75.4984 38.6929C75.69 17.8283 58.831 0.660432 38.0905 0.611191C17.3382 0.561923 0.500025 17.4713 0.500025 38.3371C0.4941 43.5305 1.55704 48.6688 3.62192 53.4285C5.6868 58.1883 8.70889 62.4664 12.4979 65.9936C13.3932 66.8231 14.567 67.2818 15.7842 67.2777Z"
          fill="#FCFDFD"
        />
        <path
          d="M18.6335 18.9187H51.7072V65.4946C51.7072 65.9393 51.5306 66.3658 51.2161 66.6802C50.9017 66.9947 50.4752 67.1713 50.0305 67.1713H20.3102C19.8655 67.1713 19.4391 66.9947 19.1246 66.6802C18.8102 66.3658 18.6335 65.9393 18.6335 65.4946V18.9187Z"
          fill="#F2F4F7"
        />
        <path
          d="M52.1889 6.81665H18.1518C17.2258 6.81665 16.4751 7.56732 16.4751 8.49332V17.2419C16.4751 18.1679 17.2258 18.9186 18.1518 18.9186H52.1889C53.1149 18.9186 53.8656 18.1679 53.8656 17.2419V8.49332C53.8656 7.56732 53.1149 6.81665 52.1889 6.81665Z"
          fill="#F2F4F7"
        />
        <path
          d="M51.7072 53.8489H26.0371C25.8192 53.8489 25.6034 53.806 25.402 53.7226C25.2006 53.6392 25.0177 53.5169 24.8636 53.3628C24.7094 53.2087 24.5872 53.0257 24.5038 52.8244C24.4204 52.623 24.3774 52.4072 24.3774 52.1892V34.7527C24.3774 34.3125 24.5523 33.8904 24.8636 33.5791C25.1748 33.2679 25.597 33.093 26.0371 33.093H51.7072V53.8489Z"
          fill="#E4E7EC"
        />
        <path
          d="M19.879 8.28352H19.8789C19.3042 8.28352 18.8383 8.74945 18.8383 9.32421V16.2791C18.8383 16.8539 19.3042 17.3198 19.879 17.3198C20.4537 17.3198 20.9196 16.8539 20.9196 16.2791V9.32421C20.9196 8.74945 20.4537 8.28352 19.879 8.28352Z"
          fill="white"
        />
        <path
          d="M25.8041 8.28352H25.8041C25.2294 8.28352 24.7634 8.74945 24.7634 9.32421V16.2791C24.7634 16.8539 25.2294 17.3198 25.8041 17.3198C26.3789 17.3198 26.8448 16.8539 26.8448 16.2791V9.32421C26.8448 8.74945 26.3789 8.28352 25.8041 8.28352Z"
          fill="white"
        />
        <path
          d="M32.7701 9.32421C32.7701 8.74945 32.3042 8.28352 31.7294 8.28352C31.1547 8.28352 30.6887 8.74945 30.6887 9.32421V16.2791C30.6887 16.8539 31.1547 17.3198 31.7294 17.3198C32.3042 17.3198 32.7701 16.8539 32.7701 16.2791V9.32421Z"
          fill="white"
        />
        <path
          d="M38.6954 9.32421C38.6954 8.74945 38.2295 8.28352 37.6547 8.28352C37.0799 8.28352 36.614 8.74945 36.614 9.32421V16.2791C36.614 16.8539 37.0799 17.3198 37.6547 17.3198C38.2295 17.3198 38.6954 16.8539 38.6954 16.2791V9.32421Z"
          fill="white"
        />
        <path
          d="M44.6206 9.32421C44.6206 8.74945 44.1546 8.28352 43.5799 8.28352C43.0051 8.28352 42.5392 8.74945 42.5392 9.32421V16.2791C42.5392 16.8539 43.0051 17.3198 43.5799 17.3198C44.1546 17.3198 44.6206 16.8539 44.6206 16.2791V9.32421Z"
          fill="white"
        />
        <path
          d="M50.5459 9.32421C50.5459 8.74945 50.0799 8.28352 49.5052 8.28352C48.9304 8.28352 48.4645 8.74945 48.4645 9.32421V16.2791C48.4645 16.8539 48.9304 17.3198 49.5052 17.3198C50.0799 17.3198 50.5459 16.8539 50.5459 16.2791V9.32421Z"
          fill="white"
        />
        <path
          d="M59.5249 66.6303L59.5249 66.6303C59.5249 64.2352 57.5834 62.2937 55.1883 62.2937L46.4302 62.2937C44.0352 62.2937 42.0936 64.2352 42.0936 66.6303C42.0936 69.0253 44.0352 70.9668 46.4302 70.9668L55.1883 70.9668C57.5834 70.9668 59.5249 69.0253 59.5249 66.6303Z"
          fill="#E4E7EC"
        />
        <path
          d="M59.5249 66.6303C59.5249 66.0608 59.4128 65.4969 59.1948 64.9707C58.9769 64.4446 58.6575 63.9665 58.2548 63.5638C57.8521 63.1612 57.374 62.8417 56.8479 62.6238C56.3217 62.4059 55.7578 62.2937 55.1883 62.2937H50.8093V70.9668H55.1883C55.7578 70.9668 56.3217 70.8547 56.8479 70.6367C57.374 70.4188 57.8521 70.0994 58.2548 69.6967C58.6575 69.294 58.9769 68.8159 59.1948 68.2898C59.4128 67.7637 59.5249 67.1998 59.5249 66.6303Z"
          fill={fillColor}
        />
        <path
          d="M45.9771 57.6179L45.9771 57.6179C44.2835 55.9244 41.5378 55.9244 39.8442 57.6179L33.6513 63.8109C31.9577 65.5044 31.9578 68.2502 33.6513 69.9437C35.3448 71.6372 38.0906 71.6372 39.7841 69.9437L45.9771 63.7508C47.6706 62.0572 47.6706 59.3115 45.9771 57.6179Z"
          fill="#E4E7EC"
        />
        <path
          d="M45.9773 57.6181C45.5746 57.2154 45.0966 56.8959 44.5704 56.678C44.0443 56.4601 43.4804 56.3479 42.9109 56.3479C42.3414 56.3479 41.7775 56.4601 41.2514 56.678C40.7252 56.8959 40.2472 57.2154 39.8445 57.6181L36.748 60.7145L42.8809 66.8473L45.9773 63.7509C46.38 63.3482 46.6994 62.8702 46.9174 62.344C47.1353 61.8179 47.2475 61.254 47.2475 60.6845C47.2475 60.115 47.1353 59.5511 46.9174 59.025C46.6994 58.4988 46.38 58.0208 45.9773 57.6181Z"
          fill={fillColor}
        />
        <path
          d="M51.7073 23.0944L18.6335 27.556V18.9187H51.7073V23.0944Z"
          fill="#E4E7EC"
        />
      </svg>

      {props.titleId && (
        <Text style={svgStyles.svgText}>
          <DisplayText textLocalId={props.titleId} textType="Text" />
        </Text>
      )}
    </View>
  );
};

export default NoMedicationsSvg;
