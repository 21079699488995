import {useLazyQuery} from '@apollo/client';
import {Select, Spin} from 'antd';
import {debounce, isArray} from 'lodash';
import {useEffect, useState} from 'react';
import ContactsQueries from '../../../services/Contacts/ContactsQueries';
import {IContactRespData} from '../../../services/Contacts/interfaces';
import {getAccountId, isContactConsentRequired} from '../../../utils/commonUtils';

const AllPatientEmailList = (props: {
  defaultValue?: any;
  selectedAllContactData?: any;
  isGetCompleteObject?: boolean;
  onSelectValue: (actionData?: any, extraData?: any) => void;
  selectedValues?: any[];
}) => {
  const isConsentRequired = isContactConsentRequired();
  const [patientSearchState, setPatientSearchState] = useState<any[]>([]);
  const [selectedValue, setSelectedValue] = useState([] as any);
  const [isLoading, setLoading] = useState(false);
  const accountId = getAccountId();

  useEffect(() => {
    let defaultValue = props?.defaultValue;
    if (isArray(defaultValue)) {
      defaultValue = defaultValue.filter((email) => { return !!email; })
    }
    setSelectedValue(defaultValue);
    searchPatient('');
  }, [props]);

  const [getPatients] = useLazyQuery<IContactRespData>(
    ContactsQueries.GetContactDetailsContainsEmail,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (resp: any) => {
        const patientList = resp?.contacts || [];
        if (patientList) {
          setPatientSearchState(patientList);
        } else {
          setPatientSearchState([]);
        }
        setLoading(false);
      },
      onError: (error: any) => {
        setLoading(false);

      },
    }
  );

  const searchPatient = async (searchString: string) => {
    if (searchString.length > 0) {
      setLoading(true);
      getPatients({
        variables: {
          searchString: `%${searchString}%`,
          accountId: accountId,
        },
      });
    }
  };

  function handleGetCompleteObject(value: any[]) {
    const result: any[] = [];
    let searchArray = patientSearchState;
    if (props.selectedValues) {
      searchArray = [...props.selectedValues, ...patientSearchState]
    }
    value.forEach((element) => {
      const check = searchArray.find((item) => (item?.id == element?.key) && element?.key);
      if (check) {
        result.push(check);
      }
    });
    return result;
  }

  return (
    <Select
      size="large"
      mode="multiple"
      showSearch
      filterOption={false}
      value={selectedValue || undefined}
      onSearch={debounce(searchPatient, 500)}
      onChange={(value: any, options: any) => {
        const contactData = handleGetCompleteObject(options)
        if (props.isGetCompleteObject) {
          props.onSelectValue(contactData);
        } else {
          setSelectedValue(value);
          if (value && value.length > 0) {
            props.onSelectValue(value, contactData);
          } else {
            props.onSelectValue(undefined);
          }
        }
      }}
      placeholder="Search Patient"
      loading={isLoading}
      notFoundContent={isLoading && <Spin size="large" />}
      style={{width: '100%'}}
    >
      {patientSearchState?.map((item: any, index:number) => {
        return (
          <Select.Option
            key={item.id}
            value={item.email}
            disabled={
              !isConsentRequired ? false : !item?.contactConsents?.length
            }
          >
            {item.name + '(' + item.email + ')'}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default AllPatientEmailList;
