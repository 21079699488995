import {useLazyQuery} from '@apollo/client';
import {AutoComplete, Spin} from 'antd';
import {debounce} from 'lodash';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {IAutoComplete} from '../../../Interfaces';
import {ZipCodeQueries} from '../../../services';
import {ModalActionAutoComplete} from '../ModalActionCommonComponent/ModalActionAutoComplete';
import {ICity, ICityVar} from './interfaces';

const {Option} = AutoComplete;

const CityAutoComplete = (props: IAutoComplete) => {
  const [cityData, setCityData] = useState<ICity[]>([]);
  const [cityName, setCityName] = useState('');
  const [getCityList] = useLazyQuery<{cities: ICity[]}, ICityVar>(
    ZipCodeQueries.getCitiesBySearchString
  );
  const intl = useIntl();
  const [loadingCities, setLoadingCities] = useState(false);
  useEffect(() => {
    setCityName(props?.name || '');
  }, [props.name]);
  const searchCity = (searchString: string) => {
    if (searchString.length > 2) {
      setLoadingCities(true);
      setCityData([]);
      getCityList({
        variables: {
          searchString: searchString + '%',
          limit: 50,
        },
      })
        .then((cityRespData) => {
          setCityData(cityRespData?.data?.cities || []);
          setLoadingCities(false);
        })
        .catch((error) => {

          setCityData([]);
          setLoadingCities(false);
        });
    }
  };
  return (
    <>
      <ModalActionAutoComplete
        isInvalid={props.isInvalid}
        errors={props.errors}
        errorText={props.errorText}
        disabled={props?.isTabDisabled}
        value={cityName}
        label={'City'}
        defaultValue={props.name}
        isRequired={props?.isRequired}
        placeholder="Search City"
        allowClear={true}
        onClear={() => {
          props.onAutoCompleteChange(null);
        }}
        onSearch={debounce(searchCity, 500)}
        onChange={(value: any) => {
          if (!value) {
            props.onAutoCompleteChange(null);
          }
          setCityName(value || '');
          props?.onValueChange && props?.onValueChange(value)
        }}
        onSelect={(value: any, options: any) => {
          const selectedCity = cityData.find(
            (item) => item.id == options?.key || ''
          );

          props.onAutoCompleteChange(
            options?.key || '',
            selectedCity?.name || '',
            selectedCity
          );
        }}
        loading={loadingCities}
        notFoundContent={intl.formatMessage({
          id: props?.noDataLabel ? props?.noDataLabel :'NoData',
        })}
        customStyle={{flex: props.flex ? props.flex : 0.48}}
        data={cityData}
        optionProps={{id: 'id', code: 'name', value: 'name'}}
      />
    </>
  );
};

export default CityAutoComplete;
