import { View, Text } from 'react-native'
import React, { useContext, useEffect, useState } from 'react'
import { Colors } from '../../../styles';
import Stack from '../LayoutComponents/Stack';
import ContactCareProgram, { GET_CONTACT_CARE_PROGRAM_WITH_STEPS_LOG } from '../../../services/ContactCareProgram/ContactCareProgram';
import { useLazyQuery } from '@apollo/client';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../constants/Configs';
import { IContactCareProgram } from './interface';
import { Divider, Skeleton } from 'antd';
import CareProgramCard from './CareProgramCard';
import CareProgramConsent from '../CareProgramConsent/CareProgramConsent';
import { MLOV_CATEGORY, RIGHT_SIDE_CONTAINER_CODE } from '../../../constants';
import { IContactCareProgramResponse } from '../../../services/ContactCareProgram/interface';
import { COMMON_ACTION_CODES } from '../../../constants/ActionConst';
import ContactSendForm from '../ContactSendForm/ContactSendForm';
import { IContact, IContactType, IContactTypeRes } from '../../RightSideContainer/TeamInbox/Conversations/interfaces';
import { getUserName, getUserUUID, isEnableCareProgram } from '../../../utils/commonUtils';
import { EcmOutreach } from '../EcmOutreach/EcmOutreach';
import { ParticipantType } from '../CalendarWidget/ParticipantAutoComplete/ParticipantEnum';
import { AppointmentType } from '../CalendarWidget/BookingWorkflows/AppointmentTypeSelection/AppointmentTypeEnum';
import BookingWorkflow from '../CalendarWidget/BookingWorkflows/BookingWorkflow';
import TimeLoggingComponent from '../../RightSideContainer/TeamInbox/Conversations/MessagingContactDetails/TimeLoggingComponent';
import {CareProgramStepOutreach} from '../CareProgramStepOutreach/CareProgramStepOutreach';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { getMlovListFromCategory, getMlovValueFromId } from '../../../utils/mlovUtils';
import { CareProgramSubmitConsentView } from '../CareProgramConsent/CareProgramSubmitConsentView';
import { CareProgramAssessmentResponseView } from '../CareProgramAssessment/CareProgramAssessmentResponseView';
import { getPatientChronicConditions } from '../../../services/CommonService/AidBoxService';
import { getRecordListFormatted } from '../../RightSideContainer/Forms/FHFormio/CustomComponents/Conditions/AddOrUpdateConditions/AddOrUpdateConditionsHelper';
import { ICondition } from '../../RightSideContainer/Forms/FHFormio/CustomComponents/Conditions/interfaces';
import { CARE_PROGRAM_STEP_TYPE } from '../MemebersView/constant';
import {IFormCommonData} from '../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomWrapper';

interface ICareProgramPanelProps {
  contactId: string;
  contact: IContact;
  contactType?: IContactType | undefined;
  locationId: string | undefined
}

const CareProgramPanel = (props: ICareProgramPanelProps) => {
  // Constants
  const { contactId, contact,  contactType, locationId } = props;
  const userId = getUserUUID();
  const userName = getUserName();
  const commonContextData = useContext(CommonDataContext);
  const careProgramTypeList = getMlovListFromCategory(
    commonContextData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.CARE_PROGRAM_TYPES
  ) || [];

  // States
  const [componentState, setComponentState] = useState<{
    loading: boolean;
    careProgramList: IContactCareProgram[],
    careProgramData: IContactCareProgramResponse[],
    chronicConditions: ICondition[],
    selectedAction?: {
      actionCode: string;
      careProgram: IContactCareProgram;
      stepId?: string;
    },
  }>({
    loading: false,
    careProgramList: [],
    selectedAction: undefined,
    careProgramData: [],
    chronicConditions: [],
  });

  // APIs
  const [getContactCarePrograms] = useLazyQuery(ContactCareProgram.GET_CONTACT_CARE_PROGRAM_LIST, {
    fetchPolicy: 'no-cache',
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
  });

  const [getContactWiseCareProgram] = useLazyQuery(
    GET_CONTACT_CARE_PROGRAM_WITH_STEPS_LOG,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
    },
  );

  // Life cycle methods
  useEffect(() => {
    fetchCareProgramList();
  }, []);

  // Other methods
  const fetchCareProgramList = async (skipLoader?: boolean) => {
    try {
      const isChronic = true;
      const promiseList: any[] = [];
      const accountLocationUuid = contact?.contactPracticeLocations?.[0]?.accountLocation?.uuid
      if (!skipLoader) {
        setComponentState((prev) => ({...prev, loading: true}));
      }
      promiseList.push(
        getContactCarePrograms({
          variables: {
            contactId: contactId,
          },
        }),
        getContactWiseCareProgram({
          variables: {
            contactIds: [contactId],
          },
        }),
        getPatientChronicConditions(
          [
            {
              location: contact?.contactPracticeLocations?.[0]?.accountLocation?.uuid?.toString(),
              patientId: contact?.patient?.patientId,
            },
          ],
          isChronic,
        )
      );
      const responses = await Promise.all(promiseList);
      let careProgramList: IContactCareProgram[] = responses?.[0]?.data?.contactCarePrograms;
      let careProgramData: IContactCareProgramResponse[] = responses?.[1]?.data?.contactCarePrograms || [];

      careProgramData = careProgramData?.filter((data) => {
        for (const stepLog of data?.stepsLog || []) {
          if(stepLog?.careProgramStep?.careProgramStepType?.code === CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_2DAY && !stepLog?.expiresOn) {
            careProgramList = careProgramList.filter((careProgram: IContactCareProgram) => {
              return careProgram?.id !== data?.id;
            });
            return false;
          }
        }
        return true;
      })

      let chronicConditions: ICondition[] = []
      const chronicConditionResources = responses?.[2]?.data?.resources;
      if (chronicConditionResources) {
        chronicConditionResources?.forEach((resource: any) => {
          chronicConditions = getRecordListFormatted(
            resource.conditions?.entry || []
          );
        });
      }
      setComponentState((prev) => ({
        ...prev,
        loading: false,
        careProgramList: careProgramList,
        careProgramData: careProgramData,
        chronicConditions: chronicConditions,
      }));
    } catch (error) {

      setComponentState((prev) => ({...prev, loading: false}));
    }
  }

  return (
    <Stack direction='column' style={{ padding: 8 }}>
      {componentState.loading && (<Skeleton active />)}
      {!componentState.loading && !componentState?.careProgramList?.length && (
        <View style={{ borderRadius: 8, padding: 16, backgroundColor: Colors.Custom.ContainerBGColor }}>
          <Text style={{ textAlign: 'center', color: Colors.Custom.PlaceholderColor }}>
            No Care Programs
          </Text>
        </View>
      )}
      {!componentState.loading && componentState?.careProgramList?.length > 0 && (
        componentState.careProgramList.map((careProgram, index) => {
          const findCareProgramObject = (componentState.careProgramData || []).find(careProgramObject => {
            return careProgramObject?.id === careProgram?.id;
          }) || {} as IContactCareProgramResponse;
          return (
            <CareProgramCard
              key={`CareProgramCard_${careProgram.id}_${index}`}
              careProgram={careProgram}
              careProgramData={findCareProgramObject}
              onActionPerform={(actionCode: string, careProgram: IContactCareProgram, stepId?: string) => {
                setComponentState((prev) => ({...prev, selectedAction: { actionCode, careProgram ,stepId}}));
              }}
              chronicConditions={componentState.chronicConditions}
              contact={contact}
            />
          );
        }))}
      <TimeLoggingComponent contactUuid={contactId} isCcmCareManagement={false}/>
      {componentState.selectedAction?.actionCode === COMMON_ACTION_CODES.CARE_PROGRAM_CONSENT_AND_ENROLL &&
        !!componentState.selectedAction?.careProgram?.id && (
        <CareProgramConsent
          isVisible={
            componentState.selectedAction?.actionCode === COMMON_ACTION_CODES.CARE_PROGRAM_CONSENT_AND_ENROLL
          }
          locationId={locationId}
          contactId={contactId}
          contactCareProgramId={componentState.selectedAction?.careProgram?.id}
          programName={
            getMlovValueFromId(
              careProgramTypeList,
              componentState.selectedAction?.careProgram?.payerCareProgram?.careProgramTypeId
            )}
          onClose={(refetch: boolean) => {
            if (refetch) {
              fetchCareProgramList(refetch);
            }
            setComponentState((prev) => ({...prev, selectedAction: undefined }));
          }}
        />
      )}
      {componentState.selectedAction?.actionCode === COMMON_ACTION_CODES.ECM_OUTREACH_VIEW &&
        !!componentState.selectedAction?.careProgram?.id && (
        <CareProgramStepOutreach
          isVisible={
            componentState.selectedAction?.actionCode === COMMON_ACTION_CODES.ECM_OUTREACH_VIEW
          }
          contactId={contactId}
          contactCareProgramId={componentState.selectedAction?.careProgram?.id}
          outreachStepId={componentState.selectedAction?.stepId}
          onClose={(refetch) => {
            if (refetch) {
              fetchCareProgramList(refetch);
            }
            setComponentState((prev) => ({...prev, selectedAction: undefined }));
          }}
        />
      )}

      {componentState.selectedAction?.actionCode === COMMON_ACTION_CODES.SEND_FORMS &&
        !!componentState.selectedAction?.careProgram?.id && (
          <ContactSendForm
              isVisible={componentState.selectedAction?.actionCode === COMMON_ACTION_CODES.SEND_FORMS}
              assignmentData={{
                patientId: contact?.patient?.patientId,
                contactId: contact?.uuid,
                assignedById: userId,
                patientEmail: contact?.email,
                patientFirstName: contact?.name,
                patientContactUUID: contact?.uuid,
                contactTypeCode: contactType?.code || '',
              }}
              onActionComplete={() => {
                setComponentState((prev) => ({...prev, selectedAction: undefined }));
              }}
              onClose={() => {
                setComponentState((prev) => ({...prev, selectedAction: undefined }));
              }}
            />
      )}
      {componentState.selectedAction?.actionCode === COMMON_ACTION_CODES.SCHEDULE_APPOINTMENT &&
        !!componentState.selectedAction?.careProgram?.id && (
          <BookingWorkflow
            user={{
              uuid: userId,
              name: userName,
            }}
            isOpen={true}
            appointmentType={AppointmentType.bookAppointment}
            defaultParticipants={[
              {
                label: contact?.name,
                key: contact?.uuid,
                value: contact?.uuid,
                type: ParticipantType.patient,
              },
            ]}
            onClose={() => {
              setComponentState((prev) => ({...prev, selectedAction: undefined }));
            }}
            onCancel={() => {
              setComponentState((prev) => ({...prev, selectedAction: undefined }));
            }}
          />
      )}

      {componentState.selectedAction?.actionCode === COMMON_ACTION_CODES.SHOW_CARE_PROGRAM_CONSENT &&
        !!componentState.selectedAction?.careProgram?.id && (
        <CareProgramSubmitConsentView
          contactId={contactId}
          contactCareProgramId={componentState.selectedAction?.careProgram?.id}
          stepId={componentState.selectedAction?.stepId || ''}
          onClose={() => {
            setComponentState((prev) => ({...prev, selectedAction: undefined }));
          }}
          locationId={locationId}
        />
      )}

    {componentState.selectedAction?.actionCode === COMMON_ACTION_CODES.SHOW_CARE_PROGRAM_ASSESSMENT &&
        !!componentState.selectedAction?.careProgram?.id && (
        <CareProgramAssessmentResponseView
          contactCareProgramId={componentState.selectedAction?.careProgram?.id}
          stepId={componentState.selectedAction?.stepId || ''}
          onClose={() => {
            setComponentState((prev) => ({...prev, selectedAction: undefined }));
          }}
        />
      )}
    </Stack>
  )
}

export default CareProgramPanel
