import {Image, Text, View} from 'native-base';
import React from 'react';
import {Platform} from 'react-native';
import {Colors} from '../../../styles';
import {DisplayText} from '../DisplayText/DisplayText';
import {svgStyles} from './SvgStyles';

const NoTagsSvg = (props: {titleId?: string; fillColor?: string}) => {
  const fillColor = props.fillColor || Colors.primary['300'];

  return (
    <View style={svgStyles.container}>
      {Platform.OS === 'web' ? (
        <svg
          width="76"
          height="74"
          viewBox="0 0 76 74"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.7842 70.629H60.2148C61.436 70.632 62.6132 70.1706 63.5104 69.3372C67.2532 65.8509 70.2476 61.6312 72.3092 56.9375C74.3708 52.2439 75.4561 47.1755 75.4984 42.0442C75.69 21.1796 58.831 4.01175 38.0905 3.96251C17.3382 3.91324 0.500025 20.8226 0.500025 41.6884C0.494099 46.8818 1.55704 52.0201 3.62192 56.7799C5.6868 61.5396 8.70889 65.8178 12.4979 69.3449C13.3932 70.1744 14.567 70.6331 15.7842 70.629Z"
            fill="#FCFDFD"
          />
          <path
            d="M43.1795 24.7388C43.0841 24.7142 42.9944 24.671 42.9156 24.6117C42.8368 24.5524 42.7705 24.4782 42.7204 24.3933C42.6703 24.3084 42.6374 24.2144 42.6237 24.1168C42.6099 24.0192 42.6155 23.9198 42.6401 23.8243C43.0584 22.2031 44.022 20.6451 44.9538 19.1385C46.1056 17.2766 47.1933 15.5179 46.7278 14.3597C46.4105 13.5702 45.3131 12.9284 43.4661 12.4519C41.6192 11.9754 40.3482 12.0063 39.6885 12.5437C38.7208 13.3321 38.8221 15.3976 38.9293 17.5843C39.016 19.3538 39.1057 21.1834 38.6874 22.8046C38.6629 22.9002 38.6198 22.99 38.5606 23.069C38.5013 23.1479 38.4271 23.2143 38.3421 23.2645C38.2572 23.3147 38.1631 23.3477 38.0654 23.3615C37.9677 23.3753 37.8682 23.3698 37.7727 23.3451C37.6771 23.3205 37.5874 23.2772 37.5085 23.2178C37.4297 23.1585 37.3634 23.0841 37.3133 22.9991C37.2632 22.9141 37.2304 22.82 37.2167 22.7223C37.203 22.6245 37.2088 22.5251 37.2336 22.4295C37.5948 21.0291 37.5108 19.3153 37.4296 17.6579C37.3033 15.0813 37.184 12.6475 38.7401 11.3797C39.805 10.5121 41.4737 10.3872 43.8412 10.998C46.2088 11.6088 47.6087 12.5252 48.121 13.7997C48.8697 15.6622 47.5878 17.7345 46.2308 19.9284C45.3579 21.3397 44.4553 22.799 44.094 24.1994C44.0694 24.2949 44.0262 24.3845 43.9669 24.4633C43.9076 24.5421 43.8334 24.6084 43.7485 24.6585C43.6636 24.7086 43.5696 24.7415 43.472 24.7552C43.3744 24.769 43.275 24.7634 43.1795 24.7388Z"
            fill={fillColor}
          />
          <path
            opacity="0.3"
            d="M43.1795 24.7388C43.0841 24.7142 42.9944 24.671 42.9156 24.6117C42.8368 24.5524 42.7705 24.4782 42.7204 24.3933C42.6703 24.3084 42.6374 24.2144 42.6237 24.1168C42.6099 24.0192 42.6155 23.9198 42.6401 23.8243C43.0584 22.2031 44.022 20.6451 44.9538 19.1385C46.1056 17.2766 47.1933 15.5179 46.7278 14.3597C46.4105 13.5702 45.3131 12.9284 43.4661 12.4519C41.6192 11.9754 40.3482 12.0063 39.6885 12.5437C38.7208 13.3321 38.8221 15.3976 38.9293 17.5843C39.016 19.3538 39.1057 21.1834 38.6874 22.8046C38.6629 22.9002 38.6198 22.99 38.5606 23.069C38.5013 23.1479 38.4271 23.2143 38.3421 23.2645C38.2572 23.3147 38.1631 23.3477 38.0654 23.3615C37.9677 23.3753 37.8682 23.3698 37.7727 23.3451C37.6771 23.3205 37.5874 23.2772 37.5085 23.2178C37.4297 23.1585 37.3634 23.0841 37.3133 22.9991C37.2632 22.9141 37.2304 22.82 37.2167 22.7223C37.203 22.6245 37.2088 22.5251 37.2336 22.4295C37.5948 21.0291 37.5108 19.3153 37.4296 17.6579C37.3033 15.0813 37.184 12.6475 38.7401 11.3797C39.805 10.5121 41.4737 10.3872 43.8412 10.998C46.2088 11.6088 47.6087 12.5252 48.121 13.7997C48.8697 15.6622 47.5878 17.7345 46.2308 19.9284C45.3579 21.3397 44.4553 22.799 44.094 24.1994C44.0694 24.2949 44.0262 24.3845 43.9669 24.4633C43.9076 24.5421 43.8334 24.6084 43.7485 24.6585C43.6636 24.7086 43.5696 24.7415 43.472 24.7552C43.3744 24.769 43.275 24.7634 43.1795 24.7388Z"
            fill="white"
          />
          <path
            d="M58.1763 23.2307L44.3466 16.4969C43.7505 16.2072 43.0932 16.066 42.4307 16.0856L42.4181 16.0806L41.043 16.1811L41.1385 16.3326C40.8173 16.4493 40.5119 16.6055 40.2294 16.7978L27.5258 25.4709C26.9325 25.8759 26.456 26.4296 26.1439 27.0767C25.8318 27.7237 25.6951 28.4413 25.7475 29.1578L28.6771 69.2417C28.711 69.705 28.8357 70.157 29.0443 70.572C29.2528 70.987 29.5411 71.3569 29.8926 71.6605C30.244 71.9641 30.6519 72.1955 31.0928 72.3415C31.5337 72.4875 31.9991 72.5452 32.4623 72.5113L60.1317 70.489C60.595 70.4552 61.047 70.3304 61.462 70.1219C61.877 69.9133 62.2469 69.6251 62.5505 69.2736C62.8541 68.9221 63.0855 68.5143 63.2315 68.0733C63.3775 67.6324 63.4352 67.1671 63.4013 66.7038L60.4717 26.6199C60.4193 25.9035 60.1798 25.2133 59.7769 24.6185C59.3741 24.0237 58.8221 23.5452 58.1763 23.2307Z"
            fill="#E4E7EC"
          />
          <path
            opacity="0.05"
            d="M58.1763 23.2307L44.3466 16.4969C43.7505 16.2072 43.0932 16.066 42.4307 16.0856L42.4181 16.0806L41.043 16.1811L41.1385 16.3326C40.8173 16.4493 40.5119 16.6055 40.2294 16.7978L27.5258 25.4709C26.9325 25.8759 26.456 26.4296 26.1439 27.0767C25.8318 27.7237 25.6951 28.4413 25.7475 29.1578L28.6771 69.2417C28.711 69.705 28.8357 70.157 29.0443 70.572C29.2528 70.987 29.5411 71.3569 29.8926 71.6605C30.244 71.9641 30.6519 72.1955 31.0928 72.3415C31.5337 72.4875 31.9991 72.5452 32.4623 72.5113L60.1317 70.489C60.595 70.4552 61.047 70.3304 61.462 70.1219C61.877 69.9133 62.2469 69.6251 62.5505 69.2736C62.8541 68.9221 63.0855 68.5143 63.2315 68.0733C63.3775 67.6324 63.4352 67.1671 63.4013 66.7038L60.4717 26.6199C60.4193 25.9035 60.1798 25.2133 59.7769 24.6185C59.3741 24.0237 58.8221 23.5452 58.1763 23.2307Z"
            fill="black"
          />
          <path
            d="M59.0665 70.5667L31.3971 72.5889C30.9339 72.6228 30.4685 72.5651 30.0276 72.4191C29.5867 72.2731 29.1788 72.0417 28.8274 71.7381C28.4759 71.4345 28.1876 71.0646 27.9791 70.6496C27.7705 70.2346 27.6458 69.7826 27.6119 69.3193L24.6823 29.2354C24.6299 28.519 24.7666 27.8013 25.0787 27.1543C25.3908 26.5072 25.8673 25.9535 26.4606 25.5485L39.1642 16.8754C39.763 16.4666 40.4592 16.2235 41.1823 16.1706C41.9054 16.1178 42.6295 16.2571 43.2814 16.5745L57.1111 23.3083C57.7569 23.6228 58.3089 24.1014 58.7117 24.6961C59.1146 25.2909 59.3541 25.9811 59.4065 26.6975L62.3361 66.7815C62.37 67.2447 62.3123 67.71 62.1663 68.151C62.0203 68.5919 61.7889 68.9997 61.4853 69.3512C61.1817 69.7027 60.8118 69.9909 60.3968 70.1995C59.9818 70.408 59.5298 70.5328 59.0665 70.5667Z"
            fill="#E4E7EC"
          />
          <path
            d="M57.619 30.6147L26.5383 54.63L27.612 69.3193C27.6803 70.2548 28.1175 71.1249 28.8274 71.738C29.5373 72.3512 30.4616 72.6573 31.3971 72.5889L52.5064 70.9479L57.619 30.6147Z"
            fill="black"
            fill-opacity="0.05"
          />
          <path
            d="M56.3977 28.1155L45.4468 17.3136C44.9747 16.8485 44.397 16.5046 43.763 16.3113L43.7527 16.3025L42.4177 15.9581L42.4596 16.1322C42.118 16.14 41.7787 16.1905 41.4495 16.2823L26.6398 20.4386C25.9481 20.6327 25.3196 21.005 24.817 21.5183C24.3145 22.0316 23.9555 22.6679 23.776 23.3635L13.7364 62.2802C13.6204 62.7299 13.5941 63.1981 13.659 63.658C13.7239 64.1179 13.8788 64.5605 14.1147 64.9606C14.3507 65.3606 14.6632 65.7103 15.0343 65.9896C15.4054 66.2689 15.8279 66.4723 16.2776 66.5883L43.1413 73.5186C43.591 73.6346 44.0592 73.6609 44.5191 73.596C44.979 73.5311 45.4216 73.3762 45.8217 73.1402C46.2217 72.9043 46.5714 72.5918 46.8507 72.2207C47.13 71.8496 47.3334 71.4271 47.4494 70.9774L57.4891 32.0607C57.6686 31.3651 57.6622 30.6346 57.4707 29.9422C57.2791 29.2499 56.9092 28.62 56.3977 28.1155Z"
            fill="#F2F4F7"
          />
          <path
            opacity="0.05"
            d="M56.3977 28.1155L45.4468 17.3136C44.9747 16.8485 44.397 16.5046 43.763 16.3113L43.7527 16.3025L42.4177 15.9581L42.4596 16.1322C42.118 16.14 41.7787 16.1905 41.4495 16.2823L26.6398 20.4386C25.9481 20.6327 25.3196 21.005 24.817 21.5183C24.3145 22.0316 23.9555 22.6679 23.776 23.3635L13.7364 62.2802C13.6204 62.7299 13.5941 63.1981 13.659 63.658C13.7239 64.1179 13.8788 64.5605 14.1147 64.9606C14.3507 65.3606 14.6632 65.7103 15.0343 65.9896C15.4054 66.2689 15.8279 66.4723 16.2776 66.5883L43.1413 73.5186C43.591 73.6346 44.0592 73.6609 44.5191 73.596C44.979 73.5311 45.4216 73.3762 45.8217 73.1402C46.2217 72.9043 46.5714 72.5918 46.8507 72.2207C47.13 71.8496 47.3334 71.4271 47.4494 70.9774L57.4891 32.0607C57.6686 31.3651 57.6622 30.6346 57.4707 29.9422C57.2791 29.2499 56.9092 28.62 56.3977 28.1155Z"
            fill="black"
          />
          <path
            d="M42.1069 73.2519L15.2432 66.3217C14.3349 66.0874 13.5569 65.5018 13.0803 64.6939C12.6038 63.886 12.4677 62.9218 12.702 62.0135L22.7416 23.0968C22.9211 22.4012 23.28 21.765 23.7826 21.2517C24.2852 20.7384 24.9137 20.366 25.6054 20.1719L40.4151 16.0157C41.1132 15.8198 41.8506 15.812 42.5526 15.9931C43.2546 16.1742 43.8963 16.5378 44.4124 17.0469L55.3633 27.8488C55.8747 28.3533 56.2447 28.9832 56.4362 29.6756C56.6278 30.3679 56.6341 31.0985 56.4547 31.794L46.415 70.7107C46.299 71.1605 46.0956 71.583 45.8163 71.9541C45.537 72.3252 45.1873 72.6376 44.7873 72.8736C44.3872 73.1096 43.9446 73.2644 43.4847 73.3293C43.0248 73.3942 42.5566 73.3679 42.1069 73.2519Z"
            fill="#F2F4F7"
          />
          <path
            d="M41.0349 24.4317C42.4464 24.4317 43.5906 23.2875 43.5906 21.876C43.5906 20.4645 42.4464 19.3203 41.0349 19.3203C39.6235 19.3203 38.4792 20.4645 38.4792 21.876C38.4792 23.2875 39.6235 24.4317 41.0349 24.4317Z"
            fill="#E4E7EC"
          />
          <path
            d="M41.035 23.693C42.0386 23.693 42.8522 22.8794 42.8522 21.8758C42.8522 20.8722 42.0386 20.0586 41.035 20.0586C40.0314 20.0586 39.2178 20.8722 39.2178 21.8758C39.2178 22.8794 40.0314 23.693 41.035 23.693Z"
            fill="white"
          />
          <path
            d="M41.035 23.693C42.0386 23.693 42.8522 22.8794 42.8522 21.8758C42.8522 20.8722 42.0386 20.0586 41.035 20.0586C40.0314 20.0586 39.2178 20.8722 39.2178 21.8758C39.2178 22.8794 40.0314 23.693 41.035 23.693Z"
            fill="#F2F4F7"
          />
          <path
            d="M42.7947 22.3299C43.043 21.367 42.558 20.4094 41.7113 20.1909C40.8645 19.9725 39.9767 20.5759 39.7283 21.5388C39.4799 22.5017 39.965 23.4593 40.8117 23.6777C41.6585 23.8962 42.5463 23.2927 42.7947 22.3299Z"
            fill="white"
          />
          <path
            d="M46.4566 0.860039C45.156 0.526052 43.7761 0.721618 42.6196 1.40383C41.463 2.08604 40.6243 3.19917 40.2875 4.49901L39.4143 7.88366C38.8622 10.0328 38.837 12.2834 39.3408 14.4444C39.7809 16.3321 39.759 18.2982 39.2767 20.1756L39.1444 20.6883C39.1159 20.7987 39.1094 20.9136 39.1253 21.0265C39.1412 21.1394 39.1792 21.2481 39.2371 21.3463C39.295 21.4445 39.3717 21.5304 39.4628 21.5989C39.5539 21.6675 39.6576 21.7174 39.768 21.7459C39.8784 21.7744 39.9933 21.7809 40.1062 21.7649C40.2191 21.749 40.3278 21.711 40.426 21.653C40.5242 21.5951 40.61 21.5184 40.6785 21.4272C40.7471 21.3361 40.797 21.2324 40.8254 21.122L40.9577 20.6092C41.5099 18.4602 41.5351 16.2095 41.0313 14.0486C40.5911 12.1609 40.6131 10.1947 41.0953 8.31726L41.9685 4.9326C42.1891 4.07797 42.7402 3.34598 43.5005 2.89762C44.2608 2.44926 45.1681 2.32125 46.0227 2.54175C46.8774 2.76224 47.6095 3.31319 48.058 4.0734C48.5065 4.83362 48.6347 5.74085 48.4143 6.59556L47.5412 9.98021C47.0552 11.8567 46.1234 13.588 44.8249 15.0272C43.3385 16.6747 42.2717 18.6567 41.7154 20.8048L41.5831 21.3175C41.5545 21.4279 41.548 21.5429 41.5639 21.6558C41.5797 21.7687 41.6177 21.8774 41.6756 21.9757C41.7335 22.074 41.8102 22.1598 41.9013 22.2285C41.9924 22.2971 42.0962 22.347 42.2066 22.3755C42.317 22.404 42.432 22.4105 42.5449 22.3945C42.6579 22.3785 42.7665 22.3405 42.8648 22.2825C42.963 22.2245 43.0488 22.1477 43.1173 22.0566C43.1858 21.9654 43.2357 21.8616 43.2641 21.7512L43.3964 21.2384C43.8823 19.362 44.8141 17.6306 46.1126 16.1915C47.5991 14.544 48.6658 12.5619 49.2222 10.4138L50.0953 7.02916C50.4293 5.72864 50.2337 4.34874 49.5516 3.1922C48.8694 2.03567 47.7564 1.19694 46.4566 0.860039Z"
            fill={fillColor}
          />
          <path
            d="M17.8429 57.5061C22.637 57.5061 26.5235 53.6197 26.5235 48.8256C26.5235 44.0314 22.637 40.145 17.8429 40.145C13.0488 40.145 9.16235 44.0314 9.16235 48.8256C9.16235 53.6197 13.0488 57.5061 17.8429 57.5061Z"
            fill={fillColor}
          />
        </svg>
      ) : (
        <Image
          w="100%"
          resizeMode="contain"
          source={require('../../../assets/images/EmptyStatePng/noTags.png')}
          alt="image"
        ></Image>
      )}

      {props.titleId && (
        <Text style={svgStyles.svgText}>
          <DisplayText textLocalId={props.titleId} textType="Text" />
        </Text>
      )}
    </View>
  );
};

export default NoTagsSvg;
