import { Select, Spin } from 'antd';
import { HStack, Text, View } from 'native-base';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ISearchFieldProps } from '../../../Interfaces';
import { ContentTypes } from '../../RightSideContainer/ContentManagement/ContentManagementConsts';
import {
  getTemplateCategories,
  getTemplateCategoryList,
  getTemplates
} from '../../RightSideContainer/ContentManagement/ContentManagementUtils';
import AddOrUpdatePushNotificationBody from '../../RightSideContainer/ContentManagement/PushNotifications/AddOrUpdatePushNotification/AddOrUpdatePushNotificationBody';
import { CaretDownOutlined, DownOutlined } from '@ant-design/icons';
import { Colors } from '../../../styles';
import { filteredFormsCategoryList } from '../../../utils/commonUtils';

const NotificationTemplateSearch = (props: ISearchFieldProps) => {
  const {isShowError, onChange} = props;
  const [value, setValue] = useState<any>(props.value);
  const [loading, setLoading] = useState(false);
  const [templateDataList, setTemplateDataList] = useState<any[]>([]);
  const [categoryList, setCategoryList] = useState<any[]>([]);
  const [searchParams] = useSearchParams();

  const setTemplateDefaultData = () => {
    if (value && value?.template?.id) {
      if (templateDataList.length === 0) {
        setTemplateDataList([
          {
            id: value?.template?.id,
            attributes: value?.template?.attributes,
          },
        ]);
      } else {
        const isPresent = templateDataList.some((templateData) => {
          return templateData.id === value?.template?.id;
        });
        if (!isPresent) {
          setTemplateDataList([
            {
              id: value?.template?.id,
              attributes: value?.template?.attributes,
            },
          ]);
        }
      }
    }
  };
  const setCategoryDefaultData = () => {
    if (value && value?.category?.id) {
      if (categoryList.length === 0) {
        setCategoryList([
          {
            id: value?.category?.id,
            name: value?.category?.name,
            code: value?.category?.code,
          },
        ]);
      } else {
        const isPresent = categoryList.some((category) => {
          return category.id === value?.category?.id;
        });
        if (!isPresent) {
          setCategoryList([
            {
              id: value?.category?.id,
              name: value?.category?.name,
              code: value?.category?.code,
            },
          ]);
        }
      }
    }
  };

  const DEFAULT_CATEGORY_CODE =
    searchParams.get('DEFAULT_CATEGORY_CODE') || 'GENERAL';
  const DEFAULT_TEMPLATE_ID = searchParams.get('DEFAULT_TEMPLATE_ID');

  useEffect(() => {
    setTemplateDefaultData();
    setCategoryDefaultData();
    getTemplateCategories()
      .then((data) => {
        return getTemplateCategoryList(data);
      })
      .then((list) => {

        const filteredCategories = filteredFormsCategoryList(list)
        if (!value) {
          filteredCategories.some((category) => {
            if (category.code === DEFAULT_CATEGORY_CODE) {
              searchTemplateList(category.code, (templateList: any) => {
                templateList.some((template: any) => {
                  if (
                    (template?.attributes?.isDefault && !DEFAULT_TEMPLATE_ID) ||
                    (DEFAULT_TEMPLATE_ID && DEFAULT_TEMPLATE_ID == template?.id)
                  ) {
                    const attributes: any = {};

                    if (template.attributes) {
                      attributes.name = template.attributes.name;
                      attributes.isDefault = template.attributes.isDefault;
                      attributes.subject = template.attributes.subject || '';
                      attributes.body = template.attributes.body || '';
                    }

                    setValue((value: any) => {
                      const newValue = {
                        category,
                        template: {
                          id: template.id,
                          attributes: attributes,
                        },
                        body: attributes.body || '',
                        subject: attributes.subject || '',
                        pushNotificationUpdatedAt: new Date()
                      };
                      onChange(newValue);
                      return newValue;
                    });
                    return true;
                  }
                });
              });
            }
          });
        }
        setCategoryList(filteredCategories);
      })
      .catch((error) => {});
  }, []);

  const searchTemplateList = (categoryCode?: string, callback?: any) => {
    setTemplateDataList([]);
    setLoading(true);
    const templateCategoryCode = value?.category?.code;
    const path = `${ContentTypes.pushNotifications.path}?category=${
      categoryCode || templateCategoryCode
    }`;
    getTemplates(path).then((response) => {
      setLoading(false);
      setTemplateDataList(response.data || []);
      callback ? callback(response.data) : [];
    });
  };

  return (
    <>
      <HStack>
        <HStack flex={2.4}>
          <Text fontWeight={300} fontSize={16} flex={2} marginTop={1}>
            {'Category'}
          </Text>
        </HStack>
        <HStack flex={7}>
          <View marginY={1} flex={1}>
            <Select
              suffixIcon={ props.isAutomationView?
                <CaretDownOutlined
                  style={{
                    color: Colors.FoldPixel.GRAY300
                  }}
                /> :
                <DownOutlined/>
              }
              size="large"
              showSearch
              //allowClear
              value={value?.category ? value?.category?.name : undefined}
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={(value: any[], data: any) => {
                if (data && data.value) {
                  setValue((value: any) => {
                    const selectedCategory = categoryList.find(
                      (item) => item.code === data.code
                    );
                    const mergeTags = selectedCategory?.mergeTags || {};
                    const newValue = {category: {...data, mergeTags}};
                    onChange(newValue);
                    setTemplateDataList([]);

                    searchTemplateList(data.code, (templateList: any) => {
                      templateList.some((template: any) => {
                        if (template?.attributes?.isDefault) {
                          setValue((value: any) => {
                            const newValue = {
                              ...value,
                              template: {
                                id: template.id,
                                attributes: {
                                  name: template?.attributes?.name,
                                },
                              },
                              body: template?.attributes?.body || '',
                              subject: template?.attributes?.subject || '',
                            };
                            onChange(newValue);
                            return newValue;
                          });
                          return true;
                        }
                      });
                    });
                    return newValue;
                  });
                }
              }}
              placeholder="Select Category"
              //loading={loading}
              //notFoundContent={loading && <Spin size="small" />}
              style={{height: '40px'}}
              //className={isShowError && !value ? 'field-error' : ''}
            >
              {categoryList.map((category: any) => {
                return (
                  <Select.Option
                    key={category.code}
                    id={category.id}
                    value={category.name}
                    name={category.name}
                    code={category.code}
                  >
                    {category?.name}
                  </Select.Option>
                );
              })}
            </Select>
          </View>
        </HStack>
      </HStack>

      <View style={{marginTop: 16}}>
        <HStack>
          <HStack flex={2.4}>
            <Text fontWeight={300} fontSize={16} flex={2} marginTop={1}>
              {'Template'}
            </Text>
          </HStack>
          <HStack flex={7}>
            <View marginY={1} flex={1}>
              <Select
                suffixIcon={ props.isAutomationView?
                  <CaretDownOutlined
                    style={{
                      color: Colors.FoldPixel.GRAY300
                    }}
                  /> :
                  <DownOutlined/>
                }
                key={value?.category?.code}
                status={props.isShowError && !value?.template?.id ? 'error' :'' }
                size="large"
                showSearch
                allowClear
                //filterOption={false}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                value={
                  value?.template?.id
                    ? value?.template?.attributes?.name
                    : undefined
                }
                onSearch={searchTemplateList}
                onChange={(value: any[], data: any) => {
                  setValue((value: any) => {
                    const newValue: any = {
                      ...value,
                    };
                    const template = data?.id
                      ? {
                          id: data?.id,
                          attributes: {
                            name: data.name,
                          },
                        }
                      : undefined;

                    newValue.template = template;
                    newValue.body = data?.body || '';
                    newValue.subject = data?.subject || '';
                    newValue.pushNotificationUpdatedAt = new Date()
                    onChange(newValue);
                    return newValue;
                  });
                }}
                placeholder="Search Template"
                loading={loading}
                notFoundContent={loading && <Spin size="small" />}
                style={{height: '40px'}}
                className={isShowError && !value ? 'field-error' : ''}
              >
                {templateDataList.map((template) => {
                  return (
                    <Select.Option
                      key={template.id}
                      name={template?.attributes?.name}
                      id={template.id}
                      body={template?.attributes?.body}
                      subject={template?.attributes?.subject}
                    >
                      {template?.attributes?.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </View>
          </HStack>
        </HStack>
      </View>
      <View style={{marginTop: 16}}>
        <HStack flex={1}>
          <HStack flex={2.4}>
            <Text fontWeight={300} fontSize={16} flex={2} marginTop={1}>
              {'Info'}
              {<Text color="error.500">*</Text>}
            </Text>
          </HStack>
          <HStack flex={7}>
            <View flex={1}>
              <AddOrUpdatePushNotificationBody
                isHideLabel={false}
                errors={ props.isShowError && !value  ? {body:' '}: {}}
                notificationData={value}
                setNotificationData={(newValue: any) => {
                  setValue(newValue);
                  setValue((prev: any) => {
                    const newValue = {
                      ...prev,
                      body: prev.body || '',
                      subject: prev.subject || '',
                      pushNotificationUpdatedAt: new Date()
                    };
                    onChange(newValue);
                    return newValue;
                  });
                }}
                isAutomationView = {props?.isAutomationView}
              ></AddOrUpdatePushNotificationBody>
            </View>
          </HStack>
        </HStack>
      </View>
    </>
  );
};

export default NotificationTemplateSearch;
