import {StyleSheet} from 'react-native';
import { Colors } from '../../../../styles/Colors';
export const styles = StyleSheet.create({
  iconView: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    backgroundColor: 'transparent',
    // marginRight: 16,
    paddingRight: 16
  },
  icons: {
    marginLeft: 2,
    marginRight: 2,
    fontSize: 14.29,
    color: Colors.Custom.Gray600,
  },
  btn: {
    border: 'none',
    backgroundColor: 'transparent',
  },
  lastMenu: {
    paddingBottom: 10,
  },
  mainMenuTxt: {
    marginLeft: 10
  },
  dividerStyle: {
    width: '100%',
  },
  mainMenuContent: {
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 2,
    cursor: 'pointer',
  },
  firstMenu: {
    paddingTop: 10,
  },
  personActionsPopoverBody: {
    minWidth: 170,
    height: 'auto',
    boxShadow: 'rgb(0 0 0 / 15%) 0px 5px 15px 1px',
    backgroundColor: '#FFF',
    paddingTop: 3,
  },
  antIcon: {
    color: Colors.secondary['800']
  }
});

export const reactStyles: Record<string, React.CSSProperties> = {
  popover: { 
    padding: 0, 
    borderRadius: 16 
  },
  padding0: {
    padding: 0
  }
};
