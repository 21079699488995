import axios from 'axios';
import {INotificationParams} from '../components/RightSideContainer/TeamInbox/Conversations/MessagingWindow/interface';
import {BASE_URL} from '../constants/Configs';
import {IMeetingInvitees, IMemberData} from '../Interfaces/HmsInterfaces';
import {
  IHostInfo,
  IMeeting,
  IUserList,
} from '../screens/VideoMeetPreview/Utils/interface';
import LocalStorage from '../utils/LocalStorage';
import {IGroupMember} from './Conversations/interfaces';
import {createAxiosInstance} from '../utils/APIUtils';
import {MEETING_VENDORS} from '../constants';
import BaseService from './CommonService/BaseService';
// import {IGroupMember} from './Conversations/interfaces';

export const scheduleMeet = async (
  topic: string,
  accountId: string | number,
  accountUUID: string,
  accountName: string,
  meeting: IMeeting,
  invitees: IMemberData[],
  groupInfo: any,
  hostInfo: IHostInfo,
  isZoomEnabled?: boolean
) => {
  const randomPassword = Math.floor(100000 + Math.random() * 900000);
  const meetingInvitees: IMeetingInvitees[] = [];
  if (groupInfo) {
    const groupMembers = groupInfo.groupMembers;
    groupMembers?.forEach((member: IGroupMember) => {
      if (hostInfo?.id != member?.contact?.id) {
        let temp: IMeetingInvitees;
        if (member?.contact) {
          temp = {
            firstName: member?.contact?.name,
            lastName: '',
            email: member?.contact?.email,
            uuid: member?.contact?.uuid,
            type: 'CONTACT',
            phoneNumber: member?.contact?.phoneNumber,
          };
          meetingInvitees.push(temp);
        }
      }
    });
  } else {
    invitees?.forEach((invitee: IMemberData) => {
      if (hostInfo?.id + '' != invitee?.person?.id) {
        let temp: IMeetingInvitees;
        if (invitee?.person) {
          temp = {
            firstName: invitee.person.firstName,
            lastName: invitee.person.lastName,
            email: invitee.email,
            uuid: invitee.uuid,
            type: 'CONTACT',
            phoneNumber: invitee?.phoneNumber,
          };
          meetingInvitees.push(temp);
        } else {
          temp = {
            firstName: invitee.firstName,
            lastName: invitee.lastName,
            email: invitee.email,
            uuid: invitee.uuid,
            type: 'CONTACT',
            phoneNumber: invitee?.phoneNumber,
          };
          meetingInvitees.push(temp);
        }
      }
    });
  }

  meeting?.inviteeList?.forEach((invitee: IUserList) => {
    if (hostInfo?.id != invitee.user.id) {
      const temp: IMeetingInvitees = {
        firstName: invitee.user?.name,
        lastName: '',
        email: invitee.user.email,
        uuid: invitee.user.uuid,
        type: 'USER',
      };
      meetingInvitees.push(temp);
    }
  });
  const [firstName, lastName]: any = hostInfo?.name?.split(' ');

  const body = {
    agenda: topic,
    accountUuid: accountUUID,
    accountId: accountId,
    templateCategoryCode: 'GENERAL',
    sendMail: meeting.sendEmail || false,
    sendEmailToPatient : meeting.sendEmailToPatient || false,
    sendSmsToPatient: meeting.sendSmsToPatient || false,
    sendNotificationToPatient: meeting.sendNotificationToPatient || false,
    accountName: accountName,
    meetingData: {
      hostInfo: {
        email: hostInfo?.email,
        first_name: firstName,
        last_name: lastName,
        password: randomPassword,
        userUUID: hostInfo?.uuid,
        hostType: 'USER',
        subtitle: hostInfo?.subtitle || '',
      },
      meetingInvitees: meetingInvitees,
    },
  };

  const userDataString = await LocalStorage.getItem('user');
  const userData = JSON.parse(userDataString);
  const axiosObj = createAxiosInstance({
    baseURL: `${BASE_URL}`,
    headers: {
      'Content-type': 'application/json',
      'token-type': 'Bearer',
      fold_access_token: userData?.fold_access_token,
    },
  });
  const url = isZoomEnabled
    ? '/crm-communication/meeting/instant'
    : '/crm-communication/meetms/meeting';
  return axiosObj.post(url, JSON.stringify(body));
};

//
export const callNotificationAPI = async (
  accountId: string | number,
  accountUUID: string,
  accountName: string
) => {
  const body = {
    accountUuid: accountUUID,
    accountId: accountId,
    templateCategoryCode: 'GENERAL',
    accountName: accountName,
    meetingData: {},
  };
  const userDataString = await LocalStorage.getItem('user');
  const userData = JSON.parse(userDataString);
  const fold_access_token = userData?.fold_access_token;
  const axiosObj = createAxiosInstance({
    baseURL: `${BASE_URL}`,
    headers: {
      'Content-type': 'application/json',
      'token-type': 'Bearer',
      fold_access_token,
    },
  });
  return axiosObj.post('/crm-nest/platform/notification', JSON.stringify(body));
};

export const getMeetingType = async (data: {meetingId: string}) => {
  const baseService = BaseService.getSharedInstance().axios;
  const url = `${BASE_URL}/crm-communication/meeting/${data.meetingId}/type`;
  const response = await baseService.get<{type: string}>(url);
  return response.data;
};

export const isZoomMeetingId = async (data: {meetingId: string}) => {
  const {meetingId} = data;
  if (!(meetingId && meetingId.length > 0)) {
    return false;
  }
  try {
    const meetingType = await getMeetingType({meetingId});
    return meetingType.type === MEETING_VENDORS.ZOOM;
  } catch (error) {
    return false;
  }
};
