import React from 'react';
import {isWeb} from '../../../utils/platformCheckUtils';
import {Image} from 'native-base';

const MedicationIconSvg = () => {
  return (
    <>
      {isWeb() ? (
        <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.9058 17.466C14.2568 16.1149 15.4314 14.9403 16.4539 13.9178V9.15328C16.4539 8.09775 15.4599 7.17188 14.3267 7.17188H13.4539C13.4539 6.68663 13.4539 6.04903 13.4539 5.57812H13.9174C14.6693 5.57812 15.3289 4.93163 15.3289 4.19461V1.47295C15.3289 0.777656 14.7253 0 13.9174 0H12.0874V4.17188H10.6812V0H8.95387V4.17188H7.54762V0H5.70726V4.17188H4.30101V0H2.58408C1.77623 0 1.17262 0.777656 1.17262 1.47295V4.19461C1.17262 4.93163 1.83215 5.57812 2.58408 5.57812H2.95387V7.17188H2.22169C1.12481 7.17188 0.000747681 8.07581 0.000747681 9.15328V21.9366C0.000747681 22.9779 1.10086 24 2.22169 24H13.0627L12.9058 23.8431C11.1476 22.0849 11.1476 19.2241 12.9058 17.466ZM8.95387 16.2891V18.6386C8.95387 19.0269 8.63906 19.3417 8.25075 19.3417C7.86244 19.3417 7.54762 19.0269 7.54762 18.6386V16.2891H5.19811C4.80979 16.2891 4.49498 15.9742 4.49498 15.5859C4.49498 15.1976 4.80979 14.8828 5.19811 14.8828H7.54762V12.5333C7.54762 12.145 7.86244 11.8302 8.25075 11.8302C8.63906 11.8302 8.95387 12.145 8.95387 12.5333V14.8828H11.3034C11.6917 14.8828 12.0065 15.1976 12.0065 15.5859C12.0065 15.9742 11.6917 16.2891 11.3034 16.2891H8.95387Z"
          fill="#98A2B3"
        />
        <path
          d="M23.0918 14.1418L22.8494 13.8994C21.6361 12.6861 19.6672 12.6933 18.4612 13.8992L16.7297 15.6308L21.3605 20.2616L23.0919 18.5302C24.3017 17.3203 24.3017 15.3517 23.0918 14.1418Z"
          fill="#98A2B3"
        />
        <path
          d="M13.9001 18.4604C12.6902 19.6702 12.6902 21.6389 13.9001 22.8487L14.1426 23.0912C15.3524 24.301 17.321 24.3011 18.5309 23.0912L20.3661 21.256L15.7353 16.6252L13.9001 18.4604Z"
          fill="#98A2B3"
        />
      </svg>
      ) : (
        <Image
          resizeMode="contain"
          size={'24px'}
          source={require('../../../assets/images/Contact/activeMedications.png')}
          alt="image"
        />
      )}
    </>
  );
};

export default MedicationIconSvg;
