import React from 'react';

const NoConversationSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="202"
      height="202"
      fill="none"
      viewBox="0 0 202 202"
    >
      <circle
        cx="101"
        cy="100.999"
        r="100"
        stroke="url(#paint0_radial_516_22247)"
        strokeDasharray="2.92 2.92"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      ></circle>
      <circle
        cx="101.001"
        cy="101"
        r="85.333"
        stroke="url(#paint1_radial_516_22247)"
        strokeDasharray="2.92 2.92"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      ></circle>
      <path
        fill="url(#paint2_linear_516_22247)"
        d="M30.709 101.203c0-38.66 31.34-70 70-70s70 31.34 70 70-31.34 70-70 70-70-31.34-70-70z"
      ></path>
      <path
        stroke="#A7AFBE"
        strokeWidth="3.194"
        d="M101 132.944c17.643 0 31.945-14.302 31.945-31.945 0-17.642-14.302-31.944-31.945-31.944-17.642 0-31.944 14.302-31.944 31.944 0 5.11 1.2 9.94 3.333 14.223.567 1.138.756 2.44.427 3.668l-1.903 7.111c-.825 3.087 1.999 5.911 5.086 5.085l7.11-1.903c1.229-.328 2.53-.14 3.668.427A31.805 31.805 0 00101 132.944z"
      ></path>
      <defs>
        <radialGradient
          id="paint0_radial_516_22247"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(-90.639 122.798 23.168) scale(156.934)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A1AABA"></stop>
          <stop offset="1" stopColor="#E4E7EC"></stop>
        </radialGradient>
        <radialGradient
          id="paint1_radial_516_22247"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(-90.639 119.518 19.852) scale(133.917)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A1AABA"></stop>
          <stop offset="1" stopColor="#E4E7EC"></stop>
        </radialGradient>
        <linearGradient
          id="paint2_linear_516_22247"
          x1="51.001"
          x2="147"
          y1="47"
          y2="168.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9FAFC"></stop>
          <stop offset="1" stopColor="#F1F2F3"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default NoConversationSvg;
