import {StyleSheet} from 'react-native';
import {Colors} from '../../../styles/Colors';
export const styles = StyleSheet.create({
  unreadMsgBox: {
    borderRadius: 1000,
    backgroundColor: Colors.Custom.UnReadTost,
    color: Colors.Custom.FontColorWhite,
    borderColor: Colors.Custom.BorderColor,
    borderWidth: 1,
    marginTop: 10,
    marginBottom: 10,
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 20,
    paddingRight: 20,
    position: 'relative',
  },
  txtColor: {
    color: Colors.Custom.FontColorWhite,
  }
});
