import React from 'react';
import Svg, {Path} from 'react-native-svg';
import { ICommonSvgProps } from '../interfaces';

function ClosedCollapseSvg(props: ICommonSvgProps) {
  const {customStrokeColor} = props;
  return (
    <Svg width="6" height="6" viewBox="0 0 6 6" fill="none">
      <Path
        d="M4 3L2 1L2 5L4 3Z"
        fill={customStrokeColor || "#6F7A90"}
        stroke={customStrokeColor || "#6F7A90"}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </Svg>
  );
}

export default React.memo(ClosedCollapseSvg);
