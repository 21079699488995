import CommonService from '../../../../services/CommonService/CommonService';
import {
  IConversationAttachments,
  IConversationAttachmentsData,
} from './interfaces';

export const conversationAttachments = (params: IConversationAttachments) => {
  const pathParams = params.pathParams;
  const bodyParams = params.bodyParams;
  const commonService = CommonService.getCommonServiceInstance();
  const axiosService = commonService.axiosService;
  return axiosService.post<IConversationAttachmentsData[]>(
    `/accounts/${pathParams?.accountId}/conversations/${pathParams?.conversationDisplayId}/messages_attachments`,
    {
      message_ids: bodyParams?.attachmentMessageId,
    }
  );
};
