export const isValidArray = (arrayData: any) => {
  const temp = arrayData;
  let isValid = false;
  temp.forEach((element: any) => {
    isValid = element.contactId === '' || element.relationContactId === '';
  });
  return isValid;
};

export const isDuplicate = (arrayData: any) => {
  return arrayData.some((value: any) => {
    return arrayData.indexOf(value) !== arrayData.lastIndexOf(value);
  });
};

export const checkDuplicate = (arrayData: any) => {
  const temp = arrayData;
  const valueArr = temp.map((item: any) => {
    return item.relationContactId;
  });
  return isDuplicate(valueArr);
};

export const validateEmptyKey = (listArray:any)=> {
  
  return listArray.some((e:any) => e.relationId === '' || e.contactId === '')
}