import React from 'react';
import {ICommonSvgProps} from '../interfaces';
import Svg, {ClipPath, Defs, G, Path} from 'react-native-svg';

const AppointmentPopoverActionSvg = (props: ICommonSvgProps) => {
  const strokeColor = props.isActive
    ? '#667085'
    : props.customStrokeColor
    ? props.customStrokeColor
    : '#D0D5DD';
  return (
    <Svg
      width={props?.width ? props?.width : 'inherit'}
      height={props?.height ? props?.height : 'inherit'}
      fill="none"
      viewBox="0 0 16 16"
    >
      <G clipPath="url(#clip0_3512_126614)">
        <Path
          fill={strokeColor}
          d="M12 11.067a.5.5 0 100-1v1zm-2.666-1a.5.5 0 000 1v-1zm1.833-.868a.5.5 0 10-1 0h1zm-1 2.735a.5.5 0 101 0h-1zm-6-9.572a.5.5 0 001 0h-1zm1-1.026a.5.5 0 00-1 0h1zm5.667 1.026a.5.5 0 001 0h-1zm1-1.026a.5.5 0 00-1 0h1zM1.667 5.28a.5.5 0 000 1v-1zm12.667 1a.5.5 0 000-1v1zM6.667 2.862h2.667v-1H6.667v1zm7.5 4.97v1.367h1V7.832h-1zm-4.833 6.337H6.667v1h2.667v-1zm-7.5-4.97V7.832h-1v1.367h1zm4.833 4.97c-1.272 0-2.174 0-2.858-.095-.668-.092-1.054-.265-1.336-.555l-.716.698c.498.511 1.132.74 1.915.847.769.106 1.752.105 2.995.105v-1zM.833 9.2c0 1.276 0 2.281.102 3.066.105.798.325 1.443.822 1.952l.716-.698c-.285-.292-.455-.692-.546-1.384-.092-.704-.094-1.633-.094-2.936h-1zm13.334 0c0 1.303-.001 2.232-.094 2.936-.09.692-.26 1.092-.545 1.384l.716.698c.496-.51.716-1.154.821-1.952.103-.785.102-1.79.102-3.066h-1zm-4.833 5.97c1.242 0 2.226.001 2.994-.105.784-.108 1.417-.336 1.916-.847l-.716-.698c-.283.29-.668.463-1.337.555-.684.094-1.586.095-2.857.095v1zm0-12.307c1.271 0 2.173 0 2.857.095.669.092 1.054.265 1.337.555l.716-.698c-.499-.512-1.132-.74-1.916-.848-.768-.106-1.752-.104-2.994-.104v1zm5.833 4.97c0-1.276 0-2.282-.102-3.066-.105-.798-.325-1.443-.821-1.952l-.716.698c.284.291.455.692.545 1.384.093.704.094 1.633.094 2.936h1zm-8.5-5.97c-1.243 0-2.226-.002-2.995.104-.783.109-1.417.336-1.915.848l.716.698c.282-.29.668-.463 1.336-.555.684-.094 1.586-.095 2.858-.095v-1zm-4.833 5.97c0-1.303 0-2.232.093-2.936.09-.692.261-1.093.546-1.384l-.716-.698c-.497.509-.717 1.154-.822 1.952-.103.784-.102 1.79-.102 3.066h1zM12 10.067h-1.333v1H12v-1zm-1.333 0H9.334v1h1.333v-1zm-.5-.868v1.368h1V9.199h-1zm0 1.368v1.367h1v-1.367h-1zm-5-8.205V1.336h-1v1.026h1zm6.667 0V1.336h-1v1.026h1zM1.667 6.28h12.667v-1H1.667v1z"
        ></Path>
      </G>
      <Defs>
        <ClipPath id="clip0_3512_126614">
          <Path fill="#fff" d="M0 0H16V16H0z"></Path>
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export default React.memo(AppointmentPopoverActionSvg);
