import { IParticipantData } from "../AppointmentBookingIntefaces";
import { IParticipantStatusMap } from "./interfaces";

export const generateUserAndStatusMap = (
  contacts: IParticipantData[]
): IParticipantStatusMap => {
  const map: IParticipantStatusMap = {};

  contacts.forEach((contact) => {
    if (contact?.contactId) {
      map[contact?.contactId] = {
        id: contact?.id,
        statusId: contact?.statusId,
        reference: contact?.reference,
      };
    }
  });
  return map;
};
