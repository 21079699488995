import { Divider, HStack, Skeleton, VStack } from 'native-base'
import React from 'react'

const SkeletonLoader = () => {
  return (
    <VStack>
      <HStack w="100%" overflow="clip">
        <Skeleton size="20" rounded="full" />
        <Skeleton.Text rounded="sm" lines={2} mx={2} />
      </HStack>
      <Divider my={2} />
      <VStack my={2}>
        <Skeleton size="10" w="100%" my={2} rounded="md" />
        <Skeleton.Text rounded="sm" lines={2} />
      </VStack>
      <VStack my={2}>
        <Skeleton size="10" w="100%" my={2} rounded="md" />
        <Skeleton.Text rounded="sm" lines={2} />
      </VStack>
      <VStack my={2}>
        <Skeleton size="10" w="100%" my={2} rounded="md" />
        <Skeleton.Text rounded="sm" lines={2} />
      </VStack>
    </VStack>
  );
}

export default SkeletonLoader