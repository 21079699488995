import React from 'react';
import {Collapse} from 'antd';
import {ScrollView, View, VStack} from 'native-base';
import {styles} from './MessagingContactDetailsStyles';
import {DisplayCard} from '../../../../common/DisplayCard/DisplayCard';
import {
  relatedPanelDummyDataOne,
  relatedPanelDummyDataTwo,
} from '../../../../../dummyData/Conversations/relatedPanelDummyData';
import {CommonRelatedBadge} from './CommonRelatedBadge';

const {Panel} = Collapse;

export const RelatedPanel = () => {
  return (
    <ScrollView height={'68vh'}>
      <VStack space={2} mt={2}>
        <Collapse defaultActiveKey={['1']} expandIconPosition={'right'}>
          <Panel header="DEAL" key="1">
            <View style={styles.ticketPanel}>
              <DisplayCard
                headerString={relatedPanelDummyDataOne.headerString}
                displayList={relatedPanelDummyDataOne.displayList}
                footerData={relatedPanelDummyDataOne.footerData}
              />
            </View>
            <CommonRelatedBadge label="deals" />
          </Panel>
        </Collapse>

        <Collapse defaultActiveKey={['1']} expandIconPosition={'right'}>
          <Panel header="TICKET" key="1">
            <View style={styles.ticketPanel}>
              <DisplayCard
                headerString={relatedPanelDummyDataTwo.headerString}
                displayList={relatedPanelDummyDataTwo.displayList}
                footerData={relatedPanelDummyDataTwo.footerData}
              />
            </View>
            <CommonRelatedBadge label="tickets" />
          </Panel>
        </Collapse>
        <Collapse defaultActiveKey={['1']} expandIconPosition={'right'}>
          <Panel header="TASK" key="1">
            <View style={styles.ticketPanel}></View>
            <CommonRelatedBadge label="tasks" />
          </Panel>
        </Collapse>
      </VStack>
    </ScrollView>
  );
};
