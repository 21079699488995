import {StyleSheet} from 'react-native';
import { Colors } from '../../../../../../styles/Colors';

export const styles = StyleSheet.create({
  reviewView: {
    marginTop: 30,
  },
  reviewTitle: {
    fontWeight: '600',
    fontSize: 18,
    color: '#000000',
  },
  reviewSubTitle: {
    fontWeight: '300',
    fontSize: 15,
    color: Colors.Custom.Gray600,
    marginTop:5,
    marginBottom:10
  },
  reviewCategory: {
    flexDirection: 'column',
    alignContent: 'flex-start',
    alignItems: 'flex-start',
    justifyContent: 'space-evenly',
    backgroundColor: '#ffff',
    paddingVertical: 10,
    marginTop: 10,
    height: 'auto',
  },
  reviewSubCategory: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  reviewSubCategoryWorkflowRender: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    height: '350px',
    width: '1000px',
  },
  reviewTriggerSubText: {
    marginTop: 6,
    fontWeight: '300',
    marginLeft:32,
    fontSize: 15,
    color: Colors.Custom.Gray600,
  },
  reviewHeaderText: {
    marginLeft: 12,
    fontWeight: '500',
    fontSize: 18,
    color: '#000000',
  },
});
