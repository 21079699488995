import { LazyQueryExecFunction, OperationVariables } from '@apollo/client';
import {
  ContactObject,
  ContactResponse,
  ContactResponseObject,
  GraphMemberListControllerArgs,
  GraphMemberListState
} from './interfaces';

export class GraphMemberListController {
  private state: GraphMemberListState;
  private contactUuidList: string[];
  private contactIdList: number[];
  private accountId: string | number;
  private createCampaignUrl = '/#/campaignManagement/create';
  private accountUuid: string;
  private setState: React.Dispatch<React.SetStateAction<GraphMemberListState>>;
  private getContactsByUuid: LazyQueryExecFunction<any, OperationVariables>;
  private getContactsByIds: LazyQueryExecFunction<any, OperationVariables>;
  private onCompleteAction: () => void;

  constructor(args: GraphMemberListControllerArgs) {
    this.state = args.state;
    this.contactUuidList = args.contactUuidList;
    this.accountId = args.accountId;
    this.accountUuid = args.accountUuid;
    this.setState = args.setState;
    this.getContactsByUuid = args.getContactsByUuid;
    this.getContactsByIds = args.getContactsByIds;
    this.onCompleteAction = args.onCompleteAction;
    this.contactIdList = args.contactIdList || [];
    // binding
    this.getContactList = this.getContactList.bind(this);
    this.onMount = this.onMount.bind(this);
    this.handleOnClose = this.handleOnClose.bind(this);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.getFormattedContactData = this.getFormattedContactData.bind(this);
    this.getSelectedContacts = this.getSelectedContacts.bind(this);
    this.getContactPageMap = this.getContactPageMap.bind(this);
    this.handleSelectedContacts = this.handleSelectedContacts.bind(this);
  }

  onMount(contactUuidList: string[], contactIdList?:number[]) {
    this.contactUuidList = contactUuidList;
    this.contactIdList = contactIdList || [];
    this.getContactList();
  }

  handleOnClose() {
    this.onCompleteAction();
  }

  handleVisibleChange(value: boolean) {
    this.setState((prev) => {
      return {
        ...prev,
        popoverOpen: value,
      };
    });
  }

  private async getContactList() {

    this.setState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });

    try {
      if (this.contactUuidList?.length || this.contactIdList?.length) {
        const methodCall =  this.contactUuidList?.length ? this.getContactsByUuid : this?.getContactsByIds;
        const variables:any = {
          accountId: this.accountId,
        }
        if(this.contactUuidList?.length){
          variables.contactUuidList =  (this.contactUuidList || []).filter((uuid) => {return !!uuid});
        }
        if(this.contactIdList?.length){
          variables.contactIdList =  (this.contactIdList || []).filter((id) => {return !!id});
        }
        const response = await methodCall({
          variables: variables,
          fetchPolicy: 'no-cache',
        });


        const contacts = response?.data?.contacts || [];
        const contactList = this.getFormattedContactData(contacts);
        const contactResponse1 = this.getContactResponseObjects(contacts);
        const contactResponse = ((variables?.contactIdList?.length ? variables.contactIdList : variables.contactUuidList) || []).map((contactId:number|string)=>{
          let contactObj: any = {};
          contactResponse1.some((obj)=>{
            if(obj.contact?.id == contactId ||  obj.contact?.uuid == contactId) {
              contactObj = obj;
            }
          })
          return contactObj;
        })
        this.setState((prev) => {
          return {
            ...prev,
            loading: false,
            contactList: contactList,
            contactResponse,
          };
        });
      } else {
        this.setState((prev) => {
          return {
            ...prev,
            loading: false,
            contactList: [],
            contactResponse: [],
          };
        });
      }

    } catch (error) {
      this.setState((prev) => {
        return {
          ...prev,
          loading: false,
          contactList: [],
          contactResponse: [],
        };
      });

    }
  }



  getFormattedContactData(contacts: ContactResponse[]): ContactObject[] {
    const result: ContactObject[] = [];
    const map: any = {};
    if (contacts.length == 0) {
      return result;
    }
    contacts.forEach((item) => {
      if (!map[item.uuid]) {
        map[item.uuid] = item;
        result.push({
          contact: {
            id: (item?.id || '')?.toString(),
            uuid: item?.uuid,
            email: item?.email || '',
            isActive: item?.isActive,
            birthDate: item?.person?.birthDate,
            firstName: item?.person?.firstName,
            lastName: item?.person?.lastName,
            genderCode: item?.person?.gender?.code || '',
            genderValue: item?.person?.gender?.value || '',
            name: item?.name,
            patientId: item?.patient?.patientId || '',
            patientUuid: item?.patient?.patientUuid || '',
            isSelected: false,
            phoneNumber: item?.phoneNumber,
            contactConsents: item?.contactConsents || [],
          },
        });
      }
    });
    return result;
  }

  getContactResponseObjects(
    contacts: ContactResponse[]
  ): ContactResponseObject[] {
    const result: ContactResponseObject[] = [];
    if (contacts.length == 0) {
      return result;
    }
    const map: any = {};
    contacts.forEach((item) => {
      if (!map[item.uuid]) {
        map[item.uuid] = item;
        result.push({
          contact: {
            id: item?.id,
            name: item?.name,
            isActive: item?.isActive,
            person: item?.person,
            uuid: item?.uuid,
            email: item?.email,
            patient: item?.patient,
            phoneNumber: item?.phoneNumber,
            contactConsents: item?.contactConsents || [],
          },
        });
      }
    });
    return result;
  }

  getSelectedContacts(args: {selectedKeys: string[]}) {
    const {selectedKeys} = args;
    const selectedContacts: ContactObject[] = [];
    this.state.contactList.forEach((item) => {
      const check = selectedKeys.some((key) => key == item.contact.uuid);
      if (check) {
        selectedContacts.push(item);
      }
    });
    return selectedContacts;
  }

  handleSelectedContacts(selectedKeys: string[]) {
    const selectedContacts = this.getSelectedContacts({selectedKeys});
    const finalSelectedKeys = [
      ...new Set([...this.state.selectedRowKeys, ...selectedKeys]),
    ];
    const allContacts = [...this.state.selectedContacts, ...selectedContacts];
    const uuids = allContacts.map(function (obj) {
      return obj.contact.uuid;
    });
    const finalContactList = allContacts.filter(function (el, i, arr) {
      return uuids.indexOf(el.contact.uuid) == i;
    });
    return {finalSelectedKeys, finalContactList};
  }

  getContactPageMap() {
    const contactPageMap: {[index in number]: string[]} = {};
    const pageSize = this.state.pageSize;
    let index = 1;
    for (let i = 0; i < this.contactUuidList.length; i += pageSize) {
      const chunk = this.contactUuidList.slice(i, i + pageSize);
      contactPageMap[index] = chunk;
      index += 1;
    }
    return contactPageMap;
  }
}

export const getContactWithConsent = (
  selectedRowKeys: string[],
  contactUuidMap: any
) => {
  const result = selectedRowKeys
    .map((uuid) => contactUuidMap[uuid])
    .filter((contact) => {
      return !!contact?.email;
    });
  return result;
};
