import {Button, Text} from 'native-base';
import {useIntl} from 'react-intl';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {BUTTON_TYPE} from '../../../constants';
import {Colors} from '../../../styles';
import { getOnFocusButtonStyles } from '../../../utils/commonUtils';
import {FoldButton} from '../../CommonComponents/FoldButton/FoldButton';
import PlusIcon from '../Svg/PlusSvg';

export interface IDropdownButtonProps {
  title: string;
  onClick?: () => void;
}

const CustomDropdownButton = (props: IDropdownButtonProps) => {
  const intl = useIntl();
  return (
    <FoldButton
    nativeProps={{
      variant: BUTTON_TYPE.PRIMARY,
      onPress: () => {
        if (props.onClick && typeof props.onClick === 'function') {
          props.onClick()
        }
      },
      leftIcon: (
       <PlusIcon />
      ),
    }}
    customProps={{
      btnText: intl.formatMessage({
        id: props.title,
      }),
      withRightBorder: false,
    }}
  ></FoldButton>
  );
};

export default CustomDropdownButton;
