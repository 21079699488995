import { Pie } from '@ant-design/plots';
import { View } from 'native-base';

const data = [
  {
    type: 'AÃ§Ãµes',
    value: 27,
  },
  {
    type: 'Fundos Imobiliarios',
    value: 40,
  },
  {
    type: 'Renda Fixa',
    value: 18,
  },
  {
    type: 'Internacional',
    value: 15,
  },
];

export function DonutChartGraph(props: {
  tooltip?: any;
  legend?: any;
  color?: any[];
  appendPadding: number;
  radius: number;
  interactions: any[];
  label: {type: string; content: string};
  colorField: string;
  data: any[];
  angleField: string;
  style?: any;
  right?: number;
  totalCount: number;
}) {


  const config = {
    appendPadding: 10,
    legend: props.legend || false,
    data: props.data,
    angleField: 'value',
    colorField: 'type',
    color: props.color,
    radius: 0.8,
    innerRadius: 0.64,
    theme: 'light',
    meta: {
      value: {
        formatter: (v: any) => `${v}`,
      },
    },
    label: undefined,
    statistic: {
      title: false as any,
      // title: {
      //   offsetY: -8,
      //   title: false,
      // },
      content: {
        content: `Total Executed\n ${props.totalCount}`,
        offsetY: -4,
        style: {
          fontSize: '10',
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
      
    },
    pieStyle: {
      lineWidth: 0,
    },
  };
  // if (props.legend != undefined) {
  //   config.legend = props.legend;
  // }
  // if (props.color != undefined) {
  //   config.color = props.color;
  // }
  // if (props.tooltip != undefined) {
  //   config.tooltip = props.tooltip;
  // }
  return (
    <View
      width={'full'}
      height="full"
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        position: 'relative',
        // right: props.right != undefined ?  props.right : 100,
      }}
    >
      { props?.data?.length > 0 && props.totalCount> 0 &&<Pie {...config} />}
      { props.totalCount<= 0 && <View>No Data Found</View>}
    </View>
  );
}

