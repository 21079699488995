import {cloneDeep} from '@apollo/client/utilities';
import {Drawer, Skeleton, Table} from 'antd';
import {
  Button,
  HStack,
  ScrollView,
  Spacer,
  Text,
  View,
  VStack,
} from 'native-base';
import React, {useEffect, useState} from 'react';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {displayName} from 'react-quill';
import {BUTTON_TYPE, DATE_FORMATS} from '../../../../../constants';
import {FlowType} from '../../../../../context/WorkflowContext';
import { Colors } from '../../../../../styles';
import {getDateObjFromDateStrAndFormat} from '../../../../../utils/DateUtils';
import {CommonCollapse} from '../../../Contacts/TeamMembers/LeftTeamContainer';
import {IProductDetailForm} from '../../../Sales/ProductsAndServices/Products/ProductDetailView/ProductDetailViewSidebar/RightSideContainer/ProductDetailViewForm/Forms/interfaces';
import {IUserInputField} from '../../../Workflow/FlowComponent/StateNodes/FlowNodeInterface';
import SideMenu from '../../../Workflow/FlowComponent/StateNodes/SideMenu';
import { getActivitiesFromElements, getActivityColumns, getCareJourneyEndDate } from '../../AddOrUpdateJourney/CareJourneyPreview/CareJourneyPreviewHelper';
import { IActivityDisplay, IActivityGroup } from '../../AddOrUpdateJourney/CareJourneyPreview/interface';
import { getNodeMetadata, INodeDataResponse } from '../../AddOrUpdateJourney/JourneyMetadataService';


export interface ICareJourneyActivitiesProps {
  nodes: any[];
  edges: any[];
  nodeMasterDataMap?: any;
  nodeMetaData?: any;
  journeyDetails: IProductDetailForm;
  displayConfig: {
    height?: string;
    showAssignee: boolean;
    canEdit: boolean;
    hideHeader?: boolean;
  };
  onNodeChange?: (nodes: any[]) => void;
  onEdgeChange?: (edges: any[]) => void;
  onNodeDataChange?: (nodeId: string, config: any) => void;
}

const PatientCareJourneyActivities = (props: ICareJourneyActivitiesProps) => {
  const [activitiesState, setActivitiesState] = useState<{
    loading: boolean;
    isTreeVisible: boolean;
    activityGroups: IActivityGroup[];
    nodeData: INodeDataResponse;
    editNodeData?: any;
    edges: any[];
    nodes: any[];
    isOpen: boolean;
    selectedNodeType: string;
    selectedUserInputFieldList: any,
  }>({
    loading: false,
    isTreeVisible: false,
    activityGroups: [],
    nodes: props.nodes,
    edges: props.edges,
    nodeData: {
      nodeMasterData: props.nodeMasterDataMap,
      nodeMetaData: props.nodeMetaData,
    },
    isOpen: false,
    selectedNodeType: '',
    selectedUserInputFieldList: undefined,
  });

  useEffect(() => {
    if (
      activitiesState.nodeData.nodeMasterData &&
      activitiesState.nodeData.nodeMetaData
    ) {
      setActivityGroups();
    }
    if (props.onNodeChange) {
      props.onNodeChange(activitiesState.nodes);
    }
  }, [activitiesState.nodes]);

  useEffect(() => {
    if (
      activitiesState.nodeData.nodeMasterData &&
      activitiesState.nodeData.nodeMetaData
    ) {
      setActivityGroups();
    } else {
      setActivitiesState((prev) => ({...prev, loading: true}));
      getNodeMetadata(
        FlowType.careJourney,
        (response) => {
          const groups = getActivitiesFromElements(
            [...activitiesState.nodes, ...activitiesState.edges],
            response.nodeMasterData?.nodeMap,
            response.nodeMetaData,
            getStartDate(),
            getEndDate()
          );
          setActivitiesState((prev) => ({
            ...prev,
            activityGroups: groups,
            loading: false,
            nodeData: {
              nodeMasterData: response.nodeMasterData?.nodeMap,
              nodeMetaData: response.nodeMetaData,
            },
          }));
        },
        () => {
          setActivitiesState((prev) => ({...prev, loading: false}));
        }
      );
    }
  }, []);

  const getStartDate = () => {
    if (props.journeyDetails.startDate) {
      return getDateObjFromDateStrAndFormat(
        props.journeyDetails.startDate,
        DATE_FORMATS.DISPLAY_DATE_FORMAT
      );
    }
  };

  const getEndDate = () => {
    const startDate = getStartDate();
    if (startDate) {
      return getCareJourneyEndDate(startDate, props.journeyDetails);
    }
    return undefined;
  };

  const setActivityGroups = () => {
    const groups = getActivitiesFromElements(
      [...activitiesState.nodes, ...activitiesState.edges],
      activitiesState.nodeData.nodeMasterData,
      activitiesState.nodeData.nodeMetaData,
      getStartDate(),
      getEndDate()
    );
    setActivitiesState((prev) => ({...prev, activityGroups: groups}));
  };

  const onCloseEditMode = () => {
    setActivitiesState((prev) => ({...prev, editNodeData: undefined, isOpen: false, selectedNodeType: '', selectedUserInputFieldList: undefined}));
  };

  const onRowClicked = (activityData: IActivityDisplay) => {
    if (props.displayConfig.canEdit) {
      setActivitiesState((prev) => ({
        ...prev,
        editNodeData: activityData.dataObject,
        isOpen: true,
        selectedNodeType: activityData.dataObject?.data?.nodeType,
        selectedUserInputFieldList: activityData.dataObject?.data.metaData.userInputFieldList
      }));
    }
  };

  const onAddNewActivityClicked = (activityGroup: IActivityGroup, index: number) => {
    setActivitiesState((prev) => ({
      ...prev,
      isOpen: true,
      selectedNodeType: activityGroup.list[0]?.dataObject?.data?.nodeType,
      selectedUserInputFieldList: getNewNodeUserInputFieldList(activityGroup.list[0]?.dataObject?.data.metaData.userInputFieldList)
    }));
  }

  const getNewNodeUserInputFieldList = (userInputFieldList: any[]) => {
    const newUserInputFieldList: any[] = [];
    (userInputFieldList || []).forEach(inputField => {
      inputField.value = {};
      newUserInputFieldList.push(inputField);
    });
    return newUserInputFieldList;
  }

  const onSaveEditMode = (data: any) => {
    const newUserInputFieldList: IUserInputField[] =
      data.userInputFieldMap.out.userInputFieldList || [];

    const updatedElements = activitiesState.nodes;
    updatedElements.forEach((element) => {
      if (element.id === activitiesState.editNodeData.id) {
        element.data.metaData.userInputFieldList = newUserInputFieldList;
      }
    });
    if (props.onNodeDataChange) {
      props.onNodeDataChange(
        activitiesState.editNodeData.id,
        newUserInputFieldList
      );
    }
    setActivitiesState((prev) => ({
      ...prev,
      nodes: [...updatedElements],
      editNodeData: undefined,
    }));
  };

  return (
    <>
      <VStack space={2}>
        {!props.displayConfig.hideHeader && (
          <HStack paddingX={2}>
            <Text fontSize="md">Care Journey Activities</Text>
            <Spacer />
            <Button
              variant="ghost"
              onPress={() => {
                setActivitiesState((prev) => ({...prev, isTreeVisible: true}));
              }}
            >
              Journey Flow
            </Button>
          </HStack>
        )}
        <ScrollView height={props.displayConfig?.height || '50vh'}>
          {activitiesState.loading && (
            <View>
              <Skeleton active />
            </View>
          )}
          {!activitiesState.loading && (
            <VStack space={4}>
              {activitiesState.activityGroups.map((activityGroup, index) => (
                <CommonCollapse
                  key={index}
                  textLocalId={activityGroup.title}
                  btnList={[
                    {
                      icon: <AntIcon
                      name="plus"
                      style={{color: Colors.Custom.Gray700, marginTop: 2}}
                      size={15}
                    />,
                      tooltipLabel: 'Add Activity',
                      onIconBtnClick: () => {
                        onAddNewActivityClicked(activityGroup, index);
                      },
                    },
                  ]}
                  style={{ overflow: 'hidden' }}
                >
                  <View>
                    <Table
                      rowClassName={(record, index) => index % 2 == 0 ? 'table-row-light' : ''}
                      rowKey={(row) => row.id}
                      dataSource={activityGroup.list}
                      onRow={(activityData: IActivityDisplay) => {
                        return {
                          onClick: () => {
                            onRowClicked(activityData);
                          },
                        };
                      } }
                      columns={getActivityColumns({
                        showAssignee: props.displayConfig.showAssignee,
                        showActions: props.displayConfig.canEdit,
                      })}
                      pagination={false}
                      size="small" />
                  </View>
                </CommonCollapse>
              ))}
            </VStack>
          )}
        </ScrollView>
      </VStack>
      <Drawer
        destroyOnClose
        placement="right"
        onClose={() => {
          onCloseEditMode();
        }}
        visible={activitiesState.isOpen}
        closable={false}
        width={500}
      >
        <SideMenu
          userInputFieldMap={{
            out: {
                userInputFieldList: cloneDeep(
                  activitiesState.selectedUserInputFieldList
                ),
              },
           }}
            nodeType={activitiesState.selectedNodeType || ''}
            isOpen={activitiesState.isOpen}
            title={displayName}
            onClose={onCloseEditMode}
            onSave={onSaveEditMode}
        />
      </Drawer>
    </>
  );
};

export default PatientCareJourneyActivities;
