import React, { useContext, useState } from 'react';
import { IMlov, IUser } from '../../Interfaces/CommonInterfaces';
import useGetBatchedAccountUsers from './useGetBatchedAccountUsers';
import { ToastType, showToast } from '../../utils/commonViewUtils';
import { useToast } from 'native-base';
import {
  GET_ALL_USER_COUNT_FOR_TIME_LOG_PERFORMER,
  GET_USERS_FOR_TIME_LOG_PERFORMER,
} from '../../services/User/UserQueries';
import { ITimeLoggingComponentState } from '../RightSideContainer/TeamInbox/Conversations/MessagingContactDetails/TimeLoggingComponent';
import { CommonDataContext } from '../../context/CommonDataContext';
import { getMlovIdFromCode, getMlovListFromCategory } from '../../utils/mlovUtils';
import { MLOV_CATEGORY } from '../../constants';
import { BILLING_ACTIVITY_STATUS, CARE_PROGRAM_STATUS_CODES } from '../../constants/MlovConst';
import { useMutation } from '@apollo/client';
import { LOG_BILLING_ACTIVITY } from '../../services/CareProgram/CareProgramQueries';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../constants/Configs';
import { notification } from 'antd';

export interface IUseManualLogTime {
  recordedTime: ITimeLoggingComponentState['recordedTime'];
  contactCareProgramId: string;
  onClose: (isSave?: boolean, totalBillingMinutes?: number, timerNoOfSeconds?: number) => void;
}

export interface IManualLogTimeState {
  taskType?: IMlov;
  timeSpent?: number;
  performedBy?: IUser;
  performedOn?: string;
}

export type IErrorState = {
  [field in keyof IManualLogTimeState]-?: string;
};

export interface IResourcesList {
  taskTypes: IMlov[];
}
const UseManualLogTime = (params: IUseManualLogTime) => {
  const toast = useToast();
  const mlovData = useContext(CommonDataContext);
  const careProgramTaskTypeList = getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.CCM_BILLING_TASK_TYPE
  ) || [];
  const careProgramActivityStatusMlov =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.CARE_PROGRAM_BILLABLE_ACTIVITY_STATUS
    ) || [];
  const careProgramCompletedStatusId = getMlovIdFromCode(
    careProgramActivityStatusMlov,
    BILLING_ACTIVITY_STATUS.COMPLETED
  );
  const careProgramDraftStatusId = getMlovIdFromCode(
    careProgramActivityStatusMlov,
    BILLING_ACTIVITY_STATUS.DRAFT
  )
  const [manualLogTimeFields, setManualLogTimeFields] =
    useState<IManualLogTimeState>({
      timeSpent:
        params.recordedTime.minutes > 0 || params.recordedTime.seconds > 0
          ? params.recordedTime.minutes +
          (params.recordedTime.seconds > 0 ? 1 : 0)
          : undefined,
    });

  const [errors, setErrors] = useState<IErrorState>({
    taskType: '',
    timeSpent: '',
    performedBy: '',
    performedOn: '',
  });

  const {
    loading: accountUserLoading,
    error: accountUsersError,
    userList: accountUserList,
  } = useGetBatchedAccountUsers({
    onError: () => {
      showToast(
        toast,
        'Error in fetching account users',
        ToastType.error,
        4000
      );
    },
    usersCountQueryName: GET_ALL_USER_COUNT_FOR_TIME_LOG_PERFORMER,
    usersQueryName: GET_USERS_FOR_TIME_LOG_PERFORMER,
  });

  const [logBillingActivity, { loading: loadingLogBillingActivity }] = useMutation(
    LOG_BILLING_ACTIVITY, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    onCompleted: (response: any) => {
      showToast(
        toast,
        'Time Logged Successfully',
        ToastType.success,
        4000
      )
      params.onClose(true, response.addContactCareProgramBillableActivity.billableActivityData.totalBillingMinutes, response.addContactCareProgramBillableActivity.updatedTimerSeconds.timerSeconds);
    },
    onError: (err: any) => {
      notification.destroy();
      notification.error({
        message: 'Error in saving time log',
        duration: 2.0,
        placement: 'top'
      });
      }
    }
  );


  const getErrorText = <fieldName extends keyof IManualLogTimeState>(
    field: fieldName
  ) => {
    let errorText = '';
    switch (field) {
      case 'taskType':
        errorText = 'Please select a task type';
        break;
      case 'performedBy':
        errorText = 'Please select a user';
        break;
      case 'performedOn':
        errorText = 'Please select date time';
        break;
      case 'timeSpent':
        errorText = 'Please enter time spent';
    }
    return errorText;
  };

  const handleFieldChange = <fieldName extends keyof IManualLogTimeState>(
    field: fieldName,
    fieldValue: IManualLogTimeState[fieldName]
  ) => {
    setManualLogTimeFields((prev) => {
      return {
        ...prev,
        [field]: fieldValue,
      };
    });
    if (fieldValue) {
      setErrors((prev) => ({
        ...prev,
        [field]: ''
      }))
    }
  };

  const isInvalid = () => {
    let hasError = false;
    const allFields: Required<IManualLogTimeState> = {
      taskType: {} as IMlov,
      timeSpent: 0,
      performedBy: {} as IUser,
      performedOn: '',
    };

    Object.keys(allFields)?.forEach((field) => {
      const fieldName = field as keyof IManualLogTimeState;
      if (!manualLogTimeFields[fieldName]) {
        setErrors((prev) => {
          return {
            ...prev,
            [field]: getErrorText(fieldName),
          };
        });
        hasError = true;
      }
    });
    return hasError;
  };

  const handleSaveTimeLog = () => {
    const hasError = isInvalid();
    if (!hasError) {
      logBillingActivity({
        variables: {
          params: {
            contactCareProgramId: params.contactCareProgramId,
            durationInMinutes: manualLogTimeFields.timeSpent,
            timestamp: manualLogTimeFields.performedOn,
            performedByUserId: manualLogTimeFields.performedBy?.uuid,
            statusId: careProgramDraftStatusId,
            billableActivityEntities: [{
              durationInMinutes: manualLogTimeFields.timeSpent,
              resourceTypeCode: manualLogTimeFields.taskType?.value,
              resourceId: manualLogTimeFields.taskType?.id,
              statusId: careProgramCompletedStatusId,
              timestamp: manualLogTimeFields.performedOn,
              performedByUserId: manualLogTimeFields.performedBy?.uuid,
            }]
          }
        }
      })
    }
  };

  return {
    manualLogTimeFields,
    resourcesList: { taskTypes: careProgramTaskTypeList, accountUserList: accountUserList },
    handleSaveTimeLog,
    handleFieldChange,
    errors,
    accountUserLoading,
    manualLogTimeLoaders: { savingTimeEntry: loadingLogBillingActivity },
  };
};

export default UseManualLogTime;
