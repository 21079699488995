import React from 'react';
import {Svg, Path} from 'react-native-svg';
import {ICommonSvgProps} from './interfaces';

interface ISorterSvgProps extends ICommonSvgProps {
  sortOrder: 'asc' | 'desc' | 'none';
}

const SorterSvg = (props: ISorterSvgProps) => {
  const size = props.size || 24;
  const sortOrder = props.sortOrder;

  const topColor = sortOrder === 'asc' ? '#6F7A90' : '#D0D6E1';
  const bottomColor = sortOrder === 'desc' ? '#6F7A90' : '#D0D6E1';

  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        d="M12 8L10 10L14 10L12 8Z"
        fill={topColor}
        stroke={topColor}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <Path
        d="M12 16L14 14H10L12 16Z"
        fill={bottomColor}
        stroke={bottomColor}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </Svg>
  );
};

export default SorterSvg;
