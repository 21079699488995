import React from "react";
import Svg, { Path } from 'react-native-svg';

const CarePlanEstablishedSvg = () => {
    return (
        <Svg
          width="16"
          height="16"
          fill="none"
          viewBox="0 0 16 16"
        >
          <Path
            fill="#6F7A90"
            d="M8.5 4a.5.5 0 00-1 0h1zm-1 2.667a.5.5 0 001 0h-1zm-.833-1.834a.5.5 0 100 1v-1zm2.666 1a.5.5 0 100-1v1zm-4 3a.5.5 0 000 1v-1zm5.334 1a.5.5 0 000-1v1zM6 11.5a.5.5 0 000 1v-1zm4 1a.5.5 0 100-1v1zM7.333 1.833h1.334v-1H7.333v1zM13.5 6.667v2.666h1V6.667h-1zm-4.833 7.5H7.333v1h1.334v-1zM2.5 9.333V6.667h-1v2.666h1zm4.833 4.834c-1.27 0-2.174-.001-2.86-.094-.67-.09-1.056-.259-1.338-.54l-.707.706c.498.5 1.131.72 1.913.826.767.103 1.75.102 2.992.102v-1zM1.5 9.333c0 1.243-.001 2.226.102 2.993.105.782.327 1.414.826 1.913l.707-.707c-.283-.282-.452-.668-.542-1.34-.092-.684-.093-1.587-.093-2.859h-1zm12 0c0 1.272-.001 2.175-.093 2.86-.09.67-.26 1.057-.542 1.34l.707.706c.5-.499.72-1.131.826-1.913.103-.767.102-1.75.102-2.993h-1zm-4.833 5.834c1.243 0 2.225 0 2.992-.102.782-.105 1.415-.327 1.913-.826l-.707-.707c-.282.282-.668.451-1.339.541-.685.093-1.588.094-2.86.094v1zm0-13.334c1.27 0 2.174.001 2.86.094.67.09 1.056.259 1.338.541l.707-.707c-.498-.499-1.131-.72-1.913-.826C10.892.832 9.91.833 8.667.833v1zM14.5 6.667c0-1.243.001-2.226-.102-2.993-.105-.782-.327-1.414-.825-1.913l-.708.707c.283.282.452.669.542 1.34.092.684.093 1.587.093 2.859h1zM7.333.833c-1.243 0-2.225 0-2.992.102-.782.106-1.415.327-1.913.826l.707.707c.282-.282.668-.451 1.339-.541.685-.093 1.588-.094 2.86-.094v-1zM2.5 6.667c0-1.272.001-2.175.093-2.86.09-.67.26-1.057.542-1.339l-.707-.707c-.5.499-.72 1.131-.826 1.913-.103.767-.102 1.75-.102 2.993h1zM7.5 4v1.333h1V4h-1zm0 1.333v1.334h1V5.333h-1zm-.833.5H8v-1H6.667v1zm1.333 0h1.333v-1H8v1zm-2.667 4h5.334v-1H5.333v1zM6 12.5h4v-1H6v1z"
          ></Path>
        </Svg>
      );
}

export default CarePlanEstablishedSvg;
