import React, { useContext } from 'react';
import {HStack, Text} from 'native-base';
import {Switch, Tooltip} from 'antd';
import Feather from 'react-native-vector-icons/Feather';
import {Colors} from '../../../../../styles';
import { CustomToggleButton } from '../../../../common/CustomToggleButton/CustomToggleButton';
import { CommonDataContext } from '../../../../../context/CommonDataContext';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  featherStyle: {
    marginTop: 2,
  },
});

interface IShowToPatientSwitchProps {
  value: boolean;
  onChange: (value: boolean) => void;
  loading?: boolean;
  message: string;
  tooltipMessage?: string;
}

const ShowToPatientSwitch = (props: IShowToPatientSwitchProps) => {
  const context = useContext(CommonDataContext);
  const isSidecarContext = context.sidecarContext?.isSidecar;

  const switchStyle = React.useMemo(() => ({
    backgroundColor: props.value
      ? Colors.Custom.mainPrimaryPurple
      : Colors.Custom.silverGray,
    width: 10,
  }), [props.value]);

  const textStyle = React.useMemo(() => ({
    marginLeft: isSidecarContext ? 1 : 0,
    color: Colors.Custom.Gray500,
  }), [isSidecarContext]);

  return (
    <HStack alignItems={'center'} space={1} flexWrap={'wrap'}>
      {isSidecarContext ? (
        <CustomToggleButton
          size="small"
          value={props.value}
          onChange={props.onChange}
        />
      ) : (
        <Switch
          size="small"
          style={switchStyle}
          loading={props.loading}
          checked={props.value}
          onChange={props.onChange}
        />
      )}
      <Text style={textStyle}>{props?.message}</Text>
      {!!props?.tooltipMessage && (
        <Tooltip title={props?.tooltipMessage} placement="top">
          <Feather
            style={styles.featherStyle}
            size={14}
            name="info"
            color={Colors.Custom.Gray500}
          />
        </Tooltip>
      )}
    </HStack>
  );
};

export default ShowToPatientSwitch;
