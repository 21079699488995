import BaseService from '../CommonService/BaseService';
import {INTEGRATION_BASE_URL} from '../../constants/BaseUrlConst';

export const getPatientHieStatus = async (patientId: string) => {
  const baseService = BaseService.getSharedInstance().axios;
  const response = await baseService.get(
    `${INTEGRATION_BASE_URL}/api/hie/patient/status?patientId=${patientId}`
  );
  return response
};
