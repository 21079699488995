import { capitalize } from "lodash";
import { IAllergy } from "../../../../RightSideContainer/Forms/FHFormio/CustomComponents/Allergies/interfaces";
import { getStartAndEndDateText } from "../../../../RightSideContainer/Forms/FHFormio/CustomComponents/CareplanMedications/MedicationUtils";
import { ICondition } from "../../../../RightSideContainer/Forms/FHFormio/CustomComponents/Conditions/interfaces";
import { IMedicationStatement } from "../../../../RightSideContainer/Forms/FHFormio/CustomComponents/Medications/interfaces";
import { IImmunization } from "../../../../RightSideContainer/Forms/FHFormio/CustomComponents/Immunizations/interfaces";
import { getFormattedDate, isDateStringIsValid } from "../../../../../utils/DateUtils";
import { DATE_FORMATS, DISPLAY_DATE_FORMAT } from "../../../../../constants";
import { ISurgicalHistory } from "../../../../RightSideContainer/Forms/FHFormio/CustomComponents/SurgicalHistory/interfaces";
import { IPastMedicalHistory } from "../../../../RightSideContainer/Forms/FHFormio/CustomComponents/PastMedicalHistory/interfaces";
import { IFamilyHistory } from "../../../../RightSideContainer/Forms/FHFormio/CustomComponents/FamilyHistory/interfaces";
import { getConditionName } from "../../../../RightSideContainer/Forms/FHFormio/CustomComponents/FamilyHistory/AddOrUpdateFamilyHistory/AddOrUpdateFamilyHistoryHelper";
import { IObservation } from "../../../../RightSideContainer/Forms/FHFormio/CustomComponents/Vitals/interfaces";
import { getFormattedVitalValue } from "../../../../RightSideContainer/Forms/FHFormio/CustomComponents/Vitals/AddOrUpdateVitals/AddOrUpdateVitalsHelper";
import { IEhrCapability, IMlov, IVitalData } from "../../../../../Interfaces";
import { IPatientCommunicationComponentValue } from "../../../../RightSideContainer/Forms/FHFormio/CustomComponents/PatientCommunication/AddOrUpdatePatientCommunication/AddOrUpdatePatientCommunication";
import { IPatientDemographicsComponentValue, PatientField } from "../../../../RightSideContainer/Forms/FHFormio/CustomComponents/PatientDemographics/AddOrUpdatePatientDemographics/AddOrUpdatePatientDemographics";
import { IEmergencyContactComponentValue } from "../../../../RightSideContainer/Forms/FHFormio/CustomComponents/EmergencyContact/AddOrUpdateEmergencyContact/AddOrUpdateEmergencyContact";

export const getDropdownSelectedValues = (selectedValue: any, values: any[]) => {
  const result: string[] = [];
  values.forEach((item: any) => {
    if (
      selectedValue &&
      Array.isArray(selectedValue) &&
      selectedValue.map((item) => `${item}`).includes(`${item.value}`)
    ) {
      result.push(`${item.label}`);
    } else if (`${selectedValue}` === `${item.value}`) {
      result.push(`${item.label}`);
    } else if (
      selectedValue &&
      selectedValue.hasOwnProperty(item.value) &&
      selectedValue[item.value]
    ) {
      result.push(`${item.label}`);
    }
  });
  return result.join(', ');
};

export const getMedicationSubtitle = (item: IMedicationStatement) => {
  const subtitleList: string[] = [];
  const startTimeEndTimeText = getStartAndEndDateText(item);
  if (startTimeEndTimeText) {
    subtitleList.push(startTimeEndTimeText);
  }
  if (item.sig) {
    subtitleList.push(item.sig);
  }
  if (item.note) {
    subtitleList.push(item.note);
  }
  return subtitleList.join(' • ');
}

export const getAllergySubtitle = (item: IAllergy) => {
  const subtitleList: string[] = [];
  let startTimeEndTimeText = item.onSetDateTime;
  if (startTimeEndTimeText) {
    startTimeEndTimeText = isDateStringIsValid(startTimeEndTimeText) ? startTimeEndTimeText : getFormattedDate(startTimeEndTimeText, DATE_FORMATS.DISPLAY_DATE_FORMAT)
    subtitleList.push(startTimeEndTimeText);
  }
  if (item.clinicalStatus?.code) {
    subtitleList.push(item.clinicalStatus?.code);
  }
  if (item.criticality) {
    subtitleList.push(capitalize(item.criticality).split('-').join(' '));
  }
  if (item.reactions?.length) {
    const reactionList: string[] = [];
    item.reactions.forEach((reactionItem) => {
      let reaction = '';
      if (reactionItem.manifestation?.display) {
        reaction += reactionItem.manifestation?.display;
      }
      if (reactionItem.severity) {
        reaction ? reaction += ' - ' : undefined;
        reaction += capitalize(reactionItem.severity).split('-').join(' ');
      }
      if (reaction) {
        reactionList.push(reaction)
      }
    })
    subtitleList.push(reactionList.join(', '));
  }
  if (item.note) {
    subtitleList.push(item.note);
  }
  return subtitleList.join(' • ');
}

export const getConditionSubtitle = (item: ICondition) => {
  const subtitleList: string[] = [];
  let startTimeEndTimeText = item.onSetDateTime;
  if (startTimeEndTimeText) {
    startTimeEndTimeText = isDateStringIsValid(startTimeEndTimeText) ? startTimeEndTimeText : getFormattedDate(startTimeEndTimeText, DATE_FORMATS.DISPLAY_DATE_FORMAT)
    subtitleList.push(startTimeEndTimeText);
  }
  if (item.conditionType?.display) {
    subtitleList.push(item.conditionType?.display);
  }
  if (item.clinicalStatus?.code) {
    subtitleList.push(item.clinicalStatus?.code);
  }
  if (item.note) {
    subtitleList.push(item.note);
  }
  return subtitleList.join(' • ');
}

export const getImmunizationSubtitle = (item: IImmunization) => {
  const subtitleList: string[] = [];
  let startTimeEndTimeText = item.occurrenceDateTime;
  if (startTimeEndTimeText) {
    startTimeEndTimeText = isDateStringIsValid(startTimeEndTimeText) ? startTimeEndTimeText : getFormattedDate(startTimeEndTimeText, DATE_FORMATS.DISPLAY_DATE_FORMAT)
    subtitleList.push(startTimeEndTimeText);
  }
  if (item.doseQuantity) {
    let dose = item.doseQuantity;
    if (item.doseQuantityUnit) {
      dose += ` ${item.doseQuantityUnit}`;
    }
    subtitleList.push(dose);
  }
  if (item.statusCode) {
    subtitleList.push(capitalize(item.statusCode).split('-').join(' '));
  }
  if (item.practitioner?.name) {
    subtitleList.push(item.practitioner?.name);
  }
  return subtitleList.join(' • ');
}

export const getSurgicalHXSubtitle = (item: ISurgicalHistory) => {
  const subtitleList: string[] = [];
  let startTimeEndTimeText = item.performedDateTime;
  if (startTimeEndTimeText) {
    startTimeEndTimeText = isDateStringIsValid(startTimeEndTimeText) ? startTimeEndTimeText : getFormattedDate(startTimeEndTimeText, DATE_FORMATS.DISPLAY_DATE_FORMAT)
    subtitleList.push(startTimeEndTimeText);
  }
  if (item.status?.display) {
    subtitleList.push(item.status.display);
  }
  return subtitleList.join(' • ');
}

export const getPMHXSubtitle = (item: IPastMedicalHistory) => {
  const subtitleList: string[] = [];
  let startTimeEndTimeText = item.onSetDateTime;
  if (startTimeEndTimeText) {
    startTimeEndTimeText = isDateStringIsValid(startTimeEndTimeText) ? startTimeEndTimeText : getFormattedDate(startTimeEndTimeText, DATE_FORMATS.DISPLAY_DATE_FORMAT)
    subtitleList.push(startTimeEndTimeText);
  }
  if (item.pastMedicalHistoryType?.display) {
    subtitleList.push(item.pastMedicalHistoryType?.display);
  }
  if (item.verificationStatusCode) {
    subtitleList.push(capitalize(item.verificationStatusCode).split('-').join(' '));
  }
  if (item.clinicalStatus?.code) {
    subtitleList.push(item.clinicalStatus?.code);
  }
  if (item.note) {
    subtitleList.push(item.note);
  }
  return subtitleList.join(' • ');
}

export const getPatientCommunicationSubTitle = (item: IPatientCommunicationComponentValue, additionalParams: {isPatientAddress?: boolean}) => {
  const subtitleList: string[] = [];
  const data = additionalParams?.isPatientAddress ? ['addressLine1', 'addressLine2', 'zipCode', 'state', 'city'] : ['email', 'phoneNumber', 'addressLine1', 'addressLine2', 'zipCode', 'state', 'city'];
  data.forEach((key) => {
    let value = item[
      key as keyof IPatientCommunicationComponentValue
    ] as any;
    let fieldTitle = '';
    switch (key) {
      case 'email':
        fieldTitle = 'Email';
        break;
      case 'phoneNumber':
        fieldTitle = 'Phone Number';
        break;
      case 'addressLine1':
        fieldTitle = 'Address Line 1';
        break;
      case 'addressLine2':
        fieldTitle = 'Address Line 2';
        break;
      case 'zipCode':
        fieldTitle = 'Zip code';
        value = value?.code;
        break;
      case 'state':
        fieldTitle = 'State';
        value = value?.name;
        break;
      case 'city':
        fieldTitle = 'City';
        value = value?.name;
        break;
      default:
        break;
    }
    if (value) subtitleList.push(`${fieldTitle}:   ${value}`);
  });
  return subtitleList.join('\n');
}

const canShow = (component: any, field: PatientField, capabilities?: IEhrCapability) => {
  if (component?.enabledFields) {
    return component?.enabledFields[field];
  }
  const keyAllowedOperations = capabilities?.abilities?.keyAllowedOperations;
  switch (field) {
    case PatientField.birthSex: return !keyAllowedOperations?.birthSex?.isHidden;
    case PatientField.gender: return !keyAllowedOperations?.gender?.isHidden;
  }
  return true;
};

export const getPatientDemographicsSubTitle = (
  componentValue: IPatientDemographicsComponentValue,
  additionalParams: {
    hideGenderFields?: boolean,
    component: any,
    capabilities?: IEhrCapability,
    genderList?: IMlov[],
  }
) => {
  const subtitleList: string[] = [];
  const hideGenderFields = additionalParams?.hideGenderFields || false;
  const component = additionalParams?.component;
  const capabilities = additionalParams?.capabilities;
  const genderList = additionalParams?.genderList || [];
  const birthSexList = capabilities?.abilities?.keyAllowedOperations?.birthSex?.possibleValues || [];
  const data = ['firstName', 'lastName', 'birthDate'];
    if (canShow(component, PatientField.birthSex, capabilities) && !hideGenderFields) {
      data.push('birthSex');
    }
    if (canShow(component, PatientField.gender, capabilities) && !hideGenderFields) {
      data.push('genderCode');
    }
    data.forEach((item: string) => {
      let value = componentValue[
        item as keyof IPatientDemographicsComponentValue
      ] as any;
      let fieldTitle = '';
      switch (item) {
        case 'firstName':
          fieldTitle = 'First Name';
          break;
        case 'lastName':
          fieldTitle = 'Last Name';
          break;
        case 'birthDate':
          fieldTitle = 'DOB';
          break;
        case 'genderCode':
          fieldTitle = 'Gender';
          const selectedGender = genderList.find(
            (gender: IMlov) => {
              return gender.code == componentValue.genderCode;
            }
          );
          value = selectedGender?.value;
          break;
        case 'birthSex':
          fieldTitle = 'Sex at Birth';
          value = birthSexList.find(value => value.code === componentValue.birthSex)?.display
          break;
        case 'birthDate':
          fieldTitle = 'Date of Birth';
          value = componentValue.birthDate
            ? getFormattedDate(componentValue.birthDate, DISPLAY_DATE_FORMAT)
            : '';
          break;
        default:
      }
      if (value) subtitleList.push(`${fieldTitle}:   ${value}`);
    });
  return subtitleList.join('\n');
}

export const getEmergencyContactSubTitle = (
  componentValue: IEmergencyContactComponentValue
) => {
  const subtitleList: string[] = [];
  const data = ['firstName', 'lastName', 'phoneNumber', 'relationType'];
  data.forEach((item) => {
    let value = componentValue[
      item as keyof IEmergencyContactComponentValue
    ] as any;
    let fieldTitle = '';
    switch (item) {
      case 'firstName':
        fieldTitle = 'First Name';
        break;
      case 'lastName':
        fieldTitle = 'Last Name';
        break;
      case 'email':
        fieldTitle = 'Email';
        break;
      case 'phoneNumber':
        fieldTitle = 'Phone Number';
        break;
      case 'relationType':
        fieldTitle = 'Relation Type';
        value = value?.code ? capitalize(value?.code) : value?.code;
        break;
    }
    if (value) subtitleList.push(`${fieldTitle}:   ${value}`);
  });
  return subtitleList.join('\n');
}

export const getFHXSubtitle = (item: IFamilyHistory) => {
  return getConditionName(item);
}

export const getVitalSubTitle = (item: IObservation, additionalParams?: {vitalDataList: IVitalData[]}) => {
  const config = additionalParams?.vitalDataList.find(config => config.loinc === item.loinc);
  const {value} = getFormattedVitalValue(item, config, true) as  {value: string; valueText: string; unit: string | undefined; recordedDate: string; };
  return `${config?.foldDisplay || config?.display}  ${value}`;
}
