import React from 'react';
import {Spinner, View} from 'native-base';
import {styles} from './LoadingSpinnerStyle';
import { TestIdentifiers, testID } from '../../../testUtils';
const LoadingSpinner = () => {
  return (
    <View {...testID(TestIdentifiers.lazyLoading)} style={[styles.mainScreenLoader]}>
      <View style={styles.loaderView}>
        <Spinner size="lg" />
      </View>
    </View>
  );
};

export default LoadingSpinner;
