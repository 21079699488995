import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useToast, View} from 'native-base';
import {
  IContact,
  IConversationData,
  IConversationResponse,
} from '../../RightSideContainer/TeamInbox/Conversations/interfaces';

import {useIntl} from 'react-intl';
import {
  getAccountId,
  getCurrentUserRoleCodes,
  getUserData,
  getUserId,
  getUserUUID,
} from '../../../utils/commonUtils';
import {CommonDataContext} from '../../../context/CommonDataContext';
import BaseService from '../../../services/CommonService/BaseService';
import {
  ICreateGroupBodyParams,
  ISearchExistingGroupBodyParams,
} from '../CreateChannelView/interface';
import {GROUP_TYPE_CODES, MLOV_CATEGORY} from '../../../constants/MlovConst';
import {useLazyQuery, useMutation} from '@apollo/client';
import ConversationsQueries from '../../../services/Conversations/ConversationsQueries';
import {getDateToMomentISOString} from '../../../utils/DateUtils';
import {
  getMlovIdFromCode,
  getMlovListFromCategory,
} from '../../../utils/mlovUtils';
import {GROUP_MEMBER_TYPE} from '../../../constants/StringConst';
import {showToast, ToastType} from '../../../utils/commonViewUtils';
import GroupQueries from '../../../services/Conversations/GroupQueries';
import InstantPatientChatView from './InstantChatView';
import { isWeb } from '../../../utils/platformCheckUtils';
import { IChatDrawerProps, IChatDrawerState } from './interface';
import {getContactDataAndType} from '../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MessagingUtils';
import { isAccountConfigEnabled } from '../../../utils/configUtils';
import { CONFIG_CODES } from '../../../constants/AccountConfigConst';
import { allowOneTwoOnePatientChat, getDefaultConversationData } from '../../RightSideContainer/TeamInbox/DefaultCommunicationGroup/DefaultCommunicationGroupService';
import { COMMON_ACTION_CODES, CONVERSATION_ACTION_CODES, GROUP_ACTION_CODES } from '../../../constants/ActionConst';
import { IGroupMember } from '../../../services/Conversations/interfaces';
import { TEAM_MEMBERS_ROLES_CODE } from '../../RightSideContainer/Contacts/TeamMembers/AddEditUser/TeamMemberConst';
import { EventBus } from '../../../utils/EventBus';
import { CUSTOM_MESSAGE_EVENT_CODES } from '../../../constants/WebSocketConst';
import CreateChannelView from '../CreateChannelView/CreateChannelView';
import { CONVERSATION_TAB_CODES } from '../../RightSideContainer/TeamInbox/Conversations/ConversationConst';

const InstantChat = (props: IChatDrawerProps) => {
  const createGroupToast = useToast();
  const intl = useIntl();
  const accountId = getAccountId();
  const userUuid = getUserUUID();
  const userId = getUserId();
  const commonData = useContext(CommonDataContext);
  const loggedInUserRole = getCurrentUserRoleCodes();
  const currentUserData = getUserData();
  const {
    contactUuid,
    isDrawerVisible,
    onActionPerformed,
    navigation,
    secondaryUserUUID,
    groupType,
    name,
    selectedInboxTypeCode,
  } = props;
  const isPatientOneTwoOneChatEnabled = isAccountConfigEnabled(CONFIG_CODES.ALLOW_PATIENT_ONE_TWO_ONE_CHAT) && 
                                        !loggedInUserRole?.includes(TEAM_MEMBERS_ROLES_CODE.WORKFLOW) && 
                                        !loggedInUserRole?.includes(TEAM_MEMBERS_ROLES_CODE.CUSTOMER_SUCCESS);
  const isInternalChat = () =>  groupType === GROUP_TYPE_CODES.INTERNAL
  const [componentState, setComponentState] = useState<IChatDrawerState>(
    {
      selectedConversation: {} as IConversationData,
      contactData: {} as IContact,
      isLoading: true,
      openConfirmationModal: false,
      checkConversationLoading: false,
      openCreateChatDrawer: false,
    }
  );

  const [updateConversationLastActivityAt] = useMutation(
    ConversationsQueries.updateConversationLastActivityAt
  );

  const [getGroupMemberConversations] = useLazyQuery<IConversationResponse>(
    GroupQueries.GetSingleGroupConversations,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );

  const findExistingGroupWithSamePatient = async () => {
    setComponentState((prev) => {
      return {
        ...prev,
        isLoading: true,
        checkConversationLoading: true
      };
    });
    const baseService = BaseService.getSharedInstance().axios;
    const searchGroupWithExistUserUrl = `/crm-nest/account/${accountId}/search-group-with-exist-user`;
    const bodyData: ISearchExistingGroupBodyParams =  {
      groupTypeCode: groupType || GROUP_TYPE_CODES.PRIVATE,
      userIds: (isInternalChat() && secondaryUserUUID) ? [userUuid ,secondaryUserUUID] : [userUuid],
      ...(!isInternalChat() && {contactIds: [contactUuid || '']}),
    };
    const groupListInfo = await baseService
      .post(searchGroupWithExistUserUrl, bodyData, {
        headers: {
          api_access_token: currentUserData.access_token,
        },
      })
      .catch(() => {
        emptyRespHandler();
      });
    if (groupListInfo?.data?.length) {
      const conversationUuid = groupListInfo?.data?.[0]?.conversationUuid;
      await getSingleGroupConversation(conversationUuid, groupListInfo?.data?.[0]?.groupMembers);
    } else {
      if(isInternalChat()){
        // create group with user 2 user
        secondaryUserUUID && createGroupConversation( '',secondaryUserUUID);
      } else {
        if (isWeb()) {
          setComponentState((prev) => {
            return {
              ...prev,
              openConfirmationModal: true,
              isLoading: false,
            }
          })
        } else if (contactUuid) {
          createGroupConversation(contactUuid ,'');
        }
      }
    }
  };

  const createGroupConversation = async (contactUuid: string,secondaryUserUUID:string) => {
    const groupMemberTypeList =
      getMlovListFromCategory(commonData.MLOV, MLOV_CATEGORY.GroupMemberType) ||
      [];
    const userTypeId = getMlovIdFromCode(
      groupMemberTypeList,
      GROUP_MEMBER_TYPE.USER
    );

    const baseService = BaseService.getSharedInstance().axios;
    const createGroupUrl = `/crm-nest/account/${accountId}/group`;

    const bodyData: ICreateGroupBodyParams = {
      groupType: groupType ||  GROUP_TYPE_CODES.PRIVATE,
      groupName: name || '',
      groupData: {
        userIds: (isInternalChat() && secondaryUserUUID) ? [userUuid ,secondaryUserUUID] : [userUuid],
        ...(!isInternalChat() && {contactId: contactUuid || ''}),
        familyMemberIds: [],
      },
    };
    const groupInfo = await baseService
      .post(createGroupUrl, bodyData, {
        headers: {
          ['user-type-id']: userTypeId,
          api_access_token: currentUserData.access_token,
        },
      })
      .catch(() => {
        emptyRespHandler();
      });

    if (groupInfo?.data?.uuid) {
      showToast(
        createGroupToast,
        intl.formatMessage({id: 'groupCreatedMsg'}),
        ToastType.success
      );
      groupInfo.data.conversationUuid = groupInfo?.data?.uuid;
      await getSingleGroupConversation(
        groupInfo?.data?.uuid,
        groupInfo?.data?.groupConversation?.groupMembers
      );
    } else {
      emptyRespHandler();
    }
  };

  const emptyRespHandler = () => {
    setComponentState((prev) => {
      return {
        ...prev,
        isLoading: false,
      };
    });
    showToast(
      createGroupToast,
      intl.formatMessage({id: 'apiErrorMsg'}),
      ToastType.error
    );
  };

  const getSingleGroupConversation = async (
    conversationUuid: string,
    groupMembers?: IGroupMember[]
  ) => {
    setComponentState((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });
    const groupResp = await getGroupMemberConversations({
      variables: {
        limit: 1,
        offset: 0,
        loginUserId: userId,
        loginUserUuid: userUuid,
        whereCondition: {
          accountId: {
            _eq: accountId,
          },
          uuid: {
            _eq: conversationUuid,
          },
        },
      },
    }).catch(() => {
      emptyRespHandler();
    });
    if (groupResp?.data) {
      const allConversationList = groupResp?.data?.conversations || [];
      const selectedConversation = allConversationList[0];
      if (allConversationList[0]?.id) {
        setComponentState((prev) => {
          return {
            ...prev,
            isLoading: false,
            selectedConversation: selectedConversation,
            contactData:
              getContactDataAndType(selectedConversation)?.contactData || {},
          };
        });
      }
    } else {
      emptyRespHandler();
    }
  };

  const onConversationActionPerformed = (
    actionCode: string,
    actionData?: any
  ) => {
    switch (actionCode) {
      case CONVERSATION_ACTION_CODES.MESSAGE_READ: {
        setComponentState((prev) => {
          return {
            ...prev,
            selectedConversation: {
              ...prev.selectedConversation,
              unreadMessages: [],
              unreadMsgCount: 0,
            },
          };
        });
        break;
      }

      case COMMON_ACTION_CODES.ITEM_CLICKED: {
        setComponentState((prev) => {
          return {
            ...prev,
            selectedConversation: actionData,
          };
        });
        break;
      }

      case CONVERSATION_ACTION_CODES.START_GROUP_CHAT: {
        setComponentState((prev) => {
          return {
            ...prev,
            openConfirmationModal: false,
            isLoading: true,
          };
        });
        if (isPatientOneTwoOneChatEnabled) {
          setComponentState((prev) => {
            return {
              ...prev,
              openCreateChatDrawer: true,
              isLoading: false,
            };
          });
        } else if (contactUuid) {
          createGroupConversation(contactUuid, '');
        }
        break;
      }

      case GROUP_ACTION_CODES.ADD_MEMBER_IN_DEAFULT_GROUP: {
        if (isPatientOneTwoOneChatEnabled) {
          setComponentState((prev) => {
            return {
              ...prev,
              openConfirmationModal: false,
            };
          });
          getOneOnOneChatData();
        }
        break;
      }

      case CONVERSATION_ACTION_CODES.DRAWER_CLOSE: {
        setComponentState((prev) => {
          return {
            ...prev,
            openCreateChatDrawer: false,
          };
        });
        onActionPerformed?.(COMMON_ACTION_CODES.CLOSE_MODAL);
        break;
      }

      case CONVERSATION_ACTION_CODES.GROUP_CREATE: {
        setComponentState((prev) => {
          return {
            ...prev,
            selectedConversation: actionData,
            openCreateChatDrawer: false,
          };
        });
        break;
      }

      default: {
        onActionPerformed?.(actionCode, actionData);
        break;
      }
    }
  };

  const checkExistingDefaultGroupWithSamePatient = async () => {
    try {
      const conversationData = await getDefaultConversationData(
        accountId,
        contactUuid
      );
      if (
        conversationData?.conversationUuid &&
        conversationData?.groupMembers?.[0]?.groupUserId === userUuid &&
        conversationData?.groupMembers?.[0]?.groupUserType?.code ===
          GROUP_MEMBER_TYPE.USER
      ) {
        await getSingleGroupConversation(conversationData?.conversationUuid);
      } else {
        setComponentState((prev) => {
          return {
            ...prev,
            openConfirmationModal: true,
            isLoading: false,
          };
        });
      }
    } catch (e) {
      setComponentState((prev) => {
        return {
          ...prev,
          isLoading: false,
        };
      });
    }
  };

  const getOneOnOneChatData = async () => {
    setComponentState((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });
   const response = await allowOneTwoOnePatientChat(
      {
        contactData: {
          contactId: contactUuid || '',
          contactName: props.contactData.name,
        },
        userId: userId,
      },
      accountId + ''
    ).catch(() => emptyRespHandler());
    const conversationId = response?.data?.conversationUUID
    await getSingleGroupConversation(conversationId);
  };

  const getConversationData = () => {
    if(isPatientOneTwoOneChatEnabled) {
      if (isWeb()) {
        checkExistingDefaultGroupWithSamePatient();
      } else {
        getOneOnOneChatData()
      }
    } else {
      findExistingGroupWithSamePatient();
    }
  }
  const onMsgSendOnArchiveConversationListenerFn = useCallback(
    (data: {
      conversationData: IConversationData
    }) => {
      const conversationData = data?.conversationData;
      if (conversationData?.id && conversationData?.status === 1) {
        setComponentState((prev) => {
          return {
            ...prev,
            selectedConversation: {
              ...prev.selectedConversation,
              status: 0
            },
          };
        });
      }
    },
    [componentState]
  );

  useEffect(() => {
    if(isInternalChat()){
      findExistingGroupWithSamePatient();
    } else {
      getConversationData();
    }
    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener(
      CUSTOM_MESSAGE_EVENT_CODES.NEW_MESSAGE_CREATED,
      onMsgSendOnArchiveConversationListenerFn
    );

    return () => {
      eventBus.removeEventListener(onMsgSendOnArchiveConversationListenerFn);
    };
  }, [contactUuid]);

  return (
    <View flex={isWeb() ? 0 : 1}>
      <InstantPatientChatView
        selectedConversation={componentState.selectedConversation}
        contactData={props?.contactData || componentState.contactData}
        onActionPerformed={onConversationActionPerformed}
        isDrawerVisible={
          isDrawerVisible && !componentState.openCreateChatDrawer
        }
        isLoading={componentState.isLoading}
        navigation={navigation}
        isInstantChatView={true}
        selectedInboxTypeCode={selectedInboxTypeCode}
        openConfirmationView={componentState.openConfirmationModal}
      />
      {componentState.openCreateChatDrawer && (
        <CreateChannelView
          isDrawerVisible={componentState.openCreateChatDrawer}
          onCreateChannelActionPerformed={onConversationActionPerformed}
          selectedTabCode={CONVERSATION_TAB_CODES.PRIVATE}
          contactData={props?.contactData || componentState.contactData}
        />
      )}
    </View>
  );
};
export default InstantChat;
