import QuillConfig from '../Builder/QuillConfig';
import SearchableComponentFields from './SearchableComponentFields';
import ShareWithPatientFields from './ShareWithPatientFields';

export default [
  {
    type: 'oldtextfield',
    key: 'label',
    label: 'Label',
    input: true,
    validate: {
      required: true,
    },
  },
  {
    type: 'checkbox',
    key: 'validate.required',
    label: 'Is this field required?',
    input: true,
  },
  ...SearchableComponentFields,
  {
    type: 'oldtextfield',
    input: true,
    key: 'placeholder',
    label: 'Placeholder',
    placeholder: 'Placeholder',
    tooltip: 'The placeholder text that will appear when this field is empty.',
  },
  {
    type: 'textarea',
    input: true,
    key: 'description',
    label: 'Description',
    placeholder: 'Description for this field.',
    tooltip: 'The description is text that will appear below the input field.',
    editor: 'quill',
    wysiwyg: QuillConfig,
  },
  ...ShareWithPatientFields,
];
