import React from 'react';
import {Image} from 'native-base';
import {isWeb} from '../../../utils/platformCheckUtils';
import { ICommonSvgProps } from './interfaces';

const DialpadDeleteSvg = (props: ICommonSvgProps) => {
    const { customBgColor } = props;
  return (
    <>
      {isWeb() ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="22"
          fill="none"
          viewBox="0 0 28 25"
        >
          <path
            fill="#2C2C2E"
            d="M24.98 12.511c0 4.903-3.96 8.878-8.847 8.878-4.886 0-8.847-3.975-8.847-8.878 0-4.903 3.96-8.878 8.847-8.878 4.886 0 8.847 3.975 8.847 8.878z"
          ></path>
          <path
            fill= {customBgColor? customBgColor : "#E5E5E5"}
            d="M24.102 24.5c1.295 0 2.268-.327 2.92-.981.652-.645.978-1.61.978-2.89V4.371c0-1.282-.326-2.245-.978-2.89C26.37.826 25.397.5 24.102.5H12.804c-.502 0-.969.044-1.4.133-.432.08-.842.225-1.23.437a4.356 4.356 0 00-1.096.836L1.15 10.1a5.802 5.802 0 00-.661.809 3.07 3.07 0 00-.37.769 2.633 2.633 0 00.158 1.962c.185.39.476.791.873 1.207l7.941 8.075c.344.354.705.645 1.084.875.387.23.797.402 1.228.517.441.115.912.173 1.414.173l11.285.013zM11.68 18.082a.85.85 0 01-.608-.238.856.856 0 01-.238-.61c0-.239.084-.442.251-.61l4.097-4.11-4.097-4.111a.832.832 0 01-.25-.61.811.811 0 01.845-.836.78.78 0 01.608.266l4.096 4.097 4.123-4.11a.792.792 0 01.594-.266c.238 0 .436.084.595.252.167.16.251.354.251.584 0 .23-.084.433-.251.61l-4.11 4.123 4.097 4.097a.908.908 0 01.25.624.832.832 0 01-.25.61.805.805 0 01-.595.238.878.878 0 01-.62-.265l-4.084-4.097-4.07 4.097a.863.863 0 01-.634.265z"
          ></path>
        </svg>
      ) : (
        <Image
          resizeMode="contain"
          size={'30px'}
          source={require('../../../assets/images/DialpadDelete.png')}
          alt="image"
        />
      )}
    </>
  );
};

export default DialpadDeleteSvg;
