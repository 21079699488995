import React from 'react';
import {HStack, Text, VStack, View} from 'native-base';
import {FoldButton} from '../../../../CommonComponents/FoldButton/FoldButton';
import {BUTTON_TYPE} from '../../../../../constants';
import {Colors} from '../../../../../styles';
import {useIntl} from 'react-intl';
import {Modal} from 'antd';

interface IConfirmAssignInReviewCarePlan {
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmAssignInReviewCarePlan = (
  props: IConfirmAssignInReviewCarePlan
) => {
  const {onConfirm, onCancel} = props;
  const intl = useIntl();
  const buttonList = [
    {
      show: true,
      id: 1,
      btnText: 'cancel',
      textColor: Colors.Custom.mainSecondaryBrown,
      variant: BUTTON_TYPE.SECONDARY,
      isTransBtn: false,
      onClick: onCancel,
    },
    {
      show: true,
      id: 2,
      btnText: 'assign',
      isTransBtn: false,
      onClick: onConfirm,
      textColor: Colors.Custom.mainPrimaryPurple,
      variant: BUTTON_TYPE.PRIMARY,
    },
  ];

  return (
    <Modal open closeIcon={null} closable={false} footer={null}>
      <VStack>
        <Text
          mb={1}
          fontSize={'xl'}
          fontWeight={'bold'}
        >{`Assign care plan to patient?`}</Text>
        <Text
          my={1}
          fontSize={'md'}
        >{`The care plan is still in review. Assigning this to the patient will mark the associated task as complete.`}</Text>
        <HStack mt={5} justifyContent={'flex-end'}>
          {buttonList.map((button: any, index: number) => {
            if (button.show) {
              return (
                <FoldButton
                  key={index + button.btnText}
                  nativeProps={{
                    variant: button.variant,
                    onPress: () => {
                      button.onClick();
                    },
                    leftIcon: button.leftIcon,
                    rightIcon: button.rightIcon,
                    isDisabled: button.isDisabled,
                    isLoading: button.isLoading,
                    marginBottom: 1,
                    marginTop: 1,
                    marginLeft: 2,
                  }}
                  customProps={{
                    btnText: intl.formatMessage({id: button.btnText}),
                    withRightBorder: false,
                  }}
                ></FoldButton>
              );
            }
          })}
        </HStack>
      </VStack>
    </Modal>
  );
};

export default ConfirmAssignInReviewCarePlan;
