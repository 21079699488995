import {gql} from '@apollo/client';

export const GET_ALL_REUSABLE_COMPONENTS = gql`
  query GetAllReusableComponents {
    components: getComponents {
      component
      id
      name
      isSearchable
    }
  }
`;

export const GET_BARRIERS = gql`
  query getBarriers(
    $searchTerm: String!
    $limit: Int!
    $offset: Int!
  ) {
    getBarriers(params: {searchTerm: $searchTerm, limit: $limit, offset: $offset}) {
    total
    barriers {
      id
      title
      tenantId
    }
  }
  }
`;

export const CREATE_BARRIER = gql`
  mutation UpsertFeedData($title: String!) {
    addOrUpdateBarriers(params: [{title: $title}]) {
      id
      title
    }
  }
`;

export default {
  GET_ALL_REUSABLE_COMPONENTS,
  GET_BARRIERS,
  CREATE_BARRIER,
};
