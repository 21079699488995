import {TextInput} from "react-native";
import {Colors} from "../../../../styles";
import {ICustomFieldViewRef, INumberInput} from "./interface";
import {useRef} from "react";
import {CUSTOM_ATTRIBUTE_DATA_TYPES} from "./CustomFieldConst";

export const NumberInput = (props: INumberInput) => {
  const customFieldViewRef = useRef<ICustomFieldViewRef>({} as any);
  return (
    <TextInput
      ref={(ref) =>
        (customFieldViewRef.current[props?.refId] = ref as HTMLInputElement | any)
      }
      style={{
        borderWidth: 1,
        borderColor: Colors.Custom.Gray200,
        borderRadius: 6,
        height: 38,
        width: '100%',
        paddingLeft: 10,
      }}
      onChangeText={(text) => {
        if (props?.type === CUSTOM_ATTRIBUTE_DATA_TYPES.TEXT) {
          return props?.onChangeText?.(text);
        }
        const numericValue = text?.replace(/[^0-9.]/g, '');
        customFieldViewRef.current[props?.refId].value = numericValue;
        props?.onChangeText?.(numericValue);
      }}
      value={props?.value}
      placeholder={props?.type === CUSTOM_ATTRIBUTE_DATA_TYPES.TEXT ? "Enter text" : "Enter number"}
      placeholderTextColor={Colors.Custom.Gray300}
    />
  )
}