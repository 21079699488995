export const formatAndCombinePrevAndNewOptions = (
  response: any[]
): {
  dropdownList: any[];
  selectedUsers: {id: string; label: string; value: string}[];
} => {
  const finalData: any[] = [];
  const formmattedSetOfPrevUsers = response?.[0]?.data?.users;
  const prevGroupUUIDs = new Set(
    formmattedSetOfPrevUsers?.map((item: any) => item?.uuid)
  );
  const formmattedSetOfNewUsers = response?.[1]?.data?.users;
  finalData.push(...formmattedSetOfPrevUsers);

  formmattedSetOfNewUsers.filter((group: any) => {
    if (!prevGroupUUIDs.has(group?.uuid)) {
      finalData.push(group);
    }
  });

  return {
    dropdownList: finalData,
    selectedUsers: formmattedSetOfPrevUsers.map((item: any) => ({
      value: item.uuid,
      key: item.uuid,
      label: item.name,
    })),
  };
};