import React from 'react';
import {View, ViewStyle} from 'react-native';
import {svgStyles} from '../../components/common/Svg/SvgStyles';
import {Path, Svg} from 'react-native-svg';
import { Colors } from '../../styles';

const ThreeDotIconSvg = (props: {style?: ViewStyle, color?: string}) => {
  return (
    <View style={[svgStyles.container, props.style]}>
      <Svg width="20" height="5" fill="none" viewBox="0 0 20 5">
        <Path
          stroke={props?.color || Colors.FoldPixel.GRAY400}
          strokeLinecap="round"
          d="M5 2.5a2 2 0 11-4 0 2 2 0 014 0zM12 2.5a2 2 0 11-4 0 2 2 0 014 0zM19 2.5a2 2 0 11-4 0 2 2 0 014 0z"
        ></Path>
      </Svg>
    </View>
  );
};

export default ThreeDotIconSvg;
