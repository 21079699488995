import {IConversationData} from '../../RightSideContainer/TeamInbox/Conversations/interfaces';
import {IContact} from '../CreateChannelView/interface';

export interface IEmailDrawer {
  bulkEmail?: any[];
  onMailCompleteAction: (actionCode: string, rowData?: any) => void;
  contactData: any;
  selectedConversation?: IConversationData;
}

export interface MemberGroupSearch {
  contactId?: string;
  contactUuid?: string;
  email?: string;
  name?: string;
  groupName?: string;
  groupId?: string;
  value: string;
  label: string;
  key: string;
  type: MemberGroupSearchType;
}

export enum MemberGroupSearchType {
  member = 'Member',
  group = 'Group',
}

export enum SubtitleData {
  age = 'age',
  dateOfBirth = 'dateOfBirth',
  gender = 'gender',
  birthSex = 'birthSex',
  memberCountStr ='memberCountStr'
}

export interface GroupDataResponse {
  campaignSegments: CampaignSegment[];
  aggregateCampaignSegment: AggregateCampaignSegment;
}

export interface CampaignSegment {
  id: string;
  name: string;
  description?: string;
  metadata: Metadata;
  externalId?: string;
  type: string;
  createdOn: string;
}

export interface Metadata {
  patientFilter: PatientFilter;
}

export interface PatientFilter {
  tagIds?: number[];
  genderIds?: string[];
  zipcodeIds?: string[];
  contactIds?: any[];
}

export interface AggregateCampaignSegment {
  aggregate: Aggregate;
}

export interface Aggregate {
  count: number;
}

export interface MemberAutoCompleteData {
  label: JSX.Element;
  key: string;
  options: MemberGroupSearch[];
}

export interface SendFormArgs {
  formId: string;
  accountUuid: string;
  memberData: RecipientMemberData[];
  fromEmail: string;
  emailData: any;
  formSourceId: string;
}

export interface RecipientMemberData {
  name: string;
  email: string;
  contactUuid: string;
  patientUuid?: string;
  // taskId is assigned to this subjectId which is required to create form link
  subjectId?: string;
}

export interface RecipientGroupData {
  groupId: string;
  groupName?: string;
}

export interface FormEmailData {
  from: string;
  to: string[];
  subject: string;
  templateCategoryCode?: string;
  template?: any;
  data: Data;
  accountId: string;
  tags: string[];
}

export interface Data {
  patient: Patient;
  form: Form;
}

export interface Global {
  accountLogo: string;
  accountName: string;
  accountPatientAppUrl: string;
  accountAdminUrl: string;
}

export interface Patient {
  firstName: string;
}

export interface Form {
  link: string;
}


export interface ICreateEmailConversationData {
  selectedPatient: IContact
      messageStr: any,
      subject: string
}
