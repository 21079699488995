import {FONT_CONFIG} from '../../styles/FontConst';
export const getHeadingComponentThemeConfig = (
  baseStyle?: Record<string, any>
) => {
  return {
    baseStyle: {
      color: baseStyle?.color || FONT_CONFIG.HEADING.headingColor,
      fontFamily: baseStyle?.fontFamily || FONT_CONFIG.HEADING.fontFamily,
      fontWeight: baseStyle?.fontWeight || FONT_CONFIG.HEADING.fontWeight,
      lineHeight: baseStyle?.lineHeight || FONT_CONFIG.HEADING.lineHeight,
    },
    sizes: {
      '2xl': {
        fontSize: 22,
      },
      xl: {
        fontSize: 20,
      },
      lg: {
        fontSize: 18,
      },
      md: {
        fontSize: 16,
      },
      sm: {
        fontSize: 14,
      },
    },
    defaultProps: {
      size: 'md',
    },
  };
};
