import {ColumnsType} from 'antd/lib/table';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import {Colors} from '../../../../../styles';
import {getMediaTypeFromMime} from '../../MediaLibrary/MediaLibraryUtils';
import {
  getMediaPreviewElementForList,
  getShortenedString,
} from '../../MediaLibrary/MediaLibraryTable/Helper';
import {HStack, Text, VStack, View} from 'native-base';
import {Tooltip} from 'antd';

export const getMediaSelectionListColumn = () => {
  const columns: ColumnsType<any> = [
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="name"
        />
      ),
      dataIndex: 'name',
      key: 'name',
      width: '52%',
      fixed: 'left',
      render: (name: string, record) => {
        const type = getMediaTypeFromMime(record.mime || '');
        const size = record.size;
        const url = record.thumbnail || record.url || '';
        const previewElement = getMediaPreviewElementForList({url, type});
        const sizeInMB = (size / 1000).toFixed(2);
        return (
          <HStack
            style={{
              alignItems: 'center',
            }}
          >
            <View
              style={[
                {
                  height: 50,
                  width: 80,
                  marginRight: 8,
                  borderRadius: 5,
                  overflow: 'hidden',
                },
              ]}
            >
              {previewElement}
            </View>
            <VStack style={{flex: 1}}>
              <Tooltip title={name}>
                <Text
                  // ellipsizeMode='clip'   
                  whiteSpace={'nowrap'}
                  textOverflow={'ellipsis'}         
                  style={{
                    fontWeight: '500',
                    fontSize: 16,
                    lineHeight: 24,
                    color: '#000',
                    overflow: 'hidden',                    
                  }}
                >
                {getShortenedString({str: name, length: 20})}
                </Text>
              </Tooltip>
              <Text
                style={{
                  color: Colors.Custom.Gray500,
                  fontSize: 14,
                  fontWeight: '400',
                  lineHeight: 21,
                }}
              >
                {sizeInMB} MB
              </Text>
            </VStack>
          </HStack>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="mime"
        />
      ),
      dataIndex: 'type',
      key: 'type',
      width: '23%',
      render: (type: string) => {
        return (
          <Text
            style={{
              color: Colors.Custom.Gray500,
              fontSize: 14,
              fontWeight: '500',
              lineHeight: 21,
            }}
          >
            {type}
          </Text>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="lastUpdated"
        />
      ),
      dataIndex: 'lastUpdated',
      width: '25%',
      key: 'lastUpdated',
      // sorter: (a: any, b: any) =>
      //   new Date(a.lastUpdated).getTime() - new Date(b.lastUpdated).getTime(),
      render: (lastUpdated: string) => {
        const date = new Date(lastUpdated).toLocaleDateString('en-US', {
          year: 'numeric',
          day: 'numeric',
          month: 'long',
        });
        return (
          <Text
            style={{
              color: Colors.Custom.Gray500,
              fontSize: 14,
              fontWeight: '500',
              lineHeight: 21,
            }}
          >
            {date}
          </Text>
        );
      },
    },
  ];
  return columns;
};
