import { v4 as uuidV4 } from 'uuid';
import { EventBus } from '../../../../../utils/EventBus';
import {
  IMessagingEventQueueData,
  IMessagingEventQueueState,
  IMessagingEventQueueReducerAction,
  IReducerActionEventBusAddEventListener,
  IReducerActionEventBusRemoveEventListener,
  IReducerActionEventBusRemoveEventListenerAll,
  IReducerActionEventQueueAddEvent,
  IReducerActionEventQueueRemoveEvent,
  IReducerActionSetLoading,
  IReducerActionRemoveEventQueue,
} from './MessagingEventQueueInterfaces';

export const updateMessagingQueueState = (state: IMessagingEventQueueState, action: IMessagingEventQueueReducerAction): IMessagingEventQueueState => {
  switch (action.actionCode) {
    case 'SET_LOADING':
      return setLoading(state, action);
    case 'EVENT_QUEUE/ADD_EVENT':
      return eventQueueAddEvent(state, action);

    case 'EVENT_QUEUE/REMOVE_EVENT':
      return eventQueueRemoveEvent(state, action);

    case 'EVENT_BUS/ADD_EVENT_LISTENER':
      return eventBusAddEventListener(state, action);

    case 'EVENT_BUS/REMOVE_EVENT_LISTENER':
      return eventBusRemoveEventListener(state, action);

    case 'EVENT_BUS/REMOVE_EVENT_LISTENER/ALL':
      return eventBusRemoveEventListenerAll(state, action);

      case 'EVENT_QUEUE/REMOVE':
      return eventQueueRemove(state, action);

    default:
      return state;
  }
};

const setLoading = (state: IMessagingEventQueueState, action: IReducerActionSetLoading): IMessagingEventQueueState => {
  return { ...state, isLoading: action.payload?.isLoading || false };
};

const eventQueueAddEvent = (state: IMessagingEventQueueState, action: IReducerActionEventQueueAddEvent): IMessagingEventQueueState => {
  if (state.isRemoved || !action.payload?.eventCode) {
    return state;
  }
  const eventData: IMessagingEventQueueData = {
    event: {
      id: uuidV4(),
      data: action.payload?.data,
      eventCode: action.payload.eventCode,
    },
  };

  state.eventQueue.push(eventData);
  return state;
};

const eventQueueRemoveEvent = (state: IMessagingEventQueueState, action: IReducerActionEventQueueRemoveEvent): IMessagingEventQueueState => {
  if (state.isRemoved || !action.payload?.eventId) {
    return state;
  }

  const eventIndex = state.eventQueue.findIndex((e) => e.event?.id === action.payload?.eventId);

  if (eventIndex !== -1) {
    state.eventQueue.splice(eventIndex, 1);
  }

  return state;
};

const eventBusAddEventListener = (state: IMessagingEventQueueState, action: IReducerActionEventBusAddEventListener): IMessagingEventQueueState => {
  if (state.isRemoved || !action.payload?.eventCode) {
    return state;
  }
  const eventBus = EventBus.getEventBusInstance();
  eventBus.addEventListener(action.payload.eventCode, action.payload?.eventBusListener, {
    disallowToAddMultipleEventsForModule: true,
    moduleCode: state.moduleCode,
  });

  state.eventListeners.set(action.payload.eventCode, action.payload?.listener);
  return state;
};

const eventBusRemoveEventListener = (state: IMessagingEventQueueState, action: IReducerActionEventBusRemoveEventListener): IMessagingEventQueueState => {
  const eventBusRemove = EventBus.getEventBusInstance();
  eventBusRemove.removeEventListenerByEventName(action.payload?.eventCode, action.payload?.eventBusListener, {
    moduleCode: state.moduleCode,
  });

  state.eventListeners.delete(action.payload?.eventCode);

  return state;
};

const eventBusRemoveEventListenerAll = (state: IMessagingEventQueueState, action: IReducerActionEventBusRemoveEventListenerAll): IMessagingEventQueueState => {
  const eventBus = EventBus.getEventBusInstance();
  state.eventListeners.clear();
  state.eventQueue = [];

  eventBus.removeAllEventListenerByModuleCode(state.moduleCode);

  return state;
};

const eventQueueRemove = (state: IMessagingEventQueueState, action: IReducerActionRemoveEventQueue): IMessagingEventQueueState => {
  const eventBus = EventBus.getEventBusInstance();
  state.eventListeners.clear();
  state.eventQueue = [];
  state.isRemoved = true;

  eventBus.removeAllEventListenerByModuleCode(state.moduleCode);

  return state;
};
