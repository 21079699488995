import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  zipCodeLabel: {
    marginLeft: 4,
  },
  zipSelectContainer: {
    width: '100%',
  },
});
