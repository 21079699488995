import React from "react";
import {Colors} from "../../../styles";
import {ICommonSvgProps} from "./interfaces";

export const PhoneCallSvg =(props: ICommonSvgProps) => {
  const {
    isEnabled,
    defaultColor,
    customStrokeColor,
    customBgColor,
    borderColor,
  } = props;
  const strokeColor = isEnabled
  ? '#667085' :  "#D0D5DD"
  const bgColor = 'transparent'
  // isEnabled
  //   ? customBgColor
  //     ? customBgColor
  //     : Colors.primary['500']
  //   : customBgColor
  //   ? customBgColor
  //   : Colors.primary['200'];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{width: 'inherit', height: 'inherit'}}
      fill="none"
      viewBox="0 0 36 36"
    >
      <rect
        width="35"
        height="35"
        x="0.5"
        y="0.5"
        fill={defaultColor ? defaultColor : bgColor}
        rx="17.5"
      ></rect>
      <path
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M20.542 12.167a4.167 4.167 0 013.291 3.291m-3.291-6.625a7.5 7.5 0 016.625 6.617m-.834 6.65v2.5a1.667 1.667 0 01-1.816 1.667 16.492 16.492 0 01-7.192-2.559 16.25 16.25 0 01-5-5 16.491 16.491 0 01-2.558-7.225 1.667 1.667 0 011.658-1.816h2.5a1.667 1.667 0 011.667 1.433c.105.8.3 1.585.583 2.342A1.667 1.667 0 0115.8 15.2l-1.058 1.058a13.334 13.334 0 005 5L20.8 20.2a1.667 1.667 0 011.758-.375c.756.282 1.542.478 2.342.583a1.667 1.667 0 011.433 1.692z"
      ></path>
    </svg>
  );
}

export default PhoneCallSvg;
