import React from 'react';
import {Path, Svg} from 'react-native-svg';

function BodyTemperatureIconSvg(params?: {
  strokeColor?: string;
  width?: string;
  height?: string;
}) {
  return (
    <Svg
      width={params?.width || '16'}
      height={params?.height || '16'}
      fill="none"
      viewBox="0 0 24 24"
    >
      <Path
        d="M12 14C10.6193 14 9.5 15.1193 9.5 16.5C9.5 17.8807 10.6193 19 12 19C13.3807 19 14.5 17.8807 14.5 16.5C14.5 15.1193 13.3807 14 12 14ZM12 14V5M17.5 16.5C17.5 19.5376 15.0376 22 12 22C8.96243 22 6.5 19.5376 6.5 16.5C6.5 14.7636 7.30465 13.2152 8.56141 12.2072C8.82505 11.9957 9 11.6857 9 11.3477V5C9 3.34315 10.3431 2 12 2C13.6569 2 15 3.34315 15 5V11.3477C15 11.6857 15.1749 11.9957 15.4386 12.2072C16.6954 13.2152 17.5 14.7636 17.5 16.5Z"
        strokeLinecap="round"
        stroke={params?.strokeColor || '#8C5AE2'}        
      />
    </Svg>
  );
}

export default React.memo(BodyTemperatureIconSvg);
