import {Drawer, Table} from 'antd';
import {Spinner, Text, View, VStack} from 'native-base';
import {useEffect, useState} from 'react';
import {getObservations} from '../../../../../../../services/CommonService/AidBoxService';
import {Colors} from '../../../../../../../styles';
import {IDiagnosticModalProps} from '../interface';
import {
  getFormattedObservationList,
  getObservationIds,
} from './DiagnosticReportViewUtils';
import {DiagnosticData} from './interfaces';
import {StyleSheet} from 'react-native';
const columns = [
  {
    title: 'Name',
    dataIndex: 'displayCode',
    key: 'displayCode',
    width: '70%',
    render: (code: string) => {
      return (
        <View>
          <Text>{code}</Text>
        </View>
      );
    },
  },
  {
    title: 'Value',
    dataIndex: 'displayValue',
    key: 'displayValue',
    width: '30%',
    render: (displayValue: string) => {
      return (
        <View>
          <Text>{displayValue}</Text>
        </View>
      );
    },
  },
];

const DiagnosticModal = (props: IDiagnosticModalProps) => {
  const ids = getObservationIds(props.data?.result || []);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<DiagnosticData[]>([]);

  useEffect(() => {
    setLoading(true);
    getObservations(ids)
      .then((response) => {
        setLoading(false);
        const data = getFormattedObservationList(response.data);
        setData(data);
      })
      .catch((error) => {
        setLoading(false);

      });
  }, []);

  return (
    <Drawer
      visible={props.isOpen}
      onClose={() => props.onClose()}
      placement="right"
      width={'33vw'}
      closable
      destroyOnClose
      title={
        <Text fontSize={24} fontWeight={400}>
          {props.data?.text}
        </Text>
      }
    >
      <View>
        <View>
          <VStack bgColor={'white'}>
            {props.data && !loading ? (
              <>
                <View
                  style={styles.table}
                >
                  <Table
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                    rowKey={(row: DiagnosticData) => row.id}
                  />
                </View>
              </>
            ) : (
              <View margin={50}>
                <Spinner />
              </View>
            )}
          </VStack>
        </View>
      </View>
    </Drawer>
  );
};

const styles = StyleSheet.create({
  table: {
    borderColor: Colors.Custom.BorderColor,
    borderWidth: 0.5,
    overflow: 'hidden',
  }
})

export default DiagnosticModal;
