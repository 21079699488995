import React from 'react';
import {isWeb} from '../../../utils/platformCheckUtils';
import {Image} from 'native-base';

const SubscriptionIcon = () => {
  return (
    <>
      {isWeb() ? (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.0656 0H5.93437C2.65781 0 0 2.65781 0 5.93437V18.0656C0 21.3422 2.65781 24 5.93437 24H18.0656C21.3422 24 24 21.3422 24 18.0656V5.93437C24 2.65781 21.3422 0 18.0656 0ZM9.76406 5.01562C11.4094 5.01562 12.7453 6.35156 12.7453 7.99688C12.7453 9.64219 11.4094 10.9781 9.76406 10.9781C8.11875 10.9781 6.78281 9.64219 6.78281 7.99688C6.78281 6.35156 8.11406 5.01562 9.76406 5.01562ZM15.7266 17.6578C15.7266 18.3891 15.1313 18.9844 14.4 18.9844H5.12813C4.39688 18.9844 3.80156 18.3891 3.80156 17.6578C3.80156 14.6203 6.2625 12.1547 9.30469 12.1547H10.2281C13.2656 12.15 15.7266 14.6156 15.7266 17.6578ZM20.4281 11.1234H18.4594V13.0922H16.6594V11.1234H14.6906V9.31406H16.6594V7.34531H18.4594V9.31406H20.4281V11.1234Z"
            fill="#98A2B3"
          />
        </svg>
      ) : (
        <Image
          resizeMode="contain"
          size={'24px'}
          source={require('../../../assets/images/Contact/subscription.png')}
          alt="image"
        />
      )}
    </>
  );
};

export default SubscriptionIcon;
