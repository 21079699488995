import React from 'react';
import {ICommonSvgProps} from '../interfaces';
import {isWeb} from '../../../../utils/platformCheckUtils';
import {Image} from 'native-base';

const RobotSvg = (props: ICommonSvgProps) => {
  const height = props.height ?? '14';
  const width = props.width ?? '14';
  const stroke = props.customStrokeColor ?? '#667085';
  return isWeb() ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${width}`}
      height={`${height}`}
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M7.00584 3.92546V5.41667M5.13463 7.93273V9.29152M7.67948 11.3067H6.32069M8.86554 7.93273V9.29152M13.3334 8.23213V10.5121M0.666748 8.23213V10.5121M4.05796 5.41091H9.9422C10.9311 5.41091 11.7328 6.2126 11.7328 7.20152V11.5427C11.7328 12.5317 10.9311 13.3333 9.9422 13.3333H4.05796C3.06904 13.3333 2.26735 12.5317 2.26735 11.5427V7.20152C2.26735 6.2126 3.06904 5.41091 4.05796 5.41091ZM8.62948 2.29607C8.62948 3.19596 7.89997 3.92546 7.00008 3.92546C6.10019 3.92546 5.37069 3.19596 5.37069 2.29607C5.37069 1.39618 6.10019 0.666672 7.00008 0.666672C7.89997 0.666672 8.62948 1.39618 8.62948 2.29607Z"
        stroke={`${stroke}`}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ) : (
    <Image
      size={props.size}
      resizeMode="contain"
      source={require('../../../../assets/images/CallTrailPng/robot.png')}
      alt='image'
    />
  );
};

export default RobotSvg;
