import {
  CaptureTransaction,
  TRANSACTION_NAMES,
} from '../../utils/CaptureTransaction';
import {DB_NAME, DB_VERSION, STORES} from './constants';
import {DBKey, IStoreName, IData} from './interfaces';
const captureTransactionInst = CaptureTransaction.getInstance();

export class IndexedDBService {
  private static instance: IndexedDBService;
  private db: IDBDatabase | null = null;
  private dbName = DB_NAME;
  private dbVersion = DB_VERSION;

  constructor() {}

  public static getInstance() {
    if (IndexedDBService.instance) {
      return IndexedDBService.instance;
    }
    IndexedDBService.instance = new IndexedDBService();
    return IndexedDBService.instance;
  }

  public async connectToDB() {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(this.dbName, this.dbVersion);
      request.onupgradeneeded = (ev) => {
        const db = request.result;
        const storeNames = db.objectStoreNames;
        const version = ev.oldVersion;
        switch (version) {
          case 0:
            STORES.forEach((store) => {
              if (!storeNames.contains(store.name)) {
                db.createObjectStore(store.name, {
                  keyPath: store.keyPath,
                  autoIncrement: store.autoIncrement,
                });
              }
            });
            break;
          case 1:
            STORES.forEach((store) => {
              if (!storeNames.contains(store.name)) {
                db.createObjectStore(store.name, {
                  keyPath: store.keyPath,
                  autoIncrement: store.autoIncrement,
                });
              }
            });
            break;
          case 2:
            STORES.forEach((store) => {
              if (!storeNames.contains(store.name)) {
                db.createObjectStore(store.name, {
                  keyPath: store.keyPath,
                  autoIncrement: store.autoIncrement,
                });
              }
            });
        }
      };

      request.onsuccess = async () => {
        this.db = request.result;
        captureTransactionInst.captureTransaction(
          TRANSACTION_NAMES.INDEXED_DB_INIT_SUCCESS,
          `${this.dbName}_${this.dbVersion}`
        );
        await this.clearDB();
        resolve('Database connected');
      };

      request.onerror = (error) => {
        const target = error.target as IDBOpenDBRequest;
        captureTransactionInst.captureTransaction(
          TRANSACTION_NAMES.INDEXED_DB_INIT_FAIL,
          `${this.dbName}_${this.dbVersion}`,
          {
            ...(target?.error || {}),
          }
        );
        console.log('Error connecting to database', error);
        reject('Error connecting to database');
      };
    });
  }

  public async addDataByKey<T>(
    storeName: IStoreName,
    key: DBKey,
    data: T
  ): Promise<DBKey | null> {
    return new Promise(async (resolve, reject) => {
      if (!this.db) {
        reject('Database not connected');
        return;
      }
      if (!key) {
        reject('Key is required');
        return;
      }
      const transaction = this.db.transaction(storeName, 'readwrite');
      const store = transaction.objectStore(storeName);
      const request = store.put({
        id: this.getKey(key),
        data,
      });

      request.onsuccess = () => {
        resolve(key);
      };
      request.onerror = (error) => {
        console.log('Error adding data', error);
        reject(null);
      };
    });
  }

  public async getDataByKey<T>(
    storeName: IStoreName,
    key: DBKey
  ): Promise<T | null> {
    return new Promise(async (resolve, reject) => {
      if (!this.db) {
        reject(null);
        return;
      }
      if (!key) {
        reject('Key is required');
        return;
      }
      const transaction = this.db.transaction(storeName, 'readonly');
      const store = transaction.objectStore(storeName);
      const request = store.get(this.getKey(key));
      request.onsuccess = () => {
        const result: IData<T> = request.result;
        resolve(result?.data || null);
      };

      request.onerror = () => {
        reject(null);
      };
    });
  }

  public async deleteDataByKey(
    storeName: IStoreName,
    key: DBKey
  ): Promise<DBKey | null> {
    return new Promise(async (resolve, reject) => {
      if (!this.db) {
        reject('Database not connected');
        return;
      }
      if (!key) {
        reject('Key is required');
        return;
      }
      const transaction = this.db.transaction(storeName, 'readwrite');
      const store = transaction.objectStore(storeName);
      const request = store.delete(this.getKey(key));
      request.onsuccess = () => {
        resolve(key);
      };

      request.onerror = () => {
        reject(null);
      };
    });
  }

  public async deleteDB() {
    if (!this.db) return;
    this.db.close();
    indexedDB.deleteDatabase(this.dbName);
  }

  public async clearDB() {
    for (const store of STORES) {
      try {
        await this.clearStore(store.name);
      } catch (error) {
        console.error(`Error clearing store ${store.name}:`, error);
      }
    }
  }

  private async clearStore(storeName: IStoreName) {
    return new Promise(async (resolve, reject) => {
      if (!this.db) {
        reject('Database not connected');
        return;
      }
      const transaction = this.db.transaction(storeName, 'readwrite');
      const store = transaction.objectStore(storeName);
      const request = store.clear();
      request.onsuccess = () => {
        resolve(storeName);
      };
      request.onerror = (error) => {
        console.log('Error clearing store', error);
        reject(null);
      };
    });
  }

  private getKey(key: DBKey) {
    return key.toString();
  }
}
