import {Menu, Popover} from 'antd';
import {IconButton, Input, View} from 'native-base';
import React from 'react';
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import {Colors} from '../../../../../styles';
import {
  getMenuItems,
  getSelectedMergeTag,
  insertAtIndex,
} from '../../SMSTemplates/SMSTemplatesUtils';
import {ISubjectInputProps} from '../interfaces';

const SubjectInput = (props: ISubjectInputProps) => {
  const cursorPositionRef = React.useRef<number>(0);

  const insertMergeTags = (mergeTagString: string, index: number) => {
    const body = props.value || '';
    const insertedBody = insertAtIndex(body, index, mergeTagString);
    if (props.onChangeText) {
      props.onChangeText(insertedBody);
    }
  };
  const onMenuItemSelected = (info: any) => {
    const mergeTagString = getSelectedMergeTag(info.keyPath);
    insertMergeTags(mergeTagString, cursorPositionRef.current);
  };
  const popoverContent = () => {
    return (
      <Menu
        items={getMenuItems(props.mergeTags || {})}
        selectable={false}
        onClick={(info) => {
          onMenuItemSelected(info);
        }}
      />
    );
  };
  return (
    <View
      style={{
        flexDirection: 'row',
        borderColor: props.isError ? '#e11d48' : '#e4e7ec',
        borderWidth: 1,
        borderRadius: 4,
        minWidth: 300,
      }}
    >
      <Input
        {...props}
        borderWidth={0}
        borderColor={'transparent'}
        flexGrow={1}
        onSelectionChange={(e) => {
          cursorPositionRef.current = e.nativeEvent.selection.start;
        }}
      />
      <Popover trigger={'focus'} content={popoverContent}>
        <IconButton
          _hover={{
            backgroundColor: 'transparent',
          }}
          icon={
            <MaterialCommunityIcon
              name="code-json"
              size={16}
              color={Colors.muted[700]}
            />
          }
        />
      </Popover>
    </View>
  );
};

export default SubjectInput;
