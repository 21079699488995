import { useLazyQuery } from '@apollo/client';
import { Popover,Tooltip,notification } from 'antd';
import {
  HStack,
  Pressable,
  Spinner,
  Text,
  VStack,
  View,
  useMediaQuery,
  useToast,
} from 'native-base';
import React,{ useCallback,useContext,useEffect,useMemo,useRef,useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CONVERSATION_CONST,
  CONVERSATION_STATUS,
  GROUP_MEMBER_TYPE,
  HEADER_ACTION_ITEM,
  PERSON_TYPES,
  THIRTEEN_INCH_WIDTH,
  eleven_inch_width,
  getInActiveContactError,
} from '../../../../../constants';
import {
  COMMON_ACTION_CODES,
  CONVERSATION_ACTION_CODES,
  GROUP_ACTION_CODES,
} from '../../../../../constants/ActionConst';
import { MLOV_CATEGORY } from '../../../../../constants/MlovConst';
import { CONVERSATION_LOCAL_EVENT_CODES,SUPPORTED_EVENT_CODE,WEB_SOCKET_EVENT_CODE } from '../../../../../constants/WebSocketConst';
import { CommonDataContext } from '../../../../../context/CommonDataContext';
import { LeadQueries } from '../../../../../services';
import ConversationsQueries from '../../../../../services/Conversations/ConversationsQueries';
import { Colors } from '../../../../../styles';
import { EventBus } from '../../../../../utils/EventBus';
import {
  getAllowedUserPracticeLocationUuids,
  getPrimaryGenderCode,
  getUserId,
  getUserUUID,
  isActiveContact,
  isChannelEmail,
  isChannelTwillioSms,
  notListedPronounId,
  showInfoOnMessageHeader
} from '../../../../../utils/commonUtils';
import { getMlovListFromCategory } from '../../../../../utils/mlovUtils';
import { useContainerDimensions } from '../../../../CustomHooks/ContainerDimensionHook';
import { withMiniContactViewHOC } from '../../../../MiniContactViewHOC';
import { PatientInfoBanner } from '../../../../PatientInfoBanner/PatientInfoBanner';
import { getViewInEHRLink,redirectToNewTab } from '../../../../SideCar/SidecarUtils';
import { ConversationAttachments } from '../../../../common/ChatUI/MessageBox/ConversationAttachments';
import AssignActionSvg from '../../../../common/Svg/AssignActionSvg';
import MenuHorizontalIcon from '../../../../common/Svg/MenuHorizontalIcon';
import ArchiveActionSvg from '../../../../common/Svg/PersonActionSvgIcons/ArchiveActionSvg';
import SearchActionSvg from '../../../../common/Svg/PersonActionSvgIcons/SearchActionSvg';
import UnArchiveActionSvg from '../../../../common/Svg/PersonActionSvgIcons/UnArchiveActionSvg';
import SideCarBackSvg from '../../../../common/Svg/SideCarBackSvg';
import ConversationActionView from '../ConversationActionView/ConversationActionView';
import { getGroupName } from '../ConversationChannelNew/ConversationSidebarUtils';
import CreateAllConversationPopup from '../ConversationChannelNew/CreateAllConversationPopup';
import { CONVERSATION_TAB_CODES } from '../ConversationConst';
import { assignStatusValue } from '../ConversationContainer/ConversationConst';
import { IContact,IConversationData,IMessagingWindowHeaderProps } from '../interfaces';
import MemberListByContactValue from './MemberListByContactValue';
import {
  canShowArchive,
  canShowAssignment,
  getAvatarNameAndType,
  getButtonActionObject,
  getEventCodeFromParentCode,
  getGroupMemberList,
  getMessagingHeaderInfoInJSON,
  getPatientAgeAndGenderJson,
  getPatientAgeAndGenderObject,
  getPatientAgeAndGenderStr,
  isBroadCastGroup,
  isGroupConversation,
  isGroupMember,
  isGroupMembersAvailable,
  isInboxMember,
  isInternalChat,
  isPrivateGroup,
  isUserGroupsGroup,
  messageDataKeysConverter
} from './MessagingUtils';
import { cssProperties, styles } from './MessagingWindowStyles';
import { IMessageTopHeaderInfo } from './interface';
import { getAvatarFullName } from '../ConversationContainer/ConversationList/ConversationListItemUtils';
import {localBroadcastEvent} from '../../../../../utils/CustomEventHandler';
import { getEhrConfig, getEHRUrl } from '../../../../../utils/capabilityUtils';
import { getCurrentPatientDeceasedData } from '../../../../../services/CommonService/AidBoxService';
import { IContentAttributesAssigneeData} from '../ConversationChannelNew/Interfaces';
import {ILocalAssigneeChangedEventData} from '../../../../BodyContainer/interface';
import { TestIdentifiers, testID } from '../../../../../testUtils';
import { useIntl } from 'react-intl';
import { USER_ACCESS_PERMISSION } from '../../../UserAccess/UserAccessPermission';
import { MAIN_MENU_CODES } from '../../../../SideMenuBar/SideBarConst';
import { isAccountConfigEnabled } from '../../../../../utils/configUtils';
import { CONFIG_CODES } from '../../../../../constants/AccountConfigConst';
import { ToastType, showToast } from '../../../../../utils/commonViewUtils';
import {
  CaptureTransaction,
  TRANSACTION_NAMES,
} from '../../../../../utils/CaptureTransaction';

const MessagingWindowHeaderComponent = (props: IMessagingWindowHeaderProps) => {
  const ehrConfig = getEhrConfig('', '');
  const {
    contactData,
    conversation,
    onActionPerformed,
    isDisplayActions,
    selectedInboxTypeCode,
    showInfoIconInHeader,
    isInstantChatView,
    isSelectChatOn,
    parentCode,
    messagingWindowHeaderLoading,
    moduleCode,
    pcpUserData
  } = props;
  const ehrURL = getEHRUrl(contactData?.contactPracticeLocations?.[0]?.accountLocation?.uuid, '');
  const {width, resetDimension} = useContainerDimensions(props?.headerContainerRef);
  const isEmailConversation = isChannelEmail(props.conversation?.conversationInbox?.channelType || '')
  const emailSubject = props.conversation?.additionalAttributes?.mail_subject || ''
  const userUuid = getUserUUID();
  const userId = getUserId();
  const headerContainerRef: any = useRef();
  const [button, setButton] = useState({} as any);
  const navigate = useNavigate();
  const genderCode = getPrimaryGenderCode(contactData);
  const groupName = getGroupName(conversation as IConversationData);
  const mlovData = useContext(CommonDataContext);
  const isPatientSidecarContext = mlovData.sidecarContext?.isPatient;
  const isSideCarContext = mlovData.sidecarContext?.isSidecar;
  const showInfoOnHeaderData = showInfoOnMessageHeader();
  const toast = useToast();
  const intl = useIntl()
  const pronounList = getMlovListFromCategory(
    mlovData.MLOV,
    MLOV_CATEGORY.PRONOUN
  );
  const notListedPronounData = notListedPronounId(pronounList);
  const avatarNameAndType = getAvatarNameAndType(userUuid, conversation)
  const isCommunicationLocationHandlingEnabled = isAccountConfigEnabled(
    CONFIG_CODES.ENABLE_COMMUNICATION_LOCATION_HANDLING
  );
  const allowedUserPracticeLocationUuids = getAllowedUserPracticeLocationUuids(
    USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
    MAIN_MENU_CODES.INBOX
  );
  const captureTransactionInst = CaptureTransaction.getInstance();

  const [is13InchScreen, is11InchScreen] = useMediaQuery([
    {maxWidth: THIRTEEN_INCH_WIDTH},
    {maxWidth: eleven_inch_width},
  ]);
  const isNavigateToOmniView = is11InchScreen || is13InchScreen

  const handleNavigateToOmniView = () => {
    const contactId = contactData?.id;
    const type = contactData?.contactType?.contactType?.code || '';
    if (type == PERSON_TYPES.CUSTOMER) {
      props?.navigateOrOpenContactIdDrawer?.(contactId?.toString(), {
        state: conversation?.conversationInbox,
      });
    } else if (type == PERSON_TYPES.LEAD) {
      navigate(`/members/prospect/${contactId}`);
    } else {
      navigate(`/members/supporting-memeber/${contactId}`);
    }
  }

  // Separate state variables
  const [loadingArchive, setLoadingArchive] = useState(false);
  const [conversationAttachmentDrawer, setConversationAttachmentDrawer] = useState(false);
  const [assignUserListPopup, setAssignUserListPopup] = useState(false);
  const [moreActionPopup, setMoreActionPopup] = useState(false);
  const [isNoContactFound, setIsNoContactFound] = useState(false);
  const [assignUserName, setAssignUserName] = useState('');
  const [isSeachMsgContainerVisible, setIsSeachMsgContainerVisible] = useState(props.isSeachMsgContainerVisible);
  const [localContactData, setLocalContactData] = useState<IContact>(props.contactData || {} as IContact);
  const [contactDeceasedDate, setContactDeceasedDate] = useState('');
  const [assignedUserData, setAssignedUserData] = useState<any>({});
  const [isDetailsContainerVisible, setIsDetailsContainerVisible] = useState(props.isDetailsContainerVisible);
  const [accessToViewPatientProfile, setAccessToViewPatientProfile] = useState(isCommunicationLocationHandlingEnabled ? false : true);

  useEffect(() => {
    setIsSeachMsgContainerVisible(props.isSeachMsgContainerVisible)
  }, [props?.isSeachMsgContainerVisible])

  useEffect(() => {
    setIsDetailsContainerVisible(props?.isDetailsContainerVisible)
  }, [props?.isDetailsContainerVisible])

  const channelType = conversation?.conversationInbox?.channelType || '';

  const findPatient = (members: any) => {
    const patient = members.filter((member: any) => {
      return member.groupUserType.code === GROUP_MEMBER_TYPE.PATIENT;
    });
    return patient[0];
  };

  const handleResolve = async (status: any) => {
    let statusStr = '';
    localBroadcastEvent(CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_STATUS_CHANGED_ACTION_BUS, {
      conversation: props.conversation,
      status,
    });
    if (status === assignStatusValue.ARCHIVED) {
      statusStr = 'ARCHIVED';
      localBroadcastEvent(CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_BUS_ARCHIVED, {
        conversation: conversation,
      });
    } else if (status === assignStatusValue.OPEN) {
      statusStr = 'OPEN';
      localBroadcastEvent(CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_BUS_UN_ARCHIVED, {
        conversation: conversation,
      });
    }
    if (isInstantChatView && status === assignStatusValue.ARCHIVED && props?.onActionPerformed) {
      props?.onActionPerformed(CONVERSATION_ACTION_CODES.DRAWER_CLOSE, conversation)
    }
    captureTransactionInst.initiateTransaction({
      name: TRANSACTION_NAMES.CONVERSATION_STATUS_CHANGE,
      identifier: props.conversation?.id || 0,
    });
    captureTransactionInst.finishTransaction(
      TRANSACTION_NAMES.CONVERSATION_STATUS_CHANGE,
      props.conversation?.id || 0,
      {
        status: statusStr,
      }
    );
    setLoadingArchive(false);
    setAssignUserListPopup(false);
  };

  const [getAssignUserData] = useLazyQuery(
    ConversationsQueries.GetConversationUserById,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
      variables: {
        conversationId: conversation?.id,
        loginUserId: userId,
      },
    }
  );

  const [getContactForMessageHeader] = useLazyQuery(LeadQueries.GetContactForMessageHeader, {
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    variables: {
      id: contactData.id,
    },
  });


  const onContactClicked = (contactId: any) => {
    const type = localContactData.contactType
      ? localContactData.contactType.contactType.value
      : '';
    let currentStatus = true;
    if (
      isGroupConversation(conversation || ({} as any)) &&
      isUserGroupsGroup(conversation || ({} as any))
    ) {
      currentStatus = true;
    } else {
      currentStatus = isActiveContact(contactData);
      if (!currentStatus && !props.defaultActiveStatus) {
        const message = getInActiveContactError(contactData);
        notification.error({
          message,
        });
        return;
      }
    }
    if (type == 'Customer') {
      props?.navigateOrOpenContactIdDrawer?.(contactId, {
        state: conversation?.conversationInbox,
      });

    } else if (type == 'Lead') {
      navigate(`/members/prospect/${contactId}`);
    } else {
      navigate(`/members/supporting-memeber/${contactId}`);
    }
  };

  const setHeaderTitle = (conversation: any) => {
    return conversation?.groupConversation?.name || CONVERSATION_CONST.GROUP_CHAT;
  };

  const isShowForSmsOrEmail =
    isChannelEmail(channelType) || isChannelTwillioSms(channelType)
      ? true
      : false;

  const onCallBackAction = useCallback((code: any) => {
    if (COMMON_ACTION_CODES.NO_CONTACT_FOUND == code) {
      setIsNoContactFound(true);
    }
  }, []);

  const substringForEmailOrSms: any = (
    <MemberListByContactValue
      contactData={contactData}
      channelType={channelType}
      conversationUuid={conversation?.uuid || ''}
      navigate={navigate}
      onCallBackAction={onCallBackAction}
    />
  );

  const messagingHeaderInfo = useMemo(() => {
    if (showInfoOnHeaderData?.SHOW_PCP) {
      const headerData: IMessageTopHeaderInfo = getMessagingHeaderInfoInJSON(
        props?.pcpUserData || '',
        showInfoOnHeaderData,
        contactData,
        userUuid,
        ehrConfig,
        selectedInboxTypeCode,
        conversation,
        isInstantChatView,
        {
          deceasedDate: contactDeceasedDate,
        }
      );
      return {
        ...headerData,
        subtitleData: JSON.stringify(headerData.subtitleData)
      };
    } else {
      const groupMembers = conversation?.groupConversation?.groupMembers || [];
      const groupMemberData = getGroupMemberList(groupMembers);
      const memberList = groupMemberData.groupMembersInfoList;
      let contactTypeStr, contactTypeData;
      if (conversation) {
        contactTypeStr = getPatientAgeAndGenderStr(
          conversation,
          ehrConfig,
          pcpUserData?.name || '',
          undefined,
          {
            deceasedDate: contactDeceasedDate
          },
        );
        contactTypeData = getPatientAgeAndGenderObject(
          conversation,
          isInstantChatView,
          pcpUserData?.name || ''
        );
      }
      const choosenName =
        !isBroadCastGroup(conversation as IConversationData) &&
        showInfoOnHeaderData?.SHOW_CHOSEN_NAME &&
        contactData?.person?.chosenName?.length
          ? ' (' + contactData?.person?.chosenName + ')'
          : '';

      if (isPrivateGroup(conversation as any)) {
        if (conversation?.groupConversation?.name) {
          return {
            headerString: setHeaderTitle(conversation) + choosenName,
            subtitleData: contactTypeStr,
            memberSubtitleData: contactTypeData,
          };
        } else {
          return {
            headerString: getAvatarFullName(conversation as any, true, '', contactData),
            subtitleData: contactTypeStr,
            memberSubtitleData: contactTypeData,
          };
        }
      } else if (isInternalChat(conversation as any)) {
        let headerStringName = '';
        if (memberList.length === 2 && !conversation?.groupConversation?.name) {
          memberList.forEach((userData: any) => {
            if (userData?.uuid !== userUuid) {
              headerStringName = userData?.name;
            }
          });
        }
        return {
          headerString:
            headerStringName || conversation?.groupConversation?.name || avatarNameAndType?.name,
          subtitleData: `${groupMemberData?.groupMembersInfoList?.length} ${
            groupMemberData.groupMembersInfoList.length > 1 ? 'Members' : 'Member'
          }`,
          memberSubtitleData: contactTypeData,
        };
      } else if (isChannelTwillioSms(channelType)) {
        return {
          headerString:
            conversation?.groupConversation?.name &&
            conversation?.groupConversation?.name + choosenName,
          subtitleData: contactTypeStr,
        };
      } else if (isChannelEmail(selectedInboxTypeCode || '')) {
        return {
          headerString:
            (conversation?.groupConversation?.name || CONVERSATION_CONST.GROUP_CHAT) + choosenName,
          subtitleData: contactTypeStr,
        };
      } else {
        return {
          headerString:
            (conversation?.groupConversation?.name || CONVERSATION_CONST.GROUP_CHAT) + choosenName,
          subtitleData: `${groupMemberData?.groupMembersInfoList?.length} ${
            groupMemberData.groupMembersInfoList.length > 1 ? 'Members' : 'Member'
          }`,
        };
      }
    }
  }, [
    showInfoOnHeaderData,
    props.pcpUserData,
    pcpUserData,
    contactDeceasedDate,
    contactData,
    userUuid,
    ehrConfig,
    selectedInboxTypeCode,
    conversation,
    isInstantChatView,
    channelType,
    avatarNameAndType
  ]);

  useEffect(() => {
    const buttonStatus = getButtonActionObject(conversation?.status);
    setButton(buttonStatus);
  }, [conversation?.status]);



  useEffect(() => {
    if (conversation?.assignedUser?.id) {
      setAssignUserName(conversation?.assignedUser?.name || '');
      setAssignedUserData(conversation?.assignedUser);
    }
    const eventBus = EventBus.getEventBusInstance();
    eventBus.removeEventListener(onMsgAssigneeChangedListenerFn);
    eventBus.removeEventListener(onLocalConversationAssignedOrUnassignedEvent, {
      moduleCode: moduleCode,
    });
    eventBus.removeEventListener(onLocalConversationStatusChangedEvent, {
      moduleCode: moduleCode,
    });
    eventBus.removeEventListener(onConversationStatusChangedEvent);
    eventBus.addEventListener(
      SUPPORTED_EVENT_CODE.ASSIGNEE_CHANGED,
      onMsgAssigneeChangedListenerFn
    );

    eventBus.addEventListener(
      CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_ASSIGNEE_CHANGED,
      onLocalConversationAssignedOrUnassignedEvent, {
        moduleCode: moduleCode,
      });

    eventBus.addEventListener(
      CONVERSATION_LOCAL_EVENT_CODES.CONVERSATION_STATUS_CHANGED_EVENT,
      onLocalConversationStatusChangedEvent, {
        moduleCode: moduleCode,
      });

    eventBus.addEventListener(
      WEB_SOCKET_EVENT_CODE.CONVERSATION_RESOLVED,
      onConversationStatusChangedEvent
    );

    eventBus.addEventListener(
      WEB_SOCKET_EVENT_CODE.CONVERSATION_OPENED,
      onConversationStatusChangedEvent
    );

    eventBus.addEventListener(
      WEB_SOCKET_EVENT_CODE.ASSIGNEE_CHANGED,
      onConversationStatusChangedEvent
    );

    eventBus.addEventListener(
      WEB_SOCKET_EVENT_CODE.MESSAGE_CREATED,
      onConversationStatusChangedEvent
    );

    if (!conversation?.groupConversation?.id && !showInfoIconInHeader) {
      getContactDataObject();
    }
    return () => {
      eventBus.removeEventListener(onMsgAssigneeChangedListenerFn);
      eventBus.removeEventListener(onLocalConversationAssignedOrUnassignedEvent, {
        moduleCode: moduleCode,
      });
      eventBus.removeEventListener(onLocalConversationStatusChangedEvent, {
        moduleCode: moduleCode,
      });
      eventBus.removeEventListener(onConversationStatusChangedEvent);
    };
  }, [conversation?.uuid]);

  const fetchPatientDeceasedDate = useCallback(async () => {
    if (ehrConfig?.isAthena) {
      const deceasedDate = await getCurrentPatientDeceasedData(contactData);
      setContactDeceasedDate(deceasedDate);
    } else {
      setContactDeceasedDate('');
    }
  }, [contactData.patient?.id, ehrConfig?.isAthena]);

  useEffect(() => {
    fetchPatientDeceasedDate();
    setLocalContactData(contactData);
  }, [contactData?.uuid, contactData.updatedAt]);

  const getAssigneeName = useCallback(async () => {
    const assigneeData = await getAssignUserData();
    if (assigneeData) {
      const assignUserName =
        assigneeData?.data?.conversations?.[0]?.assignedUser?.name || '';
      const assignedUser = assigneeData?.data?.conversations?.[0]?.assignedUser;
      if (conversation) {
        conversation.assignedUser = {...assignedUser};
        conversation.assigneeId = assignedUser?.id;
      }
      setAssignUserName(assignUserName);
      setAssignedUserData(assigneeData);
    }
  }, [
    conversation?.assignedUser?.id,
    conversation?.assigneeId,
  ]);

  const getContactDataObject = useCallback(async () => {
    const contactData = await getContactForMessageHeader();
    if (contactData?.data?.contact) {
      setLocalContactData(contactData?.data?.contact);
    }
  }, []);

  const onMsgAssigneeChangedListenerFn = useCallback(
    (data: any) => {
      data = Object.keys(data?.data || {}).length > 0
        ? messageDataKeysConverter(data.data)
        : data;
      if ((data?.conversationUuid === conversation?.uuid || data?.conversation_uuid === conversation?.uuid)) {
        const assignUserName = data?.assigneeName || data?.meta?.assignee?.name || data?.additionalData?.assigneeName || '';
        setAssignUserName(assignUserName);
        setAssignedUserData(data?.contentAttributes?.assigneeToUserData);
      }
    },
    [conversation]
  );

  const onLocalConversationAssignedOrUnassignedEvent = useCallback(
    (eventData: ILocalAssigneeChangedEventData) => {
      const contentAttributesAssigneeData: IContentAttributesAssigneeData = eventData?.messageData?.contentAttributes;
      if (conversation?.uuid === eventData?.messageData?.conversation?.uuid) {
        if (contentAttributesAssigneeData && Object.keys(contentAttributesAssigneeData)?.length) {
          const assignUserName = contentAttributesAssigneeData?.assigneeToUserData?.name || '';
          const assignedUser = contentAttributesAssigneeData?.assigneeToUserData;
          if (conversation) {
            conversation.assignedUser = {...assignedUser}
            conversation.assigneeId = assignedUser?.id
          }
          setAssignUserName(assignUserName);
          setAssignedUserData(contentAttributesAssigneeData?.assigneeToUserData);
        }
        if (isInstantChatView && eventData?.messageData?.conversation?.status !== conversation?.status) {
          const buttonStatus = getButtonActionObject(eventData?.messageData?.conversation?.status);
          setButton(buttonStatus);
        }
      }
    },
    [conversation, isInstantChatView]
  );

  const onConversationStatusChangedEvent = useCallback((eventData: any) => {
    if (
      eventData?.data?.conversation_uuid === conversation?.uuid ||
      eventData?.data?.conversationUuid === conversation?.uuid ||
      eventData?.conversation_uuid === conversation?.uuid
    ) {
      const buttonStatus = getButtonActionObject(
        eventData?.data?.conversation?.status
      );
      setButton(buttonStatus);
      if (
        eventData?.data?.conversation?.status === CONVERSATION_STATUS.RESOLVED
      ) {
        const unAssignedUserData = {
          id: -1,
          name: '',
          uuid: '',
          email: '',
        };
        setAssignUserName('');
        setAssignedUserData(unAssignedUserData);
      }
    }
  }, [conversation]);

  const onLocalConversationStatusChangedEvent = useCallback(
    (eventData: ILocalAssigneeChangedEventData) => {
      if (conversation?.uuid === eventData?.messageData?.conversation?.uuid) {
        if (conversation) {
          conversation.status = eventData?.messageData?.conversation?.status as any;
        }
        if (isInstantChatView) {
          const buttonStatus = getButtonActionObject(eventData?.messageData?.conversation?.status);
          setButton(buttonStatus);
        }
      }
    },
    [conversation, isInstantChatView]
  );

  const canViewPatientProfile = () => {
    let isPatientOfUserLocation = false;
    if (contactData?.contactPracticeLocations?.[0]?.practiceLocationUuid) {
      isPatientOfUserLocation = allowedUserPracticeLocationUuids?.includes(
        contactData?.contactPracticeLocations?.[0]?.practiceLocationUuid
      );
    } else if (
      contactData?.contactType?.contactType?.code !== PERSON_TYPES.CUSTOMER
    ) {
      isPatientOfUserLocation = true;
    }
    setAccessToViewPatientProfile(isPatientOfUserLocation)
  };

  useEffect(() => {
    if (isCommunicationLocationHandlingEnabled) {
      canViewPatientProfile()
    }
  }, [conversation?.uuid, contactData?.contactType?.contactType?.code, contactData?.contactPracticeLocations?.[0]?.practiceLocationUuid])
  useEffect(() => {
    resetDimension();
  }, [props.isSideDetailVisible]);

  const handleAssignVisibleChange = (visible: any) => {
    setAssignUserListPopup(visible);
  };

  const handleMoreActionVisibleChange = (visible: any) => {
    setMoreActionPopup(visible);
  };

  const handleResolveWrapper = useCallback(() => {
    handleResolve(button.nextStatusCode);
  }, [handleResolve, button.nextStatusCode]);

  const onSelectUser = useCallback(() => {
    setAssignUserListPopup(false);
  }, [setAssignUserListPopup]);

  const setAssignUserListPopupWrapper = useCallback(() => {
    setAssignUserListPopup(true);
  }, [setAssignUserListPopup]);

  const onSearchMessage = useCallback(() => {
    if (props.onActionPerformed) {
      props.onActionPerformed(CONVERSATION_ACTION_CODES.SEARCH_MESSAGE, {
        isSeachMsgContainerVisible: isSeachMsgContainerVisible,
      });
      setIsSeachMsgContainerVisible(!isSeachMsgContainerVisible);
    }
  }, [isSeachMsgContainerVisible, setIsSeachMsgContainerVisible]);

  const onCreateGroupPopupActionPerformed = useCallback(
    (actionCode: string, actionData?: any) => {
      if (actionCode === CONVERSATION_ACTION_CODES.COPY) {
        props?.onActionPerformed?.(CONVERSATION_ACTION_CODES.COPY, actionData);
      }
      if (actionCode === GROUP_ACTION_CODES.GROUP_HEADER_CLICKED) {
        if (onActionPerformed && typeof onActionPerformed === 'function') {
          onActionPerformed(GROUP_ACTION_CODES.GROUP_HEADER_CLICKED, {});
        }
      } else if (actionCode === CONVERSATION_ACTION_CODES.FILES) {
        setConversationAttachmentDrawer(true);
      } else if (HEADER_ACTION_ITEM?.includes(actionCode)) {
        onActionPerformed?.(actionCode, actionData);
      } else if (actionCode === CONVERSATION_ACTION_CODES.SELECT_CHAT) {
        if (props?.handleSelectChat) {
          props?.handleSelectChat();
        }
      } else if (actionCode === CONVERSATION_ACTION_CODES.SEARCH_MESSAGE) {
        if (props.onActionPerformed) {
          props.onActionPerformed(CONVERSATION_ACTION_CODES.SEARCH_MESSAGE, {
            isSeachMsgContainerVisible: !isSeachMsgContainerVisible,
          });
          setIsSeachMsgContainerVisible(!isSeachMsgContainerVisible);
        }
      } else if (
        actionCode === CONVERSATION_ACTION_CODES.ARCHIVE ||
        actionCode === CONVERSATION_ACTION_CODES.UNARCHIVE
      ) {
        handleResolve(button.nextStatusCode);
      } else {
        const eventBus = EventBus.getEventBusInstance();
        const eventCode = getEventCodeFromParentCode(parentCode);
        eventBus.broadcastEvent(eventCode, {
          actionCode,
          actionData,
        });
      }
      setMoreActionPopup(false);
    },
    [
      props,
      onActionPerformed,
      setConversationAttachmentDrawer,
      isSeachMsgContainerVisible,
      setIsSeachMsgContainerVisible,
      handleResolve,
      button.nextStatusCode,
      parentCode,
      setMoreActionPopup,
    ]
  );

  const onMoreBtnClick = useCallback(() => {
    setMoreActionPopup(true);
  }, [setMoreActionPopup]);

  const onHeaderInfoClick = useCallback(() => {
    if(!conversation) return;
    if (accessToViewPatientProfile) {
      if (showInfoIconInHeader) {
        if (isNavigateToOmniView) {
          handleNavigateToOmniView();
        } else {
          captureOpenContactDetailViewTransaction()
          setIsDetailsContainerVisible(!isDetailsContainerVisible)
          if (props.onActionPerformed) {
            props.onActionPerformed(
              CONVERSATION_ACTION_CODES.IS_DETAILS_CONTAINER_VISIBLE,
              {
                isDetailsContainerVisible:
                  isDetailsContainerVisible,
              }
            );
          }
        }
        return;
      }
      let currentStatus = true;
      if (
        isGroupConversation(conversation || ({} as any)) &&
        isUserGroupsGroup(conversation || ({} as any))
      ) {
        currentStatus = true;
      } else {
        currentStatus = isActiveContact(contactData);
        if (!currentStatus && !props.defaultActiveStatus) {
          const message = getInActiveContactError(contactData);
          notification.error({
            message,
          });
          return;
        }
      }

      if (conversation.groupConversation === null) {
        onActionPerformed &&
          onActionPerformed(
            GROUP_ACTION_CODES.GROUP_HEADER_CLICKED_REDIRECT,
            conversation
          );
      } else if (conversation.groupConversation) {
        const patient = findPatient(
          conversation.groupConversation?.groupMembers
        );
        const contact = patient.contact;
        onActionPerformed &&
          !isInstantChatView &&
          onActionPerformed(
            GROUP_ACTION_CODES.GROUP_HEADER_CLICKED_REDIRECT,
            contact
          );
      }
    } else {
      showToast(
        toast,
        intl.formatMessage({
          id: 'patientProfileAccessDenyMsg',
        }),
        ToastType.info
      );
    }
  }, [
    conversation?.id,
    showInfoIconInHeader,
    isNavigateToOmniView,
    isDetailsContainerVisible,
    localContactData?.id,
    props.defaultActiveStatus,
    isInstantChatView,
    handleNavigateToOmniView,
    props.onActionPerformed,
    onActionPerformed,
    findPatient,
    isGroupConversation,
    isUserGroupsGroup,
    isActiveContact,
    getInActiveContactError
  ]);

  const onContactInfoClick = useCallback(() => {
    if (accessToViewPatientProfile) {
      if (showInfoIconInHeader) {
        setIsDetailsContainerVisible(!isDetailsContainerVisible);
        if (props.onActionPerformed) {
          if (isSideCarContext) {
            const ehrPatientId = contactData?.patient?.patientId || '';
            const openInEhrUrl = getViewInEHRLink(ehrPatientId, ehrURL);
            if (openInEhrUrl) {
              window.open(openInEhrUrl, '_blank', 'noopener,noreferrer');
            } else {
              if (contactData?.contactType?.contactType?.code == 'CUSTOMER') {
                redirectToNewTab(`/members/patient/${contactData?.id}`);
              } else {
                redirectToNewTab(`/members/prospect/${contactData?.id}`);
              }
            }
          } else {
            captureOpenContactDetailViewTransaction();
            props.onActionPerformed(
              CONVERSATION_ACTION_CODES.IS_DETAILS_CONTAINER_VISIBLE,
              {
                isDetailsContainerVisible: isDetailsContainerVisible,
              }
            );
          }
        }
        return;
      }
      !isInstantChatView && onContactClicked(contactData.id);
    } else {
      showToast(
        toast,
        intl.formatMessage({
          id: 'patientProfileAccessDenyMsg',
        }),
        ToastType.info
      );
    }
  }, [
    showInfoIconInHeader,
    isDetailsContainerVisible,
    props.onActionPerformed,
    isSideCarContext,
    localContactData?.patient?.patientId,
    localContactData?.id,
    ehrURL,
    isInstantChatView,
    onContactClicked
  ]);

  const handleSms = () => {
    if (props.onActionPerformed) {
      props.onActionPerformed(COMMON_ACTION_CODES.SMS, contactData);
    }
  };

  const captureOpenContactDetailViewTransaction = () => {
    captureTransactionInst.initiateTransaction({
      name: TRANSACTION_NAMES.CONVERSATION_CONTACT_DETAIL_CLICK,
      identifier: props.conversation?.id || 0,
    });
    captureTransactionInst.finishTransaction(
      TRANSACTION_NAMES.CONVERSATION_CONTACT_DETAIL_CLICK,
      props.conversation?.id || 0,
      {
        contactId: props.conversation?.contactId,
        selectedInboxTypeCode,
        isDetailsContainerVisible: !isDetailsContainerVisible,
      }
    );
  };

  const getHeaderInfoElem = () => {
    const showRightArrow = !(
      isInstantChatView ||
      (isSideCarContext &&
        isInternalChat(conversation || ({} as IConversationData)))
    );
    const selectedPronoun = pronounList?.find((pronoun) => {
      return pronoun?.id === localContactData?.person?.pronounId
    })
    const isShowPronounName =
      showInfoOnHeaderData?.SHOW_PRONOUN_NAME &&
      notListedPronounData?.id !== selectedPronoun?.id;
    const pronounName =
      isShowPronounName && localContactData?.name ? selectedPronoun?.value : '';
    if (conversation?.groupConversation?.id) {
      return (
        <Pressable
          onPress={onHeaderInfoClick}
          {...testID('HeaderInfoClick')}
        >
          <HStack
            ml={props.showNavigationBack ? 2 : 5}
            justifyContent={'center'}
            alignItems={'center'}
            space="2"
          >
            <PatientInfoBanner
              name={
                conversation?.groupConversation?.name
                  ? conversation?.groupConversation?.name
                  : avatarNameAndType?.name
                    ? avatarNameAndType?.name
                    : groupName
                      ? groupName
                      : localContactData?.name || CONVERSATION_CONST.GROUP_CHAT
              }
              showRightArrow={showRightArrow}
              showPronounName={pronounName}
              bannerType="headerBanner"
              isSelected={false}
              headerString={messagingHeaderInfo.headerString}
              groupType={
                conversation?.groupConversation?.groupType?.code as any
              }
              genderCode={genderCode as any}
              userId={localContactData?.id}
              userType={
                avatarNameAndType?.userType
                  ? avatarNameAndType?.userType
                  : GROUP_MEMBER_TYPE.CONTACT
              }
              subtitleString={messagingHeaderInfo.subtitleData}
              memberSubtitleData={messagingHeaderInfo.memberSubtitleData}
              shouldShowSubtitleHighlighter={false}
              contactData={localContactData}
              singleConversationData={conversation}
              getConversationTypeImgSvg={false}
              headerContainerWidth={width}
              onActionPerformed={onActionPerformed}
              deceasedDate={contactDeceasedDate}
              messagingWindowHeaderLoading={messagingWindowHeaderLoading || false}
            />
          </HStack>
        </Pressable>
      );
    } else if (localContactData?.name) {
      const contactNameWithChosenName =
        localContactData.name +
        (localContactData?.person?.chosenName?.length
          ? ' (' + localContactData?.person?.chosenName + ')'
          : '');
      let contactTypeStr;
      if (conversation) {
        contactTypeStr = showInfoOnHeaderData?.SHOW_PCP
          ? JSON.stringify(getPatientAgeAndGenderJson(
              conversation,
              ehrConfig,
              pcpUserData?.name || '',
              isInstantChatView,
              {
                deceasedDate: contactDeceasedDate,
              }
            ))
          : getPatientAgeAndGenderStr(
              conversation,
              ehrConfig,
              pcpUserData?.name || '',
              undefined,
              {
                deceasedDate: contactDeceasedDate,
              }
            );
      }
      return (
        <Pressable
          disabled={
            isCommunicationLocationHandlingEnabled
              ? messagingWindowHeaderLoading
              : false
          }
          onPress={onContactInfoClick}
          {...testID('HeaderInfoClick')}
        >
          <HStack
            ml={props.showNavigationBack ? 2 : 5}
            justifyContent={'flex-start'}
            alignItems={'center'}
            space="2"
          >
            <PatientInfoBanner
              isLetterAvatarShow={true}
              name={groupName ? groupName : localContactData.name}
              showRightArrow={!isInstantChatView}
              showPronounName={pronounName}
              bannerType="headerBanner"
              isSelected={false}
              headerString={contactNameWithChosenName}
              memberSubtitleData={messagingHeaderInfo.memberSubtitleData}
              onActionPerformed={onActionPerformed}
              groupType={
                conversation?.groupConversation?.groupType?.code as any
              }
              genderCode={genderCode as any}
              userId={localContactData?.id}
              userType={GROUP_MEMBER_TYPE.CONTACT}
              subtitleString={
                !isNoContactFound && isShowForSmsOrEmail
                  ? substringForEmailOrSms
                  : contactTypeStr
              }
              shouldShowSubtitleHighlighter={false}
              contactData={localContactData}
              singleConversationData={conversation}
              headerContainerWidth={width}
              deceasedDate={contactDeceasedDate}
              messagingWindowHeaderLoading={messagingWindowHeaderLoading || false}
            />
          </HStack>
        </Pressable>
      );
    }
    return <></>;
  };

  const onConversationAttachmentActionPerformed = (
    actionCode: string  ) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.CANCEL:
        setConversationAttachmentDrawer(false);
        break;
      default:
        break;
    }
  };

  const isShowFinallyAssignee = () => {
    let isShowAssigneeButton = false;
    if (conversation) {
      const canShowAssigneeOnType =  canShowAssignment(conversation || {});
      const isGroupMemberPresent = isGroupMembersAvailable(conversation, userUuid);
      if (canShowAssigneeOnType && isGroupMemberPresent) {
        isShowAssigneeButton = true;
      }
    }
    return isShowAssigneeButton;
  }

  const groupMembers = conversation?.groupConversation?.groupMembers || [];
  let isLoggedInUserGroupMember = false;
  if (conversation?.groupConversation?.id) {
    isLoggedInUserGroupMember = isGroupMember(userUuid, groupMembers);
  } else if (conversation?.conversationInbox?.id) {
    isLoggedInUserGroupMember = isInboxMember(
      userUuid,
      conversation?.conversationInbox?.inboxMembers || []
    );
  }

  return (
    <>
      {emailSubject && (
        <View style={styles.emailSubjectContainer}>
          <Text style={styles.emailSubjectText} {...testID('Subject')}>
            {`Subject: ${emailSubject}`}
          </Text>
        </View>
      )}
      <HStack
        ref={headerContainerRef}
        style={[
          styles.topBar,
          {
            position: 'relative',
            zIndex: 100000,
            justifyContent: props.showNavigationBack
              ? undefined
              : 'space-between',
          },
        ]}
      >
        {props.showNavigationBack && <Pressable onPress={props.navigationBackPressed} marginLeft={2}><SideCarBackSvg /></Pressable>}
        {getHeaderInfoElem()}
        <HStack style={styles.headerActionContainer}>
          {isDisplayActions && (
            <HStack
              flex={1}
              justifyContent={'flex-end'}
              alignItems={'center'}
              space="4"
              pr="4"
            >
              {isSelectChatOn && props.copyMsgView}
              {!isPatientSidecarContext &&
                !isSelectChatOn &&
                !(isDetailsContainerVisible || isSeachMsgContainerVisible) &&
                props?.selectChatView}
              {isSelectChatOn && props?.createNoteView}
              {isSelectChatOn && props.assignMessageTypeView}
              {isSelectChatOn && props?.createTaskView}
              {isSelectChatOn && props?.cancelView}
              {!isPatientSidecarContext &&
                !isSelectChatOn &&
                !(isDetailsContainerVisible || isSeachMsgContainerVisible) &&
                canShowArchive(selectedInboxTypeCode ?? '', conversation) &&
                (loadingArchive ? (
                  <VStack
                    alignItems={'center'}
                    {...testID(TestIdentifiers.lazyLoading)}
                  >
                    <Spinner size="sm" />
                  </VStack>
                ) : (
                  <HStack>
                    <Tooltip title={button.title}>
                      <Pressable
                        onPress={handleResolveWrapper}
                        {...testID('ArchivedBtnClick')}
                      >
                        <VStack alignItems={'center'}>
                          {button.currentStatusCode === 1 ? (
                            <UnArchiveActionSvg
                              customStrokeColor={Colors.FoldPixel.GRAY300}
                              width={20}
                              height={20}
                            />
                          ) : (
                            <ArchiveActionSvg
                              customStrokeColor={Colors.FoldPixel.GRAY300}
                              width={20}
                              height={20}
                            />
                          )}
                        </VStack>
                      </Pressable>
                    </Tooltip>
                  </HStack>
                ))}

              {!isPatientSidecarContext &&
                !isSelectChatOn &&
                !(isDetailsContainerVisible || isSeachMsgContainerVisible) &&
                isShowFinallyAssignee() && (
                  <>
                    <HStack>
                      <Popover
                        overlayInnerStyle={cssProperties.overlayInnerStyle}
                        overlayStyle={cssProperties.overlayStyle}
                        overlay={cssProperties.overlay}
                        style={styles.assigneePopover}
                        placement={'bottomLeft'}
                        trigger="click"
                        visible={assignUserListPopup}
                        onVisibleChange={handleAssignVisibleChange}
                        content={
                          <View width={300} {...testID('AssignPopover')}>
                            <ConversationActionView
                              conversation={conversation}
                              selectedInboxTypeCode={selectedInboxTypeCode}
                              style={styles.conversationActionViewStyle}
                              assignedUserData={assignedUserData}
                              onSelectUser={onSelectUser}
                              onActionPerformed={onActionPerformed}
                            />
                          </View>
                        }
                      >
                        <Tooltip
                          title={
                            assignUserName?.length
                              ? assignUserName
                              : 'Unassigned'
                          }
                        >
                          <Pressable
                            onPress={setAssignUserListPopupWrapper}
                            {...testID('AssignBtnClick')}
                          >
                            <VStack alignItems={'center'}>
                              <AssignActionSvg
                                customStrokeColor={
                                  assignUserName &&
                                  assignUserName.length
                                    ? '#12B76A'
                                    : Colors.FoldPixel.GRAY300
                                }
                                width={20}
                                height={20}
                              />
                            </VStack>
                          </Pressable>
                        </Tooltip>
                      </Popover>
                    </HStack>
                  </>
                )}
              {!isPatientSidecarContext &&
                !isSelectChatOn &&
                !(isDetailsContainerVisible || isSeachMsgContainerVisible) &&
                !isInstantChatView &&
                !isEmailConversation && (
                  <Tooltip title={'Search'}>
                    <Pressable onPress={onSearchMessage}>
                      <VStack alignItems={'center'}>
                        <SearchActionSvg
                          customStrokeColor={Colors.FoldPixel.GRAY300}
                          width={20}
                          height={20}
                        />
                      </VStack>
                    </Pressable>
                  </Tooltip>
                )}
              {!isSelectChatOn && (
                <Popover
                  overlayInnerStyle={cssProperties.overlayInnerStyle}
                  overlayStyle={cssProperties.overlayStyle}
                  overlay={cssProperties.overlay}
                  style={styles.assigneePopover}
                  placement={'bottomLeft'}
                  trigger="click"
                  visible={moreActionPopup}
                  onVisibleChange={handleMoreActionVisibleChange}
                  content={
                    <View width={210}>
                      <CreateAllConversationPopup
                        contactData={localContactData}
                        isDetailsContainerVisible={isDetailsContainerVisible}
                        selectedTabCode={CONVERSATION_TAB_CODES.PATIENTS}
                        openCount={0}
                        conversation={conversation}
                        isCompactView={
                          isDetailsContainerVisible ||
                          isSeachMsgContainerVisible
                        }
                        selectedInboxTypeCode={selectedInboxTypeCode}
                        getAssigneeName={getAssigneeName}
                        assignUserName={assignUserName}
                        isShowFinallyAssignee={isShowFinallyAssignee()}
                        isSingleContactPresentInSMSOrEmailInbox={
                          isShowForSmsOrEmail && isNoContactFound
                        }
                        isInstantChatView={isInstantChatView}
                        assignedUserData={assignedUserData}
                        onCreateGroupPopupActionPerformed={onCreateGroupPopupActionPerformed}
                        accessToViewPatientProfile={accessToViewPatientProfile}
                        onActionPerformed={onActionPerformed}
                      />
                    </View>
                  }
                >
                  <Pressable
                    onPress={onMoreBtnClick}
                    {...testID('MoreBtnClick')}
                  >
                    <Tooltip title={'More'}>
                      <VStack alignItems={'center'}>
                        <MenuHorizontalIcon width={24} height={24} />
                      </VStack>
                    </Tooltip>
                  </Pressable>
                </Popover>
              )}
            </HStack>
          )}
        </HStack>
      </HStack>

      <View width={'100%'} style={styles.dividerView} />

      {conversationAttachmentDrawer && (
        <ConversationAttachments
          conversationId={conversation?.id || -1}
          conversationDisplayId={conversation?.displayId || -1}
          isDrawerVisible={conversationAttachmentDrawer}
          onConversationAttachmentActionPerformed={
            onConversationAttachmentActionPerformed
          }
          openInDrawer={true}
        />
      )}
    </>
  );
};

export const MessagingWindowHeader = withMiniContactViewHOC(MessagingWindowHeaderComponent)


