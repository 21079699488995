import { capitalize } from 'lodash';
import { Text } from 'native-base';
import { FHIR_RESOURCE } from '../../../../../constants/FhirConstant';
import { CARE_PLAN_STATUS_CODES, CARE_TEAM_MEMBER_TYPE, FREQUENCY_CODES } from '../../../../../constants/MlovConst';
import { WORKFLOW_INPUT_FIELD_KEYS } from '../../../../../constants/WorkflowNodeConst';
import { FlowType } from '../../../../../context/WorkflowContext';
import { getDateStrFromFormat, getDisplayDateFormatted } from '../../../../../utils/DateUtils';
import { getLabelForKey } from '../../../../../utils/capabilityUtils';
import { NodeCategory } from '../../../../common/CircleIconView/CircleIconView';
import { formatTemplate } from '../../UserInputFields/FormOutputDisplayTemplate/FormOutputDisplayTemplateHelper';
import { IReminderData } from '../../Workflow/AddOrUpdateWorkflow/IWorkflow';
import { IUserInputField } from './FlowNodeInterface';
import { INPUT_FIELD_KEY, INPUT_FIELD_TYPE } from './NodeInputField';
import { IVirtualPhoneNumber } from './interface';
import { VitalUnit } from '../../../../../utils/VitalUtils';
import { DATE_FORMATS } from '../../../../../constants/StringConst';
import { processDataRecurrence } from '../../../../common/Audit/AuditViewHelper';
import { getSummaryString } from '../../../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/hooks/useReccuringEvent/helpers';
import { COMPARISON_OPERATOR } from '../../../constants';
import { CUSTOM_ATTRIBUTE_FILTERS } from '../../../Contacts/CustomField/CustomFieldConst';
import { assignToTypeCodes } from '../../../../common/Tasks/TaskPool/OptedOutJourneyTaskField';

export const TRIGGER_START_OF_CARE_PLAN_UNIT = 'Start';
export const DURATION_END_OF_CARE_PLAN_UNIT = 'End';
export const TRIGGER_SUFFIX_TEXT = 'after journey is started';
export const WORKFLOW_CARE_JOURNEY_TRIGGER = 'CareJourneyPatientEnroll';
export const TARGET_PREFIX_TEXT = 'Aim for ';

export const getDisplayLabel = (userInputField: IUserInputField) => {
  if (userInputField?.value && userInputField?.value.vitalName) {
    return userInputField?.value.vitalName;
  }
  if (userInputField?.fieldType === INPUT_FIELD_TYPE.FORM_OUTCOME_MATCH) {
    return '';
  }
  return userInputField.displayName;
};

const getRichTextDisplayValueComponent = (props: {
  value: any;
  onClick: any;
}) => {
  // const displayValue = <>
  //   {props.value && (
  //     <Text numberOfLines={2} fontWeight={300}
  //     style={ { color: Colors.Custom.UnReadTost }}
  //     >{props.value}</Text>
  //   )}
  //   {!props.value && <Text>-</Text>}
  // </>;

  return (
    <>
      <Text numberOfLines={2} fontWeight={300}>
        {props.value ? props.value.replace(/(<([^>]+)>)/gi, '') : '-'}
      </Text>
      {/* {
        props.onClick &&
        <Pressable onPress={props.onClick}>
          {displayValue}
        </Pressable>
      }
      {
        !props.onClick &&
        displayValue
      } */}
    </>
  );
};

export const getDisplayValueComponent = (
  userInputField: IUserInputField,
  nodeMetaData: any,
  onClick?: any
): string | JSX.Element => {
  if (userInputField) {
    switch (userInputField.fieldType) {
      case INPUT_FIELD_TYPE.RICH_TEXT_EDITOR:
        return getRichTextDisplayValueComponent({
          value: userInputField.value,
          onClick,
        });
      default:
        return getDisplayValue(userInputField, nodeMetaData);
    }
  }

  return <Text></Text>;
};

export const getLabelNameOfUserInputField = (userInputField:any, locationId: string | undefined)=>{
    switch (userInputField?.fieldType) {
      case INPUT_FIELD_TYPE.RESOURCE_ABILITIES_SEARCH:
        return (
          getLabelForKey(
            FHIR_RESOURCE.PATIENT,
            userInputField?.category || '',
            '',
            locationId
          ) ||
          userInputField?.displayName ||
          ''
        );
        break;
      default:
        return (userInputField?.displayName || '')
        break;
    }
}

export const getFormOutputDisplayTemplate = (userInputFieldList: IUserInputField[]) => {
  let userInputField:any;
  (userInputFieldList || []).some((field)=>{
    if(field?.fieldType === INPUT_FIELD_TYPE.FORM_OUTPUT_DISPLAY_TEMPLATE){
      userInputField  = field;
      return true;
    }
  })
  if(!userInputField){
    return  ''
  }
  const templateValue = formatTemplate(userInputField?.template, userInputField?.templateVarList, userInputFieldList);
  return templateValue || ''
}

export const getNodeLevelGraphQl = (
  userInputFieldList: IUserInputField[],
  nodeType: string,
  nodeMetaData: any,
  locationId: string | undefined
) => {
  let displayValue: string | boolean = '';
  userInputFieldList.forEach((userInputField: IUserInputField) => {
    if(userInputField.fieldType === INPUT_FIELD_TYPE.SHOW_FILTER_TOTAL_COUNT){
      return ''
    }
    const currentInputDisplayValue = getDisplayValue(
      userInputField,
      nodeMetaData
    );

    const keyName: string = (userInputField.key || '').replace(/\./g, '-');
    displayValue =
      currentInputDisplayValue != '-'
        ? displayValue +
        (displayValue ? ' and ' : '') +
        `${getLabelNameOfUserInputField(userInputField, locationId)} ` +
        `{{${keyName}}}`
        : displayValue;
  });
  displayValue = displayValue.trim();
  displayValue = displayValue || false;
  return displayValue;
};

export const getDisplayValue = (
  userInputField: IUserInputField,
  nodeMetaData: any
) => {
  let displayValue = userInputField.displayValue;

  if(userInputField?.selectedComparisonOperator?.value == COMPARISON_OPERATOR.ANY && INPUT_FIELD_TYPE.MULTIPLE_EMPLOYER_SEARCH == userInputField.fieldType){
    displayValue = 'in any emlpoyer'
    return displayValue
  }

  if (!userInputField.displayValue) {
    switch (userInputField.fieldType) {
      case INPUT_FIELD_TYPE.SEND_EMAIL_TO:
        if (userInputField?.value?.displayName && userInputField?.value?.displayName?.trim()?.length) {
          displayValue = userInputField.value.displayName;
        } else {
          displayValue =
          userInputField?.value?.member?.label
            ? userInputField?.value?.member?.label :  (userInputField?.value?.label ? userInputField?.value?.label
            : '');
        }
        break;
      case INPUT_FIELD_TYPE.TAG_RESOURCE_SEARCH:
        displayValue =
          userInputField?.value?.value !== undefined
            ? userInputField.value?.value
            : '';
        break;
      case INPUT_FIELD_TYPE.RESOURCE_ABILITIES_SEARCH:
          displayValue = userInputField?.value?.display;
          break;
      case INPUT_FIELD_TYPE.MLOV_SEARCH:
        displayValue = userInputField?.value?.value;
        break;
      case INPUT_FIELD_TYPE.MLOV_MULTIPLE_SEARCH:
        const displayValueList = (userInputField?.value||[])?.map((data:any)=>{return data?.value });
        displayValue = displayValueList.join(' or ')
        break;
      case INPUT_FIELD_TYPE.TIME_RANGE:
        displayValue =
          (userInputField?.value?.unit?.code == 'Custom' ? (userInputField?.value?.dateString ? getDisplayDateFormatted(userInputField?.value?.dateString) : '') : (userInputField?.value?.count || '')) +
          (userInputField?.value?.unit && userInputField?.value?.unit?.code != 'Custom'
            ? ' ' + userInputField?.value?.unit.displayValue
            : '');
        if (
          userInputField?.toValue &&
          userInputField?.selectedComparisonOperator?.value === 'BTW'
        ) {
          displayValue =
            displayValue +
            ' To ' +
            (userInputField?.toValue?.unit?.code == 'Custom' ? (userInputField?.toValue?.dateString ? getDisplayDateFormatted(userInputField?.toValue?.dateString) : '') : (userInputField?.toValue?.count || '')) +
            (userInputField?.toValue?.unit && userInputField?.toValue?.unit?.code != 'Custom'
              ? ' ' + userInputField?.toValue?.unit.displayValue
              : '');
        }
        break;
        case INPUT_FIELD_TYPE.APPOINTMENT_SCHEDULE_DATE_RANGE:
          displayValue = ''
          if (
            userInputField?.selectedComparisonOperator?.value && userInputField?.selectedComparisonOperator?.value !== 'SAME_DATE'
          ) {
            displayValue = (userInputField?.value?.count || '') + ' ' + (userInputField?.value?.unit?.displayValue || '')
          }
          break;
      case INPUT_FIELD_TYPE.TAGGER_TYPE_SEARCH:
        displayValue = userInputField?.value?.value;
        break;
      case INPUT_FIELD_TYPE.TAG_SEARCH:
        displayValue = userInputField?.value?.title || '-';
        break;
      case INPUT_FIELD_TYPE.TRIGGER:
      case INPUT_FIELD_TYPE.DURATION:
        displayValue = getDurationDisplay(userInputField, nodeMetaData);
        if (userInputField.fieldType === INPUT_FIELD_TYPE.TRIGGER) {
          displayValue += ' ' + TRIGGER_SUFFIX_TEXT;
        }
        break;

      case INPUT_FIELD_TYPE.REMINDER:
        displayValue = getReminderDisplay(userInputField, nodeMetaData);
        break;

      case INPUT_FIELD_TYPE.NUTRITION_SEARCH:
        const nutritionData =
          userInputField.value || userInputField.defaultValue;
        displayValue = nutritionData.description;
        if (nutritionData.brandOwner) {
          displayValue += ' ' + nutritionData.brandOwner;
        }
        displayValue = displayValue;
        break;
      case INPUT_FIELD_TYPE.FORMS_SEARCH:
        const formList = userInputField.value || [];
        displayValue = '';
        const formListLength = formList.length - 1;
        formList.forEach((form: any, index: number) => {
          const formName = capitalize(form?.name) || '';
          if (formName) {
            displayValue =
              displayValue +
              (displayValue ? (index == formListLength ? ' and ' : ', ') : '') +
              formName;
          }
        });
        break;
        case INPUT_FIELD_TYPE.PACKAGES_SEARCH:
          const packageList = userInputField?.value || [];
          displayValue = '';
          const packageListLength = packageList?.length - 1;
          packageList?.forEach((item: any, index: number) => {
            const packageName = capitalize(item?.name) || '';
            if (packageName) {
              displayValue =
                displayValue +
                (displayValue ? (index == packageListLength ? ' and ' : ', ') : '') +
                packageName;
            }
          });
          break;
      case INPUT_FIELD_TYPE.GENERAL_SMS_TEMPLATE_SEARCH:
        displayValue = userInputField.value?.body || '';
        break;
      case INPUT_FIELD_TYPE.GENERAL_PUSH_NOTIFICATION_TEMPLATE_SEARCH:
        displayValue = userInputField.value?.subject || '';
        break;
      case INPUT_FIELD_TYPE.GENERAL_TEMPLATE_SEARCH:
        const templateInfo = userInputField.value || [];
        displayValue = templateInfo?.template?.attributes?.name;
        break;
      case INPUT_FIELD_TYPE.FORM_SEARCH:
      case INPUT_FIELD_TYPE.VITAL_SEARCH:
      case INPUT_FIELD_TYPE.IMMUNIZATION_SEARCH:
      case INPUT_FIELD_TYPE.VISIT_NOTE_TEMPLATE_SEARCH:
        const data = userInputField.value || userInputField.defaultValue;
        displayValue = data.name;
        break;

      case INPUT_FIELD_TYPE.APPOINTMENT_TYPE_SEARCH_ALL:
      case INPUT_FIELD_TYPE.APPOINTMENT_TYPE_SEARCH:
        case INPUT_FIELD_TYPE.APPOINTMENT_TYPE_SEARCH_IN_APPOINTMENT_FILTER:
        const appointmentType =
          userInputField?.value || userInputField?.defaultValue;
        displayValue = appointmentType.eventName
          ? `${appointmentType.eventName}`
          : undefined;
        break;

      case INPUT_FIELD_TYPE.FORM_OUTCOME_MATCH:
        const value = userInputField.value || userInputField.defaultValue;
        displayValue = value
          ? `when ${value?.form?.name || 'any'} form response is ${value?.state || 'any'
          }`
          : '';
        break;

      case INPUT_FIELD_TYPE.CONTENT_SEARCH:
        const templateData =
          userInputField.value || userInputField.defaultValue;
        displayValue = templateData.title;
        if (templateData.description && false) {
          displayValue += ' ' + templateData.description;
        }
        break;

      case INPUT_FIELD_TYPE.MED_SEARCH:
        const medData = userInputField.value || userInputField.defaultValue;
        displayValue = medData.name || medData.drugName;
        break;

      case INPUT_FIELD_TYPE.CONDITIONAL:
        const selectedValue = userInputField.value ||
          userInputField.defaultValue || {
          condition: '',
          from: 0,
          to: 0,
        };
        displayValue = getConditionalDisplay(
          selectedValue,
          userInputField.possibleValueList || []
        );
        break;

      case INPUT_FIELD_TYPE.VITAL_CONDITIONAL:
        const selectedVital = userInputField.value ||
          userInputField.defaultValue || {
          condition: '',
          from: 0,
          to: 0,
        };

        if (selectedVital.systolic && selectedVital.diastolic) {
          const systolicDisplay = getConditionalDisplay(
            selectedVital.systolic,
            userInputField.possibleValueList || []
          );
          const diastolicDisplay = getConditionalDisplay(
            selectedVital.diastolic,
            userInputField.possibleValueList || []
          );
          displayValue =
            'Systolic ' +
            systolicDisplay +
            '\n' +
            'Diastolic ' +
            diastolicDisplay;
        } else {
          displayValue = getConditionalDisplay(
            selectedVital,
            userInputField.possibleValueList || []
          );
        }
        break;

      case INPUT_FIELD_TYPE.USER_SEARCH:
        const userData = userInputField.value || userInputField.defaultValue;
        displayValue = userData?.label || '-';
        break;

      case INPUT_FIELD_TYPE.RISK_SCORE_CONDITIONAL:
        const riskInterpretation =
          userInputField.value || userInputField.defaultValue;
        displayValue = riskInterpretation?.condition || '-';
        break;

      case INPUT_FIELD_TYPE.LAB_TEST_SEARCH:
        const labData = userInputField.value || userInputField.defaultValue;
        displayValue = labData?.name || '-';
        break;

      case INPUT_FIELD_TYPE.PHONE_NUMBER_BY_TYPE:
        const phoneNumberData = userInputField.value;

        if (phoneNumberData?.phoneType?.value === 'mobile') {
          displayValue = phoneNumberData?.phoneNumber;
        } else if (phoneNumberData?.phoneType?.value === 'webPhone') {
          displayValue = phoneNumberData?.virtualNumberList?.map((element: IVirtualPhoneNumber) => {
            if (element.virtualPhoneNumber && element.virtualPhoneNumber.length > 0) {
              return element.virtualPhoneNumber;
            }
          }).toString() || '';
        } else if (phoneNumberData?.phoneType?.value === 'deskPhone') {
          displayValue = phoneNumberData.phoneName;
        } else {
          displayValue = phoneNumberData?.virtualPhoneNumber
        }
        break;

      case INPUT_FIELD_TYPE.USER_ROLE_SEARCH:
        const roleData = userInputField.value || userInputField.defaultValue;
        displayValue = roleData?.name || '-';
        break;

      case INPUT_FIELD_TYPE.AUTOMATION_SEARCH:
      case INPUT_FIELD_TYPE.MOMENT_SEARCH:
        const workflowData =
          userInputField.value || userInputField.defaultValue;
        displayValue = workflowData?.name || '-';
        break;

      case INPUT_FIELD_TYPE.VALUE_AND_UNIT:
        displayValue = '-';
        const displayData = userInputField.value || userInputField.defaultValue;
        if (displayData?.value && displayData?.unit) {
          const unit = getUnitDisplayFromList(
            displayData.unit,
            userInputField?.possibleValueList || []
          );
          displayValue = `${displayData.value} ${unit}`;
        }
        break;
      case INPUT_FIELD_TYPE.EMAIL_FREQUENCY:
        displayValue = '-';
        const displayDataForEmailFrequency = userInputField?.value || userInputField?.defaultValue;
        if (displayDataForEmailFrequency?.value && displayDataForEmailFrequency?.unit) {
          const unit = getUnitDisplayFromList(
            displayDataForEmailFrequency?.unit,
            userInputField?.possibleValueList || []
          );
          displayValue = `${displayDataForEmailFrequency?.value} ${unit}`;
        }
        break;
      case INPUT_FIELD_TYPE.SHOW_FILTER_TOTAL_COUNT:
        return '';
        break;
      case INPUT_FIELD_TYPE.ACTIVITY_TARGET:
        const targetData = getTargetDisplay(userInputField, nodeMetaData);
        displayValue = targetData;
        break;

      case INPUT_FIELD_TYPE.PRACTICE_LOCATION_SEARCH:
        const practiceLocation = userInputField?.value?.practiceLocation?.name || '';
        displayValue = practiceLocation;
        break;
      case INPUT_FIELD_TYPE.EMPLOYER_SEARCH:
        const employerName = userInputField?.value?.name || '';
        displayValue = employerName;
        break;
      case INPUT_FIELD_TYPE.TAG_DROPDOWN_SELECT:
        const tagList = (userInputField?.value || []).map((tag: any) => {
          return tag?.title || tag;
        });
        displayValue = tagList.length ? tagList.join(',') : '-';
        break;
      case INPUT_FIELD_TYPE.CONTACT_TYPE_SEARCH:
        const list = userInputField?.value || [];
        const valueList = list.map((info:any)=>{return info?.value})
        displayValue =  valueList.join(',');
        break;
      case INPUT_FIELD_TYPE.MULTIPLE_EMPLOYER_SEARCH:
        if (userInputField?.value?.employersNameList) {
          const exerciseList = userInputField?.value?.employersNameList || [];
          displayValue = exerciseList
            .map((exerciseName: string) => {
              return exerciseName || '';
            })
            .join(',');
        }
        break;

      case INPUT_FIELD_TYPE.EXERCISE_SEARCH:
        if (userInputField?.value?.length) {
          const exerciseList = userInputField?.value || [];
          displayValue = exerciseList
            .map((exerciseName: string) => {
              return exerciseName || '';
            })
            .join(',');
        }
        break;

      case INPUT_FIELD_TYPE.VISIT_NOTE_OUTCOME_MATCH:
        const displayOutputValue = userInputField.value || userInputField.defaultValue;
        displayValue = displayOutputValue
          ? `when ${displayOutputValue?.form?.name || 'any'} note response is ${displayOutputValue?.state || 'any'
          }`
          : '';
        break;

      case INPUT_FIELD_TYPE.CARE_JOURNEY_SEARCH_ALL:
        const journeySearchValue = userInputField.value || userInputField.defaultValue;
        displayValue = journeySearchValue?.journeyName?.length ? journeySearchValue?.journeyName?.toString() : '';
        break;

      case INPUT_FIELD_TYPE.PRACTICE_LOCATION_SEARCH_ALL:
        const locationSearchValue = userInputField.value || userInputField.defaultValue;
        displayValue = locationSearchValue?.locationName?.length ? locationSearchValue?.locationName?.toString() : '';
        break;

      case INPUT_FIELD_TYPE.GENERATE_TASK_OPTED_OUT_JOURNEY:
        const taskFieldValue = userInputField.value || userInputField.defaultValue;
        displayValue = taskFieldValue?.allowToCreateTask ? 'Allow to create task' : 'Not allow to create task';
        break;

      case INPUT_FIELD_TYPE.GROUP_APPOINTMENT_WEBINAR:
        const groupAppointmentValue = userInputField.value || userInputField.defaultValue;
        displayValue = groupAppointmentValue.appointmentData?.eventName || '';
        const recurrenceData = groupAppointmentValue.appointmentData?.reference?.recurrenceData || undefined;
        if (recurrenceData && recurrenceData?.endDate && recurrenceData?.seriesStartDate) {
          const formattedDate = getDateStrFromFormat(recurrenceData?.seriesStartDate, DATE_FORMATS.CONVERSATION_DATE_PICKER)
          displayValue += `(${getSummaryString(processDataRecurrence(recurrenceData), { startTime: formattedDate})})`
        }
        break;

      case INPUT_FIELD_TYPE.USER_POOL_SEARCH_ALL:
        const poolValue = userInputField.value || userInputField.defaultValue;
        displayValue = poolValue?.poolName?.length ? poolValue?.poolName?.toString() : '';
        break;

      case INPUT_FIELD_TYPE.TASK_LABEL_SEARCH_ALL:
        const labelValue = userInputField.value || userInputField.defaultValue;
        displayValue = labelValue?.labelName?.length ? labelValue?.labelName?.toString() : '';
        break;

      case INPUT_FIELD_TYPE.JOURNEY_AUTOMATION_ASSIGNEE_TYPE:
        const taskAssigneeOption = userInputField?.value?.displayText || '';
        displayValue = taskAssigneeOption;
        break;

      case INPUT_FIELD_TYPE.APPOINTMENT_SERIES_REFERENCE:
        const isAppliedFortSeriesAppointment = userInputField?.value?.isAppliedFortSeriesAppointment || false;
        const occurrenceFrequency = userInputField?.value?.appointmentSeriesConfiguration?.occurrenceFrequency;
        if (isAppliedFortSeriesAppointment && occurrenceFrequency) {
          switch(occurrenceFrequency) {
            case 'FIRST':
              displayValue = 'First occurrence';
              break;
            case 'LAST':
              displayValue = 'Last occurrence';
              break;
            case 'ALL_OTHER_THAN_FIRST':
              displayValue = 'All other than first';
              break;
            case 'ALL_OTHER_THAN_LAST':
              displayValue = 'All other than last';
              break;
            case 'ALL':
              displayValue = 'All occurrences';
              break;
          }
        }
        break;
      case INPUT_FIELD_TYPE.PRIMARY_CARE_PROVIDER_MULTIPLE_SEARCH:
      case INPUT_FIELD_TYPE.BILLING_PRACTITIONER_MULTIPLE_SEARCH:
        if (userInputField?.value?.userNames) {
          const userNameList = userInputField?.value?.userNames || [];
          displayValue = userNameList
            .map((userName: string) => {
              return userName || '';
            })
            .join(', ');
        }
        break;
      case INPUT_FIELD_TYPE.CUSTOM_ATTRIBUTE_FILTER:
        if (userInputField?.value?.customAttributes && userInputField?.value?.customAttributes.length > 0) {
          const customAttributes = userInputField?.value?.customAttributes

          displayValue = customAttributes
            .map((attributeValue: any) => {
              const filterType = attributeValue?.filterType || '';
              const customAttributeName = attributeValue?.customAttributeName || '';
              let value = attributeValue?.value || '';

              if (filterType == CUSTOM_ATTRIBUTE_FILTERS.BETWEEN) {
                const fromValue = attributeValue?.range?.from;
                const toValue = attributeValue?.range?.to;
                if(fromValue && toValue){
                  value = fromValue + ' and ' + toValue
                }
                else{
                  value = ''
                }
              }

              return customAttributeName + ' ' + filterType + ' ' + value
            })
            .join(', ')
        }
        break;
      
      case INPUT_FIELD_TYPE.ASSIGNED_TO_CARE_JOURNEY_USER_ROLE_OR_TASK_POOL:
        if (userInputField?.value?.taskPoolId) {
          return 'Specific User in Task Pool'
        }
        else {
          return 'Specific Role User in Journey Care Team'
        }
      case INPUT_FIELD_TYPE.CARE_PLAN_STATUS_CUSTOM_OBJECT:
        return userInputField?.value?.carePlanStatus?.value
      case INPUT_FIELD_TYPE.CARE_PLAN_TEMPLATE_SEARCH:
        const templates = userInputField?.value?.length > 0 ? userInputField?.value : []
        return templates.map((template: any) => template?.label).join(', ');
      default:
        displayValue = userInputField.displayValue || userInputField.value;
    }
  }
  displayValue =
    ['object', 'number'].indexOf((typeof displayValue)) != -1
      ? JSON.stringify(displayValue)
      : (displayValue ? displayValue : '-');

  if (userInputField?.selectedComparisonOperator && displayValue && userInputField?.isShowComparisonOperator) {
    displayValue = userInputField?.selectedComparisonOperator.displayName  ? userInputField?.selectedComparisonOperator.displayName + ' ' + displayValue : ('' + displayValue)
  }
  return displayValue || '-';
};

const getConditionalValue = (selectedValue: any, isBTW:boolean) => {
  const isFeetInch = selectedValue.displayUnit === VitalUnit.ft_inches;
  if (isBTW) {
    return {
      fromValue : isFeetInch ? `${selectedValue.convertedFromValue || selectedValue.from} ft ${selectedValue.convertedFromValue1} inches` :  (selectedValue.convertedFromValue || '-'),
      toValue : isFeetInch ? `${selectedValue.convertedToValue || selectedValue.to} ft ${selectedValue.convertedToValue1} inches` : (selectedValue.convertedToValue || '-'),
    }
  } else {
    return {
      fromValue : isFeetInch ? `${selectedValue.convertedFromValue || selectedValue.from} ft ${selectedValue.convertedFromValue1} inches` :  (selectedValue.convertedFromValue || selectedValue.from || '-'),
    }
  }
}

const getConditionalDisplay = (selectedValue: any, conditionList: any[]) => {
  let displayValue = '';
  const isFeetInch = selectedValue.displayUnit === VitalUnit.ft_inches;
  const value = getConditionalValue(selectedValue,selectedValue.condition === 'BTW');
  if (selectedValue.condition && conditionList) {
    const matchedData = conditionList.filter((unitData: any) => {
      return unitData.value === selectedValue.condition;
    });
    if (matchedData.length > 0) {
      displayValue = matchedData[0].displayName;
      if (selectedValue.condition === 'BTW') {
        displayValue += ' ' + value.fromValue + ' and ' + value.toValue;
      } else {
        displayValue += ' ' + value.fromValue;
      }
      if (selectedValue.unit && !isFeetInch) { // no need to append units in case of feetInches
        displayValue += ' ' + (selectedValue.displayUnit || selectedValue.unit || '-');
      }
    }
  }
  return displayValue;
};

const getDurationDisplay = (
  userInputField: IUserInputField,
  nodeMetaData: any
) => {
  const data =
    userInputField.value && userInputField.value.value >= 0
      ? userInputField.value
      : userInputField.defaultValue;
  const unit = getUnitDisplay(
    data.unit,
    userInputField.fieldType,
    nodeMetaData
  );
  return data.value >= 0 ? data.value + ' ' + (unit || '-') : (unit || '-');
};

const getTargetDisplay = (
  userInputField: IUserInputField,
  nodeMetaData: any
) => {
  if (userInputField?.value && userInputField?.value?.displayValue) {
    return userInputField?.value?.displayValue;
  }
  return '';
};

const getReminderDisplay = (
  userInputField: IUserInputField,
  nodeMetaData: any
) => {
  let displayValue = '';
  const reminderList =
    userInputField.value || userInputField.defaultValue || [];
  reminderList.forEach((data: any, index: number) => {
    const unit = getUnitDisplay(
      data.unit,
      userInputField.fieldType,
      nodeMetaData
    );
    displayValue +=
      getOffsetDisplay(data.offset) +
      ' ' +
      data.value +
      ' ' +
      unit +
      (reminderList.length > index + 1 ? ', ' : '');
  });
  return displayValue;
};

const getUnitDisplayFromList = (
  unit: string,
  possibleValueList: { value: string; displayName: string }[]
) => {
  const matchedData = possibleValueList.filter((unitData: any) => {
    return unitData.value === unit;
  });
  if (matchedData.length > 0) {
    return matchedData[0].displayName;
  }
  return unit;
};

const getUnitDisplay = (unit: string, fieldType: string, nodeMetaData: any) => {
  if (!nodeMetaData) {
    return '';
  }
  let unitList = nodeMetaData?.durationUnits || [];
  if (fieldType === INPUT_FIELD_TYPE.TRIGGER) {
    unitList = nodeMetaData.triggerUnits;
  } else if (fieldType === INPUT_FIELD_TYPE.REMINDER) {
    unitList = nodeMetaData.reminderUnits;
  }
  const matchedData = unitList.filter((unitData: any) => {
    return unitData.value === unit;
  });
  if (matchedData.length > 0) {
    return matchedData[0].displayName;
  }
  return unit;
};

const getOffsetDisplay = (offset: string) => {
  if (offset === 'BEFORE') {
    return 'Before';
  } else if (offset === 'AFTER') {
    return 'After';
  }
  return offset;
};

const handleSendEmailToValidation = (userInputField : IUserInputField) => {
  const { value } = userInputField;
  switch (value?.condition) {
    case 'SELECT_USER':
      return !value?.member?.key;
    case 'SELECT_CARE_JOURNEY_USER_ROLE':
    case 'MEMBER_CARE_TEAM_USER_ROLES':
      return !value?.roleId && !value?.name;
    case 'TASK_POOL_USER':
      return !value?.taskPoolId || !value?.userId
    default:
      return !value?.condition;
  }
}

export const isInvalidUserInput = (
  userInputField: IUserInputField,
  userInputFieldList?: IUserInputField[]
) => {
  userInputFieldList = userInputFieldList || [];
  let selectedComparisonOperatorStr: any =
    userInputField?.selectedComparisonOperator;
  try {
    selectedComparisonOperatorStr = JSON.stringify(
      userInputField.selectedComparisonOperator
    );
  } catch {
  }
  if (userInputField.optional || !canShowField(userInputField)) {
    let valueStr = userInputField.value;
    try {
      valueStr = JSON.stringify(userInputField.value);
    } catch {
    }
    if (
      userInputField.optional &&
      (userInputField.value === undefined ||
        userInputField.value === '' ||
        userInputField.value === null ||
        valueStr === '[]' ||
        valueStr == '{}') &&
      userInputField.isShowComparisonOperator &&
      userInputField.selectedComparisonOperator &&
      selectedComparisonOperatorStr != '{}'
    ) {
      return true;
    }
    const isUnitAbsent = (!(userInputField?.value?.unit) || !(userInputField?.toValue?.unit));
    const isFromValueAbsent = (!(userInputField?.value?.count) && !(userInputField?.value?.dateString));
    const isToValueAbsent = (!(userInputField?.toValue?.count) && !(userInputField?.toValue?.dateString));
    if(
      userInputField.optional &&
      userInputField.isShowComparisonOperator &&
      userInputField.selectedComparisonOperator &&
      userInputField.selectedComparisonOperator.value==='BTW' &&
      (isUnitAbsent || isToValueAbsent || isFromValueAbsent)
    ){
      return true;
    }
    if (
      userInputField.optional &&
      (userInputField.selectedComparisonOperator === undefined ||
        selectedComparisonOperatorStr === '{}') &&
      userInputField.isShowComparisonOperator &&
      userInputField.value !== null &&
      userInputField.value !== undefined &&
      userInputField.value !== '' &&
      valueStr != '[]' &&
      valueStr != '{}'
    ) {
      return true;
    }
    return false;
  }
  const isInvalidField = !userInputField.value;
  switch (userInputField.fieldType) {
    case INPUT_FIELD_TYPE.REMINDER:
      return (
        isInvalidField ||
        (userInputField.value || []).some((reminderInput: IReminderData) => {
          return (
            !reminderInput.value || !reminderInput.unit || !reminderInput.offset
          );
        })
      );
    case INPUT_FIELD_TYPE.SEND_EMAIL_TO:
      return handleSendEmailToValidation (userInputField);
    case INPUT_FIELD_TYPE.VALUE_AND_UNIT:
      return (
        userInputField?.value === undefined ||
        userInputField?.value?.value === '' ||
        userInputField?.value?.value === undefined ||
        userInputField?.value?.value === null ||
        !userInputField?.value?.unit
      );
    case INPUT_FIELD_TYPE.NUMBER:
      return userInputField.value === undefined || userInputField.value === '';
    case INPUT_FIELD_TYPE.GENERAL_SMS_TEMPLATE_SEARCH:
      return !userInputField?.value?.body;
    case INPUT_FIELD_TYPE.GENERAL_PUSH_NOTIFICATION_TEMPLATE_SEARCH:
      return !userInputField?.value?.body || !userInputField?.value?.subject;
    case INPUT_FIELD_TYPE.FORMS_SEARCH:
      return !userInputField?.value?.length
    case INPUT_FIELD_TYPE.PACKAGES_SEARCH:
      return !userInputField?.value?.length
    case INPUT_FIELD_TYPE.GENERAL_TEMPLATE_SEARCH:
      const isSkipEmail = userInputFieldList.some((userInputField)=>{
        return (userInputField?.key == INPUT_FIELD_KEY.isSkipEmail && userInputField?.value == 'TRUE') ||  (userInputField?.key == INPUT_FIELD_KEY.sendVia && userInputField?.value == 'APP') || (userInputField?.key == INPUT_FIELD_KEY.communicationViaNode && userInputField?.value == 'APP')
      })
      return (
        (isInvalidField ||
          !userInputField?.value?.template?.id ||
          !userInputField?.value?.subject) &&
        !userInputField?.value?.isSkipEmail && !isSkipEmail
      );
    case INPUT_FIELD_TYPE.TRIGGER:
      return (
        isInvalidField ||
        (userInputField.value.unit !== TRIGGER_START_OF_CARE_PLAN_UNIT &&
          !(userInputField.value.value >= 0)) ||
        !userInputField.value.unit
      );

    case INPUT_FIELD_TYPE.DURATION:
      return (
        isInvalidField ||
        (userInputField.value.unit !== DURATION_END_OF_CARE_PLAN_UNIT &&
          !userInputField.value.value) ||
        !userInputField.value.unit
      );
    case INPUT_FIELD_TYPE.ASSIGNED_TO_USER_ROLE_OR_TASK_POOL:
      return !userInputField.value || Object.keys(userInputField.value).length === 0;
    case INPUT_FIELD_TYPE.AUDIO_URL:
      return isInvalidField || !checkLinkIsMp3(userInputField.value);
    case INPUT_FIELD_TYPE.UNITY_AI_CUSTOM_FIELD:
      return userInputField?.promptList?.some(item => item === undefined || item === '') || userInputField?.keysList?.some(item => item === undefined || item === '');
    case INPUT_FIELD_TYPE.CARE_PLAN_STATUS_CUSTOM_OBJECT:
      return isCustomInputFieldInvalid(INPUT_FIELD_TYPE.CARE_PLAN_STATUS_CUSTOM_OBJECT, userInputField)
  }
  if (
    !isInvalidField &&
    (userInputField.value === null ||
      userInputField.value === undefined ||
      userInputField.value === '') &&
    userInputField.isShowComparisonOperator &&
    userInputField.selectedComparisonOperator &&
    selectedComparisonOperatorStr != '{}'
  ) {
    return true;
  }
  if (
    userInputField.optional &&
    (userInputField.selectedComparisonOperator === undefined ||
      selectedComparisonOperatorStr == '{}') &&
    userInputField.isShowComparisonOperator &&
    userInputField.value !== null &&
    userInputField.value !== undefined &&
    userInputField.value !== ''
  ) {
    return true;
  }

  return isInvalidField;
};

const isCustomInputFieldInvalid = (inputFieldType: any, userInputField: any) => {
  let isInvalid = true
  switch (inputFieldType) {
    case INPUT_FIELD_TYPE.CARE_PLAN_STATUS_CUSTOM_OBJECT:
      const carePlanStatus = userInputField?.value?.carePlanStatus;
      if (carePlanStatus) {
        if (
          carePlanStatus &&
          carePlanStatus?.code &&
          carePlanStatus?.code === CARE_PLAN_STATUS_CODES.IN_REVIEW
        ) {
          const selectedOptionForReview = userInputField?.value?.selectedOptionForReview
          if (selectedOptionForReview?.condition === CARE_TEAM_MEMBER_TYPE.CARE_MANAGER) {
            isInvalid = false
          }
          else if (selectedOptionForReview?.condition === assignToTypeCodes.SELECT_USER) {
            const member = selectedOptionForReview?.member
            if (member && member?.value) {
              isInvalid = false
            }
          }
          const taskTitle = userInputField?.value?.taskTitle
          if (taskTitle == undefined || taskTitle == '') {
            isInvalid = true
          }
          return isInvalid
        }
        else {
          return false
        }
      }
      return isInvalid
  }
}

export const checkLinkIsMp3 = (link: string) => {
  const mp3Regex = /\.(mp3)$/i;
  return mp3Regex.test(link);
};


export const canShowField = (field: IUserInputField, getPreviousImmediateNode?:any, prevImmediateNode?:any): boolean => {
  if(field?.isDeprecated){
    return false;
  }
  if(!prevImmediateNode && (field?.allowedSubflowList ||  field?.allowedRootNodeTypeList) ){
    return false

  }
  if(getPreviousImmediateNode && prevImmediateNode && (field?.allowedSubflowList ||  field?.allowedRootNodeTypeList)){
    let prevImmediateNodeC = prevImmediateNode;
    let isBreak = false;
    do {
      const onParentTriggerType = prevImmediateNode?.data?.nodeType;
      if (field?.allowedSubflowList && field?.allowedSubflowList.indexOf(onParentTriggerType) != -1) {
        return true
      }

      if(prevImmediateNodeC){
        prevImmediateNodeC = getPreviousImmediateNode(prevImmediateNodeC?.id)
      }
      if(prevImmediateNodeC){
        prevImmediateNode = prevImmediateNodeC
      }

      if(prevImmediateNode?.type == 'MapSubflow'){
        isBreak = true;
      }

    }while(prevImmediateNodeC && !isBreak)

  }

  const onParentTriggerType = prevImmediateNode?.data?.nodeType;
  if (field?.allowedSubflowList) {
    if (field?.allowedSubflowList && field?.allowedSubflowList.indexOf(onParentTriggerType) != -1) {
      return true;
    }


    if (field?.allowedRootNodeTypeList && field?.allowedRootNodeTypeList?.indexOf(prevImmediateNode?.data?.nodeType) != -1) {
      return true;
    }
   return false;
  }
  if (field && field.isHidden || field && field.fieldType === INPUT_FIELD_TYPE.EXERCISE_SEARCH) return false;
  return true;
};

export const setFieldsVisibility = (inputFields: IUserInputField[]) => {
  const frequencyNode =
    getInputFieldByKey(inputFields, WORKFLOW_INPUT_FIELD_KEYS.FREQUENCY) ||
    getInputFieldByKey(inputFields, WORKFLOW_INPUT_FIELD_KEYS.FREQUENCY_OBJECT);
  const durationNode = getInputFieldByKey(
    inputFields,
    WORKFLOW_INPUT_FIELD_KEYS.DURATION
  );
  const counterNode = getInputFieldByKey(
    inputFields,
    WORKFLOW_INPUT_FIELD_KEYS.COUNT
  );

  let isValueChanged = false;

  if (frequencyNode && !frequencyNode.isHidden) {
    if (durationNode) {
      const currentDurationNodeHiddenValue = !!durationNode.isHidden;
      if (
        frequencyNode.value === FREQUENCY_CODES.NEVER ||
        frequencyNode.value?.code === FREQUENCY_CODES.NEVER
      ) {
        durationNode.isHidden = true;
        durationNode.value = undefined;
      } else {
        durationNode.isHidden = false;
        if (!durationNode.value || !durationNode.value.unit)
          durationNode.value = durationNode.defaultValue;
      }

      if (currentDurationNodeHiddenValue !== durationNode.isHidden) {
        isValueChanged = true;
      }
    }
    if (counterNode) {
      const currentDurationNodeHiddenValue = !!counterNode.isHidden;
      if (
        frequencyNode.value === FREQUENCY_CODES.NEVER ||
        frequencyNode.value?.code === FREQUENCY_CODES.NEVER
      ) {
        counterNode.isHidden = true;
        counterNode.value = undefined;
      } else {
        counterNode.isHidden = false;
        if (!counterNode.value) counterNode.value = counterNode.defaultValue;
      }

      if (currentDurationNodeHiddenValue !== counterNode.isHidden) {
        isValueChanged = true;
      }
    }
  }

  return { isValueChanged, inputFields };
};

export const setFieldsVisibilityFromPreviousNode = (
  inputFields: IUserInputField[],
  nodeMasterDataMap: any,
  prevImmediateNode?: any,
  flowType?:string
) => {
  if(flowType != FlowType.careJourney ){
    return { isValueChanged:false, inputFields };
  }
  let isValueChanged = false;
  if (prevImmediateNode?.data?.nodeType && nodeMasterDataMap) {
    const nodeData = nodeMasterDataMap[prevImmediateNode.data.nodeType];

    inputFields.forEach((field) => {
      const previousValue = !!field.isHidden;
      if (
        nodeData?.category?.code === NodeCategory.operation ||
        nodeData?.category?.code === NodeCategory.event
      ) {
        if (field.requiredForEntity) {
          field.isHidden = true;
          field.value = field.defaultValue;
        }
      } else {
        field.isHidden = false;
      }
      if (
        nodeData?.passData?.entityType &&
        field.group &&
        field.group !== nodeData.passData.entityType
      ) {
        field.isHidden = true;
        field.value = field.defaultValue;
      }
      if (previousValue !== field.isHidden) {
        isValueChanged = true;
      }
    });
  } else {
    inputFields.forEach((field) => {
      const previousValue = !!field.isHidden;
      field.isHidden = false;
      if (previousValue !== field.isHidden) {
        isValueChanged = true;
      }
    });
  }

  return { isValueChanged, inputFields };
};

export const getInputFieldByKey = (
  inputFields: IUserInputField[],
  key: string
) => {
  return (inputFields || []).find((field) => field?.key === key);
};
