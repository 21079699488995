import { gql } from "@apollo/client";

export const getFormAnalytics = gql`
query GetPopulationGroupFormAnalytics($params: PopulationGroupFormBatchInput!) 
{ getPopulationGroupFormAnalytics(params: $params) 
  {forms
    aggregate
  }
}
`;

export default { getFormAnalytics }