import React from 'react';
import {Tooltip} from 'antd';
import {Pressable, Text, VStack, View} from 'native-base';
import {COPY_MESSAGE_CODE} from '../../../../../../constants';
import {Colors} from '../../../../../../styles';
import {testID} from '../../../../../../testUtils';
import CreateNew from '../../../../../common/Svg/CreateNew';
import CopyActionSvg from '../../../../../common/Svg/PersonActionSvgIcons/CopyActionSvg';
import {ICopiedMessageItem} from '../interface';
import {styles} from './CopyMsgViewStyles';
import '../MessagingWindowHeaderComponents/MessagingWindowHeaderComponentsStyles.css';
import {FHTooltip} from '../../../../../common/FHTooltip';

export interface ICopyMsgViewProps {
  code: string;
  copyMessageList: ICopiedMessageItem[];
  handleCopyToClipBoard: () => void;
}

const CopyMsgView = ({
  code,
  isMessageCopy,
  handleCopyToClipBoard,
}: {
  code: string;
  isMessageCopy: boolean;
  handleCopyToClipBoard: () => void;
}) => {
  if (code !== COPY_MESSAGE_CODE.COPY_MODE_COPIED) {
    return (
      <FHTooltip text={'Copy Message'} position="bottom">
        <div
          onClick={handleCopyToClipBoard}
          {...testID('CopyMessageClick')}
          className={`pressable ${!isMessageCopy ? 'disabled' : ''}`}
        >
          <div className="vstack">
            <CopyActionSvg
              customStrokeColor={
                isMessageCopy ? Colors.FoldPixel.GRAY300 : Colors.Custom.Gray300
              }
              width={20}
              height={20}
            />
          </div>
        </div>
      </FHTooltip>
    );
  }

  if (code === COPY_MESSAGE_CODE.COPY_MODE_COPIED) {
    return (
      <div className="hstack">
        <CreateNew customColor={Colors.Custom.SuccessColor} />
        <p className="copied-text" {...testID('Copied')}>
          Copied
        </p>
      </div>
    );
  }

  return <></>;
};

export default CopyMsgView;
