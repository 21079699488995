import React from 'react';
import {View, Text} from 'react-native';
import {svgStyles} from '../SvgStyles';
import {DisplayText} from '../../DisplayText/DisplayText';
import {Colors} from '../../../../styles';
import Svg, { Circle, Defs, LinearGradient, Path, RadialGradient, Stop } from 'react-native-svg';

const NoGoalsSvg = (props: {titleId?: string; fillColor?: string}) => {
  return (
    <View style={svgStyles.container}>
      <Svg
        width="102"
        height="102"
        viewBox="0 0 102 102"
        fill="none"
      >
        <Circle
          cx="51"
          cy="51"
          r="50"
          stroke="url(#paint0_radial_264_155019)"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-dasharray="1.46 1.46"
        />
        <Circle
          cx="51.0007"
          cy="50.9997"
          r="42.6667"
          stroke="url(#paint1_radial_264_155019)"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-dasharray="1.46 1.46"
        />
        <Path
          d="M15.8545 51.1016C15.8545 31.7716 31.5245 16.1016 50.8545 16.1016C70.1845 16.1016 85.8545 31.7716 85.8545 51.1016C85.8545 70.4315 70.1845 86.1016 50.8545 86.1016C31.5245 86.1016 15.8545 70.4315 15.8545 51.1016Z"
          fill="url(#paint2_linear_264_155019)"
        />
        <Path
          d="M50.9996 35.0273C59.8208 35.0273 66.9718 42.1784 66.9718 50.9996C66.9718 59.8208 59.8208 66.9718 50.9996 66.9718C42.1784 66.9718 35.0273 59.8208 35.0273 50.9996C35.0273 46.5315 36.862 42.4919 39.819 39.5931M39.819 50.9996C39.819 57.1744 44.8247 62.1801 50.9996 62.1801C57.1744 62.1801 62.1801 57.1744 62.1801 50.9996C62.1801 44.8247 57.1744 39.819 50.9996 39.819M50.9996 57.3885C54.5281 57.3885 57.3885 54.5281 57.3885 50.9996C57.3885 47.4711 54.5281 44.6107 50.9996 44.6107"
          stroke="#A7AFBE"
          stroke-width="1.59722"
          stroke-linecap="round"
        />
        <Defs>
          <RadialGradient
            id="paint0_radial_264_155019"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(51.0001 73.6082) rotate(-90.639) scale(78.4672)"
          >
            <Stop stop-color="#A1AABA" />
            <Stop offset="1" stop-color="#E4E7EC" />
          </RadialGradient>
          <RadialGradient
            id="paint1_radial_264_155019"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(51.0007 70.292) rotate(-90.639) scale(66.9587)"
          >
            <Stop stop-color="#A1AABA" />
            <Stop offset="1" stop-color="#E4E7EC" />
          </RadialGradient>
          <LinearGradient
            id="paint2_linear_264_155019"
            x1="26.0002"
            y1="24"
            x2="74.0002"
            y2="84.75"
            gradientUnits="userSpaceOnUse"
          >
            <Stop stop-color="#F9FAFC" />
            <Stop offset="1" stop-color="#F1F2F3" />
          </LinearGradient>
        </Defs>
      </Svg>

      {props.titleId && (
        <Text style={svgStyles.svgText}>
          <DisplayText
            textLocalId={props.titleId}
            textType="Text"
            extraStyles={{color: Colors.Custom.Gray400}}
          />
        </Text>
      )}
    </View>
  );
};

export default NoGoalsSvg;
