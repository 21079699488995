import React from 'react';
import {isWeb} from '../../../../utils/platformCheckUtils';
import {ICommonSvgProps} from '../interfaces';
import {Icon} from 'native-base';
import FeatherIcon from 'react-native-vector-icons/Feather';

const ArrowUpsvg = (props: ICommonSvgProps) => {
  const {width, height, customStrokeColor} = props;
  return (
    <svg
      width={width ? width : '20'}
      height={height ? height : '21'}
      fill="none"
      viewBox="0 0 20 21"
    >
      <path
        stroke={customStrokeColor ? customStrokeColor : '#667085'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M5 8l5 5 5-5"
      ></path>
    </svg>
  );
};

export default ArrowUpsvg;
