import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
  },
  squareStyle: {
    height: 24,
    width: 24,
    borderRadius: 4,
    backgroundColor: '#FFF',
    marginTop: 2,
    marginLeft: 2,
  },

  mainStyes: {
    borderRadius: 4,
    backgroundColor: 'red',
    height: 28,
    width: 50,
  },
});
