import React from 'react';
import {Center, HStack, VStack, View} from 'native-base';
import {EmptyStateSvg} from '../../../../common/Svg';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import {Colors} from '../../../../../styles';
import { isWeb } from '../../../../../utils/platformCheckUtils';

const NoNotes = (props:{
  title: string;
  extraView?: JSX.Element
}) => {
  const componentHeight = isWeb() ? '70vh' : '60%';
  const illustrationSize = isWeb() ? 24 : undefined;
  return (
    <Center
      alignItems="center"
      justifyContent="center"
      height={componentHeight}
    >
      <VStack padding={5} space={4}>
        <HStack justifyContent={'center'}>
          <View
            width={illustrationSize}
            height={illustrationSize}
            style={isWeb() ? {marginBottom: 15} : undefined}
          >
            <EmptyStateSvg />
          </View>
        </HStack>
        <HStack>
          <DisplayText
            size="lgNormal"
            textLocalId={props.title}
            extraStyles={{
              color: Colors.Custom.Gray500,
              textAlign: isWeb() ? 'left' : 'center',
            }}
          />
        </HStack>
        {props?.extraView}
      </VStack>
    </Center>
  );
};

export default NoNotes;
