import {IAllowedWearableData, IVitalData} from '../../../../../../Interfaces';
import {DATE_FORMATS, DISPLAY_DATE_FORMAT, DISPLAY_SLASH_DATE_FORMAT} from '../../../../../../constants';
import {
  getActivityPromise,
  getVitalsWithFilters,
} from '../../../../../../services/CommonService/AidBoxService';
import {getMomentObj, isTodayDate} from '../../../../../../utils/DateUtils';
import {MonitoringActivityCodes, Vital} from '../../../../../../utils/VitalUtils';
import {
  getFormattedVitalData,
  getVitalDataArray,
} from '../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailTables/DetailTableUtils';
import {getAnonationIndex} from '../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailTables/VitalsGraphView/VitalsGraphUtils';
import {DurationInputArg2, Moment} from 'moment';
import {
  IActivity,
  IVitals,
} from '../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/interfaces';
import DropIcon from '../../../../../../assets/Icons/DropIcon';
import HeartIcon from '../../../../../../assets/Icons/HeartIcon';
import BloodOxygenIcon from '../../../../../../assets/Icons/BloodOxygenIcon';
import {
  GRAPH_TYPES,
  getActivitiesGraphTitle,
} from '../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailTables/Activities/Activities';
import {
  getFormattedActivityData,
  getPatientData,
} from '../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailTables/Activities/ActivityUtils';
import {AxiosResponse} from 'axios';
import {data} from '../../../Analytics/raw';
import WalkingIcon from '../../../../../../assets/Icons/WalkingIcon';
import FlameIcon from '../../../../../../assets/Icons/FlameIcon';
import ClockIcon from '../../../../../../assets/Icons/ClockIcon';
import SleepIcon from '../../../../../../assets/Icons/SleepIcon';
import { DataType, GraphDataOperation, GraphsNodeTypes, GraphsTypes, IDateRange, IFilterParams, IHighLightValues, IWearableDataFilterParams, IWearableSetting, IWearableSettingListItem, ObservationSortBy, ObservationSourceType, VitalDataSubFilterMap, VitalGraphData } from './interface';

import {isWeb} from '../../../../../../utils/platformCheckUtils';
import {Observation} from 'fhir/r4';
import {EXTENSION_URLS} from '../../../../../PersonOmniView/MiddleContainer/PatientNotes/components/AddOrUpdateTemplate/constant';
import { CINICAL_SECTIONS_FILTER_TAB_CODES } from '../../../../../PatientOmniView/Sections/ClinicalSection/ClinicalSectionConstants';
import { AllowedWearableDataCategories } from '../../../../WearableDataIngest/WearableDataConstants';
import BloodPressureIcon from '../../../../../../assets/Icons/BloodPressureIcon';
import RespirationRateIcon from '../../../../../../assets/Icons/RespirationRateIcon';
import WeightIconSvg from '../../../../../../assets/Icons/WeightIconSvg';
import HeightIconSvg from '../../../../../../assets/Icons/HeightIconSvg';
import BMIIconSvg from '../../../../../../assets/Icons/BMIIconSvg';
import BodyTemperatureIconSvg from '../../../../../../assets/Icons/BodyTemperatureIconSvg';
import HeadCircumeferenceIconSvg from '../../../../../../assets/Icons/HeadCircumeferenceIconSvg';
import FormIconSvg from '../../../../../common/Svg/InterventionSvg/InterventionTypeSvg/FormIconSvg';
import ClinicalAssessmenticonSvg from '../../../../../../assets/Icons/ClinicalAssessmenticonSvg';
import { getSleepGraphData } from './SleepUtils';
import { getDataAvailableCountString } from './CommonUtils';
import { IObservation } from '../Vitals/interfaces';
import LabTestTubeSvg from '../../../../../common/Svg/LabTestTubeSvg';

export type Activity = GRAPH_TYPES;

export const VITAL_API_DATE_FORMAT = 'YYYY-MM-DD';

export const getUnitForExternalVital = (vital: string, isActivity: boolean) => {
  if (isActivity) {
    switch (vital) {
      case GRAPH_TYPES.Calories:
        return 'kcal';
      case GRAPH_TYPES.Step:
        return '';
      case GRAPH_TYPES.Time:
        return 'mins';
    }
  }
  const config = HOME_MONITORING_VITALS.find((item) => item.loinc === vital);
  return config?.unit;
};

export const HOME_MONITORING_VITALS: IVitalData[] = [
  {
    loinc: Vital.bloodGlucose,
    display: 'Blood Glucose',
    foldDisplay: 'Blood Glucose',
    unit: 'mg/dL',
    allowedDecimalPlaces: 1,
    displayUnit: 'mg/dL',
    range: [
      {
        min: 70,
        max: 140,
      },
    ],
  },
  {
    loinc: Vital.heartRate,
    display: 'Heart Rate',
    foldDisplay: 'Heart Rate',
    unit: 'bpm',
    allowedDecimalPlaces: 0,
    displayUnit: 'bpm',
  },
  {
    loinc: Vital.restingHeartRate,
    display: 'Resting Heart Rate',
    foldDisplay: 'Resting Heart Rate',
    unit: 'bpm',
    allowedDecimalPlaces: 0,
    displayUnit: 'bpm',
  },
  {
    loinc: Vital.heartRateVariability,
    display: 'Heart Rate Variability',
    foldDisplay: 'Heart Rate Variability',
    unit: 'ms',
    allowedDecimalPlaces: 0,
    displayUnit: 'ms',
  },
  {
    loinc: Vital.oxygenSaturation,
    display: 'Oxygen Saturation',
    foldDisplay: 'Oxygen Saturation',
    unit: '%',
    allowedDecimalPlaces: 1,
    displayUnit: '%',
  },
  {
    loinc: Vital.sleep,
    display: 'Sleep',
    foldDisplay: 'Sleep',
    unit: 'hrs',
    allowedDecimalPlaces: 1,
    displayUnit: 'hrs',
  }
];

export const enabledVitalsForHomeMonitoringWithConfigs = [
  {
    type: GraphsTypes.LINE,
    code: Vital.bloodGlucose,
  },
  {
    type: GraphsTypes.BAR,
    code: Vital.bloodGlucose,
    dataOperation: GraphDataOperation.LOW_EVENT,
  },
  {
    type: GraphsTypes.LINE,
    code: Vital.heartRate,
  },
  {
    type: GraphsTypes.LINE,
    code: Vital.restingHeartRate,
  },
  {
    type: GraphsTypes.LINE,
    code: Vital.heartRateVariability,
  },
  {
    type: GraphsTypes.LINE,
    code: Vital.oxygenSaturation,
  },
  {
    type: GraphsTypes.SPLIT_BAR,
    code: Vital.sleep,
  }
];

export const getLastRecordedDate = (dateStr: string) => {
  if (!dateStr) {
    return '';
  }
  const momentObj = getMomentObj(dateStr);
  if (!momentObj.isValid()) {
    return '';
  }
  const formattedDate = momentObj.format(DATE_FORMATS.MESSAGE_DATE_FORMAT);
  return formattedDate;
};

export const FilterTypes = {
  Day: 'Day',
  Week: 'Week',
  Month: 'Month',
  ThreeMonths: 'ThreeMonths',
  SixMonths: 'SixMonths',
};

export const DRILL_DOWN_FILTERS = [
  {
    code: FilterTypes.Day,
    label: isWeb() ? 'Day' : '1D',
  },
  {
    code: FilterTypes.Week,
    label: isWeb() ? 'Week' : '1W',
  },
  {
    code: FilterTypes.Month,
    label: isWeb() ? 'Month' : '1M',
  },
  {
    code: FilterTypes.ThreeMonths,
    label: isWeb() ? '3 Months' : '3M',
  },
  {
    code: FilterTypes.SixMonths,
    label: isWeb() ? '6 Months' : '6M',
  },
];

export const getDateRangeForInitCall = (
  filterType?: keyof typeof FilterTypes,
) => {
  const range: {
    start: Date;
    end: Date;
  } = {
    start: new Date(),
    end: new Date(),
  };
  let start: Date = getMomentObj(new Date()).startOf('day').toDate();
  let end: Date = getMomentObj(new Date()).endOf('day').toDate();
  switch (filterType) {
    case FilterTypes.Day:
      break;
    case FilterTypes.Week:
      start = getMomentObj(new Date()).subtract('6', 'days').startOf('day').toDate();
      break;
    case FilterTypes.Month:
      start = getMomentObj(new Date()).subtract('1', 'month').startOf('day').toDate();
      break;
    case FilterTypes.ThreeMonths:
      start = getMomentObj(new Date()).subtract('3', 'months').startOf('day').toDate();
      break;
    case FilterTypes.SixMonths:
      start = getMomentObj(new Date()).endOf('month').subtract('6', 'months').startOf('day').toDate();
      end = getMomentObj(new Date()).endOf('month').endOf('day').toDate();
      break;
    default:
      return range;
  }

  range.start = start;
  range.end = end;
  return range;
};

export const getPromiseListWithDateRange = (params: {
  patientId: string;
  locationId?: string;
  dateRange?: IDateRange;
  allowedWearableList: IAllowedWearableData[];
}) => {
  const {patientId, locationId} = params;
  const promiseList: Promise<any>[] = [];

  const startDate = params.dateRange?.start
    ? getMomentObj(params.dateRange?.start).startOf('day').toISOString()
    : undefined;
  const endDate = params.dateRange?.end
    ? getMomentObj(params.dateRange?.end).endOf('day').toISOString()
    : undefined;
  const filteredEnabledVitalsForHomeMonitoringWithConfigs = enabledVitalsForHomeMonitoringWithConfigs.filter((item) => params?.allowedWearableList.find((wearable) => wearable.code === item.code && wearable.enabled)) || [];

  filteredEnabledVitalsForHomeMonitoringWithConfigs.forEach((vital) => {
    promiseList.push(
      getVitalsWithFilters(
        patientId,
        vital.code,
        1000,
        0,
        locationId,
        startDate,
        endDate,
        ObservationSourceType.wearable,
        ObservationSortBy.DESC,
        vital.code === Vital.sleep ? 'activity' : undefined,
        true,
      ),
    );
  });

  return promiseList;
};

const getSource = (item: Observation)  => {
  const extensions = item?.extension || [];
  const source = extensions.find((ext) => ext.url === EXTENSION_URLS.observationSource);
  return source?.valueString || '';
}

export const getFormattedVitalGraphData = (
  response: any[],
  vitalList: IVitalData[],
  dateRange: IDateRange,
  filteredEnabledVitalsForHomeMonitoringWithConfigs: ({
    type: GraphsTypes;
    code: Vital;
    dataOperation?: undefined;
  } | {
    type: GraphsTypes;
    code: Vital;
    dataOperation: GraphDataOperation;
  })[],
  filterType?: keyof typeof FilterTypes,
  ccmDate?: string,
  subFilterType?: VitalDataSubFilterMap,
  isTrendsView?: boolean
) => {
  const reponseObjects: VitalGraphData[] = [];
  response.forEach((res, index) => {
    let data = res?.data?.entry || [];
    const vital = filteredEnabledVitalsForHomeMonitoringWithConfigs[index].code;
    const graphType = filteredEnabledVitalsForHomeMonitoringWithConfigs[index].type;
    data = data.map((item: any) => item.resource);
    const dataOperation =
      filteredEnabledVitalsForHomeMonitoringWithConfigs[index]?.dataOperation;
    let vitalData = getFormattedVitalData(data, vitalList, undefined, undefined);

    if (!!dataOperation) {
      if (dataOperation === GraphDataOperation.LOW_EVENT) {
        vitalData = vitalData.filter((item) => {
          const value = parseInt(item.value);
          const vitalConfig = vitalList.find(
            (vitalConfig) => vitalConfig.loinc === vital
          );
          const lowEventValue = vitalConfig?.range?.[0]?.min;
          return lowEventValue ? value < lowEventValue : true;
        });
      }
    }


    const source = getSource(data[0]);

    const graphData = getHomeMonitoringGraphDataV2({
      vital,
      vitalData,
      vitalConfigs: vitalList,
      dateRange,
      filterType,
      dataOperation,
      ccmDate: filterType !== FilterTypes.Day ? ccmDate : undefined,
      subFilterType,
      isTrendView: isTrendsView
    });

    reponseObjects.push({
      ...graphData,
      ...(vital !== Vital.sleep && {graphType: graphType}),
      source,
    });
  });

  return reponseObjects;
};

export const getOnlyFormattedSleepVitalGraphData = (
  data: IObservation[],
  vitalList: IVitalData[],
  dateRange: IDateRange,
  vitalConfig: ({
    type: GraphsTypes;
    code: Vital;
    dataOperation?: undefined;
  } | {
    type: GraphsTypes;
    code: Vital;
    dataOperation: GraphDataOperation;
  }),
  filterType?: keyof typeof FilterTypes,
  ccmDate?: string,
  subFilterType?: VitalDataSubFilterMap
) => {
    const vitalData = getFormattedVitalData(data, vitalList, undefined, undefined);
    const source = getSource(data[0] as any);
    const graphData = getHomeMonitoringGraphDataV2({
      vital: vitalConfig?.code,
      vitalData,
      vitalConfigs: vitalList,
      dateRange,
      filterType,
      dataOperation: vitalConfig?.dataOperation as GraphDataOperation,
      ccmDate: filterType !== FilterTypes.Day ? ccmDate : undefined,
      subFilterType: subFilterType
    });
    return {
      ...graphData,
      source,
    };
};

export const getDateFilterTitle = ({
  currentView,
  startDate,
  endDate,
}: {
  currentView: keyof typeof FilterTypes;
  startDate?: Date;
  endDate?: Date;
}): string => {
  if (!startDate || !endDate) return '';
  const startDateObj = getMomentObj(startDate);
  const endDateObj = getMomentObj(endDate);
  switch (currentView) {
    case FilterTypes.Day:
      if (isTodayDate(startDateObj)) {
        return 'Today';
      }
      return startDateObj.format(DISPLAY_SLASH_DATE_FORMAT);
    case FilterTypes.Month:
    case FilterTypes.ThreeMonths:
    case FilterTypes.Week:
    case FilterTypes.SixMonths:
      return getTitleFromDateRange(startDateObj, endDateObj);
  }
  return '';
};

const getTitleFromDateRange = (
  startDate: moment.Moment,
  endDate: moment.Moment,
) => {
  return `${startDate.format(DISPLAY_SLASH_DATE_FORMAT)} - ${endDate.format(
    DISPLAY_SLASH_DATE_FORMAT
  )}`;
};

export const addOrUpdateSubstractTime = (params: {
  value: number;
  unit: DurationInputArg2;
  action: 'add' | 'subtract';
  selectedTabCode: string;
  currStartDate: Moment;
  currEndDate: Moment;
}) => {
  const {value, unit, action, selectedTabCode, currStartDate, currEndDate} =
    params;
  const dateRange = {} as IDateRange;
  dateRange.start = currStartDate[action](value, unit).toDate();
  dateRange.end = currEndDate[action](value, unit).toDate();
  return dateRange;
};

export const getStartAndEndDateBasedOnFilterAndAction = (
  selectedTabCode: string,
  currStartDate: Moment,
  currEndDate: Moment,
  action: 'add' | 'subtract'
) => {
  let dateRange = {} as IDateRange;
  switch (selectedTabCode) {
    case FilterTypes.Day:
      dateRange = addOrUpdateSubstractTime({
        currStartDate,
        currEndDate,
        action: action,
        value: 1,
        unit: 'day',
        selectedTabCode: selectedTabCode,
      });
      break;
    case FilterTypes.Week:
      dateRange = addOrUpdateSubstractTime({
        currStartDate,
        currEndDate,
        action: action,
        value: 1,
        unit: 'week',
        selectedTabCode: selectedTabCode,
      });
      break;
    case FilterTypes.Month:
      dateRange = addOrUpdateSubstractTime({
        currStartDate,
        currEndDate,
        action: action,
        value: 1,
        unit: 'month',
        selectedTabCode: selectedTabCode,
      });
      break;
    case FilterTypes.ThreeMonths:
      dateRange = addOrUpdateSubstractTime({
        currStartDate,
        currEndDate,
        action: action,
        value: 3,
        unit: 'month',
        selectedTabCode: selectedTabCode,
      });
      break;
      case FilterTypes.SixMonths:
      dateRange = addOrUpdateSubstractTime({
        currStartDate,
        currEndDate,
        action: action,
        value: 6,
        unit: 'month',
        selectedTabCode: selectedTabCode,
      });
      break;
    default:
      break;
  }

  return dateRange;
};

const getSlotRangeByDuration = (duration: number) => {
  const startOfRange = getMomentObj(new Date()).startOf('day');
  const endOfRange = getMomentObj(new Date()).endOf('day');

  const durationOfTimeSlot = duration;

  const timeSlots: string[] = [];
  timeSlots.push(startOfRange.toISOString());

  while (startOfRange.isBefore(endOfRange)) {
    const startTime = startOfRange
      .add(durationOfTimeSlot, 'hours')
      .toISOString();
    timeSlots.push(startTime);
  }
  timeSlots.push(endOfRange.toISOString());

  return timeSlots;
};

const getMinMaxValue = (data: (IVitals | IActivity)[]) => {
  let min = Number.MAX_VALUE;
  let max = Number.MIN_VALUE;
  data.forEach((item) => {
    const value =
      typeof item.value === 'number' ? item.value : parseInt(item.value);
    if (value < min) {
      min = value;
    }
    if (value > max) {
      max = value;
    }
  });

  return {min: min.toFixed(0), max: max.toFixed(0)};
};

const getAverageValue = (
  data: {
    x: string;
    y: string | number;
    dateStr: string;
  }[],
) => {
  return (
    data.reduce(
      (acc, item) =>
        acc + (typeof item.y === 'number' ? item.y : parseInt(item.y)),
      0,
    ) / data.length
  );
};


export const getAveragedGraphData = (
  vitalData: IVitals[],
  vital: Vital,
  params: IFilterParams,
  vitalConfig?: IVitalData,
  showDataOperation?: boolean,
  dataOperation?: GraphDataOperation,
): VitalGraphData => {
  const durationHours = 3;
  let matchedData = getVitalDataArray(vital, vitalData) || [];

  const config = HOME_MONITORING_VITALS.find((item) => item.loinc === vital);
  const graphConfig = enabledVitalsForHomeMonitoringWithConfigs.find((item) => item.code === vital && item.dataOperation === dataOperation);
  if (dataOperation === GraphDataOperation.LOW_EVENT) {
     matchedData = matchedData.filter((item) => {
      const intValue = parseInt(item.value);
      const minValue = config?.range?.[0]?.min || 0;
      return intValue < minValue;
    });
  }


  const timeSlots = getSlotRangeByDuration(durationHours);
  const highLightValues: IHighLightValues[] = [];

  const valueMapByTimeSlots: Map<string, number[]> = new Map();


  matchedData.forEach((data) => {
    const dataDate = getMomentObj(data.date);
    const startHour = dataDate.get('hour');
    const startMinute = dataDate.get('minute');
    const startSecond = dataDate.get('second');

    const newReadingDate = getMomentObj(new Date()).set({
      hour: startHour,
      minute: startMinute,
      second: startSecond,
    });
    // EDGE CASE TO CHECK for seconds
    const slot = timeSlots.find((slot) =>
      newReadingDate.isBetween(
        getMomentObj(slot),
        getMomentObj(slot).add(durationHours, 'hours'),
      ),
    );
    if (slot && data?.value) {
      const values = valueMapByTimeSlots.get(slot) || [];
      values.push(typeof data.value === 'string' ? parseInt(data.value) : data.value);
      valueMapByTimeSlots.set(slot, values);
    }
  });

  const graphData: {x: string; y: string | number; dateStr: string; label?: string}[] = [];
  valueMapByTimeSlots.forEach((values, key) => {
    const startTimeHour = getMomentObj(key).format('hh a');
    const endTimeHour = getMomentObj(key)
      .add(durationHours, 'hours')
      .format('hh a');
    const x = `${startTimeHour} - ${endTimeHour}`;
    const y = getDataByDataOperation(values,params.vital, dataOperation)
    if (dataOperation === GraphDataOperation.LOW_EVENT && y === 0) {
      return;
    }
    const value = parseInt(y.toFixed(0));
    graphData.push({
      x,
      y: value,
      dateStr: key,
      label:  value && graphConfig?.type === GraphsTypes.BAR ? `${value}` : undefined,
    });
  });


  if (dataOperation !== GraphDataOperation.LOW_EVENT) {
    const minMaxValues = getMinMaxValue(vitalData);
    if (minMaxValues.max !== minMaxValues.min) {
      highLightValues.push({
        value: `${minMaxValues.min} - ${minMaxValues.max}`,
        subValue: 'Range',
      });
    }
    const graphDataWithoutZero = matchedData.map((item) => ({
      x: item.date,
      y: parseInt(item.value),
      dateStr: item.date,
    }));

    highLightValues.unshift({
      value: getAverageValue(graphDataWithoutZero).toFixed(0),
      subValue: 'Avg',
    });
  }


  const vitalYAxisLabel = vitalConfig?.unit
    ? `${vitalConfig?.display} (${vitalConfig.unit})`
    : vitalConfig?.display;

  const oprTitle = dataOperationTitle(dataOperation);

  return {
    graphData,
    title: getGraphTitle(vitalConfig?.display || '', dataOperation, config?.loinc as Vital),
      // vitalConfig?.display + (showDataOperation ? `${oprTitle ? `(${oprTitle})` : ''}` : '') ||
      // '',
    loinc: vitalConfig?.loinc || '',
    code: vital,
    highLightValues: highLightValues,
    dataAvailability: params.filterType !== FilterTypes.Day ? getDataAvailableCountString(
      matchedData,
      params.dateRange,
    ) : '',
    vitalYAxisLabel: vitalYAxisLabel as string,
    dataType: DataType.VITAL,
    graphType:
      enabledVitalsForHomeMonitoringWithConfigs.find(
        (item) => item.code === vital,
      )?.type || GraphsTypes.LINE,
    dataOperation,
  };
};

export const getIconBasedOnVital = (vital: string, isLabGraphCard?: boolean) => {
  if (isLabGraphCard) {
    return LabTestTubeSvg;
  }
  switch (vital) {
    case Vital.bloodGlucose:
      return DropIcon;
    case Vital.bloodPressure:
      return BloodPressureIcon;
    case Vital.heartRate:
      return HeartIcon;
    case Vital.restingHeartRate:
      return HeartIcon;
    case Vital.heartRateVariability:
      return HeartIcon;
    case Vital.oxygenSaturation:
    case Vital.oxygenSaturationByPulseOximetry:
      return BloodOxygenIcon;
    case Vital.respirationRate:
      return RespirationRateIcon;
    case Vital.weight:
      return WeightIconSvg;
    case Vital.height:
      return HeightIconSvg;
    case Vital.headCircumference:
      return HeadCircumeferenceIconSvg;
    case Vital.bmi:
      return BMIIconSvg;
    case Vital.temperature:
      return BodyTemperatureIconSvg;
    case GRAPH_TYPES.Form:
      return ClinicalAssessmenticonSvg;
    case GRAPH_TYPES.Step:
    case MonitoringActivityCodes.steps:
      return WalkingIcon;
    case GRAPH_TYPES.Calories:
    case MonitoringActivityCodes.caloriesBurnt:
      return FlameIcon;
    case GRAPH_TYPES.Time:
    case MonitoringActivityCodes.activityDuration:
      return ClockIcon;
    case MonitoringActivityCodes.sleep:
      return SleepIcon;
    default:
      return DropIcon;
  }
};

const isActivityEnabled = (code: MonitoringActivityCodes, allowedWearableList: IAllowedWearableData[]) => allowedWearableList.some((wearable) => wearable.code === code && wearable.enabled);

export const getAcitivityPromiseList = (params: {
  patientId: string;
  locationId?: string;
  dateRange?: IDateRange;
  allowedWearableList: IAllowedWearableData[];
}) => {
  const {patientId, locationId, dateRange} = params;
  const activityDataPromisList = [];

  const startDate = dateRange?.start
    ? getMomentObj(dateRange?.start).format(VITAL_API_DATE_FORMAT)
    : undefined;
  const endDate = dateRange?.end
    ? getMomentObj(dateRange?.end).format(VITAL_API_DATE_FORMAT)
    : undefined;

    const dailyActivityTypesToFetch = [];
    const nonDailyActivityTypesToFetch = [];



  if (isActivityEnabled(MonitoringActivityCodes.caloriesBurnt, params.allowedWearableList)) {
    dailyActivityTypesToFetch.push(GRAPH_TYPES.Calories);
  }
  if (isActivityEnabled(MonitoringActivityCodes.steps, params.allowedWearableList)) {
    dailyActivityTypesToFetch.push(GRAPH_TYPES.Step);
  }
  if (isActivityEnabled(MonitoringActivityCodes.activityDuration, params.allowedWearableList)) {
    nonDailyActivityTypesToFetch.push(GRAPH_TYPES.Time);
  }
  if (dailyActivityTypesToFetch.length > 0) {
    activityDataPromisList.push(
      getActivityPromise({
        patientUuid: [patientId],
        startDate: startDate,
        endDate: endDate,
        types: dailyActivityTypesToFetch,
        isDaily: true,
        accountLocationUuid: locationId,
      })
    );
  }
  if (nonDailyActivityTypesToFetch.length > 0) {
    activityDataPromisList.push(
      getActivityPromise({
        patientUuid: [patientId],
        startDate: startDate,
        endDate: endDate,
        types: nonDailyActivityTypesToFetch,
        isDaily: false,
        accountLocationUuid: locationId,
      })
    );
  }

  return activityDataPromisList;
};

export const getFormattedActivityResponseData = (
  responseList: AxiosResponse<any, any>[],
  patientId: string,
  filterType: keyof typeof FilterTypes,
  allowedWearableList: IAllowedWearableData[],
  dateRange?: IDateRange,
  ccmDate?: string,
): VitalGraphData[] => {
  const dailyData = getPatientData(
    responseList?.[0]?.data || [],
    patientId,
    true,
  );
  const activityData = getPatientData(responseList?.[1]?.data || [], patientId);
  const activityDataMap: VitalGraphData[] = [];
  const allowedActivities = [];
  const isCalorieInAllowedAbility = allowedWearableList.find((wearable) => wearable.code === MonitoringActivityCodes.caloriesBurnt && wearable.enabled)
  const isStepsInAllowedAbility = allowedWearableList.find((wearable) => wearable.code === MonitoringActivityCodes.steps && wearable.enabled)
  const isDurationInAllowedAbility = allowedWearableList.find((wearable) => wearable.code === MonitoringActivityCodes.activityDuration && wearable.enabled)

  if(isCalorieInAllowedAbility) {
    allowedActivities.push({
      type: GRAPH_TYPES.Calories,
      loinc: MonitoringActivityCodes.caloriesBurnt,
    });
  }
  if(isStepsInAllowedAbility) {
    allowedActivities.push({
      type: GRAPH_TYPES.Step,
      loinc: MonitoringActivityCodes.steps,
    });
  }
  if(isDurationInAllowedAbility) {
    allowedActivities.push({
      type: GRAPH_TYPES.Time,
      loinc: MonitoringActivityCodes.activityDuration,
    });
  }
  allowedActivities.forEach(
    (item, index) => {
      const {type, loinc} = item;
      const dailyListData = getFormattedActivityData(dailyData, type, true);

      const activityListData = getFormattedActivityData(activityData, type);

      const data = [...dailyListData, ...activityListData];
      let unit = (data || []).find((element) => {
        return element.unit?.length > 0;
      })?.unit;

      if (type === GRAPH_TYPES.Step) {
        unit = undefined;
      }

      const obj = getActivityGraphData(
        data,
        type,
        filterType,
        dateRange,
        unit || '',
        ccmDate,
        loinc
      );
      activityDataMap.push(obj);
    },
  );
  return activityDataMap;
};

const getActivityAveragedGraphData = (
  data: IActivity[],
  type: Activity,
  dateRange?: IDateRange,
) => {
  const durationHours = 3;
  const timeSlots = getSlotRangeByDuration(durationHours);
  const highLightValues: IHighLightValues[] = [];
  const minMaxValues = getMinMaxValue(data);
  const valueMapByTimeSlots: Map<string, number[]> = new Map();

  if (minMaxValues.max !== minMaxValues.min) {
    highLightValues.push({
      value: `${minMaxValues.min} - ${minMaxValues.max}`,
      subValue: 'Range',
    });
  }
  const sortedData = data.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
  sortedData.forEach((item) => {
    const dataDate = getMomentObj(item.date);
    const startHour = dataDate.get('hour');
    const startMinute = dataDate.get('minute');
    const startSecond = dataDate.get('second');

    const newReadingDate = getMomentObj(new Date()).set({
      hour: startHour,
      minute: startMinute,
      second: startSecond,
    });
    // EDGE CASE TO CHECK for seconds
    const slot = timeSlots.find((slot) =>
      newReadingDate.isBetween(
        getMomentObj(slot),
        getMomentObj(slot).add(durationHours, 'hours'),
      ),
    );
    if (slot && item?.value) {
      const values = valueMapByTimeSlots.get(slot) || [];
      values.push(item.value);
      valueMapByTimeSlots.set(slot, values);
    }
  });

  const graphData: {x: string; y: number; dateStr: string; tooltipText: string}[] = [];
  const unit = type === GRAPH_TYPES.Step ? '' : data[0]?.unit

  valueMapByTimeSlots.forEach((values, key) => {
    const startTimeHour = getMomentObj(key).format('hh a');
    const endTimeHour = getMomentObj(key)
      .add(durationHours, 'hours')
      .format('hh a');
    const x = `${startTimeHour} - ${endTimeHour}`;
    const y = values.reduce((acc, item) => acc + item, 0);
    const roundedValue = Math.round(y);
    const tooltipText = `${roundedValue} ${unit ? unit : ''}`
    graphData.push({x, y: parseInt(y.toFixed(0)), dateStr: key, tooltipText});
  });

  const dataForAverage = graphData.map((item) => ({
    x: item.dateStr,
    y: parseInt(item.y.toFixed(0)),
    dateStr: item.dateStr,
  }));

  highLightValues.unshift({
    value: getAverageValue(dataForAverage).toFixed(0),
    subValue: 'Avg',
  });

  return graphData;
};

const getActivityGraphData = (
  data: IActivity[],
  type: Activity,
  filterType: keyof typeof FilterTypes,
  dateRange?: IDateRange,
  unit?: string,
  ccmDate?: string,
  loinc?: string
) => {
  let dates: string[] = [];
  let dataPlot: {
    x: string;
    y: number | string;
    dateStr: string;
    label?: string;
  }[] = [];
  let annontationIndex: number | undefined;
  const addIndexCorrection = ccmDate && dateRange ? !getMomentObj(ccmDate || '').isBetween(
    getMomentObj(dateRange?.start),
    getMomentObj(dateRange?.end),
  ) : false;
  switch (filterType) {
    case FilterTypes.Day:
      dates = getDataSetByDateRange(dateRange as IDateRange);
      dataPlot = getActivityAveragedGraphData(data, type, dateRange);
       annontationIndex =
    dataPlot.length && ccmDate
      ? getAnonationIndex(ccmDate as string, dataPlot)
      : undefined;
      break;
    case FilterTypes.Week:
      dates = getDataSetByDateRange(dateRange as IDateRange);
      dataPlot = getActivitySummedData(data, dates, filterType, 'day');
       annontationIndex =
    dataPlot.length && ccmDate
      ? getAnonationIndex(ccmDate, dataPlot, 'day', true) + (addIndexCorrection ? 0.25 : 0)
      : undefined;
      break;
    case FilterTypes.Month:
      dates = getDataSetByDateRange(dateRange as IDateRange);
      dataPlot = getActivitySummedData(data, dates, filterType, 'day');
      annontationIndex =
      dataPlot.length && ccmDate
        ? getAnonationIndex(ccmDate, dataPlot, 'day', true) + (addIndexCorrection ? 0.25 : 0)
        : undefined;
      break;
    case FilterTypes.ThreeMonths:
      dates = getDataSetByDateRange(dateRange as IDateRange, 'month');
      dataPlot = getActivitySummedData(data, dates, filterType, 'month');
      annontationIndex =
      dataPlot.length && ccmDate
        ? getAnonationIndex(ccmDate, dataPlot, 'month', true) + (addIndexCorrection ? 0.25 : 0)
        : undefined;
      break;
    case FilterTypes.SixMonths:
      dates = getDataSetByDateRange(dateRange as IDateRange, 'month');
      dataPlot = getActivitySummedData(data, dates, filterType, 'month');
       annontationIndex =
    dataPlot.length && ccmDate
      ? getAnonationIndex(ccmDate, dataPlot, 'month', true) + (addIndexCorrection ? 0.25 : 0)
      : undefined;
  }


  const isAllEmpty = dataPlot.every((item) => item.y === 0);

  const highLightValues: IHighLightValues[] = [];

  const total = dataPlot.reduce((acc, item) => acc + (item.y as number), 0);

  if (total) {
    highLightValues.push({
      value: `${total}`,
      subValue: 'Total',
    });
  }

  const title = getActivitiesGraphTitle(type);
  const source = data?.[0]?.source;

  const graphData: VitalGraphData = {
    graphType: GraphsTypes.BAR,
    graphData: isAllEmpty ? [] : dataPlot,
    title: getActivitiesGraphTitle(type),
    loinc: loinc || '',
    code: type,
    highLightValues,
    dataAvailability: filterType !== FilterTypes.Day ? getDataAvailableCountString(
      data,
      dateRange as IDateRange,
    ) : '',
    dataType: DataType.ACTIVITY,
    vitalYAxisLabel: `${title}` + (unit ? ` (${unit})` : ''),
    annontationIndex,
    displayUnit: unit,
    source: source
  };

  return graphData;
};

export const getFormatttedDateByFilterType = (
  date: string,
  filterType: keyof typeof FilterTypes,
) => {
  let format = '';

  switch (filterType) {
    case FilterTypes.Day:
      format = 'hh:mm a';
      break;
    case FilterTypes.Week:
      format = 'ddd';
      break;
    case FilterTypes.Month:
      format = 'MMM DD';
      break;
    case FilterTypes.ThreeMonths:
    case FilterTypes.SixMonths:
      format = 'MMM';
      break;
  }

  return getMomentObj(date).format(format);
};

const getActivitySummedData = (
  data: IActivity[],
  dates: string[],
  filterType: keyof typeof FilterTypes,
  groupBy: DurationInputArg2,
) => {
  const dataPlot: {
    x: string;
    y: number | string;
    dateStr: string;
    label?: string;
  }[] = [];
  dates.forEach((date) => {
    const dataForCurrentDate: number[] = [];

    data
      .filter((item) => typeof item.value === 'number')
      .forEach((item) => {
        const recordDate = getMomentObj(item.date);
        if (recordDate.isSame(date, groupBy)) {
          dataForCurrentDate.push(item.value);
        }
      });

    if (dataForCurrentDate.length === 0) {
      dataPlot.push({
        x: getFormatttedDateByFilterType(date, filterType),
        y: 0,
        dateStr: date,
        label: '',
      });
      return;
    }

    const sum = dataForCurrentDate.reduce((acc, item) => acc + item, 0);
    const value = sum === 0 ? '0' : sum.toFixed(0);
    dataPlot.push({
      x: getFormatttedDateByFilterType(date, filterType),
      y: parseInt(value),
      dateStr: date,
      label: value ? `${value}` : undefined,
    });
  });

  return dataPlot;
};

const getDataSetByDateRange = (
  dateRange: IDateRange,
  addUnit?: DurationInputArg2,
) => {
  const dataSet = [];
  const startDate = getMomentObj(dateRange.start);
  const endDate = getMomentObj(dateRange.end);
  while (startDate.isSameOrBefore(endDate)) {
    dataSet.push(startDate.toISOString());
    startDate.add(1, addUnit || 'day');
  }
  return dataSet;
};

export const getHomeMonitoringGraphDataV2 = (params: IFilterParams) => {
  let data: VitalGraphData = {} as VitalGraphData;
  switch (params.vital) {
    case Vital.bloodGlucose:
        data = getAveragedGraphData(
          params.vitalData,
          params.vital,
          params,
          params.vitalConfigs.find((item) => item.loinc === params.vital),
          true,
          params.dataOperation,
        ) as VitalGraphData;
      break;
    case Vital.heartRate:
    case Vital.restingHeartRate:
    case Vital.heartRateVariability:
    case Vital.oxygenSaturation:
    case Vital.sleep:
      data = getGraphDataWithCustomXAxisByFilterType(params);
      break;
    default:
      data = {
        graphType: GraphsTypes.LINE,
        graphData: [],
        title: '',
        loinc: '',
        code: params.vital,
        highLightValues: [],
        dataAvailability: '',
        dataType: DataType.VITAL,
        vitalYAxisLabel: '',
      };
  }

  return data;
};

const groupVitalDataByTimeUnit = (
  vitalData: IVitals[],
  timeUnit: DurationInputArg2,
) => {
  const dataMap = new Map<string, number[]>();
  vitalData?.forEach((item) => {
    const date = getMomentObj(item.date);
    const key = date.startOf(timeUnit).toISOString();
    const value = dataMap.get(key) || [];
    value.push(parseInt(parseInt(item.value).toFixed(0)));
    dataMap.set(key, value);
  });

  return dataMap;
};

const getDataByDataOperation = (values:number[], vital: Vital, dataOperation?: GraphDataOperation):number => {
  const valueList = values.filter((item) => typeof item === 'number' ? (item > 0) : parseInt(item) > 0);
  switch (dataOperation) {
    case GraphDataOperation.LOW_EVENT:
      const vitalConfig = HOME_MONITORING_VITALS.find(
        (item) => item.loinc === vital,
      );
      const lowEventValue = vitalConfig?.range?.[0]?.min;
      return values.filter(value => lowEventValue ? value < lowEventValue : true).length;
    case GraphDataOperation.AVERAGE:
      return valueList.reduce((acc, item) => acc + item, 0) / valueList.length
    default:
      return valueList.reduce((acc, item) => acc + item, 0) / valueList.length;
  }

}

const dataOperationTitle = (dataOperation?: GraphDataOperation) => {
  switch (dataOperation) {
    case GraphDataOperation.LOW_EVENT:
      return 'Low Event';
    case GraphDataOperation.AVERAGE:
      return 'Daily Average';
    default:
      return '';
  }
}

const getGroupedVitalGraphData = (params: {
  vital: Vital;
  vitalData: IVitals[];
  vitalConfigs: IVitalData[];
  ccmDate?: string;
  dateRange: IDateRange;
  groupBy: DurationInputArg2;
  filterType?: keyof typeof FilterTypes;
  dataOperation?: GraphDataOperation;
}): VitalGraphData => {
  const isSleep = params.vital === Vital.sleep;
  if (isSleep) {
    return getSleepGraphData({...params});
  }
  const sortedData = params.vitalData.length
    ? getVitalDataArray(params.vital, params.vitalData)
    : [];
  const groupedDataByDays = groupVitalDataByTimeUnit(
    sortedData,
    params.groupBy,
  );
  const oprTitle = dataOperationTitle(params.dataOperation);
  const dataSetTime = getDataSetByDateRange(params.dateRange, params.groupBy);
  const graphData: {x: string; y: string | number; dateStr: string}[] = [];
  const vitalConfig = params.vitalConfigs.find(
    (item) => item.loinc === params.vital,
  );

  dataSetTime.forEach((date) => {
    const key = Array.from(groupedDataByDays.keys()).find((item) => {
      return getMomentObj(item).isSame(date, params.groupBy);
    })
    if (!key) {
      graphData.push({
        x: getFormatttedDateByFilterType(
          date,
          params.filterType as keyof typeof FilterTypes,
        ),
        y: 0,
        dateStr: date,
      });
      return;
    }
    const values = groupedDataByDays.get(key) || [];
    const avg = values?.length
      ? getDataByDataOperation(values,params.vital, params.dataOperation)
      : 0;
    graphData.push({
      x: getFormatttedDateByFilterType(
        date,
        params.filterType as keyof typeof FilterTypes,
      ),
      y: parseInt(avg.toFixed(0)),
      dateStr: date,
    });
  });
  const isAllEmpty = graphData.every((item) => item.y === 0);

  const minMaxValues = isAllEmpty ? {min: 0, max: 0} : getMinMaxValue(sortedData);
  const highLightValues: IHighLightValues[] = [];
  const graphDataWithoutZero = sortedData.map((item) => ({
    x: item.date,
    y: parseInt(item.value),
    dateStr: item.date,
  }));

 if (params.dataOperation !== GraphDataOperation.LOW_EVENT) {
  highLightValues.push({
    value: isAllEmpty ? '--' : getAverageValue(graphDataWithoutZero).toFixed(0),
    subValue: 'Avg',
  });

  if (minMaxValues.max !== minMaxValues.min) {
    highLightValues.push({
      value: `${minMaxValues.min} - ${minMaxValues.max}`,
      subValue: 'Range',
    });
  }
}

  const graphType =
    enabledVitalsForHomeMonitoringWithConfigs.find(
      (item) => item.code === params.vital,
    )?.type || GraphsTypes.LINE;

    const addIndexCorrection = params.ccmDate ? !getMomentObj(params.ccmDate || '').isBetween(
      getMomentObj(params.dateRange.start),
      getMomentObj(params.dateRange.end),
      params.groupBy,
    ) : false;

  const annontationIndex = params?.ccmDate
    ? getAnonationIndex(params?.ccmDate as string, graphData, params.groupBy, true) + (addIndexCorrection ? 0.25 : 0)
    : undefined;
  return {
    graphType,
    graphData: isAllEmpty ? [] : graphData,
    title: getGraphTitle(vitalConfig?.display || '', params.dataOperation, params.vital) || '',
    loinc: vitalConfig?.loinc || '',
    code: params.vital,
    highLightValues,
    dataAvailability:
      params.filterType !== FilterTypes.Day
        ? getDataAvailableCountString(sortedData, params.dateRange)
        : '',
    dataType: DataType.VITAL,
    vitalYAxisLabel: `${vitalConfig?.display} (${vitalConfig?.unit})`,
    annontationIndex,
    dataOperation: params?.dataOperation,
  };
};

const getGraphDataWithCustomXAxisByFilterType = (params: IFilterParams) => {
  const {vital, vitalData, vitalConfigs} = params;

  switch (params.filterType) {
    case FilterTypes.Day:
      if (vital === Vital.sleep) {
        return getSleepGraphData({...params, groupBy: 'day'});
      }
      return getAveragedGraphData(
        vitalData,
        vital,
        params,
        vitalConfigs.find((item) => item.loinc === vital),
        true,
        params.dataOperation
      ) as VitalGraphData;
    case FilterTypes.Week:
      return getGroupedVitalGraphData({...params, groupBy: 'day'});
    case FilterTypes.Month:
      if (vital === Vital.sleep) {
        return getSleepGraphData({...params, groupBy: 'day'});
      }
      return getGroupedVitalGraphData({...params, groupBy: 'week'});
    case FilterTypes.ThreeMonths:
    case FilterTypes.SixMonths:
      if (vital === Vital.sleep) {
        return getSleepGraphData({...params, groupBy: 'day'});
      }
      return getGroupedVitalGraphData({...params, groupBy: 'month'});
    default:
      return getAveragedGraphData(
        vitalData,
        vital,
        params,
        vitalConfigs.find((item) => item.loinc === vital),
        false,
      ) as VitalGraphData;
  }
};


export const getLatestValue = (
  data: {
    x: string;
    y: string | number;
    dateStr: string;
  }[],
) => {
  const filteredData = data.filter((item) => item.y !== 0);
  return filteredData.length ? filteredData[filteredData.length - 1].y : '--';
}

export const updateAllListSequenceInAscendingOrder = (
  settings: IWearableSettingListItem[]
) => {
  return settings.map((setting, index) => {
    setting.sequence = index + 1;
    return setting;
  });
};

export const sortSettings = (settings: IWearableSettingListItem[]) => {
  return settings.sort((a, b) => {
    if(a.sequence && b.sequence){
      return a.sequence - b.sequence
    }
    return 1
  })
}

export const getFilteredPinnedGraphList = (filterParams: IWearableDataFilterParams) => {
  const {wearableSetting, graphList, filterDataType} = filterParams;

  const filteredGraphList: VitalGraphData[] = [];

  const key = filterDataType === DataType.ACTIVITY ? AllowedWearableDataCategories.activity : AllowedWearableDataCategories.biomarkers;

  let wearableSettings = wearableSetting[key] || [];

  wearableSettings = wearableSettings.filter(
    (wearable) => wearable.isSelected
  );

  sortSettings(wearableSettings)?.map((wearableSetting)=> {
    const graphData = graphList.filter((graph)=> graph.loinc === wearableSetting.code) || [];
    if(graphData){
      filteredGraphList.push(...graphData)
    }
  });

  return filteredGraphList;
}

export const getFilteredGraphList = (filterParams: IWearableDataFilterParams) => {

  const filteredGraphListByDataType = filterParams.graphList.filter(
    (item) => {
      if(filterParams.filterEmptyGraphData){
        return item.dataType === filterParams.filterDataType && item.graphData.length > 0;
      }
      else {
        return item.dataType === filterParams.filterDataType;
      }
    }
  );

  if (
    filterParams.selectedFilterTab ===
    CINICAL_SECTIONS_FILTER_TAB_CODES.RELEVANT
  ) {
    return getFilteredPinnedGraphList({...filterParams, graphList: filteredGraphListByDataType});
  } else {
    return filteredGraphListByDataType;
  }
};


export const getFilteredPinnedWearableList = (wearableSetting: IWearableSetting, allowedWearableList: IAllowedWearableData[]) => {
  const filteredWearableList: IAllowedWearableData[] = [];

  Object.keys(wearableSetting).map((key, index) => {
    let wearableSettings = wearableSetting[key];

    wearableSettings = wearableSettings.filter(
      (wearable) => wearable.isSelected
    );

    wearableSettings?.map((wearableSetting)=> {
      const allowedWearableSetting = allowedWearableList.find((graph)=> graph.code === wearableSetting.code);
      if(allowedWearableSetting){
        filteredWearableList.push(allowedWearableSetting);
      }
    });
  });

  return filteredWearableList;
}


export const checkWearableSettingExist = (wearableSetting: IWearableSetting, allowedWearableList: IAllowedWearableData[]) => {
  let isExist = false;
  const allowedLoincCodes = allowedWearableList
    .filter((allowedWearable) => allowedWearable.enabled)
    ?.map((allowedWearable) => allowedWearable.code) || [];


  Object.keys(wearableSetting).map((key, index) => {
    let wearableSettings = wearableSetting[key];

    wearableSettings = wearableSettings?.filter(
      (wearable) => wearable.isSelected && allowedLoincCodes.includes(wearable.code)
    );

    if(wearableSettings.length > 0){
      isExist = true;
    }
  });
  return isExist;
}


export const getLastRecordedDataMapKey = (data: {
  code: Vital | GRAPH_TYPES;
  dataOperation?: GraphDataOperation;
}) => {
  const {code, dataOperation} = data;
  let key = `${code}`;

  if (dataOperation) {
    key += `_${dataOperation}`;
  }

  return key;
};
export const getGraphTitle = (
  existingTitle: string,
  dataOperation: GraphDataOperation | undefined,
  vital: Vital | GRAPH_TYPES
) => {
  switch (vital) {
    case Vital.bloodGlucose:
      if (dataOperation === GraphDataOperation.LOW_EVENT) {
        return `Low Glucose Events`;
      } else {
        return 'Average Blood Glucose';
      }
    case Vital.duration:
      return 'Average Duration';
      default:
      return existingTitle;
  }
};
