import React, { useCallback, useEffect, useState } from 'react'
import { View } from 'native-base';
import { ColorSelector } from '../../../../Contacts/Tags/TagsModal/ColorSelector';
import { COLOR_SELECTOR_VIEW_CODES } from '../../../../Contacts/Tags/TagsModal/ColorSelector/ColorSelector';
import { DEFAULT_COLOR, DEFAULT_COLORS } from './Helper';
import { IFormComponentProps } from '../CustomWrapper/CustomWrapper';
import { IFormValidationOutput } from '../CustomWrapper/interfaces';
import { IColorPickerState } from './interfaces';

const ColorPicker = (props: IFormComponentProps) => {
  const { component, disabled } = props;
  const [componentState, setComponentState] = useState<IColorPickerState>({
    value: DEFAULT_COLOR,
    showErrors: false,
  });

  const setValue = useCallback((value: any) => {
    setComponentState((prev) => {
      return {
        ...prev,
        value: value || DEFAULT_COLOR,
      };
    });
  }, []);

  const validateData = (currentData: string): IFormValidationOutput => {
    setComponentState((prev) => ({...prev, showErrors: true}));
    if (props.component?.validate?.required) {
      return { isValid: !!currentData, message: !currentData ? `${component.label} is required` : '' };
    }
    return {isValid: true, message: ''};
  };

  props.setValueRef.current = setValue;
  props.validateRef.current = validateData;

  useEffect(() => {
    props.onChange(componentState.value);
  }, [componentState.value]);

  return (
    <View width={'full'}>
      <ColorSelector
        customStyle={{ width: '100%' }}
        viewCode={COLOR_SELECTOR_VIEW_CODES.COLOR_AND_NAME}
        color={componentState.value}
        colorList={DEFAULT_COLORS}
        onBtnPress={(color: string) => {
          setComponentState((prev) => ({...prev, value: color}));
        }}
        onChangeHex={(text: any) => {
          setComponentState((prev) => ({...prev, value: `#${text}`}));
        }}
      />
    </View>
  )
}

export default ColorPicker
