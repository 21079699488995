import {Colors} from '../../../styles';

const ArrowRightDiagonalSvg = (props?: {color: string}) => {
    return (
        <svg width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 11L11 1M11 1H1M11 1V11" stroke={props?.color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default ArrowRightDiagonalSvg