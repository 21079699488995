import {PERSON_TYPES} from "../../../constants";

export const getActiveContactTypeFormList = (contactTypeList: any, hideCustomerType?: boolean) => {
  return contactTypeList.filter((contactType: any) => {
    if (contactType?.isActive){
      if (contactType?.code === PERSON_TYPES.CUSTOMER && hideCustomerType) {
        return false;
      }
      return true;
    }
    return false;
  }) || []
}