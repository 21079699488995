export const WORKFLOW_INPUT_FIELD_KEYS = {
  ENTITY: 'entity',
  TRIGGER: 'trigger',
  FREQUENCY: 'frequency',
  FREQUENCY_OBJECT: 'frequencyObject',
  DURATION: 'duration',
  NOTE: 'note',
  COUNT: 'count',
  TYPE: 'type',
  CHANNEL: 'channel',
  GAP_BETWEEN: 'gapBetween',
  WORKFLOW: 'workflow'
};

export const CARE_JOURNEY_DISABLE_NODES_ON_PROD = [ 'CareJourneyImmunization', 'CareJourneyLabTest', 'CareJourneyMedication' ];
