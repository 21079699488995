import {AutoComplete, Form, Select, Spin} from "antd";
import {TextInput, View, Text, Pressable} from "react-native";
import {Colors} from "../../../../styles";
import {BOOLEAN_OPTION, CUSTOM_ATTRIBUTE_DATA_TYPES, CUSTOM_FIELD_TOAST_ERROR_DURATION, CUSTOM_FIELD_TOAST_ERROR_DURATION_LONG, INVALID_DATE} from "./CustomFieldConst";
import {DATE_FORMATS, PATIENT_QUERRY_DATE_FROMATED} from "../../../../constants";
import {ModalActionDatePicker} from "../../../common/ModalActionCommonComponent/ModalActionDatePicker";
import moment from "moment";
import {COMMON_ACTION_CODES} from "../../../../constants/ActionConst";
import {useEffect, useRef, useState} from "react";
import {ICustomFieldValueView, ICustomFieldViewRef, ICustomFieldsValueView, ICustomToast, IFormattedCustomFieldsData, IGetFieldByDataType} from "./interface";
import AntDesign from "react-native-vector-icons/AntDesign";
import {getDateObjectFromAPIFormatToMyFormat} from "../../../../utils/DateUtils";
import {LoadingOutlined} from "@ant-design/icons";
import {getCustomFieldValueById} from "./CustomFieldService";
import {getFormattedCustomAttributesSuggestedValues} from "./CustomFieldUtils";
import {ToastType} from "../../../../utils/commonViewUtils";
import {useToast} from "../../../Toast/ToastProvider";
import {useIntl} from "react-intl";
import "./CustomFieldStyle.css"

export const CustomFieldsValueView = (props: ICustomFieldValueView) => {
  const { customFieldsValue, onActionPerformed, customFieldsError, loading } = props;
  const [form] = Form.useForm();
  const customFieldViewRef = useRef<ICustomFieldViewRef>({} as any);
  const toast = useToast();
  const intl = useIntl();
  const { Option } = Select;

  const [stateData, setStateData] = useState<ICustomFieldsValueView>({
    searchString: '',
    customAttributesSuggestedValues: [],
    selectedCustomAttributeId: '',
    customAttributeSuggestedValueLoading: false,
  })

  const showToast = (toastData: ICustomToast) => {
    toast({
      toastType: toastData?.toastType,
      message: toastData?.message,
      duration: toastData?.duration || CUSTOM_FIELD_TOAST_ERROR_DURATION,
      closeAllPrevToast: toastData?.closeAllPrevToast || false,
    })
  }

  const getCustomAttributeSuggestedValues = async (searchString: string) => {
    setStateData((prev) => {
      return {
        ...prev,
        customAttributeSuggestedValueLoading: true,
      }
    })
    try {
      const selectedCustomAttributeId = stateData?.selectedCustomAttributeId;
      const params = {
        searchQuery: searchString
      }
      if (searchString && selectedCustomAttributeId) {
        const response = await getCustomFieldValueById(params, selectedCustomAttributeId);
        if (response?.data?.length) {
          const formattedData = getFormattedCustomAttributesSuggestedValues(response?.data);
          setStateData((prev) => {
            return {
              ...prev,
              customAttributesSuggestedValues: formattedData,
              customAttributeSuggestedValueLoading: false,
            }
          })
        } else {
          setStateData((prev) => {
            return {
              ...prev,
              customAttributesSuggestedValues: [],
              customAttributeSuggestedValueLoading: false,
            }
          })
        }
      } else {
        setStateData((prev) => {
          return {
            ...prev,
            customAttributesSuggestedValues: [],
            customAttributeSuggestedValueLoading: false,
          }
        })
      }
    } catch(error: any) {
      setStateData((prev) => {
        return {
          ...prev,
          customAttributeSuggestedValueLoading: false,
        }
      })
      showToast({
        toastType: ToastType.error,
        message: error.response.data.message || intl.formatMessage({id: 'apiErrorMsg'}),
        duration: CUSTOM_FIELD_TOAST_ERROR_DURATION_LONG,
        closeAllPrevToast: true,
      });
    }
  }

  const onSearch = (value: string, id: string) => {
    onActionPerformed(COMMON_ACTION_CODES.CUSTOM_FIELD_VALUE_CHANGE, {
      id: id,
      fieldValue: value
    })
    setStateData((prev) => ({
      ...prev,
      searchString: value,
    }));
  }

  const setCustomAttributeIdForSearch = (customAttributeId: string) => {
    setStateData((prev) => {
      return {
        ...prev,
        selectedCustomAttributeId: customAttributeId,
        customAttributesSuggestedValues: [],
      }
    })
  }

  const setSelectedValueForAttribute = (value: string, id: string) => {
    onActionPerformed(COMMON_ACTION_CODES.CUSTOM_FIELD_VALUE_CHANGE, {
      id: id,
      fieldValue: value
    })
    setStateData((prev) => {
      return {
        ...prev,
        searchString: '',
      }
    })
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getCustomAttributeSuggestedValues(stateData?.searchString);
    }, 300);
    return () => clearTimeout(delayDebounceFn);
  }, [stateData?.searchString]);

  const getFieldByDataType = (customFieldData: IGetFieldByDataType) => {
    const { type, value, label, id, errors, customAttributeId } = customFieldData
    let ele = <></>;
    if (
      type === CUSTOM_ATTRIBUTE_DATA_TYPES.TEXT ||
      type === CUSTOM_ATTRIBUTE_DATA_TYPES.EMAIL
    ) {
      ele = <Form.Item
      label={label}
      validateStatus={errors?.[id] ? 'error' : ''}
    >
      <AutoComplete
        ref={(ref) =>
          (customFieldViewRef.current[id] = ref as HTMLInputElement | any)
        }
        className="select-custom-field"
        value={value}
        placeholder={type === CUSTOM_ATTRIBUTE_DATA_TYPES.EMAIL ? "Enter valid email" : "Enter text"}
        onSelect={(value: string) => {
          setSelectedValueForAttribute(value, id);
        }}
        onFocus={()=>{
          setCustomAttributeIdForSearch(customAttributeId);
        }}
        onBlur={()=> {
          setCustomAttributeIdForSearch(customAttributeId);
        }}
        onSearch={(text: string)=> {
          onSearch(text, id);
        }}
      >
        {stateData?.customAttributesSuggestedValues?.map((field: any,index: number) => {
          return (
            <>
              <Option key={field?.code} value={field?.value}>
                {field?.label}
              </Option>
            </>
          )
        })}
      </AutoComplete>
      {(stateData?.customAttributeSuggestedValueLoading && stateData?.selectedCustomAttributeId === customAttributeId) && <View style={{
        position: 'absolute',
        right: 5,
        top: 5,
      }}>
        <Spin size='small' indicator={<LoadingOutlined style={{ fontSize: 16, color: Colors.Custom.Gray300 }} spin />} />
      </View>}
      {errors?.[id] && <div style={{ color: Colors.Custom.ErrorColor }}>
        {errors?.[id]}
      </div>}
    </Form.Item>
    } else if (
      type === CUSTOM_ATTRIBUTE_DATA_TYPES.NUMBER ||
      type === CUSTOM_ATTRIBUTE_DATA_TYPES.PHONE ||
      type === CUSTOM_ATTRIBUTE_DATA_TYPES.NPI
    ) {
      if (type === CUSTOM_ATTRIBUTE_DATA_TYPES.NPI) {
        ele = <Form.Item
        label={label}
        validateStatus={errors?.[id] ? 'error' : ''}
      >
        <AutoComplete
          ref={(ref) =>
            (customFieldViewRef.current[id] = ref as HTMLInputElement | any)
          }
          className="select-custom-field"
          value={value}
          placeholder={"Enter valid NPI number" }
          onSelect={(value: string) => {
            setSelectedValueForAttribute(value, id);
          }}
          onFocus={()=>{
            setCustomAttributeIdForSearch(customAttributeId);
          }}
          onBlur={()=> {
            setCustomAttributeIdForSearch(customAttributeId);
          }}
          onSearch={(text: string)=> {
            const validateRegex = /[^0-9]/g;
            const numericValue = text?.replace(validateRegex, '');
            customFieldViewRef.current[id].value = numericValue;
            onSearch(numericValue, id);
          }}
        >
          {stateData?.customAttributesSuggestedValues?.map((field: any,index: number) => {
            return (
              <>
                <Option key={field?.code} value={field?.value}>
                  {field?.label}
                </Option>
              </>
            )
          })}
        </AutoComplete>
        {(stateData?.customAttributeSuggestedValueLoading && stateData?.selectedCustomAttributeId === customAttributeId) && <View style={{
          position: 'absolute',
          right: 5,
          top: 5,
        }}>
          <Spin size='small' indicator={<LoadingOutlined style={{ fontSize: 16, color: Colors.Custom.Gray300 }} spin />} />
        </View>}
          {errors?.[id] && <div style={{ color: Colors.Custom.ErrorColor }}>
            {errors?.[id]}
          </div>}
        </Form.Item>
      } else {
        ele = <Form.Item
        label={label}
        validateStatus={errors?.[id] ? 'error' : ''}
      >
        <TextInput
          ref={(ref) =>
            (customFieldViewRef.current[id] = ref as HTMLInputElement | any)
          }
          style={{
            borderWidth: 1,
            borderColor: Colors.Custom.Gray200,
            borderRadius: 6,
            height: 44,
            width: '100%',
            paddingLeft: 10,
          }}
          onChangeText={(text) => {
            const validateRegex = type === CUSTOM_ATTRIBUTE_DATA_TYPES.NUMBER ? /[^0-9.]/g  : /[^0-9]/g;
            const numericValue = text?.replace(validateRegex, '');
            customFieldViewRef.current[id].value = numericValue;
            onActionPerformed(COMMON_ACTION_CODES.CUSTOM_FIELD_VALUE_CHANGE, {
              id: id,
              fieldValue: numericValue
            })
          }}
          value={value}
          placeholder={type === CUSTOM_ATTRIBUTE_DATA_TYPES.PHONE ? "Enter valid phone" : "Enter number"}
          placeholderTextColor={Colors.Custom.Gray300}
        />
        {errors?.[id] && <div style={{ color: Colors.Custom.ErrorColor }}>
          {errors?.[id]}
        </div>}
      </Form.Item>
      }
    } else if (type === CUSTOM_ATTRIBUTE_DATA_TYPES.DATE) {
      ele = <View style={{
        marginBottom: 16
      }}>
        <Text style={{
          color: Colors.Custom.Gray500,
          fontWeight: '400',
          fontSize: 14,
          marginLeft: 4,
          marginBottom: 10,
        }}>
          {label}
        </Text>
        <ModalActionDatePicker
          className="date-picker-background-color"
          changeBackground={true}
          value={value ? moment(value) : undefined}
          format={DATE_FORMATS.CONVERSATION_DATE_PICKER}
          placeholder={DATE_FORMATS.CONVERSATION_DATE_PICKER}
          onChange={(date: any, dateString: string) => {
            const formattedDateStr = getDateObjectFromAPIFormatToMyFormat(dateString, PATIENT_QUERRY_DATE_FROMATED)
            onActionPerformed(COMMON_ACTION_CODES.CUSTOM_FIELD_VALUE_CHANGE, {
              id: id,
              fieldValue: formattedDateStr === INVALID_DATE ? '' : formattedDateStr,
            })
          }}
          customStyle={{
            borderRadius: 6,
            marginBottom: 10,
          }}
          extraStyle={{
            flex: 1.0,
          }}
        />
        {errors?.[id] && <div style={{ color: Colors.Custom.ErrorColor }}>
          {errors?.[id]}
        </div>}
      </View>
    } else if (type === CUSTOM_ATTRIBUTE_DATA_TYPES.BOOLEAN) {
      ele = <Form.Item
        label={label}
      >
        <Select
          style={{
            borderRadius: 6,
            width: '100%'
          }}
          defaultValue={value ? value : undefined}
          placeholder="Select value"
          options={BOOLEAN_OPTION}
          onSelect={(value) => {
            onActionPerformed(COMMON_ACTION_CODES.CUSTOM_FIELD_VALUE_CHANGE, {
              id: id,
              fieldValue: value
            })
          }}
        />
        {errors?.[id] && <div style={{ color: Colors.Custom.ErrorColor }}>
          {errors?.[id]}
        </div>}
      </Form.Item>
    }
    return ele;
  }
  return (
    <>
      {customFieldsValue?.length && customFieldsValue?.map((customField: IFormattedCustomFieldsData) => {
        const customFieldData = {
          type: customField?.customAttribute?.datatype,
          value: customField?.value,
          label: customField?.customAttribute?.label,
          id: customField?.id || customField?.tempId || '',
          errors: customFieldsError,
          customAttributeId: customField?.customAttributeId
        }
        return (
          <Form
            className="custom-form"
            form={form}
            layout="vertical"
            labelCol={{
              style: {
                paddingLeft: 3,
              }
            }}
          >
            <View style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}>
              <View style={{
                flex: 0.96
              }}>
                {getFieldByDataType(customFieldData)}
              </View>
              <Pressable
                style={{
                  alignItems: 'center',
                  alignSelf: 'center'
                }}
                onPress={()=> {
                  onActionPerformed(COMMON_ACTION_CODES.DELETE, {
                    id: customField?.id,
                    tempId: customField?.tempId
                  })
                }}
              >
                {!loading?.[customFieldData?.id] ? <AntDesign style={{
                    marginTop: 10,
                    color: Colors.Custom.Gray400
                  }}
                  size={18}
                  name="close"
                />: <Spin style={{
                  marginTop: 10,
                  color: Colors.Custom.Gray400
                }}/>}
              </Pressable>
            </View>
          </Form>
        )
      })}
    </>
  )
}