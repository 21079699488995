import {Text, View} from 'native-base';
import React from 'react';
import {Colors} from '../../../styles';
import {DisplayText} from '../DisplayText/DisplayText';
import {svgStyles} from './SvgStyles';

const NoAllergiesSvg = (props: {titleId?: string; fillColor?: string}) => {
  const fillColor = props.fillColor || Colors.primary['300'];
  return (
    <View style={svgStyles.container}>
      <svg
        width="76"
        height="71"
        viewBox="0 0 76 71"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.28 66.67H59.71C60.93 66.67 62.11 66.21 63.01 65.38C66.75 61.89 69.75 57.67 71.81 52.98C73.87 48.29 74.96 43.22 75 38.09C75.19 17.22 58.33 0.0499885 37.59 -1.14744e-05C16.84 -0.0500115 0 16.86 0 37.73C0 42.92 1.06 48.06 3.12 52.82C5.18 57.58 8.21 61.86 12 65.39C12.9 66.22 14.07 66.68 15.29 66.67H15.28Z"
          fill="#FCFDFD"
        />
        <path
          d="M43.74 7.14C42.5 6.6 38.86 5.9 37.2 5.88C35.54 5.89 31.9 6.6 30.66 7.14C24.9 9.65 24.83 14.51 24.83 22.09H49.57C49.57 14.51 49.5 9.65 43.74 7.14Z"
          fill="#E4E7EC"
        />
        <path
          d="M42.27 37.28H32.39C32.39 37.28 33.9 45.61 26.71 52.5L36.85 64.39H37.8L47.94 52.5C40.75 45.61 42.26 37.28 42.26 37.28H42.27Z"
          fill="#FCC083"
        />
        <path
          d="M42.27 37.28H32.39C32.39 37.28 33.12 41.34 31.12 46.13C35.25 44.57 38.54 41.27 42.17 38.24C42.21 37.63 42.27 37.27 42.27 37.27V37.28Z"
          fill="#E8AA63"
        />
        <path
          d="M31.8 43.71C31.1 46.3 29.99 49.83 27.18 52.6L36.89 64.33H37.8L47.51 52.6C44.71 49.83 43.43 46.3 42.73 43.71L41.91 44.45C39.22 46.88 35.12 46.84 32.48 44.35L31.8 43.71Z"
          fill="#ECEDED"
        />
        <path
          d="M49.96 21.53C49.59 20.58 48.94 21.07 48.44 21.7C48.58 14.29 44.67 10.53 44.67 10.53H29.98C29.98 10.53 26.04 14.28 26.17 21.64C25.67 21.04 25.05 20.6 24.69 21.53C24.02 23.25 25.59 25.61 25.29 28C25.09 29.62 26.36 29.46 27.17 29C27.47 30.22 27.93 31.67 28.65 33.35C30.19 36.92 34.59 40.17 37.3 40.17C40.01 40.17 44.35 36.89 45.95 33.35C46.69 31.72 47.15 30.37 47.44 28.97C48.24 29.44 49.56 29.63 49.36 27.99C49.06 25.6 50.63 23.24 49.96 21.52V21.53Z"
          fill="#F2F4F7"
        />
        <path
          d="M58.81 50.31C56.97 47.03 42.73 43.72 42.73 43.72C42.73 43.72 42.5 44.52 42.13 45.79C40.7 50.67 33.78 50.65 32.38 45.76C32.02 44.51 31.8 43.73 31.8 43.73C31.8 43.73 17.56 47.04 15.72 50.32C14.9 51.78 12.91 58.49 11.99 64.48C11.74 66.1 13.01 70.03 14.65 70.03H59.89C61.53 70.03 62.8 66.1 62.55 64.48C61.63 58.48 59.63 51.77 58.82 50.32L58.81 50.31Z"
          fill="#F2F4F7"
        />
        <path
          d="M28.75 10.31C28.75 10.31 36.99 25.77 49.92 9.71C49.92 9.71 38.59 0.709996 28.75 10.31Z"
          fill="#E4E7EC"
        />
        <path
          d="M28.56 27.81C28.03 23.24 27.58 12.55 34.42 9.55999L32.33 8.54999C32.33 8.54999 26.21 9.78999 25.65 19.24L27.29 29.22H27.3C28.06 29.22 28.65 28.56 28.56 27.81Z"
          fill="#E4E7EC"
        />
        <path
          d="M47.27 29.22H47.34L48.97 19.3C48.41 9.84999 42.29 8.60999 42.29 8.60999L40.2 9.61999C47.04 12.6 46.59 23.28 46.06 27.86C45.98 28.59 46.54 29.22 47.28 29.22H47.27Z"
          fill="#E4E7EC"
        />
        <path
          d="M30.94 27.49C30.08 27.88 29.18 28.33 28.68 29.14C28.02 30.21 28.26 31.58 28.52 32.8C28.98 34.96 29.43 37.12 29.89 39.28C30.15 40.5 30.4 41.81 29.94 42.97C29.36 44.44 28.36 45.96 27.06 46.87L20.84 57.23C21.27 58.18 22.13 58.46 23.06 58.93C24.1 59.46 25.14 59.98 26.17 60.51C26.67 60.77 27.22 61.03 27.78 60.95C28.35 60.87 28.8 60.46 29.24 60.1C33.55 56.5 39.95 55.59 45.09 57.86C46.05 58.28 47.14 58.82 48.1 58.38C49.03 57.96 49.36 56.85 49.7 55.89C50.47 53.73 51.78 51.76 53.48 50.23C54.05 49.72 54.69 49.21 54.95 48.49C55.22 47.75 55.03 46.93 54.8 46.18C53.41 41.61 50.43 37.53 46.5 34.82C44.67 33.56 42.45 32.33 41.99 30.15V30.12C41.44 27.52 38.93 25.82 36.33 26.36L30.92 27.5L30.94 27.49Z"
          fill={fillColor}
        />
        <path
          d="M54.91 48.61C54.91 48.61 54.95 48.53 54.97 48.49C55.23 47.77 55.06 46.98 54.84 46.25C52.36 46.86 49.75 47.05 47.1 46.77C38.76 45.87 31.85 40.4 28.6 33.14C29.03 35.19 29.47 37.23 29.9 39.28C30.16 40.5 30.41 41.81 29.95 42.97C29.37 44.44 28.37 45.96 27.07 46.87L20.85 57.23C21.28 58.18 22.14 58.46 23.07 58.93C24.11 59.46 25.15 59.98 26.18 60.51C26.68 60.77 27.23 61.03 27.79 60.95C28.36 60.87 28.81 60.46 29.25 60.1C30.95 58.68 32.97 57.69 35.11 57.14C39.79 51.4 47.14 47.96 54.9 48.62L54.91 48.61Z"
          fill={fillColor}
        />
        <path
          opacity="0.25"
          d="M53.11 50.64C53.25 50.51 53.37 50.36 53.51 50.23C54.08 49.72 54.72 49.21 54.98 48.49C55.25 47.75 55.06 46.93 54.83 46.18C54.4 44.77 53.81 43.4 53.09 42.11C48.35 46.53 41.81 48.99 34.98 48.25C34.73 48.22 34.49 48.19 34.24 48.15C31.93 48.65 29.53 48.81 27.08 48.54C26.77 48.51 26.46 48.46 26.15 48.42L20.86 57.23C21.29 58.18 22.15 58.46 23.08 58.93C24.12 59.46 25.16 59.98 26.19 60.51C26.69 60.77 27.24 61.03 27.8 60.95C28.37 60.87 28.82 60.46 29.26 60.1C31.52 58.21 34.34 57.08 37.26 56.74C41.5 52.87 47.13 50.56 53.1 50.64H53.11Z"
          fill="white"
        />
        <path
          d="M46.04 35.27C44.27 36.19 44.43 39.97 44.64 41.56C44.69 41.99 43.23 42.53 43.11 42.52L33.4 33.46C33.23 33.32 33.04 33.21 32.85 33.12C31.94 32.71 30.93 33.53 31.17 34.49C31.3 35.04 31.66 35.37 31.78 35.47L36.89 40.58C36.89 40.58 36.85 40.67 36.82 40.72L31.77 35.82C31.77 35.82 32.21 39.88 33.15 40.81L35.57 43.19C35.55 43.31 35.54 43.4 35.54 43.41L32.74 40.72C32.74 40.72 32.73 41.54 32.89 42.62C33.09 43.99 33.48 44.69 34.28 45.84L34.39 45.95L34.33 46.07L34.31 46.1L33.25 44.7C33.14 45.05 33.3 45.83 33.41 46.11C34.71 49.45 37.99 51.51 37.99 51.51C38.35 51.8 42.74 53.78 43.2 53.89L46.55 56.94L51.13 51.61L48.89 48.81L48.52 44.77C48.53 44.59 48.52 44.42 48.49 44.24L46.79 35.29C46.79 35.29 46.65 34.93 46.02 35.26L46.04 35.27Z"
          fill="#F2F4F7"
        />
        <path
          d="M48.29 58.66L52.85 53.62L48.9 48.82L43.76 54.38L48.29 58.66Z"
          fill="#E4E7EC"
        />
        <path
          d="M61.51 60.1L50.33 49.85L44.64 55.88L57.12 68.51C57.76 69.16 58.55 69.67 59.43 69.89C60.76 70.23 62.24 70 63.87 67.95C65.79 65.52 64.44 62.51 61.51 60.1Z"
          fill="#E4E7EC"
        />
        <path
          d="M43.8 43.5C43.88 42.98 43.91 42.54 43.93 42.21C43.56 42.39 43.17 42.52 43.11 42.52L43.05 42.47L41.15 44.3L41.12 44.08L42.9 42.33L40.98 40.54L39.87 41.61C39.87 41.61 39.81 41.5 39.8 41.47L40.85 40.42L38.82 38.52L36.75 40.45L36.89 40.59C36.89 40.59 36.85 40.68 36.82 40.73L36.64 40.56L34.71 42.36L35.57 43.2C35.55 43.32 35.54 43.41 35.54 43.42L34.57 42.49L33.58 43.42C33.58 43.42 33.28 43.34 33 43.22C33.22 44.23 33.6 44.88 34.28 45.85L34.39 45.96L34.33 46.08L34.31 46.11L33.25 44.71C33.14 45.06 33.3 45.84 33.41 46.12C34.71 49.46 37.99 51.52 37.99 51.52C38.09 51.6 38.53 51.83 39.11 52.1C40.04 51.42 42.25 49.6 43.25 47C43.36 46.72 43.52 45.94 43.41 45.59L42.35 46.99L42.33 46.96L42.28 46.84L42.39 46.73C43.19 45.58 43.58 44.88 43.78 43.51L43.8 43.5Z"
          fill="#E4E7EC"
        />
        <path
          d="M40.9 44.1C41.01 43.82 41.17 43.04 41.06 42.69L40 44.09L39.98 44.06L39.93 43.94L40.04 43.83C40.78 42.77 41.16 42.09 41.38 40.91L40.98 40.54L40.76 40.33L40.32 39.92L38.79 41.4C38.79 41.4 38.78 41.31 38.76 41.18L40.18 39.78L38.83 38.52L38.25 37.98L37.51 38.7C37.51 38.7 37.45 38.59 37.44 38.56L38.13 37.87L36.08 35.96L34.11 37.8L36.89 40.58C36.89 40.58 36.85 40.67 36.82 40.72L33.95 37.94L32.44 39.35C32.63 39.99 32.87 40.54 33.14 40.81L35.56 43.19C35.54 43.31 35.53 43.4 35.53 43.41L34.56 42.48L32.73 40.72C32.73 40.72 32.72 41.54 32.88 42.62C32.99 43.4 33.17 43.96 33.45 44.51L33.47 44.56C33.57 44.75 33.68 44.94 33.8 45.14L33.85 45.22C33.98 45.42 34.11 45.62 34.27 45.85L34.38 45.96L34.32 46.08L34.3 46.11L33.24 44.71C33.13 45.06 33.29 45.84 33.4 46.12C33.98 47.61 34.95 48.84 35.85 49.75C36.07 49.64 36.24 49.55 36.29 49.5C36.29 49.5 39.57 47.44 40.87 44.1H40.9Z"
          fill="#E4E7EC"
        />
        <path
          d="M28.96 35.53C30.73 36.45 30.57 40.23 30.36 41.82C30.3 42.25 31.77 42.79 31.89 42.78L41.6 33.72C41.77 33.58 41.96 33.47 42.15 33.38C43.06 32.97 44.07 33.79 43.83 34.75C43.7 35.3 43.34 35.63 43.22 35.73L38.11 40.84C38.11 40.84 38.15 40.93 38.18 40.98L43.23 36.08C43.23 36.08 42.79 40.14 41.85 41.07L39.43 43.45C39.45 43.57 39.46 43.66 39.46 43.67L42.26 40.98C42.26 40.98 42.27 41.8 42.11 42.88C41.91 44.25 41.52 44.95 40.72 46.1L40.61 46.21L40.66 46.33L40.68 46.36L41.74 44.96C41.85 45.31 41.69 46.09 41.58 46.37C40.28 49.71 37 51.77 37 51.77C36.64 52.06 32.25 54.04 31.79 54.15L28.44 57.2L23.86 51.87L26.1 49.07L26.47 45.03C26.46 44.85 26.47 44.68 26.5 44.5L28.2 35.55C28.2 35.55 28.34 35.19 28.97 35.52L28.96 35.53Z"
          fill="#F2F4F7"
        />
        <path
          d="M26.71 58.92L22.16 53.88L26.1 49.08L31.24 54.64L26.71 58.92Z"
          fill="#E4E7EC"
        />
        <path
          d="M13.49 60.36L24.67 50.11L30.36 56.14L17.88 68.77C17.24 69.42 16.45 69.93 15.57 70.15C14.24 70.49 12.76 70.26 11.13 68.21C9.21 65.78 10.56 62.77 13.49 60.36Z"
          fill="#E4E7EC"
        />
      </svg>

      {props.titleId && (
        <Text style={svgStyles.svgText}>
          <DisplayText textLocalId={props.titleId} textType="Text" />
        </Text>
      )}
    </View>
  );
};

export default NoAllergiesSvg;
