import {StyleSheet} from 'react-native';
import {Colors} from '../../../styles';

export const styles = StyleSheet.create({
  container: {
    borderRadius: 4,
    borderWidth: 0.5,
    borderColor: Colors.FoldPixel.GRAY150,
  },
  title: {
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 16.8,
    color: Colors.FoldPixel.GRAY400,
  },
  resultContainer: {
    backgroundColor: Colors.FoldPixel.GRAY50,
    padding: 8,
    borderRadius: 4,
    marginTop: 8,
    flex: 1,
  },
});
