import React from 'react';
import Stack from '../../../../../common/LayoutComponents/Stack';
import NoPinnedWidgetsIconSvg from '../../../../../common/Svg/NoPinnedWidgetsIconSvg';
import NoDataFoundWithCustomIcon from '../../../../../common/NoDataFound/NoDataFoundWithCustomIcon';
import { styles } from '../../../Analytics/style';

export const NoPinnedWidgetView = () => {
  return (
    <Stack
      direction="column"
      style={styles.stackStyling}
    >
      <NoDataFoundWithCustomIcon
        icon={<NoPinnedWidgetsIconSvg />}
        displayString="noPinnedWidgets"
      />
    </Stack>
  );
};
