import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-github';
import {View} from 'native-base';
import {ForwardedRef, forwardRef} from 'react';
import ReactAce from 'react-ace/lib/ace';
import {styles} from './EmailHtmlEditorStyles';

export interface IEmailHtmlEditorProps {
  onChangeHtml: (htmlString: string) => void;
  htmlString: string;
}

const EmailHtmlEditor = forwardRef(
  (props: IEmailHtmlEditorProps, ref: ForwardedRef<ReactAce>) => {
    const {onChangeHtml, htmlString} = props;
    return (
      <View style={styles.editorView}>
        <AceEditor
          value={htmlString}
          ref={ref}
          onChange={(value) => {
            onChangeHtml(value);
          }}
          style={{overflow: 'scroll', height: '100%', width: '100%'}}
          showGutter
          mode={'html'}
          wrapEnabled
          setOptions={{
            enableSnippets: true,
            cursorStyle: 'slim',
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
          }}
          editorProps={{
            $onCursorChange(...args) {
              // onCursorChange
            },
          }}
        />
      </View>
    );
  }
);

export default EmailHtmlEditor;
