import {evnName} from '../../../../constants/BaseUrlConst';

export const COMPONENT_TYPES = {
  MEDIA: 'pe-article.media',
  TEXT: 'pe-article.text',
  EMBED: 'pe-article.embed',
};

export const COMPONENT_TITLES = {
  MEDIA: 'media',
  TEXT: 'text',
  EMBED: 'embed',
};

export const BASE_PREVIEW_URL = `https://${evnName()}foldhealth.io/viewer/articles`;

// https://sandbox.foldhealth.io/viewer/articles/recently-diagnosed-with-diabetes-faq-e95aZ--jSlShSOiHdkG11A
