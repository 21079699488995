import {
  HStack,
  View,
  Text,
  Input,
  VStack,
  Select,
  FormControl,
  Radio,
  WarningOutlineIcon,
} from 'native-base';
import {useContext, useState} from 'react';
import {MLOV_CATEGORY} from '../../../../constants/MlovConst';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {Colors} from '../../../../styles/Colors';
import {getMlovId, getMlovListFromCategory} from '../../../../utils/mlovUtils';
import {IInputElement} from '../../../RightSideContainer/Workflow/FlowComponent/StateNodes/FlowNodeInterface';
import {
  RichTextEditor,
  TEXT_ONLY_MODULES,
} from '../../RichTextEditor/RichTextEditor';
import {Checkbox, Select as AntSelect, Spin} from 'antd';
import UserAutoComplete, {
  IUserSearch,
} from '../../CalendarWidget/UserAutoComplete/UserAutoComplete';
import {useQuery} from '@apollo/client';
import TaskPoolQueries from '../../../../services/TaskPool/TaskPoolQueries';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../constants/Configs';
import {ParticipantType} from '../../CalendarWidget/ParticipantAutoComplete/ParticipantEnum';
import { isAccountConfigEnabled } from '../../../../utils/configUtils';
import { CONFIG_CODES } from '../../../../constants/AccountConfigConst';

interface IOptedJourneyOption {
  allowToCreateTask: boolean;
  selectedAssignToCode?: string;
  selectedAssigneeRoleId?: string;
  selectedTaskPoolId?: string;
  selectedAssigneeId?: string;
  member?: IUserSearch;
  selectedCareTeamRoleId?: string;
  title?: string;
  description?: string;
  priorityId?: string;
}

interface IOptedJourneyComponentField {
  value: IOptedJourneyOption;
  accountTaskPools?: any[];
}

export const assignToTypeCodes = {
  USER_ROLE: 'USER_ROLE',
  TASK_POOL: 'TASK_POOL',
  SELECT_USER: 'SELECT_USER',
  SELECT_CARE_TEAM_ROLE: 'SELECT_CARE_TEAM_ROLE',
};

export const OptedOutJourneyTaskField = (props: IInputElement) => {
  const useAbsoluteLocation = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED)
  const [componentState, setComponentState] =
    useState<IOptedJourneyComponentField>({
      value: {
        allowToCreateTask: props.value?.allowToCreateTask,
        selectedAssignToCode: props.value?.assignToCode,
        selectedAssigneeRoleId: props.value?.assigneeRoleId,
        selectedTaskPoolId: props.value?.taskPoolId,
        selectedAssigneeId: props.value?.assigneeId,
        member: props?.value?.member,
        selectedCareTeamRoleId: props?.value?.careTeamRoleId,
        title: props?.value?.title,
        description: props?.value?.description,
        priorityId: props?.value?.priorityId,
      },
    });

  const mlovData = useContext(CommonDataContext);
  const taskPriority =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.TASK_PRIORITY,
    ) || [];
  const userRoles =
    getMlovListFromCategory(mlovData.MLOV, MLOV_CATEGORY.USER_ROLES) || [];
  const taskPoolTypeId = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'UserPoolType',
    'task_user_pool',
  );

  const TaskPoolQuery = useAbsoluteLocation ? TaskPoolQueries.GetTaskPoolQuery : TaskPoolQueries.GetTaskPool
  const { loading: isGetTaskPoolAPILoading } = useQuery(
    TaskPoolQuery,
    {
      variables: useAbsoluteLocation ?
        {
          params: {
            typeId: {
              _in: [taskPoolTypeId],
            },
            _and: (props?.locationIdList || []).map(locationId => ({
              userPoolLocations: {
                locationId: {
                  _in: [locationId],
                },
              },
            })),
          },
        } :
        {
          params: {
            userPoolTypeId: taskPoolTypeId,
          },
        },
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
      onCompleted: data => {
        setComponentState((prev: any) => {
          return {
            ...prev,
            accountTaskPools: useAbsoluteLocation ? data?.userPools : data?.getUserPools?.userPools,
          };
        });
      },
    },
  );

  function isFormValid(updatedField: IOptedJourneyOption) {
    const { title, priorityId, selectedAssignToCode, selectedAssigneeRoleId, selectedTaskPoolId, member, selectedCareTeamRoleId } = updatedField;
    if (!title || !priorityId || !selectedAssignToCode) {
      return false;
    }
    const isInvalidAssigneeRoleId = selectedAssignToCode === assignToTypeCodes.USER_ROLE && !selectedAssigneeRoleId;
    const isInvalidAssigneeTaskPool = selectedAssignToCode === assignToTypeCodes.TASK_POOL && !selectedTaskPoolId;
    const isInvalidAssigneeSelectUser = selectedAssignToCode === assignToTypeCodes.SELECT_USER && (!member || !member?.value);
    const isInvalidCareTeamRoleId = selectedAssignToCode === assignToTypeCodes.SELECT_CARE_TEAM_ROLE && !selectedCareTeamRoleId;
    if (isInvalidAssigneeRoleId || isInvalidAssigneeTaskPool || isInvalidAssigneeSelectUser || isInvalidCareTeamRoleId) {
      return false;
    }
    return true;
  }

  function onChange(updatedField: IOptedJourneyOption) {
    if (!updatedField?.allowToCreateTask) {
      return props.onChange({
        allowToCreateTask: false,
      });
    }
    if (!isFormValid(updatedField)) {
      return props.onChange(null);
    }
    const value = {
      allowToCreateTask: true,
      assignToCode: updatedField?.selectedAssignToCode,
      assigneeRoleId: updatedField?.selectedAssigneeRoleId,
      taskPoolId: updatedField?.selectedTaskPoolId,
      member: updatedField?.member,
      assigneeId: updatedField?.member?.value,
      careTeamRoleId: updatedField?.selectedCareTeamRoleId,
      title: updatedField?.title,
      description: updatedField?.description,
      priorityId: updatedField?.priorityId,
    };
    return props.onChange(value);
  }

  return (
    <>
      <HStack>
        <Checkbox
          checked={componentState.value?.allowToCreateTask}
          key={'opted-journey-task'}
          className="copy-checkbox"
          onChange={item => {
            setComponentState((prev: any) => {
              return {
                ...prev,
                value: {
                  ...prev.value,
                  allowToCreateTask: item?.target?.checked,
                },
              };
            });
            const updatedField: IOptedJourneyOption = {
              ...componentState.value,
              allowToCreateTask: item?.target?.checked,
            };
            onChange(updatedField);
          }}
        />
        <Text fontWeight={300} fontSize={16} flex={2} marginLeft={2}>
          {'Create task when the journey is opted out by a patient'}
        </Text>
      </HStack>
      {componentState.value?.allowToCreateTask && (
        <VStack>
          <View style={{marginTop: 18}}>
            <HStack>
              <HStack flex={2.4}>
                <Text fontWeight={300} fontSize={16} flex={2} marginTop={1}>
                  {'Title'}
                  {<Text color="error.500">*</Text>}
                </Text>
              </HStack>
              <HStack flex={7}>
                <View marginY={1} flex={1}>
                  <Input
                    isInvalid={props.isShowError && !componentState.value.title}
                    _focus={{
                      borderColor: Colors.Custom.Gray200,
                    }}
                    style={{height: 36}}
                    value={componentState.value.title}
                    onChangeText={(value: any) => {
                      setComponentState(prev => {
                        return {
                          ...prev,
                          value: {
                            ...prev.value,
                            title: value,
                          },
                        };
                      });
                      const updatedField: IOptedJourneyOption = {
                        ...componentState.value,
                        title: value,
                      };
                      onChange(updatedField);
                    }}
                    onBlur={(event: any) => {
                      setComponentState(prev => {
                        return {
                          ...prev,
                          value: {
                            ...prev.value,
                            title: event?.target?.value,
                          },
                        };
                      });
                      const updatedField: IOptedJourneyOption = {
                        ...componentState.value,
                        title: event?.target?.value,
                      };
                      onChange(updatedField);
                    }}
                  />
                </View>
              </HStack>
            </HStack>
          </View>
          <View style={{marginTop: 18}}>
            <HStack>
              <HStack flex={2.4}>
                <Text fontWeight={300} fontSize={16} flex={2} marginTop={1}>
                  {'Description'}
                </Text>
              </HStack>
              <HStack flex={7}>
                <View marginY={1} flex={1}>
                  <RichTextEditor
                    modules={TEXT_ONLY_MODULES}
                    valueStr={componentState.value.description}
                    onChangesValue={(value: any) => {
                      setComponentState(prev => {
                        return {
                          ...prev,
                          value: {
                            ...prev.value,
                            description: value,
                          },
                        };
                      });
                      const updatedField: IOptedJourneyOption = {
                        ...componentState.value,
                        description: value,
                      };
                      onChange(updatedField);
                    }}
                  />
                </View>
              </HStack>
            </HStack>
          </View>
          <View style={{marginTop: 18}}>
            <HStack>
              <HStack flex={2.4}>
                <Text fontWeight={300} fontSize={16} flex={2} marginTop={1}>
                  {'Task Priority'}
                  {<Text color="error.500">*</Text>}
                </Text>
              </HStack>
              <HStack flex={7}>
                <VStack marginY={1} flex={1}>
                  <Select
                    borderColor={props.isShowError && componentState?.value?.allowToCreateTask && !componentState.value.priorityId ? 'red.500' : 'gray.300'}
                    height={'36px'}
                    fontSize={'13px'}
                    selectedValue={componentState.value.priorityId}
                    onValueChange={itemValue => {
                      setComponentState(prev => {
                        return {
                          ...prev,
                          value: {
                            ...prev.value,
                            priorityId: itemValue,
                          },
                        };
                      });
                      const updatedField: IOptedJourneyOption = {
                        ...componentState.value,
                        priorityId: itemValue,
                      };
                      onChange(updatedField);
                    }}>
                    {taskPriority?.length &&
                      taskPriority.map((data: any) => {
                        return (
                          <Select.Item
                            key={data.id}
                            label={data.value}
                            value={data.id}
                          />
                        );
                      })}
                  </Select>
                </VStack>
              </HStack>
            </HStack>
          </View>
          <View style={{marginTop: 18}}>
            <HStack>
              <HStack flex={2.4}>
                <Text fontWeight={300} fontSize={16} flex={2} marginTop={1}>
                  {'Assignee Option'}
                  {<Text color="error.500">*</Text>}
                </Text>
              </HStack>
              <HStack flex={7}>
                <VStack space={1}>
                  <View>
                    <FormControl
                      isInvalid={
                        props.isShowError &&
                        !componentState.value.selectedAssignToCode
                      }
                      flex={1}>
                      <Radio.Group
                        name="frequencyType"
                        value={componentState.value.selectedAssignToCode}
                        onChange={(selectedAssignToCode: any) => {
                          setComponentState(prev => ({
                            ...prev,
                            value: {
                              ...prev.value,
                              selectedAssignToCode: selectedAssignToCode,
                              selectedAssigneeRoleId: undefined,
                              selectedTaskPoolId: undefined,
                            },
                          }));
                          const updatedField: IOptedJourneyOption = {
                            ...componentState.value,
                            selectedAssignToCode: selectedAssignToCode,
                          };
                          onChange(updatedField);
                        }}>
                        <HStack space={2}>
                          <Radio
                            value={assignToTypeCodes.USER_ROLE}
                            marginY={1}>
                            <Text marginLeft={2}>User Role</Text>
                          </Radio>

                          <Radio
                            value={assignToTypeCodes.TASK_POOL}
                            marginY={1}>
                            <Text marginLeft={2}>Task Pool</Text>
                          </Radio>

                          <Radio
                            value={assignToTypeCodes.SELECT_USER}
                            marginY={1}>
                            <Text marginLeft={2}>Select User</Text>
                          </Radio>
                          <Radio
                            value={assignToTypeCodes.SELECT_CARE_TEAM_ROLE}
                            marginY={1}>
                            <Text marginLeft={2}>Care Team Role</Text>
                          </Radio>
                        </HStack>
                      </Radio.Group>
                      <FormControl.ErrorMessage
                        leftIcon={<WarningOutlineIcon size="xs" />}>
                        Please select assign to option
                      </FormControl.ErrorMessage>
                    </FormControl>
                  </View>

                  {componentState.value.selectedAssignToCode ===
                    assignToTypeCodes.USER_ROLE && (
                    <View marginTop={2}>
                      <FormControl
                        isInvalid={
                          props.isShowError &&
                          !componentState.value.selectedAssigneeRoleId
                        }
                        flex={1}>
                        <Select
                          height={'40px'}
                          fontSize={'13px'}
                          placeholder="Select user role"
                          selectedValue={
                            componentState.value.selectedAssigneeRoleId
                          }
                          onValueChange={selectedAssigneeRoleId => {
                            setComponentState(prev => ({
                              ...prev,
                              value: {
                                ...prev.value,
                                selectedAssigneeRoleId: selectedAssigneeRoleId,
                              },
                            }));
                            const updatedField: IOptedJourneyOption = {
                              ...componentState.value,
                              selectedAssigneeRoleId: selectedAssigneeRoleId,
                            };
                            onChange(updatedField);
                          }}>
                          {userRoles?.map((data: any) => {
                            return (
                              <Select.Item
                                key={data.id}
                                label={data.value}
                                value={data.id}
                              />
                            );
                          })}
                        </Select>
                      </FormControl>
                    </View>
                  )}

                  {componentState.value.selectedAssignToCode ===
                    assignToTypeCodes.TASK_POOL && (
                    <View marginTop={2}>
                      <AntSelect
                        size="large"
                        showSearch={false}
                        allowClear
                        filterOption={false}
                        value={
                          componentState.accountTaskPools?.length &&
                          componentState.value.selectedTaskPoolId
                            ? [componentState.value.selectedTaskPoolId]
                            : []
                        }
                        onChange={(value: any[], data: any) => {
                          setComponentState(prev => ({
                            ...prev,
                            value: {
                              ...prev.value,
                              selectedTaskPoolId: data?.value || undefined,
                            },
                          }));
                          const updatedField: IOptedJourneyOption = {
                            ...componentState.value,
                            selectedTaskPoolId: data?.value || undefined,
                          };
                          onChange(updatedField);
                        }}
                        placeholder="Select task pool"
                        loading={isGetTaskPoolAPILoading}
                        notFoundContent={
                          isGetTaskPoolAPILoading && <Spin size="small" />
                        }
                        style={{height: '40px'}}
                        className={
                          props.isShowError &&
                          !componentState.value.selectedTaskPoolId
                            ? 'field-error'
                            : ''
                        }>
                        {componentState.accountTaskPools?.map((item, index) => {
                          return (
                            <AntSelect.Option key={item.id} value={item.id}>
                              <Text>{item.name}</Text>
                            </AntSelect.Option>
                          );
                        })}
                      </AntSelect>
                    </View>
                  )}

                  {componentState.value.selectedAssignToCode ===
                    assignToTypeCodes.SELECT_USER && (
                    <View marginTop={2}>
                      <UserAutoComplete
                        selectedValue={props.value?.member}
                        isDisabled={false}
                        onChange={user => {
                          if (user) {
                            const member = {
                              key: user?.key,
                              email: user?.data?.email,
                              label: user?.label,
                              value: user?.value,
                              type: ParticipantType.staff,
                            };
                            const updatedField: IOptedJourneyOption = {
                              ...componentState.value,
                              member: member,
                            };
                            onChange(updatedField);
                          } else {
                            const updatedField: IOptedJourneyOption = {
                              ...componentState.value,
                              member: undefined,
                            };
                            onChange(updatedField);
                          }
                        }}
                      />
                    </View>
                  )}

                  {componentState.value.selectedAssignToCode ===
                    assignToTypeCodes.SELECT_CARE_TEAM_ROLE && (
                    <View marginTop={2}>
                      <FormControl
                        isInvalid={
                          props.isShowError &&
                          !componentState.value.selectedCareTeamRoleId
                        }
                        flex={1}>
                        <Select
                          height={'40px'}
                          fontSize={'13px'}
                          placeholder="Select user role"
                          selectedValue={
                            componentState.value.selectedCareTeamRoleId
                          }
                          onValueChange={selectedCareTeamRoleId => {
                            setComponentState(prev => ({
                              ...prev,
                              value: {
                                ...prev.value,
                                selectedCareTeamRoleId: selectedCareTeamRoleId,
                              },
                            }));
                            const updatedField: IOptedJourneyOption = {
                              ...componentState.value,
                              selectedCareTeamRoleId: selectedCareTeamRoleId,
                            };
                            onChange(updatedField);
                          }}>
                          {userRoles?.map((data: any) => {
                            return (
                              <Select.Item
                                key={data.id}
                                label={data.value}
                                value={data.id}
                              />
                            );
                          })}
                        </Select>
                      </FormControl>
                    </View>
                  )}
                </VStack>
              </HStack>
            </HStack>
          </View>
        </VStack>
      )}
    </>
  );
};

export default OptedOutJourneyTaskField;
