import { Coding } from "fhir/r4";
import { EXTENSION_URLS } from "../../PersonOmniView/MiddleContainer/PatientNotes/components/AddOrUpdateTemplate/constant";

export enum OrderSearchType {
  radiology = 'Radiology',
  lab = 'Lab'
}

export const getExternalOrderDisplay = (item: Coding) => {
  const display = item.display || '';
  const group = item.extension?.find((item) => item.url === EXTENSION_URLS.group)?.valueString;
  if (group) {
    return `${group}: ${display}`;
  }
  return display;
}

export const getExternalOrderCode = (item: Coding) => {
  return item.code || '';
}
