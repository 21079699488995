import {useState} from 'react';
import {
  getAttachmentsList,
  getFormattedMessagingList,
  getMessageAttachmentWithResourceData,
  getMessageCommunicationTypeMap,
  getMessageIds,
  getMessageIdsFromApiResponse,
  getMessageUuidsFromApiResponse,
} from './MessagingUtils';
import {isAccountConfigEnabled} from '../../../../../utils/configUtils';
import {CONFIG_CODES} from '../../../../../constants/AccountConfigConst';
import {getUserData} from '../../../../../utils/commonUtils';
import {IGetAdditionalDataForMessages} from './interface';

const useMessagingAdditionalData = () => {
  const showConversationTaskCount = isAccountConfigEnabled(
    CONFIG_CODES.ENABLE_TASK_COUNT_ON_MESSAGE
  );
  const userData = getUserData();

  const [messagingAdditionalData, setMessagingAdditionalData] = useState({
    loading: false,
  });


  const getAdditionalDataForMessages = async (params: IGetAdditionalDataForMessages, abortSignal: AbortSignal) => {
    const {apiResponse, conversationDisplayId, showPracticeMessageTogether} =
      params;
    setMessagingAdditionalData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const messageIds = getMessageIdsFromApiResponse(apiResponse);
    const messageUuids = getMessageUuidsFromApiResponse(apiResponse);
    const attachmentMessageIds = getMessageIds(
      apiResponse?.conversationMessages
    );
    const finalRespData = await getMessageAttachmentWithResourceData({
      conversationUuid: params?.conversationUuid,
      attachmentMessageIds: attachmentMessageIds,
      smsStatusMessageIds: messageIds,
      messageUuids: messageUuids,
      conversationDisplayId: conversationDisplayId
    }, abortSignal);
    const msgAttachmentData = finalRespData?.msgAttachmentData;
    const communicationType = finalRespData?.communicationType;
    const smsStatus = finalRespData?.smsStatus;
    const messageTaskCount = finalRespData?.messageTaskCount?.getResourceMapCount;
    const msgWithAttachments = getAttachmentsList(msgAttachmentData);
    const displayMsgList = getFormattedMessagingList(
      apiResponse?.conversationMessages,
      msgWithAttachments,
      userData,
      showPracticeMessageTogether
    );

    const messageData = [...displayMsgList];
    const messageCommunicationTypeMap = getMessageCommunicationTypeMap(
      communicationType
    );
    const messageTaskCountData = messageTaskCount;
    messageData.forEach((singleMessage) => {
      const messageTaskCount = messageTaskCountData?.find(
        (item: any) => item?.sourceId === singleMessage?.uuid
      );
      if (smsStatus) {
        const statusObject = smsStatus?.find(
          (singleStatusData: any) =>
            singleStatusData?.messageId === singleMessage?.id
        );
        if (statusObject?.errorCode) {
          const errorData = {
            errorCode: statusObject?.errorCode,
            message: statusObject?.twilioErrorCodes?.customMessage || statusObject?.twilioErrorCodes?.message,
          };
          singleMessage.errorData = errorData;
        }
      }
      if (messageCommunicationTypeMap[singleMessage?.uuid]) {
        singleMessage.communicationTypes =
          messageCommunicationTypeMap[singleMessage?.uuid] || [];
      }
      if (messageTaskCount?.count && showConversationTaskCount) {
        singleMessage.taskCount = messageTaskCount?.count;
      }
    });
    setMessagingAdditionalData((prev) => {
      return {
        ...prev,
        loading: false,
      };
    });

    return {
      messageData,
    };
  };
  return {
    loading: messagingAdditionalData.loading,
    getAdditionalDataForMessages,
  };
};

export default useMessagingAdditionalData;
