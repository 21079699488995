import {Formio} from '@foldhealth/formiojs';
import QuillConfig from '../Builder/QuillConfig';
import ConditionalComponents from '../EditFormFields/ConditionalFields';
import KeyField from '../EditFormFields/KeyField';
import SearchableComponentFields from '../EditFormFields/SearchableComponentFields';
import {FormComponentLabelEnum} from '../FormEnum';
import ShareWithPatientFields from '../EditFormFields/ShareWithPatientFields';

const field = Formio.Components.components.field;
Formio.Components.components.checkbox.schema = (...extend: any) => {
  return field.schema(
    {
      type: 'checkbox',
      inputType: 'checkbox',
      label: FormComponentLabelEnum.checkbox,
      key: 'checkbox',
      dataGridLabel: true,
      labelPosition: 'right',
      value: '',
      name: '',
      validate: {
        customMessage: 'Field is required',
      }
    },
    ...extend
  );
};

Formio.Components.components.checkbox.editForm = function () {
  return {
    key: 'display',
    components: [
      {
        type: 'oldtextfield',
        key: 'label',
        label: 'Label',
        input: true,
        validate: {
          required: true,
        },
      },
      {
        type: 'checkbox',
        key: 'validate.required',
        label: 'Is this field required?',
        input: true,
      },
      ...SearchableComponentFields,
      {
        type: 'textarea',
        input: true,
        key: 'description',
        label: 'Description',
        placeholder: 'Description for this field.',
        tooltip:
          'The description is text that will appear below the input field.',
        editor: 'quill',
        wysiwyg: QuillConfig,
      },
      // KEPT FOR FUTURE
      // {
      //   type: 'checkbox',
      //   label: 'Hide Label',
      //   tooltip:
      //     'Hide the label or title of this component. This allows you to show the label in the form builder, but not when the form is shown to the member.',
      //   key: 'hideLabel',
      //   input: true,
      // },
      ...ConditionalComponents,
      ...KeyField,
      {
        type: 'hidden',
        input: true,
        label: 'Data Source Values',
        key: 'values',
        hidden: true,
        clearOnHide: false,
        defaultValue: [
          {
            label: 'Checked',
            value: true,
            riskScore: 0,
            groupRiskScore: 0,
          },
          {
            label: 'Unchecked',
            value: false,
            riskScore: 0,
            groupRiskScore: 0,
          },
        ],
      },
      ...ShareWithPatientFields,
    ],
  };
};
