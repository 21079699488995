import { Popover } from 'antd';
import { Badge, Text, View } from 'native-base';
import { getEnvVariable } from '../../../constants/BaseUrlConst';
import { styles } from './EnvIndicatorStyles';

export interface EnvConfig {
  env: string;
  badgeColor: string;
  text: string;
  tooltipText: string;
  visitText: string;
  visitSentence: string;
}

const EnvIndicator = () => {
  const env = getEnvVariable();
  const {badgeColor, text, tooltipText, visitText, visitSentence} =
    getEnvConfig();
  return (env ? (
    <View>
      <Popover
        placement="bottomRight"
        title={() => {
          return (
            <View
              style={{
                backgroundColor: 'white',
                padding: 24,
                width: 350,
                borderTopLeftRadius: 4,
                borderBottomLeftRadius: 16,
                borderTopRightRadius: 16,
                borderBottomRightRadius: 16,
              }}
            >
              <Text style={styles.alertText}>
                {`${tooltipText}\n`}
                <Text>
                  {/* {visitSentence}{' '} */}
                  {/* <TouchableOpacity>
                    <Text
                      style={{
                        color: '#F37232',
                      }}
                    >
                      {visitText} Environment
                    </Text>
                  </TouchableOpacity> */}
                </Text>
              </Text>
            </View>
          );
        }}
        trigger={'hover'}
        color="transparent"
        overlayStyle={{
          height: '0px',
          width: '0px',
          padding: '0px',
        }}
        overlayInnerStyle={{}}
      >
        <Badge
          style={{
            paddingVertical: 2,
            paddingHorizontal: 8,
            backgroundColor: badgeColor,
            borderRadius: 4,
          }}
        >
          <Text style={styles.badgeText}>{text}</Text>
        </Badge>
      </Popover>
    </View>
  ) : <></>);
};

export default EnvIndicator;

export const getEnvConfig = (): EnvConfig => {
  const env = getEnvVariable();
  if (!env?.length) {
    // prod env
    return {
      badgeColor: '#4F2D90',
      env: 'production',
      text: 'Production',
      tooltipText: ENV_TEXT.PRODUCTION,
      visitText: 'Demo',
      visitSentence: 'please visit our',
    };
  }
  return {
    badgeColor: '#F37232',
    env: 'sandbox',
    text: 'Demo',
    tooltipText: ENV_TEXT.DEMO,
    visitText: 'Production',
    visitSentence: 'Go to',
  };
};

export const ENV_TEXT = {
  DEMO: 'This is demo environment so please feel free to play around with the features and get yourself acquainted',
  PRODUCTION:
    'This is a active business environment and not for testing purpose.',
};
