import {View} from 'native-base';
import {useState} from 'react';
import {FILE_TYPE} from '../../../../constants';
import VideoPlayerModal from '../../../common/VideoPlayerModal/VideoPlayerModal';
import {styles} from './Styles';
import WorkflowMainTableView from './WorkflowTableView/WorkflowMainTableView';

const WorkflowView = (props: {
  flowType: string;
  showTableTopBar: boolean;
  searchText?: string;
  isNewWorkflowView?: boolean;
}) => {
  const [selectedView, setSelctedView] = useState(false);
  return (
    <View>
      <View>
        <View style={[styles.workflowListView]}>
          <WorkflowMainTableView
            isNewWorkflowView={props.isNewWorkflowView}
            searchText={props.searchText}
            showTableTopBar={props.showTableTopBar}
            flowType={props.flowType}
          />
        </View>
        {selectedView && (
          <VideoPlayerModal
            filetype={FILE_TYPE.VIDEO}
            modalTitle={'autoMagicIntro'}
            srcUrl="https://generalfolddocs.s3.us-east-2.amazonaws.com/Automation+2022-04-14+at+11.07.54+PM.mov"
            onActionPerformed={() => {
              setSelctedView(false);
            }}
          />
        )}
      </View>
    </View>
  );
};

export default WorkflowView;
