import {Button, Text, View} from 'native-base';
import React, {useState} from 'react';
import {Colors} from '../../../../styles';
import {IMedlineSuggestion} from '../EmailTemplates/CreateOrEditTemplate/CustomBlocks/MedlineSuggestions/MedlineSuggestionsUtils';

const ViewMedlineSuggestionsList = (props: any) => {
  const {html, onModalClose, suggestions, index} = props;
  const [disabled, setDisabled] = useState(false);
  const [disabledR, setDisabledR] = useState(true);

  const value = html.content?.find(
    (x: {[x: string]: string}) => x['@_name'] === 'FullSummary'
  );
  const snippet = html.content?.find(
    (x: {[x: string]: string}) => x['@_name'] === 'snippet'
  );

  return (
    <View>
      <View
        style={{
          marginVertical: 20,
          marginBottom: 20,
        }}
      >
        <Text
          size={'mdBold'}
          color={Colors.Custom.Gray700}
          style={{marginTop: 20}}
        >
          <div
            dangerouslySetInnerHTML={{__html: html?.content[0]?.['#text']}}
          />
        </Text>

        <Text
          size={'smMedium'}
          color={Colors.Custom.Gray700}
          style={{marginTop: 20}}
        >
          <div dangerouslySetInnerHTML={{__html: value?.['#text']}} />
        </Text>
      </View>

      {disabledR && (
        <Button
          style={{
            backgroundColor: Colors.primary['300'],
            borderRadius: 20,
          }}
          isDisabled={disabled}
          onPress={() => {
            const suggestion: IMedlineSuggestion = {
              title: html?.content[0]?.['#text'],
              description: snippet?.['#text'],
              url: html?.['@_url'],
            };
            suggestions.suggestedContent?.push(suggestion);
            setDisabled(true);
            setDisabledR(false);
          }}
        >
          Add to suggestions
        </Button>
      )}

      {disabled && (
        <Button
          style={{
            backgroundColor: Colors.primary['300'],
            borderRadius: 20,
          }}
          isDisabled={disabledR}
          onPress={() => {
            suggestions.suggestedContent = suggestions.suggestedContent?.filter(
              function (e: any) {
                return e?.url !== html?.['@_url'];
              }
            );

            setDisabled(false);
            setDisabledR(true);
          }}
        >
          Remove from suggestions
        </Button>
      )}
    </View>
  );
};

export default ViewMedlineSuggestionsList;
