import {Button, HStack, Spacer, Text, View, VStack} from 'native-base';
import {FormattedMessage} from 'react-intl';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {Colors} from '../../../styles';
import GlobalActions from '../GlobalActions/GlobalActions';
import MainTopBarView from '../MainTopbarView/MainTopbarView';
import {Notifications} from '../Notifications';
import ProfileView from '../ProfileView/ProfileView';
import {ITitleSubtitleView} from './interface';
import {styles} from './TitleSubtitleStyle';
import { testID, TestIdentifiers } from '../../../testUtils';
import React from 'react';

const TitleSubtitleView = (props: ITitleSubtitleView) => {
  const {
    title,
    secondTitle,
    titleLabelId,
    // secondTitleLabelId,
    titleStyle,
    containerStyle,
    actionContainer,
    isTitleSubtitleOldView,
    isHideCommonButton,
    patientContextView,
    useStrictWidth,
  } = props;

  return (
    <>
      { !isHideCommonButton ? (
        <MainTopBarView
          patientContextView={patientContextView}
          leftBackView={
            props.showBackButton ? (
              <VStack>
                <Button
                  backgroundColor={'white'}
                  _hover={{bg: Colors.primary['100'] + '7a' || ''}}
                  style={styles.backButtonStyle}
                  onPress={() => {
                    props.onBackClick?.();
                  }}
                  {...testID(TestIdentifiers.backButton)}
                >
                  <AntIcon name="arrowleft" size={18} />
                </Button>
              </VStack>
            ) : (
              <></>
            )
          }
        />
      ) : (
        <View
          style={[
            styles.titleSubtiteWrapperStyle,
            containerStyle ? containerStyle : {},
          ]}
        >
          <HStack alignItems={'center'} flex={1} maxWidth={useStrictWidth? '100%' : undefined}>
            {props.showBackButton && (
              <VStack>
                <Button
                  backgroundColor={'white'}
                  _hover={{bg: Colors.primary['100'] + '7a' || ''}}
                  style={styles.backButtonStyle}
                  onPress={() => {
                    props.onBackClick?.();
                  }}
                  {...testID(TestIdentifiers.backButton)}
                >
                  <AntIcon name="arrowleft" size={18} />
                </Button>
              </VStack>
            )}
            <VStack maxWidth={useStrictWidth? '100%' : undefined}>
              <View>
                <HStack alignItems={'center'} style={{minHeight: 40}}>
                  <VStack maxWidth={useStrictWidth ? '100%' : undefined}>
                    <Text
                      numberOfLines={2}
                      style={[
                        styles.titleStyle,
                        {color: Colors.primary['400']},
                        {...titleStyle}
                      ]}
                      size={'xxlBold'}
                      {...testID('MainHeaderTitle')}
                    >
                      {titleLabelId ? (
                        <FormattedMessage id={titleLabelId} />
                      ) : (
                        title
                      )}
                    </Text>
                  </VStack>
                </HStack>
                  {props.subtitle?.length
                  ?
                    <VStack style={{marginTop: 10, maxWidth: 500}}>
                      <Text {...testID('MainHeaderSubtitle')} color={Colors.Custom.Gray400}>
                        {props.subtitle}
                      </Text>
                    </VStack>
                  :
                  <></>
                  }
                {/* <Text
            style={[styles.subTitleStyle, subtitleStyle ? titleStyle : {}]}
            size={'mdNormal'}
          >
            {subtitleLabelId ? (
              <FormattedMessage id={subtitleLabelId} />
            ) : (
              subtitle
            )}
          </Text> */}
              </View>
            </VStack>
            <Spacer />
            <View
              style={{
                marginHorizontal: 16,
              }}
            >
              {props.isHideCommonButton ? <></> : <Notifications />}
            </View>
            <View
              style={{
                marginHorizontal: 12,
              }}
            >
              {props.isHideCommonButton ? <></> : <GlobalActions />}
            </View>
            {props.isHideCommonButton ? <></> : <ProfileView />}
            {actionContainer && false && (
              <View style={{marginLeft: 10}}>{actionContainer}</View>
            )}
          </HStack>
        </View>
      )}
    </>
  );
};

export default React.memo(TitleSubtitleView);
