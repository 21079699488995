import React from 'react';
import {FormControl, HStack} from 'native-base';
import {Colors} from '../../../../../../../styles';
import {InputNumber, Select} from 'antd';
import {DATE_UNIT_CONST} from '../../../../../../../utils/DateUtils';

const CustomRSVPExpiry = (props: {
  valueFormatter: (
    value: string | number | undefined,
    info: {userTyping: boolean; input: string}
  ) => string;
  selectedUnit: string;
  handleUnitChange: (value: string) => void;
  handleValueChange: (value: string) => void;
  value: string;
  error?: boolean;
}) => {
  return (
    <FormControl isInvalid={props.error}>
      <HStack
        borderWidth={1}
        borderColor={props?.error ? Colors.Custom.ErrorColor : Colors.Custom.Gray300}
        style={{
          borderRadius: 8,
        }}
      >
        <InputNumber
          height={'44px'}
          bordered={false}
          style={{flex: 1, padding: 2, fontSize: 12}}
          precision={0}
          step={1}
          value={props.value}
          formatter={props?.valueFormatter}
          onChange={(value) => {
            props?.handleValueChange(value as string);
          }}
        />
        <Select
          bordered={false}
          value={props?.selectedUnit}
          className="appointment-timezone"
          onChange={(value) => props.handleUnitChange(value)}
        >
          {DATE_UNIT_CONST.map((item, index) => {
            return (
              <Select.Option value={item} key={index}>
                {item}
              </Select.Option>
            );
          })}
        </Select>
      </HStack>
      <FormControl.ErrorMessage
        _text={{
          fontSize: 'xs',
          color: 'error.500',
          fontWeight: 500,
        }}
      >
        {props?.error && 'Invalid expiry time'}
      </FormControl.ErrorMessage>
    </FormControl>
  );
};

export default CustomRSVPExpiry;
