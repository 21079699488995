import {StyleSheet} from 'react-native';
import {Colors} from '../../../../../../styles';
export const styles = StyleSheet.create({
  cannedResponsesMain: {
    maxHeight: 150,
    width: '100%',
    overflow: 'scroll',
    position: 'absolute',
    left: 0,
    zIndex: 100,
    backgroundColor: Colors.Custom.BackgroundColor,
    borderColor: Colors.Custom.BorderColor,
    marginRight: 15,
  },
  cannedResponse: {
    backgroundColor: Colors.Custom.BackgroundColor,
    borderColor: Colors.Custom.BorderColor,
    borderWidth: 1,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    padding: 5
  },
  cannedResponseForSms: {
    backgroundColor: Colors.Custom.BackgroundColor,
    borderColor: Colors.Custom.BorderColor,
    borderWidth: 1,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    marginRight: 15,
    padding: 5
  },
  contentOverflow: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  contentListItem: {
    padding: 5,
  },
  contentListItemInner: {
    flexDirection: 'row'
  },
  flexRow: {
    flexDirection: 'row',
  },
  contentText: {
    fontSize: 14,
    fontWeight: '300',
  },
  shortCode: {
    fontSize: 14,
    fontWeight: '600',
  },
  cannedResponsesMainNative: {
    maxHeight: 150,
    width: '97%',
    overflow: 'hidden',
    position: 'absolute',
    bottom: 10,
    zIndex: 100,
    backgroundColor: Colors.Custom.BackgroundColor,
    borderColor: Colors.Custom.BorderColor,
    borderWidth: 1,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    alignSelf:'center',
    padding: 5,
  },
  nativeBoxView:{
    position:'relative',
    zIndex:100,
    height: 75,
    width: '97%',
    overflow: 'hidden',
    backgroundColor: Colors.Custom.BackgroundColor,
    borderColor: Colors.Custom.BorderColor,
    borderWidth: 1,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    alignSelf:'center',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
  },
  mentionItem: {
    paddingHorizontal: 12,
    paddingTop: 6,
    paddingBottom: 6,
  },
  mentionFirstItem: {
    paddingTop: 12,
  },
  mentionLastItem: {
    paddingBottom: 12,
  },
});
