import { updateMessagingQueueState } from './MessagingEventQueueUtils';
import { IMessagingEventQueueEventListener, IMessagingEventQueueState } from './MessagingEventQueueInterfaces';

export class MessagingEventQueue {
  private readonly state: IMessagingEventQueueState;

  constructor(params: { moduleCode: string; }) {
    this.state = {
      moduleCode: params.moduleCode,
      isLoading: false,
      eventQueue: [],
      eventListeners: new Map(),
      isRemoved: false,
      inprogressEventId: undefined,
      updateLoadingStatusTimeoutId: undefined,
    };
    this.eventBusListener = this.eventBusListener.bind(this);
  }

  eventBusListener(data: any, eventCode?: string) {
    if (!eventCode) {
      return;
    }
    updateMessagingQueueState(this.state, { actionCode: 'EVENT_QUEUE/ADD_EVENT', payload: { eventCode, data} });
    this.broadcastEventQueueEvent();
  }

  updateLoadingStatus(payload: { isLoading: boolean }) {
    clearTimeout(this.state.updateLoadingStatusTimeoutId);
    this.state.updateLoadingStatusTimeoutId = undefined;

    if (!payload?.isLoading) {
      this.state.updateLoadingStatusTimeoutId = setTimeout(() => {
        updateMessagingQueueState(this.state, { actionCode: 'SET_LOADING', payload });
        this.broadcastEventQueueEvent();
      }, 200);
    } else {
      updateMessagingQueueState(this.state, { actionCode: 'SET_LOADING', payload });
      this.broadcastEventQueueEvent();
    }
  }

  addEventListener(eventCode: string, listener: IMessagingEventQueueEventListener) {
    updateMessagingQueueState(this.state, { actionCode: 'EVENT_BUS/ADD_EVENT_LISTENER', payload: { eventCode, listener, eventBusListener: this.eventBusListener } });
  }

  removeEventListener(eventCode: string) {
    updateMessagingQueueState(this.state, { actionCode: 'EVENT_BUS/REMOVE_EVENT_LISTENER', payload: { eventCode, eventBusListener: this.eventBusListener } });
  }

  removeEventFromEventQueue(eventId: string) {
    updateMessagingQueueState(this.state, { actionCode: 'EVENT_QUEUE/REMOVE_EVENT', payload: { eventId } });
  }

  removeAllEventListener() {
    updateMessagingQueueState(this.state, { actionCode: 'EVENT_BUS/REMOVE_EVENT_LISTENER/ALL', payload: { eventBusListener: this.eventBusListener } });
  }

  removeEventQueue() {
    updateMessagingQueueState(this.state, { actionCode: 'EVENT_QUEUE/REMOVE' });
  }

  private broadcastEventQueueEvent() {
    const state = this.state;
    if (state.isRemoved || state.inprogressEventId || state.isLoading || !state.eventQueue?.length) {
      return;
    }
    const event = state.eventQueue[0];
    const eventId = event?.event?.id;
    const eventCode = event?.event?.eventCode;

    state.inprogressEventId = eventId;
    return setTimeout(() => {
      if (!state.isRemoved) {
        try {
          const eventListener = eventCode ? state.eventListeners?.get(eventCode) : undefined;
          if (eventListener) {
            const eventData = event?.event?.data || {};
            eventData.moduleCode = state.moduleCode;
            eventListener(eventData, eventCode);
          }
        } catch (error) { }
      }
      this.removeEventFromEventQueue(eventId);
      state.inprogressEventId = undefined;
      this.broadcastEventQueueEvent();
    }, 100);
  }
}
