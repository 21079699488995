import {VStack, Text, HStack} from 'native-base';
import {styles} from './DisplayCardStyles';
import {ICardFooterProps} from './interface';
export const DisplayCardFooter = (props: ICardFooterProps) => {
  const {footerData} = props;
  return (
    <HStack flex={1} justifyContent={'space-between'}>
      <VStack flex={0.4} style={styles.footerLeft}>
        <Text style={styles.footerText}>{footerData.leftStr}</Text>
      </VStack>
      <VStack flex={0.6} style={styles.footerRight}>
        <Text style={styles.footerText}>{footerData.dateStr}</Text>
      </VStack>
    </HStack>
  );
};
