import React from 'react';
import {View, ViewStyle} from 'react-native';
import {Path, Svg} from 'react-native-svg';
import { Colors } from '../../../styles';

const GraphIconSvg = (props: {style?: ViewStyle}) => {
  return (
    <Svg width="30" height="32" viewBox="0 0 25 24" fill="none">
      <Path
        d="M2.5 21.5C2.22386 21.5 2 21.7239 2 22C2 22.2761 2.22386 22.5 2.5 22.5V21.5ZM22.5 21.5H2.5V22.5H22.5V21.5ZM17 13.5H20V12.5H17V13.5ZM21 14.5V22H22V14.5H21ZM16 22V14.5H15V22H16ZM20 13.5C20.5523 13.5 21 13.9477 21 14.5H22C22 13.3954 21.1046 12.5 20 12.5V13.5ZM17 12.5C15.8954 12.5 15 13.3954 15 14.5H16C16 13.9477 16.4477 13.5 17 13.5V12.5ZM15 5V22H16V5H15ZM10 22V5H9V22H10ZM12.5 2.5C13.2212 2.5 13.7119 2.50106 14.0793 2.55046C14.4323 2.59792 14.5958 2.68161 14.7071 2.79289L15.4142 2.08579C15.0862 1.75773 14.6764 1.62175 14.2125 1.55938C13.763 1.49894 13.193 1.5 12.5 1.5V2.5ZM16 5C16 4.30703 16.0011 3.737 15.9406 3.28747C15.8783 2.82355 15.7423 2.41384 15.4142 2.08579L14.7071 2.79289C14.8184 2.90418 14.9021 3.06769 14.9495 3.42072C14.9989 3.78813 15 4.27876 15 5H16ZM12.5 1.5C11.807 1.5 11.237 1.49894 10.7875 1.55938C10.3236 1.62175 9.91384 1.75773 9.58579 2.08579L10.2929 2.79289C10.4042 2.68161 10.5677 2.59792 10.9207 2.55046C11.2881 2.50106 11.7788 2.5 12.5 2.5V1.5ZM10 5C10 4.27876 10.0011 3.78813 10.0505 3.42072C10.0979 3.06769 10.1816 2.90418 10.2929 2.79289L9.58579 2.08579C9.25773 2.41384 9.12175 2.82355 9.05938 3.28747C8.99894 3.737 9 4.30703 9 5H10ZM5 8.5H8V7.5H5V8.5ZM9 9.5V22H10V9.5H9ZM4 22V9.5H3V22H4ZM8 8.5C8.55228 8.5 9 8.94772 9 9.5H10C10 8.39543 9.10457 7.5 8 7.5V8.5ZM5 7.5C3.89543 7.5 3 8.39543 3 9.5H4C4 8.94772 4.44772 8.5 5 8.5V7.5Z"
        fill={Colors.FoldPixel.GRAY300}
      />
    </Svg>
  );
};

export default GraphIconSvg;
