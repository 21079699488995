import {StyleSheet} from 'react-native';
import {Colors} from '../../../../../styles';

export const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.Custom.Gray100,
    borderRadius: 15,
    flex: 1,
    justifyContent: 'space-between'
  },

  noPreview: {
    marginTop: 8,
    padding: 12,
    backgroundColor: Colors.Custom.Gray100,
    borderRadius: 15,
    maxHeight: 300,
    overflow: 'scroll',
  }
});
