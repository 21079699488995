import {Formio} from '@foldhealth/formiojs';
import BasicFields from '../EditFormFields/BasicFields';
import ConditionalComponents from '../EditFormFields/ConditionalFields';
import KeyField from '../EditFormFields/KeyField';
import {FormComponentLabelEnum} from '../FormEnum';

const field = Formio.Components.components.field;
Formio.Components.components.radio.schema = (...extend: any) => {
  return field.schema(
    {
      type: 'radio',
      inputType: 'radio',
      label: FormComponentLabelEnum.radio,
      key: 'radio',
      values: [{label: '', value: ''}],
      validate: {
        onlyAvailableItems: false,
        customMessage: 'Field is required',
      },
      fieldSet: false,
    },
    ...extend
  );
};

Formio.Components.components.radio.editForm = function () {
  return {
    key: 'display',
    components: [
      ...BasicFields,
      {
        type: 'datagrid',
        input: true,
        // label: 'Values',
        label: 'Options',
        key: 'values',
        tooltip:
          'The radio button values that can be picked for this field. Values are text submitted with the form data. Labels are text that appears next to the radio buttons on the form.',
        reorder: true,
        components: [
          {
            label: 'Label',
            key: 'label',
            input: true,
            type: 'oldtextfield',
          },
          {
            label: 'Value',
            key: 'value',
            input: true,
            type: 'oldtextfield',
            allowCalculateOverride: true,
            hidden: true,
            clearOnHide: false,
            calculateValue: {_camelCase: [{var: 'row.label'}]},
            validate: {
              required: true,
            },
          },
        ],
      },
      // {
      //   type: 'select',
      //   input: true,
      //   label: 'Number of columns',
      //   key: 'numberOfColumns',
      //   dataSrc: 'values',
      //   weight: 651,
      //   defaultValue: '1',
      //   tooltip: 'Values will be displayed in selected number of columns',
      //   data: {
      //     values: [
      //       {label: '1', value: '1'},
      //       {label: '2', value: '2'},
      //       {label: '3', value: '3'},
      //     ],
      //   },
      // },
      // {
      //   type: 'checkbox',
      //   input: true,
      //   key: 'inline',
      //   label: 'Inline Layout',
      //   tooltip: 'Displays the checkboxes/radios horizontally.',
      //   weight: 650,
      // },
      // KEPT FOR FUTURE
      // {
      //   type: 'checkbox',
      //   label: 'Hide Label',
      //   tooltip:
      //     'Hide the label or title of this component. This allows you to show the label in the form builder, but not when the form is shown to the member.',
      //   key: 'hideLabel',
      //   input: true,
      // },
      ...ConditionalComponents,
      ...KeyField,
    ],
  };
};
