import { StyleSheet } from 'react-native';
import { Colors } from '../../../../styles/Colors';
import React from 'react';
const baseMargin = {
  marginTop: 24,
  marginLeft: 24,
  marginRight: 24,
  marginBottom: 24,
};
export const styles = StyleSheet.create({
  workflowGraphPopup: { margin: 0},
  titleWrapper: {
    marginHorizontal: 22,
    marginVertical: 12,
  },
  workflowListView: {
    width: 'full',
  },
  container: {},
  tableContainer: {
    ...baseMargin,
    overflow: 'hidden',
    display: 'flex',
  },
  shadow: {
    shadowColor: 'black',
    shadowOffset: {width: 2, height: 2},
    shadowOpacity: 0.2,
    shadowRadius: 8,
    elevation: 10,
  },
  shadowBoxWorkflowMainTable: {
    overflow: 'hidden',
    // marginLeft: 20,
    // marginRight: 20,
  },
  loadingView: {
    ...baseMargin,
  },
});

export const workflowListViewStyle = StyleSheet.create({
  loadingSpinner: {
    position: 'absolute',
    marginTop: '200px',
    marginLeft: '650px',
    zIndex: 1000,
  },
});

export const workFlowFilterTagStyle = StyleSheet.create({
  fiterBtnContainer: {
    borderRadius: 18,
    width: 140,
    justifyContent: 'flex-start',
    marginLeft: 16,
    alignItems: 'center',
    padding: 8,
  },
  selectedTagList: {
    borderWidth: 1,
    borderRadius: 12,
    paddingHorizontal: 2,
    marginHorizontal: 2,
    marginVertical: 2,
    justifyContent: 'space-between',
  },
});

export const addOrUpdateWorkflowStyes = StyleSheet.create({
  loadingHStack: {
    space: 6,
    justifyContent: 'center',
  },
  loadingSpinner: {
    position: 'absolute',
    marginTop: '250px',
    marginLeft: '-50px',
    color: 'red',
    zIndex: 10000,
  },
  mainView: {
    backgroundColor: 'rgb(249,247,247)',
    width: 'full',
    height: 900,
  },
  textStyle: {
    fontWeight: '400',
    fontSize: 24,
    color: Colors.Custom.Gray400,
  }
});

export const workflowFooterStyle = StyleSheet.create({
  footerFlex: {
    backgroundColor: '#ffff',
  },
  button: {
    marginTop: 10,
    marginBottom: 6,
  },
});

export const workflowHeaderStyle = StyleSheet.create({
  mainView: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    height: '60px',
    alignItems: 'center',
    backgroundColor: '#ffff',
  },
  tab: {
    flexDirection: 'row',
    fontWeight: 'bold',
    width: 'full',
  },
  iconButton: {
    width: '6',
    height: '6',
    variant: 'PRIMARY',
    borderRadius: 50,
  },
  icon: {
    size: 'xs',
    color: 'white',
  },
  text: {
    marginLeft: '10px',
  },
});

export const workflowTriggerStyle = StyleSheet.create({
  conditionView: {
    height: 'container',
    marginTop: '5px',
    width: '100%'
  },
  height36: {
    height: 36
  },
  marginTop8: {
    marginTop: 8
  },
  text2: {
    marginHorizontal: 0, 
    paddingVertical: 10 
  },
  conditionView2: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    overflow: 'scroll',
    width: '100%',
    height: '500px'
  },
  borderRadius12: {
    borderRadius: 12
  },
  fullWidthNoMarginHorizontal: {
    width: '100%',
    marginHorizontal: 0
  },
  marginLeft20: {
    marginLeft: 20
  },
  fullWidthMarginTop15: {
    marginTop: 15,
    width: '100%'
  },
  view6: {
    // backgroundColor: Colors.primary[100],
    width: 25,
    height: 25,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
  },
  marginTop20: {
    marginTop: 20
  },
  paginationView: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 15,
  },
  fontSize15: {
    fontSize: 15
  },
  mainView: {
    margin: '20px',
    backgroundColor: '#ffff',
    marginHorizontal: '100',
  },
  pressable: {
    borderColor: '#ddd5d5',
    marginHorizontal: '15px',
  },
  justifyCenter: {
    justifyContent: 'center',
    width: '100%'
  },
  workflowNameTextLabel: {
    // marginHorizontal: 15,
    // marginBottom: 5,
    // fontSize: 18,
    // fontWeight: 'bold',
    color: '#000000',
    fontSize: 20,
    fontWeight: '500',
    margin:'2%'
  },
  workflowNameInput: {
    // marginHorizontal: '15px',
    width:'100%'

  },
  workflowNameFlex: {
    alignItems: 'flex-start',
    marginTop: 15,
    width:'100%'
  },
  mainFlex: {
    height: 'container',
    // marginTop: 15,
    //alignItems: 'center',
    //justifyContent: 'center',
  },
  workflowSubtitle:{
    fontSize: 14,
    fontWeight: '400',
    color: '#000000',
    opacity: 0.3,
    lineHeight: 16.8,
    fontStyle: 'normal',
  },
  hStackStyle: {
    width: '100%',
    marginTop: 30,
    borderBottomWidth: 1,
    borderBottomColor: Colors.Custom.Gray200,
  },
  view: {
    borderWidth:.5,
    height: 16,
    borderColor:Colors.FoldPixel.GRAY200
  },
  view1: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    overflow: 'scroll',
    width: '100%',
    height: 'auto',
    maxHeight: window.innerHeight - 250
  },
  view2: {
    height:'32px',
    justifyContent:'center',
    paddingHorizontal:'8px',
    borderRadius:4
  },
  view3: {
    borderBottomWidth: 2,
    borderBottomColor: Colors.secondary[500],
    marginRight: 10,
    paddingVertical: 10,
  },
  alignSelfCenter: {
    alignSelf: 'center'
  },
  text5: { 
    fontSize: 14, 
    paddingLeft: '16px' , 
    fontWeight:'300', 
    lineHeight: 14
  },
  justifyContentCenter: {
    justifyContent: 'center'
  },
  fullWidth: {
    width: '100%'
  },
  dayElement: { 
    width: '100%', 
    marginTop: 15 
  },
  borderColorGray200: {
    borderColor: Colors.FoldPixel.GRAY200
  },
  text1: {
    marginHorizontal: 0, 
    paddingVertical: 10 
  },
  view4: { 
    marginTop: 20, 
    flexDirection: 'row', 
    flexWrap: 'wrap' 
  },
  view5: {
    marginRight: 10,
    marginVertical: 5,
    borderRadius: 15,
    padding: 5,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderColor: Colors.secondary[500],
  },
  stackStyle: {
    backgroundColor: Colors.Custom.Red100, 
    marginTop:4, 
    borderRadius: 4
  }
});

export const reactStyles: Record<string, React.CSSProperties> = {
  colorGray300: {
    color : Colors.FoldPixel.GRAY300
  },
  multiSelectStyle: {
    backgroundColor: 'white',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: 'gray',
  },
  noSpace: {
    padding: 0, 
    margin: 0
  },
  borderColorGray200: {
    borderColor: Colors.FoldPixel.GRAY200
  },
  dropdownStyles: { 
    background: 'white', 
    padding: '8px', 
    borderRadius: '4px', 
    fontSize: '14px' 
  }
};
