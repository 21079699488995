import React from 'react';
import { Path, Svg } from "react-native-svg";
import { View } from 'react-native';
import { interventionSvgStyles } from '../InterventionSvgStyles';

const EducationContentSvg = () => {

  return (
    <View style={interventionSvgStyles.leftSvgStyle}>
      <Svg
        width="22"
        height="21"
        fill="none"
        viewBox="0 0 16 14"
        strokeWidth="0"
      >
        <Path
          fill="#6F7A90"
          d="M13.388 1.012l.017.5-.017-.5zM11 1.325l-.143-.479.143.479zm-1.884.877l-.25-.433.25.433zM2.655 1.05l-.03.499.03-.499zm2.012.275l.127-.483-.127.483zm2.188.925l-.234.442.234-.442zm2.23 10.13l.235.44-.235-.44zm2.248-.958l-.127-.483.127.483zm1.99-.273l.032.499-.032-.5zm-6.408 1.23l-.235.442.235-.441zm-2.248-.957l.127-.483-.127.483zm-1.99-.273l-.032.499.032-.5zm-.844-1.387v-7.43h-1v7.43h1zm13.334 0V2.29h-1v7.473h1zM13.37.512c-.76.027-1.751.106-2.514.334l.286.958c.633-.19 1.516-.266 2.262-.292l-.034-1zm-2.514.334c-.664.199-1.41.586-1.992.923l.501.866c.567-.328 1.23-.667 1.777-.831l-.286-.958zm-8.233.703c.644.04 1.374.117 1.915.26l.255-.967c-.64-.17-1.45-.25-2.108-.291l-.062.998zm1.915.26c.642.169 1.431.539 2.082.883l.468-.884c-.664-.35-1.537-.767-2.295-.966l-.255.966zM9.32 12.82c.661-.352 1.48-.74 2.14-.915l-.254-.967c-.78.206-1.684.641-2.356 1l.47.882zm2.14-.915c.536-.142 1.255-.218 1.895-.258l-.063-.998c-.653.04-1.453.122-2.086.289l.255.967zm-4.31.032c-.672-.358-1.575-.793-2.356-1l-.255.968c.662.174 1.48.563 2.14.915l.471-.883zm-2.356-1c-.633-.166-1.433-.248-2.086-.288l-.063.998c.64.04 1.359.117 1.894.258l.255-.967zm9.373-1.176c0 .458-.38.857-.875.888l.063.998c.982-.062 1.812-.861 1.812-1.886h-1zm1-7.473c0-.976-.777-1.812-1.796-1.777l.034 1c.41-.015.762.322.762.777h1zM.833 9.762c0 1.025.83 1.824 1.812 1.886l.063-.998c-.496-.031-.875-.43-.875-.888h-1zm8.017 2.176a1.816 1.816 0 01-1.7 0l-.47.883a2.816 2.816 0 002.64 0l-.47-.883zM8.865 1.77a1.846 1.846 0 01-1.776.04l-.468.883a2.846 2.846 0 002.745-.057l-.5-.866zm-7.032.563c0-.465.369-.809.791-.783l.062-.998C1.646.487.833 1.332.833 2.33h1zm5.667.335v10h1v-10h-1z"
        ></Path>
      </Svg>
    </View>
  );
};

export default EducationContentSvg;
