import {Platform, StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  mainScreenLoader: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    height: Platform.OS === 'web' ? '40px' : 40,
    width: Platform.OS === 'web' ? '40px' : 40,
    display: 'flex',
    justifyContent: 'center',
    paddingRight: Platform.OS === 'web' ? 0 : 40,
    paddingBottom: Platform.OS === 'web' ? 0 : 40,
    zIndex: 9999999999999,
    // 'box-shadow': 'rgb(94 109 124 / 30%) 0px 2px 4px -1px, rgb(94 109 124 / 14%) 0px 4px 5px 0px, rgb(94 109 124 / 12%) 0px 1px 10px 0px',
  },
  loaderView: {
    alignSelf: 'center',
    borderRadius: Platform.OS === 'web' ? 0 : 20,
    borderWidth: Platform.OS === 'web' ? 0 : 1,
    borderColor: Platform.OS === 'web' ? '' : '#f9f9f9',
    backgroundColor: Platform.OS === 'web' ? '' : '#f9f9f9',
  },
});
