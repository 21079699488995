import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {IRichTextProps} from './interface';
import './RichText.css';

export const RichText = (props: IRichTextProps) => {
  const {onChangesValue} = props;

  const modules = {
    toolbar: [
      [{header: [1, 2, false]}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{list: 'ordered'}, {list: 'bullet'}, {indent: '-1'}, {indent: '+1'}],
      ['link', 'image'],
      ['clean'],
    ],
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
  ];

  return (
    <ReactQuill
      className="conversation-messaging"
      theme="snow"
      value={props.valueStr}
      onChange={onChangesValue}
      onKeyDown={(e) => {
        const keyCode = e.which || e.keyCode;
        if (keyCode === 13 && !e.shiftKey) {
          e.preventDefault();
          if (props.isEnterToSubmit) {
            props.onRichTextAction();
          }
        }
      }}
      modules={modules}
      formats={formats}
      placeholder="Shift + enter for new line."
      style={{
        backgroundColor : props.isAutomationView ? 'white' : undefined,
      }}
    />
  );
};
