import {useLazyQuery, useQuery} from '@apollo/client';
import {Spin} from 'antd';
import {debounce} from 'lodash';
import {HStack, Text, useToast} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../constants/Configs';
import {ISearchFieldProps} from '../../../Interfaces';
import {FormsQueries} from '../../../services';
import {showToast, ToastType} from '../../../utils/commonViewUtils';
import {ModalActionAntSelect} from '../ModalActionCommonComponent/ModalActionAntSelect';
import Feather from 'react-native-vector-icons/Feather';
import { Colors } from '../../../styles';
import { GET_FORM_CATEGORIES } from '../../../services/Forms/FormsQueries';
import { FORM_CATEGORY_TYPES } from '../../RightSideContainer/Forms/FormsConstants';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { isSendProviderRequestFormAllowed } from '../../RightSideContainer/Forms/FormsUtils';
import {getUserUUID, isMultiTenancyEnabled} from '../../../utils/commonUtils';
import {ModuleCodes, useModuleSpecificUserLocations} from '../../CustomHooks/useModuleSpecificUserLocations';
import {usePermissions} from '../../CustomHooks/usePermissions';
import {USER_ACCESS_PERMISSION} from '../../RightSideContainer/UserAccess/UserAccessPermission';
import {MAIN_MENU_CODES} from '../../SideMenuBar/SideBarConst';

interface IFormSearch extends ISearchFieldProps {
  infoMessage?: string;
  isVisitNoteTemplate?: boolean;
  isShowProviderRequestForm?: boolean;
  formLocations?: string[];
  useAbsoluteLocations?: boolean;
  disabledInlineFormEdit?: boolean;
  disableAddNewForm?: boolean;
  onInitSetDefaultForm?: (formData: any) => void;
  setDefaultValueOnInit?: boolean;
  allowClear?: boolean;
  displayAllForms?: boolean;
}

const FormSearch = (props: IFormSearch) => {
  const {value, isShowError, onChange, infoMessage, isVisitNoteTemplate, isShowProviderRequestForm, useAbsoluteLocations} = props;
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<any[]>([]);
  const [noteCategoryId, setNoteCategoryId] = useState<string | undefined>(undefined);
  const toast = useToast();

  const context = useContext(CommonDataContext);
  const userSettings = context.userSettings;
  const isEnabledMultiTenancy = isMultiTenancyEnabled(userSettings);
  const userUUID = getUserUUID();
  const {check} = usePermissions();
  const permissionConfig = check(USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code, MAIN_MENU_CODES.FORMS);
  const currentUserAllowedLocations = permissionConfig?.allowedLocationIds || [];

  const {loading: allCategoriesLoading} = useQuery(GET_FORM_CATEGORIES, {
    fetchPolicy: 'no-cache',
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
    onError(error) {
      setNoteCategoryId(undefined);
    },
    onCompleted(data) {
      if (data?.formCategories?.length) {
        const noteFieldCategory = (data?.formCategories || []).find((formCategory : any) => {
          return formCategory?.code === 'SOAP';
        });
        let noteFieldCategoryId;
        if (noteFieldCategory?.id) {
          noteFieldCategoryId = noteFieldCategory?.id;
        } else if (data?.formCategories[0]?.id) {
          noteFieldCategoryId = data?.formCategories[0]?.id;
        }
        setNoteCategoryId(noteFieldCategoryId || undefined);
      }
    },
  });

  useEffect(() => {
    if (value && value.id && formData.length === 0) {
      setFormData([
        {
          id: value.id,
          name: value.name,
          metadata: value.metadata,
          isHealthComponentIncluded: value.isHealthComponentIncluded
        },
      ]);
    }
    if (noteCategoryId) {
      onSearch('', props?.setDefaultValueOnInit);
    }
  }, [noteCategoryId]);



  const [searchForms] = useLazyQuery(FormsQueries.SEARCH_FORMS_WITH_LOCATION, {
    fetchPolicy: 'no-cache',
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
  });

  const [searchFormByCategory] = useLazyQuery(FormsQueries.SEARCH_FORM_BY_CATEGORY, {
    fetchPolicy: 'no-cache',
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
  });

  const onSearch = async (searchString: string, setDefaultValue=false) => {
    setFormData([]);
    setLoading(true);
    const params: any = {
      name: `%${searchString}%`,
      offset: 0,
      limit: 10,
      locationIds: isEnabledMultiTenancy? (props?.formLocations?.length ? props?.formLocations : currentUserAllowedLocations) : [],
      useAbsoluteLocations: useAbsoluteLocations ?? undefined
    }
    let formResponse: any = {forms: []};
    try {
      if (isVisitNoteTemplate) {
        params['categoryIds'] = noteCategoryId;
        formResponse = await searchForms({variables: {
          params
        }});
      } else {
        formResponse = await searchForms({variables: {
          params
        }});
      }
      setLoading(false);
      if (formResponse?.data?.searchForms?.forms && formResponse?.data?.searchForms?.forms?.length) {
        let forms = formResponse?.data?.searchForms?.forms;
        if (isVisitNoteTemplate) {
          setFormData(forms);
          return forms;
        }
        if (!props?.displayAllForms) {
          forms = forms?.filter((responseForm: any) => {
            const skipCategoryForm = responseForm?.formCategory?.code === FORM_CATEGORY_TYPES.NOTES || responseForm?.formCategory?.code === FORM_CATEGORY_TYPES.CARE_PLAN;
            if (isSendProviderRequestFormAllowed(userSettings) && !isShowProviderRequestForm){
              return (
                responseForm?.formCategory?.code !== FORM_CATEGORY_TYPES.PROVIDER_REQUEST_FORM && !skipCategoryForm
              );
            }
            return !skipCategoryForm;
          });
        }
        setFormData(forms);
        if (props?.onInitSetDefaultForm && setDefaultValue) {
          props?.onInitSetDefaultForm(forms[0]);
        }
      } else {
        setFormData([]);
      }
    } catch(error) {

      showToast(toast, 'Something went wrong. Please try again later', ToastType.error);
      setLoading(false);
    }
  };

  const getDataFromId = (id: any) => {
    const matchedData = formData.filter((form) => {
      return form.id === id;
    });
    if (matchedData.length > 0) {
      return props.isReturnCategory
        ? {
            id: matchedData[0].id,
            name: matchedData[0].name,
            metadata: matchedData[0].metadata,
            categoryId: matchedData[0].categoryId,
            categoryName: matchedData[0].formCategory?.name,
            isHealthComponentIncluded: matchedData[0].isHealthComponentIncluded,
            formLocations: matchedData[0].formLocations
          }
        : {
            id: matchedData[0].id,
            name: matchedData[0].name,
            metadata: matchedData[0].metadata,
            isHealthComponentIncluded: matchedData[0].isHealthComponentIncluded,
            formLocations: matchedData[0].formLocations
          };
    }
  };

  return (
    <>
      <ModalActionAntSelect
        showSearch={true}
        allowClear={props?.allowClear === false ? false : true}
        filterOption={false}
        value={value ? value.id : undefined}
        onSearch={debounce(onSearch, 500)}
        onChange={(value: any[], data: any) => {
          if (data && data.value) {
            onChange(getDataFromId(data.value));
          } else {
            onChange(undefined);
          }
        }}
        placeholder= {isVisitNoteTemplate ? 'Search Note Template' : 'Search Form'}
        loading={loading || allCategoriesLoading}
        notFoundContent={(loading || allCategoriesLoading) && <Spin size="small" />}
        extraStyle={{flex: 1}}
        data={formData}
        optionProps={{key: 'id', value: 'id', label: 'name'}}
        errors={props?.isShowError}
      />
      {!!infoMessage && (
        <HStack alignItems={'flex-start'} space={2} my={1}>
          <Feather name="info" size={16} color={Colors.Custom.Gray500} style={{marginTop: 2}} />
          <Text fontSize={12} color={Colors.Custom.Gray500} width="full">
            {infoMessage}
          </Text>
        </HStack>
      )}
    </>
  );
};

export default FormSearch;
