import {Button, Divider, HStack, Spacer, Text, View, VStack} from 'native-base';
import React from 'react';
import RenderHTML from 'react-native-render-html';
import {BLOOD_PRESSURE_LOINC, HEIGHT_LOINC} from '../../constants';
import { IVitalData } from '../../Interfaces';
import {Colors} from '../../styles/Colors';
import {getDateStrFromFormat} from '../../utils/DateUtils';
import {cmToFeetAndInches, convertVitalValues, inchesToFeetAndInches, isInchesUnit} from '../../utils/vitalUnitConversions';
import {ITask} from '../common/CareDashboard/CareDashboardInterfaces';
import {EntityType} from './TaskEnum';
import VitalCaptureInput, {
  VitalType,
} from './VitalCaptureInput/VitalCaptureInput';
import { testID, TestIdentifiers } from '../../testUtils';
import { Dimensions, StyleSheet } from 'react-native';

export enum BottomViewAction {
  openForm = 'OPEN_FORM',
  captureVital = 'CAPTURE_VITAL',
  captureLabTest = 'CAPTURE_LAB_TEST',
  openContent = 'OPEN_CONTENT',
  markAsComplete = 'MARK_AS_COMPLETE',
  markAsIncomplete = 'MARK_AS_INCOMPLETE',
  changeAssignee = 'CHANGE_ASSIGNEE',
  assignCareJourney = 'ASSIGN_CARE_JOURNEY',
  deleteTask = 'DELETE_TASK',
  markAsMissed = 'MARK_AS_MISSED',
  updatePriority = 'UPDATE_PRIORITY',
  refreshCount = 'REFRESH_COUNT',
}

export enum descriptionDisplayModes {
  SHORT = 'CARD',
  VERBOSE = 'VERBOSE',
}

const descriptionTypes = {
  RICH_TEXT: 'RICH_TEXT',
  TEXT: 'TEXT',
};

const styles = StyleSheet.create({
  unreadToast: {
    color: Colors.Custom.UnReadTost,
  },
  htmlSpan: {
    color: Colors.Custom.textBlackColor,
  },
});

export const getDescription = (
  task: ITask,
  patientName: string | undefined,
  displayMode = descriptionDisplayModes.SHORT,
  vitalList: IVitalData[],
  hideDivider?: boolean,
  hideVitalCapturedDataInDescription?: boolean,
  hidePoolInDescription?: boolean,
  hideEventDataInDescription?: boolean
): JSX.Element | undefined => {
  const vitalCapturedData = getVitalCapturedDataForCompletedTask(task, vitalList);
  const eventData = getTaskEventData(task);

  // const assignedByUserName = task.assignedBy?.name || '';

  let taskDescription: JSX.Element | undefined;

  if (task.description) {
    const entityType = task.referenceData?.entityType;
    const descriptionType =
      entityType === 'INSTRUCTION'
        ? descriptionTypes.RICH_TEXT
        : descriptionTypes.TEXT;

    if (descriptionType === descriptionTypes.RICH_TEXT) {
      if (displayMode === descriptionDisplayModes.SHORT) {
        taskDescription = (
          <Text style={styles.unreadToast} marginTop={2} {...testID(TestIdentifiers.viewInstruction)}>
            View Instruction
          </Text>
        );
      }
    } else {
      const value = `<span>${task.description.replace(/<font/g,"<span").replace(/<\/font/g,"</span") || ''}</span>`;
      taskDescription = (
        <View width={'full'}>
          <RenderHTML
            // NOTE: contentWidth is required to render the html properly without any warning if not provided it takes Dimensions.get('window').width as default
            // this code is only added to remove warning from console
            contentWidth={Dimensions.get('window').width}
            source={{ html: value as string }}
            enableExperimentalMarginCollapsing={true}
            enableExperimentalBRCollapsing={true}
            baseStyle={{
              whiteSpace: 'normal',
              overflow: 'visible',
              width: '100%',
            }}
            tagsStyles={{
              span: {color: Colors.Custom.textBlackColor},
            }}
          />
        </View>
      );
    }
  }

  const hasDescriptionData = taskDescription || vitalCapturedData || task.userPool?.name || eventData;

  if (!hasDescriptionData) {
    return;
  }

  return (
    <VStack>
      {!hideDivider && <Divider marginBottom={4}/>}
      {!hideVitalCapturedDataInDescription && vitalCapturedData ? (
        <Text color="black" {...testID('TaskVitalValue')}>{vitalCapturedData}</Text>
      ) : null}
      {taskDescription}
      {!hidePoolInDescription && task.userPool?.name && (
          <HStack space={2}>
          <Text fontWeight={400} fontSize={14} color="black" marginTop={1} {...testID('TaskPoolName')}>
            {`Task Pool: ${task.userPool?.name}`}
          </Text>
        </HStack>
        )}
      {/* {assignedByUserName ? (
        <Text fontSize={12} fontWeight={400} color="gray.500" marginTop={2}>
          Assigned By: {assignedByUserName}
        </Text>
      ) : null} */}
      {!hideEventDataInDescription && eventData}
    </VStack>
  );
};

// export const getDescription = (
//   task: ITask,
//   patientName: string | undefined,
//   displayMode = descriptionDisplayModes.SHORT
// ): JSX.Element => {
//   const vitalCapturedData = getVitalCapturedDataForCompletedTask(task);
//   const eventData = getTaskEventData(task);

//   const taskDateTime = getDateStrFromFormat(
//     task.startDateTime || new Date(),
//     'Do MMM YYYY'
//   );
//   const priority = task.priority?.value || '';
//   const assignedByUserName = task.assignedBy?.name || '';

//   let taskDescription = null;

//   if (task.description) {
//     const entityType = task.referenceData?.entityType;
//     const descriptionType =
//       entityType === 'INSTRUCTION'
//         ? descriptionTypes.RICH_TEXT
//         : descriptionTypes.TEXT;

//     if (descriptionType === descriptionTypes.RICH_TEXT) {
//       if (displayMode === descriptionDisplayModes.SHORT) {
//         taskDescription = (
//           <Text style={{color: Colors.Custom.UnReadTost}} marginTop={2}>
//             View Instruction
//           </Text>
//         );
//       } else {
//         taskDescription = <Text>hello</Text>;
//       }
//     } else {
//       taskDescription = (
//         <Text color="gray.500" marginTop={2}>
//           {task.description}
//         </Text>
//       );
//     }
//   } else {
//     taskDescription = <Text></Text>;
//   }

//   return (
//     <VStack>
//       {vitalCapturedData ? <Text>{'eventData'}</Text>:null}
//       <Text>{'eventData'}</Text>
//     </VStack>
//   );
// };

export const getVitalCapturedDataForCompletedTask = (task: ITask, vitalList: IVitalData[]): string => {
  let description = '';
  if (task.isCompleted && task.capturedData && task.capturedData.value) {
    if (task.referenceData?.entity?.loinc === HEIGHT_LOINC) {
      if (isInchesUnit(task.capturedData.valueUnit)) {
        const {feet, inches} = inchesToFeetAndInches(
          parseFloat(task.capturedData.value)
        );
        description += `${feet} ft ${inches} in`;
      } else {
        const {feet, inches} = cmToFeetAndInches(
          parseFloat(task.capturedData.value)
        );
        description += `${feet} ft ${inches} in`;
      }
    } else {
      const vitalConfig = vitalList.find(vital => vital.loinc === task.referenceData?.entity?.loinc);
      const unit = task.capturedData.valueUnit || vitalConfig?.unit;
      if (vitalConfig?.displayUnit && unit && vitalConfig?.displayUnit !== unit) {
        const value = convertVitalValues(task.capturedData.value, unit, vitalConfig?.displayUnit, vitalConfig.allowedDecimalPlaces || 0);
        description += `${value} ${vitalConfig.displayUnit}`;
      } else {
        description += task.capturedData.value;
        if (unit) {
          description += ` ${unit}`;
        }
      }
    }
  }
  return description;
};

const getTaskEventDataForCareJourney = (
  task: ITask,
  patientName: string | undefined
): JSX.Element | undefined => {
  if (task && task.referenceData && task.referenceData.careJourney) {
    let eventName = '';
    const careJourney = task.referenceData.careJourney;
    eventName = careJourney.title ? careJourney.title : '';
    return (
      <>
        {eventName && patientName && (
          <>
            <Text
              fontWeight="600"
              color={Colors.Custom.IATextColor}
              marginTop={2}
              {...testID('TaskEventName')}
            >
              {eventName}
            </Text>

            <Text color="gray.500" {...testID('PurchasedFor')}>
              {' purchased for '}
              <Text fontWeight="600" color={Colors.Custom.IATextColor}>
                {patientName ? patientName : ''}
              </Text>
            </Text>
          </>
        )}
      </>
    );
  }
};

const getTaskEventData = (task: ITask): JSX.Element | undefined => {
  if (
    task.referenceData &&
    task.referenceData.eventData &&
    task.referenceData.eventData.value
  ) {
    const eventData = task.referenceData.eventData;
    const event = eventData.value;
    let unit = '';
    let eventName = '';
    let reportedDate = '';
    if (task.referenceData.eventData.entity) {
      const entity = task.referenceData.eventData.entity;
      if (entity.units && entity.units.length > 0) {
        unit = ' ' + entity.units[0];
      }
      if (entity.name) {
        eventName = entity.name;
      }
      if (eventData.submitDate || eventData.observationDate) {
        reportedDate = getDateStrFromFormat(
          eventData.submitDate || eventData.observationDate,
          'Do MMM YYYY, hh:mm a'
        );
      }
    }
    return (
      <Text color="gray.500" marginTop={2} {...testID('ReportedData')}>
        Reported{' '}
        <Text fontWeight="600" color={Colors.Custom.IATextColor}>
          {event}
          {unit ? ' ' + unit : ''}
        </Text>
        {eventName && (
          <Text>
            {' '}
            for{' '}
            <Text fontWeight="600" color={Colors.Custom.IATextColor}>
              {eventName}
            </Text>
          </Text>
        )}
        {reportedDate ? ' on ' + reportedDate : ''}
      </Text>
    );
  }
};

export const isTaskWithType = (task: ITask, type: EntityType): boolean => {
  return (
    task.referenceData &&
    task.referenceData.entityType &&
    task.referenceData.entityType === type
  );
};

export const getCardTag = (task: ITask): EntityType => {
  if (task.isEscalated) {
    return EntityType.ESCALATION;
  }
  if (task.referenceData && task.referenceData.entityType) {
    switch (task.referenceData.entityType) {
      case 'MED':
        return EntityType.MED;
      case 'VITAL':
        return EntityType.VITAL;
      case 'LAB_TEST':
        return EntityType.LAB_TEST;
      case 'FORM':
        return EntityType.FORM;
      case 'EXERCISE':
        return EntityType.EXERCISE;
      case 'CONTENT':
        return EntityType.CONTENT;
      case 'INSTRUCTION':
        return EntityType.INSTRUCTION;
      case 'NUTRITION':
        return EntityType.NUTRITION;
      case 'IMMUNIZATION':
        return EntityType.IMMUNIZATION;
      case 'GOAL':
        return EntityType.GOAL;
      case 'TASK':
        return EntityType.TASK;
      case 'ESCALATION':
        return EntityType.ESCALATION;
    }
  }
  return EntityType.TASK;
};

export const canHideCheckBox = (task: ITask, isWearableEnabled = false) => {
  if (isTaskWithType(task, EntityType.EXERCISE)) {
    return isWearableEnabled;
  }
  return isTaskWithType(task, EntityType.FORM) ||
    (isTaskWithType(task, EntityType.VITAL) && !task.isCompleted)
    ? true
    : false;
};

export const getBottomView = (
  task: ITask,
  loading: boolean,
  vitals: IVitalData[],
  actionBlock: (task: ITask, action: BottomViewAction, actionData?: any) => void
): JSX.Element | undefined => {
  if (isTaskWithType(task, EntityType.FORM) && !task.isCompleted) {
    return (
      <View marginTop={2}>
        <HStack>
          <Spacer />
          <Button
            isLoading={loading}
            isDisabled={task.isCompleted || false}
            rounded="3xl"
            onPress={() => {
              actionBlock(task, BottomViewAction.openForm);
            }}
            {...testID(task.isCompleted ? 'FormSubmitted' : 'FillFormBtn')}
          >
            {task.isCompleted ? 'Form Submitted' : 'Fill the form'}
          </Button>
        </HStack>
      </View>
    );
  } else if (isTaskWithType(task, EntityType.VITAL) && !task.isCompleted) {
    const referenceRange = getVitalRangeFromLoinc(
      vitals,
      task.referenceData.entity.loinc
    );
    const defaultRange = {min: 0, max: 400};
    if (referenceRange.length === 0) {
      referenceRange.push(defaultRange);
    }
    const vitalType = getVitalTypeFromTask(task);
    if (vitalType === VitalType.bloodPressure) {
      referenceRange.push(defaultRange);
    }
    const unit =
      task.referenceData.entity.units &&
      task.referenceData.entity.units.length > 0
        ? task.referenceData.entity.units[0]
        : '';
    return (
      <VitalCaptureInput
        vital={{
          minValue: referenceRange[0].min,
          maxValue: referenceRange[0].max,
        }}
        vital1={
          vitalType === VitalType.bloodPressure
            ? {minValue: referenceRange[1].min, maxValue: referenceRange[1].max}
            : undefined
        }
        loinc={task.referenceData.entity.loinc}
        vitalType={vitalType}
        unit={unit}
        loading={loading}
        onComplete={(data) => {
          actionBlock(task, BottomViewAction.captureVital, data);
        }}
        isKanbanView={true}
        locationId={task?.taskLocations?.[0]?.locationId}
      />
    );
  } else if (isTaskWithType(task, EntityType.CONTENT)) {
    return (
      <View marginTop={2}>
        <HStack>
          <Spacer />
          <Button
            isLoading={loading}
            rounded="3xl"
            onPress={() => {
              actionBlock(task, BottomViewAction.openContent);
            }}
            {...testID('SeeContentBtn')}
          >
            See the content
          </Button>
        </HStack>
      </View>
    );
  }
  return undefined;
};

export const getVitalRangeFromLoinc = (vitals: IVitalData[], loinc: string): any[] => {
  if (loinc === HEIGHT_LOINC) {
    return [{max: 12, min: 0}];
  }
  const matchedData = vitals.filter((vital) => {
    return vital.loinc === loinc;
  });
  if (
    matchedData.length > 0 &&
    matchedData[0].range &&
    matchedData[0].range.length > 0
  ) {
    return matchedData[0].range;
  }
  return [];
};

export const getVitalTypeFromTask = (task: ITask) => {
  if (task.referenceData?.entity?.loinc === BLOOD_PRESSURE_LOINC) {
    return VitalType.bloodPressure;
  } else if (task.referenceData?.entity?.loinc === HEIGHT_LOINC) {
    return VitalType.heightWithFeetAndInches;
  }
  return VitalType.normal;
};
