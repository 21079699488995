import React from 'react';
import {Colors} from '../../../../styles/Colors';
import {ICommonSvgProps} from '../interfaces';

const AutomationSvg = (props: ICommonSvgProps) => {
  const bgColor = props?.customStrokeColor
    ? props?.customStrokeColor
    : Colors.Custom.GraphSubTitleColor;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props?.width || '16'}
      height={props?.height || '16'}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        d="M9.51292 4.76624C10.3805 3.0754 10.8143 2.22998 10.748 1.9315C10.664 1.55303 10.3265 1.30543 9.94053 1.33897C9.63609 1.36543 8.96061 2.03352 7.60966 3.3697L4.51484 6.43066C3.68842 7.24804 3.27521 7.65673 3.34073 8.09335C3.40625 8.52998 3.91126 8.73311 4.92128 9.13936L5.92403 9.54269C6.29675 9.6926 6.48311 9.76756 6.59679 9.892C6.74134 10.0502 6.8131 10.2659 6.79578 10.4901C6.78216 10.6664 6.68424 10.8573 6.48838 11.239C5.62078 12.9298 5.18697 13.7752 5.25326 14.0737C5.3373 14.4522 5.67475 14.6998 6.06078 14.6662C6.36521 14.6398 7.04069 13.9717 8.39164 12.6355L11.4865 9.57454C12.3129 8.75717 12.7261 8.34848 12.6606 7.91185C12.5951 7.47523 12.09 7.2721 11.08 6.86585L10.0773 6.46252C9.70455 6.31261 9.5182 6.23765 9.40451 6.11321C9.25996 5.955 9.18821 5.73931 9.20552 5.51511C9.21914 5.33877 9.31706 5.14793 9.51292 4.76624Z"
        stroke={bgColor}
        stroke-linecap="round"
      />
    </svg>
  );
};

export default AutomationSvg;
