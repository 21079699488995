import React from 'react';
import {useNavigate} from 'react-router-dom';
import {IContactRelations} from '../../../services/ContactRelation/interface';
import {IContact} from '../../RightSideContainer/TeamInbox/Conversations/interfaces';
import RelativeListView from './RelativeListView';
import { Patient } from 'fhir/r4';
import { withMiniContactViewHOC } from '../../MiniContactViewHOC';
// import AntIcon from 'react-native-vector-icons/AntDesign';

interface RelativeContactListProps {
  contactData?: IContact;
  contactId?: string | number | undefined;
  onAddMembersClick?: () => void;
  contactRelations: IContactRelations[];
  patientDetails?: Patient;
  onActionPerformedOnContactRelative: (actionCode:string, actionData?: any) => void;
  navigateOrOpenContactIdDrawer?:(contactId?:string, additionalData?:any) => void;
  isCompactView?: boolean;
}
const RelativeContactList = (props: RelativeContactListProps) => {
  const {contactRelations, onActionPerformedOnContactRelative} = props;
  const contactData = props.contactData;
  const patientDetails = props.patientDetails;
  const contactId = props?.contactId || contactData?.id;
  const navigate = useNavigate();

  const onContactClicked = (contactId: any, contact: any) => {
    const type = contact.contactType
      ? contact.contactType.contactType.value
      : '';
    if (type == 'Customer') {
      props?.navigateOrOpenContactIdDrawer?.(contactId);
    } else if (type == 'Lead') {
      navigate(`/members/prospect/${contactId}`);
    } else {
      navigate(`/members/supporting-memeber/${contactId}`);
    }
  };
  return (
    <>
      <RelativeListView
        contactData={contactData}
        contactId={contactId}
        onClickAction={(contactId, contactData) => {
          onContactClicked(contactId, contactData);
        }}
        contactRelations={contactRelations}
        patientDetails={patientDetails}
        onActionPerformedOnContactRelative={onActionPerformedOnContactRelative}
        isCompactView={props?.isCompactView}
      />
    </>
  );
};

export default withMiniContactViewHOC(RelativeContactList);
