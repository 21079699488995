import {HStack, Pressable, Text} from 'native-base';
import React from 'react';
import {isWeb} from '../../../../../../utils/platformCheckUtils';
import {Colors} from '../../../../../../styles';
import {Keyboard} from 'react-native';
import {MESSAGE_WINDOW_TABS} from '../../../../../../constants';
import {testID} from '../../../../../../testUtils';

export interface IMessagingWindowCommonTabsProps {
  tab: string;
  onTabPress: (tab: string) => void;
  isEmailInbox: boolean;
}

const MessagingWindowCommonTabs = (props: IMessagingWindowCommonTabsProps) => {
  return (
    <HStack height={isWeb() ? 10 : 8}>
      <Pressable
        borderBottomWidth={props?.tab === MESSAGE_WINDOW_TABS.CHAT ? 2 : 0}
        borderColor={Colors.primary['500']}
        alignSelf={isWeb() ? 'flex-end' : 'center'}
        alignItems={'center'}
        flex={0.5}
        onPress={() => {
          props.onTabPress(MESSAGE_WINDOW_TABS.CHAT);
        }}
        {...testID(MESSAGE_WINDOW_TABS.CHAT + 'Tab')}
      >
        <Text fontWeight={500} fontSize={16}>
          {props.isEmailInbox ? 'Email' : MESSAGE_WINDOW_TABS.CHAT}
        </Text>
      </Pressable>
      <Pressable
        borderBottomWidth={props?.tab === MESSAGE_WINDOW_TABS.FILES ? 2 : 0}
        borderColor={Colors.primary['500']}
        alignItems={'center'}
        alignSelf={isWeb() ? 'flex-end' : 'center'}
        flex={0.5}
        onPress={() => {
          props.onTabPress(MESSAGE_WINDOW_TABS.FILES);
        }}
        {...testID(MESSAGE_WINDOW_TABS.FILES + 'Tab')}
      >
        <Text fontWeight={500} fontSize={16}>
          {MESSAGE_WINDOW_TABS.FILES}
        </Text>
      </Pressable>
    </HStack>
  );
};

export default MessagingWindowCommonTabs;
