import {gql} from '@apollo/client';

export interface IStatus {
  id: string;
  value: string;
  code: string;
}

export interface ITask {
  endDateTime: Date;
  id: string;
  startDateTime: Date;
  title: string;
  description: string;
  status: IStatus;
  assigneeId: string;
  isEscalated: boolean;
  patientEducations?: IPatientEducations[];
  isCompleted?: boolean;
  referenceData?: any;
}
export interface IPatientEducations {
  patientEducation: IPatientEducation;
}

export interface ITasksData {
  tasks: ITask[];
}

export interface ITasksVars {
  assigneeId: string;
  patientCareJourneyId?: string;
  startDate: Date;
  endDate: Date;
}

export interface IDocumentType {
  code: string;
}

export interface IPatientEducation {
  documentType: IDocumentType;
  url: string;
  id: string;
  description: string;
}

export const GET_TASKS_BY_ASSIGNEEID = gql`
  query GetTasksByAssigneeId($assigneeId: uuid!, $startDate: String, $endDate: String) {
    getTasks(params: {assigneeIds: [$assigneeId], startDateTime: $startDate, endDateTime: $endDate}) {
      tasks {
        endDateTime
        id
        createdBy
        assigneeTypeCode
        assigneeUser
        startDateTime
        title
        description
        isEscalated
        referenceData
        placeholderTaskId
        placeholderTaskDerivedId
        status {
          id
          value
          code
        }
        patientEducations {
          patientEducation {
            documentType {
              code
            }
            url
            id
            description
          }
        }
        subTasks{
          id
        }
        attachments {
          id
          taskId
          attachmentId
          taskDocumentAttachments
        }
        labels {
          id
          labelId
        }
        orders {
          id
          externalOrderId
          description
          orderDate
          orderNote
          orderType
          reference
        }
      }
    }
  }
`;

export interface ITaskStatusCountCareProgram {
  title: string;
}

export interface ITaskStatusCount {
  patientCareJourneyId?: string;
  completedCount: number;
  totalCount: number;
  patientCareJourney?: {careJourney: ITaskStatusCountCareProgram};
}

export interface ITaskStatusCountData {
  getTaskStatusCount: ITaskStatusCount[];
}

export interface ITaskStatusCountVars {
  assigneeId: string;
}

export const GET_TASK_PROGRESS_BY_ASSIGNEEID = gql`
  query GetTaskStatusCount(
    $assigneeId: String!
    $startDate: String
    $endDate: String
  ) {
    getTaskStatusCount(
      params: {
        assigneeId: $assigneeId
        startDate: $startDate
        endDate: $endDate
      }
    ) {
      completedCount
      patientCareJourneyId
      totalCount
      patientCareJourney {
        title
        careJourney {
          title
        }
      }
    }
  }
`;

export interface ITaskUpdateVars {
  params :ITaskStatusUpdateVars
}

interface ITaskStatusUpdateVars {
  id: string;
  data: {
    statusId: string;
  };
}

export const UPDATE_TASK_STATUS = gql`
  mutation UpdateTask($params: UpdateTaskInput!) {
    updateTask(params: $params) {
      statusId
    }
  }
`;

export const GET_TASKS_GROUP_BY_ASSIGNEE = gql`
  query GetTasksGroupByAssigneeId($params: GetTasksInput!) {
    getTasks(params: $params) {
      tasksByGroup {
        aggregate {
          assigneeId
          count
        }
      }
    }
  }
`;
