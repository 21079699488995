import {Pressable, ScrollView, View} from 'native-base';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {GROUP_MEMBER_TYPE} from '../../../constants';
import {IGroupMember} from '../../../services/Conversations/interfaces';
import {Colors} from '../../../styles';
import {getUserId} from '../../../utils/commonUtils';
import {useKeypress} from '../../CustomHooks/KeyPressHook';
import {GroupMemberListItem} from '../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/GroupMemberList/GroupMemberListItem';
import {styles} from './GroupUserMentionListStyle';
import {IGroupUserMentionListProps} from './interface';
import { getMemberContent, getMemberContentForTask } from './Utils';
import { TestIdentifiers, testID } from '../../../testUtils';
import { useLazyQuery } from '@apollo/client';
import ConversationsQueriesV2 from '../../../services/Conversations/V2/ConversationsQueriesV2';
import { CONVERSATION_ACTION_CODES } from '../../../constants/ActionConst';
import { getUniqueMemberListByUuid } from '../../RightSideContainer/TeamInbox/Conversations/ConversationContainer/ConversationList/ConversationListUtils';

const GroupUserMentionList = (props: IGroupUserMentionListProps) => {
  const {
    onMemberSelection,
    memberList,
    searchString,
    selectedTab,
    // hideCannedResponse,
    mentionForTask,
    inboxId,
    conversationUuid,
    memberListAggergateCount,
    taskUserRoles,
  } = props;

  const selectedIndex = useRef(0)

  const [GetInboxMemberByInboxId] = useLazyQuery(
    ConversationsQueriesV2.GetInboxMemberByInboxId, {
    variables: {
      inboxId: inboxId,
    },
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
  });

  const [GetGroupMembersByConversationUuid] = useLazyQuery(
    ConversationsQueriesV2.GetGroupMembersByConversationUuid,
    {
      variables: {
        conversationUuid: conversationUuid
      },
      fetchPolicy: 'no-cache',
    }
  )

  const groupMemberListMainRef: any = useRef(null);
  const [groupUserMentionListState, setGroupUserMentionListState] = useState<{
    groupMemberList: IGroupMember[];
  }>({
    groupMemberList: [] as IGroupMember[],
  });
  const userId = getUserId();


  useEffect(() => {
    let cannedResponseTop = '-200px';
    let offsetHeight = 0;
    if (props.isReplyMessage) {
      offsetHeight = 110;
      cannedResponseTop = '-301px';
    }
    if (groupMemberListMainRef?.current && !props?.disableTop) {
      if (groupMemberListMainRef.current.offsetHeight < 150) {
        cannedResponseTop =
          '-' +
          (groupMemberListMainRef.current.offsetHeight + offsetHeight) +
          'px';
      }
      groupMemberListMainRef.current.style.top = cannedResponseTop;
    }
  }, [
    groupUserMentionListState?.groupMemberList?.length,
    props.isReplyMessage,
  ]);

  useKeypress(
    'ArrowDown',
    useCallback(() => {
      setGroupUserMentionListState((prev) => {
        let selectedMemberIndex = selectedIndex?.current;
        selectedMemberIndex++;
        if (selectedMemberIndex >= prev.groupMemberList.length) {
          selectedMemberIndex = prev.groupMemberList.length - 1;
        }
        const scrollToElemHeight = selectedMemberIndex * 55;
        if (groupMemberListMainRef?.current) {
          groupMemberListMainRef?.current.scrollTo({y: scrollToElemHeight});
        }
        selectedIndex.current = selectedMemberIndex
        return {
          ...prev,
        };
      });
    }, [groupUserMentionListState]),
    []
  );
  useKeypress(
    'ArrowUp',
    useCallback(() => {
      setGroupUserMentionListState((prev) => {
        let selectedMemberIndex = selectedIndex?.current;
        selectedMemberIndex--;
        if (selectedMemberIndex < 0) {
          selectedMemberIndex = 0;
        }
        const scrollToElemHeight = selectedMemberIndex * 55;
        if (groupMemberListMainRef?.current) {
          groupMemberListMainRef?.current.scrollTo({y: scrollToElemHeight});
        }
        selectedIndex.current = selectedMemberIndex
        return {
          ...prev,
        };
      });
    }, [groupUserMentionListState]),
    []
  );

  useKeypress(
    'Enter',
    useCallback(() => {
      setGroupUserMentionListState((prev) => {
        const member = prev.groupMemberList[selectedIndex?.current];
        if (member) {
            const selectedUser = mentionForTask
            ? getMemberContentForTask(member)
            : getMemberContent(member, inboxId);

          selectedUser && onMemberSelection(selectedUser);
        }
        return {
          ...prev,
        };
      });
    }, [groupUserMentionListState]),
    []
  );

  const getFilterMemberList = (memberList: IGroupMember[]) => {
    let filteredMemberList: IGroupMember[] = [...getUniqueMemberListByUuid(memberList)];
    if (searchString && searchString.trim().includes('@')) {
      filteredMemberList = filteredMemberList.filter((singleMember) => {
        const groupMemberName = singleMember?.user?.name ?? singleMember?.contact?.name;
        // user should not be able to mention him/her self and if there is no valid user/member
        if(!groupMemberName || singleMember?.user?.id === userId){
          return false;
        }
        const lowerCaseSearchString = searchString.trim().replace('@','').toLowerCase();
        const lowercaseUserName = groupMemberName.toLowerCase();

        if (selectedTab === 'internalMessage' ) {
          if (
            singleMember?.groupUserType?.code === GROUP_MEMBER_TYPE.USER
          ) {
            return lowercaseUserName.includes(lowerCaseSearchString);
          } else if (inboxId && inboxId !== -1) {
            return lowercaseUserName.includes(lowerCaseSearchString);
          }
        } else {
          return lowercaseUserName.includes(lowerCaseSearchString);
        }
      });
      return filteredMemberList;
    }

    return [];
  };

  const getMemberDetails = async () => {
    if (inboxId === -1 && conversationUuid) {
      const groupMemberList = await GetGroupMembersByConversationUuid();
      const groupMembers =
        groupMemberList?.data?.groupConversations?.[0]?.groupMembers;
      if (props?.onActionPerformed) {
        props?.onActionPerformed(
          CONVERSATION_ACTION_CODES.CONVERSATION_GROUP_MEMBER,
          groupMembers
        );
      }
      const filteredMemberList = getFilterMemberList(groupMembers)
      return filteredMemberList;
    } else {
      const inboxMemberList = await GetInboxMemberByInboxId();
      const inboxMembers = inboxMemberList?.data?.inboxMembers;
      if (props?.onActionPerformed) {
        props?.onActionPerformed(
          CONVERSATION_ACTION_CODES.CONVERSATION_INBOX_MEMBER,
          inboxMembers
        );
      }
      const filteredMemberList = getFilterMemberList(inboxMemberList?.data?.inboxMembers)
      return filteredMemberList;
    }
  };

  const handleMemberDetails = async () => {
    let groupMemberList = [] as IGroupMember[];
    if (memberListAggergateCount && memberList?.length !== memberListAggergateCount) {
      groupMemberList = await getMemberDetails();
    } else {
      groupMemberList = getFilterMemberList(memberList);
    }
    if (props.searchString.endsWith('\n')) {
      setGroupUserMentionListState((prev) => {
        return {
          ...prev,
          groupMemberList: groupMemberList,
        };
      });
    } else {
      setGroupUserMentionListState((prev) => {
        return {
          ...prev,
          selectedMemberIndex: 0,
          groupMemberList: groupMemberList,
        };
      });
    }
  };

  useEffect(() => {
    handleMemberDetails();
  }, [props.searchString]);



  const getGroupMemberList = (): JSX.Element => {
    if (groupUserMentionListState.groupMemberList?.length) {
      return (
        <ScrollView
          ref={groupMemberListMainRef}
          style={styles.cannedResponsesMain}
          {...testID(TestIdentifiers.scrollListView)}
        >
          {groupUserMentionListState.groupMemberList.map(
            (member: IGroupMember, index: number) => {
              return (
                <Pressable
                  _hover={{bg: Colors.primary[100]}}
                  backgroundColor={
                    selectedIndex?.current === index
                      ? Colors.primary[100]
                      : ''
                  }
                  onPress={() => {
                    const selectedUser = mentionForTask
                      ? getMemberContentForTask(member)
                      : getMemberContent(member, inboxId);
                    onMemberSelection(selectedUser);
                  }}
                  key={member.id}
                >
                  <View py={2} pr={8}>
                    <GroupMemberListItem memberData={member} inboxId={inboxId}  taskUserRoles={taskUserRoles} />
                  </View>
                </Pressable>
              );
            }
          )}
        </ScrollView>
      );
    } else {
      return <View></View>;
    }
  };
  return <View>{getGroupMemberList()}</View>;
};
export default GroupUserMentionList;
