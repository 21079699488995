import React from 'react';

const EditSvg = (props: {
  isActive?: boolean;
  customActiveColor?: string;
  customColor?: string;
  width?: number;
}) => {
  const strokeColor = props.isActive
    ? props.customActiveColor
      ? props.customActiveColor
      : ''
    : props.customColor
    ? props.customColor
    : '#4F2D90';
  const svgWidth = props.width || 20;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={svgWidth}
      height={svgWidth}
      fill="none"
      viewBox={`0 0 20 20`}
    >
      <path
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M14.167 2.5A2.357 2.357 0 0117.5 5.833L6.25 17.083l-4.583 1.25 1.25-4.583L14.167 2.5z"
      ></path>
    </svg>
  );
};

export default EditSvg;
