import { useLazyQuery } from '@apollo/client';
import { Select, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { ISearchFieldProps } from '../../../Interfaces';
import {
  UserQueries,
} from '../../../services';
import { getAccountId } from '../../../utils/commonUtils';
import { IUsersResponse } from '../../RightSideContainer/Contacts/TeamMembers/interfaces';
import { IVirtualPhoneNumber } from '../../RightSideContainer/Workflow/FlowComponent/StateNodes/interface';
import { isUuid } from '../PhoneCalling/utils';
import { IVirtualNumberSearch } from './interface';
import { USER_SEARCH_TYPE } from '../../RightSideContainer/constants';
import { isAccountConfigEnabled } from '../../../utils/configUtils';
import { CONFIG_CODES } from '../../../constants/AccountConfigConst';
import { GLOBAL_ROLE_CODES } from '../../../constants/MlovConst';

const VirtualNumberSearch = (props: ISearchFieldProps) => {
  const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED);
  const {value, isShowError, userSearchType, locationIds, onChange} = props;
  const [componentState, setComponentState] = useState<IVirtualNumberSearch>({
    virtualPhoneData: [],
    loading: true,
  });

  useEffect(() => {
    getNumberList();
  }, []);

  const userboolExp = userSearchType === USER_SEARCH_TYPE.SELECTED_LOCATION_BASED
    ? {
      _or: [
        {
          userPracticeLocations: {
            isDeleted: {
              _eq: false
            },
            accountLocation: {
              uuid: {
                _in: locationIds,
              }
            },
          },
        },
        ...(isMsoEnabled
          ? [{
            userRoles: {
              userRole: {
                userRole: {
                  code: { _in: [GLOBAL_ROLE_CODES.GLOBAL_ADMIN] }
                }
              }
            }
          }]
          : [])
      ]
    }
    : null;

  const [getUsersData, { loading: getUserDataLoading }] = useLazyQuery<IUsersResponse>(
    userSearchType === USER_SEARCH_TYPE.SELECTED_LOCATION_BASED
      ? UserQueries.GET_USERS_FOR_CALENDAR_V2
      : UserQueries.GET_USERS,
    {
      variables: {
        searchString: '%%',
        accountId: getAccountId(),
        ...(userboolExp && { userboolExp })
      },
    });

  const getNumberList = async () => {
    const userDataList = await getUsersData();
    let users = userDataList.data?.users.map(({uuid, name}) => ({uuid, name}));
    users = users ? users.sort((userOne, userTwo) => {
      if(userTwo.name && userOne.name) {
        return userOne.name.toLowerCase() > userTwo.name.toLowerCase() ? 1 : -1
      }
      return 0;
    }) : [];

    setComponentState((prev: any) => {
      return {
        ...prev,
        virtualPhoneData: users,
      };
    });
  };

  const getDataFromId = (uuid: any) => {
    const matchedData = componentState.virtualPhoneData.filter((item: any) => {
      return item.uuid === uuid;
    });
    if (matchedData.length > 0) {
      const virtualPhoneObj = matchedData[0];
      return virtualPhoneObj;
    }
  };

  const getVirtualNumberObjectList = (uuid: string[]) => {
    const matchedData = componentState.virtualPhoneData.filter((item: any) => {
      return uuid.includes(item.uuid);
    });
    return matchedData;
  }

  const getValueOfDropdown = (value: any) => {
    // this handling added for backward compatibility. We will remove this after few release
    let valueResponse;
    if (value && value.virtualNumberList) {
      valueResponse = value.virtualNumberList?.map((item: IVirtualPhoneNumber) => item.userUuid)
    } else if (value && isUuid(value)) {
      valueResponse = value
    } else {
      valueResponse = undefined;
    }
    return valueResponse;
  }

  return (
    <>
      <Select
        dropdownMatchSelectWidth={3}
        size="large"
        allowClear
        showSearch={true}
        mode="multiple"
        filterOption={(input, option) =>
          (option!.children as unknown as string)
            .toLowerCase()
            .includes(input.toLowerCase())
        }
        value={getUserDataLoading ? undefined : getValueOfDropdown(value)}
        onChange={(value: any[], data: any) => {
          if (value && value.length) {
            onChange(getVirtualNumberObjectList(value));
          } else {
            onChange(undefined);
          }
        }}
        placeholder="Search phone numbers"
        notFoundContent={componentState.loading && <Spin size="small" />}
        style={{height: '36px'}}
        className={isShowError && !value ? 'field-error' : ''}
        maxTagCount={'responsive'}
      >
        {componentState.virtualPhoneData.map((virtualNumberObject: any) => {
          return (
            <Select.Option
              key={`${virtualNumberObject.uuid}`}
              value={virtualNumberObject.uuid}
            >
              {virtualNumberObject.name}
            </Select.Option>
          );
        })}
      </Select>
    </>
  );
};

export default VirtualNumberSearch;
