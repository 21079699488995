import {HStack, View, Text, Divider, Input} from 'native-base';
import {useContext, useEffect, useState} from 'react';
import {CommonDataContext} from '../../../context/CommonDataContext';
import { Colors } from '../../../styles/Colors';
import {INPUT_FIELD_TYPE} from '../../RightSideContainer/Workflow/FlowComponent/StateNodes/NodeInputField';
import { RichTextEditor, TEXT_ONLY_MODULES } from '../RichTextEditor/RichTextEditor';
import { Select as AntSelect} from 'antd';
import { CaretDownOutlined, DownOutlined } from '@ant-design/icons';


export interface IOrderTaskField {
  description: string;
  title: string;
  roleId: string;
  priority: string;
  frequencyFieldValue: string;
  orderNameValue: string;
}

export const OrderTaskFields = (props: any) => {
  const getTaskDescription = () => {
    if (props?.value?.description) {
      return props?.value?.description;
    } else if (props?.frequencyField?.displayValue) {
      return `Frequency ${props?.frequencyField?.displayValue}`;
    } else {
      return `Add ${props?.orderInputField?.displayName} order for the member`;
    }
  };

  const getTaskTitle = () => {
    if (props?.orderInputField?.value) {
      let orderTitle = '';
      switch (props?.orderInputField?.fieldType) {
        case INPUT_FIELD_TYPE.MED_SEARCH:
          orderTitle =
            props?.orderInputField?.value?.name ||
            props?.orderInputField?.value?.drugName;
          break;
        case INPUT_FIELD_TYPE.LAB_TEST_SEARCH:
          orderTitle = props?.orderInputField?.value?.name;
          break;
        case INPUT_FIELD_TYPE.IMMUNIZATION_SEARCH:
          orderTitle = props?.orderInputField?.value?.name;
          break;
      }
      return `Prescribe ${orderTitle}`;
    } else if (props?.value?.title) {
      return props?.value?.title;
    } else {
      return `Add ${props?.orderInputField?.displayName} order for the member`;
    }
  };

  const taskPriority = [
    {value: 'routine', displayName: 'Low', id: 'routine'},
    {value: 'urgent', displayName: 'Medium', id: 'urgent'},
    {value: 'asap', displayName: 'High', id: 'asap'},
  ];

  const handleCallback = (
    title?: string,
    description?: string,
    taskPriority?: string,
  ) => {
    if (!title || !description || !taskPriority) {
      props.onChange(undefined);
      return;
    }
    const responseValue = {
      title: title,
      description: description,
      priority: taskPriority,
    };
    props.onChange(responseValue);
  };

  const [orderTaskField, setOrderTask] = useState<IOrderTaskField>({
    description: props?.value?.description || `Add ${props?.orderInputField?.displayName} order for the member`,
    title: props?.value?.title || `Add ${props?.orderInputField?.displayName} order for the member`,
    roleId: props?.value?.roleId || '',
    priority: props?.value?.priority || 'asap',
    frequencyFieldValue: '',
    orderNameValue: '',
  });

  useEffect(() => {
    const description = getTaskDescription();
    const title = getTaskTitle();

    const oldFrequencyValue = orderTaskField.frequencyFieldValue;
    const oldOrderValue = orderTaskField.orderNameValue;
    const currentOrderValue = props?.orderInputField?.value?.name || props?.orderInputField?.value?.drugName;
    const currentFrequencyValue = props?.frequencyField?.displayValue;
    if (currentOrderValue !== oldOrderValue) {
      setOrderTask(prev => {
        return {
          ...prev,
          title: title,
          orderNameValue: currentOrderValue,
        };
      });
      handleCallback(title, orderTaskField.description, orderTaskField.priority);
    }
    if (oldFrequencyValue !== currentFrequencyValue) {
      setOrderTask(prev => {
        return {
          ...prev,
          description: description,
          frequencyFieldValue: currentFrequencyValue,
        };
      });
      handleCallback(orderTaskField.title, orderTaskField.description, orderTaskField.priority);
    }
  }, [props.orderInputField, props.frequencyField]);

  return (
    <>
      <Divider
        marginBottom={5}
        style={{width: '100%'}}
        bg={Colors.Custom.Gray300}
      />
      <HStack>
        <Text color={Colors.FoldPixel.GRAY300}>{`Fold will remind users with following role to add an order for the member`}</Text>
      </HStack>
      <View style={{marginTop: 18}}>
        <HStack>
          <HStack flex={2.4}>
            <Text fontWeight={300} fontSize={16} flex={2} marginTop={1}>
              {'Title'}
              {<Text color="error.500">*</Text>}
            </Text>
          </HStack>
          <HStack flex={7}>
            <View marginY={1} flex={1}>
              <Input
                backgroundColor={props.isAutomationView ? 'white' : undefined}
                borderWidth={props.isAutomationView ? 0.5 : 1}
                isInvalid={props.isShowError && !orderTaskField.title}
                _focus={{
                  borderColor: props.isAutomationView ? Colors.FoldPixel.PRIMARY_FOCUS : Colors.Custom.Gray200
                }}
                placeholder="Add order for the member "
                style={{height: 36}}
                value={orderTaskField.title}
                onChangeText={(value: any) => {
                  setOrderTask(prev => {
                    return {
                      ...prev,
                      title: value,
                    };
                  });
                  handleCallback(
                    value,
                    orderTaskField?.description,
                    orderTaskField.priority,
                  );
                }}
                onBlur={(event: any) => {
                  setOrderTask(prev => {
                    return {
                      ...prev,
                      title: event?.target?.value,
                    };
                  });
                }}
              />
            </View>
          </HStack>
        </HStack>
      </View>
      <View style={{marginTop: 18}}>
        <HStack>
          <HStack flex={2.4}>
            <Text fontWeight={300} fontSize={16} flex={2} marginTop={1}>
              {'Description'}
            </Text>
          </HStack>
          <HStack flex={7}>
            <View marginY={1} flex={1}>
              <RichTextEditor
                modules={TEXT_ONLY_MODULES}
                placeholder="Add order for the member"
                valueStr={orderTaskField.description}
                onChangesValue={(value: any) => {
                  setOrderTask(prev => {
                    return {
                      ...prev,
                      description: value,
                    };
                  });
                  handleCallback(
                    orderTaskField?.title,
                    value,
                    orderTaskField.priority,
                  );
                }}
              />
              {/* <Input
                placeholder="Add Medication order for the member "
                style={{height: 36}}
                value={orderTaskField.description}
                onChangeText={(value: any) => {
                  setOrderTask(prev => {
                    return {
                      ...prev,
                      description: value,
                    };
                  });
                  handleCallback(
                    orderTaskField?.title,
                    value,
                    orderTaskField.priority,
                  );
                }}
                onBlur={(event: any) => {
                  setOrderTask(prev => {
                    return {
                      ...prev,
                      description: event?.target?.value,
                    };
                  });
                }}
                {...props.elementProperty}
              /> */}
            </View>
          </HStack>
        </HStack>
      </View>
      <View style={{marginTop: 18}}>
        <HStack>
          <HStack flex={2.4}>
            <Text fontWeight={300} fontSize={16} flex={2} marginTop={1}>
              {'Task Priority'}
              {<Text color="error.500">*</Text>}
            </Text>
          </HStack>
          <HStack flex={7}>
            <View marginY={1} flex={1}>
              <AntSelect
                suffixIcon={
                  props.isAutomationView?
                  <CaretDownOutlined
                    style={{
                      color: Colors.FoldPixel.GRAY300
                    }}
                  /> :
                  <DownOutlined/>
                }
                style={{ height: '36px', fontSize: '13px' }}
                value={orderTaskField.priority}
                onChange={(itemValue) => {
                  setOrderTask((prev) => ({
                    ...prev,
                    priority: itemValue,
                  }));
                  handleCallback(orderTaskField?.title, orderTaskField?.description, itemValue);
                }}
              >
                {taskPriority?.length &&
                  taskPriority.map((data: any) => (
                    <AntSelect.Option key={data.id} label={data.displayName} value={data.id}>
                      {data.displayName}
                    </AntSelect.Option>
                  ))}
              </AntSelect>
            </View>
          </HStack>
        </HStack>
      </View>
    </>
  );
};
