import { Card, Modal, Typography } from 'antd';
import { Box, Button, HStack, Spinner, View, VStack } from 'native-base';
import { Colors } from '../../../styles';
import ReferralOrderCard from './ReferralOrderCard';
import { useLazyQuery, useMutation } from '@apollo/client';
import CustomAttributeQueries from '../../RightSideContainer/Contacts/CustomField/CustomAttributeQueries';
import { useContext, useEffect, useState } from 'react';
import AddSquareSvg from '../../../assets/Icons/AddSquareSvg';
import ThreeSquaresSvg from '../../../assets/Icons/ThreeSquaresSvg';
import SideCarListSvg from '../../../assets/Icons/SideCarListSvg';
import InsightsCreateReferral from './InsightsCreateReferral';
import { GET_DOC_BY_ATTACHMENTS_IDS } from '../../../services/Document/DocumentQueries';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../constants/Configs';
import { Pressable } from 'react-native';
import SideCarReferralOrderNoDataSvg from '../../../assets/Icons/SideCarReferralOrderNoDataSvg';
import { getAccountId, getUserId, getUserUUID } from '../../../utils/commonUtils';
import { IInsightsReferralOrdersView } from '../../RightSideContainer/TeamInbox/Conversations/ConversationChannelTabs/CreateEFaxConversationDrawer/interface';
import InboxQueries from '../../../services/Inbox/InboxQueries';
import { useIntl } from 'react-intl';
import TrashBinIcon2024 from '../../../assets/Icons/TrashBinIcon2024';
import { MONTH_NAMES, SIDECAR_REFERRAL_MANAGEMENT } from '../constants';
import { CommonDataContext } from '../../../context/CommonDataContext';
import PlusIcon from '../../../components/common/Svg/PlusSvg'

const { Text } = Typography;

const InsightsReferralOrdersView = (props: IInsightsReferralOrdersView) => {
  const { contactData, onBack, formattedContactData, createReferralClicked } = props;
  const [isCreateReferralFormVisible, setCreateReferralFormVisible] = useState<boolean>(props?.isCreateReferralFormVisible || false);
  const [selectedCardDetails, setSelectedCardDetails] = useState<any>(null);
  const [referralOrders, setReferralOrders] = useState<any[]>([]);
  const [deleteValue, setDeleteValue] = useState<any>(null);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [status, setStatus] = useState<string>('ALL');
  const [preview, setPreview] = useState<boolean>(true);
  const [draftCount, setDraftCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const userUUID = getUserUUID();
  const userId = getUserId();
  const accountId = getAccountId();
  const intl = useIntl();
  const commonData = useContext(CommonDataContext);
  const isSidecarContext = commonData?.sidecarContext?.isSidecar;
  const [getReferralOrderList] = useLazyQuery(
    CustomAttributeQueries.GET_REFERRAL_ORDERS,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [getInboxes] = useLazyQuery(InboxQueries.GetEFaxInboxList, {
    fetchPolicy: 'no-cache',
  });

  const getList = async () => {
    setLoading(true);
    try {
      const customBooleanExpression: any = {
        senderUuid: { _eq: userUUID },
        contactUuid: { _eq: formattedContactData?.contactUUID },
      };

      if (status === SIDECAR_REFERRAL_MANAGEMENT.DRAFT) {
        customBooleanExpression.status = { _eq: SIDECAR_REFERRAL_MANAGEMENT.DRAFT };
      }

      const variables = { customBooleanExpression };

      const response = await getReferralOrderList({ variables });

      const fetchedInboxList = await getInboxes({
        variables: {
          userId: userId,
          accountId: accountId,
        },
      });

      const inboxData = fetchedInboxList?.data?.inboxes || [];
      const referralManagementData = response?.data?.referralManagement || [];

      const inboxIdToEFaxMap = inboxData?.reduce((acc: any, inbox: any) => {
        acc[inbox.id] = inbox.channelEfax?.efaxNumber;
        return acc;
      }, {});

      const modifiedReferralManagement = await Promise.all(
        referralManagementData?.map(async (referral: any) => {
          const efaxNumber = inboxIdToEFaxMap[referral?.efaxNumber];
          const attachmentIds = referral?.referralAttachments?.map(
            (attachment: any) => attachment?.attachmentUuid
          );

          const draftCount = response?.data?.referralManagement?.filter((item: any) => item?.status === SIDECAR_REFERRAL_MANAGEMENT.DRAFT)?.length;
          setDraftCount(draftCount)
          let documentInfo = [];
          if (attachmentIds?.length > 0) {
            const docInfo = await documentInformationByAttachmentIds(attachmentIds);
            documentInfo = docInfo?.data?.documents;
          }
          return {
            ...referral,
            attachmentIds: attachmentIds || [],
            documentInfo: documentInfo || [],
            senderEfaxNo: efaxNumber || null,
          };
        })
      );
      const groupedByMonth = modifiedReferralManagement.reduce((acc, referral) => {
        const date = new Date(referral.createdAt);
        const month = date.getMonth();
        const year = date.getFullYear();
        const monthYear = `${MONTH_NAMES[month]} ${year}`;

        if (!acc[monthYear]) {
          acc[monthYear] = [];
        }
        acc[monthYear].push(referral);

        return acc;
      }, {});

      setReferralOrders(groupedByMonth);
      setLoading(false);
    } catch (error) {
      console.log(error)
      setLoading(false);
    }
  };

  const [getDocInfo] = useLazyQuery(
    GET_DOC_BY_ATTACHMENTS_IDS,
    {
      fetchPolicy: 'no-cache',
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
    }
  );

  const documentInformationByAttachmentIds = async (ids: string[]) => {
    try {
      const variables = {
        attachmentIds: ids
      };
      const response = await getDocInfo({
        variables
      });
      return response
    } catch (err) {
      console.log(err)
    }
  }

  const [deleteReferralOrderList] = useMutation(
    CustomAttributeQueries.DELETE_REFERRAL_ORDER,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const deleteOrder = async (id: string) => {
    try {
      const variables = {
        id
      };
      const response = await deleteReferralOrderList({
        variables
      });
      getList();
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getList();
  }, [status]);

  const handleCardClick = (details: any) => {
    setSelectedCardDetails(details);
    if(createReferralClicked){
    const data = {
      contactData,
      preview: true,
      receiverDetails: { name: details?.receiver?.name, uuid: details?.receiver?.uuid, phoneNumber: details?.receiver?.eFaxNo?.[0]?.value, cityName: details?.receiver?.personAddress?.[0]?.cities?.name, zipCode: details?.receiver?.personAddress?.[0]?.zipcodes?.code, speciality: details?.receiver?.speciality?.[0]?.value },
      isEdit: true,
      documents: details?.documentInfo,
      details,
      note: details?.summary,
      inboxId: (details?.efaxNumber),
      onBack: () => {
        getList()
        onBack()
        setPreview(true)
      },
      formattedContactData,
      setCreateReferralFormVisible: () => setCreateReferralFormVisible(false)
    }
      createReferralClicked && createReferralClicked(data);
    }else{
      setCreateReferralFormVisible(true)
    }
  };

  if (isCreateReferralFormVisible) {
    if (createReferralClicked) {
      const data = {
        contactData,
        preview: selectedCardDetails ? preview : false,
        receiverDetails: selectedCardDetails ? { name: selectedCardDetails?.receiver?.name, uuid: selectedCardDetails?.receiver?.uuid, phoneNumber: selectedCardDetails?.receiver?.eFaxNo?.[0]?.value, cityName: selectedCardDetails?.receiver?.personAddress?.[0]?.cities?.name, zipCode: selectedCardDetails?.receiver?.personAddress?.[0]?.zipcodes?.code, speciality: selectedCardDetails?.receiver?.speciality?.[0]?.value } : null,
        isEdit: selectedCardDetails && preview ? true : false,
        documents: selectedCardDetails?.documentInfo,
        details: selectedCardDetails,
        note: selectedCardDetails ? selectedCardDetails?.summary : '',
        inboxId: selectedCardDetails ? (selectedCardDetails?.efaxNumber) : '',
        onBack: () => {
          getList()
          onBack()
          setPreview(true)
        },
        formattedContactData,
        setCreateReferralFormVisible: () => setCreateReferralFormVisible(false)
      }
      createReferralClicked(data);
    } else {
      return (
        <InsightsCreateReferral
          contactData={contactData}
          preview={selectedCardDetails ? preview : false}
          receiverDetails={selectedCardDetails ? { name: selectedCardDetails?.receiver?.name, uuid: selectedCardDetails?.receiver?.uuid, phoneNumber: selectedCardDetails?.receiver?.eFaxNo?.[0]?.value, cityName: selectedCardDetails?.receiver?.personAddress?.[0]?.cities?.name, zipCode: selectedCardDetails?.receiver?.personAddress?.[0]?.zipcodes?.code, speciality: selectedCardDetails?.receiver?.speciality?.[0]?.value } : null}
          isEdit={selectedCardDetails && preview ? true : false}
          documents={selectedCardDetails?.documentInfo}
          details={selectedCardDetails}
          note={selectedCardDetails ? selectedCardDetails?.summary : ''}
          inboxId={selectedCardDetails ? (selectedCardDetails?.efaxNumber) : ''}
          onBack={() => {
            getList()
            onBack()
            setPreview(true)

          }}
          formattedContactData={formattedContactData}
          setCreateReferralFormVisible={() => setCreateReferralFormVisible(false)}
        />
      );
    }
  }

  if (loading) {
    return (
      <VStack
        paddingBottom={0}
        minHeight={'70vh'}
        alignItems='center'
        justifyContent='center'
        height={'100%'}
        padding={4}
        position="relative"
      >
        {loading ? (
          <Spinner
            size='lg'
            position="absolute"
            top="50%"
            left="50%"
            zIndex={1}
          />
        ) :
          <>
            <SideCarReferralOrderNoDataSvg />
            <Text style={{ marginTop: 16, marginBottom: 8, fontSize: 16, color: Colors.FoldPixel.GRAY300 }}>
              {intl.formatMessage({ id: 'createReferralOrder' })}
            </Text>
            {!props?.isReadOnlyView && <Pressable onPress={() => {
              setCreateReferralFormVisible(true);
              setPreview(false);
              setSelectedCardDetails(null);
            }}>
              <HStack
                fontSize={14}
                justifyContent={'space-between'}
                space={1}
                alignItems={'center'}
                padding={2}
                color={'white'}
                backgroundColor={Colors.Custom.PrimaryColor100}
                borderRadius={4}
                borderColor={Colors.Custom.PrimaryColor200}
                borderWidth={1}
                cursor={'pointer'}
              >
                <AddSquareSvg />
                <Text style={{ color: Colors.FoldPixel.PRIMARY300 }}> {intl.formatMessage({ id: 'newReferral' })}</Text>
              </HStack>
            </Pressable>}
          </>
        }
      </VStack>
    );
  }

  return (
    <Card
      title={
        <HStack justifyContent={'space-between'} alignItems={'center'}>
          <HStack space={5} paddingBottom={0}>
            <Pressable onPress={() => { setStatus(SIDECAR_REFERRAL_MANAGEMENT.ALL) }}>
              <HStack
                gap={4}
                justifyContent={'space-between'}
                alignItems={'center'}
                width={'49px'}
                height={'28px'}
                cursor={'pointer'}
                padding={'4px 6px'}
                borderRadius={4}
                borderWidth={status === SIDECAR_REFERRAL_MANAGEMENT.ALL ? 0.5 : 0}
                borderColor={status === SIDECAR_REFERRAL_MANAGEMENT.ALL ? Colors.Custom.shadeGray : 'transparent'}
                backgroundColor={status === SIDECAR_REFERRAL_MANAGEMENT.ALL ? Colors.Custom.ConversationBgColor : 'transparent'}
              >
                <SideCarListSvg />
                <Text style={{ fontSize: 14, fontWeight: '400', color: status === SIDECAR_REFERRAL_MANAGEMENT.ALL ? Colors.Custom.Gray700 : Colors.FoldPixel.GRAY250 }}>All</Text>
              </HStack>
            </Pressable>
            <Pressable onPress={() => { setStatus(SIDECAR_REFERRAL_MANAGEMENT.DRAFT) }}>
              <HStack
                gap={4}
                justifyContent={'space-between'}
                alignItems={'center'}
                height={'28px'}
                width={'93px'}
                cursor={'pointer'}
                padding={'4px 6px'}
                borderRadius={4}
                borderWidth={status === SIDECAR_REFERRAL_MANAGEMENT.DRAFT ? 0.5 : 0}
                borderColor={status === SIDECAR_REFERRAL_MANAGEMENT.DRAFT ? Colors.Custom.shadeGray : 'transparent'}
                backgroundColor={status === SIDECAR_REFERRAL_MANAGEMENT.DRAFT ? Colors.Custom.ConversationBgColor : 'transparent'}
              >
                <ThreeSquaresSvg />
                <Text style={{ fontSize: '14px', fontWeight: '400', color: status === SIDECAR_REFERRAL_MANAGEMENT.DRAFT ? Colors.Custom.Gray700 : Colors.FoldPixel.GRAY250 }}>Drafts</Text>
                <Box
                  textAlign='center'
                  borderRadius={4}
                  borderWidth='0.5px'
                  borderColor={Colors.Custom.shadeGray}
                  padding='2px 4px'
                  width='16px'
                  height='16px'
                >
                  <Text
                    style={{
                      fontSize: '10px',
                      textAlign: 'center',
                      lineHeight: '12px',
                      color: Colors.FoldPixel.GRAY250,
                    }}
                  >
                    {draftCount}
                  </Text>
                </Box>
              </HStack>
            </Pressable>
          </HStack>
          {!props?.isReadOnlyView && <Pressable onPress={() => {
            setCreateReferralFormVisible(true);
            setPreview(false);
            setSelectedCardDetails(null)
          }}>
            <HStack
              justifyContent={'space-between'}
              alignItems={'center'}
              paddingX={2}
              backgroundColor={Colors.Custom.PrimaryColor100}
              borderRadius={4}
              borderColor={Colors.Custom.PrimaryColor200}
              borderWidth={'0.5px'}
              cursor={'pointer'}
              width={'123px'}
              height={'33px'}
            >
              <PlusIcon width={16} height={16} />
              <Text style={{ color: Colors.FoldPixel.PRIMARY300, fontSize: '14px', fontWeight: '500' }}> {intl.formatMessage({ id: 'newReferral' })}</Text>
            </HStack>
          </Pressable>
          }
        </HStack>
      }
      style={{ backgroundColor: Colors.Custom.Gray50, boxShadow: 'none', border: 'none', ...(!isSidecarContext ? { height: 'auto', minHeight: '100%' } : {}) }}
    >
      {!Object.keys(referralOrders)?.length ? <Text style={{ color: Colors.Custom.Gray400 }} >No Referrals</Text> : Object.keys(referralOrders)?.map((month: any, index: number) => (
        <VStack key={index} gap={2} space={2}>
          <Text style={{ fontSize: '14px', fontWeight: 500, marginTop: 3, color: Colors.FoldPixel.GRAY300 }}>
            {month}
          </Text>
          {referralOrders[month]?.map((item: any, idx: any) => (
            <ReferralOrderCard
              key={idx}
              onEditClick={(val: any) => {
                setCreateReferralFormVisible(true);
                setSelectedCardDetails(val);
                setPreview(false);
              }}
              onDelete={(val: any) => {
                setDeleteValue(val);
                setDeleteModal(true);
              }}
              details={item}
              onClick={() => handleCardClick(item)}
              contactData={contactData}
              isReadOnlyView={props?.isReadOnlyView}
            />
          ))}
        </VStack>
      ))}

      <Modal
        visible={deleteModal}
        onOk={() => {
          if (deleteValue) {
            deleteOrder(deleteValue);
          }
          setDeleteModal(false);
        }}
        onCancel={() => setDeleteModal(false)}
        footer={null}
        centered
        bodyStyle={{ padding: '20px' }}
        width={320}
      >
        <View style={{ alignItems: 'center', marginBottom: 8 }}>
          <TrashBinIcon2024 color={'#D92D20'} height={24} width={24} />
        </View>
        <View style={{ alignItems: 'center', marginBottom: 16 }}>
          <Text style={{ textAlign: 'center', fontWeight: '600', color: Colors.FoldPixel.GRAY400 }}>
            Are you Sure?
          </Text>

          <Text style={{ textAlign: 'center', fontWeight: '600', marginTop: 8 }} color={Colors.FoldPixel.GRAY300}>
            It will permanently delete the record.
          </Text>
        </View>
        <HStack space={4} alignItems={'center'} justifyContent={'center'}>
          <Button
            variant='outline'
            backgroundColor={Colors.Custom.MonochromeWhite}
            borderColor={Colors.FoldPixel.GRAY250}
            borderWidth={0.5}
            _text={{
              color: Colors.FoldPixel.GRAY300,
              fontWeight: '500',
              fontSize: '14px'
            }}
            _hover={{
              backgroundColor: Colors.FoldPixel.GRAY50
            }}
            width={131}
            onPress={() => setDeleteModal(false)}
          >
            Cancel
          </Button>
          <Button
            colorScheme='custom'
            backgroundColor={Colors.Custom.Red100}
            borderRadius={4}
            borderWidth={0.5}
            borderColor={Colors.FoldPixel.STATUS_ERROR}
            _text={{
              color: Colors.FoldPixel.STATUS_ERROR,
              fontWeight: '500',
            }}
            _hover={{
              backgroundColor: Colors.FoldPixel.STATUS_ERROR,
              _text: {
                color: Colors.Custom.Red100
              }
            }}
            width={131}
            onPress={() => {
              if (deleteValue) {
                deleteOrder(deleteValue);
              }
              setDeleteModal(false);
            }}
          >
            Confirm
          </Button>
        </HStack>
      </Modal>


    </Card>
  );
};

export default InsightsReferralOrdersView;
