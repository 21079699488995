import {Text, View} from 'native-base';
import React from 'react';
import {Colors} from '../../../styles';
import {DisplayText} from '../DisplayText/DisplayText';
import {svgStyles} from './SvgStyles';

const NoImmunizationsSvg = (props: {titleId?: string; fillColor?: string}) => {
  const fillColor = props.fillColor || Colors.primary['300'];

  return (
    <View style={svgStyles.container}>
      <svg
        width="76"
        height="67"
        viewBox="0 0 76 67"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.7842 66.6768H60.2148C61.436 66.6798 62.6132 66.2183 63.5104 65.3849C67.2532 61.8987 70.2476 57.6789 72.3092 52.9853C74.3708 48.2916 75.4561 43.2233 75.4984 38.092C75.69 17.2273 58.831 0.0594798 38.0905 0.0102392C17.3382 -0.0390292 0.500025 16.8703 0.500025 37.7362C0.4941 42.9295 1.55704 48.0678 3.62192 52.8276C5.6868 57.5874 8.70889 61.8655 12.4979 65.3926C13.3932 66.2221 14.567 66.6808 15.7842 66.6768Z"
          fill="#FAFAF9"
        />
        <path
          d="M22.5864 48.1068L23.2368 48.7571L5.8187 66.1752C5.73246 66.2614 5.61549 66.3099 5.49353 66.3099C5.37157 66.3099 5.25461 66.2614 5.16837 66.1752C5.08213 66.0889 5.03368 65.972 5.03368 65.85C5.03368 65.7281 5.08213 65.6111 5.16837 65.5249L22.5864 48.1068L22.5864 48.1068Z"
          fill="#D0D5DD"
        />
        <path
          d="M24.6826 40.6669L30.6765 46.6609L22.6919 52.4405C22.4393 52.6233 22.1298 52.7101 21.8189 52.6854C21.5081 52.6607 21.2162 52.526 20.9957 52.3055L19.038 50.3478C18.8175 50.1273 18.6828 49.8354 18.6581 49.5246C18.6333 49.2137 18.7202 48.9042 18.903 48.6516L24.6826 40.6669Z"
          fill="#D0D5DD"
        />
        <path
          d="M30.006 48.0726L23.2707 41.3373C23.001 41.0675 22.8494 40.7016 22.8494 40.3201C22.8494 39.9386 23.001 39.5727 23.2707 39.303L54.2292 8.34453L62.9988 17.1141L32.0403 48.0726C31.7706 48.3423 31.4047 48.4939 31.0232 48.4939C30.6417 48.4939 30.2758 48.3423 30.006 48.0726Z"
          fill="#E4E7EC"
        />
        <path
          d="M47.8297 17.52L53.8237 23.514L67.6725 9.66524L61.6785 3.67125L47.8297 17.52Z"
          fill="#F5F5F4"
        />
        <path
          d="M50.6489 10.7958L60.5475 20.6944C60.8538 21.0006 61.3503 21.0006 61.6566 20.6944L63.5632 18.7877C63.8695 18.4815 63.8695 17.9849 63.5632 17.6786L53.6646 7.78003C53.3584 7.47377 52.8618 7.47377 52.5555 7.78003L50.6489 9.68666C50.3426 9.99293 50.3426 10.4895 50.6489 10.7958Z"
          fill="#D0D5DD"
        />
        <path
          d="M59.05 3.62249L67.721 12.2935C68.0272 12.5997 68.5238 12.5997 68.8301 12.2935L70.7367 10.3868C71.043 10.0806 71.043 9.58403 70.7367 9.27776L62.0657 0.60677C61.7594 0.300504 61.2629 0.300503 60.9566 0.606769L59.05 2.5134C58.7437 2.81967 58.7437 3.31622 59.05 3.62249Z"
          fill="#D0D5DD"
        />
        <path
          d="M43.4262 19.1477L52.1958 27.9174L55.2115 24.9016L46.4419 16.132L43.4262 19.1477Z"
          fill="#D0D5DD"
        />
        <path
          d="M30.7184 46.3522L24.9911 40.625C24.8189 40.4527 24.7222 40.2192 24.7222 39.9756C24.7222 39.7321 24.8189 39.4985 24.9911 39.3263L43.8997 20.4177C43.9594 20.358 44.0303 20.3106 44.1083 20.2783C44.1864 20.246 44.27 20.2294 44.3544 20.2294C44.4389 20.2294 44.5225 20.246 44.6005 20.2783C44.6786 20.3106 44.7495 20.358 44.8092 20.4177L50.9256 26.5342C50.9853 26.5939 51.0327 26.6648 51.065 26.7428C51.0973 26.8208 51.114 26.9044 51.114 26.9889C51.114 27.0734 51.0973 27.157 51.065 27.235C51.0327 27.313 50.9853 27.3839 50.9256 27.4436L32.017 46.3522C31.8448 46.5244 31.6113 46.6212 31.3677 46.6212C31.1242 46.6212 30.8906 46.5244 30.7184 46.3522Z"
          fill={fillColor}
        />
        <path
          opacity="0.2"
          d="M48.1143 14.4598L50.8052 17.1508C50.8866 17.2322 50.9323 17.3426 50.9323 17.4577C50.9323 17.5728 50.8866 17.6831 50.8052 17.7645L50.3205 18.2492C50.2802 18.2895 50.2324 18.3215 50.1797 18.3433C50.1271 18.3651 50.0707 18.3763 50.0137 18.3763C49.9567 18.3763 49.9002 18.3651 49.8476 18.3433C49.7949 18.3215 49.7471 18.2895 49.7068 18.2492L47.0158 15.5583L48.1143 14.4598L48.1143 14.4598Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M43.8125 18.7616L47.7311 22.6801C47.7714 22.7204 47.8033 22.7683 47.8251 22.8209C47.8469 22.8736 47.8582 22.93 47.8582 22.987C47.8582 23.044 47.8469 23.1005 47.8251 23.1531C47.8033 23.2058 47.7714 23.2536 47.7311 23.2939L47.2464 23.7786C47.2061 23.8189 47.1583 23.8508 47.1056 23.8726C47.0529 23.8944 46.9965 23.9057 46.9395 23.9057C46.8825 23.9057 46.8261 23.8944 46.7734 23.8726C46.7208 23.8508 46.6729 23.8189 46.6326 23.7786L42.7141 19.86L43.8125 18.7616Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M39.5107 23.0634L42.2017 25.7543C42.2831 25.8357 42.3288 25.9461 42.3288 26.0612C42.3288 26.1763 42.2831 26.2867 42.2017 26.368L41.717 26.8527C41.6356 26.9341 41.5252 26.9798 41.4101 26.9798C41.295 26.9798 41.1847 26.9341 41.1033 26.8527L38.4123 24.1618L39.5107 23.0634L39.5107 23.0634Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M35.209 27.3646L39.1275 31.2832C39.2089 31.3646 39.2546 31.4749 39.2546 31.5901C39.2546 31.7052 39.2089 31.8155 39.1275 31.8969L38.6429 32.3816C38.5615 32.463 38.4511 32.5087 38.336 32.5087C38.2209 32.5087 38.1105 32.463 38.0291 32.3816L34.1106 28.463L35.209 27.3646Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M30.9073 31.6664L33.5983 34.3573C33.6797 34.4387 33.7254 34.5491 33.7254 34.6642C33.7254 34.7793 33.6797 34.8897 33.5983 34.9711L33.1136 35.4557C33.0733 35.496 33.0255 35.528 32.9728 35.5498C32.9202 35.5716 32.8637 35.5828 32.8067 35.5828C32.7498 35.5828 32.6933 35.5716 32.6407 35.5498C32.588 35.528 32.5402 35.496 32.4999 35.4557L29.8089 32.7648L30.9073 31.6664Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M26.6055 35.9681L30.524 39.8867C30.6054 39.9681 30.6511 40.0785 30.6511 40.1936C30.6511 40.3087 30.6054 40.4191 30.524 40.5004L30.0394 40.9851C29.958 41.0665 29.8476 41.1122 29.7325 41.1122C29.6174 41.1122 29.507 41.0665 29.4256 40.9851L25.5071 37.0666L26.6055 35.9681Z"
          fill="white"
        />
      </svg>

      {props.titleId && (
        <Text style={svgStyles.svgText}>
          <DisplayText textLocalId={props.titleId} textType="Text" />
        </Text>
      )}
    </View>
  );
};

export default NoImmunizationsSvg;
