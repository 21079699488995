import React, { useContext, useEffect, useState } from 'react';
import { ContentTypes } from '../../../../../RightSideContainer/ContentManagement/ContentManagementConsts';
import { getAccountMergeTagData, getTemplateCategories, getTemplateCategoryList, getTemplates } from '../../../../../RightSideContainer/ContentManagement/ContentManagementUtils';
import { getFormattedEmailTemplateData } from '../../../../../RightSideContainer/ContentManagement/EmailTemplates/EmailTemplatesUtils';
import { IEmailTemplateData } from '../../../../../RightSideContainer/ContentManagement/EmailTemplates';
import { ITemplateCategory } from '../../../../../RightSideContainer/ContentManagement/EmailTemplates/interfaces';
import { IAppointmentDetail } from '../../../CalendarWidgetInterfaces';
import { Liquid } from 'liquidjs';
import { getCurrentTimeZone, getCurrentTimeZoneAbbr, getDateStrFromFormatWithTimezone } from '../../../../../../utils/DateUtils';
import { getAppointmentTimeWithTimeZone } from '../../BookAppointment/BookAppointmentHelper';
import { getMlovListFromCategory } from '../../../../../../utils/mlovUtils';
import { BUTTON_TYPE, DATE_FORMATS, MLOV_CATEGORY } from '../../../../../../constants';
import { IMlov } from '../../../../../../Interfaces';
import { CommonDataContext } from '../../../../../../context/CommonDataContext';
import { Drawer, notification } from 'antd';
import { ModalActionTitle } from '../../../../ModalActionTitle/ModalActionTitle';
import { Button, HStack, Select, Skeleton, Spacer, VStack, View } from 'native-base';
import { DisplayText } from '../../../../DisplayText/DisplayText';
import parse from 'html-react-parser';
import { useIntl } from 'react-intl';
import { FoldButton } from '../../../../../CommonComponents/FoldButton/FoldButton';
import { useMutation } from '@apollo/client';
import { AppointmentQueries } from '../../../../../../services';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../../constants/Configs';

interface IProps {
  isOpen: boolean;
  appointment: IAppointmentDetail;
  contactData: any;
  linkCode: string;
  participantId: string;
  appointmentId: string;

  onClose: () => void;
}

interface IComponentState {
  showError: boolean;
  loading: boolean;
  emailTemplateId?: string;
  //html?: string;
  templateData?: any;
}

export function SendAppointmentLinkDrawer(props: IProps) {
  const mlovData = useContext(CommonDataContext);

  const [formattedTemplates, setFormattedTemplates] = useState<IEmailTemplateData[]>([]);
  const defaultValue: IComponentState = {
    showError: false,
    loading: false,
  };
  const [sendAppointmentLinkState, setSendAppointmentLinkState] = useState<IComponentState>(defaultValue);
  const [categoryList, setCategoryList] = useState<ITemplateCategory[]>([]);

  const accountMergeTags = getAccountMergeTagData();

  const defaultTimeZone = getCurrentTimeZone();
  const timezoneAbbr = getCurrentTimeZoneAbbr();

  const scheduleLocationTypeList =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.SCHEDULE_LOCATION_TYPE
    ) || [];

  const locationType: IMlov = scheduleLocationTypeList.find((locationType) => { return locationType.id === props.appointment?.locationTypeId; }) || ({} as IMlov);

  const [sendAppointmentLinkToParticipantApi, sendAppointmentLinkToParticipantAPIData] = useMutation(AppointmentQueries.SEND_APPOINTMENT_LINK_TO_PARTICIPANT, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    onCompleted: (e) => {
      notification.success({
        message: 'Sent email successfully',
      });
      props.onClose();
    },
    onError: (error) => {
      notification.error({
        message: 'Something went wrong',
      });
    },
  });

  useEffect(() => {
    setSendAppointmentLinkState((prev) => ({...prev, loading: true}));
    getTemplateCategories()
      .then((data) => {
        return getTemplateCategoryList(data);
      })
      .then((list) => {
        setCategoryList(list);
      })
      .catch((error) => {});

    getTemplatesByCategoryCode();
  }, []);

  const getTemplatesByCategoryCode = () => {
    const path = `${ContentTypes.emails.path}?category=GENERAL`;
    getTemplates(path)
      .then((response) => {
        return getFormattedEmailTemplateData(response);
      })
      .then((formattedTemplates) => {
        setFormattedTemplates(formattedTemplates)
        if (formattedTemplates.length > 0) {
          const template = formattedTemplates[0];
          setSendAppointmentLinkState((prev) => ({
            ...prev,
            emailTemplateId: template.id || '',
            templateData: template || {},
            loading: false
          }));
        } else {
          setSendAppointmentLinkState((prev) => ({...prev, loading: false}));
        }
      })
      .catch((error) => {
        notification.error({
          message: 'Something went wrong',
        });
        setSendAppointmentLinkState((prev) => ({...prev, loading: false}));
      });
  };

  const getMergeTags = (category: string, categories: ITemplateCategory[]) => {
    const mergeTagsByCategory = categories.find(
      (item) => item.name === category
    )?.mergeTags;
    return {...mergeTagsByCategory, global: accountMergeTags };
  };

  const getAppointmentMergeTags = () => {
    const hostNames: string[] = [];
    props.appointment?.participants?.forEach(p => {
      if (p?.user?.name) {
        hostNames.push(p.user.name);
      }
    });

    return {
      appointment: {
        name: props.appointment.name || '',
        description: props.appointment.description || '',
        date: getDateStrFromFormatWithTimezone(props.appointment.startDateTime, defaultTimeZone, DATE_FORMATS.EMAIL_DISPLAY_DATE_FORMAT),
        time: `${getAppointmentTimeWithTimeZone(props.appointment?.startDateTime, props.appointment?.endDateTime, defaultTimeZone)} (${timezoneAbbr})`,
        location: locationType?.value || '',
        hosts: hostNames?.join(' • ') || '',
        meetingLink: props.linkCode === 'VIRTUAL_MEETING_LINK' ? 'meetingLink' : '',
        meetingPassword: props.linkCode === 'VIRTUAL_MEETING_LINK' ? 'meetingPassword' : '',
        rsvpLink: props.linkCode === 'RSVP_LINK' ? 'rsvpLink' : '',
      },
      patient: {
        name: props.contactData?.name,
      },
    };
  }

  const getPreviewHtml = (templateData: any) => {
    const engine = new Liquid();
    const finalMergeTag = {...getMergeTags(templateData?.templateCategory, categoryList), ...getAppointmentMergeTags()};
    const tpl = engine.parse(templateData?.templateHtml || '');
    return engine.renderSync(tpl, finalMergeTag);
  };

  const onSubmitClick = () => {
    const params = {
      appointmentId: props.appointmentId,
      emailTemplateId: sendAppointmentLinkState.emailTemplateId,
      linkCode: props.linkCode,
      participantId: props.participantId,
    };

    sendAppointmentLinkToParticipantApi({
      variables: {
        params: params,
      },
    });
  }

  const intl = useIntl();

  return (
    <Drawer
      visible={props.isOpen}
      onClose={() => {
        props.onClose();
      }}
      destroyOnClose
      title={
        <HStack justifyContent={'space-between'} alignItems="center">
          <ModalActionTitle title={'Send Email'} titleColor={''} buttonList={[]} />
          <HStack space={2} alignItems={'center'} justifyContent="space-between">
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.SECONDARY,
                onPress: () => {
                  props.onClose();
                },
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'Close',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
            <FoldButton
              nativeProps={{
                isLoading: sendAppointmentLinkToParticipantAPIData.loading,
                variant: BUTTON_TYPE.PRIMARY,
                onPress: () => {
                  onSubmitClick();
                },
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'Send',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
          </HStack>
        </HStack>
      }
      width={'800px'}
      closable={false}
      placement="right"
    >
      {sendAppointmentLinkState.loading && (
        <VStack space={4}>
          <Skeleton.Text />
          <Skeleton rounded="md" />
        </VStack>
      )}
      {!sendAppointmentLinkState.loading && (
        <VStack space={4}>
          <DisplayText textLocalId="template"></DisplayText>
          <Select
            onValueChange={(value: string) => {
              const template = formattedTemplates.find(
                (item:any) => item.id == value
              );
              setSendAppointmentLinkState((prev) => ({
                ...prev,
                emailTemplateId: template?.id || '',
                templateData: template || {},
              }));
            }}
            selectedValue={
              sendAppointmentLinkState.emailTemplateId
            }
          >
            {formattedTemplates.map((item:any) => {
              return (
                <Select.Item
                  label={item.templateName}
                  value={item.id as string}
                >
                  {item.templateName}
                </Select.Item>
              );
            })}
          </Select>

          {sendAppointmentLinkState.templateData?.templateHtml && (
            <View>{parse(getPreviewHtml(sendAppointmentLinkState.templateData))}</View>
          )}
        </VStack>
      )}
    </Drawer>
  );
}
