import BaseService from '../services/CommonService/BaseService';

const baseServiceInstance = BaseService.getSharedInstance().axios;

export const deleteUserPhoneNumberFromCache = async (userUuid: string) => {
  const url = `crm-nest/crm-redis/${userUuid}/phoneNumber`;
  try {
    const response = await baseServiceInstance.delete(url);
    return response.data;
  } catch (error) {
    return null;
  }
};
