import {StyleSheet} from 'react-native';
import {Colors} from '../../../../styles';
import { isWeb } from '../../../../utils/platformCheckUtils';

const styles = StyleSheet.create({
  viewPressable: {
    paddingHorizontal: 10,
  },
  spinner: {
    paddingHorizontal: 10,
  },
  clearAllButton: {
    marginRight: 12,
    flexDirection: 'row',
    alignItems: 'center',
  },
  clearAllButtonText: {
    marginLeft: 6,
  },
  linkNoteText: {
    color: Colors.Custom.alertsDescriptionColor,
    fontWeight: '400',
    fontSize: 14,
  },
  visitNoteLinkElement: {
    borderColor: '',
    backgroundColor: '',
  },
  visitNoteLinkElementHovered: {
    borderColor: Colors.FoldPixel.PRIMARY_FOCUS,
    backgroundColor: Colors.Custom.Primary50,
  },
  additionalDetailLinkElement: {
    borderColor: '',
    backgroundColor: '',
  },
  additionalDetailLinkElementHovered: {
    borderColor: Colors.FoldPixel.PRIMARY_FOCUS,
    backgroundColor: Colors.Custom.Primary50,
  },
  divider: {
    height: 18,
    width: 1,
    backgroundColor: Colors.Custom.Gray200,
  },
  header2: {
    padding: 4,
  },
  header: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  headerText: {
    fontSize: 20,
    fontWeight: '700',
    color: '#101828',
    marginLeft: 10,
  },
  header3: {
    padding: 18,
    // backgroundColor: Colors.Custom.Gray50,
  },
  header3Container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginLeft: 4,
  },
  header3IconContainer: {
    marginRight: 10,
  },
  flatList: {
    marginLeft: 8,
    minHeight: 600
  },
  header2Text: {
    fontSize: 16,
    fontWeight: '700',
    color: '#101828',
    marginLeft: 10,
  },
  clearAll: {
    marginRight: 12,
    flexDirection: 'row',
    alignItems: 'center',
  },
  clearAllText: {
    marginLeft: 6,
  },
  printContainer: {
    display: 'none',
  },
  viewPressable2: {
    paddingHorizontal: 10,
    alignSelf: 'center',
  },
  container: {
    flex:1,
    shadowColor: Colors.Custom.Gray100,
    shadowOffset: {width: 0, height: 0},
    shadowOpacity: 1,
    shadowRadius: 8,
    elevation: 8,
    width: isWeb() ? '100%' : '90%',
    height:'100%',
    backgroundColor:'white',
  },
  timeLineMonth: {

    fontSize: 16,
    fontWeight: '700',
    color: '#101828',
    paddingRight: 4,

    top: 0,
    transform: [{rotate: '-360deg'}],
  },
  dateBox: {
    borderRadius: 4,
    backgroundColor: Colors.Custom.Info50,
    maxWidth: 65,
    height: 30,
    paddingVertical: 4,
    paddingHorizontal: 8,
    marginVertical: 2,
  },
  noteCard: {
    // flexDirection: 'row',
    // alignItems: 'center',
    backgroundColor: Colors.Custom.Gray50,
    borderRadius: 8,
    borderColor: Colors.Custom.Gray100,
    borderWidth: 0.5,
    padding: 12,
    flex: 1,
  },
  noteCardIcon: {
    width: 38,
    height: 38,
    borderRadius: 19,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 2,
    borderColor: Colors.Custom.Gray25,
    shadowRadius: 4,
    shadowColor: Colors.Custom.Gray200,
  },
  moreCardIcon: {
    shadowRadius: 4,
    shadowColor: Colors.Custom.Gray200,
    borderWidth: 1,
    backgroundColor: Colors.Custom.Base50,
    borderColor: Colors.Custom.PrimaryBtnColor,
    width: 32,
    height: 32,
    borderRadius: 18,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  internalNoteDataCard: {
    backgroundColor: Colors.Custom.ContainerBGColor,
    borderRadius: 8,
    borderColor: Colors.FoldPixel.GRAY200,
    borderWidth: 0.5,
    padding: 12,
    flex: 1,
    marginVertical: 2,
    marginTop: 2
  },
  outerNoteTaskCard: {
    backgroundColor: Colors.Custom.White,
    borderRadius: 6,
    borderColor: Colors.FoldPixel.GRAY200,
    borderWidth: 0.5,
    marginTop: 10,
    padding: 2
  },
});

export default styles;


export const reactStyles: Record<string, React.CSSProperties> = {
  webContainer: {
    marginRight: -5,
    overflowY: 'scroll',
    height: '100%',
    flex: 1,
  },
  displayNone: {
    display: 'none'
  },
  fontWeightBold: {
    fontWeight: 'bold'
  },
  flexP5: {
    flex: 0.5
  },
  displayFlex: {
    display: 'flex'
  }
};
