export default {
  theme: 'snow',
  modules: {
    // blotFormatter: {},
    toolbar: [
      // [{size: ['small', false, 'large', 'huge']}],
      [{header: [1, 2, false]}],
      // [],
      [
        'bold',
        'italic',
        'underline',
        'strike',
        // {script: 'sub'},
        // {script: 'super'},
        // 'clean',
      ],
      // [{color: []}, {background: []}],
      // [
      //   {list: 'ordered'},
      //   {list: 'bullet'},
      //   {indent: '-1'},
      //   {indent: '+1'},
      //   {align: []},
      // ],
      // ['blockquote'],
      ['link' /*'image', 'video'*/],
    ],
  },
};
