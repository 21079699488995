import {gql} from '@apollo/client';

const GetResponses = gql`
  query getResponses($searchString: String!) {
    cannedResponses(
      order_by: {updatedAt: desc}
      where: {shortCode: {_ilike: $searchString}}
    ) {
      id
      shortCode
      content
      createdAt
      type_id
      type {
        value
        id
        code
      }
    }
  }
`;
const GetResponsesOnMessageWindow = gql`
  query GetResponsesOnMessageWindow(
    $searchString: String!
    $categoryCodeUuids: [uuid!]
  ) {
    cannedResponses(
      order_by: {updatedAt: desc}
      where: {
        _or: [
          {shortCode: {_ilike: $searchString}}
          {content: {_ilike: $searchString}}
        ]
        type: {id: {_in: $categoryCodeUuids}}
      }
    ) {
      id
      shortCode
      content
      createdAt
      type {
        value
        id
        code
      }
    }
  }
`;

const CreateResponse = gql`
  mutation createResponse(
    $id: uuid!
    $shortCode: String!
    $content: String!
    $accountId: Int!
  ) {
    createCannedResponse(
      object: {
        type_id: $id
        shortCode: $shortCode
        content: $content
        accountId: $accountId
      }
    ) {
      accountId
      content
      createdAt
      id
      updatedAt
      shortCode
    }
  }
`;

const UpdateResponse = gql`
  mutation updateResponse(
    $content: String!
    $shortCode: String!
    $id: bigint!
    $categoryCode: uuid!
  ) {
    updateCannedResponse(
      pk_columns: {id: $id}
      _set: {content: $content, shortCode: $shortCode, type_id: $categoryCode}
    ) {
      accountId
      content
      shortCode
    }
  }
`;

const DeleteResponse = gql`
  mutation MyMutation($id: bigint!) {
    deleteCannedResponse(id: $id) {
      id
    }
  }
`;

const GetFilteredResponses = gql`
  query getResponses($searchString: String!, $categoryCodeUuids: [uuid!]) {
    cannedResponses(
      order_by: {updatedAt: desc}
      where: {
        shortCode: {_ilike: $searchString}
        type: {id: {_in: $categoryCodeUuids}}
      }
    ) {
      id
      shortCode
      content
      createdAt
      type_id
      type {
        value
        id
        code
      }
    }
  }
`;

export default {
  GetResponses,
  GetFilteredResponses,
  CreateResponse,
  UpdateResponse,
  DeleteResponse,
  GetResponsesOnMessageWindow,
};
