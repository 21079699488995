import {View, Text} from 'react-native';
import React from 'react';
import {useOnWindowClose} from '../../CustomHooks/useOnWindowClose';
import {onWindowConfirmExit} from '../../../utils/commonUtils';

const ConfirmOnExitOrReload = ({children}: {children: JSX.Element}) => {
  useOnWindowClose(onWindowConfirmExit);
  return <View>{children}</View>;
};

export default ConfirmOnExitOrReload;
