import React from 'react';
import {Path, Svg, Circle} from 'react-native-svg';

function SysIconSvg(params?: {
  strokeColor?: string;
  width?: string;
  height?: string;
}) {
  return (
    <Svg
      width={params?.width || '9'}
      height={params?.height || '9'}
      fill="none"
      viewBox="0 0 9 9"
    >
      <Circle cx="4.68652" cy="4.5" r="4" fill="#BFD7F6" />
    </Svg>
  );
}

export default React.memo(SysIconSvg);
