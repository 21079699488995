import React from 'react';
import {Path, Svg} from 'react-native-svg';

function RespirationRateIcon(params?: {
  strokeColor?: string;
  width?: string;
  height?: string;
}) {
  return (
    <Svg
      width={params?.width || '16'}
      height={params?.height || '17'}
      fill="none"
      viewBox="0 0 17 16"
    >
      <Path
        d="M7.99977 0.833252V6.43325M7.99977 6.43325C7.99977 7.02235 8.47733 7.49991 9.06643 7.49991M7.99977 6.43325C7.99977 7.02235 7.52221 7.49991 6.9331 7.49991M4.83663 3.05255C7.0037 3.05441 6.8044 6.002 6.80366 7.18111C6.80218 8.68583 7.17151 11.7231 6.01092 12.8792C4.82774 14.0572 2.46322 14.6451 1.28189 13.6616C0.100554 12.6777 2.07833 3.05033 4.83663 3.05255ZM11.1714 3.057C9.0043 3.05515 9.19878 6.00311 9.19767 7.18222C9.19656 8.68732 8.82204 11.7238 9.98115 12.8818C11.1621 14.062 13.5259 14.6533 14.7087 13.672C15.8915 12.6903 13.9297 3.05922 11.1714 3.057Z"
        stroke={params?.strokeColor || '#8C5AE2'}
        strokeWidth="0.666667"
        strokeLinecap="round"
      />
    </Svg>
  );
}



export default React.memo(RespirationRateIcon);
