import React, {useState} from 'react';
import {Modal} from 'antd';
import {Colors} from '../../../styles';
import {BUTTON_TYPE} from '../../../constants';
import {HStack, Text, VStack} from 'native-base';
import {FoldButton} from '../../CommonComponents/FoldButton/FoldButton';
import {useIntl} from 'react-intl';

const PatientIntakeDeleteModal = (props: {
  type: string;
  visible: boolean;
  onCancel: () => void;
  onDelete: () => void;
  isDeleteHistorical?: boolean;
}) => {
  const {type, visible, isDeleteHistorical, onCancel, onDelete} = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const intl = useIntl();

  const isDeleteHistoricalMessage = intl.formatMessage({
    id: 'isDeleteHistoricalMessage',
  });

  const onConfirmDelete = () => {
    setIsLoading(true);
    onDelete();
  };

  const buttonList = [
    {
      show: true,
      id: 1,
      btnText: 'cancel',
      textColor: Colors.Custom.mainPrimaryPurple,
      variant: BUTTON_TYPE.PRIMARY,
      isTransBtn: false,
      onClick: onCancel,
    },
    {
      show: true,
      id: 2,
      btnText: 'delete',

      isTransBtn: false,
      onClick: onConfirmDelete,
      isLoading: isLoading,
      textColor: Colors.Custom.mainSecondaryBrown,
      variant: BUTTON_TYPE.SECONDARY,
    },
  ];

  return (
    <Modal
      open={visible}
      closable={false}
      onCancel={props?.onCancel}
      footer={null}
    >
      <VStack>
        <Text
          mb={1}
          fontSize={'xl'}
          fontWeight={'bold'}
        >{`Delete ${type}?`}</Text>
        <Text
          my={1}
          fontSize={'md'}
        >{`Are you sure you want to delete this ${type}?`}</Text>
        {isDeleteHistorical && (
          <Text my={1} fontSize={'md'}>{`${isDeleteHistoricalMessage}`}</Text>
        )}
        <HStack mt={5} justifyContent={'flex-end'}>
          {buttonList.map((button: any, index: number) => {
            if (button.show) {
              return (
                <FoldButton
                  key={index + button.btnText}
                  nativeProps={{
                    variant: button.variant,
                    onPress: () => {
                      button.onClick();
                    },
                    leftIcon: button.leftIcon,
                    rightIcon: button.rightIcon,
                    isDisabled: button.isDisabled,
                    isLoading: button.isLoading,
                    marginBottom: 1,
                    marginTop: 1,
                    marginLeft: 2,
                  }}
                  customProps={{
                    btnText: intl.formatMessage({id: button.btnText}),
                    withRightBorder: false,
                  }}
                ></FoldButton>
              );
            }
          })}
        </HStack>
      </VStack>
    </Modal>
  );
};

export default PatientIntakeDeleteModal;
