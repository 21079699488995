import { View } from 'native-base';
import { FC } from 'react';
import { NodeProps } from 'reactflow';




const GroupState: FC<NodeProps> = ({ data, id }) => {

  return <View width={10} height={data?.metaData?.height}>


  </View>
};

export default GroupState;
