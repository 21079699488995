import {addTimeToDate} from '../../../../../../utils/DateUtils';
import {IServiceBookingState} from './ServiceBooking';

export const getAppointmentTypeIdsFromWorkflowElement = (
  elements: any[]
): string[] => {
  const appointmentTypeIds: string[] = [];
  elements.forEach((element) => {
    if (
      element.data &&
      element.data.nodeType === 'CareJourneyVisit' &&
      element.data.metaData &&
      element.data.metaData.userInputFieldList
    ) {
      const inputFields = element.data.metaData.userInputFieldList;
      const matchedData = inputFields.filter(
        (input: any) => input.key === 'type'
      );
      if (
        matchedData.length > 0 &&
        matchedData[0].value &&
        matchedData[0].value.id
      ) {
        appointmentTypeIds.push(matchedData[0].value.id);
      }
    }
  });
  return appointmentTypeIds;
};

export const getFilteredUsersByRoleId = (users: any[], roleId: string) => {
  return users
    .filter((user) => {
      if (user.userRoles && user.userRoles.length > 0) {
        const matchedData = user.userRoles.filter((userRole: any) => {
          if (userRole.userRole && userRole.userRole.userRole) {
            return userRole.userRole.userRole.id === roleId;
          }
          return false;
        });
        return matchedData.length > 0;
      }
      return false;
    })
    .map((user) => {
      return {
        id: user.uuid,
        name: user.name,
        roleId: roleId,
      };
    });
};

export const isInvalidData = (
  serviceBookingData: IServiceBookingState
): boolean => {
  let allRolesHaveUsersSelected = true;
  serviceBookingData.userRoleMapping.forEach((userRole) => {
    const matchedData = serviceBookingData.selectedUsers.filter(
      (user) => user.roleId === userRole.roleId && user.userId
    );
    if (matchedData.length === 0) {
      allRolesHaveUsersSelected = false;
    }
  });

  return (
    !serviceBookingData.selectedService ||
    !serviceBookingData.startDate ||
    serviceBookingData.participants.length === 0 ||
    !allRolesHaveUsersSelected ||
    serviceBookingData.selectedUsers.length === 0
  );
};

export const getAppointmentEndDate = (
  date: Date,
  duration: number,
  durationUnitCode: string
): Date => {
  const endDate = addTimeToDate(
    date,
    duration || 0,
    durationUnitCode || 'MONTH'
  );
  return addTimeToDate(endDate, -1, 'DAY');
};
