import {
  HStack,
  Text,
  VStack,
  View,
  Pressable,
  Center,
  Modal,
  Menu,
} from 'native-base';
import React, {useEffect, useState, useMemo} from 'react';
import {StyleSheet} from 'react-native';

import {Colors} from '../../../../styles/Colors';
import InfoSvg from '../../../common/Svg/InfoSvg';
import {
  getPrimaryGenderCode,
  numericStringMask,
} from '../../../../utils/commonUtils';
import {isWeb} from '../../../../utils/platformCheckUtils';
import {Popover} from 'antd';
import {DisplayCardAvatar} from '../../../common/DisplayCard/DisplayCardAvatar';
import {GROUP_MEMBER_TYPE, PHONE_NUMBER_MASK} from '../../../../constants';
import {getPatientOrProspectElem, getUserNameByContact} from './OtherDeatilsUtils';
import { useIntl } from 'react-intl';
import { ILinkedMemberInterface } from './interfaces';
import { IContactType } from '../../../RightSideContainer/TeamInbox/Conversations/interfaces';
import { getEhrConfig } from '../../../../utils/capabilityUtils';
import { getCurrentPatientDeceasedData } from '../../../../services/CommonService/AidBoxService';
import { ScrollView } from 'react-native';

export const LinkedMemberFromNumberView = (props: ILinkedMemberInterface) => {
  const {contacts, contactData, navigate, contactType} = props;
  const [linkNumberMemberVisible, setLinkNumberMemberVisible] = useState(false);
  const phoneNumber = contactData.phoneNumber;
  const intl = useIntl();
  const ehrConfig = getEhrConfig(
    contactData?.contactPracticeLocations?.[0]?.accountLocation?.uuid,
    ''
  );
  const handleVisibleChange = (isVisible: boolean) => {
    setLinkNumberMemberVisible(isVisible);
  };
  const [componentState, setComponentState] = useState<{
    contactDeceasedDateMap:  Map<string, string>
  }>({
    contactDeceasedDateMap: new Map<string, string>(),
  });

  const fetchPatientData = async (contactData: any) => {
    const patientId = contactData?.patient?.patientId;
    if (patientId) {
      const key = `${patientId}`;
      const mapData = componentState.contactDeceasedDateMap.get(key);
      if (!mapData) {
        const deceasedDate = await getCurrentPatientDeceasedData(contactData);
        setComponentState((prev) => {
          const map = prev.contactDeceasedDateMap;
          map.set(key, deceasedDate);
          return {
            ...prev,
            contactDeceasedDateMap: map,
          }
        });
      }
    }
  }

  useEffect(() => {
    //To disable fetch desease date data for mobile due to memory leaks and screen hang issues. (After discuss with @Prasad)
    if (isWeb() && ehrConfig.isAthena) {
      contacts.forEach((contactData) => {
        fetchPatientData(contactData);
      });
    }
  }, [contacts]);

  const getDeceasedDate = (contact: any) => {
    const patientId = contact?.patient?.patientId;
    if (patientId) {
      const key = `${patientId}`;
      return componentState.contactDeceasedDateMap.get(key);
    }
    return '';
  }

  const contentView = (
    <View height={'400'}>
      <ScrollView>
      {contacts?.map((contact: any) => {
        const userName = getUserNameByContact(contact);
        return (
          <Menu.Item
            key={contact?.uuid}
            onPress={() => {
              navigate(contact);
              handleVisibleChange(false);
            }}
            style={styles.menuItem}
          >
            <View style={styles.menuItemContent}>
              <DisplayCardAvatar
                avatarStyle={{
                  avatarSize: '10',
                  width: 28,
                  height: 28,
                  textStyle: {
                    fontSize: 12,
                  },
                  disableTop: true
                }}
                isLetterAvatarShow={true}
                userData={{
                  userId: contactData?.id || '',
                  userType: GROUP_MEMBER_TYPE.CONTACT,
                  genderCode: getPrimaryGenderCode(contact) as any,
                  contactType: contact?.contactType?.contactType?.code || '',
                  name: contact?.person,
                  userName: userName,
                }}
              />
              <VStack>
                <Text
                  fontWeight={500}
                  fontSize={14}
                  noOfLines={1}
                  style={styles.userName}
                >
                  {userName}
                </Text>
                <Text
                  fontSize={12}
                  fontWeight={400}
                  noOfLines={1}
                  style={styles.userDetails}
                >
                  {getPatientOrProspectElem(
                    contact,
                    contact?.contactType?.contactType || ({} as IContactType),
                    {
                      deceasedDate: getDeceasedDate(contact)
                    }
                  )}
                </Text>
              </VStack>
            </View>
          </Menu.Item>
        );
      })}
      </ScrollView>
    </View>
  );

  const antdStyles: Record<string, React.CSSProperties> = {
    popover: {
      bottom: 3,
      borderRadius: 16,
      padding: 0,
    },
    popoverOverlay: {
      borderRadius: 8,
    },
  };

  return (
    <VStack>
      <View style={styles.separator} backgroundColor={Colors.Custom.Gray200} />
      <HStack style={styles.infoContainer}>
        <InfoSvg customDimension="15px"></InfoSvg>
        <Text
          flex={0.85}
          fontSize={14}
          fontWeight={400}
          color={Colors.Custom.Gray500}
          paddingLeft={1}
        >
          {`${numericStringMask(phoneNumber, PHONE_NUMBER_MASK)} Used by ${
            contacts.length
          } ${contacts.length > 1 ? 'members' : 'member'}`}
        </Text>
        <View flex={0.15}>
          {isWeb() ? (
            <Popover
              overlayInnerStyle={antdStyles.popover}
              content={contentView}
              trigger="click"
              placement="left"
              visible={linkNumberMemberVisible}
              onVisibleChange={() => {
                handleVisibleChange(false);
              }}
              overlayStyle={antdStyles.popoverOverlay}
            >
              <Pressable
                onPress={() => {
                  handleVisibleChange(true);
                }}
              >
                <Text style={styles.viewText}>
                  {intl.formatMessage({id: 'view'})}
                </Text>
              </Pressable>
            </Popover>
          ) : (
            <>
              <Pressable
                onPress={() => {
                  handleVisibleChange(true);
                }}
              >
                <Text style={styles.viewText}>
                  {intl.formatMessage({id: 'view'})}
                </Text>
              </Pressable>
              <Center>
                <Modal
                  onClose={() => {
                    handleVisibleChange(false);
                  }}
                  animationPreset="slide"
                  isOpen={linkNumberMemberVisible}
                  overlayVisible={true}
                  style={styles.modal}
                >
                  <Modal.Content
                    width={'full'}
                    paddingX={3}
                    paddingY={3}
                    style={styles.modalContent}
                  >
                    {contentView}
                  </Modal.Content>
                </Modal>
              </Center>
            </>
          )}
        </View>
      </HStack>
    </VStack>
  );
};

const styles = StyleSheet.create({
  separator: {
    height: '1px',
    // backgroundColor: Colors.Custom.Gray200,
    width: '100%',
  },
  infoContainer: {
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    padding: 2,
  },
  menuItem: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.Custom.Gray200,
  },
  menuItemContent: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  userName: {
    marginLeft: 10,
    color: 'black',
  },
  userDetails: {
    color: Colors.Custom.Gray500,
    marginLeft: 10,
  },
  viewText: {
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
    fontSize: 14,
    fontWeight: '500',
    color: Colors.Custom.mainPrimaryPurple,
  },
  modal: {
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  modalContent: {
    borderRadius: 16,
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: Colors.Custom.CardBorderColor,
  },
});

export default LinkedMemberFromNumberView;
