import React from 'react';
import { Path, Svg } from 'react-native-svg';

const ThreeSquaresSvg: React.FC = () => {
  return (
    <Svg width="16" height="17" viewBox="0 0 16 17" fill="none">
      <Path
        d="M4.66659 8.49998H3.99992C2.74284 8.49998 2.1143 8.49998 1.72378 8.8905C1.33325 9.28103 1.33325 9.90957 1.33325 11.1666V12.5C1.33325 13.7571 1.33325 14.3856 1.72378 14.7761C2.1143 15.1666 2.74284 15.1666 3.99992 15.1666H5.33325C6.59033 15.1666 7.21887 15.1666 7.60939 14.7761C7.99992 14.3856 7.99992 13.7571 7.99992 12.5V11.8333M7.99992 5.16665H7.33325C6.07617 5.16665 5.44763 5.16665 5.05711 5.55717C4.66659 5.94769 4.66659 6.57623 4.66659 7.83331V9.16665C4.66659 10.4237 4.66659 11.0523 5.05711 11.4428C5.44763 11.8333 6.07617 11.8333 7.33325 11.8333H8.66659C9.92366 11.8333 10.5522 11.8333 10.9427 11.4428C11.3333 11.0523 11.3333 10.4237 11.3333 9.16665V8.49998M10.6666 8.49998H11.9999C13.257 8.49998 13.8855 8.49998 14.2761 8.10946C14.6666 7.71893 14.6666 7.09039 14.6666 5.83331V4.49998C14.6666 3.2429 14.6666 2.61436 14.2761 2.22384C13.8855 1.83331 13.257 1.83331 11.9999 1.83331H10.6666C9.40951 1.83331 8.78097 1.83331 8.39044 2.22384C7.99992 2.61436 7.99992 3.2429 7.99992 4.49998V5.83331C7.99992 7.09039 7.99992 7.71893 8.39044 8.10946C8.78097 8.49998 9.40951 8.49998 10.6666 8.49998Z"
        stroke="#8A94A8"
      />
    </Svg>
  );
};

export default ThreeSquaresSvg;
