import React from 'react';
import {ICommonSvgProps} from './interfaces';
import {isWeb} from '../../../utils/platformCheckUtils';
import {Image} from 'native-base';

const StatusErrorSvg = (props: ICommonSvgProps) => {
  const isWebsite = isWeb();
  const color = props.customStrokeColor || '#D92D20';
  const height = props.height || '16';
  const width = props.width || '16';
  const size = props?.size || '8';
  return (
    <>
      {isWebsite ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={height}
          height={width}
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M12 8v4m0 4h.01M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10z"
          ></path>
        </svg>
      ) : (
        <Image
          size={size}
          source={require('../../../assets/images/StatusError.png')}
          alt="aa"
        />
      )}
    </>
  );
};

export default StatusErrorSvg;
