import { Moment } from 'moment';
import { getDayNameByNumber, getMomentObj } from '../../../../../../../../utils/DateUtils';
import {IUseReccuringEvent, Month, ReccurenceType} from './interface';
import { DISPLAY_DATE_FORMAT } from '../../../../../../../../constants';
import { cloneDeep } from 'lodash';

export const getRepeatsEverySuffixText = (reccurenceType: ReccurenceType) => {
  switch (reccurenceType) {
    case ReccurenceType.DAILY:
      return 'Day/s';
    case ReccurenceType.WEEKLY:
      return 'Week/s';
    case ReccurenceType.MONTHLY:
      return 'Month/s';
    case ReccurenceType.YEARLY:
      return 'Year/s';
  }
};

export const getRepeatsEverySuffixSummaryText = (reccurenceType: ReccurenceType,isMultiple:boolean) => {
  let string = '';
  switch (reccurenceType) {
    case ReccurenceType.DAILY:
      string = 'Day';
      break;
    case ReccurenceType.WEEKLY:
      string = 'Week';
      break;
    case ReccurenceType.MONTHLY:
      string = 'Month';
      break;
    case ReccurenceType.YEARLY:
      string = 'Year';
      break;
  }

  if (isMultiple) {
    return string + 's';
  }
  return string
};

export const getFrequencyCode = (freq: ReccurenceType) => {
  return freq
};

const isLeapYear = (year: number) =>
  (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;

export function isMonthAndDateValid(date: number, month: Month): boolean {
  if (date < 1 || date > 31) {
    return false; // Invalid day
  }

  switch (month) {
    case Month.JANUARY:
    case Month.MARCH:
    case Month.MAY:
    case Month.JULY:
    case Month.AUGUST:
    case Month.OCTOBER:
    case Month.DECEMBER:
      return date <= 31;

    case Month.APRIL:
    case Month.JUNE:
    case Month.SEPTEMBER:
    case Month.NOVEMBER:
      return date <= 30;

    case Month.FEBRUARY:
      // Check for leap year (divisible by 4, but not by 100 unless also by 400)

      return isLeapYear(new Date().getFullYear()) ? date <= 29 : date <= 28;

    default:
      return false; // Invalid month
  }
}
  
export function getMinMaxDates(month: Month): {minDate: number; maxDate: number} {
  const currentYear = new Date().getFullYear();
  
  switch (month) {
    case Month.JANUARY:
      return {minDate: 1, maxDate: 31};
    case Month.FEBRUARY:
      const isLeapYear = (year: number) =>
        (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
      return isLeapYear(currentYear)
        ? {minDate: 1, maxDate: 29}
        : {minDate: 1, maxDate: 28};
    case Month.MARCH:
      return {minDate: 1, maxDate: 31};
    case Month.APRIL:
      return {minDate: 1, maxDate: 30};
    case Month.MAY:
      return {minDate: 1, maxDate: 31};
    case Month.JUNE:
      return {minDate: 1, maxDate: 30};
    case Month.JULY:
      return {minDate: 1, maxDate: 31};
    case Month.AUGUST:
      return {minDate: 1, maxDate: 31};
    case Month.SEPTEMBER:
      return {minDate: 1, maxDate: 30};
    case Month.OCTOBER:
      return {minDate: 1, maxDate: 31};
    case Month.NOVEMBER:
      return {minDate: 1, maxDate: 30};
    case Month.DECEMBER:
      return {minDate: 1, maxDate: 31};
    default:
      return {minDate: 1, maxDate: 31};
  }
}

export const calculateMaxFutureDate = (
  frequency: ReccurenceType,
  steps: number,
  startDate: Moment,
): Moment => {
  const currentDate = cloneDeep(startDate);


  switch (frequency) {
    case ReccurenceType.DAILY:
      if (steps >= 1 && steps <= 6) {
        return currentDate.add(1, 'month');
      } else if (steps >= 7 && steps <= 15) {
        return currentDate.add(3, 'months');
      } else if (steps > 15) {
        return currentDate.add(4, 'months');
      }
      break;
    case ReccurenceType.WEEKLY:
      if (steps >= 1 && steps <= 5) {
        return currentDate.add(3, 'month');
      } else if (steps > 5) {
        return currentDate.add(12, 'months');
      }
      break;
    case ReccurenceType.MONTHLY:
      if (steps >= 1 && steps <= 12) {
        return currentDate.add(12, 'months');
      } else if (steps > 12) {
        return currentDate.add(36, 'months');
      }
      break;
    case ReccurenceType.YEARLY:
      return currentDate.add(5, 'years');
  }

  return getMomentObj(new Date()).add('months',3);
};

export const getSummaryString = (config:IUseReccuringEvent,additionalData:{
  startTime: string
}) => {
  const isWeekReccurence = config.frequency === ReccurenceType.WEEKLY
  
  const reccStartDate = additionalData?.startTime;

  const reccEndDate = getMomentObj(config.endDate)?.format(DISPLAY_DATE_FORMAT);
  const daysOfWeek =
    isWeekReccurence && config?.repeatsOnWeekDays
      ? getDayNamesFromNumbers(config?.repeatsOnWeekDays || [])
      : '';

  if (
    config.repeatEvery === null ||
    config.repeatEvery === 0 ||
    (isWeekReccurence && daysOfWeek.length === 0)
  ) {
    return '';
  }    
  return `Occurs every ${config.repeatEvery} ${getRepeatsEverySuffixSummaryText(
    config.frequency,
    config.repeatEvery > 1
  )} ${daysOfWeek.length > 0 ? `on ${daysOfWeek}` : ''} starting ${reccStartDate} till ${reccEndDate}`;
}

export const getDayNamesFromNumbers = (dayNumbers: number[]): string => {
  if (dayNumbers.length === 0) {
    return '';
  }
  const dayList: string[] = [];
  dayNumbers.forEach((number) => dayList.push(getDayNameByNumber(number)));

  return joinWithCommasAnd(dayList);
};

const joinWithCommasAnd = (array: string[]): string => {
  const length = array.length;

  if (length === 0) {
    return '';
  } else if (length === 1) {
    return array[0];
  } else {
    const commaJoined = array.slice(0, length - 1).join(', ');
    return `${commaJoined} and ${array[length - 1]}`;
  }
};
