import {IAntThemeColors} from './interface';
import {colorWeight} from './themeConst';

export const getAntdBaseTheme = (themeConfig: any): IAntThemeColors => {
  const colorConst: {[index: string]: string} = {
    info: 'infoColor',
    error: 'errorColor',
    success: 'successColor',
    primary: 'primaryColor',
    warning: 'warningColor',
  };
  const antdColorConfig = {} as any;
  const colorConfig = themeConfig.colorConfig;
  Object.keys(colorConst).forEach((colorCode) => {
    const currentColorCodeObj = colorConfig[colorCode]
    const currentColorCode = getMainColor(currentColorCodeObj);
    if (currentColorCode) {
      const colorConstValue = colorConst[colorCode];
      antdColorConfig[colorConstValue] = currentColorCode;
    }
  });
  return antdColorConfig;
};

const getMainColor = (colorObj: {[index: string]: string}) => {
  let finalColorCode = '';
  const colorList = Object.keys(colorObj);
  if (colorList.length) {
    for (let colorIndex = 0; colorIndex < colorWeight.length; colorIndex++) {
      const currentColorWeight = colorWeight[colorIndex];
      if (colorObj[currentColorWeight]) {
        finalColorCode = colorObj[currentColorWeight];
        break;
      }
    }
  }
  return finalColorCode;
}
