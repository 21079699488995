import { StyleSheet } from 'react-native';
import { Colors } from '../../../../../styles';

export const unityAiCustomPrompt = StyleSheet.create({
    addCustomBtn : {
        alignItems: 'center', 
        flexDirection: 'row', 
        marginLeft: 5, 
        width: 70
    },
    addCustomBtnText : {
       color: Colors.Custom.mainPrimaryPurple, 
       fontWeight: '400'
    },
    addCustomBtnView : {
        flex: 1, 
        marginBottom: 2
    },
    addBtnDelete : {
        alignItems: 'center',
        alignSelf: 'center',
    }

});

export const cssPropertiesAntD: Record<string, React.CSSProperties> = {
    contextSelect: {
      width: '100%'
    },
    contextSelectPanel: {
      border: 'none'
    },
    filterNodeSelect:{
        width: 150
    },
    filterNodeInput:{
        width: 200, 
        borderRadius: 4
    },
    customPropmtTextArea:{
        borderRadius: 4,
        width:'100%', 
        borderWidth:0.5
    },
    customPromptInput:{
        borderWidth:0.5, 
        width:'30%'
    }
  };