import React from 'react';
import {isWeb} from '../../../utils/platformCheckUtils';
import {Image} from 'native-base';

const OtherInfo = () => {
  return (
    <>
      {isWeb() ? (
        <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.2292 1.83333H10.2208C9.91833 0.779167 8.94667 0 7.79167 0C6.63667 0 5.665 0.779167 5.3625 1.83333H4.35417C3.97833 1.83333 3.66667 2.145 3.66667 2.52083V3.89583C3.66667 4.77583 4.39083 5.5 5.27083 5.5H10.3125C11.1925 5.5 11.9167 4.77583 11.9167 3.89583V2.52083C11.9167 2.145 11.605 1.83333 11.2292 1.83333Z"
          fill="#98A2B3"
        />
        <path
          d="M13.0625 2.75H12.8333V3.89583C12.8333 5.28917 11.7058 6.41667 10.3125 6.41667H5.27083C3.8775 6.41667 2.75 5.28917 2.75 3.89583V2.75H2.52083C1.1275 2.75 0 3.8775 0 5.27083V16.7292C0 18.1225 1.1275 19.25 2.52083 19.25H8.56167C8.10333 18.2692 7.82833 17.1875 7.80083 16.0417H3.4375C3.06167 16.0417 2.75 15.73 2.75 15.3542C2.75 14.9783 3.06167 14.6667 3.4375 14.6667H7.87417C7.95667 14.1075 8.09417 13.5758 8.2775 13.0625H3.4375C3.06167 13.0625 2.75 12.7508 2.75 12.375C2.75 11.9992 3.06167 11.6875 3.4375 11.6875H8.9375C9.295 11.1008 9.71667 10.56 10.2117 10.0833H3.4375C3.06167 10.0833 2.75 9.77167 2.75 9.39583C2.75 9.02 3.06167 8.70833 3.4375 8.70833H12.0908C13.145 8.15833 14.3275 7.82833 15.5833 7.80083V5.27083C15.5833 3.8775 14.4558 2.75 13.0625 2.75Z"
          fill="#98A2B3"
        />
        <path
          d="M15.8125 9.625C12.4007 9.625 9.625 12.4007 9.625 15.8125C9.625 19.2243 12.4007 22 15.8125 22C19.2243 22 22 19.2243 22 15.8125C22 12.4007 19.2243 9.625 15.8125 9.625ZM16.7292 18.3333C16.7292 18.8393 16.3194 19.25 15.8125 19.25C15.3056 19.25 14.8958 18.8393 14.8958 18.3333V15.125C14.8958 14.619 15.3056 14.2083 15.8125 14.2083C16.3194 14.2083 16.7292 14.619 16.7292 15.125V18.3333ZM15.8125 13.75C15.3065 13.75 14.8958 13.3393 14.8958 12.8333C14.8958 12.3273 15.3065 11.9167 15.8125 11.9167C16.3185 11.9167 16.7292 12.3273 16.7292 12.8333C16.7292 13.3393 16.3185 13.75 15.8125 13.75Z"
          fill="#98A2B3"
        />
      </svg>
      ) : (
        <Image
          resizeMode="contain"
          size={'24px'}
          source={require('../../../assets/images/Contact/otherInfo.png')}
          alt="image"
        />
      )}
    </>
  );
};

export default OtherInfo;
