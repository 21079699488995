import { IDisplayTextFontSize } from "../../../theme/interface";
import { USER_SEARCH_TYPE } from "../../RightSideContainer/constants";
import { StyleProp, ViewStyle } from "react-native";

export interface ICustomUserSearchProps {
    userSelectRef?: any;
    isRequired: boolean;
    placeholder?: string;
    className?: string;
    isDisabled?: boolean;
    label?: string;
    disabledUsers: string[];
    onActionPerformed?: (actionCode: string, actionData?: any) => void;
    selectedUsersData: IInternalUsers[];
    onChange: (selectedUsersUUIDs: string[], selectedInternalUsers?: IInternalUsers[]) => void;
    userSearchType: keyof typeof USER_SEARCH_TYPE;
    selectedLocationUuids?: string[];
    filterLoggedInUser?: boolean;
    selectedPatientLocationUuid?: string;
    filterRoleTypes?: string[];
    mode?: CUSTOM_USER_SEARCH_MODE;
    extraTitleStyles?: {[index: string]: string | number | undefined};
    formControlLabelStyles?: StyleProp<ViewStyle>;
    titleSize?: IDisplayTextFontSize;
    hideTooltip?: boolean;
    allowClear?: boolean;
    customLabel?: JSX.Element;
}

export interface IOptionCheckProps {
    isDisabled: boolean;
    checked: boolean;
    value: string;
    name: string;
    userRoles: IUserRole[];
    showCheckbox?: boolean;
    hideTooltip?: boolean;
}

export interface IUserRole {
  userRole: {
    roleId?: string;
    roleName?: string;
    customRoleCode?: string;
    userRole: {
      value: string;
      code: string;
    };
  };
}

export interface IInternalUsersState {
    internalUsersOptions: IInternalUsers[];
    internalUsersData: IInternalUsers[];
    isLoading: boolean;
    selectedUsersUUIDs: string[];
    selectedUsersList: IInternalUsers[];
}

export interface IAccountUserSearchResp {
    accountUsers: IInternalUsers[]
}

export interface IUser {
    userRoles?: IUserRole[];
    uuid: string;
    id: number;
    name: string;
}

export interface IInternalUsers {
    userId: number;
    user: IUser;
}

export interface IUserOptionCheck {
    checked?: boolean;
    isDisabled?: boolean;
    userRoles: IUserRole[];
    uuid: string;
    name: string;
    id: number;
}

export interface IInternalUserOption {
    label: JSX.Element;
    value: string;
    disabled: boolean;
}

export interface ICustomUserSearchViewProps {
    userSelectRef: any;
    isRequired: boolean;
    className?: string;
    placeholder?: string;
    isDisabled?: boolean;
    label?: string;
    disabledUsers: string[];
    internalUsersOptions: IInternalUsers[];
    isLoading: boolean;
    selectedUsersUUIDs: string[];
    selectedUsersList: IInternalUsers[];
    onActionPerformed: (actionCode: string, actionData?: any) => void;
    mode: CUSTOM_USER_SEARCH_MODE;
    extraTitleStyles: {[index: string]: string | number | undefined};
    formControlLabelStyles: StyleProp<ViewStyle>;
    titleSize: IDisplayTextFontSize;
    hideTooltip: boolean;
    allowClear: boolean;
    customLabel?: JSX.Element;
}

export interface ICustomUserTagProps {
    value: string;
    isDisabled: boolean;
    label: string;
    onPreventMouseDown: (event: any) => void;
    onTagClose: (uuid: string) => void;
}

export interface IUserPracticeLocationResp {
  userPracticeLocations: IUserPracticeLocation[];
}

export interface IUserPracticeLocation {
  accountLocations: IAccountLocation[];
}

export interface IAccountLocation {
  practiceLocation: IPracticeLocation;
}

export interface IPracticeLocation {
  uuid: string;
  id: number;
  addressLine1: string;
  addressLine2: string;
  name: string;
  practiceCity: {
    name: string | undefined;
  };
  practiceState: {
    name: string | undefined;
  };
  practiceCountry: {
    name: string | undefined;
  };
}

export enum CUSTOM_USER_SEARCH_MODE {
	SINGLE_SELECT = 'SINGLE_SELECT',
	MULTIPLE_SELECT = 'MULTIPLE_SELECT',
}
