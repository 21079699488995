import React from 'react';
import {Colors} from '../../../styles';
import {ICommonSvgProps} from './interfaces';
import {isWeb} from '../../../utils/platformCheckUtils';
import {Image} from 'native-base';

const PlusIcon = (props: ICommonSvgProps) => {
  const {defaultColor, height,width} = props;
  return (
    <>
      {isWeb() ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width ? width : "20"}
          height= {height ? height : "20"}
          fill="none"
          viewBox="0 0 20 20"
        >
          <path
            stroke={
              defaultColor ? defaultColor : Colors.Custom.mainPrimaryPurple
            }
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M10 4.167v11.666M4.167 10h11.666"
          ></path>
        </svg>
      ) : (
        <Image
          tintColor={
            defaultColor ? defaultColor : Colors.Custom.mainPrimaryPurple
          }
          size={'18px'}
          source={require('../../../assets/images/PNGImages/plusIcon.png')}
          alt="image"
        />
      )}
    </>
  );
};

export default React.memo(PlusIcon);