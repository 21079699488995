// Get the DateTime from the components listing.
import {Formio} from '@foldhealth/formiojs';
import TextAreaFormComponent from '../TextAreaComponent';
import {ReactComponent} from '@foldhealth/formio-react';

const TextAreaComponent = TextAreaFormComponent;

export default class PlanComponent extends TextAreaComponent {
  static schema() {
    return ReactComponent.schema({
      type: 'plan',
      label: 'Plan',
      key: 'plan',
      rows: 3,
      wysiwyg: false,
      editor: '',
      fixedSize: true,
      inputFormat: 'html',
      validate: {
        minWords: '',
        maxWords: '',
        customMessage: 'Field is required',
      },
    });
  }

  static get builderInfo() {
    return {
      title: 'Plan',
      group: 'advanced',
      icon: 'filetext1',
      documentation: '/userguide/#datetime',
      schema: PlanComponent.schema(),
    };
  }
}

Formio.Components.addComponent('plan', PlanComponent);
