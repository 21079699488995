import {Drawer, Select} from 'antd';
import React from 'react';
import {BUTTON_TYPE} from '../../../../../constants';
import {Colors} from '../../../../../styles';
import {getAccountUUID, getUserUUID} from '../../../../../utils/commonUtils';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import {
  addOrRemoveCptCodesIdsFromCommunicationType,
  createCommunicationType,
  getCptCodesByCommunicationTypeId,
  getSingleCommunicationType,
  updateCommunicationType,
  updateExistingContactSavings,
  validateCommunicationTypeBody,
} from './CommunicationTypesUtils';
import {
  IAddOrUpdateCommunicationTypeProps,
  ICommunicationType,
  ICommunicationTypeCptCode,
  ICommunicationTypeState,
} from './interfaces';
import {
  FormControl,
  HStack,
  Input,
  Pressable,
  Spinner,
  Text,
  TextArea,
  VStack,
  View,
} from 'native-base';
import {useLazyQuery} from '@apollo/client';
import CptCodesQueries from '../../../../../services/CptCodes/CptCodesQueries';
import UpdateCostAlert from './UpdateCostAlert';
import AddCptCodes from './AddCptCodes';
import { StyleSheet } from 'react-native';

const AddOrUpdateCommunicationType = (
  props: IAddOrUpdateCommunicationTypeProps
) => {
  const {communicationTypeId, isOpen, onClose, onSave} = props;
  const userUuid = getUserUUID();
  const [state, setState] = React.useState<ICommunicationTypeState>({
    name: '',
    description: '',
    createdBy: userUuid,
    updatedBy: userUuid,
    loading: false,
    submitting: false,
    associatedCptCodes: [],
    selectedCptCodeIds: [],
    cptCodes: [],
    marketCost: '',
    ourCost: '',
    originalMarketCost: '',
    originalOurCost: '',
    isCostChangedAlertOpen: false,
    isAddNewCptCodeDrawerOpen: false,
  });
  const [errors, setErrors] = React.useState({
    name: '',
    description: '',
    marketCost: '',
    ourCost: '',
  });
  const accountUuid = getAccountUUID();

  const [getCptCodes] = useLazyQuery(CptCodesQueries.GET_CPT_CODES, {
    fetchPolicy: 'no-cache',
    variables: {
      searchString: '%%',
      accountUuid: accountUuid,
    },
  });

  const getCommunicationTypeData = async () => {
    setState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    try {
      let data: Partial<ICommunicationType> = {};
      let associatedCptCodes: ICommunicationTypeCptCode[] = [];
      if (communicationTypeId) {
        const singleCommunicationTypePromise =
          getSingleCommunicationType(communicationTypeId);
        const cptCodesPromise =
          getCptCodesByCommunicationTypeId(communicationTypeId);
        const response = await Promise.all([
          singleCommunicationTypePromise,
          cptCodesPromise,
        ]);
        const first = response[0];
        const second = response[1];
        data = first.data;
        associatedCptCodes = second.data;
      }
      const cptCodesResponse = await getCptCodes();
      const cptCodes = cptCodesResponse?.data?.cpt_codes || [];
      setState((prev) => {
        return {
          ...prev,
          name: data?.name || '',
          description: data?.description || '',
          createdBy: data?.createdBy || userUuid,
          updatedBy: data?.updatedBy || userUuid,
          loading: false,
          id: data?.id,
          associatedCptCodes: associatedCptCodes,
          selectedCptCodeIds: associatedCptCodes.map((item) => item.cptCodeId),
          cptCodes,
          ourCost: data.ourCost || '',
          marketCost: data.marketCost || '',
          originalMarketCost: data.marketCost || '',
          originalOurCost: data.ourCost || '',
        };
      });
    } catch (error) {

      setState((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    }
  };

  const getTitle = () => {
    if (communicationTypeId) {
      return 'Edit';
    }
    return 'Add';
  };

  const isCostChanged = () => {
    const result =
      state.originalMarketCost !== state.marketCost ||
      state.originalOurCost !== state.ourCost;
    return result;
  };

  const showCostChangedAlert = () => {
    return communicationTypeId && isCostChanged();
  };

  const onSubmit = async () => {
    const {error, valid} = validateCommunicationTypeBody({
      name: state.name,
      description: state.description,
      marketCost: state.marketCost,
      ourCost: state.ourCost,
    });
    if (!valid) {
      setErrors(error);
      return;
    }
    if (showCostChangedAlert()) {
      setState((prev) => {
        return {
          ...prev,
          isCostChangedAlertOpen: true,
        };
      });
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    setState((prev) => {
      return {
        ...prev,
        submitting: true,
      };
    });
    const promise = communicationTypeId
      ? updateCommunicationType
      : createCommunicationType;
    try {
      const response = await promise({
        name: state.name,
        createdBy: state.createdBy,
        updatedBy: state.updatedBy,
        description: state.description,
        id: communicationTypeId,
        ourCost: state.ourCost,
        marketCost: state.marketCost,
      });
      if (response?.data?.id) {
        const currentCptCodes = state.associatedCptCodes.map(
          (item) => item.cptCodeId
        );
        await addOrRemoveCptCodesIdsFromCommunicationType({
          communicationTypeId: communicationTypeId || response?.data?.id,
          selectedCptCodeIds: state.selectedCptCodeIds,
          currentCptCodeIds: currentCptCodes,
        });
      }
      onSave(response.data);
    } catch (error) {

      setState((prev) => {
        return {
          ...prev,
          submitting: false,
        };
      });
    }
  };

  const handleConfirm = async () => {
    setState((prev) => {
      return {
        ...prev,
        isCostChangedAlertOpen: false,
      };
    });
    await updateExistingContactSavings({
      communicationTypeId: communicationTypeId || '',
      ourCost: state.ourCost,
      marketCost: state.marketCost,
    });
    await handleSubmit();
  };

  const handleClose = () => {
    setState((prev) => {
      return {
        ...prev,
        isCostChangedAlertOpen: false,
      };
    });
    handleSubmit();
  };

  const fetchCptCodes = async () => {
    const cptCodesResponse = await getCptCodes();
    const cptCodes = cptCodesResponse?.data?.cpt_codes || [];
    setState((prev) => {
      return {
        ...prev,
        cptCodes: [...cptCodes],
        isAddNewCptCodeDrawerOpen: false,
      };
    });
  };

  React.useEffect(() => {
    getCommunicationTypeData();
  }, []);

  return (
    <Drawer
      onClose={onClose}
      width={'33vw'}
      open={isOpen}
      title={
        <ModalActionTitle
          title={getTitle()}
          titleColor={''}
          buttonList={[
            {
              show: true,
              id: 1,
              btnText: 'cancel',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.SECONDARY,
              isTransBtn: false,
              onClick: () => {
                onClose();
              },
            },
            {
              show: true,
              id: 2,
              btnText: 'save',
              textColor: Colors.Custom.mainPrimaryPurple,
              isLoading: state.submitting,
              variant: BUTTON_TYPE.PRIMARY,
              isTransBtn: false,
              onClick: () => {
                onSubmit();
              },
            },
          ]}
        />
      }
    >
      {state.loading && (
        <View>
          <Spinner />
        </View>
      )}

      {state.isCostChangedAlertOpen && (
        <UpdateCostAlert
          isOpen={state.isCostChangedAlertOpen}
          onClose={() => {
            handleClose();
          }}
          onConfirm={() => {
            handleConfirm();
          }}
          onCancel={() => {
            setState((prev) => {
              return {
                ...prev,
                isCostChangedAlertOpen: false,
              };
            });
          }}
        />
      )}

      {!state.loading && (
        <>
          <VStack>
            <View>
              <Text style={styles.titleText}>
                Communication Type
              </Text>
            </View>

            <View style={styles.descriptionContainer}>
              <Text style={styles.descriptionText}>
                You can create a new communication type to categorize and
                analyze different types of communication
              </Text>
            </View>
          </VStack>

          <FormControl
            style={styles.marginVertical20}
            isRequired
            isInvalid={!!errors.name}
          >
            <FormControl.Label>Name</FormControl.Label>
            <Input
              value={state.name}
              isDisabled
              onChangeText={(text) => {
                setState((prev) => {
                  return {
                    ...prev,
                    name: text,
                  };
                });
              }}
            />
            {errors.name && (
              <FormControl.ErrorMessage>{errors.name}</FormControl.ErrorMessage>
            )}
          </FormControl>

          <FormControl isRequired={false} style={styles.formControl}>
            <FormControl.Label>Description</FormControl.Label>
            <TextArea
              value={state.description}
              isDisabled
              onChangeText={(text) => {
                setState((prev) => {
                  return {
                    ...prev,
                    description: text,
                  };
                });
              }}
            />
          </FormControl>

          <FormControl style={styles.formControl}>
            <HStack>
              <FormControl.Label>Associated CPT Codes</FormControl.Label>
              <Pressable
                onPress={() => {
                  setState((prev) => {
                    return {
                      ...prev,
                      isAddNewCptCodeDrawerOpen: true,
                    };
                  });
                }}
              >
                <Text style={styles.addNewText}>
                  + Add New
                </Text>
              </Pressable>
            </HStack>
            <Select
              value={state.selectedCptCodeIds || []}
              onChange={(value) => {
                setState((prev) => {
                  return {
                    ...prev,
                    selectedCptCodeIds:
                      typeof value === 'string' ? [value] : value,
                  };
                });
              }}
            >
              {state.cptCodes.map((item) => {
                return (
                  <Select.Option key={item.id} value={item.id}>
                    {item.code}
                  </Select.Option>
                );
              })}
            </Select>
          </FormControl>

          <FormControl
            style={styles.formControl}
            isInvalid={!!errors.marketCost}
          >
            <FormControl.Label>Market Cost</FormControl.Label>
            <Input
              type="number"
              value={state.marketCost}
              maxLength={9}
              onChangeText={(text) => {
                setState((prev) => {
                  return {
                    ...prev,
                    marketCost: text,
                  };
                });
              }}
            />
            {errors.marketCost && (
              <FormControl.ErrorMessage>
                {errors.marketCost}
              </FormControl.ErrorMessage>
            )}
          </FormControl>

          <FormControl
            style={styles.formControl}
            isInvalid={!!errors.ourCost}
          >
            <FormControl.Label>Our Cost</FormControl.Label>
            <Input
              value={state.ourCost}
              maxLength={9}
              onChangeText={(text) => {
                setState((prev) => {
                  return {
                    ...prev,
                    ourCost: text,
                  };
                });
              }}
            />
            {errors.ourCost && (
              <FormControl.ErrorMessage>
                {errors.ourCost}
              </FormControl.ErrorMessage>
            )}
          </FormControl>
        </>
      )}

      {state.isAddNewCptCodeDrawerOpen && (
        <AddCptCodes
          isOpen={state.isAddNewCptCodeDrawerOpen}
          onClose={() => {
            setState((prev) => {
              return {
                ...prev,
                isAddNewCptCodeDrawerOpen: false,
              };
            });
          }}
          onSubmit={() => {
            fetchCptCodes();
          }}
        />
      )}
    </Drawer>
  );
};

const styles = StyleSheet.create({
  titleText: {
    fontSize: 18,
    fontWeight: '700',
    color: Colors.Custom.textBlackColor,
  },
  descriptionContainer: {
    marginVertical: 12,
  },
  descriptionText: {
    color: Colors.Custom.Gray700,
    fontSize: 16,
    fontWeight: '500',
  },
  formControl: {
    marginVertical: 10,
  },
  marginVertical20: {
    marginVertical: 20,
  },
  addNewText: {
    color: Colors.secondary['500'],
  },
});

export default AddOrUpdateCommunicationType;
