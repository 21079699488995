// Helper
// Views
import { Drawer, Skeleton } from 'antd';
import { Divider, View, useToast } from 'native-base';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { BUTTON_TYPE, DATE_FORMATS, MLOV_CATEGORY } from '../../../../../constants';
import { CommonDataContext } from '../../../../../context/CommonDataContext';
import {
  FlowType,
  IWorkflowContextData,
  WorkflowContext
} from '../../../../../context/WorkflowContext';
import { Colors } from '../../../../../styles/Colors';
import { getDateStrFromFormat } from '../../../../../utils/DateUtils';
import { getAccountUUID } from '../../../../../utils/commonUtils';
import { ToastType, showToast } from '../../../../../utils/commonViewUtils';
import {
  getMlovListFromCategory,
  getMlovValueFromId
} from '../../../../../utils/mlovUtils';
import { DisplayText } from '../../../../common/DisplayText/DisplayText';
import { ModalActionTitle } from '../../../../common/ModalActionTitle/ModalActionTitle';
import { WorkflowRender } from '../../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowRenderView';
import {
  getNodeMetadata,
  getWorkFlowDataById
} from '../../AddOrUpdateJourney/JourneyMetadataService';
import PatientCareJourneyActivities from '../PatientCareJourney/PatientCareJourneyActivities';
import { setWorkFlowConfigToElements } from './PatientCareJourneyDetailHelper';
import { INPUT_FIELD_TYPE } from '../../../Workflow/FlowComponent/StateNodes/NodeInputField';
import { useLazyQuery } from '@apollo/client';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../constants/Configs';
import { GET_FORM_NAME_BY_IDS } from '../../../../../services/Forms/FormsQueries';
import { updateEmailNames, updatePushNotificationNames, updateSMSNames } from '../../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import { getFormTemplateUuidList, updateFormNames } from '../../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowUtils';

const PatientCareJourneyDetail = (props: {
  title?: string;
  isIgnorePopup?:true,
  visible: boolean;
  patientCareJourney: any;
  onClose: () => void;
  showActivityTab?: boolean;
  showJourneyTab?: boolean;
  flowType?:any
}) => {
  const intl = useIntl();
  const toast = useToast();
  const accountUuId = getAccountUUID();
  const mlovData = useContext(CommonDataContext);
  const journeyDuration =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.CARE_JOURNEY_DURATION
    ) || [];
  const [detailState, setDetailState] = useState<{
    loading: boolean;
    selectedJourneyNodes: any[];
    selectedJourneyEdges: any[];
    workflowData: any;
  }>({
    loading: false,
    selectedJourneyNodes: [],
    selectedJourneyEdges: [],
    workflowData: {},
  });

  const commonData = useContext(CommonDataContext);
  const isSidecarContext = commonData?.sidecarContext?.isSidecar;

  const flowType: any = props.flowType ? props.flowType : FlowType.careJourney;
  const [workflowContextData, setWorkflowContextData] =
    useState<IWorkflowContextData>({
      nodeMasterDataMap: {},
      nodeMetaData: undefined,
      flowType: flowType,
      setNodeMasterDataMap: (data) => {
        setWorkflowContextData((prev) => ({...prev, nodeMasterDataMap: data}));
      },
    });

  const [getFormNames, { loading: formNamesLoading }] = useLazyQuery<{ forms: { id: string; name: string }[] }>(GET_FORM_NAME_BY_IDS, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache'
  });

  const updateFormInformation = async (nodes: any[]) => {
    const uuids = getFormTemplateUuidList(nodes)
    const formResponse = await getFormNames({
      variables: { formIds: uuids }
    });

    const formIdToNameMap: { [key: string]: string } = {};
    formResponse?.data?.forms?.forEach((form: { id: string; name: string }) => {
      formIdToNameMap[form?.id] = form?.name;
    });

    const formUpdates = updateFormNames(nodes, formIdToNameMap)
    return formUpdates
  }

  const updateWorkflowDataInformation = async (nodes: any) => {
    const formUpdate = updateFormInformation(nodes)
    const pushNotificationUpdate = updatePushNotificationNames(nodes)
    const smsUpdate = updateSMSNames(nodes)
    const emailUpdate = updateEmailNames(nodes)
    const update = await Promise.all([formUpdate, pushNotificationUpdate, smsUpdate, emailUpdate])
    return update
  }

  useEffect(() => {
    const journeyId =
      props.patientCareJourney?.careJourneyVersionId ||
      props.patientCareJourney?.careJourneyId;
    if (journeyId) {
      setDetailState((prev) => ({...prev, loading: true}));
      getWorkFlowDataById(
        journeyId,
        accountUuId,
        async (response) => {
          const formUpdate = await updateWorkflowDataInformation(response?.nodes)
          const nodes = response.nodes;
          const edges = response.edges;
          if (
            nodes &&
            nodes.length > 0 &&
            props.patientCareJourney?.workflowConfig
          ) {
            setWorkFlowConfigToElements(
              nodes,
              edges,
              props.patientCareJourney?.workflowConfig
            );
          }
          setDetailState((prev) => ({
            ...prev,
            selectedJourneyNodes: nodes || [],
            selectedJourneyEdges: edges || [],
            workflowData: response?.workflowData,
            loading: false,
          }));
        },
        () => {
          showErrorToast();
          setDetailState((prev) => ({
            ...prev,
            loading: false,
          }));
        }
      );
    }
  }, [props.patientCareJourney?.careJourneyVersionId, props.patientCareJourney?.careJourneyId, props.patientCareJourney?.workflowConfig]);

  useEffect(() => {
    getNodeMetaData();
  }, []);

  const getNodeMetaData = () => {
    getNodeMetadata(
      flowType,
      (response) => {
        setWorkflowContextData((prev) => ({
          ...prev,
          nodeMasterDataMap: response.nodeMasterData?.nodeMap,
          nodeMetaData: {
            frequencyList: response.nodeMetaData?.frequencyList || [],
            triggerUnits: response.nodeMetaData?.triggerUnits || [],
            durationUnits: response.nodeMetaData?.durationUnits || [],
            reminderUnits: response.nodeMetaData?.reminderUnits || [],
          },
        }));
      },
      () => {
        showErrorToast();
      }
    );
  };

  const showErrorToast = () => {
    showToast(toast, intl.formatMessage({id: 'apiErrorMsg'}), ToastType.error);
  };

  const getJourneyDetail = () => {
    return {
      name: detailState?.workflowData?.name || props.patientCareJourney?.title || props.patientCareJourney?.careJourney?.title || 'Journey Details',
      description: props.patientCareJourney?.description || props.patientCareJourney?.careJourney?.description || '',
      triggerEvents: [],
      shipping_category_id: '',
      available_on: '',
      price: '',
      sku: '',
      cost_currency: 'USD',
      compare_at_price: '',
      startDate:  props.patientCareJourney?.startDate ? getDateStrFromFormat(
        props.patientCareJourney?.startDate,
        DATE_FORMATS.DISPLAY_DATE_FORMAT
      ) : '',
      duration: {
        value: (props.patientCareJourney?.duration || props.patientCareJourney?.careJourney?.duration),
        unit: (props.patientCareJourney?.durationUnitId || props.patientCareJourney?.careJourney?.durationUnitId),
        unitList: journeyDuration,
      },
    };
  };

  const getDuration = () => {
    if (props.patientCareJourney) {
      const journeyDetails = getJourneyDetail();
      return (
        (journeyDetails.duration?.value || '') +
        ' ' +
        (journeyDetails.duration?.unit
          ? getMlovValueFromId(journeyDuration, journeyDetails.duration?.unit)
          : '')
      );
    }
    return '';
  };

  return (
    props?.isIgnorePopup ? <>{
      !detailState.loading && workflowContextData &&
      <WorkflowContext.Provider value={workflowContextData}>
      <WorkflowRender
      isViewOnly={true}
      setWorkflowContext={setWorkflowContextData}
      onNodeDataChange={() => {
        /**/
      }}
      isWorkFlowEditable={false}
      flowType={props.flowType || FlowType.MICRO_AUTOMATION}
      libNodeIdList={[]}
      nodes={detailState.selectedJourneyNodes || []}
      setNodes={() => {
        /**/
      }}
      edges={detailState.selectedJourneyEdges || []}
      setEdges={() => {
        /**/
      }}
    />
    </WorkflowContext.Provider>
    }
    </>:
    <Drawer
      destroyOnClose
      placement="right"
      onClose={() => {
        props.onClose();
      }}
      style={{padding: 0}}
      visible={props.visible}
      closable={false}
      width={isSidecarContext? '100%' : '60%'}
      bodyStyle={{padding: 0}}
      mask={!isSidecarContext}
      title={
        <ModalActionTitle
              title={props?.title ||  'journeyFlow'}
              buttonList={[
                {
                  show: true,
                  id: 1,
                  btnText: isSidecarContext ? 'back' : 'close',
                  textColor: Colors.Custom.mainSecondaryBrown,
                  variant: BUTTON_TYPE.SECONDARY,
                  isTransBtn: false,
                  onClick: () => {
                    props.onClose();
                  },
                },
              ]}
            />
      }
    >
      {detailState.loading && (
        <View width="full" padding={4}>
          <Skeleton active />
        </View>
      )}
      {props.patientCareJourney &&
        workflowContextData.nodeMasterDataMap &&
        !detailState.loading && (
          <View padding={4}>
            <DisplayText
              size={'mdSemibold'}
              extraStyles={{
                fontWeight: 700,
                fontSize: 20,
                fontFamily: 'Manrope',
              }}
              textLocalId={detailState?.workflowData?.name || props?.patientCareJourney?.title || props?.patientCareJourney?.careJourney?.title || ''}
            />
            <Divider margin={2} />
            {props.showJourneyTab && (
              <WorkflowContext.Provider value={workflowContextData}>
              <View height="85vh">
                <WorkflowRender
                setWorkflowContext={setWorkflowContextData}
                  onNodeDataChange={() => {
                    /**/
                  }}
                  isViewOnly={true}
                  isWorkFlowEditable={false}
                  flowType={flowType}
                  libNodeIdList={[]}
                  nodes={detailState.selectedJourneyNodes || []}
                  setNodes={() => {
                    /**/
                  }}
                  edges={detailState.selectedJourneyEdges || []}
                  setEdges={() => {
                    /**/
                  }}
                />
              </View>
              </WorkflowContext.Provider>
            )}
            {props.showActivityTab && (
              <WorkflowContext.Provider value={workflowContextData}>
              <PatientCareJourneyActivities
                journeyDetails={getJourneyDetail()}
                nodes={detailState.selectedJourneyNodes || []}
                edges={detailState.selectedJourneyEdges || []}
                displayConfig={{
                  canEdit: true,
                  showAssignee: false,
                  height: '85vh',
                  hideHeader: true,
                }}
                nodeMasterDataMap={
                  workflowContextData.nodeMasterDataMap
                }
                nodeMetaData={workflowContextData.nodeMetaData}
              />
              </WorkflowContext.Provider>
            )}
          </View>
        )}
    </Drawer>
  );
};

export default PatientCareJourneyDetail;
