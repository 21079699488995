import { IRatingColor, IRatingIconPair } from "./interfaces";
import FontAwesomeIcon from 'react-native-vector-icons/FontAwesome';
import { ColorValue } from "react-native";
import Feather from "react-native-vector-icons/Feather";
import { Colors } from "../../../../../../styles";
import { NPS } from "../../../../../../assets/svg/NPS";

export enum RatingType {
  star = 'star',
  heart = 'heart',
  user = 'user',
  thumbsUp = 'thumbsUp',
  circle = 'circle',
  nps = 'nps',
}

export const DEFAULT_RATING_SCALE = 10;
export const DEFAULT_ICON_SIZE = 26;
// For NPS we show 0 to 10 scale, thus returning 11 count
export const NPS_TOTAL_SCALE = 11;
export const NPS_TILE_WIDTH = 28;
export const NPS_TILE_SPACE = 8;
export const NPS_TILE_HEIGHT = 40;

export const NPS_INDEX_COLORS: {[index: number]: string} = {
  0: '#F9564E',
  1: '#F9564E',
  2: '#F9564E',
  3: '#F9564E',
  4: '#F9564E',
  5: '#F9564E',
  6: '#F9564E',
  7: '#FFDD33',
  8: '#FFDD33',
  9: '#5DDB4F',
  10: '#5DDB4F',
}

export const getIconElement = (
  ratingType: RatingType,
  shouldHighlight: boolean,
  ratingColors: IRatingColor
): JSX.Element | undefined => {
  const iconPair = getIconPair(ratingType);
  const iconName = shouldHighlight ? iconPair.filled : iconPair.unfilled;
  const color = shouldHighlight ? ratingColors.filledIcon : ratingColors.unfilledIcon;
  const size = ratingColors.size || DEFAULT_ICON_SIZE;
  switch (ratingType) {
    case RatingType.star:
    case RatingType.heart:
    case RatingType.circle:
      return <FontAwesomeIcon name={iconName} size={size} color={color as ColorValue} />;

    case RatingType.thumbsUp:
    case RatingType.user:
      return <Feather name={iconName} size={size} color={color as ColorValue} />;
    case RatingType.nps:
      return <NPS size={size} />;
  }
}
export const getIconElementName = (
  ratingType: RatingType,
): string => {
  switch (ratingType) {
    case RatingType.star:
      return 'Stars'
    case RatingType.heart:
      return 'Hearts'
    case RatingType.circle:
      return 'Circles'

    case RatingType.thumbsUp:
      return 'Thumbs ups'
    case RatingType.user:
      return 'Users'
    case RatingType.nps:
      return ''
  }
}

export const getIconPair = (type: RatingType): IRatingIconPair => {
  switch (type) {
    case RatingType.star:
      return {
        filled: 'star',
        unfilled: 'star-o',
      };
    case RatingType.heart:
      return {
        filled: 'heart',
        unfilled: 'heart-o',
      };
    case RatingType.circle:
      return {
        filled: 'circle',
        unfilled: 'circle-thin',
      };
    case RatingType.thumbsUp:
      return {
        filled: 'thumbs-up',
        unfilled: 'thumbs-up',
      };
    case RatingType.user:
      return {
        filled: 'user',
        unfilled: 'user',
      };
  }
  return {
    filled: 'star',
    unfilled: 'star-o',
  };
}

export const RATING_ELEMENTS = [
  {
    label: 'Star',
    value: RatingType.star,
    customIcon: getIconElement(RatingType.star, false, {
      unfilledIcon: Colors.Custom.Gray500,
      size: 16
    }),
  },
  {
    label: 'Heart',
    value: RatingType.heart,
    customIcon: getIconElement(RatingType.heart, false, {
      unfilledIcon: Colors.Custom.Gray500,
      size: 16
    }),
  },
  {
    label: 'User',
    value: RatingType.user,
    customIcon: getIconElement(RatingType.user, false, {
      unfilledIcon: Colors.Custom.Gray500,
      size: 16
    }),
  },
  {
    label: 'Thumbs Up',
    value: RatingType.thumbsUp,
    customIcon: getIconElement(RatingType.thumbsUp, false, {
      unfilledIcon: Colors.Custom.Gray500,
      size: 16
    }),
  },
  {
    label: 'Circle',
    value: RatingType.circle,
    customIcon: getIconElement(RatingType.circle, false, {
      unfilledIcon: Colors.Custom.Gray500,
      size: 16
    }),
  },
  {
    label: 'NPS',
    value: RatingType.nps,
    customIcon: getIconElement(RatingType.nps, false, {
      unfilledIcon: Colors.Custom.Gray500,
      size: 18
    }),
  },
];
