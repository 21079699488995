import React from 'react';
import {ICommonSvgProps} from '../interfaces';
import {isWeb} from '../../../../utils/platformCheckUtils';
import {Image} from 'native-base';

const CallTrailSvg = (props: ICommonSvgProps) => {
  const height = props.height ?? '21';
  const width = props.width ?? '21';
  const stroke = props.customStrokeColor ?? '#667085';
  return isWeb() ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${width}`}
      height={`${height}`}
      viewBox="0 0 21 21"
      fill="none"
    >
      <path
        d="M3.28571 7.07143H10.1429M1 2.5H5.57143M3.28571 2.5V15.0714C3.28571 15.3745 3.40612 15.6652 3.62045 15.8796C3.83478 16.0939 4.12547 16.2143 4.42857 16.2143H10.1429M10.1429 5.92857C10.1429 5.62547 10.2633 5.33478 10.4776 5.12045C10.6919 4.90612 10.9826 4.78571 11.2857 4.78571H18.1429C18.446 4.78571 18.7367 4.90612 18.951 5.12045C19.1653 5.33478 19.2857 5.62547 19.2857 5.92857V8.21429C19.2857 8.51739 19.1653 8.80808 18.951 9.02241C18.7367 9.23674 18.446 9.35714 18.1429 9.35714H11.2857C10.9826 9.35714 10.6919 9.23674 10.4776 9.02241C10.2633 8.80808 10.1429 8.51739 10.1429 8.21429V5.92857ZM10.1429 15.0714C10.1429 14.7683 10.2633 14.4776 10.4776 14.2633C10.6919 14.049 10.9826 13.9286 11.2857 13.9286H18.1429C18.446 13.9286 18.7367 14.049 18.951 14.2633C19.1653 14.4776 19.2857 14.7683 19.2857 15.0714V17.3571C19.2857 17.6602 19.1653 17.9509 18.951 18.1653C18.7367 18.3796 18.446 18.5 18.1429 18.5H11.2857C10.9826 18.5 10.6919 18.3796 10.4776 18.1653C10.2633 17.9509 10.1429 17.6602 10.1429 17.3571V15.0714Z"
        stroke={`${stroke}`}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ) : (
    <Image
      size={props.size}
      resizeMode="contain"
      source={require('../../../../assets/images/CallTrailPng/trail.png')}
      alt='image'
    />
  );
};

export default CallTrailSvg;
