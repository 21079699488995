import React from 'react';
import { Path, Svg } from 'react-native-svg';

const InsuranceCardPlanNameSvg = () => {
  return (
    <Svg width="16" height="14" viewBox="0 0 16 14" fill="none" >
    <Path d="M2.45325 6.55556H1.95325V6.55556L2.45325 6.55556ZM2.45325 7.66667L2.10109 8.02161C2.29603 8.21502 2.61047 8.21502 2.80541 8.02161L2.45325 7.66667ZM3.92528 6.9105C4.12131 6.716 4.12256 6.39942 3.92806 6.20339C3.73357 6.00737 3.41699 6.00612 3.22096 6.20062L3.92528 6.9105ZM1.68554 6.20062C1.48951 6.00612 1.17293 6.00737 0.978435 6.20339C0.783941 6.39942 0.785185 6.716 0.981212 6.9105L1.68554 6.20062ZM12.4104 3.92853C12.555 4.16377 12.863 4.23723 13.0982 4.09261C13.3335 3.94799 13.4069 3.64005 13.2623 3.40481L12.4104 3.92853ZM13.5426 6.33333L13.8941 5.97772C13.6993 5.7852 13.3859 5.7852 13.1912 5.97772L13.5426 6.33333ZM12.067 7.08883C11.8706 7.28295 11.8688 7.59953 12.0629 7.79593C12.257 7.99233 12.5736 7.99417 12.77 7.80006L12.067 7.08883ZM14.3153 7.80006C14.5117 7.99417 14.8283 7.99233 15.0224 7.79593C15.2165 7.59953 15.2147 7.28295 15.0183 7.08883L14.3153 7.80006ZM3.54533 10.0707C3.40031 9.83575 3.09224 9.76281 2.85725 9.90784C2.62225 10.0529 2.54931 10.3609 2.69433 10.5959L3.54533 10.0707ZM8.05262 0.5C4.68774 0.5 1.95325 3.20747 1.95325 6.55556H2.95325C2.95325 3.76714 5.2326 1.5 8.05262 1.5V0.5ZM1.95325 6.55556L1.95325 7.66667L2.95325 7.66667L2.95325 6.55556L1.95325 6.55556ZM2.80541 8.02161L3.92528 6.9105L3.22096 6.20062L2.10109 7.31173L2.80541 8.02161ZM2.80541 7.31173L1.68554 6.20062L0.981212 6.9105L2.10109 8.02161L2.80541 7.31173ZM13.2623 3.40481C12.1914 1.66285 10.2582 0.5 8.05262 0.5V1.5C9.89897 1.5 11.5152 2.4723 12.4104 3.92853L13.2623 3.40481ZM7.92186 13.5C11.2968 13.5 14.0426 10.7943 14.0426 7.44444H13.0426C13.0426 10.2311 10.7555 12.5 7.92186 12.5V13.5ZM14.0426 7.44444V6.33333H13.0426V7.44444H14.0426ZM13.1912 5.97772L12.067 7.08883L12.77 7.80006L13.8941 6.68894L13.1912 5.97772ZM13.1912 6.68894L14.3153 7.80006L15.0183 7.08883L13.8941 5.97772L13.1912 6.68894ZM2.69433 10.5959C3.76941 12.338 5.7094 13.5 7.92186 13.5V12.5C6.06686 12.5 4.44394 11.5269 3.54533 10.0707L2.69433 10.5959Z" fill="#8C5AE2"/>
    </Svg>
  );
};

export default InsuranceCardPlanNameSvg;
