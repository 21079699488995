import {cloneDeep} from 'lodash';
import {sortConversationMessages} from '../../components/RightSideContainer/TeamInbox/Conversations/MessagingWindow/MessagingUtils';
import {IConversationMessage} from '../../components/RightSideContainer/TeamInbox/Conversations/interfaces';
import {
  CaptureTransaction,
  TRANSACTION_NAMES,
} from '../../utils/CaptureTransaction';
import BaseService from '../CommonService/BaseService';
import {IndexedDBService} from '../IndexedDBService';
import {DB_MESSAGE_PAGE_SIZE} from '../IndexedDBService/constants';
import {EncryptionService} from '../EncryptionService';

const indexedDBService = IndexedDBService.getInstance();
const captureTransactionInst = CaptureTransaction.getInstance();
const baseService = BaseService.getSharedInstance().axios;
const encryptionService = EncryptionService.getInstance();

export class MessageDBService {
  private static instance: MessageDBService;
  private nonSyncedConversationUuids: Set<string> = new Set();

  constructor() {}

  public static getInstance() {
    if (!MessageDBService.instance) {
      MessageDBService.instance = new MessageDBService();
    }
    return MessageDBService.instance;
  }

  public async getMessagesByConversationUuid(conversationUuid: string) {
    if (this.nonSyncedConversationUuids.has(conversationUuid)) {
      return [];
    }
    try {
      const data = await indexedDBService.getDataByKey<IConversationMessage[]>(
        'conversations',
        conversationUuid
      );
      const decryptedMessages = await this.decryptMessages(data || []);
      return decryptedMessages || [];
    } catch (error) {
      console.error('Error fetching messages:', error);
      return [];
    }
  }

  public async deleteAllMessagesByConversationUuid(conversationUuid: string) {
    try {
      await indexedDBService.deleteDataByKey('conversations', conversationUuid);
      return conversationUuid;
    } catch (error) {
      console.error('Error deleting messages:', error);
      return [];
    }
  }

  public async addSingleMessageByConversationUuid(
    conversationUuid: string,
    message: IConversationMessage
  ) {
    const existingData = await this.getMessagesByConversationUuid(
      conversationUuid
    );
    if (existingData.length === 0) {
      // conversation is not there in the db
      this.nonSyncedConversationUuids.add(conversationUuid);
      return;
    }
    const encryptedMessage = await this.encryptSingleMessage(message);
    const updatedData = sortConversationMessages([
      ...existingData,
      encryptedMessage,
    ]);
    try {
      await indexedDBService.addDataByKey<IConversationMessage[]>(
        'conversations',
        conversationUuid,
        updatedData
      );
      if (this.nonSyncedConversationUuids.has(conversationUuid)) {
        this.nonSyncedConversationUuids.delete(conversationUuid);
      }
      await this.updateConversationLastSyncedAt(conversationUuid);
      return updatedData;
    } catch (error) {
      console.error('Error adding message:', error);
      return [];
    }
  }

  public async updateMessageByConversationUuid(
    conversationUuid: string,
    updatedMessage: IConversationMessage
  ) {
    const existingData = await this.getMessagesByConversationUuid(
      conversationUuid
    );
    if (existingData.length === 0) {
      // conversation is not there in the db
      this.nonSyncedConversationUuids.add(conversationUuid);
      return;
    }
    const encryptedMessage = await this.encryptSingleMessage(updatedMessage);
    const updatedData = this.getUpdatedMessageData(
      existingData,
      encryptedMessage
    );
    try {
      await indexedDBService.addDataByKey<IConversationMessage[]>(
        'conversations',
        conversationUuid,
        updatedData
      );
      if (this.nonSyncedConversationUuids.has(conversationUuid)) {
        this.nonSyncedConversationUuids.delete(conversationUuid);
      }
      await this.updateConversationLastSyncedAt(conversationUuid);
      return updatedData;
    } catch (error) {
      console.error('Error adding message:', error);
      return [];
    }
  }

  public async addMessagesByConversationUuid(
    conversationUuid: string,
    messages: IConversationMessage[]
  ) {
    const existingData = await this.getMessagesByConversationUuid(
      conversationUuid
    );
    const updatedData = await this.encryptMessages(
      sortConversationMessages([...existingData, ...messages])
    );
    try {
      await indexedDBService.addDataByKey<IConversationMessage[]>(
        'conversations',
        conversationUuid,
        this.getFirstNElements(updatedData, DB_MESSAGE_PAGE_SIZE)
      );
      if (this.nonSyncedConversationUuids.has(conversationUuid)) {
        this.nonSyncedConversationUuids.delete(conversationUuid);
      }
      captureTransactionInst.captureTransaction(
        TRANSACTION_NAMES.INDEXED_DB_SYNC_CONVERSATION,
        conversationUuid,
        {
          totalMessages: updatedData.length,
        }
      );
      await this.updateConversationLastSyncedAt(conversationUuid);
      return updatedData;
    } catch (error) {
      console.error('Error adding messages:', error);
      return [];
    }
  }

  public async deleteMessagesByMessageIds(
    conversationUuid: string,
    messageIds: number[]
  ) {
    const existingData = await this.getMessagesByConversationUuid(
      conversationUuid
    );
    const updatedData = existingData.filter(
      (message) => !messageIds.includes(message.id || 0)
    );
    try {
      await indexedDBService.addDataByKey<IConversationMessage[]>(
        'conversations',
        conversationUuid,
        updatedData
      );
    } catch (error) {
      console.error('Error deleting messages:', error);
    }
  }

  public async getUnreadConversationData() {
    const url = `crm-nest/conversation/priority-conversations`;
    try {
      const response = await baseService.post<{id: number; uuid: string}[]>(
        url
      );
      const conversationUuids: string[] = [];
      (response.data || []).forEach((item) => {
        conversationUuids.push(item.uuid);
      });
      this.addToUnreadConversationUuids(conversationUuids);
      return response.data;
    } catch (error) {
      return [];
    }
  }

  private async getStaleConversationUuids() {
    //TODO implement this method
    return [];
  }

  public async removeStaleConversations() {
    const staleConversationUuids = await this.getStaleConversationUuids();
    staleConversationUuids.forEach(async (conversationUuid) => {
      await this.deleteAllMessagesByConversationUuid(conversationUuid);
    });
  }

  public addToUnreadConversationUuids(conversationUuids: string[]) {
    conversationUuids.forEach((uuid) => {
      this.nonSyncedConversationUuids.add(uuid);
    });
  }

  private getFirstNElements<T>(arr: T[] = [], n = 10): T[] {
    if (arr.length === 0) {
      return [];
    }
    return arr.slice(0, n);
  }

  public async updateConversationLastSyncedAt(conversationUuid: string) {
    try {
      await indexedDBService.addDataByKey(
        'conversationLastSyncedAt',
        conversationUuid,
        new Date()
      );
    } catch (error) {
      console.error('Error updating conversation last synced at:', error);
    }
  }

  public async getConversationLastSyncedAt(conversationUuid: string) {
    try {
      const data = await indexedDBService.getDataByKey<Date>(
        'conversationLastSyncedAt',
        conversationUuid
      );
      return data;
    } catch (error) {
      console.error('Error getting conversation last synced at:', error);
      return null;
    }
  }

  private getUpdatedMessageData = (
    existingData: IConversationMessage[],
    updatedMessage: IConversationMessage
  ) => {
    const updatedData: IConversationMessage[] = [];
    for (const existingMesssage of existingData) {
      if (existingMesssage.id === updatedMessage.id) {
        updatedData.push(updatedMessage);
      } else {
        updatedData.push(existingMesssage);
      }
    }
    return sortConversationMessages(updatedData);
  };

  private async encryptMessages(messages: IConversationMessage[]) {
    const encryptedMessages = await Promise.all(
      messages.map((message) => this.encryptSingleMessage(message))
    );
    return encryptedMessages;
  }

  private async encryptSingleMessage(message: IConversationMessage) {
    const encryptedData = await encryptionService.encrypt(
      message.content || ''
    );
    if (!encryptedData) {
      return message;
    }
    const result = cloneDeep(message);
    result.iv = encryptedData.iv;
    result.encryptedMessage = encryptedData.encryptedMessage;
    result.content = '';
    return result;
  }

  private decryptMessages = async (messages: IConversationMessage[]) => {
    const decryptedMessages = Promise.all(
      messages.map((message) => this.decryptSingleMessage(message))
    );
    return decryptedMessages;
  };

  private async decryptSingleMessage(message: IConversationMessage) {
    const iv = message.iv;
    const encryptedMessage = message.encryptedMessage;
    if (!iv || !encryptedMessage) {
      return message;
    }
    const result = cloneDeep(message);
    const decryptedContent = await encryptionService.decrypt(
      iv,
      encryptedMessage
    );
    result.content = decryptedContent || '';
    return result;
  }
}
