import { View, useWindowDimensions } from 'react-native'
import React, { useEffect, useRef, useState } from 'react'
import { Input, InputGroup, InputLeftAddon, InputRightAddon, TextArea, VStack, Text, FormControl, Skeleton } from 'native-base';
import CannedResponses from '../../../../TeamInbox/Conversations/MessagingWindow/CannedResponses/CannedResponses';
import { Colors } from '../../../../../../styles';
import { ICannedResponses } from '../../../../TeamInbox/Conversations/MessagingWindow/CannedResponses/interface';
import { FORM_ONLY_MODULES, RichTextEditor } from '../../../../../common/RichTextEditor/RichTextEditor';
import { RESPONSE_CATEGORY_CODE } from '../../../../../../constants';
import { useOutsideClick } from '../../../../../CustomHooks/useOutsideClick';
import {Popover} from 'antd';
import {testID} from '../../../../../../testUtils';
import './CustomTextAreaField.css';
import { isWeb } from '../../../../../../utils/platformCheckUtils';
import { reactStyles, styles } from '../../../Analytics/style';

export enum CustomTextInputMode {
  textarea = 'TEXTAREA',
  textfield = 'TEXTFIELD'
}

interface ICustomTextAreaFieldProps {
  value?: string;
  defaultValue?: string;
  macroTemplatesEnabled?: boolean;
  isRTE?: boolean;
  disabled?: boolean;
  numberOfLines?: number;
  placeholder?: string;
  showError?: boolean;
  additionalProps?: any;
  onValueChange: (value: string) => void;
  onBlur?: () => void;
  flex?: number;
  inputMode?: CustomTextInputMode;
  prefix?: string;
  suffix?: string;
  disableAutoManageCannedResponsesPosition?: boolean;
}

const CustomTextAreaField = (props: ICustomTextAreaFieldProps) => {
  const { value, macroTemplatesEnabled, isRTE, disabled, numberOfLines, placeholder, showError, additionalProps, defaultValue, flex, onValueChange, onBlur } = props;

  const [selectedValue, setSelectedValue] = useState(value);
  const [dismissResponse, setDismissResponse] = useState(false);
  const inputMode = props.inputMode || CustomTextInputMode.textarea;
  const [focused, setFocused] = useState(false);

  const ref = useOutsideClick(() => {
    setDismissResponse(true);
  });

  useEffect(() => {
    if (selectedValue !== value) {
      setSelectedValue(value);
    }
  }, [value]);

  const isDisplayCannedResponses = () => {
    if (disabled) {
      return false;
    }
    return macroTemplatesEnabled && !dismissResponse && getEnteredString()?.indexOf('/') === 0;
  };

  const getSearchStringForCannedResponse = () => {
    const markdown = getEnteredString();
    if (markdown?.indexOf('\n') !== -1) {
      return markdown?.substring(0, markdown?.indexOf('\n'));
    }
    return markdown;
  };

  const getEnteredString = () => {
    let markdown = selectedValue || '';
    if (markdown?.startsWith('/') || markdown?.startsWith('<p>/')) {
      if (markdown?.startsWith('<p>')) {
        markdown = markdown?.substring(3, markdown?.length);
      }
      if (markdown?.endsWith('</p>')) {
        markdown = markdown?.substring(0, markdown?.length - 4);
      }
    }
    return markdown
  }

  const removeExtraSlashFromString = (currentSelectedValue: string) => {
    if(getEnteredString()?.indexOf('/') === 0){
      return currentSelectedValue?.substring(1, currentSelectedValue?.length);;
    }
    return currentSelectedValue;
  }

  const onTextChange = (text: string) => {
    setSelectedValue(text);
    onValueChange?.(text);
    setDismissResponse(false);
  }

  const [popoverPosition, setPopoverPosition] = useState('top');
  const inputRef = useRef<HTMLInputElement>(null);
  const reactInputRef = useRef<HTMLTextAreaElement>(null);

  const showCannedResponses = isDisplayCannedResponses();
  useEffect(() => {
    if (showCannedResponses && !props.disableAutoManageCannedResponsesPosition) {
      const inputElement = reactInputRef?.current || inputRef?.current;
      if (inputElement) {
        const rect = inputElement.getBoundingClientRect();
        const spaceAbove = rect.top;
        const position = spaceAbove >= 100 ? 'top' : 'bottom';
        setPopoverPosition(position);
      }
    }
  }, [showCannedResponses]);

  const [containerSize, setContainerSize] = useState<number | null>(null);
  const cannedResponseView = () =>  {
    return (
      <div
        style={{
          position: 'absolute',
          ...(popoverPosition === 'top'
            ? { bottom: '100%', marginBottom: '5px' }
            : { top: '30%', marginTop: '5px' }),
          left: 0,
          right: 30,
          backgroundColor: Colors.Custom.White,
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
          zIndex: 999999999999999
        }}
      >
      <CannedResponses
        customJSXWrapper={(children: JSX.Element, loading: boolean) => {
         return (
           <View
            {...testID('cannedResponsesView')}
            onLayout={(event) => {
              const {x, y, width, height} = event.nativeEvent.layout;
              setContainerSize(height);
            }}
            style={styles.cannedResponseViewStyle}
           >
             {loading ? (
                <Skeleton.Text />
             ) : (
               children
             )}
           </View>
         );
        }}
        isReplyMessage={false}
        categoryCode={RESPONSE_CATEGORY_CODE.NOTES}
        skipAllCategory
        searchString={getSearchStringForCannedResponse()}
        onCannedResponsesSelection={(cannedResponseData: ICannedResponses) => {
          let currentSelectedValue;
          setSelectedValue((prev) => {
            currentSelectedValue = prev;
            return prev;
          });
          const finalText = currentSelectedValue
            ? `${cannedResponseData.content} ${removeExtraSlashFromString(
                currentSelectedValue
              )} `
            : cannedResponseData.content;

          onTextChange(finalText);
        }}
      />
      </div>
    );
  }

  const getBorderColor = () => {
    if(focused) {
      if(additionalProps?._focus) {
        return Colors.Custom.selectedTabBorderColor
      }
      else {
        return Colors.Custom.customInputFocusedBorderColor;
      }
    }
    else {
      return Colors.Custom.customInputBorderColor
    }
  }

  const showReactTextArea = isWeb();

  return (
    <VStack flex={flex || 1}>
      <div style={reactStyles.relative}>
        {
          showCannedResponses && popoverPosition === 'top' && cannedResponseView()
        }
        <FormControl isInvalid={showError} onTouchStart={e => {
          e.stopPropagation();
        }}>
          {!isRTE && inputMode === CustomTextInputMode.textfield && (
            <InputGroup>
              {!!props.prefix && (
                <InputLeftAddon
                  children={
                    <Text color={Colors.Custom.Gray500}>{props.prefix}</Text>
                  }
                  backgroundColor={Colors.Custom.Gray100}
                />
              )}
              <Input
                ref={inputRef}
                flex={1}
                value={selectedValue}
                defaultValue={defaultValue}
                isDisabled={disabled}
                placeholder={placeholder}
                isInvalid={showError}
                _focus={{
                  borderColor: Colors.Custom.Gray300,
                }}
                onBlur={() => {
                  if (!isDisplayCannedResponses()) {
                    onBlur?.();
                  }
                }}
                {...additionalProps}
                onChangeText={onTextChange}
              />
              {!!props.suffix && (
                <InputRightAddon
                  children={
                    <Text color={Colors.Custom.Gray500}>{props.suffix}</Text>
                  }
                  backgroundColor={Colors.Custom.Gray100}
                />
              )}
            </InputGroup>
          )}
          {!isRTE && inputMode === CustomTextInputMode.textarea && !showReactTextArea && (
            <TextArea
              ref={inputRef}
              value={selectedValue}
              defaultValue={defaultValue}
              isDisabled={disabled}
              numberOfLines={numberOfLines || 3}
              placeholder={placeholder}
              isInvalid={showError}
              _focus={{
                borderColor: Colors.Custom.Gray300,
              }}
              blurOnSubmit={isDisplayCannedResponses()}
              onBlur={() => {
                if (!isDisplayCannedResponses()) {
                  onBlur?.();
                }
              }}
              {...additionalProps}
              onChangeText={onTextChange}
            />
          )}

          {!isRTE && inputMode === CustomTextInputMode.textarea && showReactTextArea && (
            <textarea
              ref={reactInputRef}
              className={`custom-text-input ${showError && 'custom-text-input-error'}`}
              value={selectedValue}
              defaultValue={defaultValue}
              disabled={disabled || additionalProps?.isReadOnly}
              rows={numberOfLines || additionalProps?.totalLines || 4}
              style={{
                borderRadius: additionalProps?.borderRadius || '4px',
                fontSize: additionalProps?.fontSize || '12px',
                backgroundColor: additionalProps?.backgroundColor,
                borderColor: getBorderColor()
              }}
              autoFocus={additionalProps?.autoFocus}
              placeholder={placeholder}
              onFocus={()=> {
                setFocused(true);
              }}
              onBlur={() => {
                if (!isDisplayCannedResponses()) {
                  onBlur?.();
                }
                setFocused(false);
              }}
              onChange={(e)=> {
                onTextChange(e.target.value)
              }}
            />
          )}

          {isRTE && inputMode === CustomTextInputMode.textarea && (
            <RichTextEditor
              modules={FORM_ONLY_MODULES}
              hideDefaultPlaceholder
              hideBottomBorder
              placeholder={placeholder}
              valueStr={selectedValue}
              isReadOnly={disabled}
              onChangesValue={onTextChange}
            />
          )}
          {
            showCannedResponses && popoverPosition === 'bottom' &&  cannedResponseView()
          }
          </FormControl>
      </div>
    </VStack>
  );
}

export default CustomTextAreaField
