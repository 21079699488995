import {Divider, HStack, Pressable, Text, View, VStack} from 'native-base';
import React, { useEffect, useRef, useState} from 'react';
import {
  BUTTON_TYPE,
  DATE_FORMATS,
  GROUP_MEMBER_TYPE,
} from '../../../../constants';
import {Colors} from '../../../../styles/Colors';
import {DisplayCardAvatar} from '../../DisplayCard/DisplayCardAvatar';
import Feather from 'react-native-vector-icons/Feather';
import {Content} from 'antd/lib/layout/layout';
import {Viewer} from '@toast-ui/react-editor';
import {Popover} from 'antd';
import {useIntl} from 'react-intl';
import {ICommentsItem, ITaskCommentsList} from '../interfaces';
import {getTimeDifference} from '../../../../utils/DateUtils';
import ModalActionBtn from '../../ModalActionBtn/ModalActionBtn';
import GroupUserMentionList from '../../GroupUserMentionList/GroupUserMentionList';
import {formatForMentionUsers} from '../AddTaskUtils';
import { parseHtmlToPlainText} from '../../../../utils/commonUtils';
import './EditorStyles.css'
import CustomEditor from '../../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/CustomEditor/CustomEditor';
import CustomViewer from '../../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/CustomEditor/CustomViewer';
import FoldButtonV2, { ButtonType } from '../../../PersonOmniView/MiddleContainer/CarePlan/components/FoldButtonV2';
import { StyleSheet } from 'react-native';

const CommentsItem = (props: ICommentsItem) => {
  const { accountUsers, editComment, deleteComment, commentsData} = props;
  const editorRef: any = useRef<any>(null);
  const intl = useIntl();
  const [commentsDataState, setCommentsDataState] = useState<{
    commentText: string;
    selectedComment: ITaskCommentsList;
    showAgentList: boolean;
    openActions: boolean;
  }>({
    commentText: '',
    selectedComment: {} as ITaskCommentsList,
    showAgentList: false,
    openActions: false,
  });

  const allUsersForMention = formatForMentionUsers(accountUsers || []);

  useEffect(() => {
    props.onCommentChange?.(commentsDataState.selectedComment.comment, true);
  }, [commentsDataState.selectedComment.comment]);

  const onUserAgentSelection = (userSelected: any) => {
    const inst = editorRef?.current;
    const plainString: string = (inst && inst.getMarkdown()) || '';
    let messagePlainStr = parseHtmlToPlainText(plainString.trim());
    messagePlainStr = messagePlainStr?.trim() || '';
    const currentText = messagePlainStr;
    const lastIndex = currentText?.lastIndexOf('@');
    const replaced =
      currentText?.slice(0, lastIndex) +
      `${userSelected}` +
      currentText?.slice(lastIndex + currentText?.length);

    setCommentsDataState((prev) => {
      return {
        ...prev,
        commentText: replaced,
        showAgentList: false,
      };
    });
    if (editorRef?.current) {
      const inst = editorRef.current;
      inst.setMarkdown(replaced);
    }
  };

  const getActions = () => {
    return (
      <>
        <Pressable
          onPress={() => {
            setCommentsDataState((prev) => {
              return {
                ...prev,
                selectedComment: commentsData,
                openActions: false,
              };
            });
          }}
        >
          <HStack alignItems={'center'} w="24" p={2}>
            <Feather name="edit-3" size={20} color={Colors.Custom.IconColor} />
            <Text ml={2} color={Colors.Custom.Gray500}>
              {intl.formatMessage({
                id: 'edit',
              })}
            </Text>
          </HStack>
        </Pressable>
        <Divider />
        <Pressable
          onPress={() => {
            deleteComment(commentsData);
            setCommentsDataState((prev) => {
              return {
                ...prev,
                openActions: false,
              };
            });
          }}
        >
          <HStack alignItems={'center'} w="24" p={2}>
            <Feather name="trash-2" size={20} color={Colors.Custom.IconColor} />
            <Text ml={2} color={Colors.Custom.Gray500}>
              {intl.formatMessage({
                id: 'delete',
              })}
            </Text>
          </HStack>
        </Pressable>
      </>
    );
  };

  const renderAddComments = () => (
    <VStack mb={5}>
      <View
        style={styles.addCommentContainer}
      >
        <View flex={1}>
          <Content className={`${props?.showEditCommentError ? 'messagewindow-editor-task-comment-error-customized' : 'messagewindow-editor-task-comment-customized'}`}>
            <CustomEditor
              placeholder="Add a comment"
              initialValue={commentsDataState.selectedComment.comment || ''}
              initialEditType="wysiwyg"
              height="140px"
              useCommandShortcut={true}
              previewHighlight={false}
              customRef={editorRef}
              toolbarItems={[['heading', 'bold', 'italic', 'strike']]}
              autofocus={false}
              onChange={() => {
                if (editorRef && editorRef?.current) {
                  const inst = editorRef.current;
                  const plainString = inst.getMarkdown();
                  const includeAt = plainString.slice(
                    plainString.lastIndexOf('@')
                  );
                  if (!plainString.length) {
                    isDisplayUserAgents(false);
                  } else if (!includeAt.trim().includes('@')) {
                    isDisplayUserAgents(false);
                  } else if (includeAt.trim().includes('@')) {
                    isDisplayUserAgents(true);
                  }
                  setCommentsDataState((prev) => {
                    return {
                      ...prev,
                      commentText: plainString,
                    };
                  });
                  props.onCommentChange?.(plainString, true);
                }
              }}
            />
          </Content>
          {props?.showEditCommentError && (
            <Text marginBottom={2} color={Colors.Custom.crossIconColor}>Unsaved</Text>
          )}
        </View>
      </View>
      <View
          alignItems={'center'}
          style={styles.buttonContainer}
          flexDirection={'row'}
        >
          <FoldButtonV2
            key={'save'}
            label={intl.formatMessage({ id: 'save' })}
            buttonType={ButtonType.primary}
            isDisabled={!commentsDataState.commentText?.length}
            onPress={() => {
              const updatedComments = {
                ...commentsDataState.selectedComment,
                comment: commentsDataState.commentText,
                isModified: true,
              };
              editComment(updatedComments);
              commentsData.comment = commentsDataState.commentText;
              setCommentsDataState((prev) => {
                return {
                  ...prev,
                  commentText: '',
                  selectedComment: {} as ITaskCommentsList,
                };
              });
              if (editorRef?.current) {
                const inst = editorRef.current;
                inst.reset();
              }
            }}
          />
          <FoldButtonV2
            key={'cancel'}
            label={intl.formatMessage({ id: 'cancel' })}
            buttonType={ButtonType.secondary}
            onPress={() => {
              setCommentsDataState((prev) => {
                return {
                  ...prev,
                  commentText: '',
                  selectedComment: {} as ITaskCommentsList,
                };
              });
              if (editorRef?.current) {
                const inst = editorRef?.current;
                inst.reset();
              }
              props?.onCommentChange?.('',true);
            }}
          />
        </View>
    </VStack>
  );

  const getUserListElem = () => {
    let listElem = <></>;
    if (allUsersForMention?.length && commentsDataState.showAgentList) {
      listElem = (
        <GroupUserMentionList
          memberList={allUsersForMention}
          searchString={commentsDataState.commentText.slice(
            commentsDataState.commentText.lastIndexOf('@')
          )}
          selectedTab={'reply'}
          onMemberSelection={(selectedUser: any) => {
            onUserAgentSelection(selectedUser);
          }}
        />
      );
      return listElem;
    }
  };

  const isDisplayUserAgents = (listCheck: boolean) => {
    setCommentsDataState((prev) => {
      return {
        ...prev,
        showAgentList: listCheck,
      };
    });
  };

  return (
    <>
      {commentsDataState.selectedComment?.id || commentsDataState.selectedComment?.tempId ? (
        <>
          {getUserListElem()}
          {renderAddComments()}
        </>
      ) : (
        <VStack key={commentsData.id || commentsData.tempId}>
          <View style={styles.commentHeader}>
            <HStack flex={1} alignItems={'center'}>
              <DisplayCardAvatar
                avatarStyle={{
                  avatarSize: '10',
                  width: 28,
                  height: 28,
                  textStyle: {
                    fontSize: 12,
                  },
                }}
                isLetterAvatarShow={true}
                userData={{
                  userId: '',
                  userType: GROUP_MEMBER_TYPE.CONTACT,
                  userName: commentsData?.userName,
                }}
              />
              <Text style={styles.userName}>{commentsData?.userName || ''}</Text>
              <View style={styles.divider} backgroundColor={Colors.Custom.Gray200} />
              <Text style={styles.timeStamp}>
                {commentsData?.createdOn ? getTimeDifference(commentsData?.createdOn) : ''}
              </Text>
              {commentsData.isModified && (
                <>
                  <View style={styles.divider} backgroundColor={Colors.Custom.Gray200} />
                  <Text style={styles.editedText}>{'Edited'}</Text>
                </>
              )}
            </HStack>
            <View style={styles.actionsContainer}>
              <Popover
                overlayInnerStyle={{borderRadius: 16}}
                style={{borderRadius: 16}}
                placement={'left'}
                trigger="click"
                content={getActions()}
                visible={commentsDataState.openActions}
                onVisibleChange={() => {
                  setCommentsDataState((prev) => {
                    return {
                      ...prev,
                      openActions: false,
                    };
                  });
                }}
              >
                <Pressable
                  onPress={() => {
                    setCommentsDataState((prev) => {
                      return {
                        ...prev,
                        openActions: !commentsDataState.openActions,
                      };
                    });
                  }}
                >
                  <Feather name="more-horizontal" size={14} style={styles.moreIcon} />
                </Pressable>
              </Popover>
            </View>
          </View>
          <View key={commentsData.id} style={styles.commentContent}>
            <Content
              key={commentsData.id}
              className="message-box-viewer" 
              style={{
                fontSize: 14,
                fontWeight: 400,
                flexWrap: 'wrap',
                color: Colors.Custom.Gray700,
              }}>
              <CustomViewer
                linkAttributes={{
                  target: '_blank',
                }}
                extendedAutolinks={true}
                initialValue={commentsData.comment}
              />
            </Content>
          </View>
        </VStack>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  avatarStyle: {
    avatarSize: '10',
    width: 28,
    height: 28,
    textStyle: {
      fontSize: 12,
    },
  },
  commentHeader: {
    alignItems: 'center',
    width: '100%',
    flexDirection: 'row',
  },
  userName: {
    marginLeft: 16,
    fontWeight: '500',
    fontSize: 16,
    fontStyle: 'normal',
    color: Colors.Custom.Gray900,
  },
  divider: {
    height: 16,
    // backgroundColor: Colors.Custom.Gray200,
    width: 0.5,
    marginLeft: 8,
  },
  timeStamp: {
    fontWeight: '500',
    fontSize: 12,
    fontStyle: 'normal',
    color: Colors.Custom.Gray500,
    marginLeft: 5,
  },
  editedText: {
    fontWeight: '500',
    fontSize: 12,
    fontStyle: 'normal',
    color: Colors.Custom.Gray900,
    marginLeft: 5,
  },
  actionsContainer: {
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  popoverOverlay: {
    borderRadius: 16,
  },
  moreIcon: {
    marginRight: 5,
    fontSize: 20,
    color: Colors.Custom.Gray500,
  },
  commentContent: {
    marginLeft: 48,
    marginTop: -8,
  },
  messageContent: {
    fontSize: 14,
    fontWeight: '400',
    flexWrap: 'wrap',
    color: Colors.Custom.Gray700,
  },
  addCommentContainer: {
    flexDirection: 'row',
    flex: 1,
    alignItems: 'flex-start',
    width: '100%',
  },
  buttonContainer: {
    bottom: 10,
    top: 2
  },
});

export default CommentsItem;
