import React from 'react';
import ReactDOM from 'react-dom';
import {ReactComponent} from '@foldhealth/formio-react';
import {Formio} from '@foldhealth/formiojs';
import CustomWrapper from '../CustomWrapper/CustomWrapper';
import KeyField from '../../EditFormFields/KeyField';
import ConditionalComponents from '../../EditFormFields/ConditionalFields';
import CustomClassComponent from '../CustomWrapper/CustomClassComponent';
import QuillConfig from '../../Builder/QuillConfig';
import AddOrUpdateChiefComplaint from './AddOrUpdateChiefComplaint/AddOrUpdateChiefComplaint';
import SearchableComponentFields from '../../EditFormFields/SearchableComponentFields';
import ShareWithPatientFields from '../../EditFormFields/ShareWithPatientFields';
import MacroTemplateField from '../../EditFormFields/MacroTemplateField';

export default class ChiefComplaint extends CustomClassComponent {
  static get builderInfo() {
    return {
      title: 'Chief Complaint',
      icon: 'filetext1',
      group: 'advanced',
      documentation: '',
      schema: ChiefComplaint.schema(),
    };
  }

  labelIsHidden() {
    return this.options.renderMode === 'html';
  }

  static schema() {
    return ReactComponent.schema({
      type: 'chiefComplaint',
      label: 'Chief Complaint',
      key: 'chiefComplaint',
    });
  }

  attachReact(element: any) {
    return ReactDOM.render(
      <CustomWrapper
        formOptionData={this.options}
        capabilityList={[]}
      >
        <AddOrUpdateChiefComplaint
          options={this.options}
          disabled={this.disabled}
          component={this.component}
          validateRef={this.validateRef}
          onChange={this.updateValue}
          setValueRef={this.setValueRef}
        />
      </CustomWrapper>,
      element
    );
  }

  static editForm = function () {
    return {
      key: 'display',
      components: [
        {
          type: 'oldtextfield',
          key: 'label',
          label: 'Label',
          input: true,
          validate: {
            required: true,
          },
        },
        {
          type: 'checkbox',
          key: 'validate.required',
          label: 'Is this field required?',
          input: true,
        },
        ...SearchableComponentFields,
        ...MacroTemplateField,
        ...ShareWithPatientFields,
        {
          type: 'oldtextfield',
          input: true,
          key: 'placeHolder',
          label: 'Placeholder',
          tooltip:'The placeholder text that will appear when this field is empty',
          placeholder:'Placeholder (Optional)'
        },
        {
          type: 'textarea',
          input: true,
          key: 'description',
          label: 'Description',
          placeholder: 'Description for this field.',
          tooltip:
            'The description is text that will appear below the input field.',
          editor: 'quill',
          wysiwyg: QuillConfig,
        },
        ...ConditionalComponents,
        ...KeyField,
      ],
    };
  };
}

Formio.Components.addComponent('chiefComplaint', ChiefComplaint);
