import {useEffect} from 'react';

export const useOnWindowClose = (
  onExitEventListener: (e: BeforeUnloadEvent) => void
) => {
  useEffect(() => {
    window.addEventListener('beforeunload', onExitEventListener);

    return () => {
      window.removeEventListener('beforeunload', onExitEventListener);
    };
  }, []);
};
