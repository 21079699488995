import {View, Text} from 'react-native';
import React from 'react';
import {ICommonSvgProps} from './interfaces';

const UnityAISvg = (props: ICommonSvgProps) => {
  const height = props.height || '17';
  const width = props.width || '16';

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.35559 2.93856C5.58622 2.35381 6.41378 2.35381 6.64441 2.93856L7.35909 4.75066C7.4295 4.92919 7.57081 5.0705 7.74934 5.14091L9.56144 5.85559C10.1462 6.08622 10.1462 6.91378 9.56144 7.14441L7.74934 7.85909C7.57081 7.9295 7.4295 8.07081 7.35909 8.24934L6.64441 10.0614C6.41378 10.6462 5.58622 10.6462 5.35559 10.0614L4.64091 8.24934C4.5705 8.07081 4.42919 7.9295 4.25066 7.85909L2.43856 7.14441C1.85381 6.91378 1.85381 6.08622 2.43856 5.85559L4.25066 5.14091C4.42919 5.0705 4.5705 4.92919 4.64091 4.75066L5.35559 2.93856Z"
        fill="url(#paint0_linear_977_2256)"
      />
      <path
        d="M10.9923 9.39875C11.1144 9.0893 11.5523 9.0893 11.6744 9.39875L12.2081 10.7521C12.2453 10.8465 12.3201 10.9213 12.4146 10.9586L13.7679 11.4923C14.0774 11.6144 14.0774 12.0523 13.7679 12.1744L12.4146 12.7081C12.3201 12.7453 12.2453 12.8201 12.2081 12.9146L11.6744 14.2679C11.5523 14.5774 11.1144 14.5774 10.9923 14.2679L10.4586 12.9146C10.4213 12.8201 10.3465 12.7453 10.2521 12.7081L8.89875 12.1744C8.5893 12.0523 8.5893 11.6144 8.89875 11.4923L10.2521 10.9586C10.3465 10.9213 10.4213 10.8465 10.4586 10.7521L10.9923 9.39875Z"
        fill="url(#paint1_linear_977_2256)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_977_2256"
          x1="8"
          y1="-6.92"
          x2="-6.3009"
          y2="2.10898"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#1E9DAE" />
          <stop offset="1" stop-color="#D478FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_977_2256"
          x1="8"
          y1="-6.92"
          x2="-6.3009"
          y2="2.10898"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#1E9DAE" />
          <stop offset="1" stop-color="#D478FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default UnityAISvg;
