import {useEffect} from 'react';
import { isWeb } from '../../utils/platformCheckUtils';

export const useKeypress = (
  key: string,
  keyUpAction: () => void,
  dependencyList: any[]
) => {
  if (isWeb()) {
    useEffect(() => {
      const onKeyup = (event: KeyboardEvent) => {
        if (event.key === key) {
          keyUpAction();
          // event?.stopPropagation();
          // event?.preventDefault();
        }
      };
      window.addEventListener('keyup', onKeyup);
      return () => window.removeEventListener('keyup', onKeyup);
    }, [...dependencyList]);
  }
  
};
