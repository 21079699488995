

import {Drawer} from 'antd';
import {useMediaQuery, View} from 'native-base';
import React, {useState} from 'react';
import {COMMON_ACTION_CODES} from '../../../../../../../../../../constants/ActionConst';
import {BUTTON_TYPE, IPAD_MINI_WIDTH, IPAD_WIDTH} from '../../../../../../../../../../constants/StringConst';
import {Colors} from '../../../../../../../../../../styles/Colors';
import {getUserFullName} from '../../../../../../../../../../utils/commonUtils';
import TaskDetailsPreview from '../../../../../../../../../common/AddTask/TaskDetailsPreview';
import {ModalActionTitle} from '../../../../../../../../../common/ModalActionTitle/ModalActionTitle';

const TaskActivityView = (props: {
  taskData: any,
  isDrawerVisible: boolean;
  navigation?: any
  onActionPerformed: (actionCode: string, actionData?: any) => void;
}) => {
  const {taskData, isDrawerVisible, onActionPerformed} = props;
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  return (
    <Drawer
      destroyOnClose
      placement="right"
      onClose={() => {
        onActionPerformed(COMMON_ACTION_CODES.CLOSE_MODAL)
      }}
      visible={isDrawerVisible}
      closable={false}
      width={isIPadScreen || isIPadMiniScreen ? '70%' : '42%'}
      title={<ModalActionTitle
        title={'AddTask'}
        titleColor={''}
        buttonList={[
          {
            show: true,
            id: 1,
            btnText: 'cancel',
            textColor: Colors.Custom.mainSecondaryBrown,
            variant: BUTTON_TYPE.SECONDARY,
            isTransBtn: false,
            onClick: () => {
              onActionPerformed(COMMON_ACTION_CODES.CLOSE_MODAL);
            },
          }

        ]}

      />}
    >
      <TaskDetailsPreview
        task={taskData}
        assignee={{
          label: getUserFullName(),
        }}
      />
    </Drawer>
  )
};

export default React.memo(TaskActivityView);
