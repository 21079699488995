import { HStack, Icon, Link, Text, VStack } from 'native-base';
import { Pressable, ViewStyle } from 'react-native';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { COMMON_ACTION_CODES } from '../../../../constants/ActionConst';
import { Colors } from '../../../../styles';
import { SingleNotification } from '../interfaces';
import { getAppointmentDateTimeString, getNotificationDataForEmailNotification, getUSLocaleDateString, NOTIFICATION_TYPES } from '../NotificationsUtils';
import { styles } from './NotificationListItemStyles';
import { EmailHtml } from '../../EmailPopupView/EmailHtml';
import { isMentionedMessage, isValidJsonString } from '../../../../utils/commonUtils';
import { Viewer } from '@toast-ui/react-editor';

export interface INotificationListItemProps {
  wrapperStyle?: ViewStyle;
  singleNotification: SingleNotification;
  onActionPerformed: (
    actionCode: string,
    actionData: SingleNotification
  ) => void;
}
const isExternalLink = (url: string) => {
  try {
    const tmp = document.createElement('a');
    tmp.href = url;
    return tmp.host !== window.location.host;
  } catch (error) {
  }
  return true;
};

const urlify = (text: string) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const linkList: any = [];
  text = text.replace(urlRegex, function (url) {
    linkList.push(url);
    return '<urlify<Link>urlify>';
  })
  const textList = text.split('<urlify<Link>urlify>');
  const list: any = []
  if (textList.length && linkList.length) {
    let index = 0;
    textList.forEach((data: any) => {
      list.push({ txt: data, isLink: false });
      if (linkList.length > index) {
        list.push({ txt: linkList[index], isLink: true, isExternal: isExternalLink(linkList[index]) });
        index = index + 1;
      }
    })
  } else {
    linkList.forEach((data: any) => {
      list.push({ txt: data, isLink: true, isExternal: isExternalLink(data) });
    })
    textList.forEach((data: any) => {
      list.push({ txt: data, isLink: false });
    })
  }
  return list;
  // or alternatively
  // return text.replace(urlRegex, '<a href="$1">$1</a>')
}


const NotificationListItem = (props: INotificationListItemProps) => {
  const { wrapperStyle, singleNotification } = props;

  const { description, typeDisplayString, createdOn } = getNotificationDataForEmailNotification(singleNotification);
  let { title } = getNotificationDataForEmailNotification(singleNotification);

  const date = getUSLocaleDateString(createdOn);

  const dateTimeString = getAppointmentDateTimeString(singleNotification);
  const urlifyDescriptionList = urlify(description);
  const getUrlifyDescriptionList = () => {
    const element = <>{
      urlifyDescriptionList.map((data: any) => {
        return data.isLink ? <Link href={data.txt} isExternal={data.isExternal}>{data.txt}</Link> : <Text style={styles.description}>
          {data.txt}
        </Text>;
      })}
    </>
    return element;
  }

  let hideDescription = false;

  if (isValidJsonString(props.singleNotification?.metaData?.messageData?.content)) {
    const data = JSON.parse(props.singleNotification?.metaData?.messageData?.content)
    if (data.resourceCode === 'CONTACT_CARD') {
      hideDescription = true;
      title = `${title} [Shared a Contact]`;
    }
  }

  return (
    <VStack style={[styles.container, wrapperStyle]} >

      <HStack style={styles.itemContainer}>

        <Text style={styles.dateText}>{date}</Text>
        <Pressable
          onPress={() => {
            props.onActionPerformed(
              COMMON_ACTION_CODES.DELETE,
              singleNotification
            );
          }}
        >
          <Icon as={AntIcon} name={'close'} size="3" color={Colors.Custom.Gray400} />
        </Pressable>
      </HStack>
      <Pressable
        onPress={() => {
          props.onActionPerformed(
            COMMON_ACTION_CODES.UPDATE,
            singleNotification
          );
        }}
      >
        <VStack>
          <Text style={styles.titleText}>{singleNotification?.type === NOTIFICATION_TYPES.POPULATION_GROUP_ACTIVITY ?
            `${singleNotification?.metaData?.groups?.[0]?.forms?.formName} was sent to ${singleNotification?.metaData?.groups?.[0]?.forms?.successCount}/${singleNotification?.metaData?.groups?.[0]?.forms?.successCount + singleNotification?.metaData?.groups?.[0]?.forms?.failCount + singleNotification?.metaData?.groups?.[0]?.forms?.pendingCount} members`
            : title}</Text>

          {isMentionedMessage(description) ? (
            <Viewer initialValue={description} />
          ) : (
            <EmailHtml html={singleNotification?.type === NOTIFICATION_TYPES.POPULATION_GROUP_ACTIVITY ? 'Details of send form action on population group available' : (hideDescription ? "" : description)} />
          )}
          {dateTimeString && (
            <Text style={styles.description}>{`on ${dateTimeString}`}</Text>
          )}
          {singleNotification?.notificationDetailElement}
        </VStack>

      </Pressable>
    </VStack>
  );
};

export default NotificationListItem;
