import {DatePicker} from 'antd';
import {FormControl, HStack, Input, VStack, Text, Select, CheckIcon, View} from 'native-base';
import React, {useEffect, useState} from 'react';
import {DATE_FORMATS} from '../../../../../constants';
import {Colors} from '../../../../../styles';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
// import PageBodyContainer from '../../../../common/PageBodyContainer/PageBodyContainer';
// import {TableTopBar} from '../../../../common/TableTopBar';
import {styles} from './CampaignDetailsFormStyles';
import {ICampaignDetailsFormState, ICampaignErrors} from '../interfaces';
//import {CheckboxChangeEvent} from 'antd/lib/checkbox';
import {CheckboxValueType} from 'antd/lib/checkbox/Group';
import {isPastDay, getMomentObj} from '../../../../../utils/DateUtils';
import moment from 'moment';
import {useIntl} from 'react-intl';
import PageBodyContainer from '../../../../common/PageBodyContainer/PageBodyContainer';

const CampaignDetailsForm = (props: {
  onChange?: (value: ICampaignDetailsFormState) => void;
  campaignDetailsError?: ICampaignErrors;
  isReadOnly?: boolean;
  initData?: ICampaignDetailsFormState;
}) => {

  const intl = useIntl();
  const [selectedTimeType, setSelectedTimeType] = useState<string>();

  const [campaignDetailsFormData, setCampaignDetailsFormData] =
    useState<ICampaignDetailsFormState>({
      type: props?.initData?.type || ['email'],
      campaignOptions: [
        {
          label: intl.formatMessage({id: 'email'}),
          value: 'email',
        },
        {
          label: intl.formatMessage({id: 'sms'}),
          value: 'sms',
        },
      ],
      enableReminder: props?.initData?.enableReminder || false,
      numberOfRetry: props?.initData?.numberOfRetry || '',
      interval: props?.initData?.interval || '',
      name: props?.initData?.name || '',
      campaignTime: {
        type: props?.initData?.campaignTime?.type || null,
        value: props?.initData?.campaignTime?.value || undefined,
      },
      description: props?.initData?.description || '',
    });

  useEffect(() => {
    props?.onChange?.(campaignDetailsFormData);
  }, [campaignDetailsFormData]);

 const getSelecgtedTimeType = () => {
  if(campaignDetailsFormData?.campaignTime?.type == 'scheduleOnDate' &&  campaignDetailsFormData?.campaignTime?.value !=null){
    return campaignDetailsFormData?.campaignTime?.type
  } else if(campaignDetailsFormData?.campaignTime?.type == 'immediate'){
    return campaignDetailsFormData?.campaignTime?.type
  }else{
    return selectedTimeType
  } 
 }

  if (props.isReadOnly) {
    return (
        <VStack
          flex={1}
          justifyContent={'space-between'}
        >
          <HStack style={{marginTop: 10}}>
            <Text fontWeight={600} fontSize="lg" flex={1}>
              {campaignDetailsFormData.name}
            </Text>
          </HStack>
          <HStack style={{marginTop: 10}}>
            <Text flex={1} fontWeight={500} fontSize="md" numberOfLines={2}>
            {campaignDetailsFormData.description}
            </Text>
          </HStack>
          <HStack style={{marginTop: 30}}>
            <Text flex={1} fontWeight={300}>
              {intl.formatMessage({id: 'campaignType'})}
            </Text>
            <Text flex={6} fontWeight={500} numberOfLines={1}>
              {campaignDetailsFormData.type.map(
                (type: CheckboxValueType) => type
              )}
            </Text>
          </HStack>

          {campaignDetailsFormData.enableReminder ? (
            <HStack style={{marginTop: 10}}>
              <Text flex={1} fontWeight={300}>
              {intl.formatMessage({id: 'campaignReminders'})}
              </Text>
              <Text flex={6} fontWeight={300} numberOfLines={1}>
                <Text fontWeight={300} marginRight={1} numberOfLines={1}>
                  {campaignDetailsFormData.numberOfRetry}
                </Text>
                <Text fontWeight={300} marginRight={1} numberOfLines={1}>
                  {` ${intl.formatMessage({id: 'reminders'})} `}
                </Text>
                <Text fontWeight={300} marginRight={1} numberOfLines={1}>
                  {campaignDetailsFormData.interval}
                </Text>
                <Text fontWeight={300} numberOfLines={1}>
                  {` ${intl.formatMessage({id: 'times'})} `}
                </Text>
              </Text>
            </HStack>
          ) : null}
          <HStack style={{marginTop: 10}}>
            <Text flex={1} fontWeight={300}>
              Campaign Time
            </Text>
            <Text flex={6} fontWeight={300} numberOfLines={1}>
              <Text fontWeight={300} marginRight={1} numberOfLines={1}>
                {campaignDetailsFormData.campaignTime.value
                  ? moment(
                      campaignDetailsFormData.campaignTime.value
                    ).format("DD/MM/YYYY hh:mm a")
                  : intl.formatMessage({id: 'immediate'})}
              </Text>
            </Text>
          </HStack>
        </VStack>
    );
  }

  return (
    <>
    <PageBodyContainer style={{marginHorizontal:0}}>
      <VStack
        style={{ }}>
        <HStack
          flex={1}
        //justifyContent={'center'}
        >
          {/* <VStack flex={0.5}>
            <FormControl
              style={styles.formElement}
              isRequired
              isInvalid={props.campaignDetailsError?.campaignName ? true : false}
            >
              <FormControl.Label style={styles.formLabel}>
                <DisplayText
                  textLocalId={'campaignName'}
                  size={'smMedium'}
                  extraStyles={{ color: Colors.Custom.Gray700 }}
                />
              </FormControl.Label>
              <Input
                isReadOnly={props.isReadOnly}
                defaultValue={campaignDetailsFormData?.name}
                value={campaignDetailsFormData?.name}
                onChangeText={(value: string) => {
                  setCampaignDetailsFormData({
                    ...campaignDetailsFormData,
                    name: value,
                  });
                }}
              />
              {props.campaignDetailsError?.campaignName && (
                <FormControl.ErrorMessage
                  _text={{
                    fontSize: 'xs',
                    color: 'error.500',
                    fontWeight: 500,
                  }}
                >
                  {props.campaignDetailsError?.campaignName}
                </FormControl.ErrorMessage>
              )}
            </FormControl>
            <FormControl style={[styles.formElement]}>
              <FormControl.Label style={styles.formLabel}>
                <DisplayText
                  textLocalId={'description'}
                  size={'smMedium'}
                  extraStyles={{ color: Colors.Custom.Gray700 }}
                />
              </FormControl.Label>
              <Input
                isReadOnly={props.isReadOnly}
                defaultValue={campaignDetailsFormData?.description}
                value={campaignDetailsFormData?.description}
                onChangeText={(value: string) => {
                  setCampaignDetailsFormData({
                    ...campaignDetailsFormData,
                    description: value,
                  });
                }}
              />
            </FormControl>
          </VStack> */}
          <VStack flex={1} marginLeft={15} marginRight={15}>
            <FormControl
              style={[styles.formElement]}
              isRequired
              isInvalid={props.campaignDetailsError?.campaignTime ? true : false}
            >
              <FormControl.Label style={styles.formLabel} isRequired>
                <DisplayText
                  textLocalId={'scheduledImmediateCampaign'}
                  size={'smMedium'}
                  extraStyles={{ color: Colors.Custom.Gray700 }}
                />
              </FormControl.Label>
              <Select minWidth='150' 
              selectedValue={getSelecgtedTimeType()} 
              accessibilityLabel='Choose Time' 
              placeholder='Choose Time' 
              _selectedItem={{
                bg: "teal.600",
                endIcon: <CheckIcon size={5} />
              }}
                onValueChange={value => {
                  setSelectedTimeType(value)
                  setCampaignDetailsFormData({
                    ...campaignDetailsFormData,
                    campaignTime: {
                      type: value,
                      value: value == 'immediate' ? undefined : moment().toDate(),
                    },
                  });
                }}
              >
                <Select.Item label='Immediate' value='immediate' />
                <Select.Item label='Schedule on date' value='scheduleOnDate' />
              </Select>
              {props.campaignDetailsError?.campaignTime && (
              <FormControl.ErrorMessage
                _text={{
                  fontSize: 'xs',
                  color: 'error.500',
                  fontWeight: 500,
                }}
              >
                {props.campaignDetailsError?.campaignTime}
              </FormControl.ErrorMessage>
            )}
            </FormControl>

            <FormControl style={[styles.formElement]}>
              <FormControl.Label style={styles.formLabel}>
                <DisplayText
                  textLocalId={'Choose Time'}
                  size={'smMedium'}
                  extraStyles={{ color: Colors.Custom.Gray700 }}
                />
              </FormControl.Label>
              <DatePicker
                showTime={{format: 'HH:mm', use12Hours: true}}
                style={{ height: '36px', flex: 1 }}
                allowClear={false}
                defaultValue={
                  campaignDetailsFormData?.campaignTime?.value
                    ? getMomentObj(campaignDetailsFormData?.campaignTime?.value)
                    : undefined
                }
                value={
                  campaignDetailsFormData?.campaignTime?.value
                    ? getMomentObj(campaignDetailsFormData?.campaignTime?.value)
                    : undefined
                }
                placeholder=""
                format={DATE_FORMATS.MESSAGE_DATE_FORMAT}
                disabledDate={(current: any) => {
                  return current && isPastDay(current);
                }}
                onChange={(date: any) => {
                  if (date) {
                    setCampaignDetailsFormData({
                      ...campaignDetailsFormData,
                      campaignTime: {
                        type: 'scheduleOnDate',
                        value: date.toDate(),
                      },
                    });
                  }
                }}
              />
            </FormControl>
          </VStack>
        </HStack>
      </VStack>
      </PageBodyContainer>
    </>
  );
};

export default CampaignDetailsForm;
