import React from 'react';

const ReadMsgSvg = (props: {color?: string, width?: string, height?: string, strokeWidth?: number}) => {
  const {color, width, height, strokeWidth} = props;
  return (
    <>
      <svg
        width={ width ? width : "16"}
        height={ height ? height : "16"}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.56 5.24316L4.04667 10.7565L2 8.70983M7.62667 9.8965L8.48667 10.7565L14 5.24316"
          stroke={color ? color : "#5ADA81"}
          stroke-width={ strokeWidth ? strokeWidth : "1.5"}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};

export default ReadMsgSvg;
