import React from 'react';
import { Colors } from "../../../../../../styles"
import { IGraphDataSetInCoords, getTooltipContent } from "../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailTables/VitalsGraphView/VitalsGraphUtils"
import { GoalOperator } from "./AddOrUpdateGoals/interface"
import { VictoryArea, VictoryLabel, VictoryLine, VictoryGroup } from '../../../../../PersonOmniView/LeftContainer/RecentActivity/RecentReport/VictoryComponent'
import { IGoalLinesViewProps } from './GoalLinesView';


const BloodPressureGraphView = (props: IGoalLinesViewProps) => {

  const {goalLines, graphData, fontSize, unit, isExtended} = props;

  const higherSysDys = !!goalLines?.[0]?.value ? goalLines[0].value.toString().split("/") : undefined;
  const lowerSysDys = !!goalLines?.[1]?.value ? goalLines[1].value.toString().split("/") : undefined;
  if(!higherSysDys || higherSysDys?.length < 2) { // no goal
    return <></>
  }
  if(!lowerSysDys || lowerSysDys?.length < 2) { // single goal (eq)
    return (
      higherSysDys.map((value: string, ind: number) => {
        return (
          <VictoryLine
            key={""}
            name={getTooltipContent(goalLines?.[0].operator, +value, `${unit} ${ind === 0 ? '(sys)' : '(dys)'}`)}
            style={{
              data: {
                stroke: Colors.Custom.goalLineColor,
                strokeWidth: 0.5,
                strokeDasharray: 3,
                strokeLinecap: 'round'
              },
              labels: {fontSize: fontSize},
            }}
            labelComponent={<VictoryLabel x={100} />}
            y={() => +value}
          />
        )
      })
    )
  }

  // between goal
  const sysGoalValues = {
    max: Math.max(+higherSysDys[0], +lowerSysDys[0]),
    min: Math.min(+higherSysDys[0], +lowerSysDys[0])
  }
  const dysGoalValues = {
    max: Math.max(+higherSysDys[1], +lowerSysDys[1]),
    min: Math.min(+higherSysDys[1], +lowerSysDys[1])
  }
  return (
    [
      // systolic area and lines
      <VictoryArea
        key={'bloodPressureSysGoalArea'}
        data={[
          ...(graphData[0] as IGraphDataSetInCoords[])?.map((e: any) => {
            return {
              x: e?.x,
              y: sysGoalValues.max,
              y0: sysGoalValues.min,
            }
          }),
          ...(isExtended ? [
            {
              x: 0,
              y: sysGoalValues.max,
              y0: sysGoalValues.min,
            },
            {
              x: (graphData[0] as IGraphDataSetInCoords[])?.length + 1,
              y: sysGoalValues.max,
              y0: sysGoalValues.min,
            }
          ] : [])
        ]}
        style={{ data: { fill: Colors.Custom.sysGoalAreaColor, strokeWidth: 1, strokeDasharray: 1 } }}
      />,
      <VictoryLine
        key={'bloodPressureSysGoalAreaLine-1'}
        name={getTooltipContent(GoalOperator.between, [sysGoalValues.max, sysGoalValues.min], `${unit} (sys)`)}
        style={{
          data: {
            stroke: Colors.Custom.sysGoalAreaBorderColor,
            strokeWidth: 0.5,
            strokeDasharray: 3,
            strokeLinecap: 'round'
          },
          labels: {fontSize: fontSize},
        }}
        labelComponent={<VictoryLabel x={100} />}
        y={() => sysGoalValues.max}
      />,
      <VictoryLine
        key={'bloodPressureSysGoalAreaLine-2'}
        name={getTooltipContent(GoalOperator.between, [sysGoalValues.max, sysGoalValues.min], `${unit} (sys)`)}
        style={{
          data: {
            stroke: Colors.Custom.sysGoalAreaBorderColor,
            strokeWidth: 0.5,
            strokeDasharray: 3,
            strokeLinecap: 'round'
          },
          labels: {fontSize: fontSize},
        }}
        labelComponent={<VictoryLabel x={100} />}
        y={() => sysGoalValues.min}
      />,
      // dystolic area and lines
      <VictoryArea
        key={'bloodPressureDysGoalArea'}
        data={[
          ...(graphData[0] as IGraphDataSetInCoords[])?.map((e: any) => {
            return {
              x: e?.x,
              y: dysGoalValues.max,
              y0: dysGoalValues.min,
            }
          }),
          ...(isExtended ? [
            {
              x: 0,
              y: dysGoalValues.max,
              y0: dysGoalValues.min,
            },
            {
              x: (graphData[0] as IGraphDataSetInCoords[])?.length + 1,
              y: dysGoalValues.max,
              y0: dysGoalValues.min,
            }
          ] : [])
        ]}
        style={{ data: { fill: Colors.Custom.dysGoalAreaColor, strokeWidth: 1, strokeDasharray: 1 } }}
      />,
      <VictoryLine
        key={'bloodPressureDysGoalAreaLine-1'}
        name={getTooltipContent(GoalOperator.between, [dysGoalValues.max, dysGoalValues.min], `${unit} (dys)`)}
        style={{
          data: {
            stroke: Colors.Custom.dysGoalAreaBorderColor,
            strokeWidth: 0.5,
            strokeDasharray: 3,
            strokeLinecap: 'round'
          },
          labels: {fontSize: fontSize},
        }}
        labelComponent={<VictoryLabel x={100} />}
        y={() => dysGoalValues.max}
      />,
      <VictoryLine
        key={'bloodPressureDysGoalAreaLine-2'}
        name={getTooltipContent(GoalOperator.between, [dysGoalValues.max, dysGoalValues.min], `${unit} (dys)`)}
        style={{
          data: {
            stroke: Colors.Custom.dysGoalAreaBorderColor,
            strokeWidth: 0.5,
            strokeDasharray: 3,
            strokeLinecap: 'round'
          },
          labels: {fontSize: fontSize},
        }}
        labelComponent={<VictoryLabel x={100} />}
        y={() => dysGoalValues.min}
      />,
    ]
  )
}

  export default BloodPressureGraphView;