import React, { useContext, useRef, useState } from 'react';
import { Card, Typography, Modal as AntModal } from 'antd';
import ProviderSelectionView from './InsightsReferralProviderSection';
import { HStack, View, Button, Text as NativeText } from 'native-base';
import { Colors } from '../../../styles';
import { Pressable } from 'react-native';
import ShieldCheckSvg from '../../common/Svg/ShieldCheckSvg';
import { showToast, ToastType } from '../../../utils/commonViewUtils';
import InsightsReferralPreviewSection from './InsightsReferralPreviewSection';
import { useToast as useCustomToast } from '../../Toast/ToastProvider';
import InsightsCreateReferralForm from './InsightsCreateReferralForm';
import { getAccountUUID } from '../../../utils/commonUtils';
import BaseService from '../../../services/CommonService/BaseService';
import PenSvg from '../../common/Svg/PenSvg';
import { ComponentToPrint } from '../../common/PrintComponent/ComponentToPrint';
import DownloadSvg from '../../common/Svg/DownloadSvg';
import { useReactToPrint } from 'react-to-print';
import { InsightsCreateReferralProps, IReferralState, Provider } from '../../RightSideContainer/TeamInbox/Conversations/ConversationChannelTabs/CreateEFaxConversationDrawer/interface';
import DocumentsForSideCar from '../../PersonOmniView/MiddleContainer/Documents/DocumentsForSideCar';
import { EFAX_SOURCE } from '../../RightSideContainer/TeamInbox/Conversations/ConversationChannelTabs/ConversationChannelTabConst';
import { useIntl } from 'react-intl';
import { SIDECAR_REFERRAL_MANAGEMENT } from '../constants';
import { CommonDataContext } from '../../../context/CommonDataContext';
import ArrowLeft2024 from '../../common/Svg/SideMenu/ArrowLeft2024';

const { Text } = Typography;

const InsightsCreateReferral = (props: InsightsCreateReferralProps) => {
  const { contactData, formattedContactData, onBack, onComplete, preview = false, note = '', receiverDetails = null, documents = [], isEdit = false, setCreateReferralFormVisible, details, inboxId = null } = props
  const customToast = useCustomToast();
  const [state, setState] = useState<IReferralState>({
    selectedProvider: receiverDetails,
    isSelectingProvider: false,
    isPreview: preview,
    isPreviewModalVisible: false,
    isBackModalVisible: false,
    selectedDocuments: documents || [],
    additionalNote: note,
    editReferral: isEdit || false,
    loadingDraft: false,
    loadingRefer: false,
    selectedSender: null,
    selectedInbox: inboxId,
    isSelectingDocument: false,
    reasonForReferral: ''
  });

  const accountUUID = getAccountUUID();
  const printRef = useRef(null);
  const intl = useIntl();
  const commonData = useContext(CommonDataContext);
  const isSidecarContext = commonData?.sidecarContext?.isSidecar;

  const { isSelectingProvider, isPreview, selectedProvider, isPreviewModalVisible, isBackModalVisible, selectedDocuments, additionalNote, editReferral, loadingDraft, loadingRefer, selectedInbox, isSelectingDocument, reasonForReferral } = state;

  const updateState = (updates: any) => setState((prev: any) => ({ ...prev, ...updates }));

  const handleProviderSelect = (provider: Provider) => updateState({ selectedProvider: provider });

  const signAndRefer = async (status: string) => {
    const attachmentUuids = selectedDocuments?.flatMap((doc: any) =>
      doc?.documentAttachments?.map((attachment: any) => attachment?.attachment?.id)
    );
    const requestData = {
      ...(details?.id && { id: details.id }),
      receiverUuid: selectedProvider?.uuid,
      contactUuid: formattedContactData?.contactUUID,
      status,
      summary: additionalNote,
      attachmentUuids,
      efaxNumber: String(selectedInbox)
    };
    const dataa = {
      subject: reasonForReferral,
      message: additionalNote,
      attachmentIds: attachmentUuids,
      contactContextUuid: formattedContactData?.contactUUID,
      contactProviderUuid: selectedProvider?.uuid,
      inboxId: +selectedInbox,
      attachmentSource: EFAX_SOURCE.PATIENT_PROFILE,
    };

    const baseService = BaseService.getSharedInstance().axios;
    const headers = { accountId: accountUUID };
    try {
      if (status === SIDECAR_REFERRAL_MANAGEMENT.DRAFT) {
        await baseService.post('/crm-nest/referral', requestData, { headers });
      } else {
        const efaxResponse: any = await baseService.post('crm-nest/api/v1/efax/send', dataa);
        if (efaxResponse?.messageUuid) requestData.messageUuid = efaxResponse?.messageUuid;
        await baseService.post('/crm-nest/referral', requestData, { headers });
      }
      showToast(customToast, status === SIDECAR_REFERRAL_MANAGEMENT.DRAFT ? intl.formatMessage({ id: 'draftSaved' }) : intl.formatMessage({ id: 'patientReferred' }), ToastType.success, 1000, true);
      setCreateReferralFormVisible && setCreateReferralFormVisible();
      updateState({ isPreview: false, selectedProvider: null, editReferral: false, loadingDraft: false, loadingRefer: false });
      onBack();
    } catch (error) {
      showToast(customToast, intl.formatMessage({ id: 'errorMsg' }), ToastType.error, 1000, true);
      console.error('Error:', error);
      updateState({ loadingDraft: false, loadingRefer: false });
    }
  };

  const handleSignAndRefer = () => {
    updateState({ isPreviewModalVisible: false, loadingRefer: true });
    signAndRefer(SIDECAR_REFERRAL_MANAGEMENT.SENT);
    onComplete && onComplete()
  };

  const saveAsDraft = () => {
    try {
      updateState({ loadingDraft: true })
      signAndRefer(SIDECAR_REFERRAL_MANAGEMENT.DRAFT);
    } catch (err) {
      console.log(err);
    }
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: intl.formatMessage({ id: 'referralOrder' }),
  });

  return (
    <Card
      title={
        (
          <HStack justifyContent='space-between' alignItems='center'>
            <HStack space={5} alignItems='center'>
              <Pressable onPress={() => {
                if (isPreview && props?.preview) {
                  onBack()
                  setCreateReferralFormVisible && setCreateReferralFormVisible()
                  updateState({ isPreview: false, selectedProvider: null, editReferral: false });
                } else if (isPreview) {
                  updateState({ isPreview: false });
                }
                else if (isSelectingProvider) {
                  updateState({ isSelectingProvider: false, editReferral: false, isSelectingDocument: false, });
                }
                else if (isSelectingDocument) {
                  updateState({ isSelectingDocument: false, editReferral: false });
                } else {
                  updateState({ isBackModalVisible: true, editReferral: false, isSelectingDocument: false, });
                }
              }}>
               <ArrowLeft2024 color={Colors.FoldPixel.GRAY300}/>
              </Pressable>
              <NativeText fontSize={'14px'} fontWeight={'500'} color={Colors.FoldPixel.GRAY400}>{isPreview ? intl.formatMessage({ id: 'preview' }) : isSelectingProvider ? SIDECAR_REFERRAL_MANAGEMENT.ADD_PROVIDER : isSelectingDocument ? intl.formatMessage({ id: 'addAttachment' }) : intl.formatMessage({ id: 'referralOrder' })}</NativeText>
            </HStack>

            <HStack space={2}>
              {
                editReferral ?
                  <>
                    <Pressable onPress={() => handlePrint()}>
                      <DownloadSvg
                        customColor={
                          Colors.Custom.GRAY350
                        }
                        customStrokeWidth='1'
                      />
                    </Pressable>
                    <div style={{ display: 'none' }}>
                      <ComponentToPrint footer={''} ref={printRef}>
                        <InsightsReferralPreviewSection
                          contactData={contactData}
                          note={additionalNote}
                          provider={selectedProvider}
                        />
                      </ComponentToPrint>
                    </div>
                    {details.status == SIDECAR_REFERRAL_MANAGEMENT.DRAFT &&
                      <Pressable onPress={() => updateState({ editReferral: false, isPreview: false })}>
                        <PenSvg customStrokeColor={Colors.Custom.GRAY350} size={18} />
                      </Pressable>}
                  </>
                  :
                  isSelectingProvider ? (
                    <Button
                    colorScheme='custom'
                    backgroundColor={Colors.FoldPixel.PRIMARY100}
                    borderColor={Colors.Custom.Primary200}
                    borderWidth={0.5}
                    borderRadius={4}
                    _text={{ color: Colors.Custom.Primary300, fontWeight: '500', fontSize: 14 }}
                    _hover={{
                      backgroundColor: Colors.Custom.Primary300,
                      _text: { color: Colors.Custom.Primary50 }
                    }}
                      isDisabled={!selectedProvider}
                      onPress={() => updateState({ isSelectingProvider: false })}
                    >
                     {intl.formatMessage({ id: 'done' })}
                    </Button>
                  ) :
                    isSelectingDocument ?
                      (
                        <HStack alignItems={'center'} space={2}>
                          {selectedDocuments?.length > 0 && (
                            <>
                              <Text
                                style={{
                                  fontSize: 10,
                                  borderRadius: 5,
                                  borderWidth: 1,
                                  borderColor: Colors.Custom.shadeGray,
                                  color: Colors.FoldPixel.GRAY250,
                                  backgroundColor: Colors.FoldPixel.GRAY50,
                                  padding: '0px 4px',
                                  width: 14,
                                  height: 14,
                                  textAlign: 'center',
                                }}
                              >
                                {selectedDocuments?.length}
                              </Text>
                              <Text style={{ fontSize: 12, color: Colors.FoldPixel.GRAY250 }}> Files Selected</Text>
                            </>
                          )}
                          <Button
                            isDisabled={!selectedDocuments?.length}
                            onPress={() => updateState({ isSelectingDocument: false })}
                            colorScheme='custom'
                            backgroundColor={Colors.FoldPixel.PRIMARY100}
                            borderColor={Colors.Custom.Primary300}
                            borderWidth={0.5}
                            borderRadius={4}
                            _text={{ color: Colors.Custom.Primary300, fontWeight: '500', fontSize: 14 }}
                            _hover={{
                              backgroundColor: Colors.Custom.Primary300,
                              _text: { color: Colors.Custom.Primary50 }
                            }}
                          >
                            {intl.formatMessage({ id: 'done' })}
                          </Button>
                        </HStack>
                      )
                      : (
                        <>
                          <Button
                            variant='outline'
                            _text={{ color: Colors.FoldPixel.GRAY300, fontWeight: '500', fontSize: 14 }}
                            backgroundColor={Colors.Custom.MonochromeWhite}
                            borderColor={Colors.FoldPixel.GRAY250}
                            borderWidth={0.5}
                            _hover={{ backgroundColor: Colors.FoldPixel.GRAY50 }}
                            onPress={saveAsDraft}
                            isLoading={loadingDraft}
                            isDisabled={!selectedProvider && !selectedDocuments?.length}
                          >
                            {intl.formatMessage({ id: 'saveAsDraft' })}
                          </Button>
                          <Button
                            colorScheme='custom'
                            backgroundColor={Colors.FoldPixel.PRIMARY100}
                            borderColor={Colors.Custom.Primary200}
                            borderWidth={0.5}
                            borderRadius={4}
                            _text={{ color: Colors.Custom.Primary300, fontWeight: '500', fontSize: 14 }}
                            _hover={{
                              backgroundColor: Colors.Custom.Primary300,
                              _text: { color: Colors.Custom.Primary50 }
                            }}
                            onPress={() => updateState({ isPreviewModalVisible: true })}
                            isLoading={loadingRefer}
                            isDisabled={!selectedDocuments?.length || !selectedProvider}
                          >
                             {intl.formatMessage({ id: 'signNRefer' })}
                          </Button>
                        </>
                      )}
            </HStack>
          </HStack>
        )
      }
      style={{...(!isSidecarContext ? { height: 'auto', minHeight:'100%' } : { height: '100%'}), boxShadow: 'none', border: 'none', }}
    >
      {
        isPreview ? <InsightsReferralPreviewSection contactData={contactData} provider={selectedProvider} note={additionalNote} details={details} />
          :
          isSelectingProvider ? <ProviderSelectionView onSelect={handleProviderSelect} />
            :
            isSelectingDocument ?
              <DocumentsForSideCar
                contactUuid={formattedContactData?.contactUUID}
                contactName={formattedContactData?.name}
                contactId={formattedContactData?.person?.contactId}
                onDocumentsSelected={(docs: any) => {
                  const isSelected = selectedDocuments.find((doc: any) => doc?.id === docs.id);
                  if (isSelected) {
                    const updatedDocuments = selectedDocuments.filter((doc: any) => doc?.id !== docs.id);
                    updateState({ selectedDocuments: updatedDocuments });
                  } else {
                    updateState({ selectedDocuments: [...selectedDocuments, docs] });
                  }
                }}
                selectedDocuments={selectedDocuments}
              />
              : <InsightsCreateReferralForm
                contactData={contactData}
                formattedContactData={formattedContactData}
                selectedProvider={selectedProvider}
                onChangeSelectedProvider={(val: Provider) => updateState({ selectedProvider: val })}
                selectedDocuments={selectedDocuments}
                onChangeDeleteDoc={(doc: any) => {
                  updateState({ selectedDocuments: selectedDocuments.filter((selectedDoc: any) => selectedDoc?.id !== doc.id) });
                }}
                onChangeInboxId={(val: any) => {
                  updateState({ selectedInbox: val });
                }}
                additionalNote={additionalNote}
                onChangeSelectedDocuments={(docs: any) => {
                  const isSelected = selectedDocuments.find((doc: any) => doc?.id == docs.id);
                  if (isSelected) {
                    const updatedDocuments = selectedDocuments.filter((doc: any) => doc?.id != docs.id);
                    updateState({ selectedDocuments: updatedDocuments });
                  } else {
                    updateState({ selectedDocuments: [...state.selectedDocuments, docs] });
                  }
                }}
                onAddAttachments={() => updateState({ isSelectingDocument: true })}
                selectedInbox={selectedInbox}
                onChangeAdditionalNote={(note: string) => updateState({ additionalNote: note })}
                onChangeisSelectingProvider={(val: boolean) => updateState({ isSelectingProvider: val })}
                onChangeReasonForReferral={(val: string) => updateState({ reasonForReferral: val })}
                reasonForReferral={reasonForReferral}
              />
      }
      <AntModal
        visible={isPreviewModalVisible}
        onCancel={() => updateState({ isPreviewModalVisible: false })}
        footer={null}
        centered
        bodyStyle={{ padding: '20px' }}
        width={320}
      >
        <View style={{ alignItems: 'center', marginBottom: 8 }}>
          <ShieldCheckSvg height={22} width={20} />
        </View>
        <View style={{ alignItems: 'center', marginBottom: 16 }}>
          <Text style={{ textAlign: 'center', fontWeight: 600 }}>
            {intl.formatMessage({ id: 'checkPreview' })}
          </Text>
        </View>
        <HStack space={4} alignItems={'center'} justifyContent={'center'}>
          <Button
            variant='outline'
            backgroundColor={Colors.Custom.MonochromeWhite}
            borderColor={Colors.FoldPixel.GRAY300}
            borderWidth={0.5} 
            _text={{
              color: Colors.FoldPixel.GRAY300,
              fontWeight: '500',
              fontSize: '14px'
            }}
            _hover={{ backgroundColor: Colors.FoldPixel.GRAY50 }}
            width={131}
            onPress={handleSignAndRefer}
          >
            {intl.formatMessage({ id: 'signNRefer' })}
          </Button>
          <Button
            colorScheme='custom'
            backgroundColor={Colors.FoldPixel.PRIMARY300}
            borderRadius={4}
            _text={{
              color: '#FFF',
              fontWeight: '500',
            }}
            _hover={{
              backgroundColor: Colors.FoldPixel.PRIMARY_HOVER,
            }}
            width={131}
            onPress={() => {
              updateState({ isPreviewModalVisible: false, isPreview: true })
            }}
          >
            {intl.formatMessage({ id: 'preview' })}
          </Button>
        </HStack>
      </AntModal>

      <AntModal
        visible={isBackModalVisible}
        onCancel={() => updateState({ isBackModalVisible: false })}
        footer={null}
        centered
        bodyStyle={{ padding: '20px' }}
        width={320}
      >
        <View style={{ alignItems: 'center', marginBottom: 8 }}>
          <ShieldCheckSvg height={22} width={20} />
        </View>
        <View style={{ alignItems: 'center', marginBottom: 16 }}>
          <Text style={{ fontSize: 16, textAlign: 'center', fontWeight: 600, marginBottom: 3, color: Colors.FoldPixel.GRAY400 }}>
            Are you sure ?
          </Text>
          <Text style={{ fontSize: 14, textAlign: 'center', marginBottom: 10, color: Colors.FoldPixel.GRAY250 }}>
            {intl.formatMessage({ id: 'leavePageWithoutSavingInfo' })}
          </Text>
        </View>
        <HStack space={2} alignItems={'center'} justifyContent={'center'}>
          <Button
            variant='outline'
            backgroundColor={Colors.Custom.MonochromeWhite}
            borderColor={Colors.FoldPixel.GRAY300}
            borderWidth={0.5} 
            _text={{
              color: Colors.FoldPixel.GRAY300,
              fontWeight: '500',
              fontSize: '14px'
            }}
            _hover={{ backgroundColor: Colors.FoldPixel.GRAY50 }}
            width={131}
            onPress={() => {
              setCreateReferralFormVisible && setCreateReferralFormVisible()
              updateState({ isBackModalVisible: false, selectedProvider: null, isPreview: false })
              onBack()

            }}
          >
            Discard
          </Button>
          <Button
            colorScheme='custom'
            backgroundColor={Colors.Custom.Primary300}
            borderRadius={4}
            _text={{
              color: '#FFF',
              fontWeight: '500',
            }}
            width={131}
            _hover={{
              backgroundColor: Colors.FoldPixel.PRIMARY_HOVER,
            }}
            onPress={() => {
              updateState({ isBackModalVisible: false })
              saveAsDraft()
              onComplete && onComplete()
            }}
          >
            {intl.formatMessage({ id: 'saveAsDraft' })}
          </Button>
        </HStack>
      </AntModal>
    </Card>
  );
};

export default InsightsCreateReferral;
