import {Image} from 'native-base';
import React from 'react';
import {isWeb} from '../../../utils/platformCheckUtils';
import { ICommonSvgProps } from './interfaces';
import Svg, { Path } from 'react-native-svg';

const ShareSvg = (props: ICommonSvgProps) => {
  const strokeColor = props.customStrokeColor || '#667085';
  return (
    <>
      <Svg
        width={props?.width || "24"}
        height={props?.height || "24"}
        fill="none"
        viewBox="0 0 24 25"
      >
        <Path
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M4 12.5v8a2 2 0 002 2h12a2 2 0 002-2v-8m-4-6l-4-4m0 0l-4 4m4-4v13"
        />
      </Svg>
    </>
  );
};

export default React.memo(ShareSvg);
