import React from 'react';
import {BlockAvatarWrapper} from '@foldhealth/easy-email-editor';
import {CustomBlocksType} from '../constants';
import FormSvg from '../../../../../../common/Svg/SideMenu/FormSvg';

const FormsBlockWrapper = () => {
  return (
    <BlockAvatarWrapper type={CustomBlocksType.FORMS_BLOCK}>
      <div
        style={{
          height: 96,
          width: 96,
          padding: '10px',
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          marginBottom: '20px',
          boxShadow:
            '#373f470a 0 0 0 1px,#373f4714 0 4px 4px,#373f4714 0 8px 24px',
          border: '2px solid transparent',
          borderRadius: '4px',
          marginRight: '15px',
        }}
      >
        <div
          style={{
            height: 20,
            width: 20,
          }}
        >
          <FormSvg defaultColor={'black'} />
        </div>
        <div
          style={{
            marginTop: 10,
          }}
        >
          <p
            style={{
              color: '#1d2129',
            }}
          >
            Forms
          </p>
        </div>
      </div>
    </BlockAvatarWrapper>
  );
};

export default FormsBlockWrapper;
