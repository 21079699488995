import React from 'react';
import {Path, Svg} from 'react-native-svg';

function UserIcon() {
  return (
    <Svg width="16" height="17" fill="none" viewBox="0 0 16 17">
      <Path
        stroke="#667085"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.6"
        d="M13.333 14.5v-1.333a2.667 2.667 0 00-2.666-2.667H5.333a2.667 2.667 0 00-2.666 2.667V14.5m8-9.333a2.667 2.667 0 11-5.334 0 2.667 2.667 0 015.334 0z"
      ></Path>
    </Svg>
  );
}

export default UserIcon;
