import React, { useContext } from 'react';
import {Drawer} from 'antd';
import {ModalActionTitle} from '../../common/ModalActionTitle/ModalActionTitle';
import CallTrail from './CallTrail';
import {Colors} from '../../../styles';
import {BUTTON_TYPE} from '../../../constants';
import {useIntl} from 'react-intl';
import {ICallTrailPopUpProps} from './interfaces';
import { CommonDataContext } from '../../../context/CommonDataContext';

const CallTrailPopUp = (props: ICallTrailPopUpProps) => {
  const intl = useIntl();
  const commonData = useContext(CommonDataContext);
  const isSideCarContext = commonData.sidecarContext?.isSidecar;
  return (
    <Drawer
      open={props.isOpen}
      onClose={props.onClose}
      width={isSideCarContext ? '100%' : '33vw'}
      title={
        <ModalActionTitle
          title={intl.formatMessage({id: 'callTrail'})}
          titleColor={''}
          buttonList={[
            {
              show: true,
              id: 1,
              btnText: 'close',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.SECONDARY,
              isTransBtn: false,
              onClick: () => {
                props.onClose();
              },
            },
          ]}
        />
      }
    >
      <CallTrail callId={props.callId} />
    </Drawer>
  );
};

export default CallTrailPopUp;
