import React from 'react';
import { Svg, Path } from 'react-native-svg';

const LoadMoreIconSvg = () => {
  return (
    <Svg
    style={{marginLeft: 5}}
    width="15"
    height="14"
    fill="none"
    viewBox="0 0 15 14"
    >

      <Path
        fill="#8C5AE2"
        d="M1.953 6.556h-.5.5zm0 1.11l-.352.356a.5.5 0 00.704 0l-.352-.355zm1.472-.756a.5.5 0 10-.704-.71l.704.71zm-2.24-.71a.5.5 0 10-.704.71l.705-.71zM11.91 3.93a.5.5 0 00.852-.524l-.852.524zm1.133 2.404l.351-.355a.5.5 0 00-.703 0l.352.355zm-1.476.756a.5.5 0 00.703.711l-.703-.711zm2.248.711a.5.5 0 00.703-.711l-.703.711zm-10.77 2.27a.5.5 0 00-.85.526l.85-.525zM7.553.5c-3.365 0-6.1 2.707-6.1 6.056h1c0-2.789 2.28-5.056 5.1-5.056v-1zm-6.1 6.056v1.11h1v-1.11h-1zm.852 1.466l1.12-1.112-.704-.71-1.12 1.112.704.71zm0-.71L1.185 6.2l-.704.71 1.12 1.11.704-.71zm10.457-3.907A6.105 6.105 0 007.552.5v1c1.847 0 3.463.972 4.358 2.429l.852-.524zM7.422 13.5c3.375 0 6.12-2.706 6.12-6.056h-1c0 2.787-2.287 5.056-5.12 5.056v1zm6.12-6.056v-1.11h-1v1.11h1zm-.85-1.466l-1.125 1.11.703.712 1.124-1.111-.703-.711zm0 .71L13.814 7.8l.703-.711-1.124-1.111-.703.71zM2.193 10.597A6.131 6.131 0 007.422 13.5v-1a5.131 5.131 0 01-4.377-2.43l-.85.526z"
      ></Path>
    </Svg>
  );
};


export default LoadMoreIconSvg;
