import React from 'react';
import './FHTooltip.css';

type TooltipPosition = 'top' | 'right' | 'bottom' | 'left';

interface TooltipProps {
  text: string;
  position?: TooltipPosition;
  children: React.ReactNode;
}

const FHTooltip: React.FC<TooltipProps> = ({children, position, text}) => {
  return (
    <div className="fh-tooltip-container">
      {children}
      <div className={`fh-tooltip-box fh-tooltip-${position || 'top'}`}>
        {text}
        <div className="fh-tooltip-arrow"></div>
      </div>
    </div>
  );
};

export default FHTooltip;
