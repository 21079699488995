import { v4 } from 'uuid';
import { INPUT_FIELD_TYPE } from '../../FlowComponent/StateNodes/NodeInputField';

export const phoneTreeAutoSaveInDraft = false;

export const getWorkflowDataFromNodeType = (args: {nodeType: string}): any => {
  const {nodeType} = args;

  const parentUuid = v4();
  const targetUuid = v4();
  const edgeUuid = v4();

  return {
    flowType: 'PATIENTS',
    description: '',
    workflowName: '',
    tagList: [],
    triggerInfo: {
      frequency: 'ON_NEED',
      day: '',
      time: '',
      date: '',
      triggerCondition: 'ForAllPatientWithFilter',
      triggerType: 'A_SPECIFIC_TIME',
    },
    graph: {
      nodes: [
        {
          width: 358,
          height: 223,
          id: parentUuid,
          type: 'MapSubflow',
          position: {
            x: 208.75,
            y: 20,
          },
          positionAbsolute: {
            x: 208.75,
            y: 20,
          },
          data: {
            nodeType: 'ForAllPatientWithFilter',
            isStartNode: true,
            metaData: {},
            isWorkFlowEditable: true,
            isViewOnly: false,
          },
          selected: false,
          dragging: false,
        },
        {
          width: 350,
          height: 177,
          id: targetUuid,
          type: 'Task',
          position: {
            x: 284,
            y: 316,
          },
          positionAbsolute: {
            x: 284,
            y: 316,
          },
          data: {
            nodeType: nodeType,
            metaData: {},
            isWorkFlowEditable: true,
            sourceHandle: 'out',
            node: nodeType,
            sourceId: parentUuid,
            isViewOnly: false,
          },
          selected: true,
          dragging: false,
        },
      ],
      edges: [
        {
          id: edgeUuid, // uuid generate
          type: 'bezier',
          source: parentUuid, // parent id
          sourceHandle: 'out',
          target: targetUuid, // target sms node id
          targetHandle: 'in',
        },
      ],
    },
    isSendSurveyForm: false,
  };
};

export const isOnboardingWorkflow = (data: any) => {
  const check = data?.triggerInfo?.triggerCondition == 'OnPatientRegister';
  return check;
};


export const getFormTemplateUuidList = (nodes: any[]) => {
  const uuids: string[] = [];
  nodes?.forEach((node: any) => {
    const metadata = node?.data?.metaData;
    if (metadata?.userInputFieldList?.length > 0) {
      const userInputFieldList = metadata?.userInputFieldList;
      userInputFieldList?.forEach((input: any) => {
        if ((input?.fieldType === INPUT_FIELD_TYPE.FORM_SEARCH) && input?.value?.id != null) {
          uuids.push(input?.value?.id);
        }
        else if (input?.fieldType === INPUT_FIELD_TYPE.FORMS_SEARCH && input?.value?.length > 0) {
          input?.value?.forEach((formInput: any) => {
            uuids.push(formInput?.id)
          })
        }
        else if (input?.fieldType === INPUT_FIELD_TYPE.GENERAL_TEMPLATE_SEARCH && input?.value?.formList?.length > 0) {
          const formList = input?.value?.formList;
          formList.forEach((form: any) => {
            if (!(uuids?.includes(form?.id))) {
              uuids.push(form?.id)
            }
          })
        }
      });
    }
  });
  return uuids || []
}

export const updateNodeInformation = (
  responseList: any[],
  nodes: any,
  inputFieldType: string,
  mapTemplateAttributes: (input: any, template: any) => void
) => {
  if (responseList?.length > 0) {
    const templateIdMap: { [key: string]: any } = {};

    responseList.forEach((response: any) => {
      templateIdMap[response?.id] = response;
    });

    nodes?.forEach((node: any) => {
      const metadata = node?.data?.metaData;
      if (metadata?.userInputFieldList?.length > 0) {
        const userInputFieldList = metadata?.userInputFieldList;
        userInputFieldList?.forEach((input: any) => {
          if (input?.fieldType === inputFieldType) {
            const templateObject = templateIdMap[input?.value?.template?.id];
            if (templateObject) {
              mapTemplateAttributes(input, templateObject);
            }
          }
        });
      }
    });
  }
};

export const updateFormNames = async (nodes: any, formIdToNameMap: { [key: string]: string }) => {
  nodes?.forEach((node: any) => {
    const metadata = node?.data?.metaData;
    if (metadata?.userInputFieldList?.length > 0) {
      const userInputFieldList = metadata?.userInputFieldList;
      userInputFieldList?.forEach((input: any) => {
        if (input?.fieldType === INPUT_FIELD_TYPE.FORM_SEARCH && input?.value?.id != null) {
          input.value.name = formIdToNameMap[input?.value?.id] || input?.value?.name;
        }
        else if (input?.fieldType === INPUT_FIELD_TYPE.FORMS_SEARCH && input?.value?.length > 0) {
          input?.value?.forEach((formInput: any) => {
            formInput.name = formIdToNameMap[formInput?.id] || formInput?.name;
          })
        }
        else if (input?.fieldType === INPUT_FIELD_TYPE.GENERAL_TEMPLATE_SEARCH && input?.value?.formList?.length > 0) {
          input?.value?.formList?.forEach((form: any) => {
            form.name = formIdToNameMap[form?.id] || form?.name
          })
        }
      });
    }
  });
  return nodes;
};