import React from 'react';

const PamiIconSvg = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.16192 0.199951H3.34016C1.6088 0.199951 0.199997 1.60875 0.199997 3.33987V7.16163C0.199997 8.89299 1.6088 10.3016 3.34016 10.3016H9.42032C9.90704 10.3016 10.3018 9.90675 10.3018 9.42003V3.33987C10.3018 1.60875 8.89328 0.199951 7.16192 0.199951Z"
        fill="#98A2B3"
      />
      <path
        d="M18.6625 0.199951H14.8407C13.1094 0.199951 11.7006 1.60875 11.7006 3.33987V9.42003C11.7006 9.90699 12.0954 10.3016 12.5821 10.3016H18.6625C20.3938 10.3016 21.8024 8.89299 21.8024 7.16163V3.33987C21.8024 1.60875 20.3938 0.199951 18.6625 0.199951Z"
        fill="#98A2B3"
      />
      <path
        d="M9.42032 11.7005H3.34016C1.6088 11.7005 0.199997 13.1091 0.199997 14.8404V18.6622C0.199997 20.3936 1.6088 21.8021 3.34016 21.8021H7.16192C8.89328 21.8021 10.3018 20.3933 10.3018 18.6622V12.582C10.3018 12.0951 9.90704 11.7005 9.42032 11.7005Z"
        fill="#98A2B3"
      />
      <path
        d="M18.6625 11.7005H12.5821C12.0954 11.7005 11.7006 12.0953 11.7006 12.582V18.6622C11.7006 20.3936 13.1094 21.8021 14.8407 21.8021H18.6625C20.3938 21.8021 21.8024 20.3933 21.8024 18.6622V14.8404C21.8024 13.1091 20.3938 11.7005 18.6625 11.7005Z"
        fill="#98A2B3"
      />
    </svg>
  );
};

export default PamiIconSvg;
