import * as React from 'react';
import { IGlobalWarnings } from '../../../utils/interfaces';
export interface IGlobalWarningsContextData {
  globalWarningData: IGlobalWarnings;
};

export const defaultGlobalWarningsDataContext: IGlobalWarningsContextData = {
  globalWarningData: {} as IGlobalWarnings,
};

export const GlobalWarningsContext = React.createContext(
  defaultGlobalWarningsDataContext
);
