import { Column } from '@ant-design/plots';
import { View } from 'native-base';
// import '../../../styles/CSS/CommonBarGraph.css';


export function BarGraph(props: {
  data: Array<any>,
  xField: string,
  yField: string,
  seriesField: string,
  style?: any,
  label?: {
    type: string,
    content: string
  },
  onClick?: any
  color?:any
}) {


  interface DataItem {
    status: string;
    date: string;
    eventCode: string;
    createdOn: string;
    count: number;
    
  }
  let totalUniqueKey = 1;
  function mergeAndSumData(data: DataItem[]): DataItem[] {
    const mergedData: Record<string, DataItem> = {};
  
    data.forEach((item) => {
      const date = new Date(item.date);
      const options:any = { year: 'numeric', month: '2-digit', day: '2-digit' };
      const formattedDate = date.toLocaleDateString(undefined, options);
      const key = `${formattedDate}_${item.eventCode}`; // Combine date and eventCode as the key
  
      if (mergedData[key]) {
        mergedData[key].count += item.count;
      } else {
        mergedData[key] = { ...item, date: formattedDate };
      }
    });
    totalUniqueKey = Object.keys(mergedData)?.length
    return Object.values(mergedData);
  }
  
  // Example data
  const inputData: DataItem[] = [
    // Your data items here
  ];
  
  const mergedData: DataItem[] = mergeAndSumData(props.data);
  

  const config:any = {
    data: mergedData,
    xField: props.xField,
    yField: props.yField,
    seriesField: 'eventCode',
   
    columnWidthRatio: mergedData?.length <= 5 ? .2: .7,
    scrollbar: {
      type: 'horizontal',
      categorySize: 100,
    },
    
    
    legend: {
      layout: 'horizontal',
      position: 'bottom',
      style:{margin:'20px'},
      itemName: {
        style: {
          fill: '#101828',
          font: 'Manrope'
        },
        

        formatter: (seriesField:string) => {
          let legendName = seriesField;
          mergedData.some((data:any)=>{
            if(seriesField == data?.eventCode){
              legendName = data?.status
            }
          })
          return legendName;
        },
      },
    },

    color: props?.color,
    isGroup: true,
    marginRatio:0.1,
    columnStyle: {
      radius: [10, 10, 0, 0],
    },
    onReady: (plot: any) => {
      plot.on('element:click', (args: any) => {
        if (props.onClick) {
          props.onClick(args?.data?.data)
        }
      });
    },
  };

  return <View width={'100%'}> <Column   {...config}></Column>  </View>
}
