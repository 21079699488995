import React from 'react';
import {FormError, getFormErrorAlertType} from '../CustomComponentHelper';
import {Alert, HStack, VStack, View, Text} from 'native-base';

const FormComponentError = (props: {error: FormError, customError?: string}) => {
  const {error, customError} = props;
  const status = getFormErrorAlertType(error);
  return (
    <View margin={2}>
      <Alert width="100%" status={status} variant="left-accent">
        <VStack space={2} flexShrink={1} width="100%">
          <HStack flexShrink={1} space={2} justifyContent="space-between">
            <HStack space={2} flexShrink={1}>
              <Alert.Icon marginTop="1" />
              <Text fontSize="md" color="coolGray.800">
                {customError || error}
              </Text>
            </HStack>
          </HStack>
        </VStack>
      </Alert>
    </View>
  );
};

export default FormComponentError;
