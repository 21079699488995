import React from "react";
export const getFileExtension = (url: string): {fileName: string; ext: string} => {
  const strList = url?.split('/');
  let ext = '';
  let fileNameStr = 'File';
  if (strList && strList.length) {
    const fileName = strList[strList.length - 1];
    if (fileName) {
      const stringList = fileName.split('.');
      fileNameStr = fileName;
      ext = stringList[1];
    }
  }
  return {
    fileName: fileNameStr,
    ext,
  };
};
