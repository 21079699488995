import { Colors } from "../../../styles";
 
export const styles = {
  container: {
    fontWeight: '400',
    fontSize: 18,
    color: '#667085',
  },
  buttonText: {
    fontSize: 16,
    fontWeight: '500',
    color: Colors.Custom.Primary300
  },
  noDataText: {
    fontSize: 16,
    fontWeight: '400',
    color: Colors.FoldPixel.GRAY300,
    textAlign: 'center',
    marginTop: 8,
  }
}