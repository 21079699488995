import {gql} from '@apollo/client';

export const GET_MDM_DATA = gql`
  query GetMDMData($type: String!) {
    mdm(where: {type: {_eq: $type}}) {
      id
      type
      data
    }
  }
`;

export const GET_MDM_LAB_DATA = gql`
  query GetMDMLabData($query: String!) {
    mdmLabs(
      where: {
        _or: [
          {loinc: {_ilike: $query}}
          {longCommonName: {_ilike: $query}}
          {shortName: {_ilike: $query}}
        ]
      }
      limit: 10
      offset: 0
    ) {
      longCommonName
      shortName
      loinc
    }
  }
`;

export const GET_LAB_TEST_WITH_LOINC_CODE = gql`
  query GetMDMLabDataWithLoincList($loincList: [String!]!) {
    mdmLabs(
      where: {
          loinc: {
            _in: $loincList
          }
      }
    ) {
      longCommonName
      shortName
      loinc
    }
  }
`;

export const CREATE_REASON_FOR_VISIT = gql`
  mutation createAccountReasonForVisit($data: accountReasonForVisit_insert_input!) {
    createAccountReasonForVisit(object: $data) {
      id
    }
  }
`;

export const GET_ALL_REASON_FOR_VISIT = gql`
 query getAllReasonForVisit {
  accountReasonForVisits(where: {isDeleted: {_eq: false} isAddedByPatient: {_eq: false}}) {
    reasonForVisit
  }
}
`;

export default {GET_LAB_TEST_WITH_LOINC_CODE, GET_MDM_DATA, GET_MDM_LAB_DATA, CREATE_REASON_FOR_VISIT, GET_ALL_REASON_FOR_VISIT};
