import { IAppointmentDetail } from "../../../../CalendarWidgetInterfaces";
import { AppointmentAction } from "../AppointmentBookingEnums";
import { IReccuringEventConfig } from "../hooks/useReccuringEvent/interface";

export interface IReccuringEventView {
  config: IReccuringEventConfig;
  isEnabled: boolean;
  onToggle: (value: boolean) => void;
  type: ReccuringEventType;
  allowedToEdit: boolean;
  allowedToToggle: boolean;
  editType?: AppointmentAction;
}

export enum ReccuringEventType {
  APPOINTMENT,
  BLOCKTIME,
}
