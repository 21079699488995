import React from "react";
import {Path, Svg} from "react-native-svg";

function UserAddSvg(props: {
  color?: string;
}) {
  return (
    <Svg
      width='16'
      height='16'
      fill='none'
      viewBox='0 0 16 16'
    >
      <Path
        fill={
          props?.color || '#6F7A90'
        }
        d='M12.501 9.78a.5.5 0 00-1 0h1zm-1 1.778a.5.5 0 001 0h-1zm-.389-1.389a.5.5 0 100 1v-1zm1.778 1a.5.5 0 000-1v1zm-2.722-7.166c0 1.196-.97 2.166-2.167 2.166v1a3.167 3.167 0 003.167-3.166h-1zM8.001 6.169a2.167 2.167 0 01-2.166-2.166h-1A3.167 3.167 0 008 7.169v-1zM5.835 4.003c0-1.197.97-2.167 2.166-2.167v-1a3.167 3.167 0 00-3.166 3.167h1zM8 1.836c1.197 0 2.167.97 2.167 2.167h1A3.167 3.167 0 008.001.836v1zm0 12.333c-1.316 0-2.28-.083-2.984-.226-.707-.143-1.12-.339-1.367-.54-.455-.371-.482-.862-.482-1.734h-1c0 .785-.027 1.794.85 2.51.42.341 1.008.584 1.8.745.796.16 1.833.245 3.183.245v-1zm-4.833-2.5c0-.567.414-1.183 1.307-1.685.878-.494 2.123-.815 3.526-.815v-1c-1.542 0-2.963.35-4.016.943-1.037.584-1.817 1.468-1.817 2.557h1zm4.833-2.5c.672 0 1.31.074 1.89.206l.221-.975a9.544 9.544 0 00-2.11-.23v1zm4.729 3.68c-.105.326-.33.64-.998.889-.702.261-1.851.431-3.73.431v1c1.911 0 3.206-.169 4.079-.494.908-.338 1.389-.857 1.6-1.52l-.951-.305zm1.438-2.18c0 1.197-.97 2.167-2.167 2.167v1a3.167 3.167 0 003.167-3.167h-1zm-2.167 2.167a2.167 2.167 0 01-2.166-2.167h-1A3.167 3.167 0 0012 13.836v-1zm-2.166-2.167c0-1.196.97-2.166 2.166-2.166v-1a3.167 3.167 0 00-3.166 3.166h1zM12 8.503c1.197 0 2.167.97 2.167 2.166h1a3.167 3.167 0 00-3.167-3.166v1zm-.5 1.277v1.778h1V9.78h-1zm-.389 1.39h1.778v-1h-1.778v1z'
      ></Path>
    </Svg>
  );
}

export default React.memo(UserAddSvg);