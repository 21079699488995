import { CARE_STUDIO_NEST_URL } from '../../../../../constants/Configs';
import BaseService from '../../../../../services/CommonService/BaseService';
import {IDocument} from '../../../../../services/Document/DocumentInterfaces';

export const FORM_FILE_S3_URL = {
    FILE_DETAIL_GET: `${CARE_STUDIO_NEST_URL}/attachment/#ATTACHMENT_ID`,
  };


export const getAttachmentURL = (attachmentId: string) => {
    const axios = BaseService.getSharedInstance().axios;
    const url =
      FORM_FILE_S3_URL.FILE_DETAIL_GET.replace('#ATTACHMENT_ID', attachmentId) +
      '?isPreview=true';
    return axios
      .get(url)
      .then((response: any) => {
        return response.data?.url;
      })
      .catch((err: any) => {
      });
  };

  export const checkDuplicateCaption = (careJourneyDocuments?: IDocument[],caption?: string)=> {
    const isDuplicateName = careJourneyDocuments?.some((document)=>{
      if (document?.caption === caption && !document?.isDeleted) {
        return document;
      }
    })
    return isDuplicateName;
  }
