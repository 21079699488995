import React from 'react';
import {ICommonSvgProps} from '../interfaces';
import {isWeb} from '../../../../utils/platformCheckUtils';
import {Image} from 'native-base';

const VoicemailSvg = (props: ICommonSvgProps) => {
  const height = props.height ?? '14';
  const width = props.width ?? '14';
  const stroke = props.customStrokeColor ?? '#667085';
  return isWeb() ? (
    <svg
      width={`${width}`}
      height={`${height}`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5 16C7.98528 16 10 13.9853 10 11.5C10 9.01472 7.98528 7 5.5 7C3.01472 7 1 9.01472 1 11.5C1 13.9853 3.01472 16 5.5 16ZM5.5 16H18.5M18.5 16C20.9853 16 23 13.9853 23 11.5C23 9.01472 20.9853 7 18.5 7C16.0147 7 14 9.01472 14 11.5C14 13.9853 16.0147 16 18.5 16Z"
        stroke={`${stroke}`}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ) : (
    <Image
      size={props.size}
      resizeMode="contain"
      source={require('../../../../assets/images/CallTrailPng/voicemail.png')}
      alt='image'
    />
  );
};

export default VoicemailSvg;
