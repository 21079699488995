import React from 'react';
import {Path, Svg} from 'react-native-svg';
import {Colors} from '../../styles';

export const StopIconSvg = () => {
  return (
    <Svg width="14" height="15" viewBox="0 0 15 16" fill="none">
      <Path
        d="M11.8335 3.66668L3.16684 12.3332M14.1668 8.00001C14.1668 11.6819 11.1821 14.6667 7.50016 14.6667C3.81826 14.6667 0.833496 11.6819 0.833496 8.00001C0.833496 4.31811 3.81826 1.33334 7.50016 1.33334C11.1821 1.33334 14.1668 4.31811 14.1668 8.00001Z"
        stroke={Colors.FoldPixel.STATUS_ERROR}
        stroke-linecap="round"
      />
    </Svg>
  );
};
