import {Box, Pressable, View} from 'native-base';
import React from 'react';
import {ICard} from './CardInterfaces';

const Card = (props: ICard) => {
  const extraProps = props.extraProps || {};
  return (
    <Pressable
      onPress={() => {
        if (props.onPress) {
          props.onPress();
        }
      }}
      accessible={false}
    >
      <View
        bgColor={props.bgColor}
        borderWidth="1"
        shadow="1"
        borderRadius={24}
        marginY={1}
        borderColor="#F5F1FE"
        pl={2}
        overflow={'hidden'}
      >
        <Box
          borderTopLeftRadius={8}
          borderBottomLeftRadius={8}
          borderRadius={20}
          {...extraProps}
          bg="white"
          paddingX={3}
          paddingY={5}
          overflow={'hidden'}
          {...extraProps}
        >
          {props.children}
        </Box>
      </View>
    </Pressable>
  );
};

export default Card;
