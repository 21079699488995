import {Image, Text, View} from 'native-base';
import React from 'react';
import {Platform} from 'react-native';
import {Colors} from '../../../styles';
import {DisplayText} from '../DisplayText/DisplayText';
import {svgStyles} from './SvgStyles';

const NoSupportingMemberSvg = (props: {
  titleId?: string;
  fillColor?: string;
}) => {
  const fillColor = props.fillColor || Colors.primary['300'];

  return (
    <View style={svgStyles.container}>
      {Platform.OS === 'web' ? (
        <svg
          width="76"
          height="73"
          viewBox="0 0 76 73"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.7842 67.2974H60.2148C61.436 67.3004 62.6132 66.8389 63.5104 66.0055C67.2532 62.5193 70.2476 58.2995 72.3092 53.6059C74.3708 48.9122 75.4561 43.8439 75.4984 38.7126C75.69 17.8479 58.831 0.680085 38.0905 0.630845C17.3382 0.581576 0.500025 17.4909 0.500025 38.3568C0.494099 43.5501 1.55704 48.6884 3.62192 53.4482C5.6868 58.208 8.70889 62.4861 12.4979 66.0132C13.3932 66.8427 14.567 67.3014 15.7842 67.2974Z"
            fill="#FCFDFD"
          />
          <path
            d="M23.7913 37.2248C23.0548 36.874 22.4107 36.3558 21.9102 35.7116C21.4098 35.0674 21.0669 34.315 20.9091 33.5147L20.437 31.121L17.2421 32.1474L14.0472 31.121L13.5752 33.5147C13.4174 34.315 13.0745 35.0674 12.5741 35.7116C12.0736 36.3558 11.4294 36.874 10.693 37.2248L6.64352 39.1538C5.9168 39.5 5.33113 40.0854 4.98465 40.812C4.63817 41.5386 4.55193 42.3622 4.74037 43.1448L4.75881 43.2213C5.01286 44.2866 5.55648 45.2609 6.32959 46.0365C7.1027 46.8122 8.0752 47.359 9.13963 47.6165C14.331 48.856 19.7086 48.8377 25.2724 47.5616C26.3548 47.3142 27.3468 46.7698 28.1367 45.9895C28.9266 45.2092 29.4832 44.2241 29.7439 43.1448C29.9324 42.3622 29.8461 41.5386 29.4996 40.812C29.1531 40.0854 28.5675 39.5 27.8408 39.1538L23.7913 37.2248Z"
            fill="#E4E7EC"
          />
          <path
            opacity="0.05"
            d="M23.7913 37.2248C23.0548 36.874 22.4107 36.3558 21.9102 35.7116C21.4098 35.0674 21.0669 34.315 20.9091 33.5147L20.437 31.121L17.2421 32.1474L14.0472 31.121L13.5752 33.5147C13.4174 34.315 13.0745 35.0674 12.5741 35.7116C12.0736 36.3558 11.4294 36.874 10.693 37.2248L6.64352 39.1538C5.9168 39.5 5.33113 40.0854 4.98465 40.812C4.63817 41.5386 4.55193 42.3622 4.74037 43.1448L4.75881 43.2213C5.01286 44.2866 5.55648 45.2609 6.32959 46.0365C7.1027 46.8122 8.0752 47.359 9.13963 47.6165C14.331 48.856 19.7086 48.8377 25.2724 47.5616C26.3548 47.3142 27.3468 46.7698 28.1367 45.9895C28.9266 45.2092 29.4832 44.2241 29.7439 43.1448C29.9324 42.3622 29.8461 41.5386 29.4996 40.812C29.1531 40.0854 28.5675 39.5 27.8408 39.1538L23.7913 37.2248Z"
            fill="black"
          />
          <path
            d="M20.4486 34.1844C19.5431 34.9226 18.4106 35.3257 17.2423 35.3257C16.0741 35.3257 14.9416 34.9226 14.036 34.1844C13.5276 33.77 13.1088 33.2565 12.805 32.6751C12.5012 32.0938 12.3188 31.4567 12.2689 30.8026L11.8903 25.8418C11.8341 25.1046 11.9307 24.3638 12.1742 23.6658C12.4176 22.9677 12.8027 22.3275 13.3051 21.7852C13.8076 21.2429 14.4167 20.8103 15.0942 20.5144C15.7717 20.2185 16.503 20.0658 17.2423 20.0658H17.2423C17.9816 20.0658 18.713 20.2185 19.3905 20.5144C20.068 20.8103 20.6771 21.2429 21.1795 21.7852C21.682 22.3275 22.0671 22.9677 22.3105 23.6658C22.554 24.3638 22.6506 25.1046 22.5944 25.8418L22.2158 30.8026C22.1659 31.4567 21.9835 32.0938 21.6797 32.6751C21.3759 33.2565 20.9571 33.77 20.4486 34.1844Z"
            fill="#E4E7EC"
          />
          <path
            d="M52.2086 37.2248C52.9451 36.874 53.5892 36.3558 54.0897 35.7116C54.5901 35.0674 54.933 34.315 55.0908 33.5147L55.5629 31.121L58.7578 32.1474L61.9527 31.121L62.4247 33.5147C62.5825 34.315 62.9254 35.0674 63.4258 35.7116C63.9263 36.3558 64.5705 36.874 65.3069 37.2248L69.3564 39.1538C70.0831 39.5 70.6688 40.0854 71.0152 40.812C71.3617 41.5386 71.448 42.3622 71.2595 43.1448L71.2411 43.2213C70.987 44.2866 70.4434 45.2609 69.6703 46.0365C68.8972 46.8122 67.9247 47.359 66.8603 47.6165C61.6689 48.856 56.2913 48.8377 50.7275 47.5616C49.6451 47.3142 48.6531 46.7698 47.8632 45.9895C47.0733 45.2092 46.5167 44.2241 46.256 43.1448C46.0676 42.3622 46.1538 41.5386 46.5003 40.812C46.8468 40.0854 47.4324 39.5 48.1591 39.1538L52.2086 37.2248Z"
            fill="#E4E7EC"
          />
          <path
            opacity="0.05"
            d="M52.2086 37.2248C52.9451 36.874 53.5892 36.3558 54.0897 35.7116C54.5901 35.0674 54.933 34.315 55.0908 33.5147L55.5629 31.121L58.7578 32.1474L61.9527 31.121L62.4247 33.5147C62.5825 34.315 62.9254 35.0674 63.4258 35.7116C63.9263 36.3558 64.5705 36.874 65.3069 37.2248L69.3564 39.1538C70.0831 39.5 70.6688 40.0854 71.0152 40.812C71.3617 41.5386 71.448 42.3622 71.2595 43.1448L71.2411 43.2213C70.987 44.2866 70.4434 45.2609 69.6703 46.0365C68.8972 46.8122 67.9247 47.359 66.8603 47.6165C61.6689 48.856 56.2913 48.8377 50.7275 47.5616C49.6451 47.3142 48.6531 46.7698 47.8632 45.9895C47.0733 45.2092 46.5167 44.2241 46.256 43.1448C46.0676 42.3622 46.1538 41.5386 46.5003 40.812C46.8468 40.0854 47.4324 39.5 48.1591 39.1538L52.2086 37.2248Z"
            fill="black"
          />
          <path
            d="M55.5514 34.1844C56.457 34.9226 57.5894 35.3257 58.7577 35.3257C59.926 35.3257 61.0585 34.9226 61.964 34.1844C62.4724 33.77 62.8913 33.2565 63.1951 32.6751C63.4989 32.0938 63.6812 31.4567 63.7312 30.8026L64.1097 25.8418C64.166 25.1046 64.0694 24.3638 63.8259 23.6658C63.5824 22.9677 63.1974 22.3275 62.6949 21.7852C62.1924 21.2429 61.5834 20.8103 60.9059 20.5144C60.2283 20.2185 59.497 20.0658 58.7577 20.0658H58.7577C58.0184 20.0658 57.2871 20.2185 56.6096 20.5144C55.9321 20.8103 55.323 21.2429 54.8205 21.7852C54.318 22.3275 53.933 22.9677 53.6895 23.6658C53.4461 24.3638 53.3494 25.1046 53.4057 25.8418L53.7843 30.8026C53.8342 31.4567 54.0166 32.0938 54.3204 32.6751C54.6242 33.2565 55.043 33.77 55.5514 34.1844Z"
            fill="#E4E7EC"
          />
          <path
            d="M51.0313 41.6191C49.5659 40.9211 48.2841 39.89 47.2884 38.6082C46.2926 37.3264 45.6104 35.8294 45.2964 34.237L44.3571 29.474L38 31.5164L31.6429 29.474L30.7037 34.237C30.3896 35.8294 29.7075 37.3264 28.7117 38.6082C27.7159 39.89 26.4341 40.9211 24.9688 41.6191L16.9113 45.4574C15.4653 46.1463 14.3 47.3111 13.6106 48.7569C12.9212 50.2026 12.7496 51.8413 13.1245 53.3985L13.1612 53.5508C13.6667 55.6704 14.7484 57.609 16.2867 59.1524C17.825 60.6957 19.76 61.7838 21.878 62.2962C32.2076 64.7624 42.9077 64.726 53.9783 62.1869C56.1321 61.6947 58.1058 60.6114 59.6776 59.0588C61.2493 57.5063 62.3568 55.546 62.8755 53.3985V53.3985C63.2505 51.8413 63.0789 50.2026 62.3895 48.7569C61.7001 47.3111 60.5347 46.1463 59.0887 45.4574L51.0313 41.6191Z"
            fill="#E4E7EC"
          />
          <path
            d="M44.3946 35.6663H44.3946C42.5887 37.1384 40.3301 37.9423 38.0002 37.9423C35.6703 37.9423 33.4117 37.1384 31.6058 35.6663H31.6058C30.4023 34.6852 29.409 33.4715 28.6854 32.0978C27.9617 30.7241 27.5226 29.2185 27.3942 27.6712L26.7447 19.8452C26.6156 18.2886 26.8107 16.7222 27.3177 15.2449C27.8247 13.7676 28.6326 12.4116 29.6904 11.2624C30.7481 10.1132 32.0328 9.19591 33.4631 8.56847C34.8934 7.94102 36.4383 7.61707 38.0002 7.61707H38.0002C39.5621 7.61707 41.107 7.94102 42.5373 8.56847C43.9676 9.19591 45.2522 10.1132 46.31 11.2624C47.3678 12.4116 48.1757 13.7676 48.6827 15.2449C49.1897 16.7222 49.3848 18.2886 49.2556 19.8452L48.6062 27.6712C48.4778 29.2185 48.0387 30.7241 47.315 32.0978C46.5914 33.4715 45.5981 34.6852 44.3946 35.6663Z"
            fill="#F2F4F7"
          />
          <path
            d="M37.9999 72.4118C42.794 72.4118 46.6804 68.5254 46.6804 63.7312C46.6804 58.9371 42.794 55.0507 37.9999 55.0507C33.2058 55.0507 29.3193 58.9371 29.3193 63.7312C29.3193 68.5254 33.2058 72.4118 37.9999 72.4118Z"
            fill={fillColor}
          />
        </svg>
      ) : (
        <Image
          w="100%"
          // style={{aspectRatio: 16 / 9}}
          resizeMode="contain"
          source={require('../../../assets/images/EmptyStatePng/noMembers.png')}
          alt="image"
        ></Image>
      )}
      {props.titleId && (
        <Text style={svgStyles.svgText}>
          <DisplayText textLocalId={props.titleId} textType="Text" />
        </Text>
      )}
    </View>
  );
};

export default NoSupportingMemberSvg;
