import {View, Text} from 'react-native';
import React from 'react';
import {Popover} from 'antd';
import {useIntl} from 'react-intl';
import SettingsIconSvg from '../../../../../common/Svg/SettingsIconSvg';
import {Colors} from '../../../../../../styles';
import Stack from '../../../../../common/LayoutComponents/Stack';
import {Pressable} from 'native-base';
import MapIcon from '../../../../../../assets/Icons/MapIcon';
import {CaretDownOutlined} from '@ant-design/icons';
import Feather from 'react-native-vector-icons/Feather';
import { reactStyles, styles } from '../../../Analytics/style';

const ConfigurationPopover = (props: {children: React.ReactNode, error?: boolean}) => {
  const intl = useIntl();
  const [open, setOpen] = React.useState(false);
  return (
    <Popover
      content={<View style={styles.viewStyle4}>{props?.children}</View>}
      trigger="click"
      overlayInnerStyle={{padding: -8, borderRadius: 16}}
      overlayStyle={{padding: 0}}
      overlay={{padding: 0}}
      style={reactStyles.popoverStyle}
      open={open}
      onOpenChange={(open) => {
        setOpen(open);
      }}
    >
      <Pressable
        onPress={() => setOpen(true)}
        flexDir="row"
        ml={2}
        alignItems="center"
        borderWidth={1}
        p={2}
        borderRadius={4}
        borderColor={props?.error ? Colors.Custom.ErrorColor : Colors.Custom.Gray300}
        style={styles.height35}
      >
        <Text
        >
          {intl.formatMessage({id: 'moreDetails'})}
          <Feather name="chevron-down" size={12} style={styles.marginLeft2} color={Colors.Custom.Gray400} />
        </Text>
      </Pressable>
    </Popover>
  );
};

export default ConfigurationPopover;
