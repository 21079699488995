import {getParentIdx, IBlockData} from '@foldhealth/easy-email-core';
import {useBlock, useFocusIdx} from '@foldhealth/easy-email-editor';
import {get} from 'lodash';
import {
  Box,
  FormControl,
  HStack,
  Text,
  TextArea,
  View,
  VStack,
} from 'native-base';
import {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Dimensions} from 'react-native';
import {BUTTON_TYPE} from '../../../../constants';
import {getSuggestions} from '../../../../services/Billing/BillingServices';
import {DisplayText} from '../../../common/DisplayText/DisplayText';
import LoadingSpinner from '../../../common/Loader/LoadingSpinner';
import ModalActionBtn from '../../../common/ModalActionBtn/ModalActionBtn';
import {FoldButton} from '../../../CommonComponents/FoldButton/FoldButton';
import {CustomBlocksType} from '../EmailTemplates/CreateOrEditTemplate/CustomBlocks/constants';
import {IGpt3} from '../EmailTemplates/CreateOrEditTemplate/CustomBlocks/OpenAiSuggestions';
import ViewOpenaiSuggestionsList from './ViewOpenaiSuggestionsList';
const {
  Label,
  // ErrorMessage
} = FormControl;

const ViewOpenaiSuggestions = (props: {template?: any; onModalClose?: any}) => {
  const [template, setTemplate] = useState<any>(null);
  const [showLoader, setShowLoader] = useState(false);
  const [suggestedContent, setSuggestedContent] = useState<any>([]);
  const [tSuggestion, setTSuggestion] = useState<any>(null);
  const {addBlock, moveBlock, values} = useBlock();
  const {focusIdx} = useFocusIdx();
  const intl = useIntl();
  const [suggestions, setSuggestions] = useState({
    suggestedContent: [] as any,
    selectedContent: [] as any,
  });

  const getSuggestionsResp = async (suggestion: string) => {
    setShowLoader(true);
    setSuggestions({
      suggestedContent: [] as any,
      selectedContent: [] as any,
    });
    setSuggestedContent([]);
    const suggs = [];
    const suggestionsResp = await getSuggestions(suggestion);
    if (suggestionsResp) {
      const result = suggestionsResp.data?.result;

      if (result && result.length > 0) {
        for (const choice of result) {
          const sugg = {
            title: suggestion,
            description: choice?.text,
          };
          suggs.push(sugg);
        }
      }
    }
    setSuggestedContent(suggs);
    setShowLoader(false);
  };

  useEffect(() => {
    setTemplate(props?.template);
  }, [props]);
  const {height} = Dimensions.get('window');
  const finalHeight = height - 380;
  return (
    <VStack>
      <Text style={{marginLeft: 5, marginBottom: 10}} color="gray.500">
        {'You can add the below suggested content to the email.'}
      </Text>

      <View style={{flex: 1}}>
        <Box alignItems="center">
          <FormControl>
            <HStack
              style={{
                justifyContent: 'flex-start',
              }}
            >
              <Label marginTop={2}>
                <DisplayText textLocalId="contentSuggestions" />
              </Label>
              <TextArea
                defaultValue={template?.templateData?.subject}
                onBlur={(event: any) => {
                  setTSuggestion(event?.target?.value);
                }}
              />
              <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.PRIMARY,
                  onPress: () => {
                    if (tSuggestion) {
                      getSuggestionsResp(tSuggestion);
                    } else {
                      getSuggestionsResp(template?.templateData?.subject);
                    }
                  },
                  style: {margin: 16},
                }}
                customProps={{
                  btnText: intl.formatMessage({
                    id: 'viewSuggestions',
                  }),
                  withRightBorder: false,
                }}
              ></FoldButton>
            </HStack>
          </FormControl>
        </Box>
        {suggestedContent?.map((content: any, index: any) => {
          return (
            <ViewOpenaiSuggestionsList
              html={content}
              index={index}
              suggestions={suggestions}
              onModalClose={props?.onModalClose}
            />
          );
        })}

        <FoldButton
          nativeProps={{
            variant: BUTTON_TYPE.PRIMARY,
            onPress: () => {
              let parentIdx = getParentIdx(focusIdx);
              const self = get(values, focusIdx) as IBlockData | null;

              if (focusIdx === 'content') {
                parentIdx = 'content';
              }

              if (parentIdx) {
                const payload: IGpt3 = {
                  type: CustomBlocksType.OPENAI_SUGGESTIONS,
                  data: {
                    value: {
                      suggestions: suggestions.selectedContent,
                    },
                  },
                  attributes: {
                    'background-color': '#ffffff',
                    'button-text-color': '#ffffff',
                    'button-color': '#414141',
                    'title-color': '#222222',
                    'description-color': '#222222',
                  },
                  children: [],
                };
                if (self?.type != CustomBlocksType.OPENAI_SUGGESTIONS) {
                  addBlock({
                    type: CustomBlocksType.OPENAI_SUGGESTIONS,
                    parentIdx: parentIdx,
                    positionIndex: undefined,
                    payload: payload,
                  });
                } else {
                  addBlock({
                    type: CustomBlocksType.OPENAI_SUGGESTIONS,
                    parentIdx: parentIdx,
                    positionIndex: undefined,
                    payload: payload,
                    canReplace: true,
                  });
                }
              }
              setSuggestedContent([]);
              props?.onModalClose();
            },
            style: {margin: 16},
          }}
          customProps={{
            btnText: intl.formatMessage({
              id: 'submit',
            }),
            withRightBorder: false,
          }}
        ></FoldButton>
        {showLoader && <LoadingSpinner />}
      </View>
    </VStack>
  );
};

export default ViewOpenaiSuggestions;
