import React from "react";
import { ICommonSvgProps } from "./interfaces";
import { isWeb } from "../../../utils/platformCheckUtils";
import {Image} from 'native-base';

const DialpadSvg = (props: ICommonSvgProps) =>{
    const {customStrokeColor,width,height, size} = props;
    return (
      <>
        {isWeb() ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width ? width : '18'}
            height={height ? height : '18'}
            fill="none"
            viewBox="0 0 18 18"
          >
            <path
              stroke={customStrokeColor ? customStrokeColor : '#825AC7'}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M9 15a.75.75 0 100-1.5.75.75 0 000 1.5zM14.25 15a.75.75 0 100-1.5.75.75 0 000 1.5zM3.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zM9 9.75a.75.75 0 100-1.5.75.75 0 000 1.5zM14.25 9.75a.75.75 0 100-1.5.75.75 0 000 1.5zM3.75 9.75a.75.75 0 100-1.5.75.75 0 000 1.5zM9 4.5A.75.75 0 109 3a.75.75 0 000 1.5zM14.25 4.5a.75.75 0 100-1.5.75.75 0 000 1.5zM3.75 4.5a.75.75 0 100-1.5.75.75 0 000 1.5z"
            ></path>
          </svg>
        ) : (
          <Image
            size={size ? size : '7'}
            source={require('../../../assets/images/Dialpad.png')}
            alt="aa"
          />
        )}
      </>
    );
};

export default DialpadSvg
