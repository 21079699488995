import { Extension } from 'fhir/r4';
import {EncounterApiResponse, IEncounter} from '../interfaces';
import {EncounterNotesApiResponse, IEncounterNote} from './interfaces';
import { getExtensionByUrl } from '../../../LeftContainer/RecentActivity/RecentReport/ReportUtils';
import { EXTENSION_URLS } from '../components/AddOrUpdateTemplate/constant';

export default {};

export const getFormattedEncounters = (
  encountersApiResponse: EncounterApiResponse
): IEncounter[] => {
  const result: IEncounter[] = [];
  const entry = encountersApiResponse.entry;
  entry.forEach((element) => {
    const resource = element.resource;
    result.push({
      id: resource?.id,
      appointmentId: resource?.appointment[0]?.reference,
      date: resource?.period?.start,
      locationId: resource?.location[0]?.location?.reference,
      status: resource?.status,
      type: resource?.type[0]?.coding[0]?.display,
    });
  });

  return result;
};

export const getEncounterNoteData = (data: EncounterNotesApiResponse) => {
  const {id, category, status, docStatus, type, context, content,extension} = data;
  const visitName: Extension = getExtensionByUrl(
    extension || [],
    EXTENSION_URLS.visitName
  );
  const providerFirstName: Extension = getExtensionByUrl(
    extension || [],
    EXTENSION_URLS.providerFirstName
  );
  const providerLastName: Extension = getExtensionByUrl(
    extension || [],
    EXTENSION_URLS.providerLastName
  );
  const providerName = `${
    providerFirstName?.valueString ? providerFirstName?.valueString : ''
  } ${providerLastName?.valueString ? providerLastName?.valueString : ''}`;

  const result: IEncounterNote = {
    id: id,
    status: status,
    docStatus: docStatus,
    type: type?.coding[0]?.display,
    category: category[0]?.text,
    date: context?.period?.start,
    html: content[0]?.attachment?.data,
    title: content[0]?.attachment?.title,
    visitName: visitName?.valueString,
    providerName
  };

  return result;
};

export const ATHENA_VISIT_HISTORY_CSS = `
<style>
.clinicalsummary {
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
}
table {
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  border-collapse: collapse;
  text-align: left;
}
td .activeallergy {
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
}
.reviewedmessage {
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
}
#emptysummary {
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  color: gray;
}
div#SUMMARYHEADER {
  color: var(--header-default-text, #000);
  border-bottom: 1px solid black;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bolder;
  font-style: normal;
}
div#SUMMARYCONTENT {
  margin-bottom: 20px;
}
th, td {
  display: block;
}
th {
  text-align: start;
  color: #202025;
  line-height: 150%;
  font-size: 20px;
  font-weight: bold;
}
strong {
  font-size: 20px;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}
.medicationlistheader {
  display: none;
}
.hideforprintfax {
  display: none;
}
.medicationrow .indented {
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
}
.medicationrow .medicationdetails {
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
}
.medicationrow strong {
  display: none;
}

.reskindatatable .breakrow {
  display: none;
}
.medicationrow .indented {
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
}
.medicationrow .medicationdetails {
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
}
.vaccinerowheading {
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
}
.vaccinerowheading .vaccinerow {
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
}
.vaccinerow.alternaterow  {
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
}
.familyhxtable .paddedhang {
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  margin-left: 10px;
}
.familyhxtable {
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
}
.vaccinerow.alternaterow td {
  display: inline-block;
  padding: 5px; /* Optional: Add padding for demonstration */
}
</style>
`;

export const VISIT_HISTORY_PDF_MARGIN = { top: '10mm', right: '10mm', bottom: '10mm', left: '10mm' };