import React from 'react';
import {Path, Svg} from 'react-native-svg';

function LinkIcon() {
  return (
    <Svg
      id="hyperlink-icon"
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      >
      <Path
        d="M7.5 10.25C7.82209 10.6806 8.23301 11.0369 8.70491 11.2947C9.1768 11.5525 9.69863 11.7058 10.235 11.7442C10.7713 11.7826 11.3097 11.7053 11.8135 11.5173C12.3173 11.3294 12.7748 11.0353 13.155 10.655L15.405 8.40497C16.0881 7.69772 16.4661 6.75046 16.4575 5.76722C16.449 4.78398 16.0546 3.84344 15.3593 3.14815C14.664 2.45287 13.7235 2.05849 12.7403 2.04995C11.757 2.0414 10.8098 2.41938 10.1025 3.10247L8.8125 4.38497M10.5 8.74997C10.1779 8.31938 9.76698 7.96309 9.29508 7.70527C8.82319 7.44745 8.30137 7.29414 7.76501 7.25572C7.22865 7.21731 6.69031 7.2947 6.18649 7.48264C5.68267 7.67058 5.22516 7.96467 4.845 8.34497L2.595 10.595C1.9119 11.3022 1.53393 12.2495 1.54247 13.2327C1.55101 14.216 1.9454 15.1565 2.64068 15.8518C3.33596 16.5471 4.2765 16.9415 5.25974 16.95C6.24298 16.9585 7.19024 16.5806 7.8975 15.8975L9.18 14.615"
        stroke="#825AC7"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </Svg>
  );
}

export default LinkIcon;
