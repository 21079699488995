import React from 'react';
import {INewFeaturePopUpProps} from './interfaces';
import {Button, Popover} from 'antd';
import {Text, View} from 'native-base';
import {Colors} from '../../../styles';
import CancelButtonSvg from '../Svg/PersonActionSvgIcons/CancelButtonSvg';
import {
  getSingleUserAction,
  insertUserAction,
} from '../../RightSideContainer/Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import {getUserUUID} from '../../../utils/commonUtils';

const NewFeaturePopUp = (props: INewFeaturePopUpProps) => {
  const [state, setState] = React.useState({
    isOpen: false,
  });
  const userUuid = getUserUUID();
  const handleOnOpenChange = async () => {
    setState((prev) => {
      return {
        ...prev,
        isOpen: false,
      };
    });
    await insertUserAction({
      actionCode: props.code,
      level: 'NEW_FEATURE',
      status: 'COMPLETED',
      userId: userUuid,
    });
  };

  const onMount = async () => {
    try {
      const response = await getSingleUserAction({
        userId: userUuid,
        actionCode: props.code,
      });
      const userActions: any[] = response.userActions || [];
      if (userActions.length === 0) {
        setState((prev) => {
          return {
            ...prev,
            isOpen: true,
          };
        });
      }
    } catch (error) {
      setState((prev) => {
        return {
          ...prev,
          isOpen: false,
        };
      });
    }
  };

  React.useEffect(() => {
    onMount();

    return () => {};
  }, []);

  return (
    <Popover
      overlayInnerStyle={{padding: 0, borderRadius: 12, marginTop: 4}}
      open={state.isOpen}
      placement="bottomLeft"
      trigger={'click'}
      content={
        <View flexDirection={'row'}>
          <View
            style={{
              padding: 8,
              borderRadius: 4,
              borderWidth: 1,
              borderColor: Colors.FoldPixel.GRAY100,
              width: 'fit-content',
              height: 'fit-content',
            }}
          >
            {props.Icon}
          </View>
          <View marginLeft={4}>
            <Text>{props.title}</Text>
            <Text
              style={{
                width: 200,
                color: Colors.Custom.Gray400,
              }}
            >
              {props.subtitle}
            </Text>
          </View>
          <Button
            style={{marginLeft: 8, padding: 4}}
            type="text"
            onClick={() => {
              handleOnOpenChange();
            }}
          >
            <CancelButtonSvg />
          </Button>
        </View>
      }
    >
      {props.children}
    </Popover>
  );
};

export default NewFeaturePopUp;
