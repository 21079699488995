import {useContext} from 'react';
import {CommonDataContext} from '../../../context/CommonDataContext';
import CMSService from '../../../services/CommonService/CMSService';
import CommerceCommonService from '../../../services/CommonService/CommerceCommonService';
import {getAccountUUID, getFormURL} from '../../../utils/commonUtils';
import {
  FormEmailData,
  ICreateEmailConversationData,
  RecipientGroupData,
  RecipientMemberData,
  SendFormArgs,
} from './interface';
import {createCopyLinkPackageApi} from '../../RightSideContainer/Sales/ProductsAndServices/JourneyPackages/PackageServices';
import {TEMPLATES_CONST} from '../../RightSideContainer/TeamInbox/Conversations/ConversationConst';
import { IBulkContactData } from '../MemebersView/interface';

export const getEmailFormattedObject = (
  emailData: any,
  patientName?: string,
  bulkEmail?: string[],
  customHtml?: any
) => {
  const commonData = useContext(CommonDataContext);
  const subdomainMap: any = commonData.accountSubdomain || {};
  const tenantName: string =
    subdomainMap?.accountToSubdomain?.name || 'tenant name';
  const accountUuid = getAccountUUID();

  let data: any = {
    global: {
      clinicName: tenantName,
    },

    general: {text: customHtml ? customHtml : emailData?.emailContent},
    tenant: {
      name: tenantName,
    },
  };

  if (patientName) {
    data = {...data, patient: {firstName: patientName}};
  }
  if (emailData?.careplan) {
    data = {
      ...data,
      careplan: {
        name1: emailData.careplan,
        description1: emailData.careplanDes,
        price1: emailData.careplanPrice,
      },
    };
  }

  if (emailData?.formLink) {
    data = {
      ...data,
      form: {
        link: emailData.formLink,
      },
    };
  }

  if (emailData?.formList) {
    data = {
      ...data,
      formList: emailData.formList,
    };
  }

  if (emailData?.careJourneys && emailData?.careJourneys?.length > 0) {
    data = {
      ...data,
      carePlans: emailData.careJourneys,
    };
  }

  if (emailData?.template || emailData?.templateCategoryCode) {
    return {
      from: emailData?.from,
      to: emailData?.to && emailData?.to?.length ? emailData?.to : bulkEmail,
      subject: emailData?.subject || 'No Subject',
      template: emailData?.template,
      templateCategoryCode: emailData?.templateCategoryCode,
      data: data,
      accountId: accountUuid,
      tags: ['cms-email'],
    };
  } else {
    return {
      from: emailData?.from,
      to: emailData?.to && emailData?.to?.length ? emailData?.to : bulkEmail,
      subject: emailData?.subject || 'No Subject',
      data: data,
      accountId: accountUuid,
      tags: ['cms-email'],
    };
  }
};

const cmsInstance = CMSService.getCMSServiceInstance();
const cmsService = cmsInstance.cmsAxiosService;

export const sendEmail = (
  emailFormattedData: any,
  handlers?: {
    successHandler: (response: any) => void;
    errorHandler: (error: any) => void;
  }
) => {
  const currentList: any = [];
  const pendingList: any = [];
  emailFormattedData.to = Array.from(new Set(emailFormattedData.to))
  emailFormattedData.to.forEach((element: any, index: number) => {
    if(element?.slice()?.length) {
    if (index <= 5) {
      currentList.push(element);
    } else {
      pendingList.push(element);
    }
  }
  });
  const promiseAll = currentList.map((toEmail: any) => {
    const newFormattedEmailData = {
      ...emailFormattedData,
      to: [toEmail],
    };
    return cmsService.post('email/', newFormattedEmailData, {});
  });

  Promise.all(promiseAll)
    .then((res: any) => {
      if (pendingList && pendingList.length) {
        const newFormattedEmailData = {
          ...emailFormattedData,
          to: pendingList,
        };
        sendEmail(newFormattedEmailData, handlers);
      } else if (handlers) {
        handlers?.successHandler(res);
      }
    })
    .catch((err: any) => {
      if (handlers) {
        handlers.errorHandler(err);
      }
    });
};

export const sendMultipleFormEmail = (
  emailFormattedData: any,
  handlers?: {
    successHandler: (response: any) => void;
    errorHandler: (error: any) => void;
  }
) => {
  const currentList: any = [];
  const pendingList: any = [];
  emailFormattedData = Array.from(new Set(emailFormattedData))
  emailFormattedData?.forEach((element: any, index: number) => {
    if(element?.to?.[0]?.slice()?.length) {
    if (index <= 5) {
      currentList.push(element);
    } else {
      pendingList.push(element);
    }
  }
  });
  const promiseAll = currentList?.map((data: any) => {
    const newFormattedEmailData = {
      ...data,
      to: [data?.to?.[0]],
    };
    return cmsService.post('email/', newFormattedEmailData, {});
  });

  Promise.all(promiseAll)
    .then((res: any) => {
      if (pendingList && pendingList?.length) {
        const newFormattedEmailData = pendingList;
        sendMultipleFormEmail(newFormattedEmailData, handlers);
      } else if (handlers) {
        handlers?.successHandler(res);
      }
    })
    .catch((err: any) => {
      if (handlers) {
        handlers.errorHandler(err);
      }
    });
};

const commerceCommonService =
  CommerceCommonService.getCommerceCommonServiceInstance();

const axiosService = commerceCommonService.axiosService;

// export const getAllCarePlans = (
//   responseHandler: (response: any) => void,
//   errorHandler: (error: any) => void
// ) => {
//   const path = '/commerce/api/v2/platform/products';
//   axiosService
//     .get(path)
//     .then((response) => {
//       responseHandler(response);
//     })
//     .catch((error) => {
//       errorHandler(error);
//     });
// };

export interface ICarePlan {
  id: string;
  title: string;
  description: string;
  price: string;
}

export const getFormattedCarePlans = (response: any) => {
  const data: any[] = response?.data?.careJourneys;

  const resultArray: ICarePlan[] = data.map((item) => {
    const id = item?.id;
    const title = item?.title;
    const description = item?.description;
    const price = item?.price || '';

    return {
      id,
      title,
      description,
      price,
    };
  });

  return resultArray;
};

export const sendFormToGroupAndMembers = async (args: SendFormArgs) => {
  const {accountUuid, formId, memberData, fromEmail, emailData, formSourceId} =
    args;
  const formEmailData = getFormEmailData({
    formId,
    accountUuid,
    memberData: memberData,
    fromEmail: fromEmail,
    emailData,
    formSourceId,
  });

  return sendFormEmailInBatches(formEmailData);
};

export const getFormEmailData = (args: {
  formId: string;
  accountUuid: string;
  memberData: RecipientMemberData[];
  fromEmail?: string;
  emailData: any;
  formSourceId: string;
}): FormEmailData[] => {
  const {formId, accountUuid, memberData, fromEmail, emailData, formSourceId} =
    args;
  const result: FormEmailData[] = [];
  memberData.forEach((item) => {
    const formUrl = getFormURL(
      formId,
      item.contactUuid,
      accountUuid,
      item.subjectId,
      formSourceId
    );
    result.push({
      from: fromEmail || '',
      to: [item.email],
      subject: emailData?.subject || 'No Subject',
      accountId: accountUuid,
      templateCategoryCode: emailData.template ? '' : 'PAT_FORM',
      template: emailData.template,
      tags: ['cms-email', accountUuid],
      data: {
        form: {
          link: formUrl,
        },
        patient: {
          firstName: item.name,
        },
      },
    });
  });

  return result;
};

export const getMemberDataFromGroups = (
  groupApiResponse: any[]
): RecipientMemberData[] => {
  const result: RecipientMemberData[] = [];
  groupApiResponse.forEach((item) => {
    const {contacts} = item;
    contacts.forEach((item: any) => {
      if (item.email) {
        result.push({
          contactUuid: item.uuid,
          email: item.email,
          name: item.name,
        });
      }
    });
  });
  return result;
};

export const sendFormEmailInBatches = async (data: FormEmailData[]) => {
  const currentList: FormEmailData[] = [];
  const pendingList: FormEmailData[] = [];

  data.forEach((item, index) => {
    if (index <= 5) {
      currentList.push(item);
    } else {
      pendingList.push(item);
    }
  });

  const promiseList = currentList.map((item) => {
    return cmsService.post('email/', item, {});
  });

  const response = await Promise.all(promiseList);
  if (pendingList && pendingList.length > 0) {
    sendFormEmailInBatches(pendingList);
  }
  return response;
};

export const removeDuplicates = (memberData: RecipientMemberData[]) => {
  const result = [
    ...new Map(memberData.map((item) => [item['email'], item])).values(),
  ];

  return result;
};

export const getFormattedFormEmailData = (
  data: any
): {
  groupData: RecipientGroupData[];
  memberData: RecipientMemberData[];
} => {
  const {groups, members} = data;
  const memberData: RecipientMemberData[] = [];
  const groupData: RecipientGroupData[] = [];
  groups.forEach((item: any) => {
    groupData.push({groupId: item});
  });

  members.forEach((item: any) => {
    item?.uuid && memberData.push({
      contactUuid: item.uuid,
      email: item.email,
      name: item.name,
    });
  });

  return {
    groupData,
    memberData,
  };
};

export const getUpdatedCreateEmailConversationErrorMessages = (
  createSmsConversationDrawerData: ICreateEmailConversationData
): any => {
  let patientErrorMsgStr = '';
  let messageErrorStr = '';
  let subjectErrorStr = '';
  let isErrorPresent = false;

  if (!createSmsConversationDrawerData?.selectedPatient?.uuid) {
    patientErrorMsgStr = 'Please select patient';
    isErrorPresent = true;
  }
  if (!createSmsConversationDrawerData.messageStr) {
    messageErrorStr = 'Message is required';
    isErrorPresent = true;
  }
  if (!createSmsConversationDrawerData.subject) {
    subjectErrorStr = 'Subject is required';
    isErrorPresent = true;
  }
  return {
    errors: {
      patient: patientErrorMsgStr,
      message: messageErrorStr,
      subject: subjectErrorStr,
    },
    isErrorPresent,
  };
};

export const getContactIdsFromGroupResponse = (args: {groupResponse: any}) => {
  const {groupResponse} = args;
  const contactIdArray: any[] =
    groupResponse.map(
      (item: any) => item?.data?.rule?.patientFilter?.contactIds
    ) || [];
  const result: number[] = [];
  contactIdArray
    .filter((item) => Boolean(item))
    .forEach((item) => {
      item.forEach((id: any) => {
        result.push(id);
      });
    });
  return result;
};

export const getRecipientDataFromContacts = (args: {
  data: any;
}): RecipientMemberData[] => {
  const {data} = args;
  const result: RecipientMemberData[] = [];
  if (!data?.contacts?.length) {
    return [];
  }
  data?.contacts?.forEach((item: any) => {
    if (item.email) {
      result.push({
        contactUuid: item.uuid,
        email: item.email,
        name: item.name,
      });
    }
  });
  return result;
};

export const handleShareProducts = async (data: {
  productIds: string[];
  emails: string[];
  fromEmail: string;
  subject: string;
  templateId: string;
  accountUuid: string;
}) => {
  const promiseList = data.productIds.map((item) => {
    return createCopyLinkPackageApi(item);
  });
  const response = await Promise.all(promiseList);
  const emails = (data.emails || []).filter((item) => Boolean(item));
  const emailPromiseList = emails.map((item) => {
    const emailData = {
      to: [item],
      from: data.fromEmail,
      subject: data.subject,
      template: data.templateId,
      templateCategoryCode: TEMPLATES_CONST.SHARE_PACKAGES,
      data: {
        packages: response,
      },
      accountId: data.accountUuid,
      tags: ['cms-email'],
    };
    return cmsService.post('email', emailData);
  });
  const emailResponse = await Promise.allSettled(emailPromiseList);
  if (emailResponse.every((item) => item.status === 'fulfilled')) {
    return {
      data: emailResponse,
      error: null,
    };
  } else {
    return {
      data: null,
      error: emailResponse.filter((item) => item.status === 'rejected'),
    };
  }
};

export const filterBulkEmailWithConsentCheck = (isConsentRequired: boolean, bulkEmail: string[], bulkContactData?: IBulkContactData[]) => {
  if (!isConsentRequired || !bulkEmail?.length || !bulkContactData?.length) {
    return bulkEmail;
  }
  
  const filteredItems = bulkContactData
    .filter(
      (contact) =>
        bulkEmail.includes(contact?.email) && contact?.contactConsents?.length
    )
    ?.map((item) => item?.email);
  return filteredItems;
}

export const getWithoutConsentPatients = (
  bulkContactData: IBulkContactData[]
) => {
  let allEmails = '';
   if (bulkContactData?.length) {
     allEmails = bulkContactData
       ?.filter(
         (contact) => !contact?.contactConsents?.length
       )
       ?.map((contact) => contact?.email)
       ?.join(', ');
   }
  return allEmails;
};