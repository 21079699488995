import {Drawer} from 'antd';
import {Text, View} from 'native-base';
import {useIntl} from 'react-intl';
import {usePermission} from 'react-use';
import {Colors} from '../../../../../styles';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import {usePermissions} from '../../../../CustomHooks/usePermissions';
import AddOrUpdateForm from '../../../../RightSideContainer/Forms/FormBuilderWidget/AddOrUpdateForm/AddOrUpdateForm';
import {USER_ACCESS_PERMISSION} from '../../../../RightSideContainer/UserAccess/UserAccessPermission';
import {MAIN_MENU_CODES} from '../../../../SideMenuBar/SideBarConst';
import { styles } from '../../../../RightSideContainer/Contacts/Leads/LeadView/LeadViewStyle';

const CreateForm = (props: {
  showModal: boolean;
  noteCategoryId: string;
  onCancel: () => void;
  handleNewlyCreatedNote: (noteId: string, templateName?: string) => void;
  title?:string;
  isFromEmpDashboard?:boolean;
  quickNoteCreation?:boolean;
  style?:any
  categoryCode?:string
  patientLocations?: string[]
}) => {
  const {showModal, noteCategoryId, onCancel, handleNewlyCreatedNote,title,isFromEmpDashboard,quickNoteCreation ,style,categoryCode} = props;
  const intl = useIntl();
  const {check} = usePermissions();
  const permissionConfig = check(USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code, MAIN_MENU_CODES.FORMS);
  const currentUserAllowedLocations = permissionConfig?.allowedLocationIds || [];
  return (
    <Drawer
      open={showModal}
      onClose={onCancel}
      width={1200}
      title={
        <>
          <ModalActionTitle title={title ? title : 'newNoteTemplate'} />
          {isFromEmpDashboard ? (
            <Text style={styles.infoOutlineIcon}>
              Please make sure the selected form should have 'Score' set for all
              the fields. As the customer satisfaction calculations are done
              based on the form each field defined scores.
            </Text>
          ) : (
            <></>
          )}
        </>
      }
    >
      {showModal && (
        <View style={[style ? style : {}]}>
          <AddOrUpdateForm
            categoryCode={categoryCode}
            handleNewlyCreatedNote={handleNewlyCreatedNote}
            onClose={onCancel}
            quickNoteCreation={!quickNoteCreation ? quickNoteCreation : true}
            noteCategoryId={noteCategoryId}
            isFromEmpDashboard={isFromEmpDashboard}
            heightOffset={16}
            allowedLocationList={currentUserAllowedLocations}
            nonRemovableLocations={new Set(props?.patientLocations)}
          />
        </View>
      )}
    </Drawer>
  );
};

export default CreateForm;
