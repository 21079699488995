import React from 'react';
import Svg, {Path} from 'react-native-svg';
import {ICommonSvgProps} from '../interfaces';
import {Colors} from '../../../../styles';

const VideoMeetActionSvg = (props: ICommonSvgProps) => {
  const strokeColor = props?.isActive
    ? Colors.FoldPixel.GRAY300
    : Colors.Custom.Gray300;
  return (
    <Svg
      width={props?.width ? props?.width : '24'}
      height={props?.height ? props?.height : '24'}
      fill="none"
      viewBox="0 0 24 24"
    >
      <Path
        stroke={strokeColor}
        d="M2 11.5c0-3.287 0-4.931.908-6.038a4 4 0 01.554-.554C4.57 4 6.212 4 9.5 4c3.287 0 4.931 0 6.038.908a4 4 0 01.554.554C17 6.57 17 8.212 17 11.5v1c0 3.287 0 4.931-.908 6.038a4.001 4.001 0 01-.554.554C14.43 20 12.788 20 9.5 20c-3.287 0-4.931 0-6.038-.908a4 4 0 01-.554-.554C2 17.43 2 15.788 2 12.5v-1zM17 9.5l.658-.329c1.946-.973 2.92-1.46 3.63-1.02.712.44.712 1.528.712 3.703v.292c0 2.176 0 3.263-.711 3.703-.712.44-1.685-.047-3.63-1.02L17 14.5v-5z"
      ></Path>
    </Svg>
  );
};

export default VideoMeetActionSvg;
