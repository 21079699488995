import {Image, Popover} from 'antd';
import {
  Button,
  Center,
  HStack,
  Link,
  Pressable,
  Spinner,
  Text,
  View,
  VStack,
} from 'native-base';
import React, {useState} from 'react';
import AntIcon from 'react-native-vector-icons/AntDesign';
import Fontisto from 'react-native-vector-icons/Fontisto';
import {ATTACHMENT_TYPE, FILE_TYPE} from '../../../../constants';
import {Colors} from '../../../../styles';
import {getDateStrFromFormat} from '../../../../utils/DateUtils';
import {IMsgAttachment} from '../../../RightSideContainer/TeamInbox/Conversations/interfaces';
import VideoPlayerModal from '../../VideoPlayerModal/VideoPlayerModal';
import {styles} from './MsgAttachmentStyles';
import {getFileExtension} from './MsgAttachmentUtils';

const AttachmentView = (props: {
  attachments: any;
  openInDrawer: boolean | undefined;
}) => {
  let attachments: IMsgAttachment[] = props?.attachments?.attachments;
  let positionCls: any;
  const [stateData, setStateData] = useState({
    audioPlayerModal: false,
    videoPlayerModal: false,
    isOpen: false,
    id: -1,
    isImgLoaded: false,
    isPreviewVisible: false,
  });

  const getMsgAttachmentElem = (): JSX.Element => {
    positionCls = [styles.atttachmentbox, styles.rceMboxRight];
    attachments = attachments.filter((attachment) => attachment?.id);
    const attachmentElemList = attachments.map((attachment, index) => {
      const attachmentCode = attachment?.file_type;
      return (
        <View key={`AttachmentView_${attachment?.id}_${index}`}>
          <View>
            <Pressable
              key={attachment?.id}
              onPress={() => {
                handleOpenAttachment(attachment, attachmentCode);
              }}
            >
              <View>
                <View
                  style={[
                    positionCls,
                    {
                      height: 60,
                      borderColor: Colors.Custom.Gray50,
                      padding: 4,
                      marginBottom: 2,
                    },
                  ]}
                >
                  {getElementFromAttachmentType(attachment)}
                </View>
              </View>
            </Pressable>
          </View>
        </View>
      );
    });
    return <>{attachmentElemList}</>;
  };

  const handleOpenAttachment = (attachment: any, attachmentCode: string) => {
    switch (attachmentCode) {
      case ATTACHMENT_TYPE.AUDIO:
        setStateData((prev) => {
          return {
            ...prev,
            isOpen: false,
            audioPlayerModal: true,
          };
        });
        break;
      case ATTACHMENT_TYPE.FILE:
        setStateData((prev) => {
          return {
            ...prev,
            isOpen: false,
          };
        });
        window.open(attachment.data_url, '_blank', 'noopener,noreferrer');
        break;
      case ATTACHMENT_TYPE.VIDEO:
        setStateData((prev) => {
          return {
            ...prev,
            isOpen: false,
            videoPlayerModal: true,
          };
        });
        break;
      case ATTACHMENT_TYPE.IMAGE:
        setStateData((prev) => {
          return {
            ...prev,
            isOpen: false,
            id: attachment?.id || -1,
            isPreviewVisible: true,
          };
        });
        break;
    }
  };

  const handleDownloadAttachment = (
    attachment: any,
    attachmentCode: string
  ) => {
    switch (attachmentCode) {
      case ATTACHMENT_TYPE.AUDIO:
      case ATTACHMENT_TYPE.FILE:
      case ATTACHMENT_TYPE.VIDEO:
      case ATTACHMENT_TYPE.IMAGE:
        setStateData((prev) => {
          return {
            ...prev,
            isOpen: false,
          };
        });
        window.open(attachment.data_url, '_blank', 'noopener,noreferrer');
        break;
    }
  };

  const moreOptionsElements = (attachment: any, attachmentCode: string) => {
    return (
      <>
        <Pressable
          padding={1}
          onPress={() => {
            handleOpenAttachment(attachment, attachmentCode);
          }}
        >
          <Text>{'Open'}</Text>
        </Pressable>
        <Pressable
          padding={1}
          onPress={() => {
            handleDownloadAttachment(attachment, attachmentCode);
          }}
        >
          <Text>{'Download'}</Text>
        </Pressable>
      </>
    );
  };

  const handleVisibleChange = (attachment?: any) => {
    setStateData((prev) => {
      return {
        ...prev,
        id: attachment?.id || -1,
        isOpen: !stateData?.isOpen,
      };
    });
  };

  const getActions = (attachment: any, attachmentCode: string) => {
    return (
      <Popover
        content={moreOptionsElements(attachment, attachmentCode)}
        trigger="click"
        overlayInnerStyle={{padding: 0, borderRadius: 16}}
        overlayStyle={{padding: 0}}
        overlay={{padding: 0}}
        placement={'bottom'}
        visible={stateData?.isOpen && stateData?.id === attachment?.id}
        onVisibleChange={handleVisibleChange}
      >
        <Button
          onPress={() => {
            handleVisibleChange(attachment);
          }}
          style={{
            height: 32,
            width: 32,
            borderRadius: 18,
            borderWidth: 1,
            borderColor: Colors.secondary['200'],
            backgroundColor: Colors.secondary['100'],
          }}
        >
          <Fontisto name="more-v-a" size={18} color={Colors.secondary['800']} />
        </Button>
      </Popover>
    );
  };

  const imageAttachment = (attachment: any) => {
    const getImgElement = (): JSX.Element => {
      return (
        <Image
          height={50}
          width={50}
          src={attachment.thumb_url}
          preview={{
            visible:
              stateData?.isPreviewVisible && attachment?.id === stateData?.id,
            onVisibleChange: (visible, prevVisible) =>
              setStateData((prev) => {
                return {
                  ...prev,
                  isPreviewVisible: visible,
                };
              }),
          }}
          onClick={() => {
            setStateData((prev) => {
              return {
                ...prev,
                id: attachment?.id,
                isPreviewVisible: true,
              };
            });
          }}
          onLoad={() => {
            setStateData((prev) => {
              return {
                ...prev,
                isImgLoaded: true,
              };
            });
          }}
          alt={'img'}
        />
      );
    };

    return (
      <>
        {stateData?.isImgLoaded ? (
          <></>
        ) : (
          <Center>
            <Spinner />
          </Center>
        )}
        {getImgElement()}
      </>
    );
  };

  const FileNameInfo = (attachment: any) => {
    const attachmentCode = attachment?.file_type;
    const fileObj = getFileExtension(attachment.data_url);
    const senderName =
      props?.attachments?.sender?.name ||
      props?.attachments?.sender?.available_name ||
      '';
    return (
      <HStack space={2} flex={1}>
        <VStack flex={1}>
          <Text
            width={props?.openInDrawer ? 370 : 510}
            numberOfLines={1}
            overflow={'hidden'}
            size={'smMedium'}
            color={Colors.Custom.Gray700}
          >
            {fileObj.fileName}
          </Text>
          <Text
            width={props?.openInDrawer ? 370 : 510}
            numberOfLines={1}
            overflow={'hidden'}
            size={'smMedium'}
            color={Colors.Custom.Gray700}
          >
            {`Shared by ${senderName}, `}
            <Text>{getDateStrFromFormat(props?.attachments?.updated_at)}</Text>
          </Text>
        </VStack>
        <View justifyContent={'center'}>
          {getActions(attachment, attachmentCode)}
        </View>
      </HStack>
    );
  };

  const getElementFromAttachmentType = (
    attachment: IMsgAttachment
  ): JSX.Element => {
    let attachmentElem = <></>;
    const attachmentCode = attachment?.file_type;
    switch (attachmentCode) {
      case ATTACHMENT_TYPE.IMAGE:
        {
          if (attachment.thumb_url) {
            attachmentElem = (
              <HStack space={8}>
                <View>{imageAttachment(attachment)}</View>
                {FileNameInfo(attachment)}
              </HStack>
            );
          }
        }
        break;
      case ATTACHMENT_TYPE.VIDEO:
        {
          if (attachment.data_url) {
            attachmentElem = (
              <HStack space={8}>
                <Pressable
                  onPress={() => {
                    setStateData((prev) => {
                      return {
                        ...prev,
                        videoPlayerModal: true,
                      };
                    });
                  }}
                >
                  <View
                    style={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: 50,
                      height: 50,
                      borderRadius: 16,
                      backgroundColor: Colors.primary['100'] + '7a',
                    }}
                  >
                    <AntIcon
                      name="videocamera"
                      size={24}
                      color={Colors.primary['500']}
                    />
                  </View>
                </Pressable>
                {FileNameInfo(attachment)}

                {attachment?.data_url && stateData?.videoPlayerModal && (
                  <VideoPlayerModal
                    modalTitle={'videoMessage'}
                    srcUrl={attachment?.data_url}
                    filetype={FILE_TYPE.VIDEO}
                    height={200}
                    scrollEnabled={false}
                    onActionPerformed={() => {
                      setStateData((prev) => {
                        return {
                          ...prev,
                          videoPlayerModal: false,
                        };
                      })
                    }}
                  />
                )}
              </HStack>
            );
          }
        }
        break;

      case ATTACHMENT_TYPE.AUDIO:
        {
          if (attachment.data_url) {
            attachmentElem = (
              <HStack space={8}>
                <Pressable
                  onPress={() => {
                    setStateData((prev) => {
                      return {
                        ...prev,
                        audioPlayerModal: true,
                      };
                    });
                  }}
                >
                  <View
                    style={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: 50,
                      height: 50,
                      borderRadius: 16,
                      backgroundColor: Colors.primary['100'] + '7a',
                    }}
                  >
                    <AntIcon
                      name="playcircleo"
                      size={24}
                      color={Colors.primary['500']}
                    />
                  </View>
                </Pressable>
                {FileNameInfo(attachment)}

                {attachment?.data_url && stateData?.audioPlayerModal && (
                  <VideoPlayerModal
                    modalTitle={'audioMessage'}
                    srcUrl={attachment?.data_url}
                    filetype={FILE_TYPE.VIDEO}
                    height={200}
                    scrollEnabled={false}
                    onActionPerformed={() => {
                      setTimeout(
                        () =>
                          setStateData((prev) => {
                            return {
                              ...prev,
                              audioPlayerModal: false,
                            };
                          }),
                        200
                      );
                    }}
                  />
                )}
              </HStack>
            );
          }
        }
        break;
      case ATTACHMENT_TYPE.FILE:
        {
          attachmentElem = (
            <HStack height={50} flex={1}>
              <VStack flex={1}>
                <HStack space={6} flex={1}>
                  <Link
                    href={attachment.data_url}
                    isExternal
                    _hover={{
                      color: 'white',
                    }}
                  >
                    <View
                      mr={2}
                      style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: 50,
                        height: 50,
                        borderRadius: 16,
                        backgroundColor: Colors.primary['100'] + '7a',
                      }}
                    >
                      <AntIcon
                        name="file1"
                        size={24}
                        color={Colors.primary['500']}
                      />
                    </View>
                  </Link>
                  {FileNameInfo(attachment)}
                </HStack>
              </VStack>
            </HStack>
          );
        }
        break;
    }
    return attachmentElem;
  };

  return <>{getMsgAttachmentElem()}</>;
};

export default AttachmentView;
