import {useMutation} from '@apollo/client';
import {Drawer} from 'antd';
import {
  HStack,
  Icon,
  Spacer,
  Spinner,
  Text,
  TextArea,
  useToast,
  View,
  VStack,
} from 'native-base';
import {useIntl} from 'react-intl';
import {Pressable} from 'react-native';
import CalendarCancelIcon from '../../../../../../assets/Icons/CalendarCancelIcon';
import {DATE_FORMATS} from '../../../../../../constants';
import {
  APPOINTMENT_PARTICIPANT_STATUS_CODES,
} from '../../../../../../constants/MlovConst';
import {Colors} from '../../../../../../styles';
import {
  getDateStrFromFormat,
} from '../../../../../../utils/DateUtils';
import {IAppointmentDetail} from '../../../CalendarWidgetInterfaces';

const ProposedTimeBanner = (props: {
  appointmentDetails: IAppointmentDetail;
  isLoading: boolean;
  handleAccept: () => void;
  handleDecline: () => void;
}) => {
  const intl = useIntl();
  const proposedTime = props.appointmentDetails.proposedTime;

  return (
    <HStack
      backgroundColor={Colors.Custom.Gray50}
      borderRadius={4}
      p={4}
      mb={2}
      alignItems="center"
    >
      <VStack>
        <HStack alignItems={'center'}>
          <Icon as={<CalendarCancelIcon />} />
          <Text fontWeight={500} ml={1} color={Colors.Custom.SuccessColor}>
            {intl.formatMessage({id: 'patientProposedNewTime'})}
          </Text>
        </HStack>
        {proposedTime && (
          <Text fontSize={12} fontWeight={400} mt={1}>
            {'Proposed Time: ' +
              getDateStrFromFormat(
                proposedTime,
                `${DATE_FORMATS.DISPLAY_DATE_FORMAT} ${DATE_FORMATS.DISPLAY_TIME_FORMAT}`
              )}
          </Text>
        )}
      </VStack>
      <Spacer />
      <HStack alignItems={'center'}>
        {props.isLoading ? (
          <Spinner />
        ) : (
          <>
            <Pressable
              onPress={() =>
                props?.handleAccept()
              }
            >
              <Text
                color={Colors.Custom.PurpleColor}
                fontSize={12}
                fontWeight={400}
              >
                {intl.formatMessage({id: 'accept'})}
              </Text>
            </Pressable>
            <View
              style={{
                width: 1,
                height: 20,
                backgroundColor: Colors.Custom.Gray200,
                marginHorizontal: 12,
              }}
            ></View>
            <Pressable onPress={() => props.handleDecline()}>
              <Text
                color={Colors.Custom.PurpleColor}
                fontSize={12}
                fontWeight={400}
              >
                {intl.formatMessage({id: 'decline'})}
              </Text>
            </Pressable>
          </>
        )}
      </HStack>
    </HStack>
  );
};

export default ProposedTimeBanner;
