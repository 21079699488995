import React from 'react';
import {Drawer, InputNumber, Skeleton} from 'antd';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import {Colors} from '../../../../../styles/Colors';
import {BUTTON_TYPE, DATE_FORMATS} from '../../../../../constants/StringConst';
import {ModalActionAntSelect} from '../../../../common/ModalActionCommonComponent/ModalActionAntSelect';
import UseManualLogTime from '../../../../CustomHooks/UseManualLogTime';
import {ModalActionInput} from '../../../../common/ModalActionCommonComponent/ModalActionInput';
import {InputStyle} from '../../../Contacts/Leads/LeadView/LeadViewStyle';
import Stack from '../../../../common/LayoutComponents/Stack';
import {Text, View, StyleSheet} from 'react-native';
import {FormControl, Spinner} from 'native-base';
import UserAutoComplete from '../../../../common/CalendarWidget/UserAutoComplete/UserAutoComplete';
import {ITimeLoggingComponentState} from './TimeLoggingComponent';
import dayjs from 'dayjs';
import {ModalActionDatePicker} from '../../../../common/ModalActionCommonComponent/ModalActionDatePicker';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import {
  getCurrentTimeZone,
  getEndOfDayOfTimezone,
  getStartOfDayOfTimezone,
} from '../../../../../utils/DateUtils';
import { useIntl } from 'react-intl';

export interface IManualTimeLoggingDrawer {
  onClose: (isSave?: boolean, totalBillingMinutes?: number, timerNoOfSeconds?: number) => void;
  recordedTime: ITimeLoggingComponentState['recordedTime'];
  contactCareProgramId: string;
}

const ManualTimeLoggingDrawer = (props: IManualTimeLoggingDrawer) => {
  const {onClose, recordedTime, contactCareProgramId} = props;
  const intl = useIntl();
  const {
    manualLogTimeFields,
    resourcesList,
    handleSaveTimeLog,
    handleFieldChange,
    errors,
    accountUserLoading,
    manualLogTimeLoaders,
  } = UseManualLogTime({
    recordedTime: recordedTime,
    contactCareProgramId: contactCareProgramId,
    onClose,
  });

  const valueFormatter = (
    value: string | number | undefined,
    info: {userTyping: boolean; input: string}
  ): string => {
    const data = `${value}`;
    if (data.includes('.') && !info.userTyping) {
      return `${value}`.replace('\\.0*$', '');
    }
    return `${value}`;
  };

  return (
    <Drawer
      destroyOnClose
      placement="right"
      open={true}
      width={'30%'}
      closable
      title={
        <ModalActionTitle
          title={'logTime'}
          titleColor={''}
          buttonList={[
            {
              show: true,
              id: 1,
              btnText: 'cancel',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.SECONDARY,
              isTransBtn: false,
              onClick: () => {
                onClose();
              },
            },
            {
              show: true,
              id: 2,
              btnText: 'save',
              textColor: Colors.Custom.mainPrimaryPurple,
              variant: BUTTON_TYPE.PRIMARY,
              isTransBtn: false,
              onClick: () => {
                handleSaveTimeLog();
              },
              isLoading: manualLogTimeLoaders.savingTimeEntry,
              isDisabled: manualLogTimeLoaders.savingTimeEntry,
            },
          ]}
        />
      }
    >
      <Stack direction={'column'} space={8}>
        <ModalActionAntSelect
          label={'Task Type'}
          isRequired={true}
          value={manualLogTimeFields.taskType?.value}
          placeholder={`Select Task Type`}
          allowClear={true}
          onChange={(
            itemValue: any,
            data: typeof resourcesList.taskTypes[0]
          ) => {
            handleFieldChange('taskType', data);
          }}
          data={resourcesList.taskTypes}
          optionProps={{
            key: 'id',
            label: 'value',
            value: 'code',
          }}
          errors={!!errors['taskType']}
          errorText={errors['taskType']}
          customStyle={{
            flex: 1,
            placeHolderFontsize: InputStyle.placeHolderFontsize,
            placeHolderFontWeight: InputStyle.placeHolderFontWeight,
            minHeight: 45,
            width: '100%',
            labelBottomMargin: 0,
          }}
          style={{
            flex: 1,
          }}
        />
        <FormControl isInvalid={!!errors['timeSpent']}>
          <FormControl.Label>
            <DisplayText
              textLocalId={'timeSpentOnPatient'}
              extraStyles={{
                color: Colors.Custom.Gray500,
                fontWeight: 500,
                fontSize: 14,
              }}
            />
            <Text style={styles.requiredStar}>*</Text>
          </FormControl.Label>
          <Stack
            direction="row"
            style={{
              borderColor: !!errors.timeSpent ? Colors.Custom?.ErrorColor : Colors.Custom.Gray200,
              borderWidth: 1,
              backgroundColor: 'white',
              borderRadius: 5,
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <InputNumber
              type={'number'}
              placeholder={'Enter Time In Minutes'}
              height={'44px'}
              style={antdStyles.inputNumber}
              value={manualLogTimeFields['timeSpent']}
              controls={false}
              bordered={false}
              min={1}
              precision={0}
              step={1}
              formatter={valueFormatter}
              onChange={(value: any) => {
                handleFieldChange('timeSpent', value);
              }}
            />
            <View style={styles.minutesContainer}>
              <Text style={styles.minutesText}>mins</Text>
            </View>
          </Stack>
          <Text style={styles.instructionText}>
            {intl.formatMessage({id: 'recordingTimeRoundedOffInstruction'})}
          </Text>
          {!!errors['timeSpent'] && (
            <FormControl.ErrorMessage
              _text={{
                fontSize: 'xs',
                color: 'error.500',
                fontWeight: 500,
              }}
            >
              {errors['timeSpent']}
            </FormControl.ErrorMessage>
          )}
        </FormControl>
        {accountUserLoading ? (
          <Skeleton.Input
            active
            size="large"
            style={reactStyles.skeletonInput}
          />
        ) : (
          <>
            <UserAutoComplete
              labelText={'performedBy'}
              isRequired={true}
              isShowError={!!errors.performedBy}
              selectedValue={{
                ...manualLogTimeFields['performedBy'],
                key: manualLogTimeFields['performedBy']?.id || '',
                label: manualLogTimeFields['performedBy']?.name || '',
                value: manualLogTimeFields['performedBy']?.name || '',
              }}
              onChange={(selectedUser) => {
                handleFieldChange('performedBy', selectedUser?.data);
              }}
              usersData={resourcesList.accountUserList}
              errors={!!errors['performedBy']}
              errorText={errors['performedBy']}
            />
          </>
        )}
        <FormControl.Label marginLeft={0}>
          <DisplayText
            size={'smMedium'}
            extraStyles={{
              color: Colors.Custom.Gray500,
              fontWeight: 500,
              fontSize: 14,
            }}
            textLocalId={'performedOn'}
          />
          <Text style={styles.requiredStar}>
            *
          </Text>
        </FormControl.Label>
        <ModalActionDatePicker
          label=""
          showTime
          placeholder="Select date time"
          value={manualLogTimeFields.performedOn}
          format={`${DATE_FORMATS.DISPLAY_DATE_FORMAT} ${DATE_FORMATS.DISPLAY_TIME_FORMAT}`}
          extraStyle={{flex: 1}}
          isHideOkButton={true}
          customStyle={{height: 42}}
          minuteStep={5}
          allowClear={false}
          disabledDate={(date: any) => {
            const currentDate = getEndOfDayOfTimezone(
              new Date(),
              getCurrentTimeZone()
            );
            return date && date > currentDate;
          }}
          onChange={(startDate) => {
          }}
          onSelect={(value: any) => {
            handleFieldChange('performedOn', value);
          }}
          isInvalid={!!errors['performedOn']}
          errors={!!errors['performedOn']}
          errorsText={errors['performedOn']}
        />
      </Stack>
    </Drawer>
  );
};

const styles = StyleSheet.create({
  labelText: {
    color: Colors.Custom.Gray500,
    fontWeight: '500',
    fontSize: 14,
  },
  requiredStar: {
    color: Colors.Custom.ErrorColor,
  },
  inputContainer: {
    borderColor: Colors.Custom.Gray200,
    borderWidth: 1,
    backgroundColor: 'white',
    borderRadius: 5,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  inputContainerError: {
    borderColor: Colors.Custom?.ErrorColor,
  },
  minutesContainer: {
    justifyContent: 'center',
    marginLeft: 4,
  },
  minutesText: {
    padding: 2,
  },
  instructionText: {
    fontWeight: '400',
    fontSize: 12,
    color: Colors.FoldPixel.GRAY200,
    marginTop: 4,
  },
  skeletonInput: {
    height: 30,
    marginTop: 4,
    width: '100%',
  },
});

const antdStyles: Record<string, React.CSSProperties> = {
  inputNumber: {
    width: '100%',
    padding: 2,
    fontSize: 12,
    borderRadius: 8,
    height: '44px',
  },
};

const reactStyles: Record<string, React.CSSProperties> = {
  skeletonInput: {
    height: 30,
    marginTop: 4,
    width: '100%',
  },
  inputNumber: {
    width: '100%',
    padding: 2,
    fontSize: 12,
    borderRadius: 8,
  },
};

export default ManualTimeLoggingDrawer;
