import { StyleSheet } from 'react-native';
import { Colors } from '../../../styles';

export const styles = StyleSheet.create({
  contactGroupingOutSideContainer: {
    borderColor: Colors.FoldPixel.GRAY150,
    borderWidth: 0.5,
    height: '40px',
    padding: '8px 12px',
    backgroundColor: Colors.FoldPixel.GRAY50,
    width: '100%',
  },
  groupContactView: {
    marginTop: 15,
    width: '100%'
  },
  groupWidth: {
    width: '100%'
  }
});


export const cssPropertiesAntD: Record<string, React.CSSProperties> = {
  groupWidth: {
    width: '100%'
  },
  groupName: {
    color: Colors.FoldPixel.GRAY300,
    fontSize: 14,
    fontWeight: '600'
  },
};