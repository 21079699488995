import {useQuery} from '@apollo/client';
import {HStack, Text, Tooltip} from 'native-base';
import ContactDetailsQueries from '../../../../../services/Conversations/ContactDetailsQueries';
import {Colors} from '../../../../../styles';
import {getAccountId} from '../../../../../utils/commonUtils';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import {IContact} from '../interfaces';
import {styles} from './MessagingContactDetailsStyles';
import { CHANNEL_TYPE } from '../ConversationConst';
import { getEFaxInboxDetails, getEmailInboxDetails, getSmsInboxDetails } from './InboxDetailsPanelUtils';
import { NoDataFound } from '../../../../common/NoDataFound';

interface IDetailsPanelReadOnly {
  contactData: IContact;
  conversationUuid?: string;
  channelType?: string;
}

export const DetailsPanelReadOnly = (props: IDetailsPanelReadOnly) => {
  const {contactData} = props;
  const accountId = getAccountId();
  const {data} = useQuery(ContactDetailsQueries.GetConversationDetails, {
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    variables: {
      contactId: contactData?.id,
      accountId: accountId,
      uuid: props?.conversationUuid,
    },
  });
  const conversationObj = data?.conversations[0] || [];

  const getInboxDetailsData = (channelType?: string) => {
    switch (channelType) {
      case CHANNEL_TYPE.CHANNEL_TWILIO_SMS:
        return getSmsInboxDetails(conversationObj);

      case CHANNEL_TYPE.CHANNEL_EMAIL:
        return getEmailInboxDetails(conversationObj);

      case CHANNEL_TYPE.CHANNEL_EFAX:
        return getEFaxInboxDetails(conversationObj);

      default:
        return [];
    }
  };

  const getDetailPanelElement = () => {
    const inboxDetailsData = getInboxDetailsData(props?.channelType);
    return (
      <>
        {inboxDetailsData && inboxDetailsData?.length > 0 ? (
          inboxDetailsData?.map((items) => {
            return (
              <HStack style={styles.panelBoxContents} flex={1}>
                <DisplayText
                  size={'smNormal'}
                  extraStyles={{color: Colors.Custom.Gray700, flex: 0.4}}
                  textLocalId={items?.key}
                />
                <Tooltip placement={'top'} label={items?.value || ''}>
                  <Text
                    flex={0.6}
                    size={'smNormal'}
                    style={styles.grayText}
                    numberOfLines={1}
                  >
                    {items?.value ? `: ${items?.value}` : ': --'}
                  </Text>
                </Tooltip>
              </HStack>
            );
          })
        ) : (
          <NoDataFound textStyle={{fontSize: 14}} />
        )}
      </>
    );
  };
  return (
    getDetailPanelElement()
  );
};
