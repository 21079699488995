import {Drawer} from 'antd';
import {Button, HStack, Text, View} from 'native-base';
import React from 'react';
import {Dimensions} from 'react-native';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import {OverrideDrawerProps} from '../interfaces';

const OverrideDrawer = (props: OverrideDrawerProps) => {
  const {isOpen, onCancel, onConfirm, textContents} = props;
  const {headerText, body} = textContents;

  const {width} = Dimensions.get('window');
  const drawerWidth = width * 0.33;

  return (
    <Drawer
      visible={isOpen}
      destroyOnClose
      width={drawerWidth}
      onClose={onCancel}
      title={
        <>
          <HStack
            style={{
              flex: 1,
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <DisplayText textLocalId={headerText} textType="Heading" />

            <Button.Group>
              <Button
                onPress={() => {
                  onCancel();
                }}
              >
                Cancel
              </Button>
              <Button
                onPress={() => {
                  onConfirm();
                }}
              >
                Confirm
              </Button>
            </Button.Group>
          </HStack>
        </>
      }
    >
      <View>
        <Text>{body}</Text>
      </View>
    </Drawer>
  );
};

export default OverrideDrawer;
