import {StyleSheet} from 'react-native';
import {Colors} from '../../../../../../styles';

export const styles = StyleSheet.create({
  btnStyle: {
    borderRadius: 50,
  },
  container: {
    backgroundColor: Colors.Custom.BackgroundColor,
    marginLeft:20,
  },
  footerStyle: {
    backgroundColor: Colors.Custom.BackgroundColor,
  },
});
