import {
  HStack,
  VStack,
  Icon,
  Text,
  Spinner,
  ScrollView,
  Pressable,
} from 'native-base';
import {useEffect, useState} from 'react';
import {useWindowDimensions, View} from 'react-native';

import {TouchableOpacity} from 'react-native-gesture-handler';
import AntDesign from 'react-native-vector-icons/AntDesign';
import {ICampaignErrors} from './interfaces';
import PageBodyContainer from '../../../common/PageBodyContainer/PageBodyContainer';
import {Colors} from '../../../../styles/Colors';
import ModalActionBtn from '../../../common/ModalActionBtn/ModalActionBtn';
import {BUTTON_TYPE} from '../../../../constants';
import {styles} from './EmailOrSmsCreatorForm/EmailOrSmsCreatorFormStyles';
import {DisplayText} from '../../../common/DisplayText/DisplayText';
import {
  NotificationApiResponse,
  NotificationMetaData,
  NotificationTableState,
  SingleNotification,
} from '../PushNotifications/interfaces';
import {ITemplateCategory} from '../EmailTemplates/interfaces';
import {
  getFormattedPushNotifications,
  getParseBody,
  getPushNotifications,
} from '../PushNotifications/PushNotificationsUtils';
import {
  getAccountMergeTagData,
  getTemplateCategories,
  getTemplateCategoryList,
} from '../ContentManagementUtils';
import {getCategoryQueryString} from '../EmailTemplates/EmailTemplatesUtils';
import TemplateBg from './EmailOrSmsCreatorForm/TemplateBg';
import {AddOrUpdatePushNotification} from '../PushNotifications/AddOrUpdatePushNotification';
import {COMMON_ACTION_CODES} from '../../../../constants/ActionConst';
import {PushNotificationPreview} from '../PushNotifications/PushNotificationPreview';
import {ModalActionTitle} from '../../../common/ModalActionTitle/ModalActionTitle';
import TemplateCategoryAutoComplete from './EmailOrSmsCreatorForm/TemplateCategoryAutoComplete';
import {SearchBar} from '../../../common/SearchBar';
import {debounce} from 'lodash';

const NotificationTemplateCampaignPreview = (props: {
  emailTemplateId?: string;
  campaignDetailsError?: ICampaignErrors;
  onTemplateIdSelected: (value: any) => void;
  onCancel: () => void;
}) => {
  const {height, width} = useWindowDimensions();

  const [tableState, setTableState] = useState<NotificationTableState>({
    apiData: {} as NotificationApiResponse,
    metaData: {} as NotificationMetaData,
    page: 1,
    pageSize: 200,
    categoryList: [] as ITemplateCategory[],
    displayList: [] as SingleNotification[],
    searchedDisplayList: [] as SingleNotification[],
    isLoading: true,
    isNoDataFound: false,
    selectedCategories: [],
    sort: 'desc',
    tableSortOrderString: 'descend',
    searchedTemplateText: '',
  });
  const [showMore, setShowMore] = useState<boolean>(false);
  const [showPreview, setShowPreview] = useState<boolean>(false);

  const [shouldCreateEmailTemplate, setShouldCreateEmailTemplate] =
    useState<boolean>(false);
  const [shouldUpdateEmailTemplate, setShouldUpdateEmailTemplate] =
    useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] =
    useState<SingleNotification>();

  const setLoading = (loading: boolean) => {
    setTableState((prev) => {
      return {
        ...prev,
        isLoading: loading,
      };
    });
  };

  useEffect(() => {
    try {
      getData();
    } catch (error) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    try {
      getNotificationDataAsPerFilters(props.emailTemplateId);
    } catch (error) {
      setLoading(false);
    }
  }, [tableState.page, tableState.pageSize, '', tableState.selectedCategories]);

  const getData = () => {
    setLoading(true);
    const categoryQueryString = getCategoryQueryString(
      tableState.categoryList,
      tableState.selectedCategories?.length
        ? tableState.selectedCategories?.[0]?.code != 'All'
          ? [tableState.selectedCategories?.[0]?.name]
          : []
        : []
    );
    const notificationPromise = getPushNotifications(categoryQueryString, {
      name: '',
      page: tableState.page,
      pageSize: tableState.pageSize,
      sort: tableState.sort,
    });

    const categoryPromise = getTemplateCategories();

    Promise.all([notificationPromise, categoryPromise]).then((response) => {
      const list = getFormattedPushNotifications(response[0]);
      const categoryList = getTemplateCategoryList(response[1], true);
      setTableState((prev) => {
        return {
          ...prev,
          apiData: response[0].data,
          metaData: response[0].meta,
          categoryList: categoryList,
          searchedDisplayList: list || [],
          displayList: list,
          isLoading: false,
          selectedCategories: categoryList?.length ? [categoryList?.[0]] : [],
        };
      });
    });
  };

  const getNotificationDataAsPerFilters = async (id: string | undefined) => {
    setLoading(true);
    const categoryQueryString = getCategoryQueryString(
      tableState.categoryList,
      tableState.selectedCategories?.length
        ? tableState.selectedCategories?.[0]?.code != 'All'
          ? [tableState.selectedCategories?.[0]?.name]
          : []
        : []
    );

    const response = await getPushNotifications(categoryQueryString, {
      name: '',
      page: tableState.page,
      pageSize: tableState.pageSize,
      sort: tableState.sort,
    });
    const list = getFormattedPushNotifications(response);
    if (id) {
      const template = list?.find((value) => {
        return value.id == id;
      });
      if (template) {
        template;
        setSelectedTemplate(template);
      }
    } else {
      if (list && list.length > 0) {
        // setSelectedTemplate(list[0]);
      }
    }
    setTableState((prev) => {
      return {
        ...prev,
        apiData: response.data,
        metaData: response.meta,
        displayList: list,
        searchedDisplayList: list || [],
        isLoading: false,
      };
    });
  };

  useEffect(() => {
    if (selectedTemplate?.id) {
      props.onTemplateIdSelected(selectedTemplate);
    }
  }, [selectedTemplate]);

  const accountMergeTags = getAccountMergeTagData();

  const getMergeTags = (notificationData?: SingleNotification) => {
    if (notificationData?.category) {
      return {
        ...notificationData?.category?.mergeTags,
        global: accountMergeTags,
      };
    }
    return {
      global: accountMergeTags,
    };
  };

  const searchTemplates = (value: string) => {
    const searchedTemplates = tableState?.displayList?.filter((template) => {
      if (template?.name?.toLowerCase()?.includes(value.toLowerCase())) {
        return template;
      }
    });
    setTableState({
      ...tableState,
      searchedDisplayList: searchedTemplates || [],
      searchedTemplateText: value,
    });
  };

  return (
    <>
      <PageBodyContainer style={{marginHorizontal: 0}}>
          <View style={{padding: 4}}>
            <View style={{}}>
              <VStack>
                <ModalActionTitle
                  title={'pushNotifications'}
                  //titleColor={Colors.Custom.Gray700}
                  //titleFontSize={24}
                  buttonList={[
                    {
                      show: true,
                      id: 1,
                      btnText: 'cancel',
                      textColor: Colors.Custom.mainPrimaryPurple,
                      variant: BUTTON_TYPE.SECONDARY,
                      isTransBtn: false,
                      onClick: () => {
                        if (showPreview) {
                          setShowPreview(false);
                        } else {
                          props?.onCancel();
                        }
                      },
                    },
                ]}
              />
              {/* {selectedTemplate &&
                !shouldCreateEmailTemplate &&
                !shouldUpdateEmailTemplate ? (
                  <HStack
                    marginBottom={2}
                    alignItems="flex-end"
                    justifyContent={'flex-end'}
                    marginRight={0}
                  >
                    <ModalActionBtn
                      btnText="Edit"
                      customStyle={[styles.createBtn]}
                      btnStype={BUTTON_TYPE.PRIMARY}
                      onClickAction={() => {
                        setShouldUpdateEmailTemplate(
                          !shouldUpdateEmailTemplate
                        );
                      }}
                    />
                  </HStack>
                ) : (
                  <View style={{height: 48}}></View>
                )} */}
              {showPreview ? (
                <VStack
                  justifyItems={'center'}
                  alignSelf={'center'}
                  style={[styles.templateCard, {padding: 40, paddingTop: 0}]}
                >
                  <HStack style={styles.readonlyLineContainer}>
                    <Text paddingTop={'3px'} width={100} fontWeight={300}>
                      Name
                    </Text>
                    <Text
                      style={styles.readPillContainer}
                      fontWeight={500}
                      numberOfLines={1}
                    >
                      {selectedTemplate?.name || ''}
                    </Text>
                  </HStack>

                  <HStack style={styles.readonlyLineContainer}>
                    <Text paddingTop={'3px'} width={100} fontWeight={300}>
                      Category
                    </Text>
                    <Text
                      style={styles.readPillContainer}
                      fontWeight={500}
                      numberOfLines={1}
                    >
                      {selectedTemplate?.category?.name || ''}
                    </Text>
                  </HStack>
                  <HStack style={styles.readonlyLineContainer}>
                    <Text paddingTop={'3px'} width={100} fontWeight={300}>
                      Body
                    </Text>
                    <Text
                      style={styles.readPillContainer}
                      fontWeight={500}
                      //numberOfLines={1}
                    >
                      {selectedTemplate?.body || ''}
                    </Text>
                  </HStack>
                  <PushNotificationPreview
                    notificationBody={
                      getParseBody({
                        bodyString: selectedTemplate?.body || '',
                        mergeTags: getMergeTags(selectedTemplate),
                      }) || 'Body'
                    }
                    notificationSubject={selectedTemplate?.subject || 'Title'}
                  />
                </VStack>
              ) : (
                <>
                  <HStack
                    style={{
                      marginTop: 100,
                      marginBottom: 20,
                    }}
                    justifyContent={'space-between'}
                  >
                    <TemplateCategoryAutoComplete
                      flex={0.3}
                      name={tableState?.selectedCategories?.[0]?.name || ''}
                      isRequired={false}
                      onAutoCompleteChange={(
                        selectedTemplateCategoryCode: ITemplateCategory
                      ) => {
                        setTableState({
                          ...tableState,
                          selectedCategories: [selectedTemplateCategoryCode],
                          searchedTemplateText: '',
                        });
                      }}
                      onValueChange={(value: string) => {
                        const selectedTemplateCategory =
                          tableState?.categoryList?.find((categoryObj) => {
                            return categoryObj.name === value;
                          }) || ({} as ITemplateCategory);
                        setTableState({
                          ...tableState,
                          selectedCategories: [selectedTemplateCategory],
                          searchedTemplateText: '',
                        });
                      }}
                      showAdd={true}
                      dataList={tableState?.categoryList}
                    />

                    <SearchBar
                      searchText={tableState?.searchedTemplateText || ''}
                      flex={0.3}
                      searchBarInputStyles={{
                        marginTop: 7,
                        paddingVertical: 0.1,
                      }}
                      placeholderText={'search template'}
                      borderRadius={10}
                      onChange={debounce(searchTemplates, 1000)}
                    />
                  </HStack>
                  {!tableState.isLoading ? (
                    <ScrollView backgroundColor="white">
                      <VStack>
                        {props?.campaignDetailsError?.emailTemplateError ? (
                          <DisplayText
                            textLocalId={'required'}
                            size={'smMedium'}
                            extraStyles={styles.requiredText}
                          />
                        ) : null}
                        <HStack
                          flexWrap={'wrap'}
                          justifyContent={
                            tableState?.searchedDisplayList &&
                            tableState?.searchedDisplayList?.length < 2
                              ? 'flex-start'
                              : 'space-between'
                          }
                          alignItems="center"
                          backgroundColor="white"
                        >
                          <Pressable
                            style={{
                              justifyContent: 'center',
                              alignSelf: 'center',
                              backgroundColor: 'white',
                              marginBottom: 10,
                              marginLeft: width * 0.0075,
                            }}
                            onPress={() => {
                              setShouldCreateEmailTemplate(
                                !shouldCreateEmailTemplate
                              );
                            }}
                          >
                            <View
                              style={[
                                styles.templateCard,
                                {
                                  marginTop: 5,
                                  alignSelf: 'center',
                                  height: width * 0.15,
                                  width: width * 0.24,
                                  justifyContent: 'center',
                                },
                              ]}
                            >
                              <Icon
                                as={AntDesign}
                                name="plus"
                                size="10"
                                color="blue.900"
                                alignSelf="center"
                              />
                            </View>
                            <Text
                              style={{
                                color: Colors.Custom.Gray700,
                                fontSize: 14,
                                marginTop: 3,
                                justifyContent: 'center',
                                alignSelf: 'center',
                                alignItems: 'center',
                              }}
                            >
                              {' '}
                            </Text>
                          </Pressable>
                          {tableState?.searchedDisplayList?.map(
                            (notificationTemplateData: SingleNotification) => {
                              return (
                                <Pressable
                                  style={{
                                    justifyContent: 'center',
                                    alignSelf: 'center',
                                    marginLeft: width * 0.0075,
                                    marginBottom: 10,
                                    position: 'relative',
                                  }}
                                  onPress={() => {
                                    setSelectedTemplate(
                                      notificationTemplateData
                                    );
                                  }}
                                >
                                  <View
                                    style={{
                                      height: width * 0.15 + 5,
                                      width: width * 0.24,
                                      position: 'absolute',
                                      paddingTop: 5,
                                      paddingHorizontal: 10,
                                      borderRadius: 12,
                                      top: 0,
                                      // @ts-ignore: Unreachable code error
                                      backgroundImage: `linear-gradient(#ffffff00,#ffffff00, #000000cc)`,
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      flexDirection: 'column',
                                      alignItems: 'center',
                                      zIndex: 2,
                                    }}
                                  >
                                    <View
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-end',
                                        alignItems: 'center',
                                        width: '100%',
                                        padding: 10,
                                      }}
                                    >
                                      <Pressable
                                        style={{
                                          backgroundColor: '#FFECD5',
                                          borderRadius: 50,
                                          padding: 8,
                                          marginRight: 10,
                                        }}
                                        onPress={() => {
                                          setShouldUpdateEmailTemplate(true);
                                          setSelectedTemplate(
                                            notificationTemplateData
                                          );
                                        }}
                                      >
                                        <AntDesign
                                          name="edit"
                                          color={'#8C210F'}
                                          size={20}
                                        />
                                      </Pressable>
                                      <Pressable
                                        style={{
                                          backgroundColor: '#FFECD5',
                                          borderRadius: 50,
                                          padding: 8,
                                        }}
                                        onPress={() => {
                                          setSelectedTemplate(
                                            notificationTemplateData
                                          );
                                          setShowPreview(true);
                                        }}
                                      >
                                        <AntDesign
                                          name="eye"
                                          color={'#8C210F'}
                                          size={20}
                                        />
                                      </Pressable>
                                    </View>
                                    <Pressable
                                      style={{
                                        backgroundColor: '#FFECD5',
                                        borderRadius: 50,
                                        padding: 8,
                                        width: '45%',
                                        paddingHorizontal: 44,
                                        marginBottom: 20,
                                      }}
                                      onPress={() => {
                                        props.onTemplateIdSelected(
                                          notificationTemplateData
                                        );
                                        setSelectedTemplate(
                                          notificationTemplateData
                                        );
                                        props?.onCancel();
                                      }}
                                    >
                                      <Text
                                        size="smNormal"
                                        fontSize={14}
                                        color="#8C210F"
                                        textAlign={'center'}
                                      >
                                        Use
                                      </Text>
                                    </Pressable>
                                  </View>
                                  <View
                                    style={[
                                      styles.templateCard,
                                      {
                                        justifyContent: 'center',
                                        alignSelf: 'center',
                                        alignItems: 'center',
                                        marginTop: 5,
                                        height: width * 0.15,
                                        width: width * 0.24,
                                        overflow: 'hidden',
                                        display: 'flex',
                                        borderWidth:
                                          selectedTemplate?.id ==
                                          notificationTemplateData?.id
                                            ? 2
                                            : 0,
                                        borderColor: '#825AC7',
                                      },
                                    ]}
                                  >
                                    <HStack
                                      style={styles.readonlyLineContainer}
                                    >
                                      <Text
                                        style={styles.readPillContainer}
                                        fontWeight={500}
                                        //numberOfLines={1}
                                      >
                                        {notificationTemplateData?.body || ''}
                                      </Text>
                                    </HStack>
                                    <PushNotificationPreview
                                      notificationBody={
                                        getParseBody({
                                          bodyString:
                                            notificationTemplateData.body || '',
                                          mergeTags: getMergeTags(
                                            notificationTemplateData
                                          ),
                                        }) || 'Body'
                                      }
                                      notificationSubject={
                                        notificationTemplateData.subject ||
                                        'Title'
                                      }
                                    />
                                  </View>
                                  <Text
                                    style={{
                                      color: Colors.Custom.Gray700,
                                      fontSize: 14,
                                      marginTop: 3,
                                      justifyContent: 'center',
                                      alignSelf: 'center',
                                      alignItems: 'center',
                                    }}
                                  >
                                    {notificationTemplateData &&
                                      notificationTemplateData.name}
                                  </Text>
                                </Pressable>
                              );
                            }
                          )}
                        </HStack>
                      </VStack>
                    </ScrollView>
                  ) : (
                    <View style={{height: height - 300}}>
                      <Spinner
                        flex={8.5}
                        size="lg"
                        position="absolute"
                        alignSelf="center"
                        top="50%"
                        left="50%"
                      />
                    </View>
                  )}
                </>
              )}
              {/* <HStack>
                  <HStack flex={1.5} height={height - 220}>
                    <ScrollView backgroundColor="white">
                      <VStack>
                        {props?.campaignDetailsError?.emailTemplateError ? (
                          <DisplayText
                            textLocalId={'required'}
                            size={'smMedium'}
                            extraStyles={styles.requiredText}
                          />
                        ) : null}
                        <TouchableOpacity
                          style={{
                            width: '100%',
                            justifyContent: 'center',
                            alignSelf: 'center',
                            backgroundColor: 'white',
                            marginBottom: 10,
                          }}
                          onPress={() => {
                            setShouldCreateEmailTemplate(
                              !shouldCreateEmailTemplate
                            );
                          }}
                        >
                          <View
                            style={[
                              styles.templateCard,
                              {
                                margin: 10,
                                alignSelf: 'center',
                                height: 130,
                                width: 130,
                                justifyContent: 'center',
                              },
                            ]}
                          >
                            <Icon
                              as={AntDesign}
                              name="plus"
                              size="10"
                              color="blue.900"
                              alignSelf="center"
                            />
                          </View>
                        </TouchableOpacity>
                        <VStack flex={1} backgroundColor="white">
                          {tableState.displayList &&
                            !tableState.isLoading &&
                            tableState.displayList.map(
                              (singleGenderObj: SingleNotification) => {
                                return (
                                  <TouchableOpacity
                                    style={{
                                      width: '100%',
                                      backgroundColor:
                                        selectedTemplate?.id ==
                                        singleGenderObj.id
                                          ? '#dec8f5'
                                          : 'white',
                                      justifyContent: 'center',
                                      alignSelf: 'center',
                                      marginBottom: 10,
                                    }}
                                    onPress={() => {
                                      setSelectedTemplate(singleGenderObj);
                                    }}
                                  >
                                    <View
                                      style={[
                                        styles.templateCard,
                                        {
                                          height: 130,
                                          width: 130,
                                          justifyContent: 'center',
                                          alignSelf: 'center',
                                          alignItems: 'center',
                                          marginTop: 5,
                                        },
                                      ]}
                                    >
                                      <TemplateBg></TemplateBg>
                                    </View>
                                    <Text
                                      style={{
                                        color: Colors.Custom.Gray700,
                                        fontSize: 14,
                                        marginTop: 3,
                                        justifyContent: 'center',
                                        alignSelf: 'center',
                                        alignItems: 'center',
                                      }}
                                    >
                                      {singleGenderObj && singleGenderObj.name}
                                    </Text>
                                  </TouchableOpacity>
                                );
                              }
                            )}
                        </VStack>
                      </VStack>
                    </ScrollView>
                    <VStack
                      style={{backgroundColor: '#eaecf0', width: 20}}
                    ></VStack>
                  </HStack>
                  <HStack flex={8.5}>
                    <ScrollView>
                      <HStack>
                        <View style={{flex: 9}}>
                          <AddOrUpdatePushNotification
                            isReadOnly={true}
                            categoryList={tableState.categoryList}
                            singleNotification={selectedTemplate}
                          />
                        </View>
                        <VStack
                          style={{backgroundColor: '#eaecf0', width: 20}}
                        ></VStack>
                      </HStack>
                    </ScrollView>
                  </HStack>
                </HStack> */}
            </VStack>
          </View>
        </View>

        {shouldCreateEmailTemplate && (
          <AddOrUpdatePushNotification
            categoryList={tableState.categoryList}
            onFormCompleteAction={(actionCode) => {
              setShouldCreateEmailTemplate(false);
              if (actionCode === COMMON_ACTION_CODES.ADDED_OR_UPDATED) {
                // onActionPerformed(actionCode, selectedRow);
                getNotificationDataAsPerFilters('');
              }
            }}
          />
        )}

        {shouldUpdateEmailTemplate && (
          <AddOrUpdatePushNotification
            categoryList={tableState.categoryList}
            singleNotification={selectedTemplate}
            onFormCompleteAction={(actionCode) => {
              if (actionCode === COMMON_ACTION_CODES.ADDED_OR_UPDATED) {
                // onActionPerformed(actionCode, selectedRow);
                getNotificationDataAsPerFilters('');
              }
              setShouldUpdateEmailTemplate(false);
            }}
          />
        )}
      </PageBodyContainer>
    </>
  );
};

export default NotificationTemplateCampaignPreview;
