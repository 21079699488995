import {Drawer, Skeleton} from 'antd';
import {
  FlatList,
  HStack,
  useMediaQuery,
  Text,
  VStack,
  View,
  Image,
} from 'native-base';
import {Dimensions, SafeAreaView} from 'react-native';
import {
  BUTTON_TYPE,
  DATE_FORMATS,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH,
  SMALL_WINDOW_1700,
} from '../../../constants/StringConst';
import {Colors} from '../../../styles/Colors';
import {testID} from '../../../testUtils';
import {COMPONENT_TEST_IDENTIFIER} from '../../../testUtils/ComponentTestIdentifiers';
import {
  getDateStrFromFormat,
} from '../../../utils/DateUtils';
import {
  IResourceMapDetail,
  useResourceMapping,
} from '../../CustomHooks/useResourceMapping';
import { RESOURCE_MAP, SOURCE_MAP } from '../AddTask/AddTaskUtils';
import {ModalActionTitle} from '../ModalActionTitle/ModalActionTitle';

interface ICareProgramConsentProps {
  isVisible: boolean;
  contactId: string;
  contactCareProgramId: string;
  onClose: (action: boolean) => void;
}

export const EcmOutreach = (props: ICareProgramConsentProps) => {
  const {contactId, isVisible, contactCareProgramId, onClose} = props;
  const screenDimensions = Dimensions.get('window');
  const smallWindow = screenDimensions?.width < SMALL_WINDOW_1700;
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const {loading, resourceMapList} = useResourceMapping({
    resourceId: '67afe563-6f58-4b16-af70-25d68981065a',
  });

  const getIconName = (resourceType: string) => {
    if (resourceType === 'MESSAGE') {
      return (
        <Image
          style={{height: 20, width: 20}}
          source={require('../../../assets/images/CRMPipeline/Messages_CRM_Activity.png')}
          size={'32px'}
          alt="image"></Image>
      );
    }
    if (resourceType === 'MEMBERSHIP') {
      return (
        <Image
          style={{height: 20, width: 20}}
          source={require('../../../assets/images/CRMPipeline/membership.png')}
          size={'32px'}
          alt="image"></Image>
      );
    }
    if (resourceType === 'APPOINTMENT') {
      return (
        <Image
          style={{height: 20, width: 20}}
          source={require('../../../assets/images/CRMPipeline/AppointmentAction.png')}
          size={'32px'}
          alt="image"></Image>
      );
    }
    if (resourceType === 'AUTOMATION') {
      return (
        <Image
          style={{height: 20, width: 20}}
          source={require('../../../assets/images/CRMPipeline/automation.png')}
          size={'32px'}
          alt="image"></Image>
      );
    }
    if (resourceType === 'CALL') {
      return (
        <Image
          style={{height: 20, width: 20}}
          source={require('../../../assets/images/CRMPipeline/call.png')}
          size={'32px'}
          alt="image"></Image>
      );
    }
    if (resourceType === 'FORM_RESPONSE') {
      return (
        <Image
          style={{height: 20, width: 20}}
          source={require('../../../assets/images/CRMPipeline/Form_Response.png')}
          size={'32px'}
          alt="image"></Image>
      );
    }
    if (resourceType === 'TASK') {
      return (
        <Image
          style={{height: 20, width: 20}}
          source={require('../../../assets/images/CRMPipeline/Task.png')}
          size={'32px'}
          alt="image"></Image>
      );
    }
    if (resourceType === 'VIDEO_CALL') {
      return (
        <Image
          style={{height: 20, width: 20}}
          source={require('../../../assets/images/MemberActions/video_call.png')}
          size={'32px'}
          alt="image"></Image>
      );
    }
    return (
      <Image
        style={{height: 20, width: 20}}
        source={require('../../../assets/images/ProspectPatientActions/addNote.png')}
        size={'32px'}
        alt="image"></Image>
    );
  }

  const getTextBySourceTypeCode = (sourceTypeCode: string, item?: IResourceMapDetail) => {
    switch(sourceTypeCode) {
      case RESOURCE_MAP.MESSAGE:
        return `Message sent`;
      case RESOURCE_MAP.TASK:
        return `Task created`;
      case RESOURCE_MAP.APPOINTMENT:
        return `APPOINTMENT booked`;
    }
  }

  const renderSingleOutreachCard = ({item, index}: any) => {
    const dateStr = getDateStrFromFormat(
      item?.createdOn,
      DATE_FORMATS.HOLIDAY_DATE_FORMAT,
    );
    const timeStampIndex = Date.now();
    return (
      <>
        {index === 0 && (
          <HStack
          key={`${timeStampIndex}_${index}`}
          style={{
            justifyContent: 'space-between',
            marginTop: 0,
          }}
        >
          <Text
            style={{
              fontSize: 14,
              lineHeight: 21,
              fontWeight: '600',
              color: 'rgb(102, 112, 133)',
              padding: 4,
              marginLeft: 7,
              marginTop:17,
              textTransform: 'uppercase',
              letterSpacing: 2,
              fontFamily: 'Manrope'
            }}
          >
            {getDateStrFromFormat(item?.createdOn, 'MMM YYYY')}
          </Text>
        </HStack>
        )}
        <VStack key={timeStampIndex}>
          <HStack flex={1} alignItems={'center'} minH={102}>
            <VStack marginX={2} justifyContent={'center'}>
              <View
                style={{
                  width: 2,
                  height: 32,
                  backgroundColor: Colors.Custom.Gray100,
                  alignSelf: 'center',
                  transform: [{rotate: '180deg'}],
                }}
              />
              <View
                {...testID(COMPONENT_TEST_IDENTIFIER.feedDataCollapseIcon)}
                style={{
                  borderRadius: 88,
                  padding: 10,
                  borderWidth: 1,
                  borderColor: Colors.Custom.Gray200,
                }}>
                {getIconName(item.sourceTypeCode)}
              </View>
            </VStack>
            <VStack
              flex={1}
              style={{marginTop: 20, paddingLeft: 10}}
              borderRadius={10}>
              <HStack display={'flex'} flexWrap={'wrap'} flex={1}>
                <HStack
                  alignItems={'flex-start'}
                  width={{
                    base: '100%',
                    sm: '100%',
                    md: '57.5%',
                    lg: '57.5%',
                    xl: '57.5%',
                  }}>
                  <Text
                    {...testID(
                      COMPONENT_TEST_IDENTIFIER.feedDataCollapsePanelHeader,
                    )}
                    style={{
                      color: Colors.Custom.Gray900,
                      fontWeight: '500',
                      fontFamily: 'Manrope',
                    }}
                    fontSize={14}
                    lineHeight={21}
                    paddingRight={2}
                    fontWeight={500}
                    numberOfLines={2}
                    textOverflow={'ellipsis'}>
                     {getTextBySourceTypeCode(item?.sourceTypeCode)}
                  </Text>
                  <Text
                    {...testID(COMPONENT_TEST_IDENTIFIER.feedDataCollapseDate)}
                    color="gray.400"
                    style={{
                      fontSize: 12,
                      lineHeight: 20,
                      fontWeight: '600',
                      letterSpacing: 1,
                      fontFamily: 'Manrope',
                    }}>
                    {dateStr}
                  </Text>
                </HStack>
              </HStack>
            </VStack>
          </HStack>
        </VStack>
      </>
    );
  };

  return (
    <Drawer
      destroyOnClose
      placement="right"
      open={true}
      closable
      width={
        isIPadScreen || isIPadMiniScreen ? '60%' : smallWindow ? '50%' : '40%'
      }
      onClose={() => {
        onClose(false);
      }}
      title={
        <ModalActionTitle
          title={'Outreach'}
          titleColor={''}
          buttonList={[
            {
              show: true,
              id: 1,
              btnText: 'cancel',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.SECONDARY,
              isTransBtn: false,
              isDisabled: false,
              onClick: () => {
                onClose(false);
              },
            },
          ]}
        />
      }>
      {loading && <Skeleton active />}
      {!loading && resourceMapList?.length > 0 && (
        <SafeAreaView>
          <FlatList
            marginBottom={10}
            {...testID(COMPONENT_TEST_IDENTIFIER.flatList)}
            data={resourceMapList}
            keyExtractor={(item, index) => {
              return `outreach_${item?.resourceId}_${index}`;
            }}
            renderItem={renderSingleOutreachCard}
          />
        </SafeAreaView>
      )}
      {!loading && !resourceMapList?.length && (
        <Text>Outreach not started yet</Text>
      )}
    </Drawer>
  );
};
