import { View, Text, HStack, FormControl, Pressable, VStack, Image } from 'native-base'
import React, { useState } from 'react'
import { useIntl } from 'react-intl';
import { Colors } from '../../../../styles';
import { TestIdentifiers, testID } from '../../../../testUtils';
import { camelCase } from 'lodash';

export interface ICustomLabelProps {
  label: string;
  isIntlLabel?: boolean;
  isRequired?: boolean;
  infoText?: string;
  labelColor?: string;
}

const CustomLabel = (props: ICustomLabelProps) => {
  const intl = useIntl();
  const {label, isIntlLabel, isRequired, infoText, labelColor} = props;
  const [showInfo, setShowInfo] = useState(false);
  const labelTestId = camelCase(label);

  return (
    <VStack space={2}>
      <HStack>
        <FormControl.Label isRequired={isRequired}>
          <Text
            flexWrap={'wrap'}
            fontSize={'14'}
            fontWeight={'400'}
            color={labelColor || Colors.Custom.Gray500}
            {...testID(`${labelTestId}Label`)}
          >
            {isIntlLabel ? intl.formatMessage({id: label}) : label}
          </Text>
        </FormControl.Label>
        {!!infoText && (
          <Pressable onPress={() => setShowInfo(!showInfo)} marginTop={1} marginLeft={-1} {...testID(`${labelTestId}InfoIcon`)}>
            <Image
              width={'4'}
              height={'4'}
              source={require('../../../../assets/images/MessageActionImages/info.png')}
              alt="info"
            />
          </Pressable>
        )}
      </HStack>
      {!!infoText && showInfo && (
        <View
          backgroundColor={Colors.Custom.Gray700}
          paddingX={2}
          paddingY={2}
          borderRadius={6}
          marginTop={-2}
          marginBottom={1}>
          <Text fontWeight={500} fontSize={14} color={Colors.Custom.FontColorWhite} {...testID(`${labelTestId}InfoText`)}>{infoText}</Text>
        </View>
      )}
    </VStack>
  )
}

export default CustomLabel;
