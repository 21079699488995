import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  basicContainer: {
    display: 'flex',
    padding: 10,
    marginTop: 10,
    marginBottom: 10,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  formControlView: {
    margin: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  addButton: {
    margin: 5,
    width: 'fit-content',
  },
});
