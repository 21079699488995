import React from 'react';
import { Svg, Path } from 'react-native-svg';

const ObservationIconSvg = () => {
  return (
    <Svg
      width="18"
      height="16"
      fill="none"
      viewBox="0 0 22 18"
    >
      <Path
        fill="#6F7A90"
        d="M19.725 5.704l-.396.305.396-.305zm0 6.592l-.396-.305.396.305zM21 9h-.5.5zM2.275 5.704l.396.305-.396-.305zm0 6.592l.396-.305-.396.305zm.396-6.287C4.323 3.863 7.04 1.5 11 1.5v-1C6.597.5 3.62 3.136 1.879 5.4l.792.61zM11 1.5c3.96 0 6.677 2.363 8.329 4.51l.792-.61C18.38 3.135 15.403.5 11 .5v1zm8.329 10.49C17.677 14.138 14.96 16.5 11 16.5v1c4.403 0 7.38-2.636 9.121-4.9l-.792-.61zM11 16.5c-3.96 0-6.677-2.363-8.329-4.51l-.792.61C3.62 14.865 6.597 17.5 11 17.5v-1zm8.329-10.49c.434.563.711.928.895 1.344.176.398.276.872.276 1.646h1c0-.865-.113-1.487-.361-2.05-.242-.547-.602-1.01-1.018-1.55l-.792.61zm.792 6.59c.416-.54.776-1.003 1.018-1.55.248-.563.361-1.185.361-2.05h-1c0 .774-.1 1.248-.276 1.646-.184.416-.461.78-.895 1.345l.792.61zM1.88 5.4c-.416.54-.776 1.003-1.018 1.55C.613 7.512.5 8.134.5 9h1c0-.774.1-1.248.276-1.646.184-.416.461-.78.895-1.345L1.88 5.4zm.792 6.59c-.434-.563-.711-.928-.895-1.344C1.6 10.248 1.5 9.774 1.5 9h-1c0 .865.113 1.487.361 2.05.242.547.602 1.01 1.018 1.55l.792-.61zM13.5 9a2.5 2.5 0 01-2.5 2.5v1A3.5 3.5 0 0014.5 9h-1zM11 11.5A2.5 2.5 0 018.5 9h-1a3.5 3.5 0 003.5 3.5v-1zM8.5 9A2.5 2.5 0 0111 6.5v-1A3.5 3.5 0 007.5 9h1zM11 6.5A2.5 2.5 0 0113.5 9h1A3.5 3.5 0 0011 5.5v1z"
      ></Path>
    </Svg>
  );
};


export default ObservationIconSvg;
