import React from 'react';
import {Colors} from '../../../../styles';
import {ICommonSvgProps} from '../interfaces';

const SuggestionsSvg = (props: ICommonSvgProps) => {
  const {isSelected} = props;
  const strokeColor = isSelected
    ? Colors.primary['500']
    : props.isPrimary
    ? Colors.primary['300']
    : '#fff';
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.85714 17.7143L7.71429 13L3 10.8571L7.71429 8.71429L9.85714 4L12 8.71429L16.7143 10.8571L12 13L9.85714 17.7143ZM17.5714 21.1429L16.5 18.7857L14.1429 17.7143L16.5 16.6429L17.5714 14.2857L18.6429 16.6429L21 17.7143L18.6429 18.7857L17.5714 21.1429Z"
        fill="#FBEED8"
        stroke="black"
        stroke-width="1.2"
      />
    </svg>
  );
};

export default SuggestionsSvg;
