export const defaultThemeColors = {
  colorConfig: {
    tertiary: {
      '50': '#ECFDF5',
      '100': '#D1FAE5',
      '200': '#A7F3D0',
      '300': '#6EE7B7',
      '400': '#34D399',
      '500': '#10B981',
      '600': '#059669',
      '700': '#047857',
      '800': '#065F46',
      '900': '#064E3B',
    },
    secondary: {
      '50': '#FDF2F8',
      '100': '#FCE7F3',
      '200': '#FBCFE8',
      '300': '#F9A8D4',
      '400': '#F472B6',
      '500': '#EC4899',
      '600': '#DB2777',
      '700': '#BE185D',
      '800': '#BE185D',
      '900': '#831843',
    },
    muted: {
      '50': '#FAFAFA',
      '100': '#F5F5F5',
      '200': '#E5E5E5',
      '300': '#D4D4D4',
      '400': '#A3A3A3',
      '500': '#737373',
      '600': '#525252',
      '700': '#404040',
      '800': '#262626',
      '900': '#171717',
    },
    error: {
      '50': '#FEF3F2',
      '100': '#FEE4E2',
      '200': '#FFCDCA',
      '300': '#FDA29B',
      '400': '#FA7066',
      '500': '#F04438',
      '600': '#D92D20',
      '700': '#B42318',
      '800': '#912018',
      '900': '#7A271A',
    },
    success: {
      '50': '#ECFDF3',
      '100': '#D1FADF',
      '200': '#A6F4C5',
      '300': '#6CE9A6',
      '400': '#32D583',
      '500': '#12B76A',
      '600': '#039855',
      '700': '#027A48',
      '800': '#05603A',
      '900': '#054F31',
    },
    primary: {
      '100': '#F4EBFF',
      '200': '#E9D7FE',
      '300': '#D6BBFB',
      '400': '#B692F6',
      '450':'#9C7CD3',
      '500': '#9E77ED',
      '600': '#7F56D9',
      '700': '#6941C6',
      '800': '#53389E',
      '900': '#42307D',
    },
    warning: {
      '50': '#FFFAEB',
      '100': '#FEF0C7',
      '200': '#FEDF89',
      '300': '#FEC84B',
      '400': '#FDB022',
      '600': '#DC6803',
      '700': '#B54708',
      '800': '#93370D',
      '900': '#7A2E0E',
    },
    danger: {
      '50': '#FFF1F2',
      '100': '#FFE4E6',
      '200': '#FECDD3',
      '300': '#FDA4AF',
      '400': '#FB7185',
      '500': '#F43F5E',
      '600': '#E11D48',
      '700': '#BE123C',
      '800': '#9F1239',
      '900': '#881337',
    },
    light: {
      '50': '#FAFAF9',
      '100': '#F5F5F4',
      '200': '#E7E5E4',
      '300': '#D6D3D1',
      '400': '#A8A29E',
      '500': '#78716C',
      '600': '#57534E',
      '700': '#44403C',
      '800': '#292524',
      '900': '#1C1917',
    },
    info: {
      '50': '#F0F9FF',
      '100': '#E0F2FE',
      '200': '#BAE6FD',
      '300': '#7DD3FC',
      '400': '#38BDF8',
      '500': '#0EA5E9',
      '600': '#0284C7',
      '700': '#0369A1',
      '800': '#075985',
      '900': '#0C4A6E',
    },
  },
  themeName: 'Default',
};
