import {Badge, Box, HStack, Text, VStack, View} from 'native-base';
import React, {useContext, useEffect, useState} from "react";
import { Platform} from 'react-native';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { GROUP_MEMBER_TYPE, MLOV_CATEGORY} from '../../../../../../constants';
import {CommonDataContext} from '../../../../../../context/CommonDataContext';
import {Colors} from '../../../../../../styles';
import {getPrimaryGenderCode, getUserId, getUserName, getUserUUID, isChannelTwillioSms} from '../../../../../../utils/commonUtils';
import {getChatDisplayTimeAgo, getMessageDisplayTimeAgoFullDate} from '../../../../../../utils/DateUtils';
import {getMlovCodeFromId, getMlovCodeIdObj} from '../../../../../../utils/mlovUtils';
import {isWeb} from '../../../../../../utils/platformCheckUtils';
import {capitalizeText} from '../../../../../common/ContactRelationView/ContactRelationUtils';
import {DisplayText} from '../../../../../common/DisplayText/DisplayText';
import {PatientInfoBanner} from '../../../../../PatientInfoBanner/PatientInfoBanner';
import {CHANNEL_TYPE, CHANNEL_TYPE_CODE, CONVERSATION_SUBTAB_CODES} from '../../ConversationConst';
import {
  IContact,
  IConversationListItemProps,
  tabTypeString
} from '../../interfaces';
import {getUserIdListFromMsgContent} from '../../MessagingWindow/ConversationMentions/ConversationMentions';
import {MESSAGE_CUSTOM_CARD_KEY, MESSAGE_CUSTOM_CARD_TYPE} from '../../MessagingWindow/MessagingCustomCard/CustomCardConst';
import {
  canShowAssignment,
  getContactDataAndType,
  getConversationMentionTabData,
  getPatientAgeAndGenderStr,
  getPatientDataFromGroupMember,
  getPatientOrProspectStr,
  isBroadCastAndPrivateGroup,
  isGroupConversation,
  isJsonString,
  isPrivateGroupConversation
} from '../../MessagingWindow/MessagingUtils';
import {TimeType} from '../ConversationConst';
import {styles} from '../MessagingListStyles';
import {getRemovedConversationIndicatorStr, getRemovedConversationIndicatorStrStyle, getSmsConversationLastCallTypeCode} from './ConversationListItemUtils';
import {getAccountConfigResourceAbilities, getEhrConfig} from '../../../../../../utils/capabilityUtils';
import {FHIR_RESOURCE} from '../../../../../../constants/FhirConstant';
import {getPatientDetailsFromHint} from '../../../../../../services/CommonService/AidBoxService';
import { TestIdentifiers, testID } from '../../../../../../testUtils';
import { useIntl } from 'react-intl';
import { getCallStatus } from '../../../CallLogs/CallLogsUtils';
import { IMessageContentParse } from '../../../../../common/ChatUI/MessageBox/MessagingCustomCard/CallLogCard/interface';
import MsgHighlighter from './MsgHighlighter';
import {useContactLocationUuid} from '../../../../../common/MemebersView/customHook/useContactLocationUuid';
import {CallDirections, TWILIO_CALL_STATUS} from '../../../CallLogs/CallLogsConst';
import {GetConversationTypeAvatarV2} from '../../MessagingWindow/GetConversationTypeAvatarV2';

export const ConversationListItem = React.memo((props: IConversationListItemProps) => {
  const {
    singleConversationData,
    searchString,
    parentCode,
    displayTimeType,
    allInboxList,
    selectedInboxTypeCode,
    showFoldStatus,
    enableConversationSelection,
    isSelected,
    selectedSubtabCode
  } = props;
  const intl = useIntl();

  const messageDataObj = singleConversationData.conversationMessages;
  const userUuid = getUserUUID();
  const myUserId = getUserId();
  const userName = getUserName();
  //const {width} = Dimensions.get('window');
  const commonData = useContext(CommonDataContext);
  const userList = commonData?.accountUserList;
  const sipNumberList = commonData?.sipNumberList;
  const mlovData = commonData.MLOV;
  const groupMemberTypeList = mlovData[MLOV_CATEGORY.GroupMemberType];
  const groupMemberCodeIdObj = getMlovCodeIdObj(groupMemberTypeList);
  const conversationConfig = {
    tabTypeCode:
      selectedInboxTypeCode ===
      CHANNEL_TYPE_CODE.CHANNEL_MENTION
        ? tabTypeString.CONVERSATION_MENTIONS
        : '',
    tabData: getConversationMentionTabData(
      selectedInboxTypeCode || '',
      singleConversationData
    ),
    selectedInboxTypeCode: selectedInboxTypeCode
  }
  const callLastActivityCode =
    selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_ALL ||
      selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MISSED_CALLS ||
      selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_ARCHIVED ||
      selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_ME ||
      selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION ||
      selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_UNASSIGNED ||
      selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS ||
      selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_ASSIGNED_OTHER ||
      selectedInboxTypeCode === CHANNEL_TYPE_CODE.ALL_CALLS
      ? getSmsConversationLastCallTypeCode(singleConversationData)
      : '';
  const isShowInboxName = false;
  const isDisplayCallMsgHeader = singleConversationData && (
    (singleConversationData?.conversationMessages?.[0]?.messageType === MESSAGE_CUSTOM_CARD_TYPE.INCOMING_COMPLETED_CALL_CARD) ||
    (singleConversationData?.conversationMessages?.[0]?.messageType === MESSAGE_CUSTOM_CARD_TYPE.OUTGOING_COMPLETED_CALL_CARD) ||
    (singleConversationData?.conversationMessages?.[0]?.messageType === MESSAGE_CUSTOM_CARD_TYPE.INCOMING_MISSED_CALL_CARD) ||
    (singleConversationData?.conversationMessages?.[0]?.messageType === MESSAGE_CUSTOM_CARD_TYPE.OUTGOING_MISSED_CALL_CARD))
  const isSidecarContext = commonData?.sidecarContext?.isSidecar;

  const groupTypeList = commonData.MLOV[MLOV_CATEGORY.GroupType];
  const groupTypeCode = getMlovCodeFromId(
    groupTypeList,
    singleConversationData?.groupConversation?.groupTypeId || ''
  );
  const contactInfo: any = getContactDataAndType(singleConversationData);
  const contactData: IContact = contactInfo.contactData;

  const [hintPatientDetails, setHintPatientDetails] = useState({
    status: '',
    loading: false,
    locationId: contactData?.contactPracticeLocations?.[0]?.accountLocation?.uuid
  });
  const hintCapability = getAccountConfigResourceAbilities(FHIR_RESOURCE.HINT);
  const openInHint = hintCapability?.openInHint || false;
  const ehrConfig = getEhrConfig('', '');

  const {fetchContactLocationUuid} = useContactLocationUuid(contactData?.id, true);
  const getContactLocationId = async (contactId: number) => {
    const response = await fetchContactLocationUuid(contactId);
    setHintPatientDetails((prev) => {
      return {
        ...prev,
        locationId: response,
      };
    });
  };


  const getLastMessageTimeStr = (dateStr: string): string => {
    let convertedDateStr = '';
    if (dateStr && (dateStr.includes('Z') || dateStr.includes('z'))) {
      convertedDateStr = (displayTimeType && displayTimeType === TimeType.timeAgoFullDate) ? getMessageDisplayTimeAgoFullDate(dateStr) : getChatDisplayTimeAgo(dateStr);
    } else if (dateStr) {
      dateStr = dateStr + 'Z';
      convertedDateStr = (displayTimeType && displayTimeType === TimeType.timeAgoFullDate) ? getMessageDisplayTimeAgoFullDate(dateStr) : getChatDisplayTimeAgo(dateStr);
    }
    return convertedDateStr;
  };

  const getLastMessageDateTime = () => {
    if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.ALL_CALLS) {
      const callContent: IMessageContentParse = isJsonString(
        messageDataObj?.[0]?.content || ''
      )
        ? JSON.parse(messageDataObj?.[0]?.content || '')
        : {};
      if (callContent?.resourceData?.dateTime) {
        return callContent?.resourceData?.dateTime;
      }
    }
    if (
      selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_EMAIL &&
      selectedSubtabCode == CONVERSATION_SUBTAB_CODES.SENT &&
      singleConversationData?.lastOutgoingEmailAt
    ) {
      return singleConversationData?.lastOutgoingEmailAt || '';
    }
    if (
      selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_E_FAX &&
      selectedSubtabCode == CONVERSATION_SUBTAB_CODES.SENT &&
      singleConversationData?.lastOutgoingEFaxAt
    ) {
      return singleConversationData?.lastOutgoingEFaxAt || '';
    }
    if (
      selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MISSED_CALLS &&
      singleConversationData?.lastMissCallAt
    ) {
      return singleConversationData?.lastMissCallAt;
    } else if (
      messageDataObj?.[0]?.updatedAt &&
      messageDataObj?.[0]?.updatedAt.length
    ) {
      return messageDataObj?.[0]?.updatedAt;
    } else {
      return singleConversationData?.createdAt || '';
    }
  };

  

  const getCallStatusAndCallerName = (callContent: IMessageContentParse) => {
    const userName = userList?.find((user) => user?.uuid === callContent.resourceData?.userUUID)?.name;
    const phoneName = sipNumberList?.find((sip) => sip?.id === callContent.resourceData?.sipPhoneId)?.phoneName;
    const isIncoming = callContent.resourceData?.direction == CallDirections.Incoming && callContent.resourceData?.status == TWILIO_CALL_STATUS.COMPLETED;
    const inOutgoing = callContent.resourceData?.direction === CallDirections.Outgoing;
    if ((userName || phoneName) && (isIncoming || inOutgoing)) {
      return `${userName || phoneName} ${inOutgoing ? 'Called' : 'Answered'}`;
    }
    return getCallStatus(callContent.resourceData)
  }

  const getMsgContentString = () => {
    //const userName = getLastMessageUserName();
    const content: string[] = [];
    if (
      singleConversationData &&
      singleConversationData?.conversationMessages?.[0]?.content
    ) {
      const lastConversationMessage = singleConversationData?.conversationMessages?.[0];
      if (
        lastConversationMessage?.contentType ===
        MESSAGE_CUSTOM_CARD_TYPE.CONTACT_CARD ||
        lastConversationMessage?.contentType ===
        MESSAGE_CUSTOM_CARD_KEY.CUSTOM_CARD
      ) {
        content.push('Custom card');
      } else if (isDisplayCallMsgHeader) {
        const callContent: IMessageContentParse = isJsonString(lastConversationMessage?.content || '') ? JSON.parse(lastConversationMessage?.content || '') : {}
        content.push(getCallStatusAndCallerName(callContent) || 'Call');
      } else if (checkIfMentionedAnyOne()) {
        if (conversationConfig?.tabTypeCode === tabTypeString.CONVERSATION_MENTIONS) {
          content.push(
            lastConversationMessage?.senderUser?.name + ' mentioned you'
          );
        } else {
          let mentionedBy = '';
          let mentionedTo = '';
          const mentionedToList: any = lastConversationMessage?.content?.split('[@')?.[1]?.split(']')
          if (singleConversationData?.conversationMessages?.[0]?.senderUser?.id === myUserId) {
            mentionedBy = 'You mentioned'
          } else {
            const mentionedByUserName =
              lastConversationMessage?.senderType?.toLowerCase() == 'user'
                ? lastConversationMessage?.senderUser?.name
                : lastConversationMessage?.senderContact?.name;
            mentionedBy = mentionedByUserName + ' mentioned';
          }
          if (checkIfMentionedMe()) {
            mentionedTo = 'You'
          } else if (mentionedToList?.length) {
            mentionedTo = mentionedToList?.[0]
          }
          content.push(
            mentionedBy + ' ' + mentionedTo
          );
        }
      } else {
        const data = getRemovedConversationIndicatorStr(
          singleConversationData,
          selectedInboxTypeCode,
          myUserId,
        );
        content.push(data);
      }
    }
    const msgStr = content.join(' : ');
    return `${msgStr?.trim()?.length ? msgStr : 'No messages'}`
  };

  const checkIfMentionedAnyOne = () => {
    if (groupMemberCodeIdObj && Object.keys(groupMemberCodeIdObj)?.length && singleConversationData?.conversationMessages?.[0]?.content) {
      const mentionUserLists = getUserIdListFromMsgContent(singleConversationData?.conversationMessages?.[0]?.content || '', groupMemberCodeIdObj);
      if (mentionUserLists?.length) {
        return true;
      }
    }
    return false;
  }

  const checkIfMentionedMe = () => {
    if (groupMemberCodeIdObj && Object.keys(groupMemberCodeIdObj)?.length && singleConversationData?.conversationMessages?.[0]?.content) {
      const mentionUserLists = getUserIdListFromMsgContent(singleConversationData?.conversationMessages?.[0]?.content || '', groupMemberCodeIdObj);
      if (mentionUserLists?.includes(userUuid)) {
        return true;
      }
    }
    return false;
  }

  const getMentionTooltipMessage = () => {
    if (checkIfMentionedMe()) {
      const extractMsg = singleConversationData?.conversationMessages?.[0]?.content?.split(')') || '';
      const finalLastMentionedMsg = '@' + userName + ' ' + extractMsg?.[extractMsg?.length - 1]
      return finalLastMentionedMsg;
    }
  }

  const getIconsForLastMessages = () => {
    if ((conversationConfig?.tabTypeCode === tabTypeString.CONVERSATION_MENTIONS && conversationConfig.tabData.conversationMentionId == singleConversationData?.conversationMentionId) || checkIfMentionedMe()) {
      return (
        <Text
          fontSize={16}
          style={styles.mentionIcon}
          color={
            !conversationConfig?.tabData?.isRead ? Colors.Custom.brandAccentColor : Colors.Custom.Gray500
          }
        >
          {'@'}
        </Text>
      )
    }
    return <></>
  }

  const getInboxUserName = () => {
    let userName = '';
    if (
      allInboxList &&
      allInboxList[CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS]?.length > 1
    ) {
      userName = singleConversationData?.conversationInbox?.name + '(' + singleConversationData?.conversationInbox?.channelTwilioSms?.phoneNumber + ')'
    }
    return userName;
  }

  const getMsgContentElem = (): JSX.Element | boolean => {
    let msgContentElem = <></>;
    let isValidText = false;

    if (singleConversationData?.conversationMessages?.[0]?.content) {
      isValidText = true;
      msgContentElem = (
        <Text
          size={Platform.OS === 'web' ? 'mdNormal' : 'md'}
          noOfLines={1}
          {...testID(TestIdentifiers.messageContent)}
          style={[
            getRemovedConversationIndicatorStrStyle(
              singleConversationData,
              selectedInboxTypeCode || '',
              myUserId,
            ),
          ]}
        >
          {getMsgContentString()}
        </Text>
      );
    } else if (singleConversationData?.conversationMessages?.[0]?.messageAttachments?.length) {
      msgContentElem = getAttachmentMsgContentElem();
      isValidText = true;
    } else {
      msgContentElem = (
        <Text size={Platform.OS === 'web' ? 'mdNormal' : 'md'} noOfLines={1} {...testID(TestIdentifiers.messageContent)}>
          {intl.formatMessage({id: channelType === CHANNEL_TYPE.CHANNEL_EFAX ? 'attachments' : 'noMessages' })}
        </Text>
      );
      isValidText = true;
    }

    if (singleConversationData?.conversationInbox?.channelType === CHANNEL_TYPE.CHANNEL_EMAIL) {
      const emailSubject =
        singleConversationData?.additionalAttributes?.mail_subject ||
        singleConversationData?.conversationMessages?.[0]?.contentAttributes
          ?.subject ||
        singleConversationData?.subject ||
        '';
      // const finalMsgDisplayStr = getRemovedConversationIndicatorStr(singleConversationData, selectedInboxTypeCode, myUserId);
      msgContentElem = (
        <Text
          size={Platform.OS === 'web' ? 'mdNormal' : 'md'}
          noOfLines={1}
          {...testID(TestIdentifiers.messageContent)}
          style={[
            getRemovedConversationIndicatorStrStyle(
              singleConversationData,
              selectedInboxTypeCode || '',
              myUserId,
            ),
          ]}
        >
           <MsgHighlighter
              searchString={searchString || ''}
              contentString={emailSubject || intl.formatMessage({id: 'noMessages' }) || ''}
            />
        </Text>
      );

    }
    const ele = isValidText ? msgContentElem : isValidText;
    return ele;
  };

  const getAttachmentMsgContentElem = () => {
    const attachmentContentElem = (
      <>
        <AntIcon
          name="file1"
          size={14}
          style={styles.mentionIcon}
        />
        <DisplayText
          textLocalId="fileMessage"
          testID='fileMessage'
          size={
            !isWeb()
              ? 'sm'
              : singleConversationData?.unreadMsgCount
                ? 'smSemibold'
                : 'smLight'
          }
        />
      </>
    );
    return attachmentContentElem;
  };

  const getPatientOrProspectElem = (): string => {
    const tempSingleConversationData = singleConversationData;
    // if (singleConversationData?.id === selectedConversationId) {
    //   // tempSingleConversationData = selectedConversation;
    // }
    const patientStr = getPatientOrProspectStr(
      tempSingleConversationData,
      parentCode + '',
      userUuid
    );
    return patientStr;
  };
  const groupName = getPatientOrProspectElem();
  const genderCode = getPrimaryGenderCode(contactData);

  const getSubHeaderElem = (): {
    showSubtitleHighlighter?: boolean;
    subtitleString?: string;
  } => {
    const contactTypeStr = getPatientAgeAndGenderStr(singleConversationData, ehrConfig);

    if (isGroupConversation(singleConversationData)) {
      if (isPrivateGroupConversation(singleConversationData)) {
        return {
          showSubtitleHighlighter: true,
          subtitleString: contactTypeStr,
        };
      } else {
        const detail: string[] = [];
        const groupName = singleConversationData?.groupConversation?.name;
        if (groupName.length) {
          detail.push(groupName);
        }
        const groupMemberCount =
          singleConversationData?.groupConversation?.groupMembers?.length;

        if (groupMemberCount) {
          const str = `${groupMemberCount}`;
          detail.push(str + `${groupMemberCount > 1 ? ' members' : 'member'}`);
        }

        return {
          showSubtitleHighlighter: false,
          subtitleString: detail.join(' | '),
        };
      }
    } else {
      return {
        showSubtitleHighlighter: false,
        subtitleString: contactTypeStr,
      };
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const channelType = singleConversationData?.conversationInbox?.channelType || '';
  // getConversationTypeImgSrc(singleConversationData) ||
  // require('../../../../../../assets/images/message.png');
  const assignUserName = singleConversationData?.assignedUser?.name

  const isShowAssignedIcon = canShowAssignment(singleConversationData);

  const canShowFoldStatus = () => {
    if (
      !showFoldStatus ||
      isWeb() ||
      isBroadCastAndPrivateGroup(singleConversationData)
    ) {
      return false;
    }
    if (contactData?.contactType?.contactType?.code) {
      return contactData?.contactType?.contactType?.code === 'CUSTOMER';
    } else {
      const patientData = getPatientDataFromGroupMember(
        singleConversationData?.groupConversation?.groupMembers
      );
      if (patientData?.id) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    let isMounted = true;
    if (openInHint && canShowFoldStatus() && !isWeb()) {
      setHintPatientDetails((prev) => {
        return {
          ...prev,
          loading: true,
          status: '',
        };
      });
      getPatientDetailsFromHint(
        contactData?.id || '',
        hintPatientDetails?.locationId
      )
        .then((response) => {
          const data = response.data;
          if (isMounted) {
          setHintPatientDetails((prev) => {
            return {
              ...prev,
              status: data.membership_status || '',
              loading: false,
            };
          });
          }
        })
        .catch(() => {
          if (isMounted) {
          setHintPatientDetails((prev) => {
            return {
              ...prev,
              loading: false,
              status: '',
            };
          });
          }
        });
    return () => {
      isMounted = false;
    };
    }
  }, [contactData?.id, hintPatientDetails?.locationId]);

  const getPatientStatusComponent = () => {
    return (
      <View
        style={styles.patientStatusContainer}
        flexDirection={'row'}
        alignItems={'center'}
        marginLeft={57}
      >
        {openInHint ? (
          <>
            {!hintPatientDetails.loading && hintPatientDetails.status.length ? (
              <>
                <Text
                  size={'xsNormal'}
                  style={styles.patientStatusLabel}
                  {...testID('MembershipStatusLabel')}
                >
                  {'Membership status: '}
                </Text>
                <Text
                  size={'xsNormal'}
                  style={{
                    color:
                      hintPatientDetails.status == 'active'
                        ? Colors.success[500]
                        : Colors.error[500],
                  }}
                  {...testID('MembershipStatus')}
                >
                  {capitalizeText(hintPatientDetails.status)}
                </Text>
              </>
            ) : null}
          </>
        ) : (
          <>
            <Text
              size={'xsNormal'}
              style={styles.patientStatusLabel}
              {...testID('MembershipStatusLabel')}
            >
              {'Membership status: '}
            </Text>
            <Text
              size={'xsNormal'}
              color={
                contactData?.isActive ? Colors.success[500] : Colors.danger[500]
              }
              {...testID('MembershipStatus')}
            >
              {contactData?.isActive ? 'Active' : 'Inactive'}
            </Text>
          </>
        )}
      </View>
    );
  };


  const getUnreadBatchCountElem = () => {
    if (
      conversationConfig?.selectedInboxTypeCode !== CHANNEL_TYPE_CODE.CHANNEL_MENTION &&
      conversationConfig?.selectedInboxTypeCode !== CHANNEL_TYPE_CODE.ALL_CALLS &&
      singleConversationData?.unreadMsgCount
    ) {
      return (
        <Badge
          bgColor={
            isWeb() ? Colors.primary['300'] : Colors.primary['500']
          }
          borderRadius="50"
          variant="solid"
          rounded="full"
          _text={{
            fontSize: isWeb() ? 10 : 12,
            color: Colors.Custom.FontColorWhite,
          }}
          {...testID('UnreadCount')}
        >
          {singleConversationData?.unreadMsgCount}
        </Badge>
      );
    } else {
      return (
        <></>
      )
    }
  };

  return (
    <>
      {singleConversationData.id && (
        <Box
          key={props?.singleConversationData?.id}
          style={[
            isWeb()
              ? styles.conversationListItem
              : styles.conversationListItemNative,
            {paddingLeft: isWeb() ? 0 : 16},
            {paddingVertical: 6},
          ]}
        >
          <HStack flex={1}>
            <VStack flex={1}>
              <HStack paddingX={isSidecarContext ? 3 : isWeb() ? 2 : 0} justifyContent={'space-between'}>
                <VStack flex={isWeb() ? 0.78 : 0.8}>
                  <HStack flex={1} alignItems={'center'}>
                    <PatientInfoBanner
                      isLetterAvatarShow={true}
                      name={groupName ? groupName : contactData?.name}
                      assignUserName={assignUserName}
                      isShowAssignedIcon={isShowAssignedIcon}
                      bannerType="listViewBanner"
                      isSelected={
                        isSelected
                      }
                      headerString={getPatientOrProspectElem()}
                      groupType={
                        singleConversationData?.groupConversation?.groupType
                          ?.code as any || groupTypeCode
                      }
                      genderCode={genderCode as any}
                      userId={contactData?.id}
                      userType={GROUP_MEMBER_TYPE.CONTACT}
                      contactData={contactData}
                      //subtitleString={getSubHeaderElem()?.subtitleString}
                      shouldShowSubtitleHighlighter={
                        getSubHeaderElem()?.showSubtitleHighlighter
                      }
                      groupName={groupName}
                      searchString={searchString}
                      getConversationTypeAvatar={
                        <GetConversationTypeAvatarV2
                          channelType={
                            singleConversationData?.conversationInbox
                              ?.channelType
                          }
                          enableConversationSelection={
                            enableConversationSelection
                          }
                          callLastActivityCode={callLastActivityCode}
                          selectedInboxTypeCode={selectedInboxTypeCode}
                          groupTypeCode={
                            singleConversationData?.groupConversation?.groupType
                              ?.code
                          }
                        />
                      }
                      showCustomAvatar={true}
                      lastMessageElement={
                        <>
                          <VStack>
                            {isShowInboxName &&
                              isChannelTwillioSms(channelType) &&
                              getInboxUserName().length && (
                                <Text
                                  noOfLines={1}
                                  style={styles.inboxName}
                                  fontSize={12}
                                  size={
                                    !isWeb()
                                      ? 'sm'
                                      : singleConversationData?.unreadMsgCount
                                        ? 'smSemibold'
                                        : 'smNormal'
                                  }

                                  
                                  color={
                                    isSelected
                                        ? Colors.Custom.Gray900
                                        : Colors.Custom.Gray500
                                  }
                                  {...testID('InboxName')}
                                >
                                  {capitalizeText(getInboxUserName())}
                                </Text>
                              )}
                            {getMsgContentElem() ? (
                              <HStack
                                alignItems={'center'}
                                //space={1}
                                style={styles.lastMessageContent}
                              >
                                {getIconsForLastMessages()}
                                  <Text
                                    noOfLines={1}
                                    style={styles.text3}
                                    size={
                                      !isWeb()
                                        ? 'sm'
                                        : checkIfMentionedMe()
                                          ? 'smSemibold'
                                          : singleConversationData?.unreadMsgCount
                                            ? 'smSemibold'
                                            : 'smNormal'
                                    }
                                    color={
                                      isSelected
                                          ? Colors.Custom.Gray900
                                          : Colors.Custom.Gray500
                                    }
                                    {...testID(TestIdentifiers.messageContent)}
                                  >
                                    {getMsgContentElem()}
                                  </Text>
                              </HStack>
                            ) : (
                              <></>
                            )}
                          </VStack>
                        </>
                      }
                      isSidecarView={isSidecarContext}
                      hideOnlineIndicator={props?.hideOnlineIndicator}
                    />
                  </HStack>
                  {canShowFoldStatus() && (
                    <View>{getPatientStatusComponent()}</View>
                  )}
                </VStack>
                <VStack
                  flex={isWeb() ? 0.25 : 0.2}
                  alignItems={'flex-end'}
                  justifyContent={'space-between'}
                >
                    <Text
                      maxW={(isWeb() && !isSidecarContext) ? props?.conversationListWidth : null}
                      noOfLines={1}
                      size={isWeb() ? 'smNormal' : 'xsNormal'}
                      style={styles.text2}
                      {...testID('LastMessageTime')}
                    >
                      {getLastMessageTimeStr(getLastMessageDateTime())}
                    </Text>
                  {getUnreadBatchCountElem()}
                </VStack>
              </HStack>
            </VStack>
          </HStack>
        </Box>
      )}
    </>
  );
});
