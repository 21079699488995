import {useLazyQuery} from '@apollo/client';
import {AutoComplete, Spin} from 'antd';
import {debounce} from 'lodash';
import {HStack, Text, View} from 'native-base';
import {useEffect, useState} from 'react';
import {Pressable} from 'react-native';
import {default as AntIcon} from 'react-native-vector-icons/AntDesign';
import {COMMON_ACTION_CODES} from '../../../constants/ActionConst';
import {IAutoComplete} from '../../../Interfaces';
import {ZipCodeQueries} from '../../../services';
import {ModalActionAutoComplete} from '../ModalActionCommonComponent/ModalActionAutoComplete';
import {AddZipCode} from './AddZipCode';
import {IZipCodeData, IZipCodeVar} from './interfaces';

const {Option} = AutoComplete;

const ZipCodeAutoComplete = (props: IAutoComplete) => {
  const [zipCodeData, setZipCodeData] = useState<IZipCodeData[]>([]);
  const [getZipCodes] = useLazyQuery<{zipcodes: IZipCodeData[]}, IZipCodeVar>(
    ZipCodeQueries.getZipCodeBySearchString,
    {
      context: props.apolloContext,
    }
  );
  const {isInvalid, errors, errorText, showAdd} = props;
  const [loadingZipCodes, setLoadingZipCodes] = useState(false);
  const [stateData, setStateData] = useState({
    showAddZip: false,
    addZipCodeDrawer: false,
    zipCode: '',
  });

  const searchZipCode = (searchString: string) => {
    if (searchString.length) {
      setLoadingZipCodes(true);
      setZipCodeData([]);
      getZipCodes({
        variables: {
          searchString: searchString + '%',
          limit: 50,
        },
      })
        .then((zipCodeRespData) => {
          setZipCodeData(zipCodeRespData?.data?.zipcodes || []);
          setLoadingZipCodes(false);
          if (zipCodeRespData?.data?.zipcodes?.length) {
            setStateData((prev) => {
              return {
                ...prev,
                showAddZip: false,
              };
            });
          } else {
            setStateData((prev) => {
              return {
                ...prev,
                showAddZip: true,
                zipCode: searchString,
              };
            });
          }

          if (zipCodeRespData?.data?.zipcodes.length == 1) {
            props.onAutoCompleteChange(zipCodeRespData?.data?.zipcodes[0]);
            setZipCodeData([]);
          }
        })
        .catch((error) => {

          setZipCodeData([]);
          setLoadingZipCodes(false);
        });
    }
  };

  const onAddZipCodeActionPerformed = (
    actionCode: string,
    actionData?: any
  ) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.CANCEL:
        setStateData((prev) => {
          return {
            ...prev,
            addZipCodeDrawer: false,
          };
        });
        break;
      case COMMON_ACTION_CODES.SUCCESS:
        props.onAutoCompleteChange(actionData);
        props?.onValueChange?.(actionData?.zipCode);
        setStateData((prev) => {
          return {
            ...prev,
            addZipCodeDrawer: false,
          };
        });
        break;
    }
  };

  useEffect(()=> {
    if (props.name && props.sendZipCodeChange) {
      searchZipCode(props.name)
    }
  },[props.name])

  return (
    <>
      <ModalActionAutoComplete
        disabled={props.isTabDisabled}
        value={props.name}
        allowClear={true}
        isRequired={props.isRequired !== undefined ? props.isRequired : true}
        changeBackground={props.changeBackground}
        label={'zipCode'}
        isInvalid={isInvalid}
        errors={errors}
        errorText={errorText}
        defaultValue={props.name}
        placeholder="Search Zipcode"
        onSelect={(value: any, options: any) => {
          const selectedZipCode =
            zipCodeData.find((zipCodeObj) => {
              return zipCodeObj.id === options?.key;
            }) || ({} as IZipCodeData);
          props.onAutoCompleteChange(selectedZipCode);
        }}
        onClear={() => {
          props?.onValueChange?.('');
          props.onAutoCompleteChange(null);
          setStateData((prev) => {
            return {
              ...prev,
              showAddZip: false,
            };
          });
        }}
        onSearch={debounce(searchZipCode, 500)}
        onChange={(value: any, options: any) => {
          if (!value) {
            props.onAutoCompleteChange(null);
          } else {
            const selectedZipCode =
              zipCodeData.find((zipCodeObj) => {
                return zipCodeObj.id === options?.key;
              }) || ({} as IZipCodeData);
            if (options?.key) {
              props.onAutoCompleteChange(selectedZipCode);
            }else{
              props.onAutoCompleteChange(null);
            }
          }
          props?.onValueChange?.(value);
        }}
        onBlur={() => {
          if (zipCodeData.length == 1) {
            props.onAutoCompleteChange(zipCodeData[0]);
          }
        }}
        customStyle={{flex: props.flex ? props.flex : 0.48,...props.customStyles}}
        notFoundContent={loadingZipCodes && <Spin size="small" />}
        data={zipCodeData}
        classStyle={props.isInvalid ? 'field-error' : ''}
        optionProps={{id: 'id', code: 'code', value: 'code'}}
        valueType={'Zipcode'}
        maxLength={5}
      />
      {stateData?.showAddZip && zipCodeData.length === 0 && (
        <View position={'absolute'} top={2} left={32}>
          <Pressable
            onPress={() => {
              setStateData((prev) => {
                return {
                  ...prev,
                  addZipCodeDrawer: true,
                };
              });
            }}
          >
            <HStack alignItems={'center'} justifyContent={'center'} space={1}>
              <Text color={'#F37232'} fontSize={12}>
                {'Zipcode not found, Add zipcode'}
              </Text>
              <AntIcon
                style={{
                  alignSelf: 'center',
                  marginTop: 4,
                }}
                name="plus"
                color={'#F37232'}
                size={14}
              />
            </HStack>
          </Pressable>
        </View>
      )}
      {stateData?.addZipCodeDrawer && showAdd && (
        <AddZipCode
          onAddZipCodeActionPerformed={onAddZipCodeActionPerformed}
          zipCode={stateData?.zipCode}
        />
      )}
    </>
  );
};

export default ZipCodeAutoComplete;
