import {StyleSheet} from 'react-native';
import {Colors} from '../../../../../styles';
export const styles = StyleSheet.create({
  mainContainer: {
    paddingHorizontal: 15,
    paddingVertical: 15,
  },
  btnContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 5,
  },
  popoverBtn: {
    borderRadius: 30,
    width: 'fit-content',
  },
  inputStyle: {
    backgroundColor: Colors.Custom.ContainerBGColor,
  },
  formContainer: {
    alignItems: 'center',
  },
  componentsView: {
    alignItems: 'center',
  },
  commonContainer: {
    marginBottom: 5,
    marginTop: 5,
    padding: 15,
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: 10,
  },
  rightContainer: {
    flex: 1,
    padding: 10,
  },
});
