import {useLazyQuery} from '@apollo/client';
import {Select, Spin} from 'antd';
import {debounce} from 'lodash';
import {Text} from 'native-base';
import React, {useEffect, useState} from 'react';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../constants/Configs';
import {ISearchFieldProps} from '../../../Interfaces';
import {MDMQueries} from '../../../services';
import { CaretDownOutlined, DownOutlined, UnderlineOutlined } from '@ant-design/icons';
import { Colors } from '../../../styles';

const LabSearch = (props: ISearchFieldProps) => {
  const {value, isShowError, onChange} = props;
  const [loading, setLoading] = useState(false);
  const [mdmData, setMdmData] = useState<any[]>([]);

  useEffect(() => {
    if (value && value.loinc && mdmData.length === 0) {
      const {loinc, longCommonName, shortName} = value;
      const name = shortName || longCommonName;
      setMdmData([{name, loinc, longCommonName, shortName}]);
    }
  }, []);

  const [searchMDM] = useLazyQuery(MDMQueries.GET_MDM_LAB_DATA, {
    fetchPolicy: 'no-cache',
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
    onCompleted: (response: any) => {
      if (response && response.mdmLabs && response.mdmLabs.length > 0) {
        const data = response.mdmLabs;
        setMdmData([...data]);
      } else {
        setMdmData([]);
      }
      setLoading(false);
    },
    onError: (error) => {

      setLoading(false);
    },
  });

  const onSearch = (searchString: string) => {
    if (searchString.length > 0) {
      setLoading(true);
      searchMDM({
        variables: {
          query: `%${searchString}%`,
        },
      });
    }
  };

  const getDataFromId = (id: any) => {
    const matchedData = mdmData.filter((item) => {
      return item.loinc === id;
    });
    if (matchedData.length > 0) {
      const {loinc, longCommonName, shortName} = matchedData[0];
      const name = shortName || longCommonName;
      return {name, loinc, longCommonName, shortName};
    }
  };

  return (
    <Select
      suffixIcon={
        props.isAutomationView?
        <CaretDownOutlined
          style={{
            color: Colors.FoldPixel.GRAY300
          }}
        /> :
        <DownOutlined/>
      }
      size="large"
      showSearch
      allowClear
      filterOption={false}
      value={value ? value.loinc : undefined}
      onSearch={debounce(onSearch, 500)}
      onChange={(value: any[], data: any) => {
        if (data && data.value) {
          onChange(getDataFromId(data.value));
        } else {
          onChange(undefined);
        }
      }}
      placeholder="Search Lab Test"
      loading={loading}
      notFoundContent={loading && <Spin size="small" />}
      style={{height: '40px', borderRadius: props.isAutomationView? 4 : undefined}}
      className={isShowError && !value ? 'pami-search field-error' : 'pami-search'}
    >
      {mdmData.map((lab, index) => {
        return (
          <Select.Option key={`${lab.loinc}_${index}`} value={lab.loinc}>
            <Text>
              {lab.longCommonName}
              <Text fontSize="xs" color="gray.500">
                {'\n'}
                {lab.shortName}
              </Text>
            </Text>
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default LabSearch;
