import {StyleSheet} from 'react-native';
import {Colors} from '../../../styles';
export const styles = StyleSheet.create({
  mainContainer: {
    marginBottom: 8,
    paddingTop: 8,
    paddingRight: 8,
    paddingBottom: 8,
    paddingLeft: 8,
    fontSize: 12,
    justifyContent: 'space-between',
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 4px 0px',
    backgroundColor: Colors.Custom.ContainerBGColor,
  },
  mainBoxContainer: {
    boxShadow: 'rgb(0 0 0 / 20%) 0px 0px 4px 0px',
    padding: 10,
    overflow: 'hidden',
    backgroundColor: '#fff',
    shadow: 1,
    maxWidth: 300,
  },
  contentContainer: {
    padding: 2,
    marginRight: 5,
  },
  heading: {
    wordBreak: 'break-word',
    marginTop: 5,
    marginRight: 10,
    marginBottom: 5,
    width: 'auto',
    height: 'auto',
    fontSize: 14,
    fontWeight: '500',
  },
  avatarStyle: {
    borderRadius: 50,
    borderColor: Colors.Custom.Gray200,
    borderWidth: 1,
  },
  sideCarAvatarStyle: {
    borderColor: Colors.Custom.Gray200,
    borderWidth: 1,
  },
  displayListStyle: {
    marginTop: 8,
    marginBottom: 8,
  },
  displayListText: {
    fontSize: 12,
  },
  footerText: {
    fontSize: 11,
    color: Colors.Custom.DataKeyColor,
    alignItems: 'flex-end',
  },
  footerLeft: {
    justifyContent: 'flex-start',
    marginLeft: 5,
  },
  footerRight: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginRight: 2,
  },
  bulletPoint: {
    width: 8,
    height: 8,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderTopLeftRadius: 4,
    marginTop: 6,
    marginRight: 6,
    marginBottom: 0,
    marginLeft: 0,
  },
  divider: {
    marginTop: 7,
    marginBottom: 7,
  },
});
