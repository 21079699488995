import { useLazyQuery } from '@apollo/client';
import { Alert, Drawer, Skeleton, Avatar } from 'antd';
import { Center, Divider, HStack, Icon, Pressable, Spacer, Text, View, VStack } from 'native-base';
import React, { useEffect, useState } from 'react';
import { GROUP_MEMBER_TYPE } from '../../../constants';
import { UserQueries } from '../../../services';
import { getAllAgentsList } from '../../../services/ProfileImage/ProfileImage';
import { Colors } from '../../../styles';
import { getAccountId, getAccountUUID, getAgentsList, getUserRoles, getUserUUID } from '../../../utils/commonUtils';
import { IUser } from '../../RightSideContainer/Contacts/TeamMembers/interfaces';
import { TeamMemberProfile } from '../../RightSideContainer/Contacts/TeamMembers/TeamMembersTable/TeamMemberProfile';
import { DisplayCardAvatar } from '../DisplayCard/DisplayCardAvatar';
import CreateNew from '../Svg/CreateNew';
import Stack from '../LayoutComponents/Stack';
import { StyleSheet } from 'react-native';
interface IProps {
  userIds?: string[];
  userList?: IUser[];
  accountUsers?: IUser[];
  defaultUserId?: string;

  showAsAvatarGroup?: boolean;
  maxAvatarGroupUsers?: number;

  onClick?: () => void;
}

interface IComponentState {
  userList: IUser[];
  accountUsers: IUser[];
  isError: boolean;
  profileUrlsByUserId: { [index: string]: string; };
  isLoading?: boolean;

}

export function DisplayUserList(props: IProps) {
  const [ componentState, setComponentState ] = useState<IComponentState>({
    userList: [],
    accountUsers: [],
    isError: false,
    profileUrlsByUserId: {},
  });

  const accountUuid = getAccountUUID();
  const loggedInUserId = getUserUUID();
  const agentList = getAgentsList()

  async function getAgentData(userList: IUser[]) {
    if (!userList?.length) {
      return;
    }
    if (agentList.length) {
      const agentData = agentList;
      const profileUrlsByUserId: { [index: string]: string; } = {};

      userList.forEach((user) => {
        const userId = user.id;
        if (!userId) {
          return;
        }

        const matchUser = agentData?.find((agent: any) => {
          if (userId === agent?.id) {
            return agent;
          }
        });
        if (!matchUser) {
          return;
        }
        const thumbnail = matchUser?.thumbnail;
        if (!thumbnail || thumbnail?.includes('404')) {
          return;
        }
        profileUrlsByUserId[user.uuid as string] = thumbnail;
      });

      setComponentState((prev) => ({ ...prev, profileUrlsByUserId: profileUrlsByUserId }));

    }
  }

  const [ getUsersApi, { error: getUsersApiError, loading: getUsersApiLoading } ] = useLazyQuery(UserQueries.GET_USERS_FOR_CALENDAR, {
    fetchPolicy: 'no-cache',
    variables: {
      accountId: accountUuid,
    },
    onCompleted: (response) => {
      const users = response.users;
      setUserListFromUserIds(props.userIds as string[], users);
    }
  });

  function setUserListFromUserIds(userIds: string[], accountUsers: IUser[]) {
    const userList: IUser[] = [];

    if (!userIds?.length || !accountUsers?.length) {
      return setComponentState((prev) => ({ ...prev, userList: userList }));
    }

    userIds.forEach((userId) => {
      if (!userId) {
        return;
      }
      const user = accountUsers.find((accountUser) => {
        return (userId === accountUser?.uuid);
      });

      if (user) {
        userList.push(user);
      }
    });

    const sortedUserList = sortUserList(userList);
    getAgentData(sortedUserList);
    setComponentState((prev) => ({ ...prev, userList: sortedUserList }));
  }

  function sortUserList(userList: IUser[]) {
    if (!userList?.length) {
      return userList || [];
    }

    const sortedUserList = userList.sort((user1, user2) => {
      if (props.defaultUserId) {
        if (user1?.uuid === props.defaultUserId) {
          return -1;
        } else if (user2?.uuid === props.defaultUserId) {
          return 1;
        }
      }

      if (loggedInUserId) {
        if (user1?.uuid === loggedInUserId) {
          return -1;
        } else if (user2?.uuid === loggedInUserId) {
          return 1;
        }
      }

      const name1 = user1?.name?.toLowerCase?.()?.trim?.();
      const name2 = user2?.name?.toLowerCase?.()?.trim?.();

      if (!name1 && !name2) {
        return 0;
      }
      if (!name1 || !name2) {
        return -1;
      }

      if (name1 < name2) {
        return -1;
      } else if (name1 > name2) {
        return 1;
      } else {
        return 0;
      }
    });

    return sortedUserList;
  }

  useEffect(() => {
    if (!props?.userList?.length && !props.userIds?.length) {
      return;
    }

    if (props?.userList?.length) {
      const userList = sortUserList(props.userList);
      return setComponentState((prev) => ({ ...prev, userList: userList }));;
    } else if (props.userIds?.length) {
      if (!props.accountUsers?.length) {
        getUsersApi();
      } else {
        setComponentState((prev) => ({ ...prev, accountUsers: props.accountUsers || [] }));;
        setUserListFromUserIds(props.userIds, props.accountUsers);
      }
    } else {
      return setComponentState((prev) => ({ ...prev, userList: [] }));;
    }
  }, []);

  const isError = (getUsersApiError || componentState.isError);

  const handleClick = (event: any) => {
    event.stopPropagation();
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <View width={'full'}>
      {
        isError &&
        <Alert
          message="Something went wrong, please try again later"
          type='error'
          showIcon={true}
        />
      }
      {
        getUsersApiLoading &&
        <View width={'full'}>
          <Skeleton active />
        </View>
      }
      {
        !isError &&
        !getUsersApiLoading &&
        props.showAsAvatarGroup &&
        componentState.userList?.length > 0 &&
        <div onClick={handleClick}>
          <Avatar.Group
            maxStyle={{
              borderRadius: '8px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            size={'default'}
            maxCount={props.maxAvatarGroupUsers || 5}
          >
            {
              componentState.userList.map((user) => {
                const userRoles = getUserRoles(user || {});
                return (
                  <View marginLeft={-2} key={user.uuid}>
                    <DisplayCardAvatar
                      avatarStyle={{
                        avatarSize: 8,
                        width: 32,
                        height: 32,
                        textStyle: {
                          fontSize: 12,
                        }
                      }}
                      userData={{
                        userId: user?.id || '',
                        userType: GROUP_MEMBER_TYPE.USER,
                        roles: userRoles,
                        userName: user.name,
                        imgSrc: (user.uuid && componentState.profileUrlsByUserId[user.uuid] ? componentState.profileUrlsByUserId[user.uuid] : undefined )

                      }}
                      isLetterAvatarShow
                    />
                  </View>
                );
              })
            }
          </Avatar.Group>
        </div>
      }
      {
        !isError &&
        !getUsersApiLoading &&
        !props.showAsAvatarGroup &&
        componentState.userList?.length > 0 &&
        <div onClick={handleClick}>
          <VStack
            width={'full'}
            space={3}
            alignItems={'center'}
          >
            {componentState.userList.map((user) => {
              return (
                <View width={'full'} key={user.uuid}>
                  <Stack direction='row' style={styles.alignItemsCenter}>
                    <TeamMemberProfile
                      singleUserData={user}
                      fullName={user?.name || ''}
                      showActiveStatus={false}
                      profileUrl={(user.uuid && componentState.profileUrlsByUserId[user.uuid] ? componentState.profileUrlsByUserId[user.uuid] : undefined )}
                    />
                    {
                      props.defaultUserId &&
                      user.uuid === props.defaultUserId &&
                      <Stack direction='row' style={styles.alignItemsCenter} space={4}>
                        <Spacer/>
                        <CreateNew customColor={Colors.success[500]} />
                        <Text color={Colors.success[500]}>{'Default'}</Text>
                      </Stack>
                    }
                  </Stack>
                  <Divider marginTop={3}/>
                </View>
              );
            })}
          </VStack>
        </div>
      }
      {
        !isError &&
        !getUsersApiLoading &&
        componentState.userList?.length === 0 &&
        <Center
          width="full"
          justifyContent="center"
          alignItems="center"
        >
          <Text fontWeight={200} fontSize="12" textAlign="center" color={Colors.Custom.Gray500}>
            No users present
          </Text>
        </Center>
      }
    </View>
  );

}

const styles = StyleSheet.create({
  alignItemsCenter: {
    alignItems: 'center',
  },
});

export default DisplayUserList;
