import React from 'react';
import {isWeb} from '../../../utils/platformCheckUtils';
import {Image} from 'native-base';

const FamilyMember = () => {
  return (
    <>
      {isWeb() ? (
        <svg
          width="24"
          height="22"
          viewBox="0 0 24 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.1281 1.23135H16.5046V3.44385L20.1281 6.58447V1.23135Z"
            fill="#98A2B3"
          />
          <path
            d="M12 0.373535L0.989014 9.84229H2.95308C3.11714 9.84229 3.25308 9.97822 3.25308 10.1423V12.8985C3.25308 17.7173 7.16245 21.6267 11.9812 21.6267C16.8046 21.6267 20.714 17.7173 20.714 12.8985V10.1423C20.714 9.97822 20.85 9.84229 21.014 9.84229H23.0156L12 0.373535ZM15.8109 13.9251L12.164 17.572C12.1218 17.6142 12.0656 17.6376 12.0046 17.6376C11.9437 17.6376 11.8875 17.6142 11.8453 17.572L8.19839 13.9251C8.14683 13.8782 8.09995 13.8313 8.05308 13.7798C7.16245 12.8095 7.22808 11.2954 8.19839 10.4048C9.1687 9.51416 10.6828 9.57979 11.5734 10.5501C11.8125 10.7845 12.1968 10.7845 12.4359 10.5501C13.35 9.71103 14.7515 9.71103 15.6656 10.5501C16.6359 11.4407 16.7015 12.9548 15.8109 13.9251Z"
            fill="#98A2B3"
          />
        </svg>
      ) : (
        <Image
          resizeMode="contain"
          size={'24px'}
          source={require('../../../assets/images/Contact/family_members.png')}
          alt="image"
        />
      )}
    </>
  );
};

export default FamilyMember;
