import React, { useMemo } from 'react';
import {Text} from 'native-base';
import {Colors} from '../../../../../styles';
import Stack from '../../../../common/LayoutComponents/Stack';
import {
  getIconBasedOnVital,
} from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/HomeMonitoringView/utils';
import {useIntl} from 'react-intl';
import TrendView from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/HomeMonitoringView/components/TrendView';
import {isWeb} from '../../../../../utils/platformCheckUtils';
import { VitalGraphData } from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/HomeMonitoringView/interface';
import { StyleSheet } from 'react-native';

interface ClinicalSectionListItemProps {
  code?: string;
  title: string;
  highlightValue?: string;
  unit?: string;
  lastRecordedOn?: string;
  vitalGraphData?: VitalGraphData;
  valueType?: string;
}

export const ClinicalSectionListItem = (
  props: ClinicalSectionListItemProps
) => {
  const {
    code,
    title,
    highlightValue,
    unit,
    lastRecordedOn,
    vitalGraphData,
    valueType,
  } = props;
  const Icon = getIconBasedOnVital(code || '');
  const intl = useIntl();
  const isGraphDataAvailable = vitalGraphData?.graphData?.length && vitalGraphData?.graphData?.length > 0;  

  const webIconStyle = useMemo(() => ({
    strokeColor: isGraphDataAvailable
      ? Colors.Custom.Primary300
      : Colors.Custom.Gray400,
  }), [isGraphDataAvailable]);

  return isWeb() ? (
    <Stack
      direction="row"
      style={styles.webContainer}
    >
      <Stack direction="column" style={styles.webContentContainer}>
        <Text fontSize={14} color={Colors.Custom.Gray900} selectable={false}>
          <Icon
            strokeColor={webIconStyle.strokeColor}
          />
          {`  ${title}`}
        </Text>
        {!isGraphDataAvailable && (
            <Text
              fontSize={12}
              color={Colors.Custom.Gray400}
              marginTop={1}
              selectable={false}
            >
              {'No Recorded Data'}
            </Text>
        )}
        {isGraphDataAvailable && (
          <Stack direction="column">
            {!!valueType && (
              <Text color={Colors.Custom.Gray400} fontSize={12}>
                {valueType}
              </Text>
            )}
            <Text>
              {!!highlightValue && (
                <Text
                  color={Colors.Custom.Gray900}
                  selectable={false}
                  fontSize={18}
                >
                  {highlightValue}
                  <Text
                    color={Colors.Custom.Gray500}
                    fontSize={12}
                    paddingLeft={2}
                    paddingTop={2}
                  >
                    {unit}
                  </Text>
                </Text>
              )}
            </Text>
            {!!lastRecordedOn && (
              <Text color={Colors.Custom.Gray400} fontSize={12}>
                {`${intl.formatMessage({
                  id: 'lastRecordedOn',
                })}: ${lastRecordedOn}`}
              </Text>
            )}
          </Stack>
        )}
      </Stack>
      {vitalGraphData && isGraphDataAvailable && <TrendView vitalGraph={vitalGraphData} />}
    </Stack>
  ) : (
    <Stack
      direction="column"
      style={styles.mobileContainer}
    >
      <Stack style={styles.mobileHeaderContainer} direction="row">
        <Icon width={'24'} height={'24'} />
        <Text
          ml={2}
          fontSize={16}
          color={Colors.Custom.Gray900}
          selectable={false}
          alignItems={'center'}
        >
          {title}
        </Text>
      </Stack>
      <Stack
        direction="row"
        style={styles.mobileContentContainer}
      >
        <Stack direction="column" style={styles.mobileTextContainer}>
          <Text
            color={Colors.Custom.Gray400}
            fontSize={16}
            selectable={false}
            fontWeight={'500'}
          >
            {valueType}
          </Text>

          <Text
            color={Colors.Custom.Gray900}
            selectable={false}
            fontSize={24}
            fontWeight={'500'}
          >
            {highlightValue}
            {!!unit && (
              <Text
                color={Colors.Custom.Gray500}
                fontSize={16}
                paddingLeft={2}
                paddingTop={2}
              >
                {` ${unit}`}
              </Text>
            )}
          </Text>
          {!!lastRecordedOn && (
            <Text color={Colors.Custom.Gray400} fontSize={14}>
              {`${intl.formatMessage({
                id: 'lastRecordedOn',
              })}: ${lastRecordedOn}`}
            </Text>
          )}
        </Stack>
        {vitalGraphData && <TrendView vitalGraph={vitalGraphData} />}
      </Stack>
    </Stack>
  );
};

const styles = StyleSheet.create({
  webContainer: {
    padding: 8,
    borderWidth: 0.5,
    marginBottom: 8,
    borderRadius: 8,
    borderColor: Colors.Custom.Gray200,
    alignItems: 'center',
  },
  webContentContainer: {
    flex: 0.6,
  },
  mobileContainer: {
    flex: 1,
    borderWidth: 0.5,
    marginBottom: 8,
    borderRadius: 8,
    borderColor: Colors.Custom.Gray200,
  },
  mobileHeaderContainer: {
    alignItems: 'center',
    padding: 16,
  },
  mobileContentContainer: {
    flex: 1,
    borderTopWidth: 0.5,
    borderColor: Colors.Custom.Gray200,
    alignItems: 'center',
    padding: 16,
  },
  mobileTextContainer: {
    flex: 0.5,
  },
});