import { IAddOrUpdateObservationList, IObservation } from "../components/RightSideContainer/Forms/FHFormio/CustomComponents/Vitals/interfaces";
import { BLOOD_PRESSURE_LOINC, DIASTOLIC_BLOOD_PRESSURE_LOINC, SYSTOLIC_BLOOD_PRESSURE_LOINC } from "../constants";
import { IEhrCapability, IVitalData } from "../Interfaces";
import { getEnabledVitalsFromCapability, getVitalListFromCapability } from "./capabilityUtils";

export enum VitalUnit {
  inches = 'inches',
  in = 'in',
  gm = 'gm',
  g = 'g',
  grams = 'grams',
  kg = 'kg',
  lbs = 'lbs',
  pounds = 'pounds',
  oz = 'oz',
  ounce = 'ounce',
  fahrenheit = 'fahrenheit',
  F = 'F',
  C = 'C',
  cm = 'cm',
  cms = 'cms',
  ft_inches = 'ft_in',
  ft = 'ft',
  mmHg = 'mmHg'
}

export enum VitalInputType {
  numeric = 'numeric',
  choice = 'choice',
  date = 'date',
}

export enum Vital {
  bloodPressure = '85354-9',
  bmi = '39156-5',
  height = '8302-2',
  weight = '29463-7',
  // This is used for wearables
  oxygenSaturation = '2708-6',
  // This is used for EHR
  oxygenSaturationByPulseOximetry = '59408-5',
  heartRate = '8867-4',
  temperature = '8310-5',
  respirationRate = '9279-1',
  headCircumference = '9843-4',
  headCircumferenceByTape = '8287-5',
  waistCircumference = '56086-2',
  painScale = '80316-3',
  systolicBloodPressure = '8480-6',
  diastolicBloodPressure = '8462-4',
  vod = '11378-8',
  vos = '11377-0',
  lmp = '8665-2',
  birthWeight = '8339-4',
  bloodGlucose = '2339-0',
  restingHeartRate = '40443-4',
  heartRateVariability = '80404-7',
  calories = '93822-5',
  steps =  '41950-7',
  duration =  '55411-3',
  sleep = '82235-3',
  light_sleep = '93830-8',
  deep_sleep = '93831-6',
  awake = 'LA33709-9',
  rem_sleep = '93829-0',
}

export enum MonitoringActivityCodes {
  steps = '41950-7',
  caloriesBurnt = '93822-5',
  activityDuration = '55411-3',
  sleep = '82235-3'
}

export const getEnabledVitals = (
  capability?: IEhrCapability,
  formEnabledVitals?: any
) => {
  const list = capability?.abilities?.allowedVitalList || [];
  const vitals = [
    Vital.bloodPressure,
    Vital.height,
    Vital.weight,
    Vital.bmi,
    Vital.oxygenSaturation,
    Vital.oxygenSaturationByPulseOximetry,
    Vital.heartRate,
    Vital.temperature,
    Vital.respirationRate,
    Vital.headCircumference,
    Vital.headCircumferenceByTape,
    Vital.waistCircumference,
    Vital.painScale,
    Vital.birthWeight,
    Vital.vod,
    Vital.vos,
    Vital.lmp,
  ];
  const enabledVitals = vitals.filter((vital) => canShow(vital, list));
  if (formEnabledVitals && Object.keys(formEnabledVitals).length > 0) {
    return vitals.filter((vital) => {
      if (formEnabledVitals.hasOwnProperty(vital)) {
        return formEnabledVitals[vital] && enabledVitals.includes(vital);
      }
      // return enabledVitals.includes(vital);
    });
  }
  return enabledVitals;
};

export const canShow = (vital: Vital, vitalData: IVitalData[]) => {
  const vitalPresent = vitalData.find((item) => item.loinc === vital && !item.isHidden);
  return !!vitalPresent;
};

export const getDisplay = (vital: Vital, vitalData: IVitalData[]) => {
  const vitalConfig = getVitalConfig(vital, vitalData);
  // vitalConfig ? (vitalConfig.display = undefined) : null;
  switch (vital) {
    case Vital.bloodPressure:
      return vitalConfig?.foldDisplay || 'Blood Pressure';
    case Vital.bmi:
      return vitalConfig?.foldDisplay || 'BMI';
    case Vital.height:
      return vitalConfig?.foldDisplay || 'Height';
    case Vital.weight:
      return vitalConfig?.foldDisplay || 'Weight';
    case Vital.oxygenSaturation:
    case Vital.oxygenSaturationByPulseOximetry:
      return vitalConfig?.foldDisplay || 'Oxygen Saturation';
    case Vital.heartRate:
      return vitalConfig?.foldDisplay || 'Pulse Rate';
    case Vital.temperature:
      return vitalConfig?.foldDisplay || 'Temperature';
    case Vital.respirationRate:
      return vitalConfig?.foldDisplay || 'Respiration Rate';
    case Vital.headCircumference:
    case Vital.headCircumferenceByTape:
      return vitalConfig?.foldDisplay || 'Head Circumference';
    case Vital.waistCircumference:
      return vitalConfig?.foldDisplay || 'Waist Circumference';
    case Vital.painScale:
      return vitalConfig?.foldDisplay || 'Pain Scale';
    case Vital.systolicBloodPressure:
      return vitalConfig?.foldDisplay || 'Systolic Blood Pressure';
    case Vital.diastolicBloodPressure:
      return vitalConfig?.foldDisplay || 'Diastolic Blood Pressure';
    default:
      return vitalConfig?.foldDisplay || '';
  }
};

export const getUnit = (vital: Vital, vitalData: IVitalData[]) => {
  const vitalConfig = getVitalConfig(vital, vitalData);
  return vitalConfig?.unit;
};

export const getVitalUnitDisplay = (vital: Vital, vitalData: IVitalData[], isGraph?: boolean) => {
  const vitalConfig = getVitalConfig(vital, vitalData);

  let unit = vitalConfig?.displayUnit || vitalConfig?.unit;
  const temperatureUnits = [VitalUnit.fahrenheit, VitalUnit.F, VitalUnit.C]
  switch (vitalConfig?.loinc) {
    case Vital.bloodPressure:
      unit = VitalUnit.mmHg;
      break;
    case Vital.height:
      if(isGraph){
        unit = VitalUnit.ft;
      }
      else {
        unit = VitalUnit.ft_inches;
      }
      break;
    default:
      break;
  }
  if (temperatureUnits.includes(unit as VitalUnit)) {
    return `°${unit}`
  }
  return unit || '';
};

export const getVitalConfig = (vital: Vital, vitalData: IVitalData[]) => {
  return vitalData.find((item: any) => item.loinc === vital);
};

export const getEHRDisplay = (vital: Vital, vitalData: IVitalData[]) => {
  const vitalConfig = getVitalConfig(vital, vitalData);
  return vitalConfig?.display;
};

export const getVitalConfigFromLoinc = (vitalData: IVitalData[], loinc?: string) => {
  return vitalData.find((vital) => vital.loinc === loinc);
}

export const getObservationResourceTemplate = (vital: Vital, vitalData: IVitalData[], value?: string, date?: string) => {
  const displayText = getDisplay(vital, vitalData);
  const unit = getUnit(vital, vitalData);
  return {
    code: {
      text: displayText,
      coding: [
        {
          system: 'http://loinc.org',
          code: vital,
          display: getEHRDisplay(vital, vitalData),
        },
      ],
    },
    ...(date && { effectiveDateTime: date }),
    loinc: vital,
    valueQuantity: {
      value,
      unit,
    },
  }
}

export const getFHIRTemplate = (params: IAddOrUpdateObservationList) => {
  return {
    resourceType: 'Bundle',
    type: 'searchset',
    total: params.observations.length,
    entry: params.observations.map((observation) => {
      return {
        resource: toObservationFhirItem({
          ...observation,
          patientId: params.patientId,
        }),
      };
    }),
  };
};

export const getObservationFHIRBundle = (observations: IObservation[], patientId: string) => {
  return {
    resourceType: 'Bundle',
    type: 'searchset',
    total: observations.length,
    entry: observations.map((observation) => {
      return {
        resource: getIndividualFHIRTemplate({
          ...observation,
          patientId: patientId,
        }),
      };
    }),
  };
}

export const getIndividualFHIRTemplate = (params: IObservation) => {
  const template: any = {
    resourceType: 'Observation',
    subject: {
      reference: `Patient/${params.patientId}`,
    },
    ...(params.id && {id: params.id}),
    category: [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'vital-signs',
            display: 'Vital Signs',
          },
        ],
      },
    ],
    code: params.code,
    ...(params.effectiveDateTime && {
      effectiveDateTime: params.effectiveDateTime,
    }),
    ...(params.issued && {
      issued: params.issued,
    }),
    ...(params.valueQuantity && {
      valueQuantity: params.valueQuantity
    }),
    ...(params.valueString && {
      valueString: params.valueString
    }),
    ...(params.component && {
      component: params.component
    })
  };
  return template;
};

export const toObservationFhirItem = (params: IObservation) => {
  const isBP = params.loinc == BLOOD_PRESSURE_LOINC;
  let values: string[] = [];
  if (isBP && params.valueQuantity?.value) {
    values = `${params.valueQuantity.value}`.split('/');
  }
  const subjectObj = {
    subject: {
      reference: `Patient/${params.patientId}`,
    },
  };
  const template: any = {
    resourceType: 'Observation',
    ...(params?.patientId && subjectObj),
    ...(params.patientId && {
      subject: {
        reference: `Patient/${params.patientId}`,
      }
    }),
    ...(params.id && {id: params.id}),
    category: [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'vital-signs',
            display: 'Vital Signs',
          },
        ],
      },
    ],
    code: params.code,
    ...(params.issued && {
      issued: params.issued,
    }),
    ...(params.effectiveDateTime && {
      effectiveDateTime: params.effectiveDateTime,
    }),
    ...(!isBP &&
      params.valueQuantity?.value && {
        valueQuantity: {
          value: params.valueQuantity.value,
          ...(params.valueQuantity.unit && {
            unit: params.valueQuantity.unit,
          }),
        },
      }),
    ...(isBP &&
      values.length && {
        component: [],
      }),
  };
  if (isBP && values.length) {
    if (values[0]) {
      template.component?.push({
        code: {
          coding: [
            {
              code: SYSTOLIC_BLOOD_PRESSURE_LOINC,
              system: 'http://loinc.org',
            },
          ],
        },
        valueQuantity: {
          value: values[0],
          ...(params.valueQuantity?.unit && {
            unit: params.valueQuantity.unit,
          }),
        },
      });
    }
    if (values.length > 1 && values[1]) {
      template.component?.push({
        code: {
          coding: [
            {
              code: DIASTOLIC_BLOOD_PRESSURE_LOINC,
              system: 'http://loinc.org',
            },
          ],
        },
        valueQuantity: {
          value: values[1],
          ...(params.valueQuantity?.unit && {
            unit: params.valueQuantity.unit,
          }),
        },
      });
    }
  }
  return template;
};

export const isVitalValueAvailable = (observation: IObservation) => {
  return observation.valueString ||
  observation.valueQuantity?.value ||
  observation.valueQuantity?.value === 0 ||
  ((observation.component || []).length > 0 && observation.component?.some(item => {
    return item.valueString ||
    item.valueQuantity?.value ||
    item.valueQuantity?.value === 0;
  }))
}

export const isTooltipForGoal = (name: string) => {
  return name && name.includes('Goal');
}
export const getEnabledVitalListFromCapability = (locationId: string | undefined) => {
  const vitalList = getVitalListFromCapability('', locationId);
  const enabledVitals = getEnabledVitalsFromCapability(locationId);

  return vitalList.filter(
    (vital) => !vital.isHidden && enabledVitals.includes(vital.loinc as Vital)
  );
};
