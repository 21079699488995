import { Collapse } from 'antd';
import {Badge, Divider, HStack, Spacer, Text, View, VStack} from 'native-base';
import React, {useContext} from 'react';
import {MLOV_CATEGORY} from '../../../../../constants';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import { IAppointmentTask } from '../../../../../services/Appointment/AppointmentInterface';
import {Colors} from '../../../../../styles';
import {getDateStrFromFormat} from '../../../../../utils/DateUtils';
import {
  getMlovListFromCategory,
  getMlovValueFromId,
} from '../../../../../utils/mlovUtils';
import { TaskCheckList } from '../../../../common/AppointmentTaskCheckList/TaskCheckList';

interface IAppointmentBannerProps {
  name?: string;
  reasonForVisit?: string;
  date?: string;
  statusId?: string;
  location?: string;
  extraStyles?: any;
  appointmentId?: string;
  tasks?: IAppointmentTask[];
}

const antdStyles: Record<string, React.CSSProperties> = {
  collapsePanel: {
    backgroundColor: Colors.Custom.Gray50,
  },
};

const AppointmentBanner = (props: IAppointmentBannerProps) => {
  const {name, statusId, date, reasonForVisit, location} = props;
  const commonContextData = useContext(CommonDataContext);
  const appointmentStatusList =
    getMlovListFromCategory(
      commonContextData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.APPOINTMENT_STATUS
    ) || [];
  return (
    <View>
      <VStack
        backgroundColor={Colors.Custom.Gray50}
        borderRadius={8}
        style={props.extraStyles}
      >
        <HStack p={4}>
          <Text fontWeight={'bold'} fontSize={18}>
            {name}
          </Text>
          <Badge
            alignItems={'center'}
            rounded="3xl"
            _text={{fontSize: 'xs', color: Colors.Custom.Blue700}}
            ml={2}
            backgroundColor={Colors.Custom.Blue50}
          >
            {getMlovValueFromId(appointmentStatusList, statusId || '')}
          </Badge>
          <Spacer />
          <Text fontSize={14} color={'gray.500'}>
            {date && getDateStrFromFormat(date, 'DD/MM/YYYY')}
            {location && ' • ' + location}
          </Text>
        </HStack>
        <Divider />
        <HStack alignItems="center" p={4}>
          <Text fontSize={14} color={'gray.700'}>
            Visit Reason:
          </Text>
          <Text fontSize={14} ml={1} color={'gray.500'}>
            {reasonForVisit ? reasonForVisit : ''}
          </Text>
        </HStack>
      </VStack>

      {
        !!props.tasks?.length && props.appointmentId &&
        <Collapse accordion={true}>
          <Collapse.Panel header="Instructions" key="1" style={antdStyles.collapsePanel}>
            <TaskCheckList
              appointmentId={props.appointmentId}
              tasks={props.tasks}
              canAddNewTasks={false}
              canDeleteTasks={false}
              canEditStatus={true}
              canEditTitle={false}
            />
          </Collapse.Panel>
        </Collapse>
      }
    </View>
  );
};

export default AppointmentBanner;
