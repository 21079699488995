export const EXTENSION_URLS = {
  formName: 'https://foldhealth.io/structure/form-name',
  formId: 'https://foldhealth.io/structure/form-id',
  elationCreatedDate: 'http://xcaliber-fhir/structureDefinition/created-date',
  formKey: 'https://foldhealth.io/structure/form-key',
  signedDate: 'http://xcaliber-fhir/structureDefinition/signed-date',
  signedBy: 'http://xcaliber-fhir/structureDefinition/signed-by',
  requestGroupId: 'https://foldhealth.io/structure/request-group-id',
  requestGroupCategory: 'https://foldhealth.io/structure/request-group-category',
  medicationStatus: 'https://foldhealth.io/structure/patient-medication-status',
  externalStatus: 'https://foldhealth.io/structure/external-status',
  pharmacyNote: 'https://foldhealth.io/structure/pharmacy-note',
  medicationRequestId: 'https://foldhealth.io/structure/medication-request-id',
  specimen: 'https://www.healthgorilla.com/fhir/StructureDefinition/requestgroup-specimen',
  performer: 'https://www.healthgorilla.com/fhir/StructureDefinition/requestgroup-performer',
  docRefAdditionalSection: 'https://foldhealth.io/structure/additional-sections',
  reviewedBy: 'https://fold.health.io/fhir/StructureDefinition/reviewed_by',
  reviewedDate: 'https://fold.health.io/fhir/StructureDefinition/reviewed_date',
  requestGroupTiming: 'https://www.healthgorilla.com/fhir/StructureDefinition/requestgroup-timing',
  diagnosticReportingPriority: 'https://www.healthgorilla.com/fhir/StructureDefinition/diagnosticreport-reportingPriority',
  diagnosticInterpretation: 'https://www.healthgorilla.com/fhir/StructureDefinition/diagnosticreport-interpretation',
  discontinuedDate: 'http://xcaliber-fhir/structureDefinition/discontinued-date',
  requestGroupDeliveryOption: 'https://www.healthgorilla.com/fhir/StructureDefinition/requestgroup-deliveryOptions',
  foldUserUuid: 'https://foldhealth.io/structure/user-uuid',
  orderCategory: 'https://foldhealth.io/structure/order-category',
  orderId: 'https://foldhealth.io/structure/order-id',
  unlockedBy: 'https://foldhealth.io/structure/unlocked-by',
  amendedBy: 'https://foldhealth.io/structure/amended-by',
  amendedDate: 'https://foldhealth.io/structure/amended-date',
  group: 'https://foldhealth.io/structure/group',
  externalDisplayName: 'https://foldhealth.io/structure/external-display-name',
  system: 'https://foldhealth.io/structure/system',
  requestGroupPerformer: 'https://foldhealth.io/structure/requestgroup-performer',
  source: 'https://foldhealth.io/structure/source',
  orderingPhysician: 'https://foldhealth.io/structure/ordering-physician',
  author: 'https://foldhealth.io/structure/author',
  foldRequestGroupTiming: 'https://foldhealth.io/structure/requestgroup-timing',
  providerList: 'http://xcaliber-fhir/structureDefinition/provider-list',
  foldAccountLocationId: 'http://fold.health/integration/extension/fold-location-id',
  providerGroupList: 'http://xcaliber-fhir/structureDefinition/provider-group-list',
  providerGroupId: 'http://xcaliber-fhir/structureDefinition/provider-group-id',
  noShow: 'https://foldhealth.io/structure/no-show',
  sectionNote: 'http://xcaliber-fhir/structureDefinition/section-note',
  nkda: 'http://xcaliber-fhir/structureDefinition/nkda',
  noteLastModifiedBy: 'http://xcaliber-fhir/structureDefinition/note-last-modified-by',
  noteLastModifiedDateTime: 'http://xcaliber-fhir/structureDefinition/note-last-modified-date-time',
  rawPayload: 'https://foldhealth.io/structure/raw-payload',
  visitName: 'http://xcaliber-fhir/structureDefinition/encounter-visit-name',
  providerFirstName: 'http://xcaliber-fhir/structureDefinition/provider-first-name',
  providerLastName: 'http://xcaliber-fhir/structureDefinition/provider-last-name',
  noteContactId: 'https://foldhealth.io/structure/contact-id',
  noteContactUuid: 'https://foldhealth.io/structure/contact-uuid',
  formPayload: 'https://foldhealth.io/structure/form-payload',
  formRiskScore: 'https://foldhealth.io/structure/form-risk-score',
  departmentId: 'http://xcaliber-fhir/structureDefinition/department-id',
  foldLocationId: 'http://fold.health/integration/extension/fold-location-id',
  formHTML: 'https://foldhealth.io/structure/form-html',
  attachmentExists: 'http://xcaliber-fhir/structureDefinition/attachment-exists',
  patientShareHtml: 'https://foldhealth.io/structure/patient-share-html',
  allowDocumentReferenceToWriteBack: 'https://foldhealth.io/structure/allow-document-reference-to-write-back',
  insuranceCategory: 'http://xcaliber-fhir/structureDefinition/category',
  insuranceId: 'http://xcaliber-fhir/structureDefinition/insurance-id',
  insuranceDob: 'http://xcaliber-fhir/structureDefinition/policy-holder/dob',
  insuranceSexAtBirth: 'http://hl7.org/fhir/us/core/StructureDefinition/us-core-birthsex',
  insurancePolicyHolderName: 'http://xcaliber-fhir/structureDefinition/policy-holder/name',
  insuranceCopay: 'https://terminology.hl7.org/3.1.0/ValueSet-coverage-copay-type.html',
  insurancePlan: 'http://terminology.hl7.org/CodeSystem/coverage-class',
  insurancePolicyHolderAddress: 'http://xcaliber-fhir/structureDefinition/insurance-policy-holder-address',
  insuranceHolderCountry: 'http://xcaliber-fhir/structureDefinition/policy-holder/country',
  insuranceCoInsurancePercent: 'http://xcaliber-fhir/structureDefinition/coinsurance-percent',
  insuranceLastChecked: 'http://xcaliber-fhir/structureDefinition/eligibility-last-checked',
  insuranceSequenceNumber: 'http://xcaliber-fhir/structureDefinition/sequence-number',
  insurancePackageId: 'http://xcaliber-fhir/structureDefinition/insurance-package-id',
  showToPatient: 'https://foldhealth.io/structure/show-to-patient',
  formLogId: 'https://foldhealth.io/structure/form-log-id',
  formResponseId:  'https://foldhealth.io/structure/form-response-id',
  coverageProductType: 'http://xcaliber-fhir/structureDefinition/product-type',
  insuranceMediaRank: 'https://foldhealth.io/thread/structure-definition/rank',
  insuranceMediaSide: 'https://foldhealth.io/thread/structure-definition/side',
  insurancePolicyHolderIdAthena: 'http://xcaliber-fhir/structureDefinition/insured-id-number',
  insurancePolicyHolderIdElation: 'http://xcaliber-fhir/structureDefinition/policy-holder/id',
  insuranceDeletedMedia: 'https://foldhealth.io/structure/media-deleted',
  observationSource: 'https://foldhealth.io/structure/wearable-provider',
  documentDescription: 'http://xcaliber-fhir/structureDefinition/document-description',
  observationDateTime: 'http://xcaliber-fhir/structureDefinition/observation-datetime',
  internalNote: 'http://xcaliber-fhir/structureDefinition/internal-note',
  patientNote: 'http://xcaliber-fhir/structureDefinition/patient-note',
  syncWithEhr: 'https://foldhealth.io/structure/sync-pdf-with-ehr',
  documentData: "http://xcaliber-fhir/structureDefinition/document-data",
  taskId: 'https://foldhealth.io/structure/task-id',
  noteStatusComponent: 'https://foldhealth.io/structure/is-note-status-component-exist',
  noteSubStatusComponent: 'https://foldhealth.io/structure/is-note-sub-status-component-exist',
};

export const FOLD_CATEGORY = {
  clinicalNote: {
    code: 'clinical-note',
    system:
      'http://hl7.org/fhir/us/core/CodeSystem/us-core-documentreference-category',
    display: 'Clinical Note',
  },
  mimeTypeSufficient: {
    code: 'urn:ihe:iti:xds:2017:mimeTypeSufficient',
    system: 'http://ihe.net/fhir/ValueSet/IHE.FormatCode.codesystem',
    display: 'mimeType Sufficient',
  },
  evaluationPlanNote: {
    code: '51847-2',
    system: 'http://loinc.org',
    display: 'Evaluation+Plan note',
  },
};

export const ELATION_CATEGORY = {
  visitNoteType: {
    system: 'https://sandbox.elationemr.com/api/2.0/visit_note_types',
    code: 'Elation',
  },
};

