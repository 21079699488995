import {useMutation} from '@apollo/client';
import {Drawer, Modal} from 'antd';
import {Button, HStack, Text, VStack} from 'native-base';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {Dimensions} from 'react-native';
import {BUTTON_TYPE} from '../../../constants';
import {COMMON_ACTION_CODES} from '../../../constants/ActionConst';
import ContactRelation from '../../../services/ContactRelation/ContactRelation';
import {Colors} from '../../../styles';
import {FoldButton} from '../../CommonComponents/FoldButton/FoldButton';
import {DisplayText} from '../DisplayText/DisplayText';
import ModalActionBtn from '../ModalActionBtn/ModalActionBtn';
import {ModalActionTitle} from '../ModalActionTitle/ModalActionTitle';
import { getEmptyEmergencyContactFHIRTemplate } from './ContactRelationUtils';
import { updateEmergencyContact } from '../../../services/CommonService/AidBoxService';

const DeleteRelativeView = (props: any) => {
  const [showModal, setShowModal] = useState(true);
  const {removeRelationObj, isEmergencyContact, additionalDetails} = props;
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [deleteContactRelation] = useMutation(
    ContactRelation.DeleteContactRelation
  );
  const onCloseModal = () => {
    setShowModal(false);
    props.onFormActionPerformed(COMMON_ACTION_CODES.SAVE);
  };
  const onDeleteAction = () => {
    if (isEmergencyContact) {
      onDeleteEmergencyContact();
      return;
    }
    const promiseList = [
      deleteContactRelation({
        variables: {
          contactId: removeRelationObj?.contactId,
          relationContactId: removeRelationObj?.relativeContactId,
        },
      }),
    ];
    setLoading(true);
    Promise.all(promiseList).then(() => {
      setLoading(false);
      onCloseModal();
    });
  };

  const onDeleteEmergencyContact = async () => {
    if (!additionalDetails?.patientId || !additionalDetails?.contactUuid) {
      return;
    }
    setLoading(true);
    try {
      const body = getEmptyEmergencyContactFHIRTemplate(additionalDetails.patientId, additionalDetails.contactUuid)
      await updateEmergencyContact(body, additionalDetails?.accountLocationUuid);
      onCloseModal();
      setLoading(false);
    } catch (error) {

      setLoading(false);
    }
  }

  return (
    <>
      <Modal open={showModal} footer={null} closable={false}>
        <VStack>
          <Text
            mb={1}
            fontSize={'xl'}
            fontWeight={'bold'}
          >{`Delete Relation?`}</Text>
          <Text my={1} fontSize={'md'}>
            {intl.formatMessage({id: 'deleteContactRelation'})}
          </Text>
          <HStack mt={5} justifyContent={'flex-end'}>
            {[
              {
                textLocalId: 'cancel',
                buttonProps: {
                  variant: BUTTON_TYPE.SECONDARY,
                },
                onPress: onCloseModal,
              },
              {
                textLocalId: 'delete',
                buttonProps: {
                  variant: BUTTON_TYPE.PRIMARY,
                },
                loading: loading,
                onPress: onDeleteAction,
              },
            ].map((button: any, index: number) => {
              return (
                <FoldButton
                  key={index + button.btnText}
                  nativeProps={{
                    variant: button?.buttonProps?.variant,
                    onPress: () => {
                      button?.onPress();
                    },
                    ml: 2,
                    isLoading: button?.loading,
                    isDisabled: button?.loading,
                  }}
                  customProps={{
                    btnText: intl.formatMessage({id: button.textLocalId}),
                    withRightBorder: false,
                  }}
                ></FoldButton>
              );
            })}
          </HStack>
        </VStack>
      </Modal>
    </>
  );
};

export default DeleteRelativeView;
