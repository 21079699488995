import {HStack, Text, VStack, View, Pressable, Spinner} from 'native-base';
import React from 'react';
import {Colors} from '../../../../styles';
import {Radio, Select} from 'antd';
import {ModalActionAntSelect} from '../../ModalActionCommonComponent/ModalActionAntSelect';
import CustomTextAreaField from '../../../RightSideContainer/Forms/FHFormio/CustomComponents/TextAreaComponent/CustomTextAreaField';
import {IStepOutreachLog, StepOutreachLogModeOfContact} from '../../../../services/ContactCareProgram/interface';
import useOutreachStepOutComes from '../useOutreachStepOutComes';
import { useIntl } from 'react-intl';



export interface IData {
  modeOfContact: StepOutreachLogModeOfContact;
  outcomeId: string | undefined;
  note: string;
}

const OutreachStepNoteView = (props: {
  onSave: (data: IData) => void;
  onCancel: () => void;
  log: IStepOutreachLog;
  showNotSaved: boolean;
  enabledContactModes:  StepOutreachLogModeOfContact[];
  stepSaveLoading: boolean;
}) => {
  const { categoryWiseList } = useOutreachStepOutComes();
  const { log, enabledContactModes } = props;
  const [data, setData] = React.useState<IData>({
    modeOfContact: log.communicationMode ||  StepOutreachLogModeOfContact.IN_PERSON,
    outcomeId: log.outcomeId,
    note: log.note,

  });
  const intl = useIntl();

  const onChange = (
    key: Partial<keyof IData>,
    value: string | StepOutreachLogModeOfContact | undefined
  ) => {
    setData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const renderCardAction = (
    type: 'primary' | 'secondary',
    label: React.ReactNode,
    onPress: () => void
  ) => {
    return (
      <Pressable
        px={2}
        py={1}
        rounded={'sm'}
        borderWidth={0.5}
        mx={1}
        onPress={onPress}
        borderColor={
          type === 'primary'
            ? Colors.FoldPixel.PRIMARY300
            : Colors.FoldPixel.GRAY300
        }
        backgroundColor={
          type === 'primary' ? Colors.FoldPixel.PRIMARY300 : Colors.Custom.White
        }
      >
        <Text
          color={
            type === 'primary' ? Colors.Custom.White : Colors.FoldPixel.GRAY300
          }
        >
          {label}
        </Text>
      </Pressable>
    );
  };

  return (
    <View backgroundColor={Colors.FoldPixel.GRAY50} p={2} borderRadius={8} mt={2}>
      <HStack justifyContent={'flex-start'}>
        <Radio.Group
          value={data.modeOfContact}
          onChange={(e) => {
            onChange('modeOfContact', e.target.value);
          }}
        >
          {enabledContactModes.map((mode: any) => (
            <Radio key={mode} value={mode}>
              {mode}
            </Radio>
          ))}
        </Radio.Group>
      </HStack>
      <VStack mt={2}>
        <Select
          placeholder="Select the outcome of the outreach"
          value={data.outcomeId}
          style={{width: '100%'}}
          onSelect={(value) => onChange('outcomeId', value)}
        >
          {Object.keys(categoryWiseList).map((key) => {
            const optionList = categoryWiseList[key];
            return (
              <Select.OptGroup key={key} label={key}>
                {optionList.map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select.OptGroup>
            );
          })}
        </Select>
        <View mt={2} />
        <CustomTextAreaField
          disableAutoManageCannedResponsesPosition
          placeholder="Enter note"
          value={data.note}
          onValueChange={(value) => onChange('note', value)}
          macroTemplatesEnabled
          additionalProps={{
            backgroundColor: Colors.Custom.White,
            borderRadius: 8,
            _focus: {
              borderColor: Colors.Custom.BorderColor,
              style: {
                borderColor: Colors.Custom.BorderColor,
              },
            },
          }}
        />
      </VStack>
      <HStack gap={2} mt={2} justifyContent={'flex-end'}>
        {props.showNotSaved &&
          <Text paddingRight={2} paddingTop={1} justifyContent={'flex-start'} color={Colors.FoldPixel.STATUS_ERROR}>
            {intl.formatMessage({ id: 'notSavedMsg' })}
          </Text>
        }
        {renderCardAction('secondary', 'Cancel', () => {
          props.onCancel();
        })}
        {renderCardAction('primary', props?.stepSaveLoading ? <Spinner size={'sm'} /> : 'Save', () => {
          props.onSave(data);
        })}
      </HStack>
    </View>
  );
};

export default OutreachStepNoteView;
