import { Select as AntSelect } from 'antd';
import { HStack, Link, Text } from 'native-base';
import { useContext, useEffect, useState } from 'react';
import { Colors } from '../../../../../styles';

import { WorkflowContext } from '../../../../../context/WorkflowContext';
import { GraphMemberList } from '../../../../common/AppDashboard/GraphMemberList';
import { INPUT_FIELD_TYPE } from './NodeInputField';
const { Option } = AntSelect;
//import './NodeInputFieldStyles.css';


const ShowFilterTotalCountOnNode = (props: {sourceHandle?:string,setUserInputFieldList?:any,getPreviousImmediateNode?:any, prevImmediateNode?:any, value?:any, onChange?:any, userInputFieldList: any, widthHeightRatio?:number }) => {
  const widthHeightRatio = props?.widthHeightRatio;
  const workflowContext = useContext(WorkflowContext);
  const nodeMasterDataMap = workflowContext.nodeMasterDataMap;
  const [parentContactUuidList, setParentContactUuidList] = useState([])
 
  const getPreviousNodeContactUuidList = (prevImmediateNode:any, getPreviousImmediateNode:any, sourceHandle?:string): any=>{
      
    let contactUuidList;
      if(!prevImmediateNode ){
        return contactUuidList
      }

    let prevUserInputFieldList = prevImmediateNode?.data?.metaData?.userInputFieldList || [];
    let nodeType = prevImmediateNode?.data?.nodeType;
     if(prevImmediateNode?.type == 'Condition'){
        prevUserInputFieldList = prevImmediateNode?.data?.metaData?.optionMap?.[sourceHandle || 'out']?.userInputFieldList || []
        nodeType = prevImmediateNode?.data?.metaData?.optionMap?.[sourceHandle || 'out']?.nodeType;
     }
     if(prevImmediateNode && (nodeMasterDataMap[nodeType]?.isMemberFilterNode)){
        (prevUserInputFieldList).some((userInputField:any)=>{
          if(userInputField.fieldType === INPUT_FIELD_TYPE.SHOW_FILTER_TOTAL_COUNT && userInputField?.value){
            contactUuidList = userInputField?.value?.contactUuidList
            return true;
          } 
        })
     } else {
      contactUuidList = getPreviousNodeContactUuidList(getPreviousImmediateNode(prevImmediateNode?.id), getPreviousImmediateNode, prevImmediateNode?.data?.sourceHandle)
     }
     return contactUuidList
  }
  
  useEffect(()=>{
      const list = getPreviousNodeContactUuidList(props.prevImmediateNode, props.getPreviousImmediateNode, props?.sourceHandle || 'out')
      if(parentContactUuidList?.length != list?.length){
        setParentContactUuidList(list || [])
      }
  },[getPreviousNodeContactUuidList(props.prevImmediateNode, props.getPreviousImmediateNode, props?.sourceHandle || 'out'), props?.prevImmediateNode?.data?.metaData?.userInputFieldList])

  const [state, setState] = useState({
    page:1,
    pageSize:10,
    total: parentContactUuidList?.length || 0
  })

  const getPageContactUuidlist = (page:number, pageSize:number)=>{
      const list = (parentContactUuidList|| []).slice((page-1)*(pageSize), ((page-1)*pageSize)+ pageSize)
      return list 
  }
  const [data, setData]= useState({isOpenDrawer:false})
  return <HStack>{
    <Link
      onPress={() => {
        setData({ ...data, isOpenDrawer: true });
      }}
      alignItems={'left'}
      justifyContent='left'
    >
      <Text
        underline={true}
        color={Colors.FoldPixel.GRAY200}
        fontSize={widthHeightRatio? widthHeightRatio*12 : 12}
        fontWeight={400}
      >
        {parentContactUuidList?.length + ''}
      </Text>
    </Link>}
  
  
  <GraphMemberList
          title={'Members'}
          page={state.page}
          pageSize={state.pageSize}
          isFromNewEmployer={false}
          isFromDashboard={false}
          onClose={()=>{
            setData({...data, isOpenDrawer:false})
            setState({page:1, pageSize:10, total:parentContactUuidList?.length })
          }}
          onPaginationChange={(page: number, pageSize: number) => {

            setState((prev) => {
              return {
                ...prev,
                page,
                pageSize,
              }
            });
          }}
          total={parentContactUuidList?.length}
          isOpen={!!data?.isOpenDrawer}
          contactUuidList={getPageContactUuidlist(state.page, state.pageSize)}
          onCompleteAction={() => {
            setData({...data, isOpenDrawer:false})
            setState({page:1, pageSize:10, total:parentContactUuidList?.length })
          }}
        />
  </HStack>;
};


export default ShowFilterTotalCountOnNode;
