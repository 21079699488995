import {Components, Formio, Utils} from '@foldhealth/formiojs';
import _ from 'lodash';
import NativePromise from 'native-promise-only';
import QuillConfig from './QuillConfig';

const CustomComponent = Components.components.component;
const isIEBrowser = Utils.getBrowserInfo().ie;
const QUILL_URL = isIEBrowser
  ? 'https://cdn.quilljs.com/1.3.7'
  : 'https://cdn.quilljs.com/2.0.0-dev.3';
const QUILL_TABLE_URL = 'https://cdn.form.io/quill/quill-table.js';

CustomComponent.prototype.addQuill = function (element, settings, onChange) {
  settings = QuillConfig;
  /*
  Commenting this as it's overriding all settings
  settings = _.merge(
    this.wysiwygDefault.quill,
    _.get(this.options, 'editors.quill.settings', {}),
    settings
  );*/
  settings = {
    ...settings,
    modules: {
      table: true,
      ...settings.modules,
    },
  };
  // Lazy load the quill css.
  Formio.requireLibrary(
    `quill-css-${settings.theme}`,
    'Quill',
    [{type: 'styles', src: `${QUILL_URL}/quill.${settings.theme}.css`}],
    true
  );

  return Formio.requireLibrary(
    'quill',
    'Quill',
    _.get(this.options, 'editors.quill.src', `${QUILL_URL}/quill.min.js`),
    true
  ).then(() => {
    return Formio.requireLibrary(
      'quill-table',
      'Quill',
      QUILL_TABLE_URL,
      true
    ).then(() => {
      if (!element.parentNode) {
        return NativePromise.reject();
      }
      // Quill.register('modules/blotFormatter', BlotFormatter);
      settings.bounds = element;
      this.quill = new Quill(
        element,
        isIEBrowser ? {...settings, modules: {}} : settings
      );

      /** This block of code adds the [source] capabilities.  See https://codepen.io/anon/pen/ZyEjrQ **/
      const txtArea = document.createElement('textarea');
      txtArea.setAttribute('class', 'quill-source-code');
      this.quill.addContainer('ql-custom').appendChild(txtArea);
      const qlSource = element.parentNode.querySelector('.ql-source');
      if (qlSource) {
        this.addEventListener(qlSource, 'click', (event) => {
          event.preventDefault();
          if (txtArea.style.display === 'inherit') {
            this.quill.setContents(this.quill.clipboard.convert(txtArea.value));
          }
          txtArea.style.display =
            txtArea.style.display === 'none' ? 'inherit' : 'none';
        });
      }
      /** END CODEBLOCK **/

      // Make sure to select cursor when they click on the element.
      this.addEventListener(element, 'click', () => this.quill.focus());

      // Allows users to skip toolbar items when tabbing though form
      const elm = document.querySelectorAll('.ql-formats > button');
      for (let i = 0; i < elm.length; i++) {
        elm[i].setAttribute('tabindex', '-1');
      }

      this.quill.on('text-change', () => {
        txtArea.value = this.quill.root.innerHTML;
        onChange(txtArea);
      });
      return this.quill;
    });
  });
};
