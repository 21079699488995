import CommonService from '../../../services/CommonService/CommonService';
import {IAddZipCode} from './interfaces';

export const addZipCode = (params: IAddZipCode) => {
  const bodyParams = params.bodyParams;
  const commonService = CommonService.getCommonServiceInstance();
  const axiosService = commonService.crmService;
  return axiosService.post(`/api/admin/zipcode`, {
    ...bodyParams,
  });
};
