import {Colors} from '../../../styles/Colors';
import {ICommonSvgProps} from './interfaces';

const SmsSvg = (props: ICommonSvgProps) => {
  const {
    isEnabled,
    defaultColor,
    customStrokeColor,
    customBgColor,
    borderColor,
    key,
  } = props;
  const bgColor = isEnabled
    ? customBgColor
      ? customBgColor
      : Colors.primary['500']
    : customBgColor
    ? customBgColor
    : Colors.primary['200'];

  return (
    <svg
      key={key}
      style={{width: 'inherit', height: 'inherit'}}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="35"
        height="35"
        rx="17.5"
        fill={defaultColor ? defaultColor : bgColor}
      />
      <path
        d="M25.5 20.5C25.5 20.942 25.3244 21.366 25.0118 21.6785C24.6993 21.9911 24.2754 22.1667 23.8333 22.1667H13.8333L10.5 25.5V12.1667C10.5 11.7246 10.6756 11.3007 10.9882 10.9882C11.3007 10.6756 11.7246 10.5 12.1667 10.5H23.8333C24.2754 10.5 24.6993 10.6756 25.0118 10.9882C25.3244 11.3007 25.5 11.7246 25.5 12.1667V20.5Z"
        stroke={customStrokeColor ? customStrokeColor : '#fff'}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect
        x="0.5"
        y="0.5"
        width="35"
        height="35"
        rx="17.5"
        stroke={
          isEnabled
            ? borderColor
              ? borderColor
              : '#FDD7AD'
            : customStrokeColor
            ? customStrokeColor
            : '#fff'
        }
      />
    </svg>
  );
};

export default SmsSvg;
