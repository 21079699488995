import {StyleSheet} from 'react-native';
import {Colors} from '../../../../../styles';

export const styles = StyleSheet.create({
  topbarContainer: {
    alignItems: 'center',
    margin: 5,
  },
  titleText: {},
  inputStyle: {
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  dropdownStyle: {
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  emailContainer: {
    // maxWidth: 400,
  },
  switchView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
  },
  mainContainer: {
    margin: 10,
    borderColor: Colors.Custom.BorderColor,
    borderWidth: 1,
    borderRadius: 10,
    overflow: 'hidden',
  },
});

export const getEmailTemplateButtonStyles = (args: {
  btnType: 'primary' | 'secondary';
}) => {
  const {btnType} = args;
  return {
    backgroundColor:
      btnType == 'primary'
        ? Colors.Custom.FontColorWhite
        : Colors.danger['100'],
    borderColor:
      btnType == 'primary'
        ? Colors.Custom.mainPrimaryPurple
        : Colors.danger['200'],
    height: 40,
    borderRadius: 6,
    borderWidth: 1,
    padding: 10,
    marginHorizontal: 4,
    marginTop: 2,
  };
};
