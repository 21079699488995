import {StyleSheet} from 'react-native';
import {Colors} from '../../../../../styles';

export const styles = StyleSheet.create({
  readonlyLineContainer:{marginTop: 10},
  readPillContainer: {
    backgroundColor: Colors.Custom.Gray300,
    padding: 2.5,
    paddingHorizontal: 5,
    marginRight: 5,
    borderRadius: 5,
    marginTop:10
  },
  readonlyContainer: {paddingHorizontal: 24, paddingBottom: 24},
  formElement: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  yearsInput: {
    paddingRight: 24,
  },
  formLabel: {
    marginBottom: 8,
  },
  formLastElement: {
    paddingTop: 10,
    paddingBottom: 50,
  },
  submitElement: {
    width: 100,
    paddingTop: 10,
    marginBottom: 30,
  },
  cancelElement: {
    width: 100,
    paddingLeft: 10,
    paddingTop: 10,
    marginBottom: 30,
  },
  headerText: {
    fontWeight: '400',
    fontSize: 20,
    color: '#344054',
    paddingLeft: 16,
  },
});
