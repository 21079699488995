import { updateValueToPrecision } from '../../../../../../RightSideContainer/Forms/FHFormio/CustomComponents/Vitals/AddOrUpdateVitals/AddOrUpdateVitalsHelper';
import {
  DiagnosticData,
  ObservationListApiResponse,
  ObservationResult,
} from './interfaces';

export const getFormattedData = (data: any[]) => {
  const formattedArray: DiagnosticData[] = [];
  data.forEach((item) => {
    let displayValue = '';
    const displayCode = item.code.text;
    const value = item.value?.Quantity?.value?.toFixed(2);
    const unit = item.value?.Quantity?.unit;
    if (value && unit) {
      displayValue = `${value} ${unit}`;
    }
    const id = item.id;
    formattedArray.push({
      id,
      displayCode,
      displayValue,
      unit,
      value,
    });
  });
  return formattedArray;
};

export const getObservationIds = (list: ObservationResult[]) => {
  if (list.length === 0) {
    return '';
  }
  const ids = list
    .map((item) => {
      if (item.reference.indexOf('urn:uuid:') > -1) {
        return item.reference.substring('urn:uuid:'.length);
      }
      return item.reference;
    })
    .join(',');

  return ids;
};

export const getFormattedObservationList = (
  data: ObservationListApiResponse
): DiagnosticData[] => {
  const entry = data.entry;
  const result: DiagnosticData[] = [];
  entry.map((item) => {
    let displayValue = '';
    const resource = item.resource;
    const unit = resource?.valueQuantity?.unit;
    const value = resource?.valueQuantity?.value ? updateValueToPrecision(resource?.valueQuantity.value, 2) : undefined;
    if (value && unit) {
      displayValue = `${value} ${unit}`;
    }

    result.push({
      displayCode:
        resource?.code?.text || resource?.code?.coding?.length > 0
          ? resource?.code?.coding[0]?.display
          : '',
      displayValue: displayValue,
      id: resource?.id,
      unit: resource?.valueQuantity?.unit || '',
      value: resource?.valueQuantity?.value,
    });
  });

  return result;
};
