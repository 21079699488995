import React from "react";

const AutomationActionBtn = ()=> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#667085"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M13.78 1.79c-.114 2.258 2.667 3.408 4.179 1.73a2.402 2.402 0 012.52 2.521c-1.677 1.513-.523 4.293 1.73 4.178a2.4 2.4 0 010 3.562c-2.257-.115-3.407 2.665-1.73 4.178a2.402 2.402 0 01-2.52 2.52c-1.512-1.677-4.293-.523-4.178 1.73a2.4 2.4 0 01-3.562 0c.115-2.257-2.665-3.407-4.178-1.73a2.402 2.402 0 01-2.52-2.52c1.677-1.512.523-4.293-1.73-4.178a2.4 2.4 0 010-3.562c2.257.115 3.407-2.665 1.73-4.178a2.402 2.402 0 012.52-2.52c1.513 1.677 4.293.523 4.178-1.73a2.4 2.4 0 013.562 0z"
      ></path>
      <path
        stroke="#667085"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M10 9l5 3-5 3V9z"
      ></path>
    </svg>
  );
}

export default AutomationActionBtn;
