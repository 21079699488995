import { Linking, StyleProp, Text, TextStyle } from "react-native"

export type TextSnippet = {
    text: string
    style?: StyleProp<TextStyle>
    destination?: string
}

export const EmbeddedLinks: React.FC<{
    textSnippets: Array<TextSnippet>
    linkStyle?: StyleProp<TextStyle>
    textStyle?: StyleProp<TextStyle>
}> = ({ 
    textSnippets, 
    linkStyle = { color: 'blue' }, 
    textStyle = {} 
}) => {
    
    return <Text style={textStyle || {}} >
        {textSnippets.map(({ text, style = {}, destination }) => {
            let props: {
                style: StyleProp<TextStyle>
                onPress?: () => unknown
            }
            if (destination) {
                props = {
                    onPress: () => Linking.openURL(destination),
                    style: [linkStyle, style]
                }
            } else {
                props = {
                    style: [textStyle, style]
                }
            }
            return <Text {...props}>{text}</Text>
        })}
    </Text >
}