import {useLazyQuery} from '@apollo/client';
import {Select, Skeleton, Spin} from 'antd';
import {debounce} from 'lodash';
import {useContext, useEffect, useState} from 'react';
import {Text} from 'native-base';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import {
  getMlovListFromCategory,
  getMlovObjectFromCode,
} from '../../../../../utils/mlovUtils';
import {
  CARE_PROGRAM_STATUS,
  MLOV_CATEGORY,
} from '../../../../../constants/MlovConst';
import {
  GET_CARE_JOURNEY_BY_IDS_STATUS,
  GET_CARE_JOURNEY_STATUS_AND_TITLE,
} from '../../../../../services/CareJourney/CareJourneyQueries';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../constants/Configs';

export const CareJourneySearch = (props: {
  defaultValue?: any;
  isShowError?: boolean;
  onChange: (actionData?: any, extraData?: any) => void;
  locationIdList?: string[];
  searchAbsoluteLocations?: boolean;
}) => {
  const {defaultValue} = props;
  const [careJourneys, setCareJourneys] = useState<any[]>([]);
  const [isLoading, setLoading] = useState(true);
  const commonData = useContext(CommonDataContext);
  const mlovList =
    getMlovListFromCategory(commonData.CARE_STUDIO_MLOV, MLOV_CATEGORY.CareProgramStatus) ||
    [];
  const activeProgramMlov = getMlovObjectFromCode(
    CARE_PROGRAM_STATUS.ACTIVE,
    mlovList,
  );

  const [getCareJourney] = useLazyQuery(GET_CARE_JOURNEY_STATUS_AND_TITLE, {
    fetchPolicy: 'no-cache',
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
  });

  const [searchJourneyById] = useLazyQuery(GET_CARE_JOURNEY_BY_IDS_STATUS, {
    fetchPolicy: 'no-cache',
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    onCompleted: data => {
      setCareJourneys(data?.careJourneys || []);
      setLoading(false);
    },
    onError: error => {
      setLoading(false);
    },
  });

  const onGetCareJourneyByIds = (journeyIds: string[]) => {
    setLoading(true);
    searchJourneyById({
      variables: {
        ids: journeyIds,
      },
    });
  };

  const getCareJourneyLocations = () => {
    if (props?.locationIdList?.length) {
      const absoluteLocationObject = {
        _and: props?.locationIdList.map(id => ({
          careJourneyLocations: {
            locationId: {
              _in: [id]
            }
          }
        }))
      }
      return absoluteLocationObject
    }
    return {}
  }

  const onSearchCareJourney = async (searchString: string) => {
    try {
      const careJourneyParams = {
        limit: 10,
        offset: 0,
        search: `%${searchString?.trim()}%`,
        statusId: activeProgramMlov?.id,
        careJourneyBoolExp: {}
      };
      if (props?.searchAbsoluteLocations) {
        careJourneyParams.careJourneyBoolExp = getCareJourneyLocations()
      }
      const apiResponse = await getCareJourney({variables: careJourneyParams});
      const careJourneys = apiResponse?.data?.careJourneys || [];
      setCareJourneys(careJourneys || []);
      setLoading(false);
    } catch (error) {
      setCareJourneys([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (defaultValue && defaultValue?.length) {
      onGetCareJourneyByIds(defaultValue);
    } else {
      onSearchCareJourney('');
    }
  }, [defaultValue]);

  return (
    <>
      <Select
        size="large"
        mode="multiple"
        showSearch
        status={
          props.isShowError && !(defaultValue && defaultValue?.length)
            ? 'error'
            : ''
        }
        allowClear
        filterOption={false}
        value={careJourneys?.length ? defaultValue || undefined : undefined}
        onSearch={debounce(onSearchCareJourney, 500)}
        onChange={(value: any) => {
          if (value && value.length > 0) {
            const careJourneyName = value?.map((id: any) => {
              const careJourney = careJourneys?.find(journey => {
                return journey?.id == id;
              });
              return careJourney?.title;
            });
            props.onChange(value, careJourneyName);
          } else {
            props.onChange(undefined);
          }
        }}
        placeholder="Select Journey"
        loading={isLoading}
        notFoundContent={
          isLoading ? <Spin size="large" /> : <Text>No Data Found</Text>
        }
        style={{width: '100%'}}>
        {careJourneys?.map((item: any) => {
          return (
            <Select.Option key={item.id} value={item.id}>
              {item.title}
            </Select.Option>
          );
        })}
      </Select>
    </>
  );
};

export default CareJourneySearch;
