import { View } from 'native-base';
import React from 'react';

const RightArrowIconForDocuments = () => {
    return (
        <View >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 18L15 12L9 6" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

        </View>
    )
};
export default RightArrowIconForDocuments;