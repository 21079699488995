import {StyleSheet} from 'react-native';
import {Colors} from '../../../../../../styles/Colors';
import {isWeb} from '../../../../../../utils/platformCheckUtils';
export const styles = StyleSheet.create({
  newMessage: {
    boxShadow: '0 0.8rem 1.6rem rgb(50 50 93 / 8%), 0 0.4rem 1.2rem rgb(0 0 0 / 7%)',
    borderRadius: 1000,
    backgroundColor: Colors.Custom.PurpleColor,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 20,
    paddingRight: 20,
    position: 'absolute',
    top: -55,
    contentContainer: {
      flex: 1
    },
  },
  vStackStyle: {
    marginLeft: isWeb() ? 0 : 3
  },
  boxStyle: {
    alignItems: 'center',
  },
});
