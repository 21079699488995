import React from 'react';
import {isWeb} from '../../../utils/platformCheckUtils';
import {ICommonSvgProps} from './interfaces';
import {Image} from 'native-base';
import {Path, Svg} from 'react-native-svg';

const EmailReplyAllSvg = (props: ICommonSvgProps) => {
  const height = props.height || '16';
  const width = props.width || '16';
  const strokeColor = props.customStrokeColor || '#6F7A90';
  return isWeb() ? (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.33333 7.99996L7.66667 4.66663M4.33333 7.99996L7.66667 11.3333M4.33333 7.99996L11 7.99996C12.1111 7.99996 14.3333 8.66663 14.3333 11.3333M5.33333 4.66663L2 7.99996L5.33333 11.3333"
        stroke={strokeColor}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ) : (
    <Svg width={width} height={height} viewBox="0 0 16 16" fill="none">
      <Path
        d="M4.33333 7.99996L7.66667 4.66663M4.33333 7.99996L7.66667 11.3333M4.33333 7.99996L11 7.99996C12.1111 7.99996 14.3333 8.66663 14.3333 11.3333M5.33333 4.66663L2 7.99996L5.33333 11.3333"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default React.memo(EmailReplyAllSvg);
