import { LOCALES } from '../locales/locales';

export default {
  [LOCALES.SPANISH]: {
    hello: 'Holla',
    teamInbox: 'Bandeja de entrada del equipo',
    conversations: 'Conversaciones',
    channels: 'Canales',
    integrations: 'integraciones',
    skills: 'Habilidades',
    responses: 'Respuestas',
    contacts: 'Contactos',
    visitors: 'Visitantes',
    leads: 'Dirige',
    customers: 'Clientes',
    teamMembers: 'Miembros del equipo',
    companies: 'Compañías',
    segments: 'Segmentos',
    tags: 'Etiquetas',
    marketing: 'Márketing',
    forms: 'Formularios',
    campaigns: 'Campañas',
    sales: 'Ventas',
    salesPipeline: 'Flujo de ventas',
    productsAndServices: 'productos y servicios',
    support: 'Apoyo',
    tickets: 'Entradas',
    knowledgebase: 'Base de conocimientos',
    management: 'Gestión',
    task: 'Tarea',
    reports: 'Informes',
    calendar: 'Calendario',
    settings: 'Ajustes',
    name: 'Nombre',
    role: 'El Oficio',
    phone: 'Teléfono',
    email: 'Email',
    bloodGroup:'Blood Group',
    pleaseAgreeToTermsAndCondition: 'Por favor, acepta los Términos y condiciones',
    labels: 'Etiquetas',
    stopCareJourneyMessage: 'El viaje una vez detenido, no se puede reanudar. ¿Estás seguro de que quieres detener este viaje de atención?',
    stopCareJourneyHeader: 'Detener viaje',
    appUpdateAlert: 'Hemos lanzado una nueva y mejorada versión. Actualice la aplicación para una mejor experiencia.',
    appUpdateHeader: 'Actualización requerida',
    updateNowText: 'Actualizar ahora',
    careJourneyAssigned: 'VIAJE DE CUIDADO ASIGNADO',
    nameCapital: 'NOMBRE',
    noUnassignedMembers: 'No hay miembros no asignados',
    showAllMembers: 'Mostrar todos los miembros',
    updateSomeContent: 'Por favor actualice algún contenido',
    taskIsDeleted: 'Esta tarea no existe',
    linkNote: 'Nota vinculada',
    linkNoteAdditionalDetail: 'Detalles de la nota del caso',
    mandatoryNoteTitleFieldError: 'El título es obligatorio.',
    deleteNoteLinkTaskConfirmation: 'Al eliminar esta tarea, se desvinculará de la nota asociada, pero las notas aún persistirán. ¿Desea continuar?',
    deleteLinkTaskNote: 'Al eliminar esta nota también se eliminará la tarea vinculada asociada a ella. ¿Está seguro?',
    consentToEmail: 'Consentimiento para enviar correo electrónico',
    consentToText: 'Consentimiento para enviar mensajes de texto',
    consentToCall: 'Consentimiento para llamar',
    selectSlotError: 'Puede seleccionar una ranura dentro del siguiente $bookWithinLimit pero no antes de $bookWithinOffset a partir de ahora.',
    editLabel: 'Editar Etiqueta',
    deleteLabel: 'Eliminar Etiqueta',
    noLabelsAvail: 'No hay etiquetas disponibles',
    searchLabels: 'Buscar etiquetas',
    sendProviderRequestFormError: "No puede enviar un formulario de categoría 'Formulario de solicitud de proveedor' a pacientes/clientes potenciales.",
    labelName: 'Nombre de etiqueta',
    labelDescription: 'Descripción de la etiqueta',
    labelColor: 'Color de la etiqueta',
    labelDeleteMsg: 'Esta acción eliminará la etiqueta y la eliminará de todas las tareas existentes a las que se haya agregado.',
    conversationDeleteMsg: 'Esta acción quitará la etiqueta de todas las conversaciones que tengan esta etiqueta y eliminará la etiqueta.',
    startDateNotAssigned : 'Fecha de inicio no asignada',
    accept: 'Aceptar',
    acceptAll: 'Aceptar todo',
    decline: 'Rechazar',
    declineAll: 'Rechazar todo',
    proposeNewSlot: 'Proponer nuevo espacio',
  },
};
