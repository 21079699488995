import React from 'react';
import {
  FlatList,
  FlatListProps,
  LayoutChangeEvent,
  NativeScrollEvent,
  NativeSyntheticEvent,
} from 'react-native';
import {IMessageBoxData} from '../../RightSideContainer/TeamInbox/Conversations/interfaces';
import {MESSAGE_FLAT_LIST_CONST, SCROLL_DIRECTION} from '../../../constants';
import {usePrevious} from '../../CustomHooks/usePrevious';

type MessageFlatListProps<T> = FlatListProps<T> & {
  onStartReached: (info: {distanceFromStart: number}) => void;
  onStartReachedThreshold: number;
};

const MessagesFlatList = React.forwardRef(
  (props: MessageFlatListProps<IMessageBoxData>, ref: any) => {
    const prevProps = usePrevious(props);
    const scrollThresholdToReach = React.useRef<number>(0);
    const previousDistanceFromStart = React.useRef<number>(0);
    const allowMoreEvents = React.useRef(true);
    const shouldScrollAfterOnStartReached = React.useRef(false);

    const onScroll = (ev: NativeSyntheticEvent<NativeScrollEvent>) => {
      if (props.horizontal) {
        return;
      }
      const distanceFromStart = ev.nativeEvent.contentOffset.y;
      const hasReachedScrollThreshold =
        distanceFromStart <= scrollThresholdToReach.current;
      const scrollDirection =
        distanceFromStart - previousDistanceFromStart.current < 0
          ? SCROLL_DIRECTION.TOP
          : SCROLL_DIRECTION.BOTTOM;
      previousDistanceFromStart.current = distanceFromStart;
      if (
        allowMoreEvents.current &&
        hasReachedScrollThreshold &&
        scrollDirection === 'TOP'
      ) {
        onStartReached({distanceFromStart: distanceFromStart});
      }
    };

    const onLayout = (ev: LayoutChangeEvent) => {
      const onStartReachedThreshold = props.onStartReachedThreshold;
      scrollThresholdToReach.current =
        ev.nativeEvent.layout.height * onStartReachedThreshold;
    };

    const onStartReached = (info: {distanceFromStart: number}) => {
      allowMoreEvents.current = false;
      shouldScrollAfterOnStartReached.current = true;
      props.onStartReached(info);
    };

    const onResponderEnd = () => {
      allowMoreEvents.current = true;
    };

    return (
      <FlatList
        {...props}
        scrollEventThrottle={MESSAGE_FLAT_LIST_CONST.SCROLL_EVENT_THROTTLE}
        onScroll={onScroll}
        onLayout={onLayout}
        onResponderEnd={onResponderEnd}
        ref={ref}
      />
    );
  }
);

export default MessagesFlatList;
