import {Box, Divider, HStack, Text, VStack} from 'native-base';
import React from 'react';
import {TagComponent} from '../TagComponent';
import {DisplayCardAvatar} from './DisplayCardAvatar';
import {DisplayCardFooter} from './DisplayCardFooter';
import {DisplayCardList} from './DisplayCardList';
import {styles} from './DisplayCardStyles';
import {IDisplayCard} from './interface';
export const DisplayCard = (props: IDisplayCard) => {
  const {displayList, avatarData, footerData, tagData, bottomTagData} = props;

  return (
    <Box rounded="xl" style={styles.mainBoxContainer}>
      <VStack flex={1} style={styles.contentContainer}>
        <HStack>
          {tagData && (
            <TagComponent
              tagText={tagData?.tagStr}
              tagColor={tagData.tagColor}
              tagStyle={{borderRadius: 30}}
              tagTextStyle={{
                textTransform: 'uppercase',
                fontSize: 11,
                fontWeight: '400',
              }}
            />
          )}
        </HStack>
        <HStack>
          <Text flex={1} style={styles.heading}>
            {props.headerString}
          </Text>
        </HStack>
        {displayList.length && (
          <HStack style={styles.displayListStyle}>
            <DisplayCardList displayList={displayList} />
          </HStack>
        )}

        {avatarData && (
          <HStack justifyContent={'flex-end'}>
            <DisplayCardAvatar />
          </HStack>
        )}

        <HStack flex={1}>
          {bottomTagData && (
            <TagComponent
              tagText={bottomTagData?.tagStr}
              tagColor={bottomTagData.tagColor}
              tagStyle={{borderRadius: 30}}
              tagTextStyle={{
                textTransform: 'uppercase',
                fontSize: 11,
                fontWeight: '400',
              }}
            />
          )}
        </HStack>
        <Divider style={styles.divider} />
        <HStack flex={1} justifyContent={'space-between'}>
          <DisplayCardFooter footerData={footerData} />
        </HStack>
      </VStack>
    </Box>
  );
};
