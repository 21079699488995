import {Pressable, Text, View, VStack, HStack, Tooltip } from 'native-base';
import CircleIconView, {
  NodeCategory,
} from '../../../common/CircleIconView/CircleIconView';
import { IIconInfo } from '../FlowComponent/StateNodes/FlowNodeInterface';
import { StyleProp, ViewStyle } from 'react-native';
import { useState } from 'react';
import { Colors } from '../../../../styles';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import { styles } from '../workflowStyles';

const NewLibNodeCard = (props: {
  isLastNode? : boolean;
  isFirstNode? : boolean;
  title: string;
  isNodeEnabled:boolean;
  description?: string;
  iconInfo?: IIconInfo;
  nodeCategory?: string;
  onClick?: () => void;
  isOperationNode?: boolean;
  isConditionNode?:boolean;
  isActionNode?:boolean;
  tooltip?: string
}) => {
  const BORDER_RADIUS = 8

  const operationNodeStyles : StyleProp<ViewStyle> = {
    paddingVertical: 16,
    // paddingHorizontal: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignSelf:'center',
  }

  const conditionNodeStyles: StyleProp<ViewStyle> = {
    height:'auto',
    width:'auto',
    padding:16
  };

  const actionNodeStyles: StyleProp<ViewStyle> = {
    // flexDirection:'row',
    padding:16
  };

  const customStyles: StyleProp<ViewStyle> = {
    ...(props?.isOperationNode? operationNodeStyles:{}),
    ...(props?.isConditionNode? conditionNodeStyles:{}),
    ...(props?.isActionNode? actionNodeStyles:{})
  }

  const backgroundColorByNodeType = () => {
    if (props?.isOperationNode) {
      return (Colors.FoldPixel.GRAY50)
    }
    else if (props?.isConditionNode) {
      return (Colors.FoldPixel.ACCENT_LIGHT_PINK)
    }
    else if (props?.isActionNode) {
      return (Colors.FoldPixel.PRIMARY100)
    }
    else {
      return ('#FFFFFF')
    }
  }

  const hoverColorByNodeType = () => {
    if (props?.isOperationNode) {
      return (Colors.FoldPixel.GRAY100)
    }
    else if (props?.isConditionNode) {
      return (Colors.FoldPixel.ACCENT_DARK_PINK_20)
    }
    else if (props?.isActionNode) {
      return (Colors.FoldPixel.PRIMARY_20)
    }
    else {
      return ('#FFFFFF')
    }
  }

  const styleText:any= {overflowWrap: 'break-word' ,color:props.isNodeEnabled? Colors.FoldPixel.GRAY400 : Colors.FoldPixel.GRAY200, fontSize:14, flexWrap:'wrap'}

  const RenderNodesByType = () => {
    if(props?.isConditionNode){
      return (
        <div className={props.isNodeEnabled? 'workflow-condition-nodes' : ''}>
        <View
          style={customStyles}
          flexDirection={'row'}
          flex={1}
        >
          <HStack alignItems="center" space={4} flex={1}>
            <View>
              <CircleIconView
                iconInfo={props.iconInfo}
                nodeCategory={props.nodeCategory || NodeCategory.moment}
                backgroundColor= {Colors.FoldPixel.TRANSPARENT}
                borderColor= {Colors.FoldPixel.TRANSPARENT}
                iconColor={props.isNodeEnabled? Colors.FoldPixel.ACCENT_DARK_PINK : Colors.FoldPixel.GRAY200}
                size='32px'
              />
            </View>
            <VStack flex={1}>
              <Text style={styleText}>{props.title}</Text>
              <Text color={Colors.FoldPixel.GRAY400} style={styles.fontSize14}>{props.description}</Text>
            </VStack>
          </HStack>
        </View>
        </div>
      )
    }
    else if (props?.isActionNode) {
      return (
        <div className={props.isNodeEnabled? 'workflow-action-nodes' : ''}>
        <Tooltip label={props?.tooltip || ''} isDisabled={!props?.tooltip} maxWidth={300} placement='right' hasArrow>
        <View
          style={customStyles}
          flexDirection={'row'}
          flex={1}
        >
          <HStack alignItems="center" space={4} flex={1}>
            <View>
              <CircleIconView
                iconInfo={props.iconInfo}
                nodeCategory={props.nodeCategory || NodeCategory.moment}
                backgroundColor={Colors.FoldPixel.TRANSPARENT}
                borderColor={'transparent'}
                iconColor={props.isNodeEnabled? Colors.FoldPixel.PRIMARY300 : Colors.FoldPixel.GRAY200}
                size='32px'
              />
            </View>
            <VStack overflowWrap={'normal'} flex={1}>
              {<Text style={styleText}>{props.title}</Text>}
              {props.description && <Text color={Colors.FoldPixel.GRAY400}>{props.description}</Text>}
            </VStack>
            {/* <MaterialIcons
            name={'drag-indicator'}
            size={22}
            color={Colors.Custom.Gray500}
          /> */}
          </HStack>
        </View>
        </Tooltip>
        </div>

      )
    }
    else if(props?.isOperationNode){
      return(
        <div className={props.isNodeEnabled? 'workflow-operation-nodes' : ''}>
        <View
          flex={1}
          style={customStyles}
          flexDirection={'row'}
        >
          <VStack alignItems="center" space={2} flex={1}>
            <View>
              <CircleIconView
                iconInfo={props.iconInfo}
                nodeCategory={props.nodeCategory || NodeCategory.moment}
                backgroundColor={Colors.FoldPixel.TRANSPARENT}
                borderColor={Colors.FoldPixel.TRANSPARENT}
                iconColor={Colors.FoldPixel.GRAY300}
                size='32px'
              />
            </View>
            <VStack >
              <Text color={Colors.FoldPixel.GRAY400}>{props.title}</Text>
              <Text color={Colors.FoldPixel.GRAY400}>{props.description}</Text>
            </VStack>
          </VStack>
        </View>
        </div>
      )
    }
    return <></>
  }

  return (
    <View>
      <Pressable
        backgroundColor={!props.isNodeEnabled ? 'white' : backgroundColorByNodeType()}
        borderTopRadius={
          (props?.isActionNode || props?.isConditionNode)
            ? props?.isFirstNode ? BORDER_RADIUS : 0
            : undefined
        }
        borderBottomRadius={
          (props?.isActionNode || props?.isConditionNode)
            ? props?.isLastNode ? BORDER_RADIUS : 0
            : undefined
        }
        borderLeftRadius={
          (props?.isOperationNode)
            ? props?.isFirstNode ? BORDER_RADIUS : 0
            : undefined
        }
        borderRightRadius={
          (props?.isOperationNode)
            ? props?.isLastNode ? BORDER_RADIUS : 0
            : undefined
        }
        _hover={{
          backgroundColor: (!props.isNodeEnabled ? '#FFFFFF' : hoverColorByNodeType()),
          borderTopRadius:
            (props?.isActionNode || props?.isConditionNode)
              ? props?.isFirstNode ? BORDER_RADIUS : 0
              : undefined,

          borderBottomRadius:
            (props?.isActionNode || props?.isConditionNode)
              ? props?.isLastNode ? BORDER_RADIUS : 0
              : undefined,

          borderLeftRadius:
            (props?.isOperationNode)
              ? props?.isFirstNode ? BORDER_RADIUS : 0
              : undefined,

          borderRightRadius:
            (props?.isOperationNode)
              ? props?.isLastNode ? BORDER_RADIUS : 0
              : undefined
        }}
        onPress={() => {
          if (props.onClick) {
            props.onClick();
          }
        }}
        // flex={1}
      >
        <div className={props.isNodeEnabled ? '' : 'not-allowed'}>
          <RenderNodesByType/>
        </div>
      </Pressable>
    </View>
    )
};

export default NewLibNodeCard;
