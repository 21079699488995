import {StyleSheet} from 'react-native';
import {Colors} from '../../../../../../styles';

export const getStyles = ({topValue}: {topValue: number}) => {
  return StyleSheet.create({
    messageReplyBodyElemView: {
      borderColor: Colors.Custom.BorderColor,
      borderBottomWidth: 1,
    },
    tabContainer: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    editorContainer: {
      flexDirection: 'row',
      flex: 1,
    },
    editorView: {
      flex: 1,
    },
    textArea: {
      color: Colors.Custom.TextColor,
      fontSize: 18,
    },
    cannedResponseContainer: {
      position: 'absolute',
      zIndex: 99,
      width: '70%',
      left: 40,
      top: topValue,
      justifyContent: 'flex-start',
    },
  });
};

export const cssProperties: Record<string, React.CSSProperties> = {
  upload: {
    background: '',
  },
  drawer: {
    zIndex: 1000,
  },
  tab: {
    borderWidth: 0,
  },
  tabPane: {
    fontWeight: '500',
  },
};
