import {Formio} from '@foldhealth/formiojs';
import ConditionalComponents from '../EditFormFields/ConditionalFields';
import KeyField from '../EditFormFields/KeyField';
import SearchableComponentFields from '../EditFormFields/SearchableComponentFields';

Formio.Components.components.button.editForm = function () {
  return {
    key: 'display',
    components: [
      {
        type: 'oldtextfield',
        key: 'label',
        label: 'Label',
        input: true,
        validate: {
          required: true,
        },
      },
      ...SearchableComponentFields,
      {
        type: 'select',
        key: 'action',
        label: 'Action',
        input: true,
        dataSrc: 'values',
        tooltip: 'This is the action to be performed by this button.',
        data: {
          values: [
            {label: 'Submit', value: 'submit'},
            {label: 'Reset', value: 'reset'},
          ],
        },
      },
      {
        type: 'select',
        key: 'theme',
        label: 'Theme',
        input: true,
        tooltip: 'The color theme of this button.',
        dataSrc: 'values',
        data: {
          values: [
            {label: 'Primary', value: 'primary'},
            {label: 'Secondary', value: 'secondary'},
            {label: 'Info', value: 'info'},
            {label: 'Success', value: 'success'},
            {label: 'Danger', value: 'danger'},
            {label: 'Warning', value: 'warning'},
          ],
        },
      },
      ...ConditionalComponents,
      ...KeyField,
    ],
  };
};
