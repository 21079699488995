export const MESSAGE_BOX_CONSTANT = {
  CLICK_HERE: 'Click here'
}

export const MESSAGE_BOX_AVATAR_STYLE = {
  avatarSize: '7',
  textStyle: {
    fontSize: 12,
    fontWeight: '500',
  },
};

export const CUSTOM_VIEWER_LINK_ATTRIBUTES = {
  target: '_blank',
};
