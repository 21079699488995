import React, {useEffect, useMemo} from 'react';
import {IBookingWidgetData} from '../AppointmentBookingWidget';
import SlotsGroup from '../../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/SlotsGroup';
import {
  DATE_FORMATS,
  GROUP_MEMBER_TYPE,
  SCREEN_CONTEXT,
} from '../../../../constants';
import {FlatList, Heading, HStack, Text, View, VStack} from 'native-base';
import {ISlot} from '../../../common/CalendarWidget/BookingWorkflows/BookAppointment/Interfaces';
import Stack from '../../../common/LayoutComponents/Stack';
import {Collapse, Skeleton} from 'antd';
import {Colors} from '../../../../styles';
import {getMomentObj} from '../../../../utils/DateUtils';
import {DisplayCardAvatar} from '../../../common/DisplayCard/DisplayCardAvatar';
import {IUser} from '../../../../Interfaces';

interface IWidgetDateAndSlotView {
  bookingData: IBookingWidgetData;
  selectedTimezone: string;
  shortLocationName: string;
  slotsLoading: boolean;
  handleSlotSelection: (slot: ISlot | any, user: IUser) => void;
  calendarHeaderTextElem: () => JSX.Element;
  renderTimeSlotErrors: () => JSX.Element;
}

const WidgetDateAndSlotView = (props: IWidgetDateAndSlotView) => {
  const {
    bookingData,
    selectedTimezone,
    slotsLoading,
    shortLocationName,
    handleSlotSelection,
    calendarHeaderTextElem,
    renderTimeSlotErrors,
  } = props;

  const dummyArray = useMemo(() => Array.from({length: 15}, (v, k) => k), []);
  const isMobile = useMemo(
    () => /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
    []
  );
  const {userWiseSlotMap, userList, userUuidListBasedOnSelectedLocation} = bookingData;
  const dateKey = getMomentObj(bookingData.selectedDate).format(
    DATE_FORMATS.DISPLAY_DATE_FORMAT
  );

  return (
    <VStack
      flex={['none', 'none', 1]}
      space={2}
      alignItems="stretch"
      justifyContent={'flex-start'}
      paddingY={2}
      maxH={400}
      overflowY={'scroll'}
    >
      <HStack paddingX={1} alignItems={'center'} justifyContent={'center'}>
        {calendarHeaderTextElem()}
      </HStack>
      <View>
        {!bookingData.slotError &&
          bookingData.slotList.length > 0 &&
          shortLocationName && (
            <Text
              fontSize={12}
              color={Colors.FoldPixel.GRAY300}
            >{`Showing slots for ${shortLocationName}`}</Text>
          )}
      </View>

      {slotsLoading && (
        <Stack
          direction="row"
          style={{
            width: '100%',
            flexWrap: 'wrap',
          }}
        >
          {dummyArray.map((item, index) => (
            <Skeleton.Button
              active
              size="small"
              style={{width: 80, margin: 4}}
            />
          ))}
        </Stack>
      )}

      <div className="slots">
        {bookingData.slotLoading && (
          <Stack
            direction="row"
            style={{
              width: '100%',
              flexWrap: 'wrap',
            }}
          >
            {dummyArray.map((item, index) => (
              <Skeleton.Button
                active
                size="small"
                style={{width: 80, margin: 4}}
              />
            ))}
          </Stack>
        )}
        <VStack paddingLeft={isMobile ? 30 : 0} maxH={'100%'}>
          {!bookingData.slotLoading &&
            !bookingData.slotError &&
            userWiseSlotMap.size > 0 &&
            userUuidListBasedOnSelectedLocation?.length > 0 && (
              <Collapse
                accordion
                className="custom-collapse"
                style={{width: '100%'}}
                expandIconPosition="end"
              >
                {userUuidListBasedOnSelectedLocation.map((uuid) => {
                  const userSlotMap = userWiseSlotMap?.get(uuid) || {};
                  const slotsForSelectedDate = userSlotMap[dateKey] || [];
                  const user = bookingData.userList.find((user) => user.uuid === uuid) as IUser;
                  return (
                    <Collapse.Panel
                      key={uuid}
                      collapsible={slotsForSelectedDate.length > 0 ? undefined : 'disabled'}
                      header={
                        <HStack alignItems={'center'} px={2}>
                          <DisplayCardAvatar
                            avatarStyle={{
                              avatarSize: '6',
                              textStyle: {fontSize: 10},
                            }}
                            isLetterAvatarShow
                            userData={{
                              userId: user?.id as string,
                              userType: GROUP_MEMBER_TYPE.USER,
                              userName: user?.name,
                            }}
                          />
                          <Text
                            fontSize={14}
                            ml={1}
                            color={Colors.FoldPixel.GRAY400}
                          >
                            {user.name}
                          </Text>
                        </HStack>
                      }
                      extra={
                        <View
                          backgroundColor={Colors.FoldPixel.GRAY50}
                          paddingX={1}
                          borderRadius={4}
                          borderColor={Colors.FoldPixel.GRAY100}
                          borderWidth={0.5}
                        >
                          <Text
                            fontSize={12}
                            color={
                              slotsForSelectedDate.length > 0
                                ? Colors.FoldPixel.GRAY200
                                : Colors.FoldPixel.GRAY150
                            }
                          >
                            {slotsForSelectedDate.length > 0
                              ? `${slotsForSelectedDate.length} Slots available`
                              : 'No slots available'}
                          </Text>
                        </View>
                      }
                    >
                      {slotsForSelectedDate.length > 0 ? (
                        <SlotsGroup
                          skipGrouping
                          selectedSlotDate={bookingData.selectedDate}
                          selectedSlot={bookingData.slot}
                          selectedTimezone={selectedTimezone}
                          userSlots={slotsForSelectedDate}
                          handleSlotSelection={(slot) =>
                            handleSlotSelection(slot, user)
                          }
                          screenContext={SCREEN_CONTEXT.APPOINTMENT_WIDGET}
                        />
                      ) : (
                        <Text
                          p={2}
                          fontSize={14}
                          color={Colors.FoldPixel.GRAY400}
                        >
                          No slots available
                        </Text>
                      )}
                    </Collapse.Panel>
                  );
                })}
              </Collapse>
            )}
        </VStack>
        {!bookingData.slotLoading && <HStack px={2} justifyContent='center'>{renderTimeSlotErrors()}</HStack>}
      </div>
    </VStack>
  );
};

export default WidgetDateAndSlotView;
