import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {CommonDataContext} from '../../../../../../context/CommonDataContext';
import {IFormCommonData} from '../CustomWrapper/CustomWrapper';
import {IVitalData} from '../../../../../../Interfaces';
import {
  FilterTypes, HOME_MONITORING_VITALS, getAcitivityPromiseList, getDateRangeForInitCall, getFilteredPinnedWearableList, VITAL_API_DATE_FORMAT, addOrUpdateSubstractTime, enabledVitalsForHomeMonitoringWithConfigs, getFormattedActivityResponseData, getFormattedVitalGraphData,
  getLastRecordedDate,
  getPromiseListWithDateRange,
  getStartAndEndDateBasedOnFilterAndAction,
  getLastRecordedDataMapKey,
  checkWearableSettingExist,
  getOnlyFormattedSleepVitalGraphData
} from './utils';
import {CapabilityResource, FormError} from '../CustomWrapper/CustomComponentHelper';
import {IHealthComponent} from '../CustomWrapper/interfaces';
import {IVitals} from '../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/interfaces';
import {getMomentObj, isTodayDate} from '../../../../../../utils/DateUtils';
import {getActivities, getActivityPromise, getLatestRecordForObservation, getVitalsWithFilters} from '../../../../../../services/CommonService/AidBoxService';
import {ActivityLoincCodes, GRAPH_TYPES} from '../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailTables/Activities/Activities';
import {getFormattedActivityData, getPatientData} from '../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailTables/Activities/ActivityUtils';
import { getEHRCapability, getUserUUID } from '../../../../../../utils/commonUtils';
import { addOrUpdateRelevantClinicalCodes, getRelevantClinicalCodes } from '../../../../../../services/CommonService/OrderService';
import { RESOURCE_CONFIGURATION_TYPES, RESOURCE_TYPES } from '../../../../../PersonOmniView/LeftContainer/PAMIConstants';
import { IDateRange, ILastRecordedData, IWearableSetting, IWearableSettingListItem, ObservationSortBy, ObservationSourceType, VitalDataSubFilterMap, VitalGraphData } from './interface';
import { CINICAL_SECTIONS_FILTER_TAB_CODES } from '../../../../../PatientOmniView/Sections/ClinicalSection/ClinicalSectionConstants';
import {getFormattedVitalData} from '../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailTables/DetailTableUtils';
import {Vital} from '../../../../../../utils/VitalUtils';
import { getDiffInHours } from './SleepUtils';
import { IObservation } from '../Vitals/interfaces';
import { getAccountConfigCapability } from '../../../../../../utils/capabilityUtils';

interface IHomeMonitorViewState extends IHealthComponent {
  vitalList: IVitals[];
  graphList: VitalGraphData[];
  showGraphs?: boolean;
  selectedTabCode: keyof typeof FilterTypes;
  dateRange?: IDateRange
  showSettingsView?: boolean;
  selectedFilterTab: string;
  wearableSetting: IWearableSetting;
  updatedWearableSetting: IWearableSetting;
  lastRecordedDateMap: Map<string, ILastRecordedData>;
  preferenceLoading?: boolean;
  showPinnedIntro?: boolean;
  pinnedWearableConfigLoading?: boolean;
  wearableSettingsFetched?: boolean;
  fetchedVitalResponses?: any[];
  fetchedActivityResponses?: any[];
  subFilter?: VitalDataSubFilterMap;
  fetchedSleepData?: IObservation[];
}

interface IHomeMonitoringParams {
  isTrendsView: boolean;
  patientId?: string;
  defaultCollapse?: boolean;
  allowedVitalList?: any[];
  locationId?: string;
  ccmDate?: string;
  accountId?: string | number;
}

const useHomeMonitoring = (params: IHomeMonitoringParams) => {
  const {isTrendsView, patientId, defaultCollapse, allowedVitalList, locationId, ccmDate, accountId} = params;
  const componentRef = useRef();
  const context = useContext(CommonDataContext);
  const ehrCapabilities = context.ehrCapabilities || [];
  const allowedWearableList = getAccountConfigCapability(CapabilityResource.wearable)?.abilities?.allowedWearableList || [];
  const userUuid = context?.userData?.uuid;
  const [componentState, setComponentState] = useState<IHomeMonitorViewState>({
    loading: false,
    collapsed: !!defaultCollapse,
    showSavingIndicator: false,
    editModalVisible: false,
    editModalCollapse: false,
    showPatientReportedRecords: false,
    vitalList: [],
    activePanels: [],
    graphList: [],
    showSettingsView: false,
    selectedFilterTab: CINICAL_SECTIONS_FILTER_TAB_CODES.ALL,
    wearableSetting: {},
    updatedWearableSetting: {},
    lastRecordedDateMap: new Map(),
    selectedTabCode: FilterTypes.Week as keyof typeof FilterTypes,
    dateRange: getDateRangeForInitCall(FilterTypes.Week as keyof typeof FilterTypes),
    showPinnedIntro: false,
    wearableSettingsFetched: false

  });
  const getVitalList = (): IVitalData[] => {
    const vitals = HOME_MONITORING_VITALS || [];
    return vitals.filter((item) => !item.isHidden);
  };

  const getPinnedWearableList = () => {
    setComponentState((prev) => ({
      ...prev,
      pinnedWearableConfigLoading: true,
    }));
    getRelevantClinicalCodes(RESOURCE_CONFIGURATION_TYPES.HOME_MONITORING, RESOURCE_TYPES.OBSERVATION, undefined, locationId)
    .then((response) => {
      const wearableSetting = response?.data?.configValue?.relevantData || {};
      const isPinnedWearableExist = checkWearableSettingExist(wearableSetting, allowedWearableList);

      setComponentState((prev) => ({
        ...prev,
        wearableSetting: wearableSetting,
        updatedWearableSetting: wearableSetting,
        selectedFilterTab: isPinnedWearableExist ? CINICAL_SECTIONS_FILTER_TAB_CODES.RELEVANT : CINICAL_SECTIONS_FILTER_TAB_CODES.ALL,
        pinnedWearableConfigLoading: false,
        wearableSettingsFetched: true
      }));
    })
    .catch((error) => {
      setComponentState((prev) => ({
        ...prev,
        pinnedWearableConfigLoading: false,
        wearableSettingsFetched: true
      }));
    });
  }

  const fetchData = async (
    patientId: string,
  ) => {
    try {
      setComponentState((prev) => ({...prev, loading: true}));
      let filteredWearableList = allowedWearableList;

      if(componentState.selectedFilterTab === CINICAL_SECTIONS_FILTER_TAB_CODES.RELEVANT){
        filteredWearableList = getFilteredPinnedWearableList(componentState.wearableSetting, allowedWearableList)
      }

      const promiseList = getPromiseListWithDateRange({
        patientId,
        locationId: locationId,
        dateRange: componentState.dateRange,
        allowedWearableList: filteredWearableList,
      }) || [];
      const filteredEnabledVitalsForHomeMonitoringWithConfigs = enabledVitalsForHomeMonitoringWithConfigs.filter((item) =>
        filteredWearableList.find(
          (wearable) => wearable.code === item.code && wearable.enabled,
        ))

      const initialResponses = await Promise.all(promiseList);

      const additionalPromises: Promise<any>[] = [];
      const completeResponsesMap = new Map();

      const startDate = componentState.dateRange?.start
        ? getMomentObj(componentState.dateRange?.start).startOf('day').toISOString()
        : undefined;
      const endDate = componentState.dateRange?.end
        ? getMomentObj(componentState.dateRange?.end).endOf('day').toISOString()
        : undefined;

      initialResponses.forEach((response, index) => {
        const totalCount = response.data.total;
        const vital = filteredEnabledVitalsForHomeMonitoringWithConfigs[index];
        completeResponsesMap.set(vital.code, response);

        if (totalCount > 1000) {
          const additionalCalls = Math.ceil((totalCount - 1000) / 1000);
          for (let i = 1; i <= additionalCalls; i++) {
            additionalPromises.push(
              getVitalsWithFilters(
                patientId,
                vital.code,
                1000,
                i * 1000,
                locationId,
                startDate,
                endDate,
                ObservationSourceType.wearable,
                ObservationSortBy.DESC,
                vital.code === Vital.sleep ? 'activity' : undefined,
                true,
              ).then(additionalResponse => ({
                code: vital.code,
                data: additionalResponse.data.entry || []
              }))
            );
          }
        }
      });

      if (additionalPromises.length > 0) {
        const additionalResponses = await Promise.all(additionalPromises);
        additionalResponses.forEach(({ code, data }) => {
          const existingResponse = completeResponsesMap.get(code);
          if (existingResponse) {
            existingResponse.data.entry = (existingResponse.data.entry || []).concat(data);
          }
        });
      }

      const completeResponses = filteredEnabledVitalsForHomeMonitoringWithConfigs.map(vital =>
        completeResponsesMap.get(vital.code)
      ).filter(Boolean);

      const reponseObjects = getFormattedVitalGraphData(
        completeResponses,
        getVitalList(),
        componentState?.dateRange as IDateRange,
        filteredEnabledVitalsForHomeMonitoringWithConfigs,
        componentState.selectedTabCode,
        ccmDate,
        componentState.subFilter,
        isTrendsView
      );

      let sleepData: any[] = [];

      completeResponses.forEach((res, index) => {
          const data = res?.data?.entry || [];
          const vital = filteredEnabledVitalsForHomeMonitoringWithConfigs[index]?.code;
          if(vital === Vital.sleep) {
            sleepData = data.map((item: any) => item.resource);
          }
      })

      const activityDataPromisList = getAcitivityPromiseList({
        patientId,
        dateRange: componentState.dateRange,
        locationId: locationId,
        allowedWearableList,
      });

      const activityDataResponse = await Promise.all(activityDataPromisList);
      const formattedActivityData =
        getFormattedActivityResponseData(
          activityDataResponse,
          patientId,
          componentState.selectedTabCode,
        filteredWearableList,
          componentState.dateRange,
          ccmDate,
        ) || [];

      setComponentState((prev) => ({
        ...prev,
        graphList: [...reponseObjects, ...formattedActivityData],
                fetchedSleepData: sleepData,
        loading: false,
      }));
    } catch (err) {

      setComponentState((prev) => ({
        ...prev,
        formError: FormError.existingDataAPIFail,
        loading: false,
      }));
    }
  };
  const onSaveSettingsClick = () => {
    setComponentState((prev) => ({
      ...prev,
      showSavingIndicator: true,
    }));

    addOrUpdateRelevantClinicalCodes(
      {
        configCode: RESOURCE_CONFIGURATION_TYPES.HOME_MONITORING,
        configValuePayload: {
          relevantData: componentState.updatedWearableSetting
        },
        userId: userUuid,
        resourceType: RESOURCE_TYPES.OBSERVATION,
        locationId: locationId
      },
      (response) => {
        setComponentState((prev) => ({
          ...prev,
          showSavingIndicator: false,
          formError: undefined,
          showSettingsView: false,
          wearableSetting: componentState.updatedWearableSetting
        }));
      },
      (error) => {

        setComponentState((prev) => ({
          ...prev,
          showSavingIndicator: false,
          formError: undefined,
          showSettingsView: false
        }));
      }
    );
  }
  const fetchLastRecordedDates = async (patientId: string) => {
    const filteredEnabledVitalsForHomeMonitoringWithConfigs =
      enabledVitalsForHomeMonitoringWithConfigs.filter((item) =>
        allowedWearableList.find(
          (wearable) => wearable.code === item.code && wearable.enabled,
        ),
      ) || [];
    const promiseData: {
      index: number;
      promise: Promise<any>;
    }[] = [];
    filteredEnabledVitalsForHomeMonitoringWithConfigs.forEach(
      (vital, index) => {
        promiseData.push({
          index: index,
          promise: getVitalsWithFilters(
            patientId,
            vital.code,
            1,
            0,
            locationId,
            undefined,
            undefined,
            ObservationSourceType.wearable,
            ObservationSortBy.DESC,
            vital.code === Vital.sleep ? 'activity' : undefined
          ),
        });
      },
    );
    const reponseList = await Promise.all(
      promiseData.map((item) => item.promise),
    );

    const lastrecordDateMap = new Map<string, ILastRecordedData>();

    reponseList.forEach((res, index) => {
      const vitalConfig =
        filteredEnabledVitalsForHomeMonitoringWithConfigs[index];
      const key = getLastRecordedDataMapKey(vitalConfig);
      const vital =
        HOME_MONITORING_VITALS.find(
          (item) => item.loinc === vitalConfig.code,
        ) || {};
      let data = res?.data?.entry || [];
      data = data.map((item: any) => item.resource);
      const vitalData = getFormattedVitalData(
        data,
        getVitalList(),
        undefined,
        undefined,
      );

      let value = parseInt(vitalData?.[0]?.value).toFixed(0);
      if(key === Vital.sleep) {
        if(vitalData?.[0]?.effectivePeriod) {
          value = getDiffInHours(vitalData?.[0]?.effectivePeriod)
        }
        else {
          value = ''
        }
      }


      lastrecordDateMap.set(key, {
        value: value,
        date: getLastRecordedDate(vitalData?.[0]?.date),
      });
    });

    const activityPromiseList:Promise<any>[] = [];
    const activities = [
      {
        code: ActivityLoincCodes.DAILY_ACTIVITY,
        componentCode: ActivityLoincCodes.CALORIES,
      },
      {
        code: ActivityLoincCodes.DAILY_ACTIVITY,
        componentCode: ActivityLoincCodes.DAILY_STEPS,
      },
      {
        code: ActivityLoincCodes.EXERCISE_ACTIVITY,
        componentCode: ActivityLoincCodes.DURATION,
      },
    ];
    activities.forEach((activity, index) => {
      if (accountId) {
        activityPromiseList.push(
          getLatestRecordForObservation(
            accountId,
            patientId,
            activity.code,
            activity.componentCode,
            1,
            ObservationSourceType.wearable,
            ObservationSortBy.DESC,
            "activity"
          ),
        );
      }
    });

    const activityResponseList = await Promise.all(activityPromiseList);

    activityResponseList.forEach((res, index) => {
      let data = res?.data?.entry || [];
      data = data.map((item: any) => item.resource);
      const vitaldata = getFormattedVitalData(data, getVitalList(), undefined, undefined);

      const activityLoincCode = activities[index].code;
      if (activityLoincCode === ActivityLoincCodes.DAILY_ACTIVITY) {
        const step = vitaldata.find(item => item.text === Vital.steps);
        const calories = vitaldata.find((item) => item.text === Vital.calories);

        const stepKey = getLastRecordedDataMapKey({
          code: GRAPH_TYPES.Step,
        });

        const caloriesKey = getLastRecordedDataMapKey({
          code: GRAPH_TYPES.Calories,
        });

        lastrecordDateMap.set(stepKey, {
          value: parseInt(step?.value ?? '').toFixed(0),
          date: getLastRecordedDate(step?.date || ''),
        });

        const caloriesValue =
        typeof calories?.value === 'number'
          ? (calories?.value as number).toFixed(2)
          : calories?.value;

        if (caloriesValue) {
          lastrecordDateMap.set(caloriesKey, {
              value: caloriesValue,
              date: getLastRecordedDate(calories?.date || ''),
            });
          }
      } else if (activityLoincCode === ActivityLoincCodes.EXERCISE_ACTIVITY) {
        const exercise = vitaldata.find((item) => item.text === Vital.duration);
        const exerciseKey = getLastRecordedDataMapKey({
          code: GRAPH_TYPES.Time,
        });

        lastrecordDateMap.set(exerciseKey, {
          value: parseInt(exercise?.value ?? '').toFixed(0),
          date: getLastRecordedDate(exercise?.date || ''),
        });
      }
    });

    setComponentState((prev) => ({
      ...prev,
      lastRecordedDateMap: lastrecordDateMap,
    }));

  };


  useEffect(() => {
    if (patientId) {
      fetchLastRecordedDates(patientId);
    }
  }, [patientId]);


  useEffect(() => {
    if (patientId && componentState.wearableSettingsFetched) {
      fetchData(
        patientId,
      );
    }
  }, [
    componentState.selectedTabCode,
    componentState.dateRange?.start,
    componentState.dateRange?.end,
    componentState.selectedFilterTab,
    componentState.wearableSetting
  ]);


  useEffect(()=> {
    if(patientId) {
      onSubFilterChange(patientId);
    }
  }, [componentState.subFilter]);

  useEffect(()=> {
    getPinnedWearableList();
  }, [])

  const getFilterHomeMonitoringConfigs = () => {
    const filteredEnabledVitalsForHomeMonitoringWithConfigs =
    enabledVitalsForHomeMonitoringWithConfigs.filter((item) =>
      allowedWearableList.find(
        (wearable) => wearable.code === item.code && wearable.enabled,
      ),
    );
    return filteredEnabledVitalsForHomeMonitoringWithConfigs;
  }


  const onSubFilterChange = (patientId: string) => {
    if(componentState?.subFilter) {
        if (
          componentState?.fetchedSleepData
        ) {
          const filteredEnabledVitalsForHomeMonitoringWithConfigs = getFilterHomeMonitoringConfigs();


          const vitalConfig = filteredEnabledVitalsForHomeMonitoringWithConfigs?.find((filteredVital)=> filteredVital?.code === Vital.sleep);

          if(vitalConfig) {

            const formattedSleepData = getOnlyFormattedSleepVitalGraphData(
              componentState?.fetchedSleepData,
              getVitalList(),
              componentState?.dateRange as IDateRange,
              vitalConfig,
              componentState.selectedTabCode,
              ccmDate,
              componentState.subFilter,
            );

            setComponentState((prev)=> {
              const updatedGraphList =  prev.graphList?.map((graph)=> {
                if(graph.code === Vital.sleep) {
                  return formattedSleepData;
                }
                return graph;
              })

              return {
                ...prev,
                graphList:updatedGraphList
              }
            })
          }
        }
    }
  }

  const onNextDate = () => {
    const currStartDate = getMomentObj(componentState.dateRange?.start || '');
    const currEndDate = getMomentObj(componentState.dateRange?.end || '');
    const dateRange: IDateRange = getStartAndEndDateBasedOnFilterAndAction(
      componentState.selectedTabCode,
      currStartDate,
      currEndDate,
      'add'
    );
    setComponentState((prev) => ({
      ...prev,
      dateRange,
    }));
  };

  const onPrevDate = () => {
    const currStartDate = getMomentObj(componentState.dateRange?.start || '');
    const currEndDate = getMomentObj(componentState.dateRange?.end || '');
    const dateRange: IDateRange = getStartAndEndDateBasedOnFilterAndAction(
      componentState.selectedTabCode,
      currStartDate,
      currEndDate,
      'subtract'
    );
    setComponentState((prev) => ({
      ...prev,
      dateRange,
    }));
  }

  const isNextDisabled = useMemo(() => {
    const endDate = getMomentObj(componentState.dateRange?.end || new Date());
    if (componentState.selectedTabCode === FilterTypes.Day) {
      return isTodayDate(endDate);
    }
    const currentDate = getMomentObj(new Date());
    const startDate = getMomentObj(componentState.dateRange?.start || new Date());
    return isTodayDate(endDate) || currentDate.isBetween(startDate, endDate) || componentState.loading;
  }, [componentState.dateRange?.start, componentState.selectedTabCode, componentState.dateRange?.end, componentState.loading]);

  const isPrevDisabled = componentState.loading;

  return {
    componentState,
    componentRef,
    onChangeComponentState: (data: Partial<IHomeMonitorViewState>) => {

      if (data.selectedTabCode) {
        data.dateRange = getDateRangeForInitCall(data.selectedTabCode);
      }

      setComponentState((prev) => ({
        ...prev,
        ...data,
      }));
    },
    onNextDate,
    onPrevDate,
    isNextDisabled,
    isPrevDisabled,
    onSaveSettingsClick,
    allowedWearableList
  };
};

export default useHomeMonitoring;
