import { Upload, UploadFile, Progress, Tooltip } from 'antd';
import { DiagnosticReport, RequestGroup } from 'fhir/r4';
import { View, Text, HStack, Spacer, VStack, Spinner, useToast, Pressable, Skeleton } from 'native-base'
import React, { useEffect, useState } from 'react'
import Feather from 'react-native-vector-icons/Feather';
import Ionicons from 'react-native-vector-icons/Ionicons';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { Colors } from '../../../../../../styles';
import { getBase64, UPLOAD_ACCEPTED_FILE_TYPES } from '../../../../LeftContainer/OtherDetails/PatientDocuments/UploadPatientDocument';
import { IReportAndResourceMap } from '../../../../LeftContainer/RecentActivity/OrdersInterface';
import { OrderType } from '../OrderConstants';
import { canShowUploadReportBtn, getOrderTypeFromOrder } from '../OrderUtils';
import CareStudioService from '../../../../../../services/CommonService/CareStudioService';
import { WORD_DOC_MIME_TYPES } from '../../../../../common/DocumentViewer/DocumentViewerHelper';
import { isWeb } from '../../../../../../utils/platformCheckUtils';
import { showToast, ToastType } from '../../../../../../utils/commonViewUtils';
import { IMediaLibraryData } from '../../../../../RightSideContainer/ContentManagement/MediaLibrary/interfaces';
import { RcFile } from 'antd/lib/upload';
import { DocumentViewer } from '../../../../../common/DocumentViewer/DocumentViewer';
import { addOrUpdateReport } from '../../../../../../services/CommonService/OrderService';
import './IndividualOrderView.css';
import { getDateStrFromFormat, getMomentObj } from '../../../../../../utils/DateUtils';
import { FHAlertDialog } from '../../../../../common/FHAlertDialog';
import { BUTTON_TYPE, DATE_FORMATS } from '../../../../../../constants';
import { SUPPORTED_EVENT_CODE } from '../../../../../../constants/WebSocketConst';
import { EventBus } from '../../../../../../utils/EventBus';
import {StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  uploadBtn: {
    fontSize: 16,
    color: Colors.Custom.Gray500,
    padding: 0,
  },
});

interface IComponentState {
  fileList: UploadFile<any>[];
  existingFileList: any[];
  isFileLoading: {[index: string]: boolean};
  isLoading: boolean;
  currentReport?: DiagnosticReport;
  existingFileLoading: boolean;
  showDeleteConfirmationModal: boolean;
  selectedFile?: any;
  // Preview file
  isPreviewVisible: boolean;
  previewTitle?: string;
  previewImage?: any;
  previewObj?: {url: string; type: string; fileMediaData: IMediaLibraryData};
  // Progress number
  progress: number;
}

interface IIndividualOrderViewProps {
  order: RequestGroup;
  action: any;
  isPreviewMode?: boolean;
  report?: IReportAndResourceMap;
  personData?: any;
}

const IndividualOrderView = (props: IIndividualOrderViewProps) => {
  const { order, action, report, isPreviewMode} = props;
  const toast = useToast();
  const careStudioInstance = CareStudioService.getCareStudioServiceInstance();
  const fileUploadService = careStudioInstance.fileUploadService;
  const service = careStudioInstance.careStudioAxiosService;

  const [componentState, setComponentState] = useState<IComponentState>({
    fileList: [],
    existingFileList: [],
    isFileLoading: {},
    isLoading: false,
    existingFileLoading: false,
    isPreviewVisible: false,
    progress: 0,
    currentReport: undefined,
    showDeleteConfirmationModal: false,
    selectedFile: undefined
  });

  useEffect(() => {
    const actionReport = report?.resources.find((item) => {
      return action?.resource?.reference && item.basedOn?.[0]?.reference === action?.resource?.reference
    });
    setComponentState((prev) => {
      return {
        ...prev,
        currentReport: actionReport,
      }
    })
  }, [props.report?.display]);

  useEffect(() => {
    if (componentState.currentReport?.media?.length) {
      const media = componentState.currentReport.media;
      const attachmentIds: string[] = [];
      for (const item of media) {
        if (item.link?.type === 'FoldAttachment' && item.link?.id) {
          attachmentIds.push(item.link.id);
        }
      }
      if (attachmentIds.length) {
        updateFileList(attachmentIds);
      }
    } else {
      setComponentState((prev) => ({...prev, existingFileList: [], fileList: []}));
    }
  }, [componentState.currentReport])

  const updateFileList = async (attachmentIds: string[]) => {
    if (!componentState.existingFileList.length) {
      setComponentState((prev) => ({...prev, existingFileLoading: true}));
    }
    try {
      const list = attachmentIds.map((attachmentId) => {
        return service.get(`/attachment/${attachmentId}?isPreview=true`);
      });
      const response = await Promise.all(list);
      const fileList: any[] = [];
      response.forEach((data) => {
        if (data.status == 200) {
          fileList.push(data.data);
        }
      });
      if (fileList.length) {
        const sortedFiles = fileList.sort((file1, file2) => {
          return getMomentObj(file2.createdOn).valueOf() - getMomentObj(file1.createdOn).valueOf();
        })
        setComponentState((prev) => ({...prev, existingFileList: sortedFiles, fileList: [], existingFileLoading: false}))
      } else {
        setComponentState((prev) => ({...prev, existingFileLoading: false}));
      }
    } catch {
      setComponentState((prev) => ({...prev, existingFileLoading: false}));
    }
  }

  function getIconByFileType(documentType?: string) {
    if (documentType === 'application/pdf') {
      return (
        <MaterialCommunityIcons
          name="file-pdf-box"
          size={32}
          color={Colors.primary['300']}
        />
      );
    } else if (documentType?.startsWith('image/')) {
      return (
        <MaterialCommunityIcons
          name="file-image"
          size={26}
          color={Colors.primary['300']}
        />
      );
    } else if (documentType && WORD_DOC_MIME_TYPES.includes(documentType)) {
      return (
        <MaterialCommunityIcons
          name="file-word"
          size={26}
          color={Colors.primary['300']}
        />
      );
    }
    return (
      <Ionicons name="document-text" size={26} color={Colors.primary['300']} />
    );
  }

  async function downloadFileAsBlob(file: any, downloadFile = false) {
    const attachmentId: string = (file as any).id;
    const service = careStudioInstance.careStudioAxiosService;
    setComponentState((prev) => {
      prev.isFileLoading[file.uid || file.id] = true;
      return {...prev, isFileLoading: prev.isFileLoading};
    });

    try {
      if (!file.blobUrl || WORD_DOC_MIME_TYPES.includes(file.type)) {
        const response = await service.get(`/attachment/${attachmentId}/file?isPreview=${downloadFile === false}`, {
          responseType: 'blob',
        });

        file.blob = response.data;
        file.blobUrl = URL.createObjectURL(file.blob);
        file.preview = await getBase64(response.data);
      }

      if (downloadFile) {
        if (isWeb()) {
          const link = document.createElement('a');
          link.href = file.blobUrl;
          link.download = file.name;
          link.click();
        } else {
          showToast(toast, 'Unable to download file', ToastType.error);
        }
      }

      setComponentState((prev) => {
        prev.isFileLoading[file.uid || file.id] = false;
        return {...prev, isFileLoading: prev.isFileLoading};
      });
    } catch (error) {

      showToast(toast, 'Error while opening file', ToastType.error);
      setComponentState((prev) => {
        prev.isFileLoading[file.uid || file.id] = false;
        return {...prev, isFileLoading: prev.isFileLoading};
      });
    }
  }

  async function convertToPdf(file: any) {
    const service = careStudioInstance.careStudioAxiosService;
    setComponentState((prev) => {
      prev.isFileLoading[file.uid || file.id] = true;
      return {...prev, isFileLoading: prev.isFileLoading};
    });

    try {
      const formData = new FormData();
      formData.append('file', file.originFileObj);
      const response = await service.post(`/attachment/convert-to-pdf`, formData, {
        responseType: 'blob',
      });

      file.blob = response.data;
      file.blobUrl = URL.createObjectURL(file.blob);
      file.preview = await getBase64(response.data);

      // if (downloadFile) {
      //   if (isWeb()) {
      //     const link = document.createElement('a');
      //     link.href = file.blobUrl;
      //     link.download = file.name;
      //     link.click();
      //   } else {
      //     showToast(toast, 'Unable to download file', ToastType.error);
      //   }
      // }

      setComponentState((prev) => {
        prev.isFileLoading[file.uid || file.id] = false;
        return {...prev, isFileLoading: prev.isFileLoading};
      });
    } catch (error) {

      showToast(toast, 'Error while opening file', ToastType.error);
      setComponentState((prev) => {
        prev.isFileLoading[file.uid || file.id] = false;
        return {...prev, isFileLoading: prev.isFileLoading};
      });
    }
  }

  async function handlePreview(file: UploadFile) {
    if (componentState.isFileLoading[(file as any).uid]) {
      return;
    }

    let fileUrl = '';

    if ((file as any).id && (!(file as any).blobUrl || WORD_DOC_MIME_TYPES.includes(file.type as string))) {
      await downloadFileAsBlob(file);
    }

    if (!(file as any).id && file?.type && WORD_DOC_MIME_TYPES.includes(file?.type)) {
      await convertToPdf(file);
    }

    const fileMediaData: IMediaLibraryData = {
      ...file,
      lastUpdated: file.lastModified?.toString() || '',
      mime: file.type as string,
      name: file.name,
      size: file.size as number,
    };

    if (!(file as any).blobUrl && !file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    if (file.type) {
      fileUrl = (file as any).blobUrl || file.preview || URL.createObjectURL(file.originFileObj as any);
    }

    setComponentState((prev) => ({
      ...prev,
      isPreviewVisible: true,
      previewTitle: file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1),
      ...(file.type && fileUrl &&
        {
          previewObj: {
            url: fileUrl || '',
            type: file.type as string,
            fileMediaData: fileMediaData,
          },
        }
      ),
    }));
  }

  function handlePreviewCancel(value = false) {
    setComponentState((prev) => ({
      ...prev,
      previewObj: undefined,
      isPreviewVisible: value,
      previewTitle: undefined,
      // previewImage: undefined,
    }));
  }

  const broadcastOrderEvent = (code: string) => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.broadcastEvent(code, {patientId: props.personData?.patientId || props.personData?.patientUuid});
  };

  async function uploadFile(files: UploadFile<any>[]) {
    setComponentState((prev) => ({...prev, isLoading: true}));
    const attachmentIds = await createAttachments(files);
    const existingAttachmentIds = componentState.existingFileList.map((attachment) => attachment.id) || [];
    await addOrUpdateDiagnosticReport([...existingAttachmentIds, ...attachmentIds]);
    broadcastOrderEvent(SUPPORTED_EVENT_CODE.ORDER_CREATED);
    showToast(toast, 'Attachment uploaded successfully', ToastType.success);
  }

  const onRemoveClick = (file: any) => {
    setComponentState((prev) => {
      return {
        ...prev,
        selectedFile: file,
        showDeleteConfirmationModal: true,
      }
    })
  }

  const handleRemoveAttachment = async (file: any) => {
    if (file.id) {
      const attachmentIds: string[] = [];
      componentState.existingFileList.filter((item) => {
        if (item.id !== file.id) {
          attachmentIds.push(item.id);
        }
      });
      setComponentState((prev) => {
        prev.isFileLoading[file.uid || file.id] = true;
        return {
          ...prev,
          isFileLoading: prev.isFileLoading,
        }
      });
      await addOrUpdateDiagnosticReport(attachmentIds);
      setComponentState((prev) => {
        prev.isFileLoading[file.uid || file.id] = false;
        return {
          ...prev,
          isLoading: false,
          isFileLoading: prev.isFileLoading,
        }
      });
    } else {
      setComponentState((prev) => {
        const fileList = prev.fileList.filter((item) => item.uid !== file.uid);
        return {
          ...prev,
          fileList: fileList,
        }
      })
    }
  }

  async function addOrUpdateDiagnosticReport(attachmentIds: string[]) {
    const requestGroupId = order.id;
    const serviceRequestId = action?.resource?.reference?.split('/')?.[1];
    const patientId = order?.subject?.reference?.split('/')?.[1];
    if (!requestGroupId || !serviceRequestId || !patientId) {
      setComponentState((prev) => ({...prev, isLoading: false}));
      showToast(
        toast,
        'Something went wrong. Please try again later',
        ToastType.error
      );
      return;
    }
    try {
      const response = await addOrUpdateReport({
        attachmentIds: attachmentIds,
        requestGroupId,
        serviceRequestId,
        patientId,
        reportName: action?.resource?.display || undefined,
        diagnosticReportId: componentState.currentReport?.id || undefined,
      });
      if (response.status == 200) {
        setComponentState((prev) => ({...prev, currentReport: attachmentIds.length ? response.data : undefined, isLoading: false}));
      } else {
        showToast(toast, 'Something went wrong', ToastType.error);
        setComponentState((prev) => ({...prev, isLoading: false}));
      }
    } catch (error) {

      showToast(toast, 'Something went wrong', ToastType.error);
      setComponentState((prev) => ({...prev, isLoading: false}));
    }
  }

  async function createAttachments(files: UploadFile<any>[]) {
    const formData = new FormData();
    files = files.filter((file) => !(file as any).id);
    if (!files?.length) {
      return [];
    }

    files.forEach((file: any) => formData.append('files', file.originFileObj));

    try {
      const res = await fileUploadService.post('', formData, {
        onUploadProgress: (progressEvent) => {
          const percent = Math.floor(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          setComponentState((prev) => ({...prev, progress: percent}));

          if (percent === 100) {
            setTimeout(
              () => setComponentState((prev) => ({...prev, progress: 0})),
              2000
            );
          }
        },
      });
      return res.data?.ids || [];
    } catch (err) {
      setComponentState((prev) => ({...prev, isLoading: false}));
      showToast(
        toast,
        'Something went wrong. Please try again later',
        ToastType.error
      );
    }
  }

  const getUploadButton = (order: RequestGroup, orderType: OrderType, action: any, noteId?: string): JSX.Element | undefined => {
    const canShowUploadButton = canShowUploadReportBtn(order);
    if (!canShowUploadButton) return;
    return (
      <Upload
        disabled={componentState.isLoading}
        maxCount={1}
        className="patient-report-upload-container"
        beforeUpload={() => false}
        onChange={(info) => {
          const tempFileList = [...info.fileList];
          setComponentState((prev) => ({
            ...prev,
            fileList: tempFileList,
          }));
          uploadFile(tempFileList);
        }}
        onRemove={(file) => {
          setComponentState((prev) => {
            const prevFileList = prev.fileList;
            const fileIndex = prevFileList.indexOf(file);
            const newFileList = prevFileList.slice();
            newFileList.splice(fileIndex, 1);
            return {
              ...prev,
              fileList: newFileList,
            };
          });
        }}
        accept={UPLOAD_ACCEPTED_FILE_TYPES}
      >
        <Tooltip title="Upload Report">
          <Feather
            name="upload"
            style={styles.uploadBtn}
          />
        </Tooltip>
      </Upload>
    );
  }

  return (
    <VStack space={2}>
      <HStack alignItems={'center'}>
        <Text
          my={1}
          color={Colors.Custom.Gray900}
          fontWeight={isPreviewMode ? 600 : 400}
          fontSize={14}
        >
          {action.resource?.display}
        </Text>
        <Spacer />
        {getUploadButton(order, getOrderTypeFromOrder(order), action)}
      </HStack>
      {componentState.existingFileLoading && <Skeleton height={6} borderRadius={6} />}
      {componentState.fileList.map((file, index) => {
        return <VStack>
        <HStack
          key={index}
          width={'100%'}
          space={2}
          marginRight={3}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Text
            size={'smMedium'}
            noOfLines={1}
            color={Colors.Custom.Gray500}
          >
            {file.name}
          </Text>
          <Spacer />
          {!componentState.isLoading && (
            <HStack space={2} alignItems="center">
              {componentState.isFileLoading[file.uid] && <Spinner size="sm" />}
              {!componentState.isFileLoading[file.uid] && (
                <HStack space={2} alignItems="center">
                  <Pressable onPress={() => onRemoveClick(file)}>
                    <Text color={Colors.primary[300]}>Remove</Text>
                  </Pressable>
                  <View width={0.4} height={4} backgroundColor={Colors.Custom.Gray300} />
                  {!componentState.isFileLoading[file.uid] && (
                    <Pressable onPress={() =>
                      handlePreview(file)
                    }>
                      <Text color={Colors.primary[300]}>View</Text>
                    </Pressable>
                  )}
                </HStack>
              )}
            </HStack>
          )}
        </HStack>
        {componentState.progress > 0 ? (
          <Progress percent={componentState.progress} strokeColor={Colors.primary[300]} />
        ) : null}
        </VStack>
      })}
      {componentState.existingFileList.map((file, index) => {
        return <HStack
          key={index}
          width={'100%'}
          space={2}
          marginRight={3}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <VStack flexShrink={1}>
            <Text fontSize={14} fontWeight={400} noOfLines={1} color={Colors.Custom.Gray500}>
              {file.name}
            </Text>
            {file.createdOn && (
              <Text fontSize={14} fontWeight={400} noOfLines={1} color={Colors.Custom.Gray500}>
                {`Uploaded on ${getDateStrFromFormat(file.createdOn, DATE_FORMATS.DIAGNOSTIC_REPORT_DATE_FORMAT)}`}
              </Text>
            )}
          </VStack>
          <Spacer />
          {!componentState.isLoading && (
            <HStack space={2} alignItems="center">
              {componentState.isFileLoading[file.uid || file.id] && <Spinner size="sm" />}
              {!componentState.isFileLoading[file.uid || file.id] && (
                <HStack space={2} alignItems="center">
                  <Pressable onPress={() => onRemoveClick(file)}>
                    <Text color={Colors.primary[300]}>Remove</Text>
                  </Pressable>
                  <View width={0.4} height={4} backgroundColor={Colors.Custom.Gray300} />
                  {!componentState.isFileLoading[file.uid || file.id] && (
                    <Pressable onPress={() =>
                      handlePreview(file)
                    }>
                      <Text color={Colors.primary[300]}>View</Text>
                    </Pressable>
                  )}
                </HStack>
              )}
            </HStack>
          )}
        </HStack>
      })}
      {
        componentState.previewTitle &&
        componentState.previewObj?.type &&
        componentState.isPreviewVisible &&
        <DocumentViewer
          fileName={componentState.previewTitle}
          fileType={componentState.previewObj.type}
          onClose={handlePreviewCancel}
          fileUrl={componentState.previewObj.url}
          isOpen={true}
        />
      }
      {componentState.showDeleteConfirmationModal && (
        <FHAlertDialog
          isOpen={componentState.showDeleteConfirmationModal}
          header={'Remove attachment?'}
          message={`Are you sure you want to remove ${componentState.selectedFile?.name || 'this attachment'}`}
          buttonActions={[
            {
              textLocalId: 'remove',
              buttonProps: {
                variant: BUTTON_TYPE.SECONDARY,
              },
              onPress: () => {
                handleRemoveAttachment(componentState.selectedFile);
                setComponentState((prev) => {
                  return {
                    ...prev,
                    showDeleteConfirmationModal: false,
                    selectedFile: undefined,
                  };
                });
              },
            },
            {
              textLocalId: 'cancel',
              buttonProps: {
                variant: BUTTON_TYPE.PRIMARY,
              },
              onPress: () => {
                setComponentState((prev) => {
                  return {
                    ...prev,
                    showDeleteConfirmationModal: false,
                    selectedFile: undefined,
                  };
                });
              },
            },
          ]}
        />
      )}
    </VStack>
  )
}

export default IndividualOrderView

