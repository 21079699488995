import {
  HStack,
  Pressable,
  Spinner,
  Text,
  Tooltip,
  VStack,
  View,
  useToast,
} from 'native-base';
import {Colors} from '../../../../../../styles';
import React from 'react';
import {handleMarkMentionAsUnread} from '../MessagingUtils';
import {ToastType, showToast} from '../../../../../../utils/commonViewUtils';
import {EventBus} from '../../../../../../utils/EventBus';
import {CUSTOM_MESSAGE_EVENT_CODES} from '../../../../../../constants/WebSocketConst';
import AtSignSvg from '../../../../../common/Svg/AtSignSvg';
import Close from '../../../../../../assets/svg/Close';
import { TestIdentifiers, testID } from '../../../../../../testUtils';
import { StyleSheet } from 'react-native';
// import '../../../../common/ChatUI/MessageBox/Message-box.css';
// import '../../../../common/ChatUI/MessageBox/MessageViewer.css';

export interface MentionBarProps {
  text: string;
  selectedConversationMentionId: string;
  handleClose: () => void;
}

const MentionBar = (props: MentionBarProps) => {
  const {text, handleClose} = props;
  const [state, setState] = React.useState({
    loading: false,
  });
  const toast = useToast();

  const finalText = text
    .replace(/ *\([^)]*\) */g, '')
    .replace(/[\])}[{(]/g, ' ');

  const handleMarkAsUnread = async () => {
    if (!props.selectedConversationMentionId) {
      return;
    }
    setState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    try {
      const mentionResp = await handleMarkMentionAsUnread([props.selectedConversationMentionId], false);
      if (mentionResp?.data?.updateConversationMentions?.returning?.length) {
        const eventBus = EventBus.getEventBusInstance();
        eventBus.broadcastEvent(CUSTOM_MESSAGE_EVENT_CODES.MARK_US_UNREAD_MENTION, {
          mentionUuid: props.selectedConversationMentionId,
          isRead: false
        })
      }
      handleClose();
      showToast(toast, 'Marked as unread', ToastType.success);
    } catch (error) {

      showToast(toast, 'Something went wrong.', ToastType.error);
    }
    setState((prev) => {
      return {
        ...prev,
        loading: false,
      };
    });
  };

  return (
    <HStack style={styles.container}>
      <HStack flex={0.5}>
        <VStack style={styles.atSignContainer} {...testID('image')}>
          <AtSignSvg size={20}/>
        </VStack>
        <VStack style={styles.textContainer}>
          <Tooltip label={finalText}>
          <Text
            noOfLines={1}
            style={styles.mentionText}
            {...testID('MentionTextMessage')}
          >
            {finalText}
          </Text>
          </Tooltip>
        </VStack>
      </HStack>

      <HStack
        flex={0.5}
        style={styles.actionContainer}
      >
        {state.loading ? (
          <View
            style={styles.spinnerContainer}
            {...testID(TestIdentifiers.lazyLoading)}
          >
            <Spinner />
          </View>
        ) : (
          <Pressable
            onPress={() => {
              handleMarkAsUnread();
            }}
            style={styles.markAsUnreadButton}
          >
            <Text
              style={styles.markAsUnreadText}
              {...testID('MarkAsUnread')}
            >
              {'Mark as unread'}
            </Text>
          </Pressable>
        )}

        <VStack style={styles.closeButtonContainer}>
          <Pressable
            onPress={() => {
              handleClose();
            }}
            {...testID(TestIdentifiers.closeBtn)}
          >
            <Close size={20} fill={Colors.Custom.Gray500}/>
          </Pressable>
        </VStack>
      </HStack>
    </HStack>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: Colors.Custom.Gray50,
    borderWidth: 1,
    borderColor: Colors.Custom.Gray200,
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  atSignContainer: {
    marginRight: 10,
  },
  textContainer: {
    width: '94%',
  },
  mentionText: {
    color: Colors.Custom.Gray500,
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 21,
  },
  actionContainer: {
    justifyContent: 'flex-end',
  },
  spinnerContainer: {
    marginRight: 12,
  },
  markAsUnreadButton: {
    marginRight: 12,
  },
  markAsUnreadText: {
    color: Colors.Custom.mainPrimaryPurple,
  },
  closeButtonContainer: {
    position: 'relative',
    right: 10,
  },
});

export default MentionBar;
