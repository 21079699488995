import React, {useContext, useEffect, useState} from 'react';
import Stack from '../common/LayoutComponents/Stack';
import {useLazyQuery} from '@apollo/client';
import {DATE_FORMATS, MLOV_CATEGORY} from '../../constants';
import {IContact} from '../RightSideContainer/TeamInbox/Conversations/interfaces';
import {getDateStrFromFormat} from '../../utils/DateUtils';
import {getCurrentPatientDeceasedData} from '../../services/CommonService/AidBoxService';
import {getFormDataFromLeadData} from '../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateUtils';
import {CommonDataContext} from '../../context/CommonDataContext';
import {
  getMlovId,
  getMlovListByCategory,
  getMlovObjectFromCode,
  getMlovValue,
} from '../../utils/mlovUtils';
import {TASK_STATUS, TASK_STATUS_CODES} from '../../constants/MlovConst';
import {TaskPanelType} from '../TaskCard/TaskEnum';
import {ITopBarData} from '../common/CareDashboard/CareDashboardTopBar/CareDashboardTopBar';
import {TaskViewType} from '../TaskModule/TaskInterfaces';
import SidecarPatientTaskManager from './SidecarPatientTaskManager';
import UserTaskManager from '../TaskModule/UserTaskManager/UserTaskManager';
import {LeadQueries} from '../../services';
import {Spinner, View, useToast} from 'native-base';
import {SidecarTitle} from './SidecarTitle';
import {ToastType, showToast} from '../../utils/commonViewUtils';
import {useIntl} from 'react-intl';
import TaskDataProvider from '../../context-provider/TaskDataProvider';
import TaskViewProvider from '../../context-provider/TaskViewProvider';
import { Dimensions } from 'react-native';

interface ISidecarTaskDrawerProps {
  contactId: string;
  onClose: () => void;
  isPatientContext?: boolean;
  onActionClick?: (action: string) => void;
  selectedAction: string;
}

interface ISidecarTaskDrawerComponentState {
  contactData?: IContact;
  contactPersonDeceasedDate: '';
  formattedContactData?: any;
  isLoading: boolean;
}
export const SidecarPatientDataContext = React.createContext<IContact | undefined>(undefined);

export const SidecarTaskDrawer = (props: ISidecarTaskDrawerProps) => {
  const {onClose, contactId, isPatientContext, onActionClick, selectedAction} = props;
  const {height} = Dimensions.get('window');

  const [componentState, setComponentState] =
    useState<ISidecarTaskDrawerComponentState>({
      contactPersonDeceasedDate: '',
      isLoading: false,
    });
  const toast = useToast();
  const intl = useIntl();

  const [topBarData, setTopBarData] = useState<ITopBarData>({
    start: getDateStrFromFormat(new Date(), DATE_FORMATS.DISPLAY_DATE_FORMAT),
    end: getDateStrFromFormat(new Date(), DATE_FORMATS.DISPLAY_DATE_FORMAT),
    addTask: false,
    panelType: TaskPanelType.INTERNAL,
  });
  const mlovData = useContext(CommonDataContext);

  const [GetContact] = useLazyQuery(LeadQueries.GetContact, {
    fetchPolicy: 'no-cache',
  });

  const initialTaskEntityTypes =
    getMlovListByCategory(MLOV_CATEGORY.TASK_ENTITY_TYPE) || [];

  const initialPriorityType =
    getMlovListByCategory(MLOV_CATEGORY.TASK_PRIORITY) || [];

  const taskStatusMlovs = getMlovListByCategory(MLOV_CATEGORY.TASK_STATUS);

  const acceptedStatusMlov = getMlovObjectFromCode(
    TASK_STATUS.ACCEPTED,
    taskStatusMlovs
  );

  const missedStatusMlov = getMlovObjectFromCode(
    TASK_STATUS_CODES.MISSED,
    taskStatusMlovs
  );

  const allStatusValue = getMlovValue(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'all'
  );
  const accessUserTypeId = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'UserPoolType',
    'access_user_task'
  );

  const accessUserTaskPoolTypeId = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'UserPoolType',
    'task_user_pool'
  );

  const allStatusUuid = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'all'
  );

  const [filter, setFilter] = useState({
    priorityTypeList: initialPriorityType,
    taskEntityTypeList: initialTaskEntityTypes,
    selectedStatusCodes: [acceptedStatusMlov?.code, missedStatusMlov?.code],
    formData: {
      selectedStatusList: [
        {...acceptedStatusMlov, value: 'Not Completed'},
        {...missedStatusMlov, value: 'missed'},
      ],
      selectedDueDate: allStatusValue,
      selectedDueDateUuid: allStatusUuid,
      start: getDateStrFromFormat(new Date(), DATE_FORMATS.DISPLAY_DATE_FORMAT),
      end: getDateStrFromFormat(new Date(), DATE_FORMATS.DISPLAY_DATE_FORMAT),
    },
  });

  useEffect(() => {
    if (isPatientContext && contactId) {
      setComponentState((prev) => {
        return {
          ...prev,
          isLoading: true,
        };
      });
      getContactData(contactId);
    }
  }, []);

  const updatePanelType = (panelType: TaskPanelType) => {
    setTopBarData((prev) => ({
      ...prev,
      panelType: panelType,
    }));
  };

  const getContactData = async (contactId: string) => {
    if (!contactId) {
      return;
    }

    try {
      const response = await GetContact({
        variables: {
          id: contactId,
        },
      });

      if (response?.data?.contact) {
        setComponentState((prev) => {
          return {
            ...prev,
            contactData: response?.data?.contact,
            contactType: response?.data?.contact?.contactType?.contactType,
            formattedContactData: getFormDataFromLeadData(
              response?.data?.contact,
              mlovData
            ),
          };
        });
        getPatientDeceasedData(response?.data?.contact);
      }
      setComponentState((prev) => {
        return {
          ...prev,
          isLoading: false,
        };
      });
    } catch (error) {
      showToast(
        toast,
        intl.formatMessage({id: 'apiErrorMsg'}),
        ToastType.error
      );
      setComponentState((prev) => {
        return {
          ...prev,
          isLoading: false,
        };
      });

    }
  };

  const getPatientDeceasedData = async (contactData: IContact) => {
    const deceasedDatePatient = await getCurrentPatientDeceasedData(
      contactData
    );

    setComponentState((prev) => {
      return {
        ...prev,
        deceasedDate: deceasedDatePatient,
      };
    });
  };

  return (
    <Stack direction="column" style={{flex: 1}}>
      {componentState.isLoading && (
        <View
          style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Spinner size={'lg'} />
        </View>
      )}
      <SidecarPatientDataContext.Provider
        value={componentState.contactData as IContact}
      >
        {isPatientContext && componentState?.formattedContactData && (
          <Stack direction="column">
            <SidecarTitle
              onClose={onClose}
              panelType={topBarData.panelType}
              patientUuid={componentState?.contactData?.uuid}
              titleId="tasks"
              paddingVertical={6}
              showCreateNewButton={true}
            />
            <TaskViewProvider isRenderCompactView>
              <SidecarPatientTaskManager
                contactData={componentState?.contactData}
                updatePanelType={updatePanelType}
                taskTopBarData={topBarData}
                contactType={componentState?.formattedContactData?.contactType}
                formattedContactData={componentState?.formattedContactData}
                filters={filter}
                onClose={onClose}
                viewType={TaskViewType.list}
                patientTaskScrollHeight={height - 170}
              />
            </TaskViewProvider>
          </Stack>
        )}
        {!isPatientContext && (
          <Stack direction="column">
            <SidecarTitle
              panelType={topBarData.panelType}
              patientUuid={componentState?.contactData?.uuid}
              onClose={onClose}
              titleId="tasks"
              paddingVertical={6}
              showCreateNewButton={true}
            />
            <TaskViewProvider isRenderCompactView>
              <TaskDataProvider>
                <UserTaskManager shouldNotNavigateToTabsPath={true} />
              </TaskDataProvider>
            </TaskViewProvider>
          </Stack>
        )}
      </SidecarPatientDataContext.Provider>
    </Stack>
  );
};
