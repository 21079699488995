
const UnityAiICloseIcon = (props: {customColor?: string, width?: string, height?: string}) => {
  const {customColor} = props;
  const strokeColor = customColor ? customColor : '#8C210F';
  return (<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.4998 24.5832C6.80375 24.5832 3.95571 24.5832 2.1855 22.813C0.416504 21.0452 0.416504 18.1959 0.416504 12.4998C0.416504 6.80375 0.416504 3.95571 2.1855 2.1855C3.95692 0.416504 6.80375 0.416504 12.4998 0.416504C18.1959 0.416504 21.044 0.416504 22.813 2.1855C24.5832 3.95692 24.5832 6.80375 24.5832 12.4998C24.5832 18.1959 24.5832 21.044 22.813 22.813C21.0452 24.5832 18.1959 24.5832 12.4998 24.5832ZM8.83859 8.83859C9.00851 8.66888 9.23885 8.57355 9.479 8.57355C9.71916 8.57355 9.9495 8.66888 10.1194 8.83859L12.4998 11.219L14.8803 8.83859C15.052 8.67851 15.2793 8.59136 15.5141 8.5955C15.7488 8.59964 15.9728 8.69475 16.1389 8.86079C16.3049 9.02683 16.4 9.25084 16.4042 9.48562C16.4083 9.7204 16.3212 9.94763 16.1611 10.1194L13.7807 12.4998L16.1611 14.8803C16.2501 14.9632 16.3215 15.0633 16.3711 15.1744C16.4206 15.2856 16.4472 15.4056 16.4494 15.5273C16.4515 15.649 16.4291 15.7698 16.3836 15.8827C16.338 15.9955 16.2701 16.098 16.1841 16.1841C16.098 16.2701 15.9955 16.338 15.8827 16.3836C15.7698 16.4291 15.649 16.4515 15.5273 16.4494C15.4056 16.4472 15.2856 16.4206 15.1744 16.3711C15.0633 16.3215 14.9632 16.2501 14.8803 16.1611L12.4998 13.7807L10.1194 16.1611C10.0365 16.2501 9.9364 16.3215 9.82524 16.3711C9.71407 16.4206 9.59407 16.4472 9.47238 16.4494C9.3507 16.4515 9.22983 16.4291 9.11699 16.3836C9.00415 16.338 8.90164 16.2701 8.81558 16.1841C8.72953 16.098 8.66168 15.9955 8.61611 15.8827C8.57053 15.7698 8.54814 15.649 8.55029 15.5273C8.55243 15.4056 8.57907 15.2856 8.6286 15.1744C8.67813 15.0633 8.74955 14.9632 8.83859 14.8803L11.219 12.4998L8.83859 10.1194C8.66888 9.9495 8.57355 9.71916 8.57355 9.479C8.57355 9.23885 8.66888 9.00851 8.83859 8.83859Z" fill="#825AC7"/>
  </svg>
  
  );
};

export default UnityAiICloseIcon;
