import React from 'react';
import { InputNumber, Select as AntSelect } from 'antd';
import { FormControl, HStack, Radio, Text, View, VStack, WarningOutlineIcon } from 'native-base';
import { useState } from 'react';
import { IInputElement } from '../../FlowComponent/StateNodes/FlowNodeInterface';
import { FREQUENCY_CODES } from '../../../../../constants/MlovConst';
import { reactStyles } from '../../workflowStyles';

interface IFrequencyObjectState {
  code: {
    value: string,
    isValid: boolean,
  };
  frequency: {
    value: any;
    isValid: boolean;
  };
  period: {
    value: any;
    isValid: boolean;
  };
  periodUnit: {
    value: any;
    isValid: boolean;
  };
  displayValue?: string;
}

const frequencyPeriodUnitCodes = {
  HOUR: 'hour',
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  YEAR: 'year',
};

export const frequencyPeriodUnitValuesByCode = {
  [frequencyPeriodUnitCodes.HOUR]: 'Hour',
  [frequencyPeriodUnitCodes.DAY]: 'Day',
  [frequencyPeriodUnitCodes.WEEK]: 'Week',
  [frequencyPeriodUnitCodes.MONTH]: 'Month',
  [frequencyPeriodUnitCodes.YEAR]: 'Year',
};

const frequencyTypeCodes = {
  NEVER: FREQUENCY_CODES.NEVER,
  CUSTOM: 'CUSTOM',
}

export const defaultCustomTypeFrequencyObject: IFrequencyObjectState = {
  code: {
    value: frequencyTypeCodes.CUSTOM,
    isValid: true,
  },
  frequency: {
    value: 1,
    isValid: true,
  },
  period: {
    value: 1,
    isValid: true,
  },
  periodUnit: {
    value: 'week',
    isValid: true,
  },
}

export const FrequencyUserInputField = (props: IInputElement) => {

  const propFrequencyCode = props.value?.code;
  const propFrequency = props.value?.times;
  const propPeriod = props.value?.per?.value;
  const propPeriodUnit = props.value?.per?.unit || props.userInputField?.defaultValue || '';

  const frequencyObjectInitValue: IFrequencyObjectState = {
    code: {
      value: propFrequencyCode,
      isValid: isFrequencyCodeValid(propFrequencyCode),
    },
    frequency: {
      value: propFrequency,
      isValid: isFrequencyValueValid(propFrequency),
    },
    period: {
      value: propPeriod,
      isValid: isPeriodValueValid(propPeriod),
    },
    periodUnit: {
      value: propPeriodUnit,
      isValid: isPeriodUnitValueValid(propPeriodUnit),
    },
  }

  const [ frequencyObjectState, setFrequencyObjectState ] = useState<IFrequencyObjectState>(frequencyObjectInitValue);

  const possibleValueList = props.possibleValueList;
  const elementProperty = props?.elementProperty || {};

  function isFrequencyCodeValid(code: string) {
    return !!code;
  }

  function isFrequencyValueValid(frequency: any) {
    return (frequency && frequency > 0);
  }

  function isPeriodValueValid(period: any) {
    return (period && period > 0);
  }

  function isPeriodUnitValueValid(periodUnit: any) {
    return !!periodUnit;
  }

  function isFormValid(frequencyObject: IFrequencyObjectState) {
    const isFrequencyCodeValid = frequencyObject.code.isValid;
    if (isFrequencyCodeValid) {
      const isFrequencyCodeValue = frequencyObject.code.value;
      if (isFrequencyCodeValue === frequencyTypeCodes.NEVER) return true;
    }
    const isFrequencyValid = frequencyObject.frequency.isValid;
    const isPeriodValid = frequencyObject.period.isValid;
    const isPeriodUnitValid = frequencyObject.periodUnit.isValid;

    return (isFrequencyCodeValid && isFrequencyValid && isPeriodValid && isPeriodUnitValid);
  }

  function getDisplayValue(frequencyObject: IFrequencyObjectState) {
    if (isFormValid(frequencyObject)) {
      const isFrequencyCodeValue = frequencyObject.code.value;
      if (isFrequencyCodeValue === frequencyTypeCodes.NEVER) return 'Never';

      const frequencyValue = frequencyObject.frequency.value;
      const periodValue = frequencyObject.period.value;
      const periodUnitValue = frequencyObject.periodUnit.value;

      const frequencyDisplayValue = (frequencyValue === 1) ? ' Once' : `${frequencyValue} times`;
      const periodDisplayValue= (periodValue === 1) ? 'every' : `every ${periodValue}`;
      let periodUnitDisplayValue= (frequencyPeriodUnitValuesByCode[periodUnitValue] || periodUnitValue || '').toLowerCase();
      if (periodValue !== 1) {periodUnitDisplayValue += '(s)'}
      return `${frequencyDisplayValue} ${periodDisplayValue} ${periodUnitDisplayValue}`;

    } else {
      return '';
    }
  }

  function onChange(frequencyObject: IFrequencyObjectState) {
    if (isFormValid(frequencyObject)) {
      const isFrequencyCodeValue = frequencyObject.code.value;
      const frequencyValue = frequencyObject.frequency.value;
      const periodValue = frequencyObject.period.value;
      const periodUnitValue = frequencyObject.periodUnit.value;

      const displayValue = getDisplayValue(frequencyObject);

      if (isFrequencyCodeValue === frequencyTypeCodes.NEVER) {
        const value = { code: isFrequencyCodeValue };
        props.onChange(value, displayValue);
      } else {
        const value = {
          code: isFrequencyCodeValue,
          times: frequencyValue,
          per: {
            value: periodValue,
            unit: periodUnitValue,
          }
        };

        props.onChange(value, displayValue);
      }
    } else {
      props.onChange(null, '');
    }
  }

  function onFrequencyTypeChange(frequencyType: string) {
    setFrequencyObjectState((prevFrequencyObjectState) => {
      if (frequencyType === frequencyTypeCodes.CUSTOM) {
        prevFrequencyObjectState = {
          ...prevFrequencyObjectState,
          ...defaultCustomTypeFrequencyObject,
        }
      }
      const newState = { ...prevFrequencyObjectState, code: { value: frequencyType, isValid: isFrequencyCodeValid(frequencyType) }};
      onChange(newState);
      return newState;
    });
  }

  const displayValue = getDisplayValue(frequencyObjectState);

  return (
    <VStack space={1}>
      <View>
        <FormControl
          isInvalid={props.isShowError && !frequencyObjectState.code.isValid}
          flex={1}
        >
          <Radio.Group
            name="frequencyType"
            value={frequencyObjectState.code.value}
            onChange={onFrequencyTypeChange}
          >
            <HStack space={2}>
              <Radio value={frequencyTypeCodes.NEVER} marginY={1}>
                <Text marginLeft={2}>Never</Text>
              </Radio>

              <Radio value={frequencyTypeCodes.CUSTOM} marginY={1}>
                <Text marginLeft={2}>Custom</Text>
              </Radio>
            </HStack>
          </Radio.Group>
          <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
            Please select frequency type
          </FormControl.ErrorMessage>
        </FormControl>
      </View>

      {
        frequencyObjectState.code.value === frequencyTypeCodes.CUSTOM &&
        <View>
          <HStack space={2} alignItems={'center'} justifyContent={'center'}>
            <View flex={1}>
              <FormControl
                isInvalid={props.isShowError && !frequencyObjectState.frequency.isValid}
                flex={1}
              >
                <InputNumber
                  value={frequencyObjectState.frequency.value}
                  placeholder="eg. 3"
                  type="number"
                  min={1}
                  className={ (props.isShowError && !frequencyObjectState.frequency.isValid) ? 'field-error' : '' }
                  onChange={(frequency) => {
                    setFrequencyObjectState((prevFrequencyObjectState) => ({ ...prevFrequencyObjectState, frequency: { value: frequency, isValid: isFrequencyValueValid(frequency) }}));
                  }}
                  onBlur={() => {
                    onChange(frequencyObjectState);
                  }}
                  style={reactStyles.fullWidth}
                  {...props.elementProperty}
                />
              </FormControl>
            </View>

            <View>
              <Text>times every</Text>
            </View>

            <View flex={1}>
              <FormControl
                isInvalid={props.isShowError && !frequencyObjectState.period.isValid}
                flex={1}
              >
                <InputNumber
                  value={frequencyObjectState.period.value}
                  placeholder="eg. 1"
                  type="number"
                  min={1}
                  className={ (props.isShowError && !frequencyObjectState.period.isValid) ? 'field-error' : '' }
                  onChange={(period) => {
                    setFrequencyObjectState((prevFrequencyObjectState) => ({ ...prevFrequencyObjectState, period: { value: period, isValid: isPeriodValueValid(period) }}));
                  }}
                  onBlur={() => {
                    onChange(frequencyObjectState);
                  }}
                  style={reactStyles.fullWidth}
                  {...props.elementProperty}
                />
              </FormControl>
            </View>

            <View flex={1}>
              <FormControl
                isInvalid={props.isShowError && !frequencyObjectState.periodUnit.isValid}
                flex={1}
              >
                <AntSelect
                  value={frequencyObjectState.periodUnit.value}
                  style={reactStyles.fullWidth}
                  onChange={(periodUnit) => {
                    setFrequencyObjectState((prevFrequencyObjectState) => {
                      const newState = {
                        ...prevFrequencyObjectState,
                        periodUnit: { value: periodUnit, isValid: isPeriodUnitValueValid(periodUnit) },
                      };
                      onChange(newState);
                      return newState;
                    });
                  }}
                  {...elementProperty}
                >
                  {possibleValueList &&
                    possibleValueList.map((data: any) => (
                      <AntSelect.Option key={data.value} value={data.value}>
                        {data.displayName}
                      </AntSelect.Option>
                    ))}
                </AntSelect>
              </FormControl>
            </View>
          </HStack>
        </View>
      }

      {
        frequencyObjectState.code.value === frequencyTypeCodes.CUSTOM &&
        displayValue &&
        <View>
          <Text>{displayValue}</Text>
        </View>
      }
    </VStack>
  );
}

export default FrequencyUserInputField;
