import React from "react";
import {Path, Svg} from "react-native-svg";
import {Colors} from "../../styles";

function CarePlanIcon() {
  return (
    <Svg
      width="15"
      height="16"
      fill="none"
      viewBox="0 0 15 16"
    >
      <Path
        stroke='#6F7A90'
        strokeLinecap="round"
        d="M11.12 5.36l.309-.31a1.31 1.31 0 111.854 1.854l-.31.31M11.12 5.36s.039.656.618 1.235c.58.58 1.236.618 1.236.618M11.12 5.36L8.28 8.2a4.558 4.558 0 00-.372.395c-.097.125-.181.26-.25.403-.057.122-.1.25-.186.509L7.107 10.6m5.867-3.388l-2.84 2.84c-.193.193-.29.29-.395.372-.125.098-.26.181-.404.25-.121.057-.25.1-.509.186l-1.093.365m0 0l-.268.09a.354.354 0 01-.447-.448l.09-.267m.625.625l-.626-.625M4.333 8.667H6M4.333 6h4.334m-4.334 5.333h1m6.886-9.219c-.781-.78-2.038-.78-4.552-.78H6.333c-2.514 0-3.77 0-4.552.78C1 2.895 1 4.153 1 6.667v2.666c0 2.514 0 3.772.781 4.553.781.78 2.038.78 4.552.78h1.334c2.514 0 3.77 0 4.552-.78.629-.63.751-1.566.775-3.22"
      ></Path>
    </Svg>
  );
}

export default React.memo(CarePlanIcon);
