import React from 'react';
import {Modal} from 'antd';
import {HStack, Text, VStack} from 'native-base';
import {ITask} from '../../../common/CareDashboard/CareDashboardInterfaces';
import {useIntl} from 'react-intl';
import {BUTTON_TYPE} from '../../../../constants';
import {FoldButton} from '../../../CommonComponents/FoldButton/FoldButton';

export interface IProps {
  deleteTaskData: {
    showConfirmation: boolean;
    task?: ITask;
  };
  onDeleteCancel: () => void;
  onDeleteConfirm: () => Promise<void>;
}

const DeleteTaskModal: React.FC<IProps> = (props) => {
  const {deleteTaskData, onDeleteCancel, onDeleteConfirm} = props;
  const intl = useIntl();
  return (
    <Modal
      open={deleteTaskData.showConfirmation}
      footer={null}
      closable={false}
    >
      <VStack>
        <Text mb={1} fontSize={'xl'} fontWeight={'bold'}>{`Delete Task?`}</Text>
        <Text my={1} fontSize={'md'}>
          {deleteTaskData?.task?.resourceMap?.notes
            ? intl.formatMessage({id: 'deleteNoteLinkTaskConfirmation'})
            : deleteTaskData?.task?.parentId
            ? intl.formatMessage({id: 'deleteSubTaskConfirmation'})
            : intl.formatMessage({id: 'deleteTaskConfirmation'})}
        </Text>
        <HStack mt={5} justifyContent={'flex-end'}>
          <FoldButton
            nativeProps={{
              variant: BUTTON_TYPE.SECONDARY,
              onPress: onDeleteCancel,
              ml: 2,
              isLoading: false,
            }}
            customProps={{
              btnText: intl.formatMessage({id: 'cancel'}),
              withRightBorder: false,
            }}
          />
          <FoldButton
            nativeProps={{
              variant: BUTTON_TYPE.PRIMARY,
              onPress: onDeleteConfirm,
              ml: 2,
              isLoading: false,
            }}
            customProps={{
              btnText: intl.formatMessage({id: 'delete'}),
              withRightBorder: false,
            }}
          />
        </HStack>
      </VStack>
    </Modal>
  );
};

export default DeleteTaskModal;
