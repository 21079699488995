import { HStack, Pressable, View, Text, ScrollView } from "native-base";
import { useEffect, useState } from "react";
import { Colors } from '../../../../../../styles';
import { testID, TestIdentifiers } from "../../../../../../testUtils";
import { TaskViewTabsCode, TASK_VIEW_TABS } from "../../../../AddTask/AddEditComment/TaskCommentsHelper";
import AuditRender from "../../../../Audit/AuditRender";
import { DisplayText } from "../../../../DisplayText/DisplayText";
import useAppointmentAudit from "./hooks/useAppointmentAudit/useAppointmentAudit";
import { IAppointmentType } from "../../BookAppointment/Interfaces";
import { IAuditRender } from "../../../../Audit/AuditInterfaces";
import SortIcon from "../../../../Svg/SortIcon";
import { AUDIT_ACTION_CODES } from "../../../../Audit/AuditHelper";
import { Dimensions } from "react-native";

export enum AUDIT_RESOURCE {
    APPOINTMENT = 'appointment',
    TASK = 'task'
};

export interface IAppointmentParticipantTypeIds {
    patient: string;
    primaryUser: string;
    secondaryUser: string;
}

interface IProps {
    tabs: typeof TASK_VIEW_TABS;
    showAutomationTab: boolean;
    showActivityTab: boolean;
    automationTabView: any;
    auditResource: AUDIT_RESOURCE;
    appointmentParticipantTypeIds: IAppointmentParticipantTypeIds;
    appointmentId?: string;
    appointmentTypeList?: IAppointmentType[];
    isGroupSessionAppointment?: boolean;
    refetchData?: boolean;
    onAuditRefetchData?: () => void;
}

const BottomTabs = (props: IProps) => {
    const [selectedTab, setSelectedTab] = useState(() => {
        const tabCode =
            props?.showActivityTab
                ? TaskViewTabsCode.ACTIVITY
                : props?.showAutomationTab
                    ? TaskViewTabsCode.AUTOMATION
                    : '';

        return TASK_VIEW_TABS?.find(tab => tab?.code === tabCode);
    });
    const {height} = Dimensions.get('window');
    let { logAuditData,
        accountUsersMappedById,
        mappingUuidWithData,
        handleActions,
        offset,
        sortState,
        totalTransactionsFetchedCount,
        initialBatchLogsCountData,
        isGroupSessionAppointment,
        ehrList
    } = {} as any

    if (props?.auditResource === AUDIT_RESOURCE.APPOINTMENT) {
        ({
            logAuditData,
            accountUsersMappedById,
            mappingUuidWithData,
            handleActions,
            offset,
            sortState,
            totalTransactionsFetchedCount,
            initialBatchLogsCountData,
            isGroupSessionAppointment,
            ehrList
        } = useAppointmentAudit({
            appointmentId: props?.appointmentId,
            appointmentTypeList: props?.appointmentTypeList,
            appointmentParticipantTypeIds: props?.appointmentParticipantTypeIds,
            isGroupSessionAppointment: props?.isGroupSessionAppointment,
            refetchData: props?.refetchData,
            onAuditRefetchData: props?.onAuditRefetchData
        }))
    }

    const updatedTabsList = props?.tabs?.filter(tab =>
        (tab.code === TaskViewTabsCode.ACTIVITY && props?.showActivityTab) ||
        (tab.code === TaskViewTabsCode.AUTOMATION && props?.showAutomationTab)
    );
          

    return <View maxHeight={height - 110}>
        <HStack space={1}>
            {updatedTabsList?.map((tab) => {
                return (
                    <Pressable key={tab?.key}
                        style={{ marginHorizontal: 1 }}
                        onPress={() => {
                            setSelectedTab(tab);
                        }}
                        {...testID(TestIdentifiers?.[tab?.code])}
                    >
                        <View
                            borderBottomColor={selectedTab?.code === tab?.code ? Colors?.Custom?.mainPrimaryPurple : Colors?.Custom?.BackgroundColor}
                            borderBottomWidth={4}
                            padding={2}>
                            <DisplayText
                                extraStyles={{
                                    color: selectedTab?.code === tab?.code ? Colors.Custom.mainPrimaryPurple : Colors?.Custom?.Gray400,
                                    fontWeight: 600,
                                    fontSize: 16,
                                }}
                                textLocalId={tab?.title}
                            />
                        </View>
                    </Pressable>
                )
            })}
            {selectedTab?.code === TaskViewTabsCode?.ACTIVITY &&
                <HStack justifyContent={'flex-end'} alignItems={'center'} space={1} flex={1}>
                    <Pressable onPress={() => handleActions(AUDIT_ACTION_CODES.CHANGE_SORT)} alignItems={'center'}>
                        <HStack alignItems={'center'} >
                            <Text
                                {...testID(TestIdentifiers?.sortTaskAudit)}
                                color={Colors?.Custom?.mainPrimaryPurple}
                                fontSize={16}
                                fontWeight={700}
                            >
                                {sortState.dueDate?.desc ? 'Newest first' : 'Oldest first'}
                            </Text>
                            <SortIcon sortMode='' strokeColor={Colors?.Custom?.mainPrimaryPurple} strokeWidth="1.5" />
                        </HStack>
                    </Pressable>
                </HStack>
            }
        </HStack>
        {selectedTab?.code === TaskViewTabsCode?.AUTOMATION && (
            props?.automationTabView
        )}
        {selectedTab?.code === TaskViewTabsCode?.ACTIVITY && props?.auditResource === AUDIT_RESOURCE.APPOINTMENT && (
            <ScrollView marginTop={2} maxHeight={height - 110}>
                <AuditRender
                    auditData={{
                        loadingAuditData: logAuditData?.loadingAuditData,
                        logData: logAuditData?.logData,
                        aggregate: logAuditData?.aggregate
                    }}
                    accountUsersMappedById={accountUsersMappedById}
                    uuidMappingToData={mappingUuidWithData}
                    handleActions={handleActions}
                    offset={offset}
                    totalTransactionsFetchedCount={totalTransactionsFetchedCount}
                    initialBatchLogsCountData={initialBatchLogsCountData}
                    auditResource={props?.auditResource}
                    isGroupSessionAppointment={isGroupSessionAppointment}
                    ehrList={ehrList}
                />
            </ScrollView>
        )}
    </View>

}
export default BottomTabs