export const BILLING_API_PATH = {
  USAGES_LIST: '/billing/plan/usages',
  AUDIT_LOGS_LIST: '/billing/audit/audit-log',
  ACTIVE_LIVES_COUNT:'/billing/plan/activeLivesCount',
  ACTIVE_LIVES_CONTACTS:'/billing/plan/activeLivesContacts',
  OPENAI_COMPLETION: '/openai/completion',
  OPENAI_COMPLETION_PLAIN: '/openai/completion/plain',
  DALLE_COMPLETION: '/openai/dalle/completion',
  OPENAI_SUGGESTION: '/openai/suggestions',
};
