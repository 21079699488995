import React from 'react';
import {Svg, Path} from 'react-native-svg';
import {Colors} from '../../styles';

function ClockIcon(params?: {strokeColor?: string, width?: string, height?: string,strokeWidth?: number}) {
  return (
    <Svg width={params?.width || "16"} height={params?.height || "17"} fill="none" viewBox="0 0 16 17">
      <Path
        stroke={params?.strokeColor || Colors.Custom.mainPrimaryPurple}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={ params?.strokeWidth ? params?.strokeWidth : "0.667"}
        d="M8 5.833V8.5l1.667 1.667m5-1.667a6.667 6.667 0 11-13.334 0 6.667 6.667 0 0113.334 0z"
      ></Path>
    </Svg>
  );
}

export default React.memo(ClockIcon);
