import React, { useEffect, useState } from 'react'
import { VStack, View, useMediaQuery } from 'native-base';
import { useIntl } from 'react-intl';
import FHCustomSwitch from '../../../../common/CustomSwtich/FHCustomSwitch';
import ReactDevicePreview from 'react-device-preview';
import './WebPreview.scss';

export const PREVIEW_TYPE = {
  DESKTOP: 'DESKTOP',
  TABLET: 'TABLET',
  PHONE: 'PHONE',
};

const WebPreview = (props: {
  children: JSX.Element,
  onScreenTypeChange?: (type: string) => void}
) => {
  const intl = useIntl();
  const [screenType, setScreenType] = useState(PREVIEW_TYPE.DESKTOP);
  const [isSmallScreen] = useMediaQuery({maxWidth: 1512});

  const getDeviceName = () => {
    switch (screenType) {
      case PREVIEW_TYPE.DESKTOP: return 'macbookpro';
      case PREVIEW_TYPE.TABLET: return 'ipadmini';
      case PREVIEW_TYPE.PHONE: return 'iphonex';
    }
    return 'macbookpro';
  }
  const getDeviceScale= () => {
    switch (screenType) {
      case PREVIEW_TYPE.DESKTOP: return isSmallScreen ? '0.65' : '0.8';
      case PREVIEW_TYPE.TABLET: return isSmallScreen ? '0.5' : '0.65';
      case PREVIEW_TYPE.PHONE: return isSmallScreen ? '0.5' : '0.65';
    }
    return '0.8';
  }
  const getLeftMargin= () => {
    switch (screenType) {
      case PREVIEW_TYPE.DESKTOP: return isSmallScreen ? 456 : 225;
      case PREVIEW_TYPE.TABLET: return isSmallScreen ? 325 : 225;
      case PREVIEW_TYPE.PHONE: return isSmallScreen ? 225 : 156;
    }
    return '0.8';
  }

  useEffect(() => {
    props.onScreenTypeChange?.(screenType);
  }, []);

  return (
    <VStack
      alignItems={'center'}
      justifyContent={'center'}
      width={'100%'}
      paddingY={2}
    >
      <View>
        <FHCustomSwitch
          btnTextArray={[
            {
              text: intl.formatMessage({id: 'desktop'}),
              code: PREVIEW_TYPE.DESKTOP,
            },
            {
              text: intl.formatMessage({id: 'tablet'}),
              code: PREVIEW_TYPE.TABLET,
            },
            {
              text: intl.formatMessage({id: 'phone'}),
              code: PREVIEW_TYPE.PHONE,
            },
          ]}
          onBtnSelect={(code) => {
            setScreenType(code);
            props.onScreenTypeChange?.(code);
          }}
          selectedCode={screenType}
        />
      </View>
      <View marginLeft={getLeftMargin()} marginTop={2}>
        <ReactDevicePreview
          device={getDeviceName()}
          scale={getDeviceScale()}
          >
          <View marginTop={screenType === PREVIEW_TYPE.PHONE ? 6 : 0}>
            <div className={screenType === PREVIEW_TYPE.PHONE ? 'fh-phone-preview' : ''}>
                {props.children}
            </div>
          </View>
        </ReactDevicePreview>
      </View>
    </VStack>
  )
}

export default WebPreview
