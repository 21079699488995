import { useLazyQuery } from "@apollo/client";
import { Collapse } from "antd";
import { HStack, Pressable, Skeleton, Text, VStack } from "native-base";
import { useEffect, useState } from "react";
import { stringOrDate } from "react-big-calendar";
import { GROUP_MEMBER_TYPE } from "../../../../../../constants";
import ContactsQueries from "../../../../../../services/Contacts/ContactsQueries";
import { Colors } from "../../../../../../styles";
import { DisplayCardAvatar } from "../../../../../common/DisplayCard/DisplayCardAvatar";
import MessagingContactDetailsDrawer from "../../../../TeamInbox/Conversations/MessagingContactDetails/MessagingContactDetailsDrawer";
import BaseService from "../../../../../../services/CommonService/BaseService";
import { styles } from "../../../workflowStyles";
const { Panel } = Collapse;

const GetContactPhotoAndNameByContactId = (props: {isHideIcon?:boolean, contactId: number, email?:stringOrDate }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [contact, setContact] = useState<any>()

  const getContactsByIds = async (
    params: {
      params: {
      contactIds: number[]
      }
    }
  ) => {
    try{
      const baseService = BaseService.getSharedInstance().axios;
      const getContactDetails = `crm-nest/account/contact-details`;

      const response = await baseService.get(getContactDetails, params);
      return response;

    } catch (error) {
      return {error: error}
    }
  }


  useEffect(() => {
    getContactsByIds({
      params: {
        contactIds: [props.contactId],
      },
    }).then((respInner: any) => {
      if (respInner?.data?.length) {
        setContact(respInner?.data?.[0])
      }
      setIsLoading(false)
    });
  }, [props.contactId])
  
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  return  <HStack alignItems={'center'} flex={1} >
    {isLoading ?
      <Skeleton.Text flex={1} lines={1}></Skeleton.Text> :
      <>
       {!props.isHideIcon && 
       <Pressable  onPress={()=>{
        setIsOpenDrawer(true)
      }}>
       <DisplayCardAvatar
          avatarStyle={{
            avatarSize: '12',
          }}
          isLetterAvatarShow={true}
          userData={{
            userId: contact?.id,
            userType: GROUP_MEMBER_TYPE.CONTACT,
            imgSrc: contact?.profileImgSrc || '',
            contactType:
              contact?.contactType?.contactType?.code || '',
            genderCode: contact?.person?.gender?.code || 'other',
            userName: contact?.name,
            isUserDeleted: contact?.is_deleted
          }}
        />
        </Pressable> }
        <VStack style={styles.marginLeft12} >
          <Text size={'smMedium'} color={Colors.Custom.Gray900} >
            {(
              (contact?.person?.firstName || '') +
              ' ' +
              (contact?.person?.lastName || '')
            ).trim() ||
              contact?.name ||
              ''}
          </Text>
          {!props.isHideIcon && <Text size={'smNormal'} color={Colors.Custom.Gray500} >
            {props.email != undefined ? props.email : contact?.email }
          </Text> }
        </VStack>
      </>}
      {isOpenDrawer ?  (
        <MessagingContactDetailsDrawer
          contactData={contact}
          isDrawerVisible={isOpenDrawer}
          contactType={contact?.contactType}
          onSideBarActionPerformed={(action?: any) => {
            setIsOpenDrawer(false)
          }}
        /> 
      ) : <></>}
  </HStack> 
}
export default GetContactPhotoAndNameByContactId;
