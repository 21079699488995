import { Liquid } from "liquidjs";

export const replacePlaceHolder = (str: any, tags: any) => {
  try {
    const mergeTags = { ...tags }
    mergeTags.patientapp = mergeTags.patientapp || {}
    mergeTags.patientapp.user = '*******';
    mergeTags.patientapp.password = '*******';
    const engine = new Liquid();
    const tpl = engine.parse(str || '');

    return engine.renderSync(tpl, tags || {});
  } catch (exception) {
    return 'Template not found / Invalid body'
  }
}