import React from 'react';
import { Colors } from '../../../styles/Colors';
import {isWeb} from '../../../utils/platformCheckUtils';
import { ICommonSvgProps } from './interfaces';
import {Image} from 'native-base';

const AssignedToOthersSvg = (props: ICommonSvgProps) => {
    const {
        customStrokeColor,
    } = props;
    const bgColor = customStrokeColor || Colors.Custom.Gray500;

    return (
      <>
        {isWeb() ? (
          <svg 
            width={props?.width ? props?.width : "16"}
            height={props?.height ? props?.height : "17"}
            viewBox="0 0 16 17" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
          >
            <path 
              d="M10.6918 2.4987C11.7841 2.4987 12.6824 3.4187 12.6824 4.55203C12.6824 5.68536 11.7905 6.5987 10.6918 6.5987M13.7553 12.3254C14.6666 11.912 14.6666 11.2187 14.6666 10.4387C14.6666 9.29203 13.2124 8.33203 11.3122 8.1587M9.08895 4.4987C9.08895 5.97203 7.93206 7.16536 6.50372 7.16536C5.07538 7.16536 3.91848 5.97203 3.91848 4.4987C3.91848 3.02536 5.07538 1.83203 6.50372 1.83203C7.93206 1.83203 9.08895 3.02536 9.08895 4.4987ZM11.6742 12.1654C11.6742 13.8254 11.6742 15.1654 6.50372 15.1654C1.33325 15.1654 1.33325 13.8254 1.33325 12.1654C1.33325 10.5054 3.64703 9.16537 6.50372 9.16537C9.3604 9.16537 11.6742 10.5054 11.6742 12.1654Z" 
              stroke={bgColor} 
              stroke-linecap="round" 
              stroke-linejoin="round"
            />
          </svg>
        ) : (
          <Image
            tintColor={customStrokeColor}
            size={'4'}
            source={require('../../../assets/images/MemberActions/assignSvgIcon.png')}
            alt="aa"
          />
        )}
      </>
    );
};

export default AssignedToOthersSvg;
