import { useLazyQuery } from '@apollo/client';
import {Select, Spin} from 'antd';
import {debounce} from 'lodash';
import {Text} from 'native-base';
import React, {useEffect, useState} from 'react';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../constants/Configs';
import {ISearchFieldProps} from '../../../Interfaces';
import { CareJourneyQueries } from '../../../services';
import {processResponse} from '../../../utils/commonUtils';

const NutritionSearch = (props: ISearchFieldProps) => {
  const {value, isShowError, onChange} = props;
  const [loading, setLoading] = useState(false);
  const [nutritionData, setNutritionData] = useState<any[]>([]);
  const NUTRITION_SEARCH_PUBLIC_API =
    'https://api.nal.usda.gov/fdc/v1/foods/search?pageSize=10&api_key=IggJT0owshZtWRnxyEcDijMWf7fBCtSztaXBEQ4x&query=';

  useEffect(() => {
    if (value && value.fdcId && nutritionData.length === 0) {
      setNutritionData([
        {
          fdcId: value.fdcId,
          description: value.description,
          // brandOwner: value.brandOwner,
        },
      ]);
    }
  }, []);

  const [getNutritions] = useLazyQuery(
    CareJourneyQueries.GET_NUTRITION,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  const searchNutrition = (searchString: string) => {
    setNutritionData([]);
    if (searchString.length > 0) {
      setLoading(true);
      getNutritions({
        variables: {
          limit: 10,
          offset: 0,
          searchString: `%${searchString}%`,
        },
        onCompleted: (data) => {
          if (data?.mdmNutritions?.length) {
            setNutritionData(data.mdmNutritions);
          }
          setLoading(false);
        },
        onError: (error) => {

          setLoading(false);
        }
      });
      // fetch(NUTRITION_SEARCH_PUBLIC_API + searchString)
      //   .then((response) => processResponse(response))
      //   .then((result) => {
      //     if (
      //       result &&
      //       result.data &&
      //       result.data.foods &&
      //       result.data.foods.length > 0
      //     ) {
      //       setNutritionData(result.data.foods);
      //     }
      //     setLoading(false);
      //   })
      //   .catch(() => {
      //     setLoading(false);
      //   });
    }
  };

  const getDataFromId = (id: any) => {
    const matchedData = nutritionData.filter((nutrition) => {
      return nutrition.fdcId === id;
    });
    if (matchedData.length > 0) {
      return {
        fdcId: matchedData[0].fdcId,
        description: matchedData[0].description,
        // brandOwner: matchedData[0].brandOwner,
      };
    }
  };

  return (
    <Select
      size="large"
      showSearch
      allowClear
      filterOption={false}
      value={value ? value.fdcId : undefined}
      onSearch={debounce(searchNutrition, 500)}
      onChange={(value: any[], data: any) => {
        if (data && data.value) {
          onChange(getDataFromId(data.value));
        } else {
          onChange(undefined);
        }
      }}
      placeholder="Search Nutrition"
      loading={loading}
      notFoundContent={loading && <Spin size="small" />}
      style={{height: '40px'}}
      className={isShowError && !value ? 'field-error' : ''}
    >
      {nutritionData.map((nutrition, index) => {
        return (
          <Select.Option
            key={`${nutrition.fdcId}_${index}`}
            value={nutrition.fdcId}
          >
            {nutrition.description}
            {/* <Text fontSize="xs" color="gray.500">
              {'  '}
              {nutrition.brandOwner}
            </Text> */}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default NutritionSearch;
