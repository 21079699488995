import React, { useContext } from 'react';
import {View} from 'react-native';
import {Colors} from '../../../../../../styles';
import {isWeb} from '../../../../../../utils/platformCheckUtils';
import {FoldButton} from '../../../../../CommonComponents/FoldButton/FoldButton';
import {EMAIL_REPLY_ACTIONS} from '../../../../../common/EmailDrawerCommonV2/EmailDrawerCommonV2Const';
import EmailForwardSvg from '../../../../../common/Svg/EmailForwardSvg';
import EmailInternalSvg from '../../../../../common/Svg/EmailInternalSvg';
import EmailReplyAllSvg from '../../../../../common/Svg/EmailReplyAllSvg';
import EmailReplySvg from '../../../../../common/Svg/EmailReplySvg';
import {IEmailInboxFooterProps} from './interfaces';
import {useIntl} from 'react-intl';
import { CommonDataContext } from '../../../../../../context/CommonDataContext';
import {isOutlookInbox} from '../../../../../common/EmailDrawerCommonV2/EmailDrawerCommonV2Utils';
import { StyleSheet } from 'react-native';

const EmailInboxFooter = (props: IEmailInboxFooterProps) => {
  const commonData = useContext(CommonDataContext);
  const isSidecarContext = commonData.sidecarContext?.isSidecar;
  const marginX = isWeb() && !isSidecarContext ? 5 : 1;
  const iconImgSize = 4;
  const intl = useIntl();
  return (
    <View style={styles.container}>
      <FoldButton
        customProps={{
          btnText: intl.formatMessage({id: 'reply'}),
        }}
        nativeProps={{
          backgroundColor: Colors.Custom.Primary300,
          leftIcon: (
            <EmailReplySvg
              customStrokeColor={Colors.FoldPixel.GRAY50}
              size={iconImgSize}
            />
          ),
          marginX: marginX,
          borderRadius: 8,
          onPress() {
            props.onActionPerformed(EMAIL_REPLY_ACTIONS.REPLY);
          },
        }}
      />
      {
        isOutlookInbox(props?.selectedConversation?.conversationInbox?.channelEmail) ?
        <FoldButton
          customProps={{
            btnText: isWeb() ? intl.formatMessage({id: 'replyAll'}) : '',
          }}
          nativeProps={{
            backgroundColor: 'white',
            leftIcon: <EmailReplyAllSvg size={iconImgSize} />,
            borderColor: '#A7AFBE',
            borderWidth: 0.5,
            marginX: marginX,
            ...(isWeb() ? {} : {padding: 2.5}),
            borderRadius: 8,
            _text: {
              color: '#6F7A90',
            },
            onPress() {
              props.onActionPerformed(EMAIL_REPLY_ACTIONS.REPLY_ALL);
            },
          }}
        />
        : <></>
      }
      {
        isOutlookInbox(props?.selectedConversation?.conversationInbox?.channelEmail) ?
        <FoldButton
          nativeProps={{
            backgroundColor: 'white',
            leftIcon: <EmailForwardSvg size={iconImgSize} />,
            borderColor: '#A7AFBE',
            borderWidth: 0.5,
            marginX: marginX,
            ...(isWeb() ? {} : {padding: 2.5}),
            borderRadius: 8,
            _text: {
              color: '#6F7A90',
            },
            onPress() {
              props.onActionPerformed(EMAIL_REPLY_ACTIONS.FORWARD);
            },
          }}
          customProps={{
            btnText: isWeb() ? intl.formatMessage({id: 'forward'}) : '',
          }}
        />
        : <></>
      }

      <FoldButton
        nativeProps={{
          backgroundColor: 'white',
          borderColor: '#A7AFBE',
          borderWidth: 0.5,
          leftIcon: <EmailInternalSvg size={iconImgSize} />,
          marginX: marginX,
          borderRadius: 8,
          onPress() {
            props.onActionPerformed(EMAIL_REPLY_ACTIONS.INTERNAL);
          },
          _text: {
            color: '#6F7A90',
          },
        }}
        customProps={{
          btnText: intl.formatMessage({id: 'internalNote'}),
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: isWeb() ? 'flex-start' : 'center',
    flexDirection: 'row',
    padding: isWeb() ? 5 : 10,
  },
});

export default EmailInboxFooter;
