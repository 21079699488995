import React from "react";

const VideoActionSvg = (props: {isActive?:boolean})=> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{width: 'inherit', height: 'inherit'}}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke={ !props.isActive ? "#D0D5DD"  : '#667085' }
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M19.167 5.833L13.333 10l5.834 4.167V5.833zM11.667 4.167H2.5c-.92 0-1.667.746-1.667 1.666v8.334c0 .92.746 1.666 1.667 1.666h9.167c.92 0 1.666-.746 1.666-1.666V5.833c0-.92-.746-1.666-1.666-1.666z"
      ></path>
    </svg>
  );
}

export default VideoActionSvg;
