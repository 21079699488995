import {Skeleton, View} from 'native-base';

const CMSLoading = () => {
  return (
    <View style={{padding: 16}}>
      <Skeleton.Text lines={4} />
    </View>
  );
};

export default CMSLoading;
