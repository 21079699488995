import React, { useEffect, useState } from "react";

interface IUseDebounceEventInterface {
  debounceTime?: number;
  onDebounce: () => void;
}

const useDebounceEvent = (args: IUseDebounceEventInterface) => {
  const debounceTime = args.debounceTime || 1500;
  const [event, setEvent] = useState<number>(0);
  const [timer, setTimer] = useState<NodeJS.Timeout | undefined>();

  const pushEvent = () => {
    setEvent((prev) => {
      return prev + 1;
    })
  }

  useEffect(() => {
    return () => {
      timer && clearTimeout(timer);
    }
  }, [])

  useEffect(() => {
    if (!timer && event > 0) {
      const newTimer = setTimeout(() => {
        args.onDebounce();
        clearTimeout(timer)
        setTimer(undefined);
        setEvent(0);
      }, debounceTime);
      setTimer(newTimer);
    }
  }, [event])


  return { pushEvent}
}

export default useDebounceEvent;
