import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  row: {
    justifyContent: 'space-between',
    marginVertical: 8,
  },
  label: {
    flex: 0.3,
  },
  dropdown: {
    flex: 0.7,
  },
});
