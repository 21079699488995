import { CaretDownOutlined } from '@ant-design/icons';
import { Select, Spin, Tag } from 'antd';
import { debounce } from 'lodash';
import { HStack, Text, View, FormControl, Switch, Alert } from 'native-base';
import { useEffect, useState } from 'react';
import Feather from 'react-native-vector-icons/Feather';
import { Colors } from '../../../styles';
import { testID } from '../../../testUtils';
import { GetPackages } from '../../../services/Stripe/StripeServices';
import { PACKAGE_TYPES } from '../../RightSideContainer/Sales/ProductsAndServices/Products/ProductDetailView/ProductDetailViewSidebar/RightSideContainer/ProductDetailViewForm/Forms/FormConst';
import { isExpiredDate } from '../../RightSideContainer/Sales/ProductsAndServices/JourneyPackages/PackagesUtils';
import { getMomentObj } from '../../../utils/DateUtils';
import { IPackage } from '../../RightSideContainer/Sales/ProductsAndServices/Products/ProductDetailView/ProductDetailViewSidebar/RightSideContainer/ProductDetailViewForm/SearchComponents/interfaces';
import { isAccountConfigEnabled } from '../../../utils/configUtils';
import { CONFIG_CODES } from '../../../constants/AccountConfigConst';
export interface ISearchFieldProps {
  value?: any;
  isShowError: boolean;
  onChange: (value: any) => void;
  contactTypeCode?: string;
}
const packageTagRender = (props: any, onSetIsVisible: any) => {
  const { label, value, closable, onClose } = props;

  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Tag
      color={'default'}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginRight: 3, borderRadius: '0px', justifyContent: 'center', alignItems: 'center' }}
    >
      {label}
    </Tag>
  );
};

interface IMultiplePackageSearch extends ISearchFieldProps {
  infoMessage?: string;
  workFlowLocations?: string[];
}

const MultiplePackageSearch = (props: IMultiplePackageSearch) => {
  const { value, isShowError, onChange, infoMessage } = props;
  const [loading, setLoading] = useState(false);
  const [packageData, setPackageData] = useState<any[]>(value ? value : []);
  const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED)

  useEffect(() => {
    if (value && value?.length && packageData?.length === 0) {
      setPackageData(value);
    }
  }, []);

  const getPackages = async (searchString?: string) => {
    try {
      setLoading(true);
      const { data: { products = [] } = {} } = await GetPackages({
        searchString: searchString?.trim() || '',
        limit: 20,
        offset: 0,
        status: PACKAGE_TYPES.PUBLISHED,
      });

      const filteredPackages = products
        ?.filter(({ expiryDate }) => !isExpiredDate(getMomentObj(expiryDate)?.toDate()))
        .filter(({ productLocation }) => {
          if (isMsoEnabled) {
            return props?.workFlowLocations?.every((workflowLocationUuid) =>
              productLocation?.some(({ accountLocationUuid }) =>
                accountLocationUuid === workflowLocationUuid
              )
            );
          } else {
            return true;
          }
        });
      setPackageData(filteredPackages);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPackages();
  }, []);

  const onPackageSearch = debounce(async (searchString: string) => {
    try {
      if (searchString.length > 0) {
        setLoading(true);
        setPackageData([]);
        await getPackages(searchString);
      }
    } catch (error) {
      console.log(error);
    }
  }, 500);

  const handleChange = async (value: string[]) => {
    if (value) {
      const selectedPackages = packageData
        .filter(pkg => value?.includes(pkg.id))
        .map(({ id, name, productPackage, expiryDate}) => ({ id, name, journeyId: productPackage?.journeyId, expiryDate: expiryDate ? new Date(expiryDate).toLocaleDateString('en-CA') : null }));
      onChange(selectedPackages);
    } else {
      onChange(undefined);
    }
  };

  return (
    <>
      <Select
        {...testID('Search Package')}
        showSearch
        mode='multiple'
        tagRender={(props) => {
          return packageTagRender(props, (value: any) => {
          });
        }}
        suffixIcon={<CaretDownOutlined width={10} height={10} />}
        allowClear
        filterOption={false}
        value={(value || [])?.map((data: any) => data?.id)}
        dropdownRender={(menu) => <>{menu}</>}
        onSearch={onPackageSearch}
        onChange={(value: any[]) => handleChange(value)}
        placeholder='Search Package'
        loading={loading}
        notFoundContent={loading ? <Spin size='small' /> : <Text>Type to search</Text>}
        className={
          isShowError && !value?.length
            ? 'ant-multi-select field-error'
            : 'ant-multi-select'
        }
        style={{ height: 'auto' }}
      >
        {packageData.map((pkg) => (
          <Select.Option key={pkg.id} value={pkg.id} {...testID(pkg.name)}>
            {pkg?.name}
          </Select.Option>
        ))}
      </Select>
      {!!infoMessage && (
        <HStack alignItems={'flex-start'} space={2} mt={1}>
          <Feather name='info' size={16} color={Colors.Custom.Gray500} style={{ marginTop: 2 }} />
          <Text fontSize={12} color={Colors.Custom.Gray500} width='full'>
            {infoMessage}
          </Text>
        </HStack>
      )}
    </>
  );
};

export default MultiplePackageSearch;
