import { VisitCodes, VisitType } from "./VisitSummaryConstants";
import { IVisitElement } from "./interfaces";

const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const addMissingMonths = (data: IVisitElement[], selectedYear: string) => {
    // Extract existing monthYears
    const existingMonthYears = data.map((entry: any) => entry.monthYear);
 
    // Iterate over the range of months
    for (let i = 0; i < monthNames.length; i++) {
        const monthName = monthNames[i];
        const yearMonth = `${monthName} ${selectedYear}`;

        // If the monthYear doesn't exist in the data, add it with empty data
        if (!existingMonthYears.includes(yearMonth)) {
            data.push({
                monthYear: yearMonth,
                visits: []
            });
        }
    }

    // Sort the data by monthYear
    data.sort((a: IVisitElement, b: IVisitElement) => {
        const dateA = new Date(a.monthYear);
        const dateB = new Date(b.monthYear);
        return dateA.getTime() - dateB.getTime();
    });

    return data;
}

export const getDurationFromMinutes = (durationInMinutes: number) => {
  if (durationInMinutes >= 60) {
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = durationInMinutes % 60;
    return minutes > 0 ? `${hours} hr ${minutes} mins` : `${hours} hr`;
  } else if (durationInMinutes >= 1) {
    return `${durationInMinutes} mins`;
  } else {
    const seconds = Math.round(durationInMinutes * 60);
    return `${seconds} secs`;
  }
};

export const getVisitCode = (visitType: string): any => {
    switch (visitType) {
        case VisitType.OP:
            return VisitCodes.OP
        case VisitType.ER:
            return VisitCodes.ER
        case VisitType.Speciality:
            return VisitCodes.Speciality
        case VisitType.IP:
            return VisitCodes.IP
        case VisitType.homeVisit:
            return VisitCodes.homeVisit
        case VisitType.others:
            return VisitCodes.others
    }
    return ''
}

export const getVisitTypeByCode = (visitCode: string): any => {
    switch (visitCode) {
        case VisitCodes.OP:
            return VisitType.OP
        case VisitCodes.IP:
            return VisitType.IP
        case VisitCodes.ER:
            return VisitType.ER
        case VisitCodes.Speciality:
            return VisitType.Speciality
        case VisitCodes.homeVisit:
            return VisitType.homeVisit
        case VisitCodes.others:
            return VisitType.others
    }
    return ''
}