import {CONFIG_CODES, DEFAULT_CONFIG_VALUE, ON_OFF_CONFIG_VALUE} from './../constants/AccountConfigConst';
import {useContext} from "react";
import {CommonDataContext} from "../context/CommonDataContext";
import { Configurations } from '../components/RightSideContainer/TeamInbox/VideoCallSettings/interfaces';
import { accountConfigObject } from '../components/PublicPages/PublicForm/PublicForm';
import {getUserUUID} from './commonUtils';

export const getAccountConfigValue = (code: string) => {
  const commonData = useContext(CommonDataContext);
  const configObj = commonData?.accountConfigData?.[code];
  if (configObj?.accountConfigurations?.length) {
    return configObj?.accountConfigurations?.[0].value;
  } else if (configObj?.defaultValue) {
    return configObj?.defaultValue;
  } else if (code) {
    return DEFAULT_CONFIG_VALUE[code] || '';
  }
  return '';
};


export const isAccountConfigEnabled = (code: string): boolean => {
  const windowObj: any = window;
  if (window?.location?.host?.includes?.('.staging.foldhealth.io')) {
    if (code === CONFIG_CODES.ENABLE_POWER_BI_ANALYTICS) {
      return true;
    }
  }
  if (windowObj && code === windowObj.ENABLE_FLAG) {
    return true;
  }
  const configValue = getAccountConfigValue(code);
  if (configValue == ON_OFF_CONFIG_VALUE.ON) {
    return true;
  } else {
    return false;
  }
}

export const isOpenZoomLinksEnabled = () => {
  return isAccountConfigEnabled(CONFIG_CODES.OPEN_ZOOM_LINKS);
};

export const isFlagEnabled = (configs: Configurations) => {
  const result = configs.accountConfigurations?.find(
    (item) => item.value == ON_OFF_CONFIG_VALUE.ON
  );
  if (configs.accountConfigurations && configs.accountConfigurations?.length) {
    return !!result;
  } else {
    return configs.defaultValue == ON_OFF_CONFIG_VALUE.ON;
  }
};

export const isIndexedDbEnabledForAccount = () => {
  const commonData = useContext(CommonDataContext);
  const isSidecarContext = commonData.sidecarContext?.isSidecar;
  if (isSidecarContext) {
    return false;
  }
  return isAccountConfigEnabled(CONFIG_CODES.ENABLE_INDEXED_DB);
};

export const isContentMigrationAccessGiven = () => {
  const userUuid = getUserUUID();
  const userUuidListStr = getAccountConfigValue(
    CONFIG_CODES.CONTENT_MIGRATION_ACCESS
  );
  try {
    const userUuidsList: string[] = JSON.parse(userUuidListStr) || [];
    return userUuidsList.includes(userUuid);
  } catch (error) {
    return false;
  }
};
