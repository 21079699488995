import {Image, Input, Pressable, View} from 'native-base';
import React from 'react';
import {StyleProp, ViewStyle} from 'react-native';
import {Colors} from '../../styles';
import { TestIdentifiers, testID } from '../../testUtils';

export const CustomHeaderBackArrow = (props: {
  onPress: () => void;
  iconType: string;
  parentViewStyle?: StyleProp<ViewStyle>;
}) => {
  const {onPress, iconType, parentViewStyle} = props;
  const back_arrow =
    '../../assets/images/PNGImages/TabsPngIcons/back_arrow.png';
  const back = '../../assets/images/PNGImages/TabsPngIcons/Back.png';
  return (
    <Pressable
      onPress={onPress}
      paddingLeft={iconType === 'arrow' ? 4 : 0}
      paddingRight={iconType === 'arrow' ? 2 : 0}
      paddingY={iconType === 'arrow' ? 3 : 0}
      style={parentViewStyle ? parentViewStyle : {}}
      {...testID(TestIdentifiers.backButton)}
    >
      <Image
        resizeMode="contain"
        width={iconType === 'arrow' ? 14 : '40px'}
        height={iconType === 'arrow' ? 14 : '40px'}
        source={iconType === 'arrow' ? require(back_arrow) : require(back)}
        alt="image"
        {...testID(TestIdentifiers.backButton)}
      />
    </Pressable>
  );
};
