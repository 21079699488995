import {Box, HStack, Image, Text, Tooltip, VStack} from 'native-base';
import React, {useCallback, useEffect, useState} from 'react';
import {SUPPORTED_EVENT_CODE} from '../../../constants/WebSocketConst';
import {
  IContactTypingMsgData,
  IWSSender,
} from '../../../Interfaces/WebSocketEventInterfaces';
import {
  getTypingUsersText,
  getTypingUsersToolTipText,
  getUserId,
} from '../../../utils/commonUtils';
import {EventBus} from '../../../utils/EventBus';
import {IConversationData} from '../../RightSideContainer/TeamInbox/Conversations/interfaces';
import {
  getUniqueTypingUsersList,
  isUserAlreadyTyping,
} from '../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MessagingUtils';
import {getStyleForTypingBox, styles} from './TypingOnStyle';
import {isWeb} from '../../../utils/platformCheckUtils';
import {CHANNEL_TYPE_CODE} from '../../RightSideContainer/TeamInbox/Conversations/ConversationConst';
import { testID } from '../../../testUtils';

const TypingOn = (props: {
  selectedConversation: IConversationData;
  selectedInboxTypeCode: string;
  isNewMessageShown?: boolean;
}) => {
  const {selectedConversation} = props;
  const userId = getUserId();
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const gif = require('../../../assets/images/typing.gif');

  const [typingState, setTypingState] = useState<{
    isTypingOn: boolean;
    typingUsers: IWSSender[];
  }>({
    isTypingOn: false,
    typingUsers: [],
  });

  const onUserTypingListenerFn = useCallback(
    (data: IContactTypingMsgData) => {
      if (
        data &&
        data?.conversation &&
        data?.conversation?.id &&
        data?.conversation?.id === props?.selectedConversation?.id &&
        data?.user?.id !== userId
      ) {
        if (
          typingState.typingUsers.length === 0 ||
          !isUserAlreadyTyping(typingState.typingUsers, data.user)
        ) {
          setTypingState((prev) => {
            return {
              ...prev,
              isTypingOn: true,
              typingUsers: getUniqueTypingUsersList([
                data.user,
                ...prev.typingUsers,
              ]),
            };
          });
        }
      }
    },
    [selectedConversation?.id]
  );

  const onUserTypingOffListenerFn = useCallback(
    (data: IContactTypingMsgData) => {
      if (data && data?.conversation && data?.conversation?.id) {
        setTypingState((prev) => {
          return {
            ...prev,
            isTypingOn: false,
            typingUsers: [],
          };
        });
      }
    },
    [selectedConversation]
  );

  useEffect(() => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener(
      SUPPORTED_EVENT_CODE.CONVERSATION_TYPING_ON,
      onUserTypingListenerFn
    );

    eventBus.addEventListener(
      SUPPORTED_EVENT_CODE.CONVERSATION_TYPING_OFF,
      onUserTypingOffListenerFn
    );

    return () => {
      eventBus.removeEventListener(onUserTypingListenerFn);
      eventBus.removeEventListener(onUserTypingOffListenerFn);
    };
  }, [selectedConversation?.id]);

  const getTypingElem = (): JSX.Element => {
    const typingBoxStyle = getStyleForTypingBox(props?.selectedInboxTypeCode, props?.isNewMessageShown);
    const typingText = getTypingUsersText(typingState.typingUsers);

    return (
      <HStack style={typingBoxStyle}>
        <VStack justifyContent={'flex-end'}>
          <Text {...testID('TypingUserName')} color={'coolGray.400'}>{typingText}</Text>
        </VStack>
        <VStack justifyContent={'flex-start'}>
          <Image
            resizeMode="cover"
            backgroundSize={'88%'}
            width={'10'}
            height={'5'}
            source={gif}
            alt={'Alternate Text xs'}
          />
        </VStack>
      </HStack>
    );
  };
  return (
    <>
      {(typingState.isTypingOn &&
      typingState.typingUsers &&
      typingState.typingUsers.length) ? (
        <Box
          style={{
            alignItems: 'center',
          }}
        >
          {getTypingUsersToolTipText() ? (
            <Tooltip
              placement={'top'}
              key={1}
              label={getTypingUsersToolTipText()}
            >
              {getTypingElem()}
            </Tooltip>
          ) : (
            <>{getTypingElem()}</>
          )}
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default TypingOn;
