

export enum MedicationViewType {
  edit = 'EDIT',
  list = 'LIST' 
}


export enum MedicationStatus {
  willStop = 'WILL_STOP',
  willContinue = 'WILL_CONTIMUE'
}