import {IKeyOperation} from '../../../../../../../Interfaces';
import {IAddOrUpdatePastMedicalHistoryParams, IPastMedicalHistory, IPastMedicalHistoryComponentValue} from '../interfaces';
import { PastMedicalHistoryFields } from '../../CustomComponentUtils';
import { ComponentType } from '../../Diagnosis/interfaces';

const extension_url_type = 'type'
export const getRecordListFormatted = (list: any[],fields?:IKeyOperation) => {
  const entries = [];
  for (const el of list || []) {
    entries.push(el.resource);
  }
  const formattedData = getRecordDataFormatted(entries, fields);
  return sortItems(formattedData);
}

export const getRecordDataFormatted = (listData: any[],fields?:IKeyOperation): IPastMedicalHistory[] => {
  const tempFormattedList: IPastMedicalHistory[] = [];
  listData.map((item: any) => {
    if (item?.code) {
      const status = item?.clinicalStatus?.coding?.length
        ? item.clinicalStatus?.coding[0]
        : {code: item.clinicalStatus?.text};
      let note = '';
      if (item?.note?.length) {
        note = item.note[0].text || '';
      }
      const pastMedicalHistoryType = item?.extension?.length && fields?.type?.extensionUrl ? item.extension.find((ext: {url:string,valueString:string}) => ext.url == fields?.type?.extensionUrl)?.valueString : null;
      const type = item?.extension?.length ? item.extension.filter((ext: {url:string,valueString:string}) => ext.url.includes(extension_url_type))?.[0]?.valueString : null;
      const tempObj: IPastMedicalHistory = {
        id: item?.id,
        onSetDateTime: item?.onsetDateTime,
        pastMedicalHistory: item.code,
        name: getPastMedicalHistoryName(item.code),
        clinicalStatus: status,
        note,
        uniqueId: item?.id,
        isFreeTextRecord: !item.code?.coding?.length,
        type,
        showNote: note.length > 0 || fields?.note?.isRequired,
        pastMedicalHistoryType: {code: pastMedicalHistoryType},
        fieldType: item?.category?.[0]?.code || 'past-medical-history', // if no category then set it to PastMedicalHistory for backward compatibility
        extension: item.extension,
      };

      tempFormattedList.push(tempObj);
    }
  });
  return tempFormattedList;
};

export const getSampleData = (): IPastMedicalHistoryComponentValue => {
  return {
    pastMedicalHistories: [
      {
        id: '34234324',
        onSetDateTime: '2020-12-24',
        pastMedicalHistory: {
          text: `Sample ${
            'Medical History'
          }`,
          coding: [],
        },
        name: `Sample ${
          'Medical History'
        }`,
        clinicalStatus: {code: 'active', display: 'Active'},
        note: 'Test notes',
        uniqueId: '1234',
        isFreeTextRecord: false,
      },
    ],
  };
};

export const sortItems = (records: IPastMedicalHistory[]) => {
  return records.sort((a: IPastMedicalHistory, b: IPastMedicalHistory) => {
    const statusA = a.clinicalStatus?.code || '';
    const statusB = b.clinicalStatus?.code || '';
    if (statusA !== statusB) {
      return statusA.toLowerCase().localeCompare(statusB.toLowerCase());
    }
    return (
      new Date(
        b.recordedDate || b.onSetDateTime || new Date()
      ).getTime() -
      new Date(
        a.recordedDate || a.onSetDateTime || new Date()
      ).getTime()
    );
  })
};

export const getPastMedicalHistoryName = (item: any) => {
  let name = item?.text;
  if (!name && item?.coding?.length) {
    name = item.coding[0].display;
  }
  return name;
};

export const isInvalid = (
  field: PastMedicalHistoryFields,
  pastMedicalHistoryData: IPastMedicalHistory,
  submitTriggered: boolean,
  fields?: IKeyOperation
) => {
  if (field !== PastMedicalHistoryFields.code && pastMedicalHistoryData.isFreeTextRecord && fields?.code?.hideFieldsForFreeText) {
    return false;
  }
  switch (field) {

    case PastMedicalHistoryFields.code:
      if ((fields?.type?.isRequired || false) && submitTriggered) {
        return !pastMedicalHistoryData.pastMedicalHistoryType || !pastMedicalHistoryData.pastMedicalHistoryType.code;
      }
      return false;

    default:
      return false;
  }
};

export const getFHIRTemplate = (params: IAddOrUpdatePastMedicalHistoryParams) => {
  return {
    resourceType: 'Condition',
    ...(params.id && {id: params.id}),
    subject: {
      reference: `Patient/${params.patientId}`,
    },
    ...((params.extension || []).length > 0 && {extension: params.extension}),
    code: params.pastMedicalHistory,
    category: [
      {
        coding: [
          {
            code: ComponentType?.Condition,
          },
        ],
      },
    ],
  };
};
