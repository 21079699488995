import {Formio} from '@foldhealth/formiojs';
import ConditionalComponents from '../EditFormFields/ConditionalFields';
import KeyField from '../EditFormFields/KeyField';
import SearchableComponentFields from '../EditFormFields/SearchableComponentFields';
import {FormComponentLabelEnum} from '../FormEnum';
import ShareWithPatientFields from '../EditFormFields/ShareWithPatientFields';

const nestedComponent = Formio.Components.components.nested;
Formio.Components.components.panel.schema = (...extend: any) => {
  return nestedComponent.schema(
    {
      label: FormComponentLabelEnum.panel,
      type: 'panel',
      key: 'panel',
      title: 'Container',
      theme: 'default',
      breadcrumb: 'default',
      components: [],
      clearOnHide: false,
      input: false,
      tableView: false,
      persistent: false,
    },
    ...extend
  );
};

Formio.Components.components.panel.editForm = function () {
  return {
    key: 'display',
    components: [
      {
        type: 'oldtextfield',
        input: true,
        placeholder: 'Panel Title',
        label: 'Title',
        key: 'title',
        tooltip: 'The title text that appears in the header of this panel.',
      },
      ...SearchableComponentFields,
      ...ShareWithPatientFields,
      // {
      //   type: 'select',
      //   input: true,
      //   label: 'Theme',
      //   key: 'theme',
      //   dataSrc: 'values',
      //   data: {
      //     values: [
      //       {label: 'Default', value: 'default'},
      //       {label: 'Primary', value: 'primary'},
      //       {label: 'Info', value: 'info'},
      //       {label: 'Success', value: 'success'},
      //       {label: 'Danger', value: 'danger'},
      //       {label: 'Warning', value: 'warning'},
      //     ],
      //   },
      // },
      // KEPT FOR FUTURE
      // {
      //   type: 'checkbox',
      //   label: 'Hide Label',
      //   tooltip:
      //     'Hide the label or title of this component. This allows you to show the label in the form builder, but not when the form is shown to the member.',
      //   key: 'hideLabel',
      //   input: true,
      // },
      ...ConditionalComponents,
      ...KeyField,
    ],
  };
};
