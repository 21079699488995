import React, { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import {Drawer, Table} from 'antd';
import {useIntl} from 'react-intl';
import {BUTTON_TYPE} from '../../../../../constants/StringConst';
import {Colors} from '../../../../../styles/Colors';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import {CustomSendCommunicationButton} from '../../../LeftContainer/RecentActivity/RecentReport/CustomSendCommunicationButton';
import {ReportCategory} from './OrderUtils';
import {View, VStack, Text, Divider, HStack, Pressable} from 'native-base';
import Stack from '../../../../common/LayoutComponents/Stack';
import InfoSvg from '../../../../common/Svg/InfoSvg';
import {Dimensions} from 'react-native';
import {FHIR_RESOURCE} from '../../../../../constants/FhirConstant';
import {IMediaReport} from '../../../LeftContainer/RecentActivity/OrdersInterface';
import {useState} from 'react';
import {MediaReportView} from '../../../LeftContainer/RecentActivity/MediaReportView';
import {SOURCE_TYPES} from '../../../../RightSideContainer/TeamInbox/Conversations/MessagingContactDetails/ContactDetailsTabs/ActivityTimeLine/FeedConst';
import {fromBase64} from '../../../../../utils/commonUtils';
import { isValidXmlString, xmlStringToHtml } from '../../../../common/XmlToHtml/xmlToHtmlUtils';

export interface IExternalSourceReportDrawerProps {
  isVisible: boolean;
  reportType: string;
  onClose?: () => void;
  unformmatedContactDetails?: any;
  contactData?: any;
  onCommunicationActionPerformed?: (actionCode: string) => void;
  showCustomButton?: boolean;
  reportName?: string;
  reportDetails: {[key: string]: any}[];
  report: any;
}
const finalHeight = Dimensions.get('window').height - 120;

export const ExternalSourceReportDrawer = (
  props: IExternalSourceReportDrawerProps,
) => {
  const {
    onClose,
    reportDetails,
    reportName,
    reportType,
    isVisible,
    showCustomButton,
    unformmatedContactDetails,
    contactData,
    onCommunicationActionPerformed,
    report,
  } = props;
  const intl = useIntl();

  const [reportResultDetail, setResultDetail] = useState<{
    showModal: boolean;
    resultType: string;
    title: string;
    isHtmlFormattedData: boolean;
    htmlDataString: string;
    drawerTitle: string;
  }>({
    showModal: false,
    resultType: '',
    title: '',
    isHtmlFormattedData: false,
    htmlDataString: '',
    drawerTitle: '',
  });

  const getPreferredCommunicationDetail = () => {
    if (
      props.unformmatedContactDetails?.contactPreferredCommunicationChannel
        ?.length > 0
    ) {
      const contactPreferredCommunication =
        unformmatedContactDetails?.contactPreferredCommunicationChannel[0];
      if (contactPreferredCommunication?.id) {
        return {
          preferredCommunicationTypeId: contactPreferredCommunication?.id,
          preferredCommunicationCode:
            contactPreferredCommunication?.preferredCommunicationModeMlov?.code,
          preferredCommunicationValue:
            contactPreferredCommunication?.preferredCommunicationModeMlov
              ?.value,
        };
      }
    } else {
      return {};
    }
  };

  const preferredCommunicationDetail = getPreferredCommunicationDetail();

  const getReportResultList = () => {
    const resultData: any[] = [];
    const resources = report?.resources || [];
    for (const resource of resources) {
      if (
        resource?.resourceType === FHIR_RESOURCE.DIAGNOSTIC_REPORT &&
        resource.presentedForm?.length
      ) {
        const display = resource.category?.coding?.[0]?.display || reportName;
        const resultList: any[] = [];
        let index = 0;
        (resource?.presentedForm ?? []).forEach(
          (presentedFormResponse: any) => {
            if (
              presentedFormResponse?.contentType === 'text/html' ||
              presentedFormResponse?.contentType === 'text/xml'
            ) {
              index += 1;
              let htmlString = ''
              const xmlData = presentedFormResponse?.data;
              const content = fromBase64(xmlData);
              const isValidXmlStringContent = isValidXmlString(content);
              if (isValidXmlStringContent) {
                htmlString = xmlStringToHtml(content)
              } else {
                htmlString = `<div>${content}</div>`;
              }
              resultList.push({
                resultType: report?.orderType || report?.reportType,
                title: 'View Report ' + `${index}`,
                isHtmlFormattedData: true,
                htmlDataString: htmlString,
              });
            }
          },
        );
        if (resultList?.length) {
          resultData.push({
            display: display,
            creation: report.effectiveDateTime,
            resultList: resultList,
          });
        }
      }
      if (
        resource?.resourceType === FHIR_RESOURCE.DOCUMENT_REFERENCE &&
        resource.content?.length
      ) {
        const resourceDataContent: any[] = resource?.content || [];
        const resultList = [];
        for (const content of resourceDataContent) {
          const attachment : { contentType: string; data: string; } = content?.attachment || {};
          const contentType = attachment?.contentType;
          let index = 0;
          if (contentType == 'text/xml' && attachment?.data?.length) {
            index += 1;
            let htmlString = '';
            const xmlData: any = attachment?.data || '';
            const content = fromBase64(xmlData);
            const isValidXmlStringContent = isValidXmlString(content);
            if (isValidXmlStringContent) {
                htmlString = xmlStringToHtml(content)
            } else {
              htmlString = `<div>${content}</div>`;
            }
            resultList.push({
              resultType: report?.orderType || report?.reportType,
              title: 'View Note ' + `${index}`,
              isHtmlFormattedData: true,
              htmlDataString: htmlString,
            });
          }
        }
        if (resultList?.length) {
          resultData.push({
            display: `Note`,
            creation: report.effectiveDateTime,
            resultList: resultList,
          });
        }
      }
    }
    return resultData;
  };

  const renderExternalSourceReportDetail = (): JSX.Element => {
    return (
      <VStack space={2} style={styles.reportContainer}>
        <View style={styles.reportHeader}>
          <Text fontSize="lg" fontWeight={'bold'}>
            {reportName}
          </Text>
        </View>
        <Divider backgroundColor={Colors.Custom.Gray400} height={'0.5px'} />
        <VStack space={1} style={styles.reportContent}>
          {(reportDetails || []).map((data: {[key: string]: any}) => {
            return (
              <HStack key={data.key} alignItems="baseline">
                <Text flex={1} fontSize="xs" color={'gray.500'}>
                  {data.key}
                </Text>
                <Text flex={3} fontSize="sm" fontWeight={'medium'}>
                  {data.value}
                </Text>
              </HStack>
            );
          })}
        </VStack>
      </VStack>
    );
  };

  const getResultTableViewColumns = (): any[] => {
    const columns: any[] = [
      {
        title: 'Test Name',
        dataIndex: 'display',
        key: 'display',
        width: '50%',
        fixed: 'left',
        render: (display: string, selectedRecord: any): JSX.Element => {
          return (
            <HStack flex={1} space={2}>
              <HStack
                flex={selectedRecord.showGraph ? 0.49 : 1}
                justifyContent={'flex-start'}
                flexWrap={'wrap'}
                space={1}>
                <Text>{display}</Text>
                {selectedRecord?.unit && (
                  <Text
                    fontSize={12}
                    fontWeight={'semibold'}
                    color={
                      Colors.Custom.Gray400
                    }>{` ${selectedRecord.unit}`}</Text>
                )}
              </HStack>
            </HStack>
          );
        },
      },
      {
        title: 'Reports',
        width: '50%',
        render: (selectedRecord: any): JSX.Element => {
          return (
            <VStack space={2}>
              <View>
                {selectedRecord?.resultList?.length > 0 &&
                  (selectedRecord?.resultList || []).map(
                    (result: IMediaReport) => {
                      return (
                        <VStack>
                          <Pressable
                            onPress={() => {
                              setResultDetail((prev: any) => {
                                return {
                                  ...prev,
                                  showModal: true,
                                  resultType: result?.resultType,
                                  title: result?.title,
                                  isHtmlFormattedData:
                                    result?.isHtmlFormattedData,
                                  htmlDataString: result?.htmlDataString,
                                };
                              });
                            }}>
                            <Text style={styles.underlinedText}>
                              {result?.title}
                            </Text>
                          </Pressable>
                        </VStack>
                      );
                    },
                  )}
              </View>
            </VStack>
          );
        },
      },
    ];
    return columns;
  };
  const displayColumns = getResultTableViewColumns();

  const renderExternalSourceReport = () => {
    return (
      <Table
        rowKey={record => {
          return record?.creation || '';
        }}
        className="patient-list-view report-table"
        scroll={{y: finalHeight - 132}}
        columns={displayColumns}
        dataSource={getReportResultList()}
        pagination={false}
      />
    );
  };

  const preferredCommunicationMemo = useMemo(() => {
    return (
      preferredCommunicationDetail?.preferredCommunicationCode && (
        <Stack
          direction={'row'}
          style={styles.preferredCommunicationMemo}>
          <InfoSvg customColor={Colors.Custom.Gray500} />
          <Text style={styles.preferredCommunicationMemoText}>
            {preferredCommunicationDetail?.preferredCommunicationCode
              ? `Patient preferred communication channel is ${preferredCommunicationDetail?.preferredCommunicationCode}`
              : ''}
          </Text>
        </Stack>
      )
    );
  }, [preferredCommunicationDetail?.preferredCommunicationCode]);

  return (
    <>
      <Drawer
        headerStyle={antdStyles.drawerHeader}
        destroyOnClose
        placement="right"
        onClose={onClose}
        visible={isVisible}
        closable={false}
        width={'40%'}
        title={
          <>
            <ModalActionTitle
              title={
                reportType === ReportCategory.IMAGING
                  ? 'Results'
                  : 'Report Details'
              }
              titleColor={''}
              showCustomButton={showCustomButton}
              buttonList={[
                {
                  show: true,
                  id: 1,
                  btnText: 'close',
                  size: 'sm',
                  textColor: Colors.Custom.mainSecondaryBrown,
                  variant: BUTTON_TYPE.SECONDARY,
                  isTransBtn: false,
                  onClick: onClose,
                },
              ]}
              customButton={
                <CustomSendCommunicationButton
                  contactData={contactData}
                  defaultCommunicationCode={
                    preferredCommunicationDetail?.preferredCommunicationCode
                  }
                  title={'Send Communication'}
                  isLoading={false}
                  onActionPerformed={actionCode => {
                    if (
                      onCommunicationActionPerformed &&
                      typeof onCommunicationActionPerformed === 'function'
                    ) {
                      onCommunicationActionPerformed?.(actionCode);
                    }
                  }}
                />
              }
            />
          </>
        }>
        <View marginX={-2}>
          {preferredCommunicationMemo}
          {renderExternalSourceReportDetail()}
          {renderExternalSourceReport()}
        </View>
      </Drawer>

      {reportResultDetail.showModal && (
        <MediaReportView
          showReportPdf={true}
          reportSource={SOURCE_TYPES.HIE}
          sourceType={SOURCE_TYPES.HIE}
          title={reportResultDetail?.title}
          htmlDataString={reportResultDetail.htmlDataString}
          isHtmlFormattedData={reportResultDetail.isHtmlFormattedData}
          isImaging={true}
          onClose={() => {
            setResultDetail(prev => {
              return {
                ...prev,
                showModal: false,
                resultType: '',
                title: '',
                isHtmlFormattedData: false,
                htmlDataString: '',
              };
            });
          }}
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  reportContainer: {
    borderRadius: 8,
    borderWidth: 0.5,
    borderColor: Colors.Custom.Gray400,
  },
  reportHeader: {
    paddingHorizontal: 12,
    paddingTop: 12,
    paddingBottom: 4,
  },
  reportContent: {
    paddingHorizontal: 12,
    paddingTop: 4,
    paddingBottom: 12,
  },
  underlinedText: {
    textDecorationColor: 'black',
    textDecorationStyle: 'solid',
    textDecorationLine: 'underline',
  },
  preferredCommunicationMemo: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  preferredCommunicationMemoText: {
    color: Colors.Custom.Gray500,
  },
});

const antdStyles: Record<string, React.CSSProperties> = {
  drawerHeader: {
    borderWidth: 0,
    marginBottom: 0,
    paddingBottom: 0,
  },
  table: {
    maxHeight: `${finalHeight - 132}px`,
  },
};