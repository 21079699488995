import {AxiosRequestConfig} from 'axios';
import BaseService from '../../../services/CommonService/BaseService';
import {ICallTrailResponse} from './interfaces';
import {CLOUD_TELEPHONY_API_ENDPOINTS} from '../CloudTelephony/CloudTelePhoneyConst';

const baseService = BaseService.getSharedInstance().axios;

export const getCallTrailByCallId = async (data: {
  callId: string;
  config: AxiosRequestConfig;
}) => {
  const url = CLOUD_TELEPHONY_API_ENDPOINTS.CALL_TRAIL.replace(
    '#CALL_ID',
    data.callId
  );
  const response = await baseService.get<ICallTrailResponse>(url, data.config);
  return response.data;
};
