import { APPOINTMENT_PARTICIPANT_TYPE_CODES } from "../../../../constants/MlovConst";
import { ACTION_CODE, APPOINTMENT_RESOURCE_TYPE_CODES, fieldsOfTask, PARTICIPANT_ACTION_CODE, RESOURCE_CODE_TO_FIELD, TASK_RESOURCE_TYPE_CODES } from "../../Audit/AuditHelper";
import { IAuditState } from "../../Audit/AuditInterfaces"
import { IAppointmentParticipantTypeIds } from "../../CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/BottomTabs";
import { TASK_CHANGE_PERFORMER_TYPE } from "./TaskAuditConstants";

export const processActivityLogs = (
    activityLogs: IAuditState[],
    resourceTypeCodes: Array<keyof typeof RESOURCE_CODE_TO_FIELD>, // these are the codes for which data needs to be collected across multiple logs
    creationTransactionInfo?: {creationCode: string, transactionId: string},
    appointmentParticipantTypeIds?: IAppointmentParticipantTypeIds
): {restructuredData: IAuditState[], patientCareJourneyIds?: string[]} => {
    const allReplacementLogs: Record<string, Record<string, IAuditState[]>> = {};
    const processedLogs: Record<string, any> = {};
    const patientCareJourneyIds: string[] = [];
    let isCreatedLogAddedToResult = false;

    resourceTypeCodes.forEach((code) => {
        allReplacementLogs[code] = {};
    });

    activityLogs.forEach((log) => {
        if (resourceTypeCodes?.includes(log.resourceTypeCode as keyof typeof RESOURCE_CODE_TO_FIELD)) {
            const transactionId = log.transactionId;
            if (!allReplacementLogs[log.resourceTypeCode][transactionId]) {
                allReplacementLogs[log.resourceTypeCode][transactionId] = [];
            }
            allReplacementLogs[log.resourceTypeCode][transactionId].push(log)
        }
    });

    const resultActivityLogs = activityLogs.reduce((result, log) => {
        const isTransactionMatchedToCreationTransaction = log?.transactionId === creationTransactionInfo?.transactionId && creationTransactionInfo?.creationCode === log?.resourceTypeCode
        const isAppointmentSeriescreationLog = log?.resourceTypeCode === APPOINTMENT_RESOURCE_TYPE_CODES?.APPOINTMENT && isTransactionMatchedToCreationTransaction && log?.data?.newData?.isRecurrentAppointment
        const shouldPerformAction = !creationTransactionInfo ||
            log?.transactionId !== creationTransactionInfo?.transactionId ||
                (isTransactionMatchedToCreationTransaction && !isCreatedLogAddedToResult);
        if (!isCreatedLogAddedToResult && (isTransactionMatchedToCreationTransaction)) {
            isCreatedLogAddedToResult = true;
        }
        if (shouldPerformAction) {
            //above condition explained-> eg: when task is created with labels and attachment, different log is created and they have the same transaction ids. This log is to be excluded because we only need to show '[userName] created the Task'
            const transactionId = log.transactionId;

            if (resourceTypeCodes?.includes(log.resourceTypeCode as keyof typeof RESOURCE_CODE_TO_FIELD)) {
                if (!(processedLogs?.[transactionId]?.includes(log?.resourceTypeCode))) {
                    let replacementLogs: IAuditState[] = [];
                    replacementLogs = allReplacementLogs[log.resourceTypeCode][transactionId] || [];
                    if (log?.resourceTypeCode === APPOINTMENT_RESOURCE_TYPE_CODES?.APPOINTMENT_PARTICIPANT && appointmentParticipantTypeIds) {
                        const { newLogs, patientCareJourneyId } = handleAppointmentParticipant(replacementLogs, appointmentParticipantTypeIds, log)
                        if (patientCareJourneyId) {
                            patientCareJourneyIds.push(patientCareJourneyId)
                        }
                        result.push(...newLogs)
                    }
                    else {
                        const field = RESOURCE_CODE_TO_FIELD[log.resourceTypeCode as keyof typeof RESOURCE_CODE_TO_FIELD]
                        let oldData = [];
                        let newDataForNewLog: any = [];
                        let oldDataForNewLog: any = [];
                        if (log.resourceTypeCode === APPOINTMENT_RESOURCE_TYPE_CODES.APPOINTMENT_TASK) {
                            oldData = log?.parentResourceData?.oldData?.oldAppointmentTasks;
                            const deletedIds = replacementLogs
                                ?.filter((log) => log?.data?.newData?.isDeleted)
                                ?.map((item) => item?.data?.newData?.id);

                            const filteredNewData = replacementLogs
                                ?.filter((replacementLog) => !replacementLog?.data?.newData?.isDeleted)
                                ?.map((item) => item?.data?.newData);

                            newDataForNewLog = [
                                ...(oldData?.filter((item: any) => !deletedIds?.includes(item?.id) && !filteredNewData.some((newItem) => newItem.id === item.id)) || []),
                                ...(filteredNewData || []),
                            ]?.map((item) => item?.title);

                            oldDataForNewLog = oldData?.map((item: any) => item?.title);
                        }
                        else {
                            oldData = log?.parentResourceData?.oldData;
                            const sortedOldData = oldData?.sort((a: { createdOn: string | number | Date; }, b: { createdOn: string | number | Date; }) => new Date(a.createdOn)?.getTime() - new Date(b.createdOn)?.getTime());

                            const filteredSortedNewData = replacementLogs
                                ?.filter((replacementLog) => !replacementLog?.data?.newData?.isDeleted)
                                ?.sort((a, b) => new Date(a?.data?.newData?.createdOn)?.getTime() - new Date(b?.data?.newData?.createdOn)?.getTime());

                            const deletedIds = replacementLogs
                                ?.filter((replacementLog) => replacementLog?.data?.newData?.isDeleted)?.map((log) => log?.data?.newData?.[field])

                            oldDataForNewLog = Array.from(new Set(sortedOldData?.map((data: { [x: string]: any; }) => data?.[field])));
                            const idsForNewLog = Array.from(new Set(filteredSortedNewData?.map((log) => log?.data?.newData?.[field])));
                            newDataForNewLog = Array.from(new Set([...oldDataForNewLog, ...idsForNewLog]?.filter((id) => !(deletedIds?.includes(id)))));
                        }

                        const newLog: IAuditState = {
                            ...log,
                            data: {
                                oldData: {
                                    [field]: oldDataForNewLog,
                                    ...(log?.data?.oldData?.seriesReference && { seriesReference: log?.data?.oldData?.seriesReference })
                                },
                                newData: {
                                    ...log?.data?.newData,
                                    [field]: newDataForNewLog,
                                    ...(log?.data?.newData?.seriesReference && { seriesReference: log?.data?.newData?.seriesReference })
                                },
                            },
                            actionCode: ACTION_CODE.UPDATE,
                        };

                        result.push(newLog);
                    }
                    if (processedLogs[transactionId]) {
                        processedLogs[transactionId].push(log?.resourceTypeCode)
                    }
                    else processedLogs[transactionId] = [log?.resourceTypeCode]
                }
            } else {
                if (isAppointmentSeriescreationLog) {
                    // this log is pushed to show the appointment series created log
                    const newLog: IAuditState = {
                        ...log,
                        showSeriesCreationAuditLog: true,
                        data: {
                            oldData: {},
                            newData: {},
                        },
                        actionCode: ACTION_CODE.CREATE,
                    };
                    result.push(newLog)
                }
                else result.push(log);
            }
        }
        return result;
    }, [] as IAuditState[]);
    return { restructuredData: resultActivityLogs, patientCareJourneyIds}
}; 

  const handleAppointmentParticipant = (replacementLogs: IAuditState[], appointmentParticipantTypeIds: IAppointmentParticipantTypeIds, log: IAuditState) => {
    const contactData: any = [];
    const primaryUserData: any = [];
    const secondaryUserData: any = [];
    const oldContactIds = [];
    const oldPrimaryUserIds = [];
    const oldSecondaryUserIds = [];
    const newLogs: IAuditState[] = [] as IAuditState[];
    replacementLogs?.forEach((replacement) => {
        if ((replacement?.actionCode !== ACTION_CODE.UPDATE) && (replacement?.actionCode !== ACTION_CODE.CREATE)) {
            let participantTypeCode = ''
            if (replacement?.performedByTypeCode === TASK_CHANGE_PERFORMER_TYPE.CONTACT) {
                participantTypeCode = APPOINTMENT_PARTICIPANT_TYPE_CODES.PATIENT
            }
            else participantTypeCode = APPOINTMENT_PARTICIPANT_TYPE_CODES.PRIMARY_USER
            newLogs.push({ ...replacement, participantTypeCode: participantTypeCode })
        }
        else {
        if (replacement?.data?.newData?.contactId) {
            contactData.push(replacement.data?.newData);
        }
        else if (!replacement?.data?.newData?.participantTypeId) {
            const participantTypeId = log?.parentResourceData?.oldData?.oldAppointmentParticipants?.find(
                (participant: any) => participant?.userId === replacement?.data?.newData?.userId)
                ?.participantTypeId
            participantTypeId === appointmentParticipantTypeIds?.primaryUser ? primaryUserData.push(replacement.data?.newData) : secondaryUserData.push(replacement.data?.newData)
        }
        else if (replacement?.data?.newData?.participantTypeId === appointmentParticipantTypeIds?.primaryUser) {
            primaryUserData.push(replacement.data?.newData)
        }
        else secondaryUserData.push(replacement.data?.newData)
    }
    })
    if (contactData?.length > 0) {
            newLogs?.push({
                ...log, data: {
                    oldData: null,
                    newData: { contactIds: contactData?.map((contact: { contactId: string }) => contact?.contactId) }
                },
                actionCode: ACTION_CODE.UPDATE,
                participantTypeCode: APPOINTMENT_PARTICIPANT_TYPE_CODES?.PATIENT,
                participantActionCode: contactData?.[0]?.isDeleted ? PARTICIPANT_ACTION_CODE.REMOVE : PARTICIPANT_ACTION_CODE.ADD,
                ...(contactData?.[0]?.patientCareJourneyId && { performedByPatientCareJourneyId: contactData?.[0]?.patientCareJourneyId })
            })
    }
    if (primaryUserData?.length > 0) {
        const mappedOldPrimaryUserIds = log?.parentResourceData?.oldData?.oldAppointmentParticipants
            ?.filter((participant: any) => participant?.participantTypeId === appointmentParticipantTypeIds?.primaryUser)
            ?.map((item: any) => item?.userId)
        oldPrimaryUserIds?.push(mappedOldPrimaryUserIds)
        const deletedIds = primaryUserData?.filter((item: any) => item?.isDeleted)?.map((user: any) => user?.userId)
        const mappedNewUserIds = primaryUserData?.map((user: any) => user?.userId)
        const newDataForNewLog = Array.from(new Set([...mappedOldPrimaryUserIds, ...mappedNewUserIds]?.filter((id) => !(deletedIds?.includes(id)))));
        if (mappedNewUserIds) {
            newLogs?.push({
                ...log, data: {
                    oldData: {
                        userIds: mappedOldPrimaryUserIds,
                        ...(log?.data?.oldData?.seriesReference && { seriesReference: log?.data?.oldData?.seriesReference })
                    },
                    newData: {
                        userIds: newDataForNewLog,
                        ...(log?.data?.newData?.seriesReference && { seriesReference: log?.data?.newData?.seriesReference })
                    }
                },
                actionCode: ACTION_CODE.UPDATE,
                participantTypeCode: APPOINTMENT_PARTICIPANT_TYPE_CODES?.PRIMARY_USER
            })
        }
    }
    if (secondaryUserData?.length > 0) {
        const mappedOldSecondaryUserIds = log?.parentResourceData?.oldData?.oldAppointmentParticipants
            ?.filter((participant: any) => participant?.participantTypeId === appointmentParticipantTypeIds?.secondaryUser)
            ?.map((item: any) => item?.userId)
        oldSecondaryUserIds?.push(mappedOldSecondaryUserIds)
        const deletedIds = secondaryUserData?.filter((item: any) => item?.isDeleted)?.map((user: any) => user?.userId)
        const mappedNewUserIds = secondaryUserData?.map((user: any) => user?.userId)
        const newDataForNewLog = Array.from(new Set([...mappedOldSecondaryUserIds, ...mappedNewUserIds]?.filter((id) => !(deletedIds?.includes(id)))));
        if (mappedNewUserIds) {
            newLogs?.push({
                ...log, data: {
                    oldData: {
                        userIds: mappedOldSecondaryUserIds,
                        ...(log?.data?.oldData?.seriesReference && { seriesReference: log?.data?.oldData?.seriesReference })
                    },
                    newData: {
                        userIds: newDataForNewLog,
                        ...(log?.data?.newData?.seriesReference && { seriesReference: log?.data?.newData?.seriesReference })
                    }
                },
                actionCode: ACTION_CODE.UPDATE,
                participantTypeCode: APPOINTMENT_PARTICIPANT_TYPE_CODES?.SECONDARY_USER
            })
        }
    }
    const patientCareJourneyId = contactData?.[0]?.patientCareJourneyId
    return {newLogs, patientCareJourneyId}
}
