import { Skeleton, Text, View } from "native-base";
import { useEffect, useState } from "react";
import { getAccountUUID, getDisplayTimeSaved } from "../../../utils/commonUtils";
import { getTotalTimeSavedByWorkflow } from "../../RightSideContainer/Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi";

const WorkflowTimeSavedView = (props: {size?:string, color?:string,isHideLabel?:boolean,workflowMasterId?:string }) => {
  const tenantId = getAccountUUID();
  const [timeSaveInSeconds, setTimeSaveInSeconds] = useState(0);
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    setIsLoading(true);
    const variables:any = {
      nodeLogBoolExp: {
        tenantId: {
          _eq: tenantId
        }
      }
    }
    if(props.workflowMasterId){
      variables.nodeLogBoolExp.workflowExecutionLog = {
        workflowMasterId: {
          _eq: props.workflowMasterId
        }
      }
    }
    getTotalTimeSavedByWorkflow(variables).then((response) => {
      const timeSaveInSeconds = response?.timeSaveInSeconds?.aggregate?.sum?.timeSaveInSeconds;
      setTimeSaveInSeconds(timeSaveInSeconds);
      setIsLoading(false);
    })
  }, [tenantId])
  return <View>
    {isLoading ? <Skeleton.Text lines={1} /> :
      <Text size={props.size} color={props.color} >
        {props.isHideLabel ? '' :'Time Saved :  '}{getDisplayTimeSaved(timeSaveInSeconds)}
      </Text>}
  </View>

};

export default WorkflowTimeSavedView;
