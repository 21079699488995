import { Pressable, Text, View } from 'native-base';
import { Colors } from '../../../styles';
import { styles } from './CustomSwtichStyles';
import React from 'react';

interface IFHCustomSwitchProps {
  btnTextArray: { text: string; code: string, leadingIcon?:any }[];
  selectedCode: string;
  onBtnSelect: (code: string) => void;
  customSelectedTextColor?: string;
  customSelectedBackground?: string;
  customerUnselectedColor?: string;
}

const FHCustomSwitch = (props: IFHCustomSwitchProps) => {
  const { btnTextArray, selectedCode, onBtnSelect, customSelectedBackground, customSelectedTextColor, customerUnselectedColor } = props;

  return (
    <View
      style={{
        height: 40,
        borderRadius: 52,
        borderWidth: 1,
        borderColor: Colors.Custom.Gray200,
        flexDirection: 'row',
        alignItems: 'center',
        marginHorizontal: 12,
        paddingHorizontal: 4,
        paddingVertical: 8,
        backgroundColor: '#fff',
      }}
    >
      {btnTextArray.map((btn) => {
        return (
          <Pressable
            key={btn.code}
            onPress={() => {
              onBtnSelect(btn.code);
            }}
          >
            <View
              style={[
                styles.swtichBtnStyle,
                selectedCode === btn.code
                  ? { backgroundColor: customSelectedBackground || Colors.primary['300'] }
                  : {},
              ]}
            >
              {btn.leadingIcon}
              <Text
                size={'xsBold'}
                color={
                  selectedCode === btn.code ? (customSelectedTextColor || '#fff') : (customerUnselectedColor || Colors.Custom.Gray900)
                }
              >
                {btn.text}
              </Text>
            </View>
          </Pressable>
        );
      })}
    </View>
  );
};

export default FHCustomSwitch;
