import MDEditor from '@uiw/react-md-editor';
import {Drawer} from 'antd';
import {Text, View} from 'native-base';
import React, {useState} from 'react';
import {Dimensions} from 'react-native';
import {COMMON_ACTION_CODES} from '../../../constants/ActionConst';
import {Colors} from '../../../styles';
import {getFormattedDateString} from '../../PersonOmniView/LeftContainer/OtherDetails/OtherDeatilsUtils';
import {DisplayText} from '../DisplayText/DisplayText';
import {ModalActionTitle} from '../ModalActionTitle/ModalActionTitle';

const ContactNoteReadOnlyView = (props: any) => {
  const [showModal, setShowModal] = useState(true);
  const closeModal = () => {
    setShowModal(false);
    props.onDeleteCompleteAction(COMMON_ACTION_CODES.RESET);
  };
  const selectedNote = props.selectedNote;
  const {width} = Dimensions.get('window');
  const finalWidth = width / 3;
  return (
    <Drawer
      visible={showModal}
      width={finalWidth}
      onClose={() => closeModal()}
      title={
        <>
          <ModalActionTitle title={'note'} titleColor={''} buttonList={[]} />
        </>
      }
    >
      <View flex={1} px={4}>
        <View marginBottom={2}>
          <Text fontStyle={'italic'} color={'gray.500'}>
            {selectedNote?.user?.name || ''}
          </Text>
          <Text color={'gray.400'}>
            {getFormattedDateString(selectedNote.createdAt + 'Z', 'dob')}
          </Text>
        </View>
        <Text
          borderColor={Colors.Custom.BorderColor}
          borderWidth={1}
          padding={2}
        >
          <div data-color-mode="light">
            <MDEditor.Markdown source={selectedNote.content} />
          </div>
        </Text>
      </View>
    </Drawer>
  );
};
export default ContactNoteReadOnlyView;
