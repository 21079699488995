import {gql} from '@apollo/client';

const CREATE_CONVERSATION_TAGGED_ITEM = gql`
  mutation addOrUpdateResourceMapping($data: [ResourceMappingInput!]) {
    addOrUpdateResourceMapping(params: $data) {
      id
      resourceId
      sourceId
    }
  }
`;

const GET_CONVERSATION_TASK_COUNT = gql`
  query getResourceMapCount($data: GetResourceMapCountInput!) {
    getResourceMapCount(params: $data) {
      sourceId
      count
    }
  }
`;

const GET_TASK_ID_BY_MESSAGE_UUID = gql`
  query getResourceMappings($data: GetResourceMappingResponseInput!) {
    getResourceMappings(params: $data) {
      sourceId
      resourceIds
    }
  }
`;

export default {
  CREATE_CONVERSATION_TAGGED_ITEM,
  GET_CONVERSATION_TASK_COUNT,
  GET_TASK_ID_BY_MESSAGE_UUID,
};
