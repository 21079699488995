import {StyleSheet} from 'react-native';
import {Colors} from '../../../../../styles';
import {isWeb} from '../../../../../utils/platformCheckUtils';
export const styles = StyleSheet.create({
  previewContainer: {
    margin: 10,
    padding: 10,
    alignItems: 'center',
    borderWidth: 1,
    borderRadius: 20,
    borderColor: Colors.Custom.BorderColor,
  },
  nameContainer: {
    marginTop: 10,
    marginBottom: 10,
    alignItems: 'center',
  },
  mainContainer: {
    minWidth: 800,
  },
  mediaContainer: {
    overflow: 'hidden',
    height: isWeb() ? 220 : 130,
    width: isWeb() ? 200 : 130,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    flexDirection: 'row',
    marginHorizontal: 10,
    marginVertical: 16,
  },
  uploadBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    // borderColor: 'black',
    // borderWidth: 1,
    // borderStyle: 'dashed',
    // padding: 70,
  },
  previewContainerView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  mediaElementContainer: {
    margin: 20,
  },
  listContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  mediaRow: {
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
  },
  submitBtnStyle: {
    height: 40,
    paddingHorizontal: 16,
    paddingVertical: 10,
    borderRadius: 20,
    borderWidth: 0,
    minWidth: 64,
  },
});
