

import { Tooltip } from 'antd';
import { HStack } from 'native-base';
import { isWeb } from '../../../../utils/platformCheckUtils';
import { getTimeSince } from '../../CommonUtils/CommonUtils';
import { ICommonLoggedProps } from '../interfaces';
import React from 'react';

const AppUsingPatientSvg = (props: ICommonLoggedProps) => {
  const strokeColor = props.loggedInTime ? '#30B566' : '#808080';
  const title= props.loggedInTime ? 'Last logged in ' + getTimeSince(new Date(props.loggedInTime)) : 'Member not logged into member app/portal.'

  return (
    <>{isWeb() ? (<Tooltip title={title}>
      <HStack marginLeft={2}>
        <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.21725 0.373215H2.0641C1.18802 0.373215 0.443634 1.08755 0.443634 1.96339V14.0386C0.443634 14.9144 1.17227 15.6269 2.04835 15.6269L7.20999 15.6214C8.08595 15.6214 8.80259 14.9087 8.80259 14.0328L8.8101 1.96255C8.80998 1.08356 8.09685 0.373215 7.21725 0.373215ZM3.89623 1.3266H5.3913C5.52264 1.3266 5.62962 1.43321 5.62962 1.56491C5.62962 1.69661 5.52264 1.80323 5.3913 1.80323H3.89623C3.76453 1.80323 3.65792 1.69661 3.65792 1.56491C3.65792 1.43321 3.76453 1.3266 3.89623 1.3266ZM2.99786 1.40244C3.08618 1.31424 3.24695 1.31303 3.33516 1.40244C3.37938 1.44654 3.40555 1.50845 3.40555 1.5717C3.40555 1.63373 3.37938 1.69552 3.33516 1.73974C3.29118 1.78396 3.22902 1.81001 3.16711 1.81001C3.10399 1.81001 3.04329 1.78396 2.99786 1.73974C2.95388 1.69564 2.9288 1.63373 2.9288 1.5717C2.92868 1.50845 2.95388 1.44654 2.99786 1.40244ZM4.59567 15.1546C4.20239 15.1546 3.88254 14.8345 3.88254 14.4413C3.88254 14.048 4.20239 13.7281 4.59567 13.7281C4.98894 13.7281 5.3088 14.048 5.3088 14.4413C5.3088 14.8345 4.98882 15.1546 4.59567 15.1546ZM8.07032 13.2433H1.2778V2.63751H8.07032V13.2433Z" fill="#948F9A" />
        </svg>
        <svg style={{ marginLeft: -4 }} stroke-width="0.7" stroke={'#ffff'} width="8" height="14" viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.98508 0.53273C1.98248 0.53273 0.353241 2.16197 0.353241 4.16457C0.353241 6.16717 1.98248 7.79641 3.98508 7.79641C5.98768 7.79641 7.61692 6.16717 7.61692 4.16457C7.61692 2.16197 5.98768 0.53273 3.98508 0.53273ZM3.25908 5.7673L1.91057 4.42171L2.42339 3.90744L3.25835 4.74094L5.18105 2.81825L5.69459 3.33179L3.25908 5.7673Z" fill={strokeColor} />
        </svg>
      </HStack>
    </Tooltip>) : null}</>
  );
};
export default AppUsingPatientSvg;
