import {AlertDialog, Button, HStack, Stack, Text, View} from 'native-base';
import React from 'react';
import {useIntl} from 'react-intl';
import {FHAlertDialogProps} from './interface';
import { TestIdentifiers, testID } from '../../../testUtils';

const FHAlertDialog = (props: FHAlertDialogProps) => {
  const cancelRef = React.useRef(null);
  const intl = useIntl();
  return (
    <AlertDialog
      isOpen={props.isOpen}
      leastDestructiveRef={cancelRef}
      onClose={props.onClose}
    >
      <AlertDialog.Content>
        {props.showCloseBtn && <AlertDialog.CloseButton />}
        <AlertDialog.Header fontSize="lg" fontWeight="bold" {...testID(TestIdentifiers.alertTitle)}>
          {props.header || intl.formatMessage({id: 'defaultAlertDialogHeader'})}
        </AlertDialog.Header>
        <AlertDialog.Body>
          <View>
            {props.bodyContent ? (
              props.bodyContent
            ) : (
              <Text {...testID(TestIdentifiers.alertMessage)}>
                {props.message ||
                  intl.formatMessage({id: 'defaultAlertDialogMessage'})}
              </Text>
            )}

            {/* <AlertDialog.Footer> */}
            <Stack flexDir={props.showVerticalButtons ? 'column' : 'row'} justifyContent={'flex-end'} width={'100%'} mt={2}>
              {props.buttonActions.map((buttonAction, index) => {
                const buttonText = intl.formatMessage({id: buttonAction.textLocalId});
                return (
                  <Button
                    // flex={0.4}
                    isLoading={buttonAction.isDeleting || false}
                    key={index}
                    onPress={buttonAction.onPress}
                    {...buttonAction.buttonProps}
                    style={{
                      height: 40,
                      paddingHorizontal: 16,
                      paddingVertical: 10,
                      marginLeft: props.showVerticalButtons ? undefined : 12,
                      // borderRadius: 20,
                      // borderWidth: 1,
                      marginVertical:  props.showVerticalButtons ? 4 : undefined,
                      width: props.showVerticalButtons ? undefined : 100,
                    }}
                    {...testID(buttonText + TestIdentifiers.alertBtn)}
                  >
                    {/* <DisplayText
                      textLocalId={buttonAction.textLocalId}
                      extraStyles={{color: buttonAction.textColor || 'black'}}
                    /> */}
                    <Text
                      size={'smNormal'}
                      color={buttonAction.textColor || 'black'}
                    >

                      {buttonText}
                    </Text>
                  </Button>
                );
              })}
            </Stack>
          </View>
        </AlertDialog.Body>
      </AlertDialog.Content>
    </AlertDialog>
  );
};

export default FHAlertDialog;
