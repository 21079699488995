import React from 'react';

function EmptyFormForNoteIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="161"
      height="161"
      fill="none"
      viewBox="0 0 161 161"
    >
      <path
        fill="#F2F4F7"
        d="M80.5 160.503c44.182 0 80-35.817 80-80s-35.818-80-80-80c-44.183 0-80 35.817-80 80s35.817 80 80 80z"
      ></path>
      <path
        stroke="#D0D5DD"
        strokeLinecap="round"
        strokeWidth="3.687"
        d="M32.511 35.63l21.2 20.278M79.614 26.82l-.445 29.335M124.901 35.118l-19.922 21.536"
      ></path>
      <path
        fill="#E4E7EC"
        d="M25.107 116.886h106.925v33.183a7.375 7.375 0 01-7.374 7.375H32.481a7.375 7.375 0 01-7.374-7.375v-33.183z"
      ></path>
      <g filter="url(#filter0_d_2241_52451)">
        <path
          fill="#fff"
          d="M24.968 114.989l13.236-38.032a3.687 3.687 0 013.482-2.475h74.22a3.687 3.687 0 013.493 2.508l12.906 38.243c.786 2.328-.883 4.759-3.338 4.863l-22.324.945a8.064 8.064 0 00-7.723 8.057 8.064 8.064 0 01-8.064 8.064H67.29a8.296 8.296 0 01-8.267-7.608l-.171-2.058a8.296 8.296 0 00-8.268-7.608H28.451c-2.537 0-4.316-2.503-3.483-4.899z"
        ></path>
      </g>
      <path
        fill="#E4E7EC"
        d="M48.037 102.077l4.221-13.366a6.452 6.452 0 016.153-4.51H97.04c2.74 0 5.181 1.732 6.088 4.317l4.69 13.366c1.472 4.196-1.642 8.588-6.088 8.588H54.19c-4.36 0-7.466-4.236-6.153-8.395z"
      ></path>
      <path
        stroke="#D0D5DD"
        strokeLinecap="round"
        strokeWidth="2.765"
        d="M58.291 148.222h40.558"
      ></path>
      <defs>
        <filter
          id="filter0_d_2241_52451"
          width="141.886"
          height="96.826"
          x="10.534"
          y="60.255"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="2.845" dy="2.845"></feOffset>
          <feGaussianBlur stdDeviation="8.536"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0.596373 0 0 0 0 0.635165 0 0 0 0 0.701667 0 0 0 0.25 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2241_52451"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_2241_52451"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
}

export default (EmptyFormForNoteIcon);
