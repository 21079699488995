import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {useIdleTimer} from 'react-idle-timer'
import {WEB_SOCKET_EVENT_CODE} from '../../constants/WebSocketConst';
import {EventBus} from '../../utils/EventBus';

export const IdleTimer = (props:any) => {
  const timeout = 1000 * 15 * 60;
  const remainingTimeAfterIdle = 30000;
  const intervalTime = 10000;
  const onIdle = () => {
    props?.onIdle()
  }
  const onActive = () => {
    props?.onActive()
  }
  const { getRemainingTime,activate,getLastActiveTime } = useIdleTimer({
    timeout,
    onIdle,
    onActive
  })
  const handleStillHere = () => {
    activate()
  }

  const [stateData,setStateData] = useState({
    prevLastActiveTime: '' as any,
    lastActiveTime: '' as any
  })

  const handleLockScreen = () => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.broadcastEvent(WEB_SOCKET_EVENT_CODE.LOCK_SCREEN, {data:''});
  }

  const onVisibleChange = () => {
    const currentTime = moment(new Date());
    setStateData((prev)=>{
      const duration = moment.duration(currentTime.diff(prev?.prevLastActiveTime || prev?.lastActiveTime));
      const timeDifference = timeout - duration.asMilliseconds();
      if (timeDifference + remainingTimeAfterIdle <= 0) {
        handleLockScreen()
      }
      return {
        ...prev,
        prevLastActiveTime: prev?.lastActiveTime,
        lastActiveTime: getLastActiveTime(),
      }
    })
  }

  const updateLastActiveTime = () => {
    if (!document.hidden) {
      setStateData((prev) => {
        return {
          ...prev,
          lastActiveTime: getLastActiveTime(),
        };
      });
    }
  };

  useEffect(()=>{
    const updateLastActivity = setInterval(() => updateLastActiveTime(), intervalTime);
    document.addEventListener('visibilitychange',onVisibleChange)
    return () => {
      document.removeEventListener('visibilitychange',onVisibleChange);
      clearInterval(updateLastActivity);
    }
  },[])

  useEffect(() => {
    window.addEventListener('click',handleStillHere);
    window.addEventListener('keypress',handleStillHere);
    window.addEventListener('scroll',handleStillHere);
    window.addEventListener('mouseover',handleStillHere);

    return () => {
      window.removeEventListener('click',handleStillHere);
      window.removeEventListener('keypress',handleStillHere);
      window.removeEventListener('scroll',handleStillHere);
      window.removeEventListener('mouseover',handleStillHere);
    }
  },[])

  return <></>
};

