import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { Button, HStack, Icon, Pressable, Text, Tooltip, View, VStack } from 'native-base';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { default as Feather, default as FeatherIcon } from 'react-native-vector-icons/Feather';
import {
  CLAIMS_MEMBER_STATUS,
  DISPLAY_SLASH_DATE_FORMAT,
  GROUP_MEMBER_TYPE,
  IS_DEACTIVATE_CONTACT_ENABLED,
  PERSON_TYPES
} from '../../../constants';
import { COMMON_ACTION_CODES } from '../../../constants/ActionConst';
import { Colors } from '../../../styles';
import {
  getGenderTextByGenderCode,
  getPrimaryGenderCode,
  isAllowToShowExternalPatientId,
  isConsentRequiredAndGiven,
  numericStringMask
} from '../../../utils/commonUtils';
import { getAgeValue } from '../../../utils/DateUtils';
import { isWeb } from '../../../utils/platformCheckUtils';
import { TagDataListView } from '../../PersonOmniView/LeftContainer/OtherDetails/TagDataListView';
import { DisplayCardAvatar } from '../DisplayCard/DisplayCardAvatar';
import AppUsingPatientSvg from './../../common/Svg/SideMenu/AppUsingPatientSvg';
import { IMemberInfoListItem } from './interface';
import { styles } from './MemberInfoStyles';
import HintPlanDetailView from '../HintPlanDetailView/HintPlanDetailView';
import { IContact } from '../../RightSideContainer/TeamInbox/Conversations/interfaces';
import { getCurrentPatientDeceasedData} from '../../../services/CommonService/AidBoxService';
import { getEhrConfig } from '../../../utils/capabilityUtils';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { Popover } from 'antd';

const MemberInfoListItem = (props: IMemberInfoListItem) => {
  const {
    contactData,
    isOnHeader,
    hideAvatar: hideAvatar,
    showEmail,
    showPhoneNumber,
    isPatientSearchCard,
    onActionPerformed,
    showTagging,
    showTagInMultiline,
    textColor,
    showEmailActionIcon,
    showSmsActionIcon,
    showDateOfBirth,
    hideFoldStatus,
    showHintMembershipInfo,
    isSelected,
    metadataScore,
    contactNameMaxWidth,
    sendDateText,
    receivedDateText,
    hideFullNameTooltip,
    isSidecarTaskLabelSelect,
  } = props
  const[deceasedDate,setDeceasedDate] = useState('')
  const birthDate = contactData?.person?.birthDate
    ? moment(contactData?.person?.birthDate).format(DISPLAY_SLASH_DATE_FORMAT)
    : '';
  const membershipData: any = contactData?.contactMemberships?.[0] || {};
  const membershipStartDate = membershipData?.startDate || '';
  const membershipEndDate = membershipData?.endDate || '';
  const gender = getGenderTextByGenderCode(contactData);
  const genderProfileCode = getPrimaryGenderCode(contactData);
  const currentStatus = contactData?.isActive == false ? false : true;
  const contactType =
    contactData?.contactType?.contactType ?
      contactData.contactType.contactType.code === PERSON_TYPES.CUSTOMER
        ? 'Patient'
        : contactData.contactType.contactType.code === PERSON_TYPES.CUSTOMER ? 'Prospect'
          : contactData.contactType.contactType.value
      : 'Prospect';
  const mask = `(###) ###-####`;
  const phoneNumber = contactData?.phoneNumber;
  const consentCheck = isConsentRequiredAndGiven(contactData);
  const entryScoreToDisplay = metadataScore?.length ? metadataScore?.find((item) => item.groupId == null) || metadataScore[0] : null;
  const getPatientDeceasedData = async (contactData: IContact | undefined) => {
   const patientDeceasedDate = await getCurrentPatientDeceasedData(contactData || {});
    setDeceasedDate(patientDeceasedDate)
  }
  const ehrConfig = getEhrConfig(
    contactData?.contactPracticeLocations?.[0]?.accountLocation?.uuid,
    ''
  );
  const contextData = useContext(CommonDataContext);
  const userSettings = contextData.userSettings;
  const allowToShowExternalPatientId = isAllowToShowExternalPatientId(userSettings);
  useEffect(() => {
    ehrConfig.isAthena && getPatientDeceasedData(contactData);
  }, [contactData?.patient?.patientId]);

   const getPatientDemographics = (): JSX.Element => {
     const ageValue = contactData?.person?.birthDate
       ? getAgeValue(contactData?.person?.birthDate, deceasedDate, true)
       : '';
     const genderValue = getPrimaryGenderCode(contactData);
     const genderCode = genderValue?.charAt(0).toUpperCase() || '';
     const tooltipContent = birthDate;
     const displayText = genderCode 
       ? ` (${genderCode}, ${ageValue})`
       : ` (${ageValue})`;    
     return (
       <Popover
         style={{ borderRadius: 16 }}
         content={tooltipContent}
         placement="top"
         trigger="hover"
       >
         <Text color={Colors.Custom.Gray500}>{displayText}</Text>
       </Popover>
     );
   };

  const getPatientSubTitle = (): string => {
    let subTitle = '';
    const patientId = contactData?.patient?.patientId;
    if (allowToShowExternalPatientId && patientId) {
      subTitle += `#${patientId}`;
    }
    return subTitle;
  };

  return (
    <HStack
      key={contactData?.id}
      flex={1}
      flexDirection={'row'}
      style={{
        marginLeft: !isOnHeader && !isWeb() ? 16 : 0,
        height: !isWeb() && !isOnHeader ? 80 : 'auto',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <HStack flex={1} alignItems={props?.alignAvatar}>
        {!hideAvatar && (
          <View>
            <DisplayCardAvatar
              avatarStyle={{
                avatarSize: isSidecarTaskLabelSelect ? '3' : '12',
                width: (isSidecarTaskLabelSelect || isOnHeader) ? 32 : 48,
                height: (isSidecarTaskLabelSelect || isOnHeader) ? 32 : 48,
                ...(isSidecarTaskLabelSelect ?
                  {
                    textStyle: {
                      fontSize: 12
                    }
                  } : {}
                )
              }}
              isLetterAvatarShow
              userData={{
                userId: contactData?.id || '',
                userType: GROUP_MEMBER_TYPE.CONTACT,
                genderCode: genderProfileCode as any,
                contactType: contactData?.contactType?.contactType?.code || '',
                name: contactData?.person,
                imgSrc:
                  contactData?.contactProfilePhotos &&
                    contactData?.contactProfilePhotos.length
                    ? contactData?.contactProfilePhotos[0]?.profilePhotoUrl
                    : undefined,
                userName: contactData?.name || '',
              }}
              hideFullNameTooltip={hideFullNameTooltip}
            />
          </View>
        )}

        <VStack style={{ marginLeft: 8 }} flex={1}>
          <HStack>
            <View
              flexDirection={'row'}
              justifyContent={'center'}
              alignItems={'center'}
              marginLeft={2}
            >
              {!isPatientSearchCard ? (
                <HStack alignItems={'center'}>
                  <Tooltip
                    label={contactData?.name || ''}
                    placement="top"
                  >
                    <Pressable
                      onPress={() => {
                        onActionPerformed && onActionPerformed('RowClick', {
                          contactData,
                        });
                      }}
                    >
                      <Text
                        size={'smBold'}
                        color={textColor || 'coolGray.700'}
                        justifyContent={'center'}
                        style={contactNameMaxWidth ? { maxWidth: contactNameMaxWidth } : {}}
                        isTruncated={props.isNameTruncate ? true : false}
                      >
                        {contactData?.name ? contactData?.name : ' '}
                      </Text>
                    </Pressable>
                  </Tooltip>
                  {props?.isMemberNameColumn && getPatientDemographics()}
                </HStack>
              ) : (
                <HStack alignItems={'center'}>
                  <Text
                    style={contactNameMaxWidth ? { maxWidth: contactNameMaxWidth } : {}}
                    size={'smBold'}
                    color={textColor || 'coolGray.700'}
                    justifyContent={'center'}
                  >
                    {contactData?.name ? contactData?.name : ' '}
                  </Text>
                  {getPatientDemographics()}
                </HStack>
              )}

              {props.showDetailIcon && (
                <Icon
                  as={Feather}
                  size={4}
                  name="chevron-right"
                  color={
                    textColor ? Colors.Custom.Gray200 : Colors.Custom.Gray700
                  }
                  marginRight={4}
                />
              )}
              {contactType == 'Patient' && (
                <AppUsingPatientSvg
                  loggedInTime={contactData?.additionalAttributes?.loggedInTime}
                />
              )}
            </View>
          </HStack>
          {showEmail && (
            <View justifyContent={'center'} marginLeft={2}>
              <Text
                style={contactNameMaxWidth ? { maxWidth: contactNameMaxWidth } : {}}
                color={textColor || Colors.Custom.Gray700}
                size={'xsMedium'}
              >
                {contactData?.email ? contactData?.email : ''}
              </Text>
            </View>
          )}
          {showPhoneNumber && (
            <View justifyContent={'center'} marginLeft={2}>
              <Text
                color={textColor || Colors.Custom.Gray700}
                size={'xsMedium'}
              >
                {phoneNumber ? numericStringMask(phoneNumber, mask) : ''}
              </Text>
            </View>
          )}
          <HStack>
            <View justifyContent={'center'} marginLeft={2}>
              <Text
                color={
                  textColor ? Colors.Custom.Gray200 : Colors.Custom.Gray500
                }
                size={'xsMedium'}
              >
                {getPatientSubTitle()}
              </Text>
              {props.isFormResponse ? (
                <>
                  {props?.sendDate && (
                    <Text
                      color={
                        textColor
                          ? Colors.Custom.Gray200
                          : Colors.Custom.Gray500
                      }
                      size={'xsMedium'}
                    >
                      {`${sendDateText ? sendDateText : 'Sent'}:  ${props.sendDate || ''}`}
                    </Text>
                  )}
                  {props?.receviedDate && (
                    <Text
                      color={
                        textColor
                          ? Colors.Custom.Gray200
                          : Colors.Custom.Gray500
                      }
                      size={'xsMedium'}
                    >
                      {`${receivedDateText ? receivedDateText : 'Recieved'}:  ${props.receviedDate || ''}`}
                    </Text>
                  )}
                  {props.signature && (
                    <Text
                      color={
                        textColor
                          ? Colors.Custom.Gray200
                          : Colors.Custom.Gray500
                      }
                      size={'xsMedium'}
                    >
                      {props.signature || ''}
                    </Text>
                  )}
                </>
              ) : (
                <></>
              )}
              {metadataScore?.length ? <>
                <HStack>
                  <Text
                    color={
                      '#000000'
                    }
                    size={'xsMedium'}
                  >
                    {entryScoreToDisplay?.groupId ? 'Score group: ' : 'Form Score: '}
                  </Text>
                  <Text
                    color={
                      textColor ? Colors.Custom.Gray200 : Colors.Custom.Gray500
                    }
                    size={'xsMedium'}
                  >
                    {entryScoreToDisplay?.groupId ? entryScoreToDisplay?.metadata?.name : entryScoreToDisplay?.metadata?.score}
                  </Text>
                </HStack>
                <HStack>
                  <Text
                    color={
                      '#000000'
                    }
                    size={'xsMedium'}
                  >
                    Interpretation:
                  </Text>
                  <Text
                    color={
                      textColor ? Colors.Custom.Gray200 : Colors.Custom.Gray500
                    }
                    size={'xsMedium'}
                  >
                    {entryScoreToDisplay?.metadata?.state}
                  </Text>
                </HStack>
                {metadataScore?.length > 1 ? <Text
                  color={
                    isSelected ? Colors.Custom.Gray500 : Colors.Custom.mainPrimaryPurple
                  }
                  size={'xsMedium'}
                >
                  {`+${metadataScore?.length - 1} more`}
                </Text> : null}

              </> : null}

            </View>
          </HStack>
          {contactData?.claimsMemberStatus === CLAIMS_MEMBER_STATUS.NON_MEMBER && (
            <HStack>
              <View justifyContent={'center'} marginLeft={2}>
                <Text color={Colors.danger[500]} size={'xsMedium'}>
                  {`Not Member`}
                </Text>
              </View>
            </HStack>
          )}
          {
            contactData?.id && showHintMembershipInfo ?
              (
                <HStack marginLeft={2}>
                  <HintPlanDetailView
                    id={contactData?.id}
                    isEmployerReportDrillDown={true}
                    locationId={contactData?.contactPracticeLocations?.[0]?.accountLocation?.uuid}
                  />
                </HStack>
              )
              : <></>
          }
          {IS_DEACTIVATE_CONTACT_ENABLED && !hideFoldStatus && (
            <>
              {currentStatus ? (
                <></>
              ) : (
                <HStack>
                  <View justifyContent={'center'} marginLeft={2}>
                    <Text color={Colors.danger[500]} size={'xsMedium'}>
                      {`Inactive`}
                    </Text>
                  </View>
                </HStack>
              )}
            </>
          )}
          {!!props?.extaSubHeader && props?.extaSubHeader}
          {showTagging && (
            <>
              {/* {showTagsInPopover ? ( */}
              <TagDataListView
                taggableType={'Contact'}
                style={{ marginBottom: 10 }}
                hideAddMoreButton={true}
                tagList={contactData?.contactTagging}
                popOverConfig={{
                  placement: 'rightTop',
                  trigger: 'hover',
                }}
              />
              {/* ) : ( // KEPT FOR FUTURE REFERENCE
                <TaggingView
                  tagData={contactData?.contactTagging || []}
                  showTagInMultiline={showTagInMultiline}
                /> */}
              {/* )} */}
            </>
          )}
        </VStack>
      </HStack>
      <HStack>
        {showEmailActionIcon && contactData?.email ? (
          <Tooltip
            label={
              !contactData?.email ? 'Please Add Email Address' : 'Send Email'
            }
            placement="top"
          >
            <Button
              _hover={{ bg: Colors.Custom.HoverBgColor }}
              onPress={() => {
                if (consentCheck) {
                  if (!contactData?.email) {
                    props.onActionClick('EDIT', contactData);
                  } else {
                    props.onActionClick('MAIL', contactData);
                  }
                } else {
                  props.onActionClick(
                    'EDIT',
                    COMMON_ACTION_CODES.CONSENT_WARNING
                  );
                }
              }}
              // disabled={isEmailBtnDisabled()}
              leftIcon={
                <AntIcon
                  name="mail"
                  style={[
                    styles.icons,
                    // emailStyleIcon()
                  ]}
                />
              }
              style={styles.btn}
            />
          </Tooltip>
        ) : null}
        {/* <Button
        onPress={() => {
          props.onActionClick('DELETE');
        }}
        leftIcon={<Icon name="delete" size={17} style={[styles.icons]} />}
        style={styles.btn}
      /> */}

        {/* <Tooltip label="Edit" placement="top">
        <Button
          onPress={() => {
            props.onActionClick('EDIT');
          }}
          leftIcon={<Feather name="edit-2" size={17} style={[styles.icons]} />}
          style={styles.btn}
        />
      </Tooltip> */}

        {showSmsActionIcon && contactData?.phoneNumber ? (
          <Tooltip
            label={!contactData?.phoneNumber ? 'Please Add Number' : 'Send SMS'}
            placement="top"
          >
            <Button
              onPress={() => {
                if (consentCheck) {
                  if (!contactData?.phoneNumber) {
                    props.onActionClick('EDIT', contactData);
                  } else {
                    props.onActionClick('SMS', contactData);
                  }
                } else {
                  props.onActionClick(
                    'EDIT',
                    COMMON_ACTION_CODES.CONSENT_WARNING
                  );
                }
              }}
              // disabled={isNumberBtnDisabled()}
              leftIcon={
                <Feather
                  name="message-circle"
                  size={17}
                  style={[styles.icons]}
                />
              }
              style={styles.btn}
            />
          </Tooltip>
        ) : null}
      </HStack>

      {
        !isWeb() && !isOnHeader && (
          <VStack justifyContent={'center'}>
            <Icon
              as={Feather}
              size={4}
              name="chevron-right"
              color={Colors.Custom.Gray400}
              marginRight={4}
            />
          </VStack>
        )
      }
      {
        props?.showRedirectIcon &&
        <Pressable
          onPress={() => {
            props?.onRedirectToProfile?.();
          }}
        >
          <Icon
            as={FeatherIcon}
            name="external-link"
            size="sm"
            color={Colors.Custom.Gray500}
          />
        </Pressable>
      }
    </HStack >
  );
};

export default MemberInfoListItem;
