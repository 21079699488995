import {Formio} from '@foldhealth/formiojs';
import BasicFields from '../../EditFormFields/BasicFields';
import ConditionalComponents from '../../EditFormFields/ConditionalFields';
import KeyField from '../../EditFormFields/KeyField';
import QuillConfig from '../../Builder/QuillConfig';
import ShareWithPatientFields from '../../EditFormFields/ShareWithPatientFields';

const SelectComponent = Formio.Components.components.select;

export default class NoteSubStatusComponent extends SelectComponent {
  static schema(...extend: any) {
    return SelectComponent.schema(
      {
        type: 'noteSubStatus',
        label: 'Sub Status',
        key: 'noteSubStatus',
        dataSrc: 'values',
          clearOnHide: false,
          data: {
            values: [
              {label: 'Prior Authorization Follow Up', value: 'Prior Authorization Follow Up'},
              {label: 'EMO Requested', value: 'EMO Requested'},
              {label: 'Coordinator', value: 'Coordinator'},
              {label: 'Nurse Reassignment', value: 'Nurse Reassignment'},
              {label: 'Transition of Care', value: 'Transition of Care'},
              {label: 'EMO Review', value: 'EMO Review'},
              {label: '1st Member Outreach', value: '1st Member Outreach'},
              {label: '2nd Member Outreach', value: '2nd Member Outreach'},
              {label: '3rd Member Outreach', value: '3rd Member Outreach'},
              {label: 'Medical Records Request', value: 'Medical Records Request'},
              {label: 'Infusion Setup', value: 'Infusion Setup'},
              {label: 'Risk Surveillance', value: 'Risk Surveillance'},
              {label: 'DPC - Med Records Request', value: 'DPC - Med Records Request'},
              {label: 'Awaiting Physician Call Back', value: 'Awaiting Physician Call Back'},
              {label: 'Awaiting Member Call Back', value: 'Awaiting Member Call Back'},
              {label: 'SCA Creation', value: 'SCA Creation'},
              {label: 'Regenerative Medicine', value: 'Regenerative Medicine'},
              {label: 'Ventegra - TruDate Outreach', value: 'Ventegra - TruDate Outreach'},
              {label: 'Missed Opportunity', value: 'Missed Opportunity'},
              {label: 'Transition of Care', value: 'Transition of Care'},
              {label: 'Proactive', value: 'Proactive'},
              {label: 'Surgery Outcome', value: 'Surgery Outcome'},
              {label: 'Appt Outcome', value: 'Appt Outcome'}
            ]
          },
      },
      ...extend
    );
  }

  static get builderInfo() {
    return {
      title: 'Sub Status',
      group: 'advanced',
      icon: 'calendar',
      weight: 2,
      documentation: '/userguide/#select',
      schema: NoteSubStatusComponent.schema(),
    };
  }

  static editForm = function () {
    return {
      key: 'display',
      components: [
        {
          type: 'oldtextfield',
          key: 'label',
          label: 'Label',
          input: true,
          validate: {
            required: true,
          },
        },
        ...ShareWithPatientFields,
        {
          type: 'textarea',
          input: true,
          key: 'description',
          label: 'Description',
          placeholder: 'Description for this field.',
          tooltip:
            'The description is text that will appear below the input field.',
          editor: 'quill',
          wysiwyg: QuillConfig,
        },
        ...ConditionalComponents,
        ...KeyField,
      ],
    };
  }
}

Formio.Components.addComponent('noteSubStatus', NoteSubStatusComponent);
