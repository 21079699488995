// import { useState } from 'react';
import FlowComponent from '../../../FlowComponent';
import { IWorkflowRender } from './../IWorkflow';

const WorkflowRender = (props: IWorkflowRender) => {
  
  return (
    <FlowComponent
      setWorkflowContext={props.setWorkflowContext}
      key={props.workflowMasterId}
      allowedFlowTypeList={props.allowedFlowTypeList}
      setAllowedFlowTypeList={props.setAllowedFlowTypeList}
      isViewOnly={props.isViewOnly}
      workflowMasterId={props.workflowMasterId}
      showGoalReviewModal={props.showGoalReviewModal}
      getTriggerElement={props.getTriggerElement}
      workflowGoalAndReview={props.workflowGoalAndReview}
      flowType={props.flowType}
      isWorkFlowEditable={props.isWorkFlowEditable}
      triggerCondition={props.triggerCondition}
      libNodeIdList={props.libNodeIdList}
      setNodes={(nodes: any, isEdit: boolean) => {
        if (isEdit) {
          props.setNodes(JSON.parse(JSON.stringify(nodes)));
        } else {
          props.setNodes(nodes);
        }
      }}
      setEdges={props.setEdges}
      onNodeDataChange={props.onNodeDataChange}
      nodes={props.nodes}
      edges={props.edges}
    />
  );
};
export default WorkflowRender;
