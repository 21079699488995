import React from 'react';
import Svg, {ClipPath, Defs, G, Path} from 'react-native-svg';

const SchedulingSpecialistSvg = () => {
  return (
    <Svg width="16" height="16" fill="none" viewBox="0 0 16 16">
      <G clipPath="url(#clip0_1416_5046)">
        <Path
          fill="#6F7A90"
          d="M4.167 2.359a.5.5 0 101 0h-1zm1-1.026a.5.5 0 00-1 0h1zm5.666 1.026a.5.5 0 101 0h-1zm1-1.026a.5.5 0 00-1 0h1zM1.667 5.278a.5.5 0 100 1v-1zm12.666 1a.5.5 0 000-1v1zM6.667 2.859h2.666v-1H6.667v1zm7.5 4.97v1.368h1V7.829h-1zm-4.834 6.338H6.667v1h2.666v-1zm-7.5-4.97V7.829h-1v1.368h1zm4.834 4.97c-1.272 0-2.174-.001-2.858-.096-.669-.092-1.054-.264-1.337-.554l-.716.698c.499.51 1.132.739 1.916.847.768.106 1.752.105 2.995.105v-1zM.833 9.197c0 1.275 0 2.28.102 3.066.105.798.325 1.442.821 1.952l.716-.698c-.284-.292-.455-.693-.545-1.384-.093-.705-.094-1.633-.094-2.936h-1zm13.334 0c0 1.303-.001 2.231-.094 2.936-.09.691-.261 1.092-.546 1.384l.716.698c.497-.51.717-1.154.822-1.952.103-.785.102-1.79.102-3.066h-1zm-4.834 5.97c1.243 0 2.226 0 2.995-.105.784-.108 1.417-.336 1.915-.847l-.716-.698c-.282.29-.668.462-1.336.554-.684.095-1.586.096-2.858.096v1zm0-12.308c1.272 0 2.174.001 2.858.095.668.093 1.054.265 1.336.555l.716-.698c-.498-.511-1.131-.74-1.915-.847-.769-.106-1.752-.105-2.995-.105v1zm5.834 4.97c0-1.275 0-2.281-.102-3.066-.105-.798-.325-1.443-.822-1.952l-.716.698c.285.292.456.692.546 1.384.093.705.094 1.633.094 2.936h1zm-8.5-5.97c-1.243 0-2.227-.001-2.995.105-.784.108-1.417.336-1.916.847l.716.698c.283-.29.668-.462 1.337-.555.684-.094 1.586-.095 2.858-.095v-1zm-4.834 5.97c0-1.303.001-2.231.094-2.936.09-.692.26-1.092.545-1.384l-.716-.698c-.496.51-.716 1.154-.82 1.952-.104.785-.103 1.79-.103 3.066h1zm3.334-5.47V1.333h-1V2.36h1zm6.666 0V1.333h-1V2.36h1zm-.333 8.547a.513.513 0 01-.5.526v1c.84 0 1.5-.695 1.5-1.526h-1zm-.5.526a.513.513 0 01-.5-.526h-1c0 .83.66 1.526 1.5 1.526v-1zm-.5-.526c0-.302.236-.526.5-.526v-1c-.84 0-1.5.695-1.5 1.526h1zm.5-.526c.264 0 .5.224.5.526h1c0-.83-.66-1.526-1.5-1.526v1zM1.667 6.278h12.666v-1H1.667v1z"
        ></Path>
      </G>
      <Defs>
        <ClipPath id="clip0_1416_5046">
          <Path fill="#fff" d="M0 0H16V16H0z"></Path>
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export default SchedulingSpecialistSvg;
