import {Icon, Input, View} from 'native-base';
import React, {useEffect, useState} from 'react';
import {Platform, TouchableOpacity} from 'react-native';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {Colors} from '../../../styles';
import {testID, TestIdentifiers} from '../../../testUtils';
import {isWeb} from '../../../utils/platformCheckUtils';
import {ISearchBarProps} from './interface';
import {styles, stylesObj} from './SearchBarStyles';
import { InputStyle } from '../../RightSideContainer/Contacts/Leads/LeadView/LeadViewStyle';
import DialpadSvg from '../Svg/DialpadSvg';
import SelectPatientCallDrawer from '../../RightSideContainer/CloudTelephony/ActiveNumbersTable/SelectPatientCallDrawer';
import { COMMON_ACTION_CODES } from '../../../constants/ActionConst';
import { IContact } from '../CareDashboard/CareDashboardInterfaces';
import { EventBus } from '../../../utils/EventBus';
import {IExtensionData} from '../../RightSideContainer/CallConfiguration/interface';


export const SearchBar = (props: ISearchBarProps) => {
  const {
    onChange,
    placeholderText,
    height,
    flex,
    borderRadius,
    borderWidth,
    borderColor,
    minWidth,
    isDisable,
    variant,
    iconColor,
    placeholderTextColor,
    searchText,
    width,
    isNotDebounce,
    selectedTab,
    isDialPadVisible,
    onDialPadClick,
    leftElement,
    minHeightWrapper,
    nativeId
  } = props;
  const placeholderStr = placeholderText || 'Search';
  const searchBarInputStyles = props.searchBarInputStyles || {};
  const searchInputStyleFinal: any = {
    ...stylesObj.searchInputStyle,
    ...searchBarInputStyles,
  };

  let finalPlaceholderTextColor = InputStyle.placeholderTextColor;
  if (placeholderTextColor) {
    finalPlaceholderTextColor = placeholderTextColor;
  }
  let finalIconColor = Colors.Custom.Gray600;
  if (iconColor) {
    finalIconColor = iconColor;
  }

  let searchBarBorderRadius = isWeb() ? 5 : 50;
  if (borderRadius || borderRadius === 0) {
    searchBarBorderRadius = borderRadius;
  }
  let searchBarBorderColor = Colors.Custom.CardBorderColor;
  if (borderColor) {
    searchBarBorderColor = borderColor;
  }
  let searchBarBorderWidth = 1;
  if (borderWidth || borderWidth === 0) {
    searchBarBorderWidth = borderWidth;
  }
  const [searchString, setSearchString] = useState('');
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [isDialpadOn, setIsDialpadOn] =useState<boolean>(false)

  useEffect(() => {
    setSearchString(searchText || '');
  }, [searchText,selectedTab]);

  const onChangeText = (text: string) => {
    if (isWeb() && !isNotDebounce) {
      setSearchString(text);
      const delayDebounceFn = setTimeout(() => {
        onChange(text);
      }, 100);

      return () => clearTimeout(delayDebounceFn);
    } else {
      setSearchString(text);
      onChange(text);
    }
  };

  const handleCloudCall = (
    actionCode: string,
    contactData: IContact,
    fromNumber: string,
    extensionData: IExtensionData,
  ) => {
    if (actionCode === COMMON_ACTION_CODES.CLOUD_CALL) {
      const eventBus = EventBus.getEventBusInstance();
      eventBus.broadcastEvent('CLOUD_CALL', {
        contactData: contactData,
        fromNumber: fromNumber,
        extensionData: extensionData
      });
    }
  };

  const onDialpadPress =()=>{
      if(isWeb())
      {
        setIsDrawerVisible(true);
      }
      else{
        onDialPadClick?.();
      }
  }



  return (
    <View
      style={[
        {
          backgroundColor: searchInputStyleFinal?.backgroundColor
            ? searchInputStyleFinal?.backgroundColor
            : 'light.100',
        },
        styles.searchBarContainer,
        {
          minHeight: minHeightWrapper ? minHeightWrapper : 40,
          width: width,
          borderRadius: isWeb() ? searchBarBorderRadius : searchBarBorderRadius,
          borderWidth: isWeb() ? 0 : searchBarBorderWidth,
          borderColor: searchBarBorderColor,
        },
        flex ? {flex} : {},
      ]}
      marginTop={
        searchInputStyleFinal?.marginTop ? searchInputStyleFinal?.marginTop : 0
      }
      marginBottom={
        searchInputStyleFinal?.marginBottom
          ? searchInputStyleFinal?.marginBottom
          : 0
      }
      marginLeft={
        searchInputStyleFinal?.marginLeft
          ? searchInputStyleFinal?.marginLeft
          : 0
      }
      marginRight={
        searchInputStyleFinal?.marginRight
          ? searchInputStyleFinal?.marginRight
          : 0
      }
      testID={nativeId ? 'tableTopSearchBarStyle' : 'tableTopSearchBar'}
      nativeID={nativeId ? 'tableTopSearchBarStyle' : 'tableTopSearchBar'}
    >
      <Input
        paddingRight={15}
        paddingLeft="0"
        width={'100%'}
        height={height ? height : 'auto'}
        autoCorrect={false}
        borderWidth={isWeb() ? searchBarBorderWidth : 0}
        borderColor={borderColor}
        minWidth={minWidth}
        value={searchString || ''}
        defaultValue={searchString ? searchString : ''}
        onChangeText={onChangeText}
        variant={variant || ''}
        fontSize={searchInputStyleFinal.fontSize || 14}
        placeholder={placeholderStr}
        size={Platform.OS === 'web' ? 'mdNormal' : 'md'}
        py={searchBarInputStyles?.paddingVertical || 3}
        placeholderTextColor={finalPlaceholderTextColor || 'gray.300'}
        borderRadius={searchBarBorderRadius}
        autoComplete="off"
        backgroundColor={
          searchInputStyleFinal?.backgroundColor
            ? searchInputStyleFinal?.backgroundColor
            : "light.100"
        }
        style={[searchInputStyleFinal || {}]}
        InputLeftElement={
          leftElement ? (
            leftElement
          ) : (
            <Icon
              m="3"
              size={isWeb() ? '4' : '6'}
              color={finalPlaceholderTextColor || 'gray.400'}
              as={<AntIcon name="search1" />}
              {...testID('image')}
            />
          )
        }
        InputRightElement={
          isDialPadVisible ? (
            <TouchableOpacity
              onPress={onDialpadPress}
              style={{marginRight: isWeb() ? 0 : 10}}
              {...testID('DialPadIcon')}
            >
              <DialpadSvg
                customStrokeColor={Colors.Custom.Gray500}
              />
            </TouchableOpacity>
          ) : (
            props?.rightElement
          )
        }
        _focus={{
          borderColor: borderColor || Colors.Custom.Gray200,
        }}
        isDisabled={isDisable}
        {...testID(TestIdentifiers.searchBar)}
      />
      {isDrawerVisible && isWeb() && (
        <SelectPatientCallDrawer
          isDrawerVisible={isDrawerVisible}
          selectedRecord={null}
          handleCloudCall={handleCloudCall}
          onCreateCallConversationActionPerformed={() => {
            setIsDrawerVisible(false);
          }}
        />
      )}
    </View>
  );
};

export default SearchBar;
