import {Tooltip} from 'antd';
import {testID} from '../../../../../../testUtils';
import CreateTaskSvg from '../../../../../common/Svg/CreateTaskSvg';
import {Colors} from '../../../../../../styles';
import './MessagingWindowHeaderComponentsStyles.css';
import {FHTooltip} from '../../../../../common/FHTooltip';

const CreateTaskView = ({
  isMessageCopy,
  onCreateTaskBtnPress,
}: {
  isMessageCopy: boolean;
  onCreateTaskBtnPress: () => void;
}) => {
  return (
    <FHTooltip text={'Create Task'} position="bottom">
      <div
        onClick={onCreateTaskBtnPress}
        {...testID('CreateTaskClick')}
        className={`pressable ${!isMessageCopy ? 'disabled' : ''}`}
      >
        <div className="vstack">
          <CreateTaskSvg
            height={18}
            width={18}
            customStrokeColor={
              isMessageCopy ? Colors.FoldPixel.GRAY300 : Colors.Custom.Gray300
            }
          />
        </div>
      </div>
    </FHTooltip>
  );
};
export default CreateTaskView;
