import { IFamilyHistory } from '../../RightSideContainer/Forms/FHFormio/CustomComponents/FamilyHistory/interfaces';
import {IRecordList} from './PatientIntakeConfirm';

export const getAcceptedAndIgnoredRecordNames = (recordList: IRecordList[]) => {
  const acceptRecord: string[] = [];
  const ignoredRecords: string[] = [];
  recordList.map((record) => {
    if (record.isFreeTextRecord) {
      ignoredRecords.push(record.name);
    } else {
      acceptRecord.push(record.name);
    }
  });
  return {
    acceptRecord,
    ignoredRecords,
  };
};


export const getAcceptedAndIgnoredRecordNamesForFamilyHistory = (
  records: {
    relation: string;
    list: IFamilyHistory[];
  }[]
) => {
  const acceptRecord: {
    relation: string;
    list: string[];
  }[] = [];
  const ignoredRecords: {
    relation: string;
    list: string[];
  }[] = [];

  records.forEach((record) => {
    const structuredRecordList = record.list.filter(
      (item) => !item.isFreeTextRecord
    );
    const unStructuredRecordList = record.list.filter(
      (item) => item.isFreeTextRecord
    );

    if (structuredRecordList.length) {
      acceptRecord.push({
        relation: record.relation,
        list: structuredRecordList.map(item => item?.condition?.text),
      });
    }
    if (unStructuredRecordList.length) {
      ignoredRecords.push({
        relation: record.relation,
        list: unStructuredRecordList.map(item => item?.condition?.text),
      });
    }
  });
  return {acceptRecord,ignoredRecords}
};