import React from 'react';

const DeleteSvg = (props: {
  isActive?: boolean;
  customActiveColor?: string;
  customColor?: string;
}) => {
  const strokeColor = props.isActive
    ? props.customActiveColor
      ? props.customActiveColor
      : ''
    : props.customColor
    ? props.customColor
    : '#D94D55';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M2.5 5h1.667m0 0H17.5M4.167 5v11.667a1.667 1.667 0 001.666 1.667h8.334a1.667 1.667 0 001.666-1.667V5H4.167zm2.5 0V3.334a1.667 1.667 0 011.666-1.667h3.334a1.667 1.667 0 011.666 1.667V5m-5 4.167v5m3.334-5v5"
      ></path>
    </svg>
  );
};

export default DeleteSvg;
