import {Drawer} from 'antd';
import {HStack, Text} from 'native-base';
import {useState} from 'react';
import {useIntl} from 'react-intl';
import {BUTTON_TYPE} from '../../../../../constants';
import {Colors} from '../../../../../styles';
import ModalActionBtn from '../../../../common/ModalActionBtn/ModalActionBtn';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import {FoldButton} from '../../../../CommonComponents/FoldButton/FoldButton';
import {StyleSheet} from 'react-native';

const ConfirmationModal = (props: {
  isOpen: boolean;
  isDelete: boolean;

  onCancel: () => void;
  onConfirm: () => void;
}) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const onUserConfirm = () => {
    setLoading(true);
    props.onConfirm();
    setLoading(false);
  };
  const buttonList = [
    {
      show: true,
      id: 1,
      btnText: intl.formatMessage({
        id: 'cancel',
      }),
      isLoading: false,

      textColor: Colors.Custom.mainSecondaryBrown,
      variant: BUTTON_TYPE.SECONDARY,
      isTransBtn: false,
      onClick: () => {
        props.onCancel();
      },
    },
    {
      show: true,
      isLoading: loading,
      id: 2,
      btnText: props.isDelete
        ? intl.formatMessage({id: 'delete'})
        : intl.formatMessage({id: 'save'}),
      textColor: Colors.Custom.mainPrimaryPurple,
      variant: BUTTON_TYPE.PRIMARY,
      isTransBtn: false,

      onClick: () => {
        onUserConfirm();
      },
    },
  ];
  return (
    <Drawer visible={props.isOpen} closable width={600}
    title={<ModalActionTitle
      title={props.isDelete ? 'Delete Care Team Member' : 'Save Care Team'}
    />}
    >
      <HStack>
        <Text style={styles.text}>
          Are you sure you want to delete this care team member?
        </Text>
      </HStack>
      <HStack marginTop={4} justifyContent="flex-end">
        {buttonList.map((button) => (
          <FoldButton
            key={button.id}
            nativeProps={{
              variant: button.variant,
              onPress: () => {
                button.onClick();
              },
              style: {marginHorizontal: 5},
            }}
            customProps={{
              btnText: intl.formatMessage({id: button.btnText}),
              withRightBorder: false,
            }}
          ></FoldButton>
        ))}
      </HStack>
    </Drawer>
  );
};

const styles = StyleSheet.create({
  text: {
    fontSize: 16,
  }
});

export default ConfirmationModal;
