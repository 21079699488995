// Get the DateTime from the components listing.
import {Formio} from '@foldhealth/formiojs';
import QuillConfig from '../../Builder/QuillConfig';
import ConditionalFields from '../../EditFormFields/ConditionalFields';
import KeyField from '../../EditFormFields/KeyField';
import SearchableComponentFields from '../../EditFormFields/SearchableComponentFields';
import ShareWithPatientFields from '../../EditFormFields/ShareWithPatientFields';

const HTMLComponent = Formio.Components.components.htmlelement;

export default class ParagraphComponent extends HTMLComponent {
  static schema(...extend: any) {
    return HTMLComponent.schema(
      {
        type: 'paragraph',
        label: 'Paragraph',
        key: 'paragraph',
        content: 'Paragraph',
      },
      ...extend
    );
  }

  static get builderInfo() {
    return {
      title: 'Paragraph',
      group: 'advanced',
      icon: 'paragraph',
      weight: 2,
      documentation: '/userguide/#datetime',
      schema: ParagraphComponent.schema(),
    };
  }
}

ParagraphComponent.editForm = function () {
  return {
    key: 'display',
    components: [
      {
        type: 'textarea',
        input: true,
        key: 'content',
        label: 'Content',
        placeholder: 'Content to be displayed',
        tooltip:
          'The content is a readonly data like header, title or instructions',
        editor: 'quill',
        isUploadEnabled: true,
        uploadStorage: 'base64',
        wysiwyg: QuillConfig,
      },
      ...SearchableComponentFields,
      ...ConditionalFields,
      ...KeyField,
      {
        type: 'oldtextfield',
        key: 'label',
        label: 'Label',
        input: true,
        hidden: true,
        clearOnHide: false,
      },
      ...ShareWithPatientFields,
    ],
  };
};

Formio.Components.addComponent('paragraph', ParagraphComponent);
