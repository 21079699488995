import { Collapse } from "antd";
import { HStack, Skeleton, Text, View, VStack } from "native-base";
import { useEffect, useState } from "react";
import { getEmailStatusByTags } from "../../AddOrUpdateWorkflow/WorkflowApi";
const { Panel } = Collapse;
const WorkflowExecutionLevelInfoByTag = (props: { subject?:string,isHideTotal?: boolean, marginLeft: number, totalSms?: number, totalPushNotification?: number, totalEmail?: number, tag?: string }): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  const [uniqueClicked, setUniqueClicked] = useState(0)
  const [uniqueOpened, setUniqueOpened] = useState(0)
  useEffect(() => {
    loadWorkflowExecutionData(false)
  }, [props.tag])

  const loadWorkflowExecutionData = async (isReset: boolean) => {

    if (props?.tag) {
      setIsLoading(true)
      const response = await getEmailStatusByTags(props?.tag, 'communicationLogResponse');
      setUniqueClicked(response.clicked);
      setUniqueOpened(response.opened);
      setIsLoading(false)
    }
  };
  return isLoading && props.tag ? <View flex={1}><Skeleton.Text lines={1}></Skeleton.Text></View> : <HStack flex={1} >
    {props.tag &&<VStack  flex={1}>
      <HStack>
        {props.tag && <View marginLeft={props.marginLeft || 0}>Opened:{uniqueOpened}</View>}
        {props.tag && <View marginLeft={5}>Clicked:{uniqueClicked}</View>}
      </HStack>
      <HStack>
        {props.tag && props.subject && <View marginLeft={props.marginLeft || 0}><Text flexWrap={'wrap'} numberOfLines={4} isTruncated={true} maxWidth={'80%'}> {'Subject: ' +  props.subject}</Text></View>}
      </HStack>

    </VStack> }
    {(!props.isHideTotal && props?.totalEmail) ? <View marginLeft={5}>Total Email:{props?.totalEmail || 0}</View> : <></>}
    {(!props.isHideTotal && props?.totalPushNotification) ? <View marginLeft={5}>Total Push Notification:{props?.totalPushNotification || 0}</View> : <></>}
    {(!props.isHideTotal && props?.totalSms) ? <View marginLeft={5}>Total SMS:{props?.totalSms || 0}</View> : <></>}
  </HStack>
}

export default WorkflowExecutionLevelInfoByTag;