import {StyleSheet} from 'react-native';
import {Colors} from '../../../styles/Colors';
import {isWeb} from '../../../utils/platformCheckUtils';
import {CHANNEL_TYPE_CODE} from '../../RightSideContainer/TeamInbox/Conversations/ConversationConst';
export const styles = StyleSheet.create({
  typingBox: {
     boxShadow: '0 0.8rem 1.6rem rgb(50 50 93 / 8%), 0 0.4rem 1.2rem rgb(0 0 0 / 7%)',
     borderRadius: 1000,
     backgroundColor: Colors.Custom.FontColorWhite,
     paddingTop: 8,
     paddingBottom: 8,
     paddingLeft: 20,
     paddingRight: 20,
     position: 'absolute',
     top: -65,
     contentContainer: {
      flex: 1
  },
  }
});

export const mentionStyles = StyleSheet.create({
  typingBox: {
     boxShadow: '0 0.8rem 1.6rem rgb(50 50 93 / 8%), 0 0.4rem 1.2rem rgb(0 0 0 / 7%)',
     borderRadius: 1000,
     backgroundColor: Colors.Custom.FontColorWhite,
     paddingTop: 8,
     paddingBottom: 8,
     paddingLeft: 20,
     paddingRight: 20,
     position: 'absolute',
     top: -105,
     contentContainer: {
      flex: 1
  },
  }
});

export const mentionWithNewMessageStyles = StyleSheet.create({
  typingBox: {
     boxShadow: '0 0.8rem 1.6rem rgb(50 50 93 / 8%), 0 0.4rem 1.2rem rgb(0 0 0 / 7%)',
     borderRadius: 1000,
     backgroundColor: Colors.Custom.FontColorWhite,
     paddingTop: 8,
     paddingBottom: 8,
     paddingLeft: 20,
     paddingRight: 20,
     position: 'absolute',
     top: -115,
     contentContainer: {
      flex: 1
  },
  }
});

export const getStyleForTypingBox = (selectInboxTypeCode: string, isNewMessageShown?: boolean) => {
  if (isWeb() && selectInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION) {
    if (isNewMessageShown) {
      return mentionWithNewMessageStyles.typingBox;
    }
    return mentionStyles.typingBox;
  }
  return styles?.typingBox;
}
