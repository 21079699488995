import {Center, Image, View} from 'native-base';
import React from 'react';

const SendMesgSvgNative = () => {
  return (
    <View>
      <Center>
        <Image
          width={5}
          height={5}
          source={require('../../../assets/images/send.png')}
          alt='image'
        />
      </Center>
    </View>
  );
};

export default SendMesgSvgNative;
