import {Select} from 'antd';
import {Text, View, VStack} from 'native-base';
import { useState } from 'react';
import { Colors } from '../../../../../../styles';
import {Answer, IMultiSelectProps} from '../interfaces';
import {styles} from './QuestionnaireComponentStyles';

const MultiSelect = (props: IMultiSelectProps) => {
  const {title, answerOptions, required} = props;

  const getSelectedAnswers = (answer: Answer[]) => {
    const answers: string[] = [];
    answer.forEach((item) => {
      if (item.display) {
        answers.push(item.display);
      }
    });
    return answers;
  };

  const [answer, setAnswer] = useState<string[]>(getSelectedAnswers(props.answer || []));

  return (
    <VStack style={styles.container}>
      <View style={styles.titleView}>
        <Text style={styles.titleText}>{title} {required && <Text color={Colors.error[500]}>*</Text>}</Text>
      </View>

      <View>
        <Select
          defaultValue={answer}
          disabled={props.readonly}
          mode="multiple"
          status={required && !answer.length ?  'error' : ''}
          onChange={(selectedAnswers) => {
            const resultArray: any[] = [];
            selectedAnswers.forEach((element: any) => {
              if (answerOptions.some((answer) => answer.display === element)) {
                const answer = answerOptions.find(
                  (item) => item.display === element
                );
                resultArray.push(answer);
              }
            });
            setAnswer(resultArray);
            props.onItemSelect(resultArray);
          }}
        >
          {answerOptions.map((item) => {
            return (
              <Select.Option
                key={`${item.code}_${item.display}`}
                value={item.display}
              >
                {item.display}
              </Select.Option>
            );
          })}
        </Select>
      </View>
    </VStack>
  );
};

export default MultiSelect;
