import {Drawer, Select} from 'antd';
import React, {useEffect, useState} from 'react';
import {ModalActionTitle} from '../../../common/ModalActionTitle/ModalActionTitle';
import Stack from '../../../common/LayoutComponents/Stack';
import {Button, Skeleton, Spacer, useMediaQuery, useToast} from 'native-base';
import {DisplayText} from '../../../common/DisplayText/DisplayText';
import {IForm} from '../../Forms/interfaces';
import {IPAD_MINI_WIDTH, IPAD_WIDTH} from '../../../../constants';
import {ContentTypes} from '../../ContentManagement/ContentManagementConsts';
import {
  getAccountMergeTagData,
  getTemplateCategories,
  getTemplateCategoryList,
  getTemplates,
} from '../../ContentManagement/ContentManagementUtils';
import {getFormattedEmailTemplateData} from '../../ContentManagement/EmailTemplates/EmailTemplatesUtils';
import parse from 'html-react-parser';
import {View} from 'react-native';
import {Liquid} from 'liquidjs';
import {ITemplateCategory} from '../../ContentManagement/EmailTemplates/interfaces';
import {getAccountUUID, getTpaFormURL} from '../../../../utils/commonUtils';
import {useLazyQuery} from '@apollo/client';
import {FormsQueries} from '../../../../services';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../constants/Configs';
import {sendEmail} from '../../../common/EmailPopupView/EmailPopupViewUtils';
import {GENERATE_WIDGET_TOKEN} from '../../../../services/Forms/FormsQueries';
import {ToastType, showPopupNotification, showToast} from '../../../../utils/commonViewUtils';
import {useIntl} from 'react-intl';
import {WIDGET_TYPES} from '../../Forms/FormsConstants';
import {Colors} from '../../../../styles';

interface IProvideRequestFormDrawer {
  isVisible: boolean;
  assignmentData: {
    contactId: string;
    contactEmail: string;
  };
  onActionComplete: () => void;
  onClose: () => void;
}

interface IComponentState {
  showError: boolean;
  loading: boolean;
  selectedForm?: IForm;
  emailTemplateId?: string;
  //html?: string;
  templateData?: any;
  forms: IForm[];
  templateCategories: ITemplateCategory[];
  submitApiLoading: boolean;
}

export function SendProviderRequestFormDrawer(
  props: IProvideRequestFormDrawer
) {
  const {isVisible, onClose, assignmentData} = props;
  const toast = useToast();
  const intl = useIntl();

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const accountId = getAccountUUID();

  const [formattedTemplates, setFormattedTemplates] = useState<any>([]);

  const isSmallScreen = isIPadMiniScreen || isIPadScreen;

  const drawerWidth = isSmallScreen ? '70%' : '35%';

  const defaultValue: IComponentState = {
    showError: false,
    loading: false,
    forms: [],
    templateCategories: [],
    submitApiLoading: false,
  };

  const accountMergeTags = getAccountMergeTagData();

  const [componentState, setComponentState] =
    useState<IComponentState>(defaultValue);

  useEffect(() => {
    setComponentState((prev) => ({
      ...prev,
      loading: true,
    }));
    getTemplatesAndForms();
    getTemplateCategoriesData();
  }, []);

  const [generateFormToken] = useLazyQuery(GENERATE_WIDGET_TOKEN, {
    fetchPolicy: 'no-cache',
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
    onCompleted: (response: any) => {
      if (response && response.forms) {
        setComponentState((prev) => ({
          ...prev,
          forms: response.forms,
          loading: false,
        }));
      } else {
        setComponentState((prev) => ({
          ...prev,
          forms: [],
          loading: false,
        }));
      }
    },
    onError: (error) => {
      setComponentState((prev) => ({
        ...prev,
      }));
    },
  });

  const [getFormsByCategory] = useLazyQuery(
    FormsQueries.GET_FORMS_BY_CATEGORY,
    {
      fetchPolicy: 'no-cache',
      variables: {
        code: 'PROVIDER_REQUEST_FORM',
      },
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
      },
    }
  );

  const showErrorToast = () => {
    showToast(toast, intl.formatMessage({id: 'apiErrorMsg'}), ToastType.error);
  };

  const getTemplateCategoriesData = () => {
    getTemplateCategories()
      .then((data) => {
        return getTemplateCategoryList(data);
      })
      .then((list) => {
        setComponentState((prev) => ({
          ...prev,
          templateCategories: list || [],
        }));
      })
      .catch((error) => {

        showErrorToast();
        setComponentState((prev) => ({
          ...prev,
          forms: [],
          loading: false,
        }));
      });
  };

  const getTemplatesAndForms = () => {
    const path = `${ContentTypes.emails.path}?category=MULTIPLE_PATIENT_FORMS`;

    Promise.all([getTemplates(path), getFormsByCategory()])
      .then((responses) => {
        const templateResponse = responses?.[0];
        const formsResponse = responses?.[1];
        if (templateResponse?.data) {
          const formattedTemplates =
            getFormattedEmailTemplateData(templateResponse);
          setFormattedTemplates(formattedTemplates);
          const template = formattedTemplates?.[0];
          setComponentState((prev) => ({
            ...prev,
            emailTemplateId: template?.id || '',
            templateData: template || {},
          }));
        }

        if (formsResponse && formsResponse?.data?.forms) {
          setComponentState((prev) => ({
            ...prev,
            forms: formsResponse?.data?.forms,
            loading: false,
          }));
        }
      })
      .catch((error) => {
        showErrorToast();
        setComponentState((prev) => ({
          ...prev,
          loading: false,
        }));
      });
  };

  const getPreviewHtml = (templateData: any) => {
    if (!componentState.selectedForm) {
      return '';
    }
    const engine = new Liquid();
    const finalMergeTag = {
      ...getMergeTags(
        templateData?.templateCategory,
        componentState?.templateCategories
      ),
      ...getFormMergedTags([componentState.selectedForm], ''),
    };
    const tpl = engine.parse(templateData?.templateHtml || '');
    return engine.renderSync(tpl, finalMergeTag);
  };

  const getFormMergedTags = (forms: IForm[], token: string) => {
    return {
      formList: forms.map((form) => {
        return {
          name: form.name,
          link: getTpaFormURL(token),
        };
      }),
    };
  };

  const getMergeTags = (category: string, categories: ITemplateCategory[]) => {
    const mergeTagsByCategory = categories.find(
      (item) => item.name === category
    )?.mergeTags;
    return {...mergeTagsByCategory, global: accountMergeTags};
  };

  const getEmailData = (forms: IForm[], widgetToken: string) => {
    return {
      to: [props?.assignmentData?.contactEmail],
      template: componentState.emailTemplateId,
      data: getFormMergedTags(forms, widgetToken),
      accountId: accountId,
      tags: ['cms-email'],
    };
  };

  const onSubmitClick = () => {
    if (componentState?.submitApiLoading) {
      return;
    }
    const isInputError = componentState.selectedForm ? false : true;
    setComponentState((prev) => ({
      ...prev,
      showError: isInputError,
      submitApiLoading: !isInputError,
    }));
    if (!isInputError && componentState?.emailTemplateId) {
      if (!assignmentData.contactId) {
        return;
      }

      generateFormToken({
        variables: {
          params: {
            payload: {
              formId: componentState?.selectedForm?.id,
              tpaCareAdvocateId: assignmentData?.contactId,
            },
            accountId: accountId,
            widgetType: WIDGET_TYPES.TPA_CARE_ADVOCATE,
          },
        },
      })
        .then((data: any) => {
          const widgetToken = data?.data?.generateWidgetToken?.widgetToken;
          if (componentState?.selectedForm && widgetToken) {
            const emailData = getEmailData(
              [componentState?.selectedForm],
              widgetToken
            );
            sendEmail(emailData, {
              successHandler: () => {
                setComponentState((prev) => ({
                  ...prev,
                  submitApiLoading: false,
                }));
                props?.onClose();
                showPopupNotification(
                  'Success',
                  intl.formatMessage({
                    id: 'providerRequestSend',
                  }),
                  'success'
                );
              },
              errorHandler: () => {
                showErrorToast();
                setComponentState((prev) => ({
                  ...prev,
                  submitApiLoading: false,
                }));
              },
            });
          } else {
            showErrorToast();
            setComponentState((prev) => ({
              ...prev,
              submitApiLoading: false,
            }));
          }
        })
        .catch((error) => {

          showErrorToast();
          setComponentState((prev) => ({
            ...prev,
            submitApiLoading: false,
          }));
        });
    }
  };

  return (
    <Drawer
      visible={isVisible}
      onClose={() => {
        onClose();
      }}
      destroyOnClose
      title={
        <ModalActionTitle title={'sendForm'} titleColor={''} buttonList={[]} />
      }
      width={drawerWidth}
      closable={false}
      placement="right"
    >
      {componentState.loading && (
        <Stack space={4} direction="column">
          <Skeleton.Text />
          <Skeleton rounded="md" />
        </Stack>
      )}
      {!componentState.loading && (
        <Stack space={4} direction="column">
          <DisplayText textLocalId="template"></DisplayText>
          <Select
            onChange={(value: string) => {
              const template = formattedTemplates.find(
                (item: any) => item.id == value
              );
              setComponentState((prev) => ({
                ...prev,
                emailTemplateId: template.id || '',
                templateData: template || {},
              }));
            }}
            value={componentState.emailTemplateId}
          >
            {formattedTemplates.map((item: any) => {
              return (
                <Select.Option
                  label={item.templateName}
                  value={item.id as string}
                  key={`template_option_${item.id}`}
                >
                  {item.templateName}
                </Select.Option>
              );
            })}
          </Select>
          <DisplayText
            textLocalId="selectForm"
            extraStyles={{
              marginTop: 20,
            }}
          />
          <Select
            onChange={(value: string) => {
              const selectedForm = componentState?.forms?.find(
                (item: any) => item.id == value
              );
              setComponentState((prev) => ({
                ...prev,
                selectedForm: selectedForm,
              }));
            }}
            className={
              componentState?.showError && !componentState?.selectedForm
                ? 'ant-select field-error'
                : 'ant-select'
            }
            value={componentState.selectedForm?.id}
          >
            {componentState?.forms.map((item: any) => {
              return (
                <Select.Option
                  label={item.name}
                  value={item.id as string}
                  key={`form_option_${item.id}`}
                >
                  {item.name}
                </Select.Option>
              );
            })}
          </Select>
          <Stack direction="row" style={{marginTop: 20, marginBottom: 20}}>
            <Spacer />
            <Button
              onPress={onSubmitClick}
              rounded="3xl"
              width={'100'}
              isLoading={componentState?.submitApiLoading}
              isDisabled={componentState?.submitApiLoading}
            >
              <DisplayText
                textLocalId="sendForm"
                extraStyles={{
                  color: componentState?.submitApiLoading
                    ? Colors.Custom.Gray400
                    : Colors.Custom.MonochromeWhite,
                }}
              />
            </Button>
          </Stack>
          {componentState.templateData?.templateHtml &&
            componentState.selectedForm && (
              <View>{parse(getPreviewHtml(componentState.templateData))}</View>
            )}
        </Stack>
      )}
    </Drawer>
  );
}
