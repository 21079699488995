import React from 'react';
import { Text, StyleSheet } from 'react-native';
import { Colors } from '../../../styles';
import Stack from '../LayoutComponents/Stack';
import CheckRoundIcon from '../../../assets/Icons/CheckRoundIcon';
import { useIntl } from 'react-intl';
import DangerSvg from '../Svg/DangerSvg';

const GoalInfoView = (props: {goalProgress: number}) => {
  const isProgressNegative = props?.goalProgress < 0;
  const intl = useIntl();
  return (
    <Stack
      direction='row'
      style={styles.container}
    >
      {isProgressNegative ? (
        <DangerSvg
          customColor={Colors.Custom.crossIconColor}
          height='16'
          width='16'
        />
      ) : (
        <CheckRoundIcon />
      )}
      <Text
        style={[styles.goalInfoText, {color: isProgressNegative ? Colors.Custom.crossIconColor : Colors.Custom.SuccessColor}]}
      >
        {intl.formatMessage({ id: `${isProgressNegative ? 'targetDeviation' : 'goalAchieved'}` })}
      </Text>
    </Stack>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  goalInfoText: {
    marginLeft: 3,
    fontSize: 14,
  }
});

export default GoalInfoView;
