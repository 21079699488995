import React from 'react';
import {View, ViewStyle} from 'react-native';
import {Path, Svg} from 'react-native-svg';
import {svgStyles} from './SvgStyles';
import {Colors} from '../../../styles';

const DraggableIconSvg = (props: {style?: ViewStyle, width?: number, height?: number}) => {
  const { width = 8, height = 12 } = props;
  return (
    <View style={[props.style]}>
      <Svg
        width={width}
        height={height}
        viewBox="0 0 8 12"
        fill="none"
      >
        <Path
          d="M6.51852 8.96295C7.17308 8.96295 7.7037 9.49358 7.7037 10.1481C7.7037 10.8027 7.17308 11.3333 6.51852 11.3333C5.86396 11.3333 5.33333 10.8027 5.33333 10.1481C5.33333 9.49358 5.86396 8.96295 6.51852 8.96295Z"
          fill={Colors.FoldPixel.GRAY300}
        />
        <Path
          d="M6.51852 4.8148C7.17308 4.8148 7.7037 5.34543 7.7037 5.99999C7.7037 6.65455 7.17308 7.18517 6.51852 7.18517C5.86396 7.18517 5.33333 6.65455 5.33333 5.99999C5.33333 5.34543 5.86396 4.8148 6.51852 4.8148Z"
          fill={Colors.FoldPixel.GRAY300}
        />
        <Path
          d="M6.51852 0.666656C7.17308 0.666656 7.7037 1.19728 7.7037 1.85184C7.7037 2.5064 7.17308 3.03703 6.51852 3.03703C5.86396 3.03703 5.33333 2.5064 5.33333 1.85184C5.33333 1.19728 5.86396 0.666656 6.51852 0.666656Z"
          fill={Colors.FoldPixel.GRAY300}
        />
        <Path
          d="M1.18519 8.96295C1.83974 8.96295 2.37037 9.49358 2.37037 10.1481C2.37037 10.8027 1.83974 11.3333 1.18519 11.3333C0.530625 11.3333 0 10.8027 0 10.1481C0 9.49358 0.530625 8.96295 1.18519 8.96295Z"
          fill={Colors.FoldPixel.GRAY300}
        />
        <Path
          d="M1.18519 4.8148C1.83974 4.8148 2.37037 5.34543 2.37037 5.99999C2.37037 6.65455 1.83974 7.18517 1.18519 7.18517C0.530625 7.18517 0 6.65455 0 5.99999C0 5.34543 0.530625 4.8148 1.18519 4.8148Z"
          fill={Colors.FoldPixel.GRAY300}
        />
        <Path
          d="M1.18519 0.666656C1.83974 0.666656 2.37037 1.19728 2.37037 1.85184C2.37037 2.5064 1.83974 3.03703 1.18519 3.03703C0.530625 3.03703 0 2.5064 0 1.85184C0 1.19728 0.530625 0.666656 1.18519 0.666656Z"
          fill={Colors.FoldPixel.GRAY300}
        />
      </Svg>
    </View>
  );
};

export default DraggableIconSvg;
